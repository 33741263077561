import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes, { array, bool, func, number } from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import {
  GenericAlert,
  GenericDateInput,
  GenericDialog,
  GenericDialogActions,
  GenericGrid,
  GenericLabel,
  GenericNumberInput,
  GenericRadioInput,
  GenericSelectInput,
  GenericTextInput,
  GenericTitle,
} from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType } from "views/Constants/Constant";
import ParameterComponent from "views/Components/ParameterComponent";
import { DialogContent, DialogTitle } from "@material-ui/core";
import { EditIcon } from "core/Icons";
import GridButton from "views/Components/GridButton";
import { Proxy } from "core";
import Button from "components/CustomButtons/Button.jsx";
import Select from "react-select";

class eTransferSettlementFileByClient extends Component {
  constructor(props) {
    super(props);
    this.initTransactionDetailColumns();
    this.initExportColumns();

    this.state = {
      model: {
        SettlementDate: DateHelper.GetDate(),
        UniqueClientId: null,
        UniqueClientId2: null,
        ToDate: DateHelper.GetDate(),
        FromDate: DateHelper.GetDate(),
        SettlementStatusId: -1,
      },
      filterName: "Filter By",
      settlementListBySettlementDate: [],
      settlementListBySettTransactionDate: [],
      isLoading: false,
      GrandTotalCreditBalance: undefined,
      GrandTotalCreditNumber: undefined,
      GrandTotalDebitBalance: undefined,
      GrandTotalDebitNumber: undefined,
      GrandTotalDifference: undefined,
      TotalSettledBalance: undefined,
      TotalCanceledBalance: undefined,
      TotalOutstandingBalance: undefined,
      TotalUnSettledBalance: undefined,
      TotalUnmatchedBalance: undefined,
      NetTotalBalanceChange: undefined,
      isTransactionDetailDialogOpen: false,
      loadAtStartup: false,
      gridTransactionDetailRefreshToken: false,
      selectedRow: undefined,
      headerName: undefined,
      clientNameColumnColor: "#000000",
      settlementStatusColumnColor: "#000000",
      totalCreditNumberColumnColor: "#000000",
      totalDebitNumberColumnColor: "#000000",
      isDownloadDialogOpen: false,
      exportReportType: "PDF",
      exportSelectedColumns: this.exportColumns,
    };

    this.transactionDetailGridRef = React.createRef();
  }

  componentDidMount() {
    this.props.setAppLeftTitle(
      "E-Transfer Settlement and Reconciliation Report"
    );
    this.props.setAppCenterTitle("CLEARING & SETTLEMENT");
    this.setState({ loadAtStartup: true });
  }

  handleChange = (name, newValue) => {
    this.setState((state) => {
      var model = state.model;
      model[name] = newValue;
      return { model };
    });
  };

  GetData = async () => {
    if (this.IsValid()) {
      const { ExecuteApiPost } = this.props;
      const { model } = this.state;

      let requetModel = {
        TransactionDateFrom: model.FromDate,
        TransactionDateTo: model.FromDate,
        uniqueClientId: model.UniqueClientId,
        settlementStatusId: model.SettlementStatusId,
      };

      const responseData = await ExecuteApiPost(
        "/bankapi/v1.0/ClearingSettlement/GetEtransferTransactionsLogSummaryByClients",
        requetModel
      );

      this.setState({
        settlementListBySettTransactionDate:
          responseData.EtransferTransactionsLogSummaryByClients,
        GrandTotalCreditBalance: responseData.GrandTotalCreditBalance,
        GrandTotalCreditNumber: responseData.GrandTotalCreditNumber,
        GrandTotalDebitBalance: responseData.GrandTotalDebitBalance,
        GrandTotalDebitNumber: responseData.GrandTotalDebitNumber,
        GrandTotalDifference: responseData.GrandTotalDifference,
        TotalSettledBalance: responseData.TotalSettledBalance,
        TotalCanceledBalance: responseData.TotalCanceledBalance,
        TotalOutstandingBalance: responseData.TotalOutstandingBalance,
        TotalUnmatchedBalance: responseData.TotalUnmatchedBalance,
        NetTotalBalanceChange: responseData.NetTotalBalanceChange,
        TotalUnSettledBalance: responseData.TotalUnSettledBalance,
      });
    }
  };

  IsValid = () => {
    var vmodel = { ...this.state.model };
    if (!vmodel || !vmodel.SettlementDate) {
      this.props.showMessage(
        "warning",
        "Please fill required fields!",
        "Settlement File Date cannot be null"
      );
      return false;
    } else if (typeof vmodel.SettlementDate != "object") {
      this.props.showMessage(
        "warning",
        "Please fill required fields!",
        "Settlement File Date is invalid"
      );
      return false;
    }
    return true;
  };

  handleClear = () => {
    this.setState({
      model: { SettlementDate: DateHelper.GetDate() },
      settlementListBySettlementDate: [],
      settlementListBySettTransactionDate: [],
      UniqueClientId: null,
      UniqueClientId2: undefined,
      FromDate: DateHelper.GetDate(),
      ToDate: DateHelper.GetDate(),
      GrandTotalCreditBalance: undefined,
      GrandTotalCreditNumber: undefined,
      GrandTotalDebitBalance: undefined,
      GrandTotalDebitNumber: undefined,
      GrandTotalDifference: undefined,
      TotalSettledBalance: undefined,
      TotalCanceledBalance: undefined,
      TotalOutstandingBalance: undefined,
      TotalUnmatchedBalance: undefined,
      NetTotalBalanceChange: undefined,
    });
  };

  closeTransactionDetailDialog = () => {
    this.setState({
      transactionList: [],
      isTransactionDetailDialogOpen: false,
    });
  };

  GetTransactionList = async (selectedRow, headerName) => {
    const { ExecuteApiPost } = this.props;
    let requestData = null;
    if (headerName === "ClientName" || headerName === "SettlementStatus") {
      requestData = {
        TransactionDateFrom: selectedRow.TransactionDateFrom,
        TransactionDateTo: selectedRow.TransactionDateTo,
        UniqueClientId: selectedRow.UniqueClientId,
        SettlementStatusId: selectedRow.SettlementStatusId,
        PageSize: 1_000_000,
      };
    }
    if (headerName === "TotalCreditNumber") {
      requestData = {
        TransactionDateFrom: selectedRow.TransactionDateFrom,
        TransactionDateTo: selectedRow.TransactionDateTo,
        UniqueClientId: selectedRow.UniqueClientId,
        SettlementStatusId: selectedRow.SettlementStatusId,
        TransactionTypeId: selectedRow.CreditTransactionTypeId,
        PageSize: 1_000_000,
      };
    }
    if (headerName === "TotalDebitNumber") {
      requestData = {
        TransactionDateFrom: selectedRow.TransactionDateFrom,
        TransactionDateTo: selectedRow.TransactionDateTo,
        UniqueClientId: selectedRow.UniqueClientId,
        SettlementStatusId: selectedRow.SettlementStatusId,
        TransactionTypeId: selectedRow.DebitTransactionTypeId,
        PageSize: 1_000_000,
      };
    }

    let responseData = await ExecuteApiPost(
      "/bankapi/v1.0/ClearingSettlement/GetEtransferTransactionsLogByClientsPaged",
      requestData
    );
    if (responseData != null) {
      this.setState({
        headerModel: {},
        transactionList: responseData.Data.length < 1 ? [] : responseData.Data,
        isLoading: false,
      });
    }
  };

  getTransactionDetailDialog = () => {
    const { isTransactionDetailDialogOpen, isLoading } = this.state;

    return (
      <GenericDialog
        open={isTransactionDetailDialogOpen && !isLoading}
        maxWidth="xl"
        fullWidth
      >
        <DialogContent>
          <GridContainer spacing={16}>
            <GenericGrid
              Data={this.state.transactionList}
              Columns={this.transactionDetailColumns}
              PageSize={10}
              ShowPagination={true}
            />
          </GridContainer>
        </DialogContent>
        <GenericDialogActions>
          <GridContainer justify="flex-end">
            <Button size="sm" onClick={this.closeTransactionDetailDialog}>
              CANCEL
            </Button>
          </GridContainer>
        </GenericDialogActions>
      </GenericDialog>
    );
  };

  initTransactionDetailColumns() {
    this.transactionDetailColumns = [
      {
        Header: "Actions",
        accessor: "Actions",
        Cell: (row) => (
          <GridButton Disabled={true} tooltip="Edit" Icon={EditIcon} />
        ),
        sortable: false,
        filterable: false,
        width: 70,
        show: true,
      },
      {
        Header: "Client Id",
        accessor: "UniqueClientId",
      },
      {
        Header: "Client Name",
        accessor: "ClientName",
        width: 150,
      },
      {
        Header: "Balance Date",
        accessor: "BalanceDate",
        type: GridColumnType.DateTimeUtc,
        width: 100,
      },
      {
        Header: "Settlement Date",
        accessor: "SettlementDate",
        type: GridColumnType.DateUtc,
      },
      {
        Header: "Received/Sent",
        accessor: "ReceivedSent",
      },
      {
        Header: "Debit/Credit",
        accessor: "TransactionType",
      },
      {
        Header: "Amount",
        accessor: "Amount",
        type: GridColumnType.Money,
      },
      {
        Header: "Settlement Status",
        accessor: "SettlementStatus",
      },
      {
        Header: "Interac Ref Number",
        accessor: "InteracRefNumber",
      },
      {
        Header: "Reference Number",
        accessor: "TransactionReferenceNumber",
      },
      {
        Header: "Transaction Insert Date",
        accessor: "TransactionInsertDate",
        type: GridColumnType.DateTimeUtc,
      },
      {
        Header: "Participant Id",
        accessor: "ParticipantId",
      },
      {
        Header: "Financial Ins. Name",
        accessor: "FinancialInstitutionName",
        width: 150,
      },
      {
        Header: "Description",
        accessor: "Description",
        width: 600,
      },
    ];
  }

  getColumns = () => {
    const {
      clientNameColumnColor,
      settlementStatusColumnColor,
      totalCreditNumberColumnColor,
      totalDebitNumberColumnColor,
    } = this.state;

    return [
      {
        Header: "Client ID",
        accessor: "UniqueClientCode",
      },
      {
        Header: "Client Name",
        accessor: "ClientName",
        Cell: (row) => {
          return (
            <div
              style={{
                width: "100%",
                height: "100%",
                cursor: "pointer",
                color: clientNameColumnColor,
              }}
              onClick={() => {
                this.openTransactionDetailDialog();
                this.GetTransactionList(row.original, "ClientName");
              }}
              onMouseEnter={() => {
                this.setState({
                  clientNameColumnColor: "#FF0000",
                });
              }}
              onMouseLeave={() => {
                this.setState({
                  clientNameColumnColor: "#000000",
                });
              }}
            >
              {row.original.ClientName}
            </div>
          );
        },
      },
      {
        Header: "Settlement Status",
        accessor: "SettlementStatus",
        Cell: (row) => {
          return (
            <div
              style={{
                width: "100%",
                height: "100%",
                cursor: "pointer",
                color: settlementStatusColumnColor,
              }}
              onClick={() => {
                this.openTransactionDetailDialog();
                this.GetTransactionList(row.original, "SettlementStatus");
              }}
              onMouseEnter={() => {
                this.setState({
                  settlementStatusColumnColor: "#FF0000",
                });
              }}
              onMouseLeave={() => {
                this.setState({
                  settlementStatusColumnColor: "#000000",
                });
              }}
            >
              {row.original.SettlementStatus}
            </div>
          );
        },
      },
      {
        Header: "Transaction Date",
        accessor: "TransactionDateFrom",
        type: GridColumnType.DateUtc,
      },
      {
        Header: "Total Number Of Credits",
        accessor: "TotalCreditNumber",
        Cell: (row) => {
          return (
            <div
              style={{
                width: "100%",
                height: "100%",
                cursor: "pointer",
                color: totalCreditNumberColumnColor,
              }}
              onClick={() => {
                this.openTransactionDetailDialog();
                this.GetTransactionList(row.original, "TotalCreditNumber");
              }}
              onMouseEnter={() => {
                this.setState({
                  totalCreditNumberColumnColor: "#FF0000",
                });
              }}
              onMouseLeave={() => {
                this.setState({
                  totalCreditNumberColumnColor: "#000000",
                });
              }}
            >
              {row.original.TotalCreditNumber}
            </div>
          );
        },
      },
      {
        Header: "Credit Balance",
        accessor: "CreditBalance",
        type: GridColumnType.Money,
        ColumnType: GridColumnType.Money,
      },
      {
        Header: "Total Number Of Debits",
        accessor: "TotalDebitNumber",
        Cell: (row) => {
          return (
            <div
              style={{
                width: "100%",
                height: "100%",
                cursor: "pointer",
                color: totalDebitNumberColumnColor,
              }}
              onClick={() => {
                this.openTransactionDetailDialog();
                this.GetTransactionList(row.original, "TotalDebitNumber");
              }}
              onMouseEnter={() => {
                this.setState({
                  totalDebitNumberColumnColor: "#FF0000",
                });
              }}
              onMouseLeave={() => {
                this.setState({
                  totalDebitNumberColumnColor: "#000000",
                });
              }}
            >
              {row.original.TotalDebitNumber}
            </div>
          );
        },
      },
      {
        Header: "Debit Balance",
        accessor: "DebitBalance",
        type: GridColumnType.Money,
        ColumnType: GridColumnType.Money,
      },
      {
        Header: "Balance Difference",
        accessor: "Difference",
        type: GridColumnType.Money,
        ColumnType: GridColumnType.Money,
      },
    ];
  };

  onValidate = () => {
    const { model } = this.state;

    if (!model.FromDate || !model.ToDate) {
      this.props.showMessage(
        "warning",
        "Please fill required fields!",
        "Transaction Date is invalid"
      );
      return false;
    }

    return true;
  };

  getTotalDetailPanel = () => {
    const {
      GrandTotalCreditBalance,
      GrandTotalCreditNumber,
      GrandTotalDebitBalance,
      GrandTotalDebitNumber,
      GrandTotalDifference,
      TotalSettledBalance,
      TotalCanceledBalance,
      TotalOutstandingBalance,
      TotalUnmatchedBalance,
      NetTotalBalanceChange,
      TotalUnSettledBalance,
    } = this.state;

    return (
      <GridItem xs={7}>
        <Card>
          <CardHeader>
            <GenericTitle text={"Date Range Results"} />
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={4}>
                <GenericNumberInput
                  Name="TotalSettledBalance"
                  LabelText="Total Settled"
                  LabelMd={6}
                  Value={TotalSettledBalance}
                  Disabled={true}
                  AllowNegative
                  DecimalScale={2}
                  Prefix="$"
                />
              </GridItem>
              <GridItem xs={4}>
                <GenericNumberInput
                  Name="TotalUnmatchedBalance"
                  LabelText="Total Unmatched"
                  LabelMd={6}
                  Value={TotalUnmatchedBalance}
                  AllowNegative
                  Disabled={true}
                  DecimalScale={2}
                  Prefix="$"
                />
              </GridItem>
              <GridItem xs={4}>
                <GenericNumberInput
                  Name="NetTotalBalanceChange"
                  LabelText="Net Total Change"
                  LabelMd={6}
                  Value={NetTotalBalanceChange}
                  AllowNegative
                  Disabled={true}
                  DecimalScale={2}
                  Prefix="$"
                />
              </GridItem>
              <GridItem xs={4}>
                <GenericNumberInput
                  Name="TotalUnSettledBalance"
                  LabelText="Total Unsettled"
                  LabelMd={6}
                  Value={TotalUnSettledBalance}
                  AllowNegative
                  Disabled={true}
                  DecimalScale={2}
                  Prefix="$"
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <GenericTitle text={"Query Results"} />
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={6}>
                <GenericNumberInput
                  Name="GrandTotalCreditNumber"
                  LabelText="Total Number of Credits"
                  LabelMd={6}
                  Value={GrandTotalCreditNumber}
                  Disabled={true}
                  DecimalScale={2}
                  AllowNegative
                />
                <GenericNumberInput
                  Name="GrandTotalCreditBalance"
                  LabelText="Total Credit Balance"
                  LabelMd={6}
                  Value={GrandTotalCreditBalance}
                  Disabled={true}
                  DecimalScale={2}
                  Prefix="$"
                  AllowNegative
                />
                <GenericNumberInput
                  Name="GrandTotalDifference"
                  LabelText="Total Balance Difference"
                  LabelMd={6}
                  Value={GrandTotalDifference}
                  Disabled={true}
                  DecimalScale={2}
                  Prefix="$"
                  AllowNegative
                />
              </GridItem>
              <GridItem xs={6}>
                <GenericNumberInput
                  Name="GrandTotalDebitNumber"
                  LabelText="Total Number of Debits"
                  Value={GrandTotalDebitNumber}
                  Disabled={true}
                  DecimalScale={2}
                  AllowNegative
                />
                <GenericNumberInput
                  Name="GrandTotalDebitBalance"
                  LabelText="Total Debit Balance"
                  Value={GrandTotalDebitBalance}
                  Disabled={true}
                  DecimalScale={2}
                  Prefix="$"
                  AllowNegative
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    );
  };

  openTransactionDetailDialog = (row, headerName) => {
    this.setState({
      isLoading: true,
      isTransactionDetailDialogOpen: true,
      headerName,
      selectedRow: row,
      gridTransactionDetailRefreshToken:
        !this.state.gridTransactionDetailRefreshToken,
    });
  };

  getDataSource = () => {
    return this.state.settlementListBySettTransactionDate;
  };

  openExportDialog = () => {
    this.setState({
      isDownloadDialogOpen: true,
    });
  };

  closeExportDialog = () => {
    this.setState({
      isDownloadDialogOpen: false,
    });
  };

  getSelectedExportColumns = () => {
    const { exportSelectedColumns } = this.state;

    var columns = {};

    exportSelectedColumns.forEach((c) => {
      columns[c.value] = {
        value: c.value,
        title: c.title,
        columntype: c.columntype,
        type: c.type,
      };
    });

    var result = [];
    result.push(columns);

    return result;
  };

  isExportRequestValid = () => {
    const { model } = this.state;

    if (model.FromDate == null || model.FromDate == undefined) {
      this.showMessage(
        "warning",
        "Please select required field !",
        "Select a Settlement File Date !"
      );
      return false;
    }

    return true;
  };

  showLoading() {
    this.setState({ isLoading: true });
  }

  hideLoading() {
    this.setState({ isLoading: false });
  }

  showMessage = (type, title, message) => {
    this.setState({
      alert: (
        <GenericAlert
          Title={title}
          Message={message}
          Type={type}
          OnConfirm={() => this.setState({ alert: "" })}
        />
      ),
    });
  };

  onExport = () => {
    if (this.isExportRequestValid()) {
      const { exportReportType } = this.state;
      const { SettlementDate, FromDate, ToDate, UniqueClientId2, ...model2 } =
        this.state.model;

      const exportModel = {
        ...model2,
        TransactionDateTo: this.state.model.FromDate,
        UniqueClientId: this.state.model.UniqueClientId2,
        TransactionDateFrom: this.state.model.FromDate,
        jsonFile: {},
      };

      var exportColumns = this.getSelectedExportColumns() ?? [];

      exportModel.jsonFile = {
        colmns: exportColumns,
        format: exportReportType,
        title: "E-Transfer Settlement and Reconciliation Report",
      };

      this.showLoading();

      Proxy.DownloadGeneratedFile(
        "/bankapi/v1.0/ClearingSettlement/GetEtransferTransactionsLogByClientsExportDownload",
        exportModel,
        (responseData) => {
          this.hideLoading();

          if (!responseData.IsSucceeded) {
            this.showMessage("error", "Error", responseData.ErrorDescription);
            return;
          }
        },
        (errorMessage) => {
          this.hideLoading();
          this.showMessage("error", "Error", errorMessage);
        },
        this.showMessage
      );
    }
  };

  setExportSelection = (value) => {
    console.log(value);
    this.setState({ exportSelectedColumns: value });
  };

  initExportColumns() {
    var exportColumns = [];

    this.transactionDetailColumns.forEach((c) => {
      if (
        c.accessor != "Actions" &&
        c.accessor != "ED" &&
        (c.show == undefined || c.show)
      ) {
        console.log(c.accessor, c.Header, c.type);
        var exportColumn = {};
        exportColumn.value = c.accessor;
        exportColumn.title = c.Header;
        if (c.type) {
          exportColumn.type = c.type;
        }

        exportColumns.push(exportColumn);
      }
    }, this);

    this.exportColumns = exportColumns;
  }

  setValueToState = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    const {
      isLoading,
      filterName,
      model,
      isDownloadDialogOpen,
      exportReportType,
      exportSelectedColumns,
    } = this.state;

    return (
      <div>
        {this.state.alert}
        <ButtonToolbar
          ButtonList={[
            { Code: "Search", OnClick: this.GetData, Disabled: false },
            { Code: "Export", OnClick: this.openExportDialog, Disabled: false },
          ]}
          menuId={this.props.menuId}
          ApprovalData={this.props.ApprovalData}
          After={this.props.After}
        />
        <LoadingComponent Show={isLoading} />
        <GridContainer>
          <GridItem xs={5}>
            <Card style={{ height: "90%" }}>
              <CardHeader>
                <GenericTitle text={filterName} />
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12}>
                    <GenericDateInput
                      Name="FromDate"
                      LabelText="Transaction Date"
                      Value={
                        model.FromDate
                          ? Formatter.FormatDateUtc(model.FromDate)
                          : ""
                      }
                      ValueChanged={this.handleChange}
                      MaxDate={model.ToDate || DateHelper.GetDate()}
                      Utc
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <ParameterComponent
                      Name="SettlementStatusId"
                      LabelText="Settlement Status"
                      Value={model.SettlementStatusId}
                      ParameterCode="ETransferSettlementStatus"
                      ValueChanged={this.handleChange}
                      All={true}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <GenericSelectInput
                      Name="UniqueClientId"
                      LabelText="Client"
                      Method="POST"
                      Url="/bankapi/v1.0/BankCustomer/Search"
                      Parameter={{}}
                      DataRoot="Item"
                      KeyValueMember="Id"
                      RenderItem={(d) => `${d.UniqueClientId} - ${d.Name}`}
                      Value={model.UniqueClientId || ""}
                      ValueChanged={this.handleChange}
                      All
                      CanClear
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <GenericTextInput
                      Name="SettlementSource"
                      LabelText="Settlement Source"
                      Value="Bridge"
                      Disabled
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          {this.getTotalDetailPanel()}
        </GridContainer>
        <Card className="no-radius">
          <CardBody>
            <CardHeader>
              <GenericTitle text={"Settlement Details"} />
            </CardHeader>
            <GridItem xs={12}>
              <GridItem>
                <GenericGrid
                  Data={this.getDataSource()}
                  Columns={this.getColumns()}
                  SelectedIndex={this.state.rowIndex}
                />
              </GridItem>
            </GridItem>
          </CardBody>
        </Card>
        {this.getTransactionDetailDialog()}
        <GenericDialog open={isDownloadDialogOpen} fullWidth={true}>
          <DialogTitle>
            <GenericLabel
              Text="Select Report Type"
              FontSize="20px"
              Bold={true}
            />
          </DialogTitle>
          <DialogContent style={{ height: 300 }}>
            <GenericRadioInput
              Name="exportReportType"
              LabelText="Report Type"
              IsStatic={true}
              StaticData={[
                { Value: "PDF", Text: "PDF" },
                { Value: "CSV", Text: "CSV" },
                { Value: "EXCEL", Text: "EXCEL" },
              ]}
              KeyValueMember="Value"
              TextValueMember="Text"
              Value={exportReportType}
              ValueChanged={this.setValueToState}
            />
            <GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
            <GridItem style={{ marginTop: 20 }}>
              <Select
                value={exportSelectedColumns}
                onChange={this.setExportSelection}
                isMulti
                name="exportSelectedColumns"
                getOptionLabel={(d) => d.title}
                options={this.exportColumns}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </GridItem>
          </DialogContent>
          <GenericDialogActions>
            <Button size="sm" onClick={this.onExport}>
              Download
            </Button>
            <Button size="sm" onClick={this.closeExportDialog}>
              Cancel
            </Button>
          </GenericDialogActions>
        </GenericDialog>
      </div>
    );
  }
}

eTransferSettlementFileByClient.propTypes = {
  classes: PropTypes.object,
  handleOperationType: func,
  SelectedRowChange: func,
  showQuestionMessage: func,
  setAppLeftTitle: func,
  setAppCenterTitle: func,
  Disabled: bool,
  menuId: number,
  ApprovalData: array,
  After: func,
};

export default withArtifex(eTransferSettlementFileByClient, {});
