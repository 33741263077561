import CanadaPostTest from "views/ExternalPages/CanadaPostTest";
import ApiModelTest from "views/ExternalPages/ApiModelTest";
import ApiRequestTest from "views/ExternalPages/ApiRequestTest";
import CardDesign from "views/AmlKyc/ManualKycApplication/CardDesign";

import AtmLocator from "views/Atm/Location/AtmLocator.jsx";


import InteracRedirectPage from "views/InteracConfirm/InteracRedirectPage";
import KycPhotoUpload from "views/AmlKyc/ManualKycApplication/KycPhotoUpload";
import InteracCardHolderPage from "views/InteracConfirm/InteracCardHolderPage";
import KycAccountVerification from "views/AmlKyc/ManualKycApplication/KycAccountVerification";
import InteracInboundLogin from "views/InteracConfirm/InteracInboundLogin";
import InteracInboundPage from "views/InteracConfirm/InteracInboundPage";

import VisaDirectThirdPartyIframe from "views/VisaDirect/VisaDirectThirdPartyIframe";
import VirtualCardIframe from "views/VisaDirect/VirtualCardIframe";

const externalPageRoutes = [
	{
		path: "/VirtualCard/VirtualCardIframe",
		name: "VirtualCardIframe",
		component: VirtualCardIframe
	},
	{
		path: "/VisaDirect/VisaDirectThirdPartyIframe",
		name: "VisaDirectThirdPartyIframe",
		component: VisaDirectThirdPartyIframe
	},
	{
		path: "/InteracConfirm/InboundLogin",
		name: "InteracInboundLogin",
		component: InteracInboundLogin
	},
	{
		path: "/InteracConfirm/InboundPage",
		name: "InteracInboundPage",
		component: InteracInboundPage
	},
	{
		path: "/InteracConfirm/CardHolder",
		name: "InteracCardHolderPage",
		component: InteracCardHolderPage
	},
	{
		path: "/InteracConfirm/Redirect",
		name: "InteracRecirectPage",
		component: InteracRedirectPage
	},
	{
		path: "/out/ATMLocator",
		name: "ATM Locator",
		component: AtmLocator
	},
	{
		path: "/out/KycPhotoUpload/:key?",
		name: "Kyc Photo Upload",
		component: KycPhotoUpload
	},
	{
		path: "/out/KycAccountVerification/:key?",
		name: "Kyc Account Verification",
		component: KycAccountVerification
	},
	{
		path: "/out/ApiTest",
		name: "ApiTest",
		component: ApiModelTest
	},
	{
		path: "/out/RequestTest",
		name: "RequestTest",
		component: ApiRequestTest
	},
	{
		path:  "/out/CardDesign/:key?",
		name: "CardDesign",
		component: CardDesign
	},
	{
		path: "/out/CanadaPostTest",
		name: "Canada",
		component: CanadaPostTest
	},
	{
		path: "/out/404",
		name: "notFound"
	},
	{
		redirect: true,
		path: "/out",
		pathTo: "/out/404",
		name: "404 Page"
	} 
];

export default externalPageRoutes;
