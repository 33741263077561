import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import { GenericCheckInput, GenericExpansionPanel, GenericNumberInput, GenericSelectInput, GenericTitle } from "views/Components/Generic";
import { LmsLimitAdjustmentHistoryStatus } from "views/Constants/Constant";

const LOSLoanType = {
	SecureCard: "0",
	Loan: "1",
	LOC: "2",
};

class LimitAdjustmentForWorkflow extends React.Component {
	constructor(props) {
		super(props);

		this.parameterApplicantId = {};

		this.state = {
			model: {

			},
			applicantList: [],
			feeProfileList: [],
			parameterList: [],
			loanApprovalFeeList: [],
			isApprovedPeriodDisabled: false
		};

		this.parameterCodeList = ["LOSOccupation", "LOSDocumentType", "LOSPayOption"
			, "LOSYesNo", "LOSLoanPurpose", "LOSPaymentSchedule", "LOSRateOption"
			, "LOSPaymentMethod", "LOSLoanTranferType", "LMSPaymentDay"
			, "LOSSalary", "LOSAddrTime", "LOSOccupation", "EntityType"
			, "PaymentFrequency", "PaymentMethod", "PaymentOption", "LOSEmplTime"
			, "LOSDepositIn", "LOSApprovalSubStatus", "LOSVerifiedStatus", "IdentityVerifyingPerson", "LOSMaritalStatus"];

		this.parameterLOSPaymentFrequency = {
			ParameterCode: "LOSPaymentSchedule"
		};

		this.parameterLOSPaymentMethod = {
			ParameterCode: "LOSPaymentMethod"
		};
	}

	componentDidMount() {
	}

	GetApplicantList = async (model) => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/losapi/v1.0/LoanApproval/GetLoanApprovalUiParameter", {
			"ParameterList": this.parameterCodeList,
			"MenuCode": this.props.MenuCode,
			"UniqueClientId": model.UniqueClientId
		});

		this.setState({
			applicantList: result?.ApplicantList || [],
			feeProfileList: result?.FeeProfileList || [],
			parameterList: result?.ParameterList || []
		});
	}

	RenderItemApplication = (data) => {
		this.renderItemApplication = `${data.ApplicationNumber} - ${data.LoanType.ParameterDesc}`;

		return this.renderItemApplication;
	}

	Submit = async (workflowId, after) => {
		const { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var result;

		if (!model.AdditionalSecurityAmount || (model.AdditionalSecurityAmount && String(model.AdditionalSecurityAmount) == "0")) {
			var requestUpdateCardLimit = {
				ApplicationId: model.Id,
				NewCardLimit: model.NewCardLimit,
				ApplicationCardId: model.ApplicationCardId,
				StatusParameterValue: LmsLimitAdjustmentHistoryStatus.Approved,
				IsLimitAdjustment: true
			};

			result = await ExecuteApiPost("/lmsapi/v1.0/PaymentAdmin/UpdateCardLimit", requestUpdateCardLimit, null, null, null, null, null, workflowId);

			if (result != null && after) {
				after();
			}
		} else {
			var requestUpdateApplication = {
				ApplicationId: model.Id,
				NewSecurityAmount: model.AdditionalSecurityAmount,
				ApplicationCardId: model.ApplicationCardId,
				StatusParameterValue: LmsLimitAdjustmentHistoryStatus.NeedsSecurityAmount
			};

			result = await ExecuteApiPost("/lmsapi/v1.0/LimitAdjustmentHistory/ApplicationUpdate", requestUpdateApplication, null, null, null, null, null, workflowId);

			if (result != null && after) {
				after();
			}
		}
	}

	Reject = async (after, rejectReason) => {
		const { model } = this.state;
		const { ExecuteApiPost } = this.props;

		var request = {
			ApplicationCardId: model.ApplicationCardId,
			StatusParameterValue: LmsLimitAdjustmentHistoryStatus.Rejected,
			RejectReason: rejectReason
		};

		var result = await ExecuteApiPost("/lmsapi/v1.0/LimitAdjustmentHistory/Update", request);

		if (result != null && after) {
			after();
		}
	}

	GetModel = () => {
		const { model } = this.state;

		return { model };
	}

	FillData = (data) => {
		this.parameterApplicantId = {
			ApplicantId: data.Id
		};

		this.setState({ model: data, isApprovedPeriodDisabled: data.LoanType.ParameterValue == LOSLoanType.SecureCard });

		this.GetApplicantList(data);
	}

	render() {
		const { model, applicantList, feeProfileList, parameterList, isApprovedPeriodDisabled } = this.state;

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Submit",
							RowId: this.state.model.LimitAdjustmentHistoryId,
							Disabled: true,
							ModelFunction: this.GetModel,
							FillDataFromModel: this.FillData,
							OnClick: this.Submit,
							RejectFunction: this.Reject
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Applicant and Application Information">
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												Disabled
												IsStatic
												Name="ApplicantId"
												LabelText="Applicant"
												StaticData={applicantList}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="FullName"
												Value={model.ApplicantId} />
										</GridItem>
										<GridItem xs={4}>
											<GenericSelectInput
												Disabled
												Name="Id"
												LabelText="Application Number"
												Method="POST"
												Url="/losapi/v1.0/Application/SearchForPaymentAdmin"
												Parameter={this.parameterApplicantId}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={this.RenderItemApplication}
												Value={model.Id} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Detail">
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer>
												<GridItem xs={4}>
													<GenericTitle text="Requested" />
												</GridItem>
												<GridItem xs={4}>
													<GenericTitle text="Approved" />
												</GridItem>
												<GridItem xs={4}>
													<GenericTitle text="Agreement" />
												</GridItem>
											</GridContainer>
											<GridContainer>
												<GridItem xs={4}>
													<GenericNumberInput
														Disabled
														Name="RequestedAmount"
														LabelText="Requested Amount"
														LabelMd={6}
														Prefix="$"
														Value={model.RequestedAmount} />
												</GridItem>
												<GridItem xs={4}>
													<GenericSelectInput
														Disabled
														IsStatic
														Name="LoanFeeDefinitionId"
														LabelText="Fee Profile"
														LabelMd={5}
														StaticData={feeProfileList}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="Name"
														Value={model.LoanFeeDefinitionId} />
												</GridItem>
												<GridItem xs={4}>
													<GenericCheckInput
														Disabled
														Name="IsAgreementSigned"
														LabelText="Agreement Signed"
														Value={model.IsAgreementSigned} />
												</GridItem>
											</GridContainer>
											<GridContainer>
												<GridItem xs={4}>
													<GenericSelectInput
														Disabled
														IsStatic
														Name="RequestedPaymentFrequencyId"
														LabelText="Requested Payment Frequency"
														LabelMd={6}
														StaticData={parameterList.filter(prm => prm.ParameterCode == this.parameterLOSPaymentFrequency.ParameterCode)}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="ParameterDesc"
														Value={model.RequestedPaymentFrequencyId} />
												</GridItem>
												<GridItem xs={4}>
													<GenericNumberInput
														Disabled
														Name="ApprovedAmount"
														Prefix="$"
														LabelMd={5}
														LabelText="Approved Amount"
														Value={model.ApprovedAmount} />
												</GridItem>
												<GridItem xs={4}>
												</GridItem>
											</GridContainer>
											<GridContainer>
												<GridItem xs={4}>
													<GenericNumberInput
														Disabled
														Name="SecurityAmount"
														LabelText="Security Amount"
														LabelMd={6}
														Prefix="$"
														Value={model.SecurityAmount} />
												</GridItem>
												<GridItem xs={4}>
													<GenericSelectInput
														Disabled
														IsStatic
														Name="ApprovedPaymentFrequencyId"
														LabelText="Approved Payment Frequency"
														LabelMd={5}
														StaticData={parameterList.filter(prm => prm.ParameterCode == this.parameterLOSPaymentFrequency.ParameterCode)}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="ParameterDesc"
														Value={model.ApprovedPaymentFrequencyId} />
												</GridItem>
												<GridItem xs={4}>
												</GridItem>
											</GridContainer>
											<GridContainer>
												<GridItem xs={4}>
													{!isApprovedPeriodDisabled &&
														<GenericNumberInput
															Disabled
															NoFormatting
															Name="RequestedPaymentPeriod"
															LabelText="Requested Payment Period"
															LabelMd={6}
															Value={model.RequestedPaymentPeriod} />
													}
												</GridItem>
												<GridItem xs={4}>
													<GenericSelectInput
														Disabled
														IsStatic
														Name="ApprovedPaymentMethodId"
														LabelText="Interest Calculation Method"
														LabelMd={5}
														StaticData={parameterList.filter(prm => prm.ParameterCode == this.parameterLOSPaymentMethod.ParameterCode)}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="ParameterDesc"
														Value={model.ApprovedPaymentMethodId} />
												</GridItem>
												<GridItem xs={4}>

												</GridItem>
											</GridContainer>
											<GridContainer>
												<GridItem xs={4}>
												</GridItem>
												<GridItem xs={4}>
													<GenericNumberInput
														Disabled
														Name="ApprovedSecurityAmount"
														Prefix="$"
														LabelMd={5}
														LabelText="Approved Security Amount"
														Value={model.ApprovedSecurityAmount} />
												</GridItem>
											</GridContainer>
											<GridContainer>
												<GridItem xs={4}>
												</GridItem>
												<GridItem xs={4}>
													{!isApprovedPeriodDisabled &&
														<GenericNumberInput
															Disabled
															NoFormatting
															Name="ApprovedPaymentPeriod"
															LabelMd={5}
															LabelText="Approved Payment Period"
															Value={model.ApprovedPaymentPeriod} />
													}
												</GridItem>
												<GridItem xs={4}>
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Adjust Limit Detail">
									<GridContainer>
										<GridItem xs={6}>
											<GenericNumberInput
												Disabled
												LabelText="Current Security Amount"
												LabelMd={6}
												Value={model.SecurityBalance} />
											<GenericNumberInput
												Disabled
												Name="AdditionalSecurityAmount"
												LabelText="Additional Security Amount"
												LabelMd={6}
												Value={model.AdditionalSecurityAmount} />
										</GridItem>
										<GridItem xs={6}>
											<GenericNumberInput
												Disabled
												LabelText="Current Card Limit" 
												LabelMd={6}
												Value={model.CurrentCardLimit} />
											<GenericNumberInput
												Disabled
												Name="NewCardLimit"
												LabelText="New Card Limit"
												LabelMd={6}
												Value={model.NewCardLimit} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

LimitAdjustmentForWorkflow.propTypes = {
	ExecuteApiPost: PropTypes.func,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default LimitAdjustmentForWorkflow;