import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ResourceHelper from "core/ResourceHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import { GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTitle } from "views/Components/Generic";
import ParameterComponent from "views/Components/ParameterComponent";
import { SortedMenuName } from "views/Constants/Constant.Sorted";

class RoleLevelMenuMap extends React.Component {
	constructor(props) {
		super(props);

		this.columns = [
			{
				Header: "Role Group",
				accessor: "RoleGroup"
			},
			{
				Header: "Role Level",
				accessor: "RoleLevel"
			},
			{
				Header: "Menu Name",
				accessor: "MenuName"
			},
			{
				Header: "Order",
				accessor: "Order"
			}
		];

		this.validateProperties = [{ "RoleGroupId": "Role group" }, { "RoleLevelId": "Role level" }, { "MenuId": "Menu" }, { "Order": "Order" }];

		this.state = {
			model: {}
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("USER ADMIN");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("Role Level Menu Map");
		}
	}

	ValueChanged = (name, value) => {
		this.setState(state => {
			var model = state.model;
			var listRoleLevel = state.listRoleLevel;

			model[name] = value;

			if (name == "RoleGroupId") {
				if (value != null) {
					this.GetRoleLevel(value);

					model["RoleLevelId"] = null;
				}
				else {
					listRoleLevel = [];
				}
			}

			return { model, listRoleLevel };
		});
	}

	RowSelected = (index) => {
		const { list } = this.state;

		this.GetRoleLevel(list[index].RoleGroupId);

		this.setState({ model: list[index], selected: index });
	}

	GetRenderItemMenu = (x) => {
		var render = ResourceHelper.GetForMenu(x.MenuName);
		render = `${!render ? "! " + x.MenuName : render}`;

		return render;
	}

	GetRoleLevel = async (roleGroupId) => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/coreapi/v1.0/Role/SearchRoleLevel", { "RoleGroupId": roleGroupId });

		this.setState({ listRoleLevel: result || [] });
	}

	Search = async () => {
		var { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/coreapi/v1.0/RoleLevelMenuMap/Search", model);
		var list = result || [];

		if (result != null) {
			list.map(x => {
				var menuName = ResourceHelper.GetForMenu(x.MenuName);
				menuName = `${!menuName ? "! " + x.MenuName : menuName} - ${x.MenuCode}`;

				x.MenuName = menuName;

				return null;
			});

			model["IsRoleGroupId"] = false;
			model["IsRoleLevelId"] = false;
			model["IsMenuId"] = false;
			model["IsOrder"] = false;
		}

		this.setState({ list });
	}

	Clear = () => {
		this.setState({ model: {}, list: [], listRoleLevel: [], selected: null });
	}

	render() {
		const { model, list, listRoleLevel, selected } = this.state;
		const { Disabled, ExecuteApiPost, showConfirmMessageInDelete } = this.props;

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},
						{
							Code: "Submit",
							Disabled: Disabled || selected != null,
							OnClick: () => ExecuteApiPost("/coreapi/v1.0/RoleLevelMenuMap/Insert", model, model, this.validateProperties, null, [this.Clear, this.Search], true)
						},
						{
							Code: "Update",
							Disabled: Disabled || selected == null,
							OnClick: () => ExecuteApiPost("/coreapi/v1.0/RoleLevelMenuMap/Update", { Id: model.Id, RoleLevelId: model.RoleLevelId, MenuId: model.MenuId, Order: model.Order }, model, this.validateProperties, null, [this.Clear, this.Search], true)
						},
						{
							Code: "Delete",
							Disabled: Disabled || selected == null,
							OnClick: () => showConfirmMessageInDelete(() => ExecuteApiPost("/coreapi/v1.0/RoleLevelMenuMap/Delete", { Id: model.Id }, null, null, null, [this.Clear, this.Search], true))
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={"Role Level Menu Map"} />
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={6}>
									</GridItem>
								</GridContainer>
								<GenericExpansionPanel>
									<GridContainer>
										<GridItem xs={3}>
											<ParameterComponent
												Required
												IsInvalid={model.IsRoleGroupId}
												Name="RoleGroupId"
												LabelText="Role Group"
												LabelMd={3}
												ParameterCode="RoleGroup"
												Value={model.RoleGroupId}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												Required
												IsInvalid={model.IsRoleLevelId}
												Name="RoleLevelId"
												LabelText="Role Level"
												LabelMd={3}
												IsStatic={true}
												StaticData={listRoleLevel}
												DataRoot="Item"
												KeyValueMember="RoleLevelId"
												TextValueMember="RoleLevel"
												Value={model.RoleLevelId}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												Required
												IsInvalid={model.IsMenuId}
												Name="MenuId"
												LabelText="Menu"
												LabelMd={2}
												Url="/coreapi/v1.0/Menu/GetForBaseMenu"
												Method="GET"
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={this.GetRenderItemMenu}
												Sorted={SortedMenuName}
												Value={model.MenuId}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={2}>
											<GenericNumberInput
												Required
												IsInvalid={model.IsOrder}
												Name="Order"
												LabelText="Order"
												LabelMd={2}
												MaxLength={4}
												NoFormatting={true}
												Value={model.Order}
												ValueChanged={this.ValueChanged} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													SelectedIndex={selected}
													Columns={this.columns}
													RowSelected={this.RowSelected} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

RoleLevelMenuMap.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default RoleLevelMenuMap;