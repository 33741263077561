import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import { GenericDateInput, GenericExpansionPanel, GenericGrid, GenericSelectInput } from "views/Components/Generic";
import { GridColumnType } from "views/Constants/Constant";

class TransUnionSummaryReport extends React.Component {
	constructor(props) {
		super(props);

		this.columns = [
			{
				Header: "Applicant Name",
				accessor: "Applicant.FullName"
			},
			{
				Header: "Previous Inquiry Dates",
				accessor: "InsertDateTime",
				type: GridColumnType.DateUtc
			},
			{
				Header: "Bankruptcies",
				accessor: "Bankruptcies"
			},
			{
				Header: "Banking Closed For Cause",
				accessor: "BankingClosedForCause"
			},
			{
				Header: "Collection Inquiries",
				accessor: "CollectionInquiries"
			},
			{
				Header: "Trade Last Opened",
				accessor: "TradeLastOpened"
			},
			{
				Header: "Id Mismatch Alert",
				accessor: "IdMismatchAlert.ParameterDesc"
			},
			{
				Header: "Factors",
				accessor: "Factors"
			},
			{
				Header: "LegalItems",
				accessor: "LegalItems"
			},
			{
				Header: "Registered Items",
				accessor: "RegisteredItems"
			},
			{
				Header: "Inquiries Within Six Months",
				accessor: "InquiriesWithinSixMonths"
			},
			{
				Header: "Current Negative Trade",
				accessor: "CurrentNegativeTrade"
			},
			{
				Header: "High Risk Fraud Alert",
				accessor: "HighRiskFraudAlert.ParameterDesc"
			},
			{
				Header: "Collections",
				accessor: "Collections"
			},
			{
				Header: "Inquiries",
				accessor: "Inquiries"
			},
			{
				Header: "Trade First Opened",
				accessor: "TradeFirstOpened"
			},
			{
				Header: "Paid Trade",
				accessor: "PaidTrade"
			},
			{
				Header: "Credit Vision Risk Score",
				accessor: "CreditVisionRiskScore"
			}
		];

		this.emptyObject = {};

		this.validateProperties = [{ "ApplicantId": "Applicant" }];

		this.state = {
			model: {}
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("LOAN ORIGINATOR SYSTEM");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("TransUnion Credit History");
		}
	}

	ValueChanged = (name, value) => {
		this.setState(state => {
			var model = state.model;
			model[name] = value;

			return { model };
		});
	}

	Search = async () => {
		var { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/lmsapi/v1.0/LoanCreditHistory/Search", model, model, this.validateProperties);

		this.setState({ list: result || [] });
	}

	Clear = () => {
		this.setState({ model: {}, list: [] });
	}

	render() {
		const { model, list } = this.state;
		const { Disabled } = this.props;

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="TransUnion Credit History">
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												Required
												IsInvalid={model.IsApplicantId}
												Name="ApplicantId"
												LabelText="Applicant"
												Url="/losapi/v1.0/Applicant/GetByUniqueClientId"
												Method="POST"
												Parameter={this.emptyObject}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ApplicantName"
												Value={model.ApplicantId}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={3}>
											<GenericDateInput
												Utc
												Name="FromDate"
												LabelText="Between Date"
												LabelMd={4}
												IncludeTime={false}
												Value={model.FromDate ? Formatter.FormatDateUtc(model.FromDate) : null}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={2}>
											<GenericDateInput
												Utc
												Name="ToDate"
												LabelMd={0}
												IncludeTime={false}
												MinDate={model.FromDate}
												Value={model.ToDate ? Formatter.FormatDateUtc(model.ToDate) : null}
												ValueChanged={this.ValueChanged} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columns} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

TransUnionSummaryReport.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default TransUnionSummaryReport;