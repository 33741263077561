import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import { DeleteIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericCheckInput, GenericDateFilterInput, GenericDialog, GenericExpansionPanel, GenericGrid, GenericLabel, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle,GenericDialogActions } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { BankFileStatus, BankTransactionStatus, GridColumnType, MenuCodes, TransactionType } from "views/Constants/Constant.js";
import GridButton from "views/Components/GridButton";
import { DialogContent, DialogTitle } from "@material-ui/core";
import Button from "components/CustomButtons/Button.jsx";
class TransactionAdjustment extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			DateFilter: {
				BeginDate: DateHelper.GetDate(),
				EndDate: DateHelper.GetDate()
			},
			IsRefundAdjustmentStatus: false,
			IsUniqueDataSearch: false

		};

		this.state = {
			list: [],
			vModel: {},
			model: this.initialModel,
			isLoading: false,
			selected: null,
			isManualTransactionEntryMenu: this.props.MenuCode == MenuCodes.ManualTransactionEntry,
			cancelRequestModel: {
				SelectedTransfer: {},
				cancellationReason: null,
			 },
			 cancelDialog: false,
		};
		this.maxLength400 = { maxLength: 400 };



		this.renderItemAccount = {};

		this.parameterDestAcc = {};

		this.sortedList = [{ id: "ReferenceNumber", desc: true }];

		this.Columns = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: (row) => (
				  <div>
					 <GridButton
					   Disabled={ row.original.TransactionStatus !=  "Pending" }
					   tooltip="Cancel Transfer"
						Icon={DeleteIcon}
						OnClick={() => {
						  this.handleCancel(row.original);
						}}
					 />
				  </div>
				),
			 },
			{
				Header: "Reference Number",
				accessor: "ReferenceNumber"
			},
			{
				Header: "Transaction Date",
				accessor: "TransactionDate",
				type: GridColumnType.DateUtc
			},
			{
				Header: "Source Wallet",
				accessor: "SourceAccount.AccountNumber"
			},
			{
				Header: "Source",
				accessor: "SourceOwnerName"
			},
			{
				Header: "Destination Wallet",
				accessor: "DestinationAccount.AccountNumber"
			},
			{
				Header: "Destination",
				accessor: "DestinationOwnerName"
			},
			{
				Header: "Transaction Type",
				accessor: "TransactionType.ParameterDesc"
			},
			{
				Header: "Transaction Code",
				accessor: "TransactionCode.TxnName"
			},
			{
				Header: "Amount",
				accessor: "TransactionAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},

			{
				Header: "Description",
				accessor: "Description"
			},
			{
				Header: "User Name",
				accessor: "UserName"
			}
		];
	}

	componentDidMount() {
		this.props.setAppCenterTitle("WALLET");

		if (this.state.isManualTransactionEntryMenu) {
			this.props.setAppLeftTitle("Manual Transaction Entry");
		} else {
			this.props.setAppLeftTitle("Transaction Adjustment");
		}
	}

	HandleChange = (name, value, data) => {
		const model = { ...this.state.model };
		model[name] = value;

		if (name == "ReferenceNumber") {
			model.IsUniqueDataSearch = value.length > 0;
		}
		if (name == "SourceAccountId") {
			model.sourceAccountName = data ? data.AccountName + " " + data.AccountNumber : "";
		}
		if (name == "DestinationAccountId") {
			model.destinationAccountName = data ? data.AccountName + " " + data.AccountNumber : "";
		}
		if (name == "TransactionTypeId") {
			model.transactionTypeName = data ? data.ParameterValue : "";
		}

		this.setState({ model });
	}

	HandleSelectModel = (model, index) => {
		this.setState({ model, selected: index });
		this.HandleGetList();
	}

	HandleClear = () => {
		this.setState({ model: this.initialModel });
	}

	showLoading = () => {
		this.setState({ alert: null, isLoading: true });
	 };
	 hideLoading = () => {
		this.setState({ alert: null, isLoading: false });
	 };
	 hideDialog = () => {
		this.setState({ cancelDialog: false, remindDialog: false });
	 };

	handleCancel = (row) => {
		if (!this.state.isBackOffice) {
			var cancelRequestModel = { ...this.state.cancelRequestModel };
			const selectTransfer = row;
			cancelRequestModel.SelectedTransfer = selectTransfer;
			if (cancelRequestModel.SelectedTransfer.TransactionStatus == "Pending")
			  this.setState({ cancelRequestModel, cancelDialog: true });
			else
			  this.ShowMessage(
				 "warning",
				 "Warning",
				 "Selected transaction status is not Pending !!!"
			  );
		 }
	}

	IsValid = () => {
		var vmodel = { ...this.state.model };

		if (!vmodel.IsUniqueDataSearch && typeof vmodel.DateFilter.BeginDate != "object" && vmodel.DateFilter.BeginDate != "" && vmodel.DateFilter.BeginDate != null) {
			this.props.showMessage("warning", "Please fill required fields!", "Begin Date is invalid");
			return false;
		}

		if (!vmodel.IsUniqueDataSearch && typeof vmodel.DateFilter.EndDate != "object" && vmodel.DateFilter.EndDate != "" && vmodel.DateFilter.EndDate != null) {
			this.props.showMessage("warning", "Please fill required fields!", "End Date is invalid");
			return false;
		}

		return true;
	}

	HandleGetList = () => {

		if (!this.IsValid())
			return;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/Transaction/GetAllTransactionAdjustment",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					this.setState({ list: responseData.Item });
				} else {
					this.setState({ list: [] });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	HandleSubmit = (workflowId, after, accessToken) => {
		if (!this.Validate())
			return;

		var temp = { ...this.state.model };
		temp.IsManualTransactionEntry = this.state.isManualTransactionEntryMenu;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/Transaction/InsertTransactionAdjustment",
			temp,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessage("success", "Success", "Operation is successfully!");
				this.HandleClear();
				this.HandleGetList();

				if (after) after();
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			},
			workflowId,
			accessToken
		);
	}

	Validate = () => {
		const { model } = this.state;
		const vModel = this.state.vModel;

		if (!this.state.isManualTransactionEntryMenu) {
			if (!model.SourceAccountId) {
				vModel.SourceAccountId = true;
				this.ShowMessage("warning", "Warning", "Settlement Wallet is not selected!");
				return;
			} else { vModel.SourceAccountId = false; }
		}
		if (!model.ProgramId) {
			vModel.ProgramId = true;
			this.ShowMessage("warning", "Warning", "Program is not selected!");
			return;
		} else { vModel.ProgramId = false; }
		if (!model.ToClientId) {
			vModel.ToClientId = true;
			this.ShowMessage("warning", "Warning", "Client is not selected!");
			return;
		} else { vModel.ToClientId = false };
		if (!model.DestinationAccountId) {
			vModel.DestinationAccountId = true;
			this.ShowMessage("warning", "Warning", "Client Wallet is not selected!");
			return;
		} else { vModel.DestinationAccountId = false };
		if (!model.TransactionTypeId) {
			vModel.TransactionTypeId = true;
			this.ShowMessage("warning", "Warning", "Transaction Type is not selected!");
			return;
		} else { vModel.TransactionTypeId = false; }
		if (model.Amount == null) {
			vModel.Amount = true;
			this.ShowMessage("warning", "Warning", "Amount is not selected!");
			return;
		} else { vModel.Amount = false; }
		this.setState({ vModel });
		return true;
	}

	RenderItemAccount = (d) => {
		this.renderItemAccount = `${d?.BankingAccountType?.ParameterDesc || ""} - ${d.AccountName} - ${d?.AccountNumber || ""}`;
		return this.renderItemAccount;
	}

	GetDestinationAccountParameter = () => {
		this.parameterDestAcc.UniqueClientId = this.state.model.ToClientId;
		this.parameterDestAcc.ProgramId = this.state.model.ProgramId;
		return this.parameterDestAcc;
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: "" })} OnConfirm={onConfirm} />
		});
	}
	handleChangeCancelModel = (name, newValue) => {
		const cancelRequestModel = { ...this.state.cancelRequestModel };
		cancelRequestModel.cancellationReason = newValue;
		this.setState({ cancelRequestModel });
	 };

	 confirmCancel = () => {
		var cancelRequestModel = { ...this.state.cancelRequestModel };
		if (
		  cancelRequestModel.cancellationReason != null &&
		  cancelRequestModel.cancellationReason != ""
		) {
		  this.hideDialog();
		  this.showLoading();
  
		  Proxy.POST(
			 "/bankapi/v1.0/Transaction/CancelManuelEntryTransactionIsCheque",
			 cancelRequestModel,
			 (responseData) => {
				if (!responseData.IsSucceeded) {
				  this.setState({ IsConfirm: false });
				  this.ShowMessage("error", "Error", responseData.ErrorDescription);
				  return;
				}
				this.ShowMessage(
				  "success",
				  "Success",
				  "Transfer cancelled successfuly"
				);
				this.HandleClear();
				this.HandleGetList();
			 },
			 (error) => {
				this.ShowMessage("error", "Error", error);
			 }
		  );
		} else {
		  this.ShowMessage(
			 "warning",
			 "Warning",
			 "Cancellation Reason can not be null !!!"
		  );
		}
	 };

	getTransactionMessage = () => {
		if (!this.state ||
			!this.state.model ||
			!this.state.model.transactionTypeName ||
			!this.state.model.Amount ||
			!this.state.model.destinationAccountName ||
			(!this.state.isManualTransactionEntryMenu &&
				!this.state.model.sourceAccountName)) {
			return;
		}
		var model = { ...this.state.model };
		let amount = model.Amount.toFixed(2); //300000000.00
		if (this.state.isManualTransactionEntryMenu) {

			if (this.state.model.transactionTypeName == TransactionType.Credit) {
				return `The amount of $${amount} will be deposited to Wallet ${model.destinationAccountName}.`
			}
			else {
				return `The amount of $${amount} will be withdrawn from Wallet ${model.destinationAccountName}.`
			}
		}
		else {
			if (this.state.model.transactionTypeName == TransactionType.Credit) {
				return `The amount of $${amount} will be deposited from Wallet ${model.sourceAccountName} to ${model.destinationAccountName}`;
			}
			else {
				return `The amount of $${amount} will be deposited from Wallet ${model.destinationAccountName} to ${model.sourceAccountName}`;
			}
		}
	}
	render() {
		const { classes, Disabled } = this.props;
		const { alert, model, list, isLoading, vModel,cancelDialog ,cancelRequestModel} = this.state;
		return (
			<div>
				<LoadingComponent Show={isLoading} />
				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.HandleGetList, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled || model === this.initialModel },
					{ Code: "Submit", OnClick: this.HandleSubmit, Disabled: Disabled, ModelFunction: () => model, FillDataFromModel: model => this.setState({ model }), ValidationFunction: this.Validate },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
					<GenericDialog open={cancelDialog} maxWidth="sm" fullWidth>
					<DialogTitle>
					<b>
						<h6>Cancel Transfer </h6>
					</b>
					</DialogTitle>
					<DialogContent>
					<GenericTextInput
						inputProps={this.maxLength400}
						Name="cancellationReason"
						LabelText="Cancellation Reason"
						Value={
							cancelRequestModel != null
							? cancelRequestModel.cancellationReason != null
								? cancelRequestModel.cancellationReason
								: ""
							: ""
						}
						ValueChanged={this.handleChangeCancelModel}
					/>
					</DialogContent>
					<GenericDialogActions>
					<Button size="sm" onClick={this.confirmCancel}>
						Confirm
					</Button>
					<Button
						size="sm"
						onClick={() =>
							this.setState({ cancelDialog: false, cancelRequestModel: {} })
						}
					>
						No
					</Button>
					</GenericDialogActions>
				</GenericDialog>
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<GenericExpansionPanel Title="Transaction">
								<CardBody>
									<GridContainer>
										{!this.state.isManualTransactionEntryMenu && (
											<GridItem xs={3}>
												<GenericSelectInput
													Name="SourceAccountId"
													LabelMd={4}
													LabelText="Settlement Wallet"
													Method="GET"
													Url="/bankapi/v1.0/InstutionParameter/GetAllSettlementInstutionParameter"
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={d => `${d.AccountNumber} - ${d.AccountName} - ${d.SettlementAccountType}`}
													Value={model.SourceAccountId}
													ValueChanged={this.HandleChange}
													Required={!model.IsUniqueDataSearch && !this.state.isManualTransactionEntryMenu == true ? true : false}
													IsInvalid={vModel.SourceAccountId}
													Disabled={Disabled}
												/>
											</GridItem>
										)}
										<GridItem xs={3}>
											<GenericSelectInput
												Name="ProgramId"
												LabelMd={4}
												LabelText="Program"
												Method="GET"
												Url="/bankapi/v1.0/Bank/ValidBankProgramsForAdjustment"
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Description"
												Value={model.ProgramId}
												ValueChanged={this.HandleChange}
												Required={!model.IsUniqueDataSearch}
												IsInvalid={vModel.ProgramId}
												Disabled={Disabled}
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												Name="ToClientId"
												LabelMd={4}
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={{}}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
												Value={model.ToClientId}
												ValueChanged={this.HandleChange}
												Required={!model.IsUniqueDataSearch && model.ProgramId != null ? true : false}
												IsInvalid={vModel.ToClientId}
												Disabled={Disabled}
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												key={"DestinationAccountId_" + [model.ProgramId, model.ToClientId]}
												Name="DestinationAccountId"
												LabelMd={3}
												LabelText="Client Wallet"
												Method="POST"
												Url={"/bankapi/v1.0/Account/GetAllAccountByProgram"}
												Parameter={this.GetDestinationAccountParameter()}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={this.RenderItemAccount}
												Value={model.DestinationAccountId}
												ValueChanged={this.HandleChange}
												Required={!model.IsUniqueDataSearch && model.ToClientId != null ? true : false}
												IsInvalid={vModel.DestinationAccountId}
												Disabled={Disabled}
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												Name="TransactionTypeId"
												LabelMd={4}
												LabelText="Transaction Type"
												Method="GET"
												Url="/bankapi/v1.0/Transaction/GetAllFinancialTransactionType"
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ParameterDesc"
												Value={model.TransactionTypeId}
												ValueChanged={this.HandleChange}
												Required={!model.IsUniqueDataSearch}
												IsInvalid={vModel.TransactionTypeId}
												Disabled={Disabled}
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericNumberInput
												Name="Amount"
												LabelMd={4}
												LabelText="Amount"
												Value={model.Amount}
												ValueChanged={this.HandleChange}
												MaxLength={10}
												Prefix="$"
												Required={!model.IsUniqueDataSearch}
												IsInvalid={vModel.Amount}
												Disabled={Disabled}
											/>
										</GridItem>
										<GridItem xs={1} style={{ marginLeft: 2, marginTop: 12 }}>
											<GenericLabel FontSize="12px" TextColor="Black" Text="Description" />
										</GridItem>
										<GridItem xs={5} style={{ marginLeft: -3 }}>
											<GenericTextInput
												Name="Description"
												LabelMd={0}
												Value={model.Description}
												ValueChanged={this.HandleChange}
												MultiLine={true}
												Disabled={Disabled}
												RowCount={2} />
										</GridItem>
										<GridItem xs={3}>
											<GenericDateFilterInput
												Name="DateFilter"
												LabelText="Date Filter"
												Model={model.DateFilter}
												BeginDate={model.BeginDate}
												EndDate={model.EndDate}
												ValueChanged={this.HandleChange}
												IncludeTime={false}
												Disabled={Disabled}
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericNumberInput
												NoFormatting Name="ReferenceNumber"
												LabelText="Reference Number"
												Value={model.ReferenceNumber}
												ValueChanged={this.HandleChange}
												Disabled={Disabled} />
										</GridItem>
										<GridItem xs={3}>
											{this.state.isManualTransactionEntryMenu && (
												<GenericCheckInput
													Disabled={Disabled}
													Name="IsCheque"
													LabelText="Is Cheque"
													Value={model.IsCheque || false}
													ValueChanged={this.HandleChange}
												/>
											)}
										</GridItem>
										<GridItem xs={12} style={{ marginTop: 25 }}>
											<b style={{ marginLeft: 8, marginTop: 25 }}> {this.getTransactionMessage()}</b>
										</GridItem>
									</GridContainer>
								</CardBody>
							</GenericExpansionPanel>
						</Card>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text="Transaction List" />
							</CardHeader>
							<CardBody>
								<GridContainer spacing={16}>
									<GridItem xs={12}>
										<GenericGrid
											Data={list}
											Columns={this.Columns}
											Sorted={this.sortedList} />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
			
		);

	}
}

TransactionAdjustment.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withArtifex(TransactionAdjustment, {});

