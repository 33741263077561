import extendedFormsStyle from "./extendedFormsStyle";

const parameterComponentStyle = {
	...extendedFormsStyle,
	selectLabel: {
		color: "#AAAAAA !important"
	}
};

export default parameterComponentStyle;
