import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericCheckInput, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { GridColumnType } from "views/Constants/Constant";
import kycManualPhotoDefinitionStyle from "assets/jss/material-dashboard-pro-react/views/kycManualPhotoDefinitionStyle.jsx";
import GenericCardMedia from "views/Components/Generic/GenericCardMedia.jsx";
import ClientHelper from "core/ClientHelper";


const galerydefault = require("assets/img/galerydefault-image.png");

class ProductDefinition extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			UniqueClientId: ClientHelper.IsClient()
			? ClientHelper.GetClientRowId()
			: 0,
			PlasticTypeId: 0,
			ProductTypeId: 0,
			ProductBrandId: 0
		};

		this.state = {
			model: { ...this.initialModel },
			vModel: {},
			list: [],
			fileInfoModel: {},
			isEdit: false,
			isLoading: false,
			index: -1
		};

		this.emptyObject = {};

		this.renderItemUniqueClient = {};

		this.parameterProductBrandId = { ParameterCode: "Brand" };
		this.parameterPhysicalTypeId = { ParameterCode: "CardPhysicalType" };

		this.parameterClientProfile = {};
		this.parameterDCBankProfile = {};
		this.isBackOffice = ClientHelper.IsBackOffice();

		this.maxLength26 = { maxLength: 26 };

		this.Columns = [
			{
				Header: "Product Name",
				accessor: "ProductName"
			},
			{
				Header: "Client",
				accessor: "UniqueClient.ClientName"
			},
			{
				Header: "Product Type",
				accessor: "ProductType.ParameterDesc"
			},
			{
				Header: "Bin Range Profile",
				accessor: "BinRangeProfile.ProfileName"
			},
			{
				Header: "Plastic Type",
				accessor: "PlasticType.PlasticName"
			},
			{
				Header: "Product Brand",
				accessor: "ProductBrand.ParameterDesc"
			},
			{
				Header: "Is No Name Product",
				accessor: "IsNoNameProduct",
				Cell: row => (
					row.value ? "Yes" : "No"
				)
			},
			{
				Header: "No Name Emboss Name",
				accessor: "NoNameEmbossName"
			},
			{
				Header: "Interest Profile",
				accessor: "InterestProfile.ProfileName"
			},
			{
				Header: "Restriction Profile",
				accessor: "RestrictionProfile.ProfileName"
			},
			// {
			// 	Header: "Client Restriction Profile",
			// 	accessor: "ClientRestrictionProfile.ProfileName"
			// },
			{
				Header: "Limit Profile",
				accessor: "LimitProfile.ProfileName"
			},
			{
				Header: "Client Limit Profile",
				accessor: "ClientLimitProfile.ProfileName"
			},
			{
				Header: "Fee Profile",
				accessor: "FeeProfile.ProfileName"
			},
			// {
			// 	Header: "Client Fee Profile",
			// 	accessor: "ClientFeeProfile.ProfileName"
			// },
			{
				Header: "Card Fee Profile",
				accessor: "CardFeeProfile.ProfileName"
			},
			// {
			// 	Header: "Client Card Fee Profile",
			// 	accessor: "ClientCardFeeProfile.ProfileName"
			// },
			{
				Header: "Auto Renew Card",
				accessor: "AutoRenewCard",
				Cell: row => (
					row.value ? "Yes" : "No"
				)
			},
			{
				Header: "Auto Renewal Period (Month)",
				accessor: "AutoRenewalPeriodMonth"
			},
		];
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Product Definition");
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
	}
	handleChange = async (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		const { ExecuteApiPost } = this.props;

		var responseData = await ExecuteApiPost("/prepaidapi/v1.0/Product/GetProductImage", { PlasticTypeId: model.PlasticTypeId, UniqueClientId: model.UniqueClientId });
		var fileInfoModel = { ...this.state.fileInfoModel }

		if (responseData == null || responseData == undefined) {
			fileInfoModel.StoredImage = galerydefault;
			this.setState({ model });
		}
		if (responseData != null || responseData != undefined) {
			var cardNumber = document.getElementById("cardNumber").textContent = "5032 9334 3764 9846";
    cardNumber = document.getElementById("cardNumber").style.position = "absolute";
    cardNumber = document.getElementById("cardNumber").style.left = "20px";
    cardNumber = document.getElementById("cardNumber").style.bottom = "55px";
    cardNumber = document.getElementById("cardNumber").style.color = "white";
    cardNumber = document.getElementById("cardNumber").style.fontSize = "15px";

    var cardName = document.getElementById("cardName").textContent = "Alexander Theodore";
    cardName = document.getElementById("cardName").style.position = "absolute";
    cardName = document.getElementById("cardName").style.left = "20px";
    cardName = document.getElementById("cardName").style.bottom = "20px";
    cardName = document.getElementById("cardName").style.color = "white";
    cardName = document.getElementById("cardName").style.fontSize = "15px";

    var cardDate = document.getElementById("cardDate").textContent = "09/19";
    cardDate = document.getElementById("cardDate").style.position = "absolute";
    cardDate = document.getElementById("cardDate").style.left = "160px";
    cardDate = document.getElementById("cardDate").style.bottom = "30px";
    cardDate = document.getElementById("cardDate").style.color = "white";
    cardDate = document.getElementById("cardDate").style.fontSize = "15px";

    if (responseData.CardChipTypeId == 11100) {
      var documentry = document.getElementById("cardChipType").style.width = "50px";
      documentry = document.getElementById("cardChipType").style.height = "40px";
      documentry = document.getElementById("cardChipType").style.left = "20px";
      documentry = document.getElementById("cardChipType").style.bottom = "80px";
      documentry = document.getElementById("cardChipType").style.position = "absolute";
      documentry = document.getElementById("cardChipType").style.borderRadius = "5px";
      documentry = document.getElementById("cardChipType").style.backgroundColor = "white";
    }
	 else {
      var documentry = document.getElementById("cardChipType").style.width = "";
      documentry = document.getElementById("cardChipType").style.height = "";
      documentry = document.getElementById("cardChipType").style.left = "";
      documentry = document.getElementById("cardChipType").style.bottom = "";
      documentry = document.getElementById("cardChipType").style.position = "";
      documentry = document.getElementById("cardChipType").style.borderRadius = "";
      documentry = document.getElementById("cardChipType").style.backgroundColor = "";
    }

    if (responseData.CardBrandId == 3400) {
      var documentry2 = document.getElementById("cardBrandId").style.width = "80px";
      documentry2 = document.getElementById("cardBrandId").style.height = "37px";
      documentry2 = document.getElementById("cardBrandId").style.left = "235px";
      documentry2 = document.getElementById("cardBrandId").style.bottom = "15px";
      documentry2 = document.getElementById("cardBrandId").style.position = "absolute";
      documentry2 = document.getElementById("cardBrandId").style.borderRadius = "10px";
      documentry2 = document.getElementById("cardBrandId").style.backgroundColor = "white";
    }
	 else if (responseData.CardBrandId == 3403) {
      var documentry2 = document.getElementById("cardBrandId").style.width = "48px";
      documentry2 = document.getElementById("cardBrandId").style.height = "48px";
      documentry2 = document.getElementById("cardBrandId").style.left = "265px";
      documentry2 = document.getElementById("cardBrandId").style.bottom = "15px";
      documentry2 = document.getElementById("cardBrandId").style.position = "absolute";
      documentry2 = document.getElementById("cardBrandId").style.borderRadius = "10px";
      documentry2 = document.getElementById("cardBrandId").style.backgroundColor = "white";
    }
	 else if (responseData.CardBrandId == 3401) {
      var documentry2 = document.getElementById("cardBrandId").style.width = "80px";
      documentry2 = document.getElementById("cardBrandId").style.height = "55px";
      documentry2 = document.getElementById("cardBrandId").style.left = "235px";
      documentry2 = document.getElementById("cardBrandId").style.bottom = "15px";
      documentry2 = document.getElementById("cardBrandId").style.position = "absolute";
      documentry2 = document.getElementById("cardBrandId").style.borderRadius = "30px";
      documentry2 = document.getElementById("cardBrandId").style.backgroundColor = "white";
    }
	 else {
      var documentry2 = document.getElementById("cardBrandId").style.width = "";
      documentry2 = document.getElementById("cardBrandId").style.height = "";
      documentry2 = document.getElementById("cardBrandId").style.left = "";
      documentry2 = document.getElementById("cardBrandId").style.bottom = "";
      documentry2 = document.getElementById("cardBrandId").style.position = "";
      documentry2 = document.getElementById("cardBrandId").style.borderRadius = "";
      documentry2 = document.getElementById("cardBrandId").style.backgroundColor = "";
    }

    if (responseData.CardContactlessColorTypeId == 11102) {
      var documentry3 = document.getElementById("cardContactlessColorType").style.position = "absolute";
      documentry3 = document.getElementById("cardContactlessColorType").style.width = "40px";
      documentry3 = document.getElementById("cardContactlessColorType").style.height = "40px";
      documentry3 = document.getElementById("cardContactlessColorType").style.left = "275px";
      documentry3 = document.getElementById("cardContactlessColorType").style.bottom = "100px";
      documentry3 = document.getElementById("cardContactlessColorType").style.borderRadius = "40px";
      documentry3 = document.getElementById("cardContactlessColorType").style.backgroundColor = "black";
    }
	 else if (responseData.CardContactlessColorTypeId == 11104) {
      var documentry3 = document.getElementById("cardContactlessColorType").style.position = "absolute";
      documentry3 = document.getElementById("cardContactlessColorType").style.width = "40px";
      documentry3 = document.getElementById("cardContactlessColorType").style.height = "40px";
      documentry3 = document.getElementById("cardContactlessColorType").style.left = "275px";
      documentry3 = document.getElementById("cardContactlessColorType").style.bottom = "100px";
      documentry3 = document.getElementById("cardContactlessColorType").style.borderRadius = "40px";
      documentry3 = document.getElementById("cardContactlessColorType").style.backgroundColor = "white";
    }
	 else {
      var documentry3 = document.getElementById("cardContactlessColorType").style.position = "";
      documentry3 = document.getElementById("cardContactlessColorType").style.width = "";
      documentry3 = document.getElementById("cardContactlessColorType").style.height = "";
      documentry3 = document.getElementById("cardContactlessColorType").style.left = "";
      documentry3 = document.getElementById("cardContactlessColorType").style.bottom = "";
      documentry3 = document.getElementById("cardContactlessColorType").style.borderRadius = "";
      documentry3 = document.getElementById("cardContactlessColorType").style.backgroundColor = "";
    }

    if(responseData.BackgroundColorId == 11105 || responseData.BackgroundColorId == 11109 || responseData.BackgroundColorId == 11107){
      document.getElementById("cardName").style.color = "black";
      document.getElementById("cardNumber").style.color = "black";
      document.getElementById("cardDate").style.color = "black";
    }
	 else if(responseData.BackgroundColorId == 11106 || responseData.BackgroundColorId == 11110 || responseData.BackgroundColorId == 11108){
      document.getElementById("cardName").style.color = "white";
      document.getElementById("cardNumber").style.color = "white";
      document.getElementById("cardDate").style.color = "white";
    }
			this.setState({ model, fileInfoModel: responseData });
		}


	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		if (name == "UniqueClientId") {
			model.PlasticTypeId = 0;
		}
		if (name == "ProductBrandId" && newValue == null) {
			model.ProductBrandId = 0;
		}
		if (name == "ProductTypeId" && newValue == null) {
			model.ProductTypeId = 0;
		}
		if (!model.AutoRenewCard) {
			model.AutoRenewalPeriodMonth = null;
		}

		if (!model.IsNoNameProduct) {
			model.NoNameEmbossName = null;
		}

		this.setState({ model });
	}

	HandleClear = () => {
		this.setState({ model: { ...this.initialModel }, fileInfoModel: {}, vModel: {}, isEdit: false, index: -1 });

		var cardNumber = document.getElementById("cardNumber").textContent = "";
			cardNumber = document.getElementById("cardNumber").style.position = "";
			cardNumber = document.getElementById("cardNumber").style.left = "";
			cardNumber = document.getElementById("cardNumber").style.bottom = "";
			cardNumber = document.getElementById("cardNumber").style.color = "";
			cardNumber = document.getElementById("cardNumber").style.fontSize = "";

		var cardName = document.getElementById("cardName").textContent = "";
			cardName = document.getElementById("cardName").style.position = "";
			cardName = document.getElementById("cardName").style.left = "";
			cardName = document.getElementById("cardName").style.bottom = "";
			cardName = document.getElementById("cardName").style.color = "";
			cardName = document.getElementById("cardName").style.fontSize = "";

		var cardDate = document.getElementById("cardDate").textContent = "";
			cardDate = document.getElementById("cardDate").style.position = "";
			cardDate = document.getElementById("cardDate").style.left = "";
			cardDate = document.getElementById("cardDate").style.bottom = "";
			cardDate = document.getElementById("cardDate").style.color = "";
			cardDate = document.getElementById("cardDate").style.fontSize = "";

		var documentry = document.getElementById("cardChipType").style.width = "";
			documentry = document.getElementById("cardChipType").style.height = "";
			documentry = document.getElementById("cardChipType").style.left = "";
			documentry = document.getElementById("cardChipType").style.bottom = "";
			documentry = document.getElementById("cardChipType").style.position = "";
			documentry = document.getElementById("cardChipType").style.borderRadius = "";
			documentry = document.getElementById("cardChipType").style.backgroundColor = "";
		
		var documentry2 = document.getElementById("cardBrandId").style.width = "";
			documentry2 = document.getElementById("cardBrandId").style.height = "";
			documentry2 = document.getElementById("cardBrandId").style.left = "";
			documentry2 = document.getElementById("cardBrandId").style.bottom = "";
			documentry2 = document.getElementById("cardBrandId").style.position = "";
			documentry2 = document.getElementById("cardBrandId").style.borderRadius = "";
			documentry2 = document.getElementById("cardBrandId").style.backgroundColor = "";

		var documentry3 = document.getElementById("cardContactlessColorType").style.position = "";
			documentry3 = document.getElementById("cardContactlessColorType").style.width = "";
			documentry3 = document.getElementById("cardContactlessColorType").style.height = "";
			documentry3 = document.getElementById("cardContactlessColorType").style.left = "";
			documentry3 = document.getElementById("cardContactlessColorType").style.bottom = "";
			documentry3 = document.getElementById("cardContactlessColorType").style.borderRadius = "";
			documentry3 = document.getElementById("cardContactlessColorType").style.backgroundColor = "";
	}

	HandleSearch = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/Product/Search",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", responseData.ErrorDescription);
					return;
				}

				if (responseData.Item !== null) {
					this.setState({ list: responseData.Item });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	HandleSubmit = () => {
		if (!this.Validate()) {
			return;
		}

		this.setState({ isLoading: true });
		Proxy.POST("/prepaidapi/v1.0/Product/Insert",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", responseData.ErrorDescription);
					return;
				}
				this.setState({ hasChandedFromList: false });
				this.HandleSearch();
				this.ShowMessage("success", "Success", "Operation successfully completed!");
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);

		this.HandleClear();
	}

	HandleUpdate = () => {
		if (!this.Validate()) {
			return;
		}

		this.setState({ isLoading: true });
		Proxy.POST("/prepaidapi/v1.0/Product/Update",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", responseData.ErrorDescription);
					return;
				}
				this.setState({ hasChandedFromList: false });
				this.HandleSearch();
				this.ShowMessage("success", "Success", "Operation successfully completed!");
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	HandleDelete = () => {
		this.setState({ isLoading: true });
		Proxy.GET(
			"/prepaidapi/v1.0/Product/Delete/Id?Id=" + this.state.model.Id,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.HandleSearch();
				this.setState({ model: { ...this.initialModel }, index: -1 });
				this.ShowMessage("success", "Operation successfully completed!");
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while deleting data", error.message);
			}
		);
	}

	Validate = () => {
		const { model, vModel } = this.state;
		var Messages = [];
		if (!model.UniqueClientId) {
			Messages.push("Client Name is empty");
			vModel.UniqueClientId = true;
		}
		else
			vModel.UniqueClientId = false;

		if (!model.ProductName) {
			Messages.push("Product Name is empty");
			vModel.ProductName = true;
		}
		else
			vModel.ProductName = false;

		if (!model.BinRangeProfileId) {
			Messages.push("Bin Range Profile not selected");
			vModel.BinRangeProfileId = true;
		}
		else
			vModel.BinRangeProfileId = false;

		if (!model.PlasticTypeId || model.PlasticTypeId == 0) {
			Messages.push("Plastic Type not selected");
			vModel.PlasticTypeId = true;
		}
		else
			vModel.PlasticTypeId = false;

		if (!model.ProductBrandId || model.ProductBrandId == 0) {
			Messages.push("Product Brand not selected");
			vModel.ProductBrandId = true;
		}
		else
			vModel.ProductBrandId = false;

		if (!model.ProductTypeId || model.ProductTypeId == 0) {
			Messages.push("Product Type not selected");
			vModel.ProductTypeId = true;
		}
		else
			vModel.ProductTypeId = false;

		if (model.AutoRenewCard && !model.AutoRenewalPeriodMonth) {
			Messages.push("Auto Renewal Period (Month) is empty");
			vModel.AutoRenewCard = true;
		}
		else
			vModel.AutoRenewCard = false;


		if (model.IsNoNameProduct && !model.NoNameEmbossName) {
			Messages.push("No Name Emboss Name is empty");
			vModel.IsNoNameProduct = true;
		}
		else
			vModel.IsNoNameProduct = false;

		if (!model.CardPhysicalTypeId || model.CardPhysicalTypeId == 0) {
			Messages.push("Card Physical Type not selected");
			vModel.CardPhysicalTypeId = true;
		}
		else
			vModel.CardPhysicalTypeId = false;

		this.setState({ vModel });
		if (Messages.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", Messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		else
			return true;
	}

	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}

	RowSelected = (index) => {
		this.setState({ isEdit: true, model: this.state.list[index], index });
	}

	GetDCBankProfileParameter = () => {
		this.parameterClientProfile.IsDefaultProfile = true;
		this.parameterClientProfile.UniqueClientId = -1;
		return this.parameterClientProfile;
	}

	GetClientProfileParameter = () => {
		this.parameterDCBankProfile.IsDefaultProfile = true;
		this.parameterDCBankProfile.UniqueClientId = -1;
		return this.parameterDCBankProfile;
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: null })} OnConfirm={onConfirm} />
		});
	}

	render() {
		const { classes, Disabled, ExecuteApiPost } = this.props;
		const { alert, model, vModel, list, isLoading, index, fileInfoModel } = this.state;

		return (
			<>

				<LoadingComponent Show={isLoading} />

				{alert}

				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
					{ Code: "Submit", OnClick: this.HandleSubmit, Disabled: Disabled || model.Id > 0, ModelFunction: () => model, FillDataFromModel: model => this.setState({ model }), ValidationFunction: this.Validate },
					{ Code: "Update", OnClick: this.HandleUpdate, Disabled: Disabled || model.Id === 0, ModelFunction: () => model, FillDataFromModel: model => this.setState({ model }), ValidationFunction: this.Validate },
					{ Code: "Delete", OnClick: this.HandleDelete, Disabled: Disabled || model.Id === 0, ModelFunction: () => model, FillDataFromModel: model => this.setState({ model }), ValidationFunction: this.Validate },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Parameters" >
									<GridContainer>
										<GridItem xs={3}>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Client Name"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={{}}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={this.RenderItemUniqueClient}
												Value={model.UniqueClientId}
												ValueChanged={this.HandleChange}
												Required
												IsInvalid={vModel.UniqueClientId}
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												key={model.UniqueClientId}
												Name="PlasticTypeId"
												LabelText="Plastic Type"
												Method="POST"
												Url="/prepaidapi/v1.0/PlasticType/GetAllApproved"
												DataRoot="Item"
												Parameter={
													{ UniqueClientId: model.UniqueClientId }
												}
												KeyValueMember="Id"
												TextValueMember="Name"
												Value={model.PlasticTypeId}
												ValueChanged={this.handleChange}
												Required
												IsInvalid={vModel.PlasticTypeId}
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												Name="LimitProfileId"
												LabelText="Limit Profile"
												Method="POST"
												Url="/prepaidapi/v1.0/CardTransactionLimitProfile/SearchForComponent"
												Parameter={this.GetDCBankProfileParameter()}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ProfileName"
												Value={model.LimitProfileId}
												ValueChanged={this.HandleChange} />
										</GridItem>

										<GridItem xs={3}>
											<GenericSelectInput
												Name="CardFeeProfileId"
												LabelText="Card Fee Profile"
												Method="POST"
												Url="/prepaidapi/v1.0/CardFeeProfile/SearchForComponent"
												Parameter={this.GetDCBankProfileParameter()}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ProfileName"
												Value={model.CardFeeProfileId}
												ValueChanged={this.HandleChange} />
										</GridItem>
										<GridItem xs={3}>
											<GenericTextInput
												Name="ProductName"
												IsText
												LabelText="Product Name"
												Value={model.ProductName}
												ValueChanged={this.HandleChange}
												Required
												IsInvalid={vModel.ProductName}
											/>

										</GridItem>
										<GridItem xs={3}>
											<ParameterComponent
												Name="ProductTypeId"
												LabelText="Product Type"
												ParameterCode="CardType"
												Value={model.ProductTypeId}
												ValueChanged={this.HandleChange}
												Required
												IsInvalid={vModel.ProductTypeId}
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												Name="FeeProfileId"
												LabelText="Fee Profile"
												Method="POST"
												Url="/prepaidapi/v1.0/CardTransactionFeeProfile/SearchForComponent"
												Parameter={this.GetDCBankProfileParameter()}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ProfileName"
												Value={model.FeeProfileId}
												ValueChanged={this.HandleChange} />
										</GridItem>

										<GridItem xs={3}>
											<GenericSelectInput
												Name="RestrictionProfileId"
												LabelText="Restriction Profile"
												Method="POST"
												Url="/prepaidapi/v1.0/ProductRestrictionProfile/SearchForComponent"
												Parameter={this.GetDCBankProfileParameter()}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ProfileName"
												Value={model.RestrictionProfileId}
												ValueChanged={this.HandleChange} />
										</GridItem>



										<GridItem xs={3}>
											<GenericSelectInput
												Name="ProductBrandId"
												LabelText="Product Brand"
												Method="POST"
												Url="/coreapi/v1.0/Parameter/Search"
												Parameter={this.parameterProductBrandId}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ParameterDesc"
												Value={model.ProductBrandId}
												ValueChanged={this.HandleChange}
												Required
												IsInvalid={vModel.ProductBrandId}
											/>

										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												Name="BinRangeProfileId"
												LabelText="Bin Range Profile"
												Method="POST"
												Url="/prepaidapi/v1.0/BinRangeProfile/SearchForComponent"
												Parameter={this.emptyObject}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ProfileName"
												Value={model.BinRangeProfileId}
												ValueChanged={this.HandleChange}
												Required
												IsInvalid={vModel.BinRangeProfileId}
											/>

										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												key={model.UniqueClientId}
												Name="InterestProfileId"
												LabelText="Interest Fee Profile"
												Method="POST"
												Url="/prepaidapi/v1.0/InterestProfile/GetAll"
												DataRoot="Item"
												Parameter={
													{ UniqueClientId: model.UniqueClientId }
												}
												KeyValueMember="Id"
												TextValueMember="ProfileName"
												Value={model.InterestProfileId}
												ValueChanged={this.HandleChange}
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												Name="CardPhysicalTypeId"
												LabelText="Card Physical Type"
												Method="POST"
												Url="/coreapi/v1.0/Parameter/Search"
												Parameter={this.parameterPhysicalTypeId}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ParameterDesc"
												Value={model.CardPhysicalTypeId}
												ValueChanged={this.HandleChange}
												Required
												IsInvalid={vModel.CardPhysicalTypeId}
											/>
										</GridItem>
										<GridItem xs={3} style={{ marginTop: 4, marginBottom: 4 }}>
											<GenericCheckInput
												Name="AutoRenewCard"
												LabelText="Auto Renew Card"
												Value={model.AutoRenewCard || false}
												ValueChanged={this.HandleChange}
												Disabled={Disabled} />
										</GridItem>
										<GridItem xs={3} style={{ marginTop: 4, marginBottom: 4 }}>
											<GenericCheckInput
												Name="IsNoNameProduct"
												LabelText="Is No Name Product"
												Value={model.IsNoNameProduct || false}
												ValueChanged={this.HandleChange}
												Disabled={Disabled} />
										</GridItem>
										<GridItem xs={3} style={{ marginTop: 4, marginBottom: 4 }}>
											<GenericCheckInput
												Name="IsCreateBlockedCard"
												LabelText="Is Create Blocked Card"
												Value={model.IsCreateBlockedCard || false}
												ValueChanged={this.HandleChange}
												Disabled={Disabled} />
										</GridItem>
										{model.AutoRenewCard &&
											<GridItem xs={3}>
												<GenericNumberInput
													Name="AutoRenewalPeriodMonth"
													LabelText="Auto Renewal Period"
													Value={model.AutoRenewalPeriodMonth}
													ValueChanged={this.HandleChange}
													MaxLength={2}
													NoFormatting={true}
													Required={model.AutoRenewCard}
													IsInvalid={vModel.AutoRenewalPeriodMonth} />
											</GridItem>
										}
										{model.IsNoNameProduct &&
											<GridItem xs={3}>
												<GenericTextInput
													Name="NoNameEmbossName"
													LabelText="Emboss Name"
													inputProps={this.maxLength26}
													IsText={true}
													Value={model.NoNameEmbossName}
													ValueChanged={this.HandleChange}
													Required={model.IsNoNameProduct}
													IsInvalid={vModel.NoNameEmbossName} />
											</GridItem>
										}
										<GridContainer>
											<GridItem xs={4}>
												<br></br>
												<br></br>

												<div id="parent" style={{ position: "relative",width: 323, height: 204 }}>
													<GenericCardMedia
														ExecuteApiPost={ExecuteApiPost}
														className={classes.imageProfile}
														ImageFileId={vModel.Id}
														Image={fileInfoModel.StoredImage}
														ImagePlaceholder={galerydefault}
													/>
													<div id="cardNumber"></div>
													<div id="cardName"></div>
													<div id="cardDate"></div>
													<div id="cardChipType"></div>
													<div id="cardBrandId"></div>
													<div id="cardContactlessColorType"></div>
												</div>
											</GridItem>
										</GridContainer>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Product List">
									<GridContainer>
										<GridItem xs={12}>
											<br />
											<GenericGrid
												Data={list}
												Columns={this.Columns}
												RowSelected={this.RowSelected}
												SelectedIndex={index}
												HideButton={true} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

			</>
		);
	}
}

ProductDefinition.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(ProductDefinition, kycManualPhotoDefinitionStyle);
