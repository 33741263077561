/*!

 =========================================================
 * Material Dashboard PRO React - v1.3.0 based on Material Dashboard PRO - v1.2.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2018 Creative Tim (https://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

// ##############################
// // // Variables - Styles that are used on more than one component
// #############################

const drawerWidth = window.LeftRightMargin || 100;

const drawerMiniWidth = 80;

const transition = {
	transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
};

const containerFluid = {
	paddingRight: "40px",
	paddingLeft: "40px",
	marginRight: "auto",
	marginLeft: "auto",
	"&:before,&:after": {
		display: "table",
		content: "\" \""
	},
	"&:after": {
		clear: "both"
	}
};

const container = {
	paddingRight: "15px",
	paddingLeft: "15px",
	marginRight: "auto",
	marginLeft: "auto",
	"@media (min-width: 768px)": {
		width: "750px"
	},
	"@media (min-width: 992px)": {
		width: "970px"
	},
	"@media (min-width: 1200px)": {
		width: "1170px"
	},
	"&:before,&:after": {
		display: "table",
		content: "\" \""
	},
	"&:after": {
		clear: "both"
	}
};

const boxShadow = {
	// boxShadow:"0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
};

const cardBoxShadow = {
	//boxShadow:"5px 5px 30px 0px rgba(0, 0, 0, 0.05), 4px 4px 25px 0px rgba(0, 0, 0, 0.06), 8px 8px 10px -5px rgba(0, 0, 0, 0.17)",
	border: "solid 1px #908e8e"
};

const card = {
	display: "inline-block",
	position: "relative",
	width: "100%",
	margin: "25px 0",
	boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
	borderRadius: "6px",
	color: "rgba(0, 0, 0, 1)",
	background: "#fff"
};

const defaultFont = {
	fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
	fontWeight: 300,
	lineHeight: "1.5em"
};

const primaryColor = window.PageMainColor;
const warningColor = "#457cf7";
const dangerColor = "#f44336";
const successColor = "#4caf50";
const infoColor = "#00acc1";
const roseColor = "#f57f29";
const whiteColor = "#fff";
const grayColor = "#999999";
const blackColor = "#000";

const hexToRgb = hex =>
	hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
		, (m, r, g, b) => "#" + r + r + g + g + b + b)
		.substring(1).match(/.{2}/g)
		.map(x => parseInt(x, 16));

const lightenDarkenColor = (col, amt) => {
	var usePound = false;

	if (col[0] == "#") {
		col = col.slice(1);
		usePound = true;
	}

	var num = parseInt(col, 16);

	var r = (num >> 16) + amt;

	if (r > 255) r = 255;
	else if (r < 0) r = 0;

	var b = ((num >> 8) & 0x00FF) + amt;

	if (b > 255) b = 255;
	else if (b < 0) b = 0;

	var g = (num & 0x0000FF) + amt;

	if (g > 255) g = 255;
	else if (g < 0) g = 0;

	return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
};

const primaryBoxShadow = {
	boxShadow:
		"0 12px 20px -10px rgba(" + hexToRgb(primaryColor) + ", 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(" + hexToRgb(primaryColor) + ", 0.2)"
};
const infoBoxShadow = {
	boxShadow:
		"0 12px 20px -10px rgba(0, 188, 212, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 188, 212, 0.2)"
};
const successBoxShadow = {
	boxShadow:
		"0 12px 20px -10px rgba(76, 175, 80, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2)"
};
const warningBoxShadow = {
	boxShadow:
		"0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 152, 0, 0.2)"
};
const dangerBoxShadow = {
	boxShadow:
		"0 12px 20px -10px rgba(244, 67, 54, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(244, 67, 54, 0.2)"
};
const roseBoxShadow = {
	boxShadow:
		"0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4)"
};
const whiteBoxShadow = {
	boxShadow:
		"10px 10px 10px rgba(0, 0, 0, 0.45)"
};
// old card headers
const orangeCardHeader = {
	background: "linear-gradient(60deg, " + warningColor + ", " + warningColor + ")",
	...warningBoxShadow
};
const greenCardHeader = {
	background: "linear-gradient(60deg, #66bb6a, #43a047)",
	...successBoxShadow
};
const redCardHeader = {
	background: "linear-gradient(60deg, #ef5350, #e53935)",
	...dangerBoxShadow
};
const blueCardHeader = {
	background: "linear-gradient(60deg, #26c6da, #00acc1)",
	...infoBoxShadow
};
const purpleCardHeader = {
	background: "linear-gradient(60deg, #ab47bc, #8e24aa)",
	...primaryBoxShadow
};
// new card headers
const warningCardHeader = {
	background: "linear-gradient(60deg, #457CF7, #402FC0)",
	...warningBoxShadow
};
const successCardHeader = {
	background: "linear-gradient(60deg, #66bb6a, #43a047)",
	...successBoxShadow
};
const dangerCardHeader = {
	background: "linear-gradient(60deg, #ef5350, #e53935)",
	...dangerBoxShadow
};
const infoCardHeader = {
	background: "linear-gradient(60deg, #26c6da, #00acc1)",
	...infoBoxShadow
};
const primaryCardHeader = {
	background: "linear-gradient(60deg, " + lightenDarkenColor(primaryColor, 20) + ", " + primaryColor + ")",
	...primaryBoxShadow
};
const roseCardHeader = {
	background: "linear-gradient(60deg, #ec407a, #d81b60)",
	...roseBoxShadow
};
const whiteCardHeader = {
	background: "linear-gradient(60deg, #fff, #fff)",
	...whiteBoxShadow
};
const blackCardHeader = {
	background: "linear-gradient(60deg, #000, #000)",
	...whiteBoxShadow
};
const cardActions = {
	margin: "0 20px 10px",
	paddingTop: "10px",
	borderTop: "1px solid #eeeeee",
	height: "auto",
	...defaultFont
};

const cardHeader = {
	margin: "-20px 15px 0",
	borderRadius: "3px",
	padding: "15px"
};

const defaultBoxShadow = {
	border: "0",
	borderRadius: "3px",
	boxShadow:
		"0 10px 20px -12px rgba(0, 0, 0, 0.42), 0 3px 20px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
	padding: "10px 0",
	transition: "all 150ms ease 0s"
};

const tooltip = {
	padding: "10px 15px",
	minWidth: "130px",
	color: "#FFFFFF",
	lineHeight: "1.7em",
	background: "rgba(85,85,85,0.9)",
	border: "none",
	borderRadius: "3px",
	opacity: "1!important",
	boxShadow:
		"0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2)",
	maxWidth: "200px",
	textAlign: "center",
	fontFamily: "\"Helvetica Neue\",Helvetica,Arial,sans-serif",
	fontSize: "12px",
	fontStyle: "normal",
	fontWeight: 400,
	textShadow: "none",
	textTransform: "none",
	letterSpacing: "normal",
	wordBreak: "normal",
	wordSpacing: "normal",
	wordWrap: "normal",
	whiteSpace: "normal",
	lineBreak: "auto"
};

const title = {
	color: "#3C4858",
	textDecoration: "none",
	fontWeight: 300,
	marginTop: "30px",
	marginBottom: "25px",
	minHeight: "32px",
	fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
	"& small": {
		color: "#777",
		fontSize: "65%",
		fontWeight: 400,
		lineHeight: "1"
	}
};

const cardTitle = {
	...title,
	marginTop: "0",
	marginBottom: "3px",
	minHeight: "auto",
	"& a": {
		...title,
		marginTop: ".625rem",
		marginBottom: "0.75rem",
		minHeight: "auto"
	}
};

const cardSubtitle = {
	marginTop: "-.375rem"
};

const cardLink = {
	"& + $cardLink": {
		marginLeft: "1.25rem"
	}
};

const chartColors = {
	red: "rgb(153, 51, 102)",
	orange: "rgb(245, 127, 41)",
	yellow: "rgb(255, 205, 86)",
	green: "rgb(75, 192, 192)",
	blue: "rgb(54, 162, 235)",
	purple: "rgb(153, 102, 255)",
	grey: "rgb(201, 203, 207)",
	red1: "rgb(153, 51, 102)",
	orange1: "rgb(245, 127, 41)",
	yellow1: "rgb(255, 205, 86)",
	green1: "rgb(75, 192, 192)",
	blue1: "rgb(54, 162, 235)",
	purple1: "rgb(153, 102, 255)",
	grey1: "rgb(201, 203, 207)",
	red3: "rgb(153, 51, 102)",
	orange3: "rgb(245, 127, 41)",
	yellow3: "rgb(255, 205, 86)",
	green3: "rgb(75, 192, 192)",
	blue3: "rgb(54, 162, 235)",
	purple3: "rgb(153, 102, 255)",
	grey3: "rgb(201, 203, 207)",
	red4: "rgb(153, 51, 102)",
	orange4: "rgb(245, 127, 41)",
	yellow4: "rgb(255, 205, 86)",
	green4: "rgb(75, 192, 192)",
	blue4: "rgb(54, 162, 235)",
	purple4: "rgb(153, 102, 255)",
	grey4: "rgb(201, 203, 207)"
};
const chartGradientColors = [
	{ begin: "#457CF7", end: "#339900" },
	{ begin: "#457CF7", end: "#339900" },
	{ begin: "#457CF7", end: "#339900" },
	{ begin: "#ceae18", end: "#74A764" },
	{ begin: "#e6ab05", end: "#74A764" },
	{ begin: "#f1a900", end: "#74A764" },
	{ begin: "#ffa600", end: "#C9CBCF" }
];
export {
	//variables
	drawerWidth,
	drawerMiniWidth,
	transition,
	container,
	containerFluid,
	boxShadow,
	cardBoxShadow,
	card,
	defaultFont,
	primaryColor,
	warningColor,
	dangerColor,
	successColor,
	infoColor,
	roseColor,
	whiteColor,
	grayColor,
	blackColor,
	primaryBoxShadow,
	infoBoxShadow,
	successBoxShadow,
	warningBoxShadow,
	dangerBoxShadow,
	roseBoxShadow,
	whiteBoxShadow,
	// old card header colors
	orangeCardHeader,
	greenCardHeader,
	redCardHeader,
	blueCardHeader,
	purpleCardHeader,
	roseCardHeader,
	whiteCardHeader,
	blackCardHeader,
	// new card header colors
	warningCardHeader,
	successCardHeader,
	dangerCardHeader,
	infoCardHeader,
	primaryCardHeader,
	cardActions,
	cardHeader,
	defaultBoxShadow,
	tooltip,
	title,
	cardTitle,
	cardSubtitle,
	cardLink,
	chartColors,
	chartGradientColors
};
