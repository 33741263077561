import { DialogContent, DialogTitle, Slide } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import RouteHelper from "core/RouteHelper";
import PropTypes, { any } from "prop-types";
import React from "react";
import BillFromFile from "views/BankAccount/BillPayment/BillDefinition/BillFromFile";
import BillIndividual from "views/BankAccount/BillPayment/BillDefinition/BillIndividual";
import BillRecurring from "views/BankAccount/BillPayment/BillDefinition/BillRecurring";
import AccountLimitToolbar from "views/Components/AccountLimitToolbar";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericCheckInput, GenericDateInput, GenericDialog, GenericDialogActions, GenericGrid, GenericIcon, GenericLabel, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { BillPayType, PeriodCodes, ProgramCodes } from "views/Constants/Constant";
import CustomerDefinition from "views/Customer/CustomerDefinition";

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

class BillDefinition extends React.Component {
	constructor(props) {
		super(props);

		this.renderItemBiller = {};
		this.defaultModel = {
			BillPayType: "",
			BillPayTypeId: null,

			IsUpdate: false,
			ClientId: 0,

			BillerCode: "",
			BillerName: "",
			BillerId: null,

			IsCustomerPopupOpen: false,
			ClientCustomerId: 0,
			ClientCustomerName: "",
			NewCustomer: false,
		};
		this.defaultRecurringModel = {
			Id: null,
			ClientCustomerId: null,
			PayeeName: "",
			TransitNumber: "",
			AccountNumber: "",
			StartDate: DateHelper.GetLocalDateAsUtc(),
			EndDate: DateHelper.GetLocalDateAsUtc(),
			PaymentCount: null,
			RecurringAmount: null,
			TotalAmount: null,
			BillDescription: "",
			PeriodId: null,
			PeriodCode: "",
			WeeklyId: null,
			WeeklyNumber: null,
			BiWeeklyId: null,
			BiWeeklyNumber: null,
			MonthlyId: null,
			MonthlyNumber: null,
			QuarterlyId: null,
			QuarterlyNumber: null,
			IsCustomerPopupOpen: false,
			FreezeStartDate: null,
			FreezeEndDate: null,
			BillPayTypeId: null,
			IsPayed: false,
			PayIndex: 0,
			BillerCode: "",
			BillerName: ""
		};
		this.defaultFromFileModel = {
			AutoApprove: false,
			FileName: "",
			File: null
		};
		this.defaultIndividualModel = {
			Id: null,
			ClientCustomerId: null,
			PayeeName: "",
			NewCustomer: false,
			TransitNumber: "",
			AccountNumber: "",
			Amount: null,
			AccountName: "",
			BillDescription: "",
			IsCustomerPopupOpen: false,
			TransactionType: "",
			TransactionTypeCode: "",
			TransactionCode: "",
			BillPayTypeId: null,
			BillerCode: "",
			BillerName: ""
		};
		this.defaultBillerModel = {
			Id: 0,
			Name: "",
			Code: "",
			IsNewBiller: false,
			IsAgainNewBiller: false,
			NewBiller: false
		};
		this.state = {
			isLoading: false,
			alert: null,

			vFromFileModel: {},
			vIndividualModel: {},
			vRecurringModel: {},
			vModel: {},

			IsPayIndivudalPassive: true,
			IsPayRecurringPassive: true,
			IsPayFromFilePassive: true,

			idIndividualClientWarningOpen: false,
			isIndividualDialogOpen: false,
			isRecurringDialogOpen: false,
			isFileDialogOpen: false,
			isBillerDialogOpen: false,
			isClearDialogOpen: false,
			isFileResultDialogOpen: false,


			BillFromFileModel: { ...this.defaultFromFileModel },
			BillRecurringModel: { ...this.defaultRecurringModel },
			BillIndividualModel: { ...this.defaultIndividualModel },
			BillerModel: { ...this.defaultBillerModel },
			model: { ...this.defaultModel },

			LimitToken: false,
			LimitBalanceData: {
				AvailableCreditLimit: 0,
				AvailableDebitLimit: 0,
				AvailableBalance: 0
			},
			customertoken: 0,

			BillList: [],
			BillerList: [],
			FileResultList: [],
		};
		this.RecurringModelChange = this.RecurringModelChange.bind(this);
		this.IndividualModelChange = this.IndividualModelChange.bind(this);
		this.BillerModelChange = this.BillerModelChange.bind(this);
		this.FromFileModelChange = this.FromFileModelChange.bind(this);
		this.RefreshToolBar = this.RefreshToolBar.bind(this);

		this.addCustomerDialogClose = this.addCustomerDialogClose.bind(this);
		this.addCustomerSaved = this.addCustomerSaved.bind(this);
		this.hideAlert = this.hideAlert.bind(this);

	}

	Validate = () => {

		const { model, BillIndividualModel, BillRecurringModel, BillFromFileModel,  vModel, vIndividualModel, vRecurringModel, vFromFileModel } = this.state;
		var billMessages = [];

		if (model.BillPayType != BillPayType.File) {
			if (model.BillerCode == null || model.BillerCode == "") { vModel.BillerCode = true; billMessages.push("Payee Name can not be null"); } else { vModel.BillerCode = false; }
			if (!model.ClientCustomerId) { vModel.ClientCustomerId = true; billMessages.push("Customer name can not be null"); } else { vModel.ClientCustomerId = false; }
		}
		switch (model.BillPayType) {
			case BillPayType.Individual:
				vModel.BillPayTypeId = false;
				if (!BillIndividualModel.AccountNumber) { vIndividualModel.AccountNumber = true; billMessages.push("Payee Wallet Number cannot be null."); } else { vIndividualModel.AccountNumber = false; }
				if (!BillIndividualModel.Amount) { vIndividualModel.Amount = true; billMessages.push("Amount cannot be null."); } else { vIndividualModel.Amount = false; }

				// if (IsClient) {
				// 	if (LimitBalanceData == null) billMessages.push("Limit Balance data is empty.");
				// 	else if (!Disabled && !model.IsUpdate && LimitBalanceData.AvailableBalance != null && LimitBalanceData.AvailableBalance < BillIndividualModel.Amount) billMessages.push("Transaction Amount cannot be higher than balance.");
				// }
				break;

			case BillPayType.Recurring:
				vModel.BillPayTypeId = false;
				if (BillRecurringModel.AccountNumber == "") { vRecurringModel.AccountNumber = true; billMessages.push("Payee Wallet Number cannot be null."); } else { vRecurringModel.AccountNumber = false; }

				if (BillRecurringModel.StartDate == null) { vRecurringModel.StartDate = true; billMessages.push("Start Date cannot be null."); }
				else if (typeof BillRecurringModel.StartDate != "object") { vRecurringModel.StartDate = true; billMessages.push("Start Date is invalid."); }
				else { vRecurringModel.StartDate = false; }

				if (BillRecurringModel.EndDate == null) { vRecurringModel.EndDate = true; billMessages.push("End Date cannot be null."); }
				else if (typeof BillRecurringModel.EndDate != "object") { vRecurringModel.EndDate = true; billMessages.push("End Date is invalid."); }
				else if (DateHelper.ToMoment(BillRecurringModel.EndDate).startOf("day") < DateHelper.GetDate()) { vRecurringModel.EndDate = true; billMessages.push("End Date cannot be past."); }
				else { vRecurringModel.EndDate = false; }

				if (typeof BillRecurringModel.StartDate == "object" && typeof BillRecurringModel.EndDate == "object" && BillRecurringModel.StartDate > BillRecurringModel.EndDate) billMessages.push("Start Date cannot be after End Date.");

				if (model.IsUpdate) {
					if (BillRecurringModel.FreezeStartDate != null && typeof BillRecurringModel.FreezeStartDate != "object") { vRecurringModel.FreezeStartDate = true; billMessages.push("Freeze Start Date is invalid."); }
					else if (DateHelper.ToMoment(BillRecurringModel.FreezeStartDate).startOf("day") < DateHelper.ToMoment(BillRecurringModel.StartDate).startOf("day")) { vRecurringModel.FreezeStartDate = true; billMessages.push("Freeze Start Date cannot be before Start Date."); }
					else { vRecurringModel.FreezeStartDate = false; }


					if (BillRecurringModel.FreezeEndDate != null && typeof BillRecurringModel.FreezeEndDate != "object") { vRecurringModel.FreezeEndDate = true; billMessages.push("Freeze End Date is invalid."); }
					else if (DateHelper.ToMoment(BillRecurringModel.FreezeEndDate).startOf("day") > DateHelper.ToMoment(BillRecurringModel.EndDate).startOf("day")) { vRecurringModel.FreezeEndDate = true; billMessages.push("Freeze End Date cannot be after End Date."); }
					else { vRecurringModel.FreezeEndDate = false; }

					if (!BillRecurringModel.FreezeStartDate && BillRecurringModel.FreezeEndDate) { vRecurringModel.FreezeStartDate = true; billMessages.push("Freeze Start Date cannot be empty."); }
					else { vRecurringModel.FreezeStartDate = false; }

					if (!BillRecurringModel.FreezeEndDate && BillRecurringModel.FreezeStartDate) { vRecurringModel.FreezeEndDate = true; billMessages.push("Freeze End Date cannot be empty."); }
					else { vRecurringModel.FreezeEndDate = false; }
				}
				if (BillRecurringModel.PaymentCount == null || BillRecurringModel.PaymentCount == "0") { vRecurringModel.PaymentCount = true; billMessages.push("Period/No. Of Payment cannot be null."); } else { vRecurringModel.PaymentCount = false; }
				if (BillRecurringModel.RecurringAmount == null || BillRecurringModel.RecurringAmount == "" || BillRecurringModel.RecurringAmount == 0) { vRecurringModel.RecurringAmount = true; billMessages.push("Recurring Amount cannot be null."); } else { vRecurringModel.RecurringAmount = false; }
				if (BillRecurringModel.PeriodId == null || BillRecurringModel.PeriodId == "") { vRecurringModel.PeriodId = true; billMessages.push("First Due Date Options is not selected."); } else { vRecurringModel.PeriodId = false; }
				if (BillRecurringModel.PeriodCode == PeriodCodes.Weekly && (BillRecurringModel.WeeklyNumber == null || BillRecurringModel.WeeklyNumber == "")) billMessages.push("Day of week is not selected.");
				if (BillRecurringModel.PeriodCode == PeriodCodes.BiWeekly && (BillRecurringModel.BiWeeklyNumber == null || BillRecurringModel.BiWeeklyNumber == "")) billMessages.push("Day of week is not selected.");
				if (BillRecurringModel.PeriodCode == PeriodCodes.Monthly && (BillRecurringModel.MonthlyNumber == null || BillRecurringModel.MonthlyNumber == "")) billMessages.push("Day of month is not selected.");
				if (BillRecurringModel.PeriodCode == PeriodCodes.Quarterly && (BillRecurringModel.QuarterlyNumber == null || BillRecurringModel.QuarterlyNumber == "")) billMessages.push("Day of first month of quarter is not selected.");

				break;
			case BillPayType.File:
				vFromFileModel.File = BillFromFileModel.File;
				if (!vFromFileModel.File) {
					this.props.showMessage("warning", "File not selected", "Select File to continue.");
					this.setState({ vFromFileModel });
					return false;
				}
				break;
			default:
				this.ShowMessage("warning", "Bill Payment Type not selected", "Bill Payment Type not selected.");
				vModel.BillPayTypeId = true;
				break;
		}
		if (billMessages.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", billMessages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		else
			return true;

	}

	FillLastData = (data) => {
		if (data) {
			const { BillIndividualModel, BillRecurringModel } = data;

			if (BillIndividual && BillIndividualModel != null && BillIndividualModel != any) {
				this.setState({ BillIndividualModel });
			}
			else if (BillRecurringModel && BillRecurringModel != any) {
				BillRecurringModel.RecurringAmount = null;
				BillRecurringModel.TotalAmount = null;
				BillRecurringModel.PeriodId = BillRecurringModel.FirstDueDateOptionId;
				BillRecurringModel.WeeklyId = BillRecurringModel.PeriodWeeklyId;
				BillRecurringModel.WeeklyNumber = BillRecurringModel.PeriodWeekly != null ? BillRecurringModel.PeriodWeekly : "";
				BillRecurringModel.BiWeeklyId = BillRecurringModel.PeriodBiWeeklyId;
				BillRecurringModel.BiWeeklyNumber = BillRecurringModel.PeriodBiWeekly != null ? BillRecurringModel.PeriodBiWeekly : "";
				BillRecurringModel.MonthlyId = BillRecurringModel.PeriodMonthlyId;
				BillRecurringModel.MonthlyNumber = BillRecurringModel.PeriodMonthly != null ? BillRecurringModel.PeriodMonthly : "";
				BillRecurringModel.QuarterlyId = BillRecurringModel.PeriodQuarterlyId;
				BillRecurringModel.QuarterlyNumber = BillRecurringModel.PeriodQuarterly != null ? BillRecurringModel.PeriodQuarterly : "";
				BillRecurringModel.StartDate = BillRecurringModel.StartDate ? DateHelper.ToMoment(BillRecurringModel.StartDate) : null;
				BillRecurringModel.EndDate = BillRecurringModel.EndDate ? DateHelper.ToMoment(BillRecurringModel.EndDate) : null;
				BillRecurringModel.FreezeStartDate = null;
				BillRecurringModel.FreezeEndDate = null;
				BillRecurringModel.PayeeAccountNumber=
				this.setState({ BillRecurringModel });
			} else {
				this.setState({ BillIndividualModel: { ...this.defaultIndividualModel }, BillRecurringModel: { ...this.defaultRecurringModel } });

			}
		}
		else {
			this.setState({ BillIndividualModel: { ...this.defaultIndividualModel }, BillRecurringModel: { ...this.defaultRecurringModel } });
		}
	}

	GetLastBill = (model) => {
		if (model.BillPayTypeId && model.BillerCode && model.ClientCustomerId && !model.IsUpdate) {
			this.setState({ isLoading: true });
			Proxy.POST(
				"/bankapi/v1.0/Bill/LastBillTransaction",
				{
					"BillPayType": model.BillPayType == BillPayType.Individual ? 1 : 2,
					"BillPayTypeId": model.BillPayTypeId,
					"ClientCustomerId": model.ClientCustomerId,
					"BillerCode": model.BillerCode
				},
				(responseData) => {
					this.setState({ isLoading: false });
					if (!responseData.IsSucceeded) {
						this.setState({ isLoading: false });
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					this.FillLastData(responseData.Item);
				},
				(error) => {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "An error occurred while requesting data", error.message);
				}
			);
		}
	}

	RecurringModelChange(modelFunction) {
		this.setState(function (state, props) {
			var model = state.BillRecurringModel || {};
			if (modelFunction)
				model = modelFunction(model);
			return { BillRecurringModel: model };
		});
	}

	BillerModelChange(name, newValue, data) {
		this.setState(function (state) {
			var model = state.model || {};
			if (data) {
				model.BillerId = data.Id;
				model[name] = newValue;
				model.BillerCode = data.BillerCode;
				model.BillerName = data.BillerName;
				this.GetLastBill(model);
			}
			return { model };
		});
	}

	IndividualModelChange(modelFunction) {
		this.setState(function (state, props) {
			var model = state.BillIndividualModel || {};

			if (modelFunction)
				model = modelFunction(model);

			return { BillIndividualModel: model };
		});
	}

	TransactionModelChange(modelFunction) {
		this.setState(function (state, props) {
			var model = state.BillIndividualModel || {};

			if (modelFunction)
				model = modelFunction(model);

			return { BillIndividualModel: model };
		});

	}

	FromFileModelChange(modelFunction) {
		this.setState(function (state, props) {
			var model = state.BillFromFileModel || {};

			if (modelFunction)
				model = modelFunction(model);

			return { BillFromFileModel: model };
		});
	}

	componentDidMount() {
		const { setAppLeftTitle, setAppCenterTitle } = this.props;

		if (setAppLeftTitle)
			this.props.setAppLeftTitle("Create Bill Payment");
		if (setAppCenterTitle)
			this.props.setAppCenterTitle("BILL PAYMENT");

		this.isClient = ClientHelper.IsClient();
		var model = { ...this.state.model };
		if (this.isClient) {
			model.ClientId = ClientHelper.GetClientRowId();
		}
		model.IsUpdate = false;
		this.setState({ model });
		Proxy.GET("/bankapi/v1.0/BankCustomer/CheckClientIndividual",
			responseData => {
				if (!responseData.IsSucceeded) {
					return;
				}
				var data = responseData.Item;
				if (data) {
					if (data.IsClient && data.IsIndividual)
						this.setState({ idIndividualClientWarningOpen: true });
				}
			});

		var tempmodel = RouteHelper.ReadAndClear("BillModel");
		if (tempmodel != any && tempmodel != null) {
			this.SelectedRowChange(0, tempmodel);
			this.GetBiller(this.state.model);
		}
	}

	CancelModel = (model) => {
		const { BillRecurringModel } = { ...this.state };
		var ErrorList = [];
		Proxy.POST("/bankapi/v1.0/Bill/CancelRecurringBill",
			{
				Id: BillRecurringModel.Id
			},
			responseData => {
				this.setState({ isLoading: false, isRecurringDialogOpen: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessageNode("warning", "", ErrorList.map((x, i) => <div key={i}>{x}</div>));
					return;
				}
				else {
					model.IsDisable = true;
					this.ClearScreen();
					this.ShowMessage("success", "Recurring Transaction  successfully canceled.");
					this.RefreshToolBar();
				}
			},
			e => {
				this.setState({ isLoading: false, isRecurringDialogOpen: false });
				this.ShowMessage("error", "An error occurred while inserting data", typeof e == "object" ? JSON.stringify(e) : e);
			}
		);
		this.setState({ model: model });
	}

	ClearDialog = () => {
		const { model } = { ...this.state };
		this.showClearQuestionMessage("Are you sure you want to clear on the screen entered information? ", model);
	}

	UpdateDialog = () => {
		const { model } = { ...this.state };
		if (model.IsUpdate && !model.IsDisable) {
			if (!this.Validate())
				return false;
			switch (model.BillPayType) {
				case BillPayType.Individual:
					this.setState({ isIndividualDialogOpen: true });
					break;
				case BillPayType.Recurring:
					this.setState({ isRecurringDialogOpen: true });
					break;
				case BillPayType.File:
					this.setState({ isFileDialogOpen: true });
					break;
				default:
					break;
			}
		}

	}

	errorDefinitionCallback(error) {
		this.ShowMessage("error", "Error !", "An error occurred while requesting data" + error);
	}
	ShowMessage(type, title, message) {
		this.setState({ alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} /> });
	}

	hideAlert() {
		this.setState({ alert: null, isLoading: false });
	}

	ValueChanged = (name, value) => {
		const model = { ...this.state.model };
		model[name] = value;
		this.setState({ model });
	}

	handleChange = (name, value, data) => {
		const model = { ...this.state.model };

		const BillRecurringModel = { ...this.state.BillRecurringModel };
		const BillIndividualModel = { ...this.state.BillIndividualModel };

		model[name] = value;
		if (name == "NewCustomer") {
			if (value) {
				model.IsCustomerPopupOpen = true;
				this.setState({ isCustomerAdded: false, customertoken: 0 });
			}
		}
		if (data) {
			if (name == "BillPayTypeId") {
				if (data.ParameterValue == BillPayType.Individual) {
					model.BillPayType = BillPayType.Individual;
					this.setState({ IsPayIndivudalPassive: false, IsPayRecurringPassive: true });
					this.GetBiller(model);
					this.GetLastBill(model);
				}
				else if (data.ParameterValue == BillPayType.Recurring) {
					model.BillPayType = BillPayType.Recurring;
					this.setState({ IsPayIndivudalPassive: true, IsPayRecurringPassive: false });
					this.GetBiller(model);
					this.GetLastBill(model);
				}
				else if (data.ParameterValue == BillPayType.File) {
					model.BillPayType = BillPayType.File;
					this.setState({ IsPayIndivudalPassive: true, IsPayRecurringPassive: true });

				}
			}

			if (name == "ClientCustomerId") {
				model.ClientCustomerId = data.Id;
				model.ClientCustomerName = data.Name;
				BillRecurringModel.ClientCustomerId = data.Id;
				BillIndividualModel.ClientCustomerId = data.Id;
				this.GetLastBill(model);
			}

			if (name == "IsCustomerPopupOpen") {
				model.NewCustomer = false;
			}
		}
		this.setState({ model });

		if (name == "ClientCustomerId")
			this.GetBiller(model);


	}

	SelectedRowChange = (e, d) => {

		const { model, BillerModel, BillRecurringModel, BillIndividualModel } = { ...this.state };

		if (d.BillPayTypeValue == BillPayType.Individual) {
			model.BillPayType = BillPayType.Individual;
			model.BillPayTypeId = d.BillPayTypeId;
			model.ClientCustomerId = d.ClientCustomerId;
			model.BillerId = d.BillerId;
			model.BillerCode = d.BillerCode;
			model.BillerName = d.BillerName;
			model.ClientCustomerName = d.ClientCustomerName;
			model.IsUpdate = true;
			BillIndividualModel.Id = d.Id;
			BillIndividualModel.Amount = d.Amount;
			BillIndividualModel.AccountNumber = d.BillAccountNumber;
			BillIndividualModel.BillDescription = d.BillDescription;
			this.setState({ BillerModel: BillerModel, BillIndividualModel: BillIndividualModel, IsPayRecurringPassive: true, IsPayIndivudalPassive: false, model: model });

		}

		else if (d.BillPayTypeValue == BillPayType.Recurring) {

			model.BillPayType = BillPayType.Recurring;
			model.BillPayTypeId = d.BillPayTypeId;
			model.ClientCustomerId = d.ClientCustomerId;
			model.IsUpdate = true;
			model.BillerId = d.BillerId;
			model.BillerCode = d.BillerCode;
			model.BillerName = d.BillerName;
			model.ClientCustomerName = d.ClientCustomerName;
			BillRecurringModel.Id = d.BillId;
			BillRecurringModel.RecurringAmount = d.AutoPayAmount;
			BillRecurringModel.PaymentCount = d.PaymentCount;
			BillRecurringModel.TotalAmount = d.TotalAmount;
			BillRecurringModel.AccountNumber = d.BillAccountNumber;
			BillRecurringModel.StartDate = d.StartDate ? DateHelper.ToMoment(d.StartDate) : null;
			BillRecurringModel.EndDate = d.EndDate ? DateHelper.ToMoment(d.EndDate) : null;
			BillRecurringModel.FreezeStartDate = d.FreezeStartDate ? DateHelper.ToMoment(d.FreezeStartDate) : null;
			BillRecurringModel.FreezeEndDate = d.FreezeEndDate ? DateHelper.ToMoment(d.FreezeEndDate) : null;
			BillRecurringModel.PeriodId = d.FirstDueDateOptionId;
			BillRecurringModel.PeriodCode = d.PeriodCode;
			BillRecurringModel.BillDescription = d.BillDescription;
			BillRecurringModel.IsPayed = d.IsHaveTransaction;

			BillRecurringModel.WeeklyId = d.PeriodWeeklyId;
			BillRecurringModel.WeeklyNumber = d.PeriodWeekly != null ? d.PeriodWeekly : "";

			BillRecurringModel.BiWeeklyId = d.PeriodBiWeeklyId;
			BillRecurringModel.BiWeeklyNumber = d.PeriodBiWeekly != null ? d.PeriodBiWeekly : "";

			BillRecurringModel.MonthlyId = d.PeriodMonthlyId;
			BillRecurringModel.MonthlyNumber = d.PeriodMonthly != null ? d.PeriodMonthly : "";

			BillRecurringModel.QuarterlyId = d.PeriodQuarterlyId;
			BillRecurringModel.QuarterlyNumber = d.PeriodQuarterly != null ? d.PeriodQuarterly : "";


			BillRecurringModel.PayIndex = d.PayIndex;
			this.setState({ BillerModel: BillerModel, BillRecurringModel: BillRecurringModel, IsPayRecurringPassive: false, IsPayIndivudalPassive: true, model: model, });
		}
	}
	ClearScreen = () => {
		this.setState({ isLoading: true });
		this.FromFileModelChange(() => { return { ...this.defaultFromFileModel }; });
		this.IndividualModelChange(() => { return { ...this.defaultIndividualModel }; });
		this.RecurringModelChange(() => { return { ...this.defaultRecurringModel }; });
		this.BillerModelChange(() => { return { ...this.defaultBillerModel }; });
		this.setState({ model: { ...any }, isClearDialogOpen: false, isLoading: false, alert: null, IsPayIndivudalPassive: true, IsPayRecurringPassive: true });
		this.GetBiller();
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
	}


	SubmitDialog = (workflowId, after, accessToken) => {
		const { model } = this.state;

		if (!model.IsUpdate) {
			if (!this.Validate())
				return false;

			switch (model.BillPayType) {
				case BillPayType.Individual:
					this.setState({ workflowId, after, isIndividualDialogOpen: true, accessToken });
					break;
				case BillPayType.Recurring:
					this.setState({ workflowId, after, isRecurringDialogOpen: true, accessToken });
					break;
				case BillPayType.File:
					this.setState({ workflowId, after, isFileDialogOpen: true, accessToken });
					break;
				default:
					break;
			}
		}
	}

	Search = () => {
		this.setState({ isLoading: true });
		const { model } = this.state;
		Proxy.POST(
			"/bankapi/v1.0/Bill/GetCustomerBaseBillList",
			model,
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				this.setState({ BillList: responseData.Item || [], isLoading: false });
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while requesting data", error.message);
			}
		);
	}

	RefreshToolBar() {
		this.setState({ LimitToken: !this.state.LimitToken });
	}

	Submit = () => {

		const { BillRecurringModel, BillIndividualModel } = this.state;
		const { model, workflowId, after,accessToken } = this.state;

		this.setState({ isLoading: true });
		if (model.BillPayType == BillPayType.Recurring) {
			var recurringRequest = {
				BillerName: model.BillerName,
				BillerCode: model.BillerCode,
				Id: BillRecurringModel.Id,
				ClientCustomerId: model.ClientCustomerId,
				ClientCustomerName: model.ClientCustomerName,
				BillerId: model.BillerId,
				RecurringAmount: BillRecurringModel.RecurringAmount,
				TotalAmount: BillRecurringModel.TotalAmount,
				BillDescription: BillRecurringModel.BillDescription,
				StartDate: BillRecurringModel.StartDate,
				EndDate: BillRecurringModel.EndDate,
				FreezeStartDate: BillRecurringModel.FreezeStartDate,
				FreezeEndDate: BillRecurringModel.FreezeEndDate,
				PaymentCount: BillRecurringModel.PaymentCount,
				FirstDueDateOptionId: BillRecurringModel.PeriodId,
				PeriodWeeklyId: BillRecurringModel.PeriodCode == PeriodCodes.Weekly ? BillRecurringModel.WeeklyId : "",
				PeriodBiWeeklyId: BillRecurringModel.PeriodCode == PeriodCodes.BiWeekly ? BillRecurringModel.BiWeeklyId : "",
				PeriodMonthlyId: BillRecurringModel.PeriodCode == PeriodCodes.Monthly ? BillRecurringModel.MonthlyId : "",
				PeriodQuarterlyId: BillRecurringModel.PeriodCode == PeriodCodes.Quarterly ? BillRecurringModel.QuarterlyId : "",
				AccountNumber: BillRecurringModel.AccountNumber,
				UniqueClientId: ClientHelper.GetClientRowId(),
				BillPayTypeId: model.BillPayTypeId,
			};

			this.setState({ isLoading: true });

			if (!model.IsUpdate) {
				Proxy.POST("/bankapi/v1.0/Bill/InsertRecurringBill",
					{ BillRecurringModel: recurringRequest },
					responseData => {
						this.setState({ isLoading: false, isRecurringDialogOpen: false });

						if (!responseData.IsSucceeded) {
							if (responseData.ErrorCode == "SysError")
								this.ShowMessage("error", "Error", responseData.ErrorDescription);
							else
								this.ShowMessage("warning", "Warning", responseData.ErrorDescription);
							return;
						}
						else {
							if (after) after();
							this.ClearScreen();
							this.RefreshToolBar();
							this.GetBiller();
							this.ShowMessage("success", "Recurring Transaction  successfully saved.");
						}
					},
					e => {
						this.setState({ isLoading: false, isRecurringDialogOpen: false });
						this.ShowMessage("error", "An error occurred while updating data", typeof e == "object" ? JSON.stringify(e) : e);
					},
					workflowId,
					accessToken
				);
			}
			else {
				Proxy.POST("/bankapi/v1.0/Bill/UpdateRecurringBill",
					{ BillRecurringModel: recurringRequest },
					responseData => {
						this.setState({ isLoading: false, isRecurringDialogOpen: false });

						if (!responseData.IsSucceeded) {
							if (responseData.ErrorCode == "SysError")
								this.ShowMessage("error", "Error", responseData.ErrorDescription);
							else
								this.ShowMessage("warning", "Warning", responseData.ErrorDescription);
							return;
						}
						else {
							if (after) after();
							this.ClearScreen();
							this.RefreshToolBar();
							this.GetBiller();
							this.ShowMessage("success", "Recurring Transaction successfully updated.");
						}
					},
					e => {
						this.setState({ isLoading: false, isRecurringDialogOpen: false });
						this.ShowMessage("error", "An error occurred while inserting data", typeof e == "object" ? JSON.stringify(e) : e);
					},
					workflowId,
					accessToken
				);
			}
		}
		else {

			var individualRequest = {
				Id: BillIndividualModel.Id,
				Amount: BillIndividualModel.Amount,
				ClientCustomerId: model.ClientCustomerId,
				BillerId: model.BillerId,
				AccountNumber: BillIndividualModel.AccountNumber,
				BillDescription: BillIndividualModel.BillDescription,
				ClientCustomerName: model.ClientCustomerName,
				BillerName: model.BillerName,
				BillerCode: model.BillerCode,
				UniqueClientId: ClientHelper.GetClientRowId()
			};


			if (!model.IsUpdate) {
				this.setState({ isLoading: true });

				Proxy.POST("/bankapi/v1.0/Bill/InsertIndividualBill",
					{ BillIndividualModel: individualRequest },
					responseData => {
						this.setState({ isLoading: false, isIndividualDialogOpen: false });

						if (!responseData.IsSucceeded) {
							if (responseData.ErrorCode == "SysError")
								this.ShowMessage("error", "Error", responseData.ErrorDescription);
							else
								this.ShowMessage("warning", "Warning", responseData.ErrorDescription);
							return;
						}
						else {
							if (after) after();
							this.ClearScreen();
							this.ShowMessage("success", "Individual Transaction Entry successfully saved.");
							this.RefreshToolBar();
							this.GetBiller();

						}
					},
					e => {
						this.setState({ isLoading: false, isIndividualDialogOpen: false });
						this.ShowMessage("error", "An error occurred while inserting data", typeof e == "object" ? JSON.stringify(e) : e);
					},
					workflowId,
					accessToken
				);
			}
			else {
				Proxy.POST("/bankapi/v1.0/Bill/UpdateIndividualBill",
					{ BillIndividualModel: individualRequest },
					responseData => {
						this.setState({ isLoading: false, isIndividualDialogOpen: false });

						if (!responseData.IsSucceeded) {
							if (responseData.ErrorCode == "SysError")
								this.ShowMessage("error", "Error", responseData.ErrorDescription);
							else
								this.ShowMessage("warning", "Warning", responseData.ErrorDescription);
							return;
						}
						else {
							if (after) after();
							this.ClearScreen();
							this.RefreshToolBar();
							this.GetBiller();
							this.ShowMessage("success", "Individual Transaction successfully updated.");
						}
					},
					e => {
						this.setState({ isLoading: false, isRecurringDialogOpen: false });
						this.ShowMessage("error", "An error occurred while inserting data", typeof e == "object" ? JSON.stringify(e) : e);
					},
					workflowId,
					accessToken
				);
			}

		}
	}
	addCustomerSaved(customerData) {
		const model = { ...this.state.model };
		if (customerData != null) {
			model.ClientCustomerId = customerData.Id;
			model.ClientCustomerName = customerData.CustomerName;
		}

		var newCustomerToken = this.state.customerToken == "1" ? "0" : "1";
		this.setState({ model: model, customerToken: newCustomerToken });
		this.addCustomerDialogClose();
	}

	SubmitFileClick = () => {
		const { BillFromFileModel } = this.state;
		this.setState({ isLoading: true });
		var formData = new FormData();
		formData.append("file", BillFromFileModel.File, BillFromFileModel.FileName);
		formData.append("approved", String(BillFromFileModel.AutoApprove));

		Proxy.FileUpload("/bankapi/v1.0/Bill/UploadCreateTransactionFile",
			formData,
			responseData => {
				this.setState({ isLoading: false, isFileDialogOpen: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "An error occurred while upload file", responseData.ErrorDescription);
					return;
				}

				var fileId = responseData.Item ? responseData.Item.FileId : 0;
				this.setState({ isLoading: true });
				Proxy.POST("/bankapi/v1.0/Bill/GetFileInformation",
					{ Id: fileId },
					fileResponseData => {
						if (!fileResponseData.IsSucceeded) {
							this.setState({ isLoading: false, isFileDialogOpen: false });

							this.props.showMessage("error", "An error occurred while load file information", fileResponseData.ErrorDescription);
							return;
						}

						this.setState({ FileResultList: fileResponseData.Item, isFileResultDialogOpen: true, isLoading: false, isFileDialogOpen: false });
						this.ClearScreen();
						this.RefreshToolBar();
					},
					errorMessage => {
						this.setState({ isLoading: false, isFileDialogOpen: false });
						this.props.showMessage("error", "An error occurred while load file information", errorMessage);
					}
				);
			},
			errorMessage => {
				this.setState({ isLoading: false, isFileDialogOpen: false });
				this.props.showMessage("error", "An error occurred while upload file", errorMessage);
			}
		);
	}

	addCustomerDialogClose() {
		this.handleChange("IsCustomerPopupOpen", false);
		this.setState({
			customertoken: 1
		});
	}

	showClearQuestionMessage = (message, model) => {
		this.setState({
			alert: <GenericAlert Title="Cancel" Message={message} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.ClearScreen()} />
		});
		this.setState({ isLoading: false });
	}

	GetBiller = (model) => {
		if (!model || !model.ClientCustomerId || !model.BillPayType) {
			this.setState({ BillerList: [] });
			return;
		}
		this.setState({ isLoading: true });
		Proxy.POST("/bankapi/v1.0/Biller/GetAllBiller",
			{
				IsCustomer: true,
				ClientCustomerId: model.ClientCustomerId,
				BillPayType: model.BillPayType == BillPayType.Individual ? 1 : 2
			},
			responseData => {
				if (responseData.IsSucceeded != false) {
					this.setState({ BillerList: responseData.Item, model, isLoading: false });
				}
				else { this.setState({ BillerList: [], StaticBillerList: [], isLoading: false }); }
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			});
	}

	GetAllBiller = () => {
		this.setState({ isLoading: true });
		Proxy.POST("/bankapi/v1.0/Biller/GetAllBiller",
			{
				IsCustomer: true
			},
			responseData => {
				if (responseData.IsSucceeded != false) {
					this.setState({ BillerList: responseData.Item, isLoading: false });
				}
				else { this.setState({ BillerList: [], StaticBillerList: [], isLoading: false }); }
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			});
	}

	GetTelpayBiller = (billerName) => {
		Proxy.POST("/bankapi/v1.0/Biller/GetTelpayBiller",
			{
				Name: billerName
			},
			responseData => {
				if (responseData.IsSucceeded != false) {
					this.setState({ BillerList: responseData.Item });
				}
				else {
					this.setState({ BillerList: [] });
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			});
	}


	BillerNameChange = (name, newValue) => {
		if (name == "BillerCode" && newValue != "") {
			if (newValue.length > 2)
				this.GetTelpayBiller(newValue);
		}
	}

	GetModel = () => {
		const { model, BillIndividualModel, BillRecurringModel, BillFromFileModel } = this.state;

		switch (model.BillPayType) {
			case BillPayType.Individual:
				model.ClientId = ClientHelper.GetClientRowId();
				BillIndividualModel.BillerCode = model.BillerCode;
				BillIndividualModel.ClientCustomerId = model.ClientCustomerId;
				BillIndividualModel.BillerName = model.BillerName;
				return { model, BillIndividualModel: BillIndividualModel };
			case BillPayType.Recurring:
				model.ClientId = ClientHelper.GetClientRowId();
				BillRecurringModel.BillerCode = model.BillerCode;
				BillRecurringModel.ClientCustomerId = model.ClientCustomerId;
				BillRecurringModel.BillerName = model.BillerName;
				return { model, BillRecurringModel: BillRecurringModel };
			case BillPayType.File:
				return { model, BillFromFileModel };
			default: return null;
		}
	}


	FillData = (data) => {
		const { model, BillIndividualModel, BillRecurringModel } = data;

		switch (model.BillPayType) {
			case BillPayType.File:
				this.setState({ model, BillFromFile });
				break;
			case BillPayType.Individual:
				BillIndividualModel.BillerName = model.BillerName;
				this.setState({ model, BillIndividualModel });
				break;
			case BillPayType.Recurring:
				BillRecurringModel.BillerName = model.BillerName;
				BillRecurringModel.StartDate = BillRecurringModel.StartDate ? DateHelper.ToMoment(BillRecurringModel.StartDate) : null;
				BillRecurringModel.EndDate = BillRecurringModel.EndDate ? DateHelper.ToMoment(BillRecurringModel.EndDate) : null;
				BillRecurringModel.FreezeStartDate = BillRecurringModel.FreezeStartDate ? DateHelper.ToMoment(BillRecurringModel.FreezeStartDate) : null;
				BillRecurringModel.FreezeEndDate = BillRecurringModel.FreezeEndDate ? DateHelper.ToMoment(BillRecurringModel.FreezeEndDate) : null;
				this.setState({ model, BillRecurringModel });
				break;
			default: return;
		}
	}

	BillerCodeDisable = () => {
		const { model, BillRecurringModel } = this.state;

		if (model.IsUpdate && model.BillPayType == BillPayType.Individual)
			return false;

		else if (model.BillPayType == BillPayType.Recurring) {
			if (model.IsUpdate && BillRecurringModel && BillRecurringModel.PayIndex && BillRecurringModel.PayIndex > 0)
				return true;
		}
		else if (model.BillPayType == BillPayType.File)
			return true;
	}

	GetModelId() {
		const { model, BillIndividualModel, BillRecurringModel } = this.state;

		switch (model.BillPayType) {
			case BillPayType.File: return null;
			case BillPayType.Individual: return BillIndividualModel.Id;
			case BillPayType.Recurring: return BillRecurringModel.Id;
			default: return null;
		}
	}

	GetRenderItemBiller = (x) => {
		this.renderItemBiller = `${x.BillerName} - ${x.BillerCode}`;
		return this.renderItemBiller;
	}

	render() {

		const { model, BillRecurringModel, BillIndividualModel, BillFromFileModel, BillerList, LimitToken, idIndividualClientWarningOpen, FileResultList, isFileResultDialogOpen, isRecurringDialogOpen, isIndividualDialogOpen, isFileDialogOpen, vFromFileModel, vRecurringModel, vIndividualModel, vModel } = this.state;
		const { Disabled, history } = this.props;
		return (
			<div>

				<GridContainer spacing={16}>
					{this.state.alert}
					<GridItem xs={12}>

						<LoadingComponent Show={this.state.isLoading} />
						{this.state.alert}
						{!Disabled &&
							<AccountLimitToolbar key={LimitToken} Program={ProgramCodes.Bank_Programs_BILL} IsUseDisableDebitLimit={true} OnLoad={data => { this.setState({ LimitBalanceData: data, isLoading: false }); }} />
						}
						<ButtonToolbar
							ButtonList={[

								{ Code: "Submit", Disabled: model.IsUpdate, OnClick: this.SubmitDialog, ModelFunction: this.GetModel, FillDataFromModel: this.FillData, RowId: this.GetModelId(), ValidationFunction: this.Validate },
								{ Code: "Update", Disabled: !model.IsUpdate, OnClick: this.UpdateDialog, ModelFunction: this.GetModel, FillDataFromModel: this.FillData, RowId: this.GetModelId(), ValidationFunction: this.Validate },
								{ Code: "Clear", Disabled: Disabled, OnClick: this.ClearDialog }
							]}
							menuId={this.props.menuId}
							ApprovalData={this.props.ApprovalData}
							After={this.props.After} />

						{this.state.alert}
						<GridContainer>
							<GridItem xs={6}>
								<Card className="no-radius" style={{ height: "90%", opacity: model.IsUpdate ? 0.4 : 1 }}>
									<CardHeader><GenericTitle text={"Main Parameters"} /></CardHeader>
									<CardBody>
										<GridContainer>
											<GridItem xs={8}>
												<ParameterComponent
													Name="BillPayTypeId"
													LabelText="Bill Payment Type"
													LabelMd={4}
													ParameterCode="BillPayType"
													Value={model.BillPayTypeId}
													ValueChanged={this.handleChange}
													Disabled={Disabled || model.IsUpdate}
													Required
													IsInvalid={vModel.BillPayTypeId}
												/>
											</GridItem>
										</GridContainer>
									</CardBody>
								</Card>
							</GridItem>

							<GridItem xs={6}>
								<Card style={{ height: "90%", opacity: "1" }}>
									<CardHeader> <GenericTitle text={"Select  Payee and Customer"} /></CardHeader>
									<CardBody>
										<GridContainer>
											<GridItem xs={8}>
												<GenericSelectInput
													key={[this.state.customertoken, model.ClientId]}
													Labelmd={2}
													Name="ClientCustomerId"
													LabelText="Customer Name"
													Method="POST"
													Url="/bankapi/v1.0/ClientCustomer/GetByProgramCodeAndClientId"
													DataRoot="Item"
													Parameter={{ ProgramCode: ProgramCodes.Bank_Programs_BILL, UniqueClientId: model.ClientId }}
													KeyValueMember="Id"
													TextValueMember="Name"
													Value={model.ClientCustomerId}
													Disabled={Disabled || model.IsUpdate || model.BillPayType == BillPayType.File}
													ValueChanged={this.handleChange}
													Required
													IsInvalid={vModel.ClientCustomerId}
												/>

											</GridItem>
											<GridItem xs={4}>
												<GenericCheckInput Name="NewCustomer" LabelText="New Customer" Value={model.NewCustomer} ValueChanged={this.handleChange} Disabled={Disabled || model.IsUpdate || model.BillPayType == BillPayType.File} />
											</GridItem>
										</GridContainer>

										<GridContainer >
											<GridItem xs={8}>
												{!Disabled &&
													<GenericSelectInput
														Name="BillerCode"
														StaticData={BillerList}
														isStatic={true}
														Value={model.BillerCode}
														KeyValueMember="BillerCode"
														Disabled={Disabled || this.BillerCodeDisable()}
														TextValueMember="BillerName"
														LabelText="Payee Name"
														RenderItem={this.GetRenderItemBiller}
														ValueChanged={this.BillerModelChange}
														InputValueChanged={this.BillerNameChange}
														Required
														IsInvalid={vModel.BillerCode}
													/>
												}
												{Disabled &&
													<GenericTextInput
														Name="BillerName"
														Value={model.BillerName}
														Disabled={Disabled}
														LabelText="Payee Name"
													/>

												}
											</GridItem>
										</GridContainer>

									</CardBody>
								</Card>
							</GridItem>
						</GridContainer>

						<GridContainer>
							<GridItem xs={12}>
								<BillFromFile Disabled={Disabled} model={BillFromFileModel} vModel={vFromFileModel} onModelChange={this.FromFileModelChange} parentModel={model}
								/>
							</GridItem>
						</GridContainer>

						<GridContainer>
							<GridItem xs={12}>
								<BillIndividual
									vModel={vIndividualModel}
									model={this.state.BillIndividualModel}
									onModelChange={this.IndividualModelChange}
									Disabled={this.state.IsPayIndivudalPassive}
								/>
							</GridItem>
						</GridContainer>

						<GridContainer>
							<GridItem xs={12}>
								<BillRecurring
									vModel={vRecurringModel}
									model={this.state.BillRecurringModel}
									onModelChange={this.RecurringModelChange}
									Disabled={this.state.IsPayRecurringPassive}
								/>
							</GridItem>
						</GridContainer>

					</GridItem >
				</GridContainer >

				<GenericDialog open={idIndividualClientWarningOpen} maxWidth="md" fullWidth>
					<DialogTitle>
						<GenericLabel Text="Error" Bold={true} />
					</DialogTitle>
					<DialogContent>
						<div style={{ textAlign: "center" }}>
							<GenericLabel Text={"The individual Client has no authorization to enter the Bill Payment Portal. Please Contact us if you see The Bill Payment Portal at the menu screen."} />
						</div>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={() => history.push("/OnlineSupport")}>Online Support</Button>
						<Button size="sm" onClick={() => history.push("/")}>OK</Button>
					</GenericDialogActions>
				</GenericDialog>

				<GenericDialog open={model.IsCustomerPopupOpen && this.isClient} fullScreen onBackdropClick={this.addCustomerDialogClose} TransitionComponent={Transition}>
					<DialogTitle>
						<GridContainer justify="space-between" alignItems="center">
							<GridItem>
								<GenericLabel FontSize="16px" TextColor="black" Text="Add New Customer" Bold={true} />
							</GridItem>
							<GridItem>
								<IconButton onClick={this.addCustomerDialogClose}>
									<GenericIcon>close</GenericIcon>
								</IconButton>
							</GridItem>
						</GridContainer>
					</DialogTitle>
					<DialogContent>
						<CustomerDefinition
							Programs={[ProgramCodes.Bank_Programs_BILL]}
							menuId={this.props.menuId}
							ApprovalData={this.props.ApprovalData}
							ForInsert
							CustomerSaved={this.addCustomerSaved}
						/>
					</DialogContent>
				</GenericDialog>

				<GenericDialog open={isRecurringDialogOpen} maxWidth="md" fullWidth>
					<DialogTitle>
						<GenericLabel Text="Transaction Confirmation" Bold={true} />
					</DialogTitle>
					<DialogContent>
						<GridContainer xs={12}>
							<GridItem md={12}>
								<GenericTextInput
									LabelMd={2}
									Name="CustomerName"
									LabelText="Customer Name"
									inputProps={{ maxLength: 50 }}
									Value={model.ClientCustomerName}
									Disabled={true}
								/>

								<GenericTextInput
									LabelMd={2}
									Name="Name"
									LabelText="Payee Name"
									inputProps={{ maxLength: 50 }}
									Value={model.BillerName}
									ValueChanged={this.ValueChanged}
									Disabled={true}
								/>

								<GenericTextInput
									LabelMd={2}
									LabelText="Payee Wallet Number"
									Name="AccountNumber"
									inputProps={{ maxLength: 29 }}
									Value={BillRecurringModel.AccountNumber}
									Disabled={true} />
								<GenericTextInput LabelText="Period/No. Of Payment" Value={BillRecurringModel.PaymentCount} Disabled={true} LabelMd={2} />
								<GenericNumberInput Name="RecurringAmount" LabelText="Recurring Amount" Value={BillRecurringModel.RecurringAmount} MaxLength={10} Disabled={true} LabelMd={2} />
								<GenericNumberInput LabelText="Total Amount" Value={BillRecurringModel.RecurringAmount && BillRecurringModel.PaymentCount && BillRecurringModel.RecurringAmount * BillRecurringModel.PaymentCount} Disabled={true} LabelMd={2} />
								<GenericDateInput Name="StartDate" LabelText="Start Date" Value={Formatter.FormatDateUtc(BillRecurringModel.StartDate)} Disabled={true} LabelMd={2} />
								<GenericDateInput Name="EndDate" LabelText="End Date" Value={Formatter.FormatDateUtc(BillRecurringModel.EndDate)} Disabled={true} LabelMd={2} />
								<br />
								<GenericLabel Text={
									model.IsUpdate ?
										"Are you sure you want to apply changes for this transaction ?" :
										"Are you sure you want to enter this transaction ?"} Bold={true} />
							</GridItem>
						</GridContainer>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.Submit}>Confirm</Button>
						<Button size="sm" onClick={() => this.setState({ isRecurringDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>

				<GenericDialog open={isIndividualDialogOpen} maxWidth="md" fullWidth>
					<DialogTitle><GenericLabel Text="Transaction Confirmation" Bold={true} /></DialogTitle>
					<DialogContent>
						<GridContainer xs={12}>
							<GridItem xs={12}>

								<GenericTextInput
									LabelMd={2}
									Name="CustomerName"
									LabelText="Customer Name"
									inputProps={{ maxLength: 50 }}
									Value={model.ClientCustomerName}
									Disabled={true}
								/>
								<GenericTextInput
									LabelMd={2}
									Name="Name"
									LabelText="Payee Name"
									inputProps={{ maxLength: 50 }}
									Value={model.BillerName}
									Disabled={true}
								/>

								<GenericTextInput
									LabelMd={2}
									LabelText="Payee Wallet Number"
									Name="AccountNumber"
									inputProps={{ maxLength: 29 }}
									Value={BillIndividualModel.AccountNumber}
									Disabled={true} />

								<GenericNumberInput Name="Amount" LabelText="Amount" Value={BillIndividualModel.Amount} MaxLength={10} Disabled={true} LabelMd={2} />
								<br />
								<GenericLabel Text={model.IsUpdate ?
									"Are you sure you want to apply changes for this transaction ?" :
									"Are you sure you want to enter this transaction ?"} Bold={true}
								/>

							</GridItem>
						</GridContainer>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.Submit}>Confirm</Button>
						<Button size="sm" onClick={() => this.setState({ isIndividualDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>

				<GenericDialog open={isFileDialogOpen} maxWidth="md" fullWidth>
					<DialogTitle>
						<GenericLabel Text="Transaction Confirmation" Bold={true} />
					</DialogTitle>
					<DialogContent>
						<GenericTextInput Name="FileName" LabelText="File Name" Value={BillFromFileModel.FileName} Disabled={true} />
						<GenericTextInput Name="Date" LabelText="Date" Value={Formatter.FormatDate(DateHelper.GetDateTimeLocal())} Disabled={true} />
						{/* <GenericCheckInput Name="AutoApprove" LabelText="Automatic Approval" Value={BillFromFileModel.AutoApprove} Disabled={true} /> */}
						<br />
						<GenericLabel Text="Are you sure you want to upload file ?" Bold={true} />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.SubmitFileClick}>Confirm</Button>
						<Button size="sm" onClick={() => this.setState({ isFileDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>

				<GenericDialog open={isFileResultDialogOpen} maxWidth="lg" fullWidth>
					<DialogTitle>
						<GridContainer justify="space-between" alignItems="center">
							<GridItem>
								<GenericLabel FontSize="16px" TextColor="black" Text="File Upload Process Done" Bold={true} />
							</GridItem>
							<GridItem>
								<IconButton onClick={() => this.setState({ isFileResultDialogOpen: false })}>
									<GenericIcon>close</GenericIcon>
								</IconButton>
							</GridItem>
						</GridContainer>
					</DialogTitle>
					<DialogContent>
						<GenericGrid
							Columns={[
								{
									Header: "Line Number",
									accessor: "LineId"
								},
								{
									Header: "Is Rejected",
									accessor: "IsRejected",
									Cell: row => row.value ? "Yes" : "No"
								},
								{
									Header: "Comment",
									accessor: "Comment"
								},
								{
									Header: "Pay Type",
									accessor: "BillPayType"
								},
								{
									Header: "Customer Number",
									accessor: "CustomerNumber"
								},
								{
									Header: "Payee Name",
									accessor: "PayeeName"
								},
								{
									Header: "Payee Code",
									accessor: "PayeeCode"
								},
								{
									Header: "Payee Wallet Number",
									accessor: "PayeeAccountNumber"
								},
								{
									Header: "Amount",
									accessor: "Amount"
								},
								{
									Header: "Start Date",
									accessor: "StartDate"
								},
								{
									Header: "End Date",
									accessor: "EndDate"
								},
								{
									Header: "First Due Date Option",
									accessor: "FirstDueDateOption"
								},
								{
									Header: "Period Weekly",
									accessor: "PeriodWeekly"
								},
								{
									Header: "Period BiWeekly",
									accessor: "PeriodBiWeekly"
								},
								{
									Header: "Period Monthly",
									accessor: "PeriodMonthly"
								},
								{
									Header: "Period Quarterly",
									accessor: "PeriodQuarterly"
								},
								{
									Header: "Freeze Start Date",
									accessor: "FreezeStartDate"
								},
								{
									Header: "Freeze End Date",
									accessor: "FreezeEndDate"
								},
								{
									Header: "Description",
									accessor: "Description"
								},
							]}
							Data={FileResultList}
							Sorted={[{ id: "LineId", desc: false }]}
							ProgramCode={ProgramCodes.Bank_Programs_Bank_Account} />
					</DialogContent>
				</GenericDialog>

			</div >


		);
	}

}

BillDefinition.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	PayeeId: PropTypes.any,
	ApprovalData: PropTypes.any,
	onModelChange: PropTypes.func,
	After: PropTypes.any,
	model: PropTypes.object,
};

export default BillDefinition;