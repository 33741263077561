import { DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import createEtransferStyle from "assets/jss/material-dashboard-pro-react/views/createEtransferStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import RouteHelper from "core/RouteHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import AccountLimitToolbar from "views/Components/AccountLimitToolbar.jsx";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericCheckInput, GenericDateInput, GenericDialog, GenericDialogActions, GenericGrid, GenericIcon, GenericLabel, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent.jsx";
import { ClientType, ETransferDayOfMonthTypes, EtransferEntryType, EtransferFrequencyCodes, EtransferPeriodCodes, EtransferTransactionTypes, MenuCodes, Portals, ProgramCodes, EtransferType, GridColumnType, DcbETransferType } from "views/Constants/Constant";
import CreateEtransferFromFile from "./CreateEtransferFromFile";
import CreateEtransferIndividual from "./CreateEtransferIndividual";
import CreateEtransferRecurring from "./CreateEtransferRecurring";

class CreateEtransfer extends Component {

	constructor(props) {
		super(props);

		this.isClient = ClientHelper.IsClient();
		this.uniqueClientId = ClientHelper.GetClientRowId();

		this.defaultModel = {
			EntryType: "",
			EntryCode: "",
			EtransferType: "",
			IsUpdate: false,
			UniqueClientId: this.uniqueClientId
		};

		this.defaultFromFileModel = {
			AutoApprove: false,
			FileName: "",
			File: null
		};
		this.defaultIndividualModel = {
			Id: null,
			PayeeId: "",
			PayeeName: "",
			PayeeEmail: "",
			NewClient: false,
			Amount: null,
			DateOfFunds: DateHelper.GetDateTime(),
			MoneyReqExpDate: DateHelper.GetDateTime().add(9,"days"),
			TransferMemo: "",
			SecurityQuestion: 0,
			SecurityQuestionText: "",
			SecurityQuestionAnswer: "",
			IsCustomerPopupOpen: false,
			TransactionType: "",
			TransactionTypeCode: null,
			HasDDR: false,
			maxExpireDate: 30,
			IsHaveQuestion: false,
			NotificationTypeId: 0,
			ServiceType: 0,
			PhoneNumber: "",
			CustomerStoreId: 0,
			TransferType: -1
		};

		this.defaultRecurringModel = {
			Id: null,
			PayeeId: "",
			PayeeName: "",
			PayeeEmail: "",
			NewClient: false,
			StartDate: DateHelper.GetDateTime(),
			EndDate: DateHelper.GetDateTime(),
			PaymentCount: null,
			RecurringAmount: null,
			TransferMemo: "",
			SecurityQuestion: 0,
			SecurityQuestionText: "",
			SecurityQuestionAnswer: "",
			FrequencyType: "",
			FrequencyCode: "",
			PeriodType: "",
			PeriodCode: "",
			DayOfWeekId: "",
			DayOfWeekNumber: "",
			DayOfMonthTypeId: "",
			DayOfMonthTypeNumber: "",
			DayOfMonthId: "",
			DayOfMonthNumber: "",
			WeekOfMonthTypeId: "",
			WeekOfMonthTypeNumber: "",
			WeekOfMonthId: "",
			WeekOfMonthNumber: "",
			IsCustomerPopupOpen: false,
			TransactionType: "",
			TransactionTypeCode: null,
			FreezeStartDate: null,
			FreezeEndDate: null,
			HasDDR: false,
			IsHaveQuestion: false,
			NotificationTypeId: 0,
			ServiceType: 0,
			PhoneNumber: "",
			CustomerStoreId: 0
		};

		this.state = {
			isLoading: this.isClient,
			isFileDialogOpen: false,
			isIndividualDialogOpen: false,
			isRecurringDialogOpen: false,
			isClearDialogOpen: false,
			isFileResultDialogOpen: false,
			idIndividualClientWarningOpen: false,
			FileResultList: [],
			model: { ...this.defaultModel },
			vModel: {},
			FromFileModel: { ...this.defaultFromFileModel },
			vFromFileModel: {},
			IndividualModel: { ...this.defaultIndividualModel },
			vIndividualModel: {},
			RecurringModel: { ...this.defaultRecurringModel },
			vRecurringModel: {},
			LimitToken: false,
			LimitBalanceData: {
				AvailableCreditLimit: 0,
				AvailableDebitLimit: 0,
				AvailableBalance: 0
			},
			addNewCustomerMenuId: 0,
			UpdateRefreshIndividual: false,
			UpdateRefreshRecurring: false,
			CustomerStoreList: []
		};

		this.ValueChanged = this.ValueChanged.bind(this);
		this.FromFileModelChange = this.FromFileModelChange.bind(this);
		this.IndividualModelChange = this.IndividualModelChange.bind(this);
		this.RecurringModelChange = this.RecurringModelChange.bind(this);
		this.SubmitClick = this.SubmitClick.bind(this);
		this.ClearClick = this.ClearClick.bind(this);
		this.Validate = this.Validate.bind(this);
		this.ClearScreen = this.ClearScreen.bind(this);
		this.ClearDialogConfirm = this.ClearDialogConfirm.bind(this);

		this.SubmitFileClick = this.SubmitFileClick.bind(this);
		this.SubmitIndividualClick = this.SubmitIndividualClick.bind(this);
		this.SubmitRecurringClick = this.SubmitRecurringClick.bind(this);
		this.RefreshToolBar = this.RefreshToolBar.bind(this);

		this.GetModel = this.GetModel.bind(this);
		this.FillData = this.FillData.bind(this);
		this.GetModelId = this.GetModelId.bind(this);
		this.LimitDataLoad = this.LimitDataLoad.bind(this);
	}

	componentDidMount() {
		const { setAppLeftTitle, setAppCenterTitle, setPortal } = this.props;
		this.GetMaxExpireDate();
		if (setAppLeftTitle)
			setAppLeftTitle("Create e-Transfer");
		if (setAppCenterTitle)
			setAppCenterTitle("Interac e-Transfer");
		if (setPortal)
			setPortal(Portals.eTransfer);

		Proxy.GET("/bankapi/v1.0/BankCustomer/CheckClientIndividual",
			responseData => {
				if (!responseData.IsSucceeded) {
					// this.props.showMessage("error", "An error occurred while fetching data", responseData.ErrorDescription);
					return;
				}

				var data = responseData.Item;
				if (data) {
					if (data.IsClient && data.IsIndividual)
						this.setState({ idIndividualClientWarningOpen: true });
				}
			});

		Proxy.POST("/coreapi/v1.0/Menu/GetByMenuCode",
			MenuCodes.CustomerDefinition,
			responseData => {
				if (!responseData.IsSucceeded) {
					// this.props.showMessage("error", "An error occurred while fetching data", responseData.ErrorDescription);
					return;
				}

				var menu = responseData.Item;
				if (menu) {
					var menuId = menu.Id;
					this.setState({ addNewCustomerMenuId: menuId });
				}
			});

		var Id = RouteHelper.ReadAndClear("CreateEtransfer_Id");
		var RecurringId = RouteHelper.ReadAndClear("CreateEtransfer_RecurringId");
		if ((Id == null || Id <= 0) && RecurringId == null || RecurringId <= 0) {
			this.GetCustomerStore();
		}

	 


		// Individual
		if (Id) {
			this.setState({ isLoading: true });
			Proxy.POST("/bankapi/v1.0/BankInteracETransfer/GetByIdRequest",
				{ Item: Id },
				responseData => {
					this.setState({ isLoading: false });

					if (!responseData.IsSucceeded) {
						this.props.showMessage("error", "An error occurred while fetching data", responseData.ErrorDescription);
						return;
					}

					var etransferData = responseData.Item;
					var data = etransferData.Transaction;

					this.setState(function (state) {
						var model = state.model || {};
						var IndividualModel = state.IndividualModel || {};

						model.UniqueClientId = data.UniqueClientId;

						model.EntryType = data.EntryTypeId;
						model.EntryCode = EtransferEntryType.Individual;
						model.EtransfertypeParamValue = etransferData.PriorityType.ParameterValue;
						model.EtransferType = etransferData.PriorityTypeId;

						model.IsUpdate = true;
						IndividualModel.Id = data.Id;
						IndividualModel.PayeeId = etransferData.PayeeId;						
						IndividualModel.CustomerStoreId = data.StoreId;
						IndividualModel.PayeeName = etransferData.PayeeName;
						IndividualModel.PayeeEmail = etransferData.PayeeEmail;
						IndividualModel.Amount = data.TransactionAmount;
						IndividualModel.DateOfFunds = data.TransactionDate ? DateHelper.ToMoment(data.TransactionDate) : null;
						IndividualModel.MoneyReqExpDate = etransferData.MoneyRequestExpiryDate ? DateHelper.ToMoment(etransferData.MoneyRequestExpiryDate) : null;
						IndividualModel.SecurityQuestion = etransferData.SecurityQuestionId;
						IndividualModel.IsHaveQuestion = etransferData.SecurityQuestionId != null && etransferData.SecurityQuestionId > 0;
						IndividualModel.SecurityQuestionText = etransferData.SecurityQuestion;
						IndividualModel.SecurityQuestionAnswer = etransferData.SecurityQuestionAnswer;
						IndividualModel.TransferMemo = etransferData.Memo;
						IndividualModel.TransactionType = data.TransactionTypeId;
						IndividualModel.TransactionTypeCode = data.TransactionType ? data.TransactionType.ParameterValue : "";
						IndividualModel.NotificationTypeId = etransferData.NotificationTypeId;
						IndividualModel.NotificationType = etransferData.NotificationType;
						IndividualModel.ServiceType = etransferData.NotificationType != null ? etransferData.NotificationType.ParameterValue3 : 0;
						IndividualModel.PhoneNumber = etransferData.Payee != null ? etransferData.Payee.Phone != null ? etransferData.Payee.Phone.PhoneNumber : "" : "";

						return { model, IndividualModel, UpdateRefreshIndividual: !this.state.UpdateRefreshIndividual };
					}, () => this.GetCustomerStore());
				},
				errorMessage => {
					this.setState({ isLoading: false });
					this.props.showMessage("error", "An error occurred while fetching data", errorMessage);
				}
			);
		}
		// Recurring
		else if (RecurringId) {
			this.setState({ isLoading: true });
			Proxy.POST("/bankapi/v1.0/BankInteracETransfer/GetByIdRecurringRequest",
				{ Item: RecurringId },
				responseData => {
					this.setState({ isLoading: false });

					if (!responseData.IsSucceeded) {
						this.props.showMessage("error", "An error occurred while fetching data", responseData.ErrorDescription);
						return;
					}

					var data = responseData.Item;

					this.setState(function (state) {
						var model = state.model || {};
						var RecurringModel = state.RecurringModel || {};

						model.UniqueClientId = data.UniqueClientId;

						model.EntryType = data.EntryTypeId;
						model.EntryCode = EtransferEntryType.Recurring;
						model.EtransferType = data.PriorityTypeId;

						model.IsUpdate = true;
						RecurringModel.Id = data.Id;
						RecurringModel.PayeeId = data.PayeeId;
						RecurringModel.CustomerStoreId = data.CustomerStoreId;

						if (data.CustomerPayee && data.CustomerPayee.ClientType) {
							RecurringModel.PayeeEmail = data.CustomerPayee.Email;
							if (data.CustomerPayee.ClientType.ParameterValue == ClientType.Corporate)
								RecurringModel.PayeeName = data.CustomerPayee.CompanyLegalName;
							else if (data.CustomerPayee.ClientType.ParameterValue == ClientType.Personal)
								RecurringModel.PayeeName = data.CustomerPayee.FirstName + " " + data.CustomerPayee.LastName;
						}

						RecurringModel.StartDate = data.StartingFrom ? DateHelper.ToMoment(data.StartingFrom) : null;
						RecurringModel.EndDate = data.EndOfDate ? DateHelper.ToMoment(data.EndOfDate) : null;
						RecurringModel.FreezeStartDate = data.FreezeFromDate ? DateHelper.ToMoment(data.FreezeFromDate) : null;
						RecurringModel.FreezeEndDate = data.FreezeToDate ? DateHelper.ToMoment(data.FreezeToDate) : null;
						RecurringModel.RecurringAmount = data.TransactionAmount;
						RecurringModel.TransferMemo = data.Memo;
						RecurringModel.SecurityQuestion = data.SecurityQuestionId;
						RecurringModel.IsHaveQuestion = data.SecurityQuestionId != null && data.SecurityQuestionId > 0;
						RecurringModel.SecurityQuestionText = data.SecurityQuestionText;
						RecurringModel.SecurityQuestionAnswer = data.SecurityQuestionAnswer;
						RecurringModel.FrequencyType = data.FrequencyTypeId;
						RecurringModel.FrequencyCode = data.FrequencyType != null ? data.FrequencyType.ParameterValue : "";
						RecurringModel.PeriodType = data.PeriodTypeId;
						RecurringModel.PeriodCode = data.PeriodType != null ? data.PeriodType.ParameterValue : "";
						RecurringModel.DayOfWeekId = data.DayOfWeekId;
						RecurringModel.DayOfWeekNumber = data.DayOfWeek != null ? data.DayOfWeek.ParameterValue : "";
						RecurringModel.DayOfMonthTypeId = data.DayOfMonthTypeId;
						RecurringModel.DayOfMonthTypeNumber = data.DayOfMonthType != null ? data.DayOfMonthType.ParameterValue : "";
						RecurringModel.DayOfMonthId = data.DayOfMonthId;
						RecurringModel.DayOfMonthNumber = data.DayOfMonth != null ? data.DayOfMonth.ParameterValue : "";
						RecurringModel.WeekOfMonthTypeId = data.WeekOfMonthTypeId;
						RecurringModel.WeekOfMonthTypeNumber = data.WeekOfMonthType != null ? data.WeekOfMonthType.ParameterValue : "";
						RecurringModel.WeekOfMonthId = data.WeekOfMonthId;
						RecurringModel.WeekOfMonthNumber = data.WeekOfMonth != null ? data.WeekOfMonth.ParameterValue : "";
						RecurringModel.PaymentCount = data.NumberOfPayments;
						RecurringModel.TransactionType = data.TransactionTypeId;
						RecurringModel.TransactionTypeCode = data.TransactionType ? data.TransactionType.ParameterValue : "";
						RecurringModel.NotificationTypeId = data.NotificationTypeId;
						RecurringModel.NotificationType = data.NotificationType;
						RecurringModel.ServiceType = data.NotificationType != null ? data.NotificationType.ParameterValue3 : 0;
						RecurringModel.PhoneNumber = data.CustomerPayee != null ? data.CustomerPayee.Phone != null ? data.CustomerPayee.Phone.PhoneNumber : "" : "";

						return { model, RecurringModel, UpdateRefreshRecurring: !this.state.UpdateRefreshRecurring };
					}, () => this.GetCustomerStore());
				},
				errorMessage => {
					this.setState({ isLoading: false });
					this.props.showMessage("error", "An error occurred while fetching data", errorMessage);
				}
			);
		}
	}
 	GetCustomerStore = () => {
		Proxy.POST("/bankapi/v1.0/CustomerStore/GetStoreAccountMaps", { Id: this.state.model != null ? this.state.model.UniqueClientId : 0, ProgramCode: ProgramCodes.Bank_Programs_e_Transfer },
			responseData => {
				if (!responseData.IsSucceeded) {
					console.log(responseData.ErrorDescription);
					this.setState({ CustomerStoreList: [] });
					return;
				}
				this.setState({ CustomerStoreList: responseData.Item || [] });
			},
			errorMessage => {
				this.setState({ CustomerStoreList: [] });
				console.log(errorMessage);
			});
	}
	GetMaxExpireDate = () => {
		Proxy.POST("/coreapi/v1.0/Parameter/Search",
			{
				ParameterCode: "InteracEtransferExpireDate",
				ParameterValue: "MX"
			},
			responseData => {
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", "An error occurred while request " + responseData.ErrorDescription);
					return;
				}
				if (responseData.Item.length != null && responseData.Item.length > 0) {
					this.setState(state => {
						var maxExpDate = DateHelper.GetDateTime().add((state.IndividualModel.maxExpireDate - 1), "d");
						if (state.IndividualModel.MoneyReqExpDate >= maxExpDate) {
							state.IndividualModel.maxExpireDate = responseData.Item[0].ParameterValue2 > 30 ? 30 : responseData.Item[0].ParameterValue2;
							state.IndividualModel.MoneyReqExpDate = DateHelper.GetDateTime().add((state.IndividualModel.maxExpireDate - 1), "d");
						}

						return state;
					});
				}
			},
			e => {
				this.props.showMessage("error", "Error", "An error occurred while inserting data ");
			}
		);
	}
	RefreshToolBar() {
		this.setState({ LimitToken: !this.state.LimitToken });
	}

	ValueChanged(name, newValue, data) {
		this.setState(function (state) {
			var model = state.model || {};
			model[name] = newValue;
			if (name == "EntryType" && data != null)
				model.EntryCode = data.ParameterValue;
			return { model };
		});
	}

	FromFileModelChange(modelFunction) {
		this.setState(function (state) {
			var model = state.FromFileModel || {};

			if (modelFunction)
				model = modelFunction(model);

			return { FromFileModel: model };
		});
	}

	IndividualModelChange(modelFunction) {
		this.setState(function (state) {
			var model = state.IndividualModel || {};

			if (modelFunction)
				model = modelFunction(model);

			return { IndividualModel: model };
		});
	}

	RecurringModelChange(modelFunction) {
		this.setState(function (state) {
			var model = state.RecurringModel || {};

			if (modelFunction)
				model = modelFunction(model);

			return { RecurringModel: model };
		});
	}

	SubmitClick(workflowId, after, accessToken) {
		const { DateOfFunds, MoneyReqExpDate } = this.state.IndividualModel;
		const diffMonths = MoneyReqExpDate.diff(DateOfFunds, "days");
		if(diffMonths > 30) {
			this.props.showMessage("warning", "Please fill required fields!", "Between two dates cannot be longer than 30 days!");
		}
		if (!this.Validate() && diffMonths < 30)
			return;

		const { model } = this.state;

		switch (model.EntryCode) {
			case EtransferEntryType.FromFile:
				this.setState({ isFileDialogOpen: true, workflowId, after, accessToken });
				break;
			case EtransferEntryType.Individual:
				this.setState({ isIndividualDialogOpen: true, workflowId, after, accessToken });
				break;
			case EtransferEntryType.Recurring:
				this.setState({ isRecurringDialogOpen: true, workflowId, after, accessToken });
				break;
			default:
				break;
		}
	}

	SubmitFileClick() {
		const { FromFileModel } = this.state;

		this.setState({ isLoading: true });

		var formData = new FormData();
		formData.append("file", FromFileModel.File, FromFileModel.FileName);
		formData.append("approved", String(FromFileModel.AutoApprove));

		Proxy.FileUpload("/bankapi/v1.0/BankInteracETransferFile/UploadCreateTransactionFile",
			formData,
			responseData => {
				this.setState({ isLoading: false, isFileDialogOpen: false });

				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "An error occurred while upload file", responseData.ErrorDescription);
					return;
				}

				var fileId = responseData.Item ? responseData.Item.FileId : 0;

				this.setState({ isLoading: true });
				Proxy.POST("/bankapi/v1.0/BankInteracETransferFile/GetFileInformation",
					{ Id: fileId },
					fileResponseData => {
						this.setState({ isLoading: false, isFileDialogOpen: false });

						if (!fileResponseData.IsSucceeded) {
							this.props.showMessage("error", "An error occurred while load file information", fileResponseData.ErrorDescription);
							return;
						}

						this.setState({ FileResultList: fileResponseData.Item, isFileResultDialogOpen: true });
					},
					errorMessage => {
						this.setState({ isLoading: false, isFileDialogOpen: false });
						this.props.showMessage("error", "An error occurred while load file information", errorMessage);
					}
				);
			},
			errorMessage => {
				this.setState({ isLoading: false, isFileDialogOpen: false });
				this.props.showMessage("error", "An error occurred while upload file", errorMessage);
			}
		);
	}

	SubmitIndividualClick() {
		const { model, workflowId, after, accessToken } = this.state;

		var individualRequest = this.GetModel();

		this.setState({ isLoading: true });

		if (!model.IsUpdate)
			Proxy.POST("/bankapi/v1.0/BankInteracETransfer/InsertIndividualRequest",
				individualRequest,
				responseData => {
					this.setState({ isLoading: false, isIndividualDialogOpen: false });

					if (!responseData.IsSucceeded) {
						this.props.showMessage("error", "An error occurred while inserting data", responseData.ErrorDescription);
						return;
					}

					if (after) after();

					this.props.showMessage("success", "Individual Transaction Entry successfully saved.");

					if (this.isClient)
						this.ClearScreen();
				},
				errorMessage => {
					this.setState({ isLoading: false, isIndividualDialogOpen: false });
					this.props.showMessage("error", "An error occurred while inserting data", errorMessage);
				},
				workflowId,
				accessToken
			);
		else
			Proxy.POST("/bankapi/v1.0/BankInteracETransfer/EditETransferRequest",
				individualRequest,
				responseData => {
					this.setState({ isLoading: false, isIndividualDialogOpen: false });

					if (!responseData.IsSucceeded) {
						this.props.showMessage("error", "An error occurred while updating data", responseData.ErrorDescription);
						return;
					}

					if (after) after();

					this.props.showMessage("success", "Individual Transaction Entry successfully updated.");

					if (this.isClient)
						this.ClearScreen();
				},
				errorMessage => {
					this.setState({ isLoading: false, isIndividualDialogOpen: false });
					this.props.showMessage("error", "An error occurred while updating data", errorMessage);
				},
				workflowId,
				accessToken
			);
	}

	SubmitRecurringClick() {
		const { model, workflowId, after, accessToken } = this.state;

		var recurringRequest = this.GetModel();
		this.setState({ isLoading: true });

		if (!model.IsUpdate)
			Proxy.POST("/bankapi/v1.0/BankInteracETransfer/InsertRecurringRequest",
				recurringRequest,
				responseData => {
					this.setState({ isLoading: false, isRecurringDialogOpen: false });

					if (!responseData.IsSucceeded) {
						this.props.showMessage("error", "An error occurred while inserting data", responseData.ErrorDescription);
						return;
					}

					if (after) after();

					this.props.showMessage("success", "Recurring Transaction Entry successfully saved.");

					if (this.isClient)
						this.ClearScreen();
				},
				errorMessage => {
					this.setState({ isLoading: false, isRecurringDialogOpen: false });
					this.props.showMessage("error", "An error occurred while inserting data", errorMessage);
				},
				workflowId,
				accessToken
			);
		else
			Proxy.POST("/bankapi/v1.0/BankInteracETransfer/EditRecurringRequest",
				recurringRequest,
				responseData => {
					this.setState({ isLoading: false, isRecurringDialogOpen: false });

					if (!responseData.IsSucceeded) {
						this.props.showMessage("error", "An error occurred while updating data", responseData.ErrorDescription);
						return;
					}

					if (after) after();

					this.props.showMessage("success", "Recurring Transaction Entry successfully updated.");

					if (this.isClient)
						this.ClearScreen();
				},
				errorMessage => {
					this.setState({ isLoading: false, isRecurringDialogOpen: false });
					this.props.showMessage("error", "An error occurred while updating data", errorMessage);
				},
				workflowId,
				accessToken
			);
	}

	ClearClick() {
		this.setState({ isClearDialogOpen: true });
	}

	ClearDialogConfirm() {
		this.ClearScreen();
	}

	ClearScreen() {
		this.setState({ model: { ...this.defaultModel }, vModel: {}, vFromFileModel: {}, vIndividualModel: {}, vRecurringModel: {}, isClearDialogOpen: false, isLoading: true, isFileResultDialogOpen: false });
		this.FromFileModelChange(() => { return { ...this.defaultFromFileModel }; });
		this.IndividualModelChange(() => { return { ...this.defaultIndividualModel }; });
		this.RecurringModelChange(() => { return { ...this.defaultRecurringModel }; });
		this.RefreshToolBar();
		this.GetMaxExpireDate();
	}

	Validate() {
		const { model, vModel, FromFileModel, vFromFileModel, IndividualModel, vIndividualModel, RecurringModel, vRecurringModel } = this.state;

		vModel.EntryType = model.EntryType == null || model.EntryType == "";
		if (vModel.EntryType) {
			this.props.showMessage("warning", "Entry Type not selected", "Select Entry Type to continue.");
			this.setState({ vModel });
			return false;
		}

		vModel.EtransferType = (model.EtransferType == null || model.EtransferType == "") && model.EntryCode != EtransferEntryType.FromFile;
		if (vModel.EtransferType) {
			this.props.showMessage("warning", "e-Transfer Type not selected", "Select e-Transfer Type to continue.");
			this.setState({ vModel });
			return false;
		}

		switch (model.EntryCode) {
			case EtransferEntryType.FromFile:
				vFromFileModel.File = !FromFileModel.File;
				if (vFromFileModel.File) {
					this.props.showMessage("warning", "File not selected", "Select File to continue.");
					this.setState({ vModel });
					return false;
				}
				return true;

			case EtransferEntryType.Individual:
				var individualMessages = [];
				// if (this.state.CustomerStoreList.length > 0) {
				// 	vIndividualModel.CustomerStoreId = IndividualModel.CustomerStoreId == null || IndividualModel.CustomerStoreId <= 0;
				// 	if (vIndividualModel.CustomerStoreId) individualMessages.push("Customer Store cannot be null.");
				// }
				vIndividualModel.TransactionType = IndividualModel.TransactionType == null || IndividualModel.TransactionType == "";
				if (vIndividualModel.TransactionType) individualMessages.push("Transaction Type cannot be null.");
				vIndividualModel.PayeeId = IndividualModel.PayeeId == null || IndividualModel.PayeeId == "";
				if (vIndividualModel.PayeeId) individualMessages.push("Customer Name cannot be null.");
				vIndividualModel.Amount = IndividualModel.Amount == null || IndividualModel.Amount == "";
				if (vIndividualModel.Amount) individualMessages.push("Amount cannot be null.");

				vIndividualModel.DateOfFunds = IndividualModel.DateOfFunds == null;
				if (vIndividualModel.DateOfFunds) individualMessages.push("Date Of Funds cannot be null.");
				else {
					vIndividualModel.DateOfFunds = typeof IndividualModel.DateOfFunds != "object";
					if (vIndividualModel.DateOfFunds) individualMessages.push("Date Of Funds is invalid.");
					else {
						vIndividualModel.DateOfFunds = !model.IsUpdate && IndividualModel.DateOfFunds < DateHelper.GetDateLocal();
						if (vIndividualModel.DateOfFunds) individualMessages.push("Date Of Funds cannot be past.");
					}
				}

				vIndividualModel.MoneyReqExpDate = IndividualModel.MoneyReqExpDate == null;
				if (vIndividualModel.MoneyReqExpDate) individualMessages.push("Money Request Expiry Date cannot be null.");
				else {
					vIndividualModel.MoneyReqExpDate = typeof IndividualModel.MoneyReqExpDate != "object";
					if (vIndividualModel.MoneyReqExpDate) individualMessages.push("Money Request Expiry Date is invalid.");
					else {
						vIndividualModel.MoneyReqExpDate = IndividualModel.MoneyReqExpDate < DateHelper.GetDateLocal();
						if (vIndividualModel.MoneyReqExpDate) individualMessages.push("Money Request Expiry Date cannot be past.");
					}
				}

				if (IndividualModel.TransactionTypeCode == EtransferTransactionTypes.Credit && !IndividualModel.HasDDR && IndividualModel.TransferType != DcbETransferType.AccountDeposit) {
					if (IndividualModel.IsHaveQuestion && IndividualModel.IsHaveQuestion == true) {
						vIndividualModel.SecurityQuestion = IndividualModel.SecurityQuestion == null || IndividualModel.SecurityQuestion == 0;
						if (vIndividualModel.SecurityQuestion) individualMessages.push("Security Question is not selected.");
						else {
							let isTooShortSecurityQuestionAnswer = IndividualModel.SecurityQuestionAnswer?.length && IndividualModel.SecurityQuestionAnswer?.length < 3;
							vIndividualModel.SecurityQuestionAnswer = IndividualModel.SecurityQuestionAnswer == null || IndividualModel.SecurityQuestionAnswer == "" || isTooShortSecurityQuestionAnswer;
							if (vIndividualModel.SecurityQuestionAnswer) {
								if (!isTooShortSecurityQuestionAnswer)
									individualMessages.push("Security Question Answer cannot be null.");
								else
									individualMessages.push("Security Question Answer cannot be smaller than 3 character");
							};
						}
					}
					else {
						vIndividualModel.SecurityQuestionText = IndividualModel.SecurityQuestionText == null || IndividualModel.SecurityQuestionText == "" || IndividualModel.SecurityQuestionText?.length < 3;
						if (vIndividualModel.SecurityQuestionText) individualMessages.push(IndividualModel.SecurityQuestionText?.length && IndividualModel.SecurityQuestionText?.length < 3 ? "Security Question Text cannot be smaller than 3 character" : "Security Question is not defined.");
						else {
							let isTooShortSecurityQuestionAnswer = IndividualModel.SecurityQuestionAnswer?.length && IndividualModel.SecurityQuestionAnswer?.length < 3;
							vIndividualModel.SecurityQuestionAnswer = IndividualModel.SecurityQuestionAnswer == null || IndividualModel.SecurityQuestionAnswer == "" || isTooShortSecurityQuestionAnswer;
							if (vIndividualModel.SecurityQuestionAnswer) {
								if (!isTooShortSecurityQuestionAnswer)
									individualMessages.push("Security Question Answer cannot be null.");
								else
									individualMessages.push("Security Question Answer cannot be smaller than 3 character");
							};
						}
					}
				}
				vIndividualModel.NotificationTypeId = IndividualModel.NotificationTypeId == null || IndividualModel.NotificationTypeId == 0;
				if (vIndividualModel.NotificationTypeId) individualMessages.push("Notification Type cannot be null.");

				if (IndividualModel.ServiceType == 0 && (IndividualModel.PayeeEmail == null || IndividualModel.PayeeEmail == "")) {
					individualMessages.push("Customer eMail cannot be null.");
				}

				if (IndividualModel.ServiceType == 1 && (IndividualModel.PhoneNumber == null || IndividualModel.PhoneNumber == "")) {
					individualMessages.push("Customer Phone Number cannot be null.");
				}

				if (individualMessages.length > 0) {
					this.props.showMessage("warning", "Please fill required fields!", individualMessages.map((x, i) => <div key={i}>{x}</div>));
					this.setState({ vIndividualModel });
					return false;
				}

				return true;

			case EtransferEntryType.Recurring:
				var recurringMessages = [];
				if (this.state.CustomerStoreList.length > 0) {
					vRecurringModel.CustomerStoreId = RecurringModel.CustomerStoreId == null || RecurringModel.CustomerStoreId <= 0;
					if (vRecurringModel.CustomerStoreId) recurringMessages.push("Customer Store cannot be null.");
				}
				vRecurringModel.TransactionType = RecurringModel.TransactionType == null || RecurringModel.TransactionType == "";
				if (vRecurringModel.TransactionType) recurringMessages.push("Transaction Type cannot be null.");
				vRecurringModel.PayeeId = RecurringModel.PayeeId == null || RecurringModel.PayeeId == "";
				if (vRecurringModel.PayeeId) recurringMessages.push("Customer Name cannot be null.");
				vRecurringModel.NotificationTypeId = RecurringModel.NotificationTypeId == null || RecurringModel.NotificationTypeId == 0;
				if (vRecurringModel.NotificationTypeId) recurringMessages.push("Notification Type cannot be null.");

				if (RecurringModel.ServiceType == 0 && (RecurringModel.PayeeEmail == null || RecurringModel.PayeeEmail == "")) {
					recurringMessages.push("Customer eMail cannot be null.");
				}

				if (RecurringModel.ServiceType == 1 && (RecurringModel.PhoneNumber == null || RecurringModel.PhoneNumber == "")) {
					recurringMessages.push("Customer Phone Number cannot be null.");
				}

				vRecurringModel.StartDate = RecurringModel.StartDate == null;
				if (vRecurringModel.StartDate) recurringMessages.push("Start Date cannot be null.");
				else {
					vRecurringModel.StartDate = typeof RecurringModel.StartDate != "object";
					if (vRecurringModel.StartDate) recurringMessages.push("Start Date is invalid.");
					else {
						vRecurringModel.StartDate = !model.IsUpdate && RecurringModel.StartDate < DateHelper.GetLocalDateAsUtc();
						if (vRecurringModel.StartDate) recurringMessages.push("Start Date cannot be past.");
					}
				}

				vRecurringModel.EndDate = RecurringModel.EndDate == null;
				if (vRecurringModel.EndDate) recurringMessages.push("End Date cannot be null.");
				else {
					vRecurringModel.EndDate = typeof RecurringModel.EndDate != "object";
					if (vRecurringModel.EndDate) recurringMessages.push("End Date is invalid.");
					else {
						vRecurringModel.EndDate = RecurringModel.EndDate < DateHelper.GetLocalDateAsUtc();
						if (vRecurringModel.EndDate) recurringMessages.push("End Date cannot be past.");
					}
				}

				if (vRecurringModel.StartDate == false && vRecurringModel.EndDate == false && RecurringModel.StartDate > RecurringModel.EndDate) recurringMessages.push("Start Date cannot be after End Date.");

				if (model.IsUpdate) {
					vRecurringModel.FreezeStartDate = RecurringModel.FreezeStartDate != null && typeof RecurringModel.FreezeStartDate != "object";
					if (vRecurringModel.FreezeStartDate) recurringMessages.push("Freeze Start Date is invalid.");
					else {
						vRecurringModel.FreezeStartDate = RecurringModel.FreezeStartDate != null && RecurringModel.FreezeStartDate < RecurringModel.StartDate;
						if (vRecurringModel.FreezeStartDate) recurringMessages.push("Freeze Start Date cannot be before Start Date.");
					}

					vRecurringModel.FreezeEndDate = RecurringModel.FreezeEndDate != null && typeof RecurringModel.FreezeEndDate != "object";
					if (vRecurringModel.FreezeEndDate) recurringMessages.push("Freeze End Date is invalid.");
					else {
						vRecurringModel.FreezeEndDate = RecurringModel.FreezeEndDate != null && RecurringModel.FreezeEndDate > RecurringModel.EndDate;
						if (vRecurringModel.FreezeEndDate) recurringMessages.push("Freeze End Date cannot be after End Date.");
					}

					if (vRecurringModel.FreezeStartDate == false && vRecurringModel.FreezeEndDate == false && RecurringModel.FreezeStartDate > RecurringModel.FreezeEndDate) recurringMessages.push("Freeze Start Date cannot be after Freeze End Date.");
				}

				vRecurringModel.PaymentCount = RecurringModel.PaymentCount == null;
				if (vRecurringModel.PaymentCount) recurringMessages.push("Period/No. Of Payment cannot be null.");
				vRecurringModel.RecurringAmount = RecurringModel.RecurringAmount == null || RecurringModel.RecurringAmount == "";
				if (vRecurringModel.RecurringAmount) recurringMessages.push("Recurring Amount cannot be null.");
				vRecurringModel.FrequencyType = RecurringModel.FrequencyType == null || RecurringModel.FrequencyType == "";
				if (vRecurringModel.FrequencyType) recurringMessages.push("Frequency is not selected.");
				if (RecurringModel.FrequencyCode == EtransferFrequencyCodes.Weekly || RecurringModel.FrequencyCode == EtransferFrequencyCodes.Monthly) {
					vRecurringModel.PeriodType = RecurringModel.PeriodType == null || RecurringModel.PeriodType == "";
					if (vRecurringModel.PeriodType) recurringMessages.push("Period is not selected.");
					else {
						if (RecurringModel.PeriodCode == EtransferPeriodCodes.DayOfMonth) {
							vRecurringModel.DayOfMonthTypeId = RecurringModel.DayOfMonthTypeNumber == null || RecurringModel.DayOfMonthTypeNumber == "";
							if (vRecurringModel.DayOfMonthTypeId)
								recurringMessages.push("Day of month type is not selected.");
							else {
								vRecurringModel.DayOfMonthId = RecurringModel.DayOfMonthTypeNumber == ETransferDayOfMonthTypes.SelectedDay && (RecurringModel.DayOfMonthNumber == null || RecurringModel.DayOfMonthNumber == "");
								if (vRecurringModel.DayOfMonthId)
									recurringMessages.push("Day of month is not selected.");
							}
						}
						if (RecurringModel.PeriodCode == EtransferPeriodCodes.DayOfWeek) {
							vRecurringModel.DayOfWeekId = RecurringModel.DayOfWeekNumber == null || RecurringModel.DayOfWeekNumber == "";
							if (vRecurringModel.DayOfWeekId)
								recurringMessages.push("Day of week is not selected.");
						}
						if (RecurringModel.PeriodCode == EtransferPeriodCodes.WeekOfMonth) {
							vRecurringModel.WeekOfMonthTypeId = RecurringModel.WeekOfMonthTypeNumber == null || RecurringModel.WeekOfMonthTypeNumber == "";
							if (vRecurringModel.WeekOfMonthTypeId)
								recurringMessages.push("Week of month type is not selected.");
							vRecurringModel.WeekOfMonthId = RecurringModel.WeekOfMonthNumber == null || RecurringModel.WeekOfMonthNumber == "";
							if (vRecurringModel.WeekOfMonthId)
								recurringMessages.push("Week of month is not selected.");
						}
					}
				}

				if (RecurringModel.TransactionTypeCode == EtransferTransactionTypes.Credit && !RecurringModel.HasDDR) {
					if (RecurringModel.IsHaveQuestion && RecurringModel.IsHaveQuestion == true) {
						vRecurringModel.SecurityQuestion = RecurringModel.SecurityQuestion == null || RecurringModel.SecurityQuestion == 0;
						if (vRecurringModel.SecurityQuestion) recurringMessages.push("Security Question is not selected.");
						else {
							let isTooShortSecurityQuestionAnswer = RecurringModel.SecurityQuestionAnswer?.length && RecurringModel.SecurityQuestionAnswer?.length < 3;
							vRecurringModel.SecurityQuestionAnswer = RecurringModel.SecurityQuestionAnswer == null || RecurringModel.SecurityQuestionAnswer == "" || isTooShortSecurityQuestionAnswer;
							if (vRecurringModel.SecurityQuestionAnswer) {
								if (!isTooShortSecurityQuestionAnswer)
									recurringMessages.push("Security Question Answer cannot be null.");
								else
									recurringMessages.push("Security Question Answer cannot be smaller than 3 character");
							};
						}
					}
					else {
						vRecurringModel.SecurityQuestionText = RecurringModel.SecurityQuestionText == null || RecurringModel.SecurityQuestionText == "" || RecurringModel.SecurityQuestionText?.length < 3;
						if (vRecurringModel.SecurityQuestionText) recurringMessages.push(RecurringModel.SecurityQuestionText?.length && RecurringModel.SecurityQuestionText?.length < 3 ? "Security Question Text cannot be smaller than 3 character" : "Security Question is not defined.");
						else {
							let isTooShortSecurityQuestionAnswer = RecurringModel.SecurityQuestionAnswer?.length && RecurringModel.SecurityQuestionAnswer?.length < 3;
							vRecurringModel.SecurityQuestionAnswer = RecurringModel.SecurityQuestionAnswer == null || RecurringModel.SecurityQuestionAnswer == "" || isTooShortSecurityQuestionAnswer;
							if (vRecurringModel.SecurityQuestionAnswer) {
								if (!isTooShortSecurityQuestionAnswer)
									recurringMessages.push("Security Question Answer cannot be null.");
								else
									recurringMessages.push("Security Question Answer cannot be smaller than 3 character");
							};
						}
					}
				}

				if (recurringMessages.length > 0) {
					this.props.showMessage("warning", "Please fill required fields!", recurringMessages.map((x, i) => <div key={i}>{x}</div>));
					this.setState({ vRecurringModel });
					return false;
				}
				return true;

			default:
				this.props.showMessage("warning", "Entry Type not selected", "Select Entry Type to continue.");
				return false;
		}
	}

	GetModel() {
		const { model, FromFileModel, IndividualModel, RecurringModel } = this.state;

		switch (model.EntryCode) {
			case EtransferEntryType.FromFile: return { model, FromFileModel };
			case EtransferEntryType.Individual: return { model, IndividualModel };
			case EtransferEntryType.Recurring: return { model, RecurringModel };
			default: return null;
		}
	}

	FillData(data) {
		const { model, FromFileModel, IndividualModel, RecurringModel } = data;

		switch (model.EntryCode) {
			case EtransferEntryType.FromFile: this.setState({ model, FromFileModel }); break;
			case EtransferEntryType.Individual:
				IndividualModel.DateOfFunds = IndividualModel.DateOfFunds ? DateHelper.ToMoment(IndividualModel.DateOfFunds) : null;
				IndividualModel.MoneyReqExpDate = IndividualModel.MoneyReqExpDate ? DateHelper.ToMoment(IndividualModel.MoneyReqExpDate) : null;
				this.setState({ model, IndividualModel });
				break;
			case EtransferEntryType.Recurring:
				RecurringModel.StartDate = RecurringModel.StartDate ? DateHelper.ToMoment(RecurringModel.StartDate) : null;
				RecurringModel.EndDate = RecurringModel.EndDate ? DateHelper.ToMoment(RecurringModel.EndDate) : null;
				RecurringModel.FreezeStartDate = RecurringModel.FreezeStartDate ? DateHelper.ToMoment(RecurringModel.FreezeStartDate) : null;
				RecurringModel.FreezeEndDate = RecurringModel.FreezeEndDate ? DateHelper.ToMoment(RecurringModel.FreezeEndDate) : null;
				this.setState({ model, RecurringModel });
				break;
			default: return;
		}
	}

	GetModelId() {
		const { model, IndividualModel, RecurringModel } = this.state;

		switch (model.EntryCode) {
			case EtransferEntryType.FromFile: return null;
			case EtransferEntryType.Individual: return IndividualModel.Id;
			case EtransferEntryType.Recurring: return RecurringModel.Id;
			default: return null;
		}
	}

	LimitDataLoad(data) {
		this.setState({ LimitBalanceData: data, isLoading: false });
	}

	render() {
		const { model, vModel, isLoading, alert, isFileDialogOpen, isIndividualDialogOpen, isRecurringDialogOpen, isClearDialogOpen, isFileResultDialogOpen, FileResultList, FromFileModel, vFromFileModel, IndividualModel, vIndividualModel, RecurringModel, vRecurringModel, LimitToken, addNewCustomerMenuId, idIndividualClientWarningOpen, UpdateRefreshIndividual, UpdateRefreshRecurring, CustomerStoreList } = this.state;
		const { Disabled, history } = this.props;

		return (
			<div>
				<LoadingComponent Show={isLoading} />
				{alert}
				{this.isClient && <AccountLimitToolbar key={LimitToken} Program={ProgramCodes.Bank_Programs_e_Transfer} OnLoad={this.LimitDataLoad} IsPopUp={this.props.ApprovalData != null} />}
				<ButtonToolbar ButtonList={[
					{ Code: "Submit", OnClick: this.SubmitClick, Disabled: Disabled || model.IsUpdate, ModelFunction: this.GetModel, FillDataFromModel: this.FillData, DisableWorkflow: this.isClient, RowId: this.GetModelId(), ValidationFunction: this.Validate },
					{ Code: "Update", OnClick: this.SubmitClick, Disabled: Disabled || !model.IsUpdate, ModelFunction: this.GetModel, FillDataFromModel: this.FillData, DisableWorkflow: this.isClient, RowId: this.GetModelId(), ValidationFunction: this.Validate },
					{ Code: "Clear", OnClick: this.ClearClick, Disabled: Disabled || !this.isClient }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius" >
							<CardHeader icon>
								<h4 style={{ color: "black" }}>
									<b>Main Parameters</b>
								</h4>
							</CardHeader>
							<CardBody>
								<GridContainer spacing={16}>
									<GridItem xs={4}>
										<ParameterComponent
											Name="EntryType"
											Value={model.EntryType}
											LabelText="Entry Type"
											ParameterCode="EtransferEntryType"
											ValueChanged={this.ValueChanged}
											DefaultParamValue={model.IsUpdate ? model.EntryCode : null}
											Disabled={Disabled || model.IsUpdate}
											Required
											IsInvalid={vModel.EntryType} />
									</GridItem>
									<GridItem xs={4}>
										<ParameterComponent
											key={model.EntryCode}
											Name="EtransferType"
											Value={model.EtransferType}
											LabelText="e-Transfer Type"
											ParameterCode="EtransferType"
											ParameterValue={!model.IsUpdate ? model.EntryCode == EtransferEntryType.FromFile ? null : EtransferType.RealTime : null}
											ValueChanged={this.ValueChanged}
											CanClear={false}
											DefaultParamValue={!model.IsUpdate ? (model.EntryCode == EtransferEntryType.Individual || model.EntryCode == EtransferEntryType.Recurring) ? EtransferType.RealTime : null : null}
											Disabled={Disabled}
											Required
											IsInvalid={vModel.EtransferType} />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={12}>
						<div>
							<CreateEtransferFromFile
								Disabled={Disabled}
								model={FromFileModel}
								vModel={vFromFileModel}
								onModelChange={this.FromFileModelChange}
								parentModel={model}
							/>
							<CreateEtransferIndividual
								CustomerStoreList={CustomerStoreList}
								Disabled={Disabled}
								model={IndividualModel}
								vModel={vIndividualModel}
								onModelChange={this.IndividualModelChange}
								addNewCustomerMenuId={addNewCustomerMenuId}
								parentModel={model}
								showMessage={this.props.showMessage}
								key={UpdateRefreshIndividual + "ind"}
							/>
							<CreateEtransferRecurring
								CustomerStoreList={CustomerStoreList}
								Disabled={Disabled}
								model={RecurringModel}
								vModel={vRecurringModel}
								onModelChange={this.RecurringModelChange}
								addNewCustomerMenuId={addNewCustomerMenuId}
								parentModel={model}
								showMessage={this.props.showMessage}
								key={UpdateRefreshRecurring + "rec"}
							/>
						</div>
					</GridItem>
				</GridContainer>

				<GenericDialog open={isFileDialogOpen} maxWidth="md" fullWidth>
					<DialogTitle>
						<GenericLabel Text="Transaction Confirmation" Bold={true} />
					</DialogTitle>
					<DialogContent>
						<GenericTextInput Name="FileName" LabelText="File Name" Value={FromFileModel.FileName} Disabled={true} />
						<GenericDateInput Name="Date" LabelText="Date" Value={DateHelper.GetDateTimeLocal()} Disabled={true} />
						<GenericCheckInput Name="AutoApprove" LabelText="Automatic Approval" Value={FromFileModel.AutoApprove} Disabled={true} />
						<br />
						<GenericLabel Text="Are you sure you want to upload file ?" Bold={true} />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.SubmitFileClick}>Confirm</Button>
						<Button size="sm" onClick={() => this.setState({ isFileDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>

				<GenericDialog open={isIndividualDialogOpen} maxWidth="md" fullWidth>
					<DialogTitle>
						<GenericLabel Text="Transaction Confirmation" Bold={true} />
					</DialogTitle>
					<DialogContent>
						<GenericSelectInput
							Name="PayeeId"
							LabelText="Customer Name"
							Method="POST"
							Url="/bankapi/v1.0/ClientCustomer/GetAllMainPayeeCustomer"
							DataRoot="Item"
							Parameter={{ ProgramCode: ProgramCodes.Bank_Programs_e_Transfer, UniqueClientId: model.UniqueClientId }}
							KeyValueMember="PayeeId"
							TextValueMember="Name"
							Value={IndividualModel.PayeeId}
							Disabled={true} />
						{IndividualModel.ServiceType == 0 && <GenericTextInput Name="PayeeEmail" LabelText="Customer E-Mail" Value={IndividualModel.PayeeEmail} Disabled={true} />}
						{IndividualModel.ServiceType == 1 && <GenericTextInput Name="PayeePhone" LabelText="Customer Phone" Value={IndividualModel.PhoneNumber} Disabled={true} />}

						<GenericNumberInput Name="Amount" LabelText="Amount" Value={IndividualModel.Amount} MaxLength={10} Disabled={true} />
						<br />
						<GenericLabel Text={model.IsUpdate ?
							"Are you sure you want to apply changes for this transaction ?" :
							"Are you sure you want to enter this transaction ?"} Bold={true} />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.SubmitIndividualClick}>Confirm</Button>
						<Button size="sm" onClick={() => this.setState({ isIndividualDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>

				<GenericDialog open={isRecurringDialogOpen} maxWidth="md" fullWidth>
					<DialogTitle>
						<GenericLabel Text="Transaction Confirmation" Bold={true} />
					</DialogTitle>
					<DialogContent>
						<GenericSelectInput
							Name="PayeeId"
							LabelText="Customer Name"
							Method="POST"
							Url="/bankapi/v1.0/ClientCustomer/GetAllMainPayeeCustomer"
							DataRoot="Item"
							Parameter={{ ProgramCode: ProgramCodes.Bank_Programs_e_Transfer, UniqueClientId: model.UniqueClientId }}
							KeyValueMember="PayeeId"
							TextValueMember="Name"
							Value={RecurringModel.PayeeId}
							Disabled={true} />
						<GenericTextInput LabelText="Period/No. Of Payment" Value={RecurringModel.PaymentCount} Disabled={true} />
						<GenericNumberInput Name="RecurringAmount" LabelText="Recurring Amount" Value={RecurringModel.RecurringAmount} MaxLength={10} Disabled={true} />
						<GenericNumberInput LabelText="Total Amount" Value={RecurringModel.RecurringAmount && RecurringModel.PaymentCount && RecurringModel.RecurringAmount * RecurringModel.PaymentCount} Disabled={true} />
						<GenericDateInput Name="StartDate" LabelText="Start Date" Value={RecurringModel.StartDate} Disabled={true} Utc />
						<GenericDateInput Name="EndDate" LabelText="End Date" Value={RecurringModel.EndDate} Disabled={true} Utc />
						<br />
						<GenericLabel Text={model.IsUpdate ?
							"Are you sure you want to apply changes for this transaction ?" :
							"Are you sure you want to enter this transaction ?"} Bold={true} />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.SubmitRecurringClick}>Confirm</Button>
						<Button size="sm" onClick={() => this.setState({ isRecurringDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>

				<GenericDialog open={isClearDialogOpen} maxWidth="md" fullWidth>
					<DialogContent>
						<GenericLabel Text="This will erase the information you enter on the screen. Are you sure you want to quit this transaction ?" Bold={true} />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.ClearDialogConfirm}>Confirm</Button>
						<Button size="sm" onClick={() => this.setState({ isClearDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>

				<GenericDialog open={isFileResultDialogOpen} maxWidth="lg" fullWidth>
					<DialogTitle>
						<GridContainer justify="space-between" alignItems="center">
							<GridItem>
								<GenericLabel FontSize="16px" TextColor="black" Text="File Upload Process Done" Bold={true} />
							</GridItem>
							<GridItem>
								<IconButton onClick={this.ClearScreen}>
									<GenericIcon>close</GenericIcon>
								</IconButton>
							</GridItem>
						</GridContainer>
					</DialogTitle>
					<DialogContent>
						<GenericGrid
							Sorted={[{ id: "LineId", desc: false }]}
							Columns={[
								{
									Header: "Line Number",
									accessor: "LineId"
								},
								{
									Header: "Is Rejected",
									accessor: "IsRejected",
									Cell: row => row.value ? "Yes" : "No"
								},
								{
									Header: "Comment",
									accessor: "Comment"
								},
								{
									Header: "Entry Type",
									accessor: "EntryType"
								},
								{
									Header: "Transaction Type",
									accessor: "TransactionType"
								},
								{
									Header: "e-Transfer Type",
									accessor: "ETransferType"
								},
								{
									Header: "Customer Number",
									accessor: "CustomerNumber"
								},
								{
									Header: "Transaction Amount",
									accessor: "TransactionAmount",
									type: GridColumnType.Money,
									ColumnType: GridColumnType.Money
								},
								{
									Header: "Date of Funds",
									accessor: "DateFunds"
								},
								{
									Header: "Transaction Expire Date",
									accessor: "TransactionExpDate"
								},
								{
									Header: "Security Question Text",
									accessor: "SecurityQuestionText"
								},
								{
									Header: "Security Question Answer",
									accessor: "SecurityQuestionAnswer"
								},
								{
									Header: "Starting From",
									accessor: "StartingFrom"
								},
								{
									Header: "End Of Date",
									accessor: "EndOfDate"
								},
								{
									Header: "Frequency Type",
									accessor: "FrequencyType"
								},
								{
									Header: "Period Type",
									accessor: "PeriodType"
								},
								{
									Header: "Day Of Month Type",
									accessor: "DayOfMonthType"
								},
								{
									Header: "Day Of Month",
									accessor: "DayOfMonth"
								},
								{
									Header: "Day Of Week",
									accessor: "DayOfWeek"
								},
								{
									Header: "Week Of Month Type",
									accessor: "WeekOfMonthType"
								},
								{
									Header: "Week Of Month",
									accessor: "WeekOfMonth"
								},
								{
									Header: "Freeze Start Date",
									accessor: "FreezeStartDate"
								},
								{
									Header: "Freeze End Date",
									accessor: "FreezeEndDate"
								},
								{
									Header: "Transaction Description",
									accessor: "TransactionDescription"
								},
								{
									Header: "Customer Store Code",
									accessor: "CustomerStoreCode"
								}
							]}
							Data={FileResultList}
							ProgramCode={ProgramCodes.Bank_Programs_e_Transfer} />
					</DialogContent>
				</GenericDialog>

				<GenericDialog open={idIndividualClientWarningOpen} maxWidth="md" fullWidth>
					<DialogTitle>
						<GenericLabel Text="Error" Bold={true} />
					</DialogTitle>
					<DialogContent>
						<div style={{ textAlign: "center" }}>
							<GenericLabel Text={"The individual Client has no authorization to enter the e-Transfer Portal. Please Contact us if you see The e-Transfer Portal at the menu screen."} />
						</div>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={() => history.push("/OnlineSupport")}>Online Support</Button>
						<Button size="sm" onClick={() => history.push("/")}>OK</Button>
					</GenericDialogActions>
				</GenericDialog>
			</div>
		);
	}
}

CreateEtransfer.propTypes = {
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool,
	showMessage: PropTypes.func
};

CreateEtransfer.defaultProps = {
	Disabled: false
};

export default withArtifex(CreateEtransfer, createEtransferStyle);