import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import DDTListStyle from "assets/jss/material-dashboard-pro-react/views/DDTListStyle.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import Formatter from "core/Formatter";
import { DetailIcon } from "core/Icons";
import { PropTypes } from "prop-types";
import React from "react";
import { GenericCheckInput, GenericDialogActions, GenericGrid, GenericLabel, GenericTitle } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import ChequeOrderDetailInfo from "./ChequeOrderDetailInfo";
import { GridColumnType } from "views/Constants/Constant";

class ChequeOrderDataTable extends React.Component {
	constructor(props) {
		super(props);

		this.state = {

			openDialog: false,
			alert: "",
			isLoading: false,
			chequemodel: {},
			chqinfomodel: {}
		};
	}


	OpenEditModal = (item) => {
		// Id: item.UniqClntId,

		Proxy.POST(
			"/chequeapi/v1.0/Cheque/ChequeOrderDetailInfo",
			{
				Id: item.UniqClntId,
				ClientTypeId: item.ClientTypeId
			},

			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				var chqinfomodel = {

					SignatureTypeId: item.SignatureTypeId,
					ChequeTypeId: item.ChequeTypeId
				};

				this.setState({ chequemodel: responseData.Item, isLoading: false, openDialog: true, chqinfomodel });
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while requesting data", error.message);
			}
		);
		// this.setState({
		// 	openDialog: true
		// });
	}

	SuccessSendCallback(responseData) {
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error", responseData.ErrorDescription);
			return;
		}

		this.setState({ openDialog: true });

		this.ShowMessage("success", "Operation is successfully!");

	}
	ErrorSendCallback(error) {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "An error occurred while sending data", error.message);
	}

	ShowMessage(type, title, message) {
		this.setState({
			alert: ""

		});
	}
	handleClose = () => {
		this.setState({
			openDialog: false
		});
	}

	hideAlert = () => {
		this.setState({ alert: null });
	}


	render() {
		const { classes, list, checkedValues, totalresult } = this.props;
		// { this.state.alert }


		return (

			<div >
				{this.state.alert}
				<Dialog open={this.state.openDialog} maxWidth="md" fullWidth onBackdropClick={this.handleClose} >
					<DialogTitle > <GenericLabel Text="Cheque Order Information" FontSize="20px" Bold={true} />

					</DialogTitle>
					<DialogContent>
						{<ChequeOrderDetailInfo
							chequemodel={this.state.chequemodel}
							chqinfomodel={this.state.chqinfomodel}

						/>}
					</DialogContent>
					<GenericDialogActions>
						<Button onClick={this.handleClose}>Close</Button>
					</GenericDialogActions>
				</Dialog>




				<GridContainer>
					<GridItem xs={12}>
						<GridContainer>
							<GridItem xs={4}>

								<GenericTitle text={"Pendings Total"} />
							</GridItem>
							<GridItem xs={4} />

						</GridContainer>
						<GridContainer spacing={16}>
							<GridItem xs={12}>
								<GenericGrid
									Data={list.map(d => {
										return {
											Header: "Actions",
											accessor: "Actions",
											Actions: (
												<div>
													<GridContainer>
														<GridItem xs={6}>
															<GridButton
																tooltip="Edit"
																Icon={DetailIcon}
																OnClick={() => { this.OpenEditModal(d); }} />
														</GridItem>
														<GridItem xs={6}>
															<GenericCheckInput
																Grid
																Value={checkedValues.filter(c => c.Id == d.Id).length > 0}
																ValueChanged={(e) => {
																	this.props.handleCheck(e, d);
																}}
																Disabled={d.OrderStatus == "Approved"}
															/>
														</GridItem>
													</GridContainer>
												</div>
											),
											UniqueClientId: d.UniqueClientId,
											ChequeOrderDate: d.ChequeOrderDate,
											QuantityType: d.QuantityType,
											Price: d.Price,
											SignatureType: d.SignatureType,
											ShipAttentionTo: d.ShipAttentionTo,
											ChequeType: d.ChequeType,
											OrderStatus: d.OrderStatus,
											Name: d.Name,
											SignatureTypeId: d.SignatureTypeId,
											QuantityTypeId: d.QuantityTypeId,

										};
									})}
									Columns={[

										{
											Header: "Actions",
											accessor: "Actions",
											sortable: false,
											filterable: false,
											width: 100
										},
										{
											Header: "Client Name",
											accessor: "Name"
										},
										{
											Header: "Unique Client Id",
											accessor: "UniqueClientId"
										},
										{
											Header: "Date",
											accessor: "ChequeOrderDate",
											type: GridColumnType.Date

										},
										{
											Header: "Quantity",
											accessor: "QuantityType"
										},
										{
											Header: "Price",
											accessor: "Price",
											type: GridColumnType.Money
										},
										{
											Header: "Signature Type",
											accessor: "SignatureType"
										},
										{
											Header: "Ship Attention to",
											accessor: "ShipAttentionTo"
										},
										{
											Header: "Cheque Type",
											accessor: "ChequeType"
										},

										{
											Header: "Order Status",
											accessor: "OrderStatus"
										}

									]}
								/>
							</GridItem>
						</GridContainer>
					</GridItem>
				</GridContainer>

				<GridContainer >
					<GridItem xs={6} />
					<GridItem xs={6}>
						<GridContainer className={classes.containerHeight} justify="flex-end">
							<GridItem xs={2} className={classes.gridBorder}>
							</GridItem>
							<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
								<p className={classes.pt}>Waiting</p>
							</GridItem>
							<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
								<p className={classes.pt}>Approved</p>
							</GridItem>
							<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
								<p className={classes.pt}>Total</p>
							</GridItem>
							{<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
								<p className={classes.pt}>Quantity</p>
							</GridItem>}
							{<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
								<p className={classes.pt}>Price</p>
							</GridItem>}
						</GridContainer>
						<GridContainer justify="flex-end">
							<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold}>
								TOTAL
							</GridItem>
							<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
								{totalresult == null || undefined ? 0 : totalresult.TotalWaiting == null || undefined ? 0 : typeof totalresult.TotalWaiting == "number" ? Formatter.FormatNumber(totalresult.TotalWaiting) : totalresult.TotalWaiting}
							</GridItem>
							<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
								{totalresult == null || undefined ? 0 : totalresult.TotalApproved == null || undefined ? 0 : typeof totalresult.TotalApproved == "number" ? Formatter.FormatNumber(totalresult.TotalApproved) : totalresult.TotalApproved}
							</GridItem>
							<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
								{totalresult == null || undefined ? 0 : totalresult.Total == null || undefined ? 0 : typeof totalresult.Total == "number" ? Formatter.FormatNumber(totalresult.Total) : totalresult.Total}
							</GridItem>
							<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
								{totalresult == null || undefined ? 0 : totalresult.TotalQuantity == null || undefined ? 0 : typeof totalresult.TotalQuantity == "number" ? Formatter.FormatNumber(totalresult.TotalQuantity) : totalresult.TotalQuantity}
							</GridItem>
							<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
								{totalresult == null || undefined ? 0 : totalresult.TotalPrice == null || undefined ? 0 : typeof totalresult.TotalPrice == "number" ? Formatter.FormatMoney(totalresult.TotalPrice) : totalresult.TotalPrice}
							</GridItem>
						</GridContainer>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

ChequeOrderDataTable.propTypes = {
	classes: PropTypes.object,
	checkedValues: PropTypes.array,
	list: PropTypes.array,
	handleCheck: PropTypes.func,
	handleSend: PropTypes.func
};
export default withArtifex(ChequeOrderDataTable, DDTListStyle);
