import { ClickAwayListener, DialogContent, DialogTitle, Grow, Hidden, Icon, MenuItem, MenuList, Paper, Popper, withStyles } from "@material-ui/core";
import appTitleBarStyle from "assets/jss/material-dashboard-pro-react/views/appTitleBarStyle.jsx";
import classNames from "classnames";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React from "react";
import { GenericDialog, GenericDialogActions, GenericLabel } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ToolbarButton from "views/Components/ToolbarButton";
import { FileCode, MenuCodes, ViewSourceType } from "views/Constants/Constant";

class AppTitleBar extends React.Component {
	constructor(props) {
		super(props);

		this.initialListNotification = [
			{
				Id: 0,
				Message: "You have no notification!",
				Read: false
			}
		];

		this.initialListAnnouncement = [
			{
				Id: 0,
				Message: "You have no announcement!",
				Read: false
			}
		];

		this.state = {
			viewSourceTypeIds: {},
			listHeaderMenuRight: [],
			listLegal: [],
			listFee: [],
			listNotification: this.initialListNotification,
			listAnnouncement: this.initialListAnnouncement,
			openLegal: false,
			openFee: false,
			openNotification: false,
			openAnnouncement: false,
			isLoading: false,
			isHelpDialogOpen: false,
			helpContent: null
		};
		this.blackColor = { color:"#000000" };
	}

	componentDidMount() {
		const { GetIsMenuFavorited } = this.props, { pathname } = this.props.history.location;

		GetIsMenuFavorited(pathname);
		this.GetMenuRightByParentMenuCode();
		this.GetLegalList();
		this.GetFeeList();
		this.GetNotificationList();
		this.GetAnnouncementList();
	}

	LegalClick = () => {
		const { openLegal } = this.state;

		this.props.ChangeOpacity(openLegal ? 1 : 0.2);
		this.setState({ openLegal: !openLegal });
	};

	LegalClose = () => {
		this.props.ChangeOpacity(1);
		this.setState({ openLegal: false });
	};

	FeeClick = () => {
		const { openFee } = this.state;

		this.props.ChangeOpacity(openFee ? 1 : 0.2);
		this.setState({ openFee: !openFee });
	};

	FeeClose = () => {
		this.props.ChangeOpacity(1);
		this.setState({ openFee: false });
	};

	NotificationClick = () => {
		const { listNotification, openNotification } = this.state;

		this.props.ChangeOpacity(openNotification ? 1 : 0.2);
		this.setState({ openNotification: !openNotification });

		if (listNotification != this.initialListNotification) {
			const { ExecuteApiPost } = this.props;

			ExecuteApiPost("/bankapi/v1.0/CustomerNotificationView/InsertOrUpdate"
				, {
					Item: listNotification.filter(d => !d.Read).map(i => {
						return {
							SourceId: i.Id,
							SourceTypeId: i.SourceTypeId
						};
					})
				});
		}
	};

	NotificationClose = () => {
		const { listNotification } = this.state;

		this.props.ChangeOpacity(1);
		this.setState({ openNotification: false });

		if (listNotification.filter(d => !d.Read).length > 0) {
			this.GetNotificationList();
		}
	};

	AnnouncementClick = () => {
		const { listAnnouncement, openAnnouncement } = this.state;

		this.props.ChangeOpacity(openAnnouncement ? 1 : 0.2);
		this.setState({ openAnnouncement: !openAnnouncement });

		if (listAnnouncement != this.initialListAnnouncement) {
			const { ExecuteApiPost } = this.props;

			ExecuteApiPost("/bankapi/v1.0/CustomerAnnouncement/UpdateForRead"
				, listAnnouncement
					.filter(x => !x.Read && !x.IsRead)
					.map(x => {
						return x.Id;
					}));
		}
	};

	AnnouncementClose = () => {
		const { listAnnouncement } = this.state;

		this.props.ChangeOpacity(1);
		this.setState({ openAnnouncement: false });

		if (listAnnouncement.filter(d => !d.Read).length > 0) {
			this.GetAnnouncementList();
		}
	};

	FavoriteClick = async () => {
		const { ExecuteApiPost, GetIsMenuFavorited } = this.props, { pathname } = this.props.history.location;
		var result = await ExecuteApiPost("/coreapi/v1.0/UserFavoriteMenuMap/InsertOrUpdate", { MenuUrl: pathname });

		if (result != null) {
			var user = ClientHelper.GetUser();

			user.IsFavorite = true;
			localStorage.setItem("user", JSON.stringify(user));
			localStorage.removeItem("MenuData");

			window.location.reload(true);
			GetIsMenuFavorited(pathname);
		}
	}

	GetMenuRightByParentMenuCode = async () => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/coreapi/v1.0/MenuRight/GetByParentMenuCode", { MenuCode: MenuCodes.AppTitleBar }, null, null, null, null, null);



		this.setState({ listHeaderMenuRight: result || [] });
	}

	GetLegalList = async () => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/coreapi/v1.0/File/Search", { FileCode: FileCode.Legal });

		this.setState({ listLegal: result || [] });
	}

	GetFeeList = async () => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/bankapi/v1.0/FeeManage/Search", {});
		
		this.setState({ listFee: result || [] });
	}

	GetNotificationList = async () => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/bankapi/v1.0/CustomerNotificationView/GetAll", { ViewSourceType: ViewSourceType.All });
		
		this.setState({ listNotification: result == null || result.filter(x => !x.Read).length == 0 ? this.initialListNotification : result });
	}

	GetAnnouncementList = async () => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/bankapi/v1.0/CustomerNotificationView/GetAll", { ViewSourceType: ViewSourceType.Announcement });
		
		this.setState({ listAnnouncement: result == null || result.filter(x => !x.Read).length == 0 ? this.initialListAnnouncement : result });
	}

	openHelpDialog = () => {
		this.setState({
			helpContent: this.props.history.location,
		  isHelpDialogOpen: true,
		});
	};
	
	 closeHelpDialog = () => {
		this.setState({
		  isHelpDialogOpen: false,
		});
	};

	render() {
		const { listHeaderMenuRight, listLegal, listFee, listNotification, listAnnouncement, openLegal, openFee, openNotification, openAnnouncement, isHelpDialogOpen, helpContent } = this.state;
		const { classes, LeftTitle, CenterTitle, IsFavorited, ExecuteApiFileDownloadWithGenericResponse } = this.props;

		const dropdownItem = classNames(
			classes.dropdownItem,
			classes.primaryHover
		);
		const managerClasses = classNames({
			[classes.managerClasses]: true
		});

		if (listHeaderMenuRight != null) {

			var favoriteIsShow = true,
				gavelIsShow = false,
				attachMoneyIsShow = false,
				notificationsIsShow = false,
				volumeUpIsShow = false;

			for (var headerMenuRight of listHeaderMenuRight) {
				if (headerMenuRight.MenuCode == MenuCodes.AppTitleBar_Gavel) {
					gavelIsShow = headerMenuRight.HasAuthority;
				} else if (headerMenuRight.MenuCode == MenuCodes.AppTitleBar_AttachMoney) {
					attachMoneyIsShow = headerMenuRight.HasAuthority;
				} else if (headerMenuRight.MenuCode == MenuCodes.AppTitleBar_Notifications) {
					notificationsIsShow = headerMenuRight.HasAuthority;
				} else if (headerMenuRight.MenuCode == MenuCodes.AppTitleBar_VolumeUp) {
					volumeUpIsShow = headerMenuRight.HasAuthority;
				}
			}

			var isFavorited = IsFavorited;
			var favoriteIcon = isFavorited
				? "fas fa-star"
				: "far fa-star";
		}

		return (
			<div className={classes.main} style={{ marginBottom: 5 }}>
				<LoadingComponent Show={this.state.isLoading} />
				<GridContainer
					style={{ marginBottom: "-5px", marginTop: "-5px" }}
					alignItems="center"
					direction="row"
					justify="space-between">
					<GridItem>
						<div style={{ textTransform: "none", marginLeft: 6 }}>
							<h4>
								<b style={this.blackColor}>
									{LeftTitle}
								</b>
							</h4>
						</div>
					</GridItem>
					<Hidden only="xs" implementation="css">
						<GridItem>
							<h4>
								<b style={this.blackColor}>
									{CenterTitle}
								</b>
							</h4>
						</GridItem>
					</Hidden>
					<Hidden smDown implementation="css">
						<GridItem>
							{
								favoriteIsShow !== undefined
									&& favoriteIsShow
									?
									(
										<ToolbarButton
											tooltip={!isFavorited ? "Add to Favorites" : "Remove from Favorites"}
											color='primary'
											size="sm"
											justIcon
											onClick={() => this.openHelpDialog()} >
											<i className={'fas fa-question'} />
										</ToolbarButton>
									)
									:
									(
										<div style={{ display: "none" }}> </div>
									)
							}
							{
								favoriteIsShow !== undefined
									&& favoriteIsShow
									&& this.props.history.location.pathname != "/Dashboard"
									&& this.props.history.location.pathname != "/CryptoDashboard"
									?
									(
										<ToolbarButton
											tooltip={!isFavorited ? "Add to Favorites" : "Remove from Favorites"}
											color='primary'
											justIcon
											size="sm"
											onClick={() => this.FavoriteClick()} >
											<i className={favoriteIcon} />
										</ToolbarButton>
									)
									:
									(
										<div style={{ display: "none" }}> </div>
									)
							}
							{
								gavelIsShow !== undefined
									&& gavelIsShow
									?
									(
										<div className={managerClasses}>
											<ToolbarButton
												tooltip="Legal"
												color="primary"
												justIcon
												size="sm"
												aria-label="legals"
												aria-owns={openLegal ? "menu-list" : null}
												aria-haspopup="true"
												onClick={this.LegalClick}
												muiClasses={{
													label: ""
												}}
												buttonRef={node => {
													this.anchorEl = node;
												}}
											>
												<Icon className={classes.icon}>gavel</Icon>
											</ToolbarButton>
											{
												listLegal.length > 0
												&& <Popper
													open={openLegal}
													anchorEl={this.anchorEl}
													transition
													disablePortal
													placement="bottom"
													className={classNames({
														[classes.popperClose]: !openLegal,
														[classes.pooperResponsive]: true,
														[classes.pooperNav]: true
													})}
												>
													{({ TransitionProps }) => (
														<Grow
															{...TransitionProps}
															id="menu-list"
															style={{ transformOrigin: "0 0 0", marginTop: 20, marginRight: 50 }}
														>
															<Paper className={classes.dropdown}>
																<ClickAwayListener onClickAway={this.LegalClose}>
																	<MenuList role="menu">
																		{listLegal.map(x => {
																			return (
																				<MenuItem
																					style={{ fontWeight: "bold" }}
																					key={x.Id}
																					onClick={() => ExecuteApiFileDownloadWithGenericResponse("/coreapi/v1.0/File/DownloadFileFromBinary", { FileId: x.FileId }, x.FileName, x.FileExtension)}
																					className={dropdownItem} >
																					{Formatter.FormatDate(x.InsertDateTime) + "   " + x.FileName}
																				</MenuItem>
																			);
																		})}
																	</MenuList>
																</ClickAwayListener>
															</Paper>
														</Grow>
													)}
												</Popper>
											}
										</div>
									)
									:
									(
										<div style={{ display: "none" }}> </div>
									)
							}
							{
								attachMoneyIsShow !== undefined
									&& attachMoneyIsShow
									?
									(
										<div className={managerClasses}>
											<ToolbarButton
												tooltip="Fee Information"
												color="primary"
												justIcon
												size="sm"
												aria-label="Fees"
												aria-owns={openFee ? "menu-list" : null}
												aria-haspopup="true"
												onClick={this.FeeClick}
												muiClasses={{
													label: ""
												}}
												buttonRef={node => {
													this.anchorEl = node;
												}}
											>
												<Icon className={classes.icon}>attach_money</Icon>
											</ToolbarButton>
											{
												listFee.length > 0
												&& <Popper
													open={openFee}
													anchorEl={this.anchorEl}
													transition
													disablePortal
													placement="bottom"
													className={classNames({
														[classes.popperClose]: !openFee,
														[classes.pooperResponsive]: true,
														[classes.pooperNav]: true
													})}
												>
													{({ TransitionProps }) => (
														<Grow
															{...TransitionProps}
															id="menu-list"
															style={{ transformOrigin: "0 0 0", marginTop: 20, marginRight: 50 }}
														>
															<Paper className={classes.dropdown}>
																<ClickAwayListener onClickAway={this.FeeClose}>
																	<MenuList role="menu">
																		{listFee.map(x => {
																			return (
																				<MenuItem
																					style={{ fontWeight: "bold" }}
																					key={x.Id}
																					onClick={() => ExecuteApiFileDownloadWithGenericResponse("/coreapi/v1.0/File/DownloadFileFromBinary", { FileId: x.FileId }, x.FileName, x.FileExtension)}
																					className={dropdownItem} >
																					{Formatter.FormatDate(x.InsertDateTime) + "   " + x.ProgramDescription + "   " + x.FileName}
																				</MenuItem>
																			);
																		})}
																	</MenuList>
																</ClickAwayListener>
															</Paper>
														</Grow>
													)}
												</Popper>
											}
										</div>
									)
									:
									(
										<div style={{ display: "none" }}> </div>
									)
							}
							{
								notificationsIsShow !== undefined
									&& notificationsIsShow
									?
									(
										<div className={managerClasses}>
											<ToolbarButton
												tooltip="Notifications"
												color="primary"
												justIcon
												size="sm"
												aria-label="Notifications"
												aria-owns={openNotification ? "menu-list" : null}
												aria-haspopup="true"
												onClick={this.NotificationClick}
												muiClasses={{
													label: ""
												}}
												buttonRef={node => {
													this.anchorEl = node;
												}}
											>
												<Icon className={classes.icon}>notifications</Icon>
												{
													listNotification != this.initialListNotification
													&& listNotification.filter(d => !d.Read).length > 0
													&& <span className={classes.notifications}>{listNotification.filter(d => !d.Read).length}</span>
												}
											</ToolbarButton>
											{
												listNotification.filter(d => !d.Read).length > 0
												&& <Popper
													open={openNotification}
													anchorEl={this.anchorEl}
													transition
													disablePortal
													placement="bottom"
													className={classNames({
														[classes.popperClose]: !openNotification,
														[classes.pooperResponsive]: true,
														[classes.pooperNav]: true
													})}
												>
													{({ TransitionProps }) => (
														<Grow
															{...TransitionProps}
															id="menu-list"
															style={{ transformOrigin: "0 0 0", marginTop: 20, marginRight: 50, width: "550px" }}
														>
															<Paper className={classes.dropdown}>
																<ClickAwayListener onClickAway={this.NotificationClose}>
																	<MenuList role="menu">
																		{listNotification.filter(d => !d.Read).map(x => {
																			return (
																				<GridContainer
																					key={x.Id}
																					spacing={0}
																					direction="row"
																					justify="flex-start"
																					alignItems="center"
																					style={{
																						margin: "0 4px auto",
																						maxWidth: "542px",
																						borderTop: listNotification.length > 0 && x.Id === listNotification.filter(d => !d.Read).map(x => { return x.Id; })[0] ? "none" : "1px solid lightgray",
																						borderBottom: listNotification.length > 0 && x.Id === listNotification.filter(d => !d.Read).map(x => { return x.Id; })[listNotification.filter(d => !d.Read).length - 1] ? "none" : "1px solid lightgray",
																						padding: "6px 0"
																					}}>
																					<GridItem xs={3}>
																						<div style={{ textAlign: "center" }}>
																							<GridItem className={classes.circleText}>
																								{DateHelper.ToMoment(x.DateTime).format("D")}
																							</GridItem>
																							<GridItem className={classes.circleAfterText}>
																								{DateHelper.ToMoment(x.DateTime).format("MMMM")}
																							</GridItem>
																						</div>
																					</GridItem>
																					<GridItem xs={9}>
																						<GenericLabel
																							Text={x.Message}
																							Bold={!x.IsRead} />
																					</GridItem>
																				</GridContainer>
																			);
																		})}
																	</MenuList>
																</ClickAwayListener>
															</Paper>
														</Grow>
													)}
												</Popper>
											}
										</div>
									)
									:
									(
										<div style={{ display: "none" }}> </div>
									)
							}
							{
								volumeUpIsShow !== undefined
									&& volumeUpIsShow
									?
									(
										<div className={managerClasses}>
											<ToolbarButton
												tooltip="Announcements"
												color="primary"
												justIcon
												size="sm"
												aria-label="Announcements"
												aria-owns={openAnnouncement ? "announcement-list" : null}
												aria-haspopup="true"
												onClick={this.AnnouncementClick}
												muiClasses={{
													label: ""
												}}
												buttonRef={node => {
													this.anchorEl = node;
												}}
											>
												<Icon className={classes.icon}>volume_up</Icon>
												{
													listAnnouncement != this.initialListAnnouncement
													&& listAnnouncement.filter(d => !d.Read).length > 0
													&& <span className={classes.announcements}>{listAnnouncement.filter(d => !d.Read).length}</span>
												}
											</ToolbarButton>
											{
												listAnnouncement.length > 0
												&& <Popper
													open={openAnnouncement}
													anchorEl={this.anchorEl}
													transition
													disablePortal
													placement="bottom"
													className={classNames({
														[classes.popperClose]: !openAnnouncement,
														[classes.pooperResponsive]: true,
														[classes.pooperNav]: true
													})}
												>
													{({ TransitionProps }) => (
														<Grow
															{...TransitionProps}
															id="announcement-list"
															style={{ transformOrigin: "0 0 0", marginTop: 20, marginRight: 50, width: "550px" }}
														>
															<Paper className={classes.dropdown} >
																<ClickAwayListener onClickAway={this.AnnouncementClose}>
																	<MenuList role="announcement">
																		{listAnnouncement.filter(d => !d.Read).map(x => {
																			return (
																				<GridContainer
																					key={x.Id}
																					spacing={0}
																					direction="row"
																					justify="flex-start"
																					alignItems="center"
																					style={{
																						margin: "0 4px auto",
																						maxWidth: "542px",
																						borderTop: listAnnouncement.length > 0 && x.Id === listAnnouncement.filter(d => !d.Read).map(x => { return x.Id; })[0] ? "none" : "1px solid lightgray",
																						borderBottom: listAnnouncement.length > 0 && x.Id === listAnnouncement.filter(d => !d.Read).map(x => { return x.Id; })[listAnnouncement.filter(d => !d.Read).length - 1] ? "none" : "1px solid lightgray",
																						padding: "6px 0"
																					}}>
																					<GridItem xs={3}>
																						<div style={{ textAlign: "center" }}>
																							<GridItem>
																								<img
																									alt=""
																									src={x.Icon}
																									style={{ width: x.Icon !== undefined ? 50 : 0, height: x.Icon !== undefined ? 50 : 0, borderRadius: "50%", objectFit: "contain" }}
																								/>
																							</GridItem>
																							<GridItem className={classes.circleAfterText}>
																								{DateHelper.ToMoment(x.DateTime).format("D MMMM")}
																							</GridItem>
																						</div>
																					</GridItem>
																					<GridItem xs={9}>
																						<GenericLabel
																							Text={x.Message}
																							Bold={!x.IsRead} />
																					</GridItem>
																				</GridContainer>
																			);
																		})}
																	</MenuList>
																</ClickAwayListener>
															</Paper>
														</Grow>
													)}
												</Popper>
											}
										</div>
									)
									:
									(
										<div style={{ display: "none" }}> </div>
									)
							}
						</GridItem>
					</Hidden>
				</GridContainer>
				<GenericDialog open={isHelpDialogOpen} fullWidth={true}>
					<DialogTitle>
						<GenericLabel
						Text={LeftTitle}
						FontSize="20px"
						Bold={true}
						/>
					</DialogTitle>
					<DialogContent style={{ height: 400}}>
							<p>Help For - {this.props.history.location.pathname}</p>
							<p>
							Lorem ipsum dolor sit <b>amet consectetur</b>, adipisicing elit. Explicabo nulla, reiciendis cum eius maxime molestias minima modi voluptate iusto! Numquam itaque tempora unde in voluptate libero aperiam dolores eius non?
							</p>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.closeHelpDialog}>
						Close
						</Button>
					</GenericDialogActions>
				</GenericDialog>
			</div>
		);
	}
}

AppTitleBar.propTypes = {
	classes: PropTypes.object.isRequired,
	LeftTitle: PropTypes.string,
	CenterTitle: PropTypes.string,
	history: PropTypes.object,
	ChangeOpacity: PropTypes.func,
	GetIsMenuFavorited: PropTypes.func,
	IsFavorited: PropTypes.bool
};

export default withStyles(appTitleBarStyle)(AppTitleBar);