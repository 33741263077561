import {
	primaryColor, grayColor
} from "assets/jss/material-dashboard-pro-react.jsx";

const genericCheckInputStyle = {
	checked: {
		color: primaryColor + "!important"
	},
	disabled: {
		color: grayColor + "!important"
	},
	checkedIcon: {
		width: "18px",
		height: "18px",
		border: "1px solid rgba(0, 0, 0, .54)",
		borderRadius: "3px"
	},
	uncheckedIcon: {
		width: "0px",
		height: "0px",
		padding: "8px",
		border: "1px solid rgba(0, 0, 0, .54)",
		borderRadius: "3px"
	},
	label: {
		color: "#000",
		cursor: "pointer",
		paddingLeft: "0",
		fontSize: "12px",
		lineHeight: "1.428571429",
		fontWeight: "400",
		display: "inline-flex",
		transition: "0.3s ease all"
	},
	labelBold: {
		fontWeight: "bold !important",
		color: "#000",
		paddingLeft: "5px",
		cursor: "pointer",
		paddingTop : "2px",
		fontSize: "12px",
		lineHeight: "1.428571429",
		display: "inline-flex",
		transition: "0.3s ease all"
	},
};

export default genericCheckInputStyle;
