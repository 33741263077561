import style from "assets/jss/material-dashboard-pro-react/views/createWireTransferStyle.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericDateInput, GenericTextInput } from "views/Components/Generic";
import DateHelper from "core/DateHelper";
import { GenericLabel } from '../../Components/Generic';

class MessageDetail extends Component {

	constructor(props) {
		super(props);

		this.MaxLen = { maxLength: 30 };
		this.ParameterCountrySortOrderIndex = { Member: "OrderIndex" };
		this.EmptyObj = {};
		this.SortedCountry = { Member: "OrderIndex" };
		this.SortedCityProvince = { Member: "ParameterDesc" };
	}

	RenderCountry(d) {
		return d.ISO2 + " - " + d.Name;
	}

	RenderUser = () => {
		var x = this.props;
		var render = `${x.LastUser} - ${x.SubStatus}`;
		return render;
	}
	render() {
		const { Disabled,
			DateOfRequest,
			CreationDate,
			ValueDate,
			BeneficiaryName,
			BeneficiaryAddress,
			Remittance,
			BeneficiaryAccountNumber,
			Amount,
			Currency,
			Reference,
			BeneficiaryBank,
			BeneficiaryBankAddress,
			BeneficiaryIdentifier,
			BeneficiaryBankPartyIdentifier,
			OrderingInstitutionSwiftCode,
			OrderingInstitutionPartyIdentifier,
			OrderingInstitutionAddress,
			TempOrderingInstitutionSwiftCode,
			SenderName,
			SenderAccountNumber,
			SenderSwiftCode,
			SenderAddress,
			SenderAdditionalInformation,
			LastUser,
			ValueChanged } = this.props;

		return (
			<div>
				<GridContainer>
					<GridItem xs={4}>
						<GridContainer style={{ borderRadius: 5 }}>
							<GridItem xs={12}>
								<h4 style={{ color: "black" }}>
									<b>Transaction Information</b>
								</h4>
							</GridItem>
							{LastUser &&
								<GridItem xs={12}>
									<GenericTextInput
										Name="LastUser"
										LabelText="Bso User"
										Value={LastUser}
										Disabled={true}
									/>
								</GridItem>
							}
							<GridItem xs={12}>
								<GenericDateInput
									Name="DateOfRequest"
									LabelText="Date Of Request"
									Value={DateOfRequest ? DateHelper.ToMoment(DateOfRequest) : null}
									ValueChanged={ValueChanged}
									IncludeTime={false}
									Utc
									Disabled={true} />
							</GridItem>
							<GridItem xs={12}>
								<GenericDateInput
									Name="CreationDate"
									LabelText="Creation Date"
									Value={CreationDate ? DateHelper.ToMoment(CreationDate) : null}
									ValueChanged={ValueChanged}
									IncludeTime={false}
									Utc
									Disabled={true} />
							</GridItem>
							<GridItem xs={12}>
								<GenericDateInput
									Name="ValueDate"
									LabelText="Value Date"
									Value={ValueDate ? DateHelper.ToMoment(ValueDate) : null}
									ValueChanged={ValueChanged}
									IncludeTime={false}
									Utc
									Disabled={true} />
							</GridItem>
							<GridItem xs={12}>
								<GenericTextInput
									Name="Amount"
									LabelText="Amount"
									Value={Amount}
									Disabled={true} />
							</GridItem>
							<GridItem xs={12}>
								<GenericTextInput
									Name="Currency"
									LabelText="Currency"
									Value={Currency}
									ValueChanged={ValueChanged}
									inputProps={{ maxLength: 10 }}
									Disabled={Disabled} />
							</GridItem>

							<GridItem xs={12}>
								<GenericTextInput
									Name="Reference"
									LabelText="Reference"
									Value={Reference}
									ValueChanged={ValueChanged}
									inputProps={{ maxLength: 500 }}
									Disabled={Disabled} />
							</GridItem>
							<GridItem xs={12}>
								<GenericTextInput
									Name="Remittance"
									LabelText="Remittance"
									Value={Remittance}
									ValueChanged={ValueChanged}
									inputProps={{ maxLength: 500 }}
									Disabled={Disabled} />
							</GridItem>
						</GridContainer>
					</GridItem>
					<GridItem xs={4}>
						<GridContainer style={{ borderRadius: 5 }}>
							<GridItem xs={12}>
								<h4 style={{ color: "black" }}>
									<b>Beneficiary Information</b>
								</h4>
							</GridItem>
							<GridItem xs={12}>
								<GenericTextInput
									Name="BeneficiaryName"
									LabelText="Beneficiary Name"
									Value={BeneficiaryName}
									ValueChanged={ValueChanged}
									inputProps={{ maxLength: 500 }}
									Disabled={Disabled} />
							</GridItem>
							<GridItem xs={12}>
								<GenericTextInput
									Name="BeneficiaryAccountNumber"
									LabelText="Beneficiary Wallet Number"
									inputProps={{ maxLength: 500 }}
									Value={BeneficiaryAccountNumber}
									ValueChanged={ValueChanged}
									Disabled={Disabled} />
							</GridItem>
							<GridItem xs={12}>
								<GenericTextInput
									Name="BeneficiaryAddress"
									LabelText="Address"
									Value={BeneficiaryAddress}
									ValueChanged={ValueChanged}
									inputProps={{ maxLength: 500 }}
									Disabled={Disabled} />
							</GridItem>

							<GridItem xs={12}>
								<GenericTextInput
									Name="BeneficiaryIdentifier"
									LabelText="Beneficiary Identifier"
									Value={BeneficiaryIdentifier}
									ValueChanged={ValueChanged}
									inputProps={{ maxLength: 500 }}
									Disabled={Disabled} />
							</GridItem>
							<GridItem xs={12}>
								<GenericLabel
									Name="Empty"
									LabelText=""
									Disabled={Disabled} />
							</GridItem>
							<GridItem xs={12}>
								<h4 style={{ color: "black" }}>
									<b>Beneficiary Bank Information</b>
								</h4>
							</GridItem>
							<GridItem xs={12}>
								<GenericTextInput
									Name="BeneficiaryBank"
									LabelText="Bank Name"
									Value={BeneficiaryBank}
									ValueChanged={ValueChanged}
									inputProps={{ maxLength: 500 }}
									Disabled={Disabled} />
							</GridItem>
							<GridItem xs={12}>
								<GenericTextInput
									Name="BeneficiaryBankPartyIdentifier"
									LabelText="Beneficiary Bank Party Identifier"
									Value={BeneficiaryBankPartyIdentifier}
									ValueChanged={ValueChanged}
									inputProps={{ maxLength: 1000 }}
									Disabled={Disabled} />
							</GridItem>

							<GridItem xs={12}>
								<GenericTextInput
									Name="BeneficiaryBankAddress"
									LabelText="Bank Address"
									Value={BeneficiaryBankAddress}
									ValueChanged={ValueChanged}
									inputProps={{ maxLength: 1000 }}
									Disabled={Disabled} />
							</GridItem>


						</GridContainer>
					</GridItem>
					<GridItem xs={4}>
						<GridContainer style={{ borderRadius: 5 }}>
							<GridItem xs={12}>
								<h4 style={{ color: "black" }}>
									<b>Sender Information</b>
								</h4>
							</GridItem>
							<GridItem xs={12}>
								<GenericTextInput
									Name="SenderName"
									LabelText="SenderName"
									Value={SenderName}
									Disabled={true} />
							</GridItem>
							<GridItem xs={12}>
								<GenericTextInput
									Name="SenderAccountNumber"
									LabelText="Sender Wallet Number"
									Value={SenderAccountNumber}
									Disabled={true} />
							</GridItem>
							<GridItem xs={12}>
								<GenericTextInput
									Name="SenderSwiftCode"
									LabelText="Sender Swift Code"
									Value={SenderSwiftCode}
									Disabled={true} />
							</GridItem>
							<GridItem xs={12}>
								<GenericTextInput
									Name="Sender Address"
									LabelText="Sender Address"
									Value={SenderAddress}
									Disabled={true} />
							</GridItem>
							<GridItem xs={12}>
								<GenericTextInput
									Name="SenderAdditionalInformation"
									LabelText="Sender Additional Information"
									Value={SenderAdditionalInformation}
									Disabled={true} />
							</GridItem>

							<GridItem xs={12}>
								<h4 style={{ color: "black" }}>
									<b>Ordering Institution Information</b>
								</h4>
							</GridItem>

							<GridItem xs={12}>
								<GenericTextInput
									Name="OrderingInstitutionSwiftCode"
									LabelText="Ordering Institution Swift Code"
									Value={OrderingInstitutionSwiftCode}
									Disabled={Disabled || TempOrderingInstitutionSwiftCode}
									inputProps={{ maxLength: 50 }}
									ValueChanged={ValueChanged}
								/>
							</GridItem>

							<GridItem xs={12}>
								<GenericTextInput
									Name="OrderingInstitutionPartyIdentifier"
									LabelText="Ordering Institution Party Identifier"
									Value={OrderingInstitutionPartyIdentifier}
									Disabled={true} />
							</GridItem>

							<GridItem xs={12}>
								<GenericTextInput
									Name="OrderingInstitutionAddress"
									LabelText="Ordering Institution Address"
									Value={OrderingInstitutionAddress}
									Disabled={true} />
							</GridItem>
						</GridContainer>
					</GridItem>
				</GridContainer>

				<br></br>

			</div>
		);
	}
}

MessageDetail.propTypes = {
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool,
	ValueChanged: PropTypes.func,
	CustomerName: PropTypes.string,
	AccountNumber: PropTypes.string,
	DateOfRequest: PropTypes.string,
	CreationDate: PropTypes.string,
	ValueDate: PropTypes.string,
	BeneficiaryName: PropTypes.string,
	BeneficiaryAddress: PropTypes.string,
	BeneficiaryAddress2: PropTypes.string,
	CustomerAddress: PropTypes.string,
	BeneficiaryCountry: PropTypes.string,
	BeneficiaryCity: PropTypes.string,
	BeneficiaryProvince: PropTypes.string,
	BeneficiaryPostalCode: PropTypes.string,
	BeneficiaryBankCountry: PropTypes.string,
	BeneficiaryBankPostalCode: PropTypes.string,
	BeneficiaryBankInstitution: PropTypes.string,
	Remittance: PropTypes.string,
	BeneficiaryAccountNumber: PropTypes.string,
	Amount: PropTypes.string,
	Currency: PropTypes.string,
	PurposeOfWire: PropTypes.string,
	Reference: PropTypes.string,
	BeneficiaryBank: PropTypes.string,
	BeneficiaryBankAddress: PropTypes.string,
	BeneficiaryBankAddress2: PropTypes.string,
	BeneficiaryBankCity: PropTypes.string,
	BeneficiaryBankProvince: PropTypes.string,
	TransitNumber: PropTypes.string,
	BeneficiaryBankPartyIdentifier: PropTypes.string,
	BeneficiaryIdentifier: PropTypes.string,
	OrderingInstitutionSwiftCode: PropTypes.string,
	TempOrderingInstitutionSwiftCode: PropTypes.string,

	SenderName: PropTypes.string,
	SenderAccountNumber: PropTypes.string,
	SenderSwiftCode: PropTypes.string,
	SenderAddress: PropTypes.string,
	SenderAdditionalInformation: PropTypes.string,

	OrderingInstitutionPartyIdentifier: PropTypes.string,
	OrderingInstitutionAddress: PropTypes.string,
	LastUser: PropTypes.string,

	SubStatus: PropTypes.string
};

export default withArtifex(MessageDetail, style);