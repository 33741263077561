import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericNumberInput, GenericSelectInput, GenericLabel } from "views/Components/Generic";
import { ClientType, FeeProfileType, SalesChannel } from "views/Constants/Constant.js";
import { withArtifex } from "core";

class ContractApprove extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			ApprovalData: {}
		};
	}

	handleChange = (name, newValue, data) => {
		const model = { ...this.state.ApprovalData };
		if (name == "SalesChannelTypeId" && data != null) {
			if (data.ParameterValue == SalesChannel.SalesPerson) {
				model.IsSalesChannel = true;
			} else {
				model.IsSalesChannel = false;
				model.SalesChannelTypeId = null;
			}
		}
		if (name == "ProgramId") {
			model.LimitProfileId = 0;
			model.FeeProfileId = 0;
			model.CommissionFeeProfileId = 0;
		}
		if (name == "FeeProfileId" && data != null) {
			if (data.FeeProfileType != null && data.FeeProfileType.ParameterValue == FeeProfileType.UnWeighed) {
				model.IsUnWeighedFeeProfile = true;
			} else {
				model.IsUnWeighedFeeProfile = false;
			}

			if (data.FeeProfileType != null && data.FeeProfileType.ParameterValue == FeeProfileType.CombineUnWeighted) {
				model.IsCombineUnWeighedFeeProfile = true;
			} else {
				model.IsCombineUnWeighedFeeProfile = false;
			}

			model.AggrementPriorityTransactionCount = null;
			model.AggrementRegularTransactionCount = null;
			model.AggrementTransactionCount = null;

		}
		model[name] = newValue;
		this.setState({ ApprovalData: model });
	}

	updateCustomerContract = (workflowId, after, accessToken) => {
		var temp = this.state.ApprovalData;
		this.setState({ isLoading: true });

		Proxy.POST(
			"/bankapi/v1.0/CustomerContract/Update",
			temp,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.showErrorMessage("Error " + responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					this.showSuccessMessage();
				}
				if (after) after();
				this.setState({ isLoading: false });
			},
			error => {
				this.setState({ isLoading: false });
				this.showErrorMessage("Error " + error);
			},
			workflowId,
			accessToken
		);
	}

	ClearData = () => {

	}

	showSuccessMessage = () => {
		this.setState({
			alert: <GenericAlert Title="Success" Message="Your changes saved succesfully ! " Type="success" OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	showErrorMessage = (message) => {
		this.setState({
			alert: <GenericAlert Title="Error" Message={message} Type="error" OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}
	render() {
		const { paramGlobalModel, Disabled } = this.props;
		const { ApprovalData } = this.state;

		return (
			<GridContainer spacing={16}>
				<GridItem md={12} xs={12}>


					<ButtonToolbar ButtonList={[
						{ Code: "Clear", OnClick: () => this.ClearData(), Disabled: Disabled },
						{
							Code: "Submit", OnClick: this.updateCustomerContract, Disabled: Disabled,
							ModelFunction: () => {
								return {
									ApprovalData: this.state.ApprovalData
								}
							},
							FillDataFromModel: model => {
								this.setState({ ApprovalData: model });
							},
							RowId: ApprovalData == null ? 0 : ApprovalData.Id
						}
					]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />


					<Card className="no-radius">
						<CardBody>
							<GridItem md={12} xs={12}>
								<GridContainer>
									<GridItem xs={12} sm={8}>
										<h5><b> Main Parameters </b></h5>
									</GridItem>
									<GridItem xs={12} sm={4}>
									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={12} sm={4}>
										<GenericLabel Text={"UniqueClientId " + ApprovalData.WorkflowUniqueClientId} Bold={true} />
									</GridItem>
									<GridItem xs={12} sm={4}>
										<GenericLabel Text={"Client Name " + ApprovalData.WorkflowClientName} Bold={true} />
									</GridItem>
									<GridItem xs={12} sm={4}>
										<GenericLabel Text={"Client Type " + ApprovalData.WorkflowClientType} Bold={true} />
									</GridItem>
								</GridContainer>
								<GridContainer spacing={16}>
									<GridItem xs={12} sm={4} md={4} lg={4}>
										<GridItem>
											<GenericSelectInput
												Disabled={Disabled || ApprovalData != null && ApprovalData.Id > 0}
												Name="ProgramId"
												LabelText="Program"
												Value={ApprovalData == null ? "" : ApprovalData.ProgramId || ""}
												ValueChanged={this.handleChange}
												KeyValueMember="Id" TextValueMember="Description"
												Method="GET" Url="/bankapi/v1.0/Bank/ValidBankProgramsForLimitProfile"
												DataRoot="Item" />
										</GridItem>
										<GridItem>
											<GenericDateInput Disabled={Disabled} Name="AgreementStartDate"
												LabelText="Agreement Start Date"
												Value={ApprovalData == null ? "" : ApprovalData.AgreementStartDate == null ? "" : DateHelper.ToDateInputValue(ApprovalData.AgreementStartDate) || ""}
												ValueChanged={this.handleChange} />
										</GridItem>
										<GridItem>
											<GenericDateInput Disabled={Disabled} Name="AgreementEndDate" LabelText="Agreement End Date" Value={ApprovalData == null ? "" : ApprovalData.AgreementEndDate == null ? "" : DateHelper.ToDateInputValue(ApprovalData.AgreementEndDate) || ""} ValueChanged={this.handleChange} />
										</GridItem>
										<GridItem>
											{paramGlobalModel && (paramGlobalModel.ClientType == ClientType.SalesAgentPersonal || paramGlobalModel.ClientType == ClientType.SalesAgentCorporate) && (
												<GenericSelectInput Disabled={Disabled} Name="SalesChannelTypeId" LabelText="Sales Channel" Value={ApprovalData == null ? "" : ApprovalData.SalesChannelTypeId || ""}
													ValueChanged={this.handleChange} KeyValueMember="Id" TextValueMember="ParameterDesc" Method="POST" Url="/coreapi/v1.0/Parameter/Search"
													Parameter={{ ParameterCode: "SalesChannel", ParameterValue2: "D", ParameterDesc: "" }} DataRoot="Item" />
											)}
											{paramGlobalModel && (paramGlobalModel.ClientType == ClientType.Personal || paramGlobalModel.ClientType == ClientType.Corporate) && (
												<GenericSelectInput Disabled={Disabled} Name="SalesChannelTypeId" LabelText="Sales Channel" Value={ApprovalData == null ? "" : ApprovalData.SalesChannelTypeId || ""}
													ValueChanged={this.handleChange} KeyValueMember="Id" TextValueMember="ParameterDesc" Method="POST" Url="/coreapi/v1.0/Parameter/Search"
													Parameter={{ ParameterCode: "SalesChannel" }} DataRoot="Item" />
											)}
										</GridItem>
									</GridItem>
									<GridItem xs={12} sm={4} md={4} lg={4}>
										<GridItem>
											<GenericSelectInput
												Disabled={Disabled}
												Name="NotificationPeriod"
												LabelText="Notification Period"
												IsStatic={true}
												StaticData={[{ Value: 50, Text: "50" }, { Value: 100, Text: "100" }, { Value: 150, Text: "150" }, { Value: 170, Text: "170" }, { Value: 180, Text: "180" }]}
												KeyValueMember="Value"
												TextValueMember="Text"
												Value={ApprovalData == null ? "" : ApprovalData.NotificationPeriod || ""}
												ValueChanged={this.handleChange}
											/>
										</GridItem>
										<GridItem>
											<GenericSelectInput Disabled={Disabled} Name="RenewalTypeId" LabelText="Renewal Type" Value={ApprovalData == null ? "" : ApprovalData.RenewalTypeId || ""} ValueChanged={this.handleChange} KeyValueMember="Id" TextValueMember="ParameterDesc" Method="POST" Url="/coreapi/v1.0/Parameter/Search" Parameter={{ ParameterCode: "RenewalType", ParameterValue: "", ParameterDesc: "" }} DataRoot="Item" />
										</GridItem>
										<GridItem>
											<GenericDateInput Disabled={Disabled || (ApprovalData != null && ApprovalData.Id > 0) == false} MinDate={ApprovalData && ApprovalData.AgreementEndDate ? DateHelper.ToMoment(ApprovalData.AgreementEndDate) : null} Name="LastRenewalDate" LabelText="Last Renewal Date" Value={ApprovalData == null ? "" : ApprovalData.LastRenewalDate == null ? "" : DateHelper.ToDateInputValue(ApprovalData.LastRenewalDate) || ""} ValueChanged={this.handleChange} />
										</GridItem>
										<GridItem>
											{ApprovalData != null && ((ApprovalData.SalesChannelType != null && ApprovalData.SalesChannelType.ParameterValue == SalesChannel.SalesPerson) || (ApprovalData.IsSalesChannel == true)) && (
												<GenericSelectInput Disabled={Disabled} Name="SalesPersonUniqueClientId" LabelText="Sales Person" Value={ApprovalData.SalesPersonUniqueClientId || ""} DataRoot="Item" ValueChanged={this.handleChange} KeyValueMember="Id" TextValueMember="Name" Url="/bankapi/v1.0/BankCustomer/Search" Method="POST" Parameter={{ UniqueClientId: 0, ClientTypes: [ClientType.SalesAgentPersonal, ClientType.SalesAgentCorporate] }} />
											)}
										</GridItem>
									</GridItem>
									<GridItem xs={12} sm={4} md={4} lg={4}>
										<GridItem>
											<GenericSelectInput Disabled={Disabled}
												Name="ContractStatusId"
												LabelText="Contract Status"
												Value={ApprovalData == null ? "" : ApprovalData == null ? "" : ApprovalData.ContractStatusId || ""}
												ValueChanged={this.handleChange} KeyValueMember="Id" TextValueMember="ParameterDesc"
												Method="POST" Url="/coreapi/v1.0/Parameter/Search"
												Parameter={{ ParameterCode: "ContractStatus", ParameterValue: "", ParameterDesc: "" }}
												DataRoot="Item" />
										</GridItem>
										<GridItem>
											<GenericSelectInput Disabled={Disabled} Method="POST" LabelMd={4} LabelText="Fee Profile"
												key={ApprovalData == null ? "fee" : ApprovalData.ProgramId + "fee" || "f"}
												Name="FeeProfileId" Url="/bankapi/v1.0/FeeProfile/GetByProgramId"
												Parameter={{ ProgramId: ApprovalData == null ? 0 : ApprovalData.ProgramId, ClientType: paramGlobalModel == null ? 0 : paramGlobalModel.ClientType }}
												DataRoot="Item" KeyValueMember="Id" TextValueMember="ProfileName"
												Value={ApprovalData == null ? "" : ApprovalData.FeeProfileId || ""}
												ValueChanged={this.handleChange} />

											{paramGlobalModel && (paramGlobalModel.ClientType == ClientType.SalesAgentCorporate || paramGlobalModel.ClientType == ClientType.SalesAgentPersonal) && (
												<GenericSelectInput Disabled={Disabled} Method="POST" LabelMd={4} LabelText="Commission Profile" key={ApprovalData == null ? "comm" : ApprovalData.ProgramId + "comm" || "c"}
													Name="CommissionFeeProfileId" Url="/bankapi/v1.0/FeeProfileAgentCommission/GetByProgramId" Parameter={{ Id: ApprovalData == null ? 0 : ApprovalData.ProgramId }}
													DataRoot="Item" KeyValueMember="Id" TextValueMember="ProfileName" Value={ApprovalData == null ? "" : ApprovalData.CommissionFeeProfileId || ""}
													ValueChanged={this.handleChange} />
											)}
											{((ApprovalData != null && ApprovalData.IsUnWeighedFeeProfile) || (ApprovalData != null && ApprovalData.AggrementPriorityTransactionCount != null)) && (
												<GenericNumberInput Disabled={Disabled} MaxLength={8} DecimalScale={0} LabelMd={4} Name="AggrementPriorityTransactionCount" LabelText="Aggrement Priority Transaction Count"
													Value={ApprovalData == null ? "" : ApprovalData.AggrementPriorityTransactionCount || ""}
													ValueChanged={this.handleChange} />
											)}
											{((ApprovalData != null && ApprovalData.IsUnWeighedFeeProfile) || (ApprovalData != null && ApprovalData.AggrementRegularTransactionCount != null)) && (
												<GenericNumberInput Disabled={Disabled} MaxLength={8} DecimalScale={0} LabelMd={4} Name="AggrementRegularTransactionCount" LabelText="Aggrement Regular Transaction Count"
													Value={ApprovalData == null ? "" : ApprovalData.AggrementRegularTransactionCount || ""}
													ValueChanged={this.handleChange} />
											)}
											{((ApprovalData != null && ApprovalData.IsCombineUnWeighedFeeProfile) || (ApprovalData != null && ApprovalData.AggrementTransactionCount != null)) && (
												<GenericNumberInput Disabled={Disabled} MaxLength={8} DecimalScale={0} LabelMd={4} Name="AggrementTransactionCount" LabelText="Aggrement Transaction Count"
													Value={ApprovalData == null ? "" : ApprovalData.AggrementTransactionCount || ""}
													ValueChanged={this.handleChange} />
											)}
										</GridItem>
										<GridItem>
											<GenericSelectInput Disabled={Disabled} Method="POST"
												LabelMd={4} LabelText="Limit Profile" key={ApprovalData == null ? "lim" : ApprovalData.ProgramId + "lim" || "l"}
												Name="LimitProfileId" Url="/bankapi/v1.0/LimitProfile/GetByProgramId"
												Parameter={{ ProgramId: ApprovalData == null ? 0 : ApprovalData.ProgramId }}
												DataRoot="Item" KeyValueMember="Id" TextValueMember="ProfileName"
												Value={ApprovalData == null ? "" : ApprovalData.LimitProfileId || ""}
												ValueChanged={this.handleChange} />

										</GridItem>
									</GridItem>
								</GridContainer>
							</GridItem>
						</CardBody>
					</Card>

				</GridItem>
			</GridContainer>
		);
	}
}

ContractApprove.propTypes = {
	classes: PropTypes.object
};

export default withArtifex(ContractApprove, {});