import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import { DeleteIcon, EditIcon } from "core/Icons";
import RouteHelper from "core/RouteHelper";
import PropTypes from "prop-types";
import React from "react";
import AccountLimitToolbar from "views/Components/AccountLimitToolbar";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTitle } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { BillStatusType, GridColumnType, MenuCodes, ProgramCodes } from "views/Constants/Constant";



class BillTransactionList extends React.Component {


	constructor(props) {
		super(props);

		this.emptyObject = {};
		this.renderItemUniqueClient = {};
		this.sortedName = { Member: "Name" };
		this.IsClient = ClientHelper.IsClient();
		this.ProgramCode = "";
		this.initialModel = {
			Id: 0,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined,
			StartDate: DateHelper.GetLocalDateAsUtc(),
			EndDate: DateHelper.GetLocalDateAsUtc(),
			IsUpdate: false,
			BillerCode: "",
			BillStatusTypeId: null,
			ReferenceNumber: null
		};

		this.state = {
			BillList: [],
			model: { ...this.initialModel },
			isLoading: false,
			alert: null,
		};

		this.hideAlert = this.hideAlert.bind(this);
		this.IsCustomer = false;

	}

	GetRenderItemUniqueClient = (x) => {
		this.renderItemUniqueClient = `${x.Name} - ${x.UniqueClientId}`;

		return this.renderItemUniqueClient;
	}
	componentDidMount() {
		this.props.setAppLeftTitle("Customer Transaction List");

		var isBackOff = ClientHelper.IsBackOffice();
		var model = { ...this.state.model };
		this.IsClient = false;
		if (!isBackOff) {
			this.IsClient = true;
			model.UniqueClientId = ClientHelper.GetClientRowId();
		}
		model.IsUpdate = false;
		this.setState({ model });

		switch (this.props.MenuCode) {
			case MenuCodes.BillCustomerTransactionList:
				this.props.setAppCenterTitle("BILL PAYMENT");
				this.props.setAppLeftTitle("Customer Transaction List");
				this.Url = "/bankapi/v1.0/Bill/GetCustomerBaseBillList";
				this.IsCustomer = true;
				this.ProgramCode = ProgramCodes.BillPayment;
				break;
			case MenuCodes.BillClientTransactionList:
				this.props.setAppCenterTitle("WALLET");
				this.props.setAppLeftTitle("Client Transaction List");
				this.Url = "/bankapi/v1.0/Bill/GetClientBaseBillList";
				this.IsCustomer = false;
				this.ProgramCode = ProgramCodes.Bank_Programs_Bank_Account;
				break;
			default:
				break;
		}
	}


	ClearDialog = () => {
		this.setState({ model: { ...this.initialModel } });
	}

	errorDefinitionCallback(error) {
		this.ShowMessage("error", "Error !", "An error occurred while requesting data" + error);
	}
	ShowMessage(type, title, message) {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
	}
	showCancelMessage(type, title, message, model) {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.hideAlert()} />
		});
	}
	hideAlert() {
		this.setState({
			alert: null,
			isLoading: false
		});
	}

	ValueChanged = (name, value) => {
		const model = { ...this.state.model };
		model[name] = value;
		this.setState({ model });
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
	}

	IsValid = () => {
		var vmodel = { ...this.state.model };

		if (vmodel.StartDate && typeof vmodel.StartDate != "object") {
			this.props.showMessage("warning", "Please fill required fields!", "Start Date is invalid");
			return false;
		}

		if (vmodel.EndDate && typeof vmodel.EndDate != "object") {
			this.props.showMessage("warning", "Please fill required fields!", "To Dateis invalid");
			return false;
		}

		if (vmodel.StartDate && vmodel.EndDate && vmodel.EndDate < vmodel.StartDate) {
			this.props.showMessage("warning", "Please fill required fields!", "Start Date cannot be bigger than End Date");
			return false;
		}

		return true;
	}


	Search = () => {

		if (!this.IsValid())
			return false;

		this.setState({ isLoading: true });
		const { model } = this.state;

		Proxy.POST(
			this.Url,
			model,
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({
						isLoading: false
					});
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				this.setState({ BillList: responseData.Item || [], isLoading: false });
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while requesting data", error.message);
			}
		);
	}
	showQuestionMessage = (message, model) => {
		this.setState({
			alert: <GenericAlert Title="Cancel" Message={message} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.CancelBill(model)} />
		});
		this.setState({ isLoading: false });
	}

	CancelDialog = (model) => {
		this.showQuestionCancelMessage("Are you sure you want to cancel the bill? ", model);
	}
	showQuestionCancelMessage = (message, model) => {
		this.setState({
			alert: <GenericAlert Title="Cancel" Message={message} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.CancelBill(model)} />
		});
		this.setState({ isLoading: false });
	}

	CancelBill = (model) => {
		this.setState({ isLoading: true, alert: null });
		Proxy.POST(
			"/bankapi/v1.0/Bill/CancelBillTransaction",
			{
				ReferenceNumber: model.TransactionReferenceNumber,
				UniqueClientId: model.UniqueClientId
			},
			responseData => {
				if (responseData.IsSucceeded != false) {
					this.props.showMessage("success", "Success", "Cancel Transaction Succesfuly!");

					this.setState({
						isLoading: false,
						alert: null
					});

					this.Search();
				}
				else {
					this.props.showMessage("error", "Error !", responseData.ErrorDescription);
					this.setState({
						isLoading: false,
						alert: null
					});
				}
			}
		);
	}

	RejectedDialog = (model) => {
		this.showQuestionRejectMessage("Are you sure you want to reject the bill? ", model);
	}

	showQuestionRejectMessage = (message, model) => {
		this.setState({
			alert: <GenericAlert Title="Cancel" Message={message} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.RejectBill(model)} />
		});
		this.setState({ isLoading: false });
	}

	RejectBill = (model) => {
		this.setState({ isLoading: true, alert: null });
		Proxy.POST(
			"/bankapi/v1.0/Bill/RejectBillTransaction",
			{
				ReferenceNumber: model.TransactionReferenceNumber,
				UniqueClientId: model.UniqueClientId
			},
			responseData => {
				if (responseData.IsSucceeded != false) {
					this.props.showMessage("success", "Success", "Rejected Transaction Succesfuly!");

					this.setState({
						isLoading: false,
						alert: null
					});

					this.Search();
				}
				else {
					this.props.showMessage("error", "Error !", responseData.ErrorDescription);
					this.setState({
						isLoading: false,
						alert: null
					});
				}
			}
		);

	}


	render() {
		const { model } = this.state;
		const { Disabled } = this.props;
		return (
			<div>

				{this.IsClient && this.IsCustomer == false &&
					<AccountLimitToolbar Program={ProgramCodes.Bank_Programs_Bank_Account} LimitMainType={ProgramCodes.BillPayment} IsUseDisableDebitLimit={true} OnLoad={data => { this.setState({ LimitBalanceData: data, isLoading: false }); }} />
				}
				<GridContainer spacing={16}>
					{this.state.alert}
					<GridItem xs={12}>

						<LoadingComponent Show={this.state.isLoading} />
						{this.state.alert}

						<ButtonToolbar
							ButtonList={[
								{
									Code: "Search",
									Disabled: false,
									OnClick: this.Search
								},
								{
									Code: "Clear",
									Disabled: Disabled,
									OnClick: this.ClearDialog

								}
							]}
							menuId={this.props.menuId}
							ApprovalData={this.props.ApprovalData}
							After={this.props.After} />

						{this.state.alert}
						<GridContainer>
							<GridItem xs={12}>
								<Card>
									<GenericExpansionPanel IsActive={true} Title="Filter Panel">
										<CardBody>
											<GridContainer >
												<GridItem xs={6}>
													<GenericSelectInput
														Name="UniqueClientId"
														LabelText="Client"
														Method="POST"
														Url="/bankapi/v1.0/BankCustomer/Search"
														Parameter={this.emptyObject}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="Name"
														RenderItem={this.GetRenderItemUniqueClient}
														Sorted={this.sortedName}
														All={true}
														Value={model.UniqueClientId}
														ValueChanged={this.ValueChanged}
														Disabled={this.IsClient}
													/>

													<GenericSelectInput
														key={[model.UniqueClientId, this.IsCustomer]}
														Name="BillerCode"
														LabelText="Payee Name"
														Method="POST"
														Url="/bankapi/v1.0/Biller/GetAllBiller"
														DataRoot="Item"
														KeyValueMember="BillerCode"
														TextValueMember="BillerName"
														Value={model.BillerCode}
														Disabled={false}
														ValueChanged={this.ValueChanged}
														Parameter={{ UniqueClientId: model.UniqueClientId, IsCustomer: this.IsCustomer, BillPayType: 1 }}
													/>

													<ParameterComponent
														Name="BillStatusTypeId"
														LabelText="Status Type"
														ParameterCode="BillStatusType"
														Value={model.BillStatusTypeId}
														ValueChanged={this.ValueChanged}
														Sorted={{ Member: "ParameterDesc" }}
													/>

												</GridItem>


												<GridItem xs={6}>
													<GenericDateInput
														Utc
														Name="StartDate"
														LabelText="From Date"
														Value={model.StartDate}
														ValueChanged={this.ValueChanged}
														MaxDate={model.EndDate || DateHelper.GetDate()}
														IncludeTime={false}
													/>

													<GenericDateInput
														Utc
														Name="EndDate"
														LabelText="To Date"
														Value={model.EndDate}
														ValueChanged={this.ValueChanged}
														MinDate={model.StartDate}
														MaxDate={DateHelper.GetDate()}
													/>
													<GenericNumberInput NoFormatting Name="ReferenceNumber" LabelText="Reference Number" Value={model.ReferenceNumber} ValueChanged={this.ValueChanged} />
												</GridItem>
											</GridContainer>
										</CardBody>
									</GenericExpansionPanel>
								</Card>
							</GridItem>
						</GridContainer>


						<GridContainer>


							<GridItem xs={12}>
								<Card className="no-radius">
									<GridItem>
										<GenericTitle text={"Transaction List"} /></GridItem>
									<CardBody>
										<GridContainer>
											<GridItem xs={12}>
												<GenericGrid
													Data={this.state.BillList.map(d => {
														return {
															Actions: (
																<div>

																	{this.IsClient == false &&
																		<GridButton
																			Icon={DeleteIcon}
																			Disabled={
																				(d.BillStatusValue == BillStatusType.Rejected ? true : false) ||
																				(d.BillStatusValue == BillStatusType.Canceled ? true : false) ||
																				(d.BillStatusValue == BillStatusType.Waiting ? true : false)
																			}
																			tooltip="Reject"
																			OnClick={() => this.RejectedDialog(d)}

																		/>
																	}
																	{this.IsClient == true &&
																		<GridButton
																			Icon={DeleteIcon}
																			Disabled={
																				(d.BillStatusValue == BillStatusType.Payed ? true : false) ||
																				(d.BillStatusValue == BillStatusType.Rejected ? true : false) ||
																				(d.BillStatusValue == BillStatusType.Canceled ? true : false) ||
																				(d.BillStatusValue != BillStatusType.Waiting ? true : false) ||
																				(d.IsFileWrite ? true : false)

																			}
																			tooltip="Cancel"
																			OnClick={() => this.CancelDialog(d)}

																		/>
																	}

																	{this.IsClient == true &&
																		<GridButton
																			Icon={EditIcon}
																			Disabled={
																				(d.BillStatusValue == BillStatusType.Canceled ? true : false) ||
																				(d.BillStatusValue == BillStatusType.Rejected ? true : false) ||
																				(d.BillStatusValue == BillStatusType.Payed ? true : false) ||
																				(d.IsFileWrite ? true : false)
																			}
																			OnClick={() => {
																				if (this.IsCustomer)
																					RouteHelper.Push(this.props.history, "/BillDefinition", "BillModel", d);
																				else
																					RouteHelper.Push(this.props.history, "/ClientBillDefinition", "BillModel", d);
																			}
																			}
																		/>}

																</div>
															),

															BillerName: d.BillerName,
															BillerCode: d.BillerCode,
															BillAccountNumber: d.BillAccountNumber,
															ClientCustomerName: d.ClientCustomerName,
															BillerTypeName: d.BillerTypeName,
															BillerGroupName: d.BillerGroupName,
															AutoPayInstructions: d.AutoPayInstructions,
															IsAutoPayPeriod: d.IsAutoPayPeriod,
															AutoPayAmount: d.AutoPayAmount,
															AutoPayPeriod: d.AutoPayPeriod,
															Date: d.Date,
															BillerProvinceName: d.BillerProvinceName,
															LastPayment: d.LastPayment,
															LastPaymentDate: d.LastPaymentDate,
															TelpaySend: d.TelpaySend,
															Amount: d.Amount,
															TotalAmount: d.TotalAmount,
															Fee: d.Fee,
															TransactionDate: d.TransactionDate,
															BillStatusValue: d.BillStatusValue,
															IsFileWrite: d.IsFileWrite,
															Id: d.Id,
															UniqueClientId: d.UniqueClientId,
															SettlementDate: d.TelpaySendDate,
															TransactionReferenceNumber: d.TransactionReferenceNumber,
															UserName: d.UserName,
															TransactionSource: d.TransactionSource
														};
													})}

													Columns={[
														{
															Header: "Actions",
															accessor: "Actions",
															sortable: false,
															filterable: false,
															width: 60
														},

														{
															Header: "Transaction Date",
															accessor: "TransactionDate",
															type: GridColumnType.DateTime
														},
														{
															Header: "Unique Client Id",
															accessor: "UniqueClientId"
														},
														{
															Header: "Settlement Date",
															accessor: "SettlementDate",
															type: GridColumnType.DateTime
														},
														{
															Header: "Customer Name",
															accessor: "ClientCustomerName",
															show: this.IsCustomer
														},

														{
															Header: "Amount",
															accessor: "Amount",
															type: GridColumnType.Money,
															ColumnType: GridColumnType.Money
														},
														{
															Header: "Fee Amount",
															accessor: "Fee",
															type: GridColumnType.Money,
															ColumnType: GridColumnType.Money
														},
														{
															Header: "Status",
															accessor: "TelpaySend"
														},
														{
															Header: "Payee Name",
															accessor: "BillerName"
														},
														{
															Header: "Payee Wallet No.",
															accessor: "BillAccountNumber"
														},

														{
															Header: "Total Amount",
															accessor: "TotalAmount",
															type: GridColumnType.Money,
															ColumnType: GridColumnType.Money
														},
														{
															Header: "Last Payment",
															accessor: "LastPayment",
															type: GridColumnType.Money,
															ColumnType: GridColumnType.Money
														},
														{
															Header: "Last Payment Date",
															accessor: "LastPaymentDate",
															type: GridColumnType.Date
														},
														{
															Header: "Auto-pay Intructions",
															accessor: "IsAutoPayPeriod",
															Cell: row => (
																<div>{row.value ? "Yes" : "No"}</div>
															),
														},
														{
															Header: "Auto-pay Period",
															accessor: "AutoPayPeriod"
														},
														{
															Header: "Auto-pay Amount",
															accessor: "AutoPayAmount",
															type: GridColumnType.Money,
															ColumnType: GridColumnType.Money
														},
														{
															Header: "Transaction Source",
															accessor: "TransactionSource",
															Cell: row => (
																<div>
																	{row.value == "Manuel" ? "Manuel"
																		: row.value == "Sftp" ? "Sftp"
																			: row.Value == "FileUpload" ? "File Upload"
																				: "Api"
																	}


																</div>
															),
														},
														{
															Header: "Reference Number",
															accessor: "TransactionReferenceNumber"
														}
													]
													}
													PageSize={500}
													IsSorted={false}
													ProgramCode={this.ProgramCode} 
													ShowPagination={true}/>
											</GridItem>
										</GridContainer>
									</CardBody>
								</Card>
							</GridItem>
						</GridContainer>

					</GridItem >
				</GridContainer >
			</div >
		);
	}

}

BillTransactionList.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	PayeeId: PropTypes.any,
	ApprovalData: PropTypes.any,
	onModelChange: PropTypes.func,
	After: PropTypes.any,
	model: PropTypes.object,
};

export default BillTransactionList;