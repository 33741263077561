import { DialogContent, DialogTitle, Slide } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import createEFTIndividualStyle from "assets/jss/material-dashboard-pro-react/views/createEFTIndividualStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericCheckInput, GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericLabel, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import ParameterComponent from "views/Components/ParameterComponent.jsx";
import { EFTEntryType, ProgramCodes } from "views/Constants/Constant.js";
import AddNewCustomerEFT from "views/EFT/AddNewCustomerEFT.jsx";

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

class CreateEFTIndividual extends Component {

	constructor(props) {
		super(props);

		this.state = { customerToken: "0" };

		this.isClient = ClientHelper.IsClient();

		this.sortName = { Member: "Name" };
		this.sortAccountName = { Member: "AccountName" };
		this.sortParamDesc = { Member: "ParameterDesc" };
		this.sortParamValue = { Member: "ParameterValue" };
		this.ClientParamObj = { ProgramCode: ProgramCodes.Bank_Programs_EFT, UniqueClientId: null };

		this.ValueChanged = this.ValueChanged.bind(this);
		this.addCustomerDialogClose = this.addCustomerDialogClose.bind(this);
		this.addCustomerSaved = this.addCustomerSaved.bind(this);
		this.GetClientParamObj = this.GetClientParamObj.bind(this);
	}

	ValueChanged(name, newValue, data) {
		this.props.onModelChange(model => {
			model[name] = newValue;

			if (name == "PayeeId") {
				model.PayeeName = data ? data.Name : "";
				model.BeneficiaryId = "";
			}

			if (name == "NewClient" && newValue) {
				model.IsCustomerPopupOpen = true;
				this.setState({ isCustomerAdded: false });
			}
			if (name == "TransferMemoId") {
				model.TransferMemo = data ? data.Memo : "";
			}
			if (name == "IsHaveMemo") {
				if (newValue) model.TransferMemo = "";
				model.TransferMemoId = "";
			}
			if (name == "TransactionType")
				model.TransactionTypeCode = data ? data.ParameterValue : "";

			return model;
		});
	}

	addCustomerDialogClose() {
		this.ValueChanged("IsCustomerPopupOpen", false);
	}

	addCustomerSaved(customerData) {
		if (customerData != null) {
			this.props.onModelChange(model => {
				model.PayeeId = customerData.Id;
				model.PayeeName = customerData.CustomerName;
				model.BeneficiaryId = "";

				return model;
			});

			var newCustomerToken = this.state.customerToken == "1" ? "0" : "1";
			this.setState({ customerToken: newCustomerToken });
		}

		this.addCustomerDialogClose();
	}

	GetClientParamObj() {
		const { parentModel } = this.props;
		this.ClientParamObj.UniqueClientId = parentModel.UniqueClientId;
		return this.ClientParamObj;
	}

	RenderBeneficiarySelect(d) {
		return `${d.FinancialInstitution.Description} - ${d.AccountNumber}`;
	}

	RenderAccountSelect(d) {
		const accountNumber = d.Account && d.Account.AccountNumber;
		const accountName = d.Account && d.Account.AccountName;
		const storeCode = d.CustomerStore && d.CustomerStore.StoreCode;
		const storeName = d.CustomerStore && d.CustomerStore.StoreName;

		return `${storeName}(${storeCode}) - ${accountName}(${accountNumber})`;
	}

	RenderTransactionCode(d) {
		const transactionCode = d.ParameterValue;
		const transactionCodeDescription = d.ParameterDesc;

		return `${transactionCode} - ${transactionCodeDescription}`;
	}

	render() {
		const { model, vModel, Disabled: DisabledProp, parentModel, CustomerStoreList } = this.props;

		const Selected = parentModel.EntryCode == EFTEntryType.Individual;
		const Disabled = DisabledProp || !Selected;

		return (
			<div style={{ opacity: Selected ? 1.0 : 0.4 }}>
				<Card>
					<CardBody>
						<GenericExpansionPanel IsActive={Selected} Title="Individual Entry">
							<GridContainer spacing={16} alignItems="center" style={{ marginLeft: 2 }}>
								<GridItem xs={4}>
									{CustomerStoreList != null && CustomerStoreList.length > 0 &&
										<GenericSelectInput
											LabelMd={4}
											key={"CustomerStoreId" + this.state.customerToken + "_" + parentModel.UniqueClientId}
											Name="CustomerStoreId"
											LabelText="Store Wallet"
											IsStatic={true}
											StaticData={CustomerStoreList}
											DataRoot="Item"
											KeyValueMember="CustomerStoreId"
											RenderItem={this.RenderAccountSelect}
											Value={model.CustomerStoreId}
											ValueChanged={this.ValueChanged}
											Disabled={Disabled || parentModel.IsUpdate}
											Required
											IsInvalid={vModel.CustomerStoreId} />}
								</GridItem>
								<GridItem xs={8}>

								</GridItem>
								<GridItem xs={4}>
									<GenericSelectInput
										LabelMd={4}
										key={"PayeeId_" + this.state.customerToken + "_" + parentModel.UniqueClientId}
										Name="PayeeId"
										LabelText="Customer Name"
										Method="POST"
										Url="/bankapi/v1.0/ClientCustomer/GetByProgramCodeAndClientId"
										DataRoot="Item"
										Parameter={this.GetClientParamObj()}
										KeyValueMember="Id"
										TextValueMember="Name"
										Value={model.PayeeId}
										ValueChanged={this.ValueChanged}
										Disabled={Disabled} Sorted={this.sortName}
										Required
										IsInvalid={vModel.PayeeId} />
								</GridItem>
								<GridItem xs={4}>
									<GenericSelectInput
										LabelMd={4}
										key={"BeneficiaryId_" + model.PayeeId}
										Name="BeneficiaryId"
										LabelText="Customer Wallet"
										Method="GET"
										Url={"/bankapi/v1.0/Beneficiary/GetByCustomerId/Id?Id=" + model.PayeeId}
										DataRoot="Item"
										KeyValueMember="Id"
										RenderItem={this.RenderBeneficiarySelect}
										Value={model.BeneficiaryId}
										ValueChanged={this.ValueChanged}
										Disabled={Disabled} Sorted={this.sortAccountName}
										Required
										IsInvalid={vModel.BeneficiaryId} />
								</GridItem>
								<GridItem xs={2}>
									{this.isClient && !parentModel.IsUpdate && <GenericCheckInput Name="NewClient" LabelText="Add New Customer" Value={model.NewClient} ValueChanged={this.ValueChanged} Disabled={Disabled} />}
								</GridItem>
								<GridItem xs={2}>
								</GridItem>
								<GridItem xs={4}>
									<GenericSelectInput
										Name="TransactionType"
										LabelText="Transaction Type"
										Method="GET"
										Url="/bankapi/v1.0/Transaction/GetAllFinancialTransactionType"
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={model.TransactionType}
										ValueChanged={this.ValueChanged}
										Disabled={Disabled || parentModel.IsUpdate}
										Required
										IsInvalid={vModel.TransactionType} />
								</GridItem>
								<GridItem xs={4}>
									<ParameterComponent
										Name="TransactionCode"
										LabelText="Transaction Code"
										ParameterCode="EFTTransactionType"
										RenderItem={this.RenderTransactionCode}
										Value={model.TransactionCode}
										ValueChanged={this.ValueChanged}
										Disabled={Disabled}
										Sorted={this.sortParamValue}
										Required
										IsInvalid={vModel.TransactionCode} />
								</GridItem>
								<GridItem xs={4}>
								</GridItem>
								<GridItem xs={4}>
									<GenericNumberInput
										LabelMd={4} Name="Amount" LabelText="Amount"
										Value={model.Amount} ValueChanged={this.ValueChanged}
										MaxLength={10} Disabled={Disabled || parentModel.IsUpdate} Prefix="$"
										Required
										IsInvalid={vModel.Amount} />
								</GridItem>
								<GridItem xs={4}>
									<GenericDateInput
										LabelMd={4}
										Name="TransactionDate"
										LabelText="Transaction Date"
										Value={model.TransactionDate}
										ValueChanged={this.ValueChanged}
										Disabled={Disabled || parentModel.IsUpdate}
										IsFuture={true}
										Required
										IsInvalid={vModel.TransactionDate}
									/>
								</GridItem>
								<GridItem xs={4}>
								</GridItem>
								<GridItem xs={8}>
									{model.IsHaveMemo ?
										<GenericSelectInput
											key={"Memo_" + parentModel.UniqueClientId}
											LabelMd={2}
											Name="TransferMemoId"
											LabelText="Transfer Memo"
											Method="POST"
											Url="/bankapi/v1.0/BankBmoEFT/GetMemosByClientId"
											Parameter={parentModel.UniqueClientId}
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember="Memo"
											Value={model.TransferMemoId}
											ValueChanged={this.ValueChanged}
											Disabled={Disabled} />
										:
										<GenericTextInput LabelMd={2} Name="TransferMemo" LabelText="Transfer Memo" Value={model.TransferMemo} ValueChanged={this.ValueChanged} Disabled={Disabled} />
									}
								</GridItem>
								<GridItem xs={2}>
									<GenericCheckInput Name="IsHaveMemo" LabelText="Get Memo From List" Value={model.IsHaveMemo} ValueChanged={this.ValueChanged} Disabled={Disabled} />
								</GridItem>
								<GridItem xs={2}>
								</GridItem>
							</GridContainer>
						</GenericExpansionPanel>
					</CardBody>
				</Card>

				<GenericDialog open={model.IsCustomerPopupOpen} fullScreen onBackdropClick={this.addCustomerDialogClose} TransitionComponent={Transition}>
					<DialogTitle>
						<GenericLabel FontSize="16px" Text="Add New Customer" Bold={true} />
					</DialogTitle>
					<DialogContent>
						<AddNewCustomerEFT ForInsert CustomerSaved={this.addCustomerSaved} menuId={this.props.addNewCustomerMenuId} />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.addCustomerDialogClose}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>

			</div>
		);
	}
}

CreateEFTIndividual.propTypes = {
	classes: PropTypes.object.isRequired,
	model: PropTypes.object,
	vModel: PropTypes.object,
	parentModel: PropTypes.object,
	onModelChange: PropTypes.func,
	Disabled: PropTypes.bool,
	addNewCustomerMenuId: PropTypes.number,
	CustomerStoreList: PropTypes.array
};

export default withStyles(createEFTIndividualStyle)(CreateEFTIndividual);