import { Hidden } from "@material-ui/core";
import atmLocatorStyle from "assets/jss/material-dashboard-pro-react/views/atmLocatorStyle.jsx";
import cx from "classnames";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ExternalContainer from "layouts/ExternalContainer";
import PropTypes from "prop-types";
import React from "react";
import { GenericMapContainer, GenericMapMarker, GenericMapSearchbox } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";



const AtmComponent = React.memo((props) => {

	const { item, rowIndex, classes, OnClick } = props;
	const comp = <div
		className={cx({
			[classes.locationBox]: true,
			[classes.locationBoxSelected]: rowIndex + 1 == item.Id
		})}
		onClick={OnClick(item.Id)}
		key={item.AtmCode}
	>
		<div>
			<span className={classes.infoBox}>
				{item.Address}
			</span>
		</div>
		<div className={classes.infoBoxDescription}>
			<span className={classes.distanceStyle}>
				Distance: {Number(item.Distance).toFixed(2)}km
					  </span>
			<br />
			{item.SiteName}
			<br />
			{item.PostCode}
			<br />
		</div>
	</div>;
	return (comp);
}, (prev, next) => {
	var itemDiff = prev.item.Id == next.item.Id;
	var rowIndexDiff = next.item.Id != next.rowIndex + 1;
	var oldRowIndexDiff = prev.item.Id != prev.rowIndex + 1;
	return itemDiff && rowIndexDiff && oldRowIndexDiff;
});

const AtmListComponent = React.memo((props) => {
	const { classes, tempData, rowIndex, OnClick } = props;
	const comp = <div>
		{tempData.map((item, index) => {
			return (
				<AtmComponent
					index={index}
					item={item}
					rowIndex={rowIndex}
					OnClick={OnClick}
					classes={classes}
				/>);
		})}
	</div>;
	return (comp);
}, (prev, next) => {
	var itemDiff = prev.tempData == next.tempData;
	return itemDiff;
});
const MapMarkerPopupComponent = React.memo(({ AtmCode, SiteName, Address, City, Province, PostCode, infoBoxTitleClassName }) => {

	return (
		<div>
			<h5>
				<b className={infoBoxTitleClassName}>
					{SiteName}
				</b>
			</h5>
			<p>
				{Address}, {City}, {Province}
			</p>
			<p>{PostCode}</p>
		</div>
	);
}, (prev, next) => {
	return prev.AtmCode == next.AtmCode;
});

class AtmLocator extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			searchedViewport: null,
			googleApiKey: window.GoogleMapApiKey,
			isFirstSearch: false,
			disableSearch: true,
			data: [],
			hasSearched: false,
			tempData: [],
			filterModel: {},
			model: {},
			isLoading: false,
			rowIndex: null,
			focusedLocation: {
				lat: 60.736176,
				lng: -102.11977
			},
			mapZoom: 4,
			mobileOpen: false,
			myLocation: null
		};
		this.markerRef = React.createRef();
		this.googleMapRef = React.createRef();
		this.mapSearchboxRef = React.createRef();
		this.defaultMapOptions = {
			scrollwheel: true,
			zoomControl: true
		};
		this.mapPlaceholderComponent = <div style={{ height: "67vh" }} />;
		this.mapContainerComponent = <div
			style={{
				height: "67vh",
				borderRadius: "6px",
				overflow: "hidden"
			}}
		/>;

		this.genericMapSearchboxInputProps = {
			onChange: () => {
				this.setState({ disableSearch: true });
			}
		};

		this.markerLatLngList = {};

		this.handleMarkerClickList = {};
		this.markerInfoWindowProps = {
			onCloseClick: this.handleMapClick
		};

		this.atmListComponentClasses = {};
		this.emptyObject = {};
		this.externalContainerRef = React.createRef();
	}
	setLocation = (location) => {
		var filterModel = { ...this.state.filterModel };
		filterModel.Latitude = location.coords.latitude;
		filterModel.Longitude = location.coords.longitude;
		this.setState({ myLocation: location, filterModel });
		this.Search({ filterModel });
	}
	componentDidMount() {
		//this.loadGoogleApiKey();
		this.getClientLocation();
	}
	loadGoogleApiKey = () => {
		Proxy.POST(
			"/coreapi/v1.0/Parameter/GoogleApiKey",
			{},
			responseData => {
				if (!responseData.IsSucceeded || !responseData.Item || responseData.Item.length == 0) {
					console.log("Canada Post Api key not fetched !");
					return;
				}
				var apikey = responseData.Item;
				console.log("KEY Google ", apikey);
				this.setState({ googleApiKey: apikey });
			},
			e => {
				console.log("Google Api key not fetched !");
			}
		);
	};

	getClientLocation = () => {
		var setLocation = this.setLocation;
		if (navigator.geolocation) {
			window.navigator.geolocation.getCurrentPosition(function (position) {
				setLocation(position);
			});
		}
		else {
			console.log("Browser is not supporting geolocation api");
		}
	}

	atmListComponent = () => {

	};

	getMapZoom = (zoomLevel) => {
		const rand = Math.random();
		return parseFloat(zoomLevel + ".000" + rand.toString().substr(2, 3));
	}
	Search = (model) => {
		this.setState({ isLoading: true });
		var requestModel = (model && model.filterModel) || this.state.filterModel;
		Proxy.POST(
			"/bankapi/v1.0/Atm/SearchAtmLocator",
			requestModel,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					console.log("Error!");
					return;
				}

				if (responseData.Item != null) {

					var data = responseData.Item || [];
					var locations = data.Locations.map((item, index) => {
						item.Id = index + 1;
						if (index == 0) {
							item.IsOpen = true;
						}
						return item;
					});
					var rowIndex = -1;
					if (locations && locations.length > 0) {
						rowIndex = 0;

						var atmContainerDiv = document.getElementById("AtmContainer");
						var atmContainerMobileDiv = document.getElementById("AtmContainerMobile");

						if (atmContainerDiv) atmContainerDiv.scrollTop = 0;
						if (atmContainerMobileDiv) atmContainerMobileDiv.scrollTop = 0;
					}
					const filterModel = { ...requestModel };


					var state = {
						data: locations,
						tempData: locations,
						hasSearched: true,
						//mapZoom: this.getMapZoom(15),
						rowIndex,
						filterModel
					};

					const googleMap = this.googleMapRef.current;
					if (this.state.searchedViewport && googleMap) {
						googleMap.fitBounds(this.state.searchedViewport);
					} else if (locations[0]) {
						state.focusedLocation = {
							lat: locations[0].Latitude,
							lng: locations[0].Longitude
						};
						state.mapZoom = this.getMapZoom(17);
					}
					else if (data.FocusLocation) {
						state.focusedLocation = {
							lat: data.FocusLocation && data.FocusLocation.X || null,
							lng: data.FocusLocation && data.FocusLocation.Y || null
						};
					}
					this.setState(state);
				}
			},
			e => {
				console.log("Error!");
				this.setState({ isLoading: false });
			}
		);
	};

	handleChangeFilter = (name, newValue, data) => {
		const temp = { ...this.state.filterModel };
		temp[name] = newValue;
		this.setState({ filterModel: temp });
	};

	getHandleMarkerClick = (id) => {
		var handler = this.handleMarkerClickList[id];
		if (!handler) {
			this.handleMarkerClickList[id] = (e) => this.handleMarkerClick(e, id);
		}
		return this.handleMarkerClickList[id];
	}
	handleMarkerClick = (e, id) => {
		const temp = [...this.state.tempData];
		var markedIndex = -1;
		var modifiedData = temp.map((item, index) => {
			item.IsOpen = false;
			if (item.Id == id) {
				item.IsOpen = true;
				markedIndex = index;
			}
			return item;
		});
		var selected = temp.find(a => a.Id == id);
		var hasMobileOpen = this.state.mobileOpen;
		var ref = this.externalContainerRef && this.externalContainerRef.current;
		ref && ref.setDrawerState(false);
		setTimeout(() => {
			this.setState({
				tempData: modifiedData,
				model: selected,
				rowIndex: markedIndex,
				mapZoom: this.getMapZoom(17)
			});

		}, hasMobileOpen ? 250 : 0);


	};

	handleMapClick = () => {
		const temp = [...this.state.tempData];
		var modifiedData = temp.map((item, index) => {
			item.IsOpen = false;
			return item;
		});
		this.setState({ tempData: modifiedData });
	};


	handleOnPlacesChanged = () => {
		const mapSearchbox = this.mapSearchboxRef.current;
		var places = mapSearchbox.getPlaces();
		if (places && places.length > 0) {
			var firstPlace = places[0];
			if (!firstPlace) {
				return;
			}
			var bound = new window.google.maps.LatLngBounds();
			bound.union(firstPlace.geometry.viewport);

			var loc = firstPlace.geometry.location;
			var filterModel = { ...this.state.filterModel };
			filterModel.Address = firstPlace.vicinity;
			filterModel.Latitude = loc.lat();
			filterModel.Longitude = loc.lng();
			this.setState({ filterModel, disableSearch: false, searchedViewport: bound });
		}

	}
	getMarkerPosition = (item) => {
		var latLngList = this.markerLatLngList[item.Id];

		if (!latLngList) {
			var newLatLng = {
				lat: item.Latitude,
				lng: item.Longitude
			};
			this.markerLatLngList[item.Id] = newLatLng;
			return newLatLng;
		}
		else {
			latLngList.lat = item.Latitude;
			latLngList.lng = item.Longitude;
			return latLngList;
		}
	}
	getPopupComponent = (item) => {
		var latLngList = this.markerLatLngList[item.Id];

		if (!latLngList) {
			var newLatLng = {
				lat: item.Latitude,
				lng: item.Longitude
			};
			this.markerLatLngList[item.Id] = newLatLng;
			return newLatLng;
		}
		else {
			latLngList.lat = item.Latitude;
			latLngList.lng = item.Longitude;
			return latLngList;
		}
	}
	generateMarkerList = () => {

		const { tempData } = this.state;
		const { classes } = this.props;
		const markerList = tempData.map((item, index, list) => {

			if (index + 1 == list.length) {
				if (!this.state.isFirstSearch) {
					setTimeout(() => {
						const googleMap = this.googleMapRef.current;
						this.state.searchedViewport && googleMap && googleMap.fitBounds(this.state.searchedViewport);
						this.setState({ isFirstSearch: true });
					}, 500);
				}
			}
			return (
				<div key={item.AtmCode}>
					<GenericMapMarker
						MarkerRef={this.state.rowIndex == index && this.markerRef}
						position={this.getMarkerPosition(item)}
						onClick={this.getHandleMarkerClick(item.Id)}
						infoWindowProps={this.markerInfoWindowProps}
						OpenPopup={item.IsOpen}
						PopupComponent={item.IsOpen ? <MapMarkerPopupComponent {...item} infoBoxTitleClassName={classes.infoBoxTitle} /> : this.emptyObject}
					/>
				</div>
			);

		});
		const rand = Math.random();
		return <div key={rand}>{markerList}</div>;
	}

	getAtmListComponentClasses = (classes) => {

		var classesList = ["distanceStyle", "infoBoxDescription", "infoBox", "locationBoxSelected", "locationBox"];
		classesList.forEach(item => {
			this.atmListComponentClasses[item] = classes[item];
		});
		return this.atmListComponentClasses;
	}
	LeftSideComponent = () => {
		const { classes } = this.props;
		const { tempData, rowIndex } = this.state;
		return (
			<div id="AtmContainerMobile" >
				<AtmListComponent
					OnClick={this.getHandleMarkerClick}
					classes={this.getAtmListComponentClasses(classes)}
					tempData={tempData}
					rowIndex={rowIndex}
				/>
			</div>);
	}
	handleDrawerChanged = (state) => {
		this.setState({ mobileOpen: state });
	}
	render() {
		const {
			focusedLocation,
			mapZoom,
			hasSearched,
			disableSearch,
			googleApiKey,
			myLocation,
			tempData,
			rowIndex
		} = this.state;
		const { classes } = this.props;
		const { mobileOpen } = this.state;

		return (
			<>
				<LoadingComponent Show={this.state.isLoading} />
				<ExternalContainer
					LeftSideItem={this.LeftSideComponent}
					OnDrawerChanged={this.handleDrawerChanged}
					OpenDrawer={mobileOpen}
					innerRef={this.externalContainerRef}
				>
					<GridContainer spacing={0}>
						<GridItem xs={12}>
							<Card className="no-radius">
								<CardBody>
									<GridContainer>
										<GridItem xs={12}>
											<div className={classes.title}>
												<h3>ATM locator</h3>
												<span>Enter Address</span>
											</div>
											<div className={classes.searchBox}>
												<div className={classes.input}>
													<GenericMapSearchbox
														key={googleApiKey}
														ApiKey={googleApiKey}
														MapSearchboxRef={this.mapSearchboxRef}
														inputProps={this.genericMapSearchboxInputProps}
														onPlacesChanged={this.handleOnPlacesChanged}
													/>
												</div>
												<Button
													className={classes.search}
													size="md"
													onClick={this.Search}
													disabled={disableSearch}
												>
													Search
                      				</Button>
											</div>
										</GridItem>

										{hasSearched || (myLocation) ? (
											<>
												<Hidden smDown>
													<GridItem md={3}>
														<div id="AtmContainer" className={classes.locationBoxWrapper}  >
															<AtmListComponent
																OnClick={this.getHandleMarkerClick}
																classes={this.getAtmListComponentClasses(classes)}
																tempData={tempData}
																rowIndex={rowIndex}
															/>
														</div>
													</GridItem>
												</Hidden>
												<GridItem xs={12} md={9}>
													<GenericMapContainer
														key={googleApiKey}
														ApiKey={googleApiKey}
														MapContainerRef={this.googleMapRef}
														onClick={this.handleMapClick}
														defaultZoom={4}
														zoom={mapZoom}
														defaultOptions={this.defaultMapOptions}
														center={focusedLocation}
														loadingElement={this.mapPlaceholderComponent}
														mapElement={this.mapPlaceholderComponent}
														containerElement={this.mapContainerComponent}
													>
														{this.generateMarkerList()}
													</GenericMapContainer>
												</GridItem>
											</>
										) : (
												<h4 className={classes.initialMsg}>
													<span>
														Enter an Address or Postal code to find nearby ATMs
                      					</span>
												</h4>
											)}
									</GridContainer>
								</CardBody>
							</Card>
						</GridItem>
					</GridContainer>
				</ExternalContainer>
			</>);
	}
}
AtmLocator.propTypes = {
	classes: PropTypes.object
};

export default withArtifex(AtmLocator, atmLocatorStyle);
