import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDateInput, GenericExpansionPanel, GenericGrid, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType } from "views/Constants/Constant";

class MastercardFinancialPosDetail extends Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			FromDate: DateHelper.GetLocalDateAsUtc(),
			ToDate: DateHelper.GetLocalDateAsUtc(),
			FileName:""
		};

		this.state = {
			model: {...this.initialModel},
			vModel: {},
			list: [],
			isLoading: false,
			
		};


		this.emptyObject = {};

		this.maxLength26 = { maxLength: 26 };


		this.Columns = [
			{
				Header: "Clearing Date",
				accessor: "MastercardFinancialPositionDetail.ClearingDate",
				type: GridColumnType.DateTime
			},
			{
				Header: "File Name",
				accessor: "FileName"
			},
			{
				Header: "Mti",
				accessor: "MastercardFinancialPositionDetail.Mti"
			},
			{
				Header: "Message Reason Code",
				accessor: "MastercardFinancialPositionDetail.De025"
			},
			{
				Header: "Card Acceptor Business Code(MCC)",
				accessor: "MastercardFinancialPositionDetail.De026"
			},
			{
				Header: "Currency Exponents",
				accessor: "MastercardFinancialPositionDetail.Pds0148"
			},
			{
				Header: "Settlement Indicator",
				accessor: "MastercardFinancialPositionDetail.Pds0165"
			},
			{
				Header: "Reconciled File",
				accessor: "MastercardFinancialPositionDetail.Pds0300"
			},
			{
				Header: "Reconciled Member Activity",
				accessor: "MastercardFinancialPositionDetail.Pds0302"
			},
			{
				Header: "Reconciled Business Activity",
				accessor: "MastercardFinancialPositionDetail.Pds0358"
			},
			{
				Header: "Reconciled Wallet Range",
				accessor: "MastercardFinancialPositionDetail.Pds0370"
			},
			{
				Header: "Reconciled Transaction Function",
				accessor: "MastercardFinancialPositionDetail.Pds0372"
			},
			{
				Header: "Reconciled Processing Code",
				accessor: "MastercardFinancialPositionDetail.Pds0374"
			},
			{
				Header: "Member Reconciliation Indicator 1",
				accessor: "MastercardFinancialPositionDetail.Pds0375"
			},
			{
				Header: "Original/Reversal Totals Indicator",
				accessor: "MastercardFinancialPositionDetail.Pds0378"
			},
			{
				Header: "Debit/Credit Indicator(Debit)",
				accessor: "MastercardFinancialPositionDetail.Pds0380_1"
			},
			{
				Header: "Transaction Amount(Debit)",
				accessor: "MastercardFinancialPositionDetail.Pds0380_2"
			},
			{
				Header: "Debit/Credit Indicator(Credit)",
				accessor: "MastercardFinancialPositionDetail.Pds0381_1"
			},
			{
				Header: "Transaction Amount(Credit)",
				accessor: "MastercardFinancialPositionDetail.Pds0381_2"
			},
			{
				Header: "Debit/Credit Indicator(Net Transaction)",
				accessor: "MastercardFinancialPositionDetail.Pds0384_1"
			},
			{
				Header: "Transaction Amount(Net)",
				accessor: "MastercardFinancialPositionDetail.Pds0384_2"
			},
			{
				Header: "Debit/Credit Indicator(Reconcilation Debit)",
				accessor: "MastercardFinancialPositionDetail.Pds0390_1"
			},
			{
				Header: "Transaction Amount(Reconcilation Debit)",
				accessor: "MastercardFinancialPositionDetail.Pds0390_2"
			},
			{
				Header: "Debit/Credit Indicator(Reconcilation Credit)",
				accessor: "MastercardFinancialPositionDetail.Pds0391_1"
			},
			{
				Header: "Transaction Amount(Reconcilation Credit)",
				accessor: "MastercardFinancialPositionDetail.Pds0391_2"
			},
			{
				Header: "Fee Amounts in Reconciliation Currency(Debit)",
				accessor: "MastercardFinancialPositionDetail.Pds0392"
			},
			{
				Header: "Fee Amounts in Reconciliation Currency(Credit)",
				accessor: "MastercardFinancialPositionDetail.Pds0393"
			},
			{
				Header: "Debit/Credit Indicator in Reconciliation Currency",
				accessor: "MastercardFinancialPositionDetail.Pds0394_1"
			},
			{
				Header: "Net Amount in Reconcilation Currency",
				accessor: "MastercardFinancialPositionDetail.Pds0394_2"
			},
			{
				Header: "Net Fee in Reconciliation Currency Debit/Credit Indicator",
				accessor: "MastercardFinancialPositionDetail.Pds0395_1"
			},
			{
				Header: "Net Fee Amount in Reconcilation Currency",
				accessor: "MastercardFinancialPositionDetail.Pds0396_1"
			},
			{
				Header: " Net Total Debit/Credit Indicator in Reconciliation Currency",
				accessor: "MastercardFinancialPositionDetail.Pds0396_2"
			},
			{
				Header: "Net Total Amoount in Reconciliation Currency",
				accessor: "MastercardFinancialPositionDetail.Pds0400"
			},
			{
				Header: "Debit Transaction Number",
				accessor: "MastercardFinancialPositionDetail.Pds0400"
			},
			{
				Header: "Credit Transaction Number",
				accessor: "MastercardFinancialPositionDetail.Pds0401"
			},
			{
				Header: "Total Transaction Number",
				accessor: "MastercardFinancialPositionDetail.Pds0402"
			},
			{
				Header: "Transaction Currency Code",
				accessor: "MastercardFinancialPositionDetail.De049"
			},
			{
				Header: "Reconcilation Currency Code",
				accessor: "MastercardFinancialPositionDetail.De050"
			},
			{
				Header: "Additional Data 2",
				accessor: "MastercardFinancialPositionDetail.De062"
			},
			{
				Header: "Message Number",
				accessor: "MastercardFinancialPositionDetail.De071"
			},
			{
				Header: "Transaction Destination Institution ID Code",
				accessor: "MastercardFinancialPositionDetail.De093"
			},
			{
				Header: "Receiving Institution ID Code",
				accessor: "MastercardFinancialPositionDetail.De100"
			},
			{
				Header: "Additional Data 3",
				accessor: "MastercardFinancialPositionDetail.De123"
			},
			{
				Header: "Additional Data 4",
				accessor: "MastercardFinancialPositionDetail.De124"
			},
			{
				Header: "Additional Data 5",
				accessor: "MastercardFinancialPositionDetail.De125"
			},
			{
				Header: "Function Code",
				accessor: "MastercardFinancialPositionDetail.De024"
			},


		];
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Mastercard Financial Position Detail List");
		this.props.setAppCenterTitle("CLEARING AND SETTLEMENT");
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}

	HandleClear = () => {
		this.setState({ model: { ...this.initialModel }, isEdit: false, index: -1 });
	}

	HandleSearch = () => {
		const model = { ...this.state.model };
		this.setState({ isLoading: true });
		Proxy.POST
			(
				"/prepaidapi/v1.0/MastercardSettlement/SeearchSettlementPositionDetail",
				model,
				(responseData) => {
					this.setState({ isLoading: false });
					if (!responseData.IsSucceeded) {
						this.props.showMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					if (responseData.Item !== null) {
						this.setState({
							list: responseData.Item
						});
					}

				},
				(error) => {
					this.setState({ isLoading: false });
					this.props.showMessage("error", "Error", error);
				}
			);

	}

	render() {
		const { Disabled } = this.props;
		const { alert, model, list, isLoading, index, vModel } = this.state;


		return (
			<div>
				<LoadingComponent Show={isLoading} />
				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />


				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Panel">
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer>
												<GridItem xs={6}>
													<GenericDateInput
														Utc
														Name="FromDate"
														LabelText="From Date"
														Value={model.FromDate ? DateHelper.ToDateInputValue(model.FromDate) : ""}
														ValueChanged={this.HandleChange}
														Required
														IsInvalid={vModel.FromDate}
													/>
													<GenericDateInput
														Utc
														Name="ToDate"
														LabelText="To Date"
														Value={model.ToDate ? DateHelper.ToDateInputValue(model.ToDate) : ""}
														ValueChanged={this.HandleChange}
														MinDate={model.ToDate}
														Required
														IsInvalid={vModel.ToDate}
													/>
												</GridItem>
												<GridItem xs={6}>
													<GenericTextInput
														Name="FileName"
														LabelMd={4}
														LabelText="File Name"
														inputProps={this.maxLength26}
														IsText={true}
														Value={model.FileName}
														ValueChanged={this.HandleChange}
													/>
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={"Mastercard Financial POS List"} />
							</CardHeader>
							<CardBody>
								<GridContainer spacing={16}>
									<GridItem xs={12}>
										<GenericGrid
											Data={list}
											Columns={this.Columns}
											SelectedIndex={index}
											ShowPagination={true}
										/>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

MastercardFinancialPosDetail.propTypes = {
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool
};

export default withArtifex(MastercardFinancialPosDetail, {});