import { Proxy } from "core";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericAlert } from "views/Components/Generic";

class GenericPage extends Component {

	componentDidMount() {
		this.setState({
			IsLoading: false,
			Alert: null
		});
		this.Bind(this);
	}

	Bind(target) {
		var protoType = Reflect.getPrototypeOf(target);
		var methods = Reflect.ownKeys(protoType);
		methods.forEach(element => {
			const descriptor = Reflect.getOwnPropertyDescriptor(protoType, element);
			if (descriptor && typeof descriptor.value === "function") {
				target[element] = target[element].bind(target);
			}
		});

		this.HideAlert = this.HideAlert.bind(this);
		this.ShowLoadingSync = this.ShowLoadingSync.bind(this);
		this.HideLoadingSync = this.HideLoadingSync.bind(this);
		this.ShowGenericMessage = this.ShowGenericMessage.bind(this);
		this.ExecutePostRequest = this.ExecutePostRequest.bind(this);
		this.ExecuteDownloadRequest = this.ExecuteDownloadRequest.bind(this);
		this.ExecuteUploadRequest = this.ExecuteUploadRequest.bind(this);
		this.ExecuteGetRequest = this.ExecuteGetRequest.bind(this);
	}

	render() {
		return (<div></div>);
	}

	HideAlert(func = null) {
		this.setState({ Alert: null, IsLoading: false }, func);
	}

	ShowLoadingSync(func = null) {
		this.setState({ IsLoading: true }, func);
	}

	HideLoadingSync(func = null) {
		this.setState({ IsLoading: false }, func);
	}

	ShowGenericMessage(type, title, message, showCancel = false, onConfirm = null, onCancel = null, func = null) {
		var alert = (<GenericAlert Title={title} Message={message} Type={type} ShowCancel={showCancel} OnCancel={onCancel != null ? onCancel : this.HideAlert} OnConfirm={() => { this.HideAlert(onConfirm); }} />);
		this.setState({ Alert: alert }, func);
	}

	ExecutePostRequest(url, body, success, fail) {
		this.ShowLoadingSync(() => {
			Proxy.POST(url, body,
				responseData => {
					this.HideLoadingSync(() => {
						if (!responseData.IsSucceeded) {
							this.ShowGenericMessage("error", "Error", responseData.ErrorDescription);
							if (fail) fail(responseData);
							return;
						}
						if (success) success(responseData);
					});
				},
				error => {
					this.HideLoadingSync(() => {
						this.ShowGenericMessage("error", "Error", error);
						if (fail) fail(null);
					});
				});
		});
	}

	ExecuteDownloadRequest(url, name, extension, body, success, fail) {
		this.ShowLoadingSync(() => {
			Proxy.FileDownload(url, name, extension, body,
				responseData => {
					this.HideLoadingSync(() => {
						if (success) success(responseData);
					});
				},
				error => {
					this.HideLoadingSync(() => {
						this.ShowGenericMessage("error", "Error", error);
						if (fail) fail(null);
					});
				});
		});
	}

	ExecuteUploadRequest(url, formData, success, fail) {
		this.ShowLoadingSync(() => {
			Proxy.FileUpload(url, formData,
				responseData => {
					this.HideLoadingSync(() => {
						if (!responseData.IsSucceeded) {
							this.ShowGenericMessage("error", "Error", responseData.ErrorDescription);
							if (fail) fail(responseData);
							return;
						}
						if (success) success(responseData);
					});
				},
				error => {
					this.HideLoadingSync(() => {
						this.ShowGenericMessage("error", "Error", error);
						if (fail) fail(null);
					});
				});
		});
	}

	ExecuteGetRequest(url, success, fail) {
		this.ShowLoadingSync(() => {
			Proxy.GET(url,
				responseData => {
					this.HideLoadingSync(() => {
						if (!responseData.IsSucceeded) {
							this.ShowGenericMessage("error", "Error", responseData.ErrorDescription);
							if (fail) fail(responseData);
							return;
						}
						if (success) success(responseData);
					});
				},
				error => {
					this.HideLoadingSync(() => {
						this.ShowGenericMessage("error", "Error", error);
						if (fail) fail(null);
					});
				});
		});
	}
}

GenericPage.propTypes = {
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool,
	ValueChanged: PropTypes.func,
	Model: PropTypes.object
};

export default GenericPage;