import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import {
	primaryColor
} from "assets/jss/material-dashboard-pro-react.jsx";

const portalPageStyle = {
	...sweetAlertStyle,
	margin: "0px", paddingTop: "15px",
	tableHeader: {
		fontSize: "0.9em",
		lineHeight: "2",
		fontWeight: "400",
		textAlign: "center",
		paddingLeft: "10px",
		borderColor: primaryColor,
		borderStyle: "solid",
		borderWidth: "1px",
		borderRadiusInputTopLeft: 2,
		borderRadiusInputTopRight: 2,
		borderRadiusInputBottomLeft: 2,
		borderRadiusInputBottomRight: 2,
	},
	tableTextRight: {
		width: "150xp",
		textAlign: "left",
		background: "#ffffff",
		borderRadius: 2,
		borderRadiusInputTopLeft: 2,
		borderRadiusInputTopRight: 2,
		borderRadiusInputBottomLeft: 2,
		borderRadiusInputBottomRight: 2,
		color: "#2e333a",
		fontSize: "0.9em",
		lineHeight: "1.428571429",
		fontWeight: "400",
		borderColor: primaryColor,
		borderStyle: "solid",
		borderWidth: "1px",
		paddingTop: "1px",
		paddingBottom: "1px",
		paddingLeft: "10px",
		paddingRight: "30px",
		marginTop: "9px"
	},
	tableTextRightFix: {
		width: "150xp",
		textAlign: "left",
		background: "#ffffff",
		borderRadius: 2,
		borderRadiusInputTopLeft: 2,
		borderRadiusInputTopRight: 2,
		borderRadiusInputBottomLeft: 2,
		borderRadiusInputBottomRight: 2,
		color: "#2e333a",
		fontSize: "0.8em",
		lineHeight: "1.428571429",
		fontWeight: "400",
		borderColor: primaryColor,
		borderStyle: "solid",
		borderWidth: "1px",
		paddingTop: "1px",
		paddingBottom: "1px",
		paddingLeft: "10px",
		paddingRight: "10px"
	},
	tableTextLeft: {
		width: "150xp",
		textAlign: "left",
		color: "#2e333a",
		fontSize: "0.8em",
		lineHeight: "1.428571429",
		fontWeight: "400",
		paddingTop: "1px",
		paddingBottom: "1px",
		paddingLeft: "5px",
		paddingRight: "5px",
		marginTop: "9px"
	},
	tableTh: {
		marginTop: "5px"
	},
	tableOutStyle: {
		borderLeft: "1px solid " + primaryColor,
		borderBottom: "1px solid " + primaryColor,
		marginBottom: "5px"
	},
	cardBackGround: {
		background: "#efefef"
	},
	tableFix: {
		borderLeft: "1px solid " + primaryColor,
		borderBottom: "1px solid " + primaryColor,
		borderTop: "1px solid " + primaryColor,
		borderRight: "1px solid " + primaryColor,
	}

};

export default portalPageStyle;