import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes, { array, bool, func, number } from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import {
    GenericAlert,
    GenericDateInput,
    GenericDialog,
    GenericDialogActions,
    GenericGrid,
    GenericLabel,
    GenericNumberInput,
    GenericRadioInput,
    GenericSelectInput,
    GenericTitle,
} from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType } from "views/Constants/Constant";
import TabsComponent from "views/Components/TabsComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { DialogContent, DialogTitle } from "@material-ui/core";
import Select from "react-select";
import { EditIcon } from "core/Icons";
import { Proxy } from "core";
import GridButton from "views/Components/GridButton";
import Button from "components/CustomButtons/Button.jsx";

class eTransferSettlementFileByClient extends Component {
    constructor(props) {
        super(props);
        this.initTransactionDetailColumns();
        this.initExportColumns();

        this.directionDataSource = [
            { Key: "Incoming", Value: 16307 },
            { Key: "Outgoing", Value: 16308 },
        ];

        this.state = {
            model: {
					SettlementDate: null,
					SettlementDateFrom: null,
					SettlementDateTo: null,
					UniqueClientId: null,
					UniqueClientId2: null,
					ToDate: DateHelper.GetDate(),
					FromDate: DateHelper.GetDate(),
					SettlementStatusId: -1,
					TransferTypeId: -1,
					DirectionId: -1,
					TimezoneOffset: 0,
					FinancialInstitutionId: -1,
					TransactionStatusId: null,
					SettlementFromDate: null,
					SettlementToDate: null,
            },
            filterName: "Filter By",
            tabIndex: 0,
            settlementListBySettlementDate: [],
            settlementListBySettTransactionDate: [],
            isLoading: false,
            GrandTotalCreditBalance: undefined,
            GrandTotalCreditNumber: undefined,
            GrandTotalDebitBalance: undefined,
            GrandTotalDebitNumber: undefined,
            GrandTotalDifference: undefined,
            TotalSettledBalance: undefined,
            TotalCanceledBalance: undefined,
            TotalOutstandingBalance: undefined,
            TotalUnmatchedBalance: undefined,
            NetTotalBalanceChange: undefined,
            TotalUnSettledBalance: undefined,
            isTransactionDetailDialogOpen: false,
            loadAtStartup: false,
            gridTransactionDetailRefreshToken: false,
            selectedRow: undefined,
            headerName: undefined,
            clientNameColumnColor: "#000000",
            settlementStatusColumnColor: "#000000",
            totalCreditNumberColumnColor: "#000000",
            totalDebitNumberColumnColor: "#000000",
            isDownloadDialogOpen: false,
            exportReportType: "PDF",
            exportSelectedColumns: this.exportColumns,
        };

        this.transactionDetailGridRef = React.createRef();
    }
    componentDidMount() {
        this.props.setAppLeftTitle("E-Transfer Transactions by Client Activities");
        this.props.setAppCenterTitle("CLEARING & SETTLEMENT");
        this.setState({ loadAtStartup: true });
    }

    handleChange = (name, newValue) => {
        this.setState((state) => {
            var model = state.model;
            model[name] = newValue;
            return { model };
        });
    };

    initExportColumns() {
        var exportColumns = [];

        this.transactionDetailColumns.forEach((c) => {
            if (
                c.accessor != "Actions" &&
                c.accessor != "ED" &&
                (c.show == undefined || c.show)
            ) {
                console.log(c.accessor, c.Header, c.type);
                var exportColumn = {};
                exportColumn.value = c.accessor;
                exportColumn.title = c.Header;
                if (c.type) {
                    exportColumn.type = c.type;
                }

                exportColumns.push(exportColumn);
            }
        }, this);

        this.exportColumns = exportColumns;
    }

    GetData = async () => {
        if (this.IsValid()) {
            const { ExecuteApiPost } = this.props;
            const { model, tabIndex } = this.state;
            if (tabIndex === 1) {
                let requestModel = {
                    TransactionDateFrom: model.FromDate,
                    TransactionDateTo: model.ToDate,
                    uniqueClientId: model.UniqueClientId2,
                    settlementStatusId: model.SettlementStatusId,
                    TransferTypeId: model.TransferTypeId,
                    FinancialInstitutionId: model.FinancialInstitutionId,
                    DirectionId: model.DirectionId,
                    TimezoneOffset: model.TimezoneOffset,
                    SettlementDate: model.SettlementDate || null,
						  SettlementDateFrom: model.SettlementDateFrom || null,
						  SettlementDateTo: model.SettlementDateTo || null,
                    TransactionStatusId: model.TransactionStatusId,
                };

                const responseData = await ExecuteApiPost(
                    "/bankapi/v1.0/ClearingSettlement/GetEtransferTransactionsSummaryByClients",
                    requestModel
                );

                this.setState({
                    settlementListBySettTransactionDate:
                        responseData.EtransferTransactionsSummaryByClients,
                    GrandTotalCreditBalance: responseData.GrandTotalCreditBalance,
                    GrandTotalCreditNumber: responseData.GrandTotalCreditNumber,
                    GrandTotalDebitBalance: responseData.GrandTotalDebitBalance,
                    GrandTotalDebitNumber: responseData.GrandTotalDebitNumber,
                    GrandTotalDifference: responseData.GrandTotalDifference,
                    TotalSettledBalance: responseData.TotalSettledBalance,
                    TotalCanceledBalance: responseData.TotalCanceledBalance,
                    TotalOutstandingBalance: responseData.TotalOutstandingBalance,
                    TotalUnmatchedBalance: responseData.TotalUnmatchedBalance,
                    NetTotalBalanceChange: responseData.NetTotalBalanceChange,
                    TotalUnSettledBalance: responseData.TotalUnSettledBalance,
                });
            } else {
					let requestModel = {
						SettlementDate: model.SettlementDate,
						SettlementFromDate: model.SettlementFromDate,
						SettlementToDate: model.SettlementToDate,
						SettlementStatusId: model.SettlementStatusId,
                    uniqueClientId: model.UniqueClientId,
                };

                const responseData = await ExecuteApiPost(
                    "/bankapi/v1.0/ClearingSettlement/GetEtransferSettlementSummaryByClients",
                    requestModel
                );
                this.setState({
                    settlementListBySettlementDate:
                        responseData.EtransferSettlementSummaryByClients,
                    GrandTotalCreditBalance: responseData.GrandTotalCreditBalance,
                    GrandTotalCreditNumber: responseData.GrandTotalCreditNumber,
                    GrandTotalDebitBalance: responseData.GrandTotalDebitBalance,
                    GrandTotalDebitNumber: responseData.GrandTotalDebitNumber,
                    GrandTotalDifference: responseData.GrandTotalDifference,
                });
            }
        }
    };

    showMessage = (type, title, message) => {
        this.setState({
            alert: (
                <GenericAlert
                    Title={title}
                    Message={message}
                    Type={type}
                    OnConfirm={() => this.setState({ alert: "" })}
                />
            ),
        });
    };

    IsValid = () => {
        var vmodel = { ...this.state.model };
		  if (((!vmodel || !vmodel.SettlementFromDate) || (!vmodel || !vmodel.SettlementToDate) ) && this.state.tabIndex === 0) {
            this.props.showMessage(
                "warning",
                "Please fill required fields!",
					 "Settlement File From Date and  Settlement File To Date cannot be null"
            );
            return false;
        } else if (
			(typeof vmodel.SettlementFromDate != "object" &&
			this.state.tabIndex === 0) || (typeof vmodel.SettlementToDate != "object" &&
			this.state.tabIndex === 0)
        ) {
            this.props.showMessage(
                "warning",
                "Please fill required fields!",
					 "Settlement File From Date and  Settlement File To Date is invalid"
            );
            return false;
        }
        return true;
    };

    handleClear = () => {
        this.setState({
				model: { SettlementDate: DateHelper.GetDate(), SettlementDateFrom: DateHelper.GetDate(), SettlementDateTo: DateHelper.GetDate() },
            settlementListBySettlementDate: [],
            settlementListBySettTransactionDate: [],
            UniqueClientId: null,
            UniqueClientId2: undefined,
            FromDate: DateHelper.GetDate(),
            ToDate: DateHelper.GetDate(),
            GrandTotalCreditBalance: undefined,
            GrandTotalCreditNumber: undefined,
            GrandTotalDebitBalance: undefined,
            GrandTotalDebitNumber: undefined,
            GrandTotalDifference: undefined,
            TotalSettledBalance: undefined,
            TotalCanceledBalance: undefined,
            TotalOutstandingBalance: undefined,
            TotalUnmatchedBalance: undefined,
            NetTotalBalanceChange: undefined,
        });
    };
    handleChangeTab = (active) => {
        let filterName = "";
        if (active === 0) {
            filterName = "Filter By";
        }
        if (active === 1) {
            filterName = "Filter By";
        }
        this.setState({ tabIndex: active, filterName });
    };

    closeTransactionDetailDialog = () => {
        this.setState({
            transactionList: [],
            isTransactionDetailDialogOpen: false,
        });
    };

    GetTransactionList = async (selectedRow, headerName) => {
        const { ExecuteApiPost } = this.props;
        const { model } = this.state;
        let requestData = null;
        if (headerName === "ClientName" || headerName === "SettlementStatus") {
            requestData = {
                TransactionDateFrom: selectedRow.TransactionDateFrom,
                TransactionDateTo: selectedRow.TransactionDateTo,
                UniqueClientId: selectedRow.UniqueClientId,
                SettlementStatusId: selectedRow.SettlementStatusId,
                PageSize: 1_000_000,
                TransferTypeId: model.TransferTypeId,
                DirectionId: model.DirectionId,
                TimezoneOffset: model.TimezoneOffset,
                FinancialInstitutionId: model.FinancialInstitutionId,
                SettlementDate: model.SettlementDate,
					 SettlementDateFrom: model.SettlementDateFrom,
        			 SettlementDateTo: model.SettlementDateTo,
                TransactionStatusId: model.TransactionStatusId,
            };
        }
        if (headerName === "TotalCreditNumber") {
            requestData = {
                TransactionDateFrom: selectedRow.TransactionDateFrom,
                TransactionDateTo: selectedRow.TransactionDateTo,
                UniqueClientId: selectedRow.UniqueClientId,
                SettlementStatusId: selectedRow.SettlementStatusId,
                TransactionTypeId: selectedRow.CreditTransactionTypeId,
                PageSize: 1_000_000,
                TransferTypeId: model.TransferTypeId,
                DirectionId: model.DirectionId,
                TimezoneOffset: model.TimezoneOffset,
                FinancialInstitutionId: model.FinancialInstitutionId,
                SettlementDate: model.SettlementDate,
                TransactionStatusId: model.TransactionStatusId,
            };
        }
        if (headerName === "TotalDebitNumber") {
            requestData = {
                TransactionDateFrom: selectedRow.TransactionDateFrom,
                TransactionDateTo: selectedRow.TransactionDateTo,
                UniqueClientId: selectedRow.UniqueClientId,
                SettlementStatusId: selectedRow.SettlementStatusId,
                TransactionTypeId: selectedRow.DebitTransactionTypeId,
                PageSize: 1_000_000,
                TransferTypeId: model.TransferTypeId,
                DirectionId: model.DirectionId,
                TimezoneOffset: model.TimezoneOffset,
                FinancialInstitutionId: model.FinancialInstitutionId,
                SettlementDate: model.SettlementDate,
					 SettlementDateFrom: model.SettlementDateFrom,
        			 SettlementDateTo: model.SettlementDateTo,
                TransactionStatusId: model.TransactionStatusId,
            };
        }

        let responseData = await ExecuteApiPost(
            "/bankapi/v1.0/ClearingSettlement/GetEtransferTransactionsByClientActivitiesPaged",
            requestData
        );
        if (responseData != null) {
            this.setState({
                headerModel: {},
                transactionList: responseData.Data.length < 1 ? [] : responseData.Data,
                isLoading: false,
            });
        }
    };

    getTransactionDetailDialog = () => {
        const { isTransactionDetailDialogOpen, isLoading } = this.state;

        return (
            <GenericDialog
                open={isTransactionDetailDialogOpen && !isLoading}
                maxWidth="xl"
                fullWidth
            >
                <DialogContent>
                    <GridContainer spacing={16}>
                        <GenericGrid
                            Data={this.state.transactionList}
                            Columns={this.transactionDetailColumns}
                            PageSize={10}
                            ShowPagination={true}
                        />
                    </GridContainer>
                </DialogContent>
                <GenericDialogActions>
                    <GridContainer justify="flex-end">
                        <Button size="sm" onClick={this.closeTransactionDetailDialog}>
                            CANCEL
                        </Button>
                    </GridContainer>
                </GenericDialogActions>
            </GenericDialog>
        );
    };

    initTransactionDetailColumns() {
        this.transactionDetailColumns = [
            {
                Header: "Actions",
                accessor: "Actions",
                Cell: (row) => (
                    <GridButton Disabled={true} tooltip="Edit" Icon={EditIcon} />
                ),
                sortable: false,
                filterable: false,
                width: 70,
                show: true,
            },
            {
                Header: "Client Id",
                accessor: "UniqueClientId",
            },
            {
                Header: "Client Name",
                accessor: "ClientName",
                width: 100,
            },
            {
                Header: "Balance Date",
                accessor: "BalanceDate",
                type: GridColumnType.DateTimeUtc,
                width: 100,
            },
            {
                Header: "Settlement Date",
                accessor: "SettlementDate",
                type: GridColumnType.DateTimeUtc,
            },
            {
                Header: "Received/Sent",
                accessor: "ReceivedSent",
            },
            {
                Header: "Debit/Credit",
                accessor: "TransactionType",
            },
            {
                Header: "Amount",
                accessor: "Amount",
            },
            {
                Header: "Settlement Status",
                accessor: "SettlementStatus",
            },
            {
                Header: "Interac Ref Number",
                accessor: "InteracRefNumber",
            },
            {
                Header: "Reference Number",
                accessor: "TransactionReferenceNumber",
            },
            {
                Header: "Transaction Insert Date",
                accessor: "TransactionInsertDate",
                type: GridColumnType.DateTimeUtc,
            },
            {
                Header: "Transaction Status",
                accessor: "TransactionStatus"
            },
            {
                Header: "Priority Type",
                accessor: "PriorityType"
            },
            {
                Header: "Description",
                accessor: "Description",
                width: 600,
            },
        ];
    }

    getColumns = () => {
        const {
            tabIndex,
            clientNameColumnColor,
            settlementStatusColumnColor,
            totalCreditNumberColumnColor,
            totalDebitNumberColumnColor,
        } = this.state;

        if (tabIndex === 1) {
            return [
                {
                    Header: "Client ID",
                    accessor: "UniqueClientCode",
                },
                {
                    Header: "Client Name",
                    accessor: "ClientName",
                    Cell: (row) => {
                        return (
                            <div
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    cursor: "pointer",
                                    color: clientNameColumnColor,
                                }}
                                onClick={() => {
                                    this.openTransactionDetailDialog();
                                    this.GetTransactionList(row.original, "ClientName");
                                }}
                            >
                                {row.original.ClientName}
                            </div>
                        );
                    },
                },
                {
                    Header: "Settlement Status",
                    accessor: "SettlementStatus",
                    Cell: (row) => {
                        return (
                            <div
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    cursor: "pointer",
                                    color: settlementStatusColumnColor,
                                }}
                                onClick={() => {
                                    this.openTransactionDetailDialog();
                                    this.GetTransactionList(row.original, "SettlementStatus");
                                }}
                            >
                                {row.original.SettlementStatus}
                            </div>
                        );
                    },
                },
                {
                    Header: "Date From",
                    accessor: "TransactionDateFrom",
                    type: GridColumnType.DateUtc,
                },
                {
                    Header: "Date To",
                    accessor: "TransactionDateTo",
                    type: GridColumnType.DateUtc,
                },
                {
                    Header: "Total Number Of Credits",
                    accessor: "TotalCreditNumber",
                    Cell: (row) => {
                        return (
                            <div
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    cursor: "pointer",
                                    color: totalCreditNumberColumnColor,
                                }}
                                onClick={() => {
                                    this.openTransactionDetailDialog();
                                    this.GetTransactionList(row.original, "TotalCreditNumber");
                                }}
                            >
                                {row.original.TotalCreditNumber}
                            </div>
                        );
                    },
                },
                {
                    Header: "Credit Balance",
                    accessor: "CreditBalance",
                    type: GridColumnType.Money,
                    ColumnType: GridColumnType.Money,
                },
                {
                    Header: "Total Number Of Debits",
                    accessor: "TotalDebitNumber",
                    Cell: (row) => {
                        return (
                            <div
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    cursor: "pointer",
                                    color: totalDebitNumberColumnColor,
                                }}
                                onClick={() => {
                                    this.openTransactionDetailDialog();
                                    this.GetTransactionList(row.original, "TotalDebitNumber");
                                }}
                            >
                                {row.original.TotalDebitNumber}
                            </div>
                        );
                    },
                },
                {
                    Header: "Debit Balance",
                    accessor: "DebitBalance",
                    type: GridColumnType.Money,
                    ColumnType: GridColumnType.Money,
                },
                {
                    Header: "Balance Difference",
                    accessor: "Difference",
                    type: GridColumnType.Money,
                    ColumnType: GridColumnType.Money,
                },
            ];
        } else {
            return [
                {
                    Header: "Client ID",
                    accessor: "UniqueClientId",
                },
                {
                    Header: "Client Name",
                    accessor: "ClientName",
                },
                {
                    Header: "Settlement Status",
                    accessor: "SettlementStatus",
                },
                {
                    Header: "Total Number of Credits",
                    accessor: "TotalCreditNumber",
                },
                {
                    Header: "Credit Balance",
                    accessor: "CreditBalance",
                    type: GridColumnType.Money,
                    ColumnType: GridColumnType.Money,
                },
                {
                    Header: "Total Number of Debits",
                    accessor: "TotalDebitNumber",
                },
                {
                    Header: "Debit Balance",
                    accessor: "DebitBalance",
                    type: GridColumnType.Money,
                    ColumnType: GridColumnType.Money,
                },
                {
                    Header: "Balance Difference ",
                    accessor: "Difference",
                    type: GridColumnType.Money,
                    ColumnType: GridColumnType.Money,
                },
            ];
        }
    };

    setValueToModel = (name, value) => {
        const model = { ...this.state.model };
        model[name] = value;
        this.setState({ model });
    };

    onValidate = () => {
        const { model } = this.state;

        if (!model.FromDate || !model.ToDate) {
            this.props.showMessage(
                "warning",
                "Please fill required fields!",
                "Transaction Date is invalid"
            );
            return false;
        }

        return true;
    };

    filterBySettlementDate = () => {
        const { model } = this.state;

        return (
            <GridContainer>
                <GridItem xs={12}>
                    <GenericDateInput
                        Utc
                        Name="SettlementFromDate"
								LabelText="Settlement File From Date"
								Value={model.SettlementFromDate}
                        ValueChanged={this.handleChange}
                        IncludeTime={false}
                        MaxDate={DateHelper.GetDate()}
							Required
							/>
					 </GridItem>
					<GridItem xs={12}>
							<GenericDateInput
								Utc
								Name="SettlementToDate"
								LabelText="Settlement File To Date"
								Value={model.SettlementToDate}
								ValueChanged={this.handleChange}
								IncludeTime={false}
								MaxDate={DateHelper.GetDate()}
							Required
                    />
                </GridItem>
                <GridItem xs={12}>
                    <ParameterComponent
                        Name="SettlementStatusId"
                        LabelText="Settlement Status"
                        Value={model.SettlementStatusId}
                        ParameterCode="ETransferSettlementStatus"
                        ValueChanged={this.handleChange}
                        All={true}
                    />
                </GridItem>
                <GridItem xs={12}>
                    <GenericSelectInput
                        Name="UniqueClientId"
                        LabelText="Client"
                        Method="POST"
                        Url="/bankapi/v1.0/BankCustomer/Search"
                        Parameter={{}}
                        DataRoot="Item"
                        KeyValueMember="Id"
                        RenderItem={(d) => `${d.UniqueClientId} - ${d.Name}`}
                        Value={model.UniqueClientId || ""}
                        ValueChanged={this.handleChange}
                        All
                        CanClear
                    />
                </GridItem>
            </GridContainer>
        );
    };
    filterByTransactionDate = () => {
        const { model } = this.state;

        return (
            <GridContainer>
                <GridItem xs={12}>
                    <GenericDateInput
                        Name="FromDate"
                        LabelText="From Date"
                        Value={
                            model.FromDate ? Formatter.FormatDateUtc(model.FromDate) : ""
                        }
                        ValueChanged={this.handleChange}
                        MaxDate={model.ToDate || DateHelper.GetDate()}
                        Utc
                    />
                </GridItem>
                <GridItem xs={12}>
                    <GenericDateInput
                        Name="ToDate"
                        LabelText="To Date"
                        Value={model.ToDate ? Formatter.FormatDateUtc(model.ToDate) : ""}
                        ValueChanged={this.handleChange}
                        MinDate={model.FromDate}
                        Utc
                    />
                </GridItem>
                <GridItem xs={12}>
                    <ParameterComponent
                        Name="SettlementStatusId"
                        LabelText="Settlement Status"
                        Value={model.SettlementStatusId}
                        ParameterCode="ETransferSettlementStatus"
                        ValueChanged={this.handleChange}
                        All={true}
                    />
                </GridItem>
					 
					<GridItem xs={12}>
						<GenericDateInput
							Name="SettlementDateFrom"
							LabelText="Settlement Date From"
							Value={
							model.SettlementDateFrom
								? Formatter.FormatDateUtc(model.SettlementDateFrom)
								: ""
							}
							ValueChanged={this.handleChange}
							MaxDate={DateHelper.GetDate()}
							Utc
						/>
					</GridItem>
					<GridItem xs={12}>
						<GenericDateInput
							Name="SettlementDateTo"
							LabelText="Settlement Date To"
							Value={
							model.SettlementDateTo
								? Formatter.FormatDateUtc(model.SettlementDateTo)
								: ""
							}
							ValueChanged={this.handleChange}
							MaxDate={DateHelper.GetDate()}
							Utc
						/>
					</GridItem>
                <GridItem xs={12}>
                    <ParameterComponent
                        Name="TransactionStatusId"
                        Value={model.TransactionStatusId}
                        LabelText="Transaction Status"
                        GetEtransferTransactionsSummaryByClients
                        ParameterCode="BankTransactionStatus"
                        ValueChanged={this.handleChange}
                        All={true}
                    />
                </GridItem>
                <GridItem xs={12}>
                    <GenericSelectInput
                        Name="UniqueClientId2"
                        LabelText="Client"
                        Method="POST"
                        Url="/bankapi/v1.0/BankCustomer/Search"
                        Parameter={{}}
                        DataRoot="Item"
                        KeyValueMember="Id"
                        RenderItem={(d) => `${d.UniqueClientId} - ${d.Name}`}
                        Value={model.UniqueClientId2 || ""}
                        ValueChanged={this.handleChange}
                        All
                        CanClear
                    />
                </GridItem>
                <GridItem xs={12}>
                    <GenericSelectInput
                        Name="TransferTypeId"
                        LabelText="Transfer Type"
                        Method="GET"
                        Url="/bankapi/v1.0/Transaction/GetAllFinancialTransactionType"
                        DataRoot="Item"
                        KeyValueMember="Id"
                        TextValueMember="ParameterValue6"
                        Value={model.TransferTypeId}
                        ValueChanged={this.setValueToModel}
                        All
                        CanClear
                    />
                </GridItem>
                <GridItem xs={12}>
                    <GenericSelectInput
                        IsStatic
                        Name="DirectionId"
                        LabelText="Fund Direction"
                        StaticData={this.directionDataSource}
                        KeyValueMember="Value"
                        TextValueMember="Key"
                        Value={model.DirectionId}
                        ValueChanged={this.setValueToModel}
                        All
                        CanClear
                    />
                </GridItem>
                <GridItem xs={12}>
                    <GenericSelectInput
                        Name="FinancialInstitutionId"
                        LabelText="Financial Institution"
                        Method="GET"
                        Url="/bankapi/v1.0/financialinstitution/getall"
                        DataRoot="Item"
                        KeyValueMember="FinancialInstitutionId"
                        TextValueMember="Description"
                        Value={model.FinancialInstitutionId}
                        ValueChanged={this.setValueToModel}
                        All
                        CanClear
                    />
                </GridItem>
            </GridContainer>
        );
    };

    getTabList = () => {
        var tabList = [
            {
                tabButton: "Settlement File Date",
                tabContent: this.filterBySettlementDate(),
            },
            {
                tabButton: "Transaction Date",
                tabContent: this.filterByTransactionDate(),
            },
        ];

        return tabList;
    };
    getTotalDetailPanel = () => {
        const {
            GrandTotalCreditBalance,
            GrandTotalCreditNumber,
            GrandTotalDebitBalance,
            GrandTotalDebitNumber,
            GrandTotalDifference,
            TotalSettledBalance,
            TotalCanceledBalance,
            TotalOutstandingBalance,
            TotalUnmatchedBalance,
            NetTotalBalanceChange,
            TotalUnSettledBalance,
            tabIndex,
        } = this.state;

        if (tabIndex === 1) {
            return (
                <GridItem xs={7}>
                    <Card>
                        <CardHeader>
                            <GenericTitle text={"Date Range Results"} />
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={4}>
                                    <GenericNumberInput
                                        Name="TotalSettledBalance"
                                        LabelText="Total Settled"
                                        LabelMd={6}
                                        Value={TotalSettledBalance}
                                        Disabled={true}
                                        AllowNegative
                                        DecimalScale={2}
                                        Prefix="$"
                                    />
                                </GridItem>
                                <GridItem xs={4}>
                                    <GenericNumberInput
                                        Name="TotalUnmatchedBalance"
                                        LabelText="Total Unmatched"
                                        LabelMd={6}
                                        Value={TotalUnmatchedBalance}
                                        AllowNegative
                                        Disabled={true}
                                        DecimalScale={2}
                                        Prefix="$"
                                    />
                                </GridItem>
                                <GridItem xs={4}>
                                    <GenericNumberInput
                                        Name="NetTotalBalanceChange"
                                        LabelText="Net Total Change"
                                        LabelMd={6}
                                        Value={NetTotalBalanceChange}
                                        AllowNegative
                                        Disabled={true}
                                        DecimalScale={2}
                                        Prefix="$"
                                    />
                                </GridItem>
                                <GridItem xs={4}>
                                    <GenericNumberInput
                                        Name="TotalUnSettledBalance"
                                        LabelText="Total Unsettled"
                                        LabelMd={6}
                                        Value={TotalUnSettledBalance}
                                        AllowNegative
                                        Disabled={true}
                                        DecimalScale={2}
                                        Prefix="$"
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader>
                            <GenericTitle text={"Query Results"} />
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={6}>
                                    <GenericNumberInput
                                        Name="GrandTotalCreditNumber"
                                        LabelText="Total Number of Credits"
                                        LabelMd={6}
                                        Value={GrandTotalCreditNumber}
                                        Disabled={true}
                                        DecimalScale={2}
                                        AllowNegative
                                    />
                                    <GenericNumberInput
                                        Name="GrandTotalCreditBalance"
                                        LabelText="Total Credit Balance"
                                        LabelMd={6}
                                        Value={GrandTotalCreditBalance}
                                        Disabled={true}
                                        DecimalScale={2}
                                        Prefix="$"
                                        AllowNegative
                                    />
                                    <GenericNumberInput
                                        Name="GrandTotalDifference"
                                        LabelText="Total Balance Difference"
                                        LabelMd={6}
                                        Value={GrandTotalDifference}
                                        Disabled={true}
                                        DecimalScale={2}
                                        Prefix="$"
                                        AllowNegative
                                    />
                                </GridItem>
                                <GridItem xs={6}>
                                    <GenericNumberInput
                                        Name="GrandTotalDebitNumber"
                                        LabelText="Total Number of Debits"
                                        Value={GrandTotalDebitNumber}
                                        Disabled={true}
                                        DecimalScale={2}
                                        AllowNegative
                                    />
                                    <GenericNumberInput
                                        Name="GrandTotalDebitBalance"
                                        LabelText="Total Debit Balance"
                                        Value={GrandTotalDebitBalance}
                                        Disabled={true}
                                        DecimalScale={2}
                                        Prefix="$"
                                        AllowNegative
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            );
        }
        return (
            <GridItem xs={7}>
                <Card>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={6}>
                                <GenericNumberInput
                                    Name="GrandTotalCreditNumber"
                                    LabelText="Total Number of Credits"
                                    LabelMd={6}
                                    Value={GrandTotalCreditNumber}
                                    Disabled={true}
                                    DecimalScale={0}
                                />
                                <GenericNumberInput
                                    Name="GrandTotalCreditBalance"
                                    LabelText="Total Credit Balance"
                                    LabelMd={6}
                                    Value={GrandTotalCreditBalance}
                                    Disabled={true}
                                    Prefix="$"
                                />
                                <GenericNumberInput
                                    Name="GrandTotalDifference"
                                    LabelText="Total Balance Difference"
                                    LabelMd={6}
                                    Value={GrandTotalDifference}
                                    Disabled={true}
                                    Prefix="$"
                                />
                            </GridItem>
                            <GridItem xs={6}>
                                <GenericNumberInput
                                    Name="GrandTotalDebitNumber"
                                    LabelText="Total Number of Debits"
                                    Value={GrandTotalDebitNumber}
                                    Disabled={true}
                                    DecimalScale={0}
                                />
                                <GenericNumberInput
                                    Name="GrandTotalDebitBalance"
                                    LabelText="Total Debit Balance"
                                    Value={GrandTotalDebitBalance}
                                    Disabled={true}
                                    Prefix="$"
                                />
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
        );
    };

    openTransactionDetailDialog = (row, headerName) => {
        this.setState({
            isLoading: true,
            isTransactionDetailDialogOpen: true,
            headerName,
            selectedRow: row,
            gridTransactionDetailRefreshToken:
                !this.state.gridTransactionDetailRefreshToken,
        });
    };

    getDataSource = () => {
        const {
            settlementListBySettlementDate,
            settlementListBySettTransactionDate,
            tabIndex,
        } = this.state;

        if (tabIndex === 1) {
            return settlementListBySettTransactionDate;
        } else {
            return settlementListBySettlementDate;
        }
    };

    isExportRequestValid = () => {
        const { model } = this.state;

        if (model.FromDate == null || model.FromDate == undefined) {
            this.showMessage(
                "warning",
                "Please select required field !",
                "Select a Settlement File Date !"
            );
            return false;
        }

        return true;
    };

    showLoading() {
        this.setState({ isLoading: true });
    }

    hideLoading() {
        this.setState({ isLoading: false });
    }

    onExport = () => {
        if (this.isExportRequestValid()) {
            const { exportReportType } = this.state;
            const { SettlementDate, FromDate, UniqueClientId2, ...model2 } =
                this.state.model;

            const exportModel = {
                ...model2,
                TransactionDateTo: this.state.model.ToDate,
                UniqueClientId: this.state.model.UniqueClientId2,
                TransactionDateFrom: this.state.model.FromDate,
                SettlementDate: this.state.model.SettlementDate,
                jsonFile: {},
            };

            var exportColumns = this.getSelectedExportColumns() ?? [];

            exportModel.jsonFile = {
                colmns: exportColumns,
                format: exportReportType,
                title: "E-Transfer Transactions by Client Activities",
            };

            this.showLoading();

            Proxy.DownloadGeneratedFile(
                "/bankapi/v1.0/ClearingSettlement/GetEtransferTransactionsByClientActivitiesExportDownload",
                exportModel,
                (responseData) => {
                    this.hideLoading();

                    if (!responseData.IsSucceeded) {
                        this.showMessage("error", "Error", responseData.ErrorDescription);
                        return;
                    }
                },
                (errorMessage) => {
                    this.hideLoading();
                    this.showMessage("error", "Error", errorMessage);
                },
                this.showMessage
            );
        }
    };

    openExportDialog = () => {
        this.setState({
            isDownloadDialogOpen: true,
        });
    };

    closeExportDialog = () => {
        this.setState({
            isDownloadDialogOpen: false,
        });
    };

    setExportSelection = (value) => {
        console.log(value);
        this.setState({ exportSelectedColumns: value });
    };

    getSelectedExportColumns = () => {
        const { exportSelectedColumns } = this.state;

        var columns = {};

        exportSelectedColumns.forEach((c) => {
            columns[c.value] = {
                value: c.value,
                title: c.title,
                columntype: c.columntype,
                type: c.type,
            };
        });

        var result = [];
        result.push(columns);

        return result;
    };

    setValueToState = (name, value) => {
        this.setState({ [name]: value });
    };

    render() {
        const {
            isLoading,
            tabIndex,
            filterName,
            isDownloadDialogOpen,
            exportReportType,
            exportSelectedColumns,
        } = this.state;

        return (
            <div>
                {this.state.alert}
                <ButtonToolbar
                    ButtonList={[
                        { Code: "Search", OnClick: this.GetData, Disabled: false },
                        { Code: "Export", OnClick: this.openExportDialog, Disabled: false },
                    ]}
                    menuId={this.props.menuId}
                    ApprovalData={this.props.ApprovalData}
                    After={this.props.After}
                />
                <LoadingComponent Show={isLoading} />
                <GridContainer>
                    <GridItem xs={5}>
                        <Card style={{ height: "95%" }}>
                            <CardHeader>
                                <GenericTitle text={filterName} />
                            </CardHeader>
                            <CardBody>
                                <TabsComponent
                                    TabIndex={tabIndex}
                                    tabIndexChanged={(e) => this.handleChangeTab(e)}
                                    tabList={this.getTabList()}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                    {this.getTotalDetailPanel()}
                </GridContainer>
                <Card className="no-radius">
                    <CardBody>
                        <CardHeader>
                            <GenericTitle text={"Settlement Details"} />
                        </CardHeader>
                        <GridItem xs={12}>
                            <GridItem>
                                <GenericGrid
                                    Data={this.getDataSource()}
                                    Columns={this.getColumns()}
                                    SelectedIndex={this.state.rowIndex}
                                />
                            </GridItem>
                        </GridItem>
                    </CardBody>
                </Card>
                {this.getTransactionDetailDialog()}
                <GenericDialog open={isDownloadDialogOpen} fullWidth={true}>
                    <DialogTitle>
                        <GenericLabel
                            Text="Select Report Type"
                            FontSize="20px"
                            Bold={true}
                        />
                    </DialogTitle>
                    <DialogContent style={{ height: 300 }}>
                        <GenericRadioInput
                            Name="exportReportType"
                            LabelText="Report Type"
                            IsStatic={true}
                            StaticData={[
                                { Value: "PDF", Text: "PDF" },
                                { Value: "CSV", Text: "CSV" },
                                { Value: "EXCEL", Text: "EXCEL" },
                            ]}
                            KeyValueMember="Value"
                            TextValueMember="Text"
                            Value={exportReportType}
                            ValueChanged={this.setValueToState}
                        />
                        <GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
                        <GridItem style={{ marginTop: 20 }}>
                            <Select
                                value={exportSelectedColumns}
                                onChange={this.setExportSelection}
                                isMulti
                                name="exportSelectedColumns"
                                getOptionLabel={(d) => d.title}
                                options={this.exportColumns}
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />
                        </GridItem>
                    </DialogContent>
                    <GenericDialogActions>
                        <Button size="sm" onClick={this.onExport}>
                            Download
                        </Button>
                        <Button size="sm" onClick={this.closeExportDialog}>
                            Cancel
                        </Button>
                    </GenericDialogActions>
                </GenericDialog>
            </div>
        );
    }
}

eTransferSettlementFileByClient.propTypes = {
    classes: PropTypes.object,
    handleOperationType: func,
    SelectedRowChange: func,
    showQuestionMessage: func,
    setAppLeftTitle: func,
    setAppCenterTitle: func,
    Disabled: bool,
    menuId: number,
    ApprovalData: array,
    After: func,
};

export default withArtifex(eTransferSettlementFileByClient, {});
