import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import { DeleteIcon, EditIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import ReactTable from "react-table";
import { GenericAlert, GenericCheckInput, GenericDateInput, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import SearchComponent from "./CommissionProfileSearchComonent";
import { GridColumnType } from "views/Constants/Constant";

class ReferralFee extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			model: {},
			modelArray: [],
			headerModel: {},
			modelStandart: {}
		};
		this.handleChange = this.handleChange.bind(this);
		this.ClearData = this.ClearData.bind(this);
		this.Submit = this.Submit.bind(this);
		this.Delete = this.Delete.bind(this);
		this.DeleteRecord = this.DeleteRecord.bind(this);
		this.UpdateRecord = this.UpdateRecord.bind(this);
		this.FetchData = this.FetchData.bind(this);
		this.errorDefinitionCallback = this.errorDefinitionCallback.bind(this);
		this.handleResponse = this.handleResponse.bind(this);
		this.handleUpdate = this.handleUpdate.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleResponseStandartRate = this.handleResponseStandartRate.bind(this);
		this.SelectedRowChange = this.SelectedRowChange.bind(this);

		this.ShowMessage = this.ShowMessage.bind(this);
		this.hideAlert = this.hideAlert.bind(this);
		this.showDeleteMessage = this.showDeleteMessage.bind(this);
		this.showConfirmMessage = this.showConfirmMessage.bind(this);

		this.handleChangeHeader = this.handleChangeHeader.bind(this);
		this.selectProfileSearchCallback = this.selectProfileSearchCallback.bind(this);
	}
	componentDidMount() {
		//this.props.hideSidebar();
		this.props.setAppLeftTitle("Referral Fee Profiles");
		this.props.setAppCenterTitle("FEE PROFILES");

		Proxy.GET(
			"/bankapi/v1.0/CommissionStandardRate/GetFirst",
			this.handleResponseStandartRate,
			this.errorDefinitionCallback
		);
	}

	selectProfileSearchCallback(responseModel) {
		const model = { ...this.state.headerModel };
		if (responseModel != undefined) {
			model.ProfileId = responseModel.ProfileId;
			model.ProfileName = responseModel.ProfileName;
			model.Id = responseModel.Id;
			model.ValidFrom = responseModel.ValidFrom;
			model.ValidTo = responseModel.ValidTo;
			this.setState({ headerModel: model }, () => {
				console.log("selectProfileSearchCallback ", this.state.headerModel);
				this.FetchData();
			});
		}
	}
	SelectedRowChange(e, index) {
		const temp = this.state.modelArray[index];
		if (temp.UniqueClient != null) {
			temp.UniqueClientId = temp.UniqueClient.UniqueClientId;
		}
		this.setState({ model: temp }, () => console.log("SelectedRowChange", this.state));
	}
	DeleteRecord(obj) {
		const header = { ...this.state.headerModel };
		if (header.Id != 0 && header.Id != undefined && obj.ProfileId != 0 && obj.Id != undefined && obj.Id != 0) {
			Proxy.GET(
				"/bankapi/v1.0/CommissionProfileDetail/Delete/Id?Id=" + obj.Id,
				this.handleDelete,
				this.errorDefinitionCallback
			);
		} else {
			this.ShowMessage("info", "Invalid", "Please select profile first !");
		}

	}
	UpdateRecord() {
		const header = { ...this.state.headerModel };
		if (header.Id != 0 && header.Id != undefined) {
			const temp = { ...this.state.model };
			temp.ProfileId = this.state.headerModel.Id;
			if (temp.UniqueClient != undefined && temp.UniqueClient.UniqueClientId != undefined) {
				temp.UniqueClientId = this.state.model.UniqueClient.UniqueClientId;
			}
			Proxy.POST(
				"/bankapi/v1.0/CommissionProfileDetail/InsertOrUpdate",
				temp,
				this.handleUpdate,
				this.errorDefinitionCallback
			);
		} else {
			this.ShowMessage("info", "Invalid", "Please select profile first !");
		}
	}
	FetchData() {
		const header = { ...this.state.headerModel };
		if (header.Id != 0 && header.Id != undefined) {
			Proxy.GET(
				"/bankapi/v1.0/CommissionProfileDetail/GetById/Id?Id=" + header.Id,
				this.handleResponse,
				this.errorDefinitionCallback
			);
		}
	}
	handleResponse(responseData) {
		console.log("getall", responseData.IsSucceeded + "");
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error !", responseData.ErrorDescription);
			return;
		} else {
			this.setState({ modelArray: responseData.Item || {} });
		}
	}
	handleUpdate(responseData) {
		console.log("update", responseData.IsSucceeded + "");
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error !", responseData.ErrorDescription);
			return;
		} else {
			this.setState({ model: responseData.Item || {} });
			this.ShowMessage("success", "Success", "Changes Saved Sucesfully.");
			this.FetchData();
		}
	}
	handleResponseStandartRate(responseData) {
		console.log("standart rate", responseData.IsSucceeded + "");
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error !", responseData.ErrorDescription);
			return;
		} else {
			this.setState({ modelStandart: responseData.Item || {} });
		}
	}

	handleDelete(responseData) {
		console.log("update", responseData.IsSucceeded + "");
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error !", responseData.ErrorDescription);
			return;
		} else {
			this.ShowMessage("success", "Success", "Deleted !");
			this.FetchData();
			this.ClearData();
		}
	}
	errorDefinitionCallback(error) {
		this.ShowMessage("error", "Error !", "An error occurred while requesting data" + error);
	}
	handleChange(name, newValue) {
		const model = { ...this.state.model };
		model[name] = newValue;
		if (name == "UseStandartRate" && newValue == true) {
			const standart = { ...this.state.modelStandart };
			model.FirstMonthCommissionRate = standart.FirstMonthCommissionRate;
			model.FollowingCommissionRate = standart.FollowingCommissionRate;
		}
		this.setState({ model: model }, () => console.log("model ", this.state));
	}
	handleChangeHeader(name, newValue) {
		const model = { ...this.state.headerModel };
		model[name] = newValue;
		this.setState({ headerModel: model }, () => console.log("headerModel ", this.state));
	}
	ClearData() {
		var temp = { Id: 0 };
		this.setState({ model: temp, headerModel: temp }, () => console.log("ClearData ", this.state));
	}
	Delete(obj) {
		this.showDeleteMessage("warning", "Delete", "Delete This Profile Record !", obj);
	}
	Submit() {
		this.showConfirmMessage("info", "Confirm", "Do you want to save changes!");
	}
	ShowMessage(type, title, message) {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
	}
	showDeleteMessage(type, title, message, obj) {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.DeleteRecord(obj)} />
		});
	}
	showConfirmMessage(type, title, message) {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.UpdateRecord()} />
		});
	}
	hideAlert() {
		this.setState({
			alert: null
		});
	}

	render() {
		const { model, headerModel, modelStandart, modelArray } = this.state;
		var data = modelArray.map(d => {
			return {
				Id: d.Id,
				UniqueClientId: d.UniqueClient == undefined ? "" : d.UniqueClient.UniqueClientId || "",
				AgreementType: d.AgreementType == undefined ? "" : d.AgreementType.Description || "",
				AgreementTypeId: d.AgreementTypeId,
				ProfileId: d.ProfileId,
				UseStandartRate: d.UseStandartRate,
				AgreementSignDate: d.AgreementSignDate,
				FirstMonthCommissionRate: d.FirstMonthCommissionRate,
				FollowingCommissionRate: d.FollowingCommissionRate,
				actions: (
					<div>
						<Button justIcon simple size="sm" tooltip="Edit Profile" onClick={() => this.setState({ model: d })}>
							<img src={EditIcon} alt="Edit" />
						</Button>
						<Button justIcon simple size="sm" tooltip="Delete Profile" onClick={() => this.Delete(d)}>
							<img src={DeleteIcon} alt="Delete" />
						</Button>
					</div>
				)
			};
		});
		return (
			<div>
				{this.state.alert}
				<Card className="no-radius">
					<CardBody>
						<GridContainer spacing={16}>
							<GridItem xs={3}>
								<GenericTextInput Name="ProfileId" LabelText="Profile ID" Value={headerModel.ProfileId || ""} />
							</GridItem>
							<GridItem xs={3}>
								<SearchComponent nameTextSearchProp="ProfileName" nameIdSearchProp="ProfileId" ProfileId={model.ProfileId} ProfileName={headerModel.ProfileName} fillCallback={this.selectProfileSearchCallback} handleChange={this.handleChangeHeader} />
							</GridItem>
							<GridItem xs={6}>
								<GridContainer justify="flex-end">
									<GridItem>
										<Button size="sm" onClick={() => this.Delete()}> Delete </Button>
										<Button size="sm" onClick={() => this.ClearData()}>	Clear	</Button>
										<Button size="sm" onClick={() => this.Submit()}> Save </Button>
									</GridItem>
								</GridContainer>
							</GridItem>
							<GridItem>
								<GenericCheckInput Name="UseStandartRate" LabelText="Use Standart Rate" Value={model.UseStandartRate} ValueChanged={this.handleChange} />
								<GenericCheckInput Name="DefineCustomizedDate" LabelText="Define Customized Date" Value={headerModel.DefineCustomizedDate} ValueChanged={this.handleChangeHeader} />
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>

				<Card className="no-radius">
					<CardBody>
						<p style={{ paddingTop: "20px" }}><b>Sales Person Referral Commission</b></p>
						<GridContainer spacing={16}>
							<GridItem xs={4}>
								<GenericSelectInput Name="AgreementTypeId" LabelText="Agreement Type" Value={model.AgreementTypeId || ""} ValueChanged={this.handleChange} KeyValueMember="Id" TextValueMember="Description" Method="GET" Url="/bankapi/v1.0/Bank/ValidBankPrograms" DataRoot="Item" />
								<GenericNumberInput ThousandSeparator="" Name="UniqueClientId" LabelText="Unique Client ID" Value={model.UniqueClientId || ""} ValueChanged={this.handleChange} />
								<GenericDateInput Name="AgreementSignDate" LabelText="Agreement Sign Date" Value={DateHelper.ToDateInputValue(model.AgreementSignDate) || ""} ValueChanged={this.handleChange} />
							</GridItem>
							<GridItem xs={2}></GridItem>
							<GridItem xs={6}>
								<GridContainer spacing={16}>
									<GridItem xs={6}> <p style={{ paddingTop: "20px" }}><b></b></p></GridItem>
									<GridItem xs={3}>
										<p style={{ paddingTop: "20px" }}><b>Standart Rate </b></p>
									</GridItem>
									<GridItem xs={3}>
										<p style={{ paddingTop: "20px" }}><b>Customized Rate</b></p>
									</GridItem>
								</GridContainer>
								<GridContainer spacing={16}>
									<GridItem xs={6}> <p style={{ paddingTop: "20px" }}>Commission Rate (First 1 Month)</p></GridItem>
									<GridItem xs={3}>
										<GenericNumberInput Name="" LabelText="" Value={modelStandart.FirstMonthCommissionRate} Disabled={true} />
									</GridItem>
									<GridItem xs={3}>
										<GenericNumberInput Name="FirstMonthCommissionRate" LabelText="" Value={model.FirstMonthCommissionRate} Disabled={model.UseStandartRate} ValueChanged={this.handleChange} />
									</GridItem>
								</GridContainer>
								<GridContainer spacing={16}>
									<GridItem xs={6}> <p style={{ paddingTop: "20px" }}>Commission Rate (Following Months)</p></GridItem>
									<GridItem xs={3}>
										<GenericNumberInput Name="" LabelText="" Value={modelStandart.FollowingCommissionRate} Disabled={true} />
									</GridItem>
									<GridItem xs={3}>
										<GenericNumberInput Name="FollowingCommissionRate" LabelText="" Value={model.FollowingCommissionRate} Disabled={model.UseStandartRate} ValueChanged={this.handleChange} />
									</GridItem>
								</GridContainer>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>

				<Card className="no-radius">
					<CardBody>
						<ReactTable
							data={data}
							filterable={false}
							columns={[
								{
									Header: "Agreement Type",
									accessor: "AgreementType"
								},
								{
									Header: "Unique Client ID",
									accessor: "UniqueClientId"
								},
								{
									Header: "Agreement Sign Date",
									accessor: "AgreementSignDate",
									type: GridColumnType.Date
								},
								{
									Header: "Use Standart Rate",
									accessor: "UseStandartRate",
									Cell: row => (
										<div>{row.value == true ? "Yes" : "No"}</div>
									)
								},
								{
									Header: "First Month Commission Rate",
									accessor: "FirstMonthCommissionRate"
								},
								{
									Header: "Following Commission Rate",
									accessor: "FollowingCommissionRate"
								},
								{
									Header: "Actions",
									accessor: "actions",
									sortable: false,
									filterable: false
								}
							]}
							defaultPageSize={5}
							showPaginationTop={false}
							showPaginationBottom={data.length > 0 ? true : false}
							minRows={2}
							getTrProps={(state, rowInfo) => {
								if (rowInfo && rowInfo.row) {
									return {
										onClick: e => {
											this.setState({ selected: rowInfo.index });
											this.SelectedRowChange(e, rowInfo.index);
										},
										style: {
											background:
												rowInfo.index === this.state.selected
													? "rgba(0, 0, 0, 0.1)"
													: ""
										}
									};
								} else {
									return {};
								}
							}}
						/>
					</CardBody>
				</Card>
			</div>
		);
	}
}

ReferralFee.propTypes = {
	classes: PropTypes.object,
	setAppCenterTitle: PropTypes.func,
	setAppLeftTitle: PropTypes.func
};

export default withArtifex(ReferralFee, {});