import withStyles from "@material-ui/core/styles/withStyles";
import { primaryColor } from "assets/jss/material-dashboard-pro-react.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React from "react";
import ReactTable from "react-table";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericCheckInput, GenericDateInput, GenericExpansionPanel, GenericSelectInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType } from "views/Constants/Constant";

const styles = ({
	grayRatingStyle: {
		background: "gray",
		padding: "10px",
		border: "1px solid black",
		textAlign: "center"
	},
	orangeRatingStyle: {
		background: primaryColor,
		padding: "10px",
		color: "white",
		borderRadius: "5px"
	}
});

class VolumeAnalysis extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {};

		this.state = {
			transactionList: [],
			revenueList: [],
			model: this.initialModel,
			vModel: {},
			isLoading: false,
		};
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Volume Analysis");
		this.props.setAppCenterTitle("REPORTS");
	}

	HandleChange = (name, value, data) => {
		const model = { ...this.state.model };
		model[name] = value;
		if (name == "Year1")
			if (data) {
				model.Year1Desc = data.ParameterDesc;
			}
			else {
				delete model.Year1;
				delete model.Y1Quarter1; delete model.Y1Quarter2; delete model.Y1Quarter3; delete model.Y1Quarter4;
			}
		if (name == "Year2")
			if (data) {
				model.Year2Desc = data.ParameterDesc;
			}
			else {
				delete model.Year2;
				delete model.Y2Quarter1; delete model.Y2Quarter2; delete model.Y2Quarter3; delete model.Y2Quarter4;
			}
		if (name == "Year3")
			if (data) {
				model.Year3Desc = data.ParameterDesc;
			}
			else {
				delete model.Year3;
				delete model.Y3Quarter1; delete model.Y3Quarter2; delete model.Y3Quarter3; delete model.Y3Quarter4;
			}
		this.setState({ model });

		if (name == "QuarterFilter") {
			delete model.FromDate;
			delete model.ToDate;
		}
		if (name == "DateFilter") {
			delete model.Year1;
			delete model.Year2;
			delete model.Year3;
			delete model.Y1Quarter1; delete model.Y1Quarter2; delete model.Y1Quarter3; delete model.Y1Quarter4;
			delete model.Y2Quarter1; delete model.Y2Quarter2; delete model.Y2Quarter3; delete model.Y2Quarter4;
			delete model.Y3Quarter1; delete model.Y3Quarter2; delete model.Y3Quarter3; delete model.Y3Quarter4;
		}
	}

	HandleBooleanChange = (name, value) => {
		const model = { ...this.state.model };
		model[name] = JSON.parse(value);
		this.setState({ model });
	}

	HandleClear = () => {
		this.setState({ model: this.initialModel, selected: null });
	}

	HandleGetList = () => {
		if (!this.Validate())
			return;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/Reporting/SearchVolumeAnalysis",
			this.state.model,
			this.SuccessListCallback,
			this.ErrorListCallback
		);
	}

	SuccessListCallback = (responseData) => {
		this.setState({ isLoading: false });
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error", responseData.ErrorDescription);
			return;
		}
		if (responseData.Item !== null) {
			const { model } = this.state;
			model.BeginDate = responseData.Item.BeginDate;
			model.EndDate = responseData.Item.EndDate;
			this.setState({
				model,
				transactionList: responseData.Item.TransactionList,
				revenueList: responseData.Item.RevenueList
			});
		}
	}

	ErrorListCallback = (error) => {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", error);
	}

	GetElementValue = (obj, path) => {
		if (obj == null || path == null)
			return "";
		return [obj].concat(path.split(".")).reduce((c, n) => c && c[n]);
	}

	GetElementAmountValue = (obj, path) => {
		if (obj == null || path == null)
			return "";
		return [obj].concat(path.split(".")).reduce((c, n) => {
			if (c) {
				if (typeof c[n] === "number")
					return `$${c[n].toFixed(2)}`;
				return c[n];
			}
		});
	}

	Print = () => {
		if (this.state.transactionList.length > 0)
			this.PrintTransactionList();
	}

	PrintTransactionList = () => {
		this.setState({ isLoading: true });
		var keyvalueList = [];
		this.state.transactionList.forEach(function (listElement) {
			var keyValueObject = {};
			var isExist = false;
			this.GetTransactionColumns().forEach(function (gridColumnElement) {
				if (gridColumnElement.Header == "Transactions") {
					var valueItem = this.GetElementValue(listElement, gridColumnElement.accessor);
					keyValueObject[gridColumnElement.accessor] = { value: valueItem, title: gridColumnElement.Header };
					keyvalueList.map(x => {
						if (x == keyValueObject)
							isExist = true;
					});
					if (!isExist)
						keyvalueList.push(keyValueObject);
				}
				if (gridColumnElement.columns) {
					gridColumnElement.columns.forEach(element => {
						var valueItem = this.GetElementValue(listElement, element.accessor);
						keyValueObject[element.accessor] = { value: valueItem, title: `${gridColumnElement.Header} ${element.Header}` };
						keyvalueList.map(x => {
							if (x == keyValueObject)
								isExist = true;
						});
						if (!isExist)
							keyvalueList.push(keyValueObject);
					});
				}
			}, this);
		}, this);

		var format = "PDF";

		Proxy.POST(
			"/coreapi/v1.0/report/CreateFileFromData",
			{
				jsonFile: {
					title: "Volume Analysis Transactions Report",
					table: keyvalueList,
					format: format,
					model: {
						DateFilter: { BeginDate: this.state.model.BeginDate, EndDate: this.state.model.EndDate }
					}
				}
			},
			(responseData) => {
				this.setState({ isLoading: false });
				let a = document.createElement("a");
				a.style = "display: none";
				document.body.appendChild(a);
				a.href = "data:application/octet-stream;base64," + responseData.Item.value;
				a.download = "VolumeAnalysisTransactionsReport.pdf";
				a.click();
				if (this.state.revenueList)
					this.PrintRevenueList();
			},
			(error) => {
				this.setState({ isLoading: false });
			}
		);
	}

	PrintRevenueList = () => {
		this.setState({ isLoading: true });
		var keyvalueList = [];
		this.state.revenueList.forEach(function (listElement) {
			var keyValueObject = {};
			var isExist = false;
			this.GetRevenueColumns().forEach(function (gridColumnElement) {
				if (gridColumnElement.Header == "Revenue") {
					var valueItem = this.GetElementAmountValue(listElement, gridColumnElement.accessor);
					keyValueObject[gridColumnElement.accessor] = { value: valueItem, title: gridColumnElement.Header };
					keyvalueList.map(x => {
						if (x == keyValueObject)
							isExist = true;
					});
					if (!isExist)
						keyvalueList.push(keyValueObject);
				}
				if (gridColumnElement.columns) {
					gridColumnElement.columns.forEach(element => {
						var valueItem = this.GetElementAmountValue(listElement, element.accessor);
						keyValueObject[element.accessor] = { value: valueItem, title: `${gridColumnElement.Header} ${element.Header}` };
						keyvalueList.map(x => {
							if (x == keyValueObject)
								isExist = true;
						});
						if (!isExist)
							keyvalueList.push(keyValueObject);
					});
				}
			}, this);
		}, this);

		var format = "PDF";

		Proxy.POST(
			"/coreapi/v1.0/report/CreateFileFromData",
			{
				jsonFile: {
					title: "Volume Analysis Revenue Report",
					table: keyvalueList,
					format: format,
					model: {
						DateFilter: { BeginDate: this.state.model.BeginDate, EndDate: this.state.model.EndDate }
					}
				}
			},
			(responseData) => {
				this.setState({ isLoading: false });
				let a = document.createElement("a");
				a.style = "display: none";
				document.body.appendChild(a);
				a.href = "data:application/octet-stream;base64," + responseData.Item.value;
				a.download = "VolumeAnalysisRevenuesReport.pdf";
				a.click();
			},
			(error) => {
				this.setState({ isLoading: false });
			}
		);
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	Validate = () => {
		const { model, vModel } = this.state;
		var messages = [];

		if (model.QuarterFilter) {
			if (!model.Year1) {
				vModel.Year1 = true;
				messages.push("First Year must be select");
			} else {
				vModel.Year1 = false;
			}

			if (!model.Y1Quarter1 && !model.Y1Quarter2 && !model.Y1Quarter3 && !model.Y1Quarter4) {
				vModel.Y1Quarter = true;
				messages.push("Quarter of first year must be select");
			} else {
				vModel.Y1Quarter = false;
			}

			if (!model.Year2) {
				vModel.Year2 = true;
				messages.push("Second Year must be select");
			} else {
				vModel.Year2 = false;
			}

			if (!model.Y2Quarter1 && !model.Y2Quarter2 && !model.Y2Quarter3 && !model.Y2Quarter4) {
				vModel.Y2Quarter = true;
				messages.push("Quarter of second year must be select");
			} else {
				vModel.Y2Quarter = false;
			}

			if (parseInt(model.Year1Desc) > parseInt(model.Year2Desc) || parseInt(model.Year1Desc) > parseInt(model.Year3Desc) || parseInt(model.Year2Desc) > parseInt(model.Year3Desc)) {
				this.ShowMessage("warning", "Dates are not valid!", "Select the small date first.");
				return false;
			}

			if (model.Year3) {
				if (!model.Y3Quarter1 && !model.Y3Quarter2 && !model.Y3Quarter3 && !model.Y3Quarter4) {
					vModel.Y3Quarter = true;
					messages.push("Quarter of third year must be select");
				} else {
					vModel.Y3Quarter = false;
				}
			}
		} else if (model.DateFilter) {
			if (!model.FromDate) {
				vModel.FromDate = true;
				messages.push("From Date must be select");
			}
			else {
				vModel.FromDate = false;
			}

			if (!model.ToDate) {
				vModel.ToDate = true;
				messages.push("To Date must be select");
			}
			else {
				vModel.ToDate = false;
			}
		} else {
			this.ShowMessage("warning", "No filter not selected", "Select any filter to continue.");
			return false;
		}

		if (messages.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.setState({ vModel });
		return true;
	}

	SumValues = (...arr) => {
		return arr.length > 0 ? arr[0].reduce((a, b) => a + b, 0) : 0;
	}

	GetTransactionColumns = () => {
		const { model, transactionList } = this.state;

		const columnHeaders = [
			{
				Header: "Transactions",
				accessor: "Transactions",
				sortable: false,
				filterable: false,
				Cell: row => (
					<span>
						<strong>{row.value}</strong>
					</span>
				),
				Footer: (
					<span>
						<strong>Total Transactions</strong>
					</span>
				)
			}
		];

		const columnsY1 = [];
		const columnsY2 = [];
		const columnsY3 = [];

		if (model.Year1) {
			columnHeaders.push(
				{
					Header: model.Year1Desc,
					columns: columnsY1
				}
			);
		}

		if (model.Year2) {
			columnHeaders.push(
				{
					Header: model.Year2Desc,
					columns: columnsY2
				}
			);
		}

		if (model.Year3) {
			columnHeaders.push(
				{
					Header: model.Year3Desc,
					columns: columnsY3
				}
			);
		}

		if (model.Y1Quarter1) {
			columnsY1.push(
				{
					Header: "Q1",
					accessor: "Y1Q1TransactionCount",
					Cell: row => (
						<div>{Formatter.FormatNumber(row.value)}</div>
					),
					Footer: (
						<span>
							<strong>{Formatter.FormatNumber(this.SumValues(transactionList.filter(d => d.Y1Q1IsTransaction).map(d => d.Y1Q1TransactionCount)))}</strong>
						</span>
					)
				}
			);
		}
		if (model.Y2Quarter1) {
			columnsY2.push(
				{
					Header: "Q1",
					accessor: "Y2Q1TransactionCount",
					Cell: row => (
						<div>{Formatter.FormatNumber(row.value)}</div>
					),
					Footer: (
						<span>
							<strong>{Formatter.FormatNumber(this.SumValues(transactionList.filter(d => d.Y2Q1IsTransaction).map(d => d.Y2Q1TransactionCount)))}</strong>
						</span>
					)
				}
			);
		}
		if (model.Y3Quarter1) {
			columnsY3.push(
				{
					Header: "Q1",
					accessor: "Y3Q1TransactionCount",
					Cell: row => (
						<div>{Formatter.FormatNumber(row.value)}</div>
					),
					Footer: (
						<span>
							<strong>{Formatter.FormatNumber(this.SumValues(transactionList.filter(d => d.Y3Q1IsTransaction).map(d => d.Y3Q1TransactionCount)))}</strong>
						</span>
					)
				}
			);
		}
		if (model.Y1Quarter2) {
			columnsY1.push(
				{
					Header: "Q2",
					accessor: "Y1Q2TransactionCount",
					Cell: row => (
						<div>{Formatter.FormatNumber(row.value)}</div>
					),
					Footer: (
						<span>
							<strong>{Formatter.FormatNumber(this.SumValues(transactionList.filter(d => d.Y1Q2IsTransaction).map(d => d.Y1Q2TransactionCount)))}</strong>
						</span>
					)
				}
			);
		}
		if (model.Y2Quarter2) {
			columnsY2.push(
				{
					Header: "Q2",
					accessor: "Y2Q2TransactionCount",
					Cell: row => (
						<div>{Formatter.FormatNumber(row.value)}</div>
					),
					Footer: (
						<span>
							<strong>{Formatter.FormatNumber(this.SumValues(transactionList.filter(d => d.Y2Q2IsTransaction).map(d => d.Y2Q2TransactionCount)))}</strong>
						</span>
					)
				}
			);
		}
		if (model.Y3Quarter2) {
			columnsY3.push(
				{
					Header: "Q2",
					accessor: "Y3Q2TransactionCount",
					Cell: row => (
						<div>{Formatter.FormatNumber(row.value)}</div>
					),
					Footer: (
						<span>
							<strong>{Formatter.FormatNumber(this.SumValues(transactionList.filter(d => d.Y3Q2IsTransaction).map(d => d.Y3Q2TransactionCount)))}</strong>
						</span>
					)
				}
			);
		}
		if (model.Y1Quarter3) {
			columnsY1.push(
				{
					Header: "Q3",
					accessor: "Y1Q3TransactionCount",
					Cell: row => (
						<div>{Formatter.FormatNumber(row.value)}</div>
					),
					Footer: (
						<span>
							<strong>{Formatter.FormatNumber(this.SumValues(transactionList.filter(d => d.Y1Q3IsTransaction).map(d => d.Y1Q3TransactionCount)))}</strong>
						</span>
					)
				}
			);
		}
		if (model.Y2Quarter3) {
			columnsY2.push(
				{
					Header: "Q3",
					accessor: "Y2Q3TransactionCount",
					Cell: row => (
						<div>{Formatter.FormatNumber(row.value)}</div>
					),
					Footer: (
						<span>
							<strong>{Formatter.FormatNumber(this.SumValues(transactionList.filter(d => d.Y2Q3IsTransaction).map(d => d.Y2Q3TransactionCount)))}</strong>
						</span>
					)
				}
			);
		}
		if (model.Y3Quarter3) {
			columnsY3.push(
				{
					Header: "Q3",
					accessor: "Y3Q3TransactionCount",
					Cell: row => (
						<div>{Formatter.FormatNumber(row.value)}</div>
					),
					Footer: (
						<span>
							<strong>{Formatter.FormatNumber(this.SumValues(transactionList.filter(d => d.Y3Q3IsTransaction).map(d => d.Y3Q3TransactionCount)))}</strong>
						</span>
					)
				}
			);
		}
		if (model.Y1Quarter4) {
			columnsY1.push(
				{
					Header: "Q4",
					accessor: "Y1Q4TransactionCount",
					Cell: row => (
						<div>{Formatter.FormatNumber(row.value)}</div>
					),
					Footer: (
						<span>
							<strong>{Formatter.FormatNumber(this.SumValues(transactionList.filter(d => d.Y1Q4IsTransaction).map(d => d.Y1Q4TransactionCount)))}</strong>
						</span>
					)
				}
			);
		}
		if (model.Y2Quarter4) {
			columnsY2.push(
				{
					Header: "Q4",
					accessor: "Y2Q4TransactionCount",
					Cell: row => (
						<div>{Formatter.FormatNumber(row.value)}</div>
					),
					Footer: (
						<span>
							<strong>{Formatter.FormatNumber(this.SumValues(transactionList.filter(d => d.Y2Q4IsTransaction).map(d => d.Y2Q4TransactionCount)))}</strong>
						</span>
					)
				}
			);
		}
		if (model.Y3Quarter4) {
			columnsY3.push(
				{
					Header: "Q4",
					accessor: "Y3Q4TransactionCount",
					Cell: row => (
						<div>{Formatter.FormatNumber(row.value)}</div>
					),
					Footer: (
						<span>
							<strong>{Formatter.FormatNumber(this.SumValues(transactionList.filter(d => d.Y3Q4IsTransaction).map(d => d.Y3Q4TransactionCount)))}</strong>
						</span>
					)
				}
			);
		}
		if (model.DateFilter) {
			columnHeaders.push(
				{
					Header: "",
					accessor: "TransactionCount",
					Cell: row => (
						<div>{Formatter.FormatNumber(row.value)}</div>
					),
					Footer: (
						<span>
							<strong>{Formatter.FormatNumber(this.SumValues(transactionList.filter(d => d.IsTransaction).map(d => d.TransactionCount)))}</strong>
						</span>
					)
				}
			);
		}
		return columnHeaders;
	}

	GetRevenueColumns = () => {
		const { model, revenueList } = this.state;

		const columnHeaders = [
			{
				Header: "Revenue",
				accessor: "Transactions",
				sortable: false,
				filterable: false,
				Cell: row => (
					<span>
						<strong>{row.value}</strong>
					</span>
				),
				Footer: (
					<span>
						<strong>Total Transactions</strong>
					</span>
				)
			}
		];

		const columnsY1 = [];
		const columnsY2 = [];
		const columnsY3 = [];

		if (model.Year1) {
			columnHeaders.push(
				{
					Header: model.Year1Desc,
					columns: columnsY1
				}
			);
		}

		if (model.Year2) {
			columnHeaders.push(
				{
					Header: model.Year2Desc,
					columns: columnsY2
				}
			);
		}

		if (model.Year3) {
			columnHeaders.push(
				{
					Header: model.Year3Desc,
					columns: columnsY3
				}
			);
		}

		if (model.Y1Quarter1) {
			columnsY1.push(
				{
					Header: "Q1",
					accessor: "Y1Q1TransactionCount",
					Cell: row => (
						<div>{Formatter.FormatMoney(row.value)}</div>
					),
					Footer: (
						<span>
							<strong>{Formatter.FormatMoney(this.SumValues(revenueList.filter(d => d.Y1Q1IsTransaction).map(d => d.Y1Q1TransactionCount)))}</strong>
						</span>
					)
				}
			);
		}
		if (model.Y2Quarter1) {
			columnsY2.push(
				{
					Header: "Q1",
					accessor: "Y2Q1TransactionCount",
					Cell: row => (
						<div>{Formatter.FormatMoney(row.value)}</div>
					),
					Footer: (
						<span>
							<strong>{Formatter.FormatMoney(this.SumValues(revenueList.filter(d => d.Y2Q1IsTransaction).map(d => d.Y2Q1TransactionCount)))}</strong>
						</span>
					)
				}
			);
		}
		if (model.Y3Quarter1) {
			columnsY3.push(
				{
					Header: "Q1",
					accessor: "Y3Q1TransactionCount",
					Cell: row => (
						<div>{Formatter.FormatMoney(row.value)}</div>
					),
					Footer: (
						<span>
							<strong>{Formatter.FormatMoney(this.SumValues(revenueList.filter(d => d.Y3Q1IsTransaction).map(d => d.Y3Q1TransactionCount)))}</strong>
						</span>
					)
				}
			);
		}
		if (model.Y1Quarter2) {
			columnsY1.push(
				{
					Header: "Q2",
					accessor: "Y1Q2TransactionCount",
					Cell: row => (
						<div>{Formatter.FormatMoney(row.value)}</div>
					),
					Footer: (
						<span>
							<strong>{Formatter.FormatMoney(this.SumValues(revenueList.filter(d => d.Y1Q2IsTransaction).map(d => d.Y1Q2TransactionCount)))}</strong>
						</span>
					)
				}
			);
		}
		if (model.Y2Quarter2) {
			columnsY2.push(
				{
					Header: "Q2",
					accessor: "Y2Q2TransactionCount",
					Cell: row => (
						<div>{Formatter.FormatMoney(row.value)}</div>
					),
					Footer: (
						<span>
							<strong>{Formatter.FormatMoney(this.SumValues(revenueList.filter(d => d.Y2Q2IsTransaction).map(d => d.Y2Q2TransactionCount)))}</strong>
						</span>
					)
				}
			);
		}
		if (model.Y3Quarter2) {
			columnsY3.push(
				{
					Header: "Q2",
					accessor: "Y3Q2TransactionCount",
					Cell: row => (
						<div>{Formatter.FormatMoney(row.value)}</div>
					),
					Footer: (
						<span>
							<strong>{Formatter.FormatMoney(this.SumValues(revenueList.filter(d => d.Y3Q2IsTransaction).map(d => d.Y3Q2TransactionCount)))}</strong>
						</span>
					)
				}
			);
		}
		if (model.Y1Quarter3) {
			columnsY1.push(
				{
					Header: "Q3",
					accessor: "Y1Q3TransactionCount",
					Cell: row => (
						<div>{Formatter.FormatMoney(row.value)}</div>
					),
					Footer: (
						<span>
							<strong>{Formatter.FormatMoney(this.SumValues(revenueList.filter(d => d.Y1Q3IsTransaction).map(d => d.Y1Q3TransactionCount)))}</strong>
						</span>
					)
				}
			);
		}
		if (model.Y2Quarter3) {
			columnsY2.push(
				{
					Header: "Q3",
					accessor: "Y2Q3TransactionCount",
					Cell: row => (
						<div>{Formatter.FormatMoney(row.value)}</div>
					),
					Footer: (
						<span>
							<strong>{Formatter.FormatMoney(this.SumValues(revenueList.filter(d => d.Y2Q3IsTransaction).map(d => d.Y2Q3TransactionCount)))}</strong>
						</span>
					)
				}
			);
		}
		if (model.Y3Quarter3) {
			columnsY3.push(
				{
					Header: "Q3",
					accessor: "Y3Q3TransactionCount",
					Cell: row => (
						<div>{Formatter.FormatMoney(row.value)}</div>
					),
					Footer: (
						<span>
							<strong>{Formatter.FormatMoney(this.SumValues(revenueList.filter(d => d.Y3Q3IsTransaction).map(d => d.Y3Q3TransactionCount)))}</strong>
						</span>
					)
				}
			);
		}
		if (model.Y1Quarter4) {
			columnsY1.push(
				{
					Header: "Q4",
					accessor: "Y1Q4TransactionCount",
					Cell: row => (
						<div>{Formatter.FormatMoney(row.value)}</div>
					),
					Footer: (
						<span>
							<strong>{Formatter.FormatMoney(this.SumValues(revenueList.filter(d => d.Y1Q4IsTransaction).map(d => d.Y1Q4TransactionCount)))}</strong>
						</span>
					)
				}
			);
		}
		if (model.Y2Quarter4) {
			columnsY2.push(
				{
					Header: "Q4",
					accessor: "Y2Q4TransactionCount",
					Cell: row => (
						<div>{Formatter.FormatMoney(row.value)}</div>
					),
					Footer: (
						<span>
							<strong>{Formatter.FormatMoney(this.SumValues(revenueList.filter(d => d.Y2Q4IsTransaction).map(d => d.Y2Q4TransactionCount)))}</strong>
						</span>
					)
				}
			);
		}
		if (model.Y3Quarter4) {
			columnsY3.push(
				{
					Header: "Q4",
					accessor: "Y3Q4TransactionCount",
					Cell: row => (
						<div>{Formatter.FormatMoney(row.value)}</div>
					),
					Footer: (
						<span>
							<strong>{Formatter.FormatMoney(this.SumValues(revenueList.filter(d => d.Y3Q4IsTransaction).map(d => d.Y3Q4TransactionCount)))}</strong>
						</span>
					)
				}
			);
		}
		if (model.DateFilter) {
			columnHeaders.push(
				{
					Header: "",
					accessor: "TransactionCount",
					Cell: row => (
						<div>{Formatter.FormatMoney(row.value)}</div>
					),
					Footer: (
						<span>
							<strong>{Formatter.FormatMoney(this.SumValues(revenueList.filter(d => d.IsTransaction).map(d => d.TransactionCount)))}</strong>
						</span>
					)
				}
			);
		}
		return columnHeaders;
	}

	render() {
		const { classes, Disabled } = this.props;
		const { alert, isLoading, model, vModel, transactionList, revenueList } = this.state;
		return (
			<>

				<LoadingComponent Show={isLoading} />

				{alert}

				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled || model == this.initialModel },
					{ Code: "Print", OnClick: this.Print, Disabled: Disabled || (transactionList.length == 0 && revenueList.length == 0) },
					{ Code: "CreateReport", OnClick: this.HandleGetList, Disabled: Disabled },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card >
							<CardBody>
								<GenericExpansionPanel Title="Filter Panel">
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer>
												<GridItem xs={6}>
													<GridItem style={{ opacity: model.DateFilter ? 0.4 : 1 }}>
														<GenericCheckInput
															Name="QuarterFilter"
															LabelText="Quarter"
															Value={model.QuarterFilter}
															ValueChanged={this.HandleChange}
															Disabled={model.DateFilter} />
													</GridItem>

													<GridContainer>
														<GridItem xs={4} style={{ opacity: !model.QuarterFilter ? 0.4 : 1 }} >
															<GenericSelectInput
																Name="Year1"
																LabelText="Quarter"
																Method="POST"
																Url="/coreapi/v1.0/Parameter/Search"
																Parameter={{
																	ParameterCode: "Year"
																}}
																DataRoot="Item"
																KeyValueMember="Id"
																TextValueMember="ParameterDesc"
																Sorted={{
																	Member: "ParameterDesc",
																	Desc: true
																}}
																Value={model.Year1}
																ValueChanged={this.HandleChange}
																Disabled={!model.QuarterFilter}
																Required={model.QuarterFilter}
																IsInvalid={vModel.Year1} />
														</GridItem>
														<GridItem xs={8}>
															<GridItem style={{ opacity: !model.QuarterFilter ? 0.4 : 1, marginLeft: 16, marginTop: 4 }}>
																<GenericCheckInput
																	Name="Y1Quarter1"
																	LabelText="Q1"
																	Value={model.Y1Quarter1}
																	ValueChanged={this.HandleBooleanChange}
																	Disabled={!model.QuarterFilter} />
																<GenericCheckInput
																	Name="Y1Quarter2"
																	LabelText="Q2"
																	Value={model.Y1Quarter2}
																	ValueChanged={this.HandleBooleanChange}
																	Disabled={!model.QuarterFilter} />
																<GenericCheckInput
																	Name="Y1Quarter3"
																	LabelText="Q3"
																	Value={model.Y1Quarter3}
																	ValueChanged={this.HandleBooleanChange}
																	Disabled={!model.QuarterFilter} />
																<GenericCheckInput
																	Name="Y1Quarter4"
																	LabelText="Q4"
																	Value={model.Y1Quarter4}
																	ValueChanged={this.HandleBooleanChange}
																	Disabled={!model.QuarterFilter} />
															</GridItem>
														</GridItem>
													</GridContainer>

													<GridContainer>
														<GridItem xs={4} style={{ opacity: !model.QuarterFilter ? 0.4 : 1 }} >
															<GenericSelectInput
																Name="Year2"
																LabelText="Quarter"
																Method="POST"
																Url="/coreapi/v1.0/Parameter/Search"
																Parameter={{
																	ParameterCode: "Year"
																}}
																DataRoot="Item"
																KeyValueMember="Id"
																TextValueMember="ParameterDesc"
																Sorted={{
																	Member: "ParameterDesc",
																	Desc: true
																}}
																Value={model.Year2}
																ValueChanged={this.HandleChange}
																Disabled={!model.QuarterFilter}
																Required={model.QuarterFilter}
																IsInvalid={vModel.Year2} />
														</GridItem>
														<GridItem xs={8}>
															<GridItem style={{ opacity: !model.QuarterFilter ? 0.4 : 1, marginLeft: 16, marginTop: 4 }}>
																<GenericCheckInput
																	Name="Y2Quarter1"
																	LabelText="Q1"
																	Value={model.Y2Quarter1}
																	ValueChanged={this.HandleBooleanChange}
																	Disabled={!model.QuarterFilter} />
																<GenericCheckInput
																	Name="Y2Quarter2"
																	LabelText="Q2"
																	Value={model.Y2Quarter2}
																	ValueChanged={this.HandleBooleanChange}
																	Disabled={!model.QuarterFilter} />
																<GenericCheckInput
																	Name="Y2Quarter3"
																	LabelText="Q3"
																	Value={model.Y2Quarter3}
																	ValueChanged={this.HandleBooleanChange}
																	Disabled={!model.QuarterFilter} />
																<GenericCheckInput
																	Name="Y2Quarter4"
																	LabelText="Q4"
																	Value={model.Y2Quarter4}
																	ValueChanged={this.HandleBooleanChange}
																	Disabled={!model.QuarterFilter} />
															</GridItem>
														</GridItem>
													</GridContainer>

													<GridContainer>
														<GridItem xs={4} style={{ opacity: !model.QuarterFilter ? 0.4 : 1 }} >
															<GenericSelectInput
																Name="Year3"
																LabelText="Quarter"
																Method="POST"
																Url="/coreapi/v1.0/Parameter/Search"
																Parameter={{
																	ParameterCode: "Year"
																}}
																DataRoot="Item"
																KeyValueMember="Id"
																TextValueMember="ParameterDesc"
																Sorted={{
																	Member: "ParameterDesc",
																	Desc: true
																}}
																Value={model.Year3}
																ValueChanged={this.HandleChange}
																Disabled={!model.QuarterFilter}
															/>
														</GridItem>
														<GridItem xs={8}>
															<GridItem style={{ opacity: !model.QuarterFilter ? 0.4 : 1, marginLeft: 16, marginTop: 4 }}>
																<GenericCheckInput
																	Name="Y3Quarter1"
																	LabelText="Q1"
																	Value={model.Y3Quarter1}
																	ValueChanged={this.HandleBooleanChange}
																	Disabled={!model.QuarterFilter} />
																<GenericCheckInput
																	Name="Y3Quarter2"
																	LabelText="Q2"
																	Value={model.Y3Quarter2}
																	ValueChanged={this.HandleBooleanChange}
																	Disabled={!model.QuarterFilter} />
																<GenericCheckInput
																	Name="Y3Quarter3"
																	LabelText="Q3"
																	Value={model.Y3Quarter3}
																	ValueChanged={this.HandleBooleanChange}
																	Disabled={!model.QuarterFilter} />
																<GenericCheckInput
																	Name="Y3Quarter4"
																	LabelText="Q4"
																	Value={model.Y3Quarter4}
																	ValueChanged={this.HandleBooleanChange}
																	Disabled={!model.QuarterFilter} />
															</GridItem>
														</GridItem>
													</GridContainer>
												</GridItem>

												<GridItem xs={4}>
													<GridItem style={{ opacity: model.QuarterFilter ? 0.4 : 1 }}>
														<GenericCheckInput
															Name="DateFilter"
															LabelText="Date"
															Value={model.DateFilter}
															ValueChanged={this.HandleChange}
															Disabled={model.QuarterFilter} />
													</GridItem>
													<GridContainer>
														<GridItem xs={6} style={{ opacity: !model.DateFilter ? 0.4 : 1 }}>
															<GenericDateInput
																Name="FromDate"
																LabelText="From"
																Value={model.FromDate ? DateHelper.ToDateInputValue(model.FromDate) : ""}
																ValueChanged={this.HandleChange}
																Disabled={!model.DateFilter}
																Required={model.DateFilter}
																IsInvalid={vModel.FromDate} />
														</GridItem>
														<GridItem xs={6} style={{ opacity: !model.DateFilter ? 0.4 : 1 }}>
															<GenericDateInput
																Name="ToDate"
																LabelText="To"
																Value={model.ToDate ? DateHelper.ToDateInputValue(model.ToDate) : ""}
																ValueChanged={this.HandleChange}
																Disabled={!model.DateFilter}
																Required={model.DateFilter}
																IsInvalid={vModel.ToDate} />
														</GridItem>
													</GridContainer>
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={12}>
						<GenericTitle text={`${window.Title} Volume Analysis Report`} />
					</GridItem>
					<GridItem xs={12} style={{ marginTop: "16px" }}>
						<ReactTable
							style={{
								border: "1px solid black"
							}}
							className={`${classes.table} ${"-striped -highlight"}`}
							data={transactionList}
							filterable={false}
							columns={this.GetTransactionColumns()}
							showPaginationTop={false}
							showPaginationBottom={false}
							minRows={1}
							getTheadGroupTrProps={() => {
								return {
									style: {
										background: "#f8ab72",
										borderBottom: "1px solid black"
									}
								};
							}}
							getTheadTrProps={() => {
								return {
									style: {
										background: "#f8ab72"
									}
								};
							}}
							getTfootTrProps={() => {
								return {
									style: {
										background: "#f8ab72"
									}
								};
							}}
						/>
					</GridItem>
					<GridItem xs={12} style={{ marginTop: "36px", marginBottom: "36px" }}>
						<ReactTable
							style={{
								border: "1px solid black"
							}}
							className={`${classes.table} ${"-striped -highlight"}`}
							data={revenueList}
							filterable={false}
							columns={this.GetRevenueColumns()}
							showPaginationTop={false}
							showPaginationBottom={false}
							minRows={1}
							getTheadGroupTrProps={() => {
								return {
									style: {
										background: "#f8ab72",
										borderBottom: "1px solid black"
									}
								};
							}}
							getTheadTrProps={() => {
								return {
									style: {
										background: "#f8ab72"
									}
								};
							}}
							getTfootTrProps={() => {
								return {
									style: {
										background: "#f8ab72"
									}
								};
							}}
						/>
					</GridItem>
				</GridContainer>

			</>
		);
	}
}

VolumeAnalysis.propTypes = {
	classes: PropTypes.object
};

export default withStyles(styles)(VolumeAnalysis);