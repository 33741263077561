import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import PropTypes from "prop-types";
import React from "react";
import { GenericAlert } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { ProgramCodes } from "views/Constants/Constant.js";
import CorporateAccount from "./CorporateAccount";
import CustomerContact from "./CustomerContact";
import ETransferInformation from "./ETransferInformation";
import MyProfileAccounts from "./MyProfileAccounts";
import PersonalAccount from "./PersonalAccount"; 
import MyProfileAddresses from "./MyProfileAddresses";
class Profile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			model: {},
			customerAccountProfile: {},
			personalModel: {},
			corporateModel: {},
			accounts: [],
			IsCustomer: 0,
			isLoading: false,
			programs: [],
			eTransferInfos: [],
			addressList: [],
			clientType: ""
		};
	}

	componentDidMount() {
		this.GetProfileInfo();
		this.GetPrograms();
	}

	handleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = data.Description;
		this.setState({ model });
	}

	GetPrograms() {
		Proxy.GET(
			"/bankapi/v1.0/Bank/ValidBankPrograms",
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error !", responseData.ErrorDescription);
					this.hideLoading();
					return;
				} else {
					if (responseData.Item != null) {
						this.setState({
							programs: responseData.Item.filter(k =>
								k.ProgramCode == ProgramCodes.Bank_Programs_EFT ||
								k.ProgramCode == ProgramCodes.Bank_Programs_e_Transfer ||
								k.ProgramCode == ProgramCodes.Bank_Programs_DDT ||
								k.ProgramCode == ProgramCodes.Bank_Programs_CDIC)
						});
					}
				}
			},
			this.errorDefinitionCallback
		);
	}

	GetProfileInfo = () => {
		this.showLoading();
		//GetCustomerBankAccountsOp class
		Proxy.POST(
			"/bankapi/v1.0/BankCustomer/GetProfileInfo",
			{},
			this.handleCustomerProfileInfo,
			this.errorDefinitionCallback
		);
	}

	handleCustomerProfileInfo = (responseData) => {
		var personalModel = { ...this.state.personalModel };
		var corporateModel = { ...this.state.personalModel };
		var IsCustomer = 0;
		var accounts = [...this.state.accounts];
		var eTransferInfos = [...this.state.eTransferInfos];
		var addressList = [...this.state.addressList];
		var clientType = this.state.clientType;
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error !", responseData.ErrorDescription);
			this.hideLoading();
			return;
		} else {
			if (responseData.Item != null) {
				if (responseData.Item.IsCustomer) {
					personalModel = responseData.Item.Customer || {};
					IsCustomer = 1;
				} else {
					corporateModel = responseData.Item.Corporate || {};
					IsCustomer = 2;
					accounts = responseData.Item.Accounts;
				}

				eTransferInfos = responseData.Item.InteractCustomersInfos;
				addressList = responseData.Item.CustomerAddressList;
				clientType = responseData.Item.ClientType;
				this.setState({
					personalModel: personalModel,
					corporateModel: corporateModel,
					IsCustomer: IsCustomer,
					isLoading: false,
					accounts,
					eTransferInfos: eTransferInfos,
					addressList,
					clientType
				});
			}

		}
	}

	errorDefinitionCallback = (error) => {
		this.hideLoading();
		this.ShowMessage("error", "Error !", "An error occurred while requesting data" + error);
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
	}

	hideAlert = () => {
		this.setState({
			alert: null
		});
	}

	showLoading = () => {
		this.setState({ isLoading: true });
	}

	hideLoading = () => {
		this.setState({ isLoading: false });
	}

	render() {
		const { personalModel, corporateModel, isLoading, accounts, eTransferInfos, addressList, clientType } = this.state;
		return (
			<div>
				<GridContainer spacing={16}>
					<LoadingComponent Show={isLoading} />
					{this.state.alert}
					<GridItem xs={12}>

						{this.state.IsCustomer == 1 &&
							<PersonalAccount
								setAppCenterTitle={this.props.setAppCenterTitle}
								setAppLeftTitle={this.props.setAppLeftTitle}
								model={personalModel || {}}
							/>
						}
						{this.state.IsCustomer == 2 &&
							<CorporateAccount
								setAppCenterTitle={this.props.setAppCenterTitle}
								setAppLeftTitle={this.props.setAppLeftTitle}
								model={corporateModel || {}}
							/>
						}
					</GridItem>
					<GridItem xs={12} >
						<MyProfileAddresses
							addressList={addressList}
							clientType={clientType} />
					</GridItem>
					<GridItem xs={12} >
						<ETransferInformation
							eTransferInfos={eTransferInfos} />
					</GridItem>
					<GridItem xs={12} style={{ marginLeft: 5, marginRight: 5 }}>
						<CustomerContact />
					</GridItem>
					<GridItem xs={12} >
						<MyProfileAccounts Accounts={accounts || []} />
					</GridItem>
				</GridContainer>
				<br />
			</div>
		);
	}
}

Profile.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func
};

export default Profile;