import { History } from "history";

/**
 * @param {History<any>} history
 * @param {string} path
 * @param {string} [name]
 * @param {any} [value]
 */
function Push(history, path, name, value) {
	if (value != null)
		localStorage.setItem(name, JSON.stringify(value));

	history.push(path);
}

/**
 * @param {string} name
 */
function ReadAndClear(name) {
	var item = localStorage.getItem(name);
	var value = item != null ? JSON.parse(item) : null;
	localStorage.removeItem(name);
	return value;
}

/**
 * @param {string} name
 */
function Read(name) {
	var item = localStorage.getItem(name);
	var value = item != null ? JSON.parse(item) : null;
	return value;
}

const RouteHelper = {
	Push,
	ReadAndClear,
	Read
};

export default RouteHelper;