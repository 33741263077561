import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import CustomerComponent from "views/Components/CustomerComponent.jsx";
import { GenericCheckInput, GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import GridButton from "views/Components/GridButton.jsx";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { GridColumnType, MenuCodes, ProgramCodes, EmbossType} from "views/Constants/Constant";
import { DetailIcon } from '../../../core/Icons';


class CardRenewalRequestList extends Component {
	constructor(props) {
		super(props);
		this.parameterEmbossType = { ParameterCode: "EmbossType" };

		this.initialModel = {
			Id: 0,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined,
			IsMainCard: true,
			ProgramCode: "",
			CardIssuingReasonTypeId: 0,
			EmbossStatusId: 0,
			ProductId: 0,
			StartDate: DateHelper.GetLocalDateAsUtc(),
			EndDate: DateHelper.GetLocalDateAsUtc(),
			IsMass: true
		};

		this.state = {
			model: this.initialModel,
			list: [],
			isLoading: false,
			vModel: {},
			reEmbossList: [],
			reReasonEmbosList: [],
			isReEmbossDialogOpen: false,
			isReasonReEmbossDialogOpen: false
		}

		this.emptyObject = {};

		this.maxLength26 = { maxLength: 26 };

		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined
		};

		this.renderItemUniqueClient = {};
		this.renderItemCard = {};
		this.parameterProduct = {};

		this.ReasonEmbossColumns =
			[
				{
					Header: "Is Succeded",
					accessor: "IsSucceded",
					Cell: row => (
						<div>{row.value == true ? "Yes" : "No"}</div>
					)
				},
				{
					Header: "Masked Card Number",
					accessor: "MaskedCardNumber"
				},
				{
					Header: "Error Description",
					accessor: "ErrorDescription"
				},

			];

		this.DetailColumns =
			[
				{
					Header: "Create Date",
					accessor: "InsertDateTime",
					type: GridColumnType.DateTimeUtc
				},
				{
					Header: "Card Id",
					accessor: "CardId"
				},
				{
					Header: "Client",
					accessor: "ClientName"
				},
				{
					Header: "Customer Name",
					accessor: "CustomerName"
				},
				{
					Header: "Card Number",
					accessor: "CardNumber"
				},
				{
					Header: "Product Name",
					accessor: "ProductName"
				},
				{
					Header: "Emboss Name",
					accessor: "EmbossName1"
				},
				{
					Header: "Emboss 4th Line",
					accessor: "EmbossName2"
				},
				{
					Header: "Usage Limit",
					accessor: "CardUsageLimit",
					type: GridColumnType.Money
				},
				{
					Header: "Reason",
					accessor: "IssuingReasonType",
				},
				{
					Header: "Emboss Status",
					accessor: "EmbossStatusName",
				},
				{
					Header: "File Name",
					accessor: "FileName",
				},
				{
					Header: "File Creation Date",
					accessor: "EmbossFileCreateDate",
					type: GridColumnType.Date
				},
				{
					Header: "Plastic Type",
					accessor: "PlasticTypeDesc",
				},
				{
					Header: "Product Brand",
					accessor: "ProductBrand",
				},
				{
					Header: "Bin Description",
					accessor: "BinDesc",
				}
				,
				{
					Header: "Is No Name Card",
					accessor: "IsNoNameCard",
					Cell: row => (
						<div>{row.value == true ? "Yes" : "No"}</div>
					)
				},
				{
					Header: "Emboss Type",
					accessor: "EmbossTypeDesc"
				},
				{
					Header: "Card Perso Office",
					accessor: "CardPersoOffice"
				}
			];

		this.Columns = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip="Payment Code Resend"
							Icon={DetailIcon}
							Disabled={props.Disabled}
							OnClick={() => { this.ShowQrCode(row.original.Id, row.index); }} />
					</div>
				),
				sortable: false,
				filterable: false,
				resizable: false,
				width: 60
			},
			{
				Header: "Re-Emboss",
				accessor: "Actions",
				Cell: row => (
					<GenericCheckInput
						Value={this.state.reEmbossList.filter(c => c.Id == row.original.Id).length > 0}
						ValueChanged={(e) => {
							this.HandleCheck(e, row.original);
						}}
						Disabled={
							!row.original.IsExported || !row.original.IsAvailableCenterCode || !row.original.IsAvailableEmbossStatus || !row.original.HasAddress
						}
					/>
				),
				sortable: false,
				filterable: false,
				resizable: false,
				width: 90,
				show: !ClientHelper.IsClient()
			},
			...this.DetailColumns
		];
	}

	HandleCheck = (e, x) => {
		this.setState(state => ({
			reEmbossList: state.reEmbossList.includes(x)
				? state.reEmbossList.filter(c => c !== x)
				: [...state.reEmbossList, x]
		}));
	}

	ShowQrCode = (data, index) => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/CardRequest/ShowQrCodeData",
			{ Id: data.Id },
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.state.model.QrCode = responseData.Item;
					this.setState({ model: this.state.model, isDialogOpen: true });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			});
	}



	componentDidMount() {
		var menuCode = this.props.MenuCode;
		if (menuCode == MenuCodes.Menu_LmsCardRenewalList) {
			this.props.setAppLeftTitle("Card Emboss Monitoring");
			this.props.setAppCenterTitle("LOAN MANAGEMENT SYSTEM");
			this.ProgramCode = ProgramCodes.Lender;
		}
		else {
			this.props.setAppLeftTitle("Card Emboss Monitoring");
			this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
			this.ProgramCode = ProgramCodes.Prepaid;
		}
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		if (name === "Customer") {
			if (newValue) {
				model.ClientCustomerId = newValue.Id;
				model.CustomerNumber = newValue.CustomerNumber;
			} else {
				delete model.ClientCustomerId;
				delete model.CustomerNumber;
			}
		}
		if (name === "UniqueClientId") {
			delete model.ClientCustomerId;
			delete model.CustomerNumber;
		}
		if (name === "CardId" && data) {
			model.EmbossName = data.CardEmbossName;
		}
		if (name === "EmbossType" && newValue!=EmbossType.InstantId) {
			model.IsMass=true;
			model.InstantEmbossBranchId = undefined;
		}
		if (name === "EmbossType" && newValue==EmbossType.InstantId) {
			model.IsMass=false;
		}

		model[name] = newValue;
		this.setState({ model });
	}

	HandleClear = () => {
		this.setState({ model: { ...this.initialModel }, isEdit: false, index: -1, reEmbossList: [] });
	}

	HandleSearch = () => {
		if (!this.Validate()) {
			return;
		}
		const model = { ...this.state.model };
		model.ProgramCode = this.ProgramCode;
		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/CardRequest/GetAllCardRequest",
			model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ list: responseData.Item, reEmbossList: [] });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);

	}

	Validate = () => {
		const { model, vModel } = this.state;
		const { showValidationErrors } = this.props;

		var errorList = [];

		if (!model.UniqueClientId) {
			errorList.push("Select the Client to continue.");
			vModel.UniqueClientId = true;
		}
		else
			vModel.UniqueClientId = false;



		if (errorList.length > 0) {
			showValidationErrors(errorList);
			return false;
		}

		return true;
	}
	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}
	RenderItemCard = (d) => {
		this.renderItemCard = `${d.MaskedCardNumber} - ${d.CardEmbossName}`;
		return this.renderItemCard;
	}
	RowSelected = (index) => {
		let { model, list } = this.state;
		let StartDate = model.StartDate;
		let EndDate = model.EndDate;

		model = list[index];

		model.StartDate = StartDate;
		model.EndDate = EndDate;
		if (list[index].CardRequestId) {
			model.Id = list[index].Id;
		}
		this.setState({ isEdit: true, model, index });
	}

	GetProductParameter = () => {
		this.parameterProduct.UniqueClientId = this.state.model.UniqueClientId;
		this.parameterProduct.IsNoNameProduct = this.state.model.IsNoNameCard;
		return this.parameterProduct;
	}

	FillCustomer = (customer) => {
		this.HandleChange("Customer", customer);
	}

	CancelSelectCallback = () => {
		this.setState({
			isDialogOpen: false,
			isDisputeDialogOpen: false,
			isReEmbossDialogOpen: false,
			isReasonReEmbossDialogOpen: false
		});
	};
	ReEmbossConfirm = async () => {
		const { ExecuteApiPost } = this.props;

		let { model, reEmbossList } = this.state;

		var result = await ExecuteApiPost("/prepaidapi/v1.0/CardRequest/ReSendCardRequest", {
			CardRequestDataList: reEmbossList,
			UniqueClientId: model.UniqueClientId
		});
		if (result != null) {
			this.setState({ reReasonEmbosList: result, isReasonReEmbossDialogOpen: true })
		}
	}
	HandleReEmboss = () => {
		this.setState({ isReEmbossDialogOpen: true });
	}
	render() {
		const { Disabled } = this.props;
		const { alert, model, vModel, list, isLoading, index } = this.state;

		var IsClient = ClientHelper.IsClient();

		return (
			<div>
				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
					!IsClient && { Code: "Re-Emboss", OnClick: this.HandleReEmboss, Disabled: Disabled || this.state.reEmbossList.length == 0 },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Panel">
									<GridItem xs={12}>
										<GridContainer>
											<GridItem xs={4}>
												<GenericCheckInput
													Name="IsNoNameCard"
													LabelText="Is No Name Card"
													Value={model.IsNoNameCard || false}
													ValueChanged={this.HandleChange}
													Disabled={Disabled} />
											</GridItem>
										</GridContainer>
										<GridContainer>
											<GridItem xs={4}>
												<GenericSelectInput
													Name="UniqueClientId"
													LabelMd={4}
													LabelText="Client"
													Method="POST"
													Url="/bankapi/v1.0/BankCustomer/Search"
													Parameter={this.parameterUniqueClientId}
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={this.RenderItemUniqueClient}
													Value={model.UniqueClientId}
													ValueChanged={this.HandleChange}
													CanClear
													Disabled={IsClient}
													DefaultIndex={IsClient ? 0 : -1}
													Required
													IsInvalid={vModel.UniqueClientId}
												/>
											</GridItem>

											<GridItem xs={4}>
												<GenericSelectInput
													key={[model.UniqueClientId, model.IsNoNameCard]}
													Name="ProductId"
													LabelMd={4}
													LabelText="Product"
													Method="POST"
													Url="/prepaidapi/v1.0/Product/Search"
													Parameter={this.GetProductParameter()}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ProductName"
													Value={model.ProductId}
													ValueChanged={this.HandleChange}
												/>
											</GridItem>

											{!model.IsNoNameCard &&
												<GridItem xs={4}>
													<CustomerComponent
														key={model.UniqueClientId}
														LabelMd={4}
														CustomerNumber={model.CustomerNumber}
														HandleChange={this.HandleChange}
														FillCallback={this.FillCustomer}
														Programs={[ProgramCodes.Prepaid]}
														UniqueClientId={model.UniqueClientId}
													/>
												</GridItem>
											}
											{!model.IsNoNameCard &&

												<GridItem xs={4}>
													<GenericSelectInput
														key={model.ClientCustomerId}
														Name="CardId"
														LabelMd={4}
														LabelText="Masked Card Number"
														Method="POST"
														Url="/prepaidapi/v1.0/Card/CardSearch"
														Parameter={{
															ClientCustomerId: model.ClientCustomerId,
															CardSearchCriteria: 2
														}}
														DataRoot="Item"
														RenderItem={d => `${d.CardEmbossName} - ${d.MaskedCardNumber}`}
														KeyValueMember="CardId"
														TextValueMember="MaskedCardNumber"
														Value={model.CardId}
														ValueChanged={this.HandleChange}
													/>
												</GridItem>
											}

											<GridItem xs={4}>
												<ParameterComponent
													Name="CardIssuingReasonTypeId"
													LabelMd={4}
													LabelText="Reason"
													ParameterCode="CardIssuingReasonType"
													ParameterValue3="1"
													Value={model.CardIssuingReasonTypeId}
													ValueChanged={this.HandleChange}
													Disabled={false}
												/>
											</GridItem>
											<GridItem xs={4}>
												<ParameterComponent
													Name="EmbossStatusId"
													LabelMd={4}
													LabelText="Emboss Status"
													ParameterCode="EmbossStatus"
													Value={model.EmbossStatusId}
													ValueChanged={this.HandleChange}
													Disabled={false}
												/>
											</GridItem>
											<GridItem xs={4}>
												<GenericTextInput
													Name="EmbossName1"
													LabelMd={4}
													LabelText="Emboss Name"
													inputProps={this.maxLength26}
													IsText={true}
													Value={model.EmbossName1}
													ValueChanged={this.HandleChange}
												/>
											</GridItem>

											{model.IsNoNameCard &&
												<GridItem xs={4}>
													<GenericTextInput
														Name="MaskedCardNumber"
														LabelMd={4}
														Format={"@@@@@@******@@@@"}
														LabelText="Masked Card Number"
														inputProps={this.maxLength26}
														IsText={true}
														Value={model.MaskedCardNumber}
														ValueChanged={this.HandleChange}
													/>
												</GridItem>
											}
											<GridItem xs={4}>
												<GenericDateInput
													Utc
													Name="StartDate"
													LabelText="Date From"
													Value={model.StartDate ? DateHelper.ToDateInputValue(model.StartDate) : ""}
													ValueChanged={this.HandleChange}
													IsFuture={false} />
											</GridItem>
											<GridItem xs={4}>
												<GenericDateInput
													Utc
													Name="EndDate"
													LabelText="Date To"
													Value={model.EndDate ? DateHelper.ToDateInputValue(model.EndDate) : ""}
													ValueChanged={this.HandleChange}
													MinDate={model.StartDate} />
											</GridItem>


											<GridItem xs={4}>
												<GenericTextInput
													Name="FileName"
													LabelText="File Name"
													Value={model.FileName}
													ValueChanged={this.HandleChange}
												/>
											</GridItem>

											<GridItem xs={4}>
												<GenericDateInput
													Utc
													Name="EmbossFileCreateDate"
													LabelText="File Creation Date"
													Value={model.EmbossFileCreateDate}
													ValueChanged={this.HandleChange}
													MaxDate={DateHelper.GetLocalDateAsUtc()} />
											</GridItem>

											<GridItem xs={4}>
												<GenericSelectInput
													Name="EmbossType"
													LabelText="Emboss Type"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.parameterEmbossType}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													ValueChanged={this.HandleChange}
													Value={model.EmbossType}
												/>
											</GridItem>
											<GridItem xs={4}>
												<GenericSelectInput
													Name="InstantEmbossBranchId"
													LabelText="Card Perso Office"
													Method="POST"
													Url="/prepaidapi/v1.0/Card/GetInstantCardBranchList"
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="Description"
													ValueChanged={this.HandleChange}
													Value={model.InstantEmbossBranchId}
													Disabled={model.IsMass}
												/>
											</GridItem>
											<GridItem xs={4}>
												<GenericTextInput
													Name="Emboss4thLine"
													LabelText="Emboss 4th Line"
													inputProps={this.maxLength21}
													Value={model.Emboss4thLine}
													ValueChanged={this.HandleChange} />
												</GridItem>
										</GridContainer>
									</GridItem>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={"Card Emboss List"} />
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={4}>
									</GridItem>
									<GridItem xs={8} />
								</GridContainer>
								<GridContainer spacing={16}>
									<GridItem xs={12}>
										<GenericGrid
											Data={list}
											Columns={this.Columns}
											ProgramCode={ProgramCodes.Prepaid}
											ShowPagination={true}
											PageSize={100} />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>



				<GenericDialog open={this.state.isDialogOpen} onBackdropClick={this.CancelSelectCallback} maxWidth="sm" fullWidth keepMounted={true} style={{ zIndex: 8 }} >
					<DialogTitle><GenericLabel FontSize="16px" Text="Payment Code" Bold={true} />
					</DialogTitle>

					<DialogContent>
						<img style={{ width: 300, height: 300, marginLeft: "114px", display: "inline-block" }} src={"data:image/png;base64," + model.QrCode}
						/>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.CancelSelectCallback}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>


				<GenericDialog open={this.state.isReEmbossDialogOpen} onBackdropClick={this.CancelSelectCallback} maxWidth="lg" fullWidth keepMounted={true} style={{ zIndex: 8 }} >
					<DialogTitle><GenericLabel FontSize="16px" Text="Detail" Bold={true} />
					</DialogTitle>

					<DialogContent>
						<GridContainer spacing={16}>
							<GridItem xs={12}>
								<GenericGrid
									Data={this.state.reEmbossList}
									Columns={this.DetailColumns}
									SelectedIndex={index}
									ProgramCode={ProgramCodes.Prepaid}
									ShowPagination={true}
									PageSize={100} />
							</GridItem>
						</GridContainer>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.ReEmbossConfirm}>Confirm</Button>
						<Button size="sm" onClick={this.CancelSelectCallback}>Cancel</Button>

					</GenericDialogActions>
				</GenericDialog>

				<GenericDialog open={this.state.isReasonReEmbossDialogOpen} onBackdropClick={this.CancelSelectCallback} maxWidth="lg" fullWidth keepMounted={true} style={{ zIndex: 8 }} >
					<DialogTitle><GenericLabel FontSize="16px" Text="Detail" Bold={true} />
					</DialogTitle>

					<DialogContent>
						<GridContainer spacing={16}>
							<GridItem xs={12}>
								<GenericGrid
									Data={this.state.reReasonEmbosList}
									Columns={this.ReasonEmbossColumns}
									SelectedIndex={index}
									ProgramCode={ProgramCodes.Prepaid}
									ShowPagination={true}
									PageSize={100} />
							</GridItem>
						</GridContainer>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.CancelSelectCallback}>Cancel</Button>

					</GenericDialogActions>
				</GenericDialog>
			</div >
		);
	}
}

CardRenewalRequestList.propTypes = {
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool
};

export default withArtifex(CardRenewalRequestList, {});