import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericDateInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import DateHelper from "core/DateHelper";
import ClientHelper from "core/ClientHelper";
import { GridColumnType } from "views/Constants/Constant";

class CouponHistory extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			model: {},
			list: [],
			isClient: ClientHelper.IsClient(),
		};

		this.ParameterEmpty = {};
		this.ParameterExecutionType = { ParameterCode: "CampaignExecutionType" };
		this.ColumnsCouponHistory = [
			{
				Header: "Program",
				accessor: "CouponProfile.Program.Description"
			},
			{
				Header: "ExecutionType",
				accessor: "ExecutionType.ParameterDesc"
			},
			{
				Header: "Profile Name",
				accessor: "CouponProfile.ProfileName"
			},
			{
				Header: "Unique Client ID",
				accessor: "UniqueClient.UniqueClientId"
			},
			{
				Header: "Customer Name",
				accessor: "Customer.CustomerName"
			},
			{
				Header: "Coupon Code",
				accessor: "CouponCode"
			},
			{
				Header: "Is Success",
				accessor: "IsSuccess",
				Cell: row => (
					<div>{row.value ? "Yes" : "No"}</div>
				),
			},
			{
				Header: "Is Free Gift",
				accessor: "IsFreeGift",
				Cell: row => (
					<div>{row.value ? "Yes" : "No"}</div>
				),
			},
			{
				Header: "Is Discount",
				accessor: "IsDiscount",
				Cell: row => (
					<div>{row.value ? "Yes" : "No"}</div>
				),
			},
			{
				Header: "Date",
				accessor: "InsertDateTime",
				type: GridColumnType.DateTime,
				filterMethod: (filter, row, column) => {
					return Formatter.FormatDateTime(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase())
				}
			}
		];
	}
	componentDidMount() {
		if (this.props.setAppCenterTitle) {
			this.props.setAppCenterTitle("Campaign Manager");
		}

		if (this.props.setAppLeftTitle)
			this.props.setAppLeftTitle("Coupon History");

	}


	handleChange = (name, newValue, data) => {
		this.setState(function (state, props) {
			var temp = state.model || {};
			temp[name] = newValue;
			return { model: temp };
		});
	}


	Search = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/campaignapi/v1.0/CampaignCouponHistory/CouponSearch",
			this.state.model,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					this.props.showMessage("error", "Error !", responseData.ErrorDescription);
				} else {
					this.setState({ list: responseData.Item || [] });
				}
				this.setState({ isLoading: false });
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	ClearData = () => {
		this.setState({
			isLoading: false,
			model: {},
			list: []
		});
	}
	RenderItemUniqueClient = (d) => {
		return  `${d.UniqueClientId} - ${d.Name}`;
	}
	
	render() {
		const { Disabled } = this.props;
		const { isLoading, model, list } = this.state;
		return (
			<div>
				<LoadingComponent Show={isLoading} />
				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: () => this.ClearData(), Disabled: Disabled },
					{ Code: "Search", OnClick: this.Search, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<Card className="no-radius">
					<GenericExpansionPanel Title={"Filter Search"}>
						<CardBody>
							<GridContainer spacing={16}>
								{!this.state.isClient && (
									<GridItem xs={3} >
										<GenericSelectInput
											Name="UniqueClientId"
											LabelText="Client"
											Method="POST"
											Url="/bankapi/v1.0/BankCustomer/Search"
											Parameter={{}}
											DataRoot="Item"
											KeyValueMember="Id"
											RenderItem={this.RenderItemUniqueClient}
											Value={model.UniqueClientId}
											ValueChanged={this.handleChange}
											CanClear
											All
											Disabled={this.state.isClient}
											DefaultIndex={this.state.isClient ? 0 : -1}
										/>
									</GridItem>
								)}
								<GridItem xs={3} >
									<GenericSelectInput
										CanClear
										Disabled={Disabled || model.Id > 0}
										Name="ProgramId"
										LabelText="Program"
										Value={model.ProgramId}
										ValueChanged={this.handleChange}
										KeyValueMember="Id"
										TextValueMember="Description"
										Method="GET"
										Url="/bankapi/v1.0/Bank/ValidProgramsCampaign"
										DataRoot="Item"
									/>
								</GridItem>
								<GridItem xs={3} >
									<GenericSelectInput
										CanClear
										Disabled={Disabled || model.Id > 0}
										Name="ExecutionTypeId"
										LabelText="Execution Type"
										Value={model.ExecutionTypeId || ""}
										ValueChanged={this.handleChange}
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.ParameterExecutionType}
										DataRoot="Item"
									/>
								</GridItem>
								<GridItem xs={3} >
									<GenericSelectInput
										CanClear
										Disabled={Disabled || model.Id > 0}
										Name="CouponProfileId"
										LabelText="Coupon Profile"
										Value={model.CouponProfileId}
										ValueChanged={this.handleChange}
										KeyValueMember="Id"
										TextValueMember="ProfileName"
										Method="POST"
										Parameter={this.ParameterEmpty}
										Url="/campaignapi/v1.0/CouponProfile/GetAll"
										DataRoot="Item"
									/>
								</GridItem>
								<GridItem xs={3} >
									<GenericDateInput
										Name="StartDateTime"
										LabelText="Start Date"
										MaxDate={model.EndDateTime && DateHelper.ToMoment(model.EndDateTime)}
										Value={model.StartDateTime == null ? "" : DateHelper.ToDateInputValue(model.StartDateTime)}
										ValueChanged={this.handleChange}
										Disabled={Disabled}
									/>
								</GridItem>
								<GridItem xs={3} >
									<GenericDateInput
										Name="EndDateTime"
										LabelText="End Date"
										MinDate={model.StartDateTime && DateHelper.ToMoment(model.StartDateTime)}
										Value={model.EndDateTime == null ? "" : DateHelper.ToDateInputValue(model.EndDateTime)}
										ValueChanged={this.handleChange}
										Disabled={Disabled}
									/>
								</GridItem>
							</GridContainer>
						</CardBody >
					</GenericExpansionPanel>
				</Card>

				<Card className="no-radius">
					<CardBody>
						<GenericGrid
							Data={list}
							Columns={this.ColumnsCouponHistory}
						/>
					</CardBody>
				</Card>

			</div>
		);
	}
}

CouponHistory.propTypes = {
	classes: PropTypes.object
};

export default withArtifex(CouponHistory, {});