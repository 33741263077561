import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import  PropTypes  from "prop-types";
import React from "react";
import { GenericCheckInput, GenericGrid, GenericLabel, GenericRadioInput, GenericTextInput } from "views/Components/Generic";

class ButtonOverride extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		const { classes, jsonModel, model, Disabled } = this.props;
		return (
			<div>

				<GridContainer>
					<GridItem xs={1}>
						<Button
							color='black'
							size="sm"
							onClick={() => {
								if (jsonModel.ButtonOverrides)
									jsonModel.ButtonOverrides.findIndex(x => x.Code == model.Code) > -1 ? this.props.handleUpdate() : this.props.handleAdd();
								else
									this.props.handleAdd();
							}}
							fullWidth
							disabled={!model || !model.Code}>
							{jsonModel.ButtonOverrides && jsonModel.ButtonOverrides.findIndex(x => x.Code == model.Code) > -1 ? "Update" : "Add"}
						</Button>
					</GridItem>
					<GridItem xs={1}>
						<Button
							color='black'
							size="sm"
							onClick={this.props.handleDelete}
							fullWidth
							disabled={!model || !model.Code}>
							Delete
						</Button>
					</GridItem>
					<GridItem xs={1}>
						<Button
							color='black'
							size="sm"
							onClick={this.props.handleClear}
							fullWidth
							disabled={!model || !model.Code}>
							Clear
						</Button>
					</GridItem>
				</GridContainer>
				<GridContainer>
					<GridItem xs={3}>
						<GenericTextInput
							Name="Code"
							LabelText="Code"
							Value={model.Code}
							ValueChanged={this.props.handleChange} />
					</GridItem>
					<GridItem xs={6}>
						<GenericTextInput
							Name="URL"
							LabelMd={2}
							LabelText="URL"
							Value={model.URL}
							ValueChanged={this.props.handleChange} />
					</GridItem>
					<GridItem xs={3}>
						<GenericCheckInput
							Name="Hidden"
							LabelText="Is Hidden"
							Value={model.Hidden}
							ValueChanged={this.props.handleBooleanChange} />
					</GridItem>
					<GridItem xs={3}>
						<GenericRadioInput
							Name="Method"
							LabelText="Method"
							IsStatic={true}
							StaticData={[{ Value: "GET", Text: "GET" }, { Value: "POST", Text: "POST" }]}
							KeyValueMember="Value"
							TextValueMember="Text"
							Value={model.Method}
							ValueChanged={this.props.handleChange} />
					</GridItem>
					<GridItem xs={12}>
						{jsonModel.ButtonOverrides &&
							<GenericLabel Bold={true} FontSize="12px" TextColor="black" Text="Buttons"></GenericLabel>
						}
						{jsonModel.ButtonOverrides &&
							<GenericGrid
								key={jsonModel.ButtonOverrides.length}
								Data={jsonModel.ButtonOverrides}
								Columns={[
									{
										Header: "Code",
										accessor: "Code",
										width: 85
									},
									{
										Header: "Hidden",
										accessor: "Hidden",
										Cell: row => (
											<div>{row.value == true ? "Yes" : "No"}</div>
										),
										width: 85
									},
									{
										Header: "Method",
										accessor: "Method",
										width: 85
									},
									{
										Header: "URL",
										accessor: "URL"
									}
								]}
								RowSelected={index => {
									var data = jsonModel.ButtonOverrides[index];
									this.props.handleSelectButton(data);
								}}
							/>
						}
					</GridItem>
				</GridContainer>

			</div >
		);
	}
}

ButtonOverride.propTypes = {
	classes: PropTypes.object.isRequired,
	jsonModel: PropTypes.object,
	model: PropTypes.object,
	handleChange: PropTypes.func,
	handleBooleanChange: PropTypes.func,
	handleAdd: PropTypes.func,
	handleUpdate: PropTypes.func,
	handleDelete: PropTypes.func,
	handleClear: PropTypes.func,
	handleSelectButton: PropTypes.func
};

export default withArtifex(ButtonOverride, {});
