import { DialogContent, DialogTitle, Slide } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericSelectInput } from "views/Components/Generic";
import LedgerCardCompanyIconDetail from "views/LOS/LedgerCard/LedgerCardCompanyIconDetail";

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

class LedgerCardCompanyIcon extends React.Component {
	constructor(props) {
		super(props);

		this.columns = [
			{
				Header: "Client Name",
				accessor: "ClientName"
			},
			{
				Header: "Logo Count",
				accessor: "IconCount"
			}
		];

		this.uniqueClientId = ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined;

		this.parameterUniqueClientId = {
			UniqueClientId: this.uniqueClientId
		};

		this.renderItemUniqueClient = {};

		this.state = {
			model: {
				UniqueClientId: this.uniqueClientId
			},
			isAddLogoDialogOpen: false
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("LOAN ORIGINATOR SYSTEM");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("Upload Logo");
		}
	}

	ValueChanged = (name, value, data) => {
		this.setState(state => {
			var model = state.model;
			model[name] = value;

			if (name == "UniqueClientId") {
				model["ClientName"] = data.Name;
			}

			return { model };
		});
	}

	RowSelected = (index) => {
		const { list } = this.state;

		this.setState({ model: list[index], selected: index });
	}

	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;

		return this.renderItemUniqueClient;
	}

	AddLogoDialogOpen = () => {
		if (!this.Validate()) {
			return;
		}

		this.setState({ isAddLogoDialogOpen: true });
	}

	AddLogoDialogClose = () => {
		this.setState({ isAddLogoDialogOpen: false });

		this.Search();
	}

	IsFileChangedToFalse = () => {
		this.setState({ isFileChanged: false });
	}

	Search = async () => {
		const { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/lmsapi/v1.0/LoanLedgerCardIcon/Search", model);

		this.setState({ list: result || [] });
	}

	Clear = () => {
		if (this.uniqueClientId != null) {
			this.setState({ list: [], selected: null });
		} else {
			this.setState({ model: {}, list: [], selected: null });
		}
	}

	Validate = () => {
		var { model } = this.state;
		const { showValidationErrors } = this.props;
		var errors = [];

		if (!model.UniqueClientId) {
			errors.push("Client cannot be null.");
		}

		model["IsUniqueClientId"] = !model.UniqueClientId;

		if (errors.length > 0) {
			showValidationErrors(errors);

			return false;
		}

		return true;
	}

	render() {
		const { model, list, selected, isAddLogoDialogOpen } = this.state;
		const { Disabled, ExecuteApiPost, ExecuteApiFileUpload, showConfirmMessageInDelete, showValidationErrors } = this.props;

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},
						{
							Code: "Delete",
							Disabled: Disabled || selected == null,
							OnClick: () => showConfirmMessageInDelete(() => ExecuteApiPost("/lsmapi/v1.0/LoanLedgerCardIcon/Delete", { Id: model.Id }, null, null, null, [this.Clear, this.Search], true))
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						},
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GenericDialog open={isAddLogoDialogOpen} fullScreen onBackdropClick={this.AddLogoDialogClose} TransitionComponent={Transition}>
					<DialogTitle>
						DETAIL OF LOGOS
					</DialogTitle>
					<DialogContent>
						<LedgerCardCompanyIconDetail
							LoanLedgerCardIconId={model.Id}
							UniqueClientId={model.UniqueClientId}
							ClientName={model.ClientName}
							Disabled={Disabled}
							ExecuteApiPost={ExecuteApiPost}
							ExecuteApiFileUpload={ExecuteApiFileUpload}
							showConfirmMessageInDelete={showConfirmMessageInDelete}
							showValidationErrors={showValidationErrors} />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.AddLogoDialogClose}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="">
									<GridContainer>
										<GridItem xs={3}>
											<GenericSelectInput
												All
												CanClear
												Required
												Disabled={this.uniqueClientId}
												Name="UniqueClientId"
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={this.parameterUniqueClientId}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={this.RenderItemUniqueClient}
												DefaultIndex={this.uniqueClientId ? 0 : -1}
												Value={model.UniqueClientId}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={2}>
											<Button size="sm" onClick={this.AddLogoDialogOpen} style={{ marginTop: 8 }}>Detail of Logos</Button>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columns}
													SelectedIndex={selected}
													RowSelected={this.RowSelected}
													IsSorted={false} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

LedgerCardCompanyIcon.propTypes = {
	setAppCenterTitle: PropTypes.func,
	setAppLeftTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	ExecuteApiFileUpload: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default LedgerCardCompanyIcon;