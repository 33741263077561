import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes, { bool, func } from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { ProgramCodes } from "views/Constants/Constant";

class eftDailySettledItems extends Component {
	constructor(props) {
		super(props);
		this.state = {
			model: {
				ReceivedDate: DateHelper.GetDate()
			},
			getDataList: [],
			summaryModel: {},
			isLoading: false
		};
		this.handleChange = this.handleChange.bind(this);
		this.GetData = this.GetData.bind(this);
		this.errorCallback = this.errorCallback.bind(this);
		this.hideAlert = this.hideAlert.bind(this);
		this.showErrorMessage = this.showErrorMessage.bind(this);
		this.successSummaryCallback = this.successSummaryCallback.bind(this);
	}

	componentDidMount() {
		this.props.setAppLeftTitle("EFT Daily Settled Items");
		this.props.setAppCenterTitle("CLEARING & SETTLEMENT");
	}

	handleChange(name, newValue) {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}

	GetData() {
		if (this.state.model.ReceivedDate == null)
			this.showErrorMessage("Received Date can not be empty !!");
		else {
			this.setState({ isLoading: true });
			// "/bankapi/v1.0/ClearingSettlement/GetAllDailySettlementItemsByReceivedDate",
			Proxy.POST(
				"/bankapi/v1.0/BankBmoEFT/SearchEFTClearingDailySettledItems",
				this.state.model,
				this.successSummaryCallback,
				this.errorCallback
			);
		}
	}

	successSummaryCallback(responseData) {
		var summaryModel = { ...this.state.summaryModel };
		if (!responseData.IsSucceeded) {
			this.showErrorMessage(responseData.ErrorDescription);
			return;
		}
		if (responseData.Item == null) {
			this.setState({
				summaryModel: {}, getDataList: [], isLoading: false
			});
		} else {
			summaryModel = responseData.Item.summaryItemResponse == null || undefined ? {} : responseData.Item.summaryItemResponse;
			this.setState({
				summaryModel,
				getDataList: responseData.Item.DailySettledItems == null || undefined ? [] : responseData.Item.DailySettledItems.length < 1 ? [] : responseData.Item.DailySettledItems.map(x => {
					return {
						RecordType: x.RecordType == null ? "" : x.RecordType,
						FileType: x.FileType == null ? "" : x.FileType,
						UniqueClientId: x.UniqueClientId == null ? "" : x.UniqueClientId,
						ValueDate: x.ValueDate == null ? "" : x.ValueDate,
						TransactionType: x.TransactionType == null ? "" : x.TransactionType,
						PayeeAccountNo: x.PayeeAccountNumber == null ? "" : x.PayeeAccountNumber,
						CrossReferenceNumber: x.CrossReferenceNumber == null ? "" : x.CrossReferenceNumber,
						PayeeName: x.PayeeDraweeName == null ? "" : x.PayeeDraweeName,
						Amount: x.Amount == null ? "" : x.Amount,
						TransactionId: x.TransactionId == null ? "" : x.TransactionId
					};
				}), isLoading: false
			});
		}
	}

	errorCallback(error) {
		this.showErrorMessage("An error occurred during the api visit" + error);
	}

	showErrorMessage(message) {
		this.setState({
			isLoading: false,
			alert: <GenericAlert Title={"Error"} Message={message} Type="warning" ShowCancel={false} OnConfirm={() => this.hideAlert()} />
		});
	}

	hideAlert() {
		this.setState({ alert: null });
	}

	render() {
		const { Disabled } = this.props;
		const { model, summaryModel, isLoading } = this.state;
		return (
			<div>
				{this.state.alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.GetData, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<LoadingComponent Show={isLoading} />
				<Card className="no-radius">

					<GenericExpansionPanel Title={"Main Parameters"}>
						<CardBody>
							<GridContainer>
								<GridItem xs={3}>
									<GenericSelectInput
										Name="ClientId"
										LabelText="Client"
										Method="POST"
										Url="/bankapi/v1.0/BankCustomer/Search"
										Parameter={{}}
										DataRoot="Item"
										KeyValueMember="Id"
										RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
										Value={model.ClientId || ""}
										ValueChanged={this.handleChange}
										All
										CanClear />
									<GenericDateInput
										Name="ReceivedDate"
										LabelText="Received Date"
										Value={model == null || undefined ? "" : model.ReceivedDate ? DateHelper.ToDateInputValue(model.ReceivedDate) : ""}
										ValueChanged={this.handleChange}
										MaxDate={DateHelper.GetDate()}
										IncludeTime={false} />
								</GridItem>
								<GridItem xs={1}></GridItem>
								<GridItem xs={8}>
									<Card>
										<CardBody>
											<GridContainer>
												<GridItem xs={5}>
													<GenericTextInput
														Name="TotalNoOfCredit"
														LabelText="Total No. of Credit"
														Value={summaryModel == null ? "0" : summaryModel.TotalNoOfCredit ? summaryModel.TotalNoOfCredit : "0"}
														Disabled={true} />
													<GenericTextInput
														Name="TotalNoOfDebit"
														LabelText="Total No. of Debit"
														Value={summaryModel == null ? "0" : summaryModel.TotalNoOfDebit ? summaryModel.TotalNoOfDebit : "0"}
														Disabled={true} />
												</GridItem>
												<GridItem xs={2}></GridItem>
												<GridItem xs={5}>
													<GenericNumberInput
														Name="CreditTotal"
														LabelText="CreditTotal"
														Value={summaryModel == null ? "0" : summaryModel.CreditTotal ? summaryModel.CreditTotal : "0"}
														Disabled={true}
														Prefix="$" />
													<GenericNumberInput
														Name="DebitTotal"
														LabelText="Debit Total"
														Value={summaryModel == null ? "0" : summaryModel.DebitTotal ? summaryModel.DebitTotal : "0"}
														Disabled={true}
														Prefix="$" />
												</GridItem>
											</GridContainer>
										</CardBody>
									</Card>
								</GridItem>
							</GridContainer></CardBody>
					</GenericExpansionPanel>
				</Card>
				<Card className="no-radius">
					<CardBody>
						<CardHeader>
							<GenericTitle text={"Settlement Transaction Detail"} />
						</CardHeader>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={this.state.getDataList}
									Columns={[
										{
											Header: "Rec. Type",
											accessor: "RecordType"
										},
										{
											Header: "File Type",
											accessor: "FileType"
										},
										{
											Header: "Unique Client Id",
											accessor: "UniqueClientId"
										},
										{
											Header: "Value Date",
											accessor: "ValueDate",
											Cell: row => (
												<div>{Formatter.FormatDate(String(row.value))}</div>
											)
										},
										{
											Header: "Tran Type",
											accessor: "TransactionType"
										},
										{
											Header: "Payee Wallet No.",
											accessor: "PayeeAccountNo"
										},
										{
											Header: "Cross Reference Number",
											accessor: "CrossReferenceNumber"
										},
										{
											Header: "Payee Name",
											accessor: "PayeeName"
										},
										{
											Header: "Amount",
											accessor: "Amount"
										},
										{
											Header: "Trans. ID",
											accessor: "TransactionId"
										}
									]}
									ProgramCode={ProgramCodes.Bank_Programs_EFT} />
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>
			</div>
		);
	}
}

eftDailySettledItems.propTypes = {
	classes: PropTypes.object,
	handleOperationType: PropTypes.func,
	SelectedRowChange: PropTypes.func,
	showQuestionMessage: func,
	setAppLeftTitle: func,
	setAppCenterTitle: func,
	Disabled: bool
};

export default withArtifex(eftDailySettledItems, {});