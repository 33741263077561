import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
// import whyDidYouRender from "@welldone-software/why-did-you-render/dist/no-classes-transpile/umd/whyDidYouRender.min.js";
import samplePageStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { PrivateRoute, withRedux } from "core";
import DateHelper from "core/DateHelper";
import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import indexRoutes from "routes/index.jsx";

class App extends React.Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		Date.prototype.toJSON = function () { return DateHelper.ToMoment(this).format(); };
		// if (process.env.NODE_ENV !== "production") {
		// 	whyDidYouRender(React);
		// 	// whyDidYouRender(React, { include: [/^Generic/] }); // All components starts with Generic
		// }
		document.title = window.Title;
	}

	render() {
		const theme = createMuiTheme({
			palette: { primary: { main: window.PageMainColor } },
			typography: { useNextVariants: true }
		});

		return (
			<div>
				<MuiThemeProvider theme={theme}>
					<HashRouter>
						<Switch>
							{indexRoutes.map((prop, key) => {
								if (prop.private) {
									return <PrivateRoute UserContext={this.props.UserContext} component={prop.component} key={key} />;
								}
								return <Route path={prop.path} component={prop.component} key={key} />;
							})}
						</Switch>
					</HashRouter>
				</MuiThemeProvider>
			</div>
		);
	}
}

export default withRedux(App, samplePageStyles);