import { cardBoxShadow } from "assets/jss/material-dashboard-pro-react.jsx";

const dashboardStyle = {
	main: {
		backgroundColor: "#e4e4e4",
		...cardBoxShadow
	},
	cardBackGround: {
		backgroundColor: "#e4e4e4"
	},
	expansionPanelSummaryExpaned: {
		// marginTop: "-1px !important",
		// marginLeft: "-12px !important"
	},
	expansionPanelSummaryContent: {
		marginTop: "-1px !important",
		marginLeft: "-24px !important"
	},
	expansionPanelDetails: {
		padding: "8px 0px 24px !important"
	},
	circleBoxGrid: {
		width: "100%",
		textAlign: "center"
	},
	circleBox: {
		display: "inline-block",
		marginTop: "24px",
		marginLeft: "auto",
		marginRight: "auto",
		marginBottom: "24px"
	}
};

export default dashboardStyle;
