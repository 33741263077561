import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton
} from "@material-ui/core";
import { primaryColor } from "assets/jss/material-dashboard-pro-react.jsx";
import { Proxy, withArtifex } from "core";
import { ProfileIcon, EditIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import ClientHelper from "core/ClientHelper";
import {
	GenericAlert,
	
	GenericIcon,
	GenericExpansionPanel,
	GenericGrid,
	GenericNumberInput,
	GenericSelectInput,
	GenericTextInput
} from "views/Components/Generic";
import { ProgramCodes } from "views/Constants/Constant.js";
import AccountComponent from "views/Components/AccountComponent";
import CustomerComponent from "views/Components/CustomerComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import ToolbarButton from "views/Components/ToolbarButton";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import Button from "components/CustomButtons/Button.jsx";
import LoadingComponent from "views/Components/LoadingComponent";

class TransactionCodeMap extends React.Component {
	constructor(props) {
		super(props);

		this.emptyModel = {
			Id : null,
			IsRecordValid : null,
			Network : null,
			NetworkId : null,
			Mti : null,
			ProcessingCode : null,
			Transaction : null,
			TransactionId : null,
		};

		this.columns = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<ToolbarButton
							color="primary"
							justIcon
							simple
							size="sm"
							tooltip="Modify"
							onClick={() => { this.handleSelect(row.original, row.index); }}>
							<img src={EditIcon} />
						</ToolbarButton>
					</div>
				),
				sortable: false,
				filterable: false,
				resizable: false,
				width: 80
			},
			{
				Header: "Network",
				accessor: "Network.ParameterDesc"
			},
			{
				Header: "MTI",
				accessor: "Mti"
			},
			{
				Header: "Processing Code",
				accessor: "ProcessingCode"
			},
			{
				Header: "Transaction Code",
				accessor: "Transaction.TxnCode"
			},
			{
				Header: "Transaction Name",
				accessor: "Transaction.TxnName"
			}
		];

		this.state = {
			alert : null,
			list: [],
			model: this.emptyModel,
			isLoading: false
		};
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Switch Transaction Code Map");
		this.props.setAppCenterTitle("System Admin");
	}

	handleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		const initialModel = { ...this.emptyModel };
		if (name === "Network") {
			model.Network = data;
			model.NetworkId = newValue;
		} else if (name === "Mti") {
			model.Mti = newValue;
		} else if (name === "ProcessingCode") {
			model.ProcessingCode = newValue;
		} else if (name === "Transaction") {
			model.Transaction = data;
			model.TransactionId = newValue;
		}
		this.setState({ model });
	};

	handleClear = () => {
		this.setState({
			model: this.emptyModel,
			alert : null,
			list: []
		});
	};

	handleSearch = () => {
		const { model } = this.state;
		const request = {
			NetworkId : model.Network == null ? model.NetworkId : model.Network.Id,
			Mti: model.Mti,
			ProcessingCode: model.ProcessingCode,
			TransactionId : model.Transaction == null ? model.TransactionId : model.Transaction.Id
		};
		Proxy.ExecuteGeneral(
			this, "POST",
			"/prepaidapi/v1.0/TransactionCodeMap/Search",
			request,
			responseData => {
				this.setState({
					list: responseData.Item
				});
			}
		);
	};

	handleSubmit = () => {
		const { model } = this.state;
		const request = {
			IsRecordValid : true,
			Id : model.Id == null ? 0 : model.Id,
			NetworkId : model.Network == null ? 0 : model.Network.Id,
			Mti: model.Mti == null ? null : model.Mti,
			ProcessingCode: model.ProcessingCode == null ? null : model.ProcessingCode,
			TransactionId : model.Transaction == null ? 0 : model.Transaction.Id
		};

		Proxy.ExecuteGeneral(
			this, "POST",
			"/prepaidapi/v1.0/TransactionCodeMap/" + (model.Id == null ? "Insert" : "Update"),
			request,
			responseData => {
				this.setState({
					model : this.emptyModel
				}, this.handleSearch);
			}
		);
	};

	handleDelete = () => {
		const { model } = this.state;
		const request = {
			IsRecordValid : false,
			Id : model.Id == null ? 0 : model.Id,
			NetworkId : model.Network == null ? 0 : model.Network.Id,
			Mti: model.Mti == null ? null : model.Mti,
			ProcessingCode: model.ProcessingCode == null ? null : model.ProcessingCode,
			TransactionId : model.Transaction == null ? 0 : model.Transaction.Id
		};

		Proxy.ExecuteGeneral(
			this, "POST",
			"/prepaidapi/v1.0/TransactionCodeMap/Update",
			request,
			responseData => {
				this.setState({
					model : this.emptyModel
				}, this.handleSearch);
			}
		);
	};

	handleSelect = (data, index) => {
		var clonedData = Object.assign({}, data);
		this.setState({ model : clonedData});
	};

	render() {
		const { Disabled } = this.props;
		const { model, alert, list, isLoading } = this.state;
		return (
			<div>
				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar
					ButtonList={[
						{ Code: "Search", OnClick: this.handleSearch, Disabled: Disabled },
						{ Code: "Submit", OnClick: this.handleSubmit, Disabled: (Disabled || model.Id != null) },
						{ Code: "Update", OnClick: this.handleSubmit, Disabled: (Disabled || model.Id == null) },
						{ Code: "Delete", OnClick: this.handleDelete, Disabled: (Disabled || model.Id == null) },
						{ Code: "Clear", OnClick: this.handleClear, Disabled: Disabled }
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After}
				/>
				<GridContainer spacing={16}>
					<GridItem md={12} xs={12}>
						<Card>
							<CardBody>
								<GenericExpansionPanel>
									<GridContainer>
										<GridItem md={4} xs={12}>
											<GridItem>
												<ParameterComponent
													Name="Network"
													LabelText="Network"
													ParameterCode="SwitchNetworkType"
													Value={model.Network == null ? model.NetworkId : model.Network.Id}
													ValueChanged={this.handleChange}
													All />
											</GridItem>
										</GridItem>
										<GridItem md={4} xs={12}>
											<GridItem>
												<GenericSelectInput
													Name="Transaction"
													LabelText="Transaction"
													Value={model.Transaction == null ? model.TransactionId : model.Transaction.Id}
													ValueChanged={this.handleChange}
													KeyValueMember="Id"
													TextValueMember="TxnName"
													Method="GET"
													Url="/prepaidapi/v1.0/TransactionCode/GetAll"
													DataRoot="Item"
													All />
												</GridItem>
										</GridItem>
										<GridItem md={4} xs={12}>
											<GridItem>
												<GenericNumberInput
													Name="Mti"
													LabelText="MTI"
													MaxLength={6}
													NoFormatting = {true}
													Value={model.Mti}
													ValueChanged={this.handleChange} />
											</GridItem>
										</GridItem>
										<GridItem md={4} xs={12}>
											<GridItem>
												<GenericTextInput
													Name="ProcessingCode"
													LabelText="Processing Code"
													Value={ model.ProcessingCode }
													ValueChanged={this.handleChange} />
											</GridItem>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card>
							<CardBody>
								<GridContainer>
									<GridItem md={12} xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid Data={list} Columns={this.columns} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		);
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })}
				/>
			)
		});
	};

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					MessageNode={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })}
				/>
			)
		});
	};

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					ShowCancel={true}
					OnCancel={() => this.setState({ alert: "" })}
					OnConfirm={onConfirm}
				/>
			)
		});
	};
}

TransactionCodeMap.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	handleChange: PropTypes.func,
	menuId: PropTypes.number,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default withArtifex(TransactionCodeMap, {});
