import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import {  GenericExpansionPanel,  GenericGrid,  GenericDateInput, GenericSelectInput} from "views/Components/Generic";
import DateHelper from "core/DateHelper";
import {  ProgramCodes, MenuCodes,} from "views/Constants/Constant.js";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType } from "views/Constants/Constant";
import ClientHelper from "core/ClientHelper";
import CustomerComponent from "views/Components/CustomerComponent";

class PaymentInterestTransactions extends React.Component {
  constructor(props) {
    super(props);
    let IsSwitchReport = this.props.MenuCode == MenuCodes.Menu_SwitchReport;
    this.emptyObject = {};
    this.sortedName = { Member: "Name" };

    this.emptyObject = {};
    this.sortedName = { Member: "Name" };
    this.initialModel = {
      Id: 0,
      UniqueClientId: ClientHelper.IsClient()
        ? ClientHelper.GetClientRowId()
        : undefined,
      Customer: null,
      DefaultAccount: null,
      PaymentStatusId: null,
      ToDate: null,
      FromDate: null,
      CardTokenNumber: null,
      CustomerNumber: null,
    };

    this.columns = [
		{
			Header: "Settlement Date",
			accessor: "SettlementDate",
			type: GridColumnType.DateTime,
		 },
      {
        Header: "Wallet Name",
        accessor: "AccountName",
      },
      {
        Header: "Customer Name",
        accessor: "CustomerName",
      },
      {
        Header: "Customer Number",
        accessor: "CustomerNumber",
      },
      {
        Header: "Is Processed",
        accessor: "IsProcessed",
        type: GridColumnType.YesNo,
      },

      {
        Header: "Settlement Status",
        accessor: "SettlementStatus",
      },

      {
        Header: "Card Masked Number",
        accessor: "CardTokenNumber",
        //   show: !IsSwitchReport,
      },

      {
        Header: "Transactions Amount",
        accessor: "TransactionsAmount",
        type: GridColumnType.Money,
        ColumnType: GridColumnType.Money,
      },
    ];

    this.LMSPaymentStatus = {
      ParameterCode: "LMSPaymentStatus",
    };
    this.LMSDailyTransactionType = {
      ParameterCode: "LMSDailyTransactionType",
    };

    this.state = {
      // model: {},
      model: this.initialModel,
      isLoading: false,
      checkedAccounts: [],
      ClientTypeParameter: {},
      panelTransactionIsActive: false,
      list: [],
      isBackOffice: ClientHelper.IsBackOffice(),
    };
  }

  componentDidMount() {
    const { setAppCenterTitle, setAppLeftTitle } = this.props;

    if (setAppCenterTitle) {
      setAppCenterTitle("LOAN MANAGEMENT SYSTEM");
    }
    if (setAppLeftTitle) {
      setAppLeftTitle("Payment Transactions");
    }
  }

  ValueChanged = (name, value) => {
    const model = { ...this.state.model };
    model[name] = value;

    this.setState({ model });
  };

  Search = async () => {
    if (!this.Validate()) return;
    const { ExecuteApiPost } = this.props;
    const model = { ...this.state.model };
    const request = {
      FromDate: model.FromDate,
      ToDate: model.ToDate,
      CustomerNumber: model.Customer == null ? null : model.Customer.CustomerNumber,
      UniqueClientId: model.UniqueClientId,
    };
    var result = await ExecuteApiPost(
      "/lmsapi/v1.0/Statement/PaymentTransactionDataList",
      request,
      null,
      null,
      null
    );

    if (result != null) {
      this.setState({ list: result || [] });
    }
  };

  Clear = () => {
    this.setState({ model: {}, list: [], selected: null, checkedAccounts: [] });
  };

  Validate = () => {
    var { model } = this.state;
    const { showValidationErrors } = this.props;
    var errors = [];
	     if (errors.length > 0) {
      showValidationErrors(errors);

      return false;
    }

    return true;
  };

  GetRenderItemUniqueClient = (x) => {
    this.renderItemUniqueClient = `${x.Name} - ${x.UniqueClientId}`;
    return this.renderItemUniqueClient;
  };


handleChange = (name, newValue, data) => {
	const model = {
	  ...this.state.model,
	};
	model[name] = newValue;
	this.setState({ model });
 };
  handleChangeForCustomer= (name, newValue) => {
	const model = { ...this.state.model };
      const initialModel = { ...this.initialModel };
      if (name === "Customer") {
         if (newValue != null) {
            model.Customer = newValue;
            if (newValue.Accounts != null && newValue.Accounts.length > 0) {
               model.CustomerNumber = newValue.Accounts[0].CustomerNumber;
            } else {
               model.CustomerNumber = null;
            }
         } else {
            model.Customer = initialModel.Customer;
         }
      }
	this.setState({ model });
};
  HandleClear = () => {
    this.setState({
      model: this.initialModel,
      filterModel: {},
      selected: null,
      list: [],
    });
  };

  FillCustomer = (customer) => {
    this.handleChange("Customer", customer);
  };
  render() {
    const {
      model,
      model2,
      list,
      panelTransactionIsActive,
      isBackOffice,
    } = this.state;
    const { Disabled } = this.props;
	 var IsClient = ClientHelper.IsClient();

    return (
      <div>
        <ButtonToolbar
          ButtonList={[
            {
              Code: "Search",
              Disabled: Disabled,
              OnClick: this.Search,
            },
            {
              Code: "Clear",
              Disabled: Disabled,
              OnClick: this.HandleClear,
            },
          ]}
          menuId={this.props.menuId}
          ApprovalData={this.props.ApprovalData}
          After={this.props.After}
        />
        <LoadingComponent Show={this.state.isLoading} />

        <GridContainer spacing={16}>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <GenericExpansionPanel Title="Filter">
                  <GridContainer>
						<GridItem xs={2}>
							<GenericSelectInput
								Name="UniqueClientId"
								LabelText="Client"
								Method="POST"
								Url="/bankapi/v1.0/BankCustomer/Search"
								Parameter={{}}
								DataRoot="Item"
								KeyValueMember="Id"
								RenderItem={(d) => `${d.UniqueClientId} - ${d.Name}`}
								Value={model.UniqueClientId || ""}
								ValueChanged={this.ValueChanged}
								CanClear
								All
								Required
								Disabled={IsClient}
								DefaultIndex={IsClient ? 0 : -1}
							/>
						</GridItem>
						<GridItem xs={2}>
						  <CustomerComponent
                     LabelMd={4}
                      CustomerNumber={model.Customer === null ? null : model.Customer.CustomerNumber}
                      UniqueClientId={model.UniqueClientId}
                      FillCallback={customer => { this.handleChange("Customer", customer); }}
                      Programs={[ProgramCodes.Prepaid]}
							 Disabled={model.UniqueClientId == undefined} />
                    </GridItem>
						   <GridItem xs={2}>
                      <GenericDateInput
                        Name="FromDate"
                        LabelText="From Date"
                        Value={model.FromDate}
                        ValueChanged={this.handleChange}
                        MaxDate={model.ToDate || DateHelper.GetDateTimeLocal()}
                        IncludeTime={false}
                        Utc
                      />
                    </GridItem>
                    <GridItem xs={2}>
                      <GenericDateInput
                        Name="ToDate"
                        LabelText="To Date"
                        Value={model.ToDate}
                        ValueChanged={this.handleChange}
                        MinDate={model.FromDate}
                        IncludeTime={false}
                        Utc
                      />
                    </GridItem>
						 
                  </GridContainer>
                </GenericExpansionPanel>
              </CardBody>
            </Card>

            <Card className="no-radius">
              <CardBody>
                <GenericExpansionPanel
                  IsActive
                  Title="Payment Transactions"
                >
                  <GridContainer>
                    <GridItem xs={12}>
                      <GridContainer spacing={16}>
                        <GridItem xs={12}>
                          <GenericGrid
                            Data={list}
                            Columns={this.columns}
                            ShowPagination={true}
                            PageSize={100}
                            IsSorted={false}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </GenericExpansionPanel>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

PaymentInterestTransactions.propTypes = {
  setAppLeftTitle: PropTypes.func,
  setAppCenterTitle: PropTypes.func,
  ExecuteApiPost: PropTypes.func,
  Disabled: PropTypes.bool,
  menuId: PropTypes.any,
  ApprovalData: PropTypes.any,
  After: PropTypes.any,
};

export default PaymentInterestTransactions;
