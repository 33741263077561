import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericDateInput, GenericEmailInput, GenericExpansionPanel, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ClientHelper from "core/ClientHelper.js";
import Formatter from "core/Formatter";

class LoanApplicantInformation extends Component {
	constructor(props) {
		super(props);

		this.state = {
		};
		this.MaritalStatusParameterCode = { ParameterCode: "LOSMaritalStatus" };
		this.OccupationParameterCode = { ParameterCode: "LOSOccupation" };
		this.YesNoParameterCode = { ParameterCode: "LOSYesNo" };
		this.LOSPhoneGivenParameterCode = { ParameterCode: "LOSPhoneGiven" };

		this.LOSDownpaymentParameterCode = { ParameterCode: "LOSDownpayment" };
		this.LOSInterbankTimeParameterCode = { ParameterCode: "LOSInterbankTime" };
		this.LOSSalaryParameterCode = { ParameterCode: "LOSSalary" };
		this.LOSHousholdInParameterCode = { ParameterCode: "LOSHousholdIn" };
		this.LOSEmplTimeParameterCode = { ParameterCode: "LOSEmplTime" };
		this.LOSTimePerParameterCode = { ParameterCode: "LOSTimePer" };
		this.LOSAddrTimeParameterCode = { ParameterCode: "LOSAddrTime" };

		this.LOSResidentalStatusParameterCode = { ParameterCode: "LOSResidentalStatus" };
		this.LOSEduLevelParameterCode = { ParameterCode: "LOSEduLevel" };
		this.LanguageParameterCode = { ParameterCode: "Language" };
		this.CountryParameterCode = { ParameterCode: "Country" };
		this.IdentityVerifyingPersonParameterCode = { ParameterCode: "IdentityVerifyingPerson" };
		this.parameterIdentificationMethod = { ParameterCode: "IdentificationMethod" };
		this.parameterIdentityType = { ParameterCode: "IdentityType" };

		this.ParameterIdentificationMethod = {
			ParameterCode: "IdentificationMethod",
			ParameterValue2: "Active"
		}; 

		this.ParameterApplicantStatus = {
			ParameterCode: "LOSApplicantStatus"
		};

		this.ParameterIntendedUse = {
			ParameterCode: "IntendedUse"
		};

		this.ParameterIdentityType = {
			ParameterCode: "IdentityType2",
			ParameterValue2: "PrimaryId"
		};

		this.sortedParameterDesc = {
			Member: "ParameterDesc"
		};

		this.ParameterProvincialGovermentPhotoId = {
			ParameterCode: "ProvincialGovermentPhotoId"
		};

		this.ParameterIdentitySecondary = {
			ParameterCode: "IdentityType2"
		};

		this.ParameterCreditAgency = {
			ParameterCode: "CreditAgency"
		};

		this.ParameterPrimarySourceForDualProcessId = {
			ParameterCode: "DocumentForDualProcessIdentification",
			ParameterValue2: "PrimaryDocument"
		};

		this.ParameterSecondarySourceForDualProcessId = {
			ParameterCode: "DocumentForDualProcessIdentification",
		};

		this.sortedOrderIndex = { Member: "OrderIndex" };

		this.maxLength20 = { maxLength: 20 };
		this.maxLength100 = { maxLength: 100 };
		this.marginLeft2 = { marginLeft: 2 };
		this.ParameterMaxLength30 = { maxLength: 30 };
		this.ParameterMaxLength64 = { maxLength: 64 };
		this.emptyObject = {};

		this.renderItemPhoneCode = {};
	}

	componentDidMount() {
		
	}

	RenderItemPhoneCode = (d) => {
		this.renderItemPhoneCode = "+" + d.Code + " (" + d.Name + ")";
		return this.renderItemPhoneCode;
	}

	IsNullOrEmpty(value) {
		return (value == null || value === "" || value === '' 
									 || value === '""' || value === '""');
	}


	render() {
		const { ApplicantModel, Title, Disabled, IsActive, Name } = this.props;
		const { isLoading, alert } = this.state;
		const model = ApplicantModel;

		
		return (
			<div>


				<LoadingComponent Show={isLoading} />

				<GenericExpansionPanel
					IsActive={IsActive}
					Title={Title}
					Name={Name}
					ValueChanged={this.props.HandleChangeFilterPanel}>
					<GridContainer
						direction="row"
						justify="flex-start"
						alignItems="stretch">
						<GridItem xs={12}>
							<GridContainer>
								{alert}
								<GridItem xs={12}>

									<GridContainer direction="row" justify="flex-start" alignItems="flex-start">
										<GridItem xs={6}>
											<GenericTitle text={"General Information"} />
											<div>
												<GridItem>
													<GenericSelectInput
														Name="StatusId"
														LabelText="Applicant Status"
														Method="POST"
														Url="/coreapi/v1.0/Parameter/Search"
														Parameter={this.ParameterApplicantStatus}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="ParameterDesc"
														Value={model.StatusId}
														Disabled />
												</GridItem>
												<GridItem>
													<GenericSelectInput
														Name="IntendedUseId"
														LabelText="Intended Use"
														Method="POST"
														Url="/coreapi/v1.0/Parameter/Search"
														Parameter={this.ParameterIntendedUse}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="ParameterDesc"
														Value={model.IntendedUseId}
														Disabled={Disabled}																
														RenderItem={d => `${d.ParameterValue} (${d.ParameterDesc})`}/>
												</GridItem>
												<GridItem >
													<GenericTextInput
														Name="FirstName"
														inputProps={this.ParameterMaxLength30}
														IsText
														LabelText="First Name"
														Value={model.FirstName}
														Disabled={Disabled}
													/>
												</GridItem>
												<GridItem >
													<GenericTextInput
														Name="MiddleName"
														inputProps={this.ParameterMaxLength30}
														IsText
														LabelText="Middle Name"
														Value={model.MiddleName}
														Disabled={Disabled}
													/>
												</GridItem>
												<GridItem >
													<GenericTextInput
														Name="LastName"
														inputProps={this.ParameterMaxLength30}
														IsText
														LabelText="Last Name"
														Value={model.LastName}
														Disabled={Disabled}
													/>
												</GridItem>
												<GridItem>
													<GenericDateInput
														Name="BirthDate"
														IsPast={true}
														LabelText="Date of Birth"
														Value={model.BirthDate ? DateHelper.ToMoment(model.BirthDate) : ""}
														Disabled={Disabled}
														IncludeTime={false}
													/>
												</GridItem>
												<GridItem >
													<GenericSelectInput
														Name="GenderId"
														LabelText="Gender"
														Method="POST"
														Url="/coreapi/v1.0/Parameter/Search"
															Parameter={{
															ParameterCode: "Gender",
															ParameterValue: "",
															ParameterDesc: ""
															}}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="ParameterDesc"
														Value={model.GenderId}													
														Disabled={Disabled} />
												</GridItem>	
												<GridItem>
													<GenericSelectInput
														Name="OccupationId"
														LabelText="Occupation"
														Method="POST"
														Url="/coreapi/v1.0/Parameter/Search"
														Parameter={{ParameterCode: "Occupation"}}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="ParameterDesc"
														Value={model.OccupationId}														
														Disabled={Disabled} />
												</GridItem>
												{this.props.RetiredOrUnemployedIDs.includes(model.OccupationId) 
													&&							
														<GridItem>
															<GenericSelectInput
																Name="PriorOccupationId"
																LabelText="Prior Occupation"
																Method="POST"
																Url="/coreapi/v1.0/Parameter/Search"
																Parameter={{ParameterCode: "Occupation"}}
																DataRoot="Item"
																KeyValueMember="Id"
																TextValueMember="ParameterDesc"
																Value={model.PriorOccupationId}
																Disabled={Disabled} />
														</GridItem>
												}
											</div>
										</GridItem>
										<GridItem xs={6}>
											<GenericTitle text={"ID Verification"} />
											<div>
												
												<GridItem>
													<GenericSelectInput
														Name="IdentificationMethodId"
														LabelText="Identification Method"
														Method="POST"
														Url="/coreapi/v1.0/Parameter/Search"
														Parameter={this.ParameterIdentificationMethod}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="ParameterDesc"
														Value={model.IdentificationMethodId}
														Disabled={Disabled} />
												</GridItem>

												{
													(this.props.IdentificationMethodIds.length > 0) &&
													 (this.props.IdentificationMethodIds.find(x=>x.Value=="I").Id == model.IdentificationMethodId) 
														&&
															<GridItem>
																<GridItem xs={4}>
																	<br/>
																	<b>Primary Identification</b>
																</GridItem>
																<GridItem>
																	<GenericSelectInput
																		Name="IdentityTypeId"
																		LabelText="ID Type"
																		Method="POST"
																		Url="/coreapi/v1.0/Parameter/Search"
																		Parameter={this.ParameterIdentityType}
																		DataRoot="Item"
																		KeyValueMember="Id"
																		TextValueMember="ParameterDesc"
																		Value={model.IdentityTypeId}
																		Disabled={Disabled}
																		Sorted={this.sortedParameterDesc} />
																</GridItem>
																{this.props.SpecificIdentityTypesIds.includes(model.IdentityTypeId) &&
																	<GridItem>
																		<GenericSelectInput
																			Name="PrimaryProvincialIdentityTypeId"
																			LabelText="Provincial Id Type"
																			Method="POST"
																			Url="/coreapi/v1.0/Parameter/Search"
																			Parameter={this.ParameterProvincialGovermentPhotoId}
																			DataRoot="Item"
																			KeyValueMember="Id"
																			TextValueMember="ParameterDesc"
																			Sorted={this.sortedParameterDesc}
																			Value={model.PrimaryProvincialIdentityTypeId}
																			Disabled={Disabled}  />																	
																	</GridItem>
																}
																<GridItem>
																	<GenericTextInput
																		Name="IdentityPlaceOfIssue"
																		LabelText="ID Place of Issue"
																		Value={model.IdentityPlaceOfIssue}															
																		Disabled={Disabled} />
																</GridItem>
																<GridItem>
																	<GenericTextInput
																		Name="IdentityNumber"
																		LabelText="ID Number"
																		Value={model.IdentityNumber}
																		Disabled={Disabled}
																		inputProps={this.maxLength20} />
																</GridItem>
																<GridItem>
																	<GenericDateInput
																		Utc
																		Name="IdentityExpireDate"
																		LabelText="ID Exp Date"
																		MinDate={DateHelper.GetDate()}
																		Value={model.IdentityExpireDate 
																				?? Formatter.FormatDateUtc(model.IdentityExpireDate)}																	
																		Disabled={Disabled}																	
																		IncludeTime={false} />
																</GridItem>
																<GridItem>
																	<GenericTextInput
																		Name="IdentityVerifiedBy"
																		LabelText="Veryfied By"
																		Value={model.IdentityVerifiedBy}																		
																		Disabled={Disabled}
																		inputProps={this.maxLength20} />
																</GridItem>
																<GridItem>
																	<GenericDateInput
																		Name="IdentityVerificationDate"
																		LabelText="Verification Date"
																		Value={model.IdentityVerificationDate 
																				?? DateHelper.ToDateInputValue(model.IdentityVerificationDate)}																			
																		Disabled={Disabled} 	/>
																</GridItem>
																<GridItem xs={5}>
																	<br/>
																	<b>Secondary Identification</b>
																</GridItem>
																<GridItem>
																	<GenericSelectInput
																		Name="SecondaryIdentityTypeId"
																		LabelText="Identification Type 2"
																		Method="POST"
																		Url="/coreapi/v1.0/Parameter/Search"
																		Parameter={this.ParameterIdentitySecondary}
																		DataRoot="Item"
																		KeyValueMember="Id"
																		TextValueMember="ParameterDesc"
																		Sorted={this.sortedParameterDesc}
																		Value={model.SecondaryIdentityTypeId}																				
																		Disabled={Disabled}/>										
																</GridItem>
																{this.props.SpecificIdentityTypesIds.includes(model.SecondaryIdentityTypeId) &&
																		<GridItem>
																			<GenericSelectInput
																				Name="SecondaryProvincialIdentityTypeId"
																				LabelText="Provincial Id Type"
																				Method="POST"
																				Url="/coreapi/v1.0/Parameter/Search"
																				Parameter={this.ParameterProvincialGovermentPhotoId}
																				DataRoot="Item"
																				KeyValueMember="Id"
																				TextValueMember="ParameterDesc"
																				Sorted={this.sortedParameterDesc}
																				Value={model.SecondaryProvincialIdentityTypeId}
																				Disabled={Disabled} />																	
																		</GridItem>
																}
																<GridItem>
																	<GenericTextInput
																		Name="SecondaryIdentityPlaceOfIssue"
																		LabelText="ID Place of Issue"
																		Value={model.SecondaryIdentityPlaceOfIssue}																		
																		Disabled={Disabled}									
																		inputProps={this.ParameterMaxLength30} />
																</GridItem>
																<GridItem>
																	<GenericTextInput
																		Name="SecondaryIdentityNumber"
																		LabelText="ID No."
																		Value={model.SecondaryIdentityNumber}
																		Disabled={Disabled}									
																		inputProps={this.maxLength20} />
																</GridItem>	
																<GridItem>
																	<GenericDateInput
																		Name="SecondaryIdentityExpireDate"
																		LabelText="ID Exp Date"
																		Value={model.SecondaryIdentityExpireDate 
																				?? DateHelper.ToDateInputValue(model.SecondaryIdentityExpireDate) }
																		IsFuture={true}
																		Disabled={Disabled} />
																</GridItem>		
																<GridItem>
																	<GenericTextInput
																		Name="SecondaryIdentityVerifiedBy"
																		LabelText="Verified By"
																		Value={model.SecondaryIdentityVerifiedBy}																				
																		Disabled={Disabled}
																		inputProps={this.maxLength20} />
																</GridItem>	
																<GridItem>
																	<GenericDateInput
																		Name="SecondaryIdentityVerificationDate"
																		LabelText="Vwerification Date"
																		Value={model.SecondaryIdentityVerificationDate 
																				?? DateHelper.ToDateInputValue(model.SecondaryIdentityVerificationDate)}																				
																		IsFuture={true}
																		Disabled={Disabled} />
																</GridItem>			

															</GridItem>											
												}

												{	(this.props.IdentificationMethodIds.length > 0) &&
													(this.props.IdentificationMethodIds.find(x=>x.Value=="P").Id == model.IdentificationMethodId) &&
															<GridItem>
																<GridItem xs={5}>
																	<br />
																	<b>Credit File Information</b>
																</GridItem>
																<GridItem>
																	<GenericSelectInput
																		Name="CreditAgencyId"
																		LabelText="Credit Agency"
																		Method="POST"
																		Url="/coreapi/v1.0/Parameter/Search"
																		Parameter={this.ParameterCreditAgency}
																		DataRoot="Item"
																		KeyValueMember="Id"
																		TextValueMember="ParameterDesc"
																		Sorted={this.sortedParameterDesc}
																		Value={model.CreditAgencyId}																			
																		Disabled={Disabled}/>										
																</GridItem>
																<GridItem>
																	<GenericTextInput
																		Name="CreditFileNumber"
																		LabelText="Credit File No"																	
																		Value={model.CreditFileNumber}																			
																		Disabled={Disabled}																				
																		inputProps={this.maxLength20} />
																</GridItem>
																<GridItem>
																	<GenericDateInput
																		Name="CreditFileCompletionDate"
																		LabelText="Completion Date"
																		Value={model.CreditFileCompletionDate 
																				?? DateHelper.ToDateInputValue(model.CreditFileCompletionDate)}																																							
																		Disabled={Disabled} />
																</GridItem>
															</GridItem>
												}

												{	(this.props.IdentificationMethodIds.length > 0) &&
													(this.props.IdentificationMethodIds.find(x=>x.Value=="D").Id == model.IdentificationMethodId) &&
															<GridItem>
																<GridItem xs={5}>
																	<br/>
																	<b>Primary Document</b>
																</GridItem>
																<GridItem>
																	<GenericSelectInput
																		Name="PrimaryDocumentId"
																		LabelText="Document"
																		Method="POST"
																		Url="/coreapi/v1.0/Parameter/Search"
																		Parameter={this.ParameterPrimarySourceForDualProcessId}
																		DataRoot="Item"
																		KeyValueMember="Id"
																		TextValueMember="ParameterDesc"																			
																		Sorted={this.sortedParameterDesc}
																		Value={model.PrimaryDocumentId}																			
																		Disabled={Disabled}/>										
																</GridItem>
																<GridItem>
																	<GenericDateInput
																		Name="PrimaryDocumentVerificationDate"
																		LabelText="Verification Date"
																		Value={model.PrimaryDocumentVerificationDate 
																			?? DateHelper.ToDateInputValue(model.PrimaryDocumentVerificationDate) }																		
																		Disabled={Disabled} />
																</GridItem>
																<GridItem>
																	<GenericTextInput
																		Name="PrimaryDocumentNumber"
																		LabelText="Associated No"
																		Value={model.PrimaryDocumentNumber}																			
																		Disabled={Disabled}																				
																		inputProps={this.maxLength20} />
																</GridItem>
																<GridItem>
																	<GenericTextInput
																		Name="PrimaryDocumentReviewedBy"
																		LabelText="Reviewed By"
																		Value={model.PrimaryDocumentReviewedBy}																			
																		Disabled={Disabled}																		
																		inputProps={this.maxLength20} />
																</GridItem>
																<GridItem xs={5}>
																	<br/>
																	<b>Secondary Document</b>
																</GridItem>
																<GridItem>
																	<GenericSelectInput
																		Name="SecondaryDocumentId"
																		LabelText="Document"
																		Method="POST"
																		Url="/coreapi/v1.0/Parameter/Search"
																		Parameter={this.ParameterSecondarySourceForDualProcessId}
																		DataRoot="Item"
																		KeyValueMember="Id"
																		TextValueMember="ParameterDesc"
																		Sorted={this.sortedParameterDesc}
																		Value={model.SecondaryDocumentId}																			
																		Disabled={Disabled}/>										
																</GridItem>
																<GridItem>
																	<GenericDateInput
																		Name="SecondaryDocumentVerificationDate"
																		LabelText="Verification Date"
																		Value={model.SecondaryDocumentVerificationDate 
																				?? DateHelper.ToDateInputValue(model.SecondaryDocumentVerificationDate)}																				
																		Disabled={Disabled} />
																</GridItem>
																<GridItem>
																	<GenericTextInput
																		Name="SecondaryDocumentNumber"
																		LabelText="Associated No"
																		Value={model.SecondaryDocumentNumber}																			
																		Disabled={Disabled}
																		inputProps={this.maxLength20} />
																</GridItem>
																<GridItem>
																	<GenericTextInput
																		Name="SecondaryDocumentReviewedBy"
																		LabelText="Reviewed By"
																		Value={model.SecondaryDocumentReviewedBy}																			
																		Disabled={Disabled}
																		inputProps={this.maxLength20} />
																</GridItem>

															</GridItem>
												}										
												
												<GridItem>
													<GenericSelectInput
														Name="CountryOfCitizenShipId"
														LabelText="Country of Citizenship"
														Method="POST"
														Url="/coreapi/v1.0/Country/GetAll"
														Parameter={this.CountryParameterCode}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="Name"
														Value={model.CountryOfCitizenShipId}
														Disabled={Disabled} />
												</GridItem>
												<GridItem>
													<GenericSelectInput
														Name="CountryOfBirthId"
														LabelText="Country of Birth"
														Method="POST"
														Url="/coreapi/v1.0/Country/GetAll"
														Parameter={this.CountryParameterCode}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="Name"
														Value={model.CountryOfBirthId}
														Disabled={Disabled}/>
												</GridItem>
												<GridItem>
													<GenericSelectInput
														Name="CountryOfResidentId"
														LabelText="Country of Resident"
														Method="POST"
														Url="/coreapi/v1.0/Country/GetAll"
														Parameter={this.CountryParameterCode}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="Name"
														Value={model.CountryOfResidentId}
														Disabled={Disabled} />
												</GridItem>
											</div>
										</GridItem>
									</GridContainer>
									<GridContainer direction="row" justify="flex-start" alignItems="flex-start">
										<GridItem xs={6}>
											<GenericTitle text={"Communication Information"} />
											<div>
												<GridItem>
													<GenericEmailInput
														Name="Email"
														LabelText="E-Mail"
														inputProps={this.ParameterMaxLength64}
														Value={model.Email}
														Disabled={Disabled} />
												</GridItem>
												<GridItem>
													<GridContainer>
														<GridItem xs={8}>
															<GenericSelectInput
																Name="MobilePhoneCountryCodeId"
																LabelText="Phone Number"
																TitleLabel="Country Code"
																LabelMd={6}
																Method="POST"
																Url="/coreapi/v1.0/Country/GetAll"
																Parameter={this.emptyObject}
																DataRoot="Item"
																KeyValueMember="Id"
																TextValueMember={"Code"}
																Value={model.MobilePhoneCountryCodeId}
																Disabled={Disabled}
																Sorted={{ Member: "OrderIndex" }}
																RenderItem={d => "+" + d.Code + " (" + d.Name + ")"} 	/>
														</GridItem>
														<GridItem xs={4} style={{ paddingRight: 2 + "px !important" }}>
															<GenericNumberInput
																IsPhone
																Name="MobilePhoneNumber"
																Format="(###) ### ####"
																InputStyle={this.marginLeft2}
																LabelMd={0}
																Value={model.MobilePhoneNumber}
																Disabled={Disabled}
																MaxLength={10} />
														</GridItem>
													</GridContainer>
												</GridItem>
												<GridItem>
													<GridContainer>
														<GridItem xs={8}>
															<GenericSelectInput
																Name="OfficePhoneCountryCodeId"
																LabelText="Office Phone"
																TitleLabel="Country Code"
																LabelMd={6}
																Method="POST"
																Url="/coreapi/v1.0/Country/GetAll"
																Parameter={this.emptyObject}
																DataRoot="Item"
																KeyValueMember="Id"
																TextValueMember={"Code"}
																Value={model.OfficePhoneCountryCodeId}
																Disabled={Disabled}
																Sorted={{ Member: "OrderIndex" }}
																RenderItem={d => "+" + d.Code + " (" + d.Name + ")"} />
														</GridItem>
														<GridItem xs={4} style={{ paddingRight: 2 + "px !important" }}>
															<GenericNumberInput
																IsPhone
																Name="OfficePhoneNumber"
																Format="(###) ### ####"
																InputStyle={this.marginLeft2}
																LabelMd={0}
																Value={model.OfficePhoneNumber}
																Disabled={Disabled}
																MaxLength={10} />
														</GridItem>
													</GridContainer>
												</GridItem>
												<GridItem>
													<GridContainer>
														<GridItem xs={8}>
															<GenericSelectInput
																Name="HomePhoneCountryCodeId"
																LabelText="Home Phone"
																TitleLabel="Country Code"
																LabelMd={6}
																Method="POST"
																Url="/coreapi/v1.0/Country/GetAll"
																Parameter={this.emptyObject}
																DataRoot="Item"
																KeyValueMember="Id"
																TextValueMember={"Code"}
																Value={model.HomePhoneCountryCodeId}
																Disabled={Disabled}
																Sorted={{ Member: "OrderIndex" }}
																RenderItem={d => "+" + d.Code + " (" + d.Name + ")"} />
														</GridItem>
														<GridItem xs={4} style={{ paddingRight: 2 + "px !important" }}>
															<GenericNumberInput
																IsPhone
																Name="HomePhoneNumber"
																Format="(###) ### ####"
																InputStyle={this.marginLeft2}
																LabelMd={0}
																Value={model.HomePhoneNumber}
																Disabled={Disabled}
																MaxLength={10} />
														</GridItem>
													</GridContainer>
												</GridItem>
												<GridItem >
													<GenericSelectInput
														Name="HasEmailNotificationId"
														LabelText="E-Mail Notification"
														Method="POST"
														Url="/coreapi/v1.0/Parameter/Search"
														Parameter={this.YesNoParameterCode}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="ParameterDesc"
														Value={model.HasEmailNotificationId}
														Disabled={Disabled} />
												</GridItem>
												<GridItem >
													<GenericSelectInput
														Name="HasTextNotificationId"
														LabelText="Text Notification"
														Method="POST"
														Url="/coreapi/v1.0/Parameter/Search"
														Parameter={this.YesNoParameterCode}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="ParameterDesc"
														Value={model.HasTextNotificationId}
														Disabled={Disabled} />
												</GridItem>
												<GridItem >
													<GenericSelectInput
														Name="LanguageId"
														LabelText="Language"
														Method="POST"
														Url="/coreapi/v1.0/Parameter/Search"
														Parameter={this.LanguageParameterCode}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="ParameterDesc"
														Value={model.LanguageId}
														Disabled={Disabled} />
												</GridItem>
												<GridItem>
													<div></div>
												</GridItem>

											</div>

										</GridItem>
										<GridItem xs={6}>
											<GenericTitle text={"Address Information"} />
											<div>
												<GridItem>
													<GenericTextInput
														Name="Address1"
														LabelText="Address Line 1"
													//	Id="street-address"
														Value={model.Address1}
														Disabled={Disabled}
														inputProps={this.maxLength100} />
												</GridItem>
												<GridItem>
													<GenericTextInput
														Name="Address2"
														// Id="street-address2"
														LabelText="Address Line 2"
														Value={model.Address2}
														Disabled={Disabled}
														inputProps={this.maxLength100} />
												</GridItem>
												<GridItem>
													<GenericTextInput
														Name="ZipCode"
														LabelText="Postal Code / Zip Code"
												//		Id="postcode"
														Value={model.ZipCode}
														Disabled={Disabled}
														inputProps={this.maxLength20} />
												</GridItem>
												<GridItem>
													<GenericSelectInput
														Name="CountryId"
														LabelText="Country"
														Method="POST"
														Url="/coreapi/v1.0/Country/GetAll"
														Parameter={{}}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="Name"
														Value={model.CountryId}
														Disabled={Disabled} 	/>
													<GenericSelectInput
														key={model.CountryId + "province"}
														Name="ProvinceId"
														LabelText="Province"
														Method="POST" Url="/coreapi/v1.0/Province/Filter"
														Parameter={{ CountryId: model.CountryId }} DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="Name"
														Value={model.ProvinceId}
														Disabled={Disabled} />
													<GenericSelectInput
														key={"city" + model.ProvinceId + ""}
														Name="CityId"
														LabelText="City"
														Method="POST"
														Url="/coreapi/v1.0/City/Filter"
														Parameter={{ ProvinceId: model.ProvinceId }}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="Name"
														Value={model.CityId}
														Disabled={Disabled} />
												</GridItem>
											</div>

										</GridItem>

										<GridItem xs={12}>
											<GridContainer direction="row" justify="flex-start" alignItems="flex-start">
												<GridItem xs={6}>
													<GenericTitle text={"Employer Information"} />
														<div>
															<GridItem>
																<GenericTextInput
																	Name="EmployerName"
																	LabelText="Employer"
																	Value={model.EmployerName}
																	Disabled={Disabled}
																	inputProps={this.ParameterMaxLength30}/>												
															</GridItem>
															<GridItem>
																<GenericTextInput
																	Name="EmployerAddress"
																	LabelText="Address"
																	Id="employer-address"
																	Value={model.EmployerAddress}
																	Disabled={Disabled}
																	inputProps={this.maxLength100} />
															</GridItem>								
															<GridItem>
																<GenericTextInput
																	Name="EmployerZipCode"
																	LabelText="Postal Code"
																	Id="employer-postcode"
																	Value={model.EmployerZipCode}
																	Disabled={Disabled}
																	inputProps={this.maxLength20} />
															</GridItem>
															<GridItem>
																<GenericSelectInput
																	Name="EmployerCountryId"
																	LabelText="Country"
																	Method="POST"
																	Url="/coreapi/v1.0/Country/GetAll"
																	Parameter={{}}
																	DataRoot="Item"
																	KeyValueMember="Id"
																	TextValueMember="Name"
																	Value={model.EmployerCountryId}
																	Disabled={Disabled} />
															</GridItem>
															<GridItem>
																<GenericSelectInput
																	key={model.EmployerCountryId + "province"}
																	Name="EmployerProvinceId"
																	LabelText="Province"
																	Method="POST"
																	Url="/coreapi/v1.0/Province/Filter"
																	Parameter={{ CountryId: model.EmployerCountryId }}
																	DataRoot="Item"
																	KeyValueMember="Id"
																	TextValueMember="Name"
																	Value={model.EmployerProvinceId}
																	Disabled={Disabled} />
															</GridItem>
															<GridItem>
																<GenericSelectInput
																	key={"city" + model.EmployerProvinceId + ""}
																	Name="EmployerCityId"
																	LabelText="City"
																	Method="POST"
																	Url="/coreapi/v1.0/City/Filter"
																	Parameter={{ ProvinceId: model.EmployerProvinceId }}
																	DataRoot="Item"
																	KeyValueMember="Id"
																	TextValueMember="Name"
																	Value={model.EmployerCityId}
																	Disabled={Disabled} />
															</GridItem>
															<GridItem>
																<GridContainer>
																	<GridItem xs={8}>
																		<GenericSelectInput
																			Name="EmployerPhoneCountryCodeId"
																			LabelText="Phone Number"
																			TitleLabel="Country Code"
																			LabelMd={6}
																			Method="POST"
																			Url="/coreapi/v1.0/Country/GetAll"
																			Parameter={this.emptyObject}
																			DataRoot="Item"
																			KeyValueMember="Id"
																			TextValueMember={"Code"}
																			Value={model.EmployerPhoneCountryCodeId}
																			Sorted={this.sortedOrderIndex}
																			RenderItem={this.RenderItemPhoneCode}
																			Disabled={Disabled} />
																	</GridItem>
																	<GridItem xs={4} style={{ paddingRight: 2 + "px !important" }}>
																		<GenericNumberInput
																			IsPhone
																			Name="EmployerPhoneNumber"
																			InputStyle={this.marginLeft2}
																			LabelMd={0}
																			Value={model.EmployerPhoneNumber}
																			MaxLength={10}
																			Disabled={Disabled}/>
																	</GridItem>
																</GridContainer>
															</GridItem>
														</div>
													</GridItem>
											</GridContainer>																	
										</GridItem>

									</GridContainer>

								</GridItem>
							</GridContainer>
						</GridItem>
						<GridItem xs={11}>
						</GridItem>
						<GridItem xs={1}>
							<Button size="sm" color="black" fullWidth
								onClick={this.props.NextClicked}>Next</Button>
						</GridItem>
					</GridContainer>
				</GenericExpansionPanel>
			</div >
		);
	}
}

LoanApplicantInformation.propTypes = {
	classes: PropTypes.object.isRequired,
	ApplicantModel: PropTypes.object,
	Title: PropTypes.string,
	Disabled: PropTypes.bool,
	NextClicked: PropTypes.func,
	HandleChangeFilterPanel: PropTypes.func,
	Name: PropTypes.string,
	IsActive: PropTypes.bool,
	RetiredOrUnemployedIDs: PropTypes.array,
	IdentificationMethodIds: PropTypes.array,
	SpecificIdentityTypesIds: PropTypes.array
};

export default withArtifex(LoanApplicantInformation, {});