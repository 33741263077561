import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import ClientHelper from "core/ClientHelper";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent.jsx";
import { GridColumnType } from "views/Constants/Constant";

class LimitDefinition extends React.Component {
	constructor(props) {
		super(props);
		this.initialModel = {
			UniqueClientId: ClientHelper.IsBackOffice() ? -2 : ClientHelper.GetClientRowId()
		};
		this.limitdefinationcolumns = [
			{
				Header: "Date",
				accessor: "LimitDefinitionDate",
				type: GridColumnType.Date
			},
			{
				Header: "Client Name",
				accessor: "UniqueClient.ClientName"
			},
			{
				Header: "LimitLevel",
				accessor: "LimitLevelType.ParameterDesc"
			},
			{
				Header: "Product Based Max Order",
				accessor: "ProductBasedMaximumOrder"
			}
			,
			{
				Header: "Maximum Order-Daily",
				accessor: "MaximumOrderDaily"
			}
			, {
				Header: "Maximum Order-Weekly",
				accessor: "MaximumOrderWeekly"
			},
			{
				Header: "Safe Level Limit For Order",
				accessor: "SafeLevelLimit"
			},
			{
				Header: "Maximum Package Size",
				accessor: "MaximumPackageSize"
			}
 

		];
		this.emptyObject = {}
		this.sortedCustomerName = { Member: "Name" };
		this.sortedCustomerStore = { Member: "StoreName" };
		this.parameterCustomerStore = {};

		this.state = {
			safelist: [],
			model: { ...this.initialModel },
			selected: null,
			isLoading: false,
			IsBackOffice: false,
			isOpenDialog: false,
			detailDialog: false,
			vModel:{}
		};
	}
	componentDidMount() {
		this.props.setAppLeftTitle("Limit Definition");
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
	}

	ValueChanged = (name, value) => {

		const model = this.state.model;
		model[name] = value;

		this.setState({ model });
	}

	RowSelected = (index) => {
		const { safelist } = this.state;

		this.setState({ model: safelist[index], selected: index });
	}
	DeleteConfirm = () => {
		const { showConfirmMessage } = this.props;
		showConfirmMessage("warning", "Warning", "Are you sure want to delete ?", this.Delete);
	}

	GetParameterCustomerStore = () => {
		const { model } = this.state;

		this.parameterCustomerStore.Id = model.UniqueClientId;

		return this.parameterCustomerStore;
	}
	Search = () => {
		this.setState({ isLoading: true });

		const { model } = this.state;
		const { showMessage } = this.props;

		Proxy.POST(
			"/prepaidapi/v1.0/InventoryLimitDefinition/Search",
			model,
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				this.setState({ safelist: responseData.Item || [], isLoading: false });
			},
			(error) => {
				this.setState({ isLoading: false });
				showMessage("error", "An error occurred while requesting data", error.message);
			}
		);
	}
	Clear = () => {
		this.setState({ model: { ...this.initialModel }, safelist: [], selected: null });
	}
	Update = () => {
		if (!this.Validate()) {
			return;
		}

		this.setState({ isLoading: true });

		const { model } = this.state;
		const { showMessage } = this.props;

		Proxy.POST(
			"/prepaidapi/v1.0/InventoryLimitDefinition/Update",
			model,
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				this.setState({ isLoading: false });
				showMessage("success", "Operation is successfully!");
				this.Clear();
				this.Search();
			},
			(error) => {
				this.setState({ isLoading: false });
				showMessage("error", "An error occurred while sending data", error.message);
			}
		);
	}
	Delete = () => {
		this.setState({ isLoading: true });

		const { model } = this.state;
		const { showMessage } = this.props;

		Proxy.POST(
			"/prepaidapi/v1.0/InventoryLimitDefinition/Delete",
			{
				"Id": model.Id
			},
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				this.setState({ isLoading: false });
				showMessage("success", "Operation is successfully!");
				this.Clear();
				this.Search();
			},
			(error) => {
				this.setState({ isLoading: false });
				showMessage("error", "An error occurred while sending data", error.message);
			}
		);
	}
	Submit = () => {
		if (!this.Validate()) {
			return;
		}

		this.setState({ isLoading: true });

		const { model } = this.state;
		const { showMessage } = this.props;

		Proxy.POST(
			"/prepaidapi/v1.0/InventoryLimitDefinition/Insert",
			model
			,
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				this.setState({ isLoading: false });
				showMessage("success", "Operation is successfully!");
				this.Clear();
				this.Search();
			},
			(error) => {
				this.setState({ isLoading: false });
				showMessage("error", "An error occurred while sending data", error.message);
			}
		);
	}


	Validate() {
		const { model } = { ...this.state };
		const { showMessageNode } = this.props;
		var messages = [];
		const vModel = { ...this.state.vModel };

		if (model.UniqueClientId == -2) {
			messages.push("Client can not be null.");
				vModel.UniqueClientId = true;
		} else{
			vModel.UniqueClientId = false;
		}

		if (!model.LimitLevelTypeId) {
			messages.push("Limit Level  can not be null.");
			vModel.LimitLevelTypeId = true;
		} else{
			vModel.LimitLevelTypeId = false;
		}
		if (!model.ProductBasedMaximumOrder) {
			messages.push("Product Based Maximum Order  can not be null.");
			vModel.ProductBasedMaximumOrder = true;
		} else{
			vModel.ProductBasedMaximumOrder = false;
		}
		if (!model.SafeLevelLimit) {
			messages.push("Safe Level Limit for Order can not be null.");
			vModel.SafeLevelLimit = true;
		} else{
			vModel.SafeLevelLimit = false;
		}
		if (!model.MaximumOrderWeekly) {
			messages.push("Maximum Order Weekly  can not be null.");
			vModel.MaximumOrderWeekly = true;
		} else{
			vModel.MaximumOrderWeekly = false;
		}
		if (!model.MaximumOrderDaily) {
			messages.push("Maximum Order Daily  can not be null.");
			vModel.MaximumOrderDaily = true;
		} else{
			vModel.MaximumOrderDaily = false;
		}
		if (!model.MaximumPackageSize) {
			messages.push("Maximum Package Size  can not be null.");
			vModel.MaximumPackageSize = true;
		} else{
			vModel.MaximumPackageSize = false;
		}
		this.setState({ vModel });

		if (messages.length > 0) {
			if (messages.length > 9) {
				var moreWarn = messages.length - 8;
				messages = messages.slice(0, 8);
				messages.push("And " + moreWarn + " more warnings");
			}
			showMessageNode("error", "Please Fill Required Fields", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		return true;
	}

	handleClose = () => {

		this.setState({ detailDialog: false, openCheckinDialog: false, openTransferDialog: false, openReceiveDialog: false, openOrderDialog: false });
	}


	render() {
		const { classes, Disabled } = this.props;
		const { alert, model, selected, limitdefinationcolumns, safelist, isLoading, detailDialog, IsBackOffice,vModel } = this.state;
		var IsClient = ClientHelper.IsClient();
		return (
			<div>
				<LoadingComponent Show={isLoading}/>

				{alert}
				<ButtonToolbar ButtonList={[
					{
						Code: "Search",
						Disabled: Disabled,
						OnClick: this.Search
					},
					{
						Code: "Submit",
						Disabled: Disabled || selected != null,
						OnClick: this.Submit
					},
					{
						Code: "Update",
						Disabled: Disabled || selected == null,
						OnClick: this.Update
					},
					{
						Code: "Delete",
						Disabled: Disabled || selected == null,
						OnClick: this.DeleteConfirm
					},
					{
						Code: "Clear",
						Disabled: Disabled,
						OnClick: this.Clear
					}
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card >
							<CardBody>
								<GenericExpansionPanel >
									<GridContainer>
										<GridItem xs={4}>

											<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={{ UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined }}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
												Value={model.UniqueClientId || ""}
												ValueChanged={this.ValueChanged}
												CanClear
												All
												Disabled={ClientHelper.IsClient()}
												DefaultIndex={ClientHelper.IsClient() ? 0 : -1}	
												IsInvalid={vModel.UniqueClientId}
												Required
											/>
											<ParameterComponent
												Name="LimitLevelTypeId"
												Value={model.LimitLevelTypeId}
												LabelText="Limit Level"
												ParameterCode="InventoryLimitType"
												ValueChanged={this.ValueChanged}
												IsInvalid={vModel.LimitLevelTypeId}
												Required />
											<GenericTextInput
												Name="SafeLevelLimit"
												LabelText="Safe Level Limit For Order"
												Value={model.SafeLevelLimit}
												ValueChanged={this.ValueChanged}
												IsInvalid={vModel.SafeLevelLimit}
												Required
											>	</GenericTextInput>
										</GridItem>
										<GridItem xs={4}>
											<GenericTextInput
												Name="ProductBasedMaximumOrder"
												LabelText="Product Based Maximum Order"
												Value={model.ProductBasedMaximumOrder}
												ValueChanged={this.ValueChanged}
												IsInvalid={vModel.ProductBasedMaximumOrder}
												Required
											>	</GenericTextInput>
											<GenericTextInput
												Name="MaximumPackageSize"
												LabelText="Maximum Package Size"
												Value={model.MaximumPackageSize}
												ValueChanged={this.ValueChanged}
												IsInvalid={vModel.MaximumPackageSize}
												Required
											>	</GenericTextInput>
										</GridItem>
										<GridItem xs={4}>

											<GenericTextInput
												Name="MaximumOrderDaily"
												LabelText="Maximum Order-Daily"
												Value={model.MaximumOrderDaily}
												ValueChanged={this.ValueChanged}
												IsInvalid={vModel.MaximumOrderDaily}
												Required
											>	</GenericTextInput>
											<GenericTextInput
												Name="MaximumOrderWeekly"
												LabelText="Maximum Order-Weekly"
												Value={model.MaximumOrderWeekly}
												ValueChanged={this.ValueChanged}
												IsInvalid={vModel.MaximumOrderWeekly}
												Required
											>	</GenericTextInput>

										</GridItem>

									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>

						<Card className="no-radius">
							<GenericExpansionPanel Title="Client Limit Definition List">
								<CardBody>
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer spacing={16}>
												<GridItem xs={12}>
													<GenericGrid
														Data={safelist}
														SelectedIndex={selected}
														Columns={this.limitdefinationcolumns}
														RowSelected={this.RowSelected} />
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</CardBody>
							</GenericExpansionPanel>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

LimitDefinition.propTypes = {
	setAppCenterTitle: PropTypes.func,
	setAppLeftTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default LimitDefinition;