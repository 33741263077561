import { DialogContent, DialogTitle, withStyles } from "@material-ui/core";
import genericAlertStyle from "assets/jss/generic/genericAlertStyle.jsx";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericDialog, GenericDialogActions, GenericIcon, GenericLabel } from "views/Components/Generic";

class GenericAlert extends Component {

	constructor(props) {
		super(props);
		this.state = {
			iconName: null,
			iconStyle: {
				fontSize: 80,
				color: null
			}
		};
		this.dashboardStyleZIndex = undefined;
	}

	componentDidMount() {
		var dashboardDiv = document.getElementById("dashboardDiv");

		if (dashboardDiv) {
			this.dashboardStyleZIndex = dashboardDiv.style.zIndex;
			dashboardDiv.style.zIndex = "1";
		}
		this.getIconStyle();
	}

	componentWillUnmount() {
		var dashboardDiv = document.getElementById("dashboardDiv");
		if (dashboardDiv) {
			dashboardDiv.style.zIndex = this.dashboardStyleZIndex;
		}
	}

	componentWillUpdate(nextProps) {
		if (nextProps && (nextProps.Type != this.props.Type)) {
			this.getIconStyle(nextProps);
		}
	}
	handleButtonCancel = () => {
		const { OnCancel } = this.props;
		OnCancel && OnCancel();
	}
	handleButtonOk = (e) => {
		const { OnConfirm } = this.props;
		OnConfirm && OnConfirm(e);
	}
	getIconStyle = (nextProps) => {
		const { Type } = nextProps || this.props;
		var iconStyle = { ...this.state.iconStyle };

		var iconName = "";
		var iconColor = "";
		switch (Type) {
			case "success":
				iconName = "check_circle_outline";
				iconColor = "#a5dc86";
				break;
			case "error":
				iconName = "cancel_outline";
				iconColor = "#f27474";
				break;
			case "warning":
				iconName = "error_outline";
				iconColor = "#f8bb86";
				break;
			case "info":
				iconName = "info_outline";
				iconColor = "#3fc3ee";
				break;
			case "question":
				iconName = "help_outline";
				iconColor = "#87adbd";
				break;
			default:
				break;
		}

		iconStyle.color = iconColor;
		this.setState({ iconName, iconStyle });
	}
	render() {

		const { Message, MessageNode, Title, ShowCancel, classes } = this.props;
		const { iconName, iconStyle } = this.state;

		return (
			<GenericDialog open maxWidth="md">
				<DialogTitle className={classes.dialogTitle}>
					<GridContainer direction="row" justify="flex-start" alignItems="center">
						<GridItem>
							<GenericIcon style={iconStyle}>{iconName}</GenericIcon>
						</GridItem>
						<GridItem className={classes.dialogTitleText}>
							<GenericLabel FontSize="16px" Bold Text={Title} />
						</GridItem>
					</GridContainer>
				</DialogTitle>
				<DialogContent className={classes.dialogContent}>
					{Message || MessageNode}
				</DialogContent>
				<GenericDialogActions>
					{ShowCancel ? <Button autoFocus className={classes.alertButton} size="sm" onClick={this.handleButtonCancel}>CANCEL</Button> : null}
					<Button autoFocus={!ShowCancel} className={classes.alertButton} size="sm" onClick={this.handleButtonOk}>OK</Button>
				</GenericDialogActions>
			</GenericDialog>
		);
	}
}

GenericAlert.propTypes = {
	classes: PropTypes.object.isRequired,
	OnConfirm: PropTypes.func,
	OnCancel: PropTypes.func,
	Title: PropTypes.string,
	Message: PropTypes.node,
	ShowCancel: PropTypes.bool,
	MessageNode: PropTypes.node,
	Type: PropTypes.oneOf(["default", "info", "success", "warning", "danger", "error", "input", "custom"])
};

export default withStyles(genericAlertStyle)(GenericAlert);