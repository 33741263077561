import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import PropTypes from "prop-types";
import React from "react";
import TabsComponent from "views/Components/TabsComponentPagination";
import { ClientType, MenuCodes } from "views/Constants/Constant.js";
//Steps
import Address from "./CustomerDetailStep/Address";
import Corporate from "./CustomerDetailStep/Corporate";
import Personal from "./CustomerDetailStep/Personal";



class CustomerDetails extends React.Component {
	constructor(props) {
		super(props);
		this.state = {

		};
	}


	componentDidMount() {
		if (this.props.setAppLeftTitle) {
			if (this.props.MenuCode == MenuCodes.Backoffice_ClientSetup) {
				this.props.setAppLeftTitle("Client Setup");
			}
			if (this.props.isSalesAgentPersonel) {
				this.props.setAppLeftTitle("Sales Agent Details");
			}
		}
	}
	render() {
		return (
			<GridContainer spacing={16}>
				<GridItem xs={12}>
					<br />
					<TabsComponent
						TabIndex={this.props.ChildTabIndex}
						handleChange={(e, a) => this.props.handleChangeChildTab(e, a, true)}
						tabList={[
							{
								tabButton: "Individual",
								tabContent: (
									<Personal
										paramCustomerProfile={this.props.paramCustomerProfile}
										paramGlobalModel={this.props.paramGlobalModel}
										handleChangePersonal={this.props.handleChangePersonal}
										setCustomerState={this.props.setCustomerState}
										setIdentificationState={this.props.setIdentificationState}
										paramIdentificationMethot1={this.props.paramIdentificationMethot1}
										paramIdentificationMethot2={this.props.paramIdentificationMethot2}
										handleChangeIdentification1={this.props.handleChangeIdentification1}
										handleChangeIdentification2={this.props.handleChangeIdentification2}
										handleChangePhone1={this.props.handleChangePhone1}
										handleChangePhone2={this.props.handleChangePhone2}
										handleChangePhone3={this.props.handleChangePhone3}
										paramCustomerMobilePhone={this.props.paramCustomerMobilePhone}
										paramCustomerPrimaryPhone={this.props.paramCustomerPrimaryPhone}
										paramCustomerAlternativePhone={this.props.paramCustomerAlternativePhone}
										MenuCode={this.props.MenuCode}
										handleChangeParentTab={this.props.handleChangeParentTab}
										handleChangeChildTab={this.props.handleChangeChildTab}
										isSalesAgentPersonel={this.props.isSalesAgentPersonel}
										paramBankCustomerKeyContactList={this.props.paramBankCustomerKeyContactList}
										GetCustomerKeyContactModels={this.props.GetCustomerKeyContactModels}

										Disabled={this.props.Disabled}
										FetchClientLimitedAuthority={this.props.FetchClientLimitedAuthority}
									/>
								),
								Hidden: this.props.paramGlobalModel.ClientType == "" ? false : this.props.paramGlobalModel.ClientType == ClientType.Corporate ? true : this.props.paramGlobalModel.ClientType == ClientType.SalesAgentCorporate ? true : false
							},
							{
								tabButton: "Corporate",
								tabContent: (
									<Corporate
										paramCustomerProfile={this.props.paramCustomerProfile}
										paramGlobalModel={this.props.paramGlobalModel}

										hideAlert={this.props.hideAlert}
										paramBankCustomerCorporate={this.props.paramBankCustomerCorporate}
										GetCorporateProfile={this.props.GetCorporateProfile}
										setCorporateState={this.props.setCorporateState}
										handleChangeCorporate={this.props.handleChangeCorporate}
										handleChangeCorporatePhoneFax={this.props.handleChangeCorporatePhoneFax}
										handleChangeCorporatePhoneBuss={this.props.handleChangeCorporatePhoneBuss}
										paramCorporateBussinesPhone={this.props.paramCorporateBussinesPhone}
										paramCorporateFaxPhone={this.props.paramCorporateFaxPhone}

										handleChangeParentTab={this.props.handleChangeParentTab}
										handleChangeChildTab={this.props.handleChangeChildTab}
										paramBankCustomerKeyContactList={this.props.paramBankCustomerKeyContactList}
										paramBankCustomerDirectorsList={this.props.paramBankCustomerDirectorsList}
										GetCustomerKeyContactModels={this.props.GetCustomerKeyContactModels}
										GetCustomerDirectorsModels={this.props.GetCustomerDirectorsModels}
										paramBankCustomerRegulatoryLicenseList={this.props.paramBankCustomerRegulatoryLicenseList}
										GetCustomerRegulatoryLicenses={this.props.GetCustomerRegulatoryLicenses}

										paramBankCustomerCorporateAuthOwnerList={this.props.paramBankCustomerCorporateAuthOwnerList}
										paramBankCustomerCorporateAuthPersonList={this.props.paramBankCustomerCorporateAuthPersonList}
										GetCustomerCorporateAuthOwnerModels={this.props.GetCustomerCorporateAuthOwnerModels}
										GetCustomerCorporateAuthPersonModels={this.props.GetCustomerCorporateAuthPersonModels}

										Disabled={this.props.Disabled}
										FetchClientLimitedAuthority={this.props.FetchClientLimitedAuthority}
									/>
								),
								Hidden: this.props.isSalesAgentPersonel ? true : this.props.paramGlobalModel.ClientType == "" ? false : this.props.paramGlobalModel.ClientType == ClientType.Personal ? true : this.props.paramGlobalModel.ClientType == ClientType.SalesAgentPersonal ? true : false
							},
							{
								tabButton: "Address",
								tabContent: (
									<Address
										paramCustomerProfile={this.props.paramCustomerProfile}
										paramGlobalModel={this.props.paramGlobalModel}
										paramBankCustomerAddressList={this.props.paramBankCustomerAddressList}
										GetCustomerAddressModels={this.props.GetCustomerAddressModels}

										handleChangeParentTab={this.props.handleChangeParentTab}
										handleChangeChildTab={this.props.handleChangeChildTab}

										Disabled={this.props.Disabled}
										FetchClientLimitedAuthority={this.props.FetchClientLimitedAuthority}
									/>
								),
								Hidden: false
							}
						]}
					/>
				</GridItem>
			</GridContainer>
		);
	}
}

CustomerDetails.propTypes = {
	classes: PropTypes.object,
	paramCustomerProfile: PropTypes.object,
	paramGlobalModel: PropTypes.object,
	paramIdentificationMethot1: PropTypes.object,
	paramIdentificationMethot2: PropTypes.object,
	GetCorporateProfile: PropTypes.func,
	handleChangePersonal: PropTypes.func,
	handleChangeIdentification1: PropTypes.func,
	handleChangeIdentification2: PropTypes.func,
	handleOperationType: PropTypes.func,
	setCustomerState: PropTypes.func,
	setIdentificationState: PropTypes.func,
	hideAlert: PropTypes.func,
	paramBankCustomerCorporate: PropTypes.object,
	paramBankCustomerAddressList: PropTypes.array,
	paramBankCustomerCorporateAuthPersonList: PropTypes.array,
	paramBankCustomerCorporateAuthOwnerList: PropTypes.array,
	GetCustomerAddressModels: PropTypes.func,
	GetCustomerCorporateAuthOwnerModels: PropTypes.func,
	GetCustomerCorporateAuthPersonModels: PropTypes.func,
	handleChangePhone1: PropTypes.func,
	handleChangePhone2: PropTypes.func,
	handleChangePhone3: PropTypes.func,
	paramCustomerMobilePhone: PropTypes.object,
	paramCustomerAlternativePhone: PropTypes.object,
	paramCustomerPrimaryPhone: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	setCorporateState: PropTypes.func,
	handleChangeCorporate: PropTypes.func,
	handleChangeCorporatePhoneFax: PropTypes.func,
	handleChangeCorporatePhoneBuss: PropTypes.func,
	paramCorporateBussinesPhone: PropTypes.object,
	paramCorporateFaxPhone: PropTypes.object,
	handleChangeParentTab: PropTypes.func,
	handleChangeChildTab: PropTypes.func,
	paramBankCustomerKeyContactList: PropTypes.array,
	paramBankCustomerDirectorsList: PropTypes.array,
	GetCustomerKeyContactModels: PropTypes.func,
	GetCustomerDirectorsModels: PropTypes.func,
	paramBankCustomerRegulatoryLicenseList: PropTypes.array,
	GetCustomerRegulatoryLicenses: PropTypes.func,
	ChildTabIndex: PropTypes.number,
	FetchClientLimitedAuthority: PropTypes.func
};


export default withArtifex(CustomerDetails, {});