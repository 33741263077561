import withStyles from "@material-ui/core/styles/withStyles";
import { chartColors } from "assets/jss/material-dashboard-pro-react.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericExpansionPanel, GenericSelectInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import TabsComponent from "views/Components/TabsComponent";
import ExecutiveSummaryTab from "./ExecutiveSummaryTab";

const styles = ({
});

class ExecutiveSummary extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {};

		this.txnCountChartData = {
			labels: ["Transactions"],
			datasets: [{
				data: [100],
				backgroundColor: chartColors.red,
				hoverBackgroundColor: chartColors.red
			}],
			text: "0"
		};

		this.txnTypeChartData = {
			labels: ["Credit & Debit"],
			datasets: [{
				data: [100],
				backgroundColor: "rgb(255, 159, 64)",
				hoverBackgroundColor: "rgb(255, 159, 64)"
			}],
			text: "0"
		};

		this.feeChartData = {
			labels: ["Total Fees"],
			datasets: [{
				data: [100],
				backgroundColor: "rgb(54, 162, 235)",
				hoverBackgroundColor: "rgb(54, 162, 235)"
			}],
			text: "0"
		};

		this.renderItemUniqueClient = {};

		this.state = {
			programList: [],
			merchantList: [],
			model: { ...this.initialModel },
			vModel: {},
			isLoading: false,
			activeIndex: 0,
			txnCountChartData1: JSON.parse(JSON.stringify(this.txnCountChartData)),
			txnCountChartData2: JSON.parse(JSON.stringify(this.txnCountChartData)),
			txnTypeChartData1: JSON.parse(JSON.stringify(this.txnTypeChartData)),
			txnTypeChartData2: JSON.parse(JSON.stringify(this.txnTypeChartData)),
			feeChartData1: JSON.parse(JSON.stringify(this.feeChartData)),
			feeChartData2: JSON.parse(JSON.stringify(this.feeChartData))
		};
		// preserve the initial state in a new object
		this.baseState = this.state;

		// this.chartColors = {
		// 	red: "rgb(255, 99, 132)",
		// 	orange: "rgb(255, 159, 64)",
		// 	yellow: "rgb(255, 205, 86)",
		// 	green: "rgb(75, 192, 192)",
		// 	blue: "rgb(54, 162, 235)",
		// 	purple: "rgb(153, 102, 255)",
		// 	grey: "rgb(201, 203, 207)"
		// };
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Executive Summary");
		this.props.setAppCenterTitle("REPORTS");
	}

	HandleChange = (name, value) => {
		const model = { ...this.state.model };
		model[name] = value;
		this.setState({ model });
	}

	HandleClear = () => {
		this.setState(function (state) {
			state = this.baseState;
			return state;
		});
	}

	HandleGetList = () => {
		if (!this.Validate())
			return;
		const { model } = { ...this.state };
		if (this.state.activeIndex == 0) {
			model.UniqueClientId = 0
		};
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/Reporting/SearchExecutiveSummary",
			this.state.model,
			this.SuccessListCallback,
			this.ErrorListCallback
		);
	}

	SuccessListCallback = (responseData) => {
		const { model, activeIndex } = this.state;

		this.setState({ isLoading: false });
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error", responseData.ErrorDescription);
			return;
		}

		var arrayData = [];
		var arrayLabel = [];
		var arrayColor = [];
		if (responseData.Item !== null) {
			var colorNames = Object.keys(chartColors);
			var i = 0;
			for (var item of responseData.Item.TransactionChartData) {
				var colorName = colorNames[i % colorNames.length];
				var newColor = chartColors[colorName];
				arrayColor.push(newColor);
				arrayData.push(item["TransactionCount"]);
				arrayLabel.push(item["ProgramName"]);
				i++;
			}
			var txnCountChartObj = {
				labels: arrayLabel,
				datasets: [{
					data: arrayData,
					hoverBackgroundColor: arrayColor,
					backgroundColor: arrayColor
				}],
				text: this.SumValues(arrayData)
			};

			arrayColor = [];
			arrayData = [];
			arrayLabel = [];
			var j = 0;
			for (var item of responseData.Item.CreditDebitChartData) {
				var colorName = colorNames[j % colorNames.length];
				var newColor = chartColors[colorName];
				arrayColor.push(newColor);
				arrayData.push(item["TransactionAmount"]);
				arrayLabel.push(item["TransactionType"]);
				j++;
			}
			var txnTypeChartObj = {
				labels: arrayLabel,
				datasets: [{
					data: arrayData,
					hoverBackgroundColor: arrayColor,
					backgroundColor: arrayColor
				}],
				text: `$${this.GetChartText(this.SumValues(arrayData).toFixed(2))}`
			};

			arrayColor = [];
			arrayData = [];
			arrayLabel = [];
			var k = 0;
			for (var item of responseData.Item.TotalFeeChartData) {
				var colorName = colorNames[k % colorNames.length];
				var newColor = chartColors[colorName];
				arrayColor.push(newColor);
				arrayData.push(item["TransactionAmount"]);
				arrayLabel.push(item["ProgramName"]);
				k++;
			}
			var feeChartObj = {
				labels: arrayLabel,
				datasets: [{
					data: arrayData,
					hoverBackgroundColor: arrayColor,
					backgroundColor: arrayColor
				}],
				text: `$${this.GetChartText(this.SumValues(arrayData).toFixed(2))}`
			};

			model.BeginDate = responseData.Item.BeginDate;
			model.EndDate = responseData.Item.EndDate;

			this.setState({
				model,
				programList: activeIndex == 0 ? responseData.Item.ProgramTransactionList : [],
				merchantList: activeIndex == 1 ? responseData.Item.MerchantTransactionList : [],
				txnCountChartData1: JSON.parse(JSON.stringify(activeIndex == 0 ? txnCountChartObj : this.txnCountChartData)),
				txnCountChartData2: JSON.parse(JSON.stringify(activeIndex == 1 ? txnCountChartObj : this.txnCountChartData)),
				txnTypeChartData1: JSON.parse(JSON.stringify(activeIndex == 0 ? txnTypeChartObj : this.txnTypeChartData)),
				txnTypeChartData2: JSON.parse(JSON.stringify(activeIndex == 1 ? txnTypeChartObj : this.txnTypeChartData)),
				feeChartData1: JSON.parse(JSON.stringify(activeIndex == 0 ? feeChartObj : this.feeChartData)),
				feeChartData2: JSON.parse(JSON.stringify(activeIndex == 1 ? feeChartObj : this.feeChartData))
			});
		}
	}

	ErrorListCallback = (error) => {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", error);
	}

	GetElementValue = (obj, path) => {
		if (obj == null || path == null)
			return "";
		return [obj].concat(path.split(".")).reduce((c, n) => {
			if (c) {
				if (n.toString().includes("Percent")) {
					return `${c[n].toFixed(1)}%`;
				} else if (n.toString().includes("Amount")) {
					return `$${c[n].toFixed(2)}`;
				}
				return c[n];
			}
		});
	}

	Print = () => {
		const { activeIndex, model, programList, merchantList } = this.state;
		this.setState({ isLoading: true });
		var keyvalueList = [];
		var list = activeIndex == 0 ? programList : merchantList;
		var columns = activeIndex == 0 ? this.GetColumns(programList, "Programs", "ProgramName") : this.GetColumns(merchantList, "Client", "FullName");

		if (list.length == 0)
			return;

		list.forEach(function (listElement) {
			var keyValueObject = {};
			columns.forEach(function (gridColumnElement) {
				var valueItem = this.GetElementValue(listElement, gridColumnElement.accessor);
				keyValueObject[gridColumnElement.accessor] = { value: valueItem, title: gridColumnElement.Header };
			}, this);
			keyvalueList.push(keyValueObject);
		}, this);

		var format = "PDF";

		Proxy.POST(
			"/coreapi/v1.0/report/CreateFileFromData",
			{
				jsonFile: {
					title: "Executive Summary Report",
					table: keyvalueList,
					format: format,
					model: {
						DateFilter: { BeginDate: this.state.model.BeginDate, EndDate: this.state.model.EndDate }
					}
				}
			},
			(responseData) => {
				this.setState({ isLoading: false });
				let a = document.createElement("a");
				a.style = "display: none";
				document.body.appendChild(a);
				a.href = "data:application/octet-stream;base64," + responseData.Item.value;
				a.download = "ExecutiveSummaryReport.pdf";
				a.click();
			},
			(error) => {
				this.setState({ isLoading: false });
			}
		);
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	TabIndexChanged = (activeIndex) => {
		this.setState({ activeIndex });
	}

	Validate = () => {
		const { model, activeIndex, vModel } = this.state;
		var messages = [];

		if (!model.Top) {
			vModel.Top = true;
			messages.push("Top Filter must be select");
		} else {
			vModel.Top = false;
		}

		if (!model.FromDate) {
			vModel.FromDate = true;
			messages.push("From Date must be select");
		} else {
			vModel.FromDate = false;
		}

		if (!model.ToDate) {
			vModel.ToDate = true;
			messages.push("To Date must be select");
		} else {
			vModel.ToDate = false;
		}

		if (model.ToDate < model.FromDate) {
			messages.push("To Date must be bigger than From Date");
		}

		if (messages.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.setState({ vModel });
		return true;
	}

	SumValues = (...arr) => {
		return arr.length > 0 ? arr[0].reduce((a, b) => a + b, 0) : 0;
	}

	GetColumns = (list, header, accessor) => {
		const columns = [
			{
				Header: header,
				accessor: accessor,
				Footer: (
					<span>
						<strong>Total Transactions</strong>
					</span>
				)
			},
			{
				Header: "Transactions",
				accessor: "TransactionCount",
				Cell: row => (
					<div>{Formatter.FormatNumber(row.value)}</div>
				),
				Footer: (
					<span>
						<strong>{Formatter.FormatNumber(this.SumValues(list.map(d => d.TransactionCount)))}</strong>
					</span>
				)
			},
			{
				Header: "% of Transaction Errors",
				accessor: "TransactionErrorCountPercent",
				Cell: row => (
					<div>{Formatter.FormatPercent(row.value)}</div>
				),
				Footer: (
					<span>
						<strong>{Formatter.FormatPercent(this.SumValues(list.map(d => d.TransactionErrorCountPercent)))}</strong>
					</span>
				)
			},
			{
				Header: "Debit",
				accessor: "DebitCount",
				Cell: row => (
					<div>{Formatter.FormatNumber(row.value)}</div>
				),
				Footer: (
					<span>
						<strong>{Formatter.FormatNumber(this.SumValues(list.map(d => d.DebitCount)))}</strong>
					</span>
				)
			},
			{
				Header: "% of Debit Errors",
				accessor: "DebitErrorCountPercent",
				Cell: row => (
					<div>{Formatter.FormatPercent(row.value)}</div>
				),
				Footer: (
					<span>
						<strong>{Formatter.FormatPercent(this.SumValues(list.map(d => d.DebitErrorCountPercent)))}</strong>
					</span>
				)
			},
			{
				Header: "Credit",
				accessor: "CreditCount",
				Cell: row => (
					<div>{Formatter.FormatNumber(row.value)}</div>
				),
				Footer: (
					<span>
						<strong>{Formatter.FormatNumber(this.SumValues(list.map(d => d.CreditCount)))}</strong>
					</span>
				)
			},
			{
				Header: "% of Credit Errors",
				accessor: "CreditErrorCountPercent",
				Cell: row => (
					<div>{Formatter.FormatPercent(row.value)}</div>
				),
				Footer: (
					<span>
						<strong>{Formatter.FormatPercent(this.SumValues(list.map(d => d.CreditErrorCountPercent)))}</strong>
					</span>
				)
			},
			{
				Header: "Total",
				accessor: "TotalAmount",
				Cell: row => (
					<div>{Formatter.FormatMoney(row.value)}</div>
				),
				Footer: (
					<span>
						<strong>{Formatter.FormatMoney(this.SumValues(list.map(d => d.TotalAmount)))}</strong>
					</span>
				)
			},
			{
				Header: "Total Fee",
				accessor: "TotalFeeAmount",
				Cell: row => (
					<div>{Formatter.FormatMoney(row.value)}</div>
				),
				Footer: (
					<span>
						<strong>{Formatter.FormatMoney(this.SumValues(list.map(d => d.TotalFeeAmount)))}</strong>
					</span>
				)
			},
			{
				Header: "Per Transaction Fee",
				accessor: "PerTransactionFee",
				Cell: row => (
					<div>{Formatter.FormatPercentFee(row.value)}</div>
				)
			}
		];
		return columns;
	}

	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}
	
	GetChartText = (txt) => {
		return txt.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
	}

	render() {

		const { Disabled } = this.props;
		const { alert, activeIndex, isLoading, model, vModel, programList, merchantList } = this.state;

		return (
			<>

				<LoadingComponent Show={isLoading} />

				{alert}

				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled || model == this.initialModel },
					{ Code: "Print", OnClick: this.Print, Disabled: Disabled },
					{ Code: "CreateReport", OnClick: this.HandleGetList, Disabled: Disabled },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card >
							<CardBody>
								<GenericExpansionPanel Title="Filter Panel">
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer>
												<GridItem xs={2}>
													<GenericSelectInput
														Name="Top"
														LabelMd={4}
														LabelText="Top"
														IsStatic={true}
														StaticData={[{ Value: 10, Text: "10" }, { Value: 20, Text: "20" }, { Value: 30, Text: "30" }, { Value: 40, Text: "40" }, { Value: 50, Text: "50" }, { Value: 100, Text: "100" }, { Value: 200, Text: "200" }]}
														KeyValueMember="Value"
														TextValueMember="Text"
														Value={model.Top}
														ValueChanged={this.HandleChange}
														Required
														IsInvalid={vModel.Top} />
												</GridItem>
												<GridItem xs={3}>
													<GenericSelectInput
														Name="ProgramTypeId"
														LabelMd={5}
														LabelText="Program"
														Method="GET"
														Url="/bankapi/v1.0/Bank/ValidBankProgramsForReports"
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="Description"
														Value={model.ProgramTypeId}
														ValueChanged={this.HandleChange}
													/>
												</GridItem>
												{activeIndex == 1 &&
													<GridItem xs={3}>
														<GenericSelectInput
															Name="UniqueClientId"
															LabelMd={4}
															LabelText="Client"
															Method="POST"
															Url="/bankapi/v1.0/BankCustomer/Search"
															Parameter={{}}
															DataRoot="Item"
															KeyValueMember="Id"
															RenderItem={this.RenderItemUniqueClient}
															Value={model.UniqueClientId}
															ValueChanged={this.HandleChange}
															CanClear />
													</GridItem>
												}
												<GridItem xs={2}>
													<GenericDateInput
														Name="FromDate"
														LabelMd={4}
														LabelText="From"
														Value={model.FromDate}
														ValueChanged={this.HandleChange}
														Required
														Utc
														IsInvalid={vModel.FromDate} />
												</GridItem>
												<GridItem xs={2}>
													<GenericDateInput
														Name="ToDate"
														LabelMd={4}
														LabelText="To"
														Value={model.ToDate}
														ValueChanged={this.HandleChange}
														Required
														Utc
														IsInvalid={vModel.ToDate} />
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={12}>
						<TabsComponent
							ActiveIndex={activeIndex}
							tabList={[
								{
									tabButton: "Programs",
									tabContent: (
										<ExecutiveSummaryTab
											columns={this.GetColumns(programList, "Programs", "ProgramName")}
											list={this.state.programList}
											txnCountChartData={this.state.txnCountChartData1}
											txnTypeChartData={this.state.txnTypeChartData1}
											feeChartData={this.state.feeChartData1}
										/>
									)
								},
								{
									tabButton: "Client",
									tabContent: (
										<ExecutiveSummaryTab
											columns={this.GetColumns(merchantList, "Client", "FullName")}
											list={this.state.merchantList}
											txnCountChartData={this.state.txnCountChartData2}
											txnTypeChartData={this.state.txnTypeChartData2}
											feeChartData={this.state.feeChartData2}
										/>
									)
								}
							]}
							tabIndexChanged={this.TabIndexChanged}
						/>
					</GridItem>
				</GridContainer>

			</>
		);
	}
}

ExecutiveSummary.propTypes = {
	classes: PropTypes.object
};

export default withStyles(styles)(ExecutiveSummary);