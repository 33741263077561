import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import { GenericGrid, GenericTitle } from "views/Components/Generic";
import { GridColumnType } from "views/Constants/Constant";
import ResourceHelper from "../../../core/ResourceHelper";

class GenericListScreen extends React.Component {
	constructor(props) {
		super(props);

		this.ColumnDefinitions = props.viewData.GridColumns.map(obj => {
			if(obj?.Title?.toLowerCase().includes("date")) {
				return {
					Header: ResourceHelper.CorrectAccountNames(obj.Title),
					accessor: obj.ColumnName,
					type: GridColumnType.DateTimeUtc,
					resizable: obj.Resizable,
					sortable: obj.Sortable,
					filterable: obj.Filterable,
					width: obj.Width,
					ColumnType: obj.ColumnType
				};
			}
			return {
				Header: ResourceHelper.CorrectAccountNames(obj.Title),
				accessor: obj.ColumnName,
				type: obj.Type,
				resizable: obj.Resizable,
				sortable: obj.Sortable,
				filterable: obj.Filterable,
				width: obj.Width,
				ColumnType: obj.ColumnType
			};
		});
	}

	RowSelected = index => {
		const { list, viewData, handleSelectModel } = this.props;
		if (!viewData.IsNotFillFromGrid) {
			const model = { ...list[index] };
			handleSelectModel(model, index);
		}
	}

	render() {
		const { list, viewData, model } = this.props;
		return (
			<div>
				<GridContainer>
					<GridItem xs={12}>
						<GridContainer style={{ marginBottom: "4px" }}>
							<GridItem xs={4} >
								<GenericTitle text={viewData.HeaderTableText} />
							</GridItem>
						</GridContainer>
					</GridItem>
				</GridContainer>
				<GridContainer>
					<GridItem xs={12}>
						<GenericGrid
							Data={list}
							Model={model}
							Columns={this.ColumnDefinitions}
							RowSelected={this.RowSelected}
							SelectedIndex={this.props.selected}
							DocumentTitle={viewData.CenterTitle}
							PageSize={10}
							ShowPagination={true}
							HideButton={model.DocumentButtons === false}
							Sorted={viewData.Sorted && viewData.Sorted.map(obj => {
								return {
									id: obj.ColumnName || obj.id,
									desc: obj.Desc || obj.desc
								};
							})}
						/>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

GenericListScreen.propTypes = {
	list: PropTypes.array.isRequired,
	selected: PropTypes.number,
	handleSelectModel: PropTypes.func,
	handleDocument: PropTypes.func,
	viewData: PropTypes.object.isRequired,
	model: PropTypes.object,
};

export default GenericListScreen;
