import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import { DetailIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDateInput, GenericDialog, GenericDialogActions, GenericGrid, GenericSelectInput, GenericTitle, GenericNumberInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton.jsx";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType, ProgramCodes } from "views/Constants/Constant.js";
import ResourceHelper from "../../../core/ResourceHelper";

class ClientActivities extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			model: {},
			isLoading: false,
			TxnDetail: {},
			IsTxnDetailOpen: false
		};

		this.txnDateRenderStyle = {
			column: "TransactionType",
			condition: "Incoming",
			style: { fontWeight: "600" },
			prefix: ["+", ""]
		};

		this.balanceDateRenderStyle = {
			column: "TransactionType",
			condition: "Incoming",
			style: { fontWeight: "600" },
			prefix: ["", ""]
		};

		this.ValueChanged = this.ValueChanged.bind(this);
		this.ClearClick = this.ClearClick.bind(this);
		this.SearchClick = this.SearchClick.bind(this);
	}

	componentDidMount() {
		const { setAppLeftTitle, setAppCenterTitle } = this.props;

		if (setAppLeftTitle)
			setAppLeftTitle("Client Activities");

		if (setAppCenterTitle)
			setAppCenterTitle("Wallets");
	}

	ValueChanged(name, newValue) {
		this.setState(state => {
			var model = state.model || {};
			model[name] = newValue;

			return { model };
		});
	}

	ClearClick() {
		this.setState({ model: {} });
	}

	SearchClick() {
		const { showMessage } = this.props;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/Transaction/SearchClientActivities",
			this.state.model,
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				responseData.Item.forEach( item => {
						item.AccountName = ResourceHelper.CorrectAccountNames(item.AccountName);
						item.Description = ResourceHelper.CorrectAccountNames(item.Description);
					})

				this.setState({ TransactionList: responseData.Item ?? [] });
			},
			error => {
				this.setState({ isLoading: false });
				showMessage("error", "Error", error);
			}
		);
	}

	render() {
		const { Disabled } = this.props;
		const { isLoading, alert, model, TransactionList, IsTxnDetailOpen, TxnDetail } = this.state;

		return (
			<div>
				<LoadingComponent Show={isLoading} />
				{alert}

				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<ButtonToolbar ButtonList={[
							{ Code: "Clear", OnClick: this.ClearClick, Disabled: Disabled },
							{ Code: "Search", OnClick: this.SearchClick, Disabled: Disabled }
						]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
						<Card>
							<CardHeader>
								<GenericTitle text={"Filter Panel"} />
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={3}>
										<GenericSelectInput
											Name="UniqueClientId"
											LabelText="Client"
											Method="POST"
											Url="/bankapi/v1.0/BankCustomer/Search"
											Parameter={{}}
											DataRoot="Item"
											KeyValueMember="Id"
											RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
											Value={model.UniqueClientId || ""}
											ValueChanged={this.ValueChanged}
											CanClear
											Disabled={Disabled} />
									</GridItem>
									<GridItem xs={3}>
										<GenericDateInput
											Name="TransactionBeginDate"
											LabelText="From Date"
											Value={model.TransactionBeginDate}
											ValueChanged={this.ValueChanged}
											Disabled={Disabled}
											MaxDate={model.TransactionEndDate || DateHelper.GetDate()}
											Utc />
									</GridItem>
									<GridItem xs={3}>
										<GenericDateInput
											Name="TransactionEndDate"
											LabelText="To Date"
											Value={model.TransactionEndDate}
											ValueChanged={this.ValueChanged}
											Disabled={Disabled}
											MinDate={model.TransactionBeginDate}
											MaxDate={DateHelper.GetDate()}
											Utc />
									</GridItem>
									<GridItem xs={3}>
										<GenericNumberInput
											NoFormatting Name="ReferenceNumber"
											LabelText="Reference Number"
											Value={model.ReferenceNumber}
											ValueChanged={this.ValueChanged} />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>

						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={"Transaction List"} />
							</CardHeader>
							<CardBody>
								<GridItem xs={12}>
									<GridItem>
										<GenericGrid
											Data={TransactionList}
											Columns={[
												{
													width: 100,
													Header: "Actions",
													sortable: false,
													filterable: false,
													Cell: row => (
														<GridButton
															tooltip="Detail"
															Icon={DetailIcon}
															Disabled={Disabled}
															OnClick={() => this.setState({ IsTxnDetailOpen: true, TxnDetail: row.original })} />
													)
												},
												{
													Header: "Client Name",
													accessor: "ClientName"
												},
												{
													Header: "Transaction Date",
													accessor: "TransactionDate",
													type: GridColumnType.DateTimeUtc,
													style: this.txnDateRenderStyle
												},
												{
													Header: "Incoming / Outgoing",
													accessor: "TransactionType",
													Cell: row => (
														<p style={{ fontWeight: row.original.TransactionType == "D" ? 600 : "normal" }}>{row.value == "D" ? "Received" : "Sent"}</p>
													)
												},
												{
													Header: "Currency",
													accessor: "CurrencyCode",
													Cell: row => (
														<p style={{ fontWeight: row.original.TransactionType == "D" ? 600 : "normal" }}>{row.value}</p>
													)
												},
												{
													Header: "Amount",
													accessor: "Amount",
													type: GridColumnType.Money,
													style: this.txnDateRenderStyle
												},
												{
													Header: "Remaining Balance",
													accessor: "RemainingBalance",
													type: GridColumnType.Money,
													style: this.balanceDateRenderStyle
												},
												{
													Header: "Wallet Name",
													accessor: "AccountName",
													Cell: row => (
														<p style={{ fontWeight: row.original.TransactionType == "D" ? 600 : "normal" }}>{row.value}</p>
													)
												},
												{
													Header: "Explanation",
													accessor: "Description"
												},

												{
													Header: "Wallet Number",
													accessor: "AccountNumber"
												},
												{
													Header: "Reference Number",
													accessor: "ReferenceNumber"
												}
											]}
											ProgramCode={ProgramCodes.Bank_Programs_Bank_Account} />
									</GridItem>
								</GridItem>
							</CardBody>
						</Card>

						<GenericDialog open={IsTxnDetailOpen} maxWidth="md" fullWidth>
							<DialogTitle>Transaction Details</DialogTitle>
							<DialogContent>
								<p> Reference Number: <b>{TxnDetail.ReferenceNumber}</b> </p>
								<p> Transaction Date: <b>{Formatter.FormatDateUtc(TxnDetail.TransactionDate)}</b> </p>
								<p> Transaction Amount:<b> {TxnDetail.Amount}</b> </p>
								<p> Description: <b>{TxnDetail.Description}</b> </p>
								<p> Transaction Type: <b>{TxnDetail.TransactionType}</b> </p>
								<p> Wallet Name: <b>{TxnDetail.AccountName}</b> </p>
								<p> Wallet Number: <b>{TxnDetail.AccountNumber}</b> </p>
							</DialogContent>
							<GenericDialogActions>
								<Button size="sm" onClick={() => this.setState({ IsTxnDetailOpen: false })}>Close</Button>
							</GenericDialogActions>
						</GenericDialog>
					</GridItem>
				</GridContainer>
			</div>
		);
	}

}

ClientActivities.propTypes = {
	Disabled: PropTypes.bool
};

ClientActivities.defaultProps = {
	Disabled: false
};

export default ClientActivities;