const KYCManualApplicationStatus = {
  OnHold: "0",
  Approved: "1",
  Reject: "2",
};
const KycManualApplicationReasonType = {
  CODE: "KycManualApplicationReasonType",
  CanadaPost: "CP",
  MissingDocument: "MD",
  Other: "O",
  Reject: "R",
};

const DigitalVerificationAction = {
  PopupUpload: "DigitalVerificationPhotoUpload",
};
const DigitalVerificationPopupUploadCode = {
  ManualKycEmailLink: "ManualKycEmailLink",
  ManualKyc: "ManualKyc",
  IdIntroduction: "IdIntroduction",
};

const IdentitySourceType = {
  CODE: "IdentitySourceType",
  ParameterDefinition: "P",
  DigitalIdentityVerificationIdentityType: "D",
};

const LinkRequestType = {
  CODE: "LinkRequestType",
  DigitalIdentityVerification: "D",
  AccountVerification: "A",
};

const KYCVerifiedStatus = {
  CODE: "KYCVerifiedStatus",
  Verified: "1",
  UnVerified: "2",
};

const ManualKycAppMenuAuthority = {
	InternalBlacklist: "KycInternalBlacklist",
	WatchList: "KycWatchList",
	TransUnion: "KycTransUnion",
	TransUnionId: "KycTransUnionId",
	DigitalIdVerification: "KycDigitalIdVerification",
	AccountVerification: "KycAccountVerification",
	Documents: "KycDocuments",
	CanadaPost: "KycCanadaPost",
	InPerson: "KycInPerson"
};

export {
  KYCManualApplicationStatus,
  KycManualApplicationReasonType,
  DigitalVerificationAction,
  DigitalVerificationPopupUploadCode,
  IdentitySourceType,
  LinkRequestType,
  KYCVerifiedStatus,
  ManualKycAppMenuAuthority,
};
