import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import {Proxy, withArtifex} from "core";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import {GenericAlert, GenericGrid} from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import {ProgramCodes, GridColumnType} from "views/Constants/Constant.js";
import Button from "../../components/CustomButtons/Button";

class CaaS extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            list: [],
            model: this.initialModel,
            isLoading: false,
            vModel: {},
            IsInsertOp: false,
            CaaSLinkProd: "",
        };

        this.maxLength1 = {maxLength: 1};
    }

    ShowMessage = (type, title, message) => {
        // type:-success->warning->info->error
        this.setState({
            alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()}/>
        });
        this.setState({isLoading: false});
    }
    hideAlert = () => {
        this.setState({
            alert: null,
            isLoading: false
        });
    }

    RedirectToCaas(url) {
        window.open(url, "_blank");
    }

    GetRedirectLinkForCaaS() {
        Proxy.GET("/coreapi/v1.0/caas/GetRedirectUrl",
            responseData => {
                if (!responseData.IsSucceeded) {
                    this.ShowMessage("error", "Error", responseData.ErrorDescription);
                    return;
                } else {
                    this.setState({CaaSLinkProd: responseData.Item});
                    this.setState({isLoading: false});

                    return;
                }
            },
            errorMessage => {
                this.ShowMessage("error", "CaaS error", errorMessage);
                return;
            });
    }

    componentDidMount() {
        this.props.setAppLeftTitle("CaaS Links");
        this.props.setAppCenterTitle("Programs");
        this.setState({isLoading: true});
        this.GetRedirectLinkForCaaS();
    }

    render() {
        const {alert, model, list, isLoading, index} = this.state;

        return (
            <div>
                <LoadingComponent Show={isLoading}/>
                {alert}
                <GridContainer spacing={16}>
                    <GridItem xs={12}>
                        <Card className="no-radius">
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12}>
                                        <GridContainer spacing={16}>
                                            <GridItem xs={5}>
                                            </GridItem>
                                            <GridItem xs={1}>
                                                <Button size="sm" color="black"
                                                        onClick={() => this.RedirectToCaas(this.state.CaaSLinkProd)}>
                                                    CaaS Login </Button>
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

CaaS.propTypes = {
    classes: PropTypes.object,
    setAppLeftTitle: PropTypes.func,
    setAppCenterTitle: PropTypes.func,
    Disabled: PropTypes.bool
};

export default withArtifex(CaaS, {});