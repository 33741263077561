import React, { Component } from "react";
import { withArtifex } from "core";
import GridItem from "components/Grid/GridItem";
import { Card } from "@material-ui/core";
import CardBody from "components/Card/CardBody";
import { GenericTextInput, GenericNumberInput, GenericSelectInput } from "views/Components/Generic";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";

class VisaDirectThirdPartyAddNewCustomer extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { vModel, customerModel, appSettings } = this.props;  //Bunlar yollanacak

		return (
			<Card
				style={{
					border: "1px solid gray",
					borderRadius: "2px",
					marginBlockEnd: "7px",
					overflow: "inherit",
				}}
			>
				<CardBody>
					<GridContainer>
						<GridItem xs={4}>
							<GenericTextInput
								Name="FirstName"
								LabelText="First Name"
								Value={customerModel.FirstName || ""}
								ValueChanged={this.HandleChangeCustomerModel}
								Required
								IsInvalid={vModel.FirstName}
								Disabled={!appSettings.IsModifyAllowed}
							/>
							<GenericTextInput
								Name="MiddleName"
								LabelText="Middle Name"
								Value={customerModel.MiddleName || ""}
								ValueChanged={this.HandleChangeCustomerModel}
								Disabled={!appSettings.IsModifyAllowed}
							/>
							<GenericTextInput
								Name="LastName"
								LabelText="Last Name"
								Value={customerModel.LastName || ""}
								ValueChanged={this.HandleChangeCustomerModel}
								Required
								IsInvalid={vModel.LastName}
								Disabled={!appSettings.IsModifyAllowed}
							/>
							<GenericNumberInput
								IsPhone
								Name="PhoneNumber"
								LabelText="Phone"
								Format="(###) ### ####"
								InputStyle={this.marginLeft2}
								Value={customerModel.PhoneNumber || ""}
								ValueChanged={this.HandleChangeCustomerModel}
								MaxLength={10}
								Disabled={!appSettings.IsModifyAllowed}
							/>
						</GridItem>
						<GridItem xs={3}>
							<GenericTextInput
								Name="AddressLine1"
								LabelText="Address Line 1"
								Id="street-address"
								Required
								Value={customerModel.AddressLine1 || ""}
								ValueChanged={this.HandleChangeCustomerModel}
								inputProps={{ maxLength: this.maxLength40 }}
								Disabled={!appSettings.IsModifyAllowed}
								IsInvalid={vModel.AddressLine1}
							/>
							<GenericTextInput
								Name="AddressLine2"
								Id="street-address2"
								LabelText="Address Line 2"
								Value={customerModel.AddressLine2 || ""}
								ValueChanged={this.HandleChangeCustomerModel}
								inputProps={{ maxLength: this.maxLength40 }}
								Disabled={!appSettings.IsModifyAllowed}
							/>
							<GenericTextInput
								Name="PostalCode"
								LabelText="Postal Code"
								Id="postcode"
								Value={customerModel.PostalCode || ""}
								ValueChanged={this.HandleChangeCustomerModel}
								inputProps={{ maxLength: this.maxLength6 }}
								Required
								IsInvalid={vModel.PostalCode}
								Disabled={!appSettings.IsModifyAllowed}
							/>
						</GridItem>
						<GridItem xs={3}>
							<GenericSelectInput
								Name="CountryId"
								LabelText="Country"
								Method="POST"
								Url="/coreapi/v1.0/Country/GetAll"
								Parameter={{ "TrackingID": this.TrackingID }}
								DataRoot="Item"
								KeyValueMember="Id"
								TextValueMember={"Code"}
								Sorted={{ Member: "OrderIndex" }}
								RenderItem={d => `+${d.Code} (${d.Name})`}
								Required

								Value={customerModel.CountryId || ""}
								ValueChanged={this.HandleChangeCustomerModel}
								Disabled={!appSettings.IsModifyAllowed}
								IsInvalid={vModel.CountryId}
							/>
							<GenericSelectInput
								key={customerModel.CountryId + "province"}
								Name="ProvinceId"
								LabelText="Province"
								Method="POST"
								Url="/coreapi/v1.0/Province/Filter"
								Parameter={{
									"CountryId": customerModel.CountryId,
									"TrackingID": this.TrackingID
								}}
								DataRoot="Item"
								KeyValueMember="Id"
								TextValueMember="Name"
								Required

								Value={customerModel.ProvinceId}
								ValueChanged={this.HandleChangeCustomerModel}
								Disabled={!appSettings.IsModifyAllowed}
								IsInvalid={vModel.ProvinceId}
							/>
							<GenericSelectInput
								key={"city" + customerModel.ProvinceId + ""}
								Name="CityId"
								LabelText="City"
								Method="POST"
								Url="/coreapi/v1.0/City/Filter"
								Parameter={{
									"CountryId": customerModel.CountryId,
									"ProvinceId": customerModel.ProvinceId,
									"TrackingID": this.TrackingID
								}}

								DataRoot="Item"
								KeyValueMember="Id"
								TextValueMember="Name"
								Required

								Value={customerModel.CityId}
								ValueChanged={this.HandleChangeCustomerModel}
								Disabled={!appSettings.IsModifyAllowed}
								IsInvalid={vModel.CityId}
							/>
						</GridItem>
						<GridContainer justify="flex-end">
							<GridItem style={{ float: "right", marginRight: "12px" }}>
								<div hidden={!appSettings.IsModifyAllowed}>
									<Button size="sm" color="black" onClick={() => this.HandleCustomerClear()}>Clear</Button>
									<Button size="sm" color="black" onClick={this.state.IsCustomerExists ? () => this.UpdateCustomer() : () => this.InsertCustomer()}
									>
										{this.state.IsCustomerExists ? "Update" : "Add"}
									</Button>
								</div>
							</GridItem>
						</GridContainer>
					</GridContainer>
				</CardBody>
			</Card>
		);
	}
}

export default withArtifex(VisaDirectThirdPartyAddNewCustomer, {});
