// @ts-nocheck
import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import DateHelper from "core/DateHelper";
import { TaskPool } from "core/TaskPool";
import PropTypes from "prop-types";
import React from "react";
import { GenericDateInput, GenericDialog, GenericDialogActions, GenericLabel, GenericNumberInput, GenericSelectInput,GenericCheckInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { CrudType, FileType,FileCode } from "views/Constants/Constant.js";


class ClientDisputeAction extends React.Component {
	constructor(props) {
		super(props);
		this.fileInputRef = React.createRef();

		this.state = {
			selected: -1,
			isLoading: false,
			model: {}
		};
		this.loadTaskPool = new TaskPool(this.handleLoadTaskPoolAppend, this.handleLoadTaskPoolCompleted);
		this.maxLength150 = { maxLength: 150 };
		this.maxLength6 = { maxLength: 6 };
	}

	componentDidMount() {
		if (this.props.KnownCustomer)
			this.HandleClickByKeyPressed();
	}

	ValueChanged = (name, newValue, data) => {
		this.props.onModelChange(model => {
			model[name] = newValue;
			return model;
		});
	}
	Upload = () => {
		this.fileInputRef.current.click();
	}
	FileSelect = (e) => {
		const { disputeModel } = this.props;
		this.setState({ isLoading: true });
		e.preventDefault();

		var file = e.target.files[0];
		if (!file) {
			return;
		}
		var { model } = this.state;


		if (disputeModel)
			if (disputeModel.File)
				model.TempFileId = disputeModel.File.Id;
			else
				model.TempFileId = model.File !== undefined
					? model.File.Id
					: 0;

		else
			model.TempFileId = model.File !== undefined
				? model.File.Id
				: 0;

		model.File = file;
		model.FileName = file.name;
		model.FileSelect = true;



		this.setState({ isLoading: false, model });

	}
	HandleClickReject = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/ClearingDispute/RejectDispute",
			this.state.model.DisputeId,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.props.showMessage("success", "Success", "Dispute Rejected Succesfuly!");
					this.setState({ isLoading: false, model: {} });
					this.props.onActionModelClose();
					return;
				}
				this.setState({ isLoading: false });
			},
			e => {
				this.setState({ isLoading: false, data: [] });
			}
		);
	}

	HandleClickSubmit = () => {

		const { disputeModel, transactionData } = this.props;
		const { model } = this.state;
		var formData = new FormData();


		if (model.File) {
			formData.append("file", model.File, model.FileName);
			formData.append("fileId", model.TempFileId || 0);
			formData.append("fileCode", FileCode.Dispute);
			formData.append("isBinaryOrBase64", FileType.Binary);
			formData.append("isInsertOrUpdate", model.TempFileId == null ? CrudType.Insert : CrudType.Update);
		}

		formData.append("TransactionDataId", transactionData.Id);
		formData.append("ClientDocumentStatusId", disputeModel.ClientDocumentStatusId);
		formData.append("ClientDisputeReasonCodeId", disputeModel.ClientDisputeReasonCodeId);
		formData.append("Explanation", disputeModel.Explanation);
		formData.append("DisputeAmount", disputeModel.DisputeAmount);
		formData.append("DisputeCurrencyId", disputeModel.DisputeCurrencyId);
		formData.append("DisputeId", disputeModel.Id);

		this.loadTaskPool.AppendTask(
			Proxy.FileUpload(
				"/prepaidapi/v1.0/ClearingDispute/ClientDisputeInsert",
				formData,
				(responseData) => {
					this.setState({ isLoading: false });
					if (!responseData.IsSucceeded) {
						this.props.showMessage("error", "Error", responseData.ErrorDescription);
						this.props.onActionModelClose();

						return;
					}
					else {
						this.setState({ isLoading: false });
						this.props.showMessage("success", "Success", "Dispute Transaction Succesfuly!");
						this.props.onActionModelClose();
					}
				},
				(error) => {
					this.setState({ isLoading: false });
				}
			)
		);
	}


	CancelSelectCallback = () => {
		this.props.onActionModelClose();
	}
	RenderCurrencySelect = (d) => {
		return d.Code + " - " + d.Description;
	}


	render() {
		const { model } = this.state;
		const { disputeModel, transactionData } = this.props;
		const { isLoading } = this.state;


		return (
			<>
				{this.state.alert}
				<LoadingComponent Show={isLoading} />
				<GenericDialog open={this.props.isDisputeActionDialogOpen} onBackdropClick={this.CancelSelectCallback} maxWidth="xl" fullWidth keepMounted={true} style={{ zIndex: 8 }} >
					<DialogTitle><GenericLabel FontSize="16px" Text="Detail" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ marginTop: 8 }}>
						<GridContainer>
							<GridItem xs={12}>
								<Card>
									<CardHeader>
										<GenericTitle text={"Transaction Detail"} />
									</CardHeader>
									<CardBody>
										<GridContainer >
											<GridItem xs={4}>
												<GenericTextInput
													Name="CardNumber"
													LabelText="Masked Card Number"
													Value={transactionData.MaskedCardNumber}
													ValueChanged={this.ValueChanged}
													Disabled={true}
												/>
												<GenericTextInput
													Name="OrginalAmount"
													LabelText="Orginal Amount"
													Value={transactionData.BillingAmount}
													ValueChanged={this.ValueChanged}
													Disabled={true}
												/>


											</GridItem>
											<GridItem xs={4}>
												<GenericDateInput
													Name="TransactionDate"
													LabelText="Transaction Date"
													Value={DateHelper.ToMoment(transactionData.TransactionDate)}
													ValueChanged={this.ValueChanged}
													Disabled={true}
													DateFormat="DD/MMM/YY HH:mm:ss" />

												<GenericDateInput
													Name="SettlementDate"
													LabelText="Settlement Date"
													Value={DateHelper.ToMoment(transactionData.SettlementDate)}
													ValueChanged={this.ValueChanged}
													Disabled={true}
													DateFormat="DD/MMM/YY HH:mm:ss"
												/>
											</GridItem>
											<GridItem xs={4}>
												<GenericCheckInput
													Name="IsOnus"
													LabelText="Is Onus"
													Value={transactionData.IsOnus}
													Disabled={true} />
											</GridItem>
										</GridContainer>


									</CardBody>
								</Card>
							</GridItem>
						</GridContainer>
						<GridContainer>
							<GridItem xs={12}>
								<Card>
									<CardHeader>
										<GenericTitle text={"Dispute"} />
									</CardHeader>
									<CardBody>
										<GridContainer >

											<GridItem xs={4}>

												<GenericSelectInput
													Name="DisputeCurrencyId"
													LabelText="Currency"
													Value={disputeModel.DisputeCurrencyId}
													ValueChanged={this.ValueChanged}
													KeyValueMember="Id"
													TextValueMember="Description"
													Method="GET"
													Url="/bankapi/v1.0/Currency/ValidCurrencies"
													RenderItem={this.RenderCurrencySelect}
													DataRoot="Item" />


												<GenericNumberInput
											     	MaxLength={6}
													Name="DisputeAmount"
													LabelText="Dispute Amount"
													Value={disputeModel.DisputeAmount}
													ValueChanged={this.ValueChanged}
												/>

											</GridItem>

											<GridItem xs={4}>
												<ParameterComponent
													Name="ClientDisputeReasonCodeId"
													LabelText="Client Dispute Reason Code"
													ParameterCode="ClientDisputeReasonCode"
													Value={disputeModel.ClientDisputeReasonCodeId}
													ValueChanged={this.ValueChanged}
													disabled={true} />

												<ParameterComponent
													Name="ClientDocumentStatusId"
													LabelText="Client Document Status"
													ParameterCode="ClientDocumentStatus"
													Value={disputeModel.ClientDocumentStatusId}
													ValueChanged={this.ValueChanged} />

											</GridItem>

											<GridItem xs={4}>
												<input
													type="file"
													key={disputeModel.FileName}
													style={{ display: "none" }}
													onChange={this.FileSelect}
													ref={this.fileInputRef}
												/>

												<div>
													<GenericLabel Text="Upload Document" />
													<Button size="sm" onClick={this.Upload} disabled={model.Disabled} >BROWSE</Button>
												</div>



											</GridItem>
										</GridContainer>
										<GridContainer>
											<GridItem xs={8}>
												<GenericTextInput
													MultiLine={true}
													RowCount={2}
													LabelMd={2}
													inputProps={this.maxLength150}
													Name="Explanation"
													LabelText="Explanation"
													Value={disputeModel.Explanation}
													ValueChanged={this.ValueChanged}
													Disabled={false}
												/>
											</GridItem>
										</GridContainer>

									</CardBody>
								</Card>
							</GridItem>

						</GridContainer>

					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.HandleClickSubmit}>Submit</Button>
						<Button size="sm" onClick={this.CancelSelectCallback}>Cancel</Button>
						<Button size="sm" onClick={this.HandleClickReject}>Reject</Button>
					</GenericDialogActions>
				</GenericDialog>
			</>
		);
	}
}

ClientDisputeAction.propTypes = {
	classes: PropTypes.object.isRequired,
	model: PropTypes.object,
	Disabled: PropTypes.bool
};

export default ClientDisputeAction;
