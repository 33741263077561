import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import Formatter from "core/Formatter";
import PropTypes, { bool, func } from "prop-types";
import React, { Component } from "react";
import AccountComponent from "views/Components/AccountComponent.jsx";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericNumberInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { TransactionType } from "views/Constants/Constant.js";

class EftIncomingApprove extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			ScreenDisabled: true,
			SourceAccountModel: {
				AccountNumber: ""
			},
			DestinationAccountModel: {
				FinancialInstitutionId: "",
				FinancialInstitutionBranchId: "",
				AccountNumber: ""
			}
		};
	}

	componentDidMount() {
		this.props.setAppLeftTitle("EFT Incoming Transaction Approve");
		this.props.setAppCenterTitle("EFT SYSTEM");
	}

	HandleSubmit = () => {
		this.showIsLoading();
		Proxy.GET(
			"/bankapi/v1.0/BankBmoEFT/InComingApproval/Id?Id=" + this.state.model.TransactionEftId,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.setState({
						isLoading: false,
						alert: <GenericAlert Title={"Error"} Message={"Approval operation error : " + responseData.ErrorDescription + "!!!"} Type={"error"} OnConfirm={() => this.hideAlert()} />
					});
				}
				else {
					this.setState({
						isLoading: false,
						alert: <GenericAlert Title={"Success"} Message={"Approval operation is successfully completed."} Type={"success"} OnConfirm={() => this.hideAlert()} />
					});
				}
			},
			error => {
				this.setState({
					isLoading: false,
					alert: <GenericAlert Title={"Error"} Message={"Approval operation error : " + error + "!!!"} Type={"error"} OnConfirm={() => this.hideAlert()} />
				});
			}
		);
	}

	HandleCancel = () => {
		this.showIsLoading();
		Proxy.POST(
			"/bankapi/v1.0/BankBmoEFT/InComingCancel/Id?Id=" + this.props.fileInfomodel.Id,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.setState({
						isLoading: false,
						alert: <GenericAlert Title={"Error"} Message={"Cancel operation error : " + responseData.ErrorDescription + "!!!"} Type={"error"} OnConfirm={() => this.hideAlert()} />
					});
				}
				else {
					this.setState({
						isLoading: false,
						alert: <GenericAlert Title={"Success"} Message={"cancel operation is successfully completed."} Type={"success"} OnConfirm={() => this.hideAlert()} />
					});
				}
			},
			error => {
				this.setState({
					isLoading: false,
					alert: <GenericAlert Title={"Error"} Message={"Cancel operation error : " + error + "!!!"} Type={"error"} OnConfirm={() => this.hideAlert()} />
				});
			}
		);
	}

	FillData = (model) => {
		this.setState({ model });
	}

	errorCallback = (error) => {
		this.showErrorMessage("An error occurred during the api visit" + error);
	}

	showErrorMessage = (message) => {
		this.setState({
			isLoading: false,
			alert: <GenericAlert Title={"Error"} Message={message} Type="Error" ShowCancel={false} OnConfirm={() => this.hideAlert()} />
		});
	}

	hideAlert = () => { this.setState({ alert: null }); }

	showIsLoading = () => { this.setState({ isLoading: true, alert: null }); }

	hideIsLoading = () => { this.setState({ isLoading: false, alert: null }); }

	render() {
		const { Disabled } = this.props;
		const { isLoading, ScreenDisabled, model, SourceAccountModel, DestinationAccountModel } = this.state;

		return (
			<div>
				<LoadingComponent Show={isLoading} />
				{this.state.alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Submit", OnClick: this.HandleSubmit, Disabled: Disabled, FillDataFromModel: this.FillData },
					{ Code: "Cancel", OnClick: this.HandleCancel, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer>
					<GridItem xs={4}>
						<Card style={{ height: "90%" }}>
							<CardHeader>
								<GenericTitle text={"Client Information"} />
							</CardHeader>
							<CardBody>
								<GenericTextInput
									Name="UniqueClientId"
									LabelText="Unique Client Id"
									LabelMd={6}
									Value={model.UniqueClientId ? model.UniqueClientId : ""}
									Disabled={ScreenDisabled} />
								<GenericTextInput
									Name="ClientType"
									LabelText="Client Type"
									LabelMd={6}
									Value={model.ClientType ? model.ClientType : ""}
									Disabled={ScreenDisabled} />
								<GenericTextInput
									Name="ClientName"
									LabelText="Client Name"
									LabelMd={6}
									Value={model.ClientName ? model.ClientName : ""}
									Disabled={ScreenDisabled} />
								{model.ClientType && model.ClientType.substring(0, 1) == TransactionType.Credit &&
									<GenericNumberInput
										Name="Incoming Credit Limit"
										LabelText="PerTransactionIncomingCreditLimit"
										LabelMd={6}
										Value={model.PerTransactionIncomingCreditLimit ? model.PerTransactionIncomingCreditLimit : ""}
										Prefix="$"
										Disabled={ScreenDisabled} />
								}
								{model.ClientType && model.ClientType.substring(0, 1) == TransactionType.Debit &&
									<GenericNumberInput
										Name="Incoming Debit Limit"
										LabelText="PerTransactionIncomingDebitLimit"
										LabelMd={6}
										Value={model.PerTransactionIncomingDebitLimit ? model.PerTransactionIncomingDebitLimit : ""}
										Prefix="$"
										Disabled={ScreenDisabled} />
								}
								<AccountComponent
									LabelText="Wallet"
									Disabled={true}
									IsDCBankAccount
									Model={SourceAccountModel} />
							</CardBody>
						</Card>
						<br />
					</GridItem>
					<GridItem xs={4}>
						<Card style={{ height: "90%" }}>
							<CardHeader>
								<GenericTitle text={"Transaction Details"} />
							</CardHeader>
							<CardBody>
								<GenericDateInput
									Name="TransactionDate"
									LabelText="Transaction Date"
									LabelMd={6}
									Value={model.TransactionDate ? Formatter.FormatDate(model.TransactionDate) : ""}
									Disabled={ScreenDisabled} />
								<GenericTextInput
									Name="TransactionType"
									LabelText="Transaction Type"
									LabelMd={6}
									Value={model.TransactionType ? model.TransactionType : ""}
									Disabled={ScreenDisabled} />
								<GenericNumberInput
									Name="TransactionAmount"
									LabelText="Transaction Amount"
									LabelMd={6}
									Value={model.TransactionAmount ? model.TransactionAmount : ""}
									Prefix="$"
									Disabled={true} />
								<GenericTextInput
									Name="ProcessStatus"
									LabelText="Process Status"
									LabelMd={6}
									Value={model.ProcessStatus ? model.ProcessStatus : ""}
									Disabled={ScreenDisabled} />
								<GenericTextInput
									Name="EFTType"
									LabelText="EFTType"
									LabelMd={6}
									Value={model.EFTType ? model.EFTType : ""}
									Disabled={ScreenDisabled} />
								<AccountComponent
									LabelText="External Wallet"
									Disabled={true}
									Model={DestinationAccountModel} />
							</CardBody>
						</Card>
						<br />
					</GridItem>
					<GridItem xs={4}>
						<Card style={{ height: "90%" }}>
							<CardHeader>
								<GenericTitle text={"File Information"} />
							</CardHeader>
							<CardBody>
								<GenericTextInput
									Name="FileName"
									LabelText="File Name"
									Value={model.FileName == null ? model.FileName : ""}
									LabelMd={6}
									Disabled={ScreenDisabled} />
								<GenericDateInput
									Name="FileDate"
									LabelText="File Date"
									Value={model.FileDate == null ? Formatter.FormatDate(model.FileDate) : ""}
									LabelMd={6}
									Disabled={ScreenDisabled} />
								<GenericDateInput
									Name="FileDate"
									LabelText="File Time"
									Value={model.FileDate ? Formatter.FormatTime(model.FileDate) : ""}
									LabelMd={6}
									DateFormat="HH:mm:ss"
									IncludeTime={false}
									Disabled={ScreenDisabled} />
							</CardBody>
						</Card><br />
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

EftIncomingApprove.propTypes = {
	classes: PropTypes.object,
	handleOperationType: PropTypes.func,
	showQuestionMessage: func,
	setAppLeftTitle: func,
	setAppCenterTitle: func,
	Disabled: bool
};

export default withArtifex(EftIncomingApprove, {});