import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Proxy, withArtifex } from "core";
import ResourceHelper from "core/ResourceHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import { GenericTitle, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";

class WorkflowStepDefinition extends React.Component {
	constructor(props) {
		super(props);

		this.defaultModel = {
			Id: undefined,
			MenuId: undefined,
			MenuActionId: undefined,
			StepName: "",
			StepNr: undefined,
			MethodId: undefined,
			GroupId: undefined
		};

		this.state = {
			vModel: {},
			isLoading: false,
			IsUpdate: false,
			StepList: [],
			data: [],
			SelectedRowIndex: undefined,
			model: { ...this.defaultModel }
		};

		this.EmptyObject = {};

		this.sortedName = {
			Member: "Name"
		};
		this.sortedCode = {
			Member: "Code"
		};

		this.sortedGrid = [{
			id: "StepNr",
			desc: false
		}];

		this.ValueChanged = this.ValueChanged.bind(this);
		this.SubmitClick = this.SubmitClick.bind(this);
		this.Insert = this.Insert.bind(this);
		this.Update = this.Update.bind(this);
		this.DeleteClick = this.DeleteClick.bind(this);
		this.Delete = this.Delete.bind(this);
		this.ClearClick = this.ClearClick.bind(this);
		this.LoadStepList = this.LoadStepList.bind(this);
		this.RowSelected = this.RowSelected.bind(this);
	}

	componentDidMount() {
		const { setAppLeftTitle, setAppCenterTitle } = this.props;

		if (setAppLeftTitle)
			setAppLeftTitle("Workflow Step Definition");
		if (setAppCenterTitle)
			setAppCenterTitle("SYSTEM ADMIN");
	}

	SubmitClick() {
		if (!this.Validate())
			return;

		const { IsUpdate } = this.state;

		if (IsUpdate) {
			this.Update();
		}
		else {
			this.Insert();
		}
	}

	Insert() {
		const { model } = this.state;

		var request = {
			MenuActionId: model.MenuActionId,
			Name: model.StepName,
			StepNr: model.StepNr,
			WorkflowMethodId: model.MethodId,
			WorkflowUserGroupId: model.GroupId
		};

		this.setState({ isLoading: true });
		Proxy.POST("/coreapi/v1.0/WorkflowStep/InsertWorkflowStep",
			request,
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "An error occurred while inserting data", responseData.ErrorDescription);
					return;
				}

				this.props.showMessage("success", "Workflow Step successfully saved.");

				this.LoadStepList(model.MenuActionId);
				this.HandleSearch();


				model.Id = responseData.Item.Id;
				this.setState({ model, IsUpdate: true });
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "An error occurred while inserting data", errorMessage);
			});
	}

	Update() {
		const { model } = this.state;

		var request = {
			Id: model.Id,
			Name: model.StepName,
			StepNr: model.StepNr,
			WorkflowMethodId: model.MethodId,
			WorkflowUserGroupId: model.GroupId
		};

		this.setState({ isLoading: true });
		Proxy.POST("/coreapi/v1.0/WorkflowStep/UpdateWorkflowStep",
			request,
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "An error occurred while updating data", responseData.ErrorDescription);
					return;
				}

				this.props.showMessage("success", "Workflow Step successfully updated.");

				this.LoadStepList(model.MenuActionId);
				this.HandleSearch();

				this.setState({ model });
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "An error occurred while inserting data", errorMessage);
			});
	}

	Validate() {
		const { model, StepList, IsUpdate, vModel } = this.state;

		var messages = [];
		if (model.MenuActionId == null) {
			vModel.MenuActionId = true;
			messages.push("Menu Action is not selected.");
		} else { vModel.MenuActionId = false; }

		if (model.StepName == null || model.StepName == "") {
			vModel.StepName = true;
			messages.push("Step Name cannot be null.");
		} else { vModel.StepName = false; }

		if (model.StepNr == null || model.StepNr == "") {
			vModel.StepNr = true;
			messages.push("Step Number cannot be null.");
		} else { vModel.StepNr = false; }

		if (StepList.some(x => (!IsUpdate || x.Id == model.Id) && x.StepNr == model.StepNr)) messages.push("Step Number already defined.");

		if (model.GroupId == null) {
			vModel.GroupId = true;
			messages.push("Approve Group is not selected.");
		} else { vModel.GroupId = false }

		if (model.MethodId == null) {
			vModel.MethodId = true;
			messages.push("Method is not selected.");
		} else { vModel.MethodId = false }
		this.setState({ vModel })
		if (messages.length > 0) {
			this.props.showMessage("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		return true;
	}

	HandleSearch = () => {
		this.setState({ isLoading: true });
		Proxy.GET(
			"/coreapi/v1.0/WorkflowStep/GetAllForSearch",
			(responseData) => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", "Sorry, an error has occurred");
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ data: responseData.Item });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	ValueChanged(name, newValue) {
		if (name == "MenuActionId") {
			this.LoadStepList(newValue);
		}

		this.setState(state => {
			var model = state.model || {};
			model[name] = newValue;

			if (name == "MenuId") {
				model.MenuActionId = undefined;
				state.StepList = [];
				state.SelectedRowIndex = undefined;
			}

			if (name == "MenuId" || name == "MenuActionId") {
				model.Id = undefined;
				model.StepName = "";
				model.StepNr = undefined;
				model.MethodId = undefined;
				model.GroupId = undefined;
				state.IsUpdate = false;
			}

			return { model };
		});
	}

	LoadStepList(menuActionId) {
		this.setState({ isLoading: true });
		Proxy.POST("/coreapi/v1.0/WorkflowStep/GetByMenuId",
			menuActionId,
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "An error occurred while fetching data", responseData.ErrorDescription);
					return;
				}

				this.setState({ StepList: responseData.Item, SelectedRowIndex: undefined });
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "An error occurred while fetching data", errorMessage);
			});
	}

	ClearClick() {
		this.setState({ model: { ...this.defaultModel }, IsUpdate: false, StepList: [], SelectedRowIndex: undefined, vModel: {}, data : [] });
	}

	DeleteClick() {
		this.props.showConfirmMessage("warning", "Workflow Step Confirmation", "Are you sure to delete this step?", () => {
			this.Delete();
		});
	}

	Delete() {
		const { model } = this.state;

		this.setState({ isLoading: true });
		Proxy.POST("/coreapi/v1.0/WorkflowStep/DeleteWorkflowStep",
			model.Id,
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "An error occurred while deleting data", responseData.ErrorDescription);
					return;
				}

				this.props.showMessage("success", "Workflow Step successfully deleted.");

				this.LoadStepList(model.MenuActionId);

				model.Id = undefined;
				model.StepName = "";
				model.StepNr = undefined;
				model.MethodId = undefined;
				model.GroupId = undefined;

				this.setState({ model, IsUpdate: false });
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "An error occurred while deleting data", errorMessage);
			});
	}

	GetMenuName(menu) {
		var menuName = ResourceHelper.GetForMenu(menu.Name);

		return `${!menuName ? "! " + menu.Name : menuName} - ${menu.Code}`;
	}

	GetRenderItemAction(menu) {
		return `${menu.Action.Code} - ${menu.Action.Description}`;
	}

	RowSelected(index) {
		// this.setState({ model: { ...data }, IsUpdate: true });
		this.setState(state => {
			state.SelectedRowIndex = index;

			var row = state.StepList[index];
			state.model.Id = row.Id;
			state.model.StepName = row.StepName;
			state.model.StepNr = row.StepNr;
			state.model.MethodId = row.MethodId;
			state.model.GroupId = row.GroupId;

			state.IsUpdate = true;

			return state;
		});
	}

	render() {
		const { Disabled } = this.props;
		const { isLoading, model, StepList, SelectedRowIndex, IsUpdate, vModel,data } = this.state;

		return (
			<>
				<LoadingComponent Show={isLoading} />
				<ButtonToolbar ButtonList={[
					{ Code: "Submit", OnClick: this.SubmitClick, Disabled: Disabled },
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
					{ Code: "Delete", OnClick: this.DeleteClick, Disabled: Disabled || !IsUpdate },
					{ Code: "Clear", OnClick: this.ClearClick, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<Card>
					<CardHeader>
						<GenericTitle text={"Step Info"} />
					</CardHeader>
					<CardBody>
						<GridContainer>
							<GridItem xs={4}>
								<GenericSelectInput
									Name="MenuId"
									LabelText="Menu"
									Value={model.MenuId}
									ValueChanged={this.ValueChanged}
									Disabled={Disabled}
									Method="GET"
									Url="/coreapi/v1.0/Menu/GetMenuListForRight"
									DataRoot="MenuList"
									KeyValueMember="Id"
									RenderItem={this.GetMenuName}
									Sorted={this.sortedName} />
							</GridItem>
							<GridItem xs={4}>
								<GenericSelectInput
									key={"WSDMenuAction_" + model.MenuId}
									Name="MenuActionId"
									LabelText="Action"
									Value={model.MenuActionId}
									ValueChanged={this.ValueChanged}
									Disabled={Disabled}
									Method="POST"
									Url="/coreapi/v1.0/MenuAction/GetByMenuId"
									Parameter={model.MenuId}
									DataRoot="Item"
									KeyValueMember="Id"
									RenderItem={this.GetRenderItemAction}
									Sorted={this.sortedCode}
									Required
									IsInvalid={vModel.MenuActionId}
								/>
							</GridItem>
							<GridItem xs={4} />
							<GridItem xs={4}>
								<GenericTextInput
									Name="StepName"
									LabelText="Step Name"
									Value={model.StepName}
									ValueChanged={this.ValueChanged}
									Disabled={Disabled}
									Required
									IsInvalid={vModel.StepName}
								/>
							</GridItem>
							<GridItem xs={4}>
								<GenericNumberInput
									Name="StepNr"
									LabelText="Step Number"
									Value={model.StepNr}
									ValueChanged={this.ValueChanged}
									Disabled={Disabled}
									DecimalScale={0}
									Required
									IsInvalid={vModel.StepNr}
								/>
							</GridItem>
							<GridItem xs={4} />
							<GridItem xs={4}>
								<GenericSelectInput
									Name="GroupId"
									LabelText="Approve Group"
									Value={model.GroupId}
									ValueChanged={this.ValueChanged}
									Disabled={Disabled}
									Method="GET"
									Url="/coreapi/v1.0/WorkflowUserGroup/GetAll"
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="Name"
									Sorted={this.sortedName}
									Required
									IsInvalid={vModel.GroupId}
								/>
							</GridItem>
							<GridItem xs={4}>
								<GenericSelectInput
									Name="MethodId"
									LabelText="Method"
									Value={model.MethodId}
									ValueChanged={this.ValueChanged}
									Disabled={Disabled}
									Method="GET"
									Url="/coreapi/v1.0/WorkflowMethod/GetAll"
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="Name"
									Sorted={this.sortedName}
									Required
									IsInvalid={vModel.MethodId}
								/>
							</GridItem>
							<GridItem xs={4} />
						</GridContainer>
					</CardBody>
				</Card>
				<GenericGrid
					Data={data}
					Columns={[
						{
							Header: "Step Name",
							accessor: "StepName"
						},
						{
							Header: "Step Number",
							accessor: "StepNumber"
						},
						{
							Header: "Method Name",
							accessor: "MethodName"
						},
						{
							Header: "Approve Group Name",
							accessor: "GroupName"
						}
					]}
					RowSelected={this.RowSelected}
					SelectedIndex={SelectedRowIndex}
					Sorted={this.sortedGrid} />
			</>
		);
	}
}

WorkflowStepDefinition.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	menuId: PropTypes.number.isRequired,
	ApprovalData: PropTypes.object,
	After: PropTypes.func,
	Disabled: PropTypes.bool,
	showMessage: PropTypes.func,
	showMessageNode: PropTypes.func,
	showConfirmMessage: PropTypes.func
};

export default withArtifex(WorkflowStepDefinition, {});