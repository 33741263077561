import withStyles from "@material-ui/core/styles/withStyles";
import styles from "assets/jss/material-dashboard-pro-react/views/riskRatingStyle.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import AlertHelper from "core/AlertHelper";
import PropTypes from "prop-types";
import React from "react";
import { GenericCheckInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { OperationType, RiskLightRating } from "views/Constants/Constant.js";

class RiskRatingQuestionaries extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			alert: null,
			checkedValues: [],
			isLoading: false,
			list: [],
		};

		this.handleCheck = this.handleCheck.bind(this);

		this.handleGetList = this.handleGetList.bind(this);
		this.successListCallback = this.successListCallback.bind(this);
		this.errorListCallback = this.errorListCallback.bind(this);

		this.handleSubmit = this.handleSubmit.bind(this);
		this.successSubmitCallback = this.successSubmitCallback.bind(this);
		this.errorSubmitCallback = this.errorSubmitCallback.bind(this);

		this.showSuccessMessage = this.showSuccessMessage.bind(this);
		this.hideAlert = this.hideAlert.bind(this);
	}

	componentDidMount() {
		this.handleGetList();
	}

	handleCheck(e, q, t) {
		const checkedValues = [...this.state.checkedValues];
		const cv = checkedValues.find(x => x.QuestionId == q.QuestionId);
		var val = cv.IsYes;
		if (t == 1) {
			cv.IsYes = val === true ? null : true;
		} else {
			cv.IsYes = val === false ? null : false;
		}

		this.state.list.map(d => {
			var model = d.CustomerRiskRatings.find(x => x.QuestionId == cv.QuestionId);
			if (model)
				model.IsYes = cv.IsYes;

			return null;
		});

		this.setState({ checkedValues, ...this.state.list });
	}

	handleGetList() {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/CustomerRiskRating/GetAll",
			{
				CustomerRiskRatingAgreementId: this.props.customerRiskRatingAgreementId,
				ClientType: this.props.clientType
			},
			this.successListCallback,
			this.errorListCallback
		);
	}

	successListCallback(responseData) {
		this.setState({ isLoading: false });
		if (!responseData.IsSucceeded) {
			this.props.showMessage("error", "Error", responseData.ErrorDescription);
			return;
		}
		if (responseData.Item !== null) {
			this.setState({ list: responseData.Item });
			var checkedValues = [];
			responseData.Item.map(d => {
				return (d.CustomerRiskRatings.map(q => {
					checkedValues.push({
						Id: q.Id,
						QuestionId: q.QuestionId,
						QuestionTypeId: q.QuestionTypeId,
						IsYes: q.IsYes,
						LightRatingId: q.Question.LightRatingId,
						LightRating: q.Question.LightRating
					});

					return null;
				}));
			});
			this.setState({ checkedValues });
		} else {
			this.setState({ list: [] });
		}
	}

	errorListCallback(error) {
		this.setState({ isLoading: false });
		this.props.showMessage("error", "Error", error);
	}

	handleSubmit() {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/CustomerRiskRating/InsertOrUpdateAgreement",
			{
				Id: this.props.customerRiskRatingAgreementId,
				ReviewDate: this.props.reviewDate,
				UniqueClientId: this.props.uniqueClientId,
				MediumOverrideFactor: this.props.MediumOverrideFactor,
				HighOverrideFactor: this.props.HighOverrideFactor,
				StopOverrideFactor: this.props.StopOverrideFactor
			},
			this.successSubmitCallback,
			this.errorSubmitCallback
		);
	}

	successSubmitCallback(responseData) {
		const { checkedValues } = this.state;
		if (!responseData.IsSucceeded) {
			this.props.showMessage("error", "Error", responseData.ErrorDescription);
			return;
		}
		Proxy.POST(
			"/bankapi/v1.0/CustomerRiskRating/InsertOrUpdate",
			checkedValues.map(q => {
				return {
					Id: q.Id,
					CustomerRiskRatingAgreementId: responseData.Item.Id,
					QuestionId: q.QuestionId,
					QuestionTypeId: q.QuestionTypeId,
					IsYes: q.IsYes
				};
			}),
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.showSuccessMessage("Saved succesfully!");
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	errorSubmitCallback(error) {
		this.setState({ isLoading: false });
		this.props.showMessage("error", "Error", error);
	}

	showSuccessMessage(message) {
		this.setState({
			alert: AlertHelper.CreateAlert("Success", message, "success", () => {
				this.hideAlert();
				this.props.handleOperationType(OperationType.DEFAULT);
				this.props.handleGetList();
			})
		});
	}

	hideAlert() {
		this.setState({ alert: null });
	}

	render() {
		const { classes, Disabled } = this.props;
		const { checkedValues, isLoading } = this.state;
		return (
			<div>
				<LoadingComponent Show={isLoading} />

				<GridContainer spacing={16}>
					{this.state.alert}
					<GridItem xs={12}>
						<GridContainer>
							<GridItem xs={4} />
							<GridItem xs={8}>
								<GridContainer justify="flex-end">
									<GridItem xs={1}>
										<Button
											disabled={Disabled}
											size="sm"
											onClick={() => this.props.handleOperationType(OperationType.DEFAULT)}
											fullWidth
										>
											Back
										</Button>
									</GridItem>
									<GridItem xs={1}>
										<Button
											size="sm"
											onClick={this.handleSubmit}
											fullWidth
											disabled={this.props.uniqueClientId == 0 || Disabled}
										>
											Submit
										</Button>
									</GridItem>
								</GridContainer>
							</GridItem>
						</GridContainer>
						<GridContainer>
							{this.state.list.map(d => {
								return (
									<GridItem key={d.QuestionTypeId} xs={12}>
										<GridContainer>
											<GridItem xs={9}>
												<h5><b>{d.QuestionTypeName}</b></h5>
											</GridItem>
											<GridItem xs={2} className={classes.center}>
												<h5><b>Select If Yes / No</b></h5>
											</GridItem>
											<GridItem xs={1}>
												<h5><b>Light Rating</b></h5>
											</GridItem>
										</GridContainer>
										{d.CustomerRiskRatings && d.CustomerRiskRatings.map(q => {
											return (
												<GridContainer key={q.Question.Id}>
													<GridItem xs={9}>
														{q.Question.QuestionDescription}
													</GridItem>
													<GridItem xs={2} className={classes.center}>
														<GenericCheckInput
															Disabled={Disabled}
															key={q.Question.Id}
															Value={checkedValues.find(x => x.QuestionId == q.QuestionId) === undefined ? false : checkedValues.find(x => x.QuestionId == q.QuestionId).IsYes}
															ValueChanged={(e) => {
																this.handleCheck(e, q, 1);
															}} />
													</GridItem>
													<GridItem xs={1}>
														{q.Question.LightRating && q.Question.LightRating.ParameterValue &&
															<div style={{ marginTop: "3px", marginLeft: "32px" }}
																className={q.Question.LightRating.ParameterValue === RiskLightRating.Black ? classes.blackRatingBoxStyle :
																	q.Question.LightRating.ParameterValue === RiskLightRating.Green ? classes.greenRatingBoxStyle :
																		q.Question.LightRating.ParameterValue === RiskLightRating.Yellow ? classes.yellowRatingBoxStyle :
																			q.Question.LightRating.ParameterValue === RiskLightRating.Red ? classes.redRatingBoxStyle : ""}></div>
														}
													</GridItem>
												</GridContainer>
											);
										})}
									</GridItem>
								);
							})}
						</GridContainer>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

RiskRatingQuestionaries.propTypes = {
	classes: PropTypes.object,
	uniqueClientId: PropTypes.number.isRequired,
	clientType: PropTypes.string.isRequired,
	customerRiskRatingAgreementId: PropTypes.object,
	reviewDate: PropTypes.object,
	handleGetList: PropTypes.func,
	handleOperationType: PropTypes.func,
	showMessage: PropTypes.func
};

export default withStyles(styles)(RiskRatingQuestionaries);
