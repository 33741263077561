import ResetPassword from "views/ExternalPages/ResetPassword";
import LoginPage from "views/Pages/LoginPage.jsx";
import OTPPage from "views/Pages/OTPPage";

const pagesRoutes = [
	{
		path: "/acc/otp",
		name: "OTP",
		component: OTPPage
	},
	{
		path: "/acc/login",
		name: "Login",
		component: LoginPage
	},
	{
		path: "/acc/reset",
		name: "Reset",
		component: ResetPassword
	},
	{
		redirect: true,
		path: "/acc",
		pathTo: "/acc/login",
		name: "Register Page"
	},
];

export default pagesRoutes;