
const iframeKeyDefinitionStyle = {

	profileHeader: {
		margin: "0px 0px 5px 40px"
	},
	mediaProfile: {
		width: "70%",
		margin: "auto",
		height: 200,
		borderRadius: 20,
		borderColor: "rgb(179, 179, 179)",
		borderWidth: 2,
	},
	previewTitle:{
		marginLeft:5
	},
	mediaProfileForLogo: {
		// width: "70%",
		width: 136,
		margin: "auto",
		height: 50,
		borderRadius: 10,
		borderColor: "rgb(179, 179, 179)",
		borderWidth: 2
	}
};

export default iframeKeyDefinitionStyle;