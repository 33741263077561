import withStyles from "@material-ui/core/styles/withStyles";
import { List } from "@material-ui/icons";
import { chartGradientColors } from "assets/jss/material-dashboard-pro-react.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
// @ts-ignore
import { Chart, Doughnut as DoughnutChart } from "react-chartjs-2";

const styles = ({
	circleBoxGrid: {
		width: "14%",
		textAlign: "center"
	},
	circleBox: {
		display: "inline-block",
		marginTop: "24px",
		marginLeft: "auto",
		marginRight: "auto",
		marginBottom: "24px"
	}
});

class LoanCircleChart extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
		};

		this.chartOptions = {
			maintainAspectRatio: false,
			responsive: false,
			legend: {
				display: false
			},
			cutoutPercentage: 90,
			animation: {
				duration: 2000
			},
			tooltips: { enabled: true }
		};
		this.getGradientList = this.getGradientList.bind(this);

	}

	componentDidMount() {
		this.props.setAppLeftTitle(this.props.leftTitle);
		this.props.setAppCenterTitle(this.props.centerTitle);

		var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
		Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
			draw: function () {
				originalDoughnutDraw.apply(this, arguments);

				var chart = this.chart;
				var width = chart.chart.width,
					height = chart.chart.height,
					ctx = chart.chart.ctx;

				var fontSize = 1.5;
				ctx.font = fontSize + "em sans-serif";
				ctx.textBaseline = "middle";
				ctx.fillStyle = "#000";

				var text = chart.config.data.text,
					textX = Math.round((width - ctx.measureText(text).width) / 2),
					textY = height / 2;

				ctx.fillText(text, textX, textY);

			}
		});

	}
	getCircleItemList() {
		const { classes, itemList } = this.props;
		let circleList = [];
		itemList.map(item => {
			var gradientList = this.getGradientList(item);
			var backGround = item;
			if (gradientList != undefined) {
				backGround.datasets[0].backgroundColor = gradientList;
				backGround.datasets[0].hoverBackgroundColor = gradientList;
			}
			circleList.push(
				<GridItem xs={2} className={classes.circleBoxGrid}>
					<div className={classes.circleBox}>
						<DoughnutChart
							// @ts-ignore
							id="canvas" data={backGround} width={200} height={300}
							options={backGround.text == "" || backGround.text == "0" ? { ...this.chartOptions, ...{ tooltips: { enabled: false } } } : this.chartOptions} />
						<h4>{item.labels[0]}</h4>
					</div>
				</GridItem>
			);

			return null;
		});
		return circleList;

	}

	getGradientList(dataList) {
		var gradientList = undefined;
		if (document.getElementById("canvas") != null) {
			// @ts-ignore
			var ctx = document.getElementById("canvas").getContext("2d");
			gradientList = [];
			if (dataList.datasets != undefined) {
				for (var i = 0; i < dataList.datasets[0].data.length; i++) {
					var gradient = ctx.createLinearGradient(0, 0, 0, 400);
					if (chartGradientColors[i] == undefined) {
						gradient.addColorStop(0, chartGradientColors[0].begin);
						gradient.addColorStop(1, chartGradientColors[0].end);
					} else {
						gradient.addColorStop(0, chartGradientColors[i].begin);
						gradient.addColorStop(1, chartGradientColors[i].end);
					}
					gradientList.push(gradient);
				}
				return gradientList;
			}
		}
		return gradientList;

	}
	render() {
		const { classes, mainTitle } = this.props;
		return (
			<Card style={{ marginTop: "25px" }}>
				<CardHeader color="warning" icon>
					<CardIcon color="warning">
						<List />
					</CardIcon>
					<h4 className={classes.cardIconTitle} style={{ color: "black" }}>
						<b>{mainTitle}</b>
					</h4>
				</CardHeader>
				<CardBody>
					<GridContainer justify="center">
						{this.getCircleItemList()}
					</GridContainer>
				</CardBody>
			</Card>
		);
	}
}

LoanCircleChart.propTypes = {
	classes: PropTypes.object,
	itemList: PropTypes.array,
	leftTitle: PropTypes.string,
	centerTitle: PropTypes.string,
	mainTitle: PropTypes.string,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func
};

// @ts-ignore
export default withStyles(styles)(LoanCircleChart);