import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import AlertHelper from "core/AlertHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes, { bool, func } from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDateInput, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTitle, GenericDialog, GenericLabel, GenericRadioInput, GenericDialogActions } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType, ProgramCodes, TransactionTraceStatus } from "views/Constants/Constant";
import { DialogTitle, DialogContent } from "@material-ui/core";
import Select from "react-select";
import Button from "components/CustomButtons/Button";
import GridButton from "views/Components/GridButton";
import MenuAuthorityRightHelper from "core/MenuAuthorityRightHelper";
import { ApproveIcon, TraceIcon } from "core/Icons";
import ParameterComponent from "views/Components/ParameterComponent";


class eftTraceTransactions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			model: {
				FromDate: null,
				ToDate: null
			},
			list: [],
			isLoading: false,
			isDownloadDialogOpen: false,
			ExportReportType: null,
			gridButtonRightDetail: []
		};

		this.handleChange = this.handleChange.bind(this);
		this.trxTable = React.createRef();
		this.columns = [
			{
				Header: "Transaction Date",
				accessor: "TransactionDate",
				type: GridColumnType.DateTimeUtc
			},
			{
				Header: "Settlement Date",
				accessor: "SettlementDate",
				type: GridColumnType.DateTimeUtc
			},
			{
				Header: "Unique Client Id",
				accessor: "UniqueClientId"
			},
			{
				Header: "Client Name",
				accessor: "UniqueClientName"
			},
			{
				Header: "Customer Name",
				accessor: "CustomerName"
			},
			{
				Header: "Trace Status",
				accessor: "TraceStatus"
			},
			// {
			// 	Header: "Trace Reject Reason",
			// 	accessor: "TraceRejectReason"
			// },
			{
				Header: "Transaction Type",
				accessor: "TransactionType"
			},
			{
				Header: "Financial Institution",
				accessor: "FID"
			},
			{
				Header: "Financial Institution Branch",
				accessor: "FIBranch"
			},
			{
				Header: "Customer Wallet Number",
				accessor: "AccountNumber"
			},
			{
				Header: "Amount",
				accessor: "Amount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Fee Amount",
				accessor: "FeeAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Transaction Status",
				accessor: "TransactionStatus"
			},
			{
				Header: "EFT Type",
				accessor: "EFTType"
			},
			{
				Header: "Financial Institution Id",
				accessor: "FinancialInstitutionId"
			},
			{
				Header: "Transit Number",
				accessor: "TransitNumber"
			},
			{
				Header: "EFT Transaction Type",
				accessor: "EFTTransactionType"
			},
			{
				Header: "Direction",
				accessor: "Direction"
			},
			{
				Header: "Transaction Source",
				accessor: "TransactionSource"
			},
			{
				Header: "Client User",
				accessor: "InsertUser"
			},
			{
				Header: "Transaction Reference Number",
				accessor: "ReferenceNumber"
			},
			{
				Header: "Approve Date Time",
				accessor: "ApproveDateTime",
				type: GridColumnType.DateTimeUtc
			},
			{
				Header: "Trace Reason",
				accessor: "TraceReason"
			}
		];

		this.MenuAuthorityRightHelper = new MenuAuthorityRightHelper();
	}
	componentDidMount() {
		this.props.setAppLeftTitle("EFT Trace Transactions");
		this.props.setAppCenterTitle("CLEARING & SETTLEMENT");
		this.setState({ loadingCompleted: true });
		this.GetGridButtonsRight();
	}

	GetGridButtonsRight = async () => {
		const { MenuCode } = this.props;
		var gridButtonRightDetail = await this.MenuAuthorityRightHelper.RightCheckList(MenuCode, ["EFTTRACECOMPLETE"]);
		this.setState({ gridButtonRightDetail });
	}

	handleChange(name, newValue) {
		if (name == "ExportReportType") {
			this.setState({
				ExportReportType: newValue
			});
			return;
		}
		const model = { ...this.state.model };
		
		model[name] = newValue;
		this.setState({ model });

	}

	handleGetList = () => {

		const model = { ...this.state.model };
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/ClearingSettlement/GetEFTTraceTransactionsV2",
			model,
			(responseData) => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ data: responseData.Item });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: AlertHelper.CreateAlert(title, message, type, () => this.setState({ alert: "" }))
		});
	}

	handleClear = () => {
		this.setState({
			model: {},
			list: [],
		});
	}

	GetExportButtonDialog() {
		return (<GenericDialog open={this.state.isDownloadDialogOpen} fullWidth={true}>
			<DialogTitle>
				<GenericLabel Text="Select Report Type" FontSize="20px" Bold={true} />
			</DialogTitle>
			<DialogContent style={{ height: 300 }}>
				<GenericRadioInput
					Name="ExportReportType"
					LabelText="Report Type"
					IsStatic={true}
					StaticData={[{ Value: "PDF", Text: "PDF" }, { Value: "CSV", Text: "CSV" }, { Value: "EXCEL", Text: "EXCEL" }]}
					KeyValueMember="Value"
					TextValueMember="Text"
					Value={this.state.ExportReportType}
					ValueChanged={this.handleChange}
				/>
				<GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
				<GridItem style={{ marginTop: 20 }}>
					<Select
						value={this.state.ExportSelectedColumns}
						onChange={value => this.setState({ ExportSelectedColumns: value })}
						isMulti
						name="columns"
						getOptionLabel={d => d.title}
						options={this.state.GridKeyValueList}
						className="basic-multi-select"
						classNamePrefix="select"
					/>
				</GridItem>
			</DialogContent>
			<GenericDialogActions>
				<Button size="sm" onClick={this.ExcelDownload}>Download</Button>
				<Button size="sm" onClick={() => this.setState({ isDownloadDialogOpen: false })}>Cancel</Button>
			</GenericDialogActions>
		</GenericDialog>
		);
	}

	ExportClick = () => {
		var trxColumns = [];
		this.trxTable.current.props.Columns.forEach(c => {
			if (c.accessor != "Actions" && c.accessor != "ED" && (c.show == undefined || c.show)) {
				trxColumns.push({ value: c.accessor, title: c.Header, columntype: c.ColumnType, type: c.type });
			}
		}, this);
		console.log(this.trxTable);
		this.setState({ isDownloadDialogOpen: true, GridKeyValueList: trxColumns, ExportSelectedColumns: trxColumns });
	}

	ExcelDownload = () => {
		if (!this.state.ExportReportType) {
			this.props.showMessage("warning", "Export Report Type not selected", "Select Export Report Type to continue");
			return;
		}

		if (this.state.ExportReportType == "PDF" && this.state.ExportSelectedColumns.length > 15) {
			this.props.showMessage("warning", "PDF document cannot exceed 15 columns", "PDF document cannot exceed 15 columns");
			return;
		}

		this.setState({ isDownloadDialogOpen: false });

		const temp = { ...this.state.model };

		var jsonColList = [];

		var trxColumns = {};
		this.state.ExportSelectedColumns.map(c => {
			trxColumns[c.value] = { value: c.value, title: c.title, columntype: c.columntype, type: c.type };
		});
		jsonColList.push(trxColumns);

		var mytitle = "EFT Trace Transactions";

		temp.jsonFile = {
			colmns: jsonColList,
			format: this.state.ExportReportType,
			title: mytitle
		};

		this.setState({ isLoading: true });
		Proxy.DownloadGeneratedFile(
			"/bankapi/v1.0/ClearingSettlement/GetEFTTraceTransactionExportDownload",
			temp,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", errorMessage);
			},
			this.props.showMessage
		);
	}

	GetHandleTraceApprove = (model) => async () => {
		if (!await this.props.showConfirmMessage("warning", "Warning", "Are you sure you want to save trace transaction as completed?")) return;

		var result = await this.props.ExecuteApiPost("/bankapi/v1.0/TransactionTrace/ApproveTracedTransactionById", { Id: model.TransactionId });
		if (result) {
			this.handleGetList();
			this.props.showMessage("success", "Success", "Transaction Trace successfully saved as completed!");
		}
	}

	render() {
		const { Disabled } = this.props;
		const { model, isLoading, gridButtonRightDetail, data } = this.state;
		return (
			<div>
				{this.state.alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.handleGetList, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.handleClear, Disabled: Disabled },
					{ Code: "Export", OnClick: this.ExportClick, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<LoadingComponent Show={isLoading} />

				<Card className="no-radius">
					<CardBody>
						<GenericExpansionPanel Title="Filter Panel">
							<GridContainer>
								{this.GetExportButtonDialog()}
								<GridItem xs={3}>
									<GenericDateInput
										Name="FromDate"
										LabelText="From Date"
										Value={model.FromDate}
										ValueChanged={this.handleChange}
										Utc />
								</GridItem>
								<GridItem xs={3}>
									<GenericDateInput
										Name="ToDate"
										LabelText="To Date"
										Value={model.ToDate}
										ValueChanged={this.handleChange}
										Utc />
								</GridItem>
							</GridContainer>
						</GenericExpansionPanel>
					</CardBody>
				</Card>


				<Card className="no-radius">
					<CardBody>
						<CardHeader>
							<GenericTitle text={"Trace Transactions List"} />
						</CardHeader>
						<GridContainer>
							<GridItem xs={12}>
								<GridContainer spacing={16}>
									<GridItem xs={12}>
										<GenericGrid
											Data={data}
											// SelectedIndex={selected}
											IsSorted={true}
											PageSize={10}
											ShowPagination={true}
											Columns={
												[
													{
														Header: "Actions",
														accessor: "Actions",
														Cell: row => (
															<div>
																<GridButton
																	tooltip="Complete Review"
																	Disabled={Disabled || row.original.TraceStatusParameterValue != TransactionTraceStatus.Pending // || !gridButtonRightDetail.find(x => x.Code == "EFTTRACECOMPLETE")?.HasAuthority
																	}
																	Icon={ApproveIcon}
																	OnClick={this.GetHandleTraceApprove(row.original)} />
															</div>
														),
														sortable: false,
														filterable: false,
														width: 60
													},
													...this.columns
												]
											}
										// ref={this.cpverTable}
										/>
									</GridItem>
								</GridContainer>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>


			</div>
		);
	}
}

eftTraceTransactions.propTypes = {
	classes: PropTypes.object,
	handleOperationType: PropTypes.func,
	SelectedRowChange: PropTypes.func,
	showQuestionMessage: func,
	setAppLeftTitle: func,
	setAppCenterTitle: func,
	Disabled: bool
};

export default withArtifex(eftTraceTransactions, {});