import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import PropTypes from "prop-types";
import React from "react";
import { GenericAlert, GenericCheckInput, GenericExpansionPanel, GenericGrid, GenericSelectInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { InventoryOrderStatusType } from "views/Constants/Constant";
import ClientHelper from "core/ClientHelper";
import ParameterComponent from "views/Components/ParameterComponent";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";

const styles = ({
	...sweetAlertStyle
});
class NewCardOrder extends React.Component {
	constructor(props) {
		super(props);
		var userJson = localStorage.getItem("user");
		var user = userJson ? JSON.parse(userJson) : null;
		this.initialModel = {
			Id: 0,
			ClientTypeId: (user && user.UniqueClient && user.UniqueClient.ClientTypeId) || null,
			isBackOffice: true,
			OrderStatusName: InventoryOrderStatusType.Created,
			InventorySafeDefinitionId: 0,
			Quantity: null
		};
		this.state = {
			model: {},
			cardorderlist: [],
			totalresult: {},
			checkedValues: [],
			selected: null,
			isLoading: false,
			alert: "",
			IsUpdate: false,
			vModel: {},
		};
		this.HandleCheck = this.HandleCheck.bind(this);
		this.columns = [
			{
				Header: "Actions",
				accessor: "Actions",
				sortable: false,
				filterable: false,
				width: 60
			},
			{
				Header: "Store/Safe",
				accessor: "CustomerStoreSafeName"
			},
			{
				Header: "Product Name",
				accessor: "ProductName"
			},
			{
				Header: "Order Status",
				accessor: "OrderStatusType"
			},

			{
				Header: "Quantity",
				accessor: "Quantity"
			},
			{
				Header: "Design Name",
				accessor: "PlasticTypeName"
			},

			// {
			// 	Header: "Ship Via",
			// 	accessor: "ShipViaName"
			// },
		];
	}

	componentDidMount() {
		this.props.setAppLeftTitle("New Card Order");
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
	}
	ValueChanged = (name, value, data) => {
		const model = { ...this.state.model };
		model[name] = value;
		if (name === "Name")
			model["UniqueClientId"] = value;

		if (name === "UniqueClientId")
			model["Name"] = value;

		if (name == "ProductId")
			model["PlasticTypeId"] = data.PlasticTypeId;

		if (name == "QuantityId")
			model.Quantity = data.ParameterValue;

		this.setState({ model });
	}
	HandleCheck = (e, x) => {

		this.setState(state => ({
			checkedValues: state.checkedValues.includes(x)
				? state.checkedValues.filter(c => c !== x)
				: [...state.checkedValues, x]
		}));
	}

	OrderCardConfrim = () => {
		this.setState({ isLoading: true, alert: null });

		if (this.state.checkedValues.length > 0) {
			Proxy.POST(
				"/prepaidapi/v1.0/InventoryOrder/InventoryCardChangeStatusUpdate",
				this.state.checkedValues,
				(responseData) => {
					if (!responseData.IsSucceeded) {
						this.setState({ isLoading: false });
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					// var listItems = [];
					// if (responseData.Item != null) {
					// 	listItems = responseData.Item.map((number) =>

					// 		<div key={number.Id}>
					// 			{number.OrderReceiveDate == null ?
					// 				number.CustomerStoreSafeName + "==>" + number.ProductName + "==>" + Formatter.FormatDate(number.OrderReceiveDate) + "==>Error"
					// 				:
					// 				number.CustomerStoreSafeName + "==>" + number.ProductName + "==>" + Formatter.FormatDate(number.OrderReceiveDate) + "==>Succeses"
					// 			}
					// 		</div>
					// 	);
					// }
				},
				null, null, null, 200000000
			);

			this.setState({ isLoading: false });

			this.props.showMessage("success", "Order request has been added to the queue");
			this.Clear();
			this.props.clearlist();
		} else {

			this.ShowMessage("error", "Error", "Please select ordered cards !");
			this.setState({ isLoading: false });
		}

	}

	Submit = () => {
		if (!this.Validate()) {
			return;
		}

		this.setState({ isLoading: true });

		const { model } = this.state;
		const { showMessage } = this.props;

		Proxy.POST(
			"/prepaidapi/v1.0/InventoryOrder/InsertorUpdateInventoryOrder",
			model,
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ isLoading: false });
				this.props.showMessage("success", "Operation is successfully!");
				this.Clear();
				this.Search();
			},
			(error) => {
				this.setState({ isLoading: false });
				// showMessage("error", "An error occurred while sending data", error.message);
			}
		);

	}
	Search = () => {

		this.setState({ isLoading: true });

		const { model } = this.state;
		const { showMessage } = this.props;
		model.OrderStatusName = InventoryOrderStatusType.Created;
		Proxy.POST(
			"/prepaidapi/v1.0/InventoryOrder/Search",
			model,
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				this.setState({ cardorderlist: responseData.Item || [], isLoading: false });
			},
			(error) => {
				this.setState({ isLoading: false });
				// showMessage("error", "An error occurred while requesting data", error.message);
			}
		);
	}

	DeleteConfirm = () => {
		const { showConfirmMessage } = this.props;
		showConfirmMessage("warning", "Warning", "Are you sure want to delete ?", this.Delete);
	}
	Update = () => {

		const { model } = this.state;
		const { showMessage } = this.props;

		Proxy.POST(
			"/prepaidapi/v1.0/InventoryOrder/InsertorUpdateInventoryOrder",
			model,
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ isLoading: false });
				this.props.showMessage("success", "Operation is successfully!");
				this.Clear();
				this.Search();
			},
			(error) => {
				this.setState({ isLoading: false });
				// showMessage("error", "An error occurred while sending data", error.message);
			}
		);

	}
	Delete = () => {
		this.setState({ isLoading: true });

		const { model } = this.state;

		Proxy.POST(
			"/prepaidapi/v1.0/InventoryOrder/Delete",
			{
				"Id": model.Id
			},
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ isLoading: false });
				this.props.showMessage("success", "Operation is successfully!");
				this.Clear();
				this.Search();
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "An error occurred while sending data", error.message);
			}
		);
	}
	Clear = () => {
		const { model } = this.state;
		model.UniqueClientId = this.initialModel.UniqueClientId;

		this.initialModel.InventorySafeDefinitionId = ClientHelper.IsStore() ? model.InventorySafeDefinitionId : 0;
		this.setState({ model: this.initialModel, cardorderlist: [], selected: null, checkedValues: [], totalresult: {}, IsUpdate: false });
	}
	Validate() {
		const { model } = this.state;
		var messages = [];
		const vModel = { ...this.state.vModel };

		if (!model.InventorySafeDefinitionId) {
			messages.push("Store / Safe can not be null.");
			vModel.InventorySafeDefinitionId = true;
		} else {
			vModel.InventorySafeDefinitionId = false;
		}


		if (model.ProductId == null) {
			messages.push("Product Name can not be null.");
			vModel.ProductId = true;

		} else {
			vModel.ProductId = false;
		}
		if (model.Quantity == null) {
			messages.push("Quantity can not be null.");
			vModel.Quantity = true;

		} else {
			vModel.Quantity = false;
		}

		this.setState({ vModel });

		if (messages.length > 0) {
			if (messages.length > 9) {
				var moreWarn = messages.length - 8;
				messages = messages.slice(0, 8);
				messages.push("And " + moreWarn + " more warnings");
			}
			this.props.showMessageNode("error", "Please Fill Required Fields", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		return true;
	}

	ErrorSendCallback(error) {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "An error occurred while sending data", error.message);
	}
	ShowMessage = (type, title, message) => {
		this.setState({
			alert:
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })}
				/>
		});
	}
	ShowConfirmMessage(type, title, message, onConfirm) {
		this.setState({
			alert:
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					ShowCancel={true}
					OnCancel={() => this.setState({ alert: null, isLoading: false })}
					OnConfirm={onConfirm}
				/>,
			isLoading: true
		});
	}

	render() {
		const { model, cardorderlist, checkedValues, IsUpdate, vModel } = this.state;

		return (
			<div>
				<LoadingComponent Show={this.state.isLoading} />

				{this.state.alert}

				<ButtonToolbar ButtonList={[
					{
						Code: "Search",
						OnClick: this.Search
					},
					{
						Code: "Clear",
						OnClick: this.Clear
					},
					{
						Code: "Submit",
						OnClick: this.Submit,
						Disabled: IsUpdate
					},
					{
						Code: "Update",
						OnClick: this.Update,
						Disabled: IsUpdate == false ? true : false
					},
					{
						Code: "Delete",
						OnClick: this.DeleteConfirm,
						Disabled: IsUpdate == false ? true : false
					},
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<GenericExpansionPanel Title={"Main Parameters"}>
								<CardBody>
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={{ UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined }}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
												Value={model.UniqueClientId || ""}
												ValueChanged={this.ValueChanged}
												CanClear
												All
												Disabled={ClientHelper.IsClient()}
												DefaultIndex={ClientHelper.IsClient() ? 0 : -1}
											/>
										</GridItem>
										<GridItem xs={4}  >
											<GenericSelectInput
												key={model.UniqueClientId}
												Name="InventorySafeDefinitionId"
												LabelText="Store/Safe"
												Method="POST"
												Url={ model.UniqueClientId > 0 ? "/prepaidapi/v1.0/InventoryOrder/GetAllStoreBySafe" : ""}
												DataRoot="Item"
												KeyValueMember="SafeId"
												TextValueMember="CustomerStoreSafeName"
												Value={model.InventorySafeDefinitionId}
												Parameter={ {UniqueClientId : model.UniqueClientId, IsNoNameProduct: true}}
												ValueChanged={this.ValueChanged}
												Sorted={{ Member: "CustomerStoreSafeName" }}
												IsInvalid={vModel.InventorySafeDefinitionId}
												Required
												DefaultIndex={ClientHelper.IsStore() ? 0 : -1}
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericSelectInput
												key={model.UniqueClientId}
												Name="ProductId"
												LabelText="Product"
												Method="POST"
												Url={ model.UniqueClientId > 0 ? "/prepaidapi/v1.0/Product/Search" : ""}
												Parameter={ {UniqueClientId : model.UniqueClientId, IsNoNameProduct: true}}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ProductName"
												Value={model.ProductId}
												ValueChanged={this.ValueChanged}
												IsInvalid={vModel.ProductId}
												Required
											/>
										</GridItem>
										<GridItem xs={4}  >
											<ParameterComponent
												Name="QuantityId"
												Value={model.QuantityId}
												ParameterCode="InventoryQuantity"
												LabelText="Quantity"
												Sorted="ParameterValue"
												ValueChanged={this.ValueChanged}
												IsInvalid={vModel.QuantityId}
												Required
											/>
										</GridItem>
										
									</GridContainer>
								</CardBody>
							</GenericExpansionPanel>
						</Card>
						<Card className="no-radius" >
							<GenericExpansionPanel Title={"Card Orders"} >
								<CardBody>
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer spacing={16}>
												<GridItem xs={12}>
													<GenericGrid
														Data={cardorderlist.map(d => {
															return {
																Actions: (
																	<div>
																		<GenericCheckInput
																			Grid
																			Value={checkedValues.filter(c => c.Id == d.InventoryOrder.Id).length > 0}
																			ValueChanged={(e) => {
																				this.HandleCheck(e, d.InventoryOrder);
																			}}
																			Disabled={d.InventoryOrder.OrderStatusType == "Ordered" || d.InventoryOrder.OrderStatus.ParameterDesc == "OrderPreparing"}
																		/>
																	</div>
																),
																CustomerStoreSafeName: d.InventoryOrder.InventorySafeDefinition.SafeName + " / " + d.InventoryOrder.InventorySafeDefinition.CustomerStore.StoreName,
																Id: d.Id,
																ProductName: d.InventoryOrder.Product.ProductName,
																ProductId: d.InventoryOrder.Product.Id,
																OrderStatusType: d.InventoryOrder.OrderStatus.ParameterDesc,
																PlasticTypeName: d.InventoryOrder.Product.PlasticType.PlasticName,
																Quantity: d.InventoryOrder.Quantity

															};
														})}
														Columns={this.columns}
														RowSelected={index => {
															this.setState({ model: cardorderlist[index].InvertoryOrder, selected: index, openOrderDialog: true, IsUpdate: true });
															var tt = cardorderlist[index];
															var model = tt.InventoryOrder;
															model["PlasticTypeId"] = cardorderlist[index].InventoryOrder.Product.PlasticTypeId;
															this.setState({ model });

														}}
													/>
												</GridItem>
											</GridContainer>
										</GridItem>
										<GridContainer xs={12} justify={"center"}>
											<GridItem >
												<Button size="sm" style={{ width: "100px" }} onClick={() => this.OrderCardConfrim()}>
													CONFIRM
												</Button>
											</GridItem>
										</GridContainer>

									</GridContainer>
								</CardBody>
							</GenericExpansionPanel>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

NewCardOrder.propTypes = {
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any,
	classes: PropTypes.object,


};

export default withArtifex(NewCardOrder, styles);