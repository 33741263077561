import { withStyles } from "@material-ui/core";
import genericDateInputStyle from "assets/jss/generic/genericDateInputStyle.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import DateHelper from "core/DateHelper";
import moment from "moment";
import "moment-timezone";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import Datetime from "react-datetime";
import { GenericLabel } from "views/Components/Generic";

class GenericDateInput extends PureComponent {

	constructor(props) {
		super(props);

		this.timeFormat = "hh:mm A";

		this.OnChange = this.OnChange.bind(this);
		this.IsValidDate = this.IsValidDate.bind(this);
	}

	OnChange(e) {
		const { Name, ValueChanged, IncludeTime } = this.props;

		if (ValueChanged) {
			var returnValue = e;

			if (moment.isMoment(returnValue)) {
				if (IncludeTime)
					returnValue = DateHelper.ToMoment(returnValue);
			}

			ValueChanged(Name, returnValue);
		}
	}

	IsValidDate(current) {
		const { IsFuture, IsPast, IsValidDate, MinDate, MaxDate, Utc } = this.props;

		if (IsValidDate)
			return IsValidDate(current);

		var minDate = DateHelper.ToMoment(MinDate || "");
		var maxDate = DateHelper.ToMoment(MaxDate || "");

		var today = Utc ? DateHelper.GetLocalDateAsUtc() : DateHelper.GetDateLocal();

		if (IsFuture)
			minDate = today;
		else if (IsPast)
			maxDate = today.add(-1, "days");
		else if (IsFuture == false)
			maxDate = today;

		if (minDate.isValid() && maxDate.isValid())
			return current.isSameOrBefore(maxDate) && current.isSameOrAfter(minDate);
		else if (minDate.isValid())
			return current.isSameOrAfter(minDate);
		else if (maxDate.isValid())
			return current.isSameOrBefore(maxDate);

		return true;
	}

	render() {
		const { classes, LabelText, Name, Value, DateFormat, IncludeTime, ViewMode, Disabled, LabelMd, Required, IsInvalid, Utc } = this.props;

		return (
			<div style={{ marginTop: 4 }}>
				<GridContainer direction="row" justify="flex-start" alignItems="flex-end">
					{LabelMd != 0 && <GridItem style={{ height: 20 }} xs={LabelMd}>
						<GenericLabel Text={LabelText} htmlFor={Name} className={classes.label} />
					</GridItem>}
					<GridItem xs={12 - LabelMd}>
						<div style={{ marginRight: 2, marginTop: 2, marginBottom: 2, opacity: Disabled ? 0.5 : 1 }}>
							<Datetime
								value={Value}
								dateFormat={DateFormat}
								timeFormat={IncludeTime}
								viewMode={ViewMode}
								closeOnSelect={true}
								utc={Utc}
								displayTimeZone={DateHelper.GetTimeZone()}
								inputProps={{
									id: Name,
									placeholder: IncludeTime ? this.timeFormat : DateFormat,
									disabled: Disabled,
									style: {
										fontSize: "12px",
										background: "unset",
										height: 25,
										color: "black",
										paddingLeft: 2,
										border: Required == true ? `1px solid ${IsInvalid == true ? window.InvalidFieldColor : window.RequiredFieldColor}` : null
									},
									autoComplete: "off"
								}}
								onChange={this.OnChange}
								isValidDate={this.IsValidDate}
							/>
						</div>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

GenericDateInput.defaultProps = {
	LabelMd: 4,
	DateFormat: "DD/MMM/YY",
	IncludeTime: false
};

GenericDateInput.propTypes = {
	classes: PropTypes.object.isRequired,
	InputData: PropTypes.object,
	ValueChanged: PropTypes.func,
	Value: PropTypes.any,
	Name: PropTypes.string,
	LabelText: PropTypes.string,
	DateFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	IncludeTime: PropTypes.bool,
	ViewMode: PropTypes.string,
	Disabled: PropTypes.bool,
	LabelMd: PropTypes.number,
	IsFuture: PropTypes.bool,
	IsPast: PropTypes.bool,
	IsValidDate: PropTypes.func,
	MinDate: PropTypes.object,
	MaxDate: PropTypes.object,
	Required: PropTypes.bool,
	IsInvalid: PropTypes.bool,
	Utc: PropTypes.bool
};

export default withStyles(genericDateInputStyle)(GenericDateInput);