import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import PropTypes, { bool, func } from "prop-types";
import React, { Component } from "react";
import AccountLimitToolbar from "views/Components/AccountLimitToolbar";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import {
  GenericAlert,
  GenericCheckInput,
  GenericDateInput,
  GenericExpansionPanel,
  GenericGrid,
  GenericNumberInput,
  GenericSelectInput,
  GenericTitle,
  GenericTextInput,
  GenericDialog,
  GenericLabel,
  GenericRadioInput,
  GenericDialogActions,
} from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import {
  BankProgramType,
  BankTransactionStatus,
  Direction,
  EtransferApplicationTypes,
  GridColumnType,
  ProgramCodes,
} from "views/Constants/Constant.js";
import { DialogTitle, DialogContent } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import Select from "react-select";
import MenuAuthorityRightHelper from "core/MenuAuthorityRightHelper";
import GridButton from "views/Components/GridButton";
import { InformationIcon, TraceIcon } from "core/Icons";
import ParameterComponent from "views/Components/ParameterComponent";
import Logo from "../../../../assets/img/logo.png";
import Formatter from "core/Formatter";
class eTransferIncomingTransactions extends Component {
  constructor(props) {
    super(props);
    this.isClient = ClientHelper.IsClient();
    this.initialModel = {
      SearchEndDate: DateHelper.GetDateTime(),
      SearchStartDate: DateHelper.GetDateTime(),
      DirectionParameterValue: Direction.Incoming,
      ClientId: 0,
      IsBankingScreen: false,
    };

    this.state = {
      model: this.initialModel,
      vModel: {},
      tempModel: {},
      getDataList: [],
      summaryModel: {},
      isLoading: false,
      isBackOffice: false,
      selectList: [],
      checkedValues: [],
      LimitToken: false,
      isInfoDialogOpen: false,
      infoDialogData: {},
    };

    var isBackOffice = ClientHelper.IsBackOffice();
    this.columns = [
      {
        Header: "Actions",
        accessor: "Actions",
        Cell: (row) => (
          <div>
            <GridButton
              tooltip="Information"
              Icon={InformationIcon}
              OnClick={() => {
                this.getInfoDialogData(row);
              }}
            />
          </div>
        ),
        sortable: false,
        filterable: false,
        width: 75,
        show: !this.isCallCenter,
      },
      {
        Header: "Actions",
        accessor: "AC",
        Cell: (d) => (
          <div>
            <GridButton
              tooltip="Trace"
              Disabled={
                this.props.Disabled ||
                !this.isAvailableForTraceTransaction(d.original) ||
                !this.state.gridButtonRightDetail.find(
                  (x) => x.Code == "ETRNSFINTRACE"
                )?.HasAuthority
              }
              Icon={TraceIcon}
              OnClick={this.GetHandleTrace(d.original)}
            />
          </div>
        ),
        sortable: false,
        filterable: false,
        width: 50,
        show: !isBackOffice,
      },
      {
        Header: "Transaction Date",
        accessor: "TransactionDate",
        type: GridColumnType.DateTimeUtc,
      },
      {
        Header: "Settlement Date",
        accessor: "SettlementDate",
        type: GridColumnType.DateTimeUtc,
      },
      {
        Header: "Wallet Number",
        accessor: "AccountNumber",
      },
      {
        Header: "Unique Client Id",
        accessor: "ClientNumber",
        show: isBackOffice,
      },
      {
        Header: "Client Name",
        accessor: "ClientName",
        show: isBackOffice,
      },
      {
        Header: "Transfer Type",
        accessor: "TransactionType",
      },
      {
        Header: "Sender Name",
        accessor: "SenderName",
      },
      {
        Header: "Sender EMail",
        accessor: "CustomerEmail",
      },
      {
        Header: "Amount",
        accessor: "TransactionAmount",
        type: GridColumnType.Money,
        ColumnType: GridColumnType.Money,
      },
      {
        Header: "Sender Bank",
        accessor: "SenderBank",
        show: this.state.model.DirectionParameterValue == Direction.Incoming,
      },
      {
        Header: "Sender Transit",
        accessor: "SenderTransit",
        show: this.state.model.DirectionParameterValue == Direction.Incoming,
      },
      {
        Header: "Fee Amount",
        accessor: "FeeAmount",
        type: GridColumnType.Money,
        ColumnType: GridColumnType.Money,
      },
      {
        Header: "Settlement Status",
        accessor: "SettlementStatus",
      },
      {
        Header: "Transfer Status",
        accessor: "BankTransactionStatus",
      },
      {
        Header: "Transaction Description",
        accessor: "TransactionMemo",
      },
      {
        Header: "Trans. Ref. No ",
        accessor: "ParticipantReferenceNumber",
      },
      {
        Header: "Interac Ref. Number",
        accessor: "TransferReferenceNumber",
      },
      {
        Header: "Transaction Source",
        accessor: "TransactionSource",
      },
      {
        Header: "Application",
        accessor: "ApplicationId",
        Cell: (row) => {
          var rowValue = "";
          EtransferApplicationTypes &&
            EtransferApplicationTypes.forEach((item) => {
              if (item.Value == row.value) rowValue = item.Text;
            });
          return rowValue;
        },
      },
      {
        Header: "Error Description",
        accessor: "ErrorDesc",
      },
      {
        Header: "Customer Store",
        accessor: "CustomerStore",
      },
      {
        Header: "Trace Status",
        accessor: "TraceStatus",
      },
		{
			Header: "Sender Email",
			accessor: "SenderEmail",
		},
      {
        Header: "Receiver Name",
        accessor: "ReceiverName",
      },
      {
        Header: "Receiver Email",
        accessor: "ReceiverEmail",
      },
      {
        Header: "Client Reference Number",
        accessor: "ClientRefNo",
      },
    ];
    this.handleChange = this.handleChange.bind(this);
    this.GetData = this.GetData.bind(this);
    this.errorCallback = this.errorCallback.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.showErrorMessage = this.showErrorMessage.bind(this);
    this.successSummaryCallback = this.successSummaryCallback.bind(this);
    this.sortedByDate = [{ id: "TransactionDate", desc: true }];
    this.trxTable = React.createRef();

    this.MenuAuthorityRightHelper = new MenuAuthorityRightHelper();
  }

  componentDidMount() {
    this.props.setAppLeftTitle("Interac e-Transfer / Incoming Transactions");

    var model = { ...this.state.model };
    var initialModel = this.initialModel;
    model.IsBankingScreen = this.props.MenuCode == "BETINC";
    var isBackOffice = ClientHelper.IsBackOffice();
    if (!isBackOffice) {
      model.ClientId = ClientHelper.GetClientRowId();
      initialModel.ClientId = model.ClientId;
      this.props.setAppCenterTitle(
        model.IsBankingScreen ? "WALLET" : "INTERAC E-TRANSFER"
      );
    } else {
      this.props.setAppCenterTitle("INTERAC E-TRANSFER");
    }
    this.RefreshToolBar();
    this.setState({ model, isBackOffice, loadingCompleted: true });
    this.GetGridButtonsRight();
  }

  GetGridButtonsRight = async () => {
    const { MenuCode } = this.props;
    var gridButtonRightDetail =
      await this.MenuAuthorityRightHelper.RightCheckList(MenuCode, [
        "ETRNSFINTRACE",
      ]);

    this.setState({ gridButtonRightDetail });
  };

  RefreshToolBar = () => {
    this.setState({ LimitToken: !this.state.LimitToken });
  };
  handleChange(name, newValue) {
    const model = { ...this.state.model };
    model[name] = newValue;
    this.setState({ model });
  }
  ValueChanged = (name, value) => {
    this.setState({ [name]: value });
  };
  IsValid = () => {
    var { model, vModel } = this.state;

    var searchIsNotSpecific =
      (model.RefNo == undefined || model.RefNo == null || model.RefNo == "") &&
      (model.InteracRefNo == undefined ||
        model.InteracRefNo == null ||
        model.InteracRefNo == "") &&
      (model.PayeeEmail == undefined ||
        model.PayeeEmail == null ||
        model.PayeeEmail == "");

    model["searchIsNotSpecific"] = searchIsNotSpecific;

    var validateStatus = true;

    if (searchIsNotSpecific) {
      if (!model.ClientId) {
        this.props.showMessage(
          "warning",
          "Please fill required fields!",
          "Select Client to continue"
        );
        vModel["ClientId"] = true;
        validateStatus = false;
      }

      if (
        model.SearchStartDate == "" ||
        (typeof model.SearchStartDate != "object" &&
          model.SearchStartDate != "" &&
          model.SearchStartDate != null)
      ) {
        this.props.showMessage(
          "warning",
          "Please fill required fields!",
          "Start Date is invalid"
        );
        validateStatus = false;
      }
      if (
        model.SearchEndDate == "" ||
        (typeof model.SearchEndDate != "object" &&
          model.SearchEndDate != "" &&
          model.SearchEndDate != null)
      ) {
        this.props.showMessage(
          "warning",
          "Please fill required fields!",
          "End Date is invalid"
        );
        validateStatus = false;
      }
      if (
        typeof model.SearchStartDate == "object" &&
        typeof model.SearchEndDate == "object" &&
        model.SearchEndDate < model.SearchStartDate
      ) {
        this.props.showMessage(
          "warning",
          "Please fill required fields!",
          "Start Date cannot be bigger than End Date"
        );
        validateStatus = false;
      }

      if (
        typeof model.SearchStartDate == "object" &&
        typeof model.SearchEndDate == "object"
      ) {
        var diffMonths = model.SearchEndDate.diff(
          model.SearchStartDate,
          "days"
        );
        if (diffMonths < 0) {
          this.props.showMessage(
            "warning",
            "Warning",
            "End Date must be later than Start Date"
          );
          validateStatus = false;
        }

        if (diffMonths > 30) {
          this.props.showMessage(
            "warning",
            "Warning",
            "Difference between Start Date and End Date cannot be longer than 30 days"
          );
          validateStatus = false;
        }
      }
      this.setState({ model, vModel });
      if (!validateStatus) return false;
    } else {
      vModel["ClientId"] = false;
      this.setState({ model, vModel });
    }
    return true;
  };

  GetData() {
    this.setState((state) => ({ trxGridToken: !state.trxGridToken }));
    this.GetSummaryData();
  }

  GetSummaryData = () => {
    if (!this.IsValid()) return;

    Proxy.POST(
      "/bankapi/v1.0/ClearingSettlement/GetEtransferOutInSummaryData",
      this.state.model,
      (responseData) => {
        if (!responseData.IsSucceeded) {
          this.props.showMessage(
            "error",
            "Error",
            responseData.ErrorDescription
          );
          return;
        }
        this.setState({ summaryModel: responseData.Item });
      },
      (error) => {
        this.props.showMessage("error", "Error", error);
      }
    );
  };

  successSummaryCallback(responseData) {
    if (!responseData.IsSucceeded) {
      this.showErrorMessage(responseData.ErrorDescription);
      return;
    }
    if (
      responseData.Item == null ||
      responseData.Item.TransactionList == null ||
      responseData.Item.TransactionList.length < 1
    ) {
      this.setState({ summaryModel: {}, getDataList: [], isLoading: false });
    } else {
      var summaryModel =
        responseData.Item == null ? {} : responseData.Item.SummaryItems;
      this.setState({
        summaryModel,
        getDataList:
          responseData.Item.TransactionList == null
            ? []
            : responseData.Item.TransactionList,
        isLoading: false,
      });
    }
  }

  HandleCheck(trans) {
    var selectList = [...this.state.selectList];

    selectList = this.state.selectList.includes(trans.TransactionEtransferId)
      ? this.state.selectList.filter((t) => t !== trans.TransactionEtransferId)
      : [...this.state.selectList, trans.TransactionEtransferId];

    this.setState((state) => ({
      selectList,
      checkedValues: state.checkedValues.includes(trans)
        ? state.checkedValues.filter((c) => c !== trans)
        : [...state.checkedValues, trans],
    }));
  }

  GetHandleTrace = (model) => () => {
    this.traceHelper.InsertTransactionTraceWithAlert(model.TransactionId);
  };

  ShowErrorMessage = (message) => {
    this.setState({
      isLoading: false,
      alert: (
        <GenericAlert
          Title={"Warning"}
          Message={message}
          Type={"warning"}
          OnConfirm={() => this.hideAlert()}
        />
      ),
    });
  };

  errorCallback(error) {
    this.hideLoading();
    this.showErrorMessage("An error occurred during the api visit" + error);
  }

  showErrorMessage(message) {
    this.setState({
      isLoading: false,
      alert: (
        <GenericAlert
          Title={"Error"}
          Message={message}
          Type="warning"
          ShowCancel={false}
          OnConfirm={() => this.hideAlert()}
        />
      ),
    });
  }

  handleClear = () => {
    this.setState({
      model: this.initialModel,
      getDataList: [],
      summaryModel: {},
    });
  };
  showLoading = () => {
    this.setState({ isLoading: true });
  };
  hideLoading = () => {
    this.setState({ isLoading: false });
  };
  hideAlert() {
    this.setState({ alert: null });
  }

  ExportClick = () => {
    var trxColumns = [];
    this.trxTable.current.props.Columns.forEach((c) => {
      if (
        c.accessor != "Actions" &&
        c.accessor != "AC" &&
        (c.show == undefined || c.show)
      ) {
        trxColumns.push({
          value: c.accessor,
          title: c.Header,
          columntype: c.ColumnType,
          type: c.type,
        });
      }
    }, this);

    this.setState({
      isDownloadDialogOpen: true,
      GridKeyValueList: trxColumns,
      ExportSelectedColumns: trxColumns,
    });
  };

  ExcelDownload = () => {
    if (!this.state.ExportReportType) {
      this.props.showMessage(
        "warning",
        "Export Report Type not selected",
        "Select Export Report Type to continue"
      );
      return;
    }

    if (
      this.state.ExportReportType == "PDF" &&
      this.state.ExportSelectedColumns.length > 15
    ) {
      this.props.showMessage(
        "warning",
        "PDF document cannot exceed 15 columns",
        "PDF document cannot exceed 15 columns"
      );
      return;
    }

    this.setState({ isDownloadDialogOpen: false });

    if (!this.IsValid()) return;

    const temp = { ...this.state.model };

    var jsonColList = [];

    var trxColumns = {};
    this.state.ExportSelectedColumns.map((c) => {
      trxColumns[c.value] = {
        value: c.value,
        title: c.title,
        columntype: c.columntype,
        type: c.type,
      };
    });
    jsonColList.push(trxColumns);

    var mytitle = "Interac e-Transfer Incoming Transfers";

    temp.jsonFile = {
      colmns: jsonColList,
      format: this.state.ExportReportType,
      title: mytitle,
    };

    this.setState({ isLoading: true });
    Proxy.DownloadGeneratedFile(
      "/bankapi/v1.0/ClearingSettlement/GetETransferIncomingTransfersExportDownload",
      temp,
      (responseData) => {
        this.setState({ isLoading: false });
        if (!responseData.IsSucceeded) {
          this.props.showMessage(
            "error",
            "Error",
            responseData.ErrorDescription
          );
          return;
        }
      },
      (errorMessage) => {
        this.setState({ isLoading: false });
        this.props.showMessage("error", "Error", errorMessage);
      },
      this.props.showMessage
    );
  };
  isAvailableForTraceTransaction = (row) => {
    var availableForTrace = true;
    switch (row.BankTransactionStatusParamValue) {
      case BankTransactionStatus.Pending:
      case BankTransactionStatus.Error:
      case BankTransactionStatus.Cancelled:
        availableForTrace = false;
        break;
      default:
        break;
    }
    availableForTrace =
      availableForTrace && !row.TransactionTraceId && row.TransactionId > 0;
    return availableForTrace;
  };
  HandleChange = (name, value, data) => {
    const model = { ...this.state.model };
    model[name] = value;

    if (name == "PriorityTypeId" && value == null) {
      model[name] = -1;
    }

    this.setState({ model });
  };
  getInfoDialogData = (row) => {
    this.setState({ isInfoDialogOpen: false });

    const payload = {
      TransactionEtransferId: row?.original?.TransactionEtransferId,
      TransactionId: null,
      TransferReferenceNumber: null,
    };

    Proxy.POST(
      "/bankapi/v1.0/ClearingSettlement/GetEtransferRemittancePartyData",
      payload,
      (responseData) => {
        this.setState({ isLoading: false });
        this.setState({ isInfoDialogOpen: true });

        this.setState({
          infoDialogData: responseData?.Item,
        });
      },
      (errorMessage) => {
        this.setState({ isLoading: false });
        this.ShowMessage(
          "error",
          "Error",
          "An error occurred while sending approve request. " +
            (typeof errorMessage !== "string" ? errorMessage : "")
        );
      }
    );
  };
  handleAmountInput = (num) => {
    if (typeof num === "number") {
      return `${num?.toString()?.includes(".") ? num : num + ".00"} ${
        this.state.infoDialogData?.ReferredDocumentRemittedCurrency
      }`;
    }
    return null;
  };
  showInfoDialog = () => {
    const { isInfoDialogOpen } = this.state;

    return (
     (
        <GenericDialog
          open={isInfoDialogOpen}
          maxWidth="md"
          fullWidth
          style={{ height: "100%", width: "100%" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <DialogTitle>
              <GenericLabel Text="Transfer Information" Bold FontSize={16} />
            </DialogTitle>
            <DialogTitle>
              <img
                src={Logo}
                alt="Logo"
                style={{ width: "50%", height: "50%" }}
              />
            </DialogTitle>
            <DialogTitle>
              <Button size="sm">PDF</Button>
            </DialogTitle>
          </div>
          <DialogContent style={{ height: "100%" }}>
            <GridContainer spacing={16}>
              <GridContainer item>
                <DialogTitle>
                  <GenericLabel Text="Transfer Information" Bold />
                </DialogTitle>
                <GridContainer
                  item
                  style={{ display: "flex", flexDirection: "row" }}
                  xs={12}
                >
                  <GridContainer item xs={12} style={{ justifyContent: "end" }}>
                    <GridItem xs={6}>
                      <GenericTextInput
                        Name="DateReceived"
                        LabelText="Date Received"
                        Value={this.state.infoDialogData?.DateReceived ? Formatter.FormatDateUtc(
                          this.state.infoDialogData?.DateReceived
                        ) : ""}
                        Disabled
                      />
                    </GridItem>
                    <GridItem xs={6}>
                      <GenericTextInput
                        Name="TransactionDirection"
                        LabelText="Transaction Direction"
                        Value={this.state.infoDialogData?.TransactionDirection}
                        Disabled
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer item xs={12}>
                    <GridItem xs={6}>
                      <GenericNumberInput
                        Name="Amount"
                        LabelText="Amount"
                        Value={this.state.infoDialogData?.Amount}
                        Disabled
                      />
                    </GridItem>
                    <GridItem xs={6}>
                      <GenericTextInput
                        Name="InteracRefNumber"
                        LabelText="Interac Ref. Number"
                        Value={
                          this.state.infoDialogData?.InteracReferenceNumber
                        }
                        Disabled
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer item xs={12}>
                    <GridItem xs={6}>
                      <GenericTextInput
                        Name="Currency"
                        LabelText="Currency"
                        Value={this.state.infoDialogData?.Currency}
                        Disabled
                      />
                    </GridItem>
                    <GridItem xs={6}>
                      <GenericTextInput
                        Name="InteracStatus"
                        Value={this.state.infoDialogData?.InteracStatus}
                        LabelText="Interac Status"
                        Disabled
                      />
                    </GridItem>
                  </GridContainer>
                </GridContainer>
              </GridContainer>

              <GridContainer item>
                <DialogTitle>
                  <GenericLabel Text="Ultimate Originator Details" Bold />
                </DialogTitle>
                <GridContainer item xs={12}>
                  <GridContainer item xs={12}>
                    <GridItem xs={6}>
                      <GenericTextInput
                        Name="Name"
                        LabelText="Name"
                        Value={
                          this.state.infoDialogData?.UltimateDebtorPartyData
                            ?.Name
                        }
                        Disabled
                      />
                    </GridItem>
                    <GridItem xs={6}>
                      <GenericTextInput
                        Name="ID"
                        LabelText="ID"
                        Value={
                          this.state.infoDialogData?.UltimateDebtorPartyData
                            ?.Identification
                        }
                        Disabled
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer item xs={12}>
                    <GridItem xs={6}>
                      <GenericTextInput
                        Name="CountryOfResidence"
                        LabelText="Country of Residence"
                        Value={
                          this.state.infoDialogData?.UltimateDebtorPartyData
                            ?.CountryOfResidence
                        }
                        Disabled
                      />
                    </GridItem>
                    <GridItem xs={6}>
                      <GenericTextInput
                        Name="AddressType"
                        Value={
                          this.state.infoDialogData?.UltimateDebtorPartyData
                            ?.PostalCode
                        }
                        LabelText="Address Type"
                        Disabled
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer xs={12}>
                    <GridItem xs={12}>
                      <GenericTextInput
                        Name="Address"
                        LabelText="Address"
                        Value={
                          this.state.infoDialogData?.UltimateDebtorPartyData
                            ?.AddressLine
                        }
                        Disabled
                        LabelMd={2}
                      />
                    </GridItem>
                  </GridContainer>
                </GridContainer>
              </GridContainer>

              <GridContainer item>
                <DialogTitle>
                  <GenericLabel Text="Originator Details" Bold />
                </DialogTitle>
                <GridContainer item xs={12}>
                  <GridContainer item xs={12}>
                    <GridItem xs={6}>
                      <GenericTextInput
                        Name="Name"
                        LabelText="Name"
                        Value={this.state.infoDialogData?.DebtorPartyData?.Name}
                        Disabled
                      />
                    </GridItem>
                    <GridItem xs={6}>
                      <GenericTextInput
                        Name="AccountHolderName"
                        LabelText="Wallet Holder Name"
                        Value={
                          this.state.infoDialogData?.InitiatingPartyData?.Name
                        }
                        Disabled
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer item xs={12}>
                    <GridItem xs={6}>
                      <GenericTextInput
                        Name="AccountNumber"
                        LabelText="Wallet Number"
                        Value={
                          this.state.infoDialogData?.DebtorPartyData
                            ?.AccountNumber
                        }
                        Disabled
                      />
                    </GridItem>
                    <GridItem xs={6}>
                      <GenericTextInput
                        Name="OriginatorAgent"
                        LabelText="Originator Agent"
                        Value={
                          this.state.infoDialogData?.DebtorPartyData
                            ?.AgentNumber
                        }
                        Disabled
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer item xs={12}>
                    <GridItem xs={6}>
                      <GenericTextInput
                        Name="EmailAddress"
                        LabelText="E-Mail Address"
                        Value={
                          this.state.infoDialogData?.DebtorPartyData?.Email
                        }
                        Disabled
                      />
                    </GridItem>
                    <GridItem xs={6}>
                      <GenericTextInput
                        Name="PhoneNumber"
                        LabelText="Phone Number"
                        Value={
                          this.state.infoDialogData?.DebtorPartyData
                            ?.PhoneNumber
                        }
                        Disabled
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer item xs={12}>
                    <GridItem xs={6}>
                      <GenericTextInput
                        Name="CountryOfResidence"
                        LabelText="Country of Residence"
                        Value={
                          this.state.infoDialogData?.DebtorPartyData
                            ?.CountryOfResidence
                        }
                        Disabled
                      />
                    </GridItem>
                    <GridItem xs={6}>
                      <GenericTextInput
                        Name="AddressType"
                        LabelText="Address Type"
                        Value={
                          this.state.infoDialogData?.DebtorPartyData?.PostalCode
                        }
                        Disabled
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer xs={12}>
                    <GridItem xs={12}>
                      <GenericTextInput
                        Name="Address"
                        LabelText="Address"
                        Value={
                          this.state.infoDialogData?.DebtorPartyData
                            ?.AddressLine
                        }
                        Disabled
                        LabelMd={2}
                      />
                    </GridItem>
                  </GridContainer>
                </GridContainer>
              </GridContainer>

              <GridContainer item>
                <DialogTitle>
                  <GenericLabel Text="Recipient Details" Bold />
                </DialogTitle>
                <GridContainer item xs={12}>
                  <GridContainer item xs={12}>
                    <GridItem xs={6}>
                      <GenericTextInput
                        Name="Name"
                        LabelText="Name"
                        Value={
                          this.state.infoDialogData?.CreditorPartyData?.Name
                        }
                        Disabled
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer item xs={12}>
                    <GridItem xs={6}>
                      <GenericTextInput
                        Name="AccountNumber"
                        LabelText="Wallet Number"
                        Value={
                          this.state.infoDialogData?.CreditorPartyData
                            ?.AccountNumber
                        }
                        Disabled
                      />
                    </GridItem>
                    <GridItem xs={6}>
                      <GenericTextInput
                        Name="RecipientAgent"
                        LabelText="Recipient Agent"
                        Value={
                          this.state.infoDialogData?.CreditorPartyData
                            ?.AgentNumber
                        }
                        Disabled
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer item xs={12}>
                    <GridItem xs={6}>
                      <GenericTextInput
                        Name="EmailAddress"
                        LabelText="E-Mail Address"
                        Value={
                          this.state.infoDialogData?.CreditorPartyData?.Email
                        }
                        Disabled
                      />
                    </GridItem>
                    <GridItem xs={6}>
                      <GenericTextInput
                        Name="MobileNumber"
                        LabelText="Mobile Number"
                        Value={
                          this.state.infoDialogData?.CreditorPartyData
                            ?.MobileNumber
                        }
                        Disabled
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer item xs={12}>
                    <GridItem xs={6}>
                      <GenericTextInput
                        Name="CountryOfResidence"
                        LabelText="Country of Residence"
                        Value={
                          this.state.infoDialogData?.CreditorPartyData
                            ?.CountryOfResidence
                        }
                        Disabled
                      />
                    </GridItem>
                    <GridItem xs={6}>
                      <GenericTextInput
                        Name="AddressType"
                        LabelText="Address Type"
                        Value={
                          this.state.infoDialogData?.CreditorPartyData
                            ?.PostalCode
                        }
                        Disabled
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer xs={12}>
                    <GridItem xs={12}>
                      <GenericTextInput
                        Name="Address"
                        LabelText="Address"
                        Value={
                          this.state.infoDialogData?.CreditorPartyData
                            ?.AddressLine
                        }
                        Disabled
                        LabelMd={2}
                      />
                    </GridItem>
                  </GridContainer>
                </GridContainer>
              </GridContainer>

              <GridContainer item>
                <DialogTitle>
                  <GenericLabel Text="Ultimate Recipient Details" Bold />
                </DialogTitle>
                <GridContainer item xs={12}>
                  <GridContainer item xs={12}>
                    <GridItem xs={6}>
                      <GenericTextInput
                        Name="Name"
                        LabelText="Name"
                        Value={
                          this.state.infoDialogData?.UltimateCreditorPartyData
                            ?.Name
                        }
                        Disabled
                      />
                    </GridItem>
                    <GridItem xs={6}>
                      <GenericTextInput
                        Name="ID"
                        LabelText="ID"
                        Value={
                          this.state.infoDialogData?.UltimateCreditorPartyData
                            ?.Identification
                        }
                        Disabled
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer item xs={12}>
                    <GridItem xs={6}>
                      <GenericTextInput
                        Name="CountryOfResidence"
                        LabelText="Country of Residence"
                        Value={
                          this.state.infoDialogData?.UltimateCreditorPartyData
                            ?.CountryOfResidence
                        }
                        Disabled
                      />
                    </GridItem>
                    <GridItem xs={6}>
                      <GenericTextInput
                        Name="AddressType"
                        LabelText="Address Type"
                        Value={
                          this.state.infoDialogData?.UltimateCreditorPartyData
                            ?.PostalCode
                        }
                        Disabled
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer xs={12}>
                    <GridItem xs={12}>
                      <GenericTextInput
                        Name="Address"
                        LabelText="Address"
                        Value={
                          this.state.infoDialogData?.UltimateCreditorPartyData
                            ?.AddressLine
                        }
                        Disabled
                        LabelMd={2}
                      />
                    </GridItem>
                  </GridContainer>
                </GridContainer>
              </GridContainer>
              <GridContainer item>
                <DialogTitle>
                  <GenericLabel Text="Transfer Memo" Bold />
                </DialogTitle>
                <GridContainer item xs={12}>
                  <GridItem xs={12}>
                    <GenericTextInput
                      Name="Memo1"
                      LabelText="Memo 1"
                      Value={this.state.infoDialogData?.TransferMemo1}
                      Disabled
                      LabelMd={2}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <GenericTextInput
                      Name="Memo2"
                      LabelText="Memo 2"
                      Value={this.state.infoDialogData?.TransferMemo2}
                      Disabled
                      LabelMd={2}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <GenericTextInput
                      Name="Memo3"
                      LabelText="Memo 3"
                      Value={this.state.infoDialogData?.TransferMemo3}
                      Disabled
                      LabelMd={2}
                    />
                  </GridItem>
                </GridContainer>
              </GridContainer>

              <GridContainer item style={{ marginTop: "2rem" }}>
                <DialogTitle style={{ paddingBottom: 0, paddingLeft: 0 }}>
                  <GenericLabel
                    Text="Remittance Information"
                    FontSize={16}
                    Bold
                  />
                </DialogTitle>
                <GridContainer
                  item
                  style={{ justifyContent: "center", marginTop: "1rem" }}
                >
                  <GenericLabel
                    Text={this.state.infoDialogData?.ReferredDocumentCode}
                    FontSize={14}
                    Bold
                  />
                </GridContainer>
                <GridContainer item>
                  <DialogTitle>
                    <GenericLabel Text="Payor Details" Bold />
                  </DialogTitle>
                  <GridContainer
                    item
                    style={{ display: "flex", flexDirection: "row" }}
                    xs={12}
                  >
                    <GridContainer item xs={12}>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="InvoiceePayorName"
                          LabelText="Payor Name"
                          Value={this.state.infoDialogData?.InvoiceeName}
                          Disabled
                        />
                      </GridItem>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="InvoiceeContactName"
                          LabelText="Contact Name"
                          Value={this.state.infoDialogData?.InvoiceeContactName}
                          Disabled
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer item xs={12}>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="InvoiceeEmailAddress"
                          LabelText="E-Mail Address"
                          Value={this.state.infoDialogData?.InvoiceeEmail}
                          Disabled
                        />
                      </GridItem>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="InvoiceeIdentificationType"
                          LabelText="Identification Type"
                          Value={
                            this.state.infoDialogData
                              ?.InvoiceeIdentificationCode
                          }
                          Disabled
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer item xs={12}>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="InvoiceePhoneNumber"
                          LabelText="Phone Number"
                          Value={this.state.infoDialogData?.InvoiceePhoneNumber}
                          Disabled
                        />
                      </GridItem>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="InvoiceeIdentificationNumber"
                          LabelText="Identification Number"
                          Value={
                            this.state.infoDialogData?.InvoiceeIdentification
                          }
                          Disabled
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer item xs={12}>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="InvoiceeMobilePhoneNumber"
                          LabelText="Mobile Phone Number"
                          Value={
                            this.state.infoDialogData?.InvoiceeMobileNumber
                          }
                          Disabled
                        />
                      </GridItem>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="InvoiceeCountryOfResidence"
                          LabelText="Country of Residence"
                          Value={
                            this.state.infoDialogData
                              ?.InvoiceeCountryOfResidence
                          }
                          Disabled
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer item xs={12}>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="InvoiceeFaxNumber"
                          LabelText="Fax Number"
                          Value={this.state.infoDialogData?.InvoiceeFaxNumber}
                          Disabled
                        />
                      </GridItem>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="InvoiceeAddressType"
                          LabelText="Address Type"
                          Value={this.state.infoDialogData?.InvoiceePostalCode}
                          Disabled
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer item xs={12}>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="InvoiceeDepartment"
                          LabelText="Department"
                          Value={this.state.infoDialogData?.InvoiceeDepartment}
                          Disabled
                        />
                      </GridItem>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="InvoiceeSubDepartment"
                          LabelText="Sub Department"
                          Value={
                            this.state.infoDialogData?.InvoiceeSubDepartment
                          }
                          Disabled
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer item xs={12}>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="InvoiceeStreetName"
                          LabelText="Street Name"
                          Value={this.state.infoDialogData?.InvoiceeStreetName}
                          Disabled
                        />
                      </GridItem>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="InvoiceeBuildingNumber"
                          LabelText="Building Number"
                          Value={
                            this.state.infoDialogData?.InvoiceeBuildingNumber
                          }
                          Disabled
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer item xs={12}>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="InvoiceePostCode"
                          LabelText="Post Code"
                          Value={this.state.infoDialogData?.InvoiceePostCode}
                          Disabled
                        />
                      </GridItem>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="InvoiceeTownName"
                          LabelText="Town Name"
                          Value={this.state.infoDialogData?.InvoiceeTownName}
                          Disabled
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer item xs={12}>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="InvoiceeCountrySubDivision"
                          LabelText="Country Sub Division"
                          Value={
                            this.state.infoDialogData
                              ?.InvoiceeCountrySubDivision
                          }
                          Disabled
                        />
                      </GridItem>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="InvoiceeCountry"
                          LabelText="Country"
                          Value={this.state.infoDialogData?.InvoiceeCountry}
                          Disabled
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer item xs={12}>
                      <GridItem xs={12}>
                        <GenericTextInput
                          Name="InvoiceeAddress"
                          LabelText="Address"
                          LabelMd={2}
                          Value={this.state.infoDialogData?.InvoiceeAddressLine}
                          Disabled
                        />
                      </GridItem>
                    </GridContainer>
                  </GridContainer>
                </GridContainer>

                <GridContainer item>
                  <DialogTitle>
                    <GenericLabel Text="Payee Details" Bold />
                  </DialogTitle>
                  <GridContainer
                    item
                    style={{ display: "flex", flexDirection: "row" }}
                    xs={12}
                  >
                    <GridContainer item xs={12}>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="InvoicerPayeeName"
                          LabelText="Payee Name"
                          Value={this.state.infoDialogData?.InvoicerName}
                          Disabled
                        />
                      </GridItem>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="InvoicerContactName"
                          LabelText="Contact Name"
                          Value={this.state.infoDialogData?.InvoicerContactName}
                          Disabled
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer item xs={12}>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="InvoicerEmailAddress"
                          LabelText="E-Mail Address"
                          Value={this.state.infoDialogData?.InvoicerEmail}
                          Disabled
                        />
                      </GridItem>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="InvoicerFaxNumber"
                          LabelText="Fax Number"
                          Value={this.state.infoDialogData?.InvoicerFaxNumber}
                          Disabled
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer item xs={12}>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="InvoicerPhoneNumber"
                          LabelText="Phone Number"
                          Value={this.state.infoDialogData?.InvoicerPhoneNumber}
                          Disabled
                        />
                      </GridItem>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="InvoicerIdentificationType"
                          LabelText="Identification Type"
                          Value={
                            this.state.infoDialogData
                              ?.InvoicerIdentificationCode
                          }
                          Disabled
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer item xs={12}>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="InvoicerMobilePhoneNumber"
                          LabelText="Mobile Phone Number"
                          Value={
                            this.state.infoDialogData?.InvoicerMobileNumber
                          }
                          Disabled
                        />
                      </GridItem>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="InvoicerIdentificationNumber"
                          LabelText="Identification Number"
                          Value={
                            this.state.infoDialogData?.InvoicerIdentification
                          }
                          Disabled
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer item xs={12}>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="InvoicerFaxNumber"
                          LabelText="Fax Number"
                          Value={this.state.infoDialogData?.InvoicerFaxNumber}
                          Disabled
                        />
                      </GridItem>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="InvoicerAddressType"
                          LabelText="Address Type"
                          Value={this.state.infoDialogData?.InvoicerPostalCode}
                          Disabled
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer item xs={12}>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="InvoicerDepartment"
                          LabelText="Department"
                          Value={this.state.infoDialogData?.InvoicerDepartment}
                          Disabled
                        />
                      </GridItem>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="InvoicerSubDepartment"
                          LabelText="Sub Department"
                          Value={
                            this.state.infoDialogData?.InvoicerSubDepartment
                          }
                          Disabled
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer item xs={12}>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="InvoicerStreetName"
                          LabelText="Street Name"
                          Value={this.state.infoDialogData?.InvoicerStreetName}
                          Disabled
                        />
                      </GridItem>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="InvoicerBuildingNumber"
                          LabelText="Building Number"
                          Value={
                            this.state.infoDialogData?.InvoicerBuildingNumber
                          }
                          Disabled
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer item xs={12}>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="InvoicerPostCode"
                          LabelText="Post Code"
                          Value={this.state.infoDialogData?.InvoicerPostCode}
                          Disabled
                        />
                      </GridItem>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="InvoicerTownName"
                          LabelText="Town Name"
                          Value={this.state.infoDialogData?.InvoicerTownName}
                          Disabled
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer item xs={12}>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="InvoicerCountrySubDivision"
                          LabelText="Country Sub Division"
                          Value={
                            this.state.infoDialogData
                              ?.InvoicerCountrySubDivision
                          }
                          Disabled
                        />
                      </GridItem>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="InvoicerCountry"
                          LabelText="Country"
                          Value={this.state.infoDialogData?.InvoicerCountry}
                          Disabled
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer item xs={12}>
                      <GridItem xs={12}>
                        <GenericTextInput
                          Name="InvoicerAddress"
                          LabelText="Address"
                          LabelMd={2}
                          Value={this.state.infoDialogData?.InvoicerAddressLine}
                          Disabled
                        />
                      </GridItem>
                    </GridContainer>
                  </GridContainer>
                </GridContainer>

                <GridContainer item>
                  <DialogTitle>
                    <GenericLabel Text="Remittance Details" Bold />
                  </DialogTitle>
                  <GridContainer
                    item
                    style={{ display: "flex", flexDirection: "row" }}
                    xs={12}
                  >
                    <GridContainer item xs={12}>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="ReferredDocumentNumber"
                          LabelText="Document Number"
                          Value={
                            this.state.infoDialogData?.ReferredDocumentNumber
                          }
                          Disabled
                        />
                      </GridItem>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="ReferredDateOfFunds"
                          LabelText="Date of Funds"
                          Value={this.state.infoDialogData
									?.ReferredDocumentRelatedDate ? Formatter.FormatDateUtc(
                            this.state.infoDialogData
                              ?.ReferredDocumentRelatedDate 
                          ) : ""}
                          Disabled
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer item xs={12}>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="ReferredDueAmount"
                          LabelText="Due Amount"
                          Value={
                            this.handleAmountInput(
                              this.state.infoDialogData
                                ?.ReferredDocumentDuePayableAmount
                            ) || ""
                          }
                          Disabled
                        />
                      </GridItem>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="ReferredDebitOrCredit"
                          LabelText="Debit / Credit"
                          Value={
                            this.state.infoDialogData
                              ?.ReferredDocumentCreditDebitIndicator
                          }
                          Disabled
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer item xs={12}>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="ReferredRemittedAmount"
                          LabelText="Remitted Amount"
                          Value={
                            this.handleAmountInput(
                              this.state.infoDialogData
                                ?.ReferredDocumentRemittedAmount
                            ) || ""
                          }
                          Disabled
                        />
                      </GridItem>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="CreditorRefCode"
                          LabelText="Creditor Ref. Code"
                          Value={
                            this.state.infoDialogData
                              ?.CreditorInformationReferenceCode
                          }
                          Disabled
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer item xs={12}>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="ReferredAdjustedAmount"
                          LabelText="Adjusted Amount"
                          Value={
                            this.handleAmountInput(
                              this.state.infoDialogData
                                ?.ReferredDocumentAdjusmentAmount
                            ) || ""
                          }
                          Disabled
                        />
                      </GridItem>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="CreditorRefNumber"
                          LabelText="Creditor Ref. Number"
                          Value={
                            this.state.infoDialogData
                              ?.CreditorInformationReference
                          }
                          Disabled
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer item xs={12}>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="ReferredAdjustmentReasonCode"
                          LabelText="Adjustment Reason Code"
                          Value={
                            this.state.infoDialogData?.ReferredDocumentReason
                          }
                          Disabled
                        />
                      </GridItem>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="IdentificationType"
                          LabelText="Identification Type"
                          Value={
                            this.state.infoDialogData?.InvoicerIdentification
                          }
                          Disabled
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer item xs={12}>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="ReferredAdjustmentMemo"
                          LabelText="Adjustment Memo"
                          Value={
                            this.state.infoDialogData
                              ?.ReferredDocumentAdditionalInformation
                          }
                          Disabled
                        />
                      </GridItem>
                      <GridItem xs={6}>
                        <GenericTextInput
                          Name="RemittanceMemo"
                          LabelText="Remittance Memo"
                          Value={
                            this.state.infoDialogData
                              ?.AdditionalRemittanceInformation
                          }
                          Disabled
                        />
                      </GridItem>
                    </GridContainer>
                  </GridContainer>
                </GridContainer>
              </GridContainer>
            </GridContainer>
          </DialogContent>
          <GenericDialogActions>
            <GridContainer justify="flex-end">
              <Button
                size="sm"
                onClick={() => this.setState({ isInfoDialogOpen: false })}
              >
                CANCEL
              </Button>
            </GridContainer>
          </GenericDialogActions>
        </GenericDialog>
      )
    );
  };

  render() {
    const { Disabled } = this.props;
    const {
      model,
      summaryModel,
      isLoading,
      isBackOffice,
      checkedValues,
      LimitToken,
      vModel,
    } = this.state;
    return (
      <div>
        {this.state.alert}
        {this.showInfoDialog()}
        {this.isClient && (
          <AccountLimitToolbar
            key={"_" + LimitToken}
            Program={ProgramCodes.Bank_Programs_e_Transfer}
            IsUseDisableDebitLimit={false}
          />
        )}
        <ButtonToolbar
          ButtonList={[
            { Code: "Search", OnClick: this.GetData, Disabled: Disabled },
            // { Code: "Trace", OnClick: ()=>{}, Disabled: Disabled || this.state.selectList.length > 0 ? false : true },
            { Code: "Clear", OnClick: this.handleClear, Disabled: Disabled },
            { Code: "Export", OnClick: this.ExportClick, Disabled: Disabled },
          ]}
          menuId={this.props.menuId}
          ApprovalData={this.props.ApprovalData}
          After={this.props.After}
        />

        <LoadingComponent Show={isLoading} />
        {/*EXPORT DIALOG*/}
        <GenericDialog open={this.state.isDownloadDialogOpen} fullWidth={true}>
          <DialogTitle>
            <GenericLabel
              Text="Select Report Type"
              FontSize="20px"
              Bold={true}
            />
          </DialogTitle>
          <DialogContent style={{ height: 300 }}>
            <GenericRadioInput
              Name="ExportReportType"
              LabelText="Report Type"
              IsStatic={true}
              StaticData={[
                { Value: "PDF", Text: "PDF" },
                { Value: "CSV", Text: "CSV" },
                { Value: "EXCEL", Text: "EXCEL" },
              ]}
              KeyValueMember="Value"
              TextValueMember="Text"
              Value={this.state.ExportReportType}
              ValueChanged={this.ValueChanged}
            />
            <GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
            <GridItem style={{ marginTop: 20 }}>
              <Select
                value={this.state.ExportSelectedColumns}
                onChange={(value) =>
                  this.setState({ ExportSelectedColumns: value })
                }
                isMulti
                name="columns"
                getOptionLabel={(d) => d.title}
                options={this.state.GridKeyValueList}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </GridItem>
          </DialogContent>
          <GenericDialogActions>
            <Button size="sm" onClick={this.ExcelDownload}>
              Download
            </Button>
            <Button
              size="sm"
              onClick={() => this.setState({ isDownloadDialogOpen: false })}
            >
              Cancel
            </Button>
          </GenericDialogActions>
        </GenericDialog>

        <Card className="no-radius">
          <GenericExpansionPanel Title={"Summary Information"}>
            <CardBody>
              <GridContainer>
                <GridItem xs={4}>
                  <GenericSelectInput
                    Name="ClientId"
                    LabelText="Client"
                    Method="POST"
                    Url="/bankapi/v1.0/BankCustomer/Search"
                    Parameter={{}}
                    DataRoot="Item"
                    KeyValueMember="Id"
                    RenderItem={(d) => `${d.UniqueClientId} - ${d.Name}`}
                    Value={model.ClientId || ""}
                    ValueChanged={this.handleChange}
                    CanClear
                    Required={model.searchIsNotSpecific == true}
                    IsInvalid={vModel.ClientId}
                    Disabled={!isBackOffice}
                  />
                  <GenericDateInput
                    Name="SearchStartDate"
                    LabelText="Start Date"
                    Value={model.SearchStartDate}
                    ValueChanged={this.handleChange}
                    MaxDate={model.SearchEndDate || DateHelper.GetDate()}
                    IncludeTime={false}
                    Utc
                  />
                  <GenericDateInput
                    Name="SearchEndDate"
                    LabelText="End Date"
                    Value={model.SearchEndDate}
                    ValueChanged={this.handleChange}
                    MinDate={
                      model.SearchStartDate == null
                        ? null
                        : model.SearchStartDate
                    }
                    IncludeTime={false}
                    Utc
                  />
                  <GenericSelectInput
                    Name="TransactionTypeId"
                    LabelText="Transaction Type"
                    Value={
                      model === undefined ? "" : model.TransactionTypeId || ""
                    }
                    DataRoot="Item"
                    All
                    ValueChanged={this.handleChange}
                    KeyValueMember="Id"
                    TextValueMember="ParameterValue6"
                    Method="GET"
                    Url="/bankapi/v1.0/Transaction/GetAllFinancialTransactionType"
                  />
                  <ParameterComponent
                    Name="PriorityTypeId"
                    LabelText="e-Transfer Type"
                    Value={model.PriorityTypeId}
                    ParameterCode="EtransferType"
                    ValueChanged={this.HandleChange}
                    Disabled={Disabled}
                  />
                  <GenericSelectInput
                    Name="NotificationStatusId"
                    LabelText="Notification Status"
                    Value={
                      model == null || undefined
                        ? ""
                        : model.NotificationStatusId || ""
                    }
                    DataRoot="Item"
                    All
                    ValueChanged={this.handleChange}
                    KeyValueMember="Id"
                    TextValueMember="ParameterDesc"
                    Url="/coreapi/v1.0/Parameter/Search"
                    Method="POST"
                    Parameter={{
                      ParameterCode: "NotificationStatus",
                      ParameterValue: "",
                      ParameterValue2: "",
                      ParameterDesc: "",
                    }}
                  />
                  <GenericSelectInput
                    Name="ApplicationId"
                    LabelText="Application Type"
                    Value={model === undefined ? "" : model.ApplicationId || ""}
                    DataRoot="Item"
                    All
                    ValueChanged={this.handleChange}
                    KeyValueMember="Value"
                    TextValueMember="Text"
                    IsStatic
                    StaticData={EtransferApplicationTypes || []}
                  />
                  <GenericNumberInput
                    NoFormatting={true}
                    Name="RefNo"
                    LabelText="Reference Number"
                    Value={model.RefNo}
                    ValueChanged={this.handleChange}
                  />
                  <GenericTextInput
                    Name="InteracRefNo"
                    LabelText="Interac Reference Number"
                    Value={model.InteracRefNo}
                    ValueChanged={this.handleChange}
                  />
                  <GenericNumberInput
                    NoFormatting={true}
                    Name="AccountNumber"
                    LabelText="Wallet Number"
                    Value={model.AccountNumber}
                    ValueChanged={this.handleChange}
                  />
                </GridItem>
                <GridItem xs={1}></GridItem>
                <GridItem xs={7}>
                  <Card>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={6}>
                          <GenericNumberInput
                            Name="TotalNoOfCredit"
                            LabelText="Total No. of Money Send"
                            LabelMd={6}
                            Value={
                              summaryModel == null
                                ? ""
                                : summaryModel.TotalNoOfCredit
                                ? summaryModel.TotalNoOfCredit
                                : ""
                            }
                            Disabled={true}
                            DecimalScale={0}
                          />
                          <GenericNumberInput
                            Name="TotalNoOfDebit"
                            LabelText="Total No. of Money Request"
                            LabelMd={6}
                            Value={
                              summaryModel == null
                                ? ""
                                : summaryModel.TotalNoOfDebit
                                ? summaryModel.TotalNoOfDebit
                                : ""
                            }
                            Disabled={true}
                            DecimalScale={0}
                          />
                        </GridItem>
                        <GridItem xs={1}></GridItem>
                        <GridItem xs={5}>
                          <GenericNumberInput
                            Name="CreditTotal"
                            LabelText="Money Send Total"
                            Value={
                              summaryModel == null
                                ? ""
                                : summaryModel.CreditTotal
                                ? summaryModel.CreditTotal
                                : ""
                            }
                            Disabled={true}
                            Prefix="$"
                          />
                          <GenericNumberInput
                            Name="DebitTotal"
                            LabelText="Money Request Total"
                            Value={
                              summaryModel == null
                                ? ""
                                : summaryModel.DebitTotal
                                ? summaryModel.DebitTotal
                                : ""
                            }
                            Disabled={true}
                            Prefix="$"
                          />
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </CardBody>
          </GenericExpansionPanel>
        </Card>
        <Card className="no-radius">
          <CardBody>
            <CardHeader>
              <GenericTitle text={"Incoming Transfers"} />
            </CardHeader>
            <GridItem xs={12}>
              <GridItem>
                <GenericGrid
                  key={"trxGrid" + this.state.trxGridToken}
                  ref={this.trxTable}
                  PageSize={10}
                  ShowPagination={true}
                  Sorted={this.sortedByDate}
                  Columns={this.columns}
                  ProgramCode={ProgramCodes.Bank_Programs_e_Transfer}
                  ServerSide
                  LoadAtStartup={this.state.loadingCompleted}
                  ValidateRequest={this.IsValid}
                  PrepareRequest={() => {
                    return this.state.model;
                  }}
                  RequestUrl="/bankapi/v1.0/ClearingSettlement/GetETransferIncomingTransfersPaged"
                  RequestMethod="POST"
                />
              </GridItem>
            </GridItem>
          </CardBody>
        </Card>
      </div>
    );
  }
}

eTransferIncomingTransactions.propTypes = {
  classes: PropTypes.object,
  handleOperationType: PropTypes.func,
  SelectedRowChange: PropTypes.func,
  showQuestionMessage: func,
  setAppLeftTitle: func,
  setAppCenterTitle: func,
  Disabled: bool,
};

export default withArtifex(eTransferIncomingTransactions, {});
