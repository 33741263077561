import { CardMedia } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import { withArtifex } from "core";
import PropTypes from "prop-types";
import React, { Component } from "react";

class ChequeOrderDetailInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(name, newValue) {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}
	render() {
		const { chequemodel, chqinfomodel } = this.props;
		/* height: 160px; */
		const logo = require("assets/img/" + window.ChequeLogo);
		return (

			<div>

				<Card login>
					<CardHeader  >
						<CardMedia
							image={logo.toString()}
						>

							<div style={{ borderRadius: "8px", height: "415px", fontFamily: "Orbitron", fontWeight: "bold", marginTop: "10px" }} >

								<div style={{ background: "#0e99ff", height: "10px", float: "left", width: "100%", borderRadius: "5px" }}>	</div>
								<div style={{ height: "160px", float: "left", width: "100%", marginTop: "10px", padding: "10px" }}>
									<div style={{ height: "150px", float: "left", width: "25%" }}>
										<div style={{ height: "20px", float: "left", width: "100%" }}>{chequemodel.ClientName}</div>
										<div style={{ height: "20px", float: "left", width: "100%" }}>	{chequemodel.ClientAdress}  </div>
										<div style={{ height: "20px", float: "left", width: "100%" }}>{chequemodel.ClientCity}</div>
										<div style={{ height: "20px", float: "left", width: "100%" }}>{chequemodel.PostalCode}</div>

									</div>
									<div style={{ height: "150px", float: "left", width: "25%" }}>
									</div>
									<div style={{ height: "150px", float: "left", width: "50%" }}>
										<div style={{ height: "35px", float: "left", width: "100%" }}> </div>
										<div style={{ height: "35px", float: "left", width: "100%" }}></div>
										<div style={{ height: "35px", float: "left", width: "100%", textAlign: "center", lineHeight: "30px", color: "#0e99ff" }}>
											<div style={{ height: "30px", float: "left", width: "10%" }}>

											</div>
											<div style={{ border: "solid 1px #f1e0e0", background: "white", height: "30px", float: "left", width: "10%", fontWeight: "bold" }}>
												2
											</div>
											<div style={{ border: "solid 1px #f1e0e0", background: "white", height: "30px", float: "left", width: "10%", fontWeight: "bold" }}>
												0
											</div>
											<div style={{ border: "solid 1px #f1e0e0", background: "white", height: "30px", float: "left", width: "10%" }}>

											</div>
											<div style={{ border: "solid 1px #f1e0e0", background: "white", height: "30px", float: "left", width: "10%" }}>

											</div>
											<div style={{ border: "solid 1px #f1e0e0", background: "white", height: "30px", float: "left", width: "10%" }}>

											</div>
											<div style={{ border: "solid 1px #f1e0e0", background: "white", height: "30px", float: "left", width: "10%" }}>

											</div>
											<div style={{ border: "solid 1px #f1e0e0", background: "white", height: "30px", float: "left", width: "10%" }}>

											</div>
											<div style={{ border: "solid 1px #f1e0e0", background: "white", height: "30px", float: "left", width: "10%" }}>

											</div>
										</div>
										<div style={{ height: "35px", float: "left", width: "100%", fontWeight: "bold", textAlign: "center", lineHeight: "30px", color: "#0e99ff" }}>

											<div style={{ height: "30px", float: "left", width: "10%" }}>
												DATE
											</div>
											<div style={{ height: "30px", float: "left", width: "10%" }}>
												Y
											</div>
											<div style={{ height: "30px", float: "left", width: "10%" }}>
												Y
											</div>
											<div style={{ height: "30px", float: "left", width: "10%" }}>
												Y
											</div>
											<div style={{ height: "30px", float: "left", width: "10%" }}>
												Y
											</div>
											<div style={{ height: "30px", float: "left", width: "10%" }}>
												M
											</div>
											<div style={{ height: "30px", float: "left", width: "10%" }}>
												M
											</div>
											<div style={{ height: "30px", float: "left", width: "10%" }}>
												D
											</div>
											<div style={{ height: "30px", float: "left", width: "10%" }}>
												D
											</div>
										</div>


									</div>

								</div>
								<div style={{ height: "50px", float: "left", width: "100%", padding: "10px" }}>
									<div style={{ height: "10px", float: "left", width: "75%" }}>
										PAY to the order of .......................................................................................................................................................			</div>
									<div style={{ borderBottom: "solid 1px red", height: "30px", float: "left", width: "25%" }}>
										<div style={{ height: "30px", float: "left", fontSize: "large", width: "2%", fontWeight: "bold" }}>
											$
										</div>
										<div style={{ background: "white", border: "solid 1px white", height: "30px", float: "left", width: "75%", marginLeft: "10px" }}>
										</div>
									</div>
									<div style={{ height: "10px", float: "left", width: "100%" }}>
										........................................................................................................................................................................................./100 DOLLARS					   </div>
								</div>


								<div style={{ float: "left", width: "100%", marginTop: "5px", padding: "10px" }}>
									<div style={{ float: "left", width: "100%" }}>
										{window.Title}
									</div>
									<div style={{ float: "left", width: "100%" }}>
										{chequemodel.BankCity}
									</div>
									<div style={{ float: "left", width: "100%" }}>
										{chequemodel.BankAdress}
									</div>


								</div>

								<div style={{ height: "60px", float: "left", width: "100%", marginTop: "-35px", padding: "10px" }}>
									<div style={{ height: "15px", float: "left", width: "45%", marginTop: "29px" }}>

										For ...................................................................................................										</div>

									<div style={{ background: "rgb(0, 36, 103)", border: "4px solid rgb(103, 45, 12)", height: "48px", float: "left", width: "5%", borderRadius: "5px" }}>
									</div>
									<div style={{ height: "15px", float: "left", width: "45%", marginLeft: "15px" }}>



										{chqinfomodel.SignatureTypeId == 24815 ?
											<div style={{ height: "15px", float: "left", width: "100%", marginBottom: "15px" }}   >
												Per ................................................................................................						   </div> : <div style={{ height: "15px", float: "left", width: "100%", marginBottom: "15px" }}></div>
										}
										<div style={{ height: "15px", float: "left", width: "100%", marginBottom: "15px" }}>
											Per ................................................................................................                     </div>
									</div>
								</div>
								<div style={{ height: "60px", float: "left", width: "45%", marginTop: "10px", fontFamily: "MICR Encoding", fontSize: "19px", padding: "10px" }}>

									<div style={{ height: "35px", float: "left", width: "15%" }}>0001</div>
									<div style={{ height: "35px", float: "left", width: "15%" }}>{chequemodel.TransitNumber}</div>
									<div style={{ height: "35px", float: "left", width: "15%" }}>{chequemodel.InstutionNumber}</div>
									<div style={{ height: "35px", float: "left", width: "30%" }}>{chequemodel.AccountNumber}</div>


								</div>

							</div>
						</CardMedia>
					</CardHeader>
				</Card>





			</div>
		);
	}
}

ChequeOrderDetailInfo.propTypes = {
	classes: PropTypes.object,
	chequemodel: PropTypes.object,
	handleChange: PropTypes.func,
	PhoneModel: PropTypes.object,
	propModel: PropTypes.object
};

export default withArtifex(ChequeOrderDetailInfo, {});