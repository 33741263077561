import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import Formatter from "core/Formatter";
import { EditIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import GridButton from "views/Components/GridButton.jsx";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType } from "views/Constants/Constant";

class ClientCardholderTransactionFee extends React.Component {
	constructor(props) {
		super(props);

		this.emptyProfile = {
			Id: 0,
			IsRecordValid: true,
			ProfileName: null,
			IsDefaultProfile: true
		};

		this.emptyDetail = {
			Index: null,
			Id: 0,
			IsRecordValid: true,
			ProfileId: 0,
			TransactionCodeId: null,
			TransactionCode: null,
			FeeRate: null,
			DeclineFee: null,
			FlatAmount: null,
			ProvisionSourceId: 0,
			ProvisionSource: null,
			CardTransactionFeeProfile: { ...this.emptyProfile }
		};

		this.initialModel = {
			SelectedFeeProfile: { ...this.emptyProfile },
			SelectedTransactionFee: { ...this.emptyDetail },
			ProfileList: [],
			DetailList: []
		};

		this.parameterProvisionSource = {
			ParameterCode: "ProvisionSource"
		};

		this.feeProfileColumns = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip="Edit"
							Icon={EditIcon}
							Disabled={props.Disabled}
							OnClick={() => { this.handleSelectProfile(row.original, row.index); }} />
					</div>
				),
				sortable: false,
				filterable: false,
				resizable: false,
				width: 60
			},
			{
				Header: "Profile Name",
				accessor: "Profile.ProfileName"
			},
			{
				Header: "User",
				accessor: "Profile.InsertUser"
			},
			{
				Header: "Create Date",
				accessor: "Profile.InsertDateTime",
				type: GridColumnType.DateTime
			},
		];

		this.feeItemColumns = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip="Edit"
							Icon={EditIcon}
							Disabled={props.Disabled}
							OnClick={() => { this.handleSelectDetail(row.original, row.index); }} />
					</div>
				),
				sortable: false,
				filterable: false,
				resizable: false,
				width: 60
			},
			{
				Header: "Fee Profile Name",
				accessor: "CardTransactionFeeProfile.ProfileName"
			},
			{
				Header: "Transaction Source",
				accessor: "ProvisionSource.ParameterDesc"
			},
			{
				Header: "Transaction Code",
				accessor: "TransactionCode.TxnCode"
			},
			{
				Header: "Transaction Name",
				accessor: "TransactionCode.TxnName"
			},
			{
				Header: "Fee Rate",
				accessor: "FeeRate",
				Cell: row => (<div>{Formatter.FormatPercentFee(row.value)}</div>)
			},
			{
				Header: "Flat Amount",
				accessor: "FlatAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Decline Fee",
				accessor: "DeclineFee",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			}
		];

		this.state = {
			model: { ...this.initialModel },
			isLoading: false,
			vModel: {},
			vModelDetail: {}
		};
	}

	toArray = (value) => {
		var returnList = [];
		if (value != null && value[0]) {
			for (const key in value) {
				if (value.hasOwnProperty(key)) {
					const element = value[key];
					returnList.push(element);
				}
			}
		}
		return returnList;
	};

	componentDidMount() {
		this.props.setAppLeftTitle("Transaction Fee Profile");
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
	}

	handleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		if (name === "FeeProfileName") {
			model.SelectedFeeProfile.ProfileName = newValue;
		} else if (name == "ProvisionSource") {
			model.SelectedTransactionFee.ProvisionSourceId = newValue;
			model.SelectedTransactionFee.ProvisionSource = data;
		} else if (name == "TransactionCode") {
			model.SelectedTransactionFee.TransactionCodeId = newValue;
			model.SelectedTransactionFee.TransactionCode = data;
		} else if (name == "FlatAmount") {
			model.SelectedTransactionFee.FlatAmount = newValue;
		} else if (name == "FeeRate") {
			model.SelectedTransactionFee.FeeRate = newValue;
		} else if (name == "DeclineFee") {
			model.SelectedTransactionFee.DeclineFee = newValue;
		}
		this.setState({ model });
	};

	handleClear = () => {

		this.setState({
			model: {
				...this.initialModel,
				SelectedFeeProfile: { ...this.emptyProfile },
				SelectedTransactionFee: { ...this.emptyDetail }
			}
		});
	};

	handleSearch = () => {
		this.executeSearch();
	};

	handleSelectProfile = (data, index) => {
		const model = { ...this.state.model };

		var feeProfile = Object.assign({}, data.Profile);
		var feeItemList = Object.assign({}, data.FeeList);

		model.SelectedFeeProfile = feeProfile;
		model.SelectedTransactionFee = Object.assign({}, this.emptyDetail);
		model.DetailList = this.toArray(feeItemList);

		this.setState({ model });
	};

	handleSelectDetail = (data, index) => {
		const model = { ...this.state.model };

		model.SelectedTransactionFee = Object.assign({}, data);
		model.SelectedTransactionFee.Index = index;

		this.setState({ model });
	};

	handleAddDetail = () => {
		const model = { ...this.state.model };

		var newItem = Object.assign({}, model.SelectedTransactionFee);

		if (!this.validateNewDetail(newItem, model.DetailList)) {
			return;
		}

		newItem.CardTransactionFeeProfile = model.SelectedFeeProfile;
		var temporary = [];
		if (newItem.Id == 0) {
			newItem.ProfileId = model.SelectedFeeProfile.Id;

			if (newItem.Index != null) {
				for (let index = 0; index < model.DetailList.length; index++) {
					const element = model.DetailList[index];
					temporary.push(index == newItem.Index ? newItem : element);
				}
			} else {
				model.DetailList.forEach((value, index, array) => { temporary.push(value); });
				temporary.push(newItem);
			}
		} else {
			model.DetailList.forEach(element => {
				if (element.Id == newItem.Id) {
					temporary.push(newItem);
				} else {
					element.CardTransactionFeeProfile = model.SelectedFeeProfile;
					temporary.push(element);
				}
			});
		}

		model.SelectedTransactionFee = Object.assign({}, this.emptyDetail);
		model.DetailList = temporary;



		this.setState({ model });
	}

	handleDeleteDetail = () => {
		const model = { ...this.state.model };

		var newItem = Object.assign({}, model.SelectedTransactionFee);

		var temporary = [];
		if (newItem.Id != 0) {
			newItem.IsRecordValid = false;
			model.DetailList.forEach(element => {
				if (element.Id != newItem.Id) { temporary.push(element); }
			});
		} else {
			for (let index = 0; index < model.DetailList.length; index++) {
				const element = model.DetailList[index];
				if (index != newItem.Index) { temporary.push(element); }
			}
		}
		model.SelectedTransactionFee = Object.assign({}, this.emptyDetail);
		model.DetailList = temporary;
		this.setState({ model }, this.executeSubmit);
	}

	handleClearDetail = () => {
		const model = { ...this.state.model };
		model.SelectedTransactionFee = Object.assign({}, this.emptyDetail);
		this.setState({ model });
	}

	executeSearch = () => {
		const model = { ...this.state.model };
		this.setState({ isLoading: true });
		const request = {
			ProfileName: model.SelectedFeeProfile.ProfileName
		};
		Proxy.POST(
			"/prepaidapi/v1.0/CardTransactionFeeProfile/SearchProfile",
			request,
			responseData => {
				model.SelectedTransactionFee = Object.assign({}, this.emptyDetail);
				model.ProfileList = responseData.Item;
				model.DetailList = [];
				this.setState({ model, isLoading: false });
			}
		);
	};
	successCallback = (responseData) => {

		if (responseData.IsSucceeded == false) {
			this.props.showMessage("error", "Error", responseData.ErrorDescription);
			return;
		}


		const model = { ...this.state.model };
		model.SelectedFeeProfile = responseData.Item.Profile;
		model.SelectedTransactionFee = Object.assign({}, this.emptyDetail);
		model.ProfileList = [];
		model.ProfileList.push(responseData.Item);
		model.DetailList = responseData.Item.FeeList;

		this.ShowMessage("success", "Succeeded!", "Operation successfully completed!");

		this.setState({ model });
	}

	errorCallBack = (error) => {
		this.props.showMessage("error", "Error", error);
	}

	executeSubmit = () => {
		if (!this.validateSubmit()) {
			return;
		}

		const model = { ...this.state.model };
		var callUrl = model.SelectedFeeProfile.Id != 0 ?
			"/prepaidapi/v1.0/CardTransactionFeeProfile/Update" :
			"/prepaidapi/v1.0/CardTransactionFeeProfile/Insert";

		var requestList = [];
		if (model.SelectedFeeProfile.Id != 0) {
			var originalDetail = model.ProfileList.find(x => x.Profile.Id == model.SelectedFeeProfile.Id).FeeList;
			var originalDetailList = this.toArray(originalDetail);

			model.DetailList.forEach(element => {
				if (element.Id == 0) { requestList.push(element); }
				if (element.Id != 0) { requestList.push(element); }
			});

			// deleted list
			originalDetailList.forEach(element => {
				var relatedItem = model.DetailList.find(x => x.Id == element.Id);
				if (relatedItem == null) {
					var deletedItem = Object.assign({}, element);
					deletedItem.IsRecordValid = false;
					requestList.push(deletedItem);
				}
			});
		} else {
			model.DetailList.forEach(element => {
				// added list
				requestList.push(element);
			});
		}

		//	const UniqueClientId = ClientHelper.IsClient() ? null : model.SelectedFeeProfile.UniqueClientId;
		const request = {
			ProfileName: model.SelectedFeeProfile.ProfileName,
			//	UniqueClientId: UniqueClientId,
			Id: model.SelectedFeeProfile != null ? model.SelectedFeeProfile.Id : null,
			IsRecordValid: true,
			DetailList: requestList
		};
		Proxy.POST(
			callUrl,
			request,
			this.successCallback,
			this.errorCallBack
		);
	};

	executeDelete = () => {
		const model = { ...this.state.model };

		//const UniqueClientId = ClientHelper.IsClient() ? null : model.SelectedFeeProfile.UniqueClientId;
		const request = {
			ProfileName: model.SelectedFeeProfile.ProfileName,
			//	UniqueClientId: UniqueClientId,
			Id: model.SelectedFeeProfile != null ? model.SelectedFeeProfile.Id : null,
			IsRecordValid: false
		};
		Proxy.POST(
			"/prepaidapi/v1.0/CardTransactionFeeProfile/Delete",
			request,
			responseData => {
				this.setState({
					model: {
						SelectedFeeProfile: Object.assign({}, this.emptyProfile),
						SelectedTransactionFee: Object.assign({}, this.emptyDetail),
						ProfileList: [],
						DetailList: [],
					}
				});
			}
		);
	};

	validateSubmit = () => {
		var errorList = [];
		const { model, vModel } = this.state;


		if (model.SelectedFeeProfile == null
			|| model.SelectedFeeProfile.ProfileName == null
			|| model.SelectedFeeProfile.ProfileName === "") {
			vModel.ProfileName = true;
			errorList.push("Please fill Profile Name field.");
		}
		else
			vModel.ProfileName = false;

		if (errorList.length > 0) {
			this.ShowMessageNode("warning", "Required fields!", errorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		return true;
	}

	validateNewDetail = (newItem, allDetail) => {
		var errorList = [];
		const { model, vModelDetail, vModel } = this.state;

		if (!model.SelectedFeeProfile.ProfileName) {
			errorList.push("Please fill in the Fee Profile Name field.");
			vModel.ProfileName = true;
		}
		else
			vModel.ProfileName = false;

		if (newItem.ProvisionSource == null) {
			errorList.push("Please fill in the Transaction Source field.");
			vModelDetail.ProvisionSourceId = true;
		}
		else
			vModelDetail.ProvisionSourceId = false;

		if (newItem.TransactionCodeId == null) {
			errorList.push("Please fill in the Transaction Code field.");
			vModelDetail.TransactionCodeId = true;
		}
		else
			vModelDetail.TransactionCodeId = false;

		if (newItem.FeeRate == null) {
			errorList.push("Please fill in the Fee Rate field.");
			vModelDetail.FeeRate = true;
		}
		else
			vModelDetail.FeeRate = false;

		if (newItem.FlatAmount == null) {
			errorList.push("Please fill in the Flat Amount field.");
			vModelDetail.FlatAmount = true;
		}
		else
			vModelDetail.FlatAmount = false;


		if (newItem.Id == 0) {
			if (newItem.Index != null) {
				for (let index = 0; index < allDetail.length; index++) {
					const element = allDetail[index];
					if (index != newItem.Index && element.TransactionCodeId == newItem.TransactionCodeId && element.ProvisionSourceId == newItem.ProvisionSourceId) {
						errorList.push("Transaction source and transaction code have already been added!");
					}
				}
			} else {
				allDetail.forEach((value, index, array) => {
					if (value.TransactionCodeId == newItem.TransactionCodeId && value.ProvisionSourceId == newItem.ProvisionSourceId) {
						errorList.push("Transaction source and transaction code have already been added!");
					}
				});
			}
		} else {
			allDetail.forEach(element => {
				if (element.Id != newItem.Id && element.TransactionCodeId == newItem.TransactionCodeId && element.ProvisionSourceId == newItem.ProvisionSourceId) {
					errorList.push("Transaction source and transaction code have already been added!");
				}
			});
		}

		if (errorList.length > 0) {
			this.ShowMessageNode("warning", "Required fields!", errorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		return true;
	}

	render() {
		const { Disabled } = this.props;
		const { model, isLoading, alert, vModel, vModelDetail } = this.state;

		return (
			<div>
				<LoadingComponent Show={isLoading} />
				{alert}
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Submit",
							OnClick: this.executeSubmit,
							Disabled: Disabled || model.SelectedFeeProfile.Id > 0,
							ModelFunction: () => model,
							ValidationFunction: this.validateSubmit
						},
						{
							Code: "Update",
							OnClick: this.executeSubmit,
							Disabled: Disabled || model.SelectedFeeProfile.Id == 0,
							ModelFunction: () => model,
							ValidationFunction: this.validateSubmit
						},
						{
							Code: "Delete",
							OnClick: this.executeDelete,
							Disabled: Disabled || model.SelectedFeeProfile.Id == 0,
							ModelFunction: () => model,
						},
						{
							Code: "Search",
							OnClick: this.handleSearch,
							Disabled: Disabled,
							ModelFunction: () => model,
						},
						{ Code: "Clear", OnClick: this.handleClear, Disabled: Disabled }
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card>
							<CardBody>
								<GridContainer>

									<GridItem xs={4}>
										<GridItem>
											<GenericTextInput
												Name="FeeProfileName"
												LabelText="Fee Profile Name"
												Value={model.SelectedFeeProfile.ProfileName}
												ValueChanged={this.handleChange}
												Required
												IsInvalid={vModel.ProfileName}
											/>
										</GridItem>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
						<Card>
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid Data={model.ProfileList} Columns={this.feeProfileColumns} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
						<Card>
							<CardHeader>
								<GridContainer spacing={16} alignItems="center">
									<GridItem>
										<GenericTitle text={"Fee Definition"} />
									</GridItem>
								</GridContainer>
							</CardHeader>
							<CardBody>
								<GridContainer>
									{!ClientHelper.IsClient() &&
										<GridContainer>
											<GridItem xs={3}>
												<GenericSelectInput
													Name="ProvisionSource"
													LabelText="Transaction Source"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.parameterProvisionSource}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={model.SelectedTransactionFee.ProvisionSourceId}
													ValueChanged={this.handleChange}
													Required
													IsInvalid={vModelDetail.ProvisionSourceId}
												/>
											</GridItem>
											<GridItem xs={3}>
												<GenericSelectInput
													Name="TransactionCode"
													LabelText="Transaction Code"
													Method="GET"
													Url="/bankapi/v1.0/BankTransactionCode/GetAllForTransactionFee"
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={d => `${d.TxnCode} - ${d.TxnName}`}
													Value={model.SelectedTransactionFee.TransactionCodeId || ""}
													ValueChanged={this.handleChange}
													CanClear
													Required
													IsInvalid={vModelDetail.TransactionCodeId}
												/>
											</GridItem>
											<GridItem xs={2}>
												<GenericNumberInput
													MaxValue={100}
													Name="FeeRate"
													LabelText="Fee Rate"
													Suffix="%"
													MaxLength={4}
													Value={model.SelectedTransactionFee.FeeRate}
													ValueChanged={this.handleChange}
													Required
													IsInvalid={vModelDetail.FeeRate}
												/>
											</GridItem>
											<GridItem xs={2}>
												<GenericNumberInput
													MaxValue={1000}
													Name="FlatAmount"
													LabelText="Flat Amount"
													Prefix="$"
													MaxLength={5}
													Value={model.SelectedTransactionFee.FlatAmount}
													ValueChanged={this.handleChange}
													Required
													IsInvalid={vModelDetail.FlatAmount}
												/>
											</GridItem>
											<GridItem xs={2}>
												<GenericNumberInput
													Name="DeclineFee"
													LabelText="Decline Fee"
													Prefix="$"
													MaxLength={1000000}
													Value={model.SelectedTransactionFee.DeclineFee}
													ValueChanged={this.handleChange}
												/>
											</GridItem>
											<GridItem md={8} />
											<GridItem xs={4}>
												<GridContainer justify="flex-end">
													<GridItem>
														<Button
															size="sm"
															onClick={this.handleAddDetail}>{model.SelectedTransactionFee.Index > -1 && model.SelectedTransactionFee.Index != null ? "UPDATE" : "ADD"}</Button>
														<Button size="sm"
															disabled={model.SelectedTransactionFee.Index == null}
															onClick={this.handleDeleteDetail}>DELETE</Button>
														<Button size="sm"
															onClick={this.handleClearDetail}>CLEAR</Button>
													</GridItem>
												</GridContainer>
											</GridItem>
										</GridContainer>
									}
									<GridContainer>
										<GridItem xs={12}>
											<br />
											<GenericGrid Data={model.DetailList} Columns={this.feeItemColumns} HideButton />

										</GridItem>
									</GridContainer>

								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		);
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })} />
			)
		});
	};

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					MessageNode={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })} />
			)
		});
	};

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					ShowCancel={true}
					OnCancel={() => this.setState({ alert: "" })}
					OnConfirm={onConfirm} />
			)
		});
	};
}

ClientCardholderTransactionFee.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	handleChange: PropTypes.func,
	menuId: PropTypes.number,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default withArtifex(ClientCardholderTransactionFee, {});
