import CardMedia from "@material-ui/core/CardMedia";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withRedux } from "core";
import RouteHelper from "core/RouteHelper";
import PropTypes from "prop-types";
import React from "react";
import { GenericAlert } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { InteracRedirectURL } from "views/Constants/Constant";

class InteracRedirectPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			alert: null,
			isLoading: false,
			model: {},
			DCLogo: {}, HRLogo: {}, CardHolderLogo: {}, EtransferLogo: {}, EPFinancialLogo: {}, DcOldLogo: {},
			PTNLogo: {}, DcPaymentEtransferLogo:{},
			InteracUrlData: {}
		};

		this.gridItemCenterStyle = { textAlign: "left" };
		this.imageStyle2 = { width: 150, float: "right", marginTop: 30, cursor: "pointer" };
		this.imageStyle1 = { width: 100, float: "left", cursor: "pointer" };
		this.cardHeaderStyle = { height: 80, width: "50%", marginLeft: "auto", marginRight: "auto" };
		this.cardMediaStyle = {
			height: "100%", width: "75%", marginLeft: "auto", marginRight: "auto", marginTop: 10,
			backgroundSize: "100% 100%"
		};
		this.cardBodyStyle = { marginTop: 62, width: "83%", marginLeft: "auto", marginRight: "auto" };
		this.buttonStyle = { fontSize: 20, textTransform: "none", fontWeight: "500" };
	}

	componentDidMount() {
		this.FetchUrl();
		this.GetDefaultButtons();
	}

	GetParameterByName = (name, url) => {
		name = name.replace(/[\[\]]/g, "\\$&");
		var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
			results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return "";
		return decodeURIComponent(results[2].replace(/\+/g, " "));
	}


	FetchUrl = () => {
		var data = {};
		data.Url = window.location.href;
		data.ETID = this.GetParameterByName("ETID", data.Url);
		data.RQETID = this.GetParameterByName("RQETID", data.Url);
		data.src = this.GetParameterByName("src", data.Url);
		data.fiID = this.GetParameterByName("fiID", data.Url);
		data.cuID = this.GetParameterByName("cuID", data.Url);
		data.lang = this.GetParameterByName("lang", data.Url);

		var etid = this.GetParameterByName("etid", data.Url);
		var rqetid = this.GetParameterByName("rqetid", data.Url);

		if (etid != null && etid != "") {
			data.ETID = etid;
		}
		if (rqetid != null && rqetid != "") {
			data.RQETID = rqetid;
		}

		if (data.ETID != null && data.ETID != "") { // money send
			data.IsValidUrl = true;
			data.IsMoneySend = true;
			data.IsMoneyRequest = false;
		}
		if (data.RQETID != null && data.RQETID != "") { // money request
			data.IsValidUrl = true;
			data.IsMoneySend = false;
			data.IsMoneyRequest = true;
		}

		if (data.ETID != null && data.RQETID != null) {
			data.IsValidUrl = false;
		}
		console.log(data);

		this.setState({ InteracUrlData: data });


	}
	DefaultImageClick = (url, Redirect) => {
		if (url == null || url == "") {
			this.ShowMessage("error", "Error", "Redirect url must be valid.");
			return;
		}
		var urldata = { ...this.state.InteracUrlData };
		if (Redirect) {
			var uri = url + "?";
			if (urldata.ETID != null && urldata.ETID != "") {
				uri += `ETID=${urldata.ETID}`;
			}
			if (urldata.RQETID != null && urldata.RQETID != "") {
				uri += `RQETID=${urldata.RQETID}`;
			}
			if (urldata.etid != null && urldata.etid != "") {
				uri += `ETID=${urldata.ETID}`;
			}
			if (urldata.rqetid != null && urldata.rqetid != "") {
				uri += `RQETID=${urldata.RQETID}`;
			}
			if (urldata.src != null && urldata.src != "") {
				uri += `&src=${urldata.src}`;
			}
			if (urldata.fiID != null && urldata.fiID != "") {
				uri += `&fiID=${urldata.fiID}`;
			}
			if (urldata.cuID != null && urldata.cuID != "") {
				uri += `&cuID=${urldata.cuID}`;
			}
			if (urldata.lang != null && urldata.lang != "") {
				uri += `&lang=${urldata.lang}`;
			}
			window.location.href = uri;
		} else {
			RouteHelper.Push(this.props.history, url, InteracRedirectURL.LocalStorageKey, urldata);
		}
	}
	GetDefaultButtons = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/InteracRedirectUrlMap/GetDefault",
			this.state.model,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ isLoading: false });

				if (responseData.Item != null) {
					var DC = responseData.Item.find(x => x.DefaultName == "DC");
					var HR = responseData.Item.find(x => x.DefaultName == "HR");
					var CH = responseData.Item.find(x => x.DefaultName == "CH");
					var ET = responseData.Item.find(x => x.DefaultName == "ET");
					var EP = responseData.Item.find(x => x.DefaultName == "EP");
					var OL = responseData.Item.find(x => x.DefaultName == "OL");
					var DCPD = responseData.Item.find(x => x.DefaultName == "DCPD");
					var DCPET = responseData.Item.find(x => x.DefaultName == "DCPET");



					this.setState({ DCLogo: DC || {}, HRLogo: HR || {}, CardHolderLogo: CH || {}, EtransferLogo: ET || {}, EPFinancialLogo: EP || {},DcOldLogo : OL || {}, DCPDLogo: DCPD || {}, DcPaymentEtransferLogo: DCPET || {} });
				} else {
					this.setState({ DCLogo: {}, HRLogo: {}, CardHolderLogo: {}, EtransferLogo: {}, EPFinancialLogo: {} ,DcOldLogo : {}, PTNLogo: {}, DcPaymentEtransferLogo: {},DCPDLogo: {}});
				}

			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	handleChange = (name, newValue) => {
		this.setState(function (state, props) {
			var model = state.model || {};
			model[name] = newValue;
			return { model };
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	ShowMessage = (type, title, message) => {
		// type:-success->warning->info->error
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}

	render() {
		const { classes } = this.props;
		const { model, isLoading, alert,PTNLogo, DcPaymentEtransferLogo,DCPDLogo} = this.state;
		const logo = require("assets/img/" + window.LoginLogo);

		return (
			<div className={classes.container}>
				<LoadingComponent Show={isLoading} />
				{alert}
				<GridContainer justify="center">
					<GridItem style={{ minWidth: "600px", maxWidth: "656px", marginTop: "150px" }}>
						<form>
							<Card login>
								<CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color="white" style={this.cardHeaderStyle}>
									<CardMedia className={classes.media} image={logo} style={this.cardMediaStyle} title="" />
								</CardHeader>
								<CardBody style={this.cardBodyStyle}								>
									<GridContainer>
										<GridItem xs={2}></GridItem>
										<GridItem xs={8}>

											{DCPDLogo?.IsVisible == true && (
												<div>
													<Button style={this.buttonStyle} color="primary" size="lg" block onClick={() => this.DefaultImageClick(DCPDLogo?.RedirectUrl, false)}>
														{DCPDLogo?.RedirectUrlName
														}
													</Button>
													<br />
												</div>
											)}
											{DcPaymentEtransferLogo?.IsVisible == true && (
												<div>
													<Button style={this.buttonStyle} color="primary" size="lg" block onClick={() => this.DefaultImageClick(DcPaymentEtransferLogo?.RedirectUrl, true)}>
														{DcPaymentEtransferLogo?.RedirectUrlName}
													</Button>
													<br />	
												</div>
											)}
											<br />	<br />	<br />	<br />
										</GridItem>
										<GridItem xs={2}></GridItem>
									</GridContainer>
								</CardBody>
							</Card>
						</form>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

InteracRedirectPage.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withRedux(InteracRedirectPage, loginPageStyle);