import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTextInput, GenericNumberInput } from "views/Components/Generic";
import Tooltip from "react-tooltip-lite";
import { blackColor } from "assets/jss/material-dashboard-pro-react.jsx";

class FinancialInstitutionBranchList extends React.Component {
	constructor(props) {
		super(props);
		this.getappCenterTitle = "Empty";
		this.getappLeftTitle = "Empty";
		this.columns = [
			{
				Header: "Financial Institution",
				accessor: "FinancialInstitution",
				Cell: row => (
					<div>{this.GetRenderFinancialInstitution(row.original)} </div>
				),
			},
			{
				Header: "Routing Number",
				accessor: "RoutingNumber",

			},
			{
				Header: "Transit Number",
				accessor: "TransitNumber"
			},
			{
				Header: "Branch",
				accessor: "Description"
			},
			{
				Header: "Province",
				accessor: "StateCode"
			},
			{
				Header: "City",
				accessor: "CityName"
			},
			{
				Header: "Address1",
				accessor: "Address1"
			},
			{
				Header: "Address2",
				accessor: "Address2"
			},
			{
				Header: "Postal Code",
				accessor: "ZipCode"
			}
		];
		this.zIndex10 = { zIndex: 10 }

		this.state = {
			model: {
			}
		};

	}
	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("SYSTEM ADMIN");	this.getappCenterTitle = "SYSTEM ADMIN";
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("Financial Institutions Branch List");	this.getappLeftTitle = "Financial Institutions Branch List";
		}
		this.LoadCanadaPost();
	}

	ValueChanged = (name, value) => {
		const model = { ...this.state.model };
		model[name] = value;
		this.setState({ model });
	}

	RowSelected = (index) => {
		const { list } = this.state;

		this.setState({ model: list[index], selected: index });
	}

	GetRenderItemAction = (x) => {
		var render = `${x.FinancialInstitutionId} - ${x.Description}`;
		return render;
	}
	GetRenderFinancialInstitution = (x) => {
		var render = `${x.FinancialInstitution.FinancialInstitutionId} - ${x.FinancialInstitution.Description}`;
		return render;
	}

	Search = async () => {
		const { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/bankapi/v1.0/Bank/GetAllFinancialInstitutionBranch", model, null, null, null);

		this.setState({ list: result || [] });
	}

	Clear = () => {
		this.setState({ model: {}, list: [], selected: null });
	}

	Validate = () => {
		var { model } = this.state;
		const { showValidationErrors } = this.props;
		var errors = [];

		if (!model.FinancialInstitutionId) {
			errors.push("Financial Institution can not be null.");
		}
		if (!model.TransitNumber) {
			errors.push("Transit Number can not be null.");
		}
		if (!model.Description) {
			errors.push("Description can not be null.");
		}
		if (!model.CityName) {
			errors.push("City can not be null.");
		}
		 
		if (!model.ZipCode) {
			errors.push("Postal Code can not be null.");
		}
		if (!model.Address1) {
			errors.push("Address 1 can not be null.");
		}

		model["IsFinancialInstitutionId"] = !model.FinancialInstitutionId;
		model["IsTransitNumber"] = !model.TransitNumber;
		model["IsDescription"] = !model.Description;
		model["IsCityName"] = !model.CityName;
		model["IsZipCode"] = !model.ZipCode;
		model["IsAddress1"] = !model.Address1;

		if (errors.length > 0) {
			showValidationErrors(errors);

			return false;
		}

		return true;
	}

	LoadCanadaPost = async () => {

		var parent = this;
		var result = await this.props.ExecuteApiPost("/coreapi/v1.0/Parameter/Search", {
			ParameterCode: "CanadaPostApiKey",
			ParameterValue: "CP"
		});
		if (result?.length > 0) {
			var apikey = result[0].ParameterDesc;
			console.log("KEY CP ", apikey);


			var url = window.CanadaPostAddressCompleteUrl;
			var path = url + apikey;
			var CanadaPostRequest=this.SubmitCanadaPostRequest;

			var pca;
			const script = document.createElement("script");
			script.src = path;
			script.async = false;
			document.head.appendChild(script);
			script.onload = function () {
				pca = window.pca;
				if (pca != null) {
					var fields =
						[
							{ element: "street-address", field: "Line1", mode: pca.fieldMode.SEARCH },
							{ element: "street-address2", field: "Line2", mode: pca.fieldMode.POPULATE },
							{ element: "postcode", field: "PostalCode", mode: pca.fieldMode.SEARCH },
						],
						options = {
							key: apikey
						},
						control = new pca.Address(fields, options);
						CanadaPostRequest();

					control.listen("populate", function (address) {
						parent.setState(state => {
							state.model.Address1 = address.Line1;
							state.model.Address2 = address.Line2;
							state.model.ZipCode = address.PostalCode;
							state.model.StateCode = address.ProvinceCode;
							state.model.CityName = address.City;
							return state;
						});
					});
				}
			};

		}

	}
	SubmitCanadaPostRequest = async () => {
		const model = { ...this.state.model };
		var requestdata = { 

			UniqueClientId: null,
			MenuCode:this.props.getMenuCode,
			AppCenterTitle:this.getappCenterTitle,
			AppLeftTitle:this.getappLeftTitle
		};
		// Proxy.POST(
		// 	"/bankapi/v1.0/Reporting/CanadaPostRequestReportInsert",
		// 	requestdata,
		// 	responseData => {
		// 		if (responseData.Item != null ) {
					 
		// 		}
		// 	},
 		// );
		  var result = await this.props.ExecuteApiPost("/bankapi/v1.0/Reporting/CanadaPostRequestReportInsert", requestdata);
	}
	render() {
		const { model, list, selected } = this.state;
		const { Disabled, ExecuteApiPost, showConfirmMessageInDelete } = this.props;

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},
						{
							Code: "Submit",
							Disabled: Disabled || selected != null,
							OnClick: () => ExecuteApiPost("/bankapi/v1.0/Bank/InsertFinancialInstitutionBranch", model, null, null, this.Validate, [this.Clear], true)
						},
						{
							Code: "Update",
							Disabled: Disabled || selected == null,
							OnClick: () => ExecuteApiPost("/bankapi/v1.0/Bank/InsertFinancialInstitutionBranch", model, null, null, this.Validate, [this.Clear], true)
						},
						{
							Code: "Delete",
							Disabled: Disabled || selected == null,
							OnClick: () => showConfirmMessageInDelete(() => ExecuteApiPost("/bankapi/v1.0/Bank/DeleteFinancialInstitutionBranch", { Id: model.Id }, null, null, null, [this.Clear], true))
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Financial Institutions Branch">
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												Required
												IsInvalid={model.IsFinancialInstitutionId}
												key={model.FinancialInstitutionId}
												Name="FinancialInstitutionId"
												LabelText="Financial Institution"
												Url="/bankapi/v1.0/FinancialInstitution/GetAll"
												Method="GET"
												DataRoot="Item"
												Parameter={{}}
												KeyValueMember="Id"
												RenderItem={this.GetRenderItemAction}
												Value={model.FinancialInstitutionId}
												ValueChanged={this.ValueChanged} />

											<GenericNumberInput
												NoFormatting={true}
												LabelText="Transit Number"
												Name="TransitNumber"
												Value={model.TransitNumber}
												ValueChanged={this.ValueChanged}
												Disabled={Disabled}
												MaxLength={5}
												IsInvalid={model.IsTransitNumber}
												Required
											/>
											<GenericTextInput
												Required
												IsInvalid={model.IsDescription}
												Name="Description"
												LabelText="Branch"
												Value={model.Description}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={4}>
											<GenericTextInput
												Required
												IsInvalid={model.IsAddress1}
												Name="Address1"
												LabelText="Address Line 1"
												Value={model.Address1}
												Id="street-address"
												ValueChanged={this.ValueChanged} />

											<GenericTextInput

												Disabled={Disabled}
												Name="Address2"
												LabelText="Address Line 2"
												Value={model.Address2}
												ValueChanged={this.ValueChanged} />
											<Tooltip content="Please Use the 2 Letter abbreviations" styles={this.zIndex10} background={blackColor} color="white">

												<GenericTextInput
													Disabled={Disabled}
													Name="StateCode"
													LabelText="Province"
													Value={model.StateCode}
													ValueChanged={this.ValueChanged} />
											</Tooltip>
											<GenericTextInput
												Required
												IsInvalid={model.IsCityName}
												Disabled={Disabled}
												Name="CityName"
												LabelText="City"
												Value={model.CityName}
												ValueChanged={this.ValueChanged} />
											<GenericTextInput
												Required
												IsInvalid={model.IsZipCode}
												Disabled={Disabled}
												Name="ZipCode"
												LabelText="Postal Code"
												Value={model.ZipCode}
												ValueChanged={this.ValueChanged} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columns}
													RowSelected={this.RowSelected}
													ShowPagination={true}
													PageSize={100}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

FinancialInstitutionBranchList.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default FinancialInstitutionBranchList;