import { primaryColor, cardBoxShadow } from "assets/jss/material-dashboard-pro-react.jsx";

const eftEtransferTitleBarStyle = {
	main: {
		...cardBoxShadow,
		backgroundColor: "#e4e4e4",
		fontWeight: 700,
		textTransform: "uppercase",
		borderRadius: "6px",
		marginTop: "8px"
	},
	icon: {
		backgroundColor: primaryColor,
		color: "white",
		borderRadius: "6px"
	},
	appbaritemright: {
		background: "#fcfcfc",
		borderRadius: 4,
		borderRadiusInputTopLeft: 7,
		borderRadiusInputTopRight: 7,
		borderRadiusInputBottomLeft: 7,
		borderRadiusInputBottomRight: 7,
		color: "#2e333a",
		fontSize: "1.2em",
		lineHeight: "1.428571429",
		fontWeight: 400,
		borderColor: "#707070",
		textAlign: "center",
		marginTop: "9px"
	},
	appbaritemleft: {
		fontSize: "1.1em",
		textAlign: "center",
		marginTop: "10px"
	},
	appbar: {
		height: "40px",
		color: "#2e333a",
		fontSize: "0.8em",
		lineHeight: "1.428571429",
		fontWeight: 400,
		textAlign: "left",
		...cardBoxShadow,
		backgroundColor: "#e4e4e4",
		textTransform: "uppercase",
		borderRadius: "6px",
		marginTop: "8px"
	}
};

export default eftEtransferTitleBarStyle;
