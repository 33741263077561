import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import ClientHelper from "core/ClientHelper.js";
import Formatter from "core/Formatter";
import HtmlEditorHelper from "core/HtmlEditorHelper";
import { EditorState } from "draft-js";
import PropTypes from "prop-types";
import React from "react";
import "react-autocomplete-input/dist/bundle.css";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericCheckInput, GenericDialog, GenericDialogActions, GenericGrid, GenericLabel, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { NotificationSendType } from "views/Constants/Constant";
const emptyObject = {};
class TemplateDefinition extends React.Component {
	constructor(props) {
		super(props);

		this.isClient = ClientHelper.IsClient();

		this.initialModel = {
			Id: 0,
			UniqueClientId: this.isClient ? ClientHelper.GetClientRowId() : undefined
		};

		this.state = {
			list: [],
			model: this.initialModel,
			countModel: {},
			rowIndex: -1,
			isLoading: false,
			propertyList: [],
			alert: null,
			File: {},
			editorState: EditorState.createEmpty(),
			IsVisibleMailEditor: true,
			IsEmailSignatureOpen: false,
			IsPreviewOpen: false
		};

		this.fileInputRef = React.createRef();

		this.parameterClient = {};

		this.parameterTemplate = {
			operation: "GetByParameter",
			screenId: 52
		};

		this.parameterSendType = {
			ParameterCode: "NotificationSendType"
		};

		this.parameterUniqueClientId = {
			UniqueClientId: this.isClient ? ClientHelper.GetClientId() : undefined,
		};

		this.renderItemUniqueClient = {};

		this.Columns = [
			{
				Header: "Template Type",
				accessor: "NotificationTemplateType.Description",
				width: 250
			},
			{
				Header: "Send Type",
				accessor: "NotificationSendType.ParameterDesc",
				width: 100
			},
			{
				Header: "Bank Message",
				accessor: "IsDefaultTemplate",
				Cell: row => (
					Formatter.FormatYesNo(row.value)
				),
				width: 120,
				filterMethod: (filter, row, column) => {
					return Formatter.FormatYesNo(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase());
				}
			},
			{
				Header: "Client",
				accessor: "UniqueClient.ClientName",
				width: 150,
				show: !this.isClient
			},
			{
				Header: "Subject",
				accessor: "Subject",
				width: 250
			},
			{
				Header: "Template",
				accessor: "Template"
			},
			{
			  Header: "OrganizationUniqueId",
			  accessor: "OrganizationUniqueId",
			  show: window.OrganizationUniqueId != 5000?false:true
			}
		];
	};

	componentDidMount() {
		this.props.setAppLeftTitle("Template Definition");
		this.props.setAppCenterTitle("Notifications");

		this.setState({ editorState: HtmlEditorHelper.EmptyEditorContent() });

		this.GetAllDataModelProperty();
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		if (!model.OrganizationUniqueId) {
			this.ShowMessage(
			  "warning",
			  "OrganizationUniqueId",
			  "OrganizationUniqueId must be filled."
			);
			return false;
		 }
		if (name == "NotificationSendTypeId") {
			model.NotificationSendType = data;
			this.setState({ IsVisibleMailEditor: NotificationSendType.Email === data.ParameterValue ? true : false });
		}
		if (name === "IsDefaultTemplate" && newValue) {
			model.UniqueClient = null;
			model.UniqueClientId = null;
		}
		if (name === "EmailSignatureId") {
			model.SignatureContent = data ? data.Content : null;
		}
		this.setState({ model });
	}

	HandleToggleSignature = () => {
		this.setState(state => {
			state.IsEmailSignatureOpen = !state.IsEmailSignatureOpen;
			return state;
		});
	}

	HandleTogglePreview = () => {
		this.setState(state => {
			state.IsPreviewOpen = !state.IsPreviewOpen;
			return state;
		});
	}

	onEditorStateChange = (editorState) => {
		// console.log(editorState)
		this.setState({
			editorState,
		});
	};

	HandleClear = () => {
		this.setState({ model: this.initialModel, rowIndex: -1, editorState: HtmlEditorHelper.EmptyEditorContent(), File: {} });
	}

	HandleSearch = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/coreapi/v1.0/NotificationTemplate/Search",
			this.state.model,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ list: responseData.Item });
				} else {
					this.setState({ list: [] });
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			});
	}

	HandleSubmit = () => {
		const { model, editorState } = this.state;
		const temp = { ...this.state.model };

		if (!this.Validate())
			return;

		if (model.NotificationSendType.ParameterValue === NotificationSendType.Email) {
			temp.Template = HtmlEditorHelper.EditorContentToHtml(editorState);
		} else {
			temp.Template = HtmlEditorHelper.EditorContentToText(editorState);
		}

		this.setState({ isLoading: true });
		Proxy.POST(
			"/coreapi/v1.0/NotificationTemplate/InsertOrUpdate",
			temp,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessage("success", "Success", "Operation is successfully!");
				this.HandleClear();
				this.HandleSearch();
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	HandleDelete = () => {
		this.setState({ isLoading: true });
		Proxy.GET(
			"/coreapi/v1.0/NotificationTemplate/Delete/Id?Id=" + this.state.model.Id,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessage("success", "Success", "Deleted successfully!");
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	GetAllDataModelProperty = () => {
		this.setState({ isLoading: true });
		Proxy.GET(
			"/coreapi/v1.0/NotificationTemplate/GetDataModelProperties",
			responseData => {
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				if (responseData.Item != null) {
					var temp = responseData.Item.map(x => {
						return { text: x.PropertyDescription, value: x.PropertyName };
					});
					this.setState({ propertyList: temp });
				}
				this.setState({ isLoading: false });
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	Validate = () => {
		const { model, editorState } = this.state;

		if (!model.NotificationTemplateTypeId) {
			this.ShowMessage("warning", "Template Type not selected", "Select Template Type to continue.");
			return false;
		}

		if (!model.NotificationSendTypeId) {
			this.ShowMessage("warning", "Notification Send Type not selected", "Select Notification Send Type to continue.");
			return false;
		}

		if (model.NotificationSendType.ParameterValue === NotificationSendType.Email) {
			var mailContent = HtmlEditorHelper.EditorContentToHtml(editorState);
			if (mailContent == null || mailContent == "") {
				this.ShowMessage("warning", "Template is empty", "Enter the E-Mail content to continue.");
			}
		} else {
			var smsContent = HtmlEditorHelper.EditorContentToText(editorState);
			if (smsContent == null || smsContent == "") {
				this.ShowMessage("warning", "Template is empty", "Enter the SMS content to continue.");
			}
		}

		return true;
	}

	FileSelect = (e) => {
		e.preventDefault();

		var file = e.target.files[0];
		if (!file) {
			return;
		}
		this.setState({ File: file });

		var EsTemp = this.onEditorStateChange;
		var model = this.state.editorState;
		var ToHtml = HtmlEditorHelper.EditorContentToHtml;
		var ToEditor = HtmlEditorHelper.HtmlToEditorContent;
		var reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			var base64 = reader.result;
			var html = ToHtml(model);
			var img = "<img src=\"" + base64 + "\" alt=\"undefined\" style=\"float:none;height: auto;width: auto\"/>";

			html += img;
			html += "<p></p><p></p><p></p>";

			const editorState = ToEditor(html);
			EsTemp(editorState);
		};
		reader.onerror = function (error) {
			console.log("Error: ", error);
		};
	}

	GetSignatureContent = () => {
		const { model } = this.state;
		return { __html: model.SignatureContent };
	}

	Preview = () => {
		const { model, editorState } = this.state;
		var mailContent = HtmlEditorHelper.EditorContentToHtml(editorState);
		if (model.UseSignature && model.SignatureContent)
			mailContent += model.SignatureContent;
		return { __html: mailContent };
	}

	GetClientParameter = () => {
		this.parameterClient.UniqueClientId = this.state.model.UniqueClientId;
		return this.parameterClient;
	}

	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}

	SelectedRowChange = (index) => {
		const model = this.state.list[index];
		if (model && model.Template != null && model.Template != "") {
			var content = HtmlEditorHelper.HtmlToEditorContent(model.Template);
			if (model.NotificationSendType.ParameterValue === NotificationSendType.Email) {
				this.setState({ editorState: content, IsVisibleMailEditor: true });
			} else {
				this.setState({ editorState: content, IsVisibleMailEditor: false });
			}
		} else {
			this.setState({ editorState: HtmlEditorHelper.EmptyEditorContent() });
		}

		if(model.EmailSignature != null ){
			model.UseSignature = model.EmailSignature && model.EmailSignature.IsRecordValid;
			model.SignatureContent = model.EmailSignature ? model.EmailSignature.Content : "";
		}
		if(model.EmailSignature == null){
			model.UseSignature =false;
			model.SignatureContent = "";
		}

		this.setState({ model, rowIndex: index });
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}
	RenderOrganizationUniqueIdSelect = (d) => {
		if (window.OrganizationUniqueId != 5000) {
		  const { model } = this.state;
		  model["OrganizationUniqueId"] = d.OrganizationUniqueId;
		  this.setState({ model: model });
		}
		return d.OrganizationUniqueId + "-" + d.OrganizationName;
	 };
	render() {
		const { Disabled } = this.props;
		const { alert, isLoading, model, editorState, list, propertyList, IsVisibleMailEditor, IsEmailSignatureOpen, IsPreviewOpen } = this.state;

		return (
			<div>

				<LoadingComponent Show={isLoading} />

				{alert}

				<GenericDialog open={IsPreviewOpen} maxWidth="lg" fullWidth={true}>
					<DialogTitle>
						<GridContainer justify="space-evenly" alignItems="center">
							<GridItem>
								<GenericLabel FontSize="16px" TextColor="black" Text="E-Mail Signature" Bold={true} />
							</GridItem>
						</GridContainer>
					</DialogTitle>
					<DialogContent>
						<GridContainer>
							<GridItem xs={12}>
								<br />
								<div style={{ border: "1px solid #000", padding: 10, overflow: "auto" }}
									dangerouslySetInnerHTML={this.Preview()} />
							</GridItem>
						</GridContainer>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={() => this.HandleTogglePreview()}>CLOSE</Button>
					</GenericDialogActions>
				</GenericDialog>

				<GenericDialog open={IsEmailSignatureOpen} maxWidth="lg" fullWidth={true}>
					<DialogTitle>
						<GridContainer justify="space-evenly" alignItems="center">
							<GridItem>
								<GenericLabel FontSize="16px" TextColor="black" Text="E-Mail Signature" Bold={true} />
							</GridItem>
						</GridContainer>
					</DialogTitle>
					<DialogContent>
						<GridContainer>
							<GridItem xs={4}>
								<GenericSelectInput
									Name="EmailSignatureId"
									LabelText="E-Mail Signature"
									Method="POST"
									Url="/coreapi/v1.0/EmailSignature/Search"
									Parameter={this.GetClientParameter()}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="SignatureName"
									Value={model.EmailSignatureId}
									ValueChanged={this.HandleChange}
									CanClear
									Disabled={!IsVisibleMailEditor} />
							</GridItem>
							<GridItem xs={2}>
								<GenericCheckInput
									Name="UseSignature"
									LabelText="Use Signature"
									Value={model.UseSignature}
									ValueChanged={this.HandleChange}
									Disabled={!(model.EmailSignatureId > 0)} />
							</GridItem>
							<GridItem xs={6} />
							<GridItem xs={12}>
								<br />
								<div key={model.EmailSignatureId} style={{ marginTop: 6, border: "1px solid #000", height: "94.5%", minHeight: 200, padding: 10, overflow: "auto" }}
									dangerouslySetInnerHTML={this.GetSignatureContent()} />
							</GridItem>
						</GridContainer>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={() => this.HandleToggleSignature()}>CLOSE</Button>
					</GenericDialogActions>
				</GenericDialog>

				< ButtonToolbar ButtonList={
					[
						{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
						{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled || model == this.initialModel },
						{ Code: "Submit", OnClick: this.HandleSubmit, Disabled: Disabled || model.Id > 0, ModelFunction: () => model, FillDataFromModel: model => this.setState({ model }), ValidationFunction: this.Validate },
						{ Code: "Update", OnClick: this.HandleSubmit, Disabled: Disabled || model.Id === 0, ModelFunction: () => model, FillDataFromModel: model => this.setState({ model }), ValidationFunction: this.Validate },
						{ Code: "Delete", OnClick: this.HandleDelete, Disabled: Disabled || model.Id === 0 }
					]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text="Filter Panel" />
							</CardHeader>
							<CardBody>
								<GridContainer>
								<GridItem xs={3}>
                    <GenericSelectInput
                      IsTextBold
                      key={this.state.IsClientComponent}
                      Name="OrganizationUniqueId"
                      LabelText="Organization"
                      Value={model.OrganizationUniqueId || ""}
                      DataRoot="Item"
                      ValueChanged={this.HandleChange}
                      KeyValueMember="OrganizationUniqueId"
                      TextValueMember="OrganizationUniqueId"
                      //  TaskPoolDomain={this.loadTaskPool}
                      Url="/bankapi/v1.0/OrganizationInfo/GetAll"
                      Method="POST"
                      RenderItem={this.RenderOrganizationUniqueIdSelect}
                      Parameter={emptyObject}
                      Required
                      Disabled={model.Id > 0 ? true : Disabled}
                    />
                  </GridItem>
									<GridItem xs={3}>
										<GenericSelectInput
											Name="NotificationTemplateTypeId"
											LabelText="Template Type"
											LabelMd={5}
											Method="POST"
											Url="/coreapi/v1.0/dynamic/do"
											Parameter={this.parameterTemplate}
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember="Description"
											Value={model.NotificationTemplateTypeId}
											ValueChanged={this.HandleChange} />
									</GridItem>
									<GridItem xs={3}>
										<GenericSelectInput
											Name="NotificationSendTypeId"
											LabelText="Notification Send Type"
											LabelMd={5}
											Method="POST"
											Url="/coreapi/v1.0/Parameter/Search"
											Parameter={this.parameterSendType}
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember="ParameterDesc"
											Value={model.NotificationSendTypeId}
											ValueChanged={this.HandleChange} />
									</GridItem>
									{!this.isClient &&
										<GridItem xs={3} style={{ marginTop: 6 }}>
											<GenericCheckInput
												Name="IsDefaultTemplate"
												LabelText="Bank Message"
												Value={model.IsDefaultTemplate}
												ValueChanged={this.HandleChange} />
										</GridItem>
									}
									<GridItem xs={3} style={{ opacity: model.IsDefaultTemplate ? 0.4 : 1 }}>
										<GenericSelectInput
											Name="UniqueClientId"
											LabelText="Client"
											LabelMd={4}
											Method="POST"
											Url="/bankapi/v1.0/BankCustomer/Search"
											Parameter={this.parameterUniqueClientId}
											DataRoot="Item"
											KeyValueMember="Id"
											RenderItem={this.RenderItemUniqueClient}
											Value={model.UniqueClientId}
											ValueChanged={this.HandleChange}
											CanClear={!this.isClient}
											Disabled={model.IsDefaultTemplate} />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text="Template" />
							</CardHeader>
							<CardBody>
								{IsVisibleMailEditor &&
									<GridContainer>
										<GridItem xs={3}>
											<GenericTextInput
												Name="Subject"
												LabelText="Subject"
												Value={model.Subject}
												ValueChanged={this.HandleChange}
												Disabled={!IsVisibleMailEditor} />
										</GridItem>
										<GridItem style={{ float: "right", textAlign: "end", opacity: !IsVisibleMailEditor ? 0.4 : 1 }}>
											<Button size="sm" onClick={() => this.fileInputRef.current.click()} style={{ marginTop: 8 }}>+ Image</Button>
											<input type="file" key={model.FileName} style={{ display: "none" }} onChange={this.FileSelect} ref={this.fileInputRef} accept="image/*" />
											<Button size="sm" onClick={() => this.HandleToggleSignature()} style={{ marginTop: 8 }}>+ E-Mail Signature</Button>
											<Button size="sm" onClick={() => this.HandleTogglePreview()} style={{ marginTop: 8 }}>Preview</Button>
										</GridItem>
									</GridContainer>
								}
								<GridContainer>
									<GridItem xs={12}>
										<br />
										<div style={styles.editor}>
											<Editor
												editorState={editorState}
												toolbarClassName="toolbarClassName"
												wrapperClassName="wrapperClassName"
												editorClassName="editorClassName"
												onEditorStateChange={this.onEditorStateChange}
												// toolbarHidden={!IsVisibleMailEditor}
												mention={{
													separator: " ",
													trigger: "@",
													suggestions: propertyList,
												}} />
										</div>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text="Template List" />
							</CardHeader>
							<CardBody>
								<GridContainer spacing={16}>
									<GridItem xs={12}>
										<GenericGrid
											Data={list}
											Columns={this.Columns}
											RowSelected={this.SelectedRowChange}
											SelectedIndex={this.state.rowIndex} />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

const styles = {
	editor: {
		border: "1px solid gray",
		minHeight: "16em"
	}
};

TemplateDefinition.propTypes = {
	classes: PropTypes.object
};

export default TemplateDefinition;
