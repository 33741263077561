import { DialogContent, DialogTitle } from "@material-ui/core";
// @ts-ignore
import Card from "components/Card/Card.jsx";
// @ts-ignore
import CardBody from "components/Card/CardBody.jsx";
// @ts-ignore
import Button from "components/CustomButtons/Button.jsx";
// @ts-ignore
import GridContainer from "components/Grid/GridContainer.jsx";
// @ts-ignore
import GridItem from "components/Grid/GridItem.jsx";
// @ts-ignore
import { Proxy, withArtifex } from "core";
// @ts-ignore
import ClientHelper from "core/ClientHelper";
// @ts-ignore
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React from "react";
// @ts-ignore
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
// @ts-ignore
import { GenericPage, GenericCheckInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericTextInput, GenericSelectInput } from "views/Components/Generic";
// @ts-ignore
import LoadingComponent from "views/Components/LoadingComponent";
import FileUpload from "views/Components/FileUpload";
// @ts-ignore
import { Portals, ProgramCodes } from "views/Constants/Constant";
import BeneficiaryAmountDetail from "./CreateWireTransfer/BeneficiaryAmountDetail.jsx";
import BeneficiaryDetail from "./CreateWireTransfer/BeneficiaryDetail.jsx";
import SaveConfirmDialog from "./CreateWireTransfer/SaveConfirmDialog.jsx";
import FileResultDialog from "./CreateWireTransfer/FileResultDialog.jsx";
import AccountLimitToolbar from "views/Components/AccountLimitToolbar.jsx";
import StringHelper from "core/StringHelper.js";
class CreateWireTransfer extends GenericPage {

	constructor(props) {
		super(props);
		this.getappCenterTitle = "Empty";
		this.getappLeftTitle = "Empty";
		this.defaultClientModel = {
			IsCorporate: false,
			LegalName: "",
			Name: "",
			MiddleName: "",
			LastName: "",
			AddressLine1: "",
			AddressLine2: "",
			ZipCode: "",
			CityName: "",
			ProvinceName: "",
			CountryName: ""
		};

		this.defaultTemplateModel = {
			Id: 0,
			IsRecordValid: true,
			IsTemplate: false,
			Alias: null,
			TemplateName: null,
			UniqueClientId: 0,
			UniqueClient: null,
			CompanyName: null,
			Name: null,
			MiddleName: null,
			LastName: null,
			AddressLine1: null,
			AddressLine2: null,
			ZipCode: null,
			CityName: null,
			ProvinceName: null,
			CityId: null,
			City: null,
			ProvinceId: null,
			Province: null,
			CountryId: null,
			Country: null,
			CurrencyId: null,
			Currency: null,
			BankName: null,
			BankInstitution: null,
			BankTransitNumber: null,
			BankBranchName: null,
			BankFinInsId: null,
			BankFinIns: null,
			BankBranchId: null,
			BankBranch: null,
			AccountNumber: null,
			BankSwiftCode: null,
			BankAddressLine1: null,
			BankAddressLine2: null,
			BankZipCode: null,
			BankCityId: null,
			BankCity: null,
			BankProvinceId: null,
			BankProvince: null,
			BankCountryId: null,
			BankCountry: null,
			BankCityName: null,
			BankProvinceName: null,
			IsDomestic: null,
			HasChange: false,
			PhoneNumber : null
		};

		this.MaxLen = { maxLength: 30 };
		this.ParameterCountrySortOrderIndex = { Member: "OrderIndex" };
		this.SortedCountry = { Member: "OrderIndex" };
		this.SortedCityProvince = { Member: "ParameterDesc" };

		this.defaultModel = {
			// status
			SendReceiptPDF: false,
			DownloadReceiptPDF: false,
			AcceptTerms: false,

			// Detail
			DateRequest: DateHelper.GetDateTime(),
			ValueEffective: DateHelper.GetDateTime(),
			TotalAmount: 0,
			Amount: 0,
			CurrencyId: null,
			PurposeOfWire: "",
			AccountId: null,
			HasSubSender: false,
			FileId: null,
			SendEmailAddress: "",
			IsSendEmailAddressValid: false,
			AmountPrefix: "$"
		};

		this.defaultStateModel = {
			IsClearDialogOpen: false,
			SaveConfirmDialog: false,
			IsSubmitTemplate: false,
			FileResultDialog: false,
			Alert: null,
			IsMsbClient: false,

		};

		this.defaultFileModel = {
			File: null,
			FileName: ""
		};

		this.AcceptTermText = "I do confirm my rights to bind the company and have the authority to perform the transaction on behalf of the company.";
		this.TemplateColumns = [
			{
				Header: "Template Name",
				accessor: "TemplateName"
			},
			{
				Header: "Company Name",
				accessor: "CompanyName"
			},
			{
				Header: "Name",
				accessor: "Name"
			},
			{
				Header: "Middle Name",
				accessor: "MiddleName"
			},
			{
				Header: "Last Name",
				accessor: "LastName"
			},
			{
				Header: "Address Line 1",
				accessor: "AddressLine1"
			},
			{
				Header: "AddressLine2",
				accessor: "AddressLine2"
			},
			{
				Header: "Zip Code",
				accessor: "ZipCode"
			},
			{
				Header: "City",
				accessor: "City.Name"
			},
			{
				Header: "Province",
				accessor: "Province.Name"
			},
			{
				Header: "Country",
				accessor: "Country.Name"
			},
			{
				Header: "Beneficiary Bank",
				Cell: row => (<span>{row.original.BankFinIns != null ? row.original.BankFinIns.Description : row.original.BankName}</span>)
			},
			{
				Header: "Transit Number",
				Cell: row => (<span>{row.original.BankBranch != null ? row.original.BankBranch.TransitNumber : row.original.BankTransitNumber}</span>)
			},
			{
				Header: "FIID",
				Cell: row => (<span>{row.original.BankFinIns != null ? row.original.BankFinIns.FinIns : ""}</span>)
			},
			{
				Header: "Wallet Number",
				accessor: "AccountNumber"
			},
			{
				Header: "Bank Swift Code",
				accessor: "BankSwiftCode"
			},
			{
				Header: "Address Line 1",
				accessor: "BankAddressLine1"
			},
			{
				Header: "Bank Address Line 2",
				accessor: "BankAddressLine2"
			},
			{
				Header: "Zip Code",
				accessor: "BankZipCode"
			},
			{
				Header: "City",
				accessor: "BankCity.Name"
			},
			{
				Header: "Province",
				accessor: "BankProvince.Name"
			},
			{
				Header: "Bank Country",
				accessor: "BankCountry.Name"
			}
		];

		this.state = {
			Model: { ...this.defaultModel  },
			StateModel: { ...this.defaultStateModel },
			FileModel: { ...this.defaultFileModel },
			ClientInfo: { ...this.defaultClientModel },
			Template: { ...this.defaultTemplateModel },
			isLoading: false,
			TemplateList: [],
			LimitToken: false,
			readOnlyData: {},
			LimitBalanceData: {
				AvailableCreditLimit: 0,
				AvailableDebitLimit: 0,
				AvailableBalance: 0
			},
			IsAsianCountry : false,
			IsRestrictedClient: false
		};
	}

	async componentDidMount() {
		super.componentDidMount();
		const { setAppLeftTitle, setAppCenterTitle, setPortal } = this.props;
		if (setAppLeftTitle) {
			setAppLeftTitle("Create Wire Transfer");this.getappLeftTitle = "Create Wire Transfer";
		}
		if (setAppCenterTitle) {
			setAppCenterTitle("WIRE TRANSFER");this.getappCenterTitle = "WIRE TRANSFER";
		}
		if (setPortal) {
			setPortal(Portals.WireTransfer);
		}

		var isClient = ClientHelper.IsClient();
		this.LoadTemplateList(() => {
			if (isClient) {
				this.LoadClientInfo(this.LoadCustomerContract);
			}
		});

		await this.GetClientInternationalRestriction();
		this.FetchCanadaPost(this.props,this.state);
	}


	FetchCanadaPost(newProps,state) {
		const asianCountries = ["Malaysia","India","Indonesia","Thailand","Myanmar","South Korea","China",
		"Philippines","Vietnam","Nepal","Cambodia","Japan","Pakistan","Bangladesh","Brunei","Singapore",
		"Laos","Sri Lanka","Bhutan","Taiwan","Mongolia","Kazakhstan","Iran","Maldives"];

		var parent = this;

		this.ExecutePostRequest("/coreapi/v1.0/Parameter/Search",
			{
				ParameterCode: "CanadaPostApiKey",
				ParameterValue: "CP"
			},
			responseData => {
				if (responseData.Item == null) {
					console.log("Canada Post Api key not fetched !");
					return;
				}
				if (responseData.Item[0] == null) {
					console.log("Canada Post Api key not fetched !");
					return;
				}

				var apikey = responseData.Item[0].ParameterDesc;
				console.log("KEY CP ", apikey);
				var FillBankAddressFields = this.FillBankAddressFields;
				var FillBeneficiaryAddressFields = this.FillBeneficiaryAddressFields;
				var RemoveAddressLineFields = this.RemoveAddressLineFields;
				 var SetAsianCountries = this.SetAsianCountries;
				// @ts-ignore
				var url = window.CanadaPostAddressCompleteUrl;
				var path = url + apikey;
				var CanadaPostRequest=this.SubmitCanadaPostRequest;

				var pca;
				const script = document.createElement("script");
				script.src = path;
				script.async = false;
				document.head.appendChild(script);
				script.onload = function () {
					// @ts-ignore
					pca = window.pca;
					if (pca != null) {
						var fields = [
							{ element: "bank-street-address", field: "Line1", mode: pca.fieldMode.SEARCH },
							{ element: "bank-street-address2", field: "Line2", mode: pca.fieldMode.POPULATE },
							{ element: "bank-postcode", field: "PostalCode", mode: pca.fieldMode.SEARCH }
						];
						var options = {
							key: apikey
						};
						var control = new pca.Address(fields, options);

						control.listen("populate", function (address) {
							if (address.CountryName != "Canada" && state.IsRestrictedClient) {
								RemoveAddressLineFields(false);
								newProps.showMessage("error", "Error", "Sorry country not supported except Canada!");
							}
							else {
								FillBankAddressFields(address);
							}

							if(asianCountries.includes(address.CountryName)){
								SetAsianCountries(true);
							}
							else{
								SetAsianCountries(false);
							}
						});

						var fields2 = [
							{ element: "beneficiary-street-address", field: "Line1", mode: pca.fieldMode.SEARCH },
							{ element: "beneficiary-street-address2", field: "Line2", mode: pca.fieldMode.POPULATE },
							{ element: "beneficiary-postcode", field: "PostalCode", mode: pca.fieldMode.SEARCH }
						];
						CanadaPostRequest();

						var control2 = new pca.Address(fields2, options);

						control2.listen("populate", function (address) {
								FillBeneficiaryAddressFields(address);
						});
					}
				};
			});
	}

	RemoveAddressLineFields = (isBeneficiaryInformation) => {
		const { Template } = this.state;

		if (isBeneficiaryInformation) {
			Template.AddressLine1 = null;
			Template.AddressLine2 = "";
		}
		else {
			Template.BankAddressLine1 = null;
			Template.BankAddressLine2 = "";
		}
		this.setState({ Template });
	}

	async GetClientInternationalRestriction() {
		this.setState({ isLoading: true });
		var result = await this.props.ExecuteApiPost("/bankapi/v1.0/WireTransfer/GetClientInternationalRestriction");
		this.setState({ IsRestrictedClient: result?.IsRestricted });
		this.setState({ isLoading: false });
	}

		SetAsianCountries(isAsian)  {
		var { IsAsianCountry } = this.state;
		const { Template } = this.state;
		if(isAsian == true)
		{
			IsAsianCountry = true;
		}
		else{
			IsAsianCountry = false;
			Template.PhoneNumber = null;
		}
		this.setState({ IsAsianCountry });
	}

	SubmitCanadaPostRequest = async () => {
		const model = { ...this.state.model };
		var requestdata = {

			UniqueClientId: null,
			MenuCode:this.props.MenuCode,
			AppCenterTitle:this.getappCenterTitle,
			AppLeftTitle:this.getappLeftTitle
		};
		Proxy.POST(
			"/bankapi/v1.0/Reporting/CanadaPostRequestReportInsert",
			requestdata,
			responseData => {
				if (responseData.Item != null ) {

				}
			},
		);
		// var result = await this.props.ExecuteApiPost("/bankapi/v1.0/Reporting/CanadaPostRequestReportInsert", requestdata);
	}
	FillBankAddressFields(model, continueWith = null) {
		this.ExecutePostRequest("/bankapi/v1.0/WireCountry/CanadaPost",
			model,
			responseData => {
				const { Template } = this.state;
				if (responseData.Item != null) {
					Template.BankCountryId = responseData.Item.CountryId;
					Template.BankProvinceId = responseData.Item.ProvinceId;
					Template.BankCityId = responseData.Item.CityId;
					Template.IsDomestic = model.CountryIso2 == "CA";
				} else {
					Template.BankCountryId = 0;
					Template.BankProvinceId = 0;
					Template.BankCityId = 0;
				}
				Template.BankAddressLine1 = model.Line1;
				Template.BankAddressLine2 = model.Line2;
				Template.BankZipCode = model.PostalCode;
				this.setState({ Template }, continueWith);
			}
		);
	}

	FillBeneficiaryAddressFields(model, continueWith = null) {
		this.ExecutePostRequest("/bankapi/v1.0/WireCountry/CanadaPost",
			model,
			responseData => {
				const { Template } = this.state;
				if (responseData.Item != null) {
					Template.CountryId = responseData.Item.CountryId;
					Template.ProvinceId = responseData.Item.ProvinceId;
					Template.CityId = responseData.Item.CityId;
				} else {
					Template.CountryId = 0;
					Template.ProvinceId = 0;
					Template.CityId = 0;
				}
				Template.AddressLine1 = model.Line1;
				Template.AddressLine2 = model.Line2;
				Template.ZipCode = model.PostalCode;
				this.setState({ Template }, continueWith);
			}
		);
	}

	LoadTemplateList(continueWith = null) {
		this.ExecutePostRequest("/bankapi/v1.0/WireBeneficiaryTemplate/Search", {},
			(responseData) => {
				this.setState({ TemplateList: responseData.Item || [] }, continueWith);
			});
	}

	LoadClientInfo(continueWith = null) {
		this.ExecutePostRequest("/bankapi/v1.0/WireTransferSender/GetSenderInfo", {},
			(responseData) => {
				this.setState({ ClientInfo: responseData.Item }, continueWith);
			});
	}

	LoadCustomerContract(continueWith = null) {
		this.ExecuteGetRequest("/bankapi/v1.0/CustomerContract/GetByCustomerId/Id?Id=",
			(responseData) => {
				const { StateModel } = this.state;
				var isSmbClient = false;
				if (responseData.Item != null) {
					responseData.Item.forEach(element => {
						if (element.Program != null && element.Program.ProgramCode == ProgramCodes.Bank_Programs_Bank_Account) {
							isSmbClient = element.IsMsbClient == true;
						}
					});
				}
				StateModel.IsMsbClient = isSmbClient;
				this.setState({ StateModel }, continueWith);
			});
	}

	ValueChanged(name, newValue, data) {
		const { FileModel } = this.state;
		const { Model } = this.state;
		Model[name] = newValue;

		if (name == "AccountId" && data != null) {
			Model.CurrencyId = data.CurrencyId;
			//Model.AmountPrefix = data.Currency.Symbol;
		}
		if (name == "SendEmailAddress" && data != null)
			Model["IsSendEmailAddressValid"] = data.IsValid;
		if (name == "SendReceiptPDF" && !newValue)
			Model["SendEmailAddress"] = "";
		if (name == "Model.HasSubSender" && !newValue) {
			FileModel.File = null;
			FileModel.FileName = "";
		}
		this.setState({ Model, FileModel });
	}
	onlyLetterAndDigit = (str) => {
		return StringHelper.VerifiyDigitAndLetterForWire(str);
	}
	VerifiyLine = (str) => {
		return StringHelper.VerifiyWireLine(str);
	}

	TemplateValueChanged(name, newValue, data) {
		const { Template } = this.state;

		var isTemplate = Template.Id != 0;
		if (Template[name] != newValue && isTemplate) {
			Template.HasChange = true;
		}

		if (name == "CurrencyId" && this.defaultTemplateModel.CurrencyId == null) {
			this.defaultTemplateModel.CurrencyId = newValue;
		}

		if (name == "Alias" && newValue != null) {
			var check = this.onlyLetterAndDigit(newValue);
			if (check == true)
				return true;
		}

		if (name == "AddressLine1" && newValue != null) {
			var checkAddressLine1 = this.VerifiyLine(newValue);
			if (checkAddressLine1 == true)
				return true;
		}
		if (name == "AddressLine2" && newValue != null) {
			var checkAddressLine2 = this.VerifiyLine(newValue);
			if (checkAddressLine2 == true)
				return true;
		}
		if (name == "BankInstitution" && newValue != null) {
			var checkBankInstitution = this.VerifiyLine(newValue);
			if (checkBankInstitution == true)
				return true;
		}
		if (name == "BankSwiftCode" && newValue != null) {
			var checkBankSwiftCode = this.VerifiyLine(newValue);
			if (checkBankSwiftCode == true)
				return true;
		}
		if (name == "BankAddressLine1" && newValue != null) {
			var checkBankAddressLine1 = this.VerifiyLine(newValue);
			if (checkBankAddressLine1 == true)
				return true;
		}
		if (name == "BankAddressLine2" && newValue != null) {
			var checkBankAddressLine2 = this.VerifiyLine(newValue);
			if (checkBankAddressLine2 == true)
				return true;
		}

		Template[name] = newValue;

		if (name == "BankCountryId" && data != null) {
			Template.IsDomestic = data.ISO2 == "CA";
			Template.BankCityId = null;
			Template.BankProvinceId = null;
		}
		if (name == "BankCountryId" && newValue == null) {
			Template.BankCityId = null;
			Template.BankProvinceId = null;
		}
		if (name == "CountryId") {
			Template.CityId = null;
			Template.ProvinceId = null;
		}
		// if (name == "AccountId") {
		// 	Template.CurrencyId = data.CurrencyId;
		// 	AmountPrefix = data.Currency.Symbol;
		// }
		if (name == "SendEmailAddress" && data != null)
			Template["IsSendEmailAddressValid"] = data.IsValid;
		if (name == "SendReceiptPDF" && !newValue)
			Template["SendEmailAddress"] = "";
		this.setState({ Template });
		//Template
	}

	HandleSubmit() {
		const { StateModel, FileModel, Template, Model } = this.state;
		// if (Template.HasChange) {
		// 	this.ShowGenericMessage("warning", "Update Template", "Please update the selected Template with changes", true, this.HideAlert);
		// 	return;
		// }

		if (!this.Validate(false))
			return;


		if (StateModel.IsMsbClient == true && FileModel.File != null && Model.FileId == null) {
			this.SubmitFileClick();
		} else {
			StateModel.SaveConfirmDialog = true;
			this.setState({ StateModel, Template });
		}
	}

	HandleConfirm() {
		const { Model, Template } = this.state;
		if ((Model.SendReceiptPDF && Model.SendReceiptPDF == true && Model.SendEmailAddress == "") ||
			(Model.SendReceiptPDF && Model.SendReceiptPDF == true && !Model.IsSendEmailAddressValid)) {
			this.ShowGenericMessage("warning", "Error", "Email address is not a correct.");
			return;
		}
		this.CloseSaveConfirmDialog();
		this.setState({ isLoading: true });
		const request = {
			SendReceiptPDF: Model.SendReceiptPDF,
			DownloadReceiptPDF: Model.DownloadReceiptPDF,
			DateRequest: Model.DateRequest,
			ValueEffective: Model.ValueEffective,
			Amount: Model.Amount,
			PurposeOfWire: Model.PurposeOfWire,
			AccountId: Model.AccountId,
			FileId: Model.FileId,
			BeneficiaryTemplate: Template,
			UniqueClientId: ClientHelper.GetClientRowId(),
			IsDomestic: Template.IsDomestic
		};

		Proxy.POST("/bankapi/v1.0/WireTransfer/ValidateCreateWireTransfer", request,
			validateResponseData => {
				if (!validateResponseData.IsSucceeded) {
					this.setState({ isLoading: false });
					this.props.showMessage("error", "Error", validateResponseData.ErrorDescription);
					return;
				}
				Proxy.POST("/bankapi/v1.0/WireTransfer/CreateOutgoingWireTransfer", request,
					createResponseData => {
						this.setState({ isLoading: false });
						if (!createResponseData.IsSucceeded) {
							this.props.showMessage("error", "Error", createResponseData.ErrorDescription);
							return;
						}
						if (createResponseData.ErrorCode != "0") {
							this.ShowGenericMessage("error", "Rejected", createResponseData.ErrorDescription);
							return;
						}
						if (Model.DownloadReceiptPDF != null && Model.DownloadReceiptPDF == true) {
							this.DownloadPdf(createResponseData.Item.Id != null ? createResponseData.Item.Id : 0);
							this.ClearScreen();
							this.RefreshToolBar();
						} else {
							if (!request.FileId)
								this.ShowGenericMessage("success", "Create", "Created successfully.");
							else
								this.ShowGenericMessage("success", "Create", "The file is being process.");

							this.ClearScreen();
							this.RefreshToolBar();
						}
					});
			});
	}

	HandleCancel() {
		const { StateModel } = this.state;
		StateModel.SaveConfirmDialog = false;
		this.setState({ StateModel });
	}

	HandleFileConfirm() {
		const { StateModel } = this.state;
		StateModel.SaveConfirmDialog = true;
		StateModel.FileResultDialog = false;
		this.setState({ StateModel });
	}

	HandleFileCancel() {
		const { StateModel } = this.state;
		StateModel.FileResultDialog = false;
		this.setState({ StateModel });
	}

	DownloadPdf(transactionSwiftId) {
		const { ExecuteApiFileDownloadWithGenericResponse } = this.props;
		ExecuteApiFileDownloadWithGenericResponse("/bankapi/v1.0/WireTransfer/WireTransferReceipDownloadFile",
			{ "Id": transactionSwiftId },
			"WireReceipt" + "-" + Math.floor(Math.random() * Math.floor(999999)),
			"pdf",
			() => {
				this.ShowGenericMessage("success", "Create & Download", "Created and downloaded receipt successfully !!!");
			});
	}

	SelectedRowChange(index) {
		const { TemplateList } = this.state;
		var tempModel = { ...TemplateList[index] };
		tempModel.IsDomestic = tempModel.BankCountry != null && tempModel.BankCountry.ISO2 == "CA";
		if (tempModel.IsDomestic) {
			tempModel.BankName = null;
			tempModel.BankBranchName = null;
		} else {
			tempModel.BankFinIns = null;
			tempModel.BankBranchId = null;
		}
		tempModel.HasChange = tempModel.CurrencyId != this.defaultTemplateModel.CurrencyId;
		tempModel.CurrencyId = this.defaultTemplateModel.CurrencyId;
		tempModel.AccountNumberInputId = null;

		this.setState({ Template: { ...tempModel } });
	}

	CloseSaveConfirmDialog() {
		const { StateModel } = this.state;
		StateModel.SaveConfirmDialog = false;
		this.setState({ isLoading: false, StateModel });
	}
	GetRenderItemAccount = (x) => {
		this.renderItemAccount = `${x.AccountNumber} - ${x.AccountName}`;
		return this.renderItemAccount;
	}

	SubmitFileClick() {
		const { FileModel } = this.state;
		var formData = new FormData();
		formData.append("file", FileModel.File, FileModel.FileName);
		this.ExecuteUploadRequest("/bankapi/v1.0/WireTransferSubSender/UploadSubSenderFile", formData,
			responseData => {
				var fileUploadResponse = responseData.Item ? responseData.Item.FileUploadResponse : null;
				var wireSubSenderFileRecords = responseData.Item ? responseData.Item.WireSubSenderFileRecords : [];

				const { Model, StateModel } = this.state;
				Model.TotalAmount = responseData.Item ? responseData.Item.TotalAmount : "";
				StateModel.FileResultDialog = true;
				if (fileUploadResponse != null) {
					if (fileUploadResponse.RejectedItemCount > 0) {
						Model.FileId = null;
					} else {
						Model.FileId = fileUploadResponse.FileId;
					}
				}
				this.setState({ Model, StateModel, FileResultList: wireSubSenderFileRecords });
			});
	}

	SubmitTemplate() {
		const { StateModel } = this.state;
		StateModel.IsSubmitTemplate = true;
		this.setState({ StateModel });
	}

	HandleTemplateConfirm() {
		if (!this.Validate(true))
			return;

		const { StateModel, Template } = this.state;
		Template.IsTemplate = true;
		this.ExecutePostRequest("/bankapi/v1.0/WireBeneficiaryTemplate/InsertOrUpdate",
			Template,
			responseData => {
				if (responseData.Item != null) {
					Template.Id = responseData.Item.Id;
					Template.HasChange = false;
				}
				StateModel.IsSubmitTemplate = false;
				this.ShowGenericMessage("success", "Template", "Template Submitted.", false, this.LoadTemplateList(this.HideAlert));
				this.setState({ StateModel, Template });
			});
	}

	HandleTemplateCancel() {
		const { StateModel } = this.state;
		StateModel.IsSubmitTemplate = false;
		this.setState({ StateModel });
	}

	ClearClick() {
		const { StateModel } = this.state;
		StateModel.IsClearDialogOpen = true;
		this.setState({ StateModel });
	}

	FileSelect(e) {
		const { FileModel } = this.state;

		e.preventDefault();
		let reader = new FileReader();
		var file = e.target.files[0];
		if (!file) return;

		reader.onloadend = () => {
			FileModel.File = file;
			FileModel.FileName = file.name;
			this.setState(FileModel);
		};
		reader.readAsDataURL(file);
	}

	HandleClearConfirm() {
		this.ClearScreen();
	}

	HandleClearCancel() {
		const { StateModel } = this.state;
		StateModel.IsClearDialogOpen = false;
		this.setState({ StateModel });
	}

	HandleConfirmDelete() {
		const { Template } = this.state;
		Proxy.POST("/bankapi/v1.0/WireBeneficiaryTemplate/Delete",
			{ Id: Template.Id },
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowGenericMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ClearScreen();
				this.ShowGenericMessage("success", "Success", "Template deleted successfully !!!");
				this.LoadTemplateList(() => { });
			},
			error => {
				this.ShowGenericMessage("error", "Error", error);
			});
	}

	ClearScreen() {
		const { StateModel } = this.state;
		StateModel.IsClearDialogOpen = false;
		this.setState({
			Model: { ...this.defaultModel },
			StateModel,
			FileModel: { ...this.defaultFileModel },
			Template: { ...this.defaultTemplateModel }
		});
	}

	Validate(isTemplateCreate) {
		const { Template, Model } = this.state;

		var messages = [];

		if (!isTemplateCreate) {
			if (Model.AcceptTerms == null || Model.AcceptTerms == false) {
				this.ShowGenericMessage("warning", "Warning", "Please accept the confirmation message and then try again.");
				return false;
			}
			if (Model.DateRequest == null) messages.push("Date Request can not be null.");
			if (Model.ValueEffective == null) messages.push("Value Effective can not be null.");
			if (Model.Amount == null || Model.Amount <= 0) messages.push("Amount can not be null.");
			// if (Model.DateRequest == null || Model.DateRequest == "") messages.push("Date Request can not be null.");
			// if (Model.ValueEffective == null || Model.ValueEffective == "") messages.push("Value Effective can not be null.");
			// if (Model.Amount == null || Model.Amount == "" || Model.Amount < 1) messages.push("Amount can not be null.");
			if (Model.PurposeOfWire == null || Model.PurposeOfWire == "") messages.push("Purpose of Wire can not be null.");
		}

		if (Model.AccountId == null || Model.AccountId < 1) messages.push("From Wallet cannot be empty!");

		const isIndividual = Template.CompanyName == null || Template.CompanyName == "";
		if (isIndividual) {
			if (Template.Name == null || Template.Name == "") messages.push("Name can not be null.");
			if (Template.LastName == null || Template.LastName == "") messages.push("LastName can not be null.");
		}
		if (Template.AddressLine1 == null || Template.AddressLine1 == "") messages.push("Address Line 1 can not be null.");
		// if (Template.AddressLine2 == null || Template.AddressLine2 == "") messages.push("Address Line 2 can not be null.");
		if (Template.ZipCode == null || Template.ZipCode == "") messages.push("ZipCode can not be null.");
		if (Template.CityId == null) messages.push("City can not be null.");
		if (Template.CountryId == null || Template.CountryId == "") messages.push("Country can not be null.");

		if (Template.BankCountryId == null || Template.BankCountryId < 1) messages.push("Bank Country can not be null.");
		var accountIsEmpty = Template.AccountNumber == null || Template.AccountNumber == "";
		var ibanIsEmpty = Template.BankInstitution == null || Template.BankInstitution == "";
		if (accountIsEmpty && ibanIsEmpty) {
			messages.push("Wallet Number or IBAN can not be null. Please fill Wallet Number or IBAN field.");
		}

		if (Template.BankZipCode == null || Template.BankZipCode == "") messages.push("Bank ZipCode can not be null.");
		if (Template.IsDomestic && !accountIsEmpty) {
			if (Template.BankFinInsId == null || Template.BankFinInsId < 1) messages.push("FIID can not be null.");
			if (Template.BankBranchId == null || Template.BankBranchId < 1) messages.push("Branch can not be null.");

		} else if (!accountIsEmpty) {
			if (Template.BankName == null || Template.BankName == "") messages.push("Bank Name can not be null.");
			//if (Template.BankTransitNumber == null || Template.BankTransitNumber == "") messages.push("Bank Transit Number can not be null.");
		}

		if (Template.BankSwiftCode == null || Template.BankSwiftCode == "") messages.push("Swift Code can not be null.");

		if (Template.Alias) {
			var check = this.onlyLetterAndDigit(Template.Alias);
			if (check == true)
				messages.push("Remittance Information contains invalid character!");
		}

		// if (Template.AddressLine1) {
		// 	var check3 = this.onlyLetterAndDigit(Template.AddressLine1);
		// 	if (check3 == true)
		// 		messages.push("AddressLine1 contains invalid character!");
		// }

		if (Template.BankInstitution) {
			var check2 = this.onlyLetterAndDigit(Template.BankInstitution);
			if (check2 == true)
				messages.push("BankInstitution contains invalid character!");
		}
		// if (Template.IsDomestic) {
		// 	if (Template.BankTransitNumber == null || Template.BankTransitNumber == "") messages.push("Bank Transit Number can not be null.");

		// }
		if (Template.BankCityId == null || Template.BankCityId == 0) messages.push("Bank City can not be null.");
		if (Template.BankAddressLine1 == null || Template.BankAddressLine1 == "") messages.push("Bank Address Line 1 can not be null.");
		// if (Template.BankAddressLine2 == null || Template.BankAddressLine2 == "") messages.push("Bank Address Line 2 can not be null.");

		if (messages.length > 0) {
			this.ShowGenericMessage("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		if(this.state.IsAsianCountry == true){
			messages.push("Phone Number cannot be empty!");
		}

		return true;
	}

	HandleDelete() {
		this.ShowGenericMessage("info", "Info", "Are you sure delete this template ?", true, this.HandleConfirmDelete);
	}
	RefreshToolBar() {
		this.setState({ LimitToken: !this.state.LimitToken });
	}

	render() {
		const { Disabled } = this.props;
		const { isLoading, Alert, IsLoading } = this.state;
		const { Model, StateModel, FileModel, ClientInfo, Template, TemplateList, FileResultList, LimitToken } = this.state;
		return (
			<div>
				{Alert}
				<AccountLimitToolbar key={LimitToken} Program={ProgramCodes.Bank_Programs_Bank_Account} LimitMainType={"WireTransfer"} IsUseDisableDebitLimit={true} OnLoad={data => { this.setState({ LimitBalanceData: data, isLoading: false }); }} />

				<LoadingComponent Show={isLoading || IsLoading } />
				<ButtonToolbar
					ButtonList={[
						{ Code: "Clear", OnClick: this.ClearClick, Disabled: Disabled },
						{ Code: "Submit", OnClick: this.HandleSubmit, Disabled: Disabled },
						{ Code: "Delete", OnClick: this.HandleDelete, Disabled: Template.Id == 0 ? true : false },
						{ Code: "SubmitTemplate", OnClick: this.SubmitTemplate, Disabled: Disabled }
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After}></ButtonToolbar>
				<br />
				<Card>
					<GenericExpansionPanel Title={"Wire Transfer Transaction Entry"}>
						<CardBody>
							<GridContainer spacing={16}>
								<GridItem xs={4}>
									<GridContainer style={{ borderRadius: 5 }}>
										<GridItem xs={12}>
											<h4 style={{ color: "black" }}>
												<b>Sender Information</b>
											</h4>
										</GridItem>
										<GridItem xs={12}>
											<GenericSelectInput
												Name="AccountId"
												LabelText="From Wallet"
												Method="POST"
												Url="/bankapi/v1.0/Account/GetAllAccountByUniqueClient"
												DataRoot="Item"
												KeyValueMember="AccountId"
												Parameter={{}}
												TextValueMember="AccountNumber"
												RenderItem={this.GetRenderItemAccount}
												Sorted={{ Member: "AccountNumber" }}
												Value={Model.AccountId}
												ValueChanged={this.ValueChanged}
											/>
										</GridItem>
										{ClientInfo.IsCorporate && <GridItem xs={12}>
											<GenericTextInput
												LabelText="Legal Name Of Company"
												Value={ClientInfo.LegalName}
												Disabled={true} />
										</GridItem>}
										{!ClientInfo.IsCorporate && <GridItem xs={12}>
											<GenericTextInput
												LabelText="Name"
												Value={ClientInfo.Name}
												Disabled={true} />
										</GridItem>}
										{!ClientInfo.IsCorporate && <GridItem xs={12}>
											<GenericTextInput
												LabelText="Middle Name"
												Value={ClientInfo.MiddleName}
												Disabled={true} />
										</GridItem>}
										{!ClientInfo.IsCorporate && <GridItem xs={12}>
											<GenericTextInput
												LabelText="Last Name"
												Value={ClientInfo.LastName}
												Disabled={true} />
										</GridItem>}
										<GridItem xs={12}>
											<GenericTextInput
												LabelText="Address Line 1"
												Value={ClientInfo.AddressLine1}
												Disabled={true} />
										</GridItem>
										<GridItem xs={12}>
											<GenericTextInput
												LabelText="Address Line 2"
												Value={ClientInfo.AddressLine2}
												Disabled={true} />
										</GridItem>
										<GridItem xs={12}>
											<GenericTextInput
												LabelText="Postal Code"
												Value={ClientInfo.ZipCode}
												Disabled={true} />
										</GridItem>
										<GridItem xs={12}>
											<GenericTextInput
												LabelText="City"
												Value={ClientInfo.CityName}
												Disabled={true} /></GridItem>
										<GridItem xs={12}>
											<GenericTextInput
												LabelText="Province"
												Value={ClientInfo.ProvinceName}
												Disabled={true} />
										</GridItem>
										<GridItem xs={12}>
											<GenericTextInput
												LabelText="Country"
												Value={ClientInfo.CountryName}
												Disabled={true} />
										</GridItem>
									</GridContainer>
								</GridItem>
								<GridItem xs={8}>
									<BeneficiaryDetail
										ValueChanged={this.TemplateValueChanged}
										CompanyName={Template.CompanyName}
										Name={Template.Name}
										MiddleName={Template.MiddleName}
										LastName={Template.LastName}
										AddressLine1={Template.AddressLine1}
										AddressLine2={Template.AddressLine2}
										ZipCode={Template.ZipCode}
										CityId={Template.CityId}
										ProvinceId={Template.ProvinceId}
										CountryId={Template.CountryId}
										IsDomestic={Template.IsDomestic}
										BankCountryId={Template.BankCountryId}
										BankFinInsId={Template.BankFinInsId}
										BankName={Template.BankName}
										BankBranchId={Template.BankBranchId}
										BankTransitNumber={Template.BankTransitNumber}
										AccountNumber={Template.AccountNumber}
										BankSwiftCode={Template.BankSwiftCode}
										BankAddressLine1={Template.BankAddressLine1}
										BankAddressLine2={Template.BankAddressLine2}
										BankZipCode={Template.BankZipCode}
										BankCityId={Template.BankCityId}
										BankProvinceId={Template.BankProvinceId}
										BankInstitution={Template.BankInstitution}
										Alias={Template.Alias}
										Disabled={false}
										MenuCode = {false}
										IsAsianCountry = {this.state.IsAsianCountry}
										PhoneNumber = {Template.PhoneNumber} ></BeneficiaryDetail>
								</GridItem>
								{StateModel.IsMsbClient ? <GridItem xs={3}>
									<Card>
										<CardBody>
											<h4 style={{ color: "black", textAlign: "center" }}>
												<b>MSB Wire Backup</b>
											</h4>
											<GridContainer>
												<GridItem xs={12}>
													<GenericCheckInput
														Name="HasSubSender"
														LabelText="Upload Wire Backup Data"
														IsTextLeft
														Value={Model.HasSubSender}
														ValueChanged={this.ValueChanged} />
												</GridItem>
												<GridItem xs={12}>
													<GenericTextInput
														Name="FileName"
														Value={FileModel.FileName}
														LabelMd={0}
														Disabled={true} />
												</GridItem>
												<GridItem xs={6} />
												<GridItem xs={6}>
													<FileUpload
														size="sm"
														buttonProps={{ color: Model.HasSubSender ? "primary" : "warning" }}
														onFileChange={this.FileSelect}
														Disabled={!Model.HasSubSender}
														accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
													></FileUpload>
												</GridItem>
											</GridContainer>
										</CardBody>
									</Card>
								</GridItem> : null}
								<GridItem xs={6}>
									<Card>
										<CardBody>
											<BeneficiaryAmountDetail
												ValueChanged={this.ValueChanged}
												TemplateValueChanged={this.TemplateValueChanged}
												PurposeOfWire={Model.PurposeOfWire}
												CurrencyId={Template.CurrencyId}
												Amount={Model.Amount}
												DateRequest={Model.DateRequest}
												ValueEffective={Model.ValueEffective}
												Disabled={false}
												AmountPrefix={Model.AmountPrefix}></BeneficiaryAmountDetail>
										</CardBody>
									</Card>
								</GridItem>
								<GridItem xs={3} style={{ textAlign: "center", alignSelf: "center" }}>
									<br /><br /><br /><br />
									<GenericCheckInput
										Name="AcceptTerms"
										LabelText={this.AcceptTermText}
										IsTextLeft
										Value={Model.AcceptTerms}
										ValueChanged={this.ValueChanged} />
								</GridItem>
							</GridContainer>
						</CardBody>
					</GenericExpansionPanel>
				</Card>
				<Card>
					<CardBody>
						<GridContainer spacing={16}>
							<GridItem xs={12}>
								<GenericGrid
									Data={TemplateList}
									Columns={this.TemplateColumns}
									RowSelected={this.SelectedRowChange}
									SelectedIndex={this.state.rowIndex}
									ProgramCode={ProgramCodes.Bank_Programs_Bank_Account}
								></GenericGrid>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
				{StateModel.SaveConfirmDialog && <SaveConfirmDialog
					Model={Model}
					Template={Template}
					Disabled={true}
					ValueChanged={this.ValueChanged}
					HandleConfirm={this.HandleConfirm}
					HandleCancel={this.HandleCancel}>
				</SaveConfirmDialog>}
				{StateModel.FileResultDialog && <FileResultDialog
					Model={Model}
					CurrencyId={Template.CurrencyId}
					ShowResult={Model.FileId == null}
					Disabled={Disabled}
					HandleConfirm={this.HandleFileConfirm}
					HandleCancel={this.HandleFileCancel}
					FileResultList={FileResultList}>
				</FileResultDialog>}
				<GenericDialog open={StateModel.IsClearDialogOpen} maxWidth="sm" fullWidth>
					<DialogContent>
						<GenericLabel Text="This will erase the information you enter on the screen. Are you sure you want to quit this transaction ?" Bold={true} />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.HandleClearConfirm}>YES</Button>
						<Button size="sm" onClick={this.HandleClearCancel}>NO</Button>
					</GenericDialogActions>
				</GenericDialog>
				<GenericDialog open={StateModel.IsSubmitTemplate} maxWidth="sm" fullWidth>
					<DialogTitle>
						<h6>	<b>{Template.Id == 0 ? "Create " : "Update "} Template</b>	</h6>
					</DialogTitle>
					<DialogContent>
						<GenericTextInput
							Name="TemplateName"
							LabelText="TemplateName"
							Value={Template.TemplateName}
							ValueChanged={this.TemplateValueChanged} />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.HandleTemplateConfirm}>YES</Button>
						<Button size="sm" onClick={this.HandleTemplateCancel}>NO</Button>
					</GenericDialogActions>
				</GenericDialog>
			</div>
		);
	}
}

CreateWireTransfer.propTypes = {
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool,
	ValueChanged: PropTypes.func,
	Model: PropTypes.object,
	ExecuteApiFileDownloadWithGenericResponse: PropTypes.func
};

export default withArtifex(CreateWireTransfer, {});