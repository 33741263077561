import { DialogContent, DialogTitle, Slide, Tooltip, Zoom } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import createEFTRecurringStyle from "assets/jss/material-dashboard-pro-react/views/createEFTRecurringStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericCheckInput, GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericIcon, GenericLabel, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import ParameterComponent from "views/Components/ParameterComponent";
import { EFTEntryType, PeriodCodes, ProgramCodes } from "views/Constants/Constant.js";
import AddNewCustomerEFT from "views/EFT/AddNewCustomerEFT.jsx";

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

class CreateEFTRecurring extends Component {

	constructor(props) {
		super(props);

		this.state = { customerToken: "0" };

		this.sortName = { Member: "Name" };
		this.sortAccountName = { Member: "AccountName" };
		this.sortParamDesc = { Member: "ParameterDesc" };
		this.sortParamValue2 = { Member: "ParameterValue2" };
		this.sortParamValue = { Member: "ParameterValue" };
		this.ClientParamObj = { ProgramCode: ProgramCodes.Bank_Programs_EFT, UniqueClientId: null };

		this.isClient = ClientHelper.IsClient();

		this.ValueChanged = this.ValueChanged.bind(this);
		this.GetTooltip = this.GetTooltip.bind(this);
		this.addCustomerDialogClose = this.addCustomerDialogClose.bind(this);
		this.addCustomerSaved = this.addCustomerSaved.bind(this);
		this.GetClientParamObj = this.GetClientParamObj.bind(this);
	}

	ValueChanged(name, newValue, data) {
		this.props.onModelChange(model => {
			model[name] = newValue;

			if (name == "PeriodId") model.PeriodCode = data ? data.ParameterValue : "";
			if (name == "WeeklyId") model.WeeklyNumber = data ? data.ParameterValue : "";
			if (name == "BiWeeklyId") model.BiWeeklyNumber = data ? data.ParameterValue : "";
			if (name == "MonthlyId") model.MonthlyNumber = data ? data.ParameterValue : "";
			if (name == "QuarterlyId") model.QuarterlyNumber = data ? data.ParameterValue : "";

			if (name == "PayeeId") {
				model.PayeeName = data ? data.Name : "";
				model.BeneficiaryId = "";
			}

			if (name == "NewClient" && newValue) {
				model.IsCustomerPopupOpen = true;
				this.setState({ isCustomerAdded: false });
			}

			const { StartDate, EndDate, FreezeStartDate, FreezeEndDate } = model;

			if (name == "StartDate" || name == "EndDate" || name == "PeriodId" || name == "WeeklyId" ||
				name == "BiWeeklyId" || name == "MonthlyId" || name == "QuarterlyId" || name == "FreezeStartDate" || name == "FreezeEndDate") {
				var paymentCount = this.GetPaymentCount(StartDate, EndDate, model);
				var freezeCount = this.GetPaymentCount(FreezeStartDate, FreezeEndDate, model);
				var netCount = paymentCount - freezeCount;
				if (netCount == 0) netCount = null;
				model.PaymentCount = netCount;
			}

			if (name == "TransferMemoId") {
				model.TransferMemo = data ? data.Memo : "";
			}
			if (name == "IsHaveMemo") {
				if (newValue) model.TransferMemo = "";
				model.TransferMemoId = "";
			}
			if (name == "TransactionType")
				model.TransactionTypeCode = data ? data.ParameterValue : "";

			return model;
		});
	}

	/**
	 * @param {moment.Moment} startDate
	 * @param {moment.Moment} endDate
	 * @param {{ WeeklyNumber?: any; BiWeeklyNumber?: any; MonthlyNumber?: any; QuarterlyNumber?: any; PeriodId?: any; PeriodCode?: string; }} model
	 */
	GetPaymentCount(startDate, endDate, model) {
		const { WeeklyNumber, BiWeeklyNumber, MonthlyNumber, QuarterlyNumber } = model;

		if (moment.isMoment(startDate) && startDate.isValid() && moment.isMoment(endDate) && endDate.isValid() && model.PeriodId) {

			const StartDate = startDate.clone().add(startDate.toDate().getTimezoneOffset(), "minutes").toDate();
			const EndDate = endDate.clone().add(endDate.toDate().getTimezoneOffset(), "minutes").toDate();

			var count = 0;
			/**
			 * @param {number} d1
			 * @param {number} d
			 */
			var dayDif = (d1, d) => {
				return d1 > d ? d - d1 + 7 : d - d1;
			};
			/**
			 * @param {Date} date
			 * @param {number} days
			 */
			var addDays = (date, days) => {
				var nd = new Date(date); nd.setDate(date.getDate() + days);
				return nd;
			};
			/**
			 * @param {Date} d1
			 * @param {Date} d2
			 */
			var monthDif = (d1, d2) => {
				var d1Start = new Date(d1.getFullYear(), d1.getMonth(), 1);
				var d2Start = new Date(d2.getFullYear(), d2.getMonth() + 1, 1);
				return Math.round(moment.duration(d2Start.getTime() - d1Start.getTime()).asMonths());
			};
			/**
			 * @param {Date} d
			 */
			var lastDayOfMonth = d => {
				return new Date(d.getFullYear(), d.getMonth() + 1, 0);
			};

			switch (model.PeriodCode) {
				case PeriodCodes.Weekly:
					if (WeeklyNumber != null && WeeklyNumber != "") {
						var firstWeekPayDate = addDays(StartDate, dayDif(StartDate.getDay(), WeeklyNumber));
						if (EndDate.getTime() - firstWeekPayDate.getTime() >= 0)
							count = Math.floor(moment.duration(EndDate.getTime() - firstWeekPayDate.getTime()).asDays() / 7 + 1);
					}
					break;
				case PeriodCodes.BiWeekly:
					if (BiWeeklyNumber != null && BiWeeklyNumber != "") {
						var firstBiWeekPayDate = addDays(StartDate, dayDif(StartDate.getDay(), BiWeeklyNumber));
						if (EndDate.getTime() - firstBiWeekPayDate.getTime() >= 0)
							count = Math.floor(moment.duration(EndDate.getTime() - firstBiWeekPayDate.getTime()).asDays() / 14 + 1);
					}
					break;
				case PeriodCodes.SemiMonthly:
					var sMonthDiff = monthDif(StartDate, EndDate);
					if (sMonthDiff == 1) {
						if (StartDate.getDate() > 16) break;
						if (StartDate.getDate() > 1 && EndDate.getDate() < 16) break;
						if (StartDate.getDate() > 1) { count = 1; break; }
						if (StartDate.getDate() == 1 && EndDate.getDate() < 16) { count = 1; break; }
						if (StartDate.getDate() == 1) { count = 2; break; }
					}
					if (sMonthDiff > 1) {
						var smStartDateSkip = (StartDate.getDate() == 1) ? 0 : (StartDate.getDate() <= 16) ? 1 : 2;
						var smEndDateSkip = EndDate.getDate() >= 16 ? 0 : 1;
						count = (2 * sMonthDiff) - smStartDateSkip - smEndDateSkip;
					}
					break;
				case PeriodCodes.Monthly:
					if (MonthlyNumber != null && MonthlyNumber != "") {
						var mMonthDiff = monthDif(StartDate, EndDate);
						var mMonthlyNumber = Math.min(lastDayOfMonth(EndDate).getDate(), MonthlyNumber);
						if (mMonthDiff == 1) {
							if (StartDate.getDate() > MonthlyNumber || EndDate.getDate() < mMonthlyNumber) break;
							if (StartDate.getDate() <= MonthlyNumber && EndDate.getDate() >= mMonthlyNumber) { count = 1; break; }
						}
						if (mMonthDiff > 1) {
							var mStartDateSkip = StartDate.getDate() > MonthlyNumber ? 1 : 0;
							var mEndDateSkip = EndDate.getDate() < mMonthlyNumber ? 1 : 0;
							count = mMonthDiff - mStartDateSkip - mEndDateSkip;
						}
					}
					break;
				case PeriodCodes.MonthlyMonthEnd:
					var meMonthDiff = monthDif(StartDate, EndDate);
					if (meMonthDiff == 1) {
						var meLastMonthDay = lastDayOfMonth(EndDate).getDate();
						if (EndDate.getDate() == meLastMonthDay) { count = 1; break; }
					}
					if (meMonthDiff > 1) {
						var meEndDateSkip = EndDate.getDate() < lastDayOfMonth(EndDate).getDate() ? 1 : 0;
						count = meMonthDiff - meEndDateSkip;
					}
					break;
				case PeriodCodes.Quarterly:
					if (QuarterlyNumber != null && QuarterlyNumber != "") {
						var qbStartDate = addDays(StartDate, -QuarterlyNumber);
						var qStartNewMonth = qbStartDate.getMonth() - (qbStartDate.getMonth() % 3) + 3;
						var qStartDate = new Date(qbStartDate.getFullYear(), qStartNewMonth, QuarterlyNumber);
						var qaEndDate = addDays(EndDate, -QuarterlyNumber + 1);
						var qEndNewMonth = qaEndDate.getMonth() - (qaEndDate.getMonth() % 3);
						var qEndDate = new Date(qaEndDate.getFullYear(), qEndNewMonth, QuarterlyNumber);
						var qDiff = monthDif(qStartDate, qEndDate);
						count = (qDiff + 2) / 3;
					}
					break;
				case PeriodCodes.QuarterlyMonthEnd:
					var qeGetStartMonth = n => n - (n % 3) + 3;
					var qeStartDate = new Date(StartDate.getFullYear(), qeGetStartMonth(StartDate.getMonth()) + 1, 0);
					var qeGetEndMonth = n => n - (n % 3);
					var qeaEndDate = addDays(EndDate, 1);
					var qeEndDate = new Date(qeaEndDate.getFullYear(), qeGetEndMonth(qeaEndDate.getMonth()) + 1, 0);
					var qeDiff = monthDif(qeStartDate, qeEndDate);
					count = (qeDiff + 2) / 3;
					break;
				default:
					break;
			}

			return count;
		}

		return 0;
	}

	GetTooltip() {
		const { model } = this.props;

		switch (model.PeriodCode) {
			case PeriodCodes.Weekly:
				return "The user will select this option for weekly payment processes. The day of the week that the payment occurs is dependent on the date selected for the First Due Date (i.e. if the First Due Date selected was on a Wednesday, then the weekly payment will occur every Wednesday).";
			case PeriodCodes.BiWeekly:
				return "The user will select this option for payment processing plans based on every other week. The day of the week that the payment will occurs is dependent on the date selected for the First Due Date (i.e. if the First Due Date selected was on a Wednesday, then the bi-weekly payment will occur every other Wednesday).";
			case PeriodCodes.Monthly:
				return "The user will select this option for the monthly payments. The payment date for this	option is dependent on the date selected for the First Due Date (i.e. if the First Due Date selected was on the 12th, then the monthly payment will occur on the 12th of every month).";
			case PeriodCodes.Quarterly:
				return "The user will select this option for quarterly processing of a payment. The payment date for this option is dependent on the date selected for the First Due Date (i.e. if the First Due Date selected was on the 12th, then the quarterly payment will occur on the 12th day of the first month of a quarter). ";
			default:
				return "";
		}
	}

	addCustomerDialogClose() {
		this.ValueChanged("IsCustomerPopupOpen", false);
	}

	addCustomerSaved(customerData) {
		if (customerData != null) {
			this.props.onModelChange(model => {
				model.PayeeId = customerData.Id;
				model.PayeeName = customerData.CustomerName;
				model.BeneficiaryId = "";

				return model;
			});

			var newCustomerToken = this.state.customerToken == "1" ? "0" : "1";
			this.setState({ customerToken: newCustomerToken });
		}

		this.addCustomerDialogClose();
	}

	GetClientParamObj() {
		const { model } = this.props;
		this.ClientParamObj.UniqueClientId = model.UniqueClientId;
		return this.ClientParamObj;
	}

	RenderBeneficiarySelect(d) {
		return `${d.FinancialInstitution.Description} - ${d.AccountNumber}`;
	}	
	RenderAccountSelect(d) {
		const accountNumber = d.Account && d.Account.AccountNumber;
		const accountName = d.Account && d.Account.AccountName;
		const storeCode = d.CustomerStore && d.CustomerStore.StoreCode;
		const storeName = d.CustomerStore && d.CustomerStore.StoreName;

		return `${storeName}(${storeCode}) - ${accountName}(${accountNumber})`;
	}

	RenderTransactionCode(d) {
		const transactionCode = d.ParameterValue;
		const transactionCodeDescription = d.ParameterDesc;

		return `${transactionCode} - ${transactionCodeDescription}`;
	}

	render() {
		const { classes, model, vModel, Disabled: DisabledProp, parentModel, CustomerStoreList } = this.props;

		const Selected = parentModel.EntryCode == EFTEntryType.Recurring;
		const Disabled = DisabledProp || !Selected;
		const ShowNewCustomer = this.isClient && !parentModel.IsUpdate;

		return (
			<div style={{ opacity: Selected ? 1.0 : 0.4 }}>
				<Card>
					<CardBody>
						<GenericExpansionPanel IsActive={Selected} Title="Recurring Transaction Entry">
							<GridContainer spacing={16} style={{ marginLeft: 2 }}>
								<GridItem xs={8}>
									<GridContainer spacing={16} alignItems="center">
										<GridItem xs={6}>
											{CustomerStoreList.length > 0 &&
												<GenericSelectInput
													LabelMd={4}
													key={"CustomerStoreId" + this.state.customerToken + "_" + parentModel.UniqueClientId}
													Name="CustomerStoreId"
													LabelText="Store Wallet"
													IsStatic={true}
													StaticData={CustomerStoreList}
													DataRoot="Item"
													KeyValueMember="CustomerStoreId"
													RenderItem={this.RenderAccountSelect}
													Value={model.CustomerStoreId}
													ValueChanged={this.ValueChanged}
													Disabled={Disabled || parentModel.IsUpdate}
													Required
													IsInvalid={vModel.CustomerStoreId} />}
										</GridItem>
										<GridItem xs={6}></GridItem>
										<GridItem xs={6}>
											<GenericSelectInput
												LabelMd={4}
												key={"RecurringPayeeId_" + this.state.customerToken + "_" + parentModel.UniqueClientId}
												Name="PayeeId"
												LabelText="Customer Name"
												Method="POST"
												Url="/bankapi/v1.0/ClientCustomer/GetByProgramCodeAndClientId"
												DataRoot="Item"
												Parameter={this.GetClientParamObj()}
												KeyValueMember="Id"
												TextValueMember="Name"
												Value={model.PayeeId}
												ValueChanged={this.ValueChanged}
												Disabled={Disabled} Sorted={this.sortName}
												Required
												IsInvalid={vModel.PayeeId} />
										</GridItem>
										{ShowNewCustomer ?
											<GridItem xs={6}>
												<GenericCheckInput Name="NewClient" LabelText="New Customer" Value={model.NewClient} ValueChanged={this.ValueChanged} Disabled={Disabled} />
											</GridItem> : null}
										<GridItem xs={6}>
											<GenericSelectInput
												LabelMd={4}
												key={"BeneficiaryId_" + model.PayeeId}
												Name="BeneficiaryId"
												LabelText="Customer Wallet"
												Method="GET"
												Url={"/bankapi/v1.0/Beneficiary/GetByCustomerId/Id?Id=" + model.PayeeId}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={this.RenderBeneficiarySelect}
												Value={model.BeneficiaryId}
												ValueChanged={this.ValueChanged}
												Disabled={Disabled} Sorted={this.sortAccountName}
												Required
												IsInvalid={vModel.BeneficiaryId} />
										</GridItem>
										{ShowNewCustomer ? <GridItem xs={6} /> : null}
										<GridItem xs={6}>
											<GenericSelectInput
												Name="TransactionType"
												LabelText="Transaction Type"
												Method="GET"
												Url="/bankapi/v1.0/Transaction/GetAllFinancialTransactionType"
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ParameterDesc"
												Value={model.TransactionType}
												ValueChanged={this.ValueChanged}
												Disabled={Disabled}
												Required
												IsInvalid={vModel.TransactionType} />
										</GridItem>
										<GridItem xs={6}>
											<ParameterComponent
												Name="TransactionCode"
												LabelText="Transaction Code"
												ParameterCode="EFTTransactionType"
												RenderItem={this.RenderTransactionCode}
												Value={model.TransactionCode}
												ValueChanged={this.ValueChanged}
												Disabled={Disabled}
												Sorted={this.sortParamValue}
												Required
												IsInvalid={vModel.TransactionCode} />
										</GridItem>
										<GridItem xs={6}>
											<GenericDateInput
												LabelMd={4}
												Name="StartDate"
												LabelText="Start Date"
												Value={model.StartDate}
												ValueChanged={this.ValueChanged}
												Disabled={Disabled || parentModel.IsUpdate}
												IsFuture
												Utc
												Required
												IsInvalid={vModel.StartDate} />
										</GridItem>
										<GridItem xs={6}>
											<GenericDateInput
												LabelMd={4}
												Name="EndDate"
												LabelText="End Date"
												Value={model.EndDate}
												ValueChanged={this.ValueChanged}
												Disabled={Disabled}
												MinDate={model.StartDate || DateHelper.GetLocalDateAsUtc()}
												Utc
												Required
												IsInvalid={vModel.EndDate} />
										</GridItem>
										<GridItem xs={6}>
											<GenericTextInput
												LabelMd={4} LabelText="Period/No. Of Payment" Value={model.PaymentCount} Disabled={true}
												Required
												IsInvalid={vModel.PaymentCount} />
										</GridItem>
										<GridItem xs={6}>
										</GridItem>
										<GridItem xs={6}>
											<GenericNumberInput
												LabelMd={4} Name="RecurringAmount" LabelText="Recurring Amount"
												Value={model.RecurringAmount} ValueChanged={this.ValueChanged}
												MaxLength={10} Disabled={Disabled} Prefix="$"
												Required
												IsInvalid={vModel.RecurringAmount} />
										</GridItem>
										<GridItem xs={6}>
										</GridItem>
										<GridItem xs={6}>
											<GenericNumberInput
												LabelMd={4} LabelText="Total Amount"
												Value={model.RecurringAmount && model.PaymentCount && model.RecurringAmount * model.PaymentCount}
												Disabled={true} Prefix="$" />
										</GridItem>
										<GridItem xs={6}>
										</GridItem>
										<GridItem xs={8}>
											{model.IsHaveMemo ?
												<GenericSelectInput
													key={"RecurringMemo_" + parentModel.UniqueClientId}
													LabelMd={3}
													Name="TransferMemoId"
													LabelText="Transfer Memo"
													Method="POST"
													Url="/bankapi/v1.0/BankBmoEFT/GetMemosByClientId"
													Parameter={parentModel.UniqueClientId}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="Memo"
													Value={model.TransferMemoId}
													ValueChanged={this.ValueChanged}
													Disabled={Disabled} />
												:
												<GenericTextInput LabelMd={3} Name="TransferMemo" LabelText="Transfer Memo" Value={model.TransferMemo} ValueChanged={this.ValueChanged} Disabled={Disabled} />
											}
										</GridItem>
										<GridItem xs={2}>
											<GenericCheckInput Name="IsHaveMemo" LabelText="Get Memo From List" Value={model.IsHaveMemo} ValueChanged={this.ValueChanged} Disabled={Disabled} />
										</GridItem>
										<GridItem xs={2}>
										</GridItem>
										<GridItem xs={12} style={{ display: model.Id != null ? "inherit" : "none" }}>
											<GridContainer>
												<GridItem xs={6}>
													<GenericDateInput
														LabelMd={4}
														Name="FreezeStartDate"
														LabelText="Freeze Start Date"
														Value={model.FreezeStartDate}
														ValueChanged={this.ValueChanged}
														Disabled={Disabled}
														IsFuture
														Utc
														Required={parentModel.IsUpdate}
														IsInvalid={vModel.FreezeStartDate} />
												</GridItem>
												<GridItem xs={6}>
													<GenericDateInput
														LabelMd={4}
														Name="FreezeEndDate"
														LabelText="Freeze End Date"
														Value={model.FreezeEndDate}
														ValueChanged={this.ValueChanged}
														Disabled={Disabled}
														MinDate={model.FreezeStartDate || DateHelper.GetLocalDateAsUtc()}
														Utc
														Required={parentModel.IsUpdate}
														IsInvalid={vModel.FreezeEndDate} />
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</GridItem>
								<GridItem xs={4}>
									<ParameterComponent Name="PeriodId" Value={model.PeriodId} LabelText="First Due Date Options" ParameterCode="EFTRecurringPeriod" ValueChanged={this.ValueChanged} Disabled={Disabled}
										Required
										IsInvalid={vModel.PeriodId} />
									{(model.PeriodId && [PeriodCodes.Weekly, PeriodCodes.BiWeekly, PeriodCodes.Monthly, PeriodCodes.Quarterly].some(x => x == model.PeriodCode)) ?
										<div>
											<br />
											<GenericLabel FontSize="12px" TextColor="black" Text="Period Definition" Bold={true} />
											<GridContainer alignItems="flex-end">
												<GridItem xs={11}>
													{(model.PeriodCode == PeriodCodes.Weekly) ? <ParameterComponent Name="WeeklyId" Value={model.WeeklyId} LabelText="Day of Week" ParameterCode="EFTWeekDays" ValueChanged={this.ValueChanged} Disabled={Disabled} Required IsInvalid={vModel.WeeklyId} /> : null}
													{(model.PeriodCode == PeriodCodes.BiWeekly) ? <ParameterComponent Name="BiWeeklyId" Value={model.BiWeeklyId} LabelText="Day of Week" ParameterCode="EFTWeekDays" ValueChanged={this.ValueChanged} Disabled={Disabled} Required IsInvalid={vModel.BiWeeklyId} /> : null}
													{(model.PeriodCode == PeriodCodes.Monthly) ? <ParameterComponent Name="MonthlyId" Value={model.MonthlyId} LabelText="Day of Month" ParameterCode="EFTMonthDays" ValueChanged={this.ValueChanged} Disabled={Disabled} Sorted={this.sortParamValue2} Required IsInvalid={vModel.MonthlyId} /> : null}
													{(model.PeriodCode == PeriodCodes.Quarterly) ? <ParameterComponent Name="QuarterlyId" Value={model.QuarterlyId} LabelText="First Due Date of Quarter" ParameterCode="EFTMonthDays" ValueChanged={this.ValueChanged} Disabled={Disabled} Sorted={this.sortParamValue2} Required IsInvalid={vModel.QuarterlyId} /> : null}
												</GridItem>
												<GridItem xs={1}>
													<Tooltip title={this.GetTooltip()}
														classes={{ tooltip: classes.tooltip }}
														TransitionComponent={Zoom}>
														<GenericIcon>help</GenericIcon>
													</Tooltip>
												</GridItem>
											</GridContainer>
										</div> : null}
								</GridItem>
							</GridContainer>
						</GenericExpansionPanel>
					</CardBody>
				</Card>

				<GenericDialog open={model.IsCustomerPopupOpen} fullScreen onBackdropClick={this.addCustomerDialogClose} TransitionComponent={Transition}>
					<DialogTitle>
						<GenericLabel FontSize="16px" Text="Add New Customer" Bold={true} />
					</DialogTitle>
					<DialogContent>
						<AddNewCustomerEFT ForInsert CustomerSaved={this.addCustomerSaved} menuId={this.props.addNewCustomerMenuId} />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.addCustomerDialogClose}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>

			</div>
		);
	}
}

CreateEFTRecurring.propTypes = {
	classes: PropTypes.object.isRequired,
	model: PropTypes.object,
	vModel: PropTypes.object,
	parentModel: PropTypes.object,
	onModelChange: PropTypes.func,
	Disabled: PropTypes.bool,
	addNewCustomerMenuId: PropTypes.number,
	CustomerStoreList: PropTypes.array
};

export default withStyles(createEFTRecurringStyle)(CreateEFTRecurring);