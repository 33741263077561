import { withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import eftEtransferTitleBarStyle from "assets/jss/material-dashboard-pro-react/views/eftEtransferTitleBarStyle.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React, { Component } from "react";

class DDTTitleBar extends Component {
	render() {
		const { classes, TotalNumberDDT, TotalNumberCDIC, DDTAccountBalance, CDICAccountBalance } = this.props;

		return (
			<div className={classes.appbar}>
				<GridContainer>
					<GridItem xs={12} sm={3} md={3} lg={3} >
						<GridContainer>
							<GridItem xs={8} sm={8} md={8} lg={8} className={classes.appbaritemleft}>
								Total No.Of DDT
							</GridItem>
							<GridItem xs={4} sm={4} md={4} lg={4} >
								<Typography variant="h6" color="inherit" className={classes.appbaritemright} >
									{this.props.TotalNumberDDT}
								</Typography>
							</GridItem>
						</GridContainer>
					</GridItem>
					<GridItem xs={12} sm={3} md={3} lg={3}  >
						<GridContainer>
							<GridItem xs={8} sm={8} md={8} lg={8} className={classes.appbaritemleft}>
								Total No. of DDT - CDIC
							</GridItem>
							<GridItem xs={4} sm={4} md={4} lg={4}>
								<Typography variant="h6" color="inherit" className={classes.appbaritemright} >
									{this.props.TotalNumberCDIC}
								</Typography>
							</GridItem>
						</GridContainer>
					</GridItem>
					<GridItem xs={12} sm={3} md={3} lg={3}  >
						<GridContainer>
							<GridItem xs={8} sm={8} md={8} lg={8} className={classes.appbaritemleft}>
								DDT Wallet Balance
							</GridItem>
							<GridItem xs={4} sm={4} md={4} lg={4}>
								<Typography variant="h6" color="inherit" className={classes.appbaritemright} >
									${this.props.DDTAccountBalance}
								</Typography>
							</GridItem>
						</GridContainer>
					</GridItem>
					<GridItem xs={12} sm={3} md={3} lg={3}  >
						<GridContainer>
							<GridItem xs={8} sm={8} md={8} lg={8} className={classes.appbaritemleft}>
							DDT - CDIC Wallet Balance
							</GridItem>
							<GridItem xs={4} sm={4} md={4} lg={4}>
								<Typography variant="h6" color="inherit" className={classes.appbaritemright} >
									${this.props.CDICAccountBalance}
								</Typography>
							</GridItem>
						</GridContainer>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

DDTTitleBar.propTypes = {
	classes: PropTypes.object.isRequired,
	AvailableCreditLimit: PropTypes.number,
	AvailableDebitLimit: PropTypes.number,
	TotalNumberDDT: PropTypes.any
};

export default withStyles(eftEtransferTitleBarStyle)(DDTTitleBar);