import ClientHelper from "core/ClientHelper";
import ResourceHelper from "core/ResourceHelper";
import StringHelper from "core/StringHelper";
import moment from "moment";
import DateHelper from "core/DateHelper";

var UtcOffset = moment().utcOffset().toString();
//var UtcOffset = DateHelper.GetDateTimeEst().utcOffset().toString();

const ProxyTimeOut = window.ProxyTimeOut ?? 200000;
const IsProxyErrorDetailActive = window.IsProxyErrorDetailActive;
const ProxyUrl = window.ProxyUrl;
const ProxyUrl2 = window.ProxyUrl2;

function timeout(ms, url, promise) {
	return new Promise(function (resolve, reject) {
		setTimeout(function () {
			console.log(`Request has timed out ->  ${url}`);
			reject(new Error("We're sorry. We run into a problem completing your request. Please try that again in a few minutes."));
		}, ms);
		promise.then(resolve, reject);
	});
}

const Proxy = {
	POST: (URL, Params, callback = null, errorCallback = null, workflowId = null, accessToken = null, customTimeout = null) => {
		var result = timeout(customTimeout ?? ProxyTimeOut, URL, fetch(GetEnvironmentUrl(URL), {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Authorization": "Bearer " + getSessionId(accessToken),
				"Path": window.location.pathname,
				"WorkflowId": workflowId,
				"APIPATH": URL,
				"UtcOffset": UtcOffset
			},
			body: JSON.stringify(Params)
		}).then(response => {
			if (!response.ok) {
				if (response.status == 401 || response.status == 403) {
					localStorage.removeItem("user");
					window.location.reload();
					throw Error("Your session has expired. Please login again.");
				} else {
					console.log(`Error: ${response.statusText}  StatusCode: ${response.status} Type: ${response.type}   Url: ${response.url}`);
					throw Error("We're sorry. We run into a problem completing your request. Please try that again in a few minutes.");
				}
			}
			return response.json();
		})).then(responseData => {
			if (!responseData.IsSucceeded) {
				if (IsProxyErrorDetailActive == "1") {
					if (responseData.StackTrace != null) {
						window.console.error(responseData.StackTrace);
					}
				} else {
					window.console.error("An API Error Occured. Check logs.");
				}
			}

			if (!responseData.IsSucceeded && responseData.ErrorCode) {
				if (responseData.ErrorList && responseData.ErrorList.length > 0) {
					responseData.ErrorDescription = responseData.ErrorList.map(x =>
						(x.ParameterList != null && x.ParameterList.length > 0) ?
							StringHelper.Format(ResourceHelper.Get(x.ErrorCode), x.ParameterList) :
							ResourceHelper.Get(x.ErrorCode)
					).join(" - ");
				}
				else {
					if (responseData.ParameterList != null && responseData.ParameterList.length > 0)
						responseData.ErrorDescription = StringHelper.Format(ResourceHelper.Get(responseData.ErrorCode), responseData.ParameterList);
					else
						responseData.ErrorDescription = ResourceHelper.Get(responseData.ErrorDescription);
				}
			}

			if (callback != null) {
				callback(responseData);

				return Promise.resolve(responseData);
			}
		}).catch(error => {
			if (IsProxyErrorDetailActive == "1") {
				window.console.error(error);
			}
			if (errorCallback != null) {
				if (error.message == "Failed to fetch") {
					errorCallback("We're sorry. Please try that again later.");

					return Promise.resolve("We're sorry. Please try that again later.");
				} else {
					errorCallback(ToErrorString(error));

					return Promise.resolve(ToErrorString(error));
				}
			}
		});

		return result;
	},
	GET: (URL, callback = null, errorCallback = null, workflowId = null, accessToken = null) => {
		var result = timeout(ProxyTimeOut, URL, fetch(GetEnvironmentUrl(URL), {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"Authorization": "Bearer " + getSessionId(accessToken),
				"Path": window.location.pathname,
				"WorkflowId": workflowId,
				"APIPATH": URL,
				"UtcOffset": UtcOffset
			}
		}).then(response => {

			if (!response.ok) {
				if (response.status == 401 || response.status == 403) {
					localStorage.removeItem("user");
					window.location.reload();
					throw Error("Your session has expired. Please login again.");
				} else {
					console.log(`Error: ${response.statusText}  StatusCode: ${response.status} Type: ${response.type}   Url: ${response.url}`);
					throw Error("We're sorry. We run into a problem completing your request. Please try that again in a few minutes.");
				}
			}
			return response.json();
		})).then(responseData => {
			if (!responseData.IsSucceeded) {
				if (IsProxyErrorDetailActive == "1") {
					if (responseData.StackTrace != null) {
						window.console.error(responseData.StackTrace);
					}
				} else {
					window.console.error("An API Error Occured. Check logs.");
				}
			}

			if (!responseData.IsSucceeded && responseData.ErrorCode) {
				if (responseData.ErrorList && responseData.ErrorList.length > 0) {
					responseData.ErrorDescription = responseData.ErrorList.map(x =>
						(x.ParameterList != null && x.ParameterList.length > 0) ?
							StringHelper.Format(ResourceHelper.Get(x.ErrorCode), x.ParameterList) :
							ResourceHelper.Get(x.ErrorCode)
					).join(" - ");
				}
				else {
					if (responseData.ParameterList != null && responseData.ParameterList.length > 0)
						responseData.ErrorDescription = StringHelper.Format(ResourceHelper.Get(responseData.ErrorCode), responseData.ParameterList);
					else
						responseData.ErrorDescription = ResourceHelper.Get(responseData.ErrorDescription);
				}
			}

			if (callback != null) {
				callback(responseData);

				return Promise.resolve(responseData);
			}
		}).catch(error => {
			if (IsProxyErrorDetailActive == "1") {
				window.console.error(error);
			}
			if (errorCallback != null && error.message != "Session expired.") {
				if (error.message == "Failed to fetch") {
					errorCallback("We're sorry. Please try that again later.");

					return Promise.resolve("We're sorry. Please try that again later.");
				} else {
					errorCallback(ToErrorString(error));

					return Promise.resolve(ToErrorString(error));
				}
			}
		});

		return result;
	},
	FileUpload: (URL, Data, callback = null, errorCallback = null) => {
		var result = fetch(GetEnvironmentUrl(URL), {
			method: "POST",
			headers: {
				"Authorization": "Bearer " + getSessionId(),
				"UtcOffset": UtcOffset
			},
			body: Data
		}).then(response => {
			if (!response.ok) {
				console.log(`Error: ${response.statusText}  StatusCode: ${response.status} Type: ${response.type}   Url: ${response.url}`);
				throw Error("We're sorry. We run into a problem completing your request. Please try that again in a few minutes.");
			}
			return response.json();
		}).then(responseData => {
			if (!responseData.IsSucceeded && responseData.StackTrace)
				window.console.error(IsProxyErrorDetailActive == "1" ? responseData.StackTrace : "An API Error Occured. Check logs.");

			if (!responseData.IsSucceeded && responseData.ErrorCode) {
				if (responseData.ErrorList && responseData.ErrorList.length > 0) {
					responseData.ErrorDescription = responseData.ErrorList.map(x =>
						(x.ParameterList != null && x.ParameterList.length > 0) ?
							StringHelper.Format(ResourceHelper.Get(x.ErrorCode), x.ParameterList) :
							ResourceHelper.Get(x.ErrorCode)
					).join(" - ");
				}
				else {
					if (responseData.ParameterList != null && responseData.ParameterList.length > 0)
						responseData.ErrorDescription = StringHelper.Format(ResourceHelper.Get(responseData.ErrorCode), responseData.ParameterList);
					else
						responseData.ErrorDescription = ResourceHelper.Get(responseData.ErrorCode);
				}
			}

			if (callback != null) {
				callback(responseData);

				return Promise.resolve(responseData);
			}
		}).catch(error => {
			if (IsProxyErrorDetailActive == "1") {
				window.console.error(error);
			}
			if (errorCallback != null && error.message != "Session expired.") {
				if (error.message == "Failed to fetch") {
					errorCallback("We're sorry. Please try that again later.");

					return Promise.resolve("We're sorry. Please try that again later.");
				} else {
					errorCallback(ToErrorString(error));

					return Promise.resolve(ToErrorString(error));
				}
			}
		});

		return result;
	},
	FileDownload: (FileUrl, FileName, FileExtension, Params, Callback = null, ErrorCallback = null) => {
		return fetch(GetEnvironmentUrl(FileUrl), {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Authorization": "Bearer " + getSessionId(),
				"UtcOffset": UtcOffset
			},
			body: JSON.stringify(Params)
		}).then(response => response.blob()).then(blob => {
			var aElement = document.createElement("a");
			document.body.appendChild(aElement);
			var url = URL.createObjectURL(blob);
			aElement.href = url;
			aElement.download = FileName + "." + FileExtension;
			aElement.click();
			URL.revokeObjectURL(url);

			if (Callback != null) {
				Callback();
			}
		}).catch(error => {
			if (ErrorCallback != null) {
				if (error.message == "Failed to fetch") {
					ErrorCallback("We're sorry. Please try that again later.");
				} else {
					ErrorCallback(ToErrorString(error));
				}
			}
		});
	},
	FileDownloadWithGenericResponse: (FileUrl, FileName, FileExtension, Params, Callback = null, ErrorCallback = null) => {
		var result = timeout(ProxyTimeOut, URL, fetch(GetEnvironmentUrl(FileUrl), {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Authorization": "Bearer " + getSessionId(),
				"UtcOffset": UtcOffset
			},
			body: JSON.stringify(Params)
		}).then(response => {
			if (!response.ok) {
				console.log(response);

				if (response.status == 401 || response.status == 403) {
					localStorage.removeItem("user");
					window.location.reload();
					throw Error("Your session has expired. Please login again.");
				} else {
					console.log(`Error: ${response.statusText}  StatusCode: ${response.status} Type: ${response.type}   Url: ${response.url}`);
					throw Error("We're sorry. We run into a problem completing your request. Please try that again in a few minutes.");
				}
			}

			return response.json();
		})).then(responseData => {
			if (!responseData.IsSucceeded) {
				if (IsProxyErrorDetailActive == "1") {
					if (responseData.StackTrace != null) {
						window.console.error(responseData.StackTrace);
					}
				} else {
					window.console.error("An API Error Occured. Check logs.");
				}

				if (responseData.ErrorCode) {
					if (responseData.ErrorList && responseData.ErrorList.length > 0) {
						responseData.ErrorDescription = responseData.ErrorList.map(x =>
							(x.ParameterList != null && x.ParameterList.length > 0) ?
								StringHelper.Format(ResourceHelper.Get(x.ErrorCode), x.ParameterList) :
								ResourceHelper.Get(x.ErrorCode)
						).join(" - ");
					}
					else {
						if (responseData.ParameterList != null && responseData.ParameterList.length > 0)
							responseData.ErrorDescription = StringHelper.Format(ResourceHelper.Get(responseData.ErrorCode), responseData.ParameterList);
						else
							responseData.ErrorDescription = ResourceHelper.Get(responseData.ErrorCode);
					}
				}

				if (Callback != null) {
					Callback(responseData);

					return Promise.resolve(responseData);
				}
			} else {
				var binary = atob(responseData.Item);
				var array = [];

				for (var i = 0; i < binary.length; i++) {
					array.push(binary.charCodeAt(i));
				}

				var file = new Blob([new Uint8Array(array)]);
				var aElement = document.createElement("a");
				document.body.appendChild(aElement);
				var url = URL.createObjectURL(file);
				aElement.href = url;
				aElement.download = FileName + "." + FileExtension;
				aElement.click();
				URL.revokeObjectURL(url);

				if (Callback != null) {
					Callback(responseData);

					return Promise.resolve(responseData);
				}
			}
		}).catch(error => {
			if (IsProxyErrorDetailActive == "1") {
				window.console.error(error);
			}
			if (ErrorCallback != null) {
				if (error.message == "Failed to fetch") {
					ErrorCallback("We're sorry. Please try that again later.");

					return Promise.resolve("We're sorry. Please try that again later.");
				} else {
					ErrorCallback(ToErrorString(error));

					return Promise.resolve(ToErrorString(error));
				}
			}
		});

		return result;
	},
	ExecuteGeneral,
	DownloadGeneratedFile,
	GetEnvironmentUrl
};

function GetEnvironmentUrl(URL) {
	var BaseUrl = ProxyUrl;

	if (window.location.href != null && window.location.href.includes("epuat.dcbank.ca")) {
		BaseUrl = ProxyUrl2;
	}

	return BaseUrl + URL;
}

function getSessionId(accessToken = null) {
	if (accessToken != null)
		return accessToken;

	var user = ClientHelper.GetUser();

	if (!user || !user.SessionId)
		return null;
	return user.SessionId;
}

function ToErrorString(obj) {
	if (obj == null) return "";
	if (typeof obj == "string")
		return IsProxyErrorDetailActive == "1" ? String(obj) : "An API Error Occured.";

	if (typeof obj == "object") {
		if (obj.message != null)
			return IsProxyErrorDetailActive == "1" ? String(obj.message) : "An API Error Occured.";
		if (obj instanceof TypeError)
			return "Unknown Type Error Occured";
		else
			return "Unknown API Error Occured";
	}

	return String(obj);
}

function ExecuteGeneral(page, method, url, request, action) {
	if (method == "POST") {
		Proxy.POST(
			url, request,
			(responseData) => {
				if (!responseData.IsSucceeded) {
					page.setState({ isLoading: false }, () => {
						if (page.ShowMessage) {
							page.ShowMessage("error", responseData.ErrorDescription);
						}
					});
					return;
				}
				page.setState({ isLoading: false }, () => { action(responseData); });
			},
			(error) => {
				page.setState({ isLoading: false }, () => {
					if (page.ShowMessage) {
						page.ShowMessage("error", "An error occurred while requesting data", error.message);
					}
				});
			}
		);
	} else if (method == "GET") {
		Proxy.GET(
			url,
			(responseData) => {
				if (!responseData.IsSucceeded) {
					page.setState({ isLoading: false }, () => {
						if (page.ShowMessage) {
							page.ShowMessage("error", responseData.ErrorDescription);
						}
					});
					return;
				}
				page.setState({ isLoading: false }, () => { action(responseData); });
			},
			(error) => {
				page.setState({ isLoading: false }, () => {
					if (page.ShowMessage) {
						page.ShowMessage("error", "An error occurred while requesting data", error.message);
					}
				});
			}
		);
	}
}

function DownloadGeneratedFile(URL, Params, callback = null, errorCallback = null, alertFunc) {
	var newSuccessCallback = responseData => {
		callback(responseData);
		if (responseData.IsSucceeded ) {
			if (responseData.Item.IsPooled)
				alertFunc("success", "Success", `Report request has been added to the queue. When the rendering process is done, the file will be downloadable from the "Download Report" menu.`);
			else
				window.location.href = GetEnvironmentUrl("/coreapi/v1.0/File/DownloadGeneratedFile") + "?Guid=" + responseData.Item.FileGuid;
		}
	};
	return Proxy.POST(URL, Params, newSuccessCallback, errorCallback, null, null, window.DownloadFileTimeout);
}

export default Proxy;