import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericExpansionPanel, GenericGrid } from "views/Components/Generic";
import { CrudType, FileCode, FileType } from "views/Constants/Constant";

class LegalManage extends React.Component {
	constructor(props) {
		super(props);

		this.fileInputRef = React.createRef();

		this.columns = [
			{
				Header: "File Name",
				accessor: "FileName"
			}
		];

		this.state = {
			model: {}
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("SUPPORT");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("Legal Manage");
		}

		this.Search();
	}

	RowSelected = (index) => {
		const { list } = this.state;

		this.setState({ model: list[index], selected: index });
	}

	Search = async () => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/coreapi/v1.0/File/Search", { FileCode: FileCode.Legal });

		this.setState({ list: result || [] });
	}

	Submit = () => {
		if (!this.Validate()) {
			return;
		}

		const { model } = this.state;
		const { ExecuteApiFileUpload } = this.props;
		var formData = new FormData();
		formData.append("file", model.File, model.FileName);
		formData.append("fileId", model.TempFileId || 0);
		formData.append("fileCode", FileCode.Legal);
		formData.append("isBinaryOrBase64", FileType.Binary);
		formData.append("isInsertOrUpdate", CrudType.Insert);

		ExecuteApiFileUpload("/coreapi/v1.0/File/Uploadfile", formData, [this.Search], true);
	}

	Clear = () => {
		this.setState({ model: {}, list: [], selected: null });
	}

	Upload = () => {
		this.fileInputRef.current.click();
	}

	FileSelect = (e) => {
		e.preventDefault();

		var file = e.target.files[0];
		if (!file) {
			return;
		}

		var { model } = this.state;

		model.File = file;
		model.FileName = file.name;

		this.setState({ model });
		this.Submit();
	}

	Validate() {
		const { model } = this.state;
		const { showValidationErrors } = this.props;
		var errors = [];

		if (model.FileName == null
			&& model.File == null) {
			errors.push("Legal file can not be null.");
		}

		if (errors.length > 0) {
			showValidationErrors(errors);

			return false;
		}

		return true;
	}

	render() {
		const { model, list, selected } = this.state;
		const { Disabled, ExecuteApiPost, ExecuteApiFileDownloadWithGenericResponse, showConfirmMessageInDelete } = this.props;

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Delete",
							Disabled: Disabled || selected == null,
							OnClick: () => showConfirmMessageInDelete(() => ExecuteApiPost("/coreapi/v1.0/File/Delete", { Id: model.FileId }, null, null, null, [this.Clear, this.Search], true))
						},
						{
							Code: "Upload",
							Disabled: Disabled,
							OnClick: this.Upload
						},
						{
							Code: "View",
							Disabled: Disabled || selected == null,
							OnClick: () => ExecuteApiFileDownloadWithGenericResponse("/coreapi/v1.0/File/DownloadFileFromBinary", { FileId: model.FileId }, model.FileName, model.FileExtension)
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						},
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<input
					type="file"
					key={model.FileName}
					style={{ display: "none" }}
					onChange={this.FileSelect}
					ref={this.fileInputRef}
					accept="application/pdf" />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Legal Manage">
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer spacing={16}>
												<GridItem xs={12}>
													<GenericGrid
														Data={list}
														Columns={this.columns}
														SelectedIndex={selected}
														RowSelected={this.RowSelected} />
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

LegalManage.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	ExecuteApiFileUpload: PropTypes.func,
	ExecuteApiFileDownloadWithGenericResponse: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default LegalManage;