
const kycPhotoUploadStyle = theme => ({

	profileHeader: {
		margin: "0px 0px 5px 40px"
	},
	mediaProfile: {
		// width: "70%",
		width: "200px",
		// margin: "auto",
		height: 200,
		borderRadius: 20,
		borderColor: "rgb(179, 179, 179)",
		borderWidth: 2,
	},
	mediaCard: {
		width: "400px",
		margin: "auto",
		height: 200,
		borderRadius: 25,
		borderColor: "rgb(179, 179, 179)",
		// backgroundSize: "100%",
		borderWidth: 2,
		[theme.breakpoints.down("sm")]: {
			width: "350px",
		},
		[theme.breakpoints.down("xs")]: {
			width: "300px",
		}
	},
	innerContainer: {
		display: "flex",
		[theme.breakpoints.down("sm")]: {
			alignItems: "center",
			flexDirection: "column"
		}
	},
	buttonContainer: {
		margin: "0px 10px",
		[theme.breakpoints.down("sm")]: {
			alignItems: "center",
		}
	},
	stepper: {
		background: "#f2f2f2"
	},
	button: {
		[theme.breakpoints.down("sm")]: {
			width:"100%"
		}
	},
	mediaCardPlaceholder: {
		backgroundSize: "contain !important",
	},
	previewTitle: {
		marginLeft: 5
	}
});

export default kycPhotoUploadStyle;