import ClientHelper from "core/ClientHelper";
import StringHelper from "core/StringHelper";
import React from "react";
import { GridColumnType } from "views/Constants/Constant";

export function GetColumns(props, state) {
	return [
		{
			Header: "Client Id",
			accessor: "UniqueClient.UniqueClientId",
			show: ClientHelper.IsBackOffice(),
			width: 120
		},
		{
			Header: "Client Name",
			accessor: "UniqueClient.ClientName",
			show: ClientHelper.IsBackOffice()
		},
		{
			Header: "Client Customer No.",
			accessor: "ClientCustomerNumber"
		},
		{
			Header: "First Name",
			accessor: "Name",
			Cell: row => (
				row.value ? StringHelper.Capitalize(row.value, true) : ""
			)
		},
		{
			Header: "Middle Name",
			accessor: "MiddleName",
			Cell: row => (
				row.value ? StringHelper.Capitalize(row.value, true) : ""
			)
		},
		{
			Header: "Last Name",
			accessor: "SurName",
			Cell: row => (
				row.value ? StringHelper.Capitalize(row.value, true) : ""
			)
		},
		{
			Header: "E-Mail",
			accessor: "Email",
			Cell: row => (
				row.value ? String(row.value).toLowerCase() : ""
			),
			width: 150
		},
		{
			Header: "Interac E-Mail",
			accessor: "InteracEmail",
			Cell: row => (
				row.value ? String(row.value).toLowerCase() : ""
			),
			width: 150
		},
		{
			Header: "Phone Number",
			accessor: "PhoneNumber",
			Cell: row => {
				return row.original.PhoneCountryCode && row.original.PhoneCountryCode.Code && StringHelper.IsValidatePhone(row.original.PhoneNumber) &&
					<div>{`+${row.original.PhoneCountryCode.Code} ${StringHelper.GetFormatPhoneText(row.original.PhoneNumber)}`}</div>;
			}
		},
		{
			Header: "Corporate Name",
			accessor: "CompanyLegalName",
			Cell: row => (
				row.value ? StringHelper.Capitalize(row.value, true) : ""
			)
		},
		{
			Header: `${window.Title} Customer No.`,
			accessor: "CustomerNumber"
		},
		{
			Header: "DDR Wallet Number",
			accessor: "DDRAccountNumber"
		},
		{
			Header: "Is Registered",
			accessor: "IsRegistered",
			Cell: row => (
				row.value+"" == "null" ? "" : row.value+""
			)
		},
		{
			Header: "Direct Deposit Reference Number",
			accessor: "DirectDepositReferenceNumber",
			Cell: row => (
				row.value+"" == "null" ? "" : row.value+""
			)
		},
		{
			Header: "Is Direct Deposit Registration Creation.",
			accessor: "IsDirectDepositRegistrationCreation",
			Cell: row => (
				row.value+"" == "null" ? "" : row.value+""
			)
		},
		{
			Header: "Masked Card No.",
			accessor: "MaskedCardNumber",
			Cell: row => (
				row.value+""
			)
		},
		{
			Header: "Watchlist Scanning Status",
			accessor: "WatchlistScanningStatus",
		},
		{
			Header: "Last Watchlist Review Date",
			accessor: "LastWatchlistReviewDate",
			type: GridColumnType.Date
		},
	];
}

export function GetCDICAccountColumns(props, state) {
	return [
		{
			Header: "Unique Client Id",
			accessor: "UniqueClientId",
			show: ClientHelper.IsBackOffice()
		},
		{
			Header: "Customer Name",
			accessor: "CustomerName",
			show: ClientHelper.IsBackOffice()
		},
		{
			Header: "Transit Number",
			accessor: "TransitNumber"
		},
		{
			Header: "Wallet Number",
			accessor: "AccountNumber",
			Cell: row => (
				<div>{row.value}</div>
			)
		},
		{
			Header: "Issued Date",
			accessor: "IssuedDate",
			type: GridColumnType.Date
		},
		{
			Header: "Expire Date",
			accessor: "IdentityExpireDate",
			type: GridColumnType.Date
		},
		{
			Header: "Balance",
			accessor: "Balance",
			type: GridColumnType.Money,
			ColumnType: GridColumnType.Money
		}
	];
}