import { DialogContent, DialogTitle, Slide } from "@material-ui/core";
import style from "assets/jss/material-dashboard-pro-react/views/workflowManagementStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { DynamicComponent, Proxy, withArtifex } from "core";
import AlertHelper from "core/AlertHelper";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import { ApproveIcon, RowDetailIcon } from "core/Icons";
import ResourceHelper from "core/ResourceHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericSelectInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent.jsx";
import { ApprovalStatus, GridColumnType, MenuCodes, UserStatus } from "views/Constants/Constant";

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

class WorkflowManagement extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			IsUpdate: false,
			CreationStartDate: DateHelper.GetDateLocal(),
			CreationEndDate: DateHelper.GetDateLocal()
		};

		this.state = {
			model: { ...this.initialModel },
			InboxData: [],
			OutboxData: [],
			isLoading: false,
			isApprovalDialogOpen: false,
			isApproveUsersDialogOpen: false,
			ApprovalData: {}
		};

		this.sortedName = {
			Member: "Name"
		};

		this.isBackOffice = ClientHelper.IsBackOffice();
		this.clientId = ClientHelper.GetClientRowId();

		this.ValueChanged = this.ValueChanged.bind(this);
		this.GetClick = this.GetClick.bind(this);
		this.ClearClick = this.ClearClick.bind(this);
		this.ClearScreen = this.ClearScreen.bind(this);

		this.ShowMessage = this.ShowMessage.bind(this);
		this.hideAlert = this.hideAlert.bind(this);

		this.approvalDialogClose = this.approvalDialogClose.bind(this);
		this.GetMenuNameLabel = this.GetMenuNameLabel.bind(this);
	}

	componentDidMount() {
		const { setAppLeftTitle, setAppCenterTitle } = this.props;

		if (setAppLeftTitle)
			setAppLeftTitle("Approval");
		if (setAppCenterTitle) {
			if (this.props.MenuCode == MenuCodes.Backoffice_TransactionApproval) {
				setAppCenterTitle("CLIENT");
			}
			else {
				setAppCenterTitle("USER ADMIN");
			}
		}

	}

	ValueChanged(name, newValue, data) {
		this.setState(function (state, props) {
			var model = state.model || {};
			model[name] = newValue;
			return { model };
		});
	}

	GetClick() {
		const { model } = this.state;

		var request = {
			MenuId: model.Menu,
			UserId: model.User,
			ApprovalStatusId: model.ApprovalStatus,
			CreationStartDate: model.CreationStartDate,
			CreationEndDate: model.CreationEndDate,
			LastUpdateStartDate: model.LastUpdateStartDate,
			LastUpdateEndDate: model.LastUpdateEndDate
		};

		this.setState({ isLoading: true });
		Proxy.POST(
			"/coreapi/v1.0/WorkflowData/GetAll",
			request,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				var mainData = responseData.Item || {};
				this.setState({ InboxData: mainData.InboxData, OutboxData: mainData.OutboxData });
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", errorMessage);
			}
		);
	}

	ClearClick() {
		this.ClearScreen();
	}

	ClearScreen() {
		this.setState({ model: this.initialModel, lastModel: this.initialModel, rowIndex: null });
	}

	ShowMessage(type, title, message) {
		this.setState({ alert: AlertHelper.CreateAlert(title, message, type, this.hideAlert) });
	}

	hideAlert() {
		this.setState({ alert: null });
	}

	approvalDialogClose() {
		this.setState({ isApprovalDialogOpen: false }, this.GetClick);
	}

	GetMenuNameLabel(approvalData) {
		if (approvalData != null) {
			var menuCode = approvalData.MenuNameCode;
			var menuName = ResourceHelper.GetForMenu(menuCode);
			return menuName || "! " + menuCode;
		}

		return "Unknown";
	}

	GetActionName(approvalData) {
		if (approvalData != null) {
			var actionName = approvalData.ActionName;
			return actionName || "Unknown";
		}

		return "Unknown";
	}

	GetMenuName(menu) {
		var menuName = ResourceHelper.GetForMenu(menu.Name);

		return `${!menuName ? "! " + menu.Name : menuName} - ${menu.Code}`;
	}

	render() {
		const { model, alert, isLoading, InboxData, OutboxData, ApprovalData, isApprovalDialogOpen, isApproveUsersDialogOpen, ApproveUsers } = this.state;

		return (
			<div>
				<LoadingComponent Show={isLoading} />
				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.GetClick, Disabled: model.IsUpdate },
					{ Code: "Clear", OnClick: this.ClearClick }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<Card>
					<GenericExpansionPanel Title={"Main Parameters"}>
						<CardBody>
							<GridContainer>
								<GridItem xs={6}>
									<GenericSelectInput
										Name="Menu"
										LabelText="Menu"
										Value={model.Menu}
										ValueChanged={this.ValueChanged}
										Method="GET"
										Url="/coreapi/v1.0/Menu/GetMenuListForRight"
										DataRoot="MenuList"
										KeyValueMember="Id"
										RenderItem={this.GetMenuName}
										Sorted={this.sortedName}
									/>
								</GridItem>
								<GridItem xs={6}>
									<GenericSelectInput
										Name="User"
										LabelText="User"
										Method="POST"
										Url="/coreapi/v1.0/User/Search"
										Parameter={{ UniqueClientId: this.isBackOffice ? undefined : this.clientId, UserStatusList: [ UserStatus.Ok ]  }}
										DataRoot="Item"										
										KeyValueMember="Id"										
										TextValueMember="UserName" 
										Sorted={{ Member: "UserName" }}
										Value={model.User}
										ValueChanged={this.ValueChanged}
									/>
								</GridItem>
								<GridItem xs={6}>
									<ParameterComponent
										Name="ApprovalStatus"
										Value={model.ApprovalStatus}
										LabelText="Approval Status"
										ParameterCode="ApprovalStatus"
										Sorted={{ Member: "ParameterDesc" }}
										ValueChanged={this.ValueChanged}
										DefaultParamValue={ApprovalStatus.Waiting}
									/>
								</GridItem>
								<GridItem xs={6}>
									<GenericDateInput
										Name="CreationStartDate"
										LabelText="Creation Start Date"
										Value={model.CreationStartDate}
										ValueChanged={this.ValueChanged}
									/>
								</GridItem>
								<GridItem xs={6}>
									<GenericDateInput
										Name="CreationEndDate"
										LabelText="Creation End Date"
										Value={model.CreationEndDate}
										ValueChanged={this.ValueChanged}
										MinDate={model.CreationStartDate}
									/>
								</GridItem>
								<GridItem xs={12} sm={6}>
									<GenericDateInput
										Name="LastUpdateStartDate"
										LabelText="Last Update Start Date"
										Value={model.LastUpdateStartDate}
										ValueChanged={this.ValueChanged}
									/>
								</GridItem>
								<GridItem xs={6}>
									<GenericDateInput
										Name="LastUpdateEndDate"
										LabelText="Last Update End Date"
										Value={model.LastUpdateEndDate}
										ValueChanged={this.ValueChanged}
										MinDate={model.LastUpdateStartDate}
									/>
								</GridItem>
							</GridContainer>
						</CardBody>
					</GenericExpansionPanel>
				</Card>
				<Card className="no-radius">
					<CardHeader color="warning" icon>
						<h4 style={{ color: "black" }}>
							<b>Inbox (Checker)</b>
						</h4>
					</CardHeader>
					<CardBody>
						<GenericGrid
							Columns={[
								{
									Header: "Approve",
									accessor: "approveButton",
									Cell: d => {
										var disabled = true;

										if (d.original.ApprovalStatusCode == ApprovalStatus.Waiting)
											disabled = false;

										return (
											<GridButton Disabled={disabled} Icon={ApproveIcon}
												OnClick={() => { this.setState({ isApprovalDialogOpen: true, ApprovalData: d.original }); }} />
										);
									},
									width: 80,
									sortable: true,
									filterable: true,
								},
								{
									Header: "Menu",
									accessor: "MenuNameCode",
									Cell: d => <div>{ResourceHelper.GetForMenu(d.value)}</div>,
									sortable: true,
									filterable: true,
									filterMethod: (filter, row) => {
										var name = ResourceHelper.Get(row[filter.id]);
										return name.toUpperCase().includes(filter.value.toUpperCase());
									}
								},
								{
									Header: "Action",
									accessor: "ActionName"
								},
								{
									Header: "User",
									accessor: "UserName"
								},
								{
									Header: "Approval Status",
									accessor: "ApprovalStatus"
								},
								{
									Header: "Sub Status",
									accessor: "SubStatus"
								},
								{
									Header: "Creation Date",
									accessor: "InsertDateTime",
									type: GridColumnType.DateTime,
									filterMethod: (filter, row) => {
										return Formatter.FormatDateTime(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase());
									}
								},
								{
									Header: "Last Update Date",
									accessor: "UpdateDateTime",
									Cell: row => <div>{Formatter.FormatDateTime(row.value || row.original.InsertDateTime)}</div>,
									filterMethod: (filter, row) => {
										return Formatter.FormatDateTime(row[filter.id] || row._original.InsertDateTime).toLowerCase().startsWith(filter.value.toLowerCase());
									}
								}
							]}
							Data={InboxData}
						/>
					</CardBody>
				</Card>
				<Card className="no-radius">
					<CardHeader color="warning" icon>
						<h4 style={{ color: "black" }}>
							<b>Outbox (Maker)</b>
						</h4>
					</CardHeader>
					<CardBody>
						<GenericGrid
							Columns={[
								{
									Header: "Approve",
									accessor: "approveButton",
									Cell: d => {
										var disabled = true;

										if ([ApprovalStatus.Approved, ApprovalStatus.Rejected].some(x => x == d.original.ApprovalStatusCode))
											disabled = false;

										return (
											<GridButton Disabled={disabled} Icon={ApproveIcon}
												OnClick={() => { this.setState({ isApprovalDialogOpen: true, ApprovalData: d.original }); }} />
										);
									},
									width: 80,
									sortable: false,
									filterable: false,
								},
								{
									Header: "Approve Users",
									accessor: "usersCanApproveButton",
									Cell: d => {
										var disabled = true;
										if (d.original.ApprovalStatus != null) {
											if (![ApprovalStatus.Completed, ApprovalStatus.CanceledbyUser].some(x => x == d.original.ApprovalStatusCode))
												disabled = false;
										}
										return (
											<GridButton Disabled={disabled} Icon={RowDetailIcon}
												OnClick={() => {
													this.setState({ isLoading: true });
													Proxy.POST(
														"/coreapi/v1.0/WorkflowData/GetApprovers",
														d.original.Id,
														responseData => {
															if (!responseData.IsSucceeded) {
																console.error("Error", responseData.ErrorDescription);
																return;
															}
															let approverList = responseData.Item.map(x => {
																return {
																	value: x
																}
															});
															this.setState({ isApproveUsersDialogOpen: true, isLoading: false, ApproveUsers: approverList });
														},
														error => {
															this.setState({ isLoading: false });
															console.error("Error", error.message);
														}
													);
												}} />
										);
									},
									width: 120,
									sortable: false,
									filterable: false,
								},
								{
									Header: "Menu",
									accessor: "MenuNameCode",
									Cell: d => <div>{ResourceHelper.GetForMenu(d.value)}</div>,
									sortable: true,
									filterable: true,
									filterMethod: (filter, row) => {
										var name = ResourceHelper.Get(row[filter.id]);
										return name.toUpperCase().includes(filter.value.toUpperCase());
									}
								},
								{
									Header: "Action",
									accessor: "ActionName"
								},
								{
									Header: "Approval Status",
									accessor: "ApprovalStatus"
								},
								{
									Header: "Sub Status",
									accessor: "SubStatus"
								},
								{
									Header: "Creation Date",
									accessor: "InsertDateTime",
									type: GridColumnType.DateTime,
									filterMethod: (filter, row) => {
										return Formatter.FormatDateTime(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase());
									}
								},
								{
									Header: "Last Update Date",
									accessor: "UpdateDateTime",
									Cell: row => <div>{Formatter.FormatDateTime(row.value || row.original.InsertDateTime)}</div>,
									filterMethod: (filter, row) => {
										return Formatter.FormatDateTime(row[filter.id] || row._original.InsertDateTime).toLowerCase().startsWith(filter.value.toLowerCase());
									}
								}
							]}
							Data={OutboxData}
						/>
					</CardBody>
				</Card>
				<GenericDialog open={isApprovalDialogOpen} fullScreen onBackdropClick={this.approvalDialogClose} TransitionComponent={Transition}>
					<DialogTitle>
						<GenericLabel FontSize="16px" TextColor="black" Text={"Transaction Approval (" + this.GetMenuNameLabel(ApprovalData) + ") (Action Name: " + this.GetActionName(ApprovalData) + ")"} Bold={true} />
					</DialogTitle>
					<DialogContent>
						<DynamicComponent
							{...this.props}
							route={ApprovalData.MenuPath}
							screenId={ApprovalData.ScreenId}
							menuId={ApprovalData.MenuId}
							MenuCode={ApprovalData.MenuCode}
							ApprovalData={ApprovalData} After={this.approvalDialogClose}
							Disabled={ApprovalData.ApprovalStatusCode != ApprovalStatus.Rejected}
						/>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.approvalDialogClose}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>
				<GenericDialog open={isApproveUsersDialogOpen} fullScreen onBackdropClick={() => this.setState({ isApproveUsersDialogOpen: false })} TransitionComponent={Transition}>
					<DialogTitle>
						Users Can Approve
					</DialogTitle>
					<DialogContent>
						<GenericGrid
							Columns={[
								{
									Header: "Approve User Name",
									accessor: "value",
								}
							]}
							Data={ApproveUsers}
						/>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={() => this.setState({ isApproveUsersDialogOpen: false })}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>
			</div>
		);
	}
}

WorkflowManagement.propTypes = {
	classes: PropTypes.object
};

export default withArtifex(WorkflowManagement, style);