import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import { GenericAlert } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { EFTEntryType, EFTTransactionTypes } from "views/Constants/Constant";
import CreateEFTIndividual from "views/EFT/CreateEFT/CreateEFTIndividual";

class EftTraceApprove extends Component {

	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			model: {

			},
			IndividualModel: {

			}
		};

		this.SubmitClick = this.SubmitClick.bind(this);
		this.RejectClick = this.RejectClick.bind(this);
		this.Nothing = this.Nothing.bind(this);
		this.FillData = this.FillData.bind(this);
		this.GetModel = this.GetModel.bind(this);

		this.ShowMessage = this.ShowMessage.bind(this);
		this.hideAlert = this.hideAlert.bind(this);
	}

	Nothing() {

	}

	SubmitClick(workflowId, after, accessToken) {
		const { Id } = this.state;

		this.setState({ isLoading: true });
		Proxy.POST("/bankapi/v1.0/BankBmoEFT/TraceApproval",
			{ Id: Id },
			fileResponseData => {
				this.setState({ isLoading: false });

				if (!fileResponseData.IsSucceeded) {
					this.ShowMessage("error", "An error occurred", fileResponseData.ErrorDescription);
					return;
				}

				if (after) after();
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred", errorMessage);
			},
			workflowId,
			accessToken
		);
	}

	RejectClick(after) {
		const { Id } = this.state;

		this.setState({ isLoading: true });
		Proxy.POST("/bankapi/v1.0/BankBmoEFT/TraceCancel",
			{ Id: Id },
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "An error occurred", responseData.ErrorDescription);
					return;
				}
				if (after) after();
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred", errorMessage);
			}
		);
	}

	GetModel() {
		const { model } = this.state;
		return model;
	}

	FillData(Id) {
		this.setState({ isLoading: true });
		Proxy.POST("/bankapi/v1.0/BankBmoEFT/GetByIdRequest",
			{ Item: Id },
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "An error occurred while fetching data", responseData.ErrorDescription);
					return;
				}

				var eftData = responseData.Item;
				var data = eftData.Transaction;

				this.setState(function (state) {
					var model = state.model || {};
					var IndividualModel = state.IndividualModel || {};

					model.UniqueClientId = data.UniqueClientId;

					model.EntryType = data.EntryTypeId;
					model.EntryCode = data.EntryType ? data.EntryType.ParameterValue : "";
					model.EFTType = eftData.EFTTypeId;

					model.IsUpdate = true;
					IndividualModel.Id = data.Id;
					IndividualModel.PayeeId = eftData.PayeeId;
					IndividualModel.PayeeName = eftData.PayeeName;
					IndividualModel.BeneficiaryId = eftData.BeneficiaryId;
					IndividualModel.Amount = data.TransactionAmount;
					IndividualModel.TransactionDate = data.TransactionDate ? DateHelper.ToMoment(data.TransactionDate) : null;
					IndividualModel.TransferMemo = eftData.TransferMemo;
					IndividualModel.TransactionType = data.TransactionTypeId;
					IndividualModel.TransactionTypeCode = data.TransactionType ? data.TransactionType.ParameterValue : "";
					IndividualModel.TransactionCode = eftData.EFTTransactionTypeId;

					if (IndividualModel.TransactionTypeCode == EFTTransactionTypes.Credit) {
						IndividualModel.BeneficiaryId = eftData.DestinationAccountId;
					}
					else if (IndividualModel.TransactionTypeCode == EFTTransactionTypes.Debit) {
						IndividualModel.BeneficiaryId = eftData.SourceAccountId;
					}

					return { model, IndividualModel, Id };
				});
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while fetching data", errorMessage);
			}
		);
	}

	ShowMessage(type, title, message) {
		this.setState({ alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={this.hideAlert} /> });
	}

	hideAlert() {
		this.setState({ alert: null });
	}

	render() {
		const { model, IndividualModel, isLoading, Id, alert } = this.state;
		const parentModel = { EntryCode: EFTEntryType.Individual, UniqueClientId: model.UniqueClientId };

		return (
			<>
				<LoadingComponent Show={isLoading} />
				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Submit", OnClick: this.SubmitClick, RejectFunction: this.RejectClick, Disabled: true, ModelFunction: this.GetModel, FillDataFromModel: this.FillData, RowId: Id }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<CreateEFTIndividual Disabled={true} model={IndividualModel} onModelChange={this.Nothing} parentModel={parentModel} vModel={{}} />
			</>
		);
	}
}

export default withArtifex(EftTraceApprove, {});