
/**
 *
 * @param {string} text
 * @param {boolean} allWords
 */
function Capitalize(text, allWords = false) {
	if (typeof text !== "string") return "";
	return (allWords) ? // if all words
		text.split(" ").map(word => this.Capitalize(word)).join(" ") : //break down phrase to words then recursive calls until capitalizing all words
		text.charAt(0).toUpperCase() + text.slice(1).toLowerCase(); // if allWords is false, capitalize only the first word, mean the first char of the whole string
}

/**
 *
 * @param {string} text
 * @param {Array<string>} parameters
 */
function Format(text, parameters) {
	if (text == null) return null;

	if (parameters == null) return text;

	return text.replace(/{(\d+)}/g, function (match, number) {
		return typeof parameters[number] != "undefined"
			? parameters[number]
			: match;
	});
}

/**
 *
 * @param {string} string
 * @param {string} search
 * @param {string} replace
 */
function ReplaceAll(string, search, replace) {
	return string.split(search).join(replace);
}

/**
 *
 * @param {string} email
 */
function VerifyEmail(email) {
	var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return regex.test(email);
}

/**
 * digit and string control
 * this method allow french characters and hypen
 * @param {string} text
 */
function VerifiyDigitAndLetter(text) {
	var regex = /[^A-Za-z0-9 àâäèéêëîïôœùûüÿñæœçÀÂÄÈÉÊËÎÏÔÆŒÙÛÜŸÑÇ \-_]+/g;
	return regex.test(text);
}

/**
 * digit and string control
 * this method allow wire special character
 * @param {string} text
 */
function VerifiyDigitAndLetterForWire(text) {
	var regex = /[^A-Za-z0-9 (),.]+/g;
	return regex.test(text);
}
/**
 * 
 * @param {string} hex
 */
function IsValidColor(hex) {
	var pattern = new RegExp("^#([a-fA-F0-9]){3}$|[a-fA-F0-9]{6}$");
	return pattern.test(hex);
}

/**
 * Levenshtein distance is a string metric for measuring the difference between two sequences.
 * Compare two string and get similarity distance between texts as length.
 * Similarity decreases while distance increase. Zero means two string is same
 * @param {string} s1 str1 for compare
 * @param {string} s2 str2 for compare
 */
function LevenshteinDistance(s1, s2, caseSensitive = false) {
	var a = caseSensitive ? s1 : s1.toLowerCase();
	var b = caseSensitive ? s2 : s2.toLowerCase();

	if (a.length == 0) return b.length;
	if (b.length == 0) return a.length;

	var matrix = [];

	// increment along the first column of each row
	var i;
	for (i = 0; i <= b.length; i++) {
		matrix[i] = [i];
	}

	// increment each column in the first row
	var j;
	for (j = 0; j <= a.length; j++) {
		matrix[0][j] = j;
	}

	// Fill in the rest of the matrix
	for (i = 1; i <= b.length; i++) {
		for (j = 1; j <= a.length; j++) {
			if (b.charAt(i - 1) == a.charAt(j - 1)) {
				matrix[i][j] = matrix[i - 1][j - 1];
			} else {
				matrix[i][j] = Math.min(
					matrix[i - 1][j - 1] + 1, // substitution
					Math.min(
						matrix[i][j - 1] + 1, // insertion
						matrix[i - 1][j] + 1
					)
				); // deletion
			}
		}
	}

	return matrix[b.length][a.length];
}
/**
 * Get similarity score between two string from 0 to 100
 * Score 100 meaning both string is same, zero is completely different strings
 * @param {string} a str1
 * @param {string} b str2
 */
function GetSimilarity(a, b, caseSensitive = false) {
	if (typeof a != "string" || typeof b != "string") {
		return 0;
	}
	var maxLength = Math.max(a.length, b.length);
	var levDistance = LevenshteinDistance(a, b, caseSensitive);
	if (isNaN(levDistance)) {
		return 0;
	}
	var score = Math.ceil(((maxLength - levDistance) / maxLength) * 100);
	return score;
}

/**
* IsValidatePhone
* return true if the string 'input' is a valid phone
* @param {string} input
*/
function IsValidatePhone(input) {
	var phoneRe = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
	var digits = input.replace(/\D/g, "");
	return phoneRe.test(digits);
}


/*******************************************************
* GetFormatPhoneText
* returns a string that is in XXX-XXX-XXXX format
* @param {string} value
*******************************************************/
function GetFormatPhoneText(value) {
	let output = "(";
	value.replace(/^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/, function (match, g1, g2, g3) {
		if (g1.length) {
			output += g1;
			if (g1.length == 3) {
				output += ")";
				if (g2.length) {
					output += " " + g2;
					if (g2.length == 3) {
						output += " ";
						if (g3.length) {
							output += g3;
						}
					}
				}
			}
		}
	}
	);
	return output;
}

function PadLeft(paddingChar, value, size) {
	if (!typeof value)
		return paddingChar;

	return (new Array(size + 1).join(paddingChar || "0") + String(value)).slice(-size);;

}

function PadRight(paddingChar, value, size) {
	if (!typeof value)
		return paddingChar;

	return (new Array(size + 1).join(paddingChar || "0") + String(value)).slice(size);

}

function VerifyDigit(text) {
	var regex = /^[0-9]+/g;
	return regex.test(text);
}

function VerifiyWireLine(text) {
	var regex = /[^A-Za-z0-9 -]+/g;
	return regex.test(text);
}
function VerifiyAdressLineWire(text) {
	var regex = /[^A-Za-z0-9 (),.]+/g;
	return regex.test(text);
}

const StringHelper = {
	Capitalize,
	Format,
	ReplaceAll,
	VerifyEmail,
	VerifiyDigitAndLetter,
	IsValidColor,
	LevenshteinDistance,
	GetSimilarity,
	VerifiyDigitAndLetterForWire,
	IsValidatePhone,
	GetFormatPhoneText,
	PadLeft,
	PadRight,
	VerifyDigit,
	VerifiyWireLine,
	VerifiyAdressLineWire
};

export default StringHelper;
