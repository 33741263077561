import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import CustomerComponent from "views/Components/CustomerComponent";
import { GenericAlert, GenericDateInput, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { ProgramCodes, TransactionType, GridColumnType, MenuCodes } from "views/Constants/Constant.js";
import DateHelper from "core/DateHelper";
import moment from "moment";

class WalletTransaction extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			UniqueClientId: null,
			Customer: null,
			AccountNumber: null,
			ValidFrom: DateHelper.GetLocalDateAsUtc(),
			ValidTo: DateHelper.GetLocalDateAsUtc(),
			MinAmount: null,
			MaxAmount: null,
			ReferenceNumber: null,
			IsUniqueDataSearch: null
		};

		this.columns = [
			{
				Header: "Transaction Date",
				accessor: "TransactionDate",
				type: GridColumnType.DateTime,
				style: this.RenderStyle()
			},
			{
				Header: "Received / Sent",
				accessor: "TransactionType",
				Cell: row => (
					<p style={{ fontWeight: row.original.TransactionType == "Received" ? 600 : "normal" }}>{row.value}</p>
				)
			},
			{
				Header: "Unique Client Id",
				accessor: "ClientUniqueClientId",
				Cell: row => (
					<p style={{ fontWeight: row.original.TransactionType == "Received" ? 600 : "normal" }}>{row.value}</p>
				)
			},
			{
				Header: "Client Name",
				accessor: "ClientName",
				Cell: row => (
					<p style={{ fontWeight: row.original.TransactionType == "Received" ? 600 : "normal" }}>{row.value}</p>
				)
			},
			{
				Header: "Currency",
				accessor: "CurrencyCode",
				Cell: row => (
					<p style={{ fontWeight: row.original.TransactionType == "Received" ? 600 : "normal" }}>{row.value}</p>
				)
			}
			,
			{
				Header: "Original Billing Amount",
				accessor: "OriginalBillingAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money,
				style: this.RenderStyle()

			}, {
				Header: "Amount",
				accessor: "Amount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money,
				style: this.RenderStyle()
			},

			{
				Header: "Fee Amount",
				accessor: "FeeAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money,
				style: this.RenderStyle()
			},
			{
				Header: "Remaining Balance",
				accessor: "RemainingBalance",
				type: GridColumnType.Money,
				style: this.BalanceRenderStyle(),
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Card  Masked Number ",
				accessor: "MaskedCardNumber",
				Cell: row => (
					<p style={{ fontWeight: row.original.TransactionType == "Received" ? 600 : "normal" }}>{row.value}</p>
				)
			},
			{
				Header: "Wallet Name",
				accessor: "AccountName",
				Cell: row => (
					<p style={{ fontWeight: row.original.TransactionType == "Received" ? 600 : "normal" }}>{row.value}</p>
				)
			},
			{
				Header: "Explanation",
				accessor: "Description",
				Cell: row => (
					<p style={{ fontWeight: row.original.TransactionType == "Received" ? 600 : "normal" }}>{row.value}</p>
				)
			},
			{
				Header: "Wallet Number",
				accessor: "AccountNumber",
				Cell: row => (
					<p style={{ fontWeight: row.original.TransactionType == "Received" ? 600 : "normal" }}>{row.value}</p>
				)
			}
			, {
				Header: "Reference Number",
				accessor: "ReferenceNumber",
				Cell: row => (
					<p style={{ fontWeight: row.original.TransactionType == "Received" ? 600 : "normal" }}>{row.value}</p>
				)
			}
			, {
				Header: "Mcc",
				accessor: "Mcc",
				Cell: row => (
					<p style={{ fontWeight: row.original.TransactionType == "Received" ? 600 : "normal" }}>{row.value}</p>
				)
			}
			, {
				Header: "Settlement Status",
				accessor: "SettlementStatus",
				Cell: row => (
					<p style={{ fontWeight: row.original.TransactionType == "Received" ? 600 : "normal" }}>{row.value}</p>
				)
			}

		];

		this.state = {
			list: [],
			model: this.initialModel,
			filterModel: {},
			isLoading: false,
			LimitToken: false,
			LimitBalanceData: {
				AvailableCreditLimit: 0,
				AvailableDebitLimit: 0,
				AvailableBalance: 0
			},
			kModel: {}
		};
	}

	componentDidMount() {
		if (this.props.MenuCode == MenuCodes.CallCenter_WalletTransaction) {
			this.props.setAppLeftTitle("Wallet Transaction Search");
			this.props.setAppCenterTitle("CALL CENTER");
		} else {
			this.props.setAppLeftTitle("Wallet Transaction Search");
			this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
		}
	}

	RefreshToolBar = () => {
		this.setState({ LimitToken: !this.state.LimitToken });
	};

	LimitDataLoad = data => {
		this.setState({ LimitBalanceData: data, isLoading: false });
	};

	handleChange = (name, newValue) => {
		const model = { ...this.state.model };
		const initialModel = { ...this.initialModel };
		if (name === "Customer") {
			if (newValue != null) {
				model.Customer = newValue;
				if (newValue.Accounts != null && newValue.Accounts.length > 0) {
					model.AccountNumber = newValue.Accounts[0].AccountNumber;
				} else {
					model.AccountNumber = null;
				}
			} else {
				model.Customer = initialModel.Customer;
			}
		} else if (name === "UniqueClientId") {
			if (
				ClientHelper.IsClient() &&
				initialModel.UniqueClientId == null &&
				newValue != null
			) {
				this.initialModel.UniqueClientId = newValue;
			}
			model.UniqueClientId = newValue;
		} else if (name === "ValidFrom") {
			model.ValidFrom = newValue;
		} else if (name === "ValidTo") {
			model.ValidTo = newValue;
		} else if (name === "AccountNumber") {
			model.AccountNumber = newValue;
		} else if (name === "MinAmount") {
			model.MinAmount = newValue;
		} else if (name === "MaxAmount") {
			model.MaxAmount = newValue;
		} else if (name === "ReferenceNumber") {
			model.ReferenceNumber = newValue;
			model.IsUniqueDataSearch = newValue?.length > 0;
		}
		this.setState({ model });
	};

	HandleClear = () => {
		this.setState({
			model: this.initialModel,
			filterModel: {},
			selected: null,
			list: [],
			kmodel: {}
		});
	};

	addDays = (date, days) => {
		var returnDate = new Date();
		returnDate.setDate(date.getDate() + days);
		return returnDate;
	};
	IsValid = () => {
		var vmodel = { ...this.state.model };
		const kModel = { ...this.state.vModel };

		if (!vmodel.IsUniqueDataSearch && vmodel.ValidFrom == null || vmodel.ValidFrom == "") {
			kModel.ValidFrom = true;
			this.props.showMessage("warning", "Please fill required fields!", "Select From Date to continue!");
			this.setState({
				kModel: kModel
			});
			return false;
		}
		else {
			kModel.ValidFrom = false;
		}
		if (!vmodel.IsUniqueDataSearch && vmodel.ValidTo == null || vmodel.ValidTo == "") {
			kModel.ValidTo = true;
			this.props.showMessage("warning", "Please fill required fields!", "Select To Date to continue!");
			this.setState({
				kModel: kModel
			});
			return false;
		}
		else {
			kModel.ValidTo = false;
		}

		if (!vmodel.IsUniqueDataSearch && typeof vmodel.ValidFrom != "object" && vmodel.ValidFrom != "" && vmodel.ValidFrom != null) {
			this.props.showMessage("warning", "Please fill required fields!", "Select From Date to continue!");
			return false;
		}

		if (!vmodel.IsUniqueDataSearch && typeof vmodel.ValidTo != "object" && vmodel.ValidTo != "" && vmodel.ValidTo != null) {
			this.props.showMessage("warning", "Please fill required fields!", "Select To Date to continue!");
			return false;
		}

		if (!vmodel.IsUniqueDataSearch && (typeof vmodel.ValidFrom == "object" && typeof vmodel.ValidTo == "object") && vmodel.ValidTo < vmodel.ValidFrom) {
			this.props.showMessage("warning", "Please fill required fields!", "From Date cannot be greater than To Date!");
			return false;
		}

		if (!vmodel.IsUniqueDataSearch && (typeof vmodel.ValidFrom == "object" && typeof vmodel.ValidTo == "object")) {
			var interval = moment.duration(vmodel.ValidTo.diff(vmodel.ValidFrom)).asDays();
			if (interval > 30) {
				this.props.showMessage("warning", "Please fill required fields!", "Between two dates cannot be longer than 30 days!");
				return false;
			}
		}
		this.setState({
			kModel: kModel
		});
		return true;
	}
	HandleSearch = () => {

		if (!this.IsValid())
			return;

		const model = { ...this.state.model };
		this.setState({ isLoading: true });
		const request = {
			FromDate: model.ValidFrom,
			ToDate: model.ValidTo,
			ClientCustomerId: model.Customer != null ? model.Customer.Id : null,
			UniqueClientId: model.UniqueClientId,
			MinAmount: model.MinAmount,
			MaxAmount: model.MaxAmount,
			AccountNumber: model.AccountNumber,
			ReferenceNumber: model.ReferenceNumber,
		};
		Proxy.ExecuteGeneral(
			this, "POST",
			"/prepaidapi/v1.0/TransactionData/SearchWalletTransactionSummary",
			request,
			responseData => {
				this.setState({
					model,
					list: responseData.Item,
					isLoading: false
				});
			}
		);
	};

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })}
				/>
			)
		});
	};

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					MessageNode={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })}
				/>
			)
		});
	};

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					ShowCancel={true}
					OnCancel={() => this.setState({ alert: "" })}
					OnConfirm={onConfirm}
				/>
			)
		});
	};
	RenderStyle = () => {
		return {
			column: "TransactionType",
			condition: "Received",
			style: { fontWeight: "600" },
			prefix: ["+", "-"]
		};
	}
	BalanceRenderStyle = () => {
		return {
			column: "TransactionType",
			condition: "Received",
			style: { fontWeight: "600" },
			prefix: ["", ""]
		};
	}
	render() {
		const { Disabled } = this.props;
		const { alert, model, list, isLoading, kModel } = this.state;
		var endDate = (model.ValidFrom instanceof moment) ? model.ValidFrom.clone() : "";

		return (
			<div>

				<LoadingComponent Show={isLoading} />
				{alert}
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled || model.Id > 0,

						},
						{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled }
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After}
				/>
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card>
							<CardBody>
								<GenericExpansionPanel>
									<GridContainer>
										<GridItem xs={3}>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={{
													UniqueClientId: ClientHelper.IsClient()
														? ClientHelper.GetClientId()
														: undefined
												}}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
												Value={model.UniqueClientId || ""}
												ValueChanged={this.handleChange}
												CanClear
												All
												Disabled={ClientHelper.IsClient()}
												DefaultIndex={ClientHelper.IsClient() ? 0 : -1} />
										</GridItem>
										<GridItem xs={3}>
											<CustomerComponent
												LabelMd={4}
												CustomerNumber={model.Customer === null ? null : model.Customer.CustomerNumber}
												UniqueClientId={model.UniqueClientId}
												FillCallback={customer => {
													this.handleChange("Customer", customer);
												}}
												Programs={[ProgramCodes.Prepaid]} />
										</GridItem>
										<GridItem xs={3}>
											<GenericNumberInput
												NoFormatting={true}
												LabelText="Wallet"
												Name="AccountNumber"
												Value={model.AccountNumber}
												ValueChanged={this.handleChange}
												MaxLength={12} />
										</GridItem>
										<GridItem xs={3}>
											<GenericNumberInput NoFormatting Name="ReferenceNumber" LabelText="Reference Number" Value={model.ReferenceNumber} ValueChanged={this.handleChange} />
										</GridItem>
										<GridItem xs={3}>
											<GenericDateInput
												Name="ValidFrom"
												LabelText="From Date"
												LabelMd={4}
												IsFuture={false}
												MaxDate={DateHelper.GetLocalDateAsUtc()}
												Value={model.ValidFrom == null ? "" : DateHelper.ToDateInputValue(model.ValidFrom) || undefined}
												ValueChanged={this.handleChange}
												Utc
												Required={!model.IsUniqueDataSearch}
												IsInvalid={kModel.ValidFrom}
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericDateInput
												Name="ValidTo"
												LabelText="To Date"
												LabelMd={4}
												IsFuture={false}
												MinDate={model.ValidFrom}
												MaxDate={endDate != "" ? endDate.add(30, "days") : undefined}
												Value={model.ValidTo == null ? "" : DateHelper.ToDateInputValue(model.ValidTo) || ""}
												ValueChanged={this.handleChange}
												Utc
												Required={!model.IsUniqueDataSearch}
												IsInvalid={kModel.ValidTo}
											/>

										</GridItem>
										<GridItem xs={3}>
											<GenericNumberInput
												NoFormatting={true}
												LabelText="Amount(Min)"
												Name="MinAmount"
												Value={model.MinAmount}
												ValueChanged={this.handleChange}
												MaxLength={12} />
										</GridItem>
										<GridItem xs={3}>
											<GenericNumberInput
												NoFormatting={true}
												LabelText="Amount(Max)"
												Name="MaxAmount"
												Value={model.MaxAmount}
												ValueChanged={this.handleChange}
												MaxLength={12} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columns}
													ShowPagination={true}
													PageSize={100}
													ProgramCode={ProgramCodes.Prepaid}
													Sorted={[{ id: "TransactionDate", desc: true }]}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

WalletTransaction.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(WalletTransaction, {});
