/**
 * Rotate base64 image with degrees
 * @param {String} srcBase64 base64 string image data
 * @param {Number} degrees Rotation degrees. Negative numbers allowed
 * @param {Function} [callback]  Use callback or call method as async
 * @returns {Promise<String>} Async base64 callback
 */
function Rotate(srcBase64, degrees, callback = null) {
  return new Promise((resolve) => {
    const canvas = document.createElement("canvas");
    let ctx = canvas.getContext("2d");
    let image = new Image();

    image.onload = function () {
      canvas.width = degrees % 180 === 0 ? image.width : image.height;
      canvas.height = degrees % 180 === 0 ? image.height : image.width;

      ctx.translate(canvas.width / 2, canvas.height / 2);
      ctx.rotate((degrees * Math.PI) / 180);
      ctx.drawImage(image, image.width / -2, image.height / -2);

      let dataUrl = canvas.toDataURL();
      resolve(dataUrl);
      callback && callback(dataUrl);
    };
    image.src = srcBase64;
  });
}

const imageBase64Signature = {
  JPG: "/9j/",
  PNG: "iVBORw0KGgo",
};
/**
 * Predicate image tag by raw data signature and generate base64 image and then return like this 'data:image/?;base64,.......'
 * @param {String} base64 raw base64 without 'data:image/png;base64' tag
 */
function GenerateImageFromRawBase64(base64) {
  var image = base64;
  var base64Data = base64 && base64.toString().trim();
  if (base64Data && !base64Data.startsWith("data:")) {
    if (base64Data.startsWith(imageBase64Signature.JPG)) {
      image = `data:image/jpeg;base64,${base64Data}`;
    } else if (base64Data.startsWith(imageBase64Signature.PNG)) {
      image = `data:image/png;base64,${base64Data}`;
    }
  }
  return image;
}

if (!HTMLCanvasElement.prototype.toBlob) {
  Object.defineProperty(HTMLCanvasElement.prototype, "toBlob", {
    value: function (callback, type, quality) {
      var binStr = atob(this.toDataURL(type, quality).split(",")[1]),
        len = binStr.length,
        arr = new Uint8Array(len);

      for (var i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt(i);
      }

      callback(new Blob([arr], { type: type || "image/png" }));
    },
  });
}

window.URL = window.URL || window.webkitURL;
// Modified from https://stackoverflow.com/a/32490603, cc by-sa 3.0
// -2 = not jpeg, -1 = no data, 1..8 = orientations
function getExifOrientation(file, callback) {
  // Suggestion from http://code.flickr.net/2012/06/01/parsing-exif-client-side-using-javascript-2/:
  if (file.slice) {
    file = file.slice(0, 131072);
  } else if (file.webkitSlice) {
    file = file.webkitSlice(0, 131072);
  }

  var reader = new FileReader();
  reader.onload = function (e) {
    var view = new DataView(e.target.result);
    if (view.getUint16(0, false) != 0xffd8) {
      callback(-2);
      return;
    }
    var length = view.byteLength,
      offset = 2;
    while (offset < length) {
      var marker = view.getUint16(offset, false);
      offset += 2;
      if (marker == 0xffe1) {
        if (view.getUint32((offset += 2), false) != 0x45786966) {
          callback(-1);
          return;
        }
        var little = view.getUint16((offset += 6), false) == 0x4949;
        offset += view.getUint32(offset + 4, little);
        var tags = view.getUint16(offset, little);
        offset += 2;
        for (var i = 0; i < tags; i++)
          if (view.getUint16(offset + i * 12, little) == 0x0112) {
            callback(view.getUint16(offset + i * 12 + 8, little));
            return;
          }
      } else if ((marker & 0xff00) != 0xff00) break;
      else offset += view.getUint16(offset, false);
    }
    callback(-1);
  };
  reader.readAsArrayBuffer(file);
}

// Derived from https://stackoverflow.com/a/40867559, cc by-sa
function imgToCanvasWithOrientation(img, rawWidth, rawHeight, orientation) {
  var canvas = document.createElement("canvas");
  if (orientation > 4) {
    canvas.width = rawHeight;
    canvas.height = rawWidth;
  } else {
    canvas.width = rawWidth;
    canvas.height = rawHeight;
  }

  if (orientation > 1) {
    if (process.env.NODE_ENV !== "production") {
      console.log("EXIF orientation = " + orientation + ", rotating picture");
    }
  }

  var ctx = canvas.getContext("2d");
  switch (orientation) {
    case 2:
      ctx.transform(-1, 0, 0, 1, rawWidth, 0);
      break;
    case 3:
      ctx.transform(-1, 0, 0, -1, rawWidth, rawHeight);
      break;
    case 4:
      ctx.transform(1, 0, 0, -1, 0, rawHeight);
      break;
    case 5:
      ctx.transform(0, 1, 1, 0, 0, 0);
      break;
    case 6:
      ctx.transform(0, 1, -1, 0, rawHeight, 0);
      break;
    case 7:
      ctx.transform(0, -1, -1, 0, rawHeight, rawWidth);
      break;
    case 8:
      ctx.transform(0, -1, 1, 0, 0, rawWidth);
      break;
    default:
      break;
  }
  ctx.drawImage(img, 0, 0, rawWidth, rawHeight);
  return canvas;
}

/**
 * Compresses the image file if it exceeds the acceptable file size
 * @param {File} file File object
 * @param {Number} [acceptFileSize] Compression will be trig if larger than this file size.Default 512000(500kB). For example set '512000' for trigger compression when image size over than 500kb
 * @param {Number} [maxWidth] Resize image if larger than this width. Default 2048. If you set any value to width, set the height parameter to Infinity for dynamic resize
 * @param {Number} [maxHeight] Resize image if larger than this height. Default Infinity. If you set any value to height, set the width parameter to Infinity for dynamic resize
 * @param {Number} [quality]  Takes a number between 1 and 0. Ratio 0.8 is gives good results in many situations
 * @param {Function} [callback]
 * @returns {Promise<Blob>}
 */

function CompressImage(
  file,
  acceptFileSize = 512000,
  maxWidth = 1920,
  maxHeight = Infinity,
  quality = 0.8,
  callback = null
) {
  return new Promise((resolve) => {
    if (file.size <= acceptFileSize) {
      callback && callback(file);
      resolve(file);
      return;
    }
    var img = new Image();
    img.onerror = function () {
      URL.revokeObjectURL(this.src);
      callback && callback(file);
      resolve(file);
    };
    img.onload = function () {
      URL.revokeObjectURL(this.src);
      getExifOrientation(file, function (orientation) {
        var w = img.width,
          h = img.height;
        var scale =
          orientation > 4
            ? Math.min(maxHeight / w, maxWidth / h, 1)
            : Math.min(maxWidth / w, maxHeight / h, 1);
        h = Math.round(h * scale);
        w = Math.round(w * scale);

        var canvas = imgToCanvasWithOrientation(img, w, h, orientation);
        canvas.toBlob(
          function (blob) {
            if (process.env.NODE_ENV !== "production") {
              console.log(
                "Resized image to " +
                  w +
                  "x" +
                  h +
                  ", " +
                  (blob.size >> 10) +
                  "kB"
              );
            }
            callback && callback(blob);
            resolve(blob);
          },
          "image/jpeg",
          quality
        );
      });
    };
    img.src = URL.createObjectURL(file);
  });
}
/**
 * Get Image() object from base64
 * @param {*} base64 Base64 uri with header MimeData
 */
function GetImageObjectFromBase64(base64) {
  return new Promise((resolve) => {
    var i = new Image();
    i.onload = function () {
      resolve(i);
    };
    i.src = base64;
  });
}
const ImageHelper = {
  Rotate,
  GenerateImageFromRawBase64,
  CompressImage,
  GetImageObjectFromBase64,
};

export default ImageHelper;
