import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericDialog, GenericDialogActions, GenericGrid, GenericLabel, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { ClientType, GridColumnType } from "views/Constants/Constant";
import ClientSummaryTab from "../BankingClientSetup/ClientSteps/ClientSummaryTab";

class ContractList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			paramGlobalModel: {},
			paramCustomerProfile: {},
			paramBankCustomerCorporate: {},
			paramBankCustomerInternalAccountList: [],
			paramBankCustomerExternalAccountList: [],
			paramBankCustomerSecurityList: [],
			model: {},
			data: [],
			contract: {},
			accountModel: {},
			isLoading: false,
			isConfirmDialogOpen: false
		};

	}
	showErrorMessage = (message) => {
		this.setState({
			alert: <GenericAlert Title="Error" Message={message} Type="error" OnConfirm={() => this.setState({
				alert: null,
				isLoading: false
			})} />
		});
		this.setState({ isLoading: false });
	}
	componentDidMount() {
		this.props.setAppLeftTitle("Contract List");
		this.props.setAppCenterTitle("CLIENT");
	}
	SelectedRowChange = (index) => {
		const { data } = this.state;
		if (index != null && data != null && data[index] != null && data[index].UniqueClientId != null && data[index].UniqueClientId > 0) {
			this.GetCustomerContractModel(data[index].Id)
			this.GetCorporateProfile(data[index].UniqueClientId);
			this.GetCustomerProfile(data[index].UniqueClientId);
			this.GetCustomerSecurityModels(data[index].UniqueClientId);
			this.GetCustomerExternalBankAccountModels(data[index].UniqueClientId);
			this.GetCustomerDCBankAccountModels(data[index].UniqueClientId);
		}
		const { contract, paramGlobalModel, paramCustomerProfile, paramBankCustomerCorporate, paramBankCustomerInternalAccountList, paramBankCustomerExternalAccountList, paramBankCustomerSecurityList } = this.state;
		if ((!contract) || (!paramGlobalModel) || (!paramBankCustomerInternalAccountList) || (!paramBankCustomerExternalAccountList) || (!paramBankCustomerSecurityList) || ((!paramCustomerProfile) && (!paramBankCustomerCorporate))) {
			return;
		}
		this.setState({ isConfirmDialogOpen: true });
	}
	Clear = () => {
		this.setState({ model: {}, data: [], isLoading: false, accountModel: {} });
	}
	Search = () => {
		let { accountModel } = this.state;
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/CustomerContract/SearchContract",
			this.state.model,
			responseData => {
				if (!responseData.IsSucceeded) {
					console.log("Error!");
					return;
				}
				this.setState({ isLoading: false });
				if (responseData.Item != null) {

					accountModel.FeeAccount = responseData.Item.reduce(function (acc, val) { return acc + val.FeeAccount; }, 0);
					accountModel.SecurityAccount = responseData.Item.reduce(function (acc, val) { return acc + val.SecurityAccount; }, 0);
					accountModel.AdditionalAccount = responseData.Item.reduce(function (acc, val) { return acc + val.AdditionalAccount; }, 0);
					accountModel.StoreAccount = responseData.Item.reduce(function (acc, val) { return acc + val.StoreAccount; }, 0);
					accountModel.PrimaryAccount = responseData.Item.reduce(function (acc, val) { return acc + val.PrimaryAccount; }, 0);
					accountModel.TotalContract = responseData.Item.reduce(function (acc, val) { return acc + val.PrimaryAccount + val.AdditionalAccount + val.FeeAccount + val.SecurityAccount + val.StoreAccount; }, 0);
					accountModel.TotalBalance = responseData.Item.reduce(function (acc, val) { return acc + val.AccountBalance; }, 0);

					this.setState({ data: responseData.Item || [], accountModel });
				}
			},
			e => {
				console.log("Error!");
				this.setState({ isLoading: false });
			}
		);

	}
	handleChange = (name, newValue, data) => {
		const temp = { ...this.state.model };
		temp[name] = newValue;
		this.setState({ model: temp });
	}
	GetCorporateProfile = (UniqueClientId) => {
		Proxy.GET(
			"/bankapi/v1.0/CustomerCorporate/GetCorporateById/Id?Id=" + UniqueClientId,
			responseData => {
				if (responseData.Item != null) {
					if (!responseData.IsSucceeded) {
						this.showErrorMessage(responseData.ErrorDescription);
						return;
					}
					const model = { ...this.state.paramGlobalModel };
					model.UniqueClientId = responseData.Item.UniqueClient.UniqueClientId;
					model.CustomerName = responseData.Item.Name;
					model.Id = responseData.Item.UniqueClientId;
					model.ClientType = responseData.Item.ClientType;
					this.setState({ paramGlobalModel: model, paramBankCustomerCorporate: responseData.Item || {} });
				}
			},
			e => {
				console.log("Error!");
				this.setState({ isLoading: false });
			}
		);
	}
	GetCustomerProfile = (UniqueClientId) => {
		Proxy.GET(
			"/bankapi/v1.0/BankCustomer/GetById/Id?Id=" + UniqueClientId,
			responseData => {
				if (responseData.Item != null) {
					const model = { ...this.state.paramGlobalModel };
					model.UniqueClientId = responseData.Item.UniqueClient.UniqueClientId;
					model.CustomerName = responseData.Item.Name;
					model.Id = responseData.Item.UniqueClientId;
					model.ClientType = responseData.Item.ClientType;
					this.setState({ paramGlobalModel: model, paramCustomerProfile: responseData.Item || {} });
				}
			},
			e => {
				console.log("Error!");
				this.setState({ isLoading: false });
			}
		);
	}
	GetCustomerSecurityModels = (UniqueClientId) => {
		Proxy.GET(
			"/bankapi/v1.0/CustomerSecurity/GetByCustomerId/Id?Id=" + UniqueClientId,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.showErrorMessage(responseData.ErrorDescription);
					return;
				}
				this.setState({ paramBankCustomerSecurityList: responseData.Item || [] });
			},
			e => {
				console.log("Error!");
				this.setState({ isLoading: false });
			}
		);
	}
	GetCustomerExternalBankAccountModels = (UniqueClientId) => {
		Proxy.GET(
			"/bankapi/v1.0/CustomerSettlementAccount/GetByCustomerId/Id?Id=" + UniqueClientId,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.showErrorMessage(responseData.ErrorDescription);
					return;
				}
				this.setState({ paramBankCustomerExternalAccountList: responseData.Item || [] });
			},
			e => {
				console.log("Error!");
				this.setState({ isLoading: false });
			}
		);
	}
	GetCustomerDCBankAccountModels = (UniqueClientId) => {
		Proxy.GET(
			"/bankapi/v1.0/Account/GetByCustomerId/Id?Id=" + UniqueClientId,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.showErrorMessage(responseData.ErrorDescription);
					return;
				}
				this.setState({ paramBankCustomerInternalAccountList: responseData.Item || [] });
			},
			e => {
				console.log("Error!");
				this.setState({ isLoading: false });
			}
		);
	}
	GetCustomerContractModel = (ContractId) => {
		Proxy.POST(
			"/bankapi/v1.0/CustomerContract/GetById",
			{ Id: ContractId },
			responseData => {
				if (!responseData.IsSucceeded) {
					this.showErrorMessage(responseData.ErrorDescription);
					return;
				}
				this.setState({ contract: responseData.Item || {} });
			},
			e => {
				console.log("Error!");
				this.setState({ isLoading: false });
			}
		);
	}
	render() {
		const { accountModel, model, data } = this.state;
		const { Disabled, classes } = this.props;

		return (
			<div>
				<GridContainer spacing={16}>
					{this.state.alert}
					<LoadingComponent Show={this.state.isLoading} />

					<GridItem xs={12}>
						<ButtonToolbar ButtonList={[
							{ Code: "Clear", OnClick: this.Clear, Disabled: Disabled },
							{ Code: "Search", OnClick: this.Search, Disabled: Disabled },
						]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
					</GridItem>
					<Card className="no-radius">
						<CardBody>
							<GridContainer>
								<GridItem xs={4}>
									<GenericSelectInput All Name="ProgramId" LabelText="Program" Value={model.ProgramId || ""} ValueChanged={this.handleChange} KeyValueMember="Id" TextValueMember="Description" Method="GET" Url="/bankapi/v1.0/Bank/ValidBankProgramsForLimitProfile" DataRoot="Item" />
									<GenericSelectInput All Name="UniqueClientId" Url="/bankapi/v1.0/BankCustomer/Search" Method="POST" Parameter={{ UniqueClientId: 0, }} LabelText="Client Name" Value={model.UniqueClientId || ""} DataRoot="Item" ValueChanged={this.handleChange} KeyValueMember="Id" TextValueMember="Name" />
									<GenericDateInput Utc Name="AgreementStartDate" LabelText="Agreement Start Date" Value={model.AgreementStartDate == null ? "" : DateHelper.ToDateInputValue(model.AgreementStartDate) || ""} ValueChanged={this.handleChange} />
									<GenericDateInput Utc Name="AgreementEndDate" LabelText="Agreement End Date" Value={model.AgreementEndDate == null ? "" : DateHelper.ToDateInputValue(model.AgreementEndDate) || ""} ValueChanged={this.handleChange} />
									<GenericSelectInput All Name="SalesPersonUniqueClientId" LabelText="Sales Person" Value={model.SalesPersonUniqueClientId || ""} DataRoot="Item" ValueChanged={this.handleChange} KeyValueMember="Id" TextValueMember="Name" Url="/bankapi/v1.0/BankCustomer/Search" Method="POST" Parameter={{ UniqueClientId: 0, ClientTypes: [ClientType.SalesAgentPersonal, ClientType.SalesAgentCorporate] }} />
								</GridItem>
								<GridItem xs={8}>
									{accountModel.FeeAccount != null &&
										(
											<div style={{ opacity: accountModel.FeeAccount != null ? "0.6" : "1" }}>
												<Card className="no-radius">
													<CardHeader>
														<GenericTitle text={"Statistical Information"} />
													</CardHeader>
													<CardBody>
														<GridContainer>
															<GridItem xs={6}>
																<GenericTextInput
																	LabelMd={4}
																	Name="FeeAccount"
																	LabelText="Fee Wallet Count"
																	Value={accountModel.FeeAccount || ""}
																	Disabled={true}
																/>
																<GenericTextInput
																	LabelMd={4}
																	Name="SecurityAccount"
																	LabelText="Security Wallet Count"
																	Value={accountModel.SecurityAccount || ""}
																	Disabled={true}
																/>
																<GenericTextInput
																	LabelMd={4}
																	Name="StoreAccount"
																	LabelText="Store Wallet"
																	Value={accountModel.StoreAccount || ""}
																	Disabled={true}
																/>
																<GenericTextInput
																	LabelMd={4}
																	Name="TotalContract"
																	LabelText="Total Contract"
																	Value={accountModel.TotalContract || ""}
																	Disabled={true}
																/>
															</GridItem>
															<GridItem xs={6}>
																<GenericTextInput
																	LabelMd={4}
																	Name="AdditionalAccount"
																	LabelText="Additional Wallet"
																	Value={accountModel.AdditionalAccount || ""}
																	Disabled={true}
																/>
																<GenericTextInput
																	LabelMd={4}
																	Name="PrimaryAccount"
																	LabelText="Funding Wallet Count"
																	Value={accountModel.PrimaryAccount || ""}
																	Disabled={true}
																/>
																<GenericNumberInput
																	LabelMd={4}
																	Name="TotalBalance"
																	LabelText="Total Balance"
																	Value={accountModel.TotalBalance || ""}
																	DecimalScale={2}
																	Disabled={true}
																/>
															</GridItem>
														</GridContainer>
													</CardBody>
												</Card>
											</div>
										)
									}
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>

					<Card className="no-radius">
						<CardBody>
							<GridItem xs={12}>
								<GridItem>
									<GenericGrid
										Data={data}
										PageSize={10}
										Columns={[
											{
												Header: "Program",
												accessor: "ProgramName"
											},
											{
												Header: "Client Unique ID",
												accessor: "UniqueClientNumber"
											},
											{
												Header: "Client Name",
												accessor: "ClientName"
											},
											{
												Header: "Client Type",
												accessor: "ClientTypeStr",
												Cell: row => (
													<div>{row.value}</div>
												)
											},
											{
												Header: "Contract Status",
												accessor: "ContractStatusStr"
											},
											{
												Header: "Sales Person/Agent",
												accessor: "SalesAgentUniqueClintNumber",
												Cell: row => (
													<div>{row.value ? "(" + row.value + ")" + " " + row.original.SalesPerson : "-"}</div>
												)
											},
											{
												Header: "Created Date",
												accessor: "InsertDateTime",
												type: GridColumnType.DateUtc
											},
											{
												Header: "Agreement Start Date",
												accessor: "AgreementStartDate",
												type: GridColumnType.DateUtc
											},
											{
												Header: "Agreement End Date",
												accessor: "AgreementEndDate",
												type: GridColumnType.DateUtc
											},
											{
												Header: "Sales Channel",
												accessor: "SalesChannelTypeStr"
											},
											{
												Header: "Renewal Type",
												accessor: "RenewalTypeStr"
											},
											{
												Header: "Last Renewal Date",
												accessor: "LastRenewalDate",
												type: GridColumnType.Date
											},
											{
												Header: "Notification Period",
												accessor: "NotificationPeriod"
											},
											{
												Header: "Limit Profile",
												accessor: "LimitProfileStr"
											},
											{
												Header: "Fee Profile",
												accessor: "FeeProfileStr"
											},
											{
												Header: "Fee Wallet",
												accessor: "FeeAccount"
											},
											{
												Header: "Security Wallet",
												accessor: "SecurityAccount"
											},
											{
												Header: "Store Wallet",
												accessor: "StoreAccount"
											},
											{
												Header: "Additional Wallet",
												accessor: "AdditionalAccount"
											},
											{
												Header: "Funding Wallet",
												accessor: "PrimaryAccount"
											},
											{
												Header: "Balance",
												accessor: "AccountBalance",
												type: GridColumnType.Money,
												ColumnType: GridColumnType.Money
											}
										]}
										RowSelected={index => {
											this.SelectedRowChange(index);
										}}
										SelectedIndex={this.state.rowIndex}
									/>
								</GridItem>
							</GridItem>
						</CardBody>
					</Card>
				</GridContainer>
				<GenericDialog open={this.state.isConfirmDialogOpen} fullScreen maxWidth="md" fullWidth>
					<DialogTitle><GenericLabel FontSize="16px" TextColor="black" Text="Client Summary" Bold={true} /></DialogTitle>
					{/* <GridContainer justify="space-between" alignItems="center">
							<GridItem>
								<GenericLabel FontSize="16px" TextColor="black" Text="Client Summary" Bold={true} />
							</GridItem>
							<GridItem>
								<IconButton onClick={() => this.setState({ isConfirmDialogOpen: false })}>
									<GenericIcon>close</GenericIcon>
								</IconButton>
							</GridItem>
						</GridContainer> */}

					<DialogContent>
						<ClientSummaryTab
							key={"keys" + this.state.contract.Id}
							Disabled={true}
							contract={this.state.contract}
							paramCustomerProfile={this.state.paramCustomerProfile}
							paramGlobalModel={this.state.paramGlobalModel}
							paramBankCustomerCorporate={this.state.paramBankCustomerCorporate}
							paramBankCustomerInternalAccountList={this.state.paramBankCustomerInternalAccountList}
							paramBankCustomerExternalAccountList={this.state.paramBankCustomerExternalAccountList}
							paramBankCustomerSecurityList={this.state.paramBankCustomerSecurityList}
						/>
					</DialogContent>

					<GenericDialogActions>
						<Button size="sm" onClick={() => this.setState({ isConfirmDialogOpen: false })}>Close</Button>
					</GenericDialogActions>

				</GenericDialog>
			</div>

		);
	}
}
ContractList.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func
};


export default withArtifex(ContractList, {});