import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import { GenericCheckInput, GenericExpansionPanel, GenericLabel, GenericRadioInput, GenericSelectInput } from "views/Components/Generic";
import { NotificationUsageType } from "views/Constants/Constant";

class NotificationDefinitionAddOrUpdate extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			initialModel: props.model
		};
	}

	render() {
		const { model } = this.props;
		return (
			<div>
				<GenericExpansionPanel Title="Notification">
					<GridContainer>
						<GridItem xs={3} style={{ marginTop: 22 }}>
							<GenericSelectInput
								Name="ProgramTypeId"
								LabelMd={3}
								LabelText="Program"
								Method="GET"
								Url="/bankapi/v1.0/Bank/ValidBankProgramsNotification"
								DataRoot="Item"
								KeyValueMember="Id"
								TextValueMember="Description"
								Value={model.ProgramTypeId}
								ValueChanged={this.props.handleChange}
							/>
						</GridItem>
						<GridItem xs={4} style={{ marginTop: 22 }}>
							<GenericSelectInput
								Name="NotificationTemplateTypeId"
								LabelMd={5}
								LabelText="Notification Template Type"
								Method="POST"
								Url="/coreapi/v1.0/dynamic/do"
								Parameter={{
									operation: "GetByParameter",
									screenId: 52
								}}
								DataRoot="Item"
								KeyValueMember="Id"
								TextValueMember="Description"
								Value={model.NotificationTemplateTypeId}
								ValueChanged={this.props.handleChange}
								Sorted={{
									Member: "Description"
								}}
							/>
						</GridItem>
						<GridItem xs={2}>
							<div style={{ marginTop: 4, marginBottom: 6 }}>
								<GenericLabel Text="Available Notification Channels"></GenericLabel>
							</div>
							<GenericCheckInput
								Name="SendSms"
								LabelText="SMS"
								Value={model.SendSms}
								ValueChanged={this.props.handleBooleanChange} />
							<GenericCheckInput
								Name="SendEmail"
								LabelText="E-Mail"
								Value={model.SendEmail}
								ValueChanged={this.props.handleBooleanChange} />
							<GenericCheckInput
								Name="SendApplication"
								LabelText="Application"
								Value={model.SendApplication}
								ValueChanged={this.props.handleBooleanChange} />
						</GridItem>
						<GridItem xs={3}>
							<GenericRadioInput
								Name="UsageType"
								LabelText="Usage Type"
								IsStatic={true}
								StaticData={NotificationUsageType}
								KeyValueMember="Value"
								TextValueMember="Text"
								Value={model.UsageType || 0}
								ValueChanged={this.props.handleChange} />
						</GridItem>
					</GridContainer>
				</GenericExpansionPanel>
			</div>
		);
	}
}

NotificationDefinitionAddOrUpdate.propTypes = {
	classes: PropTypes.object,
	model: PropTypes.object.isRequired,
	handleChange: PropTypes.func,
	handleBooleanChange: PropTypes.func,
	handleToggle: PropTypes.func
};

export default NotificationDefinitionAddOrUpdate;
