import { Card, DialogActions } from "@material-ui/core";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericAlert, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import { ClientType } from "views/Constants/Constant";

class WireSenderInformation extends Component {
	constructor(props) {
		super(props);

		this.state = {
			model: {
				UniqueClientId: this.props.ClientInfo.Id,
				CityId: 0
			},
			vModel: {},
			alert: null
		};
		this.maxLength30 = { maxLength: 30 };
		this.maxLength128 = { maxLength: 128 };
		this.MaxLen = { maxLength: 30 };
		this.SortedCountry = { Member: "OrderIndex" };
		this.SortedCityProvince = { Member: "ParameterDesc" };
		this.EmptyObj = {};

	}

	componentDidMount() {
		var senderModel = this.props.SenderModel;
		if (!senderModel)
			this.props.GetData();

		this.setState({ model: senderModel })
		this.FetchCanadaPost();
	}
	RenderCountry(d) {
		return d.ISO2 + " - " + d.Name;
	}
	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;


		if (name == "CountryId") {
			model.ProvinceId = null;
			model.CityId = null;
		}
		if (name == "ProvinceId") {
			model.CityId = null;
		}
		this.setState({ model });
	}

	HandleClear = () => {
		this.setState({ model: {}, isEdit: false, index: -1 });
	}


	FetchCanadaPost = () => {
		Proxy.POST("/coreapi/v1.0/Parameter/Search",
			{
				ParameterCode: "CanadaPostApiKey",
				ParameterValue: "CP"
			},
			responseData => {
				if (responseData.Item == null) {
					console.log("Canada Post Api key not fetched !");
					return;
				}
				if (responseData.Item[0] == null) {
					console.log("Canada Post Api key not fetched !");
					return;
				}

				var apikey = responseData.Item[0].ParameterDesc;
				console.log("KEY CP ", apikey);
				var FillAddressFields = this.FillSenderAddressFields;
				// @ts-ignore
				var url = window.CanadaPostAddressCompleteUrl;
				var path = url + apikey;
				var CanadaPostRequest=this.SubmitCanadaPostRequest;

				var pca;
				const script = document.createElement("script");
				script.src = path;
				script.async = false;
				document.head.appendChild(script);
				script.onload = function () {
					// @ts-ignore
					pca = window.pca;
					if (pca != null) {
						var fields = [
							{ element: "bank-street-address", field: "Line1", mode: pca.fieldMode.SEARCH },
							{ element: "bank-street-address2", field: "Line2", mode: pca.fieldMode.POPULATE },
							{ element: "bank-postcode", field: "PostalCode", mode: pca.fieldMode.SEARCH }
						];
						var options = {
							key: apikey
						};
						var control = new pca.Address(fields, options);
						CanadaPostRequest();

						control.listen("populate", function (address) {
							console.log();

							FillAddressFields(address);
						});

					}
				};
			});
	}
	SubmitCanadaPostRequest = async () => {
		const model = { ...this.state.model };
		var requestdata = { 

			UniqueClientId: null,
			MenuCode:this.props.getMenuCode,
			AppCenterTitle:this.props.getAppCenterTitle,
			AppLeftTitle:this.props.getAppLeftTitle
		};
		Proxy.POST(
			"/bankapi/v1.0/Reporting/CanadaPostRequestReportInsert",
			requestdata,
			responseData => {
				if (responseData.Item != null ) {
					 
				}
			},
 		);
		// var result = await this.props.ExecuteApiPost("/bankapi/v1.0/Reporting/CanadaPostRequestReportInsert", requestdata);
	}
	FillSenderAddressFields = (address) => {
		const { model } = { ...this.state };
		Proxy.POST("/bankapi/v1.0/WireCountry/CanadaPost",
			address,
			responseData => {
				if (responseData.Item != null) {
					model.CountryId = responseData.Item.CountryId;
					model.ProvinceId = responseData.Item.ProvinceId;
					model.CityId = responseData.Item.CityId;
				} else {
					model.CountryId = 0;
					model.ProvinceId = 0;
					model.CityId = 0;
				}
				model.Address1 = address.Line1;
				model.Address2 = address.Line2;
				model.ZipCode = address.PostalCode;
				this.setState({ model });
			}
		);
	}



	Validate = () => {
		const { model, vModel } = this.state;
		const { ClientInfo } = this.props;
		var messages = [];

		if (ClientInfo && ClientInfo.ClientType == ClientType.Corporate) {
			if (!model.CompanyLegalName) {
				vModel.CompanyLegalName = true;
				messages.push("Company Name cannot be empty")
			}
			else
				vModel.CompanyLegalName = false;

		}

		if (ClientInfo && ClientInfo.ClientType == ClientType.Personal) {
			if (!model.Name) {
				vModel.Name = true;
				messages.push("Name cannot be empty");
			}
			else
				vModel.Name = false;

			if (!model.SurName) {
				vModel.SurName = true;
				messages.push("Last Name cannot be empty");
			}
			else
				vModel.SurName = false;
		}


		if (!model.Address1) {
			vModel.Address1 = true;
			messages.push("Address 1 cannot be empty");
		}
		else
			vModel.Address1 = false;

		if (!model.CityId) {
			vModel.CityId = true;
			messages.push("City cannot be empty");
		}
		else
			vModel.CityId = false;


		if (!model.CountryId) {
			vModel.CountryId = true;
			messages.push("Country cannot be empty");
		}
		else
			vModel.CountryId = false;

		if (!model.ZipCode) {
			vModel.ZipCode = true;
			messages.push("Postal Code cannot be empty");
		}
		else
			vModel.ZipCode = false;

		if (messages.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		return true;
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}
	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: null })} OnConfirm={onConfirm} />
		});
	}
	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
	}
	HandleUpdate = () => {
		if (!this.Validate()) {
			return;
		}

		this.setState({ isLoading: true });
		const model = { ...this.state.model };
		model.UniqueClientId = this.props.ClientInfo.Id;
		Proxy.POST("/bankapi/v1.0/WireTransferSender/Update",
			model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", responseData.ErrorDescription);
					return;
				}
				this.props.addWireSenderDialogClose();
				this.props.GetData();
				this.props.showMessage("success", "Updated successfully!");
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	HandleDelete = () => {
		this.ShowConfirmMessage("warning", "Delete", "Are you sure to delete this record?", () => {
			this.setState({ isLoading: true, alert: "" });
			const model = { ...this.state.model };
			model.UniqueClientId = this.props.ClientInfo.Id;

			Proxy.POST("/bankapi/v1.0/WireTransferSender/Delete",
				{ Id: this.state.model.Id },
				(responseData) => {
					this.setState({ isLoading: false });
					if (!responseData.IsSucceeded) {
						this.props.showMessage("error", responseData.ErrorDescription);
						return;
					}
					this.HandleClear();
					this.props.addWireSenderDialogClose();
					this.props.GetData();
					this.props.showMessage("success", "Deleted successfully!");
				},
				(error) => {
					this.setState({ isLoading: false });
					this.props.showMessage("error", "Error", error);
				}
			);
		});
	}

	HandleSubmit = () => {
		if (!this.Validate()) {
			return;
		}
		this.setState({ isLoading: true });
		const model = { ...this.state.model };
		model.UniqueClientId = this.props.ClientInfo.Id;
		Proxy.POST("/bankapi/v1.0/WireTransferSender/Insert",
			model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.HandleClear();
				this.props.addWireSenderDialogClose();
				this.props.GetData();
				this.props.showMessage("success", "Success", "Added successfully!");
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}


	render() {
		const { Disabled, ClientInfo } = this.props;
		const { model, alert, vModel } = { ...this.state }

		return (

			<>
				{alert}
				<DialogActions>
					<GridContainer justify="left">
						<Button size="sm" onClick={this.HandleSubmit} disabled={model.Id}>
							Submit  </Button>
						<Button size="sm" onClick={this.HandleUpdate} disabled={!model.Id}>
							Update  </Button>
						<Button size="sm" onClick={this.HandleDelete} disabled={!model.Id}>
							Delete  </Button>
						<Button size="sm" onClick={this.HandleClear}>
							Clear  </Button>
					</GridContainer>
				</DialogActions>

				<GridContainer>
					<GridItem xs={6}>
						{ClientInfo && ClientInfo.ClientType == ClientType.Corporate &&
							<GenericTextInput
								LabelText="Company Name"
								Name="CompanyLegalName"
								Value={model.CompanyLegalName}
								ValueChanged={this.HandleChange}
								Disabled={Disabled}
								inputProps={this.maxLength128}
								Required
								IsInvalid={vModel.CompanyLegalName}
							/>
						}
						{ClientInfo && ClientInfo.ClientType == ClientType.Personal &&
							<GenericTextInput
								Name="Name"
								LabelText="Name"
								Value={model.Name}
								ValueChanged={this.HandleChange}
								Disabled={Disabled}
								inputProps={this.maxLength30}
								Required
								IsInvalid={vModel.Name}
							/>
						}
						{ClientInfo && ClientInfo.ClientType == ClientType.Personal &&
							<GenericTextInput
								Name="MiddleName"
								LabelText="Middle Name"
								Value={model.MiddleName}
								inputProps={this.maxLength30}
								ValueChanged={this.HandleChange}
								Disabled={Disabled}
							/>
						}
						{ClientInfo && ClientInfo.ClientType == ClientType.Personal &&
							<GenericTextInput
								LabelText="Last Name"
								Name="SurName"
								Value={model.SurName}
								inputProps={this.maxLength30}
								ValueChanged={this.HandleChange}
								Disabled={Disabled}
								Required
								IsInvalid={vModel.SurName}
							/>
						}

					</GridItem>
					<GridItem xs={6}>
						<GridItem xs={12}>
							<GenericTextInput
								Name="Address1"
								LabelText="Address 1"
								Id="bank-street-address"
								Value={model.Address1}
								ValueChanged={this.HandleChange}
								Disabled={Disabled}
								inputProps={this.MaxLen} />
						</GridItem>
						<GridItem xs={12}>
							<GenericTextInput
								Name="Address2"
								LabelText="Address 2"
								Id="bank-street-address2"
								Value={model.Address2}
								ValueChanged={this.HandleChange}
								Disabled={Disabled}
								inputProps={this.MaxLen} />
						</GridItem>
						<GridItem xs={12}>
							<GenericTextInput
								Name="ZipCode"
								LabelText="Postal Code"
								Id="bank-postcode"
								Value={model.ZipCode}
								ValueChanged={this.HandleChange}
								Disabled={Disabled} />
						</GridItem>
						<GridItem xs={12}>
							<GenericSelectInput
								Name="CountryId"
								LabelText="Country"
								Method="POST"
								Url="/bankapi/v1.0/WireCountry/Search"
								Parameter={{ Name: "Canada" }}
								DataRoot="Item"
								KeyValueMember="Id"
								TextValueMember="Code"
								Value={model.CountryId}
								ValueChanged={this.HandleChange}
								Sorted={this.SortedCountry}
								RenderItem={this.RenderCountry}
								Disabled={Disabled} />
						</GridItem>
						<GridItem xs={12}>
							<GenericSelectInput
								key={model.CountryId + "province"}
								Name="ProvinceId"
								LabelText="Province"
								Method="POST"
								Url="/bankapi/v1.0/WireProvince/Search"
								Parameter={{ CountryId: model.CountryId }}
								DataRoot="Item"
								KeyValueMember="Id"
								TextValueMember="Name"
								Value={model.ProvinceId}
								ValueChanged={this.HandleChange}
								Disabled={Disabled} />
						</GridItem>
						<GridItem xs={12}>
							<GenericSelectInput
								key={"city" + model.ProvinceId + "" + model.CountryId}
								Name="CityId"
								LabelText="City"
								Method="POST"
								Url="/bankapi/v1.0/WireCity/Search"
								Parameter={{ ProvinceId: model.ProvinceId, CountryId: model.CountryId }}
								DataRoot="Item"
								KeyValueMember="Id"
								TextValueMember="Name"
								Value={model.CityId}
								ValueChanged={this.HandleChange}
								Disabled={Disabled} />
						</GridItem>
					</GridItem>

				</GridContainer>

			</>
		);
	}
}

WireSenderInformation.propTypes = {
	classes: PropTypes.object.isRequired,
	model: PropTypes.object,
	showMessage: PropTypes.func,
	Disabled: PropTypes.bool,
	getAppLeftTitle:PropTypes.string,
	getAppCenterTitle:PropTypes.string,
	getMenuCode:PropTypes.string
};

export default withArtifex(WireSenderInformation, {});