import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDateInput, GenericLabel, GenericNumberInput } from "views/Components/Generic";

class FeeProfile extends Component {
	constructor(props) {
		super(props);

		this.state = {
			list: []
		};
	}
	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("AML & KYC");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("Fee Profile");
		}

		this.List();
	}

	ValueChanged = (name, value) => {
		this.setState(state => {
			var list = state.list;

			if (name.includes("FeeAmount")) {
				var index = name.replace("FeeAmount", "");
				list[index].FeeAmount = value;
			} else if (name.includes("Cost")) {
				index = name.replace("Cost", "");
				list[index].Cost = value;
			} else if (name.includes("ValidFrom")) {
				index = name.replace("ValidFrom", "");
				list[index].ValidFrom = value;
			} else if (name.includes("ValidTo")) {
				index = name.replace("ValidTo", "");
				list[index].ValidTo = value;
			}

			return { list };
		});
	}

	List = async () => {
		const { ExecuteApiGet } = this.props;
		var result = await ExecuteApiGet("/amlkycapi/v1.0/FeeProfile/List");

		this.setState({ list: result || [] });
	}

	Validate = () => {
		const { list } = this.state;
		const { showValidationErrors } = this.props;
		var errors = [];

		if (list.filter(x => x.FeeAmount == null).length != 0) {
			errors.push("Fee amount can not be null.")
		}
		if (list.filter(x => x.Cost == null).length != 0) {
			errors.push("Cost can not be null.")
		}
		if (list.filter(x => x.ValidFrom == null).length != 0) {
			errors.push("Valid from can not be null.")
		}
		if (list.filter(x => x.ValidTo == null).length != 0) {
			errors.push("Valid to can not be null.")
		}

		if (errors.length > 0) {
			showValidationErrors(errors);

			return false;
		}

		return true;
	}

	render() {
		const { list } = this.state;
		const { Disabled, ExecuteApiPost } = this.props;
		var feeProfiles = [], index = 0;

		list.map(x => {
			var tempArray = [];

			tempArray.push(
				<GridItem key={index} xs={2} style={{ marginTop: 8 }}>
					<GenericLabel
						Text={x.Firm.ParameterDesc}
						Bold />
				</GridItem>
			);
			tempArray.push(
				<GridItem key={index + 1} xs={2}>
					<GenericNumberInput
						Name={"FeeAmount" + index}
						LabelMd={0}
						Prefix="$"
						MaxLength={15}
						Value={list[index].FeeAmount}
						ValueChanged={this.ValueChanged} />
				</GridItem>
			);
			tempArray.push(
				<GridItem key={index + 2} xs={2}>
					<GenericNumberInput
						Name={"Cost" + index}
						LabelMd={0}
						Prefix="$"
						MaxLength={15}
						Value={list[index].Cost}
						ValueChanged={this.ValueChanged} />
				</GridItem>
			);
			tempArray.push(
				<GridItem key={index + 3} xs={1}>
					<GenericDateInput
						Name={"ValidFrom" + index}
						LabelMd={0}
						Value={Formatter.FormatDate(list[index].ValidFrom)}
						ValueChanged={this.ValueChanged} />
				</GridItem>
			);
			tempArray.push(
				<GridItem key={index + 4} xs={1}>
					<GenericDateInput
						Name={"ValidTo" + index}
						LabelMd={0}
						Value={Formatter.FormatDate(list[index].ValidTo)}
						ValueChanged={this.ValueChanged} />
				</GridItem>
			);

			feeProfiles.push(tempArray);
			index++;
		});

		index = 0;

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Submit",
							Disabled: Disabled,
							OnClick: () => ExecuteApiPost("/amlkycapi/v1.0/FeeProfile/Submit", list, null, null, this.Validate, null, true)
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<Card className="no-radius">
					<CardBody>
						<GridContainer>
							<GridItem xs={2} />
							<GridItem xs={2}>
								<GenericLabel
									Bold
									Text="Fee Amount" />
							</GridItem>
							<GridItem xs={2}>
								<GenericLabel
									Bold
									Text="Cost" />
							</GridItem>
							<GridItem xs={1}>
								<GenericLabel
									Bold
									Text="Valid From" />
							</GridItem>
							<GridItem xs={1}>
								<GenericLabel
									Bold
									Text="Valid To" />
							</GridItem>
						</GridContainer>
						{
							feeProfiles.map(x => {
								index++;
								return (
									<GridContainer key={index}>
										{
											x
										}
									</GridContainer>
								)
							})
						}
					</CardBody>
				</Card>
			</div>
		);
	}
}

FeeProfile.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiGet: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default FeeProfile;