import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import { DeleteIcon, EditIcon } from "core/Icons";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericAlert, GenericGrid, GenericSelectInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";



const initialState = {
	documentModel: {},
	isLoading: false,
	ReadOnly: false,
	vModel: {}
};
class InternalScoreCardVerification extends Component {
	constructor(props) {
		super(props);
		this.state = initialState;
		this.DocumentTypeParameterCode = { ParameterCode: "LOSDocumentType" };
		this.DocumentMandatoryStatusParameterCode = { ParameterCode: "LOSMandatoryStatus" };

		this.ColumnsData = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							Disabled={this.props.Disabled}
							tooltip="Edit"
							Icon={EditIcon}
							OnClick={() => this.setState({ documentModel: { ...row.original } })} />
						<GridButton
							Disabled={this.props.Disabled}
							tooltip="Delete"
							Icon={DeleteIcon}
							OnClick={() => this.showQuestionMessage("Delete this record !", row.original)} />
					</div>
				),
				sortable: false,
				filterable: false,
				width: 100
			},
			{
				Header: "Document Type",
				accessor: "DocumentType.ParameterDesc"
			},
			{
				Header: "Mandatory Status",
				accessor: "MandatoryStatus.ParameterDesc"
			}
		];
	}

	componentDidMount() {
	}

	showQuestionMessage = (message, model) => {
		this.setState({
			alert: <GenericAlert Title="Delete" Message={message} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.DeleteModel(model)} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}
	ErrorCallback = (error) => {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", "An error occurred during the api visit" + error);
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: null })} OnConfirm={onConfirm} />
		});
	}

	HideAlert = () => {
		this.setState({ alert: null });
	}

	RefreshToolBar = () => {
		this.setState({ LimitToken: !this.state.LimitToken });
	}
	DeleteModel = (model) => {
		if (model == null || model.Id == null || model.Id == 0) {
			this.ShowMessage("warning", "Warning", "Document must be selected for delete operation.");
			return;
		}
		this.setState({ isLoading: true });
		Proxy.POST(
			"/losapi/v1.0/LoanScorecardProfileDocument/Delete",
			{ Id: model.Id },
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessage("success", "Success", "Document Deleted");
				this.ClearData();
				this.props.GetLoanScorecardDocumentModels();
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	ClearData = () => {
		var model = { Id: 0 };
		this.setState({ documentModel: model });
	}
	SelectedRowChange = (e, index) => {
		const model = this.props.LoanScorecardDocumentList[index];
		this.setState({ documentModel: model || {} });
	}
	handleChange = (name, newValue, data) => {
		this.setState(function (state, props) {
			var model = state.documentModel || {};
			model[name] = newValue;
			return { documentModel: model };
		});
	}
	OperationCheck = () => {

		var ErrorList = [];
		const data = this.state.documentModel;

		const { vModel } = this.state;

		if (data == null) {
			ErrorList.push("Document model not defined.");
			this.ShowMessageNode("warning", "Please Fill Required Fields", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		if (data.DocumentTypeId == null || data.DocumentTypeId == "") {
			ErrorList.push("Document type must be defined.");
			vModel.DocumentTypeId = true;
		} else {
			vModel.DocumentTypeId = false;
		}
		if (data.MandatoryStatusId == null || data.MandatoryStatusId == "") {
			ErrorList.push("Document mandatory status must be defined.");
			vModel.MandatoryStatusId = true;
		} else {
			vModel.MandatoryStatusId = false;
		}
		this.setState({ vModel });
		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please Fill Required Fields", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.UpdateModel();
	}

	UpdateModel = () => {
		var { LoanScorecardProfileModel } = this.props;
		var temp = this.state.documentModel;
		temp.LoanScorecardProfileId = LoanScorecardProfileModel.Id;
		this.setState({ isLoading: true });

		Proxy.POST(
			"/losapi/v1.0/LoanScorecardProfileDocument/InsertUpdate",
			temp,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ isLoading: false });
				this.props.GetLoanScorecardDocumentModels();

			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);


	}
	render() {
		const { isLoading, documentModel, alert, vModel } = this.state;

		const { LoanScorecardDocumentList, Disabled } = this.props;
		return (
			<div>

				<LoadingComponent Show={isLoading} />

				<GridContainer>
					{alert}
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<div style={{ marginLeft: 20 }}>
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												Name="DocumentTypeId"
												LabelText="Document"
												Method="POST"
												Url="/coreapi/v1.0/Parameter/Search"
												Parameter={this.DocumentTypeParameterCode}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ParameterDesc"
												Value={documentModel.DocumentTypeId}
												ValueChanged={this.handleChange}
												Disabled={Disabled}
												Required={true}
												IsInvalid={vModel.DocumentTypeId}
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericSelectInput
												Name="MandatoryStatusId"
												LabelText="Requirement Type"
												Method="POST"
												Url="/coreapi/v1.0/Parameter/Search"
												Parameter={this.DocumentMandatoryStatusParameterCode}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ParameterDesc"
												Value={documentModel.MandatoryStatusId}
												ValueChanged={this.handleChange}
												Disabled={Disabled}
												Required={true}
												IsInvalid={vModel.MandatoryStatusId}
											/>
										</GridItem>
										<GridItem xs={4}>
											<Button size="sm" color="black" disabled={Disabled} onClick={() => this.OperationCheck()}>{documentModel.Id > 0 ? "Update" : "Add"}</Button>
											<Button disabled={Disabled} size="sm" color="black" onClick={() => this.ClearData()}>	Clear	</Button>

										</GridItem>
									</GridContainer>
								</div>
							</CardBody>
						</Card>

						<Card className="no-radius">
							<CardBody>
								<GridItem xs={12}>
									<GridItem>
										<GenericGrid
											Data={LoanScorecardDocumentList}
											PageSize={5}
											HideButton={true}
											Columns={this.ColumnsData}
											RowSelected={index => {
												this.SelectedRowChange(0, index);
											}}
										/>
									</GridItem>
								</GridItem>
							</CardBody>
						</Card>
						{/* <Card className="no-radius">
							<CardHeader >
								<h6><b>Available Verification Channels for this profile</b></h6>
							</CardHeader>
							<CardBody>
								<GenericCheckInput
									Disabled={Disabled}
									Name="HasInternalBlacklist"
									LabelText="Internal Blacklist"
									Value={this.props.LoanScorecardProfileModel.HasInternalBlacklist}
									ValueChanged={this.props.HandleChange}
								/>
								<GenericCheckInput
									Disabled={Disabled}
									Name="HasFinscanWatchList"
									LabelText="Finscan Watch List"
									Value={this.props.LoanScorecardProfileModel.HasFinscanWatchList}
									ValueChanged={this.props.HandleChange}
								/>
								<GenericCheckInput
									Disabled={Disabled}
									Name="HasTransUnionCreditFile"
									LabelText="TransUnion Credit File"
									Value={this.props.LoanScorecardProfileModel.HasTransUnionCreditFile}
									ValueChanged={this.props.HandleChange}
								/>
								<GenericCheckInput
									Disabled={Disabled}
									Name="HasTransUnionIDVerification"
									LabelText="TransUnion ID Verification"
									Value={this.props.LoanScorecardProfileModel.HasTransUnionIDVerification}
									ValueChanged={this.props.HandleChange}
								/>
								<GenericCheckInput
									Disabled={Disabled}
									Name="HasFlinksAccountVerification"
									LabelText="Flinks-Account Verification"
									Value={this.props.LoanScorecardProfileModel.HasFlinksAccountVerification}
									ValueChanged={this.props.HandleChange}
								/>
								<GenericCheckInput
									Disabled={Disabled}
									Name="HasFlinksCashFlowValidation"
									LabelText="Flinks Cash Flow Validation"
									Value={this.props.LoanScorecardProfileModel.HasFlinksCashFlowValidation}
									ValueChanged={this.props.HandleChange}
								/>
								<GenericCheckInput
									Disabled={Disabled}
									Name="HasCanadaPostIdVerification"
									LabelText="Canada Post Id Verification"
									Value={this.props.LoanScorecardProfileModel.HasCanadaPostIdVerification}
									ValueChanged={this.props.HandleChange}
								/>
							</CardBody>
						</Card> */}
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

InternalScoreCardVerification.propTypes = {
	GetLoanScorecardDocumentModels: PropTypes.func,
	LoanScorecardDocumentList: PropTypes.array,
	HandleDocumentAdd: PropTypes.func,
	Disabled: PropTypes.bool,
	LoanScorecardProfileModel: PropTypes.object
};

export default withArtifex(InternalScoreCardVerification, {});