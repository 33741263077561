import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { Proxy } from "core";
import { GenericTitle, GenericTextInput, GenericSelectInput, GenericAlert } from "views/Components/Generic";
import CardHeader from "components/Card/CardHeader";

class CorporateAccountDetail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			model: {},
			IsCustomer: 0
		};
		this.GetProfileInfo = this.GetProfileInfo.bind(this);
		this.handleCustomerProfileInfo = this.handleCustomerProfileInfo.bind(this);

		this.ShowMessage = this.ShowMessage.bind(this);
		this.hideAlert = this.hideAlert.bind(this);
		this.errorDefinitionCallback = this.errorDefinitionCallback.bind(this);
	}

	componentDidMount() {
		this.GetProfileInfo();
	}
	GetProfileInfo() {
		//GetCustomerBankAccountsOp class
		Proxy.POST(
			"/bankapi/v1.0/BankCustomer/GetProfileInfo",
			{},
			this.handleCustomerProfileInfo,
			this.errorDefinitionCallback
		);
	}
	handleCustomerProfileInfo(responseData) {
		console.log("customerAccountProfile", responseData.IsSucceeded + "");
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error !", responseData.ErrorDescription);
			return;
		} else {
			if (responseData.Item != null) {
				if (!responseData.Item.IsCustomer) {
					this.setState({
						model: responseData.Item.Corporate || {},
						IsCustomer: 2
					});
				}
			}

		}
	}

	errorDefinitionCallback(error) {
		this.ShowMessage("error", "Error !", "An error occurred while requesting data" + error);
	}
	ShowMessage(type, title, message) {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
	}
	hideAlert() {
		this.setState({
			alert: null
		});
	}
	render() {
		const { model } = this.state;
		return (
			<Card>
				{this.state.alert}
				<CardHeader> 
					<GenericTitle text={" General Information"}/>
				</CardHeader>
				<CardBody>
					<GridContainer spacing={16}>
						<GridItem xs={12} sm={6} md={6} lg={6}>
							<GridContainer justify="flex-start">
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<GenericTextInput Name="Explanation" LabelText="Legal Name of Business" Value={model.LegalName || ""} Disabled={true} />
									<GenericTextInput Name="Explanation" LabelText="Business ID Nr. (BIN)" Value={model.BusinessIdNumber || ""} Disabled={true} />
									<GenericTextInput Name="Explanation" LabelText="Quebec Enterp. Nr (NEQ)" Value={model.QuebecEntNumber || ""} Disabled={true} />
									<GenericSelectInput
										Name="IncorporationCountryId"
										LabelText="Country of Legal Formation"
										Value={model.IncorporationCountryId || ""}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember={"Name"}
										Method="POST" Url="/coreapi/v1.0/Country/GetAll"
										Parameter={{}}
										Disabled={true}
										Sorted={{ Member: "Name" }}
									/>
									<GenericSelectInput Name="AccountStatusId" LabelText="Wallet Status" Value={model.AccountStatusId || ""}
										DataRoot="Item" KeyValueMember="Id" TextValueMember="ParameterDesc"
										Method="POST" Url="/coreapi/v1.0/Parameter/Search" Parameter={{ ParameterCode: "AccountStatus" }} Disabled={true} />

								</GridItem>
							</GridContainer>
						</GridItem>
						<GridItem xs={12} sm={6} md={6} lg={6}>
							<h5><b> Communication</b></h5>
							<GridContainer justify="flex-start">
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<GenericTextInput LabelText="Address Line 1" Value={model.CustomerAddress == null ? "" : model.CustomerAddress.Address1} Disabled={true} />
									<GenericTextInput LabelText="Address Line 2" Value={model.CustomerAddress == null ? "" : model.CustomerAddress.Address2} Disabled={true} />
									<GenericTextInput LabelText="Province" Value={model.CustomerAddress == null ? "" : model.CustomerAddress.Provience} Disabled={true} />
									<GenericTextInput LabelText="City" Value={model.CustomerAddress == null ? "" : model.CustomerAddress.City == null ? "" : model.CustomerAddress.City.ParameterDesc || ""} Disabled={true} />
									<GenericTextInput LabelText="Postal Code" Value={model.CustomerAddress == null ? "" : model.CustomerAddress.ZipCode} Disabled={true} />
									<GenericTextInput LabelText="E Mail" Value={model.Email || ""} Disabled={true} />
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<GridContainer>
										<GridItem xs={3} sm={3} md={3}>
											<GenericSelectInput
												Name="PhoneCountryCodeId"
												LabelText="Code"
												TitleLabel="Country Code"
												LabelMd={6}
												Method="POST"
												Url="/coreapi/v1.0/Country/GetAll"
												Parameter={{}}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember={"Code"}
												Value={model.BusinessPhone == null ? "" : model.BusinessPhone.PhoneCountryCodeId || ""}
												Disabled={true}
												Sorted={{ Member: "OrderIndex" }}
												RenderItem={d => "+" + d.Code + " (" + d.Name + ")"}
											/>
										</GridItem>
										<GridItem xs={9} sm={9} md={9}>
											<GenericTextInput IsPhone Name="PhoneNumber" LabelText="Business Phone" Value={model.BusinessPhone == null ? "" : model.BusinessPhone.PhoneNumber || ""} Disabled={true} />
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem xs={3} sm={3} md={3}>
											<GenericSelectInput
												Name="PhoneCountryCodeId"
												LabelText="Code"
												TitleLabel="Country Code"
												LabelMd={6}
												Method="POST"
												Url="/coreapi/v1.0/Country/GetAll"
												Parameter={{}}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember={"Code"}
												Value={model.FaxPhone == null ? "" : model.FaxPhone.PhoneCountryCodeId || ""}
												Disabled={true}
												Sorted={{ Member: "OrderIndex" }}
												RenderItem={d => "+" + d.Code + " (" + d.Name + ")"}
											/>
										</GridItem>
										<GridItem xs={9} sm={9} md={9}>
											<GenericTextInput IsPhone Name="PhoneNumber" LabelText="Fax Phone" Value={model.FaxPhone == null ? "" : model.FaxPhone.PhoneNumber || ""} Disabled={true} />
										</GridItem>
									</GridContainer>
									<GenericSelectInput Name="LanguageId" LabelText="Preferred Language" Value={model.PreferedLanguageId || ""}
										KeyValueMember="Id" TextValueMember="ParameterDesc" Method="POST" Url="/coreapi/v1.0/Parameter/Search" Parameter={{ ParameterCode: "Language", ParameterValue: "", ParameterDesc: "" }}
										DataRoot="Item" Disabled={true} />
									<GenericSelectInput Name="SendSms" LabelText="Text/SMS Notification" Value={model.SendSms == undefined ? "" : String(model.SendSms)}
										KeyValueMember="ParameterValue" TextValueMember="ParameterDesc" Method="POST" Url="/coreapi/v1.0/Parameter/Search"
										Parameter={{ ParameterCode: "YesNo", ParameterValue: "", ParameterDesc: "" }} DataRoot="Item" Disabled={true} />
									<GenericSelectInput Name="SendEmail" LabelText="E-Mail Notification" Value={model.SendEmail == undefined ? "" : String(model.SendEmail)}
										KeyValueMember="ParameterValue" TextValueMember="ParameterDesc" Method="POST" Url="/coreapi/v1.0/Parameter/Search"
										Parameter={{ ParameterCode: "YesNo", ParameterValue: "", ParameterDesc: "" }} DataRoot="Item" Disabled={true} />
								</GridItem>
							</GridContainer>
						</GridItem>
					</GridContainer>
				</CardBody>
			</Card>
		);
	}
}

CorporateAccountDetail.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	model: PropTypes.object
};

export default CorporateAccountDetail;