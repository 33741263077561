import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import {  GenericExpansionPanel,  GenericGrid,  GenericDateInput,  GenericNumberInput,  GenericSelectInput,} from "views/Components/Generic";
import DateHelper from "core/DateHelper";
import { MenuCodes } from "views/Constants/Constant.js";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType } from "views/Constants/Constant";
import ClientHelper from "core/ClientHelper";


class InteracUrl extends React.Component {
  constructor(props) {
    super(props);
    let IsSwitchReport = this.props.MenuCode == MenuCodes.Menu_SwitchReport;
    this.emptyObject = {};
    this.sortedName = { Member: "Name" };
    this.initialModel = {
      Id: 0,
      UniqueClientId: ClientHelper.IsClient()
        ? ClientHelper.GetClientRowId()
        : undefined,
      Customer: null,
      DefaultAccount: null,
      PaymentStatusId: null,
      ToDate: null,
      FromDate: null,
      CardTokenNumber: null,
      CustomerNumber: null,
      ReferenceNumber: null,
    };
    this.isClient = ClientHelper.IsClient();
    this.parameterUniqueClientId = {
      UniqueClientId: this.isClient ? ClientHelper.GetClientId() : undefined,
    };

    this.columns = [
      {
        Header: "Url",
        accessor: "Url",
        width: 500,
      },
      {
        Header: "Amount",
        accessor: "TransactionAmount",
        type: GridColumnType.Money,
        ColumnType: GridColumnType.Money,
      },
      {
        Header: "Contact Name",
        accessor: "ContactName",
      },
		{
			Header: "Customer Number",
			accessor: "ClientCustomerNumber",
		 },
      {
        Header: "Transaction Status",
        accessor: "TransactionStatus",
      },
      {
        Header: "Reference Number",
        accessor: "ReferenceNumber",
      },
      {
        Header: "Transaction Type",
        accessor: "TransactionType",
      },
      {
        Header: "Interac Referans Number",
        accessor: "InteracReferansNumber",
      },
      {
        Header: "Transaction Date",
        accessor: "TransactionDate",
        type: GridColumnType.DateUtc,
      },
    ];

    this.state = {
      // model: {},
      model: this.initialModel,
      isLoading: false,
      checkedAccounts: [],
      ClientTypeParameter: {},
      panelTransactionIsActive: false,
      list: [],
      isBackOffice: ClientHelper.IsBackOffice(),
    };
  }

  componentDidMount() {
    const { setAppCenterTitle, setAppLeftTitle } = this.props;

    if (setAppCenterTitle) {
      setAppCenterTitle("LOAN MANAGEMENT SYSTEM");
    }
    if (setAppLeftTitle) {
      setAppLeftTitle("Interac Url");
    }
  }

  ValueChanged = (name, value) => {
    const model = { ...this.state.model };
    model[name] = value;

    this.setState({ model });
  };

  RenderItemUniqueClient = (d) => {
    this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
    return this.renderItemUniqueClient;
  };

  Search = async () => {
    if (!this.Validate()) return;
    const { ExecuteApiPost } = this.props;
    const model = { ...this.state.model };
    const request = {
      FromDate: model.FromDate,
      ToDate: model.ToDate,
      CustomerNumber:
        model.Customer == null ? null : model.Customer.CustomerNumber,
      UniqueClientId: model.UniqueClientId,
      ReferenceNumber: model.ReferenceNumber,
    };
    var result = await ExecuteApiPost(
      "/bankapi/v1.0/Transaction/InteracUrlApi",
      request,
      null,
      null,
      null
    );

    if (result != null) {
      this.setState({ list: result || [] });
    }
  };

  Clear = () => {
    this.setState({ model: {}, list: [], selected: null, checkedAccounts: [] });
  };

  Validate = () => {
    var { model } = this.state;
    const { showValidationErrors } = this.props;
    var errors = [];
    if (errors.length > 0) {
      showValidationErrors(errors);

      return false;
    }

    return true;
  };

  GetRenderItemUniqueClient = (x) => {
    this.renderItemUniqueClient = `${x.Name} - ${x.UniqueClientId}`;
    return this.renderItemUniqueClient;
  };

  handleChange = (name, newValue, data) => {
    const model = {
      ...this.state.model,
    };
    model[name] = newValue;
    this.setState({ model });
  };
  handleChangeForCustomer = (name, newValue) => {
    const model = { ...this.state.model };
    const initialModel = { ...this.initialModel };
    if (name === "Customer") {
      if (newValue != null) {
        model.Customer = newValue;
        if (newValue.Accounts != null && newValue.Accounts.length > 0) {
          model.CustomerNumber = newValue.Accounts[0].CustomerNumber;
        } else {
          model.CustomerNumber = null;
        }
      } else {
        model.Customer = initialModel.Customer;
      }
    }
    this.setState({ model });
  };
  HandleClear = () => {
    this.setState({
      model: this.initialModel,
      filterModel: {},
      selected: null,
      list: [],
    });
  };

  FillCustomer = (customer) => {
    this.handleChange("Customer", customer);
  };
  render() {
    const { model, model2, list, panelTransactionIsActive, isBackOffice } =
      this.state;
    const { Disabled } = this.props;

    return (
      <div>
        <ButtonToolbar
          ButtonList={[
            {
              Code: "Search",
              Disabled: Disabled,
              OnClick: this.Search,
            },
            {
              Code: "Clear",
              Disabled: Disabled,
              OnClick: this.HandleClear,
            },
          ]}
          menuId={this.props.menuId}
          ApprovalData={this.props.ApprovalData}
          After={this.props.After}
        />
        <LoadingComponent Show={this.state.isLoading} />

        <GridContainer spacing={16}>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <GenericExpansionPanel Title="Filter">
                  <GridContainer>
                    <GridItem xs={2}>
                      <GenericSelectInput
                        Name="UniqueClientId"
                        LabelText="Client"
                        Method="POST"
                        Url="/bankapi/v1.0/BankCustomer/Search"
                        Parameter={this.parameterUniqueClientId}
                        DataRoot="Item"
                        KeyValueMember="Id"
                        RenderItem={this.RenderItemUniqueClient}
                        Value={model.UniqueClientId}
                        ValueChanged={this.handleChange}
                        CanClear
                        All
                        Disabled={this.isClient}
                        DefaultIndex={this.isClient ? 0 : -1}
                        Required
                      />
                    </GridItem>
                    <GridItem xs={2}>
                      <GenericDateInput
                        Name="FromDate"
                        LabelText="From Date"
                        Value={
                          model.FromDate
                            ? DateHelper.ToDateInputValueAsUtc(model.FromDate)
                            : ""
                        }
                        ValueChanged={this.ValueChanged}
                        MaxDate={model.ToDate || DateHelper.GetDate()}
                        Disabled={Disabled}
                        IncludeTime={false}
                        Utc
                        Required
                      />
                    </GridItem>

                    <GridItem xs={2}>
                      <GenericDateInput
                        Name="ToDate"
                        LabelText="To Date"
                        Value={
                          model.ToDate
                            ? DateHelper.ToDateInputValueAsUtc(model.ToDate)
                            : ""
                        }
                        ValueChanged={this.ValueChanged}
                        MinDate={model.FromDate}
                        MaxDate={DateHelper.GetDate()}
                        IncludeTime={false}
                        Utc
                        Required
                      />
                    </GridItem>
                    <GridItem xs={3}>
                      <GenericNumberInput
                        Name="ReferenceNumber"
                        LabelText="Reference Number"
                        Value={model.ReferenceNumber}
                        ValueChanged={this.handleChange}
                        //Required
                        IsInvalid={model.ReferenceNumber}
                        NoFormatting
                        Disabled={Disabled}
                      />
                    </GridItem>
                  </GridContainer>
                </GenericExpansionPanel>
              </CardBody>
            </Card>

            <Card className="no-radius">
              <CardBody>
                <GenericExpansionPanel IsActive Title="Interac Url">
                  <GridContainer>
                    <GridItem xs={12}>
                      <GridContainer spacing={16}>
                        <GridItem xs={12}>
                          <GenericGrid
                            Data={list}
                            Columns={this.columns}
                            ShowPagination={true}
                            PageSize={100}
                            IsSorted={false}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </GenericExpansionPanel>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

InteracUrl.propTypes = {
  setAppLeftTitle: PropTypes.func,
  setAppCenterTitle: PropTypes.func,
  ExecuteApiPost: PropTypes.func,
  Disabled: PropTypes.bool,
  menuId: PropTypes.any,
  ApprovalData: PropTypes.any,
  After: PropTypes.any,
};

export default InteracUrl;
