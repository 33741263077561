import PropTypes from "prop-types";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import React, { Component } from 'react';
import { GenericExpansionPanel, GenericNumberInput, GenericTextInput } from "views/Components/Generic";

class FlinksAccountPreviewCard extends Component {

	GetBody = () => {
		const { Model } = this.props;
		var flinksAccountModel = this.FlinksDataModelParse(Model || {});
		return (
			<>
				<GridItem xs={4}>
					<GenericTextInput
						Disabled={true}
						LabelText="Title"
						Value={flinksAccountModel.Title || ""} />
					<GenericTextInput
						Disabled={true}
						LabelText="Wallet Number"
						Value={flinksAccountModel.AccountNumber || ""} />
					<GenericNumberInput
						Disabled={true}
						LabelText="Balance"
						Prefix="$ "
						Value={flinksAccountModel.CurrentBalance} />
				</GridItem>
				<GridItem xs={4}>
					<GenericTextInput
						Disabled={true}
						LabelText="Category"
						Value={flinksAccountModel.Category || ""} />
					<GenericTextInput
						Disabled={true}
						LabelText="Currency"
						Value={flinksAccountModel.Currency || ""} />
					<GenericTextInput
						Disabled={true}
						LabelText="Name"
						Value={flinksAccountModel.Name || ""} />
				</GridItem>
				<GridItem xs={4}>
					<GenericTextInput
						Disabled={true}
						LabelText="Email"
						Value={flinksAccountModel.Email || ""} />
					<GenericTextInput
						Disabled={true}
						LabelText="Phone Number"
						Value={flinksAccountModel.PhoneNumber || ""} />
					<GenericTextInput
						Disabled={true}
						LabelText="Address"
						Value={`${flinksAccountModel.CivicAddress || ""} ${flinksAccountModel.PostalCode || ""} ${flinksAccountModel.City || ""} ${flinksAccountModel.Country || ""}`} />
				</GridItem>
			</>
		);
	}
	FlinksDataModelParse = (model) => {
		var temp = {};
		const flinks = { ...model };

		temp.HttpStatusCode = flinks.HttpStatusCode;
		temp.Institution = flinks.Institution;
		temp.RequestId = flinks.RequestId;  // RequestId
		temp.Tag = flinks.Tag;

		temp.Username = flinks.Login?.Username ?? flinks.Username;
		temp.LastRefresh = flinks.Login?.LastRefresh ?? flinks.LastRefresh;
		temp.Type = flinks.Login?.Type ?? flinks.Type;
		temp.LoginId = flinks.Login?.Id ?? flinks.LoginId; // LoginId


		temp.Title = flinks.Title;
		temp.AccountNumber = flinks.AccountNumber ?? flinks.AccountNumber;
		temp.Category = flinks.Category ?? flinks.Category;
		temp.Currency = flinks.Currency ?? flinks.Currency;
		temp.AccountId = flinks.Id ?? flinks.AccountId;  // AccountId
		temp.TransitNumber = flinks.TransitNumber;
		temp.InstitutionNumber = flinks.InstitutionNumber; 

		temp.AvailableBalance = flinks.Balance?.Available ?? flinks.AvailableBalance;
		temp.CurrentBalance = flinks.Balance?.Current ?? flinks.CurrentBalance;
		temp.Limit = flinks.Balance?.Limit ?? flinks.Limit;

		temp.Name = flinks.Holder?.Name ?? flinks.Name;
		temp.Email = flinks.Holder?.Email ?? flinks.Email;
		temp.PhoneNumber = flinks.Holder?.PhoneNumber ?? flinks.PhoneNumber;
		temp.CivicAddress = flinks.Holder?.Address?.CivicAddress ?? flinks.CivicAddress;
		temp.City = flinks.Holder?.Address?.City ?? flinks.City;
		temp.Province = flinks.Holder?.Address?.Province ?? flinks.Province;
		temp.PostalCode = flinks.Holder?.Address?.PostalCode ?? flinks.PostalCode;
		temp.Pobox = flinks.Holder?.Address?.POBox ?? flinks.Pobox;
		temp.Country = flinks.Holder?.Address?.Country ?? flinks.Country;
		return temp;
	}
	render() {
		const { OnClickButton, Title, ButtonText, GenericExpansionPanelProps, Top, Bottom } = this.props;


		return (
			<Card className="no-radius">
				<CardBody>
					<GenericExpansionPanel Title={Title} {...GenericExpansionPanelProps}>
						<GridContainer>
							{Top}
							{this.GetBody()}
							{Bottom}
							<GridItem xs={12}>
								{
									(!Bottom && OnClickButton) &&
									<GridContainer justify="flex-end">
										<GridItem>
											<Button size="sm" onClick={OnClickButton}>{ButtonText}</Button>
										</GridItem>
									</GridContainer>
								}
							</GridItem>
						</GridContainer>
					</GenericExpansionPanel>
				</CardBody >
			</Card >
		)
	}
}


FlinksAccountPreviewCard.defaultProps = {
	ButtonText: "Connect",
	Title: "Linked Wallet Details",
};
FlinksAccountPreviewCard.propTypes = {
	Model: PropTypes.any.isRequired,
	OnClickButton: PropTypes.any,
	ButtonText: PropTypes.string,
	Title: PropTypes.string,
	GenericExpansionPanelProps: PropTypes.any,
	Top: PropTypes.any,
	Bottom: PropTypes.any,
};
export default FlinksAccountPreviewCard;