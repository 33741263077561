import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import CustomerComponent from "views/Components/CustomerComponent";
import { GenericDateInput, GenericExpansionPanel, GenericGrid, GenericSelectInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType, ProgramCodes } from "views/Constants/Constant.js";

class CardStatusReport extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			UniqueClientId: null,
			Customer: null,
			DefaultAccount: null,
			DefaultCard: null,
			FilterBy: null
		};
		const IsBackOffice = ClientHelper.IsBackOffice();

		this.initialDialogModel = {
			IsDialogOpen: false,
			SelectedCard: null
		};

		this.columns = [
			{
				Header: "Card Create Date",
				accessor: "CardCreateDate",
				type: GridColumnType.DateTime,
				ColumnType: GridColumnType.DateTime
			},
			{
				Header: "Cardholder Name",
				accessor: "CardEmbossName"
			},
			{
				Header: "Masked Card Number",
				accessor: "MaskedCardNumber"
			},
			{
				Header: "Card Status",
				accessor: "StatusDescription"
			},
			{
				Header: "Client",
				accessor: "ClientName",
				show: IsBackOffice
			},
			{
				Header: "Customer Name",
				accessor: "CustomerName"
			},
			{
				Header: "Available Balance",
				accessor: "AvailableBalance",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Updated By",
				accessor: "UpdateUser"
			},
			{
				Header: "Last Transaction Date",
				accessor: "LastTransactionDate",
				type: GridColumnType.DateTime,
				ColumnType: GridColumnType.DateTime
			},

			{
				Header: "Card Status Change Date",
				accessor: "CardStatusChangeDate",
				type: GridColumnType.DateTime,
				ColumnType: GridColumnType.DateTime
			}
		];

		this.state = {
			list: [],
			model: this.initialModel,
			lastModel: this.initialModel,
			dialogModel: this.initialDialogModel,
			isLoading: false
		};
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Card Status Report");
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
	}


	handleChange = async (name, newValue, data) => {
		const model = { ...this.state.model };
		const dialogModel = { ...this.state.dialogModel };
		const initialModel = { ...this.initialModel };
		const { ExecuteApiPost } = this.props;
		if (name === "Customer") {
			if (newValue != null) {
				model.Customer = newValue;
				if (newValue.Accounts != null && newValue.Accounts.length > 0) {
					model.DefaultAccount = newValue.Accounts[0];
				}
				const request = { CardLevelCriteria: 1, CustomerId: newValue.Id };
				var result = await ExecuteApiPost("/prepaidapi/v1.0/Card/GetListByCustomerNo", request);
				if (result != null && result[0] != null) {
					model.DefaultCard = result[0];
				} else {
					model.DefaultCard = null;
				}
				this.setState({ model: model });
			} else {
				model.Customer = initialModel.Customer;
				model.DefaultAccount = initialModel.DefaultAccount;
				model.DefaultCard = initialModel.DefaultCard;
			}
		} else if (name === "UniqueClientId") {
			if (
				ClientHelper.IsClient() &&
				initialModel.UniqueClientId == null &&
				newValue != null
			) {
				this.initialModel.UniqueClientId = newValue;
			}
			model.UniqueClientId = newValue;
		} else if (name === "CardStatus") {
			model.FilterBy = data;
		} else if (name === "SelectedCardStatus") {
			dialogModel.SelectedCard.Status = newValue;
		} else if (name === "SelectedCardStatusReason") {
			dialogModel.SelectedCard.StatusReason = newValue;
		}
		else {
			model[name] = newValue;
		}
		this.setState({ model, dialogModel });
	};

	handleClear = () => {
		this.setState({
			model: this.initialModel,
			filterModel: {},
			selected: null,
			list: []
		});
	};



	handleSearch = async () => {

		const { model } = this.state;
		this.setState({ isLoading: true });

		const request = {
			ClientCustomerId: model.Customer === null ? null : model.Customer.Id,
			UniqueClientId: model.UniqueClientId,
			StatusId: model.FilterBy === null ? null : model.FilterBy.Id,
			FromDate: model.FromDate,
			EndDate: model.EndDate
		};

		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/prepaidapi/v1.0/Card/CardStatusReport", request);
		this.setState({ list: result, isLoading: false });

	};

	handleShowDialog = (data, index) => {
		const dialogModel = { ...this.state.dialogModel };
		dialogModel.IsDialogOpen = true;
		dialogModel.SelectedCard = Object.assign({}, data);
		this.setState({ dialogModel });
	};

	handleCancelDialog = () => {
		const dialogModel = { ...this.state.dialogModel };
		dialogModel.IsDialogOpen = false;
		dialogModel.SelectedCard = null;
		this.setState({ dialogModel });
	};

	handleSubmitDialog = () => {
		const dialogModel = { ...this.state.dialogModel };

		if (dialogModel.SelectedCard == null) {
			dialogModel.IsDialogOpen = false;
			dialogModel.SelectedCard = null;
			return;
		}

		Proxy.POST(
			"/prepaidapi/v1.0/Card/UpdateStatus",
			{
				CardId: dialogModel.SelectedCard.CardId,
				CardStatusId: dialogModel.SelectedCard.Status,
			},
			responseData => {
				dialogModel.IsDialogOpen = false;
				dialogModel.SelectedCard = null;

				if (!responseData.IsSucceeded) {
					this.setState({ dialogModel, isLoading: false }, () => {
						this.props.showMessgae("error", "Error", responseData.ErrorDescription);
					});
					return;
				}

				this.setState({ dialogModel, isLoading: false }, () => {
					this.handleSearch();
				});
			},
			error => {
				this.setState({ isLoading: false }, () => {
					this.props.showMessage(
						"error",
						"An error occurred while requesting data",
						error.message
					);
				});
			}
		);
	};

	render() {
		const { Disabled } = this.props;
		const { model,  list, isLoading } = this.state;
	
		return (
			<div>
				<LoadingComponent Show={isLoading} />


				{alert}
				<ButtonToolbar
					ButtonList={[{
						Code: "Search",
						OnClick: this.handleSearch,
						Disabled: Disabled || model.Id > 0
					},
					{ Code: "Clear", OnClick: this.handleClear, Disabled: Disabled }
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After}
				/>
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card>
							<CardBody>
								<GenericExpansionPanel>
									<GridContainer>
										<GridItem xs={4}>
											<GridItem>
												<GenericSelectInput
													Name="UniqueClientId"
													LabelText="Client"
													Method="POST"
													Url="/bankapi/v1.0/BankCustomer/Search"
													Parameter={{
														UniqueClientId: ClientHelper.IsClient()
															? ClientHelper.GetClientId()
															: undefined
													}}
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
													Value={model.UniqueClientId || ""}
													ValueChanged={this.handleChange}
													CanClear
													All
													Disabled={ClientHelper.IsClient()}
													DefaultIndex={ClientHelper.IsClient() ? 0 : -1}
												/>
											</GridItem>
											<GridItem>
												<CustomerComponent
													LabelMd={4}
													CustomerNumber={
														model.Customer === null
															? null
															: model.Customer.CustomerNumber
													}
													UniqueClientId={model.UniqueClientId}
													FillCallback={customer => {
														this.handleChange("Customer", customer);
													}}
													Programs={[ProgramCodes.Prepaid]}
												/>
											</GridItem>
											<GridItem>
												<GenericSelectInput
													Name="CardStatus"
													LabelText="Card Status"
													Method="POST"
													Url="/prepaidapi/v1.0/CardStatus/GetAll"
													Parameter={{ Criteria: 0 }}
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={d => `${d.Description}`}
													Value={model.FilterBy == null ? null : model.FilterBy.Id}
													ValueChanged={this.handleChange}
													Sorted={{Member: "Description"}}
													CanClear />
											</GridItem>
										</GridItem>

										<GridItem xs={4}>
											<GenericDateInput
												Utc
												Name="FromDate"
												LabelText="From Date"
												Value={model.FromDate}
												ValueChanged={this.handleChange}
												MaxDate={model.EndDate || DateHelper.GetDate()}
												IncludeTime={false} />

											<GenericDateInput
												Utc
												Name="EndDate"
												LabelText="End Date"
												Value={model.EndDate}
												ValueChanged={this.handleChange}
												MinDate={model.StartDate}
												MaxDate={DateHelper.GetDate()}
											/>
										</GridItem>

									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card>
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columns}
													ProgramCode={ProgramCodes.Prepaid}
													ShowPagination={true}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

CardStatusReport.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	handleChange: PropTypes.func,
	menuId: PropTypes.number,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default withArtifex(CardStatusReport, {});
