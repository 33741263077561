import style from "assets/jss/material-dashboard-pro-react/views/createWireTransferStyle.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericDateInput, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
class BeneficiaryAmountDetail extends Component {
	constructor(props) {
		super(props);
		this.MaxLen = { maxLength: 60 };
	}

	render() {
		const { Disabled, AmountPrefix, ValueChanged, TemplateValueChanged, DateRequest, ValueEffective, Amount, CurrencyId, PurposeOfWire } = this.props;
		return (
			<GridItem>
				<h4 style={{ color: "black", textAlign: "center" }}>
					<b>Transfer Detail</b>
				</h4>
				<GridContainer >
					<GridItem xs={6}>
						<GenericDateInput
							Name="DateRequest"
							LabelText="Date Request"
							Value={DateRequest}
							ValueChanged={ValueChanged}
							MinDate={DateHelper.GetDate()}
							Disabled={true} />
					</GridItem>
					<GridItem xs={6}>
						<GenericDateInput
							Name="ValueEffective"
							LabelText="Value Effective"
							Value={ValueEffective}
							MinDate={DateRequest}
							Disabled={true} />
					</GridItem>
					<GridItem xs={6}>
						<GenericNumberInput
							Name="Amount"
							LabelText="Amount"
							Value={Amount}
							ValueChanged={ValueChanged}
							Disabled={Disabled}
							Prefix={AmountPrefix}
							MaxLength={10} />
					</GridItem>
					<GridItem xs={6}>
						<GenericSelectInput
							Disabled={true}
							Name="CurrencyId"
							LabelText="Currency"
							Value={CurrencyId || ""}
							KeyValueMember="Id"
							TextValueMember="Description"
							Method="GET"
							Url="/bankapi/v1.0/Currency/ValidCurrencies"
							DefaultIndex={0}
							RenderItem={d => d.Code + " - " + d.Description}
							ValueChanged={TemplateValueChanged}
							DataRoot="Item" />
					</GridItem>
					<GridItem xs={12}>
						<GenericTextInput
							Name="PurposeOfWire"
							LabelText="Purpose of Wire"
							Value={PurposeOfWire}
							ValueChanged={ValueChanged}
							LabelMd={2}
							Disabled={Disabled}
							inputProps={{ maxLength: 60 }} />
					</GridItem>
				</GridContainer>
			</GridItem>
		);
	}
}

BeneficiaryAmountDetail.propTypes = {
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool,
	ValueChanged: PropTypes.func,
	TemplateValueChanged: PropTypes.func,
	AmountPrefix: PropTypes.string,
	PurposeOfWire: PropTypes.string,
	CurrencyId: PropTypes.number,
	Amount: PropTypes.number,
	DateRequest: PropTypes.any,
	ValueEffective: PropTypes.any,
};

export default withArtifex(BeneficiaryAmountDetail, style);


