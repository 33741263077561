import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import { Proxy, withArtifex } from "core";

class EmptyFile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {

		};
	}

	render() {
		const { classes } = this.props;
		const { model } = this.state;
		return (
			<GridContainer spacing={16}>
				<GridItem md={12} xs={12}>

				</GridItem>
			</GridContainer>
		);
	}
}

EmptyFile.propTypes = {
	classes: PropTypes.object
};

export default withArtifex(EmptyFile, {});