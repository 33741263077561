import { primaryColor } from "assets/jss/material-dashboard-pro-react.jsx";

const menuBarStyle = {
	menu: {
		cursor: "pointer",
		fontWeight: "bold",
		textTransform: "UPPERCASE",
		borderTop: "4px solid white",
		padding: "10px 40px 5px 40px",
		"& > li": {
			background: primaryColor,
			color: "white"
		},
		"& > li:hover": {
			background: "black",
			color: "white",
			borderBottom: "2px solid transparent"
		}
	},
	subMenu: {
		borderLeft: "1px solid gray",
		borderRight: "1px solid gray",
		borderTop: "2px solid gray",
		borderBottom: "2px solid gray",
		"& > div:hover": {
			background: "black",
			color: "white"
		}
	},
	menuItem: {
		borderLeft: "1px solid white",
		borderRight: "1px solid white",
		"&:hover": {
			background: "black",
			color: "white",
			borderBottom: "2px solid transparent"
		}
	},
	popupMenu: {
		zIndex: "9999999",
		"& > ul li": {
			background: "black",
			color: "white",
			cursor: "pointer",
			border: "1px solid"
		},
		"& > ul li:hover": {
			background: primaryColor,
			color: "white"
		}
	}
};

export default menuBarStyle;
