const genericNumberInputStyle = {
	button: {
		cursor: "pointer",
		"&:hover": {
			background: "rgba(0, 0, 0, 0.15)"
		},
		borderRadius: "50%"
	}
};

export default genericNumberInputStyle;