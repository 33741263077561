import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import {
	GenericSelectInput,
	GenericDateInput,
	GenericExpansionPanel,
	GenericRadioInput,
	GenericGrid,
	GenericDialog,
	GenericAlert,
	GenericDialogActions,
	GenericLabel,
	GenericTitle,
	GenericNumberInput
} from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType, ProgramCodes } from "views/Constants/Constant";
import Button from "components/CustomButtons/Button.jsx";
import { DialogContent, DialogTitle } from "@material-ui/core";
import Select from "react-select";
import CustomerComponent from "views/Components/CustomerComponent.jsx";
import CardComponent from "views/Components/CardComponent.jsx";

class SimulationCreditCardBucketCloseLog extends Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			UniqueClientId: ClientHelper.IsClient()
				? ClientHelper.GetClientRowId()
				: undefined,
			ProgramCode: "",
			// FromDate: DateHelper.GetLocalDateAsUtc(),
			// ToDate: DateHelper.GetLocalDateAsUtc(),
			ReferenceNumber: null,
		};

		this.state = {
			model: this.initialModel,
			list: [],
			isLoading: false,
			vModel: {},
		};

		this.emptyObject = {};

		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.IsClient()
				? ClientHelper.GetClientId()
				: undefined,
		};

		this.parameterBucketType = { ParameterCode: "BucketType" };

		this.Columns = [
			{
				Header: "Client",
				accessor: "Client",
			},
			{
				Header: "Customer Name",
				accessor: "CustomerName",
			},
			{
				Header: "Customer Number",
				accessor: "CustomerNumber",
			},
			{
				Header: "End of Date",
				accessor: "EodDate",
			},
			{
				Header: "Reference Number",
				accessor: "ReferenceNumber",
			},
			{
				Header: "Transaction Description",
				accessor: "TxnDescription",
			},
			{
				Header: "Transaction Code Id",
				accessor: "TrxCodeId",
			},
			{
				Header: "Transaction Code",
				accessor: "TxnName",
			},
			{
				Header: "Statement Begin Date",
				accessor: "StatementBeginDate",
				type: GridColumnType.Date,
			},
			{
				Header: "Statement End Date",
				accessor: "StatementEndDate",
				type: GridColumnType.Date,
			},
			{
				Header: "Bucket Type",
				accessor: "BucketType.ParameterDesc",
			},
			{
				Header: "Total Payment Amount",
				accessor: "TotalPaymentAmount",
				type: GridColumnType.Money,
			},
			{
				Header: "Total Capital Amount",
				accessor: "TotalCapitalAmount",
				type: GridColumnType.Money,
			},
			{
				Header: "Closed Amount",
				accessor: "ClosedAmount",
				type: GridColumnType.Money,
			},
		];

		this.trxTable = React.createRef();
	}

	showMessage = (type, title, message) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })}
				/>
			),
		});
	};
	setValueToState = (name, value) => {
		this.setState({ [name]: value });
	};
	showLoading() {
		this.setState({ isLoading: true });
	}

	hideLoading() {
		this.setState({ isLoading: false });
	}
	ExportClick = () => {
		var trxColumns = [];
		this.trxTable.current.props.Columns.forEach((c) => {
			if (
				c.accessor != "Actions" &&
				c.accessor != "ED" &&
				(c.show == undefined || c.show)
			) {
				trxColumns.push({
					value: c.accessor,
					title: c.Header,
					columntype: c.ColumnType,
					type: c.type,
				});
			}
		}, this);

		this.setState({
			isDownloadDialogOpen: true,
			GridKeyValueList: trxColumns,
			ExportSelectedColumns: trxColumns,
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false,
		});
	};

	ExcelDownload = () => {
		if (!this.state.ExportReportType) {
			this.props.showMessage(
				"warning",
				"Export Report Type not selected",
				"Select Export Report Type to continue"
			);
			return;
		}

		// if (
		// 	this.state.ExportReportType == "PDF" &&
		// 	this.state.ExportSelectedColumns.length > 15
		// ) {
		// 	this.props.showMessage(
		// 		"warning",
		// 		"PDF document cannot exceed 15 columns",
		// 		"PDF document cannot exceed 15 columns"
		// 	);
		// 	return;
		// }

		this.setState({ isDownloadDialogOpen: false });

		this.props.showConfirmMessage(
			"question",
			"Long Process Warning",
			"This process may take a while. Continue ?",
			() => {
				this.hideAlert();
				const { model } = this.state;
				model.IsClientBalanceScreen = true;

				// Add jsonFile
				var jsonColList = [];

				var trxColumns = {};
				this.state.ExportSelectedColumns.map((c) => {
					trxColumns[c.value] = {
						value: c.value,
						title: c.title,
						columntype: c.columntype,
						type: c.type,
					};
				});
				jsonColList.push(trxColumns);

				model["jsonFile"] = {
					colmns: jsonColList,
					format: this.state.ExportReportType,
					title: "Simulation Credit Card Bucket Close Log Report",
				};

				this.setState({ isLoading: true });
				Proxy.DownloadGeneratedFile(
					"/lmsapi/v1.0/FeeAndCodeBasedTransaction/DownloadSimulationCreditCardBucketCloseLog",
					model,
					(responseData) => {
						this.setState({ isLoading: false });
						if (!responseData.IsSucceeded) {
							this.props.showMessage(
								"error",
								"Error",
								responseData.ErrorDescription
							);
							return;
						}
					},
					(errorMessage) => {
						this.setState({ isLoading: false });
						this.props.showMessage("error", "Error", errorMessage);
					},
					this.props.showMessage
				);
			}
		);
	};

	openExportDialog = () => {
		this.setState({
			isDownloadDialogOpen: true,
		});
	};

	closeExportDialog = () => {
		this.setState({
			isDownloadDialogOpen: false,
		});
	};

	componentDidMount() {
		this.props.setAppLeftTitle("Simulation Credit Card Bucket Close Log");
		this.props.setAppCenterTitle("LOAN MANAGEMENT SYSTEM");
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		if (name === "UniqueClientId") {
			delete model.ClientCustomerId;
			delete model.CustomerNumber;
			delete model.CardId;
			delete model.CardTokenNumber;
		}
		if (name === "Customer") {
			if (newValue) {
				model.ClientCustomerId = newValue.Id;
				model.CustomerNumber = newValue.CustomerNumber;
			} else {
				delete model.ClientCustomerId;
				delete model.CustomerNumber;
				delete model.CardId;
				delete model.CardTokenNumber;
			}
		}
		if (name === "Card") {
			if (newValue) {
				model.CardId = newValue.CardId;
				model.CardTokenNumber = newValue.CardTokenNumber;
			} else {
				delete model.CardId;
				delete model.CardTokenNumber;
			}
		}
		model[name] = newValue;
		this.setState({ model });
	};

	ValueChangedForExport = (name, value) => {
		this.setState({ [name]: value });
	};

	HandleClear = () => {
		this.setState({
			model: { ...this.initialModel },
			isEdit: false,
			index: -1,
		});
	};

	HandleSearch = () => {
		if (!this.Validate()) {
			return;
		}

		const model = { ...this.state.model };
		model.ProgramCode = this.ProgramCode;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/lmsapi/v1.0/FeeAndCodeBasedTransaction/GetSimulationCreditCardBucketCloseLog",
			model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage(
						"error",
						"Error",
						responseData.ErrorDescription
					);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ list: responseData.Item });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	};
	Validate = () => {
		const { model, vModel } = this.state;
		const { showValidationErrors } = this.props;

		var errorList = [];

		vModel.hasReferenceNumber = model.ReferenceNumber != undefined && model.ReferenceNumber != null && model.ReferenceNumber > 0;
		
		if (!vModel.hasReferenceNumber) {
			vModel.UniqueClientId = !model.UniqueClientId;
			if (vModel.UniqueClientId) errorList.push("Client cannot be empty.");

			vModel.ClientCustomerId = !model.ClientCustomerId
			if (vModel.ClientCustomerId) errorList.push("Customer cannot be empty.");
		}

		if (errorList.length > 0) {
			showValidationErrors(errorList);
			return false;
		}

		return true;
	};

	GetModel = () => {
		return this.state.model;
	};

	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}

	FillCustomer = (customer) => {
		this.HandleChange("Customer", customer);
	}

	// FillCard = (card) => {
	// 	this.HandleChange("Card", card);
	// }

	render() {
		const { Disabled } = this.props;
		const { alert, model, list, isLoading, vModel } = this.state;
		var IsClient = ClientHelper.IsClient();
		return (
			<div>
				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar
					ButtonList={[
						{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
						{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
						{
							Code: "Export",
							OnClick: () => this.ExportClick(),
							Disabled: Disabled,
						},
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After}
				/>
				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Filter Panel">
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={this.parameterUniqueClientId}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={this.RenderItemUniqueClient}
												Value={model.UniqueClientId}
												ValueChanged={this.HandleChange}
												CanClear
												Disabled={IsClient}
												DefaultIndex={IsClient ? 0 : -1}
												IsInvalid={vModel.UniqueClientId} />
										</GridItem>
										<GridItem xs={4}>
											<CustomerComponent
												key={model.UniqueClientId}
												LabelMd={4}
												CustomerNumber={model.CustomerNumber}
												HandleChange={this.HandleChange}
												FillCallback={this.FillCustomer}
												Programs={[ProgramCodes.Prepaid]}
												UniqueClientId={model.UniqueClientId}
												Disabled={!model.UniqueClientId}
												IsInvalid={vModel.ClientCustomerId} />
										</GridItem>
										<GridItem xs={4}>
											<GenericSelectInput
												Name="BucketTypeId"
												LabelText="Bucket Type"
												Method="POST"
												Url="/coreapi/v1.0/Parameter/Search"
												Parameter={this.parameterBucketType}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ParameterDesc"
												ValueChanged={this.HandleChange}
												Value={model.BucketTypeId}
												IsInvalid={vModel.BucketTypeId}
												All
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericNumberInput
												Name="ReferenceNumber"
												LabelText="Reference Number"
												Value={model.ReferenceNumber}
												ValueChanged={this.HandleChange}
												IsInvalid={vModel.ReferenceNumber}
												NoFormatting
												Disabled={Disabled}
											/>
										</GridItem>
										{/* <GridItem xs={3}>
											<CardComponent
												key={"Card_" + [model.UniqueClientId, model.ClientCustomerId]}
												LabelMd={4}
												CardTokenNumber={model.CardTokenNumber}
												HandleChange={this.HandleChange}
												FillCallback={this.FillCard}
												ClientCustomerId={model.ClientCustomerId}
												UniqueClientId={model.UniqueClientId}
												Disabled={!model.ClientCustomerId}
												Required
												IsInvalid={vModel.CardId}
												MenuCode={this.props.MenuCode} />
										</GridItem> */}
										<GridItem xs={4}>
											<GenericDateInput
												Utc
												Name="FromDate"
												LabelText="Start Date"
												Value={
													model.FromDate
														? DateHelper.ToDateInputValue(model.FromDate)
														: ""
												}
												ValueChanged={this.HandleChange}
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericDateInput
												Utc
												Name="ToDate"
												LabelText="End Date"
												Value={
													model.ToDate
														? DateHelper.ToDateInputValue(model.ToDate)
														: ""
												}
												ValueChanged={this.HandleChange}
												MinDate={model.FromDate}
											/>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
				<GridContainer>
					<GenericDialog
						open={this.state.isDownloadDialogOpen}
						fullWidth={true}
					>
						<DialogTitle>
							<GenericLabel
								Text="Select Report Type"
								FontSize="20px"
								Bold={true}
							/>
						</DialogTitle>
						<DialogContent style={{ height: 300 }}>
							<GenericRadioInput
								Name="ExportReportType"
								LabelText="Report Type"
								IsStatic={true}
								StaticData={[
									{ Value: "PDF", Text: "PDF" },
									{ Value: "CSV", Text: "CSV" },
									{ Value: "EXCEL", Text: "EXCEL" },
								]}
								KeyValueMember="Value"
								TextValueMember="Text"
								Value={this.state.ExportReportType}
								ValueChanged={this.ValueChangedForExport}
							/>
							<GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
							<GridItem style={{ marginTop: 20 }}>
								<Select
									value={this.state.ExportSelectedColumns}
									onChange={(value) =>
										this.setState({ ExportSelectedColumns: value })
									}
									isMulti
									name="Columns"
									getOptionLabel={(d) => d.title}
									options={this.state.GridKeyValueList}
									className="basic-multi-select"
									classNamePrefix="select"
								/>
							</GridItem>
						</DialogContent>
						<GenericDialogActions>
							<Button size="sm" onClick={this.ExcelDownload}>
								Download
							</Button>
							<Button
								size="sm"
								onClick={() => this.setState({ isDownloadDialogOpen: false })}
							>
								Cancel
							</Button>
						</GenericDialogActions>
					</GenericDialog>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={3}></GridItem>
									<GridItem xs={8} />
								</GridContainer>
								<GridContainer spacing={16}>
									<GridItem xs={12}>
										<GenericGrid
											Data={list}
											Columns={this.Columns}
											ProgramCode={ProgramCodes.Prepaid}
											ShowPagination={true}
											ref={this.trxTable}
											PageSize={100}
											Sorted={[{ id: "EodDate", desc: true }]}
										/>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

SimulationCreditCardBucketCloseLog.propTypes = {
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool,
};

export default withArtifex(SimulationCreditCardBucketCloseLog, {});
