import { FormControlLabel, Radio } from "@material-ui/core";
import genericRadioInputStyle from "assets/jss/generic/genericRadioInputStyle.jsx";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Black from "components/Typography/Black";
import { Proxy, withRedux } from "core";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React from "react";
import { UserConstants } from "store";
import ButtonToolbar from "views/Components/ButtonToolbar";
import { GenericAlert, GenericNumberInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { AuthenticationMethod } from "views/Constants/Constant";

const changePasswordStyle = theme => ({
	...genericRadioInputStyle
});

class ChangeOrResetPassword extends React.Component {

	constructor(props) {
		super(props);

		this.initialModel = {
			Otp: "",
			OtpId: 0,
			NewPassword: "",
			ConfirmNewPassword: "",
			AuthenticationMethod: ""
		};

		this.state = {
			model: { ...this.initialModel },
			isLoading: false
		};

		this.maxLength6 = { maxLength: 6 };
		this.AuthMethodDivStyle = { width: "50%", marginRight: "auto", marginLeft: "auto" };
		this.googleImageStyle = { height: 50 };
		this.twilioImageStyle = { height: 50 };
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Change Password");
		this.props.setAppCenterTitle("User Admin");

		var model = { ...this.state.model };
		const userJson = ClientHelper.GetUser();
		model.Email = userJson["UserEmail"];
		model.UserRole = userJson["Role"]["RoleName"];

		this.setState({ model });
	}

	HandleChange = (name, value) => {
		const model = { ...this.state.model };
		model[name] = value;
		this.setState({ model });
	}

	HandleChangeAuthMethod = event => {
		const { model } = this.state;
		if (!this.ValidateEmail())
			return;

		if (event.target.value == AuthenticationMethod.Google) {
			// this.GetQRCode();
		} else {
			this.SendSMSOTP();
		}
		model.AuthenticationMethod = event.target.value;
		this.setState({ model });
	};

	HandleClear = () => {
		this.setState({ model: { ...this.initialModel } });
	}

	HandleSubmit = () => {
		this.setState({ isLoading: true });

		if (!this.Validate()) {
			this.setState({ isLoading: false });
			return;
		}

		Proxy.POST("/coreapi/v1.0/User/ChangePassword",
			this.state.model,
			this.ChangePasswordCallback,
			this.ChangePasswordErrorCallback
		);
	}

	ValidateEmail = () => {
		const { model } = this.state;

		if (!model.Email) {
			this.ShowMessage("warning", "E-Mail Is Empty", "Enter E-Mail for us to send the verification SMS!");
			return false;
		}
		return true;
	}

	Validate = () => {
		const { model } = this.state;
		if (!model.OldPassword || model.OldPassword.trim() === "") {
			this.ShowMessage("warning", "Old Password Is Empty", "Enter old password to change password.");
			return false;
		}
		if (!model.NewPassword || model.NewPassword.trim() === "") {
			this.ShowMessage("warning", "New Password Is Empty", "Enter new password to change password.");
			return false;
		}
		if (!model.ConfirmNewPassword || model.ConfirmNewPassword.trim() === "") {
			this.ShowMessage("warning", "Confirm New Password Is Empty", "Enter confirm new password to change password.");
			return false;
		}
		if (model.NewPassword != model.ConfirmNewPassword) {
			this.ShowMessage("warning", "Warning", "The new password and the confirm new password do not match.");
			return false;
		}

		return true;
	}

	SendSMSOTP = () => {
		const { model } = this.state;
		this.setState({ isLoading: true });
		Proxy.POST(
			"/coreapi/v1.0/TwoFactorAuth/SendSmsOtpForPasswordChange",
			{
				Email: this.state.model.Email,
				OrganizationUniqueId: window.OrganizationUniqueId
			},
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				model.OtpId = responseData.OtpId;
				this.setState({ model });
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", "An error occurred during the send sms otp " + error);
			}
		);
	}

	ChangePasswordCallback = (responseData) => {
		this.setState({ isLoading: false });
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error", responseData.ErrorDescription);
			return;
		}
		this.ShowMessage("success", "Successful", "The password has been successfully change.");

		const { dispatch } = this.props;
		setTimeout(function () {
			localStorage.removeItem("user");
			dispatch({ type: UserConstants.LOGOUT });
		}, 2000);
	}

	ChangePasswordErrorCallback = (error) => {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", "An error occurred during the change password " + error);
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })}
			/>
		});
	}

	render() {
		const { classes, Disabled } = this.props;
		const { alert, isLoading, model } = this.state;

		const googleLogo = require("assets/img/" + window.OtpGoogleLogo);
		const twilioLogo = require("assets/img/" + window.OtpTwilio);

		return (
			<div>
				<LoadingComponent Show={isLoading} />
				{alert}
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Submit",
							Disabled: Disabled,
							OnClick: this.HandleSubmit
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.HandleClear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className={"no-radius"}>
							<CardHeader>
								<GenericTitle text="Change Password" />
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={4}>
										<GridItem xs={12}>
											<GenericTextInput
												Name="UserRole"
												LabelText="User Role"
												Disabled={true}
												Value={model.UserRole} />
										</GridItem>
										<GridItem xs={12}>
											<GenericTextInput
												Name="UserEmail"
												LabelText="User E-Mail"
												Disabled={true}
												Value={model.Email} />
										</GridItem>
										<GridContainer style={{ margin: 10 }} direction="column" justify="space-between" alignItems="center">
											<Black>Select Your 2FA Authentication Method</Black>
											<div style={this.AuthMethodDivStyle}>
												<GridContainer direction="row" justify="space-between" alignItems="flex-start">
													<GridItem>
														<GridContainer direction="column" justify="flex-start" alignItems="center">
															<GridItem>
																<FormControlLabel
																	control={
																		<Radio
																			checked={model.AuthenticationMethod === AuthenticationMethod.Google}
																			value={AuthenticationMethod.Google}
																			name="radio-button"
																			classes={{ checked: classes.radio }}
																			onChange={this.HandleChangeAuthMethod}
																		/>
																	}
																	classes={{ label: classes.radioLabel }}
																	label="Google"
																/>
															</GridItem>
															<GridItem>
																<img className={classes.img} style={this.googleImageStyle} src={googleLogo} />
															</GridItem>
														</GridContainer>
													</GridItem>
													<GridItem>
														<GridContainer direction="column" justify="flex-start" alignItems="center">
															<GridItem>
																<FormControlLabel
																	control={
																		<Radio
																			checked={model.AuthenticationMethod === AuthenticationMethod.Twilio}
																			value={AuthenticationMethod.Twilio}
																			name="radio-button"
																			classes={{ checked: classes.radio }}
																			onChange={this.HandleChangeAuthMethod}
																		/>
																	}
																	classes={{ label: classes.radioLabel }}
																	label="Text 2FA"
																/>
															</GridItem>
															<GridItem>
																<img className={classes.img} style={this.twilioImageStyle} src={twilioLogo} />
															</GridItem>
														</GridContainer>
													</GridItem>
												</GridContainer>
											</div>
										</GridContainer>
										<GridItem xs={12}>
											<GenericNumberInput
												NoFormatting={true}
												ThousandSeparator=""
												Name="Otp"
												LabelText="Enter 2FA Code"
												Value={model.Otp}
												ValueChanged={this.HandleChange}
												MaxLength={6} />
										</GridItem>
										<GridItem xs={12}>
											<GenericTextInput
												Name="OldPassword"
												LabelText="Enter Old Password"
												IsPassword={true}
												Value={model.OldPassword}
												ValueChanged={this.HandleChange}
												EndIconName="lock_outline" />
										</GridItem>
										<GridItem xs={12}>
											<GenericTextInput
												Name="NewPassword"
												LabelText="Enter New Password"
												IsPassword={true}
												Value={model.NewPassword}
												ValueChanged={this.HandleChange}
												EndIconName="lock_outline" />
										</GridItem>
										<GridItem xs={12}>
											<GenericTextInput
												Name="ConfirmNewPassword"
												LabelText="Confirm New Password"
												IsPassword={true}
												Value={model.ConfirmNewPassword}
												ValueChanged={this.HandleChange}
												EndIconName="lock_outline" />
										</GridItem>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

ChangeOrResetPassword.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withRedux(ChangeOrResetPassword, changePasswordStyle);