import PropTypes from "prop-types";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import externalPagesRoutes from "routes/externalPage.jsx";
import { TaskPool } from "core/TaskPool";
import DashboardHelper from "core/DashboardHelper";
import LoadingComponent from "views/Components/LoadingComponent";

class ExternalPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			alert: null,
			isLoading: false

		};
		this.handleLoadTaskPoolCompleted = this.handleLoadTaskPoolCompleted.bind(this);
		this.handleLoadTaskPoolAppend = this.handleLoadTaskPoolAppend.bind(this);		
		this.handleAlertChange = this.handleAlertChange.bind(this);

		this.loadTaskPool = new TaskPool(this.handleLoadTaskPoolAppend, this.handleLoadTaskPoolCompleted);
		this.dashboardHelper = new DashboardHelper(this.handleAlertChange, this.loadTaskPool);
		
		this.ExecuteApiGet = this.dashboardHelper.ExecuteApiGet;
		this.ExecuteApiPost = this.dashboardHelper.ExecuteApiPost;
		this.ExecuteApiFileUpload = this.dashboardHelper.ExecuteApiFileUpload;
		this.ExecuteApiImageUpload = this.dashboardHelper.ExecuteApiImageUpload;
		this.ExecuteApiFileDownload = this.dashboardHelper.ExecuteApiFileDownload;
		this.ExecuteApiFileDownloadWithGenericResponse = this.dashboardHelper.ExecuteApiFileDownloadWithGenericResponse;
		this.ShowMessage = this.dashboardHelper.ShowMessage;
		this.ShowMessageNode = this.dashboardHelper.ShowMessage;
		this.ShowConfirmMessage = this.dashboardHelper.ShowConfirmMessage;
		this.ShowValidationErrors = this.dashboardHelper.ShowValidationErrors;
		this.ShowMessageAfterOperation = this.dashboardHelper.ShowMessageAfterOperation;
		this.ShowConfirmMessageInDelete = this.dashboardHelper.ShowConfirmMessageInDelete;
	}

	handleAlertChange(alert) {
		this.setState({ alert });
	}

	handleLoadTaskPoolCompleted() {
		this.setState({ isLoading: false });
	}
	handleLoadTaskPoolAppend() {
		this.setState({ isLoading: true });
	}

	componentDidMount() {
		document.body.style.overflow = "unset";
	}
	getExternalRoutes = () => {

		return externalPagesRoutes.map((prop, key) => {
			if (prop.collapse) {
				return null;
			}
			if (prop.redirect) {
				return (
					<Redirect from={prop.path} to={prop.pathTo} key={key} />
				);
			}
			var Comp = prop.component;
			return (
				<Route
					path={prop.path}
					key={key}
					render={props =>
						<Comp
							ExecuteApiGet={this.ExecuteApiGet}
							ExecuteApiPost={this.ExecuteApiPost}
							ExecuteApiFileUpload={this.ExecuteApiFileUpload}
							ExecuteApiImageUpload={this.ExecuteApiImageUpload}
							ExecuteApiFileDownload={this.ExecuteApiFileDownload}
							ExecuteApiFileDownloadWithGenericResponse={this.ExecuteApiFileDownloadWithGenericResponse}
							showMessage={this.ShowMessage}
							showMessageNode={this.ShowMessage}
							showConfirmMessage={this.ShowConfirmMessage}
							showValidationErrors={this.ShowValidationErrors}
							showMessageAfterOperation={this.ShowMessageAfterOperation}
							showConfirmMessageInDelete={this.ShowConfirmMessageInDelete}
							taskPool={this.loadTaskPool}
							{...this.props}
							{...props}
						/>
					}
				/>);
		});
	}
	render() {
		const { isLoading, alert } = this.state;
		return (
			<div>
				<LoadingComponent Show={isLoading} />
				{alert}
				<Switch>
					{this.getExternalRoutes()}
				</Switch>
			</div>
		);
	}
}

ExternalPage.propTypes = {
	classes: PropTypes.object
};

export default ExternalPage;
