const OperationType = {
	DEFAULT: 0,
	INSERT: 1,
	UPDATE: 2,
	DELETE: 3,
};

const GetType = {
	ALL: 0,
	BYPARAMETER: 1,
};

const GridColumnType = {
	Date: "date",
	DateUtc: "date_utc",
	DateTime: "datetime",
	DateTimeUtc: "datetime_utc",
	Time: "time",
	Money: "money",
	YesNo: "yesno",
	Percent: "percent",
};

const AuthenticationMethod = {
	Google: "G",
	Twilio: "T",
};

const AuthenticatorStatus = {
	UnRegistered: 0,
	WaitingForVerification: 1,
	Verified: 2,
};

const SessionStatus = {
	Active: 1,
	Closed: 2,
	Expired: 3,
	ManuelClosed: 4,
	Banned: 5,
};

const DocumentType = {
	CSV: 0,
	Excel: 1,
	PDF: 2,
};

const Portals = {
	EFT: "EFT",
	eTransfer: "eTransfer",
	DDT: "DDT",
	BankAccount: "BankAccount",
	WireTransfer: "WireTransfer",
};

const MainSearchItems = {
	All: "A",
	Errors: "E",
	Rejected: "RJ",
	Returned: "R",
	UnSettledUnProcessed: "U",
	Pending: "P",
	File: "F",
};

const UnSettledMainSearchItem = {
	UnSettledTransactions: "UST",
	UnProcessedTransactions: "UPT",
	UnSettledFile: "USF",
	UnProcessedFiles: "UPF",
};





const PeriodCodes = {
	Weekly: "W",
	BiWeekly: "BW",
	SemiMonthly: "SM",
	Monthly: "M",
	MonthlyMonthEnd: "ME",
	Quarterly: "Q",
	QuarterlyMonthEnd: "QE",
};

const CardTransferTypes = {
	VisaDirect: "V",
	MastercardSend: "M",
	All: "A"
};

const ProgramCodes = {
	ACQUIRING: "ACQ",
	ACQUIRING_Atm_Acquiring: "ACQATM",
	ACQUIRING_Credit: "ACQCRD",
	ACQUIRING_Debit: "ACQDBT",
	ACQUIRING_Prepaid: "ACQPRP",
	Atm_Acquiring_Interac: "ACQATMINT",
	Atm_Acquiring_Mastercard: "ACQATMMSC",
	Atm_Acquiring_Mastercard_Cirrus: "ACQATMMSCR",
	Atm_Acquiring_Visa: "ACQATMVIS",
	Atm_Acquiring_Visa_Plus: "ACQATMVISA",
	BANK_PROGRAMS: "BNK_PRG",
	Bank_Programs_Agent: "AGENT",
	Bank_Programs_Bank_Account: "BNKACCOUNT",
	Bank_Programs_Bank_Account_Corporate: "BNKACCOUNT_C",
	Bank_Programs_Bank_Account_Personal: "BNKACCOUNT_P",
	BANK_PROGRAMS_Bank_Programs: "BNK_PROGRAM",
	BANK_PROGRAMS_Card_Transfers: "CRDTRANSFER",
	Bank_Programs_CDIC: "CDIC",
	Bank_Programs_DDT: "DDT",
	Bank_Programs_e_Transfer: "ETRANSFER",
	Bank_Programs_EFT: "EFT",
	Bank_Programs_BILL: "BILL",
	Bank_Programs_MSB: "MSB",
	Bank_Programs_Sponsorship: "SPONSORSHIP",
	Bank_Programs_Swift: "SWIFT",
	Card_Transfers_Mastercard_Moneysend: "MONEYSEND",
	Card_Transfers_Visa_Direct: "VISADIRECT",
	Credit_Amex: "ACQCRDAMX",
	Credit_Mastercard: "ACQCRDMSC",
	Credit_Visa: "ACQCRDVIS",
	Debit_Interac: "ACQDBTINT",
	Debit_Interac_Debit: "INTD",
	Debit_Mastercard: "ACQDBTMSC",
	Debit_Mastercard_Debit: "MSCD",
	Debit_Visa: "ACQDBTVIS",
	Debit_Visa_Debit: "VISAD",
	ISSUING: "ISS",
	ISSUING_Debit: "DBT",
	ISSUING_Prepaid: "PRP",
	Prepaid_Interac: "ACQPRPINT",
	Prepaid_Mastercard: "ACQPRPMSC",
	Prepaid_Mastercard_Prepaid: "MSCP",
	Prepaid_Visa: "ACQPRPVIS",
	Prepaid_Visa_Prepaid: "VISAP",
	Prepaid: "PREPAID",
	Lender: "LENDER",
	BillPayment: "BILL",
	DigitalIdVerification: "DIGITALID",
	Tsp : "Tokenization Service",
};

const WeekDays = [
	{
		Id: 0,
		Text: "Sunday",
	},
	{
		Id: 1,
		Text: "Monday",
	},
	{
		Id: 2,
		Text: "Tuesday",
	},
	{
		Id: 3,
		Text: "Wednesday",
	},
	{
		Id: 4,
		Text: "Thursday",
	},
	{
		Id: 5,
		Text: "Friday",
	},
	{
		Id: 6,
		Text: "Saturday",
	},
];

const DateHomePageFilter = [
	{
		Id: 0,
		Text: "Today"
	},
	{
		Id: 1,
		Text: "3 Days"
	},
	{
		Id: 2,
		Text: "10 Days"
	},
	{
		Id: 3,
		Text: "30 Days"
	}

];
const DateFilter = [
	{
		Id: 0,
		Text: "24 Hours",
	},
	{
		Id: 1,
		Text: "3 Days",
	},
	{
		Id: 2,
		Text: "7 Days",
	},
];

const LastPaymentDateAfter = [
	{
		Id: 1,
		Text: "1 Day",
	},
	{
		Id: 5,
		Text: "5 Days",
	},
	{
		Id: 10,
		Text: "10 Days",
	},
	{
		Id: 21,
		Text: "21 Days",
	},
];
const SelectNextDayList = [
	{
		Id: 1,
		Text: "1 Day",
	},
	{
		Id: 2,
		Text: "2 Days",
	},
	{
		Id: 3,
		Text: "3 Days",
	},
	{
		Id: 4,
		Text: "4 Days",
	},
	{
		Id: 5,
		Text: "5 Days",
	},
];

const PersonalizedExpDateList = [
	{
		Id: 2,
		Text: "2 Years",
	},
	{
		Id: 3,
		Text: "3 Years",
	},
	{
		Id: 4,
		Text: "4 Years",
	},
	{
		Id: 5,
		Text: "5 Years",
	},
];

const ReportType = {
	Reportable: "R",
	ReportableReadOnly: "RR",
	NonReportable: "N",
};

const DynamicReportType = [
	{ Value: 0, Text: "Schedule" },
	{ Value: 1, Text: "Screen" },
	{ Value: 2, Text: "On-demand" }
];

const ReportTypeForWalletAccounts = [
	{
		Value: ReportType.NonReportable,
		Text: "Number",
	},
	{
		Value: ReportType.Reportable,
		Text: "Reportable",
	},
];

const NotificationUsageType = [
	{ Value: 0, Text: "Only Client" },
	{ Value: 1, Text: "Both Client & Customer" },
	{ Value: 2, Text: "Only Customer" },
];
const EtransferApplicationTypes = [
	{ Value: 1, Text: "Screen" },
	{ Value: 2, Text: "Client Api" },
	{ Value: 3, Text: "End User Api" },
	{ Value: 7, Text: "Recurring" },
];
const LoginUserType = {
	ParameterCode: "LoginUserType",
	BackOffice: "LBO",
	Customer: "LCU",
	DCBankVisitorUser: "LVU",
};

const EFTTransactionTypes = {
	Credit: "C",
	Debit: "D",
};

const EFTPriorityTypes = {
	Priority: "P",
	Regular: "R",
};

const EtransferTransactionTypes = {
	Credit: "C",
	Debit: "D",
};

const EtransferType = {
	RealTime: "0",
	BulkRegular: "1",
	BulkPriority: "2",
};

const EtransferFrequencyCodes = {
	OneTime: "0",
	Daily: "1",
	Weekly: "2",
	Monthly: "3",
};

const EtransferPeriodCodes = {
	DayOfMonth: "0",
	DayOfWeek: "1",
	WeekOfMonth: "2",
};

const DcbETransferType = {
	Default: 0,
	AccountDeposit: 1
};

const ETransferDayOfMonthTypes = {
	FirstDay: "0",
	SelectedDay: "1",
	LastDay: "2",
};

const BankingAccountTypes = {
	DCBankAccount: "DBA",
	DDTAccount: "DDT",
	CDIC: "CDIC",
	DdtPoolAccount: "DDTP",
	CDICPoolAccount: "CDICP",
	ETransferMainAccount: "ETRANSFERMACC",
	PrimaryAccount: "PA",
	SecondaryAccount: "SA",
	SecurityAccount: "CA",
	FeeAccount: "FA",
	PooledAccount: "PooledAccount",
};

const AccountStatus = {
	Active: "A",
	Passive: "P",
	Inactive: "I",
	Suspended: "T",
	Closed: "C",
	Deactivated: "D",
};

const DDTStatus = {
	Active: 8002,
	Inactive: 8003,
};


const NotificationStatus = {
	Pending: "P",
	Notified: "N",
};

const BankTransactionStatus = {
	Completed: "C",
	Error: "E",
	Pending: "P",
	Return: "R",
	Rejected: "RJ",
	ReSend: "RS",
	Sent: "S",
	Cancelled: "V",
	Settled: "Y",
	Unapproved: "U",
	Traced: "T",
	ReadToSend: "RD",
	Defered: "DF",
	BlockedLimit: "BL",
	InsufficientBalance: "IB",
};
const TransactionTraceStatus = {
	CODE: "TransactionTraceStatus",
	Pending: "P",
	Completed: "C",
};

const BankTransactionStatusParameterDesc = {
	Outgoing: "Outgoing",
	Completed: "Completed",
	Return: "Return",
	Unapproved: "Unapproved",
	Pending: "Pending",
	ReadyToSend: "Ready To Send",
};

const ETransferWeekOfMonthTypes = {
	First: "1",
	Second: "2",
	Third: "3",
	Fourth: "4",
	Last: "L",
};

const ClientType = {
	Personal: "P",
	Corporate: "C",
	SalesAgentPersonal: "SAP",
	SalesAgentCorporate: "SAC",
	WireTransferMSBCorparate: "WMSBCorparate",
	WireTransferMSBIndividual: "WMSBIndividual",
};

const CustomerStatus = {
	Active: "N",
	Inactive: "C",
	Deactivated: "D",
	Suspended: "S",
};

const AmlKycRiskLevel = {
	High: "H",
	Medium: "M",
	Low: "L",
};

const CustomerBusinessType = {
	Individual: "I",
	Business: "B",
};

const ApprovalRequest = {
	Insert: "INS",
	Delete: "DEL",
	Update: "UPT",
};
const ApprovalStatus = {
	Waiting: "W",
	Approved: "A",
	Rejected: "R",
	CanceledbyUser: "C",
	Completed: "D",
	KYC: "KYC",
};
const BankProgramType = {
	EFT: "EFT",
	eTransfer: "e Transfer",
	DDT: "DDT",
	CDIC: "CDIC",
	Account: "Account",
};
const PortalProgram = {
	All: "All Program",
	EFT: "EFT Program",
	eTransfer: "eTransfer Program",
	DDT: "DDT Program",
};

const DCBankRowId = {
	Id: 46,
};

const SecurityType = {
	FixedCash: "FC",
	LetterofGuarantee: "LG",
	Prepaid: "P",
};

const LimitType = {
	TransactionLimitDaily: "TransactionLimitDaily",
	PrepaidAccountFundsTransferLimitDaily:
		"PrepaidAccountFundsTransferLimitDaily",
	TransactionBasedLimit: "TransactionBasedLimit",
	FileTotalUploadLimitPerFile: "FileTotalUploadLimitPerFile",
};

const PhoneType = {
	Primary: "P",
	Alternative: "A",
	Cell: "C",
	Business: "B",
	Fax: "F",
};
const PortalType = {
	Client: 0,
	BackOffice: 1,
};
const TransactionType = {
	Credit: "C",
	Debit: "D",
};
const FeeProfileType = {
	Fixed: "F",
	Weighed: "W",
	UnWeighed: "U",
	CombineWeighted: "WC",
	CombineUnWeighted: "UC",
};
const FeeType = {
	MonthlyProgramFeeEft: "MonthlyProgramFeeEft",
	MonthlySftpFeeEft: "MonthlySftpFeeEft",
	MonthlyDueDeligenceFeeEft: "MonthlyDueDeligenceFeeEft",
	MonthlyApiFeeEft: "MonthlyApiFeeEft",
	MiscDebitRegularRvsTxnFee: "MiscDebitRegularRvsTxnFee",
	MiscDebitRegularFilePrcFee: "MiscDebitRegularFilePrcFee",
	MiscDebitRegularDisputeFee: "MiscDebitRegularDisputeFee",
	MiscDebitPriorityRvsTxnFee: "MiscDebitPriorityRvsTxnFee",
	MiscDebitPriorityFilePrcFee: "MiscDebitPriorityFilePrcFee",
	MiscDebitPriorityDisputeFee: "MiscDebitPriorityDisputeFee",
	MiscCreditRegularTxnFee: "MiscCreditRegularTxnFee",
	MiscCreditRegularRvsTxnFee: "MiscCreditRegularRvsTxnFee",
	MiscCreditRegularFilePrcFee: "MiscCreditRegularFilePrcFee",
	MiscCreditRegularDisputeFee: "MiscCreditRegularDisputeFee",
	MiscCreditPriorityTxnFee: "MiscCreditPriorityTxnFee",
	MiscCreditPriorityRvsTxnFee: "MiscCreditPriorityRvsTxnFee",
	MiscCreditPriorityFilePrcFee: "MiscCreditPriorityFilePrcFee",
	MiscCreditPriorityDisputeFee: "MiscCreditPriorityDisputeFee",
	MonthlyProgramFeeEtransfer: "MonthlyProgramFeeEtransfer",
	MonthlySftpFeeEtransfer: "MonthlySftpFeeEtransfer",
	MonthlyDueDeligenceFeeEtransfer: "MonthlyDueDeligenceFeeEtransfer",
	MonthlyApiFeeEtransfer: "MonthlyApiFeeEtransfer",
	RealTimeETCancelledTxnFee: "RealTimeETCancelledTxnFee",
	RealTimeETFileProcFee: "RealTimeETFileProcFee",
	RealTimeETDisputeFee: "RealTimeETDisputeFee",
	RealTimeETMinTxnFee: "RealTimeETMinTxnFee",
	BulkRegularETCancelledTxnFee: "BulkRegularETCancelledTxnFee",
	BulkRegularETFileProcFee: "BulkRegularETFileProcFee",
	BulkRegularETDisputeFee: "BulkRegularETDisputeFee",
	BulkRegularETMinTxnFee: "BulkRegularETMinTxnFee",
	BulkPriorityETCancelledTxnFee: "BulkPriorityETCancelledTxnFee",
	BulkPriorityETFileProcFee: "BulkPriorityETFileProcFee",
	BulkPriorityETDisputeFee: "BulkPriorityETDisputeFee",
	BulkPriorityETMinTxnFee: "BulkPriorityETMinTxnFee",
	BulkRegularMRCancelledTxnFee: "BulkRegularMRCancelledTxnFee",
	BulkRegularMRFileProcFee: "BulkRegularMRFileProcFee",
	BulkRegularMRDisputeFee: "BulkRegularMRDisputeFee",
	BulkRegularMRMinTxnFee: "BulkRegularMRMinTxnFee",
	BulkPriorityMRCancelledTxnFee: "BulkPriorityMRCancelledTxnFee",
	BulkPriorityMRFileProcFee: "BulkPriorityMRFileProcFee",
	BulkPriorityMRDisputeFee: "BulkPriorityMRDisputeFee",
	BulkPriorityMRMinTxnFee: "BulkPriorityMRMinTxnFee",
	TransferToExtAccountFee: "TransferToExtAccountFee",
	TransferToDcbPrepaidCard: "TransferToDcbPrepaidCard",
	TransferToETransferFee: "TransferToETransferFee",
	DormantFee: "DormantFee",
	PerDepositFee: "PerDepositFee",
	MinNumOfDdtFee: "MinNumOfDdtFee",
	YearlyAccountFee: "YearlyAccountFee",
	SMSNotificationFee: "SMSFee",
	EmailNotificationFee: "EmailFee",
	ApplicationNotificationFee: "ApplicationFee",
};
const PriorityRegularType = {
	Regular: "R",
	Priority: "P",
	None: "N",
};
const OrderStatusType = {
	Approved: "2",
	Waiting: "1",
};
const TxnEffect = {
	None: "N",
	Credit: "C",
	Debit: "D",
};
const RoleGroup = {
	Client: "Client",
	Backoffice: "Backoffice",
};
const LimitProfileType = {
	ClientLimitProfile: "C",
	UserLimitProfile: "U",
};
const NotificationSendType = {
	Sms: "1",
	Email: "2",
	Application: "3",
};
const AddressType = {
	MainAddress: "M",
	BranchAddress: "B",
	Home: "H",
	Business: "A",
	StoreAdress: "STR",
	HeadOffice: "HDO",
};
const IdentificationMethod = {
	InPersonIDVerification: "I",
	CreditFileMethod: "P",
	DualProcessMethod: "D",
	CanadaPostVerification: "C",
	DigitalIdentityVerification: "V",
	Documents: "U",
	AccountVerification: "A",
	Other: "O",
};
const RiskLightRating = {
	Black: "B",
	Green: "G",
	Yellow: "Y",
	Red: "R",
};
const SalesChannel = {
	DirectSales: "DS",
	SalesPerson: "SP",
};

const DefaultBankInformation = {
	FinancialInstitutionId: 46,
	FinancialInstitutionBranchId: 3,
};

const EmbossType = {
	InstantId: 3300,
	MassId: 3301
}

const CampaignEngineMethodType = {
	Notification: "Notification",
	ICoupon: "ICoupon",
	FreeProduct: "FreeProduct",
};

const InformationType = {
	AccountNumber: "AccountNumber",
	CardNumber: "CardNumber",
	Email: "Email",
};

const IdentityType = {
	ONDriversLicence: "C000",
	QCDriversLicence: "C001",
	ABDriversLicence: "C002",
	BCDriversLicence: "C003",
	MBDriversLicence: "C004",
	NUDriversLicence: "C005",
	NBDriversLicence: "C006",
	NSDriversLicence: "C007",
	NLDriversLicence: "C008",
	NTDriversLicence: "C009",
	PEDriversLicence: "C010",
	SKDriversLicence: "C011",
	YTDriversLicence: "C012",
	DriversLicenceForeign: "C013",
	CanadaPassport: "C014",
	ForeignPassport: "C015",
	USAPassport: "C016",
	CertificateofIndianStatusCard: "C018",
	CanadaPermanentResidentCard: "C019",
	CanadianForcesID: "C020",
	ONProvincialPhotoID: "C021",
	MBProvincialPhotoID: "C024",
	PEProvincialPhotoID: "C025",
	QCProvincialHealthCard: "C033",
	ABProvincialPhotoID: "C034",
	BCProvincialPhotoID: "C035",
	NBProvincialPhotoID: "C036",
	NLProvincialPhotoID: "C037",
	NSProvincialPhotoID: "C038",
	SKProvincialPhotoID: "C039",
	YTProvincialPhotoID: "C040",
	NUProvincialPhotoID: "C041",
	NTProvincialPhotoID: "C042",
	FirearmsPossessionandAcquisitionLicense: "C043",
	CanadaVeteransHealthCardNoPhoto: "C044",
	BCComboCard: "C075",

	ZeroPoR: "0_PoR",
	OnePoR: "1_PoR",
};

const Direction = {
	Incoming: "I",
	Outgoing: "O",
};

const ViewSourceType = {
	All: "All",
	Announcement: "A",
	Notification: "N",
	BankingNotification: "BN",
};

const FileCode = {
	AmlKycInternalBlacklist: "AmlKycInternalBlacklist",
	UserGuide: "UserGuide",
	Legal: "Legal",
	FeeManage: "FeeManage",
	Contract: "Contract",
	Dispute: "Dispute",
	KycManualPhoto: "KycManualPhoto",
	LedgerCardLogo: "LEDGERCARDCOMPANYICON",
	IframeHeaderLogo: "IframeHeaderLogo"
};

const FileType = {
	Base64: "Base64",
	Binary: "Binary",
};

const FileExtension = {
	PDF: "PDF",
	CSV: "CSV",
	JPG: "JPG",
	JSON: "JSON",
	PNG: "PNG",
	XLSX: "XLSX",
	XML: "XML",
};

const LoanLedgerCardIconType = {
	CompanyLogo: "0",
	StatementLogo: "1",
};

const CrudType = {
	Insert: "Insert",
	Update: "Update",
};

const UserLimitType = {
	UserCreditTransactionLimitDaily: "UserCreditTransactionLimitDaily",
	UserDebitTransactionLimitDaily: "UserDebitTransactionLimitDaily",
	UserPrepaidAccountFundsTransferLimitDaily:
		"UserPrepaidAccountFundsTransferLimitDaily",
	UserTransactionBasedLimit: "UserTransactionBasedLimit",
	UserCreditFileTotalUploadLimitEachFile:
		"UserCreditFileTotalUploadLimitEachFile",
	UserDebitFileTotalUploadLimitEachFile:
		"UserDebitFileTotalUploadLimitEachFile",
	UserMaxNumberOfTransactionOneTimeUpload:
		"UserMaxNumberOfTransactionOneTimeUpload",
	UserNumberOfTransacionDailyUpload: "UserNumberOfTransacionDailyUpload",
	UserMaxLimit: "UserMaxLimit",
};

const EFTEntryType = {
	FromFile: "BF",
	Individual: "IE",
	Recurring: "RT",
};

const EtransferEntryType = {
	FromFile: "BF",
	Individual: "IE",
	Recurring: "RT",
};

const TrueFalse = {
	True: "T",
	False: "F",
};

const CurrencyType = {
	KRW: "KRW",
	AFA: "AFA",
	ALL: "ALL",
	DZD: "DZD",
	ADP: "ADP",
	AOK: "AOK",
	ARS: "ARS",
	AMD: "AMD",
	AWG: "AWG",
	AUD: "AUD",
	BSD: "BSD",
	BHD: "BHD",
	BDT: "BDT",
	BBD: "BBD",
	BZD: "BZD",
	BMD: "BMD",
	BTN: "BTN",
	BOB: "BOB",
	BWP: "BWP",
	BRL: "BRL",
	GBP: "GBP",
	BND: "BND",
	BGN: "BGN",
	BUK: "BUK",
	BIF: "BIF",
	CAD: "CAD",
	CVE: "CVE",
	KYD: "KYD",
	CLP: "CLP",
	CLF: "CLF",
	COP: "COP",
	XOF: "XOF",
	XAF: "XAF",
	KMF: "KMF",
	XPF: "XPF",
	CRC: "CRC",
	CUP: "CUP",
	CYP: "CYP",
	CZK: "CZK",
	DKK: "DKK",
	YDD: "YDD",
	DOP: "DOP",
	XCD: "XCD",
	TPE: "TPE",
	ECS: "ECS",
	EGP: "EGP",
	SVC: "SVC",
	EEK: "EEK",
	ETB: "ETB",
	EUR: "EUR",
	FKP: "FKP",
	FJD: "FJD",
	GMD: "GMD",
	GHC: "GHC",
	GIP: "GIP",
	XAU: "XAU",
	GTQ: "GTQ",
	GNF: "GNF",
	GWP: "GWP",
	GYD: "GYD",
	HTG: "HTG",
	HNL: "HNL",
	HKD: "HKD",
	HUF: "HUF",
	INR: "INR",
	IDR: "IDR",
	XDR: "XDR",
	IRR: "IRR",
	IQD: "IQD",
	IEP: "IEP",
	ILS: "ILS",
	JMD: "JMD",
	JPY: "JPY",
	JOD: "JOD",
	KHR: "KHR",
	KES: "KES",
	KWD: "KWD",
	LAK: "LAK",
	LBP: "LBP",
	LSL: "LSL",
	LRD: "LRD",
	LYD: "LYD",
	MOP: "MOP",
	MGF: "MGF",
	MWK: "MWK",
	MYR: "MYR",
	MVR: "MVR",
	MTL: "MTL",
	MRO: "MRO",
	MUR: "MUR",
	MXP: "MXP",
	MNT: "MNT",
	MAD: "MAD",
	MZM: "MZM",
	NAD: "NAD",
	NPR: "NPR",
	ANG: "ANG",
	YUD: "YUD",
	NZD: "NZD",
	NIO: "NIO",
	NGN: "NGN",
	KPW: "KPW",
	NOK: "NOK",
	OMR: "OMR",
	PKR: "PKR",
	XPD: "XPD",
	PAB: "PAB",
	PGK: "PGK",
	PYG: "PYG",
	PEN: "PEN",
	PHP: "PHP",
	XPT: "XPT",
	PLN: "PLN",
	QAR: "QAR",
	RON: "RON",
	RUB: "RUB",
	RWF: "RWF",
	WST: "WST",
	STD: "STD",
	SAR: "SAR",
	SCR: "SCR",
	SLL: "SLL",
	XAG: "XAG",
	SGD: "SGD",
	SKK: "SKK",
	SBD: "SBD",
	SOS: "SOS",
	ZAR: "ZAR",
	LKR: "LKR",
	SHP: "SHP",
	SDP: "SDP",
	SRG: "SRG",
	SZL: "SZL",
	SEK: "SEK",
	CHF: "CHF",
	SYP: "SYP",
	TWD: "TWD",
	TZS: "TZS",
	THB: "THB",
	TOP: "TOP",
	TTD: "TTD",
	TND: "TND",
	TRY: "TRY",
	UGX: "UGX",
	AED: "AED",
	UYU: "UYU",
	USD: "USD",
	VUV: "VUV",
	VEF: "VEF",
	VND: "VND",
	YER: "YER",
	CNY: "CNY",
	ZRZ: "ZRZ",
	ZMK: "ZMK",
	ZWD: "ZWD",
};
const CampaignCouponStatus = {
	Burned: "BR",
	Waiting: "WT",
	Expired: "EX",
	Passive: "PS",
};

const CampaignExecutionType = {
	Online: "Online",
	Batch: "Batch",
};

const MenuCodes = {
	PaymentAdminLenderProgram: "BLPPAS",
	PaymentAdminCallCenter: "BCCPAS",
	CSLimitedClientSearch: "CSCESH",
	CSLimitedClientSummary: "CSCECS",
	CSLimitedIndividual: "CSCEPR",
	CSLimitedAddress: "CSCEAD",
	CSLimitedSigningOfficer: "CSCEAS",
	CSLimitedDcAccounts: "CSCEDA",
	CSLimitedCorporate: "CSCECP",
	CSLimitedBeneficiaryOwner: "CSCEBO",
	CSLimitedExternalAccount: "CSCEEA",
	CSLimitedKeyContact: "CSCEKC",
	CSLimitedRiskRating: "CSCERR",
	CSLimitedContracts: "CSCECT",
	CSLimitedPrograms: "CSCEPO",
	CSLimitedMemo: "CSCEMM",
	CSLimitedSecurity: "CSCESS",
	DCBankAccount_Approve: "DCBWRF",
	Contract_Approve: "CNTWRF",
	Parameter_ParameterDefinition: "333333",
	Prepaid_Card_Application: "444444",
	Prepaid_Prepaid_Group_Definition: "666666",
	Prepaid_MyProfile: "888888",
	Prepaid_BinDefinition: "999999",
	Prepaid_ReportGenerator: "101010",
	Prepaid_ListGenerator: "111111",
	RoleDefinition_Menu: "121212",
	Menu_Backoffice_EftClearingTransactionDdt: "PRDDTC",
	Menu_Client_EftClearingTransactionDdt: "DDTETD",
	EftClearingTransactionEftClient: "CEFTCT",
	Menu_Backoffice_RejectedReturnTransactionsDdt: "PRDDTR",
	Menu_Client_RejectedReturnTransactionsDdt: "DDTERR",
	Eft: "161616",
	ETransfer: "171717",
	DDT: "181818",
	Prepaid: "191919",
	UserAdmin: "202020",
	Eft_CreateEFT: "222221",
	Prepaid_AddOrUpdateContact: "232323",
	Backoffice_SiteVisit: "242424",
	Prepaid_AddOrUpdateAddress: "303030",
	Backoffice: "323232",
	Backoffice_ClientSetup: "343434",
	Backoffice_ClientSetupAutoDeposit: "BOCMIU",
	Backoffice_SalesAgentSetup: "SASETP",
	Backoffice_SalesPersonEftTransactionSearch: "SAEFTS",
	Backoffice_SalesPersonETransferTransactionSearch: "SAETSH",
	Backoffice_SalesPersonDdtTransactionSearch: "SADDTS",
	Backoffice_SalesPersonCdicTransactionSearch: "SACCSH",
	Backoffice_SalesPersonClientMonitoring: "SACSCS",
	Backoffice_Backoffice: "353535",
	Eft_AddNewCustomerEFT: "393939",
	ETransfer_AddNewCustomereTransfer: "424242",
	Transactions_RejectedReturnTransactions: "434343",
	Parameter_RiskQuestion: "454545",
	SystemAdministration: "464646",
	Parameter_BMOOriginator: "474747",
	Parameter_RiskQuestionType: "484848",
	ClearingSettlement: "525252",
	Eft_EftRejectedDailySettledItems: "545454",
	Parameter_AreaCodeDefinition: "555551",
	Parameter_CountryCodeDefinition: "565656",
	Parameter_CurrencyCodeParameters: "575757",
	Parameter_EndDayStepDefinition: "595959",
	Parameter_HolidayDaysDefinition: "606060",
	Parameter_EFTTransactionTypes: "616161",
	Parameter_EFTReturnedReasonCodes: "626262",
	Parameter_CanadaFinancialInstitutionsList: "636363",
	ETransfer_eTransferSettlementFiles: "727272",
	ETransfer_eTransferOutgoingTransfers: "787878",
	ETransfer_eTransferCompletedTransfers: "797979",
	Eft_EFTRetunedItemList: "808080",
	SEARCH_EtransferTransactionSearch: "868686",
	Transactions_ErrorTransactions: "878787",
	Transactions_PendingTransactions: "888881",
	Transactions_EtransferUnSettledUnProcessedTransactions: "898989",
	Eft_NotificationSettings: "909090",
	CardTransfer: "939393",
	Swift: "949494",
	MoneyTransfer: "959595",
	Banking: "989898",
	ATM: "999991",
	Banking_Cheque: "100100",
	AML_KYC: "101101",
	Support: "103103",
	Banking_BankAccountDashboard: "105105",
	Eft_EFTDailySettledItems: "108108",
	Profiles: "114114",
	ClearingSettlement_Reconciliation: "118118",
	Transactions_EftUnSettledUnProcessedTransactions: "121121",
	SEARCH_EftTransactionSearch: "122122",
	Parameter_DataModelDefinition: "126126",
	Parameter_ReportDataModelDefinition: "127127",
	Parameter_ReportPropertyDefinition: "129129",
	DDT_DDTList: "131131",
	ETransfer_NotificationSettings: "132132",
	DDT_NotificationSettings: "133133",
	Reports_CreateReport: "134134",
	Notification_AnnouncementDefinition: "135135",
	ETransfer_CreateEtransfer: "138138",
	Notification_NotificationDefinition: "142142",
	FeeProfiles_DdtFeeProfile: "143143",
	FeeProfiles_ReferralFeeProfile: "145145",
	SystemAdministration_Test: "146146",
	Banking_AccountSummary: "147147",
	Banking_TransferBetweenMyAccounts: "149149",
	SEARCH_CDICTransactionSearch: "151151",
	Banking_EftTransferLinkedAccount: "156156",
	Banking_LinkedAccounts: "157157",
	SystemAdministration_CharacterMaksing: "159159",
	Transactions_EftRecurringTransactionSearch: "160160",
	Transactions_EtransferRecurringTransactionSearch: "161161",
	Transactions_BackofficeEtransferRecurringTransactionSearch: "ETRTBO",
	AML_KYC_AMLKYCBlacklist: "162162",
	Eft_EftOutgoingFile: "167167",
	SystemAdministration_AuditLog: "170170",
	SystemAdministration_TestUser: "171171",
	Reports_TransactionDetailSearch: "172172",
	UserAdmin_ChangeOrResetPassword: "173173",
	UserAdmin_NewUserDefinition: "174174",
	Backoffice_TransactionApproval: "176176",
	ExecutiveReports_FundsMovement: "177177",
	Reports: "178178",
	RoleDefinition_Action: "180180",
	RoleDefinition_MenuAction: "181181",
	ExecutiveReports_RiskRatingSummary: "184184",
	RoleDefinition_MenuActionRight: "187187",
	RoleDefinition_UserAuthorityDefinition: "188188",
	ExecutiveReports_VolumeAnalysis: "189189",
	ExecutiveReports_ExecutiveSummary: "195195",
	DDT_CDICAccounts: "199199",
	LimitProfileMenu_ClientLimitProfile: "202202",
	ClearingSettlement_Eft: "205205",
	ClearingSettlement_ETransfer: "206206",
	Profiles_FeeProfiles: "210210",
	DDT_CDIC: "214214",
	Eft_Transactions: "216216",
	ETransfer_Transactions: "229229",
	Eft_Reports: "231231",
	Reports_ExecutiveReports: "232232",
	UserAdmin_UserDetail: "233233",
	Notification: "234234",
	Notification_NotificationTemplate: "235235",
	NotificationTemplate_TemplateTypeDefinition: "236236",
	NotificationTemplate_TemplateDefinition: "237237",
	RoleDefinition_UserRoleDefinition: "238238",
	Favorites: "241241",
	SEARCH: "242242",
	MONITORING: "243243",
	Parameter: "244244",
	Program: "245245",
	Reports_Merchant: "246246",
	Reports_Merchant_Reports_EFT: "247247",
	Reports_Merchant_Reports_ETransfer: "248248",
	Reports_Merchant_Reports_DDT: "249249",
	Header: "250250",
	FeeProfiles_FeeProfileList: "251251",
	Header_MyProfileHeader: "252252",
	FeeProfiles_FixedFeeProfile: "253253",
	FeeProfiles_WeightedFeeProfile: "254254",
	UserAdmin_RoleDefinition: "255255",
	Header_ApiSandBox: "257257",
	ClearingSettlement_MatchingCriteriaDefinition: "258258",
	AppTitleBar: "259259",
	AppTitleBar_Gavel: "260260",
	AppTitleBar_AttachMoney: "261261",
	AppTitleBar_Notifications: "262262",
	AppTitleBar_VolumeUp: "263263",
	ClearingSettlement_ClientReconciliation: "264264",
	Banking_NotificationSettings: "265265",
	Profiles_LimitProfileMenu: "267267",
	LimitProfileMenu_UserLimitProfile: "268268",
	Favorites_ManageFavoriteUserMenu: "271271",
	SEARCH_DDTTransactionSearch: "272272",
	Loyalty_Manage_Card_Loyalty_Card_Detail: "273273",
	Prepaid_LoyaltyTerminal: "275275",
	Prepaid_ProductDetail: "277277",
	Loyalty_Manage_Card_Loyalty_AssignedCard: "278278",
	Prepaid_ManageRisk: "282282",
	Prepaid_PosTransaction: "283283",
	Loyalty_Manage_Card_Loyalty_Transaction: "284284",
	Loyalty_Manage_Card_Loyalty_AutoLoad: "285285",
	AlertReports_ContractDeadlineAlert: "286286",
	Prepaid_LoyaltyXUsage: "287287",
	Prepaid_Loyalty_Manage_Card: "288288",
	ETransfer_Reports: "289289",
	Reports_AdHocReports: "290290",
	Prepaid_LoyaltyUpgParam: "291291",
	Prepaid_LoyaltyCatalogUsg: "292292",
	Prepaid_LoyaltyMerchantLst: "293293",
	DDT_Reports: "294294",
	Reports_ListGenerator: "295295",
	Parameter_AuditLog: "298298",
	TransactionAdjustment: "299299",
	ManualTransactionEntry: "MANTXN",
	Prepaid_KadirTestName: "301301",
	Prepaid_ConversionParameter: "303303",
	Parameter_GenericScreenDefinition: "304304",
	Program_DDTCDIC: "308308",
	DDTCDIC_DDTList: "309309",
	DDTCDIC_ListGenerator: "311311",
	DDTCDIC_CDICAccounts: "313313",
	Backoffice_ContractList: "315315",
	Backoffice_AgentEarnings: "317317",
	Backoffice_AgentStatement: "318318",
	Backoffice_AlertReports: "322322",
	AlertReports_ClientRiskReviewAlert: "324324",
	FeeProfiles_AgentCommissionDefinition: "325325",
	WireTransfer: "326326",
	WireTransfer_CreateWireTransfer: "327327",
	BaseTest: "328328",
	Parameter_GenericScreenTest: "329329",
	Support_OnlineSupport: "330330",
	Support_FAQManage: "331331",
	Support_FAQView: "332332",
	Support_UserGuideManage: "333331",
	Support_UserGuideView: "334334",
	SystemAdministration_Resource: "335335",
	SystemAdministration_ClientUserAuthorization: "336336",
	LENDING: "347347",
	LENDING_Performance: "348348",
	Performance_PortfolioPerformance: "349349",
	LENDING_PaymentAdmin: "350350",
	LENDING_BorrowerAdmin: "351351",
	LENDING_PartnerAdmin: "352352",
	Program_Eft: "353353",
	Eft_UnSettledUnProcessedTransactions: "354354",
	Eft_EftIncoming: "355355",
	Eft_BackofficeEftIncoming: "EFTINP",
	Eft_Transaction_Incoming: "EFTITR",
	Reports_Report_20190225124243: "356356",
	Banking_AutoLoadingInstruction: "357357",
	Backoffice_FeeProfileList: "358358",
	Reports_Report_20190226114936: "359359",
	Banking_EtransferTransferLinkedAccount: "360360",
	Cheque_ChequeOrder: "362362",
	Reports_Report_20190227092635: "363363",
	Cheque_ChequeManagement: "364364",
	SystemAdministration_name: "365365",
	EftTransactionSearch_TestName: "SDDSSS",
	Eft_EftIncomingWorkflow: "EFTINT",
	AML_KYC_TransactionAlert: "TRALER",
	AML_KYC_KycAlert: "KYCALE",
	AML_KYC_ClientDetail: "CLIDET",
	AML_KYC_CaseTransactionHistory: "CASETR",
	AML_KYC_TransactionAlertScenarioDefinition: "TASDEF",
	AML_KYC_KycVerificationScenarioDefinition: "KVSDEF",
	AML_KYC_DownJonesBlacklist: "DJBLAC",
	AML_KYC_DCBankBlacklistPool: "BACKBL",
	AML_KYC_ClientBlacklistPool: "CLIBLA",
	AML_KYC_InternalBlacklist: "INTBLA",
	AML_KYC_VerificationPriority: "VERPRI",
	SystemAdministration_Ufuk_Test: "UFUTES",
	Reports_Report_20190304094411: "378378",
	Reports_Report_20190304094528: "379379",
	EFTPredefinedReports: "EFTPRE",
	EFTAdHocReports: "EFTADH",
	ETransferPredefinedReports: "ETRPRE",
	ETransferAdHocReports: "ETRADH",
	DDTPredefinedReports: "DDTPRE",
	DDTAdHocReports: "DDTADH",
	LMSPredefinedReports: "LMSPRE",
	LMSAdHocReports: "LMSADH",
	LOSPredefinedReports: "LOSPRE",
	LOSAdHocReports: "LOSADH",
	BillPaymentPredefinedReports: "BILPRE",
	BillPaymentAdHocReports: "BILADH",
	BankingPredefinedReports: "BANPRE",
	BankingAdHocReports: "BANADH",
	PrepaidAdHocReports: "CMSGRP",
	ClientAccountActivities: "CLIACT",
	CloseAccount: "CLOACC",
	CallCenterEFTTransactions: "CALEFT",
	CallCenterEtransferTransactions: "CALETS",
	CallCenterDDTTransactions: "CALDDT",
	CallCenterCDICTransactions: "CALCDC",
	CallCenterClientMonitor: "CALLCS",
	CustomerDefinition: "CUSDEF",
	Eft_TraceApprove: "EFTTAP",
	Etransfer_TraceApprove: "ETRTAP",
	Eft_SuspiciousApprove: "EFTSAP",
	Etransfer_SuspiciousApprove: "ETRSAP",
	Ddt_SuspiciousApprove: "DDTSAP",
	Cdic_SuspiciousApprove: "CDISAP",
	Eft_SubmitApprove: "EFTSUB",
	Etransfer_SubmitApprove: "ETRSUB",
	Bill_CustomerList: "BILLPL",
	CallCenter_CustomerList: "CALCUS",
	BillCustomerTransactionList: "BCTLOP",
	BillClientTransactionList: "BTCLOP",
	ClientProductRestrictionProfile: "CLRSTD",
	DCBankProductRestrictionProfile: "DCRSTD",
	ClientBillRecurringList: "BILCLR",
	CustomerBillRecurringList: "BILCUR",
	Menu_CollectionParameter: "LMSCOL",
	Menu_AdminCollectionPool: "ACOLPO",
	Lms_BorrowerDetail: "LMSBDT",
	Lms_LoanDetail: "LMSLDT",
	Lms_PaymentAdmin: "LMSPAD",
	LOS_LoanApproval: "LOSLAP",
	Lms_ApplicantDetails: "LMSLDT",
	LOS_ApplicantDefinition: "LOSAPL",
	LOS_CreateApplication: "NWLOAN",
	LOS_QuickFundsTransfer: "LOSQFT",
	Lms_PortfolioPerformance: "LMSPER",
	Menu_MoneyTransferToCardHolder: "PRPMTC",
	Backoffice_Accounts: "ACCCUN",
	Menu_WalletCardRenawalList: "WLLEMB",
	Menu_CardRenawalList: "PPCRLM",
	Menu_LmsCardRenewalList: "LMSCES",
	Menu_DomesticAndInternationalReport: "CRDDAI",
	Menu_CardReport: "CRDRPT",
	Menu_SwitchReport: "CRDSWW",
	Menu_ChannelReport: "CRDCHN",
	Menu_ActiveCardReport: "CRDACC",
	Menu_CallCenterWireTransferTransactionSearch: "CLLCWT",
	Menu_ClientWireTransferTransactionSearch: "TSWTTL",
	Menu_BackOfficeTransactionSearchWireTransfer: "WRTCPY",
	Menu_LmsETransfer: "LMSETR",
	Lms_LimitAdjustmentForWorkflow: "LAFWWW",
	DDT_Transactions: "DDTTNX",
	DDT_Transactions_DdtRejectedReturnedTransactions: "DDTERR",
	DDT_Transactions_DdtClearingTransactionSearch: "DDTETD",
	DDT_DdtAccountActivities: "DDTACA",
	DDT_Transactions_DdtTransactions: "272272",
	Menu_Backoffice_DDT_Transactions_IncomingEftTransactions: "998855",
	Menu_Client_DDT_Transactions_IncomingEftTransactions: "998866",
	DDT_Transactions_IncomingETransferTransactions: "DDTINE",
	Menu_SubClientIncomingEftTransactions: "SCINEF",
	DDT_ETransfer_Incoming_TransactionSearch: "DDTINE",
	DDT_CDIC_Etransfer_Incoming_TransactionSearch: "DDTINB",
	Menu_BackOfficeCardFeeProfile: "BCKCFP",
	Menu_CardFeeProfile: "CRDFPR",
	Menu_ClientCardFeeProfile: "CCDFPR",
	Menu_BackofficeTransactionFeeProfile: "BCKTRX",
	Menu_TransactionFeeProfile: "TRXFPR",
	Menu_ClientCardHolderFee: "CCRCHF",
	Menu_ClientTransactionLimitProfile: "CMSTLM",
	Menu_LimitProfile: "PRLPTP",
	Menu_DCLimitProfile: "PRLIPF",
	Menu_ClientProductManagement: "CMSCPM",
	Menu_MyAccountActivityReport: "MYRPDF",
	CMS_TransactionManagement_WalletTransaction: "WALTRA",
	CMS_TransactionManagement_CardTransaction: "WALCTR",
	CallCenter_WalletTransaction: "CALLWT",
	CallCenter_CardTransaction: "CALLCT",
	CallCenter_CardMonitoring: "CLCNCM",
	Fee_Transaction: "FEESRC",
	Menu_ExportCardEmbossFile: "CASCEF",
	Menu_ImportCardEmbossFile: "CMSIMF",
	Menu_CardMonitoring: "CRDMON",
	Menu_BackofficeCardMonitoring: "WLLMON",
	Menu_CallCenterCardMonitoring: "CLCNCM",
	Menu_CorporateCard : "CORPCC",
    Menu_FrozenCardDetails : "FRZCRD",
    Menu_CaaSLinks : "CAASLK",
};
const ActionCodes = {
	Submit: "Submit",
	Create: "Create",
	Delete: "Delete",
	Update: "Update ",
	Refresh: "Refresh",
	Get: " Get",
	Search: "Search ",
	Send: "Send ",
	Rollback: "Rollback ",
	ReSubmit: "Re-Submit",
	CreateFile: "CreateFile",
	CreateReport: "CreateReport",
	Preview: "Preview ",
	Upload: "Upload",
	View: "View ",
	Print: "Print",
};
const EtransferRequestType = {
	Request: "R",
	Transfer: "T",
};

const BankFileStatus = {
	Pending: "P",
	Rejected: "R",
	Success: "S",
	Sent: "T",
	Error: "E",
	InProgress: "I",
	Waiting: "W",
};
const LicenseRegistrationEntity = {
	FINTRAC: "FINTRAC",
	AMF: "AMF",
	Other: "O",
};

//#region FanClub

const BillPayType = {
	Individual: "I",
	Recurring: "R",
	File: "F",
};

const FcRole = {
	Backoffice: "1",
	User: "2",
	Merchant: "4",
};
const FcGender = {
	Male: "0",
	Female: "1",
};
const FcCountry = {
	Canada: "CA",
	Turkey: "TR",
};
const FcCity = {
	Konya: "KONYA",
	Toronto: "TORONTO",
};
//#endregion

const WireStatus = {
	Successfull: "S",
	DCRejection: "DR",
	SwiftRejection: "SR",
	AutoRejection: "AR",
};
const WireSubStatus = {
	Send: "S",
	LimitException: "LE",
	Limit: "L",
	KYC: "KYC",
	Error: "E",
	Country: "C",
	InsufficentFunds: "I",
	Success: "SC",
	Pending: "P",
};
const ResourceType = {
	Menu: "0",
	Error: "1",
	Others: "2",
};
const PropertyType = {
	Date: "date",
	Text: "text",
	Number: "number",
	Decimal: "decimal",
	Select: "select",
	Email: "email",
	Int: "int",
};
const AmlReviewType = {
	TransactionBased: "TransactionBased",
	Periodic: "Periodic",
};

const WalletAutoLoadType = {
	EFT: "EFT",
	eTransfer: "eTransfer",
	MyAccounts: "MyAccounts",
};

const CardLevel = {
	MainCard: "M",
	SupplementaryCard: "S",
};

const TransactionOrderStatus = {
	Pending: "P",
	Working: "I",
	Finished: "F",
	Error: "E",
	Cancelled: "C",
	Freezed: "D",
};

const ExpressionType = {
	Equal: "Equal",
	Like: "Like",
};

const EmvScriptPoolInsertType = {
	Single: "S",
	File: "F",
};
const InventoryOrderStatusType = {
	Ordered: "O",
	Received: "R",
	Created: "C",
};
const CheckType = {
	CheckIn: "Check-In",
	CheckOut: "Check-Out",
	CheckInId: "I",
	CheckOutId: "O",
};
const ClearingProcessStatus = {
	ReadyToSend: "RD",
};

const ClearingDirection = {
	Outgoing: "O",
};

const ClearingIssuerFlag = {
	Acquirer: "A",
	Issuer: "I",
};

const CardFindType = [
	{
		Id: 1,
		Text: "Card Id",
	},
	{
		Id: 2,
		Text: "Card Number",
	},
];
const DdtFindType = [
	{
		Id: 1,
		Text: "Customer Number",
	},
	{
		Id: 2,
		Text: "DDT Id",
	},
	{
		Id: 3,
		Text: "DDT Number",
	},
];
const ProfileStatus = {
	Active: "A",
	Inactive: "IA",
	Closed: "C",
};
const KycVerificationType = {
	IdVerification: "Id",
	AddressVerification: "Address",
	AccountVerification: "Account",
};

const TransactionMoneyTransferType = [
	{
		Id: 1,
		Text: "Customer",
	},
	{
		Id: 2,
		Text: "Card",
	},
];

const LOSLoanType = {
	SecureCard: "0",
	Loan: "1",
	LOC: "2",
};

const CashLimitUsageType = {
	Fixed: "F",
	Ratio: "R",
};

const LOSApprovalStatus = {
	Saved: "0",
	Approved: "1",
	Rejected: "2",
	OnHold: "3",
	SentApproval: "4",
};

const LOSIdenticationMethod = {
	DualProcessMethod: "D",
	InPersonIDVerification: "I",
	CreditFileMethod: "P",
};

const BillStatusType = {
	Waiting: "Waiting",
	Canceled: "Canceled",
	Rejected: "Rejected",
	Payed: "Payed",
	Completed: "Completed",
	TelpayAccess: "TelpayAccess",
};
const LOSApplicantStatus = {
	Saved: "0",
	Completed: "1",
};
const LOSApplicationStatus = {
	Open: "0",
	Saved: "2",
};
const DaysOfWeek = [
	{
		Id: 1,
		Text: "Monday",
	},
	{
		Id: 2,
		Text: "Tuesday",
	},
	{
		Id: 3,
		Text: "Wednesday",
	},
	{
		Id: 4,
		Text: "Thursday",
	},
	{
		Id: 5,
		Text: "Friday",
	},
	{
		Id: 6,
		Text: "Saturday",
	},
	{
		Id: 0,
		Text: "Sunday",
	},
];
const DaysOfMonth = [
	{
		Id: 1,
		Text: "1",
	},
	{
		Id: 2,
		Text: "2",
	},
	{
		Id: 3,
		Text: "3",
	},
	{
		Id: 4,
		Text: "4",
	},
	{
		Id: 5,
		Text: "5",
	},
	{
		Id: 6,
		Text: "6",
	},
	{
		Id: 7,
		Text: "7",
	},
	{
		Id: 8,
		Text: "8",
	},
	{
		Id: 9,
		Text: "9",
	},
	{
		Id: 10,
		Text: "10",
	},
	{
		Id: 11,
		Text: "11",
	},
	{
		Id: 12,
		Text: "12",
	},
	{
		Id: 13,
		Text: "13",
	},
	{
		Id: 14,
		Text: "14",
	},
	{
		Id: 15,
		Text: "15",
	},
	{
		Id: 16,
		Text: "16",
	},
	{
		Id: 17,
		Text: "17",
	},
	{
		Id: 18,
		Text: "18",
	},
	{
		Id: 19,
		Text: "19",
	},
	{
		Id: 20,
		Text: "20",
	},
	{
		Id: 21,
		Text: "21",
	},
	{
		Id: 22,
		Text: "22",
	},
	{
		Id: 23,
		Text: "23",
	},
	{
		Id: 24,
		Text: "24",
	},
	{
		Id: 25,
		Text: "25",
	},
	{
		Id: 26,
		Text: "26",
	},
	{
		Id: 27,
		Text: "27",
	},
	{
		Id: 28,
		Text: "28",
	},
	{
		Id: 29,
		Text: "29",
	},
	{
		Id: 30,
		Text: "30",
	},
	{
		Id: 31,
		Text: "31",
	},
];
const MonthsOfYear = [
	{
		Id: 1,
		Text: "January",
	},
	{
		Id: 2,
		Text: "February",
	},
	{
		Id: 3,
		Text: "March",
	},
	{
		Id: 4,
		Text: "April",
	},
	{
		Id: 5,
		Text: "May",
	},
	{
		Id: 6,
		Text: "June",
	},
	{
		Id: 7,
		Text: "July",
	},
	{
		Id: 8,
		Text: "August",
	},
	{
		Id: 9,
		Text: "September",
	},
	{
		Id: 10,
		Text: "October",
	},
	{
		Id: 11,
		Text: "November",
	},
	{
		Id: 12,
		Text: "December",
	},
];
const SwitchNetworkType = {
	MasterCard: "MASTERCARD",
	Visa: "VISA",
	Moneris: "MONERIS",
};

const PgmCode = {
	MasterCard: "MASTERCARD",
	Visa: "VISA",
	Interact: "INTERACT",
};

const ETransferMoneySendStatus = {
	TransferInitiated: "1",
	TransferAvailable: "3",
	AuthenticationFailure: "5",
	AuthenticationSuccessful: "6",
	Declined: "7",
	Cancelled: "8",
	Expired: "9",
	Completed: "10",
	DepositMechanismSpecified: "11",
	DepositMechanismFailed: "13",
	DepositMechanismCorrected: "12",
	DepositMechanismDeclined: "14",
	InSettlement: "15",
};

const FinScanSearchStatus = {
	ManualVerification: "0",
	Unverified: "1",
	Verified: "2",
	Rejected: "4"
};

const FinScanSearchResultType = {
	ListPartialMatch: "ListPartialMatch",
	ListNotMatch: "ListNotMatch",
	ListExactMatch: "ListExactMatch"
};

const CanadaPostIdVerificationStatus = {
	Cleared: "Cleared",
	Denied: "Denied",
	None: "None",	
	Expired : "Expired"
};
const ETransferMoneyRequestStatus = {
	Initiated: "1",
	Available: "2",
	Fulfilled: "3",
	Declined: "4",
	Cancelled: "5",
	Expired: "6",
	DepositFailed: "7",
	DepositeComplete: "8",
};

const PgmCodeType = {
	MasterCard: "MASTERCARD",
	Visa: "VISA",
	Interact: "INTERACT",
};

const ChargeBack = {
	FunctionCodeFirstChargeBack: "450;453;",
	FunctionCodeSecondChargeBack: "451;454;",
};

const ClearingDisputeStatus = {
	ClearingDisputeReceiverStatus: "ClearingDisputeReceiverStatus",
	ClearingDisputeSenderStatus: "ClearingDisputeSenderStatus",
};

const ClearingFeeFunctionCode = {
	FunctionCodeFee: "700;",
	FunctionCodeFeeReturn: "780;",
	FunctionCodeFeeResubmission: "781;",
	FunctionCodeFeeArbitrationReturn: "782;",
	FunctionCodeSystemFee: "783;",
};

const ClearingFeeType = {
	Fee: "Fee",
	Funds: "Funds",
	SystemFee: "SystemFee",
};
const InteracRedirectURL = {
	LocalStorageKey: "IntRqDtV",
};

const RoleLevel = {
	BackofficeAdmin: "BackofficeAdmin",
	BackofficeStandardUser: "BackofficeStandardUser",
	BackofficeSuperAdmin: "BackofficeSuperAdmin",
	ClientAdmin: "ClientAdmin",
	ClientStandardUser: "ClientStandardUser",
	SalesPerson: "SalesPerson",
};

const UserStatus = {
	Ok: "1",
	PasswordLocked: "2",
	Blocked: "3",
	Expired: "4",
	Inactive: "5",
	OnHold: "6",
};

const WireMsbStatusValue = {
	Pending: "Pending",
	Reject: "Reject",
	Approve: "Approve",
};

const LocationRange = {
	latitudeMax: 90,
	latitudeMin: -90,
	longitudeMax: 180,
	longitudeMin: -180,
};

const MenuAuthorityDefinitionCode = {};

const CMSTemplateType = {
	TransactionLimit: "1",
	CardApplication: "2",
	CustomerCreation: "3",
	MoneyDeposit: "4",
};

const WarningMessage = {
	Empty: "cannot be empty.",
};
const BankingAccountType = {
	CODE: "BankingAccountType",
	SettlementCode: "SettlementAccount",
	DCBankAccount: "DBA",
	DDTAccount: "DDT",
	CDIC: "CDIC",
	PrimaryAccount: "PA",
	SecondaryAccount: "SA",
	SecurityAccount: "CA",
	SecurityNumber: "SCN",
	FeeAccount: "FA",
	SettlementAccount: "CASA",
	TelpaySettlementAccount: "TELPAYA",
	StoreAccount: "STA",

	WalletAccount: "WA",
	WalletChequeAccount: "WCA",
	PrepaidCardAccount: "PPCA",
	CustomerMainAccount: "CCMACC",

	MainAccountSummaryType: "M",
	PooledAccount: "PooledAccount",
	IsClientCustomer: "IsClientCustomer",
	IsClient: "IsClient",
	Account: "Account",
};
const LOSApplicationCardStatus = {
	Passive: "0",
	Active: "1",
	Cancel: "3"
};
const LmsLimitAdjustmentHistoryStatus = {
	Pending: "0",
	Approved: "1",
	Rejected: "2",
	NeedsSecurityAmount: "3"
};

const TspTokenStatus = {
	Pending: "0",
	Approved: "1",
	Rejected: "2",
	NeedsSecurityAmount: "3"
};

const MdesTokenStatusDefinition = [
	{ Value: 1, Text: "INACTIVE" },
	{ Value: 2, Text: "ACTIVE" },
	{ Value: 3, Text: "SUSPENDED" },
	{ Value: 4, Text: "DEACTIVATED" }
];

const MdesTokenTypeDefinition = [
	{ Value: 1, Text: "EMBEDDED_SE" },
	{ Value: 2, Text: "CLOUD" },
	{ Value: 3, Text: "STATIC" }
];

const MdesWalletDefinition = [
	{ Value: 1, Text: "APPLE" },
	{ Value: 2, Text: "GOOGLE" },
	{ Value: 3, Text: "SAMSUNG" },
	{ Value: 4, Text: "MDES 4 MERCHANT" }
];

const MdesTokenSourceDefinition = [
	{ Value: 1, Text: "ACCOUNT_ON_FILE" },
	{ Value: 2, Text: "ACCOUNT_ADDED_MANUALLY" },
	{ Value: 3, Text: "ACCOUNT_ADDED_VIA_APPLICATION" },
	{ Value: 4, Text: "DEFAULT" }
];


const MdesTokenRequestorDefinition = [
	{ Value: 1, Text: "APPLE PAY" },
	{ Value: 2, Text: "GOOGLE PAY" },
	{ Value: 3, Text: "SAMSUNG PAY" },
	{ Value: 4, Text: "NETFLIX" }
];

const MdesRequestTypeDefinition = [
	{ Value: 1, Text: "MDES authorizeService" },
	{ Value: 2, Text: "CMS authorizeService" },
	{ Value: 3, Text: "MDES requestActivationMethods" },
	{ Value: 4, Text: "CMS requestActivationMethods" },
	{ Value: 5, Text: "MDES deliverActivationCode" },
	{ Value: 6, Text: "CMS deliverActivationCode" },
	{ Value: 7, Text: "MDES notifyServiceActivated" },
	{ Value: 8, Text: "CMS notifyServiceActivated" },
	{ Value: 9, Text: "MDES notifyTokenUpdated" },
	{ Value: 10, Text: "MDES notifyTokenUpdated" }
];

const MdesRequestsStatusDefinition = [
	{ Value: 1, Text: "Service Authorized - MDES" },
	{ Value: 2, Text: "Service Authorized - CMS" },
	{ Value: 3, Text: "Activation Methods Requested - MDES" },
	{ Value: 4, Text: "Activation Methods Requested - CMS" },
	{ Value: 5, Text: "Activation Code Delivered - MDES" },
	{ Value: 6, Text: "Activation Code Delivered - CMS" },
	{ Value: 7, Text: "Token Activated - MDES" },
	{ Value: 8, Text: "Token Activated - CMS" }
];

const DueTermOfStatement = [
	{	Id: 1, Text: "1 Days"},
	{	Id: 5, Text: "5 Days"},
	{	Id: 21, Text: "21 Days"},
	{	Id: 25, Text: "25 Days"},	
];

const EndOfDayPageFilter = [
	{
		Id: 0,
		Text: "Last End Of Day"
	},
	{
		Id: 1,
		Text: "3 Days"
	},
	{
		Id: 2,
		Text: "7 Days"
	}
];

export {
	TspTokenStatus,
	LmsLimitAdjustmentHistoryStatus,
	LOSApplicationCardStatus,
	MenuAuthorityDefinitionCode,
	WarningMessage,
	InteracRedirectURL,
	RoleLevel,
	UserStatus,
	CanadaPostIdVerificationStatus,
	DaysOfMonth,
	DaysOfWeek,
	MonthsOfYear,
	ProfileStatus,
	CampaignExecutionType,
	CampaignCouponStatus,
	CurrencyType,
	CampaignEngineMethodType,
	PropertyType,
	DDTStatus,
	WireSubStatus,
	WireStatus,
	LicenseRegistrationEntity,
	BankFileStatus,
	ActionCodes,
	EtransferRequestType,
	UserLimitType,
	Direction,
	IdentityType,
	SalesChannel,
	RiskLightRating,
	FinScanSearchStatus,
	IdentificationMethod,
	AddressType,
	LimitProfileType,
	PriorityRegularType,
	TxnEffect,
	FeeType,
	FeeProfileType,
	TransactionType,
	PortalType,
	PhoneType,
	LimitType,
	ApprovalStatus,
	ApprovalRequest,
	OperationType,
	GetType,
	GridColumnType,
	AuthenticationMethod,
	AuthenticatorStatus,
	SessionStatus,
	Portals,
	MainSearchItems,
	UnSettledMainSearchItem,
	PeriodCodes,
	ProgramCodes,
	WeekDays,
	DateFilter,
	DateHomePageFilter,
	LastPaymentDateAfter,
	SelectNextDayList,
	PersonalizedExpDateList,
	ReportType,
	DynamicReportType,
	ReportTypeForWalletAccounts,
	NotificationUsageType,
	LoginUserType,
	EFTTransactionTypes,
	EFTPriorityTypes,
	EtransferTransactionTypes,
	EtransferType,
	EtransferFrequencyCodes,
	EtransferPeriodCodes,
	ETransferDayOfMonthTypes,
	BankingAccountTypes,
	AccountStatus,
	NotificationStatus,
	BankTransactionStatus,
	DocumentType,
	ETransferWeekOfMonthTypes,
	ClientType,
	CustomerStatus,
	AmlKycRiskLevel,
	CustomerBusinessType,
	BankProgramType,
	PortalProgram,
	DCBankRowId,
	SecurityType,
	RoleGroup,
	NotificationSendType,
	DefaultBankInformation,
	InformationType,
	BankTransactionStatusParameterDesc,
	ViewSourceType,
	FileCode,
	FileType,
	FileExtension,
	CrudType,
	EFTEntryType,
	EtransferEntryType,
	MenuCodes,
	OrderStatusType,
	FcRole,
	FcGender,
	FcCountry,
	FcCity,
	BillPayType,
	ResourceType,
	AmlReviewType,
	WalletAutoLoadType,
	CardLevel,
	TransactionOrderStatus,
	ExpressionType,
	TrueFalse,
	EmvScriptPoolInsertType,
	CardFindType,
	InventoryOrderStatusType,
	CheckType,
	DdtFindType,
	KycVerificationType,
	TransactionMoneyTransferType,
	ClearingDirection,
	ClearingIssuerFlag,
	ClearingProcessStatus,
	LOSLoanType,
	CashLimitUsageType,
	LOSApprovalStatus,
	BillStatusType,
	LOSIdenticationMethod,
	SwitchNetworkType,
	LoanLedgerCardIconType,
	PgmCode,
	ETransferMoneySendStatus,
	ETransferMoneyRequestStatus,
	PgmCodeType,
	ChargeBack,
	ClearingDisputeStatus,
	ClearingFeeFunctionCode,
	ClearingFeeType,
	LOSApplicantStatus,
	LOSApplicationStatus,
	WireMsbStatusValue,
	LocationRange,
	EtransferApplicationTypes,
	CMSTemplateType,
	BankingAccountType,
	EmbossType,
	CardTransferTypes,
	FinScanSearchResultType,
	MdesTokenStatusDefinition,
	MdesTokenTypeDefinition,
	MdesWalletDefinition, 
	MdesTokenSourceDefinition, 
	MdesTokenRequestorDefinition, 
	MdesRequestTypeDefinition,
	MdesRequestsStatusDefinition,
	TransactionTraceStatus,
	DueTermOfStatement,
	EndOfDayPageFilter,
	DcbETransferType
};


export * from "views/Constants/Constant.AmlKyc.js";
