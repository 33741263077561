import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Formatter from "core/Formatter";
import PropTypes, { any } from "prop-types";
import React, { Component } from "react";
import { GenericCheckInput, GenericExpansionPanel, GenericSelectInput } from "views/Components/Generic";


class Biller extends Component {

	constructor(props) {
		super(props);

		this.state = {
			customerToken: "0",
			Disabled: false
		};


		this.ValueChanged = this.ValueChanged.bind(this);
	}

	ValueChanged(name, newValue, data) {
		this.props.onModelChange(model => {
			model[name] = newValue;

			if (name == "PayeeId") {
				model.PayeeName = data.Name;
				model.PayeerFinancialInstitutionRowId = data.FinancialInstitutionRowId;
				model.PayeerFinancialInstitutionBranchRowId = data.FinancialInstitutionBranchRowId;
				model.PayeerFinancialInstitutionId = Formatter.PadLeft(data.BankNumber, 3, "0");
				model.PayeerTransitNumber = Formatter.PadLeft(data.BranchNumber, 5, "0");
				model.PayeerAccountNumber = data.AccountNumber;
			}
			if (name == "Id") {
				model.GroupId = data.GroupId;
				model.TypeId = data.TypeId;
				model.Name = data.Name;
				model.Code = data.Code;
				model.BillerId = data.Id;
				model.ProvinceId = data.ProvinceId;
			}
			if (name == "NewClient") {
				if (newValue) {
					model.IsCustomerPopupOpen = true;
					this.setState({ isCustomerAdded: false });
				}
			}
			if (name == "TransferMemoId") {
				model.TransferMemo = data.Memo;
			}
			if (name == "IsHaveMemo") {
				if (newValue) model.TransferMemo = "";
				model.TransferMemoId = "";
			}
			if (name == "TransactionType")
				model.TransactionTypeCode = data.ParameterValue;

			if (name == "NewBiller") {
				if (newValue == true) {
					model.IsAgainNewBiller = true;
					model.IsNewBiller = false;
					model.Id = any;
					model.Code = any;
					model.GroupId = any;
					model.TypeId = any;
					model.ProvinceId = any;
					model.Name = any;
					this.setState({
						model: model

					});

				}
				else {
					model.IsAgainNewBiller = false;
					model.Id = any;
					model.Code = any;
					model.GroupId = any;
					model.TypeId = any;
					model.ProvinceId = any;
					model.IsNewBiller = true;
					model.Id = any;
					model.Name = any;
					this.setState({
						model: model
					});
				}
			}
			return model;
		});
	}




	render() {

		const { model, Disabled } = this.props;
		return (
			<div >

				<Card style={{ height: "90%", opacity: "1" }}>

					<GenericExpansionPanel Title="Payee">
						<CardBody>
							<GridContainer >
								<GridItem xs={12} sm={10}>

									<GenericSelectInput
                             
										Name="Id"
										LabelText="Payee Name"
										Method="POST"
										Url="/bankapi/v1.0/Biller/GetAllBiller"
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="Name"
										Value={model.Id}
										Disabled={Disabled}
										ValueChanged={this.ValueChanged}
									/>
									{/* <GenericTextInput
										Name="Code"
										LabelText="Payee Code"
										inputProps={{ maxLength: 7 }}
										Value={model.Code}
										Disabled={!model.IsAgainNewBiller}
										ValueChanged={this.ValueChanged}
									/> */}
								</GridItem>

								<GridItem xs={12} sm={2}>
									<GenericCheckInput
										key={model.IsNewBiller}
										Name="NewBiller"
										LabelText="New Payee"
										Value={model.NewBiller}
										ValueChanged={this.ValueChanged}
										Disabled={Disabled} />

								</GridItem>


							</GridContainer>

						</CardBody>
					</GenericExpansionPanel>
				</Card>

			</div>
		);
	}
}

Biller.propTypes = {
	model: PropTypes.object,
	onModelChange: PropTypes.func,
	Disabled: PropTypes.bool
};

export default Biller;