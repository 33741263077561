import React from "react";
import { GenericAlert } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { Proxy, withArtifex } from "core";
import "./Box.scss";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
const VisaImage = require("assets/img/visaBrand.png");
const InteracImage = require("assets/img/interac.png");
const MastercardImage = require("assets/img/mastercardBrand.png");
class VirtualCardIframe extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			vModel: {},
			alert: null,
			isLoading: false,
			appSettings: {
				"IsModifyAllowed": false,
				"Base64Logo": null
			},
			cardDetail: {},
			cardCVV: "",
			cardbrand: {},
			cardVisible: false,
			cvvVisible: false,
			isBtnTimerRunning: false,
			isCvvBtnDisabled: false,
			isCardBtnDisabled: false
		};

		this.timeoutId = null;

		this.GetCVV = this.GetCVV.bind(this);

	}

	ShowMessage = (type, title, message) => {
		this.setState({ alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.setState({ alert: "" })} /> });
	};

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: "" })} OnConfirm={onConfirm} />
		});
	};

	ShowConfirmDelete = (onConfirm) => {
		this.ShowConfirmMessage("warning", "Warning", "Delete this Credit Card?", onConfirm);
	};

	ShowMessageNode = (type, title, message) => {
		this.setState({ alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.setState({ alert: "" })} /> });
	};

	IsNullOrEmpty(value) {
		return (value == null || value === "" || value === ""
			|| value === "\"\"" || value === "\"\"");
	}

	JavascriptgetURLParameterValues(parameterName, url) {
		if (!url) url = window.location.href;
		parameterName = parameterName.replace(/[\[\]]/g, "\\$&amp;");
		var regularExpression =
			new RegExp("[?&amp;]" + parameterName + "(=([^&amp;#]*)|&amp;|#|$)"),
			results = regularExpression.exec(url);
		if (!results) return null;
		if (!results[2]) return "";
		return decodeURIComponent(results[2].replace(/\+/g, " "));
	}

	componentDidMount() {
		window.resizeTo(500, 500);
		this.loginWithTrackingId().then(() => {
			this.GetCard(parseInt(this.JavascriptgetURLParameterValues("CardId")),true);
		});
	}

	componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }

	deleteCardInformation = () => {
		this.props.showMessage("warning", "Warning", "Session Expired");
		this.setState({ cardDetail: {}, cardCVV: "", cardbrand: {}, cardVisible: false });
	};

	buttonTimer = () => {
		if(this.state.isBtnTimerRunning) {
			return;
		}

		const DURATION = 90 * 1000; // 90 seconds
		this.setState({ isBtnTimerRunning: true });
		this.timeoutId = setTimeout(() => {
			this.setState({ isCvvBtnDisabled: true, isCardBtnDisabled: true });
			this.deleteCardInformation();
		}, DURATION);
	};

	getBase64Logo(trackingId){
		this.setState({ isLoading: true });

		Proxy.POST(
			"/bankapi/v1.0/VisaDirect/SearchIframeApiKeyList",
			{ EncryptedApiKey: trackingId },
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				if (responseData.Item !== null) {
					let appSett = this.state.appSettings;
					appSett.Base64Logo = responseData.Item[0].Base64Logo;
					this.setState({ appSettings: appSett });
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	loginWithTrackingId() {
		this.setState({ isLoading: true });
		let trackingId = this.JavascriptgetURLParameterValues("TrackingID");

		return Proxy.POST(
			"/coreapi/v1.0/Authentication/VisaDirectThirdPartyLogin",
			trackingId,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				const user = {
					SessionId: responseData.DCBankAccessToken, ForceTwoFactorAuth: responseData.ForceTwoFactorAuth,
					SessionStatus: responseData.SessionStatus
				};

				localStorage.setItem("user", JSON.stringify({ SessionId: user.SessionId }));
				let appSett = this.state.appSettings;
				appSett.IsModifyAllowed = true;
				this.setState({ appSettings: appSett });
				this.getBase64Logo(trackingId);
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	GetCard = (cardId, onlyImage) => {
		this.setState({ isLoading: true });
		let trackingID = this.JavascriptgetURLParameterValues("TrackingID");
		let sessionID = this.JavascriptgetURLParameterValues("SessionID");

		Proxy.POST(
			"/bankapi/v1.0/VisaDirect/GetVirtualCardDetail",
			{
				CardId: cardId,
				TrackingId: trackingID,
				SessionId: sessionID,
				OnlyImage: onlyImage
			},
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ cardDetail: {}, cardCVV: "", cardbrand: {}, isLoading: false });
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (onlyImage == true) {
					this.setState({ cardDetail: responseData.Item });
				}
				else {
					this.setState({ cardDetail: responseData.Item, cardVisible: true });
				}

				this.setState({ isLoading: false });
				if (responseData.Item.CardBrand == "Interac") {
					var styleInt = {
						height: "27px",
						width: "80px",
						backgroundImage: "url('" + InteracImage.toString() + "')",
						backgroundPosition: "right",
						backgroundSize: "contain",
						backgroundRepeat: " no-repeat",
					};
					this.setState({ cardbrand: styleInt });
				} else if (responseData.Item.CardBrand == "Mastercard") {
					var styleMasterCard = {
						height: "35px",
						width: "100px",
						backgroundImage: "url('" + MastercardImage.toString() + "')",
						backgroundPosition: "right",
						backgroundSize: "contain",
						backgroundRepeat: " no-repeat"
					};
					this.setState({ cardbrand: styleMasterCard });
				} else if (responseData.Item.CardBrand == "Visa") {
					var styleVisa = {
						height: "27px",
						width: "80px",
						backgroundImage: "url('" + VisaImage.toString() + "')",
						backgroundPosition: "right",
						backgroundSize: "contain",
						backgroundRepeat: " no-repeat",
					};
					this.setState({ cardbrand: styleVisa });
				}
				else {
					var styleOther = {
						disabled: "true",
					};
					this.setState({ cardbrand: styleOther });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	};

	GetCVV = () => {
		this.buttonTimer();
		this.setState({ cardVisible: false });
		if (!this.state.cvvVisible) {
			this.setState({ isLoading: true });
			let cardid = this.JavascriptgetURLParameterValues("CardId");
			let trackingID = this.JavascriptgetURLParameterValues("TrackingID");
			let sessionID = this.JavascriptgetURLParameterValues("SessionID");

			Proxy.POST(
				"/bankapi/v1.0/VisaDirect/GetCvv",
				{
					CardId: cardid,
					TrackingId: trackingID,
					SessionId: sessionID
				},
				(responseData) => {
					this.setState({ isLoading: false });
					if (!responseData.IsSucceeded) {
						this.props.showMessage("error", "Error", responseData.ErrorDescription);
						this.setState({ cardDetail: {}, cardCVV: "", cardbrand: {}, isLoading: false });
						return;
					}
					this.setState({ cardCVV: responseData.Item.EncCvv });
					this.setState({ cvvVisible: true });
				},
				(error) => {
					this.setState({ isLoading: false });
					this.props.showMessage("error", "Error", error);
					this.setState({ cardDetail: {}, cvvVisible: false });
				}
			);
		}
		else {
			this.setState({ cvvVisible: false });
		}
	};

	GetCardShow = () => {
		this.buttonTimer();
		this.setState({ cvvVisible: false });
		if (this.state.cardVisible) {
			this.setState({ cardVisible: false, cardbrand: {} });
			return;
		}
		if (!(this.state.cardVisible)) {
			this.GetCard(parseInt(this.JavascriptgetURLParameterValues("CardId")), false);
			return;
		}
	};

	render() {
		const { cardDetail, cardCVV, cardbrand, cardVisible, cvvVisible, isLoading } = this.state;

		return (
			<div className="virtual-card">
				<LoadingComponent Show={isLoading} />
				<div className="virtual-card__container">
					<div className="virtual-card__container__nickname">
							{cardVisible ? cardDetail.CardNickName : ""}
					</div>
					<div className="virtual-card__container__card" style={{ backgroundImage: "url(' data:image/png;base64," + cardDetail.CardImageBase64 + "')", backgroundSize: "cover", backgroundPosition: "center center"}}>
						<div className="virtual-card__container__card__details">
							<div className="virtual-card__container__card__details__name" style={{ color: cardDetail.CardFontColor }}>
								<div className="virtual-card__container__card__details__name__header">Cardholder Name</div>
									<span style={{ color: cardDetail.CardFontColor }}>
										{cardDetail.CardHolderName} 
									</span>   
							</div>
							<div className="virtual-card__container__card__details__number" style={{ color: cardDetail.CardFontColor }}>
								<div className="virtual-card__container__card__details__number__header">Account Number</div>
								<div className="virtual-card__container__card__details__number-row">
									<div>
										<span style={{ color: cardDetail.CardFontColor }} >
											{cardVisible ? cardDetail.CardNumber : "*********************"}
										</span>
									</div>
								{ cardVisible &&
									<div className="virtual-card__container__card__details__number-row__icon">
										<FileCopyOutlinedIcon
											className="virtual-card__container__card__details__number-row__copy-img" 
											style={{ color: cardDetail.CardFontColor, height: "1rem", width: "1rem" }}
											onClick={() => {
												// eslint-disable-next-line no-unused-expressions
												window.ReactNativeWebView?.postMessage(cardDetail.CardNumber);
												navigator.clipboard.writeText(cardDetail.CardNumber);
											}}
										/>
									</div>	
								}
								</div>
							</div>
							<div className="virtual-card__container__card__details__expiry-row">
								<div className="virtual-card__container__card__details__expiry-row__cvv">
									<span className="virtual-card__container__card__details__expiry-row__cvv__header" style={{ color: cardDetail.CardFontColor}}>
										CVV
									</span>
									<span style={{ color: cardDetail.CardFontColor}}>
										{cvvVisible ? cardCVV : "***"}
									</span>
								</div>
								<div className="virtual-card__container__card__details__expiry-row__expiry">
									<span className="virtual-card__container__card__details__expiry-row__expiry__header" style={{ color: cardDetail.CardFontColor }}>
										Good Thru
									</span>
									<span style={{ color: cardDetail.CardFontColor}} >
										{cardVisible ? cardDetail.ExpiryDate : "*****"}
									</span>
								</div>
								<div className="virtual-card__container__card__details__expiry-row__brand-logo">
									<div style={cardbrand}></div>
								</div>
							</div>
						</div>
					</div>
					<div className="virtual-card__container__address-row">
						<div className="virtual-card__container__address-row__address">
							<label>Address</label>
							<label>
								{cardVisible ? (cardDetail.AddressLine2 == null || cardDetail.AddressLine2 == "" ? cardDetail.AddressLine1 : cardDetail.AddressLine1 + " - " + cardDetail.AddressLine2) : ""}
							</label>
							<label>
								{cardVisible ? (cardDetail.City + " - " + cardDetail.Province) : ""}
							</label>
							<label>
								{cardVisible ? cardDetail.ZipCode : ""}
							</label>
						</div>
						{ cardVisible &&
							<div>
								<FileCopyOutlinedIcon
									className="virtual-card__container__address-row__copy-img"
									style={{ height: "1rem", width: "1rem" }}
									onClick={() => {
										// eslint-disable-next-line no-unused-expressions
										window.ReactNativeWebView?.postMessage(cardDetail.Address);
										navigator.clipboard.writeText(cardDetail.Address);
									}}
								/>
							</div>
						}
					</div>
					<div className="virtual-card__container__btns-row">
						<button 
							className={`virtual-card__container__btns-row__btn virtual-card__container__btns-row__btn${this.state.isCvvBtnDisabled? "--disabled": ""}`}
							disabled={this.state.isCvvBtnDisabled}
							onClick={this.GetCVV}>
								CVV
						</button>
						<button 
							className={`virtual-card__container__btns-row__btn virtual-card__container__btns-row__btn${this.state.isCardBtnDisabled? "--disabled": ""}`}
							disabled={this.state.isCardBtnDisabled}
							onClick={this.GetCardShow}>
								Card
						</button>
					</div>
				</div>
			</div>
		);
	}
}

export default withArtifex(VirtualCardIframe, {});