import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { bool, func } from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTextInput, GenericTitle, GenericDateInput } from "views/Components/Generic";
import { withArtifex } from "core";
import LoadingComponent from "views/Components/LoadingComponent";
import DateHelper from "core/DateHelper";
import { GridColumnType } from "views/Constants/Constant";

class UnverifiedCustomerCreateCardTry extends Component {
	constructor(props) {
		super(props);
		this.state = {
			vModel: {},
			model: {


			},
			list: [],
			isLoading: false
		};
		this.parameterClient = {};
		this.trxTable = React.createRef();
	}
	componentDidMount() {
		this.props.setAppLeftTitle("Unverified Customer Create Card Try");
		this.props.setAppCenterTitle("AML & KYC");
		this.setState({ loadingCompleted: true });
	}

	handleChange = (name, newValue) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		if (name == "UniqueClientId" && newValue == null) {
			model.UniqueClientId = 0;
		}
		this.setState({ model });
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert:
				<GenericAlert
					Title={title}
					MessageNode={message}
					Type={type} OnConfirm={() => this.setState({ alert: null })}
				/>
		});
	}
	handleGetList = () => {
		this.setState(state => ({ trxGridToken: !state.trxGridToken }));
	}
	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
		this.setState({ isLoading: false });
	}
	handleClear = () => {
		this.setState({
			model: {},
			list: [],
			isLoading: false
		});
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}
	GetClientParameter = () => {
		this.parameterClient.UniqueClientId = this.state.model.UniqueClientId;
		return this.parameterClient;
	}
	handlePaginationData = (data) => {
		this.setState({ list: data });
	}

	render() {
		const { Disabled } = this.props;
		const { model, isLoading, vModel } = this.state;

		return (
			<div>
				{this.state.alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.handleGetList, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.handleClear, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<LoadingComponent Show={isLoading} />

				<Card className="no-radius">
					<CardBody>
						<GenericExpansionPanel Title="Filter Panel">
							<GridContainer>
								<GridItem xs={3}>
									<GenericSelectInput
										Name="UniqueClientId"
										LabelText="Client Name"
										Value={model == null ? "" : model.UniqueClientId || ""}
										DataRoot="Item"
										ValueChanged={this.handleChange}
										KeyValueMember="Id"
										TextValueMember="Name"
										Url="/bankapi/v1.0/BankCustomer/Search"
										Method="POST"
										Parameter={{
											UniqueClientId: 0,
										}}
										Required={!model.IsUniqueDataSearchMode}
										IsInvalid={vModel.UniqueClientId}
									/>
								</GridItem>
								<GridItem xs={3}>
									<GenericDateInput
										Name="FromDate"
										LabelText="From Date"
										Value={model.FromDate}
										ValueChanged={this.handleChange}
										Disabled={Disabled}
										MaxDate={model.ToDate == null ? DateHelper.GetDate() : model.ToDate}
										LabelMd={3}
										Utc />
								</GridItem>
								<GridItem xs={3}>
									<GenericDateInput
										Name="ToDate"
										LabelText="To Date"
										Value={model.ToDate}
										ValueChanged={this.handleChange}
										MaxDate={DateHelper.GetDate()}
										MinDate={model.FromDate}
										Disabled={Disabled}
										Utc />
								</GridItem>
							</GridContainer>
						</GenericExpansionPanel>
					</CardBody>
				</Card>
				<Card className="no-radius">
					<CardBody>
						<CardHeader>
							<GenericTitle text={"Unverified Customer List"} />
						</CardHeader>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									key={"trxGrid" + this.state.trxGridToken}
									ref={this.trxTable}
									ShowPagination={true}
									PageSize={10}
									IsSorted={false}
									ServerSide
									PaginationFetchDataCallback={this.handlePaginationData}
									LoadAtStartup={this.state.loadingCompleted}
									RequestUrl="/amlkycapi/v1.0/FinScanSearchLog/UnverifiedCustomerCreateCardTryList"
									RequestMethod="POST"
									PrepareRequest={() => {
										const { model } = this.state;
										if (this.props.MenuCode != null) {
											model.MenuCode = this.props.MenuCode;
										}
										return model;
									}}
									Columns={[
										{
											Header: "Client Name",
											accessor: "ClientName",
										},
										{
											Header: "Customer Name",
											accessor: "CustomerName",
										},
										{
											Header: "Try Date",
											accessor: "TryDate",
											type: GridColumnType.DateTime
										}
									]}
								/>
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>
			</div>
		);
	}
}

UnverifiedCustomerCreateCardTry.propTypes = {
	showQuestionMessage: func,
	setAppLeftTitle: func,
	setAppCenterTitle: func,
	Disabled: bool
};


export default withArtifex(UnverifiedCustomerCreateCardTry, {});