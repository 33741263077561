import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React from "react";
import KeyContact from "views/Backoffice/BankingClientSetup/ClientSteps/KeyContact";
import { GenericAlert, GenericDateInput, GenericEmailInput, GenericExpansionPanel, GenericLabel, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { ActionCodes, IdentificationMethod, MenuCodes, IdentityType } from "views/Constants/Constant.js";
import ParameterComponent from "views/Components/ParameterComponent";

class Personal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			alert: null,
			isLoading: false,
			CustomDisabled: false,
			vModel: {},
			vModel2: {},
		};

		this.ParameterClientStatus = { ParameterCode: "CustomerStatus" };
		this.ParameterTitle = { ParameterCode: "Title" };
		this.ParameterGender = { ParameterCode: "Gender" };
		this.ParameterOccupation = { ParameterCode: "Occupation" };
		this.ParameterYesNo = { ParameterCode: "YesNo" };
		this.ParameterEmptyObject = {};
		this.ParameterCountrySortOrderIndex = { Member: "OrderIndex" };
		this.ParameterMaxLength50 = { maxLength: 50 };
		this.ParameterMaxLength30 = { maxLength: 30 };
		this.ParameterMaxLength64 = { maxLength: 64 };
		this.ParameterMaxLength25 = { maxLength: 25 };
		this.ParameterMaxLength20 = { maxLength: 20 };
		this.ParameterMaxLength15 = { maxLength: 15 };
		this.ParameterMaxLength1 = { maxLength: 1 };
		this.ParameterLanguage = { ParameterCode: "Language" };
		this.ParameterProvince = { ParameterCode: "Province" };
		this.ParameterIdentificationMethod = { ParameterCode: "IdentificationMethod" };
		this.ParameterIdentityVerifyingPerson = { ParameterCode: "IdentityVerifyingPerson" };
		this.ParameterIdentityType = { ParameterCode: "IdentityType" };

		this.sortedParameterDesc = { Member: "ParameterDesc" };
	}
	RenderCountrySelect(d) {
		return "+" + d.Code + " (" + d.Name + ")";
	}
	CustomHandleChange = (n, v, d) => {
		this.props.handleChangePersonal(n, v == "true");
	}

	componentDidMount() {
		this.props.FetchClientLimitedAuthority(MenuCodes.CSLimitedIndividual, ActionCodes.Submit, (res) => this.setState({ CustomDisabled: res }));
	}


	OperationCheck = () => {
		var ErrorList = [];
		const { vModel, vModel2 } = this.state;
		const data = this.props.paramCustomerProfile;
		const { paramIdentificationMethot1, paramIdentificationMethot2, paramCustomerPrimaryPhone, paramCustomerMobilePhone, paramCustomerAlternativePhone } = this.props;

		paramCustomerMobilePhone.IsMobilePhoneNumber = false;
		paramCustomerAlternativePhone.IsAlternativePhoneNumber = false;
		if (data == null) {
			return;
		}
		if (data.ClientStatusId == null || data.ClientStatusId == "") {
			vModel.ClientStatusId = true;

			ErrorList.push((this.props.isSalesAgentPersonel ? " Status " : "Client Status ") + "must be defined !.");
		} else { vModel.ClientStatusId = false; }

		if (data.TitleId == null || data.TitleId == "") {
			vModel.TitleId = true;
			ErrorList.push("Title must be defined !.");
		} else { vModel.TitleId = false; }
		if (!this.props.isSalesAgentPersonel) {
			if (data.GenderId == null || data.GenderId == "") {
				vModel.GenderId = true;
				ErrorList.push("Gender must be defined !.");
			} else { vModel.GenderId = false; }
			if (data.PoliticalExposed == null) {
				vModel.PoliticalExposed = true;
				ErrorList.push("Political Exposed must be defined !.");
			} else { vModel.PoliticalExposed = false; }
			if (data.LanguageId == null || data.LanguageId == "") {
				vModel.LanguageId = true;
				ErrorList.push("Language must be defined !.");
			} else { vModel.LanguageId = false; }
			if (data.SendSms == null) {
				vModel.SendSms = true;
				ErrorList.push("Text/SMS Notification must be defined !.");
			} else { vModel.SendSms = false; }
			if (data.SendEmail == null) {
				vModel.SendEmail = true;
				ErrorList.push("E-Mail Notification must be defined !.");
			} else { vModel.SendEmail = false; }
		}
		if (data.Name == null || data.Name == "") {
			vModel.Name = true;
			ErrorList.push("Name must be defined !.");
		} else { vModel.Name = false; }
		if (data.SurName == null || data.SurName == "") {
			vModel.SurName = true;
			ErrorList.push("LastName must be defined !.");
		} else { vModel.SurName = false; }
		if (data.Email == null || data.Email == "") {
			vModel.Email = true;
			ErrorList.push("Email must be defined !.");
		} else { vModel.Email = false; }
		if (paramCustomerPrimaryPhone == null || paramCustomerPrimaryPhone.PhoneCountryCodeId == null || paramCustomerPrimaryPhone.PhoneCountryCodeId == "") {
			vModel.PhoneCountryCodeId = true;
			ErrorList.push("Primary phone code must be defined !.");
		} else { vModel.PhoneCountryCodeId = false; }
		if (paramCustomerPrimaryPhone == null || paramCustomerPrimaryPhone.PhoneCountryCodeId == null || paramCustomerPrimaryPhone.PhoneNumber == "") {
			vModel.PhoneNumber = true;
			ErrorList.push("Primary phone number must be defined !.");
		} else { vModel.PhoneNumber = false; }
		if (paramCustomerPrimaryPhone != null && paramCustomerPrimaryPhone.PhoneNumber != null) {
			if (paramCustomerPrimaryPhone.PhoneNumber.toString().length != 10) {
				ErrorList.push("Primary phone number must be 10 Digits !.");
			}
		}


		if (paramCustomerAlternativePhone != null && (paramCustomerAlternativePhone.PhoneNumber != null || paramCustomerAlternativePhone.PhoneCountryCodeId != null)) {
			if (paramCustomerAlternativePhone.PhoneNumber != null && paramCustomerAlternativePhone.PhoneNumber.toString().length != 10) {
				ErrorList.push("Alternative phone number must be 10 Digits !.")
			}
			if (paramCustomerAlternativePhone == null || paramCustomerAlternativePhone.PhoneCountryCodeId == null || paramCustomerAlternativePhone.PhoneCountryCodeId == "") {
				ErrorList.push("Alternative phone country code must be defined !.");
			}
			if (paramCustomerAlternativePhone == null || paramCustomerAlternativePhone.PhoneNumber == null) {
				paramCustomerAlternativePhone["IsAlternativePhoneNumber"] = true;
				ErrorList.push("Alternative phone number must be defined !.");
			}
		}

		if (paramCustomerMobilePhone != null && (paramCustomerMobilePhone.PhoneNumber != null || paramCustomerMobilePhone.PhoneCountryCodeId != null)) {
			if (paramCustomerMobilePhone.PhoneNumber != null && paramCustomerMobilePhone.PhoneNumber.toString().length != 10) {
				ErrorList.push("Mobile phone number must be 10 Digits !.")
			}
			if (paramCustomerMobilePhone == null || paramCustomerMobilePhone.PhoneCountryCodeId == null || paramCustomerMobilePhone.PhoneCountryCodeId == "") {
				ErrorList.push("Mobile phone country code must be defined !.");
			}
			if (paramCustomerMobilePhone == null || paramCustomerMobilePhone.PhoneNumber == null) {
				paramCustomerMobilePhone["IsMobilePhoneNumber"] = true;
				ErrorList.push("Mobile phone number must be defined !.");
			}
		}


		if (data.Email != null && !this.ValidateEmail(data.Email)) {
			ErrorList.push("E-mail must be valid !");
		}
		if (!this.props.isSalesAgentPersonel) {
			if (paramIdentificationMethot1 == null || paramIdentificationMethot1.IdentificationMethodId == null || paramIdentificationMethot1.IdentificationMethodId == "") {
				vModel.IdentificationMethodId = true;
				ErrorList.push("Identification Method 1 must be defined !.");
			} else { vModel.IdentificationMethodId = false; }
			if ((paramIdentificationMethot1?.IsIdVerification && paramIdentificationMethot1.IdentificationMethodId != null) && (paramIdentificationMethot1.IdentityVerifyingPersonId == null || paramIdentificationMethot1.IdentityVerifyingPersonId == "" || paramIdentificationMethot1 == null)) {
				vModel.IdentityVerifyingPersonId = true;
				ErrorList.push("Verifying Person 1 must be defined !.");
			} else { vModel.IdentityVerifyingPersonId = false; }
			if (paramIdentificationMethot1 == null || paramIdentificationMethot1.IdentityTypeId == null || paramIdentificationMethot1.IdentityTypeId == "") {
				vModel.IdentityTypeId = true;
				ErrorList.push("Identification Type 1 must be defined !.");
			} else { vModel.IdentityTypeId = false; }
			if (paramIdentificationMethot1 == null || paramIdentificationMethot1.IdentityNumber == null || paramIdentificationMethot1.IdentityNumber == "") {
				vModel.IdentityNumber = true;
				ErrorList.push("Identity Number 1 must be defined !.");
			} else { vModel.IdentityNumber = false; }
			if (paramIdentificationMethot1 == null || paramIdentificationMethot1.IdentityPlaceOfIssue == null || paramIdentificationMethot1.IdentityPlaceOfIssue == "") {
				vModel.IdentityPlaceOfIssue = true;
				ErrorList.push("Identity Place Of Issue 1 must be defined !.");
			} else { vModel.IdentityPlaceOfIssue = false; }

			if ((paramIdentificationMethot1 != null && paramIdentificationMethot2 != null) && (paramIdentificationMethot1.IdentityTypeId == paramIdentificationMethot2.IdentityTypeId)) {
				ErrorList.push("Identification Types must be different !.");
			}
			if ((paramIdentificationMethot1 != null && paramIdentificationMethot2 != null) && (paramIdentificationMethot1.IdentityNumber == paramIdentificationMethot2.IdentityNumber)) {
				ErrorList.push("Identification Numbers must be different !.");
			}
			if (paramIdentificationMethot1 != null && paramIdentificationMethot1.IdentityTypeParameterValue2 == IdentityType.OnePoR && !paramIdentificationMethot1.ProofOfResidenceId) {
				vModel.ProofOfResidenceId1 = true;
				ErrorList.push("Proof of residence 1 must be defined !.");
			} else {
				vModel.ProofOfResidenceId1 = false;
			}
			if (paramIdentificationMethot1 == null || paramIdentificationMethot1.IdentityExpireDate == null || paramIdentificationMethot1.IdentityExpireDate == "") {
				vModel.IdentityExpireDate = true;
				ErrorList.push("Identity Expire Date 1 must be defined !.");
			} else {
				vModel.IdentityExpireDate = false;
				paramIdentificationMethot1.IdentityExpireDate = DateHelper.ToMoment(paramIdentificationMethot1.IdentityExpireDate);
				if (!paramIdentificationMethot1.IdentityExpireDate.isValid()) {
					ErrorList.push("Identity Expire Date 1 must be valid !.");
				}
				var interval1 = paramIdentificationMethot1.IdentityExpireDate.diff(DateHelper.GetDate(), "days");
				if (interval1 < 30) {
					ErrorList.push("Identity Expire Date 1 must be valid In next 30 days !");
				}
			}
			// Identity number check by IdentityTypeId
			if (paramIdentificationMethot1 != null && paramIdentificationMethot1.IdentityNumber == null) {
				ErrorList.push("Identity Type 1 must be valid !");
			}
			// Identity number check by IdentityTypeId  -> Optional
			if (paramIdentificationMethot2 != null &&
				(paramIdentificationMethot2.IdentificationMethodId != null ||
					paramIdentificationMethot2.IdentityTypeId != null ||
					paramIdentificationMethot2.IdentityNumber != null ||
					paramIdentificationMethot2.IdentityPlaceOfIssue != null ||
					paramIdentificationMethot2.IdentityExpireDate != null)) {

				if (paramIdentificationMethot2 == null || paramIdentificationMethot2.IdentificationMethodId == null || paramIdentificationMethot2.IdentificationMethodId == "") {
					vModel2.IdentificationMethodId = true;
					ErrorList.push("Identification Method 2 must be defined !.");
				} else { vModel2.IdentificationMethodId = false; }

				if ((paramIdentificationMethot2.IsIdVerification && paramIdentificationMethot2.IdentificationMethodId != null) && (paramIdentificationMethot2.IdentityVerifyingPersonId == null || paramIdentificationMethot2.IdentityVerifyingPersonId == "" || paramIdentificationMethot2 == null)) {
					vModel2.IdentityVerifyingPersonId = true;
					ErrorList.push("Verifying Person 2 must be defined !.");
				} else { vModel2.IdentityVerifyingPersonId = false; }



				if (paramIdentificationMethot2 == null || paramIdentificationMethot2.IdentityTypeId == null || paramIdentificationMethot2.IdentityTypeId == "") {
					vModel2.IdentityTypeId = true;
					ErrorList.push("Identification Type 2 must be defined !.");
				} else { vModel2.IdentityTypeId = false; }
				if (paramIdentificationMethot2 == null || paramIdentificationMethot2.IdentityNumber == null || paramIdentificationMethot2.IdentityNumber == "") {
					vModel2.IdentityNumber = true;
					ErrorList.push("Identity Number 2 must be defined !.");
				} else { vModel2.IdentityNumber = false; }
				if (paramIdentificationMethot2 == null || paramIdentificationMethot2.IdentityPlaceOfIssue == null || paramIdentificationMethot2.IdentityPlaceOfIssue == "") {
					vModel2.IdentityPlaceOfIssue = true;
					ErrorList.push("Identity Place Of Issue 2 must be defined !.");
				} else { vModel2.IdentityPlaceOfIssue = false; }

				if (paramIdentificationMethot2 == null || paramIdentificationMethot2.IdentityExpireDate == null || paramIdentificationMethot2.IdentityExpireDate == "") {
					vModel2.IdentityExpireDate = true;
					ErrorList.push("Identity Expire Date 2 must be defined !.");
				} else {
					vModel2.IdentityExpireDate = false;
					paramIdentificationMethot2.IdentityExpireDate = DateHelper.ToMoment(paramIdentificationMethot2.IdentityExpireDate);
					if (!paramIdentificationMethot2.IdentityExpireDate.isValid()) {
						ErrorList.push("Identity Expire Date 2 must be valid !.");
					}
					var interval2 = paramIdentificationMethot2.IdentityExpireDate.diff(DateHelper.GetDate(), "days");
					if (interval2 < 30) {
						ErrorList.push("Identity Expire Date 2 must be valid In next 30 days !");
					}
				}

				if (paramIdentificationMethot2 == null || paramIdentificationMethot2.IdentityNumber == null) {
					ErrorList.push("Identity Type 2 must be valid !");
				}
				if (paramIdentificationMethot2.IdentityTypeParameterValue2 == IdentityType.OnePoR && !paramIdentificationMethot2.ProofOfResidenceId) {
					vModel.ProofOfResidenceId2 = true;
					ErrorList.push("Proof of residence 2 must be defined !.");
				} else {
					vModel.ProofOfResidenceId2 = false;
				}
			}

			if (data.BirthDate == null || data.BirthDate == "") {
				vModel.BirthDate = true;
				ErrorList.push("Date Of Birth must be defined !.");
			} else {
				vModel.BirthDate = false;
				data.BirthDate = DateHelper.ToMoment(data.BirthDate);
				if (!data.BirthDate.isValid()) {
					ErrorList.push("Date Of Birth must be valid !");
				}
				var interval = DateHelper.GetDate().diff(data.BirthDate, "years");
				if (interval < 18) {
					ErrorList.push("Date Of Birth must be bigger than 18 !");
				}
			}
		}
		this.setState({ vModel, vModel2 });
		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", ErrorList.slice(0, 30).map((x, i) => <div key={i}>{x}</div>));
			this.setState({ isLoading: false });
			return false;
		}

		this.UpdateCustomer();
	}

	ValidateEmail = (email) => {
		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}
	FilterIdentityNumber = (number) => {
		if (number == null) return "";

		var regexp = new RegExp("(.*)(.{4})");
		var onEk = regexp.exec(number)[1].replace(/([^ ])/g, "*");
		var sonEk = regexp.exec(number)[2];

		return onEk + sonEk;
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}

	UpdateCustomer = () => {
		const model = { ...this.props.paramCustomerProfile };
		const prop = { ...this.props.paramGlobalModel };

		if (prop.CheckNewClient) {
			model.ClientType = prop.ClientType;
		}
		model.OrganizationUniqueId = prop.OrganizationUniqueId;
		this.setState({ isLoading: true });

		Proxy.POST(
			"/bankapi/v1.0/BankCustomer/Update",
			model,
			responseData => {
				if (!responseData.IsSucceeded) {
					if (responseData.ErrorCode != "MerchantInsE3213") {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
					}
					else {
						this.ShowMessage("warning", "Warning", responseData.ErrorDescription);
					}
					return;
				}
				this.setState({ isLoading: false });

				if (responseData.Item.InteracErrorList != null && responseData.Item.InteracErrorList.length > 0) {
					var operationResult = model.Id > 0 ? "Client Profile Updated !" : "Client Profile Saved ! ";
					this.ShowMessageNode("warning", operationResult + "But Interac Update Integration Error", responseData.Item.InteracErrorList);
				}
				else
					this.ShowMessage("success", "Success", model.Id > 0 ? "Client Profile Updated !" : "Client Profile Saved ! ");

				this.props.setCustomerState(responseData.Item);
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}


	ShowMessage = (type, title, message) => {
		// type:-success->warning->info->error
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}

	render() {
		const { Disabled: DisabledProp, paramGlobalModel } = this.props;
		const Disabled = DisabledProp || this.state.CustomDisabled;
		const { paramCustomerProfile, handleChangePersonal, paramIdentificationMethot1, paramIdentificationMethot2, handleChangeIdentification1, handleChangeIdentification2,
			handleChangePhone1, handleChangePhone2, handleChangePhone3, paramCustomerPrimaryPhone, paramCustomerMobilePhone, paramCustomerAlternativePhone } = this.props;
		const { vModel, vModel2 } = this.state;
		return (

			<GridContainer spacing={16}>
				{this.state.alert}
				<LoadingComponent Show={this.state.isLoading} />

				<GridContainer justify="flex-end">
					<GridItem style={{ float: "right" }}>
						<Button
							size="sm"
							disabled={Disabled || this.props.paramGlobalModel.Id == null ? true : this.props.paramGlobalModel.Id == 0 ? true : false}
							color="black"
							onClick={() => this.props.handleChangeChildTab(null, 1)} > Next
						</Button>
					</GridItem>
				</GridContainer>
				<GenericExpansionPanel IsActive={true} Title="Personal Details" >
					<Card className="no-radius">
						<CardBody>
							<GridContainer>
								<GridItem xs={4}>
									<h5><b> Individual Account</b></h5>
									<GenericSelectInput
										Disabled={Disabled}
										Name="TitleId"
										LabelText="Title"
										Value={paramCustomerProfile == null ? "" : paramCustomerProfile.TitleId || ""}
										ValueChanged={handleChangePersonal}
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Method="POST" Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.ParameterTitle}
										DataRoot="Item"
										Required
										IsInvalid={vModel.TitleId}
									/>
									{!this.props.isSalesAgentPersonel &&
										<GenericSelectInput
											Disabled={Disabled}
											Name="GenderId"
											LabelText="Gender"
											Value={paramCustomerProfile == null ? "" : paramCustomerProfile.GenderId || ""}
											ValueChanged={handleChangePersonal}
											KeyValueMember="Id"
											TextValueMember="ParameterDesc"
											Method="POST"
											Url="/coreapi/v1.0/Parameter/Search"
											Parameter={this.ParameterGender}
											DataRoot="Item"
											Required
											IsInvalid={vModel.GenderId}
										/>}
									<GenericTextInput
										Disabled={Disabled}
										inputProps={this.ParameterMaxLength30}
										Name="Name"
										LabelText="Name"
										Value={paramCustomerProfile == null ? "" : paramCustomerProfile.Name || ""}
										ValueChanged={handleChangePersonal}
										Required
										IsInvalid={vModel.Name}
									/>
									<GenericTextInput
										Disabled={Disabled}
										inputProps={this.ParameterMaxLength30}
										Name="MidName"
										LabelText="Middle Name"
										Value={paramCustomerProfile == null ? "" : paramCustomerProfile.MidName || ""}
										ValueChanged={handleChangePersonal}
									/>
									<GenericTextInput
										Disabled={Disabled}
										inputProps={this.ParameterMaxLength30}
										Name="SurName"
										LabelText="Last Name"
										Value={paramCustomerProfile == null ? "" : paramCustomerProfile.SurName || ""}
										ValueChanged={handleChangePersonal}
										Required
										IsInvalid={vModel.SurName}
									/>
									<GenericTextInput
										Disabled={Disabled}
										inputProps={this.ParameterMaxLength1}
										Name="Initial"
										LabelText="Initial"
										Value={paramCustomerProfile == null ? "" : paramCustomerProfile.Initial || ""}
										ValueChanged={handleChangePersonal}
									/>
									{
										!this.props.isSalesAgentPersonel &&
										<React.Fragment>
											<GenericDateInput
												Utc
												Disabled={Disabled}
												IsPast={true}
												Name="BirthDate"
												LabelText="Date Of Birth"
												Value={paramCustomerProfile == null ? "" : DateHelper.ToDateInputValue(paramCustomerProfile.BirthDate) || ""}
												ValueChanged={handleChangePersonal}
												Required
												IsInvalid={vModel.BirthDate}
											/>
											<GenericSelectInput
												Disabled={Disabled}
												Name="OccupationId"
												LabelText="Occupation"
												Value={paramCustomerProfile == null ? "" : paramCustomerProfile.OccupationId || ""}
												DataRoot="Item"
												ValueChanged={handleChangePersonal}
												KeyValueMember="Id"
												TextValueMember="ParameterDesc"
												Method="POST"
												Url="/coreapi/v1.0/Parameter/Search"
												Parameter={this.ParameterOccupation}
											/>
											<GenericSelectInput
												Disabled={Disabled}
												Name="PoliticalExposed"
												LabelText="Political Exposed"
												Value={paramCustomerProfile == null ? "" : paramCustomerProfile.PoliticalExposed == null ? "" : String(paramCustomerProfile.PoliticalExposed)}
												ValueChanged={this.CustomHandleChange}
												KeyValueMember="ParameterValue"
												TextValueMember="ParameterDesc"
												Method="POST"
												Url="/coreapi/v1.0/Parameter/Search"
												Parameter={this.ParameterYesNo}
												DataRoot="Item"
												Required
												IsInvalid={vModel.PoliticalExposed}
											/>
											<GenericTextInput
												Disabled={true}
												Name="RiskRating"
												LabelText="Risk Rating"
												Value={paramCustomerProfile == null ? "" : paramCustomerProfile.RiskRating || ""}
												ValueChanged={handleChangePersonal}
											/>
										</React.Fragment>}

									<GenericSelectInput
										Disabled={Disabled}
										Name="ClientStatusId"
										LabelText={this.props.isSalesAgentPersonel ? "Status" : "Client Status"}
										Value={paramCustomerProfile == null ? "" : paramCustomerProfile.ClientStatusId || ""}
										ValueChanged={handleChangePersonal}
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Method="POST" Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.ParameterClientStatus}
										DataRoot="Item"
										Required
										IsInvalid={vModel.ClientStatusId}
									/>
									<GenericDateInput
										Utc
										Disabled={true}
										IsPast={true}
										Name="InsertDateTime"
										LabelText="Create Date"
										Value={paramCustomerProfile == null ? "" : DateHelper.ToMoment(paramCustomerProfile.InsertDateTime) || ""}
									/>
								</GridItem>
								<GridItem xs={4}>
									<h5><b>Communication</b></h5>
									<GridContainer>
										<GridItem xs={8}>
											<GenericSelectInput
												Disabled={Disabled}
												Name="PhoneCountryCodeId"
												LabelText="Primary Phone"
												LabelMd={6}
												Method="POST"
												Url="/coreapi/v1.0/Country/GetAll"
												Parameter={this.ParameterEmptyObject}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Code"
												Value={paramCustomerPrimaryPhone == null ? "" : paramCustomerPrimaryPhone.PhoneCountryCodeId || ""}
												ValueChanged={handleChangePhone1}
												Sorted={this.ParameterCountrySortOrderIndex}
												RenderItem={this.RenderCountrySelect}
												Required
												IsInvalid={vModel.PhoneCountryCodeId}
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericNumberInput
												Disabled={Disabled}
												Format="(###) ### ####"
												LabelMd={0}
												Name="PhoneNumber"
												Value={paramCustomerPrimaryPhone == null ? "" : paramCustomerPrimaryPhone.PhoneNumber || ""}
												ValueChanged={handleChangePhone1}
												Required
												IsInvalid={vModel.PhoneNumber}
											/>
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem xs={8}>
											<GenericSelectInput
												CanClear
												Disabled={Disabled}
												Name="PhoneCountryCodeId"
												LabelText="Alternative Phone"
												LabelMd={6}
												Method="POST"
												Url="/coreapi/v1.0/Country/GetAll"
												Parameter={this.ParameterEmptyObject}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Code"
												Value={paramCustomerAlternativePhone == null ? "" : paramCustomerAlternativePhone.PhoneCountryCodeId || ""}
												ValueChanged={handleChangePhone2}
												Sorted={this.ParameterCountrySortOrderIndex}
												RenderItem={this.RenderCountrySelect}
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericNumberInput
												Disabled={Disabled}
												Format="(###) ### ####"
												LabelMd={0}
												Name="PhoneNumber"
												Value={paramCustomerAlternativePhone == null ? "" : paramCustomerAlternativePhone.PhoneNumber || ""}
												ValueChanged={handleChangePhone2}
												Required={paramCustomerAlternativePhone.PhoneCountryCodeId ? true : false}
												IsInvalid={paramCustomerAlternativePhone.IsAlternativePhoneNumber}
											/>
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem xs={8}>
											<GenericSelectInput
												CanClear
												Disabled={Disabled}
												Name="PhoneCountryCodeId"
												LabelText="Mobile Phone"
												LabelMd={6}
												Method="POST"
												Url="/coreapi/v1.0/Country/GetAll"
												Parameter={this.ParameterEmptyObject}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Code"
												Value={paramCustomerMobilePhone == null ? "" : paramCustomerMobilePhone.PhoneCountryCodeId || ""}
												ValueChanged={handleChangePhone3}
												Sorted={this.ParameterCountrySortOrderIndex}
												RenderItem={this.RenderCountrySelect}
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericNumberInput
												Disabled={Disabled}
												Format="(###) ### ####"
												LabelMd={0}
												Name="PhoneNumber"
												Value={paramCustomerMobilePhone == null ? "" : paramCustomerMobilePhone.PhoneNumber || ""}
												ValueChanged={handleChangePhone3}
												Required={paramCustomerMobilePhone.PhoneCountryCodeId ? true : false}
												IsInvalid={paramCustomerMobilePhone.IsMobilePhoneNumber}
											/>
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem xs={12}>
											<GenericEmailInput
												Disabled={Disabled}
												inputProps={this.ParameterMaxLength64}
												Name="Email"
												LabelText="E-Mail"
												Value={paramCustomerProfile == null ? "" : paramCustomerProfile.Email || ""}
												ValueChanged={handleChangePersonal}
												Required
												IsInvalid={vModel.Email}
											/>
										</GridItem>
										{!this.props.isSalesAgentPersonel &&
											<GridItem xs={12}>
												<GenericSelectInput
													Disabled={Disabled}
													Name="LanguageId"
													LabelText="Preferred Language"
													Value={paramCustomerProfile == null ? "" : paramCustomerProfile.LanguageId || ""}
													ValueChanged={handleChangePersonal}
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.ParameterLanguage}
													DataRoot="Item"
													Required
													IsInvalid={vModel.LanguageId}
												/>
												<GenericSelectInput
													CanClear
													Disabled={Disabled}
													Name="SendSms"
													LabelText="Text/SMS Notification"
													Value={paramCustomerProfile == null ? "" : paramCustomerProfile.SendSms == null ? "" : String(paramCustomerProfile.SendSms)}
													ValueChanged={this.CustomHandleChange}
													KeyValueMember="ParameterValue"
													TextValueMember="ParameterDesc"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.ParameterYesNo}
													DataRoot="Item"
													Required
													IsInvalid={vModel.SendSms}
												/>
												<GenericSelectInput
													CanClear
													Disabled={Disabled}
													Name="SendEmail"
													LabelText="E-Mail Notification"
													Value={paramCustomerProfile == null ? "" : paramCustomerProfile.SendEmail == null ? "" : String(paramCustomerProfile.SendEmail)}
													ValueChanged={this.CustomHandleChange}
													KeyValueMember="ParameterValue"
													TextValueMember="ParameterDesc"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.ParameterYesNo}
													DataRoot="Item"
													Required
													IsInvalid={vModel.SendEmail}
												/>
											</GridItem>
										}
									</GridContainer>
								</GridItem>
								{
									!this.props.isSalesAgentPersonel &&
									<GridItem xs={4}>
										<h5><b>ID Verification</b></h5>
										<GenericLabel
											Bold={true}
											FontSize="15px"
											TextColor="black"
											Text="Identification Method 1">
										</GenericLabel>
										<GenericSelectInput
											Disabled={Disabled}
											Name="IdentificationMethodId"
											LabelText="Identification Method"
											Value={paramIdentificationMethot1 == null ? "" : paramIdentificationMethot1.IdentificationMethodId || ""}
											ValueChanged={handleChangeIdentification1}
											KeyValueMember="Id"
											TextValueMember="ParameterDesc"
											Method="POST"
											Url="/coreapi/v1.0/Parameter/Search"
											Parameter={this.ParameterIdentificationMethod}
											DataRoot="Item"
											Required
											IsInvalid={vModel.IdentificationMethodId}
										/>
										{((paramIdentificationMethot1 != null && paramIdentificationMethot1.IsIdVerification) || (paramIdentificationMethot1 != null && paramIdentificationMethot1.IdentificationMethod != null && paramIdentificationMethot1.IdentificationMethod.ParameterValue == IdentificationMethod.InPersonIDVerification)) && (
											<GenericSelectInput
												Disabled={Disabled}
												Name="IdentityVerifyingPersonId"
												LabelText="Verifying Person"
												Value={paramIdentificationMethot1 == null ? "" : paramIdentificationMethot1.IdentityVerifyingPersonId || ""}
												ValueChanged={handleChangeIdentification1}
												KeyValueMember="Id"
												TextValueMember="ParameterDesc"
												Method="POST"
												Url="/coreapi/v1.0/Parameter/Search"
												Parameter={this.ParameterIdentityVerifyingPerson}
												DataRoot="Item"
												Required={(paramIdentificationMethot1.IdentificationMethod != null && paramIdentificationMethot1.IdentificationMethod.ParameterValue == IdentificationMethod.InPersonIDVerification) || (paramIdentificationMethot1 != null && paramIdentificationMethot1.IsIdVerification) ? true : false}
												IsInvalid={vModel.IdentityVerifyingPersonId}
											/>
										)}
										<GenericSelectInput
											Disabled={Disabled}
											Name="IdentityTypeId"
											LabelText="Identification Type"
											Value={paramIdentificationMethot1 == null ? "" : paramIdentificationMethot1.IdentityTypeId || ""}
											ValueChanged={handleChangeIdentification1}
											KeyValueMember="Id"
											TextValueMember="ParameterDesc"
											Method="POST"
											Url="/coreapi/v1.0/Parameter/Search"
											Parameter={this.ParameterIdentityType}
											DataRoot="Item"
											Sorted={this.sortedParameterDesc}
											Required
											IsInvalid={vModel.IdentityTypeId}
										/>
										<ParameterComponent
											Disabled={paramIdentificationMethot1.IdentityTypeParameterValue2 != IdentityType.OnePoR}
											Required={paramIdentificationMethot1.IdentityTypeParameterValue2 == IdentityType.OnePoR}
											IsInvalid={vModel.ProofOfResidenceId1}
											Name="ProofOfResidenceId"
											LabelText="Proof of Residence"
											ParameterCode="ProofOfResidence"
											Value={paramIdentificationMethot1.ProofOfResidenceId}
											ValueChanged={handleChangeIdentification1} />
										{(paramGlobalModel.IsExceptClientSetup == true) ? (
											<GenericTextInput
												Disabled={true}
												Name="IdentityNumber"
												LabelText="Identity Number"
												Value={paramIdentificationMethot1 == null ? "" : this.FilterIdentityNumber(paramIdentificationMethot1.IdentityNumber)}
												ValueChanged={handleChangeIdentification1}
												Required
												IsInvalid={vModel.IdentityNumber}
											/>
										) : (
											<GenericTextInput
												Disabled={Disabled}
												Format={paramIdentificationMethot1 == null ? "" : paramIdentificationMethot1.IdentityFormat}
												inputProps={this.ParameterMaxLength20}
												Name="IdentityNumber"
												LabelText="Identity Number"
												Value={paramIdentificationMethot1 == null ? "" : paramIdentificationMethot1.IdentityNumber || ""}
												ValueChanged={handleChangeIdentification1}
												Required
												IsInvalid={vModel.IdentityNumber}
											/>
										)}
										<GenericTextInput
											Disabled={Disabled}
											inputProps={this.ParameterMaxLength15}
											Name="IdentityPlaceOfIssue"
											LabelText="Identity Place Of Issue"
											Value={paramIdentificationMethot1 == null ? "" : paramIdentificationMethot1.IdentityPlaceOfIssue || ""}
											ValueChanged={handleChangeIdentification1}
											Required
											IsInvalid={vModel.IdentityPlaceOfIssue}
										/>
										<GenericDateInput
											Utc
											Disabled={Disabled}
											IsFuture={true}
											Name="IdentityExpireDate"
											LabelText="Identity Expire Date"
											Value={paramIdentificationMethot1 == null ? "" : DateHelper.ToDateInputValue(paramIdentificationMethot1.IdentityExpireDate)}
											ValueChanged={handleChangeIdentification1}
											Required
											IsInvalid={vModel.IdentityPlaceOfIssue}
										/>
										<hr />
										<GenericLabel
											Bold={true}
											FontSize="15px"
											TextColor="black"
											Text="Identification Method 2">
										</GenericLabel>
										<GenericSelectInput
											CanClear
											Disabled={Disabled}
											Name="IdentificationMethodId"
											LabelText="Identification Method"
											Value={paramIdentificationMethot2 == null ? "" : paramIdentificationMethot2.IdentificationMethodId || ""}
											ValueChanged={handleChangeIdentification2}
											KeyValueMember="Id"
											TextValueMember="ParameterDesc"
											Method="POST"
											Url="/coreapi/v1.0/Parameter/Search"
											Parameter={this.ParameterIdentificationMethod}
											DataRoot="Item"
										/>
										{((paramIdentificationMethot2 != null && paramIdentificationMethot2.IsIdVerification) || (paramIdentificationMethot2 != null && paramIdentificationMethot2.IdentificationMethod != null && paramIdentificationMethot2.IdentificationMethod.ParameterValue == IdentificationMethod.InPersonIDVerification)) && (
											<GenericSelectInput
												CanClear
												Disabled={Disabled}
												Name="IdentityVerifyingPersonId"
												LabelText="Verifying Person"
												Value={paramIdentificationMethot2 == null ? "" : paramIdentificationMethot2.IdentityVerifyingPersonId || ""}
												ValueChanged={handleChangeIdentification2}
												KeyValueMember="Id"
												TextValueMember="ParameterDesc"
												Method="POST"
												Url="/coreapi/v1.0/Parameter/Search"
												Parameter={this.ParameterIdentityVerifyingPerson}
												DataRoot="Item"
												Required={((paramIdentificationMethot2.IdentificationMethodId != null) && (paramIdentificationMethot2 != null && paramIdentificationMethot2.IsIdVerification)) ? true : false}
												IsInvalid={paramIdentificationMethot2.IdentificationMethodId ? vModel2.IdentityVerifyingPersonId : false}
											/>
										)}
										<GenericSelectInput
											CanClear
											Disabled={Disabled}
											Name="IdentityTypeId"
											LabelText="Identification Type"
											Value={paramIdentificationMethot2 == null ? "" : paramIdentificationMethot2.IdentityTypeId || ""}
											ValueChanged={handleChangeIdentification2}
											KeyValueMember="Id"
											TextValueMember="ParameterDesc"
											Method="POST"
											Url="/coreapi/v1.0/Parameter/Search"
											Parameter={this.ParameterIdentityType}
											DataRoot="Item"
											Sorted={this.sortedParameterDesc}
											Required={paramIdentificationMethot2.IdentificationMethodId ? true : false}
											IsInvalid={paramIdentificationMethot2.IdentificationMethodId ? vModel2.IdentityTypeId : false}
										/>
										<ParameterComponent
											Disabled={paramIdentificationMethot2.IdentityTypeParameterValue2 != IdentityType.OnePoR}
											Required={paramIdentificationMethot2.IdentityTypeParameterValue2 == IdentityType.OnePoR}
											IsInvalid={vModel.ProofOfResidenceId2}
											Name="ProofOfResidenceId"
											LabelText="Proof of Residence"
											ParameterCode="ProofOfResidence"
											Value={paramIdentificationMethot2.ProofOfResidenceId}
											ValueChanged={handleChangeIdentification2} />
										{(paramGlobalModel.IsExceptClientSetup == true) ? (
											<GenericTextInput
												Disabled={true}
												Name="IdentityNumber"
												LabelText="Identity Number"
												Value={paramIdentificationMethot2 == null ? "" : this.FilterIdentityNumber(paramIdentificationMethot2.IdentityNumber)}
												ValueChanged={handleChangeIdentification2}
												Required={paramIdentificationMethot2.IdentificationMethodId ? true : false}
												IsInvalid={vModel2.IdentityNumber}
											/>
										) : (
											<GenericTextInput
												Disabled={Disabled}
												Format={paramIdentificationMethot2 == null ? "" : paramIdentificationMethot2.IdentityFormat}
												inputProps={this.ParameterMaxLength20}
												Name="IdentityNumber"
												LabelText="Identity Number"
												Value={paramIdentificationMethot2 == null ? "" : paramIdentificationMethot2.IdentityNumber || ""}
												ValueChanged={handleChangeIdentification2}
												Required={paramIdentificationMethot2.IdentificationMethodId ? true : false}
												IsInvalid={paramIdentificationMethot2.IdentificationMethodId ? vModel2.IdentityNumber : false}
											/>
										)}
										<GenericTextInput
											Disabled={Disabled}
											inputProps={this.ParameterMaxLength15}
											Name="IdentityPlaceOfIssue"
											LabelText="Identity Place Of Issue"
											Value={paramIdentificationMethot2 == null ? "" : paramIdentificationMethot2.IdentityPlaceOfIssue || ""}
											ValueChanged={handleChangeIdentification2}
											Required={paramIdentificationMethot2.IdentificationMethodId ? true : false}
											IsInvalid={paramIdentificationMethot2.IdentificationMethodId ? vModel2.IdentityPlaceOfIssue : false}
										/>
										<GenericDateInput
											Utc
											Disabled={Disabled}
											IsFuture={true}
											Name="IdentityExpireDate"
											LabelText="Identity Expire Date"
											Value={paramIdentificationMethot2 == null ? "" : DateHelper.ToDateInputValue(paramIdentificationMethot2.IdentityExpireDate)}
											ValueChanged={handleChangeIdentification2}
											Required={paramIdentificationMethot2.IdentificationMethodId ? true : false}
											IsInvalid={paramIdentificationMethot2.IdentificationMethodId ? vModel2.IdentityExpireDate : false}
										/>
									</GridItem>
								}
							</GridContainer>
							<GridContainer justify="flex-end">
								<GridItem style={{ float: "right" }}>
									<Button
										size="sm"
										color="black"
										disabled={((this.props.paramGlobalModel.OrganizationUniqueId == null || this.props.paramGlobalModel.OrganizationUniqueId == 0) ? true : false) || Disabled || this.props.paramGlobalModel.ClientType == null ? true : this.props.paramGlobalModel.ClientType == "" ? true : false}
										onClick={() => this.OperationCheck()} >
										Save
									</Button>
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
				</GenericExpansionPanel>
				<GenericExpansionPanel IsActive={false} Title="Contact Person Information" >
					<KeyContact
						Disabled={this.props.Disabled}
						FetchClientLimitedAuthority={this.props.FetchClientLimitedAuthority}
						paramCustomerProfile={this.props.paramCustomerProfile}
						paramGlobalModel={this.props.paramGlobalModel}
						paramBankCustomerKeyContactList={this.props.paramBankCustomerKeyContactList}
						GetCustomerKeyContactModels={this.props.GetCustomerKeyContactModels} />
				</GenericExpansionPanel>
			</GridContainer >
		);
	}
}
Personal.propTypes = {
	classes: PropTypes.object,
	paramCustomerProfile: PropTypes.object,
	paramGlobalModel: PropTypes.object,
	paramIdentificationMethot1: PropTypes.object,
	paramIdentificationMethot2: PropTypes.object,
	handleChangePersonal: PropTypes.func,
	handleChangeIdentification1: PropTypes.func,
	handleChangeIdentification2: PropTypes.func,
	handleOperationType: PropTypes.func,
	setCustomerState: PropTypes.func,
	setIdentificationState: PropTypes.func,
	handleChangePhone1: PropTypes.func,
	handleChangePhone2: PropTypes.func,
	handleChangePhone3: PropTypes.func,
	paramCustomerMobilePhone: PropTypes.object,
	paramCustomerAlternativePhone: PropTypes.object,
	paramCustomerPrimaryPhone: PropTypes.object,
	paramBankCustomerKeyContactList: PropTypes.array,
	GetCustomerKeyContactModels: PropTypes.func,
	handleChangeChildTab: PropTypes.func
};

export default withArtifex(Personal, {});