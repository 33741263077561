import React, { Component } from "react";
import LoadingComponent from "views/Components/LoadingComponent";
import ButtonToolbar from "views/Components/ButtonToolbar";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Card } from "@material-ui/core";
import CardBody from "components/Card/CardBody";
import {
  GenericExpansionPanel,
  GenericTextInput,
  GenericGrid,
  GenericAlert,
} from "views/Components/Generic";
import TabsComponent from "views/Components/TabsComponent";
import { withArtifex } from "core";
import { Proxy } from "core";
import PaymentCard from "views/VisaDirect/ClientPortal/PaymentCard";
import AddNewCustomer from "views/VisaDirect/ClientPortal/AddNewCustomer";
import { GridColumnType } from "views/Constants/Constant";
import DateHelper from "core/DateHelper";
import { TaskPool } from "core/TaskPool";
class VisaDirect extends Component {
  constructor(props) {
    super(props);
    this.getappCenterTitle = "Empty";
    this.getappLeftTitle = "Empty";
    this.state = {
      ParentTabIndex: 0,
      alert: null,
      model: {},
      vModel: {},
      customerModel: {},
      list: [],
      isLoading: false,
      cardModel: {},
      cardList: [],
      cardTransferType: null,
      isPersonal: null,
      isCorporate: null,
      VerificationStatus: null,
    };
    this.loadTaskPool = new TaskPool(
      this.handleLoadTaskPoolAppend,
      this.handleLoadTaskPoolCompleted
    );
    this.ColumnsCardData = [
      {
        Header: "Name Of The Card",
        accessor: "CardEmbossName",
      },
      {
        Header: "Card Id",
        accessor: "CardId",
      },
      {
        Header: "Last Four",
        accessor: "LastFour",
      },
      {
        Header: "Masked Card Number",
        accessor: "MaskedCardNumber",
      },
      {
        Header: "Expiration Month",
        accessor: "ExpirationMonth",
      },
      {
        Header: "Expiration Year",
        accessor: "ExpirationYear",
      },
      {
        Header: "Card Name",
        accessor: "CardClientCustomer",
      },
    ];

    this.ColumnsData = [
      {
        Header: "Card Transfer Type",
        accessor: "CardTransferType",
      },
      {
        Header: "Dc Bank Customer Number",
        accessor: "ClientCustomerNumber",
      },
      {
        Header: "Client Customer Number",
        accessor: "CustomerNumber",
      },
      {
        Header: "Customer Name",
        accessor: "ClientCustomerName",
      },
      {
        Header: "Client Type",
        accessor: "ClientType",
      },
		{
			Header: "BirthDateValue",
			accessor: "BirthDateValue",
			type:GridColumnType.Date,
		 },
      {
        Header: "Business Name",
        accessor: "BusinessName",
      },
      {
        Header: "Company Legal Name",
        accessor: "CompanyLegalName",
      },

      {
        Header: "Email",
        accessor: "EMail",
      },
      {
        Header: "Date Of Birth",
        accessor: "BirthDateValue",
        type: GridColumnType.DateUtc,
      },
      {
        Header: "Postal Code",
        accessor: "PostalCode",
      },
      {
        Header: "City",
        accessor: "City",
      },
      {
        Header: "Province",
        accessor: "Province",
      },
      {
        Header: "Country",
        accessor: "Country",
      },
      {
        Header: "Address1",
        accessor: "AddressLine1",
      },
      {
        Header: "Address2",
        accessor: "AddressLine2",
      },
      {
        Header: "Phone Number",
        accessor: "PhoneNumber",
        Cell: (row) => (
          <div>
            {row.original.PhoneCountryCode > -1
              ? "+" +
                row.original.PhoneCountryCode +
                " " +
                row.original.PhoneNumber
              : ""}
          </div>
        ),
      },
    ];
  }

  componentDidMount() {
    const { setAppLeftTitle, setAppCenterTitle } = this.props;
    this.LoadCanadaPost();
    if (setAppLeftTitle) {
      setAppLeftTitle("Add New Customer");
      this.getappLeftTitle = "Add New Customer";
    }
    if (setAppCenterTitle) {
      setAppCenterTitle("CARD TRANSFER");
      this.getappCenterTitle = "CARD TRANSFER";
    }
    this.setState({ loadingCompleted: true });
  }
  Validate() {
    var ErrorList = [];
    var customerModel = this.state.customerModel;
    var vModel = this.state.vModel;

    if (this.isCorporate == false) {
      if (customerModel.FirstName == null || customerModel.FirstName == "") {
        vModel.FirstName = true;
        ErrorList.push("FirstName must be filled");
      } else {
        vModel.FirstName = false;
      }
      if (customerModel.LastName == null || customerModel.LastName == "") {
        vModel.LastName = true;
        ErrorList.push("LastName must be filled");
      } else {
        vModel.LastName = false;
      }
    }

    var interval = DateHelper.GetDate().diff(
      customerModel.BirthDateValue,
      "years"
    );
    if (interval < 18 && customerModel.BirthDateValue != null) {
      ErrorList.push("Date Of Birth must be bigger than 18 !");
    }

    if (
      customerModel.AddressLine1 == null ||
      customerModel.AddressLine1 == ""
    ) {
      vModel.AddressLine1 = true;
      ErrorList.push("AddressLine1 must be filled");
    } else {
      vModel.AddressLine1 = false;
    }
    if (
      customerModel.PhoneCountryCode == null ||
      customerModel.PhoneCountryCode == ""
    ) {
      vModel.PhoneCountryCode = true;
      ErrorList.push("PhoneCountryCode must be filled");
    } else {
      vModel.PhoneCountryCode = false;
    }
    if (customerModel.AddressLine1?.length > 40) {
      ErrorList.push("AddressLine1 length cannot exceed 40 characters");
    }
    //  if (
    //    customerModel.PhoneCountryCode == null ||
    //    customerModel.PhoneCountryCode == ""
    //  ) {
    //    vModel.PhoneCountryCode = true;
    //    ErrorList.push("PhoneCountryCode must be filled");
    //  } else {
    //    vModel.PhoneCountryCode = false;
    //  }

    //  if (customerModel.PhoneNumber == null || customerModel.PhoneNumber == "") {
    //    vModel.PhoneNumber = true;
    //    ErrorList.push("PhoneNumber must be filled");
    //  } else {
    //    vModel.PhoneNumber = false;
    //  }
    if (
      customerModel.AddressLine1 == null ||
      customerModel.AddressLine1 == ""
    ) {
      vModel.AddressLine1 = true;
      ErrorList.push("AddressLine1 must be filled");
    } else {
      vModel.AddressLine1 = false;
    }

    if (customerModel.AddressLine1?.length > 40) {
      ErrorList.push("AddressLine1 length cannot exceed 40 characters");
    }
    if (customerModel.EMail == null || customerModel.EMail == "") {
      vModel.EMail = true;
      ErrorList.push("EMail must be filled");
    } else {
      vModel.EMail = false;
    }

    // if (customerModel.IdNumber == null || customerModel.IdNumber == "") {
    // 	vModel.IdNumber = true;
    // 	ErrorList.push("IdNumber must be filled");
    // } else { vModel.IdNumber = false; }

    if (customerModel.EMail?.length > 40) {
      ErrorList.push("E-mail length cannot exceed 40 characters");
    }
    //  if (customerModel.PostalCode == null || customerModel.PostalCode == "") {
    //    vModel.PostalCode = true;
    //    ErrorList.push("PostalCode must be filled");
    //  } else {
    //    vModel.PostalCode = false;
    //  }

    //  if (customerModel.PostalCode?.length < 5) {
    //    ErrorList.push("Postal Code length must be 5 or 6 characters");
    //  }
    if (customerModel.CountryId == null || customerModel.CountryId == "") {
      vModel.CountryId = true;
      ErrorList.push("Country must be filled");
    } else {
      vModel.CountryId = false;
    }
    if (customerModel.ProvinceId == null || customerModel.ProvinceId == "") {
      vModel.ProvinceId = true;
      ErrorList.push("Province must be filled");
    } else {
      vModel.ProvinceId = false;
    }
    if (customerModel.CityId == null || customerModel.CityId == "") {
      vModel.CityId = true;
      ErrorList.push("City must be filled");
    } else {
      vModel.CityId = false;
    }
    if (
      customerModel.AddressLine1 == null ||
      customerModel.AddressLine1 == "" ||
      customerModel.AddressLine1 == undefined
    ) {
      vModel.AddressLine1 = true;
      ErrorList.push("Address Line 1 must be filled");
    }
    if (
      customerModel.CardTransferTypeId == null ||
      customerModel.CardTransferTypeId == undefined ||
      customerModel.CardTransferTypeId == ""
    ) {
      vModel.CardTransferTypeId = true;
      ErrorList.push("Card Transafer Type must be filled");
    }
    if (
      customerModel.CountryId == null ||
      customerModel.CountryId == undefined ||
      customerModel.CountryId == ""
    ) {
      vModel.CountryId = true;
      ErrorList.push("Country must be filled");
    }
    if (
      customerModel.CityId == null ||
      customerModel.CityId == undefined ||
      customerModel.CityId == ""
    ) {
      vModel.CityId = true;
      ErrorList.push("City must be filled");
    }

    if (ErrorList.length > 0) {
      this.props.showMessageNode(
        "warning",
        "Please fill required fields!",
        ErrorList.slice(0, 10).map((x, i) => <div key={i}>{x}</div>)
      );
      return false;
    }

    return true;
  }

  ValidateCard = () => {
    var ErrorList = [];
    var customerModel = this.state.customerModel;
    var cardModel = this.state.cardModel;
    var vModel = this.state.vModel;

    if (
      customerModel.CustomerNumber == null ||
      customerModel.CustomerNumber == ""
    ) {
      ErrorList.push("Which Customer belongs to the card is not selected");
    }
    if (cardModel.CardEmbossName == null || cardModel.CardEmbossName == "") {
      vModel.CardEmbossName = true;
      ErrorList.push("NameOnTheCard must be filled");
    } else {
      vModel.CardEmbossName = false;
    }

    if (cardModel.CardTokenNumber == null || cardModel.CardTokenNumber == "") {
      vModel.CardTokenNumber = true;
      ErrorList.push("CardNumber must be filled");
    } else {
      vModel.CardTokenNumber = false;
    }

    if (cardModel.CurrencyCode == null || cardModel.CurrencyCode == "") {
      vModel.CurrencyCode = true;
      ErrorList.push("CurrencyCode must be filled");
    } else {
      vModel.CurrencyCode = false;
    }

    if (cardModel.ExpirationMonth == null || cardModel.ExpirationMonth == "") {
      vModel.ExpirationMonth = true;
      ErrorList.push("ExpirationMonth must be filled");
    } else {
      vModel.ExpirationMonth = false;
    }

    if (cardModel.ExpirationYear == null || cardModel.ExpirationYear == "") {
      vModel.ExpirationYear = true;
      ErrorList.push("ExpirationYear must be filled");
    } else {
      vModel.ExpirationYear = false;
    }

    if (cardModel.SecurityCode == null || cardModel.SecurityCode == "") {
      vModel.SecurityCode = true;
      ErrorList.push("Security Code must be filled");
    } else {
      vModel.SecurityCode = false;
    }

    if (
      (cardModel.BankCode == null || cardModel.BankCode == "") &&
      this.state.cardTransferType == "Mastercard Send"
    ) {
      vModel.BankCode = true;
      ErrorList.push("Bank Code must be filled");
    } else {
      vModel.BankCode = false;
    }

    if (ErrorList.length > 0) {
      this.props.showMessageNode(
        "warning",
        "Please fill required fields!",
        ErrorList.slice(0, 10).map((x, i) => <div key={i}>{x}</div>)
      );
      return false;
    }

    return true;
  };

  handlePaginationData = (data) => {
    this.setState({ list: data });
  };

  ShowConfirmMessage = (type, title, message, onConfirm, showCancel = true) => {
    this.setState({
      alert: (
        <GenericAlert
          Title={title}
          Message={message}
          Type={type}
          ShowCancel={showCancel}
          OnCancel={() => this.setState({ alert: null })}
          OnConfirm={onConfirm}
        />
      ),
    });
  };
  DeleteCustomer = (model) => {
    this.ShowConfirmMessage(
      "warning",
      "Warning",
      "Are you sure you want to delete to customer?",
      () => {
        this.setState({ alert: null, isLoading: true });
        Proxy.POST(
          "/coreapi/v1.0//SaveBlackListToken",
          this.state.model,
          (responseData) => {
            this.setState({ isLoading: false });
            if (!responseData.IsSucceeded) {
              this.props.showMessage(
                "error",
                responseData.Code,
                responseData.ErrorDescription
              );
              return;
            }
            if (responseData.Item) {
              this.props.showMessage("success", "Success", "Success");
            } else {
              this.props.showMessage(
                "error",
                "Error",
                "There was an error adding to the blacklist"
              );
            }
          }
        );
      }
    );
  };

  InsertCustomer = async () => {
    if (!this.Validate()) {
      return;
    }
    const customerModel = this.state.customerModel;
    this.setState({ isLoading: true });
    Proxy.POST(
      this.state.cardTransferType == "Visa Direct"
        ? "/bankapi/v1.0/VisaDirect/CreateCustomer"
        : "/bankapi/v1.0/MastercardXBorder/CreateCustomer",
      {
        CardTransferTypeId: customerModel.CardTransferTypeId,
        ClientTypeId: customerModel.ClientTypeId,
        FirstName: customerModel.FirstName,
        CustomerNumber: customerModel.CustomerNumber,
        MiddleName: customerModel.MiddleName,
        LastName: customerModel.LastName,
        BirthDateValue: customerModel.BirthDateValue,
        IdType: customerModel.IdType,
        IdNumber: customerModel.IdNumber,
        IdExpireDateValue: customerModel.IdExpireDateValue,
        PhoneCountryCode: customerModel.PhoneCountryCode,
        PhoneNumber: customerModel.PhoneNumber,
        EMail: customerModel.EMail,
        AddressLine1: customerModel.AddressLine1,
        AddressLine2: customerModel.AddressLine2,
        PostalCode: customerModel.PostalCode,
        CountryId: customerModel.CountryId,
        ProvinceId: customerModel.ProvinceId,
        CityId: customerModel.CityId,
        BusinessName: customerModel.BusinessName,
        CompanyLegalName: customerModel.CompanyLegalName
      },
      (responseData) => {
        if (responseData == null) {
          this.props.showMessage("error", "Error", "An error occurred!S");
          return;
        }
		  console.log(responseData);
        this.GetVerfy(responseData.Item["CustomerId"]);
      },
      (error) => {
        this.props.showMessage("error", "Error", "An error occurred!");
        this.SearchCustomer();
      }
    );
  };

  GetVerfy = (customerId) => {
    const customerModel = { ...this.state.customerModel };
    Proxy.POST(
      "/amlkycapi/v1.0/FinScanSearchLog/CustomerWatchListCheck",
		{"ClientCustomerId": customerId}
      ,
      (responseData) => {
        this.setState({ isLoading: false });
        if (responseData == null) {
          this.props.showMessage("error", "Error", "An error occurred!S");
          return;
        }
        this.props.showMessage(
          "success",
          "success",
          "Customer Has Been Created Successfully"
        );
        customerModel["WatchListVerificationResult"] =
          responseData.Item["WatchListStatusDescription"];
        this.setState({ customerModel });
      },
      (error) => {
        this.props.showMessage("error", "Error", "An error occurred!");
        this.SearchCustomer();
      }
    );
  };

  UpdateCustomer = async () => {
    if (!this.Validate()) {
      return;
    }
    var customerModel = this.state.customerModel;
    const { ExecuteApiPost } = this.props;
    var result = await ExecuteApiPost(
      this.state.cardTransferType == "Visa Direct"
        ? "/bankapi/v1.0/VisaDirect/UpdateCustomer"
        : "/bankapi/v1.0/MastercardXBorder/UpdateCustomer",
      {
        FirstName: customerModel.FirstName,
        CustomerNumber: customerModel.CustomerNumber,
        MiddleName: customerModel.MiddleName,
        LastName: customerModel.LastName,
        BirthDateValue: customerModel.BirthDateValue,
        IdType: customerModel.IdType,
        IdNumber: customerModel.IdNumber,
        IdExpireDateValue: customerModel.IdExpireDateValue,
        PhoneCountryCode: customerModel.PhoneCountryCode,
        PhoneNumber: customerModel.PhoneNumber,
        EMail: customerModel.EMail,
        AddressLine1: customerModel.AddressLine1,
        AddressLine2: customerModel.AddressLine2,
        PostalCode: customerModel.PostalCode,
        CountryId: customerModel.CountryId,
        ProvinceId: customerModel.ProvinceId,
        CityId: customerModel.CityId,
        CardTransferTypeId: customerModel.CardTransferTypeId,
        BusinessName: customerModel.BusinessName,
        CompanyLegalName: customerModel.CompanyLegalName,
        ClientTypeId: customerModel.ClientTypeId,
        WatchListVerificationResult: customerModel.WatchListVerificationResult,
      },
    );
    if (result != null) {
      this.Clear();
      this.SearchCustomer();
    }
  };

  SearchCustomer = async () => {
    this.setState((state) => ({ trxGridToken: !state.trxGridToken }));
  };
  HandleChangeCard = (name, newValue, data) => {
    const cardModel = { ...this.state.cardModel };
    cardModel[name] = newValue;

    this.setState({ cardModel });
  };

  SelectedCardRowChange = (e, index) => {
    const model = this.state.cardList[index];
    this.setState({ cardModel: model });
  };
  InsertCard = async () => {
    if (!this.ValidateCard()) {
      return;
    }
    var customerModel = this.state.customerModel;
    var cardModel = this.state.cardModel;
    const { ExecuteApiPost } = this.props;
    var result = await ExecuteApiPost(
      this.state.cardTransferType == "Visa Direct"
        ? "/bankapi/v1.0/VisaDirect/AddPaymentInstrument"
        : "/bankapi/v1.0/MastercardXBorder/AddPaymentMethod",
      {
        CustomerNumber: customerModel.CustomerNumber,
        EncryptedPan: cardModel.CardTokenNumber,
        Name: cardModel.CardEmbossName,
        ExpirationMonth: cardModel.ExpirationMonth,
        ExpirationYear: cardModel.ExpirationYear,
        EncryptedVerificationCode: cardModel.SecurityCode,
        CurrenyId: cardModel.CurrencyCode,
        CardTransferTypeId: customerModel.CardTransferTypeId,
      }
    );
    if (result != null) {
      this.props.showMessage(
        "success",
        "success",
        "Payment Card Has Been Created Successfully"
      );
      this.ClearCard();
      this.GetCard(customerModel.Id);
    }
  };
  UpdateCard = async () => {
    if (!this.ValidateCard()) {
      return;
    }
    var customerModel = this.state.customerModel;
    var cardModel = this.state.cardModel;
    const { ExecuteApiPost } = this.props;
    var result = await ExecuteApiPost(
      this.state.cardTransferType == "Visa Direct"
        ? "/bankapi/v1.0/VisaDirect/UpdatePaymentInstrument"
        : "/bankapi/v1.0/MastercardXBorder/UpdatePaymentMethod",
      {
        CustomerId: customerModel.Id,
        CustomerNumber: customerModel.CustomerNumber,
        EncryptedPan: cardModel.CardTokenNumber,
        Name: cardModel.CardEmbossName,
        ExpirationMonth: cardModel.ExpirationMonth,
        ExpirationYear: cardModel.ExpirationYear,
        EncryptedVerificationCode: cardModel.SecurityCode,
        CurrenyId: cardModel.CurrencyCode,
      }
    );
    if (result != null) {
      this.props.showMessage(
        "success",
        "success",
        "Payment Card Has Been Updated Successfully"
      );
      this.ClearCard();
      this.GetCard(customerModel.Id);
    }
  };

  GetCard = async (CustomerId) => {
    var CustomerId = parseInt(CustomerId);
    const { ExecuteApiPost } = this.props;
    var result = await ExecuteApiPost(
      "/bankapi/v1.0/VisaDirect/GetCustomerCard",
      {
        CustomerId: CustomerId,
      }
    );

    if (result != null) {
      this.setState({ cardList: result || [] });
    }
  };

  Clear = () => {
    this.setState({
      customerModel: {},
      model: {},
      list: [],
      cardModel: {},
      vModel: {},
      cardList: [],
    });
  };

  ClearCustomer = () => {
    this.setState({ customerModel: {} });
  };

  ClearCard = () => {
    this.setState({ cardModel: {} });
  };

  SelectedRowChange = (e, index) => {
    const customerModel = this.state.list[index];
    if (customerModel.ClientType == "Corporate") {
      customerModel["FirstName"] = null;
      customerModel["LastName"] = null;
      customerModel["MiddleName"] = null;
		customerModel["BirthDateValue"] = null;
      this.setState({ isCorporate: true });
    }

    if (customerModel.ClientType  == "Individual") {
      customerModel["CompanyLegalName"] = null;
      customerModel["BusinessName"] = null;
      this.setState({ isCorporate: false });
    }
    this.setState({ customerModel: customerModel });
    this.setState({ cardTransferType: customerModel.CardTransferType });
    this.setState({ ClientType: customerModel.ClientTypeId });
    this.GetCard(customerModel.Id);
  };

  HandleChange = (name, newValue, data) => {
    const model = { ...this.state.model };
    model[name] = newValue;
    this.setState({ model });
  };

  RegExpression = (value) => {
    var reg = new RegExp("^[a-zA-Z0-9]*");
    if (reg.test(value)) {
      return true;
    }
    return false;
  };

  handleLoadTaskPoolAppend = () => {
    this.setState({ isLoading: true });
  };

  handleLoadTaskPoolCompleted = () => {
    this.setState({ isLoading: false });
  };

  HandleChangeCustomer = (name, newValue, data) => {
    const customerModel = { ...this.state.customerModel };
    customerModel[name] = newValue;

    if (name === "CardTransferTypeId") {
      this.Clear();
      customerModel[name] = newValue;
      customerModel["FirstName"] = null;
      customerModel["LastName"] = null;
      customerModel["MiddleName"] = null;
      customerModel["CompanyLegalName"] = null;
      customerModel["BusinessName"] = null;
      this.setState({ cardTransferType: data.ParameterDesc });
    }
    if (name === "ClientTypeId" && data.ParameterDesc == "Corporate") {
      customerModel["FirstName"] = null;
      customerModel["LastName"] = null;
      customerModel["MiddleName"] = null;
      this.setState({ isCorporate: true });
    }

    if (name === "ClientTypeId" && data.ParameterDesc == "Individual") {
      customerModel["CompanyLegalName"] = null;
      customerModel["BusinessName"] = null;
      this.setState({ isCorporate: false });
    }
    this.setState({ customerModel });
  };
  LoadCanadaPost() {
    var parent = this;
    this.loadTaskPool.AppendTask(
      Proxy.POST(
        "/coreapi/v1.0/Parameter/Search",
        {
          ParameterCode: "CanadaPostApiKey",
          ParameterValue: "CP",
        },
        (responseData) => {
          if (!responseData.IsSucceeded) {
            console.log("Canada Post Api key not fetched !");
            return;
          }
          if (responseData.Item == null) {
            console.log("Canada Post Api key not fetched !");
            return;
          }
          if (responseData.Item[0] == null) {
            console.log("Canada Post Api key not fetched !");
            return;
          }
          var apikey = responseData.Item[0].ParameterDesc;

          // @ts-ignore
          var url = window.CanadaPostAddressCompleteUrl;
          var path = url + apikey;

          var pca;
          var CanadaPostRequest = this.SubmitCanadaPostRequest;
          var CanadaPostSubmit = this.SubmitCanadaPostAddressModel;
          const script = document.createElement("script");
          script.src = path;
          script.async = false;
          document.head.appendChild(script);
          script.onload = function () {
            // @ts-ignore
            pca = window.pca;
            if (pca != null) {
              var fields = [
                  {
                    element: "street-address",
                    field: "Line1",
                    mode: pca.fieldMode.SEARCH,
                  },
                  {
                    element: "street-address2",
                    field: "Line2",
                    mode: pca.fieldMode.POPULATE,
                  },
                  {
                    element: "postcode",
                    field: "PostalCode",
                    mode: pca.fieldMode.SEARCH,
                  },
                ],
                options = {
                  key: apikey,
                },
                control = new pca.Address(fields, options);
              CanadaPostRequest();

              control.listen("populate", function (address) {
                CanadaPostSubmit(address);
                parent.setState((state) => {
                  state.customerModel.AddressLine1 = address.Line1;
                  state.customerModel.AddressLine2 = address.Line2;
                  state.customerModel.PostalCode = address.PostalCode;
                  return state;
                });
              });
            }
          };
        },
        (e) => {
          console.log("Canada Post Api key not fetched !");
        }
      )
    );
  }

  SubmitCanadaPostAddressModel = (model) => {
    this.loadTaskPool.AppendTask(
      Proxy.POST(
        "/coreapi/v1.0/Country/CanadaPost",
        model,
        (responseData) => {
          if (!responseData.IsSucceeded) {
            this.props.showMessage(
              "error",
              "Error",
              responseData.ErrorDescription
            );
            return;
          }
          var address = {
            ...this.state.customerModel,
          };
          if (responseData.Item != null) {
            address.CountryId = responseData.Item.CountryId;
            address.ProvinceId = responseData.Item.ProvinceId;
            address.CityId = responseData.Item.CityId;
          } else {
            address.CountryId = 0;
            address.ProvinceId = 0;
            address.CityId = 0;
          }
          this.setState({ customerModel: address });
        },
        (error) => {
          this.props.showMessage("error", "Error", error);
        }
      )
    );
  };

  SubmitCanadaPostRequest = async () => {
    const model = { ...this.state.customerModel };
    var requestdata = {
      UniqueClientId: null,
      MenuCode: this.props.Menucode,
      AppCenterTitle: this.getappCenterTitle,
      AppLeftTitle: this.getappLeftTitle,
    };
    Proxy.POST(
      "/bankapi/v1.0/Reporting/CanadaPostRequestReportInsert",
      requestdata,
      (responseData) => {
        if (responseData.Item != null) {
        }
      }
    );
  };

  handleChangeParentTab(tab) {}

  render() {
    const { model, alert, isLoading } = this.state;
    return (
      <GridContainer spacing={16}>
        {alert}
        <LoadingComponent Show={isLoading} />
        <GridItem xs={12}>
          <ButtonToolbar
            ButtonList={[
              { Code: "Search", OnClick: this.SearchCustomer },
              { Code: "Clear", OnClick: this.Clear },
              //{ Code: "Delete", OnClick: this.HandleDelete }
            ]}
            menuId={this.props.menuId}
            ApprovalData={this.props.ApprovalData}
            After={this.props.After}
          />

          <Card
            className="no-radius"
            style={{
              border: "1px solid gray",
              borderRadius: "2px",
              marginBlockEnd: "7px",
            }}
          >
            <CardBody>
              <GenericExpansionPanel Title="Filter Panel">
                <GridContainer>
                  <GridItem xs={4}>
                    <GenericTextInput
                      Name="CustomerNumber"
                      LabelText="Customer Number"
                      Value={model.CustomerNumber}
                      ValueChanged={this.HandleChange}
                    />
                  </GridItem>
                  <GridItem xs={4}>
                    <GenericTextInput
                      Name="Name"
                      LabelText="Customer Name"
                      Value={model.Name}
                      ValueChanged={this.HandleChange}
                    />
                  </GridItem>
                </GridContainer>
              </GenericExpansionPanel>
            </CardBody>
          </Card>

          <br />
          <GridItem xs={12}>
            <TabsComponent
              TabIndex={this.state.ParentTabIndex}
              handleChange={this.handleChangeParentTab(false)}
              tabList={[
                {
                  tabButton: "Add New Customer",
                  tabContent: (
                    <AddNewCustomer
                      customermodel={this.state.customerModel}
                      HandleChangeCustomer={this.HandleChangeCustomer}
                      InsertCustomer={this.InsertCustomer}
                      UpdateCustomer={this.UpdateCustomer}
                      vModel={this.state.vModel}
                      cardTransferType={this.state.cardTransferType}
                      ClearCustomer={this.ClearCustomer}
                      ClientTypeId={this.state.ClientTypeId}
                      IsCorporate={this.state.isCorporate}
                    />
                  ),
                  Hidden: false,
                },
                this.state.cardTransferType == "Visa Direct" && {
                  tabButton: "Payment Card",
                  tabContent: (
                    <PaymentCard
                      ColumnsCardData={this.ColumnsCardData}
                      cardList={this.state.cardList}
                      cardModel={this.state.cardModel}
                      customermodel={this.state.customerModel}
                      cardTransferType={this.state.cardTransferType}
                      HandleChangeCard={this.HandleChangeCard}
                      InsertCard={this.InsertCard}
                      UpdateCard={this.UpdateCard}
                      GetCard={this.GetCard}
                      ClearCard={this.ClearCard}
                      vModel={this.state.vModel}
                      SelectedCardRowChange={this.SelectedCardRowChange}
                    />
                  ),
                  Hidden: false,
                },
              ]}
            />
            <GridContainer />
          </GridItem>
          <br />
          <br />
          <GridContainer>
            <GridItem xs={12}>
              <Card className="no-radius">
                <CardBody>
                  <GenericExpansionPanel Title="Client Customer List">
                    <GridContainer>
                      <GridItem xs={12}>
                        <GenericGrid
                          key={
                            "trxGrid" +
                            this.state.trxGridToken +
                            this.state.list
                          }
                          ServerSide
                          RequestUrl="/bankapi/v1.0/VisaDirect/ClientsRegisteredSearchAccount"
                          RequestMethod="POST"
                          PrepareRequest={() => {
                            return model;
                          }}
                          PageSize={10}
                          ShowPagination={true}
                          LoadAtStartup={this.state.loadingCompleted}
                          Columns={this.ColumnsData}
                          SelectedIndex={this.state.rowIndex}
                          IsSorted={false}
                          RowSelected={(index) => {
                            this.SelectedRowChange(0, index);
                          }}
                          PaginationFetchDataCallback={
                            this.handlePaginationData
                          }
                        />
                      </GridItem>
                    </GridContainer>
                  </GenericExpansionPanel>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withArtifex(VisaDirect, {});
