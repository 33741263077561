import { DialogContent, DialogTitle, Slide } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDialog, GenericDialogActions, GenericGrid, GenericInput, GenericLabel, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import TabsComponent from "views/Components/TabsComponent";
import ButtonOverride from "./Tabs/ButtonOverride";
import General from "./Tabs/General";
import GridColumns from "./Tabs/GridColumns";
import Include from "./Tabs/Include";
import Input from "./Tabs/Input";

function Transition(props) {
	return <Slide direction="up" {...props} />;
}
class GenericScreenDefinition extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Include: [],
			Inputs: [],
			GridColumns: [],
			DocumentButtons: true,
			ButtonOverrides: [
				{
					Code: "Get",
					Hidden: false
				},
				{
					Code: "Insert",
					Hidden: false
				},
				{
					Code: "Delete",
					Hidden: false
				}
			]
		};

		this.initialSelectInputModel = {
			Method: "GET",
			Url: "/coreapi/v1.0/dynamic/do",
			Parameter: {},
			DataRoot: "Item",
			KeyValueMember: "Id",
			TextValueMember: "ParameterDesc"
		};

		this.state = {
			vModel: {},
			Id: 0,
			Code: "",
			model: this.initialModel,
			includeModel: {},
			inputModel: {},
			parameterModel: {},
			gridColumnsModel: {},
			buttonModel: {},
			tempParameters: {},
			isLoading: false,
			isPreviewDialogOpen: false
		};

		this.HandleClear = this.HandleClear.bind(this);
		this.HandleChangeScreen = this.HandleChangeScreen.bind(this);
		this.HandleChangeScreenSelect = this.HandleChangeScreenSelect.bind(this);

		this.HandleChange = this.HandleChange.bind(this);
		this.HandleChangeInclude = this.HandleChangeInclude.bind(this);
		this.HandleChangeInput = this.HandleChangeInput.bind(this);
		this.HandleChangeParameter = this.HandleChangeParameter.bind(this);
		this.HandleChangeGridColumns = this.HandleChangeGridColumns.bind(this);
		this.HandleChangeButton = this.HandleChangeButton.bind(this);

		this.HandleBooleanChange = this.HandleBooleanChange.bind(this);
		this.HandleBooleanChangeInput = this.HandleBooleanChangeInput.bind(this);
		this.HandleBooleanChangeParam = this.HandleBooleanChangeParam.bind(this);
		this.HandleBooleanChangeButton = this.HandleBooleanChangeButton.bind(this);

		this.HandleSubmit = this.HandleSubmit.bind(this);
		this.SuccessSubmitCallback = this.SuccessSubmitCallback.bind(this);
		this.ErrorSubmitCallback = this.ErrorSubmitCallback.bind(this);

		this.AddInclude = this.AddInclude.bind(this);
		this.DeleteInclude = this.DeleteInclude.bind(this);

		this.AddInput = this.AddInput.bind(this);
		this.UpdateInput = this.UpdateInput.bind(this);
		this.DeleteInput = this.DeleteInput.bind(this);
		this.ClearInputs = this.ClearInputs.bind(this);
		this.SelectInputModel = this.SelectInputModel.bind(this);

		this.AddParameter = this.AddParameter.bind(this);
		this.DeleteParameter = this.DeleteParameter.bind(this);

		this.AddGridColumns = this.AddGridColumns.bind(this);
		this.DeleteGridColumns = this.DeleteGridColumns.bind(this);
		this.SelectGridColumn = this.SelectGridColumn.bind(this);

		this.AddButton = this.AddButton.bind(this);
		this.UpdateButton = this.UpdateButton.bind(this);
		this.DeleteButton = this.DeleteButton.bind(this);
		this.ClearButtons = this.ClearButtons.bind(this);
		this.SelectButton = this.SelectButton.bind(this);

		this.Validate = this.Validate.bind(this);

		this.ShowPreview = this.ShowPreview.bind(this);

		this.ShowMessage = this.ShowMessage.bind(this);
		this.ShowMessageNode = this.ShowMessageNode.bind(this);
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Generic Screen Definition");
		this.props.setAppCenterTitle("SYSTEM ADMIN");
	}

	HandleClear() {
		this.setState({ model: this.initialModel, inputModel: {}, Id: 0, vModel: {} });
	}

	HandleChangeScreen(name, newValue) {
		this.setState({ Code: newValue });
	}

	HandleChangeScreenSelect(name, newValue, data) {
		let model = data != null ? JSON.parse(data.Data) : { ...this.initialModel };
		let Id = newValue;
		let Code = data?.Code;
		this.setState({ model, Code, Id });
	}

	HandleChange(name, newValue) {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}

	HandleChangeInclude(name, newValue) {
		const includeModel = { ...this.state.includeModel };
		includeModel[name] = newValue;
		this.setState({ includeModel });
	}

	HandleChangeInput(name, newValue) {
		const inputModel = { ...this.state.inputModel };
		inputModel[name] = newValue;
		if (newValue === "select" || newValue === "filter" || newValue === "radio") {
			this.setState({ inputModel: { ...inputModel, ...this.initialSelectInputModel } });
			return;
		}
		this.setState({ inputModel });
	}

	HandleChangeParameter(name, newValue) {
		const parameterModel = { ...this.state.parameterModel };
		parameterModel[name] = newValue;
		this.setState({ parameterModel });
	}

	HandleChangeGridColumns(name, newValue) {
		const gridColumnsModel = { ...this.state.gridColumnsModel };
		gridColumnsModel[name] = newValue;
		this.setState({ gridColumnsModel });
	}

	HandleChangeButton(name, newValue) {
		const buttonModel = { ...this.state.buttonModel };
		buttonModel[name] = newValue;
		this.setState({ buttonModel });
	}

	HandleBooleanChange(name, value) {
		const model = { ...this.state.model };
		model[name] = JSON.parse(value);
		this.setState({ model });
	}

	HandleBooleanChangeInput(name, value) {
		const inputModel = { ...this.state.inputModel };
		inputModel[name] = JSON.parse(value);
		this.setState({ inputModel });
	}

	HandleBooleanChangeParam(name, value) {
		const parameterModel = { ...this.state.parameterModel };
		parameterModel[name] = JSON.parse(value);
		this.setState({ parameterModel });
	}

	HandleBooleanChangeButton(name, value) {
		const buttonModel = { ...this.state.buttonModel };
		buttonModel[name] = JSON.parse(value);
		this.setState({ buttonModel });
	}

	HandleSubmit() {
		if (!this.Validate())
			return;

		const { Id, Code, model } = this.state;

		this.setState({ isLoading: true });

		Proxy.POST(
			"/coreapi/v1.0/Screen/InsertOrUpdate",
			{
				Id: Id,
				Code: Code,
				Data: JSON.stringify(model, null, 4)
			},
			this.SuccessSubmitCallback,
			this.ErrorSubmitCallback
		);
	}

	SuccessSubmitCallback(responseData) {
		this.setState({ isLoading: false });
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error", responseData.ErrorDescription);
			return;
		}
		this.ShowMessage("success", "Operation is successfully!");
	}

	ErrorSubmitCallback(error) {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "An error occurred while sending data", error.message);
	}

	AddInclude() {
		const { model } = this.state;
		const includeModel = { ...this.state.includeModel };

		if (!model.Include.includes(includeModel.Text))
			model.Include.push(includeModel.Text);
		this.setState({ model, includeModel: {} });
	}

	DeleteInclude() {
		const { model, includeModel } = this.state;
		const currentIndex = model.Include.findIndex(x => x == includeModel.Text);

		if (currentIndex > -1) {
			model.Include.splice(currentIndex, 1);
		}
		this.setState({ model, includeModel: {} });
	}

	AddInput() {
		const { model } = this.state;
		const inputModel = { ...this.state.inputModel };

		const input = model.Inputs.find(x => x.Name == inputModel.Name);
		if (input) {
			this.ShowMessage("warning", "Invalid", "The Name of the inputs must be unique!");
			return;
		}

		model.Inputs.push(inputModel);
		this.setState({ model, inputModel: {} });
	}

	UpdateInput() {
		const { model, inputModel } = this.state;
		const currentIndex = model.Inputs.findIndex(x => x.Name == inputModel.Name);

		if (currentIndex > -1) {
			model.Inputs[currentIndex] = inputModel;
		}
		this.setState({ model, inputModel: {} });
	}

	DeleteInput() {
		const { model, inputModel } = this.state;
		const currentIndex = model.Inputs.findIndex(x => x.Name == inputModel.Name);

		if (currentIndex > -1) {
			model.Inputs.splice(currentIndex, 1);
		}
		this.setState({ model, inputModel: {} });
	}

	ClearInputs() {
		this.setState({ inputModel: {} });
	}

	SelectInputModel(inputModel) {
		this.setState({ inputModel });
	}

	AddParameter() {
		let { model, inputModel } = this.state;
		const parameterModel = { ...this.state.parameterModel };
		var param = { [parameterModel.Name]: parameterModel.Value };
		if (parameterModel.Inner)
			param = { [parameterModel.Key]: param };

		var tempParameters = { ...inputModel.Parameter, ...param };

		const currentIndex = model.Inputs.findIndex(x => x.Name == inputModel.Name);

		if (currentIndex === -1) {
			this.ShowMessage("warning", "Warning", "You must add the input first!");
			return;
		}

		inputModel.Parameter = tempParameters;
		this.setState({ tempParameters: {}, parameterModel: {} });
		this.ShowMessage("success", "Info", "Added the parameter to input.");
	}

	DeleteParameter(paramModel) {
		let { model } = this.state;
		let isDeleted = false;

		for (let obj of model.Inputs.filter(input => { return input.Parameter !== undefined; })) {
			Object.entries(obj.Parameter).forEach(entry => {
				if (entry[0] === paramModel[0] && entry[1] === paramModel[1]) {
					delete obj.Parameter[paramModel[0]];
					isDeleted = true;
				}
			});
		}

		if (isDeleted) {
			this.setState({ model, parameterModel: {} });
			this.ShowMessage("success", "Info", "Removed the parameter");
		}
	}

	AddGridColumns() {
		const { model } = this.state;
		const gridColumnsModel = { ...this.state.gridColumnsModel };
		model.GridColumns.push(gridColumnsModel);
		this.setState({ model, gridColumnsModel: {} });
	}

	DeleteGridColumns() {
		const { model, gridColumnsModel } = this.state;
		const currentIndex = model.GridColumns.findIndex(x => x == gridColumnsModel);

		if (currentIndex > -1) {
			model.GridColumns.splice(currentIndex, 1);
		}
		this.setState({ model, gridColumnsModel: {} });
	}

	SelectGridColumn(gridColumnsModel) {
		this.setState({ gridColumnsModel });
	}

	AddButton() {
		const { model } = this.state;
		const buttonModel = { ...this.state.buttonModel };
		model.ButtonOverrides.push(buttonModel);
		this.setState({ model, buttonModel: {} });
	}

	UpdateButton() {
		const { model, buttonModel } = this.state;
		const currentIndex = model.ButtonOverrides.findIndex(x => x.Code == buttonModel.Code);

		if (currentIndex > -1) {
			model.ButtonOverrides[currentIndex] = buttonModel;
		}
		this.setState({ model, buttonModel: {} });
	}

	DeleteButton() {
		const { model, buttonModel } = this.state;
		const currentIndex = model.ButtonOverrides.findIndex(x => x == buttonModel);

		if (currentIndex > -1) {
			model.ButtonOverrides.splice(currentIndex, 1);
		}
		this.setState({ model, buttonModel: {} });
	}

	ClearButtons() {
		this.setState({ buttonModel: {} });
	}

	SelectButton(buttonModel) {
		this.setState({ buttonModel });
	}

	IsValidJson(json) {
		try {
			JSON.parse(json);
			return true;
		} catch (e) {
			return false;
		}
	}

	Validate() {
		const { model, Code, vModel } = this.state;

		if (Code.length !== 6) {
			vModel.Code = true;
			this.ShowMessage("warning", "Code not valid", "The Screen Code must be 6 characters!");
			return false;
		} else { vModel.Code = false; }

		if (!model.HeaderText) {
			vModel.HeaderText = true;
			this.ShowMessage("warning", "Header Text not selected", "Select Header Text Year to continue.");
			return false;
		} else { vModel.HeaderText = false; }

		if (!model.LeftTitle) {
			vModel.LeftTitle = true;
			this.ShowMessage("warning", "Left Title not selected", "Select Left Title Year to continue.");
			return false;
		} else { vModel.LeftTitle = false; }

		if (!model.CenterTitle) {
			vModel.CenterTitle = true;
			this.ShowMessage("warning", "Center Title not selected", "Select Center Title Year to continue.");
			return false;
		} else { vModel.CenterTitle = false; }
		this.setState({ vModel });
		return true;
	}

	ShowPreview(isShown = true) {
		if (isShown)
			this.setState({ isPreviewDialogOpen: true });
		else
			this.setState({ isPreviewDialogOpen: false });
	}

	ShowMessage(type, title, message) {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode(type, title, message) {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	render() {
		const { Disabled } = this.props;
		const { alert, model, inputModel, parameterModel, includeModel, gridColumnsModel, buttonModel, isLoading, isPreviewDialogOpen } = this.state;
		return (
			<div>


				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Get", OnClick: this.HandleGetList, Disabled: Disabled || model !== this.initialModel },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled || model === this.initialModel },
					{ Code: "Submit", OnClick: this.HandleSubmit, Disabled: Disabled || model === this.initialModel, ModelFunction: () => model, FillDataFromModel: model => this.setState({ model }), ValidationFunction: this.Validate },
					{ Code: "Preview", OnClick: this.ShowPreview, Disabled: Disabled || model === this.initialModel },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<GenericDialog open={isPreviewDialogOpen} fullScreen onBackdropClick={() => this.ShowPreview(false)} TransitionComponent={Transition}>
					<DialogTitle >
						<GenericLabel Bold={true} FontSize="16px" Text="Preview"></GenericLabel>

					</DialogTitle>
					<DialogContent>
						<br />
						<GenericLabel Bold={true} FontSize="16px" TextColor="black" Text="Filter Panel"></GenericLabel>
						<GridContainer>
							{
								model.Inputs.map((view, i) =>
									<GridItem key={i} xs={4}>
										<GenericInput ViewData={view} Value={model[view.Name]} ValueChanged={this.HandleChange} Model={model} FilterParameter={{ [view.FilterParameter]: model[view.FilterParameter] }} />
									</GridItem>)
							}
						</GridContainer>
						<GridContainer>
							<GridItem xs={12}>
								<br />
								<GenericLabel Bold={true} FontSize="16px" TextColor="black" Text="List"></GenericLabel>
								<GenericGrid
									Columns={model.GridColumns.map(obj => {
										return {
											Header: obj.Title,
											accessor: obj.ColumnName
										};
									})}
									DocumentTitle={model.CenterTitle}
									ShowPagination={model.ShowPagination}
									HideButton={model.DocumentButtons === false}
									Sorted={model.Sorted && model.Sorted.map(obj => {
										return {
											id: obj.ColumnName || obj.id,
											desc: obj.Desc || obj.desc
										};
									})}
								/>
							</GridItem>
						</GridContainer>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={() => this.ShowPreview(false)}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>

				<Card>
					<CardBody>
						<GridContainer>
							<GridItem xs={3}>
								<GenericSelectInput
									Name="Id"
									LabelMd={4}
									LabelText="Screen"
									Method="GET"
									Url="/coreapi/v1.0/Screen/GetScreenList"
									DataRoot="Item"
									KeyValueMember="Id"
									RenderItem={d => `${d.Id} - ${d.Code}`}
									Value={this.state.Id}
									ValueChanged={this.HandleChangeScreenSelect} />
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>

				<Card >
					<CardBody>
						<TabsComponent
							tabList={[
								{
									tabButton: "General",
									tabContent: (
										<General
											vModel={this.state.vModel}
											model={model}
											code={this.state.Code}
											handleChange={this.HandleChange}
											handleChangeScreen={this.HandleChangeScreen}
											handleBooleanChange={this.HandleBooleanChange}
										/>
									)
								},
								{
									tabButton: "Include",
									tabContent: (
										<Include
											jsonModel={model}
											model={includeModel}
											handleChange={this.HandleChangeInclude}
											handleAdd={this.AddInclude}
											handleDelete={this.DeleteInclude}
										/>
									)
								},
								{
									tabButton: "Input",
									tabContent: (
										<Input
											jsonModel={model}
											model={inputModel}
											paramModel={parameterModel}
											handleChange={this.HandleChangeInput}
											handleBooleanChange={this.HandleBooleanChangeInput}
											handleAdd={this.AddInput}
											handleUpdate={this.UpdateInput}
											handleDelete={this.DeleteInput}
											handleClear={this.ClearInputs}
											handleChangeParam={this.HandleChangeParameter}
											handleAddParam={this.AddParameter}
											handleDeleteParam={this.DeleteParameter}
											handleBooleanChangeParam={this.HandleBooleanChangeParam}
											handleSelectInput={this.SelectInputModel}
										/>
									)
								},
								{
									tabButton: "Grid",
									tabContent: (
										<GridColumns
											jsonModel={model}
											model={gridColumnsModel}
											handleChange={this.HandleChangeGridColumns}
											handleAdd={this.AddGridColumns}
											handleDelete={this.DeleteGridColumns}
											handleSelectGridColumn={this.SelectGridColumn}
										/>
									)
								},
								{
									tabButton: "Button",
									tabContent: (
										<ButtonOverride
											jsonModel={model}
											model={buttonModel}
											handleChange={this.HandleChangeButton}
											handleBooleanChange={this.HandleBooleanChangeButton}
											handleAdd={this.AddButton}
											handleUpdate={this.UpdateButton}
											handleDelete={this.DeleteButton}
											handleClear={this.ClearButtons}
											handleSelectButton={this.SelectButton}
										/>
									)
								}
							]}
						/>
					</CardBody>
				</Card>
				<Card>
					<CardBody>
						<GenericLabel Bold={true} FontSize="12px" TextColor="black" Text="JSON"></GenericLabel>
						<div>
							<GenericTextInput
								LabelMd={0}
								Value={JSON.stringify(model, null, 4)}
								MultiLine={true}
								RowCount={24}
								Disabled={true} />
						</div>
					</CardBody>
				</Card>

			</div >
		);
	}
}

GenericScreenDefinition.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withArtifex(GenericScreenDefinition, {});
