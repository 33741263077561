import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import CustomerComponent from "views/Components/CustomerComponent.jsx";
import { GenericCheckInput, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType, ProgramCodes, EmbossType } from "views/Constants/Constant.js";
import kycManualPhotoDefinitionStyle from "assets/jss/material-dashboard-pro-react/views/kycManualPhotoDefinitionStyle.jsx";
import GenericCardMedia from "views/Components/Generic/GenericCardMedia.jsx";

const galerydefault = require("assets/img/galerydefault-image.png");


class CreateSupplementaryCard extends Component {
	constructor(props) {
		super(props);
		this.parameterEmbossType = { ParameterCode: "EmbossType" };

		this.initialModel = {
			Id: 0,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined,
			IsMainCard: false,
			IsMass: true
		};

		this.state = {
			model: { ...this.initialModel },
			vModel: {},
			list: [],
			fileInfoModel: {},
			index: -1,
			isLoading: false
		}

		this.emptyObject = {};

		this.maxLength21 = { maxLength: 21 };
		this.maxLength10 = { maxLength: 10 };

		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined
		};

		this.renderItemUniqueClient = {};

		this.parameterProduct = {};

		this.parameterMainCard = {};

		this.parameterPeriod = { ParameterCode: "LoadingPeriod" };

		this.SortedProductName = { Member: "ProductName" };

		this.Columns = [
			{
				Header: "Client",
				accessor: "UniqueClient.ClientName"
			},
			{
				Header: "Customer",
				accessor: "ClientCustomer.CustomerName"
			},
			{
				Header: "Card Number",
				accessor: "CardNumber"
			},
			{
				Header: "Product Name",
				accessor: "Product.ProductName"
			},
			{
				Header: "Emboss Name",
				accessor: "EmbossName1"
			},
			{
				Header: "Emboss 4th Line",
				accessor: "EmbossName2"
			},
			{
				Header: "Usage Period",
				accessor: "CardUsagePeriod.ParameterDesc"
			},
			{
				Header: "Usage Limit",
				accessor: "CardUsageLimit",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Zip Code",
				accessor: "AvsZipCode",
			}
		];
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Create Supplementary Card");
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
	}
	handleChange = async (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		const { ExecuteApiPost } = this.props;

		var responseData = await ExecuteApiPost("/prepaidapi/v1.0/Product/GetProductImage", { Id: model.ProductId, UniqueClientId: model.UniqueClientId });
		var fileInfoModel = { ...this.state.fileInfoModel }

		if (responseData == null || responseData == undefined) {
			fileInfoModel.StoredImage = galerydefault;
			this.setState({ model });
		}

		if (responseData != null || responseData != undefined) {
			var cardNumber = document.getElementById("cardNumber").textContent = "5032 9334 3764 9846";
			cardNumber = document.getElementById("cardNumber").style.position = "absolute";
			cardNumber = document.getElementById("cardNumber").style.left = "20px";
			cardNumber = document.getElementById("cardNumber").style.bottom = "55px";
			cardNumber = document.getElementById("cardNumber").style.color = "white";
			cardNumber = document.getElementById("cardNumber").style.fontSize = "15px";

			var cardName = document.getElementById("cardName").textContent = "Alexander Theodore";
			cardName = document.getElementById("cardName").style.position = "absolute";
			cardName = document.getElementById("cardName").style.left = "20px";
			cardName = document.getElementById("cardName").style.bottom = "20px";
			cardName = document.getElementById("cardName").style.color = "white";
			cardName = document.getElementById("cardName").style.fontSize = "15px";

			var cardDate = document.getElementById("cardDate").textContent = "09/19";
			cardDate = document.getElementById("cardDate").style.position = "absolute";
			cardDate = document.getElementById("cardDate").style.left = "160px";
			cardDate = document.getElementById("cardDate").style.bottom = "30px";
			cardDate = document.getElementById("cardDate").style.color = "white";
			cardDate = document.getElementById("cardDate").style.fontSize = "15px";

			if (responseData.CardChipTypeId == 922702) {
				var documentry = document.getElementById("cardChipType").style.width = "50px";
				documentry = document.getElementById("cardChipType").style.height = "40px";
				documentry = document.getElementById("cardChipType").style.left = "20px";
				documentry = document.getElementById("cardChipType").style.bottom = "80px";
				documentry = document.getElementById("cardChipType").style.position = "absolute";
				documentry = document.getElementById("cardChipType").style.borderRadius = "5px";
				documentry = document.getElementById("cardChipType").style.backgroundColor = "white";
			}
			else {
				var documentry = document.getElementById("cardChipType").style.width = "";
				documentry = document.getElementById("cardChipType").style.height = "";
				documentry = document.getElementById("cardChipType").style.left = "";
				documentry = document.getElementById("cardChipType").style.bottom = "";
				documentry = document.getElementById("cardChipType").style.position = "";
				documentry = document.getElementById("cardChipType").style.borderRadius = "";
				documentry = document.getElementById("cardChipType").style.backgroundColor = "";
			}

			if (responseData.CardBrandId == 3400) {
				var documentry2 = document.getElementById("cardBrandId").style.width = "80px";
				documentry2 = document.getElementById("cardBrandId").style.height = "37px";
				documentry2 = document.getElementById("cardBrandId").style.left = "235px";
				documentry2 = document.getElementById("cardBrandId").style.bottom = "15px";
				documentry2 = document.getElementById("cardBrandId").style.position = "absolute";
				documentry2 = document.getElementById("cardBrandId").style.borderRadius = "10px";
				documentry2 = document.getElementById("cardBrandId").style.backgroundColor = "white";
			}
			else if (responseData.CardBrandId == 3403) {
				var documentry2 = document.getElementById("cardBrandId").style.width = "48px";
				documentry2 = document.getElementById("cardBrandId").style.height = "48px";
				documentry2 = document.getElementById("cardBrandId").style.left = "265px";
				documentry2 = document.getElementById("cardBrandId").style.bottom = "15px";
				documentry2 = document.getElementById("cardBrandId").style.position = "absolute";
				documentry2 = document.getElementById("cardBrandId").style.borderRadius = "10px";
				documentry2 = document.getElementById("cardBrandId").style.backgroundColor = "white";
			}
			else if (responseData.CardBrandId == 3401) {
				var documentry2 = document.getElementById("cardBrandId").style.width = "80px";
				documentry2 = document.getElementById("cardBrandId").style.height = "55px";
				documentry2 = document.getElementById("cardBrandId").style.left = "235px";
				documentry2 = document.getElementById("cardBrandId").style.bottom = "15px";
				documentry2 = document.getElementById("cardBrandId").style.position = "absolute";
				documentry2 = document.getElementById("cardBrandId").style.borderRadius = "30px";
				documentry2 = document.getElementById("cardBrandId").style.backgroundColor = "white";
			}
			else {
				var documentry2 = document.getElementById("cardBrandId").style.width = "";
				documentry2 = document.getElementById("cardBrandId").style.height = "";
				documentry2 = document.getElementById("cardBrandId").style.left = "";
				documentry2 = document.getElementById("cardBrandId").style.bottom = "";
				documentry2 = document.getElementById("cardBrandId").style.position = "";
				documentry2 = document.getElementById("cardBrandId").style.borderRadius = "";
				documentry2 = document.getElementById("cardBrandId").style.backgroundColor = "";
			}

			if (responseData.CardContactlessColorTypeId == 922699) {
				var documentry3 = document.getElementById("cardContactlessColorType").style.position = "absolute";
				documentry3 = document.getElementById("cardContactlessColorType").style.width = "40px";
				documentry3 = document.getElementById("cardContactlessColorType").style.height = "40px";
				documentry3 = document.getElementById("cardContactlessColorType").style.left = "275px";
				documentry3 = document.getElementById("cardContactlessColorType").style.bottom = "100px";
				documentry3 = document.getElementById("cardContactlessColorType").style.borderRadius = "40px";
				documentry3 = document.getElementById("cardContactlessColorType").style.backgroundColor = "black";
			}
			else if (responseData.CardContactlessColorTypeId == 922700) {
				var documentry3 = document.getElementById("cardContactlessColorType").style.position = "absolute";
				documentry3 = document.getElementById("cardContactlessColorType").style.width = "40px";
				documentry3 = document.getElementById("cardContactlessColorType").style.height = "40px";
				documentry3 = document.getElementById("cardContactlessColorType").style.left = "275px";
				documentry3 = document.getElementById("cardContactlessColorType").style.bottom = "100px";
				documentry3 = document.getElementById("cardContactlessColorType").style.borderRadius = "40px";
				documentry3 = document.getElementById("cardContactlessColorType").style.backgroundColor = "white";
			}
			else {
				var documentry3 = document.getElementById("cardContactlessColorType").style.position = "";
				documentry3 = document.getElementById("cardContactlessColorType").style.width = "";
				documentry3 = document.getElementById("cardContactlessColorType").style.height = "";
				documentry3 = document.getElementById("cardContactlessColorType").style.left = "";
				documentry3 = document.getElementById("cardContactlessColorType").style.bottom = "";
				documentry3 = document.getElementById("cardContactlessColorType").style.borderRadius = "";
				documentry3 = document.getElementById("cardContactlessColorType").style.backgroundColor = "";
			}
			this.setState({ model, fileInfoModel: responseData });
		}
	}

	HandleChange = (name, newValue, data) => {
		this.setState(state => {
			var model = state.model;
			if (name === "Customer") {
				if (newValue) {
					model.ClientCustomerId = newValue.Id;
					model.CustomerNumber = newValue.CustomerNumber;
				} else {
					delete model.ClientCustomerId;
					delete model.CustomerNumber;
				}
			}
			if (name === "UniqueClientId") {
				delete model.ClientCustomerId;
				delete model.CustomerNumber;
			}
			if (name === "EmbossType" && newValue != EmbossType.InstantId) {
				model.IsMass = true;
				model.InstantEmbossBranchId = undefined;
			}
			if (name === "EmbossType" && newValue == EmbossType.InstantId) {
				model.IsMass = false;
			}
			model[name] = newValue;
			return { model };
		});
	}

	HandleClear = () => {
		this.setState({ model: { ...this.initialModel }, isEdit: false, index: -1 });
	}

	HandleSearch = () => {
		const model = { ...this.state.model };
		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/Card/GetCardSummaryList",
			{
				IsMainCard: model.IsMainCard,
				UniqueClientId: model.UniqueClientId || 0,
				ClientCustomerId: model.ClientCustomerId,
				ProductId: model.ProductId,
				EmbossName1: model.EmbossName,
				CardUsagePeriodId: model.CardUsagePeriodId,
				CardUsageLimit: model.CardUsageLimit,
				EmbossTypeId: model.EmbossType,
				AvsZipCode: model.AvsZipCode
			},
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ list: responseData.Item });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	HandleSubmit = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/Prepaid/CreateSupplementaryCard",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.HandleClear();
				this.HandleSearch();
				this.props.showMessage("success", "Success", "Operation is successfully!");
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	Validate = () => {
		const { model, vModel } = this.state;
		var messages = [];

		vModel.UniqueClientId = model.UniqueClientId == null;
		if (vModel.UniqueClientId) messages.push("Client must be select");

		vModel.ClientCustomerId = model.ClientCustomerId == null;
		if (vModel.ClientCustomerId) messages.push("Customer must be select");

		vModel.MainCardId = model.MainCardId == null;
		if (vModel.MainCardId) messages.push("Main Card must be select");

		vModel.ProductId = model.ProductId == null;
		if (vModel.ProductId) messages.push("Product must be select");

		vModel.EmbossName = (model.EmbossName == null || model.EmbossName.trim() == "");
		if (vModel.EmbossName) messages.push("Emboss Name cannot be empty");

		vModel.CardUsagePeriodId = model.HasCardUsageLimit && model.CardUsagePeriodId == null;
		if (vModel.CardUsagePeriodId) messages.push("Card Usage Period must be select");

		vModel.CardUsageLimit = model.HasCardUsageLimit && (model.CardUsageLimit == null || model.CardUsageLimit == 0);
		if (vModel.CardUsageLimit) messages.push("Card Usage Limit cannot be empty");

		vModel.EmbossType = model.EmbossType == null;
		if (vModel.EmbossType) messages.push("Emboss Type must be select");

		vModel.InstantEmbossBranchId = model.InstantEmbossBranchId == null && model.EmbossType == EmbossType.InstantId;
		if (vModel.EmbossType) messages.push("Card Perso Office must be select");

		if (messages.length > 0) {
			this.props.showMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.setState({ vModel });
		return true;
	}

	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}

	RowSelected = (index) => {
		let { list } = this.state;
		let model = list[index];
		if (model.ClientCustomer) {
			model.ClientCustomerId = model.ClientCustomer.Id;
			model.CustomerNumber = model.ClientCustomer.CustomerNumber;
		}
		model.EmbossName = model.EmbossName1;
		this.setState({ isEdit: true, model, index });
	}

	GetProductParameter = () => {
		this.parameterProduct.UniqueClientId = this.state.model.UniqueClientId;
		this.parameterProduct.IsNoNameProduct = false;
		return this.parameterProduct;
	}

	GetMainCardParameter = () => {
		this.parameterMainCard.IsMainCard = true;
		this.parameterMainCard.IsNoNameCard = false;
		this.parameterMainCard.IsActive = true;
		this.parameterMainCard.CardSearchCriteria = 2; // OnlyMainCards,
		this.parameterMainCard.ClientCustomerId = this.state.model.ClientCustomerId;
		return this.parameterMainCard;
	}

	FillCustomer = (customer) => {
		this.HandleChange("Customer", customer);
	}

	render() {
		const { classes, Disabled, ExecuteApiPost } = this.props;
		const { alert, model, vModel, list, isLoading, index, fileInfoModel } = this.state;

		var IsClient = ClientHelper.IsClient();

		return (
			<>
				<LoadingComponent Show={isLoading} />

				{alert}

				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
					{ Code: "Submit", OnClick: this.HandleSubmit, Disabled: Disabled || model.Id > 0, ModelFunction: () => model, FillDataFromModel: model => this.setState({ model }), ValidationFunction: this.Validate },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Panel">
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer>
												<GridItem xs={4}>
													<GenericSelectInput
														Name="UniqueClientId"
														LabelText="Client"
														Method="POST"
														Url="/bankapi/v1.0/BankCustomer/Search"
														Parameter={this.parameterUniqueClientId}
														DataRoot="Item"
														KeyValueMember="Id"
														RenderItem={this.RenderItemUniqueClient}
														Value={model.UniqueClientId}
														ValueChanged={this.HandleChange}
														CanClear
														Disabled={IsClient}
														DefaultIndex={IsClient ? 0 : -1}
														Required
														IsInvalid={vModel.UniqueClientId} />
												</GridItem>
												<GridItem xs={4}>
													<CustomerComponent
														key={model.UniqueClientId}
														LabelMd={4}
														CustomerNumber={model.CustomerNumber}
														HandleChange={this.HandleChange}
														FillCallback={this.FillCustomer}
														Programs={[ProgramCodes.Prepaid]}
														UniqueClientId={model.UniqueClientId}
														Required
														IsInvalid={vModel.ClientCustomerId} />
												</GridItem>
												<GridItem xs={4}>
													<GenericSelectInput
														key={model.ClientCustomerId}
														Name="MainCardId"
														LabelText="Main Card"
														Method="POST"
														Url="/prepaidapi/v1.0/Card/CardSearch"
														Parameter={this.GetMainCardParameter()}
														DataRoot="Item"
														KeyValueMember="CardId"
														TextValueMember="MaskedCardNumber"
														Value={model.MainCardId}
														ValueChanged={this.HandleChange}
														Required
														IsInvalid={vModel.MainCardId} />
												</GridItem>
												<GridItem xs={4}>
													{
														model.IsMass ?
															<GenericSelectInput
																key={model.UniqueClientId}
																Name="ProductId"
																LabelText="Product"
																Method="POST"
																Url="/prepaidapi/v1.0/Product/SearchNonCredit"
																Parameter={this.GetProductParameter()}
																DataRoot="Item"
																KeyValueMember="Id"
																TextValueMember="Name"
																Value={model.ProductId}
																ValueChanged={this.HandleChange}
																Sorted={this.SortedProductName}
																Required
																IsInvalid={vModel.ProductId} />
															:
															<GenericSelectInput
																key={model.IsMass}
																Name="ProductId"
																LabelText="Product"
																Method="POST"
																Url="/prepaidapi/v1.0/Product/ApprovedSearchNonCredit"
																Parameter={this.GetProductParameter()}
																DataRoot="Item"
																KeyValueMember="Id"
																TextValueMember="Name"
																Value={model.ProductId}
																ValueChanged={this.handleChange}
																Sorted={this.SortedProductName}
																Required
																IsInvalid={vModel.ProductId} />
													}

												</GridItem>
												<GridItem xs={4}>
													<GenericTextInput
														Name="EmbossName"
														LabelText="Emboss Name"
														inputProps={this.maxLength21}
														IsText={true}
														Value={model.EmbossName}
														ValueChanged={this.HandleChange}
														Required
														IsInvalid={vModel.EmbossName} />
												</GridItem>
												<GridItem xs={4}>
													<GenericTextInput
														Name="Emboss4thLine"
														LabelText="Emboss 4th Line"
														inputProps={this.maxLength21}
														Value={model.Emboss4thLine}
														ValueChanged={this.HandleChange}
														IsInvalid={vModel.Emboss4thLine} />
												</GridItem>
												<GridItem xs={4} style={{ opacity: !model.HasCardUsageLimit ? 0.4 : 1 }}>
													<GenericSelectInput
														Name="CardUsagePeriodId"
														LabelText="Card Usage Period"
														Method="POST"
														Url="/coreapi/v1.0/Parameter/Search"
														Parameter={this.parameterPeriod}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="ParameterDesc"
														Value={model.CardUsagePeriodId}
														ValueChanged={this.HandleChange}
														Disabled={!model.HasCardUsageLimit}
														Required={model.HasCardUsageLimit}
														IsInvalid={vModel.CardUsagePeriodId} />
												</GridItem>
												<GridItem xs={4} style={{ opacity: !model.HasCardUsageLimit ? 0.4 : 1 }}>
													<GenericNumberInput
														Name="CardUsageLimit"
														LabelText="Card Usage Limit"
														Prefix="$"
														MaxLength={12}
														Value={model.CardUsageLimit}
														ValueChanged={this.HandleChange}
														Disabled={!model.HasCardUsageLimit}
														Required={model.HasCardUsageLimit}
														IsInvalid={vModel.CardUsageLimit} />
												</GridItem>
												<GridItem xs={4} style={{ marginTop: 8 }}>
													<GenericCheckInput
														Name="HasCardUsageLimit"
														LabelText="Has Card Usage Limit"
														Value={model.HasCardUsageLimit || false}
														ValueChanged={this.HandleChange}
														Disabled={Disabled} />
												</GridItem>
												<GridItem xs={4}>
													<GenericSelectInput
														Name="EmbossType"
														LabelText="Emboss Type"
														Method="POST"
														Url="/coreapi/v1.0/Parameter/Search"
														Parameter={this.parameterEmbossType}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="ParameterDesc"
														ValueChanged={this.HandleChange}
														Value={model.EmbossType}
														Required
														IsInvalid={vModel.EmbossType}
														DefaultIndex={1}
													/>
												</GridItem>
												<GridItem xs={4}>
													<GenericSelectInput
														Name="InstantEmbossBranchId"
														LabelText="Card Perso Office"
														Method="POST"
														Url="/prepaidapi/v1.0/Card/GetInstantCardBranchList"
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="Description"
														ValueChanged={this.HandleChange}
														Value={model.InstantEmbossBranchId}
														Disabled={model.IsMass}
														Required
														IsInvalid={vModel.InstantEmbossBranchId}
													/>
												</GridItem>
												<GridItem xs={4}>
													<GenericTextInput
														Name="AvsZipCode"
														LabelText="Zip Code"
														inputProps={this.maxLength10}
														IsText={false}
														Value={model.AvsZipCode}
														ValueChanged={this.HandleChange}
														IsInvalid={vModel.AvsZipCode} />
												</GridItem>
											</GridContainer>
											{
												model.IsMass ?
													<GridItem>
													</GridItem>
													:
													< GridContainer >
														<GridItem xs={4}>
															<br></br>
															<br></br>

															<div id="parent" style={{ position: "relative", width: 323, height: 204 }}>
																<GenericCardMedia
																	ExecuteApiPost={ExecuteApiPost}
																	className={classes.imageProfile}
																	ImageFileId={vModel.Id}
																	Image={fileInfoModel.StoredImage}
																	ImagePlaceholder={galerydefault}
																/>
																<div id="cardNumber"></div>
																<div id="cardName"></div>
																<div id="cardDate"></div>
																<div id="cardChipType"></div>
																<div id="cardBrandId"></div>
																<div id="cardContactlessColorType"></div>
															</div>
														</GridItem>
													</GridContainer>

											}

										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer >

				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text="Supplementary Card List" />
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={3}>
									</GridItem>
									<GridItem xs={8} />
								</GridContainer>
								<GridContainer spacing={16}>
									<GridItem xs={12}>
										<GenericGrid
											Data={list}
											Columns={this.Columns}
											RowSelected={this.RowSelected}
											SelectedIndex={index}
											ProgramCode={ProgramCodes.Prepaid} />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</>
		);
	}
}

CreateSupplementaryCard.propTypes = {
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool
};

export default withArtifex(CreateSupplementaryCard, kycManualPhotoDefinitionStyle);