import { Dialog, DialogContent } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import RouteHelper from "core/RouteHelper";
import PropTypes, { func } from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericTitle, GenericAlert, GenericDateInput, GenericGrid, GenericNumberInput, GenericExpansionPanel, GenericSelectInput } from "views/Components/Generic";
import { Portals, GridColumnType } from "views/Constants/Constant";
import ParameterComponent from "views/Components/ParameterComponent";

class CHQTransactionSearch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			model: {},
			toolBarModel: {},
			tempModel: {},
			getDataList: [],
			isLoading: false,
			poolModel: {},
			isBackOff: false
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleChangeTempModel = this.handleChangeTempModel.bind(this);
		this.GetData = this.GetData.bind(this);
		this.errorCallback = this.errorCallback.bind(this);
		this.hideAlert = this.hideAlert.bind(this);
		this.showErrorMessage = this.showErrorMessage.bind(this);
		this.successSummaryCallback = this.successSummaryCallback.bind(this);
		this.successCallback = this.successCallback.bind(this);
	}
	componentDidMount() {
		const { location } = this.props;
		var isBackOff = ClientHelper.IsBackOffice();
		var model = { ...this.state.model };
		if (!isBackOff) {
			model["ClientId"] = ClientHelper.GetClientRowId();
		}
		this.props.setAppLeftTitle("Cheque Transaction Search");
		this.props.setAppCenterTitle("CHEQUE MANAGEMENT");
		this.props.setPortal(Portals.DDT);

		var data = RouteHelper.ReadAndClear("CHQTransactionSearch_Date");

		if (data) {
			model.FromDate = data.FromDate;
			model.ToDate = data.ToDate;
			model.IsSettled = data.IsSettled;
			this.setState({ isBackOff: isBackOff, model });
			this.GetData(model);
		}
		else {
			this.setState({ isBackOff: isBackOff, model });
		}
	}



	successCallback(responseData) {
		var toolBarModel = { ...this.state.toolBarModel };
		var poolModel = { ...this.state.poolModel };
		var model = { ...this.state.model };
		this.setState({ model, toolBarModel, poolModel, isLoading: false });
	}

	handleChange(name, newValue, data) {
		const model = { ...this.state.model };
		if (name == "SettlementStatus")
			if (data.ParameterDesc == "Yes")
				model[name] = true;
			else
				model[name] = false;
		else
			model[name] = newValue;
		this.setState({ model });
	}

	handleChangeTempModel(name, newValue, data) {
		const tempModel = { ...this.state.tempModel };
		const model = { ...this.state.model };
		if (name == "SettlementStatus") {
			if (newValue != -1) {
				if (data.ParameterDesc == "Yes") {
					tempModel[name] = newValue;
					model[name] = true;
				}
				else {
					tempModel[name] = newValue;
					model[name] = false;
				}
			} else {
				tempModel[name] = newValue;
				model[name] = false;
			}
		}
		else
			tempModel[name] = newValue;
		this.setState({ tempModel, model });
	}

	GetData(model) {
		this.setState({ isLoading: true });
		// if (this.state.model.SearchStartDate == null)
		// 	this.showErrorMessage("Date / To can not be empty !!");
		// else {
		Proxy.POST(
			"/chequeapi/v1.0/Cheque/SearchTransactionChq",
			model ? model : this.state.model,
			this.successSummaryCallback,
			this.errorCallback
		);
		// }
	}

	successSummaryCallback(responseData) {
		this.hideAlert();
		if (!responseData.IsSucceeded) {
			this.showErrorMessage(responseData.ErrorDescription);
			return;
		}
		if (responseData.Item == null || responseData.Item.length < 1) {
			this.setState({ getDataList: [], isLoading: false });
		} else {
			this.setState({
				getDataList: responseData.Item.map(x => {
					return {
						ClientName: x.ClientName == null ? "" : x.ClientName,
						TransactionSource: x.TransactionSource == null ? "" : x.TransactionSource,
						CustomerNumber: x.CustomerNumber == null ? "" : x.CustomerNumber,
						TransitNumber: x.TransitNumber == null ? "" : x.TransitNumber,
						AccountNumber: x.AccountNumber == null ? "" : x.AccountNumber,
						Date: x.Date == null ? "" : x.Date,
						TransactionType: x.TransactionType == null ? "" : x.TransactionType,
						SettlementStatus: x.SettlementStatus == null ? "" : x.SettlementStatus == true ? "Yes" : "No",
						Amount: x.Amount == null ? "" : x.Amount,
						ChqStatus: x.ChqStatus == null ? "" : x.ChqStatus,
						ChqStatusCode: x.ChqStatusCode == null ? "" : x.ChqStatusCode

					};
				}), isLoading: false
			});
		}
	}

	errorCallback(error) {
		this.setState({ isLoading: false, alert: null });
		this.showErrorMessage("An error occurred during the api visit" + error);
	}

	showErrorMessage(message) {
		this.setState({
			isLoading: false,
			getDataList: [],
			alert: <GenericAlert Title={"Error"} MessageNode={message} Type={"warning"} OnConfirm={() => this.hideAlert()} />
		});
	}

	hideAlert() {
		this.setState({ alert: null });
	}
	handleClear = () => {


		this.setState({
			model: {},
			getDataList: [],

		});
	}
	render() {
		const { Disabled } = this.props;
		const { model, poolModel, isBackOff } = this.state;
		return (
			<div>
				{this.state.alert}


				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.GetData, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.handleClear, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<Card className="no-radius">
					<GenericExpansionPanel Title={"Main Parameters"}>
						<CardBody>
							<GridContainer>
								<GridItem xs={12} sm={5} md={4}>
									<GenericSelectInput
										Name="ClientId"
										LabelText="Client"
										Method="POST"
										Url="/bankapi/v1.0/BankCustomer/Search"
										Parameter={{}}
										DataRoot="Item"
										KeyValueMember="Id"
										RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
										Value={model.ClientId || ""}
										ValueChanged={this.handleChange}
										CanClear
										All
										Disabled={!isBackOff} />
									<GenericDateInput
										Name="FromDate"
										LabelMd={4}
										LabelText="From"
										Value={model == null || undefined ? "" : model.FromDate ? DateHelper.ToDateInputValue(model.FromDate) : ""}
										ValueChanged={this.handleChange}
										IncludeTime={false}
										MaxDate={model.ToDate == null ? DateHelper.GetDate() : model.ToDate} />
									<GenericDateInput
										Name="ToDate"
										LabelText="To"
										LabelMd={4}
										Value={model == null || undefined ? "" : model.ToDate ? DateHelper.ToDateInputValue(model.ToDate) : ""}
										ValueChanged={this.handleChange}
										IncludeTime={false}
										MaxDate={DateHelper.GetDate()}
										MinDate={model.FromDate} />
								</GridItem>

								<GridItem xs={12} sm={2} md={4}>
									<ParameterComponent
										Name="TransactionStatusId"
										Value={model.TransactionStatusId}
										LabelText="Transaction Status"
										ParameterCode="BankTransactionStatus"
										ValueChanged={this.handleChange} />
									<GenericSelectInput
										Name="TransactionTypeId"
										LabelText="Transaction Type"
										Method="GET"
										Url="/bankapi/v1.0/Transaction/GetAllFinancialTransactionType"
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={model.TransactionTypeId}
										ValueChanged={this.handleChange}
									/>
									<ParameterComponent
										Name="TransactionSourceId"
										Value={model.TransactionSourceId}
										LabelText="Transaction Source"
										ParameterCode="TransactionSource"
										ValueChanged={this.handleChange} />
								</GridItem>


							</GridContainer><br />
						</CardBody>
					</GenericExpansionPanel>
				</Card>
				<Card className="no-radius">
					<CardHeader>
						<GenericTitle text={"Cheque Transactions"} />
					</CardHeader>
					<CardBody>
						<GenericGrid
							Data={this.state.getDataList}
							Columns={[
								{
									Header: "Transaction Date",
									accessor: "Date",
									type: GridColumnType.DateTime
								},
								{
									Header: "Wallet Number",
									accessor: "AccountNumber"
								},
								{
									Header: "Transaction Source",
									accessor: "TransactionSource"
								},
								{
									Header: "Transaction Type",
									accessor: "TransactionType"
								},
								{
									Header: "Name of Customer",
									accessor: "ClientName"
								},
								{
									Header: "Amount",
									accessor: "Amount",
									type: GridColumnType.Money,
									ColumnType:GridColumnType.Money
								},
								{
									Header: "Transit Number",
									accessor: "TransitNumber"
								},
								// {
								// 	Header: "Cheque Serial Number",
								// 	accessor: "ChequeSerialNumber"
								// },

								{
									Header: "Cheque Status",
									accessor: "ChqStatus"
								},
								{
									Header: "Cheque Status Code",
									accessor: "ChqStatusCode"
								},
								{
									Header: "Settlement Status",
									accessor: "SettlementStatus"
								}
							]}
						/>
						<br /> <br />
					</CardBody>
				</Card>
				<br />
			</div>
		);
	}
}

CHQTransactionSearch.propTypes = {
	classes: PropTypes.object,
	handleOperationType: PropTypes.func,
	SelectedRowChange: PropTypes.func,
	showQuestionMessage: func,
	setAppLeftTitle: func,
	setAppCenterTitle: func,
	setPortal: func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.number,
	ApprovalData: PropTypes.array,
	After: PropTypes.func
};

export default withArtifex(CHQTransactionSearch, {});