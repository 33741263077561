import { Card } from "@material-ui/core";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import { EmailIcon, PrintIcon } from "core/Icons";
import MenuAuthorityRightHelper from "core/MenuAuthorityRightHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericAlert } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { MenuCodes } from "views/Constants/Constant";

class QrCode extends Component {
	constructor(props) {
		super(props);
		this.QrCode = "";
		this.state = {
			QrCode: "",
			isEmailDisable: false,
			isPrintDisable: false
		};
		this.MenuAuthorityRightHelper = new MenuAuthorityRightHelper();

	}

	componentDidMount() {
		if (this.props.MenuCode == MenuCodes.CallCenter_CardMonitoring)
			this.GetGridButtonsRight();
	}
	GetGridButtonsRight = async () => {
		const { MenuCode } = this.props;
		const model = { ...this.state };
		var gridButtonRightDetail = await this.MenuAuthorityRightHelper.RightCheckList(MenuCode, ["CALCMTSEB", "CALCMTPRB"]);
		model.isEmailDisable =
			this.props.MenuCode == MenuCodes.CallCenter_CardMonitoring ? !gridButtonRightDetail.find(x => x.Code == "CALCMTSEB")?.HasAuthority : false;
		model.isPrintDisable =
			this.props.MenuCode == MenuCodes.CallCenter_CardMonitoring ? !gridButtonRightDetail.find(x => x.Code == "CALCMTPRB")?.HasAuthority : false;
		this.setState({ isPrintDisable: model.isPrintDisable, isEmailDisable: model.isEmailDisable });

	}

	GetQrCode = async () => {
		const { model, ExecuteApiPost } = this.props;
		if (model) {
			var result = await ExecuteApiPost("/prepaidapi/v1.0/Card/GetQrCodeData", { Id: model.CardId });
			if (result)
				this.setState({ QrCode: result });
			else {
				this.setState({ QrCode: null });
				this.ShowMessage("warning", "Empty", "Payment Code Not Found");
			}
		}
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}
	MailSend = () => {
		this.setState({ isLoading: true });
		const { model } = this.props;

		Proxy.POST(
			"/prepaidapi/v1.0/Card/SendEmail",
			{
				Id: model.CardId
			},
			(responseData) => {
				this.setState({ isLoading: false });

				if (responseData.IsSucceeded)
					this.ShowMessage("success", "Succeeded!", "The QR code has been sent to " + responseData.Item + " email address");
				else
					this.ShowMessage("error", "Error", responseData.ErrorDescription);

			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while sending data", error.message);
			}
		);
	}

	Print = () => {
		this.setState({ isLoading: true });
		const { model, ExecuteApiFileDownloadWithGenericResponse } = this.props;

		ExecuteApiFileDownloadWithGenericResponse("/prepaidapi/v1.0/Card/Print",
			{ "Id": model.CardId },
			"CardQRCode" + "-" + Math.floor(Math.random() * Math.floor(999999)),
			"pdf",
			() => {
				this.ShowMessage("success", "Create & Download", "Created and downloaded successfully !!!");
			});

		this.setState({ isLoading: false });
	}

	render() {
		const { alert, isLoading } = this.state;

		const { model } = this.props;

		return (
			<Card className="no-radius" >
				{alert}
				<LoadingComponent Show={isLoading} />

				<CardBody>
					<GridContainer>
						<GridItem xs={12}>
							{
								model && model.QrCode &&
								<GridContainer justify="space-around" alignItems="center">
									<img
										src={"data:image/png;base64," + model.QrCode}
										className={this.props.classes.pictureSrc}
										alt={""}
									/>
								</GridContainer>

							}
							{
								model && model.QrCode &&
								<GridContainer justify="space-around" alignItems="center">
									<div>
										<Button
											disabled={this.state.isPrintDisable}
											Icon={PrintIcon}
											onClick={this.Print}>
											Print
                          </Button>
										<Button
											disabled={this.state.isEmailDisable}
											Icon={EmailIcon}
											onClick={this.MailSend}>
											Send Email
								   </Button>


									</div>
								</GridContainer>
							}


						</GridItem>
					</GridContainer>
				</CardBody>
			</Card >
		);
	}
}

QrCode.propTypes = {
	classes: PropTypes.object,
	list: PropTypes.array,
	showMessage: PropTypes.func,
	Disabled: PropTypes.bool,
	ExecuteApiPost: PropTypes.func
};

export default withArtifex(QrCode, {});