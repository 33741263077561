const genericMapSearchboxStyle = (theme) => ({
	input: {
		boxSizing: "border-box",
		border: "1px solid transparent",
		width: "100%",
		height: "30px",
		padding: "0 12px",
		borderRadius: "3px",
		boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
		fontSize: "14px",
		outline: "none",
		textOverflow: "ellipses",
		marginTop: 7
	}
});


export default genericMapSearchboxStyle;