import PropTypes from "prop-types";
import "assets/scss/generic/genericFlipContainer.css";

import React, { PureComponent } from "react";

export default class GenericFlipContainer extends PureComponent {
	render() {
		const { Front, Back, children } = this.props;
		return (
			<div className='cardflipper-card-container'>
				{children}
				<div className='cardflipper-card-body'>
					<div className="cardflipper-card-side cardflipper-side-back" >
						{Back}
					</div>
					<div className='cardflipper-card-side cardflipper-side-front'>
						{Front}
					</div>
				</div>
			</div>
		);
	}
}

GenericFlipContainer.propTypes = {
	Back: PropTypes.any,
	Front: PropTypes.any
};
