import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import logo from "assets/img/baseline-notifications_none-24px.svg";
import notificationStyle from "assets/jss/material-dashboard-pro-react/components/notificationStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericIcon, GenericLabel } from "views/Components/Generic";

class NotificationBar extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { iconName, text, classes, list } = this.props;
		return (
			<GridContainer>
				<GridItem xs={1}>
					<Card className={classes.cardBodyColor}>
						<CardBody>
							<GridContainer style={{ height: 80, color: "FFF" }} alignItems="center" direction="column">
								<GridItem >
									{/* <Icon className={classes.iconCss}>{iconName}</Icon> */}
									<GenericIcon className={classes.iconCss}>{iconName}</GenericIcon>
									<Typography className={classes.cardBodyColor}>{text}</Typography>
								</GridItem>

							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
				<GridItem xs={11}>
					<Card>
						<CardBody style={{ height: "92px", overflow: "auto" }}>
							<GridContainer style={{ height: "100%", width: "100%" }}>
								<GridItem>
									<br />
									{list.map(d => {
										return (
											<GridItem key={d.Id} xs={12} className={classes.cardListLabel} style={{ margin: "5px" }}>
												<img
													style={{ marginRight: "8px", width: "25px", height: "25px" }}
													src={d.Icon ? d.Icon : logo}
													className={classes.pictureSrc}
													alt={d.Icon ? d.Icon : "..."}
												/>
												<GenericLabel Bold={false} FontSize="12px" TextColor="black" Text={d.Message}></GenericLabel>
											</GridItem>
										);
									})}
									<br />
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}
NotificationBar.propTypes = {
	classes: PropTypes.object.isRequired,
	iconName: PropTypes.string,
	text: PropTypes.string,
	list: PropTypes.array
};
export default withStyles(notificationStyle)(NotificationBar);