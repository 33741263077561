import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDateInput, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { ClientType, GridColumnType } from "views/Constants/Constant";
import ClientHelper from "core/ClientHelper";

class AgentStatement extends React.Component {
	constructor(props) {
		super(props);
		this.initialModel = {
			SalesAgentUniqueClientId: !ClientHelper.IsSalesPerson() ? undefined : ClientHelper.GetClientRowId(),
		};
		this.state = {
			model: this.initialModel,
			data: [],
			isLoading: false,
			isSalesPerson: false
		};
	}
	componentDidMount() {
		//this.props.hideSidebar();
		this.setState({ isSalesPerson: ClientHelper.IsSalesPerson() });
		this.props.setAppLeftTitle("Agent Statement");
		this.props.setAppCenterTitle("SALES PERSON");
	}
	Clear = () => {
		this.setState({ model: this.initialModel, data: [], isLoading: false });
	}
	Search = () => {
		// SearchAgentStatementOp
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/SalesAgentStatement/Search",
			this.state.model,
			responseData => {
				if (!responseData.IsSucceeded) {
					console.log("Error!");
					this.setState({ isLoading: false, data: [] });
					return;
				}
				this.setState({ isLoading: false });
				this.setState({ data: responseData.Item || [] });
			},
			e => {
				console.log("Error!");
				this.setState({ isLoading: false, data: [] });
			}
		);

	}

	GetMemo = (Id) => {
		Proxy.GET(
			"/bankapi/v1.0/CustomerMemo/GetByCustomerId/Id?Id=" + Id,
			responseData => {
				if (!responseData.IsSucceeded) {
					console.log("Error!");
					return;
				}
				let data = responseData.Item != null ? responseData.Item.sort((a, b) => (b.Id - a.Id)) : null;
				var { model } = this.state;
				model.Memo = data != null ? (data[0].Memo != null ? data[0].Memo : "") : "";
				this.setState({ model });
			},
			e => {
				console.log("Error!");
			}
		);
	}

	handleChange = (name, newValue, data) => {
		const temp = { ...this.state.model };
		temp[name] = newValue;
		this.setState({ model: temp });
		if (name == "UniqueClientId") {
			temp.Memo = "";
			this.GetMemo(temp.UniqueClientId);
		}
	}
	render() {
		const { model, data, isSalesPerson } = this.state;
		const { Disabled } = this.props;

		return (
			<GridContainer spacing={16}>
				{this.state.alert}
				<LoadingComponent Show={this.state.isLoading} />
				<GridItem xs={12}>
					<ButtonToolbar ButtonList={[
						{ Code: "Clear", OnClick: this.Clear, Disabled: Disabled },
						{ Code: "Search", OnClick: this.Search, Disabled: Disabled },
					]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				</GridItem>

				<Card className="no-radius">
					<GenericExpansionPanel Title={"Main Parameters"}>

						<CardBody>
							<GridContainer>
								<GridItem xs={4}>
									<GenericSelectInput Disabled={isSalesPerson} Name="SalesAgentUniqueClientId" Url="/bankapi/v1.0/BankCustomer/Search" All Method="POST" Parameter={{ ClientTypes: [ClientType.SalesAgentPersonal, ClientType.SalesAgentCorporate] }} LabelText="Sales Agent Name" Value={model.SalesAgentUniqueClientId || ""} DataRoot="Item" ValueChanged={this.handleChange} KeyValueMember="Id" TextValueMember="Name" />
									<GenericDateInput MaxDate={model.ToDate || DateHelper.GetDate()} Name="FromDate" LabelText="From Date" Value={model.FromDate == null ? "" : DateHelper.ToDateInputValue(model.FromDate)} ValueChanged={this.handleChange} />
								</GridItem>
								<GridItem xs={4}>
									<GenericSelectInput Disabled={isSalesPerson} All Name="SalesAgentUniqueClientId" LabelText="Sales Agent ID" Value={model.SalesAgentUniqueClientId || ""} Parameter={{ ClientTypes: [ClientType.SalesAgentPersonal, ClientType.SalesAgentCorporate] }} DataRoot="Item" ValueChanged={this.handleChange} KeyValueMember="Id" TextValueMember="UniqueClientId" Url="/bankapi/v1.0/BankCustomer/Search" Method="POST" />
									<GenericDateInput MaxDate={DateHelper.GetDate()} MinDate={model.FromDate} Name="ToDate" LabelText="To Date" Value={model.ToDate == null ? "" : DateHelper.ToDateInputValue(model.ToDate)} ValueChanged={this.handleChange} />
								</GridItem>
								<GridItem xs={4}>
									<GenericTextInput MultiLine={true} RowCount={2} Disabled={true} Name="Memo" LabelText="Memo" Value={model.Memo || ""} ValueChanged={this.handleChange} />
								</GridItem>
							</GridContainer>
						</CardBody>
					</GenericExpansionPanel>
				</Card>
				<Card className="no-radius">
					<CardBody>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={data}
									PageSize={10}
									Columns={[
										{
											Header: "Sales Agent Name",
											accessor: "SalesAgentName",
											show: !isSalesPerson
										},
										{
											Header: "Sales Agent Unique Client ID",
											accessor: "SalesAgentUniqueClientId",
											show: !isSalesPerson
										},
										{
											Header: "Transaction Date",
											accessor: "TransactionDate",
											type: GridColumnType.Date
										},
										{
											Header: "Client Name",
											accessor: "ClientName"
										},
										{
											Header: "Customer Unique Client ID",
											accessor: "ClientUniqueClientId"
										},
										{
											Header: "Total",
											Cell: row => (
												<div>{row.original.TotalAmount == null || row.original.TotalAmount == 0 ? "" : "$ " + row.original.TotalAmount}</div>
											)
										},
										{
											Header: "Eft",
											Cell: row => (
												<div>{row.original.EftAmount == null || row.original.EftAmount == 0 ? "" : "$ " + row.original.EftAmount}</div>
											)
										},
										{
											Header: "Ddt",
											Cell: row => (
												<div>{row.original.DdtAmount == null || row.original.DdtAmount == 0 ? "" : "$ " + row.original.DdtAmount}</div>
											)
										},
										{
											Header: "E-Transfer",
											Cell: row => (
												<div>{row.original.EtransferAmount == null || row.original.EtransferAmount == 0 ? "" : "$ " + row.original.EtransferAmount}</div>
											)
										},
										{
											Header: "Swift",
											Cell: row => (
												<div>{row.original.SwiftAmount == null || row.original.SwiftAmount == 0 ? "" : "$ " + row.original.SwiftAmount}</div>
											)
										},
										{
											Header: "Bill Payment",
											Cell: row => (
												<div>{row.original.BillAmount == null || row.original.BillAmount == 0 ? "" : "$ " + row.original.BillAmount}</div>
											)
										},
										{
											Header: "Wallet",
											Cell: row => (
												<div>{row.original.PrepaidAmount == null || row.original.PrepaidAmount == 0 ? "" : "$ " + row.original.PrepaidAmount}</div>
											)
										},
										{
											Header: "Lender",
											Cell: row => (
												<div>{row.original.LenderAmount == null || row.original.LenderAmount == 0 ? "" : "$ " + row.original.LenderAmount}</div>
											)
										},
										{
											Header: "Payment Wallet",
											Cell: row => (
												<div>{row.original.BankAccountAmount == null || row.original.BankAccountAmount == 0 ? "" : "$ " + row.original.BankAccountAmount}</div>
											)
										}
									]}
								/>
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>

			</GridContainer>
		);
	}
}

AgentStatement.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func
};

export default withArtifex(AgentStatement, {});