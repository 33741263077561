import { Dialog, DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { primaryColor } from "assets/jss/generic/default";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import PropTypes, { any } from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import { GenericTitle, GenericAlert, GenericEmailInput, GenericGrid, GenericLabel, GenericRadioInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType } from "views/Constants/Constant";

class ApiUser extends Component {
	constructor(props) {
		super(props);
		this.state = {
			model: {},
			isLoading: true,
			customerList: [],
			userLists: [],
			filteredUserList: [],
			apiList: [],
			openNewInsertSendCredentials: false,
			openSendCredentials: false,
			openChangePassword: false,
			rowIndex: null,
			isPasswordDisabled: false,
			errorState: false,
			errorState2: false,
			maskStatus: false,
			syncCounter: 0
		};

		this.props.setAppLeftTitle("API User");
		this.props.setAppCenterTitle("USER ADMIN");
	}

	componentDidMount() {
		Promise.resolve()
			.then(() => this.GetApiList())
			.then(() => this.GetCustomerList())
			.then(() => this.GetUserList());
	}


	SelectedRowChange = (index) => {
		const api = { ...this.state.apiList[index] };

		api.UserListValue = api.ParentUserId;
		api.BankCustomer = api.UniqueClientId;

		this.setState({ model: api, rowIndex: index, isPasswordDisabled: false });
	}

	GetCustomerList = (uid = 0) => {
		Proxy.POST(
			"/bankapi/v1.0/BankCustomer/Search",
			{ UniqueClientId: uid, },
			responseData => {
				if (responseData.Item != null && responseData.Item.length > 0) {
					this.setState({
						isLoading: false,
						customerList: responseData.Item
					});
				}
				else {
					this.setState({
						isLoading: false,
						customerList: []
					});
				}
			},
			this.errorCallback
		);
	}


	FilterUsers = (uniqId) => {

		let list = this.state.userLists;
		if (!list) return [];
		let newList = list.filter((item) => {
			let BankCustomer = uniqId;
			return (BankCustomer != undefined && BankCustomer != null && ((BankCustomer >= 0 && item.UniqueClientId == BankCustomer) || BankCustomer == -1));

		});
		this.setState({ filteredUserList: newList });
		return newList;
	}

	GetApiList = (uniqId = 0, uId = 0) => {
		return Proxy.POST(
			"/coreapi/v1.0/ApiUser/GetApiUser",
			uId == 0 ? { UniqueClientId: uniqId } : { ParentUserId: uId },
			responseData => {
				if (responseData.Item != null && responseData.Item.length > 0) {
					this.setState({
						isLoading: false,
						apiList: responseData.Item
					});
				}
				else {
					this.setState({
						isLoading: false,
						apiList: []
					});
				}
			},
			this.errorCallback
		);
	}

	GetCountry = (id) => {
		return Proxy.POST(
			"/coreapi/v1.0/Country/GetAll",
			{},
			responseData => {
				const model = { ...this.state.model };
				if (responseData.Item != null && responseData.Item.length > 0) {
					model.CountryCode = responseData.Item.find((a) => {
						return a.Id == id;
					}).Code;
					this.setState({ model });
				}
			},
			this.errorCallback
		);
	}

	GetUserList = (uid = 0) => {
		return Proxy.POST(
			"/coreapi/v1.0/User/GetAllByUniqueClientId",
			{ UniqueClientId: uid },
			(responseData) => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					console.log("Error!");
					return;
				}
				if (this.state.syncCounter == 0) {
					this.setState({ syncCounter: this.state.syncCounter + 1 });
					this.setState({ filteredUserList: responseData.Item });
				}

				if (responseData.Item != null) {
					this.setState({ userLists: responseData.Item });
				} else {
					this.setState({ userLists: [] });
				}

			},
			(error) => {
				this.setState({ isLoading: false });
				console.log("Error!");
				return;
			}
		);
	}

	handleChange = (name, newValue, pureValue) => {
		const model = { ...this.state.model };
		model[name] = newValue;



		if (name == "PhoneNumber") {
			model["PureNumber"] = pureValue;
		}
		if (name == "NotificationType") {
			model.NotificationAddress = "";
		}
		if (name == "BankCustomer") {

			let uid = newValue;
			if (newValue == -1) {
				uid = 0;
			}
			model.UserListValue = null;
			this.setState({ rowIndex: null });
			Promise.resolve()
				.then(() => this.GetUserList(uid))
				.then(() => this.GetApiList(uid))
				.then(() => this.FilterUsers(newValue));

		}


		if (name == "UserName") {
			this.state.rowIndex = -1;

		}
		if (name == "UserList") {
			model["UserListValue"] = newValue;

			let temp = newValue;
			if (temp == -1) {
				temp = 0;
			}
			model.UserId = temp;
			this.GetApiList(0, newValue);
		}

		if (["Password2"].some((x) => { return x == name; })) {

			if (model.Password != model.Password2) {
				model.errorState = true;
			}
			else {
				model.errorState = false;
			}

		}

		if (["NewPassword2"].some((x) => { return x == name; })) {

			if (model.NewPassword != model.NewPassword2) {
				model.errorState2 = true;
			}
			else {
				model.errorState2 = false;
			}

		}

		this.setState({ model });
		if (name == "PhoneCountryCodeId") {
			this.GetCountry(newValue);
		}
	}

	handleSendOk = () => {
		const model = { ...this.state.model };
		const maskStatus = this.state.maskStatus;
		const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		var messages = [];
		if (model.NotificationType == undefined) {
			model.NotificationType = 1;
			this.setState({ model });
		}

		if (model.NotificationType == 2) {
			if (!maskStatus && (!model.PhoneNumber || model.PhoneNumber.length > 0)) messages.push("Please fill the Phone Number !!!");
			if (!model.PhoneCountryCodeId) messages.push("Please fill the Phone Country !!!");
		}
		if (model.NotificationType == 1 && !model.NotificationAddress) {
			messages.push("Notification Address is empty !!!");
		}


		if ((model.NotificationType == 1) && !emailRex.test(model.NotificationAddress)) messages.push("Please enter a valid Email address!!!");
		if (messages.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
		}
		else {
			return this.showQuestionAlert("Are you sure you want to send this credentials via " + (model.NotificationType == 1 ? "Email" : "Sms") + " ?", "Send");
		}
	}


	handleClickSendOk = () => {
		this.hideAlert();
		var model = { ...this.state.model };
		var newModel = {};

		newModel.ApiUsername = model.UserName;
		newModel.NotificationAddress = model.NotificationType == 1 ? model.NotificationAddress : "+" + model.CountryCode + model.PureNumber;
		newModel.NotificationType = model.NotificationType;


		Proxy.POST(
			"/coreapi/v1.0/ApiUser/SendApiUserCredentials",
			newModel,
			this.successSendCallback,
			this.errorCallback
		);
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
	}

	handleSubmit = () => {
		const model = { ...this.state.model };
		var messages = [];

		if (!model.UserName) messages.push("Please Enter a Username !!!");

		if (!model.UserListValue) {
			messages.push("Please select a valid User!!!");
			if (!model.BankCustomer) messages.push("Please select a valid Client!!!");
		}



		if (messages.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
		}
		else {
			this.setState({ openNewInsertSendCredentials: true });
		}

	}

	handleEdit = () => {
		var messages = [];
		const model = { ...this.state.model };

		if (!model.OldPassword) messages.push("Please enter old password to required fields!!!");

		if (!model.NewPassword2 && !model.NewPassword) messages.push("Please enter valid password to required fields!!!");
		if (model.NewPassword2 != model.NewPassword) messages.push("Please enter same password to required fields!!!");

		if (messages.length > 0)
			this.ShowMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
		else
			this.showQuestionAlert("Are you sure you want to change API User password for selected user?", "Edit");
	}

	handleDelete = () => {
		const rowIndex = this.state.rowIndex;
		if (!rowIndex || rowIndex == "-1") {
			this.setState({
				alert: <GenericAlert Title={"Warning"} MessageNode={"Please select an API user from grid."} Type={"warning"} OnConfirm={() => this.hideAlert()} />
			});
		}
		else {
			this.showQuestionAlert("Are you sure you want to delete '" + this.state.apiList[rowIndex].UserName + "' API user ?", "Delete");
		}
	}
	handleSend = () => {

		const rowIndex = this.state.rowIndex;
		if (!rowIndex || rowIndex == "-1") {
			this.setState({
				alert: <GenericAlert Title={"Warning"} MessageNode={"Please select an API user from grid."} Type={"warning"} OnConfirm={() => this.hideAlert()} />
			});
		}
		else {
			this.setState({ openSendCredentials: true });
		}

	}

	handleClear = () => {
		this.showQuestionAlert("This will erase the information you entered on the screen. Are you sure you want to do this?", "Clear");
	}

	handleSubmitOk = () => {
		const model = { ...this.state.model };
		const maskStatus = this.state.maskStatus;
		const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		var messages = [];
		if (model.NotificationType == undefined) {
			model.NotificationType = 1;
			this.setState({ model });
		}
		if (model.NotificationType == 2) {
			if (!maskStatus && (!model.PhoneNumber || model.PhoneNumber.length > 0)) messages.push("Please fill the Phone Number !!!");
			if (!model.PhoneCountryCodeId) messages.push("Please fill the Phone Country !!!");
		}
		if (model.NotificationType == 1 && !model.NotificationAddress) {
			messages.push("Notification Address is empty !!!");
		}

		if ((model.NotificationType == 1) && !emailRex.test(model.NotificationAddress)) messages.push("Please enter a valid Email address!!!");
		if (messages.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
		}
		else
			return this.showQuestionAlert("API User Credentals will be send over your choice after record created.\nAre you sure you want to create new API User?", "Save");




	}
	handleUpdate = () => {

		const rowIndex = this.state.rowIndex;

		if (!rowIndex || rowIndex == "-1") {
			this.setState({
				alert: <GenericAlert Title={"Warning"} MessageNode={"Please select an API User from grid."} Type={"warning"} OnConfirm={() => this.hideAlert()} />
			});
		}
		else {
			this.setState({ openChangePassword: true });
		}


	}

	handleClickSaveOk = () => {
		this.hideAlert();
		var model = { ...this.state.model };
		var newModel = {};
		newModel.ApiUserName = model.UserName;
		newModel.ClientId = model.BankCustomer;
		newModel.UserId = model.UserListValue;
		newModel.NotificationType = model.NotificationType;
		newModel.NotificationAddress = model.NotificationType == 1 ? model.NotificationAddress : "+" + model.CountryCode + model.PureNumber;
		Proxy.POST(
			"/coreapi/v1.0/ApiUser/CreateApiUser",
			newModel,
			this.successSaveCallback,
			this.errorCallback
		);
	}

	handleClickEditOk = () => {
		this.hideAlert();
		var model = { ...this.state.model };
		let params = {};
		params.UserName = model.UserName;
		params.OldPassword = model.OldPassword;
		params.NewPassword = model.NewPassword;
		Proxy.POST(
			"/coreapi/v1.0/ApiUser/ChangeApiPassword",
			params,
			this.successEditCallback,
			this.errorCallback
		);
	}

	handleClickDeleteOk = () => {
		this.hideAlert();
		let params = {};
		params.Id = this.state.model.Id;
		Proxy.POST(
			"/coreapi/v1.0/ApiUser/DeleteApiUser",
			params,
			this.successDeleteCallback,
			this.errorCallback
		);
	}


	handleClickClearOk = () => {
		this.hideAlert();
		this.setState({ model: any, rowIndex: null });
	}

	successSaveCallback = (responseData) => {
		if (!responseData.IsSucceeded) {
			this.showErrorMessage(responseData.ErrorDescription);
			return;
		}
		this.GetApiList(this.state.model.BankCustomer);
		this.showSuccessMessage("API Saved succesfully !!!");
	}


	successEditCallback = (responseData) => {
		if (!responseData.IsSucceeded) {
			this.showErrorMessage(responseData.ErrorDescription);
			return;
		}

		this.showSuccessMessage("Password changed succesfully !!!");
	}

	successDeleteCallback = () => {

		this.handleClickClearOk();
		this.GetApiList();
		this.showSuccessMessage("API User deleted successfully !!!");
	}
	successSendCallback = (responseData) => {
		if (!responseData.IsSucceeded) {
			this.showErrorMessage(responseData.ErrorDescription);
			return;
		}
		this.showSuccessMessage("Send Successfully !!!");
	}
	errorCallback = (error) => {
		this.showErrorMessage("An error occurred during the api visit:" + error);
	}



	showQuestionAlert = (message, title) => {
		if (title == "Save") {
			this.setState({
				isLoading: false,
				alert: <GenericAlert Title={title} Message={message} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.handleClickSaveOk()} />
			});
		}
		if (title == "Edit") {
			this.setState({
				alert: <GenericAlert Title={title} Message={message} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.handleClickEditOk()} />
			});
		}
		if (title == "Delete") {
			this.setState({
				alert: <GenericAlert Title={title} Message={message} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.handleClickDeleteOk()} />
			});
		}

		if (title == "Clear") {
			this.setState({
				alert: <GenericAlert Title={title} Message={message} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.handleClickClearOk()} />
			});
		}
		if (title == "Send") {
			this.setState({
				alert: <GenericAlert Title={title} Message={message} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.handleClickSendOk()} />
			});
		}
	}

	showSuccessMessage = (message) => {
		this.setState({
			isLoading: false,
			alert: <GenericAlert Title={"Success"} MessageNode={message} Type={"success"} OnConfirm={() => this.hideAlert()} />
		});
	}

	showErrorMessage = (message) => {
		this.setState({
			isLoading: false,
			alert: <GenericAlert Title={"Error"} MessageNode={message} Type={"warning"} OnConfirm={() => this.hideAlert()} />
		});
	}

	hideAlert = () => {
		this.setState({ alert: null });
	}
	RefreshToolBar = () => {
		this.setState({ LimitToken: !this.state.LimitToken });
	}
	handleClose = () => {
		const model = { ...this.state.model };
		model.NotificationAddress = "";
		this.setState({ model, openChangePassword: false, openNewInsertSendCredentials: false, openSendCredentials: false });
	}




	render() {
		const { classes, Disabled } = this.props;
		const { model, isLoading, alert } = this.state;

		var data = this.state.apiList ?? [];

		return (
			<div>

				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Submit", OnClick: this.handleSubmit, Disabled: Disabled },
					{ Code: "Send", OnClick: this.handleSend, Disabled: Disabled },
					{ Code: "Delete", OnClick: this.handleDelete, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.handleClear, Disabled: Disabled }
				]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />


				<Dialog open={this.state.openNewInsertSendCredentials || this.state.openSendCredentials} onBackdropClick={this.handleClose} fullWidth={true} >
					<DialogTitle className={classes.bgPrimary} style={{ background: primaryColor }}>
						<GridContainer style={{ marginBottom: "-18px" }} >
							<GridItem xs={11}>
								{this.state.openSendCredentials ?
									<GenericLabel Text="SEND API USER CREDENTIALS" TextColor="white" FontSize="20px" Bold={true} />
									:
									<GenericLabel Text="ADD API USER" TextColor="white" FontSize="20px" Bold={true} />
								}
							</GridItem>
							<GridItem xs={1}>
								<IconButton style={{ color: "#ffffff" }} onClick={this.handleClose} aria-label="Close">
									<CloseIcon />
								</IconButton>
							</GridItem>
						</GridContainer>
					</DialogTitle>
					<DialogContent>
						<GridContainer justify="space-between">
							{!this.state.openSendCredentials ?
								<GridItem xs={12} ><h5>Please select and enter notification address and we will be send Api Credentials to your address</h5></GridItem>
								:
								<GridItem xs={12} ><h5>API Credentials information will be send after API Password reset!</h5></GridItem>
							}
							<GridItem xs={12} >
								{model.NotificationType == undefined || model.NotificationType == true
									?
									<GenericEmailInput
										inputProps={{ maxLength: 100 }}
										Name="NotificationAddress"
										LabelText="Notification Address"
										Disabled={model.isAccount}
										Value={this.state.model.NotificationAddress}
										ValueChanged={this.handleChange} />
									:
									<GridContainer>
										<GridItem xs={7}>
											<GenericSelectInput
												Name="PhoneCountryCodeId"
												LabelText="Phone Number"
												TitleLabel="Country Code"
												LabelMd={4}
												LabelStyle={{ width: "72%", marginLeft: "-2px", marginBottom: "2px" }}
												SelectStyle={{ width: "28%" }}
												Method="POST"
												Url="/coreapi/v1.0/Country/GetAll"
												Parameter={{}}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember={"Code"}
												Value={model.PhoneCountryCodeId == null || undefined ? "" : model.PhoneCountryCodeId}
												ValueChanged={this.handleChange}
												Sorted={{ Member: "OrderIndex" }}
												RenderItem={d => "+" + d.Code + " (" + d.Name + ")"}
											/>
										</GridItem>
										<GridItem xs={5} style={{ paddingRight: 2 + "px !important" }}>
											<GenericTextInput
												Format="(###) ### ####"
												Name="PhoneNumber"
												LabelMd={0}
												Value={model.PhoneNumber == null || undefined ? "" : model.PhoneNumber}
												ValueChanged={this.handleChange}

												IsCompleted={(bool) => {
													this.setState({ maskStatus: bool });
												}} />
										</GridItem>
									</GridContainer>
								}
								<br /><br />
								<GridItem>
									<GridContainer>
										<GridItem xs={12} >
											<GenericRadioInput
												Name="NotificationType"
												LabelText="Select Notification Type"
												IsStatic={true}
												StaticData={[
													{ Value: 1, Text: "Email" },
													{ Value: 2, Text: "Sms" }
												]}
												KeyValueMember="Value"
												TextValueMember="Text"
												Value={model.NotificationType || 1}
												ValueChanged={this.handleChange} />
											<Button justify="flex-end" size="sm" onClick={this.state.openSendCredentials ? this.handleSendOk : this.handleSubmitOk} >{this.state.openSendCredentials ? "SEND" : "SAVE"}</Button>
										</GridItem>
									</GridContainer>
								</GridItem>
							</GridItem>
						</GridContainer>
					</DialogContent>
				</Dialog>


				<Dialog open={this.state.openChangePassword} onBackdropClick={this.handleClose} fullWidth={true} >
					<DialogTitle className={classes.bgPrimary} style={{ background: primaryColor }}>
						<GridContainer style={{ marginBottom: "-18px" }} justify="space-between">
							<GridItem xs={11}>
								<GenericLabel Text="API USER CHANGE PASSWORD" TextColor="white" FontSize="18px" Bold={true} />
							</GridItem>
							<GridItem xs={1}>
								<IconButton style={{ color: "#ffffff" }} onClick={this.handleClose} aria-label="Close">
									<CloseIcon />
								</IconButton>
							</GridItem>
						</GridContainer>
					</DialogTitle>
					<DialogContent>
						<GridContainer>
							<GridItem xs={12}>
								<GenericLabel Text="Please set new password from below and click submit!" TextColor="black" FontSize="14px" />
								<br />
								<GenericTextInput
									inputProps={{ maxLength: 100 }}
									Name="OldPassword"
									LabelText="Old Password"
									Value={this.state.model.OldPassword}
									ValueChanged={this.handleChange}
									IsPassword={true} />
								<GenericTextInput
									inputProps={{ maxLength: 100 }}
									Name="NewPassword"
									LabelText="New Password"
									Value={this.state.model.NewPassword}
									ValueChanged={this.handleChange}
									IsError={this.state.model.errorState2}
									IsPassword={true} />
								<GenericTextInput
									inputProps={{ maxLength: 100 }}
									Name="NewPassword2"
									LabelText="Again New Password"
									Value={this.state.model.NewPassword2}
									ValueChanged={this.handleChange}
									IsError={this.state.model.errorState2}
									IsPassword={true} />
								<GridContainer>
									<GridItem xs={6} />
									<GridItem xs={6}>
										<GridContainer justify="flex-end">
											<GridItem>
												<Button size="sm" onClick={this.handleEdit} >Submit</Button>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</GridItem>
						</GridContainer>
					</DialogContent>
				</Dialog>

				<GridContainer>
					<GridItem xs={12}>
						<Card className={classes.zerMarPad}>
							<CardBody>
								<GridContainer style={{}}>
									<GridItem xs={4}>
										<h5><b>{this.state.columnTitle1}</b></h5>
										<GridContainer>
											<GridItem xs={12}>
												<GenericSelectInput
													Name="BankCustomer"
													IsStatic={true}
													StaticData={this.state.customerList}
													LabelText="Client Name"
													Value={model.BankCustomer}
													ValueChanged={this.handleChange}
													KeyValueMember="Id"
													TextValueMember={"Code"}
													RenderItem={d => d.Name} />
												<GenericSelectInput
													IsStatic={true}
													StaticData={this.state.filteredUserList}
													Name="UserList"
													Value={model.UserListValue}
													LabelText="Users"
													ValueChanged={this.handleChange}
													KeyValueMember="Id"
													TextValueMember={"Code"}
													RenderItem={d => d.FirstName + " " + d.LastName + " (" + d.UserName + ")"}
													Sorted={{ Member: "UserName" }} />

											</GridItem>
										</GridContainer>
										<GenericTextInput
											Name="UserName"
											LabelText="API Username"
											ValueChanged={this.handleChange}
											Value={this.state.model.UserName} />
									</GridItem>

								</GridContainer>
							</CardBody>
						</Card>
						<br />
					</GridItem>
				</GridContainer >
				<Card className="no-radius">
					<CardBody>
						<CardHeader>
							<GenericTitle text={"API List"} />
						</CardHeader>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={data}
									Columns={[
										{
											Header: "First Name",
											accessor: "User.FirstName",
											Cell: row => (
												<div>{row.value == null ? "" : row.value}</div>
											)
										},
										{
											Header: "Last Name",
											accessor: "User.LastName",
											Cell: row => (
												<div>{row.value == null ? "" : row.value}</div>
											)
										},
										{
											Header: "Username",
											accessor: "UserName"
										},
										{
											Header: "Begin Date",
											accessor: "BeginDate",

											type: GridColumnType.DateTime
										},
										{
											Header: "End Date",
											accessor: "EndDate",
											type: GridColumnType.DateTime
										},
										{
											Header: "Password Retry Count",
											accessor: "PasswordRetryCount"
										}
									]}
									RowSelected={index => {
										this.SelectedRowChange(index);
									}}
									SelectedIndex={this.state.rowIndex}
								/>
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>
			</div >
		);
	}
}

ApiUser.propTypes = {
	tabList: PropTypes.array,
	classes: PropTypes.object.isRequired,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	CustomerSaved: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(ApiUser, {});	