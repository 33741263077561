import { withArtifex } from "core";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React from "react";
import LoadingComponent from "views/Components/LoadingComponent";
import QrImage from "assets/img/qr.png";

class CampaignCouponTemplate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			CouponSampleCode: "XT69FK"
		};
	}

	componentDidMount() {
		var sample = this.GenerateRandomHash();
		this.setState({ CouponSampleCode: sample });
	}

	GenerateRandomHash = () => {
		const chars = [..."ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"];
		var rnd = [...Array(6)].map(i => chars[Math.random() * chars.length | 0]).join("");
		return rnd;
	}


	render() {
		const { classes, model } = this.props;
		const { isLoading } = this.state;
		return (
			<div>
				<LoadingComponent Show={isLoading} />
				<hr />
				<div style={{ backgroundColor: model.BackgroundColor, width: "700px", height: "300px", }}>
					<span style={{ width: "200px", height: "300px", float: "left" }}>
						<div style={{ width: "180px", height: "200px", lineHeight: "200px", textAlign: "center" }}>
							{model.Image != null && (
								<img style={{ maxWidth: "180px", height: "auto", margin: "15px", maxHeight: "180px" }} src={model.Image} ></img>
							)}
						</div>
						<div style={{ width: "200px", maxHeight: "100px", lineHeight: "100px", textAlign: "center" }}>
							{model.Logo1 != null && (
								<img style={{ maxWidth: "90px", maxHeight: "90px", margin: "5px" }} src={model.Logo1}></img>
							)}
							{model.Logo2 != null && (
								<img style={{ maxWidth: "90px", maxHeight: "90px", margin: "5px" }} src={model.Logo2}></img>
							)}
						</div>
					</span>
					<span style={{ width: "500px", height: "300px", float: "left", }}>
						<table style={{ width: "100%", height: "100%", }}>
							<tbody>
								<tr>
									<th style={{ width: "60%", float: "left", marginTop: "70px", fontSize: "18px" }}>{model.Reward}</th>
									<th style={{ width: "40%", float: "right" }}><img style={{ width: "100px", height: "100px", float: "right", marginTop: "15px", marginRight: "15px" }} src={model.QrCode != null ? model.QrCode : QrImage}></img></th>
								</tr>
								<tr>
									<th colSpan="2" style={{ padding: "4px", textAlign: "initial" }}>
										{model.Message}
									</th>
								</tr>
								<tr>
									<td colSpan="2" style={{ padding: "4px", textAlign: "initial", float: "right" }}>

										<div style={{ width: "100%", display: "table", fontSize: "13px", fontWeight: "400" }}>
											<div style={{ display: "table-row" }}>
												<div style={{ display: "table-cell", textAlign: "end", paddingRight: "4px" }}> Promotion Code </div>
												<div style={{ display: "table-cell", backgroundColor: "white", padding: "3px 5px 3px 5px" }}>
													{model.CouponCode != null ? model.CouponCode :  this.state.CouponSampleCode}
												</div>
											</div>
											<div style={{ display: "table-row" }}>
												<div style={{ display: "table-cell", textAlign: "end", paddingRight: "4px" }}> Expiry Date </div>
												<div style={{ display: "table-cell", backgroundColor: "white", padding: "3px 5px 3px 5px" }}>
													{model.ExpireDateTime == null ? "" : Formatter.FormatDate(model.ExpireDateTime)}
												</div>
											</div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</span>
				</div>

			</div >
		);
	}
}

CampaignCouponTemplate.propTypes = {
	classes: PropTypes.object
};

export default withArtifex(CampaignCouponTemplate, {});