import React from "react";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Heading from "components/Heading/Heading.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { GenericTextInput, GenericSelectInput,GenericDateInput } from "views/Components/Generic";

import { Proxy } from "core";
import moment from "moment";

class ulli extends React.Component {
	render() {
	  return (
		 <ul>
			<li>Apple</li>
			<li>Orange</li>
			<li>Blueberry</li>
			<li>Cherry</li>
		 </ul>
	  )
	}
 } 


class CanadaPostTest extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			model: {}
		};

		this.handleChange = this.handleChange.bind(this);
		this.click = this.click.bind(this);
		//this.ListItem = this.ListItem.bind(this);
	}

	componentDidMount() {


	}

	click()
	{
		const model = { ...this.state.model };
		var dt = moment.utc().toDate();
		model.UtcDate = dt;

		Proxy.POST(
			"/coreapi/v1.0/User/UtcDate",
			{ UtcDateValue: model.UtcDate },
			responseData =>{
				model["UtcResponseDate"] = responseData.UtcResponseDate;
				this.handleChange("UtcResponseDateList", responseData.UtcResponseDateList);
			}
		);


	}

	handleChange(name, newValue) {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model: model }, 			() => console.log("state ", this.state));
	}


	render() {
		const { model } = this.state;

		return (
			<div>
				<GridContainer>
					Canada Post Test
					<GridItem xs={12}>
						<Card plain>
							<CardBody plain>


								<GenericDateInput MaxDate={model.UtcDate || moment.utc().toDate()} Name="UtcDate" LabelText="Date" 
								Value={model.UtcDate == null ? "" : moment(model.UtcDate)} ValueChanged={this.handleChange} />

								<div onClick={this.click} style={{ color: "black", cursor: "pointer" }}>
								Post me
								</div>

								<div>
									Utc response is {
											moment.utc(model.UtcResponseDate).local().format('YYYY-MM-DD HH:mm:ss')
											}
										
								{
									model.UtcResponseDateList? model.UtcResponseDateList.map(d => (
										<div>{moment.utc(d).local().format('YYYY-MM-DD HH:mm:ss')}</div>
									)) : ''
								}

								</div>

							
				
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

export default CanadaPostTest;
