import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import DateHelper from "core/DateHelper";
import React, { Component } from 'react';
import { Proxy, withArtifex } from "core";
import ButtonToolbar from 'views/Components/ButtonToolbar';
import { GenericDateInput, GenericExpansionPanel, GenericRadioInput, GenericTextInput, GenericGrid, GenericDialog, GenericSelectInput, GenericAlert, GenericDialogActions, GenericLabel, GenericTitle, GenericNumberInput } from "views/Components/Generic";
import LoadingComponent from 'views/Components/LoadingComponent';
import ParameterComponent from "views/Components/ParameterComponent";
import CardHeader from "components/Card/CardHeader";
import { GridColumnType } from "views/Constants/Constant";
import ClientHelper from "core/ClientHelper";
import Button from "components/CustomButtons/Button.jsx";
import { DialogContent, DialogTitle } from "@material-ui/core";
import Select from "react-select";

class VisaDirectTransactionSearch extends Component {
	constructor(props) {
		super(props)
		this.isBackOffice = ClientHelper.IsBackOffice();
		this.sortParamDesc = { Member: "ParameterDesc" };
		this.state = {
			model: {
				FromDate: DateHelper.GetDate(),
				ToDate: DateHelper.GetDate()
			},
			getDataList: [],
			isLoading: false,
		};
		this.Columns = [
			{
				Header: "Transaction Date",
				accessor: "TransactionDate",
				type: GridColumnType.DateUtc
			},
			{
				Header: "Transaction Status",
				accessor: "TransactionState",
			},
			{
				Header: "Settlement Date",
				accessor: "SettlementDate",
				type: GridColumnType.DateUtc
			},
			// {
			// 	Header: "End Date",
			// 	accessor: "EodDate",
			// 	type: GridColumnType.DateUtc
			// },
			{
				Header: "Name",
				accessor: "ClientCustomerName"
			},
			{
				Header: "Middle Name",
				accessor: "MiddleName"
			},
			{
				Header: "Last Name",
				accessor: "SurName"
			},
			{
				Header: "Transaction Type",
				accessor: "TransactionType"
			},
			{
				Header: "Currency",
				accessor: "Currency"
			},
			{
				Header: "Amount",
				accessor: "TransactionAmount",
				type: GridColumnType.Money
			},
			{
				Header: "Buying Rate Of Exchange Amount",
				accessor: "BuyingRateOfExchangeAmount",
				type: GridColumnType.Money
			},
			{
				Header: "Conversion Amount",
				accessor: "ConversionAmount",
				type: GridColumnType.Money
			},
			{
				Header: "Card Last 4",
				accessor: "LastFour",
			},
			{
				Header: "Client Customer Number",
				accessor: "ClientCustomerNumber"
			},
			{
				Header: "Description",
				accessor: "Description"
			},
			{
				Header: "TransactionReferenceNumber",
				accessor: "TransactionReferenceNumber"
			},
			// {
			// 	Header: "Transaction Balance",
			// 	accessor: "TransactionBalance",
			// 	type: GridColumnType.Money
			// },
			{
				Header: "Reference Number",
				accessor: "ReferenceNumber"
			},
			{
				Header: "Application",
				accessor: "Application"
			},

			{
				Header: "Destination",
				accessor: "Destination"
			},
			{
				Header: "Ready To Settle At",
				accessor: "ReadyToSettleAt"
			},
			{
				Header: "Created At",
				accessor: "CreatedAt",
				type: GridColumnType.DateUtc
			},
			{
				Header: "Updated At",
				accessor: "UpdatedAt",
				type: GridColumnType.DateUtc
			},
			{
				Header: "Finix Transaction ID",
				accessor: "TransactionResponseId"
			},
			{
				Header: "Merchant Identity",
				accessor: "MerchantIdentity"
			},
			{
				Header: "Unique Client Id",
				accessor: "ClientUniqueClientId",
				show: this.isBackOffice
			},
			{
				Header: "Unique Client Name",
				accessor: "UniqueClientName",
				show: this.isBackOffice
			},
			{
				Header: "Email",
				accessor: "Email"
			},

			{
				Header: "Transaction Source",
				accessor: "TransactionSource"
			},

			{
				Header: "Wallet Number",
				accessor: "AccountNumber",
			},
			{
				Header: "Wallet Name",
				accessor: "AccountName",
			},
			{
				Header: "AccountOwnerName",
				accessor: "AccountOwnerName",
			},
			{
				Header: "Direction",
				accessor: "Direction",
			},
			// {
			// 	Header: "Transaction Code",
			// 	accessor: "TransactionCode",
			// },

			// {
			// 	Header: "Provision Response Id",
			// 	accessor: "ProvisionResponseId",
			// },
			// {
			// 	Header: "Processor",
			// 	accessor: "Processor",
			// },
			{
				Header: "Transaction Payment Instrument Id",
				accessor: "TransactionPaymentInstrumentId",
			},
			{
				Header: "Trace Id",
				accessor: "TraceId",
			},
			{
				Header: "Bin Number",
				accessor: "Bin",
			},

			{
				Header: "Brand",
				accessor: "Brand",
			},
			{
				Header: "Card Emboss Name",
				accessor: "CardEmbossName",
			},
			{
				Header: "Card Id",
				accessor: "CardId",
			},
			{
				Header: "Is Adjustment Record",
				accessor: "IsAdjustmentRecord",
				Cell: row => (

					<div>{row.value == true ? "Yes" : row.value == false ? "No" : "-"}</div>
				)
			},
			{
				Header: "Is Edit Table",
				accessor: "IsEditable",
				Cell: row => (

					<div>{row.value == true ? "Yes" : row.value == false ? "No" : "-"}</div>
				)
			},
			{
				Header: "Is Recurring",
				accessor: "IsRecurring",
				Cell: row => (

					<div>{row.value == true ? "Yes" : row.value == false ? "No" : "-"}</div>
				)
			},
			{
				Header: "Is Deferred",
				accessor: "IsDeferred",
				Cell: row => (

					<div>{row.value == true ? "Yes" : row.value == false ? "No" : "-"}</div>
				)
			},
			{
				Header: "Is Settled",
				accessor: "IsSettled",
				Cell: row => (

					<div>{row.value == true ? "Yes" : row.value == false ? "No" : "-"}</div>
				)
			},
			{
				Header: "Is Free Charge",
				accessor: "IsFreeCharge",
				Cell: row => (

					<div>{row.value == true ? "Yes" : row.value == false ? "No" : "-"}</div>
				)
			},

			{
				Header: "Is Card Transaction",
				accessor: "IsCardTransaction",
				Cell: row => (

					<div>{row.value == true ? "Yes" : row.value == false ? "No" : "-"}</div>
				)
			},
			// {
			// 	Header: "Application Id",
			// 	accessor: "ApplicationId"
			// },
			// {
			// 	Header: "Adjusment Type",
			// 	accessor: "AdjusmentType"
			// },
			{
				Header: "Client Reference Number",
				accessor: "ClientReferenceNumber"
			},
			{
				Header: "Fee Amount",
				accessor: "FeeAmount",
				type: GridColumnType.Money
			},
			{
				Header: "Unique Operation Code",
				accessor: "UniqueOperationCode"
			},
			{
				Header: "Edit Count",
				accessor: "EditCount"
			},
			// {
			// 	Header: "File Name",
			// 	accessor: "FileName"
			// },
			// {
			// 	Header: "Store Name",
			// 	accessor: "StoreName"
			// },
			// {
			// 	Header: "Identity Type",
			// 	accessor: "IdentityType"
			// },
			// {
			// 	Header: "Identity Number",
			// 	accessor: "IdentityNumber"
			// },
			{
				Header: "Client Registred",
				accessor: "ClientRegistredId"
			},

			{
				Header: "Expiration Year",
				accessor: "ExpirationYear",
			},
			{
				Header: "Expiration Moth",
				accessor: "ExpirationMonth",
			},
			{
				Header: "Card Token Number",
				accessor: "CardTokenNumber",
			},
			{
				Header: "Masked Card Number",
				accessor: "MaskedCardNumber",
			},
			{
				Header: "Payment Instrument",
				accessor: "PaymentInstrumentId",
			},
			{
				Header: "Issuer Country",
				accessor: "IssuerCountry",
			}
		];
		this.trxTable = React.createRef();

		this.emptyObject = {};
		this.sortedCustomerName = { Member: "Name" };
	}
	componentDidMount() {
		const { setAppLeftTitle, setAppCenterTitle } = this.props;
		if (setAppLeftTitle) {
			setAppLeftTitle("Transaction Search");
		}
		if (setAppCenterTitle) {
			setAppCenterTitle("CARD TRANSFER");
		}
		this.setState({ loadingCompleted: true });
	}

	handleSearch = async () => {
		this.setState(state => ({ trxGridToken: !state.trxGridToken }));
		this.setState({ isLoading: true });
		const { ExecuteApiPost } = this.props;
		var model = { ...this.state.model };
		var responseData = await ExecuteApiPost("/bankapi/v1.0/VisaDirect/VisaDirectTransactionSearch", model, null, null, null, null, null);
		if (responseData != null) {
			this.setState({
				getDataList: responseData.length < 1 ? [] : responseData, isLoading: false
			});
		}
		else {
			this.setState({ isLoading: false });
		}
	}
	handleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}
	ValueChangedForExport = (name, value) => {
		this.setState({ [name]: value });
	}
	handleClear = () => {
		this.setState({
			model: {
				FromDate: DateHelper.GetDate(), ToDate: DateHelper.GetDate(),
			}
		});
	}

	ExportClick = () => {
		var trxColumns = [];
		this.trxTable.current.props.Columns.forEach(c => {
			if (c.accessor != "Actions" && c.accessor != "ED" && (c.show == undefined || c.show)) {
				trxColumns.push({ value: c.accessor, title: c.Header, columntype: c.ColumnType, type: c.type });
			}
		}, this);
		console.log("deneme");
		this.setState({ isDownloadDialogOpen: true, GridKeyValueList: trxColumns, ExportSelectedColumns: trxColumns });
	}

	ExcelDownload = () => {
		if (!this.state.ExportReportType) {
			this.props.showMessage("warning", "Export Report Type not selected", "Select Export Report Type to continue");
			return;
		}

		if (this.state.ExportReportType == "PDF" && this.state.ExportSelectedColumns.length > 15) {
			this.props.showMessage("warning", "PDF document cannot exceed 15 columns", "PDF document cannot exceed 15 columns");
			return;
		}

		this.setState({ isDownloadDialogOpen: false });

		this.props.showConfirmMessage("question", "Long Process Warning", "This process may take a while. Continue ?", () => {
			this.hideAlert();
			const { model } = this.state;
			model.IsClientBalanceScreen = true;

			// Add jsonFile
			var jsonColList = [];

			var trxColumns = {};
			this.state.ExportSelectedColumns.map(c => {
				trxColumns[c.value] = { value: c.value, title: c.title, columntype: c.columntype, type: c.type };
			});
			jsonColList.push(trxColumns);

			model["jsonFile"] = {
				colmns: jsonColList,
				format: this.state.ExportReportType,
				title: "Visa Direct Transaction Search"
			};

			this.setState({ isLoading: true });
			Proxy.DownloadGeneratedFile(
				"/bankapi/v1.0/VisaDirect/VisaDirectTransactionSearchReportExportDownload",
				model,
				responseData => {
					this.setState({ isLoading: false });
					if (!responseData.IsSucceeded) {
						this.props.showMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
				},
				errorMessage => {
					this.setState({ isLoading: false });
					this.props.showMessage("error", "Error", errorMessage);
				},
				this.props.showMessage
			);
		});
	}

	openExportDialog = () => {
		this.setState({
			isDownloadDialogOpen: true,
		});
	};

	closeExportDialog = () => {
		this.setState({
			isDownloadDialogOpen: false,
		});
	};

	render() {
		const { Disabled, classes } = this.props;
		const { model, isLoading, getDataList } = this.state;
		return (
			<div>
				{this.state.alert}
				<LoadingComponent Show={isLoading} />
				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.handleClear, Disabled: Disabled },
					{ Code: "Search", OnClick: this.handleSearch, Disabled: Disabled },
					{ Code: "Export", OnClick: () => this.ExportClick(), Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<GenericExpansionPanel Title="Main Parameters">
								<CardBody>
									<GridContainer>
										<GridItem xs={4}>

											{ClientHelper.IsBackOffice() &&
												< GenericSelectInput
													Name="UniqueClientId"
													LabelText="Client"
													Url="/bankapi/v1.0/BankCustomer/Search"
													Method="POST"
													Parameter={this.emptyObject}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="Name"
													Sorted={this.sortedCustomerName}
													Value={model.UniqueClientId}
													ValueChanged={this.handleChange} />}
											<GenericNumberInput
												Name="Amount"
												LabelText="Amount"
												Value={model ? model == null ? "" : model.Amount == null ? "" : model.Amount : ""}
												ValueChanged={this.handleChange}
												Prefix="$"
											/>
											<GenericSelectInput
												Name="CurrencyId"
												LabelText="Currency"
												Url="/bankapi/v1.0/Currency/ValidCurrencies"
												Method="GET"
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Description"
												Value={model.CurrencyId}
												ValueChanged={this.handleChange} />
											<ParameterComponent
												Name="TransactionTypeId"
												LabelText="Type"
												ParameterCode="TransactionType"
												Value={model.TransactionTypeId}
												ValueChanged={this.handleChange}
												Sorted={this.sortParamDesc}
											/>
											<GenericTextInput
												Name="TransactionId"
												LabelText="Finix Trans. ID"
												Value={model == null ? "" : model.TransactionId ? model.TransactionId : ""}
												ValueChanged={this.handleChange} />
										</GridItem>
										<GridItem xs={4}>
											<GenericDateInput
												Name="StartDate"
												LabelText="Start Date"
												Value={model.StartDate ? DateHelper.ToDateInputValueAsUtc(model.StartDate) : ""}
												ValueChanged={this.handleChange}
												Utc
												MaxDate={model.EndDate || DateHelper.GetDate()}
											/>
											<GenericDateInput
												Name="EndDate"
												LabelText="End Date"
												Value={model.EndDate ? DateHelper.ToDateInputValueAsUtc(model.EndDate) : ""}
												ValueChanged={this.handleChange}
												Utc
												MinDate={model.StartDate}
												MaxDate={DateHelper.GetDate()}
											/>
											<GenericTextInput
												Name="BinNumber"
												LabelText="BIN Number"
												Value={model == null ? "" : model.BinNumber ? model.BinNumber : ""}
												ValueChanged={this.handleChange} />
											{/* <GenericNumberInput
												NoFormatting
												Name="CardLast"
												LabelText="Card Last 4"
												Value={model.CardLast}
												ValueChanged={this.handleChange}
												MaxLength={4}
											/> */}
											<GenericTextInput
												Name="Name"
												LabelText="Name"
												Value={model == null ? "" : model.Name ? model.Name : ""}
												ValueChanged={this.handleChange} />
										</GridItem>
										<GridItem xs={4}>
											<GenericNumberInput NoFormatting Name="ReferenceNumber" LabelText="Reference Number" Value={model.ReferenceNumber} ValueChanged={this.handleChange} />
										</GridItem>
									</GridContainer><br />
								</CardBody>
							</GenericExpansionPanel>
						</Card>
						<GenericDialog open={this.state.isDownloadDialogOpen} fullWidth={true}>
							<DialogTitle>
								<GenericLabel Text="Select Report Type" FontSize="20px" Bold={true} />
							</DialogTitle>
							<DialogContent style={{ height: 300 }}>
								<GenericRadioInput
									Name="ExportReportType"
									LabelText="Report Type"
									IsStatic={true}
									StaticData={[{ Value: "PDF", Text: "PDF" }, { Value: "CSV", Text: "CSV" }, { Value: "EXCEL", Text: "EXCEL" }]}
									KeyValueMember="Value"
									TextValueMember="Text"
									Value={this.state.ExportReportType}
									ValueChanged={this.ValueChangedForExport}
								/>
								<GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
								<GridItem style={{ marginTop: 20 }}>
									<Select
										value={this.state.ExportSelectedColumns}
										onChange={value => this.setState({ ExportSelectedColumns: value })}
										isMulti
										name="Columns"
										getOptionLabel={d => d.title}
										options={this.state.GridKeyValueList}
										className="basic-multi-select"
										classNamePrefix="select"
									/>
								</GridItem>
							</DialogContent>
							<GenericDialogActions>
								<Button size="sm" onClick={this.ExcelDownload}>Download</Button>
								<Button size="sm" onClick={() => this.setState({ isDownloadDialogOpen: false })}>Cancel</Button>
							</GenericDialogActions>
						</GenericDialog>
						<Card className="no-radius">
							<CardHeader>
								<GridContainer spacing={16} justify="space-between" alignItems="center">
									<GridItem>
										<CardHeader>
											<h4><b>Transaction List</b></h4>
										</CardHeader>
									</GridItem>
								</GridContainer>
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer>
											<GridItem xs={4}>
											</GridItem>
											<GridItem xs={8} />
										</GridContainer>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													key={"trxGrid" + this.state.trxGridToken}
													ServerSide
													RequestUrl="/bankapi/v1.0/VisaDirect/VisaDirectTransactionSearch"
													RequestMethod="POST"
													PrepareRequest={() => { return model; }}
													ref = {this.trxTable}
													PageSize={10}
													ShowPagination={true}
													LoadAtStartup={this.state.loadingCompleted}
													Data={getDataList}
													Columns={this.Columns}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

			</div>
		)
	}
}

export default withArtifex(VisaDirectTransactionSearch, {});