import { DialogContent, DialogTitle, withStyles } from "@material-ui/core";
import style from "assets/jss/material-dashboard-pro-react/views/buttonToolbarStyle.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import AlertHelper from "core/AlertHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericDialog, GenericDialogActions, GenericLabel, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ToolbarButton from "views/Components/ToolbarButton.jsx";
import { ApprovalStatus } from "views/Constants/Constant";
import ClientHelper from "core/ClientHelper";

class ButtonToolbar extends Component {

	constructor(props) {
		super(props);

		this.state = {
			WorkflowData: {},
			MenuActionRights: [],
			MenuActions: [],
			AllActions: [],
			rejectReasonDialogOpen: false,
			isLoading: false
		};

		this.LoadAllActions = this.LoadAllActions.bind(this);
		this.LoadButtons = this.LoadButtons.bind(this);
		this.LoadMenuActionRights = this.LoadMenuActionRights.bind(this);
		this.LoadMenuActions = this.LoadMenuActions.bind(this);
		this.LoadRejectComment = this.LoadRejectComment.bind(this);
		this.LoadModel = this.LoadModel.bind(this);
		this.SetStatus = this.SetStatus.bind(this);
		this.StepUp = this.StepUp.bind(this);
		this.SendToWorkflow = this.SendToWorkflow.bind(this);
		this.OnButtonClick = this.OnButtonClick.bind(this);
		this.RejectClick = this.RejectClick.bind(this);
		this.CancelRejectClick = this.CancelRejectClick.bind(this);
		this.ButtonClickAsSender = this.ButtonClickAsSender.bind(this);

		this.ValueChanged = this.ValueChanged.bind(this);
		this.ShowMessage = this.ShowMessage.bind(this);
		this.ShowConfirmMessage = this.ShowConfirmMessage.bind(this);
		this.hideAlert = this.hideAlert.bind(this);
	}

	componentDidMount() {
		this.LoadMenuActionRights();
		this.LoadMenuActions();
		this.LoadAllActions();
		this.LoadRejectComment();
		this.LoadModel();
	}

	ValueChanged(name, newValue) {
		this.setState({ [name]: newValue });
	}

	LoadMenuActionRights() {
		const { menuId } = this.props;

		if (menuId) {
			Proxy.POST(
				"/coreapi/v1.0/MenuActionRight/GetByMenuId",
				menuId,
				responseData => {
					if (!responseData.IsSucceeded) {
						console.error("Error", responseData.ErrorDescription);
						return;
					}
					var MenuActionRights = responseData.Item;
					this.setState({ MenuActionRights });
				},
				error => {
					console.error("Error", error.message);
				}
			);
		}
	}

	LoadAllActions() {
		Proxy.POST(
			"/coreapi/v1.0/Action/List",
			{},
			responseData => {
				if (!responseData.IsSucceeded) {
					console.error("Error", responseData.ErrorDescription);
					return;
				}
				var AllActions = responseData.Item;
				this.setState({ AllActions });
			},
			error => {
				console.error("Error", error.message);
			}
		);
	}

	LoadMenuActions() {
		const { menuId } = this.props;

		if (menuId) {
			Proxy.POST(
				"/coreapi/v1.0/MenuAction/GetByMenuId",
				menuId,
				responseData => {
					if (!responseData.IsSucceeded) {
						console.error("Error", responseData.ErrorDescription);
						return;
					}
					var MenuActions = responseData.Item;
					this.setState({ MenuActions });
				},
				error => {
					console.error("Error", error.message);
				}
			);
		}
	}

	LoadRejectComment() {
		const { ApprovalData } = this.props;

		if (ApprovalData && ApprovalData.Id) {
			Proxy.POST(
				"/coreapi/v1.0/WorkflowData/GetRejectReason",
				ApprovalData.Id,
				responseData => {
					if (!responseData.IsSucceeded) {
						console.error("Error", responseData.ErrorDescription);
						return;
					}
					var RejectComment = responseData.Item;
					this.setState({ RejectComment });
				},
				error => {
					console.error("Error", error.message);
				}
			);
		}
	}

	LoadModel() {
		const { ApprovalData, ButtonList } = this.props;

		if (ApprovalData && ApprovalData.Id) {
			Proxy.POST(
				"/coreapi/v1.0/WorkflowData/GetDataModel",
				ApprovalData.Id,
				responseData => {
					if (!responseData.IsSucceeded) {
						console.error("Error", responseData.ErrorDescription);
						return;
					}

					const JsonData = responseData.Item;
					const actionCode = ApprovalData.ActionCode;
					const button = ButtonList.find(x => x.Code == actionCode);

					var model = JSON.parse(JsonData);
					if (model != null && button && button.FillDataFromModel) {
						button.FillDataFromModel(model);
					}
				},
				error => {
					console.error("Error", error.message);
				}
			);
		} else if (ApprovalData && ApprovalData.KycId == 0) {
			const JsonData = ApprovalData.JsonData;
			const actionCode = ApprovalData.ActionCode;
			const button = ButtonList.find(x => x.Code == actionCode);

			var model = JSON.parse(JsonData);
			if (model != null && button && button.FillDataFromModel) {
				button.FillDataFromModel(model);
			}
		}
	}

	OnButtonClick(buttonData, menuActionRight) {
		const menuId = menuActionRight?.MenuAction?.MenuId;
		const actionId = menuActionRight?.MenuAction?.ActionId;

		const sendToWorkflowFunction = (noApprove = false) => {
			this.ShowConfirmMessage("warning", "Workflow Confirmation", "Are you sure to send it for workflow confirmation?", () => {
				if (noApprove == true) {
					this.ButtonLog(menuId, actionId, "Step Check Sent to Workflow");
				}
				else {
					this.ButtonLog(menuId, actionId, "Sent to Workflow");
				}
				this.hideAlert();
				this.SendToWorkflow(buttonData.Code, noApprove);
			});
		};

		if (menuActionRight.MenuAction.IsWorkflowAction && !buttonData.DisableWorkflow) {
			if (buttonData.ValidationFunction != null) {
				if (!buttonData.ValidationFunction()) {
					this.ButtonLog(menuId, actionId, "Validation Error");
					return;
				}
			}

			sendToWorkflowFunction();
		}
		else {
			const { ModelFunction } = buttonData;

			if (ModelFunction == null) {
				this.ButtonLog(menuId, actionId);
				buttonData.OnClick();
				return;
			}

			if (buttonData.ValidationFunction != null) {
				if (!buttonData.ValidationFunction()) {
					this.ButtonLog(menuId, actionId, "Validation Error");
					return;
				}
			}

			var data = ModelFunction();
			var jsonData = JSON.stringify(data);

			var request = {
				MenuActionId: menuActionRight.MenuActionId,
				JsonData: jsonData
			};

			this.setState({ isLoading: true });

			Proxy.POST("/amlkycapi/v1.0/KycScenario/Check",
				request,
				(responseData) => {
					if (!responseData.IsSucceeded) {
						this.ButtonLog(menuId, actionId, "KYC Error");
						this.setState({ isLoading: false });
						console.error("Error", responseData.ErrorDescription);

						return;
					}

					if (responseData.Item != null && responseData.Item.Result) {
						Proxy.POST("/coreapi/v1.0/WorkflowStep/WorkflowStepCheck",
							request,
							responseData => {
								this.setState({ isLoading: false });

								if (!responseData.IsSucceeded) {
									this.ButtonLog(menuId, actionId, "Step Check Error");
									console.error("Error", responseData.ErrorDescription);
									return;
								}

								if (responseData.Item == false) {
									sendToWorkflowFunction(true);
								}
								else {
									this.ButtonLog(menuId, actionId);
									buttonData.OnClick();
								}
							},
							error => {
								this.ButtonLog(menuId, actionId, "Step Check Unknown Error");
								this.setState({ isLoading: false });
								console.error("Error", error.message);
							}
						);
					}
					else {
						this.ButtonLog(menuId, actionId, "KYC Reject");
						this.ShowMessage("warning", "KYC Check", responseData.Item.Reason);

						this.setState({ isLoading: false });
					}
				},
				error => {
					this.ButtonLog(menuId, actionId, "KYC Unknown Error");
					this.setState({ isLoading: false });
					console.error("Error", error.message);
				}
			);
		}
	}

	LoadButtons() {
		const { ButtonList, ApprovalData } = this.props;
		const { MenuActionRights, MenuActions, AllActions } = this.state;

		if (ApprovalData) {
			switch (ApprovalData.ApprovalStatusCode) {
				case ApprovalStatus.Waiting:
					var approveFunc = () => this.SetStatus(ApprovalStatus.Approved); // Normally
					if (ApprovalData.CurrentStep)
						approveFunc = () => this.StepUp(); // Longrunning
					else if (ApprovalData.DirectComplete)
						approveFunc = () => this.SetStatus(ApprovalStatus.Completed); // Direct Complete
					return (
						<div>
							<ToolbarButton tooltip={"Approve"} size="sm" onClick={approveFunc}>{"Approve"}</ToolbarButton>
							<ToolbarButton tooltip={"Reject"} size="sm" onClick={() => this.setState({ rejectReasonDialogOpen: true })}>{"Reject"}</ToolbarButton>
						</div>
					);
				case ApprovalStatus.Approved:
					return (
						<div>
							<ToolbarButton tooltip={"Approve"} size="sm" onClick={() => this.SetStatus(ApprovalStatus.Completed)}>{"Approve"}</ToolbarButton>
							<ToolbarButton tooltip={"Reject"} size="sm" onClick={() => this.SetStatus(ApprovalStatus.CanceledbyUser)}>{"Reject"}</ToolbarButton>
						</div>
					);
				case ApprovalStatus.Rejected:
					return (
						<div>
							<ToolbarButton tooltip={"Resend"} size="sm" onClick={() => this.SetStatus(ApprovalStatus.Waiting)}>{"Resend"}</ToolbarButton>
							<ToolbarButton tooltip={"Reject"} size="sm" onClick={() => this.SetStatus(ApprovalStatus.CanceledbyUser)}>{"Reject"}</ToolbarButton>
						</div>
					);
				case ApprovalStatus.CanceledbyUser:
					return "";
				case ApprovalStatus.Completed:
					break; // Load from ButtonList
				case ApprovalStatus.KYC:
					break;
				default:
					break;
			}
		}

		var menuActionList = MenuActions;
		var menuAllActionList = AllActions.sort((a, b) => (b.Order > a.Order) ? 1 : -1);
		var clearAction = ButtonList.find(x => x.Code == "Clear");

		return (
			<div>
				{menuAllActionList.map((s, k) => {
					var mActnlst = menuActionList.find(x => x.Action && s.Code == x.Action.Code);
					if (mActnlst != null) {
						var buttonData = ButtonList.find(x => mActnlst.Action && x.Code == mActnlst.Action.Code);
						if (buttonData != null) {
							var menuActionRight = MenuActionRights.find(m => m.MenuAction && m.MenuAction.Action && m.MenuAction.Action.Code == buttonData.Code);
							if (menuActionRight != null) {
								return <ToolbarButton tooltip={menuActionRight.MenuAction.Action.Description} key={k} disabled={ApprovalData != null && ApprovalData.KycId == 0 && ApprovalData.ActionCode == s.Code ? false : buttonData.Disabled} size="sm" onClick={() => this.OnButtonClick(buttonData, menuActionRight)}>{menuActionRight.MenuAction.Action.Description}</ToolbarButton>;
							}
							else {
								var description = mActnlst.Action ? mActnlst.Action.Description : "";
								return <ToolbarButton tooltip={description} key={k} disabled={true} size="sm">{description}</ToolbarButton>;
							}
						}
					}
					else {
						if (["Submit", "Delete", "Search", "Update"].some(x => x == s.Code)) {
							return <ToolbarButton key={k} tooltip={s.Code} disabled={true} size="sm">{s.Code}</ToolbarButton>;
						}
					}
					return null;
				})
				}
				{(clearAction == null) ?
					<ToolbarButton tooltip={"Clear"} disabled={true} size="sm">{"Clear"}</ToolbarButton>
					: <ToolbarButton tooltip={clearAction.Code} disabled={clearAction.Disabled} size="sm" onClick={clearAction.OnClick}>{clearAction.Code}</ToolbarButton>
				}
			</div>
		);
	}

	SetStatus(statusCode) {

		const { ButtonList, ApprovalData, After } = this.props;
		const { RejectReason } = this.state;

		const actionCode = (ApprovalData != null) ? ApprovalData.ActionCode : "";

		const button = ButtonList.find(x => x.Code == actionCode);
		const { ModelFunction } = button;

		if (ApprovalData == null) {
			this.ShowMessage("warning", "Cannot Send To Workflow", "Approval Data is empty.");
			return;
		}

		var requestData = {
			Id: ApprovalData.Id,
			StatusCode: statusCode
		};

		if (statusCode == ApprovalStatus.Waiting) {
			if (ModelFunction == null) {
				this.ShowMessage("warning", "Cannot Send To Workflow", "Model Function is empty.");
				return;
			}

			var data = ModelFunction();
			var jsonData = JSON.stringify(data);
			requestData.JsonData = jsonData;
		}

		if (statusCode == ApprovalStatus.Rejected) {
			if (RejectReason == null || RejectReason == "") {
				this.ShowMessage("warning", "Cannot Send To Workflow", "Reject Reason is empty.");
				return;
			}

			requestData.Comment = RejectReason;
		}

		if (statusCode == ApprovalStatus.Completed) {
			if (button && button.OnClick) {
				this.ButtonClickAsSender(button.OnClick);
			}
		}
		else {
			this.setState({ isLoading: true });
			Proxy.POST("/coreapi/v1.0/WorkflowData/SetWorkflowDataStatus",
				requestData,
				responseData => {
					this.setState({ isLoading: false });

					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "An error occurred while sending approve request", responseData.ErrorDescription);
						return;
					}
					this.ShowMessage("success", "Request successfully sent to approve.");
					if (After) After();
				},
				errorMessage => {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "An error occurred while sending approve request", errorMessage);
				}
			);
		}
	}

	StepUp() {

		const { ButtonList, ApprovalData, After } = this.props;

		if (ApprovalData == null) {
			this.ShowMessage("warning", "Cannot Send To Workflow", "Approval Data is empty.");
			return;
		}

		var requestData = { Id: ApprovalData.Id };

		this.setState({ isLoading: true });
		Proxy.POST("/coreapi/v1.0/WorkflowData/StepUp",
			requestData,
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "An error occurred while sending approve request", responseData.ErrorDescription);
					return;
				}

				// Last step done and can be directly completed
				if (responseData.Item && responseData.Item.CurrentStep == null && ApprovalData.DirectComplete == true) {
					const actionCode = (ApprovalData != null) ? ApprovalData.ActionCode : "";
					const button = ButtonList.find(x => x.Code == actionCode);
					if (button && button.OnClick) {
						this.ButtonClickAsSender(button.OnClick);
					}
				}
				else {
					this.ShowMessage("success", "Request successfully sent to approve.");
					if (After) After();
				}
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while sending approve request", errorMessage);
			}
		);
	}

	ButtonClickAsSender(OnClick) {
		const { ApprovalData, After } = this.props;

		Proxy.POST("/coreapi/v1.0/Authentication/ShadowLogin",
			{
				WorkflowId: ApprovalData.Id
			},
			responseData => {
				if (!responseData.IsSucceeded) {
					console.log(responseData.ErrorDescription);
					OnClick(ApprovalData.Id, After);
					return;
				}

				OnClick(ApprovalData.Id, After, responseData.DCBankAccessToken);
			},
			error => {
				console.log(error);
				OnClick(ApprovalData.Id, After);
			}
		);
	}

	SendToWorkflow(actionCode, noApprove = false) {
		const { ButtonList, menuId, After } = this.props;

		const button = ButtonList.find(x => x.Code == actionCode);
		const { ModelFunction, RowId } = button;

		if (ModelFunction == null) {
			this.ShowMessage("warning", "Cannot Send To Workflow", "Model Function is empty.");
			return;
		}

		var data = ModelFunction();
		var jsonData = JSON.stringify(data);

		var requestData = {
			MenuId: menuId,
			ActionCode: actionCode,
			JsonData: jsonData,
			RowId: RowId,
			NoApprove: noApprove
		};

		this.setState({ isLoading: true });
		Proxy.POST("/coreapi/v1.0/WorkflowData/InsertWorkflowData",
			requestData,
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "An error occurred while sending approve request", responseData.ErrorDescription);
					return;
				}
				this.ShowMessage("success", "Request successfully sent for approval");
				if (After) After();
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while sending approve request", errorMessage);
			}
		);
	}

	RejectClick() {
		const { ButtonList, ApprovalData } = this.props;

		const actionCode = (ApprovalData != null) ? ApprovalData.ActionCode : "";

		const button = ButtonList.find(x => x.Code == actionCode);
		const { RejectFunction } = button;

		const setReject = () => this.SetStatus(ApprovalStatus.Rejected);

		if (RejectFunction) {
			const { RejectReason } = this.state;

			if (RejectReason == null || RejectReason == "") {
				this.ShowMessage("warning", "Warning", "Reject Reason is empty.");

				return;
			}

			RejectFunction(setReject, RejectReason);
		}
		else {
			setReject();
		}
	}

	ShowMessage(type, title, message) {
		this.setState({ alert: AlertHelper.CreateAlert(title, message, type, this.hideAlert) });
	}

	ShowConfirmMessage(type, title, message, onConfirm) {
		this.setState({ alert: AlertHelper.CreateQuestionAlert(title, message, type, onConfirm, this.hideAlert) });
	}

	hideAlert() {
		this.setState({ alert: null });
	}

	CancelRejectClick() {
		this.setState({ rejectReasonDialogOpen: false });
	}

	ButtonLog(menuId, actionId, status) {
		if (window.DisableButtonLog != true) {
			Proxy.POST("/coreapi/v1.0/ClientAuditLog/InsertClientAuditLog",
				{
					UniqueClientId: ClientHelper.GetClientRowId(),
					MenuId: menuId,
					ActionId: actionId,
					Status: status ?? "Normal"
				}
			);
		}
	}

	render() {
		const { classes, ApprovalData, IsPopUp: IsPopup } = this.props;
		const { alert, isLoading, RejectReason, RejectComment, rejectReasonDialogOpen } = this.state;

		var isRejected = false;
		var isPopup = IsPopup;
		if (ApprovalData) {
			isPopup = true;
			isRejected = ApprovalData.ApprovalStatusCode == ApprovalStatus.Rejected;
		}

		return (
			<div className={isPopup ? "" : classes.paddingBottom}>
				<div className={isPopup ? classes.normal : classes.fixed}>
					<div className={classes.main}>
						<LoadingComponent Show={isLoading} />
						{alert}
						<GridContainer direction="row" justify="space-between">
							<GridItem>
								{this.LoadButtons()}
							</GridItem>
							<GridItem>
								{isRejected ? <GenericLabel Text={("Reject Reason: " + RejectComment) || "Rejected"} TextColor="red" Bold={true} /> : null}
							</GridItem>
						</GridContainer>

						<GenericDialog open={rejectReasonDialogOpen} maxWidth="md">
							<DialogTitle>
								<GenericLabel Text="Reject Reason" Bold={true} />
							</DialogTitle>
							<DialogContent>
								<GenericTextInput Name="RejectReason" LabelText="Reject Reason" Value={RejectReason} ValueChanged={this.ValueChanged} />
							</DialogContent>
							<GenericDialogActions>
								<Button size="sm" onClick={this.RejectClick}>OK</Button>
								<Button size="sm" onClick={this.CancelRejectClick}>CANCEL</Button>
							</GenericDialogActions>
						</GenericDialog>
					</div>
				</div>
			</div>
		);
	}
}

ButtonToolbar.propTypes = {
	classes: PropTypes.object,
	ButtonList: PropTypes.arrayOf(
		PropTypes.shape({
			Code: PropTypes.string.isRequired,
			OnClick: PropTypes.func.isRequired,
			Disabled: PropTypes.bool,
			ModelFunction: PropTypes.func,
			FillDataFromModel: PropTypes.func,
			ValidationFunction: PropTypes.func,
			RowId: PropTypes.number,
			DisableWorkflow: PropTypes.bool,
			RejectFunction: PropTypes.func
		})
	).isRequired,
	menuId: PropTypes.number.isRequired,
	ApprovalData: PropTypes.object,
	After: PropTypes.func, // Bu alan sadece Workflow larda gerekli,
	IsPopUp: PropTypes.bool
};

export default withStyles(style)(ButtonToolbar);