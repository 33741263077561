import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import { EditIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericCheckInput, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";

class ClientProductManagement extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined,
		};

		this.state = {
			model: this.initialModel,
			vModel: {},
			list: [],
			isEdit: false,
			isLoading: false,
			index: -1,
		};

		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined,
		};

		this.renderItemUniqueClient = {};

		this.parameterClientProfileParameter = {};
		this.parameterDCBankProfileParameter = {};

		this.maxLength26 = { maxLength: 26 };
		this.maxLength128 = { maxLength: 128 };

		this.Columns = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip="Edit"
							Icon={EditIcon}
							OnClick={() => this.setState({ isEdit: true, model: { ...row.original } })} />
					</div>
				),
				width: 50
			},
			{
				Header: "Product Id",
				accessor: "Id"
			},
			{
				Header: "Product Name",
				accessor: "ProductName"
			},
			{
				Header: "Program Name",
				accessor: "ProgramName"
			},
			{
				Header: "Client",
				accessor: "UniqueClient.ClientName"
			},
			{
				Header: "Bin Range Profile",
				accessor: "BinRangeProfile.ProfileName"
			},
			{
				Header: "Plastic Type",
				accessor: "PlasticType.PlasticName"
			},
			{
				Header: "Product Brand",
				accessor: "ProductBrand.ParameterDesc"
			},
			{
				Header: "Is No Name Product",
				accessor: "IsNoNameProduct",
				Cell: row => (
					row.value ? "Yes" : "No"
				)
			},
			{
				Header: "No Name Emboss Name",
				accessor: "NoNameEmbossName"
			},
			{
				Header: "Restriction Profile",
				accessor: "RestrictionProfile.ProfileName"
			},
			// {
			// 	Header: "Client Restriction Profile",
			// 	accessor: "ClientRestrictionProfile.ProfileName"
			// },
			{
				Header: "Fee Profile",
				accessor: "FeeProfile.ProfileName"
			},
			{
				Header: "Client Fee Profile",
				accessor: "ClientFeeProfile.ProfileName"
			},
			{
				Header: "Card Fee Profile",
				accessor: "CardFeeProfile.ProfileName"
			},
			{
				Header: "Client Card Fee Profile",
				accessor: "ClientCardFeeProfile.ProfileName"
			},
			{
				Header: "Limit Profile",
				accessor: "LimitProfile.ProfileName"
			},
			{
				Header: "Client Limit Profile",
				accessor: "ClientLimitProfile.ProfileName"
			},
		];
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Product Profile");
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;

		if (model.UniqueClientId > 0) {
			model.RestrictionProfileId = null;
			model.FeeProfileId = null;
			model.CardFeeProfileId = null;
		}

		if (model.UniqueClientId === -1) {
			model.ClientRestrictionProfileId = null;
			model.ClientFeeProfileId = null;
			model.ClientCardFeeProfileId = null;
		}

		if (!model.AutoRenewCard) {
			model.AutoRenewalPeriodMonth = null;
		}

		if (!model.IsNoNameProduct) {
			model.NoNameEmbossName = null;
		}

		this.setState({ model });
	}

	HandleClear = () => {
		this.setState({ model: { ...this.initialModel }, isEdit: false, index: -1 });
	}

	HandleSearch = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/Product/Search",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", responseData.ErrorDescription);
					return;
				}
				this.setState({ list: responseData.Item || [] });

			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	HandleUpdate = () => {
		if (!this.Validate()) {
			return;
		}

		this.setState({ isLoading: true });
		Proxy.POST("/prepaidapi/v1.0/Product/ClientUpdate",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", responseData.ErrorDescription);
					return;
				}
				this.HandleClear();
				this.HandleSearch();
				this.ShowMessage("success", "Success", "Operation is successfully!");
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	Validate = () => {
		const { model, vModel } = this.state;

		if (!model.UniqueClientId) {
			this.props.showMessage("warning", "Client not selected", "Select the Client to continue.");
			vModel.UniqueClientId = true;
			return false;
		}

		if (model.IsNoNameProduct && !model.NoNameEmbossName) {
			this.ShowMessage("warning", "No Name Emboss Name is empty", "Enter the No Name Emboss Name to continue.");
			vModel.NoNameEmbossName = true;
			return false;
		}
		return true;
	}

	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}


	GetClientProfileParameter = () => {
		this.parameterDCBankProfileParameter.IsDefaultProfile = false;
		this.parameterDCBankProfileParameter.UniqueClientId = this.state.model.UniqueClientId;
		return this.parameterDCBankProfileParameter;
	}


	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: null })} OnConfirm={onConfirm} />
		});
	}

	render() {
		const { classes, Disabled } = this.props;
		const { alert, model, list, isLoading, index, vModel } = this.state;

		var IsClient = ClientHelper.IsClient();

		return (
			<div>

				<LoadingComponent Show={isLoading} />

				{alert}

				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
					{ Code: "Update", OnClick: this.HandleUpdate, Disabled: Disabled || IsClient, ValidationFunction: this.Validate },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Parameters" >
									<GridContainer>
										<GridItem xs={3}>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={this.parameterUniqueClientId}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={this.RenderItemUniqueClient}
												Value={model.UniqueClientId}
												ValueChanged={this.HandleChange}
												CanClear
												Disabled={IsClient}
												DefaultIndex={IsClient ? 0 : -1}
												Required
												IsInvalid={vModel.UniqueClientId}
											/>
										</GridItem>
										{/* <GridItem xs={3}>
											<GenericSelectInput
												key={model.UniqueClientId}
												Name="ClientRestrictionProfileId"
												LabelText="Restriction Profile"
												Method="POST"
												Url="/prepaidapi/v1.0/ProductRestrictionProfile/SearchForComponent"
												Parameter={this.GetClientProfileParameter()}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ProfileName"
												Value={model.ClientRestrictionProfileId}
												ValueChanged={this.HandleChange}
												Required={model.UniqueClientId}
												IsInvalid={vModel.ClientRestrictionProfileId} />
										</GridItem> */}
										<GridItem xs={3}>
											<GenericSelectInput
												key={model.UniqueClientId}
												Name="ClientLimitProfileId"
												LabelText="Limit Profile"
												Method="POST"
												Url="/prepaidapi/v1.0/CardTransactionLimitProfile/SearchForComponent"
												Parameter={this.GetClientProfileParameter()}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ProfileName"
												Value={model.ClientLimitProfileId}
												ValueChanged={this.HandleChange}
												Required={model.UniqueClientId}
												IsInvalid={vModel.ClientLimitProfileId} />
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												key={model.UniqueClientId}
												Name="ClientFeeProfileId"
												LabelText="Fee Profile"
												Method="POST"
												Url="/prepaidapi/v1.0/CardTransactionFeeProfile/SearchForComponent"
												Parameter={this.GetClientProfileParameter()}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ProfileName"
												Value={model.ClientFeeProfileId}
												ValueChanged={this.HandleChange}
												Required={model.UniqueClientId}
												IsInvalid={vModel.ClientFeeProfileId} />
										</GridItem>
										<GridItem xs={3}>
											<GenericTextInput
												Name="ProgramName"
												LabelText="Program Name"
												inputProps={this.maxLength128}
												IsText={true}
												Value={model.ProgramName}
												ValueChanged={this.HandleChange} />
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												key={model.UniqueClientId}
												Name="ClientCardFeeProfileId"
												LabelText="Card Fee Profile"
												Method="POST"
												Url="/prepaidapi/v1.0/CardFeeProfile/SearchForComponent"
												Parameter={this.GetClientProfileParameter()}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ProfileName"
												Value={model.ClientCardFeeProfileId}
												ValueChanged={this.HandleChange}
												Required={model.UniqueClientId}
												IsInvalid={vModel.ClientCardFeeProfileId} />
										</GridItem>
										
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Product List">
									<GridContainer>
										<GridItem xs={12}>
											<br />
											<GenericGrid
												Data={list}
												Columns={this.Columns}
												SelectedIndex={index}
												HideButton={true}
											/>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

			</div>
		);
	}
}

ClientProductManagement.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(ClientProductManagement, {});
