import Collapse from "@material-ui/core/Collapse";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx";
import cx from "classnames";
import { withArtifex } from "core";
import ResourceHelper from "core/ResourceHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class Sidebar extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	// verifies if routeName is the one active (in browser input)
	activeRoute(routeName) {
		return this.props.location.pathname === routeName;
	}
	// this method sets the current state of a menu item i.e whether it is in expanded or collapsed or a collapsed state
	handleClick(item) {
		this.setState(prevState => (
			{ [item.Name]: !prevState[item.Name] }
		));
	}
	// if the menu item doesn't have any child, this method simply returns a clickable menu item that redirects to any location and if there is no child this method uses recursion to go until the last level of routes and then returns the item by the first condition.
	handler(routes) {
		const { classes, color, rtlActive } = this.props;
		const { state } = this;

		if (routes == null) return null;

		return routes.map((prop, key) => {
			if (prop.redirect) {
				return null;
			}
			if (prop.Collapse) {
				const itemText =
					classes.itemText +
					" " +
					cx({
						[classes.itemTextMini]:
							this.props.miniActive && this.state.miniActive,
						[classes.itemTextMiniRTL]:
							rtlActive && this.props.miniActive && this.state.miniActive,
						[classes.itemTextRTL]: rtlActive
					});

				return (
					<div key={key}>
						<ListItem
							button
							onClick={() => this.handleClick(prop)}>
							<ListItemText
								// inset
								primary={ResourceHelper.GetForMenu(prop.Name) || "! " + prop.Name}
								disableTypography={true}
								className={itemText} />
							{state[prop.Name] ?
								<ExpandLess /> :
								<ExpandMore />
							}
						</ListItem>
						<Collapse
							in={state[prop.Name]}
							timeout="auto"
							unmountOnExit
						>
							<List className={classes.list + " " + classes.collapseList}>
								{this.handler(prop.Views)}
							</List>
						</Collapse>
					</div>
				);
			}

			const navLinkClasses =
				classes.itemLink +
				" " +
				cx({
					[" " + classes[color]]: this.activeRoute(prop.Path)
				});

			const itemText =
				classes.itemText +
				" " +
				cx({
					[classes.itemTextMini]:
						this.props.miniActive && this.state.miniActive,
					[classes.itemTextMiniRTL]:
						rtlActive && this.props.miniActive && this.state.miniActive,
					[classes.itemTextRTL]: rtlActive
				});

			return (
				<ListItem key={key} className={classes.item}>
					<NavLink to={prop.Path} className={navLinkClasses}>
						<ListItemText
							primary={ResourceHelper.GetForMenu(prop.Name) || "! " + prop.Name}
							disableTypography={true}
							className={itemText}
						/>
					</NavLink>
				</ListItem>
			);
		});
	}

	render() {
		const { classes, bgColor, routes, rtlActive } = this.props;

		const drawerPaper =
			classes.drawerPaper +
			" " +
			cx({
				[classes.drawerPaperMini]:
					this.props.miniActive && this.state.miniActive,
				[classes.drawerPaperRTL]: rtlActive
			});

		const sidebarWrapper =
			classes.sidebarWrapper +
			" " +
			cx({
				[classes.drawerPaperMini]:
					this.props.miniActive && this.state.miniActive,
			});

		return (
			<div ref="mainPanel">
				<Drawer
					anchor={rtlActive ? "right" : "left"}
					variant="permanent"
					open
					classes={{
						paper: drawerPaper + " " + classes[bgColor + "Background"]
					}}
				>
					<div className={sidebarWrapper}>
						{this.handler(routes)}
					</div>
				</Drawer>
			</div>
		);
	}
}

Sidebar.defaultProps = {
	bgColor: "blue"
};

Sidebar.propTypes = {
	classes: PropTypes.object.isRequired,
	bgColor: PropTypes.oneOf(["white", "black", "blue"]),
	rtlActive: PropTypes.bool,
	color: PropTypes.oneOf([
		"white",
		"red",
		"orange",
		"green",
		"blue",
		"purple",
		"rose"
	]),
	routes: PropTypes.arrayOf(PropTypes.object),
	history: PropTypes.any
};

export default withArtifex(Sidebar, sidebarStyle);