import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import { ApproveIcon, DetailIcon } from "core/Icons";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import CardComponent from "views/Components/CardComponent";
import CustomerComponent from "views/Components/CustomerComponent";
import { GenericCheckInput, GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { ClearingDirection, ClearingIssuerFlag, ClearingProcessStatus, PgmCodeType, ProgramCodes } from "views/Constants/Constant.js";
import Dispute from "views/PPaid/Clearing/Dispute/Dispute";
import MastercardClearingDetail from "./MastercardClearingDetail";

class MastercardClearingTransaction extends Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			IsMainCard: true,
			ProcessStatusParams: {},
			IssuerFlagParams: {},
			ValidationStatusParams: {},
			DirectionParams: {}
		};

		this.DisputeModel = {
			ClearingDisputeFeeData: []
		}
		this.state = {
			detailModel: {},
			disputeModel: { ...this.DisputeModel },
			disputeFeeModel: {},
			model: {
				ProcessStatusParams: {},
				IssuerFlagParams: {},
				ValidationStatusParams: {},
				DirectionParams: {}
			},
			list: [],
			DisputeActionData: [],
			isLoading: false,
			isDialogOpen: false,
			isDisputeDialogOpen: false,
			isDisputeActionDialogOpen: false
		}


		this.emptyObject = {};

		this.maxLength26 = { maxLength: 26 };
		this.maxLength19 = { maxLength: 19 };
		this.maxLength12 = { maxLength: 12 };
		this.maxLength40 = { maxLength: 40 };
		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined
		};

		this.renderItemUniqueClient = {};

		this.parameterProduct = {};

		this.Columns = [
			{
				Header: "Actions",
				accessor: "Actions",
				width: 100
			},
			{
				Header: "Issuer Acquirer Flag",
				accessor: "IssuerAcqueirerFlag"
			},
			{
				Header: "Card Number",
				accessor: "CardNumber"
			},
			{
				Header: "Original Amount",
				accessor: "OrginalAmount"
			},
			{
				Header: "Settlement Amount",
				accessor: "SettlementAmount"
			},
			{
				Header: "RRN",
				accessor: "RRN"
			},
			{
				Header: "Processing Code",
				accessor: "ProcessingCode"
			},
			{
				Header: "Direction",
				accessor: "Direction"
			},
			{
				Header: "Clearing Date",
				accessor: "ClearingDate"
			},
			{
				Header: "Validation Status",
				accessor: "ValidationStatus"
			},
			{
				Header: "Arn",
				accessor: "ARN"
			},


		];

		this.HandleUpdate = this.HandleUpdate.bind(this);
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Clearing Transaction");
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
	}
	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		if (name === "UniqueClientId") {
			delete model.ClientCustomerId;
			delete model.CustomerNumber;
			delete model.CardId;
			delete model.CardTokenNumber;
		}
		if (name === "Customer") {
			if (newValue) {
				model.ClientCustomerId = newValue.Id;
				model.CustomerNumber = newValue.CustomerNumber;
			} else {
				delete model.ClientCustomerId;
				delete model.CustomerNumber;
				delete model.CardId;
				delete model.CardTokenNumber;
			}
		}
		if (name === "Card") {
			if (newValue) {
				model.CardId = newValue.CardId;
				model.CardTokenNumber = newValue.CardTokenNumber;
				model.CardNumber = newValue.MaskedCardNumber;
			} else {
				delete model.CardId;
				delete model.CardTokenNumber;
				delete model.MaskedCardNumber;
			}
		}
		model[name] = newValue;
		this.setState({ model });
	}

	FillCustomer = (customer) => {
		this.HandleChange("Customer", customer);
	}

	FillCard = (card) => {
		this.HandleChange("Card", card);
	}



	HandleClear = () => {
		this.setState({ model: { ...this.initialModel }, isEdit: false, index: -1 });
	}


	HandleSearch = () => {
		const model = { ...this.state.model };
		this.setState({ isLoading: true });
		Proxy.POST
			(
				"/prepaidapi/v1.0/MastercardClearing/GetAllMastercardTransaction",
				model,
				(responseData) => {
					this.setState({ isLoading: false });
					if (!responseData.IsSucceeded) {
						this.props.showMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					if (responseData.Item !== null) {
						this.setState({
							list:
								responseData.Item.map(x => {
									return {
										Actions: (
											<div>
												<GridButton
													tooltip="Detail"
													Icon={DetailIcon}
													onClick={() => { this.setState({ isDialogOpen: true, detailModel: x.MastercardClearingTransaction }); }} />
												<GridButton
													tooltip="Dispute"
													Icon={ApproveIcon}
													onClick={() => { this.HandleGetDispute(x.MastercardClearingTransaction); }} />
											</div>
										),
										IssuerAcqueirerFlag: x.IssuerAcqueirerFlag == null || undefined ? "" : x.IssuerAcqueirerFlag,
										CardNumber: x.CardNumber == null || undefined ? "" : x.CardNumber,
										OrginalAmount: x.OrginalAmount == null || undefined ? "" : x.OrginalAmount,
										SettlementAmount: x.SettlementAmount == null || undefined ? "" : x.SettlementAmount,
										RRN: x.RRN == null || undefined ? "" : x.RRN,
										ProcessingCode: x.ProcessingCode == null || undefined ? "" : x.ProcessingCode,
										Direction: x.Direction == null || undefined ? "" : x.Direction,
										ClearingDate: x.ClearingDate == null || undefined ? 0 : x.ClearingDate,
										ValidationStatus: x.ValidationStatus == null || undefined ? 0 : x.ValidationStatus,
										ARN: x.ARN == null || undefined ? 0 : x.ARN,
										ProcessStatusParams: x.ProcessStatusParams == null || undefined ? 0 : x.ProcessStatusParams,
										DirectionParams: x.DirectionParams == null || undefined ? 0 : x.DirectionParams,
										ValidationStatusParams: x.ValidationStatusParams == null || undefined ? 0 : x.ValidationStatusParams,
										IssuerFlagParams: x.IssuerFlagParams == null || undefined ? 0 : x.IssuerFlagParams,
										IsReversal: x.IsReversal == null || undefined ? 0 : x.IsReversal,
									}
								})
						});
					}

				},
				(error) => {
					this.setState({ isLoading: false });
					this.props.showMessage("error", "Error", error);
				}
			)

	}

	HandleUpdate() {
		if (!this.Validate()) {
			return;
		}
		const model = { ...this.state.model };
		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/Prepaid/UpdateCardRenew",
			model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.props.showMessage("success", "Succeeded", "Operation is successfully!");
					this.HandleSearch();
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}


	HandleSubmit = () => {
		if (!this.Validate()) {
			return;
		}

		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/Prepaid/RenewCard",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ model: { ...this.initialModel }, index: -1 });
					this.props.showMessage("success", "Succeeded", "Operation is successfully!");
					this.HandleSearch();
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	Validate = () => {
		const { model } = this.state;

		if (!model.UniqueClientId) {
			this.props.showMessage("warning", "Client not selected", "Select the Client to continue.");
			return false;
		}

		if (!model.ClientCustomerId) {
			this.props.showMessage("warning", "Customer not selected", "Select the Customer to continue.");
			return false;
		}

		if (!model.ProductId) {
			this.props.showMessage("warning", "Product not selected", "Select the Product to continue.");
			return false;
		}

		if (!model.EmbossName) {
			this.props.showMessage("warning", "Emboss Name is empty", "Enter the Emboss Name to continue.");
			return false;
		}

		if (!model.CardIssuingReasonType) {
			this.props.showMessage("warning", "Issuing Resason Type is empty", "Enter the Issuin Reason Type to continue.");
			return false;
		}

		return true;
	}

	RowSelected = (index) => {
		let { model, list } = this.state;
		model = list[index];
		if (list[index].CardRequestId) {
			model.Id = list[index].Id;
		}
		this.setState({ isEdit: true, model, index });
	}

	GetProductParameter = () => {
		this.parameterProduct.UniqueClientId = this.state.model.UniqueClientId;
		this.parameterProduct.IsNoNameProduct = false;
		return this.parameterProduct;
	}


	DisputeModelChange = (modelFunction) => {
		this.setState(function (state, props) {
			var model = state.disputeModel || {};

			if (modelFunction)
				model = modelFunction(model);

			return { disputeModel: model };
		});
	}


	DetailsModelChange = (modelFunction) => {
		this.setState(function (state, props) {
			var model = state.detailModel || {};

			if (modelFunction)
				model = modelFunction(model);

			return { detailModel: model };
		});
	}

	DialogModelChange = (modelFunction) => {
		this.setState(function (state, props) {
			var model = state.initialModel || {};

			if (modelFunction)
				model = modelFunction(model);

			return { initialModel: model };
		});
	}

	CancelSelectCallback = () => {
		this.setState({
			isDialogOpen: false,
			isDisputeDialogOpen: false,
			disputeModel: this.DisputeModel
		});
	};


	HandleClickUpdate = () => {

		this.setState({ isLoading: true });
		Proxy.POST("/prepaidapi/v1.0/MastercardClearing/MastercardClearingUpdateDetail",
			this.state.detailModel,
			responseData => {
				this.setState({ isLoading: false });
				if (responseData.IsSucceeded != false) {
					this.setState({
						isDialogOpen: false
					});
				} else {
					this.setState({ dialogData: [] });
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			});
	}

	HandleClickDispute = () => {

		if (!this.ValidateDispute()) return false;

		this.state.disputeModel.PgmCode = PgmCodeType.MasterCard;
		this.setState({ isLoading: true });

		Proxy.POST("/prepaidapi/v1.0/ClearingDispute/InsertOrUpdateClearingDispute",
			this.state.disputeModel,
			responseData => {
				this.setState({ isLoading: false });
				if (responseData.IsSucceeded != false) {
					this.CancelSelectCallback();
				} else {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			});
	}

	HandleGetDispute = (model) => {
		this.setState({ isLoading: true });
		Proxy.POST("/prepaidapi/v1.0/ClearingDispute/GetDisputeByTransactionId",
			{
				ClearingDataTableId: model.Id,
				PgmCode: PgmCodeType.MasterCard
			},
			responseData => {
				this.setState({ isLoading: false });
				if (responseData.IsSucceeded != false) {
					this.setState({ isDisputeDialogOpen: true, disputeModel: responseData.Item });
				}
				else {
					this.setState({ isDisputeDialogOpen: false });
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			});
	}

	HandleClickReverse = () => {
		this.setState({ isLoading: true });
		Proxy.POST("/prepaidapi/v1.0/MastercardClearing/MastercardClearingTransactionReverse",
			this.state.detailModel,
			responseData => {
				this.setState({ isLoading: false });
				if (responseData.IsSucceeded != false) {
					this.setState({
						isDialogOpen: false
					});
				} else {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);

				}
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			});
	}

	ReverseControl = () => {
		const { model } = { ...this.state };

		if (model.ProcessStatusParams.ParameterValue != ClearingProcessStatus.ReadyToSend) {
			return true;
		}
		if (model.DirectionParams.ParameterValue != ClearingDirection.Outgoing) {
			return true;
		}
		if (model.IssuerFlagParams.ParameterValue != ClearingIssuerFlag.Acquirer) {
			return true;
		}
		if (model.IsReversal) {
			return true;
		}


		return false;
	}


	DisputeFeeModelChange = (modelFunction) => {
		this.setState(state => {
			var model = state.disputeFeeModel || {};
			if (modelFunction)
				model = modelFunction(model);
			return { disputeFeeModel: model, disputeModel: this.state.disputeModel };
		});
	}

	DisputeFeeModelDelete = (index) => {

		if (!index) {
			this.state.disputeModel.ClearingDisputeFeeData.splice(index, 1);
		}
		this.setState({ disputeModel: this.state.disputeModel });
	}

	DisputeFeeModelClear = () => {
		this.setState({ disputeFeeModel: {} });
	}

	DisputeFeeModelEdit = (index) => {
		if (!index) {
			this.state.disputeModel.ClearingDisputeFeeData[index] = this.state.disputeFeeModel;
		}
		this.setState({ disputeModel: this.state.disputeModel });
	}

	DisputeFeeModelPush = (modelFunction) => {
		var model = this.state.disputeFeeModel || {};
		var arr = this.state.disputeModel.ClearingDisputeFeeData || [];
		arr.push({ ...model });
		this.state.disputeModel.ClearingDisputeFeeData = arr;
		this.setState({ disputeModel: this.state.disputeModel });
	}
	ValidateDispute = () => {

		const model = this.state.disputeModel;

		if (model.ChargebackStatusId) {
			if (!model.ChargebackReasonCodeId) {
				this.props.showMessage("error", "Chargeback", "Chargeback Reason Code cannot be empty");
				return false;
			}
		}


		if (model.RepresentmentStatusId) {
			if (!model.ChargebackStatusId) {
				this.props.showMessage("error", "Charge Back", "Chargeback information cannot be empty");
				return false;
			}
			if (!model.RepresentmentReasonCodeId) {
				this.props.showMessage("error", "Representment", "Representment Reason Code cannot be empty");
				return false;
			}
		}


		if (model.SecondChargebackStatusId) {
			if (!model.RepresentmentStatusId) {
				this.props.showMessage("error", "Retrieval Response", "Representment information can not be empty");
				return false;
			}
			if (!model.SecondChargebackReasonCodeId) {
				this.props.showMessage("error", "Retrieval Response", "Second Chargeback Reason Code  cannot be empty");
				return false;
			}
		}
		return true;
	}


	HandleDisputeAction = () => {
		this.setState({ isLoading: true });

		Proxy.POST("/prepaidapi/v1.0/ClearingDispute/GetDisputeAction",
			{
				DisputeId: this.state.disputeModel.Id
			},
			responseData => {
				this.setState({ isLoading: false });
				if (responseData.IsSucceeded != false) {
					this.setState({ isDisputeActionDialogOpen: true, DisputeActionData: responseData.Item });
				}
				else {
					this.setState({ isDisputeActionDialogOpen: false });
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			});
	}

	HandleActionDialogOpen = () => {
		this.HandleDisputeAction();
	}
	HandleActionDialogClose = () => {
		this.setState({ isDisputeActionDialogOpen: false });
	}

	render() {
		const {  Disabled } = this.props;
		const { alert, model, list, isLoading } = this.state;
		var IsClient = ClientHelper.IsClient();
		return (
			<div>
				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
					{ Code: "Submit", OnClick: this.HandleSubmit, Disabled: Disabled || model.Id > 0}
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<GenericDialog open={this.state.isDialogOpen} onBackdropClick={this.CancelSelectCallback} maxWidth="md" fullWidth keepMounted={true} style={{ zIndex: 8 }} >
					<DialogTitle>
						<GenericLabel FontSize="16px" Text="Detail" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ marginTop: 8 }}>
						<MastercardClearingDetail
							model={this.state.detailModel}
							initialModel={this.state.initialModel}
							onModelChange={this.DetailsModelChange}
						/>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.HandleClickUpdate}>Update</Button>
						<Button size="sm" onClick={this.HandleClickReverse} disabled={this.ReverseControl()}>Reverse</Button>
						<Button size="sm" onClick={this.CancelSelectCallback}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>


				<GenericDialog open={this.state.isDisputeDialogOpen} onBackdropClick={this.CancelSelectCallback} maxWidth="xl" fullWidth keepMounted={true} style={{ zIndex: 8 }} >
					<DialogTitle>
						<GenericLabel FontSize="16px" Text="Dispute" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ marginTop: 8 }}>
						{this.state.isDisputeDialogOpen &&
							<Dispute
								PgmCode={PgmCodeType.MasterCard}
								showMessage={this.props.showMessage}

								model={this.state.disputeModel}
								disputeFeeModel={this.state.disputeFeeModel}

								ActionData={this.state.DisputeActionData}
								isActionDialogOpen={this.state.isDisputeActionDialogOpen}


								onModelChange={this.DisputeModelChange}
								onFeeModelPush={this.DisputeFeeModelPush}
								onFeeModelChange={this.DisputeFeeModelChange}
								onFeeModelDelete={this.DisputeFeeModelDelete}
								onFeeModelClear={this.DisputeFeeModelClear}
								onFeeModelEdit={this.DisputeFeeModelEdit}
								onActionsList={this.HandleDisputeAction}
								onActionModelClose={this.HandleActionDialogClose}


							/>
						}
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.CancelSelectCallback}>Cancel</Button>
						<Button size="sm" onClick={this.HandleClickDispute}>Submit</Button>
						<Button size="sm" onClick={this.HandleActionDialogOpen}>Actions</Button>

					</GenericDialogActions>
				</GenericDialog>


				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Panel">
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer>

												<GridItem xs={4}>
													<ParameterComponent
														Name="IssuerAcquirerFlagId"
														LabelText="Issuer Acquirer Flag"
														ParameterCode="IssuerAcquirerFlag"
														Value={model.IssuerAcquirerFlagId}
														ValueChanged={this.HandleChange}
														Disabled={false}
													/>
												</GridItem>
												<GridItem xs={4}>
													<ParameterComponent
														Name="DirectionId"
														LabelText="Direction"
														ParameterCode="Direction"
														Value={model.DirectionId}
														ValueChanged={this.HandleChange}
														Disabled={false}
													/>
												</GridItem>
												<GridItem xs={4}>
													<ParameterComponent
														Name="ValidationStatusId"
														LabelText="Validation Status"
														ParameterCode="ValidationStatus"
														Value={model.ValidationStatusId}
														ValueChanged={this.HandleChange}
														Disabled={false}
													/>
												</GridItem>


											</GridContainer>
											<GridContainer>
												<GridItem xs={12}>
													<GridContainer>
														<GridItem xs={4}>
															<GenericSelectInput
																Name="UniqueClientId"
																LabelText="Client"
																Method="POST"
																Url="/bankapi/v1.0/BankCustomer/Search"
																Parameter={this.parameterUniqueClientId}
																DataRoot="Item"
																KeyValueMember="Id"
																RenderItem={this.RenderItemUniqueClient}
																Value={model.UniqueClientId}
																ValueChanged={this.HandleChange}
																CanClear
																Disabled={IsClient}
																DefaultIndex={IsClient ? 0 : -1} />
														</GridItem>
														<GridItem xs={4}>
															<CustomerComponent
																key={model.UniqueClientId}
																LabelMd={4}
																CustomerNumber={model.CustomerNumber}
																HandleChange={this.HandleChange}
																FillCallback={this.FillCustomer}
																Programs={[ProgramCodes.Prepaid]}
																UniqueClientId={model.UniqueClientId}
																Disabled={!model.UniqueClientId}
															// Required
															// IsInvalid={vModel.ClientCustomerId}
															/>
														</GridItem>
														<GridItem xs={4}>
															<CardComponent
																key={"Card_" + [model.UniqueClientId, model.ClientCustomerId]}
																LabelMd={4}
																CardTokenNumber={model.CardTokenNumber}
																HandleChange={this.HandleChange}
																FillCallback={this.FillCard}
																ClientCustomerId={model.ClientCustomerId}
																UniqueClientId={model.UniqueClientId}
																Disabled={!model.ClientCustomerId}
															// Required
															// IsInvalid={vModel.CardId}
															/>
														</GridItem>
													</GridContainer>
												</GridItem>
											</GridContainer>

											<GridContainer>
												<GridItem xs={4}>

													<GenericCheckInput
														Name="IsReversal"
														LabelText="Is Reversal"
														Value={model.IsReversal}
														ValueChanged={this.HandleChange}
													/>

												</GridItem>
												<GridItem xs={4}>
													<GenericDateInput
														Name="ClearingDate"
														LabelText="Clearing Date"
														Value={model.ClearingDate}
														ValueChanged={this.HandleChange}
														Disabled={false} />
												</GridItem>
												<GridItem xs={4}>
													<GenericTextInput
														Name="ARN"
														LabelMd={4}
														LabelText="ARN"
														Format={"(@) (@@@@@@) (@@@@) (@@@@@@@@@@@) (@)"}
														inputProps={this.maxLength40}
														Value={model.ARN}
														ValueChanged={this.HandleChange} />
												</GridItem>
												<GridItem xs={4}>
													<GenericTextInput
														Name="DE037"
														LabelMd={4}
														LabelText="RRN"
														inputProps={this.maxLength12}
														Value={model.DE037}
														ValueChanged={this.HandleChange} />
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={"Mastercard Clearing List"} />
							</CardHeader>
							<CardBody>
								<GridContainer spacing={16}>
									<GridItem xs={12}>
										<GenericGrid
											Data={list}
											Columns={this.Columns}
										/>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

MastercardClearingTransaction.propTypes = {
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool
};

export default withArtifex(MastercardClearingTransaction, {});