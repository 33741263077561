import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericLabel, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";

class TransferToLinkedAccount extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			customerAccountProfile: {},
			model: {},
			readOnlyData: {},
			isLoading: false,
			isConfirmDialogOpen: false,
			reloadData: false
		};
	}

	componentDidMount() {
		//this.props.hideSidebar();
		this.props.setAppLeftTitle("Transfer to Linked Wallet");
		this.props.setAppCenterTitle("WALLET");
	}

	PerformTransaction = () => {
		var model = { ...this.state.model };
		var ErrorList = [];

		
		if (model.DestName == null || model.DestName == "") {
			ErrorList.push("Receiver name must be defined !");
		}
		if (model.TransactionAmount == null || model.TransactionAmount == 0 || model.TransactionAmount < 0) {
			ErrorList.push("Transaction Amount must be defined !");
		}
		if (model.TransactionDate == null) {
			ErrorList.push("Transaction Date must be defined !");
		}


		if (model.SourceAccountId == null || model.SourceAccountId == "") {
			ErrorList.push("Source Wallet must be selected !");
		}
		if (model.DestinationAccountId == null || model.DestinationAccountId == "") {
			ErrorList.push("Destination Wallet must be selected !");
		}

		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", ErrorList.slice(0, 10).map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		this.setState({ isConfirmDialogOpen: true });
	}
	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	SendTransaction = () => {
		//CreateEftForBankAccountPortalOp class
		var request = { ...this.state.model };
		this.setState({ isLoading: true });
		Proxy.POST("/bankapi/v1.0/BankBmoEFT/CreateEftFromAccountToFlinks",
			request,
			responseData => {
				this.setState({ isLoading: false, isConfirmDialogOpen: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", "An error occurred while inserting data" + responseData.ErrorDescription);
					return;
				}
				this.ShowMessage("success", "Success", "EFT Transaction Entry successfully saved !");
				this.setState({
					model: {},
					isConfirmDialogOpen: false,
					isLoading: false,
					readOnlyData: {},
					reloadData: !this.state.reloadData
				});
			},
			e => {
				this.setState({ isConfirmDialogOpen: false, isLoading: false });
				this.ShowMessage("error", "Error", "An error occurred while inserting data ");
			}
		);
	}

	handleChange = (name, newValue, data) => {
		this.setState(function (state, props) {
			var model = state.model || {};
			var temp = state.readOnlyData || {};
			model[name] = newValue;
			if (name == "SourceAccountId" && data != null) {
				temp.SourceBalance = data.Balance;
				temp.SourceName = data.AccountName;
				temp.SourceType = data.AccountType;
				temp.SourceAccountNumber = data.AccountNumber;
			}
			if (name == "DestinationAccountId" && data != null) {
				temp.DestinationBalance = data.CurrentBalance;
				temp.DestinationName = data.Title;
				temp.DestinationType = data.AccountType;
				model.DisplayName = data.DisplayName;
				temp.DestAccountNumber = data.AccountNumber;
				temp.DestInstitutionNumber = data.InstitutionNumber;
				temp.DestTransitNumber = data.TransitNumber;
				model.DestAccountNumber = data.AccountNumber;				
				model.DestTitle = data.Title;
				model.DestName = data.Name;
				model.DestBalance = data.CurrentBalance;
			}
			return { model: model, readOnlyData: temp };
		});
	}
	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	ClearData = () => {
		this.setState({
			alert: null,
			isLoading: false,
			model: {},
			isConfirmDialogOpen: false
		});
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false,
			isConfirmDialogOpen: false
		});
	}

	RenderItemSelect(d) {
		return d.Name + " - " + d.AccountNumber;
	}

	render() {
		const { model, readOnlyData } = this.state;
		const { Disabled } = this.props;




		return (
			<div>

				{this.state.alert}
				<LoadingComponent Show={this.state.isLoading} />

				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: () => this.ClearData(), Disabled: Disabled },
					{ Code: "Submit", OnClick: () => this.PerformTransaction(), Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<br />
				<Card className="no-radius">
					<GenericExpansionPanel Title={"Select the Transfer Accounts"}>
						<CardBody>
							<GridItem md={12} xs={12}>
								<GridContainer>
									<GridContainer>
										<GridItem xs={12} sm={4} md={4} lg={4}>
											<GenericSelectInput
												key={this.state.reloadData + ""}
												Name="SourceAccountId"
												Disabled={Disabled}
												Method="POST"
												Url="/bankapi/v1.0/Account/CustomerSourceAccounts"
												Parameter={{ IsStatusCheck: true }}
												DataRoot="Item"
												LabelText="From Wallet"
												Value={model.SourceAccountId || ""}
												KeyValueMember="Id"
												TextValueMember="AccountName"
												ValueChanged={this.handleChange}
											/>
										</GridItem>
										<GridItem xs={12} sm={4} md={4} lg={4}>
											{readOnlyData.SourceName != null && (
												<Card>
													<CardBody>
														<p style={{ textColor: "#000", fontSize: "12px", fontWeight: "bold" }}> Balance : {Formatter.FormatMoney(readOnlyData.SourceBalance)}</p>
														<p style={{ textColor: "#000", fontSize: "12px", fontWeight: "bold" }}> Wallet Name : {readOnlyData.SourceName}</p>
														<p style={{ textColor: "#000", fontSize: "12px", fontWeight: "bold" }}> Wallet Number : {readOnlyData.SourceAccountNumber}</p>
													</CardBody>
												</Card>
											)}
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem xs={12} sm={4} md={4} lg={4}>
											<GenericSelectInput
												Method="POST"
												DataRoot="Item"
												Url="/bankapi/v1.0/CustomerLinkedAccount/GetByCustomerId"
												Parameter={{}}
												Name="DestinationAccountId"
												LabelText="To Wallet"
												Value={model.DestinationAccountId || ""}
												KeyValueMember="Id"
												TextValueMember="DisplayName"
												ValueChanged={this.handleChange}
												RenderItem={this.RenderItemSelect}
											/>
										</GridItem>
										<GridItem xs={12} sm={4} md={4} lg={4}>
											{readOnlyData.DestinationName != null && (
												<Card>
													<CardBody>
														<p style={{ textColor: "#000", fontSize: "12px", fontWeight: "bold" }}> Balance : {Formatter.FormatMoney(readOnlyData.DestinationBalance)}</p>
														<p style={{ textColor: "#000", fontSize: "12px", fontWeight: "bold" }}> Wallet Name : {readOnlyData.DestinationName}</p>
														<p style={{ textColor: "#000", fontSize: "12px", fontWeight: "bold" }}> Wallet Number : {`${readOnlyData.DestInstitutionNumber || ""} - ${readOnlyData.DestTransitNumber || "" } - ${readOnlyData.DestAccountNumber || "" }`}</p>
													</CardBody>
												</Card>
											)}
										</GridItem>
									</GridContainer>
								</GridContainer>
								<GridItem>
									<GridContainer justify="flex-end">
										<GridItem>
											<Button size="sm" onClick={() => { this.props.history.push("/LinkedAccounts", { TransferType: 0 }); }}>
												Create Linked Account
											</Button>
										</GridItem>
									</GridContainer>
								</GridItem>
							</GridItem>
						</CardBody>
					</GenericExpansionPanel>
				</Card>

				<Card>
					<GenericExpansionPanel Title={"To Linked Wallet"}>

						<CardBody>
							<GridContainer spacing={16}>
								<GridItem xs={12} sm={6} md={6} lg={6}>

									<GridContainer justify="flex-start">
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<GenericTextInput inputProps={{ maxLength: 20 }} Name="PayeeName" LabelText="Customer Name" Value={model == null ? "" : model.DestName || ""} ValueChanged={this.handleChange} Disabled={true} />
											<GenericTextInput inputProps={{ maxLength: 30 }} Name="PayeeAccount" LabelText="Customer Wallet" Value={model == null ? "" : model.DestAccountNumber || ""} ValueChanged={this.handleChange} Disabled={true} />
											<GenericTextInput inputProps={{ maxLength: 100 }} Name="TransferMemo" LabelText="Transfer Memo" Value={model.TransferMemo || ""} ValueChanged={this.handleChange} />
											<GenericDateInput IsFuture={true} Name="TransactionDate" LabelText="Transaction Date" Value={model.TransactionDate == null ? "" : DateHelper.ToDateInputValue(model.TransactionDate) || ""} ValueChanged={this.handleChange} />
											<GenericNumberInput Prefix="$ " MaxLength={8} Name="TransactionAmount" LabelText="Transaction Amount" Value={model.TransactionAmount || ""} ValueChanged={this.handleChange} />
										</GridItem>
									</GridContainer>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>

								</GridItem>
							</GridContainer>


							<GenericDialog open={this.state.isConfirmDialogOpen} maxWidth="md" fullWidth>
								<DialogTitle>
									<GenericLabel Text="Transaction Confirmation" Bold={true} />
								</DialogTitle>
								<DialogContent>
									<GridItem>
										<GenericTextInput Name="" LabelText="Customer Name" Value={model == null ? "" : model.DestName || ""} Disabled={true} />
										<GenericTextInput Name="" LabelText="Customer Wallet" Value={model == null ? "" : model.DestAccountNumber || ""} Disabled={true} />
										<GenericDateInput Name="" LabelText="Transaction Date" Value={model.TransactionDate == null ? "" : DateHelper.ToDateInputValue(model.TransactionDate) || ""} Disabled={true} />
										<GenericNumberInput Prefix="$ " Name="" LabelText="Transaction Amount" Value={model.TransactionAmount} Disabled={true} />
										<GenericTextInput Name="" LabelText="TransferMemo" Value={model.TransferMemo || ""} Disabled={true} />
										<GenericLabel Text="Are you sure you want to enter this transaction ?" Bold={true} />
									</GridItem>
								</DialogContent>
								<GenericDialogActions>
									<Button size="sm" onClick={() => this.SendTransaction()}>Transfer</Button>
									<Button size="sm" onClick={() => this.setState({ isConfirmDialogOpen: false })}>		Cancel	</Button>
								</GenericDialogActions>
							</GenericDialog>

						</CardBody>
					</GenericExpansionPanel>
				</Card>
			</div>
		);
	}
}

TransferToLinkedAccount.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func
};

export default withArtifex(TransferToLinkedAccount, {});