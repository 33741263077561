import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import { ApproveIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import { GenericAlert, GenericDialog, GenericDialogActions, GenericGrid, GenericLabel, GenericTextInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import { MenuCodes } from "views/Constants/Constant.js";

class CardComponent extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			model: {
				ClientCustomerId: this.props.ClientCustomerId,
				UniqueClientId: this.props.UniqueClientId,
			},
			dialog: {
				isOpen: false,
				dialogData: []
			},
			selected: -1,
			isLoading: false
		};

		this.maxLength19 = { maxLength: 19 };

		this.columns = [{
			Header: "Actions",
			accessor: "Actions",
			Cell: row => (
				<div>
					<GridButton
						tooltip="Select"
						Icon={ApproveIcon}
						OnClick={() => { this.HandleSelectModel(row.original, row.index); }} />
				</div>
			),
			sortable: false,
			filterable: false,
			resizable: false,
			width: 60
		},
		{
			Header: "Client",
			accessor: "UniqueClient.ClientName"
		},
		{
			Header: "Customer",
			accessor: "ClientCustomer.CustomerName"
		},
		{
			Header: "Card Id",
			accessor: "CardId"
		},
		{
			Header: "Card Token Number",
			accessor: "CardTokenNumber"
		}];
	}

	componentDidMount() {
		if (
				this.props.MenuCode == MenuCodes.Menu_CardMonitoring || 
				this.props.MenuCode == MenuCodes.Menu_BackofficeCardMonitoring || 
				this.props.MenuCode == MenuCodes.Menu_CallCenterCardMonitoring
			){
				const cardLast4Digit = {Header:"Card Last 4 Digit", accessor: "CardLast4Digit"};
				const cardEmbossName = {Header:"Cardholder Name", accessor: "CardEmbossName"};
				const newList = this.columns.slice();
				newList.push(cardLast4Digit);
				newList.push(cardEmbossName);
				this.columns = newList;
		}
		else {
				const cardEmbossName = {Header:"Cardholder Name", accessor: "CardEmbossName"};
				const newList = this.columns.slice();
				newList.push(cardEmbossName);
				this.columns = newList;
		}
	}

	HandleChange = (name, value) => {
		const model = { ...this.state.model };
		model[name] = value;
		this.setState({ model });
	}

	HandleKeyPress = (e) => {
		if (e.key == "Enter") {
			this.HandleClickByKeyPressed();
		}
	}

	HandleClickByKeyPressed = () => {
		this.setState({ isLoading: true });
		Proxy.POST("/prepaidapi/v1.0/Card/SearchForComponent",
			{
				UniqueClientId: this.props.UniqueClientId,
				ClientCustomerId: this.props.ClientCustomerId,
				CardTokenNumber: this.props.CardTokenNumber
			},
			responseData => {
				this.setState({ isLoading: false });
				if (responseData.Item != null) {
					this.setState({
						dialog: {
							isOpen: true,
							dialogData: responseData.Item
						}
					});
					if (responseData.Item.length === 1) {
						this.HandleSelectModel(responseData.Item[0], 0);
					}
				} else {
					this.setState({ dialogData: [] });
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			});
	}

	HandleClick = () => {
		this.setState({ isLoading: true });
		Proxy.POST("/prepaidapi/v1.0/Card/SearchForComponent",
			{
				UniqueClientId: this.props.UniqueClientId,
				ClientCustomerId: this.props.ClientCustomerId,
				CardTokenNumber: this.props.CardTokenNumber
			},
			responseData => {
				this.setState({ isLoading: false });
				if (responseData.Item != null) {
					this.setState({
						dialog: {
							isOpen: true,
							dialogData: responseData.Item
						}
					});
				} else {
					this.setState({ dialogData: [] });
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			});
	}

	HandleClearClick = () => {
		this.setState({
			dialog: {
				isOpen: false,
				dialogData: []
			},
			model: {},
			selected: -1
		}, () => {
			this.props.FillCallback(null);
		});
	}

	HandleClickInside = () => {
		this.setState({ isLoading: true });
		Proxy.POST("/prepaidapi/v1.0/Card/SearchForComponent",
			{
				ClientCustomerId: this.props.ClientCustomerId,
				UniqueClientId: this.props.UniqueClientId,
				CardTokenNumber: this.props.CardTokenNumber,
				CardholderName: this.state.model.CardholderName,
				CustomerName: this.state.model.CustomerName
			},
			responseData => {
				this.setState({ isLoading: false });
				if (responseData.Item != null) {
					this.setState({
						dialog: {
							isOpen: true,
							dialogData: responseData.Item
						}
					});
				} else {
					this.setState({ dialogData: [] });
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			});
	}

	HandleSelectModel = (model, index) => {
		this.setState({ selected: index });
		this.props.FillCallback(model);
		this.setState({
			dialog: {
				isOpen: false,
				dialogData: []
			},
			selected: -1
		});
	};

	CancelSelectCallback = () => {
		this.setState({
			dialog: {
				isOpen: false,
				dialogData: []
			},
			model: {},
			selected: -1
		});
	};

	RowSelected = (index) => {
		const model = this.state.dialog.dialogData[index];
		this.HandleSelectModel(model, index);
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: null })} OnConfirm={onConfirm} />
		});
	}

	render() {
		const { classes, LabelMd, Disabled, Required, IsInvalid } = this.props;
		const { model, dialog, isLoading, tempModel } = this.state;

		return (
			<>

				<LoadingComponent Show={isLoading} />

				<GenericTextInput
					Name="CardTokenNumber"
					LabelMd={LabelMd}
					LabelText="Card Token Number"
					KeyPressed={this.HandleKeyPress}
					Blur={this.HandleClickByKeyPressed}
					Format={"@@@@ @@@@ @@@@ @@@@"}
					inputProps={this.maxLength19}
					Value={this.props.CardTokenNumber}
					ValueChanged={this.props.HandleChange}
					EndIconName="search"
					// ClearIconName="clear"
					IconOnClick={this.HandleClick}
					// ClearOnClick={this.HandleClearClick}
					Disabled={Disabled}
					Required={Required}
					IsInvalid={IsInvalid} />

				<GenericDialog open={dialog.isOpen} onBackdropClick={this.CancelSelectCallback} maxWidth="md" fullWidth keepMounted={true}>
					<DialogTitle><GenericLabel FontSize="16px" Text="Select Card" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ marginTop: 8 }}>
						<GridContainer>
							<GridItem md={6} xs={12}>
								<GenericTextInput
									Name="CardholderName"
									IsText
									LabelText="Cardholder Name"
									Value={model.CardholderName}
									ValueChanged={this.HandleChange} />
							</GridItem>
							<GridItem md={6} xs={12}>
								<GenericTextInput
									Name="CustomerName"
									LabelText="Customer Name"
									Value={model.CustomerName}
									ValueChanged={this.HandleChange} />
							</GridItem>
						</GridContainer>
						<GridContainer>
							<GridItem md={12} xs={12}>
								<br />
								<GenericGrid
									Data={dialog.dialogData}
									Columns={this.columns}
									RowSelected={this.RowSelected}
									SelectedIndex={this.state.selected}
									PageSize={10}
									ShowPagination={true}
									HideButton={true}
								/>
							</GridItem>
						</GridContainer>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.HandleClickInside}>Search</Button>
						<Button size="sm" onClick={this.CancelSelectCallback}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>
			</>
		);
	}
}

CardComponent.propTypes = {
	CardTokenNumber: PropTypes.any,
	HandleChange: PropTypes.func,
	FillCallback: PropTypes.func,
	LabelMd: PropTypes.number,
	UniqueClientId: PropTypes.number,
	ClientCustomerId: PropTypes.number,
	Disabled: PropTypes.bool,
	MenuCode: PropTypes.string,
};

export default withArtifex(CardComponent, {});