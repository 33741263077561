import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import { EditIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericCheckInput, GenericGrid, GenericNumberInput, GenericTextInput, GenericTitle, GenericSelectInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton.jsx";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { GridColumnType, MenuCodes } from "views/Constants/Constant";

class CardholderTransactionLimit extends React.Component {
	constructor(props) {
		super(props);

		this.emptyProfile = {
			Id: 0,
			IsRecordValid: true,
			ProfileName: null,
			IsDefaultProfile: false,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined
		};

		this.emptyDetail = {
			Index: null,
			Id: 0,
			IsRecordValid: true,
			TransactionGroupId: null,
			TransactionGroup: null,
			OneTimeMaxAmount: null,
			HasDailyLimit: false,
			DailyLimitAmount: null,
			DailyLimitCount: null,
			HasWeeklyLimit: false,
			WeeklyLimitAmount: null,
			WeeklyLimitCount: null,
			HasMonthlyLimit: false,
			MonthlyLimitAmount: null,
			MonthlyLimitCount: null,
		}

		this.initialModel = {
			SelectedLimitProfile: Object.assign({}, this.emptyProfile),
			SelectedTransactionLimit: Object.assign({}, this.emptyDetail),
			ProfileList: [],
			DetailList: [],
		};

		this.IsDefaultProfile = false;
		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : -1,
		};

		this.LimitItemColumns = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip="Edit"
							Icon={EditIcon}
							Disabled={props.Disabled}
							OnClick={() => { this.HandleSelectDetail(row.original, row.index); }} />
					</div>
				),
				sortable: false,
				filterable: false,
				resizable: false,
				width: 50
			},
			{
				Header: "Limit Profile Name",
				accessor: "CardTransactionLimitProfile.ProfileName"
			},
			{
				Header: "Transaction Code",
				accessor: "TransactionGroup.ParameterCode"
			},
			{
				Header: "Transaction Name",
				accessor: "TransactionGroup.ParameterDesc"
			},
			{
				Header: "One Time Max Amount",
				accessor: "OneTimeMaxAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Has Daily Limit",
				Cell: row => (<div>{row.original.HasDailyLimit ? "✓" : "❌"}</div>),
			},
			{
				Header: "Daily Limit Amount",
				accessor: "DailyLimitAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Daily Limit Count",
				accessor: "DailyLimitCount"
			},
			{
				Header: "Has Weekly Limit",
				Cell: row => (<div>{row.original.HasWeeklyLimit ? "✓" : "❌"}</div>),
			},
			{
				Header: "Weekly Limit Amount",
				accessor: "WeeklyLimitAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Weekly Limit Count",
				accessor: "WeeklyLimitCount"
			},
			{
				Header: "Has Monthly Limit",
				Cell: row => (<div>{row.original.HasMonthlyLimit ? "✓" : "❌"}</div>),
			},
			{
				Header: "Monthly Limit Amount",
				accessor: "MonthlyLimitAmount"
			},
			{
				Header: "Monthly Limit Count",
				accessor: "MonthlyLimitCount"
			}
		];

		this.state = {
			model: Object.assign({}, this.initialModel),
			isLoading: false,
			vModel: {},
			vModelDetail: {}
		};
	};

	toArray = (value) => {
		var returnList = [];
		if (value != null && value[0]) {
			for (const key in value) {
				if (value.hasOwnProperty(key)) {
					const element = value[key];
					returnList.push(element);
				}
			}
		}
		return returnList;
	};

	componentDidMount() {
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");

		switch (this.props.MenuCode) {
			case MenuCodes.Menu_ClientTransactionLimitProfile:
				this.IsDefaultProfile = false;
				this.props.setAppLeftTitle("Client Transaction Limit Profile");
				break;
			case MenuCodes.Menu_LimitProfile:
				this.IsDefaultProfile = false;
				this.props.setAppLeftTitle("Transaction Limit Profile");
				break;
			case MenuCodes.Menu_DCLimitProfile:
				this.IsDefaultProfile = true;
				this.props.setAppLeftTitle("Transaction Limit Profile");
				break;
			default:
				break;

		}
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		if (name === "LimitProfileName") {
			model.SelectedLimitProfile.ProfileName = newValue;
		} else if (name == "UniqueClientId") {
			model.SelectedLimitProfile.UniqueClientId = newValue;
		} else if (name == "TransactionGroup") {
			model.SelectedTransactionLimit.TransactionGroupId = newValue;
			model.SelectedTransactionLimit.TransactionGroup = data;
		} else if (name == "OneTimeMaxAmount") {
			model.SelectedTransactionLimit.OneTimeMaxAmount = newValue;
		} else if (name == "HasDailyLimit") {
			model.SelectedTransactionLimit.HasDailyLimit = newValue;
		} else if (name == "DailyLimitAmount") {
			model.SelectedTransactionLimit.DailyLimitAmount = newValue;
		} else if (name == "DailyLimitCount") {
			model.SelectedTransactionLimit.DailyLimitCount = newValue;
		} else if (name == "HasWeeklyLimit") {
			model.SelectedTransactionLimit.HasWeeklyLimit = newValue;
		} else if (name == "WeeklyLimitAmount") {
			model.SelectedTransactionLimit.WeeklyLimitAmount = newValue;
		} else if (name == "WeeklyLimitCount") {
			model.SelectedTransactionLimit.WeeklyLimitCount = newValue;
		} else if (name == "HasMonthlyLimit") {
			model.SelectedTransactionLimit.HasMonthlyLimit = newValue;
		} else if (name == "MonthlyLimitAmount") {
			model.SelectedTransactionLimit.MonthlyLimitAmount = newValue;
		} else if (name == "MonthlyLimitCount") {
			model.SelectedTransactionLimit.MonthlyLimitCount = newValue;
		}

		this.setState({ model });
	};

	HandleClear = () => {
		this.setState({
			model: {
				SelectedLimitProfile: Object.assign({}, this.emptyProfile),
				SelectedTransactionLimit: Object.assign({}, this.emptyDetail),
				ProfileList: [],
				DetailList: [],
				vModel: {},
				vModelDetail: {}
			}
		});
	};

	HandleSelectProfile = (data, index) => {
		const model = { ...this.state.model };

		var LimitProfile = Object.assign({}, data.Profile);
		var LimitItemList = Object.assign({}, data.LimitList);

		model.SelectedLimitProfile = LimitProfile;
		model.SelectedTransactionLimit = Object.assign({}, this.emptyDetail);
		model.DetailList = this.toArray(LimitItemList);

		this.setState({ model });
	};

	HandleSelectDetail = (data, index) => {
		const model = { ...this.state.model };


		model.SelectedTransactionLimit = Object.assign({}, data);
		model.SelectedTransactionLimit.Index = index;

		this.setState({ model });
	};

	HandleAddDetail = () => {
		const model = { ...this.state.model };

		var newItem = Object.assign({}, model.SelectedTransactionLimit);

		if (!this.ValidateNewDetail(newItem, model.DetailList)) {
			return;
		}

		if (!newItem.HasDailyLimit) {
			newItem.DailyLimitAmount = 0;
			newItem.DailyLimitCount = 0;
		}
		if (!newItem.HasWeeklyLimit) {
			newItem.WeeklyLimitAmount = 0;
			newItem.WeeklyLimitCount = 0;
		}
		if (!newItem.HasMonthlyLimit) {
			newItem.MonthlyLimitAmount = 0;
			newItem.MonthlyLimitCount = 0;
		}

		var temporary = [];
		if (newItem.Id == 0) {
			newItem.ProfileId = model.SelectedLimitProfile.Id;
			newItem.CardTransactionLimitProfile = model.SelectedLimitProfile;

			if (newItem.Index != null) {
				for (let index = 0; index < model.DetailList.length; index++) {
					const element = model.DetailList[index];
					temporary.push(index == newItem.Index ? newItem : element);
				}
			} else {
				model.DetailList.forEach((value, index, array) => { temporary.push(value); });
				temporary.push(newItem);
			}
		} else {
			model.DetailList.forEach(element => {
				if (element.Id == newItem.Id) {
					temporary.push(newItem);
				} else {
					temporary.push(element);
				}
			});
		}

		model.SelectedTransactionLimit = Object.assign({}, this.emptyDetail);
		model.DetailList = temporary;
		this.setState({ model });

	}

	HandleDeleteDetail = () => {
		const model = { ...this.state.model };

		var newItem = Object.assign({}, model.SelectedTransactionLimit);

		var temporary = [];
		if (newItem.Id != 0) {
			newItem.IsRecordValid = false;
			model.DetailList.forEach(element => {
				if (element.Id != newItem.Id) { temporary.push(element); }
			});
		} else {
			for (let index = 0; index < model.DetailList.length; index++) {
				const element = model.DetailList[index];
				if (index != newItem.Index) { temporary.push(element); }
			}
		}
		model.SelectedTransactionLimit = Object.assign({}, this.emptyDetail);
		model.DetailList = temporary;
		this.setState({ model });
		this.props.showMessage("success", "Success", "Limit Definition deleted.");

	}

	HandleClearDetail = () => {
		const model = { ...this.state.model };
		model.SelectedTransactionLimit = Object.assign({}, this.emptyDetail);
		this.setState({ model });
	}

	HandleSearch = async () => {
		const model = { ...this.state.model };
		const { ExecuteApiPost } = this.props;

		const request = {
			ProfileName: model.SelectedLimitProfile.ProfileName,
			UniquClientId: model.SelectedLimitProfile.UniqueClientId,
			IsDefaultProfile: this.IsDefaultProfile
		};
		var result = await ExecuteApiPost("/prepaidapi/v1.0/CardTransactionLimitProfile/SearchProfile", request);
		// model.SelectedLimitProfile = Object.assign({}, this.emptyProfile);
		model.SelectedTransactionLimit = Object.assign({}, this.emptyDetail);
		model.ProfileList = result;
		model.DetailList = [];
		this.setState({ model });

	};
	successCallback = (result) => {
		const model = { ...this.state };
		model.SelectedLimitProfile = result.Profile;
		model.SelectedTransactionLimit = Object.assign({}, this.emptyDetail);
		model.ProfileList = [];
		model.ProfileList.push(result);
		model.DetailList = result.LimitList;
		this.props.showMessage("success", "Success", "The operation successfully completed.");
		this.HandleSearch();
		this.setState({ model });

	}

	errorCallback = (error) => {
		this.setState({ isLoading: false });
		this.props.showMessage("error", "Error", "An error occurred during the api visit" + error);
	}

	successDeleteCallback = (responseData) => {
		this.setState({
			model: {
				SelectedLimitProfile: Object.assign({}, this.emptyProfile),
				SelectedTransactionLimit: Object.assign({}, this.emptyDetail),
				ProfileList: [],
				DetailList: [],
			}
		});
		this.props.showMessage("success", "Success", "The operation successfully completed.");

	}



	HandleSubmit = async () => {
		const model = { ...this.state.model };
		const { ExecuteApiPost } = this.props;

		var callUrl =
			model.SelectedLimitProfile.Id != 0 ?
				"/prepaidapi/v1.0/CardTransactionLimitProfile/Update" :
				"/prepaidapi/v1.0/CardTransactionLimitProfile/Insert"

		var requestList = [];
		if (model.SelectedLimitProfile.Id != 0) {
			var originalDetail = model.ProfileList.find(x => x.Profile.Id == model.SelectedLimitProfile.Id).LimitList;
			var originalDetailList = this.toArray(originalDetail);

			model.DetailList.forEach(element => {
				// added list
				if (element.Id == 0) { requestList.push(element); }
				// modified list
				if (element.Id != 0) { requestList.push(element); }
			});

			// deleted list
			originalDetailList.forEach(element => {
				var relatedItem = model.DetailList.find(x => x.Id == element.Id);
				if (relatedItem == null) {
					var deletedItem = Object.assign({}, element);
					deletedItem.IsRecordValid = false;
					requestList.push(deletedItem);
				}
			});
		} else {
			model.DetailList.forEach(element => {
				// added list
				requestList.push(element);
			});
		}

		const request = {
			ProfileName: model.SelectedLimitProfile.ProfileName,
			Id: model.SelectedLimitProfile != null ? model.SelectedLimitProfile.Id : null,
			IsRecordValid: true,
			DetailList: requestList,
			UniqueClientId: model.SelectedLimitProfile.UniqueClientId,
			IsDefaultProfile: this.IsDefaultProfile
		};
		var result = await ExecuteApiPost(callUrl, request);
		if (result != null)
			this.successCallback(result);
	};

	HandleDelete = async () => {
		const model = { ...this.state.model };
		const { ExecuteApiPost } = this.props;

		const request = {
			ProfileName: model.SelectedLimitProfile.ProfileName,
			Id: model.SelectedLimitProfile != null ? model.SelectedLimitProfile.Id : null,
			IsRecordValid: false,
			IsDefaultProfile: this.IsDefaultProfile
		};

		var result = await ExecuteApiPost("/prepaidapi/v1.0/CardTransactionLimitProfile/Delete", request);
		if (result != null)
			this.successDeleteCallback()
	};

	ValidateSubmit = () => {
		var errorList = [];
		const { model, vModel } = this.state;
		const { showValidationErrors } = this.props;

		vModel.ProfileName = model.SelectedLimitProfile == null
			|| model.SelectedLimitProfile.ProfileName == null
			|| model.SelectedLimitProfile.ProfileName === "";

		if (vModel.ProfileName) {
			errorList.push("Please fill Profile Name field.");
		}

		vModel.UniqueClientId = this.IsDefaultProfile == false && (model.SelectedLimitProfile == null || model.SelectedLimitProfile.UniqueClientId <= 0 || !model.SelectedLimitProfile.UniqueClientId);
		if (vModel.UniqueClientId) {
			errorList.push("Please fill Client field.");
		}

		if (errorList.length > 0) {
			showValidationErrors(errorList);
			return false;
		}
		return true;
	}

	ValidateNewDetail = (newItem, allDetail) => {
		var errorList = [];
		const { model, vModelDetail, vModel } = this.state;
		const { showValidationErrors } = this.props;

		vModel.ProfileName = model.SelectedLimitProfile == null
			|| model.SelectedLimitProfile.ProfileName == null
			|| model.SelectedLimitProfile.ProfileName === "";
		if (vModel.ProfileName) {
			errorList.push("Please fill Profile Name field.");
		}


		if (newItem.TransactionGroupId == null) {
			errorList.push("Please fill in the Transaction Name field.");
			vModelDetail.TransactionGroupId = true;
		}
		else {
			vModelDetail.TransactionGroupId = false;
		}
		if (newItem.Id == 0) {
			if (newItem.Index != null) {
				for (let index = 0; index < allDetail.length; index++) {
					const element = allDetail[index];
					if (index != newItem.Index && element.TransactionGroupId == newItem.TransactionGroupId) {
						errorList.push("Please fill in the Transaction Name field with different value.");
					}
				}
			} else {
				allDetail.forEach((value, index, array) => {
					if (value.TransactionGroupId == newItem.TransactionGroupId) {
						errorList.push("Please fill in the Transaction Name field with different value.");
					}
				});
			}
		} else {
			allDetail.forEach(element => {
				if (element.Id != newItem.Id && element.TransactionGroupId == newItem.TransactionGroupId) {
					errorList.push("Please fill in the Transaction Name field with different value.");
				}
			});
		}

		if (errorList.length > 0) {
			showValidationErrors(errorList);
			return false;
		}
		return true;
	}

	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}

	render() {
		const { Disabled } = this.props;
		const { model, isLoading, alert, vModel, vModelDetail } = this.state;
		var IsClient = ClientHelper.IsClient();
		return (
			<div>

				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Submit",
							OnClick: this.HandleSubmit,
							Disabled: Disabled || !(!model.SelectedLimitProfile || !model.SelectedLimitProfile.Id) || IsClient,
							ValidationFunction: this.ValidateSubmit
						},
						{
							Code: "Update",
							OnClick: this.HandleSubmit,
							Disabled: Disabled || !(model.SelectedLimitProfile && model.SelectedLimitProfile.Id) || IsClient,
							ValidationFunction: this.ValidateSubmit
						},
						{
							Code: "Delete",
							OnClick: this.HandleDelete,
							Disabled: Disabled || !(model.SelectedLimitProfile && model.SelectedLimitProfile.Id) || IsClient
						},
						{
							Code: "Search",
							OnClick: this.HandleSearch,
							Disabled: Disabled || model.Id > 0
						},
						{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled }
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card>
							<CardBody>
								<GridContainer>
									{!this.IsDefaultProfile &&
										<GridItem xs={4}>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={this.parameterUniqueClientId}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={this.RenderItemUniqueClient}
												Value={model.SelectedLimitProfile.UniqueClientId}
												ValueChanged={this.HandleChange}
												CanClear
												Disabled={IsClient}
												DefaultIndex={IsClient ? 0 : -1}
												Required
												IsInvalid={model.vUniqueClientId}
											/>
										</GridItem>
									}
									<GridItem xs={4}>
										<GridItem>
											<GenericTextInput
												inputProps={{ maxLength: 125 }}
												Name="LimitProfileName"
												LabelText="Limit Profile Name"
												Value={model.SelectedLimitProfile.ProfileName}
												ValueChanged={this.HandleChange}
												Required
												IsInvalid={vModel.ProfileName}
											/>
										</GridItem>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
						<Card>
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid Data={model.ProfileList} Columns={[
													{
														Header: "Actions",
														accessor: "Actions",
														Cell: row => (
															<div>
																<GridButton
																	tooltip="Edit"
																	Icon={EditIcon}
																	Disabled={this.props.Disabled}
																	OnClick={() => { this.HandleSelectProfile(row.original, row.index); }} />
															</div>
														),
														sortable: false,
														filterable: false,
														resizable: false,
														width: 60
													},
													{
														Header: "Profile Name",
														accessor: "Profile.ProfileName"
													},
													{
														Header: "Client Number",
														accessor: "UniqueClientId",
														show: !this.IsDefaultProfile
													},
													{
														Header: "Client Name",
														accessor: "ClientName",
														show: !this.IsDefaultProfile
													},
													{
														Header: "User",
														accessor: "Profile.InsertUser"
													},
													{
														Header: "Date",
														accessor: "Profile.InsertDateTime",
														type: GridColumnType.DateTime
													},
												]} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
						<Card>
							<CardHeader>
								<GridContainer spacing={16} alignItems="center">
									<GridItem>
										<GenericTitle text={"Limit Definition"} />
									</GridItem>
								</GridContainer>
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={3}>
										<GridItem>
											<ParameterComponent
												Name="TransactionGroup"
												LabelText="Transaction Name"
												ParameterCode="CardTransactionGroup"
												Value={model.SelectedTransactionLimit.TransactionGroupId || ""}
												ValueChanged={this.HandleChange}
												Required
												IsInvalid={vModelDetail.TransactionGroupId} />
										</GridItem>
										<GridItem>
											<GenericNumberInput
												Name="OneTimeMaxAmount"
												LabelText="One Time Max Amount"
												MaxValue={100000000}
												MaxLength={8}
												Value={model.SelectedTransactionLimit.OneTimeMaxAmount}
												ValueChanged={this.HandleChange} />
										</GridItem>
									</GridItem>
									<GridItem xs={3}>
										<GridItem>
											<GenericCheckInput
												Name="HasDailyLimit"
												LabelText="Has Daily Limit"
												Value={model.SelectedTransactionLimit.HasDailyLimit}
												ValueChanged={this.HandleChange} />
										</GridItem>
										<GridItem>
											<GenericNumberInput
												Name="DailyLimitAmount"
												LabelText="Daily Limit Amount"
												MaxValue={100000000}
												MaxLength={8}
												Value={model.SelectedTransactionLimit.DailyLimitAmount}
												ValueChanged={this.HandleChange}
												Disabled={!model.SelectedTransactionLimit.HasDailyLimit} />
										</GridItem>
										<GridItem>
											<GenericNumberInput
												Name="DailyLimitCount"
												LabelText="Daily Limit Count"
												MaxValue={100000000}
												MaxLength={8}
												Value={model.SelectedTransactionLimit.DailyLimitCount}
												ValueChanged={this.HandleChange}
												Disabled={!model.SelectedTransactionLimit.HasDailyLimit}
												DecimalScale={0}
												FixedDecimalScale={false}
											/>
										</GridItem>
									</GridItem>
									<GridItem xs={3}>
										<GridItem>
											<GenericCheckInput
												Name="HasWeeklyLimit"
												LabelText="Has Weekly Limit"
												Value={model.SelectedTransactionLimit.HasWeeklyLimit}
												ValueChanged={this.HandleChange} />
										</GridItem>
										<GridItem>
											<GenericNumberInput
												Name="WeeklyLimitAmount"
												LabelText="Weekly Limit Amount"
												MaxValue={100000000}
												MaxLength={8}
												Value={model.SelectedTransactionLimit.WeeklyLimitAmount}
												ValueChanged={this.HandleChange}
												Disabled={!model.SelectedTransactionLimit.HasWeeklyLimit} />
										</GridItem>
										<GridItem>
											<GenericNumberInput
												Name="WeeklyLimitCount"
												LabelText="Weekly Limit Count"
												MaxValue={100000000}
												MaxLength={8}
												Value={model.SelectedTransactionLimit.WeeklyLimitCount}
												ValueChanged={this.HandleChange}
												Disabled={!model.SelectedTransactionLimit.HasWeeklyLimit}
												DecimalScale={0}
												FixedDecimalScale={false}
											/>
										</GridItem>
									</GridItem>
									<GridItem xs={3}>
										<GridItem>
											<GenericCheckInput
												Name="HasMonthlyLimit"
												LabelText="Has Monthly Limit"
												Value={model.SelectedTransactionLimit.HasMonthlyLimit}
												ValueChanged={this.HandleChange} />
										</GridItem>
										<GridItem>
											<GenericNumberInput
												Name="MonthlyLimitAmount"
												LabelText="Monthly Limit Amount"
												MaxValue={100000000}
												MaxLength={8}
												Value={model.SelectedTransactionLimit.MonthlyLimitAmount}
												ValueChanged={this.HandleChange}
												Disabled={!model.SelectedTransactionLimit.HasMonthlyLimit} />
										</GridItem>
										<GridItem>
											<GenericNumberInput
												Name="MonthlyLimitCount"
												LabelText="Monthly Limit Count"
												MaxValue={100000000}
												MaxLength={8}
												Value={model.SelectedTransactionLimit.MonthlyLimitCount}
												ValueChanged={this.HandleChange}
												Disabled={!model.SelectedTransactionLimit.HasMonthlyLimit}
												DecimalScale={0}
												FixedDecimalScale={false}
											/>
										</GridItem>
									</GridItem>
									<GridItem xs={8}></GridItem>
									<GridItem xs={4}>
										<GridContainer justify="flex-end">
											<GridItem>
												<Button
													size="sm"
													onClick={this.HandleAddDetail}>{model.SelectedTransactionLimit.Index != null ? "UPDATE" : "ADD"}</Button>
												<Button size="sm"
													disabled={model.SelectedTransactionLimit.Index == null}
													onClick={this.HandleDeleteDetail}>DELETE</Button>
												<Button size="sm"
													onClick={this.HandleClearDetail}>CLEAR</Button>
											</GridItem>
										</GridContainer>
									</GridItem>
									<GridItem xs={12}>
										<GenericGrid Data={model.DetailList} Columns={this.LimitItemColumns} HideButton />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

CardholderTransactionLimit.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	HandleChange: PropTypes.func,
	menuId: PropTypes.number,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default withArtifex(CardholderTransactionLimit, {});
