import { DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericIcon, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { CrudType, ProgramCodes } from "views/Constants/Constant";
import Formatter from "core/Formatter";
import DateHelper from "core/DateHelper";


class CreateAccountWithBulkUpload extends React.Component {
	constructor(props) {
		super(props);

		this.fileInputRef = React.createRef();

		this.initialModel = {
		};

		this.state = {
			list: [],
			IsResponseDialog: false,
			model: this.initialModel,
			isLoading: false,
			selected: null,
			bankAccounts: [],
			responseList: [],
			isFileResultDialogOpen: false,
			isFileDialogOpen: false,
			FileResultList: []
		};


		this.ColumnsData = [

			{
				Header: "Wallet Number",
				accessor: "AccountNumber"
			},
			{
				Header: "IBAN",
				accessor: "IBAN"
			},

			{
				Header: "Balance",
				accessor: "Balance"
			}
			,

			{
				Header: "Pending Balance",
				accessor: "PendingBalance"
			},

			{
				Header: "Wallet Name",
				accessor: "AccountName"
			},
		];


	}

	componentDidMount() {
		this.props.setAppLeftTitle("Create Bulk Wallet");
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
		//this.HandleGetList();
		// this.GetCustomerBankAccounts();
	}

	HandleChange = (name, value, data) => {
		const model = { ...this.state.model };
		model[name] = value;
		this.setState({ model });
	}

	HandleSelectModel = (model, index) => {
		this.setState({ model, selected: index });
		//	this.HandleGetList();
	}

	HandleClear = () => {
		const { model } = { ...this.state };
		delete model.File;
		delete model.Id;
		delete model.FileName;
		delete model.Binary;
		delete model.TempFileId;
		delete model.FileSelect;

		this.setState({ model });
	}

	HandleSubmitDialog = () => {
		if (!this.Validate())
			return;

		this.setState({ isFileDialogOpen: true });
	}

	HandleSubmit = () => {
		if (!this.Validate())
			return;

		this.setState({ isLoading: true });
		const { model } = { ...this.state };
		var formData = new FormData();
		formData.append("file", model.File, model.FileName);
		formData.append("fileId", model.TempFileId || 0);
		formData.append("isBinaryOrBase64", model.Binary);
		formData.append("isInsertOrUpdate", model.Id == null ? CrudType.Insert : CrudType.Update);

		Proxy.FileUpload("/bankapi/v1.0/Account/CreatePrepaidAccount",
			formData,
			(responseData) => {
				if (!responseData.IsSucceeded && responseData.Item < 1) {
					this.setState({ isLoading: false, isFileDialogOpen: false });
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				else {

					Proxy.POST("/bankapi/v1.0/Account/GetFileInformation",
						{ Id: responseData.Item.FileId },
						fileResponseData => {
							if (!fileResponseData.IsSucceeded) {
								this.setState({ isLoading: false, isFileDialogOpen: false });

								this.props.showMessage("error", "An error occurred while load file information", fileResponseData.ErrorDescription);
								return;
							}

							this.setState({ FileResultList: fileResponseData.Item, isFileResultDialogOpen: true, isLoading: false, isFileDialogOpen: false });

						},
						errorMessage => {
							this.setState({ isLoading: false, isFileDialogOpen: false });
							this.props.showMessage("error", "An error occurred while load file information", errorMessage);
						}
					);
					//	this.setState({ model: this.initialModel, responseList: responseData.Item, isFileChanged: true, IsResponseDialog: true, isLoading: false, });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while sending data", error.message);
			}
		);
	}

	FileSelect = (e) => {

		this.setState({ isLoading: true })
		e.preventDefault();

		var file = e.target.files[0];
		if (!file) {
			return;
		}

		var { model } = this.state;
		model.TempFileId = model.File !== undefined
			? model.File.Id
			: 0;
		model.File = file;
		model.FileName = file.name;
		model.FileSelect = true;

		this.setState({ model, isLoading: false });


	}


	Validate = () => {
		const model = this.state.model;

		if (!model.File) {
			this.ShowMessage("warning", "Invalid", "Please select file !");
			return;
		}
		return true;
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: "" })} OnConfirm={onConfirm} />
		});
	}

	Upload = () => {
		this.fileInputRef.current.click();
	}
	render() {
		const {  Disabled } = this.props;
		const { alert, model,  isLoading, IsResponseDialog, isFileResultDialogOpen, FileResultList, isFileDialogOpen } = this.state;


		return (
			<div>

				<LoadingComponent Show={isLoading} />

				{alert}

				<input
					type="file"
					key={model.FileName}
					style={{ display: "none" }}
					onChange={this.FileSelect}
					ref={this.fileInputRef}
					accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel" />


				<GenericDialog open={isFileDialogOpen} maxWidth="md" fullWidth>
					<DialogTitle>
						<GenericLabel Text="Transaction Confirmation" Bold={true} />
					</DialogTitle>
					<DialogContent>
						<GenericTextInput Name="FileName" LabelText="File Name" Value={model.FileName} Disabled={true} />
						<GenericTextInput Name="Date" LabelText="Date" Value={Formatter.FormatDate(DateHelper.GetDateTimeLocal())} Disabled={true} />
						<br />
						<GenericLabel Text="Are you sure you want to upload file ?" Bold={true} />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.HandleSubmit}>Confirm</Button>
						<Button size="sm" onClick={() => this.setState({ isFileDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>


				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
					{ Code: "Submit", OnClick: this.HandleSubmitDialog, Disabled: Disabled },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />


				<GenericDialog open={IsResponseDialog} maxWidth="md" fullWidth>
					<DialogTitle>
						<GenericLabel Text="Transaction Information" Bold={true} />
					</DialogTitle>
					<DialogContent>
						<GenericGrid
							Data={this.state.responseList}
							Columns={[

								{
									Header: "Client Customer Number",
									accessor: "ClientCustomerNumber"
								},
								{
									Header: "Amount",
									accessor: "Amount"
								},
								{
									Header: "Description",
									accessor: "Description"
								},
								{
									Header: "Status",
									accessor: "Status"
								},
								{
									Header: "Status Description",
									accessor: "StatusDescription"
								},

							]}
							ShowPagination={true}
							SelectedIndex={this.state.rowIndex}
							ProgramCode={ProgramCodes.Prepaid} />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={() => this.setState({ IsResponseDialog: false })}>OK</Button>
					</GenericDialogActions>
				</GenericDialog>

				<GenericDialog open={isFileResultDialogOpen} maxWidth="lg" fullWidth>
					<DialogTitle>
						<GridContainer justify="space-between" alignItems="center">
							<GridItem>
								<GenericLabel FontSize="16px" TextColor="black" Text="File Upload Process Done" Bold={true} />
							</GridItem>
							<GridItem>
								<IconButton onClick={() => this.setState({ isFileResultDialogOpen: false })}>
									<GenericIcon>close</GenericIcon>
								</IconButton>
							</GridItem>
						</GridContainer>
					</DialogTitle>
					<DialogContent>
						<GenericGrid
							Columns={[
								{
									Header: "Line Number",
									accessor: "LineId"
								},
								{
									Header: "Is Rejected",
									accessor: "IsRejected",
									Cell: row => row.value ? "Yes" : "No"
								},
								{
									Header: "Comment",
									accessor: "Comment"
								},
								{
									Header: "Wallet Owner Name",
									accessor: "AccountOwnerName"
								}, {
									Header: "Wallet Name",
									accessor: "AccountName"
								}, {
									Header: "Customer Number",
									accessor: "CustomerNumber"
								},
							]}
							Data={FileResultList}
							Sorted={[{ id: "LineId", desc: false }]}
						/>
					</DialogContent>
				</GenericDialog>


				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<GenericExpansionPanel Title="Transaction">
								<CardBody>
									<GridContainer>


										<GridItem>
											<GenericLabel Text="Upload File" />
										</GridItem>
										<GridItem>
											<Button size="sm" onClick={this.Upload}>BROWSE</Button></GridItem>
									</GridContainer>
								</CardBody>
							</GenericExpansionPanel>
						</Card>
						{/* <Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel
									Title="Wallet List">
									<GridContainer>
										<GridItem xs={12}>
											<GenericGrid
												Data={this.state.list}
												Columns={this.ColumnsData}
												SelectedIndex={this.state.rowIndex}
												ShowPagination={true}
												ProgramCode={ProgramCodes.Prepaid} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>*/}
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

CreateAccountWithBulkUpload.propTypes = {
	classes: PropTypes.object.isRequired
};
export default withArtifex(CreateAccountWithBulkUpload, {});