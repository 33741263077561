import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import ClientHelper from "core/ClientHelper";
import withArtifex from "core/withArtifex";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDialog, GenericLabel, GenericRadioInput, GenericDialogActions } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { DocumentType, GetType } from "views/Constants/Constant";
import GenericFormScreen from "./GenericFormScreen";
import GenericListScreen from "./GenericListScreen";
import DateHelper from "core/DateHelper";
import { DialogContent, DialogTitle } from "@material-ui/core";
import Select from "react-select";
import Button from "components/CustomButtons/Button";
import ResourceHelper from "../../../core/ResourceHelper";

class GenericScreen extends React.Component {
	constructor(props) {
		super(props);

		this.initialViewData = {
			GridColumns: [],
			Inputs: []
		};
		this.initialModel = {
			Id: 0,
			DateFilter: {}
		};
		this.action = null;

		this.state = {
			alert: null,
			checkedValues: [],
			isLoading: false,
			list: [],
			model: { ...this.initialModel },
			vModel: {},
			selected: null,
			viewData: { ...this.initialViewData },
			uniqueClientId: null,
			isColumnsLoaded: false
		};

		this.SetButton = this.SetButton.bind(this);
	}

	componentDidMount() {
		var user = ClientHelper.GetUser();
		if (user != null) {
			this.setState({ uniqueClientId: user.UniqueClientId });
		}
		this.setState({ isLoading: true });
		Proxy.POST(
			"/coreapi/v1.0/Screen/GetById",
			{
				Id: this.props.screenId,
				MenuId: this.props.menuId
			},
			this.ScreenCallback,
			this.ErrorCallback
		);
	}

	ValueChanged = (name, newValue) => {
		this.setState(state => {
			var model = state.model || {};
			model[name] = newValue;
			return { model };
		});
	}

	ValueChecked = (e, x) => {
		this.setState(state => ({
			checkedValues: state.checkedValues.includes(x)
				? state.checkedValues.filter(c => c !== x)
				: [...state.checkedValues, x]
		}));
	}

	Validate = () => {
		const { model, viewData, vModel } = this.state;
		var messages = [];

		viewData.Inputs.map((input, i) => {
			if (input.InputType === "date" && model[input.Name] != null && model[input.Name] != "" && typeof model[input.Name] != "object") {
				messages.push(<div key={i}>{input.LabelText ? input.LabelText : input.Name} is invalid<br /></div>);
				vModel[input.Name] = false;
			}
			if (input.IsRequired) {
				if (!model[input.Name]) {
					messages.push(<div key={i}>{input.LabelText ? input.LabelText : input.Name} cannot be empty<br /></div>);
					vModel[input.Name] = true;
				} else {
					vModel[input.Name] = false;
				}
			}
			return null;
		});

		this.setState({ vModel });

		if (messages.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		return true;
	}

	ValidateForSearch = () => {
		const { model, viewData, vModel } = this.state;
		var messages = [];

		viewData.Inputs.map((input, i) => {
			if (input.InputType === "date" && model[input.Name] != null && model[input.Name] != "" && typeof model[input.Name] != "object") {
				messages.push(<div key={i}>{input.LabelText ? input.LabelText : input.Name} is invalid<br /></div>);
				vModel[input.Name] = false;
			}
			return null;
		});

		this.setState({ vModel });

		if (messages.length > 0) {
			this.ShowMessageNode("warning", "Please Fill Required Fields", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		return true;
	}

	ErrorCallback = (error) => {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", error.message);
	}

	HandleSelectModel = (model, index) => {
		this.setState({ model, selected: index });
	}

	HandleClear = () => {
		this.setState({ model: { ...this.initialModel }, list: [], selected: null });
	}

	ScreenCallback = (responseData) => {
		this.setState({ isLoading: false });
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error", responseData.ErrorDescription);
			return;
		}
		if (responseData.Item != null) {
			this.setState({ viewData: JSON.parse(responseData.Item.Data), isColumnsLoaded: true });

			this.state.viewData.HeaderText = ResourceHelper.CorrectAccountNames(this.state.viewData.CenterTitle);
			this.state.viewData.HeaderTableText = ResourceHelper.CorrectAccountNames(this.state.viewData.CenterTitle);

			if (this.props.setAppLeftTitle){
				this.state.viewData.LeftTitle = ResourceHelper.CorrectAccountNames(this.state.viewData.LeftTitle);
				this.props.setAppLeftTitle(this.state.viewData.LeftTitle);
			}
			if (this.props.setAppCenterTitle){
				this.state.viewData.CenterTitle = ResourceHelper.CorrectAccountNames(this.state.viewData.CenterTitle);
				this.props.setAppCenterTitle(this.state.viewData.CenterTitle);
			}

			this.state.viewData.Inputs.forEach(input => {
				if (input.InputType == "client") {
					this.ValueChanged(input.Name, this.state.uniqueClientId);
					this.initialModel[input.Name] = this.state.uniqueClientId;
				}
				if (input.InputType.includes("date") && input.HaveDefaultValue) {
					var date = input.IncludeTime ? DateHelper.GetDateTime() : DateHelper.GetDate();
					if (input.Utc)
						date = input.IncludeTime ? DateHelper.GetLocalDateTimeAsUtc() : DateHelper.GetLocalDateAsUtc();

					this.ValueChanged(input.Name, date);
					this.initialModel[input.Name] = date;
				}
				if (input.DefaultValue !== undefined) {
					this.ValueChanged(input.Name, input.DefaultValue);
					this.initialModel[input.Name] = input.DefaultValue;
				}
			});
		} else {
			this.setState({ viewData: this.initialViewData });
		}
	}

	HandleCustomClick = (URL, method, action, value, type = GetType.BYPARAMETER) => {
		if (!this.ValidateForSearch())
			return;

		this.setState({ isLoading: true });
		this.action = action;
		if (method == "POST") {
			Proxy.POST(
				URL,
				value ? value : type == GetType.ALL ? this.initialModel : this.state.model,
				this.CustomListCallback,
				this.ErrorCallback
			);
		} else {
			Proxy.GET(
				URL,
				this.CustomListCallback,
				this.ErrorCallback
			);
		}
	}

	HandleGetList = (type = GetType.BYPARAMETER) => {
		if (!this.ValidateForSearch())
			return;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/coreapi/v1.0/dynamic/do",
			{
				operation: "GetByParameter",
				screenId: this.props.screenId,
				value: type == GetType.ALL ? this.initialModel : this.state.model,
				isReport: this.state.viewData.IsReport
			},
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null && responseData.Item.length > 0) {
					this.setState({ list: responseData.Item });
					if (this.state.viewData.IsAutoFill)
						this.HandleSelectModel(this.state.list[0], !this.state.viewData.IsNotFillFromGrid ? 0 : null);
				} else {
					this.setState({ list: [] });
				}
			},
			this.ErrorCallback
		);
	}

	CustomListCallback = (responseData) => {
		this.setState({ isLoading: false });
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error", responseData.ErrorDescription);
			return;
		}
		if (responseData.Item != null && responseData.Item.length > 0) {
			this.setState({ list: responseData.Item });
			if (this.state.viewData.IsAutoFill)
				this.HandleSelectModel(this.state.list[0], !this.state.viewData.IsNotFillFromGrid ? 0 : null);
		} else {
			this.setState({ list: [] });
			if (!this.state.viewData.IsReport && this.action !== "Search")
				this.ShowMessage("success", "Operation is successfully!");
		}
	}

	HandleInsert = (workflowId, after, accessToken) => {
		if (!this.Validate())
			return;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/coreapi/v1.0/dynamic/do",
			{
				operation: "Insert",
				screenId: this.props.screenId,
				value: this.state.model
			},
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					this.setState({ model: responseData.Item });
				}
				if (after) after();
				this.ShowMessage("success", "Added successfully!");
			},
			this.ErrorCallback,
			workflowId,
			accessToken
		);
	}

	HandleUpdate = (workflowId, after, accessToken) => {
		if (!this.Validate())
			return;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/coreapi/v1.0/dynamic/do",
			{
				operation: "Update",
				screenId: this.props.screenId,
				value: this.state.model
			},
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					this.setState({ model: responseData.Item });
				}
				if (after) after();
				this.ShowMessage("success", "Updated successfully!");
			},
			this.ErrorCallback,
			workflowId,
			accessToken
		);
	}

	HandleDelete = (workflowId, after, accessToken) => {
		this.ShowConfirmMessage("warning", "Delete", "Are you sure to delete this record?", () => {
			this.setState({ isLoading: true, alert: "" });
			Proxy.POST(
				"/coreapi/v1.0/dynamic/do",
				{
					operation: "Delete",
					screenId: this.props.screenId,
					value: { Id: this.state.model.Id }
				},
				responseData => {
					this.setState({ isLoading: false });
					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					if (after) after();
					var { list } = this.state;
					var index = list.findIndex(x => x.Id == this.state.model.Id);
					if (index > -1) {
						list.splice(index, 1);
					}
					this.setState({ model: { ...this.initialModel }, list, selected: null });
					this.ShowMessage("success", "Deleted successfully!");
				},
				this.ErrorCallback,
				workflowId,
				accessToken
			);
		});
	}

	GetElementValue = (root, name) => {
		if (name.includes(".")) {
			var res = name.split(".");
			var firtRoot = res[0];
			name = name.substring(firtRoot.length + 1, name.length);
			return this.GetElementValue(root[firtRoot], name);
		}
		return root == null || root[name] == null ? "" : root[name];

	}

	HandleGetDocument = (type) => {
		this.setState({ isLoading: true });
		const { list, viewData } = this.state;
		var keyvalueList = [];

		list.forEach(function (listElement) {
			var keyValueObject = {};
			viewData.GridColumns.forEach(function (gridColumnElement) {
				var valueItem = this.GetElementValue(listElement, gridColumnElement.ColumnName);
				keyValueObject[gridColumnElement.ColumnName] = { value: valueItem, title: gridColumnElement.Title };
			}, this);
			keyvalueList.push(keyValueObject);
		}, this);

		var format = "PDF";
		if (type == DocumentType.Excel)
			format = "EXCEL";
		else if (type == DocumentType.CSV)
			format = "CSV";
		Proxy.POST(
			"/coreapi/v1.0/report/CreateFileFromData",
			{
				jsonFile: {
					"title": this.state.viewData.CenterTitle,
					"screenId": this.props.screenId,
					"table": keyvalueList,
					"format": format,
					value: type == GetType.ALL ? this.initialModel : this.state.model
				}
			},
			this.DocumentCallback,
			this.ErrorCallback
		);
	}

	DocumentCallback = (response) => {
		if (!response.IsSucceeded) {
			this.ShowMessage("error", "Error", "An error occurred while load file information");
			return;
		}

		this.setState({ isLoading: false });
		let a = document.createElement("a");
		a.style.display = "none";
		document.body.appendChild(a);
		a.href = "data:application/octet-stream;base64," + response.Item.value;
		if (response.Item.format == "PDF")
			a.download = "report.pdf";
		else if (response.Item.format == "EXCEL")
			a.download = "report.xlsx";
		else if (response.Item.format == "CSV")
			a.download = "report.csv";
		a.click();
	}

	CancelClick = () => {

	}

	GetButtonList = () => {
		const { model, viewData, checkedValues } = this.state;
		const { Disabled } = this.props;
		var searchButton = (viewData && viewData.ButtonOverrides) ? viewData.ButtonOverrides.find(k => k.Code === "Search") : null;
		const list = [
			{
				Code: "Search", OnClick: this.HandleGetList, Disabled: Disabled || model.Id > 0, ModelFunction: () => model, FillDataFromModel: model => this.setState({ model }),
				ValidationFunction: () => {
					if (searchButton && searchButton.IsInputRequired) {
						return this.Validate();
					}
					return true;
				}
			},
			{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
			{ Code: "Submit", OnClick: this.HandleInsert, Disabled: Disabled || model.Id > 0, ModelFunction: () => model, FillDataFromModel: model => this.setState({ model }), ValidationFunction: this.Validate },
			{ Code: "Update", OnClick: this.HandleUpdate, Disabled: Disabled || model.Id === 0, ModelFunction: () => model, FillDataFromModel: model => this.setState({ model }), ValidationFunction: this.Validate },
			{ Code: "Delete", OnClick: this.HandleDelete, Disabled: Disabled || model.Id === 0, ModelFunction: () => model, FillDataFromModel: model => this.setState({ model }), ValidationFunction: this.Validate }
		];

		if (viewData.ButtonOverrides) {
			viewData.ButtonOverrides.filter(o => !list.find(o2 => o.Code === o2.Code)).map(d => {
				list.push({
					Code: d.Code,
					OnClick: d.IsBulkOperation
						? () =>
							checkedValues.forEach((val) => {
								this.HandleCustomClick(d.URL, d.Method, d.Code, val);
							})
						: () => this.HandleCustomClick(d.URL, d.Method, d.Code),
					Disabled:
						Disabled || d.IsBulkOperation
							? Object.keys(checkedValues).length <= 0
							: false,
				});
				return null;
			});

			this.SetButton(viewData, list, "Search");
			this.SetButton(viewData, list, "Submit");
			this.SetButton(viewData, list, "Update");
			this.SetButton(viewData, list, "Delete");
			this.SetButton(viewData, list, "Export");
		}
		return list;
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: "" })} OnConfirm={onConfirm} />
		});
	}

	SetButton(viewData, list, buttonCode) {
		var ButtonObj = viewData.ButtonOverrides.find(x => x.Code == buttonCode);
		if (ButtonObj) {
			var index = list.findIndex(x => x.Code == buttonCode);
			if (ButtonObj.Hidden) {
				if (index > -1) {
					list.splice(index, 1);
				}
			}
			if (buttonCode === "Export" && ButtonObj.URL && ButtonObj.Method) {
				list[index].OnClick = () => this.HandleExport();
			}
			else if (ButtonObj.URL && ButtonObj.Method) {
				list[index].OnClick = () => this.HandleCustomClick(ButtonObj.URL, ButtonObj.Method, buttonCode);
			}
		}
		return index;
	}

	ValueChangedForReportType = (name, value) => {
		this.setState({ [name]: value });
	}

	HandleExport = () => {
		const { viewData } = this.state;
		var genericColumns = [];
		viewData.GridColumns.forEach(c => {
			if (c.accessor != "Actions" && (c.show == undefined || c.show)) {
				genericColumns.push({ value: c.ColumnName, title: c.Title, columntype: c.ColumnType, type: c.Type });
			}
		}, this);

		this.setState({ isDownloadDialogOpen: true, GridKeyValueList: genericColumns, ExportSelectedColumns: genericColumns });
	}

	ExcelDownload = () => {
		const { viewData } = this.state;
		let exportDataURL = "";
		if (viewData.ButtonOverrides) {
			viewData.ButtonOverrides.map(d => {
				if (d.Code == "Export") {
					exportDataURL = d.URL;
				}
			});
		}
		if (!this.state.ExportReportType) {
			this.ShowMessage("warning", "Export Report Type not selected", "Select Export Report Type to continue");
			return;
		}

		if (this.state.ExportReportType == "PDF" && this.state.ExportSelectedColumns.length > 15) {
			this.ShowMessage("warning", "PDF document cannot exceed 15 columns", "PDF document cannot exceed 15 columns");
			return;
		}

		this.setState({ isDownloadDialogOpen: false });

		if (!this.Validate())
			return;

		const temp = { ...this.state.model };

		var jsonColList = [];

		var genericColumns = {};
		this.state.ExportSelectedColumns.map(c => {
			genericColumns[c.value] = { value: c.value, title: c.title, columntype: c.columntype, type: c.type };
		});
		jsonColList.push(genericColumns);

		var mytitle = this.state.viewData.LeftTitle;

		temp.jsonFile = {
			colmns: jsonColList,
			format: this.state.ExportReportType,
			title: mytitle
		};

		this.setState({ isLoading: true });
		Proxy.DownloadGeneratedFile(
			exportDataURL,
			temp,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", errorMessage);
			},
			this.props.showMessage
		);
	}

	render() {
		const { alert, isLoading, viewData } = this.state;

		return (
			<div>
				<LoadingComponent Show={isLoading} />
				{alert}
				<ButtonToolbar ButtonList={this.GetButtonList()}
					menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GenericDialog open={this.state.isDownloadDialogOpen} fullWidth={true}>
					<DialogTitle>
						<GenericLabel Text="Select Report Type" FontSize="20px" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ height: 300 }}>
						<GenericRadioInput
							Name="ExportReportType"
							LabelText="Report Type"
							IsStatic={true}
							StaticData={[{ Value: "PDF", Text: "PDF" }, { Value: "CSV", Text: "CSV" }, { Value: "EXCEL", Text: "EXCEL" }]}
							KeyValueMember="Value"
							TextValueMember="Text"
							Value={this.state.ExportReportType}
							ValueChanged={this.ValueChangedForReportType}
						/>
						<GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
						<GridItem style={{ marginTop: 20 }}>
							<Select
								value={this.state.ExportSelectedColumns}
								onChange={value => this.setState({ ExportSelectedColumns: value })}
								isMulti
								name="columns"
								getOptionLabel={d => d.title}
								options={this.state.GridKeyValueList}
								className="basic-multi-select"
								classNamePrefix="select"
							/>
						</GridItem>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.ExcelDownload}>Download</Button>
						<Button size="sm" onClick={() => this.setState({ isDownloadDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>
				<GridContainer spacing={16}>
					<GridItem xs={12} >
						{!this.state.viewData.IsFilterPanelHidden &&
							<Card className="no-radius">
								<CardBody>
									<GenericFormScreen
										checkedValues={this.state.checkedValues}
										model={this.state.model}
										vModel={this.state.vModel}
										initialModel={this.initialModel}
										handleChange={this.ValueChanged}
										handleClear={this.HandleClear}
										viewData={this.state.viewData}
										disabled={this.props.Disabled} />
								</CardBody>
							</Card>
						}
						{viewData.GridColumns &&
							<Card className="no-radius">
								<CardBody>
									<GenericListScreen
										key={"genericList_" + this.state.isColumnsLoaded}
										model={this.state.model}
										list={this.state.list}
										selected={this.state.selected}
										handleSelectModel={this.HandleSelectModel}
										handleDocument={this.HandleGetDocument}
										viewData={this.state.viewData} />
								</CardBody>
							</Card>
						}
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

GenericScreen.propTypes = {
	classes: PropTypes.object.isRequired,
	screenId: PropTypes.number,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func
};

export default withArtifex(GenericScreen, {});