import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import PropTypes from "prop-types";
import React from "react";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType } from "views/Constants/Constant.js";
import City from "views/WireTransfer/Address/City";
import Country from "views/WireTransfer/Address/Country";
import Province from "views/WireTransfer/Address/Province";

class CityReport extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			ProvinceId: null,
			CountryId: null,
			CityId: null
		};

		this.state = {
			model: this.initialModel,
			vModel: {},
			list: [],
			isEdit: false,
			isLoading: false,
			index: -1
		};

		this.emptyObject = {};
		this.renderItemUniqueClient = {};
		this.maxLength40 = { maxLength: 40 };
		this.maxLength25 = { maxLength: 25 };
		this.maxLength3 = { maxLength: 3 };
		this.maxLength128 = { maxLength: 128 };
		this.Columns = [
			{
				Header: "Country",
				accessor: "Country.Name"
			},
			{
				Header: "Province",
				accessor: "Province.Name"
			},
			{
				Header: "City",
				accessor: "Name",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			}

		];
	}

	componentDidMount() {
		this.props.setAppCenterTitle("WIRE TRANSFER");
		this.props.setAppLeftTitle("City Report");
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}

	HandleClear = () => {
		this.setState({ model: { ...this.initialModel }, isEdit: false, index: -1 });
	}

	HandleSearch = async () => {
		this.setState({ isLoading: true });
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/bankapi/v1.0/WireCity/Search", this.state.model);
		this.setState({ isLoading: false });

		if (result != null) {
			this.setState({ list: result || [] });
		}
	}

	HandleSubmit = async () => {
		if (!this.Validate()) {
			return;
		}

		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/bankapi/v1.0/WireCity/Insert", this.state.model);

		if (result != null) {
			this.HandleSearch();
		}
	}

	OnModelChange = (control, Id) => {
		const { model } = { ...this.state };
		if (control == "Country")
			model.CountryId = Id;
		else if (control == "Province")
			model.ProvinceId = Id;
		else if (control == "City")
			model.CityId = Id;
		this.setState({ model });
	}
	HandleUpdate = async () => {
		if (!this.Validate()) {
			return;
		}
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/bankapi/v1.0/WireCity/Updtae", this.state.model);

		if (result != null) {
			this.HandleSearch();
		}
	}

	HandleDelete = () => {
		const { ExecuteApiPost, showConfirmMessage } = this.props;
		showConfirmMessage("warning", "Warning", "Are you sure want to delete ?", () => ExecuteApiPost("/bankapi/v1.0/WireCity/Delete", { Id: this.state.model.Id }, null, null, null, [this.HandleSearch], true));
	}

	Validate = () => {
		const { showValidationErrors } = this.props;
		const { model, vModel } = this.state;
		var Messages = [];
		vModel.CountryId = !model.CountryId;
		if (vModel.ProfileName) Messages.push("Country cannot be empty.");

		vModel.CityId = model.CityId < 0 || model.CityId == null;
		if (vModel.MinimumAmount) Messages.push("City cannot be empty");

		vModel.Name = !model.Name || model.Name == null;
		if (vModel.UniqueClientId) Messages.push("Name cannot be empty");

		if (Messages.length > 0) {
			showValidationErrors(Messages);
			return false;
		}
		else
			return true;
	}

	RowSelected = (index) => {
		var tempModel = this.state.list[index];
		this.setState({ isEdit: true, model: tempModel, index });
	}

	render() {
		const { alert, model, isLoading } = this.state;

		return (
			<div>

				<LoadingComponent Show={isLoading} />

				{alert}
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Country
							ExecuteApiPost={this.props.ExecuteApiPost}
							showConfirmMessage={this.props.showConfirmMessage}
							showValidationErrors={this.props.showValidationErrors}
							OnModelChange={this.OnModelChange}
						/>
					</GridItem>
					<GridItem xs={12}>
						<Province
							ExecuteApiPost={this.props.ExecuteApiPost}
							showConfirmMessage={this.props.showConfirmMessage}
							showValidationErrors={this.props.showValidationErrors}
							CountryId={model.CountryId}
							OnModelChange={this.OnModelChange}
						/>
					</GridItem>
					<GridItem xs={12}>
						<City
							ExecuteApiPost={this.props.ExecuteApiPost}
							showConfirmMessage={this.props.showConfirmMessage}
							showValidationErrors={this.props.showValidationErrors}
							CountryId={model.CountryId}
							ProvinceId={model.ProvinceId}
							OnModelChange={this.OnModelChange}
						/>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

CityReport.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(CityReport, {});
