import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import ClientHelper from "core/ClientHelper";
import Formatter from "core/Formatter";
import { ApproveIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericCheckInput, GenericDateInput, GenericExpansionPanel, GenericGrid, GenericSelectInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent.jsx";
import { Direction, GridColumnType } from "views/Constants/Constant";

class ChequeFacs extends React.Component {
	constructor(props) {
		super(props);
		this.initialModel = {
			UniqueClientId: ClientHelper.IsBackOffice() ? -2 : ClientHelper.GetClientRowId(),
			DirectionParameterValue: Direction.Incoming
		};
		this.columns = [
			{
				Header: "Actions",
				accessor: "Actions",
				sortable: false,
				filterable: false,
				width: 100
			},
			{
				Header: "Date",
				accessor: "Date",
				type: GridColumnType.Date
			},
			{
				Header: "Client Name",
				accessor: "ClientName"
			},
			{
				Header: "Cheque Serial Number",
				accessor: "ChequeSerialNumber"
			},
			{
				Header: "Cheque Wallet",
				accessor: "ChequeAccount"
			},
			{
				Header: "Deposit Wallet",
				accessor: "DepositAccount"
			},
			{
				Header: "Status",
				accessor: "ChequeStatus"
			},
			{
				Header: "Amount",
				accessor: "Amount",
				type: GridColumnType.Money
			},


		];
		this.emptyObject = {}
		this.sortedCustomerName = { Member: "Name" };
		this.state = {
			list: [],
			checkedValues: [],
			model: { ...this.initialModel },
			selected: null,
			isLoading: false,
			IsBackOffice: false,
			isOpenDialog: false,
		};
	}
	componentDidMount() {
		this.props.setAppLeftTitle("FACS List");
		this.props.setAppCenterTitle("CHEQUE MANAGEMENT");
		const { model } = { ...this.state }
		var { isBackOffice } = this.state;
		isBackOffice = ClientHelper.IsBackOffice();
		if (!isBackOffice) {
			model.UniqueClientId = ClientHelper.GetClientRowId();
		}
		this.setState({ model, isBackOffice });
	}
	GettingMoneyFromAccount = (item) => {



	}
	ValueChanged = (name, value) => {

		const model = this.state.model;
		model[name] = value;
		this.setState({ model });
	}

	RowSelected = (index) => {
		const { list } = this.state;
		this.setState({ model: list[index], selected: index });
	}
	DeleteConfirm = () => {
		const { showConfirmMessage } = this.props;
		showConfirmMessage("warning", "Warning", "Are you sure want to delete ?", this.Delete);
	}
	Send = () => {
	}
	HandleCheck = (e, x) => {

		this.setState(state => ({
			checkedValues: state.checkedValues.includes(x)
				? state.checkedValues.filter(c => c !== x)
				: [...state.checkedValues, x]
		}));
	}
	Search = () => {
		this.setState({ isLoading: true });
		const { model } = this.state;
		const { showMessage } = this.props;

		Proxy.POST(
			"/chequeapi/v1.0/cheque/BmoFacsGetList",
			model,
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				this.setState({ list: responseData.Item || [], isLoading: false });
			},
			(error) => {
				this.setState({ isLoading: false });
				showMessage("error", "An error occurred while requesting data", error.message);
			}
		);
	}
	Clear = () => {
		this.setState({ model: { ...this.initialModel }, list: [], selected: null });
	}
	Update = () => {

		if (!this.Validate()) {
			return;
		}

		this.setState({ isLoading: true });
		const { model } = this.state;
		const { showMessage } = this.props;

		Proxy.POST(
			"//v1.0//Update",
			model,
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				this.setState({ isLoading: false });
				showMessage("success", "Operation is successfully!");
				this.Clear();
				this.Search();
			},
			(error) => {
				this.setState({ isLoading: false });
				showMessage("error", "An error occurred while sending data", error.message);
			}
		);
	}
	Delete = () => {
		this.setState({ isLoading: true });
		const { model } = this.state;
		const { showMessage } = this.props;
		Proxy.POST(
			"//v1.0//Delete",
			{
				"Id": model.Id
			},
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				this.setState({ isLoading: false });
				showMessage("success", "Operation is successfully!");
				this.Clear();
				this.Search();
			},
			(error) => {
				this.setState({ isLoading: false });
				showMessage("error", "An error occurred while sending data", error.message);
			}
		);
	}
	Submit = () => {

		if (!this.Validate()) {
			return;
		}
		this.setState({ isLoading: true });
		const { model } = this.state;
		const { showMessage } = this.props;

		Proxy.POST(
			"//v1.0//Insert",
			model
			,
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ isLoading: false });
				showMessage("success", "Operation is successfully!");
				this.Clear();
				this.Search();
			},
			(error) => {
				this.setState({ isLoading: false });
				showMessage("error", "An error occurred while sending data", error.message);
			}
		);
	}


	Validate() {
		const { model } = { ...this.state };
		const { showMessageNode } = this.props;
		var messages = [];

		if (model.UniqueClientId == -2) {
			messages.push("Client can not be null.");
		}

		if (messages.length > 0) {
			if (messages.length > 9) {
				var moreWarn = messages.length - 8;
				messages = messages.slice(0, 8);
				messages.push("And " + moreWarn + " more warnings");
			}
			showMessageNode("error", "Please Fill Required Fields", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		return true;
	}
	handleClose = () => {

	}
	render() {
		const { classes, Disabled } = this.props;
		const { alert, model, selected, columns, list, isLoading, IsBackOffice, checkedValues } = this.state;
		var IsClient = ClientHelper.IsClient();
		return (
			<div>
				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar ButtonList={[
					{
						Code: "Search",
						Disabled: Disabled,
						OnClick: this.Search
					},
					{
						Code: "Submit",
						Disabled: Disabled || selected != null,
						OnClick: this.Submit
					},
					{
						Code: "Send",
						OnClick: this.Send,
						Disabled: checkedValues.length == 0 ? true : false

					},

					{
						Code: "Update",
						Disabled: Disabled || selected == null,
						OnClick: this.Update
					},
					{
						Code: "Delete",
						Disabled: Disabled || selected == null,
						OnClick: this.DeleteConfirm
					},
					{
						Code: "Clear",
						Disabled: Disabled,
						OnClick: this.Clear
					}
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card >
							<CardBody>
								<GenericExpansionPanel >
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={this.emptyObject}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Name"
												RenderItem={this.GetRenderItemUniqueClient}
												Sorted={this.sortedName}
												All={true}
												Value={model.UniqueClientId}
												ValueChanged={this.ValueChanged} />
											<ParameterComponent
												Name="TransactionStatusId"
												Value={model.TransactionStatusId}
												LabelText="Transaction Status"
												ParameterCode="BankTransactionStatus"
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={4}>
											<GenericDateInput
												Name="FromDate"
												LabelText="From Date"
												IncludeTime={false}
												Value={model.FromDate}
												ValueChanged={this.ValueChanged} />
											<GenericDateInput
												Name="ToDate"
												LabelText="To Date"
												IncludeTime={false}
												Value={model.ToDate}
												ValueChanged={this.ValueChanged} />

										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>

						<Card className="no-radius">
							<GenericExpansionPanel Title={"Cheque FACS List"}>
								<CardBody>
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer spacing={16}>
												<GridItem xs={12}>
													<GenericGrid
														Data={list.map(d => {
															return {
																Actions: (
																	<div>
																		<GenericCheckInput
																			Grid
																			Value={checkedValues.filter(c => c.Id == d.Id).length > 0}
																			ValueChanged={(e) => {
																				this.HandleCheck(e, d);
																			}}
																		/>

																		<GridButton
																			tooltip="Approved"
																			Icon={ApproveIcon}
																			OnClick={() => { this.GettingMoneyFromAccount(d); }}
																			Disabled={d.ChequeStatus == "Apporoved" ? true : false}
																		/>
																	</div>
																),
																Date: d.Date,
																ClientName: d.ClientName,
																ChequeSerialNumber: d.ChequeSerialNumber,
																ChequeAccount: d.ChequeAccount,
																DepositAccount: d.DepositAccount,
																ChequeStatus: d.ChequeStatus,
																Amount: d.Amount,
																Id: d.Id,
																DestinitionAccountId: d.DestinitionAccountId,
																SourceAccountId: d.SourceAccountId
															};
														})}
														SelectedIndex={selected}
														Columns={this.columns}
														RowSelected={this.RowSelected} />
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</CardBody>
							</GenericExpansionPanel>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

ChequeFacs.propTypes = {
	setAppCenterTitle: PropTypes.func,
	setAppLeftTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default ChequeFacs;