import ApproveIcon from "assets/img/actionIcons/approve.png";
import DeleteIcon from "assets/img/actionIcons/delete.png";
import DetailIcon from "assets/img/actionIcons/detail.png";
import DocumentIcon from "assets/img/actionIcons/document.png";
import DownloadIcon from "assets/img/actionIcons/download.png";
import EditIcon from "assets/img/actionIcons/edit.png";
import EmailIcon from "assets/img/actionIcons/email.png";
import ExplanantionIcon from "assets/img/actionIcons/explanantion.png";
import InformationIcon from "assets/img/actionIcons/information.png";
import PrintIcon from "assets/img/actionIcons/print.png";
import ProfileIcon from "assets/img/actionIcons/profile.png";
import QRCodeIcon from "assets/img/actionIcons/qrCode.png";
import RowDetailIcon from "assets/img/actionIcons/rowdetail.png";
import SignOutIcon from "assets/img/actionIcons/signout.png";
import SubmitOrSendIcon from "assets/img/actionIcons/submitorsend.png";
import UploadIcon from "assets/img/actionIcons/upload.png";
import SuspiciousIcon from "assets/img/actionIcons/suspicious.png";
import TraceIcon from "assets/img/actionIcons/trace.png";
import EftIcon from "assets/img/actionIcons/eft.png";
import ETransferIcon from "assets/img/actionIcons/etransfer.png";
import SwiftIcon from "assets/img/actionIcons/swift.png";
import VerificationIcon from "assets/img/actionIcons/verification.png";
import MoveToLegalIcon from "assets/img/actionIcons/movetolegal.png";
import CredentialIcon from "assets/img/actionIcons/credential.png";
import WalletIcon from "assets/img/actionIcons/wallet.png";
import PayIcon from "assets/img/actionIcons/pay.png";
import CVVRetryIcon from "assets/img/actionIcons/unlock1.png";
import PinRetryIcon from "assets/img/actionIcons/unlock2.png";
import UndoIcon from "assets/img/actionIcons/undo4.png";
import CopyIcon from "assets/img/actionIcons/copyimg.png";



export {
	VerificationIcon,
	SwiftIcon,
	ETransferIcon,
	EftIcon,
	TraceIcon,
	SuspiciousIcon,
	EditIcon,
	ApproveIcon,
	DeleteIcon,
	DetailIcon,
	DocumentIcon,
	DownloadIcon,
	EmailIcon,
	ExplanantionIcon,
	InformationIcon,
	PrintIcon,
	ProfileIcon,
	QRCodeIcon,
	RowDetailIcon,
	SignOutIcon,
	SubmitOrSendIcon,
	UploadIcon,
	MoveToLegalIcon,
	CredentialIcon,
	WalletIcon,
	PayIcon,
	CVVRetryIcon,
	PinRetryIcon,
	UndoIcon,
	CopyIcon
};
