import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import DateHelper from "core/DateHelper";
import { EditIcon, SubmitOrSendIcon } from "core/Icons";
import RouteHelper from "core/RouteHelper";
import PropTypes from "prop-types";
import React from "react";
import AccountLimitToolbar from "views/Components/AccountLimitToolbar";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericCheckInput, GenericDateInput, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { GridColumnType, ProgramCodes } from "views/Constants/Constant";

class Returns extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {
		};

		this.state = {
			checkedValues: [],
			list: [],
			model: this.initialModel,
			countModel: {},
			LimitToken: false
		};

		this.HandleClear = this.HandleClear.bind(this);
		this.HandleCheck = this.HandleCheck.bind(this);
		this.HandleChange = this.HandleChange.bind(this);
		this.HandleChangeItemCount = this.HandleChangeItemCount.bind(this);

		this.HandleGetList = this.HandleGetList.bind(this);
		this.SuccessListCallback = this.SuccessListCallback.bind(this);
		this.ErrorListCallback = this.ErrorListCallback.bind(this);

		this.GetItemCountCallback = this.GetItemCountCallback.bind(this);
		this.SuccessItemCountCallback = this.SuccessItemCountCallback.bind(this);
		this.ErrorItemCountCallback = this.ErrorItemCountCallback.bind(this);

		this.HandleSend = this.HandleSend.bind(this);
		this.SuccessSendCallback = this.SuccessSendCallback.bind(this);
		this.ErrorSendCallback = this.ErrorSendCallback.bind(this);
	}

	componentDidMount() {
		const { setAppLeftTitle, setAppCenterTitle } = this.props;
		this.GetItemCountCallback();

		if (setAppLeftTitle)
			setAppLeftTitle("Error Transaction");
		if (setAppCenterTitle)
			setAppCenterTitle("EFT SYSTEM");

		this.RefreshToolBar();
	}

	RefreshToolBar = () => {
		this.setState({ LimitToken: !this.state.LimitToken });
	}

	HandleClear() {
		this.setState({ model: this.initialModel });
	}

	HandleCheck(e, x) {
		this.setState(state => ({
			checkedValues: state.checkedValues.includes(x)
				? state.checkedValues.filter(c => c !== x)
				: [...state.checkedValues, x]
		}));
	}

	HandleChange(name, value) {
		const model = { ...this.state.model };
		model[name] = value;
		this.setState({ model });
	}

	HandleChangeItemCount(name, value) {
		const countModel = { ...this.state.countModel };
		countModel[name] = value;
		this.setState({ countModel });
	}

	HandleGetList() {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/BankBmoEFT/SearchEFTTransactions",
			this.state.model,
			this.SuccessListCallback,
			this.ErrorListCallback
		);
	}

	SuccessListCallback(responseData) {
		this.setState({ isLoading: false });
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error", responseData.ErrorDescription);
			return;
		}
		if (responseData.Item !== null) {
			this.setState({ list: responseData.Item });
		} else {
			this.setState({ list: [] });
		}
	}

	ErrorListCallback(error) {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", error);
	}

	GetItemCountCallback() {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/Transaction/GetEftTransactionCountByStatus",
			{},
			this.SuccessItemCountCallback,
			this.ErrorItemCountCallback
		);
	}

	SuccessItemCountCallback(responseData) {
		this.setState({ isLoading: false });
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error", responseData.ErrorDescription);
			return;
		}

		this.HandleChangeItemCount("RejectedFiles", String(responseData.RejectedFiles));
		this.HandleChangeItemCount("ErrorCount", String(responseData.ErrorCount));
		this.HandleChangeItemCount("ReturnCount", String(responseData.ReturnCount));
	}

	ErrorItemCountCallback(error) {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", error);
	}

	HandleSend(model) {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/",
			model,
			this.SuccessSendCallback,
			this.ErrorSendCallback
		);
	}

	SuccessSendCallback(responseData) {
		this.setState({ isLoading: false });
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error", responseData.ErrorDescription);
			return;
		}
		this.ShowMessage("success", "Succeeded!", "Operation is successfully!");
	}

	ErrorSendCallback(error) {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "An error occurred while sending data", error.message);
	}

	ShowMessage(type, title, message) {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode(type, title, message) {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	render() {
		const { Disabled } = this.props;
		const { alert, checkedValues, isLoading, model, list, LimitToken } = this.state;
		return (
			<div>
				<AccountLimitToolbar key={LimitToken} Program={ProgramCodes.Bank_Programs_EFT} />


				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled || model === this.initialModel },
					{ Code: "Search", OnClick: this.HandleGetList, Disabled: Disabled, ModelFunction: () => model, FillDataFromModel: model => this.setState({ model }), ValidationFunction: this.Validate },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem md={12} xs={12}>
						<Card className="no-radius">
							<CardBody>
								<h5>Returns</h5>
								<GridContainer>
									<GridItem md={4} xs={12}>
										<GenericTextInput
											Name="TransactionNumber"
											LabelText="Number of Return Transactions"
											Value={this.state.countModel.TransactionNumber}
											Disabled />
									</GridItem>
									<GridItem md={4} xs={12}>
										<GenericTextInput
											Name="TotalCreditAmount"
											LabelText="Credit Amount"
											Value={this.state.countModel.TotalCreditAmount}
											Disabled />
									</GridItem>
									<GridItem md={4} xs={12}>
										<GenericTextInput
											Name="TotalDebitAmount"
											LabelText="Debit Amount"
											Value={this.state.countModel.TotalDebitAmount}
											Disabled />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Parameter">
									<GridContainer>
										<GridItem md={4} xs={12}>
											<GridItem>
												<GenericSelectInput
													Name="MainSearchItemId"
													LabelText="Main Search Item"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={{
														ParameterCode: "MainSearchItem",
														ParameterValue: "R",
														ParameterValue2: "EFT"
													}}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={model.MainSearchItemId}
													ValueChanged={this.HandleChange}
													Disabled={true}
													DefaultIndex={0}
													All={true}
												/>
											</GridItem>
											<GridItem>
												<ParameterComponent
													Name="PriorityTypeId" Value={model.PriorityTypeId}
													LabelText="EFT Type" ParameterCode="EFTType"
													ValueChanged={this.HandleChange} />
											</GridItem>
											<GridItem>
												<GenericSelectInput
													Name="TransactionTypeId"
													LabelText="Transaction Type"
													Method="GET"
													Url="/bankapi/v1.0/Transaction/GetAllFinancialTransactionType"
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={model.TransactionTypeId}
													ValueChanged={this.HandleChange} />
											</GridItem>
											<GridItem>
												<GenericSelectInput
													key={model.TransactionTypeId}
													Name="TransactionCodeId"
													LabelText="Transaction Code"
													Method="POST"
													Url="/bankapi/v1.0/BankTransactionCode/GetByProgram"
													Parameter={{
														Program: "EFT",
														TransactionTypeId: model.TransactionTypeId
													}}
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={d => `${d.TxnCode} - ${d.TxnName}`}
													Value={model.TransactionCodeId}
													ValueChanged={this.HandleChange}
												/>
											</GridItem>
											<GridItem>
												<GenericSelectInput
													Name="FinancialInstitutionId"
													LabelText="Bank No"
													Method="GET"
													Url="/bankapi/v1.0/FinancialInstitution/GetAll"
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="Description"
													Value={model.FinancialInstitutionId}
													ValueChanged={this.HandleChange}
												/>
											</GridItem>
										</GridItem>
										<GridItem md={4} xs={12}>
											<GridItem>
												<GenericTextInput
													Name="PayeeName"
													LabelText="Payee Name"
													Value={model.PayeeName}
													ValueChanged={this.HandleChange} />
											</GridItem>
											<GridItem>
												<GenericTextInput
													Name="PayeeAccount"
													LabelText="Payee Wallet"
													Value={model.PayeeAccount}
													ValueChanged={this.HandleChange} />
											</GridItem>
											<GridItem>
												<GenericTextInput
													Name="TransactionAmount"
													LabelText="Amount"
													Value={model.TransactionAmount}
													ValueChanged={this.HandleChange} />
											</GridItem>
											<GridItem>
												<GenericSelectInput
													Name="TransactionSourceId"
													LabelText="Transaction Source"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={{
														ParameterCode: "TransactionSource"
													}}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={model.TransactionSourceId}
													ValueChanged={this.HandleChange}
												/>
											</GridItem>
										</GridItem>
										<GridItem md={4} xs={12}>
											<GridItem>
												<GenericTextInput
													Name="Id"
													LabelText="Transaction ID"
													Value={model.Id}
													ValueChanged={this.HandleChange} />
											</GridItem>
											<GridItem>
												<GenericSelectInput
													Name="StoreId"
													LabelText="Store"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={{
														ParameterCode: "CustomerStore"
													}}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={model.StoreId}
													ValueChanged={this.HandleChange}
												/>
											</GridItem>
											<GridItem>
												<GenericDateInput
													Name="FromDate"
													LabelText="From Date"
													Value={model.FromDate ? DateHelper.ToDateInputValue(model.FromDate) : ""}
													ValueChanged={this.HandleChange} />
											</GridItem>
											<GridItem>
												<GenericDateInput
													Name="ToDate"
													LabelText="To Date"
													Value={model.ToDate ? DateHelper.ToDateInputValue(model.ToDate) : ""}
													ValueChanged={this.HandleChange} />
											</GridItem>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={"Returns Total"} />
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem md={12} xs={12}>
										<GridContainer>
											<GridItem md={4} xs={12}>
											</GridItem>
											<GridItem md={4} xs={12} />
											<GridItem md={4} xs={12}>
												<GridContainer justify="flex-end">
													<GridItem md={3} xs={12}>
														<Button
															size="sm"
															onClick={() => {
																checkedValues.forEach(val => {
																	this.HandleSend(val);
																});
															}}
															fullWidth
															disabled={Object.keys(checkedValues).length === 0}
														>
															Bulk Send
														</Button>
													</GridItem>
												</GridContainer>
											</GridItem>
										</GridContainer>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={[
														{
															Header: "Actions",
															accessor: "Actions",
															Cell: row => (
																<div style={{ opacity: !row.original.IsEditable ? 0.4 : 1 }}>
																	<GridButton
																		tooltip="Send"
																		Disabled={!row.original.IsEditable}
																		Icon={SubmitOrSendIcon}
																		OnClick={() => { this.HandleSend(row.original); }} />

																	<GenericCheckInput
																		Grid
																		Value={checkedValues.filter(c => c.Id == row.original.Id).length > 0}
																		ValueChanged={(e) => {
																			this.HandleCheck(e, row.original);
																		}}
																		Disabled={!row.original.IsEditable} />

																	<GridButton
																		tooltip="Edit"
																		Disabled={!row.original.IsEditable}
																		Icon={EditIcon}
																		OnClick={() => { RouteHelper.Push(this.props.history, "/CreateEFT", "CreateEFT_Id", row.original.Id); }} />
																</div>
															),
															sortable: false,
															filterable: false,
															width: 150
														},
														{
															Header: "Transaction Date",
															accessor: "TransactionDate",
															type: GridColumnType.DateTime
														},
														{
															Header: "Transaction Source",
															accessor: "TransactionSource.ParameterDesc"
														},
														{
															Header: "Program",
															accessor: "Program.Description"
														},
														{
															Header: "Transaction Type",
															accessor: "TransactionType.ParameterDesc"
														},
														{
															Header: "Settlement Type",
															accessor: "PriorityType.ParameterDesc"
														},
														{
															Header: "Customer Number",
															accessor: "ClientNumber"
														},
														{
															Header: "Name of Customer",
															accessor: "ClientName"
														},
														{
															Header: "Amount",
															accessor: "TransactionAmount",
															type: GridColumnType.Money,
															ColumnType: GridColumnType.Money
														},
														{
															Header: "Fee Amount",
															accessor: "FeeAmount",
															type: GridColumnType.Money,
															ColumnType: GridColumnType.Money
														},
														{
															Header: "Transaction Status",
															accessor: "TransactionStatus.ParameterDesc"
														},
														{
															Header: "Transaction Sub-Status",
															accessor: "TransactionCodeName"
														},
														{
															Header: "Transaction Type",
															accessor: "EFTTransactionType.ParameterDesc"
														},
														{
															Header: "Transaction ID",
															accessor: "ReferenceNumber"
														},
														{
															Header: "Financial Institution",
															accessor: "FinancialInstitution.Description"
														},
														{
															Header: "Financial Institution Branch",
															accessor: "FinancialInstitutionBranch.Description"
														},
														{
															Header: "Transit Number",
															accessor: "FinancialInstitutionBranch.TransitNumber"
														},
														{
															Header: "Wallet Number",
															accessor: "AccountNumber"
														},
														{
															Header: "File Id",
															accessor: "FileId"
														},
														{
															Header: "File Name",
															accessor: "FileName"
														},
														{
															Header: "Store",
															accessor: "Store.StoreName"
														},
														{
															Header: "Settlement Date",
															accessor: "SettlementDate",
															type: GridColumnType.Date
														},
														{
															Header: "Customer Note",
															accessor: "CustomerNote.Note"
														},
														{
															Header: "Posting Date",
															accessor: "TransactionDate",
															type: GridColumnType.Date
														},
														{
															Header: "Return Transaction Type",
															accessor: "ReturnTransactionType"
														},
														{
															Header: "Original Transaction Date",
															accessor: "OriginalTransactionDate"
														},
														{
															Header: "Payee Institution Id",
															accessor: "PayeeInstitutionId"
														},
														{
															Header: "Payee Wallet",
															accessor: "PayeeAccount"
														},
														{
															Header: "Item Trace Number",
															accessor: "ItemTraceNumber"
														},
														{
															Header: "Original Transaction Type",
															accessor: "OriginalTransactionType"
														},
														{
															Header: "Institution Id For Returns",
															accessor: "InstitutionIdForReturns"
														},
														{
															Header: "Wallet Number For Returns",
															accessor: "AccountNumberForReturns"
														},
														{
															Header: "Return Code",
															accessor: "ReturnCode"
														},
														{
															Header: "Return Indicator",
															accessor: "ReturnIndicator"
														},
													]}
													ProgramCode={ProgramCodes.Bank_Programs_EFT} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

Returns.propTypes = {
	classes: PropTypes.object.isRequired
};

export default Returns;
