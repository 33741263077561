import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericCheckInput, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";

class PlasticTypeDefinition extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined,
		};

		this.emptyModel = {};
		this.state = {
			model: this.initialModel,
			vModel: {},
			list: [],
			isEdit: false,
			isLoading: false,
			index: -1
		};


		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined,
		};

		this.renderItemUniqueClient = {};

		this.maxLength40 = { maxLength: 40 };
		this.maxLength25 = { maxLength: 25 };
		this.maxLength3 = { maxLength: 3 };
		this.maxLength128 = { maxLength: 128 };
		this.maxLength8 = { maxLength: 8 };
		this.maxLength1 = { maxLength: 1 };
		this.maxLength2 = { maxLength: 2 };
		this.maxLength20 = { maxLength: 20 };
		this.maxLength10 = { maxLength: 10 };
		this.maxLength14 = { maxLength: 14 };
		this.maxLength5 = { maxLength: 5 };

		this.Columns = [
			{
				Header: "Client",
				accessor: "UniqueClient.ClientName"
			},
			{
				Header: "Plastic Name",
				accessor: "PlasticName"
			},
			{
				Header: "Plastic Code",
				accessor: "PlasticCode"
			},
			{
				Header: "Product Type",
				accessor: "ProductType"
			},
			{
				Header: "Service Code",
				accessor: "ServiceCode"
			},
			{
				Header: "Expiry Period",
				accessor: "ExpiryPeriod"
			},
			{
				Header: "Bulk Delivery Location Id",
				accessor: "BulkDeliveryLocationId"
			},
			{
				Header: "Card Emboss Center Code",
				accessor: "CardEmbossCenterCode"
			}, {
				Header: "Card Type",
				accessor: "CardType"
			}, {
				Header: "Customer Service Phone Number",
				accessor: "CustomerServicePhoneNumber"
			}, {
				Header: "Return Address1",
				accessor: "ReturnAddress1"
			}, {
				Header: "Return Address2",
				accessor: "ReturnAddress2"
			}, {
				Header: "Return City",
				accessor: "ReturnCity"
			}, {
				Header: "Return Country Code",
				accessor: "ReturnCountryCode"
			}, {
				Header: "Return Post Code",
				accessor: "ReturnPostCode"
			}, {
				Header: "Return Provience Code",
				accessor: "ReturnProvienceCode"
			}, {
				Header: "Service Code",
				accessor: "ServiceCode"
			},
			{
				Header: "Plastic Language",
				accessor: "PlasticLanguage"
			},
			{
				Header: "Card Image Code",
				accessor: "CardImageCode"
			},
			{
				Header: "Instant Image Code",
				accessor: "InstantArtWorkCode"
			},
			{
				Header: "Is Emv",
				accessor: "IsEmv",
				Cell: row => (
					<div>{row.value ? "Yes" : "No"}</div>
				),
			},
			{
				Header: "IsContactless",
				accessor: "IsContactless",
				Cell: row => (
					<div>{row.value ? "Yes" : "No"}</div>
				),
			},
			{
				Header: "Has Bulk Delivery",
				accessor: "HasBulkDelivery",
				Cell: row => (
					<div>{row.value ? "Yes" : "No"}</div>
				),
			},
			{
				Header: "Has Qr Code",
				accessor: "HasQrCode",
				Cell: row => (
					<div>{row.value ? "Yes" : "No"}</div>
				),
			},
			{
				Header: "Instant Card Issuance Emboss Code",
				accessor: "InstantCardIssuanceEmbossCode"
			},
			{
				Header: "Instant or Bulk",
				accessor: "InstantOrBulk",
				Cell: row => (
					<div> {row.value ? "Instant" : "Bulk"} </div>
				),
			},
			{
				Header: "Status",
				accessor: "StatusId",
				Cell: row => (
					<div> {this.renderSwitch(row.value)} </div>
				),
			}
		];
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Plastic Type Definition");
		this.props.setAppCenterTitle("PREPAID MANAGEMENT");
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}

	HandleClear = () => {
		this.setState({ model: { ...this.initialModel }, vModel: {}, isEdit: false, index: -1 });
	}

	HandleSearch = () => {

		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/PlasticType/Search",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", responseData.ErrorDescription);
					return;
				}
				this.setState({ list: responseData.Item || [] });

			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	HandleUpdate = (workflowId, after) => {
		if (!this.Validate()) {
			return;
		}

		this.setState({ isLoading: true });
		Proxy.POST("/prepaidapi/v1.0/PlasticType/Update",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", responseData.ErrorDescription);
					return;
				}
				if (after) after();
				this.HandleClear();
				this.HandleSearch();
				this.ShowMessage("success", "Updated successfully!");
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			},
			workflowId
		);
	}

	HandleDelete = () => {
		this.ShowConfirmMessage("warning", "Delete", "Are you sure to delete this record?", () => {
			this.setState({ isLoading: true, alert: "" });
			Proxy.POST("/prepaidapi/v1.0/PlasticType/Delete",
				{ Id: this.state.model.Id },
				(responseData) => {
					this.setState({ isLoading: false });
					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", responseData.ErrorDescription);
						return;
					}
					this.HandleClear();
					this.HandleSearch();
					this.ShowMessage("success", "Deleted successfully!");
				},
				(error) => {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "Error", error);
				}
			);
		});
	}

	HandleSubmit = (workflowId, after) => {
		if (!this.Validate()) {
			return;
		}
		this.setState({ isLoading: true });
		Proxy.POST("/prepaidapi/v1.0/PlasticType/Insert",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", responseData.ErrorDescription);
					return;
				}
				if (after) after();
				this.HandleClear();
				this.HandleSearch();
				this.ShowMessage("success", "Added successfully!");
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			},
			workflowId
		);
	}



	Validate = () => {
		const { model, vModel } = this.state;
		const { showValidationErrors } = this.props;
		var Messages = [];

		if (!model.UniqueClientId) {
			Messages.push("Client cannot be empty.");
			vModel.UniqueClientId = true;
		}

		if (!model.PlasticName) {
			Messages.push("Plastic Name cannot be empty.");
			vModel.PlasticName = true;
		}

		if (!model.PlasticCode) {
			Messages.push("Plastic Code cannot be empty");
			vModel.PlasticCode = true;
		}

		if (!model.ProductType) {
			Messages.push("Product Type cannot be empty");
			vModel.ProductType = true;
		}

		if (!model.CardType) {
			Messages.push("Card Type cannot be empty");
			vModel.CardType = true;
		}

		if (!model.ServiceCode) {
			Messages.push("Service Code cannot be empty");
			vModel.ServiceCode = true;
		}

		if (!model.ExpiryPeriod) {
			Messages.push("Expiry Period cannot be empty");
			vModel.ExpiryPeriod = true;
		}

		if (!model.ReturnAddress1) {
			Messages.push("Return Address1 cannot be empty");
			vModel.ReturnAddress1 = true;
		}

		if (!model.ReturnCity) {
			Messages.push("Return City cannot be empty");
			vModel.ReturnCity = true;
		}
		if (!model.ReturnCountryCode) {
			Messages.push("Return Country Code cannot be empty");
			vModel.ReturnCountryCode = true;
		}
		if (!model.ReturnPostCode) {
			Messages.push("Return Post Code cannot be empty");
			vModel.ReturnPostCode = true;
		}
		if (!model.ReturnProvienceCode) {
			Messages.push("Return Provience Code cannot be empty");
			vModel.ReturnProvienceCode = true;
		}
		if (!model.ServiceCode) {
			Messages.push("Service Code cannot be empty");
			vModel.ServiceCode = true;
		}
		if (!model.PlasticLanguage) {
			Messages.push("Plastic Language cannot be empty");
			vModel.PlasticLanguage = true;
		}
		if (!model.BulkDeliveryLocationId) {
			Messages.push("Bulk Delivery Location Id cannot be empty");
			vModel.BulkDeliveryLocationId = true;
		}
		if (!model.BulkDeliveryLocationId) {
			Messages.push("Bulk Delivery Location Id cannot be empty");
			vModel.BulkDeliveryLocationId = true;
		}
		if (!model.CardEmbossCenterCode) {
			Messages.push("Card Emboss Center Code cannot be empty");
			vModel.CardEmbossCenterCode = true;
		}
		if (!model.CustomerServicePhoneNumber) {
			Messages.push("Card Emboss Center Code cannot be empty");
			vModel.CustomerServicePhoneNumber = true;
		}

		if (Messages.length > 0) {
			showValidationErrors(Messages);
			return false;
		}
		else
			return true;
	}

	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}

	RowSelected = (index) => {
		var tempModel = this.state.list[index];
		this.setState({ isEdit: true, model: tempModel, index });
	}


	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: null })} OnConfirm={onConfirm} />
		});
	}

	renderSwitch(value) {
		switch(value) {
		  case 11111:
			 return 'Approved';
		  case 11112:
			 return 'Rejected';
		  case 11113:
			 return 'Unapproved';
		  default:
			 return '';
		}
	 }

	render() {
		const { Disabled } = this.props;
		const { alert, model, list, isLoading, index, vModel } = this.state;

		var IsClient = ClientHelper.IsClient();

		return (
			<div>

				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
					{ Code: "Submit", OnClick: this.HandleSubmit, Disabled: model.Id || Disabled },
					{ Code: "Delete", OnClick: this.HandleDelete, Disabled: !model.Id || Disabled },
					{ Code: "Update", OnClick: this.HandleUpdate, Disabled: !model.Id || Disabled },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer spacing={16}>

					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GridItem>
									<GridContainer>
										<GridItem xs={3}>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={this.parameterUniqueClientId}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={this.RenderItemUniqueClient}
												Value={model.UniqueClientId}
												ValueChanged={this.HandleChange}
												CanClear
												Disabled={IsClient}
												DefaultIndex={IsClient ? 0 : -1}
												Required
												IsInvalid={vModel.UniqueClientId}
												LabelMd={5}
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericTextInput
												Name="PlasticName"
												LabelText="Plastic Name"
												Value={model.PlasticName}
												ValueChanged={this.HandleChange}
												Required
												IsInvalid={vModel.PlasticName}
												inputProps={this.maxLength128}
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericTextInput
												Name="PlasticCode"
												LabelText="Plastic Code"
												Value={model.PlasticCode}
												ValueChanged={this.HandleChange}
												Required
												IsInvalid={vModel.PlasticCode}
												inputProps={this.maxLength3}
												LabelMd={5}
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericTextInput
												Name="ProductType"
												LabelText="Product Type"
												Value={model.ProductType}
												ValueChanged={this.HandleChange}
												Required
												IsInvalid={vModel.ProductType}
												inputProps={this.maxLength3}
												LabelMd={6}
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericTextInput
												Name="CardType"
												LabelText="Card Type"
												Value={model.CardType}
												ValueChanged={this.HandleChange}
												Required
												IsInvalid={vModel.CardType}
												inputProps={this.maxLength10}
												LabelMd={5}
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericTextInput
												Name="ServiceCode"
												LabelText="Service Code"
												Value={model.ServiceCode}
												inputProps={this.maxLength10}
												ValueChanged={this.HandleChange}
												Required
												IsInvalid={vModel.ServiceCode}
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericTextInput
												Name="ExpiryPeriod"
												LabelText="Expiry Period"
												Value={model.ExpiryPeriod}
												inputProps={this.maxLength8}
												ValueChanged={this.HandleChange}
												Required
												IsInvalid={vModel.ExpiryPeriod}
												LabelMd={5}
											/>
										</GridItem>

										<GridItem xs={3}>
											<GenericTextInput
												Format="@-@@@-@@@-@@@@"
												Name="CustomerServicePhoneNumber"
												LabelText="Customer Service Phone Number"
												Value={model.CustomerServicePhoneNumber}
												ValueChanged={this.HandleChange}
												Required
												IsText={false}
												IsInvalid={vModel.CustomerServicePhoneNumber}
												inputProps={this.maxLength14}
												LabelMd={6}
											/>
										</GridItem>

										<GridItem xs={3}>
											<GenericTextInput
												Name="BulkDeliveryLocationId"
												LabelText="Bulk Delivery Location Id"
												Value={model.BulkDeliveryLocationId}
												ValueChanged={this.HandleChange}
												Required
												IsInvalid={vModel.BulkDeliveryLocationId}
												inputProps={this.maxLength2}
												LabelMd={5}
											/>
										</GridItem>

										<GridItem xs={3}>
											<GenericTextInput
												Name="ReturnAddress1"
												LabelText="Return Address 1"
												Value={model.ReturnAddress1}
												ValueChanged={this.HandleChange}
												Required
												IsInvalid={vModel.ReturnAddress1}
												inputProps={this.maxLength40}
											/>
										</GridItem>

										<GridItem xs={3}>
											<GenericTextInput
												Name="ReturnAddress2"
												LabelText="Return Address 2"
												Value={model.ReturnAddress2}
												ValueChanged={this.HandleChange}
												inputProps={this.maxLength40}
												LabelMd={5}

											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericTextInput
												Name="ReturnCity"
												LabelText="Return City"
												Value={model.ReturnCity}
												ValueChanged={this.HandleChange}
												Required
												IsInvalid={vModel.ReturnCity}
												inputProps={this.maxLength20}
												LabelMd={6}
											/>
										</GridItem>

										<GridItem xs={3}>
											<GenericTextInput
												Name="ReturnProvienceCode"
												LabelText="Return Provience Code"
												Value={model.ReturnProvienceCode}
												ValueChanged={this.HandleChange}
												Required
												IsInvalid={vModel.ReturnProvienceCode}
												inputProps={this.maxLength2}
												LabelMd={5}
											/>
										</GridItem>

										<GridItem xs={3}>
											<GenericTextInput
												Name="ReturnCountryCode"
												LabelText="Return Country Code"
												Value={model.ReturnCountryCode}
												ValueChanged={this.HandleChange}
												Required
												IsInvalid={vModel.ReturnCountryCode}
												inputProps={this.maxLength3}
											/>
										</GridItem>


										<GridItem xs={3}>
											<GenericTextInput
												Name="ReturnPostCode"
												LabelText="Return Pos Code"
												Value={model.ReturnPostCode}
												ValueChanged={this.HandleChange}
												Required
												IsInvalid={vModel.ReturnPostCode}
												inputProps={this.maxLength10}
												LabelMd={5}

											/>
										</GridItem>

										<GridItem xs={3}>
											<GenericTextInput
												Name="PlasticLanguage"
												LabelText="Plastic Language"
												Value={model.PlasticLanguage}
												ValueChanged={this.HandleChange}
												Required
												IsInvalid={vModel.PlasticLanguage}
												inputProps={this.maxLength1}
												LabelMd={6}

											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericTextInput
												Name="CardEmbossCenterCode"
												LabelText="Card Emboss Center Code"
												Value={model.CardEmbossCenterCode}
												ValueChanged={this.HandleChange}
												Required
												IsInvalid={vModel.CardEmbossCenterCode}
												inputProps={this.maxLength10}
												LabelMd={5}

											/>

										</GridItem>
										<GridItem xs={3}>
											<GenericTextInput
												Name="CardImageCode"
												LabelText="Card Image Code"
												Value={model.CardImageCode}
												ValueChanged={this.HandleChange}
												inputProps={this.maxLength5}
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericTextInput
												Name="InstantArtWorkCode"
												LabelText="Instant Image Code"
												Value={model.InstantArtWorkCode}
												ValueChanged={this.HandleChange}
												LabelMd={5}
											/>
										</GridItem>
										<GridItem xs={3}>
											<ParameterComponent
												LabelMd={6}
												Name="InstantCardIssuanceEmbossCode"
												LabelText="Instant Card Issuance Emboss Code"
												ParameterCode="BackgroundColor"
												KeyValueMember="ParameterDesc"
												TextValueMember="ParameterDesc"
												Value={model.InstantCardIssuanceEmbossCode}
												ValueChanged={this.HandleChange}
											/>
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem xs={3}>
											<GenericCheckInput
												Name="IsEmv"
												LabelText="IsEmv"
												Value={model.IsEmv}
												ValueChanged={this.HandleChange}
												Required
												IsInvalid={vModel.IsEmv}
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericCheckInput
												Name="IsContactless"
												LabelText="IsContactless"
												Value={model.IsContactless}
												ValueChanged={this.HandleChange}
												Required
												IsInvalid={vModel.IsContactless}
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericCheckInput
												Name="HasBulkDelivery"
												LabelText="HasBulkDelivery"
												Value={model.HasBulkDelivery}
												ValueChanged={this.HandleChange}
												Required
												IsInvalid={vModel.HasBulkDelivery}
											/>
										</GridItem>

										<GridItem xs={3}>
											<GenericCheckInput
												Name="HasQrCode"
												LabelText="HasQrCode"
												Value={model.HasQrCode}
												ValueChanged={this.HandleChange}
												Required
												IsInvalid={vModel.HasQrCode}
											/>
										</GridItem>
									</GridContainer>
								</GridItem>
							</CardBody>
						</Card>

					</GridItem>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Plastic Type List">
									<GridContainer>
										<GridItem xs={12}>
											<br />
											<GenericGrid
												Data={list}
												Columns={this.Columns}
												RowSelected={this.RowSelected}
												SelectedIndex={index}
												HideButton={true}
												PageSize={10}
												ShowPagination={true}
											/>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer >
			</div >
		);
	}
}

PlasticTypeDefinition.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(PlasticTypeDefinition, {});
