import {
	primaryColor
} from "assets/jss/material-dashboard-pro-react.jsx";

const createEtransferRecurringStyle = {
	tooltip: {
		background: primaryColor,
		color: "#ffffff",
		fontSize: 16
	}
};

export default createEtransferRecurringStyle;
