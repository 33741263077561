import { Tooltip, withStyles, Zoom } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { Component } from "react";

const gridButtonStyle = {
	imgDisable: {
		opacity: 0.5,
		marginLeft: 1,
		marginRight: 1
	},
	imgNormal: {
		marginLeft: 1,
		marginRight: 1
	}
};

class GridButton extends Component {
	constructor(props) {
		super(props);

		this.state = {
			tooltipOpen: false
		};
	}

	handleOpen(value) {
		this.setState({ tooltipOpen: value });
	}

	render() {
		const { classes, Icon, Disabled, OnClick, onMouseEnter, onMouseLeave, tooltip, placement, ...rest } = this.props;
		const { tooltipOpen } = this.state;

		const tooltipDisabled = tooltip == null || Disabled;

		return (
			<Tooltip title={tooltip || ""} TransitionComponent={Zoom} open={tooltipOpen && !tooltipDisabled} placement={placement}>
				<input className={Disabled ? classes.imgDisable : classes.imgNormal} type="image" src={Icon} disabled={Disabled} alt=""
					onMouseEnter={() => {
						if (onMouseEnter)
							onMouseEnter();
						this.handleOpen(true);
					}}
					onMouseLeave={() => {
						if (onMouseLeave)
							onMouseLeave();
						this.handleOpen(false);
					}}
					onClick={() => {
						if (OnClick)
							OnClick();
						this.handleOpen(false);
					}}
					{...rest} />
			</Tooltip>
		);
	}
}

GridButton.propTypes = {
	classes: PropTypes.object,
	Icon: PropTypes.string,
	Disabled: PropTypes.bool,
	OnClick: PropTypes.func,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
	tooltip: PropTypes.string,
	placement: PropTypes.oneOf([
		"bottom-end", "bottom-start", "bottom", "left-end",
		"left-start", "left", "right-end", "right-start",
		"right", "top-end", "top-start", "top"])
};

export default withStyles(gridButtonStyle)(GridButton);