import { DialogContent, DialogTitle } from "@material-ui/core";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React from "react";
import { GenericAlert, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { InventoryOrderStatusType } from "views/Constants/Constant";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";

const styles = ({
	...sweetAlertStyle
});
class ReceivedCardConfirmation extends React.Component {
	constructor(props) {
		super(props);

		var userJson = localStorage.getItem("user");
		var user = userJson ? JSON.parse(userJson) : null;
		this.initialModel = {
			isBackOffice: true,
			OrderStatusName: InventoryOrderStatusType.Ordered
		};
		this.state = {
			model: {},
			receivelist: [],
			totalresult: {},
			checkedValues: [],
			selected: null,
			isLoading: false,
			openReceiveDialog: false,
			alert: "",
			vModel: {},
		};

		this.HandleCheck = this.HandleCheck.bind(this);
		this.columns = [
			{
				Header: "Store/Safe",
				accessor: "CustomerStoreSafeName"
			},
			{
				Header: "Record Type",
				accessor: "RecordType"
			},
			{
				Header: "Product Name",
				accessor: "ProductName"
			},
			{
				Header: "Package No",
				accessor: "PackageNo"
			},
			{
				Header: "Quantity",
				accessor: "Quantity"
			},
			{
				Header: "Product Code",
				accessor: "PlasticTypeName"
			},

		];
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Received Card Confirmation");
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
		const { model } = { ...this.state }
		model.isBackOffice = ClientHelper.IsBackOffice();
		if (!model.isBackOffice) {
			model.UniqueClientId = ClientHelper.GetClientRowId();
			model.Name = model.UniqueClientId;
		}

		this.setState({ model });
	}

	OpenReceiveModal = (item) => {
		this.setState({ openReceiveDialog: true })
	}

	ValueChanged = (name, value, data) => {
		const model = { ...this.state.model };
		model[name] = value;
		if (name === "Name") model["UniqueClientId"] = value;
		if (name === "UniqueClientId") model["Name"] = value;

		this.setState({ model });
	}
	HandleCheck = (e, x) => {

		this.setState(state => ({
			checkedValues: state.checkedValues.includes(x)
				? state.checkedValues.filter(c => c !== x)
				: [...state.checkedValues, x]
		}));
		this.setState({ openOrderDialog: true });

	}

	ErrorSendCallback(error) {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "An error occurred while sending data", error.message);
	}
	Validate() {
		const { model } = this.state;
		const { showMessageNode } = this.props;
		const vModel = { ...this.state.vModel };

		var messages = [];

		if (model.OldQuantity != model.Quantity) {
			messages.push("Old Quantity With New Quantity Not Equal!");
			vModel.OldQuantity = true;
			vModel.Quantity = true;

		} else {
			vModel.OldQuantity = false;
			vModel.Quantity = false;

		}
		if (model.OldQuantity != (model.EndToNumber - model.StartingFromNumber)) {
			messages.push("The difference between End To Number and Starting From Number is not equal to  Quantity!");
			vModel.EndToNumber = true;
			vModel.StartingFromNumber = true;
			vModel.Quantity = true;
		} else {
			vModel.EndToNumber = false;
			vModel.StartingFromNumber = false;
			vModel.Quantity = false;

		}
		if (model.OrderPackageNo == null || model.OrderPackageNo =="") {
			messages.push("Order Package No can not be null.");
			vModel.OrderPackageNo = true;

		} else{
			vModel.OrderPackageNo = false;
		}
		this.setState({ vModel});

		if (messages.length > 0) {
			if (messages.length > 9) {
				var moreWarn = messages.length - 8;
				messages = messages.slice(0, 8);
				messages.push("And " + moreWarn + " more warnings");
			}
			this.props.showMessageNode("error", "Please Fill Required Fields", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		return true;
	}

	ReceiceConfrim = () => {


		if (!this.Validate()) {
			return;
		}
		this.setState({ isLoading: true });
		const { model } = this.state;
		const { showMessage } = this.props;
		if (model.OldQuantity == model.Quantity) {
			Proxy.POST(
				"/prepaidapi/v1.0/InventoryOrder/InventoryOrderAcceptUpdate",
				model,
				(responseData) => {
					if (!responseData.IsSucceeded) {
						this.setState({ isLoading: false });
						this.props.showMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					this.setState({ isLoading: false });
					this.props.showMessage("success", " Operation completed successfully!!");
					this.Clear();
					this.Search();
					this.props.clearlist();

				},
				(error) => {
					this.props.showMessage("error", "Error", "Inventory Order Accept Update Error!");
					this.setState({ isLoading: false });
				}
			);
		} else {

			this.props.showMessage("error", "Error", "Old Quantity With New Quantity Not Equal!");
			this.setState({ isLoading: false });
		}

	}
	Search = () => {
		this.setState({ isLoading: true });

		const { model } = this.state;
		const { showMessage } = this.props;

		Proxy.POST(
			"/prepaidapi/v1.0/InventoryOrder/Search",
			{OrderStatusName : InventoryOrderStatusType.Ordered},
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				this.setState({ receivelist: responseData.Item || [], isLoading: false });
			},
			(error) => {
				this.setState({ isLoading: false });
				// showMessage("error", "An error occurred while requesting data", error.message);
			}
		);
	}



	Clear = () => {
		const { model } = this.state;
		model.UniqueClientId = this.initialModel.UniqueClientId;
		this.setState({ model: this.initialModel, receivelist: [], selected: null, checkedValues: [], totalresult: {} });
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert:
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })}
				/>
		});
	}
	ShowConfirmMessage(type, title, message, onConfirm) {
		this.setState({
			alert:
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					ShowCancel={true}
					OnCancel={() => this.setState({ alert: null, isLoading: false })}
					OnConfirm={onConfirm}
				/>,
			isLoading: true
		});
	}
	handleClose = () => {

		this.setState({
			openReceiveDialog: false
		});
	}
	CardIdGet = (temp) => {

		const { model } = this.state.model;
		Proxy.POST(
			"/prepaidapi/v1.0/Card/GetInventoryCardorderId",
			{
				"CardOrderId": temp.Id,
			},
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					return;
				}
				var tt = responseData.Item;
				temp["StartingFromNumber"] = responseData.Item.CardOrderId;
				this.setState({ model: temp });

			},
			(error) => {
				this.setState({ isLoading: false });
				// showMessage("error", "An error occurred while requesting data", error.message);
			}
		);


	}
	render() {
		const { model, receivelist, selected, checkedValues, vModel } = this.state;
		const { Disabled } = this.props;

		return (
			<div>


				<LoadingComponent Show={this.state.isLoading} />

				{this.state.alert}

				<ButtonToolbar ButtonList={[
					{
						Code: "Search",
						OnClick: this.Search
					}
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius" >
							<GenericExpansionPanel Title={"Received Card Confirmation List"} >
								<CardBody>
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer spacing={16}>
												<GridItem xs={12}>
													<GenericGrid
														Data={receivelist.map(d => {
															return {
																RecordType: d.InventoryOrder.ReceiveRecordType == null ? "" : d.InventoryOrder.ReceiveRecordType.ParameterDesc,
																PackageNo: d.InventoryOrder.PackageNo,
																Quantity: d.InventoryOrder.Quantity,
																CustomerStoreSafeName: d.InventoryOrder.InventorySafeDefinition.SafeName + " / " + d.InventoryOrder.InventorySafeDefinition.CustomerStore.StoreName,
																Id: d.InventoryOrder.Id,
																OrderStatusType: d.InventoryOrder.OrderStatus.ParameterDesc,
																ProductName: d.InventoryOrder.Product.ProductName,
																PlasticTypeName: d.InventoryOrder.Product.PlasticType.PlasticName,
																 

															};
														})}
														Columns={this.columns}
														ShowPagination={true}
														PageSize={100}
														RowSelected={index => {
															var tempomodel = {
																SignatureTypeId: receivelist[index].InventoryOrder.SignatureTypeId,
																RecordType: receivelist[index].InventoryOrder.ReceiveRecordType == null ? "" : receivelist[index].InventoryOrder.ReceiveRecordType.ParameterDesc,
																PackageNo: receivelist[index].InventoryOrder.PackageNo,
																OldQuantity: receivelist[index].InventoryOrder.Quantity,
																CustomerStoreSafeName: receivelist[index].InventoryOrder.InventorySafeDefinition.SafeName + " / " + receivelist[index].InventoryOrder.InventorySafeDefinition.CustomerStore.StoreName,
																Id: receivelist[index].InventoryOrder.Id,
																OrderStatusType: receivelist[index].InventoryOrder.OrderStatus.ParameterDesc,
																PlasticTypeName: receivelist[index].InventoryOrder.Product.PlasticType.PlasticName,
																InventorySafeDefinitionId: receivelist[index].InventoryOrder.InventorySafeDefinition.Id,
																Quantity: receivelist[index].InventoryOrder.Quantity,
																ProductName: receivelist[index].InventoryOrder.Product.ProductName,
																Productd: receivelist[index].InventoryOrder.Product.Id,
															};
															this.setState({ model: tempomodel, selected: index, openOrderDialog: true });
															this.CardIdGet(tempomodel);
														}}
													/>
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</CardBody>
							</GenericExpansionPanel>
						</Card>
					</GridItem>
				</GridContainer>
				<GenericDialog open={this.state.openOrderDialog} maxWidth="md" fullWidth  >
					<DialogTitle >
						<GenericLabel Text="Enter card details below,the number of cards will be added to your card case." FontSize="15px" Bold={true} />

					</DialogTitle>
					<DialogContent >
						<Card className="no-radius">
							<GenericExpansionPanel Title={"Main Parameters"}>
								<CardBody>

									<GridContainer>
										<GridItem xs={6}  >

											{/* <ParameterComponent
												Name="ReceiveRecordTypeId"
												Value={model.ReceiveRecordTypeId}
												LabelText="Record Type"
												ParameterCode="InventoryReceiveRecordType"
												ValueChanged={this.ValueChanged} /> */}


											<GenericTextInput
												Name="RecordType"
												Value={model.RecordType}
												LabelText="RecordType"
												Disabled={true}
											/>

											<GenericTextInput
												Name="PackageNo"
												Value={model.PackageNo}
												LabelText="Package No"
												Disabled={true}
											/>
											<GenericTextInput
												Name="PlasticTypeName"
												Value={model.PlasticTypeName}
												LabelText="Product Code"
												Disabled={true}
											/>
										</GridItem>
										<GridItem xs={6} >
											<GenericTextInput
												Name="OrderPackageNo"
												Value={model.OrderPackageNo}
												LabelText="Order Package No"
												IsInvalid={vModel.OrderPackageNo}
												Required
												ValueChanged={this.ValueChanged}
											/>
											<GenericTextInput
												Name="StartingFromNumber"
												Value={model.StartingFromNumber}
												LabelText="Starting From"
												IsInvalid={vModel.StartingFromNumber}
												Required
												ValueChanged={this.ValueChanged}
											/>
											<GenericTextInput
												Name="EndToNumber"
												Value={model.EndToNumber}
												LabelText="End to"
												ValueChanged={this.ValueChanged}
												IsInvalid={vModel.EndToNumber}
												Required
											/>
											<GenericTextInput
												Name="Quantity"
												Value={model.Quantity}
												LabelText="Quantity"
												IsInvalid={vModel.Quantity}
												Required
												ValueChanged={this.ValueChanged}
											/>
										</GridItem>
										<GridContainer xs={12} justify={"center"} style={{ marginTop: "10px" }}>
											<GridItem >
												<Button size="sm" style={{ width: "100px" }} onClick={() => this.ReceiceConfrim()}>
													CONFIRM
							         	</Button>
											</GridItem>
										</GridContainer>
									</GridContainer>
								</CardBody>
							</GenericExpansionPanel>
						</Card>

					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={() => this.setState({ openOrderDialog: false })}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>
			</div >
		);
	}
}

ReceivedCardConfirmation.propTypes = {
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any,
	classes: PropTypes.object,

};

export default withArtifex(ReceivedCardConfirmation, styles);