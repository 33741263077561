import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import {
  GenericExpansionPanel,
  GenericGrid,
  GenericSelectInput,
  GenericTextInput,
  GenericTitle,
} from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";

class OnusBinDefiniton extends Component {
  constructor(props) {
    super(props);

    this.initialModel = {
      Id: 0,
      Bin: "",
      Description: "",
      KeyProfileId: 0,
      CardBrandId: 0,
      InstantEmvProfileCode: "",
		InstantChipProfileCode: ""
    };

    this.state = {
      model: this.initialModel,
      vModel: {},
      list: [],
      isLoading: false,
    };

    this.emptyObject = {};

    this.parameterTemplate = {
      operation: "GetByParameter",
      dataModel: "CardKeyProfile",
    };

    this.brandParameterTemplete = {
      operation: "GetByParameter",
      dataModel: "ParameterDefinition",
      value: { ParameterCode: "Brand" },
    };

    this.Columns = [
      {
        Header: "Bin",
        accessor: "Bin",
      },
      {
        Header: "Description",
        accessor: "Description",
      },
      {
        Header: "Key Profile",
        accessor: "KeyProfile.ProfileName",
      },
      {
        Header: "Card Brand",
        accessor: "CardBrand.ParameterDesc",
      },
      {
        Header: "Chip Profile Code",
        accessor: "InstantChipProfileCode",
      },
      {
        Header: "Card Issuance Chip Code",
        accessor: "InstantEmvProfileCode",
      },
    ];
  }

  componentDidMount() {
    this.props.setAppLeftTitle("Onus Bin Definition");
    this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
  }

  HandleChange = (name, newValue, data) => {
    const model = { ...this.state.model };
    model[name] = newValue;
    this.setState({ model });
  };

  HandleClear = () => {
    this.setState({
      model: { ...this.initialModel },
      vModel: {},
      isEdit: false,
      index: -1,
    });
  };

  HandleSearch = () => {
    this.setState({ isLoading: true });
    Proxy.POST(
      "/prepaidapi/v1.0/OnusBin/Search",
      this.state.model,
      (responseData) => {
        this.setState({ isLoading: false });
        if (!responseData.IsSucceeded) {
          this.props.showMessage(
            "error",
            "Error",
            responseData.ErrorDescription
          );
          return;
        }
        if (responseData.Item !== null) {
          this.setState({ list: responseData.Item });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
        this.props.showMessage("error", "Error", error);
      }
    );
  };

  HandleSubmit = () => {
    if (!this.Validate()) {
      return;
    }

    this.setState({ isLoading: true });
    Proxy.POST(
      "/prepaidapi/v1.0/OnusBin/Insert",
      this.state.model,
      (responseData) => {
        this.setState({ isLoading: false });
        if (!responseData.IsSucceeded) {
          this.props.showMessage(
            "error",
            "Error",
            responseData.ErrorDescription
          );
          return;
        }
        if (responseData.Item !== null) {
          this.setState({ model: { ...this.initialModel }, index: -1 });
          this.props.showMessage(
            "success",
            "Succeeded",
            "Operation is successfully!"
          );
          this.HandleSearch();
          this.HandleClear();
        }
      },
      (error) => {
        this.setState({ isLoading: false });
        this.props.showMessage("error", "Error", error);
      }
    );
  };

  HandleUpdate = () => {
    if (!this.Validate()) {
      return;
    }

    this.setState({ isLoading: true });
    Proxy.POST(
      "/prepaidapi/v1.0/OnusBin/Update",
      this.state.model,
      (responseData) => {
        this.setState({ isLoading: false });
        if (!responseData.IsSucceeded) {
          this.props.showMessage(
            "error",
            "Error",
            responseData.ErrorDescription
          );
          return;
        }
        if (responseData.Item !== null) {
          this.setState({ model: { ...this.initialModel }, index: -1 });
          this.props.showMessage(
            "success",
            "Succeeded",
            "Operation is successfully!"
          );
          this.HandleSearch();
          this.HandleClear();
        }
      },
      (error) => {
        this.setState({ isLoading: false });
        this.props.showMessage("error", "Error", error);
      }
    );
  };

  Validate = () => {
    const { model, vModel } = this.state;
    var errorList = [];
    const { showValidationErrors } = this.props;

	 vModel.Bin = model.Bin == null || model.Bin == "" || model.Bin == undefined;
    if (vModel.Bin) {
      errorList.push("Bin cannot be empty.");
    }

    vModel.Description =
      model.Description == null ||
      model.Description == "" ||
      model.Description == undefined;
    if (vModel.Description) {
      errorList.push("Description cannot be empty.");
    }

    vModel.KeyProfileId =
      model.KeyProfileId == null ||
      model.KeyProfileId == 0 ||
      model.KeyProfileId == undefined;
    if (!model.KeyProfileId) {
      errorList.push("KeyProfileId cannot be empty.");
    }

    vModel.CardBrandId =
      model.CardBrandId == null ||
      model.CardBrandId == 0 ||
      model.CardBrandId == undefined;
    if (vModel.CardBrandId) {
      errorList.push("CardBrandId cannot be empty.");
    }

    vModel.InstantEmvProfileCode =
      model.InstantEmvProfileCode == null ||
      model.InstantEmvProfileCode == "" ||
      model.InstantEmvProfileCode == undefined;
    if (vModel.InstantEmvProfileCode) {
      errorList.push("Card Issuance Chip Code cannot be empty.");
    }

    vModel.InstantChipProfileCode =
      model.InstantChipProfileCode == null ||
      model.InstantChipProfileCode == "" ||
      model.InstantChipProfileCode == undefined;
    if (vModel.InstantChipProfileCode) {
      errorList.push("Chip Profile Code cannot be empty.");
    }

    if (errorList.length > 0) {
      showValidationErrors(errorList);
      return false;
    }
    return true;
  };

  HandleDelete = () => {
    this.setState({ isLoading: true });
    Proxy.POST(
      "/prepaidapi/v1.0/OnusBin/Delete",
      this.state.model,
      (responseData) => {
        this.setState({ isLoading: false });
        if (!responseData.IsSucceeded) {
          this.props.showMessage(
            "error",
            "Error",
            responseData.ErrorDescription
          );
          return;
        }
        this.setState({ model: { ...this.initialModel }, index: -1 });
        this.props.showMessage("success", "Succeeded", "Deleted successfully!");
        this.HandleSearch();
      },
      (error) => {
        this.setState({ isLoading: false });
        this.props.showMessage("error", "Error", error);
      }
    );
  };

  RowSelected = (index) => {
    let { list } = this.state;
    const tempModel = list[index];
    if (list[index].CardRequestId) {
      tempModel.Id = list[index].Id;
    }
    this.setState({ isEdit: true, model: tempModel, index });
  };

  render() {
    const { Disabled } = this.props;
    const { alert, model, list, isLoading, index, vModel } = this.state;

    return (
      <div>
        <LoadingComponent Show={isLoading} />

        {alert}
        <ButtonToolbar
          ButtonList={[
            { Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
            { Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
            { Code: "Submit", OnClick: this.HandleSubmit, Disabled: Disabled },
            {
              Code: "Update",
              OnClick: this.HandleUpdate,
              Disabled: Disabled || model.Id === 0,
            },
            {
              Code: "Delete",
              OnClick: this.HandleDelete,
              Disabled: Disabled || model.Id === 0,
            },
          ]}
          menuId={this.props.menuId}
          ApprovalData={this.props.ApprovalData}
          After={this.props.After}
        />
        <GridContainer>
          <GridItem xs={12}>
            <Card className="no-radius">
              <CardBody>
                <GenericExpansionPanel Title="Filter Panel">
                  <GridContainer>
                    <GridItem xs={12}>
                      <GridContainer>
                        <GridItem xs={4}>
                          <GenericTextInput
                            Name="Bin"
                            LabelText="Bin"
                            LabelMd={4}
                            Value={model.Bin}
                            ValueChanged={this.HandleChange}
                            Required
                            IsInvalid={vModel.Bin}
                          />
                          <GenericSelectInput
                            Name="CardBrandId"
                            LabelText="Card Brand"
                            Method="POST"
                            Url="/coreapi/v1.0/Parameter/Search"
                            Parameter={{ ParameterCode: "Brand" }}
                            DataRoot="Item"
                            KeyValueMember="Id"
                            TextValueMember="ParameterDesc"
                            Value={model.CardBrandId}
                            ValueChanged={this.HandleChange}
                            Required
                            IsInvalid={vModel.CardBrandId}
                          />
                        </GridItem>
                        <GridItem xs={4}>
                          <GenericTextInput
                            Name="Description"
                            LabelText="Description"
                            Value={model.Description}
                            ValueChanged={this.HandleChange}
                            Required
                            IsInvalid={vModel.Description}
                          />
                          <GenericTextInput
                            Name="InstantChipProfileCode"
                            LabelText="Chip Profile Code"
                            LabelMd={4}
                            Value={model.InstantChipProfileCode}
                            ValueChanged={this.HandleChange}
                            Required
                            IsInvalid={vModel.InstantChipProfileCode}
                          />
                        </GridItem>
                        <GridItem xs={4}>
                          <GenericSelectInput
                            Name="KeyProfileId"
                            LabelText="Key Profile"
                            Method="POST"
                            Url="/coreapi/v1.0/dynamic/do"
                            Parameter={{
                              operation: "GetByParameter",
                              dataModel: "CardKeyProfile",
                            }}
                            DataRoot="Item"
                            KeyValueMember="Id"
                            TextValueMember="ProfileName"
                            Value={model.KeyProfileId}
                            ValueChanged={this.HandleChange}
                            Required
                            IsInvalid={vModel.KeyProfileId}
                          />
								  <GenericTextInput
                            Name="InstantEmvProfileCode"
                            LabelText="Card Issuance Chip Code"
                            LabelMd={4}
                            Value={model.InstantEmvProfileCode}
                            ValueChanged={this.HandleChange}
                            Required
                            IsInvalid={vModel.InstantEmvProfileCode}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </GenericExpansionPanel>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12}>
            <Card className="no-radius">
              <CardHeader>
                <GenericTitle text={"Onus Bin List"} />
              </CardHeader>
              <CardBody>
                <GridContainer spacing={16}>
                  <GridItem xs={12}>
                    <GenericGrid
                      Data={list}
                      Columns={this.Columns}
                      RowSelected={this.RowSelected}
                      SelectedIndex={index}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

OnusBinDefiniton.propTypes = {
  classes: PropTypes.object.isRequired,
  Disabled: PropTypes.bool,
};

export default withArtifex(OnusBinDefiniton, {});
