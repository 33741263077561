// @ts-nocheck
import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import { TaskPool } from "core/TaskPool";
import PropTypes from "prop-types";
import React from "react";
import { GenericDialog, GenericDialogActions, GenericLabel, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";


class ClientDisputeReportAction extends React.Component {
	constructor(props) {
		super(props);
		this.fileInputRef = React.createRef();

		this.state = {
			selected: -1,
			isLoading: false,
			model: {}
		};
		this.loadTaskPool = new TaskPool(this.handleLoadTaskPoolAppend, this.handleLoadTaskPoolCompleted);
		this.maxLength150 = { maxLength: 150 };
		this.maxLength6 = { maxLength: 6 };

	}

	componentDidMount() {
		if (this.props.KnownCustomer)
			this.HandleClickByKeyPressed();
	}

	ValueChanged = (name, newValue, data) => {
		this.props.onModelChange(model => {
			model[name] = newValue;
			return model;
		});
	}

	HandleClickSubmit = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/ClearingDispute/ClientDisputeUpdateStatus",
			this.props.disputeData,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					this.setState({ isLoading: false });
				
					return;
				}
				this.setState({ isLoading: false });
				this.props.showMessage("success", "Success", "Dispute Transaction Succesfuly!");
				this.props.onActionModelClose();
			},
			e => {
				this.setState({ isLoading: false, data: [] });
			}
		);
	}


	CancelSelectCallback = () => {
		this.props.onActionModelClose();
	}
	RenderCurrencySelect = (d) => {
		return d.Code + " - " + d.Description;
	}

	DownloadModel = () => {
		this.setState({ isLoading: true });
		const { showMessage } = this.props;
		const  model  = this.props.disputeData;

		if (!model.File) {
			this.setState({ isLoading: false });
			this.props.showMessage("warning", "File", "Document Not Found");
			return false;
		}


		Proxy.FileDownload(
			"/coreapi/v1.0/File/DownloadFileFromBinary",
			model.File.FileName,
			model.FileExtensionDesc,
			{
				"FileId": model.File.Id
			},
			() => {
				this.setState({ isLoading: false });	
			},
			(error) => {
				this.setState({ isLoading: false });
				showMessage("error", "An error occurred while sending data", error.message);
			}
		);
	}



	render() {
		const model = this.props.disputeData;

		const { isLoading } = this.state;

		return (
			<>
				{this.state.alert}
				<LoadingComponent Show={isLoading} />
				<GenericDialog open={this.props.isDisputeActionDialogOpen} onBackdropClick={this.CancelSelectCallback} maxWidth="lg" fullWidth keepMounted={true} style={{ zIndex: 8 }} >
					<DialogTitle><GenericLabel FontSize="16px" Text="Detail" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ marginTop: 8 }}>

						<GridContainer>
							<GridItem xs={12}>
								<Card>
									<CardHeader>
										<GenericTitle text={"Dispute"} />
									</CardHeader>
									<CardBody>
										<GridContainer >

											<GridItem xs={4}>
												<ParameterComponent
													Name="ClientDisputeStatusId"
													LabelText="Client Dispute Status"
													ParameterCode="ClientDisputeStatus"
													ValueChanged={this.ValueChanged}
													Value={model.ClientDisputeStatusId}
												/>

											</GridItem>

											<GridItem xs={4}>

												<div>
													<GenericLabel Text="Download Document" />
													<Button size="sm" onClick={this.DownloadModel} disabled={model.Disabled} >DOWNLOAD FILE</Button>
												</div>

											</GridItem>
										</GridContainer>
										<GridContainer>
											<GridItem xs={8}>
												<GenericTextInput
													MultiLine={true}
													RowCount={2}
													LabelMd={2}
													Name="ResolveExplanation"
													inputProps={this.maxLength150}
													LabelText=" Resolve Explanation"
													Value={model.ResolveExplanation}
													ValueChanged={this.ValueChanged}
													Disabled={false}
												/>
											</GridItem>
										</GridContainer>

									</CardBody>
								</Card>
							</GridItem>

						</GridContainer>

					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.HandleClickSubmit}>Submit</Button>
						<Button size="sm" onClick={this.CancelSelectCallback}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>
			</>
		);
	}
}

ClientDisputeReportAction.propTypes = {
	classes: PropTypes.object.isRequired,
	model: PropTypes.object,
	onModelChange: PropTypes.func,
	Disabled: PropTypes.bool
};

export default ClientDisputeReportAction;