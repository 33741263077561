
import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import RouteHelper from "core/RouteHelper";
import moment from "moment";
import PropTypes, { any } from "prop-types";
import React from "react";
import BillIndividual from "views/BankAccount/BillPayment/BillDefinition/BillIndividual";
import BillRecurring from "views/BankAccount/BillPayment/BillDefinition/BillRecurring";
import AccountLimitToolbar from "views/Components/AccountLimitToolbar";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericDialog, GenericDialogActions, GenericLabel, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { BillPayType, PeriodCodes, ProgramCodes } from "views/Constants/Constant";



class ClientBillDefinition extends React.Component {

	constructor(props) {
		super(props);
		this.isClient = ClientHelper.IsClient();
		this.renderItemBiller = {};

		this.initialModel = {
			Id: 0
		};
		this.defaultRecurringModel = {
			Id: null,
			ClientCustomerId: null,
			PayeeName: "",
			NewClient: false,
			StartDate: DateHelper.GetLocalDateAsUtc(),
			EndDate: DateHelper.GetLocalDateAsUtc(),
			PaymentCount: null,
			RecurringAmount: null,
			TotalAmount: null,
			BillDescription: "",
			PeriodId: null,
			PeriodCode: null,
			WeeklyId: null,
			WeeklyNumber: null,
			BiWeeklyId: null,
			BiWeeklyNumber: null,
			MonthlyId: null,
			MonthlyNumber: null,
			QuarterlyId: null,
			QuarterlyNumber: null,
			IsCustomerPopupOpen: false,
			TransactionType: "",
			TransactionTypeCode: "",
			TransactionCode: "",
			FreezeStartDate: null,
			FreezeEndDate: null,
			BillPayTypeId: null,
			IsPayed: false,
			PayIndex: null,
			TransactionDate: moment().startOf("day").toDate(),
			AccountNumber: null
		};

		this.defaultIndividualModel = {
			Id: null,
			ClientCustomerId: null,
			PayeeName: "",
			NewClient: false,
			TransitNumber: "",
			AccountNumber: null,
			Amount: null,
			AccountName: "",
			TransactionDate: moment().startOf("day").toDate(),
			BillDescription: "",
			IsCustomerPopupOpen: false,
			BillPayTypeId: null
		};


		this.defaultBillerModel = {
			Id: 0,
			Name: "",
			Code: "",
			GroupId: "",
			TypeId: 0,
			ProvinceId: 0,
			IsNewBiller: false,
			NewBiller: false
		};

		this.state = {

			vIndividualModel: {},
			vRecurringModel: {},
			vModel: {},

			BillerList: [],
			StaticBillerList: [],
			OnlineBillerList: [],
			BillList: [],
			model: {},
			list: [],
			selected: null,
			isLoading: false,
			alert: null,
			searchModel: {},
			BillProfile: {},
			bankAccounts: [],
			IsPayIndivudalActive: true,
			IsPayRecurringActive: true,
			customerAccountProfile: {},
			ValidationMessage: "",
			ClientCustomerName: "",
			IsOpenPayeeDefinitionDialog: false,
			isIndividualDialogOpen: false,
			isRecurringDialogOpen: false,
			isBillerDialogOpen: false,
			isClearDialogOpen: false,
			BillRecurringModel: { ...this.defaultRecurringModel },
			BillIndividualModel: { ...this.defaultIndividualModel },
			BillerModel: { ...this.defaultBillerModel },
			ErrorList: [],
			LimitToken: false,
			readOnlyData: {},
			LimitBalanceData: {
				AvailableCreditLimit: 0,
				AvailableDebitLimit: 0,
				AvailableBalance: 0
			},
			customertoken: 0
		};
		this.RecurringModelChange = this.RecurringModelChange.bind(this);
		this.IndividualModelChange = this.IndividualModelChange.bind(this);
		this.BillerModelChange = this.BillerModelChange.bind(this);
		this.RefreshToolBar = this.RefreshToolBar.bind(this);

		this.hideAlert = this.hideAlert.bind(this);
		this.GetClientType = this.GetClientType.bind(this);

	}



	componentDidMount() {

		const { setAppLeftTitle, setAppCenterTitle } = this.props;
		var model = { ...this.state.model };

		if (setAppLeftTitle)
			setAppLeftTitle("Create Bill Payment");
		if (setAppCenterTitle)
			setAppCenterTitle("WALLET");

		var tempmodel = RouteHelper.ReadAndClear("BillModel");
		if (!setAppLeftTitle || tempmodel != any && tempmodel != null) {
			this.SelectedRowChange(0, tempmodel);
			model.ClientId = ClientHelper.GetClientRowId();
			model.IsUpdate = true;
			this.GetClientType(model.ClientId);
			this.GetBiller(this.state.model);

		}
		else {
			this.setState({ model });
		}

	}


	Validate = () => {
		const { model, BillIndividualModel, BillRecurringModel, vModel, vIndividualModel, vRecurringModel } = this.state;
		
		var billMessages = [];

		if (model.BillerCode == null || model.BillerCode == "") { vModel.BillerCode = true; billMessages.push("Payee Name can not be null"); } else { vModel.BillerCode = false; }

		switch (model.BillPayType) {
			case BillPayType.Individual:
				vModel.BillPayTypeId = false;
				if (!BillIndividualModel.AccountNumber) { vIndividualModel.AccountNumber = true; billMessages.push("Payee Wallet Number cannot be null."); } else { vIndividualModel.AccountNumber = false; }
				if (!BillIndividualModel.Amount) { vIndividualModel.Amount = true; billMessages.push("Amount cannot be null."); } else { vIndividualModel.Amount = false; }

				// if (IsClient) {
				// 	if (LimitBalanceData == null) billMessages.push("Limit Balance data is empty.");
				// 	else if (!Disabled && !model.IsUpdate && LimitBalanceData.AvailableBalance != null && LimitBalanceData.AvailableBalance < BillIndividualModel.Amount) billMessages.push("Transaction Amount cannot be higher than balance.");
				// }
				break;

			case BillPayType.Recurring:
				vModel.BillPayTypeId = false;
				if (BillRecurringModel.AccountNumber == null || BillRecurringModel.AccountNumber == "") { vRecurringModel.AccountNumber = true; billMessages.push("Payee Wallet Number cannot be null."); } else { vRecurringModel.AccountNumber = false; }

				if (BillRecurringModel.StartDate == null) { vRecurringModel.StartDate = true; billMessages.push("Start Date cannot be null."); }
				else if (typeof BillRecurringModel.StartDate != "object") { vRecurringModel.StartDate = true; billMessages.push("Start Date is invalid."); }
				else { vRecurringModel.StartDate = false; }

				if (BillRecurringModel.EndDate == null) { vRecurringModel.EndDate = true; billMessages.push("End Date cannot be null."); }
				else if (typeof BillRecurringModel.EndDate != "object") { vRecurringModel.EndDate = true; billMessages.push("End Date is invalid."); }
				else if (DateHelper.ToMoment(BillRecurringModel.EndDate).startOf("day") < DateHelper.GetDate()) { vRecurringModel.EndDate = true; billMessages.push("End Date cannot be past."); }
				else { vRecurringModel.EndDate = false; }

				if (typeof BillRecurringModel.StartDate == "object" && typeof BillRecurringModel.EndDate == "object" && BillRecurringModel.StartDate > BillRecurringModel.EndDate) billMessages.push("Start Date cannot be after End Date.");

				if (model.IsUpdate) {
					if (BillRecurringModel.FreezeStartDate != null && typeof BillRecurringModel.FreezeStartDate != "object") { vRecurringModel.FreezeStartDate = true; billMessages.push("Freeze Start Date is invalid."); }
					else if (DateHelper.ToMoment(BillRecurringModel.FreezeStartDate).startOf("day") < DateHelper.ToMoment(BillRecurringModel.StartDate).startOf("day")) { vRecurringModel.FreezeStartDate = true; billMessages.push("Freeze Start Date cannot be before Start Date."); }
					else { vRecurringModel.FreezeStartDate = false; }


					if (BillRecurringModel.FreezeEndDate != null && typeof BillRecurringModel.FreezeEndDate != "object") { vRecurringModel.FreezeEndDate = true; billMessages.push("Freeze End Date is invalid."); }
					else if (DateHelper.ToMoment(BillRecurringModel.FreezeEndDate).startOf("day") > DateHelper.ToMoment(BillRecurringModel.EndDate).startOf("day")) { vRecurringModel.FreezeEndDate = true; billMessages.push("Freeze End Date cannot be after End Date."); }
					else { vRecurringModel.FreezeEndDate = false; }

					if (!BillRecurringModel.FreezeStartDate && BillRecurringModel.FreezeEndDate) { vRecurringModel.FreezeStartDate = true; billMessages.push("Freeze Start Date cannot be empty."); }
					else { vRecurringModel.FreezeStartDate = false; }

					if (!BillRecurringModel.FreezeEndDate && BillRecurringModel.FreezeStartDate) { vRecurringModel.FreezeEndDate = true; billMessages.push("Freeze End Date cannot be empty."); }
					else { vRecurringModel.FreezeEndDate = false; }
				}
				if (BillRecurringModel.PaymentCount == null || BillRecurringModel.PaymentCount == "0") { vRecurringModel.PaymentCount = true; billMessages.push("Period/No. Of Payment cannot be null."); } else { vRecurringModel.PaymentCount = false; }
				if (BillRecurringModel.RecurringAmount == null || BillRecurringModel.RecurringAmount == "" || BillRecurringModel.RecurringAmount == 0) { vRecurringModel.RecurringAmount = true; billMessages.push("Recurring Amount cannot be null."); } else { vRecurringModel.RecurringAmount = false; }
				if (BillRecurringModel.PeriodId == null || BillRecurringModel.PeriodId == "") { vRecurringModel.PeriodId = true; billMessages.push("First Due Date Options is not selected."); } else { vRecurringModel.PeriodId = false; }
				if (BillRecurringModel.PeriodCode == PeriodCodes.Weekly && (BillRecurringModel.WeeklyNumber == null || BillRecurringModel.WeeklyNumber == "")) billMessages.push("Day of week is not selected.");
				if (BillRecurringModel.PeriodCode == PeriodCodes.BiWeekly && (BillRecurringModel.BiWeeklyNumber == null || BillRecurringModel.BiWeeklyNumber == "")) billMessages.push("Day of week is not selected.");
				if (BillRecurringModel.PeriodCode == PeriodCodes.Monthly && (BillRecurringModel.MonthlyNumber == null || BillRecurringModel.MonthlyNumber == "")) billMessages.push("Day of month is not selected.");
				if (BillRecurringModel.PeriodCode == PeriodCodes.Quarterly && (BillRecurringModel.QuarterlyNumber == null || BillRecurringModel.QuarterlyNumber == "")) billMessages.push("Day of first month of quarter is not selected.");

				break;

			default:
				this.ShowMessage("warning", "Bill Payment Type not selected", "Bill Payment Type not selected.");
				vModel.BillPayTypeId = true;
				break;
		}
		if (billMessages.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", billMessages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		else
			return true;

	}

	RecurringModelChange(modelFunction) {
		this.setState(function (state, props) {
			var model = state.BillRecurringModel || {};

			if (modelFunction)
				model = modelFunction(model);

			return { BillRecurringModel: model };
		});
	}

	BillerModelChange(name, newValue, data) {

		this.setState(function (state) {
			var model = state.model || {};
			if (data) {
				model.BillerId = data.Id;
				model[name] = newValue;
				model.BillerCode = data.BillerCode;
				model.BillerName = data.BillerName;
				this.GetLastBill(model);
			}
			return { model };
		});
	}


	IndividualModelChange(modelFunction) {
		this.setState(function (state, props) {
			var model = state.BillIndividualModel || {};

			if (modelFunction)
				model = modelFunction(model);

			return { BillIndividualModel: model };
		});
	}


	ClearDialog = () => {
		const { model } = { ...this.state };
		this.showClearQuestionMessage("Are you sure you want to clear on the screen entered information? ", model);
	}

	UpdateDialog = () => {

		const { model } = { ...this.state };
		if (model.IsUpdate && !model.IsDisable) {
			if (!this.Validate())
				return false;
			switch (model.BillPayType) {
				case BillPayType.Individual:
					this.setState({ isIndividualDialogOpen: true });
					break;
				case BillPayType.Recurring:
					this.setState({ isRecurringDialogOpen: true });
					break;
				default:
					break;
			}
		}

	}
	errorDefinitionCallback(error) {
		this.ShowMessage("error", "Error !", "An error occurred while requesting data" + error);
	}
	ShowMessage(type, title, message) {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});

	}

	hideAlert() {
		this.setState({ alert: null, isLoading: false });
	}

	GetTelpayBiller = (billerName) => {
		Proxy.POST("/bankapi/v1.0/Biller/GetTelpayBiller",
			{
				Name: billerName
			},
			responseData => {
				if (responseData.IsSucceeded != false) {
					this.setState({ BillerList: responseData.Item });
				}
				else {
					this.setState({ BillerList: [] });
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			});
	}


	GetBiller = (model) => {

		if (!model || !model.BillPayType) {
			this.setState({ BillerList: [] });
			return;
		}

		Proxy.POST("/bankapi/v1.0/Biller/GetAllBiller",
			{
				IsCustomer: false,
				BillPayType: model.BillPayType == BillPayType.Individual ? 1 : 2
			},
			responseData => {
				if (responseData.IsSucceeded != false) {
					this.setState({ BillerList: responseData.Item });
				}
				else { this.setState({ BillerList: [] }); }
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			});
	}

	ValueChanged = (name, value) => {
		if (name == "Code") {
			this.GetTelpayBiller(value);
		}
		const model = { ...this.state.model };
		model[name] = value;
		this.setState({ model });
	}

	handleChange = (name, value, data) => {
		const model = { ...this.state.model };
		const BillRecurringModel = { ...this.state.BillRecurringModel };
		const BillIndividualModel = { ...this.state.BillIndividualModel };
		if (data) {
			model[name] = value;

			if (name == "NewClient") {
				if (value) {
					model.IsCustomerPopupOpen = true;
					this.setState({
						isCustomerAdded: false,
						customertoken: 0
					});
				}
			}

			if (name == "BillPayTypeId") {
				if (data.ParameterValue == BillPayType.Individual) {
					model.BillPayType = BillPayType.Individual;
					this.setState({
						IsPayIndivudalActive: false,
						IsPayRecurringActive: true
					});
				}
				else {
					model.BillPayType = BillPayType.Recurring;
					this.setState({
						IsPayIndivudalActive: true,
						IsPayRecurringActive: false
					});
				}
				this.GetLastBill(model);
				this.GetBiller(model);
			}

		}

		this.setState({ model, BillRecurringModel, BillIndividualModel });
	}

	SelectedRowChange = (e, d) => {

		const { model, BillerModel, BillRecurringModel, BillIndividualModel } = { ...this.state };
		if (d) {
			if (d.BillPayTypeValue == BillPayType.Individual) {
				model.BillPayType = BillPayType.Individual;
				model.BillPayTypeId = d.BillPayTypeId;
				model.ClientCustomer = d.ClientCustomerId;
				model.ClientCustomerId = d.ClientCustomerId;
				model.IsUpdate = true;
				model.BillerId = d.BillerId;
				model.BillerCode = d.BillerCode;
				model.SourceAccountId = d.SourceAccountId;
				model.BillerName = d.BillerName;

				BillIndividualModel.Id = d.Id;
				BillIndividualModel.Amount = d.Amount;
				BillIndividualModel.AccountNumber = d.BillAccountNumber;
				BillIndividualModel.BillDescription = d.BillDescription;

				this.setState({
					BillerModel: BillerModel,
					BillIndividualModel: BillIndividualModel,
					IsPayRecurringActive: true,
					IsPayIndivudalActive: false,
					model: model
				});

			}

			else if (d.BillPayTypeValue == BillPayType.Recurring) {
				model.BillPayType = BillPayType.Recurring;
				model.BillPayTypeId = d.BillPayTypeId;
				model.ClientCustomer = d.ClientCustomerId;
				model.ClientCustomerId = d.ClientCustomerId;
				model.IsUpdate = true;
				model.BillerId = d.BillerId;
				model.BillerCode = d.BillerCode;
				model.SourceAccountId = d.SourceAccountId;
				model.BillerName = d.BillerName;

				BillRecurringModel.IsPayed = d.IsHaveTransaction;
				BillRecurringModel.Id = d.BillId;
				BillRecurringModel.RecurringAmount = d.AutoPayAmount;
				BillRecurringModel.PaymentCount = d.PaymentCount;
				BillRecurringModel.TotalAmount = d.TotalAmount;
				BillRecurringModel.AccountNumber = d.BillAccountNumber;
				BillRecurringModel.StartDate = d.StartDate ? DateHelper.ToMoment(d.StartDate) : null;
				BillRecurringModel.EndDate = d.EndDate ? DateHelper.ToMoment(d.EndDate) : null;
				BillRecurringModel.FreezeStartDate = d.FreezeStartDate ? DateHelper.ToMoment(d.FreezeStartDate) : null;
				BillRecurringModel.FreezeEndDate = d.FreezeEndDate ? DateHelper.ToMoment(d.FreezeEndDate) : null;
				BillRecurringModel.PeriodId = d.FirstDueDateOptionId;
				BillRecurringModel.PeriodCode = d.PeriodCode;
				BillRecurringModel.BillDescription = d.BillDescription;

				BillRecurringModel.WeeklyId = d.PeriodWeeklyId;
				BillRecurringModel.WeeklyNumber = d.PeriodWeekly != null ? d.PeriodWeekly : "";

				BillRecurringModel.BiWeeklyId = d.PeriodBiWeeklyId;
				BillRecurringModel.BiWeeklyNumber = d.PeriodBiWeekly != null ? d.PeriodBiWeekly : "";

				BillRecurringModel.MonthlyId = d.PeriodMonthlyId;
				BillRecurringModel.MonthlyNumber = d.PeriodMonthly != null ? d.PeriodMonthly : "";

				BillRecurringModel.QuarterlyId = d.PeriodQuarterlyId;
				BillRecurringModel.QuarterlyNumber = d.PeriodQuarterly != null ? d.PeriodQuarterly : "";

				BillRecurringModel.PayIndex = d.PayIndex;

				this.setState({
					BillerModel: BillerModel,
					BillRecurringModel: BillRecurringModel,
					IsPayRecurringActive: false,
					IsPayIndivudalActive: true,
					model: model,
				});
			}
		}
	}

	GetLastBill = (model) => {
		if (model.BillPayTypeId && model.BillerCode && !model.IsUpdate) {
			this.setState({ isLoading: true });
			Proxy.POST(
				"/bankapi/v1.0/Bill/LastBillTransaction",
				{
					"BillPayType": model.BillPayType == BillPayType.Individual ? 1 : 2,
					"BillPayTypeId": model.BillPayTypeId,
					"ClientCustomerId": 0,
					"BillerCode": model.BillerCode
				},
				(responseData) => {
					this.setState({ isLoading: false });

					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					this.FillLastData(responseData.Item);
				},
				(error) => {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "An error occurred while requesting data", error.message);
				}
			);
		}
	}

	GetClientType(clientId) {
		const model = { ...this.state.model };
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/Bill/GetClientType",
			{
				"Id": clientId
			},
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				model.ClientType = responseData.Item;
				this.setState({
					isLoading: false,
					model: model,
					LimitToken: true
				});
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while requesting data", error.message);
			}
		);
	}


	ClearScreen = () => {
		this.setState({ model: { ...any }, isClearDialogOpen: false, isLoading: true, alert: null, BillerList: [] });
		this.IndividualModelChange(() => { return { ...this.defaultIndividualModel }; });
		this.RecurringModelChange(() => { return { ...this.defaultRecurringModel }; });
		this.setState({ isLoading: false, IsPayIndivudalActive: true, IsPayRecurringActive: true, BillerList: [] });
		this.state.model = any;
		this.GetBiller();
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
	}

	SubmitDialog = (workflowId, after, accessToken) => {
		const { model } = this.state;
		if (!model.IsUpdate) {
			if (!this.Validate())
				return false;

			switch (model.BillPayType) {
				case BillPayType.Individual:
					this.setState({ workflowId, after, isIndividualDialogOpen: true, accessToken });
					break;
				case BillPayType.Recurring:
					this.setState({ workflowId, after, isRecurringDialogOpen: true, accessToken });
					break;
				default:
					break;
			}
		}
	}

	RefreshToolBar() {
		this.setState({ LimitToken: !this.state.LimitToken });
	}

	GetModelId() {
		const { model, BillIndividualModel, BillRecurringModel } = this.state;

		switch (model.BillPayType) {
			case BillPayType.Individual: return BillIndividualModel.Id;
			case BillPayType.Recurring: return BillRecurringModel.Id;
			default: return null;
		}
	}
	Submit = () => {
		this.setState({ isLoading: true });
		const { BillRecurringModel, BillIndividualModel } = this.state;
		const { model, workflowId, after, accessToken } = { ...this.state };

		if (model.BillPayType == BillPayType.Recurring) {
			var recurringRequest = {
				BillerName: model.BillerName,
				BillerCode: model.BillerCode,
				Id: BillRecurringModel.Id,
				BillerId: model.BillerId,
				RecurringAmount: BillRecurringModel.RecurringAmount,
				TotalAmount: BillRecurringModel.TotalAmount,
				ClientCustomerId: 0,
				BillDescription: BillRecurringModel.BillDescription,
				StartDate: BillRecurringModel.StartDate,
				EndDate: BillRecurringModel.EndDate,
				FreezeStartDate: BillRecurringModel.FreezeStartDate,
				FreezeEndDate: BillRecurringModel.FreezeEndDate,
				PaymentCount: BillRecurringModel.PaymentCount,
				FirstDueDateOptionId: BillRecurringModel.PeriodId,
				PeriodWeeklyId: BillRecurringModel.PeriodCode == PeriodCodes.Weekly ? BillRecurringModel.WeeklyId : "",
				PeriodBiWeeklyId: BillRecurringModel.PeriodCode == PeriodCodes.BiWeekly ? BillRecurringModel.BiWeeklyId : "",
				PeriodMonthlyId: BillRecurringModel.PeriodCode == PeriodCodes.Monthly ? BillRecurringModel.MonthlyId : "",
				PeriodQuarterlyId: BillRecurringModel.PeriodCode == PeriodCodes.Quarterly ? BillRecurringModel.QuarterlyId : "",
				UniqueClientId: ClientHelper.GetClientRowId(),
				BillPayTypeId: model.BillPayTypeId,
				SourceAccountId: model.SourceAccountId,
				AccountNumber: BillRecurringModel.AccountNumber,

			};

			this.setState({ isLoading: true });

			if (!model.IsUpdate) {
				Proxy.POST("/bankapi/v1.0/Bill/InsertRecurringBill",
					{ BillRecurringModel: recurringRequest },
					responseData => {
						this.setState({ isLoading: false, isRecurringDialogOpen: false });

						if (!responseData.IsSucceeded) {
							if (responseData.ErrorCode == "SysError")
								this.ShowMessage("error", "Error", responseData.ErrorDescription);
							else
								this.ShowMessage("warning", "Warning", responseData.ErrorDescription);
							return;
						}
						else {
							if (after) after();
							this.ClearScreen();
							this.RefreshToolBar();
							this.GetBiller();
							this.ShowMessage("success", "Recurring Transaction  successfully saved.");
						}
					},
					e => {
						this.setState({ isLoading: false, isRecurringDialogOpen: false });
						this.ShowMessage("error", "An error occurred while updating data", typeof e == "object" ? JSON.stringify(e) : e);
					},
					workflowId,
					accessToken
				);
			}
			else {
				Proxy.POST("/bankapi/v1.0/Bill/UpdateRecurringBill",
					{ BillRecurringModel: recurringRequest },
					responseData => {
						this.setState({ isLoading: false, isRecurringDialogOpen: false });

						if (!responseData.IsSucceeded) {
							if (responseData.ErrorCode == "SysError")
								this.ShowMessage("error", "Error", responseData.ErrorDescription);
							else
								this.ShowMessage("warning", "Warning", responseData.ErrorDescription);
							return;
						}
						else {
							if (after) after();
							this.ClearScreen();
							this.RefreshToolBar();
							this.GetBiller();
							this.ShowMessage("success", "Recurring Transaction successfully updated.");
						}
					},
					e => {
						this.setState({ isLoading: false, isRecurringDialogOpen: false });
						this.ShowMessage("error", "An error occurred while inserting data", typeof e == "object" ? JSON.stringify(e) : e);
					},
					workflowId,
					accessToken
				);
			}
		}
		else {

			var individualRequest = {
				Id: BillIndividualModel.Id,
				Amount: BillIndividualModel.Amount,
				ClientCustomerId: model.ClientCustomerId,
				UniqueClientId: model.ClientId,
				BillerId: model.BillerId,
				AccountNumber: BillIndividualModel.AccountNumber,
				BillDescription: BillIndividualModel.BillDescription,
				AccountName: BillIndividualModel.AccountName,
				BillPayTyeId: model.BillPayTypeId,
				ClientCustomerName: model.ClientCustomerName,
				BillerCode: model.BillerCode,
				BillerName: model.BillerName,
				SourceAccountId: model.SourceAccountId

			};

			if (!model.IsUpdate) {
				this.setState({ isLoading: true });

				Proxy.POST("/bankapi/v1.0/Bill/InsertIndividualBill",
					{ BillIndividualModel: individualRequest },
					responseData => {
						this.setState({ isLoading: false, isIndividualDialogOpen: false });

						if (!responseData.IsSucceeded) {
							if (responseData.ErrorCode == "SysError")
								this.ShowMessage("error", "Error", responseData.ErrorDescription);
							else
								this.ShowMessage("warning", "Warning", responseData.ErrorDescription);
							return;
						}
						else {
							if (after) after();
							this.GetBiller();
							this.ClearScreen();
							this.ShowMessage("success", "Individual Transaction Entry successfully saved.");
							this.RefreshToolBar();

						}
					},
					e => {
						this.setState({ isLoading: false, isIndividualDialogOpen: false });
						this.ShowMessage("error", "An error occurred while inserting data", typeof e == "object" ? JSON.stringify(e) : e);
					},
					workflowId,
					accessToken
				);
			}
			else {
				Proxy.POST("/bankapi/v1.0/Bill/UpdateIndividualBill",
					{ BillIndividualModel: individualRequest },
					responseData => {
						this.setState({ isLoading: false, isIndividualDialogOpen: false });

						if (!responseData.IsSucceeded) {
							if (responseData.ErrorCode == "SysError")
								this.ShowMessage("error", "Error", responseData.ErrorDescription);
							else
								this.ShowMessage("warning", "Warning", responseData.ErrorDescription);
							return;
						}
						else {
							if (after) after();

							this.ClearScreen();
							this.GetBiller();
							this.RefreshToolBar();
							this.ShowMessage("success", "Individual Transaction successfully updated.");
						}
					},
					e => {
						this.setState({ isLoading: false, isRecurringDialogOpen: false });
						this.ShowMessage("error", "An error occurred while inserting data", typeof e == "object" ? JSON.stringify(e) : e);
					},
					workflowId,
					accessToken
				);
			}

		}
	}



	showClearQuestionMessage = (message, model) => {
		this.setState({
			alert: <GenericAlert Title="Cancel" Message={message} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.ClearScreen()} />
		});
		this.setState({ isLoading: false });
	}


	BillerNameChange = (name, newValue) => {

		if (name == "BillerCode" && newValue != "") {
			//var result = this.state.BillerList.filter(x => x.BillerName != null && x.BillerName.toLocaleLowerCase().indexOf(newValue.toLocaleLowerCase()) != -1);
			if (newValue.length > 2)
				this.GetTelpayBiller(newValue);
		}
	}

	GetModel = () => {
		const { model, BillIndividualModel, BillRecurringModel } = this.state;

		switch (model.BillPayType) {
			case BillPayType.Individual:
				model.ClientId = ClientHelper.GetClientRowId();
				BillIndividualModel.BillerCode = model.BillerCode;
				BillIndividualModel.ClientCustomerId = model.ClientCustomerId;
				BillIndividualModel.BillerName = model.BillerName;
				return { model, BillIndividualModel: BillIndividualModel };
			case BillPayType.Recurring:
				model.ClientId = ClientHelper.GetClientRowId();
				BillRecurringModel.BillerCode = model.BillerCode;
				BillRecurringModel.ClientCustomerId = model.ClientCustomerId;
				BillRecurringModel.BillerName = model.BillerName;
				return { model, BillRecurringModel: BillRecurringModel };
			default: return null;
		}
	}


	FillData = (data) => {

		const { model, BillIndividualModel, BillRecurringModel } = data;


		switch (model.BillPayType) {
			case BillPayType.Individual:
				BillIndividualModel.BillerName = model.BillerName;
				this.setState({ model, BillIndividualModel });
				break;
			case BillPayType.Recurring:
				BillRecurringModel.BillerName = model.BillerName;
				BillRecurringModel.StartDate = BillRecurringModel.StartDate ? DateHelper.ToMoment(BillRecurringModel.StartDate) : null;
				BillRecurringModel.EndDate = BillRecurringModel.EndDate ? DateHelper.ToMoment(BillRecurringModel.EndDate) : null;
				BillRecurringModel.FreezeStartDate = BillRecurringModel.FreezeStartDate ? DateHelper.ToMoment(BillRecurringModel.FreezeStartDate) : null;
				BillRecurringModel.FreezeEndDate = BillRecurringModel.FreezeEndDate ? DateHelper.ToMoment(BillRecurringModel.FreezeEndDate) : null;
				this.setState({ model, BillRecurringModel });
				break;
			default: return;
		}
	}

	FillLastData = (data) => {

		if (data) {
			const { BillIndividualModel, BillRecurringModel } = data;

			if (BillIndividual && BillIndividualModel != null && BillIndividualModel != any) {
				this.setState({ BillIndividualModel });
			}
			else if (BillRecurringModel && BillRecurringModel != null && BillRecurringModel != any) {
				BillRecurringModel.RecurringAmount = null;
				BillRecurringModel.TotalAmount = null;
				BillRecurringModel.PeriodId = BillRecurringModel.FirstDueDateOptionId;
				BillRecurringModel.WeeklyId = BillRecurringModel.PeriodWeeklyId;
				BillRecurringModel.WeeklyNumber = BillRecurringModel.PeriodWeekly != null ? BillRecurringModel.PeriodWeekly : "";
				BillRecurringModel.BiWeeklyId = BillRecurringModel.PeriodBiWeeklyId;
				BillRecurringModel.BiWeeklyNumber = BillRecurringModel.PeriodBiWeekly != null ? BillRecurringModel.PeriodBiWeekly : "";
				BillRecurringModel.MonthlyId = BillRecurringModel.PeriodMonthlyId;
				BillRecurringModel.MonthlyNumber = BillRecurringModel.PeriodMonthly != null ? BillRecurringModel.PeriodMonthly : "";
				BillRecurringModel.QuarterlyId = BillRecurringModel.PeriodQuarterlyId;
				BillRecurringModel.QuarterlyNumber = BillRecurringModel.PeriodQuarterly != null ? BillRecurringModel.PeriodQuarterly : "";
				BillRecurringModel.StartDate = BillRecurringModel.StartDate ? DateHelper.ToMoment(BillRecurringModel.StartDate) : null;
				BillRecurringModel.EndDate = BillRecurringModel.EndDate ? DateHelper.ToMoment(BillRecurringModel.EndDate) : null;
				BillRecurringModel.FreezeStartDate = null;
				BillRecurringModel.FreezeEndDate = null;
				this.setState({ BillRecurringModel });
			} else {
				this.setState({ BillIndividualModel: { ...this.defaultIndividualModel }, BillRecurringModel: { ...this.defaultRecurringModel } });
			}
		}
		else {
			this.setState({ BillIndividualModel: { ...this.defaultIndividualModel }, BillRecurringModel: { ...this.defaultRecurringModel } });
		}
	}

	BillerCodeDisable = () => {
		const { model, BillRecurringModel } = this.state;

		if (model.IsUpdate && model.BillPayType == BillPayType.Individual)
			return false;

		else if (model.BillPayType == BillPayType.Recurring && model.IsUpdate && BillRecurringModel && BillRecurringModel.PayIndex && BillRecurringModel.PayIndex > 0)
				return true;
			else
				return false;
		
	}

	GetRenderItemBiller = (x) => {
		this.renderItemBiller = `${x.BillerName} - ${x.BillerCode}`;
		return this.renderItemBiller;
	}
	render() {
		const { model, BillRecurringModel, BillIndividualModel, BillerList, LimitToken, isRecurringDialogOpen, isIndividualDialogOpen, vRecurringModel, vIndividualModel, vModel } = this.state;
		const { Disabled } = this.props;

		return (
			<div>

				<GridContainer spacing={16}>
					{this.state.alert}
					<GridItem xs={12}>

						<LoadingComponent Show={this.state.isLoading} />

						{this.isClient &&
							<AccountLimitToolbar key={LimitToken} Program={ProgramCodes.Bank_Programs_Bank_Account} LimitMainType={ProgramCodes.BillPayment} IsUseDisableDebitLimit={true} OnLoad={data => { this.setState({ LimitBalanceData: data, isLoading: false }); }} />
						}
						<ButtonToolbar
							ButtonList={[

								{ Code: "Submit", Disabled: Disabled || model.IsUpdate, OnClick: this.SubmitDialog, ModelFunction: this.GetModel, FillDataFromModel: this.FillData, RowId: this.GetModelId(), ValidationFunction: this.Validate },
								{ Code: "Update", Disabled: Disabled || !model.IsUpdate, OnClick: this.UpdateDialog, ModelFunction: this.GetModel, FillDataFromModel: this.FillData, RowId: this.GetModelId(), ValidationFunction: this.Validate },
								{ Code: "Clear", Disabled: Disabled, OnClick: this.ClearDialog }
							]}
							menuId={this.props.menuId}
							ApprovalData={this.props.ApprovalData}
							After={this.props.After} />

						{this.state.alert}

						<GridContainer>
							<GridItem xs={6}>
								<Card className="no-radius" style={{ height: "90%", opacity: model.IsUpdate ? 0.4 : 1 }}>
									<CardHeader><GenericTitle text={"Main Parameters"} /></CardHeader>
									<CardBody>
										<GridContainer>
											<GridItem xs={10}>

												<ParameterComponent
													Name="BillPayTypeId"
													LabelText="Bill Payment Type"
													LabelMd={4}
													ParameterCode="BillPayType"
													ParameterValue2="1"
													Value={model.BillPayTypeId}
													ValueChanged={this.handleChange}
													Disabled={Disabled || model.IsUpdate}
													Required
													IsInvalid={vModel.BillPayTypeId}
												/>
											</GridItem>
										</GridContainer>

									</CardBody>
								</Card>
							</GridItem>

							<GridItem xs={6}>

								<Card style={{ height: "90%", opacity: "1" }}>
									<CardHeader> <GenericTitle text={"Select  Payee"} /></CardHeader>
									<CardBody>
										<GridContainer >
											<GridItem xs={10}>
												{!Disabled &&
													<GenericSelectInput
														Name="BillerCode"
														StaticData={BillerList}
														isStatic={true}
														Value={model.BillerCode}
														KeyValueMember="BillerCode"
														Disabled={Disabled || this.BillerCodeDisable()}
														TextValueMember="BillerName"
														LabelText="Payee Name"
														ValueChanged={this.BillerModelChange}
														InputValueChanged={this.BillerNameChange}
														Required
														IsInvalid={vModel.BillerCode}
														RenderItem={this.GetRenderItemBiller}

													/>
												}
												{Disabled &&
													<GenericTextInput
														Name="BillerName"
														Value={model.BillerName}
														Disabled={Disabled}
														LabelText="Payee Name"
													/>
												}
											</GridItem>
										</GridContainer>

									</CardBody>
								</Card>
							</GridItem>
						</GridContainer>


						<GridContainer>
							<GridItem xs={12}>
								<BillIndividual
									vModel={vIndividualModel}
									model={this.state.BillIndividualModel}
									onModelChange={this.IndividualModelChange}
									Disabled={this.state.IsPayIndivudalActive}
								/>
							</GridItem>
						</GridContainer>

						<GridContainer>
							<GridItem xs={12}>
								<BillRecurring
									vModel={vRecurringModel}
									model={this.state.BillRecurringModel}
									onModelChange={this.RecurringModelChange}
									Disabled={this.state.IsPayRecurringActive}
								/>
							</GridItem>
						</GridContainer>

						<GenericDialog open={isRecurringDialogOpen} maxWidth="md" fullWidth>
							<DialogTitle><GenericLabel Text="Transaction Confirmation" Bold={true} />
							</DialogTitle>
							<DialogContent>
								<GridContainer xs={12}>
									<GridItem xs={12}>
										<GenericTextInput
											LabelMd={2}
											Name="Name"
											LabelText="Payee Name"
											inputProps={{ maxLength: 50 }}
											Value={model.BillerName}
											Disabled={true}
										/>

										<GenericTextInput
											LabelMd={2}
											inputProps={{ maxLength: 29 }}
											LabelText="Payee Wallet Number"
											Name="AccountNumber"
											Value={BillRecurringModel.AccountNumber}
											Disabled={true} />
										<GenericTextInput LabelText="Period/No. Of Payment" Value={BillRecurringModel.PaymentCount} Disabled={true} LabelMd={2} />
										<GenericNumberInput Name="RecurringAmount" LabelText="Recurring Amount" Value={BillRecurringModel.RecurringAmount} MaxLength={10} Disabled={true} LabelMd={2} />
										<GenericNumberInput LabelText="Total Amount" Value={BillRecurringModel.RecurringAmount && BillRecurringModel.PaymentCount && BillRecurringModel.RecurringAmount * BillRecurringModel.PaymentCount} Disabled={true} LabelMd={2} />
										<GenericDateInput Name="StartDate" LabelText="Start Date" Value={Formatter.FormatDateUtc(BillRecurringModel.StartDate)} Disabled={true} LabelMd={2} />
										<GenericDateInput Name="EndDate" LabelText="End Date" Value={Formatter.FormatDateUtc(BillRecurringModel.EndDate)} Disabled={true} LabelMd={2} />
										<br />
										<GenericLabel Text={
											model.IsUpdate ?
												"Are you sure you want to apply changes for this transaction ?" :
												"Are you sure you want to enter this transaction ?"} Bold={true} />

									</GridItem>
								</GridContainer>
							</DialogContent>
							<GenericDialogActions>
								<Button size="sm" onClick={this.Submit}>Confirm</Button>
								<Button size="sm" onClick={() => this.setState({ isRecurringDialogOpen: false })}>Cancel</Button>
							</GenericDialogActions>
						</GenericDialog>

						<GenericDialog open={isIndividualDialogOpen} maxWidth="md" fullWidth>
							<DialogTitle><GenericLabel Text="Transaction Confirmation" Bold={true} />
							</DialogTitle>
							<DialogContent>
								<GridContainer xs={12}>
									<GridItem xs={12}>
										<GenericTextInput
											LabelMd={2}
											Name="Name"
											LabelText="Payee Name"
											inputProps={{ maxLength: 50 }}
											Value={model.BillerName}
											Disabled={true}
										/>

										<GenericTextInput
											LabelMd={2}
											LabelText="Payee Wallet Number"
											Name="AccountNumber"
											inputProps={{ maxLength: 29 }}
											Value={BillIndividualModel.AccountNumber}
											Disabled={true} />

										<GenericNumberInput Name="Amount" LabelText="Amount" Value={BillIndividualModel.Amount} MaxLength={10} Disabled={true} LabelMd={2} />
										<br />
										<GenericLabel Text={model.IsUpdate ?
											"Are you sure you want to apply changes for this transaction ?" :
											"Are you sure you want to enter this transaction ?"} Bold={true}
										/>
									</GridItem>
								</GridContainer>
							</DialogContent>
							<GenericDialogActions>
								<Button size="sm" onClick={this.Submit}>Confirm</Button>
								<Button size="sm" onClick={() => this.setState({ isIndividualDialogOpen: false })}>Cancel</Button>
							</GenericDialogActions>
						</GenericDialog>


					</GridItem>
				</GridContainer>
			</div >


		);
	}

}

ClientBillDefinition.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	PayeeId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any,
	model: PropTypes.object,
};

export default ClientBillDefinition;