import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericExpansionPanel, GenericGrid, GenericDateInput, GenericTitle, GenericCheckInput, GenericSelectInput } from "views/Components/Generic";
import DateHelper from "core/DateHelper";
import moment from "moment";
import { ProgramCodes, BankingAccountTypes, ClientType, MenuCodes } from "views/Constants/Constant.js";
import { Proxy } from "core";
import LoadingComponent from "views/Components/LoadingComponent";
import CardHeader from "components/Card/CardHeader";
import Formatter from "core/Formatter";
import { GridColumnType } from "views/Constants/Constant.js";

class EftMontlyVolumeReport extends React.Component {
	constructor(props) {
		super(props);

		this.emptyObject = {};
		this.sortedName = { Member: "Name" };

		this.columnsEft = [
			{
				Header: "UniqueClientId",
				accessor: "UniqueClientId",
			},
			{
				Header: "Unique Client Name",
				accessor: "UniqueClientName",
				width: 200,
			},		
			{
				Header: "Total Regular Transactions",
				accessor: "TotalRegularTransactions",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},	
			{
				Header: "Total Regular Transactions Fee",
				accessor: "TotalRegularTransactionsFee",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},	
			{
				Header: "Total Regular Transactions Count",
				accessor: "TotalRegularTransactionsCount",
			},		
			{
				Header: "Total Priority Transactions",
				accessor: "TotalPriorityTransactions",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Total Priority Transactions Fee",
				accessor: "TotalPriorityTransactionsFee",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Priority Transactions Count",
				accessor: "PriorityTransactionsCount",
			},
			{
				Header: "Total Cancelled Transactions",
				accessor: "TotalCancelledTransactions",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Total Cancelled Transactions Fee",
				accessor: "TotalCancelledTransactionsFee",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Cancelled Transactions Count",
				accessor: "CancelledTransactionsCount",
			},

			{
				Header: "Total Rejected Transactions",
				accessor: "TotalRejectedTransactions",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Total Rejected Transactions Fee",
				accessor: "TotalRejectedTransactionsFee",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Rejected Transactions Count",
				accessor: "RejectedTransactionsCount",
			},
			{
				Header: "Total Returned Transactions",
				accessor: "TotalReturnedTransactions",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Total Returned Transactions Fee",
				accessor: "TotalReturnedTransactionsFee",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Returned Transactions Count",
				accessor: "ReturnedTransactionsCount",
			},
			{
				Header: "Total Regular Files Submitted",
				accessor: "TotalRegularFilesSubmitted",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Total Regular Files Submitted Fee",
				accessor: "TotalRegularFilesSubmittedFee",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Regular Files Submitted Count",
				accessor: "RegularFilesSubmittedCount",
			},
			{
				Header: "Total Priority Files Submitted",
				accessor: "TotalPriorityFilesSubmitted",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Total Priority Files Submitted Fee",
				accessor: "TotalPriorityFilesSubmittedFee",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Priority FilesSubmitted Count",
				accessor: "PriorityFilesSubmittedCount",
			}
		];

		this.columnsBill = [
			{
				Header: "UniqueClientId",
				accessor: "UniqueClientId",
			},
			{
				Header: "Unique Client Name",
				accessor: "UniqueClientName",
				width: 200,
			},	
			{
				Header: "Total Transactions",
				accessor: "TotalTransactions",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Total Transactions Fee",
				accessor: "TotalTransactionsFee",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Transactions Count",
				accessor: "TransactionsCount",
			},		
			{
				Header: "Total Canceled Transactions",
				accessor: "TotalCanceledTransactions",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Total Canceled Transactions Fee",
				accessor: "TotalCanceledTransactionsFee",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Canceled Transactions Count",
				accessor: "CanceledTransactionsCount",
			},
			{
				Header: "Total Sent Transactions",
				accessor: "TotalSentTransactions",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Total Sent Transactions Fee",
				accessor: "TotalSentTransactionsFee",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Sent Transactions Count",
				accessor: "SentTransactionsCount",
			},
			{
				Header: "Total Files Submitted",
				accessor: "TotalFilesSubmitted",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Total Files Submitted Fee",
				accessor: "TotalFilesSubmittedFee",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Files Submitted Count",
				accessor: "FilesSubmittedCount",
			}
		]

		this.columnsEtr = [
			{
				Header: "UniqueClientId",
				accessor: "UniqueClientId",
			},
			{
				Header: "Unique Client Name",
				accessor: "UniqueClientName",
				width: 200,
			},
			{
				Header: "Total Bulk Regular Transactions",
				accessor: "TotalBulkRegularTransactions",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Total Bulk Regular Transactions Fee",
				accessor: "TotalBulkRegularTransactionsFee",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Bulk Regular Transactions Count",
				accessor: "BulkRegularTransactionsCount",
			},	
			{
				Header: "Total Bulk Priority Transactions",
				accessor: "TotalBulkPriorityTransactions",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},	
			{
				Header: "Total Bulk Priority Transactions Fee",
				accessor: "TotalBulkPriorityTransactionsFee",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},	
			{
				Header: "Bulk Priority Transactions Count",
				accessor: "BulkPriorityTransactionsCount",
			},	
			{
				Header: "Total Real Time Transactions",
				accessor: "TotalRealTimeTransactions",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Total Real Time Transactions Fee",
				accessor: "TotalRealTimeTransactionsFee",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},		
			{
				Header: "Real Time Transactions Count",
				accessor: "RealTimeTransactionsCount",
			},
			{
				Header: "Total Cancelled Transactions",
				accessor: "TotalCancelledTransactions",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Total Cancelled Transactions Fee",
				accessor: "TotalCancelledTransactionsFee",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Cancelled Transactions Count",
				accessor: "CancelledTransactionsCount",
			},
			{
				Header: "Total Inbound Transactions",
				accessor: "TotalInboundTransactions",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Total Inbound Transactions Fee",
				accessor: "TotalInboundTransactionsFee",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Inbound Transactions Count",
				accessor: "InboundTransactionsCount",
			},	
			{
				Header: "Total Files Submitted",
				accessor: "TotalFilesSubmitted",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Total Files Submitted Fee",
				accessor: "TotalFilesSubmittedFee",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Files Submitted Count",
				accessor: "FilesSubmittedCount",
			},
			{
				Header: "Total Number of Stores",
				accessor: "TotalNumberofStores",
			}
		]

		this.state = { model: {}, isLoading: false, checkedAccounts: [], ClientTypeParameter: {} };
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("REPORTS");
		}
		if (setAppLeftTitle && this.props.MenuCode === "EFTVLR") {
			setAppLeftTitle("Eft Montly Volume Report");
		}
		if (setAppLeftTitle && this.props.MenuCode === "BLLVLR") {
			setAppLeftTitle("Bill Montly Volume Report");
		}
		if (setAppLeftTitle && this.props.MenuCode === "ETRVLR") {
			setAppLeftTitle("E-Transfer Montly Volume Report");
		}

	}

	ValueChanged = (name, value) => {
		const model = { ...this.state.model };
		model[name] = value;

		this.setState({ model });
	}
	Search = async () => {

		if (!this.Validate())
			return;
		const { ExecuteApiPost } = this.props;
		const model = { ...this.state.model };

		var result = null;
		if (this.props.MenuCode === "EFTVLR") {
			result = await ExecuteApiPost("/bankapi/v1.0/Reporting/EftMontlyVolumeReportList", model, null, null, null);
		}
		if (this.props.MenuCode === "BLLVLR") {
			result = await ExecuteApiPost("/bankapi/v1.0/Reporting/BillMontlyVolumeReportList", model, null, null, null);
		}
		if (this.props.MenuCode === "ETRVLR") {
			result = await ExecuteApiPost("/bankapi/v1.0/Reporting/ETransferMontlyVolumeReportList", model, null, null, null);
		}
		if (result != null) {
			this.setState({ list: result || [] });
		}
	}

	Clear = () => {
		this.setState({ model: {}, list: [], selected: null, checkedAccounts: [] });
	}


	Validate = () => {
		var { model } = this.state;
		const { showValidationErrors } = this.props;
		var errors = [];


		if (model.MonthlyFilterMonth == undefined || model.MonthlyFilterMonth == null) {
			errors.push("Month must be select!");
		}
		if (model.MonthlyFilterYear == undefined || model.MonthlyFilterYear == null) {
			errors.push("Year must be select!");
		}
		model["IsMonthlyFilterMonth"] = !model.MonthlyFilterMonth;
		model["IsMonthlyFilterYear"] = !model.MonthlyFilterMonth;

		if (errors.length > 0) {
			showValidationErrors(errors);

			return false;
		}

		return true;
	}

	GetRenderItemUniqueClient = (x) => {
		this.renderItemUniqueClient = `${x.Name} - ${x.UniqueClientId}`;
		return this.renderItemUniqueClient;
	}

	render() {
		const { model, list } = this.state;
		const { Disabled, } = this.props;

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						}

					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<LoadingComponent Show={this.state.isLoading} />

				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title={
									(() => {
										switch (this.props.MenuCode) {
											case "EFTVLR": return "Eft Montly Volume Report";
											case "BLLVLR": return "Bill Montly Volume Report";
											case "ETRVLR": return "E-Transfer Montly Volume Report";
										}
									})()
								}>
									<GridContainer>
										<GridItem xs={3}>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={this.emptyObject}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Name"
												RenderItem={this.GetRenderItemUniqueClient}
												Sorted={this.sortedName}
												All={true}
												Value={model.UniqueClientId}
												ValueChanged={this.ValueChanged}
												//Disabled={this.IsClient}
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												Name="MonthlyFilterMonth"
												LabelText="Month"
												Method="POST"
												Url="/coreapi/v1.0/Parameter/Search"
												Parameter={{
													ParameterCode: "Month"
												}}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ParameterDesc"
												Sorted={{
													Member: "ParameterValue2"
												}}
												Value={model.MonthlyFilterMonth}
												ValueChanged={this.ValueChanged}
												// Disabled={!model.MonthlyFilter}
												IsInvalid={model.IsMonthlyFilterMonth}
												Required
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												Name="MonthlyFilterYear"
												LabelText="Year"
												Method="POST"
												Url="/coreapi/v1.0/Parameter/Search"
												Parameter={{
													ParameterCode: "Year"
												}}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ParameterDesc"
												Sorted={{
													Member: "ParameterDesc",
													Desc: true
												}}
												Value={model.MonthlyFilterYear}
												ValueChanged={this.ValueChanged}
												// Disabled={!model.MonthlyFilter}
												IsInvalid={model.IsMonthlyFilterYear}
												Required
											/>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>

						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={
														(() => {
															switch (this.props.MenuCode) {
																case "EFTVLR": return this.columnsEft;
																case "BLLVLR": return this.columnsBill;
																case "ETRVLR": return this.columnsEtr;
															}
														})()
													}
													ShowPagination={true}
													PageSize={100}
													IsSorted={false}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

EftMontlyVolumeReport.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};
export default EftMontlyVolumeReport;