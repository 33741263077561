import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericAlert, GenericExpansionPanel, GenericNumberInput, GenericSelectInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";

class LoanApplicationScoringInfo extends Component {
	constructor(props) {
		super(props);

		this.state = {
		};
		this.MaritalStatusParameterCode = { ParameterCode: "LOSMaritalStatus" };
		this.OccupationParameterCode = { ParameterCode: "LOSOccupation" };
		this.YesNoParameterCode = { ParameterCode: "LOSYesNo" };
		this.LOSPhoneGivenParameterCode = { ParameterCode: "LOSPhoneGiven" };

		this.LOSDownpaymentParameterCode = { ParameterCode: "LOSDownpayment" };
		this.LOSInterbankTimeParameterCode = { ParameterCode: "LOSInterbankTime" };
		this.LOSSalaryParameterCode = { ParameterCode: "LOSSalary" };
		this.LOSHousholdInParameterCode = { ParameterCode: "LOSHousholdIn" };
		this.LOSEmplTimeParameterCode = { ParameterCode: "LOSEmplTime" };
		this.LOSTimePerParameterCode = { ParameterCode: "LOSTimePer" };
		this.LOSAddrTimeParameterCode = { ParameterCode: "LOSAddrTime" };

		this.LOSResidentalStatusParameterCode = { ParameterCode: "LOSResidentalStatus" };
		this.LOSEduLevelParameterCode = { ParameterCode: "LOSEduLevel" };
		this.LanguageParameterCode = { ParameterCode: "Language" };
		this.CountryParameterCode = { ParameterCode: "Country" };
		this.IdentityVerifyingPersonParameterCode = { ParameterCode: "IdentityVerifyingPerson" };
		this.parameterIdentificationMethod = { ParameterCode: "IdentificationMethod" };
		this.parameterIdentityType = { ParameterCode: "IdentityType" };
		this.LOSDepositInParameterCode = { ParameterCode: "LOSDepositIn" };

		this.maxLength20 = { maxLength: 20 };
		this.marginLeft2 = { marginLeft: 2 };
		this.ParameterMaxLength30 = { maxLength: 30 };
		this.emptyObject = {};

		this.sortedParameterValue = { Member: "ParameterValue" };
	}

	componentDidMount() {
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}

	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}
	ShowMessage = (type, title, message) => {
		// type:-success->warning->info->error
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}

	render() {
		const { ApplicationModel, Title, Disabled, IsOptional, IsActive, Name } = this.props;
		const { isLoading, alert } = this.state;

		return (
			<div>

				<LoadingComponent Show={isLoading} />
				<GenericExpansionPanel
					IsActive={IsActive}
					Title={Title}
					Name={Name}
					ValueChanged={this.props.HandleChangeFilterPanel}>
					<GridContainer
						direction="row"
						justify="flex-start"
						alignItems="stretch">
						<GridItem xs={12}>
							<GridItem xs={12}>
								{alert}
								{!IsOptional &&
									<GridContainer>
										<GridItem xs={6}>
											<GridItem>
												<GenericSelectInput
													Name="OccupationId"
													LabelText="Occupation"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.OccupationParameterCode}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={ApplicationModel.OccupationId}
													ValueChanged={this.props.HandleChange}
													Disabled={Disabled}
													CanClear
												/>
											</GridItem>
											<GridItem>
												<GenericSelectInput
													Name="ResidentalStatusId"
													LabelText="Residental Status"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.LOSResidentalStatusParameterCode}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={ApplicationModel.ResidentalStatusId}
													ValueChanged={this.props.HandleChange}
													Disabled={Disabled}
													CanClear
												/>
											</GridItem>
											<GridItem>
												<GenericSelectInput
													Name="EducationLevelId"
													LabelText="Level of Education"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.LOSEduLevelParameterCode}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={ApplicationModel.EducationLevelId}
													ValueChanged={this.props.HandleChange}
													Disabled={Disabled}
													CanClear
												/>
											</GridItem>
											<GridItem>
												<GenericSelectInput
													Name="TimeInterbankId"
													LabelText="Time with Interbank"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.LOSInterbankTimeParameterCode}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={ApplicationModel.TimeInterbankId}
													ValueChanged={this.props.HandleChange}
													Disabled={Disabled}
													CanClear
												/>
											</GridItem>
											<GridItem>
												<GenericSelectInput
													Name="TimeUsId"
													LabelText="Time with Us"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.LOSInterbankTimeParameterCode}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={ApplicationModel.TimeUsId}
													ValueChanged={this.props.HandleChange}
													Disabled={Disabled}
													CanClear
												/>
											</GridItem>
											<GridItem>
												<GenericSelectInput
													Name="DepositSavingUsId"
													LabelText="Deposit/Saving with Us"
													Value={ApplicationModel == null ? "" : ApplicationModel.DepositSavingUsId || ""}
													ValueChanged={this.props.HandleChange}
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.LOSDepositInParameterCode}
													Disabled={Disabled}
													CanClear
													DataRoot="Item" />
											</GridItem>
											<GridItem>
												<GenericSelectInput
													Name="OtherCreditCardHeldId"
													LabelText="Other Credit Cards Held"
													Value={ApplicationModel == null ? "" : ApplicationModel.OtherCreditCardHeldId || ""}
													ValueChanged={this.props.HandleChange}
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.YesNoParameterCode}
													Disabled={Disabled}
													CanClear
													DataRoot="Item" />
											</GridItem>
											<GridItem>

												<GenericSelectInput
													Name="BorrowerOtherLoanId"
													LabelText="Are you Co-Borrower of any other loan"
													Value={ApplicationModel == null ? "" : ApplicationModel.BorrowerOtherLoanId || ""}
													ValueChanged={this.props.HandleChange}
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.YesNoParameterCode}
													Disabled={Disabled}
													CanClear
													DataRoot="Item" />
											</GridItem>
											<GridItem>
												<GenericNumberInput
													Name="RemainingLoanAmount"
													LabelText="If you are Co-Borrower Remaining Amount of Loan"
													MaxLength={10}
													Prefix="$"
													Value={ApplicationModel.RemainingLoanAmount}
													ValueChanged={this.props.HandleChange}
													Disabled={Disabled} />
											</GridItem>
										</GridItem>
										<GridItem xs={6}>
											<GridItem>
												<GenericSelectInput
													Name="HouseOwnershipId"
													LabelText="House Ownership"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.YesNoParameterCode}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={ApplicationModel.HouseOwnershipId}
													ValueChanged={this.props.HandleChange}
													Disabled={Disabled}
													CanClear
												/>
											</GridItem>
											<GridItem>
												<GenericSelectInput
													Name="CarOwnershipId"
													LabelText="Car Ownership"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.YesNoParameterCode}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={ApplicationModel.CarOwnershipId}
													ValueChanged={this.props.HandleChange}
													Disabled={Disabled}
													CanClear
												/>
											</GridItem>
											<GridItem>
												<GenericSelectInput
													Name="LOSDownpaymentId"
													LabelText="% of Downpayment"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.LOSDownpaymentParameterCode}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={ApplicationModel.LOSDownpaymentId}
													ValueChanged={this.props.HandleChange}
													Disabled={Disabled}
													CanClear
												/>
											</GridItem>
											<GridItem>
												<GenericSelectInput
													Name="TelephoneGivenId"
													LabelText="Telephone Given"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.LOSPhoneGivenParameterCode}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={ApplicationModel.TelephoneGivenId}
													ValueChanged={this.props.HandleChange}
													Disabled={Disabled}
													CanClear
												/>
											</GridItem>
											<GridItem>
												<GenericSelectInput
													Name="DepositSavingInterbankId"
													LabelText="Deposit/Saving with Interbank"
													Value={ApplicationModel == null ? "" : ApplicationModel.DepositSavingInterbankId || ""}
													ValueChanged={this.props.HandleChange}
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.LOSDepositInParameterCode}
													Disabled={Disabled}
													CanClear
													DataRoot="Item" />
											</GridItem>
											<GridItem>
												<GenericSelectInput
													Name="MonthlyApplicantNetIncomeId"
													LabelText="Total Documented Net Income (Monthly Applicant)"
													Value={ApplicationModel == null ? "" : ApplicationModel.MonthlyApplicantNetIncomeId || ""}
													ValueChanged={this.props.HandleChange}
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.LOSDepositInParameterCode}
													Disabled={Disabled}
													CanClear
													DataRoot="Item" />
											</GridItem>
											<GridItem>
												<GenericSelectInput
													Name="MonthlyHousholdNetIncomeId"
													LabelText="Total Documented Net Income (Monthly Houshold)"
													Value={ApplicationModel == null ? "" : ApplicationModel.MonthlyHousholdNetIncomeId || ""}
													ValueChanged={this.props.HandleChange}
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.LOSDepositInParameterCode}
													Disabled={Disabled}
													CanClear
													DataRoot="Item" />
											</GridItem>
											<GridItem>
												<GenericSelectInput
													Name="MonthlyTotalPaymentId"
													LabelText="Monthly Total Payment"
													Value={ApplicationModel == null ? "" : ApplicationModel.MonthlyTotalPaymentId || ""}
													ValueChanged={this.props.HandleChange}
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.LOSDepositInParameterCode}
													Disabled={Disabled}
													CanClear
													DataRoot="Item" />
											</GridItem>
											<GridItem>
												<GenericSelectInput
													Name="HousholdIncomeId"
													LabelText="Monthly Payment as % of Monthly Houshold Income"
													Value={ApplicationModel == null ? "" : ApplicationModel.HousholdIncomeId || ""}
													ValueChanged={this.props.HandleChange}
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.LOSHousholdInParameterCode}
													Disabled={Disabled}
													CanClear
													DataRoot="Item" />
											</GridItem>
										</GridItem>
									</GridContainer>
								}
								{IsOptional &&

									<GridContainer>
										<GridItem xs={6}>
											<GridItem >
												<GenericSelectInput
													Name="MaritalStatusId"
													LabelText="Marital Status"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.MaritalStatusParameterCode}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={ApplicationModel.MaritalStatusId}
													ValueChanged={this.props.HandleChange}
													Disabled={Disabled}
													CanClear
												/>
											</GridItem>
											<GridItem >
												<GenericSelectInput
													Name="TimeInCurrentJobId"
													LabelText="Time In Current Job"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.LOSEmplTimeParameterCode}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={ApplicationModel.TimeInCurrentJobId}
													ValueChanged={this.props.HandleChange}
													Disabled={Disabled}
													CanClear
												/>
											</GridItem>
											<GridItem >
												<GenericSelectInput
													Name="TimePresentAddressId"
													LabelText="Time at Present Address"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.LOSAddrTimeParameterCode}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={ApplicationModel.TimePresentAddressId}
													ValueChanged={this.props.HandleChange}
													Disabled={Disabled}
													CanClear
													Sorted={this.sortedParameterValue}
												/>

											</GridItem>
										</GridItem>
										<GridItem xs={6}>
											<GridItem>
												<GenericSelectInput
													Name="TimeInJobId"
													LabelText="Time of Employment"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.LOSEmplTimeParameterCode}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={ApplicationModel.TimeInJobId}
													ValueChanged={this.props.HandleChange}
													Disabled={Disabled}
													CanClear
												/>
											</GridItem>
											<GridItem>
												<GenericSelectInput
													Name="MonthlySalaryId"
													LabelText="Monthly Income"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.LOSSalaryParameterCode}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={ApplicationModel.MonthlySalaryId}
													ValueChanged={this.props.HandleChange}
													CanClear
													Disabled={Disabled}
													Sorted={this.sortedParameterValue}
												/>
											</GridItem>
											<GridItem>
												<GenericSelectInput
													Name="MonthlyOtherIncomeId"
													LabelText="Monthly Other Documented Income"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.LOSSalaryParameterCode}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={ApplicationModel.MonthlyOtherIncomeId}
													ValueChanged={this.props.HandleChange}
													Disabled={Disabled}
													CanClear
													Sorted={this.sortedParameterValue}
												/>
											</GridItem>

										</GridItem>
									</GridContainer>
								}
							</GridItem>
						</GridItem>
						<GridItem xs={11}>
						</GridItem>
						<GridItem xs={1}>
							<Button size="sm" color="black" fullWidth
								onClick={this.props.NextClicked}>Next</Button>
						</GridItem>
					</GridContainer>
				</GenericExpansionPanel>
			</div >
		);
	}
}

LoanApplicationScoringInfo.propTypes = {
	classes: PropTypes.object.isRequired,
	ApplicationModel: PropTypes.object,
	HandleChange: PropTypes.func,
	Title: PropTypes.string,
	Disabled: PropTypes.bool,
	IsOptional: PropTypes.bool,
	NextClicked: PropTypes.func,
	HandleChangeFilterPanel: PropTypes.func,
	Name: PropTypes.string,
	IsActive: PropTypes.bool
};

export default withArtifex(LoanApplicationScoringInfo, {});