import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import { GenericGrid, GenericSelectInput, GenericTitle, GenericDialog, GenericLabel, GenericDialogActions, GenericRadioInput, GenericDateInput } from "views/Components/Generic";
import CardHeader from "components/Card/CardHeader";
import { GridColumnType } from "views/Constants/Constant.js";
import DateHelper from "core/DateHelper";
import MenuAuthorityRightHelper from "core/MenuAuthorityRightHelper";
import { DialogContent, DialogTitle } from "@material-ui/core";
import Select from "react-select";
import Button from "components/CustomButtons/Button.jsx";
import { Proxy } from "core";
import LoadingComponent from "views/Components/LoadingComponent";

class ClientAccountBalanceList extends React.Component {
	constructor(props) {
		super(props);

		this.columns = [
			{
				Header: "Insert Date",
				accessor: "InsertDateTime",
				type: GridColumnType.DateUtc,
				ColumnType: GridColumnType.DateUtc
			},
			{
				Header: "Client Name ",
				accessor: "ClientName"
			},
			{
				Header: "Client Unique Id",
				accessor: "ClientUniqueId"
			}, {
				Header: "Total Balance of All Client Wallet",
				accessor: "TotalAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
		];

		this.state = {
			model: {}
		};

		this.trxTable = React.createRef();
		this.MenuAuthorityRightHelper = new MenuAuthorityRightHelper();
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("WALLET");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("Client Wallet Balance List");
		}
	}

	RowSelected = (index) => {
		const { list } = this.state;

		this.setState({ model: list[index], selected: index });
	}

	List = async () => {
		const { ExecuteApiPost } = this.props;
		const { model } = this.state;
		model.IsClientBalanceScreen = true;
		var result = await ExecuteApiPost("/bankapi/v1.0/Transaction/BalanceList", model);

		this.setState({ list: result || [] });
	}

	Clear = () => {
		this.setState({ model: {}, list: [], selected: null });
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}
	HandleValueChanged = (name, value) => {
		this.setState(state => {

			var model = state.model;
			model[name] = value;

			return { model };
		});
	}
	ExportClick = () => {
		var trxColumns = [];
		this.trxTable.current.props.Columns.forEach(c => {
			if (c.accessor != "Actions" && c.accessor != "ED" && (c.show == undefined || c.show)) {
				trxColumns.push({ value: c.accessor, title: c.Header, columntype: c.ColumnType, type: c.type });
			}
		}, this);

		this.setState({ isDownloadDialogOpen: true, GridKeyValueList: trxColumns, ExportSelectedColumns: trxColumns });
	}

	ExcelDownload = () => {
		if (!this.state.ExportReportType) {
			this.props.showMessage("warning", "Export Report Type not selected", "Select Export Report Type to continue");
			return;
		}

		if (this.state.ExportReportType == "PDF" && this.state.ExportSelectedColumns.length > 15) {
			this.props.showMessage("warning", "PDF document cannot exceed 15 columns", "PDF document cannot exceed 15 columns");
			return;
		}

		this.setState({ isDownloadDialogOpen: false });

		this.props.showConfirmMessage("question", "Long Process Warning", "This process may take a while. Continue ?", () => {
			this.hideAlert();
			const { model } = this.state;
			model.IsClientBalanceScreen = true;

			// Add jsonFile
			var jsonColList = [];

			var trxColumns = {};
			this.state.ExportSelectedColumns.map(c => {
				trxColumns[c.value] = { value: c.value, title: c.title, columntype: c.columntype, type: c.type };
			});
			jsonColList.push(trxColumns);

			model["jsonFile"] = {
				colmns: jsonColList,
				format: this.state.ExportReportType,
				title: "Client Wallet Balance List Report"
			};

			this.setState({ isLoading: true });
			Proxy.DownloadGeneratedFile(
				"/bankapi/v1.0/Transaction/BalanceReportExportList",
				model,
				responseData => {
					this.setState({ isLoading: false });
					if (!responseData.IsSucceeded) {
						this.props.showMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
				},
				errorMessage => {
					this.setState({ isLoading: false });
					this.props.showMessage("error", "Error", errorMessage);
				},
				this.props.showMessage
			);
		});
	}

	ValueChanged = (name, value) => {
		this.setState({ [name]: value });
	}

	render() {
		const { model, list, selected } = this.state;
		const { Disabled } = this.props;

		return (
			<div>
				<LoadingComponent Show={this.state.isLoading} />
				{alert}
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.List
						},

						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						},
						{ Code: "Export", OnClick: () => this.ExportClick(), Disabled: Disabled }

					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={" Main Parameters "} />
							</CardHeader>
							<b><p style={{ paddingTop: "20px", marginLeft: "10px" }}></p></b>
							<CardBody>
								<GridContainer spacing={16}>
									<GridItem xs={12}>
										<GridContainer>
											<GridItem xs={4}>
												<GenericSelectInput
													Name="UniqueClientId"
													LabelMd={4}
													LabelText="Client Name"
													Method="POST"
													Url="/bankapi/v1.0/BankCustomer/Search"
													Parameter={{}}
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
													Value={model.UniqueClientId || ""}
													ValueChanged={this.HandleValueChanged}
													CanClear
												// Required
												// IsInvalid={kModel.UniqueClientId}
												/>
											</GridItem>
											<GridItem xs={4}>
												<GenericDateInput
													Name="SearchDate"
													LabelText="Date"
													LabelMd={4}
													MaxDate={DateHelper.GetDate()}
													Value={model.SearchDate == null ? "" : DateHelper.ToDateInputValueAsUtc(model.SearchDate) || ""}
													ValueChanged={this.HandleValueChanged}
													Utc
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>

						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={"Client Wallet Balance List"} />
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columns}
													ref={this.trxTable}
													PageSize={50}

												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
						<GenericDialog open={this.state.isDownloadDialogOpen} fullWidth={true}>
							<DialogTitle>
								<GenericLabel Text="Select Report Type" FontSize="20px" Bold={true} />
							</DialogTitle>
							<DialogContent style={{ height: 300 }}>
								<GenericRadioInput
									Name="ExportReportType"
									LabelText="Report Type"
									IsStatic={true}
									StaticData={[{ Value: "PDF", Text: "PDF" }, { Value: "CSV", Text: "CSV" }, { Value: "EXCEL", Text: "EXCEL" }]}
									KeyValueMember="Value"
									TextValueMember="Text"
									Value={this.state.ExportReportType}
									ValueChanged={this.ValueChanged}
								/>
								<GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
								<GridItem style={{ marginTop: 20 }}>
									<Select
										value={this.state.ExportSelectedColumns}
										onChange={value => this.setState({ ExportSelectedColumns: value })}
										isMulti
										name="columns"
										getOptionLabel={d => d.title}
										options={this.state.GridKeyValueList}
										className="basic-multi-select"
										classNamePrefix="select"
									/>
								</GridItem>
							</DialogContent>
							<GenericDialogActions>
								<Button size="sm" onClick={this.ExcelDownload}>Download</Button>
								<Button size="sm" onClick={() => this.setState({ isDownloadDialogOpen: false })}>Cancel</Button>
							</GenericDialogActions>
						</GenericDialog>

					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

ClientAccountBalanceList.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default ClientAccountBalanceList;