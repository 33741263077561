import createReportStyle from "assets/jss/material-dashboard-pro-react/views/createReportStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import Formatter from "core/Formatter";
import { TaskPool } from "core/TaskPool";
// used for making the prop types of this component
import PropTypes from "prop-types";
import React from "react";
import DualListBox from "react-dual-listbox";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
// generic components
import { GenericAlert, GenericDateInput, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType } from "views/Constants/Constant";

class ClientUserAuthorization extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			UserIds: []
		};

		this.state = {
			vModel: {},
			list: [],
			model: this.initialModel,
			columnListBoxOption: [],
			isLoading: false,
			selected: null,
			isFromWorkflow: false
		};

		this.loadTaskPool = new TaskPool(this.HandleLoadTaskPoolAppend, this.HandleLoadTaskPoolCompleted);
	}

	componentDidMount() {
		const { setAppLeftTitle, setAppCenterTitle } = this.props;

		if (setAppLeftTitle)
			setAppLeftTitle("Client User Authorization");
		if (setAppCenterTitle)
			setAppCenterTitle("SYSTEM ADMIN");

		this.HandleClientList();
	}

	HandleLoadTaskPoolCompleted = () => {
		this.setState({ isLoading: false });
	}

	HandleLoadTaskPoolAppend = () => {
		this.setState({ isLoading: true });
	}

	ColumnListBoxOnChange = (selected) => {
		const { model } = this.state;
		model.UserIds = selected;
		this.setState({ model });
	}

	HandleClear = () => {
		this.setState({ model: { ...this.initialModel }, list: [], vModel: {} });
	}

	HandleChange = (name, value) => {
		const model = { ...this.state.model };
		model[name] = value;
		this.setState({ model });
	}

	HandleSelectModel = (model, index) => {
		this.setState({ model: model, selected: index });
		this.HandleGetList({ BackofficeUserId: model.BackofficeUserId, ExpiryDate: model.ExpiryDate });
	}

	HandleGetList = (selected) => {
		const { model } = this.state;

		this.loadTaskPool.AppendTask(
			Proxy.POST(
				"/coreapi/v1.0/ClientUserAuthorization/Search",
				selected ? selected : model,
				(responseData) => {
					this.setState({ isLoading: false });
					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					if (responseData.Item != null) {
						if (selected) {
							model.BackofficeUserId = selected.BackofficeUserId;
							model.ExpiryDate = selected.ExpiryDate;
						}
						model.UserIds = [];
						responseData.Item.filter(x => x.BackofficeUserId === model.BackofficeUserId).forEach(x => {
							model.UserIds.push(x.ClientUserId.toString());
						});
						this.setState({ list: responseData.Item, model });
					} else {
						this.setState({ list: [] });
					}
				},
				(error) => {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "Error", error);
				}
			)
		);
	}

	HandleClientList = () => {
		this.loadTaskPool.AppendTask(
			Proxy.GET(
				"/coreapi/v1.0/User/GetAllClient",
				(responseData) => {
					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					if (responseData.Item !== null) {
						this.setState({ columnListBoxOption: responseData.Item });
					} else {
						this.setState({ columnListBoxOption: [] });
					}
				},
				(error) => {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "Error", error);
				}
			)
		);
	}

	HandleSubmit = (workflowId, after) => {
		if (!this.Validate())
			return;

		this.setState({ isLoading: true });

		Proxy.POST(
			"/coreapi/v1.0/ClientUserAuthorization/InsertOrUpdate",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (after) after();
				this.HandleGetList();
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			},
			workflowId
		);
	}


	Validate = () => {
		const { model, vModel } = this.state;

		if (!model.BackofficeUserId) {
			vModel.BackofficeUserId = true;
			this.ShowMessage("warning", "Backoffice User not selected", "Select Backoffice User to continue.");
			return false;
		} else { vModel.BackofficeUserId = false; }

		if (!model.ExpiryDate) {
			vModel.ExpiryDate = true;
			this.ShowMessage("warning", "Expiry Date not selected", "Select Expiry Date to continue.");
			return false;
		} else { vModel.ExpiryDate = false; }

		if (model.UserIds.length < 1) {
			vModel.UserIds = true;
			this.ShowMessage("error", "Error", "Select at least one client user");
			return;
		} else { vModel.UserIds = false; }
		this.setState({ vModel });
		return true;
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	GetModel = () => {
		const { model } = this.state;
		return model;
	}

	render() {
		const { classes, Disabled } = this.props;
		const { alert, columnListBoxOption, isLoading, model, list, isFromWorkflow, vModel } = this.state;
		return (
			<div>
				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
					{ Code: "Search", OnClick: this.HandleGetList, Disabled: Disabled },
					{ Code: "Submit", OnClick: this.HandleSubmit, Disabled: Disabled || model.Id > 0, ModelFunction: () => model, FillDataFromModel: model => this.setState({ model, isFromWorkflow: true }), ValidationFunction: this.Validate },
					{ Code: "Update", OnClick: this.HandleSubmit, Disabled: Disabled || model.Id === 0, ModelFunction: () => model, FillDataFromModel: model => this.setState({ model, isFromWorkflow: true }), ValidationFunction: this.Validate },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Parameter">
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												Name="BackofficeUserId"
												LabelText="Backoffice User"
												Method="GET"
												Url="/coreapi/v1.0/User/GetAllBackoffice"
												Parameter={{}}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="UserEmail"
												Value={model.BackofficeUserId}
												ValueChanged={this.HandleChange}
												Disabled={Disabled}
												Required
												IsInvalid={vModel.BackofficeUserId}
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericDateInput
												Utc
												Name="ExpiryDate"
												LabelText="Expiry Date"
												Value={model.ExpiryDate != null ? Formatter.FormatDateUtc(model.ExpiryDate) : null}
												ValueChanged={this.HandleChange}
												IsFuture={true}
												Disabled={Disabled}
												Required
												IsInvalid={vModel.ExpiryDate}
											/>
										</GridItem>
										<GridItem xs={4} />
										<GridItem xs={12}>

											<div style={{ marginTop: 8, marginBottom: 8 }}>
												<GenericTitle text={"Client User Selection"} />
											</div>
											<DualListBox
												canFilter
												className={classes.root}
												options={columnListBoxOption.map(i => {
													return {
														"value": i.Id.toString(),
														"label": i.UserEmail
													};
												})}
												selected={model.UserIds}
												onChange={(selected) => this.ColumnListBoxOnChange(selected)}
												disabled={Disabled}
											/>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						{!isFromWorkflow &&
							<Card className="no-radius">
								<CardHeader>
									<GenericTitle text={"Client User Authorization List"} />
								</CardHeader>
								<CardBody>
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer>
												<GridItem xs={4}>
												</GridItem>
											</GridContainer>
											<GridContainer spacing={16}>
												<GridItem xs={12}>
													<GenericGrid
														Data={list}
														Columns={[
															{
																Header: "Backoffice User",
																accessor: "BackofficeUser.UserEmail"
															},
															{
																Header: "Client User",
																accessor: "ClientUser.UserEmail"
															},
															{
																Header: "Expiry Date",
																accessor: "ExpiryDate",
																type: GridColumnType.DateUtc
															}
														]}
														RowSelected={index => {
															const model = list[index];
															this.HandleSelectModel(model, index);
														}}
														SelectedIndex={this.state.selected}
													/>
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</CardBody>
							</Card>
						}
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

ClientUserAuthorization.propTypes = {
	classes: PropTypes.object
};

export default withArtifex(ClientUserAuthorization, createReportStyle);
