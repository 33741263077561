import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React from "react";
import { GenericGrid, GenericTitle } from "views/Components/Generic";
import { GridColumnType, ProgramCodes } from "views/Constants/Constant";

class MyProfileAccounts extends React.Component {
	constructor(props) {
		super(props);
		this.state = {

		};
	}

	render() {
		var data = this.props.Accounts === undefined || this.props.Accounts === null ? [] : this.props.Accounts.map(acc => {

			return {
				AccountType: acc == null ? null : acc.AccountType == null ? null : acc.AccountType,
				Program: acc == null ? "" : acc.Program == null ? "" : acc.Program,
				FinInsId: acc == null ? null : acc.FinInsId == null ? null : acc.FinInsId,
				TransitNumber: acc == null ? null : acc.TransitNumber == null ? null : acc.TransitNumber,
				AccountNumber: acc == null ? "" : acc.AccountNumber == null ? "" : acc.AccountNumber,
				AccountCreateDate: acc == null ? "" : acc.AccountCreateDate == null ? "" : acc.AccountCreateDate,
				Status: acc == null ? "" : acc.Status == null ? "" : acc.Status,
				AccountName: acc == null ? "" : acc.AccountName == null ? "" : acc.AccountName
			};
		});

		return (
			<Card className="no-radius">
				<CardHeader>
					<GenericTitle text={"Accounts"} />
				</CardHeader>
				<CardBody>

					<GenericGrid
						Data={data}
						PageSize={5}
						HideButton={true}
						Columns={[
							{
								Header: "AccountType",
								accessor: "AccountType"
							},
							{
								Header: "Program",
								accessor: "Program"
							},
							{
								Header: "Wallet Name",
								accessor: "AccountName"
							},
							{
								Header: "FIID",
								accessor: "FinInsId",
								Cell: row => (
									<div>{row.value == null ? "" : row.value.toString().padStart(3, "0")}</div>
								)
							},
							{
								Header: "Transit",
								accessor: "TransitNumber",
								Cell: row => (
									<div>{row.value == null ? "" : row.value.toString().padStart(5, "0")}</div>
								)
							},
							{
								Header: "Wallet Number",
								accessor: "AccountNumber"
							},
							{
								Header: "Date",
								accessor: "AccountCreateDate",
								type: GridColumnType.Date
							},
							{
								Header: "Status",
								accessor: "Status"
							}
						]}
						ProgramCode={ProgramCodes.Bank_Programs_Bank_Account} />
				</CardBody>
			</Card>
		);
	}
}

MyProfileAccounts.propTypes = {
	Accounts: PropTypes.array,
	Header: PropTypes.bool
};

export default MyProfileAccounts;
