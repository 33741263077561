import { Dialog, DialogContent } from "@material-ui/core";
import CardMedia from "@material-ui/core/CardMedia";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React from "react";
import Recaptcha from "react-recaptcha";
import { UserConstants } from "store";
import { GenericAlert, GenericCircularProgress, GenericEmailInput, GenericTextInput, GenericTitle, GenericLabel, GenericSelectInput, GenericNumberInput } from "views/Components/Generic";
import RouteHelper from "core/RouteHelper";
import LoadingComponent from "views/Components/LoadingComponent";

class InteracConfirmPaymentResult extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			alert: null,
			isLoading: false,
			model: {}
		};

		this.ParameterMaxLength100 = { maxLength: 100 };
	}

	componentDidMount() {
		var data = RouteHelper.Read("InteracData");
		this.setState({ model: data || {} });
		console.log(data);
	}


	LogOutUser = () => {
		throw new Error("Method not implemented.");
	}
	handleChange = (name, newValue) => {
		this.setState(function (state, props) {
			var model = state.model || {};
			model[name] = newValue;
			return { model };
		});
	}


	render() {
		const { classes } = this.props;
		const { model, isLoading, alert } = this.state;

		return (
			<div className={classes.container}>
				{alert}
			
				
				<LoadingComponent Show={isLoading} />
				
				<GridContainer justify="center">
					<GridItem xs={12} sm={6} md={7}>
						<Card>
							<CardHeader>
							</CardHeader>
							<CardBody>
								<GridContainer container direction="column" justify="flex-start" alignItems="stretch" style={{ padding: "25px" }}>
									<GenericTitle text="You've completed your Interac e-Transfer Deposit." />
									<br /><br />
									<GridItem>
										<GenericLabel
											Bold={false}
											FontSize="15px"
											TextColor="black"
											Text="You can see Interac e-Transfer Deposit details below." />
									</GridItem>
									<br />
									<GridItem>
										<GenericTextInput
											Disabled={true}
											Name="ConfirmationNumber"
											LabelText="Confirmation Number"
											Value={model.ConfirmationNumber}
											ValueChanged={this.handleChange}
										/>
									</GridItem>
									<GridItem>
										<GenericTextInput
											Disabled={true}
											Name="DateTime"
											LabelText="Date and Time"
											Value={model.DateTime}
											ValueChanged={this.handleChange}
										/>
									</GridItem>
									<GridItem>
										<GenericNumberInput
											Disabled={true}
											Prefix="$ "
											Name="Amount"
											LabelText="Transaction Amount"
											Value={model.Amount}
											ValueChanged={this.handleChange}
										/>
									</GridItem>
									<GridItem>
										<GenericSelectInput
											Disabled={true}
											Name="AccountId"
											LabelText="To Wallet"
											Method="POST"
											Url="/bankapi/v1.0/InteracConfirmPayment/GetInteractLoadAccount"
											Parameter={{}}
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember="AccountName"
											Value={model.AccountId}
											ValueChanged={this.handleChange}
										/>
									</GridItem>
									<GridItem>
										<GenericTextInput
											Disabled={true}
											MultiLine={true}
											RowCount={4}
											Name="Message"
											LabelText="Message"
											Value={model.Message}
											ValueChanged={this.handleChange} />
									</GridItem>
									<br />
									<GridItem>
										<GridContainer>
											<GridItem md={3}>

											</GridItem>
											<GridItem md={6}></GridItem>
											<GridItem md={3}>
											 <Button color="primary" size="md" block onClick={() => this.SubmitPayment()}>
													Print
												</Button>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

InteracConfirmPaymentResult.propTypes = {
	classes: PropTypes.object
};

export default withArtifex(InteracConfirmPaymentResult, loginPageStyle);