import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericLabel, GenericTextInput } from "views/Components/Generic";
import InternalScorecardTab from "views/LOS/Scorecard/InternalScorecardTab.jsx";

class InternalScorecardLoan extends Component {
	componentDidMount() {
	}

	render() {
		const rows = this.props.LoanOtherCrediscorerows;

		return (
			<div>
				<InternalScorecardTab
					QuestionList={this.props.QuestionList}
					HandleChange={this.props.HandleChange}
					HandleAnswerChange={this.props.HandleAnswerChange}
					AnswerValueList={this.props.AnswerValueList}
					ApplicationModel={this.props.ApplicationModel}
					ApplicationDocumentList={this.props.ApplicationDocumentList}
					Disabled={this.props.Disabled}
				/>
				<Card className="no-radius">
					<CardBody>
						<div style={{ marginLeft: 20 }}>

							<GridContainer
								container
								direction="column"
								justify="flex-start"
								alignItems="stretch">


								{rows != undefined && rows.map((columns, i) => {
									return (
										<GridContainer
											container
											direction="row"
											justify="flex-start"
											alignItems="stretch">
											{columns.map((column, j) => {
												if (column.Id == 0) {
													return (
														<GridItem xs={1}>
															<GenericLabel
																Text={column.Text}
																Bold={column.Bold}
															></GenericLabel>
														</GridItem>
													);

												} else {
													return (
														<GridItem xs={1}
															style={{
																border: "1px Solid #000"

															}}> <GenericTextInput
																Disabled={j == 2 || j == 3}
																Name={column.Id}
																LabelMd={0}
																Value={this.props.OtherScorecardAnswerValueList && this.props.OtherScorecardAnswerValueList[column.Id]}
																ValueChanged={this.props.HandleOtherScorecardAnswerChange}
															></GenericTextInput>
														</GridItem>
													);

												}


											})}
										</GridContainer>


									);
								})}
							</GridContainer>
						</div>
					</CardBody>
				</Card>
			</div>
		);
	}
}

InternalScorecardLoan.propTypes = {
	QuestionList: PropTypes.object,
	AnswerValueList: PropTypes.array,
	OtherScorecardAnswerValueList: PropTypes.array,
	HandleChange: PropTypes.func,
	HandleOtherScorecardAnswerChange: PropTypes.func,
	HandleAnswerChange: PropTypes.func,
	ApplicationModel: PropTypes.object,
	ApplicationDocumentList: PropTypes.array,
	Disabled: PropTypes.object,
	LoanOtherCrediscorerows: PropTypes.array,
	ScorecardVendorList: PropTypes.array,
	ScorecardVendorLevelList: PropTypes.array
};

export default withArtifex(InternalScorecardLoan, {});