import { DialogContent, DialogTitle, Tooltip, Zoom } from "@material-ui/core";
import { AddBoxOutlined } from "@material-ui/icons";
import { primaryColor } from "assets/jss/material-dashboard-pro-react.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import RouteHelper from "core/RouteHelper";
import StringHelper from "core/StringHelper.js";
import { TaskPool } from "core/TaskPool";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericCheckInput, GenericDialog, GenericDialogActions, GenericEmailInput, GenericExpansionPanel, GenericExpansionPanelCustomHeader, GenericGrid, GenericIcon, GenericLabel, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent.jsx";
import { ClientType, MenuCodes, ProgramCodes } from "views/Constants/Constant.js";
import { GetCDICAccountColumns, GetColumns } from "views/Customer/CustomerColumns";
import AssignDDTAccountComponent from "../Components/AssignDDTAccountComponent.jsx";
import BeneficiaryComponent from "../Components/BeneficiaryComponent.jsx";
import CustomerInformationComponent from "../Components/CustomerInformationComponent.jsx";
import BenificialOwner from "./BenificialOwner";

const customerDefinitionStyle = {
	tooltip: {
		background: primaryColor,
		color: "#ffffff",
		fontSize: 16
	}
};

class CustomerDefinition extends Component {
	constructor(props) {
		super(props);

		this.state = {
			alert: null,
			accountModel: {},
			DCAccountModel: {},
			model: {
				Id: 0,
				Accounts: [],
				WalletAccounts: [],
				Programs: props.Programs || [],
				UniqueClientId: ClientHelper.GetClientRowId()
			},
			tempModel: {
				isGenerateNew: props.ForInsert || props.ForUpdate,
				isPersonal: null
			},
			IsFinScanCheckRequired: false,
			FinScanSearchData: null,
			wCheckModel: {
				ClientCustomerId: 0,
				WatchListStatusId: 0,
				WatchListStatusDescription: null,
				SearchResult: null,
				LastReviewDate: null
			},
			ddrModel: {},
			vModel: {},
			CDICAccounts: [],
			ClientCustomerList: [],
			isLoading: false,
			isEdit: false,
			isEditAccount: false,
			isErrorDialogOpen: false,
			errorMessage: "",
			uniq: "1" + Formatter.PadLeft(ClientHelper.GetClientRowId(), 3, "0"),
			Phone: {},
			services: [],
			isDDRInquiryOpen: false,
			DDRInquiryResult: "",
			hasChanged: false,
			hasChangedAccount: false,
			rowIndex: -1,
			ReadOnly: ClientHelper.IsBackOffice(),

			AddressType: null
		};
		// preserve the initial state in a new object
		this.baseState = this.state;

		this.loadTaskPool = new TaskPool(this.handleLoadTaskPoolAppend, this.handleLoadTaskPoolCompleted);

		this.emptyObject = {};

		this.customerTypes = [];

		this.parameterCustomerName = {};
		this.parameteWalletAccount = {};
		this.parameterYesNo = {
			ParameterCode: "YesNo"
		};

		this.maxLengthCustomerNumber = { maxLength: this.state.model.IsAutoGenerateCustomerNumber ? 11 : 11 - this.state.uniq.length };
		this.maxLength30 = { maxLength: 30 };
		this.maxLength64 = { maxLength: 64 };

		this.sortName = { Member: "Name" };
		this.sortListName = [{ id: "CustomerName" }];

		this.retiredOrUnemployedOccupationIds = [];
		this.specificIdentityTypesIds = [];
		this.identificationMethodIds = [];

		this.DualMethodId = 0;
		this.InPersonMethodId = 0; 	
		this.CreditFileMethodId = 0; 

		this.DefaultCustomerStatusId = 0;
	}

	componentDidMount() {
		var { model, tempModel } = this.state;

		this.FetchCanadaPost();

		if (!(this.props.ForInsert || this.props.ForUpdate)) {			
			if (this.props.setAppLeftTitle) this.props.setAppLeftTitle("Create Customer");
			if (this.props.setAppCenterTitle) this.props.setAppCenterTitle("CUSTOMER SYSTEM");
		}

		var customerModel = RouteHelper.ReadAndClear("CustomerModel") || this.props.Model;

		var isOpenFromAMLKYCAlert = this.props.ApprovalData ? true : false;
		if (isOpenFromAMLKYCAlert) {
			model = JSON.parse(this.props.ApprovalData.JsonData);
			tempModel.isGenerateNew = true;
			var ReadOnly = true;
			this.setState({ model, tempModel, ReadOnly });
			this.LoadProgramsForUi(model);
		} else {
			this.setState({ model });
			if (customerModel != null) {
				this.HandleGetList(customerModel);
			}				
			this.LoadProgramsForUi(customerModel);
		}

		this.GetDefaultCustomerStatusId();

	}
	
	GetDefaultCustomerStatusId() {
		Proxy.POST(
			"/coreapi/v1.0/Parameter/Search",
			{
				ParameterCode: "CustomerStatus",
				ParameterValue: "N"
			},
			(responseData) => {
				if (!responseData.IsSucceeded) {
					console.log("Parameters were not found.");
					return;
				}
				this.DefaultCustomerStatusId = responseData.Item[0].Id;
			}		
		);
		this.OnDefaultStatusLoaded();
	}

	OnDefaultStatusLoaded() {
		this.GetIdentificationMethodIds();
		this.GetRetiredOrUnemployedOccupationIds();
		this.GetSpecificIdentityTypesIds();
	}

	GetIdentificationMethodIds() {
		Proxy.POST(
			"/coreapi/v1.0/Parameter/Search",
			{
				ParameterCode: "IdentificationMethod",
				ParameterValue2: "Active"			
			},
			(responseData) => {
				if (!responseData.IsSucceeded) {
					console.log("Parameters were not found.");
					return;
				}	
				this.DualMethodId = responseData.Item.find(x=>x.ParameterValue =="D").Id;
				this.InPersonMethodId = responseData.Item.find(x=>x.ParameterValue=="I").Id;		
				this.CreditFileMethodId = responseData.Item.find(x=>x.ParameterValue=="P").Id;			
			}
		);
	}

	GetSpecificIdentityTypesIds() {
		Proxy.POST(
			"/coreapi/v1.0/Parameter/Search",
			{
				ParameterCode: "IdentityType2",
				ParameterValue: "ID010"
			},
			(responseData) => {
				if (!responseData.IsSucceeded) {
					console.log("Parameters were not found.");
					return;
				}
				let list = responseData.Item;												
				list.forEach(element => {
					this.specificIdentityTypesIds.push(element.Id);
				});	
			}
		);
	} 

	GetRetiredOrUnemployedOccupationIds() {
		Proxy.POST(
			"/coreapi/v1.0/Parameter/Search",
			{
				ParameterCode: "Occupation"
			},
			(responseData) => {
				if (!responseData.IsSucceeded) {
					console.log("Parameters were not found.");
					return;
				}
				let list = responseData.Item;
				list = list.filter(x=>x.ParameterValue == "Retired"
												|| x.ParameterValue == "UnemployedSocialAssistance"
												|| x.ParameterValue == "UnemploymentAssistance");
				
				list.forEach(element => {
					this.retiredOrUnemployedOccupationIds.push(element.Id);
				});	
			}
		);
	}

	FetchCanadaPost = () => {
		var parent = this;

		this.loadTaskPool.AppendTask(
			Proxy.POST("/coreapi/v1.0/Parameter/Search",
				{
					ParameterCode: "CanadaPostApiKey",
					ParameterValue: "CP"
				},
				responseData => {
					if (!responseData.IsSucceeded) {
						console.log("Canada Post Api key not fetched !");
						return;
					}
					if (responseData.Item == null) {
						console.log("Canada Post Api key not fetched !");
						return;
					}
					if (responseData.Item[0] == null) {
						console.log("Canada Post Api key not fetched !");
						return;
					}

					var apikey = responseData.Item[0].ParameterDesc;

					var url = window.CanadaPostAddressCompleteUrl;
					var path = url + apikey;

					var pca;

					var CanadaPostSubmit = this.SubmitCanadaPostAddressModel;

					const script = document.createElement("script");
					script.src = path;
					script.async = false;
					document.head.appendChild(script);
					script.onload = function () {
						pca = window.pca;
						if (pca != null) {
							var fields =
								[
									{ element: "street-address", field: "Line1", mode: pca.fieldMode.SEARCH },
									{ element: "street-address2", field: "Line2", mode: pca.fieldMode.POPULATE },
									{ element: "postcode", field: "PostalCode", mode: pca.fieldMode.SEARCH }								
								],
								options = {
									key: apikey
								},
								control = new pca.Address(fields, options);
							
							var employer = 
								[
									{ element: "employer-address", field: "Line1", mode: pca.fieldMode.SEARCH },						
									{ element: "employer-postcode", field: "PostalCode", mode: pca.fieldMode.SEARCH },
								],
							employerControl = new pca.Address(employer, options); 

							control.listen("populate", function (address) {
								CanadaPostSubmit(address);
								parent.setState(state => {
									state.model.AddressLine1 = address.Line1;
									state.model.AddressLine2 = address.Line2;
									state.model.ZipCode = address.PostalCode;		
									state.AddressType = "Customer";					
									return state;
								});
							});
							
							employerControl.listen("populate", function (address) {
								CanadaPostSubmit(address);
								parent.setState(state => {
									state.model.EmployerAddress = address.Line1;
									state.model.EmployerZipCode = address.PostalCode;
									state.AddressType = "Employer";
									return state;
								});
							}); 
						}
					};
				},
				e => {
					console.log("Canada Post Api key not fetched !");
				}
			));
	}

	SubmitCanadaPostAddressModel = (model) => {
		Proxy.POST(
			"/coreapi/v1.0/Country/CanadaPost",
			model,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ isLoading: false });
				var address = { ...this.state.model };
				if (responseData.Item != null) {
					if (this.state.AddressType == "Customer"){
						address.CountryId = responseData.Item.CountryId;
						address.ProvinceId = responseData.Item.ProvinceId;
						address.CityId = responseData.Item.CityId;
					}
					else {
						address.EmployerCountryId = responseData.Item.CountryId;
						address.EmployerProvinceId = responseData.Item.ProvinceId;
						address.EmployerCityId = responseData.Item.CityId;
					}				
				} 
				else {
					address.CountryId = 0;
					address.ProvinceId = 0;
					address.CityId = 0;

					address.EmployerCountryId = 0;
					address.EmployerProvinceId = 0;
					address.EmployerCityId = 0; 
				}
				this.setState({ model: address,AddressType: null });
			},
			error => {
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	LoadProgramsForUi = (temp) => {
		this.loadTaskPool.AppendTask(
			Proxy.POST(
				"/bankapi/v1.0/ClientCustomer/GetAllPrograms",
				{ UniqueClientId: temp ? temp.UniqueClientId : 0 },
				responseData => {
					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					if (responseData.Item != null) {
						this.baseState.services = responseData.Item;
						this.setState({ services: responseData.Item });
					}
				},
				error => {
					this.ShowMessage("error", "Error", error);
				}
			));
	}

	handleLoadTaskPoolCompleted = () => {
		this.setState({ isLoading: false });
	}

	handleLoadTaskPoolAppend = () => {
		this.setState({ isLoading: true });
	}

	HandleChange = (name, newValue, data) => {
		const { model, tempModel, vModel } = this.state;
		model[name] = newValue;

		if (name == "ClientTypeId") {
			if (data == null) {
				tempModel.isPersonal = null;
				delete model.CompanyLegalName;
				delete model.BusinessIdNumber;
				delete model.BusinessName;
				delete model.CompanyName;
				delete model.RegistrationName;
				delete model.RetailName;
				delete model.Name;
				delete model.MiddleName;
				delete model.SurName;
				delete model.BirthDate;
				delete model.OccupationId;
				delete model.CustomerName;

				delete model.PriorOccupationId;
				delete model.GenderId;

				delete model.IdentificationMethodId;

				delete model.CustomerStatusId;

			} else {
				if (data.ParameterValue === ClientType.Personal) {
					tempModel.isPersonal = true;
					delete model.CompanyLegalName;
					delete model.BusinessIdNumber;
					delete model.BusinessName;
					delete model.CompanyName;
					delete model.RegistrationName;
					delete model.RetailName;
				}
				if (data.ParameterValue === ClientType.Corporate) {
					tempModel.isPersonal = false;
					delete model.Name;
					delete model.MiddleName;
					delete model.SurName;
					delete model.BirthDate;
					delete model.OccupationId;

					delete model.PriorOccupationId;
					delete model.GenderId;

					delete model.IdentificationMethodId;

					delete model.EmployerAddress;
					delete model.EmployerZipCode;
				
					delete model.EmployerCountryId;
					delete model.EmployerProvinceId;
					delete model.EmployerCityId; 
				
				}

				model.CustomerStatusId = (model.CustomerStatusId == 0 
											|| model.CustomerStatusId == "undefined"
											|| model.CustomerStatusId == null)  
								? this.DefaultCustomerStatusId
								: model.CustomerStatusId; 
				
				model.IdentityVerifiedBy = ClientHelper.GetUser().Email;
				model.IdentityVerificationDate = DateHelper.GetDateLocal();
				model.SecondaryIdentityVerifiedBy = ClientHelper.GetUser().Email;
				model.SecondaryIdentityVerificationDate = DateHelper.GetDateLocal();
					
				model.PrimaryDocumentReviewedBy = ClientHelper.GetUser().Email;
				model.PrimaryDocumentVerificationDate =  DateHelper.GetDateLocal();				
				model.SecondaryDocumentReviewedBy = ClientHelper.GetUser().Email;
				model.SecondaryDocumentVerificationDate =  DateHelper.GetDateLocal();

			}			
		}

		if (name == "IsAutoGenerateCustomerNumber") {
			vModel.ClientCustomerNumber = !newValue;
			if (!newValue)
				model.ClientCustomerNumber = "";
		}

		if (name == "IdentificationMethodId") {
			// model.MustFillIdVerification = data && (data.ParameterValue === IdentificationMethod.InPersonIDVerification || data.ParameterValue === IdentificationMethod.CreditFileMethod);
			model.MustFillIdVerification = false;
			model.IdentityVerifyingPersonId = null;
						
			switch(newValue) {
				case this.DualMethodId:
					{
						// delete InPerson method
						delete model.IdentityTypeId;
						delete model.PrimaryProvincialIdentityTypeId;
						delete model.IdentityPlaceOfIssue;
						delete model.IdentityNumber;
						delete model.IdentityExpireDate;
						delete model.IdentityVerifiedBy;
						delete model.IdentityVerificationDate;

						delete model.SecondaryIdentityTypeId;
						delete model.SecondaryProvincialIdentityTypeId;
						delete model.SecondaryIdentityPlaceOfIssue;
						delete model.SecondaryIdentityNumber;
						delete model.SecondaryIdentityExpireDate;
						delete model.SecondaryIdentityVerifiedBy;
						delete model.SecondaryIdentityVerificationDate;

						// delete Credit File method
						delete model.CreditAgencyId;
						delete model.CreditFileNumber;
						delete model.CreditFileCompletionDate;

						model.PrimaryDocumentReviewedBy = ClientHelper.GetUser().Email;
						model.PrimaryDocumentVerificationDate =  DateHelper.GetDateLocal();

						model.SecondaryDocumentReviewedBy = ClientHelper.GetUser().Email;
						model.SecondaryDocumentVerificationDate =  DateHelper.GetDateLocal();

						break;
					}
				case this.InPersonMethodId:
					{
						// delete Dual method
						delete model.PrimaryDocumentId;
						delete model.PrimaryDocumentVerificationDate;
						delete model.PrimaryDocumentNumber;
						delete model.PrimaryDocumentReviewedBy;

						delete model.SecondaryDocumentId;
						delete model.SecondaryDocumentVerificationDate;
						delete model.SecondaryDocumentNumber;
						delete model.SecondaryDocumentReviewedBy;

						// delete Credit File method
						delete model.CreditAgencyId;
						delete model.CreditFileNumber;
						delete model.CreditFileCompletionDate;

						model.IdentityVerifiedBy = ClientHelper.GetUser().Email;
						model.IdentityVerificationDate = DateHelper.GetDateLocal();

						model.SecondaryIdentityVerifiedBy = ClientHelper.GetUser().Email;
						model.SecondaryIdentityVerificationDate = DateHelper.GetDateLocal();

						break;
					}
				case this.CreditFileMethodId:
					{
						// delete InPerson method
						delete model.IdentityTypeId;
						delete model.PrimaryProvincialIdentityTypeId;
						delete model.IdentityPlaceOfIssue;
						delete model.IdentityNumber;
						delete model.IdentityExpireDate;
						delete model.IdentityVerifiedBy;
						delete model.IdentityVerificationDate;

						delete model.SecondaryIdentityTypeId;
						delete model.SecondaryProvincialIdentityTypeId;
						delete model.SecondaryIdentityPlaceOfIssue;
						delete model.SecondaryIdentityNumber;
						delete model.SecondaryIdentityExpireDate;
						delete model.SecondaryVerifiedBy;
						delete model.SecondaryVerificationDate;

						// delete Dual method
						delete model.PrimaryDocumentId;
						delete model.PrimaryDocumentVerificationDate;
						delete model.PrimaryDocumentNumber;
						delete model.PrimaryDocumentReviewedBy;

						delete model.SecondaryDocumentId;
						delete model.SecondaryDocumentVerificationDate;
						delete model.SecondaryDocumentNumber;
						delete model.SecondaryDocumentReviewedBy;

						break;
					}
				case null:
					{
						// delete InPerson method
						delete model.IdentityTypeId;
						delete model.PrimaryProvincialIdentityTypeId;
						delete model.IdentityPlaceOfIssue;
						delete model.IdentityNumber;
						delete model.IdentityExpireDate;
						delete model.IdentityVerifiedBy;
						delete model.IdentityVerificationDate;

						delete model.SecondaryIdentityTypeId;
						delete model.SecondaryProvincialIdentityTypeId;
						delete model.SecondaryIdentityPlaceOfIssue;
						delete model.SecondaryIdentityNumber;
						delete model.SecondaryIdentityExpireDate;
						delete model.SecondaryVerifiedBy;
						delete model.SecondaryVerificationDate;

						// delete Dual method
						delete model.PrimaryDocumentId;
						delete model.PrimaryDocumentVerificationDate;
						delete model.PrimaryDocumentNumber;
						delete model.PrimaryDocumentReviewedBy;

						delete model.SecondaryDocumentId;
						delete model.SecondaryDocumentVerificationDate;
						delete model.SecondaryDocumentNumber;
						delete model.SecondaryDocumentReviewedBy;

						// delete Credit File method
						delete model.CreditAgencyId;
						delete model.CreditFileNumber;
						delete model.CreditFileCompletionDate;

						break;
					}
			}
			
		}

		if (name == "IdentityTypeId") {
			model.IdentityNumber = null;
			model.IdentityTypeParameterValue2 = data && data.ParameterValue2;
			model.ProofOfResidenceId = null;
		}

		if (name === "DontCheckCustomerInformation" && newValue) {
			var personalType = this.customerTypes.find(x => x.ParameterValue === ClientType.Personal);
			if (personalType)
				this.HandleChange("ClientTypeId", personalType.Id, personalType);
		}

		if (name == "OccupationId") {
			if (!this.retiredOrUnemployedOccupationIds.includes(newValue)) {
				delete model.PriorOccupationId;
			}
		} 

		this.setState({ model, tempModel, vModel });
	}

	LoadCustomerData() {
		Proxy.POST(
			"/bankapi/v1.0/ClientCustomer/Search",
			{ Id: this.state.model.Id },
			responseData => {
				const responseItem = responseData.Item[0];
				const model = { ...this.state.model, Accounts: responseItem?.Accounts }
				this.setState({ model });
			},
			this.ErrorCallback
		);
	}

	HandleChangeCustomer = (name, newValue, data) => {
		const { model, ClientCustomerList: list } = this.state;
		var index = list.findIndex(x => x.Id == newValue);
		if (index > -1) this.SelectedRowChange(index);
		else {
			if (newValue) {
				model[name] = newValue;
				this.setState({ model, rowIndex: index });
			} else {
				this.HandleClickClearOk();
			}
		}
	}

	HandleChangeTempModel = (name, newValue) => {
		var { tempModel } = this.state;
		if (name == "isGenerateNew" && newValue) {
			this.HandleClear(newValue);
		} else {
			tempModel[name] = newValue;
			this.setState({ tempModel });
		}
	}

	HandleChangeDDRRegistration = (name, newValue, data) => {
		var { ddrModel } = this.state;
		if (name === "IsDdrActiveId") {
			ddrModel.IsDdrActive = data.ParameterValue === "true";
		}
		ddrModel[name] = newValue;
		this.setState({ ddrModel });
	}

	HandleToggle = (value) => {
		const { model } = this.state;
		const currentIndex = model.Programs.indexOf(value);
		const newChecked = model.Programs;

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		model.Programs = newChecked;
		this.setState({ model });

		if (value === ProgramCodes.Bank_Programs_CDIC && currentIndex === -1) {
			this.GetDCAccountList();
		}
	}

	HandleChangeAccount = (name, newValue, data) => {
		const accountModel = { ...this.state.accountModel };
		accountModel[name] = newValue;

		if (name === "FinancialInstitutionId")
			accountModel.FinancialInstitution = data;

		if (name === "FinancialInstitutionBranchId")
			accountModel.FinancialInstitutionBranch = data;

		if (name == "FinancialInstitutionId" && data) {
			accountModel.BankNumber = data.FinancialInstitutionId;
			accountModel.FinancialInstitutionBranchId = null;
			accountModel.BranchNumber = null;
		}

		if (name == "FinancialInstitutionBranchId" && data) {
			accountModel.BranchNumber = data.TransitNumber;
		}

		this.setState({ accountModel });
	}

	HandleChangeDCAccount = (name, newValue, data) => {
		const DCAccountModel = { ...this.state.DCAccountModel };
		DCAccountModel[name] = newValue;
		this.setState({ DCAccountModel });
		this.GetDCAccountList(DCAccountModel);
	}

	GetWatchListCheckModel(clientCustomerId) {
		var { wCheckModel } = this.state;
		this.loadTaskPool.AppendTask(
			Proxy.POST(
				"/amlkycapi/v1.0/FinScanSearchLog/CustomerWatchListCheck",
				{
					"ClientCustomerId": clientCustomerId
				},
				responseData => {
					if (responseData.Item != null) {
						wCheckModel.ClientCustomerId = responseData.Item["ClientCustomerId"];
						wCheckModel.WatchListStatusId = responseData.Item["WatchListStatusId"];
						wCheckModel.WatchListStatusDescription = responseData.Item["WatchListStatusDescription"];
						wCheckModel.SearchResult = responseData.Item["WatchListSearchResult"];
						wCheckModel.LastReviewDate = responseData.Item["LastReviewDate"];
						
						this.setState({ wCheckModel });
					}
				},
				this.ErrorCallback
		));
	}

	HandleGetList = (temp) => {
		var { model, tempModel, ReadOnly, wCheckModel } = this.state;

		this.GetCustomerNameParameter(temp);

		if (!this.props.ForInsert) {
			this.loadTaskPool.AppendTask(
				Proxy.POST(
					"/bankapi/v1.0/ClientCustomer/Search",
					temp ? { Id: temp.Id } : this.state.model,
					responseData => {
						if (responseData.Item != null) {
							if (temp && responseData.Item.length === 1) {
								model = { ...responseData.Item[0] };
								// model.MustFillIdVerification = model.IdentificationMethod && (model.IdentificationMethod.ParameterValue === IdentificationMethod.InPersonIDVerification || model.IdentificationMethod.ParameterValue === IdentificationMethod.CreditFileMethod);
								model.MustFillIdVerification = false;
								if (temp.CDIC) {
									model.Programs.push(ProgramCodes.Bank_Programs_CDIC);
									ReadOnly = true;
								}
								if (temp.CallCenter) {
									ReadOnly = true;
								}																

								tempModel.isPersonal = model.ClientType.ParameterValue === ClientType.Personal ? true : model.ClientType.ParameterValue === ClientType.Corporate ? false : null;

								// create current FinScanSearchData search data string
								var finScanSearchData = (!tempModel.isPersonal) 
										? (model.CompanyLegalName + ";" + model.CountryId)
										: (model.Name + ";" + model.SurName + ";" + model.CountryId 
														  + ";" + model.BirthDate);
								this.setState({ FinScanSearchData: finScanSearchData });	
								
								var IsIncludePrepaid = model.Programs.includes("WALLET");
								var IsIncludeLender = model.Programs.includes(ProgramCodes.Lender);
									
								// get Watch list check data for Wallet or Lender customers
								if ((IsIncludePrepaid || IsIncludeLender) 
											&& (model.Id != wCheckModel.ClientCustomerId) ) {
									this.GetWatchListCheckModel(model.Id);
								}			
								
								this.setState({ ClientCustomerList: responseData.Item, model, tempModel, rowIndex: 0, ReadOnly, isEdit: ReadOnly === false });
							} else {
								this.setState({ ClientCustomerList: responseData.Item });
							}
						}
						else {
							this.setState({ ClientCustomerList: [] });
						}
					},
					this.ErrorCallback
			));
		}
		else {
			alert("For Insert");
		}
	}

	SelectedRowChange = (index) => {
		const { tempModel, ddrModel } = this.state;
		const list = [...this.state.ClientCustomerList];
		const customer = Object.assign({}, list[index]);

		// Customer null ise hiç kod çalışmayacak şekle getirildi. Ama customer nesnesi null olsa da çalışacak bi kod varsa düzenleme yapılması gerek.
		if (customer == null) {
			return;
		}

		if (customer.ClientType != null) {
			if (customer.ClientType.ParameterValue === ClientType.Personal) {
				tempModel.isPersonal = true;
			}
			else {
				tempModel.isPersonal = false;
			}
		}

		tempModel.isGenerateNew = false;

		var IsIncludePrepaid = customer.Programs.includes("WALLET");
		var IsIncludeLender = customer.Programs.includes(ProgramCodes.Lender);

		// get Watch list check data for Wallet or Lender customers
		if (IsIncludePrepaid || IsIncludeLender) {
			this.GetWatchListCheckModel(customer.Id);
		}						

		if (customer.Programs.includes(ProgramCodes.Bank_Programs_CDIC))
			this.GetDCAccountList();

		// customer.MustFillIdVerification = customer.IdentificationMethod && (customer.IdentificationMethod.ParameterValue === IdentificationMethod.InPersonIDVerification || customer.IdentificationMethod.ParameterValue === IdentificationMethod.CreditFileMethod);
		customer.MustFillIdVerification = false;

		ddrModel.InteracEmail = customer.InteracEmail;
		ddrModel.IsDdrActiveId = customer.IsDdrActiveId;
		ddrModel.IsDdrActive = customer.IsDdrActive;
		ddrModel.WalletAccountId = customer.WalletAccountId;

		customer.IdentityTypeParameterValue2 = customer.IdentityType && customer.IdentityType.ParameterValue2;
		this.setState({ model: { ...customer }, tempModel, rowIndex: index, isEdit: true, isDDRInquiryOpen: false, ddrModel });
	}

	HandleSubmit = () => {
		if (!this.Validate())
			return;

		this.ShowConfirmMessage("warning", this.state.model.Id > 0 ? "Edit" : "Save", "Are you sure you want to save this customer ?", () => this.HandleSubmitOk());
	}

	Validate = () => {
		const { model, tempModel, vModel, isEdit } = this.state;

		var IsIncludeEFT = model.Programs.includes(ProgramCodes.Bank_Programs_EFT);
		var IsIncludeET = model.Programs.includes(ProgramCodes.Bank_Programs_e_Transfer);
		var IsIncludeCDIC = model.Programs.includes(ProgramCodes.Bank_Programs_CDIC);
		var IsIncludePrepaid = model.Programs.includes("WALLET");
		var IsIncludeLender = model.Programs.includes(ProgramCodes.Lender);
		var IsFilledPhoneNumber = model.PhoneCountryCodeId > 0 && model.PhoneNumber;

		var IsEmailValid = StringHelper.VerifyEmail(model.Email);

		var requireds = [];

		requireds.push({ ClientCustomerNumber: !model.IsAutoGenerateCustomerNumber && (model.ClientCustomerNumber == null || model.ClientCustomerNumber.length < this.maxLengthCustomerNumber.maxLength) });
		requireds.push({ NewCustomer: isEdit == false && !tempModel.isGenerateNew });
		requireds.push({ Program: (!model.DontCheckCustomerInformation && model.Programs.length == 0) });
		requireds.push({ Account: (model.Accounts.filter(x => x.IsRecordValid).length == 0 && IsIncludeEFT) });
		requireds.push({ ClientType: (model.ClientTypeId == null || model.ClientTypeId == undefined) });
		
		// requireds.push({ Email: (IsIncludeCDIC || (IsIncludeET && IsFilledPhoneNumber == false) || IsIncludeLender || IsIncludePrepaid) && (model.Email == null || model.Email == undefined || model.Email.trim() == "") });
		
		requireds.push({ Email: !IsFilledPhoneNumber && (IsIncludeLender || IsIncludePrepaid) 
								&& (model.Email == null || model.Email == undefined || model.Email.trim() == "") });

		requireds.push({ EmailValid: !model.DontCheckCustomerInformation && (model.Email && model.Email.trim().length > 0 ? !IsEmailValid : false) });
		requireds.push({ FirstName: (model.DontCheckCustomerInformation || tempModel.isPersonal) && (model.Name == null || model.Name.trim() == "") });
		requireds.push({ LastName: (model.DontCheckCustomerInformation || tempModel.isPersonal) && (model.SurName == null || model.SurName.trim() == "") });

		requireds.push({ BirthDate: !model.DontCheckCustomerInformation && (tempModel.isPersonal && (IsIncludeCDIC || IsIncludeLender || IsIncludePrepaid)) && (model.BirthDate == null || model.BirthDate == "") });

		requireds.push({ CompanyLegalName: !model.DontCheckCustomerInformation && (tempModel.isPersonal == false) && (model.CompanyLegalName == null || model.CompanyLegalName.trim() == "") });
		requireds.push({ BusinessIdNumber: !model.DontCheckCustomerInformation && (tempModel.isPersonal == false) && (IsIncludeCDIC || IsIncludeEFT || IsIncludeET || IsIncludeLender || IsIncludePrepaid) && (model.BusinessIdNumber == null || model.BusinessIdNumber.trim() == "") });
		requireds.push({ BusinessName: !model.DontCheckCustomerInformation && (tempModel.isPersonal == false) && (model.BusinessName == null || model.BusinessName.trim() == "") });
		requireds.push({ CompanyName: !model.DontCheckCustomerInformation && (tempModel.isPersonal == false && IsIncludeET) && (model.CompanyName == null || model.CompanyName.trim() == "") });
		requireds.push({ RegistrationName: !model.DontCheckCustomerInformation && (tempModel.isPersonal == false && IsIncludeET) && (model.RegistrationName == null || model.RegistrationName.trim() == "") });
		requireds.push({ RetailName: !model.DontCheckCustomerInformation && (tempModel.isPersonal == false && IsIncludeET) && (model.RetailName == null || model.RetailName.trim() == "") });
		
		// requireds.push({ PhoneCountryCode: (IsIncludeCDIC || (IsIncludeET && !model.Email) || IsIncludeLender || IsIncludePrepaid) && !model.DontCheckCustomerInformation && (tempModel.isPersonal != null) && (model.PhoneCountryCodeId == null || model.PhoneCountryCodeId == undefined) });
		requireds.push({ PhoneCountryCode:  !model.Email 
								&& (IsIncludeLender || IsIncludePrepaid) 						
								&& (model.PhoneCountryCodeId == null || model.PhoneCountryCodeId == undefined) });
		
		// requireds.push({ PhoneNumber: (IsIncludeCDIC || (IsIncludeET && !model.Email) || IsIncludeLender || IsIncludePrepaid) && !model.DontCheckCustomerInformation && (tempModel.isPersonal != null) && (model.PhoneNumber == null || model.PhoneNumber.toString().trim() == "") });
		requireds.push({ PhoneNumber: !model.Email 
								&& (IsIncludeLender || IsIncludePrepaid) 
								&& (model.PhoneNumber == null || model.PhoneNumber.toString().trim() == "") });

		requireds.push({ Occupation: (model.DontCheckCustomerInformation || tempModel.isPersonal) 
								&& (model.OccupationId == null) 
								&& (IsIncludeLender || IsIncludePrepaid) });
		// requireds.push({ ValidationIdentityFields: (tempModel.isPersonal && (IsIncludePrepaid)) });

		// Validation od AddressLine1, PostalCode, Country, Province, City
		// has been added
		requireds.push({ AddressLine1: (IsIncludeLender || IsIncludePrepaid) && !model.DontCheckCustomerInformation && (model.AddressLine1 == null || model.AddressLine1.toString().trim() == "") });
		requireds.push({ ZipCode: (IsIncludeLender || IsIncludePrepaid) && !model.DontCheckCustomerInformation && (model.ZipCode == null || model.ZipCode.toString().trim() == "") });
		requireds.push({ CountryId: (IsIncludeLender || IsIncludePrepaid) && !model.DontCheckCustomerInformation && (model.CountryId == null || model.CountryId.toString().trim() == "") });
		requireds.push({ ProvinceId: (IsIncludeLender || IsIncludePrepaid) && !model.DontCheckCustomerInformation && (model.ProvinceId == null || model.ProvinceId.toString().trim() == "") });
		requireds.push({ CityId: (IsIncludeLender || IsIncludePrepaid) && !model.DontCheckCustomerInformation && (model.CityId == null || model.CityId.toString().trim() == "") });

		requireds.push({ CustomerStatus: (model.CustomerStatusId == null || model.CustomerStatusId == undefined) 
													&& (IsIncludeLender || IsIncludePrepaid)});

		requireds.push({ Gender: (model.DontCheckCustomerInformation || tempModel.isPersonal) 
										&& (IsIncludeLender || IsIncludePrepaid)
										&& (model.GenderId == null || model.GenderId.toString().trim() == "") });
		
		requireds.push({ PriorOccupation: (model.DontCheckCustomerInformation || tempModel.isPersonal) 
							&& (model.PriorOccupationId == null || model.PriorOccupationId.toString().trim() == "")
							&& this.retiredOrUnemployedOccupationIds.includes(model.OccupationId) 
							&& (IsIncludeLender || IsIncludePrepaid) });
		
		requireds.push({ IdentificationMethodId: (IsIncludeLender || IsIncludePrepaid) 
								&& tempModel.isPersonal 
								&& (model.IdentificationMethodId == null || model.IdentificationMethodId.toString().trim() == "") });

		requireds.push({ CreditAgency: (IsIncludeLender || IsIncludePrepaid) 
							&& tempModel.isPersonal 
							&& (model.CreditAgencyId == null || model.CreditAgencyId.toString().trim() == "") 
						   && model.IdentificationMethodId == this.CreditFileMethodId});

		requireds.push({ CreditFile: (IsIncludeLender || IsIncludePrepaid) 
							&& tempModel.isPersonal 
							&& (model.CreditFileNumber == null || model.CreditFileNumber.trim() == "") 
							&& model.IdentificationMethodId == this.CreditFileMethodId});

		requireds.push({ CreditFileCompletionDate: (IsIncludeLender || IsIncludePrepaid) 
							&& tempModel.isPersonal 
							&& (model.CreditFileCompletionDate == null || model.CreditFileCompletionDate == "") 
							&& model.IdentificationMethodId == this.CreditFileMethodId});
		
		requireds.push({ PrimaryDocument: (IsIncludeLender || IsIncludePrepaid) 
							&& tempModel.isPersonal 
							&& (model.PrimaryDocumentId == null || model.PrimaryDocumentId.toString().trim() == "") 
							&& model.IdentificationMethodId == this.DualMethodId});

		requireds.push({ PrimaryDocumentVerificationDate: (IsIncludeLender || IsIncludePrepaid) 
							&& tempModel.isPersonal 
							&& (model.PrimaryDocumentVerificationDate == null || model.PrimaryDocumentVerificationDate == "") 
							&& model.IdentificationMethodId == this.DualMethodId});

		requireds.push({ PrimaryDocumentNumber: (IsIncludeLender || IsIncludePrepaid) 
							&& tempModel.isPersonal 
							&& (model.PrimaryDocumentNumber == null || model.PrimaryDocumentNumber.trim() == "") 
							&& model.IdentificationMethodId == this.DualMethodId});

		requireds.push({ PrimaryDocumentReviewedBy: (IsIncludeLender || IsIncludePrepaid) 
							&& tempModel.isPersonal 
							&& (model.PrimaryDocumentReviewedBy == null || model.PrimaryDocumentReviewedBy.trim() == "") 
							&& model.IdentificationMethodId == this.DualMethodId});
		
		requireds.push({ IdentityType: (IsIncludeLender || IsIncludePrepaid) 
							&& tempModel.isPersonal 
							&& (model.IdentityTypeId == null || model.IdentityTypeId.toString().trim() == "") 
							&& model.IdentificationMethodId == this.InPersonMethodId});

		requireds.push({ ProvincialIdentityType: (IsIncludeLender || IsIncludePrepaid) 
							&& tempModel.isPersonal 
							&& (model.PrimaryProvincialIdentityTypeId == null || model.PrimaryProvincialIdentityTypeId.toString().trim() == "") 
							&&  this.specificIdentityTypesIds.includes(model.IdentityTypeId) 
							&& model.IdentificationMethodId == this.InPersonMethodId});

		requireds.push({ IdentityPlaceOfIssue: (IsIncludeLender || IsIncludePrepaid) 
							&& tempModel.isPersonal 
							&& (model.IdentityPlaceOfIssue == null || model.IdentityPlaceOfIssue.trim() == "") 
							&& model.IdentificationMethodId == this.InPersonMethodId});

		requireds.push({ IdentityNumber: (IsIncludeLender || IsIncludePrepaid) 
							&& tempModel.isPersonal 
							&& (model.IdentityNumber == null || model.IdentityNumber.trim() == "") 
							&& model.IdentificationMethodId == this.InPersonMethodId});

		requireds.push({ IdentityExpireDate: (IsIncludeLender || IsIncludePrepaid) 
							&& tempModel.isPersonal 
							&& (model.IdentityExpireDate == null || model.IdentityExpireDate == "") 
							&& model.IdentificationMethodId == this.InPersonMethodId});

		requireds.push({ IdentityVerifiedBy: (IsIncludeLender || IsIncludePrepaid) 
							&& tempModel.isPersonal 
							&& (model.IdentityVerifiedBy == null || model.IdentityVerifiedBy.trim() == "") 
							&& model.IdentificationMethodId == this.InPersonMethodId});

		requireds.push({ IdentityVerificationDate: (IsIncludeLender || IsIncludePrepaid) 
							&& tempModel.isPersonal 
							&& (model.IdentityVerificationDate == null || model.IdentityVerificationDate == "") 
							&& model.IdentificationMethodId == this.InPersonMethodId});

		var messages = [];
		if (requireds.find(x => x.NewCustomer == true)) {
			this.ShowMessage("warning", "Warning", "Add New Customer must be selected!");
			return false;
		}

		if (requireds.find(x => x.ClientCustomerNumber == true)) {
			this.ShowMessage("warning", "Warning", "Please enter a valid client customer number or select Auto-Generate checkbox!");
			vModel.ClientCustomerNumber = true;
			return false;
		} else {
			vModel.ClientCustomerNumber = false;
		}

		if (requireds.find(x => x.Program == true)) {
			this.ShowMessage("warning", "Warning", "Must be at least one program!");
			return false;
		}

		if (requireds.find(x => x.Account == true)) {
			vModel.ExternalAccount = true;
			this.ShowMessage("warning", "Warning", "Must be at least one account!");
			return false;
		} else {
			vModel.ExternalAccount = false;
		}

		if (requireds.find(x => x.ClientType == true)) {
			vModel.ClientType = true;
			messages.push("Customer Type cannot be empty");
		} else {
			vModel.ClientType = false;
		}

		if (requireds.find(x => x.CustomerStatus == true)) {
			vModel.CustomerStatus = true;
			messages.push("Customer Status cannot be empty");
		} else {
			vModel.CustomerStatus = false;
		}

		if (requireds.find(x => x.Email == true)) {
			if (IsIncludeET) {
				vModel.PhoneCountryCode = requireds.find(x => x.PhoneCountryCode == true);
				vModel.PhoneNumber = requireds.find(x => x.PhoneNumber == true);
				messages.push("Either E-Mail or Phone Country Code and Phone Number must be defined.");
			} else {
				messages.push("E-Mail cannot be empty");
			}
			vModel.Email = true;
		} else {
			vModel.Email = false;
		}

		if (requireds.find(x => x.EmailValid == true)) {
			vModel.Email = true;
			messages.push("E-Mail is invalid");
		} else if (requireds.find(x => x.EmailValid == false)) {
			vModel.Email = false;
		}

		if (requireds.find(x => x.ValidationIdentityFields == true)) {
			var identityMessages = this.ValidationIdentityFields();
			identityMessages.map(msg => {
				messages.push(msg);
				return null;
			});
		}

		if (requireds.find(x => x.FirstName == true)) {
			vModel.FirstName = true;
			messages.push("First Name cannot be empty");
		} else {
			vModel.FirstName = false;
		}

		if (requireds.find(x => x.Occupation == true)) {
			vModel.Occupation = true;
			messages.push("Occupation cannot be empty");
		} else {
			vModel.Occupation = false;
		}

		if (requireds.find(x => x.PriorOccupation == true)) {
			vModel.PriorOccupation = true;
			messages.push("Prior Occupation cannot be empty");
		} else {
			vModel.PriorOccupation = false;
		}

		if (requireds.find(x => x.LastName == true)) {
			vModel.LastName = true;
			messages.push("Last Name cannot be empty");
		} else {
			vModel.LastName = false;
		}

		if (requireds.find(x => x.BirthDate == true)) {
			vModel.BirthDate = true;
			messages.push("Birth Date cannot be empty");
		} else {
			vModel.BirthDate = false;
		}

		if (requireds.find(x => x.CompanyLegalName == true)) {
			vModel.CompanyLegalName = true;
			messages.push("Company Legal Name cannot be empty");
		} else {
			vModel.CompanyLegalName = false;
		}

		if (requireds.find(x => x.BusinessIdNumber == true)) {
			vModel.BusinessIdNumber = true;
			messages.push("Business Id Number cannot be empty");
		} else {
			vModel.BusinessIdNumber = false;
		}

		if (requireds.find(x => x.BusinessName == true)) {
			vModel.BusinessName = true;
			messages.push("Business Name cannot be empty");
		} else {
			vModel.BusinessName = false;
		}

		if (requireds.find(x => x.CompanyName == true)) {
			vModel.CompanyName = true;
			messages.push("Company Name cannot be empty");
		} else {
			vModel.CompanyName = false;
		}

		if (requireds.find(x => x.RegistrationName == true)) {
			vModel.RegistrationName = true;
			messages.push("Registration Name cannot be empty");
		} else {
			vModel.RegistrationName = false;
		}

		if (requireds.find(x => x.RetailName == true)) {
			vModel.RetailName = true;
			messages.push("Retail Name cannot be empty");
		} else {
			vModel.RetailName = false;
		}

		if (requireds.find(x => x.PhoneCountryCode == true) && !IsIncludeET) {
			vModel.PhoneCountryCode = true;
			messages.push("Phone Country Code cannot be empty");
		} else {
			vModel.PhoneCountryCode = false;
		}

		if (requireds.find(x => x.PhoneNumber == true) && !IsIncludeET) {
			vModel.PhoneNumber = true;
			messages.push("Phone Number cannot be empty");
		} else {
			vModel.PhoneNumber = false;
		}

		if (model.PhoneNumber != undefined && model.PhoneNumber != null 
						&& model.PhoneNumber != ""	
						&& !StringHelper.IsValidatePhone(model.PhoneNumber.toString())) {
			vModel.PhoneNumber = true;
			messages.push("Phone Number is invalid");
		}

		if (requireds.find(x => x.Gender == true)) {
			messages.push("Gender cannot be empty");
			vModel.Gender = true;
		} else {
			vModel.Gender = false;
		} 

		if (requireds.find(x => x.AddressLine1 == true)) {
			messages.push("Address cannot be empty");
			vModel.AddressLine1 = true;
		} else {
			vModel.AddressLine1 = false;
		}

		if  (requireds.find(x => x.ZipCode == true)) {
			messages.push("Postal Code cannot be empty");
			vModel.PostalCode = true;
		} else {
			vModel.PostalCode = false;
		}

		if (requireds.find(x => x.CountryId == true)) {
			messages.push("Country cannot be empty");
			vModel.CountryId = true;
		} else {
			vModel.CountryId = false;
		}

		if (requireds.find(x => x.ProvinceId == true)) {
			messages.push("Province cannot be empty");
			vModel.ProvinceId = true;
		} else {
			vModel.ProvinceId = false;
		}

		if (requireds.find(x => x.CityId == true)) {
			messages.push("City cannot be empty");
			vModel.CityId = true;
		} else {
			vModel.CityId = false;
		}

		if (requireds.find(x => x.IdentificationMethodId == true)) {
			messages.push("Identification Method cannot be empty");
			vModel.IdentificationMethodId = true;
		} else {
			vModel.IdentificationMethodId = false;
		}

		if (requireds.find(x => x.CreditAgency == true)) {
			messages.push("Credit Agency cannot be empty");
			vModel.CreditAgencyId = true;
		} else {
			vModel.CreditAgencyId = false;
		}

		if (requireds.find(x => x.CreditFile == true)) {
			messages.push("Credit File Number cannot be empty");
			vModel.CreditFileNumber = true;
		} else {
			vModel.CreditFileNumber = false;
		}

		if (requireds.find(x => x.CreditFileCompletionDate == true)) {
			messages.push("Credit File Completion Date Number cannot be empty");
			vModel.CreditFileCompletionDate = true;
		} else {
			vModel.CreditFileCompletionDate = false;
		}

		if (requireds.find(x => x.PrimaryDocument == true)) {
			messages.push("Document cannot be empty");
			vModel.PrimaryDocumentId = true;
		} else {
			vModel.PrimaryDocumentId = false;
		}

		if (requireds.find(x => x.PrimaryDocumentVerificationDate == true)) {
			messages.push("Verification Date cannot be empty");
			vModel.PrimaryDocumentVerificationDate = true;
		} else {
			vModel.PrimaryDocumentVerificationDate = false;
		}

		if (requireds.find(x => x.PrimaryDocumentNumber == true)) {
			messages.push("Document Number cannot be empty");
			vModel.PrimaryDocumentNumber = true;
		} else {
			vModel.PrimaryDocumentNumber = false;
		}

		if (requireds.find(x => x.PrimaryDocumentReviewedBy == true)) {
			messages.push("Reviewed By cannot be empty");
			vModel.PrimaryDocumentReviewedBy = true;
		} else {
			vModel.PrimaryDocumentReviewedBy = false;
		}

		if (requireds.find(x => x.IdentityType == true)) {
			messages.push("Identity type cannot be empty");
			vModel.IdentityTypeId = true;
		} else {
			vModel.IdentityTypeId = false;
		}

		if (requireds.find(x => x.IdentityPlaceOfIssue == true)) {
			messages.push("Place of Issue cannot be empty");
			vModel.IdentityPlaceOfIssue = true;
		} else {
			vModel.IdentityPlaceOfIssue = false;
		}

		if (requireds.find(x => x.IdentityNumber == true)) {
			messages.push("Identity Number cannot be empty");
			vModel.IdentityNumber = true;
		} else {
			vModel.IdentityNumber = false;
		}

		if (requireds.find(x => x.IdentityExpireDate == true)) {
			messages.push("Identity Expire Date cannot be empty");
			vModel.IdentityExpireDate = true;
		} else {
			vModel.IdentityExpireDate = false;
		}

		if (requireds.find(x => x.IdentityVerifiedBy == true)) {
			messages.push("Verified By cannot be empty");
			vModel.IdentityVerifiedBy = true;
		} else {
			vModel.IdentityVerifiedBy = false;
		}

		if (requireds.find(x => x.IdentityVerificationDate == true)) {
			messages.push("Verification Date cannot be empty");
			vModel.IdentityVerificationDate = true;
		} else {
			vModel.IdentityVerificationDate = false;
		}

		if (requireds.find(x => x.ProvincialIdentityType == true)) {
			messages.push("Provincial Id Type cannot be empty");
			vModel.ProvincialIdentityTypeId = true;
		} else {
			vModel.ProvincialIdentityTypeId = false;
		}


		if (messages.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.setState({ vModel });
		return true;
	}

	HandleDelete = () => {
		if (!this.state.model.Id) {
			this.setState({
				alert: <GenericAlert Title={"Warning"} MessageNode={"Please select a customer from grid."} Type={"warning"} OnConfirm={() => this.HideAlert()} />
			});
		}
		else {
			this.ShowConfirmMessage("warning", "Delete", "Are you sure you want to inactive this customer ?", () => this.HandleClickDeleteOk());
		}
	}

	HandleClickDeleteOk = () => {
		this.HideAlert();
		this.setState({ isLoading: true });
		Proxy.GET(
			"/bankapi/v1.0/ClientCustomer/Delete/Id?Id=" + this.state.model.Id,
			this.SuccessDeleteCallback,
			this.ErrorCallback
		);
	}

	HandleClear = (isGenerateNew) => {
		this.ShowConfirmMessage("warning", "Clear", "This will erase the information you entered on the screen. Are you sure you want to quit this customer ?", () => this.HandleClickClearOk(isGenerateNew));
	}

	HandleClickClearOk = (isGenerateNew) => {
		this.setState(function (state) {
			this.baseState.ClientCustomerList = state.ClientCustomerList;
			state = this.baseState;
			state.model = {
				Id: 0,
				Accounts: [],
				WalletAccounts: [],
				Programs: this.props.Programs || [],
				UniqueClientId: ClientHelper.GetClientRowId()
			};
			state.tempModel = {
				isGenerateNew: this.props.ForInsert || this.props.ForUpdate || isGenerateNew,
				isPersonal: null
			};
			state.ddrModel = {};
			state.rowIndex = -1;
			return state;
		});
	}

	HandleNext = () => {
		const { model, tempModel } = this.state;

		if (tempModel.isPersonal)
			model.CustomerName = model.Name + " " + model.SurName;
		else
			model.CustomerName = model.CompanyLegalName;

		this.loadTaskPool.AppendTask(
			Proxy.POST(
				"/amlkycapi/v1.0/AmlKycCheck/KycCheck",
				{
					MenuCode: MenuCodes.CustomerDefinition,
					ActionCode: "Submit",
					JsonData: JSON.stringify(model)
				},
				(responseData) => {
					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					if (responseData.Item !== null) {
						if (responseData.Item.Result) {
							this.setState({ handledByNext: true }, () => this.HandleSubmit());
						} else {
							this.ShowMessage("error", "Error", responseData.Item.Reason);
							return;
						}
					}
				},
				this.ErrorCallback
			));
	}

	CheckOld = () => {
		var interval = moment.duration(DateHelper.GetDate() - DateHelper.ToMoment(this.state.model.BirthDate)).asYears();
		if (interval < 18) {
			return false;
		}
		return true;
	}

	GetDCAccountList = (model) => {
		Proxy.POST(
			"/bankapi/v1.0/ClientCustomer/GetAllAccounts",
			{
				AccountNumber: model ? model.AccountNumber : this.state.DCAccountModel.AccountNumber,
				ClientCustomerId: this.state.model.Id
			},
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ CDICAccounts: responseData.Item });
				}
			},
			(error) => {
			}
		);
	}

	ValidationIdentityFields = () => {
		const { model, vModel } = this.state;
		var messages = [];

		if (model.IdentificationMethodId == null) {
			vModel.IdentificationMethodId = true;
			messages.push("Identity Method cannot be empty");
		} else {
			vModel.IdentificationMethodId = false;
		}

		if (model.MustFillIdVerification && model.IdentityVerifyingPersonId == null) {
			vModel.IdentityVerifyingPersonId = true;
			messages.push("Person Verifying cannot be empty");
		} else {
			vModel.IdentityVerifyingPersonId = false;
		}

		if (model.IdentityTypeId == null) {
			vModel.IdentityTypeId = true;
			messages.push("Identity Type cannot be empty");
		} else {
			vModel.IdentityTypeId = false;
		}

		if (model.IdentityNumber == null || model.IdentityNumber.trim() == "") {
			vModel.IdentityNumber = true;
			messages.push("Identity Number cannot be empty");
		} else {
			vModel.IdentityNumber = false;
		}

		if (model.IdentityPlaceOfIssue == null || model.IdentityPlaceOfIssue.trim() == "") {
			vModel.IdentityPlaceOfIssue = true;
			messages.push("Identity  Place of Issue cannot be empty");
		} else {
			vModel.IdentityPlaceOfIssue = false;
		}

		if (model == null || model.IdentityExpireDate == null) {
			vModel.IdentityExpireDate = true;
			messages.push("Identity Expire Date cannot be empty");
		} else {
			model.IdentityExpireDate = DateHelper.ToMoment(model.IdentityExpireDate);
			if (!model.IdentityExpireDate.isValid()) {
				vModel.IdentityExpireDate = true;
				messages.push("Identity Expire Date must be valid!");
			}
			var interval1 = model.IdentityExpireDate.diff(DateHelper.GetDate(), "days");
			if (interval1 < 30) {
				vModel.IdentityExpireDate = true;
				messages.push("Identity Expiration Date must be valid within the next 30 days!");
			} else {
				vModel.IdentityExpireDate = false;
			}
		}

		// Identity number check by IdentityTypeId
		if (model.IdentityType && model.IdentityType.ParameterValue == null) {
			vModel.IdentityTypeId = true;
			messages.push("Identity Type must be valid!");
		}

		this.setState({ vModel });
		return messages;
	}

	HandleSubmitOk = () => {
		this.HideAlert();
		var CheckOld = true;
		if (this.state.model.BirthDate)
			CheckOld = this.CheckOld();

		if (CheckOld) {
			this.setState({ isLoading: true });
			var model = { ...this.state.model };
			delete model.CustomerName;

			var isNewCustomer = (model.Id == 0);

			// create current FinScanSearchData search data string and
			// compare it with existing search data
			var curFinScanSearchData = (!isNewCustomer) ? this.state.FinScanSearchData : null;

			var newFinScanSearchData = (isNewCustomer) ? null
																	 : (
										(model.ClientType.ParameterValue === ClientType.Corporate) 
														? (model.CompanyLegalName + ";" + model.CountryId)
														: (model.Name + ";" + model.SurName + ";" + model.CountryId 
														  					+ ";" + model.BirthDate)
																	 ); 

						
			this.setState({ IsFinScanCheckRequired: (isNewCustomer || (curFinScanSearchData != newFinScanSearchData)) });
			this.setState({ FinScanSearchData: null });

			Proxy.POST(
				"/bankapi/v1.0/ClientCustomer/InsertOrUpdate",
				model,
				this.SuccessSaveCallback,
				this.ErrorCallback
			);
		}
		else {
			this.ShowMessage("error", "Error", "Customer age must be bigger then 18 !");
		}
	}

	SuccessSaveCallback = (responseData) => {
		let { model, ClientCustomerList, ddrModel, handledByNext } = this.state;
		this.setState({ isLoading: false });
		if (!responseData.IsSucceeded) {
			if (responseData.ErrorCode === "ClientCustomerCheckPrimaryAccountE1")
				this.setState({ isErrorDialogOpen: true, errorMessage: responseData.ErrorDescription });
			else
				this.ShowMessage("error", "Error", responseData.ErrorDescription);
			return;
		}

		// inserted or updated, do watchlist check
		var IsIncludePrepaid = model.Programs.includes("WALLET");
		var IsIncludeLender = model.Programs.includes(ProgramCodes.Lender);	
		var isFinScanCheckRequired	= this.state.IsFinScanCheckRequired;

		if ((IsIncludePrepaid || IsIncludeLender) 
					&& isFinScanCheckRequired) {
			this.GetWatchListCheckModel(responseData.Item["Id"]);
			this.setState({ IsFinScanCheckRequired: false });
		}

		if (this.props.CustomerSaved) {
			this.props.CustomerSaved(responseData.Item);
		} else {
			if (handledByNext) {
				let customer = responseData.Item;
				customer.Programs = model.Programs;
				var index = ClientCustomerList.findIndex(x => x.Id == customer.Id);
				if (index > -1) ClientCustomerList[index] = customer;
				else {
					ClientCustomerList.push(customer);
					index = ClientCustomerList.findIndex(x => x.Id == customer.Id);
				}

				ddrModel.InteracEmail = customer.InteracEmail;
				ddrModel.IsDdrActiveId = customer.IsDdrActiveId;
				ddrModel.IsDdrActive = customer.IsDdrActive;
				ddrModel.WalletAccountId = customer.WalletAccountId;

				this.setState({ model: customer, ClientCustomerList, hasChanged: !this.state.hasChanged, isDDRInquiryOpen: true, rowIndex: index, ddrModel });
			} else {
				this.setState({ hasChanged: !this.state.hasChanged });

				this.HandleClickClearOk();
				if (!this.props.ForUpdate)
					this.HandleGetList();
				else
					this.HandleGetList(model);
			}
		}
		
		this.ShowMessage("success", "Success", "Saved successfully!");
	}

	SuccessDeleteCallback = () => {
		this.setState({ isLoading: false, hasChanged: !this.state.hasChanged });
		this.HandleClickClearOk();
		this.HandleGetList();
		this.ShowMessage("success", "Success", "Customer was deleted successfully!");
	}

	ErrorCallback = (error) => {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", "An error occurred during the api visit " + error);
	}

	HandleDDRInquiry = () => {
		this.setState({ isLoading: true });
		var model = { ...this.state.model };

		Proxy.POST(
			"/bankapi/v1.0/BankInteracETransfer/GetTransferOptions",
			{
				UniqueClientId: 0,
				ProgramCode: ProgramCodes.Bank_Programs_e_Transfer,
				Email: model.Email
			},
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ DDRInquiryResult: responseData.Item ? "Available" : "Unavailable" });
			},
			this.ErrorCallback
		);
	}

	HandleDDR = () => {
		const { ddrModel, isEdit } = this.state;

		if (!ddrModel.InteracEmail) {
			this.ShowMessage("warning", "Warning", "Interac E-Mail cannot be empty!");
			return;
		}
		if (!ddrModel.IsDdrActiveId) {
			this.ShowMessage("warning", "Warning", "You have to choose for DDR!");
			return;
		}
		if (ddrModel.IsDdrActive) {
			if (!ddrModel.WalletAccountId) {
				this.ShowMessage("warning", "Warning", "Wallet Wallet cannot be empty!");
				return;
			}
		}

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/ClientCustomer/DirectDepositRegistration",
			{
				ClientCustomerId: this.state.model.Id,
				IsDdrActive: ddrModel.IsDdrActive,
				WalletAccountId: ddrModel.WalletAccountId,
				InteracEmail: ddrModel.InteracEmail,
				IsUpdate: isEdit
			},
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item)
					this.ShowMessage("success", "Success", "Operation is successfully!");
			},
			this.ErrorCallback
		);
	}

	AddAccount = (index, isEdit) => {
		const { model, accountModel, hasChangedAccount } = this.state;
		accountModel.IsRecordValid = true;

		if (isEdit && index > -1) {
			model.Accounts[index] = accountModel;
		}
		else {
			model.Accounts.push(accountModel);
		}
		this.setState({ model, accountModel: {}, hasChangedAccount: !hasChangedAccount });
	}

	DeleteAccount = (index) => {
		const { model, hasChangedAccount } = this.state;
		var accountModel = model.Accounts[index];
		if (accountModel.Id > 0) {
			accountModel.IsRecordValid = false;
			model.Accounts[index] = accountModel;
		} else {
			model.Accounts.splice(index, 1);
		}
		this.setState({ model, accountModel: {}, hasChangedAccount: !hasChangedAccount });
	}

	SelectedRowChangeAccount = (index) => {
		var accountModel = this.state.model.Accounts.filter(x => x.IsRecordValid)[index];
		this.setState({ accountModel });
	}

	ClearAccount = () => {
		this.setState({ accountModel: {} });
	}

	GetCustomerNameParameter = (temp) => {
		if (temp) {
			this.parameterCustomerName.Id = temp.Id;
			this.parameterCustomerName.UniqueClientId = temp.UniqueClientId;
		} else {
			this.parameterCustomerName = { ...this.emptyObject };
		}
		this.setState({ hasChanged: !this.state.hasChanged });
		return this.parameterCustomerName;
	}

	GetWalletAccountParameter = () => {
		this.parameteWalletAccount.ClientCustomerId = this.state.model.Id;
		return this.parameteWalletAccount;
	}

	GetTooltip() {
		return `The prefix of the customer number is ${this.state.uniq} for you. Please complete the rest of the customer number to enter a valid customer number.`;
	}

	RenderItemAccount = (d) => {
		this.renderItemAccount = `${d.AccountNumber} - ${d.AccountName}`;
		return this.renderItemAccount;
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: null })} OnConfirm={onConfirm} />
		});
	}

	Headline = () => {
		return <GridContainer spacing={16} alignItems="center">
			<GridItem>
				<GenericTitle text="Available Services" />
			</GridItem>
			<GridItem>
				<GenericCheckInput
					Name="DontCheckCustomerInformation"
					LabelText="Customer Without Wallet"
					Value={this.state.model.DontCheckCustomerInformation}
					ValueChanged={this.HandleChange}
					Disabled={this.props.ForInsert || this.state.ReadOnly} />
			</GridItem>
		</GridContainer>;
	}

	HideAlert = () => {
		this.setState({ alert: null });
	}

	RefreshToolBar = () => {
		this.setState({ LimitToken: !this.state.LimitToken });
	}

	/**
	 * @param {any[]} data
	 */
	AfterFetchCallback = (data) => {
		this.customerTypes = data.filter(x => x.ParameterCode == "ClientType");
	}

	render() {
		const { classes, ForInsert, ForUpdate, Disabled } = this.props;
		const { accountModel, model, tempModel, ddrModel, vModel, services, alert, isLoading, isErrorDialogOpen, errorMessage, CDICAccounts, DCAccountModel, hasChanged, ReadOnly, isDDRInquiryOpen, rowIndex } = this.state;
		const { wCheckModel } = this.state;

		var IsIncludeEFT = model.Programs.includes(ProgramCodes.Bank_Programs_EFT);
		var IsIncludeET = model.Programs.includes(ProgramCodes.Bank_Programs_e_Transfer);
		var IsIncludeCDIC = model.Programs.includes(ProgramCodes.Bank_Programs_CDIC);
		var IsIncludePrepaid = model.Programs.includes("WALLET");

		return (
			<>

				<LoadingComponent Show={isLoading} />

				{alert}

				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.HandleGetList, Disabled: ForInsert || ForUpdate || ReadOnly || Disabled },
					{ Code: "Submit", OnClick: this.HandleSubmit, Disabled: ReadOnly || Disabled || rowIndex > -1, ModelFunction: () => model, FillDataFromModel: model => this.setState({ model }), ValidationFunction: this.Validate },
					{ Code: "Update", OnClick: this.HandleSubmit, Disabled: ReadOnly || Disabled || rowIndex === -1, ModelFunction: () => model, FillDataFromModel: model => this.setState({ model }), ValidationFunction: this.Validate },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: ForInsert || ForUpdate || ReadOnly || Disabled },
					{ Code: "Delete", OnClick: this.HandleDelete, Disabled: ForInsert || ReadOnly || Disabled || rowIndex === -1 }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} IsPopUp={ForInsert || ForUpdate} />

				<GridContainer>
					<GenericDialog open={isErrorDialogOpen} maxWidth="md" fullWidth>
						<DialogTitle>
							<GenericLabel Text="Error" Bold={true} />
						</DialogTitle>
						<DialogContent>
							<div style={{ textAlign: "center" }}>
								<GenericLabel Text={errorMessage} />
							</div>
						</DialogContent>
						<GenericDialogActions>
							<Button size="sm" onClick={() => RouteHelper.Push(this.props.history, "/OnlineSupport")}>Online Support</Button>
							<Button size="sm" onClick={() => this.setState({ isErrorDialogOpen: false })}>OK</Button>
						</GenericDialogActions>
					</GenericDialog>

					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel IsActive={true}>
									<GridContainer>
										<GridItem xs={3}>
											<GenericSelectInput
												key={"Customer_" + hasChanged}
												Name="Id"
												LabelText="Customer Name"
												Method="POST"
												Url="/bankapi/v1.0/ClientCustomer/SearchNameValue"
												Parameter={this.parameterCustomerName}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Name"
												Value={model.Id}
												ValueChanged={this.HandleChangeCustomer}
												Sorted={this.sortName}
												Disabled={ForInsert || ForUpdate || tempModel.isGenerateNew} />
										</GridItem>
										<GridItem xs={3}>
											<GenericNumberInput
												Name="CustomerNumber"
												LabelText="DCBANK Customer Number"
												LabelMd={6}
												Value={model.CustomerNumber}
												ValueChanged={this.HandleChange}
												Disabled={tempModel.isGenerateNew}
												EndIconName="search"
												MaxLength={11}
												NoFormatting={true} />
										</GridItem>
										<GridItem xs={3}>
											<GenericTextInput
												inputProps={this.maxLengthCustomerNumber}
												key={this.state.uniq}
												Name="ClientCustomerNumber"
												LabelMd={6}
												LabelText="Client Customer Number"
												Value={model.ClientCustomerNumber == null || undefined ? "" : model.ClientCustomerNumber}
												ValueChanged={this.HandleChange}
												Disabled={ReadOnly || model.Id > 0 || model.IsAutoGenerateCustomerNumber || !tempModel.isGenerateNew}
												Format="#######"
												BeginText={model.IsAutoGenerateCustomerNumber ? "" : (model.Id == 0 ? this.state.uniq : "")}
												IsInvalid={vModel.ClientCustomerNumber} />
										</GridItem>
										<GridItem xs={3}>
											<GridContainer>
												<GridItem xs={2} style={{ marginTop: 4 }}>
													<Tooltip title={this.GetTooltip()}
														classes={{ tooltip: classes.tooltip }}
														TransitionComponent={Zoom}>
														<GenericIcon>help</GenericIcon>
													</Tooltip>
												</GridItem>
												<GridItem xs={5} style={{ opacity: model.Id && model.Id > 0 ? 0.4 : 1 }}>
													<GenericCheckInput
														Name="IsAutoGenerateCustomerNumber"
														LabelText="Auto Generate"
														LabelMd={6}
														Value={model.IsAutoGenerateCustomerNumber}
														ValueChanged={this.HandleChange}
														Disabled={ReadOnly || model.Id > 0} />
												</GridItem>
												<GridItem xs={5}>
													<GenericCheckInput
														Name="isGenerateNew"
														LabelText="Add New Customer"
														LabelMd={6}
														Value={tempModel.isGenerateNew}
														ValueChanged={this.HandleChangeTempModel}
														Disabled={ForInsert || ForUpdate || ReadOnly} />
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanelCustomHeader Header={this.Headline}>
									<GridContainer>
										<>
											{services.length === 0 &&
												<GridItem style={{ marginLeft: 4, marginTop: 4 }}>
													<GenericLabel Bold={true} FontSize="13px" TextColor="red" Text="No Available Service" />
												</GridItem>
											}
											{services.map(d => (
												<GridItem xs={2} key={String(d.ProgramCode + d.Id)} style={{ opacity: this.props.Programs ? this.props.Programs.includes(d.ProgramCode) ? 1 : 0.4 : 1 }} >
													<GenericCheckInput
														key={d.Id}
														Name={d.ProgramCode}
														LabelText={d.Description}
														Value={model.Programs.find(x => x === d.ProgramCode) !== undefined}
														ValueChanged={this.HandleToggle}
														Disabled={Disabled || ReadOnly || model.DontCheckCustomerInformation || this.props.Programs || d.ProgramCode === ProgramCodes.Lender} />
												</GridItem>
											))}
										</>
									</GridContainer>
								</GenericExpansionPanelCustomHeader>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<CustomerInformationComponent
									model={model}
									tempModel={tempModel}
									vModel={vModel}
									wCheckModel={wCheckModel}
									handleChange={this.HandleChange}
									handleNext={this.HandleNext}
									Title="Information"
									ReadOnly={ReadOnly}
									ForInsert={ForInsert}
									Disabled={this.props.ForInsert || this.state.ReadOnly}
									AfterFetchCallback={this.AfterFetchCallback} />
							</CardBody>
						</Card>
						{/* {isDDRInquiryOpen &&
							<Card className="no-radius">
								<CardBody>
									<GenericExpansionPanel
										Title="DDR Information" >
										<GridContainer>
											<GridItem xs={1}>
												<Button color="info" fullWidth disabled={Disabled} onClick={this.HandleDDRInquiry}>DDR Inquiry</Button>
											</GridItem>
											<GridItem xs={2} style={{ marginTop: 16, marginLeft: 8 }}>
												<GenericLabel FontSize="14px" Text={DDRInquiryResult} Bold={true} />
											</GridItem>
										</GridContainer>
									</GenericExpansionPanel>
								</CardBody>
							</Card>
						} */}
						{isDDRInquiryOpen && IsIncludePrepaid &&
							<Card className="no-radius">
								<CardBody>
									<GridContainer>
										<GridItem xs={12}>
											<GenericTitle text="Direct Deposit Registration" />
										</GridItem>
										<GridItem xs={4}>
											<GenericEmailInput
												Name="InteracEmail"
												LabelText="Interac E-Mail"
												Value={ddrModel.InteracEmail}
												ValueChanged={this.HandleChangeDDRRegistration}
												Disabled={ForInsert}
												inputProps={this.maxLength64} />
										</GridItem>
										<GridItem xs={4}>
											<GenericSelectInput
												Name="IsDdrActiveId"
												LabelText="Would you like to register for DDR?"
												LabelMd={6}
												Method="POST"
												Url="/coreapi/v1.0/Parameter/Search"
												Parameter={this.parameterYesNo}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ParameterDesc"
												Value={ddrModel.IsDdrActiveId}
												ValueChanged={this.HandleChangeDDRRegistration}
												Disabled={ForInsert} />
										</GridItem>
										{ddrModel.IsDdrActive &&
											<>
												<GridItem xs={4}>
													<GenericSelectInput
														key={model.Id}
														Name="WalletAccountId"
														LabelText="Wallet Wallet"
														Method="POST"
														Url="/bankapi/v1.0/ClientCustomer/GetAllWalletAccounts"
														Parameter={this.GetWalletAccountParameter()}
														DataRoot="Item"
														KeyValueMember="Id"
														RenderItem={this.RenderItemAccount}
														Value={ddrModel.WalletAccountId}
														ValueChanged={this.HandleChangeDDRRegistration}
														Disabled={ForInsert} />
												</GridItem>
											</>
										}
										<GridItem xs={12}>
											<GridContainer justify="flex-end">
												<GridItem>
													<Button size="sm" color="black" onClick={this.HandleDDR}>Submit</Button>
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</CardBody>
							</Card>
						}
						{(IsIncludeEFT || IsIncludeET) &&
							<Card className="no-radius">
								<CardBody>
									<BeneficiaryComponent
										key={"Beneficiary_" + this.state.hasChangedAccount}
										list={model.Accounts}
										model={accountModel}
										handleChange={this.HandleChangeAccount}
										handleClear={this.ClearAccount}
										handleAddAccount={this.AddAccount}
										handleDeleteAccount={this.DeleteAccount}
										title="External Payment Wallet Definition"
										labelText="External Wallet"
										selectedRowChange={this.SelectedRowChangeAccount}
										showMessageNode={this.ShowMessageNode}
										ReadOnly={ReadOnly}
										Required={vModel.ExternalAccount} />
								</CardBody>
							</Card>
						}
						{IsIncludeCDIC && tempModel.isPersonal === false &&
							<Card className="no-radius">
								<CardBody>
									<BenificialOwner
										key={"BenificialOwner_" + model.Id}
										ClientCustomerId={model.Id}
										ReadOnly={ReadOnly || model.Id === 0}
										showMessage={this.ShowMessage}
										showMessageNode={this.ShowMessageNode}
										showConfirmMessage={this.ShowConfirmMessage} />
								</CardBody>
							</Card>
						}
						{IsIncludeCDIC &&
							<>
								<Card className="no-radius">
									<CardBody>
										<AssignDDTAccountComponent
											key={"AssignDDTAccountComponent_" + model.Id}
											ClientCustomerId={model.Id}
											ReadOnly={ReadOnly || model.Id === 0}
											showMessage={this.ShowMessage}
											showMessageNode={this.ShowMessageNode}
											showConfirmMessage={this.ShowConfirmMessage} />
									</CardBody>
								</Card>
								<Card className="no-radius">
									<CardBody>
										<GenericExpansionPanel
											Title={`${window.Title} Wallet List`}
											IsActive={false}>
											<GridContainer>
												<GridItem xs={3}>
													<GenericNumberInput
														NoFormatting={true}
														Name="AccountNumber"
														LabelText="Wallet Number"
														Value={DCAccountModel.AccountNumber}
														ValueChanged={this.HandleChangeDCAccount}
														MaxLength={12}
														Disabled={ReadOnly} />
												</GridItem>
												<GridItem xs={12}>
													<br />
													<GenericGrid
														Data={CDICAccounts}
														Columns={GetCDICAccountColumns(this.props, this.state)}
														HideButton={true} />
												</GridItem>
											</GridContainer>
										</GenericExpansionPanel>
									</CardBody>
								</Card>
							</>
						}
						<Card className="no-radius" style={{ display: ForInsert ? "none" : "inherit" }}>
							<CardBody>
								<GenericExpansionPanel
									Title="Client Customer List">
									<GridContainer>
										<GridItem xs={12}>
											<GenericGrid
												key={"Customer_" + hasChanged}
												Data={this.state.ClientCustomerList}
												Columns={GetColumns(this.props, this.state)}
												RowSelected={this.SelectedRowChange}
												SelectedIndex={this.state.rowIndex}
												HideButton={true}
												IsSorted={false} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

			</>
		);
	}
}

CustomerDefinition.propTypes = {
	tabList: PropTypes.array,
	classes: PropTypes.object.isRequired,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Model: PropTypes.object,
	Programs: PropTypes.array,
	CustomerSaved: PropTypes.func,
	ForInsert: PropTypes.bool,
	ForUpdate: PropTypes.bool,
	Disabled: PropTypes.bool
};

export default withArtifex(CustomerDefinition, customerDefinitionStyle);