import buttonStyle from "./buttonStyle.jsx";

const genericAlertStyle = {
	cardTitle: {
		marginTop: "0",
		marginBottom: "3px",
		color: "#3C4858",
		fontSize: "18px"
	},
	center: {
		textAlign: "center"
	},
	right: {
		textAlign: "right"
	},
	left: {
		textAlign: "left"
	},
	...buttonStyle,
	main: {
		display: "block",
		border: "solid 2px #f57f29"
	},
	content: {
		fontSize: "20px",
		lineHeight: "22px"
	},
	dialogTitle: {
		padding: "5px 5px 5px"
	},
	dialogTitleText: {
		maxWidth: "89%"
	},
	dialogContent: {
		fontSize: 14,
		padding: "0 15px 5px"
	},
	alertButton:{
		padding: "12px 30px !important", 
		backgroundColor: "gray !important" 
	}
};

export default genericAlertStyle;