import { DialogContent, Slide, withStyles, CardMedia } from "@material-ui/core";
import { blackColor } from "assets/jss/material-dashboard-pro-react.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import RouteHelper from "core/RouteHelper";
import PropTypes from "prop-types";
import React from "react";
import Tooltip from "react-tooltip-lite";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDateInput, GenericDialog, GenericDialogActions, GenericEmailInput, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTextInput, GenericLabel } from "views/Components/Generic";
import ParameterComponent from "views/Components/ParameterComponent";
import { CanadaPostIdVerificationStatus, GridColumnType, IdentityType } from "views/Constants/Constant";
import { MaxLength128 } from "views/Constants/Constant.MaxLength";
import { DialogTitle } from "@material-ui/core";
import Select from "react-select";
import { Proxy } from "core";
import LoadingComponent from "views/Components/LoadingComponent";
import { DetailIcon } from "core/Icons";
import GridButton from "views/Components/GridButton";
import externalContainerStyle from "assets/jss/material-dashboard-pro-react/layouts/externalContainerStyle.jsx";


function Transition(props) {
	return <Slide direction="up" {...props} />;
}

class TransUnionIdVerification extends React.Component {

	constructor(props) {
		super(props);

		this.columnsRecordKeeping = [
			{
				Header: "Source Date",
				accessor: "SourceDate"
			},
			{
				Header: "Source Name",
				accessor: "SourceName"
			},
			{
				Header: "Source Match",
				accessor: "SourceMatch"
			},
			{
				Header: "Date Verified",
				accessor: "DateVerified"
			},
			{
				Header: "Type",
				accessor: "Type"
			},
			{
				Header: "Wallet Number",
				accessor: "AccountNumber"
			}
		];

		this.columns = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip="Detail"
							Icon={DetailIcon}
							Disabled={row.original.IsHaveResponse == false}
							OnClick={() => this.showDetail(row.original)}
						/>
					</div>
				),
				sortable: false,
				filterable: false,
				width: 100
			},
			{
				Header: "Insert Date Time",
				accessor: "InsertDateTime"
			},
			{
				Header: "First Name",
				accessor: "FirstName"
			},
			{
				Header: "Middle Name",
				accessor: "MiddleName"
			},
			{
				Header: "Last Name",
				accessor: "LastName"
			},
			{
				Header: "Email Address",
				accessor: "EmailAddress"
			},
			{
				Header: "Date Of Birth",
				accessor: "DateOfBirth"
			},
			{
				Header: "Address Line",
				accessor: "Street"
			},
			{
				Header: "City",
				accessor: "City"
			},
			{
				Header: "Province",
				accessor: "Province"
			},
			{
				Header: "Postal Code",
				accessor: "PostalCode"
			},
			{
				Header: "Verification Status",
				accessor: "VerificationStatus"
			},
			{
				Header: "Bureau",
				accessor: "Bureau"
			},
			{
				Header: "Bureau Description",
				accessor: "BureauDescription"
			}
		];
		this.getappCenterTitle = "Empty";
		this.getappLeftTitle = "Empty";
		this.zIndex10 = { zIndex: 10 };
		this.isClient = ClientHelper.IsClient();
		this.parameterUniqueClientId = {
			UniqueClientId: this.isClient ? ClientHelper.GetClientId() : undefined,
		};

		if (!this.isClient) {
			this.columns.splice(1, 0,
				{
					Header: "Client",
					accessor: "ClientName"
				}
			);
		}

		this.state = {
			model: {},
			data: [],
			recordKeeping: [],
			isSendEmailDialogOpen: false,
			activeClient: [],
			responseDetail: {},
			isDetailDialogOpen: false
		};
		this.cpverTable = React.createRef();
	}

	componentDidMount = () => {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;
		if (setAppCenterTitle) {
			setAppCenterTitle("AML & KYC");
			this.getappCenterTitle = "AML & KYC";
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("TransUnion Id Verification");
			this.getappLeftTitle = "TransUnion Id Verification";
		}
	}


	ValueChanged = (name, value, data) => {
		this.setState(state => {
			var model = state.model;
			model[name] = value;

			if (name == "EmailAddress") {
				model["EmailAddressIsValid"] = data.IsValid;
			}
			else if (this.isClient && name == "UniqueClientId") {
				state.activeClient = [data];
			}
			return { model };
		});
	}

	RowSelected = (index) => {
		const { data } = this.state;
		var temp = data[index];

		this.setState({ model: temp, selected: index });
	}

	Clear = () => {
		this.setState({ model: {}, data: [], selected: null });
	}

	Validate = () => {
		var { model } = this.state;
		const { showValidationErrors } = this.props;
		var errors = [];

		if (!model.FirstName) {
			errors.push("First name cannot be null");
		}
		if (!model.LastName) {
			errors.push("Last name cannot be null");
		}

		var age = DateHelper.GetDate().diff(model.DateOfBirth, "years");

		if (!model.DateOfBirth) {
			errors.push("Date of birth cannot be null");
		}
		if (!model.Street) {
			errors.push("Address line cannot be null");
		}
		if (!model.Province) {
			errors.push("Province cannot be null");
		}
		if (!model.City) {
			errors.push("City cannot be null");
		}
		if (!model.PostalCode) {
			errors.push("Postal code cannot be null");
		}

		model["IsFirstName"] = !model.FirstName;
		model["IsMiddleName"] = !model.MiddleName;
		model["Civic"] = !model.Civic;
		model["Phone"] = !model.Phone;
		model["IsLastName"] = !model.LastName;
		model["IsDateOfBirth"] = !model.DateOfBirth;
		model["IsStreet"] = !model.Street;
		model["IsProvince"] = !model.Province;
		model["IsCity"] = !model.City;
		model["IsPostalCode"] = !model.PostalCode;

		if (errors.length > 0) {
			showValidationErrors(errors);

			return false;
		}

		return true;
	}
	RenderItemUniqueClient = (x) => {
		var result = `${x.UniqueClientId} - ${x.Name}`;

		return result;
	}


	showLoading = () => { this.setState({ isLoading: true }); }
	hideLoading = () => { this.setState({ isLoading: false }); }

	showDetail = async (result) => {
		this.setState({ responseDetail: result || [], isDetailDialogOpen: true });
		console.log(result);
	}

	DetailDialogClose = () => {
		this.setState({ isDetailDialogOpen: false });
	}

	HandleSearch = () => {
		const model = { ...this.state.model };
		this.setState({ isLoading: true });
		Proxy.POST(
			"/amlkycapi/v1.0/TransUnion/SearchTransUnionDetail",
			model,
			(responseData) => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ data: responseData.Item, recordKeeping: responseData.Item.RecordKeepings });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	render() {
		const { model, list, selected, isSendEmailDialogOpen, activeClient, isLoading, isDetailDialogOpen, responseDetail, data, recordKeeping } = this.state;
		const { MenuCode, Disabled, ExecuteApiPost, ExecuteApiFileDownloadWithGenericResponse, showConfirmMessageInDelete, classes } = this.props;

		return (
			<div>
				<LoadingComponent Show={isLoading} />
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.HandleSearch
						},
						{
							Code: "Submit",
							Disabled: Disabled || selected != null,
							OnClick: () => ExecuteApiPost("/amlkycapi/v1.0/TransUnion/TransUnionIdVertification", model, null, null, this.Validate, [this.Clear, this.HandleSearch], true)
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />

				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="TransUnion Id Verification">
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												IsStatic={activeClient?.length}
												StaticData={activeClient?.length && activeClient}
												Name="UniqueClientId"
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={this.parameterUniqueClientId}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={this.RenderItemUniqueClient}
												Value={activeClient?.length > 0 ? activeClient[0].Id : model.UniqueClientId}
												ValueChanged={this.ValueChanged}
												Disabled={this.isClient || model.Id}
												DefaultIndex={this.isClient ? 0 : -1} />
											<GenericTextInput
												Required
												IsInvalid={model.IsFirstName}
												Disabled={selected != null}
												Name="FirstName"
												LabelText="First Name"
												Value={model.FirstName}
												ValueChanged={this.ValueChanged} />
											<GenericTextInput
												IsInvalid={model.IsMiddleName}
												Disabled={selected != null}
												Name="MiddleName"
												LabelText="Middle Name"
												Value={model.MiddleName}
												ValueChanged={this.ValueChanged} />
											<GenericTextInput
												Required
												IsInvalid={model.IsLastName}
												Disabled={selected != null}
												Name="LastName"
												LabelText="Last Name"
												Value={model.LastName}
												ValueChanged={this.ValueChanged} />
											<GenericDateInput
												Required
												Utc
												Name="DateOfBirth"
												LabelText="Date Of Birth"
												Value={model.DateOfBirth == null || undefined ? "" : model.DateOfBirth}
												ValueChanged={this.ValueChanged}
												MaxDate={DateHelper.GetDate()}
												IncludeTime={false} />
										</GridItem>
										<GridItem xs={4}>
											<GenericEmailInput
												Name="EmailAddress"
												LabelText="E-Mail Address"
												inputProps={MaxLength128}
												Value={model.EmailAddress}
												ValueChanged={this.ValueChanged} />
											<GenericTextInput
												Required
												IsInvalid={model.IsPostalCode}
												Disabled={selected != null}
												Name="PostalCode"
												Id="postcode"
												LabelText="Postal Code"
												Value={model.PostalCode}
												ValueChanged={this.ValueChanged} />
											<GenericTextInput
												Required
												IsInvalid={model.IsStreet}
												Disabled={selected != null}
												Name="Street"
												Id="street-address"
												LabelText="Address Line"
												Value={model.Street}
												ValueChanged={this.ValueChanged} />
											<Tooltip content="Please Use the 2 Letter abbreviations" styles={this.zIndex10} background={blackColor} color="white">
												<GenericTextInput
													Required
													IsInvalid={model.IsProvince}
													Disabled={selected != null}
													Name="Province"
													LabelText="Province"
													Value={model.Province}
													ValueChanged={this.ValueChanged} />
											</Tooltip>
											<GenericTextInput
												Required
												IsInvalid={model.IsCity}
												Disabled={selected != null}
												Name="City"
												LabelText="City"
												Value={model.City}
												ValueChanged={this.ValueChanged} />
										</GridItem>

									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={data}
													Columns={this.columns}
													SelectedIndex={selected}
													RowSelected={this.RowSelected}
													IsSorted={true}
													PageSize={10}
													ShowPagination={true}
													ref={this.cpverTable} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

				<GenericDialog maxWidth={"md"} open={isDetailDialogOpen} onBackdropClick={this.DetailDialogClose} TransitionComponent={Transition}>
					<DialogTitle>
						<GenericLabel
							Bold
							Text="TransUnion Id Verification Detail"
							FontSize="16px"
							TextColor="black" />
					</DialogTitle>
					<DialogContent>
						<GridContainer spacing={16}>
							<GridItem xs={6}>								
								<GenericTextInput
									Name="FirstName"
									LabelText="First Name"
									Value={responseDetail?.FirstName}
									Disabled={true}
								/>
								<GenericTextInput
									Name="MiddleName"
									LabelText="Middle Name"
									Value={responseDetail?.MiddleName}
									Disabled={true}
								/>
								<GenericTextInput
									Name="LastName"
									LabelText="Last Name"
									Value={responseDetail?.LastName}
									Disabled={true}
								/>
								<GenericTextInput
									Name="BureauHit"
									LabelText="BureauHit"
									Value={responseDetail?.BureauHit}
									Disabled={true}
								/>
								<GenericTextInput
									Name="FraudFlag"
									LabelText="FraudFlag"
									Value={responseDetail?.FraudFlag}
									Disabled={true}
								/>
								<GenericTextInput
									Name="Civic"
									LabelText="Civic"
									Value={responseDetail?.Civic}
									Disabled={true}
								/>
								<GenericTextInput
									Name="Street"
									LabelText="Address Line"
									Value={responseDetail?.Street}
									Disabled={true}
								/>
								<GenericTextInput
									Name="City"
									LabelText="City"
									Value={responseDetail?.City}
									Disabled={true}
								/>
								<GenericTextInput
									Name="Province"
									LabelText="Province"
									Value={responseDetail?.Province}
									Disabled={true}
								/>
								<GenericTextInput
									Name="PostalCode"
									LabelText="Postal Code"
									Value={responseDetail?.PostalCode}
									Disabled={true}
								/>
								<GenericDateInput
									Required
									Utc
									Name="DateOfBirth"
									LabelText="Date Of Birth"
									Value={responseDetail?.DateOfBirth}
									MaxDate={DateHelper.GetDate()}
									Disabled={true}
									IncludeTime={false} />
									<GenericTextInput
									Name="Unique Id"
									LabelText="UniqueID"
									Value={responseDetail?.UniqueID}
									Disabled={true}
								/>
								<GenericTextInput
									Name="Bureau"
									LabelText="Bureau"
									Value={responseDetail?.Bureau}
									Disabled={true}
								/>
								<GenericTextInput
									Name="Dirserv"
									LabelText="Dirserv"
									Value={responseDetail?.Dirserv}
									Disabled={true}
								/>
							</GridItem>
							<GridItem xs={6}>
								<GenericTextInput
									Name="FirstNameSpecified"
									LabelText="First Name Specified"
									Value={responseDetail?.FirstNameSpecified}
									Disabled={true}
								/>
								<GenericTextInput
									Name="MiddleNameSpecified"
									LabelText="Middle Name Specified"
									Value={responseDetail?.MiddleNameSpecified}
									Disabled={true}
								/>
								<GenericTextInput
									Name="LastNameSpecified"
									LabelText="Last Name Specified"
									Value={responseDetail?.LastNameSpecified}
									Disabled={true}
								/>
								<GenericTextInput
									Name="BureauHit Specified"
									LabelText="BureauHit"
									Value={responseDetail?.BureauHitSpecified}
									Disabled={true}
								/>
								<GenericTextInput
									Name="FraudFlag Specified"
									LabelText="FraudFlagSpecified"
									Value={responseDetail?.FraudFlagSpecified}
									Disabled={true}
								/>
								<GenericTextInput
									Name="Civic Specified"
									LabelText="Civic"
									Value={responseDetail?.CivicSpecified}
									Disabled={true}
								/>
								<GenericTextInput
									Name="Street Specified"
									LabelText="Address Line"
									Value={responseDetail?.StreetSpecified}
									Disabled={true}
								/>
								<GenericTextInput
									Name="CitySpecified"
									LabelText="City Specified"
									Value={responseDetail?.CitySpecified}
									Disabled={true}
								/>
								<GenericTextInput
									Name="ProvSpecified"
									LabelText="ProvinceSpecified"
									Value={responseDetail?.ProvSpecified}
									Disabled={true}
								/>
								<GenericTextInput
									Name="PostalSpecified"
									LabelText="PostalCode Specified"
									Value={responseDetail?.PostalSpecified}
									Disabled={true}
								/>
								<GenericTextInput
									Name="DOBSpecified"
									LabelText="DOB Specified"
									Value={responseDetail?.DOBSpecified}
									Disabled={true}
								/>
							</GridItem>
						</GridContainer>
						<GridContainer>
							<GridItem xs={12}>
								<GridContainer spacing={16}>
									<GridItem xs={12}>
										<GenericGrid
											Data={responseDetail.RecordKeepings}
											Columns={this.columnsRecordKeeping}
											IsSorted={true}
											PageSize={10}
											ShowPagination={true}
											ref={this.cpverTable} />
									</GridItem>
								</GridContainer>
							</GridItem>
						</GridContainer>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.DetailDialogClose}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>
			</div >
		);
	}
}

TransUnionIdVerification.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	ExecuteApiFileDownloadWithGenericResponse: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default withStyles(externalContainerStyle)(TransUnionIdVerification);