import { InputAdornment, withStyles } from "@material-ui/core";
import genericTextInputStyle from "assets/jss/generic/genericTextInputStyle.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import GenericIcon from "views/Components/Generic/GenericIcon.jsx";
import StringHelper from "core/StringHelper";

class GenericEmailInput extends PureComponent {

	constructor(props) {
		super(props);

		this.state = {
			emailState: null,
		};
	}

	render() {

		const { LabelText, Name, Value, ValueChanged, Disabled,
			BeginIconName, EndIconName, AutoFocus, KeyPressed, KeyUp,
			KeyDown, Blur, LabelMd, FloatRight, inputProps, Required, IsInvalid } = this.props;

		var haveStartIcon = (BeginIconName || "") != "";
		var haveEndIcon = (EndIconName || "") != "";


		return (
			<CustomInput
				floatRight={FloatRight}
				LabelMd={LabelMd}
				InputStyle={{ marginLeft: "-0.5px" }}
				success={this.state.emailState === "success"}
				error={this.state.emailState === "error"}
				labelText={LabelText}
				Required={Required}
				IsInvalid={IsInvalid}
				formControlProps={{
					fullWidth: true
				}}
				inputProps={{
					...inputProps,
					value: Value != null ? Value : "",
					maxLength: 64,
					onChange: e => {
						const { value } = e.target;
						var isValid = StringHelper.VerifyEmail(value.trim());
						this.setState({ emailState: (value.trim() == "" || isValid) ? "" : "error" });
						if (ValueChanged)
							ValueChanged(Name, value.trim(), { IsValid: isValid });
					},
					disabled: Disabled,
					startAdornment: haveStartIcon ?
						<InputAdornment position="start"><GenericIcon>{BeginIconName}</GenericIcon></InputAdornment>
						: null
					,
					endAdornment: haveEndIcon ?
						<InputAdornment style={{ marginLeft: "-24px" }} position="end"><GenericIcon>{EndIconName}</GenericIcon></InputAdornment>
						: null
					,
					autoFocus: AutoFocus,
					onKeyPress: KeyPressed,
					onKeyUp: KeyUp,
					onKeyDown: KeyDown,
					onBlur: (e) => {
						if (Blur) {
							Blur(e);
						}	
					},
				}}
			/>
		);
	}
}

GenericEmailInput.propTypes = {
	classes: PropTypes.object.isRequired,
	ValueChanged: PropTypes.func,
	Value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	LabelText: PropTypes.string,
	Name: PropTypes.string,
	Disabled: PropTypes.bool,
	BeginIconName: PropTypes.string,
	EndIconName: PropTypes.string,
	AutoFocus: PropTypes.bool,
	KeyPressed: PropTypes.func,
	KeyDown: PropTypes.func,
	KeyUp: PropTypes.func,
	Blur: PropTypes.func,
	LabelMd: PropTypes.number,
	FloatRight: PropTypes.bool,
	inputProps: PropTypes.object,
	Required: PropTypes.bool,
	IsInvalid: PropTypes.bool
};

GenericEmailInput.defaultProps = {
	EndIconName: "email"
};

export default withStyles(genericTextInputStyle)(GenericEmailInput);