import { DialogContent, DialogTitle } from "@material-ui/core";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import AlertHelper from "core/AlertHelper";
import DateHelper from "core/DateHelper";
import PropTypes, { func } from "prop-types";
import React, { Component } from "react";
import ReactTable from "react-table";
import { GenericDateInput, GenericDialog, GenericDialogActions, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import TabsComponent from "views/Components/TabsComponent";
import CDIC from "./ReconciliationTabs/ReconciliationCDICTab";
import DDT from "./ReconciliationTabs/ReconciliationDDTTab";
import EFT from "./ReconciliationTabs/ReconciliationEFTTab";
import ETransfer from "./ReconciliationTabs/ReconciliationeTransferTab";

const styles = ({
	...sweetAlertStyle,
	flex: {
		flex: 1,
	}
});

class Reconciliation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			isLoading: false,
			model: {
				SettlementDate: DateHelper.GetDate()
			},
			EFTSummary: {},
			eTransferSummary: {},
			DDTSummary: {},
			CDICSummary: {},
			EFTGapList: [],
			eTransferGapList: [],
			DDTGapList: [],
			CDICGapList: [],
		};
		this.handleChange = this.handleChange.bind(this);
		this.GetData = this.GetData.bind(this);
		this.errorCallback = this.errorCallback.bind(this);
		this.hideAlert = this.hideAlert.bind(this);
		this.showErrorMessage = this.showErrorMessage.bind(this);
		this.successSummaryCallback = this.successSummaryCallback.bind(this);
	}
	componentDidMount() {
		this.props.setAppLeftTitle("Reconciliation");
		this.props.setAppCenterTitle("CLEARING & SETTLEMENT");
	}
	handleChange(name, newValue) {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}

	handleClickOpen = (event) => {
		var GapList = { ...this.state.GapList };
		if (event == "eft") {
			GapList = this.state.EFTGapList;
		}
		if (event == "eTransfer") {
			GapList = this.state.eTransferGapList;
		}
		if (event == "ddt") {
			GapList = this.state.DDTGapList;
		}
		if (event == "cdic") {
			GapList = this.state.CDICGapList;
		}
		this.setState({ open: true, GapList });
	};

	handleClose = () => {
		this.setState({ open: false });
	};

	GetData() {
		if (this.state.model.SettlementDate == null)
			this.showErrorMessage("Date / To can not be empty !!");
		else {
			this.setState({ isLoading: true });
			Proxy.POST(
				"/bankapi/v1.0/ClearingSettlement/GetReconciliationBySettlementDate",
				this.state.model,
				this.successSummaryCallback,
				this.errorCallback
			);
		}
	}

	successSummaryCallback(responseData) {
		if (!responseData.IsSucceeded) {
			this.showErrorMessage(responseData.ErrorDescription);
			return;
		}
		if (responseData.Item == null) {
			this.setState({
				alert: AlertHelper.CreateAlert("Warning", "There is no item for selected date ?", "warning", this.hideAlert)
			});
		}
		else {
			var EFTSummary = responseData.Item.EFTSummary == null ? "" : responseData.Item.EFTSummary;
			var eTransferSummary = responseData.Item.eTransferSummary == null ? "" : responseData.Item.eTransferSummary;
			var DDTSummary = responseData.Item.DDTSummary == null ? "" : responseData.Item.DDTSummary;
			var CDICSummary = responseData.Item.CDICSummary == null ? "" : responseData.Item.CDICSummary;
			var EFTGapList = responseData.Item.EFTGapList == null ? "" : responseData.Item.EFTGapList;
			var eTransferGapList = responseData.Item.eTransferGapList == null ? "" : responseData.Item.eTransferGapList;
			var DDTGapList = responseData.Item.DDTGapList == null ? "" : responseData.Item.DDTGapList;
			var CDICGapList = responseData.Item.CDICGapList == null ? "" : responseData.Item.CDICGapList;
			this.setState({ EFTSummary, eTransferSummary, DDTSummary, CDICSummary, EFTGapList, eTransferGapList, DDTGapList, CDICGapList, isLoading: false });
		}
	}
	errorCallback(error) {
		this.showErrorMessage("An error occurred during the api visit" + error);
	}
	showErrorMessage(message) {
		this.setState({
			isLoading: false,
			alert: AlertHelper.CreateAlert("Error", message, "error", this.hideAlert)
		});
	}
	hideAlert() {
		this.setState({ alert: null });
	}
	render() {
		const { model, isLoading } = this.state;
		var data = this.state.GapList === undefined || this.state.GapList === null ? [] : this.state.GapList.map(d => {
			return {
				ProgramType: d.ProgramType == null || undefined ? "" : d.ProgramType.ParameterDesc == null || undefined ? "" : d.ProgramType.ParameterDesc || "",
				PayeeName: d.PayeeName == null || undefined ? "" : d.PayeeName || "",
				AccountNumberStr: d.AccountNumberStr == null || undefined ? "" : d.AccountNumberStr || "",
				TransactionStatus: d.TransactionStatus == null || undefined ? "" : d.TransactionStatus.ParameterDesc == null || undefined ? "" : d.TransactionStatus.ParameterDesc || "",
				TransactionType: d.TransactionType == null || undefined ? "" : d.TransactionType.ParameterDesc == null || undefined ? "" : d.TransactionType.ParameterDesc || "",
				TransactionAmount: d.TransactionAmount == null || undefined ? "" : d.TransactionAmount || "",
				TransferMemo: d.TransferMemo == null || undefined ? "" : d.TransferMemo || "",

			};
		});
		return (
			<GridContainer>
				{this.state.alert}

				<LoadingComponent Show={isLoading} />

				<GenericDialog open={this.state.open} maxWidth="md" onBackdropClick={this.handleClose}>
					<DialogTitle>Gap Transaction Details
					</DialogTitle>
					<DialogContent>
						<ReactTable
							data={data}
							filterable={false}
							className="-striped -highlight"
							columns={[
								{
									Header: "Payee Name",
									accessor: "PayeeName"
								},
								{
									Header: "Acc. Number",
									accessor: "AccountNumberStr"
								}, {
									Header: "Prog. Type",
									accessor: "ProgramType"
								},
								{
									Header: "Trans. Type",
									accessor: "TransactionType"
								},
								{
									Header: "Trans. Status",
									accessor: "TransactionStatus"
								},
								{
									Header: "Trans. Amount",
									accessor: "TransactionAmount",
									Cell: row => (
										<div>$ {row.value}</div>
									)
								},
								{
									Header: "Trans. Memo",
									accessor: "TransferMemo"
								}
							]}
							defaultPageSize={7}
							showPaginationTop={false}
							showPaginationBottom={data.length > 5 ? true : false}
							minRows={2}
						/>
					</DialogContent>
					<GenericDialogActions>
						<Button onClick={this.handleClose}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>
				<Card className="no-radius">
					<CardHeader>
						<GridContainer>
							<GridItem xs={12} sm={8} >
								<GenericTitle text={"Summary Information"} />
							</GridItem>
							<GridItem xs={12} sm={4}>
								<GridContainer justify="flex-end">
									<GridItem>
										<Button size="sm" onClick={this.GetData} >Search</Button>
									</GridItem>
								</GridContainer>
							</GridItem>
						</GridContainer>
					</CardHeader>
					<CardBody>
						<GridContainer>
							<GridItem xs={12} sm={2} md={3}>
								<GenericDateInput
									Name="SettlementDate"
									LabelText="Settlement Date"
									Value={model == null || undefined ? "" : model.SettlementDate ? DateHelper.ToDateInputValue(model.SettlementDate) : ""}
									ValueChanged={this.handleChange}

									IncludeTime={false} />
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
				<Card className="no-radius">
					<CardBody>
						<GridItem md={12} xs={12}>
							<GridItem>
								<TabsComponent
									tabList={[
										{
											tabButton: "EFT",
											tabContent: (
												<EFT EFTSummary={this.state.EFTSummary} openDialog={this.handleClickOpen} />
											)
										},
										{
											tabButton: "eTransfer",
											tabContent: (
												<ETransfer eTransferSummary={this.state.eTransferSummary} openDialog={this.handleClickOpen} />
											)
										},
										{
											tabButton: "DDT",
											tabContent: (
												<DDT DDTSummary={this.state.DDTSummary} openDialog={this.handleClickOpen} />
											)
										},
										{
											tabButton: "DDT - CDIC",
											tabContent: (
												<CDIC CDICSummary={this.state.CDICSummary} openDialog={this.handleClickOpen} />
											)
										}
									]}
								/>
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>
			</GridContainer>
		);
	}
}

Reconciliation.propTypes = {
	classes: PropTypes.object,
	handleOperationType: PropTypes.func,
	SelectedRowChange: PropTypes.func,
	showQuestionMessage: func,
	setAppLeftTitle: func,
	setAppCenterTitle: func
};

export default withArtifex(Reconciliation, styles);