import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

/**
 * Exports file as CSV by parameter
 * @param {Array<any>} data 
 * @param {string} fileName 
 */
function ExportToCsv(data, fileName) {
	const fileType = "text/csv;charset=UTF-8";
	const fileExtension = ".csv";

	const ws = XLSX.utils.json_to_sheet(data);
	const csvString = XLSX.utils.sheet_to_csv(ws);
	const newData = new Blob([csvString], { type: fileType });
	FileSaver.saveAs(newData, fileName + fileExtension);
}

/**
 * Exports file as Xlsx by parameter
 * @param {Array<any>} data 
 * @param {string} fileName 
 */
function ExportToExcel(data, fileName) {
	const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";

	const ws = XLSX.utils.json_to_sheet(data);
	const wb = { Sheets: { "data": ws }, SheetNames: ["data"] };
	const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
	const newData = new Blob([excelBuffer], { type: fileType });
	FileSaver.saveAs(newData, fileName + fileExtension);
}

/**
 * Exports file as PDF by parameter
 * @param {Array<any>} data 
 * @param {string} fileName 
 */
function ExportToPdf(data, fileName) {
	const fileType = "application/pdf;charset=UTF-8";
	const fileExtension = ".pdf";

	pdfMake.vfs = pdfFonts.pdfMake.vfs;

	var header = [];

	if (data != null && data.length > 0) {
		Object.keys(data[0]).forEach(key => {
			header.push({ text: key, style: "tableHeader" });
		});
	}

	const docDefinition = {
		content: [
			{
				style: "tableExample",
				table: {
					widths: Array(header.length).fill(`${100 / (header.length === 0 ? 1 : header.length)}%`),
					body: buildTableBody(data, header)
				},
				layout: {
					fillColor: function (rowIndex, node, columnIndex) {
						return (rowIndex % 2 === 0) ? "#CCCCCC" : null;
					}
				}
			}
		],
		styles: {
			tableExample: {
				fontSize: 8
			},
			tableHeader: {
				bold: true,
				fontSize: 8,
				color: "black"
			}
		}
	};

	var pdfDocGenerator = pdfMake.createPdf(docDefinition);
	pdfDocGenerator.getBlob((blob) => {
		const newData = new Blob([blob], { type: fileType });
		FileSaver.saveAs(newData, fileName + fileExtension);
	});
}

function buildTableBody(data, columns) {
	var body = [];

	body.push(columns);

	data.forEach(function (row) {
		var dataRow = [];

		columns.forEach(function (column) {
			dataRow.push(row[column.text]);
		});

		body.push(dataRow);
	});

	return body;
}

export { ExportToCsv, ExportToExcel, ExportToPdf };
