import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import { GenericAlert } from "views/Components/Generic";
import TabsComponent from "views/Components/TabsComponent";
import { Proxy } from "core";

//Steps
import PersonalAccount from "./PersonalAccount";
import CorporateAccount from "./CorporateAccount";

import ToolBar from "views/Components/DynamicToolbar";


class Profile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			customerAccountProfile: {},
			personalModel: {},
			corporateModel: {},
			IsCustomer: 0
		};
		this.handleCustomerBankAccount = this.handleCustomerBankAccount.bind(this);
		this.GetCustomerBankAccounts = this.GetCustomerBankAccounts.bind(this);

		this.GetProfileInfo = this.GetProfileInfo.bind(this);
		this.handleCustomerProfileInfo = this.handleCustomerProfileInfo.bind(this);

		this.ShowMessage = this.ShowMessage.bind(this);
		this.hideAlert = this.hideAlert.bind(this);
		this.errorDefinitionCallback = this.errorDefinitionCallback.bind(this);
	}

	componentDidMount() {
		this.GetCustomerBankAccounts();
		this.GetProfileInfo();
	}
	GetCustomerBankAccounts() {
		//GetCustomerBankAccountsOp class
		Proxy.POST(
			"/bankapi/v1.0/Account/CustomerAccountProfile",
			{},
			this.handleCustomerBankAccount,
			this.errorDefinitionCallback
		);
	}
	GetProfileInfo() {
		//GetCustomerBankAccountsOp class
		Proxy.POST(
			"/bankapi/v1.0/BankCustomer/GetProfileInfo",
			{},
			this.handleCustomerProfileInfo,
			this.errorDefinitionCallback
		);
	}
	handleCustomerBankAccount(responseData) {

		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error !", responseData.ErrorDescription);
			return;
		} else {
			this.setState({
				customerAccountProfile: responseData.Item || {}
			});
		}
	}
	handleCustomerProfileInfo(responseData) {
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error !", responseData.ErrorDescription);
			return;
		} else {
			if (responseData.Item != null) {
				if (responseData.Item.IsCustomer) {
					this.setState({
						personalModel: responseData.Item.Customer || {},
						IsCustomer: 1
					});
				} else {
					this.setState({
						corporateModel: responseData.Item.Corporate || {},
						IsCustomer: 2
					});
				}
			}

		}
	}
	errorDefinitionCallback(error) {
		this.ShowMessage("error", "Error !", "An error occurred while requesting data" + error);
	}
	ShowMessage(type, title, message) {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
	}
	hideAlert() {
		this.setState({
			alert: null
		});
	}
	render() {
		const { customerAccountProfile, personalModel, corporateModel } = this.state;
		return (
			<GridContainer spacing={16}>
				{this.state.alert}
				<GridItem md={12} xs={12}>
					<ToolBar
						ItemList={[
							{ Label: "EFT Wallet", Value: customerAccountProfile.TotalEftAccountBalance },
							{ Label: "e Transfer Wallet", Value: customerAccountProfile.TotalEtransferAccountBalance },
							{ Label: "Total Balance", Value: customerAccountProfile.TotalAccountBalance },
							{ Label: "Pending Transactions", Value: customerAccountProfile.PendingTransactions + " " }
						]}
					/>
					<br />
					<TabsComponent
						tabList={[
							{
								tabButton: "Individual Wallet",
								tabContent: (
									<PersonalAccount
										setAppCenterTitle={this.props.setAppCenterTitle}
										setAppLeftTitle={this.props.setAppLeftTitle}
										model={personalModel}
									/>
								),
								Hidden: this.state.IsCustomer == 2 ? true : false
							},
							{
								tabButton: "Corporate Wallet",
								tabContent: (
									<CorporateAccount
										setAppCenterTitle={this.props.setAppCenterTitle}
										setAppLeftTitle={this.props.setAppLeftTitle}
										model={corporateModel}
									/>
								),
								Hidden: this.state.IsCustomer == 1 ? true : false
							}
						]}
					/>

				</GridItem>
			</GridContainer>
		);
	}
}

Profile.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func
};

export default Profile;