import { Dialog, DialogContent } from "@material-ui/core";
import style from "assets/jss/material-dashboard-pro-react/views/buttonToolbarStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericCircularProgress, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTitle } from "views/Components/Generic";
import ParameterComponent from "views/Components/ParameterComponent";

const initialState = {
	vModel: {},
	model: {},
	isBrowse: false,
	parameterDatalist: [],
	paramDetailList: [],
	isLoading: false,
	rowIndex: -1,
	alert: null
};

const url = "/lmsapi/v1.0/AutomatedCollectionParameter/";

class AutomatedCollectionParameter extends Component {

	constructor(props) {
		super(props);
		this.state = { ...initialState };

		this.sortedParameterDesc = { Member: "ParameterDesc" };

		this.LoanTypeParameterCode = { ParameterCode: "LOSLoanType" };
		this.LoanPurposeParameterCode = { ParameterCode: "LOSLoanPurpose" };
		this.LMSAutoCLCParamAction = { ParameterCode: "LMSAutoCLCParamAction" };

		this.LMSAutoCollectionParamProfileFlexSet = {
			FlexType: "LMSAutoCollectionParamProfile",
			FlexCode: "LMSCPP"
		};

	}
	/**Begin Life cycle Methods */
	componentDidMount() {
		this
			.props
			.setAppLeftTitle("Automated Collection Parameters");
		this
			.props
			.setAppCenterTitle("LOAN MANAGEMENT SYSTEM");
	}
	/**END Life cycle Methods */

	/** General Methods */
	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert
				Title={title}
				Message={message}
				Type={type}
				OnConfirm={() => this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert
				Title={title}
				MessageNode={message}
				Type={type}
				OnConfirm={() => this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert
				Title={title}
				Message={message}
				Type={type}
				ShowCancel={true}
				OnCancel={() => this.setState({ alert: null })}
				OnConfirm={onConfirm} />
		});
	}
	ShowMessageAndRefresh = (type, title, message) => {
		this.setState({
			alert: <GenericAlert
				Title={title}
				MessageNode={message}
				Type={type}
				OnConfirm={() => this.hideAlertAndRefresh()} />
		});
	}
	hideAlertAndRefresh = () => {
		this.setState({ alert: null, isLoading: false, state: { ...initialState } });
		window
			.location
			.reload();

	}
	HideAlert = () => {
		this.setState({ alert: null });
	}

	ErrorCallback = (error) => {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", error);
	}


	/** END General Methods */

	/** BEGIN ACTIONS */

	GetAutoCollectionParamDetails = () => {
		const data = this.state.model;
		if (data != null && data.Id > 0) {
			this.HideAlert();
			this.setState({ isLoading: true });
			this.Post(data, "SearchParamDetail");
		}
	}
	OperationCheck = () => {

		var ErrorList = [];
		const data = this.state.model;
		const { vModel } = this.state;
		if (data == null) {
			ErrorList.push("Main parameters not defined.");
			this.ShowMessageNode("warning", "Please Fill Required Fields", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		if (data.LoanTypeId == null || data.LoanTypeId == 0) {
			vModel.LoanTypeId = true;
			ErrorList.push("Loan type must be defined.");
		}
		else {
			vModel.LoanTypeId = false;
		}
		if (data.LoanPurposeId == null || data.LoanPurposeId == 0) {
			vModel.LoanPurposeId = true;
			ErrorList.push("Purpose of loan must be defined.");
		}
		else {
			vModel.LoanPurposeId = false;
		}
		if (data.AutoCollectionProfileId == null || data.AutoCollectionProfileId == 0) {
			vModel.AutoCollectionProfileId = true;
			ErrorList.push("Profile must be defined.");
		}
		else {
			vModel.AutoCollectionProfileId = false;
		}
		const paramDetails = this.state.paramDetailList;
		if (paramDetails.length == 0) {
			ErrorList.push("Parameter detail must be defined.");
		}
		paramDetails.map((item) => {
			if (item.NumberOfDelayDay == null || item.AutoCollectionParamActionId == 0) {
				ErrorList.push("Number 0f delay day must be any value bigger than 0.");
			}
			if (item.AutoCollectionParamActionId == null || item.NumberOfDelayDay == 0) {
				ErrorList.push("Action must be defined.");
			}
			if (item.AutoCollectionParamAction.ParameterValue != 3 && (item.LoanNotificationTemplateId == null || item.LoanNotificationTemplateId == 0)) {
				ErrorList.push("Notification template must be defined.");
			}

			return null;
		});
		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please Fill Required Fields", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		if (data.Id != null && data.Id > 0) {
			this.ActionUpdate();
		} else {
			this.ActionSubmit();
		}
		this.setState({ vModel });
	}

	ActionSubmit = () => {
		this.HideAlert();
		this.setState({ isLoading: true });
		const data = {};
		data.AutoCollectionParam = this.state.model;
		data.AutoCollectionParamDetailList = this.state.paramDetailList;
		this.Post(data, "Save");
	}

	ActionSearch = () => {
		this.HideAlert();
		this.setState({ isLoading: true });
		const data = this.state.model;
		this.Post(data, "Search");

	}

	ActionUpdate = () => {
		this.HideAlert();
		this.setState({ isLoading: true });
		const data = {};
		data.AutoCollectionParam = this.state.model;
		data.AutoCollectionParamDetailList = this.state.paramDetailList;
		this.Post(data, "Save");
	}

	ActionDelete = () => {
		var ErrorList = [];
		this.HideAlert();
		this.setState({ isLoading: true });
		const data = this.state.model;
		if (data == null || data.Id == null || data.ScoreCardValueId == 0) {
			ErrorList.push("Any approval parameter not selected.");
		}

		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please Select A Record From Parameter List", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.Post(data, "Delete");
	}

	ActionClear = () => {
		this.setState({ model: {}, parameterDatalist: [{}], paramDetailList: [], alert: null, isLoading: false, rowIndex: -1 });
	}

	Post = (model, methodName) => {
		this.setState({ alert: null });
		this.setState({ isLoading: true });

		if (methodName == "Search") {
			model.LoanPurposeId = !model.LoanPurposeId
				? 0
				: model.LoanPurposeId;
		}

		Proxy.POST((url + methodName), model, (responseData) => {
			switch (methodName) {
				case "Search":
					if (responseData.IsSucceeded && responseData.Item != null) {
						this.setState({ parameterDatalist: responseData.Item, isLoading: false });
					}
					if (!responseData.IsSucceeded) {
						this.ShowMessageNode("error", "Error", "No Record Listed");
						this.ActionClear();
						this.setState({ isLoading: false });
					}
					break;
				case "Delete":
					if (responseData.IsSucceeded && responseData.Item != null) {
						this.ShowMessage("success", "Success", "The Parameter Deleted");
						this.ActionClear();
						this.setState({ isLoading: false });
					}
					else {
						this.ShowMessageNode("error", "Error", "The Parameter Did Not Delete");
						this.ActionClear();
						this.setState({ isLoading: false });
					}
					this.setState({ alert: null, isLoading: false, state: { ...initialState } });
					this.ActionSearch();
					break;
				case "SearchParamDetail":
					if (responseData.IsSucceeded && responseData.Item != null) {
						this.setState({ paramDetailList: responseData.Item, isLoading: false });
					}
					if (!responseData.IsSucceeded) {
						this.ShowMessageNode("error", "Error", "No Detail Record Listed");
						this.setState({ paramDetailList: [], isLoading: false });
					}
					break;
				default:
					if (responseData.IsSucceeded && responseData.Item != null) {
						this.ShowMessage("success", "Success", "The Parameter Saved");
						this.setState({ isLoading: false });
						this.ActionClear();
						this.ActionSearch();
					} else {
						this.ShowMessage("error", "Error", "The Parameter Did Not Save");
						this.setState({ alert: null, isLoading: false, state: { ...initialState } });
					}
					break;

			}
		}, this.ErrorCallback);

	}

	/** END ACTIONS */




	/** BEGIN EVENTS */

	HandleChange = (name, newValue) => {
		const model = {
			...this.state.model
		};

		if (name == "LoanTypeId") {
			model[name] = newValue != null
				? newValue
				: 0;
			model["LoanPurposeId"] = null;
		} else {
			model[name] = newValue;
		}

		this.setState({ model });
	}

	handleChangeCriteria = (name, newValue, index, data) => {
		this.setState(function () {
			var list = this.state.paramDetailList;
			const item = list[index];
			if (item != null) {
				item[name] = newValue;
			}
			if (name === "AutoCollectionParamActionId" && item != null) {
				item.AutoCollectionParamAction = data;
			}
			return { paramDetailList: list };
		});
	}

	RowSelected = (index) => {
		const list = this.state.parameterDatalist;
		let model = list[index];
		this.setState({ model, rowIndex: index }, () => this.GetAutoCollectionParamDetails());
	}

	AddSingleCriteria = () => {
		const { model, paramDetailList: list } = this.state;

		const temp = {
			Id: 0,
			AutomatedCollectionParameterId: model.Id,
			AutoCollectionParamActionId: 0,
			AutoCollectionParamAction: { ParameterValue: 3 },
			LoanNotificationTemplateId: 0
		};
		list.push(temp);
		this.setState({ paramDetailList: list });
	}

	RemoveSelectedCriteria = (index) => {
		this.setState(state => {
			var paramDetailList = state.paramDetailList;
			paramDetailList.splice(index, 1);
			return { paramDetailList };
		});
	}

	render() {
		const { model, alert, paramDetailList, isLoading, vModel, rowIndex } = this.state;

		var ForInsert = !model.Id || model.Id <= 0;

		return (
			<div>
				<Dialog open={isLoading}>
					<DialogContent>
						<GenericCircularProgress />
					</DialogContent>
				</Dialog>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Submit",
							OnClick: this.OperationCheck,
							Disabled: !ForInsert
						}, {
							Code: "Search",
							OnClick: this.ActionSearch,
							Disabled: false
						}, {
							Code: "Update",
							OnClick: this.OperationCheck,
							Disabled: ForInsert
						}, {
							Code: "Delete",
							OnClick: this.ActionDelete,
							Disabled: ForInsert
						}, {
							Code: "Clear",
							OnClick: this.ActionClear,
							Disabled: false
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					IsPopUp={false} />
				<GridContainer>
					{alert}
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel
									Title="Main Parameters">
									<GridContainer
										direction="row"
										justify="flex-start"
										alignItems="flex-start"
									>
										<GridItem xs={3}>
											<ParameterComponent
												Name="LoanTypeId"
												LabelText="Loan Type"
												ParameterCode={this.LoanTypeParameterCode.ParameterCode}
												TextValueMember="ParameterDesc"
												Value={model.LoanTypeId}
												ValueChanged={this.HandleChange}
												Disabled={false}
												Required
												IsInvalid={vModel.LoanTypeId} />
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												key={model.LoanTypeId}
												Disabled={!model.LoanTypeId}
												Name="LoanPurposeId"
												LabelText="Purpose Of Loan"
												LabelMd={5}
												Method="POST"
												Url="/losapi/v1.0/LosParameter/SearchLoanPurposeByLoanTypeId"
												Parameter={{ LoanTypeId: model.LoanTypeId }}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ParameterDesc"
												Sorted={this.sortedParameterDesc}
												Value={model.LoanPurposeId}
												ValueChanged={this.HandleChange}
												Required
												IsInvalid={vModel.LoanPurposeId} />
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												key={"AutoCollectionProfileId"}
												Name="AutoCollectionProfileId"
												LabelText="Collection Profile Name"
												Method="POST"
												LabelMd={5}
												Url="/losapi/v1.0/ApprovalParameter/ScurecardValueSearch"
												Parameter={this.LMSAutoCollectionParamProfileFlexSet}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="FlexDesc"
												Value={model.AutoCollectionProfileId}
												ValueChanged={this.HandleChange}
												Disabled={false}
												Required
												IsInvalid={vModel.AutoCollectionProfileId}
											/>
										</GridItem>
									</GridContainer>

								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridItem>
									<CardHeader>
										<GenericTitle text={"Parameter Details"} />
									</CardHeader>
								</GridItem>
								<GridItem xs={12}>
									<GridContainer direction="row" justify="center" alignItems="flex-start">
										<GridItem xs={6}></GridItem>
										<GridItem xs={6}>
											<GridContainer justify="flex-end">
												<GridItem xs={2}>
													<Button size="sm" onClick={() => { this.AddSingleCriteria(); }} fullWidth>ADD</Button>
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</GridItem>
								<GridItem xs={12}>
									{paramDetailList != null && paramDetailList.map((item, index) => (
										<GridContainer key={item.Id}>
											<GridItem xs={3}>
												<GenericNumberInput
													Name="NumberOfDelayDay"
													key={"NumberOfDelayDay"}
													LabelText="Number Of Delay Day"
													LabelMd={5}
													MaxLength={3}
													MaxValue={365}
													Format="###"
													Value={item.NumberOfDelayDay}
													LabelStyle={styles.LabelStyle}
													InputStyle={styles.InputStyle}
													ValueChanged={(name, value, data) => this.handleChangeCriteria(name, value, index, data)}
													Disabled={false} />
											</GridItem>
											<GridItem xs={3}>
												<ParameterComponent
													Name="AutoCollectionParamActionId"
													key={"AutoCollectionParamActionId"}
													LabelText="Action"
													LabelMd={3}
													ParameterCode={this.LMSAutoCLCParamAction.ParameterCode}
													TextValueMember="ParameterDesc"
													Value={item.AutoCollectionParamActionId}
													ValueChanged={(name, value, data) => this.handleChangeCriteria(name, value, index, data)}
													Disabled={false} />
											</GridItem>
											<GridItem xs={3}>
												{(item.AutoCollectionParamAction.ParameterValue != 3) &&
													<GenericSelectInput
														Name="LoanNotificationTemplateId"
														key={item.LoanNotificationTemplateId}
														LabelText="Notification Template"
														LabelMd={5}
														Disabled={false}
														Value={item.LoanNotificationTemplateId}
														ValueChanged={(name, value, data) => this.handleChangeCriteria(name, value, index, data)}
														KeyValueMember="Id"
														TextValueMember="Name"
														Method="POST"
														DataRoot="Item"
														Url="/lmsapi/v1.0/LoanNotificationTemplate/SearchTemp"
														Parameter={{
															LoanTypeId: 0,
															LoanPurposeId: 0,
															AutoCollectionParamActionId: 0
														}}
													/>
												}
											</GridItem>

											<GridItem xs={1}>
												<Button disabled={false} size="sm" color="black" onClick={() => this.RemoveSelectedCriteria(index)} >
													Delete
											</Button>
											</GridItem>
											<GridItem xs={2}></GridItem>
										</GridContainer>
									))}

								</GridItem>




							</CardBody>
						</Card>

						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Parameter List">
									<GridContainer>
										<GridItem xs={12}>
											<GenericGrid
												Data={this.state.parameterDatalist}
												Columns
												={[
													{
														Header: "Loan Type",
														accessor: "LoanType.ParameterDesc"
													}, {
														Header: "LoanPurpose",
														accessor: "LoanPurpose.ParameterDesc"
													}, {
														Header: "Collection Profile Name",
														accessor: "AutoCollectionProfile.FlexDesc"
													}
												]}
												RowSelected={this.RowSelected}
												SelectedIndex={rowIndex} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>


					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

const styles = ({
	LabelText: {
		color: "Black",
		marginLeft: 20
	},
	LabelText2: {
		color: "black",
		marginLeft: 50
	},
	ComboStyle: {
		marginLeft: 30
	},
	LabelStyle: {
		fontSize: 8 + "px !important;"
	},
	InputStyle: {
		backgroundColor: "White"
	}
});

AutomatedCollectionParameter.propTypes = {
	classes: PropTypes.object.isRequired,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(AutomatedCollectionParameter, style);
