import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton
} from "@material-ui/core";
import { primaryColor } from "assets/jss/material-dashboard-pro-react.jsx";
import { Proxy, withArtifex } from "core";
import { ProfileIcon, EditIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import ClientHelper from "core/ClientHelper";
import {
	GenericAlert,
	GenericLabel,
	GenericIcon,
	GenericExpansionPanel,
	GenericGrid,
	GenericNumberInput,
	GenericSelectInput,
	GenericTextInput
} from "views/Components/Generic";
import { ProgramCodes } from "views/Constants/Constant.js";
import AccountComponent from "views/Components/AccountComponent";
import CustomerComponent from "views/Components/CustomerComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import ToolbarButton from "views/Components/ToolbarButton";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import Button from "components/CustomButtons/Button.jsx";
import LoadingComponent from "views/Components/LoadingComponent";

class CountryLimits extends React.Component {
	constructor(props) {
		super(props);

		this.emptyLimitModel = {
			Id: null,
			Country: null,
			Limit: null,
			UniqueClientId: null,
			Program: null
		};

		this.columns = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<ToolbarButton
							color="primary"
							justIcon
							simple
							size="sm"
							tooltip="Modify"
							onClick={() => { this.handleSelect(row.original, row.index); }}>
							<img src={EditIcon} />
						</ToolbarButton>
					</div>
				),
				sortable: false,
				filterable: false,
				resizable: false,
				width: 80
			},
			{
				Header: "Country Code",
				accessor: "Country.Code"
			},
			{
				Header: "Country Name",
				accessor: "Country.Name"
			},
			{
				Header: "Program",
				accessor: "Program.Description"
			},
			{
				Header: "Country Limit",
				accessor: "Limit"
			}
		];

		this.state = {
			alert: null,
			list: [],
			model: this.emptyLimitModel,
			isLoading: false
		};
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Country Limits");
		this.props.setAppCenterTitle("FX TRANSFER");
	}

	handleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		const dialogModel = { ...this.state.dialogModel };
		const initialModel = { ...this.emptyLimitModel };
		if (name === "UniqueClientId") {
			if (
				ClientHelper.IsClient() &&
				initialModel.UniqueClientId == null &&
				newValue != null
			) {
				this.emptyLimitModel.UniqueClientId = newValue;
			}
			model.UniqueClientId = newValue;
		} else if (name === "CountryId") {
			model.Country = data;
		} else if (name === "Limit") {
			model.Limit = newValue;
		} else if (name === "ProgramType") {
			model.Program = data;
		}
		this.setState({ model });
	};

	handleClear = () => {
		this.setState({
			model: this.emptyLimitModel,
			alert: null,
			list: []
		});
	};

	handleSearch = () => {
		const { model } = this.state;
		const request = {
			ProgramId: model.Program == null ? null : model.Program.Id,
			CountryId: model.Country == null ? null : model.Country.Id,
		};
		Proxy.ExecuteGeneral(
			this, "POST",
			"/bankapi/v1.0/CountryLimit/Search",
			request,
			responseData => {
				this.setState({
					list: responseData.Item
				});
			}
		);
	};

	handleSubmit = () => {
		const { model } = this.state;
		const request = {
			Id: model.Id == null ? 0 : model.Id,
			UniqueClientId: 0,
			IsRecordValid: true,
			Limit: model.Limit == null ? 0 : model.Limit,
			ProgramId: model.Program == null ? null : model.Program.Id,
			CountryId: model.Country == null ? null : model.Country.Id,
		};

		Proxy.ExecuteGeneral(
			this, "POST",
			"/bankapi/v1.0/CountryLimit/" + (model.Id == null ? "Insert" : "Update"),
			request,
			responseData => {
				this.setState({
					model: this.emptyLimitModel
				}, this.handleSearch);
			}
		);
	};

	handleDelete = () => {
		const { model } = this.state;
		const request = {
			Id: model.Id == null ? 0 : model.Id,
			UniqueClientId: 0,
			IsRecordValid: false,
			Limit: model.Limit == null ? 0 : model.Limit,
			ProgramId: model.Program == null ? null : model.Program.Id,
			CountryId: model.Country == null ? null : model.Country.Id,
		};

		Proxy.ExecuteGeneral(
			this, "POST",
			"/bankapi/v1.0/CountryLimit/Update",
			request,
			responseData => {
				this.setState({
					model: this.emptyLimitModel
				}, this.handleSearch);
			}
		);
	};

	handleSelect = (data, index) => {
		var clonedData = Object.assign({}, data);
		this.setState({ model: clonedData });
	};

	render() {
		const { Disabled } = this.props;
		const { model, alert, list, isLoading } = this.state;
		return (
			<div>

				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar
					ButtonList={[
						{ Code: "Search", OnClick: this.handleSearch, Disabled: Disabled },
						{ Code: "Submit", OnClick: this.handleSubmit, Disabled: (Disabled || model.Id != null) },
						{ Code: "Update", OnClick: this.handleSubmit, Disabled: (Disabled || model.Id == null) },
						{ Code: "Delete", OnClick: this.handleDelete, Disabled: (Disabled || model.Id == null) },
						{ Code: "Clear", OnClick: this.handleClear, Disabled: Disabled }
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After}
				/>
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card>
							<CardBody>
								<GenericExpansionPanel>
									<GridContainer>
										<GridItem xs={4}>
											<GridItem>
												<GenericSelectInput
													Name="CountryId"
													LabelText="Country"
													Method="POST"
													Url="/coreapi/v1.0/Country/GetAll"
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={d => `${d.Code} - ${d.Name}`}
													Value={model.Country == null ? null : model.Country.Id}
													ValueChanged={this.handleChange}
													CanClear
													All
													Disabled={model.Id != null} />
											</GridItem>
											<GridItem>
												<GenericSelectInput
													Name="ProgramType"
													LabelText="Program"
													Value={model.Program == null ? null : model.Program.Id}
													ValueChanged={this.handleChange}
													KeyValueMember="Id"
													TextValueMember="Description"
													Method="GET"
													Url="/bankapi/v1.0/Bank/ValidBankPrograms"
													DataRoot="Item"
													Disabled={model.Id != null} />
											</GridItem>
										</GridItem>
										<GridItem xs={4}>
											<GridItem>
												<GenericTextInput
													IsText
													LabelText="Country Name"
													Value={model.Country == null ? null : model.Country.Name}
													Disabled />
											</GridItem>
											<GridItem>
												<GenericNumberInput
													Name="Limit"
													LabelText="Limit"
													MaxLength={15}
													Value={model.Limit}
													ValueChanged={this.handleChange} />
											</GridItem>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card>
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid Data={list} Columns={this.columns} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		);
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })}
				/>
			)
		});
	};

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					MessageNode={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })}
				/>
			)
		});
	};

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					ShowCancel={true}
					OnCancel={() => this.setState({ alert: "" })}
					OnConfirm={onConfirm}
				/>
			)
		});
	};
}

CountryLimits.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	handleChange: PropTypes.func,
	menuId: PropTypes.number,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default withArtifex(CountryLimits, {});
