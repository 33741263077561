import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import AlertHelper from "core/AlertHelper";
import ClientHelper from "core/ClientHelper.js";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import { DeleteIcon, EditIcon } from "core/Icons";
import RouteHelper from "core/RouteHelper";
import { TaskPool } from "core/TaskPool";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import CustomerComponent from "views/Components/CustomerComponent";
import {
	GenericAlert,
	GenericDateInput,
	GenericEmailInput,
	GenericExpansionPanel,
	GenericGrid,
	GenericNumberInput,
	GenericSelectInput,
	GenericTextInput,
	GenericTitle
} from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { IdentificationMethod, IdentityType, LOSApplicantStatus, MenuCodes, ProgramCodes } from "views/Constants/Constant";
import LoanApplicationCreditValidation from "views/LOS/CreateApplication/LoanApplicationCreditValidation";

const ConstLoanType = {
	SecureCard: 0,
	Loan: 1,
	LOC: 2
};
const ApplicantApiUrl = "/losapi/v1.0/Applicant/";
function GetAppliedForRequestedLengthOfTerm() {
	var termDataList = [];
	termDataList.push({ Value: 1, Text: "1 Month" });
	for (var i = 2; i < 61; i++) {
		termDataList.push({
			Value: i,
			Text: i + " Months"
		});
	}
	return termDataList;
}
const initialState = {
	panelActivationStatus: {
		panelApplicantInformationActive: true,
		panelAppliedForActive: false,
		panelVerificationActive: false,
		panelCreditValidationStatusActive: false,
		panelApplicantListActive: false
	},
	model: {
		InPersonIDVerificationEnable: false,
		CanadaPostVerificationEnable: false,
		OtherVerificationTypeEnable: false,
		ExternalAccount: {
			FinancialInstitutionId: 0,
			FinancialInstitutionBranchId: 0,
			AccountNumber: null
		},
		CustomerId: 0,
		CustomerNumber: ""
	},
	appliedForData: {},
	modelList: [],
	creditHistoryModel: {},
	isLoading: false,
	ReadOnly: false,
	IsActiveLoanPurposeDescription: false,
	IsPersonVerified: false,
	CanadaPostBarcode: null,
	IdVerificationTypeList: [],
	vModel: {},
	RequestedLengthOfTermSource: GetAppliedForRequestedLengthOfTerm(),

	AddressType: null,

	identificationMethodIds: []
};

class ApplicantDefinition extends Component {
	constructor(props) {
		super(props);
		this.state = Object.assign({}, initialState);
		this.LanguageParameterCode = {
			ParameterCode: "Language"
		};
		this.loadTaskPool = new TaskPool(this.handleLoadTaskPoolAppend, this.handleLoadTaskPoolCompleted);
		this.HandleChangeFilterPanel = this
			.HandleChangeFilterPanel
			.bind(this);
		this.AppliedForNextCliecked = this
			.AppliedForNextCliecked
			.bind(this);
		this.ApplicantInformationNextCliecked = this
			.ApplicantInformationNextCliecked
			.bind(this);
		this.CreditValidationStatusNextCliecked = this
			.CreditValidationStatusNextCliecked
			.bind(this);
		this.VerificationActiveNextCliecked = this
			.VerificationActiveNextCliecked
			.bind(this);
		this.SavePanelAfterInsert = this
			.SavePanelAfterInsert
			.bind(this);
		this.ParameterMaxLength30 = {
			maxLength: 30
		};
		this.ParameterMaxLength64 = {
			maxLength: 64
		};
		this.parameterIdentificationMethod = {
			ParameterCode: "IdentificationMethod",
			ParameterValue2: "Active"
		};
		this.IdentityVerifyingPersonParameterCode = {
			ParameterCode: "IdentityVerifyingPerson"
		};
		this.parameterIdentityType = {
			ParameterCode: "IdentityType"
		};
		this.CountryParameterCode = {
			ParameterCode: "Country"
		};
		this.OccupationParameterCode = {
			ParameterCode: "LOSOccupation"
		};

		this.ParameterOccupation = {
			ParameterCode: "Occupation"
		};

		this.ParameterApplicantStatus = {
			ParameterCode: "LOSApplicantStatus"
		};

		this.ParameterDefaultApplicantStatus = {
			ParameterCode: "LOSApplicantStatus",
			ParameterValue2: "Default"
		};

		this.ParameterIntendedUse = {
			ParameterCode: "IntendedUse"
		};

		this.ParameterDefaultIdentificationMethod = {
			ParameterCode: "IdentificationMethod",
			ParameterValue3: "Default"
		};

		this.ParameterIdentityType = {
			ParameterCode: "IdentityType2",
			ParameterValue2: "PrimaryId"
		};

		this.ParameterIdentitySecondary = {
			ParameterCode: "IdentityType2"
		};

		this.ParameterPrimarySourceForDualProcessId = {
			ParameterCode: "DocumentForDualProcessIdentification",
			ParameterValue2: "PrimaryDocument"
		};

		this.ParameterSecondarySourceForDualProcessId = {
			ParameterCode: "DocumentForDualProcessIdentification",
		};

		this.emptyObject = {};
		this.YesNoParameterCode = {
			ParameterCode: "LOSYesNo"
		};
		this.LoanTypeParameterCode = {
			ParameterCode: "LOSLoanType"
		};
		this.LoanPurposeParameterCode = {
			ParameterCode: "LOSLoanPurpose"
		};

		this.GetIdentityVerificationType = this
			.GetIdentityVerificationType
			.bind(this);

		this.sortedParameterDesc = {
			Member: "ParameterDesc"
		};

		this.ParameterProvincialGovermentPhotoId = {
			ParameterCode: "ProvincialGovermentPhotoId"
		};

		this.ParameterCreditAgency = {
			ParameterCode: "CreditAgency"
		};

		this.maxLength100 = {
			maxLength: 100
		};
		this.maxLength20 = {
			maxLength: 20
		};
		this.marginLeft2 = {
			marginLeft: 2
		};

		this.maxLength30 = { maxLength: 30 };

		this.columnsRowStyleColor = {};

		this.ColumnsData = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip="Edit"
							Icon={EditIcon}
							OnClick={() => {
								this.setState({
									addressModel: {
										...row.original
									}
								});
								this.GetCreditHistory(row.original.Id);
							}} />
						<GridButton
							tooltip="Delete"
							Icon={DeleteIcon}
							OnClick={() => this.ShowQuestionMessage("Delete this record !", row.original.Id)} />
					</div>
				),
				sortable: true,
				filterable: false,
				width: 100
			}, {
				Header: "Date",
				accessor: "InsertDateTime",
				Cell: row => (
					<div style={this.GetColumnsRowStyleColor(row.original.Status)}>{Formatter.FormatDate(row.value)}</div>
				)
			}, {
				Header: "FullName",
				accessor: "FullName",
				Cell: row => (
					<div style={this.GetColumnsRowStyleColor(row.original.Status)}>{row.value}</div>
				)
			}, {
				Header: "Unique ID",
				accessor: "ApplicantUniqueId",
				Cell: row => (
					<div style={this.GetColumnsRowStyleColor(row.original.Status)}>{row.value}</div>
				)
			}, {
				Header: "ID Verification Status",
				accessor: "IdVerification",
				Cell: row => (
					<div style={this.GetColumnsRowStyleColor(row.original.Status)}>{row.value}</div>
				)
			}, {
				Header: "Internal Blacklist Status",
				accessor: "InternalBlacklist",
				Cell: row => (
					<div style={this.GetColumnsRowStyleColor(row.original.Status)}>{row.value}</div>
				)
			}, {
				Header: "Watch List Verification Status",
				accessor: "WatchListVerification",
				Cell: row => (
					<div style={this.GetColumnsRowStyleColor(row.original.Status)}>{row.value}</div>
				)
			}, {
				Header: "Trans Union Id Verification Status",
				accessor: "TransUnionIdVerification",
				Cell: row => (
					<div style={this.GetColumnsRowStyleColor(row.original.Status)}>{row.value}</div>
				)
			}, {
				Header: "Credit History Verification Status",
				accessor: "CreditHistoryVerification",
				Cell: row => (
					<div style={this.GetColumnsRowStyleColor(row.original.Status)}>{row.value}</div>
				)
			}, {
				Header: "E-Mail",
				accessor: "Email",
				Cell: row => (
					<div style={this.GetColumnsRowStyleColor(row.original.Status)}>{row.value}</div>
				)
			}, {
				Header: "Phone Number",
				Cell: row => (
					<div style={this.GetColumnsRowStyleColor(row.original.Status)}>{row.original.MobilePhoneCountryCode == null
						? ""
						: "+" + row.original.MobilePhoneCountryCode.Code + " " + row.original.MobilePhoneNumber}</div>
				)
			}, {
				Header: "Status",
				accessor: "Status.ParameterDesc",
				Cell: row => (
					<div style={this.GetColumnsRowStyleColor(row.original.Status)}>{row.value}</div>
				)
			}, {
				Header: "Loan Type",
				accessor: "ApplicantAppliedFor.LoanType.ParameterDesc",
				Cell: row => (
					<div style={this.GetColumnsRowStyleColor(row.original.Status)}>{row.value}</div>
				)
			}, {
				Header: "Sale Channel",
				accessor: "ApplicantAppliedFor.SaleChannel.ParameterDesc",
				Cell: row => (
					<div style={this.GetColumnsRowStyleColor(row.original.Status)}>{row.value}</div>
				)
			}
		];
		if (this.state.model.ExternalAccount != null) {
			this.state.model.ExternalAccount.FinancialInstitutionId = 0;
			this.state.model.ExternalAccount.FinancialInstitutionBranchId = 0;
			this.state.model.ExternalAccount.AccountNumber = null;
		}

		this.retiredOrUnemployedOccupationIds = [];
		// this.identificationMethodIds = [];
		this.specificIdentityTypesIds = [];  

		this.sortedOrderIndex = { Member: "OrderIndex" };
		this.renderItemPhoneCode = {};

	}

	componentDidMount() {
		var previousUrl = localStorage.getItem("previousUrl");
	
		if (this.props.setAppLeftTitle)
			this.props.setAppLeftTitle(this.props.MenuCode != MenuCodes.Lms_BorrowerDetail && previousUrl != "/PortfolioPerformance"
				? "Create Application"
				: "Borrower Detail");
		if (this.props.setAppCenterTitle) {
			this
				.props
				.setAppCenterTitle(this.props.MenuCode != MenuCodes.Lms_BorrowerDetail && previousUrl != "/PortfolioPerformance"
					? "LOAN ORIGINATOR SYSTEM"
					: "LOAN MANAGEMENT SYSTEM");
		}
				
		if (this.props.ApplicantModel != null) {
			this.ApplicantSearchForLoanApproval(this.props.ApplicantModel.Id);
		}

		// this.GetIdentificationMethodIds();

		this.LoadCanadaPost();
		this.GetIdentityVerificationType();
				
		var Id = RouteHelper.ReadAndClear("losApplicantDefinition_Id");

		if (Id)
			this.ApplicantSearchForLoanApproval(Id);
		else {
			const { ApplicantId } = this.props;
			
			if (ApplicantId) {
				this.ApplicantSearchForLoanApproval(ApplicantId);
			}
			else {
				// when the application is being created
				// set default status and Identification Method
				this.GetDefaultApplicantStatus();
				this.GetDefaultIdentificationMethod();
			}			
		}	
	}

	componentWillMount() {
		this.GetIdentificationMethodIds();
	}

	RenderItemPhoneCode = (d) => {
		this.renderItemPhoneCode = "+" + d.Code + " (" + d.Name + ")";
		return this.renderItemPhoneCode;
	}

	IsNullOrEmpty(value) {
		return (value == null || value === "" || value === '' 
									 || value === '""' || value === '""');
	}

	GetDefaultIdentificationMethod() {
		Proxy.POST(
			"/coreapi/v1.0/Parameter/Search",
			this.ParameterDefaultIdentificationMethod,
			(responseData) => {
				if (!responseData.IsSucceeded) {
					console.log("Parameters were not found.");
					return;
				}							
				var currentModel = this.state.model;
				currentModel["IdentificationMethodId"] = responseData.Item[0].Id;
				currentModel["IdentityVerifiedBy"] = ClientHelper.GetUser().Email;
				currentModel["IdentityVerificationDate"] = DateHelper.GetDateLocal();
				currentModel["SecondaryIdentityVerifiedBy"] = ClientHelper.GetUser().Email;
				currentModel["SecondaryIdentityVerificationDate"] = DateHelper.GetDateLocal();				
				this.setState({model: currentModel});
			}
		);
	}

	GetDefaultApplicantStatus() {
		Proxy.POST(
			"/coreapi/v1.0/Parameter/Search",
			this.ParameterDefaultApplicantStatus,
			(responseData) => {
				if (!responseData.IsSucceeded) {
					console.log("Parameters were not found.");
					return;
				}							
				var currentModel = this.state.model;
				currentModel["StatusId"] = responseData.Item[0].Id;
				this.setState({model: currentModel});
			}
		);
	}

	GetSpecificIdentityTypesIds() {
		Proxy.POST(
			"/coreapi/v1.0/Parameter/Search",
			{
				ParameterCode: "IdentityType2",
				ParameterValue: "ID010"
			},
			(responseData) => {
				if (!responseData.IsSucceeded) {
					console.log("Parameters were not found.");
					return;
				}
				let list = responseData.Item;								
				list.forEach(element => {
					this.specificIdentityTypesIds.push(element.Id);
				});	
			}
		);
	}

	GetIdentificationMethodIds() {
		Proxy.POST(
			"/coreapi/v1.0/Parameter/Search",	
			this.parameterIdentificationMethod,		
			(responseData) => {
				if (!responseData.IsSucceeded) {
					console.log("Parameters were not found.");
					return;
				}
				let list = responseData.Item;	
				let methods = [];							
				for(var i=0; i<list.length; i++) {
					let method = {
						"Id" :	list[i].Id,
						"Value" : list[i].ParameterValue
					};
					//this.identificationMethodIds.push(method);
					methods.push(method);						
				}
				this.setState({identificationMethodIds: methods});
				this.OnLoadedIDMethods();	
			}
		);
	}

	OnLoadedIDMethods() {
		this.GetRetiredOrUnemployedOccupationIds();
		this.GetSpecificIdentityTypesIds();
	}

	GetRetiredOrUnemployedOccupationIds() {
		Proxy.POST(
			"/coreapi/v1.0/Parameter/Search",
			{
				ParameterCode: "Occupation"
			},
			(responseData) => {
				if (!responseData.IsSucceeded) {
					console.log("Parameters were not found.");
					return;
				}
				let list = responseData.Item;
				list = list.filter(x=>x.ParameterValue == "Retired"
												|| x.ParameterValue == "UnemployedSocialAssistance"
												|| x.ParameterValue == "UnemploymentAssistance");
				
				list.forEach(element => {
					this.retiredOrUnemployedOccupationIds.push(element.Id);				
				});				
			}
		);
	}

	GetColumnsRowStyleColor(status) {
		this.columnsRowStyleColor = {
			color: status != null && status.ParameterValue == LOSApplicantStatus.Saved
				? "#FB3005"
				: ""
		};

		return this.columnsRowStyleColor;
	}

	async GetIdentityVerificationType() {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/coreapi/v1.0/Parameter/Search", this.parameterIdentificationMethod, null, null, null, null, null);
		var IdVerificationTypeList = [];

		if (result != null) {
			result
				.filter(x => x.ParameterValue === IdentificationMethod.CanadaPostVerification || x.ParameterValue === IdentificationMethod.InPersonIDVerification || x.ParameterValue === IdentificationMethod.Other)
				.map(x => {
					IdVerificationTypeList.push(x);

					return null;
				});
		}
		this.setState({ IdVerificationTypeList });
	}

	ApplicantSearchForLoanApproval = (Id) => {
		this.setState({ isLoading: true });
		Proxy.POST("/losapi/v1.0/Applicant/Search", {
			Id: Id
		}, responseData => {
			this.setState({ isLoading: false });
			if (!responseData.IsSucceeded) {
				this.ShowMessage("error", "Error", responseData.ErrorDescription);
				return;
			}
			var model = responseData.Item[0];
			model.BirthDate = model.BirthDate === null
				? null
				: Formatter.FormatDateUtc(model.BirthDate);
			model.IsEmailValid = true;
			model.IdentityTypeParameterValue2 = model.IdentityType
				?.ParameterValue2;
			var appliedForData = model.ApplicantAppliedFor || {};

			if (model.IdentityVerificationType != null && model.IdentityVerificationType.ParameterValue == IdentificationMethod.CanadaPostVerification) {
				model.CanadaPostVerificationEnable = true;
			}

			this.setState({ model, appliedForData });
		}, error => {
			this.setState({ isLoading: false });
			this.ShowMessage("error", "Error", error);
		});
	}

	GetCreditHistory = (Id, isInquiryClicked) => {
		var creditHistoryModel = {};

		this
			.loadTaskPool
			.AppendTask(Proxy.POST("/lmsapi/v1.0/LoanCreditHistory/GetByApplicantId", {
				ApplicantId: Id
			}, (responseData) => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					this.setState({ creditHistoryModel });
					return;
				}

				if (responseData.Item != null) {
					if (responseData.Item.CreditHistory) {
						Object.assign(creditHistoryModel, responseData.Item.CreditHistory);
					}
					if (responseData.Item.CreditHistoryDetails) {
						creditHistoryModel.CreditHistoryDetails = responseData.Item.CreditHistoryDetails;
					}
				}

				if (creditHistoryModel == null || creditHistoryModel.CreditHistoryDetails == null) {
					if (isInquiryClicked) {
						this.ShowMessage("warning", "Warning", "Credit data not found for the applicant.");
					}
				}

				this.setState({ creditHistoryModel });
			}, (error) => {
				this.ShowMessage("error", "An error occurred while requesting data", error.message);
			}));
	}

	handleLoadTaskPoolCompleted = () => {
		this.setState({ isLoading: false });
	}

	handleLoadTaskPoolAppend = () => {
		this.setState({ isLoading: true });
	}
	LoadCanadaPost() {

		var parent = this;
		this
			.loadTaskPool
			.AppendTask(Proxy.POST("/coreapi/v1.0/Parameter/Search", {
				ParameterCode: "CanadaPostApiKey",
				ParameterValue: "CP"
			}, responseData => {
				if (!responseData.IsSucceeded) {
					console.log("Canada Post Api key not fetched !");
					return;
				}
				if (responseData.Item == null) {
					console.log("Canada Post Api key not fetched !");
					return;
				}
				if (responseData.Item[0] == null) {
					console.log("Canada Post Api key not fetched !");
					return;
				}
				var apikey = responseData.Item[0].ParameterDesc;
				console.log("KEY CP ", apikey);

				// @ts-ignore
				var url = window.CanadaPostAddressCompleteUrl;
				var path = url + apikey;

				var pca;

				var CanadaPostSubmit = this.SubmitCanadaPostAddressModel;
				const script = document.createElement("script");
				script.src = path;
				script.async = false;
				document
					.head
					.appendChild(script);
				script.onload = function () {
					// @ts-ignore
					pca = window.pca;
					if (pca != null) {
						var fields = [
							{
								element: "street-address",
								field: "Line1",
								mode: pca.fieldMode.SEARCH
							}, {
								element: "street-address2",
								field: "Line2",
								mode: pca.fieldMode.POPULATE
							}, {
								element: "postcode",
								field: "PostalCode",
								mode: pca.fieldMode.SEARCH
							}
						],
							options = {
								key: apikey
							},							

							control = new pca.Address(fields, options);

							var employer = 
								[
									{ element: "employer-address", field: "Line1", mode: pca.fieldMode.SEARCH },						
									{ element: "employer-postcode", field: "PostalCode", mode: pca.fieldMode.SEARCH },
								],
							employerControl = new pca.Address(employer, options);

						control.listen("populate", function (address) {
							CanadaPostSubmit(address);
							parent.setState(state => {
								state.model.Address1 = address.Line1;
								state.model.Address2 = address.Line2;
								state.model.ZipCode = address.PostalCode;
								state.AddressType = "Customer";
								return state;
							});
						});

						employerControl.listen("populate", function (address) {
							CanadaPostSubmit(address);
							parent.setState(state => {
								state.model.EmployerAddress = address.Line1;
								state.model.EmployerZipCode = address.PostalCode;
								state.AddressType = "Employer";
								return state;
							});
						});

					}
				};
			}, e => {
				console.log("Canada Post Api key not fetched !");
			}));
	}
	SubmitCanadaPostAddressModel = (model) => {
		this
			.loadTaskPool
			.AppendTask(Proxy.POST("/coreapi/v1.0/Country/CanadaPost", model, responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				var address = {
					...this.state.model
				};
				if (responseData.Item != null) {
					if (this.state.AddressType == "Customer"){
						address.CountryId = responseData.Item.CountryId;
						address.ProvinceId = responseData.Item.ProvinceId;
						address.CityId = responseData.Item.CityId;
					}
					else {
						address.EmployerCountryId = responseData.Item.CountryId;
						address.EmployerProvinceId = responseData.Item.ProvinceId;
						address.EmployerCityId = responseData.Item.CityId;
					}
					
				} else {
					address.CountryId = 0;
					address.ProvinceId = 0;
					address.CityId = 0;

					address.EmployerCountryId = 0;
					address.EmployerProvinceId = 0;
					address.EmployerCityId = 0;
				}
				this.setState({ model: address });
			}, error => {
				this.ShowMessage("error", "Error", error);
			}));
	}
	handleChangeCP = (address) => {
		const model = this.state.model;
		model.City = address.City;
		model.Province = address.ProvinceName;
		model.Country = address.CountryName;
		model.Address1 = address.Line1;
		model.Address2 = address.Line2;
		model.ZipCode = address.PostalCode;
		this.setState({ model });
	}
	OperationCheck = (isCompleteApplicationClicked) => {

		var ErrorList = [];
		const data = this.state.model;
		const dataAppliedFor = this.state.appliedForData;
		let { vModel } = this.state;

		if (data == null) {
			ErrorList.push("Model not defined.");
		}
		if (dataAppliedFor == null) {
			ErrorList.push("Applied For not defined.");
		}
		if (dataAppliedFor != null && data != null && (dataAppliedFor.LoanTypeId == null || dataAppliedFor.LoanTypeId == "")) {
			ErrorList.push("Loan type must be defined.");
			vModel.LoanTypeId = true;
		} else {
			vModel.LoanTypeId = false;
		}
		if (dataAppliedFor != null && (dataAppliedFor.LoanPurposeId == null || dataAppliedFor.LoanPurposeId == "")) {
			ErrorList.push("Loan purpose must be defined.");
			vModel.LoanPurposeId = true;
		} else {
			vModel.LoanPurposeId = false;
		}
		if (dataAppliedFor != null && (dataAppliedFor.RequestedAmount == null || dataAppliedFor.RequestedAmount == "")) {
			ErrorList.push("Requested amount must be defined.");
			vModel.RequestedAmount = true;
		} else {
			vModel.RequestedAmount = false;
		}

		if (data.StatusId == null || data.StatusId.toString().trim() == "") {
			ErrorList.push("Customer Status must be defined.");
			vModel.Status = true;
		} else {
			vModel.Status = false;
		}

		if (data.IntendedUseId == null || data.IntendedUseId.toString().trim() == "") {
			ErrorList.push("Intended Use must be defined.");
			vModel.IntendedUse = true;
		} else {
			vModel.IntendedUse = false;
		}

		if (data != null && (data.FirstName == null || data.FirstName == "")) {
			ErrorList.push("First name must be defined.");
			vModel.FirstName = true;
		} else {
			vModel.FirstName = false;
		}
		if (data != null && (data.LastName == null || data.LastName == "")) {
			ErrorList.push("Last name must be defined.");
			vModel.LastName = true;
		} else {
			vModel.LastName = false;
		}
		if (data != null && (data.BirthDate == null || data.BirthDate == "")) {
			ErrorList.push("Birth date must be defined.");
			vModel.BirthDate = true;
		} else {
			vModel.BirthDate = false;
		}
		if (data != null && (data.Email == null || data.Email == "")) {
			ErrorList.push("Email must be defined.");
			vModel.Email = true;
		} else {
			vModel.Email = false;
		}
		
		if (data.GenderId == null || data.GenderId.toString().trim() == "") {
			ErrorList.push("Gender must be defined.");
			vModel.GenderId = true;
		} else {
			vModel.GenderId = false;
		}

		if (data.OccupationId == null || data.OccupationId.toString().trim() == "") {
			ErrorList.push("Occupation must be defined.");
			vModel.Occupation = true;
		} else {
			vModel.Occupation = false;
		}

		if ((data.PriorOccupationId == null || data.PriorOccupationId.toString().trim() == "")
					&& this.retiredOrUnemployedOccupationIds.includes(data.OccupationId)) {
			ErrorList.push("Prior Occupation must be defined.");
			vModel.PriorOccupation = true;
		} else {
			vModel.PriorOccupation = false;
		}

		if (data != null && (data.MobilePhoneNumber != null || data.MobilePhoneCountryCodeId != null)) {
			if (data.MobilePhoneNumber != null && data.MobilePhoneNumber.toString().length != 10) {
				ErrorList.push("Mobile phone number must be 10 Digits.");
			}
		}

		if (data != null && (data.Address1 == null || data.Address1 == "")) {
			ErrorList.push("Address1 must be defined.");
			vModel.Address1 = true;
		} else {
			vModel.Address1 = false;
		}
		if (data != null && (data.ZipCode == null || data.ZipCode == "")) {
			ErrorList.push("Zip code must be defined.");
			vModel.ZipCode = true;
		} else {
			vModel.ZipCode = false;
		}
		if (data != null && (data.CityId == null || data.CityId == "")) {
			ErrorList.push("City must be defined.");
			vModel.CityId = true;
		} else {
			vModel.CityId = false;
		}
		if (data != null && (data.ProvinceId == null || data.ProvinceId == "")) {
			ErrorList.push("Province must be defined.");
			vModel.ProvinceId = true;
		} else {
			vModel.ProvinceId = false;
		}
		if (data != null && (data.CountryId == null || data.CountryId == "")) {
			ErrorList.push("Country must be defined.");
			vModel.CountryId = true;
		} else {
			vModel.CountryId = false;
		}

		if (data.IdentificationMethodId == null || data.IdentificationMethodId.toString().trim() == "") {
			ErrorList.push("Identification Method must be defined.");
			vModel.IdentificationMethodId = true;
		} else {
			vModel.IdentificationMethodId = false;
		}

		if ((data.IdentityTypeId == null || data.IdentityTypeId.toString().trim() == "")
				&& data.IdentificationMethodId == this.state.identificationMethodIds.find(x=>x.Value=="I").Id) {
			ErrorList.push("Identity type must be defined.");
			vModel.IdentityTypeId = true;
		} else {
			vModel.IdentityTypeId = false;
		}

		if ((data.PrimaryProvincialIdentityTypeId == null || data.PrimaryProvincialIdentityTypeId.toString().trim() == "")
				&& this.specificIdentityTypesIds.includes(data.IdentityTypeId)
				&& data.IdentificationMethodId == this.state.identificationMethodIds.find(x=>x.Value=="I").Id) {
			ErrorList.push("Provincial Id Type must be defined");
			vModel.ProvincialIdentityTypeId = true;
		} else {
			vModel.ProvincialIdentityTypeId = false;
		}

		if ((data.IdentityNumber == null || data.IdentityNumber.trim() == "")
				&& data.IdentificationMethodId == this.state.identificationMethodIds.find(x=>x.Value=="I").Id) {
			ErrorList.push("Identity number must be defined.");
			vModel.IdentityNumber = true;
		} else {
			vModel.IdentityNumber = false;
		}

		if ((data.IdentityExpireDate == null || data.IdentityExpireDate == "")
				&& data.IdentificationMethodId == this.state.identificationMethodIds.find(x=>x.Value=="I").Id) {
			ErrorList.push("Identity expire date must be defined.");
			vModel.IdentityExpireDate = true;
		} else {
			vModel.IdentityExpireDate = false;
		}

		if ((data.IdentityPlaceOfIssue == null || data.IdentityPlaceOfIssue == "")
				&& data.IdentificationMethodId == this.state.identificationMethodIds.find(x=>x.Value=="I").Id) {
			ErrorList.push("Identity place of issue must be defined.");
			vModel.IdentityPlaceOfIssue = true;
		} else {
			vModel.IdentityPlaceOfIssue = false;
		}

		if ((data.IdentityVerifiedBy == null || data.IdentityVerifiedBy.trim() == "")
				&& data.IdentificationMethodId == this.state.identificationMethodIds.find(x=>x.Value=="I").Id) {
			ErrorList.push("Verification person must be defined.");
			vModel.IdentityVerifiedBy = true;
		} else {
			vModel.IdentityVerifiedBy = false;
		}

		if ((data.IdentityVerificationDate == null || data.IdentityVerificationDate == "")
				&& data.IdentificationMethodId == this.state.identificationMethodIds.find(x=>x.Value=="I").Id) {
			ErrorList.push("Identity verification date must be defined.");
			vModel.IdentityVerificationDate = true;
		} else {
			vModel.IdentityVerificationDate = false;
		}

		if ((data.CreditAgencyId == null || data.CreditAgencyId.toString().trim() == "")
				&& (data.IdentificationMethodId == this.state.identificationMethodIds.find(x=>x.Value=="P").Id )) {
				ErrorList.push("Credit Agency must be defined.");
				vModel.CreditAgencyId = true;
		} else {
				vModel.CreditAgencyId = false;
		}

		if ((data.CreditFileNumber == null || data.CreditFileNumber.trim() == "")
				&& (data.IdentificationMethodId == this.state.identificationMethodIds.find(x=>x.Value=="P").Id )) {
				ErrorList.push("Credit File Number must be defined.");
				vModel.CreditFileNumber = true;
		} else {
				vModel.CreditFileNumber = false;
		}

		if ((data.CreditFileCompletionDate == null || data.CreditFileCompletionDate == "")
				&& (data.IdentificationMethodId == this.state.identificationMethodIds.find(x=>x.Value=="P").Id )) {
				ErrorList.push("Credit File Completion Date must be defined.");
				vModel.CreditFileCompletionDate = true;
		} else {
				vModel.CreditFileCompletionDate = false;
		}

		if ((data.PrimaryDocumentId == null || data.PrimaryDocumentId.toString().trim() == "")
				&& (data.IdentificationMethodId == this.state.identificationMethodIds.find(x=>x.Value=="D").Id )) {
				ErrorList.push("Primary Document must be defined.");
				vModel.PrimaryDocumentId = true;
		} else {
				vModel.PrimaryDocumentId = false;
		}

		if ((data.PrimaryDocumentVerificationDate == null || data.PrimaryDocumentVerificationDate == "")
				&& (data.IdentificationMethodId == this.state.identificationMethodIds.find(x=>x.Value=="D").Id )) {
				ErrorList.push("Verification Date must be defined.");
				vModel.PrimaryDocumentVerificationDate = true;
		} else {
				vModel.PrimaryDocumentVerificationDate = false;
		}

		if ((data.PrimaryDocumentNumber == null || data.PrimaryDocumentNumber.trim() == "")
				&& (data.IdentificationMethodId == this.state.identificationMethodIds.find(x=>x.Value=="D").Id )) {
				ErrorList.push("Document Number must be defined.");
				vModel.PrimaryDocumentNumber = true;
		} else {
				vModel.PrimaryDocumentNumber = false;
		}

		if ((data.PrimaryDocumentReviewedBy == null || data.PrimaryDocumentReviewedBy.trim() == "")
				&& (data.IdentificationMethodId == this.state.identificationMethodIds.find(x=>x.Value=="D").Id )) {
				ErrorList.push("Reviwed By must be defined.");
				vModel.PrimaryDocumentReviewedBy = true;
		} else {
				vModel.PrimaryDocumentReviewedBy = false;
		}

		if (data && !data.IsEmailValid) {
			ErrorList.push("The email address is not valid.");
			vModel.Email = true;
		} else {
			vModel.Email = false;
		}

		this.setState({ vModel });
		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please Fill Required Fields", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		// this.UpdateModel(LOSApplicantStatus.Completed, null, isCompleteApplicationClicked);

		// keep status Incomplete
		this.UpdateModel(LOSApplicantStatus.Saved, null, isCompleteApplicationClicked);
	}
	UpdateModel = (status, callback, isCompleteApplicationClicked) => {
		let data = this.state.model;
		data.ApplicantAppliedFor = this.state.appliedForData;
		if (data.ExternalAccount != null) {

			data.FinancialInstitutionId = data.ExternalAccount.FinancialInstitutionId;
			data.FinancialInstitutionBranchId = data.ExternalAccount.FinancialInstitutionBranchId;
			data.ExternalAccountNumber = data.ExternalAccount.AccountNumber;
		}

		if (data.Status == null || data.Status.ParameterValue == LOSApplicantStatus.Saved) {
			data.Status = {
				ParameterValue: status
			};
		}
		this
			.loadTaskPool
			.AppendTask(Proxy.POST("/losapi/v1.0/Applicant/InsertUpdate", data, responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (data.Id > 0) {
					this.ShowMessage("success", "Success", "Applicant Updated");

				} else {
					data.Id = responseData.Item.Id;
					data.TransUnionIdVerification = responseData.Item.TransUnionIdVerification;
					data.TransUnionIdVerificationStatus = responseData.Item.TransUnionIdVerificationStatus;
					data.InternalBlacklist = responseData.Item.InternalBlacklist;
					data.InternalBlacklistStatus = responseData.Item.InternalBlacklistStatus;
					data.WatchListVerification = responseData.Item.WatchListVerification;
					data.WatchListVerificationStatus = responseData.Item.WatchListVerificationStatus;
					data.IdVerification = responseData.Item.IdVerification;
					data.IdVerificationStatus = responseData.Item.IdVerificationStatus;
					this.GetCreditHistory(data.Id);
					if (this.props.IsPopUp == true) {
						this.setState({
							model: data
						}, () => this.ShowMessageNodeDisable("success", "Success", "Applicant Saved"));
					} else {
						this.setState({
							model: data
						}, () => this.ShowMessage("success", "Success", "Applicant Saved"));
					}
				}
				if (callback)
					callback(data.Id);

				if (isCompleteApplicationClicked) {
					RouteHelper.Push(this.props.history, "/CreateApplication", "ApplicantIdAndAddNewApplication", {
						ApplicantId: data.Id,
						isGenerateNew: true
					});
				}
			}, error => {
				this.ShowMessage("error", "Error", error);
			}));
	}
	DeleteModel = (id) => {
		if (id == null || id == 0) {
			this.ShowMessage("error", "Warning", "Applicant must be selected for delete operation.");
			return;
		}
		this
			.loadTaskPool
			.AppendTask(Proxy.POST("/losapi/v1.0/Applicant/Delete", {
				Id: id
			}, responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessage("success", "Success", "Applicant Deleted");
				this.HandleClickClearOk();
			}, error => {
				this.ShowMessage("error", "Error", error);
			}));
	}
	HandleSearch = () => {
		var model = this.state.model;
		model.Status = {
			ParameterValue: LOSApplicantStatus.Saved
		};
		this
			.loadTaskPool
			.AppendTask(Proxy.POST("/losapi/v1.0/Applicant/Search", model, responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				var modelList = responseData.Item || [];
				this.setState({ modelList });
			}, error => {
				this.ShowMessage("error", "Error", error);
			}));
		this.CreditValidationStatusNextCliecked();
	}

	ShowQuestionMessage = (message, id) => {
		this.setState({
			alert: AlertHelper.CreateQuestionAlert("Delete", message, "warning", e => {
				this.DeleteModel(id);
				this.HideAlert();
			}, () => {
				this.HideAlert();
			})
		});
	}

	HandleChange = (name, newValue, data) => {
		const model = {
			...this.state.model
		};

		if (name == "IdentityVerifiedPersonId" && ((model.IdentityVerifiedPersonId == null || model.IdentityVerifiedPersonId == 0) || (model.IdentityVerifiedPerson == null))) {
			model["IdentityVerifiedPersonId"] = ClientHelper
				.GetUser()
				.Id;
			model["IdentityVerifiedPerson"] = ClientHelper.GetUser();
		}

		if (name == "IdentityVerificationTypeId" && data != null) {
			model.CanadaPostVerificationEnable = true;
			model.InPersonIDVerificationEnable = false;
			if (data.ParameterValue != IdentificationMethod.CanadaPostVerification) {
				model.InPersonIDVerificationEnable = true;
				model.CanadaPostVerificationEnable = false;
			}
			model.OtherVerificationTypeEnable = data.ParameterValue == IdentificationMethod.Other;
		}

		if (name == "IdentityTypeId") {
			model.IdentityTypeIdStr = data && data.ParameterValue;
			model.IdentityNumber = null;
			model.IdentityTypeParameterValue2 = data && data.ParameterValue2;
			model.ProofOfResidenceId = null;
		}

		if (name === "Customer") {
			this.HandleCustomerSelect(newValue);
		}

		if (name === "WalletAccountId" && data != null) {
			model["CardFinancialId"] = data.CardFinancialId;
		}
		if (name == "Email") {
			model["IsEmailValid"] = data && data.IsValid;
		}

		if (name == "IdentificationMethodId") {						
			let dualMethodId = this.state.identificationMethodIds.find(x=>x.Value=="D").Id;
			let inPersonMethodId = this.state.identificationMethodIds.find(x=>x.Value=="I").Id;
			let creditFileMethodId = this.state.identificationMethodIds.find(x=>x.Value=="P").Id;	 

			switch(newValue) {
				case dualMethodId:
					{
						// delete InPerson method
						delete model.IdentityTypeId;
						delete model.PrimaryProvincialIdentityTypeId;
						delete model.IdentityPlaceOfIssue;
						delete model.IdentityNumber;
						delete model.IdentityExpireDate;
						delete model.IdentityVerifiedBy;
						delete model.IdentityVerificationDate;

						delete model.SecondaryIdentityTypeId;
						delete model.SecondaryProvincialIdentityTypeId;
						delete model.SecondaryIdentityPlaceOfIssue;
						delete model.SecondaryIdentityNumber;
						delete model.SecondaryIdentityExpireDate;
						delete model.SecondaryVerifiedBy;
						delete model.SecondaryVerificationDate;

						// delete Credit File method
						delete model.CreditAgencyId;
						delete model.CreditFileNumber;
						delete model.CreditFileCompletionDate;

						model.PrimaryDocumentReviewedBy = ClientHelper.GetUser().Email;
						model.PrimaryDocumentVerificationDate =  DateHelper.GetDateLocal();
						
						model.SecondaryDocumentReviewedBy = ClientHelper.GetUser().Email;
						model.SecondaryDocumentVerificationDate =  DateHelper.GetDateLocal();

						break;
					}
				case inPersonMethodId:
					{
						// delete Dual method
						delete model.PrimaryDocumentId;
						delete model.PrimaryDocumentVerificationDate;
						delete model.PrimaryDocumentNumber;
						delete model.PrimaryDocumentReviewedBy;

						delete model.SecondaryDocumentId;
						delete model.SecondaryDocumentVerificationDate;
						delete model.SecondaryDocumentNumber;
						delete model.SecondaryDocumentReviewedBy;

						// delete Credit File method
						delete model.CreditAgencyId;
						delete model.CreditFileNumber;
						delete model.CreditFileCompletionDate;

						model.IdentityVerifiedBy = ClientHelper.GetUser().Email;
						model.IdentityVerificationDate = DateHelper.GetDateLocal();

						model.SecondaryIdentityVerifiedBy = ClientHelper.GetUser().Email;
						model.SecondaryIdentityVerificationDate = DateHelper.GetDateLocal();

						break;
					}
				case creditFileMethodId:
					{
						// delete InPerson method
						delete model.IdentityTypeId;
						delete model.PrimaryProvincialIdentityTypeId;
						delete model.IdentityPlaceOfIssue;
						delete model.IdentityNumber;
						delete model.IdentityExpireDate;
						delete model.IdentityVerifiedBy;
						delete model.IdentityVerificationDate;

						delete model.SecondaryIdentityTypeId;
						delete model.SecondaryProvincialIdentityTypeId;
						delete model.SecondaryIdentityPlaceOfIssue;
						delete model.SecondaryIdentityNumber;
						delete model.SecondaryIdentityExpireDate;
						delete model.SecondaryVerifiedBy;
						delete model.SecondaryVerificationDate;

						// delete Dual method
						delete model.PrimaryDocumentId;
						delete model.PrimaryDocumentVerificationDate;
						delete model.PrimaryDocumentNumber;
						delete model.PrimaryDocumentReviewedBy;

						delete model.SecondaryDocumentId;
						delete model.SecondaryDocumentVerificationDate;
						delete model.SecondaryDocumentNumber;
						delete model.SecondaryDocumentReviewedBy;

						break;
					}
				case null:
					{
						// delete InPerson method
						delete model.IdentityTypeId;
						delete model.PrimaryProvincialIdentityTypeId;
						delete model.IdentityPlaceOfIssue;
						delete model.IdentityNumber;
						delete model.IdentityExpireDate;
						delete model.IdentityVerifiedBy;
						delete model.IdentityVerificationDate;

						delete model.SecondaryIdentityTypeId;
						delete model.SecondaryProvincialIdentityTypeId;
						delete model.SecondaryIdentityPlaceOfIssue;
						delete model.SecondaryIdentityNumber;
						delete model.SecondaryIdentityExpireDate;
						delete model.SecondaryIdentityVerifiedBy;
						delete model.SecondaryIdentityVerificationDate;

						// delete Dual method
						delete model.PrimaryDocumentId;
						delete model.PrimaryDocumentVerificationDate;
						delete model.PrimaryDocumentNumber;
						delete model.PrimaryDocumentReviewedBy;

						delete model.SecondaryDocumentId;
						delete model.SecondaryDocumentVerificationDate;
						delete model.SecondaryDocumentNumber;
						delete model.SecondaryDocumentReviewedBy;

						// delete Credit File method
						delete model.CreditAgencyId;
						delete model.CreditFileNumber;
						delete model.CreditFileCompletionDate;

						break;
					}
			}
			
		}

		/*if ((name == "IdentityVerifiedBy") && (this.IsNullOrEmpty(model.IdentityVerifiedBy))) {
			model["IdentityVerifiedBy"] = ClientHelper.GetUser();
		}	*/	

		model[name] = newValue;
		this.setState({ model });
	}
	HandleAppliedForInfoChanged = (name, newValue, data) => {
		const appliedForData = {
			...this.state.appliedForData
		};

		if (name == "LoanPurposeId") {
			this.state.IsActiveLoanPurposeDescription = (data != null && data.ParameterDesc === "Other");
		}
		if (!this.state.IsActiveLoanPurposeDescription) {
			appliedForData["LoanPurposeDescription"] = "";
		}
		if (name == "LoanTypeId") {
			appliedForData.LoanType = data;
		}
		appliedForData[name] = newValue;
		this.setState({ appliedForData });

	}
	GetFullName = () => {
		const model = {
			...this.state.model
		};
		let fullName = "";
		if (model.FirstName == null || model.FirstName == undefined) {
			return fullName;
		}
		fullName = model.FirstName;
		if (model.MiddleName != null && model.MiddleName !== "") {
			fullName += " " + model.MiddleName;
		}
		if (model.LastName != null && model.LastName !== "") {
			fullName += " " + model.LastName;
		}
		return fullName;
	}

	HandleCustomerSelect = (customer) => {
		const model = {
			...this.state.model
		};

		if (customer == null) {
			model.CustomerId = 0;
			model.CustomerNumber = "";
			model.AccountNumber = null;
			model.Email = null;
			this.setState({ model });
			return;
		}
		this
			.loadTaskPool
			.AppendTask(Proxy.POST("/losapi/v1.0/Applicant/GetAccountByCustomerId", {
				CustomerId: customer.Id
			}, responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				model.AccountNumber = responseData.Item != null
					? responseData.Item.AccountNumber
					: null;
				model.CustomerId = customer.Id;
				model.CustomerNumber = customer.CustomerNumber;
				model.Email = customer.Email;
				if (responseData.Item != null && responseData.Item.ClientCustomer != null && responseData.Item.ClientCustomer.CustomerName !== "") {

					model.FirstName = responseData.Item.ClientCustomer.Name;
					model.MiddleName = responseData.Item.ClientCustomer.MiddleName;
					model.LastName = responseData.Item.ClientCustomer.SurName;

				}

				this.setState({ model });
			}, error => {
				this.ShowMessage("error", "Error", error);
			}));
	}
	HandleClickClearOk = () => {
		this.HideAlert();
		let model = {
			InPersonIDVerificationEnable: false,
			ExternalAccount: {
				FinancialInstitutionId: 0,
				FinancialInstitutionBranchId: 0,
				AccountNumber: null
			}
		};
		var tempIdVerificationTypeList = Object.assign({}, this.state.IdVerificationTypeList);
		this.state = Object.assign({}, initialState);
		this.state.IdVerificationTypeList = Object.assign({}, tempIdVerificationTypeList);
		this.setState({ model: model, appliedForData: {}, modelList: [] });
	}

	HandleClear = () => {
		this.ShowConfirmMessage("warning", "Warning", "This will erase the information you entered on the screen. Are you sure you want" +
			" to quit this definition?",
			() => this.HandleClickClearOk());
	}
	ErrorCallback = (error) => {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", "An error occurred during the api visit" + error);
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert
				Title={title}
				Message={message}
				Type={type}
				OnConfirm={() => this.setState({ alert: "" })} />
		});
	}
	showBasicAlert = (title, message, type) => {
		this.setState({
			isLoading: false, alert: <GenericAlert
				Title={title}
				Message={message}
				Type={type}
				ShowCancel={false}
				OnConfirm={() => this.HideAlert()} />
		});
	}
	ShowMessageNodeDisable = (type, title, message) => {
		this.setState({
			alert: <GenericAlert
				Title={title}
				MessageNode={message}
				Type={type}
				OnConfirm={() => {
					this
						.props
						.ApplicantDefinitionDiologOpenOrClose();
					this.setState({ alert: "" });
				}} />
		});
	}
	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert
				Title={title}
				MessageNode={message}
				Type={type}
				OnConfirm={() => this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert
				Title={title}
				Message={message}
				Type={type}
				ShowCancel={true}
				OnCancel={() => this.setState({ alert: null })}
				OnConfirm={onConfirm} />
		});
	}

	HideAlert = () => {
		this.setState({ alert: null });
	}

	ShowMessageAndRefresh = (type, title, message) => {
		this.setState({
			alert: <GenericAlert
				Title={title}
				MessageNode={message}
				Type={type}
				OnConfirm={() => this.hideAlertAndRefresh()} />
		});
	}
	hideAlertAndRefresh = () => {
		this.setState({ alert: null, isLoading: false });
		window
			.location
			.reload();

	}
	SelectedRowChange = (e, index) => {
		const model = this.state.modelList[index];
		if (model.IdentityVerificationType != null && model.IdentityVerificationType.ParameterValue != IdentificationMethod.CanadaPostVerification) {
			model.InPersonIDVerificationEnable = true;
			model.OtherVerificationTypeEnable = model.IdentityVerificationType.ParameterValue == IdentificationMethod.Other;
		} else {
			model.InPersonIDVerificationEnable = false;
		}

		if (model.IdentityVerificationType != null && model.IdentityVerificationType.ParameterValue == IdentificationMethod.CanadaPostVerification) {
			model.CanadaPostVerificationEnable = true;
		}

		model.ExternalAccount = {
			FinancialInstitutionId: model.FinancialInstitutionId,
			FinancialInstitutionBranchId: model.FinancialInstitutionBranchId,
			AccountNumber: model.ExternalAccountNumber
		};
		let appliedForModel = {};
		this.state.IsActiveLoanPurposeDescription = false;
		if (model.ApplicantAppliedFor != null) {
			appliedForModel = model.ApplicantAppliedFor;
			if (appliedForModel.LoanPurpose != null && appliedForModel.LoanPurpose.ParameterDesc === "Other") {
				this.state.IsActiveLoanPurposeDescription = true;
			}
		}

		if (model.IdentityType != null) {
			model.IdentityTypeParameterValue2 = model.IdentityType.ParameterValue2;
		}

		model.IsEmailValid = true;

		model.BirthDate = model.BirthDate === null
			? null
			: Formatter.FormatDateUtc(model.BirthDate);

		this.setState({ model: model, appliedForData: appliedForModel });
		this.GetCreditHistory(model.Id);
		this.GetPanelActivationStatus(model.Id);
	}
	GetPanelActivationStatus(applicantId) {

		this
			.loadTaskPool
			.AppendTask(Proxy.POST("/losapi/v1.0/LOSPanelActivationStatus/GetByParameter", {
				DataId: applicantId,
				ScreenCode: MenuCodes.LOS_ApplicantDefinition
			}, (responseData) => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null)
					this.setState({
						panelActivationStatus: JSON.parse(responseData.Item.PanelInfo)
					});
			}
				, (error) => {
					this.ShowMessage("error", "An error occurred while requesting data", error.message);
				}));
	}
	AppliedForNextCliecked() {
		var ErrorList = [];
		let { vModel } = this.state;
		const data = this.state.model;
		if (data != null && data.Id != null && data.Id > 0) {
			const dataAppliedFor = this.state.appliedForData;
			if (dataAppliedFor.LoanTypeId == null || data.LoanTypeId == "") {
				ErrorList.push("Loan type must be defined.");
				vModel.LoanTypeId = true;
			} else {
				vModel.LoanTypeId = false;
			}
			if (dataAppliedFor.LoanPurposeId == null || data.LoanPurposeId == "") {
				ErrorList.push("Loan Purpose must be defined.");
				vModel.LoanPurposeId = true;
			} else {
				vModel.LoanPurposeId = false;
			}
			if (dataAppliedFor.RequestedPaymentFrequencyId == null || data.RequestedPaymentFrequencyId == "") {
				ErrorList.push("Requested payment frequency must be defined.");
				vModel.RequestedPaymentFrequencyId = true;
			} else {
				vModel.RequestedPaymentFrequencyId = false;
			}
			if (dataAppliedFor.RequestedAmount == null || data.RequestedAmount == "") {
				ErrorList.push("Requested amount must be defined.");
				vModel.RequestedAmount = true;
			} else {
				vModel.RequestedAmount = false;
			}
			if ((dataAppliedFor.LoanType != null && dataAppliedFor.LoanType.ParameterValue == ConstLoanType.SecureCard) && (dataAppliedFor.SecurityAmount == null || data.SecurityAmount == "")) {
				ErrorList.push("Security amount must be defined.");
				vModel.SecurityAmount = true;
			} else {
				vModel.SecurityAmount = false;
			}

			this.setState({ vModel });
			if (ErrorList.length > 0) {
				this.ShowMessageNode("warning", "Please Fill Required Fields", ErrorList.map((x, i) => <div key={i}>{x}</div>));
				return false;
			}
			this.UpdateModel(LOSApplicantStatus.Saved);
			var currentPanelActivation = {
				panelAppliedForActive: false,
				panelApplicantInformationActive: false,
				panelVerificationActive: false,
				panelCreditValidationStatusActive: true,
				panelApplicantListActive: false
			};
			this.setState({ panelActivationStatus: currentPanelActivation });
			this.InserUpdatePanelActivationStatus(currentPanelActivation);

		}
	}
	SavePanelAfterInsert(id) {
		var currentPanelActivation = {
			panelApplicantInformationActive: false,
			panelAppliedForActive: false,
			panelVerificationActive: true,
			panelCreditValidationStatusActive: false,
			panelApplicantListActive: false
		};
		this.setState({ panelActivationStatus: currentPanelActivation });
		this.InserUpdatePanelActivationStatus(currentPanelActivation, id);
	}
	ApplicantInformationNextCliecked() {
		var ErrorList = [];
		let { vModel } = this.state;
		const data = this.state.model;
	
		/* if (data.StatusId == null || data.StatusId.toString().trim() == "") {
			ErrorList.push("Customer Status must be defined.");
			vModel.Status = true;
		} else {
			vModel.Status = false;
		} */

		if (data.IntendedUseId == null || data.IntendedUseId.toString().trim() == "") {
			ErrorList.push("Intended Use must be defined.");
			vModel.IntendedUse = true;
		} else {
			vModel.IntendedUse = false;
		}

		if (data.FirstName == null || data.FirstName == "") {
			ErrorList.push("First name must be defined.");
			vModel.FirstName = true;
		} else {
			vModel.FirstName = false;
		}
		if (data.LastName == null || data.LastName == "") {
			ErrorList.push("Last name must be defined.");
			vModel.LastName = true;
		} else {
			vModel.LastName = false;
		}
		if (data.BirthDate == null || data.BirthDate == "") {
			ErrorList.push("Birth date must be defined.");
			vModel.BirthDate = true;
		} else {
			vModel.BirthDate = false;
		}

		if (data.GenderId == null || data.GenderId.toString().trim() == "") {
			ErrorList.push("Gender must be defined.");
			vModel.GenderId = true;
		} else {
			vModel.GenderId = false;
		}

		if (data.OccupationId == null || data.OccupationId.toString().trim() == "") {
			ErrorList.push("Occupation must be defined.");
			vModel.Occupation = true;
		} else {
			vModel.Occupation = false;
		}

		if ((data.PriorOccupationId == null || data.PriorOccupationId.toString().trim() == "")
					&& this.retiredOrUnemployedOccupationIds.includes(data.OccupationId)) {
			ErrorList.push("Prior Occupation must be defined.");
			vModel.PriorOccupation = true;
		} else {
			vModel.PriorOccupation = false;
		}

		if (data.Email == null || data.Email == "") {
			ErrorList.push("Email must be defined.");
			vModel.Email = true;
		} else {
			vModel.Email = false;
		}

		if (data.MobilePhoneNumber != null || data.MobilePhoneCountryCodeId != null) {
			if (data.MobilePhoneNumber != null && data.MobilePhoneNumber.toString().length != 10) {
				ErrorList.push("Mobile phone number must be 10 Digits.");
				vModel.MobilePhoneNumber = true;
			} else {
				vModel.MobilePhoneNumber = false;
			}
		}
		if (data.Address1 == null || data.Address1 == "") {
			ErrorList.push("Address1 must be defined.");
			vModel.Address1 = true;
		} else {
			vModel.Address1 = false;
		}
		if (data.ZipCode == null || data.ZipCode == "") {
			ErrorList.push("Zip code must be defined.");
			vModel.ZipCode = true;
		} else {
			vModel.ZipCode = false;
		}
		if (data.CityId == null || data.CityId == "") {
			ErrorList.push("City must be defined.");
			vModel.CityId = true;
		} else {
			vModel.CityId = false;
		}
		if (data.ProvinceId == null || data.ProvinceId == "") {
			ErrorList.push("Province must be defined.");
			vModel.ProvinceId = true;
		} else {
			vModel.ProvinceId = false;
		}
		if (data.CountryId == null || data.CountryId == "") {
			ErrorList.push("Country must be defined.");
			vModel.CountryId = true;
		} else {
			vModel.CountryId = false;
		}

		if (data.IdentificationMethodId == null || data.IdentificationMethodId.toString().trim() == "") {
			ErrorList.push("Identification Method must be defined.");
			vModel.IdentificationMethodId = true;
		} else {
			vModel.IdentificationMethodId = false;
		}

		if ((data.IdentityTypeId == null || data.IdentityTypeId.toString().trim() == "")
				&& data.IdentificationMethodId == this.state.identificationMethodIds.find(x=>x.Value=="I").Id) {
			ErrorList.push("Identity type must be defined.");
			vModel.IdentityTypeId = true;
		} else {
			vModel.IdentityTypeId = false;
		}

		if ((data.PrimaryProvincialIdentityTypeId == null || data.PrimaryProvincialIdentityTypeId.toString().trim() == "")
				&& this.specificIdentityTypesIds.includes(data.IdentityTypeId)
				&& data.IdentificationMethodId == this.state.identificationMethodIds.find(x=>x.Value=="I").Id) {
			ErrorList.push("Provincial Id Type must be defined");
			vModel.ProvincialIdentityTypeId = true;
		} else {
			vModel.ProvincialIdentityTypeId = false;
		}

		if ((data.IdentityNumber == null || data.IdentityNumber.trim() == "")
				&& data.IdentificationMethodId == this.state.identificationMethodIds.find(x=>x.Value=="I").Id) {
			ErrorList.push("Identity number must be defined.");
			vModel.IdentityNumber = true;
		} else {
			vModel.IdentityNumber = false;
		}

		if ((data.IdentityExpireDate == null || data.IdentityExpireDate == "")
				&& data.IdentificationMethodId == this.state.identificationMethodIds.find(x=>x.Value=="I").Id) {
			ErrorList.push("Identity expire date must be defined.");
			vModel.IdentityExpireDate = true;
		} else {
			vModel.IdentityExpireDate = false;
		}

		if ((data.IdentityPlaceOfIssue == null || data.IdentityPlaceOfIssue == "")
				&& data.IdentificationMethodId == this.state.identificationMethodIds.find(x=>x.Value=="I").Id) {
			ErrorList.push("Identity place of issue must be defined.");
			vModel.IdentityPlaceOfIssue = true;
		} else {
			vModel.IdentityPlaceOfIssue = false;
		}

		if ((data.IdentityVerifiedBy == null || data.IdentityVerifiedBy.trim() == "")
				&& data.IdentificationMethodId == this.state.identificationMethodIds.find(x=>x.Value=="I").Id) {
			ErrorList.push("Verification person must be defined.");
			vModel.IdentityVerifiedBy = true;
		} else {
			vModel.IdentityVerifiedBy = false;
		}

		if ((data.IdentityVerificationDate == null || data.IdentityVerificationDate == "")
				&& data.IdentificationMethodId == this.state.identificationMethodIds.find(x=>x.Value=="I").Id) {
			ErrorList.push("Identity verification date must be defined.");
			vModel.IdentityVerificationDate = true;
		} else {
			vModel.IdentityVerificationDate = false;
		}
		
		if ((data.CreditAgencyId == null || data.CreditAgencyId.toString().trim() == "")
				&& (data.IdentificationMethodId == this.state.identificationMethodIds.find(x=>x.Value=="P").Id )) {
				ErrorList.push("Credit Agency must be defined.");
				vModel.CreditAgencyId = true;
		} else {
				vModel.CreditAgencyId = false;
		}

		if ((data.CreditFileNumber == null || data.CreditFileNumber.trim() == "")
				&& (data.IdentificationMethodId == this.state.identificationMethodIds.find(x=>x.Value=="P").Id )) {
				ErrorList.push("Credit File Number must be defined.");
				vModel.CreditFileNumber = true;
		} else {
				vModel.CreditFileNumber = false;
		}

		if ((data.CreditFileCompletionDate == null || data.CreditFileCompletionDate == "")
				&& (data.IdentificationMethodId == this.state.identificationMethodIds.find(x=>x.Value=="P").Id )) {
				ErrorList.push("Credit File Completion Date must be defined.");
				vModel.CreditFileCompletionDate = true;
		} else {
				vModel.CreditFileCompletionDate = false;
		}

		if ((data.PrimaryDocumentId == null || data.PrimaryDocumentId.toString().trim() == "")
				&& (data.IdentificationMethodId == this.state.identificationMethodIds.find(x=>x.Value=="D").Id )) {
				ErrorList.push("Primary Document must be defined.");
				vModel.PrimaryDocumentId = true;
		} else {
				vModel.PrimaryDocumentId = false;
		}

		if ((data.PrimaryDocumentVerificationDate == null || data.PrimaryDocumentVerificationDate == "")
				&& (data.IdentificationMethodId == this.state.identificationMethodIds.find(x=>x.Value=="D").Id )) {
				ErrorList.push("Verification Date must be defined.");
				vModel.PrimaryDocumentVerificationDate = true;
		} else {
				vModel.PrimaryDocumentVerificationDate = false;
		}

		if ((data.PrimaryDocumentNumber == null || data.PrimaryDocumentNumber.trim() == "")
				&& (data.IdentificationMethodId == this.state.identificationMethodIds.find(x=>x.Value=="D").Id )) {
				ErrorList.push("Document Number must be defined.");
				vModel.PrimaryDocumentNumber = true;
		} else {
				vModel.PrimaryDocumentNumber = false;
		}

		if ((data.PrimaryDocumentReviewedBy == null || data.PrimaryDocumentReviewedBy.trim() == "")
				&& (data.IdentificationMethodId == this.state.identificationMethodIds.find(x=>x.Value=="D").Id )) {
				ErrorList.push("Reviwed By must be defined.");
				vModel.PrimaryDocumentReviewedBy = true;
		} else {
				vModel.PrimaryDocumentReviewedBy = false;
		}

		/*vModel.IdentityCountryId = false;
		if (data.IdentityCountryId == null || data.IdentityCountryId == "") {
			ErrorList.push("Identity country must be defined.");
			vModel.IdentityCountryId = true;
		} */

		/*if (data.IdentityTypeId == null) {
			ErrorList.push("Identity type must be valid.");
			vModel.IdentityTypeId = true;
		} else {
			vModel.IdentityTypeId = false;
		} */

		/*vModel.IdentityProvinceId = false;
		if (data.IdentityProvinceId == null || data.IdentityProvinceId == "") {
			ErrorList.push("Identity province must be defined.");
			vModel.IdentityProvinceId = true;
		} */

		if (data && !data.IsEmailValid) {
			ErrorList.push("The email address is not valid.");
			vModel.Email = true;
		} else {
			vModel.Email = false;
		}

		this.setState({ vModel });
		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please Fill Required Fields", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		this.UpdateModel(LOSApplicantStatus.Saved, this.SavePanelAfterInsert);

	}
	VerificationActiveNextCliecked() {
		var currentPanelActivation = {
			panelAppliedForActive: true,
			panelApplicantInformationActive: false,
			panelVerificationActive: false,
			panelCreditValidationStatusActive: false,
			panelApplicantListActive: false
		};
		this.setState({ panelActivationStatus: currentPanelActivation });
		this.InserUpdatePanelActivationStatus(currentPanelActivation);
		this.UpdateModel(LOSApplicantStatus.Saved);

	}
	CreditValidationStatusNextCliecked(isCompleteApplicationClicked) {
		var currentPanelActivation = {
			panelAppliedForActive: false,
			panelApplicantInformationActive: false,
			panelVerificationActive: false,
			panelCreditValidationStatusActive: false,
			panelApplicantListActive: true
		};
		this.setState({ panelActivationStatus: currentPanelActivation });
		this.InserUpdatePanelActivationStatus(currentPanelActivation, null, isCompleteApplicationClicked);

	}
	HandleChangeFilterPanel(name, expanded) {
		var currentPanelActivation = Object.assign({}, initialState.panelActivationStatus);
		currentPanelActivation.panelApplicantInformationActive = false;
		currentPanelActivation[name] = expanded
			? true
			: false;

		this.setState({ panelActivationStatus: currentPanelActivation });

		this.InserUpdatePanelActivationStatus(currentPanelActivation);
	}
	InserUpdatePanelActivationStatus(PanelInfo, id, isInquiryClicked) {
		if ((this.state.model == null || this.state.model.Id == null || this.state.model.Id == 0) && id == undefined)
			return;
		this
			.loadTaskPool
			.AppendTask(Proxy.POST("/losapi/v1.0/LOSPanelActivationStatus/InsertUpdate", {
				DataId: id == undefined
					? this.state.model.Id
					: id,
				ScreenCode: MenuCodes.LOS_ApplicantDefinition,
				PanelInfo: JSON.stringify(PanelInfo)
			}, (responseData) => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
				}
				this.HandleOperationCheckAfterPanelStatus(isInquiryClicked);

			}, (error) => {
				this.ShowMessage("error", "An error occurred while requesting data", error.message);
			}));

	}
	HandleOperationCheckAfterPanelStatus(isInquiryClicked) {
		var model = this.state.model;
		var creditHistoryModel = this.state.creditHistoryModel;
		if (model != null && model.Id > 0 && (creditHistoryModel == null || creditHistoryModel.ApplicantId == null || creditHistoryModel.ApplicantId == 0)) {
			if (isInquiryClicked) {
				this.OperationCheck(isInquiryClicked);
			}
		} else if (model != null && model.Id > 0 && creditHistoryModel != null && isInquiryClicked) {
			this.OperationCheck(true);

		}
	}
	GetUserName() {
		var userJson = localStorage.getItem("user");
		var user = userJson
			? JSON.parse(userJson)
			: null;

		if (!user || !user.SessionId)
			return null;
		return user.UserName;
	}
	HandleCanadaPostCode = (type) => {
		const model = this.state.model;
		const { MenuCode } = this.props;

		if (type === "E" && model.CanadaPostVerificationId != null && model.CanadaPostVerificationId != "") {
			const { ExecuteApiPost } = this.props;
			ExecuteApiPost("/amlkycapi/v1.0/CanadaPostVerification/SendEmail", {
				Id: model.CanadaPostVerificationId,
				MenuCode: MenuCode,
				EmailAddress: model.Email
			}, {
				Id: model.CanadaPostVerificationId,
				EmailAddress: model.Email
			}, null, null, null, null)
				? this.ShowMessage("success", "Success", "Email Sent")
				: this.ShowMessage("error", "Error", "Email Not Sent");

		} else if (type === "D" && model.CanadaPostVerificationId != null && model.CanadaPostVerificationId != "") {
			const { ExecuteApiFileDownloadWithGenericResponse } = this.props;
			ExecuteApiFileDownloadWithGenericResponse("/amlkycapi/v1.0/CanadaPostVerification/Print", {
				Id: model.CanadaPostVerificationId,
				MenuCode: MenuCode
			}, "Canada_Post_Verification_Barcode", "pdf");

		}

	}
	SendEmailForInPersonIdVerificationStatus = () => {
		const model = this.state.model;
		this.setState({ isLoading: true });

		Proxy.POST(`${ApplicantApiUrl}SendEmailInPersonIdVerificationStatus`, model, responseData => {
			this.setState({ isLoading: false });
			if (!responseData.IsSucceeded) {
				this.ShowMessage("error", "Error", responseData.ErrorDescription);
				return;
			}
			if (responseData.Item != null) {
				this.ShowMessage("success", "Success", "Email Sent");
				model.IdVerificationStatus = true;
				this.setState({ model });
			}
		}, error => {
			this.setState({ isLoading: false });
			this.ShowMessage("error", "Error", error);
		});
	}
	SetTrProps(state, rowInfo) {
		// const RowSelected  = this.SelectedRowChange();

		if (rowInfo && rowInfo.row) {
			return {
				onClick: () => {
					// if (RowSelected)
					this.SelectedRowChange(0, rowInfo.index);
				},
				style: {
					background: rowInfo.original && rowInfo.original.Status && rowInfo.original.Status.ParameterValue !== "1"
						? "#d50000"
						: "",
					cursor: "pointer"
				}
			};
		} else {
			return {};
		}
	}
	async SaveCanadaPostRequest() {
		let model = this.state.model;
		let { vModel } = this.state;

		if (model.IdentityTypeParameterValue2 == IdentityType.OnePoR && !model.ProofOfResidenceId) {
			this.ShowMessage("warning", "Warning", "Proof of residence must be defined.");
			vModel.ProofOfResidenceId = true;
			this.setState({ vModel });

			return;
		}

		vModel.ProofOfResidenceId = false;
		this.setState({ vModel });

		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost(`${ApplicantApiUrl}SaveCanadaPostAddressVerificationRequest`, {
			Id: model.Id,
			ProofOfResidenceId: model.ProofOfResidenceId
		}, null, null, null, null, null);
		if (result == null || result == "") {
			model.AddressVerificationStatus = false;
			model.AddressVerification = "Invalid";
		} else {
			model.CanadaPostVerificationId = result;
			model.AddressVerificationStatus = null;
			model.AddressVerification = "Pending";
		}
		this.setState({ model });
	}

	render() {
		const {
			model,
			vModel,
			appliedForData,
			alert,
			isLoading,
			modelList,
			IsActiveLoanPurposeDescription,
			IdVerificationTypeList,
			RequestedLengthOfTermSource

		} = this.state;
		const { MenuCode, IsPopUp, ApprovalDisabled, ApplicantId } = this.props;
		var ForInsert = !model.Id || model.Id <= 0;
		var UniqueClientId = ClientHelper.GetClientRowId();
		var IsVerificationTypeActive = (model.InternalBlacklistStatus != null && model.InternalBlacklistStatus == true) && (model.WatchListVerificationStatus != null && model.WatchListVerificationStatus == true) && (model.TransUnionIdVerificationStatus == null || model.TransUnionIdVerificationStatus == false);
		var IsNextPassive = model.IdVerificationStatus == null || model.IdVerificationStatus == false;

		return (
			<div>
				<LoadingComponent Show={isLoading} TaskPoolDomain={this.loadTaskPool} /> {ApprovalDisabled == true || ApplicantId != null
					? ""
					: <ButtonToolbar
						ButtonList={[
							{
								Code: "Search",
								OnClick: this.HandleSearch,
								Disabled: !ForInsert
							}, {
								Code: "Submit",
								OnClick: this.OperationCheck,
								Disabled: true || !ForInsert
							}, {
								Code: "Update",
								OnClick: this.OperationCheck,
								Disabled: true || ForInsert
							}, {
								Code: "Clear",
								OnClick: this.HandleClear,
								Disabled: false
							}
						]}
						menuId={this.props.menuId}
						ApprovalData={this.props.ApprovalData}
						After={this.props.After}
						IsPopUp={IsPopUp} />
				}
				<GridContainer>
					{alert}
					<GridItem xs={12}>

						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel
									IsActive={this.state.panelActivationStatus.panelApplicantInformationActive}
									Name={"panelApplicantInformationActive"}
									Title={"Applicant Information"}
									ValueChanged={this.HandleChangeFilterPanel}>
									<GridContainer direction="row" justify="flex-start" alignItems="stretch">
										<GridItem xs={12}>
											<GridContainer direction="row" justify="flex-start" alignItems="stretch">

												<GridItem xs={12}>

													<GridContainer direction="row" justify="flex-start" alignItems="flex-start">
														<GridItem xs={6}>

															<GenericTitle text={"General Information"} />
															<div>
																<GridItem>
																	<GenericSelectInput
																		Name="StatusId"
																		LabelText="Applicant Status"
																		Method="POST"
																		Url="/coreapi/v1.0/Parameter/Search"
																		Parameter={this.ParameterApplicantStatus}
																		DataRoot="Item"
																		KeyValueMember="Id"
																		TextValueMember="ParameterDesc"
																		Value={model.StatusId}
																		ValueChanged={this.HandleChange}
																		Disabled />
																</GridItem>
																<GridItem>
																	<GenericSelectInput
																		Name="IntendedUseId"
																		LabelText="Intended Use"
																		Method="POST"
																		Url="/coreapi/v1.0/Parameter/Search"
																		Parameter={this.ParameterIntendedUse}
																		DataRoot="Item"
																		KeyValueMember="Id"
																		TextValueMember="ParameterDesc"
																		Value={model.IntendedUseId}
																		ValueChanged={this.HandleChange}
																		Disabled={ApprovalDisabled || (model.CustomerNumber != null && model.CustomerNumber != "")}
																		Required
																		IsInvalid={vModel.IntendedUse}
																		RenderItem={d => `${d.ParameterValue} (${d.ParameterDesc})`}/>
																</GridItem>
																<GridItem>
																	<GenericTextInput
																		Name="FirstName"
																		inputProps={this.ParameterMaxLength30}
																		LabelText="First Name"
																		Value={model.FirstName}
																		ValueChanged={this.HandleChange}
																		Disabled={ApprovalDisabled || (model.CustomerNumber != null && model.CustomerNumber != "")}
																		Required
																		IsInvalid={vModel.FirstName} />
																</GridItem>
																<GridItem>
																	<GenericTextInput
																		Name="MiddleName"
																		inputProps={this.ParameterMaxLength30}
																		LabelText="Middle Name"
																		Value={model.MiddleName}
																		ValueChanged={this.HandleChange}
																		Disabled={ApprovalDisabled || (model.CustomerNumber != null && model.CustomerNumber != "")} />
																</GridItem>
																<GridItem>
																	<GenericTextInput
																		Name="LastName"
																		inputProps={this.ParameterMaxLength30}
																		LabelText="Last Name"
																		Value={model.LastName}
																		ValueChanged={this.HandleChange}
																		Disabled={ApprovalDisabled || (model.CustomerNumber != null && model.CustomerNumber != "")}
																		Required
																		IsInvalid={vModel.LastName} />
																</GridItem>
																<GridItem>
																	<GenericDateInput
																		Utc
																		Name="BirthDate"
																		LabelText="Date of Birth"
																		Value={model.BirthDate}
																		ValueChanged={this.HandleChange}
																		IncludeTime={false}
																		MaxDate={moment().subtract((18 * 365), "days")}
																		Disabled={ApprovalDisabled}
																		Required
																		IsInvalid={vModel.BirthDate} />
																</GridItem>
																<GridItem>
																	<GenericSelectInput
																		Name="GenderId"
																		LabelText="Gender"
																		Method="POST"
																		Url="/coreapi/v1.0/Parameter/Search"
																		Parameter={{
																			ParameterCode: "Gender",
																			ParameterValue: "",
																			ParameterDesc: ""
																		}}
																		DataRoot="Item"
																		KeyValueMember="Id"
																		TextValueMember="ParameterDesc"
																		Value={model.GenderId}
																		ValueChanged={this.HandleChange}
																		Disabled={ApprovalDisabled}
																		Required
																		IsInvalid={vModel.GenderId} />
																</GridItem>																
																
																<GridItem>
																	<GenericSelectInput
																		Name="OccupationId"
																		LabelText="Occupation"
																		Method="POST"
																		Url="/coreapi/v1.0/Parameter/Search"
																		Parameter={this.ParameterOccupation}
																		DataRoot="Item"
																		Required
																		KeyValueMember="Id"
																		TextValueMember="ParameterDesc"
																		Sorted={this.sortedParameterDesc}
																		IsInvalid={vModel.Occupation}
																		Value={model.OccupationId}
																		ValueChanged={this.HandleChange}
																		Disabled={ApprovalDisabled} />
																</GridItem>
																{this.retiredOrUnemployedOccupationIds.includes(model.OccupationId) 
																	&&							
																	<GridItem>
																		<GenericSelectInput
																			Name="PriorOccupationId"
																			LabelText="Prior Occupation"
																			Method="POST"
																			Url="/coreapi/v1.0/Parameter/Search"
																			Parameter={this.ParameterOccupation}
																			DataRoot="Item"
																			Required = {this.retiredOrUnemployedOccupationIds.includes(model.OccupationId)}
																			KeyValueMember="Id"
																			TextValueMember="ParameterDesc"
																			Sorted={this.sortedParameterDesc}
																			IsInvalid={vModel.PriorOccupation}
																			Value={model.PriorOccupationId}
																			ValueChanged={this.HandleChange}
																			Disabled={ApprovalDisabled 
																				|| !this.retiredOrUnemployedOccupationIds.includes(model.OccupationId)} 
																		/>
																	</GridItem>
																}
																<GridItem>
																	<CustomerComponent
																		CustomerNumber={model.CustomerNumber}
																		CustomerName={this.GetFullName()}
																		HandleChange={this.HandleChange}
																		FillCallback={this.HandleCustomerSelect}
																		Disabled={UniqueClientId == null}
																		ShowCustomerProgram={true}
																		Programs={[ProgramCodes.Bank_Programs_CDIC]}
																		UniqueClientId={UniqueClientId} />
																</GridItem>
															</div>

														</GridItem>
														<GridItem xs={6}>		

															<GenericTitle text={"ID Verification"} />
															<div>

																<GridItem>												
																	<GenericSelectInput
																		Name="IdentificationMethodId"
																		LabelText="Identification Method"
																		Method="POST"
																		Url="/coreapi/v1.0/Parameter/Search"
																		Parameter={this.parameterIdentificationMethod}
																		DataRoot="Item"
																		KeyValueMember="Id"
																		TextValueMember="ParameterDesc"
																		Value={model.IdentificationMethodId}
																		ValueChanged={this.HandleChange}
																		Disabled={ApprovalDisabled}
																		Required
																		IsInvalid={vModel.IdentificationMethodId} 
																	/>
																</GridItem>
																{
																	(this.state.identificationMethodIds.length > 0) && 
																	(this.state.identificationMethodIds.find(x=>x.Value=="I").Id == model.IdentificationMethodId) 
																	&&
																	<GridItem>																												
																		<GridItem>
																			<br/>
																			<b>Primary Identification</b>
																		</GridItem>													
																		<GridItem>																		 	
																			<GenericSelectInput
																				Name="IdentityTypeId"
																				LabelText="ID Type"
																				Method="POST"
																				//Url="/losapi/v1.0/Applicant/GetApplicantIdType"
																				Url="/coreapi/v1.0/Parameter/Search"
																				Parameter={this.ParameterIdentityType}
																				DataRoot="Item"
																				KeyValueMember="Id"
																				TextValueMember="ParameterDesc"
																				Value={model.IdentityTypeId}
																				ValueChanged={this.HandleChange}
																				Disabled={ApprovalDisabled}
																				Required={model.IdentificationMethodId == this.state.identificationMethodIds.find(x=>x.Value=="I").Id}
																				IsInvalid={vModel.IdentityTypeId}
																				Sorted={this.sortedParameterDesc} />
																		</GridItem>
																		{this.specificIdentityTypesIds.includes(model.IdentityTypeId) &&
																			<GridItem>
																				<GenericSelectInput
																					Name="PrimaryProvincialIdentityTypeId"
																					LabelText="Provincial Id Type"
																					Method="POST"
																					Url="/coreapi/v1.0/Parameter/Search"
																					Parameter={this.ParameterProvincialGovermentPhotoId}
																					DataRoot="Item"
																					KeyValueMember="Id"
																					TextValueMember="ParameterDesc"
																					Sorted={this.sortedParameterDesc}
																					Value={model.PrimaryProvincialIdentityTypeId}
																					ValueChanged={this.HandleChange}
																					Disabled={ApprovalDisabled}
																					Required={model.IdentificationMethodId == this.state.identificationMethodIds.find(x=>x.Value=="I").Id
																									&& this.specificIdentityTypesIds.includes(model.IdentityTypeId)}
																					IsInvalid={vModel.PrimaryProvincialIdentityTypeId} />																	
																			</GridItem>
																		}
																		<GridItem>
																			<GenericTextInput
																				Name="IdentityPlaceOfIssue"
																				LabelText="ID Place of Issue"
																				Value={model.IdentityPlaceOfIssue}
																				ValueChanged={this.HandleChange}
																				Disabled={ApprovalDisabled}
																				Required={model.IdentificationMethodId == this.state.identificationMethodIds.find(x=>x.Value=="I").Id}
																				IsInvalid={vModel.IdentityPlaceOfIssue} />
																		</GridItem>
																		<GridItem>
																			<GenericTextInput
																				Name="IdentityNumber"
																				LabelText="ID Number"
																				Value={model.IdentityNumber}
																				ValueChanged={this.HandleChange}
																				Disabled={ApprovalDisabled}
																				Required={model.IdentificationMethodId == this.state.identificationMethodIds.find(x=>x.Value=="I").Id}
																				IsInvalid={vModel.IdentityNumber}
																				inputProps={this.maxLength20} />
																		</GridItem>
																		<GridItem>
																			<GenericDateInput
																				Utc
																				Name="IdentityExpireDate"
																				LabelText="ID Exp Date"
																				MinDate={DateHelper.GetDate()}
																				Value={model.IdentityExpireDate
																					? Formatter.FormatDateUtc(model.IdentityExpireDate)
																					: null}
																				ValueChanged={this.HandleChange}
																				Disabled={ApprovalDisabled}
																				Required={model.IdentificationMethodId == this.state.identificationMethodIds.find(x=>x.Value=="I").Id}
																				IsInvalid={vModel.IdentityExpireDate}
																				IncludeTime={false} />
																		</GridItem>
																		<GridItem>
																			<GenericTextInput
																				Name="IdentityVerifiedBy"
																				LabelText="Verified By"
																				Value = {model.IdentityVerifiedBy} 
																				ValueChanged={this.HandleChange}
																				Disabled={ApprovalDisabled}
																				Required={(model.IdentificationMethodId == this.state.identificationMethodIds.find(x=>x.Value=="I").Id)}
																				IsInvalid={vModel.IdentityVerifiedBy}
																				inputProps={this.maxLength20} />
																		</GridItem>
																		<GridItem>
																			<GenericDateInput
																				Name="IdentityVerificationDate"
																				LabelText="Verification Date"
																				Value={model.IdentityVerificationDate 
																						? DateHelper.ToDateInputValue(model.IdentityVerificationDate) 
																						: ""}
																				ValueChanged={this.HandleChange}
																				Disabled={ApprovalDisabled}
																				Required={(model.IdentificationMethodId == this.state.identificationMethodIds.find(x=>x.Value=="I").Id)}
																				IsInvalid={vModel.IdentityVerificationDate}
																			/>
																		</GridItem>																																								
																		<GridItem xs={5}>
																			<br/>
																			<b>Secondary Identification</b>
																		</GridItem>
																		<GridItem>
																			<GenericSelectInput
																				Name="SecondaryIdentityTypeId"
																				LabelText="Identification Type 2"
																				Method="POST"
																				Url="/coreapi/v1.0/Parameter/Search"
																				Parameter={this.ParameterIdentitySecondary}
																				DataRoot="Item"
																				KeyValueMember="Id"
																				TextValueMember="ParameterDesc"
																				Sorted={this.sortedParameterDesc}
																				Value={model.SecondaryIdentityTypeId}
																				ValueChanged={this.HandleChange}
																				Disabled={ApprovalDisabled}/>										
																		</GridItem>
																		{this.specificIdentityTypesIds.includes(model.SecondaryIdentityTypeId) &&
																			<GridItem>
																				<GenericSelectInput
																					Name="SecondaryProvincialIdentityTypeId"
																					LabelText="Provincial Id Type"
																					Method="POST"
																					Url="/coreapi/v1.0/Parameter/Search"
																					Parameter={this.ParameterProvincialGovermentPhotoId}
																					DataRoot="Item"
																					KeyValueMember="Id"
																					TextValueMember="ParameterDesc"
																					Sorted={this.sortedParameterDesc}
																					Value={model.SecondaryProvincialIdentityTypeId}
																					ValueChanged={this.HandleChange}
																					Disabled={ApprovalDisabled} />																	
																			</GridItem>
																		}
																		<GridItem>
																			<GenericTextInput
																				Name="SecondaryIdentityPlaceOfIssue"
																				LabelText="ID Place of Issue"
																				Value={model.SecondaryIdentityPlaceOfIssue}
																				ValueChanged={this.HandleChange}
																				Disabled={ApprovalDisabled}									
																				inputProps={this.maxLength30} />
																		</GridItem>
																		<GridItem>
																			<GenericTextInput
																				Name="SecondaryIdentityNumber"
																				LabelText="ID No."
																				Value={model.SecondaryIdentityNumber}
																				ValueChanged={this.HandleChange}
																				Disabled={ApprovalDisabled}									
																				inputProps={this.maxLength20} />
																		</GridItem>	
																		<GridItem>
																			<GenericDateInput
																				Name="SecondaryIdentityExpireDate"
																				LabelText="ID Exp Date"
																				Value={model.SecondaryIdentityExpireDate 
																						? DateHelper.ToDateInputValue(model.SecondaryIdentityExpireDate) 
																						: ""}
																				ValueChanged={this.HandleChange}
																				IsFuture={true}
																				Disabled={ApprovalDisabled} />
																		</GridItem>		
																		<GridItem>
																			<GenericTextInput
																				Name="SecondaryIdentityVerifiedBy"
																				LabelText="Verified By"
																				Value={model.SecondaryIdentityVerifiedBy}
																				ValueChanged={this.HandleChange}
																				Disabled={ApprovalDisabled}
																				inputProps={this.maxLength20} />
																		</GridItem>	
																		<GridItem>
																			<GenericDateInput
																				Name="SecondaryIdentityVerificationDate"
																				LabelText="Verification Date"
																				Value={model.SecondaryIdentityVerificationDate 
																						? DateHelper.ToDateInputValue(model.SecondaryIdentityVerificationDate) 
																						: ""}
																				ValueChanged={this.HandleChange}
																				IsFuture={true}
																				Disabled={ApprovalDisabled} />
																		</GridItem>			

																	</GridItem>											
																}

																{	(this.state.identificationMethodIds.length > 0) && 
																	(this.state.identificationMethodIds.find(x=>x.Value=="P").Id == model.IdentificationMethodId) &&
																	<GridItem>
																		<GridItem xs={5}>
																			<br />
																			<b>Credit File Information</b>
																		</GridItem>
																		<GridItem>
																			<GenericSelectInput
																				Name="CreditAgencyId"
																				LabelText="Credit Agency"
																				Method="POST"
																				Url="/coreapi/v1.0/Parameter/Search"
																				Parameter={this.ParameterCreditAgency}
																				DataRoot="Item"
																				KeyValueMember="Id"
																				TextValueMember="ParameterDesc"
																				Sorted={this.sortedParameterDesc}
																				Required={model.IdentificationMethodId == this.state.identificationMethodIds.find(x=>x.Value=="P").Id}
																				Value={model.CreditAgencyId}
																				ValueChanged={this.HandleChange}
																				IsInvalid={vModel.CreditAgencyId}
																				Disabled={ApprovalDisabled}/>										
																		</GridItem>
																		<GridItem>
																			<GenericTextInput
																				Name="CreditFileNumber"
																				LabelText="Credit File No"
																				Required={model.IdentificationMethodId == this.state.identificationMethodIds.find(x=>x.Value=="P").Id}
																				Value={model.CreditFileNumber}
																				ValueChanged={this.HandleChange}
																				Disabled={ApprovalDisabled}
																				IsInvalid={vModel.CreditFileNumber}
																				inputProps={this.maxLength20} />
																		</GridItem>
																		<GridItem>
																			<GenericDateInput
																				Name="CreditFileCompletionDate"
																				LabelText="Completion Date"
																				Value={model.CreditFileCompletionDate 
																						? DateHelper.ToDateInputValue(model.CreditFileCompletionDate) 
																						: ""}
																				Required={model.IdentificationMethodId == this.state.identificationMethodIds.find(x=>x.Value=="P").Id}
																				ValueChanged={this.HandleChange}
																				Disabled={ApprovalDisabled}
																				IsInvalid={vModel.CreditFileCompletionDate} />
																		</GridItem>
																	</GridItem>
																}

																{	 (this.state.identificationMethodIds.length > 0) &&
																	(this.state.identificationMethodIds.find(x=>x.Value=="D").Id == model.IdentificationMethodId) &&
																	<GridItem>
																		<GridItem xs={5}>
																			<br/>
																			<b>Primary Document</b>
																		</GridItem>
																		<GridItem>
																			<GenericSelectInput
																				Name="PrimaryDocumentId"
																				LabelText="Document"
																				Method="POST"
																				Url="/coreapi/v1.0/Parameter/Search"
																				Parameter={this.ParameterPrimarySourceForDualProcessId}
																				DataRoot="Item"
																				KeyValueMember="Id"
																				TextValueMember="ParameterDesc"
																				Required={(model.IdentificationMethodId == this.state.identificationMethodIds.find(x=>x.Value=="D").Id)}
																				Sorted={this.sortedParameterDesc}
																				Value={model.PrimaryDocumentId}
																				ValueChanged={this.HandleChange}
																				IsInvalid={vModel.PrimaryDocumentId}
																				Disabled={ApprovalDisabled}/>										
																		</GridItem>
																		<GridItem>
																			<GenericDateInput
																				Name="PrimaryDocumentVerificationDate"
																				LabelText="Verification Date"
																				Value={model.PrimaryDocumentVerificationDate 
																					? DateHelper.ToDateInputValue(model.PrimaryDocumentVerificationDate) 
																					: ""}
																				ValueChanged={this.HandleChange}
																				Required={(model.IdentificationMethodId == this.state.identificationMethodIds.find(x=>x.Value=="D").Id)}
																				Disabled={ApprovalDisabled}
																				IsInvalid={vModel.PrimaryDocumentVerificationDate} />
																		</GridItem>
																		<GridItem>
																			<GenericTextInput
																				Name="PrimaryDocumentNumber"
																				LabelText="Associated No"
																				Required={(model.IdentificationMethodId == this.state.identificationMethodIds.find(x=>x.Value=="D").Id)}
																				Value={model.PrimaryDocumentNumber}
																				ValueChanged={this.HandleChange}
																				Disabled={ApprovalDisabled}
																				IsInvalid={vModel.PrimaryDocumentNumber}
																				inputProps={this.maxLength20} />
																		</GridItem>
																		<GridItem>
																			<GenericTextInput
																				Name="PrimaryDocumentReviewedBy"
																				LabelText="Reviewed By"
																				Value={model.PrimaryDocumentReviewedBy}
																				ValueChanged={this.HandleChange}
																				Disabled={ApprovalDisabled}
																				Required={(model.IdentificationMethodId == this.state.identificationMethodIds.find(x=>x.Value=="D").Id)}
																				IsInvalid={vModel.PrimaryDocumentReviewedBy}
																				inputProps={this.maxLength20} />
																		</GridItem>
																		<GridItem xs={5}>
																			<br/>
																			<b>Secondary Document</b>
																		</GridItem>
																		<GridItem>
																			<GenericSelectInput
																				Name="SecondaryDocumentId"
																				LabelText="Document"
																				Method="POST"
																				Url="/coreapi/v1.0/Parameter/Search"
																				Parameter={this.ParameterSecondarySourceForDualProcessId}
																				DataRoot="Item"
																				KeyValueMember="Id"
																				TextValueMember="ParameterDesc"
																				Sorted={this.sortedParameterDesc}
																				Value={model.SecondaryDocumentId}
																				ValueChanged={this.HandleChange}
																				Disabled={ApprovalDisabled}/>										
																		</GridItem>
																		<GridItem>
																			<GenericDateInput
																				Name="SecondaryDocumentVerificationDate"
																				LabelText="Verification Date"
																				Value={model.SecondaryDocumentVerificationDate 
																						? DateHelper.ToDateInputValue(model.SecondaryDocumentVerificationDate) 
																						: ""}
																				ValueChanged={this.HandleChange}
																				Disabled={ApprovalDisabled} />
																		</GridItem>
																		<GridItem>
																			<GenericTextInput
																				Name="SecondaryDocumentNumber"
																				LabelText="Associated No"
																				Value={model.SecondaryDocumentNumber}
																				ValueChanged={this.HandleChange}
																				Disabled={ApprovalDisabled}
																				inputProps={this.maxLength20} />
																		</GridItem>
																		<GridItem>
																			<GenericTextInput
																				Name="SecondaryDocumentReviewedBy"
																				LabelText="Reviewed By"
																				Value={model.SecondaryDocumentReviewedBy}
																				ValueChanged={this.HandleChange}
																				Disabled={ApprovalDisabled}
																				inputProps={this.maxLength20} />
																		</GridItem>																		
																	</GridItem>
																}															
																<GridItem>
																	<GenericSelectInput
																		Name="CountryOfCitizenShipId"
																		LabelText="Country of Citizenship"
																		Method="POST"
																		Url="/coreapi/v1.0/Country/GetAll"
																		Parameter={this.CountryParameterCode}
																		DataRoot="Item"
																		KeyValueMember="Id"
																		TextValueMember="Name"
																		Value={model.CountryOfCitizenShipId}
																		ValueChanged={this.HandleChange}
																		Disabled={ApprovalDisabled} />
																</GridItem>
																<GridItem>
																	<GenericSelectInput
																		Name="CountryOfBirthId"
																		LabelText="Country of Birth"
																		Method="POST"
																		Url="/coreapi/v1.0/Country/GetAll"
																		Parameter={this.CountryParameterCode}
																		DataRoot="Item"
																		KeyValueMember="Id"
																		TextValueMember="Name"
																		Value={model.CountryOfBirthId}
																		ValueChanged={this.HandleChange}
																		Disabled={ApprovalDisabled} />

																</GridItem>
																<GridItem>
																	<GenericSelectInput
																		Name="CountryOfResidentId"
																		LabelText="Country of Residence"
																		Method="POST"
																		Url="/coreapi/v1.0/Country/GetAll"
																		Parameter={this.CountryParameterCode}
																		DataRoot="Item"
																		KeyValueMember="Id"
																		TextValueMember="Name"
																		Value={model.CountryOfResidentId}
																		ValueChanged={this.HandleChange}
																		Disabled={ApprovalDisabled} />
																</GridItem>
															</div>
														</GridItem>
													</GridContainer>

												</GridItem>
												<GridItem xs={12}>
													<GridContainer direction="row" justify="flex-start" alignItems="flex-start">
														<GridItem xs={6}>

															<GenericTitle text={"Communication Information"} />
															<div>
																<GridItem>
																	<GenericEmailInput
																		Name="Email"
																		LabelText="E-Mail"
																		inputProps={this.ParameterMaxLength64}
																		Value={model.Email}
																		ValueChanged={this.HandleChange}
																		Disabled={ApprovalDisabled}
																		Required
																		IsInvalid={vModel.Email} />
																</GridItem>
																<GridItem>
																	<GridContainer>
																		<GridItem xs={8}>
																			<GenericSelectInput
																				Name="MobilePhoneCountryCodeId"
																				LabelText="Mobile Number"
																				TitleLabel="Country Code"
																				LabelMd={6}
																				Method="POST"
																				Url="/coreapi/v1.0/Country/GetAll"
																				Parameter={this.emptyObject}
																				DataRoot="Item"
																				KeyValueMember="Id"
																				TextValueMember={"Code"}
																				Value={model.MobilePhoneCountryCodeId}
																				ValueChanged={this.HandleChange}
																				Sorted={{
																					Member: "OrderIndex"
																				}}
																				RenderItem={d => "+" + d.Code + " (" + d.Name + ")"}
																				Disabled={ApprovalDisabled} />
																		</GridItem>
																		<GridItem
																			xs={4}
																			style={{
																				paddingRight: 2 + "px !important"
																			}}>
																			<GenericNumberInput
																				IsPhone
																				Name="MobilePhoneNumber"
																				Format="(###) ### ####"
																				InputStyle={this.marginLeft2}
																				LabelMd={0}
																				Value={model.MobilePhoneNumber}
																				ValueChanged={this.HandleChange}
																				MaxLength={10}
																				Disabled={ApprovalDisabled} />

																		</GridItem>
																	</GridContainer>
																</GridItem>
																<GridItem>
																	<GridContainer>
																		<GridItem xs={8}>
																			<GenericSelectInput
																				Name="OfficePhoneCountryCodeId"
																				LabelText="Office Phone"
																				TitleLabel="Country Code"
																				LabelMd={6}
																				Method="POST"
																				Url="/coreapi/v1.0/Country/GetAll"
																				Parameter={this.emptyObject}
																				DataRoot="Item"
																				KeyValueMember="Id"
																				TextValueMember={"Code"}
																				Value={model.OfficePhoneCountryCodeId}
																				ValueChanged={this.HandleChange}
																				Sorted={{
																					Member: "OrderIndex"
																				}}
																				RenderItem={d => "+" + d.Code + " (" + d.Name + ")"}
																				Disabled={ApprovalDisabled} />
																		</GridItem>
																		<GridItem
																			xs={4}
																			style={{
																				paddingRight: 2 + "px !important"
																			}}>
																			<GenericNumberInput
																				IsPhone
																				Name="OfficePhoneNumber"
																				Format="(###) ### ####"
																				InputStyle={this.marginLeft2}
																				LabelMd={0}
																				Value={model.OfficePhoneNumber}
																				ValueChanged={this.HandleChange}
																				MaxLength={10}
																				Disabled={ApprovalDisabled} />
																		</GridItem>
																	</GridContainer>
																</GridItem>
																<GridItem>
																	<GridContainer>
																		<GridItem xs={8}>
																			<GenericSelectInput
																				Name="HomePhoneCountryCodeId"
																				LabelText="Home Phone"
																				TitleLabel="Country Code"
																				LabelMd={6}
																				Method="POST"
																				Url="/coreapi/v1.0/Country/GetAll"
																				Parameter={this.emptyObject}
																				DataRoot="Item"
																				KeyValueMember="Id"
																				TextValueMember={"Code"}
																				Value={model.HomePhoneCountryCodeId}
																				ValueChanged={this.HandleChange}
																				Sorted={{
																					Member: "OrderIndex"
																				}}
																				RenderItem={d => "+" + d.Code + " (" + d.Name + ")"}
																				Disabled={ApprovalDisabled} />
																		</GridItem>
																		<GridItem
																			xs={4}
																			style={{
																				paddingRight: 2 + "px !important"
																			}}>
																			<GenericNumberInput
																				IsPhone
																				Name="HomePhoneNumber"
																				Format="(###) ### ####"
																				InputStyle={this.marginLeft2}
																				LabelMd={0}
																				Value={model.HomePhoneNumber}
																				ValueChanged={this.HandleChange}
																				MaxLength={10}
																				Disabled={ApprovalDisabled} />
																		</GridItem>
																	</GridContainer>
																</GridItem>
																<GridItem >
																	<GenericSelectInput
																		Name="HasEmailNotificationId"
																		LabelText="E-Mail Notification"
																		Method="POST"
																		Url="/coreapi/v1.0/Parameter/Search"
																		Parameter={this.YesNoParameterCode}
																		DataRoot="Item"
																		KeyValueMember="Id"
																		TextValueMember="ParameterDesc"
																		Value={model.HasEmailNotificationId}
																		ValueChanged={this.HandleChange}
																		Disabled={ApprovalDisabled} />
																</GridItem>
																<GridItem >
																	<GenericSelectInput
																		Name="HasTextNotificationId"
																		LabelText="Text Notification"
																		Method="POST"
																		Url="/coreapi/v1.0/Parameter/Search"
																		Parameter={this.YesNoParameterCode}
																		DataRoot="Item"
																		KeyValueMember="Id"
																		TextValueMember="ParameterDesc"
																		Value={model.HasTextNotificationId}
																		ValueChanged={this.HandleChange}
																		Disabled={ApprovalDisabled} />
																</GridItem>
																<GridItem >
																	<GenericSelectInput
																		Name="LanguageId"
																		LabelText="Language"
																		Method="POST"
																		Url="/coreapi/v1.0/Parameter/Search"
																		Parameter={this.LanguageParameterCode}
																		DataRoot="Item"
																		KeyValueMember="Id"
																		TextValueMember="ParameterDesc"
																		Value={model.LanguageId}
																		ValueChanged={this.HandleChange}
																		Disabled={ApprovalDisabled} />

																</GridItem>																
															</div>
														</GridItem>
														<GridItem xs={6}>
															<GenericTitle text={"Address Information"} />

															<div>
																<GridItem>
																	<GenericTextInput
																		Name="Address1"
																		LabelText="Address Line 1"
																		Id="street-address"
																		Value={model.Address1}
																		ValueChanged={this.HandleChange}
																		inputProps={this.maxLength100}
																		Disabled={ApprovalDisabled}
																		Required
																		IsInvalid={vModel.Address1} />
																</GridItem>
																<GridItem>
																	<GenericTextInput
																		Name="Address2"
																		Id="street-address2"
																		LabelText="Address Line 2"
																		Value={model.Address2}
																		ValueChanged={this.HandleChange}
																		inputProps={this.maxLength100}
																		Disabled={ApprovalDisabled} />
																</GridItem>
																<GridItem>
																	<GenericTextInput
																		Name="ZipCode"
																		LabelText="Postal Code"
																		Id="postcode"
																		Value={model.ZipCode}
																		ValueChanged={this.HandleChange}
																		Disabled={ApprovalDisabled}
																		inputProps={this.maxLength20}
																		Required
																		IsInvalid={vModel.ZipCode} />
																</GridItem>
																<GridItem>
																	<GenericSelectInput
																		Name="CountryId"
																		LabelText="Country"
																		Method="POST"
																		Url="/coreapi/v1.0/Country/GetAll"
																		Parameter={{}}
																		DataRoot="Item"
																		KeyValueMember="Id"
																		TextValueMember="Name"
																		Value={model.CountryId}
																		ValueChanged={this.HandleChange}
																		Disabled={ApprovalDisabled}
																		Required
																		IsInvalid={vModel.CountryId} />
																	<GenericSelectInput
																		key={model.CountryId + "province"}
																		Name="ProvinceId"
																		LabelText="Province"
																		Method="POST"
																		Url="/coreapi/v1.0/Province/Filter"
																		Parameter={{
																			CountryId: model.CountryId
																		}}
																		DataRoot="Item"
																		KeyValueMember="Id"
																		TextValueMember="Name"
																		Value={model.ProvinceId}
																		ValueChanged={this.HandleChange}
																		Disabled={ApprovalDisabled}
																		Required
																		IsInvalid={vModel.ProvinceId} />

																	<GenericSelectInput
																		key={"city" + model.ProvinceId + ""}
																		Name="CityId"
																		LabelText="City"
																		Method="POST"
																		Url="/coreapi/v1.0/City/Filter"
																		Parameter={{
																			ProvinceId: model.ProvinceId
																		}}
																		DataRoot="Item"
																		KeyValueMember="Id"
																		TextValueMember="Name"
																		Value={model.CityId}
																		ValueChanged={this.HandleChange}
																		Disabled={ApprovalDisabled}
																		Required
																		IsInvalid={vModel.CityId} />
																</GridItem>
															</div>
														</GridItem>

													</GridContainer>
												</GridItem>							
												<GridItem xs={12}>
													<GridContainer direction="row" justify="flex-start" alignItems="flex-start">
														<GridItem xs={6}>
															<GenericTitle text={"Employer Information"} />
															<div>
																<GridItem>
																	<GenericTextInput
																		Name="EmployerName"
																		LabelText="Employer"
																		Value={model.EmployerName}
																		ValueChanged={this.HandleChange}
																		Disabled={ApprovalDisabled}
																		inputProps={this.maxLength30}/>												
																</GridItem>
																<GridItem>
																	<GenericTextInput
																		Name="EmployerAddress"
																		LabelText="Address"
																		Id="employer-address"
																		Value={model.EmployerAddress}
																		ValueChanged={this.HandleChange}
																		Disabled={ApprovalDisabled}
																		inputProps={this.maxLength100} />
																</GridItem>								
																<GridItem>
																	<GenericTextInput
																		Name="EmployerZipCode"
																		LabelText="Postal Code"
																		Id="employer-postcode"
																		Value={model.EmployerZipCode}
																		ValueChanged={this.HandleChange}
																		Disabled={ApprovalDisabled}
																		inputProps={this.maxLength20} />
																</GridItem>
																<GridItem>
																	<GenericSelectInput
																		Name="EmployerCountryId"
																		LabelText="Country"
																		Method="POST"
																		Url="/coreapi/v1.0/Country/GetAll"
																		Parameter={{}}
																		DataRoot="Item"
																		KeyValueMember="Id"
																		TextValueMember="Name"
																		Value={model.EmployerCountryId}
																		ValueChanged={this.HandleChange}
																		Disabled={ApprovalDisabled} />
																</GridItem>
																<GridItem>
																	<GenericSelectInput
																		key={model.EmployerCountryId + "province"}
																		Name="EmployerProvinceId"
																		LabelText="Province"
																		Method="POST"
																		Url="/coreapi/v1.0/Province/Filter"
																		Parameter={{ CountryId: model.EmployerCountryId }}
																		DataRoot="Item"
																		KeyValueMember="Id"
																		TextValueMember="Name"
																		Value={model.EmployerProvinceId}
																		ValueChanged={this.HandleChange}
																		Disabled={ApprovalDisabled} />
																</GridItem>
																<GridItem>
																	<GenericSelectInput
																		key={"city" + model.EmployerProvinceId + ""}
																		Name="EmployerCityId"
																		LabelText="City"
																		Method="POST"
																		Url="/coreapi/v1.0/City/Filter"
																		Parameter={{ ProvinceId: model.EmployerProvinceId }}
																		DataRoot="Item"
																		KeyValueMember="Id"
																		TextValueMember="Name"
																		Value={model.EmployerCityId}
																		ValueChanged={this.HandleChange}
																		Disabled={ApprovalDisabled} />
																</GridItem>
																<GridItem>
																	<GridContainer>
																		<GridItem xs={8}>
																			<GenericSelectInput
																				Name="EmployerPhoneCountryCodeId"
																				LabelText="Phone Number"
																				TitleLabel="Country Code"
																				LabelMd={6}
																				Method="POST"
																				Url="/coreapi/v1.0/Country/GetAll"
																				Parameter={this.emptyObject}
																				DataRoot="Item"
																				KeyValueMember="Id"
																				TextValueMember={"Code"}
																				Value={model.EmployerPhoneCountryCodeId}
																				ValueChanged={this.HandleChange}
																				Sorted={this.sortedOrderIndex}
																				RenderItem={this.RenderItemPhoneCode}
																				Disabled={ApprovalDisabled} />
																		</GridItem>
																		<GridItem xs={4} style={{ paddingRight: 2 + "px !important" }}>
																			<GenericNumberInput
																				IsPhone
																				Name="EmployerPhoneNumber"
																				InputStyle={this.marginLeft2}
																				LabelMd={0}
																				Value={model.EmployerPhoneNumber}
																				ValueChanged={this.HandleChange}
																				MaxLength={10}
																				Disabled={ApprovalDisabled}/>
																		</GridItem>
																	</GridContainer>
																</GridItem>
															</div>
														</GridItem>
													</GridContainer>																	
												</GridItem>

											</GridContainer>
										</GridItem>
										<GridItem xs={11}></GridItem>
										<GridItem xs={1}>
											<Button
												size="sm"
												color="black"
												fullWidth
												onClick={this.ApplicantInformationNextCliecked}>Next</Button>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel
									IsActive={this.state.panelActivationStatus.panelVerificationActive}
									Name={"panelVerificationActive"}
									Title={"Verification"}
									ValueChanged={this.HandleChangeFilterPanel}>
									<GridContainer direction="row" justify="flex-start" alignItems="stretch">
										<GridItem xs={12}>
											<GridContainer direction="row" justify="flex-start" alignItems="flex-start">
												<GridItem xs={3}>
													<GenericTextInput
														key={"InternalBlacklist"}
														Name="InternalBlacklist"
														LabelMd={5}
														LabelText="Internal Blacklist Status"
														Value={model.InternalBlacklist == null || undefined
															? ""
															: model.InternalBlacklist}
														Disabled={true} />
												</GridItem>
												<GridItem xs={3}>
													<GenericTextInput
														key={"WatchListVerification"}
														Name="WatchListVerification"
														LabelMd={5}
														LabelText="Watch List Verification Status"
														Value={model.WatchListVerification == null || undefined
															? ""
															: model.WatchListVerification}
														Disabled={true} />
												</GridItem>
												<GridItem xs={3}>
													<GenericTextInput
														key={"TransUnionIdVerification"}
														Name="TransUnionIdVerification"
														LabelMd={6}
														LabelText="TransUnion ID Verification Status"
														Value={model.TransUnionIdVerification == null || undefined
															? ""
															: model.TransUnionIdVerification}
														Disabled={true} />
												</GridItem>
											</GridContainer>
											<GridContainer direction="row" justify="flex-start" alignItems="stretch">
												{IsVerificationTypeActive && <GridItem xs={12}>
													<GridContainer direction="row" justify="flex-start" alignItems="flex-start">
														<GridItem xs={3}>
															<GenericSelectInput
																Name="IdentityVerificationTypeId"
																LabelText="Verification Type"
																LabelMd={5}
																IsStatic
																StaticData={IdVerificationTypeList}
																DataRoot="Item"
																KeyValueMember="Id"
																TextValueMember="ParameterDesc"
																Value={model.IdentityVerificationTypeId}
																ValueChanged={this.HandleChange}
																Disabled={ApprovalDisabled || (model.IdVerificationStatus != null && model.IdVerificationStatus)} />
														</GridItem>

														{model.CanadaPostVerificationEnable && <GridItem xs={9}>
															{((model.AddressVerification == null || model.AddressVerification == "Invalid") && <GridContainer>
																{model.IdentityTypeParameterValue2 == IdentityType.OnePoR && <GridItem xs={4}>
																	<ParameterComponent
																		Disabled={model.IdentityTypeParameterValue2 != IdentityType.OnePoR}
																		Required={model.IdentityTypeParameterValue2 == IdentityType.OnePoR}
																		IsInvalid={vModel.ProofOfResidenceId}
																		Name="ProofOfResidenceId"
																		LabelText="Proof of Residence"
																		LabelMd={5}
																		ParameterCode="ProofOfResidence"
																		Value={model.ProofOfResidenceId}
																		ValueChanged={this.HandleChange} />
																</GridItem>
																}
																<GridItem xs={2}>
																	<Button
																		size="sm"
																		color="black"
																		fullWidth
																		onClick={() => {
																			this.SaveCanadaPostRequest();
																		}}>Verification Request</Button>
																</GridItem>
															</GridContainer>)}
														</GridItem>
														}

														{model.InPersonIDVerificationEnable && <GridContainer direction="row" justify="flex-start" alignItems="stretch">
															<GridItem xs={12}>
																<GridContainer direction="row" justify="flex-start" alignItems="flex-start">
																	<GridItem xs={3}>
																		{model.OtherVerificationTypeEnable
																			? <GenericTextInput
																				Name="IdentityVerificationTypeDesc"
																				Value={model.IdentityVerificationTypeDesc}
																				inputProps={{
																					maxLength: 200
																				}}
																				IsText
																				LabelText="Other Type Description"
																				ValueChanged={this.HandleChange}
																				LabelMd={5}
																				Disabled={false} />

																			: <GenericTextInput
																				Name="IdentityVerifiedPersonId"
																				Value={model == null || model.IdentityVerifiedPerson == null
																					? ClientHelper
																						.GetUser()
																						.FirstName + " " + ClientHelper
																							.GetUser()
																						.LastName
																					: model.IdentityVerifiedPerson.FullName}
																				LabelText="Verified Person"
																				ValueChanged={this.HandleChange}
																				LabelMd={5}
																				Disabled={true} />
																		}

																	</GridItem>

																	{!(model.IdVerificationStatus) && <GridItem xs={1}>
																		<Button
																			size="sm"
																			color="black"
																			fullWidth
																			disabled={(model == null || model.Id == null || model.Id == 0) || !(model.IdVerification != null && model.IdVerification)}
																			onClick={() => this.SendEmailForInPersonIdVerificationStatus()}>Send Email</Button>
																	</GridItem>
																	}
																	{model.IdVerificationStatus && <GridItem>
																		<GridContainer direction="row" justify="flex-start" alignItems="flex-start">
																			<GridItem xs={12}>
																				<GenericTextInput
																					key={"IdVerification"}
																					Name="IdVerification"
																					Value={model.IdVerification == null || undefined
																						? ""
																						: model.IdVerification}
																					Disabled={true} />
																			</GridItem>

																			< GridItem xs={8}>
																				{model.IdVerification != "Valid" && < Button size="sm" color="black" fullWidth
																					onClick={
																						() => {
																							model.IdVerificationStatus = true;
																							model.IdVerification = "Valid";
																							model.IdentityVerifiedPersonId = ClientHelper
																								.GetUser()
																								.Id;
																							model.User = ClientHelper.GetUser();
																							model.InPersonIdVerificationStatus = "Verified";
																							model.VerificationDate = DateHelper.GetDateTime();
																							this.setState({ model, IsPersonVerified: true });
																							this.UpdateModel(LOSApplicantStatus.Saved);
																						}
																					} > Verify </Button>}
																			</GridItem>

																		</GridContainer>
																	</GridItem>
																	}
																</GridContainer>
															</GridItem>
														</GridContainer>
														}
														{model.CanadaPostVerificationEnable && <GridItem xs={12}>
															<GridContainer direction="row" justify="flex-start" alignItems="flex-start">
																<GridItem xs={2}></GridItem>
																{(model.AddressVerification != null && model.AddressVerification != "Invalid") && <GridItem xs={6}>
																	<GridContainer direction="row" justify="flex-start" alignItems="flex-start">
																		<GridItem xs={3}>
																			<GenericTextInput
																				key={"AddressVerification"}
																				Name="AddressVerification"
																				Value={model.AddressVerification == null || undefined
																					? ""
																					: model.AddressVerification}
																				Disabled={true} />
																		</GridItem>
																		<GridItem xs={1}>
																			<Button
																				size="sm"
																				color="black"
																				fullWidth
																				disabled={!(model.CanadaPostVerificationId != null && model.CanadaPostVerificationId > 0)}
																				onClick={() => {
																					this.HandleCanadaPostCode("E");
																				}}>Email</Button>
																		</GridItem>
																		<GridItem xs={1}>
																			<Button
																				size="sm"
																				color="black"
																				fullWidth
																				disabled={!(model.CanadaPostVerificationId != null && model.CanadaPostVerificationId > 0)}
																				onClick={() => {
																					this.HandleCanadaPostCode("D");
																				}}>PDF</Button>
																		</GridItem>
																	</GridContainer>
																</GridItem>
																}
															</GridContainer>
														</GridItem>
														}
													</GridContainer>
												</GridItem>
												}
											</GridContainer>

										</GridItem>
										<GridItem xs={11}></GridItem>
										<GridItem xs={1}>
											<Button
												size="sm"
												color="black"
												fullWidth
												disabled={(model == null || model.Id == null || model.Id == 0) || IsNextPassive}
												onClick={this.VerificationActiveNextCliecked}>Next</Button>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>

						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel
									IsActive={this.state.panelActivationStatus.panelAppliedForActive}
									Title={"Applied For"}
									Name={"panelAppliedForActive"}
									ValueChanged={this.HandleChangeFilterPanel}>
									<GridContainer direction="row" justify="flex-start" alignItems="stretch">
										<GridItem xs={12}>
											<GridContainer direction="row" justify="flex-start" alignItems="flex-start">
												<GridItem xs={4}>
													<GenericSelectInput
														Name="LoanTypeId"
														LabelText="Loan Type"
														LabelMd={5}
														Method="POST"
														Url="/coreapi/v1.0/Parameter/Search"
														Parameter={this.LoanTypeParameterCode}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="ParameterDesc"
														Value={appliedForData == null
															? 0
															: appliedForData.LoanTypeId}
														ValueChanged={this.HandleAppliedForInfoChanged}
														Disabled={ApprovalDisabled}
														Required
														IsInvalid={vModel.LoanTypeId} />
												</GridItem>
												{(appliedForData.LoanType != null 
														&& appliedForData.LoanType.ParameterValue == ConstLoanType.Loan) 
														&& 
												<GridItem xs={4}>
													<GenericSelectInput
														IsStatic
														LabelMd={5}
														Name="RequestedPaymentPeriod"
														key="RequestedPaymentPeriod"
														LabelText="Requested Length of Term"
														StaticData={RequestedLengthOfTermSource}
														KeyValueMember="Value"
														TextValueMember="Text"
														Value={appliedForData.RequestedPaymentPeriod == null || undefined
															? ""
															: appliedForData.RequestedPaymentPeriod}
														ValueChanged={this.HandleAppliedForInfoChanged}
														Disabled={ApprovalDisabled} />
												</GridItem>
												}
												<GridItem xs={4}>
													<ParameterComponent
														Name="RequestedPaymentFrequencyId"
														LabelText="Requested Payment Frequency"
														LabelMd={5}
														ParameterCode="LOSPaymentSchedule"
														Value={appliedForData.RequestedPaymentFrequencyId}
														ValueChanged={this.HandleAppliedForInfoChanged}
														Disabled={ApprovalDisabled}
														Required
														DefaultParamValue="M"
														IsInvalid={vModel.RequestedPaymentFrequencyId} />
												</GridItem>
												<GridItem xs={4}>
													<GenericNumberInput
														key="RequestedAmount"
														Name="RequestedAmount"
														LabelText="Requested Amount"
														LabelMd={5}
														MaxLength={10}
														Prefix="$"
														Value={appliedForData.RequestedAmount == null || undefined
															? ""
															: appliedForData.RequestedAmount}
														ValueChanged={this.HandleAppliedForInfoChanged}
														Disabled={ApprovalDisabled}
														Required
														IsInvalid={vModel.RequestedAmount} />
												</GridItem>
												{(appliedForData.LoanType != null && appliedForData.LoanType.ParameterValue == ConstLoanType.SecureCard) &&
													<GridItem xs={4}>
														<GenericNumberInput
															key="SecurityAmount"
															Name="SecurityAmount"
															LabelText="Security Amount"
															LabelMd={5}
															MaxLength={10}
															Prefix="$"
															Value={appliedForData.SecurityAmount == null || undefined
																? ""
																: appliedForData.SecurityAmount}
															ValueChanged={this.HandleAppliedForInfoChanged}
															Disabled={ApprovalDisabled}
															Required
															IsInvalid={vModel.SecurityAmount} />
													</GridItem>
												}
												<GridItem xs={4}>
													<GenericSelectInput
														Name="LoanPurposeId"
														LabelText="Purpose Of Loan"
														LabelMd={5}
														key={appliedForData.LoanTypeId + "LoanPurpose"}
														Method="POST"
														Url="/losapi/v1.0/LosParameter/SearchLoanPurposeByLoanTypeId"
														Parameter={{
															LoanTypeId: appliedForData.LoanTypeId
														}}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="ParameterDesc"
														Sorted={this.sortedParameterDesc}
														Value={appliedForData.LoanPurposeId}
														ValueChanged={this.HandleAppliedForInfoChanged}
														Disabled={ApprovalDisabled || !(appliedForData != null && appliedForData.LoanTypeId != null)}
														Required
														IsInvalid={vModel.LoanPurposeId} />
												</GridItem>
												{IsActiveLoanPurposeDescription && <GridItem xs={4}>
													<GenericTextInput
														Name="LoanPurposeDescription"
														inputProps={{
															maxLength: 200
														}}
														IsText
														LabelMd={5}
														LabelText="Purpose Description"
														Value={appliedForData.LoanPurposeDescription}
														ValueChanged={this.HandleAppliedForInfoChanged}
														Disabled={ApprovalDisabled} />
												</GridItem>
												}
											</GridContainer>
										</GridItem>
										<GridItem xs={11}></GridItem>
										<GridItem xs={1}>
											<Button
												size="sm"
												color="black"
												fullWidth
												disabled={(model == null || model.Id == null || model.Id == 0)}
												onClick={this.AppliedForNextCliecked}>Next</Button>
										</GridItem>
									</GridContainer>

								</GenericExpansionPanel>
							</CardBody>
						</Card>

						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel
									IsActive={this.state.panelActivationStatus.panelCreditValidationStatusActive}
									Name={"panelCreditValidationStatusActive"}
									Title="Credit Validation Status"
									ValueChanged={this.HandleChangeFilterPanel}>
									<GridContainer direction="row" justify="flex-start" alignItems="stretch">
										<GridItem xs={12}>
											<LoanApplicationCreditValidation
												GetCreditHistory={this.GetCreditHistory}
												model={this.state.creditHistoryModel}
												Applicant={model} />
										</GridItem>
										<GridItem
											style={{
												marginLeft: "auto",
												display: MenuCode == MenuCodes.Lms_PortfolioPerformance && IsPopUp
													? "none"
													: "inherit"
											}}>
											<Button
												size="md"
												color="success"
												disabled={(model == null || model.Id == null || model.Id == 0 || IsNextPassive || ApprovalDisabled)}
												onClick={() => this.CreditValidationStatusNextCliecked(true)}>Complete Application</Button>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						{!IsPopUp && <Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel
									IsActive={this.state.panelActivationStatus.panelApplicantListActive}
									Name={"panelApplicantListActive"}
									Title="Applicant List"
									ValueChanged={this.HandleChangeFilterPanel}>
									<GenericGrid
										Data={modelList}
										PageSize={5}
										HideButton={true}
										Sorted={[{
											id: "Status",
											desc: false
										}
										]}
										Columns={this.ColumnsData}
										RowSelected={index => {
											this.SelectedRowChange(0, index);
										}} />
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						}
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

ApplicantDefinition.propTypes = {
	classes: PropTypes.object.isRequired,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	IsPopUp: PropTypes.bool,
	ApplicantModel: PropTypes.object,
	ApprovalDisabled: PropTypes.bool,
	ApplicantDefinitionDiologOpenOrClose: PropTypes.func,
	HandleChange: PropTypes.func,
	ExecuteApiPost: PropTypes.func
};

export default withArtifex(ApplicantDefinition, {});