import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericCheckInput, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericDateInput, GenericLabel } from "views/Components/Generic";
import { MonthsOfYear, MenuCodes, GridColumnType } from "views/Constants/Constant.js";
import ClientHelper from "core/ClientHelper";

class DomesticAndInternationalReport extends React.Component {
	constructor(props) {
		super(props);
		this.initialModel = {
			Id: 0,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined,
			IsMainCard: true
		};

		let IsDomesticAndInternationalReport = this.props.MenuCode == MenuCodes.Menu_DomesticAndInternationalReport;

		this.columns = [
			{
				Header: "Year",
				accessor: "Year",
				show: !IsDomesticAndInternationalReport

			},
			{
				Header: "Month",
				accessor: "Month",
				show: !IsDomesticAndInternationalReport

			},
			{
				Header: "ProductName",
				accessor: "ProductName",
			},
			{
				Header: "TransactionType",
				accessor: "TransactionType"
			},

			{
				Header: "Card Transaction Source",
				accessor: "CardTransactionSourceValue",
				show: IsDomesticAndInternationalReport
			},

			{
				Header: "PlasticType Name",
				accessor: "PlasticName",
				show: !IsDomesticAndInternationalReport
			},
			{
				Header: "PlasticType Code",
				accessor: "PlasticCode",
				show: !IsDomesticAndInternationalReport
			},
			{
				Header: "Total Amount",
				accessor: "TotalAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Total Count",
				accessor: "Count"
			}
			// {
			// 	Header: "TranactionDateTime",
			// 	accessor: "TranactionDateTime",
			// 	type: GridColumnType.DateUtc,

			// },
		];

		this.emptyObject = {};
		this.parameterProduct = {};
		this.sortedName = { Member: "Name" };
		this.sortedCode = { Member: "Code" };

		this.state = {
			model: this.initialModel,
			Month: [],
			vModel: {}
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("PREPAID CARD MANAGEMENT");
		}

		if (this.props.MenuCode == MenuCodes.Menu_DomesticAndInternationalReport) {

			setAppLeftTitle("Txn Domestic and International Report");
		} else {
			setAppLeftTitle("Txn Product and PlasticType Report");

		}
		this.SetYearList();
	}

	SetYearList = () => {
		var currentYear = parseInt(DateHelper.GetYear());
		var listYear = [];
		var listAge = [];

		for (var i = 2019; i <= currentYear; i++) {
			listYear.push({
				"Year": i
			});
		}
		for (var i = 18; i <= 100; i++) {
			listAge.push({
				"Age": i
			});
		}
		this.setState({ listAge, listYear });
	}
	handleListItemChange = (name, newValue, key, ObjectName) => {
		var model = { ...this.state.model };
		const array = this.state.Month;
		const item = array.includes(key);
		if (item == false && newValue == true) {
			array.push(key);
		} else if (item == true && newValue == false) {
			var index = array.indexOf(key);
			if (index > -1) {
				array.splice(index, 1);
			}
		}
		model["Month"] = array;
		this.setState({ Month: array, model });

	}


	ValueChanged = (name, value) => {
		this.setState(state => {
			var model = state.model;
			model[name] = value;

			return { model };
		});
	}

	Search = async () => {
		const { model } = this.state;
		const { ExecuteApiPost } = this.props;

		let IsDomesticAndInternationalReport = this.props.MenuCode == MenuCodes.Menu_DomesticAndInternationalReport;
		if (IsDomesticAndInternationalReport) {
			var result = await ExecuteApiPost("/prepaidapi/v1.0/TransactionData/DomesticAndInternationaReport", model, null, null, null);
			this.setState({ list: result || [] });

		}
		else {
			var result1 = await ExecuteApiPost("/prepaidapi/v1.0/TransactionData/ProductAndPlasticTypeReport", model, null, null, null);
			this.setState({ list: result1 || [] });
		}

	}

	Clear = () => {
		this.setState({ model: {}, list: [], selected: null, Month: [] });
	}

	Validate = () => {
		var { model } = this.state;
		const { showValidationErrors } = this.props;
		const vModel = { ...this.state.vModel };
		var errors = [];

		if (!model.ParentMenuId
			|| model.ParentMenuId == -2) {
			errors.push("Parent menu can not be null.");
		}
		if (!model.SubMenuId) {
			errors.push("Sub menu can not be null.");
		}
		if (!model.ActionId) {
			errors.push("Action can not be null.");
		}

		if (errors.length > 0) {
			showValidationErrors(errors);

			return false;
		}

		return true;
	}

	GetProductParameter = () => {
		this.parameterProduct.UniqueClientId = this.state.model.UniqueClientId;
		this.parameterProduct.IsNoNameProduct = false;
		return this.parameterProduct;
	}

	render() {
		const { model, list, listYear, listAge, Month } = this.state;
		const { Disabled } = this.props;
		let IsDomesticAndInternationalReport = this.props.MenuCode == MenuCodes.Menu_DomesticAndInternationalReport;

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},

						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Panel">
									<GridContainer>
										{(!IsDomesticAndInternationalReport) &&

											<GridItem xs={3}>
												<GenericSelectInput
													IsStatic
													Name="Year"
													LabelText="Year"
													StaticData={listYear}
													KeyValueMember="Year"
													TextValueMember="Year"
													Value={model.Year}
													ValueChanged={this.ValueChanged}
												// Required 
												/>
											</GridItem>
										}
										<GridItem xs={3}>
											<GenericSelectInput
												key={model.UniqueClientId}
												Name="ProductId"
												LabelMd={3}
												LabelText="Product"
												Method="POST"
												Url="/prepaidapi/v1.0/Product/Search"
												Parameter={this.GetProductParameter()}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ProductName"
												Value={model.ProductId}
												ValueChanged={this.ValueChanged}
												Disabled={model.Id > 0 ? true : false}
											// Required
											// IsInvalid={vModel.ProductId}
											/>
										</GridItem>
										{(IsDomesticAndInternationalReport) &&
											<GridItem xs={3}>

												<GenericSelectInput
													Name="TransactionTypeId"
													LabelMd={4}
													LabelText="Transaction Type"
													Method="GET"
													Url="/bankapi/v1.0/Transaction/GetAllFinancialTransactionType"
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={model.TransactionTypeId}
													ValueChanged={this.ValueChanged}
												// Required
												/>
											</GridItem>
										}
										{(IsDomesticAndInternationalReport) &&
											<GridItem xs={3}>

												<GenericSelectInput
													Name="CardTransactionSourceId"
													LabelText="Card Transaction Source"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={{ ParameterCode: "CardTransactionSource" }}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={model.CardTransactionSourceId}
													ValueChanged={this.ValueChanged}
												// IsInvalid={vModel.GenderId}
												// Required
												/>
											</GridItem>
										}
	{(IsDomesticAndInternationalReport) &&
											<GridItem xs={3}>

												 
											</GridItem>
										}
										{(!IsDomesticAndInternationalReport) &&
											<GridItem xs={3}>
												<GenericSelectInput
													key={model.ProductId}
													Name="PlasticTypeId"
													LabelText="Plastic Type"
													Method="POST"
													Url="/prepaidapi/v1.0/PlasticType/PlasticTypeReport"
													DataRoot="Item"
													Parameter={{ ProductId: model.ProductId }}
													KeyValueMember="Id"
													TextValueMember="PlasticName"
													Value={model.PlasticTypeId}
													ValueChanged={this.ValueChanged}
												// Required
												// IsInvalid={vModel.PlasticTypeId}
												/>
											</GridItem>
										}
										{(!IsDomesticAndInternationalReport) &&
											<GridItem xs={3}> </GridItem>
										}
										{(IsDomesticAndInternationalReport) &&
											<GridItem xs={3}>
												<GenericDateInput
													Name="FromDate"
													LabelText="From Date"
													Value={model.FromDate ? DateHelper.ToDateInputValue(model.FromDate) : ""}
													ValueChanged={this.ValueChanged}
													MaxDate={model.ToDate || DateHelper.GetDateTimeLocal()}
													Disabled={Disabled}
													LabelMd={3}
													Utc />
											</GridItem>}
										{(IsDomesticAndInternationalReport) &&
											<GridItem xs={3}>
												<GenericDateInput
													Name="ToDate"
													LabelText="To Date"
													Value={model.ToDate ? DateHelper.ToDateInputValue(model.ToDate) : ""}
													ValueChanged={this.ValueChanged}
													Disabled={Disabled}
													MinDate={model.FromDate || DateHelper.GetDateTimeLocal()}
													MaxDate={DateHelper.GetDateTimeLocal()}
													Utc />
											</GridItem>
										}

										{(!IsDomesticAndInternationalReport) &&
											<GridItem xs={1} style={{ marginTop: "10px" }}>
												<GenericLabel
													Text="Month"
													LabelMd={6}
												/>
											</GridItem>}
										{(!IsDomesticAndInternationalReport) &&
											<GridItem xs={10} style={{ marginTop: "10px" }}>
												{MonthsOfYear != null && MonthsOfYear.map(item => (
													<GenericCheckInput
														key={"MonthsOfYear" + item.Id}
														Name="Id"
														LabelText={item.Text}
														Value={Month.includes(item.Id)}
														ValueChanged={(name, value) => this.handleListItemChange(name, value, item.Id, "MonthsOfYear")}
													/>
												))}

											</GridItem>
										}

									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columns}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

DomesticAndInternationalReport.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any,
	classes: PropTypes.object

};
export default withArtifex(DomesticAndInternationalReport, {});
