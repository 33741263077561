import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import { DownloadIcon, UploadIcon } from "core/Icons";
import PropTypes, { array, bool, func, number } from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent.jsx";
import { DefaultBankInformation, GridColumnType, Portals, ProgramCodes } from "views/Constants/Constant";
import DDTListStyle from "./../../../assets/jss/material-dashboard-pro-react/views/DDTListStyle.jsx";
import ToolBar from "./../../Components/DynamicToolbar.jsx";

class DdtMoneyTransfer extends Component {
	constructor(props) {
		super(props);

		this.initialModel = {};
		this.state = {
			model: {
				UniqueClientId: ""
			},

			toolBarModel: {},
			DdtTotalInfo: {},
			isLoading: false,
			poolModel: {},
			isBackOffice: false,
			openDepositDialog: false,
			openWithDrawalDialog: false,
			ddtModel: {
				Id: 0,
				Name: "",
				Email: "",
				Phone: {
					Id: 0,
					PhoneCountryCodeId: 0,
					PhoneNumber: ""
				},
			},
			depositModel: {
				DDTId: 0,
				Description: ""
			},
			withDrawalModel: {
				DDTId: 0,
				Description: ""
			}
		};
		this.handleChange = this.handleChange.bind(this);
		this.GetAllData = this.GetAllData.bind(this);
		this.errorCallback = this.errorCallback.bind(this);
		this.hideAlert = this.hideAlert.bind(this);
		this.showErrorMessage = this.showErrorMessage.bind(this);
		this.successCallback = this.successCallback.bind(this);
		this.trxTable = React.createRef();

		this.columns = [
			{
				Header: "Actions",
				accessor: "AC",
				Cell: row => (
					<div>
						<GridButton
							Icon={DownloadIcon}
							OnClick={() => this.openDepositModal(row.original)} />
						<GridButton
							Icon={UploadIcon}
							OnClick={() => this.openWithDrawalModal(row.original)} />
					</div>
				),
				sortable: false,
				filterable: false,
				width: 70
			},
			{
				Header: "Customer Number",
				accessor: "CustomerNumber"
			},
			{
				Header: "Transit Number",
				accessor: "TransitNumber"
			},
			{
				Header: "DDT Number",
				accessor: "DDTNumber"
			},
			{
				Header: "Issued Date",
				accessor: "IssuedDate",
				type: GridColumnType.Date
			},
			{
				Header: "ExpireDate",
				accessor: "ExpireDate",
				type: GridColumnType.Date
			},
			{
				Header: "Status",
				accessor: "Status"
			},
			{
				Header: "Balance",
				accessor: "Balance",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Name",
				accessor: "Name"
			}
			,
			{
				Header: "Phone Number",
				accessor: "Phone",
				Cell: row => (
					<div>{row.original.Phone != null
						? row.original.Phone.PhoneCountryCode != null
							? row.original.Phone.PhoneCountryCode != null
								? row.original.Phone.PhoneCountryCode.Code
								: ""
							: ""
								+ " " +
								row.original.Phone.PhoneNumber != null
								? row.original.Phone.PhoneNumber
								: ""
						: ""}
					</div>
				)
			}, {
				Header: "EMail",
				accessor: "Email"
			}
		];
	}

	componentDidMount() {
		var { isBackOffice } = this.state;

		isBackOffice = ClientHelper.IsBackOffice();
		if (!isBackOffice) {
			this.GetToolbarData();
			this.initialModel = {
				UniqueClientId: ClientHelper.GetClientRowId()
			};
		}

		this.props.setAppLeftTitle("DDT Money Transfer");
		this.props.setAppCenterTitle("WALLET");
		this.props.setPortal(Portals.DDT);
		this.setState({ model: { ...this.initialModel }, isBackOffice, loadingCompleted: true });
	}

	handleChange(name, newValue) {
		this.setState(state => {
			var model = state.model;
			model[name] = newValue;
			return { model };
		});
	}

	handleChangeDepositModel = (name, newValue) => {
		this.setState(state => {
			var depositModel = state.depositModel;
			depositModel[name] = newValue;
			return { depositModel };
		});
	}

	handleChangeWithDrawalModel = (name, newValue) => {

		this.setState(state => {
			var withDrawalModel = state.withDrawalModel;
			withDrawalModel[name] = newValue;
			return { withDrawalModel };
		});
	}

	IsValid = () => {
		var vmodel = { ...this.state.model };
		if (vmodel.FromDate && typeof vmodel.FromDate != "object") {
			this.props.showMessage("warning", "Please fill required fields!", "From Date is invalid");
			return false;
		}

		else if (vmodel.ToDate && typeof vmodel.ToDate != "object") {
			this.props.showMessage("warning", "Please fill required fields!", "To Date is invalid");
			return false;
		}

		if (vmodel.FromDate && vmodel.ToDate && vmodel.FromDate > vmodel.ToDate) {
			this.props.showMessage("warning", "Please fill required fields!", "From Date cannot be bigger than To Date");
			return false;
		}


		return true;
	}

	GetAllData() {
		if (!this.IsValid()) {
			return false;
		}

		if (this.state.poolModel.BranchId == null || this.state.poolModel.AccountNumber == null) {
			this.setState(state => ({ trxGridToken: !state.trxGridToken }));
		} else {
			if (this.state.poolModel.BranchId == "" || this.state.poolModel.AccountNumber == "")
				this.showErrorMessage("No DDT program definition has been made in your name. Please contact the bank manager.");
			else {
				this.setState(state => ({ trxGridToken: !state.trxGridToken }));
			}
		}
	}


	CalculateDdtTotalInfo = async (newData) => {
		const info = {
			Active: 0,
			Inactive: 0,
			Total: 0,
			Balance: 0,
		};
		newData.forEach(item => {
			console.log(item);
			if (item.Status == "Active") {
				info.Active++;
				info.Balance += item.AccountBalance;
			}
			if (item.Status == "Inactive") {
				info.Inactive++;
				info.Balance += item.AccountBalance;
			}
			info.Total = info.Active + info.Inactive;
		});
		this.setState({ DdtTotalInfo: info });
	}

	GetToolbarData = async () => {
		this.showLoading();
		Proxy.GET(
			"/bankapi/v1.0/Ddt/GetAllDDTCDICNumberAndBalance",
			this.successCallback,
			this.errorCallback
		);
	}

	successCallback(responseData) {
		var toolBarModel = { ...this.state.toolBarModel };
		var poolModel = { ...this.state.poolModel };
		var model = { ...this.state.model };

		model.PrimaryBranchId = responseData.Item == null || undefined ? "" : responseData.Item.DDTPoolAccountInfo == null || undefined ? "" : responseData.Item.DDTPoolAccountInfo.BranchId == null || undefined ? "" : responseData.Item.DDTPoolAccountInfo.BranchId;
		model.PrimaryAccountNumber = responseData.Item == null || undefined ? "" : responseData.Item.DDTPoolAccountInfo == null || undefined ? "" : responseData.Item.DDTPoolAccountInfo.AccountNumber == null || undefined ? "" : responseData.Item.DDTPoolAccountInfo.AccountNumber;

		toolBarModel.TotalDDTNumber = responseData.Item == null || undefined ? 0 : responseData.Item.TotalDDTNumber == null || undefined ? 0 : responseData.Item.TotalDDTNumber;
		toolBarModel.TotalDDTBalance = responseData.Item == null || undefined ? 0 : responseData.Item.DDTPoolAccountInfo == null || undefined ? 0 : responseData.Item.DDTPoolAccountInfo.Balance == null || undefined ? 0 : responseData.Item.DDTPoolAccountInfo.Balance;
		toolBarModel.TotalCDICNumber = responseData.Item == null || undefined ? 0 : responseData.Item.TotalCDICNumber == null || undefined ? 0 : responseData.Item.TotalCDICNumber;
		toolBarModel.TotalCDICBalance = responseData.Item == null || undefined ? 0 : responseData.Item.CDICPoolAccountInfo == null || undefined ? 0 : responseData.Item.CDICPoolAccountInfo.Balance == null || undefined ? 0 : responseData.Item.CDICPoolAccountInfo.Balance;

		this.setState({ model, toolBarModel, poolModel, isLoading: false });
	}

	errorCallback(error) {
		this.showErrorMessage("An error occurred during the api visit" + error);
		this.hideLoading();
	}

	showErrorMessage(message) {
		this.setState({
			DDTList: [],
			DdtTotalInfo: null,
			isLoading: false,
			alert: <GenericAlert Title={"Error"} MessageNode={message} Type={"warning"} OnConfirm={() => this.hideAlert()} />
		});
	}

	hideAlert() {
		this.setState({ alert: null });
	}

	showLoading = () => {
		this.setState({ isLoading: true });
	}

	ClearModel = () => {
		this.setState({ model: { ...this.initialModel } });
	}

	hideLoading = () => {
		this.setState({ isLoading: false });
	}

	openDepositModal = (selectedDDT) => {
		var ddtModel = { ...this.state.ddtModel };
		var depositModel = { ...this.state.depositModel };
		ddtModel = selectedDDT;
		depositModel.DDTId = ddtModel.AccountId;
		this.setState({ openDepositDialog: true, ddtModel, depositModel });
	}

	openWithDrawalModal = (selectedDDT) => {
		var ddtModel = { ...this.state.ddtModel };
		var withDrawalModel = { ...this.state.withDrawalModel };
		ddtModel = selectedDDT;
		withDrawalModel.DDTId = ddtModel.AccountId;
		this.setState({ openWithDrawalDialog: true, ddtModel, withDrawalModel });
	}

	IsValidDepositModel = () => {
		var depositModel = { ...this.state.depositModel };
		const { showValidationErrors } = this.props;
		var errorMessages = [];
		if (depositModel.DDTId == null || depositModel.DDTId == 0) errorMessages.push("Wallet number is invalid !");
		if (depositModel.Amount == null || depositModel.Amount == 0) errorMessages.push("Amount can not be null or zero (0) !");

		if (errorMessages.length > 0) {
			showValidationErrors(errorMessages);
			return false;
		}
		return true;
	}

	IsValidWithDrawalModel = () => {
		var withDrawalModel = { ...this.state.withDrawalModel };
		const { showValidationErrors } = this.props;
		var errorMessages = [];
		if (withDrawalModel.DDTId == null || withDrawalModel.DDTId == 0) errorMessages.push("Wallet number is invalid !");
		if (withDrawalModel.Amount == null || withDrawalModel.Amount == 0) errorMessages.push("Amount can not be null or zero (0) !");

		if (errorMessages.length > 0) {
			showValidationErrors(errorMessages);
			return false;
		}
		return true;
	}

	handleClose = () => { this.setState({ openDepositDialog: false, openWithDrawalDialog: false, ddtModel: {}, depositModel: {}, withDrawalModel: {} }); }

	render() {
		const { model, toolBarModel, isLoading, poolModel, isBackOffice, DdtTotalInfo, ddtModel, depositModel, withDrawalModel } = this.state;
		const { Disabled, classes, ExecuteApiPost } = this.props;
		return (
			<div>
				{this.state.alert}
				<LoadingComponent Show={isLoading} />
				{!isBackOffice &&
					<div>
						<ToolBar
							IsNumber
							ItemList={[
								{ Label: "Total No. of DDT", Value: toolBarModel.TotalDDTNumber == null || undefined ? 0 : toolBarModel.TotalDDTNumber + "" },
								{ Label: "Total No. of DDT - CDIC", Value: toolBarModel.TotalCDICNumber == null || undefined ? 0 : toolBarModel.TotalCDICNumber + "" },
								{ Label: "DDT Wallet Balance", Value: toolBarModel.TotalDDTBalance == null || undefined ? 0 : toolBarModel.TotalDDTBalance },
								{ Label: "DDT - CDIC Wallet Balance", Value: toolBarModel.TotalCDICBalance == null || undefined ? 0 : toolBarModel.TotalCDICBalance }
							]} />
					</div>
				}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.GetAllData, Disabled: Disabled },
					{ Code: "Submit", OnClick: this.GetAllData, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.ClearModel, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<Card className="no-radius">
					<GenericExpansionPanel Title={"Main Parameters"}>
						<CardBody>
							<GridContainer>
								<GridItem xs={6}>
									<GenericSelectInput
										Name="UniqueClientId"
										LabelText="Client"
										Method="POST"
										Url="/bankapi/v1.0/BankCustomer/Search"
										Parameter={{}}
										DataRoot="Item"
										KeyValueMember="Id"
										RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
										Value={model.UniqueClientId || ""}
										ValueChanged={this.handleChange}
										All
										CanClear
										Disabled={!isBackOffice} />
									<GridContainer>
										<GridItem xs={6}>
											<GenericSelectInput
												LabelMd={8}
												Name="FinInsId"
												LabelText="Client Primary Wallet"
												Method="GET"
												Url="/bankapi/v1.0/FinancialInstitution/GetAll"
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="FinInsId"
												Value={DefaultBankInformation.FinancialInstitutionId != null ? DefaultBankInformation.FinancialInstitutionId : 0}
												Disabled={true} />
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												Name="PrimaryBranchId"
												LabelMd={0}
												All
												TitleLabel="Branch No."
												Method="GET"
												Url="/bankapi/v1.0/FinancialInstitutionBranch/GetAllDCBankBranches"
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="TransitNo"
												Value={model.PrimaryBranchId == null ? "" : model.PrimaryBranchId}
												ValueChanged={this.handleChange}
												Disabled={!isBackOffice} />
										</GridItem>
										<GridItem xs={3} style={{ paddingRight: 2 + "px !important" }}>
											<GenericNumberInput
												NoFormatting={true}
												InputStyle={{ marginLeft: 2 }}
												LabelMd={0}
												ValueChanged={this.handleChange}
												Name="PrimaryAccountNumber"
												Value={model.PrimaryAccountNumber == null ? "" : model.PrimaryAccountNumber}
												Disabled={!isBackOffice}
												MaxLength={12} />
										</GridItem>
									</GridContainer>
									<GenericSelectInput
										Name="StatusId"
										LabelText="DDT Status"
										Value={model.StatusId == null || undefined ? "" : model.StatusId}
										DataRoot="Item"
										ValueChanged={this.handleChange}
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Method="POST"
										All
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={{
											ParameterCode: "AccountStatus",
											"ParameterValue": "",
											ParameterValue2: "DDT",
											"ParameterDesc": ""
										}} />
									<GridContainer>
										<GridItem xs={6}>
											<GenericSelectInput
												Name="FinInsId"
												LabelMd={8}
												LabelText="DDT Number"
												TitleLabel="FIID"
												Method="GET"
												Url="/bankapi/v1.0/FinancialInstitution/GetAll"
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="FinInsId"
												Value={DefaultBankInformation.FinancialInstitutionId}
												Disabled={true} />
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												key={poolModel.FinInsId}
												Name="BranchId"
												TitleLabel="Branch No."
												LabelMd={0}
												All
												Method="GET"
												Url="/bankapi/v1.0/FinancialInstitutionBranch/GetAllDCBankBranches"
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="TransitNo"
												Value={model.BranchId == null || undefined ? "" : model.BranchId}
												ValueChanged={this.handleChange}
											/>
										</GridItem>
										<GridItem xs={3} style={{ paddingRight: 2 + "px !important" }}>
											<GenericNumberInput
												NoFormatting={true}
												LabelMd={0}
												InputStyle={{ marginLeft: 2 }}
												Name="AccNumber"
												Value={model.AccNumber == null || undefined ? "" : model.AccNumber}
												ValueChanged={this.handleChange}
												MaxLength={12} />
										</GridItem>
									</GridContainer>
								</GridItem>
								<GridItem xs={4}>
									<GenericDateInput
										Name="FromDate"
										LabelText="From"
										Value={model == null || undefined ? "" : model.FromDate ? Formatter.FormatDateUtc(model.FromDate) : ""}
										ValueChanged={this.handleChange}
										MaxDate={model.ToDate == null ? DateHelper.GetDateLocal() : model.ToDate}
										IncludeTime={false}
										Utc />
									<GenericDateInput
										Name="ToDate"
										LabelText="To"
										Value={model == null || undefined ? "" : model.ToDate ? Formatter.FormatDateUtc(model.ToDate) : ""}
										ValueChanged={this.handleChange}
										MaxDate={DateHelper.GetDateLocal()}
										MinDate={model.FromDate}
										IncludeTime={false}
										Utc />
									<GenericTextInput
										Name="CustomerNumber"
										LabelText="Customer Number"
										Value={model.CustomerNumber == null || undefined ? "" : model.CustomerNumber}
										ValueChanged={this.handleChange} />
									<GenericTextInput
										Name="AccountOwnerName"
										LabelText="Wallet Owner"
										Value={model.AccountOwnerName == null || undefined ? "" : model.AccountOwnerName}
										ValueChanged={this.handleChange} />
								</GridItem>
							</GridContainer>
						</CardBody>
					</GenericExpansionPanel>
					<GenericDialog open={this.state.openDepositDialog} >
						<DialogTitle  > <GenericLabel Text="Money Deposit" FontSize="20px" Bold={true} /> </DialogTitle>
						<DialogContent>
							<GridContainer>
								<GridItem xs={6} >
									<GenericSelectInput
										Name="FinInsId"
										LabelText="DDT Number"
										LabelMd={6}
										Method="GET"
										Url="/bankapi/v1.0/FinancialInstitution/GetAll"
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="FinancialInstitutionId"
										Value={DefaultBankInformation.FinancialInstitutionId}
										Disabled={true} />
								</GridItem>
								<GridItem xs={3} >
									<GenericSelectInput
										Name="FinInsBranchId"
										key={ddtModel.FinInsId}
										LabelMd={0}
										Method="GET"
										Url="/bankapi/v1.0/FinancialInstitutionBranch/GetAllDCBankBranches"
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="TransitNumber"
										Value={ddtModel.FinInsBranchId == null || undefined ? "" : ddtModel.FinInsBranchId}
										Disabled={true} />
								</GridItem>
								<GridItem xs={3} >
									<GenericNumberInput
										NoFormatting={true}
										LabelMd={0}
										Name="AccountNumber"
										Value={ddtModel.AccNumber == null || undefined ? "" : ddtModel.AccNumber}
										Disabled={true} />
								</GridItem>
							</GridContainer>
							<GenericTextInput
								Name="CustomerNumber"
								LabelText="CustomerNumber"
								LabelMd={3}
								Value={ddtModel.Id == null || undefined ? "" : ddtModel.Id}
								Disabled={true} />
							<GenericNumberInput
								Name="Amount"
								LabelMd={3}
								LabelText="Amount"
								Value={depositModel.Amount}
								ValueChanged={this.handleChangeDepositModel}
								Prefix={"$"}
								MaxLength={10} />
							<GenericTextInput
								Name="Description"
								LabelMd={3}
								LabelText="Description"
								Value={depositModel.Description == null || undefined ? "" : depositModel.Description}
								ValueChanged={this.handleChangeDepositModel} />
						</DialogContent>
						<GenericDialogActions>
							<Button size="sm" onClick={() => ExecuteApiPost("/bankapi/v1.0/Ddt/MoneyDepositToDdtAcc", depositModel, null, null, this.IsValidDepositModel, [this.GetAllData, this.handleClose], true)} >Confirm</Button>
							<Button size="sm" onClick={this.handleClose} >Close</Button>
						</GenericDialogActions>
					</GenericDialog>
					<GenericDialog open={this.state.openWithDrawalDialog} >
						<DialogTitle  > <GenericLabel Text="Money WithDrawal" FontSize="20px" Bold={true} /> </DialogTitle>
						<DialogContent>
							<GridContainer>
								<GridItem xs={6} >
									<GenericSelectInput
										Name="FinInsId"
										LabelText="DDT Number"
										LabelMd={6}
										Method="GET"
										Url="/bankapi/v1.0/FinancialInstitution/GetAll"
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="FinancialInstitutionId"
										Value={DefaultBankInformation.FinancialInstitutionId}
										Disabled={true} />
								</GridItem>
								<GridItem xs={3} >
									<GenericSelectInput
										Name="FinInsBranchId"
										key={ddtModel.FinInsId}
										LabelMd={0}
										Method="GET"
										Url="/bankapi/v1.0/FinancialInstitutionBranch/GetAllDCBankBranches"
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="TransitNumber"
										Value={ddtModel.FinInsBranchId == null || undefined ? "" : ddtModel.FinInsBranchId}
										Disabled={true} />
								</GridItem>
								<GridItem xs={3} >
									<GenericNumberInput
										NoFormatting={true}
										LabelMd={0}
										Name="AccountNumber"
										Value={ddtModel.AccNumber == null || undefined ? "" : ddtModel.AccNumber}
										Disabled={true} />
								</GridItem>
							</GridContainer>
							<GenericTextInput
								Name="CustomerNumber"
								LabelText="CustomerNumber"
								LabelMd={3}
								Value={ddtModel.Id == null || undefined ? "" : ddtModel.Id}
								Disabled={true} />
							<GenericNumberInput
								Name="Amount"
								LabelMd={3}
								LabelText="Amount"
								Value={withDrawalModel.Amount}
								ValueChanged={this.handleChangeWithDrawalModel}
								Prefix={"$"}
								MaxLength={10} />
							<GenericTextInput
								Name="Description"
								LabelText="Description"
								LabelMd={3}
								Value={withDrawalModel.Description == null || undefined ? "" : withDrawalModel.Description}
								ValueChanged={this.handleChangeWithDrawalModel} />
						</DialogContent>
						<GenericDialogActions>
							<Button size="sm" onClick={() => ExecuteApiPost("/bankapi/v1.0/Ddt/MoneyWithDrawalFromDdtAcc", withDrawalModel, null, null, this.IsValidWithDrawalModel, [this.GetAllData, this.handleClose], true)} >Confirm</Button>
							<Button size="sm" onClick={this.handleClose} >Close</Button>
						</GenericDialogActions>
					</GenericDialog>
				</Card>
				<Card>
					<CardHeader>
						<GenericTitle text={"Wallet List"} />
					</CardHeader>
					<CardBody>
						<GenericGrid
							key={"trxGrid" + this.state.trxGridToken}
							ref={this.trxTable}
							ShowPagination={true}
							PageSize={10}
							ServerSide
							LoadAtStartup={this.state.loadingCompleted}
							PrepareRequest={() => { return this.state.model; }}
							RequestUrl="/bankapi/v1.0/Ddt/DdtSearch"
							RequestMethod="POST"
							Columns={this.columns}
							ProgramCode={ProgramCodes.Bank_Programs_Bank_Account}
							PaginationFetchDataCallback={this.CalculateDdtTotalInfo} />
						<br />
						<GridContainer >
							<GridItem xs={6}></GridItem>
							<GridItem xs={6}>
								<GridContainer className={classes.containerHeight} justify="flex-end">
									<GridItem xs={2} className={classes.gridBorder}>
									</GridItem>
									<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
										<p className={classes.pt}>Active</p>
									</GridItem>
									<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
										<p className={classes.pt}>Inactive</p>
									</GridItem>
									<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
										<p className={classes.pt}>Total</p>
									</GridItem>
									<GridItem xs={3} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
										<p className={classes.pt}>Client Total Balance</p>
									</GridItem>
								</GridContainer>
								<GridContainer justify="flex-end">
									<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold}>
										TOTAL
									</GridItem>
									<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
										{DdtTotalInfo == null || undefined ? 0 : DdtTotalInfo.Active == null || undefined ? 0 : typeof DdtTotalInfo.Active == "number" ? Formatter.FormatNumber(DdtTotalInfo.Active) : DdtTotalInfo.Active}
									</GridItem>
									<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
										{DdtTotalInfo == null || undefined ? 0 : DdtTotalInfo.Inactive == null || undefined ? 0 : typeof DdtTotalInfo.Inactive == "number" ? Formatter.FormatNumber(DdtTotalInfo.Inactive) : DdtTotalInfo.Inactive}
									</GridItem>
									<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
										{DdtTotalInfo == null || undefined ? 0 : DdtTotalInfo.Total == null || undefined ? 0 : typeof DdtTotalInfo.Total == "number" ? Formatter.FormatNumber(DdtTotalInfo.Total) : DdtTotalInfo.Total}
									</GridItem>
									<GridItem xs={3} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
										{DdtTotalInfo == null || undefined ? 0 : DdtTotalInfo.Balance == null || undefined ? 0 : typeof DdtTotalInfo.Balance == "number" ? Formatter.FormatMoney(DdtTotalInfo.Balance) : DdtTotalInfo.Balance}
									</GridItem>
								</GridContainer>
							</GridItem>
						</GridContainer>
						<br />
					</CardBody>
				</Card>
			</div >
		);
	}
}

DdtMoneyTransfer.propTypes = {
	classes: PropTypes.object,
	handleOperationType: PropTypes.func,
	SelectedRowChange: PropTypes.func,
	showQuestionMessage: func,
	setAppLeftTitle: func,
	setAppCenterTitle: func,
	setPortal: func,
	Disabled: bool,
	menuId: number,
	ApprovalData: array,
	After: func
};
// DdtMoneyTransfer.whyDidYouRender = true;
export default withArtifex(DdtMoneyTransfer, DDTListStyle);