import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import withArtifex from "core/withArtifex";
import PropTypes from "prop-types";
import React from "react";
import { GenericAlert, GenericCheckInput, GenericDateInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import TabsComponent from "views/Components/TabsComponent";
import Tcr0 from "views/PPaid/Clearing/Visa/TransactionDetails/Tcr0.jsx";
import Tcr1 from "views/PPaid/Clearing/Visa/TransactionDetails/Tcr1.jsx";
import Tcr4 from "views/PPaid/Clearing/Visa/TransactionDetails/Tcr4.jsx";
import Tcr5 from "views/PPaid/Clearing/Visa/TransactionDetails/Tcr5.jsx";
import Tcr7 from "views/PPaid/Clearing/Visa/TransactionDetails/Tcr7.jsx";


class VisaClearingTransaction extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			list: [],
			model: {},
			smsdatamodel: {},
			chipdatamodel: {},
			paymentservicemodel: {},
			filterModel: {},
			isLoading: false,
			ParentTabIndex: 0,
			ChildTabIndex: 0,
			FileData: {},
			FileDetailsData: {}
		};
	}

	HandleChange = (name, newValue, data) => {
		this.props.onModelChange(model => {
			model[name] = newValue;
			return model;
		});
	}

	HandleClear = () => {
		this.setState({ filterModel: {}, selected: null, list: [] });
	}

	HandleSearch = () => {

	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: "" })} OnConfirm={onConfirm} />
		});
	}

	handleChangeParentTab = (event, active, tab) => {
		if (!tab) {
			this.setState({ ParentTabIndex: active });
		}
	}
	handleChangeChildTab = (event, active, tab) => {
		if (!tab) {
			this.setState({ ChildTabIndex: active });
		}
	}

	render() {
		const { isLoading } = this.state;
		const model = this.props.transactionmodel;
		const smsdatamodel = this.props.smsdatamodel;
		const chipdatamodel = this.props.chipdatamodel;
		const paymentservicemodel = this.props.paymentservicemodel;

		return (
			<div>
				<LoadingComponent Show={isLoading}/>
				{alert}
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card >
							<CardBody>
								<GridContainer spacing={16}>
									<GridItem xs={4}>

										<ParameterComponent
											Name="DirectionId"
											LabelText="Direction"
											ParameterCode="Direction"
											Value={model.DirectionId}
											ValueChanged={this.HandleChange}
											Disabled={true}
										/>
										<ParameterComponent
											Name="ProcessStatusId"
											LabelText="Process Status"
											ParameterCode="ProcessStatus"
											Value={model.ProcessStatusId}
											ValueChanged={this.HandleChange}
										/>
										<ParameterComponent
											Name="EodStatusId"
											LabelText="Eod Status"
											ParameterCode="EodStatus"
											Value={model.EodStatusId}
											ValueChanged={this.HandleChange}
										/>
										<ParameterComponent
											Name="ClearingStatusId"
											LabelText="Clearing Status"
											ParameterCode="ClearingStatus"
											Value={model.ClearingStatusId}
											ValueChanged={this.HandleChange}
										/>
									</GridItem>
									<GridItem xs={4}>
										<ParameterComponent
											Name="ValidationStatusId"
											LabelText="Validation Status"
											ParameterCode="ValidationStatus"
											Value={model.ValidationStatusId}
											Disabled={true}
											ValueChanged={this.HandleChange}
										/>
										<ParameterComponent
											Name="IssuerAcquirerFlagId"
											LabelText="Issuer Acquirer Flag"
											ParameterCode="IssuerAcquirerFlag"
											Value={model.IssuerAcquirerFlagId}
											ValueChanged={this.HandleChange}
											Disabled={false}
										/>
										<GenericTextInput
											Name="ValidationDescription"
											LabelText="Validation Description"
											Value={model.ValidationDescription}
											ValueChanged={this.HandleChange} />

										<GenericDateInput
											Name="EodDate"
											LabelText="Eod Date"
											Value={model.EodDate}
											Disabled={true}
											ValueChanged={this.HandleChange} />
									</GridItem>
									<GridItem xs={4}>
										<GenericDateInput
											Name="ClearingDate"
											LabelText="Clearing Date"
											Value={model.ClearingDate}
											Disabled={true}
											ValueChanged={this.HandleChange} />

										<GenericTextInput
											Name="ARN"
											LabelMd={4}
											LabelText="ARN"
											Format={"(@) (@@@@@@) (@@@@) (@@@@@@@@@@@) (@)"}
											inputProps={40}
											Value={model.ARN}
											ValueChanged={this.HandleChange} />

										<GenericCheckInput
											Name="IsReversal"
											LabelText="Is Reversal"
											Value={model.IsReversal}
											Disabled={true}
											ValueChanged={this.HandleChange}
										/>
									</GridItem>

								</GridContainer>
							</CardBody>
						</Card>

						<br></br>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<TabsComponent
											TabIndex={this.state.ParentTabIndex}
											handleChange={(e, a) => this.handleChangeParentTab(e, a, false)}
											tabList={[
												{
													tabButton: "Tcr0",
													tabContent: (
														<Tcr0
															model={model}
															Disabled={false}
															onModelChange={this.props.onModelChange}
														/>
													),
													Hidden: false
												},
												{
													tabButton: "Tcr1",
													tabContent: (
														<Tcr1
															model={model}
															Disabled={false}
															onModelChange={this.props.onModelChange}
														/>
													),
													Hidden: false
												},
												{
													tabButton: "Tcr4",
													tabContent: (
														<Tcr4
															model={smsdatamodel}
															Disabled={false}
															onModelChange={this.props.onSmsDataChange}
														/>
													),
													Hidden: false
												},
												{
													tabButton: "Tcr5",
													tabContent: (
														<Tcr5
															model={paymentservicemodel}
															Disabled={false}
															onModelChange={this.props.onPaymentServiceModelChange}
														/>
													),
													Hidden: false
												},
												{
													tabButton: "Tcr7",
													tabContent: (
														<Tcr7
															model={chipdatamodel}
															Disabled={false}
															onModelChange={this.props.onChipDataModelChange}
														/>
													),
													Hidden: false
												}
											]}
										/>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

VisaClearingTransaction.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(VisaClearingTransaction, {});