import atmLocationInfoStyle from "assets/jss/material-dashboard-pro-react/views/atmLocationInfoStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericCheckInput, GenericExpansionPanel, GenericGrid, GenericMapContainer, GenericMapMarker, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import { LocationRange } from "views/Constants/Constant";
const Atm = require("assets/img/atm.png");
const AtmEdit = require("assets/img/atm-edit.png");
const AtmNew = require("assets/img/atm-new.png");


class AtmLocationInfo extends React.PureComponent {
	constructor(props) {
		super(props);

		const initialModel = {
			Id: undefined,
			isUniqueDataSearch: undefined,
			Latitude: undefined,
			Longitude: undefined,
			AtmCode: undefined,
			Address1: undefined,
			Address2: undefined,
			CountryId: undefined,
			ProvinceId: undefined,
			CityId: undefined,
			SiteName: undefined,
			PostCode: undefined,
			IsDraggable: undefined,
			EditMode: undefined
		};
		this.state = {
			data: [],
			tempData: [],
			filterModel: { ...initialModel },
			model: { ...initialModel },
			vModel: {},
			vFilterModel: { ...initialModel },
			isEditMode: false,
			focusedLocation:
			{
				lat: 60.736176,
				lng: -102.119770
			},
			mapZoom: 4,
			tempLocation: {
				Latitude: null,
				Longitude: null
			}
		};
		this.markerRef = React.createRef();
		this.googleMapRef = React.createRef();
		this.tableColumns = [
			{
				Header: "Atm Code",
				accessor: "AtmCode"
			},
			{
				Header: "Site Name",
				accessor: "SiteName"
			},
			{
				Header: "Address 1",
				accessor: "Address1"
			},
			{
				Header: "Province",
				accessor: "Province"
			}
		];

		this.markerLatLngList = {};
		this.mapDefaultCenter = { lat: 43.00712742246087, lng: -101.75825072326111 };
		this.mapDefaultOptions = {
			scrollwheel: true,
			disableDefaultUI: true,
			zoomControl: true,
		};
		this.mapPlaceholder = <div style={{ height: "63vh" }} />;
		this.mapContainer = <div
			style={{
				height: "63vh",
				borderRadius: "6px",
				overflow: "hidden"
			}}
		/>;
		this.emptyObject = {};
		this.emptyArray = [];

		this.parameterCityFilterSelect = {};
		this.parameterProvinceFilterSelect = {};

		this.parameterCitySelect = {};
		this.parameterProvinceSelect = {};
	}

	Validate = () => {
		const { model } = this.state;
		const { showValidationErrors } = this.props;
		var errors = [];

		var vModel = {};
		if (!model.Latitude) {
			errors.push("Latitude can not be null.");
			vModel.Latitude = true;
		}
		else if (LocationRange.latitudeMax < model.Latitude || LocationRange.latitudeMin > model.Latitude) {
			errors.push("Latitude must be range in -90/90");
			vModel.Latitude = true;
		}
		if (!model.Longitude) {
			errors.push("Longitude can not be null.");
			vModel.Longitude = true;
		}
		else if (LocationRange.LongitudeMax < model.Longitude || LocationRange.LongitudeMin > model.Longitude) {
			errors.push("Longitude must be range in -180/180");
			vModel.Longitude = true;
		}

		if (!model.AtmCode || model.AtmCode.length == 0) {
			errors.push("Atm Code is empty");
			vModel.AtmCode = true;
		}
		if (!model.Address1 || model.Address1.length == 0) {
			errors.push("Address 1 is empty");
			vModel.Address1 = true;
		}

		if (!model.CountryId) {
			errors.push("Country is empty");
			vModel.CountryId = true;
		}

		if (!model.ProvinceId || model.ProvinceId.length == 0) {
			errors.push("Province is empty");
			vModel.ProvinceId = true;
		}

		if (!model.CityId) {
			errors.push("City is empty");
			vModel.CityId = true;
		}
		if (!model.SiteName || model.SiteName.length == 0) {
			errors.push("Site Name is empty");
			vModel.SiteName = true;
		}
		if (!model.PostCode || model.PostCode.length == 0) {
			errors.push("Post Code is empty");
			vModel.PostCode = true;
		}


		this.setState({ vModel });
		if (errors.length > 0) {
			showValidationErrors(errors);
			return false;
		}
		return true;
	}
	FilterValidate = () => {
		const { filterModel } = this.state;
		const { showValidationErrors } = this.props;
		var errors = [];

		var vFilterModel = {};
		if (!filterModel.AtmCode) {
			if (!filterModel.ProvinceId) {
				errors.push("Province can not be null.");
				vFilterModel.ProvinceId = true;
			}
			if (!filterModel.CountryId) {
				errors.push("Country can not be null.");
				vFilterModel.CountryId = true;
			}
		}

		this.setState({ vFilterModel });
		if (errors.length > 0) {
			showValidationErrors(errors);
			return false;
		}
		return true;
	}
	componentDidMount() {
		this.props.setAppLeftTitle("Atm Location Info");
		this.props.setAppCenterTitle("ATM");
	}

	Clear = () => {
		var tempData = [...this.state.data];
		tempData = tempData.map(item => {
			item.IsOpen = false;
			return item;
		});
		this.setState({ model: {}, vModel: {}, vFilterModel: {}, filterModel: {}, tempData, isEditMode: false });
	}
	Search = async () => {
		if (!this.FilterValidate()) {
			return;
		}
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/bankapi/v1.0/Atm/Search", this.state.filterModel);
		if (result != null) {
			var data = result || { Locations: {}, FocusLocation: { X: null, Y: null } };
			var locations = data.Locations;
			this.setState({
				data: locations, tempData: locations, focusedLocation: {
					lat: data.FocusLocation.X,
					lng: data.FocusLocation.Y
				}, mapZoom: 5
			});
		}
	}
	Submit = async () => {
		if (!this.Validate()) {
			return;
		}
		const { model, data } = this.state;

		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/bankapi/v1.0/Atm/InsertOrUpdate", model);
		if (result != null) {
			var tempData = data || [];
			if (model && model.Id > 0) {
				this.props.showMessage("success", "Success", "Atm Location Info Update Successfully!");
				tempData = tempData.map(item => {

					if (item.Id == model.Id) {
						model.IsDraggable = false;
						return model;
					}
					return item;
				});
			}
			else {
				this.props.showMessage("success", "Success", "Atm Location Info Created Successfully!");
				result.IsDraggable = false;
				tempData.push(result);
			}
			this.setState({ data: tempData, tempData: tempData });
		}
	}

	Delete = async () => {
		const { data, model } = this.state;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/bankapi/v1.0/Atm/Delete", { Id: model?.Id ?? 0 });
		if (result != null) {
			var tempData = data || [];
			tempData = tempData.filter(item => item.Id != model.Id);
			this.setState({ data: tempData, tempData: tempData, model: {} });
			this.props.showMessage("success", "Success", "Atm Location Deleted Successfully!");
		}
	}
	handleChange = (name, newValue, data) => {
		const temp = { ...this.state.model };
		temp[name] = newValue;
		this.setState({ model: temp });
	}
	handleChangeFilter = (name, newValue, data) => {
		const temp = { ...this.state.filterModel };
		temp[name] = newValue;
		if (name == "AtmCode") {
			temp.isUniqueDataSearch = newValue?.length > 0;
		}
		this.setState({ filterModel: temp });
	}

	handleEditLocation = (id) => {
		const temp = [...this.state.tempData];
		var tempLocation = {};
		var modifiedData = temp.map(item => {
			if (item.Id == id) {
				tempLocation.Latitude = item.Latitude;
				tempLocation.Longitude = item.Longitude;
				item.IsDraggable = true;
			}
			return item;
		});
		this.setState({ tempData: modifiedData, isEditMode: true, tempLocation });
	}
	handleEditSubmitLocation = (id) => {
		const temp = [...this.state.tempData];
		const model = { ...this.state.model };
		model.EditMode = false;
		var markerPos = this.markerRef.current.getPosition();
		var pos = this.markerRef.current.getPlace();
		console.log(pos);
		var modifiedData = temp.map(item => {
			if (item.Id == id) {
				item.Latitude = markerPos.lat();
				item.Longitude = markerPos.lng();
				item.IsDraggable = false;
				item.IsOpen = false;
			}
			return item;
		});
		model.Latitude = markerPos.lat();
		model.Longitude = markerPos.lng();
		this.setState({ tempData: modifiedData, model, isEditMode: false });
	}
	handleEditCancelLocation = (id) => {
		const temp = [...this.state.tempData];
		const model = { ...this.state.model };
		const { tempLocation } = this.state;
		if (tempLocation.Latitude && tempLocation.Longitude) {
			model.Latitude = tempLocation.Latitude;
			model.Longitude = tempLocation.Longitude;
		}

		model.EditMode = false;
		var modifiedData = temp.map(item => {
			if (item.Id == id) {
				item.IsDraggable = false;
				item.IsOpen = false;
				if (tempLocation.Latitude && tempLocation.Longitude) {
					item.Latitude = tempLocation.Latitude;
					item.Longitude = tempLocation.Longitude;
				}
			}
			return item;
		});
		this.setState({ tempData: modifiedData, model, isEditMode: false });
	}

	getHandleMarkerClick = (id) => (e) => this.handleMarkerClick(e, id);
	handleMarkerClick = (e, id) => {
		const temp = [...this.state.tempData];
		var markedIndex = -1;
		var tempLocation = {};
		var modifiedData = temp.map((item, index) => {
			item.IsOpen = false;
			if (item.Id == id) {
				item.IsOpen = true;
				tempLocation.Latitude = item.Latitude;
				tempLocation.Longitude = item.Longitude;
				if (id == 0) {
					this.setState({ isEditMode: true });
				}
				markedIndex = index;
			} else {
				item.IsDraggable = false;
			}
			return item;
		});
		var selected = temp.find(a => a.Id == id);
		this.setState({ tempLocation, tempData: modifiedData, model: selected, vModel: {}, vFilterModel: {}, rowIndex: markedIndex });
	}

	handleMarkerDoubleClick = (e) => {
		const model = { ...this.state.model };
		const { data } = this.state;
		var lat = e.latLng.lat();
		var lng = e.latLng.lng();
		var tempData = data || [];
		if (model.Id == 0)
			return;
		if (model && model.Id > 0) {
			if (!model.Latitude || !model.Longitude) {

				model.Latitude = lat;
				model.Longitude = lng;
			}

		} else {
			model.Id = 0;
			model.Latitude = lat;
			model.Longitude = lng;
			model.IsDraggable = true;
			tempData = tempData.filter(item => item.Id != 0);
			tempData.push(model);

		}

		this.setState({ model, tempData, vModel: {}, vFilterModel: {} });
	}

	handleMapClick = () => {
		const temp = [...this.state.tempData];
		var modifiedData = temp.map((item, index) => {
			item.IsOpen = false;
			item.EditMode = false;
			item.IsDraggable = false;
			return item;
		});
		this.setState({ tempData: modifiedData, isEditMode: false });
	}
	SelectedRowChange = (index) => {
		const tempModel = { ...this.state.tempData[index] };
		var focusedLocation = {
			lat: tempModel.Latitude,
			lng: tempModel.Longitude
		};

		this.setState({ model: tempModel, focusedLocation, rowIndex: index, mapZoom: 10 }, () => {
			this.handleMarkerClick(null, tempModel.Id);
		});

	}

	getMarkerPopup = (item) => (props) => {
		return (
			<div>
				<span style={{ textAlign: "center" }}>{item.Town} - {item.Province}</span><br /><br />
					ATM Code: {item.AtmCode} <br />
					Address 1: {item.Address1} <br />
					Post Code:  {item.PostCode} <br />
				<br />
				{item.IsDraggable ? <>
					<Button size="sm" onClick={() => { this.handleEditSubmitLocation(item.Id); }}>Submit</Button>
					<Button size="sm" onClick={() => { this.handleEditCancelLocation(item.Id); }}>Cancel</Button>
				</> : <Button size="sm" onClick={() => { this.handleEditLocation(item.Id); }}>Edit Location</Button>}
			</div>);
	};
	getMarkerPosition = (item) => {
		var latLngList = this.markerLatLngList[item.Id];

		if (!latLngList) {
			var newLatLng = {
				lat: item.Latitude,
				lng: item.Longitude
			};
			this.markerLatLngList[item.Id] = newLatLng;
			return newLatLng;
		}
		else {
			latLngList.lat = item.Latitude;
			latLngList.lng = item.Longitude;
			return latLngList;
		}
	}
	getMarkerList = () => {
		const { tempData, model } = this.state;
		var markerList = tempData.map(item => {
			return <GenericMapMarker
				key={item.IsDraggable + item?.EditMode}
				MarkerRef={item.IsDraggable && this.markerRef}
				position={this.getMarkerPosition(item)}
				draggable={item.IsDraggable && model?.EditMode}
				icon={item.Id == 0 ? AtmNew : item.IsDraggable ? AtmEdit : Atm}
				onClick={this.getHandleMarkerClick(item.Id)}
				OpenPopup={item.IsOpen}
				PopupComponent={item.IsOpen ? this.getMarkerPopup(item) : this.emptyObject}
			/>;
		});
		return markerList;
	}
	getParameterProvinceFilterSelect = (param) => {

		this.parameterProvinceFilterSelect.CountryId = param;
		return this.parameterProvinceFilterSelect;
	}

	getParameterCityFilterSelect = (param) => {
		this.parameterCityFilterSelect.ProvinceId = param;
		return this.parameterCityFilterSelect;
	}

	getParameterProvinceSelect = (param) => {
		this.parameterProvinceSelect.CountryId = param;
		return this.parameterProvinceSelect;
	}

	getParameterCitySelect = (param) => {
		this.parameterCitySelect.ProvinceId = param;
		return this.parameterCitySelect;
	}

	render() {
		const { model, vModel, filterModel, tempData, focusedLocation, mapZoom, isEditMode, vFilterModel } = this.state;
		const { Disabled, classes } = this.props;

		return (
			<div>
				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.Clear, Disabled: Disabled },
					{ Code: "Search", OnClick: this.Search, Disabled: Disabled },
					{ Code: "Submit", OnClick: this.Submit, Disabled: Disabled || (model && model.Id > 0) },
					{ Code: "Update", OnClick: this.Submit, Disabled: Disabled || !model?.Id },
					{ Code: "Delete", OnClick: () => { this.props.showConfirmMessageInDelete(this.Delete); }, Disabled: Disabled || !model?.Id }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Filter Panel">
									<GridContainer>
										<GridItem xs={4}>
											<GenericTextInput
												Name="AtmCode"
												LabelText="Atm Code"
												Value={filterModel == null || undefined ? "" : filterModel.AtmCode || ""}
												ValueChanged={this.handleChangeFilter}
											/>
											<GenericTextInput
												Name="SiteName"
												LabelText="Site Name"
												Value={filterModel == null || undefined ? "" : filterModel.SiteName || ""}
												ValueChanged={this.handleChangeFilter}
											/>
											<GenericTextInput
												Name="Address1"
												LabelText="Address 1"
												Value={filterModel == null || undefined ? "" : filterModel.Address1 || ""}
												ValueChanged={this.handleChangeFilter}
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericSelectInput
												Name="CountryId"
												LabelText="Country"
												Method="POST"
												Url="/coreapi/v1.0/Country/GetAll"
												Parameter={this.emptyObject}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Name"
												Value={filterModel.CountryId}
												ValueChanged={this.handleChangeFilter}
												Disabled={Disabled}
												Required={!filterModel.isUniqueDataSearch}
												IsInvalid={vFilterModel.CountryId}
											/>
											<GenericSelectInput
												key={filterModel.CountryId + "province"}
												Name="ProvinceId"
												LabelText="Province"
												Method="POST"
												Url="/coreapi/v1.0/Province/Filter"
												Parameter={this.getParameterProvinceFilterSelect(filterModel.CountryId)}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Name"
												Value={filterModel.ProvinceId}
												ValueChanged={this.handleChangeFilter}
												Disabled={Disabled}
												Required={!filterModel.isUniqueDataSearch}
												IsInvalid={vFilterModel.ProvinceId}
											/>
											<GenericSelectInput
												key={"city" + filterModel.ProvinceId + ""}
												Name="CityId"
												LabelText="City"
												Method="POST"
												Url="/coreapi/v1.0/City/Filter"
												Parameter={this.getParameterCityFilterSelect(filterModel.ProvinceId)}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Name"
												Value={filterModel.CityId}
												ValueChanged={this.handleChangeFilter}
												Disabled={Disabled}
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericTextInput
												Name="Address2"
												LabelText="Address 2"
												Value={filterModel == null || undefined ? "" : filterModel.Address2 || ""}
												ValueChanged={this.handleChangeFilter}
											/>
											<GenericTextInput
												Name="PostCode"
												LabelText="PostCode"
												Value={filterModel == null || undefined ? "" : filterModel.PostCode || ""}
												ValueChanged={this.handleChangeFilter}
											/>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={12} spacing={16}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Atm Location">
									<GridContainer>
										<GridItem xs={4}>
											<GenericTextInput
												Name="AtmCode"
												LabelText="Atm Code"
												Value={model == null || undefined ? "" : model.AtmCode || ""}
												ValueChanged={this.handleChange}
												IsInvalid={vModel.AtmCode}
												Required={true}
											/>
											<GenericTextInput
												Name="SiteName"
												LabelText="Site Name"
												Value={model == null || undefined ? "" : model.SiteName || ""}
												ValueChanged={this.handleChange}
												IsInvalid={vModel.SiteName}
												Required={true}
											/>
											<GenericTextInput
												Name="Address1"
												LabelText="Address 1"
												Value={model == null || undefined ? "" : model.Address1 || ""}
												ValueChanged={this.handleChange}
												IsInvalid={vModel.Address1}
												Required={true}
											/>
											<GenericTextInput
												Name="Address2"
												LabelText="Address 2"
												Value={model == null || undefined ? "" : model.Address2 || ""}
												ValueChanged={this.handleChange}
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericTextInput
												Name="PostCode"
												LabelText="PostCode"
												Value={model == null || undefined ? "" : model.PostCode || ""}
												ValueChanged={this.handleChange}
												Required={true}
												IsInvalid={vModel.PostCode}
											/>
											<GenericTextInput
												Name="Latitude"
												LabelText="Latitude"
												Value={model == null || undefined ? "" : model.Latitude || ""}
												ValueChanged={this.handleChange}
												Disabled={true}
												Required={true}
												IsInvalid={vModel.Latitude}
											/>
											<GenericTextInput
												Name="Longitude"
												LabelText="Longitude"
												Value={model == null || undefined ? "" : model.Longitude || ""}
												ValueChanged={this.handleChange}
												Disabled={true}
												Required={true}
												IsInvalid={vModel.Longitude}
											/>
											<GenericCheckInput
												Name="EditMode"
												LabelText="Enable Dragging"
												Value={model?.EditMode}
												ValueChanged={this.handleChange}
												Disabled={!isEditMode}
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericSelectInput
												Name="CountryId"
												LabelText="Country"
												Method="POST"
												Url="/coreapi/v1.0/Country/GetAll"
												Parameter={this.emptyObject}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Name"
												Value={model.CountryId}
												ValueChanged={this.handleChange}
												Disabled={Disabled}
												Required={true}
												IsInvalid={vModel.CountryId}
											/>
											<GenericSelectInput
												key={model.CountryId + "province"}
												Name="ProvinceId"
												LabelText="Province"
												Method="POST"
												Url="/coreapi/v1.0/Province/Filter"
												Parameter={this.getParameterProvinceSelect(model.CountryId)}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Name"
												Value={model.ProvinceId}
												ValueChanged={this.handleChange}
												Disabled={Disabled}
												Required={true}
												IsInvalid={vModel.ProvinceId}
											/>
											<GenericSelectInput
												key={"city" + model.ProvinceId + ""}
												Name="CityId"
												LabelText="City"
												Method="POST"
												Url="/coreapi/v1.0/City/Filter"
												Parameter={this.getParameterCitySelect(model.ProvinceId)}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Name"
												Value={model.CityId}
												ValueChanged={this.handleChange}
												Disabled={Disabled}
												Required={true}
												IsInvalid={vModel.CityId}
											/>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>

					<GridContainer xs={12}>
						<GridItem xs={8}>
							<Card className="no-radius">
								<CardBody>
									<GridItem xs={12}>
										<GenericMapContainer
											onRightClick={this.handleMarkerDoubleClick}
											onClick={this.handleMapClick}
											defaultZoom={5}
											zoom={mapZoom}
											// @ts-ignore
											ApiKey={window.GoogleMapApiKey}
											defaultOptions={this.mapDefaultOptions}
											defaultCenter={this.mapDefaultCenter}
											center={focusedLocation}
											loadingElement={this.mapPlaceholder}
											mapElement={this.mapPlaceholder}
											containerElement={this.mapContainer}
										>
											{this.getMarkerList()}
										</GenericMapContainer>
									</GridItem>
								</CardBody>
							</Card>
						</GridItem>
						<GridItem xs={4}>
							<Card className={classes.markerContainer + " no-radius"}>
								<CardBody>
									<GridItem xs={12} className={classes.markerBody}>
										<GenericGrid
											Data={tempData}
											Columns={this.tableColumns}
											RowSelected={this.SelectedRowChange}
											SelectedIndex={this.state.rowIndex}>
										</GenericGrid>
									</GridItem>
								</CardBody>
							</Card>
						</GridItem>
					</GridContainer>
				</GridContainer>
			</div>
		);
	}
}
AtmLocationInfo.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func
};


export default withArtifex(AtmLocationInfo, atmLocationInfoStyle);