// @ts-nocheck
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import PropTypes from "prop-types";
import React from "react";
import { GenericExpansionPanel, GenericGrid } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";

class DisputeAction extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			selected: -1,
			isLoading: false,
			model: {}
		};


	}

	componentDidMount() {
	}

	render() {
		const { isLoading } = this.state;
		return (
			<>
				<LoadingComponent Show={isLoading} />
				<Card className="no-radius">
					<GenericExpansionPanel Title="Action List" IsActive={true}>
						<CardBody>
							<GridContainer xs={12}>
								<GridContainer>
									<GridItem xs={12}>
										<br />
										<GenericGrid
											Data={this.props.DisputeActionData}
											Columns={[
												{
													Header: "Action Code",
													accessor: "ActionCode"
												},
												{
													Header: "Action User",
													accessor: "ActionUser"
												},
												{
													Header: "Action Date",
													accessor: "ActionDate"
												}

											]
											} />
									</GridItem>
								</GridContainer>
							</GridContainer>
						</CardBody>
					</GenericExpansionPanel>
				</Card>


			</>
		);
	}
}

DisputeAction.propTypes = {
	initialModel: PropTypes.any,
	HandleChange: PropTypes.func,
	FillCallback: PropTypes.func,
	LabelMd: PropTypes.number,
	Disabled: PropTypes.bool,
	classes: PropTypes.object.isRequired,
};

export default withArtifex(DisputeAction, {});