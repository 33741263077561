import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDateInput, GenericExpansionPanel,GenericRadioInput, GenericGrid,GenericDialog, GenericSelectInput,GenericAlert,GenericDialogActions,GenericLabel, GenericTitle, GenericNumberInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType, ProgramCodes } from "views/Constants/Constant";
import ParameterComponent from "views/Components/ParameterComponent";
import Button from "components/CustomButtons/Button.jsx";
import { DialogContent, DialogTitle } from "@material-ui/core";
import Select from "react-select";

class SwitchSettlementAggregateReportHundredList extends Component {
	constructor(props) {
		super(props);


		this.initialModel = {
			Id: 0,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined,
			IsMainCard: true,
			ProgramCode: "",
			StartDate: DateHelper.GetLocalDateAsUtc(),
			EndDate: DateHelper.GetLocalDateAsUtc(),
		};

		this.state = {
			model: this.initialModel,
			list: [],
			isLoading: false,
			vModel: {}
		};

		this.emptyObject = {};

		this.maxLength6 = { maxLength: 6 };

		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined
		};

		this.parameterProduct = {};

		this.Columns = [
			{
				Header: "Insert Date Time",
				accessor: "InsertDateTime",
				type: GridColumnType.DateTime
			},
			{
				Header: "Bin",
				accessor: "Bin"
			},
			{
				Header: "Match Status",
				accessor: "MatchStatus"
			},
			{
				Header: "Total Count Credit",
				accessor: "TotalCreditCount"
			},	
			{
				Header: "Total Amount Credit",
				accessor: "TotalCreditAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money,
			},
			{
				Header: "Total Count Debit",
				accessor: "TotalDebitCount"
			},
			{
				Header: "Total Amount Debit",
				accessor: "TotalDebitAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money,
			},

		];

		this.Url = "/prepaidapi/v1.0/Card/SwitchSettlementAggregateReportForHundred";

		this.sortParamValue = { Member: "ParameterValue" };
		
		this.trxTable = React.createRef();
	}

	 showMessage = (type, title, message) => {
		this.setState({
		  alert: (
			 <GenericAlert
				Title={title}
				Message={message}
				Type={type}
				OnConfirm={() => this.setState({ alert: "" })}
			 />
		  ),
		});
	 };
	 setValueToState = (name, value) => {
		this.setState({ [name]: value });
	 };
	 showLoading() {
		this.setState({ isLoading: true });
	 }
  
	 hideLoading() {
		this.setState({ isLoading: false });
	 }
	 ExportClick = () => {
		var trxColumns = [];
		this.trxTable.current.props.Columns.forEach(c => {
			if (c.accessor != "Actions" && c.accessor != "ED" && (c.show == undefined || c.show)) {
				trxColumns.push({ value: c.accessor, title: c.Header, columntype: c.ColumnType, type: c.type });
			}
		}, this);

		this.setState({ isDownloadDialogOpen: true, GridKeyValueList: trxColumns, ExportSelectedColumns: trxColumns });
	}

	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}

	 ExcelDownload = () => {
		if (!this.state.ExportReportType) {
			this.props.showMessage("warning", "Export Report Type not selected", "Select Export Report Type to continue");
			return;
		}

		if (this.state.ExportReportType == "PDF" && this.state.ExportSelectedColumns.length > 15) {
			this.props.showMessage("warning", "PDF document cannot exceed 15 columns", "PDF document cannot exceed 15 columns");
			return;
		}

		this.setState({ isDownloadDialogOpen: false });

		this.props.showConfirmMessage("question", "Long Process Warning", "This process may take a while. Continue ?", () => {
			this.hideAlert();
			const { model } = this.state;
			model.IsClientBalanceScreen = true;

			// Add jsonFile
			var jsonColList = [];

			var trxColumns = {};
			this.state.ExportSelectedColumns.map(c => {
				trxColumns[c.value] = { value: c.value, title: c.title, columntype: c.columntype, type: c.type };
			});
			jsonColList.push(trxColumns);

			model["jsonFile"] = {
				colmns: jsonColList,
				format: this.state.ExportReportType,
				title: "Switch Settlement Aggregate Report"
			};

			this.setState({ isLoading: true });
			Proxy.DownloadGeneratedFile(
				"/prepaidapi/v1.0/Card/SwitchSettlementAggregateForHundredExportDownload",
				model,
				responseData => {
					this.setState({ isLoading: false });
					if (!responseData.IsSucceeded) {
						this.props.showMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
				},
				errorMessage => {
					this.setState({ isLoading: false });
					this.props.showMessage("error", "Error", errorMessage);
				},
				this.props.showMessage
			);
		});
	}



	 openExportDialog = () => {
		this.setState({
		  isDownloadDialogOpen: true,
		});
	 };
  
	 closeExportDialog = () => {
		this.setState({
		  isDownloadDialogOpen: false,
		});
	 };

	componentDidMount() {
		this.props.setAppLeftTitle("Switch Settlement Aggregate Report/100");
		this.props.setAppCenterTitle("CLEARING & SETTLEMENT");
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}

	ValueChangedForExport = (name, value) => {
		this.setState({ [name]: value });
	}

	HandleClear = () => {
		this.setState({ model: { ...this.initialModel }, isEdit: false, index: -1 });
	}

	HandleSearch = () => {
		if (!this.Validate()) {
			return;
		}

		const model = { ...this.state.model };
		model.ProgramCode = this.ProgramCode;
		
		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/Card/SwitchSettlementAggregateReportForHundred",
			model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ list: responseData.Item });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);

	}
	Validate = () => {
		const { model, vModel } = this.state;
		const { showValidationErrors } = this.props;

		var errorList = [];

		if (!model.IsHasCustomer) {
			vModel.StartDate = !model.StartDate;
			if (vModel.StartDate)
				errorList.push("StartDate cannot be empty!");

			vModel.EndDate = !model.EndDate;
			if (vModel.EndDate)
				errorList.push("EndDate cannot be empty!");
		}
		else if (model.IsHasCustomer) {
			vModel.UniqueClientId = !model.UniqueClientId;
			if (vModel.UniqueClientId) {
				errorList.push("Select the Client to continue.");
			}
			vModel.ClientCustomerId = !model.ClientCustomerId;
			if (vModel.ClientCustomerId) {
				errorList.push("Select the Customer to continue.");
			}
			vModel.CardId = !model.CardId;
			if (vModel.CardId) {
				errorList.push("Select the Card to continue.");
			}
		}
		if (errorList.length > 0) {
			showValidationErrors(errorList);
			return false;
		}

		return true;
	}
	




	GetModel = () => { return this.state.model; }

	render() {
		const { Disabled } = this.props;
		const { alert, model, list, isLoading } = this.state;

		return (
			<div>
				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
					{ Code: "Export", OnClick: () => this.ExportClick(), Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Panel">
									<GridItem xs={12}>
										<GridContainer>
											<GridItem xs={4}>
												<GenericDateInput
													Utc
													Name="StartDate"
													LabelText="Start Date"
													Value={model.StartDate ? DateHelper.ToDateInputValue(model.StartDate) : ""}
													ValueChanged={this.HandleChange}
												/>
											</GridItem>
											<GridItem xs={4}>
												<GenericDateInput
													Utc
													Name="EndDate"
													LabelText="End Date"
													Value={model.EndDate ? DateHelper.ToDateInputValue(model.EndDate) : ""}
													ValueChanged={this.HandleChange}
													MinDate={model.StartDate}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
				<GridContainer>
				<GenericDialog open={this.state.isDownloadDialogOpen} fullWidth={true}>
							<DialogTitle>
								<GenericLabel Text="Select Report Type" FontSize="20px" Bold={true} />
							</DialogTitle>
							<DialogContent style={{ height: 300 }}>
								<GenericRadioInput
									Name="ExportReportType"
									LabelText="Report Type"
									IsStatic={true}
									StaticData={[{ Value: "PDF", Text: "PDF" }, { Value: "CSV", Text: "CSV" }, { Value: "EXCEL", Text: "EXCEL" }]}
									KeyValueMember="Value"
									TextValueMember="Text"
									Value={this.state.ExportReportType}
									ValueChanged={this.ValueChangedForExport}
								/>
								<GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
								<GridItem style={{ marginTop: 20 }}>
									<Select
										value={this.state.ExportSelectedColumns}
										onChange={value => this.setState({ ExportSelectedColumns: value })}
										isMulti
										name="Columns"
										getOptionLabel={d => d.title}
										options={this.state.GridKeyValueList}
										className="basic-multi-select"
										classNamePrefix="select"
									/>
								</GridItem>
							</DialogContent>
							<GenericDialogActions>
								<Button size="sm" onClick={this.ExcelDownload}>Download</Button>
								<Button size="sm" onClick={() => this.setState({ isDownloadDialogOpen: false })}>Cancel</Button>
							</GenericDialogActions>
						</GenericDialog>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={"Switch Settlement Aggregate Report/100"} />
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={4}>
									</GridItem>
									<GridItem xs={8} />
								</GridContainer>
								<GridContainer spacing={16}>
									<GridItem xs={12}>
										<GenericGrid
											Data={list}
											Columns={this.Columns}
											ProgramCode={ProgramCodes.Prepaid}
											ShowPagination={true}
											ref={this.trxTable}
											PageSize={100} />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

			</div >
		);
	}
}

SwitchSettlementAggregateReportHundredList.propTypes = {
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool
};

export default withArtifex(SwitchSettlementAggregateReportHundredList, {});