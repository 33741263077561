import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import ClientHelper from "core/ClientHelper";
import PropTypes, { any } from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import { GenericAlert, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTitle, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { ClientType, ProgramCodes } from "views/Constants/Constant";

class BillerList extends Component {

	constructor(props) {
		super(props);

		this.emptyObject = {};
		this.ParameterMaxLength7 = { maxLength: 7 };

		this.renderItemUniqueClient = {};

		this.sortedName = { Member: "Name" };

		this.state = {
			model: {},
			alert: null,
			BillerList: [],
			StaticBillerList: [],
			IsDelete: false

		};
		this.IsClient = ClientHelper.IsClient();
		this.initialModel = {
			Id: 0,
			UniqueClientId: this.IsClient ? ClientHelper.GetClientRowId() : undefined,
		};

		this.IsAvailable = false;

		this.GetBillerList = this.GetBillerList.bind(this);
	}

	ValueChanged = (name, value) => {
		const model = { ...this.state.model };
		model[name] = value;
		if (name == "UniqueClientId" && value == null) {
			model[name] = value;
		}
		this.setState({ model });
	}

	Search = () => {
		this.setState({ isLoading: true });
		this.GetBillerList();

	}

	componentDidMount() {
		this.props.setAppLeftTitle("Payee List");
		this.props.setAppCenterTitle("BILL PAYMENT");

		var isBackOff = ClientHelper.IsBackOffice();
		var model = { ...this.state.model };

		if (!isBackOff) {
			this.IsAvailable = true;
			model.IsClient = true;
			model.UniqueClientId = ClientHelper.GetClientRowId();
		}
		this.setState({ model });
	}
	GetBillerList() {

		const { model } = this.state;
		Proxy.POST(
			"/bankapi/v1.0/Biller/GetBillerList",
			{
				"Code": model.Code,
				"UniqueClientId": model.UniqueClientId
			},
			responseData => {
				if (responseData.Item != null && responseData.Item.length > 0) {


					this.setState({
						isLoading: false,
						BillerList: responseData.Item
					});
				}
				else {
					this.setState({
						isLoading: false,
						BillerList: []
					});
				}
			}
		);

	}
	DeleteDialog = () => {
		const { model } = { ...this.state };
		this.showQuestionMessage("Are you sure you want to delete payee? ", model);
	}

	showQuestionMessage = (message, model) => {
		this.setState({
			alert: <GenericAlert Title="Delete" Message={message} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.Delete()} />
		});
		this.setState({ isLoading: false });
	}

	hideAlert() {
		this.setState({
			alert: null,
			isLoading: false
		});
	}
	ShowMessage(type, title, message) {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
	}


	SelectedRowChange = (index) => {
		const biller = this.state.BillerList[index];
		this.setState({ model: biller, rowIndex: index });
	}

	Clear = () => {

		var model = { ...this.state.model };
		var UniqueClientId = model.UniqueClientId;
		model = any;
		model.UniqueClientId = UniqueClientId;
		if (!this.IsAvailable) {
			model.UniqueClientId = any;
		}
		this.setState({ model: model });
	}

	GetRenderItemUniqueClient = (x) => {
		this.renderItemUniqueClient = `${x.Name} - ${x.UniqueClientId}`;

		return this.renderItemUniqueClient;
	}
	render() {

		const { model } = this.state;
		return (
			<div >

				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: false,
							OnClick: this.Search
						},
						{
							Code: "Clear",
							Disabled: false,
							OnClick: this.Clear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After}

				/>
				{this.state.alert}
				<LoadingComponent Show={this.state.isLoading} />


				<Card>
					<GenericExpansionPanel >
						<CardBody>
							<GridContainer >
								<GridItem xs={6}>


									<GenericSelectInput
										Name="UniqueClientId"
										LabelText="Client"
										Method="POST"
										Url="/bankapi/v1.0/BankCustomer/Search"
										Parameter={this.emptyObject}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="Name"
										RenderItem={this.GetRenderItemUniqueClient}
										Sorted={this.sortedName}
										All={true}
										Value={model.UniqueClientId}
										ValueChanged={this.ValueChanged}
										Disabled={this.IsClient}
									/>

								</GridItem>

								<GridItem xs={6}>
									<GenericTextInput
										inputProps={this.ParameterMaxLength7}
										Name="Code"
										LabelText="Payee Code"
										Value={model.Code}
										Disabled={false}
										ValueChanged={this.ValueChanged}
									/>
								</GridItem>


							</GridContainer>

						</CardBody>
					</GenericExpansionPanel>
				</Card>

				<Card className="no-radius">

					<CardBody>
						<CardHeader>
							<GenericTitle text={"Payee List"} />
						</CardHeader>
						<GridItem md={12} xs={12}>
							<GridItem>
								<GenericGrid
									Data={this.state.BillerList}
									Columns={[
										{
											Header: "UniqueClientId",
											accessor: "UniqueClientId"
										},
										{
											Header: "Client Name",
											accessor: "ClientName"
										},
										{
											Header: "Payee Name",
											accessor: "BillerName"
										},
										{
											Header: "Payee Code",
											accessor: "BillerCode"
										}

									]}
									PageSize={500}
									ProgramCode={ProgramCodes.Bank_Programs_Bank_Account} 
									ShowPagination={true}
									/>
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>

			</div >
		);
	}
}

BillerList.propTypes = {
	model: PropTypes.object,
	onModelChange: PropTypes.func,
	Disabled: PropTypes.bool
};

export default BillerList;