import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import { DeleteIcon, DownloadIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import { GenericAlert, GenericGrid, GenericLabel, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";

class LoanApplicationDocument extends React.Component {
	constructor(props) {
		super(props);
		this.UploadClick = this.UploadClick.bind(this);
		this.FileSelect = this.FileSelect.bind(this);
		this.UpdateModel = this.UpdateModel.bind(this);
		this.UpdateFile = this.UpdateFile.bind(this);

		this.fileInputRef = React.createRef();

		this.state = {
			documentModel: { File: { FileName: "" } },
			documentList: [],
			isLoading: false,
			alert: null
		};
		this.ParameterDocumentType = { ParameterCode: "LOSDocumentType" };
		this.ParameterDocumentVerifiedStatusType = { ParameterCode: "LOSVerifiedStatus" };
		this.VerifyingPersonParameterCode = { ParameterCode: "IdentityVerifyingPerson" };

		this.ColumnsData = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip="Edit"
							Icon="EditIcon"
							OnClick={() => this.setState({ documentModel: { ...row.original } })} />
						<GridButton
							tooltip="Delete"
							Icon={DeleteIcon}
							OnClick={() => this.showQuestionMessage("Delete this record !", row.original)} />
						{this.props.ApplicationModel.Id > 0 &&
							<GridButton
								tooltip="Download"
								Icon={DownloadIcon}
								OnClick={() => this.DownloadFile(row.original)} />
						}
					</div>
				),
				sortable: false,
				filterable: false,
				width: 100
			},
			{
				Header: "Document Type",
				accessor: "DocumentType.ParameterDesc"
			},
			{
				Header: "Verification Status",
				accessor: "VerificationStatus.ParameterDesc"
			},
			{
				Header: "Verified Person",
				accessor: "VerifiedPerson.ParameterDesc"
			}
		];
	}
	componentDidMount() {
		if (this.props.setAppLeftTitle)
			this.props.setAppLeftTitle("Documents");


		var model = { Id: 0 };
		this.setState({ documentModel: model, ApplicationDocumentList: this.props.ApplicationDocumentList });

	}
	UploadClick() {
		this.fileInputRef.current.click();
	}

	FileSelect(e) {
		e.preventDefault();

		var file = e.target.files[0];
		if (!file) return;
		var documentModel = this.state.documentModel;
		if (documentModel.File == undefined)
			documentModel.File = { FileName: file.name };
		else
			documentModel.File.FileName = file.name;
		this.setState({ fileModel: file, documentModel });
	}

	OperationCheck = () => {

		var ErrorList = [];
		const data = this.state.documentModel;

		if (data == null) {
			ErrorList.push("Document model not defined.");
			this.ShowMessageNode("warning", "Please Fill Required Fields", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		if (data.DocumentTypeId == null || data.DocumentTypeId == "") {
			ErrorList.push("Document type must be defined.");
		}
		if (data.VerificationStatusId == null || data.VerificationStatusId == "") {
			ErrorList.push("Document verified status must be defined.");
		}
		if (data.VerifiedPersonId == null || data.VerifiedPersonId == "") {
			ErrorList.push("Document verified person must be defined.");
		}

		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please Fill Required Fields", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.UpdateModel();
	}
	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}

	UpdateModel = () => {
		var { ApplicationModel } = this.props;
		var temp = this.state.documentModel;

		if (ApplicationModel.Id == undefined || ApplicationModel.Id == 0) {
			var formData = new FormData();
			formData.append("file", this.state.fileModel, String(this.state.fileModel.name));
			var response = this.props.AddDocumentToTempList(temp, formData);
			if (response == false)
				return;

			this.ShowMessage("success", "Success", this.state.documentModel.Id > 0 ? "Application Document Updated" : "Application Document Saved");
			this.ClearData();
			this.props.GetApplicationDocumentModels();
			return;


		}
		temp.ApplicationId = ApplicationModel.Id;
		this.props.TaskPoolFunc.AppendTask(
			Proxy.POST(
				"/losapi/v1.0/ApplicationDocument/InsertUpdate",
				temp,
				responseData => {
					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					this.UpdateFile(responseData.Item.Id);
				},
				error => {
					this.ShowMessage("error", "Error", error);
				}
			));
	}
	UpdateFile = (documentId) => {
		var formData = new FormData();
		formData.append("file", this.state.fileModel, String(this.state.fileModel.name));
		formData.append("documentId", String(documentId));
		this.props.TaskPoolFunc.AppendTask(
			Proxy.FileUpload(
				"/losapi/v1.0/ApplicationDocument/UploadFile",
				formData,
				responseData => {
					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					this.ShowMessage("success", "Success", this.state.documentModel.Id > 0 ? "Application Document Updated" : "Application Document Saved");
					this.ClearData();
					this.props.GetApplicationDocumentModels();
				},
				error => {
					this.ShowMessage("error", "Error", error);
				}
			));
	}
	DeleteModel = (model) => {
		if (model == null || model.Id == null || model.Id == 0) {
			this.ShowMessage("error", "Warning", "Document must be selected for delete operation.");
			return;
		}
		if (model.Id < 0) {
			this.props.DeleteTempDocument(model.Id);
			this.ShowMessage("success", "Success", "Application Document Deleted");
			this.ClearData();
			return;
		}
		this.props.TaskPoolFunc.AppendTask(
			Proxy.POST(
				"/losapi/v1.0/ApplicationDocument/Delete",
				{ Id: model.Id },
				responseData => {
					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					this.ShowMessage("success", "Success", "Application Document Deleted");
					this.ClearData();
					this.props.GetApplicationDocumentModels();
				},
				error => {
					this.ShowMessage("error", "Error", error);
				}
			));
	}
	SelectedRowChange = (e, index) => {
		var { ApplicationModel } = this.props;
		if (ApplicationModel.Id == undefined || ApplicationModel.Id == 0) {
			const model = this.props.TempApplicationDocumentList[index];
			this.setState({ documentModel: model.documentModel || {} });
		} else {
			const model = this.props.ApplicationDocumentList[index];
			this.setState({ documentModel: model || {} });
		}

	}
	ClearData = () => {
		var model = { Id: 0 };
		this.setState({ documentModel: model });
	}
	handleChange = (name, newValue, data) => {
		this.setState(function (state, props) {
			var model = state.documentModel || {};
			model[name] = newValue;
			if (name == "DocumentTypeId") {
				model.DocumentType = data;
			}
			if (name == "VerificationStatusId") {
				model.VerificationStatus = data;
			}
			if (name == "VerifiedPersonId") {
				model.VerifiedPerson = data;
			}
			return { documentModel: model };
		});
	}

	showQuestionMessage = (message, model) => {
		this.setState({
			alert: <GenericAlert Title="Delete" Message={message} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.DeleteModel(model)} />
		});
		this.setState({ isLoading: false });
	}
	ShowMessage = (type, title, message) => {
		// type:-success->warning->info->error
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}
	DownloadFile = (index) => {
		this.props.TaskPoolFunc.AppendTask(
			Proxy.FileDownloadWithGenericResponse(
				"/coreapi/v1.0/File/DownloadFileFromBinary",
				index.File.FileName,
				index.File.FileExtension.ParameterDesc,
				{
					"FileId": index.FileId
				},
				() => {
				},
				(error) => {
					this.ShowMessage("error", "An error occurred while sending data", error.message);
				}
			));
	}
	render() {
		const { Disabled: DisabledProp, ApplicationDocumentList } = this.props;
		const { documentModel } = this.state;
		const Disabled = DisabledProp || this.state.CustomDisabled;

		return (
			<GridContainer spacing={16}>
				{this.state.alert}

				<LoadingComponent Show={this.state.isLoading} />

				<GridItem md={12}>
					<GridContainer>
						<GridItem xs={12} sm={4} md={4} lg={4}>
							<GenericSelectInput
								Name="DocumentTypeId"
								LabelText="Document Type"
								Value={documentModel == null ? "" : documentModel.DocumentTypeId || ""}
								ValueChanged={this.handleChange}
								KeyValueMember="Id"
								TextValueMember="ParameterDesc"
								Method="POST"
								Url="/coreapi/v1.0/Parameter/Search"
								Parameter={this.ParameterDocumentType}
								Disabled={Disabled}
								DataRoot="Item" />

							<GenericSelectInput
								Name="VerificationStatusId"
								LabelText="Verification Status"
								Value={documentModel == null ? "" : documentModel.VerificationStatusId || ""}
								ValueChanged={this.handleChange}
								KeyValueMember="Id"
								TextValueMember="ParameterDesc"
								Method="POST"
								Url="/coreapi/v1.0/Parameter/Search"
								Parameter={this.ParameterDocumentVerifiedStatusType}
								Disabled={Disabled}
								DataRoot="Item" />

							<GenericSelectInput
								Name="VerifiedPersonId"
								LabelText="Verified Person"
								Value={documentModel == null ? "" : documentModel.VerifiedPersonId || ""}
								ValueChanged={this.handleChange}
								KeyValueMember="Id"
								TextValueMember="ParameterDesc"
								Method="POST"
								Url="/coreapi/v1.0/Parameter/Search"
								Parameter={this.VerifyingPersonParameterCode}
								Disabled={Disabled}
								DataRoot="Item" />

						</GridItem>
						<GridItem md={2} xs={12}>
							<GenericLabel Text="Upload File" />
							<Button onClick={this.UploadClick} disabled={Disabled} style={{ marginLeft: "16px" }}>Browse</Button>
							<input key={documentModel.FileName} type="file" style={{ display: "none" }} onChange={this.FileSelect} ref={this.fileInputRef}
								accept=".pdf" />
						</GridItem>
						<GridItem md={4} xs={12}>
							<GenericTextInput
								key={this.state.uniq}
								Name="FileName"
								LabelMd={5}
								LabelText="File Name"
								Value={documentModel.File == null || documentModel.File.FileName == null || undefined ? "" : documentModel.File.FileName}
								Disabled={true} />
						</GridItem>

					</GridContainer>
					<GridContainer justify="flex-end">
						<GridItem>
							<Button disabled={Disabled} size="sm" color="black" onClick={() => this.ClearData()}>	Clear	</Button>
							<Button disabled={Disabled} size="sm" color="black" onClick={() => this.OperationCheck()}>{documentModel.Id > 0 ? "Update" : "Add"}</Button>
						</GridItem>
					</GridContainer>
				</GridItem>
				<GridItem md={12}>
					<GridItem xs={12} md={12}>
						<GenericTitle text={"Document List"} />
					</GridItem>
					<GridItem md={12} xs={12}>
						<GridItem>
							<GenericGrid
								Data={ApplicationDocumentList}
								PageSize={5}
								HideButton={true}
								Columns={this.ColumnsData}
								RowSelected={index => {
									this.SelectedRowChange(0, index);
								}}
							/>
						</GridItem>
					</GridItem>

				</GridItem>
			</GridContainer >
		);
	}
}

LoanApplicationDocument.propTypes = {
	classes: PropTypes.object,
	documentModel: PropTypes.object,
	ApplicationModel: PropTypes.object,
	paramApplicationProfile: PropTypes.object,
	ApplicationDocumentList: PropTypes.array,
	GetApplicationDocumentModels: PropTypes.func,
	paramBankApplicationStoreList: PropTypes.array,
	handleChangeParentTab: PropTypes.func,
	handleChangeChildTab: PropTypes.func,
	SetFile: PropTypes.func,
	TaskPoolFunc: PropTypes.func,
	TempApplicationDocumentList: PropTypes.array,
	AddDocumentToTempList: PropTypes.func,
	DeleteTempDocument: PropTypes.func,
};

export default withArtifex(LoanApplicationDocument, {});