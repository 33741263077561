import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDateInput, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType, ProgramCodes } from "views/Constants/Constant";

class CardStatus extends Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			CardId: 0,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined,
			IsMainCard: true
		};

		this.state = {
			model: this.initialModel,
			list: [],
			isLoading: false
		}

		this.emptyObject = {};

		this.maxLength19 = { maxLength: 19 };

		this.maxLength4 = { maxLength: 4 };

		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined
		};

		this.renderItemStatus = {};

		this.parameterProduct = {};

		this.Columns = [
			{
				Header: "Card Token Number",
				accessor: "CardTokenNumber"
			},
			{
				Header: "MCC",
				accessor: "MCC"
			},
			{
				Header: "Insert User",
				accessor: "InsertUser"
			},
			{
				Header: "Insert Date Time",
				accessor: "InsertDateTime",
				type: GridColumnType.DateTime,
				ColumnType: GridColumnType.DateTime
			},
			{
				Header: "Update User",
				accessor: "UpdateUser"
			},
			{
				Header: "Update Date Time",
				accessor: "UpdateDateTime",
				type: GridColumnType.DateTime,
				ColumnType: GridColumnType.DateTime
			}
		];
	}

	componentDidMount() {
		this.props.setAppLeftTitle("MCC Permission");
		this.props.setAppCenterTitle("MCC PERMISSION MANAGEMENT");
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		if (newValue == null) {
			model[name] = 0;
		}

		this.setState({ model });
	}

	HandleClear = () => {
		this.setState({ model: { ...this.initialModel }, isEdit: false, index: -1 });
	}

	HandleSearch = () => {
		const model = { ...this.state.model };
		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/Card/GetMCCPermission",
			{
				MCC: model.MCC,
				CardTokenNumber: model.CardTokenNumber,
				StartDate: model.StartDate,
				EndDate: model.EndDate,
				Id: model.Id,
				CardId : model.CardId
			},
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ list: responseData.Item });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	HandleUpdate = () => {
		if (!this.Validate()) {
			return;
		}
		const model = { ...this.state.model };
		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/Card/UpdateMCCPermission",
			{
				MCC: model.MCC,
				Id: model.Id,
				CardId : model.CardId
			},
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.IsSucceeded) {
					this.setState({ model: { ...this.initialModel }, index: -1 });
					this.props.showMessage("success", "Succeeded", "Operation is successfully!");
					this.HandleSearch();
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}
		
	handleChange = (name, newValue, data) => {
		const temp = { ...this.state.model };
		temp[name] = newValue;
		this.setState({ model: temp });
	}

	Validate = () => {
		const { model } = this.state;

		if(model.MCC.length < 4){
			this.props.showMessage("warning", "MCC Error", "Please Enter 4 Characters.");
			return false;
		}

	return true;
	}
	
	ValueChanged = (name, value) => {
		this.setState(state => {
			var model = state.model;

			model[name] = value;

			if (name == "FromDate") {
				model["ToDate"] = undefined;
			}

			return { model };
		});
	}

	RenderItemStatus = (d) => {
		this.renderItemStatus = `${d.Description} - ${d.ResponseCode}`;
		return this.renderItemStatus;
	}

	RowSelected = (index) => {
		let { list } = this.state;
		const tempModel = list[index];
		if (list[index].CardRequestId) {
			tempModel.Id = list[index].Id;
		}
		this.setState({ isEdit: true, model: tempModel, index });
	}

	GetProductParameter = () => {
		this.parameterProduct.UniqueClientId = this.state.model.UniqueClientId;
		this.parameterProduct.IsNoNameProduct = false;
		return this.parameterProduct;
	}

	FillCustomer = (customer) => {
		this.HandleChange("Customer", customer);
	}

	render() {
		const { Disabled } = this.props;
		const { alert, model, list, isLoading, index } = this.state;

		return (
			<div>

				<LoadingComponent Show={isLoading} />


				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
					{ Code: "Update", OnClick: this.HandleUpdate, Disabled: Disabled || model.Id === 0 },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<Card className="no-radius">
					<CardBody>
						<GenericExpansionPanel Title="Main Panel">
							<GridContainer>
								<GridItem xs={4}>
									<GenericNumberInput
										Name="MCC"
										LabelText="MCC"
										NoFormatting={true}
										MaxLength={4}
										Value={model.MCC}
										ValueChanged={this.HandleChange}
									/>
									<GenericTextInput
										Name="CardTokenNumber"
										LabelText="Card Token Number"
										inputProps={this.maxLength19}
										Value={model.CardTokenNumber}
										ValueChanged={this.HandleChange}
										Disabled={model.Id>0}
									/>
								</GridItem>
								<GridItem xs={4}>
									<GenericDateInput
										Utc
										Name="StartDate"
										LabelText="Start Date"
										Value={model.StartDate ? DateHelper.ToDateInputValue(model.StartDate) : ""}
										ValueChanged={this.HandleChange}
										MaxDate={model.EndDate}
									/>
									<GenericDateInput
										Utc
										Name="EndDate"
										LabelText="End Date"
										Value={model.EndDate ? DateHelper.ToDateInputValue(model.EndDate) : ""}
										ValueChanged={this.HandleChange}
										MinDate={model.StartDate}
									/>
								</GridItem>
							</GridContainer>
						</GenericExpansionPanel>
					</CardBody>
				</Card>

				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={""} />
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={4}>
									</GridItem>
									<GridItem xs={8} />
								</GridContainer>
								<GridContainer spacing={16}>
									<GridItem xs={12}>
										<GenericGrid
											Data={list}
											Columns={this.Columns}
											RowSelected={this.RowSelected}
											SelectedIndex={index}
											ProgramCode={ProgramCodes.Prepaid} 
											PageSize={10}
											ShowPagination={true}
										/>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

CardStatus.propTypes = {
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool
};

export default withArtifex(CardStatus, {});