// @ts-nocheck
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import AlertHelper from "core/AlertHelper";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDateInput, GenericGrid, GenericTextInput, GenericTitle, GenericNumberInput, GenericDialogActions, GenericDialog, GenericLabel, GenericAlert } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType } from "views/Constants/Constant.js";
import ParameterComponent from "views/Components/ParameterComponent";
import TabsComponent from "views/Components/TabsComponent";
import GridButton from "views/Components/GridButton";
import { UndoIcon, EditIcon } from "core/Icons";
import { DialogContent, DialogTitle } from "@material-ui/core";
import Button from "components/CustomButtons/Button.jsx";


class WireReconciliation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			model: {
				FromDate: DateHelper.GetDate(),
				ToDate: DateHelper.GetDate()
			},
			editModel: {},
			settlementFileList: [],
			headerModel: {},
			transactionList: [],
			isLoading: false,
			SettlementType: "",
			vModel: {},
			searchModel: {}
		};
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Wire Reconciliation");
		this.props.setAppCenterTitle("CLEARING & SETTLEMENT");
	}

	handleChange = (name, newValue, data) => {
		const model = { ...this.state.model };

		model[name] = newValue;
		this.setState({ model });
	}

	handleChangeForEdit = (name, newValue, data) => {
		const editModel = { ...this.state.editModel };

		editModel[name] = newValue;
		this.setState({ editModel });
	}

	GetSettlementFileList = () => {
		if(this.ValidateSearch()){
			this.setState({ isLoading: true });
			Proxy.POST(
				"/bankapi/v1.0/WireTransfer/WireTransferSettlementFileSearch",
				this.state.model,
				this.successSettlementFileList,
				error => {
					this.showBasicAlert("Error", "An error occurred during the api visit" + error, "error");
				}
			);
		}
	}

	successSettlementFileList = (responseData) => {
		if (!responseData.IsSucceeded) {
			this.showBasicAlert("Error", responseData.ErrorDescription, "error");
			return;
		}

		this.setState({
			headerModel: {},  isLoading: false,
			vostroList: responseData.Item.VostroList == null ? [] : responseData.Item.VostroList.length < 1 ? [] : responseData.Item.VostroList,
			bridgeList: responseData.Item.BridgeList == null ? [] : responseData.Item.BridgeList.length < 1 ? [] : responseData.Item.BridgeList,
			searchModel : responseData.Item
		});
	}

	showBasicAlert = (title, message, type) => {
		this.setState({ isLoading: false, alert: AlertHelper.CreateAlert(title, message, type, this.hideAlert) });
	}

	hideAlert = () => { this.setState({ alert: null, isLoading: false }); }

	showLoading = () => { this.setState({ alert: null, isLoading: true }); }

	ClearModel = () => {
		this.setState({
			model: {
				FromDate: DateHelper.GetDateTime(), ToDate: DateHelper.GetDateTime(),
			},
			editModel: { },
			searchModel: {}
		});
	}

	handleChangeParentTab(tab) { }

	Validate = () => {
		const { editModel } = this.state;
		const { showValidationErrors } = this.props;
		var errors = [];

		if (editModel.Credit > 0 && editModel.Debit > 0) {
			errors.push("Credit or Debit must be filled!");
		}
		if (editModel.Credit==null && editModel.Debit==null && editModel.CategoryId == null) {
			errors.push("Select field!");
		}

		if (errors.length > 0) {
			showValidationErrors(errors);

			return false;
		}

		return true;
	}

	 openEditWireDialog = (row) => {  
		const editModel = { ...this.state.editModel };

		editModel["BAI2Id"] = row.BAI2Id;

		this.setState({
			isEditWireDialogOpen: true, editModel
			});
	 };

	 handleEditWire = () => {
		const { showMessage } = this.props;
		this.setState({ isLoading: true });

		if (this.Validate())
		{
			Proxy.POST(
				"/bankapi/v1.0/WireTransfer/UpdateBAI2Transaction",
				this.state.editModel,
				(responseData) => {
					this.setState({ isLoading: false, isEditWireDialogOpen: false });
	  
					if (!responseData.IsSucceeded) {
					  showMessage("error", "Error", responseData.ErrorDescription);
					  return;
					}
					showMessage("success", "Success", responseData.ErrorDescription);
				 },
				error => {
					this.showBasicAlert("Error", "An error occurred during the api visit" + error, "error");
				}
			);
		}	
	}

	handleUndoChanges = (row) => {
		const { showMessage } = this.props;
		this.setState({ isLoading: true });

		Proxy.POST(
			"/bankapi/v1.0/WireTransfer/UpdateBAI2Transaction",
			{BAI2Id: row.BAI2Id, UndoAllChanges:true},
			(responseData) => {
				this.setState({ isLoading: false });
	
				if (!responseData.IsSucceeded) {
					showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				showMessage("success", "Success", responseData.ErrorDescription);
				},
			error => {
				this.showBasicAlert("Error", "An error occurred during the api visit" + error, "error");
			}
		);
	}

	ShowQuestionMessage = (message, model) => {
		this.setState({
			alert: AlertHelper.CreateQuestionAlert("Undo", message, "warning", e => {
				this.handleUndoChanges(model);
				this.HideAlert();
			}, () => {
				this.HideAlert();
			})
		});
	}

	HideAlert = () => {
		this.setState({ alert: null });
	}

	ValidateSearch = () => {
		const { model, vModel } = this.state;
		const { showValidationErrors } = this.props;
		var errors = [];

		console.log(model);
		if (model.FromDate == null || model.FromDate == "") {
			errors.push("From Bridge Date must be selected");
			vModel.FromDate = true;
		} else {
			vModel.FromDate = false;
		}
		if (model.ToDate == null || model.ToDate == "") {
			errors.push("To Bridge Date must be selected");
			vModel.ToDate = true;
		} else {
			vModel.ToDate = false;
		}

		if (errors.length > 0) {
			showValidationErrors(errors);

			return false;
		}

		return true;
	}

	render() {
		const { Disabled } = this.props;
		const { model, isLoading, isEditWireDialogOpen, editModel, vModel, searchModel } = this.state;
		return (
			<div>
				<LoadingComponent Show={isLoading} />
				{this.state.alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.GetSettlementFileList, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.ClearModel, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer>
					<GridItem xs={7}>
						<Card style={{ height: "90%" }}>
							<CardHeader>
								<GenericTitle text={"Filtering Criteria"} />
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={6}>
										<GenericDateInput
											Name="FromDate"
											LabelText="From Bridge Date"
											Value={model.FromDate}
											ValueChanged={this.handleChange}
											MaxDate={model.ToDate || DateHelper.GetDate()}
											IncludeTime={false}
											IsInvalid={vModel.FromDate}
											LabelMd={6}
											Required
											Utc />
										<GenericDateInput
											Name="ToDate"
											LabelText="To Bridge Date"
											Value={model.ToDate}
											ValueChanged={this.handleChange}
											MinDate={model.FromDate}
											MaxDate={DateHelper.GetDate()}
											IncludeTime={false}
											IsInvalid={vModel.ToDate}
											LabelMd={6}
											Required
											Utc />
										<ParameterComponent
											Name="SettlementStatusId"
											LabelText="Settlement Status"
											ParameterCode="WireTransferMatchStatus"
											Value={model.SettlementStatusId}
											LabelMd={6}
											ValueChanged={this.handleChange} />
										<GenericDateInput
											Name="FromSettlementDate"
											LabelText="From Settlement Date"
											Value={model.FromSettlementDate}
											ValueChanged={this.handleChange}
											IncludeTime={false}
											LabelMd={6}
											Utc />
										<GenericDateInput
											Name="ToSettlementDate"
											LabelText="To Settlement Date"
											Value={model.ToSettlementDate}
											ValueChanged={this.handleChange}
											IncludeTime={false}
											LabelMd={6}
											Utc />
										<GenericDateInput
											Name="BAI2DateFrom"
											LabelText="BAI2 Date From"
											Value={model.BAI2DateFrom}
											ValueChanged={this.handleChange}
											IncludeTime={false}
											LabelMd={6}
											Utc />
										<GenericDateInput
											Name="BAI2DateTo"
											LabelText="BAI2 Date To"
											Value={model.BAI2DateTo}
											ValueChanged={this.handleChange}
											IncludeTime={false}
											LabelMd={6}
											Utc />
										<br/>
									</GridItem>
									<GridItem xs={6}>
										<ParameterComponent
											Name="CategoryId"
											LabelText="Category"
											ParameterCode="WireTransferCategoryType"
											Value={model.CategoryId}
											LabelMd={6}
											ValueChanged={this.handleChange} />
										<GenericDateInput
											Name="AdjustmentDate"
											LabelText="Adjustment Date"
											Value={model.AdjustmentDate}
											ValueChanged={this.handleChange}
											IncludeTime={false}
											LabelMd={6}
											Utc />
										<GenericTextInput
											Name="BAI2Description"
											LabelText="BAI2 Description"
											Value={model.BAI2Description}
											LabelMd={6}
											ValueChanged={this.handleChange} />
										<GenericNumberInput
											Name="Credit"
											LabelText="Credit"
											LabelMd={6}
											Value={model.Credit}
											ValueChanged={this.handleChange}
											MaxLength={10}
											Prefix="$" />
										<GenericNumberInput
											Name="Debit"
											LabelText="Debit"
											LabelMd={6}
											Value={model.Debit}
											ValueChanged={this.handleChange}
											MaxLength={10}
											Prefix="$" />
										<GenericTextInput
											Name="ClientName"
											LabelText="Client/FI Name"
											Value={model.ClientName}
											LabelMd={6}
											ValueChanged={this.handleChange} />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={5}>
						<Card style={{ height: "90%" }}>
							<CardHeader>
								<GenericTitle text={"Date Range Results"} />
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={6}>
										<GenericNumberInput
											Name="TotalCredit"
											LabelText="Total Credit"
											Value={searchModel.TotalCredit}
											ValueChanged={this.handleChange}
											Prefix="$" 
											Disabled={true} />
										<GenericNumberInput
											Name="TotalDebit"
											LabelText="Total Debit"
											Value={searchModel.TotalDebit}
											ValueChanged={this.handleChange}
											Prefix="$"
											Disabled={true} />
										<GenericTextInput
											Name="TotalCount"
											LabelText="Total Count"
											Value={searchModel.TotalCount}
											ValueChanged={this.handleChange} 
											Disabled={true}/>
									</GridItem>
									<GridItem xs={6}>
										<GenericNumberInput
											Name="VostroTotalCredit"
											LabelText="Vostro Total Credit"
											Value={searchModel.VostroTotalCredit}
											ValueChanged={this.handleChange}
											Prefix="$" 
											Disabled={true} />
										<GenericNumberInput
											Name="VostroTotalDebit"
											LabelText="Vostro Total Debit"
											Value={searchModel.VostroTotalDebit}
											ValueChanged={this.handleChange}
											Prefix="$"
											Disabled={true} />
										<GenericTextInput
											Name="VostroTotalCount"
											LabelText="Vostro Total Count"
											Value={searchModel.VostroTotalCount}
											ValueChanged={this.handleChange} 
											Disabled={true}/>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card><br />
					</GridItem>
				</GridContainer>
				<GridItem xs={12}>
					<TabsComponent
						TabIndex={this.state.ParentTabIndex}
						handleChange={this.handleChangeParentTab(false)}
						tabList={[
							{
								tabButton: "Bridge",
								tabContent: (
									<Card className="no-radius">
										<CardBody>
											<CardHeader>
												<GenericTitle text={"BRIDGE TO BAI2 MATCHED TRANSACTIONS"} />
											</CardHeader>
											<GridItem xs={12}>
												<GridItem>
													<GenericGrid
														Data={this.state.bridgeList}
														PageSize={10}
														ShowPagination={true}
														Columns={[
															{
																Header: "Bridge Transaction Date",
																accessor: "TransactionDate",
																ColumnType: GridColumnType.DateTimeUtc,
																type: GridColumnType.DateTimeUtc
															},
															{
																Header: "BAI2 Date",
																accessor: "BAI2Date",
																ColumnType: GridColumnType.DateTimeUtc,
																type: GridColumnType.DateTimeUtc
															},
															{
																Header: "BAI2 Description",
																accessor: "BAI2Description",
															},
															{
																Header: "Debit",
																accessor: "Debit",
																ColumnType: GridColumnType.Money,
																type: GridColumnType.Money
															},
															{
																Header: "Credit",
																accessor: "Credit",
																ColumnType: GridColumnType.Money,
																type: GridColumnType.Money
															},
															{
																Header: "Category",
																accessor: "Category",
															},
															{
																Header: "Matched Date",
																accessor: "MatchedDate",
																ColumnType: GridColumnType.DateTimeUtc,
																type: GridColumnType.DateTimeUtc
															},
															{
																Header: "Adj Date",
																accessor: "AdjDate",
																ColumnType: GridColumnType.DateTimeUtc,
																type: GridColumnType.DateTimeUtc
															},
															{
																Header: "Settlement Status",
																accessor: "SettlementStatus",
															},
															{
																Header: "Client/FI Name",
																accessor: "ClientName",
															}
														]}
														SelectedIndex={this.state.rowIndex} />
												</GridItem>
											</GridItem>
										</CardBody>
									</Card>
								),
								Hidden: false
							},
							{
								tabButton: "BAI2 - Vostro",
								tabContent: (
									<Card className="no-radius">
										<CardBody>
											<CardHeader>
												<GenericTitle text={"BAI2 TO BRIDGE MATCHED TRANSACTIONS"} />
											</CardHeader>
											<GridItem xs={12}>
												<GridItem>
													<GenericGrid
														Data={this.state.vostroList}
														PageSize={10}
														ShowPagination={true}
														Columns={[
															{
																Header: "Actions",
																accessor: "Actions",
																Cell: row => (
																	<div>
																		<GridButton
																			tooltip="Edit"
																			Icon={EditIcon}
																			OnClick={() => this.openEditWireDialog(row.original)} />
																		<GridButton
																			tooltip="Undo"
																			Icon={UndoIcon}
																			OnClick={() => this.ShowQuestionMessage("Undo this record !", row.original)} />																	</div>
																),
																width: 100
															},
															{
																Header: "Bridge Transaction Date",
																accessor: "TransactionDate",
																ColumnType: GridColumnType.DateTimeUtc,
																type: GridColumnType.DateTimeUtc
															},
															{
																Header: "BAI2 Date",
																accessor: "BAI2Date",
																ColumnType: GridColumnType.DateTimeUtc,
																type: GridColumnType.DateTimeUtc
															},
															{
																Header: "BAI2 Description",
																accessor: "BAI2Description",
															},
															{
																Header: "Debit",
																accessor: "Debit",
																ColumnType: GridColumnType.Money,
																type: GridColumnType.Money
															},
															{
																Header: "Credit",
																accessor: "Credit",
																ColumnType: GridColumnType.Money,
																type: GridColumnType.Money

															},
															{
																Header: "Category",
																accessor: "Category",
															},
															{
																Header: "Matched Date",
																accessor: "MatchedDate",
																ColumnType: GridColumnType.DateTimeUtc,
																type: GridColumnType.DateTimeUtc
															},
															{
																Header: "Adj Date",
																accessor: "AdjDate",
																ColumnType: GridColumnType.DateTimeUtc,
																type: GridColumnType.DateTimeUtc
															},
															{
																Header: "Settlement Status",
																accessor: "SettlementStatus",
															},
															{
																Header: "Client/FI Name",
																accessor: "ClientName",
															}
														]}
														SelectedIndex={this.state.rowIndex} />
												</GridItem>
											</GridItem>
										</CardBody>
									</Card>
								),
								Hidden: false
							}
						]}
					/>
					<GridContainer />
				</GridItem>

				<GenericDialog open={isEditWireDialogOpen} maxWidth="md" fullWidth>
					<DialogTitle>
						<GenericLabel Text="Transaction Confirmation" Bold={true} />
					</DialogTitle>
					<DialogContent>
					<GenericNumberInput
						Name="Credit"
						LabelText="Credit"
						Value={editModel.Credit}
						ValueChanged={this.handleChangeForEdit}
						Prefix="$" />
					<GenericNumberInput
						Name="Debit"
						LabelText="Debit"
						Value={editModel.Debit}
						ValueChanged={this.handleChangeForEdit}
						Prefix="$" />
					<ParameterComponent
						Name="CategoryId"
						LabelText="Category"
						ParameterCode="WireTransferCategoryType"
						Value={editModel.CategoryId}
						ValueChanged={this.handleChangeForEdit} />
						<br /><br /><br /><br /><br /><br /><br /><br />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={() => this.handleEditWire()}>Edit</Button>
						<Button size="sm" onClick={() => this.setState({ isEditWireDialogOpen: false, editModel: {} })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>
			</div>
		);
	}
}

WireReconciliation.propTypes = {
	classes: PropTypes.object,
	handleOperationType: PropTypes.func,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(WireReconciliation, {});