import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import PropTypes from "prop-types";
import React from "react";
import { GenericGrid, GenericTitle } from "views/Components/Generic";

class KeyContactDataTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {

		};
	}

	render() {
		var data = this.props.paramBankCustomerKeyContactList === undefined || this.props.paramBankCustomerKeyContactList === null ? [] : this.props.paramBankCustomerKeyContactList.map(d => {
			return {
				Id: d.Id,
				UniqueClientId: d.UniqueClientId,
				PositionId: d.PositionId,
				Position: d.Position === null || undefined ? "" : d.Position.ParameterDesc || "",
				Name: d.Name,
				MidName: d.MidName,
				Surname: d.SurName,
				Phone: d.Phone,
				MobilePhone: d.MobilePhone,
				Email: d.Email,

			};
		});

		return (
			<Card className="no-radius">
				<CardHeader >
					<GenericTitle text="Contact Person Information" />
				</CardHeader>
				<CardBody>
					<GenericGrid
						Data={data}
						PageSize={5}
						HideButton={true}
						Columns={[
							{
								Header: "Position",
								accessor: "Position"
							},
							{
								Header: "Name",
								accessor: "Name"
							},
							{
								Header: "Middle Name",
								accessor: "MidName"
							},
							{
								Header: "Last Name",
								accessor: "Surname"
							},
							{
								Header: "Phone",
								Cell: row => (<span>{row.original.Phone == null ? "" : row.original.Phone.PhoneCountryCode == null ? "" : "+" + row.original.Phone.PhoneCountryCode.Code + " " + row.original.Phone.PhoneNumber}</span>)
							},
							{
								Header: "MobilePhone",
								Cell: row => (<span>{row.original.MobilePhone == null ? "" : row.original.MobilePhone.PhoneCountryCode == null ? "" : "+" + row.original.MobilePhone.PhoneCountryCode.Code + " " + row.original.MobilePhone.PhoneNumber}</span>)
							},
							{
								Header: "Email",
								accessor: "Email"
							}
						]}
					/>
				</CardBody>
			</Card>
		);
	}
}

KeyContactDataTable.propTypes = {
	paramBankCustomerKeyContactList: PropTypes.array,
	Header: PropTypes.bool
};

export default KeyContactDataTable;
