import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericDateInput, GenericDialog, GenericDialogActions, GenericLabel } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import DateHelper from "core/DateHelper";
import Button from "components/CustomButtons/Button.jsx";
import { DetailIcon, EditIcon } from "core/Icons";
import { CrudType, FileCode, FileType, ProgramCodes, ClientType, EmvScriptPoolInsertType } from "views/Constants/Constant";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import ClientHelper from "core/ClientHelper";
import { GridColumnType } from "views/Constants/Constant";

class PromotionStatus extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			model: {},
			file: {},
			list: [],
			isDetailDialogOpen: false,
			uploadedList: [],
			isClient: ClientHelper.IsClient(),
		};

		this.fileInputRef = React.createRef();
		this.ParameterEmpty = {};
		this.ParameterCouponStatus = { ParameterCode: "CampaignCouponStatus" };
		this.ColumnsCouponHistory = [
			{
				Header: "Program",
				accessor: "CouponProfile.Program.Description"
			},
			{
				Header: "ExecutionType",
				accessor: "CouponProfile.ExecutionType.ParameterDesc"
			},
			{
				Header: "Profile Name",
				accessor: "CouponProfile.ProfileName"
			},
			{
				Header: "Unique Client ID",
				accessor: "UniqueClient.UniqueClientId"
			},
			{
				Header: "Customer Name",
				accessor: "Customer.CustomerName"
			},
			{
				Header: "Coupon Code",
				accessor: "CouponCode"
			},
			{
				Header: "Generate Order",
				accessor: "GenerateOrder"
			},
			{
				Header: "Campaign Partner",
				accessor: "CampaignPartner.PartnerName"
			},
			{
				Header: "Reward",
				accessor: "CouponProfile.Reward"
			},
			{
				Header: "Redemtion Status",
				accessor: "UsageStatus.ParameterDesc"
			},
			{
				Header: "Is Success",
				accessor: "IsSuccess",
				Cell: row => (
					<div>{row.value ? "Yes" : "No"}</div>
				),
			},
			{
				Header: "Is Free Gift",
				accessor: "IsFreeGift",
				Cell: row => (
					<div>{row.value ? "Yes" : "No"}</div>
				),
			},
			{
				Header: "Is Discount",
				accessor: "IsDiscount",
				Cell: row => (
					<div>{row.value ? "Yes" : "No"}</div>
				),
			},
			{
				Header: "Generate Date",
				accessor: "GenerateDate",
				type: GridColumnType.DateTime,
				filterMethod: (filter, row, column) => {
					return Formatter.FormatDateTime(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase())
				}
			},
			{
				Header: "Usage Date",
				accessor: "UsageDate",
				type: GridColumnType.DateTime,
				filterMethod: (filter, row, column) => {
					return Formatter.FormatDateTime(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase())
				}
			}
		];
		this.ColumnsUploadCodes = [
			{
				Header: "Profile Name",
				accessor: "CouponProfile.ProfileName"
			},
			{
				Header: "Unique Client ID",
				accessor: "UniqueClient.UniqueClientId"
			},
			{
				Header: "Customer Name",
				accessor: "Customer.CustomerName"
			},
			{
				Header: "Coupon Code",
				accessor: "CouponCode"
			},
			{
				Header: "Generate Order",
				accessor: "GenerateOrder"
			},
			{
				Header: "Campaign Partner",
				accessor: "CampaignPartner.PartnerName"
			},
			{
				Header: "Reward",
				accessor: "CouponProfile.Reward"
			},
			{
				Header: "Redemtion Status",
				accessor: "UsageStatus.ParameterDesc"
			},
			{
				Header: "Generate Date",
				accessor: "GenerateDate",
				type: GridColumnType.DateTime,
				filterMethod: (filter, row, column) => {
					return Formatter.FormatDateTime(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase())
				}
			},
			{
				Header: "Usage Date",
				accessor: "UsageDate",
				type: GridColumnType.DateTime,
				filterMethod: (filter, row, column) => {
					return Formatter.FormatDateTime(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase())
				}
			}
		];


	}
	componentDidMount() {
		if (this.props.setAppCenterTitle) {
			this.props.setAppCenterTitle("Campaign Manager");
		}

		if (this.props.setAppLeftTitle)
			this.props.setAppLeftTitle("Promotion Status List");

	}


	handleChange = (name, newValue, data) => {
		this.setState(function (state, props) {
			var temp = state.model || {};
			temp[name] = newValue;
			return { model: temp };
		});
	}


	Search = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/campaignapi/v1.0/CampaignCouponHistory/PromotionSearch",
			this.state.model,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					this.props.showMessage("error", "Error !", responseData.ErrorDescription);
				} else {
					this.setState({ list: responseData.Item || [] });
				}
				this.setState({ isLoading: false });
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}
	UploadFileContent = (file) => {

		this.setState({ isLoading: true });

		var formData = new FormData();
		formData.append("file", file, file.name);
		formData.append("isBinaryOrBase64", FileType.Binary);

		Proxy.FileUpload(
			"/campaignapi/v1.0/CampaignCouponHistory/UploadUsedCoupon",
			formData,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					this.props.showMessage("error", "Error !", responseData.ErrorDescription);
				} else {
					this.setState({ uploadedList: responseData.Item || [] });
				}
				this.setState({ isLoading: false });
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	MarkAsUsed = () => {
		var list = this.state.uploadedList;
		if (list == null || list.length < 1) {
			this.props.showMessage("error", "Error", "Coupon list must be valid !");
			return;
		}
		var temp = [];
		list.forEach(element => {
			temp.push(element.CouponCode);
		});
		this.setState({ isLoading: true });
		Proxy.POST(
			"/campaignapi/v1.0/CampaignCouponHistory/MarkAsUsed",
			temp,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					this.props.showMessage("error", "Error !", responseData.ErrorDescription);
				}
				this.props.showMessage("success", "Success", "Coupons Marked as Used !");
				this.setState({ isLoading: false, isDetailDialogOpen: false });
				this.Search();
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);

	}
	FileSelect = (e) => {
		e.preventDefault();

		var file = e.target.files[0];
		if (!file) {
			return;
		}

		this.setState({ file: file, isDetailDialogOpen: true });
		this.UploadFileContent(file);
	}


	ClearData = () => {
		this.setState({ model: {}, list: [], file: {}, uploadedList: [] });
	}
	RenderItemUniqueClient = (d) => {
		return `${d.UniqueClientId} - ${d.Name}`;
	}


	render() {
		const { Disabled } = this.props;
		const { isLoading, model, list, file, uploadedList } = this.state;
		return (
			<div>
				<LoadingComponent Show={isLoading} />
				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: () => this.ClearData(), Disabled: Disabled },
					{ Code: "Search", OnClick: this.Search, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<Card className="no-radius">
					<GenericExpansionPanel Title={"Filter Search"}>
						<CardBody>
							<GridContainer spacing={16}>
								{!this.state.isClient && (
									<GridItem xs={3} >
										<GenericSelectInput
											Name="UniqueClientId"
											LabelText="Client"
											Method="POST"
											Url="/bankapi/v1.0/BankCustomer/Search"
											Parameter={{}}
											DataRoot="Item"
											KeyValueMember="Id"
											RenderItem={this.RenderItemUniqueClient}
											Value={model.UniqueClientId}
											ValueChanged={this.handleChange}
											CanClear
											All
											Disabled={this.state.isClient}
											DefaultIndex={this.state.isClient ? 0 : -1}
										/>
									</GridItem>
								)}
								<GridItem xs={3} >
									<GenericSelectInput
										CanClear
										Disabled={Disabled || model.Id > 0}
										Name="ProgramId"
										LabelText="Program"
										Value={model.ProgramId}
										ValueChanged={this.handleChange}
										KeyValueMember="Id"
										TextValueMember="Description"
										Method="GET"
										Url="/bankapi/v1.0/Bank/ValidProgramsCampaign"
										DataRoot="Item"
									/>
								</GridItem>
								<GridItem xs={3} >
									<GenericSelectInput
										CanClear
										Disabled={Disabled || model.Id > 0}
										Name="ExecutionTypeId"
										LabelText="Redemption Status"
										Value={model.ExecutionTypeId || ""}
										ValueChanged={this.handleChange}
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.ParameterCouponStatus}
										DataRoot="Item"
									/>
								</GridItem>
								<GridItem xs={3} >
									<GenericSelectInput
										CanClear
										Disabled={Disabled || model.Id > 0}
										Name="CouponProfileId"
										LabelText="Coupon Profile"
										Value={model.CouponProfileId}
										ValueChanged={this.handleChange}
										KeyValueMember="Id"
										TextValueMember="ProfileName"
										Method="POST"
										Parameter={this.ParameterEmpty}
										Url="/campaignapi/v1.0/CouponProfile/GetAll"
										DataRoot="Item"
									/>
								</GridItem>
								<GridItem xs={3} >
									<GenericSelectInput
										Disabled={Disabled}
										Name="CampaignPartnerId"
										LabelText="Campaign Partner"
										Value={model.CampaignPartnerId}
										ValueChanged={this.handleChange}
										KeyValueMember="Id"
										TextValueMember="PartnerName"
										Method="POST"
										Url="/campaignapi/v1.0/CampaignPartner/Search"
										Parameter={this.ParameterEmpty}
										DataRoot="Item"
									/>
								</GridItem>
								<GridItem xs={3} >
									<GenericDateInput
										Name="StartDateTime"
										LabelText="Start Date"
										MaxDate={model.EndDateTime && DateHelper.ToMoment(model.EndDateTime)}
										Value={model.StartDateTime == null ? "" : DateHelper.ToDateInputValue(model.StartDateTime)}
										ValueChanged={this.handleChange}
										Disabled={Disabled}
									/>
								</GridItem>
								<GridItem xs={3} >
									<GenericDateInput
										Name="EndDateTime"
										LabelText="End Date"
										MinDate={model.StartDateTime && DateHelper.ToMoment(model.StartDateTime)}
										Value={model.EndDateTime == null ? "" : DateHelper.ToDateInputValue(model.EndDateTime)}
										ValueChanged={this.handleChange}
										Disabled={Disabled}
									/>
								</GridItem>
							</GridContainer>

							<GridContainer justify="flex-end">
								<GridItem>
									<b>{file.name}</b>
									<Button disabled={Disabled} size="sm" onClick={() => this.fileInputRef.current.click()} >Upload Used Coupons</Button>
									<input type="file" key={model.FileName} style={{ display: "none" }} onChange={this.FileSelect} ref={this.fileInputRef} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,.csv" />
								</GridItem>
							</GridContainer>
						</CardBody >
					</GenericExpansionPanel>
				</Card>

				<Card className="no-radius">
					<CardBody>
						<GenericGrid
							Data={list}
							Columns={this.ColumnsCouponHistory}
						/>
					</CardBody>
				</Card>



				<GenericDialog open={this.state.isDetailDialogOpen} maxWidth="lg" fullWidth>
					<DialogTitle>
						<GridContainer justify="space-between" alignItems="center">
							<GridItem>
								<GenericLabel FontSize="16px" TextColor="black" Text={"Uploaded File Content Detail ->  " + file.name} Bold={true} />
							</GridItem>
						</GridContainer>
					</DialogTitle>
					<DialogContent>
						<GenericGrid
							Data={uploadedList}
							Columns={this.ColumnsUploadCodes}
						/>
					</DialogContent>
					<GenericDialogActions>
						<Button disabled={Disabled || !uploadedList.length > 0} size="sm" onClick={() => this.MarkAsUsed()} >Mark as Used</Button>
						<Button size="sm" onClick={() => this.setState({ isDetailDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>

			</div >
		);
	}
}

PromotionStatus.propTypes = {
	classes: PropTypes.object
};

export default withArtifex(PromotionStatus, {});