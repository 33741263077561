import { dangerColor, defaultFont, primaryColor, cardBoxShadow, primaryBoxShadow } from "assets/jss/material-dashboard-pro-react.jsx";

import customDropdownStyle from "assets/jss/material-dashboard-pro-react/components/customDropdownStyle.jsx";

const appTitleBarStyle = theme => ({
	main: {
		...cardBoxShadow,
		backgroundColor: "#e4e4e4",
		fontWeight: "bold",
		textTransform: "uppercase",
		borderRadius: "6px",
		marginTop: "8px"
	},
	icon: {
		backgroundColor: primaryColor,
		color: "white",
		borderRadius: "6px"
	},
	...customDropdownStyle(theme),
	linkText: {
		zIndex: "4",
		...defaultFont,
		fontSize: "14px",
		margin: "0!important",
		textTransform: "none"
	},
	top: {
		zIndex: "4"
	},
	links: {
		width: "20px",
		height: "20px",
		zIndex: "4",
		[theme.breakpoints.down("sm")]: {
			display: "block",
			width: "30px",
			height: "30px",
			color: "inherit",
			opacity: "0.8",
			marginRight: "16px",
			marginLeft: "-5px"
		}
	},
	legals: {
		zIndex: "4",
		[theme.breakpoints.up("md")]: {
			position: "absolute",
			top: "0px",
			border: "1px solid " + primaryColor,
			right: "0px",
			fontSize: "9px",
			background: dangerColor,
			color: "#FFFFFF",
			minWidth: "16px",
			height: "16px",
			borderRadius: "10px",
			textAlign: "center",
			lineHeight: "14px",
			verticalAlign: "middle",
			display: "block"
		},
		[theme.breakpoints.down("sm")]: {
			...defaultFont,
			fontSize: "14px",
			marginRight: "8px"
		}
	},
	fees: {
		zIndex: "4",
		[theme.breakpoints.up("md")]: {
			position: "absolute",
			top: "0px",
			border: "1px solid " + primaryColor,
			right: "0px",
			fontSize: "9px",
			background: dangerColor,
			color: "#FFFFFF",
			minWidth: "16px",
			height: "16px",
			borderRadius: "10px",
			textAlign: "center",
			lineHeight: "14px",
			verticalAlign: "middle",
			display: "block"
		},
		[theme.breakpoints.down("sm")]: {
			...defaultFont,
			fontSize: "14px",
			marginRight: "8px"
		}
	},
	notifications: {
		zIndex: "4",
		[theme.breakpoints.up("md")]: {
			position: "absolute",
			top: "0px",
			border: "1px solid " + primaryColor,
			right: "0px",
			fontSize: "9px",
			background: dangerColor,
			color: "#FFFFFF",
			minWidth: "16px",
			height: "16px",
			borderRadius: "10px",
			textAlign: "center",
			lineHeight: "14px",
			verticalAlign: "middle",
			display: "block"
		},
		[theme.breakpoints.down("sm")]: {
			...defaultFont,
			fontSize: "14px",
			marginRight: "8px"
		}
	},
	announcements: {
		zIndex: "4",
		[theme.breakpoints.up("md")]: {
			position: "absolute",
			top: "0px",
			border: "1px solid " + primaryColor,
			right: "0px",
			fontSize: "9px",
			background: dangerColor,
			color: "#FFFFFF",
			minWidth: "16px",
			height: "16px",
			borderRadius: "10px",
			textAlign: "center",
			lineHeight: "14px",
			verticalAlign: "middle",
			display: "block"
		},
		[theme.breakpoints.down("sm")]: {
			...defaultFont,
			fontSize: "14px",
			marginRight: "8px"
		}
	},
	managerClasses: {
		display: "inline-block"
	},
	stats: {
		"& svg": {
			position: "relative",
			top: "4px",
			height: "16px"
		},
		"& .fab,& .fas,& .far,& .fal,& .material-icons": {
			position: "relative",
			top: "4px",
			fontSize: "16px",
			marginRight: "3px"
		}
	},
	// primaryHover: {
	// 	"&:hover": {
	// 		backgroundColor: primaryColor,
	// 		color: "#FFFFFF",
	// 		...primaryBoxShadow
	// 	}
	// }
});

export default appTitleBarStyle;
