import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import { GenericLabel, GenericNumberInput, GenericSelectInput } from "views/Components/Generic";
import { DefaultBankInformation } from "views/Constants/Constant.js";

const styles = ({
	labelStyle: {
		paddingLeft: "10px !important",
		paddingTop: "12px !important"
	},
	fiidStyle: {
		paddingLeft: "5px !important"
	}
});

class AccountComponent extends React.Component {
	constructor(props) {
		super(props);

		this.parameterFinancialInstitutionBranch = {
			FinancialInstitutionId: this.props.IsDCBankAccount ? DefaultBankInformation.FinancialInstitutionId : this.props.Model.FinancialInstitutionId
		};

		this.sortedName = { Member: "Name" };
		this.sortedTransitNumber = { Member: "TransitNumber" };
	}

	GetFinancialInstitutionBranchParameter = () => {
		this.parameterFinancialInstitutionBranch.FinancialInstitutionId = this.props.IsDCBankAccount ? DefaultBankInformation.FinancialInstitutionId : this.props.Model.FinancialInstitutionId;
		return this.parameterFinancialInstitutionBranch;
	}

	RenderItemFinancialInstitution = (d) => {
		let financialInstitutionId = this.DigitEdit(d.FinancialInstitutionId, 3);
		return `${financialInstitutionId} - ${d.Description}`;
	}
	DigitEdit = (Id, digitNumber) => {
		let result = "";
		if (Id && Id.toString().length < digitNumber) {
			for (let index = 0; index < digitNumber - Id.toString().length; index++) {
				result = `0${result}`;
			}
			result = `${result}${Id}`;
			return result;
		}
		else {
			return Id;
		}
	}
	RenderItemFinancialInstitutionBranch = (d) => {
		let transitNumber = this.DigitEdit(d.TransitNumber, 5);
		return `${transitNumber} - ${d.Description}`;
	}
	render() {
		var { classes, LabelText, Model, VModel, ValueChanged, Disabled, Required, TitleLabel, TitleLabelBranch, IsDCBankAccount, MaxLength, LabelMd, Md, NumberMd, BranchMd } = this.props;

		return (
			<div style={{ margin: "0 -2px 0 -2px" }}>

				{(!IsDCBankAccount) &&
					<GridContainer>
						<GridItem xs={4} className={classes.labelStyle}>
							<GenericLabel FontSize="12px" TextColor="black" Text={LabelText} />
						</GridItem>
						<GridItem xs={3} className={classes.fiidStyle}>
							<GenericSelectInput
								LabelMd={0}
								Name="FinancialInstitutionId"
								TitleLabel={TitleLabel}
								Method="GET"
								Url="/bankapi/v1.0/FinancialInstitution/GetAll"
								DataRoot="Item"
								KeyValueMember="Id"
								RenderItem={this.RenderItemFinancialInstitution}
								Value={Model.FinancialInstitutionId}
								ValueChanged={ValueChanged}
								Disabled={Disabled}
								Required={Required}
								IsInvalid={VModel && VModel.FinancialInstitutionId}
								Sorted={this.sortedName} />
						</GridItem>
						<GridItem xs={3}>
							<GenericSelectInput
								key={Model.FinancialInstitutionId}
								Name="FinancialInstitutionBranchId"
								LabelMd={0}
								TitleLabel={TitleLabelBranch}
								Method="POST"
								Url="/bankapi/v1.0/FinancialInstitutionBranch/Search"
								Parameter={this.GetFinancialInstitutionBranchParameter()}
								DataRoot="Item"
								KeyValueMember="Id"
								RenderItem={this.RenderItemFinancialInstitutionBranch}
								Value={Model.FinancialInstitutionBranchId}
								ValueChanged={ValueChanged}
								Disabled={Disabled}
								Required={Required}
								IsInvalid={VModel && VModel.FinancialInstitutionBranchId}
								Sorted={this.sortedTransitNumber} />
						</GridItem>
						<GridItem xs={2}>
							<GenericNumberInput
								LabelMd={0}
								NoFormatting={true}
								Name="AccountNumber"
								Value={Model.AccountNumber}
								ValueChanged={ValueChanged}
								Disabled={Disabled}
								Required={Required}
								IsInvalid={VModel && VModel.AccountNumber}
								MaxLength={MaxLength || 12} />
						</GridItem>
					</GridContainer>
				}
				{(IsDCBankAccount) &&
					<GridContainer>
						<GridItem xs={4} className={classes.labelStyle}>
							<GenericLabel FontSize="12px" TextColor="black" Text={LabelText} />
						</GridItem>
						<GridItem xs={3} className={classes.fiidStyle}>
							<GenericSelectInput
								LabelMd={0}
								Name="FinancialInstitutionId"
								TitleLabel={TitleLabel}
								Method="GET"
								Url="/bankapi/v1.0/FinancialInstitution/GetAll"
								DataRoot="Item"
								KeyValueMember="Id"
								TextValueMember="FinancialInstitutionId"
								Value={DefaultBankInformation.FinancialInstitutionId}
								Disabled={true}
								Required={Required}
								IsInvalid={VModel && VModel.FinancialInstitutionId} />
						</GridItem>
						<GridItem xs={3}>
							<GenericSelectInput
								Name="FinancialInstitutionBranchId"
								LabelMd={0}
								TitleLabel={TitleLabelBranch}
								Method="GET"
								Url="/bankapi/v1.0/FinancialInstitutionBranch/GetAllDCBankBranches"
								DataRoot="Item"
								KeyValueMember="Id"
								TextValueMember="TransitNumber"
								Value={DefaultBankInformation.FinancialInstitutionBranchId}
								Disabled={true}
								Required={Required}
								IsInvalid={VModel && VModel.FinancialInstitutionBranchId} />
						</GridItem>
						<GridItem xs={2}>
							<GenericNumberInput
								LabelMd={0}
								NoFormatting={true}
								Name="AccountNumber"
								Value={Model.AccountNumber}
								ValueChanged={ValueChanged}
								Disabled={Disabled}
								Required={Required}
								IsInvalid={VModel && VModel.AccountNumber}
								MaxLength={MaxLength || 12} />
						</GridItem>
					</GridContainer>
				}

			</div>
		);
	}
}

AccountComponent.propTypes = {
	LabelText: PropTypes.string,
	Model: PropTypes.object.isRequired,
	VModel: PropTypes.object,
	ValueChanged: PropTypes.func.isRequired,
	Disabled: PropTypes.bool,
	TitleLabel: PropTypes.string,
	TitleLabelBranch: PropTypes.string,
	IsDCBankAccount: PropTypes.bool,
	MaxLength: PropTypes.number,
	Required: PropTypes.bool
};

export default withStyles(styles)(AccountComponent);