import React from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core"; import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import { GenericTitle, GenericPage, GenericGrid, GenericDateInput, GenericSelectInput } from "views/Components/Generic";
import PropTypes from "prop-types";
import LoadingComponent from "views/Components/LoadingComponent";
import Formatter from "core/Formatter";
import ClientHelper from "core/ClientHelper";
import ChequeManagementStyle from "../../../assets/jss/material-dashboard-pro-react/views/ChequeManagementStyle.jsx";
import TabsComponent from "views/Components/TabsComponentPagination";
import DateHelper from "core/DateHelper.js";
import { GridColumnType } from "views/Constants/Constant";

class ChequeWireOutgoing extends GenericPage {
	constructor(props) {
		super(props);

		this.state = {

			model: {},
			list: [],
			selected: null,
			isBackOffice: false,
			openDialog: false,
			totalchqinfomodel: {},
			ParentTabIndex: 0,
			CheckNewClient: false

		};

		this.columns = [

			{
				Header: "Status",
				accessor: "Status"
			},
			{
				Header: "Date",
				accessor: "ChequeDate",
				type: GridColumnType.Date
			},
			{
				Header: "Reference Number",
				accessor: "ReferenceNumber"
			},
			{
				Header: "Wallet Number",
				accessor: "BankAccountNumber"
			},
			{
				Header: "Amount",
				accessor: "Amount"
			},
			{
				Header: "Beneficiary Name",
				accessor: "Beneficiary.Name"
			},
			{
				Header: "Beneficiary Adress",
				accessor: "Beneficiary.Adress",

			},
			{
				Header: "Beneficiary Bank SWIFT",
				accessor: "Beneficiary.SWIFT",


			},
			{
				Header: "Beneficiary Bank SWIFT",
				accessor: "Beneficiary.SWIFT",

			},
			{
				Header: "Beneficiary Bank  Adress",
				accessor: "Beneficiary.BankAdress",

			},
			{
				Header: "Purpose Of Wire",
				accessor: "PurposeOfWire",

			},
			{
				Header: "Swift Response Code",
				accessor: "SwiftResponseCode",

			},
			{
				Header: "File Type",
				accessor: "FileType"
			},


		];


	}
	componentDidMount() {
		super.componentDidMount();
		this.Bind(this);
		// var tempuniq = Formatter.PadLeft(ClientHelper.GetClientRowId(), 3, "0");
		if (this.props.setAppLeftTitle) this.props.setAppLeftTitle("Wire Outgoing File Details");
		if (this.props.setAppCenterTitle) {
			this.props.setAppCenterTitle("WIRE TRANSFER");
		}
		this.setState({ openDialog: false });


		var { isBackOffice } = this.state;

		isBackOffice = ClientHelper.IsBackOffice();


		this.setState({ isBackOffice });

	}
	HandleChangeParentTab(active, tab) {
		if (!tab || !this.state.CheckNewClient) {
			this.setState({ ParentTabIndex: active });
		}
	}
	HandleChange(name, value) {

		const model = { ...this.state.model };
		model[name] = value;
		if (name === "Name") model["UniqueClientId"] = value;
		if (name === "UniqueClientId") model["Name"] = value;
		this.setState({ model });
	}

	Search() {




	}


	Clear(){
		this.setState({ model: {} });
	}

	render() {
		const { classes } = this.props;
		const { model, list, selected, isBackOffice } = this.state;

		const { IsLoading, Alert } = this.state;

		return (
			<div>
				{Alert}
				<LoadingComponent Show={IsLoading} />
				<ButtonToolbar ButtonList={[
					{
						Code: "Search",
						OnClick: this.Search
					},

					{
						Code: "Clear",
						OnClick: this.Clear,

					},

					{
						Code: "Upload",
						OnClick: this.Clear,

					},

					{
						Code: "View",
						OnClick: this.Clear,

					}


				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<GridContainer>
					<GridItem xs={12} sm={12} md={12}>
						<Card className={classes.zerMarPad}>
							<CardBody>
								<GridContainer>
									<GridItem xs={12} sm={6} md={12}>
										<GridContainer spacing={16} >
											<GridItem xs={10} sm={9} md={4}>
												<GenericSelectInput
													Name="Name"
													LabelText="Client Name"
													Value={model.Name || ""}
													DataRoot="Item"
													ValueChanged={this.HandleChange}
													KeyValueMember="Id"
													TextValueMember="Name"
													Url="/bankapi/v1.0/BankCustomer/Search"
													Method="POST"
													Parameter={{ UniqueClientId: 0 }}
													Disabled={!isBackOffice}


												/>

												<GenericSelectInput
													Name="UniqueClientId"
													LabelText="Unique Client ID"
													Value={model.UniqueClientId || ""}
													DataRoot="Item"
													ValueChanged={this.HandleChange}
													KeyValueMember="Id"
													TextValueMember="UniqueClientId"
													Url="/bankapi/v1.0/BankCustomer/Search"
													Method="POST"
													Parameter={{ UniqueClientId: 0 }}
													Disabled={!isBackOffice}

												/>
											</GridItem>





											<GridItem xs={10} sm={9} md={4}  >
												<GenericDateInput
													Utc={true}
													Name="SearchStartDate"
													LabelText="From Date"
													Utc={true}
													Value={model == null || undefined ? null : model.SearchStartDate == null || undefined ? "" : model.SearchStartDate}
													ValueChanged={this.HandleChange}
													MaxDate={model.SearchEndDate == null ? DateHelper.GetDateLocal() : model.ToDate}
												/>
												<GenericDateInput
													Utc={true}
													Name="SearchEndDate"
													LabelText="To Date"
													Utc={true}
													Value={model == null || undefined ? null : model.SearchEndDate == null || undefined ? "" : model.SearchEndDate}
													ValueChanged={this.HandleChange}
													MaxDate={DateHelper.GetDateLocal()}
													MinDate={model.SearchStartDate}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
				<Card className="no-radius">
					<CardBody>
						<CardHeader>
							<GenericTitle text={"Wire OutGoing File Details"} />
						</CardHeader>
						<GridItem md={12} xs={12}>
							<TabsComponent
								TabIndex={this.state.ParentTabIndex}
								HandleChange={(e, a) => this.HandleChangeParentTab(a, true)}
								tabList={[
									{
										tabButton: "Files",
										tabContent: (
											<GridItem>
												<GenericGrid
													Data={list}
													SelectedIndex={selected}
													Columns={[

														{
															Header: "Status",
															accessor: "Status"
														},
														{
															Header: "Date",
															accessor: "ChequeDate",
															type: GridColumnType.Date
														},
														{
															Header: "Client Unique Id",
															accessor: "ClientUniqueId"
														},
														{
															Header: "Client Name",
															accessor: "ClientUniqueName"
														},
														{
															Header: "File Name",
															accessor: "FileName"
														},
														{
															Header: "Total Amount",
															accessor: "TotalAmount"
														}

													]}
													RowSelected={index => {

														this.setState({ model: list[index], selected: index });
													}} />
											</GridItem>
										)
									},
									{
										tabButton: "File Details",
										tabContent: (
											<GridItem>
												<GenericGrid
													Data={list}
													SelectedIndex={selected}
													Columns={this.columns}
													RowSelected={index => {

														this.setState({ model: list[index], selected: index });
													}} />
											</GridItem>
										)
									}


								]}
							/>



						</GridItem>
						{/* <GridContainer >
							<GridItem xs={12} sm={8} md={6} />
							<GridItem xs={12} sm={8} md={6}>
								<GridContainer className={classes.containerHeight} justify="flex-end">
									<GridItem xs={12} sm={4} md={2} className={classes.gridBorder}>
									</GridItem>
									<GridItem xs={12} sm={2} md={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
										<p className={classes.pt}>Amount</p>
									</GridItem>
								 
								</GridContainer>
								<GridContainer justify="flex-end">
									<GridItem xs={12} sm={2} md={2} className={classes.gridBorder + " " + classes.gridTextBold}>
										TOTAL
							</GridItem>
									<GridItem xs={12} sm={2} md={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
										{totalchqinfomodel == null || undefined ? 0 : totalchqinfomodel.Amount == null || undefined ? 0 : typeof totalchqinfomodel.Amount == "number" ? Formatter.FormatNumber(totalchqinfomodel.Amount) : totalchqinfomodel.Amount}
									</GridItem>
									 
									{<GridItem xs={12} sm={2} md={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
										($)
								{totalchqinfomodel == null || undefined ? 0 : totalchqinfomodel.TotalPrice == null || undefined ? 0 : typeof totalchqinfomodel.TotalPrice == "number" ? Formatter.FormatNumber(totalchqinfomodel.TotalPrice) : totalchqinfomodel.TotalPrice}
									</GridItem> }
								</GridContainer>
							</GridItem>
						</GridContainer> */}
					</CardBody>
				</Card>
			</div>



		);
	}



}

ChequeWireOutgoing.propTypes =
	{
		classes: PropTypes.object.isRequired,
		Disabled: PropTypes.bool,
		ValueChanged: PropTypes.func,
		Model: PropTypes.object,
		setAppLeftTitle: PropTypes.func,
		setAppCenterTitle: PropTypes.func,
		menuId: PropTypes.any,
		ApprovalData: PropTypes.any,
		After: PropTypes.any
	};
export default withArtifex(ChequeWireOutgoing, { ChequeManagementStyle });