import { withStyles } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericExpansionPanel, GenericGrid, GenericTextInput, GenericDateInput, GenericCheckInput,GenericDialog,GenericLabel,GenericDialogActions } from "views/Components/Generic";
import { Proxy } from "core";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import externalContainerStyle from "assets/jss/material-dashboard-pro-react/layouts/externalContainerStyle.jsx";
import DateHelper from "core/DateHelper";
import { FinScanSearchStatus, FinScanSearchResultType } from "views/Constants/Constant";
import { DetailIcon } from "core/Icons";
import { DialogContent, DialogTitle, Slide } from "@material-ui/core";
import Button from "components/CustomButtons/Button";

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

class ManuelWatchListCheck extends React.Component {

	constructor(props) {
		super(props);

		this.columns = [
			{
				Header: "Full Name",
				accessor: "FullName"
			},
			{
				Header: "Nationality",
				accessor: "Notionaly"
			},
			{
				Header: "Date Of Birth",
				accessor: "DateOfBirth"
			},
			{
				Header: "Client Type",
				accessor: "ClientType"
			},
			{
				Header: "Insert User",
				accessor: "InsertUser"
			},
			{
				Header: "SearchDate",
				accessor: "SearchDate"
			},
			{
				Header: "WatchList Status",
				accessor: "WatchListStatus"
			}
		];

		this.columnsDetail = [
			{
				Header: "FullName",
				accessor: "FullName"
			},
			{
				Header: "Gender",
				accessor: "Gender"
			},
			{
				Header: "Nationality",
				accessor: "Nationality"
			},
			{
				Header: "Country",
				accessor: "Country"
			},
			{
				Header: "PlaceOfBirth",
				accessor: "PlaceOfBirth"
			},
			{
				Header: "DateOfBirth",
				accessor: "DateOfBirth"
			},
			{
				Header: "TextInfo",
				accessor: "TextInfo"
			}
		];
		this.getappCenterTitle = "Empty";
		this.getappLeftTitle = "Empty";
		this.zIndex10 = { zIndex: 10 };

		this.state = {
			model: {},
			data: [],
			recordKeeping: [],
			isSendEmailDialogOpen: false,
			activeClient: [],
			responseDetail: {},
			isDetailDialogOpen: false,
			isDetailManualVerificationDialogOpen: false,
			isDetailUnverifiedDialogOpen: false
		};
		this.cpverTable = React.createRef();
	}

	componentDidMount = () => {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;
		if (setAppCenterTitle) {
			setAppCenterTitle("AML & KYC");
			this.getappCenterTitle = "AML & KYC";
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("Manually Watchlist Check");
			this.getappLeftTitle = "Manually Watchlist Check";
		}
	}

	Clear = () => {
		this.setState({ model: {}, data: [], selected: null });
	}

	showLoading = () => { this.setState({ isLoading: true }); }
	hideLoading = () => { this.setState({ isLoading: false }); }

	showDetail = async (result) => {
		this.setState({ responseDetail: result || [], isDetailDialogOpen: true });
	}

	Submit = () => {
		if (!this.validate()) {
			return;
		}

		const model = { ...this.state.model };
		this.setState({ isLoading: true });
		Proxy.POST(
			"/amlkycapi/v1.0/ManuallyWatchListCheck/ManuallyWachList",
			model,
			(responseData) => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.props.showMessage("success", "Succeeded", "Operation is successfully!");
					this.HandleSearch();
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	HandleSearch = () => {
		this.setState({ isLoading: true });
		Proxy.GET(
			"/amlkycapi/v1.0/ManuallyWatchListCheck/GetManuallyWachList",
			(responseData) => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ data: responseData.Item });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	DetailUnverifiedDialogOpen = (finScanSearchLogId) => {
		this.GetFinScanSearchLogDetailUnverified(finScanSearchLogId);
		this.setState({ isDetailUnverifiedDialogOpen: true });
	}

	DetailManualVerificationDialogOpen = (finScanSearchLogId) => {
		this.GetFinScanSearchLogDetailManualVerification(finScanSearchLogId);
		this.setState({ isDetailManualVerificationDialogOpen: true });
	}

	GetFinScanSearchLogDetailManualVerification = async (finScanSearchLogId) => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/amlkycapi/v1.0/FinScanSearchLog/GetPartialMatchListByFinScanSearchLogId", { FinScanSearchLogId: finScanSearchLogId });

		if (result != null && result.length != 0) {
			this.SetListPartialMatches(result);
		} else {
			this.setState({ listListPartialMatches: []});
		}
	}

	SetListPartialMatches = (searchResult) => {
		var listListPartialMatches = searchResult;

		for (let i = 0; i < searchResult.length; i++) {
			searchResult[i].JsonResultDataParsed.map(x => {
				listListPartialMatches[i][x.Key] = x.Value;

				return null;
			});
		}

		this.setState({ listListPartialMatches });
	}

	GetFinScanSearchLogDetailUnverified = async (finScanSearchLogId) => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/amlkycapi/v1.0/FinScanSearchLogDetail/GetDetailBySearchLogId", { FinScanSearchLogId: finScanSearchLogId });

		if (result != null && result.length != 0) {
			var { model } = this.state;

			model = result;

			this.setState({ model });
		}
	}

	DetailUnverifiedDialogClose = () => {
		this.setState({ isDetailUnverifiedDialogOpen: false });
	}

	DetailManualVerificationDialogClose = () => {
		this.setState({ isDetailManualVerificationDialogOpen: false });
	}

	handleChange = (name, newValue) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	};

	GetRenderItemClient = (x) => {
		var render = `${x.Name} - ${x.UniqueClientId}`;

		return render;
	}

	ValueChanged = (name, value, data) => {
		const model = { ...this.state.model };
		model[name] = value;
		this.setState({ model });
	}

	validate = () => {
		const model = { ...this.state.model };
		if (!model.FullName) {
			this.props.showMessageNode("warning", "Please fill in the Required Fields!");
			return false;
		}
		else {
			return true;
		}
	}

	render() {
		const { model, selected, isLoading, data, isDetailUnverifiedDialogOpen, isDetailManualVerificationDialogOpen,listListPartialMatches } = this.state;
		const { Disabled } = this.props;

		return (
			<div>
				<LoadingComponent Show={isLoading} />
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Submit",
							Disabled: Disabled,
							OnClick: this.Submit
						},
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.HandleSearch
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />

				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Request">
									<GridContainer>
										<GridItem xs={4}>
											<GenericTextInput
												Required
												Name="FullName"
												LabelText="Full Name"
												Value={model.FullName}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={4}>
											<GenericTextInput
												Name="Notionaly"
												LabelText="Nationality"
												Value={model.Notionaly}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={3}>
											<GenericDateInput
												Name="DateOfBirth"
												LabelText="Date Of Birth"
												Value={model.DateOfBirth ? DateHelper.ToDateInputValue(model.DateOfBirth) : ""}
												ValueChanged={this.ValueChanged}
												// MaxDate={model.DateOfBirth || DateHelper.GetDateTimeLocal()}
												Disabled={Disabled}
												LabelMd={3}
												Utc />
										</GridItem>
										<GridItem xs={4}>
										<GenericCheckInput
												Name="Personel"
												LabelText="Is It Personal?"
												Value={model.Personel}
												ValueChanged={this.ValueChanged} />
										</GridItem>

									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={data}
													Columns={[
														{
															Header: "Actions",
															accessor: "Actions",
															Cell: row => (
																<div>
																	<GridButton
																		Disabled={row.original.WatchListStatus == FinScanSearchStatus.Verified}
																		tooltip="Detail"
																		Icon={DetailIcon}
																		OnClick={() => row.original.SearchResultParameterValue == FinScanSearchResultType.ListExactMatch
																			? this.DetailUnverifiedDialogOpen(row.original.FinScanSearchLogId) : 
																			this.DetailManualVerificationDialogOpen(row.original.FinScanSearchLogId) }/>
																</div>
															),
															width: 40
														},	
														...this.columns
													]}
													SelectedIndex={selected}
													IsSorted={true}
													PageSize={10}
													ShowPagination={true} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

				<GenericDialog open={isDetailUnverifiedDialogOpen} maxWidth="md" fullWidth onBackdropClick={this.DetailUnverifiedDialogClose} TransitionComponent={Transition}>
					<DialogTitle>
						<GenericLabel
							Bold
							Text="Unverified Detail"
							FontSize="16px"
							TextColor="black" />
					</DialogTitle>
					<DialogContent>
						<GridContainer >
							<GenericTextInput
								Disabled
								LabelText="Text Info"
								Value={model.TextInfo}
								MultiLine />
						</GridContainer>
						<GridContainer >
							<GenericTextInput
								Disabled
								LabelText="Nationality"
								Value={model.Nationality} />
						</GridContainer>
						<GridContainer >
							<GenericTextInput
								Disabled
								LabelText="Place Of Birth"
								Value={model.Country} />
						</GridContainer>
						<GridContainer >
							<GenericTextInput
								Disabled
								LabelText="Gender"
								Value={model.Gender} />
						</GridContainer>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.DetailUnverifiedDialogClose}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>

				<GenericDialog open={isDetailManualVerificationDialogOpen} maxWidth="lg" fullWidth onBackdropClick={this.DetailManualVerificationDialogClose} TransitionComponent={Transition}>
					<DialogContent>
						<GridContainer >
							<GridItem xs={12}>
								<GridContainer spacing={16}>
								<GridItem xs={12}>
									<GenericLabel
										Bold
										Text="Similar Customers List"
										FontSize="16px"
										TextColor="black"
										/>
									<br />
									<br />
									<GenericGrid
										HideButton
										Data={listListPartialMatches}
										Columns={this.columnsDetail} />
								</GridItem>
								</GridContainer>
							</GridItem>
						</GridContainer>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.DetailManualVerificationDialogClose}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>
			</div >
		);
	}
}

ManuelWatchListCheck.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	ExecuteApiFileDownloadWithGenericResponse: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default withStyles(externalContainerStyle)(ManuelWatchListCheck);