import { DialogContent, DialogTitle } from "@material-ui/core";
import createReportStyle from "assets/jss/material-dashboard-pro-react/views/createReportStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import { DeleteIcon, EditIcon } from "core/Icons";
import ResourceHelper from "core/ResourceHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import DualListBox from "react-dual-listbox";
import ReactTable from "react-table";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericCheckInput, GenericDateInput, GenericDialog, GenericDialogActions, GenericEmailInput, GenericGrid, GenericLabel, GenericRadioInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import { DocumentType, DynamicReportType, MenuCodes, ProgramCodes } from "views/Constants/Constant.js";

class CreateReport extends Component {

	constructor(props) {
		super(props);

		this.isClient = ClientHelper.IsClient();

		this.initialModel = {
			Id: 0,
			ReportDataModelDefinitionId: 0,
			Title: "",
			Subtitle: "",
			ColumnProperty: [],
			FilterProperty: [],
			ProgramId: -1,
			FilterColumnId: 0,
			EmailMapList: [],
			IsBackoffice: ClientHelper.IsBackOffice(),
			UniqueClientId: this.isClient ? ClientHelper.GetClientRowId() : undefined,
			IsSchedule: true
		};

		this.initialEmailModel = {
			IsRecordValid: true,
			IsValid: false
		};

		this.emptyObject = {};

		this.maxLength30 = { maxLength: 30 };
		this.maxLength64 = { maxLength: 64 };

		this.state = {
			data: [],
			isLoading: false,
			model: { ...this.initialModel },
			vModel: {},
			emailModel: { ...this.initialEmailModel },
			parameterListBoxOption: [],
			parameterListBoxSelected: [],
			columnListBoxOption: [],
			columnListBoxSelected: [],
			index: -1,
			alert: null,
			propertyList: [],
			isEmailDialogOpen: false
		};
		// preserve the initial state in a new object
		this.baseState = this.state;

		this.parameterReportDataModelDefinition = {};

		this.parameterScheduleType = {
			ParameterCode: "ScheduleType"
		};

		this.parameterDocumentType = {
			ParameterCode: "DocumentType"
		};

		this.parameterUniqueClientId = {
			UniqueClientId: this.isClient ? ClientHelper.GetClientId() : undefined
		};

		this.renderItemUniqueClient = {};

		this.sortedDescription = { Member: "Description" };
		this.sortedDefinitionName = { Member: "DefinitionName" };
		this.sortedParameters = { Member: "ParameterDesc" };

		this.Columns = [
			{
				Header: "Actions",
				accessor: "actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip="Edit"
							Icon={EditIcon}
							Disabled={props.Disabled}
							OnClick={() => this.HandleOperationType("Update", row.original)} />

						<GridButton
							tooltip="Delete"
							Icon={DeleteIcon}
							Disabled={props.Disabled}
							OnClick={() => this.HandleOperationType("Delete", row.original)} />
					</div>
				),
				sortable: false,
				filterable: false,
				width: 65
			},
			{
				Header: "Program",
				accessor: "ReportDataModelDefinition.Program.Description"
			},
			{
				Header: "Definition Name",
				accessor: "ReportDataModelDefinition.DefinitionName"
			},
			{
				Header: "Title",
				accessor: "Title"
			},
			{
				Header: "Sub Title",
				accessor: "Subtitle"
			},
			{
				Header: "Report Type",
				accessor: "ReportType",
				Cell: row => {
					var type = DynamicReportType.filter(x => x.Value == row.value);
					return <div>{type && type.length > 0 ? type[0].Text : "-"}</div>;
				}
			},
			{
				Header: "Client",
				accessor: "UniqueClient.ClientName",
				Cell: row => (
					<div>{row.value ? row.value : "All"}</div>
				)
			}
		];
	}

	componentDidMount() {
		const model = { ...this.state.model };


		this.props.setAppCenterTitle("REPORTS");

		if (this.props.MenuCode == MenuCodes.Reports_CreateReport) {
			this.props.setAppLeftTitle("Create Reports");
		} else {
			this.props.setAppLeftTitle("Report Generator");
		}
		if (!model.IsBackoffice) {
			var Program = undefined;

			switch (this.props.MenuCode) {
				case MenuCodes.EFTAdHocReports:
					Program = ProgramCodes.Bank_Programs_EFT;
					this.props.setAppLeftTitle("Ad Hoc Reports");
					this.props.setAppCenterTitle("EFT SYSTEM");
					break;
				case MenuCodes.ETransferAdHocReports:
					Program = ProgramCodes.Bank_Programs_e_Transfer;
					this.props.setAppLeftTitle("Ad Hoc Reports");
					this.props.setAppCenterTitle("INTERAC E-TRANSFER");
					break;
				case MenuCodes.DDTAdHocReports:
					Program = ProgramCodes.Bank_Programs_DDT;
					this.props.setAppLeftTitle("Ad Hoc Reports");
					this.props.setAppCenterTitle("DDT MANAGEMENT");
					break;
				case MenuCodes.LMSAdHocReports:
					Program = ProgramCodes.Lender;
					this.props.setAppLeftTitle("Ad Hoc Reports");
					this.props.setAppCenterTitle("LOAN MANAGEMENT SYSTEM");
					break;
				case MenuCodes.LOSAdHocReports:
					Program = ProgramCodes.Lender;
					this.props.setAppLeftTitle("Ad Hoc Reports");
					this.props.setAppCenterTitle("LOAN ORIGINATOR SYSTEM");
					break;
				case MenuCodes.BillPaymentAdHocReports:
					Program = ProgramCodes.BillPayment;
					this.props.setAppLeftTitle("Ad Hoc Reports");
					this.props.setAppCenterTitle("BILL PAYMENT");
					break;
				case MenuCodes.BankingAdHocReports:
					Program = ProgramCodes.Bank_Programs_Bank_Account;
					this.props.setAppLeftTitle("Ad Hoc Reports");
					this.props.setAppCenterTitle("WALLET");
					break;
				case MenuCodes.PrepaidAdHocReports:
					Program = ProgramCodes.Prepaid;
					this.props.setAppLeftTitle("Ad Hoc Reports");
					this.props.setAppCenterTitle("CARD MANAGEMENT SYSTEM");
					break;
				default:
					break;
			}

			if (Program) {
				this.setState({ isLoading: true });
				Proxy.POST("/coreapi/v1.0/dynamic/do",
					{
						operation: "GetByParameter",
						dataModel: "Bank.Program",
						value: {
							ProgramCode: Program
						},
						disableLike: [
							"ProgramCode"
						]
					},
					responseData => {
						this.setState({ isLoading: false });
						if (!responseData.IsSucceeded) {
							this.ShowMessage("error", "An error occurred while fetching data", responseData.ErrorDescription);
							return;
						}
						if (responseData.Item !== null && responseData.Item) {
							if (responseData.Item.length > 0) {
								model.ProgramId = responseData.Item[0].Id;
								model.ProgramCode = responseData.Item[0].ProgramCode;
								this.baseState.model.ProgramId = responseData.Item[0].Id;
								this.baseState.model.ProgramCode = responseData.Item[0].ProgramCode;
								this.setState({ model });
							}
						}
					},
					e => {
						this.setState({ isLoading: false });
						this.ShowMessage("error", "An error occurred while fetching data", typeof e == "object" ? JSON.stringify(e || "") : e || "");
					}
				);
			}
		}
	}

	HandleSearch = () => {
		const { model } = this.state;

		this.setState({ isLoading: true });
		Proxy.POST("/coreapi/v1.0/Report/SearchReportDefinition",
			{
				IsBackoffice: model.IsBackoffice,
				ProgramId: model.ProgramId,
				MenuCode: this.props.MenuCode,
				ReportDataModelDefinitionId: model.ReportDataModelDefinitionId,
				ReportType: model.ReportType
			},
			responseData => {

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "An error occurred while fetching data", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null && responseData.Item) {
					this.setState({ data: responseData.Item, isLoading: false });
				} else {
					this.setState({ data: [], isLoading: false });
				}
			},
			e => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while fetching data", typeof e == "object" ? JSON.stringify(e || "") : e || "");
			}
		);
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
	}

	ShowMessageWithCallback = (type, title, message, callback, args) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: null })} OnConfirm={() => callback(args)} />
		});
	}

	ShowMessageAndRefresh = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlertAndRefresh()} />
		});
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };

		if (name == "ReportDataModelDefinitionId") {
			model.FilterColumnId = 0;
			this.GetProperties(newValue);
		}
		if (name === "DocumentTypeId") {
			model.DocumentType = data;
		}

		model[name] = newValue;
		this.setState({ model });
	}

	hideAlert = () => {
		this.setState({
			alert: null
		});
	}

	hideAlertAndRefresh = () => {
		this.setState({
			alert: null
		});
		localStorage.removeItem("MenuData");
		window.location.reload(true);
	}

	columnListBoxOnChange = (selected) => {
		this.setState({
			columnListBoxSelected: selected
		});
	}

	parameterListBoxOnChange = (selected) => {
		this.setState({
			parameterListBoxSelected: selected
		});
	}

	HandleClear = () => {
		this.setState(function (state) {
			state = this.baseState;
			return state;
		});
	}

	HandleChangeEmail = (name, newValue, data) => {
		const emailModel = { ...this.state.emailModel };
		emailModel[name] = newValue;
		emailModel.IsValid = data.IsValid;
		this.setState({ emailModel });
	}

	HandleToggleEmail = (n, v, x) => {
		let { model } = this.state;
		var sel = model.EmailMapList.find(c => c === x);
		if (sel === undefined)
			return;
		sel[n] = v;
		this.setState({ model });
	}

	HandleAddEmail = () => {
		const { emailModel } = this.state;
		var emailList = this.state.model.EmailMapList;
		var isExist = emailList.find(x => x.Email === emailModel.Email) !== undefined;
		if (!isExist) {
			emailList.push(emailModel);
			this.setState({ emailModel: { ...this.initialEmailModel } });
		}
	}

	HandleDelete = (args) => {
		this.setState({ isLoading: true });
		Proxy.POST("/coreapi/v1.0/Report/DeleteReportDefinition", { "Id": args.itemId },
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "An error occurred while fetching data", responseData.ErrorDescription);
					return;
				}
				this.ShowMessageAndRefresh("success", "Deleted", "Selected item deleted.")
			},
			e => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while fetching data", typeof e == "object" ? JSON.stringify(e || "") : e || "");
			}
		);
	}

	HandleOperationType = (operation, selectedItem) => {
		if (operation == "Delete") {
			this.ShowMessageWithCallback("warning", "Are your sure?", "The selected item will be deleted, are you sure?", this.HandleDelete, { itemId: selectedItem.Id, screenId: selectedItem.ScreenId })
		}
	}

	GetProperties = (ReportDataModelDefinitionId, filterList, columnList) => {
		this.setState({ isLoading: true });

		Proxy.POST("/coreapi/v1.0/Report/GetDefinedDataModelProperyById",
			{
				"ReportDataModelDefinitionId": ReportDataModelDefinitionId
			},
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "An error occurred while fetching data", responseData.ErrorDescription);
					return;
				}

				var data = responseData.Item;

				var newListBoxOptionColumn = [];
				var newListBoxOptionColumnSelected = [];
				var newListBoxOptionFilter = [];
				var newListBoxOptionFilterSelected = [];

				data.forEach(function (element) {
					if (element.IsFilter)
						newListBoxOptionFilter.push({ "value": element.Id.toString(), "label": element.DisplayName });
					if (element.IsColumn)
						newListBoxOptionColumn.push({ "value": element.Id.toString(), "label": element.DisplayName });
				});

				if (filterList != undefined) {
					filterList.forEach(function (element) {
						newListBoxOptionFilterSelected.push(element.toString());
					});
					this.setState({
						parameterListBoxSelected: newListBoxOptionFilterSelected
					});
				}

				if (columnList != undefined) {
					columnList.forEach(function (element) {
						newListBoxOptionColumnSelected.push(element.toString());
					});

					this.setState({
						columnListBoxSelected: newListBoxOptionColumnSelected
					});
				}

				this.setState({
					propertyList: data,
					columnListBoxOption: newListBoxOptionColumn,
					parameterListBoxOption: newListBoxOptionFilter
				});
			},
			e => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while fetching data", typeof e == "object" ? JSON.stringify(e || "") : e || "");
			}
		);
	}

	HandleSubmit = () => {
		const { model } = this.state;

		if (!this.Validate())
			return;

		this.setState({ isLoading: true });

		Proxy.POST("/coreapi/v1.0/Report/InsertOrUpdateReportDefinitionInfo",
			{ ...model, ...{ MenuCode: this.props.MenuCode } },
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "An error occurred while fetching data", responseData.ErrorDescription);
					return;
				}
				var newModel = Object.assign({}, model);
				newModel.Id = responseData.Item.Id;
				this.setState({ model: newModel });
				ResourceHelper.SetLanguage(null); // Default English will be set

				this.ShowMessage("success", "Success", "Report request added queue. When render operation is done you can download on Report Download menu.");
			},
			e => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while fetching data", typeof e == "object" ? JSON.stringify(e || "") : e || "");
			}
		);

		// //update show column, show filter in ReportProperty table
		// //inser to core.screen
	}

	RowSelected = (index) => {
		let { model, data } = this.state;
		var selectedItemId = data[index].Id;

		this.setState({ index, isLoading: true });

		Proxy.GET("/coreapi/v1.0/Report/GetReportDefinitionById/Id?Id=" + selectedItemId,
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "An error occurred while fetching data", responseData.ErrorDescription);
					return;
				}

				model = responseData.Item[0];
				if (!model.UniqueClientId)
					model.UniqueClientId = -1;
				if (model.ReportDataModelDefinition.ProgramId > 0) {
					model.ProgramId = model.ReportDataModelDefinition.ProgramId;
				}

				model.BeginDate = DateHelper.ToDateInputValue(model.BeginDate);
				model.EndDate = DateHelper.ToDateInputValue(model.EndDate);
				model.IsBackoffice = this.initialModel.IsBackoffice;

				this.GetProperties(model.ReportDataModelDefinitionId, model.DefinedFilterColumn, model.DefinedColumn);

				this.setState({
					isLoading: false,
					model
				});
			},
			e => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while fetching data", typeof e == "object" ? JSON.stringify(e || "") : e || "");
			}
		);
	}

	GetReportDataModelDefinitionParameter = () => {
		const { model } = this.state;
		this.parameterReportDataModelDefinition.ProgramId = model.ProgramId;
		this.parameterReportDataModelDefinition.MenuCode = this.props.MenuCode;
		this.parameterReportDataModelDefinition.UniqueClientId = model.UniqueClientId;
		return this.parameterReportDataModelDefinition;
	}

	Validate() {
		const { model, vModel } = this.state;

		model.ColumnProperty = this.state.columnListBoxSelected;
		model.FilterProperty = this.state.parameterListBoxSelected;

		if (model.ReportType === "0") {
			model.BeginDate = DateHelper.GetDate();
		}

		var messages = [];

		vModel.ProgramId = model.ProgramId == null || model.ProgramId == 0;
		if (vModel.ProgramId) messages.push("Program must be selected");

		vModel.ReportDataModelDefinitionId = model.ReportDataModelDefinitionId == null || model.ReportDataModelDefinitionId == 0;
		if (vModel.ReportDataModelDefinitionId) messages.push("Data Model must be selected");

		vModel.FilterColumnId = model.FilterColumnId == null || model.FilterColumnId == 0;
		if (vModel.FilterColumnId) messages.push("Filter Column must be selected");

		vModel.ReportType = model.ReportType == null || model.ReportType === undefined;
		if (vModel.ReportType) messages.push("Report Type must be selected");

		vModel.UniqueClientId = model.UniqueClientId == null || model.UniqueClientId == 0;
		if (vModel.UniqueClientId) messages.push("Client must be selected");

		if (model.ColumnProperty.length == undefined || model.ColumnProperty.length == 0) {
			messages.push("At least one column must be selected");
		}

		vModel.Title = model.Title == null || model.Title == "";
		if (vModel.Title) messages.push("Title must be entered");

		if (model.Title) {
			vModel.Title = model.Title.length < 6;
			if (vModel.Title) messages.push("Title is too short");
		}

		vModel.EmailMapList = model.EmailMapList === undefined || (model.EmailMapList != null && model.EmailMapList.filter(x => x.IsRecordValid == true).length == 0);
		if (vModel.EmailMapList) messages.push("At least one e-mail must be entered");

		vModel.BeginDate = (typeof model.BeginDate != "object" || model.BeginDate == "" || model.BeginDate == null) && model.ReportType === "2";
		if (vModel.BeginDate) messages.push("Begin Date must be selected");

		vModel.EndDate = typeof model.EndDate != "object" || model.BeginDate == "" || model.BeginDate == null;
		if (vModel.EndDate) messages.push("End Date must be selected");

		vModel.DocumentTypeId = model.DocumentTypeId == null || model.DocumentTypeId == 0;
		if (vModel.DocumentTypeId) messages.push("Document Type must be selected");

		if (model.DocumentType && model.DocumentType.ParameterValue === DocumentType.PDF.toString()) {
			if (model.ColumnProperty.length > 15) {
				this.props.showMessageNode("warning", "Required fields", "PDF document cannot exceed 15 columns");
				return false;
			}
		}

		if (model.BeginDate > model.EndDate) {
			vModel.BeginDate = true;
			vModel.EndDate = true;
			this.ShowMessage("warning", "Request is invalid!", "Begin Date cannot be bigger than End Date");
			return false;
		}

		if (messages.length > 0) {
			this.props.showMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.setState({ vModel });
		return true;
	}

	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}

	render() {
		const { model, vModel, emailModel, isLoading, alert, columnListBoxOption, columnListBoxSelected, data, propertyList, isEmailDialogOpen } = this.state;

		const filterColumnList = propertyList.filter(x => x.PropertyType.includes("date") && x.IsFilter);

		const { classes } = this.props;

		return (
			<div>
				<LoadingComponent Show={isLoading} />

				<GenericDialog open={isEmailDialogOpen} fullWidth={true} maxWidth="sm">
					<DialogTitle>
						<GenericLabel Text="Schedule E-Mail" FontSize="20px" Bold={true} />
					</DialogTitle>
					<DialogContent>
						<GridContainer spacing={16}>
							<GridItem xs={9}>
								<GenericEmailInput
									Name="Email"
									LabelText="E-Mail"
									Value={emailModel.Email}
									ValueChanged={this.HandleChangeEmail}
									inputProps={this.maxLength64} />
							</GridItem>
							<GridItem xs={3}>
								<Button
									size="sm"
									onClick={this.HandleAddEmail}
									fullWidth
									disabled={!emailModel.IsValid}
								>
									Add
								</Button>
							</GridItem>

							<GridItem xs={12} style={{ marginTop: 10 }}>
								<ReactTable
									className="-striped -highlight"
									data={model.EmailMapList || []}
									filterable={false}
									columns={[
										{
											Header: "E-Mail",
											accessor: "IsRecordValid",
											Cell: row => (row.original != null &&
												<div>
													<GenericCheckInput
														Grid
														Name="IsRecordValid"
														LabelText={row.original.Email}
														Value={model.EmailMapList.find(x => x == row.original) && model.EmailMapList.find(x => x === row.original).IsRecordValid}
														ValueChanged={(n, v) => this.HandleToggleEmail(n, v, row.original)} />
												</div>
											)
										}
									]}
									defaultPageSize={50}
									showPaginationTop={false}
									showPaginationBottom={Object.keys(model.EmailMapList || []).length > 0}
									minRows={2}
								/>
							</GridItem>
						</GridContainer>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={() => this.setState({ isEmailDialogOpen: false })}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>
				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.HandleSearch },
					{ Code: "Submit", OnClick: this.HandleSubmit, Disabled: model.Id > 0 },
					{ Code: "Update", OnClick: this.HandleSubmit, Disabled: model.Id === 0 },
					{ Code: "Clear", OnClick: this.HandleClear }
				]} menuId={this.props.menuId} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius" >
							<CardHeader>
								<GridContainer spacing={16} justify="space-between" alignItems="center">
									<GridItem>
										<CardHeader>
											<h4><b>Report Data Model</b></h4>
										</CardHeader>
									</GridItem>
								</GridContainer>
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={3}>
										<GenericSelectInput
											Name="ProgramId"
											LabelText="Program"
											Method="GET"
											Url="/bankapi/v1.0/Bank/ValidBankPrograms"
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember="Description"
											Value={model.ProgramId}
											ValueChanged={this.HandleChange}
											Disabled={!model.IsBackoffice}
											Sorted={this.sortedDescription}
											Required={true}
											IsInvalid={vModel.ProgramId} />
									</GridItem>
									<GridItem xs={3}>
										<GenericSelectInput
											key={"ReportDataModelDef_" + model.ProgramId + model.UniqueClientId}
											Name="ReportDataModelDefinitionId"
											LabelText="Data Model"
											Method="POST"
											Url="/coreapi/v1.0/Report/SearchReportDataModelDefinition"
											Parameter={this.GetReportDataModelDefinitionParameter()}
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember="DefinitionName"
											Value={model.ReportDataModelDefinitionId}
											ValueChanged={this.HandleChange}
											Sorted={this.sortedDefinitionName}
											Required={true}
											IsInvalid={vModel.ReportDataModelDefinitionId} />
									</GridItem>
									<GridItem xs={3}>
										<GenericSelectInput
											IsStatic={true}
											StaticData={filterColumnList}
											Name="FilterColumnId"
											Value={model.FilterColumnId}
											LabelText="Filter Column"
											ValueChanged={this.HandleChange}
											KeyValueMember="Id"
											TextValueMember="DisplayName"
											Disabled={model.ReportDataModelDefinitionId == 0}
											Required={true}
											IsInvalid={vModel.FilterColumnId} />
									</GridItem>
									<GridItem xs={3}>
										<GenericRadioInput
											Name="ReportType"
											LabelText="Report Type"
											IsStatic={true}
											StaticData={DynamicReportType.filter(x => x.Value === 0 || x.Value === 2)}
											KeyValueMember="Value"
											TextValueMember="Text"
											Value={model.ReportType}
											ValueChanged={this.HandleChange} />
									</GridItem>
									<GridItem xs={3}>
										<GenericSelectInput
											Name="UniqueClientId"
											LabelText="Client"
											Method="POST"
											Url="/bankapi/v1.0/BankCustomer/Search"
											Parameter={this.parameterUniqueClientId}
											DataRoot="Item"
											KeyValueMember="Id"
											RenderItem={this.RenderItemUniqueClient}
											Value={model.UniqueClientId}
											ValueChanged={this.HandleChange}
											CanClear
											All
											Disabled={this.isClient}
											DefaultIndex={this.isClient ? 0 : -1}
											Required={true}
											IsInvalid={vModel.UniqueClientId} />
									</GridItem>
									<GridItem xs={3}>
										{(model.ReportType || "").toString() === "2" &&
											<GridContainer>
												<GridItem xs={8}>
													<GenericDateInput
														Name="BeginDate"
														LabelText="Between"
														LabelMd={6}
														Utc={true}
														Value={model.BeginDate ? DateHelper.ToDateInputValue(model.BeginDate) : ""}
														ValueChanged={this.HandleChange}
														Required={true}
														IsInvalid={vModel.BeginDate} />
												</GridItem>
												<GridItem xs={4}>
													<GenericDateInput
														Name="EndDate"
														LabelMd={0}
														Utc={true}
														Value={model.EndDate ? DateHelper.ToDateInputValue(model.EndDate) : ""}
														ValueChanged={this.HandleChange}
														Required={true}
														IsInvalid={vModel.EndDate} />
												</GridItem>
											</GridContainer>
										}
										{(model.ReportType || "").toString() !== "2" &&
											<GenericDateInput
												Name="EndDate"
												LabelText="Expire Date"
												Utc={true}
												Value={model.EndDate ? DateHelper.ToDateInputValue(model.EndDate) : ""}
												ValueChanged={this.HandleChange}
												Required={true}
												IsInvalid={vModel.EndDate} />
										}
									</GridItem>
									<GridItem xs={3}>
										<GenericTextInput
											Name="Title"
											LabelText="Report Title"
											Value={this.state.model.Title}
											ValueChanged={this.HandleChange}
											Required={true}
											IsInvalid={vModel.Title} />
									</GridItem>
									<GridItem xs={3}>
										<GenericSelectInput
											Name="DocumentTypeId"
											LabelText="Document Type"
											Method="POST"
											Url="/coreapi/v1.0/Parameter/Search"
											Parameter={this.parameterDocumentType}
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember="ParameterDesc"
											Sorted={this.sortedParameters}
											Value={model.DocumentTypeId}
											ValueChanged={this.HandleChange}
											Required={true}
											IsInvalid={vModel.DocumentTypeId} />
									</GridItem>
									<GridItem>
										<div style={{ marginTop: 4 }}>
											<Button
												size="sm"
												onClick={() => this.setState({ isEmailDialogOpen: true })}
												fullWidth
											>
												E-Mail List
											</Button>
										</div>
									</GridItem>
								</GridContainer>

							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

				<GridContainer spacing={16}>
					<GridItem item xs={12}>
						<Card className="no-radius" >
							<CardHeader>
								<GridContainer spacing={16} justify="space-between" alignItems="center">
									<GridItem>
										<CardHeader>
											<h4><b>Column Selection</b></h4>
										</CardHeader>
									</GridItem>
								</GridContainer>
							</CardHeader>
							<CardBody>
								<DualListBox
									className={classes.root}
									options={columnListBoxOption}
									selected={columnListBoxSelected}
									onChange={(selected) => this.columnListBoxOnChange(selected)}
								/>
							</CardBody>
						</Card>
					</GridItem>
					{/* <GridItem item xs={6}>
						<Card className="no-radius" >
							<CardHeader>
								<GridContainer spacing={16} justify="space-between" alignItems="center">
									<GridItem>
										<CardHeader>
											<h4><b>Filter Panel Parameter Selection for Screen</b></h4>
										</CardHeader>
									</GridItem>
								</GridContainer>
							</CardHeader>
							<CardBody>
								<DualListBox
									options={parameterListBoxOption}
									selected={parameterListBoxSelected}
									onChange={(selected) => this.parameterListBoxOnChange(selected)} />
							</CardBody>
						</Card>
					</GridItem> */}
				</GridContainer>

				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GridItem xs={12}>
									<GridItem>
										<GenericGrid
											Data={data}
											Columns={this.Columns}
											RowSelected={this.RowSelected}
											SelectedIndex={this.state.index}
											DocumentTitle={"Report List"}
											ProgramCode={model.ProgramCode} />
									</GridItem>
								</GridItem>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

			</div >
		);
	}
}

CreateReport.propTypes = {
	classes: PropTypes.object.isRequired,
	model: PropTypes.object
};

export default withArtifex(CreateReport, createReportStyle);