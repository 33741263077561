import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React from "react";
import { GenericCheckInput, GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericLabel, GenericSelectInput, GenericTextInput } from "views/Components/Generic";

const styles = ({
	picture: {
		width: "100px",
		height: "100px",
		overflow: "hidden",
		transition: "all 0.2s",
	},
	pictureSrc: {
		width: "100%",
		height: "100%"
	}
});

class SiteVisitAddOrUpdate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isPhotoDialogOpen: false
		};
	}

	render() {
		const { classes, model, fileModel, filterModel,vModel } = this.props;
		return (
			<div>
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<GenericDialog open={this.state.isPhotoDialogOpen} fullWidth maxWidth="sm" onBackdropClick={() => this.setState({ isPhotoDialogOpen: false })}>
							<DialogTitle>
								<GenericLabel Text="Site Visit Photo" FontSize="20px" Bold={true} />
							</DialogTitle>
							<DialogContent>
								<img
									style={{ marginTop: 24 }}
									src={model.Photo}
									className={classes.pictureSrc}
									alt={fileModel.File ? fileModel.FileName : "..."}
								/>
							</DialogContent>
							<GenericDialogActions>
								<Button onClick={() => this.setState({ isPhotoDialogOpen: false })}>Close</Button>
							</GenericDialogActions>
						</GenericDialog>

						<Card>
							<CardBody>
								<GenericExpansionPanel>
									<GridContainer>
										<GridItem xs={2}>
											<GenericDateInput
												key={filterModel.StartDateOfVisit}
												Name="StartDateOfVisit"
												LabelMd={6}
												LabelText="Start Date of Visit"
												Value={filterModel.StartDateOfVisit ? DateHelper.ToDateInputValue(filterModel.StartDateOfVisit) : ""}
												ValueChanged={this.props.handleChangeFilter}
												IncludeTime={false}
												MaxDate={DateHelper.GetDate()} 
												Required
												IsInvalid={vModel.StartDateOfVisit}
												/>
										</GridItem>
										<GridItem xs={2}>
											<GenericDateInput
												key={filterModel.EndDateOfVisit}
												Name="EndDateOfVisit"
												LabelMd={6}
												LabelText="End Date of Visit"
												Value={filterModel.EndDateOfVisit ? DateHelper.ToDateInputValue(filterModel.EndDateOfVisit) : ""}
												ValueChanged={this.props.handleChangeFilter}
												IncludeTime={false}
												MaxDate={DateHelper.GetDate()} 
												Required
												IsInvalid={vModel.EndDateOfVisit}
												/>
										</GridItem>
										<GridItem xs={2}>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelMd={4}
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={{}}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
												Value={filterModel.UniqueClientId || ""}
												ValueChanged={this.props.handleChangeFilter}
												CanClear
												All={true}
												Required
												IsInvalid={vModel.UniqueClientId2}
											/>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card>
							<CardBody>
								<GenericExpansionPanel
									Title="Site Visit Information"
									IsActive={false}>
									<GridContainer>
										<GridItem xs={4}>
											<GridItem>
												<GenericSelectInput
													Name="UniqueClientId"
													LabelMd={6}
													LabelText="Client"
													Method="POST"
													Url="/bankapi/v1.0/BankCustomer/Search"
													Parameter={{}}
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
													Value={model.UniqueClientId || ""}
													ValueChanged={this.props.handleChange}
													CanClear 
													Required
													IsInvalid={vModel.UniqueClientId}
													/>
											</GridItem>
											<GridItem>
												<GenericTextInput
													Name="LegalName"
													LabelMd={6}
													LabelText="Legal Name"
													Value={model.LegalName}
													ValueChanged={this.props.handleChange}
													Required
													IsInvalid={vModel.LegalName}
													/>
											</GridItem>
											<GridItem>
												<GenericTextInput
													Name="Dba"
													LabelMd={6}
													LabelText="DBA (If Applicable)"
													Value={model.Dba}
													ValueChanged={this.props.handleChange} />
											</GridItem>
											<GridItem>
												<GenericDateInput
													Name="DateOfVisit"
													LabelMd={6}
													LabelText="Date of Visit"
													MaxDate={DateHelper.GetDate()}
													Value={model.DateOfVisit ? DateHelper.ToDateInputValue(model.DateOfVisit) : ""}
													ValueChanged={this.props.handleChange} 
													Required
													IsInvalid={vModel.DateOfVisit}
													/>
											</GridItem>
											<GridItem>
												<GenericTextInput
													Name="DcbRepresentInAttandance"
													LabelMd={6}
													LabelText="DCBank Representative in Attendance"
													Value={model.DcbRepresentInAttandance}
													ValueChanged={this.props.handleChange} />
											</GridItem>
											<GridItem>
												<GenericTextInput
													Name="AccountRepresentInAttandance"
													LabelMd={6}
													LabelText="Wallet Representative in Attendance"
													Value={model.AccountRepresentInAttandance}
													ValueChanged={this.props.handleChange} />
											</GridItem>
										</GridItem>
										<GridItem xs={3}>
											<GridItem>
												<GenericTextInput
													Name="AddressLine1"
													LabelText="Address Line 1"
													Id="street-address"
													Value={model.AddressLine1}
													ValueChanged={this.props.handleChange} />
											</GridItem>
											<GridItem>
												<GenericTextInput
													Name="AddressLine2"
													Id="street-AddressLine2"
													LabelText="Address Line 2"
													Value={model.AddressLine2}
													ValueChanged={this.props.handleChange} />
											</GridItem>
											<GridItem>
												<GenericTextInput
													Name="Country"
													Id="country"
													LabelText="Country"
													Value={model.Country}
													ValueChanged={this.props.handleChange}
													Required
													IsInvalid={vModel.Country}
													/>
											</GridItem>
											<GridItem>
												<GenericTextInput
													Name="City"
													LabelText="City"
													Id="city"
													Value={model.City}
													ValueChanged={this.props.handleChange} 
													Required
													IsInvalid={vModel.City}
													/>
											</GridItem>
											<GridItem>
												<GenericTextInput
													Name="Province / State"
													Id="state"
													LabelText="Province"
													Value={model.Province}
													ValueChanged={this.props.handleChange} />
											</GridItem>
											<GridItem>
												<GenericTextInput
													Name="ZipCode"
													Id="postcode"
													LabelText="Postal Code"
													Value={model.ZipCode}
													ValueChanged={this.props.handleChange} 
													Required
													IsInvalid={vModel.ZipCode}
													/>
											</GridItem>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card>
							<CardBody>
								<GenericExpansionPanel
									Title="Site Visit Checklist"
									IsActive={false}>
									<GridContainer>
										<GridItem xs={3}>
											<GridItem>
												<GridItem>
													<GenericCheckInput
														Name="IsPhotoTakenCompleted"
														LabelText="Building / Office Photo taken for DCBank Database"
														Value={model.IsPhotoTakenCompleted}
														ValueChanged={this.props.handleChange}
														/>
												</GridItem>
											</GridItem>
											<GridItem>
												<h6>Location Inspected</h6>
												<GridItem>
													<GenericCheckInput
														Name="IsHeadOfficeInspectedCompleted"
														LabelText="Head Office"
														Value={model.IsHeadOfficeInspectedCompleted}
														ValueChanged={this.props.handleChange} />
												</GridItem>
												<GridItem>
													<GenericCheckInput
														Name="IsPoPBusinessOpInspectedCompleted"
														LabelText="Place of Primary Business Operations"
														Value={model.IsPoPBusinessOpInspectedCompleted}
														ValueChanged={this.props.handleChange} />
												</GridItem>
												<GridItem>
													<GenericCheckInput
														Name="IsAgentLocationInspectedCompleted"
														LabelText="Agent Location"
														Value={model.IsAgentLocationInspectedCompleted}
														ValueChanged={this.props.handleChange} />
												</GridItem>
												<GridItem>
													<GenericCheckInput
														Name="IsBankingPartnerInspectedCompleted"
														LabelText="Banking Partner"
														Value={model.IsBankingPartnerInspectedCompleted}
														ValueChanged={this.props.handleChange} />
												</GridItem>
												<GridItem>
													<GenericCheckInput
														Name="IsOtherInspectedCompleted"
														LabelText="Other (Please describe)"
														Value={model.IsOtherInspectedCompleted}
														ValueChanged={this.props.handleChange} />
												</GridItem>
											</GridItem>
										</GridItem>
										<GridItem xs={3}>
											<div style={{ float: "right", marginRight: 10, opacity: !model.IsPhotoTakenCompleted ? 0.4 : 1 }}>
												<Button
													size="sm"
													onClick={this.props.uploadClick}
													disabled={!model.IsPhotoTakenCompleted}>
													Browse Photo
												</Button>
												<input type="file" style={{ display: "none" }} onChange={this.props.fileSelect} ref={this.props.fileInputRef} accept="image/*" />
												<Button
													size="sm"
													onClick={() => this.setState({ isPhotoDialogOpen: true })}
													disabled={!model.IsPhotoTakenCompleted}>
													View Photo
												</Button>
											</div>
											<GridItem>
												<GenericTextInput
													Name="SiteVisitNote"
													LabelText="Notes"
													Value={model.SiteVisitNote}
													ValueChanged={this.props.handleChange}
													MultiLine={true}
													RowCount={5} />
											</GridItem>
											<GridItem>
												<GenericTextInput
													Name="OtherDescription"
													LabelText="Description"
													Value={model.OtherDescription}
													ValueChanged={this.props.handleChange} />
											</GridItem>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card>
							<CardBody>
								<GenericExpansionPanel
									Title="Site Visit Completion"
									IsActive={false}>
									<GridContainer>
										<GridItem xs={3}>
											<GridItem>
												<GenericTextInput
													Name="VisitCompletionName"
													LabelText="Name"
													Value={model.VisitCompletionName}
													ValueChanged={this.props.handleChange} />
											</GridItem>
											<GridItem>
												<GenericTextInput
													Name="VisitCompletionTitle"
													LabelText="Title"
													Value={model.VisitCompletionTitle}
													ValueChanged={this.props.handleChange} />
											</GridItem>
										</GridItem>
										<GridItem xs={3}>
											<GridItem>
												<GenericDateInput
													Name="VisitCompletionDate"
													LabelText="Date"
													MaxDate={DateHelper.GetDate()}
													Value={model.VisitCompletionDate ? DateHelper.ToDateInputValue(model.VisitCompletionDate) : ""}
													ValueChanged={this.props.handleChange} 
													Required={model.VisitCompletionDate?true:false}
													IsInvalid={vModel.VisitCompletionDate}
													/>
											</GridItem>
											<GridItem>
												<GenericSelectInput
													Name="VisitCompletionStatusId"
													LabelText="Completion Status"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={{
														ParameterCode: "VisitCompletionStatus"
													}}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={model.VisitCompletionStatusId}
													ValueChanged={this.props.handleChange}
													Required
													IsInvalid={vModel.VisitCompletionStatusId}
												/>
											</GridItem>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer >
			</div >
		);
	}
}

SiteVisitAddOrUpdate.propTypes = {
	classes: PropTypes.object,
	model: PropTypes.object,
	fileModel: PropTypes.object,
	filterModel: PropTypes.object,
	fileInputRef: PropTypes.object,
	handleChange: PropTypes.func,
	handleChangeFilter: PropTypes.func,
	uploadClick: PropTypes.func,
	fileSelect: PropTypes.func
};

export default withArtifex(SiteVisitAddOrUpdate, styles);
