import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React from "react";
import AccountLimitToolbar from "views/Components/AccountLimitToolbar";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDateInput, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import { GridColumnType, ProgramCodes } from "views/Constants/Constant.js";

class BulkFiles extends React.Component {
	constructor(props) {
		super(props);

		this.isBackOffice = ClientHelper.IsBackOffice();
		this.initialModel = {
			UniqueClientId: this.isBackOffice ? null : ClientHelper.GetClientRowId(),
			FromDate: DateHelper.GetDate(),
			ToDate: DateHelper.GetDate(),
			FileStatusId: null,
			FileName: null,
			ReferenceNumber: null,
			IsUniqueDataSearch: false
		};

		this.state = {
			transactionList: [],
			fileList: [],
			model: { ...this.initialModel },
			vModel: {}
		};

		this.bulkFilesColumns = [
			{
				Header: "File Date",
				accessor: "FileDate",
				type: GridColumnType.DateUtc
			},
			{
				Header: "Unique Client Id",
				accessor: "ClientNumber",
				show: this.isBackOffice
			},
			{
				Header: "Client Name",
				accessor: "ClientName",
				show: this.isBackOffice
			},
			{
				Header: "File Name",
				accessor: "FileName"
			},
			{
				Header: "File Source",
				accessor: "FileSource"
			},
			{
				Header: "File Status",
				accessor: "FileStatus"
			},
			{
				Header: "Record Count",
				accessor: "RecordCount"
			},
			{
				Header: "Reject Count",
				accessor: "RejectCount"
			},
			{
				Header: "Total Nr of Credit Trans.",
				accessor: "TotalNumberCredit"
			},
			{
				Header: "Total Credit Amount",
				accessor: "TotalAmountCredit",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Total Fee Credit Amount",
				accessor: "TotalFeeAmountCredit",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Total Nr of Debit Trans.",
				accessor: "TotalNumberDebit"
			},
			{
				Header: "Total Debit Amount",
				accessor: "TotalAmountDebit",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Total Fee Debit Amount",
				accessor: "TotalFeeAmountDebit",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			}

		];

		this.transactionColumns = [
			{
				Header: "Transaction Date",
				accessor: "TransactionDate",
				type: GridColumnType.DateUtc
			},
			{
				Header: "Settlement Date",
				accessor: "SettlementDate",
				type: GridColumnType.DateUtc
			},
			{
				Header: "Unique Client Id",
				accessor: "ClientNumber",
				show: this.isBackOffice
			},
			{
				Header: "Client Name",
				accessor: "ClientName",
				show: this.isBackOffice
			},
			{
				Header: "Customer Name",
				accessor: "CustomerName"
			},
			{
				Header: "Transaction Type",
				accessor: "TransactionType"
			},
			{
				Header: "Financial Institution",
				accessor: "FID"
			},
			{
				Header: "Financial Institution Branch",
				accessor: "FIBranch"
			},
			{
				Header: "Customer Wallet Number",
				accessor: "AccountNumber"
			},
			{
				Header: "Amount",
				accessor: "Amount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Fee Amount",
				accessor: "FeeAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Transaction Status",
				accessor: "TransactionStatus"
			},
			{
				Header: "EFT Type",
				accessor: "EftTransactionType"
			},
			{
				Header: "Transit Number",
				accessor: "TransitNumber"
			},
			{
				Header: "Customer Number",
				accessor: "CustomerNumber"
			},
			{
				Header: "File ID",
				accessor: "FileId"
			},
			{
				Header: "File Name",
				accessor: "FileName"
			},
			{
				Header: "Transaction Source",
				accessor: "TransactionSource"
			},
			{
				Header: "Client User",
				accessor: "InsertUser"
			},
			{
				Header: "Transaction Reference Number",
				accessor: "ReferenceNumber"
			},
			{
				Header: "Store",
				accessor: "CustomerStore",
				width: 150
			}
		];



		this.sortedFilesByDate = [{ id: "FileDate", desc: true }];
		this.sortedTransactionsByDate = [{ id: "TransactionDate", desc: true }];
		this.emptyObject = {};
		this.genericSelectFileStatusParameter = {
			ParameterCode: "BankFileStatus"
		};
	}

	componentDidMount() {

		const { setAppLeftTitle, setAppCenterTitle } = this.props;
		if (setAppLeftTitle)
			setAppLeftTitle("Bulk Files");
		if (setAppCenterTitle)
			setAppCenterTitle("EFT SYSTEM");
	}

	HandleClear = () => {
		this.setState({ vModel: {}, model: { ...this.initialModel }, transactionList: [], fileList: [] });
	}
	HandleChange = (name, value) => {
		this.setState(function (state) {
			var model = state.model || {};
			var vModel = state.vModel || {};
			model[name] = value;
			if (name == "ReferenceNumber" || name == "FileName") {
				model.IsUniqueDataSearch = value?.length > 0;
				if (model.IsUniqueDataSearch) {
					vModel = {};
				}
			}
			return { model, vModel };
		});
	}

	HandleSearch = async () => {

		if (!this.Validate()) return;
		var result = await this.props.ExecuteApiPost("/bankapi/v1.0/BankBmoEFTFile/SearchEftBulkFile", this.state.model);
		this.setState({ fileList: result || [], transactionList: [] });
	}

	HandleRowSelectedBulkFile = async (index) => {

		var file = this.state.fileList[index];
		var result = await this.props.ExecuteApiPost("/bankapi/v1.0/BankBmoEFTFile/SearchEftBulkFileTransactions", { FileId: file.Id });
		this.setState({ transactionList: result || [] });

	}

	Validate = () => {
		const { model, vModel } = this.state;

		var messages = [];
		if (!model.IsUniqueDataSearch) {
			if ((vModel.UniqueClientId = !model.UniqueClientId)) messages.push("Client not selected");
			if ((vModel.FromDate = !model.FromDate)) messages.push("From Date not selected",);
			if ((vModel.ToDate = !model.ToDate)) messages.push("To Date not selected");

			var diffMonths = model.ToDate.diff(model.FromDate, "days");
			if ((vModel.Date = diffMonths < 0)) messages.push("To Date must be later than From Date");
			if ((vModel.Date = diffMonths > 30)) messages.push("Difference between From Date and To Date cannot be longer than 30 days");
		}
		this.setState({ vModel });
		if (messages.length != 0) {

			this.props.showValidationErrors(messages);
			return false;
		}
		else {
			return true;
		}


	}

	render() {
		const { Disabled } = this.props;
		const { model, vModel, isBackOffice } = this.state;
		return (
			<div>
				{!isBackOffice && <AccountLimitToolbar Program={ProgramCodes.Bank_Programs_EFT} />}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Filter Panel">
									<GridContainer>
										<GridItem xs={4}>
											<GridItem>
												<GenericSelectInput
													Name="UniqueClientId"
													LabelText="Client"
													Method="POST"
													Url="/bankapi/v1.0/BankCustomer/Search"
													Parameter={this.emptyObject}
													DataRoot="Item"
													KeyValueMember="Id"
													Required={!model.IsUniqueDataSearch}
													RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
													Value={model.UniqueClientId || ""}
													ValueChanged={this.HandleChange}
													CanClear
													IsInvalid={vModel.UniqueClientId}
													DefaultIndex={this.isBackOffice ? -1 : 0}
													Disabled={!this.isBackOffice} />
											</GridItem>
											<GridItem>
												<GenericDateInput
													Name="FromDate"
													LabelText="From Date"
													Value={model.FromDate}
													ValueChanged={this.HandleChange}
													MaxDate={model.ToDate || DateHelper.GetDate()}
													IsInvalid={vModel.FromDate || vModel.Date}
													Required={!model.IsUniqueDataSearch}
													Utc />
											</GridItem>
										</GridItem>
										<GridItem xs={4}>
											<GridItem>
												<GenericSelectInput
													All
													Name="FileStatusId"
													LabelText="File Status"
													Value={model.FileStatusId || ""}
													DataRoot="Item"
													ValueChanged={this.HandleChange}
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Url="/coreapi/v1.0/Parameter/Search"
													Method="POST"
													Parameter={this.genericSelectFileStatusParameter} />
											</GridItem>
											<GridItem>
												<GenericDateInput
													Name="ToDate"
													LabelText="To Date"
													Value={model.ToDate}
													ValueChanged={this.HandleChange}
													MinDate={model.FromDate}
													IsInvalid={vModel.ToDate || vModel.Date}
													Required={!model.IsUniqueDataSearch}
													Utc />
											</GridItem>
										</GridItem>
										<GridItem xs={4}>
											<GridItem>
												<GridItem>
													<GenericTextInput
														Name="FileName"
														LabelText="File Name"
														Value={model.FileName}
														ValueChanged={this.HandleChange} />
												</GridItem>
												<GridItem>
													<GenericTextInput Name="ReferenceNumber" LabelText="Reference Number" Value={model.ReferenceNumber} ValueChanged={this.HandleChange} />
												</GridItem>
											</GridItem>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Bulk Files">
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer>
												<GridItem xs={4} />
												<GridItem xs={4} />
												<GridItem xs={4}>
													<GridContainer justify="flex-end">
														<GridItem xs={3} />
													</GridContainer>
												</GridItem>
											</GridContainer>
											<GridContainer spacing={16}>
												<GridItem xs={12}>
													<GenericGrid
														Data={this.state.fileList}
														Columns={this.bulkFilesColumns}
														Sorted={this.sortedFilesByDate}
														ProgramCode={ProgramCodes.Bank_Programs_EFT}
														RowSelected={this.HandleRowSelectedBulkFile} />
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardHeader>
								<GridContainer spacing={16} justify="space-between" alignItems="center">
									<GridItem>
										<CardHeader>
											<h4><b>Transactions of File</b></h4>
										</CardHeader>
									</GridItem>
								</GridContainer>
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer>
											<GridItem xs={4} />
											<GridItem xs={4} />
											<GridItem xs={4}>
												<GridContainer justify="flex-end">
													<GridItem xs={3} />
												</GridContainer>
											</GridItem>
										</GridContainer>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={this.state.transactionList}
													Columns={this.transactionColumns}
													Sorted={this.sortedTransactionsByDate}
													ProgramCode={ProgramCodes.Bank_Programs_EFT} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

BulkFiles.propTypes = {
	classes: PropTypes.object
};

export default BulkFiles;
