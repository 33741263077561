import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import {
  GenericExpansionPanel,
	GenericGrid,
  GenericTextInput,
} from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { MaxLength128 } from "views/Constants/Constant.MaxLength";

class eTransferLegacyClients extends React.Component {
  constructor(props) {
    super(props);

    this.initlegacyGridColumns();

    this.defaultModel = {
      CustomerInteractId: undefined,
      LegacyClientId: undefined,
      LegacyClientName: undefined,
    };

    this.state = {
      isLoadingShow: false,
      model: { ...this.defaultModel },
      legacyGridRefreshToken: false,
      legacyGridLoadAtStartup: false,
      datasource: [],
      selectedRowIndex: undefined,
    };

    this.validateSubmitProperties = [
      { CustomerInteractId: "Customer Interac ID" },
      { LegacyClientId: "Legacy Client ID" },
      { LegacyClientName: "Legacy Client Name" },
    ];
  }

  initlegacyGridColumns() {
    this.legacyGridColums = [
      {
        Header: "Customer Interac ID",
        accessor: "CustomerInteractId",
      },
      {
        Header: "Legacy Client ID",
        accessor: "LegacyClientId",
      },
      {
        Header: "Legacy Client Name",
        accessor: "LegacyClientName",
      },
    ];
  }

  showLoading() {
    this.setState({ isLoading: true });
  }

  hideLoading() {
    this.setState({ isLoading: false });
  }

  onClear = () => {
    this.setState({
      model: { ...this.defaultModel },
      selectedRowIndex: undefined,
    });
  };

  onSearch = () => {
    this.refreshLegacyGrid();
  };

  onSubmit = async () => {
    const { model } = this.state;
    const { ExecuteApiPost, showMessage } = this.props;

    await ExecuteApiPost(
      "/bankapi/v1.0/ClearingSettlement/SubmitLegacyClient",
      model,
      model,
      this.validateSubmitProperties,
      undefined,
      [
        () => {
          showMessage(
            "success",
            "Success",
            "Legacy Clients Added Successfully ."
          );
        },
        this.refreshLegacyGrid,
      ]
    );
  };

  onUpdate = () => {
    const { model } = this.state;
    const { ExecuteApiPost, showMessage } = this.props;

    ExecuteApiPost(
      "/bankapi/v1.0/ClearingSettlement/UpdateLegacyClient",
      model,
      undefined,
      undefined,
      undefined,
      [
        () => {
          showMessage(
            "success",
            "Success",
            "Legacy Clients Updated Successfully ."
          );
        },
      ]
    );
  };

  onDelete = () => {
    const { model } = this.state;
    const { ExecuteApiPost, showConfirmMessageInDelete } = this.props;

    showConfirmMessageInDelete(() => {
      ExecuteApiPost(
        "/bankapi/v1.0/ClearingSettlement/DeleteLegacyClient",
        model,
        undefined,
        undefined,
        undefined,
        [this.onClear, this.refreshLegacyGrid]
      );
    });
  };

  onValueChange = (name, value) => {
    const { model } = this.state;
    model[name] = value;
    this.setState({ model });
  };

  onValidate = () => {
    return true;
  };

  onRowSelect = (index) => {
    const { datasource } = this.state;
    const selectedRow = datasource[index];
    this.setState({ model: selectedRow, selectedRowIndex: index });
  };

  refreshLegacyGrid = () => {
    const { legacyGridRefreshToken } = this.state;
    this.setState({ legacyGridRefreshToken: !legacyGridRefreshToken });
  };

  getToolbarButtons() {
    const { selectedRowIndex } = this.state;

    return [
      { Code: "Clear", OnClick: this.onClear },
      { Code: "Search", OnClick: this.onSearch },
      {
        Code: "Submit",
        OnClick: this.onSubmit,
        Disabled: selectedRowIndex !== undefined,
      },
      {
        Code: "Update",
        OnClick: this.onUpdate,
        Disabled: selectedRowIndex === undefined,
      },
      {
        Code: "Delete",
        OnClick: this.onDelete,
        Disabled: selectedRowIndex === undefined,
      },
    ];
  }

  render() {
    const {
      isLoadingShow,
      model,
      legacyGridRefreshToken,
      legacyGridLoadAtStartup,
    } = this.state;
    const { menuId, ApprovalData, After } = this.props;

    return (
      <>
        <LoadingComponent Show={isLoadingShow}></LoadingComponent>
        <ButtonToolbar
          ButtonList={this.getToolbarButtons()}
          menuId={menuId}
          ApprovalData={ApprovalData}
          After={After}
        ></ButtonToolbar>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <GenericExpansionPanel Title="Legacy Clients">
                  <GridContainer>
                    <GridItem xs={3}>
                      <GenericTextInput
                        Name="CustomerInteractId"
                        LabelText="Customer Interac ID"
                        inputProps={MaxLength128}
                        Value={model.CustomerInteractId}
                        ValueChanged={this.onValueChange}
                        Required
                      />
                    </GridItem>
                    <GridItem xs={3}>
                      <GenericTextInput
                        Name="LegacyClientId"
                        LabelText="Legacy Client ID"
                        inputProps={MaxLength128}
                        Value={model.LegacyClientId}
                        ValueChanged={this.onValueChange}
                        Required
                      />
                    </GridItem>
                    <GridItem xs={3}>
                      <GenericTextInput
                        Name="LegacyClientName"
                        LabelText="Legacy Client Name"
                        inputProps={MaxLength128}
                        Value={model.LegacyClientName}
                        ValueChanged={this.onValueChange}
                        Required
                      />
                    </GridItem>
                  </GridContainer>
                </GenericExpansionPanel>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <GenericGrid
                  key={"trxGrid" + legacyGridRefreshToken}
                  ServerSide
                  RequestUrl="/bankapi/v1.0/ClearingSettlement/SearchLegacyClient"
                  RequestMethod="POST"
                  PrepareRequest={() => {
                    return model;
                  }}
                  ValidateRequest={this.onValidate}
                  ShowPagination={true}
                  LoadAtStartup={legacyGridLoadAtStartup}
                  PageSize={20}
                  Columns={this.legacyGridColums}
                  RowSelected={this.onRowSelect}
                  PaginationFetchDataCallback={(data) => {
                    this.setState({ datasource: data });
                  }}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }

  componentDidMount() {
    const { setAppLeftTitle, setAppCenterTitle } = this.props;

    setAppLeftTitle("Legacy Clients");
    setAppCenterTitle("CLEARING & SETTLEMENT");

    this.setState({ legacyGridLoadAtStartup: true });
  }
}

export default eTransferLegacyClients;
