import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import { SuspiciousIcon } from "core/Icons";
import MenuAuthorityRightHelper from "core/MenuAuthorityRightHelper";
import RouteHelper from "core/RouteHelper";
import PropTypes, { func } from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import ToolBar from "views/Components/DynamicToolbar";
import { GenericAlert, GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { ActionCodes, BankTransactionStatus, GridColumnType, MenuCodes, Portals, ProgramCodes } from "views/Constants/Constant";

const styles = ({
	GridItemFreePadding: {
		padding: "0px 5px !important"
	},
	GridItemTextFreePadding: {
		padding: "0px 3px !important"
	}
});

class TransactionSearch extends Component {
	constructor(props) {
		super(props);

		this.state = {
			model: {
				FromDate: DateHelper.GetDate(),
				ToDate: DateHelper.GetDate()
			},
			toolBarModel: {},
			tempModel: {},
			getDataList: [],
			isLoading: false,
			poolModel: {},
			isBackOff: false,
			isSalesPerson: false,
			suspiciousDialog: false,
			gridButtonRightDetail: []
		};

		this.MenuAuthorityRightHelper = new MenuAuthorityRightHelper();

		this.handleChange = this.handleChange.bind(this);
		this.handleChangeTempModel = this.handleChangeTempModel.bind(this);
		this.GetData = this.GetData.bind(this);
		this.errorCallback = this.errorCallback.bind(this);
		this.hideAlert = this.hideAlert.bind(this);
		this.showErrorMessage = this.showErrorMessage.bind(this);
		this.successSummaryCallback = this.successSummaryCallback.bind(this);
		this.successCallback = this.successCallback.bind(this);
	}

	componentDidMount() {
		const { setAppLeftTitle, setAppCenterTitle } = this.props;

		var isBackOff = ClientHelper.IsBackOffice();
		var isSalesPerson = ClientHelper.IsSalesPerson();
		var model = { ...this.state.model };
		if (!isBackOff && !isSalesPerson) {
			model["ClientId"] = ClientHelper.GetClientRowId();
			this.GetToolbarData();
		}

		if (this.props.MenuCode == MenuCodes.Backoffice_SalesPersonCdicTransactionSearch) {
			if (setAppLeftTitle) setAppLeftTitle("DDT - CDIC Transactions");
			if (setAppCenterTitle) setAppCenterTitle("SALES PERSON");
		} else if (this.props.MenuCode == MenuCodes.CallCenterCDICTransactions) {
			if (setAppLeftTitle) setAppLeftTitle("DDT - CDIC Transactions Search");
			if (setAppCenterTitle) setAppCenterTitle("CALL CENTER");

			this.GetGridButtonsRight();
		} else {
			if (setAppLeftTitle) setAppLeftTitle("DDT - CDIC Transactions");
			if (setAppCenterTitle) setAppCenterTitle("DDT MANAGEMENT");
		}

		this.props.setPortal(Portals.DDT);

		var data = RouteHelper.ReadAndClear("CDICTransactionSearch_Date");

		if (data) {
			model.FromDate = data.FromDate;
			model.ToDate = data.ToDate;
			model.IsSettled = data.IsSettled;
			this.setState({ isBackOff: isBackOff, model, isSalesPerson });
			this.GetData(model);
		}
		else {
			this.setState({ isBackOff: isBackOff, model, isSalesPerson });
		}
	}

	GetToolbarData = () => {
		this.setState({ isLoading: true });
		Proxy.GET(
			"/bankapi/v1.0/Ddt/GetAllDDTCDICNumberAndBalance",
			this.successCallback,
			this.errorCallback
		);
	}

	successCallback(responseData) {
		var toolBarModel = { ...this.state.toolBarModel };
		var poolModel = { ...this.state.poolModel };
		var model = { ...this.state.model };
		poolModel.FinInsId = responseData.Item == null || undefined ? "" : responseData.Item.CDICPoolAccountInfo == null || undefined ? "" : responseData.Item.CDICPoolAccountInfo.FinInsId == null || undefined ? "" : responseData.Item.CDICPoolAccountInfo.FinInsId;
		model.PrimaryBranchId = responseData.Item == null || undefined ? "" : responseData.Item.CDICPoolAccountInfo == null || undefined ? "" : responseData.Item.CDICPoolAccountInfo.BranchId == null || undefined ? "" : responseData.Item.CDICPoolAccountInfo.BranchId;
		model.PrimaryAccountNumber = responseData.Item == null || undefined ? "" : responseData.Item.CDICPoolAccountInfo == null || undefined ? "" : responseData.Item.CDICPoolAccountInfo.AccountNumber == null || undefined ? "" : responseData.Item.CDICPoolAccountInfo.AccountNumber;

		toolBarModel.TotalDDTNumber = responseData.Item == null || undefined ? 0 : responseData.Item.TotalDDTNumber == null || undefined ? 0 : responseData.Item.TotalDDTNumber;
		toolBarModel.TotalDDTBalance = responseData.Item == null || undefined ? "" : responseData.Item.DDTPoolAccountInfo == null || undefined ? "" : responseData.Item.DDTPoolAccountInfo.Balance == null || undefined ? "" : responseData.Item.DDTPoolAccountInfo.Balance;
		toolBarModel.TotalCDICNumber = responseData.Item == null || undefined ? 0 : responseData.Item.TotalCDICNumber == null || undefined ? 0 : responseData.Item.TotalCDICNumber;
		toolBarModel.TotalCDICBalance = responseData.Item == null || undefined ? "" : responseData.Item.CDICPoolAccountInfo == null || undefined ? "" : responseData.Item.CDICPoolAccountInfo.Balance == null || undefined ? "" : responseData.Item.CDICPoolAccountInfo.Balance;
		this.setState({ model, toolBarModel, poolModel, isLoading: false });
	}

	handleChange(name, newValue, data) {
		const model = { ...this.state.model };
		if (name == "SettlementStatus")
			if (data.ParameterDesc == "Yes")
				model[name] = true;
			else
				model[name] = false;
		else
			model[name] = newValue;
		this.setState({ model });
	}

	handleChangeTempModel(name, newValue, data) {
		const tempModel = { ...this.state.tempModel };
		const model = { ...this.state.model };
		if (name == "SettlementStatus") {
			if (newValue != -1) {
				if (data.ParameterDesc == "Yes") {
					tempModel[name] = newValue;
					model[name] = true;
				}
				else {
					tempModel[name] = newValue;
					model[name] = false;
				}
			} else {
				tempModel[name] = newValue;
				model[name] = false;
			}
		}
		else
			tempModel[name] = newValue;
		this.setState({ tempModel, model });
	}

	GetData(model) {
		this.setState({ isLoading: true });
		// if (this.state.model.SearchStartDate == null)
		// 	this.showErrorMessage("Date / To can not be empty !!");
		// else {
		Proxy.POST(
			"/bankapi/v1.0/Cdic/SearchTransactionCdic",
			model ? model : this.state.model,
			this.successSummaryCallback,
			this.errorCallback
		);
		// }
	}

	successSummaryCallback(responseData) {
		this.hideAlert();
		if (!responseData.IsSucceeded) {
			this.showErrorMessage(responseData.ErrorDescription);
			return;
		}
		if (responseData.Item == null || responseData.Item.length < 1) {
			this.setState({ getDataList: [], isLoading: false });
		} else {
			this.setState({
				getDataList: responseData.Item.map(x => {
					return {
						Id: x.Id,
						ClientName: x.ClientName == null ? "" : x.ClientName,
						ClientUniqueClientId: x.ClientUniqueClientId == null ? "" : x.ClientUniqueClientId,
						TransactionSource: x.TransactionSource == null ? "" : x.TransactionSource,
						CustomerNumber: x.CustomerNumber == null ? "" : x.CustomerNumber,
						TransitNumber: x.TransitNumber == null ? "" : x.TransitNumber,
						CDICNumber: x.CDICNumber == null ? "" : x.CDICNumber,
						Store: x.StoreCode == null ? "" : x.StoreCode,
						Date: x.Date == null ? "" : x.Date,
						TransactionType: x.TransactionType == null ? "" : x.TransactionType,
						SettlementStatus: x.SettlementStatus == null ? "" : x.SettlementStatus == true ? "Yes" : "No",
						Amount: x.Amount == null ? "" : x.Amount,
						CDICStatus: x.CDICStatus == null ? "" : x.CDICStatus,
						CDICStatusCode: x.CDICStatusCode == null ? "" : x.CDICStatusCode,
						FeeAmount: x.FeeAmount == null ? "" : x.FeeAmount
					};
				}), isLoading: false
			});
			this.GetToolbarData();
		}
	}

	errorCallback(error) {
		this.setState({ isLoading: false, alert: null });
		this.showErrorMessage("An error occurred during the api visit" + error);
	}

	HandleSuspicious = model => {
		this.setState({ sdTransactionId: model.Id, suspiciousDialog: true });
	}

	HandleSuspiciousConfirm = () => {
		const { sdCancelMemo, sdCancelReasonId, sdTransactionId } = this.state;

		this.ShowConfirmMessage("warning", "Workflow Confirmation", "Are you sure to send it for workflow confirmation ?", () => {

			var data = {
				TransactionId: sdTransactionId,
				CancelReasonId: sdCancelReasonId,
				CancelMemo: sdCancelMemo
			};

			var jsonData = JSON.stringify(data);

			var requestData = {
				MenuCode: MenuCodes.Cdic_SuspiciousApprove,
				ActionCode: ActionCodes.Submit,
				JsonData: jsonData,
				RowId: data.TransactionId
			};

			this.setState({ alert: "", isLoading: true });
			Proxy.POST("/coreapi/v1.0/WorkflowData/InsertWorkflowData",
				requestData,
				responseData => {
					this.setState({ isLoading: false });

					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}

					this.setState({ suspiciousDialog: false, sdCancelReasonId: "", sdCancelMemo: "" });
					this.ShowMessage("success", "Success", "Request successfully sent for approval");
				},
				errorMessage => {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "Error", "An error occurred while sending approve request " + errorMessage);
				}
			);
		});
	}

	GetGridButtonsRight = async () => {
		const { MenuCode } = this.props;
		var gridButtonRightDetail = await this.MenuAuthorityRightHelper.RightCheckList(MenuCode, ["CALCDCSUSP"]);

		this.setState({ gridButtonRightDetail });
	}

	ShowMessage = (type, title, message) => {
		this.setState({ alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.setState({ alert: "" })} /> });
	}

	ShowConfirmMessage(type, title, message, onConfirm) {
		this.setState({ alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: "" })} OnConfirm={onConfirm} /> });
	}

	showErrorMessage(message) {
		this.setState({
			isLoading: false,
			getDataList: [],
			alert: <GenericAlert Title={"Error"} MessageNode={message} Type={"warning"} OnConfirm={() => this.hideAlert()} />
		});
	}

	hideAlert() {
		this.setState({ alert: null });
	}

	handleClear = () => {
		var model = { ...this.state.model };
		var ClientId = model.ClientId == null ? null : model.ClientId;
		this.setState({
			model: {
				ClientId: ClientId,
				FromDate: DateHelper.GetDate(),
				ToDate: DateHelper.GetDate()
			}
		});
	}

	render() {
		const { Disabled, classes } = this.props;
		const { model, isLoading, toolBarModel, tempModel, poolModel, isBackOff, isSalesPerson, suspiciousDialog, sdCancelReasonId, sdCancelMemo, gridButtonRightDetail } = this.state;

		var IsCallCenter = this.props.MenuCode === MenuCodes.CallCenterCDICTransactions;

		return (
			<div>
				{this.state.alert}
				<LoadingComponent Show={isLoading} />
				{!isBackOff && !isSalesPerson &&
					<ToolBar
						IsNumber
						ItemList={[
							{ Label: "Total No. of DDT", Value: toolBarModel.TotalDDTNumber + "" },
							{ Label: "Total No. of DDT - CDIC", Value: toolBarModel.TotalCDICNumber + "" },
							{ Label: "DDT Wallet Balance", Value: toolBarModel.TotalDDTBalance },
							{ Label: "DDT - CDIC Wallet Balance", Value: toolBarModel.TotalCDICBalance }
						]} />
				}
				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.handleClear, Disabled: Disabled },
					{ Code: "Search", OnClick: this.GetData, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<Card className="no-radius">
					<GenericExpansionPanel Title="Main Parameters">
						<CardBody>
							<GridContainer>
								<GridItem xs={4}>
									<GenericSelectInput
										Name="ClientId"
										LabelText="Client"
										Method="POST"
										Url="/bankapi/v1.0/BankCustomer/Search"
										Parameter={{ UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined }}
										DataRoot="Item"
										KeyValueMember="Id"
										RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
										Value={model.ClientId || ""}
										ValueChanged={this.handleChange}
										CanClear
										All
										Disabled={!isBackOff && !isSalesPerson} />

									{!this.state.isBackOff && !isSalesPerson &&
										<GridContainer>
											<GridItem className={classes.GridItemFreePadding} xs={6}>
												<GenericSelectInput
													Name="FinInsId"
													LabelText="DDT-CDIC Main Acc."
													LabelMd={8}
													Method="GET"
													Url="/bankapi/v1.0/FinancialInstitution/GetAll"
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="FinancialInstitutionId"
													Value={poolModel.FinInsId == null || poolModel.FinInsId == "" ? 46 : poolModel.FinInsId}
													Disabled={true} />
											</GridItem>
											<GridItem xs={3}>
												<GenericSelectInput
													Name="PrimaryBranchId"
													LabelMd={0}
													TitleLabel="Branch No."
													Method="GET"
													Url="/bankapi/v1.0/FinancialInstitutionBranch/GetAllDCBankBranches"
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="TransitNo"
													Value={model.PrimaryBranchId == null ? "" : model.PrimaryBranchId}
													ValueChanged={this.handleChange}
													Disabled={!isBackOff && !isSalesPerson} />
											</GridItem>
											<GridItem className={classes.GridItemFreePadding} xs={3}>
												<GenericNumberInput
													NoFormatting={true}
													Name="PrimaryAccountNumber"
													LabelMd={0}
													Value={model.PrimaryAccountNumber == null || undefined ? "" : model.PrimaryAccountNumber}
													ValueChanged={this.handleChange}
													MaxLength={12}
													Disabled={true} />
											</GridItem>
										</GridContainer>
									}
									<GenericNumberInput
										NoFormatting={true}
										Name="CustomerNumber"
										LabelText="Customer Number"
										Value={model.CustomerNumber == null || undefined ? "" : model.CustomerNumber}
										ValueChanged={this.handleChange} />
									<GridContainer>
										<GridItem className={classes.GridItemFreePadding} xs={6}>
											<GenericSelectInput
												Name="FinInsId"
												LabelMd={8}
												LabelText="DDT - CDIC Number"
												Method="GET"
												Url="/bankapi/v1.0/FinancialInstitution/GetAll"
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="FinancialInstitutionId"
												Value={poolModel.FinInsId == null || poolModel.FinInsId == "" ? 46 : poolModel.FinInsId}
												ValueChanged={this.handleChange}
												Disabled={true} />
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												Name="BranchId"
												LabelMd={0}
												TitleLabel="Branch No."
												Method="GET"
												Url="/bankapi/v1.0/FinancialInstitutionBranch/GetAllDCBankBranches"
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="TransitNo"
												Value={model.BranchId == null || undefined ? "" : model.BranchId}
												ValueChanged={this.handleChange} />
										</GridItem>
										<GridItem className={classes.GridItemFreePadding} xs={3}>
											<GenericNumberInput
												NoFormatting={true}
												Name="AccountNumber"
												LabelMd={0}
												Value={model.AccountNumber == null || undefined ? "" : model.AccountNumber}
												ValueChanged={this.handleChange}
												MaxLength={12} />
										</GridItem>
									</GridContainer>

									<GenericSelectInput
										Name="AccountStatusId"
										LabelText="DDT - CDIC Status"
										Value={model.AccountStatusId == null || undefined ? "" : model.AccountStatusId}
										DataRoot="Item"
										ValueChanged={this.handleChange}
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Method="POST"
										All
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={{
											ParameterCode: "AccountStatus",
											"ParameterValue": "",
											ParameterValue3: "CDIC",
											"ParameterDesc": ""
										}} />
									<GenericSelectInput
										Name="CustomerBusinessTypeId"
										LabelText="AccountType"
										Value={model.CustomerBusinessTypeId}
										DataRoot="Item"
										All
										ValueChanged={this.handleChange}
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={{
											ParameterCode: "CustomerBusinessType",
											"ParameterValue": "",
											"ParameterValue3": "",
											"ParameterDesc": ""
										}} />

								</GridItem>
								<GridItem xs={4}>
									<GenericNumberInput
										ThousandSeparator=","
										Name="Amount"
										LabelText="Amount"
										Value={model.Amount == null || undefined ? "" : model.Amount}
										ValueChanged={this.handleChange} />
									<GenericSelectInput
										Name="TransactionSourceId"
										LabelText="Source"
										Value={model.TransactionSourceId == null || undefined ? "" : model.TransactionSourceId}
										DataRoot="Item"
										ValueChanged={this.handleChange}
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={{
											ParameterCode: "TransactionSource",
											"ParameterValue": "",
											"ParameterValue3": "",
											"ParameterDesc": ""
										}} />
									<GenericNumberInput
										NoFormatting={true}
										Name="StoreCode"
										LabelText="Store"
										Value={model.StoreCode == null || undefined ? "" : model.StoreCode}
										ValueChanged={this.handleChange} />

									<GenericSelectInput
										Name="SettlementStatus"
										All
										LabelText="Settlement Status"
										Value={tempModel.SettlementStatus == null || undefined ? "" : tempModel.SettlementStatus}
										DataRoot="Item"
										ValueChanged={this.handleChangeTempModel}
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={{
											ParameterCode: "YesNo",
											"ParameterValue": "",
											"ParameterValue3": "",
											"ParameterDesc": ""
										}} />
								</GridItem>
								<GridItem xs={4}>
									<GenericDateInput
										Name="FromDate"
										LabelText="From"
										Value={model == null || undefined ? "" : model.FromDate ? Formatter.FormatDateUtc(model.FromDate) : ""}
										ValueChanged={this.handleChange}
										IncludeTime={false}
										MaxDate={model.ToDate == null ? DateHelper.GetDate() : model.ToDate}
										Utc />
									<GenericDateInput
										Name="ToDate"
										LabelText="To"
										Value={model == null || undefined ? "" : model.ToDate ? Formatter.FormatDateUtc(model.ToDate) : ""}
										ValueChanged={this.handleChange}
										IncludeTime={false}
										MaxDate={DateHelper.GetDate()}
										MinDate={model.FromDate}
										Utc />

								</GridItem>
							</GridContainer><br />
						</CardBody>
					</GenericExpansionPanel>
				</Card>
				<Card className="no-radius">
					<CardHeader>
						<GenericTitle text={"DDT - CDIC Transactions"} />
					</CardHeader>
					<CardBody>
						<GenericGrid
							Data={this.state.getDataList}
							Columns={[
								{
									Header: "Actions",
									accessor: "SUS",
									sortable: false,
									filterable: false,
									width: 50,
									show: IsCallCenter,
									Cell: row => (
										<div>
											<GridButton
												Disabled={Disabled || row.original.CDICStatusCode == BankTransactionStatus.Cancelled ||
													!gridButtonRightDetail.find(x => x.Code == "CALCDCSUSP")?.HasAuthority}
												Icon={SuspiciousIcon}
												OnClick={() => { this.HandleSuspicious(row.original); }} />
										</div>
									)
								},
								{
									Header: "Transaction Date",
									accessor: "Date",
									type: GridColumnType.DateTimeUtc
								},
								{
									Header: "Transaction Source",
									accessor: "TransactionSource"
								},
								{
									Header: "Transaction Type",
									accessor: "TransactionType"
								},
								{
									Header: "Unique Client Id",
									accessor: "ClientUniqueClientId"
								},
								{
									Header: "Customer Number",
									accessor: "CustomerNumber"
								},
								{
									Header: "Customer Name",
									accessor: "ClientName"
								},
								{
									Header: "Amount",
									accessor: "Amount",
									type: GridColumnType.Money,
									ColumnType: GridColumnType.Money
								},
								{
									Header: "Fee Amount",
									accessor: "FeeAmount",
									type: GridColumnType.Money,
									ColumnType: GridColumnType.Money
								},
								{
									Header: "Transit Number",
									accessor: "TransitNumber"
								},
								{
									Header: "DDT - CDIC Number",
									accessor: "CDICNumber"
								},
								{
									Header: "Store",
									accessor: "Store"
								},
								{
									Header: "DDT - CDIC Status",
									accessor: "CDICStatus"
								}
								// ,
								// {
								// 	Header: "Settlement Status",
								// 	accessor: "SettlementStatus"
								// }
							]}
							ProgramCode={ProgramCodes.Bank_Programs_CDIC} />
						<br /> <br />
					</CardBody>
				</Card>
				<br />
				<GenericDialog open={suspiciousDialog} maxWidth="md" fullWidth>
					<DialogTitle>
						<GenericLabel Text="Cancel Suspicious Transaction" Bold={true} />
					</DialogTitle>
					<DialogContent>
						<ParameterComponent Name="sdCancelReasonId" LabelText="Cancel Reason" Value={sdCancelReasonId} ValueChanged={(n, v) => this.setState({ sdCancelReasonId: v })} ParameterCode={"TransactionCancelReason"} />
						<GenericTextInput Name="sdCancelMemo" LabelText="Cancel Memo" Value={sdCancelMemo} ValueChanged={(n, v) => this.setState({ sdCancelMemo: v })} />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.HandleSuspiciousConfirm}>SAVE</Button>
						<Button size="sm" onClick={() => this.setState({ suspiciousDialog: false, sdCancelMemo: "", sdCancelReasonId: "", sdTransactionId: null })}>CANCEL</Button>
					</GenericDialogActions>
				</GenericDialog>
			</div>
		);
	}
}

TransactionSearch.propTypes = {
	classes: PropTypes.object,
	handleOperationType: PropTypes.func,
	SelectedRowChange: PropTypes.func,
	showQuestionMessage: func,
	setAppLeftTitle: func,
	setAppCenterTitle: func,
	setPortal: func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.number,
	ApprovalData: PropTypes.array,
	After: PropTypes.func
};

export default withArtifex(TransactionSearch, styles);