import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import { DialogContent, DialogTitle } from "@material-ui/core";

import React from "react";
import {
  GenericAlert,
  GenericDialog,
  GenericDialogActions,
  GenericLabel,
  GenericNumberInput,
  GenericSelectInput,
  GenericTextInput,
} from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { TaskPool } from "core/TaskPool";
import { Proxy, withArtifex } from "core";
import RouteHelper from "core/RouteHelper";
import { EmbossType } from "views/Constants/Constant";

class PaymentAdminCardAction extends React.Component {
  constructor(props) {
    super(props);
    this.loadTaskPool = new TaskPool(
      this.handleLoadTaskPoolAppend,
      this.handleLoadTaskPoolCompleted
    );
    this.initialModel = {
      AccountOwnerName: "",
      AccountId: 0,
      AccountNumber: 0,
      DebtInquiry: 0,
		EmbossType: 0,
		InstantEmbossBranchId: 0,
    };

    this.state = {
      CancelProductModel: {
        NewCardRequestId:
          this.props.ApplicationModel.Applicant.NewCardRequestId,
        CancelReasonId: this.props.ApplicationModel.Applicant.CancelReasonId,
        NewCardCreationTimeId:
          this.props.ApplicationModel.Applicant.NewCardCreationTimeId,
      },
      UniqueClientIdForOffice:
        this.props.ApplicationModel.Applicant.UniqueClientIdForOffice,
      IsCardCanceled: false,
      openDepositDialog: false,
      openWithDrawalDialog: false,
      dialogModel: { ...this.initialModel },
      vModel: {},
      modalModel: {
        AccountNumber: 0,
        Description: "",
      },
    };

    this.ShowQuestionMessage = this.ShowQuestionMessage.bind(this);
    this.HandleChange = this.HandleChange.bind(this);
    this.CancelCard = this.CancelCard.bind(this);
    this.CloseBalance = this.CloseBalance.bind(this);
    this.CloseAccount = this.CloseAccount.bind(this);
    this.SwapCard = this.SwapCard.bind(this);
  }
  componentDidMount() {}

  handleLoadTaskPoolCompleted = () => {
    this.setState({ isLoading: false });
  };

  handleLoadTaskPoolAppend = () => {
    this.setState({ isLoading: true });
  };

  HideAlert = () => {
    this.setState({ alert: null });
  };

  ShowQuestionMessage = (message, title, action) => {
    this.setState({
      alert: (
        <GenericAlert
          Title={title}
          Message={message}
          Type="warning"
          ShowCancel={true}
          OnCancel={() => this.HideAlert()}
          OnConfirm={action}
        />
      ),
    });
    this.setState({ isLoading: false });
  };

  ShowMessage = (type, title, message) => {
    this.setState({
      alert: (
        <GenericAlert
          Title={title}
          Message={message}
          Type={type}
          OnConfirm={() => this.setState({ alert: "" })}
        />
      ),
    });
  };
  CancelCard() {
    this.setState({ alert: null });
    const { ApplicationModel } = this.props;
    const { CancelProductModel } = this.state;
    if (ApplicationModel.Id == null) {
      this.ShowMessage("warning", "Warning", "Application must be selected.");
      return;
    }
    if (!CancelProductModel.NewCardRequestId) {
      this.ShowMessage(
        "warning",
        "Warning",
        "New card request must be selected."
      );
      return;
    }

    this.loadTaskPool.AppendTask(
      Proxy.POST(
        "/lmsapi/v1.0/PaymentAdmin/CancelCard",
        {
          ApplicationId: ApplicationModel.Id,
          CancelReasonId: CancelProductModel.CancelReasonId,
          NewCardRequestId: CancelProductModel.NewCardRequestId,
          NewCardCreationTimeId: CancelProductModel.NewCardCreationTimeId,
        },
        (responseData) => {
          console.log("CancelCard ->", responseData.IsSucceeded);

          if (!responseData.IsSucceeded) {
            this.ShowMessage("error", "Error", responseData.ErrorDescription);
            return;
          }
          this.ShowMessage("success", "Success", "Card Canceled");
          this.setState({ IsCardCanceled: true }, () =>
            this.props.HandleChange("Id", this.props.ApplicationModel?.Id)
          );
        },
        (error) => {
          this.ShowMessage("error", "Error", error);
        }
      )
    );
  }
  DisableCard() {
    this.setState({ alert: null });
    const { ApplicationModel } = this.props;
    const { CancelProductModel } = this.state;
    if (ApplicationModel.Id == null) {
      this.ShowMessage("warning", "Warning", "Application must be selected.");
      return;
    }

    this.loadTaskPool.AppendTask(
      Proxy.POST(
        "/lmsapi/v1.0/PaymentAdmin/DisableCard",
        {
          ApplicationId: ApplicationModel.Id,
          CancelReasonId: CancelProductModel.CancelReasonId,
          NewCardRequestId: CancelProductModel.NewCardRequestId,
          NewCardCreationTimeId: CancelProductModel.NewCardCreationTimeId,
        },
        (responseData) => {
          console.log("DisableCard ->", responseData.IsSucceeded);

          if (!responseData.IsSucceeded) {
            this.ShowMessage("error", "Error", responseData.ErrorDescription);
            return;
          }
          this.ShowMessage("success", "Success", "Card Disabled");
          this.setState({ IsCardDisabled: true });
          this.setState({ CancelProductModel });
        },
        (error) => {
          this.ShowMessage("error", "Error", error.ErrorDescription);
        }
      )
    );
  }
  EnableCard() {
    this.setState({ alert: null });
    const { ApplicationModel } = this.props;
    if (ApplicationModel.Id == null) {
      this.ShowMessage("warning", "Warning", "Application must be selected.");
      return;
    }
    this.loadTaskPool.AppendTask(
      Proxy.POST(
        "/lmsapi/v1.0/PaymentAdmin/EnableCard",
        {
          ApplicationId: ApplicationModel.Id,
        },
        (responseData) => {
          console.log("EnableCard ->", responseData.IsSucceeded);

          if (!responseData.IsSucceeded) {
            this.ShowMessage("error", "Error", responseData.ErrorDescription);
            return;
          }
          this.ShowMessage("success", "Success", "Card Enabled");
          this.setState({ IsCardDisabled: false });
          this.setState();
        },
        (error) => {
          this.ShowMessage("error", "Error", error);
        }
      )
    );
  }

  openCustomDialogForCloseBalance = () => {
    this.HideAlert();
    var dialogModel = this.state;
    var modalModel = { ...this.state.modalModel };
    this.setState({
      isLoading: true,
    });
    this.DebtInquiryDialogOpen();
    //  modalModel.AccountNumber = cardModalModel.AccountNumber;
    this.setState({
      isLoading: false,
      IsBalanceClosed: true,
      openDepositDialog: true,
      dialogModel,
      modalModel,
    });
  };

  handleClose = () => {
    this.setState({
      openDepositDialog: false,
      openWithDrawalDialog: false,
      ddtModel: {},
      depositModel: {},
      withDrawalModel: {},
    });
  };

  HandleChange = (name, newValue, data) => {
    const { dialogModel } = this.state;
    this.setState({ dialogModel });
    const CancelProductModel = this.state.CancelProductModel;

    if (name == "CancelReasonId") {
      CancelProductModel.CancelReasonId = newValue;
    }
    if (name == "NewCardRequestId") {
      CancelProductModel.NewCardRequestId = newValue;
      CancelProductModel.NewCardRequest = data;
    }
    if (name == "NewCardCreationTimeId") {
      CancelProductModel.NewCardCreationTimeId = newValue;
    }

    dialogModel[name] = newValue;
	 dialogModel.EmbossType != EmbossType.InstantId ?  dialogModel.InstantEmbossBranchId = 0 : dialogModel.InstantEmbossBranchId = dialogModel.InstantEmbossBranchId; 

    this.setState({ CancelProductModel, dialogModel });
  };

  DebtInquiryDialogOpen = async () => {
    var dialogModel = this.state.dialogModel;
    var { ExecuteApiPost, ApplicationModel } = this.props;
    var result = await ExecuteApiPost(
      "/lmsapi/v1.0/PaymentAdmin/DebtInquiryGet",
      { ApplicationId: ApplicationModel.Id }
    );

	 if (result != null) {
      dialogModel.DebtInquiry = result[0].DebtInquiry;
    }
    this.setState({
      isLoading: false,
      dialogModel,
    });
  };

  //   ActivateAccount = async () => {
  //     var { ApplicationModel } = this.props;

  // 	 this.loadTaskPool.AppendTask(
  //       Proxy.POST(
  // 			"/lmsapi/v1.0/PaymentAdmin/ActivateAccount",
  // 		  { ApplicationId: ApplicationModel.Id },
  //         (responseData) => {
  //           console.log("Activate Wallet ->", responseData.IsSucceeded);

  //           if (!responseData.IsSucceeded) {
  //             this.ShowMessage("error", "Error", responseData.ErrorDescription);
  //             return;
  //           }
  //           this.ShowMessage("success", "Success", "Wallet Activated");
  //         },
  //         (error) => {
  //           this.ShowMessage("error", "Error", error);
  //         }
  //       )
  //     );

  //     this.setState({
  //       isLoading: false,
  //     });
  //   };

  CloseBalance() {
    this.setState({ alert: null });
    const { ApplicationModel } = this.props;
    const { CancelProductModel, dialogModel } = this.state;

    if (!CancelProductModel.NewCardRequestId) {
      this.ShowMessage(
        "warning",
        "Warning",
        "New card request must be selected."
      );
      return;
    }

    this.loadTaskPool.AppendTask(
      Proxy.POST(
        "/lmsapi/v1.0/PaymentAdmin/CloseCardBalance",
        {
          ApplicationId: ApplicationModel.Id,
          NewCardRequestId: CancelProductModel.NewCardRequestId,
          NewCardCreationTimeId: CancelProductModel.NewCardCreationTimeId,
          ApplicationCardId: ApplicationModel.ApplicationCardId,
          AccountNumber: dialogModel.AccountNumber,
          AccountId: dialogModel.AccountId,
          AccountOwnerName: dialogModel.AccountOwnerName,
          UniqueClientId: ApplicationModel.UniqueClientId,
        },
        (responseData) => {
          console.log("Close balance ->", responseData.IsSucceeded);

          if (!responseData.IsSucceeded) {
            this.ShowMessage("error", "Error", responseData.ErrorDescription);
            return;
          }
          this.ShowMessage("success", "Success", "Balance Closed");
          this.setState({ IsBalanceClosed: true });
        },
        (error) => {
          this.ShowMessage("error", "Error", error);
        }
      )
    );
  }
  CloseAccount() {
    this.setState({ alert: null });
    const { ApplicationModel } = this.props;
    const { IsBalanceClosed } = this.state;

    if (!IsBalanceClosed) {
      this.ShowMessage("warning", "Warning", "You must be close balance.");
      return;
    }

    this.loadTaskPool.AppendTask(
      Proxy.POST(
        "/lmsapi/v1.0/PaymentAdmin/CloseCardAccount",
        {
          ApplicationId: ApplicationModel.Id,
          ApplicationCardId: ApplicationModel.ApplicationCardId,
        },
        (responseData) => {
          console.log("Close Wallet ->", responseData.IsSucceeded);

          if (!responseData.IsSucceeded) {
            this.ShowMessage("error", "Error", responseData.ErrorDescription);
            return;
          }
          this.ShowMessage("success", "Success", "Wallet Closed");
          this.setState({ IsAccountClosed: true });
        },
        (error) => {
          this.ShowMessage("error", "Error", error);
        }
      )
    );
  }
  SwapCard() {
    this.setState({ alert: null });
    const { ApplicationModel } = this.props;
    const { CancelProductModel } = this.state;
    const dModel = {...this.state.dialogModel};
    if (ApplicationModel.Id == null) {
      this.ShowMessage("warning", "Warning", "Application must be selected.");
      return;
    }
    if (!CancelProductModel.NewCardRequestId) {
      this.ShowMessage(
        "warning",
        "Warning",
        "New card request must be selected."
      );
      return;
    }

    this.loadTaskPool.AppendTask(
      Proxy.POST(
        "/lmsapi/v1.0/PaymentAdmin/SwapCard",
        {
          ApplicationId: ApplicationModel.Id,
          CancelReasonId: CancelProductModel.CancelReasonId,
          CardEmbossName: ApplicationModel.CardEmbossName,
          NewCardRequestId: CancelProductModel.NewCardRequestId,
			 EmbossType: dModel.EmbossType,
			 InstantEmbossBranchId: dModel.InstantEmbossBranchId,
        },
        (responseData) => {
          console.log("SwapCard ->", responseData.IsSucceeded);

          if (!responseData.IsSucceeded) {
            this.ShowMessage("error", "Error", responseData.ErrorDescription);
            return;
          }
          this.ShowMessage("success", "Success", "Card Swapped");
          this.props.ApplicationModel.Applicant.CardNumber =
            responseData.Item?.CardNumber;
          this.props.ApplicationModel.Applicant.CATMCardId =
            responseData.Item?.CATMCardId;
          this.props.ApplicationModel.Applicant.IsCardActive = false;
          this.setState({ IsCardCanceled: true });
        },
        (error) => {
          this.ShowMessage("error", "Error", error);
        }
      )
    );
  }

  RenderItemUniqueClient = (d) => {
    this.renderItem = `${d.AccountOwnerName} - ${d.AccountNumber}`;
    const { dialogModel } = this.state;
    dialogModel["AccountNumber"] = d.AccountNumber;
    dialogModel["AccountOwnerName"] = d.AccountOwnerName;
    dialogModel["AccountId"] = d.AccountId;

    return this.renderItem;
  };

  OpenETransferPage = () => {
    const { ApplicationModel } = this.props;

    if (
      !(
        ApplicationModel &&
        ApplicationModel.Applicant &&
        ApplicationModel.Applicant.SecurityAccount &&
        ApplicationModel.Applicant.SecurityAccount.Id != null
      )
    ) {
      this.ShowMessage(
        "warning",
        "Warning",
        "Security Wallet cannot be empty."
      );

      return;
    }

    RouteHelper.Push(
      this.props.history,
      "/LmsETransfer",
      "ETransferLos_AccountId",
      ApplicationModel.Applicant.SecurityAccount.Id
    );
  };

  render() {
    const { ApplicationModel } = this.props;
    const { CancelProductModel, dialogModel } = this.state;

    var isCardCanceled =
      this.state.IsCardCanceled == true ||
      ApplicationModel.Id == null ||
      ApplicationModel.Id == 0 ||
      ApplicationModel.Applicant.IsCardCanceled == true;
    var isCardDisabled =
      this.state.IsCardDisabled == true ||
      ApplicationModel.Id == null ||
      ApplicationModel.Id == 0 ||
      ApplicationModel.Applicant.IsCardDisabled == true;
    var isCardAccountClosed =
      this.state.IsAccountClosed == true ||
      ApplicationModel.Id == null ||
      ApplicationModel.Id == 0 ||
      ApplicationModel.Applicant.CardAccountClosedDate != null;
    var isCardBalanceClosed =
      this.state.IsBalanceClosed == true ||
      ApplicationModel.Id == null ||
      ApplicationModel.Id == 0 ||
      ApplicationModel.Applicant.CardBalanceClosedDate != null;
    const newCardRequest = CancelProductModel?.NewCardRequest;

    return (
      <GridContainer spacing={40}>
        {this.state.alert}
        <LoadingComponent Show={this.state.isLoading} />
        <GenericDialog fullWidth={true} open={this.state.openDepositDialog}>
          <DialogTitle>
            <GenericLabel
              Text="Close Card Balance"
              FontSize="20px"
              Bold={true}
            />
          </DialogTitle>
          <DialogContent>
            <GridItem xs={12}>
              <GenericSelectInput
                Name="AccountOwnerName"
                LabelText="Wallet Number"
                LabelMd={3}
                SelectMd={3}
                Method="POST"
                Url="/lmsapi/v1.0/PaymentAdmin/CloseCardBalanceDetails"
                Parameter={{
                  ApplicationId: ApplicationModel.Id,
                  UniqueClientId: ApplicationModel.UniqueClientId,
                }}
                DataRoot="Item"
                KeyValueMember="AccountOwnerName"
                TextValueMember="AccountOwnerName"
                RenderItem={this.RenderItemUniqueClient}
                Value={dialogModel.AccountOwnerName}
                ValueChanged={this.HandleChange}
                Disabled={false}
              />
            </GridItem>
            <GridItem xs={12}>
              <GenericNumberInput
                Disabled
                AllowNegative
                Name="DebtInquiry"
                LabelText="Debt Inquiry"
                Value={dialogModel.DebtInquiry}
              />
            </GridItem>
          </DialogContent>
          <GenericDialogActions>
            <Button size="sm" onClick={this.handleClose}>
              Close
            </Button>
            <Button size="sm" onClick={() => this.CloseBalance()}>
              Confirm
            </Button>
          </GenericDialogActions>
        </GenericDialog>
        <GridItem xs={12}>
          {isCardDisabled == true ? (
            <GridItem xs={2}>
              <Button
                size="sm"
                color="black"
                fullWidth
                onClick={() =>
                  this.ShowQuestionMessage(
                    "Are you sure you want to enable the customer’s card?",
                    "Enable Card",
                    () => this.EnableCard()
                  )
                }
              >
                Enable Card
              </Button>
            </GridItem>
          ) : (
            <GridItem xs={2}>
              <Button
                size="sm"
                color="black"
                fullWidth
                //(CancelProductModel != undefined && newCardRequest != undefined && newCardRequest.ParameterValue == 0)}
                onClick={() =>
                  this.ShowQuestionMessage(
                    "Are you sure you want to disable the customer’s card?",
                    "Disable Card",
                    () => this.DisableCard()
                  )
                }
              >
                Disable Card
              </Button>
            </GridItem>
          )}
        </GridItem>
        <GridItem xs={12}>
          <GridContainer>
            <GridItem xs={3}>
              <GenericSelectInput
                LabelMd={5}
                Name="NewCardRequestId"
                LabelText="New Card Request"
                Method="POST"
                Url="/coreapi/v1.0/Parameter/Search"
                Parameter={{ ParameterCode: "LOSYesNo" }}
                DataRoot="Item"
                KeyValueMember="Id"
                TextValueMember="ParameterDesc"
                Disabled={false}
                Value={
                  CancelProductModel != undefined &&
                  CancelProductModel.NewCardRequestId > 0
                    ? CancelProductModel.NewCardRequestId
                    : ApplicationModel.Applicant.NewCardRequestId != undefined
                    ? ApplicationModel.Applicant.NewCardRequestId
                    : ""
                }
                ValueChanged={this.HandleChange}
                CanClear={true}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem md={3}>
              <GenericTextInput
                Name="CardEmbossName"
                LabelText="Emboss Name"
                Disabled={false}
                LabelMd={5}
                Value={
                  ApplicationModel.CardEmbossName ||
                  ApplicationModel.Applicant.FullName
                }
                ValueChanged={this.props.HandleChange}
              />
            </GridItem>
          </GridContainer>
			 <GridContainer>
			 	<GridItem md={3}>
               <GenericSelectInput
                 Name="EmbossType"
                 LabelMd={5}
                 LabelText="Emboss Type"
                 Method="POST"
                 Url="/coreapi/v1.0/Parameter/Search"
                 Parameter={{ ParameterCode: "EmbossType" }}
                 DataRoot="Item"
                 KeyValueMember="Id"
                 TextValueMember="ParameterDesc"
                 ValueChanged={this.HandleChange}
                 Value={dialogModel.EmbossType}
                 Disabled={false}
               />
            </GridItem>
			  </GridContainer>
			  <GridContainer>
               <GridItem md={3}>
                  <GenericSelectInput
                    Name="InstantEmbossBranchId"
                    LabelText="Card Perso Office"
                    LabelMd={5}
                    Method="POST"
                    Url="/prepaidapi/v1.0/Card/GetInstantCardBranchList"
                    DataRoot="Item"
                    KeyValueMember="Id"
                    TextValueMember="Description"
                    ValueChanged={this.HandleChange}
                    Value={dialogModel.InstantEmbossBranchId}
                    Disabled={dialogModel.EmbossType !== EmbossType.InstantId}
                  />
                </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={2}>
              <Button
                size="sm"
                color="black"
                fullWidth
                disabled={false}
                onClick={() =>
                  this.ShowQuestionMessage(
                    "Are you sure you want to swap the customer’s card?",
                    "Swap Card",
                    () => this.SwapCard()
                  )
                }
              >
                New Card
              </Button>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={3}>
              <GenericSelectInput
                LabelMd={5}
                Name="CancelReasonId"
                LabelText="Cancel Reason"
                Method="POST"
                Url="/coreapi/v1.0/Parameter/Search"
                Parameter={{ ParameterCode: "LMSCancelCardReason" }}
                DataRoot="Item"
                KeyValueMember="Id"
                TextValueMember="ParameterDesc"
                Value={
                  CancelProductModel != undefined &&
                  CancelProductModel.CancelReasonId > 0
                    ? CancelProductModel.CancelReasonId
                    : ApplicationModel.Applicant.CancelReasonId != undefined
                    ? ApplicationModel.Applicant.CancelReasonId
                    : ""
                }
                Disabled={false}
                ValueChanged={this.HandleChange}
              />
            </GridItem>
            {/* <GridItem xs={2}>
              <Button
                size="sm"
                color="black"
                fullWidth
                disabled={false}
                onClick={() =>
                  this.ShowQuestionMessage(
                    "Are you sure you want to activate the customer’s account?",
                    "Activate Wallet",
                    () => this.ActivateAccount()
                  )
                }
              >
                Activate Account
              </Button>
            </GridItem> */}
          </GridContainer>
          <GridContainer>
            <GridItem xs={2}>
              <Button
                size="sm"
                color="black"
                fullWidth
                disabled={false}
                onClick={() =>
                  this.ShowQuestionMessage(
                    "Are you sure you want to cancel the customer’s card?",
                    "Cancel Card",
                    () => this.CancelCard()
                  )
                }
              >
                Cancel Card
              </Button>
            </GridItem>
            <GridItem xs={2}>
              <Button
                size="sm"
                color="black"
                fullWidth
                disabled={false}
                onClick={() =>
                  this.ShowQuestionMessage(
                    "Are you sure you want to close the customer’s balance?",
                    "Close Balance",
                    () => this.openCustomDialogForCloseBalance()
                  )
                }
              >
                Close Balance
              </Button>
            </GridItem>
            <GridItem xs={2}>
              <Button
                size="sm"
                color="black"
                fullWidth
                disabled={false}
                onClick={() =>
                  this.ShowQuestionMessage(
                    "Are you sure you want to close the customer’s accounts?",
                    "Close Wallet",
                    () => this.CloseAccount()
                  )
                }
              >
                Close Account
              </Button>
            </GridItem>
            <GridItem xs={2}>
              <Button
                size="sm"
                color="black"
                fullWidth
                disabled={false}
                onClick={this.OpenETransferPage}
              >
                e-Transfer
              </Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  }
}

PaymentAdminCardAction.propTypes = {
  classes: PropTypes.object,
  ApplicationModel: PropTypes.object,
};

export default withArtifex(PaymentAdminCardAction, {});