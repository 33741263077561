import { withStyles } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericExpansionPanel, GenericGrid, GenericSelectInput } from "views/Components/Generic";
import { Proxy } from "core";
import LoadingComponent from "views/Components/LoadingComponent";
import externalContainerStyle from "assets/jss/material-dashboard-pro-react/layouts/externalContainerStyle.jsx";
import { GridColumnType, ProgramCodes } from "views/Constants/Constant";
import { ParameterEmptyObject } from "views/Constants/Constant.Parameter";
import { SortedName } from "views/Constants/Constant.Sorted";
import CustomerComponent from "views/Components/CustomerComponent.jsx";

class CardListReport extends React.Component {

	constructor(props) {
		super(props);

		this.columns = [
			{
				Header: "Card Id",
				accessor: "CardId"
			},
			{
				Header: "Card  Financial Id",
				accessor: "CardFinancialId"
			},
			{
				Header: "Card Token Number",
				accessor: "CardTokenNumber"
			},
			{
				Header: "Client Name",
				accessor: "ClientName"
			},
			{
				Header: "Emboss Name",
				accessor: "EmbossName"
			},
			{
				Header: "Wallet Number",
				accessor: "WalletAccountNumber"
			},
			{
				Header: "Card Status",
				accessor: "CardStatus"
			},
			{
				Header: "Has Card Limit",
				accessor: "HasCardLimit",
				type: GridColumnType.YesNo,
				ColumnType: GridColumnType.YesNo,
			},
			{
				Header: "Card Usage Amount",
				accessor: "CardUsageAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Customer Usage Amount",
				accessor: "CustomerUsageAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Expire Date",
				accessor: "ExpireDate",
				type: GridColumnType.DateTime,
				ColumnType: GridColumnType.DateTime
			},
			{
				Header: "Limit Start Date",
				accessor: "LimitStartDate",
				type: GridColumnType.DateTime,
				ColumnType: GridColumnType.DateTime
			},
			{
				Header: "Limit End Date",
				accessor: "LimitEndDate",
				type: GridColumnType.DateTime,
				ColumnType: GridColumnType.DateTime
			},
			{
				Header: "Limit",
				accessor: "Limit"
			},
			{
				Header: "Is Main Card",
				accessor: "IsMainCard",
				type: GridColumnType.YesNo,
				ColumnType: GridColumnType.YesNo,
			},
			{
				Header: "Is Last Card",
				accessor: "IsLastCard",
				type: GridColumnType.YesNo,
				ColumnType: GridColumnType.YesNo,
			},
			{
				Header: "Insert Date Time",
				accessor: "InsertDateTime",
				type: GridColumnType.DateTime,
				ColumnType: GridColumnType.DateTime
			},
		];
		this.getappCenterTitle = "Empty";
		this.getappLeftTitle = "Empty";
		this.zIndex10 = { zIndex: 10 };

		this.state = {
			model: {},
			data: [],
			recordKeeping: [],
			isSendEmailDialogOpen: false,
			activeClient: [],
			responseDetail: {},
			isDetailDialogOpen: false
		};
		this.cpverTable = React.createRef();
	}

	componentDidMount = () => {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;
		if (setAppCenterTitle) {
			setAppCenterTitle("Card List Report");
			this.getappCenterTitle = "Card List Report";
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("Card List Report");
			this.getappLeftTitle = "Card List Report";
		}
	}

	Clear = () => {
		this.setState({ model: {}, data: [], selected: null });
	}


	GetRenderItemClient = (x) => {
		var render = `${x.Name} - ${x.UniqueClientId}`;
		return render;
	}

	showLoading = () => { this.setState({ isLoading: true }); }
	hideLoading = () => { this.setState({ isLoading: false }); }

	showDetail = async (result) => {
		this.setState({ responseDetail: result || [], isDetailDialogOpen: true });
		console.log(result);
	}

	HandleSearch = () => {
		const model = { ...this.state.model };
		if (model.UniqueClientId == 0 || model.UniqueClientId == null) {
			this.props.showMessage("error", "Error", "Please Select Client!");
			return;
		}
		this.setState({ isLoading: true });

		Proxy.POST(
			"/prepaidapi/v1.0/Card/CardListReport",
			model,
			(responseData) => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					console.log(responseData.ErrorDescription)
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ data: responseData.Item });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	handleChange = (name, newValue) => {
		const model = { ...this.state.model };
		if (name === "Customer") {
			if (newValue) {
				model.ClientCustomerId = newValue.Id;
				model.CustomerNumber = newValue.CustomerNumber;
			} else {
				delete model.ClientCustomerId;
				delete model.CustomerNumber;
			}
		}
		model[name] = newValue;
		console.log('ass', model)
		this.setState({ model });
	};

	FillCustomer = (customer) => {
		this.handleChange("Customer", customer);
	};


	render() {
		const { model, selected, isLoading, data } = this.state;
		const { Disabled } = this.props;

		return (
			<div>
				<LoadingComponent Show={isLoading} />
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.HandleSearch
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />

				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Filter">
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												Required={model.ReviewTypeIsPeriodic}
												IsInvalid={model.IsUniqueClientId}
												Name="UniqueClientId"
												LabelText="Client"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Method="POST"
												Parameter={ParameterEmptyObject}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={this.GetRenderItemClient}
												Sorted={SortedName}
												Value={model.UniqueClientId}
												ValueChanged={this.handleChange}
												Required
											/>
										</GridItem>
										<GridItem xs={4}>
											<CustomerComponent
												key={model.UniqueClientId}
												LabelMd={4}
												CustomerNumber={model.CustomerNumber}
												HandleChange={this.handleChange}
												FillCallback={this.FillCustomer}
												Programs={[ProgramCodes.Prepaid]}
												UniqueClientId={model.UniqueClientId}
												Disabled={!model.UniqueClientId}
											// IsInvalid={model.ClientCustomerId}
											/>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={data}
													Columns={this.columns}
													SelectedIndex={selected}
													IsSorted={true}
													PageSize={10}
													ShowPagination={true}
													ref={this.cpverTable} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

CardListReport.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	ExecuteApiFileDownloadWithGenericResponse: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default withStyles(externalContainerStyle)(CardListReport);