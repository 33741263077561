import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import  PropTypes  from "prop-types";
import React from "react";
import { GenericGrid, GenericLabel, GenericNumberInput, GenericTextInput } from "views/Components/Generic";

class GridColumns extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		const { classes, jsonModel, model, Disabled } = this.props;
		return (
			<div>

				<GridContainer>
					<GridItem xs={1}>
						<Button
							color='black'
							size="sm"
							onClick={this.props.handleAdd}
							fullWidth
							disabled={!model || !model.Title || !model.ColumnName}>
							Add
						</Button>
					</GridItem>
					<GridItem xs={1}>
						<Button
							color='black'
							size="sm"
							onClick={this.props.handleDelete}
							fullWidth
							disabled={!model || !model.Title || !model.ColumnName}>
							Delete
						</Button>
					</GridItem>
				</GridContainer>
				<GridContainer>
					<GridItem xs={3}>
						<GenericTextInput
							Name="Title"
							LabelText="Title"
							Value={model.Title}
							ValueChanged={this.props.handleChange} />
					</GridItem>
					<GridItem xs={3}>
						<GenericTextInput
							Name="ColumnName"
							LabelText="Column Name"
							Value={model.ColumnName}
							ValueChanged={this.props.handleChange} />
					</GridItem>
					<GridItem xs={3}>
						<GenericNumberInput
							Name="Width"
							LabelText="Width"
							Value={model.Width}
							ValueChanged={this.props.handleChange}
							MaxLength={3}
							DecimalScale={0}
							ThousandSeparator="" />
					</GridItem>
					<GridItem xs={12}>
						{jsonModel.GridColumns &&
							<GenericLabel Bold={true} FontSize="12px" TextColor="black" Text="Grid Column List"></GenericLabel>
						}
						{jsonModel.GridColumns &&
							<GenericGrid
								key={jsonModel.GridColumns.length}
								Data={jsonModel.GridColumns}
								Columns={[
									{
										Header: "Title",
										accessor: "Title"
									},
									{
										Header: "Column Name",
										accessor: "ColumnName"
									},
									{
										Header: "Width",
										accessor: "Width"
									}
								]}
								RowSelected={index => {
									var data = jsonModel.GridColumns[index];
									this.props.handleSelectGridColumn(data);
								}}
							/>
						}
					</GridItem>
				</GridContainer>

			</div >
		);
	}
}

GridColumns.propTypes = {
	classes: PropTypes.object.isRequired,
	model: PropTypes.object,
	handleChange: PropTypes.func,
	handleAdd: PropTypes.func,
	handleDelete: PropTypes.func,
	handleSelectGridColumn: PropTypes.func
};

export default withArtifex(GridColumns, {});
