import { DialogContent, DialogTitle } from "@material-ui/core";
import ChequeManagementStyle from "assets/jss/material-dashboard-pro-react/views/ChequeManagementStyle.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import { DetailIcon } from "core/Icons";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericSelectInput, GenericTitle } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import TabsComponent from "views/Components/TabsComponentPagination";
import { Direction, GridColumnType } from "views/Constants/Constant";
import ChequeImageDetail from "./ChequeImageDetail";

const styles = ({
	...sweetAlertStyle
});
class ChequeIncomingFile extends Component {
	constructor(props) {
		super(props);

		this.state = {

			model: {
				DirectionParameterValue: Direction.Incoming,
			},
			list: [],
			dailyFileList: [],
			FileId: 0,
			dailyFileDetailList: [],
			selected: null,
			isBackOffice: false,
			openDialog: false,
			totalchqinfomodel: {},
			totalchqdetailinfomodel: {},
			ParentTabIndex: 0,
			CheckNewClient: false,
			alert: "",
			chqinfomodel: {},
			totalvisible: false,
			Titlerecord: ""
		}
	}
	componentDidMount() {
		// var tempuniq = "1" + Formatter.PadLeft(ClientHelper.GetClientRowId(), 3, "0");
		if (this.props.setAppLeftTitle) this.props.setAppLeftTitle("Cheque Incoming File");
		if (this.props.setAppCenterTitle) {
			this.props.setAppCenterTitle("CHEQUE MANAGEMENT");
		}
		this.setState({ openDialog: false });
		var { isBackOffice } = this.state;
		isBackOffice = ClientHelper.IsBackOffice();
		var model = { ...this.state.model };
		model.DirectionParameterValue = Direction.Incoming;
		this.setState({ isBackOffice, model });
	}
	handleChangeParentTab = (event, active, tab) => {
		var { FileId, totalvisible } = { ...this.state };
		if (active == 0) {
			FileId = 0;
			this.setState({ FileId, totalvisible: false });
			if (!tab || !this.state.CheckNewClient) {
				this.setState({ ParentTabIndex: active, openDialog: false });
			}
		}
		if (FileId != 0) {
			this.setState({ FileId, totalvisible: true });
			if (!tab || !this.state.CheckNewClient) {
				this.setState({ ParentTabIndex: active });
				Proxy.POST(
					"/chequeapi/v1.0/Cheque/GetCHQIncomingDailyDetailFileList",
					{
						ClientId: this.state.model.UniqueClientId,
						FileId: this.state.FileId
					},
					this.successDailyDetailFileList,
					this.errorCallback
				);
			}
		}
		else {
			if (FileId == 0 && active == 0) {
			}
			else {
				// this.showErrorMessage("Please File Select!");
				this.ShowMessage("warning", "Please Select File!", "Select a file to continue.");
			}
		}
	}

	handleChange = (name, value, data) => {
		const model = { ...this.state.model };
		model[name] = value;
		if (name === "Name") model["UniqueClientId"] = value;
		if (name === "UniqueClientId") model["Name"] = value;
		this.setState({ model });
	}

	Search = () => {
		this.setState({ isLoading: true });
		var { FileId } = { ...this.state };
		if (FileId == 0) {
			Proxy.POST(
				"/chequeapi/v1.0/Cheque/GetCHQIncomingDailyFileList",
				this.state.model,
				this.successDailyFileList,
				this.errorCallback
			);
		}
		else {
			Proxy.POST(
				"/chequeapi/v1.0/Cheque/GetCHQIncomingDailyDetailFileList",
				{
					ClientId: this.state.model.UniqueClientId,
					FileId: this.state.FileId
				},
				this.successDailyDetailFileList,
				this.errorCallback
			);
		}
	}
	successDailyDetailFileList = (responseData) => {
		if (!responseData.IsSucceeded) {
			this.showErrorMessage(responseData.ErrorDescription);
			return;
		}
		this.setState({
			dailyFileDetailList: responseData.Item.GetCHQIncomingDailyDetailFileList == null ? [] : responseData.Item.GetCHQIncomingDailyDetailFileList.length < 1 ? [] : responseData.Item.GetCHQIncomingDailyDetailFileList.map(x => {
				return {
					CHQ: (
						<div>
							<GridButton
								Icon={DetailIcon}
								OnClick={() => { this.OpenEditModal(x); }} />
						</div>
					),
					Id: x.FileInformationId == null || undefined ? "" : x.FileInformationId,
					FileName: x.FileName == null || undefined ? "" : x.FileName,
					Date: x.FileDate == null || undefined ? "" : x.FileDate,
					UniqueClientId: x.UniqueClientId == null || undefined ? "" : x.UniqueClientId,
					ClientId: x.ClientId == null || undefined ? 0 : x.ClientId,
					FileSource: x.FileSource == null || undefined ? "" : x.FileSource,
					SendStatus: x.SendStatus == null || undefined ? "" : x.SendStatus,
					RecordType: x.RecordType == null || undefined ? "" : x.RecordType,
					RecordCount: x.RecordCount == null || undefined ? "" : x.RecordCount,
					Amount: x.Amount == null || undefined ? "" : x.Amount,
					ChequeStatus: x.ChequeStatus == null || undefined ? "" : x.ChequeStatus,
					InstitutionNumber: x.InstitutionNumber == null || undefined ? "" : x.InstitutionNumber,
					TraceNumber: x.TraceNumber == null || undefined ? "" : x.TraceNumber,
					MICRAccountNumber: x.MICRAccountNumber == null || undefined ? "" : x.MICRAccountNumber,
					OriginDataCentre: x.OriginDataCentre == null || undefined ? "" : x.OriginDataCentre,
					TransactionCode: x.TransactionCode == null || undefined ? "" : x.TransactionCode,
					TransitNumber: x.TransitNumber == null || undefined ? "" : x.TransitNumber,

				};
			}), totalchqdetailinfomodel: responseData.Item.GetCHQIncomingDetailDailyFileCalculation == null ? {} : responseData.Item.GetCHQIncomingDetailDailyFileCalculation,

			isLoading: false
		});
	}
	OpenEditModal = (item) => {
		this.setState({ isLoading: false });
		var TypeName = "";
		var itemId = 0;
		var Titlerecord = "";
		if (item.FileChequeDetail != null) {

			TypeName = "CH";
			itemId = item.FileChequeDetail.Id
			Titlerecord = "Cheque Image Information"
		} else {
			TypeName = "CR";
			itemId = item.FileCredit.Id
			Titlerecord = "Credit Image Information"
		}
		Proxy.POST(
			"/chequeapi/v1.0/Cheque/GetChequeDetailList",
			{
				"RecordId": itemId,
				"TypeName": TypeName
			},
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				var chqinfomodel = responseData.Item.GetChequeDetail;

				this.setState({ isLoading: false, openDialog: true, chqinfomodel, Titlerecord: Titlerecord });
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while requesting data", error.message);
			}
		);
	}
	successDailyFileList = (responseData) => {
		if (!responseData.IsSucceeded) {
			this.showErrorMessage(responseData.ErrorDescription);
			return;
		}
		this.setState({
			dailyFileList: responseData.Item == null ? [] : responseData.Item.length < 1 ? [] : responseData.Item.GetCHQIncomingDailyFileList.map(x => {
				return {
					Id: x.FileInformationId == null || undefined ? "" : x.FileInformationId,
					FileId: x.FileInformationId == null || undefined ? "" : x.FileInformationId,
					FileName: x.FileName == null || undefined ? "" : x.FileName,
					FileDate: x.FileDate == null || undefined ? "" : x.FileDate,
					FileSource: x.FileSource == null || undefined ? "" : x.FileSource,
					FileCreationNumber: x.FileCreationNumber == null || undefined ? "" : x.FileCreationNumber,
					OriginDataCentre: x.OriginDataCentre == null || undefined ? "" : x.OriginDataCentre,
					SendStatus: x.SendStatus == null || undefined ? "" : x.SendStatus,
					CreditDebitTotalAmount: x.CreditDebitTotalAmount == null || undefined ? 0 : x.CreditDebitTotalAmount,
					CreditDebitTotalCount: x.CreditDebitTotalCount == null || undefined ? 0 : x.CreditDebitTotalCount,
					TotalAmount: x.TotalAmount == null || undefined ? 0 : x.TotalAmount,
					TotalCount: x.TotalCount == null || undefined ? 0 : x.TotalCount,
				};
			}), isLoading: false
		});

		this.setState({ totalchqinfomodel: responseData.Item.GetCHQIncomingDailyFileCalculation });

	}
	errorCallback = (error) => {
		this.showErrorMessage("An error occurred during the api visit" + error);
	}
	showErrorMessage = (message) => {
		this.setState({
			isLoading: false,
			alert: <GenericAlert Title={"Error"} Message={message} Type="Error" ShowCancel={false} OnConfirm={() => this.hideAlert()} />
		});
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert:
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })}
				/>
		});
	}
	hideAlert = () => {
		this.setState({ alert: null });
	}

	Clear = () => {
		this.setState({
			model: {
				DirectionParameterValue: Direction.Incoming,
			}, dailyFileList: [], totalchqinfomodel: {}, FileId: 0, totalchqdetailinfomodel: {}
		});
	}
	handleClose = () => {
		this.setState({
			openDialog: false
		});
	}

	render() {
		const { classes } = this.props;
		const { model, dailyFileList, selected, FileId, dailyFileDetailList, isBackOffice, totalchqinfomodel, totalvisible, totalchqdetailinfomodel, Titlerecord } = this.state;
		return (
			<div>
				<GenericDialog open={this.state.openDialog} maxWidth="md" fullWidth onBackdropClick={this.handleClose} >
					<DialogTitle><GenericLabel Text={Titlerecord} FontSize="20px" Bold={true} /></DialogTitle>
					<DialogContent >
						{<ChequeImageDetail
							chqinfomodel={this.state.chqinfomodel}
						/>}
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={() => this.setState({ openDialog: false })}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>
				{this.state.alert}
				<ButtonToolbar ButtonList={[
					{
						Code: "Search",
						OnClick: this.Search
					},

					{
						Code: "Clear",
						OnClick: this.Clear,

					},

					{
						Code: "Upload",
						OnClick: this.Clear,

					},

					{
						Code: "View",
						OnClick: this.Clear,
					}
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<Card className={classes.zerMarPad}>
					<GenericExpansionPanel Title={"Main Parameters"}>
						<CardBody>
							<GridContainer>
								<GridItem xs={6}>
									<GridContainer spacing={16} >
										<GridItem xs={4}>
											<GenericSelectInput
												Name="Name"
												LabelText="Client Name"
												Value={model.Name || ""}
												DataRoot="Item"
												ValueChanged={this.handleChange}
												KeyValueMember="Id"
												TextValueMember="Name"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Method="POST"
												Parameter={{ UniqueClientId: 0 }}
												Disabled={!isBackOffice}
											/>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Unique Client ID"
												Value={model.UniqueClientId || ""}
												DataRoot="Item"
												ValueChanged={this.handleChange}
												KeyValueMember="Id"
												TextValueMember="UniqueClientId"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Method="POST"
												Parameter={{ UniqueClientId: 0 }}
												Disabled={!isBackOffice}
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericDateInput
												Name="FromDate"
												LabelText="From Date"
												Value={model == null || undefined ? null : model.FromDate == null || undefined ? "" : model.FromDate}
												ValueChanged={this.handleChange}
												MaxDate={model.ToDate == null ? DateHelper.GetDate() : model.ToDate}
											/>
											<GenericDateInput
												Name="ToDate"
												LabelText="To Date"
												Value={model == null || undefined ? null : model.ToDate == null || undefined ? "" : model.ToDate}
												ValueChanged={this.handleChange}
												MaxDate={DateHelper.GetDate()}
												MinDate={model.FromDate}
											/>
										</GridItem>
									</GridContainer>
								</GridItem>
							</GridContainer>
						</CardBody>
					</GenericExpansionPanel>
				</Card>
				<Card className="no-radius">
					<CardBody>
						<CardHeader>
							<GenericTitle text={"Cheque Clearing Details"} />
						</CardHeader>
						<GridContainer>
							<GridItem xs={12}>
								<TabsComponent
									TabIndex={this.state.ParentTabIndex}
									handleChange={(e, a) => this.handleChangeParentTab(e, a, true)}
									tabList={[
										{
											tabButton: "Files",
											tabContent: (
												<GridItem>
													<GenericGrid
														Data={dailyFileList}
														SelectedIndex={selected}
														Columns={[
															{
																Header: "Date",
																accessor: "FileDate",
																type: GridColumnType.Date
															},
															{
																Header: "File Name",
																accessor: "FileName",

															},

															{
																Header: "Origin Data Centre",
																accessor: "OriginDataCentre"
															},
															{
																Header: "File Creation Number",
																accessor: "FileCreationNumber"
															},
															{
																Header: "Batch Total",
																accessor: "TotalAmount"
															},
															{
																Header: "Total",
																accessor: "TotalCount"
															}

														]}
														RowSelected={index => {

															this.setState({ FileId: dailyFileList[index].FileId, selected: index });
														}} />
												</GridItem>
											)
										},
										{
											tabButton: "File Details",
											tabContent: (
												<GridItem>
													<GenericGrid
														Data={dailyFileDetailList}
														SelectedIndex={selected}
														Columns={[
															{
																Header: "Unique Client Id",
																accessor: "UniqueClientId"
															},
															{
																Header: "Record Type",
																accessor: "RecordType"
															},
															{
																Header: "Record Count",
																accessor: "RecordCount"
															}
															,
															{
																Header: "Amount",
																accessor: "Amount"
															},

															{
																Header: "Transaction Code",
																accessor: "TransactionCode"
															},
															{
																Header: "MICR Wallet Number",
																accessor: "MICRAccountNumber"
															},
															{
																Header: "Institution Number",
																accessor: "InstitutionNumber"
															},
															{
																Header: "Transit Number",
																accessor: "TransitNumber"
															},

															{
																Header: "Origin Data Centre",
																accessor: "OriginDataCentre"
															}, {
																Header: "Trace Number",
																accessor: "TraceNumber"
															}

														]}
														RowSelected={index => {

															this.setState({ model: dailyFileDetailList[index], selected: index });
														}} />
												</GridItem>
											)
										}


									]}
								/>
							</GridItem>
						</GridContainer>
						{totalvisible == false ?
							<GridContainer justify="flex-end">
								<GridItem xs={8} />
								<GridItem xs={8}>
									<GridContainer className={classes.containerHeight} justify="flex-end" >
										<GridItem xs={2} className={classes.gridBorder}>
										</GridItem>
										<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
											<p className={classes.pt}>Cheque Amount</p>
										</GridItem>
										<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
											<p className={classes.pt}>Cheque Count</p>
										</GridItem>
									</GridContainer>
									<GridContainer justify="flex-end" >
										<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold} >
											TOTAL
											</GridItem>
										<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter} >

											{totalchqinfomodel == null || undefined ? 0 : totalchqinfomodel.ChequeTotalAmount == null || undefined ? 0 : typeof totalchqinfomodel.ChequeTotalAmount == "number" ? Formatter.FormatMoney(totalchqinfomodel.ChequeTotalAmount) : totalchqinfomodel.ChequeTotalAmount}
										</GridItem>
										<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter} >

											{totalchqinfomodel == null || undefined ? 0 : totalchqinfomodel.ChequeTotalCount == null || undefined ? 0 : typeof totalchqinfomodel.ChequeTotalCount == "number" ? Formatter.FormatNumber(totalchqinfomodel.ChequeTotalCount) : totalchqinfomodel.ChequeTotalCount}
										</GridItem>

									</GridContainer>
								</GridItem>
							</GridContainer>
							:
							<GridContainer >
								<GridItem xs={6} />
								<GridItem xs={6}>
									<GridContainer className={classes.containerHeight} justify="flex-end">
										<GridItem xs={2} className={classes.gridBorder}>
										</GridItem>
										<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
											<p className={classes.pt}>Deposit Amount</p>
										</GridItem>
										<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
											<p className={classes.pt}>Deposit Count</p>
										</GridItem>

									</GridContainer>
									<GridContainer justify="flex-end">
										<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold}>
											TOTAL
											</GridItem>

										<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>

											{totalchqdetailinfomodel == null || undefined ? 0 : totalchqdetailinfomodel.TotalDepositAmount == null || undefined ? 0 : typeof totalchqdetailinfomodel.TotalDepositAmount == "number" ? Formatter.FormatMoney(totalchqdetailinfomodel.TotalDepositAmount) : totalchqdetailinfomodel.TotalDepositAmount}
										</GridItem>

										<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>

											{totalchqdetailinfomodel == null || undefined ? 0 : totalchqdetailinfomodel.TotalDepositCount == null || undefined ? 0 : typeof totalchqdetailinfomodel.TotalDepositCount == "number" ? Formatter.FormatNumber(totalchqdetailinfomodel.TotalDepositCount) : totalchqdetailinfomodel.TotalDepositCount}
										</GridItem>
									</GridContainer>
								</GridItem>
							</GridContainer>
						}
					</CardBody>
				</Card>
			</div>
		);
	}
}
ChequeIncomingFile.propTypes =
	{
		setAppLeftTitle: PropTypes.func,
		setAppCenterTitle: PropTypes.func,
		Disabled: PropTypes.bool,
		menuId: PropTypes.any,
		ApprovalData: PropTypes.any,
		After: PropTypes.any

	};
export default withArtifex(ChequeIncomingFile, ChequeManagementStyle);
