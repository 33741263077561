import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericLabel } from "views/Components/Generic";
import GenericIcon from "views/Components/Generic/GenericIcon.jsx";


class DownloadButton extends Component {
	render() {
		const { name, title, onClick } = this.props;

		return (
			<GridContainer>
				<GridItem md={10} xs={12}>
					<Card style={{ height: 135 }}>
						<CardBody>
							<GridContainer
								direction="column"
								alignItems="center">
								<GridItem>
									<Button
										color="white"
										onClick={() => onClick(name)}>
										<GenericIcon style={{ fontSize: 45, paddingBottom: 5, color: "#457CF7" }} >fa-download</GenericIcon>
									</Button>
								</GridItem>
								<GridItem>
									<GenericLabel
										Text={title}
										Bold={true}
										TextAlign="center" />
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

DownloadButton.propTypes = {
	name: PropTypes.number,
	title: PropTypes.string,
	onClick: PropTypes.func
};

export default DownloadButton;