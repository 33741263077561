import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericCheckInput, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType } from "views/Constants/Constant";

class EmailSignature extends React.Component {
	constructor(props) {
		super(props);

		this.isClient = ClientHelper.IsClient();
		this.isActive = false;

		this.initialModel = {
			UniqueClientId: this.isClient ? ClientHelper.GetClientRowId() : 0,
			IsBackoffice: !this.isClient
		};

		this.state = {
			list: [],
			model: { ...this.initialModel },
			vModel: {},
			isLoading: false,
			selected: -1
		};
		// preserve the initial state in a new object
		this.baseState = this.state;

		this.parameterUniqueClientId = {
			UniqueClientId: this.isClient ? ClientHelper.GetClientId() : 0
		};

		this.ColumnsData = [
			{
				Header: "Bank Message",
				accessor: "IsBackoffice",
				Cell: row => (
					Formatter.FormatYesNo(row.value)
				),
				width: 250,
				filterMethod: (filter, row, column) => {
					return Formatter.FormatYesNo(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase());
				}
			},
			{
				Header: "Client",
				accessor: "ClientName",
				show: !this.isClient
			},
			{
				Header: "Signature",
				accessor: "SignatureName"
			},
			{
				Header: "Date",
				accessor: "InsertDateTime",
				type: GridColumnType.DateTime,
				filterMethod: (filter, row, column) => {
					return Formatter.FormatDateTime(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase());
				}
			}
		];
	}

	componentDidMount() {
		this.props.setAppLeftTitle("E-Mail Signature");
		this.props.setAppCenterTitle("NOTIFICATIONS");
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		if (name === "IsBackoffice" || newValue === true)
			model.UniqueClientId = 0;
		this.setState({ model });
	}

	HandleClear = () => {
		this.setState(function (state) {
			state = this.baseState;
			return state;
		});
		this.isActive = false;
	}

	HandleSearch = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/coreapi/v1.0/EmailSignature/Search",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ list: responseData.Item });
				}
			},
			this.ErrorListCallback
		);
	}

	HandleSubmit = () => {
		if (!this.Validate())
			return;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/coreapi/v1.0/EmailSignature/InsertOrUpdate",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessage("success", "Success", "Operation is successfully!");
				this.HandleClear();
				this.HandleSearch();
			},
			this.ErrorListCallback
		);
	}

	ErrorListCallback = (error) => {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", error);
	}

	Validate = () => {
		let { model, vModel } = this.state;

		var messages = [];

		if (!model.IsBackoffice && model.UniqueClientId == 0) {
			messages.push("Client must be selected!");
			vModel.UniqueClientId = true;
		} else {
			vModel.UniqueClientId = false;
		}

		if (model.Content == "" || model.Content == null) {
			messages.push("Content must be filled!");
			this.isActive = true;
			vModel.Content = true;
		} else {
			vModel.Content = false;
		}

		if (messages.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.setState({ vModel });
		return true;
	}

	GetContent = () => {
		const { model } = this.state;
		return { __html: model.Content };
	}

	SelectedRowChange = (index) => {
		const model = this.state.list[index];
		model.IsBackoffice = model.UniqueClientId == 0;
		this.isActive = true;
		this.setState({ model, selected: index });
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}

	render() {
		const { Disabled } = this.props;
		const { alert, isLoading, model, vModel, list, selected } = this.state;

		return (
			<>

				<LoadingComponent Show={isLoading} />

				{alert}

				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled || model == this.initialModel },
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
					{ Code: "Submit", OnClick: this.HandleSubmit, Disabled: Disabled || model.Id > 0, ModelFunction: () => model, FillDataFromModel: model => this.setState({ model }), ValidationFunction: this.Validate },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<GridContainer spacing={16}>

					<GridItem xs={12}>
						<Card >
							<CardBody>
								<GenericExpansionPanel Title="Filter Panel">
									<GridContainer>
										{!this.isClient &&
											<GridItem xs={3} style={{ marginTop: 6 }}>
												<GenericCheckInput
													Name="IsBackoffice"
													LabelText="Bank Message"
													Value={model.IsBackoffice}
													ValueChanged={this.HandleChange} />
											</GridItem>
										}
										<GridItem xs={3}>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Client"
												LabelMd={4}
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={this.parameterUniqueClientId}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={this.RenderItemUniqueClient}
												Value={model.UniqueClientId}
												ValueChanged={this.HandleChange}
												All={!this.isClient}
												CanClear={!this.isClient}
												Disabled={this.isClient || model.IsBackoffice}
												DefaultIndex={this.isClient ? 0 : -1}
												Required={true}
												IsInvalid={vModel.UniqueClientId} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
								<GenericExpansionPanel IsActive={this.isActive} Title="E-Mail Signature">
									<GridContainer>
										<GridItem xs={6}>
											<GenericTextInput
												Name="Content"
												LabelMd={0}
												Value={model.Content}
												ValueChanged={this.HandleChange}
												MultiLine={true}
												RowCount={20}
												Required={true}
												IsInvalid={vModel.Content} />
										</GridItem>
										<GridItem xs={6}>
											<div style={{ marginTop: 6, border: "1px solid #000", height: "97.5%", padding: 10, overflow: "auto" }}
												dangerouslySetInnerHTML={this.GetContent()} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>

					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="E-Mail Signature List">
									<GridContainer>
										<GridItem xs={12}>
											<GenericGrid
												Data={list}
												Columns={this.ColumnsData}
												RowSelected={this.SelectedRowChange}
												SelectedIndex={selected}
												ShowPagination={true} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</>
		);
	}
}

EmailSignature.propTypes = {
	classes: PropTypes.object
};

export default withArtifex(EmailSignature, {});