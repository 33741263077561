import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";

function CustomInput({ ...props }) {
	const {
		classes,
		formControlProps,
		labelText,
		id,
		labelProps,
		inputProps,
		error,
		white,
		inputRootCustomClasses,
		success,
		helpText,
		LabelMd,
		floatRight,
		LabelStyle,
		InputStyle,
		onInput,
		Required,
		IsInvalid
	} = props;

	const { maxLength } = inputProps;

	const labelClasses = classNames({
		[" " + classes.labelRootError]: error,
		[" " + classes.labelRootSuccess]: success && !error
	});
	const underlineClasses = classNames({
		[classes.underlineError]: error,
		[classes.underlineSuccess]: success && !error,
		[classes.underline]: true,
		[classes.whiteUnderline]: white
	});
	const marginTop = classNames({
		[inputRootCustomClasses]: inputRootCustomClasses !== undefined
	});
	const inputClasses = classNames({
		[classes.input]: true,
		[classes.whiteInput]: white
	});
	var formControlClasses;
	if (formControlProps !== undefined) {
		formControlClasses = classNames(
			formControlProps.className,
			classes.formControl
		);
	} else {
		formControlClasses = classes.formControl;
	}
	var helpTextClasses = classNames({
		[classes.labelRootError]: error,
		[classes.labelRootSuccess]: success && !error
	});

	var borderColor = "#e9eaec";
	if (IsInvalid) {
		borderColor = window.InvalidFieldColor;
	}
	else if (Required) {
		borderColor = window.RequiredFieldColor;
	}

	return (
		<FormControl {...formControlProps} className={formControlClasses}>
			<GridContainer
				direction="row" justify="flex-start"
				alignItems="flex-end" style={{ paddingRight: 2, paddingTop: 2, paddingBottom: 2, marginTop: 4 }}>
				{labelText != undefined && labelText != "" &&
					<GridItem
						style={floatRight ? { height: 25, background: "rgb(246,246,246)", textAlign: "right", ...LabelStyle } : LabelStyle}
						xs={LabelMd == -1 ? 0 : (LabelMd == undefined ? 4 : LabelMd)}
					>
						{labelText !== undefined ? (
							<InputLabel
								className={classes.labelRoot + " " + labelClasses}
								htmlFor={id}
								{...labelProps}
							>
								{labelText}
							</InputLabel>
						) : null}
					</GridItem>
				}

				<GridItem xs={LabelMd == -1 ? 0 : (LabelMd == undefined ? 8 : 12 - LabelMd)}>
					<Input
						style={{
							width: "100%",
							padding: "unset",
							border: `1px ${borderColor} solid`,
							...InputStyle
						}}
						classes={{
							input: inputClasses,
							root: marginTop,
							disabled: classes.disabled,
							underline: underlineClasses,
						}}
						autoComplete="off"
						id={id}
						onInput={onInput}
						inputProps={{
							maxLength: maxLength
						}}
						{...inputProps}
					/>

					{helpText !== undefined ? (
						<FormHelperText id={id + "-text"} className={helpTextClasses}>
							{helpText}
						</FormHelperText>
					) : null}
				</GridItem>
			</GridContainer>
		</FormControl >
	);
}

CustomInput.propTypes = {
	classes: PropTypes.object.isRequired,
	labelText: PropTypes.node,
	labelProps: PropTypes.object,
	id: PropTypes.string,
	inputProps: PropTypes.object,
	formControlProps: PropTypes.object,
	inputRootCustomClasses: PropTypes.string,
	error: PropTypes.bool,
	success: PropTypes.bool,
	white: PropTypes.bool,
	helpText: PropTypes.node,
	LabelMd: PropTypes.number,
	FloatRight: PropTypes.bool,
	LabelStyle: PropTypes.object,
	InputStyle: PropTypes.object,
	Required: PropTypes.bool,
	IsInvalid: PropTypes.bool
};

export default withStyles(customInputStyle)(CustomInput);
