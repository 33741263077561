import { withArtifex } from "core";
import React, { Component } from "react";
import EftOrETransferAddNewCustomerComponent from "../Components/EftOrETransferAddNewCustomerComponent.jsx";
import AccountLimitToolbar from "views/Components/AccountLimitToolbar.jsx";
import { ProgramCodes } from "views/Constants/Constant.js";
import { Proxy } from "core";
import CustomerDefinition from "views/Customer/CustomerDefinition.jsx";
class AddNewCustomereTransfer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			LimitToken: false
		};
	}

	componentDidMount() {
		this.RefreshToolBar();
	}

	RefreshToolBar = () => {
		this.setState({ LimitToken: !this.state.LimitToken });
	}
	render() {
		const { LimitToken } = this.state;
		return (
			<div>
				<CustomerDefinition
					hideSidebar={this.props.hideSidebar}
					setAppLeftTitle={this.props.setAppLeftTitle}
					setAppCenterTitle={this.props.setAppCenterTitle}
					CustomerSaved={this.props.CustomerSaved}
					ForInsert={this.props.ForInsert}
					Programs={[ProgramCodes.Bank_Programs_e_Transfer]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After}
					Disabled={this.props.Disabled} />
			</div>
		);
	}
}

export default withArtifex(AddNewCustomereTransfer, {});