import { RoleGroup, RoleLevel } from "views/Constants/Constant";

function IsBackOffice() {
	var user = GetUser();
	return user != null && user.Role != null && user.Role.RoleGroup != null && user.Role.RoleGroup.ParameterValue == RoleGroup.Backoffice;
}

function IsClient() {
	var user = GetUser();
	return user != null && user.Role != null && user.Role.RoleGroup != null && user.Role.RoleGroup.ParameterValue == RoleGroup.Client;
}
function IsSalesPerson() {
	var user = GetUser();
	return user != null && user.Role != null && user.Role.RoleLevel != null && user.Role.RoleLevel.ParameterValue == RoleLevel.SalesPerson;
}

/**
 * @returns {number}
 */
function GetClientId() {
	var user = GetUser();
	if (user != null && user.UniqueClient != null) {
		return user.UniqueClient.UniqueClientId;
	} else {
		return null;
	}
}

/**
 * @returns {number}
 */
function GetClientRowId() {
	var user = GetUser();
	if (user != null && user.UniqueClient != null) {
		return user.UniqueClientId;
	} else {
		return null;
	}
}

/**
 * @returns {number}
 */
function GetStoreId() {
	var user = GetUser();
	if (user != null && user.UniqueClient != null) {
		return user.CustomerStoreId;
	} else {
		return null;
	}
}

function IsStore() {
	var user = GetUser();
	if (user != null && user.UniqueClient != null) {
		return user.CustomerStoreId != null;
	} else {
		return false;
	}
}

function GetUser() {
	var userJson = localStorage.getItem("user");
	return userJson ? JSON.parse(userJson) : null;
}

const ClientHelper = {
	IsBackOffice,
	IsClient,
	GetClientId,
	GetClientRowId,
	GetStoreId,
	IsStore,
	GetUser,
	IsSalesPerson
};

export default ClientHelper;