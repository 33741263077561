import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import { GenericDateInput, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import { GridColumnType, UserStatus } from "views/Constants/Constant";
import LoadingComponent from "views/Components/LoadingComponent";

class UserFundsActivities extends React.Component {
	constructor(props) {
		super(props);

		this.columns = [
			{
				Header: "Transaction Date",
				accessor: "TransactionDate",
				type: GridColumnType.Date
			},
			{
				Header: "Customer Number",
				accessor: "CustomerNumber"
			},
			{
				Header: "Customer Name",
				accessor: "CustomerName"
			},
			{
				Header: "Source Wallet Number",
				accessor: "SourceAccount.AccountNumber"
			},
			{
				Header: "Source Wallet Name",
				accessor: "SourceOwnerName"
			},
			{
				Header: "Destination Wallet Number",
				accessor: "DestinationAccount.AccountNumber"
			},
			{
				Header: "Destination Wallet Owner Name",
				accessor: "DestinationOwnerName"
			},
			{
				Header: "Transaction Type",
				accessor: "TransactionType.ParameterDesc"
			},
			{
				Header: "Transaction Code",
				accessor: "TransactionCode.TxnName"
			},
			{
				Header: "Amount",
				accessor: "TransactionAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Reference Number",
				accessor: "ReferenceNumber"
			},
			{
				Header: "Description",
				accessor: "Description"
			},
			{
				Header: "User Name",
				accessor: "UserEmail"
			}

		];

		this.state = {
			model: {
				FromDate: DateHelper.GetLocalDateAsUtc(),
				ToDate: DateHelper.GetLocalDateAsUtc()
			}
		};

		this.clientId = ClientHelper.GetClientRowId();
		this.userParameter = { UniqueClientId: this.clientId, UserStatusList: [UserStatus.Ok] };
		this.userSort = { Member: "UserName" };
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("LOAN MANAGEMENT SYSTEM");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("User Funds Activities");
		}



	}

	ValueChanged = (name, newValue) => {
		this.setState(state => {
			var model = state.model;
			model[name] = newValue;

			if (name === "FromDate" && (newValue instanceof moment) && newValue > model.ToDate) {
				var endDate = newValue.clone();
				model["ToDate"] = endDate.add(33, "days");
			}
			if (name === "FromDate") {
				if ((newValue instanceof moment) && Math.abs(model.ToDate.diff(newValue, "days")) > 33) {
					var endDate = newValue.clone();
					model["ToDate"] = endDate.add(33, "days");
				}
			}

			if (name == "ReferenceNumber") {
				model.isUniqueDataSearch = newValue?.length > 0;
			}
			return { model };
		});
	}

	Search = async () => {
		var { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/bankapi/v1.0/Transaction/GetAllUserFundsActivitiesTransaction", model);

		model.IsCode = false;
		model.IsDescription = false;
		model.IsOrder = false;

		this.setState({ model, list: result || [] });
	}

	Clear = () => {
		this.setState({
			model: {
				FromDate: DateHelper.GetLocalDateAsUtc(),
				ToDate: DateHelper.GetLocalDateAsUtc()
			}, list: [], selected: null
		});
	}

	render() {
		const { model, list } = this.state;
		const { Disabled } = this.props;
		var endDate = (model.FromDate instanceof moment) ? model.FromDate.clone() : "";

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="User Funds Activities">
									<GridContainer>

										<GridItem xs={4}>
											<GenericSelectInput
												key={model.ClientUserId}
												LabelMd={5}
												Name="ClientCustomerId"
												LabelText="Customer Name"
												Method="POST"
												Url="/losapi/v1.0/Applicant/GetApplicantCustomerList"
												DataRoot="Item"
												Parameter={{ ClientUserId: model.ClientUserId }}
												KeyValueMember="CustomerId"
												TextValueMember="FullName"
												Value={model.ClientCustomerId}
												ValueChanged={this.ValueChanged}
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericSelectInput
												Name="TransactionTypeId"
												LabelText="Transaction Type"
												Value={model === undefined ? "" : model.TransactionTypeId || ""}
												DataRoot="Item"
												ValueChanged={this.ValueChanged}
												KeyValueMember="Id"
												TextValueMember="ParameterDesc"
												Method="GET"
												Url="/bankapi/v1.0/Transaction/GetAllFinancialTransactionType"
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericSelectInput
												LabelMd={3}
												Name="ClientUserId"
												LabelText="User Name"
												Method="POST"
												Url="/coreapi/v1.0/User/Search"
												DataRoot="Item"
												Parameter={this.userParameter}
												KeyValueMember="Id"
												TextValueMember="UserName"
												Sorted={this.userSort}
												Value={model.ClientUserId}
												ValueChanged={this.ValueChanged}
											/>
										</GridItem>
										<GridItem xs={4}>

											<GenericDateInput
												Name="FromDate"
												LabelText="From Date"
												LabelMd={5}
												IsFuture={false}
												MaxDate={DateHelper.GetDateLocal()}
												Value={model.FromDate == null ? "" : DateHelper.ToDateInputValue(model.FromDate) || ""}
												ValueChanged={this.ValueChanged}
												Utc
												Disabled={model.isUniqueDataSearch} />
										</GridItem>
										<GridItem xs={4}>

											<GenericDateInput
												Name="ToDate"
												LabelText="To Date"
												LabelMd={4}
												IsFuture={false}
												MinDate={model.FromDate}
												MaxDate={endDate != "" ? endDate.add(33, 'days') : ""}
												Value={model.ToDate == null ? "" : DateHelper.ToDateInputValue(model.ToDate) || ""}
												ValueChanged={this.ValueChanged}
												Utc
												Disabled={model.isUniqueDataSearch} />
										</GridItem>
										<GridItem xs={4}>
											<GenericTextInput LabelMd={3} Name="ReferenceNumber" LabelText="Reference Number" Value={model.ReferenceNumber} ValueChanged={this.ValueChanged} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columns}
													PageSize={10}
													ShowPagination={true}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

UserFundsActivities.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default UserFundsActivities;