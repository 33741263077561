import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import { GenericDateInput, GenericExpansionPanel, GenericGrid, GenericSelectInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType } from "views/Constants/Constant";
import ParameterComponent from "views/Components/ParameterComponent";
import DateHelper from "core/DateHelper";


class AggregatedFeeByClient extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			list: [],
			model: {},
			filterModel: {},
			isLoading: false
		};

		this.trxTable = React.createRef();
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Aggregated Fee By Client");
		this.props.setAppCenterTitle("WALLET");
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };

		model[name] = newValue;
		this.setState({ model });
	}

	HandleClear = () => {
		this.setState({
			model: {}
		});
	};

	HandleSearch = () => {
		const model = { ...this.state.model };
		const { showMessage } = this.props;
		this.setState({ isLoading: true });

		Proxy.POST(
			"/bankapi/v1.0/FeeTransaction/SearchAggregatedFee",
			model,
			(responseData) => {
			  this.setState({ isLoading: false });
 
			  if (!responseData.IsSucceeded) {
				 showMessage("error", "Error", responseData.ErrorDescription);
				 return;
			  }
			  this.setState({
				list: responseData.Item ?? [],
			  });
			},
			(error) => {
			  this.setState({ isLoading: false });
			  showMessage("error", "Error", error);
			}
		 );
	};

	render() {
		const { alert, model, list, isLoading } = this.state;
		return (
			<div>
				<LoadingComponent Show={isLoading} />
				{alert}
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: false,
							OnClick: this.HandleSearch
						},
						{
							Code: "Clear",
							Disabled: false,
							OnClick: this.HandleClear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After}
				/>

				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Panel">
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer>
												<GridItem xs={4}>
													<GenericSelectInput
														Name="UniqueClientId"
														LabelText="Client"
														Method="POST"
														Url="/bankapi/v1.0/BankCustomer/Search"
														Parameter={{}}
														DataRoot="Item"
														KeyValueMember="Id"
														RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
														Value={model.UniqueClientId || ""}
														ValueChanged={this.HandleChange}
														CanClear
														All
													/>
												</GridItem>
												<GridItem xs={4}>
													<GenericDateInput
														Name="FromDate"
														LabelText="From Date"
														MaxDate={model.ToDate || DateHelper.GetDate()}
														IncludeTime={false}
														Value={model.FromDate}
														ValueChanged={this.HandleChange} 
														Utc />
												</GridItem>
												<GridItem xs={4}>
													<GenericDateInput
														Name="ToDate"
														LabelText="To Date"
														IncludeTime={false}
														MinDate={model.FromDate}
														MaxDate={DateHelper.GetDate()}
														ValueChanged={this.HandleChange}
														Utc />
												</GridItem>
												<GridItem xs={4}>
													<ParameterComponent
														Name="FeeTypeId"
														LabelText="Fee Type"
														ParameterCode="FeeType"
														Value={model.FeeTypeId}
														ValueChanged={this.HandleChange} />		
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridItem xs={12}>
											<GenericGrid
												Data={list}
												ref={this.trxTable}
												Columns={
													[
														{
															Header: "Calculated Date",
															accessor: "CalculatedDate",
															type: GridColumnType.DateUtc,
															ColumnType: GridColumnType.DateUtc
														},
														{
															Header: "Client Name",
															accessor: "ClientName"
														},
														{
															Header: "Program",
															accessor: "Program"
														},
														{
															Header: "Fee Type",
															accessor: "FeeType"
														},
														{
															Header: "Fee Summary",
															accessor: "FeeSummary",
															type: GridColumnType.Money,
															ColumnType: GridColumnType.Money
														},
														{
															Header: "Fee Count",
															accessor: "FeeCount"
														}
													]
												}
												ShowPagination={true}
											/>
										</GridItem>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

			</div>
		);
	}
}

AggregatedFeeByClient.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default AggregatedFeeByClient;