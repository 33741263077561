import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import AlertHelper from "core/AlertHelper";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React from "react";
import Select from "react-select";
import AccountLimitToolbar from "views/Components/AccountLimitToolbar";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericRadioInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { GridColumnType, ProgramCodes, MenuCodes } from "views/Constants/Constant.js";

class Errors extends React.Component {
	constructor(props) {
		super(props);

		this.isClient = ClientHelper.IsClient();
		this.clientRowId = ClientHelper.GetClientRowId();
		this.clientId = ClientHelper.GetClientId();
		this.isBackOffice = ClientHelper.IsBackOffice();

		this.initialModel = {
			UniqueClientId: this.isBackOffice ? undefined : this.clientRowId,
			TransactinDateFrom: DateHelper.GetDate(),
			TransactinDateTo: DateHelper.GetDate(),
			ReferenceNumber: null
		};

		this.state = {
			model: { ...this.initialModel },
			countModel: {},
			isLoading: false,
			LimitToken: false
		};

		this.trxTable = React.createRef();

		this.sortParamDesc = { Member: "ParameterDesc" };
		this.sortParamValue = { Member: "ParameterValue" };

		this.HandleClear = this.HandleClear.bind(this);
		this.HandleChange = this.HandleChange.bind(this);
		this.HandleChangeItemCount = this.HandleChangeItemCount.bind(this);

		this.ErrorListCallback = this.ErrorListCallback.bind(this);

		this.GetItemCountCallback = this.GetItemCountCallback.bind(this);
		this.SuccessItemCountCallback = this.SuccessItemCountCallback.bind(this);
		this.ErrorItemCountCallback = this.ErrorItemCountCallback.bind(this);
	}

	componentDidMount() {
		const { setAppLeftTitle, setAppCenterTitle } = this.props;
		//this.GetItemCountCallback();

		if (setAppLeftTitle)

			if (this.props.MenuCode == MenuCodes.Menu_Backoffice_RejectedReturnTransactionsDdt ||
				this.props.MenuCode == MenuCodes.Menu_Client_RejectedReturnTransactionsDdt) {
				setAppLeftTitle("DDT Rejected & Returned Transactions");
			} else {
				setAppLeftTitle("Rejected & Returned Transactions");
			}
		if (setAppCenterTitle) {
			if (this.props.MenuCode == MenuCodes.Menu_Backoffice_RejectedReturnTransactionsDdt ||
				this.props.MenuCode == MenuCodes.Menu_Client_RejectedReturnTransactionsDdt) {
				setAppCenterTitle("DDT MANAGEMENT");
			} else {
				setAppCenterTitle("EFT SYSTEM");
			}
		}


		this.RefreshToolBar();
		this.setState({ loadingCompleted: true });
	}

	RefreshToolBar = () => {
		this.setState({ LimitToken: !this.state.LimitToken });
	}

	HandleClear() {
		this.setState({ model: { ...this.initialModel } });
	}

	HandleChange(name, value) {
		const model = { ...this.state.model };
		model[name] = value;
		if (name == "MainSearchItemId")
			this.initialModel[name] = value;
		this.setState({ model });
	}

	HandleChangeItemCount(name, value) {
		const countModel = { ...this.state.countModel };
		countModel[name] = value;
		this.setState({ countModel });
	}

	ValueChanged = (name, value) => {
		this.setState({ [name]: value });
	}

	HandleGetList = () => {
		this.setState(state => ({ trxGridToken: !state.trxGridToken }));
	}

	Validate = () => {
		const { model } = this.state;

		if (!model.CrossReferenceNumber && !model.PayeeAccount) {
			if (!model.UniqueClientId) {
				this.ShowMessage("warning", "Client not selected", "Select Client to continue");
				return false;
			}

			if (!model.TransactinDateFrom) {
				this.ShowMessage("warning", "From Date not selected", "Select From Date to continue");
				return false;
			}

			if (!model.TransactinDateTo) {
				this.ShowMessage("warning", "To Date not selected", "Select To Date to continue");
				return false;
			}

			var diffMonths = model.TransactinDateTo.diff(model.TransactinDateFrom, "days");
			if (diffMonths < 0) {
				this.ShowMessage("warning", "To Date must be later than From Date", "To Date must be later than From Date");
				return false;
			}

			if (diffMonths > 30) {
				this.ShowMessage("warning", "Difference between From Date and To Date cannot be longer than 30 days", "Difference between From Date and To Date cannot be longer than 30 days");
				return false;
			}
		}

		return true;
	}

	ExportClick = () => {
		var trxColumns = [];
		this.trxTable.current.props.Columns.forEach(c => {
			if (c.accessor != "Actions" && c.accessor != "ED" && (c.show == undefined || c.show)) {
				trxColumns.push({ value: c.accessor, title: c.Header, columntype: c.ColumnType, type: c.type });
			}
		}, this);

		this.setState({ isDownloadDialogOpen: true, GridKeyValueList: trxColumns, ExportSelectedColumns: trxColumns });
	}

	ExcelDownload = () => {
		if (!this.state.ExportReportType) {
			this.ShowMessage("warning", "Export Report Type not selected", "Select Export Report Type to continue");
			return;
		}

		if (this.state.ExportReportType == "PDF" && this.state.ExportSelectedColumns.length > 15) {
			this.ShowMessage("warning", "PDF document cannot exceed 15 columns", "PDF document cannot exceed 15 columns");
			return;
		}

		this.setState({ isDownloadDialogOpen: false });

		if (!this.Validate())
			return;

		const temp = { ...this.state.model };

		var jsonColList = [];

		var trxColumns = {};
		this.state.ExportSelectedColumns.map(c => {
			trxColumns[c.value] = { value: c.value, title: c.title, columntype: c.columntype, type: c.type };
		});
		jsonColList.push(trxColumns);

		var mytitle = "EFT Rejected & Returned Transactions";

		temp.jsonFile = {
			colmns: jsonColList,
			format: this.state.ExportReportType,
			title: mytitle
		};
		var request = {
			UniqueClientId: temp.UniqueClientId,
			FinancialInstitutionId:temp.FinancialInstitutionId,
			MainSearchItemId:temp.MainSearchItemId,
			EftTypeId: temp.EftTypeId,
			TransactionTypeId : temp.TransactionTypeId,
			TransactionCodeId:temp.TransactionCodeId,
			ReturnReasonId:temp.ReturnReasonId,
			PayeeName:temp.PayeeName,
			TransactionAmount:temp.TransactionAmount,
			CrossReferenceNumber:temp.CrossReferenceNumber,
			StoreId: temp.StoreId,
			TransactinDateFrom:temp.TransactinDateFrom,
			TransactinDateTo:temp.TransactinDateTo,
			DirectionId: temp.DirectionId,
			TransactionSourceId : temp.TransactionSourceId,
			PayeeAccount:temp.PayeeAccount,
			MenuCode:temp.MenuCode,
			ReferenceNumber:temp.ReferenceNumber,
			jsonFile:temp.jsonFile,

	};

		this.setState({ isLoading: true });
		Proxy.DownloadGeneratedFile(
			"/bankapi/v1.0/BankBmoEFT/DDTRejectedReturnedExportDownload",
			request,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", errorMessage);
			},
			this.props.showMessage
		);
	}

	SuccessListCallback = (responseData) => {
		this.setState({ isLoading: false });
		if (!responseData.IsSucceeded) {
			this.props.showMessage("error", "Error", responseData.ErrorDescription);
			return;
		}
		if (responseData.Item != null) {
			this.setState({ list: responseData.Item });
		} else {
			this.setState({ list: [] });
		}
	}

	ErrorListCallback(error) {
		this.setState({ isLoading: false });
		this.props.showMessage("error", "Error", error);
	}

	GetItemCountCallback() {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/Transaction/GetEftTransactionCountByStatus",
			{},
			this.SuccessItemCountCallback,
			this.ErrorItemCountCallback
		);
	}

	SuccessItemCountCallback(responseData) {
		this.setState({ isLoading: false });
		if (!responseData.IsSucceeded) {
			this.props.showMessage("error", "Error", responseData.ErrorDescription);
			return;
		}

		this.HandleChangeItemCount("RejectedFiles", String(responseData.RejectedFiles));
		this.HandleChangeItemCount("ErrorCount", String(responseData.ErrorCount));
		this.HandleChangeItemCount("ReturnCount", String(responseData.ReturnCount));
	}

	ErrorItemCountCallback(error) {
		this.setState({ isLoading: false });
		this.props.showMessage("error", "Error", error);
	}

	// HandleSend(model) {
	// 	this.setState({ isLoading: true });
	// 	Proxy.POST(
	// 		"/bankapi/v1.0/",
	// 		model,
	// 		this.SuccessSendCallback,
	// 		this.ErrorSendCallback
	// 	);
	// }

	// SuccessSendCallback(responseData) {
	// 	this.setState({ isLoading: false });
	// 	if (!responseData.IsSucceeded) {
	// 		this.props.showMessage("error", "Error", responseData.ErrorDescription);
	// 		return;
	// 	}
	// 	this.props.showMessage("success", "Succeeded!", "Operation is successfully!");
	// }

	// ErrorSendCallback(error) {
	// 	this.setState({ isLoading: false });
	// 	this.props.showMessage("error", "An error occurred while sending data", error.message);
	// }

	ShowMessage(type, title, message) {
		this.setState({
			alert: AlertHelper.CreateAlert(title, message, type, () => this.setState({ alert: "" }))
		});
	}

	RenderParam(d) {
		const code = d.ParameterValue;
		const desc = d.ParameterDesc;

		return `${code} - ${desc}`;
	}

	render() {
		const { Disabled } = this.props;
		const { alert, isLoading, model, list, LimitToken } = this.state;
		return (
			<div>
				{this.isClient && (this.props.MenuCode != MenuCodes.Menu_Client_RejectedReturnTransactionsDdt) && <AccountLimitToolbar key={LimitToken} Program={ProgramCodes.Bank_Programs_EFT} />}
				<LoadingComponent Show={isLoading} />
				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled || model === this.initialModel },
					{ Code: "Search", OnClick: this.HandleGetList, Disabled: Disabled },
					{ Code: "Export", OnClick: this.ExportClick, Disabled: Disabled },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Parameter">
									<GridContainer>
										<GridItem xs={4}>
											<GridItem>
												<GenericSelectInput
													Name="UniqueClientId"
													LabelText="Client"
													Method="POST"
													Url="/bankapi/v1.0/BankCustomer/Search"
													Parameter={{ UniqueClientId: this.isClient ? this.clientId : undefined }}
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
													Value={model.UniqueClientId || ""}
													ValueChanged={this.HandleChange}
													CanClear
													All={this.isBackOffice}
													Disabled={Disabled || this.isClient} />
											</GridItem>
											<GridItem>
												<GenericSelectInput
													Name="MainSearchItemId"
													LabelText="Main Search Item"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={{
														ParameterCode: "MainSearchItem",
														ParameterValue2: "EFT",
														ParameterValue3: "Rejected & Returned Trx."
													}}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={model.MainSearchItemId}
													ValueChanged={this.HandleChange}
													All={this.isBackOffice}
													DefaultIndex={0}
												/>
											</GridItem>
											<GridItem>
												<ParameterComponent
													Name="EFTTypeId"
													LabelText="EFT Type"
													Value={model.EFTTypeId}
													ParameterCode="EFTType"
													ValueChanged={this.HandleChange}
												/>
											</GridItem>
											<GridItem>
												<GenericSelectInput
													Name="TransactionTypeId"
													LabelText="Transaction Type"
													Method="GET"
													Url="/bankapi/v1.0/Transaction/GetAllFinancialTransactionType"
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={model.TransactionTypeId}
													ValueChanged={this.HandleChange}
												/>
											</GridItem>
											<GridItem>
												<ParameterComponent
													Name="TransactionCodeId"
													LabelText="EFT Transaction Code"
													ParameterCode="EFTTransactionType"
													RenderItem={this.RenderParam}
													Value={model.TransactionCodeId}
													ValueChanged={this.HandleChange}
													Disabled={Disabled} Sorted={this.sortParamDesc}
												/>
											</GridItem>
											<GridItem>
												<ParameterComponent
													Name="ReturnReasonId"
													LabelText="EFT Return Reason Code"
													ParameterCode="EFTTransactionType"
													ParameterValue2="ReturnReason"
													RenderItem={this.RenderParam}
													Value={model.ReturnReasonId}
													ValueChanged={this.HandleChange}
													Disabled={model.MainSearchItemId == 10016 ? false : true} Sorted={this.sortParamDesc}
												/>
											</GridItem>
											<GridItem>
												<GenericSelectInput
													Name="FinancialInstitutionId"
													LabelText="Financial Institution"
													Method="GET"
													Url="/bankapi/v1.0/FinancialInstitution/GetAll"
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={d => `${String(d.FinancialInstitutionId ?? "").padStart(3, "0")} - ${d.Description}`}
													Value={model.FinancialInstitutionId}
													ValueChanged={this.HandleChange}
												/>
											</GridItem>
										</GridItem>
										<GridItem xs={4}>
											<GridItem>
												<GenericTextInput
													Name="PayeeName"
													LabelText="Customer Name"
													Value={model.PayeeName}
													ValueChanged={this.HandleChange}
												/>
											</GridItem>
											<GridItem>
												<GenericTextInput
													Name="PayeeAccount"
													LabelText="Customer Wallet"
													Value={model.PayeeAccount}
													ValueChanged={this.HandleChange}
												/>
											</GridItem>
											<GridItem>
												<GenericTextInput
													Name="TransactionAmount"
													LabelText="Amount"
													Value={model.TransactionAmount}
													ValueChanged={this.HandleChange}
												/>
											</GridItem>
											<GridItem>
												<GenericSelectInput
													Name="TransactionSourceId"
													LabelText="Transaction Source"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={{
														ParameterCode: "TransactionSource"
													}}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={model.TransactionSourceId}
													ValueChanged={this.HandleChange}
												/>
											</GridItem>
											<GridItem>
												<GenericTextInput
													Name="ReferenceNumber"
													LabelText="Reference Number"
													Value={model.ReferenceNumber}
													ValueChanged={this.HandleChange}
												/>
											</GridItem>
											<GridItem>
												<GenericSelectInput
													key={model.FinancialInstitutionId}
													Name="FinancialInstitutionBranchId"
													LabelText="Transit"
													Method="POST"
													Url="/bankapi/v1.0/FinancialInstitutionBranch/Search"
													Parameter={{ FinancialInstitutionId: model.FinancialInstitutionId }}
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={d => `${String(d.TransitNumber ?? "").padStart(4, "0")} - ${d.Description}`}
													Value={model.FinancialInstitutionBranchId}
													ValueChanged={this.HandleChange}
													Disabled={Disabled}
													Sorted={{ Member: "TransitNumber" }}
												/>
											</GridItem>
										</GridItem>
										<GridItem xs={4}>
											<GridItem >
												<ParameterComponent
													Name="DirectionId"
													LabelText="Direction"
													Value={model.DirectionId}
													ParameterCode="Direction"
													ValueChanged={this.HandleChange}
													Disabled={Disabled}
												/>
											</GridItem>
											<GridItem>
												<GenericTextInput
													Name="CrossReferenceNumber"
													LabelText="Cross Reference Number"
													Value={model.CrossReferenceNumber}
													ValueChanged={this.HandleChange} />
											</GridItem>
											<GridItem>
												<GenericSelectInput
													Name="StoreId"
													LabelText="Store"
													Method="POST"
													Url="/bankapi/v1.0/CustomerStore/GetByUniqueClientRowId"
													DataRoot="Item"
													KeyValueMember="Id"
													Parameter={{ UniqueClientId: this.clientRowId == null ? 0 : this.clientRowId, IsUniqueClientRowId: true }}
													TextValueMember="StoreName"
													Value={model.StoreId}
													ValueChanged={this.HandleChange}
													Sorted={{ Member: "StoreName" }}
												/>
											</GridItem>
											<GridItem>
												<GenericDateInput
													Name="TransactinDateFrom"
													LabelText="From Date"
													Value={model.TransactinDateFrom ? Formatter.FormatDateUtc(model.TransactinDateFrom) : ""}
													ValueChanged={this.HandleChange}
													Utc
												/>
											</GridItem>
											<GridItem>
												<GenericDateInput
													Name="TransactinDateTo"
													LabelText="To Date"
													Value={model.TransactinDateTo ? Formatter.FormatDateUtc(model.TransactinDateTo) : ""}
													ValueChanged={this.HandleChange}
													Utc
													/>
											</GridItem>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardHeader>
								<GridContainer spacing={16} justify="space-between" alignItems="center">
									<GridItem>
										<CardHeader>
											<h4><b>Transaction List</b></h4>
										</CardHeader>
									</GridItem>
								</GridContainer>
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													key={"trxGrid" + this.state.trxGridToken}
													ref={this.trxTable}
													PageSize={10}
													ShowPagination={true}
													Columns={[
														{
															Header: "Client Name",
															accessor: "ClientName"
														},
														{
															Header: "Transaction Date",
															accessor: "TransactionDate",
															type: GridColumnType.DateTimeUtc
														},
														{
															Header: "Clearing Date",
															accessor: "ClearingDate",
															type: GridColumnType.DateUtc
														},
														{
															Header: "Return Date",
															accessor: "ReturnDate",
															type: GridColumnType.DateUtc
														},
														{
															Header: "Logical Record Type",
															accessor: "LogicalRecordType",
															Cell: row => (
																<div>	{row.value == "C" ? "Credit" : row.value == "D" ? "Debit" : row.value == "I" ? "Return of Credit" : row.value == "J" ? "Return of Debit" : "-"}</div>
															)
														},
														{
															Header: "Logical Record Count",
															accessor: "LogicalRecordCount"
														},
														{
															Header: "Eft Type",
															accessor: "EftType"
														},
														{
															Header: "Originator",
															accessor: "OriginatorId"
														},
														{
															Header: "File Creation Number",
															accessor: "FileCreationNumber"
														},
														{
															Header: "Payee Drawee Institution",
															accessor: "PayeeDraweeInstitution"
														},
														{
															Header: "Customer Name",
															accessor: "Name"
														},
														{
															Header: "EFT Transaction Type",
															accessor: "TransactionType"
														},
														{
															Header: "Stored Transaction Type",
															accessor: "StoredTransactionType"
														},
														{
															Header: "Direction",
															accessor: "Direction"
														},
														{
															Header: "Process Status",
															accessor: "ProcessStatus"
														},
														{
															Header: "Match Status",
															accessor: "MatchStatus"
														},
														{
															Header: "Validation Description",
															accessor: "ValidationDescription"
														},
														{
															Header: "Validation Status",
															accessor: "ValidationStatus"
														},
														{
															Header: "Financial Institution",
															accessor: "FinancialInstitution"
														},
														{
															Header: "Amount",
															accessor: "Amount",
															type: GridColumnType.Money,
															ColumnType: GridColumnType.Money
														},
														{
															Header: "Customer Number",
															accessor: "CustomerNumber"
														},
														{
															Header: "File Id",
															accessor: "FileId"
														},
														{
															Header: "File Name",
															accessor: "FileInformationName"
														},
														{
															Header: "Store",
															accessor: "Store"
														},
														{
															Header: "Return Transaction Type",
															accessor: "ReturnTransactionType"
														},
														{
															Header: "Transaction Type Trnx",
															accessor: "TransactionTypeTrnx"
														},
														{
															Header: "Customer Wallet",
															accessor: "PayeeDraweeAccountNumber"
														},
														{
															Header: "Item Trace No",
															accessor: "ItemTraceNo"
														},
														{
															Header: "Originator Short Name",
															accessor: "OriginatorShortName"
														},
														{
															Header: "Payee Drawee Name",
															accessor: "PayeeDraweeName"
														},
														{
															Header: "Originator Long Name",
															accessor: "OriginatorLongName"
														},
														{
															Header: "Cross Reference Number",
															accessor: "CrossReferenceNumber"
														},
														{
															Header: "Transaction Source",
															accessor: "TransactionSource"
														},
													]}
													ProgramCode={ProgramCodes.Bank_Programs_EFT}
													ServerSide
													LoadAtStartup={this.state.loadingCompleted}
													ValidateRequest={this.Validate}
													PrepareRequest={() => {
														const { model } = this.state;
														if (this.props.MenuCode != null) {
															model.MenuCode = this.props.MenuCode;
														}
														return model;
													}}
													RequestUrl="/bankapi/v1.0/BankBmoEFT/SearchEFTRejectedsTransactionsPaged"
													RequestMethod="POST"
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

				<GenericDialog open={this.state.isDownloadDialogOpen} fullWidth={true}>
					<DialogTitle>
						<GenericLabel Text="Select Report Type" FontSize="20px" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ height: 300 }}>
						<GenericRadioInput
							Name="ExportReportType"
							LabelText="Report Type"
							IsStatic={true}
							StaticData={[{ Value: "PDF", Text: "PDF" }, { Value: "CSV", Text: "CSV" }, { Value: "EXCEL", Text: "EXCEL" }]}
							KeyValueMember="Value"
							TextValueMember="Text"
							Value={this.state.ExportReportType}
							ValueChanged={this.ValueChanged}
						/>
						<GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
						<GridItem style={{ marginTop: 20 }}>
							<Select
								value={this.state.ExportSelectedColumns}
								onChange={value => this.setState({ ExportSelectedColumns: value })}
								isMulti
								name="columns"
								getOptionLabel={d => d.title}
								options={this.state.GridKeyValueList}
								className="basic-multi-select"
								classNamePrefix="select"
							/>
						</GridItem>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.ExcelDownload}>Download</Button>
						<Button size="sm" onClick={() => this.setState({ isDownloadDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>
			</div >
		);
	}
}

Errors.propTypes = {
	classes: PropTypes.object
};

export default Errors;
