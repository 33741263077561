import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import MenuAuthorityRightHelper from "core/MenuAuthorityRightHelper";
import { TaskPool } from "core/TaskPool";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import CardComponent from "views/Components/CardComponent.jsx";
import CustomerComponent from "views/Components/CustomerComponent.jsx";
import { GenericExpansionPanel, GenericSelectInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import TabsComponent from "views/Components/TabsComponent";
import { MenuCodes, ProgramCodes } from "views/Constants/Constant.js";
import CustomerCardInformation from "views/PPaid/Reports/Step/CustomerCardInformation";
import QrCode from "views/PPaid/Reports/Step/QrCode";
import EmbossInfo from "./Step/EmbossInfo";
import GeneralInfo from "./Step/GeneralInfo";
import Statistics from "./Step/Statistics";
import Transactions from "./Step/Transactions";


class CardMonitoring extends Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined,
			QrCode: ""
		};
		this.MenuAuthorityRightHelper = new MenuAuthorityRightHelper();

		this.state = {
			model: { ...this.initialModel },
			generalModel: { BankAccount: {}, WalletAccount: {}, ...this.initialModel },
			embossModel: { ...this.initialModel },
			statisticModel: { ...this.initialModel },
			customerInfo: { ...this.initialModel },
			vModel: {},
			transactions: [],
			cardList: [],
			isLoading: false,
			activeIndex: 0,
			isDisableCustomerCardInfoTab: false,
			isDisableGeneralInfoTab: false,
			isDisableEmbossInfoTab: false,
			isDisableStatisticsTab: false,
			isDisableTransactionsTab: false,
			isDisableQrCodeTab: false
		};
		this.loadTaskPool = new TaskPool(this.handleLoadTaskPoolAppend, this.handleLoadTaskPoolCompleted);

		this.emptyObject = {};

		this.maxLength26 = { maxLength: 26 };
		this.maxLength19 = { maxLength: 19 };

		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined
		};

		this.parameterPeriod = { ParameterCode: "LoadingPeriod" };

		this.renderItemUniqueClient = {};

		this.parameterProduct = {};
	}

	componentDidMount() {
		const { setAppLeftTitle, setAppCenterTitle, MenuCode } = this.props;

		switch (MenuCode) {
			case MenuCodes.CallCenter_CardMonitoring:
				if (setAppLeftTitle) setAppLeftTitle("Card Monitoring");
				if (setAppCenterTitle) setAppCenterTitle("CALL CENTER");
				this.GetGridButtonsRight();
				break;
			default:
				if (setAppLeftTitle) setAppLeftTitle("Card Monitoring");
				if (setAppCenterTitle) setAppCenterTitle("PREPAID CARD MANAGEMENT");
				break;
		}
	}


	GetGridButtonsRight = async () => {
		const { MenuCode } = this.props;
		const model = { ...this.state };
		
		var gridButtonRightDetail = await this.MenuAuthorityRightHelper.RightCheckList(MenuCode, ["CALCMTCCT", "CALCMTGIT", "CALCMTEIT", "CALCMTSST", "CALCMTTRT", "CALCMTQRT"]);
		model.isDisableCustomerCardInfoTab =
			this.props.MenuCode == MenuCodes.CallCenter_CardMonitoring ? !gridButtonRightDetail.find(x => x.Code == "CALCMTCCT")?.HasAuthority : false;
		model.isDisableGeneralInfoTab =
			this.props.MenuCode == MenuCodes.CallCenter_CardMonitoring ? !gridButtonRightDetail.find(x => x.Code == "CALCMTGIT")?.HasAuthority : false;
		model.isDisableEmbossInfoTab =
			this.props.MenuCode == MenuCodes.CallCenter_CardMonitoring ? !gridButtonRightDetail.find(x => x.Code == "CALCMTEIT")?.HasAuthority : false;
		model.isDisableStatisticsTab =
			this.props.MenuCode == MenuCodes.CallCenter_CardMonitoring ? !gridButtonRightDetail.find(x => x.Code == "CALCMTSST")?.HasAuthority : false;
		model.isDisableTransactionsTab =
			this.props.MenuCode == MenuCodes.CallCenter_CardMonitoring ? !gridButtonRightDetail.find(x => x.Code == "CALCMTTRT")?.HasAuthority : false;
		model.isDisableQrCodeTab =
			this.props.MenuCode == MenuCodes.CallCenter_CardMonitoring ? !gridButtonRightDetail.find(x => x.Code == "CALCMTQRT")?.HasAuthority : false;

		this.setState({
			isDisableCustomerCardInfoTab: model.isDisableCustomerCardInfoTab,
			isDisableGeneralInfoTab: model.isDisableGeneralInfoTab,
			isDisableEmbossInfoTab: model.isDisableEmbossInfoTab,
			isDisableStatisticsTab: model.isDisableStatisticsTab,
			isDisableTransactionsTab: model.isDisableTransactionsTab,
			isDisableQrCodeTab: model.isDisableQrCodeTab
		});

	}

	handleLoadTaskPoolCompleted = () => {
		this.setState({ isLoading: false });
	}

	handleLoadTaskPoolAppend = () => {
		this.setState({ isLoading: true });
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		if (name === "UniqueClientId") {
			delete model.ClientCustomerId;
			delete model.CustomerNumber;
			delete model.CardId;
			delete model.CardTokenNumber;
		}
		if (name === "Customer") {
			if (newValue) {
				model.ClientCustomerId = newValue.Id;
				model.CustomerNumber = newValue.CustomerNumber;
			} else {
				delete model.ClientCustomerId;
				delete model.CustomerNumber;
				delete model.CardId;
				delete model.CardTokenNumber;
			}
		}
		if (name === "Card") {
			if (newValue) {
				model.CardId = newValue.CardId;
				model.CardTokenNumber = newValue.CardTokenNumber;
			} else {
				delete model.CardId;
				delete model.CardTokenNumber;
			}
		}
		model[name] = newValue;
		this.setState({ model });
	}

	HandleClear = () => {
		this.setState({
			model: { ...this.initialModel },
			generalModel: { ...this.initialModel },
			embossModel: { ...this.initialModel },
			statisticModel: { ...this.initialModel },
			transactions: []
		});
	}

	HandleGetCardList = async () => {
		const { ExecuteApiPost } = this.props;
		const model = { ...this.state.model };
		this.setState({ isLoading: true });
		var request = {
			UniqueClientId: model.UniqueClientId,
			ClientCustomerId: model.ClientCustomerId,
			CardSearchCriteria: 2
		};
		var result = await ExecuteApiPost("/prepaidapi/v1.0/Card/CardSearch", request);
		this.setState({ cardList: result, isLoading: false });
	}

	HandleSearch = async () => {
		const { model } = this.state;

		if (!this.Validate()) {
			return;
		}

		this.loadTaskPool.AppendTask(
			Proxy.POST(
				"/prepaidapi/v1.0/Card/SearchForMonitoring",
				{
					UniqueClientId: model.UniqueClientId,
					ClientCustomerId: model.ClientCustomerId,
					CardId: model.CardId
				},
				(responseData) => {
					if (!responseData.IsSucceeded) {
						this.setState({ isLoading: false });
						this.props.showMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					if (responseData.Item !== null) {
						var generalModel = responseData.Item.CardInfo;
						Proxy.POST(
							"/prepaidapi/v1.0/Card/GetQrCodeData",
							{ Id: generalModel.CardId },
							(responseQr) => {
								generalModel.QrCode = responseQr.Item;
								this.setState({
									isLoading: false,
									generalModel: generalModel,
									embossModel: responseData.Item.EmbossInfo,
									statisticModel: responseData.Item.StatisticInfo,
									transactions: responseData.Item.CardTransactions,
									customerInfo: responseData.Item.CustomerInfo,
									cardList: responseData.Item.CardList
								});

							}, (error) => {
								this.setState({ isLoading: false });
								this.props.showMessage("error", "Error", error);
							});
					}

					else {
						this.setState({
							isLoading: false,
							generalModel: { BankAccount: {}, WalletAccount: {}, ...this.initialModel },
							embossModel: { ...this.initialModel },
							statisticModel: { ...this.initialModel },
							customerInfo: { ...this.initialModel },
							transactions: []
						});
					}
				},
				(error) => {
					this.setState({ isLoading: false });
					this.props.showMessage("error", "Error", error);
				}
			)
		);
	}

	Validate = () => {
		const { model, vModel } = this.state;
		var messages = [];

		vModel.UniqueClientId = model.UniqueClientId == null;
		if (vModel.UniqueClientId) messages.push("Client must be select");

		vModel.ClientCustomerId = model.ClientCustomerId == null;
		if (vModel.ClientCustomerId) messages.push("Customer must be select");

		vModel.CardId = model.CardId == null;
		if (vModel.CardId) messages.push("Card must be select");

		if (messages.length > 0) {
			this.props.showMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.setState({ vModel });
		return true;
	}

	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}

	TabIndexChanged = (index) => {
		this.setState({ activeIndex: index });
	}

	FillCustomer = (customer) => {
		this.HandleChange("Customer", customer);
	}

	FillCard = (card) => {
		this.HandleChange("Card", card);
	}

	render() {
		const { Disabled } = this.props;
		const { alert, isLoading, model, customerInfo, generalModel, embossModel, statisticModel, vModel, transactions, cardList, isDisableQrCodeTab, isDisableEmbossInfoTab, isDisableGeneralInfoTab, isDisableTransactionsTab, isDisableCustomerCardInfoTab, isDisableStatisticsTab } = this.state;

		var IsClient = ClientHelper.IsClient();

		return (
			<>

				<LoadingComponent Show={isLoading} />

				{alert}

				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<Card className="no-radius">
					<CardBody>
						<GenericExpansionPanel Title="Main Panel">
							<GridContainer>
								<GridItem xs={12}>
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={this.parameterUniqueClientId}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={this.RenderItemUniqueClient}
												Value={model.UniqueClientId}
												ValueChanged={this.HandleChange}
												CanClear
												Disabled={IsClient}
												DefaultIndex={IsClient ? 0 : -1}
												Required
												IsInvalid={vModel.UniqueClientId} />
										</GridItem>
										<GridItem xs={4}>
											<CustomerComponent
												key={model.UniqueClientId}
												LabelMd={4}
												CustomerNumber={model.CustomerNumber}
												HandleChange={this.HandleChange}
												FillCallback={this.FillCustomer}
												Programs={[ProgramCodes.Prepaid]}
												UniqueClientId={model.UniqueClientId}
												Disabled={!model.UniqueClientId}
												Required
												IsInvalid={vModel.ClientCustomerId} />
										</GridItem>
										<GridItem xs={4}>
											<CardComponent
												key={"Card_" + [model.UniqueClientId, model.ClientCustomerId]}
												LabelMd={4}
												CardTokenNumber={model.CardTokenNumber}
												HandleChange={this.HandleChange}
												FillCallback={this.FillCard}
												ClientCustomerId={model.ClientCustomerId}
												UniqueClientId={model.UniqueClientId}
												Disabled={!model.ClientCustomerId}
												Required
												IsInvalid={vModel.CardId} 
												MenuCode = {this.props.MenuCode}/>
										</GridItem>
									</GridContainer>
								</GridItem>
							</GridContainer>
						</GenericExpansionPanel>
					</CardBody>
				</Card>

				<br />

				<TabsComponent
					ActiveIndex={this.state.activeIndex}
					tabList={[
						{
							tabButton: "Customer/Card Info",
							tabContent: (
								<CustomerCardInformation model={customerInfo} UniqueClientId={model.UniqueClientId} ReadOnly={true} CardList={cardList} showMessage={this.props.showMessage} HandleCardList={this.HandleGetCardList} MenuCode={this.props.MenuCode} />
							),
							disabed: isDisableCustomerCardInfoTab
						},
						{
							tabButton: "General Info",
							tabContent: (
								<GeneralInfo model={generalModel} UniqueClientId={model.UniqueClientId} ReadOnly={true} />
							),
							disabled: isDisableGeneralInfoTab
						},
						{
							tabButton: "Emboss Info",
							tabContent: (
								<EmbossInfo model={embossModel} ReadOnly={true} />
							),
							disabled: isDisableEmbossInfoTab
						},
						{
							tabButton: "Statistics",
							tabContent: (
								<Statistics model={statisticModel} ReadOnly={true} />
							),
							disabled: isDisableStatisticsTab
						},
						{
							tabButton: "Transactions",
							tabContent: (
								<Transactions list={transactions} ReadOnly={true} />
							),
							disabled:isDisableTransactionsTab
						},
						{
							tabButton: "Qr Code",
							tabContent: (
								<QrCode model={generalModel} UniqueClientId={model.UniqueClientId} ReadOnly={true} ExecuteApiPost={this.props.ExecuteApiPost} ExecuteApiFileDownloadWithGenericResponse={this.props.ExecuteApiFileDownloadWithGenericResponse} MenuCode={this.props.MenuCode} />
							),
							disabled:isDisableQrCodeTab
						}
					]}
					tabIndexChanged={this.TabIndexChanged}
				/>

			</>
		);
	}
}

CardMonitoring.propTypes = {
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool,
	ExecuteApiPost: PropTypes.func,
	ExecuteApiFileDownloadWithGenericResponse: PropTypes.func

};

export default withArtifex(CardMonitoring, {});