import {
	primaryColor,
	warningColor,
	dangerColor,
	successColor,
	infoColor,
	roseColor,
	grayColor,
	cardTitle
} from "assets/jss/material-dashboard-pro-react.jsx";

import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

const style = {
	...customCheckboxRadioSwitch,
	...customSelectStyle,
	...sweetAlertStyle,
	cardBackground: {
		background: "#efefef"
	},
	cardTitle,
	cardTitleWhite: {
		...cardTitle,
		color: "#FFFFFF",
		marginTop: "0",
		textTransform: "none !important"
	},
	cardCategoryWhite: {
		margin: "0",
		color: "rgba(255, 255, 255, 0.8)",
		fontSize: "1.2em",
		fontWeight: "bold",
		textTransform: "none !important"
	},
	cardCategory: {
		color: "#999999",
		marginTop: "10px",
		fontSize: "1.2em",
		fontWeight: "bold",
		textTransform: "none !important"
	},
	icon: {
		color: "#333333",
		margin: "10px auto 0",
		width: "130px",
		height: "130px",
		border: "3px solid #E5E5E5",
		borderRadius: "50%",
		lineHeight: "174px",
		"& svg": {
			width: "55px",
			height: "55px"
		},
		"& .fab,& .fas,& .far,& .fal,& .material-icons": {
			width: "55px",
			fontSize: "55px"
		}
	},
	iconRose: {
		color: primaryColor
	},
	marginTop30: {
		marginTop: "30px"
	},
	testimonialIcon: {
		marginTop: "30px",
		"& svg": {
			width: "40px",
			height: "40px"
		}
	},
	cardTestimonialDescription: {
		fontStyle: "italic",
		color: "#999999"
	},
	cardIconTitle: {
		...cardTitle,
		marginTop: "15px",
		marginBottom: "0px"
	},
	legendTitle: {
		color: grayColor,
		margin: "10px 0 !important",
		display: "flex"
	},
	primary: {
		color: primaryColor
	},
	warning: {
		color: warningColor
	},
	danger: {
		color: dangerColor
	},
	success: {
		color: successColor
	},
	info: {
		color: infoColor
	},
	rose: {
		color: roseColor
	},
	gray: {
		color: grayColor
	},
	cardFooter: {
		display: "block"
	},
	chartItem: {
		fontSize: "14px",
		dominantBaseline: "central",
		textAnchor: "middle",
		fontWeight : 800
	}
};

export default style;
