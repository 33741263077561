
import React from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import { Proxy } from "core";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton
} from "@material-ui/core";
import Button from "components/CustomButtons/Button.jsx";
import { GenericTextInput, GenericNumberInput, GenericDialog, GenericDialogActions } from "views/Components/Generic";
import { Search as SearchImg } from "@material-ui/icons";
import { withArtifex } from "core";


class CommissionProfileSearchComonent extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			model: {},
			dialog: {
				isOpen: false,
				dialogData: []
			},
			selected: 0
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
		this.handleClickByKeyPressed = this.handleClickByKeyPressed.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.handleClickInside = this.handleClickInside.bind(this);

		this.getProfileSearchCallback = this.getProfileSearchCallback.bind(this);
		this.getProfileSearchCallbackByKeyPressed = this.getProfileSearchCallbackByKeyPressed.bind(this);
		this.selectProfileSearchCallback = this.selectProfileSearchCallback.bind(this);
		this.cancelSelectProfileSearchCallback = this.cancelSelectProfileSearchCallback.bind(this);
	}

	handleChange(name, value) {
		const model = { ...this.state.model };
		model[name] = value;
		this.setState({ model });
	}

	handleKeyPress(e) {
		if (e.key == "Enter") {
			this.handleClickByKeyPressed();
		}
	}

	handleClickByKeyPressed() {
		Proxy.POST("/bankapi/v1.0/CommissionProfile/Search", {
			ProfileName: this.props.ProfileName
		}, this.getProfileSearchCallbackByKeyPressed);
	}

	handleClick() {
		Proxy.POST("/bankapi/v1.0/CommissionProfile/Search",
			{
				ProfileName: this.props.ProfileName
			},
			this.getProfileSearchCallback);

	}

	handleClickInside() {
		Proxy.POST("/bankapi/v1.0/CommissionProfile/Search",
			this.state.model,
			this.getProfileSearchCallback);
	}

	getProfileSearchCallback(responseData) {
		if (responseData.Item != null) {
			this.setState({
				dialog: {
					isOpen: true,
					dialogData: responseData.Item
				}
			});
		} else {
			this.setState({ dialogData: [] });
		}
	}

	getProfileSearchCallbackByKeyPressed(responseData) {
		this.getProfileSearchCallback(responseData);

		if (responseData.Item.length === 1) {
			this.selectProfileSearchCallback(responseData.Item[0]);
		}
	}

	selectProfileSearchCallback(model) {
		this.props.fillCallback(model);
		this.setState({
			dialog: {
				isOpen: false,
				dialogData: []
			}
		});
	}

	cancelSelectProfileSearchCallback() {
		this.setState({
			dialog: {
				isOpen: false,
				dialogData: []
			}
		});
	}

	render() {
		var { buttonProps, SearchType } = this.props;
		return (
			<div>
				<GridContainer>
					<GridItem xs={12} sm={10}>
						<GenericTextInput
							Name={this.props.nameTextSearchProp || "ProfileName"}
							LabelText="Profile Name"
							tabIndex={0}
							KeyPressed={this.handleKeyPress}
							Blur={this.handleClickByKeyPressed}
							ThousandSeparator=""
							Value={this.props.ProfileName}
							ValueChanged={this.props.handleChange} />
					</GridItem>
					<GridItem xs={12} sm={2}>
						<IconButton size="sm" component="span">
							<SearchImg {...buttonProps} onClick={this.handleClick} />
						</IconButton>
					</GridItem>
				</GridContainer>
				<GenericDialog open={this.state.dialog.isOpen}>
					<DialogTitle>Select Commission Profile</DialogTitle>
					<DialogContent>
						<GridContainer>
							<GridItem xs={12} sm={5}>
								<GenericNumberInput
									Name={this.props.nameIdSearchProp || "ProfileId"}
									LabelText="Profile ID"
									ThousandSeparator=""
									Value={this.state.model.ProfileId}
									ValueChanged={this.handleChange} />
							</GridItem>
							<GridItem xs={12} sm={5}>
								<GenericTextInput
									Name={this.props.nameTextSearchProp || "ProfileName"}
									LabelText="Profile Name"
									Value={this.state.model.ProfileName}
									ValueChanged={this.handleChange} />
							</GridItem>
							<GridItem xs={12} sm={2}>
								<IconButton size="sm" component="span">
									<SearchImg onClick={this.handleClickInside} />
								</IconButton>
							</GridItem>
						</GridContainer>
						<ReactTable
							data={this.state.dialog.dialogData.map(d => {
								return {
									Id: d.Id,
									ProfileId: d.ProfileId,
									ProfileName: d.ProfileName,
									ValidFrom: d.ValidFrom,
									ValidTo: d.ValidTo,
									actions: (
										<div>
											<Button
												size="sm"
												onClick={() => {
													this.selectProfileSearchCallback(d);
												}}
											>
												Select
											</Button>
										</div>
									)
								};
							})
							}
							filterable
							columns={
								[{
									Header: "ID",
									accessor: "Id"
								},
								{
									Header: "Profile ID",
									accessor: "ProfileId"
								},
								{
									Header: "Profile Name",
									accessor: "ProfileName"
								},
								{
									Header: "Actions",
									accessor: "actions",
									sortable: false,
									filterable: false,
									resizable: false
								}
								]}
							defaultPageSize={10}
							showPaginationTop={false}
							showPaginationBottom={this.state.dialog.dialogData !== undefined ? Object.keys(this.state.dialog.dialogData).length > 50 : false}
							minRows={2}
						/>
					</DialogContent>
					<GenericDialogActions>
						<Button
							size="sm"
							onClick={this.cancelSelectProfileSearchCallback}
						>
							Cancel
						</Button>
					</GenericDialogActions>
				</GenericDialog>
			</div>
		);
	}
}

CommissionProfileSearchComonent.propTypes = {
	buttonProps: PropTypes.object,
	handleChange: PropTypes.func
};

export default withArtifex(CommissionProfileSearchComonent, {});