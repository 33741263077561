import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericLabel, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import { BankingAccountTypes } from "views/Constants/Constant.js";

class DCBankAccountApprove extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			ApprovalData: {},
			branchModel: {}
		};
	}

	handleChange = (name, newValue, data) => {
		const model = { ...this.state.ApprovalData };
		const branch = { ...this.state.branchModel };
		model[name] = newValue;
		if (name == "FinancialInstitutionId" && data != null) {
			branch.FinancialInstitutionDescription = data.Description;
			model.BankNumber = data.FinancialInstitutionId;
		}
		if (name == "FinancialInstitutionBranchId" && data != null) {
			branch.FinancialInstitutionBranchDescription = data.Description;
			branch.FinancialInstitutionBranchAddress1 = data.Address1;
			branch.FinancialInstitutionBranchAddress2 = data.Address2;
			model.BranchNumber = data.TransitNumber;
		}
		if (name == "BankingAccountTypeId" && data != null) {
			if (data.ParameterValue == BankingAccountTypes.FeeAccount) {
				model.IsFeeAccount = true;
			} else {
				model.IsFeeAccount = false;
			}
		}
		this.setState({ ApprovalData: model, branchModel: branch });
	}

	updateAccount = (workflowId, after, accessToken) => {
		var temp = this.state.ApprovalData;
		this.setState({ isLoading: true });

		Proxy.POST(
			"/bankapi/v1.0/Account/Update",
			temp,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.showErrorMessage("Error " + responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					this.showSuccessMessage();
				}
				if (after) after();
				this.setState({ isLoading: false });
			},
			error => {
				this.setState({ isLoading: false });
				this.showErrorMessage("Error " + error);
			},
			workflowId,
			accessToken
		);
	}

	ClearData = () => {

	}

	showSuccessMessage = () => {
		this.setState({
			alert: <GenericAlert Title="Success" Message="Your changes saved succesfully ! " Type="success" OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	showErrorMessage = (message) => {
		this.setState({
			alert: <GenericAlert Title="Error" Message={message} Type="error" OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}
	render() {
		const { Disabled } = this.props;
		const { ApprovalData } = this.state;

		return (
			<GridContainer spacing={16}>
				<GridItem md={12} xs={12}>


					<ButtonToolbar ButtonList={[
						{ Code: "Clear", OnClick: () => this.ClearData(), Disabled: Disabled },
						{
							Code: "Submit", OnClick: this.updateAccount, Disabled: Disabled,
							ModelFunction: () => {
								return {
									ApprovalData: this.state.ApprovalData
								}
							},
							FillDataFromModel: model => {
								this.setState({ ApprovalData: model });
							},
							RowId: ApprovalData == null ? 0 : ApprovalData.Id
						}
					]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

					<Card className="no-radius">
						<CardBody>
							<GridContainer>
								<GridItem xs={12} sm={8}>
									<h5><b> {window.Title} Accounts </b></h5>
								</GridItem>
								<GridItem xs={12} sm={4}>
								</GridItem>
							</GridContainer>
							<GridContainer>
								<GridItem xs={12} sm={4}>
									<GenericLabel Text={"UniqueClientId " + ApprovalData.WorkflowUniqueClientId} Bold={true} />
								</GridItem>
								<GridItem xs={12} sm={4}>
									<GenericLabel Text={"Client Name " + ApprovalData.WorkflowClientName} Bold={true} />
								</GridItem>
								<GridItem xs={12} sm={4}>
									<GenericLabel Text={"Client Type " + ApprovalData.WorkflowClientType} Bold={true} />
								</GridItem>
							</GridContainer>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<GridContainer>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<GenericSelectInput
												Disabled={Disabled || ApprovalData != null && ApprovalData.Id > 0}
												Name="ProgramId"
												LabelText="Program"
												Value={ApprovalData == null ? "" : ApprovalData.ProgramId || ""}
												ValueChanged={this.handleChange}
												KeyValueMember="Id"
												TextValueMember="Description"
												Method="GET"
												Url="/bankapi/v1.0/Bank/ValidBankProgramsForLimitProfile"
												DataRoot="Item" />
										</GridItem>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<GenericSelectInput
												Disabled={ApprovalData == null ? Disabled : ApprovalData.Id > 0 ? true : Disabled}
												Name="BankingAccountTypeId"
												LabelText="Wallet Type"
												Method="POST" Url="/coreapi/v1.0/Parameter/Search"
												Parameter={{ ParameterCode: "BankingAccountType", ParameterValue2: "SettlementAccount" }}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ParameterDesc"
												Value={ApprovalData == null ? "" : ApprovalData.BankingAccountTypeId || ""}
												ValueChanged={this.handleChange} />
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem xs={6} sm={6} md={6}>
											<GenericSelectInput
												TitleLabel="Fins. Ins. No."
												LabelStyle={{ width: "33.6%" }}
												SelectStyle={{ width: "65.7%" }}
												LabelMd={-1}
												Name="FinancialInstitutionRowId"
												LabelText="Wallet No"
												Method="GET"
												Url="/bankapi/v1.0/FinancialInstitution/GetAll"
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Description"
												Value={46}
												ValueChanged={this.handleChange}
												Disabled={true}
											/>
										</GridItem>
										<GridItem xs={3} sm={3} md={3}>
											<GenericSelectInput
												TitleLabel="Transit"
												Method="POST"
												LabelMd={0}
												key={ApprovalData == null ? "" : ApprovalData.FinancialInstitutionId || ""}
												Name="FinancialInstitutionBranchId"
												Url="/bankapi/v1.0/FinancialInstitutionBranch/Search"
												Parameter={{
													FinancialInstitutionId: 46
												}}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Description"
												Value={ApprovalData == null ? "" : ApprovalData.FinancialInstitutionBranchId || ""}
												ValueChanged={this.handleChange}
												Disabled={true}
											/>
										</GridItem>
										<GridItem xs={3} sm={3} md={3}>
											<GenericTextInput
												NoFormatting={true}
												LabelMd={0}
												InputStyle={{ marginLeft: 1 }}
												inputProps={{ maxLength: 12 }}
												ThousandSeparator=""
												Value={ApprovalData == null ? "" : ApprovalData.AccountNumber || ""}
												ValueChanged={this.handleChange}
												Disabled={true}
											/>
										</GridItem>
									</GridContainer>
								</GridItem>
								<GridItem xs={12} sm={4} md={4} lg={4}>
									<GenericTextInput
										Disabled={Disabled}
										Name="AccountName"
										LabelText="Wallet Name"
										Value={ApprovalData == null ? "" : ApprovalData.AccountName || ""}
										ValueChanged={this.handleChange} />
									<GenericSelectInput
										Disabled={Disabled}
										Name="AccountStatusId"
										LabelText="Wallet Status"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={{ ParameterCode: "AccountStatus" }} DataRoot="Item"
										KeyValueMember="Id" TextValueMember="ParameterDesc"
										Value={ApprovalData == null ? "" : ApprovalData.AccountStatusId || ""}
										ValueChanged={this.handleChange} />
									<GenericSelectInput
										Disabled={Disabled}
										Name="IntendUseOfAccountId"
										LabelText="Intend Use Of Wallet"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={{ ParameterCode: "IntendUseOfAccount" }
										} DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={ApprovalData == null ? "" : ApprovalData.IntendUseOfAccountId || ""}
										ValueChanged={this.handleChange} />
								</GridItem>
								<GridItem xs={12} sm={2} md={2} lg={2}>
									{ApprovalData != null && ApprovalData.Id == 0 && ((ApprovalData.BankingAccountType != null && ApprovalData.BankingAccountType.ParameterValue == BankingAccountTypes.FeeAccount) || (ApprovalData.IsFeeAccount == true)) && (
										<GenericSelectInput
											Disabled={ApprovalData == null ? Disabled : ApprovalData.Id > 0 ? true : Disabled}
											Name="FeeAccountMinBalanceId"
											LabelText="Fee Wallet Min. Balance"
											Method="POST"
											Url="/coreapi/v1.0/Parameter/Search"
											Parameter={{ ParameterCode: "FeeAccountBalanceAmount" }}
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember="ParameterDesc"
											Value={ApprovalData == null ? "" : ApprovalData.FeeAccountMinBalanceId || ""}
											ValueChanged={this.handleChange} />
									)}
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>

				</GridItem>
			</GridContainer>
		);
	}
}

DCBankAccountApprove.propTypes = {
	classes: PropTypes.object
};

export default withArtifex(DCBankAccountApprove, {});