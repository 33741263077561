import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import DateHelper from "core/DateHelper";
import PropTypes, { bool, func } from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import {
  GenericAlert,
  GenericDateInput,
  GenericDialog,
  GenericDialogActions,
  GenericGrid,
  GenericLabel,
  GenericNumberInput,
  GenericRadioInput,
  GenericSelectInput,
  GenericTitle,
  GenericTextInput,
} from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType } from "views/Constants/Constant";
import { DialogContent, DialogTitle } from "@material-ui/core";
import { withArtifex } from "core";
import { Proxy } from "core";
import Button from "components/CustomButtons/Button.jsx";
import Select from "react-select";
import ParameterComponent from "views/Components/ParameterComponent";
import Formatter from "core/Formatter";
import { EditIcon, UndoIcon } from "core/Icons";
import GridButton from "views/Components/GridButton";

class BillPaymentSettlement extends Component {
  constructor(props) {
    super(props);
    this.initTransactionDetailColumns();
    this.initExportColumns();

    this.state = {
      alert,
      legacyGridRefreshToken: false,
      legacyGridLoadAtStartup: false,
      datasource: [],
      model: {
        BillerCode: null,
        UniqueClientId: null,
        FromDate: null,
        ToDate: null,
        TransactionReferenceNumber: null,
        TransactionStatusId: null,
        TelpayReferenceNumber: null,
        SettlementStatusId: "",
        IsTelpaySend: null,
        PageSize: 1_000_000,
        TotalAmount: "",
        TotalCount: "",
      },
      rejectedModel: {
        BillTransactionId: null,
        SettlementDate: null,
      },
      headerModel: {},
      transactionList: [],
      isLoading: false,
      isDownloadDialogOpen: false,
      exportReportType: "PDF",
      exportSelectedColumns: this.exportColumns,
      isRejectedDialogOpen: false,
		isUndoRejectedDialogOpen:false,
      selectedRejectedModelRow: null,
      selectedUndoRejectedModelRow: null,

    };
  }

  componentDidMount() {
    this.props.setAppLeftTitle("Bill Payment Transaction Settlement");
    this.props.setAppCenterTitle("CLEARING & SETTLEMENT");

    this.setState({ legacyGridLoadAtStartup: true });
  }

  handleChange = (name, newValue, data) => {
    const model = { ...this.state.model };

    model[name] = newValue;
    this.setState({ model });
  };

  ClearModel = () => {
    this.setState({
      model: {
        FromDate: null,
        ToDate: null,
        BillerCode: null,
        UniqueClientId: null,
        TransactionReferenceNumber: null,
        TelpayReferenceNumber: null,
        SettlementStatusId: "",
        IsTelpaySend: null,
        PageSize: 1_000_000,
        TotalAmount: "",
        TotalCount: "",
      },
    });
  };

  refreshGridToken = async () => {
    const { legacyGridRefreshToken } = this.state;

    this.setState({ legacyGridRefreshToken: !legacyGridRefreshToken });
  };
  onDialogValidate = () => {
    const { showMessage } = this.props;

    if (this.state.model.SettlementDate) {
      return true;
    }

    if (!this.state.model.FromDate) {
      showMessage(
        "warning",
        "From Date is not provided",
        "Select From Date to continue."
      );
      return false;
    }

    return true;
  };

  showBasicAlert = (title, message, type) => {
    this.setState({
      isLoading: false,
      alert: (
        <GenericAlert
          Title={title}
          Message={message}
          Type={type}
          ShowCancel={false}
          OnConfirm={() => this.hideAlert()}
        />
      ),
    });
  };

  hideAlert = () => {
    this.setState({ alert: null, isLoading: false });
  };

  GetTransactionList = () => {
    if (!this.onDialogValidate()) return;
    if (!this.state.model.ToDate && !this.state.model.SettlementDate) {
      this.setState({
        model: {
          ...this.state.model,
          ToDate: DateHelper.GetDate(),
        },
      });
    }
    this.setState({ isLoading: true });
    Proxy.POST(
      "/bankapi/v1.0/ClearingSettlement/GetBillPaymentTransactionSettlement",
      this.state.model,
      (responseData) => {
        this.successDailyFileList(responseData);
        this.setState((prevState) => ({
          ...prevState,
          model: {
            ...prevState.model,
            TotalAmount: responseData.Item.TotalAmount,
            TotalCount: responseData.Item.TotalCount,
          },
        }));
      },
      (error) => {
        this.showBasicAlert(
          "Error",
          "An error occurred during the api visit" + error,
          "error"
        );
      }
    );
  };

  successDailyFileList = (responseData) => {
    if (!responseData.IsSucceeded) {
      this.showBasicAlert("Error", responseData.ErrorDescription, "error");
      return;
    }
    this.setState({
      headerModel: {},
      transactionList:
        responseData.Item.BillPaymentTransactions == null
          ? []
          : responseData.Item.BillPaymentTransactions.length < 1
          ? []
          : responseData.Item.BillPaymentTransactions.map((x) => {
              return {
                Id: x.Id == null || undefined ? "" : x.Id,
                TransactionDate:
                  x.TransactionDate == null || undefined
                    ? ""
                    : x.TransactionDate,
                TelpaySendDate:
                  x.TelpaySendDate == null || undefined ? "" : x.TelpaySendDate,
                BillerCode:
                  x.BillerCode == null || undefined ? "" : x.BillerCode,
                BillerName:
                  x.BillerName == null || undefined ? "" : x.BillerName,
                UniqueClientId:
                  x.UniqueClientId == null || undefined ? "" : x.UniqueClientId,
                ClientName:
                  x.ClientName == null || undefined ? "" : x.ClientName,
                CustomerName:
                  x.CustomerName == null || undefined ? "" : x.CustomerName,
                Amount: x.Amount == null || undefined ? "" : x.Amount,
                TransactionReferenceNumber:
                  x.TransactionReferenceNumber == null || undefined
                    ? ""
                    : x.TransactionReferenceNumber,
                TelPayReferenceNumber:
                  x.TelPayReferenceNumber == null || undefined
                    ? ""
                    : x.TelPayReferenceNumber,
                SettlementStatus:
                  x.SettlementStatus == null || undefined
                    ? ""
                    : x.SettlementStatus,
                SettlementDate:
                  x.SettlementDate == null || undefined ? "" : x.SettlementDate,
                TransactionStatus:
                  x.TransactionStatus == null || undefined
                    ? ""
                    : x.TransactionStatus,
                IsEditable: x.IsEditable,
                IsRejected: x.IsRejected,
              };
            }),
      isLoading: false,
    });
  };

  getToolbarButtons() {
    return [
      { Code: "Clear", OnClick: this.ClearModel },
      { Code: "Search", OnClick: this.GetTransactionList },
      { Code: "Export", OnClick: this.openExportDialog },
    ];
  }

  initTransactionDetailColumns() {
    this.transactionDetailColumns = [
      {
        Header: "Actions",
        accessor: "Actions",
        Cell: (row) => (
          <div>
            <GridButton
              Disabled={!row.original.IsEditable}
              tooltip="Edit"
              Icon={EditIcon}
              OnClick={() => {
                this.openRejectedDialog();
                this.setState({
                  selectedRejectedModelRow: row.original,
                  rejectedModel: {
                    BillTransactionId: row.original.Id,
                  },
                });
              }}
            />
            <GridButton
            Disabled={!row.original.IsRejected}
              tooltip="Undo"
              Icon={UndoIcon}
              OnClick={() => {
					
					this.setState({
						isLoading: false,
						rejectedModel: {
							BillTransactionId: row.original.Id,
						 },
						alert: (
						  <GenericAlert
							 Title={"Confirmation"}
							 Message={"You are going to undo this rejected transaction?"}
							 Type={"info"}
							 ShowCancel={true}
							 OnConfirm={this.saveUndoRejectedTransaction}
							 OnCancel={this.hideAlert}
						  />
						),
					 });

              }}
            />
          </div>
        ),
        sortable: false,
        filterable: false,
        width: 70,
        show: true,
      },
      {
        Header: "Transaction Date",
        accessor: "TransactionDate",
        type: GridColumnType.DateUtc,
      },
      {
        Header: "Telpay Send Date",
        accessor: "TelpaySendDate",
        type: GridColumnType.DateUtc,
      },
      {
        Header: "Biller Code",
        accessor: "BillerCode",
      },

      {
        Header: "Biller Name",
        accessor: "BillerName",
      },

      {
        Header: "Client ID",
        accessor: "UniqueClientId",
      },

      {
        Header: "Client Name",
        accessor: "ClientName",
      },

      {
        Header: "Customer Name",
        accessor: "CustomerName",
      },
      {
        Header: "Amount",
        accessor: "Amount",
        type: GridColumnType.Money,
        ColumnType: GridColumnType.Money,
      },
      {
        Header: "Transaction Ref. No",
        accessor: "TransactionReferenceNumber",
      },
      {
        Header: "Telpay Ref. No",
        accessor: "TelPayReferenceNumber",
      },
      {
        Header: "Transaction Status",
        accessor: "TransactionStatus",
      },
      {
        Header: "Settlement Status",
        accessor: "SettlementStatus",
      },
      {
        Header: "Settlement Date",
        accessor: "SettlementDate",
        type: GridColumnType.DateUtc,
      },
    ];
  }

  initExportColumns() {
    var exportColumns = [];

    this.transactionDetailColumns.forEach((c) => {
      if (
        c.accessor != "Actions" &&
        c.accessor != "ED" &&
        (c.show == undefined || c.show)
      ) {
        var exportColumn = {};
        exportColumn.value = c.accessor;
        exportColumn.title = c.Header;
        if (c.type) {
          exportColumn.type = c.type;
        }

        exportColumns.push(exportColumn);
      }
    }, this);

    this.exportColumns = exportColumns;
  }

  setExportSelection = (value) => {
    this.setState({ exportSelectedColumns: value });
  };

  getSelectedExportColumns = () => {
    const { exportSelectedColumns } = this.state;

    var columns = {};

    exportSelectedColumns.forEach((c) => {
      columns[c.value] = {
        value: c.value,
        title: c.title,
        columntype: c.columntype,
        type: c.type,
      };
    });

    var result = [];
    result.push(columns);

    return result;
  };

  isExportRequestValid = () => {
    const { model } = this.state;

    if (model.FromDate == null || model.FromDate == undefined) {
      this.showBasicAlert(
        "warning",
        "Please select required field !",
        "Select a Settlement File Date !"
      );
      return false;
    }

    return true;
  };

  openExportDialog = () => {
    this.setState({
      isDownloadDialogOpen: true,
    });
  };

  closeExportDialog = () => {
    this.setState({
      isDownloadDialogOpen: false,
    });
  };

  dataRangeResultsCard = () => {
    const { TotalAmount, TotalCount } = this.state.model;

    return (
      <Card style={{ height: "92%", padding: ".5rem" }}>
        <CardHeader>
          <GenericTitle text="Date Range Results" />
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12}>
              <GenericNumberInput
                Name="TotalAmount"
                LabelText="Total Amount"
                LabelMd={6}
                Value={TotalAmount}
                Disabled
                AllowNegative
                DecimalScale={2}
                Prefix="$"
              />
            </GridItem>
            <GridItem xs={12}>
              <GenericNumberInput
                Name="TotalCount"
                LabelText="Total Count"
                LabelMd={6}
                Value={TotalCount}
                AllowNegative
                Disabled
                DecimalScale={2}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );
  };

  onExport = () => {
    if (this.isExportRequestValid()) {
      const { exportReportType } = this.state;
      const {
        FromDate,
        ToDate,
        SettlementStatusId,
        BillerCode,
        UniqueClientId,
        TelpayReferenceNumber,
        TransactionReferenceNumber,
        ...model2
      } = this.state.model;

      const exportModel = {
        ...model2,
        BillerCode: this.state.model.BillerCode,
        UniqueClientId: this.state.model.UniqueClientId,
        FromDate: this.state.model.FromDate,
        ToDate: this.state.model.ToDate,
        TransactionReferenceNumber: this.state.model.TransactionReferenceNumber,
        TelpayReferenceNumber: this.state.model.TelpayReferenceNumber,
        SettlementStatusId: this.state.model.SettlementStatusId,
        PageSize: this.state.model.PageSize,
        jsonFile: {},
      };

      var exportColumns = this.getSelectedExportColumns() ?? [];

      exportModel.jsonFile = {
        colmns: exportColumns,
        format: exportReportType,
        title: "Bill Payment Settlement Report",
      };

      this.showBasicAlert();

      Proxy.DownloadGeneratedFile(
        "/bankapi/v1.0/ClearingSettlement/BillPaymentTransactionsSettlementDownload",
        exportModel,
        (responseData) => {
          this.hideAlert();

          if (!responseData.IsSucceeded) {
            this.showBasicAlert(
              "error",
              "Error",
              responseData.ErrorDescription
            );
            return;
          }
        },
        (errorMessage) => {
          this.hideAlert();
          this.showBasicAlert("error", "Error", errorMessage);
        },
        this.showBasicAlert
      );
    }
  };

  setValueToState = (name, value) => {
    this.setState({ [name]: value });
  };
  getRejectedDialog = () => {
    const { isRejectedDialogOpen, selectedRejectedModelRow } = this.state;

    return (
      <GenericDialog open={isRejectedDialogOpen} maxWidth="sm">
        <DialogTitle>
          <GenericLabel Text="Bill Payment Rejected Transaction" Bold={true} />
        </DialogTitle>
        <DialogContent style={{ height: 400 }}>
          <GridContainer spacing={16}>
            <GridItem xs={12}>
              <GenericTextInput
                Name="ClientName"
                LabelText="Client Name"
                Value={selectedRejectedModelRow?.ClientName}
                Disabled={true}
              />
            </GridItem>
            <GridItem xs={12}>
              <GenericNumberInput
                Name="Amount"
                LabelText="Amount"
                Value={selectedRejectedModelRow?.Amount}
                Disabled
                AllowNegative
                DecimalScale={2}
                Prefix="$"
              />
            </GridItem>
            <GridItem xs={12}>
              <GenericTextInput
                Name="TransactionRefNo"
                LabelText="Transaction Ref. No"
                Value={selectedRejectedModelRow?.TransactionReferenceNumber}
                Disabled={true}
              />
            </GridItem>
            <GridItem xs={12}>
              <GenericDateInput
                datePickerClassName="rejectedDatePicker"
                Name="SettlementDate"
                LabelText="Settlement Date"
                style={{ zIndex: 10000000, display: "absolute" }}
                ValueChanged={(name, value) => {
                  this.setState({
                    rejectedModel: {
                      SettlementDate: value,
                      ...this.state.rejectedModel,
                    },
                  });
                }}
                MaxDate={DateHelper.GetDate()}
                MinDate={new Date("01-04-2022")}
                Utc
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <GenericDialogActions>
          <GridContainer justify="flex-end">
            <Button
              size="sm"
              onClick={() => {
                if (this.isRejectedModelValid()) {
                  this.setState({
                    isLoading: false,
                    alert: (
                      <GenericAlert
                        Title={"Confirmation"}
                        Message={"You are going to reject this transaction?"}
                        Type={"info"}
                        ShowCancel={true}
                        OnConfirm={this.saveRejectedTransaction}
                        OnCancel={this.hideAlert}
                      />
                    ),
                  });
                }
              }}
            >
              SAVE
            </Button>
            <Button size="sm" onClick={this.closeRejectedDialog}>
              CANCEL
            </Button>
          </GridContainer>
        </GenericDialogActions>
      </GenericDialog>
    );
  };

  showLoading() {
    this.setState({ isLoading: true });
  }

  hideLoading() {
    this.setState({ isLoading: false });
  }

  isRejectedModelValid = () => {
    const { rejectedModel } = this.state;
    if (!rejectedModel.SettlementDate) {
      this.props.showMessage(
        "warning",
        "Please fill Settlement Date field!",
        "Settlement Date is empty"
      );
      return false;
    }

    return true;
  };

  
  saveUndoRejectedTransaction = async () => {
    const { rejectedModel } = this.state;
    this.showLoading();

    Proxy.POST(
      "/bankapi/v1.0/ClearingSettlement/UndoRejectBillPaymentTransactionForSettlement",
      rejectedModel,
      (responseData) => {
        if (!responseData.IsSucceeded) {
          console.log("Error!", responseData);
          this.hideLoading();
          this.showBasicAlert("error", responseData.ErrorDescription, "Error");
          return;
        }

        this.showBasicAlert(
          "success",
          "Operation Successfully Completed!",
          "Success"
        );

        this.GetTransactionList();
        this.closeRejectedDialog();
      },
      (error) => {
        console.log("Error!", error);
        this.hideLoading();
        this.showBasicAlert("error", "Error", error);
      }
    );
  };

  saveRejectedTransaction = async () => {
    const { rejectedModel } = this.state;
    this.showLoading();

    Proxy.POST(
      "/bankapi/v1.0/ClearingSettlement/RejectBillPaymentTransactionForSettlement",
      rejectedModel,
      (responseData) => {
        if (!responseData.IsSucceeded) {
          console.log("Error!", responseData);
          this.hideLoading();
          this.showBasicAlert("error", responseData.ErrorDescription, "Error");
          return;
        }

        this.showBasicAlert(
          "success",
          "Operation Successfully Completed!",
          "Success"
        );

        this.GetTransactionList();
        this.closeRejectedDialog();
      },
      (error) => {
        console.log("Error!", error);
        this.hideLoading();
        this.showBasicAlert("error", "Error", error);
      }
    );
  };
  openRejectedDialog() {
    this.setState({ isRejectedDialogOpen: true });
  }

  closeRejectedDialog = () => {
    this.setState({ isRejectedDialogOpen: false });
  };

  render() {
    const {
      model,
      isLoading,
      isDownloadDialogOpen,
      exportReportType,
      exportSelectedColumns,
      alert,
      legacyGridRefreshToken,
    } = this.state;
    const { menuId, ApprovalData, After } = this.props;
    return (
      <>
        {this.getRejectedDialog()}
        {alert}
        <LoadingComponent Show={isLoading} />
        <GridContainer>
          <GridItem xs={12}>
            <ButtonToolbar
              ButtonList={this.getToolbarButtons()}
              menuId={menuId}
              ApprovalData={ApprovalData}
              After={After}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={8}>
            <Card style={{ height: "92%", padding: ".5rem" }}>
              <CardHeader>
                <GenericTitle text={"Filtering Criteria"} />
              </CardHeader>
              <GridContainer>
                <GridItem xs={4}>
                  <GridContainer>
                    <GridItem xs={12}>
                      <GenericDateInput
                        Utc
                        Name="FromDate"
                        LabelText="From Date"
                        Value={
                          model.FromDate == null || undefined
                            ? ""
                            : model.FromDate
                        }
                        ValueChanged={this.handleChange}
                        MinDate={new Date("01-01-2022")}
                        MaxDate={model.ToDate || DateHelper.GetDate()}
                        IncludeTime={false}
                        Disabled={!!model?.SettlementDate}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <GenericDateInput
                        Utc
                        Name="ToDate"
                        LabelText="To Date"
                        Value={
                          model.ToDate == null || undefined ? "" : model.ToDate
                        }
                        ValueChanged={this.handleChange}
                        MinDate={model.FromDate == null ? null : model.FromDate}
                        MaxDate={DateHelper.GetDate()}
                        IncludeTime={false}
                        Disabled={!!model?.SettlementDate}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <ParameterComponent
                        Name="SettlementStatusId"
                        LabelText="Settlement Status"
                        Value={model.SettlementStatusId}
                        ParameterCode="BillPaymentSettlementStatus"
                        ValueChanged={this.handleChange}
                        All
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <GenericDateInput
                        Name="SettlementDate"
                        LabelText="Settlement Date"
                        Value={
                          model.SettlementDate
                            ? Formatter.FormatDateUtc(model.SettlementDate)
                            : ""
                        }
                        ValueChanged={this.handleChange}
                        MaxDate={DateHelper.GetDate()}
                        Disabled={!!model.FromDate || !!model.ToDate}
                        MinDate={new Date("01-04-2022")}
                        Utc
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <ParameterComponent
                        Name="IsTelpaySend"
                        LabelText="Telpay Send Status"
                        Value={model.IsTelpaySend}
                        ParameterCode="YesNo"
                        ValueChanged={this.handleChange}
                        All
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={4}>
                  <GridContainer>
                    <GridItem xs={12}>
                      <GenericSelectInput
                        Name="BillerCode"
                        LabelText="Biller Code / Name"
                        Method="POST"
                        Url="/bankapi/v1.0/Biller/GetBillerCodes"
                        Parameter={{}}
                        DataRoot="Item"
                        KeyValueMember="Code"
                        RenderItem={(d) => `${d.Code} / ${d.Name}`}
                        Value={model.BillerCode || ""}
                        ValueChanged={this.handleChange}
                        All
                        CanClear
                        Disabled={false}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <GenericSelectInput
                        Name="UniqueClientId"
                        LabelText="Client ID"
                        Method="POST"
                        Url="/bankapi/v1.0/BankCustomer/Search"
                        Parameter={{}}
                        DataRoot="Item"
                        KeyValueMember="Id"
                        RenderItem={(d) => `${d.UniqueClientId} - ${d.Name}`}
                        Value={model.UniqueClientId || ""}
                        ValueChanged={this.handleChange}
                        All
                        CanClear
                        Disabled={false}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <GenericNumberInput
                        Name="TelpayReferenceNumber"
                        LabelText="Telpay Ref. No"
                        Value={model.TelpayReferenceNumber || ""}
                        ValueChanged={this.handleChange}
                        NoFormatting={true}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <GenericNumberInput
                        NoFormatting={true}
                        Name="TransactionReferenceNumber"
                        LabelText="Transaction Ref. No"
                        Value={model.TransactionReferenceNumber || ""}
                        ValueChanged={this.handleChange}
                        Disabled={false}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <ParameterComponent
                        Name="TransactionStatusId"
                        Value={model.TransactionStatusId || ""}
                        LabelText="Transaction Status"
                        ParameterCode="BillStatusType"
                        ValueChanged={this.handleChange}
                        All
                        CanClear
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </Card>
          </GridItem>
          <GridItem xs={4}>{this.dataRangeResultsCard()}</GridItem>
        </GridContainer>
        <GridContainer>
          <Card className="no-radius">
            <CardBody>
              <GridItem xs={12}>
                <GridItem>
                  <h5>
                    <b>Transaction List</b>
                  </h5>
                  <GenericGrid
                    key={"trxGrid" + legacyGridRefreshToken}
                    ShowPagination={true}
                    PageSize={20}
                    Data={this.state.transactionList}
                    Columns={this.transactionDetailColumns}
                  />
                </GridItem>
              </GridItem>
            </CardBody>
          </Card>
        </GridContainer>
        {/* {this.getTransactionDetailDialog()} */}
        <GenericDialog open={isDownloadDialogOpen} fullWidth={true}>
          <DialogTitle>
            <GenericLabel
              Text="Select Report Type"
              FontSize="20px"
              Bold={true}
            />
          </DialogTitle>
          <DialogContent style={{ height: 300 }}>
            <GenericRadioInput
              Name="exportReportType"
              LabelText="Report Type"
              IsStatic={true}
              StaticData={[
                { Value: "PDF", Text: "PDF" },
                { Value: "CSV", Text: "CSV" },
                { Value: "EXCEL", Text: "EXCEL" },
              ]}
              KeyValueMember="Value"
              TextValueMember="Text"
              Value={exportReportType}
              ValueChanged={this.setValueToState}
            />
            <GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
            <GridItem style={{ marginTop: 20 }}>
              <Select
                value={exportSelectedColumns}
                onChange={this.setExportSelection}
                isMulti
                name="exportSelectedColumns"
                getOptionLabel={(d) => d.title}
                options={this.exportColumns}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </GridItem>
          </DialogContent>
          <GenericDialogActions>
            <Button size="sm" onClick={this.onExport}>
              Download
            </Button>
            <Button size="sm" onClick={this.closeExportDialog}>
              Cancel
            </Button>
          </GenericDialogActions>
        </GenericDialog>
      </>
    );
  }
}

BillPaymentSettlement.propTypes = {
  classes: PropTypes.object,
  handleOperationType: PropTypes.func,
  showQuestionMessage: func,
  setAppLeftTitle: func,
  setAppCenterTitle: func,
  Disabled: bool,
};

export default withArtifex(BillPaymentSettlement, {});
