import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericCheckInput, GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericSelectInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { SwitchNetworkType } from "views/Constants/Constant";
import MastercardClearingDetail from "./MastercardClearingDetail";

class IncomingBatchCorrection extends Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined,
			IsMainCard: true,

		};

		this.state = {
			detailModel: {},
			model: {},
			list: [],
			PostRequest: [],
			isLoading: false,
			isDialogOpen: false
		}

		this.Columns = [];
		this.emptyObject = {};

		this.parameterNetworkId = { ParameterCode: "SwitchNetworkType" };
		this.NetworkType = {};


		this.maxLength26 = { maxLength: 26 };
		this.maxLength19 = { maxLength: 19 };
		this.maxLength12 = { maxLength: 12 };

		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined
		};

		this.renderItemUniqueClient = {};

		this.parameterProduct = {};


	}
	GetColumns = () => {
		const { list } = this.state;
		this.Columns = [
			{
				Header: "",
				Cell: row => (
					list.length > 0 &&
					<GenericCheckInput
						Name="IsPostAvailable"
						Value={list[row.index].IsPostAvailable}
						ValueChanged={(name, value) => this.CellValueChanged(name, value, row.index, true)}
					/>
				)
			},
			{
				Header: "Clearing Date",
				accessor: "ClearingDate"
			},
			{
				Header: "Issuer Acquirer Flag",
				accessor: "IssuerAcqueirerFlag"
			},
			{
				Header: "Card Number",
				accessor: "CardNumber"
			},
			{
				Header: "Original Amount",
				accessor: "OrginalAmount"
			},
			{
				Header: "Settlement Amount",
				accessor: "SettlementAmount"
			},
			{
				Header: "RRN",
				accessor: "RRN"
			},
			{
				Header: "Processing Code",
				accessor: "ProcessingCode"
			},
			{
				Header: "Direction",
				accessor: "Direction"
			},
			{
				Header: "Clearing Date",
				accessor: "ClearingDate"
			},
			{
				Header: "Validation Status",
				accessor: "ValidationStatus"
			},
			{
				Header: "Arn",
				accessor: "ARN"
			},

		];

		this.forceUpdate();
	}
	CellValueChanged = (name, value, index, isRole) => {

		const { PostRequest,  list } = this.state;

		list[index].IsPostAvailable = value;
		var Id = list[index].Id;
		if (value) {
			var removeIndex = PostRequest.findIndex(x => x.Id == Id);
			if (removeIndex == -1) {
				PostRequest.push({ "Id": Id });
			}
		} else {
			var removeIndex = PostRequest.findIndex(x => x.Id == Id);
			if (removeIndex != -1)
				PostRequest.splice(removeIndex, 1);
		}
		this.setState({ PostRequest, value, list });
		this.GetColumns();
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Incoming Batch  Correct");
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");

		this.GetColumns();
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		if (name == "NewworkId") {
			this.NetworkType = data.ParameterValue;
		}
		model[name] = newValue;
		this.setState({ model });
	}

	HandleClear = () => {
		this.setState({ model: { ...this.initialModel }, isEdit: false, index: -1 });
	}



	Validate = () => {
		const { model } = this.state;

		if (!model.NetworkId) {
			this.props.showMessage("warning", "Network not selected", "Select the network type to continue.");
			return false;
		}
		if (!model.IncomingValidationCodeId) {
			this.props.showMessage("warning", "Validation Code not selected", "Select the Validation Code.");
			return false;
		}

		return true;
	}
	HandleSearch = () => {

		if (!this.Validate()) {
			return false;
		}
		const model = { ...this.state.model };
		this.setState({ isLoading: true });

		if (this.NetworkType == SwitchNetworkType.MasterCard)
			this.Url = "/prepaidapi/v1.0/MastercardClearing/GetAllMastercardTransaction";
		else
			this.Url = "/prepaidapi/v1.0/VisaClearingTransaction/GetAllVisaTransaction";

		Proxy.POST
			(
				this.Url,
				model,
				(responseData) => {
					this.setState({ isLoading: false });
					if (!responseData.IsSucceeded) {
						this.props.showMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					if (responseData.Item !== null) {
						this.setState({
							list: responseData.Item, PostRequest: []
						});

						this.GetColumns();
					}

				},
				(error) => {
					this.setState({ isLoading: false });
					this.props.showMessage("error", "Error", error);
				}
			)

	}

	HandleSubmit = () => {


		if (!this.Validate()) {
			return false;
		}


		if (this.NetworkType == SwitchNetworkType.MasterCard)
			this.Url = "/prepaidapi/v1.0/MastercardClearing/IncomingBatchCorrect";
		else
			this.Url = "/prepaidapi/v1.0/VisaClearingTransaction/IncomingBatchCorrect";

		const { model } = this.state;
		this.setState({ isLoading: true });
		Proxy.POST
			(
				this.Url,
				{
					"NetworkId": model.NetworkId,
					"PostRequest": this.state.PostRequest,
					"IncomingValidationCodeId": model.IncomingValidationCodeId,
					"ClearingDate": model.ClearingDate
				},
				(responseData) => {
					this.setState({ isLoading: false });
					if (!responseData.IsSucceeded) {
						this.props.showMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					if (responseData.Item !== null) {

						this.HandleSearch();
						this.GetColumns();
						this.HandleClear();
					}

				},
				(error) => {
					this.setState({ isLoading: false });
					this.props.showMessage("error", "Error", error);
				}
			)

	}



	RowSelected = (index) => {
		let { model, list } = this.state;
		model = list[index];
		if (list[index].CardRequestId) {
			model.Id = list[index].Id;
		}
		this.setState({ isEdit: true, model, index });
	}

	DetailsModelChange = (modelFunction) => {
		this.setState(function (state, props) {
			var model = state.detailModel || {};

			if (modelFunction)
				model = modelFunction(model);

			return { detailModel: model };
		});
	}

	DialogModelChange = (modelFunction) => {
		this.setState(function (state, props) {
			var model = state.initialModel || {};

			if (modelFunction)
				model = modelFunction(model);

			return { initialModel: model };
		});
	}
	CancelSelectCallback = () => {
		this.setState({
			isDialogOpen: false
		});
	};
	HandleClickUpdate = () => {
		this.setState({ isLoading: true });
		Proxy.POST("/prepaidapi/v1.0/MastercardClearing/MastercardClearingUpdateDetail",
			this.state.detailModel,
			responseData => {
				this.setState({ isLoading: false });
				if (responseData.IsSucceeded != false) {
					this.setState({
						isDialogOpen: false
					});
				} else {
					this.setState({ dialogData: [] });
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			});
	}


	render() {
		const {  Disabled } = this.props;
		const { alert, model, list, isLoading, index } = this.state;

		return (
			<div>

				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
					{ Code: "Submit", OnClick: this.HandleSubmit, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<GenericDialog open={this.state.isDialogOpen} onBackdropClick={this.CancelSelectCallback} maxWidth="md" fullWidth keepMounted={true}>
					<DialogTitle><GenericLabel FontSize="16px" Text="Detail" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ marginTop: 8 }}>
						<MastercardClearingDetail
							model={this.state.detailModel}
							initialModel={this.state.initialModel}
							onModelChange={this.DetailsModelChange}
						/>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.HandleClickUpdate}>Update</Button>
						<Button size="sm" onClick={this.CancelSelectCallback}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>


				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Panel">
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer>
												<GridItem xs={3}>
													<GenericSelectInput
														Name="NetworkId"
														LabelText="Network Type"
														Method="POST"
														Url="/coreapi/v1.0/Parameter/Search"
														Parameter={this.parameterNetworkId}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="ParameterDesc"
														Value={model.NetworkId}
														ValueChanged={this.HandleChange} />
												</GridItem>
												<GridItem xs={3}>
													<GenericDateInput
														Name="ClearingDate"
														LabelText="Clearing Date"
														Value={model.ClearingDate}
														ValueChanged={this.HandleChange}
														Disabled={false} />
												</GridItem>
												<GridItem xs={3}>
													<ParameterComponent
														Name="IncomingValidationCodeId"
														LabelText="Validation Code"
														ParameterCode="IncomingValidationCode"
														Value={model.IncomingValidationCodeId}
														ValueChanged={this.HandleChange}
														Disabled={false}
													/>
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={"Incoming File Correct List"} />
							</CardHeader>
							<CardBody>
								<GridContainer spacing={16}>
									<GridItem xs={12}>
										<GenericGrid
											Data={list}
											Columns={this.Columns}
											//RowSelected={this.RowSelected}
											SelectedIndex={index}
										/>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

IncomingBatchCorrection.propTypes = {
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool
};

export default withArtifex(IncomingBatchCorrection, {});