const drawerWidth = 280;
const externalContainerStyle = (theme) => {
	return ({
		container: {
			margin: "auto 25px auto 25px",
			zIndex: "10",
			[theme.breakpoints.down("md")]: {
				margin: "auto 0px auto 0px",
				position: "absolute",
				left: 0,
				right: 0,
				height: "100vh",
				top: 0
			}
		},
		containerHalf: {
			margin: "auto 25px auto 250px",
			[theme.breakpoints.down("md")]: {
				margin: "auto 0px auto 0px",
			}
		},
		header: {
			position: "absolute",
			top: 0,
			left: 0,
			right: 0,
			height: 100,
			backgroundColor: "#457CF7",
			color: "white",
			display: "flex",
			alignItems: "center",
			justifyItems: "center",
			[theme.breakpoints.down("xs")]: {
				height: 50,
			}
		},
		headerMenu: {
			display: "flex",
			alignItems: "center",
			height: "100%"
		},
		closeMenuButton: {
			marginRight: "auto",
			marginLeft: 0,
		},
		flex: {
			display: "flex"
		},

		headerTitle: {
			fontFamily: "Roboto, sans-serif",
			textAlign: "center",
			fontSize: 28,
			fontWeight: 600,
			margin: "0px auto 0px auto",
			[theme.breakpoints.down("sm")]: {
				fontSize: "calc(14px + 6 * ((100vw - 320px) / 680))",
			},
			[theme.breakpoints.down("xs")]: {
				textAlign: "right",
				margin: "0 5px 0 auto",
				marginRight: 5
			}

		},
		headerSubtitle: {
			fontFamily: "Roboto, sans-serif",
			textAlign: "center",
			fontSize: 22,
			fontWeight: 400,
			margin: "0px auto 0px auto",
			[theme.breakpoints.down("sm")]: {
				fontSize: "calc(14px + 6 * ((100vw - 320px) / 680))",
			},
			[theme.breakpoints.down("xs")]: {
				textAlign: "right",
				margin: "0 5px 0 auto",
				marginRight: 5
			}

		},
		title: {

			fontWeight: 600,
			marginLeft: 22,
			[theme.breakpoints.down("sm")]: {
				marginLeft: 5,
			},
			"& > h3": {
				fontSize: 18,
				fontWeight: 600,
			},
			"& > span": {
				fontSize: 11,
				fontWeight: 600,
				fontFamily: "Roboto,sans-serif"
			}
		},
		drawer: {
			width: drawerWidth
		},
		body: {
			marginTop: 120,
			marginBottom: 30,
			width: "100%",
			overflowX: "hidden",
			[theme.breakpoints.down("xs")]: {
				marginTop: 60,
			},
		},
		menuButton: {
			//marginRight: theme.spacing.unit * (2),
			margin: "35px -20px 36px 0px",
			zIndex: 10,
			[theme.breakpoints.up("md")]: {
				display: "none",
			},
		},
		media: {
			width: 200,
			height: "100%",
			marginTop: "8%",
			marginLeft: 18,
			backgroundSize: "auto",
			[theme.breakpoints.up("md")]: {
				marginLeft: 30,
			},
			[theme.breakpoints.down("sm")]: {
				width: 150
			},
			[theme.breakpoints.down("xs")]: {
				width: 120,
				marginTop: "6%"
			}

		},
		footer: {
			position: "fixed",
			bottom: 0,
			left: 0,
			right: 0,
			height: 30,
			[theme.breakpoints.down("sm")]: {
				height: 22,
			},
			backgroundColor: "rgb(66, 66, 66)",
			color: "white",
			display: "flex",
			"& > span": {
				textAlign: "right",
				margin: "auto 10px auto auto",
				fontWeight: 500
			}
		},
		mediaForLogo: {
			width: 200,
			height: "100%",
			marginTop: "8%",
			 marginLeft: 18,
			backgroundSize: "auto",
			[theme.breakpoints.up("md")]: {
				marginLeft: 30,
			},
			[theme.breakpoints.down("sm")]: {
				width: 150
			},
			[theme.breakpoints.down("xs")]: {
				width: 120,
				marginTop: "6%"
			},
			margin: "-70px auto 0",
			borderRadius: "4px",
			boxShadow: "0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)",
			position: "relative",
			zIndex: 1000,
			backgroundColor: "rgb(255, 255, 255)"
		}
	});
};

export default externalContainerStyle;