import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import PropTypes from "prop-types";
import React from "react";
import { GenericAlert, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";

class LoanApplicationSecurityInformation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			alert: null
		};
		this.LOSDepositInParameterCode = { ParameterCode: "LOSDepositIn" };
		this.YesNoParameterCode = { ParameterCode: "LOSYesNo" };
		this.ParameterPaymentRate = { ParameterCode: "LOSPaymentRate" };
		this.LOSSecurityInfoTypeParameterCode = { ParameterCode: "LOSSecurityInfoType" };

		this.LengthDocNumber = { maxLength: 15 };
		this.LengthYear = { maxLength: 4 };
	}

	componentDidMount() {
		if (this.props.setAppLeftTitle)
			this.props.setAppLeftTitle("Documents");
	}


	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}


	ClearData = () => {
		var model = { Id: 0 };
		this.setState({ otherFinancialModel: model });
	}
	
	DeleteModel = (model) => {

	}

	showQuestionMessage = (message, model) => {
		this.setState({
			alert: <GenericAlert Title="Delete" Message={message} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.DeleteModel(model)} />
		});
		this.setState({ isLoading: false });
	}
	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}
	handleChange = (name, newValue, data) => {
		this.setState(function (state, props) {
			var model = state.otherFinancialModel || {};
			model[name] = newValue;
			return { otherFinancialModel: model };
		});
	}
	render() {
		const { Disabled, ApplicationModel } = this.props;

		return (
			<GridContainer spacing={16}>
				{this.state.alert}
				

				<LoadingComponent Show={this.state.isLoading}/>

				<GridItem xs={4}>
					<GridItem xs={12}>
						<GenericSelectInput
							LabelMd={7}
							Name="SecurityInfoTypeId"
							LabelText="Type"
							Method="POST"
							Url="/coreapi/v1.0/Parameter/Search"
							Parameter={this.LOSSecurityInfoTypeParameterCode}
							DataRoot="Item"
							KeyValueMember="Id"
							TextValueMember="ParameterDesc"
							Value={ApplicationModel.SecurityInfoTypeId}
							ValueChanged={this.props.HandleChange}
							Disabled={Disabled}
						/>
					</GridItem>
					{
						ApplicationModel.OtherSecurityInfoEnable &&
						<GridItem xs={12}>
							<GenericTextInput
								Value={ApplicationModel.SecurityInformationOtherDesc}
								Name={"SecurityInformationOtherDesc"}
								LabelMd={7}
								LabelText="Other Info Type"
								ValueChanged={this.props.HandleChange}
							>
							</GenericTextInput>
						</GridItem>
					}

					<GridItem xs={12}>
						<GenericTextInput
							Value={ApplicationModel.SecurityNo}
							Name={"SecurityNo"}
							LabelMd={7}
							LabelText="No. of Loan With This Security"
							ValueChanged={this.props.HandleChange}
						>
						</GenericTextInput>
					</GridItem>
					<GridItem xs={12}>
						<GenericTextInput
							Value={ApplicationModel.ReminedAmountFromLoan}
							Name={"ReminedAmountFromLoan"}
							LabelMd={7}
							LabelText="Remined Amount from Loan (If it is second)"
							ValueChanged={this.props.HandleChange}
						>
						</GenericTextInput>
					</GridItem></GridItem>
				<GridItem xs={4}>

					<GridItem xs={12}>
						<GenericTextInput
							Value={ApplicationModel.SecurityValue}
							Name={"SecurityValue"}
							LabelMd={7}
							LabelText="Security Value"
							ValueChanged={this.props.HandleChange}
						>
						</GenericTextInput>
					</GridItem>
					<GridItem xs={12}>
						<GenericTextInput
							Value={ApplicationModel.SerialDocumentNumber}
							Name={"SerialDocumentNumber"}
							LabelMd={7}
							inputProps={this.LengthDocNumber}
							LabelText="Serial Number, VIN or Document Number"
							ValueChanged={this.props.HandleChange}
						>
						</GenericTextInput>
					</GridItem>
					<GridItem xs={12}>
						<GenericTextInput
							Value={ApplicationModel.ProductionYear}
							Name={"ProductionYear"}
							LabelMd={7}
							inputProps={this.LengthYear}
							LabelText="Year of Production"
							ValueChanged={this.props.HandleChange}
						>
						</GenericTextInput>
					</GridItem>

				</GridItem>
			</GridContainer >
		);
	}
}

LoanApplicationSecurityInformation.propTypes = {
	classes: PropTypes.object,
	ApplicationModel: PropTypes.object,
	HandleChange: PropTypes.func
};
export default withArtifex(LoanApplicationSecurityInformation, {});