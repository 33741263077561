import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import {
	GenericDateInput,
	GenericExpansionPanel,
	GenericGrid,
	GenericSelectInput,
	GenericTitle,
	GenericCheckInput,
	GenericAlert,
	GenericDialog, GenericDialogActions,GenericRadioInput,GenericLabel
} from "views/Components/Generic";
import { GridColumnType, BankingAccountTypes, ProgramCodes, ClientType } from "views/Constants/Constant";
import CardHeader from "components/Card/CardHeader";
import GridButton from "views/Components/GridButton.jsx";
import { EftIcon, ETransferIcon } from "core/Icons";
import RouteHelper from "core/RouteHelper";
import Formatter from "core/Formatter";
import { Proxy } from "core";
import LoadingComponent from "views/Components/LoadingComponent";
import Button from "components/CustomButtons/Button.jsx";
import { DialogContent, DialogTitle } from "@material-ui/core";
import Select from "react-select";
import DateHelper from "core/DateHelper";

class FromToAccountingTransaction extends React.Component {
	constructor(props) {
		super(props);

		this.columns = [
			{
				Header: "Wallet Id",
				accessor: "AccountId",
			},
			{
				Header: "Transaction Date",
				accessor: "TransactionDate",
				type: GridColumnType.DateTimeUtc,
			},
			{
				Header: "Merchant Name",
				accessor: "MerchantName",
			},
			{
				Header: "Settlement Status",
				accessor: "SettlementStatus",
			},
			{
				Header: "Channel Type",
				accessor: "ChannelType",
			},
			{
				Header: "Currency Code",
				accessor: "CurrencyCode",
			},
			{
				Header: "Currency Symbol",
				accessor: "CurrencySymbol",
			},
			{
				Header: "Wallet Balance",
				accessor: "WalletBalance",
				type: GridColumnType.Money,
			},
			{
				Header: "Amount",
				accessor: "Amount",
				type: GridColumnType.Money,
			},
			{
				Header: "Wallet Name",
				accessor: "AccountName",
			},
			{
				Header: "Source Wallet Number",
				accessor: "SourceAccountNumber",
			},
			{
				Header: "Destination Wallet Number",
				accessor: "DestinationAccountNumber",
			},
			{
				Header: "Sender Bank",
				accessor: "SenderBank",
			},
			{
				Header: "Sender Name",
				accessor: "SenderName",
			},
			{
				Header: "Sender Short Name",
				accessor: "SenderShortName",
			},
			{
				Header: "Reference Number",
				accessor: "ReferenceNumber",
			},
			{
				Header: "Interact Reference Number",
				accessor: "InteractReferenceNumber",
			},
			{
				Header: "Description",
				accessor: "Description",
			},
		];

		this.trxTable = React.createRef();

		this.isBackoffice = ClientHelper.IsBackOffice();
		this.uniqueClientId = ClientHelper.GetClientRowId();

		this.emptyObject = {};
		this.parameterUniqueClientId = {};

		this.sortedFullName = { Member: "FullName" };

		this.validateProperties = [
			{ ToDate: "End Date" },
			{ FromDate: "Start Date" },
		];

		this.state = {
			model: {
				UniqueClientId: this.uniqueClientId,
			},
			searchModel: {
				IsRequiredUniqueClientId: true,
				IsRequiredDate: true,
				IsRequiredStatementPeriod: true,
				IsInvalidStatementPeriod: false,
				IsInvalidFromDate: false,
				IsInvalidToDate: false
			},
			customerAccountList: [],
			checkedAccounts: [],
			isLoading: false
		};
	}

	componentDidMount() {
		const { setAppLeftTitle, setAppCenterTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("WALLET");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("From - To Accounting Transactions Report");
		}
	}

	ValueChanged = (name, value) => {
		this.setState({ [name]: value });
	}

	GetParameterUniqueClientId = () => {
		const { model } = this.state;

		this.parameterUniqueClientId.UniqueClientId = model.UniqueClientId;

		return this.parameterUniqueClientId;
	};

	Search = async () => {
		const { model } = this.state;
		const { ExecuteApiPost } = this.props;

		if (!this.Validate())
			return;

		var ids = [];
		this.state.checkedAccounts.forEach((element) => {
			ids.push(element.Id);
		});
		model.AccountIds = ids;

		var result = await ExecuteApiPost(
			"/bankapi/v1.0/Account/GetFromToAccountingTransactions",
			model,
			model,
			this.validateProperties
		);

		this.setState({ list: result || [] });
	};

	Validate() {
		const searchModel = { ...this.state.searchModel };

		var messages = [];

		if (searchModel.FromDate && searchModel.ToDate) {
			var diffMonths = searchModel.ToDate.diff(searchModel.FromDate, "days");
			if (diffMonths < 0) {
				messages.push("End Date must be later than Start Date");
			}

			if (diffMonths > 3) {
				messages.push("Difference between Start Date and End Date cannot be longer than 3 days");
			}
		}


		if (messages.length > 0) {
			this.ShowMessage("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		return true;
	}

	Clear = () => {
		this.setState({ model: { UniqueClientId: this.uniqueClientId }, list: [] });
	};

	HandleGetList = () => {
		this.setState(state => ({ trxGridToken: !state.trxGridToken }));
	}

	GetCustomerBankAccounts = (status) => {
		const temp = { ...this.state.searchModel };

		temp.SettlementAccountStatus = false;

		if ((temp.UniqueClientId == null || temp.UniqueClientId == "")) return;
		else if (status) {
			this.GetAccountList(temp);
			return;
		} else if (status == undefined) return;

		this.GetAccountList(temp);
	}

	GetAccountList = (temp) => {
		this.setState({ isLoading: true });

		Proxy.POST(
			"/bankapi/v1.0/Account/ClientAccountActivites",
			temp,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error !", responseData.ErrorDescription);
					return;
				} else {
					this.setState({ customerAccountList: responseData.Item || [] });
				}
				this.setState({ isLoading: false });

			},
			error => {
				this.ShowMessage("error", "Error", error);
				this.setState({ isLoading: false });

			}
		);
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}

	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}

	RenderAccountTypeParameter = (data) => {
		const parameter = { ...this.state.ClientTypeParameter };
		if (data == null) {
			return "";
		}
		if (data.AccountCode == null || data.AccountCode == "") {
			return data.AccountType;
		}
		if (data.AccountCode != BankingAccountTypes.PrimaryAccount) {
			return data.AccountType;
		}
		if (data.ProgramCode == null || data.ProgramCode == "") {
			return data.AccountType;
		}
		if (data.ProgramCode != ProgramCodes.Bank_Programs_Bank_Account) {
			return data.AccountType;
		}

		if (parameter.ParameterValue == ClientType.Corporate || parameter.ParameterValue == ClientType.SalesAgentCorporate) {
			return "Corporate Wallet";
		} else if (parameter.ParameterValue == ClientType.Personal || parameter.ParameterValue == ClientType.SalesAgentPersonal) {
			return "Individual Wallet";
		} else {
			return data.AccountType;
		}

	}

	handleChangeSearchModel = (name, newValue) => {
		this.setState(function (state, props) {
			var model = state.searchModel || {};

			model[name] = newValue;
			if (name == "CheckAllAccounts" && newValue == true) {
				const list = this.state.customerAccountList;
				this.setState({ checkedAccounts: list });
			} else if (name == "CheckAllAccounts" && newValue == false) {
				this.setState({ checkedAccounts: [] });
			}

			if (name == "UniqueClientId" && newValue !== undefined && newValue != null) {
				this.GetCustomerBankAccounts(true);
				model[name] = newValue;
				this.setState({
					customerAccountList: [], checkedAccounts: [], model
				});

			}
			else if (name == "UniqueClientId" && (newValue == undefined || newValue == null)) {
				this.setState({
					checkedAccounts: [], customerAccountList: []
				});
			}
			if (name == "Amount") {
				model.MinAmount = newValue;
				model.MaxAmount = newValue;
			}

			if (name == "ReferenceNumber") {

				let hasReferenceNumberSearch = model["ReferenceNumber"]?.length > 0;

				model.IsRequiredStatementPeriod = !hasReferenceNumberSearch;
				model.IsRequiredDate = !hasReferenceNumberSearch;
				model.IsRequiredUniqueClientId = !hasReferenceNumberSearch;
			}

			if (name == "StatementPeriod" || name == "ToDate" || name == "FromDate") {

				if (model.StatementPeriod == undefined || model.StatementPeriod == null || model.StatementPeriod == "") {
					model.StatementControl = false;
					model.IsRequiredStatementPeriod = true;
					model.IsRequiredDate = true;
					this.setState({ searchModel: model });

					if ((model.ToDate == undefined || model.ToDate == "" || model.ToDate == null) && (model.FromDate == undefined || model.FromDate == "" || model.FromDate == null)) {

						model.IsInvalidFromDate = false;
						model.IsInvalidToDate = false;

						model.DateControl = false;
						this.setState({ searchModel: model });
					}
					else {

						model.StatementControl = true;
						model.DateControl = false;
						model.IsRequiredStatementPeriod = false;

					}

				} else {
					model.DateControl = true;
					model.IsInvalidFromDate = false;
					model.IsInvalidToDate = false;
					model.IsRequiredDate = false;
					this.setState({ searchModel: model });
				}
			}


			return { searchModel: model };
		});
	}

	HandleCheckCustomerAccounts = (e, x) => {

		const { checkedAccounts } = this.state;
		var temp = true;

		const items = checkedAccounts.map((item, key) => {
			if (item.Id == x.Id) {
				checkedAccounts.splice(key, 1);
				temp = false;
			}
		}
		);

		if (checkedAccounts.length == 0 && temp) { checkedAccounts.push(x); console.log("if") }
		else if (temp) { checkedAccounts.push(x); console.log("else") }

		this.setState({ checkedAccounts });

	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={onConfirm} />
		});
	}

	ExportClick = () => {
		var trxColumns = [];
		this.trxTable.current.props.Columns.forEach(c => {
			if (c.accessor != "Actions" && c.accessor != "ED" && (c.show == undefined || c.show)) {
				trxColumns.push({ value: c.accessor, title: c.Header, columntype: c.ColumnType, type: c.type });
			}
		}, this);

		this.setState({ isDownloadDialogOpen: true, GridKeyValueList: trxColumns, ExportSelectedColumns: trxColumns });
	}

	ExcelDownload = () => {
		this.setState({ isLoading: true})
		if (!this.state.ExportReportType) {
			this.ShowMessage("warning", "Export Report Type not selected", "Select Export Report Type to continue");
			console.log("girdiResponseData.1");
			return;
		}

		if (this.state.ExportReportType == "PDF" && this.state.ExportSelectedColumns.length > 15) {
			this.ShowMessage("warning", "PDF document cannot exceed 15 columns", "PDF document cannot exceed 15 columns");
			console.log("girdiResponseData.2");
			return;
		}

		if (!this.Validate()){
			console.log("girdiResponseData.3");
			return;
		}

		const temp = { ...this.state.searchModel };

		var ids = [];
		this.ShowConfirmMessage("question", "Long Process Warning", "This process may take a while. Continue ?", () => {
			this.hideAlert();
			this.state.checkedAccounts.forEach(element => {
				ids.push(element.Id);
				if (element.SubAccountList != null) {
					element.SubAccountList.forEach(x => {
						ids.push(x);
					});
				}
			});
			temp.AccountIds = ids;
			var jsonColList = [];

			var trxColumns = {};
			this.state.ExportSelectedColumns.map(c => {
				trxColumns[c.value] = { value: c.value, title: c.title, columntype: c.columntype, type: c.type };
			});
			jsonColList.push(trxColumns);

			var mytitle = "";
			mytitle = "From - To Accounting Transactions Report";

			temp.jsonFile = {
				colmns: jsonColList,
				format: this.state.ExportReportType,
				title: mytitle
			};

			this.setState({ isLoading: true });
			Proxy.DownloadGeneratedFile(
				"/bankapi/v1.0/Account/FromToAccountingTransactionsExportDownload",
				temp,
				responseData => {
					this.setState({ isLoading: false });
					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					console.log("girdiResponseData.");
				},
				errorMessage => {
					this.setState({ isLoading: false });
					console.log("girdiError.");
					this.ShowMessage("error", "Error", errorMessage);
				},
				this.ShowMessage
			);
			this.setState({ isDownloadDialogOpen: false})
		});

	}

	render() {
		const { Disabled } = this.props;
		const { model, list, customerAccountList, searchModel, checkedAccounts, alert } = this.state;



		var GridDataAccounts = customerAccountList == null ? [] : customerAccountList.map(d => {
			return {
				ED: (
					<div style={{ marginTop: 1 }}>
						{(d.AccountCode == BankingAccountTypes.PrimaryAccount) &&
							<GridButton
								tooltip="To Linked Wallet"
								Icon={EftIcon}
								Disabled={Disabled}
								OnClick={() => { RouteHelper.Push(this.props.history, "/TransferToLinkedAccount", "TransferToLinkedAccount_ID", 0); }} />
						}
						{(d.AccountCode == BankingAccountTypes.PrimaryAccount) &&
							<GridButton
								tooltip="E-Transfer"
								Icon={ETransferIcon}
								Disabled={Disabled}
								OnClick={() => { RouteHelper.Push(this.props.history, "/EtransferFromAccount", "EtransferFromAccount_ID", 0); }} />
						}
					</div>
				),
				AP: (
					<div>
						<GenericCheckInput
							Value={checkedAccounts.filter(c => c.Id == d.Id).length > 0}
							ValueChanged={(value) => {
								this.HandleCheckCustomerAccounts(value, d);
							}}
						/>
					</div>
				),
				Id: d.Id,
				ProgramId: d.ProgramId,
				CustomerBankAccountId: d.CustomerBankAccountId,
				AccountShortName: d.AccountShortName,
				BankAccountId: d.BankAccountId,
				UniqueClientId: d.UniqueClientId,
				Balance: d.Balance,
				AccountName: d.AccountName,
				PendingBalance: d.PendingBalance,
				AccountNumber: d.AccountNumber,
				AccountNumberStr: d.AccountNumberStr,
				BranchNumber: d.BranchNumber,
				BankNumber: d.BankNumber,
				AccountTypeId: d.AccountTypeId,
				AccountType: d.AccountType,
				AccountType4: d.AccountType4,
				AccountType6: d.AccountType6,
				AccountCode: d.AccountCode,
				ProgramCode: d.ProgramCode,
				ProgramName: d.ProgramName,
				CurrencyCode: d.CurrencyCode,
				CurrencyDesc: d.CurrencyDesc,
				CurrencySymbol: d.CurrencySymbol,
				AmountDisplay: d.AmountDisplay,
				IsChequingAccount: d.IsChequingAccount,
				SubAccountList: d.SubAccountList
			};
		});

		return (
			<div>
				<LoadingComponent Show={this.state.isLoading} />
				{alert}
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						},
						{ Code: "Export", OnClick: () => this.ExportClick(), Disabled: Disabled }
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After}
				/>
				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="From - To Accounting Transaction Report">
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelMd={4}
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={{}}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
												Value={searchModel.UniqueClientId || ""}
												ValueChanged={this.handleChangeSearchModel}
												CanClear
												Required={searchModel.IsRequiredUniqueClientId}
											/>
										</GridItem>
										<GridItem xs={4}><GenericDateInput
											Utc
											Name="FromDate"
											LabelText="Start Date"
											Value={searchModel.FromDate}
											ValueChanged={this.handleChangeSearchModel}
											Required={true}
										/></GridItem>
										<GridItem xs={4}><GenericDateInput
											Utc
											Name="ToDate"
											LabelText="End Date"
											Value={searchModel.ToDate}
											ValueChanged={this.handleChangeSearchModel}
											Required={true}
										/></GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={"Wallets"} />
							</CardHeader>
							<CardBody>
								<GridItem xs={12}>
									<GridItem>
										<GenericGrid
											Data={GridDataAccounts}
											HideButton={true}
											Columns={[
												{
													width: 100,
													Header: "Actions",
													accessor: "ED",
													sortable: false,
													filterable: false,
													show: this.isBackoffice == true ? false : true
												},
												{
													Header: "Select",
													accessor: "AP",
													sortable: false,
													filterable: false,
													width: 60,
													Footer: (
														<GenericCheckInput Grid={true} Name="CheckAllAccounts" LabelText="" Value={searchModel.CheckAllAccounts || false} ValueChanged={this.handleChangeSearchModel} />
													)
												},
												{
													Header: "Wallet Name",
													accessor: "AccountName",
													Footer: (
														<span>
															<strong>All Wallets</strong>{" "}
														</span>
													)
												},
												{
													Header: "Wallet Type",
													accessor: "AccountType",
													Cell: row => (
														<div>{this.RenderAccountTypeParameter(row.original)} </div>
													),
													Footer: (
														<span>
															<strong>Total{"    "} {customerAccountList == null ? 0 : customerAccountList.length}</strong>{" "}
														</span>
													)
												},
												{
													Header: "Program Name",
													accessor: "ProgramName",
												},
												{
													Header: "Wallet Number",
													accessor: "AccountNumber"
												},
												{
													Header: "Chequing Wallet",
													accessor: "IsChequingAccount",
													show: (this.isBackoffice == true)
												},
												{
													Header: "Currency",
													accessor: "CurrencyCode"
												},
												{
													Header: "Balance",
													accessor: "Balance",
													Cell: row => (
														<div>{Formatter.FormatMoneySpecial(row.original.CurrencySymbol, row.value)}</div>
													)
												},
												{
													Header: "Pending Balance",
													accessor: "PendingBalance",
													Cell: row => (
														<div>{Formatter.FormatMoneySpecial(row.original.CurrencySymbol, row.value)}</div>
													)
												}
											]}
											ProgramCode={ProgramCodes.Bank_Programs_Bank_Account} />
									</GridItem>
								</GridItem>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GenericGrid
									Data={list}
									Columns={this.columns}
									ShowPagination={true}
									IsSorted={true}
									PageSize={100}
									Sorted={[{ id: "TransactionDate", desc: true }]}
									ref={this.trxTable}
									key={"trxGrid" + this.state.trxGridToken}
									PrepareRequest={() => {
										const temp = { ...this.state.searchModel };
										var ids = [];
										this.state.checkedAccounts.forEach(element => {
											ids.push(element.Id);
											if (element.SubAccountList != null) {
												element.SubAccountList.forEach(x => {
													ids.push(x);
												});
											}
										});
										temp.AccountIds = ids;

										return temp;
									}}
									RequestUrl="/bankapi/v1.0/Account/GetFromToAccountingTransactions"
									RequestMethod="POST"
								/>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

				<GenericDialog open={this.state.isDownloadDialogOpen} fullWidth={true}>
					<DialogTitle>
						<GenericLabel Text="Select Report Type" FontSize="20px" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ height: 300 }}>
						<GenericRadioInput
							Name="ExportReportType"
							LabelText="Report Type"
							IsStatic={true}
							StaticData={[{ Value: "PDF", Text: "PDF" }, { Value: "CSV", Text: "CSV" }, { Value: "EXCEL", Text: "EXCEL" }]}
							KeyValueMember="Value"
							TextValueMember="Text"
							Value={this.state.ExportReportType}
							ValueChanged={this.ValueChanged}
						/>
						<GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
						<GridItem style={{ marginTop: 20 }}>
							<Select
								value={this.state.ExportSelectedColumns}
								onChange={value => this.setState({ ExportSelectedColumns: value })}
								isMulti
								name="columns"
								getOptionLabel={d => d.title}
								options={this.state.GridKeyValueList}
								className="basic-multi-select"
								classNamePrefix="select"
							/>
						</GridItem>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.ExcelDownload}>Download</Button>
						<Button size="sm" onClick={() => this.setState({ isDownloadDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>
			</div>
		);
	}
}

FromToAccountingTransaction.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	ApprovalData: PropTypes.any,
	After: PropTypes.any,
};

export default FromToAccountingTransaction;
