import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import { DeleteIcon, EditIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import AccountComponent from "views/Components/AccountComponent";
import { GenericAlert, GenericExpansionPanel, GenericGrid, GenericSelectInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";

class ClientPartnerAccountDefinitionAndDetails extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			AccountDefinitionModel: {},
			accountModel: {
				FinancialInstitutionId: 0,
				FinancialInstitutionBranchId: 0,
				AccountNumber: 0
			},
			partnerModel: {
				FinancialInstitutionId: 0,
				FinancialInstitutionBranchId: 0,
				AccountNumber: 0
			},
			ClientPartnerAccountDefinitionAndDetailsList: [],
			isLoading: false,
			alert: null
		};
		this.LoanTypeParameterCode = {
			ParameterCode: "LOSLoanType"
		};
		this.SalesChannelarameterCode = {
			ParameterCode: "LOSSalesChannel"
		};
		this.PartnerFeeTypeParameterCode = {
			ParameterCode: "LOSPartnerFeeType"
		};
		this.IntendUseOfAccountParameterCode = {
			ParameterCode: "IntendUseOfAccount"
		};
		this.AccountTypeParameterCode = {
			ParameterCode: "BankingAccountType",
			ParameterValue2: "SettlementAccount"
		};
		this.Parameterwidth33 = {
			width: "33.6%"
		};
		this.Parameterwidth65 = {
			width: "65.7%"
		};

		this.ColumnsData = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip="Edit"
							Icon={EditIcon}
							OnClick={() => this.setState({
								AccountDefinitionModel: {
									...row.original
								}
							})} />
						<GridButton
							tooltip="Delete"
							Icon={DeleteIcon}
							OnClick={() => this.showQuestionMessage("Delete this record !", row.original)} />
					</div>
				),
				sortable: false,
				filterable: false,
				width: 100
			}, {
				Header: "Wallet Type",
				accessor: "AccountType.ParameterDesc"
			}, {
				Header: "Partner Financial Institution",
				accessor: "PartnerFinancialInstitution.Description"
			}, {
				Header: "Partner Financial Institution Branch",
				accessor: "PartnerFinancialInstitutionBranch.Description"
			}, {
				Header: "Partner Wallet Number",
				accessor: "PartnerAccountNumber"
			}, {
				Header: "Client Financial Institution",
				accessor: "ClientAccountFinancialInstitution.Description"
			}, {
				Header: "Client Financial Institution Branch",
				accessor: "ClientAccountFinancialInstitutionBranch.Description"
			}, {
				Header: "Client Wallet Number",
				accessor: "ClientAccountAccountNumber"
			}, {
				Header: "Instend Use of Wallet",
				accessor: "IntendUseOfAccount.ParameterDesc"
			}
		];
	}
	componentDidMount() {
		if (this.props.setAppLeftTitle)
			this.props.setAppLeftTitle("Documents");

		var model = {
			Id: 0
		};
		this.setState({ AccountDefinitionModel: model, ClientPartnerAccountDefinitionAndDetailsList: this.props.ClientPartnerAccountDefinitionAndDetailsList });

	}

	OperationCheck = () => {

		var ErrorList = [];
		const data = this.state.AccountDefinitionModel;

		if (data == null) {
			ErrorList.push("Wallet definition and details not defined.");
			this.ShowMessageNode("warning", "Please Fill Required Fields", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		if (data.ClientAccountFinancialInstitutionId != null) {
			if (data.ClientAccountFinancialInstitutionBranchId == null || undefined) {
				ErrorList.push("Client Wallet branch.");
				if (data.ClientAccountAccountNumber === null || data.ClientAccountAccountNumber === undefined || data.ClientAccountAccountNumber === "") {
					ErrorList.push("Client Wallet number.");
				}
			} else {
				if (data.ClientAccountAccountNumber === null || data.ClientAccountAccountNumber === undefined || data.ClientAccountAccountNumber === "") {
					ErrorList.push("Client Wallet number.");
				}
			}

		}
		if (data.PartnerFinancialInstitutionId != null) {
			if (data.PartnerFinancialInstitutionBranchId == null || undefined) {
				ErrorList.push("Partner Wallet branch.");
				if (data.PartnerAccountNumber === null || data.PartnerAccountNumber === undefined || data.PartnerAccountNumber === "") {
					ErrorList.push("Partner Wallet number.");
				}
			} else {
				if (data.PartnerAccountNumber === null || data.PartnerAccountNumber === undefined || data.PartnerAccountNumber === "") {
					ErrorList.push("Partner Wallet number.");
				}
			}

		}
		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please Fill Required Fields", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.UpdateModel();
	}
	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert
				Title={title}
				MessageNode={message}
				Type={type}
				OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}

	UpdateModel = () => {
		var { ClientPartnerModel } = this.props;

		var temp = this.state.AccountDefinitionModel;
		temp.ClientPartnerId = ClientPartnerModel.Id;

		this.setState({ isLoading: true });

		Proxy.POST("/losapi/v1.0/ClientPartnerAccountDefinitionAndDetails/InsertUpdate", temp, responseData => {
			this.setState({ isLoading: false });
			if (!responseData.IsSucceeded) {
				this.ShowMessage("error", "Error", responseData.ErrorDescription);
				return;
			} else {
				this.ShowMessage("success", "Success", temp.Id > 0
					? "Client Partner Wallet Definition And Details Updated"
					: "Client Partner Wallet Definition And Details Saved");
				this.ClearData();
			}
			this
				.props
				.GetClientPartnerAccountDefinitionAndDetailsModels();
		}, error => {
			this.setState({ isLoading: false });
			this.ShowMessage("error", "Error", error);
		});
	}

	DeleteModel = (model) => {
		if (model == null || model.Id == null || model.Id == 0) {
			this.ShowMessage("error", "Warning", "Document must be selected for delete operation.");
			return;
		}
		this.setState({ isLoading: true });
		Proxy.POST("/losapi/v1.0/ClientPartnerAccountDefinitionAndDetails/Delete", {
			Id: model.Id
		}, responseData => {
			if (!responseData.IsSucceeded) {
				this.ShowMessage("error", "Error", responseData.ErrorDescription);
				return;
			}
			this.ShowMessage("success", "Success", "Client Partner Wallet Definition And Details Deleted");
			this.ClearData();
			this
				.props
				.GetClientPartnerAccountDefinitionAndDetailsModels();
		}, error => {
			this.setState({ isLoading: false });
			this.ShowMessage("error", "Error", error);
		});
	}
	SelectedRowChange = (e, index) => {
		const model = this.props.ClientPartnerAccountDefinitionAndDetailsList[index];
		const tempPartner = this.state.partnerModel;
		const tempAccount = this.state.accountModel;
		tempPartner.FinancialInstitutionId = model.PartnerFinancialInstitutionId;
		tempPartner.FinancialInstitutionBranchId = model.PartnerFinancialInstitutionBranchId;
		tempPartner.AccountNumber = model.PartnerAccountNumber;
		tempAccount.FinancialInstitutionId = model.ClientAccountFinancialInstitutionId;
		tempAccount.FinancialInstitutionBranchId = model.ClientAccountFinancialInstitutionBranchId;
		tempAccount.AccountNumber = model.ClientAccountAccountNumber;
		this.setState({
			AccountDefinitionModel: model || {},
			accountModel: tempAccount,
			partnerModel: tempPartner
		});
	}
	ClearData = () => {
		var model = {
			Id: 0
		};
		var accountModelClear = {
			FinancialInstitutionId: 0,
			FinancialInstitutionBranchId: 0,
			AccountNumber: 0
		};
		var partnerModelClear = {
			FinancialInstitutionId: 0,
			FinancialInstitutionBranchId: 0,
			AccountNumber: 0
		};
		this.setState({ AccountDefinitionModel: model, partnerModel: partnerModelClear, accountModel: accountModelClear });

	}
	handleChange2 = (name, newValue, data) => {
		const temp = this.state.AccountDefinitionModel;
		const tempPartner = this.state.partnerModel;
		if (name == "FinancialInstitutionId" && data != null) {
			temp.PartnerFinancialInstitutionId = data.Id;
			tempPartner.FinancialInstitutionId = data.Id;
			temp.PartnerFinancialInstitutionBranchId = null;
			temp.PartnerAccountNumber = null;
			tempPartner.AccountNumber = null;
		}
		if (name == "FinancialInstitutionBranchId" && data != null) {
			temp.PartnerFinancialInstitutionBranchId = data.Id;
			tempPartner.FinancialInstitutionBranchId = data.Id;
		}
		if (name == "AccountNumber") {
			temp.PartnerAccountNumber = newValue;
			tempPartner.AccountNumber = newValue;
		}
		this.setState({ AccountDefinitionModel: temp, partnerModel: tempPartner });
	}
	handleChange = (name, newValue, data) => {
		const temp = this.state.AccountDefinitionModel;
		const tempAccount = this.state.accountModel;
		if (name == "FinancialInstitutionId" && data != null) {
			temp.ClientAccountFinancialInstitutionId = data.Id;
			tempAccount.FinancialInstitutionId = data.Id;
			temp.ClientAccountFinancialInstitutionBranchId = null;
			temp.ClientAccountAccountNumber = null;
			tempAccount.AccountNumber = null;
		}
		if (name == "FinancialInstitutionBranchId" && data != null) {
			temp.ClientAccountFinancialInstitutionBranchId = data.Id;
			tempAccount.FinancialInstitutionBranchId = data.Id;
		}
		if (name == "AccountNumber") {
			temp.ClientAccountAccountNumber = newValue;
			tempAccount.AccountNumber = newValue;
		}
		this.setState({ AccountDefinitionModel: temp, accountModel: tempAccount });
		this.setState(function (state, props) {
			var model = state.AccountDefinitionModel || {};
			model[name] = newValue;
			return { AccountDefinitionModel: temp, model, accountModel: tempAccount };
		});
	}
	FilterIdentityNumber = (number) => {
		if (number == null)
			return "";

		var regexp = new RegExp("(.*)(.{4})");
		var onEk = regexp
			.exec(number)[1]
			.replace(/([^ ])/g, "*");
		var sonEk = regexp.exec(number)[2];

		return onEk + sonEk;
	}
	showQuestionMessage = (message, model) => {
		this.setState({
			alert: <GenericAlert
				Title="Delete"
				Message={message}
				Type="warning"
				ShowCancel={true}
				OnCancel={() => this.hideAlert()}
				OnConfirm={() => this.DeleteModel(model)} />
		});
		this.setState({ isLoading: false });
	}
	ShowMessage = (type, title, message) => {
		// type:-success->warning->info->error
		this.setState({
			alert: <GenericAlert
				Title={title}
				Message={message}
				Type={type}
				OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({ alert: null, isLoading: false });
	}
	render() {
		const { Disabled: DisabledProp, ClientPartnerAccountDefinitionAndDetailsList } = this.props;
		const { AccountDefinitionModel, accountModel, partnerModel } = this.state;
		const Disabled = DisabledProp || this.state.CustomDisabled;

		return (
			<div>
				{this.state.alert}

				<LoadingComponent Show={this.state.isLoading} />

				<Card className="no-radius">
					<CardBody>
						<GenericExpansionPanel Title="Wallet Definition">
							<GridContainer>
								<GridContainer >
									<GridItem xs={12} sm={6} md={3}>
										<GenericSelectInput
											Name="AccountTypeId"
											LabelText="Wallet Type "
											Value={AccountDefinitionModel == null
												? ""
												: AccountDefinitionModel.AccountTypeId || ""}
											ValueChanged={this.handleChange}
											KeyValueMember="Id"
											TextValueMember="ParameterDesc"
											Method="POST"
											Url="/coreapi/v1.0/Parameter/Search"
											Parameter={this.AccountTypeParameterCode}
											Disabled={Disabled}
											DataRoot="Item" />
									</GridItem>
									<GridItem xs={12} sm={6} md={4}>
										<AccountComponent
											TitleLabel=""
											TitleLabelBranch=""
											LabelText="Client Primary Wallet"
											IsDCBankAccount={false}
											Model={accountModel}
											Disabled={Disabled}
											ValueChanged={this.handleChange} />

									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={12} sm={6} md={3}>
										<GenericSelectInput
											Name="IntendUseOfAccountId"
											LabelText="Intend Use of Wallet"
											Value={AccountDefinitionModel == null
												? ""
												: AccountDefinitionModel.IntendUseOfAccountId || ""}
											ValueChanged={this.handleChange}
											KeyValueMember="Id"
											TextValueMember="ParameterDesc"
											Method="POST"
											Url="/coreapi/v1.0/Parameter/Search"
											Parameter={this.IntendUseOfAccountParameterCode}
											Disabled={Disabled}
											DataRoot="Item" />
									</GridItem>
									<GridItem xs={12} sm={6} md={4}>
										<AccountComponent
											TitleLabel=""
											TitleLabelBranch=""
											LabelText="Partner Wallet"
											IsDCBankAccount={false}
											Model={partnerModel}
											Disabled={Disabled}
											ValueChanged={this.handleChange2} />
									</GridItem>
								</GridContainer>
								<GridContainer></GridContainer>
								<GridContainer justify="flex-end">
									<GridItem>
										<Button
											disabled={Disabled}
											size="sm"
											color="black"
											onClick={() => this.OperationCheck()}>{AccountDefinitionModel.Id > 0
												? "Update"
												: "Add"}</Button>

										<Button
											disabled={Disabled}
											size="sm"
											color="black"
											onClick={() => this.ClearData()}>
											Clear
                                        </Button>
									</GridItem>
								</GridContainer>
							</GridContainer>
						</GenericExpansionPanel>
					</CardBody>
				</Card>

				<Card className="no-radius">
					<CardBody>
						<GenericExpansionPanel Title="Wallet Details">
							<GridItem md={12} xs={12}>
								<GridItem>
									<GenericGrid
										Data={ClientPartnerAccountDefinitionAndDetailsList}
										PageSize={5}
										HideButton={true}
										Columns={this.ColumnsData}
										RowSelected={index => {
											this.SelectedRowChange(0, index);
										}} />
								</GridItem>
							</GridItem>
						</GenericExpansionPanel>
					</CardBody>
				</Card>
			</div>
		);
	}
}
ClientPartnerAccountDefinitionAndDetails.propTypes = {
	classes: PropTypes.object,
	AccountDefinitionModel: PropTypes.object,
	ClientPartnerModel: PropTypes.object,
	ClientPartnerAccountDefinitionAndDetailsList: PropTypes.array,
	GetClientPartnerAccountDefinitionAndDetailsModels: PropTypes.func,
	handleChangeParentTab: PropTypes.func,
	handleChangeChildTab: PropTypes.func,
	SetFile: PropTypes.func

};
export default withArtifex(ClientPartnerAccountDefinitionAndDetails, {});