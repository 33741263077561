import { Card } from "@material-ui/core";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericGrid } from "views/Components/Generic";
import { GridColumnType, ProgramCodes } from "views/Constants/Constant";

class Transactions extends Component {
	constructor(props) {
		super(props);

		this.state = {
		};

		this.Columns = [
			{
				Header: "Card Type",
				accessor: "CardTypeDesc"
			},
			{
				Header: "Wallet Balance",
				accessor: "TransactionData.WalletBalance",
				type: GridColumnType.Money,
				ColumnType:GridColumnType.Money
			},
			{
				Header: "Available Credit Limit",
				accessor: "TransactionData.AvailableCreditLimit",
				type: GridColumnType.Money,
				ColumnType:GridColumnType.Money
			},
			{
				Header: "Txn Date",
				accessor: "TransactionData.TransactionDateTime",
				type: GridColumnType.DateTime
			},
			{
				Header: "Txn Status",
				accessor: "TransactionData.IsSucceded",
				Cell: row => (
					row.value ? "Succeded" : "False"
				)
			},
			{
				Header: "Txn Code",
				accessor: "TransactionData.TransactionCodeId"
			},
			{
				Header: "Txn Type",
				accessor: "TransactionTypeDesc"
			},
			{
				Header: "Provision Txn Type",
				accessor: "ProvisionTransactionTypeDesc"
			},
			{
				Header: "Txn Description",
				accessor: "TransactionData.TxnDescription"
			},
			{
				Header: "Billing Amount",
				accessor: "TransactionData.BillingAmount",
				type: GridColumnType.Money,
				ColumnType:GridColumnType.Money
			},
			{
				Header: "Billing Currency",
				accessor: "BillingCurrency"
			},
			{
				Header: "Local Transaction Currency",
				accessor: "CashBackLocalCurrency"
			},
			{
				Header: "Merchant Clearing Amount",
				accessor: "TransactionData.CashBackSettlementAmount",
				type: GridColumnType.Money,
				ColumnType:GridColumnType.Money
			},
			{
				Header: "Settlement Currency",
				accessor: "CashBackSettlementCurrency"
			},
			{
				Header: "Billing Transaction Amount",
				accessor: "TransactionData.CashBackBillingAmount",
				type: GridColumnType.Money,
				ColumnType:GridColumnType.Money
			},
			{
				Header: "Billing Currency",
				accessor: "TransactionData.CashBackBillingCurrencyCode"
			},
			{
				Header: "Currency Rate",
				accessor: "TransactionData.CurrencyRate",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Card Network",
				accessor: "NetworkDesc"
			},
			{
				Header: "Card Brand",
				accessor: "CardBrandDesc"
			},
			{
				Header: "Application Network",
				accessor: "TransactionData.ApplicationNetwork"
			},
			{
				Header: "System Settlement Status",
				accessor: "SettlementStatusDesc"
			},
			{
				Header: "Wallet Number",
				accessor: "Account.AccountNumber"
			},
			{
				Header: "Wallet Owner Name",
				accessor: "Account.AccountOwnerName"
			},
			{
				Header: "Card Sequence Number",
				accessor: "TransactionData.CardSequenceNumber"
			},
			{
				Header: "Wallet Balance",
				accessor: "Account.Balance",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Merchant Clearing Date",
				accessor: "TransactionData.SettlementDate",
				type: GridColumnType.DateTime,
			},
			{
				Header: "Reversed Date Time",
				accessor: "TransactionData.ReversedDateTime",
				type: GridColumnType.DateTime,
			},
			{
				Header: "Mti",
				accessor: "TransactionData.Mti"
			},
			{
				Header: "Txn Entry",
				accessor: "TransactionData.TransactionEntryType"
			},
			{
				Header: "Client Fee Amount",
				accessor: "TransactionData.ClientFeeAmount",
				type: GridColumnType.Money,
				ColumnType:GridColumnType.Money
			},
			{
				Header: "Client Fee Currency Code",
				accessor: "TransactionData.ClientFeeCurrencyCode"
			},
			{
				Header: "Customer Fee Amount",
				accessor: "TransactionData.CustomerFeeAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Customer Fee Currency Code",
				accessor: "TransactionData.CustomerFeeCurrencyCode"
			},
		];
	}

	componentDidMount() {
	}

	RenderItemAccount = (d) => {
		this.renderItemAccount = `${d.AccountNumber} - ${d.AccountName}`;
		return this.renderItemAccount;
	}

	render() {
		const { list, ReadOnly, Disabled } = this.props;

		return (
			<Card className="no-radius">
				<CardBody>
					<GridContainer>
						<GridItem xs={12}>
							<br />
							<GenericGrid
								Data={list}
								Columns={this.Columns}
								HideButton={false}
								ProgramCode={ProgramCodes.Prepaid} 
								PageSize={10}
								ShowPagination={true}/>
						</GridItem>
					</GridContainer>
				</CardBody>
			</Card>
		);
	}
}

Transactions.propTypes = {
	classes: PropTypes.object.isRequired,
	list: PropTypes.array,
	showMessage: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(Transactions, {});