import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import { GenericDateInput, GenericPage, GenericGrid, GenericSelectInput, GenericNumberInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";

class WireTransferBeneficiaryReport extends GenericPage {
	constructor(props) {
		super(props);

		this.isBackOffice = ClientHelper.IsBackOffice();
		this.clientId = ClientHelper.GetClientId();

		this.defaultModel = {
			UniqueClientId: this.isBackOffice ? undefined : this.clientId,
			Date: DateHelper.GetDate(),
			TrxDirection: undefined,
			MessageType: undefined,
			FromAmount: undefined,
			ToAmount: undefined,
			BeneficiaryName: undefined
		};

		this.defaultSummary = {
			TotalOutgoingTransactionAmount: 0,
			TotalIncomingTransactionAmount: 0,
			TotalTransactionFee: 0
		};

		this.columns = [
			{
				Header: "Transaction Direction",
				accessor: "TransactionDirection"
			},
			{
				Header: "Beneficiary",
				Cell: row => (<span>{row.original.BeneficiaryCompanyName != "" ? row.original.BeneficiaryCompanyName : row.original.BeneficiaryName}</span>)
			},
			{
				Header: "Message Type",
				accessor: "MessageType",
			},
			{
				Header: "Total No. of Transaction",
				accessor: "TotalTrxCount",
				Cell: row => (<span>{Formatter.FormatNumber(row.value)}</span>)
			},
			{
				Header: "Total Amount of Trx.",
				accessor: "TotalTrxAmount",
				Cell: row => (<span>{Formatter.FormatDecimal(row.value)}</span>)
			},
			{
				Header: "Currency",
				accessor: "Currency"
			},
			{
				Header: "Total Amount Fee",
				accessor: "TotalFeeAmount",
				Cell: row => (<span>{Formatter.FormatDecimal(row.value)}</span>)
			}
		];

		if (this.isBackOffice) {
			const clientColumn = {
				Header: "Client",
				accessor: "ClientName"
			};
			this.columns.unshift(clientColumn);
		}

		this.state = {
			isLoading: false,
			model: { ...this.defaultModel },
			summary: { ...this.defaultSummary }
		};

		this.EmptyObject = {};
	}

	componentDidMount() {
		super.componentDidMount();
		const { setAppLeftTitle, setAppCenterTitle } = this.props;

		if (setAppLeftTitle)
			setAppLeftTitle("Beneficiary Report");
		if (setAppCenterTitle)
			setAppCenterTitle("WIRE TRANSFER");
	}

	SearchClick() {
		const { model, summary } = this.state;

		this.ExecutePostRequest("/bankapi/v1.0/WireTransfer/SearchBeneficiaryBasedReport",
			model,
			responseData => {
				summary.TotalIncomingTransactionAmount = responseData.TotalIncomingTransactionAmount;
				summary.TotalOutgoingTransactionAmount = responseData.TotalOutgoingTransactionAmount;
				summary.TotalTransactionFee = responseData.TotalTransactionFee;
				this.setState({ data: responseData.Item, summary });
			});
	}

	ClearClick() {
		this.setState({ model: { ...this.defaultModel } });
	}

	ValueChanged(name, newValue) {
		this.setState(state => {
			var model = state.model || {};
			model[name] = newValue;

			return { model };
		});
	}

	render() {
		const { Disabled } = this.props;
		const { model, data, summary } = this.state;
		const { IsLoading, Alert } = this.state;


		return (
			<>
				{Alert}
				<LoadingComponent Show={IsLoading} />
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.SearchClick, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.ClearClick, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<Card>
					<CardHeader>
						<GenericTitle text={"Filter Panel"} />
					</CardHeader>
					<CardBody>
						<GridContainer>
							<GridItem xs={8}>
								<GridContainer>
									<GridItem xs={6}>
										<GenericSelectInput
											Name="UniqueClientId"
											LabelText={"Client Name"}
											Value={model.UniqueClientId}
											DataRoot="Item"
											ValueChanged={this.ValueChanged}
											KeyValueMember="Id"
											TextValueMember="Name"
											Url="/bankapi/v1.0/BankCustomer/Search"
											CanClear
											Method="POST"
											Disabled={Disabled || !this.isBackOffice}
											Parameter={{}} />
									</GridItem>
									<GridItem xs={6}>
										<GenericDateInput Name="Date"
											LabelText={"Date"}
											Value={model.Date}
											Utc={true}
											ValueChanged={this.ValueChanged}
											Disabled={Disabled} />
									</GridItem>
									<GridItem xs={6}>
										<ParameterComponent Name="TrxDirection"
											LabelText={"Trx. Direction"}
											Value={model.TrxDirection}
											ValueChanged={this.ValueChanged}
											ParameterCode="Direction"
											Disabled={Disabled} />
									</GridItem>
									<GridItem xs={6}>
										<ParameterComponent Name="MessageType"
											LabelText={"Message Type"}
											Value={model.MessageType}
											ValueChanged={this.ValueChanged}
											ParameterCode="WireMessageType"
											Disabled={Disabled} />
									</GridItem>
								</GridContainer>
							</GridItem>
							<GridItem xs={4}>
								<Card>
									<CardBody>
										<GridContainer>
											<GridItem xs={12}>
												<GenericNumberInput
													LabelText="Total Amount of Incoming Trx."
													Value={summary.TotalIncomingTransactionAmount}
													Disabled={true} />
											</GridItem>
											<GridItem xs={12}>
												<GenericNumberInput
													LabelText="Total Amount of Outgoing Trx."
													Value={summary.TotalOutgoingTransactionAmount}
													Disabled={true} />
											</GridItem>
											<GridItem xs={12}>
												<GenericNumberInput
													LabelText="Total Amount of Fee"
													Value={summary.TotalTransactionFee}
													Disabled={true} />
											</GridItem>
										</GridContainer>
									</CardBody>
								</Card>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
				<GenericGrid
					Data={data}
					Columns={this.columns}
				/>
			</>
		);
	}
}

WireTransferBeneficiaryReport.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	menuId: PropTypes.number.isRequired,
	ApprovalData: PropTypes.object,
	After: PropTypes.func,
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool,
	ValueChanged: PropTypes.func,
	Model: PropTypes.object
};

export default withArtifex(WireTransferBeneficiaryReport, {});