import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { GridColumnType, MenuCodes } from "views/Constants/Constant.js";

class CardFeeProfileDefination extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : -1,
			AnnuelFeeChargeTypeValue: "",
			DayCount: 0
		};

		this.state = {
			model: this.initialModel,
			list: [],
			isEdit: false,
			isLoading: false,
			index: -1
		};

		this.IsDefaultProfile = false;

		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : -1,
		};

		this.renderItemUniqueClient = {};

	}

	componentDidMount() {
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");

		switch (this.props.MenuCode) {
			case MenuCodes.Menu_BackOfficeCardFeeProfile:
				this.IsDefaultProfile = true;
				this.props.setAppLeftTitle("Card Fee Profile");
				break;
			case MenuCodes.Menu_CardFeeProfile:
				this.IsDefaultProfile = false;
				this.props.setAppLeftTitle("Card Fee Profile");
				break;
			case MenuCodes.Menu_ClientCardFeeProfile:
				this.IsDefaultProfile = false;
				this.props.setAppLeftTitle("Client Card Fee Profile");
				break;
			default:
				break;
		}
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		if (name == "AnnuelFeeChargeTypeId") {
			if (data && data.ParameterValue == "D")
				model.AnnuelFeeChargeTypeValue = data.ParameterValue;
			else {
				model.AnnuelFeeChargeTypeValue = "S";
				model.DayCount = 0;
			}
		}
		this.setState({ model });
	}

	HandleClear = () => {
		this.setState({ model: { ...this.initialModel }, isEdit: false, index: -1 });
	}

	HandleSearch = async () => {
		this.setState({ isLoading: true });
		const { ExecuteApiPost } = this.props;
		const model = this.state.model;
		model.IsDefaultProfile = this.IsDefaultProfile;
		var result = await ExecuteApiPost("/prepaidapi/v1.0/CardFeeProfile/SearchCardFeeProfile", model);
		this.setState({ list: result || [], isLoading: false });
	}

	HandleSubmit = async () => {
		if (!this.Validate()) {
			return;
		}
		const { ExecuteApiPost } = this.props;
		const model = this.state.model;
		model.IsDefaultProfile = this.IsDefaultProfile;
		var result = await ExecuteApiPost("/prepaidapi/v1.0/CardFeeProfile/InsertOrUpdateCardFeeProfile", model);
		if (result != false) {
			this.HandleClear();
			this.HandleSearch();
		}
	}

	HandleDelete = () => {
		const { ExecuteApiPost, showConfirmMessage } = this.props;
		showConfirmMessage("warning", "Delete", "Are you sure to delete this record?", () => ExecuteApiPost
			("/prepaidapi/v1.0/CardFeeProfile/DeleteCardFeeProfile",
				{
					Id: this.state.model.Id,
					IsDefaultProfile: this.IsDefaultProfile
				}, null, null, null, [this.HandleSearch, this.HandleClear], true
			));
	}
	Validate = () => {
		const { showValidationErrors } = this.props;

		const { model } = this.state;
		var Messages = [];
		model.vProfileName = !model.ProfileName;
		if (model.vProfileName) Messages.push("Profile Name cannot be empty.");

		model.vMainCardIssuingFee = model.MainCardIssuingFee < 0 || model.MainCardIssuingFee == null;
		if (model.vMainCardIssuingFee) Messages.push("Main Card Issuing Fee cannot be empty");

		model.vSuppCardIssuingFee = model.SuppCardIssuingFee < 0 || model.SuppCardIssuingFee == null;
		if (model.vSuppCardIssuingFee) Messages.push("Sup Card Issuing Fee cannot be empty");

		model.vMainCardAnnualFee = model.MainCardAnnualFee < 0 || model.MainCardAnnualFee == null;
		if (model.vMainCardAnnualFee) Messages.push("Main Card Annual Fee cannot be empty");

		model.vSuppCardAnnualFee = model.SuppCardAnnualFee < 0 || model.SuppCardAnnualFee == null;
		if (model.vSuppCardAnnualFee) Messages.push("Sup Card Annual Fee cannot be empty");

		model.vMainCardRenewalFee = model.MainCardRenewalFee < 0 || model.MainCardRenewalFee == null;
		if (model.vMainCardRenewalFee) Messages.push("Main Card Renewal Fee cannot be empty");

		model.vSuppCardRenewalFee = model.SuppCardRenewalFee < 0 || model.SuppCardRenewalFee == null;
		if (model.vSuppCardRenewalFee) Messages.push("Sup Card Renewal Fee cannot be empty");

		model.vCardReembossFee = model.CardReembossFee < 0 || model.CardReembossFee == null;
		if (model.vCardReembossFee) Messages.push("Main Card Re-Issuing Fee cannot be empty");

		model.vSuppCardReEmbossFee = model.SuppCardReEmbossFee < 0 || model.SuppCardReEmbossFee == null;
		if (model.vSuppCardReEmbossFee) Messages.push("Sup Card Re-Issuing Fee cannot be empty");

		model.vTokenGenerationFee = model.TokenGenerationFee < 0 || model.TokenGenerationFee == null;
		if (model.vTokenGenerationFee) Messages.push("Token Generation Fee cannot be empty");

		model.vInstantPersonalizationFee = model.InstantPersonalizationFee < 0 || model.InstantPersonalizationFee == null;
		if (model.vInstantPersonalizationFee) Messages.push("Instant Personalization Fee cannot be empty");

		model.vTokenStatusUpdateFee = model.TokenStatusUpdateFee < 0 || model.TokenStatusUpdateFee == null;
		if (model.vTokenStatusUpdateFee) Messages.push("Token Status Update Fee cannot be empty");

		model.vUniqueClientId = this.IsDefaultProfile == false && (model.UniqueClientId < 0 || model.UniqueClientId == null);
		if (model.vUniqueClientId) Messages.push("Client cannot be empty");

		if (Messages.length > 0) {
			showValidationErrors(Messages);
			return false;
		}
		else
			return true;
	}

	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}

	RowSelected = (index) => {
		var tempModel = this.state.list[index];
		this.setState({ isEdit: true, model: tempModel, index });
	}
	render() {
		const { Disabled } = this.props;
		const { alert, model, list, isLoading, index } = this.state;
		var IsClient = ClientHelper.IsClient();
		return (
			<div>
				<LoadingComponent Show={isLoading} />
				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
					{ Code: "Submit", OnClick: this.HandleSubmit, Disabled: model.Id || Disabled || IsClient },
					{ Code: "Delete", OnClick: this.HandleDelete, Disabled: !model.Id || Disabled || IsClient },
					{ Code: "Update", OnClick: this.HandleSubmit, Disabled: !model.Id || Disabled || IsClient },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer spacing={16}>

					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel >
									<GridContainer>
										{!this.IsDefaultProfile &&
											<GridItem xs={4}>
												<GenericSelectInput
													Name="UniqueClientId"
													LabelText="Client"
													Method="POST"
													Url="/bankapi/v1.0/BankCustomer/Search"
													Parameter={this.parameterUniqueClientId}
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={this.RenderItemUniqueClient}
													Value={model.UniqueClientId}
													ValueChanged={this.HandleChange}
													CanClear
													Disabled={IsClient}
													DefaultIndex={IsClient ? 0 : -1}
													Required
													IsInvalid={model.vUniqueClientId}
												/>
											</GridItem>
										}
										<GridItem xs={4}>
											<GenericTextInput
												Name="ProfileName"
												LabelText="Profile Name"
												Value={model.ProfileName}
												ValueChanged={this.HandleChange}
												Required={!ClientHelper.IsClient()}
												IsInvalid={model.vProfileName}
											/>
										</GridItem>

										<GridItem xs={4}>
											<GenericNumberInput
												Name="MainCardIssuingFee"
												LabelText="Main Card Issuing Fee"
												Value={model.MainCardIssuingFee}
												ValueChanged={this.HandleChange}
												Prefix={"$"}
												MaxLength={17}
												Required={!ClientHelper.IsClient()}
												IsInvalid={model.vMainCardIssuingFee}
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericNumberInput
												Name="SuppCardIssuingFee"
												LabelText="Sup Card Issuing Fee"
												Value={model.SuppCardIssuingFee}
												ValueChanged={this.HandleChange}
												MaxLength={17}
												Prefix={"$"}
												Required={!ClientHelper.IsClient()}
												IsInvalid={model.vSuppCardIssuingFee}
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericNumberInput
												Name="MainCardAnnualFee"
												LabelText="Main Card Annual Fee"
												Value={model.MainCardAnnualFee}
												ValueChanged={this.HandleChange}
												MaxLength={17}
												Prefix={"$"}
												Required={!ClientHelper.IsClient()}
												IsInvalid={model.vMainCardAnnualFee}
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericNumberInput
												Name="MainCardRenewalFee"
												LabelText="Main Card Renewal Fee"
												Value={model.MainCardRenewalFee}
												ValueChanged={this.HandleChange}
												MaxLength={17}
												Prefix={"$"}
												Required={!ClientHelper.IsClient()}
												IsInvalid={model.vMainCardRenewalFee}
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericNumberInput
												Name="SuppCardAnnualFee"
												LabelText="Sup Card Annual Fee"
												Value={model.SuppCardAnnualFee}
												ValueChanged={this.HandleChange}
												MaxLength={17}
												Prefix={"$"}
												Required={!ClientHelper.IsClient()}
												IsInvalid={model.vSuppCardAnnualFee}
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericNumberInput
												Name="CardReembossFee"
												LabelText="Main Card Re-Issuing Fee"
												Value={model.CardReembossFee}
												ValueChanged={this.HandleChange}
												MaxLength={17}
												Prefix={"$"}
												Required={!ClientHelper.IsClient()}
												IsInvalid={model.vCardReembossFee}
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericNumberInput
												Name="SuppCardReEmbossFee"
												LabelText="Supp Card Re-Issuing Fee"
												Value={model.SuppCardReEmbossFee}
												ValueChanged={this.HandleChange}
												MaxLength={17}
												Prefix={"$"}
												Required={!ClientHelper.IsClient()}
												IsInvalid={model.vSuppCardReEmbossFee}
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericNumberInput
												Name="SuppCardRenewalFee"
												LabelText="Sup Card Renewal Fee"
												Value={model.SuppCardRenewalFee}
												ValueChanged={this.HandleChange}
												MaxLength={17}
												Prefix={"$"}
												Required={!ClientHelper.IsClient()}
												IsInvalid={model.vSuppCardRenewalFee}
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericNumberInput
												Name="TokenGenerationFee"
												LabelText="Token Generation Fee"
												Value={model.TokenGenerationFee}
												ValueChanged={this.HandleChange}
												MaxLength={17}
												Prefix={"$"}
												Required={!ClientHelper.IsClient()}
												IsInvalid={model.vTokenGenerationFee}
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericNumberInput
												Name="InstantPersonalizationFee"
												LabelText="Instant Personalization Fee"
												Value={model.InstantPersonalizationFee}
												ValueChanged={this.HandleChange}
												MaxLength={17}
												Prefix={"$"}
												Required={!ClientHelper.IsClient()}
												IsInvalid={model.vInstantPersonalizationFee}
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericNumberInput
												Name="TokenStatusUpdateFee"
												LabelText="Token Status Update Fee"
												Value={model.TokenStatusUpdateFee}
												ValueChanged={this.HandleChange}
												MaxLength={17}
												Prefix={"$"}
												Required={!ClientHelper.IsClient()}
												IsInvalid={model.vTokenStatusUpdateFee}
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericNumberInput
												Name="FreeReEmbossCount"
												LabelText="Free Re-Emboss Count"
												Value={model.FreeReEmbossCount}
												ValueChanged={this.HandleChange}
												MaxLength={2}
												MaxValue={99}
												Required={!ClientHelper.IsClient()}
												IsInvalid={model.vFreeReEmbossCount}
												DecimalScale={0}
											/>
										</GridItem>
										<GridItem xs={4}>
											<ParameterComponent
												Name="AnnualFeePeriodId"
												LabelText="Annual Fee Apply Period"
												ParameterCode="Period"
												Value={model.AnnualFeePeriodId}
												ValueChanged={this.HandleChange} />
										</GridItem>
										<GridItem xs={4}>
											<ParameterComponent
												Name="AnnuelFeeChargeTypeId"
												Value={model.AnnuelFeeChargeTypeId}
												LabelText="Charge Type"
												ParameterCode="AnnuelFeeChargeType"
												ValueChanged={this.HandleChange}
												Disabled={Disabled} />
										</GridItem>
										{model.AnnuelFeeChargeTypeValue == "D" &&
											<GridItem xs={4}>
												<GenericNumberInput
													Name="DayCount"
													LabelText="Day Count"
													Value={model.DayCount}
													ValueChanged={this.HandleChange}
													MaxLength={3}
													Required={!ClientHelper.IsClient()}
													IsInvalid={model.vDayCount}
													DecimalScale={0}
													MaxValue={365}
												/>
											</GridItem>}
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Card Fee Profile List">
									<GridContainer>
										<GridItem xs={12}>
											<br />
											<GenericGrid
												Data={list}
												Columns={[
													{
														Header: "Client Name",
														accessor: "UniqueClient.ClientName",
														show:!this.IsDefaultProfile
													},
													{
														Header: "Profile Name",
														accessor: "ProfileName"
													},
													{
														Header: "Main Card Issuing Fee",
														accessor: "MainCardIssuingFee",
														type: GridColumnType.Money,
														ColumnType: GridColumnType.Money
													},
													{
														Header: "Sup Card Issuing Fee",
														accessor: "SuppCardIssuingFee",
														type: GridColumnType.Money,
														ColumnType: GridColumnType.Money
													},
													{
														Header: "Main Card Annual Fee",
														accessor: "MainCardAnnualFee",
														type: GridColumnType.Money,
														ColumnType: GridColumnType.Money
													},
													{
														Header: "Sup Card Annual Fee",
														accessor: "SuppCardAnnualFee",
														type: GridColumnType.Money,
														ColumnType: GridColumnType.Money
													},
													{
														Header: "Main Card Re-Issuing Fee",
														accessor: "CardReembossFee",
														type: GridColumnType.Money,
														ColumnType: GridColumnType.Money
													},
													{
														Header: "Main Card Renewal Fee",
														accessor: "MainCardRenewalFee",
														type: GridColumnType.Money,
														ColumnType: GridColumnType.Money
													},
													{
														Header: "Supp Card Renewal Fee",
														accessor: "SuppCardRenewalFee",
														type: GridColumnType.Money,
														ColumnType: GridColumnType.Money
													},
													{
														Header: "Supp Card Re-Issuing Fee",
														accessor: "SuppCardReEmbossFee",
														type: GridColumnType.Money,
														ColumnType: GridColumnType.Money
													},
													{
														Header: "Token Generation Fee",
														accessor: "TokenGenerationFee",
														type: GridColumnType.Money,
														ColumnType: GridColumnType.Money
													},
													{
														Header: "Instant Personalization Fee",
														accessor: "InstantPersonalizationFee",
														type: GridColumnType.Money,
														ColumnType: GridColumnType.Money
													},
													{
														Header: "Annual Fee Apply Period",
														accessor: "AnnualFeePeriod.ParameterDesc"
													},
													{
														Header: "Annuel Fee Charge Type",
														accessor: "AnnuelFeeChargeType.ParameterDesc"
													},
													{
														Header: "Free Re-Emboss Count",
														accessor: "FreeReEmbossCount"
													},
													{
														Header: "Day Count",
														accessor: "DayCount"
													}

												]}
												RowSelected={this.RowSelected}
												SelectedIndex={index}
												HideButton={true}
											/>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

CardFeeProfileDefination.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(CardFeeProfileDefination, {});
