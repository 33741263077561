import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTextInput, GenericCheckInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { ProgramCodes, GridColumnType ,ReportTypeForWalletAccounts} from "views/Constants/Constant.js";
import ParameterComponent from "views/Components/ParameterComponent";
import { Grid } from "@material-ui/core";


class TransferToClientAccountDismiss extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			UniqueClientId: null
		};

		this.columns = [
			{
				Header: "Is Active",
				accessor: "IsActive",
				type: GridColumnType.YesNo,
				ColumnType: GridColumnType.YesNo
			},
			{
				Header: "Client Name",
				accessor: "ClientName"
			}, 
			{
				Header: "Insert DateTime",
				accessor: "InsertDateTime",
				type: GridColumnType.DateTime,
				ColumnType: GridColumnType.DateTime
			},
			{
				Header: "Insert User",
				accessor: "InsertUser"
			},
			{
				Header: "Update DateTime",
				accessor: "UpdateDateTime",
				type: GridColumnType.DateTime,
				ColumnType: GridColumnType.DateTime
			},
			{
				Header: "Update User",
				accessor: "UpdateUser"
			}
		];

		this.state = {
			list: [],
			model: this.initialModel,
			isLoading: false,
			vModel: {},
			IsInsertOp: false
		};
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Transfer To Client Wallet Dismiss");
		this.props.setAppCenterTitle("SYSTEM ADMIN");
	}

	handleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}

	handleClear = () => {
		this.setState({ model: { ...this.initialModel }, vModel: {}, index: -1, IsInsertOp: false });
	};

	IsValid = (operationType) => {
		const { model, vModel } = this.state;
		var errorList = [];
		const { showValidationErrors } = this.props;

		vModel.UniqueClientId = model.UniqueClientId == null || model.UniqueClientId == "";
		if (vModel.UniqueClientId) {
			errorList.push("Client cannot be empty.");
		}

		// vModel.ReportType = model.ReportType == null || model.ReportType == "";
		// if (vModel.ReportType) {
		// 	errorList.push("Webhook Type cannot be empty.");
		// }

		if (errorList.length > 0) {
			showValidationErrors(errorList);
			return false;
		}
		return true;
	}

	HandleSearch = () => {

		const model = { ...this.state.model };
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/Bank/GetAllTransfetToClientAccoutDismiss",
			model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({
						model,
						list: responseData.Item,
					});
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	};

	HandleInsertOrUpdate = (operationType) => {

		if (!this.IsValid(operationType))
			return;

		const model = { ...this.state.model };
		model.OperationType = operationType;
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/Bank/InsertOrUpdateTransfetToClientAccoutDismiss",
			model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.props.showMessage("success", "Succeeded", "Operation is successfully!");
				this.setState({
					model : { ...this.initialModel },
					isLoading: false,
					index: -1 
				});
				this.handleClear();
				this.HandleSearch();
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	};

	RowSelected = (index) => {
		let { model, list } = this.state;
		model = list[index];

		this.setState({ model, index, IsInsertOp: true });
	}

	render() {
		const { Disabled, showConfirmMessageInDelete, ExecuteApiPost } = this.props;
		const { alert, model, list, isLoading, index, vModel, IsInsertOp } = this.state;

		return (
			<div>

				<LoadingComponent Show={isLoading} />
				{alert}
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled || model.Id > 0,
							OnClick: this.HandleSearch
						},
						{
							Code: "Submit",
							Disabled: Disabled || model.Id > 0,
							OnClick: () => this.HandleInsertOrUpdate("Insert")
						},
						{
							Code: "Update",
							Disabled: Disabled || model.Id === 0,
							OnClick: () => this.HandleInsertOrUpdate("Update")
						},
						{
							Code: "Delete",
							Disabled: Disabled || model.Id === 0,
							OnClick: () => showConfirmMessageInDelete(() => ExecuteApiPost("/bankapi/v1.0/Bank/InsertOrUpdateTransfetToClientAccoutDismiss", { Id: model.Id, OperationType: "Delete" }, null, null, null, [this.handleClear, this.HandleSearch], true))
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.handleClear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After}
				/>
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card>
							<CardBody>
								<GenericExpansionPanel>
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={{
													UniqueClientId: ClientHelper.IsClient()
														? ClientHelper.GetClientId()
														: undefined
												}}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
												Value={model.UniqueClientId || ""}
												ValueChanged={this.handleChange}
												CanClear
												All
												Disabled={ClientHelper.IsClient()}
												DefaultIndex={ClientHelper.IsClient() ? 0 : -1} 
												IsInvalid={vModel.UniqueClientId}
												Required />
										</GridItem>
										<GridItem xs={4}>
											<GenericCheckInput
												LabelText="Is Active"
												Name="IsActive"
												Value={model.IsActive}
												ValueChanged={this.handleChange} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columns}
													ShowPagination={true}
													RowSelected={this.RowSelected}
													SelectedIndex={index}
													PageSize={100}
													ProgramCode={ProgramCodes.Prepaid}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

TransferToClientAccountDismiss.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(TransferToClientAccountDismiss, {});
