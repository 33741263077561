import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericCheckInput, GenericDateInput, GenericExpansionPanel, GenericGrid, GenericLabel, GenericSelectInput } from "views/Components/Generic";
import { GridColumnType } from "views/Constants/Constant";
import GridButton from "views/Components/GridButton";
import { SubmitOrSendIcon } from "core/Icons";
import LoadingComponent from "views/Components/LoadingComponent";
import DateHelper from "core/DateHelper";

class BulkFileUpload extends React.Component {
	constructor(props) {
		super(props);

		this.fileInputRef = React.createRef();

		this.columns = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip="Re-Process"
							Disabled={this.props.Disabled || !this.state.isBackOffice ||
								!(row.original.DemandStatusValue == "CF" || row.original.DemandStatusValue == "FF" || row.original.DemandStatusValue == "FR" || row.original.DemandStatusValue == "C")
								|| row.original.ContainsRejectedRows == false}
							Icon={SubmitOrSendIcon}
							OnClick={() => this.ReProcess(row.original.BulkFileDemandId)} />
					</div>
				),
				sortable: false,
				filterable: false,
				width: 100
			},
			{
				Header: "Client",
				accessor: "Client"
			},
			{
				Header: "Program",
				accessor: "ProgramName"
			},
			{
				Header: "File Name",
				accessor: "FileName"
			},
			{
				Header: "Status",
				accessor: "DemandStatusDesc"
			},
			{
				Header: "File Format",
				accessor: "FileFormatName"
			},
			{
				Header: "Comment",
				accessor: "Comment"
			},
			{
				Header: "Row Count",
				accessor: "RowCount"
			},
			{
				Header: "Received Date",
				accessor: "ReceivedDate",
				type: GridColumnType.DateTime
			},
			{
				Header: "Process Date",
				accessor: "ProcessDate",
				type: GridColumnType.DateTime
			},
			{
				Header: "Is Response Generated",
				accessor: "IsResponseGenerated",
				type: GridColumnType.YesNo
			},
			{
				Header: "Response File Name",
				accessor: "ResponseFileName"
			},
			{
				Header: "Total Amount Of Transaction",
				accessor: "TotalAmountOfTransaction",
				type: GridColumnType.Money
			},
			{
				Header: "Total Number Of Transaction",
				accessor: "TotalNumberOfTransaction"
			},
			{
				Header: "Amount Of Rejected",
				accessor: "TotalAmountOfRejected",
				type: GridColumnType.Money
			},
			{
				Header: "Number Of Rejected",
				accessor: "TotalNumberOfRejected"
			},
			{
				Header: "Total Debit Amount",
				accessor: "TotalDebitAmount",
				type: GridColumnType.Money
			},
			{
				Header: "Total Credit Amount",
				accessor: "TotalCreditAmount",
				type: GridColumnType.Money
			},
			{
				Header: "Total Number Of Credit",
				accessor: "TotalNumberOfCredit"
			},
			{
				Header: "Total Number Of Debit",
				accessor: "TotalNumberOfDebit"
			},
			{
				Header: "Number Of Completed Debit",
				accessor: "NumberOfCompletedDebit"
			},
			{
				Header: "Number Of Completed Credit",
				accessor: "NumberOfCompletedCredit"
			},
			{
				Header: "Number Of Rejected Debit",
				accessor: "NumberOfRejectedDebit"
			},
			{
				Header: "Number Of Rejected redit",
				accessor: "NumberOfRejectedCredit"
			},
			{
				Header: "Amount Of Completed Debit",
				accessor: "AmountOfCompletedDebit",
				type: GridColumnType.Money
			},
			{
				Header: "Amount Of Completed Credit",
				accessor: "AmountOfCompletedCredit",
				type: GridColumnType.Money
			},
			{
				Header: "Amount Of Rejected Debit",
				accessor: "AmountOfRejectedDebit",
				type: GridColumnType.Money
			},
			{
				Header: "Amount Of Rejected Credit",
				accessor: "AmountOfRejectedCredit",
				type: GridColumnType.Money
			}
		];

		this.columnsDetail = [
			{
				Header: "RowID",
				accessor: "RowId"
			},
			{
				Header: "Reference Number",
				accessor: "ReferenceNumber"
			},
			{
				Header: "Is Processed",
				accessor: "IsProcessed",
				type: GridColumnType.YesNo
			},
			{
				Header: "Is Success",
				accessor: "IsSuccess",
				type: GridColumnType.YesNo
			},
			{
				Header: "Is Header",
				accessor: "IsHeader",
				type: GridColumnType.YesNo
			},
			{
				Header: "Process Date",
				accessor: "ProcessDate",
				type: GridColumnType.DateTime
			},
			{
				Header: "Error Code",
				accessor: "ErrorCode"
			},
			{
				Header: "Error Desc",
				accessor: "ErrorDesc"
			},
			{
				Header: "Comment",
				accessor: "Comment"
			},
			{
				Header: "RowData",
				accessor: "Row"
			}
		];

		this.emptyObject = {};

		this.isBackOffice = ClientHelper.IsBackOffice();

		this.state = {
			model: {},
			list: [],
			listDetail: [],
			isBackOffice: false
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("REPORTS");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("Bulk File Upload");
		}

		var temp = ClientHelper.IsBackOffice();
		this.setState({ isBackOffice: temp });
	}

	ValueChanged = (name, value) => {
		this.setState(state => {
			var model = state.model;
			model[name] = value;

			return { model };
		});
	}

	RowSelected = async (index) => {
		const { list } = this.state;
		const { ExecuteApiPost } = this.props;
		var model = list[index];
		var result = await ExecuteApiPost("/bankapi/v1.0/BulkFile/SearchDetail", model);

		this.setState({ model, listDetail: result || [], selected: index });
	}

	RenderItemUniqueClient = (d) => {
		return `${d.UniqueClientId} - ${d.Name}`;
	}

	Search = async () => {
		var { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/bankapi/v1.0/BulkFile/Search", model);

		this.setState({ model, list: result || [] });
	}

	Submit = async () => {
		if (!this.Validate()) {
			return;
		}

		const { model } = this.state;
		const { ExecuteApiFileUpload } = this.props;

		var formData = new FormData();
		formData.append("file", model.File, model.FileName);
		formData.append("fileFormatId", model.FileFormatId);
		formData.append("uniqueClientId", model.UniqueClientId);
		formData.append("autoGenerateCustomer", model.AutoGenerateCustomer || false);
		formData.append("notify", model.Notify || false);
		formData.append("uploadResponseToSftp", model.UploadResponseToSftp || false);

		await ExecuteApiFileUpload("/bankapi/v1.0/BulkFile/Insert", formData, null, true);

		this.Clear();
		this.Search();
	}

	Clear = () => {
		this.setState({ model: {}, list: [], listDetail: [], selected: null });
	}

	Upload = () => {
		this.fileInputRef.current.click();
	}

	FileSelect = (e) => {
		e.preventDefault();

		var file = e.target.files[0];
		if (!file) {
			return;
		}

		var { model } = this.state;
		model.File = file;
		model.FileName = file.name;

		this.setState({ model });
	}

	ReProcess = async (Id) => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/bankapi/v1.0/BulkFile/ReProcess", { Id: Id });
		this.Clear();
	}

	Validate() {
		var { model } = this.state;
		const { showValidationErrors } = this.props;
		var errors = [];

		if (this.isBackOffice && (!model.UniqueClientId || model.UniqueClientId == -1)) {
			errors.push("Client cannot be empty.");
		}

		if (!model.FileFormatId) {
			errors.push("File format cannot be empty.");
		}

		if (model.FileName == null
			&& model.File == null) {
			errors.push("Bulk file cannot be empty.");
		}

		model["IsUniqueClientId"] = this.isBackOffice && (!model.UniqueClientId || model.UniqueClientId == -1);
		model["IsFileFormatId"] = !model.FileFormatId;

		if (errors.length > 0) {
			showValidationErrors(errors);

			return false;
		}

		return true;
	}

	render() {
		const { model, list, listDetail, selected } = this.state;
		const { Disabled } = this.props;

		return (
			<div>

				{this.state.alert}
				<LoadingComponent Show={this.state.isLoading} />

				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},
						{
							Code: "Submit",
							Disabled: Disabled || this.isBackOffice,
							OnClick: this.Submit,
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						},
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<input
					type="file"
					key={model.FileName}
					style={{ display: "none" }}
					onChange={this.FileSelect}
					ref={this.fileInputRef}
					accept=".csv" />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Bulk File Upload">
									<GridContainer>
										{this.isBackOffice && (
											<GridItem xs={3} >
												<GenericSelectInput
													All
													CanClear
													Required={this.isBackOffice}
													IsInvalid={model.IsUniqueClientId}
													Name="UniqueClientId"
													LabelText="Client"
													Method="POST"
													Url="/bankapi/v1.0/BankCustomer/Search"
													Parameter={this.emptyObject}
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={this.RenderItemUniqueClient}
													Value={model.UniqueClientId}
													ValueChanged={this.ValueChanged} />
											</GridItem>
										)}
										<GridItem xs={3}>
											<GenericSelectInput
												Required
												IsInvalid={model.IsFileFormatId}
												Name="FileFormatId"
												LabelText="File Format"
												Url="/bankapi/v1.0/BulkFile/ListFileFormat"
												Method="POST"
												Parameter={this.emptyObject}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Description"
												Value={model.FileFormatId}
												ValueChanged={this.ValueChanged} />
										</GridItem>

										<GridItem xs={3}>
											<GenericDateInput
												Utc
												Name="FromDate"
												LabelText="From Date"
												Value={model.FromDate ? DateHelper.ToDateInputValue(model.FromDate) : ""}
												ValueChanged={this.ValueChanged}
												MaxDate={model.FromDate || DateHelper.GetDate()}
												IncludeTime={false}
											/>

											<GenericDateInput
												Utc
												Name="ToDate"
												LabelText="To Date"
												Value={model.ToDate ? DateHelper.ToDateInputValue(model.ToDate) : ""}
												ValueChanged={this.ValueChanged}
												MinDate={model.FromDate}
												MaxDate={DateHelper.GetDate()}
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericCheckInput
												Name="AutoGenerateCustomer"
												LabelText="Auto Generate Customer"
												Value={model.AutoGenerateCustomer}
												ValueChanged={this.ValueChanged} />
											<GenericCheckInput
												Name="Notify"
												LabelText="Notify"
												Value={model.Notify}
												ValueChanged={this.ValueChanged} />
											<GenericCheckInput
												Name="UploadResponseToSftp"
												LabelText="Upload Response To Sftp"
												Value={model.UploadResponseToSftp}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={2}>
											<GenericLabel Text="Upload File (csv only)" />
											<Button onClick={this.Upload} disabled={Disabled} style={{ marginLeft: "16px" }}>Browse</Button>
											<GenericLabel Text={model.FileName} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columns}
													SelectedIndex={selected}
													RowSelected={this.RowSelected} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
						<Card className="no-radius" style={{ display: listDetail.length == 0 ? "none" : "inherit" }}>
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													ShowPagination={true}
													PageSize={10}
													Data={listDetail}
													Columns={this.columnsDetail} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

BulkFileUpload.propTypes = {
	setAppCenterTitle: PropTypes.func,
	setAppLeftTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	ExecuteApiFileUpload: PropTypes.func,
	ExecuteApiFileDownloadWithGenericResponse: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default BulkFileUpload;