import { DialogContent, Slide } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import ClientHelper from "core/ClientHelper.js";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import { DetailIcon } from "core/Icons";
import { TaskPool } from "core/TaskPool";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import { IdentificationMethod, KYCManualApplicationStatus, KycManualApplicationReasonType } from "views/Constants/Constant";
import ManualKycApplication from "./ManualKycApplication";


function Transition(props) {
	return <Slide direction="up" {...props} />;
}

const initialState = {

	model: {
		InPersonIDVerificationEnable: false,
		CanadaPostVerificationEnable: false,
		ExternalAccount: {
			FinancialInstitutionId: 0,
			FinancialInstitutionBranchId: 0,
			AccountNumber: null
		},
		CustomerId: 0,
		CustomerNumber: "",
		UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined,
	},
	stickyContent: null,
	manualKycModel: {},
	modelList: [],
	isLoading: false,
	IsActiveLoanPurposeDescription: false,
	IdVerificationTypeList: [],
	hasChanged: false,
	isKycManualDialogOpen: false,
	reasonTypeList: []
};

class ManualKycApplicationList extends React.Component {
	constructor(props) {
		super(props);
		this.state = Object.assign({}, initialState);
		this.parameterIdentificationMethod = { ParameterCode: "IdentificationMethod" };
		this.emptyObject = {};
		this.GetIdentityVerificationType = this.GetIdentityVerificationType.bind(this);
		this.loadTaskPool = new TaskPool(this.handleLoadTaskPoolAppend, this.handleLoadTaskPoolCompleted);
		this.sortName = { Member: "Name" };
		this.getappCenterTitle = "Empty";
		this.getappLeftTitle = "Empty";
		this.ColumnsData = [
			{
				Header: "Actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip="Open Detail"
							Icon={DetailIcon}
							OnClick={this.KycDetailDialogOpen(row.original)} />
					</div>
				),
				width: 50,
				sortable: false,
				filterable: false,
			},
			{
				Header: "Date",
				accessor: "InsertDate",
				width: 50,
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{Formatter.FormatDate(row.value)}</div>)
			},
			{
				Header: "Full Name",
				accessor: "FullName",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{row.value}</div>)
			},
			{
				Header: "BirthDate",
				accessor: "BirthDate",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{Formatter.FormatDate(row.value)}</div>)
			},
			{
				Header: "ID Verification Status",
				accessor: "IdVerificationStatus",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{row.value ? "Valid" : "Invalid"}</div>)

			},
			{
				Header: "Internal Blacklist Status",
				accessor: "InternalBlacklistStatus",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{row.value ? "Valid" : "Invalid"}</div>)

			},
			{
				Header: "Watch List Verification Status",
				accessor: "WatchListVerificationStatus",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{row.value ? "Valid" : "Invalid"}</div>)

			},
			{
				Header: "Trans Union Id Verification Status",
				accessor: "TransUnionIdVerificationStatus",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{row.value ? "Valid" : "Invalid"}</div>)

			},
			{
				width: 100,
				Header: "Canada Post Verification Id Status",
				accessor: "CanadaPostVerification",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{row.value}</div>)

			},
			{
				width: 140,
				Header: "Digital Verification Status",
				accessor: "DigitalIdentityVerificationStatus",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{row?.original?.DigitalIdentityVerificationId > 0 ? row?.value ? "Valid" : "Invalid" : ""}</div>)
			},
			{
				width: 120,
				Header: "Digital Verification Request Status",
				accessor: "DigitalIdentityVerificationRequestStatus",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{row.value}</div>)
			},
			{
				width: 100,
				Header: "Wallet Verification Status",
				accessor: "AccountVerificationStatus",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{row?.original?.CustomerLinkedAccountId > 0 ? row?.value ? "Valid" : "Invalid" : ""}</div>)
			},
			{
				width: 120,
				Header: "Wallet Verification Request Status",
				accessor: "AccountVerificationRequestStatus",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{row.value}</div>)
			},
			{
				Header: "Approvel Status",
				accessor: "ParameterStatus",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{row.value}</div>)
			},
			{
				Header: "Reason",
				accessor: "ReasonTypeId",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{this.GetReasonTypeParameterById(row.value)?.ParameterDesc}</div>)
			},
			{
				Header: "Comment",
				accessor: "ReasonComment",
				width: 120,
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{row.value}</div>)
			},
			{
				Header: "Identity Number",
				accessor: "IdentityNumber",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{row.value}</div>)
			},
			{
				Header: "Identity Type",
				accessor: "IdentityTypeName",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{row.value}</div>)
			},
			{
				Header: "External AccountNumber",
				accessor: "ExternalAccountNumber",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{row.value}</div>)
			},
			{
				Header: "Identity  Country",
				accessor: "IdentityCountryName",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{row.value}</div>)
			},

			{
				Header: "Identity Province",
				accessor: "IdentityProvinceName",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{row.value}</div>)
			},
			{
				Header: "E-Mail",
				accessor: "Email",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{row.value}</div>)
			},
			{
				Header: "Address1",
				accessor: "Address1",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{row.value}</div>)
			},

			{
				Header: "City",
				accessor: "CityName",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{row.value}</div>)
			},
			{
				Header: "Country",
				accessor: "CountryName",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{row.value}</div>)
			},
			{
				Header: "Province",
				accessor: "ProvinceName",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{row.value}</div>)
			},

			{
				Header: "Mobile Phone",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{row.original.MobilePhoneNumber}</div>)

			}

		];
	}

	componentDidMount() {
		if (this.props.setAppLeftTitle) {
			this.props.setAppLeftTitle("Manual KYC Application List");this.getappLeftTitle = "Manual KYC Application List";
		}
		if (this.props.setAppCenterTitle) {
			this.props.setAppCenterTitle("AML & KYC");this.getappCenterTitle = "AML & KYC";
		}
		this.GetIdentityVerificationType();
		this.LoadCanadaPost();
		this.GetReasonTypeList();
	}
	GetReasonTypeList = async () => {
		var result = await this.props.ExecuteApiPost("/coreapi/v1.0/Parameter/Search", { ParameterCode: KycManualApplicationReasonType.CODE });
		this.setState({ reasonTypeList: result || [] });
	}
	GetReasonTypeParameterById = (id) => {
		const { reasonTypeList } = this.state;
		return reasonTypeList.find(a => a.Id == id);
	}

	KycDetailDialogOpen = (model) => () => {

		this.setState({ isKycManualDialogOpen: true, manualKycModel: model });

	}
	GetColumnsRowStyleColor(status) {
		this.columnsRowStyleColor = {
			color: status != null && status == KYCManualApplicationStatus.OnHold
				? "#FB3005"
				: ""
		};

		return this.columnsRowStyleColor;
	}
	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };

		if (name == "IdentityVerifiedPersonId" && ((model.IdentityVerifiedPersonId == null || model.IdentityVerifiedPersonId == 0) ||
			(model.IdentityVerifiedPerson == null))) {
			model["IdentityVerifiedPersonId"] = ClientHelper.GetUser().Id;
			model["IdentityVerifiedPerson"] = ClientHelper.GetUser();
		}
		if (name == "IdentityVerificationTypeId" && data != null) {
			model.CanadaPostVerificationEnable = false;

			model.InPersonIDVerificationEnable = false;
			if (data.ParameterValue == IdentificationMethod.InPersonIDVerification) {
				model.InPersonIDVerificationEnable = true;
			}
			if (data.ParameterValue == IdentificationMethod.CanadaPostVerification) {
				model.CanadaPostVerificationEnable = true;
			}

		}
		model[name] = newValue;
		this.setState({ model });
	}
	SelectedRowChange = (e, index) => {
		const model = this.state.modelList[index];
		if (model.IdentityVerificationType != null && model.IdentityVerificationType.ParameterValue == IdentificationMethod.InPersonIDVerification) {
			model.InPersonIDVerificationEnable = true;
		} else {
			model.InPersonIDVerificationEnable = false;
			model.ExternalAccount = {
				FinancialInstitutionId: model.FinancialInstitutionId,
				FinancialInstitutionBranchId: model.FinancialInstitutionBranchId,
				AccountNumber: model.ExternalAccountNumber
			};
		}

		let appliedForModel = {};
		this.state.IsActiveLoanPurposeDescription = false;
		if (model.ApplicantAppliedFor != null) {
			appliedForModel = model.ApplicantAppliedFor;
			if (appliedForModel.LoanPurpose != null && appliedForModel.LoanPurpose.ParameterDesc === "Other") {
				this.state.IsActiveLoanPurposeDescription = true;
			}
		}
		this.setState({ model: model });

	}
	HandleSearch = () => {
		const model = this.state.model;
		this.setState({ isLoading: true });

		this.loadTaskPool.AppendTask(
			Proxy.POST(
				"/amlkycapi/v1.0/KycManualApplication/Search",
				model,
				responseData => {
					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						this.setState({ isLoading: false });

						return;
					}
					var modelList = responseData.Item || [];
					this.setState({ modelList, isLoading: false });
				},
				error => {
					this.ShowMessage("error", "Error", error);
					this.setState({ isLoading: true });

				}
			));
	}
	HandleClear = () => {
		this.setState({ modellist: [], model: {}, selected: null });
	}
	GetFullName = () => {
		const model = { ...this.state.model };
		let fullName = "";
		if (model.FirstName == null || model.FirstName == undefined) { return fullName; }
		fullName = model.FirstName;
		if (model.MiddleName != null && model.MiddleName !== "") { fullName += " " + model.MiddleName; }
		if (model.LastName != null && model.LastName !== "") { fullName += " " + model.LastName; }
		return fullName;
	}
	async GetIdentityVerificationType() {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/coreapi/v1.0/Parameter/Search", this.parameterIdentificationMethod, null, null, null, null, null);
		var IdVerificationTypeList = [];

		if (result != null) {
			result
				.filter(x => x.ParameterValue === IdentificationMethod.CanadaPostVerification || x.ParameterValue === IdentificationMethod.InPersonIDVerification)
				.map(x => {
					IdVerificationTypeList.push(x);
				});
		}
		this.setState({ IdVerificationTypeList });
	}
	LoadCanadaPost() {

		var parent = this;
		this.loadTaskPool.AppendTask(
			Proxy.POST("/coreapi/v1.0/Parameter/Search",
				{
					ParameterCode: "CanadaPostApiKey",
					ParameterValue: "CP"
				},
				responseData => {
					if (!responseData.IsSucceeded) {
						console.log("Canada Post Api key not fetched !");
						return;
					}
					if (responseData.Item == null) {
						console.log("Canada Post Api key not fetched !");
						return;
					}
					if (responseData.Item[0] == null) {
						console.log("Canada Post Api key not fetched !");
						return;
					}
					var apikey = responseData.Item[0].ParameterDesc;
					console.log("KEY CP ", apikey);


					var url = window.CanadaPostAddressCompleteUrl;
					var path = url + apikey;

					var pca;
					var CanadaPostRequest=this.SubmitCanadaPostRequest;
					var CanadaPostSubmit = this.SubmitCanadaPostAddressModel;
					const script = document.createElement("script");
					script.src = path;
					script.async = false;
					document.head.appendChild(script);
					script.onload = function () {
						pca = window.pca;
						if (pca != null) {
							var fields =
								[
									{ element: "street-address", field: "Line1", mode: pca.fieldMode.SEARCH },
									{ element: "street-address2", field: "Line2", mode: pca.fieldMode.POPULATE },
									{ element: "postcode", field: "PostalCode", mode: pca.fieldMode.SEARCH },
								],
								options = {
									key: apikey
								},
								control = new pca.Address(fields, options);
								CanadaPostRequest();
							control.listen("populate", function (address) {
								CanadaPostSubmit(address);
								parent.setState(state => {
									state.model.Address1 = address.Line1;
									state.model.Address2 = address.Line2;
									state.model.ZipCode = address.PostalCode;
									return state;
								});
							});
						}
					};
				},
				e => {
					console.log("Canada Post Api key not fetched !");
				}
			));
	}
	SubmitCanadaPostAddressModel = (model) => {
		this.loadTaskPool.AppendTask(
			Proxy.POST(
				"/coreapi/v1.0/Country/CanadaPost",
				model,
				responseData => {
					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					var address = { ...this.state.model };
					if (responseData.Item != null) {
						address.CountryId = responseData.Item.CountryId;
						address.ProvinceId = responseData.Item.ProvinceId;
						address.CityId = responseData.Item.CityId;
					} else {
						address.CountryId = 0;
						address.ProvinceId = 0;
						address.CityId = 0;
					}
					this.setState({ model: address });
				},
				error => {
					this.ShowMessage("error", "Error", error);
				}
			));
	}
	SubmitCanadaPostRequest = async () => {
		const model = { ...this.state.model };
		var requestdata = { 

			UniqueClientId: null,
			MenuCode:this.props.MenuCode,
			AppCenterTitle:this.getappCenterTitle,
			AppLeftTitle:this.getappLeftTitle
		};
		Proxy.POST(
			"/bankapi/v1.0/Reporting/CanadaPostRequestReportInsert",
			requestdata,
			responseData => {
				if (responseData.Item != null ) {
					 
				}
			},
 		);
		// var result = await this.props.ExecuteApiPost("/bankapi/v1.0/Reporting/CanadaPostRequestReportInsert", requestdata);
	}
	ErrorCallback = (error) => {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", "An error occurred during the api visit" + error);
	}

	ShowMessage = (type, title, message) => {
		type = "info";
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}
	ShowQuestionMessage = (message, model) => {
		this.setState({
			alert: <GenericAlert Title="Delete" Message={message} Type="warning" ShowCancel={true} OnCancel={() => this.HideAlert()} OnConfirm={() => this.DeleteModel(model)} />
		});
		this.setState({ isLoading: false });
	}
	showBasicAlert = (title, message, type) => {
		this.setState({ isLoading: false, alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={false} OnConfirm={() => this.HideAlert()} /> });
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}
	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: null })} OnConfirm={onConfirm} />
		});
	}

	HideAlert = () => {
		this.setState({ alert: null });
	}

	ShowMessageAndRefresh = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlertAndRefresh()} />
		});
	}
	KycManualDialogClose = () => {

		this.setState({ isKycManualDialogOpen: false });
	}
	hideAlertAndRefresh = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
		window.location.reload();

	}
	SetStickContent = (content, reRender) => {
		if (this.state.stickyContent && !reRender) return;
		this.setState({ stickyContent: content });
	}
	render() {
		const { model, modelList, alert, isLoading, isKycManualDialogOpen, stickyContent } = this.state;
		const { Disabled } = this.props;

		return (
			<div>
				<LoadingComponent Show={isLoading} />
				{alert}
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.HandleSearch
						},

						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.HandleClear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GenericDialog open={isKycManualDialogOpen} fullScreen onBackdropClick={this.KycManualDialogClose} TransitionComponent={Transition}>
					{stickyContent}
					<DialogContent>
						<ManualKycApplication
							Disabled
							ExecuteApiFileDownloadWithGenericResponse={this.props.ExecuteApiFileDownloadWithGenericResponse}
							ExecuteApiFileUpload={this.props.ExecuteApiFileUpload}
							ExecuteApiPost={this.props.ExecuteApiPost}
							ExecuteApiGet={this.props.ExecuteApiGet}
							taskPool={this.props.taskPool}
							menuId={this.props.menuId}
							ManualKycModel={this.state.manualKycModel}
							MenuCode={this.props.MenuCode}
							ApprovalData={this.props.ApprovalData}
							showConfirmMessage={this.props.showConfirmMessage}
							showMessage={this.props.showMessage}
							onStickContent={this.SetStickContent}
							After={this.KycManualDialogClose} />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.KycManualDialogClose}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>
				<GridContainer>
					{alert}
					<GridItem xs={12}>
						<Card>
							<GenericExpansionPanel IsActive={true} Title="Filter Panel" >
								<CardBody>
									<GridContainer >
										<GridItem xs={3}>
											<GenericDateInput
												Utc
												Name="StartDate"
												LabelText="Start Date"
												Value={model.StartDate ? DateHelper.ToDateInputValue(model.StartDate) : ""}
												ValueChanged={this.HandleChange}
												Disabled={false}
												IsFuture={false} />
										</GridItem>
										<GridItem xs={3}>
											<GenericDateInput
												Utc
												Name="EndDate"
												LabelText="End Date"
												Value={model.EndDate ? DateHelper.ToDateInputValue(model.EndDate) : ""}
												ValueChanged={this.HandleChange}
												Disabled={false}
												MinDate={model.StartDate} />
										</GridItem>
									</GridContainer>

								</CardBody>
							</GenericExpansionPanel>
						</Card>

						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel
									Name={"panelManualKycApplicationListActive"}
									Title="Manual KYC Application List"
								>
									<GenericGrid
										Data={modelList}
										PageSize={5}
										HideButton={true}
										Sorted={[{ id: "Status", desc: false }]}
										Columns={this.ColumnsData}
										RowSelected={index => {
											this.SelectedRowChange(0, index);
										}}
										DataRoot="Item"
									/>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

ManualKycApplicationList.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default (ManualKycApplicationList);