import { Card } from "@material-ui/core";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericDateInput, GenericNumberInput, GenericTextInput } from "views/Components/Generic";

class Statistics extends Component {
	constructor(props) {
		super(props);

		this.state = {
		};
	}

	componentDidMount() {
	}

	RenderItemAccount = (d) => {
		this.renderItemAccount = `${d.AccountNumber} - ${d.AccountName}`;
		return this.renderItemAccount;
	}

	render() {
		const { model, ReadOnly, Disabled } = this.props;

		return (
			<Card className="no-radius">
				<CardBody>
					<GridContainer>
						<GridItem xs={12}>
							<GridContainer>
								<GridItem xs={3} >
									<GenericDateInput
										Name="FirstCardEmbossDate"
										LabelText="First Card Emboss Date"
										Value={model.FirstCardEmbossDate ? DateHelper.ToDateInputValue(model.FirstCardEmbossDate) : ""}
										Disabled={Disabled || ReadOnly} />
								</GridItem>
								<GridItem xs={3} >
									<GenericDateInput
										Name="FirstCardUsedDateTime"
										LabelText="First Card Used Date"
										Value={model.FirstCardUsedDateTime ? DateHelper.ToDateInputValue(model.FirstCardUsedDateTime) : ""}
										Disabled={Disabled || ReadOnly} />
								</GridItem>
								<GridItem xs={3} >
									<GenericDateInput
										Name="FirstUsageDateTime"
										LabelText="First Usage Date"
										Value={model.FirstUsageDateTime ? DateHelper.ToDateInputValue(model.FirstUsageDateTime) : ""}
										Disabled={Disabled || ReadOnly} />
								</GridItem>
								<GridItem xs={3} >
									<GenericDateInput
										Name="FirstApprovedDateTime"
										LabelText="First Approved Date"
										Value={model.FirstApprovedDateTime ? DateHelper.ToDateInputValue(model.FirstApprovedDateTime) : ""}
										Disabled={Disabled || ReadOnly} />
								</GridItem>
								<GridItem xs={3} >
									<GenericDateInput
										Name="LastTransactionDateTime"
										LabelText="Last Transaction Date"
										Value={model.LastTransactionDateTime ? DateHelper.ToDateInputValue(model.LastTransactionDateTime) : ""}
										Disabled={Disabled || ReadOnly} />
								</GridItem>
								<GridItem xs={3}>
									<GenericTextInput
										Name="LastTransactionMerchant"
										LabelText="Last Transaction Merchant"
										IsText={true}
										Value={model.LastTransactionMerchant}
										Disabled={Disabled || ReadOnly} />
								</GridItem>
								<GridItem xs={3}>
									<GenericNumberInput
										Name="LastTransactionAmount"
										LabelText="Last Transaction Amount"
										Value={model.LastTransactionAmount}
										Prefix="$"
										MaxLength={12}
										Disabled={Disabled || ReadOnly} />
								</GridItem>
							</GridContainer>
						</GridItem>
					</GridContainer>
				</CardBody>
			</Card>
		);
	}
}

Statistics.propTypes = {
	classes: PropTypes.object.isRequired,
	model: PropTypes.object,
	showMessage: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(Statistics, {});