import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React from "react";
import ReactTable from "react-table";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateFilterInput, GenericExpansionPanel } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";

class LoanAgingAndDelinquentPayment extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			DateFilter: {
				BeginDate: DateHelper.GetDateLocal(),
				EndDate: DateHelper.GetDateLocal()
			}
		};

		this.state = {
			list: [],
			model: this.initialModel,
			isLoading: false,
		};

		this.ColumnsData = [];
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Loan Aging & Delinquent Payment Report");
		this.props.setAppCenterTitle("LOAN MANAGEMENT SYSTEM");

		this.GetColumns();
	}

	HandleChange = (name, value, data) => {
		const model = { ...this.state.model };
		model[name] = value;
		this.setState({ model });
	}

	HandleClear = () => {
		this.setState({ model: this.initialModel, selected: null });
	}

	HandleSearch = () => {
		if (!this.Validate())
			return;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/losapi/v1.0/CollectionPool/LoanAgingAndDelinquentPaymentReport",
			this.state.model,
			this.SuccessListCallback,
			this.ErrorListCallback
		);
	}

	SuccessListCallback = (responseData) => {
		let { list } = this.state;

		this.setState({ isLoading: false });
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error", responseData.ErrorDescription);
			return;
		}
		if (responseData.Item !== null) {
			list = responseData.Item.map(item => {
				let obj = {
					CustomerName: item.CustomerName,
					ClientCustomerNumber: item.ClientCustomerNumber
				};

				for (var i = 0; i < item.DelayDayAmounts.length; i++) {
					let delayDayAmount = item.DelayDayAmounts[i];
					obj[delayDayAmount.Key] = delayDayAmount.Value;
				}

				return obj;
			});
			this.setState({ list });
		}
	}

	ErrorListCallback = (error) => {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", error);
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	Validate = () => {
		return true;
	}

	SumValues = (...arr) => {
		return arr.length > 0 ? arr[0].reduce((a, b) => a + b, 0) : 0;
	}

	GetColumns = () => {
		const columnHeaders = [
			{
				Header: "Customer Name",
				accessor: "CustomerName",
				width: 175
			},
			{
				Header: "Customer Number",
				accessor: "ClientCustomerNumber",
				width: 175
			}
		];

		this.setState({ isLoading: true });
		Proxy.GET(
			"/losapi/v1.0/CollectionPool/GetAllDelayDayRange",
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				if (responseData.Item != null && responseData.Item.length > 0) {
					responseData.Item.map(x => {
						columnHeaders.push(
							{
								Header: x.FlexValue4,
								columns: [
									{
										Header: "Unpaid Principal",
										accessor: `${x.FlexDesc}_UnpaidPrincipal`,
										Cell: row => Formatter.FormatMoney(row.value ?? 0),
										width: 140
									},
									{
										Header: "Unpaid Interest",
										accessor: `${x.FlexDesc}_UnpaidInterest`,
										Cell: row => Formatter.FormatMoney(row.value ?? 0),
										width: 125
									},
									{
										Header: "Unpaid Fee",
										accessor: `${x.FlexDesc}_UnpaidFee`,
										Cell: row => Formatter.FormatMoney(row.value ?? 0),
										width: 100
									}
								]
							}
						);
						return null;
					});
				}
				this.ColumnsData = columnHeaders;
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	render() {
		const { classes, Disabled } = this.props;
		const { alert, isLoading, model, list } = this.state;
		return (
			<>

				<LoadingComponent Show={isLoading} />

				{alert}

				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled || model == this.initialModel },
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<GridContainer spacing={16}>

					<GridItem xs={12}>
						<Card >
							<CardBody>
								<GenericExpansionPanel Title="Filter Panel">
									<GridContainer>
										<GridItem xs={3}>
											<GenericDateFilterInput
												Name="DateFilter"
												LabelText="Date Filter"
												Model={model.DateFilter}
												BeginDate={model.BeginDate}
												EndDate={model.EndDate}
												ValueChanged={this.HandleChange}
												IncludeTime={false} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>

					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Transaction List">
									<GridContainer>
										<GridItem xs={12}>
											<ReactTable
												className={`${classes.table} ${"-striped -highlight"}`}
												data={list}
												filterable={false}
												columns={this.ColumnsData}
												showPaginationTop={false}
												showPaginationBottom={false}
												minRows={2} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</>
		);
	}
}

LoanAgingAndDelinquentPayment.propTypes = {
	classes: PropTypes.object
};

export default withArtifex(LoanAgingAndDelinquentPayment, {});