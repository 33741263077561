import { DialogContent, DialogTitle, Slide } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import createEtransferIndividualStyle from "assets/jss/material-dashboard-pro-react/views/createEtransferIndividualStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericCheckInput, GenericDateInput, GenericDialog, GenericDialogActions, GenericEmailInput, GenericExpansionPanel, GenericLabel, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import { EtransferEntryType, EtransferTransactionTypes, ProgramCodes, DcbETransferType } from "views/Constants/Constant.js";
import AddNewCustomereTransfer from "views/InteraceTransfer/AddNewCustomereTransfer.jsx";
import DateHelper from "core/DateHelper";
import ParameterComponent from "views/Components/ParameterComponent";

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

const sortName = { Member: "Name" };
const sortMemo = { Member: "Memo" };

const ClientParamObj = {
	ProgramCode: ProgramCodes.Bank_Programs_e_Transfer,
	UniqueClientId: undefined
};
const lengthSecurityAnswer = { maxLength: 25 };
const ParameterMaxLength400 = { maxLength: 400 };
const ParameterMaxLengthSecurityQuestion = { maxLength: 40 };

class CreateEtransferIndividual extends Component {

	constructor(props) {
		super(props);

		this.state = {
			customerToken: "0",
			maxExpDate: DateHelper.GetDateLocal().add(this.props.model.maxExpireDate, "d")
		};

		this.isClient = ClientHelper.IsClient();

		this.ValueChanged = this.ValueChanged.bind(this);
		this.addCustomerDialogClose = this.addCustomerDialogClose.bind(this);
		this.addCustomerSaved = this.addCustomerSaved.bind(this);
		this.RenderClientSelect = this.RenderClientSelect.bind(this);
		this.GetClientParamObj = this.GetClientParamObj.bind(this);
	}

	componentDidMount() {
		var model = { ...this.props.model };
		if (model != null && model.Id != null && model.Id > 0) {
			this.IsUpdate();
		}
	}

	IsNullOrEmpty(value) {
		return (value == null || value === "" || value === ''
			|| value === '""' || value === '""');
	}

	IsUpdate = () => {
		var model = { ...this.props.model };
		var serviceType = null;
		if (model.NotificationType == null || model.NotificationType.ParameterValue3 == null || model.NotificationType.ParameterValue3 == "") {
			this.props.showMessage("error", "Error", "Customer Notification Type cannot found !");
			return;
		}
		else {
			serviceType = model.NotificationType.ParameterValue3;
		}

		if (serviceType == 0 && (model.PayeeEmail == null || model.PayeeEmail == undefined)) {
			this.props.showMessage("error", "Error", "Customer Email cannot found !");
			return;
		}

		if (serviceType == 1 && (model.PhoneNumber == null || model.PhoneNumber == undefined)) {
			this.props.showMessage("error", "Error", "Customer Phone cannot found !");
			return;
		}

		if(serviceType == 2) {
			if (this.IsNullOrEmpty(model.PhoneNumber)) {
				this.props.showMessage("error", "Error", "Customer Phone Number cannot be null !");
				return;
			}
			
			if (this.IsNullOrEmpty(model.PayeeEmail)) {
				this.props.showMessage("error", "Error", "Customer Email cannot be null !");
				return;
			}
		}

		this.FetchInteracGetTransferOptions(model.PayeeEmail, model.PhoneNumber, serviceType);
	}

	FetchInteracGetTransferOptions = (eMail, phoneNumber, serviceType) => {
		var request = {};

		if (serviceType == 0 && (eMail == null || eMail == "")) {
			this.props.showMessage("error", "Error", "Customer Email cannot be null !");
			return;
		}

		if (serviceType == 1 && (phoneNumber == null || phoneNumber == "")) {
			this.props.showMessage("error", "Error", "Customer Phone Number cannot be null !");
			return;
		}

		request.UniqueClientId = this.props.parentModel.UniqueClientId;
		request.DirectDepositHandle = serviceType == 0 ? eMail : phoneNumber;
		request.ServiceType = serviceType;
		Proxy.POST("/bankapi/v1.0/BankInteracETransfer/GetTransferOptions",
			request,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", "An error occurred while request " + responseData.ErrorDescription);
					return;
				}
				this.setState({ InteracCustomerTrsnaferOprion: responseData.Item });
				this.props.onModelChange(model => {
					model.HasDDR = responseData.Item;
					return model;
				});
			},
			e => {
				this.props.showMessage("error", "Error", "An error occurred while inserting data ");
			}
		);
	}

	FetchInteracGetTransferTypes= (payeeId) => {
		Proxy.GET(`/bankapi/v1.0/BankInteracETransfer/GetTransferTypes/${payeeId}`,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", "An error occurred while request " + responseData.ErrorDescription);
					return;
				}

				const transferMethod = responseData.Item?.find(tt => tt.TransferType === DcbETransferType.Default)?.TransferMethod;

				this.props.onModelChange(model => {
					model.TransferMethod =  transferMethod;
					return model;
				});
				this.setState({ TransferTypes: responseData.Item });
			}
		);
	}	

	ValueChanged(name, newValue, data) {
		this.props.onModelChange(model => {
			model[name] = newValue;

			if (name == "PayeeId") {
				model.PayeeName = data == null ? "" : data.Name;
				model.PayeeEmail = data == null ? null : data.PayeeEmail;
				//model.HasDDR = data == null ? null : data.HasDDR;
				model.PhoneNumber = data == null ? null : data.PhoneNumber;
				if (model.PayeeId != null && model.TransactionTypeCode == EtransferTransactionTypes.Credit && model.NotificationTypeId != null && model.NotificationTypeId != 0) {
					this.FetchInteracGetTransferOptions(model.PayeeEmail, model.PhoneNumber, model.ServiceType);
			}
				if (model.PayeeId != null){
					this.FetchInteracGetTransferTypes(model.PayeeId);
				}
			}
			if (name == "NewClient") {
				if (newValue) {
					model.IsCustomerPopupOpen = true;
					this.setState({ isCustomerAdded: false });
				}
			}
			if (name == "IsHaveQuestion") {
				if (newValue == true)
					model.SecurityQuestionText = "";
				else
					model.SecurityQuestion = 0;
			}
			if (name == "SecurityQuestionAnswer") {
				model[name] = newValue.replace(/\s/g, "");
			}
			if (name == "TransferMemoId" && data != null) {
				model.TransferMemo = data.Memo;
			}
			if (name == "IsHaveMemo") {
				if (newValue) model.TransferMemo = "";
				model.TransferMemoId = "";
			}
			if (name == "TransactionType") {
				model.TransactionTypeCode = data != null ? data.ParameterValue : null;
				if (model.TransactionTypeCode == EtransferTransactionTypes.Debit) {
					if (model.SecurityQuestionAnswer != null)
						model.SecurityQuestionAnswer = null;
					if (model.SecurityQuestionText != null)
						model.SecurityQuestionText = null;
					if (model.SecurityQuestion != null)
						model.SecurityQuestion = null;
				}
				if (model.PayeeId != null && model.TransactionTypeCode == EtransferTransactionTypes.Credit && model.NotificationTypeId != null && model.NotificationTypeId != 0)
				{
					this.FetchInteracGetTransferOptions(model.PayeeEmail, model.PhoneNumber, model.ServiceType);
				}
			}

			if (name == "TransferMethod") {
				var transferType = this.state.TransferTypes.find(tt => tt.TransferMethod === model.TransferMethod);

				model.TransferType = transferType.TransferType;
				model.BenficiaryId = transferType.BenficiaryId;
			}

			if (name == "DateOfFunds") {
				model.MoneyReqExpDate = DateHelper.ToMoment(newValue).add((this.props.model.maxExpireDate - 1), "d");
				this.setState({ maxExpDate: DateHelper.ToMoment(newValue).add(this.props.model.maxExpireDate, "d") });
			}

			if (name == "NotificationTypeId" && data != null) {
				model.ServiceType = data.ParameterValue3;

				if (model.PayeeId != null && model.TransactionTypeCode == EtransferTransactionTypes.Credit && model.NotificationTypeId != null && model.NotificationTypeId != 0)
					this.FetchInteracGetTransferOptions(model.PayeeEmail, model.PhoneNumber, model.ServiceType);
			}

			return model;
		});


	}

	addCustomerDialogClose() {
		this.ValueChanged("IsCustomerPopupOpen", false);
	}

	addCustomerSaved(customerData) {
		if (customerData != null) {
			this.props.onModelChange(model => {
				model.PayeeId = customerData.PayeeId;
				model.PayeeEmail = customerData.PayeeEmail;
				return model;
			});

			var newCustomerToken = this.state.customerToken == "1" ? "0" : "1";
			this.setState({ customerToken: newCustomerToken });
		}

		this.addCustomerDialogClose();
	}

	RenderClientSelect(d) {
		return d.Name;
	}
	RenderAccountSelect(d) {
		const accountNumber = d.Account && d.Account.AccountNumber;
		const accountName = d.Account && d.Account.AccountName;
		const storeCode = d.CustomerStore && d.CustomerStore.StoreCode;
		const storeName = d.CustomerStore && d.CustomerStore.StoreName;

		return `${storeName}(${storeCode}) - ${accountName}(${accountNumber})`;
	}
	GetClientParamObj() {
		const { parentModel } = this.props;
		ClientParamObj.ProgramCode = ProgramCodes.Bank_Programs_e_Transfer;
		ClientParamObj.UniqueClientId = parentModel.UniqueClientId;
		return ClientParamObj;
	}

	RenderTransferMethod(model) {
		let transferTypeDescription = model.TransferTypeDescription;

		if (model.TransferType == DcbETransferType.AccountDeposit) {
			transferTypeDescription += ` - ${model.TransferMethod}`;
		}

		if (model.TransferType == DcbETransferType.Default) {
			transferTypeDescription += ` - SMS/Email`;
		}
		
		return transferTypeDescription;
	}

	render() {
		const { model, vModel, Disabled: DisabledProp, parentModel, isBanking, CustomerStoreList } = this.props;
		const { maxExpDate } = this.state;

		const Selected = !parentModel.IsUpdate ? parentModel.EntryCode == EtransferEntryType.Individual : true;
		const Disabled = DisabledProp || !Selected;

		return (
			<div style={{ opacity: Selected ? 1.0 : 0.4 }}>
				<Card>
					<CardBody>
						<GenericExpansionPanel IsActive={Selected} Title="Individual Transaction Entry">
							<GridContainer>
								<GridItem xs={6}>
									<GridContainer>
										<GridItem xs={8}>
											{CustomerStoreList != null && CustomerStoreList.length > 0 &&
												<GenericSelectInput
													LabelMd={4}
													key={"CustomerStoreId" + this.state.customerToken + "_" + parentModel.UniqueClientId}
													Name="CustomerStoreId"
													LabelText="Store Wallet"
													IsStatic={true}
													StaticData={CustomerStoreList}
													DataRoot="Item"
													KeyValueMember="CustomerStoreId"
													RenderItem={this.RenderAccountSelect}
													Value={model.CustomerStoreId}
													ValueChanged={this.ValueChanged}
													Disabled={Disabled || parentModel.IsUpdate}
													IsInvalid={vModel.CustomerStoreId} />}
										</GridItem>
										<GridItem xs={4}></GridItem>

										<GridItem xs={8}>
											{isBanking == true ?
												<GenericSelectInput
													key={"PayeeId_Banking_" + parentModel.UniqueClientId}
													Name="PayeeId"
													LabelText="Contact Name"
													Method="POST"
													Url="/bankapi/v1.0/ClientCustomerPayee/Search"
													Parameter={{
														IsClientCustomerPayee: false,
														ProgramCode: ProgramCodes.Bank_Programs_Bank_Account,
														UniqueClientId: parentModel.UniqueClientId
													}}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="AliasName"
													Value={model.PayeeId}
													Disabled={true}
													Required />
												:
												<GenericSelectInput
													LabelMd={4}
													key={"PayeeId_" + this.state.customerToken + "_" + parentModel.UniqueClientId}
													Name="PayeeId"
													LabelText="Customer Name"
													Method="POST"
													Url="/bankapi/v1.0/ClientCustomer/GetAllMainPayeeCustomer"
													DataRoot="Item"
													Parameter={this.GetClientParamObj()}
													KeyValueMember="PayeeId"
													RenderItem={this.RenderClientSelect}
													Value={model.PayeeId}
													ValueChanged={this.ValueChanged}
													Disabled={Disabled || parentModel.IsUpdate}
													Sorted={sortName}
													Required
													IsInvalid={vModel.PayeeId} />
											}
										</GridItem>
										<GridItem xs={4}>
											{this.isClient && !parentModel.IsUpdate &&
												<GenericCheckInput
													Name="NewClient"
													LabelText="Add New Customer"
													Value={model.NewClient}
													ValueChanged={this.ValueChanged}
													Disabled={Disabled || parentModel.IsUpdate} />}
										</GridItem>

										<GridItem xs={8}>
											{model.ServiceType == 0 &&
												<GenericEmailInput
													LabelMd={4}
													Name="PayeeEmail"
													LabelText="Customer Email"
													Value={model.PayeeEmail}
													ValueChanged={this.ValueChanged}
													Disabled={true} />
											}
											{model.ServiceType == 1 &&
												<GenericTextInput
													LabelMd={4}
													Name="PayeePhone"
													LabelText="Customer Phone"
													Value={model.PhoneNumber}
													ValueChanged={this.ValueChanged}
													Disabled={true} />
											}
											{model.ServiceType == 2 &&
    											<>
     										  <GenericEmailInput
                                   LabelMd={4}
                                   Name="PayeeEmail"
                                   LabelText="Customer Email"
                                   Value={model.PayeeEmail}
                                   ValueChanged={this.ValueChanged}
                                   Disabled={true} />
                                  <GenericTextInput
                                  LabelMd={4}
                                  Name="PayeePhone"
                                  LabelText="Customer Phone"
                                  Value={model.PhoneNumber}
                                  ValueChanged={this.ValueChanged}
                                  Disabled={true} />
                                  </>
                                 }
										</GridItem>
										<GridItem xs={4}></GridItem>

										<GridItem xs={8}>
											<GenericSelectInput
												Name="TransactionType"
												LabelMd={4}
												LabelText="Transaction Type"
												Method="GET"
												Url="/bankapi/v1.0/Transaction/GetAllFinancialTransactionType"
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ParameterValue6"
												Value={model.TransactionType}
												ValueChanged={this.ValueChanged}
												Disabled={Disabled || parentModel.IsUpdate}
												Required
												IsInvalid={vModel.TransactionType} />
										</GridItem>
										<GridItem xs={4}></GridItem>
										{(model.TransactionType == 6020) && 
										<>
										<GridItem xs={8}>
												<GenericSelectInput
													Name="TransferMethod"
													LabelMd={4}
													LabelText="Transfer Type"
													KeyValueMember="TransferMethod"
													DataRoot="Item"
													Value={model.TransferMethod}
													RenderItem={this.RenderTransferMethod}
													StaticData={this.state.TransferTypes}
													IsStatic={true}
													ValueChanged={this.ValueChanged}
												Disabled={Disabled || parentModel.IsUpdate || !model.PayeeId}
												Required />
										</GridItem>

										<GridItem xs={4}></GridItem>
										</>}
										<GridItem xs={8}>
											<ParameterComponent
												LabelMd={4}
												Name="NotificationTypeId"
												Value={model.NotificationTypeId}
												LabelText="Notification Type"
												ParameterCode="NotificationChannel"
												ParameterValue2="ETRANSFER"
												ValueChanged={this.ValueChanged}
												Disabled={Disabled}
												Required
												IsInvalid={vModel.NotificationTypeId} />
										</GridItem>
										<GridItem xs={4}></GridItem>

									</GridContainer>
								</GridItem>
								<GridItem xs={6}>
									<GridContainer>
										<GridItem xs={8}>
											<GenericNumberInput
												LabelMd={4}
												Name="Amount"
												LabelText="Amount"
												Value={model.Amount}
												ValueChanged={this.ValueChanged}
												MaxLength={10}
												Disabled={Disabled}
												Prefix="$"
												Required
												IsInvalid={vModel.Amount} />
										</GridItem>
										<GridItem xs={4}></GridItem>

										<GridItem xs={8}>
											<GenericDateInput
												LabelMd={4}
												Name="DateOfFunds"
												LabelText="Date Funds"
												Value={parentModel.IsUpdate ? DateHelper.GetDateLocal() : model.DateOfFunds}
												ValueChanged={this.ValueChanged}
												Disabled={Disabled || parentModel.IsUpdate}
												IsFuture={true}
												Required
												IsInvalid={vModel.DateOfFunds} />
										</GridItem>
										<GridItem xs={4}></GridItem>

										<GridItem xs={8}>
											<GenericDateInput
												LabelMd={4}
												Name="MoneyReqExpDate"
												LabelText="Transaction Exp. Date"
												MinDate={model.DateOfFunds}
												MaxDate={maxExpDate}
												Value={model.MoneyReqExpDate}
												ValueChanged={this.ValueChanged}
												Disabled={Disabled}
												Required
												IsInvalid={vModel.MoneyReqExpDate} />
										</GridItem>
										<GridItem xs={4}></GridItem>

										<GridItem xs={8}>
											{model.IsHaveQuestion ?
												<GenericSelectInput
													key={"SecurityQuestion_" + parentModel.UniqueClientId}
													LabelMd={4}
													Name="SecurityQuestion"
													LabelText="Security Question"
													Method="POST"
													Url="/bankapi/v1.0/BankInteracETransfer/GetSecurityQuestionsByClientId"
													Parameter={parentModel.UniqueClientId}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="SecurityQuestion"
													Value={model.SecurityQuestion}
													ValueChanged={this.ValueChanged} Disabled={Disabled || (model.TransactionTypeCode != EtransferTransactionTypes.Credit) || model.HasDDR || model.TransferType === DcbETransferType.AccountDeposit}
													Required={model.TransactionTypeCode == EtransferTransactionTypes.Credit && !model.HasDDR && model.TransferType !== DcbETransferType.AccountDeposit}
													IsInvalid={vModel.SecurityQuestion} />
												:
												<GenericTextInput
													LabelMd={4}
													inputProps={ParameterMaxLengthSecurityQuestion}
													Name="SecurityQuestionText"
													LabelText="Security Question"
													Value={model.SecurityQuestionText}
													ValueChanged={this.ValueChanged}
													Disabled={Disabled || (model.TransactionTypeCode != EtransferTransactionTypes.Credit) || model.HasDDR || model.TransferType === DcbETransferType.AccountDeposit}
													Required={model.TransactionTypeCode == EtransferTransactionTypes.Credit && !model.HasDDR}
													IsInvalid={vModel.SecurityQuestionText} />
											}
										</GridItem>
										<GridItem xs={4}>
											<GenericCheckInput
												Name="IsHaveQuestion"
												LabelText="Get Security Question From List"
												Value={model.IsHaveQuestion}
												ValueChanged={this.ValueChanged}
												Disabled={Disabled || (model.TransactionTypeCode != EtransferTransactionTypes.Credit) || model.HasDDR} />
										</GridItem>

										<GridItem xs={8}>
											<GenericTextInput
												LabelMd={4}
												Name="SecurityQuestionAnswer"
												Value={model.SecurityQuestionAnswer}
												LabelText="Security Question Answer"
												ValueChanged={this.ValueChanged}
												Disabled={Disabled || (model.TransactionTypeCode != EtransferTransactionTypes.Credit) || model.HasDDR || model.TransferType === DcbETransferType.AccountDeposit}
												inputProps={lengthSecurityAnswer}
												Required={model.TransactionTypeCode == EtransferTransactionTypes.Credit && !model.HasDDR && model.TransferType !== DcbETransferType.AccountDeposit}
												IsInvalid={vModel.SecurityQuestionAnswer} />
										</GridItem>
										<GridItem xs={4}></GridItem>

										<GridItem xs={8}>
											{model.IsHaveMemo ?
												<GenericSelectInput
													key={"Memo_" + parentModel.UniqueClientId}
													LabelMd={4}
													Name="TransferMemoId"
													LabelText="Memo"
													Method="POST"
													Url="/bankapi/v1.0/BankInteracETransfer/GetMemosByClientId"
													Parameter={parentModel.UniqueClientId}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="Memo"
													Value={model.TransferMemoId}
													ValueChanged={this.ValueChanged}
													Disabled={Disabled} Sorted={sortMemo} />
												:
												<GenericTextInput
													LabelMd={4}
													inputProps={ParameterMaxLength400}
													Name="TransferMemo"
													LabelText="Memo"
													Value={model.TransferMemo}
													ValueChanged={this.ValueChanged}
													Disabled={Disabled} />
											}
										</GridItem>
										<GridItem xs={4}>
											<GenericCheckInput
												Name="IsHaveMemo"
												LabelText="Get Description From List"
												Value={model.IsHaveMemo} ValueChanged={this.ValueChanged}
												Disabled={Disabled || parentModel.IsUpdate} />
										</GridItem>
										
									</GridContainer>
								</GridItem>
							</GridContainer>
						</GenericExpansionPanel>
					</CardBody>
				</Card>

				<GenericDialog open={model.IsCustomerPopupOpen} fullScreen onBackdropClick={this.addCustomerDialogClose} TransitionComponent={Transition}>
					<DialogTitle>
						<GenericLabel FontSize="16px" Text="Add New Customer" Bold={true} />
					</DialogTitle>
					<DialogContent>
						<AddNewCustomereTransfer ForInsert CustomerSaved={this.addCustomerSaved} menuId={this.props.addNewCustomerMenuId} />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.addCustomerDialogClose}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>

			</div>
		);
	}
}

CreateEtransferIndividual.defaultProps = {
	isBanking: false
};

CreateEtransferIndividual.propTypes = {
	classes: PropTypes.object.isRequired,
	model: PropTypes.object,
	vModel: PropTypes.object,
	parentModel: PropTypes.object,
	onModelChange: PropTypes.func,
	Disabled: PropTypes.bool,
	addNewCustomerMenuId: PropTypes.number,
	showMessage: PropTypes.func,
	isBanking: PropTypes.bool,
	CustomerStoreList: PropTypes.array
};

export default withStyles(createEtransferIndividualStyle)(CreateEtransferIndividual);