import withStyles from "@material-ui/core/styles/withStyles";
import { chartGradientColors } from "assets/jss/material-dashboard-pro-react.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
// @ts-ignore
import { Chart, Doughnut as DoughnutChart } from "react-chartjs-2";

const styles = ({
	circleBoxGrid: {
		width: "100%",
		textAlign: "center"
	},
	circleBox: {
		display: "inline-block",
		marginTop: "24px",
		marginLeft: "auto",
		marginRight: "auto",
		marginBottom: "24px"
	}
});

class LoanApplicationsChart extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
		};

		this.chartOptions = {
			maintainAspectRatio: false,
			responsive: false,
			legend: {
				display: false
			},
			tooltips: { enabled: true },
			cutoutPercentage: 90,
			animation: {
				duration: 2000
			}
		};
		this.getGradientList = this.getGradientList.bind(this);
	}

	componentDidMount() {
		this.props.setAppLeftTitle(this.props.leftTitle);
		this.props.setAppCenterTitle(this.props.centerTitle);

		var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
		Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
			draw: function () {
				originalDoughnutDraw.apply(this, arguments);

				var chart = this.chart;
				var width = chart.chart.width,
					height = chart.chart.height,
					ctx = chart.chart.ctx;

				var fontSize = 1.5;
				ctx.font = fontSize + "em sans-serif";
				ctx.textBaseline = "middle";
				ctx.fillStyle = "#000";

				var text = chart.config.data.text,
					textX = Math.round((width - ctx.measureText(text).width) / 2),
					textY = height / 2;

				ctx.fillText(text, textX, textY);

			}
		});

	}

	getGradientList(dataList) {
		var gradientList = undefined;
		if (document.getElementById("canvas") != null) {
			// @ts-ignore
			var ctx = document.getElementById("canvas").getContext("2d");
			gradientList = [];
			if (dataList.datasets != undefined) {
				var i;
				for (i = 0; i < dataList.datasets[0].data.length; i++) {
					var gradient = ctx.createLinearGradient(0, 0, 0, 400);
					if (chartGradientColors[i] == undefined) {
						gradient.addColorStop(0, chartGradientColors[0].begin);
						gradient.addColorStop(1, chartGradientColors[0].end);
					} else {
						gradient.addColorStop(0, chartGradientColors[i].begin);
						gradient.addColorStop(1, chartGradientColors[i].end);
					}
					gradientList.push(gradient);
				}
				return gradientList;
			}
		}
		return;

	}
	render() {
		const { classes, transactionChartData, TitleChart, TitleChart2, TitleChart3, creditDebitChartData, feeChartData } = this.props;

		var transactionGradientList = this.getGradientList(transactionChartData);
		var transactionBackground = transactionChartData;
		if (transactionGradientList != undefined) {
			transactionBackground.datasets[0].backgroundColor = transactionGradientList;
			transactionBackground.datasets[0].hoverBackgroundColor = transactionGradientList;
		}
		var creditDebitGradientList = this.getGradientList(creditDebitChartData);
		var creditDebitBackground = creditDebitChartData;
		if (creditDebitGradientList != undefined) {
			creditDebitBackground.datasets[0].backgroundColor = creditDebitGradientList;
			creditDebitBackground.datasets[0].hoverBackgroundColor = creditDebitGradientList;
		}
		var feeGradientList = this.getGradientList(feeChartData);
		var feeBackground = feeChartData;
		if (feeGradientList != undefined) {
			feeBackground.datasets[0].backgroundColor = feeGradientList;
			feeBackground.datasets[0].hoverBackgroundColor = feeGradientList;
		}
		return (
			<GridContainer spacing={16}>
				<GridItem xs={12}>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs={4} className={classes.circleBoxGrid}>
									<div className={classes.circleBox}>
										<DoughnutChart
											// @ts-ignore
											id='canvas'
											data={transactionBackground} width={180} height={250}
											options={transactionBackground.text == "" || transactionBackground.text == "0" ? { ...this.chartOptions, ...{ tooltips: { enabled: false } } } : this.chartOptions}
										/>
										<h4>{TitleChart}</h4>
									</div>
								</GridItem>
								<GridItem xs={4} className={classes.circleBoxGrid}>
									<div className={classes.circleBox}>
										<DoughnutChart data={creditDebitBackground} width={180} height={250}
											options={creditDebitBackground.text == "" || creditDebitBackground.text == "0" ? { ...this.chartOptions, ...{ tooltips: { enabled: false } } } : this.chartOptions}
										/>
										<h4>{TitleChart2}</h4>
									</div>
								</GridItem>
								<GridItem xs={4} className={classes.circleBoxGrid}>
									<div className={classes.circleBox}>
										<DoughnutChart
											data={feeBackground} width={180} height={250}
											options={feeBackground.text == "" || feeBackground.text == "0" ? { ...this.chartOptions, ...{ tooltips: { enabled: false } } } : this.chartOptions}
										/>
										<h4>{TitleChart3}</h4>
									</div>
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

LoanApplicationsChart.propTypes = {
	classes: PropTypes.object,
	columns: PropTypes.array,
	list: PropTypes.array,
	transactionChartData: PropTypes.object,
	creditDebitChartData: PropTypes.object,
	feeChartData: PropTypes.object,
	leftTitle: PropTypes.string,
	centerTitle: PropTypes.string,
	TitleChart: PropTypes.string,
	TitleChart2: PropTypes.string,
	TitleChart3: PropTypes.string,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func
};

// @ts-ignore
export default withStyles(styles)(LoanApplicationsChart);