import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericCheckInput, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericDateInput, GenericLabel, GenericTitle } from "views/Components/Generic";
import { MonthsOfYear, MenuCodes, GridColumnType } from "views/Constants/Constant.js";
import ClientHelper from "core/ClientHelper";
import ParameterComponent from "views/Components/ParameterComponent";

class ActiveCardandRevenueReport extends React.Component {
	constructor(props) {
		super(props);
		this.initialModel = {
			Id: 0,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined,
			IsMainCard: true
		};


		this.columnsRevenue = [
			{
				Header: "Year",
				accessor: "Year",
			},
			{
				Header: "Month",
				accessor: "Month",
			},
			{
				Header: "ProductName",
				accessor: "ProductName",
			},
			{
				Header: "TransactionType",
				accessor: "TransactionType"
			},
			{
				Header: "Card Transaction Source",
				accessor: "CardTransactionSourceValue",

			},
			{
				Header: "Total Amount",
				accessor: "TotalAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Total Count",
				accessor: "Count"
			}

		];
		this.columnsFundsTransfer = [
			{
				Header: "Year",
				accessor: "Year",

			},
			{
				Header: "Month",
				accessor: "Month",
			},
			{
				Header: "Wallet Name",
				accessor: "AccountName",
			},
			{
				Header: "Wallet Number",
				accessor: "AccountNumber"
			},
			{
				Header: "MaskedCardNumber",
				accessor: "MaskedCardNumber",
			},
			{
				Header: "CardTokenNumber",
				accessor: "CardTokenNumber"
			},
			{
				Header: "TransactionType",
				accessor: "TransactionType"
			},
			
			{
				Header: "Total Amount",
				accessor: "TotalAmount",
				type: GridColumnType.Money,

			},
			{
				Header: "Total Count",
				accessor: "Count"
			}
			// {
			// 	Header: "TranactionDateTime",
			// 	accessor: "TranactionDateTime",
			// 	type: GridColumnType.DateUtc,

			// },
		];
		this.columnsActiveCard = [
			{
				Header: "Year",
				accessor: "Year",

			}, {
				Header: "Month",
				accessor: "Month",
			},
			{
				Header: "MaskedCardNumber",
				accessor: "MaskedCardNumber",
			},
			{
				Header: "CardTokenNumber",
				accessor: "CardTokenNumber"
			},
			{
				Header: "TransactionType",
				accessor: "TransactionType"
			},
			{
				Header: "TransactionCodeType",
				accessor: "TransactionCodeType"
			},
			{
				Header: "Card Transaction Source",
				accessor: "CardTransactionSourceValue",

			},
			{
				Header: "Total Amount",
				accessor: "TotalAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Total Count",
				accessor: "Count"
			}
			// {
			// 	Header: "TranactionDateTime",
			// 	accessor: "TranactionDateTime",
			// 	type: GridColumnType.DateUtc,

			// },
		];
		this.emptyObject = {};
		this.parameterProduct = {};
		this.sortedName = { Member: "Name" };
		this.sortedCode = { Member: "Code" };

		this.state = {
			model: this.initialModel,
			Month: [],
			vModel: {}
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("PREPAID CARD MANAGEMENT");
		}

		if (this.props.MenuCode == MenuCodes.Menu_ActiveCardReport) {

			setAppLeftTitle("Active Card Report");
		} else {
			setAppLeftTitle("Revenue Report");

		}
		this.SetYearList();
	}

	SetYearList = () => {
		var currentYear = parseInt(DateHelper.GetYear());
		var listYear = [];
		var listAge = [];
		var listMonth = [];

		for (var i = 2019; i <= currentYear; i++) {
			listYear.push({
				"Year": i,
				"YearNumber": parseInt(currentYear - i),
			});
		}
		for (var i = 18, j = 1; i <= 100; i++) {
			listAge.push({
				"Age": i
			});
		}

		listMonth.push(
			{
				"MonthName": "Last 1 Months",
				"StatementPeriod": 1
			},
			{
				"MonthName": "Last 3 Months",
				"StatementPeriod": 3
			},
			{
				"MonthName": "Last 6 Months",
				"StatementPeriod": 6
			}
		);

		this.setState({ listAge, listYear, listMonth });
	}
	handleListItemChange = (name, newValue, key, ObjectName) => {
		var model = { ...this.state.model };
		const array = this.state.Month;
		const item = array.includes(key);
		if (item == false && newValue == true) {
			array.push(key);
		} else if (item == true && newValue == false) {
			var index = array.indexOf(key);
			if (index > -1) {
				array.splice(index, 1);
			}
		}
		model["Month"] = array;
		this.setState({ Month: array, model });

	}


	ValueChanged = (name, value) => {
		this.setState(state => {
			var model = state.model;
			model[name] = value;

			return { model };
		});
	}

	Search = async () => {
		const { model } = this.state;
		const { ExecuteApiPost } = this.props;

		let IsActiveCardReport = this.props.MenuCode == MenuCodes.Menu_ActiveCardReport;
		if (IsActiveCardReport) {
			var result = await ExecuteApiPost("/prepaidapi/v1.0/TransactionData/ActiveCardReport", model, null, null, null);
			this.setState({ listActiveCard: result.ActiveCardResponse || [], listFundstransfer: result.FundsTransferResponse || [] });

		}
		else {
			var result1 = await ExecuteApiPost("/prepaidapi/v1.0/TransactionData/RevenueReport", model, null, null, null);
			this.setState({ listRevenue: result1.RevenueResponse || [] });
		}

	}

	Clear = () => {
		this.setState({ model: {}, listActiveCard: [], listFundstransfer: [], listRevenue: [], selected: null, Month: [] });
	}

	Validate = () => {
		var { model } = this.state;
		const { showValidationErrors } = this.props;
		const vModel = { ...this.state.vModel };
		var errors = [];

		if (!model.ParentMenuId
			|| model.ParentMenuId == -2) {
			errors.push("Parent menu can not be null.");
		}
		if (!model.SubMenuId) {
			errors.push("Sub menu can not be null.");
		}
		if (!model.ActionId) {
			errors.push("Action can not be null.");
		}

		if (errors.length > 0) {
			showValidationErrors(errors);

			return false;
		}

		return true;
	}

	GetProductParameter = () => {
		this.parameterProduct.UniqueClientId = this.state.model.UniqueClientId;
		this.parameterProduct.IsNoNameProduct = false;
		return this.parameterProduct;
	}

	render() {
		const { model, listActiveCard, listRevenue, listFundstransfer, listYear, listMonth, Month } = this.state;
		const { Disabled } = this.props;
		let IsActiveCardReport = this.props.MenuCode == MenuCodes.Menu_ActiveCardReport;

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},

						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Panel">
									<GridContainer>
										<GridItem xs={3}>
											<GenericSelectInput
												IsStatic
												Name="YearNumber"
												LabelText="Year"
												StaticData={listYear}
												KeyValueMember="YearNumber"
												TextValueMember="Year"
												Value={model.YearNumber}
												ValueChanged={this.ValueChanged}
											// Required 
											/>
										</GridItem>
										{(IsActiveCardReport) &&
											<GridItem xs={3}>
												<GenericSelectInput
													IsStatic
													Name="StatementPeriod"
													LabelText="Statement Period"
													StaticData={listMonth}
													KeyValueMember="StatementPeriod"
													TextValueMember="MonthName"
													Value={model.StatementPeriod}
													ValueChanged={this.ValueChanged}
												// Required 
												/>
											</GridItem>
										}

										{(IsActiveCardReport) &&
											<GridItem xs={3}>
												<GenericSelectInput
													Name="TransactionCodeType"
													LabelText="Transaction Code Type"
													Method="POST"
													Url="/prepaidapi/v1.0/TransactionData/GetAllTxnName"
													Parameter={{}}
													DataRoot="Item"
													KeyValueMember="TransactionCodeType"
													TextValueMember="TransactionCodeType"
													Value={model.TransactionCodeType}
													ValueChanged={this.ValueChanged}
												// IsInvalid={vModel.GenderId}
												// Required
												/>
											</GridItem>
										}
										{(!IsActiveCardReport) &&
											<GridItem xs={3}>
												<GenericSelectInput
													key={model.UniqueClientId}
													Name="ProductId"
													LabelMd={2}
													LabelText="Product"
													Method="POST"
													Url="/prepaidapi/v1.0/Product/Search"
													Parameter={this.GetProductParameter()}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ProductName"
													Value={model.ProductId}
													ValueChanged={this.ValueChanged}
													Disabled={model.Id > 0 ? true : false}
												// Required
												// IsInvalid={vModel.ProductId}
												/>
											</GridItem>}

										<GridItem xs={3}>
											<GenericSelectInput
												Name="CardTransactionSourceId"
												LabelText="Card Transaction Source"
												Method="POST"
												Url="/coreapi/v1.0/Parameter/Search"
												Parameter={{ ParameterCode: "CardTransactionSource" }}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ParameterDesc"
												Value={model.CardTransactionSourceId}
												ValueChanged={this.ValueChanged}
											// IsInvalid={vModel.GenderId}
											// Required
											/>
										</GridItem>

										{(!IsActiveCardReport) &&
											<GridItem xs={3}  > 	</GridItem>}
										 
										{(!IsActiveCardReport) &&
											<GridItem xs={1} style={{ marginTop: "10px" }}>
												<GenericLabel
													Text="Month"
													LabelMd={6}
												/>
											</GridItem>}
										{(!IsActiveCardReport) &&
											<GridItem xs={10} style={{ marginTop: "10px" }}>
												{MonthsOfYear != null && MonthsOfYear.map(item => (
													<GenericCheckInput
														key={"MonthsOfYear" + item.Id}
														Name="Id"
														LabelText={item.Text}
														Value={Month.includes(item.Id)}
														ValueChanged={(name, value) => this.handleListItemChange(name, value, item.Id, "MonthsOfYear")}
													/>
												))}

											</GridItem>
										}

									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						{(IsActiveCardReport) &&
							<Card className="no-radius">
								<CardHeader>
									<GenericTitle text={"FundsTransfer List"} />
								</CardHeader>
								<CardBody>
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer spacing={16}>
												<GridItem xs={12}>
													<GenericGrid
														Data={listFundstransfer}
														Columns={this.columnsFundsTransfer}
													/>
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</CardBody>
							</Card>
						}

						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={IsActiveCardReport ? "Active Card List" : "Revenue List"} />
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={IsActiveCardReport ? listActiveCard : listRevenue}
													Columns={IsActiveCardReport ? this.columnsActiveCard : this.columnsRevenue}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

ActiveCardandRevenueReport.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any,
	classes: PropTypes.object

};
export default withArtifex(ActiveCardandRevenueReport, {});
