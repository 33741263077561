import { Dialog, DialogContent } from "@material-ui/core";
import CardMedia from "@material-ui/core/CardMedia";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withRedux } from "core";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React from "react";
import { UserConstants } from "store";
import { GenericAlert, GenericCircularProgress, GenericEmailInput, GenericTextInput, GenericNumberInput, GenericDateInput, GenericSelectInput, GenericTitle } from "views/Components/Generic";
import RouteHelper from "core/RouteHelper";
import LoadingComponent from "views/Components/LoadingComponent";

class InteracInboundPage extends React.Component {
	constructor(props) {
		super(props);
		console.log("We are in the inbound page")
		this.state = {
			alert: null,
			isLoading: false,
			captchaLoaded: false,
			IsMoneyRequest: false,
			isAuthenticated: false,
			IsValidUrl: false,
			model: {
				Email: "",
				Password: "",
				ReferenceNumber: "",
				AccountId: 0
			}
		};

		this.cardHeaderStyle = { height: 80, width: "50%", marginLeft: "auto", marginRight: "auto" };
		this.cardMediaStyle = {
			height: "100%", width: "75%", marginLeft: "auto", marginRight: "auto", marginTop: 10,
			backgroundSize: "100% 100%"
		};
		this.cardBodyStyle = { marginTop: 20, marginBottom: 62, width: "100%", marginLeft: "auto", marginRight: "auto" };
	}

	componentDidMount() {
		console.log("Component Mounted")
		var user = ClientHelper.GetUser();
		if (!user) {
			RouteHelper.Push(this.props.history, "/InteracConfirm/InBoundLogin", "InteracData", {});
		}
		this.GetInteracUserDetail(user.UniqueClientId);
	}

	handleChange = (name, newValue) => {
		this.setState(function (state, props) {
			var model = state.model || {};
			model[name] = newValue;
			return { model };
		});
	}

	FetchRequest = () => {
		var data = RouteHelper.Read("InteracData");
		var { model } = this.state;
		if (data == null) {
			console.log("InteracData null");
			RouteHelper.Push(this.props.history, "/InteracConfirm/InBoundLogin", "InteracData", {});
			return;
		}
		if (!data.IsValidUrl) {
			console.log("InteracData URL not VALID");
			RouteHelper.Push(this.props.history, "/InteracConfirm/InBoundLogin", "InteracData", {});
			return;
		}
		model.ReferenceNumber = data.ReferenceNumber;
		this.setState({ IsMoneyRequest: data.IsMoneyRequest, model });
		if (data.IsMoneySend) {
			this.FetchIncomingTransfers();
		}
		if (data.IsMoneyRequest) {
			this.setState({ isAuthenticated: true });
			this.FetchIncomingMoneyRequests();
		}
	}

	GetInteracUserDetail = (clientId) => {
		var { model } = this.state;
		Proxy.POST("/bankapi/v1.0/InteracInbound/GetInteracUserDetail",
			{ ClientId: clientId },
			responseData => {
				this.hideAlert();
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", "An error occurred while interac request " + responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					model.MainUserEmail = responseData.Item.CustomerETransfer?.EMail ?? "";
				}
				console.log(responseData);
				this.setState({ model });
				this.FetchRequest();
			},
			e => {
				this.hideAlert();
				this.props.showMessage("error", "Error", "An error occurred while interac request ");
			}
		);
	}

	FetchIncomingTransfers = () => {
		var { model } = this.state;
		var temp = {};
		temp.ReferenceNumber = model.ReferenceNumber;
		temp.Email = model.MainUserEmail;

		console.log("GetIncomingTransfers Request");
		console.log(temp);

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/InteracInbound/GetIncomingTransfers",
			temp,
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				console.log(responseData);
				if (responseData.Item != null){
					model.GetIncomingTransferResponse = responseData.Item;
					var request = responseData.Item.incomingTransfer;
					model.SenderName = request.senderRegistrationName;
					model.SenderMemo = request.senderMemo;
					model.Amount = request.amount;
					model.ExpiryDate = request.expiryDate;
					model.SecurityQuestion = request.securityQuestion;
					model.HashSalt = request.hashSalt;
					if (request.authenticationRequired == 0)
					{
						this.setState({ isAuthenticated: true });
					}
					this.setState({ model });
				} else {
					console.log("GetIncomingTransfers response null");
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	FetchIncomingMoneyRequests = () => {
		var { model } = this.state;
		var data = RouteHelper.Read("InteracData");
		var temp = {};
		temp.ReferenceNumber = data.ReferenceNumber;
		temp.Email = model.MainUserEmail;

		console.log("GetIncomingTransfers Request");

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/InteracInbound/GetIncomingMoneyRequests",
			temp,
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				model.IncomingRequest = responseData;
				if (responseData.Item != null) {
					model.IncomingRequest = responseData.Item;
					var request = responseData.Item.incomingRequest;
					model.SenderName = request.customerName.registrationName;
					model.SenderMemo = request.senderMemo;
					model.Amount = request.moneyRequestAmount;
					model.ExpiryDate = request.moneyRequestExpiryDate;
					this.setState({ model });

				} else {
					console.log("GetIncomingTransfers response null");
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	
	DeclineTransfer = () => {
		const { model, IsMoneyRequest } = this.state;
		var body = {};
		body.Email =  model.MainUserEmail;
		body.ReferenceNumber = model.ReferenceNumber;
		body.DeclineReason = null;
		var url = IsMoneyRequest ? "/bankapi/v1.0/InteracInbound/DeclineRequest" : "/bankapi/v1.0/InteracInbound/DeclineTransfer";

		this.setState({ isLoading: true });
		Proxy.POST(
			url,
			body,
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					if(IsMoneyRequest) {
						this.ShowMessage("success", "Request Declined", "Request has been declined successfully!");
					} else {
						this.ShowMessage("success", "Transfer Declined", "Transfer has been declined successfully!");
					}
				} else {
					console.log("DeclineTransfer response null");
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	AuthenticateTransfer = () => {
		const { model } = this.state;
		var body = {};
		body.Email =  model.MainUserEmail;
		body.ReferenceNumber = model.ReferenceNumber;
		body.SecurityAnswer = model.SecurityAnswer;
		body.HashSalt = model.HashSalt;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/InteracInbound/AuthenticateTransfer",
			body,
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				} 
				this.setState({ isAuthenticated: true });
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	CompleteTransfer = () => {
		const { model } = this.state;
		var body = {};
		body.Email =  model.MainUserEmail;
		body.AccountId = model.AccountId;
		body.ReferenceNumber = model.ReferenceNumber;
		body.GetIncomingTransferResponse = model.GetIncomingTransferResponse;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/InteracInbound/CompleteTransfer",
			body,
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				if (responseData.Item != null) {
					this.ShowMessage("success", "Transfer Complete", "Transaction completed successfully!");
				} else {
					console.log("CompleteTransfer response null");
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	CompleteRequest = () => {
		const { model } = this.state;
		var body = {};
		body.Email =  model.MainUserEmail;
		body.AccountId = model.AccountId;
		body.ReferenceNumber = model.ReferenceNumber;
		body.IncomingRequest = model.IncomingRequest;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/InteracInbound/CompleteMoneyRequest",
			body,
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				if (responseData.Item != null) {
					this.ShowMessage("success", "Request Complete", "Request completed successfully!");
				} else {
					console.log("CompleteTransfer response null");
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	ShowMessage = (type, title, message) => {
		// type:-success->warning->info->error
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}

	render() {
		const { classes } = this.props;
		const { model, isLoading, IsMoneyRequest, alert, initialized, isAuthenticated } = this.state;
		const logo = require("assets/img/" + window.LoginLogo);

		return (
			<div >
				<LoadingComponent Show={isLoading}/> 
				{alert}
				<GridContainer justify="center">
					<GridItem style={{ maxWidth: "1200px", minWidth: "700px", marginTop: "200px" }} >
						<Card style={{paddingRight: 62, paddingLeft: 62}} className="no-radius">
							<CardHeader
								className={`${classes.cardHeader} ${classes.textCenter}`} color="white" style={this.cardHeaderStyle}>
								<CardMedia
									className={classes.media} image={logo} style={this.cardMediaStyle} title=""
								/>
							</CardHeader>
							<CardBody style={this.cardBodyStyle}>
								<GenericTitle
									TextAlign="center"
									text={IsMoneyRequest?  "Money Requested" : "Complete Transfer"}
									/>
								<GridContainer style={{marginTop: "42px" }}>
									<GridItem xs={6}>
										<GenericTextInput
											LabelMd={5}
											Name="SenderName" LabelText="Sender Name" 
											Value={model.SenderName} ValueChanged={this.handleChange}
											Disabled={true} />
									
										<GenericTextInput
											LabelMd={5}
											Name="SenderMemo" LabelText="Sender Memo" 
											Value={model.SenderMemo} ValueChanged={this.handleChange}
											Disabled={true}/>

										{ !IsMoneyRequest &&
											<GenericTextInput
												LabelMd={5}
												Name="SecurityQuestion" LabelText="Security Question" 
												Value={model.SecurityQuestion} ValueChanged={this.handleChange}
												Disabled={true}/>
										}

									</GridItem>
									<GridItem xs={6}>
										<GenericNumberInput
											LabelMd={5} Name="Amount"
											LabelText="Amount"
											Value={model.Amount}
											ValueChanged={this.handleChange}
											MaxLength={10}
											Disabled={true}
											Prefix="$"/>

										<GenericDateInput
											LabelMd={5}
											Name="ExpiryDate" LabelText="Expiry Date" 
											Value={model.ExpiryDate} ValueChanged={this.handleChange}
											Disabled={true}/>
									</GridItem>


									<GridItem xs={6}>
										{ !IsMoneyRequest &&
											<GenericTextInput
												LabelMd={5}
												Required={true}
												Name="SecurityAnswer" LabelText="Security Answer" 
												Value={model.SecurityAnswer} ValueChanged={this.handleChange}
												Disabled={isAuthenticated}
												/>
										}
									</GridItem>

									<GridItem xs={6}>
										<GenericSelectInput
											LabelMd={5}
											Name="AccountId"
											LabelText="Account"
											Method="POST"
											Url="/bankapi/v1.0/InteracInbound/GetClientAccounts"
											Parameter={{}}
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember="AccountName"
											Value={model.AccountId}
											ValueChanged={this.handleChange}
										/>
									</GridItem>
								</GridContainer>
								<GridContainer style={{marginTop: "22px" }}>
									<GridItem xs={6} md={3}></GridItem>
									<GridItem xs={6} md={3}>
										{ !IsMoneyRequest &&
											<Button color="primary" size="md" block onClick={() => this.AuthenticateTransfer()} disabled={isAuthenticated}>
												Authenticate
											</Button>
										}
									</GridItem>
									

									<GridItem xs={6} md={3}>
										<Button color="primary" size="md" 
												block onClick={() => this.DeclineTransfer()}
												disabled={!isAuthenticated}>
											Decline
										</Button>
									</GridItem>

									
									<GridItem xs={6} md={3}>
										<Button color="primary" size="md" block 
												onClick={() => {IsMoneyRequest ? this.CompleteRequest() : this.CompleteTransfer()}} 
												disabled={!isAuthenticated}>
											Complete
										</Button>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

InteracInboundPage.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withRedux(InteracInboundPage, loginPageStyle);