
class TaskPool {

	constructor(AppendTask, TasksCompleted) {
		this.openConsoleLog = false;
		this.pool = [];
		this.taskCount = 0;
		this.allTaskStatus = true;
		this.handleDoneTask = TasksCompleted;
		this.handleAppendTask = AppendTask;
	}

	SetBusy=()=>{
		if (this.handleAppendTask){
			this.handleAppendTask(Promise.resolve(),false);
		}
	}
	SetDone=()=>{
		if (this.handleDoneTask){
			this.handleDoneTask(Promise.resolve());
		}
	}

	AppendTask = (promise) => {
		if (!promise) return;

		const chars = [..."ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"];
		const rand = Math.random();
		var rnd = [...Array(16)].map(i => chars[rand * chars.length | 0]).join("");
		var item = {};
		item[rnd] = promise;
		this.pool.push(item);

		if (this.handleAppendTask) {

			this.openConsoleLog && console.log("Total Pending Tasks:" + this.pool.length + " Task Added KEY:" + rnd);
			let isRunningAnyTask = this.pool.length > 0;
			if (this.allTaskStatus)
				this.taskCount = 0;
			this.taskCount++;
			this.allTaskStatus = false;
			this.handleAppendTask(promise, isRunningAnyTask);
		}
		promise.finally(() => {

			if (this.pool && this.pool.length > 0) {
				this.pool = this.pool.filter((value) => {
					return Object.keys(value)[0] != rnd;
				});
				this.openConsoleLog && console.log("Total Pending Tasks:" + this.pool.length + " Task Competed! KEY:" + rnd);
			}

			if (this.pool && this.pool.length == 0) {
				this.openConsoleLog && console.log("All Tasks Finished!");
				if (this.handleDoneTask) {
					this.handleDoneTask(promise);
					this.allTaskStatus = true;
				}
			}
		});
	}

}

export { TaskPool };