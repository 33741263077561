import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import { GenericDateInput, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import { GridColumnType } from "views/Constants/Constant";

class RefundReversalTransactions extends React.Component {
	constructor(props) {
		super(props);

		this.columns = [
			{
				Header: "Transaction Type",
				accessor: "TransactionType"
			},
			{
				Header: "Transaction Destination",
				accessor: "TransactionDestination"
			},
			{
				Header: "Reference Number",
				accessor: "ReferenceNumber"
			},
			{
				Header: "Card Token Number",
				accessor: "CardTokenNumber"
			},
			{
				Header: "TransactionDateTime",
				accessor: "TransactionDateTime",
				type: GridColumnType.DateTime
			},
			{
				Header: "Error Code",
				accessor: "ErrorCode"
			},
			{
				Header: "Response Code",
				accessor: "ResponseCode"
			},
			{
				Header: "Error Description",
				accessor: "ErrorDescription"
			},
			{
				Header: "Billing Amount",
				accessor: "BillingAmount"
			},
			{
				Header: "Txn Description",
				accessor: "TxnDescription"
			},
			{
				Header: "Settlement Status",
				accessor: "SettlementStatus"
			},
			{
				Header: "Transmission Date Time",
				accessor: "TransmissionDateTime"
			},
			{
				Header: "System Trace Audit Number",
				accessor: "SystemTraceAuditNumber"
			},
			{
				Header: "Acquiring Institution Id",
				accessor: "AcquiringInstitutionId"
			},
			{
				Header: "Retrieval Reference Number",
				accessor: "RetrievalReferenceNumber"
			},
			{
				Header: "Authorization Identification Response",
				accessor: "AuthorizationIdentificationResponse"
			}
		];

		this.uniqueClientId = ClientHelper.GetClientRowId();

		this.emptyObject = {};
		this.parameterUniqueClientId = {};


	

		this.state = {
			model: {
				UniqueClientId: this.uniqueClientId
			}
		};
	}

	componentDidMount() {
		const { setAppLeftTitle, setAppCenterTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("CLEARING AND SETTLEMENT");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("Error - Refund Reversal Transactions");
		}
	}

	ValueChanged = (name, value) => {
		this.setState(state => {
			var model = state.model;
			model[name] = value;

			return { model };
		});
	}

	Search = async () => {
		const { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/prepaid/v1.0/ReversalRefundTransactions/Search", model, model);

		this.setState({ list: result || [] });
	}

	Clear = () => {
		this.setState({ model: { UniqueClientId: this.uniqueClientId }, list: [] });
	}

	render() {
		const { Disabled } = this.props;
		const { model, list } = this.state;

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Error - Refund Reversal Transactions">
									<GridContainer>
										<GridItem xs={4}>
											<GenericDateInput
												Utc
												Name="DateFrom"
												LabelText="Start Date"
												Value={model.StartDate}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={4}>
											<GenericDateInput
												Utc
												Name="DateTo"
												LabelText="End Date"
												Value={model.EndDate}
												ValueChanged={this.ValueChanged} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GenericGrid
									Data={list}
									Columns={this.columns}
									ShowPagination={true}
									PageSize={100}
									Sorted={[{ id: "TransactionDate", desc: true }]} />
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

RefundReversalTransactions.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default RefundReversalTransactions;