import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericPage, GenericDateInput, GenericSelectInput, GenericTitle } from "views/Components/Generic";
import ChequeWireTransactionsDataTable from "./ChequeWireTransactionsDataTable";
import LoadingComponent from "views/Components/LoadingComponent";

const styles = ({
	...sweetAlertStyle
});
class ChequeWireTransactions extends GenericPage {
	constructor(props) {
		super(props);

		var user = ClientHelper.GetUser();

		this.initialModel = {
			ClientTypeId: (user.UniqueClient && user.UniqueClient.ClientTypeId) || null,
			isBackOffice: false
		};

		this.state = {
			model: this.initialModel,
			list: [],
			totalresult: {},
			checkedValues: [],
			selected: null,
			isLoading: false,
			alert: "",
			isBackOffice: false
		};

		this.HandleCheck = this.HandleCheck.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
		this.Bind(this);
		this.props.setAppLeftTitle("Wire Transactions");
		this.props.setAppCenterTitle("WIRE TRANSFER");

		var { isBackOffice } = this.state.isBackOffice;
		var model = { ...this.state.model };

		isBackOffice = ClientHelper.IsBackOffice();
		if (!isBackOffice) {
			model.UniqueClientId = ClientHelper.GetClientRowId();
			model.Name = model.UniqueClientId;
		}
		this.setState({ model, isBackOffice });
	}

	ValueChanged(name, value, data) {
		const model = { ...this.state.model };
		model[name] = value;
		if (name === "Name") model["UniqueClientId"] = value;
		if (name === "UniqueClientId") model["Name"] = value;

		this.setState({ model });
	}
	HandleCheck(e, x) {

		this.setState(state => ({
			checkedValues: state.checkedValues.includes(x)
				? state.checkedValues.filter(c => c !== x)
				: [...state.checkedValues, x]
		}));
	}
	Submit() {
		// "/bankapi/v1.0/Cheque/ChequeOrderAll", ////bu printer icin
		this.ExecutePostRequest("/bankapi/v1.0/WireTransfer/WireTransferSend",
			this.state.checkedValues,
			(responseData) => {
				var listItems = [];
				if (responseData.Item != null) {
					listItems = responseData.Item.map((number) =>

						<div key={number.Id}>
							{number.ApprovedDate == null ?
								number.UniqueClientId + "==>" + number.Name + "==>" + Formatter.FormatDate(number.Date) + "==>Error"
								:
								number.UniqueClientId + "==>" + number.Name + "==>" + Formatter.FormatDate(number.Date) + "==>Succeses"
							}
						</div>
					);
				}

				this.ShowGenericMessage("warning", "Wire Transactions Send List Successfully ", listItems);
				this.Clear();
				this.Search();
			});
	}



	ErrorSendCallback(error) {
		this.setState({ isLoading: false });
		this.ShowGenericMessage("error", "An error occurred while sending data", error.message);
	}


	Search() {

		this.setState({ isLoading: true });

		const { model } = this.state;
		if (!this.state.isBackOffice) {
			model.UniqueClientId = ClientHelper.GetClientRowId();
		}

		this.ExecutePostRequest("/bankapi/v1.0/WireTransfer/WireTransferList",
			this.state.model,
			(responseData) => {
				if (responseData.Item != null) {
					this.setState({
						list: responseData.Item.WireTransactionsList || [],
						totalresult: responseData.Item.WireTransactionsDetailInfo
					});
				}
			});
	}
	Clear() {
		this.setState({
			model: this.initialModel,
			list: [],
			selected: null, checkedValues: [],
			totalresult: {}
		});
	}
	Validate() {
		const { model, list } = this.state;

		if (!model.Code) {
			this.ShowGenericMessage("warning", "Action code not entered", "Enter action code to continue.");
			return false;
		}

		if (!model.Description) {
			this.ShowGenericMessage("warning", "Action description not entered", "Enter action description to continue.");
			return false;
		}

		if (!model.Order) {
			this.ShowGenericMessage("warning", "Action order not entered", "Enter action order to continue.");
			return false;
		}

		var isActionCodeUnique = true;
		list
			.filter(x => x.Code === model.Code)
			.map(() => {
				isActionCodeUnique = false;
			});
		if (!isActionCodeUnique) {
			this.ShowGenericMessage("warning", "Action code must be unique", "Action code has already been inserted.");
			return false;
		}

		var isActionOrderUnique = true;
		list
			.filter(x => x.Order == model.Order)
			.map(() => {
				isActionOrderUnique = false;
			});
		if (!isActionOrderUnique) {
			this.ShowGenericMessage("warning", "Action order must be unique", "Action order has already been inserted.");
			return false;
		}

		return true;
	}

	render() {
		const { model, list, selected, isBackOffice } = this.state;
		const { Disabled } = this.props;
		const { IsLoading, Alert } = this.state;

		return (
			<div>
				{Alert}
				<LoadingComponent Show={IsLoading} />
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},
						{
							Code: "Submit",
							Disabled: Disabled || Object.keys(this.state.checkedValues).length === 0,
							OnClick: () => this.ShowGenericMessage("warning", "Warning", "Are you sure want to Send ?", true, this.Submit),
						},
						{
							Code: "Clear",
							OnClick: this.Clear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After}
				/>

				<GridContainer spacing={16}>
					<GridItem md={12} xs={12}>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={"Main Parameters"} />
							</CardHeader>
							<CardBody>

								<GridContainer>
									<GridItem md={4} xs={12}>

										<GenericSelectInput
											Name="Name"
											LabelText="Client Name"
											Value={model.Name || ""}
											DataRoot="Item"
											ValueChanged={this.ValueChanged}
											KeyValueMember="Id"
											TextValueMember="Name"
											Url="/bankapi/v1.0/BankCustomer/Search"
											Method="POST"
											Parameter={{ UniqueClientId: 0 }}
											Disabled={!isBackOffice}


										/>

										<GenericSelectInput
											Name="UniqueClientId"
											LabelText="Unique Client ID"
											Value={model.UniqueClientId || ""}
											DataRoot="Item"
											ValueChanged={this.ValueChanged}
											KeyValueMember="Id"
											TextValueMember="UniqueClientId"
											Url="/bankapi/v1.0/BankCustomer/Search"
											Method="POST"
											Parameter={{ UniqueClientId: 0 }}
											Disabled={!isBackOffice}

										/>
									</GridItem>

									<GridItem md={4} xs={12}  >
										<GenericDateInput
											Name="SearchStartDate"
											LabelText="From Date"
											Utc={true}
											Value={model == null || undefined ? null : model.SearchStartDate == null || undefined ? "" : model.SearchStartDate}
											ValueChanged={this.ValueChanged}
											MaxDate={model.SearchEndDate == null ? DateHelper.GetDateLocal() : model.ToDate}


										/>
										<GenericDateInput
											Name="SearchEndDate"
											LabelText="To Date"
											Utc={true}
											Value={model == null || undefined ? null : model.SearchEndDate == null || undefined ? "" : model.SearchEndDate}
											ValueChanged={this.ValueChanged}
											MaxDate={DateHelper.GetDateLocal()}
											MinDate={model.SearchStartDate}
										/>
									</GridItem>

								</GridContainer>
							</CardBody>
						</Card>
						{<Card >
							<CardBody>
								<ChequeWireTransactionsDataTable
									checkedValues={this.state.checkedValues}
									list={this.state.list}
									totalresult={this.state.totalresult}
									Handle={this.HandleCheck}

								// handleSend={this.HandleSend}
								/>
							</CardBody>
						</Card>}
					</GridItem>
				</GridContainer>
			</div>
		);
	}


}
ChequeWireTransactions.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any,
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool,
	ValueChanged: PropTypes.func,
	Model: PropTypes.object
};

export default withArtifex(ChequeWireTransactions, styles);