import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ClientHelper from "core/ClientHelper";
import { DeleteIcon, DownloadIcon, EditIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import { GenericGrid, GenericLabel, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import ParameterComponent from "views/Components/ParameterComponent";
import { UserStatus } from "views/Constants/Constant";

class ManualKycApplicationDocument extends React.Component {
	constructor(props) {
		super(props);
		this.UploadClick = this.UploadClick.bind(this);
		this.FileSelect = this.FileSelect.bind(this);
		this.UpdateModel = this.UpdateModel.bind(this);
		this.UpdateFile = this.UpdateFile.bind(this);
		this.fileInputRef = React.createRef();
		this.state = {
			vModel: {},
			documentModel: { File: { FileName: "" } },
		};

		this.ColumnsData = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip="Edit"
							Disabled={this.props.Disabled}
							Icon={EditIcon}
							OnClick={this.HandleEditButton(row.original)} />
						<GridButton
							tooltip="Delete"
							Disabled={this.props.Disabled}
							Icon={DeleteIcon}
							OnClick={this.HandleDeleteButton(row.original)} />
						<GridButton
							tooltip="Download"
							Disabled={this.props.Disabled}
							Icon={DownloadIcon}
							OnClick={this.HandleDownloadButton(row.original)} />
					</div>
				),
				sortable: false,
				filterable: false,
				width: 100
			},
			{
				Header: "Document Type",
				accessor: "DocumentType.ParameterDesc"
			},
			{
				Header: "Verification Status",
				accessor: "VerificationStatus.ParameterDesc"
			}
		];
		this.client = ClientHelper.GetUser();
		this.verifiedPersonIdParameter = { UniqueClientId: ClientHelper.GetClientRowId(), UserStatusList: [ UserStatus.Ok ] };

	}
	HandleEditButton = model => () => {
		this.setState({ documentModel: { ...model } });
	}
	HandleDeleteButton = model => async () => {
		let confirmResult = await this.props.showConfirmMessage("warning", "Warning", "Delete this record !. Are you sure?");
		if (confirmResult) {
			this.DeleteModel(model);
		}
	}
	HandleDownloadButton = model => () => {
		this.DownloadFile(model);
	}
	componentDidMount() {
		if (this.props.setAppLeftTitle)
			this.props.setAppLeftTitle("Documents");
		var model = {
			Id: 0
		};
		this.setState({ documentModel: model, ManualKycDocumentList: this.props.ManualKycDocumentList });

	}

	UploadClick() {
		this.fileInputRef.current.click();
	}

	FileSelect(e) {
		e.preventDefault();
		var file = e.target.files[0];
		if (!file) return;
		var documentModel = this.state.documentModel;
		if (documentModel.File == undefined)
			documentModel.File = { FileName: file.name };
		else {
			documentModel.File.FileName = file.name;
		}

		this.setState({ fileModel: file, documentModel });
	}

	OperationCheck = () => {

		var ErrorList = [];
		const data = this.state.documentModel;
		let { vModel } = this.state;

		if (data == null) {
			ErrorList.push("Document model not defined.");
		}
		if (data != null && (data.DocumentTypeId == null || data.DocumentTypeId == "")) {
			vModel.DocumentTypeId = true;
			ErrorList.push("Document type must be defined.");
		} else {
			vModel.DocumentTypeId = false;
		}

		this.setState({ vModel });
		if (ErrorList.length > 0) {
			this.props.showMessageNode("warning", "Please Fill Required Fields", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.UpdateModel();
	}

	UpdateModel = async () => {
		const { ManualKycModel, ExecuteApiPost } = this.props;
		const { fileModel } = this.state;
		var request = this.state.documentModel;
		request.KycManualApplicationId = ManualKycModel.Id;

		var result = await ExecuteApiPost("/amlkycapi/v1.0/KycManualApplicationDocument/InsertUpdate", request);
		if (result?.Id > 0 && fileModel) {
			result = await this.UpdateFile(result.Id);
		}

		if (result) {
			this.props.showMessage("success", "Success", this.state.documentModel.Id > 0 ? "Document Updated" : "Document Saved");
		}
		this.ClearData();
		this.props.GetDocumentFunc();
	}
	UpdateFile = async (documentId) => {
		var formData = new FormData();
		formData.append("file", this.state.fileModel, String(this.state.fileModel.name));
		formData.append("documentId", String(documentId));

		var { ExecuteApiFileUpload } = this.props;

		var result = await ExecuteApiFileUpload("/amlkycapi/v1.0/KycManualApplicationDocument/UploadFile", formData);
		return result;
	}
	DeleteModel = async (model) => {
		if (model == null || model.Id == null || model.Id == 0) {
			this.props.showMessage("warning", "Warning", "Document must be selected for delete operation.");
			return;
		}

		var { ManualKycModel, ExecuteApiPost } = this.props;

		var request = this.state.documentModel;
		request.ManualKycId = ManualKycModel.Id;

		var result = await ExecuteApiPost("/amlkycapi/v1.0/KycManualApplicationDocument/Delete", { Id: model.Id });
		if (result) {
			this.props.showMessage("success", "Success", "Document Deleted");
			this.ClearData();
			this.props.GetDocumentFunc();
		}

	}
	SelectedRowChange = (e, index) => {
		const model = this.props.ManualKycDocumentList[index];
		this.setState({ documentModel: model || {} });
	}
	ClearData = () => {
		var model = { Id: 0 };
		this.setState({ fileModel: undefined, documentModel: model });
	}
	handleChange = (name, newValue, data) => {
		this.setState(function (state, props) {
			var model = state.documentModel || {};
			model[name] = newValue;
			return { documentModel: model };
		});
	}


	DownloadFile = (index) => {
		const { ExecuteApiFileDownloadWithGenericResponse } = this.props;
		ExecuteApiFileDownloadWithGenericResponse(
			"/coreapi/v1.0/File/DownloadFileFromBinary",
			{ "FileId": index.FileId },
			`KycDocument_${Date.now()}`,
			index.File.FileExtension.ParameterDesc,
			() => {
				this.props.showMessage("success", "Create & Download", "Created and downloaded receipt successfully !!!");
			}
		);

	}
	GetRenderItemClientUserSelect(d) {
		return `${d.FirstName} - ${d.LastName}`;
	}

	render() {
		const { Disabled: DisabledProp, ManualKycDocumentList } = this.props;
		const { vModel, fileModel } = this.state;
		const { documentModel } = this.state;
		const Disabled = DisabledProp || this.state.CustomDisabled;

		var VerifiedPersonId = documentModel.VerifiedPersonId || this.client?.Id;

		return (
			<GridContainer spacing={16}>

				<GridItem xs={12}>
					<GridContainer>
						<GridItem xs={4}>
							<ParameterComponent
								Name="DocumentTypeId"
								LabelText="Document Type"
								ParameterCode="KYCDocumentType"
								Value={documentModel == null ? "" : documentModel.DocumentTypeId || ""}
								ValueChanged={this.handleChange}
								Disabled={Disabled}
								Required
								IsInvalid={vModel.DocumentTypeId} />
							<ParameterComponent
								Name="VerificationStatusId"
								LabelText="Verification Status"
								Value={documentModel == null ? "" : documentModel.VerificationStatusId || ""}
								ValueChanged={this.handleChange}
								ParameterCode="KYCVerifiedStatus"
								Disabled={Disabled}
								Required />
							<GenericSelectInput
								Name="VerifiedPersonId"
								LabelText="Verified Person"
								Value={VerifiedPersonId || ""}
								ValueChanged={this.handleChange}
								KeyValueMember="Id"
								Url="/coreapi/v1.0/User/Search"
								Method="POST"
								Disabled={true}
								DataRoot="Item"
								Parameter={this.verifiedPersonIdParameter}
								RenderItem={this.GetRenderItemClientUserSelect}
							/>

						</GridItem>
						<GridItem xs={2}>
							<GenericLabel Text="Upload File" />
							<Button onClick={this.UploadClick} disabled={Disabled || documentModel == null ||
								(documentModel.DocumentTypeId == undefined || documentModel.DocumentTypeId == 0) ||
								(documentModel.VerificationStatusId == undefined || documentModel.VerificationStatusId == 0) ||
								(VerifiedPersonId == undefined || VerifiedPersonId == 0)} style={{ marginLeft: "16px" }}>Browse</Button>
							<input key={documentModel.FileName} type="file" style={{ display: "none" }} onChange={this.FileSelect} ref={this.fileInputRef}
								accept=".pdf" />
						</GridItem>
						<GridItem xs={4}>
							<GenericTextInput
								key={this.state.uniq}
								Name="FileName"
								LabelMd={5}
								LabelText="File Name"
								Value={documentModel.File == null || documentModel.File.FileName == null || undefined ? "" : documentModel.File.FileName}
								Disabled={true} />
						</GridItem>

					</GridContainer>
					<GridContainer justify="flex-end">
						<GridItem>
							<Button disabled={Disabled} size="sm" color="black" onClick={this.ClearData}>	Clear	</Button>
							<Button disabled={
								Disabled || 
								documentModel == null ||
								(documentModel.DocumentTypeId == undefined || documentModel.DocumentTypeId == 0) ||
								(documentModel.VerificationStatusId == undefined || documentModel.VerificationStatusId == 0) ||
								(VerifiedPersonId == undefined || VerifiedPersonId == 0)} size="sm" color="black" onClick={this.OperationCheck}>{documentModel.Id > 0 ? "Update" : "Add"}</Button>
						</GridItem>
					</GridContainer>
				</GridItem>
				<GridItem xs={12}>
					<GridItem xs={12}>
						<GenericTitle text={"Document List"} />
					</GridItem>
					<GridItem xs={12}>
						<GridItem>
							{
								ManualKycDocumentList &&
								<GenericGrid
									Data={ManualKycDocumentList}
									PageSize={5}
									HideButton={true}
									Columns={this.ColumnsData}
									RowSelected={index => {
										this.SelectedRowChange(0, index);
									}}
								/>
							}
						</GridItem>
					</GridItem>

				</GridItem>
			</GridContainer >
		);
	}
}

ManualKycApplicationDocument.propTypes = {
	classes: PropTypes.object,
	documentModel: PropTypes.object,
	ManualKycModel: PropTypes.object,
	ManualKycDocumentList: PropTypes.array,
	GetDocumentFunc: PropTypes.func,
};

export default ManualKycApplicationDocument;