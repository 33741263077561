import styles from "assets/jss/material-dashboard-pro-react/views/productRestrictionProfileStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React from "react";
import DualListBox from "react-dual-listbox";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericCheckInput, GenericGrid, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { MenuCodes } from "views/Constants/Constant.js";

class ProductRestrictionProfileDefinition extends React.Component {
	constructor(props) {
		super(props);

		this.isClient = ClientHelper.IsClient();

		this.initialModel = {
			Id: 0,
			MerchantValues: [],
			MCCValues: [],
			TxnCodeValues: [],
			CountryValues: [],
			MccOptions: [],
			TxnCodeOptions: [],
			CountryOptions: [],
			HasECommerceUsage: false,
			HasInternationalUsage: false,
			IsDefaultProfile: props.MenuCode === MenuCodes.DCBankProductRestrictionProfile,
		};

		this.state = {
			model: { ...this.initialModel },
			merchantModel: {},
			vModel: {},
			isLoading: false,
			ProfileList: []

		};

		this.emptyObject = {};
		this.merchantOptions = [];

		this.DefaultIndex = 0;

		this.renderItemUniqueClient = {};
		this.parameterRestrictionCheckType = { ParameterCode: "RestrictionCheckType" };
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Product Restriction Profile");
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
		this.LoadOptionList();
		this.HandleSearch();
	}

	GetMerchantOptions = (model) => {
		if (!this.merchantOptions.find(i => i.label == model.Name)) {
			this.merchantOptions.push({
				"value": model.Name ? model.Name.toString() : "",
				"label": model.Name
			});
		}
		this.forceUpdate();
	}

	LoadOptionList = () => {
		this.setState({ isLoading: true });

		Proxy.POST("/prepaidapi/v1.0/Mcc/Search",
			{},
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", responseData.ErrorDescription);
					this.setState({ isLoading: false });
					return;
				}
				var MccOptions = this.TranslateToLabelValue(responseData.Item, "FullName", "Code");
				Proxy.GET("/bankapi/v1.0/BankTransactionCode/GetAllForTransactionPrepaid",
					(responseData) => {
						if (!responseData.IsSucceeded) {
							this.ShowMessage("error", responseData.ErrorDescription);
							this.setState({ isLoading: false });
							return;
						}
						var TxnCodeOptions = this.TranslateToLabelValue(responseData.Item, "FullName", "TxnCode");
						Proxy.POST("/coreapi/v1.0/Country/GetAll",
							null,
							(responseData) => {
								if (!responseData.IsSucceeded) {
									this.ShowMessage("error", responseData.ErrorDescription);
									this.setState({ isLoading: false });
									return;
								}
								var CountryOptions = this.TranslateToLabelValue(responseData.Item, "Name", "ISO2");
								const model = { ...this.state.model };
								model.MccOptions = MccOptions;
								model.TxnCodeOptions = TxnCodeOptions;
								model.CountryOptions = CountryOptions;
								this.setState({ isLoading: false, model });
							},
							(error) => {
								this.setState({ isLoading: false });
								this.ShowMessage("error", "Error", error);
							}
						);
					},
					(error) => {
						this.setState({ isLoading: false });
						this.ShowMessage("error", "Error", error);
					}
				);
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	TranslateToLabelValue = (array, labelPath, valuePath) => {
		var returnObject = [];
		array.forEach((value) => {
			if (value[valuePath])
				returnObject.push({ value: value[valuePath].toString(), label: value[labelPath] });

		});
		return returnObject;
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}

	HandleChangeMerchant = (name, newValue, data) => {
		const merchantModel = { ...this.state.merchantModel };
		merchantModel[name] = newValue;
		this.setState({ merchantModel });
	}

	HandleClear = () => {
		this.merchantOptions = [];
		this.setState({ merchantModel: {}, model: { ...this.initialModel } });
		this.DefaultIndex = 1;
		this.LoadOptionList();
	}

	HandleSearch = () => {

		const { model } = this.state;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/ProductRestrictionProfile/SearchForComponent",
			model
			,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", responseData.ErrorDescription);
					return;
				}

				if (responseData.Item !== null) {
					this.setState({ ProfileList: responseData.Item });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	HandleSearchByProfileName = (index) => {

		const model = this.state.ProfileList[index];


		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/ProductRestrictionProfile/Search",
			model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", responseData.ErrorDescription);
					return;
				}

				if (responseData.Item !== null) {
					this.merchantOptions = responseData.Item.MerchantOptions;
					var tempModel = responseData.Item;
					tempModel.IsUpdate = true;
					this.setState({ model: tempModel });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	HandleSubmit = () => {
		if (!this.Validate())
			return;

		this.setState({ isLoading: true });
		Proxy.POST("/prepaidapi/v1.0/ProductRestrictionProfile/InsertOrUpdate",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", responseData.ErrorDescription);
					return;
				}
				this.setState({ hasChandedFromList: false });
				this.HandleSearch();
				this.ShowMessage("success", "Success", "Operation is successfully!");
				this.HandleClear();
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	HandleUpdate = () => {
		if (!this.Validate())
			return;

		this.setState({ isLoading: true });
		Proxy.POST("/prepaidapi/v1.0/ProductRestrictionProfile/InsertOrUpdate",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", responseData.ErrorDescription);
					return;
				}
				this.setState({ hasChandedFromList: false });
				this.HandleSearch();
				this.ShowMessage("success", "Success", "Operation is successfully!");
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	HandleDelete = () => {
		this.setState({ isLoading: true });
		Proxy.POST("/prepaidapi/v1.0/ProductRestrictionProfile/Delete",
			{ Id: this.state.model.Id },
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", responseData.ErrorDescription);
					return;
				}
				this.setState({ hasChandedFromList: false });
				this.HandleSearch();
				this.ShowMessage("success", "Success", "Operation is successfully!");
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	AddMerchant = () => {
		const { merchantModel } = this.state;
		merchantModel.Index = this.merchantOptions.length;
		this.GetMerchantOptions(merchantModel);
	}

	ColumnListBoxOnChangeMerchant = (selected) => {
		const { model } = this.state;
		model.MerchantValues = selected;
		this.setState({ model });
	}

	ColumnListBoxOnChangeMCC = (selected) => {
		const { model } = this.state;
		// selected.forEach((value, index, array) => {
		// 	array[index] = Number.parseInt(value)
		// });
		model.MCCValues = selected;
		this.setState({ model });
	}

	ColumnListBoxOnChangeTxnCode = (selected) => {
		const { model } = this.state;
		model.TxnCodeValues = selected;
		this.setState({ model });
	}

	ColumnListBoxOnChangeCountry = (selected) => {
		const { model } = this.state;
		model.CountryValues = selected;
		this.setState({ model });
	}

	Validate = () => {
		const { model, vModel } = this.state;
		var messages = [];


		vModel.ProfileName = model.ProfileName == null || model.ProfileName.trim() == "";
		if (vModel.ProfileName) messages.push("Profile Name cannot be empty");


		vModel.MerchantRestrictionCheckType = model.MerchantRestrictionCheckType == null;
		if (vModel.MerchantRestrictionCheckType) messages.push("Merchant Restriction Check Type must be select");

		vModel.MccRestrictionCheckType = model.MccRestrictionCheckType == null;
		if (vModel.MccRestrictionCheckType) messages.push("Mcc Restriction CheckType must be select");

		vModel.TransactionRestrictionCheckType = model.TransactionRestrictionCheckType == null;
		if (vModel.TransactionRestrictionCheckType) messages.push("Transaction Restriction Check Type cannot be empty");

		vModel.CountryRestrictionCheckType = model.CountryRestrictionCheckType == null;
		if (vModel.CountryRestrictionCheckType) messages.push("Country Restriction Check Type must be select");

		if (messages.length > 0) {
			this.props.showMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.setState({ vModel });
		return true;
	}



	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: null })} OnConfirm={onConfirm} />
		});
	}

	render() {
		const { classes, Disabled } = this.props;
		const { alert, model, merchantModel, vModel, isLoading } = this.state;
		var IsClient = ClientHelper.IsClient();

		return (
			<>

				<LoadingComponent Show={isLoading} />

				{alert}

				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
					{ Code: "Update", OnClick: this.HandleUpdate, Disabled: Disabled || IsClient || !model.IsUpdate },
					{ Code: "Delete", OnClick: this.HandleDelete, Disabled: Disabled || IsClient || !model.IsUpdate },
					{ Code: "Submit", OnClick: this.HandleSubmit, Disabled: Disabled || IsClient || model.IsUpdate },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text="Main Parameters" />
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={3}>
										<GenericTextInput
											Name="ProfileName"
											IsText
											LabelText="Profile Name"
											Value={model.ProfileName}
											ValueChanged={this.HandleChange}
											Required
											IsInvalid={vModel.ProfileName}
											Disabled={Disabled}
										/>
									</GridItem>
									<GridItem xs={3}>
										<GenericCheckInput
											Name="HasInternationalUsage"
											LabelText="Has International Usage"
											Value={model.HasInternationalUsage || false}
											ValueChanged={this.HandleChange}
											Disabled={Disabled} />
									</GridItem>
									<GridItem xs={3}>
										<GenericCheckInput
											Name="HasECommerceUsage"
											LabelText="Has e-Commerce Usage"
											Value={model.HasECommerceUsage || false}
											ValueChanged={this.HandleChange}
											Disabled={Disabled} />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={6}>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text="Merchant Selection" />
							</CardHeader>
							<CardBody>
								<GridItem>
								</GridItem>
								<GridContainer>
									<GridItem xs={6} style={{ marginTop: 2 }}>
										<GridContainer>
											<GridItem xs={9}>
												<GenericTextInput
													Name="Name"
													LabelText="Merchant"
													Value={merchantModel.Name}
													ValueChanged={this.HandleChangeMerchant}
													Required
													IsInvalid={vModel.MerchantName}
													Disabled={Disabled || IsClient}
												/>
											</GridItem>
											<GridItem md={3} xs={6} style={{ marginTop: 2 }}>
												<Button size="sm" onClick={this.AddMerchant} disabled={Disabled || IsClient}>Add</Button>
											</GridItem>
										</GridContainer>
									</GridItem>
									<GridItem xs={6} style={{ marginTop: 2 }}>
										<GridContainer>
											<GridItem xs={12}>
												<GenericSelectInput
													key={this.DefaultIndex}
													Name="MerchantRestrictionCheckType"
													LabelMd={5}
													LabelText="Restriction Check Type"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.parameterRestrictionCheckType}
													DataRoot="Item"
													KeyValueMember="ParameterValue"
													TextValueMember="ParameterDesc"
													Value={model.MerchantRestrictionCheckType}
													ValueChanged={this.HandleChange}
													Required
													IsInvalid={vModel.MerchantRestrictionCheckType}
													DefaultIndex={this.DefaultIndex}
													Disabled={Disabled || IsClient}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
									<GridItem xs={12}>
										<br />
										<DualListBox
											canFilter
											className={classes.root}
											options={this.merchantOptions}
											selected={model.MerchantValues}
											onChange={this.ColumnListBoxOnChangeMerchant}
											disabled={Disabled || IsClient} />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={6}>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text="MCC Selection" />
							</CardHeader>
							<CardBody>
								<GridItem>
								</GridItem>
								<GridContainer>
									<GridItem xs={6} style={{ marginTop: 2 }}>
										<GridContainer>
											<GridItem xs={12}>
												<GenericSelectInput
													Name="MccRestrictionCheckType"
													LabelMd={5}
													LabelText="Restriction Check Type"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.parameterRestrictionCheckType}
													DataRoot="Item"
													KeyValueMember="ParameterValue"
													TextValueMember="ParameterDesc"
													Value={model.MccRestrictionCheckType}
													ValueChanged={this.HandleChange}
													Required
													IsInvalid={vModel.MccRestrictionCheckType}
													DefaultIndex={this.DefaultIndex}
													Disabled={Disabled || IsClient}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
									<GridItem xs={12}>
										<br />
										<DualListBox
											canFilter
											className={classes.root}
											options={model.MccOptions}
											selected={model.MCCValues}
											onChange={this.ColumnListBoxOnChangeMCC}
											disabled={Disabled || IsClient} />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={6}>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text="Transaction Selection" />
							</CardHeader>
							<CardBody>
								<GridItem>
								</GridItem>
								<GridContainer>
									<GridItem xs={6} style={{ marginTop: 2 }}>
										<GridContainer>
											<GridItem xs={12}>
												<GenericSelectInput
													Name="TransactionRestrictionCheckType"
													LabelMd={5}
													LabelText="Restriction Check Type"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.parameterRestrictionCheckType}
													DataRoot="Item"
													KeyValueMember="ParameterValue"
													TextValueMember="ParameterDesc"
													Value={model.TransactionRestrictionCheckType}
													ValueChanged={this.HandleChange}
													Required
													IsInvalid={vModel.TransactionRestrictionCheckType}
													DefaultIndex={this.DefaultIndex}
													Disabled={Disabled || IsClient}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
									<GridItem xs={12}>
										<br />
										<DualListBox
											canFilter
											className={classes.root}
											options={model.TxnCodeOptions}
											selected={model.TxnCodeValues}
											onChange={this.ColumnListBoxOnChangeTxnCode}
											disabled={Disabled || IsClient} />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={6}>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text="Country Selection" />
							</CardHeader>
							<CardBody>
								<GridItem>
								</GridItem>
								<GridContainer>
									<GridItem xs={6} style={{ marginTop: 2 }}>
										<GridContainer>
											<GridItem xs={12}>
												<GenericSelectInput
													Name="CountryRestrictionCheckType"
													LabelMd={5}
													LabelText="Restriction Check Type"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={this.parameterRestrictionCheckType}
													DataRoot="Item"
													KeyValueMember="ParameterValue"
													TextValueMember="ParameterDesc"
													Value={model.CountryRestrictionCheckType}
													ValueChanged={this.HandleChange}
													Required
													IsInvalid={vModel.CountryRestrictionCheckType}
													DefaultIndex={this.DefaultIndex}
													Disabled={Disabled || IsClient}

												/>
											</GridItem>
										</GridContainer>
									</GridItem>
									<GridItem xs={12}>
										<br />
										<DualListBox
											canFilter
											className={classes.root}
											options={model.CountryOptions}
											selected={model.CountryValues}
											onChange={this.ColumnListBoxOnChangeCountry}
											disabled={Disabled || IsClient} />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>

					<Card className="no-radius">
						<CardBody>
							<CardHeader>
								<GenericTitle text={" Profile List"} />
							</CardHeader>
							<GridItem xs={12}>
								<GridItem>
									<GenericGrid
										Data={this.state.ProfileList}
										Columns={[
											{
												Header: "Profile Name",
												accessor: "ProfileName"
											},
											{
												Header: "Has International Usage ",
												accessor: "HasInternationalUsage",
												Cell: row => (
													<div>{row.value == true ? "Yes" : "No"}</div>
												),
											},
											{
												Header: "Has e-Commerce Usage",
												accessor: "HasECommerceUsage",
												Cell: row => (
													<div>{row.value == true ? "Yes" : "No"}</div>
												),
											},

										]}
										RowSelected={index => {
											this.HandleSearchByProfileName(index);
										}}
										SelectedIndex={this.state.rowIndex}
										ShowPagination={true}
									/>
								</GridItem>
							</GridItem>
						</CardBody>
					</Card>
				</GridContainer>

			</>
		);
	}
}

ProductRestrictionProfileDefinition.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(ProductRestrictionProfileDefinition, styles);