import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import { EditIcon } from "core/Icons";
import { TaskPool } from "core/TaskPool";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericExpansionPanel, GenericGrid, GenericSelectInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton.jsx";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { GridColumnType } from "views/Constants/Constant";
import ClientDisputeReportAction from "views/PPaid/Clearing/Dispute/ClientDisputeReportAction.jsx";


class ClientDipsuteReport extends Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined,
		};

		this.state = {
			model: { ...this.initialModel },
			generalModel: { BankAccount: {}, WalletAccount: {}, ...this.initialModel },
			embossModel: { ...this.initialModel },
			statisticModel: { ...this.initialModel },
			transactions: [],
			disputeData: {},
			isLoading: false,
			activeIndex: 0,
			isDisputeActionDialogOpen: false
		}

		this.loadTaskPool = new TaskPool(this.handleLoadTaskPoolAppend, this.handleLoadTaskPoolCompleted);

		this.emptyObject = {};

		this.maxLength26 = { maxLength: 26 };
		this.maxLength19 = { maxLength: 19 };

		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined
		};

		this.parameterPeriod = { ParameterCode: "LoadingPeriod" };

		this.renderItemUniqueClient = {};

		this.parameterProduct = {};
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Client Dispute Report");
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
	}

	handleLoadTaskPoolCompleted = () => {
		this.setState({ isLoading: false });
	}

	handleLoadTaskPoolAppend = () => {
		this.setState({ isLoading: true });
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		if (name === "UniqueClientId") {
			delete model.ClientCustomerId;
			delete model.CustomerNumber;
			delete model.CardId;
			delete model.CardTokenNumber;
		}
		if (name === "Customer") {
			if (newValue) {
				model.ClientCustomerId = newValue.Id;
				model.CustomerNumber = newValue.CustomerNumber;
			} else {
				delete model.ClientCustomerId;
				delete model.CustomerNumber;
				delete model.CardId;
				delete model.CardTokenNumber;
			}
		}
		if (name === "Card") {
			if (newValue) {
				model.CardId = newValue.CardId;
				model.CardTokenNumber = newValue.CardTokenNumber;
			} else {
				delete model.CardId;
				delete model.CardTokenNumber;
			}
		}
		model[name] = newValue;
		this.setState({ model });
	}

	HandleClear = () => {
		this.setState({
			model: { ...this.initialModel },
			transactions: []
		});
	}

	HandleSearch = () => {

		this.loadTaskPool.AppendTask(
			Proxy.POST(
				"/prepaidapi/v1.0/ClearingDispute/GetClientDisputeAll",
				this.state.model,
				(responseData) => {
					this.setState({ isLoading: false });
					if (!responseData.IsSucceeded) {
						this.props.showMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					if (responseData.Item !== null) {
						this.setState({
							transactions: responseData.Item
						});
					} else {
						this.setState({
							transactions: []
						});
					}
				},
				(error) => {
					this.setState({ isLoading: false });
					this.props.showMessage("error", "Error", error);
				}
			)
		);
	}

	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}

	TabIndexChanged = (index) => {
		this.setState({ activeIndex: index });
	}

	FillCustomer = (customer) => {
		this.HandleChange("Customer", customer);
	}

	FillCard = (card) => {
		this.HandleChange("Card", card);
	}

	HandleDispute = (data, index) => {

		var disputeData = this.state.transactions[index];

		this.setState({ isDisputeActionDialogOpen: true, disputeData: disputeData });
	}

	HandleActionDialogClose = () => {
		this.setState({ isDisputeActionDialogOpen: false });
		this.HandleSearch();
	}

	DisputeModelChange = (modelFunction) => {
		this.setState(function (state, props) {
			var model = state.disputeData || {};

			if (modelFunction)
				model = modelFunction(model);

			return { disputeData: model };
		});
	}


	render() {
		const { Disabled } = this.props;
		const { alert, isLoading, model, transactions } = this.state;

		var IsClient = ClientHelper.IsClient();

		return (
			<>
				<LoadingComponent Show={isLoading} />
				{alert}

				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />


				<ClientDisputeReportAction
					isDisputeActionDialogOpen={this.state.isDisputeActionDialogOpen}
					onActionModelClose={this.HandleActionDialogClose}
					disputeData={this.state.disputeData}
					onModelChange={this.DisputeModelChange}
					showMessage={this.props.showMessage}
				/>


				<Card className="no-radius">
					<CardBody>
						<GenericExpansionPanel Title="Main Panel">
							<GridContainer>
								<GridItem xs={12}>
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={this.parameterUniqueClientId}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={this.RenderItemUniqueClient}
												Value={model.UniqueClientId}
												ValueChanged={this.HandleChange}
												CanClear
												Disabled={IsClient}
												DefaultIndex={IsClient ? 0 : -1} />
										</GridItem>

										<GridItem xs={4}>
											<ParameterComponent
												Name="ClientDisputeStatusId"
												LabelText="Client Dispute Status"
												ParameterCode="ClientDisputeStatus"
												Value={model.ClientDisputeStatusId}
												ValueChanged={this.HandleChange}
											/>

										</GridItem>
									</GridContainer>
								</GridItem>
							</GridContainer>
						</GenericExpansionPanel>
					</CardBody>
				</Card>
				<Card className="no-radius">
					<CardBody>
						<GridContainer>
							<GridItem xs={12}>
								<GenericGrid
									Data={transactions}
									Columns={
										[
											{
												Header: "Actions",
												accessor: "Actions",
												Cell: row => (
													<div>
														<GridButton
															tooltip="Dispute"
															Icon={EditIcon}
															OnClick={() => { this.HandleDispute(row.original, row.index); }} />
													</div>
												),
												sortable: false,
												filterable: false,
												resizable: false,
												show: !IsClient,
												width: 60
											},
											{
												Header: "Client Dispute Status",
												accessor: "ClientDisputeStatus"
											},
											{
												Header: "Client Reson Code",
												accessor: "ClientResonCode"
											},
											{
												Header: "Client Document Status",
												accessor: "ClientDocumentStatus"
											},
											{
												Header: "Dispute Amount",
												accessor: "DisputeAmount"
											},
											{
												Header: "Dispute Entry Date",
												accessor: "DisputeEntryDate",
												type: GridColumnType.DateUtc,
											},
											{
												Header: "Resolve Date",
												accessor: "ResolveDate",
												type: GridColumnType.DateUtc,
											},
											{
												Header: "Resolve Explanation",
												accessor: "ResolveExplanation"
											},
											{
												Header: "Is Onus",
												accessor: "IsOnusDesc"
											},


										]}
									HideButton={true} />
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>

			</>
		);
	}
}

ClientDipsuteReport.propTypes = {
	classes: PropTypes.object,
	Disabled: PropTypes.bool
};

export default withArtifex(ClientDipsuteReport, {});