import { withStyles } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput } from "views/Components/Generic";
import { Proxy } from "core";
import LoadingComponent from "views/Components/LoadingComponent";
import externalContainerStyle from "assets/jss/material-dashboard-pro-react/layouts/externalContainerStyle.jsx";
import { ParameterEmptyObject } from "views/Constants/Constant.Parameter";
import { SortedName, SortedParameterValue, SortedPropertyName } from "views/Constants/Constant.Sorted";

class SearchAccount extends React.Component {

	constructor(props) {
		super(props);

		this.columns = [
			{
				Header: "Insert Date Time",
				accessor: "InsertDateTime"
			},
			{
				Header: "WalletId",
				accessor: "AccountId"
			},
			{
				Header: "Wallet Number",
				accessor: "AccountNumber"
			},
			{
				Header: "Client Name",
				accessor: "ClientName"
			},
			{
				Header: "Wallet Name",
				accessor: "AccountName"
			},
			{
				Header: "Wallet Owner Name",
				accessor: "AccountOwnerName"
			},
			{
				Header: "Wallet Status",
				accessor: "AccountStatus"
			},
			{
				Header: "Wallet Balance",
				accessor: "AccountBalance"
			},
			{
				Header: "Pending Balance",
				accessor: "PendingBalance"
			},
			{
				Header: "Program Id",
				accessor: "ProgramId"
			},
			{
				Header: "Province",
				accessor: "Province"
			},
			{
				Header: "Country",
				accessor: "Country"
			},
			{
				Header: "City",
				accessor: "City"
			},
			{
				Header: "Insert User",
				accessor: "InsertUser"
			}
		];
		this.getappCenterTitle = "Empty";
		this.getappLeftTitle = "Empty";
		this.zIndex10 = { zIndex: 10 };

		this.state = {
			model: {},
			data: [],
			recordKeeping: [],
			isSendEmailDialogOpen: false,
			activeClient: [],
			responseDetail: {},
			isDetailDialogOpen: false
		};
		this.cpverTable = React.createRef();
	}

	componentDidMount = () => {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;
		if (setAppCenterTitle) {
			setAppCenterTitle("WALLET");
			this.getappCenterTitle = "WALLET";
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("Wallet Search");
			this.getappLeftTitle = "Wallet Search";
		}
	}

	Clear = () => {
		this.setState({ model: {}, data: [], selected: null });
	}

	showLoading = () => { this.setState({ isLoading: true }); }
	hideLoading = () => { this.setState({ isLoading: false }); }

	showDetail = async (result) => {
		this.setState({ responseDetail: result || [], isDetailDialogOpen: true });
		console.log(result);
	}

	HandleSearch = () => {
		if(!this.validate()){
			return;
		}
		
		const model = { ...this.state.model };
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/Account/SearchAccount",
			model,
			(responseData) => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ data: responseData.Item });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	handleChange = (name, newValue) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	};

	GetRenderItemClient = (x) => {
		var render = `${x.Name} - ${x.UniqueClientId}`;

		return render;
	}

	ValueChanged = (name, value, data) => {
		const model = { ...this.state.model };
		model[name] = value;
		this.setState({ model });
	}

	validate = () => {
		const model = { ...this.state.model };
		if (model.UniqueClientId == null || model.UniqueClientId == 0) {
			this.props.showMessageNode("warning", "Please Select Client!");
			return false;
		}
		else{
			return true;
		}
	}

	render() {
		const { model, selected, isLoading, data } = this.state;
		const { Disabled } = this.props;

		return (
			<div>
				<LoadingComponent Show={isLoading} />
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.HandleSearch
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />

				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Filter">
									<GridContainer>
										<GridItem xs={4}>

											<GenericNumberInput
												NoFormatting={true}
												Name="AccountNumber"
												LabelText="Wallet Number"
												MaxLength={9}
												Value={model.AccountNumber || ""}
												ValueChanged={this.handleChange}
											/>

										</GridItem>
										<GridItem xs={4}>
											<GenericSelectInput
												Required={model.ReviewTypeIsPeriodic}
												IsInvalid={model.IsUniqueClientId}
												Name="UniqueClientId"
												LabelText="Client"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Method="POST"
												Parameter={ParameterEmptyObject}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={this.GetRenderItemClient}
												Sorted={SortedName}
												Value={model.UniqueClientId}
												ValueChanged={this.ValueChanged} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={data}
													Columns={this.columns}
													SelectedIndex={selected}
													IsSorted={true}
													PageSize={10}
													ShowPagination={true}
													ref={this.cpverTable} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

SearchAccount.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	ExecuteApiFileDownloadWithGenericResponse: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default withStyles(externalContainerStyle)(SearchAccount);