import React, { Component } from 'react'
import { withArtifex } from 'core';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import { GenericExpansionPanel, GenericNumberInput, GenericTextInput, GenericDialog, GenericDialogActions, GenericLabel, GenericSelectInput, GenericDateInput, GenericCheckInput, GenericTitle, GenericGrid } from 'views/Components/Generic';
import ClientHelper from "core/ClientHelper";
import { ProgramCodes, GridColumnType, EFTTransactionTypes, CardTransferTypes } from "views/Constants/Constant.js";
import LoadingComponent from 'views/Components/LoadingComponent';
import ButtonToolbar from 'views/Components/ButtonToolbar';
import ParameterComponent from 'views/Components/ParameterComponent';
import DateHelper from 'core/DateHelper';
import Formatter from 'core/Formatter';
import Button from "components/CustomButtons/Button.jsx";
import { DialogContent, DialogTitle } from "@material-ui/core";
class CreateCardTransfer extends Component {
	constructor(props) {
		super(props);
		this.uniqueClientId = ClientHelper.GetClientRowId();
		this.cardTransferType = CardTransferTypes.VisaDirect;
		this.state = {
			isFileDialogOpen: false,
			vModel: {},
			transfer: {},
			trxResponse: {},
			cardList: [],
			isLoading: false,
		};
		this.Columns = [
			{
				Header: "Customer Name",
				accessor: "ClientCustomerName",
			},
			{
				Header: "Source Wallet",
				accessor: "SourceAccount",
			},
			{
				Header: "Destination Wallet",
				accessor: "DestinationAccount",
			},
			{
				Header: "Currency",
				accessor: "Currency",
			},
			{
				Header: "Transaction Date",
				accessor: "TransactionDate",
				type: GridColumnType.DateUtc
			},
			// {
			// 	Header: "Transfer Reason",
			// 	accessor: "TransferReason",
			// },
			{
				Header: "Amount",
				accessor: "TransactionAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			// {
			// 	Header: "Status",
			// 	accessor: "Status",
			// },
			// {
			// 	Header: "Error Code",
			// 	accessor: "ErrorCode",
			// }
			{
				Header: "State",
				accessor: "TransactionState"
			}
		];
	}

	componentDidMount() {
		const { setAppLeftTitle, setAppCenterTitle } = this.props;

		if (setAppLeftTitle)
			setAppLeftTitle("Create Card Transfer");
		if (setAppCenterTitle)
			setAppCenterTitle("CARD TRANSFER");

		this.setState({ loadingCompleted: true });
	}
	handleSearch = async () => {
		this.setState(state => ({ trxGridToken: !state.trxGridToken }));
	}
	Clear = async () => {
		const { transfer } = this.state;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/bankapi/v1.0/VisaDirect/CreateMemo", { Memo: transfer.TransactionDescription });
		this.setState({ transfer: { NewEndUser: true }, vModel: {}, isFileDialogOpen: false });
	}

	CloseFile = () => {
		this.setState({ isFileDialogOpen: false });
	}
	Validate = () => {
		const { transfer, vModel } = this.state;
		const { showValidationErrors } = this.props;
		var errors = [];

		if (transfer.CustomerNumber == null || transfer.CustomerNumber == "") {
			vModel.CustomerNumber = true;
			errors.push("Customer Number must be defined !");
		} else { vModel.CustomerNumber = false; }

		if (transfer.AccountId == null || transfer.AccountId == "") {
			vModel.AccountId = true;
			errors.push("From Wallet must be defined !");
		} else { vModel.AccountId = false; }

		if (transfer.CardId == null || transfer.CardId == "") {
			vModel.CardId = true;
			errors.push("Card Number must be defined !");
		} else { vModel.CardId = false; }

		if (transfer.CurrencyId == null || transfer.CurrencyId == "") {
			vModel.CurrencyId = true;
			errors.push("Currency must be defined !");
		} else { vModel.CurrencyId = false; }

		if (transfer.TransactionDescription == null || transfer.TransactionDescription == "") {
			vModel.TransactionDescription = true;
			errors.push("Transaction Description must be defined !");
		} else { vModel.TransactionDescription = false; }

		if (transfer.Amount == null || transfer.Amount == "") {
			vModel.Amount = true;
			errors.push("Amount must be defined !");
		} else { vModel.Amount = false; }

		if (transfer.TransactionTypeId == null || transfer.TransactionTypeId == "" || transfer.TransactionTypeId < 0) {
			vModel.TransactionTypeId = true;
			errors.push("Transaction Type must be defined !");
		} else { vModel.TransactionTypeId = false; }

		if (errors.length > 0) {
			showValidationErrors(errors);

			return false;
		}
		this.setState({ isFileDialogOpen: true });
		return true;
	}
	HandleChange = (name, newValue, data) => {
		const transfer = { ...this.state.transfer };
		transfer[name] = newValue;
		this.setState({ transfer });
		if (name == "NewEndUser" && newValue == true) {
			this.Clear();
		}

		if (name == "TransactionTypeId" && data?.ParameterValue == EFTTransactionTypes.Debit) {
			this.setState({ Url: "/bankapi/v1.0/VisaDirect/PullFromCard" });
		}
		if (name == "TransactionTypeId" && data?.ParameterValue == EFTTransactionTypes.Credit) {
			this.setState({ Url: "/bankapi/v1.0/VisaDirect/CreatePayoutTransaction" });
		}
	}

	SelectedCardRowChange = (e, index) => {
		const model = this.state.cardList[index];
		this.setState({ transfer: model });
	}
	GetRenderItemAccount = (x) => {
		this.renderItemAccount = `${x.AccountNumber} - ${x.AccountName}`;
		return this.renderItemAccount;
	}
	RenderCurrencySelect = (d) => {
		return d.Code + " - " + d.Description;
	}

	RenderTransactionTypeSelect = (d) => {
		return d.ParameterDesc == "Debit" ? "Pull From Card" : "Push To Card";
	}

	HandleSubmit = async(url)=>{
		const transfer = { ...this.state.transfer };
		var result = await this.props.ExecuteApiPost(url, transfer, null, null, this.Validate, [this.Clear], true);
		console.log(result);
		this.CloseFile();
		this.setState({ trxResponse: result, vModel: {}, transfer: { NewEndUser: true }});
	}

	render() {
		const { transfer, alert, isLoading, vModel, isFileDialogOpen, trxResponse } = this.state;
		const { ExecuteApiPost, Disabled } = this.props;
		return (
			<React.Fragment>
				<GridContainer spacing={16}>
					{alert}
					<LoadingComponent Show={isLoading} />
					<GridItem xs={12}>
						<ButtonToolbar ButtonList={[
							{ Code: "Search", OnClick: this.handleSearch },
							{ Code: "Clear", OnClick: this.Clear },
							{ Code: "Delete", OnClick: this.HandleDelete },
							{
								Code: "Submit",
								Disabled: Disabled,
								OnClick: () => this.Validate()
								//OnClick: () => ExecuteApiPost(this.state.Url, transfer, null, null, this.Validate, null, true)
							}
						]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
						<Card style={{ border: "1px solid gray", borderRadiud: "2px", marginBlockEnd: "7px", overflow: "inherit" }}>
							<CardBody>
								<GenericExpansionPanel Title="" >
									<GridItem xs={5}>
										<GenericTitle text={"Create Card Transfer"} />
										<GenericSelectInput
											Name="CustomerNumber"
											LabelText="Customer Name"
											Method="POST"
											Url="/bankapi/v1.0/ClientCustomer/GetByProgramCodeAndClientId"
											DataRoot="Item"
											Parameter={{ ProgramCode: ProgramCodes.BANK_PROGRAMS_Card_Transfers, UniqueClientId: this.uniqueClientId, CardTransferType: this.cardTransferType }}
											KeyValueMember="CustomerNumber"
											TextValueMember="Name"
											Value={transfer.CustomerNumber == null || undefined ? "" : transfer.CustomerNumber}
											ValueChanged={this.HandleChange}
											Required
											IsInvalid={vModel.CustomerNumber}
										/>
										<GenericSelectInput
											key={transfer.CustomerNumber}
											Name="CardId"
											LabelText="Card Number"
											Method="POST"
											Url={transfer.CustomerNumber ? "/bankapi/v1.0/VisaDirect/GetCustomerCard" : ""}
											
											RenderItem={d => `${d.CardEmbossName} - ${d.MaskedCardNumber}`}
											DataRoot="Item"
											KeyValueMember="CardId"
											Parameter={{ CustomerNumber: transfer.CustomerNumber }}
											Value={transfer.CardId}
											ValueChanged={this.HandleChange}
											Required
											IsInvalid={vModel.CardId}
										/>
										<GenericSelectInput
											Name="AccountId"
											LabelText="From Wallet"
											Method="POST"
											Url="/bankapi/v1.0/VisaDirect/GetAllAccountByUniqueClient"
											DataRoot="Item"
											KeyValueMember="AccountId"
											Parameter={{}}
											TextValueMember="AccountNumber"
											RenderItem={this.GetRenderItemAccount}
											Sorted={{ Member: "AccountNumber" }}
											Value={transfer.AccountId}
											ValueChanged={this.HandleChange}
											Required
											IsInvalid={vModel.AccountId}
										/>
										<GenericSelectInput
											Name="CurrencyId"
											LabelText="Currency"
											Value={transfer.CurrencyId}
											ValueChanged={this.HandleChange}
											KeyValueMember="Id"
											TextValueMember="Description"
											Method="GET"
											Url="/bankapi/v1.0/VisaDirect/GetCurrencyByClient"
											RenderItem={this.RenderCurrencySelect}
											DataRoot="Item"
											Required
											IsInvalid={vModel.CurrencyId}
										/>
										{/* <GenericDateInput
											LabelMd={4}
											Name="TransactionDate"
											LabelText="Transaction Date"
											Value={Formatter.FormatDate(transfer.TransactionDate)}
											ValueChanged={this.HandleChange}
											IsFuture={true}
										/> */}
										{transfer.IsHaveMemo ?
											<GenericSelectInput
												key={"Memo_" + this.uniqueClientId}
												Name="TransactionDescription"
												LabelText="Transfer Memo"
												Method="POST"
												Url="/bankapi/v1.0/VisaDirect/GetMemosByClientId"
												Parameter={this.uniqueClientId}
												DataRoot="Item"
												KeyValueMember="Memo"
												TextValueMember="Memo"
												Value={transfer.TransactionDescription}
												ValueChanged={this.HandleChange}
												Required
												IsInvalid={vModel.TransactionDescription}
											/>
											:
											<GenericTextInput Name="TransactionDescription" LabelText="Transfer Memo" Value={transfer.TransactionDescription} ValueChanged={this.HandleChange} Required
												IsInvalid={vModel.TransactionDescription} />
										}
										<GridContainer>
											<GridItem xs={10} />
											<GridItem xs={2} >
												<GenericCheckInput Name="IsHaveMemo" LabelText="Get Memo From List" Value={transfer.IsHaveMemo} ValueChanged={this.HandleChange} />
											</GridItem>
										</GridContainer>

										<GenericNumberInput
											Name="Amount"
											LabelText="Amount"
											Value={transfer.Amount}
											Prefix="$"
											ValueChanged={this.HandleChange}
											Required
											IsInvalid={vModel.Amount}
										/>
										<ParameterComponent
											Name="TransactionTypeId"
											LabelText="TransactionType"
											ParameterCode="TransactionType"
											ParameterValue4="1"
											Value={transfer.TransactionTypeId}
											ValueChanged={this.HandleChange}
											RenderItem={this.RenderTransactionTypeSelect}
											Required
											IsInvalid={vModel.TransactionTypeId}
										/>
									</GridItem>
									<GridItem xs={1}></GridItem>
									<GridItem xs={5}>
										<GenericTitle text={"Transaction Response"} />
										<GenericTextInput
											Name="OrderId"
											LabelText="Order Number"
											Value={trxResponse?.OrderId }
											ValueChanged={this.HandleChange} />
										<GenericTextInput
											Name="TransactionStatus"
											LabelText="State"
											Value={trxResponse?.TransactionStatus }
											ValueChanged={this.HandleChange}
										/>
										<GenericDateInput
											Utc
											Name="TransactionDate"
											LabelText="Created At"
											IncludeTime={false}
											Value={Formatter.FormatDate(trxResponse?.TransactionDate)}
											ValueChanged={this.HandleChange}
										/>
										<GenericDateInput
											Utc
											Name="UpdateDateTime"
											LabelText="Updated At"
											IncludeTime={false}
											Value={Formatter.FormatDate(trxResponse?.UpdateDateTime)}
											ValueChanged={this.HandleChange}
										/>
										<GenericNumberInput
											Name="TransactionUniqueId"
											LabelText="Trace ID"
											NoFormatting
											Value={trxResponse?.TransactionUniqueId}
											ValueChanged={this.HandleChange}
										/>										
									</GridItem>
								</GenericExpansionPanel>
								<br />
								<br />
								<br />
							</CardBody>
						</Card>
						<GenericDialog open={isFileDialogOpen} maxWidth="md" fullWidth>
							<DialogTitle>
								<GenericLabel Text="Transaction Confirmation" Bold={true} />
							</DialogTitle>
							<DialogContent>
								<GenericSelectInput
									Name="CustomerNumber"
									LabelText="Customer Name"
									Method="POST"
									Url="/bankapi/v1.0/ClientCustomer/GetByProgramCodeAndClientId"
									DataRoot="Item"
									Parameter={{ ProgramCode: ProgramCodes.BANK_PROGRAMS_Card_Transfers, UniqueClientId: this.uniqueClientId, CardTransferType: this.cardTransferType }}
									KeyValueMember="CustomerNumber"
									TextValueMember="Name"
									Value={transfer.CustomerNumber}
									Disabled={true}
								/>
								<GenericSelectInput
									key={transfer.CustomerNumber}
									Name="CardId"
									LabelText="Card Number"
									Method="POST"
									Url="/bankapi/v1.0/VisaDirect/GetCustomerCard"
									DataRoot="Item"
									KeyValueMember="CardId"
									TextValueMember="CardEmbossName"
									Parameter={{ CustomerNumber: transfer.CustomerNumber }}
									Value={transfer.CardId}
									Disabled={true}

								/>
								<GenericSelectInput
									Name="CurrencyId"
									LabelText="Currency"
									Value={transfer.CurrencyId}
									KeyValueMember="Id"
									TextValueMember="Description"
									Method="GET"
									Url="/bankapi/v1.0/Currency/ValidCurrencies"
									RenderItem={this.RenderCurrencySelect}
									DataRoot="Item"
									Disabled={true}
								/>
								<GenericTextInput
									Name="TransactionDescription"
									LabelText="Transfer Memo"
									Value={transfer.TransactionDescription}
									Disabled={true}
								/>
								<GenericNumberInput
									Name="Amount"
									LabelText="Amount"
									Value={transfer.Amount}
									Prefix="$"
									Disabled={true}
								/>
								<ParameterComponent
									Name="TransactionTypeId"
									LabelText="TransactionType"
									ParameterCode="TransactionType"
									ParameterValue4="1"
									Value={transfer.TransactionTypeId}
									RenderItem={this.RenderTransactionTypeSelect}
									Disabled={true}
								/>
								<br />
								<GenericLabel Text="Are you sure you want to enter this transaction ?" Bold={true} />
							</DialogContent>
							<GenericDialogActions>
								<Button size="sm" onClick={() => this.HandleSubmit(this.state.Url)}>Confirm</Button>
								<Button size="sm" onClick={() => this.setState({ isFileDialogOpen: false })}>Cancel</Button>
							</GenericDialogActions>
						</GenericDialog>
					</GridItem>
				</GridContainer>
				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel
									Title="Create Card List">
									<GridContainer>
										<GridItem xs={12}>
											<GenericGrid
												key={"trxGrid" + this.state.trxGridToken}
												ServerSide
												RequestUrl="/bankapi/v1.0/VisaDirect/VisaDirectTransactionSearch"
												RequestMethod="POST"
												PageSize={10}
												ShowPagination={true}
												LoadAtStartup={this.state.loadingCompleted}
												Data={this.state.cardList}
												Columns={this.Columns}
												SelectedIndex={this.state.rowIndex}
												IsSorted={false}
											// RowSelected={index => {
											// 	this.SelectedCardRowChange(0, index);
											// }}
											/>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>


			</React.Fragment>

		);
	}
}

export default withArtifex(CreateCardTransfer, {});