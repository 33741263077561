import { withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import eftEtransferTitleBarStyle from "assets/jss/material-dashboard-pro-react/views/dynamicToolBarStyle.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React, { Component } from "react";

class DynamicToolbar extends Component {

	render() {
		const { classes, ItemList, IsPopUp } = this.props;

		return (
			<div className={IsPopUp ? "" : classes.paddingBottom}>
				<div className={IsPopUp ? classes.normal : classes.fixed}>
					<div className={classes.main}>
						<GridContainer justify="flex-start">
							{ItemList.length == 0 ? <div /> : ItemList.map((item, index) => (
								<GridItem key={index}>
									<GridContainer>
										<GridItem className={classes.appbaritemleft}>
											{item.Label}
										</GridItem>
										<GridItem >
											<Typography variant="h6" color="inherit" className={classes.appbaritemright} >
												{Formatter.FormatMoney(item.Value)}
											</Typography>
										</GridItem>
									</GridContainer>
								</GridItem>
							))}
						</GridContainer>
					</div>
				</div>

			</div>
		);
	}
}

DynamicToolbar.propTypes = {
	classes: PropTypes.object,
	ItemList: PropTypes.array.isRequired,
	IsPopUp: PropTypes.bool
};

export default withStyles(eftEtransferTitleBarStyle)(DynamicToolbar);