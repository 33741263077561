import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
class GenericCamera extends PureComponent {

	constructor(props) {
		super(props);
		this.stream = null;
		this.tempImg = null;
	}

	componentDidMount() {
		window.addEventListener("orientationchange", this.handleOrientationChange);
	}
	componentWillUnmount() {
		window.removeEventListener("orientationchange", this.handleOrientationChange);
	}

	handleOrientationChange = () => {
		this.props.Open && this.injectOverlay(0);
	}
	componentDidUpdate(prevProps, prevState) {
		const { Open } = this.props;

		if (prevProps.Open != Open && !Open) {
			this.closeStream();
		}
	}
	getVideoElement = () => {
		return document.getElementsByTagName("video")[0];
	}
	getVideoContainer = () => {
		return document.getElementsByClassName("react-html5-camera-photo")[0];
	}

	injectOverlay = async (frameCount = 4) => {
		if (!this.props.OverlayImage) {
			return;
		}
		var video = this.getVideoElement();
		this.progressCount = 0;

		var layerTrigger = (e) => {
			if (this.progressCount === frameCount) {
				video && video.removeEventListener("progress", layerTrigger);
				this.doInject();
			}
			this.progressCount += 1;
		};
		video && video.addEventListener("progress", layerTrigger, true);
	}
	doInject = () => {
		const { OverlayImage, OverlayRatio } = this.props;
		var imgRatio = OverlayRatio;
		var img = document.createElement("img");
		img.src = OverlayImage;

		var video = this.getVideoElement();
		var container = this.getVideoContainer();
		if(!video  ||!container){
			return;
		}
		var width = video.clientWidth * imgRatio;
		var margin = video.clientWidth * (1 - imgRatio) / 2;
		var height = video.clientHeight;
		img.style = `width:${width}px; position:absolute; background-position:center; margin:0px ${margin}px; opacity: 0;transition: opacity 0.5s ease-in-out;`;


		container.insertBefore(img, container.firstChild);
		var rearrange = () => {
			var marginTop = (height - img.clientHeight) / 2;
			img.style = img.getAttribute("style") + `;padding-top: ${marginTop}px; opacity: 1;`;
			if (this.tempImg) {
				this.tempImg.parentNode && this.tempImg.parentNode.removeChild(this.tempImg);
			}
			this.tempImg = img;

		};
		img.onload = () => {
			rearrange();
		};
	}
	closeStream = () => {
		this.stream && this.stream.getTracks().forEach(function (track) {
			track.stop();
		});
	}
	CustomOnCameraStart = (stream) => {
		this.stream = stream;
		const { OnCameraStart, ImageType } = this.props;
		if (typeof OnCameraStart === "function") {
			var track = stream.getTracks()[0];
			let cameraLabel = "Webcam.png";
			if (track && track.label) {
				cameraLabel = track.label.replace(/\s/g, "_").replace(/,/g, "-") + "." + ImageType;
			}
			OnCameraStart(stream, cameraLabel);
			this.injectOverlay();
		}

	}
	CustomOnTakePhoto = (dataUri) => {
		const { OnTakePhoto } = this.props;
		var base64 = dataUri.split(",").pop();

		if (OnTakePhoto) {
			OnTakePhoto(dataUri, base64);
		}

	}

	render() {
		const { IdealResolution, OnCameraStop,  IsImageMirror, OnCameraError, IdealFacingMode, ImageCompression, IsMaxResolution, IsSilentMode, IsDisplayStartCameraError, SizeFactor, ...rest } = this.props;
		var facingModes = IdealFacingMode == "back" ? FACING_MODES.ENVIRONMENT : FACING_MODES.USER;
		var imageType = IdealFacingMode == "jpg" ? IMAGE_TYPES.JPG : IMAGE_TYPES.PNG;
		return (
			<Camera
				onCameraStart={this.CustomOnCameraStart}
				onCameraStop={OnCameraStop}
				isImageMirror={IsImageMirror}
				onTakePhotoAnimationDone={this.CustomOnTakePhoto}
				onCameraError={OnCameraError}
				idealFacingMode={facingModes}
				imageCompression={ImageCompression}
				isMaxResolution={IsMaxResolution}
				isSilentMode={IsSilentMode}
				isDisplayStartCameraError={IsDisplayStartCameraError}
				sizeFactor={SizeFactor}
				imageType={imageType}
				idealResolution={IdealResolution}
				{...rest}
			/>
		);
	}
}
GenericCamera.defaultProps = {
	IsImageMirror: false,
	IdealFacingMode: "front",
	ImageType: "jpg",
	ImageCompression: 0.92,
	IsDisplayStartCameraError: false,
	IsMaxResolution: true,
	IsSilentMode: true,
	OnCameraError: PropTypes.func,
	OnTakePhoto: PropTypes.func,
	SizeFactor: 1,
	className: PropTypes.any,
	OverlayRatio: 0.95
};

GenericCamera.propTypes = {
	IdealResolution: PropTypes.shape({
		width: PropTypes.number,
		height: PropTypes.number
	}),
	OverlayImage: PropTypes.any,
	OverlayRatio: PropTypes.number,
	OnCameraStart: PropTypes.func,
	Open: PropTypes.bool,
	OnCameraStop: PropTypes.func,
	IsImageMirror: PropTypes.bool,
	IdealFacingMode: PropTypes.oneOf(["front", "back"]),
	ImageType: PropTypes.oneOf(["jpg", "png"]),
	ImageCompression: PropTypes.any,
	IsDisplayStartCameraError: PropTypes.any,
	IsMaxResolution: PropTypes.any,
	IsSilentMode: PropTypes.any,
	OnCameraError: PropTypes.any,
	OnTakePhoto: PropTypes.any,
	SizeFactor: PropTypes.any,
	className: PropTypes.any
};

export default GenericCamera;