import { Proxy, withArtifex } from "core";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { ProgramCodes, MenuCodes } from "views/Constants/Constant.js";
import CustomerDefinition from "views/Customer/CustomerDefinition";

class PpCustomerDefinition extends Component {
	constructor(props) {
		super(props);

		this.state = {
			menuId: 0
		}
	}

	componentDidMount() {
		if (this.props.setAppLeftTitle) this.props.setAppLeftTitle("Create Customer");
		if (this.props.setAppCenterTitle) this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
		this.GetCreateCustomerMenuId();
	}

	GetCreateCustomerMenuId = () => {
		this.setState({ isLoading: true });
		Proxy.POST("/coreapi/v1.0/Menu/GetByMenuCode",
			MenuCodes.CustomerDefinition,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ menuId: responseData.Item.Id });
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	render() {
		const { menuId } = this.state;
		return (
			<CustomerDefinition
				key={menuId}
				hideSidebar={this.props.hideSidebar}
				setAppLeftTitle={this.props.setAppLeftTitle}
				setAppCenterTitle={this.props.setAppCenterTitle}
				CustomerSaved={this.props.CustomerSaved}
				Programs={["WALLET"]}
				menuId={menuId}
				ForInsert={true}
				ApprovalData={this.props.ApprovalData}
				After={this.props.After}
				Disabled={this.props.Disabled} />
		);
	}
}

PpCustomerDefinition.propTypes = {
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool,
	ApprovalData: PropTypes.any,
};

export default withArtifex(PpCustomerDefinition, {});