import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import Formatter from "core/Formatter";
import RouteHelper from "core/RouteHelper";
import PropTypes from "prop-types";
import { default as React } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDialog, GenericDialogActions, GenericEmailInput, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericLabel, GenericCheckInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { InteracRedirectURL } from "views/Constants/Constant";


class InteracApproveRequest extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			model: {},
			alert: null,
			isLoading: false,
			interacData: {},
			IncomingResponse: {},
			authTransferStatus: false,
			isConfirmDialogOpen: false,
			isDeclineDialogOpen: false,
			isManualTransactionEntry: false
		};

		this.EmptyParameter = {};
		this.maxLength20 = { maxLength: 20 };
		this.maxLength100 = { maxLength: 100 };
	}

	componentDidMount() {
		if (this.props.setAppCenterTitle) {
			this.props.setAppCenterTitle("NOTIFICATIONS");
		}

		if (this.props.setAppLeftTitle)
			this.props.setAppLeftTitle("Money Deposit");



		if (this.CheckIncomingData()) {
			this.ParseIncomingData();
		}
	}

	CheckIncomingData = () => {
		var interacData = RouteHelper.Read(InteracRedirectURL.LocalStorageKey);
		if (interacData == null) {
			return false;
		}
		if (interacData == null || interacData.IsValidUrl != true) {
			return false;
		}

		if (interacData.IsMoneySend == true) {
			if (interacData.ETID == null || interacData.ETID == "") {
				return false;
			}
		}
		if (interacData.IsMoneyRequest == true) {
			if (interacData.RQETID == null || interacData.RQETID == "") {
				return false;
			}
		}

		return true;
	}

	GetManualTransactionDetails = () => {
		if (this.CheckIncomingData()) {
			this.props.showMessage("error", "Error", "Operation cannot be performed.");
			return false;
		}

		var model = { ...this.state.model };
		if (model.isManualTransactionEntry != true) {
			this.props.showMessage("error", "Error", "Operation cannot be performed.");
			return false;
		}

		if (model.ManualReferanceNumber == null || model.ManualReferanceNumber == "") {
			this.props.showMessage("error", "Error", "Operation cannot be performed. Please fill reference number");
			return false;
		}

		if (model.ManualTransactionType == null || model.ManualTransactionType == "") {
			this.props.showMessage("error", "Error", "Operation cannot be performed. Please fill reference number");
			return false;
		}


		var temp = {};
		temp.IsValidUrl = true;
		temp.IsMoneySend = false;
		temp.IsMoneyRequest = false;
		temp.ETID = null;
		temp.RQETID = null;

		if (model.ManualTransactionType == "MoneyRequest") {
			temp.IsMoneyRequest = true;
			temp.RQETID = model.ManualReferanceNumber;
		}
		if (model.ManualTransactionType == "MoneySend") {
			temp.IsMoneySend = true;
			temp.ETID = model.ManualReferanceNumber;
		}

		this.ParseIncomingData(temp);
	}

	ParseIncomingData = (data) => {
		var interacData = RouteHelper.ReadAndClear(InteracRedirectURL.LocalStorageKey);
		if (interacData != null) {
			this.setState({ interacData: interacData || {} });
		}

		if (data != null) {
			this.setState({ interacData: data });
			interacData = data;
		}

		if (interacData == null || interacData.IsValidUrl != true) {
			this.props.showMessage("error", "Error", "Invalid Approval Request URL. Approve Operation can not be done. ");
			return false;
		}

		if (interacData.IsMoneySend == true) {
			if (interacData.ETID == null || interacData.ETID == "") {
				this.props.showMessage("error", "Error", "Missing Interac data for incoming money send. Approve Operation can not be done. ");
				return false;
			}
		}
		if (interacData.IsMoneyRequest == true) {
			if (interacData.RQETID == null || interacData.RQETID == "") {
				this.props.showMessage("error", "Error", "Missing Interac data for incoming money request. Approve Operation can not be done. ");
				return false;
			}
		}

		this.GetInteracDefaultEmail();
	}

	GetInteracDefaultEmail = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/InteracApprovePayment/GetInteracDefaultEmail",
			this.state.model,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.handleChange("Email", responseData.Item || "");
				this.FetchRequest(responseData.Item);
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	FetchRequest = (email) => {
		var interacData = { ...this.state.interacData };

		var data = {};
		if (email == null || email == "") {
			data.Email = email;
		} else {
			data.Email = this.state.model?.Email;
		}

		if (data.Email == "" || data.Email == null || !this.ValidateEmail(data.Email)) {
			this.props.showMessage("error", "Error", "Email must be valid");
			return;
		}

		data.ReferenceNumber = this.GetReferenceNumber();
		if (data.ReferenceNumber == null || data.ReferenceNumber == "") {
			this.props.showMessage("error", "Error", "Referance number must be valid");
			return;
		}
		if (interacData.IsValidUrl && interacData.IsMoneySend && interacData.ETID != null && interacData.ETID != "") {
			// money send
			this.GetIncomingTransfer(data);
		} else if (interacData.IsValidUrl && interacData.IsMoneyRequest && interacData.RQETID != null && interacData.RQETID != "") {
			//money request
			this.GetIncomingMoneyRequests(data);
		} else {
			// invalid
			this.props.showMessage("error", "Error", "Invalid Approval Request");
			return;
		}
	}



	OperationCheck = () => {

		return true;
	}

	GetIncomingTransfer = (data) => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/InteracApprovePayment/GetIncomingTransfers",
			data,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.props.showMessage("success", "Success", "Get Incoming Transfer Success");
				this.setState({ isLoading: false });
				this.setState({ IncomingResponse: responseData.Item || {} });

				console.log("GetIncomingMoneyRequests Response " + responseData.IsSucceeded);
				console.log(JSON.stringify(responseData.Item));
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}
	AuthenticateTransfer = () => {
		var data = {};
		const model = { ...this.state.model };
		const interacModel = { ...this.state.interacData };
		data.Email = model?.Email;
		data.ReferenceNumber = this.GetReferenceNumber();
		data.SecurityAnswer = model?.SecurityAnswer;
		data.HashSalt = this.state.IncomingResponse?.incomingTransfer?.hashSalt;

		var ErrorList = [];

		if (data.Email == null || data.Email == "") {
			ErrorList.push("Email must be defined !.");
		}
		if (data.ReferenceNumber == null || data.ReferenceNumber == "") {
			ErrorList.push("Referance Number must be defined !.");
		}
		if (data.SecurityAnswer == null || data.SecurityAnswer == "") {
			ErrorList.push("Security Answer must be defined !.");
		}
		if (data.HashSalt == null || data.HashSalt == "") {
			ErrorList.push("Hash Salt must be defined !.");
		}
		if (!this.ValidateEmail(data.Email)) {
			ErrorList.push("Email must be valid");
		}

		if (ErrorList.length > 0) {
			this.props.showMessageNode("warning", "Please fill required fields!", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/InteracApprovePayment/AuthenticateTransfer",
			data,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.props.showMessage("success", "Success", "Authenticate Transfer Success");
				this.setState({ isLoading: false });
				this.setState({ authTransferStatus: responseData.IsSucceeded });

				console.log("AuthenticateTransfer Response " + responseData.IsSucceeded);
				console.log(JSON.stringify(responseData.Item));
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}
	GetIncomingMoneyRequests = (data) => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/InteracApprovePayment/GetIncomingMoneyRequests",
			data,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.props.showMessage("success", "Success", "Get Incoming Money Requests Success");
				this.setState({ isLoading: false });
				this.setState({ IncomingResponse: responseData.Item || {} });

				console.log("GetIncomingMoneyRequests Response " + responseData.IsSucceeded);
				console.log(JSON.stringify(responseData.Item));
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}
	CompleteTransfer = () => {
		const model = { ...this.state.model };
		var data = {};

		data.Email = model.Email;
		data.AccountId = model.AccountId;
		data.GetIncomingTransferResponse = this.state.IncomingResponse;

		if (data.GetIncomingTransferResponse == null) {
			this.props.showMessage("error", "Error", "Invalid Decline Transfer Response");
			return;
		}
		if (data.AccountId == null || data.AccountId == "") {
			this.props.showMessage("error", "Error", "Please select your Wallet");
			return;
		}
		if (data.Email == "" || data.Email == null || !this.ValidateEmail(data.Email)) {
			this.props.showMessage("error", "Error", "Email must be valid");
			return;
		}

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/InteracApprovePayment/CompleteTransfer",
			data,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.props.showMessage("success", "Success", "Complete Transfer Success");
				this.setState({ isLoading: false });
				this.ClearData();

				console.log("CompleteTransfer Response " + responseData.IsSucceeded);
				console.log(JSON.stringify(responseData.Item));
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}
	DeclineTransfer = () => {
		const model = { ...this.state.model };
		var data = {};
		data.DeclineReason = model.DeclineReason;
		data.Email = model.Email;

		data.ReferenceNumber = this.GetReferenceNumber();
		if (data.ReferenceNumber == null || data.ReferenceNumber == "") {
			this.props.showMessage("error", "Error", "Invalid Decline Referance Number");
			return;
		}
		if (data.DeclineReason == null || data.DeclineReason == "") {
			this.props.showMessage("error", "Error", "Please type your decline reason");
			return;
		}

		if (data.Email == null || data.Email == "") {
			this.props.showMessage("Email must be defined !.");
			return false;
		}

		if (!this.ValidateEmail(data.Email)) {
			this.props.showMessage("Email must be valid");
			return false;
		}


		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/InteracApprovePayment/DeclineTransfer",
			data,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.props.showMessage("success", "Success", "Decline Transfer Success");
				this.setState({ isLoading: false });
				this.ClearData();

				console.log("DeclineTransfer Response " + responseData.IsSucceeded);
				console.log(JSON.stringify(responseData.Item));
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);

	}

	DeclineMoneyRequest = () => {
		const model = { ...this.state.model };
		var data = {};
		data.DeclineReason = model.DeclineReason;
		data.Email = model.Email;

		data.ReferenceNumber = this.GetReferenceNumber();
		if (data.ReferenceNumber == null || data.ReferenceNumber == "") {
			this.props.showMessage("error", "Error", "Invalid Decline Referance Number");
			return;
		}
		if (data.DeclineReason == null || data.DeclineReason == "") {
			this.props.showMessage("error", "Error", "Please type your decline reason");
			return;
		}

		if (data.Email == null || data.Email == "") {
			this.props.showMessage("Email must be defined !.");
			return false;
		}

		if (!this.ValidateEmail(data.Email)) {
			this.props.showMessage("Email must be valid");
			return false;
		}


		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/InteracApprovePayment/DeclineRequest",
			data,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.props.showMessage("success", "Success", "Decline Request Success");
				this.setState({ isLoading: false });
				this.ClearData();

				console.log("DeclineRequest Response " + responseData.IsSucceeded);
				console.log(JSON.stringify(responseData.Item));
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);

	}

	CompleteMoneyRequest = () => {
		const model = { ...this.state.model };
		var data = {};

		data.Email = model.Email;
		data.AccountId = model.AccountId;
		data.IncomingRequest = this.state.IncomingResponse;
		data.ManualReferanceNumber = model.ManualReferanceNumber;
		if (data.IncomingRequest == null) {
			this.props.showMessage("error", "Error", "Invalid Incoming Money Request Response");
			return;
		}
		if (data.AccountId == null || data.AccountId == "") {
			this.props.showMessage("error", "Error", "Please select your Wallet");
			return;
		}
		if (data.Email == "" || data.Email == null || !this.ValidateEmail(data.Email)) {
			this.props.showMessage("error", "Error", "Email must be valid");
			return;
		}


		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/InteracApprovePayment/CompleteMoneyRequest",
			data,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.props.showMessage("success", "Success", "Complete Money Request Success");
				this.setState({ isLoading: false });
				this.ClearData();

				console.log("CompleteMoneyRequest Response " + responseData.IsSucceeded);
				console.log(JSON.stringify(responseData.Item));
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}


	GetReferenceNumber = () => {
		var interacData = { ...this.state.interacData };
		if (interacData.IsValidUrl && interacData.IsMoneySend && interacData.ETID != null && interacData.ETID != "") {
			return interacData.ETID
		} else if (interacData.IsValidUrl && interacData.IsMoneyRequest && interacData.RQETID != null && interacData.RQETID != "") {
			return interacData.RQETID
		} else {
			return null;
		}
	}
	handleChange = (name, newValue, data) => {
		this.setState(function (state, props) {
			var temp = state.model || {};
			temp[name] = newValue;
			return { model: temp };
		});
	}
	RenderClientAccountSelect = (d) => {
		return `${d.AccountName} -${d.AccountNumber} (${d.AccountType})`;
	}
	ValidateEmail = (email) => {
		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}
	GetRequestStatus = (code) => {
		switch (code) {
			case 1:
				return "Initiated";
			case 2:
				return "Available";
			case 3:
				return "Fulfilled";
			case 4:
				return "Declined";
			case 5:
				return "Cancelled";
			case 6:
				return "Expired";
			case 7:
				return "Deposit Failed";
			case 8:
				return "Deposit Complete";
			default:
				return "-";
		}
	}
	GetTransferStatus = (code) => {
		switch (code) {
			case 1:
				return "Transfer Initiated";
			case 3:
				return "Transfer Available";
			case 5:
				return "Authentication Failure";
			case 6:
				return "Authentication Successfull";
			case 7:
				return "Declined";
			case 8:
				return "Cancelled";
			case 10:
				return "Completed";
			default:
				return "-";
		}
	}
	FillApproveGridData = (interacData, IncomingResponse,model) => {
		return (
			<>
				{(interacData.IsMoneySend == true) && (
					<GridItem xs={6}>
						<GenericTextInput LabelMd={6} Disabled={true} LabelText=" Transfer Reference Number" Value={IncomingResponse?.incomingTransfer?.transferReferenceNumber} />
						<GenericTextInput LabelMd={6} Disabled={true} LabelText="Currency Code" Value={IncomingResponse?.incomingTransfer?.currencyCode} />
						<GenericNumberInput LabelMd={6} Disabled={true} LabelText="Amount" Value={IncomingResponse?.incomingTransfer?.amount} Prefix="$" />
						<GenericTextInput LabelMd={6} Disabled={true} LabelText="Expiry Date" Value={IncomingResponse?.incomingTransfer?.expiryDate} />
						<GenericTextInput LabelMd={6} Disabled={true} LabelText="Sender Memo" Value={IncomingResponse?.incomingTransfer?.senderMemo} />
						<GenericTextInput LabelMd={6} Disabled={true} LabelText="Sender Registration Name" Value={IncomingResponse?.incomingTransfer?.senderRegistrationName} />
					</GridItem>
				)}
				{(interacData.IsMoneyRequest == true) && (
					<GridItem xs={6}>
						<GenericTextInput LabelMd={6} Disabled={true} LabelText="Registration Name" Value={IncomingResponse?.incomingRequest?.customerName?.registrationName} />
						<GenericTextInput LabelMd={6} Disabled={true} LabelText="Currency Code" Value={IncomingResponse?.incomingRequest?.currencyCode} />
						<GenericTextInput LabelMd={6} Disabled={true} LabelText="Money Request Amount" Value={IncomingResponse?.incomingRequest?.moneyRequestAmount} />
						<GenericTextInput LabelMd={6} Disabled={true} LabelText="Money Request Expiry Date" Value={IncomingResponse?.incomingRequest?.moneyRequestExpiryDate} />
						<GenericTextInput LabelMd={6} Disabled={true} LabelText="Sender Memo" Value={IncomingResponse?.incomingRequest?.senderMemo} />
						<GenericTextInput LabelMd={6} Disabled={true} LabelText="Request Reference Number" Value={IncomingResponse?.incomingRequest?.requestReferenceNumber} />
						<GenericTextInput LabelMd={6} Disabled={true} LabelText="Reference Number" Value={model.ManualReferanceNumber} />
					</GridItem>
				)}
			</>
		);
	}
	ClearData = () => {
		this.setState({
			model: {},
			alert: null,
			isLoading: false,
			interacData: {},
			IncomingResponse: {},
			authTransferStatus: false,
			isConfirmDialogOpen: false,
			isDeclineDialogOpen: false,
			isManualTransactionEntry: false
		});
	}
	render() {
		const { Disabled } = this.props;
		const { isLoading, model, IncomingResponse, interacData, authTransferStatus } = this.state;
		return (
			<div>
				{this.state.alert}

				<LoadingComponent Show={isLoading} />

				<ButtonToolbar ButtonList={[
					{ Code: "Submit", OnClick: this.OperationCheck, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.ClearData, Disabled: Disabled },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />


				<Card className="no-radius">
					<CardBody>
						<b><p style={{ paddingTop: "20px", marginLeft: "10px" }}> Complete e-Transfer </p></b>
						<GridContainer justify="flex-start">
							<GridItem xs={3}>
								<GenericSelectInput
									Disabled={Disabled}
									Name="AccountId"
									LabelText="Account"
									Value={model.AccountId}
									DataRoot="Item"
									ValueChanged={this.handleChange}
									KeyValueMember="Id"
									TextValueMember="Name"
									Url="/bankapi/v1.0/InteracApprovePayment/GetClientAccounts"
									Method="POST"
									Parameter={this.EmptyParameter}
									RenderItem={this.RenderClientAccountSelect}
								/>
							</GridItem>
							<GridItem xs={3}>
								<GenericEmailInput
									Name="Email"
									LabelText="Interac E-Mail"
									Value={model.Email}
									ValueChanged={this.handleChange}
									Disabled={true}
									inputProps={this.maxLength100} />
							</GridItem>
							<GridItem xs={3}>
								{interacData?.IsValidUrl != true && (
									<GenericCheckInput
										Disabled={Disabled || interacData?.IsValidUrl}
										Name="isManualTransactionEntry"
										LabelText="Enter Referance Number Manually"
										Value={model.isManualTransactionEntry || false}
										ValueChanged={this.handleChange}
									/>
								)}
							</GridItem>
						</GridContainer>
						<GridContainer>
							{model.isManualTransactionEntry == true && (
								<>
									<GridItem xs={3}>
										<GenericSelectInput
											Disabled={Disabled || !model.isManualTransactionEntry}
											IsStatic
											Name="ManualTransactionType"
											LabelText="Money Send/Request"
											StaticData={[{ Id: "MoneyRequest", Name: "Money Request" }, { Id: "MoneySend", Name: "Money Send" }]}
											KeyValueMember="Id"
											TextValueMember="Name"
											Value={model.ManualTransactionType}
											ValueChanged={this.handleChange}
										/>
									</GridItem>
									<GridItem xs={3}>
										<GenericTextInput
											inputProps={this.maxLength20}
											Disabled={Disabled || !model.isManualTransactionEntry}
											Name="ManualReferanceNumber"
											LabelText="Referance Number"
											Value={model.ManualReferanceNumber || ""}
											ValueChanged={this.handleChange}
										/>
									</GridItem>
									<Button disabled={Disabled || !model.isManualTransactionEntry} size="sm" color="black" onClick={() => this.GetManualTransactionDetails()}>
										{model?.ManualTransactionType == "MoneyRequest" ? "Get Money Request" : model?.ManualTransactionType == "MoneySend" ? "Get Money Transfer" : "Get"}
									</Button>
								</>
							)}
						</GridContainer>

						{(interacData?.IsMoneySend == true) && (
							<GridContainer>
								{(IncomingResponse?.incomingTransfer?.securityQuestion != null && IncomingResponse?.incomingTransfer?.securityQuestion != "") && (
									<>
										<GridItem xs={3}>
											<GenericTextInput
												Disabled={true}
												LabelText=" Security Question"
												Value={IncomingResponse?.incomingTransfer?.securityQuestion}
												ValueChanged={this.handleChange}
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericTextInput
												Disabled={Disabled}
												Name="SecurityAnswer"
												LabelText=" Security Question Answer"
												Value={model.SecurityAnswer}
												ValueChanged={this.handleChange}
											/>
										</GridItem>
									</>
								)}
								{(IncomingResponse?.incomingTransfer?.transferStatus == 3 || IncomingResponse?.incomingTransfer?.authenticationRequired == 1) && (
									<GridItem xs={2}>
										<Button disabled={Disabled || (IncomingResponse?.incomingTransfer?.transferStatus == 6 || authTransferStatus)} size="sm" color="black" onClick={() => this.AuthenticateTransfer()}>
											Authenticate Transfer
										</Button>
									</GridItem>
								)}
								{(IncomingResponse?.incomingTransfer?.authenticationRequired == 0 || IncomingResponse?.incomingTransfer?.transferStatus == 6 || authTransferStatus) && (
									<>
										<GridItem xs={2}>
											<Button disabled={Disabled} size="sm" color="black" onClick={() => { this.setState({ isDeclineDialogOpen: true }); }}>
												Decline Transfer
											</Button>
										</GridItem>
										<GridItem xs={2}>
											<Button disabled={Disabled} size="sm" color="black" onClick={() => { this.setState({ isConfirmDialogOpen: true }); }}>
												Complete Transfer
											</Button>
										</GridItem>
									</>
								)}
							</GridContainer>
						)}


						{(interacData?.IsMoneyRequest == true && IncomingResponse?.incomingRequest?.moneyRequestStatus == 2) && (
							<GridContainer>
								<GridItem xs={2}>
									<Button disabled={Disabled} size="sm" color="black" onClick={() => { this.setState({ isDeclineDialogOpen: true }); }}>
										Decline Request
									</Button>
								</GridItem>
								<GridItem xs={2}>
									<Button disabled={Disabled} size="sm" color="black" onClick={() => { this.setState({ isConfirmDialogOpen: true }); }}>
										Complete Request
									</Button>
								</GridItem>
							</GridContainer>
						)}


						<GridContainer>
							<GridItem>
								<b><p style={{ paddingTop: "30px" }}>
									Url Valid : {interacData?.IsValidUrl == true ? "OK" : "-"}  <br />
									Operation Type : {interacData?.IsMoneySend == true ? "Incoming Transfer" : interacData?.IsMoneyRequest == true ? "Incoming Request" : "-"}   <br />
									Reference Number : {interacData?.IsMoneySend == true ? interacData?.ETID : interacData?.IsMoneyRequest == true ? interacData?.RQETID : "-"} <br />
									Status :   {interacData?.IsMoneySend == true ? this.GetTransferStatus(IncomingResponse?.incomingTransfer?.transferStatus) : interacData?.IsMoneyRequest == true ? this.GetRequestStatus(IncomingResponse?.incomingRequest?.moneyRequestStatus) : "-"}  <br />
								</p></b>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>


				{(interacData.IsMoneySend == true) && (
					<Card className="no-radius">
						<CardBody>
							<b><p style={{ paddingTop: "20px", marginLeft: "10px" }}> e-Transfer Details Incoming Transfer </p></b>
							<GridContainer justify="flex-start">
								<GridItem xs={4}>
									<GenericTextInput
										Disabled={true}
										LabelText=" Transfer Reference Number"
										Value={IncomingResponse?.incomingTransfer?.transferReferenceNumber}
										ValueChanged={this.handleChange}
									/>
									<GenericTextInput
										Disabled={true}
										LabelText="Currency Code"
										Value={IncomingResponse?.incomingTransfer?.currencyCode}
										ValueChanged={this.handleChange}
									/>
									<GenericNumberInput
										LabelText="Amount"
										Value={IncomingResponse?.incomingTransfer?.amount}
										Prefix="$"
										Disabled={true} />
								</GridItem>
								<GridItem xs={4}>
									<GenericTextInput
										Disabled={true}
										LabelText="Expiry Date"
										Value={IncomingResponse?.incomingTransfer?.expiryDate}
										ValueChanged={this.handleChange}
									/>
									<GenericTextInput
										Disabled={true}
										LabelText="Sender Memo"
										Value={IncomingResponse?.incomingTransfer?.senderMemo}
										ValueChanged={this.handleChange}
									/>
									<GenericTextInput
										Disabled={true}
										LabelText="Sender Registration Name"
										Value={IncomingResponse?.incomingTransfer?.senderRegistrationName}
										ValueChanged={this.handleChange}
									/>
								</GridItem>
								<GridItem xs={4}>
									<GenericTextInput
										Disabled={true}
										LabelText="Participant ID"
										Value={IncomingResponse?.incomingTransfer?.participantId}
										ValueChanged={this.handleChange}
									/>
									<GenericTextInput
										Disabled={true}
										LabelText="Security Question"
										Value={IncomingResponse?.incomingTransfer?.securityQuestion}
										ValueChanged={this.handleChange}
									/>
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
				)}


				{(interacData.IsMoneyRequest == true) && (
					<Card className="no-radius">
						<CardBody>
							<b><p style={{ paddingTop: "20px", marginLeft: "10px" }}> e-Transfer Details Incoming Request </p></b>
							<GridContainer justify="flex-start">
								<GridItem xs={4}>
									<GenericTextInput
										Disabled={true}
										LabelText="Money Request Amount"
										Value={IncomingResponse?.incomingRequest?.moneyRequestAmount}
										ValueChanged={this.handleChange}
									/>
									<GenericTextInput
										Disabled={true}
										LabelText="Money Request Expiry Date"
										Value={IncomingResponse?.incomingRequest?.moneyRequestExpiryDate}
										ValueChanged={this.handleChange}
									/>
									<GenericTextInput
										Disabled={true}
										LabelText="Registration Name"
										Value={IncomingResponse?.incomingRequest?.customerName?.registrationName}
										ValueChanged={this.handleChange}
									/>
								</GridItem>
								<GridItem xs={4}>
									<GenericTextInput
										Disabled={true}
										LabelText="Currency Code"
										Value={IncomingResponse?.incomingRequest?.currencyCode}
										ValueChanged={this.handleChange}
									/>
									<GenericTextInput
										Disabled={true}
										LabelText="Sender Memo"
										Value={IncomingResponse?.incomingRequest?.senderMemo}
										ValueChanged={this.handleChange}
									/>
								</GridItem>
								<GridItem xs={4}>
									<GenericTextInput
										Disabled={true}
										LabelText="Participant ID"
										Value={IncomingResponse?.incomingRequest?.participantId}
										ValueChanged={this.handleChange}
									/>
									<GenericTextInput
										Disabled={true}
										LabelText="Request Reference Number"
										Value={IncomingResponse?.incomingRequest?.requestReferenceNumber}
										ValueChanged={this.handleChange}
									/>
									<GenericTextInput
										Disabled={true}
										inputProps={this.maxLength20}
										Name="ManualReferanceNumber"
										LabelText="Referance Number"
										Value={model.ManualReferanceNumber || ""}
										ValueChanged={this.handleChange}
									/>
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
				)}

				<GenericDialog open={this.state.isConfirmDialogOpen} maxWidth="lg">
					<DialogTitle> Transaction Confirmation </DialogTitle>
					<DialogContent>
						{this.FillApproveGridData(interacData, IncomingResponse,model)}
						<GenericLabel Text="Are you sure you want to approve this transaction ?" Bold={true} />
					</DialogContent>
					<GenericDialogActions>
						{(interacData.IsMoneySend == true) && (
							<Button size="sm" onClick={() => this.CompleteTransfer()}>
								Complete Transfer
							</Button>
						)}
						{(interacData.IsMoneyRequest == true) && (
							<Button size="sm" onClick={() => this.CompleteMoneyRequest()}>
								Complete Request
							</Button>
						)}
						<Button size="sm" onClick={() => this.setState({ isConfirmDialogOpen: false })}>
							Cancel
						</Button>
					</GenericDialogActions>
				</GenericDialog>


				<GenericDialog open={this.state.isDeclineDialogOpen} maxWidth="lg">
					<DialogTitle> Transaction Confirmation </DialogTitle>
					<DialogContent>
						{this.FillApproveGridData(interacData, IncomingResponse,model)}
						<GenericLabel Text="Please type your decline reason ?" Bold={true} />
						<GenericTextInput ValueChanged={this.handleChange} inputProps={{ maxLength: 200 }} Name="DeclineReason" LabelText="Decline Reason" Value={model.DeclineReason || ""} />
						<GenericLabel Text="Are you sure you want to decline this transaction ?" Bold={true} />
					</DialogContent>
					<GenericDialogActions>
						{(interacData.IsMoneySend == true) && (
							<Button size="sm" onClick={() => this.DeclineTransfer()}>
								Decline Transfer
							</Button>
						)}
						{(interacData.IsMoneyRequest == true) && (
							<Button size="sm" onClick={() => this.DeclineMoneyRequest()}>
								Decline Request
							</Button>
						)}
						<Button size="sm" onClick={() => this.setState({ isDeclineDialogOpen: false })}>
							Cancel
						</Button>
					</GenericDialogActions>
				</GenericDialog>


			</div>
		);
	}
}

InteracApproveRequest.propTypes = {
	classes: PropTypes.object
};

export default withArtifex(InteracApproveRequest, {});