import { DialogContent } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import { DetailIcon,TraceIcon } from "core/Icons";
import MenuAuthorityRightHelper from "core/MenuAuthorityRightHelper";
import PropTypes, { bool, func } from "prop-types";
import React from "react";
import AccountLimitToolbar from "views/Components/AccountLimitToolbar";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericPage, GenericSelectInput, GenericTextInput, GenericTitle, GenericLabel, GenericRadioInput, GenericAlert } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { GridColumnType, MenuCodes, ProgramCodes } from "views/Constants/Constant";
import BeneficiaryDetail from "views/WireTransfer/CreateWireTransfer/BeneficiaryDetail";
import MessageDetail from "views/WireTransfer/CreateWireTransfer/MessageDetail";
import { DialogTitle } from "@material-ui/core";
import Select from "react-select";
import { Proxy } from "core";
import Formatter from "core/Formatter";
import TransactionTraceHelper from "core/TransactionTraceHelper";

class TransactionSearchWireTransfer extends GenericPage {

	constructor(props) {
		super(props);

		this.IsBackOffice = ClientHelper.IsBackOffice();
		this.columns = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						{row.original.Direction == "Outgoing" &&
							<GridButton
								Disabled={this.DetailButtonDisable}
								Icon={DetailIcon}
								tooltip="Outgoing Detail"
								OnClick={() => this.HandleOpenDetail(row.original, 0, false)}
							/>
						}
						{row.original.Direction == "Incoming" &&
							<GridButton
								Disabled={this.DetailButtonDisable}
								Icon={DetailIcon}
								tooltip="Incoming Detail"
								OnClick={() => this.HandleOpenIncomingDetail(row.original.MessageId, row.original.WireTransferId, false)}
							/>
						}
						{
							<GridButton
								tooltip="Trace"
								//Disabled={Disabled || !this.isAvailableForTraceTransaction(row.original) //|| !gridButtonRightDetail.find(x => x.Code == "EFTOUTTRACE")?.HasAuthority
								//}
								Icon={TraceIcon}
								OnClick={this.GetHandleTrace(row.original)} />
						}

					</div>
				)
			},
			{
				Header: "Unique Client Id",
				accessor: "UniqueClientId",
			},
			{
				Header: "Client Name",
				accessor: "ClientName",
			},
			{
				Header: "SenderName",
				accessor: "SenderName",
			},
			{
				Header: "Transaction Reference Number",
				accessor: "TransactionReferenceNumber",
			},
			{
				Header: "Swift Reference Number",
				accessor: "SwiftReferenceNumber",
			},
			{
				Header: "Client User",
				accessor: "InsertUser",
			},
			{
				Header: "Source Wallet Number",
				accessor: "FromAccAccountNumber"
			},
			{
				Header: "To Wallet Number",
				accessor: "ToAccountNumber"
			},
			{
				Header: "To IBAN",
				accessor: "ToIBAN"
			},
			{
				Header: "Direction",
				accessor: "Direction"
			},
			{
				Header: "Status",
				accessor: "Status"
			},
			{
				Header: "SubStatus",
				accessor: "SubStatus"
			},
			{
				Header: "Is Captured",
				accessor: "IsCaptured",
				type: GridColumnType.YesNo,
				ColumnType: GridColumnType.YesNo
			},
			{
				Header: "Reject Description",
				accessor: "PurposeOfClientReject"
			},
			{
				Header: "MessageType",
				accessor: "MessageType"
			},
			{
				Header: "Date of Request",
				accessor: "DateRequest"
			},
			// {
			// 	Header: "Value Effective",
			// 	accessor: "ValueEffective"
			// },
			{
				Header: "Amount",
				accessor: "Amount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			// {
			// 	Header: "Fee Amount",
			// 	accessor: "FeeAmount",
			// 	type: GridColumnType.Money,
			// 	ColumnType: GridColumnType.Money
			// },
			{
				Header: "Currency",
				accessor: "CurrencyName"
			},
			{
				Header: "Remittance",
				accessor: "Remittance"
			},
			{
				Header: "Purpose Of Wire",
				accessor: "PurposeOfWire"
			},
			{
				Header: "Beneficiary Company Name",
				accessor: "CompanyName"
			},
			{
				Header: "Country",
				accessor: "CountryFormat"
			},
			{
				Header: "InsertUserId",
				accessor: "InsertUserId"
			},
			{
				Header: "Update User",
				accessor: "UpdateUser"
			},
			{
				Header: "Update User Id",
				accessor: "UpdateUserId"
			}

		];
		this.DetailButtonDisable = true;
		this.MenuAuthorityRightHelper = new MenuAuthorityRightHelper();

		this.defaultModel = {
			ClientId: null,
			FromDate: DateHelper.GetDate(),
			ToDate: DateHelper.GetDate(),
			MessageTypeId: null,
			SubStatusId: null,
			StatusId: null,
			AmountFrom: null,
			AmountTo: null,
			StatusParamCode: null,
			SwiftReferenceNumber: null,
			TransactionReferenceNumber: null,
			jsonFile: null,
			Description: null

		};

		this.state = {
			model: { UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined, ...this.defaultModel, IsBackOffice: false },
			subStatusList: [],
			tempSubStatusList: [],
			transactionList: [],
			isLoading: false,
			isBackOffice: false,
			templateDetailModel: {},
			IsDomestic: {},
			detailDialog: false,
			incomingdetailDialog: false,
			messageDetailModel: {},
			approvalTransactionList: [],
			approvalDialog: false,
			matchDialog: false,
			requestModel: { IsPending: false, Description: null },
			DenyPopup: false
		};
		this.tswTraTable = React.createRef();
		this.traceHelper = new TransactionTraceHelper(this);
	}
	GetGridButtonsRight = async () => {
		const { MenuCode } = this.props;
		var gridButtonRightDetail = await this.MenuAuthorityRightHelper.RightCheckList(MenuCode, ["WIREDTLCLL", "WIREDTLBCK", "WIREDTLCLN",]);
		this.DetailButtonDisable =
			this.props.MenuCode == MenuCodes.Menu_CallCenterWireTransferTransactionSearch ? !gridButtonRightDetail.find(x => x.Code == "WIREDTLCLL")?.HasAuthority :
				false;

	}
	componentDidMount() {
		super.componentDidMount();
		this.Bind(this);
		this.GetGridButtonsRight();
		var { isBackOffice } = this.state;
		var model = { ...this.state.model };

		isBackOffice = ClientHelper.IsBackOffice();
		if (!isBackOffice) {
			model.ClientId = ClientHelper.GetClientRowId();
			this.columns.splice(1, 1);
		}
		this.setState({ model, isBackOffice });
		if (this.props.MenuCode == MenuCodes.Menu_CallCenterWireTransferTransactionSearch) {
			this.props.setAppLeftTitle("Wire Transactions");
			this.props.setAppCenterTitle("CALL CENTER");

		} else {
			this.props.setAppLeftTitle("Wire Transactions");
			this.props.setAppCenterTitle("WIRE TRANSFER");
		}
	}

	HandleChange(name, newValue, data) {
		this.setState(function (state) {
			var model = state.model || {};
			model[name] = newValue;
			if (name == "StatusId") {
				if (newValue == -1) {
					model.StatusParamCode = null;
				}
				else if (data && data.ParameterValue == null) {
					model.StatusParamCode = null;
				}
				else if (data) {
					model.StatusParamCode = data.ParameterValue;
				}
			}
			return { model };
		});
	}
	GetHandleTrace = (model) => () => {
		this.traceHelper.InsertTransactionTraceWithAlert(model.TransactionId);
	}

	GetData() {
		this.ExecutePostRequest(
			"/bankapi/v1.0/WireTransfer/ClientOutgoingWireTransactionSearch",
			this.state.model,
			responseData => {
				if (responseData.Item == null || responseData.Item.length < 1) {
					responseData.Item = [];
				}
				var approvalTransactionList = [];
				var transactionList = [];
				responseData.Item.forEach(element => {
					if (element.IsPending) {
						approvalTransactionList.push(element);
					} else {
						transactionList.push(element);
					}
				});
				this.setState({ transactionList, approvalTransactionList });
			}
		);
	}

	ClearModel() {
		var model = { ...this.state.model };
		if (this.state.isBackOffice)
			this.setState({ model: { FromDate: DateHelper.GetDateLocal(), ToDate: DateHelper.GetDateLocal() } });
		else
			this.setState({ model: { ClientId: model.ClientId, FromDate: DateHelper.GetDateLocal(), ToDate: DateHelper.GetDateLocal(), approvalTransactionList: [] } });
	}

	HandleClose() {
		this.setState({ detailDialog: false, incomingdetailDialog: false, approvalDialog: false, matchDialog: false });
	}
	DenyPopupClose()  {
		var model = { ...this.state.model };
		model["Description"] = null;

		this.setState({ DenyPopup : false ,model});
	}

	HandleOpenIncomingDetail(templateId, transferId, isApproval) {
		this.ExecutePostRequest("/bankapi/v1.0/WireBeneficiaryTemplate/SearchMessage",
			{ Id: templateId },
			responseData => {
				const tempModel = { ...responseData.Item };
				tempModel.TransferId = transferId;
				tempModel.TempOrderingInstitutionSwiftCode = tempModel.OrderingInstitutionSwiftCode;
				this.setState({
					messageDetailModel: tempModel,
					incomingdetailDialog: true,
					approvalDialog: isApproval
				});
			});
	}


	HandleDisApproval(request) {
		if(this.state.model.Description != null && this.state.model.Description != ""){
			request.Description = this.state.model.Description;
			request.IsApproval = false;
			
			this.ExecutePostRequest("/bankapi/v1.0/WireTransfer/ClientApproveOrDenyOutgoingWireTransaction",
				request,
				responseData => {
					if (!responseData.IsSucceeded) {
						this.props.showMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					else {
						this.HandleClose();
						this.DenyPopupClose();
	
						this.ShowGenericMessage("success", "Create", "Disapproved successfully.");
						this.GetData();
					}
				});
		}
		else{
			this.props.showMessage("error", "Error", "Description Required Field");
			return;
		}
		
	}

	HandleApproval(request) {	
		request.IsApproval = true;
		this.ExecutePostRequest("/bankapi/v1.0/WireTransfer/ClientApproveOrDenyOutgoingWireTransaction",
			request,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				else {
					this.HandleClose();

					this.ShowGenericMessage("success", "Create", "Approved successfully.");
					this.GetData();
				}
			});
	}

	HandleOpenDetail(model, transferId, isApproval) {
		this.ExecutePostRequest("/bankapi/v1.0/WireBeneficiaryTemplate/Search",
			{ Id: model.TemplateId, IsTemplate: false },
			responseData => {
				if (responseData.Item != null) {
					if (responseData.Item != null && responseData.Item.length > 0) {

						const tempModel = { ...responseData.Item[0] };
						tempModel.IsDomestic = tempModel.BankCountry != null && tempModel.BankCountry.ISO2 == "CA";
						tempModel.TransferId = transferId;
						this.setState({
							templateDetailModel: tempModel,
							IsDomestic: responseData.Item[0].IsDomestic,
							detailDialog: true,
							approvalDialog: isApproval,
							requestModel: model
						});
					}
				} else {
					this.props.showMessage("warning", "Detail!", "Detail not found!");
				}
			});
	}
	ValueChangedForReportType = (name, value) => {
		this.setState({ [name]: value });
	}
	ExportClick = () => {
		var tswTraColumns = [];
		this.tswTraTable.current.props.Columns.forEach(c => {
			if (c.show == undefined || c.show) {
				tswTraColumns.push({ value: c.accessor, title: c.Header, columntype: c.ColumnType, type: c.type });
			}
		}, this);

		this.setState({ isDownloadDialogOpen: true, GridKeyValueList: tswTraColumns, ExportSelectedColumns: tswTraColumns });
	}
	ExcelDownload = () => {
		if (!this.state.ExportReportType) {
			this.props.showMessage("warning", "Export Report Type not selected", "Select Export Report Type to continue");
			return;
		}

		if (this.state.ExportReportType == "PDF" && this.state.ExportSelectedColumns.length > 15) {
			this.props.showMessage("warning", "PDF document cannot exceed 15 columns", "PDF document cannot exceed 15 columns");
			return;
		}

		this.setState({ isDownloadDialogOpen: false });


		const temp = { ...this.state.model };


		var jsonColList = [];

		var tswTraColumns = {};
		this.state.ExportSelectedColumns.map(c => {
			tswTraColumns[c.value] = { value: c.value, title: c.title, columntype: c.columntype, type: c.type };
		});
		jsonColList.push(tswTraColumns);

		var mytitle = "Wire Transactions";

		temp.jsonFile = {
			colmns: jsonColList,
			format: this.state.ExportReportType,
			title: mytitle
		};

		var request = {
			ClientId: temp.ClientId,
				FromDate:temp.FromDate,
				ToDate:temp.ToDate,
			StatusId: temp.StatusId,
				SubStatusId : temp.SubStatusId,
				MessageTypeId:temp.MessageTypeId,
				AmountFrom:temp.AmountFrom,
				AmountTo:temp.AmountTo,
				SwiftReferenceNumber:temp.SwiftReferenceNumber,
				TransactionReferenceNumber:temp.TransactionReferenceNumber,
			jsonFile: temp.jsonFile
		};
		this.setState({ isLoading: true });
		Proxy.DownloadGeneratedFile(
			"/bankapi/v1.0/WireTransfer/WireTransactionsExportDownload",
			request,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", errorMessage);
			},
			this.props.showMessage
		);
	}

	showLoading = () => { this.setState({  isLoading: true }); }
	hideLoading = () => { this.setState({  isLoading: false }); }

	render() {
		const { Disabled, classes} = this.props;
		const { IsLoading, Alert } = this.state;
		const { model, isBackOffice, templateDetailModel, incomingdetailDialog, messageDetailModel, approvalTransactionList, approvalDialog, matchDialog, DenyPopup } = this.state;

		const renderColumns = [
			...this.columns
		];

		return (
			<div>
				{Alert}
				<LoadingComponent Show={IsLoading} />
				{!this.IsBackOffice &&
					<AccountLimitToolbar Program={ProgramCodes.Bank_Programs_Bank_Account} LimitMainType={"WireTransfer"} IsUseDisableDebitLimit={true} OnLoad={data => { this.setState({ LimitBalanceData: data, isLoading: false }); }} />
				}

				<GenericDialog open={incomingdetailDialog} maxWidth="xl" onBackdropClick={this.HandleClose}>
					<DialogContent>
						<h4 style={{ color: "black", textAlign: "center" }}><b>Transaction Details</b></h4>
						<br />
						<MessageDetail
							CustomerName={messageDetailModel.CustomerName}
							AccountNumber={messageDetailModel.AccountNumber}
							DateOfRequest={messageDetailModel.DateOfRequest}
							CreationDate={messageDetailModel.CreationDate}
							ValueDate={messageDetailModel.ValueDate}
							BeneficiaryName={messageDetailModel.BeneficiaryName}
							BeneficiaryAddress={messageDetailModel.BeneficiaryAddress}
							BeneficiaryAddress2={messageDetailModel.BeneficiaryAddress2}
							CustomerAddress={messageDetailModel.CustomerAddress}
							BeneficiaryCountry={messageDetailModel.BeneficiaryCountry}
							BeneficiaryCity={messageDetailModel.BeneficiaryCity}
							BeneficiaryProvince={messageDetailModel.BeneficiaryProvince}
							BeneficiaryPostalCode={messageDetailModel.BeneficiaryPostalCode}
							BeneficiaryBankCountry={messageDetailModel.BeneficiaryBankCountry}
							BeneficiaryBankPostalCode={messageDetailModel.BeneficiaryBankPostalCode}
							BeneficiaryBankInstitution={messageDetailModel.BeneficiaryBankInstitution}
							Remittance={messageDetailModel.Remittance}
							BeneficiaryAccountNumber={messageDetailModel.BeneficiaryAccountNumber}
							IntermediarySwiftCode={messageDetailModel.IntermediarySwiftCode}
							Amount={messageDetailModel.Amount}
							Currency={messageDetailModel.Currency}
							PurposeOfWire={messageDetailModel.PurposeOfWire}
							Reference={messageDetailModel.Reference}
							BeneficiaryBank={messageDetailModel.BeneficiaryBank}
							BeneficiaryBankAddress={messageDetailModel.BeneficiaryBankAddress}
							BeneficiaryBankAddress2={messageDetailModel.BeneficiaryBankAddress2}
							BeneficiaryBankCity={messageDetailModel.BeneficiaryBankCity}
							BeneficiaryBankProvince={messageDetailModel.BeneficiaryBankProvince}
							TransitNumber={messageDetailModel.TransitNumber}
							BeneficiaryIdentifier={messageDetailModel.BeneficiaryIdentifier}
							BeneficiaryBankPartyIdentifier={messageDetailModel.BeneficiaryBankPartyIdentifier}
							OrderingInstitutionSwiftCode={messageDetailModel.OrderingInstitutionSwiftCode}
							TempOrderingInstitutionSwiftCode={messageDetailModel.TempOrderingInstitutionSwiftCode}

							SenderName={messageDetailModel.SenderName}
							SenderAccountNumber={messageDetailModel.SenderAccountNumber}
							SenderSwiftCode={messageDetailModel.SenderSwiftCode}
							SenderAddress={messageDetailModel.SenderAddress}
							SenderAdditionalInformation={messageDetailModel.SenderAdditionalInformation}

							OrderingInstitutionPartyIdentifier={messageDetailModel.OrderingInstitutionPartyIdentifier}
							OrderingInstitutionAddress={messageDetailModel.OrderingInstitutionAddress}

							LastUser={messageDetailModel.LastUser}
							SubStatus={messageDetailModel.SubStatus}

							Disabled={true} />
						<br />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.HandleClose}>
							Close
						</Button>
					</GenericDialogActions>
				</GenericDialog>

				<GenericDialog open={DenyPopup} maxWidth="xl" onBackdropClick={this.DenyPopupClose}>
					<DialogContent>
						<h4 style={{ color: "black", textAlign: "center" }}><b>Reject Reason</b></h4>
						<br />
						<GridItem xs={12} style={{ marginLeft: 2, marginTop: 12 }}>
							<GenericLabel FontSize="12px" TextColor="Black" Text="Description" />
						</GridItem>
						<GridItem xs={12} style={{ marginLeft: -3, width:"300px"}}>
							<GenericTextInput
								Name="Description"
								LabelMd={0}
								Value={model.Description}
								ValueChanged={this.HandleChange}
								MultiLine={true}
								RowCount={7}
								inputProps={{maxLength: 60}}
								/>
						</GridItem>
						<br />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={() => this.HandleDisApproval(this.state.requestModel)}>Submit</Button>
						<Button size="sm" onClick={this.DenyPopupClose}>
							Close
						</Button>
					</GenericDialogActions>
				</GenericDialog>

				<GenericDialog open={matchDialog} maxWidth="md" fullWidth >

					<DialogContent className={classes.dialog}>
						<GridContainer xs={12}>
							<GridItem xs={8}>
								<GenericSelectInput
									Name="UniqueClientId"
									LabelText="Client"
									Method="POST"
									Url="/bankapi/v1.0/BankCustomer/Search"
									Parameter={{}}
									DataRoot="Item"
									KeyValueMember="Id"
									RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
									Value={model.UniqueClientId || ""}
									ValueChanged={this.ValueChanged}
									CanClear
									All
									Disabled={!this.isBackOffice} />

								<GenericSelectInput
									key={model.UniqueClientId}
									Name="AccountId"
									LabelText="Account"
									Method="POST"
									Url="/bankapi/v1.0/Account/GetAllAccountByUniqueClient"
									Parameter={{
										UniqueClientId: model.UniqueClientId
									}}
									DataRoot="Item"
									KeyValueMember="AccountId"
									TextValueMember="AccountNumber"
									RenderItem={this.GetRenderItemAccount}
									Sorted={{ Member: "AccountNumber" }}
									Value={model.AccountId}
									ValueChanged={this.HandleChange}
								/>
							</GridItem>
							<GridItem style={{ visibility: "hidden" }}>
								<GenericTextInput
									LabelText="Empty"
								/>

								<GenericTextInput
									LabelText="Empty"
								/>
								<GenericTextInput
									LabelText="Empty"
								/>
								<GenericTextInput
									LabelText="Empty"
								/>

							</GridItem>

							<GenericLabel Text={"Are you sure you want to enter this transaction ?"} Bold={true} FontSize="20ox"
							/>

						</GridContainer>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={() => this.setState({ matchDialog: false })}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>


				<GenericDialog open={this.state.detailDialog} maxWidth="md" onBackdropClick={this.HandleClose}>
					<DialogContent>
						<h4 style={{ color: "black", textAlign: "center" }}><b>Transaction Details</b></h4>
						<br />
						<BeneficiaryDetail
							CompanyName={templateDetailModel.CompanyName}
							Name={templateDetailModel.Name}
							MiddleName={templateDetailModel.MiddleName}
							LastName={templateDetailModel.LastName}
							AddressLine1={templateDetailModel.AddressLine1}
							AddressLine2={templateDetailModel.AddressLine2}
							ZipCode={templateDetailModel.ZipCode}
							CityId={templateDetailModel.CityId}
							ProvinceId={templateDetailModel.ProvinceId}
							CountryId={templateDetailModel.CountryId}
							IsDomestic={templateDetailModel.BankCountry != null && templateDetailModel.BankCountry.ISO2 == "CA"}
							BankCountryId={templateDetailModel.BankCountryId}
							BankFinInsId={templateDetailModel.BankFinInsId}
							BankName={templateDetailModel.BankName}
							BankBranchId={templateDetailModel.BankBranchId}
							BankTransitNumber={templateDetailModel.BankTransitNumber}
							AccountNumber={templateDetailModel.AccountNumber}
							BankSwiftCode={templateDetailModel.BankSwiftCode}
							BankAddressLine1={templateDetailModel.BankAddressLine1}
							BankAddressLine2={templateDetailModel.BankAddressLine2}
							BankZipCode={templateDetailModel.BankZipCode}
							BankCityId={templateDetailModel.BankCityId}
							BankProvinceId={templateDetailModel.BankProvinceId}
							BankInstitution={templateDetailModel.BankInstitution}
							Alias={templateDetailModel.Alias}
							PhoneNumber = {templateDetailModel.PhoneNumber}
							Disabled={true} />
						<br />
					</DialogContent>
					<GenericDialogActions>
						{this.state.requestModel.IsPending == true &&
							<Button size="sm" onClick={() => this.HandleApproval(this.state.requestModel)}>Approve</Button>
						}
						{this.state.requestModel.IsPending == true &&
							<Button size="sm" onClick={() => this.setState({ DenyPopup: true })}>Deny</Button>
						}

						<Button size="sm" onClick={() => this.setState({ detailDialog: false })}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.GetData, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.ClearModel, Disabled: Disabled },
					{ Code: "Export", OnClick: this.ExportClick, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GenericDialog open={this.state.isDownloadDialogOpen} fullWidth={true}>
					<DialogTitle>
						<GenericLabel Text="Select Report Type" FontSize="20px" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ height: 300 }}>
						<GenericRadioInput
							Name="ExportReportType"
							LabelText="Report Type"
							IsStatic={true}
							StaticData={[{ Value: "PDF", Text: "PDF" }, { Value: "CSV", Text: "CSV" }, { Value: "EXCEL", Text: "EXCEL" }]}
							KeyValueMember="Value"
							TextValueMember="Text"
							Value={this.state.ExportReportType}
							ValueChanged={this.ValueChangedForReportType}
						/>
						<GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
						<GridItem style={{ marginTop: 20 }}>
							<Select
								value={this.state.ExportSelectedColumns}
								onChange={value => this.setState({ ExportSelectedColumns: value })}
								isMulti
								name="columns"
								getOptionLabel={d => d.title}
								options={this.state.GridKeyValueList}
								className="basic-multi-select"
								classNamePrefix="select"
							/>
						</GridItem>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.ExcelDownload}>Download</Button>
						<Button size="sm" onClick={() => this.setState({ isDownloadDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>
				<Card>
					<CardBody>
						<GenericExpansionPanel IsActive={true}>
							<GridContainer>
								<GridItem xs={3}>
									<GridContainer>
										<GridItem xs={12}>
											<GenericSelectInput
												Name="ClientId"
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={{}}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
												Value={model.ClientId || ""}
												ValueChanged={this.HandleChange}
												All
												CanClear
												Disabled={!isBackOffice} />
										</GridItem>
										<GridItem xs={12}>
											<GenericDateInput
												Utc
												Name="FromDate"
												LabelText="From Date"
												Value={model.FromDate == null || undefined ? "" : model.FromDate}
												ValueChanged={this.HandleChange}
												MaxDate={model.ToDate || DateHelper.GetDate()}
												IncludeTime={false} />
										</GridItem>
										<GridItem xs={12}>
											<GenericDateInput
												Utc
												Name="ToDate"
												LabelText="To Date"
												Value={model.ToDate == null || undefined ? "" : model.ToDate}
												ValueChanged={this.HandleChange}
												MinDate={model.FromDate == null ? null : model.FromDate}
												MaxDate={DateHelper.GetDate()}
												IncludeTime={false} />
										</GridItem>
										{/* <GridItem xs={12}>
											<GenericTextInput Name="SwiftReferenceNumber" LabelText="Swift Reference Number" Value={model.SwiftReferenceNumber} ValueChanged={this.HandleChange} />
										</GridItem> */}
									</GridContainer>
								</GridItem>
								{/* <GridItem xs={3}>
									<GridContainer>
										<GridItem xs={12}>
											<ParameterComponent
												All
												Name="StatusId"
												Value={model.StatusId}
												LabelText="Status"
												ParameterCode="WireStatus"
												ValueChanged={this.HandleChange}
												Disabled={Disabled} />
										</GridItem>
										<GridItem xs={12}>
											<ParameterComponent
												key={"wiresubstatus_" + model.StatusId}
												Name="SubStatusId"
												Value={model.SubStatusId}
												LabelText="Sub-Status"
												ParameterCode="WireSubStatus"
												ParameterValue2={model.StatusParamCode}
												ValueChanged={this.HandleChange}
												Disabled={Disabled} />
										</GridItem>
										<GridItem xs={12}>
											<ParameterComponent
												Name="MessageTypeId"
												Value={model.MessageTypeId}
												LabelText="Message Type"
												ParameterCode="WireMessageType"
												ValueChanged={this.HandleChange}
												Disabled={Disabled} />
										</GridItem>
									</GridContainer>
								</GridItem> */}
								<GridItem xs={3}>
									<GridContainer>
										<GridItem xs={12}>
											<GenericNumberInput
												Name="AmountFrom"
												LabelText="Amount From"
												Value={model.AmountFrom == null ? "" : model.AmountFrom}
												ValueChanged={this.HandleChange}
												Prefix="$" />
										</GridItem>
										<GridItem xs={12}>
											<GenericNumberInput
												Name="AmountTo"
												LabelText="Amount To"
												Value={model.AmountTo == null ? "" : model.AmountTo}
												ValueChanged={this.HandleChange}
												Prefix="$" />
										</GridItem>
{/* 
										<GridItem xs={12}>
											<GenericTextInput Name="TransactionReferenceNumber" LabelText="Transaction Reference Number" Value={model.TransactionReferenceNumber} ValueChanged={this.HandleChange} />
										</GridItem> */}

									</GridContainer>
								</GridItem>
								<br />
							</GridContainer>
						</GenericExpansionPanel>
					</CardBody>
				</Card>
				{!this.IsBackOffice &&
					<Card className="no-radius">
						<CardBody>
							<CardHeader>
								<GenericTitle text={"Wire Approval List"} />
							</CardHeader>
							<GridItem xs={12}>
								<GridItem>
									<GenericGrid
										Data={approvalTransactionList}
										Sorted={[{ id: "DateRequest", desc: true }]}
										Columns={renderColumns}
										ProgramCode={ProgramCodes.Bank_Programs_Bank_Account}
										ShowPagination={true}

									/>
								</GridItem>
							</GridItem>
						</CardBody>
					</Card>
				}
				<Card className="no-radius">
					<CardBody>
						<CardHeader>
							<GenericTitle text={"Transaction List"} />
						</CardHeader>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={this.state.transactionList}
									Sorted={[{ id: "DateRequest", desc: true }]}
									Columns={this.columns}
									ProgramCode={ProgramCodes.Bank_Programs_Bank_Account}
									PageSize={10}
									ShowPagination={true}
									ref={this.tswTraTable} />
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>
			</div>
		);
	}
}

TransactionSearchWireTransfer.propTypes = {
	classes: PropTypes.object,
	handleOperationType: func,
	showQuestionMessage: func,
	setAppLeftTitle: func,
	setAppCenterTitle: func,
	Disabled: bool,
	ValueChanged: PropTypes.func,
	Model: PropTypes.object
};

export default withArtifex(TransactionSearchWireTransfer, {});