import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes, { bool, func } from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDateInput, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTitle } from "views/Components/Generic";
import ParameterComponent from "views/Components/ParameterComponent";
import { GridColumnType, ProgramCodes } from "views/Constants/Constant";

class eTransferIntegrationErrors extends Component {
	constructor(props) {
		super(props);
		this.state = {
			model: {
				EndDate: DateHelper.GetDate(),
				StartDate: DateHelper.GetDate(),
				ClientId: 0
			},
			getDataList: [],
			isBackOffice: false,
			NowDate: DateHelper.GetDateTime(),
			clientId: 0
		};
	}

	componentDidMount() {
		this.props.setAppLeftTitle("e-Transfer Integration Errors");
		var model = { ...this.state.model };
		var { isBackOffice, clientId } = this.state;
		isBackOffice = ClientHelper.IsBackOffice();
		if (!isBackOffice) {
			model.ClientId = ClientHelper.GetClientRowId();
			clientId = ClientHelper.GetClientRowId();
			this.props.setAppCenterTitle("INTERAC E-TRANSFER");
			this.props.setAppLeftTitle("Integration Errors");
		}
		else {
			this.props.setAppCenterTitle("SYSTEM ADMIN");
		}
		this.setState({ model, isBackOffice, clientId });
	}

	handleChange = (name, newValue) => {
		this.setState(state => {
			var model = state.model || {};
			model[name] = newValue;

			if (name == "StartDate" && newValue > model.EndDate)
				model.EndDate = newValue;

			return { model };
		});
	}
	IsValid = () => {
		var model = { ...this.state.model };
		if (typeof model.StartDate != "object" && model.StartDate != "" && model.StartDate != null) {
			this.props.showMessage("warning", "Please fill required fields!", "Start Date is invalid");
			return false;
		}
		if (typeof model.EndDate != "object" && model.EndDate != "" && model.EndDate != null) {
			this.props.showMessage("warning", "Please fill required fields!", "End Date is invalid");
			return false;
		}
		if ((typeof model.StartDate == "object" && typeof model.EndDate == "object") && model.EndDate < model.StartDate) {
			this.props.showMessage("warning", "Please fill required fields!", "Start Date cannot be bigger than End Date");
			return false;
		}
		return true;
	}
	GetData = async () => {
		var model = { ...this.state.model };
		const { ExecuteApiPost } = this.props;

		if (!this.IsValid())
			return;

		var responseData = await ExecuteApiPost("/bankapi/v1.0/ClearingSettlement/GetETransferIntegrationErrors", model, null, null, null, null, null);

		if (responseData != null)
			this.setState({ getDataList: responseData });
		else
			this.setState({ getDataList: [], });

	}

	handleClear = () => { this.setState({ model: { EndDate: DateHelper.GetDateTime(), StartDate: DateHelper.GetDateTime(), ClientId: this.state.clientId }, getDataList: [] }); }
	hideAlert() { this.setState({ alert: null }); }

	render() {
		const { Disabled } = this.props;
		const { model, isBackOffice, NowDate } = this.state;
		return (
			<div>
				{this.state.alert}

				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.GetData, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.handleClear, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<Card className="no-radius">
					<GenericExpansionPanel Title={"Filter Panel"}>
						<CardBody>
							<GridContainer >
								<GridItem xs={4}>
									<GenericSelectInput
										Name="ClientId"
										LabelText="Client"
										Method="POST"
										Url="/bankapi/v1.0/BankCustomer/Search"
										Parameter={{}}
										DataRoot="Item"
										KeyValueMember="Id"
										RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
										Value={model.ClientId || ""}
										ValueChanged={this.handleChange}
										All
										CanClear
										Disabled={!isBackOffice} />
									<GenericDateInput
										Utc
										Name="StartDate"
										LabelText="Start Date"
										Value={model.StartDate == null || undefined ? "" : model.StartDate}
										ValueChanged={this.handleChange}
										MaxDate={model.EndDate || NowDate}
										IncludeTime={false} />
									<GenericDateInput
										Utc
										Name="EndDate"
										LabelText="End Date"
										Value={model.EndDate == null || undefined ? "" : model.EndDate}
										ValueChanged={this.handleChange}
										MinDate={model.StartDate || DateHelper.GetDateTime()}
										MaxDate={NowDate}
										IncludeTime={false} />
									<ParameterComponent
										Name="ETransferBatchIntegrationErrorTypeId"
										Value={model.ETransferBatchIntegrationErrorTypeId}
										LabelText="Error Type"
										ParameterCode="ETransferBatchIntegrationErrorType"
										All
										ValueChanged={this.handleChange} />
								</GridItem>
							</GridContainer>
						</CardBody>
					</GenericExpansionPanel>
				</Card>
				<Card className="no-radius">
					<CardBody>
						<CardHeader>
							<GenericTitle text={"Error List"} />
						</CardHeader>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={this.state.getDataList}
									Columns={[
										{
											Header: "Error Type",
											accessor: "ErrorType"
										},
										{
											Header: "Is Client Operation",
											accessor: "IsClientOperation",
											Cell: row => (
												<div>{row.value == true ? "Yes" : "No"}</div>
											),
										},
										{
											Header: "Unique Client Id",
											accessor: "ClientId",
											show: isBackOffice
										},
										{
											Header: "Client Name",
											accessor: "ClientName",
											show: isBackOffice
										},
										{
											Header: "Client Customer Name",
											accessor: "ClientCustomerName"
										},
										{
											Header: "ContactName",
											accessor: "ContactName"
										}
										,
										{
											Header: "Transaction e-Transfer Id",
											accessor: "TransactionETransferId"
										},
										{
											Header: "Date",
											accessor: "Date",
											type: GridColumnType.DateTimeUtc
										},
										{
											Header: "Description",
											accessor: "Description"
										}
									]}
									ProgramCode={ProgramCodes.Bank_Programs_e_Transfer} />
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>
			</div>
		);
	}
}

eTransferIntegrationErrors.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: func,
	setAppCenterTitle: func,
	Disabled: bool
};

export default withArtifex(eTransferIntegrationErrors, {});