import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import { EditIcon, RowDetailIcon } from "core/Icons";
import MenuAuthorityRightHelper from "core/MenuAuthorityRightHelper";
import RouteHelper from "core/RouteHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericExpansionPanel, GenericGrid, GenericSelectInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton.jsx";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType } from "views/Constants/Constant";

const emptyObject = {};
class AllFeeProfileList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			FeeProfileList: [],
			isLoading: false,
			model: {},
			gridButtonRightDetail: []
		};
		this.MenuAuthorityRightHelper = new MenuAuthorityRightHelper();
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Fee Profile List");
		this.props.setAppCenterTitle("FEE PROFILES");

		this.GetGridButtonsRight().then(this.Fetcth);
	}

	GetGridButtonsRight = async () => {
		return new Promise(async (resolve, reject) => {
			const { MenuCode } = this.props;
			var gridButtonRightDetail = await this.MenuAuthorityRightHelper.RightCheckList(MenuCode, ["FEELISTMONITOR", "FEELISTEDIT"]);
			this.setState({ gridButtonRightDetail }, resolve);
		});

	}

	Fetcth = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/FeeProfile/GetAllProfile",
			{},
			this.successCallback,
			this.errorCallback
		);
	}

	successCallback = (responseData) => {
		this.setState({ isLoading: false });
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error", "An error occurred during the api visit " + responseData.ErrorDescription);
			return;
		}

		if (responseData.Item != null) {
			this.setState({
				FeeProfileList: responseData.Item.map(fee => {
					return {
						Actions: (
							<div>
								<GridButton
									tooltip="Edit"
									Icon={EditIcon}
									OnClick={() => this.pageSelect(fee == null ? "" : fee.Id == null ? 0 : fee.Id, fee.FeeProfileType == null ? "" : fee.FeeProfileType.ParameterValue == null ? "" : fee.FeeProfileType.ParameterValue)}
									Disabled={!this.state.gridButtonRightDetail.find(x => x.Code == "FEELISTEDIT")?.HasAuthority} />
								<GridButton
									tooltip="Monitor"
									Icon={RowDetailIcon}
									OnClick={() => this.pageSelect(fee == null ? "" : fee.Id == null ? 0 : fee.Id, fee.FeeProfileType == null ? "" : fee.FeeProfileType.ParameterValue == null ? "" : fee.FeeProfileType.ParameterValue, true)}
									Disabled={!this.state.gridButtonRightDetail.find(x => x.Code == "FEELISTMONITOR")?.HasAuthority} />

							</div>
						),
						Id: fee == null ? 0 : fee.Id == null ? 0 : fee.Id,
						ProfileName: fee == null ? "" : fee.ProfileName == null ? "" : fee.ProfileName,
						FeeType: fee == null ? "" : fee.FeeProfileType == null ? "" : fee.FeeProfileType.ParameterDesc == null ? "" : fee.FeeProfileType.ParameterDesc,
						Program: fee == null ? "" : fee.Program == null ? "" : fee.Program.Description == null ? "" : fee.Program.Description,
						ValidFrom: fee == null ? "" : fee.ValidFrom == null ? "" : fee.ValidFrom,
						ValidTo: fee == null ? "" : fee.ValidTo == null ? "" : fee.ValidTo,
						OrganizationUniqueId: fee == null ? "" : fee.OrganizationUniqueId == null ? "" : fee.OrganizationUniqueId

					};
				}), isLoading: false
			});
		} else {
			this.setState({ FeeProfileList: [], isLoading: false });
		}
	}

	pageSelect = (feeId, profileType, isOnlyMonitor) => {
		if (feeId != 0 && profileType != "") {

			RouteHelper.Push(this.props.history, "/FeeProfileDefinition", "FeeProfileDefinition",
				{
					Id: feeId,
					MonitorMode: isOnlyMonitor
				});

		}
	}

	handleChange = (name, newValue, data) => {
		const temp = { ...this.state.model };
		temp[name] = newValue;
		this.setState({ model: temp });
	}
	errorCallback = (error) => {
		this.ShowMessage("error", "Error", "An error occurred during the api visit" + error);
		this.setState({ isLoading: false });
	}
	ShowMessage = (type, title, message) => {
		this.setState({
			isLoading: false,
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}

	hideAlert = () => {
		this.setState({ alert: null, isLoading: false });
	}

	ClearData = () => {
		this.setState({ model: {} });
	}
	Search = () => {
		this.setState({ isLoading: true });
		const data = { ...this.state.model };
		Proxy.POST(
			"/bankapi/v1.0/FeeProfile/GetAllProfile",
			data,
			this.successCallback,
			this.errorCallback
		);
	}

	GetColumnsRowStyleColor(row) {
		if (!row) {
			return;
		}

		let localDateUtc = DateHelper.GetLocalDateTimeAsUtc();
		let condition = localDateUtc >= DateHelper.ToMoment(row.ValidFrom) && localDateUtc <= DateHelper.ToMoment(row.ValidTo);


		this.columnsRowStyleColor = {
			color: !condition
				? "#FB3005"
				: ""
		};
		return this.columnsRowStyleColor;
	}

	RenderOrganizationUniqueIdSelect = (d) => {
		if (window.OrganizationUniqueId != 5000) {
		  const model = { ...this.state.model };
		  model["OrganizationUniqueId"] = d.OrganizationUniqueId;
		  this.setState({ model: model });
		}
  
		return d.OrganizationUniqueId + "-" + d.OrganizationName;
	 };

	render() {
		const { Disabled } = this.props;
		const { model } = this.state;

		return (
			<GridContainer>
				{this.state.alert}
				<GridItem xs={12}>

					<LoadingComponent Show={this.state.isLoading} />

					<ButtonToolbar ButtonList={[
						{ Code: "Get", OnClick: () => this.Fetcth(), Disabled: Disabled },
						{ Code: "Search", OnClick: () => this.Search(), Disabled: Disabled },
						{ Code: "Clear", OnClick: () => this.ClearData(), Disabled: Disabled }

					]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

					<Card className="no-radius">
						<GenericExpansionPanel Title={"Main Parameters "}>
							<CardBody>
								<GridContainer spacing={16}>
									<GridItem xs={3}>
										<GenericSelectInput Name="FeeProfileTypeId" LabelText="Fee Profile Type" Value={model.FeeProfileTypeId || ""} ValueChanged={this.handleChange}
											KeyValueMember="Id" TextValueMember="ParameterDesc" Method="POST" Url="/coreapi/v1.0/Parameter/Search"
											Parameter={{
												ParameterCode: "FeeProfileType"
											}}
											DataRoot="Item" />
										<GenericDateInput Utc Name="ValidFrom" LabelText="Valid From" Value={model.ValidFrom ? Formatter.FormatDateUtc(model.ValidFrom) : null} ValueChanged={this.handleChange} />
									</GridItem>
									<GridItem xs={3} >
										<GenericSelectInput Name="ProgramId" LabelText="Program" Value={model.ProgramId} ValueChanged={this.handleChange}
											KeyValueMember="Id" TextValueMember="Description" Method="GET"
											Url="/bankapi/v1.0/Bank/ValidBankProgramsForFeeProfile"
											DataRoot="Item" />
										<GenericDateInput Utc Name="ValidTo" LabelText="Valid To" Value={model.ValidTo ? Formatter.FormatDateUtc(model.ValidTo) : null} ValueChanged={this.handleChange} />
									</GridItem>
									<GridItem xs={3}>
									<GenericSelectInput
                             
                            key={this.state.IsClientComponent}
                            Name="OrganizationUniqueId"
                            LabelText="Organization"
                            Value={model.OrganizationUniqueId || ""}
                            DataRoot="Item"
                            ValueChanged={this.handleChange}
                            KeyValueMember="OrganizationUniqueId"
                            TextValueMember="OrganizationUniqueId"
                            //  TaskPoolDomain={this.loadTaskPool}
                            Url="/bankapi/v1.0/OrganizationInfo/GetAll"
                            Method="POST"
                            RenderItem={this.RenderOrganizationUniqueIdSelect}
                            Parameter={emptyObject}
                             IsInvalid={model.IsOrganizationUniqueId}
                          />
									</GridItem>
									<GridItem xs={3} >
									</GridItem>
								</GridContainer>
							</CardBody>
						</GenericExpansionPanel>
					</Card>

					<Card className="no-radius">
						<br />
						<CardBody>
							<GridItem xs={12}>
								<GenericGrid
									Data={this.state.FeeProfileList}
									Columns={[
										{
											Header: "Actions",
											accessor: "Actions",
											sortable: false,
											filterable: false,
											width: 60,
											Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original)}>{row.value}</div>)
										},
										{
											Header: "Program",
											accessor: "Program",
											Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original)}>{row.value}</div>)
										},
										{
											Header: "Fee Type",
											accessor: "FeeType",
											Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original)}>{row.value}</div>)
										},
										{
											Header: "Profile Name",
											accessor: "ProfileName",
											Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original)}>{row.value}</div>)
										},
										{
											Header: "Valid From Date",
											accessor: "ValidFrom",
											type: GridColumnType.DateUtc,
											Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original)}>{Formatter.FormatDateUtc(row.value)}</div>),
											filterMethod: (filter, row, column) => {
												return Formatter.FormatDateUtc(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase());
											}
										},
										{
											Header: "Valid To Date",
											accessor: "ValidTo",
											type: GridColumnType.DateUtc,
											Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original)}>{Formatter.FormatDateUtc(row.value)}</div>),
											filterMethod: (filter, row, column) => {
												return Formatter.FormatDateUtc(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase());
											}
										},
										{
											Header: "OrganizationUniqueId",
											accessor: "OrganizationUniqueId",
											show: window.OrganizationUniqueId != 5000?false:true
										},
									]}
								/>

							</GridItem>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

AllFeeProfileList.propTypes = {
	classes: PropTypes.object,
	setAppCenterTitle: PropTypes.func,
	setAppLeftTitle: PropTypes.func,
	FeeType: PropTypes.func
};

export default withArtifex(AllFeeProfileList, {});