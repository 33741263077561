import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import { DeleteIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import {GenericAlert, GenericCheckInput, GenericGrid, GenericSelectInput, GenericLabel} from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import {GridColumnType} from "views/Constants/Constant";
class RestrictedClients extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			vModel: {},
			model: {},
			key: {},
			alert: null,
			isLoading: false,
			list: []
		};

		this.sortName = { Member: "Name" };
		this.EmptyParameter = {};

		this.ColumnsData = [

			{
				Header: "Actions",
				accessor: "Status",
				Cell: row => (
					<div>
						<GridButton
							tooltip={"Delete"}
							Disabled={false}
							Icon={DeleteIcon}
							OnClick={() => { this.Delete(row.original); }} />

					</div>
				)
			},
			{
				Header: "Program",
				accessor: "ProgramDescription"
			},
			{
				Header: "Client Id",
				accessor: "UniqueClientId"
			},
			{
				Header: "Client Name",
				accessor: "UniqueClientName"
			},
			{
				Header: "Create",
				accessor: "InsertDateTime",
				type: GridColumnType.DateTime
			}

		];
	}
	componentDidMount() {
		if (this.props.setAppCenterTitle) {
			this.props.setAppCenterTitle("CLIENT");
		}

		if (this.props.setAppLeftTitle)
			this.props.setAppLeftTitle("EFT Restricted Client");

	}
	OperationCheck = () => {
		var ErrorList = [];
		var data = { ...this.state.model };
		const vModel = this.state.vModel;

		if (data.ProgramId == null || data.ProgramId == "") {
			vModel.ProgramId = true;
			ErrorList.push("Program must be defined!");
		} else { vModel.ProgramId = false; }

		if (data.UniqueClientId == null || data.UniqueClientId == "") {
			vModel.UniqueClientId = true;
			ErrorList.push("Client Name must be defined!");
		} else { vModel.UniqueClientId = false; }

		if (ErrorList.length > 0) {
			this.props.showMessageNode("warning", "Please fill required fields!", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		this.setState({ vModel });
		this.Submit();
	}
	Search = () => {
		this.setState({ isLoading: true });
		var temp = { ...this.state.model };

		Proxy.POST(
			"/bankapi/v1.0/RestrictedClient/SearchRestrictedClient",
			temp,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ isLoading: false });
				this.setState({ list: responseData.Item || [] });
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	ShowConfirmMessage = (type, title, message, onConfirm, showCancel = true) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={showCancel} OnCancel={() => this.setState({ alert: null })} OnConfirm={onConfirm} />
		});
	}
	Delete = (model) => {
		this.ShowConfirmMessage("warning", "Warning",
			"Are you sure you want to delete?", () => {
				this.setState({ alert: null, isLoading: true });
				Proxy.POST(
					"/bankapi/v1.0/RestrictedClient/DeleteRestrictedClient", this.state.model,
					responseData => {
						this.setState({ isLoading: false });
						if (!responseData.IsSucceeded) {
							this.props.showMessage("error", responseData.Code, responseData.ErrorDescription);
							return;
						}
						if (responseData.IsSucceeded) {
							this.ClearData();
							this.Search();
							this.props.showMessage("success", "Success", "Success");
						} else {
							this.props.showMessage("error", "Error", "There was an error adding to the Restricted Client");
						}
					},
				);
			});
	}
	Submit = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/RestrictedClient/InsertRestrictedClient",
			this.state.model,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.props.showMessage("success", "Success", "The Restricted Client Completed Successfully.");
				this.Search();
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}
	GetRenderItemUniqueClient = (x) => {
		this.renderItemUniqueClient = `${x.Name} - ${x.UniqueClientId}`;

		return this.renderItemUniqueClient;
	}
	SelectedRowChange = (e, index) => {
		const model = this.state.list[index];
		this.setState({ model: model || {} });
	}
	ClearData = () => {
		this.setState({
			model: {},
			key: DateHelper.GetDateTime(),
			alert: null,
			isLoading: false,
			list: []
		});
	}
	HandleChange = (name, value) => {
		const model = { ...this.state.model };
		model[name] = value;
		this.setState({ model });
	}

	render() {
		const { Disabled } = this.props;
		const { model, list, vModel, key } = this.state;

		return (
			<div>
				{this.state.alert}
				<LoadingComponent Show={this.state.isLoading} />
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.Search, Disabled: Disabled },
					{ Code: "Submit", OnClick: this.OperationCheck, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.ClearData, Disabled: Disabled },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<Card className="no-radius">
					<CardBody>
						<b><p style={{ paddingTop: "20px", marginLeft: "10px" }}> Restricted Clients </p></b>
						<GridContainer justify="flex-start">
							<GridItem xs={4}>
								<GenericSelectInput
									Disabled={Disabled}
									Name="UniqueClientId"
									LabelText="Client Name"
									Value={model.UniqueClientId}
									DataRoot="Item"
									ValueChanged={this.HandleChange}
									KeyValueMember="Id"
									TextValueMember="Name"
									Url="/bankapi/v1.0/BankCustomer/Search"
									Method="POST"
									Parameter={this.EmptyParameter}
									RenderItem={this.GetRenderItemUniqueClient}
									CanClear
									Required
									IsInvalid={vModel.UniqueClientId}
								/>
							</GridItem>
							<GridItem xs={4}>
								<GenericSelectInput
									key={"UniqueClient" + key + ""}
									Name="ProgramId"
									LabelMd={5}
									LabelText="Program"
									Method="GET"
									Url="/bankapi/v1.0/Bank/ValidBankProgramsForLimitProfile"
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="Description"
									Value={model.ProgramId}
									ValueChanged={this.HandleChange}
									Required
									CanClear
									Disabled={true}
									IsInvalid={vModel.ProgramId}
									DefaultIndex={1}
								/>
							</GridItem>
						</GridContainer>
						<div style={{padding: '1rem 0 1rem 0.5rem'}}>
							<GridContainer>
								<GridItem xs={2}
										  container
										  alignItems="center">
									<GenericLabel
										Text='Incoming blocked non-DDT account'
									/>
								</GridItem>
								<GridItem xs={1}
										  container
										  alignItems="center">
									<GenericCheckInput
										Value={model.BlockNonDdtDebit}
										ValueChanged={() => this.HandleChange("BlockNonDdtDebit", !model["BlockNonDdtDebit"])}
									/>
									<GenericLabel
										Text='Debit'
									/>
								</GridItem>
								<GridItem xs={2}
										  container
										  alignItems="center">
									<GenericCheckInput
										Value={model.BlockNonDdtCredit}
										ValueChanged={() => this.HandleChange("BlockNonDdtCredit", !model["BlockNonDdtCredit"])}
									/>
									<GenericLabel
										Text='Credit'
									/>
								</GridItem>
							</GridContainer>
							<GridContainer>
								<GridItem xs={2}
										  container
										  alignItems="center">
									<GenericLabel
										Text='Incoming blocked individual DDT account'
									/>
								</GridItem>
								<GridItem xs={1}
										  container
										  alignItems="center">
									<GenericCheckInput
										Value={model.BlockDdtDebit}
										ValueChanged={() => this.HandleChange("BlockDdtDebit", !model["BlockDdtDebit"])}
									/>
									<GenericLabel
										Text='Debit'
									/>
								</GridItem>
								<GridItem xs={2}
										  container
										  alignItems="center">
									<GenericCheckInput
										Value={model.BlockDdtCredit}
										ValueChanged={() => this.HandleChange("BlockDdtCredit", !model["BlockDdtCredit"])}
									/>
									<GenericLabel
										Text='Credit'
									/>
								</GridItem>
							</GridContainer>
							<GridContainer>
								<GridItem xs={1}/>
								<GridItem xs={2}
										  container
										  alignItems="center"
								>
									<GenericLabel
										Text='Incoming EFT debit when no fund in DDT'
									/>
								</GridItem>
								<GridItem
									container
									alignItems="center"
									xs={4}
								>
									<GenericCheckInput
										Value={model.UseCorporateAccount}
										ValueChanged={() => this.HandleChange("UseCorporateAccount", !model["UseCorporateAccount"])}
									/>
									<GenericLabel
										Text='Allow system to check DDT Return Account, then corporate Wallet for funds/draw'
									/>
								</GridItem>
							</GridContainer>
							<GridContainer>
								<GridItem xs={1}/>
								<GridItem xs={4}
										  alignItems="center">
									<GenericLabel
										Text='(base offering: system rejects if no funds available in individual DDT)'
									/>
								</GridItem>
							</GridContainer>
						</div>
					</CardBody>
				</Card>

				<Card className="no-radius" >
					<b><p style={{ paddingTop: "20px", marginLeft: "10px" }}> Restricted Client </p></b>
					<CardBody>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={list}
									Columns={this.ColumnsData}
									RowSelected={index => {
										this.SelectedRowChange(0, index);
									}}
								/>
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>
			</div>
		);
	}
}
RestrictedClients.propTypes = {
	classes: PropTypes.object
};

export default withArtifex(RestrictedClients, {});