import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import AlertHelper from "core/AlertHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDateInput, GenericGrid, GenericNumberInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType } from "views/Constants/Constant.js";

class BAI2File extends Component {
	constructor(props) {
		super(props);
		this.state = {
			model: {
				FromDate: DateHelper.GetDate(),
				ToDate: DateHelper.GetDate()
			},
			settlementFileList: [],
			headerModel: {},
			transactionList: [],
			isLoading: false,
			SettlementType: ""
		};
	}

	componentDidMount() {
		this.props.setAppLeftTitle("BAI2 File");
		this.props.setAppCenterTitle("CLEARING & SETTLEMENT");
	}

	handleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		var { SettlementType } = this.state;
		if (name == "EFTTypeId")
			SettlementType = data != null ? data.ParameterDesc : "";
		model[name] = newValue;
		this.setState({ model, SettlementType });
	}

	SelectedRowChange = (index) => {
		const tempModel = this.state.settlementFileList[index];
		const model = { ...this.state.model };
		if (tempModel.Id == null || tempModel.Id == undefined)
			this.showBasicAlert("Error", "FileInformationId can not be null", "error");
		else {
			model.ClientId = tempModel.ClientId;
			this.setState({ model, isLoading: true, rowIndex: index, headerModel: tempModel });
			this.GetTransactionList(tempModel.Id);
		}
	}

	GetSettlementFileList = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/WireTransfer/WireTransferSettlementFileSearch",
			this.state.model,
			this.successSettlementFileList,
			error => {
				this.showBasicAlert("Error", "An error occurred during the api visit" + error, "error");
			}
		);
	}

	successSettlementFileList = (responseData) => {
		if (!responseData.IsSucceeded) {
			this.showBasicAlert("Error", responseData.ErrorDescription, "error");
			return;
		}
		this.setState({
			transactionList: [], headerModel: {},
			settlementFileList: responseData.Item == null ? [] : responseData.Item.length < 1 ? [] : responseData.Item, isLoading: false
		});
	}

	GetTransactionList = (Id) => {
		var getTransactionByFileIdModel = { ...this.state.getTransactionByFileIdModel };
		getTransactionByFileIdModel.FileId = Id;
		getTransactionByFileIdModel.ClientId = this.state.model.ClientId == null ? null : this.state.model.ClientId;
		Proxy.POST(
			"/bankapi/v1.0/WireTransfer/WireTransferSettlementTransactionSearch",
			getTransactionByFileIdModel,
			this.successTransactionListCallback,
			error => {
				this.showBasicAlert("Error", "An error occurred during the api visit" + error, "error");
			}
		);
	}

	successTransactionListCallback = (responseData) => {
		var headerModel = { ...this.state.headerModel };
		var transactionList = { ...this.state.transactionList };
		if (!responseData.IsSucceeded) {
			this.showBasicAlert("Error", responseData.ErrorDescription, "error");
			return;
		}
		if (responseData.Item != null) {
			transactionList = responseData.Item;
		}
		this.setState({ transactionList, headerModel, isLoading: false });
	}

	showBasicAlert = (title, message, type) => {
		this.setState({ isLoading: false, alert: AlertHelper.CreateAlert(title, message, type, this.hideAlert) });
	}

	hideAlert = () => { this.setState({ alert: null, isLoading: false }); }

	showLoading = () => { this.setState({ alert: null, isLoading: true }); }

	ClearModel = () => {
		this.setState({
			model: {
				FromDate: DateHelper.GetDateTime(), ToDate: DateHelper.GetDateTime(),
			}
		});
	}

	render() {
		const { Disabled } = this.props;
		const { model, headerModel, isLoading } = this.state;
		return (
			<div>
				<LoadingComponent Show={isLoading} />
				{this.state.alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.GetSettlementFileList, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.ClearModel, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer>
					<GridItem xs={3}>
						<Card style={{ height: "90%" }}>
							<CardHeader>
								<GenericTitle text={" File Filter"} />
							</CardHeader>
							<CardBody>
								<GenericDateInput
									Name="FromDate"
									LabelText="From Date"
									Value={model.FromDate}
									ValueChanged={this.handleChange}
									MaxDate={model.ToDate || DateHelper.GetDate()}
									IncludeTime={false}
									Utc />
								<GenericDateInput
									Name="ToDate"
									LabelText="To Date"
									Value={model.ToDate}
									ValueChanged={this.handleChange}
									MinDate={model.FromDate}
									MaxDate={DateHelper.GetDate()}
									IncludeTime={false}
									Utc />
							</CardBody>
						</Card>

						<br /> <br />
					</GridItem>
					<GridItem xs={9}>
						<Card style={{ height: "90%" }}>
							<CardHeader>
								<GenericTitle text={"BAI2 File Information"} />
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={6}>
										<GenericDateInput
											Name="FileCreationDateTime"
											LabelMd={6}
											LabelText="File Date"
											Value={headerModel == null ? "" : headerModel.FileCreationDateTime ? Formatter.FormatDateUtc(headerModel.FileCreationDateTime) : ""}
											Disabled={true}
											DateFormat="DD/MMM/YY"
											Utc />
										<GenericNumberInput
											Name="SummaryTotalCredits"
											LabelMd={6}
											LabelText="Summary Total Credits"
											Value={headerModel == null ? "" : headerModel.SummaryTotalCredits}
											Disabled={true}
											DecimalScale={0} />
										<GenericNumberInput
											Name="SummaryTotalCreditsAmount"
											LabelText="Summary Total Credits Amount"
											LabelMd={6}
											Prefix="$"
											Value={headerModel == null ? "" : headerModel.SummaryTotalCreditsAmount ? headerModel.SummaryTotalCreditsAmount : ""}
											Disabled={true}
											DecimalScale={0} />
										<GenericTextInput
											Name="TotalNumberOfCredits"
											LabelText="Total Number Of Credits"
											LabelMd={6}
											Value={headerModel == null ? "" : headerModel.TotalNumberOfCredits ? headerModel.TotalNumberOfCredits : ""}
											Disabled={true} />
									</GridItem>
									<GridItem xs={6}>
										<GenericDateInput
											Name="FileCreationDateTime"
											LabelText="File Time"
											LabelMd={6}
											Value={headerModel == null ? "" : headerModel.FileCreationDateTime ? Formatter.FormatTime(headerModel.FileCreationDateTime) : ""}
											DateFormat="hh:mm:ss A"
											IncludeTime={false}
											Disabled={true} />
										<GenericNumberInput
											Name="SummaryTotalDebits"
											LabelText="Summary Total Debits"
											LabelMd={6}
											Value={headerModel == null ? "" : headerModel.SummaryTotalDebits ? headerModel.SummaryTotalDebits : ""}
											Disabled={true} />
										<GenericNumberInput
											Name="SummaryTotalDebitsAmount"
											LabelText="Summary Total Debits Amount"
											LabelMd={6}
											Value={headerModel == null ? "" : headerModel.SummaryTotalDebitsAmount ? headerModel.SummaryTotalDebitsAmount : ""}
											Prefix="$"
											Disabled={true} />
										<GenericNumberInput
											Name="TotalNumberOfDebits"
											LabelText="Total Number Of Debits"
											LabelMd={6}
											Value={headerModel == null ? "" : headerModel.TotalNumberOfDebits ? headerModel.TotalNumberOfDebits : ""}
											Disabled={true} />

									</GridItem>
								</GridContainer>
							</CardBody>
						</Card><br />
					</GridItem>
				</GridContainer>
				<Card className="no-radius">
					<CardBody>
						<CardHeader>
							<GenericTitle text={"BAI2 File List"} />
						</CardHeader>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={this.state.settlementFileList}
									Columns={[
										{
											Header: "File Name",
											accessor: "FileName"
										},
										{
											Header: "File Date",
											accessor: "FileCreationDateTime",
											type: GridColumnType.DateUtc
										},
										{
											Header: "Sender Identification",
											accessor: "SenderIdentification"
										},
										{
											Header: "Receiver Identification",
											accessor: "ReceiverIdentification"
										},
										{
											Header: "File Identification Number",
											accessor: "FileIdentificationNumber"
										},
										{
											Header: "Physical Record Length",
											accessor: "PhysicalRecordLength"
										},
										{
											Header: "Version Number",
											accessor: "VersionNumber"
										},
										{
											Header: "Ultimate Receiver Id",
											accessor: "UltimateReceiverId"
										},
										{
											Header: "Originator Identification",
											accessor: "OriginatorIdentification"
										},
										{
											Header: "Group Status",
											accessor: "GroupStatus"
										},
										{
											Header: "As Of DateTime",
											accessor: "AsOfDateTime",
											type: GridColumnType.DateUtc
										},
										{
											Header: "As Of Date Modifier",
											accessor: "AsOfDateModifier"
										},
										{
											Header: "Currency Code",
											accessor: "CurrencyCode"
										},
										{
											Header: "Customer Wallet Number",
											accessor: "CustomerAccountNumber"
										},
										{
											Header: "Wallet Identifier Currency Code",
											accessor: "AccountIdentifierCurrencyCode"
										},
										{
											Header: "Summary Total Credits",
											accessor: "SummaryTotalCredits"
										},
										{
											Header: "Summary Total Credits Amount",
											accessor: "SummaryTotalCreditsAmount"
										},
										{
											Header: "Total Number Of Credits",
											accessor: "TotalNumberOfCredits"
										},
										{
											Header: "Summary Total Debits",
											accessor: "SummaryTotalDebits"
										},
										{
											Header: "Summary Total Debits Amount",
											accessor: "SummaryTotalDebitsAmount"
										},
										{
											Header: "Total Number Of Debits",
											accessor: "TotalNumberOfDebits"
										},
										{
											Header: "Wallet Control Total",
											accessor: "AccountControlTotal"
										},
										{
											Header: "Number Of Records",
											accessor: "NumberOfRecords"
										},
										{
											Header: "Group Control Total",
											accessor: "GroupControlTotal"
										},
										{
											Header: "Number Of Accounts",
											accessor: "NumberOfAccounts"
										},
										{
											Header: "Wallet Trailer Number Of Records",
											accessor: "AccountTrailerNumberOfRecords"
										},
										{
											Header: "File Control Total",
											accessor: "FileControlTotal"
										},
										{
											Header: "Number Of Groups",
											accessor: "NumberOfGroups"
										},
										{
											Header: "Group Trailer Number Of Records",
											accessor: "GroupTrailerNumberOfRecords"
										}
									]}
									RowSelected={index => {
										this.SelectedRowChange(index);
									}}
									PageSize={10}
									ShowPagination={true}
									SelectedIndex={this.state.rowIndex} />
								<br /> <br />
								<GenericTitle text={"Transaction List"} />
								<GenericGrid
									Data={this.state.transactionList}
									PageSize={10}
									ShowPagination={true}
									Columns={[
										{
											Header: "Transaction Date",
											accessor: "ValueDateTime",
											type: GridColumnType.DateUtc,
											ColumnType: GridColumnType.DateUtc
										},
										{
											Header: "BAI Type Code",
											accessor: "BAITypeCode"
										},
										{
											Header: "Amount",
											accessor: "Amount",
											type: GridColumnType.Money,
											ColumnType: GridColumnType.Money
										},
										{
											Header: "Funds Type",
											accessor: "FundsType"
										},
										{
											Header: "Bank Reference Number",
											accessor: "BankReferenceNumber"
										},
										{
											Header: "Customer Reference Number",
											accessor: "CustomerReferenceNumber"
										},
										{
											Header: "Description",
											accessor: "Description"
										},
										{
											Header: "Client Long Name",
											accessor: "ClientLongName"
										},
										{
											Header: "Sundry Information",
											accessor: "SundryInformation"
										},
										{
											Header: "Cross Reference",
											accessor: "CrossReference"
										},
										{
											Header: "Reference From Incoming Wire",
											accessor: "ReferenceFromIncomingWire"
										},
										{
											Header: "First Ordering Customer",
											accessor: "FirstOrderingCustomer"

										},
										{
											Header: "Second Ordering Customer",
											accessor: "SecondOrderingCustomer"
										},
										{
											Header: "Third Ordering Customer",
											accessor: "ThirdOrderingCustomer"
										},
										{
											Header: "Forth Ordering Customer",
											accessor: "ForthOrderingCustomer"
										},
										{
											Header: "Fifth Ordering Customer",
											accessor: "FifthOrderingCustomer"
										},
										{
											Header: "First Sender To Receiver Information",
											accessor: "FirstSenderToReceiverInformation"
										},
										{
											Header: "Second Sender To Receiver Information",
											accessor: "SecondSenderToReceiverInformation"
										},
										{
											Header: "Third Sender To Receiver Information",
											accessor: "ThirdSenderToReceiverInformation"
										},
										{
											Header: "Forth Sender To Receiver Information",
											accessor: "ForthSenderToReceiverInformation"
										},
										{
											Header: "Fifth Sender To Receiver Information",
											accessor: "FifthSenderToReceiverInformation"
										},
										{
											Header: "Sixth Sender To Receiver Information",
											accessor: "SixthSenderToReceiverInformation"
										}
									]} />
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>
			</div>
		);
	}
}

BAI2File.propTypes = {
	classes: PropTypes.object,
	handleOperationType: PropTypes.func,
	showQuestionMessage: PropTypes.func,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(BAI2File, {});