import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes, { bool, func } from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDateInput, GenericGrid, GenericNumberInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { Direction, GridColumnType, ProgramCodes } from "views/Constants/Constant";

class ImportCardEmbossFileList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			model: {
				FromDate: DateHelper.GetDate(),
				ToDate: DateHelper.GetDate(),
				DirectionParameterValue: ""
			},
			getTransactionByFileIdModel: {
				DirectionParameterValue: ""
			},
			dailyFileList: [],
			fileInfoModel: {},
			headerModel: {},
			cardList: [],
			isLoading: false
		};
		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined
		};

		this.IsImportScreen = false;
		this.IsExportScreen = false;

		this.HeaderColumns = [
			{
				Header: "File Name",
				accessor: "FileName"
			},
			{
				Header: "File Date",
				accessor: "FileDate",
				type: GridColumnType.DateUtc
			},
			{
				Header: "Record Count",
				accessor: "RecordCount"
			},
			{
				Header: "File Status",
				accessor: "SendStatus"
			}
		];

		this.DetailColumns =
			[
				{
					Header: "Create Date",
					accessor: "InsertDateTime",
					type: GridColumnType.DateTimeUtc
				},
				{
					Header: "Card Number",
					accessor: "CardNumber"
				},
				{
					Header: "Card Id",
					accessor: "CardId"
				},
				{
					Header: "Emboss Status",
					accessor: "EmbossStatusName",
				},
				{
					Header: "Client",
					accessor: "ClientName"
				},
				{
					Header: "Customer Name",
					accessor: "CustomerName"
				},
				
				{
					Header: "Product Name",
					accessor: "ProductName"
				},
				{
					Header: "Emboss Name",
					accessor: "EmbossName1"
				},
				{
					Header: "Usage Limit",
					accessor: "CardUsageLimit",
					type: GridColumnType.Money
				},
				{
					Header: "Issuing Reason Type",
					accessor: "IssuingReasonType",
				},
				
				{
					Header: "Plastic Type",
					accessor: "PlasticTypeDesc",
				},
				{
					Header: "Product Brand",
					accessor: "ProductBrand",
				},
				{
					Header: "Bin Description",
					accessor: "BinDesc",
				}
				,
				{
					Header: "Is No Name Card",
					accessor: "IsNoNameCard",
					Cell: row => (
						<div>{row.value == true ? "Yes" : "No"}</div>
					)
				}
			];
	}

	componentDidMount() {

		this.props.setAppLeftTitle("Import Card Emboss File");
		this.props.setAppCenterTitle("CLEARING & SETTLEMENT");

		var model = { ...this.state.model };
		model.DirectionParameterValue = Direction.Outgoing;
		var getTransactionByFileIdModel = { ...this.state.getTransactionByFileIdModel };
		getTransactionByFileIdModel.DirectionParameterValue = Direction.Outgoing;
		this.setState({ getTransactionByFileIdModel, model });
	}

	handleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}

	SelectedRowChange = (index) => {
		const tempModel = this.state.dailyFileList[index];
		const model = { ...this.state.model };
		if (tempModel.FileInformationId == null || tempModel.FileInformationId == undefined)
			this.props.showMessage("Error", "File can not be null", "error");
		else {
			model.ClientId = tempModel.UniqueClientId;
			this.setState({ fileInfoModel: tempModel, model, isLoading: true, rowIndex: index });
			this.GetCardList(tempModel.FileInformationId);

		}
	}

	GetDailyFileList = async () => {
		this.setState({ isLoading: true });
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/prepaidapi/v1.0/CardEmbossFile/GetImportCardEmbossFileList", this.state.model);
		if (result) {
			this.setState({
				cardList: [], headerModel: {},
				dailyFileList: result, isLoading: false
			});
		}
	}


	GetCardList = async (Id) => {
		const { ExecuteApiPost } = this.props;

		var getTransactionByFileIdModel = { ...this.state.getTransactionByFileIdModel };
		getTransactionByFileIdModel.FileInformationId = Id;
		getTransactionByFileIdModel.ClientId = this.state.model.UniqueClientId == null ? null : this.state.model.UniqueClientId;

		var result = await ExecuteApiPost("/prepaidapi/v1.0/CardEmbossFile/GetImportCardEmbossFileDetailsByFileId", getTransactionByFileIdModel);
		if (result) {
			this.setState({ cardList: result.CardRequestDataList, headerModel: result.CardEmbossHeaderDetail, isLoading: false });
		}

	}
	GetImportFile = async () => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/prepaidapi/v1.0/CardEmbossFile/ImportCardEmbossFile");
		if (result) {
			this.GetDailyFileList();
		}
	}

	ClearModel = () => {
		this.setState({
			model: {
				FromDate: DateHelper.GetDate(),
				ToDate: DateHelper.GetDate(),
			}
		});
	}
	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}

	render() {
		const { Disabled } = this.props;
		const { model, headerModel, isLoading } = this.state;
		return (
			<div>
				<LoadingComponent Show={isLoading} />
				{this.state.alert}
				<GridContainer>
					<GridItem xs={12}>
						<ButtonToolbar ButtonList={[
							{ Code: "Search", OnClick: this.GetDailyFileList, Disabled: Disabled },
							{ Code: "Import",	 OnClick: this.GetImportFile, Disabled: Disabled },
							{ Code: "Clear", OnClick: this.ClearModel, Disabled: Disabled }
						]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

					</GridItem>
				</GridContainer>
				<GridContainer>
					<GridItem xs={4}>
						<Card style={{ height: "90%" }}>
							<CardHeader>
								<GenericTitle text={" File Filter"} />
							</CardHeader>
							<CardBody>
								<GenericDateInput
									Utc
									Name="FromDate"
									LabelText="From Date"
									Value={model.FromDate == null || undefined ? "" : model.FromDate}
									ValueChanged={this.handleChange}
									MaxDate={model.ToDate || DateHelper.GetDate()}
									IncludeTime={false} />
								<GenericDateInput
									Utc
									Name="ToDate"
									LabelText="To Date"
									Value={model.ToDate == null || undefined ? "" : model.ToDate}
									ValueChanged={this.handleChange}
									MinDate={model.FromDate == null ? null : model.FromDate}
									MaxDate={DateHelper.GetDate()}
									IncludeTime={false} />

							</CardBody>
						</Card>

					</GridItem>
					<GridItem xs={8}>
						<Card style={{ height: "90%" }}>
							<CardHeader>
								<GenericTitle text={"File Information"} />
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={6}>
										<GenericDateInput
											Utc
											Name="FileDate"
											LabelMd={6}
											LabelText="File Date"
											Value={headerModel == null || undefined ? "" : headerModel.FileDate ? Formatter.FormatDateUtc(headerModel.FileDate) : ""}
											Disabled={true}
											DateFormat="DD/MMM/YY" />

										<GenericNumberInput
											Name="TotalNumber"
											LabelMd={6}
											LabelText="Total Number"
											Value={headerModel == null || undefined ? "" : headerModel.TotalNumber ? headerModel.TotalNumber : ""}
											Disabled={true}
											DecimalScale={0} />

									</GridItem>
									<GridItem xs={6}>

										<GenericDateInput
											Name="FileDate"
											LabelText="File Time"
											LabelMd={6}
											Value={headerModel == null || undefined ? "" : headerModel.FileDate ? Formatter.FormatTimeUtc(headerModel.FileDate) : ""}
											DateFormat="HH:mm:ss"
											IncludeTime={false}
											Utc
											Disabled={true} />

									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
				<Card className="no-radius">
					<CardBody>
						<CardHeader>
							<GenericTitle text={"Card Emboss File List"} />
						</CardHeader>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={this.state.dailyFileList}
									Columns={this.HeaderColumns}
									RowSelected={index => { this.SelectedRowChange(index); }}
									SelectedIndex={this.state.rowIndex}
									ProgramCode={ProgramCodes.Prepaid}
									ShowPagination={true}
								/>
								<br /> <br />
								<h5><b>Card List</b></h5> <br />
								<GenericGrid
									Data={this.state.cardList}
									Columns={this.DetailColumns}
									ProgramCode={ProgramCodes.Prepaid}
									ShowPagination={true}
								/>
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>
			</div>
		);
	}
}

ImportCardEmbossFileList.propTypes = {
	classes: PropTypes.object,
	handleOperationType: PropTypes.func,
	showQuestionMessage: func,
	setAppLeftTitle: func,
	setAppCenterTitle: func,
	Disabled: bool
};

export default withArtifex(ImportCardEmbossFileList, {});