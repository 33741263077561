import menuBarStyle from "assets/jss/material-dashboard-pro-react/views/menuBarStyle.jsx";
import { withArtifex } from "core";
import ResourceHelper from "core/ResourceHelper";
import PropTypes from "prop-types";
import Menu, { MenuItem, SubMenu } from "rc-menu";
import React from "react";

const fullWidth = { width: "100%" };
class MenuBar extends React.PureComponent {
	constructor(props) {
		super(props);

		this.PopulateMenu = this.PopulateMenu.bind(this);
	}

	PopulateMenu(routes) {
		const { classes, history } = this.props;

		if (routes == null) return null;

		return routes.map(x => {
			if (x.redirect) {
				return null;
			}

			var menuName = ResourceHelper.GetForMenu(x.Name) || "! " + x.Name;

			if (x.Collapse) {
				return (
					<SubMenu key={x.Id} className={classes.subMenu} popupClassName={classes.popupMenu}
						title={<span className="submenu-title-wrapper">{menuName}</span>}>
						{this.PopulateMenu(x.Views)}
					</SubMenu>
				);
			}
			else {
				return (
					<MenuItem key={x.Id} className={classes.menuItem} onClick={() => history.push(x.Path)}>
						{menuName}
					</MenuItem>
				);
			}
		});
	}

	render() {
		const { classes, routes } = this.props;

		return (
			<div style={fullWidth}>
				<Menu
					mode="horizontal"
					openAnimation="slide-up"
					overflowedIndicator={<span>MORE {">>"}</span>}
					className={classes.menu}
					selectable={false}
				>
					{this.PopulateMenu(routes)}
				</Menu>
			</div>
		);
	}
}

MenuBar.propTypes = {
	classes: PropTypes.object.isRequired,
	routes: PropTypes.array
};

export default withArtifex(MenuBar, menuBarStyle);