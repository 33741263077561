import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ClientHelper from "core/ClientHelper.js";
import { ETransferIcon, ProfileIcon } from "core/Icons";
import RouteHelper from "core/RouteHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import CustomerComponent from "views/Components/CustomerComponent";
import {
	GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel,
	GenericGrid, GenericLabel, GenericSelectInput, GenericTextInput
} from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import { GridColumnType } from "views/Constants/Constant";
import ApplicantDefinition from "views/LOS/ApplicantDefinition/ApplicantDefinition";

class BorrowerList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			model: {
				CustomerId: 0,
				CustomerNumber: "",
				UniqueClientId: 0,
				AccountNumber:null,
				Email:null
			},
			openDetailModel: false,
			ApplicantId: 0,
			isBackOffice: ClientHelper.IsBackOffice()
		};

		this.columns = [
			{
				Header: "Date",
				accessor: "InsertDateTime",
				type: GridColumnType.Date
			},
			{
				Header: "Client Name",
				accessor: "UniqueClient.ClientName"
			},
			{
				Header: "Unique Client Id",
				accessor: "UniqueClient.UniqueClientId"
			},
			{
				Header: "Full Name",
				accessor: "FullName"
			},
			{
				Header: "DCBank Unique ID",
				accessor: "ApplicantUniqueId",
			},
			{
				Header: "Wallet No",
				accessor: "AccountNumber"
			},
			{
				Header: "Email",
				accessor: "Email"
			},
			{
				Header: "Phone",
				Cell: row => (
					<span>{row.original.MobilePhoneCountryCode == null ? "" : "+" + row.original.MobilePhoneCountryCode.Code + " " + row.original.MobilePhoneNumber}</span>
				)
			},
			{
				Header: "Date of birth",
				accessor: "BirthDate",
				type: GridColumnType.Date
			},
			{
				Header: "External Wallet Number",
				accessor: "ExternalAccountNumber"
			}
		];

		this.urlApplicant = "/losapi/v1.0/Applicant/";
		if (!this.state.isBackOffice) {
			this.columns.unshift(
				{
					Header: "Actions",
					accessor: "Actions",
					Cell: row => (
						<div>
							<GridButton
								tooltip="Payment Admin"
								Icon={ETransferIcon}
								OnClick={() => RouteHelper.Push(this.props.history, "/lmsPaymentAdmin", "LmsPaymentAdminApplicantId", row.original.Id)} />
							<GridButton
								tooltip="Applicant Details"
								Icon={ProfileIcon}
								OnClick={() => this.setState({ ApplicantId: row.original.Id, openDetailModel: true })} />
						</div>
					),
					// @ts-ignore
					width: 70,
					filterable: false,
					sortable: false
				}
			);
		}


		this.ValueChangedCustomer = this.ValueChangedCustomer.bind(this);
		this.Search = this.Search.bind(this);
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("LOAN MANAGEMENT SYSTEM");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("Borrower List");
		}
	}

	ValueChanged = (name, value) => {
		this.setState(state => {
			var model = state.model;
			model[name] = value;
			if (name == "UniqueClientId" && value == null) {
				model[name] = 0;
			}
			return { model };
		});
	}

	async ValueChangedCustomer(customer) {
		const model = { ...this.state.model };

		if (customer == null) {
			model.CustomerId = 0;
			model.CustomerNumber = "";
			model.AccountNumber = null;
			model.Email = null;

			this.setState({ model });

			return;
		}

		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost(`${this.urlApplicant}GetAccountByCustomerId`, { CustomerId: customer.Id });

		if (result != null) {
			model.AccountNumber = result.AccountNumber;
			model.CustomerId = customer.Id;
			model.CustomerNumber = customer.CustomerNumber;
			model.Email = customer.Email;

			this.setState({ model });
		}
	}

	async Search() {
		const { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost(`${this.urlApplicant}Search`, model);

		this.setState({ list: result || [] });
	}

	Clear = () => {
		this.setState({
			model: {
				CustomerId: 0,
				CustomerNumber: "",
				UniqueClientId: 0
			}
			, list: []
			, selected: null
		});
	}
	RenderItemUniqueClient = (d) => {
		return `${d.UniqueClientId} - ${d.Name}`;
	}

	render() {
		const { model, list, selected, openDetailModel, ApplicantId, isBackOffice } = this.state;
		const { Disabled } = this.props;

		var uniqueClientId = ClientHelper.GetClientRowId();

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GenericDialog open={openDetailModel} fullScreen >
					<DialogTitle>
						<GenericLabel FontSize="16px" Text="Applicant Detail" Bold={true} />
					</DialogTitle>
					<DialogContent>
						<ApplicantDefinition ApplicantId={ApplicantId} ExecuteApiPost={this.props.ExecuteApiPost} />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={() => this.setState({ openDetailModel: false })}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Borrower List">
									<GridContainer>
										{isBackOffice && (
											<GridItem xs={4} >
												<GenericSelectInput
													Name="UniqueClientId"
													Method="POST"
													LabelText={"Client"}
													Url="/bankapi/v1.0/BankCustomer/Search"
													Parameter={{}}
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={this.RenderItemUniqueClient}
													Value={model.UniqueClientId}
													ValueChanged={this.ValueChanged}
													CanClear
													All
													Disabled={!isBackOffice}
													DefaultIndex={isBackOffice ? 0 : -1}
												/>
											</GridItem>
										)}
										<GridItem xs={4}>
											<GenericTextInput
												Name="FirstName"
												LabelText="Borrower Name"
												Value={model.FirstName}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={4}>
											<CustomerComponent
												ShowCustomerProgram
												Disabled={uniqueClientId == null}
												UniqueClientId={uniqueClientId}
												CustomerNumber={model.CustomerNumber}
												FillCallback={this.ValueChangedCustomer}
												HandleChange={this.ValueChanged} />
										</GridItem>
										<GridItem xs={4}>
											<GenericDateInput
												Name="InsertDateTime"
												LabelText="Date"
												IncludeTime={false}
												Value={model.InsertDateTime}
												ValueChanged={this.ValueChanged} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columns}
													SelectedIndex={selected} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

BorrowerList.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default BorrowerList;