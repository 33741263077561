import { DialogContent, DialogTitle } from "@material-ui/core";
import ChequeManagementStyle from "assets/jss/material-dashboard-pro-react/views/ChequeManagementStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import Formatter from "core/Formatter";
import { DetailIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import { Chart, Doughnut as DoughnutChart } from "react-chartjs-2";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericSelectInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton.jsx";
import LoadingComponent from "views/Components/LoadingComponent.jsx";
import ParameterComponent from "views/Components/ParameterComponent";
import { GridColumnType } from "views/Constants/Constant";

const chartStyles = ({
	circleBox: {
		display: "inline-block",
		marginTop: "24px",
		marginLeft: "auto",
		marginRight: "auto",
		marginBottom: "24px"
	},
	circleBoxGrid: {
		width: "100%",
		textAlign: "center"
	}
});

class ChequeClearningSummary extends React.Component {
	constructor(props) {
		super(props);

		const { history } = this.props;

		this.chartDataContent = {
			labels: [""],
			datasets: [{
				data: [100],
				backgroundColor: "rgb(255, 99, 132)",
				hoverBackgroundColor: "rgb(255, 99, 132)"
			}],
			text: ""
		};

		this.chartOptions = {
			maintainAspectRatio: true,
			responsive: false,
			legend: {
				position: "bottom"
			},
			cutoutPercentage: 90,
			tooltips: {
				callbacks: {
					label: function (tooltipItem, data) {
						var dataset = data.datasets[tooltipItem.datasetIndex];
						var total = dataset.data.reduce(function (previousValue, currentValue, currentIndex, array) {
							return previousValue + currentValue;
						});
						var currentValue = dataset.data[tooltipItem.index];
						var percentage = Math.floor(((currentValue / total) * 100) + 0.5);

						return percentage + "%";
					}
				}
			}
		};

		this.chartColors = {
			first: "rgb(104, 16, 35)",
			second: "rgb(251, 76, 35)",
		};

		this.columnSearchDetail = [
			{
				Header: "Actions",
				accessor: "Actions",
				sortable: false,
				filterable: false,
				width: 60
				// show: !propIsBackoffice
			},
			{
				Header: "Unique Client Id",
				accessor: "UniqueClientId"
			},
			{
				Header: "Cheque Serial Number",
				accessor: "ChequeSerialNumber"
			},
			{
				Header: "Date",
				accessor: "Date",
				type: GridColumnType.Date
			},

			{
				Header: "Cheque Wallet",
				accessor: "ChequeAccount"
			},
			{
				Header: "Deposit Wallet",
				accessor: "DepositAccount"
			},
			{
				Header: "Transaction Status",
				accessor: "TransactionStatus"
			},
			{
				Header: "Amount",
				accessor: "Amount",
				type: GridColumnType.Money,
				ColumnType:GridColumnType.Money
			},

			// {
			// 	Header: "Posting Item Sequence Number ",
			// 	accessor: "PostingItemSequenceNumber"
			// },
			// {
			// 	Header: "Posting Wallet Routing Number",
			// 	accessor: "PostingAccountRoutingNumber"
			// }

			// {
			// 	Header: "Number of Transaction",
			// 	accessor: "TransactionCount"
			// },
			// {
			// 	Header: "Transaction Total",
			// 	accessor: "TransactionTotal"
			// }
		];


		this.emptyObject = {};

		this.renderItemUniqueClient = {};

		this.sortedName = { Member: "Name" };

		this.state = {
			model: {},
			searchDetail: [],
			graphicDetail: [],
			transactionDetail: [],
			selected: null,
			isLoading: false,
			chqimage: {},
			openDialog: false,
			totalchqinfomodel: {},
			chartData: [this.chartDataContent, this.chartDataContent, this.chartDataContent, this.chartDataContent, this.chartDataContent]
		};
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Cheque Clearing Summary");
		this.props.setAppCenterTitle("CHEQUE MANAGEMENT");

		var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
		Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
			draw: function () {
				originalDoughnutDraw.apply(this, arguments);

				var chart = this.chart;
				var width = chart.chart.width,
					height = chart.chart.height,
					ctx = chart.chart.ctx;

				var fontSize = 1.5;
				ctx.font = fontSize + "em sans-serif";
				ctx.textBaseline = "middle";
				ctx.fillStyle = "#000";

				var text = chart.config.data.text,
					textX = Math.round((width - ctx.measureText(text).width) / 2),
					textY = height / 2;

				ctx.fillText(text, textX, textY);
			}
		});
	}

	ValueChanged = (name, value) => {
		this.setState(state => {
			var model = state.model;
			model[name] = value;

			return { model };
		});
	}

	RowSelected = (index) => {
		const { searchDetail } = this.state;

		this.setState({ transactionDetail: searchDetail[index].TransactionDetail, selected: index });
	}

	GetRenderItemUniqueClient = (x) => {
		this.renderItemUniqueClient = `${x.Name} - ${x.UniqueClientId}`;

		return this.renderItemUniqueClient;
	}

	Search = () => {
		if (!this.Validate()) {
			return;
		}

		this.setState({ isLoading: true });

		const { model } = this.state;
		const { showMessage } = this.props;

		Proxy.POST(
			"/chequeapi/v1.0/Cheque/ChequeClearingSummaryList",
			{
				"FromDate": model.FromDate || null,
				"ToDate": model.ToDate || null,
				"UniqueClientId": model.UniqueClientId || -1,
				"LastPeriodId": model.LastPeriodId || -1,
				"TransactionStatusId": model.TransactionStatusId


			},
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				var searchDetail = [], graphicDetail = [],
					chartData = [this.chartDataContent, this.chartDataContent, this.chartDataContent, this.chartDataContent, this.chartDataContent];
				if (responseData.Item != null) {
					searchDetail = responseData.Item.SearchDetail || [];
					graphicDetail = responseData.Item.GraphicDetail || []
				}

				if (graphicDetail.length != 0 && graphicDetail[0].TransactionCount != 0) {
					var programCount = graphicDetail.filter(x => x.TransactionStatusId != 0 && x.TransactionStatusId != -1).length;
					chartData[0] = {
						labels: [graphicDetail[0].TransactionStatus],
						datasets: [{
							data: [graphicDetail[0].TransactionCount],
							backgroundColor: this.chartColors.first,
							hoverBackgroundColor: this.chartColors.first
						}],
						text: graphicDetail[0].TransactionCount
					};
					for (var i = 1; i <= programCount; i++) {
						chartData[i] = {
							labels: [graphicDetail[programCount + 1].TransactionStatus, graphicDetail[i].TransactionStatus],
							datasets: [{
								data: [graphicDetail[0].TransactionCount - graphicDetail[i].TransactionCount, graphicDetail[i].TransactionCount],
								hoverBackgroundColor: [this.chartColors.second, this.chartColors.first],
								backgroundColor: [this.chartColors.second, this.chartColors.first],
							}],
							text: graphicDetail[i].TransactionCount
						};
					}
					chartData[programCount + 1] = {
						labels: ["Anothers", graphicDetail[programCount + 1].TransactionStatus],
						datasets: [{
							data: [graphicDetail[0].TransactionCount - graphicDetail[programCount + 1].TransactionCount, graphicDetail[programCount + 1].TransactionCount],
							hoverBackgroundColor: [this.chartColors.second, this.chartColors.first],
							backgroundColor: [this.chartColors.second, this.chartColors.first],
						}],
						text: graphicDetail[programCount + 1].TransactionCount
					};
				}

				this.setState({
					searchDetail: responseData.Item == null ? [] : responseData.Item.length < 1 ? [] : responseData.Item.SearchDetail.map(x => {
						return {
							Actions: (
								<div>
									<GridButton
										tooltip="Open Detail"
										Icon={DetailIcon}
										OnClick={() => { this.setState({ openDialog: true, chqimage: x }); }} />
								</div>
							),
							Date: x.Date == null || undefined ? "" : x.Date,
							UniqueClientId: x.UniqueClientId == null || undefined ? "" : x.UniqueClientId,
							ChequeAccount: x.ChequeAccount == null || undefined ? "" : x.ChequeAccount,
							TransactionStatus: x.TransactionStatus == null || undefined ? "" : x.TransactionStatus,
							DepositAccount: x.DepositAccount == null || undefined ? "" : x.DepositAccount,
							ECEInstitutionItemSequenceNumber: x.ECEInstitutionItemSequenceNumber == null || undefined ? "" : x.ECEInstitutionItemSequenceNumber,
							PostingAccountRoutingNumber: x.PostingAccountRoutingNumber == null || undefined ? "" : x.PostingAccountRoutingNumber,
							Amount: x.Amount == null || undefined ? 0 : x.Amount,
							ChequeSerialNumber: x.ChequeSerialNumber == null || undefined ? "" : x.ChequeSerialNumber,


						};
					}), graphicDetail, totalchqinfomodel: responseData.Item.ChequeClearingSummaryCalculation, transactionDetail: [], chartData, selected: null, isLoading: false
				});
			},
			(error) => {
				this.setState({ isLoading: false });
				showMessage("error", "An error occurred while requesting data", error.message);
			}
		);
	}

	Clear = () => {
		this.setState({
			model: {}, searchDetail: [], graphicDetail: [], transactionDetail: [],
			chartData: [this.chartDataContent, this.chartDataContent, this.chartDataContent, this.chartDataContent, this.chartDataContent],
			selected: null
		});
	}

	Validate() {
		const { model } = this.state;
		const { showMessageNode } = this.props;
		var messages = [];

		if (model.LastPeriodId && (model.FromDate || model.ToDate)) {
			messages.push("You can choose last or date filter same time.");
		}

		if (messages.length > 0) {
			if (messages.length > 9) {
				var moreWarn = messages.length - 8;
				messages = messages.slice(0, 8);
				messages.push("And " + moreWarn + " more warnings");
			}
			showMessageNode("error", "Please Fill Required Fields", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		return true;
	}
	handleClose = () => {
		this.setState({
			openDialog: false
		});
	}

	render() {
		const { model, searchDetail, transactionDetail, graphicDetail, selected, chartData, chqimage, totalchqinfomodel } = this.state;
		const { Disabled, history, classes } = this.props;

		var chartDataWithoutEmptyLength = chartData.filter(x => x.labels[0] != "").length;
		var chartKey = 1;

		return (
			<div>
				<LoadingComponent Show={this.state.isLoading} />
				<GenericDialog open={this.state.openDialog} maxWidth="md" fullWidth onBackdropClick={this.handleClose} >
					<DialogTitle><GenericLabel Text="Cheque Image Information" FontSize="20px" Bold={true} />
					</DialogTitle>
					<DialogContent >
						{<Card login>
							<div style={{ padding: "15px" }}>

								<img
									src={chqimage.ImageFrontData}
									style={{ width: "100%", height: "200px" }} />

							</div>
							<div style={{ padding: "15px" }}>
								<img
									src={chqimage.ImageRearData}
									style={{ width: "100%", height: "200px" }}
								/>

							</div>
						</Card>}

					</DialogContent>
					<GenericDialogActions>
						<Button onClick={this.handleClose}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<GenericExpansionPanel>
								<CardBody>
									<GridContainer>

										<GridItem xs={4}>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={this.emptyObject}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Name"
												RenderItem={this.GetRenderItemUniqueClient}
												Sorted={this.sortedName}
												All={true}
												Value={model.UniqueClientId}
												ValueChanged={this.ValueChanged} />
											<ParameterComponent
												Name="TransactionStatusId"
												Value={model.TransactionStatusId}
												LabelText="Transaction Status"
												ParameterCode="BankTransactionStatus"
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={4}>
											<GenericDateInput
												Name="FromDate"
												LabelText="From Date"
												IncludeTime={false}
												Value={model.FromDate}
												ValueChanged={this.ValueChanged} />
											<GenericDateInput
												Name="ToDate"
												LabelText="To Date"
												IncludeTime={false}
												Value={model.ToDate}
												ValueChanged={this.ValueChanged} />

										</GridItem>

										<GridItem xs={4}>
											<ParameterComponent
												Name="LastPeriodId"
												LabelText="Last"
												ParameterCode="ChequeLastPeriod"
												Value={model.LastPeriodId}
												ValueChanged={this.ValueChanged} />
										</GridItem>
									</GridContainer>
								</CardBody>
							</GenericExpansionPanel>
						</Card>
						<Card className="no-radius" style={{ display: graphicDetail.length == 0 || graphicDetail.length == 2 ? "none" : "inherit" }}>
							<CardBody>
								<GridContainer>
									<GridItem md={chartDataWithoutEmptyLength != 0 ? parseInt(((12 % chartDataWithoutEmptyLength) / 2).toString()) : 12} xs={12} />
									{
										chartData.filter(x => x.labels[0] != "").map(x => {
											chartKey++;
											return (
												<GridItem md={parseInt(((12 - (12 % chartDataWithoutEmptyLength)) / chartDataWithoutEmptyLength).toString())} xs={12} className={chartStyles.circleBoxGrid} key={chartKey}>
													<div className={chartStyles.circleBox}>
														<DoughnutChart
															data={x}
															width="lg"
															height={250}
															options={this.chartOptions} />
													</div>
												</GridItem>
											)
										})
									}
								</GridContainer>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={6}>
										<GenericLabel Bold={true} FontSize="12px" TextColor="Black" Text="Cheque Clearing Summary" />
									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={searchDetail}
													SelectedIndex={selected}
													Columns={this.columnSearchDetail}
													RowSelected={this.RowSelected} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
								<GridContainer >
									<GridItem xs={6} />
									<GridItem xs={6}>
										<GridContainer className={classes.containerHeight} justify="flex-end">
											<GridItem xs={2} className={classes.gridBorder}>
											</GridItem>
											<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
												<p className={classes.pt}>Amount</p>
											</GridItem>
											<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
												<p className={classes.pt}>Count</p>
											</GridItem>

										</GridContainer>
										<GridContainer justify="flex-end">
											<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold}>
												TOTAL
							</GridItem>

											<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>

												{totalchqinfomodel == null || undefined ? 0 : totalchqinfomodel.TotalAmount == null || undefined ? 0 : typeof totalchqinfomodel.TotalAmount == "number" ? Formatter.FormatMoney(totalchqinfomodel.TotalAmount) : totalchqinfomodel.TotalAmount}
											</GridItem>

											<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>

												{totalchqinfomodel == null || undefined ? 0 : totalchqinfomodel.TotalCount == null || undefined ? 0 : typeof totalchqinfomodel.TotalCount == "number" ? Formatter.FormatNumber(totalchqinfomodel.TotalCount) : totalchqinfomodel.TotalCount}
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>

					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

ChequeClearningSummary.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any,
	classes: PropTypes.object,
};
export default withArtifex(ChequeClearningSummary, ChequeManagementStyle);
