import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import { TaskPool } from "core/TaskPool";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericCheckInput, GenericExpansionPanel, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ClientPartnerAddress from "views/LOS/ClientPartner/ClientPartnerAddress.jsx";
import ClientPartnerContractDetails from "views/LOS/ClientPartner/ClientPartnerContractDetails.jsx";
import ClientPartnerInformation from "views/LOS/ClientPartner/ClientPartnerInformation.jsx";

const initialState = {
	model: {
		Id: 0,
		Customer: {},
		Account: {},
		AccountId: 0,
		CustomerId: 0,
		Email: ""
	},
	paramClientPartnerAddressList: [],
	paramClientPartnerContractDetailsList: [],
	paramClientPartnerAccountDefinitionAndDetailsList: [],
	isLoading: false,
	ReadOnly: false
};
class ClientPartner extends Component {
	constructor(props) {
		super(props);
		this.getappCenterTitle = "Empty";
		this.getappLeftTitle = "Empty";
		this.GetClientPartnerContractDetailsModels = this
			.GetClientPartnerContractDetailsModels
			.bind(this);
		this.HandleCustomerSelect = this.HandleCustomerSelect.bind(this);
		this.loadTaskPool = new TaskPool(this.handleLoadTaskPoolAppend, this.handleLoadTaskPoolCompleted);
		this.state = {
			vModel:{},
			model: initialState.model,
			paramClientPartnerAddressList: [],
			isLoading: false,
			ReadOnly: false
		};
		this.maxLength20 = {
			maxLength: 20
		};
		this.marginLeft2 = {
			marginLeft: 2
		};

		this.emptyObject = {};
		this.LoanTypeParameterCode = {
			ParameterCode: "LOSLoanType"
		};
	}

	componentDidMount() {
		this
			.props
			.setAppLeftTitle("Partner Definition");this.getappLeftTitle = "Partner Definition";
		this
			.props
			.setAppCenterTitle("LOAN ORIGINATOR SYSTEM");this.getappCenterTitle = "LOAN ORIGINATOR SYSTEM";
	}
	GetClientPartnerAddressModels = () => {
		this
			.loadTaskPool
			.AppendTask(Proxy.POST("/losapi/v1.0/ClientPartnerAddress/GetByClientPartnerId", {
				ClientPartnerId: this.state.model.Id
			}, responseData => {
				console.log("GetClientPartnerAddress ->", responseData.IsSucceeded);

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					this.setState({
						paramClientPartnerAddressList: responseData.Item || []
					});
				} else {
					this.setState({ paramClientPartnerAddressList: [] });
				}
			}, error => {
				this.ShowMessage("error", "Error", error);
			}));
	}

	handleLoadTaskPoolCompleted = () => {
		this.setState({ isLoading: false });
	}

	handleLoadTaskPoolAppend = () => {
		this.setState({ isLoading: true });
	}
	OperationCheck = () => {

		var ErrorList = [];
		const data = this.state.model;
		const {vModel}=this.state;
		if (data.LegalNameOfBusiness == null || data.LegalNameOfBusiness === "") {
			vModel.LegalNameOfBusiness=true;
			ErrorList.push("Legal name of business must be defined.");
		}
		else
		{
			vModel.LegalNameOfBusiness=false;
		}
		if (data.BusinessPhoneCountryCodeId == null || data.BusinessPhoneCountryCodeId === "") {
			vModel.BusinessPhoneCountryCodeId=true;
			ErrorList.push("Business phone country code must be defined.");
		}
		else
		{
			vModel.BusinessPhoneCountryCodeId=false;
		}
		if (data.BusinessPhoneNumber == null || data.BusinessPhoneNumber === "") {
			vModel.BusinessPhoneNumber=true;
			ErrorList.push("Business phone must be defined.");
		}
		else
		{
			vModel.BusinessPhoneNumber=false;
		}
		if (data.Email == null || data.Email === "") {
			vModel.Email=true;
			ErrorList.push("Email must be defined.");
		}
		else
		{
			vModel.Email=false;
		}
		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please Fill Required Fields", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		this.setState({vModel});
		this.UpdateModel();
	}

	hideAlertAndRefresh = () => {
		this.setState({ alert: null, isLoading: false });
		window
			.location
			.reload();

	}
	ShowMessageAndRefresh = (type, title, message) => {
		this.setState({
			alert: <GenericAlert
				Title={title}
				MessageNode={message}
				Type={type}
				OnConfirm={() => this.hideAlertAndRefresh()} />
		});
	}
	UpdateModel = () => {
		const model = this.state.model;
		this
			.loadTaskPool
			.AppendTask(Proxy.POST("/losapi/v1.0/clientpartner/InsertUpdate", model, responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ model: responseData.Item });
				if (model.Id > 0) {
					this.ShowMessage("success", "Success", "The Partner Successfully Updated");

				} else {
					this.ShowMessageAndRefresh("success", "Success", "The Partner Successfully Saved");
				}
			}, error => {
				this.ShowMessage("error", "Error", error);
			}));
	}
	GetClientPartnerContractDetailsModels = () => {
		this
			.loadTaskPool
			.AppendTask(Proxy.POST("/losapi/v1.0/ClientPartnerContractDetails/GetByClientPartnerId", {
				ClientPartnerId: this.state.model.Id
			}, responseData => {
				console.log("GetClientPartnerContractDetails ->", responseData.IsSucceeded);
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					this.setState({
						paramClientPartnerContractDetailsList: responseData.Item || []
					});
				} else {
					this.setState({ paramClientPartnerContractDetailsList: [] });
				}
			}, error => {
				this.ShowMessage("error", "Error", error);
			}));
	}
	HandleCustomerSelect = (customer) => {
		const model = { ...this.state.model };

		if (customer == null) {
			model.Customer = null;
			model.Account = null;
			model.Email = null;
			this.setState({ model });
			return;
		}
		this.loadTaskPool.AppendTask(
			Proxy.POST(
				"/losapi/v1.0/Applicant/GetAccountByCustomerId",
				{ CustomerId: customer.Id },
				responseData => {
					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					model.Account = responseData.Item != null ? responseData.Item : null;
					model.AccountId = model.Account.Id;
					model.Customer = customer;
					model.CustomerId = model.Customer.Id;
					model.Email = customer.Email;
					this.setState({ model });
				},
				error => {
					this.ShowMessage("error", "Error", error);
				}
			));
	}
	GetClientPartnerAccountDefinitionAndDetailsModels = () => {
		this
			.loadTaskPool
			.AppendTask(Proxy.POST("/losapi/v1.0/ClientPartnerAccountDefinitionAndDetails/GetByClientPartnerId", {
				ClientPartnerId: this.state.model.Id
			}, responseData => {
				console.log("GetClientPartnerAccountDefinitionAndDetails ->", responseData.IsSucceeded);
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				if (responseData.Item != null) {
					this.setState({
						paramClientPartnerAccountDefinitionAndDetailsList: responseData.Item || {}
					});
				} else {
					this.setState({ paramClientPartnerAccountDefinitionAndDetailsList: {} });
				}
			}, error => {
				this.ShowMessage("error", "Error", error);
			}));

	}
	HandleChange = (name, newValue, data) => {
		const model = {
			...this.state.model
		};
		model[name] = newValue;
		if (name === "isGenerateNew" && newValue == true) {
			this.ShowConfirmMessage("warning", "Warning", "This will erase the information you entered on the screen. Are you sure you want" +
				" to quit this client partner?",
				() => this.HandleCreateNew());
			return;
		}

		if (name === "Id") {
			this.HandleGetClientPartner(newValue);
		}
		this.setState({ model });
	}
	HandleCreateNew() {
		this.HideAlert();
		var newState = initialState;
		newState.model.isGenerateNew = true;

		this.setState({ model: newState.model });
	}
	HandleGetClientPartner = (id) => {
		let { model } = this.state;
		this
			.loadTaskPool
			.AppendTask(Proxy.POST("/losapi/v1.0/ClientPartner/GetById", {
				Id: id
			}, responseData => {
				console.log("GetClientPartner ->", responseData.IsSucceeded);
				if (responseData.Item != null) {
					model = responseData.Item.ClientPartner;
					this.setState({ model, paramClientPartnerAddressList: responseData.Item.ClientPartnerAddressList, paramClientPartnerContractDetailsList: responseData.Item.ClientPartnerContractDetailsList, paramClientPartnerAccountDefinitionAndDetailsList: responseData.Item.ClientPartnerAccountDefinitionAndDetailsList });
				}
			}, this.ErrorCallback));
	}
	HandleClickClearOk = () => {
		this.HideAlert();
		this.setState(initialState);
	}

	HandleClear = () => {
		this.ShowConfirmMessage("warning", "Warning", "This will erase the information you entered on the screen. Are you sure you want" +
			" to quit this client partner?",
			() => this.HandleClickClearOk());
	}
	ErrorCallback = (error) => {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", "An error occurred during the api visit" + error);
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert
				Title={title}
				Message={message}
				Type={type}
				OnConfirm={() => this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert
				Title={title}
				MessageNode={message}
				Type={type}
				OnConfirm={() => this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert
				Title={title}
				Message={message}
				Type={type}
				ShowCancel={true}
				OnCancel={() => this.setState({ alert: null })}
				OnConfirm={onConfirm} />
		});
	}

	HideAlert = () => {
		this.setState({ alert: null });
	}

	RefreshToolBar = () => {
		this.setState({
			LimitToken: !this.state.LimitToken
		});
	}

	render() {
		const { Disabled } = this.props;
		const {
			model,
			addressModel,
			tempModel,
			alert,
			isLoading,
			vModel,
		} = this.state;
		return (
			<div>

				<LoadingComponent Show={isLoading} />

				<ButtonToolbar
					ButtonList={[
						{
							Code: "Submit",
							OnClick: this.OperationCheck,
							Disabled: model.Id > 0
						}, {
							Code: "Update",
							OnClick: this.OperationCheck,
							Disabled: model.Id === 0
						}, {
							Code: "Clear",
							OnClick: this.HandleClear,
							Disabled: false
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After}
					IsPopUp={false} />

				<GridContainer>
					{alert}
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel IsActive={true}>
									<GridContainer>
										<GridItem
											xs={3}
											style={{
												opacity: model.isGenerateNew
													? 0.4
													: 1
											}}>
											<GenericSelectInput
												key={"Id"}
												Name="Id"
												LabelText="Partner Name"
												Method="POST"
												Url="/losapi/v1.0/ClientPartner/GetAll"
												Parameter={this.emptyObject}
												DataRoot="Item"
												TaskPoolDomain={this.loadTaskPool}
												KeyValueMember="Id"
												TextValueMember="LegalNameOfBusiness"
												Value={model.Id}
												ValueChanged={this.HandleChange}
												Disabled={model.isGenerateNew}
												 />
										</GridItem>
										<GridItem xs={3}>
											<GenericTextInput
												key={"PartnerUniqueId"}
												Name="PartnerUniqueId"
												LabelMd={5}
												LabelText="Partner Unique ID"
												Value={model.PartnerUniqueId == null || undefined
													? ""
													: model.PartnerUniqueId}
												ValueChanged={this.HandleChange}
												Disabled={model.isGenerateNew} />
										</GridItem>
										<GridItem xs={2}>
											<GenericCheckInput
												Name="isGenerateNew"
												LabelText="Create New"
												Value={model.isGenerateNew}
												ValueChanged={this.HandleChange} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<ClientPartnerInformation
									model={model}
									tempModel={tempModel}
									addressModel={addressModel}
									handleChange={this.HandleChange}
									HandleCustomerSelect={this.HandleCustomerSelect}
									handleAddressModel={this.HandleChange}
									Title="Partner Details"
									Disabled={Disabled}
									vModel={vModel} />
							</CardBody>
						</Card>
						<ClientPartnerAddress
							ClientPartnerModel={this.state.model}
							ClientPartnerAddressList={this.state.paramClientPartnerAddressList}
							GetClientPartnerAddressModels={this.GetClientPartnerAddressModels}
							Disabled={!model.Id || model.Id <= 0}
							getappCenterTitle={this.getappCenterTitle}
							getappLeftTitle={this.getappLeftTitle}
							/>
						<ClientPartnerContractDetails
							ClientPartnerModel={this.state.model}
							ClientPartnerContractDetailsList={this.state.paramClientPartnerContractDetailsList}
							GetClientPartnerContractDetailsModels={this.GetClientPartnerContractDetailsModels}
							Disabled={!model.Id || model.Id <= 0} />
						{/* <ClientPartnerAccountDefinitionAndDetails
							ClientPartnerModel={this.state.model}
							ClientPartnerAccountDefinitionAndDetailsList={this.state.paramClientPartnerAccountDefinitionAndDetailsList}
							GetClientPartnerAccountDefinitionAndDetailsModels={this.GetClientPartnerAccountDefinitionAndDetailsModels}
							Disabled={!model.Id || model.Id <= 0} /> */}
						{/* <ClientPartnerDdtList
							ClientPartnerModel={this.state.model}
							Disabled={!model.Id || model.Id <= 0} />					 */}
					</GridItem>
				</GridContainer>
			</div>
		);
	}
} ClientPartner.propTypes = {
	tabList: PropTypes.array,
	classes: PropTypes.object.isRequired,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ClientPartnerSaved: PropTypes.func,
	Disabled: PropTypes.bool
}; export default withArtifex(ClientPartner, {});