import { DialogContent, DialogTitle, Slide, withStyles } from "@material-ui/core";
import kycManualPhotoDefinitionStyle from "assets/jss/material-dashboard-pro-react/views/kycManualPhotoDefinitionStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericCamera, GenericCardMedia, GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { CrudType, FileCode, FileType } from "views/Constants/Constant";
import FileHelper from "core/FileHelper";
import ImageHelper from "core/ImageHelper";

const ProfileMale = require("assets/img/profile-male.png");
const ProfileFemale = require("assets/img/profile-female.png");

const FaceTemplate = require("assets/img/face-template.png");



const initialState = {

	model: {},
	modelList: [],
	photoBase64: null,
	openDialog: false,
	cameraLabel: null
};

class KycManualPhotoDefinition extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = Object.assign({}, initialState);
		this.parameterIdentificationMethod = { ParameterCode: "IdentificationMethod" };
		this.emptyObject = {};
		this.sortName = { Member: "Name" };
		this.gridSortParam = [{ id: "Status", desc: false }];
		this.ColumnsData = [
			{
				Header: "Date",
				accessor: "InsertDate",
				width: 100,
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original)}>{Formatter.FormatDate(row.value)}</div>)
			},
			{
				Header: "Full Name",
				accessor: "FullName",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original)}>{row.value}</div>)
			},
			{
				Header: "Identity Photo Status",
				accessor: "IdentityPhotoId",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original)}>{row.original.IdentityPhotoId > 0 ? "Found" : "Not Found"}</div>)
			},
			{
				Header: "Identity Back Photo Status",
				accessor: "IdentityPhotoId",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original)}>{row.original.IdentityPhotoBackId > 0 ? "Found" : "Not Found"}</div>)
			},
			{
				Header: "User Photo Status",
				accessor: "UserPhotoId",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{row.original.UserPhotoId > 0 ? "Found" : "Not Found"}</div>)
			}

		];
		this.fileInputRef1 = React.createRef();

	}
	componentDidMount() {
		if (this.props.setAppLeftTitle) {
			this.props.setAppLeftTitle("Manual KYC Application Photo Definition");
		}
		if (this.props.setAppCenterTitle) {
			this.props.setAppCenterTitle("AML & KYC");
		}
		this.GetGenderList();
	}
	handleOnCameraStart = (stream, cameraLabel) => {
		this.setState({ stream, cameraLabel });
	}

	handleTakePhoto = async (dataUri, base64) => {
		const model = { ...this.state.model };
		var cameraLabel = this.state.cameraLabel;


		let name = "Webcam.png";
		if (cameraLabel) {
			name = cameraLabel;
		}
		var rawFile = FileHelper.DataURLtoFile(dataUri, name);
		var blob = await ImageHelper.CompressImage(rawFile);
		var compressedBase64 = await FileHelper.BlobToBase64(blob);
		var compressedFile = FileHelper.DataURLtoFile(compressedBase64, name);

		model.File = compressedFile;
		model.FileName = name;
		this.setState({ photoBase64: compressedBase64, model });
		this.handleClose();
	}

	handleCameraError = (error) => {
		this.props.showMessage("error", "Camera Error", "Camera access errror!");
	}

	GetColumnsRowStyleColor(row) {
		this.columnsRowStyleColor = {
			color: row != null && row.UserPhotoId && row.IdentityPhotoId
				? "#113311"
				: ""
		};

		return this.columnsRowStyleColor;
	}
	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;

		this.setState({ model });
	}

	SelectedRowChange = (index) => {
		const model = this.state.modelList[index];
		model.genderValue = this.state.genderList && (this.state.genderList.find(a => a.Id == model.GenderId) || { ParameterValue: "M" }).ParameterValue;
		this.setState({ model: model });
	}
	HandleSearch = async () => {
		const model = this.state.model;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/amlkycapi/v1.0/KycManualApplication/Search", model);
		var modelList = result || [];
		this.setState({ modelList, photoBase64: null });
	}
	HandleSubmit = async () => {
		const { ExecuteApiFileUpload, ExecuteApiPost } = this.props;
		var { model, modelList } = this.state;
		var kycManualApplicationId = model.Id || 0;
		var kycManualApplicationUserPhotoId = model.UserPhotoId || 0;
		var formData = new FormData();
		formData.append("file", model.File, model.FileName);
		formData.append("fileId", model.UserPhotoId || 0);
		formData.append("fileCode", FileCode.KycManualPhoto);
		formData.append("isBinaryOrBase64", FileType.Base64);
		formData.append("isInsertOrUpdate", model.UserPhotoId == null ? CrudType.Insert : CrudType.Update);
		model.UserPhotoId = null;
		this.setState({ model });
		var result = await ExecuteApiFileUpload("/coreapi/v1.0/File/UploadFile", formData);
		var fileId = result;
		if (result && result > 0) {

			if (!model.Image) {
				model.Image = {};
			}
			model.UserPhotoId = fileId;
			model.File = null;
			model.FileName = null;
			var requestModel = {
				KycManualApplicationId: model.Id,
				UserPhotoId: fileId
			};
			if (kycManualApplicationUserPhotoId == 0) {
				result = await ExecuteApiPost("/amlkycapi/v1.0/KycManualApplication/ImageInsertOrUpdate", requestModel);
				model.ImageId = result;
			}
			if (result) {
				this.props.showMessage("success", "Success", "Photo uploaded successfully!");
			}

			modelList = modelList.map(item => {
				if (item.Id == kycManualApplicationId) {
					item.UserPhotoId = fileId;
					if (result && result > 0) {
						item.ImageId = result;
					}
				}
				return item;
			});
			this.setState({ model, modelList, photoBase64: null });
		}
	}

	HandleClear = () => {
		if (this.fileInputRef1 && this.fileInputRef1.current) {
			this.fileInputRef1.current.value = null;
		}
		this.setState({ modelList: [], model: {}, selected: null, photoBase64: null });
	}
	handleClose = () => {
		this.setState({
			openDialog: false
		});
	}
	handleOpenCamera = () => {
		this.setState({ openDialog: true });
	}
	GetGenderList = async () => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/coreapi/v1.0/Parameter/Search", { ParameterCode: "Gender", ParameterValue: "", ParameterDesc: "" });

		this.setState({ genderList: result || [] });
	}
	Transition = (props) => {
		return <Slide direction="up" {...props} />;
	}
	OpenFileSelectDialog = () => {
		this.fileInputRef1.current.click();
	}


	HandleFileSelect = async (e) => {

		const { model } = this.state;
		e.preventDefault();
		var file1 = e.target.files[0];
		if (!file1) return;
		this.setState({ isLoading: true });

		if (((file1.size || 0) / 1024 / 1024) > 10) {
			this.props.showMessage("error", "Error", "Photo file size cannot be larger than 10mb");
			this.setState({ isLoading: false });
			return;
		}		

		var blob = await ImageHelper.CompressImage(file1);//Start Compression if over 500kb
		var compressedBase64 = await FileHelper.BlobToBase64(blob);
		var compressedFile = FileHelper.DataURLtoFile(compressedBase64, file1.name);

		var imageObj = await ImageHelper.GetImageObjectFromBase64(compressedBase64);
		if (imageObj?.width < 100 || imageObj?.height < 100) {
			await this.props.showMessage("error", "Error", "Any dimension of the image cannot be under 100 pixel");
			this.setState({ isLoading: false });
			return;
		}

		model.TempFileId = model.UserPhotoId || 0;
		model.File = compressedFile;
		model.FileName = file1.name;
		model.FileSelect = true; 

		this.setState({ photoBase64: compressedBase64, isLoading: false });
	}

	handleRotateImage = async () => {
		const { photoBase64, model } = this.state;
		if (!photoBase64) {
			return;
		}
		this.setState({ isLoading: true });
		var imageBase64 = ImageHelper.GenerateImageFromRawBase64(photoBase64);
		var rotatedBase64 = await ImageHelper.Rotate(imageBase64, 90); 
		var rotatedFile = FileHelper.DataURLtoFile(rotatedBase64, model.FileName);
		model.File = rotatedFile;
		this.setState({ photoBase64: rotatedBase64, model, isLoading: false });
	}

	render() {
		const { model, modelList, openDialog, photoBase64, isLoading } = this.state;
		const { Disabled, classes, ExecuteApiPost } = this.props;


		var imgRatio = 0.5;
		var activeOverlay = FaceTemplate;


		return (
			<div>
				<LoadingComponent Show={isLoading} />
				<GenericDialog open={openDialog} onBackdropClick={this.handleClose} fullScreen TransitionComponent={this.Transition}>
					<DialogTitle className={classes.bgPrimary}>
						<GenericLabel Text="Take a Picture" FontSize="18px" Bold={true} />
					</DialogTitle>
					<DialogContent>
						<GridContainer>
							<GridItem xs={12}>
								<GenericLabel Text="Please take a face photo with looking as straight !" TextColor="black" FontSize="14px" />
								<br />
								<div>
									<GenericCamera
										OverlayRatio={imgRatio}
										OverlayImage={activeOverlay}
										Open={openDialog}
										OnCameraStart={this.handleOnCameraStart}
										OnTakePhoto={this.handleTakePhoto}
										OnCameraError={this.handleCameraError}
									/>
								</div>
							</GridItem>
						</GridContainer>
					</DialogContent>
					<GenericDialogActions>
						<Button onClick={this.handleClose}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>

				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.HandleSearch
						},
						{
							Code: "Submit",
							Disabled: Disabled || !(model && model.Id > 0 && photoBase64),
							OnClick: this.HandleSubmit
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.HandleClear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GridContainer>
					<GridItem xs={12}>
						<Card>
							<GenericExpansionPanel IsActive={true} Title="Filter Panel" >
								<CardBody>
									<GridContainer >
										<GridItem xs={3}>
											<GenericDateInput
												Utc
												Name="StartDate"
												LabelText="Start Date"
												Value={model.StartDate ? DateHelper.ToDateInputValue(model.StartDate) : ""}
												ValueChanged={this.HandleChange}
												Disabled={false}
												IsFuture={false} />
										</GridItem>
										<GridItem xs={3}>
											<GenericDateInput
												Utc
												Name="EndDate"
												LabelText="End Date"
												Value={model.EndDate ? DateHelper.ToDateInputValue(model.EndDate) : ""}
												ValueChanged={this.HandleChange}
												Disabled={false}
												MinDate={model.StartDate} />
										</GridItem>
									</GridContainer>

								</CardBody>
							</GenericExpansionPanel>
						</Card>

						<GridContainer>
							<GridItem xs={6}>
								<Card>
									<GenericExpansionPanel IsActive={true} Title="Profile Photo" >
										<CardBody>
											<GridContainer >
												<GridItem xs={4}>
													<GenericCardMedia
														Width={150}
														ExecuteApiPost={ExecuteApiPost}
														className={classes.mediaProfile}
														ImageFileId={model.UserPhotoId}
														ImagePlaceholder={model.genderValue == "F" ? ProfileFemale : ProfileMale}
													/>
												</GridItem>
												<GridItem xs={8}>
													<GridContainer direction="column">
														<GridItem>
															<GridContainer>
																<GridItem xs={6}>
																	<GenericTextInput
																		Disabled
																		LabelText="First Name"
																		Value={model.FirstName} />
																</GridItem>
																<GridItem xs={6}>
																	<GenericTextInput
																		Disabled
																		LabelText="Last Name"
																		Value={model.LastName} />
																</GridItem>
															</GridContainer>
														</GridItem>
														<br />
														<GridItem>
															<Button size="sm" onClick={this.handleOpenCamera}>Open Camera</Button>
														</GridItem>
														<GridItem>
															<Button
																size="sm"
																onClick={this.OpenFileSelectDialog}>
																Select Photo
															</Button>
															<input type="file" style={{ display: "none" }} onChange={this.HandleFileSelect} ref={this.fileInputRef1} accept="image/png,image/jpeg" />
														</GridItem>
													</GridContainer>
												</GridItem>
											</GridContainer>
										</CardBody>
									</GenericExpansionPanel>
								</Card>
							</GridItem>
							<GridItem xs={6}>
								<Card>
									<GenericExpansionPanel IsActive={true} Title="Preview" >
										<CardBody>
											<GridContainer direction="row">
												<GridItem xs={4}>
													<GenericCardMedia
														className={classes.mediaProfile}
														Image={photoBase64}
														ImagePlaceholder={model.genderValue == "F" ? ProfileFemale : ProfileMale}
														OnClickRotate={this.handleRotateImage}
													/>
												</GridItem>
											</GridContainer>
										</CardBody>
									</GenericExpansionPanel>
								</Card>
							</GridItem>
						</GridContainer>

						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel
									Name={"panelManualKycApplicationListActive"}
									Title="Manual KYC Application List"
								>
									<GenericGrid
										Data={modelList}
										PageSize={5}
										HideButton={true}
										Sorted={this.gridSortParam}
										Columns={this.ColumnsData}
										RowSelected={this.SelectedRowChange}
									/>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}

}

KycManualPhotoDefinition.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default withStyles(kycManualPhotoDefinitionStyle)(KycManualPhotoDefinition);