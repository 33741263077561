import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import { Search as SearchImg } from "@material-ui/icons";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import PropTypes from "prop-types";
import React from "react";
import ReactTable from "react-table";
import { GenericNumberInput, GenericTextInput, GenericDialog, GenericDialogActions } from "views/Components/Generic";

class ClientComponent extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			model: {},
			dialog: {
				isOpen: false,
				dialogData: []
			},
			selected: 0
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
		this.handleClickByKeyPressed = this.handleClickByKeyPressed.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.handleClickInside = this.handleClickInside.bind(this);

		this.getCustomerCallback = this.getCustomerCallback.bind(this);
		this.getCustomerCallbackByKeyPressed = this.getCustomerCallbackByKeyPressed.bind(this);
		this.selectCustomerCallback = this.selectCustomerCallback.bind(this);
		this.cancelSelectCustomerCallback = this.cancelSelectCustomerCallback.bind(this);
	}

	handleChange(name, value) {
		const model = { ...this.state.model };
		model[name] = value;
		this.setState({ model });
	}

	handleKeyPress(e) {
		if (e.key == "Enter") {
			this.handleClickByKeyPressed();
		}
	}

	handleClickByKeyPressed() {
		Proxy.POST("/bankapi/v1.0/BankCustomer/Search", {
			UniqueClientId: this.props.uniqueClientId
		}, this.getCustomerCallbackByKeyPressed);
	}

	handleClick() {
		Proxy.POST("/bankapi/v1.0/BankCustomer/Search", {
			UniqueClientId: this.props.uniqueClientId
		}, this.getCustomerCallback);
	}

	handleClickInside() {
		Proxy.POST("/bankapi/v1.0/BankCustomer/Search",
			this.state.model,
			this.getCustomerCallback);
	}

	getCustomerCallback(responseData) {
		if (responseData.Item != null) {
			this.setState({
				dialog: {
					isOpen: true,
					dialogData: responseData.Item
				}
			});
		} else {
			this.setState({ dialogData: [] });
		}
	}

	getCustomerCallbackByKeyPressed(responseData) {
		this.getCustomerCallback(responseData);

		if (responseData.Item.length === 1) {
			this.selectCustomerCallback(responseData.Item[0]);
		}
	}

	selectCustomerCallback(customer) {
		this.props.fillCallback(customer);
		this.setState({
			dialog: {
				isOpen: false,
				dialogData: []
			}
		});
	}

	cancelSelectCustomerCallback() {
		this.setState({
			dialog: {
				isOpen: false,
				dialogData: []
			}
		});
	}

	render() {
		var { nameInputName, numberInputName, LabelText, uniqueClientId, handleChange, LabelMd, Disabled } = this.props;
		return (
			<div>
				<GridContainer>
					<GridItem xs={12} sm={12}>
						<GenericNumberInput
							LabelMd={LabelMd}
							Name={numberInputName || "UniqueClientId"}
							LabelText={LabelText || "Client Unique ID"}
							tabIndex={0}
							KeyPressed={this.handleKeyPress}
							Blur={this.handleClickByKeyPressed}
							NoFormatting={true}
							Value={uniqueClientId}
							ValueChanged={handleChange}
							EndIconName="search"
							IconOnClick={Disabled ? undefined : this.handleClick}
							Disabled={Disabled}
						/>
					</GridItem>
				</GridContainer>

				<GenericDialog open={this.state.dialog.isOpen}>
					<DialogTitle>Select Customer</DialogTitle>
					<DialogContent>
						<GridContainer>
							<GridItem xs={12} sm={5}>
								<GenericNumberInput
									Name={numberInputName || "UniqueClientId"}
									LabelText="Client Unique ID"
									NoFormatting={true}
									Value={this.state.model.UniqueClientId}
									ValueChanged={this.handleChange} />
							</GridItem>
							<GridItem xs={12} sm={5}>
								<GenericTextInput
									Name={nameInputName || "CustomerName"}
									LabelText="Name"
									Value={this.state.model.CustomerName}
									ValueChanged={this.handleChange} />
							</GridItem>
							<GridItem xs={12} sm={2}>
								<IconButton size="sm" component="span">
									<SearchImg onClick={this.handleClickInside} />
								</IconButton>
							</GridItem>
						</GridContainer>
						<ReactTable
							data={this.state.dialog.dialogData.map(d => {
								return {
									Id: d.UniqueClientId,
									Name: d.Name,
									ClientType: d.ClientType,
									actions: (
										<div>
											<Button
												size="sm"
												onClick={() => {
													this.selectCustomerCallback(d);
												}}
											>
												Select
											</Button>
										</div>
									)
								};
							})
							}
							filterable
							columns={
								[
									{
										Header: "Id",
										accessor: "Id",
										width: 100
									},
									{
										Header: "Name",
										accessor: "Name"
									},
									{
										Header: "Client Type",
										accessor: "ClientType.ParameterDesc"
									},
									{
										Header: "Actions",
										accessor: "actions",
										sortable: false,
										filterable: false,
										resizable: false
									}
								]}
							defaultPageSize={10}
							showPaginationTop={false}
							showPaginationBottom={this.state.dialog.dialogData !== undefined ? Object.keys(this.state.dialog.dialogData).length > 50 : false}
							minRows={2}
						/>
					</DialogContent>
					<GenericDialogActions>
						<Button
							size="sm"
							onClick={this.cancelSelectCustomerCallback}
						>
							Cancel
						</Button>
					</GenericDialogActions>

				</GenericDialog>
			</div>
		);
	}
}

ClientComponent.propTypes = {
	uniqueClientId: PropTypes.any,
	fillCallback: PropTypes.func,
	nameInputName: PropTypes.string,
	numberInputName: PropTypes.string,
	LabelText: PropTypes.string,
	handleChange: PropTypes.func,
	LabelMd: PropTypes.number,
	Disabled: PropTypes.bool
};

export default withArtifex(ClientComponent, {});