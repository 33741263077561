import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import { EditIcon } from "core/Icons";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import CardComponent from "views/Components/CardComponent.jsx";
import CustomerComponent from "views/Components/CustomerComponent.jsx";
import { GenericExpansionPanel, GenericGrid, GenericSelectInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton.jsx";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType, ProgramCodes } from "views/Constants/Constant.js";
import ClientDisputeAction from "views/PPaid/Clearing/Dispute/ClientDisputeAction.jsx";

class ClientDipsute extends Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined,
			ClientCustomerId: null,
			CustomerNumber: null,
			CardTokenNumber: null,
			CardId: null
		};

		this.InitialDisputeModel = {
			DisputeCurrencyId: 0
		};

		this.state = {
			vModel: {},
			model: { ...this.initialModel },
			generalModel: { BankAccount: {}, WalletAccount: {}, ...this.initialModel },
			embossModel: { ...this.initialModel },
			statisticModel: { ...this.initialModel },
			transactions: [],
			isLoading: false,
			activeIndex: 0,
			isDisputeActionDialogOpen: false,
			transactionData: {},
			disputeModel: { ...this.InitialDisputeModel }
		};


		this.emptyObject = {};

		this.maxLength26 = { maxLength: 26 };
		this.maxLength19 = { maxLength: 19 };

		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined
		};

		this.parameterPeriod = { ParameterCode: "LoadingPeriod" };

		this.renderItemUniqueClient = {};

		this.parameterProduct = {};
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Client Dispute");
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
	}

	handleLoadTaskPoolCompleted = () => {
		this.setState({ isLoading: false });
	}

	handleLoadTaskPoolAppend = () => {
		this.setState({ isLoading: true });
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		if (name === "UniqueClientId") {
			delete model.ClientCustomerId;
			delete model.CustomerNumber;
			delete model.CardId;
			delete model.CardTokenNumber;
		}
		if (name === "Customer") {
			if (newValue) {
				model.ClientCustomerId = newValue.Id;
				model.CustomerNumber = newValue.CustomerNumber;
			} else {
				delete model.ClientCustomerId;
				delete model.CustomerNumber;
				delete model.CardId;
				delete model.CardTokenNumber;
			}
		}
		if (name === "Card") {
			if (newValue) {
				model.CardId = newValue.CardId;
				model.CardTokenNumber = newValue.CardTokenNumber;
			} else {
				delete model.CardId;
				delete model.CardTokenNumber;
			}
		}
		model[name] = newValue;
		this.setState({ model });
	}

	HandleClear = () => {
		this.setState({
			model: { ...this.initialModel },
			transactions: []
		});
	}

	HandleSearch = async () => {
		const { model } = this.state;

		if (!this.Validate()) {
			return;
		}

		this.setState({ isLoading: true });

		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/prepaidapi/v1.0/TransactionData/SearchTransactionWithDispute", {
			UniqueClientId: model.UniqueClientId,
			ClientCustomerId: model.ClientCustomerId,
			CardId: model.CardId
		});

		if (result !== null)
			this.setState({ transactions: result, isLoading: false });
		else
			this.setState({ transactions: [] });

	}

	Validate = () => {
		const { model } = this.state;

		if (!model.UniqueClientId) {
			this.props.showMessage("warning", "Client not selected", "Select the Client to continue.");
			return false;
		}

		if (!model.ClientCustomerId) {
			this.props.showMessage("warning", "Customer not selected", "Select the Customer to continue.");
			return false;
		}

		if (!model.CardId) {
			this.props.showMessage("warning", "Card not selected", "Select the Card to continue.");
			return false;
		}

		return true;
	}

	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}

	TabIndexChanged = (index) => {
		this.setState({ activeIndex: index });
	}

	FillCustomer = (customer) => {
		this.HandleChange("Customer", customer);
	}

	FillCard = (card) => {
		this.HandleChange("Card", card);
	}

	HandleDispute = async (data, index) => {
		var transactionData = this.state.transactions[index];
		this.setState({ isLoading: true });

		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/prepaidapi/v1.0/ClearingDispute/GetClientDispute", { TransactionDataId: transactionData.Id });

		if (result !== null) {
			this.setState({ isDisputeActionDialogOpen: true, disputeModel: result, transactionData: transactionData, isLoading: false });
		} else {
			transactionData.ClientDispute = { ...this.InitialDisputeModel };
			this.setState({ isDisputeActionDialogOpen: true, disputeModel: transactionData.ClientDispute, transactionData: transactionData, isLoading: false });
		}
	}

	HandleActionDialogClose = () => {
		this.setState({ isDisputeActionDialogOpen: false });
	}

	DisputeModelChange = (modelFunction) => {
		this.setState(function (state, props) {
			var model = state.disputeModel || {};

			if (modelFunction)
				model = modelFunction(model);

			return { disputeModel: model };
		});
	}


	render() {
		const { Disabled } = this.props;
		const { alert, isLoading, model, transactions, vModel } = this.state;
		var IsClient = ClientHelper.IsClient();
		return (
			<>
				<LoadingComponent Show={isLoading} />
				{alert}

				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />


				<ClientDisputeAction
					showMessage={this.props.showMessage}
					transactionData={this.state.transactionData}
					disputeModel={this.state.disputeModel}
					isDisputeActionDialogOpen={this.state.isDisputeActionDialogOpen}
					onActionModelClose={this.HandleActionDialogClose}
					onModelChange={this.DisputeModelChange}
				/>


				<Card className="no-radius">
					<CardBody>
						<GenericExpansionPanel Title="Main Panel">
							<GridContainer>
								<GridItem xs={12}>
									<GridContainer>
										<GridItem xs={3}>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={this.parameterUniqueClientId}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={this.RenderItemUniqueClient}
												Value={model.UniqueClientId}
												ValueChanged={this.HandleChange}
												CanClear
												Disabled={IsClient}
												DefaultIndex={IsClient ? 0 : -1} />
										</GridItem>
										<GridItem xs={3}>
											<CustomerComponent
												key={model.UniqueClientId}
												LabelMd={4}
												CustomerNumber={model.CustomerNumber}
												HandleChange={this.HandleChange}
												FillCallback={this.FillCustomer}
												Programs={[ProgramCodes.Prepaid]}
												UniqueClientId={model.UniqueClientId}
												Disabled={!model.UniqueClientId}
												Required
												IsInvalid={vModel.ClientCustomerId}
											/>
										</GridItem>
										<GridItem xs={3}>
											<CardComponent
												key={"Card_" + [model.UniqueClientId, model.ClientCustomerId]}
												LabelMd={4}
												CardTokenNumber={model.CardTokenNumber}
												HandleChange={this.HandleChange}
												FillCallback={this.FillCard}
												ClientCustomerId={model.ClientCustomerId}
												UniqueClientId={model.UniqueClientId}
												Disabled={!model.ClientCustomerId}
												Required
												IsInvalid={vModel.CardId}
											/>
										</GridItem>
									</GridContainer>
								</GridItem>
							</GridContainer>
						</GenericExpansionPanel>
					</CardBody>
				</Card>
				<Card className="no-radius">
					<CardBody>
						<GridContainer>
							<GridItem xs={12}>
								<GenericGrid
									Data={transactions}
									Columns={
										[
											{
												Header: "Actions",
												accessor: "Actions",
												Cell: row => (
													<div>
														<GridButton
															tooltip="Dispute"
															Icon={EditIcon}
															OnClick={() => { this.HandleDispute(row.original, row.index); }} />

													</div>
												),
												sortable: false,
												filterable: false,
												resizable: false,
												width: 60
											},
											{
												Header: "Reference Number",
												accessor: "ReferenceNumber"
											},
											{
												Header: "Wallet Number",
												accessor: "AccountNumber"
											},
											{
												Header: "Wallet Name",
												accessor: "AccountName"
											},
											{
												Header: "Masked Card Number",
												accessor: "MaskedCardNumber"
											},
											{
												Header: "Billing Amount",
												accessor: "BillingAmount"
											},
											{
												Header: "Transaction Date",
												accessor: "TransactionDate",
												type: GridColumnType.DateTime
											},
											{
												Header: "Transaction Code",
												accessor: "ChannelName"
											},

											{
												Header: "Cardholder Name",
												accessor: "CardOwnerName"
											},
											{
												Header: "Card Masked Number",
												accessor: "MaskedCardNumber"
											},
											{
												Header: "Available Balance",
												accessor: "WalletBalance",
												type: GridColumnType.Money,
												ColumnType: GridColumnType.Money
											},
											{
												Header: "Merchant",
												accessor: "Merchant"
											},
											{
												Header: "Merchant City",
												accessor: "MerchantCity"
											},
											{
												Header: "Response Code",
												accessor: "ResponseCode"
											},
											{
												Header: "Original Amount",
												accessor: "TransactionAmount",
												type: GridColumnType.Money,
												ColumnType: GridColumnType.Money

											},
											{
												Header: "Biling Amount",
												accessor: "BillingAmount",
												type: GridColumnType.Money,
												ColumnType: GridColumnType.Money

											},
											{
												Header: "Fee Amount",
												accessor: "FeeAmount",
												type: GridColumnType.Money,
												ColumnType: GridColumnType.Money

											},
											{
												Header: "Terminal Type",
												accessor: "TerminalType"

											},
											{
												Header: "Transaction Type",
												accessor: "CardTransactionSource"

											},
											{
												Header: "Settlement Amount",
												accessor: "SettlementAmount",
												type: GridColumnType.Money,
												ColumnType: GridColumnType.Money
											},
											{
												Header: "Settlement Date",
												accessor: "SettlementDate",
												type: GridColumnType.DateTime
											},
											{
												Header: "Error Code",
												accessor: "ErrorCode"
											},
											{
												Header: "Error Description",
												accessor: "ErrorDescription"
											},
											{
												Header: "Card Brand Name",
												accessor: "CardBrandName"
											},
											{
												Header: "Description",
												accessor: "Description"
											},


										]}
									HideButton={true} />
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>

			</>
		);
	}
}

ClientDipsute.propTypes = {
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool
};

export default withArtifex(ClientDipsute, {});