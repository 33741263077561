import { Dialog, DialogContent } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes, { bool, func } from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericGrid, GenericSelectInput, GenericCheckInput } from "views/Components/Generic";
import { Direction, GridColumnType, ProgramCodes } from "views/Constants/Constant";
import LoadingComponent from "views/Components/LoadingComponent";

class CreateBulkFile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			model: {
				FromDate: DateHelper.GetDate(),
				ToDate: DateHelper.GetDate(),
				DirectionParameterValue: ""
			},
			getTransactionByFileIdModel: {
				DirectionParameterValue: ""
			},
			TransactionColumns: [],
			checkedValues: [],
			fileInfoModel: {},
			headerModel: {},
			transactionList: [],
			isLoading: false,
			SettlementType: ""
		};
	}

	componentDidMount() {
		if (this.props.setAppLeftTitle)
			this.props.setAppLeftTitle("Bulk Send File");
		if (this.props.setAppCenterTitle)
			this.props.setAppCenterTitle("CLEARING & SETTLEMENT");
		var model = { ...this.state.model };
		model.DirectionParameterValue = Direction.Outgoing;
		var getTransactionByFileIdModel = { ...this.state.getTransactionByFileIdModel };
		getTransactionByFileIdModel.DirectionParameterValue = Direction.Outgoing;

		var TransactionColumns = [];
		TransactionColumns = [
			{
				Header: "Actions",
				accessor: "Actions",
				sortable: false,
				filterable: false,
				width: 60
			},
			{
				Header: "Unique Client Number",
				accessor: "ClientNumber"
			},
			{
				Header: "Client Name",
				accessor: "ClientName"
			},
			{
				Header: "Transaction Code",
				accessor: "TransactionCode"
			},
			{
				Header: "Transaction Status",
				accessor: "TransactionStatus"
			},
			{
				Header: "Transaction Source",
				accessor: "TransactionSource"
			},
			{
				Header: "Notification Status",
				accessor: "NotificationStatus"
			},
			{
				Header: "Transaction Type",
				accessor: "TransactionType",
				Cell: row => (
					<div>{row.value == "Credit" ? "Money Send" : "Money Request"}</div>
				)
			},
			{
				Header: "Priority Type",
				accessor: "PriorityType"
			},
			{
				Header: "Transaction Date",
				accessor: "TransactionDate",
				type: GridColumnType.Date
			},
			{
				Header: "Amount",
				accessor: "Amount",
				type: GridColumnType.Money,
				ColumnType:GridColumnType.Money
			},
			{
				Header: "Pay. Name",
				accessor: "PayeeName"
			},
			{
				Header: "Pay. Email",
				accessor: "PayeeEmail"
			},
			{
				Header: "Source Wallet Transit",
				accessor: "SourceAccountTransit"
			},
			{
				Header: "Source Wallet Number",
				accessor: "SourceAccountNumber"
			}
		];
		this.setState({ getTransactionByFileIdModel, model, TransactionColumns: TransactionColumns });

	}

	handleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		var { SettlementType } = this.state;
		if (name == "eTransferTypeId")
			SettlementType = data.ParameterDesc;
		model[name] = newValue;
		this.setState({ model, SettlementType });
	}


	HandleCheck = (e, x) => {

		this.setState(state => ({
			checkedValues: state.checkedValues.includes(x)
				? state.checkedValues.filter(c => c !== x)
				: [...state.checkedValues, x]
		}));
	}
	GetDailyFileList = () => {
		this.showLoading();
		Proxy.POST(
			"/bankapi/v1.0/ClearingSettlement/GeteTransferBulkFileTransactions",
			this.state.model,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.showBasicAlert("Error", responseData.ErrorDescription, "error");
					return;
				}
				if (responseData.Item == null && responseData.Item.length > 0) {
					this.showBasicAlert("Info", "No items for selected search filters !!!", "warning");

					this.setState({
						transactionList: [], headerModel: {},
						dailyFileList: responseData.Item, isLoading: false
					});
				}
				else {
					this.setState({ transactionList: responseData.Item, isLoading: false })
				}

			},
			this.errorCallback
		);
	}

	GetTransactionList = () => {
		this.showLoading();
		Proxy.POST(
			"/bankapi/v1.0/ClearingSettlement/GeteTransferTransactionsByFileId",
			responseData => {
				var headerModel = { ...this.state.headerModel };
				var transactionList = { ...this.state.transactionList };
				if (!responseData.IsSucceeded) {
					this.showBasicAlert("Error", responseData.ErrorDescription, "error");
					return;
				}
				if (responseData.Item != null) {
					if (responseData.Item.ClearingFileHeaderDetail != null) {
						headerModel = responseData.Item.FileHeaderInfo == null ? {} : responseData.Item.FileHeaderInfo;
						headerModel.FileDate = this.state.fileInfoModel.FileDate;
					}
					else
						headerModel = {};

					if (responseData.Item.FileTransactons != null || responseData.Item.FileTransactons.length > 0)
						transactionList = responseData.Item.FileTransactons;
					else
						transactionList = [];
				}
				this.setState({ transactionList, headerModel, isLoading: false });
			},
			this.errorCallback
		);
	}

	CreateFile = () => {
		if (this.state.checkedValues == null || this.state.checkedValues.length == 0)
			this.showBasicAlert("Warning", "Settlement Type cannot be empty in creation process !!!", "warning");
		else {
			this.showLoading();
			Proxy.POST(
				"/bankapi/v1.0/ClearingSettlement/SenBulkFileTransactions",
				this.state.checkedValues,
				this.successCreateFile,
				error => {
					this.showBasicAlert("Error", "An error occurred during the api visit" + error, "error");
				}
			);
		}
	}

	successCreateFile = (responseData) => {
		this.hideLoading();
		if (!responseData.IsSucceeded) {
			this.showBasicAlert("Error", responseData.ErrorDescription, "error");
			return;
		}
		if (responseData != null) {
			if (responseData.SuccessCount > 0 || responseData.ErrorCount > 0) {
				var successCount = responseData.SuccessCount + "";
				var errorCount = responseData.ErrorCount + "";
				this.showBasicAlert("Success", "Success Count :" + successCount + ", Error Count : " + errorCount, "success");
			}
			else {
				this.showBasicAlert("Warning", this.state.SettlementType + "=> Settlement Type not have any transaction !!!", "warning");
			}
		}
		this.GetDailyFileList();
	}



	errorCallback = (error) => {
		this.showBasicAlert("Error", "An error occurred during the api visit" + error, "error");
	}

	showBasicAlert = (title, message, type) => {
		this.setState({
			isLoading: false,
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={false} OnConfirm={() => this.hideAlert()} />
		});
	}

	showLoading = () => { this.setState({ isLoading: true }); }
	hideLoading = () => { this.setState({ isLoading: false }); }
	hideAlert = () => { this.setState({ alert: null, isLoading: false }); }

	ClearModel = () => {
		this.setState({
			model: {
				FromDate: DateHelper.GetDate(),
				ToDate: DateHelper.GetDate(),
			}
		});
	}

	render() {
		const { Disabled } = this.props;
		const { model, headerModel, isLoading, transactionList, TransactionColumns, checkedValues } = this.state;
		return (
			<div>
				<LoadingComponent Show={isLoading} />
				{this.state.alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.GetDailyFileList, Disabled: Disabled },
					{ Code: "CreateFile", OnClick: this.CreateFile, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.ClearModel, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer>
					<GridItem xs={12} sm={6} md={6}>
						<Card style={{ height: "90%" }}>
							<CardHeader>
								<h4><b>Transaction Filter</b></h4>
							</CardHeader>
							<CardBody>
								<GridItem md={12} xs={12}>
									<GenericSelectInput
										Name="ClientId"
										LabelText="Client"
										Method="POST"
										Url="/bankapi/v1.0/BankCustomer/Search"
										Parameter={{}}
										DataRoot="Item"
										KeyValueMember="Id"
										RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
										Value={model.ClientId || ""}
										ValueChanged={this.handleChange}
										CanClear />
									<GenericDateInput
										Name="FromDate"
										LabelText="From Date"
										Value={model.FromDate == null || undefined ? "" : model.FromDate}
										ValueChanged={this.handleChange}
										MaxDate={model.FromDate || DateHelper.GetDate()}
										IncludeTime={false} />
									<GenericDateInput
										Name="ToDate"
										LabelText="To Date"
										Value={model.ToDate == null || undefined ? "" : model.ToDate}
										ValueChanged={this.handleChange}
										MinDate={model.FromDate == null ? null : model.FromDate}
										MaxDate={DateHelper.GetDate()}
										IncludeTime={false} />
									<GenericSelectInput
										Name="eTransferTypeId"
										LabelText="Settlement Type"
										Value={model == null || undefined ? "" : model.eTransferTypeId || ""}
										All
										DataRoot="Item"
										ValueChanged={this.handleChange}
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Url="/coreapi/v1.0/Parameter/Search"
										Method="POST"
										Parameter={{
											ParameterCode: "EtransferType",
											"ParameterValue2": "ETRANSFER"
										}} />
								</GridItem>
								<br />
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={12} sm={6} md={6}>
						<Card style={{ height: "90%" }}>
							<CardHeader>
								<h4><b>File Filter</b></h4>
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={12} sm={6} md={6}>
										<GenericDateInput
											Name="FileFromDate"
											LabelText="From Date"
											Value={model.FileFromDate == null || undefined ? "" : model.FileFromDate}
											ValueChanged={this.handleChange}
											MaxDate={model.FileToDate || DateHelper.GetDate()}
											IncludeTime={false} />
										<GenericDateInput
											Name="FileToDate"
											LabelText="To Date"
											Value={model.FileToDate == null || undefined ? "" : model.FileToDate}
											ValueChanged={this.handleChange}
											MinDate={model.FileFromDate == null ? null : model.FileFromDate}
											MaxDate={DateHelper.GetDate()}
											IncludeTime={false} />
										<GenericSelectInput
											Name="FileStatusId"
											LabelText="File Status"
											Value={model.FileStatusId || ""}
											DataRoot="Item"
											All
											ValueChanged={this.handleChange}
											KeyValueMember="Id"
											TextValueMember="ParameterDesc"
											Url="/coreapi/v1.0/Parameter/Search"
											Method="POST"
											Parameter={{
												ParameterCode: "BankFileStatus",
												"ParameterValue2": "ETRANSFER"
											}} />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card><br />
					</GridItem>
				</GridContainer>
				<Card className="no-radius">
					<CardHeader>
						<h5><b>Transaction List</b></h5> <br />
					</CardHeader>
					<CardBody>
						<GenericGrid
							Data={transactionList.map(d => {
								return {
									Actions: (
										<div>
											<GenericCheckInput
												Grid
												Value={checkedValues.filter(c => c.TransactionId == d.TransactionId).length > 0}
												ValueChanged={(e) => {
													this.HandleCheck(e, d);
												}}

											/>
										</div>
									),
									ReceiveDate: d.ReceiveDate,
									TransactionId: d.TransactionId,
									TransactionETransferId: d.TransactionETransferId,
									UniqueClientId: d.UniqueClientId,
									ClientName: d.ClientName,
									ClientNumber: d.ClientNumber,
									TransactionCode: d.TransactionCode,
									TransactionStatus: d.TransactionStatus,
									TransactionSource: d.TransactionSource,
									NotificationStatus: d.NotificationStatus,
									TransactionType: d.TransactionType,
									TransactionDate: d.TransactionDate,
									PriorityType: d.PriorityType,
									Amount: d.Amount,
									PayeeName: d.PayeeName,
									PayeeEmail: d.PayeeEmail,
									SourceAccountTransit: d.SourceAccountTransit,
									SourceAccountNumber: d.SourceAccountNumber

								};
							})}
							Columns={TransactionColumns}
							ProgramCode={ProgramCodes.Bank_Programs_e_Transfer} />
					</CardBody>
				</Card >
			</div >
		);
	}
}

CreateBulkFile.propTypes = {
	classes: PropTypes.object,
	handleOperationType: PropTypes.func,
	showQuestionMessage: func,
	setAppLeftTitle: func,
	setAppCenterTitle: func,
	Disabled: bool
};

export default withArtifex(CreateBulkFile, {});