import ClientHelper from "core/ClientHelper.js";
import DateHelper from "core/DateHelper.js";
import Formatter from "core/Formatter.js";
import PropTypes from "prop-types";
import React, { Component } from "react";
import AccountComponent from "../AccountComponent.jsx";
import CustomerStoreComponent from "../CustomerStoreComponent.jsx";
import GenericCheckInput from "./GenericCheckInput.jsx";
import GenericDateFilterInput from "./GenericDateFilterInput.jsx";
import GenericDateInput from "./GenericDateInput.jsx";
import GenericEmailInput from "./GenericEmailInput.jsx";
import GenericFilterSelectInput from "./GenericFilterSelectInput.jsx";
import GenericNumberInput from "./GenericNumberInput.jsx";
import GenericRadioInput from "./GenericRadioInput.jsx";
import GenericSelectInput from "./GenericSelectInput.jsx";
import GenericTextInput from "./GenericTextInput.jsx";
import ResourceHelper from "../../../core/ResourceHelper";

class GenericInput extends Component {

	constructor(props) {
		super(props);

		this.GenerateInput = this.GenerateInput.bind(this);
		this.IsBackoffice = ClientHelper.IsBackOffice();
		this.ClientId = ClientHelper.GetClientId();
	}

	GenerateInput(inputData) {
		const { Disabled, IsInvalid, IsReport } = this.props;
		inputData.LabelText = ResourceHelper.CorrectAccountNames(inputData.LabelText);
		switch (inputData.InputType) {
			case "text":
				this.inputProps = {};
				if (inputData.MaxLength)
					this.inputProps = { maxLength: JSON.parse(inputData.MaxLength) };
				return <GenericTextInput
					Required={inputData.IsRequired || false} IsInvalid={IsInvalid || false} LabelMd={this.props.LabelMd} Name={inputData.Name} LabelText={inputData.LabelText}
					Disabled={Disabled || inputData.Disabled} Value={this.props.Value} ValueChanged={this.props.ValueChanged} MultiLine={inputData.MultiLine}
					RowCount={inputData.RowCount} BeginIconName={inputData.BeginIconName} EndIconName={inputData.EndIconName} AutoFocus={inputData.AutoFocus}
					IsPassword={inputData.IsPassword} inputProps={this.inputProps} IsText={inputData.IsText}
				/>;
			case "number":
				return <GenericNumberInput
					Required={inputData.IsRequired || false} IsInvalid={IsInvalid || false} LabelMd={this.props.LabelMd} Name={inputData.Name} LabelText={inputData.LabelText}
					Disabled={Disabled || inputData.Disabled} Value={this.props.Value} ValueChanged={this.props.ValueChanged} BeginIconName={inputData.BeginIconName}
					EndIconName={inputData.EndIconName} AutoFocus={inputData.AutoFocus} ThousandSeparator={inputData.ThousandSeparator} DecimalSeparator={inputData.DecimalSeparator}
					NoFormatting={inputData.NoFormatting} Prefix={inputData.Prefix} Suffix={inputData.Suffix} MaxLength={inputData.MaxLength}
				/>;
			case "decimal":
				return <GenericNumberInput
					Required={inputData.IsRequired || false} IsInvalid={IsInvalid || false} LabelMd={this.props.LabelMd} Name={inputData.Name} LabelText={inputData.LabelText}
					Disabled={Disabled || inputData.Disabled} Value={this.props.Value} ValueChanged={this.props.ValueChanged} BeginIconName={inputData.BeginIconName}
					EndIconName={inputData.EndIconName} AutoFocus={inputData.AutoFocus} ThousandSeparator={inputData.ThousandSeparator} DecimalSeparator={inputData.DecimalSeparator}
					NoFormatting={inputData.NoFormatting} Prefix={inputData.Prefix} Suffix={inputData.Suffix} MaxLength={inputData.MaxLength}
				/>;
			case "select":
				return <GenericSelectInput
					Required={inputData.IsRequired || false} IsInvalid={IsInvalid || false} CanClear={inputData.CanClear} LabelMd={this.props.LabelMd} key={inputData.Key}
					Name={inputData.Name} LabelText={inputData.LabelText} Disabled={Disabled || inputData.Disabled} Value={this.props.Value} ValueChanged={this.props.ValueChanged}
					KeyValueMember={inputData.KeyValueMember} TextValueMember={inputData.TextValueMember}
					RenderItem={inputData.RenderItem == null ? null : data => inputData.RenderItem.map(propName => data[propName]).join(" ")} Method={inputData.Method}
					Url={inputData.Url} Parameter={inputData.Parameter} DataRoot={inputData.DataRoot} IsStatic={inputData.IsStatic} StaticData={inputData.StaticData}
					DefaultIndex={inputData.DefaultIndex}
				/>;
			case "filter":
				return <GenericFilterSelectInput
					Required={inputData.IsRequired || false} IsInvalid={IsInvalid || false} LabelMd={this.props.LabelMd} Name={inputData.Name} LabelText={inputData.LabelText}
					Disabled={Disabled || inputData.Disabled} Value={this.props.Value} ValueChanged={this.props.ValueChanged} KeyValueMember={inputData.KeyValueMember}
					TextValueMember={inputData.TextValueMember} Url={inputData.Url}
					RenderItem={inputData.RenderItem == null ? null : data => inputData.RenderItem.map(propName => data[propName]).join(" ")}
					Parameter={{ ...inputData.Parameter, ...this.props.FilterParameter }} DataRoot={inputData.DataRoot}
				/>;
			case "date":
			case "date_utc":
				return <GenericDateInput
					Utc={inputData.Utc} Required={inputData.IsRequired || false} IsInvalid={IsInvalid || false} LabelMd={this.props.LabelMd} Name={inputData.Name}
					LabelText={inputData.LabelText} Disabled={Disabled || inputData.Disabled}
					Value={this.props.Value ? (inputData.IsFormatDate ? Formatter.FormatDateUtc(this.props.Value) : DateHelper.ToDateInputValue(this.props.Value)) : ""}
					ValueChanged={this.props.ValueChanged} DateFormat={inputData.DateFormat} IncludeTime={inputData.IncludeTime}
					MaxDate={inputData.MaxDate ? this.props.Model[inputData.MaxDate] || DateHelper.GetDate() : undefined} MinDate={this.props.Model[inputData.MinDate]}
					IsPast={inputData.IsPast} IsFuture={inputData.IsFuture}
				/>;
			case "checkbox":
				return <GenericCheckInput
					Required={inputData.IsRequired || false} IsInvalid={IsInvalid || false} LabelMd={this.props.LabelMd} Name={inputData.Name} LabelText={inputData.LabelText}
					Disabled={Disabled || inputData.Disabled} Value={this.props.Value} ValueChanged={this.props.ValueChanged}
				/>;
			case "radio":
				return <GenericRadioInput
					Required={inputData.IsRequired || false} IsInvalid={IsInvalid || false} LabelMd={this.props.LabelMd} Name={inputData.Name} LabelText={inputData.LabelText}
					Disabled={Disabled || inputData.Disabled} Value={this.props.Value} ValueChanged={this.props.ValueChanged} KeyValueMember={inputData.KeyValueMember}
					TextValueMember={inputData.TextValueMember} Method={inputData.Method} Url={inputData.Url} Parameter={inputData.Parameter} DataRoot={inputData.DataRoot}
					IsStatic={inputData.IsStatic} StaticData={inputData.StaticData}
				/>;
			case "account":
				return <AccountComponent
					Required={inputData.IsRequired || false} IsInvalid={IsInvalid || false} LabelMd={this.props.LabelMd} LabelText={inputData.LabelText}
					Disabled={Disabled || inputData.Disabled} Value={this.props.Value} ValueChanged={this.props.ValueChanged} Model={this.props.Model}
					IsDCBankAccount={inputData.IsDCBankAccount}
				/>;
			case "client":
				return <GenericSelectInput
					Required={inputData.IsRequired || false} IsInvalid={IsInvalid || false} LabelMd={this.props.LabelMd} Name={inputData.Name} LabelText={inputData.LabelText}
					Method="POST" Url="/bankapi/v1.0/BankCustomer/Search" Parameter={{ UniqueClientId: !this.IsBackoffice ? this.ClientId : 0 }}
					DataRoot="Item" KeyValueMember="Id" RenderItem={d => `${d.UniqueClientId} - ${d.Name}`} Value={this.props.Value ? this.props.Value : 0}
					ValueChanged={this.props.ValueChanged} CanClear Disabled={Disabled || inputData.Disabled || !this.IsBackoffice}
					DefaultIndex={!this.IsBackoffice ? 0 : -1}
				/>;
			case "store":
				return <CustomerStoreComponent
					Required={inputData.IsRequired || false} IsInvalid={IsInvalid || false} LabelMd={this.props.LabelMd} LabelText={inputData.LabelText}
					Disabled={Disabled || inputData.Disabled} Value={this.props.Value} ValueChanged={this.props.ValueChanged} Model={this.props.Model}
				/>;
			case "datefilter":
				return <GenericDateFilterInput Required={inputData.IsRequired || false} IsInvalid={IsInvalid || false} LabelMd={this.props.LabelMd}
					Name={inputData.Name} LabelText={inputData.LabelText} Disabled={Disabled || inputData.Disabled} ValueChanged={this.props.ValueChanged}
					Model={(this.props.Model && this.props.Model.DateFilter) || {}} BeginDate={inputData.BeginDate} EndDate={inputData.EndDate}
					MinDate={IsReport ? inputData.BeginDate : null} MaxDate={IsReport ? inputData.EndDate : null}
				/>;
			case "email":
				return <GenericEmailInput
					Required={inputData.IsRequired || false} IsInvalid={IsInvalid || false} LabelMd={this.props.LabelMd} Name={inputData.Name} LabelText={inputData.LabelText}
					Disabled={Disabled || inputData.Disabled} Value={this.props.Value} ValueChanged={this.props.ValueChanged}
				/>;
			default:
				return "";
		}
	}

	render() {
		const { ViewData } = this.props;

		if (!ViewData)
			return "";

		return (
			<div>
				{this.GenerateInput(ViewData)}
			</div>
		);
	}
}

GenericInput.propTypes = {
	Model: PropTypes.object,
	ViewData: PropTypes.object,
	ValueChanged: PropTypes.func,
	FilterParameter: PropTypes.object,
	IsDCBankAccount: PropTypes.bool,
	Disabled: PropTypes.bool,
	LabelMd: PropTypes.number
};

export default GenericInput;