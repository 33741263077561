import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericCheckInput, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericLabel, GenericTitle } from "views/Components/Generic";
import { MonthsOfYear, GridColumnType, MenuCodes } from "views/Constants/Constant.js";

class ChannelandSettlementReport extends React.Component {
	constructor(props) {
		super(props);

		let IsChannelReport = this.props.MenuCode == MenuCodes.Menu_ChannelReport;

		this.columns = [
			{
				Header: "Year",
				accessor: "Year",
			},
			{
				Header: "Month",
				accessor: "Month"
			},
			{
				Header: "Tranasction Entry Type",
				accessor: "TransactionEntryValue",
				show: IsChannelReport
			}, {
				Header: "Card Transaction Source",
				accessor: "CardTransactionSourceValue",
				show: IsChannelReport
			},
			{
				Header: "Bin",
				accessor: "Bin",
				show: !IsChannelReport
			},
			{
				Header: "Bin Description",
				accessor: "BinDescription",
				show: !IsChannelReport
			},

			{
				Header: "Network Name",
				accessor: "NetworkTypeName",
				show: !IsChannelReport
			},
			{
				Header: "AccountName",
				accessor: "AccountName",
				show: false
			},
			{
				Header: "AccountNumber",
				accessor: "AccountNumber",
				show: false
			},
			{
				Header: "Total Amount",
				accessor: "TotalAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Total Count",
				accessor: "Count"
			}
		];

		this.emptyObject = {};

		this.sortedName = { Member: "Name" };
		this.sortedCode = { Member: "Code" };

		this.state = {
			model: {},
			Month: [],
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("PREPAID CARD MANAGEMENT");
		}

		if (this.props.MenuCode == MenuCodes.Menu_ChannelReport) {
			setAppLeftTitle("Channel Report");
		}
		else {
			setAppLeftTitle("Settlement Report");
		}
		this.SetYearList();
	}

	SetYearList = () => {
		var currentYear = parseInt(DateHelper.GetYear());
		var listYear = [];
		var listAge = [];

		for (var i = 2019; i <= currentYear; i++) {
			listYear.push({
				"Year": i
			});
		}
		for (var i = 18; i <= 100; i++) {
			listAge.push({
				"Age": i
			});
		}
		this.setState({ listAge, listYear });
	}
	handleListItemChange = (name, newValue, key, ObjectName) => {
		var model = { ...this.state.model };
		const array = this.state.Month;
		const item = array.includes(key);
		if (item == false && newValue == true) {
			array.push(key);
		} else if (item == true && newValue == false) {
			var index = array.indexOf(key);
			if (index > -1) {
				array.splice(index, 1);
			}
		}
		model["Month"] = array;
		this.setState({ Month: array, model });

	}


	ValueChanged = (name, value) => {
		this.setState(state => {
			var model = state.model;
			model[name] = value;

			return { model };
		});
	}

	Search = async () => {
		const { model } = this.state;
		const { ExecuteApiPost } = this.props;
		if (this.props.MenuCode == MenuCodes.Menu_ChannelReport) {
			var result = await ExecuteApiPost("/prepaidapi/v1.0/TransactionData/ChannelReport", model, null, null, null);
			this.setState({ list: result || [] });

		} else {
			var result1 = await ExecuteApiPost("/prepaidapi/v1.0/TransactionData/SettlementReport", model, null, null, null);
			this.setState({ list: result1 || [] });
		}

	}

	Clear = () => {
		this.setState({ model: {}, list: [], selected: null, Month: [] });
	}

	Validate = () => {
		var { model } = this.state;
		const { showValidationErrors } = this.props;
		var errors = [];

		if (!model.ParentMenuId
			|| model.ParentMenuId == -2) {
			errors.push("Parent menu can not be null.");
		}
		if (!model.SubMenuId) {
			errors.push("Sub menu can not be null.");
		}
		if (!model.ActionId) {
			errors.push("Action can not be null.");
		}

		if (errors.length > 0) {
			showValidationErrors(errors);

			return false;
		}

		return true;
	}



	render() {
		const { model, list, listYear, listAge, Month } = this.state;
		const { Disabled } = this.props;
		let IsChannelReport = this.props.MenuCode == MenuCodes.Menu_ChannelReport;

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},

						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Panel">
									<GridContainer>
										<GridItem xs={3}>
											<GenericSelectInput
												IsStatic
												Name="Year"
												LabelText="Year"
												StaticData={listYear}
												KeyValueMember="Year"
												TextValueMember="Year"
												Value={model.Year}
												ValueChanged={this.ValueChanged}
											// Required
											/>
										</GridItem>

										{(!IsChannelReport) &&
											<GridItem xs={3}>
												<GenericSelectInput
													Name="OnusBinId"
													LabelText="Bin"
													Method="GET"
													Url="/prepaidapi/v1.0/OnusBin/GetAll"
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={d => `${d.Bin} - ${d.Description}`}
													Value={model.OnusBinId}
													ValueChanged={this.ValueChanged}
												/>
											</GridItem>
										}
										{(!IsChannelReport) &&
											<GridItem xs={3}>
												<GenericSelectInput
													Name="NetworkId"
													LabelText="Network Name"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={{ ParameterCode: "SwitchNetworkType" }}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={model.NetworkId}
													ValueChanged={this.ValueChanged}
												// IsInvalid={vModel.GenderId}
												// Required
												/>
											</GridItem>
										}
										{(IsChannelReport) &&

											<GridItem xs={3}>
												<GenericSelectInput
													Name="TranasctionEntryTypeId"
													LabelText="Tranasction Entry Type"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={{ ParameterCode: "TransactionEntryType" }}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={model.TranasctionEntryTypeId}
													ValueChanged={this.ValueChanged}
												// IsInvalid={vModel.GenderId}
												// Required
												/>
											</GridItem>
										}
										{(IsChannelReport) &&

											<GridItem xs={3}>
												<GenericSelectInput
													Name="CardTransactionSourceId"
													LabelText="Card Transaction Source"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={{ ParameterCode: "CardTransactionSource" }}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={model.CardTransactionSourceId}
													ValueChanged={this.ValueChanged}
												// IsInvalid={vModel.GenderId}
												// Required
												/>
											</GridItem>
										}
										<GridItem xs={3}>  			</GridItem>
										<GridItem xs={1} style={{ marginTop: "10px" }}>
											<GenericLabel
												Text="Month"
												LabelMd={6}
											/>
										</GridItem>
										<GridItem xs={10} style={{   marginTop: "10px" }}>
											{MonthsOfYear != null && MonthsOfYear.map(item => (
												<GenericCheckInput
													key={"MonthsOfYear" + item.Id}
													Name="Id"
													LabelText={item.Text}

													Value={Month.includes(item.Id)}
													ValueChanged={(name, value) => this.handleListItemChange(name, value, item.Id, "MonthsOfYear")}
												/>
											))}

										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columns}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

ChannelandSettlementReport.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any,
	classes: PropTypes.object

};
export default withArtifex(ChannelandSettlementReport, {});
