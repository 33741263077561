import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import { PropTypes } from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericExpansionPanel, GenericGrid, GenericLabel, GenericNumberInput, GenericSelectInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType, ProgramCodes } from "views/Constants/Constant";


class AutoLoadingInstruction extends React.Component {
	constructor(props) {
		super(props);

		this.isClient = ClientHelper.IsClient();

		this.initialModel = {
			Id: 0,
			Customer: null,
			CustomerNumber: null,
			CustomerId: 0,
			IsPeriodic: false,
			IsExternalAccount: false,
			IsClientInstruct: true,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined,
			SourceAccountId: 0,
			AccountId: 0,
			ProgramId: 0
		};
		
		this.state = {
			list: [],
			model: { ...this.initialModel },
			vModel: {},
			isLoading: false,
			rowIndex: -1
		};

		this.emptyObject = {};
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Auto-Loading To Sub Wallet");
		this.props.setAppCenterTitle("WALLET");
	}

	HandleChange = (name, value, data) => {
		const model = { ...this.state.model };
		model[name] = value;
		if(name == "SourceAccountId"){
			model["ProgramId"] = data.ProgramId;
		}
		this.setState({ model });
	}

	RowSelected = (index) => {
		const model = this.state.list[index];
		this.setState({ model, rowIndex: index });
	}

	HandleClear = () => {
		const nModel={...this.state.model};

		this.setState({ model: {
			Id : 0,
			Customer : null,
			CustomerNumber : null,
			CustomerId : 0,
			IsPeriodic : false,
			IsExternalAccount : false,
			IsClientInstruct : true,
			UniqueClientId : ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined,
			SourceAccountId : nModel.SourceAccountId,
			AccountId : 0,
			ProgramId : 0
		}, rowIndex: -1 });
	}

	HandleGetList = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/AutoLoadingInstruction/GetAllSubCustomer",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					this.setState({ list: responseData.Item });
				} else {
					this.setState({ list: [] });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	HandleSubmit = () => {
		if (!this.Validate())
			return;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/AutoLoadingInstruction/InsertOrUpdateCustomerAutoLoadingInstruction",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessage("success", "Success", "Operation is successfully!");
				this.HandleClear();
				this.HandleGetList();
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while sending data", error.message);
			}
		);
	}

	HandleDelete = () => {
		this.ShowConfirmMessage("warning", "Warning", "Are you sure do you want to remove the Auto-load Instruction?", () => {
			this.setState({ isLoading: true });
			Proxy.GET(
				"/bankapi/v1.0/AutoLoadingInstruction/Delete/Id?Id=" + this.state.model.Id,
				(responseData) => {
					this.setState({ isLoading: false });
					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					this.ShowMessage("success", "Deleted successfully!");
					this.HandleClear();
					this.HandleGetList();
				},
				(error) => {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "An error occurred while deleting data", error.message);
				}
			);
		});
	}

	Validate = () => {
		const { model, vModel } = this.state;
		var messages = [];

		vModel.SourceAccountId = !model.IsExternalAccount && model.SourceAccountId == null;
		if (vModel.SourceAccountId) messages.push("Source Wallet must be select");

		vModel.ExternalSourceAccountId = model.IsExternalAccount && model.SourceAccountId == null;
		if (vModel.ExternalSourceAccountId) messages.push("Source Wallet must be select");

		vModel.DestinationAccountId = !model.IsExternalAccount && model.DestinationAccountId == null;
		if (vModel.DestinationAccountId) messages.push("Receiver Wallet must be select");

		vModel.ExternalDestinationAccountId = model.IsExternalAccount && model.DestinationAccountId == null;
		if (vModel.ExternalDestinationAccountId) messages.push("Receiver Wallet must be select");

		vModel.MinimumBalance = !model.IsPeriodic && (model.MinimumBalance == null || model.MinimumBalance == 0);
		if (vModel.MinimumBalance) messages.push("Minimum Balance cannot be empty");

		vModel.Amount = model.Amount == null || model.Amount == 0;
		if (vModel.Amount) messages.push("Transaction Amount cannot be empty");

		vModel.UserId = model.UserId == null;
		if (vModel.UserId) messages.push("User must be select");

		if (messages.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.setState({ vModel });
		return true;
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: "" })} OnConfirm={onConfirm} />
		});
	}

	RenderItemClientCustomer = (d) => {
		return `${d.AccountNumber} - ${d.AccountName}`;
	}

	render() {
		const { Disabled } = this.props;
		const { alert, model, vModel, list, isLoading } = this.state;
		return (
			<>
				<LoadingComponent Show={isLoading} />

				{alert}

				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.HandleGetList, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled || model === this.initialModel },
					{ Code: "Delete", OnClick: this.HandleDelete, Disabled: Disabled || model.Id === 0 },
					{ Code: "Submit", OnClick: this.HandleSubmit, Disabled: Disabled || model.Id > 0, ModelFunction: () => model, FillDataFromModel: model => this.setState({ model }), ValidationFunction: this.Validate },
					{ Code: "Update", OnClick: this.HandleSubmit, Disabled: Disabled || model.Id === 0, ModelFunction: () => model, FillDataFromModel: model => this.setState({ model }), ValidationFunction: this.Validate },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Parameters">
									<GridContainer>
										<GridItem xs={6}>
											<GridContainer>
												<GridItem xs={9} />
												<GridItem xs={6} style={{ marginTop: 16 }}>
													<GenericLabel Text="From Wallet" FontSize="12px" Bold={true} />
												</GridItem>
												<GridItem xs={6} style={{ marginTop: 16 }}>
													<GenericLabel Text="To Wallet" FontSize="12px" Bold={true} />
												</GridItem>
												<GridItem xs={6} style={{ opacity: model.IsExternalAccount ? 0.4 : 1 }}>
													<GenericSelectInput
														Name="SourceAccountId"
														LabelMd={5}
														LabelText={`${window.Title} Account`}
														Method="POST"
														Url="/bankapi/v1.0/Account/GetAllSubCustomerAccountByUniqueClient"
														Parameter={{}}
														DataRoot="Item"
														KeyValueMember="AccountId"
														TextValueMember="AccountName"
														Value={model.SourceAccountId}
														ValueChanged={this.HandleChange}
														Disabled={model.SourceAccountId}
														Sorted={{ Member: "AccountName" }}
														All
														DefaultIndex={this.isClient ? 0 : -1}
														IsInvalid={vModel.ProgramId} 
													/> 
												</GridItem>
												<GridItem xs={6} style={{ opacity: model.IsExternalAccount ? 0.4 : 1 }}>
													<GenericSelectInput
														key={model.ProgramId}
														Name="DestinationAccountId"
														LabelMd={5}
														LabelText={`${window.Title} Account`}
														Method="POST"
														Url="/bankapi/v1.0/Account/GetAllSubCustomerNumber"
														Parameter={{ProgramId: model.ProgramId}}
														DataRoot="Item"
														KeyValueMember="AccountId"
														RenderItem={this.RenderItemClientCustomer}
														Value={model.IsExternalAccount ? "" : model.DestinationAccountId}
														ValueChanged={this.HandleChange}
														Sorted={{ Member: "AccountId" }}
														IsInvalid={vModel.ProgramId}
													/>
												</GridItem>
											</GridContainer>
										</GridItem>
										<GridItem xs={6}>
											<GridContainer>
												<GridItem xs={6}>
													<GenericNumberInput
														Name="MinimumBalance"
														LabelMd={7}
														LabelText="Min. Available Balance for Auto-Load"
														Value={model.MinimumBalance}
														ValueChanged={this.HandleChange}
														MaxLength={10}
														Prefix="$"
														IsInvalid={vModel.MinimumBalance} 
													/>
												</GridItem>
												<GridItem xs={6} />
												<GridItem xs={6}>
													<GenericNumberInput
														Name="Amount"
														LabelMd={7}
														LabelText="Auto-Loading Amount"
														Value={model.Amount}
														ValueChanged={this.HandleChange}
														MaxLength={10}
														Prefix="$"
														IsInvalid={vModel.Amount} 
													/>
												</GridItem>
												<GridItem xs={6}>
													<GenericSelectInput
														Name="UserId"
														LabelMd={5}
														LabelText="User to Send Mail"
														Method="GET"
														Url="/bankapi/v1.0/AutoLoadingInstruction/GetUsers"
														Parameter={this.emptyObject}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="UserEmail"
														Value={model.UserId}
														ValueChanged={this.HandleChange}
														IsInvalid={vModel.UserId} 
													/>
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={"Instruction List"} />
							</CardHeader>
							<CardBody>
								<GridContainer spacing={16}>
									<GridItem xs={12}>
										<GenericGrid
											Data={list}
											Columns={[
												{
													Header: "From Wallet",
													accessor: "SourceAccountName"
												},
												{
													Header: "To Wallet",
													accessor: "NumberAndName"
												},
												{
													Header: "Minimum Available Balance",
													accessor: "MinimumBalance",
													ColumnType: GridColumnType.Money,
													type: GridColumnType.Money,
												},
												{
													Header: "Auto-Loading Amount",
													accessor: "Amount",
													ColumnType: GridColumnType.Money,
													type: GridColumnType.Money,
												},
												{
													Header: "User for Mail to Send",
													accessor: "User.UserEmail",
												}
											]}
											RowSelected={this.RowSelected}
											SelectedIndex={this.state.rowIndex}
											ProgramCode={ProgramCodes.Bank_Programs_Bank_Account} 
										/>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</>
		);
	}
}

AutoLoadingInstruction.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withArtifex(AutoLoadingInstruction, {});
