import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React from "react";
import { GenericTitle, GenericDateInput, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericEmailInput } from "views/Components/Generic";

class CorporateAccount extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			model: {}
		};
	}

	componentDidMount() {
		//this.props.hideSidebar();
		this.props.setAppLeftTitle("Corporate Wallet");
		this.props.setAppCenterTitle("MY PROFILE");
	}

	render() {
		const { model } = this.props;
		return (
			<Card>
				<CardBody>
					<GridContainer spacing={16}>
						<GridItem xs={4}>
							<GenericTitle text={"Corporate Wallet"} />
							<GenericTextInput
								Name="LegalName"
								LabelText="Legal Name of Business"
								Value={model == null ? "" : model.LegalName}
								LabelMd={6}
								Disabled={true} />
							<GenericTextInput
								Disabled={true}
								Name="DoingBusiness"
								LabelText="Doing Business As"
								LabelMd={6}
								Value={model == null ? "" : model.DoingBusiness} />
							{/* <GenericTextInput
								Name="BusinessIdNumber"
								LabelText="Business ID Nr. (BIN)"
								LabelMd={6}
								Value={model == null ? "" : model.BusinessIdNumber}
								Disabled={true} />
							<GenericTextInput
								Disabled={true}
								LabelMd={6}
								Name="QuebecEntNumber"
								LabelText="Quebec EnT. Number (NEQ)"
								Value={model == null ? "" : model.QuebecEntNumber} />
							<GenericSelectInput
								Disabled={true}
								LabelMd={6}
								Name="ResidencyForTaxCountryId"
								LabelText="Residency Tax (Country)"
								DataRoot="Item"
								KeyValueMember="Id"
								TextValueMember="Name"
								Method="POST"
								Url="/coreapi/v1.0/Country/GetAll"
								Parameter={{}}
								Value={model == null ? "" : model.ResidencyForTaxCountryId} />
							<GenericSelectInput
								Disabled={true}
								LabelMd={6}
								Name="ResidencyForTaxStateId"
								LabelText="Residency Tax (Province or State)"
								Method="POST"
								Url="/coreapi/v1.0/Parameter/Search"
								Parameter={{ ParameterCode: "Province" }}
								DataRoot="Item"
								KeyValueMember="Id"
								TextValueMember="ParameterDesc"
								Value={model == null ? "" : model.ResidencyForTaxStateId} />
							<GenericSelectInput
								Name="IncorporationCountryId"
								LabelText="Country Incorporation"
								Method="POST"
								Url="/coreapi/v1.0/Country/GetAll"
								LabelMd={6}
								Parameter={{}}
								DataRoot="Item"
								KeyValueMember="Id"
								TextValueMember={"Name"}
								Value={model == null ? "" : model.IncorporationCountryId}
								Disabled={true}
								Sorted={{ Member: "Name" }} />
							<GenericSelectInput
								Name="LicenseRegistrationTypeId"
								LabelText="License Registration Type"
								Method="POST"
								Url="/coreapi/v1.0/Parameter/Search"
								Parameter={{ ParameterCode: "LicenseRegistrationType" }}
								DataRoot="Item"
								LabelMd={6}
								KeyValueMember="Id"
								TextValueMember="ParameterDesc"
								Value={model == null ? "" : model.LicenseRegistrationTypeId}
								Disabled={true} />
							<GenericSelectInput
								Name="LicenseRegistrationEntityId"
								LabelText="License Registration Entity"
								Method="POST"
								LabelMd={6}
								Url="/coreapi/v1.0/Parameter/Search"
								Parameter={{ ParameterCode: "LicenseRegistrationEntity" }}
								DataRoot="Item"
								KeyValueMember="Id"
								TextValueMember="ParameterDesc"
								Value={model == null ? "" : model.LicenseRegistrationEntityId}
								Disabled={true} />
							<GenericTextInput
								Name="LicenseRegistrationNumber"
								LabelText="License Registration Nr."
								Value={model == null ? "" : model.LicenseRegistrationNumber}
								LabelMd={6}
								Disabled={true} /> */}
						</GridItem>
						<GridItem xs={4}>
							<GenericTitle text={"Communication"} />
							<GridItem>
								<GridContainer>
									<GridItem md={8} xs={8}>
										<GenericSelectInput
											Name="PhoneCountryCodeId"
											LabelText="Business Phone Number"
											LabelMd={6}
											Method="POST"
											Url="/coreapi/v1.0/Country/GetAll"
											Parameter={{}}
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember={"Code"}
											Value={model == null ? "" : model.BusinessPhone == null || undefined ? "" : model.BusinessPhone.PhoneCountryCodeId || ""}
											Disabled={true}
											Sorted={{ Member: "OrderIndex" }}
											RenderItem={d => "+" + d.Code + " (" + d.Name + ")"} />
									</GridItem>
									<GridItem md={4} xs={4}>
										<GenericNumberInput
											IsPhone
											MaxLength={10}
											Name="PhoneNumber"
											LabelMd={0}
											Value={model == null ? "" : model.BusinessPhone == null || undefined ? "" : model.BusinessPhone.PhoneNumber || ""}
											Disabled={true} />
									</GridItem>
								</GridContainer>
							</GridItem>
							<GridItem>
								<GridContainer>
									<GridItem md={8} xs={8}>
										<GenericSelectInput
											Name="PhoneCountryCodeId"
											LabelText="Fax Phone Number"
											LabelMd={6}
											Method="POST"
											Url="/coreapi/v1.0/Country/GetAll"
											Parameter={{}}
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember={"Code"}
											Value={model == null ? "" : model.FaxPhone == null || undefined ? "" : model.FaxPhone.PhoneCountryCodeId || ""}
											Disabled={true}
											Sorted={{ Member: "OrderIndex" }}
											RenderItem={d => "+" + d.Code + " (" + d.Name + ")"} />
									</GridItem>
									<GridItem md={4} xs={4}>
										<GenericNumberInput
											IsPhone
											MaxLength={10}
											LabelMd={0}
											Name="PhoneNumber"
											Value={model == null ? "" : model.FaxPhone == null || undefined ? "" : model.FaxPhone.PhoneNumber || ""}
											Disabled={true} />
									</GridItem>
								</GridContainer>
								<GenericEmailInput
									Name="Email"
									LabelText="E-Mail"
									Value={model == null ? "" : model.Email}
									Disabled={true} />
								<GenericSelectInput
									Name="PreferedLanguageId"
									LabelText="Preferred Language"
									Method="POST"
									Url="/coreapi/v1.0/Parameter/Search"
									Parameter={{ ParameterCode: "Language" }}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="ParameterDesc"
									Value={model == null ? "" : model.PreferedLanguageId}
									Disabled={true} />
								<GenericSelectInput
									Name="SendSms"
									LabelText="Text/SMS Notification"
									Method="POST"
									Url="/coreapi/v1.0/Parameter/Search"
									Parameter={{ ParameterCode: "YesNo" }}
									DataRoot="Item"
									KeyValueMember="ParameterValue"
									TextValueMember="ParameterDesc"
									Value={String(model == null ? "" : model.SendSms)}
									Disabled={true} />
								<GenericSelectInput
									Name="SendEmail"
									LabelText="E-Mail Notification"
									Method="POST"
									Url="/coreapi/v1.0/Parameter/Search"
									Parameter={{ ParameterCode: "YesNo" }}
									DataRoot="Item"
									KeyValueMember="ParameterValue"
									TextValueMember="ParameterDesc"
									Value={String(model == null ? "" : model.SendEmail)}
									Disabled={true} />
							</GridItem>
						</GridItem>
						<GridItem xs={4}>
							{/* <GenericTitle text={"Other"} />
							<GenericSelectInput
								Name="EntityTypeId"
								LabelText="Type of Entity"
								LabelMd={6}
								Method="POST"
								Url="/coreapi/v1.0/Parameter/Search"
								Parameter={{ ParameterCode: "EntityType" }}
								DataRoot="Item"
								KeyValueMember="Id"
								TextValueMember="ParameterDesc"
								Value={model == null ? "" : model.EntityTypeId}
								Disabled={true} />
							<GenericSelectInput
								Disabled={true}
								LabelMd={6}
								Name="BusinessNatureId"
								LabelText="Business Nature"
								Method="POST"
								Url="/coreapi/v1.0/Parameter/Search"
								Parameter={{ ParameterCode: "BusinessNature" }}
								DataRoot="Item"
								KeyValueMember="Id"
								TextValueMember="ParameterDesc"
								Value={model == null ? "" : model.BusinessNatureId} />
							<GenericSelectInput
								Disabled={true}
								LabelMd={6}
								Name="IndustryOfEntityId"
								LabelText="Industry Of Entity"
								Method="POST"
								Url="/coreapi/v1.0/Parameter/Search"
								Parameter={{ ParameterCode: "IndustryOfEntity" }}
								DataRoot="Item"
								KeyValueMember="Id"
								TextValueMember="ParameterDesc"
								Value={model == null ? "" : model.IndustryOfEntityId} />
							<GenericSelectInput
								Name="AccountStatusId"
								LabelText="Wallet Status"
								Method="POST"
								LabelMd={6}
								Url="/coreapi/v1.0/Parameter/Search"
								Parameter={{ ParameterCode: "CustomerStatus" }}
								DataRoot="Item"
								KeyValueMember="Id"
								TextValueMember="ParameterDesc"
								Value={model == null ? "" : model.ClientStatusId}
								Disabled={true} />
							<GenericSelectInput
								Name="IsAccountUsedByTp"
								LabelText="Wallet Used By Tp"
								Method="POST"
								LabelMd={6}
								Url="/coreapi/v1.0/Parameter/Search"
								Parameter={{ ParameterCode: "YesNo" }}
								DataRoot="Item"
								KeyValueMember="ParameterValue"
								TextValueMember="ParameterDesc"
								Value={String(model == null ? "" : model.IsAccountUsedByTp)}
								Disabled={true} />
							<GenericSelectInput
								Name="PublicityTradeEntity"
								LabelText="Publicly Trade Entity"
								Method="POST"
								LabelMd={6}
								Url="/coreapi/v1.0/Parameter/Search"
								Parameter={{ ParameterCode: "YesNo" }}
								DataRoot="Item"
								KeyValueMember="ParameterValue"
								TextValueMember="ParameterDesc"
								Value={String(model == null ? "" : model.PublicityTradeEntity)}
								Disabled={true} />
							<GenericDateInput
								Name="LastEffectivenessDate"
								LabelText="Date of Last Effectiveness Review"
								LabelMd={6}
								Value={DateHelper.ToDateInputValue(model == null ? "" : model.LastEffectivenessDate)}
								Disabled={true} />
							<GenericSelectInput
								Name="FatcaId"
								LabelText="FATCA"
								Method="POST"
								LabelMd={6}
								Url="/coreapi/v1.0/Parameter/Search"
								Parameter={{ ParameterCode: "FATCA" }}
								DataRoot="Item"
								KeyValueMember="Id"
								TextValueMember="ParameterDesc"
								Value={model == null ? "" : model.FatcaId}
								Disabled={true} />
							<GenericDateInput
								Name="LastFintracDate"
								LabelMd={6}
								LabelText="Date of Last FINTRAC Review"
								Value={DateHelper.ToDateInputValue(model == null ? "" : model.LastFintracDate)}
								Disabled={true} /> */}
						</GridItem>
					</GridContainer>
				</CardBody>
			</Card>
		);
	}
}

CorporateAccount.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	model: PropTypes.object
};

export default CorporateAccount;