import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import { DeleteIcon, EditIcon } from "core/Icons";
import RouteHelper from "core/RouteHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import { GenericAlert, GenericDateInput, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTitle, GenericNumberInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { BillStatusType, GridColumnType, ProgramCodes } from "views/Constants/Constant";

class BillTransactionSearch extends Component {

	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined,
			StartDate: DateHelper.GetDate(),
			EndDate: DateHelper.GetDate(),
			IsUpdate: false,
			BillStatusTypeId: null,
			BillerId: null,
			BillerCode: "",
			ReferenceNumber: null,
			IsUniqueDataSearch: false
		};

		this.emptyObject = {};

		this.renderItemUniqueClient = {};

		this.sortedName = { Member: "Name" };

		this.state = {
			alert: null,
			model: { ...this.initialModel },
			BillList: [],
			isLoading: false,
			rowindex: 0
		};
		this.IsClient = false;
		this.GetBillList = this.GetBillList.bind(this);
	}

	ValueChanged = (name, value) => {
		const model = { ...this.state.model };
		model[name] = value;
		if (name == "ReferenceNumber") {
			model.IsUniqueDataSearch = value.length > 0;
		}
		this.setState({ model });
	}

	IsValid = () => {
		var vmodel = { ...this.state.model };

		if (!vmodel.IsUniqueDataSearch && vmodel.StartDate && typeof vmodel.StartDate != "object") {
			this.props.showMessage("warning", "Please fill required fields!", "From Date is invalid");
			return false;
		}

		if (!vmodel.IsUniqueDataSearch && vmodel.EndDate && typeof vmodel.EndDate != "object") {
			this.props.showMessage("warning", "Please fill required fields!", "To Date is invalid");
			return false;
		}

		if (!vmodel.IsUniqueDataSearch && vmodel.StartDate && vmodel.EndDate && vmodel.EndDate < vmodel.StartDate) {
			this.props.showMessage("warning", "Please fill required fields!", "From Date cannot be bigger than End Date");
			return false;
		}

		return true;
	}
	Search = () => {
		if (!this.IsValid())
			return false;

		this.setState({ isLoading: true });
		this.GetBillList();
	}

	componentDidMount() {
		var isBackOff = ClientHelper.IsBackOffice();
		var model = { ...this.state.model };
		if (!isBackOff) {
			this.IsClient = true;
			model.UniqueClientId = ClientHelper.GetClientRowId();
			model.IsUpdate = true;
			this.props.setAppLeftTitle("All Transaction List");
			this.props.setAppCenterTitle("BILL PAYMENT");
		}
		else {
			model.IsUpdate = false;

			this.props.setAppLeftTitle("All Bill List");
			this.props.setAppCenterTitle("BILL PAYMENT");
		}
		this.setState({ model });
	}

	GetBillList() {

		const { model } = this.state;
		Proxy.POST(
			"/bankapi/v1.0/Bill/GetAllTransationBill",
			{
				"BillerId": model.BillerId,
				"BillerCode": model.BillerCode,
				"UniqueClientId": model.UniqueClientId,
				"StartDate": model.StartDate,
				"EndDate": model.EndDate,
				"BillStatusTypeId": model.BillStatusTypeId,
				"ReferenceNumber": model.ReferenceNumber

			},
			responseData => {
				if (responseData.Item != null && responseData.Item.length > 0) {
					this.setState({
						isLoading: false,
						BillList: responseData.Item
					});
				}
				else {
					this.setState({
						isLoading: false,
						BillList: []
					});
				}
			}
		);
	}

	RejectBill = (model) => {
		this.setState({ isLoading: true, alert: null });
		Proxy.POST(
			"/bankapi/v1.0/Bill/RejectBillTransaction",
			{
				ReferenceNumber: model.TransactionReferenceNumber,
				UniqueClientId: model.UniqueClientId
			},
			responseData => {
				if (responseData.IsSucceeded != false) {
					this.props.showMessage("success", "Success", "Rejected Transaction Succesfuly!");

					this.setState({
						isLoading: false,
						alert: null
					});

					this.GetBillList();
				}
				else {
					this.props.showMessage("error", "Error !", responseData.ErrorDescription);
					this.setState({
						isLoading: false,
						alert: null
					});
				}
			}
		);
	}

	CancelDialog = (model) => {
		this.showQuestionCancelMessage("Are you sure you want to cancel the bill? ", model);
	}
	showQuestionCancelMessage = (message, model) => {
		this.setState({
			alert: <GenericAlert Title="Cancel" Message={message} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.CancelBill(model)} />
		});
		this.setState({ isLoading: false });
	}

	CancelBill = (model) => {
		this.setState({ isLoading: true, alert: null });
		Proxy.POST(
			"/bankapi/v1.0/Bill/CancelBillTransaction",
			{
				ReferenceNumber: model.TransactionReferenceNumber,
				UniqueClientId: model.UniqueClientId
			},
			responseData => {
				if (responseData.IsSucceeded != false) {
					this.props.showMessage("success", "Success", "Cancel Transaction Succesfuly!");

					this.setState({
						isLoading: false,
						alert: null
					});

					this.GetBillList();
				}
				else {
					this.props.showMessage("error", "Error !", responseData.ErrorDescription);
					this.setState({
						isLoading: false,
						alert: null
					});
				}
			}
		);
	}

	RejectedDialog = (model) => {
		this.showQuestionMessage("Are you sure you want to reject the bill? ", model);
	}
	hideAlert() {
		this.setState({
			alert: null,
			isLoading: false
		});
	}
	showQuestionMessage = (message, model) => {
		this.setState({
			alert: <GenericAlert Title="Reject" Message={message} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.RejectBill(model)} />
		});
		this.setState({ isLoading: false });
	}

	Clear = () => {
		this.setState({ model: { ...this.initialModel }, isEdit: false, index: -1 });
	}

	GetRenderItemUniqueClient = (x) => {
		this.renderItemUniqueClient = `${x.Name} - ${x.UniqueClientId}`;
		return this.renderItemUniqueClient;
	}

	render() {

		const { model } = this.state;
		var IsClient = ClientHelper.IsClient();
		return (
			<div >

				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: false,
							OnClick: this.Search
						},

						{
							Code: "Clear",
							Disabled: false,
							OnClick: this.Clear

						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />

				<LoadingComponent Show={this.state.isLoading} />
				<GridContainer>
					<GridItem xs={12}>
						<Card>

							<GenericExpansionPanel Title={"Filter Panel"}>
								<CardBody>
									<GridContainer >
										<GridItem xs={6}>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={this.emptyObject}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Name"
												RenderItem={this.GetRenderItemUniqueClient}
												Sorted={this.sortedName}
												All={true}
												Value={model.UniqueClientId}
												ValueChanged={this.ValueChanged}
												Disabled={this.IsClient}
											/>

											<GenericSelectInput
												key={model.UniqueClientId}
												Name="BillerCode"
												LabelText="Payee Name"
												Method="POST"
												Url="/bankapi/v1.0/Biller/GetAllBiller"
												DataRoot="Item"
												KeyValueMember="BillerCode"
												TextValueMember="BillerName"
												Parameter={{
													UniqueClientId: model.UniqueClientId,
													BillPayType: 1
												}}
												Value={model.BillerCode}
												Disabled={false}
												ValueChanged={this.ValueChanged}
											/>

											<ParameterComponent
												Name="BillStatusTypeId"
												LabelText="Status Type"
												ParameterCode="BillStatusType"
												Value={model.BillStatusTypeId}
												ValueChanged={this.ValueChanged}
												Sorted={{ Member: "ParameterDesc" }}
											/>

										</GridItem>

										<GridItem xs={6}>
											<GenericDateInput
												Utc
												Name="StartDate"
												LabelText="From Date"
												Value={model.StartDate ? DateHelper.ToDateInputValueAsUtc(model.StartDate) : ""}
												ValueChanged={this.ValueChanged}
												MaxDate={model.StartDate || DateHelper.GetDate()}
												IncludeTime={false}
											/>

											<GenericDateInput
												Utc
												Name="EndDate"
												LabelText="End Date"
												Value={model.EndDate ? DateHelper.ToDateInputValueAsUtc(model.EndDate) : ""}
												ValueChanged={this.ValueChanged}
												MinDate={model.StartDate}
												MaxDate={DateHelper.GetDate()}
											/>
											<GenericNumberInput NoFormatting Name="ReferenceNumber" LabelText="Reference Number" Value={model.ReferenceNumber} ValueChanged={this.ValueChanged} />
										</GridItem>
									</GridContainer>
								</CardBody>
							</GenericExpansionPanel>
						</Card>
					</GridItem>
				</GridContainer>
				{this.state.alert}
				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={"Transaction List"} />
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GenericGrid
											Data={this.state.BillList.map(d => {
												return {

													Actions: (
														<div>
															{IsClient == true &&
																<GridButton
																	Icon={EditIcon}
																	Disabled={
																		(d.BillStatusValue == BillStatusType.Canceled ? true : false) ||
																		(d.BillStatusValue == BillStatusType.Rejected ? true : false) ||
																		(d.BillStatusValue == BillStatusType.Payed ? true : false) ||
																		(d.IsFileWrite ? true : false)
																	}
																	OnClick={() => {
																		if (d.ClientCustomerId)
																			RouteHelper.Push(this.props.history, "/BillDefinition", "BillModel", d);
																		else
																			RouteHelper.Push(this.props.history, "/ClientBillDefinition", "BillModel", d);
																	}
																	}
																/>
															}

															{IsClient == false &&
																<GridButton
																	Icon={DeleteIcon}
																	Disabled={
																		(d.BillStatusValue == BillStatusType.Rejected ? true : false) ||
																		(d.BillStatusValue == BillStatusType.Canceled ? true : false) ||
																		(d.BillStatusValue == BillStatusType.Waiting ? true : false)
																	}
																	tooltip="Reject"
																	OnClick={() => this.RejectedDialog(d)}

																/>
															}
															{IsClient == true &&
																<GridButton
																	Icon={DeleteIcon}
																	Disabled={
																		(d.BillStatusValue == BillStatusType.Payed ? true : false) ||
																		(d.BillStatusValue == BillStatusType.Rejected ? true : false) ||
																		(d.BillStatusValue == BillStatusType.Canceled ? true : false) ||
																		(d.BillStatusValue != BillStatusType.Waiting ? true : false) ||
																		(d.IsFileWrite ? true : false)

																	}
																	tooltip="Cancel"
																	OnClick={() => this.CancelDialog(d)}

																/>
															}
														</div>
													),
													BillerName: d.BillerName,
													BillerCode: d.BillerCode,
													BillAccountNumber: d.BillAccountNumber,
													ClientCustomerName: d.ClientCustomerName,
													BillerTypeName: d.BillerTypeName,
													BillerGroupName: d.BillerGroupName,
													AutoPayInstructions: d.AutoPayInstructions,
													IsAutoPayPeriod: d.IsAutoPayPeriod,
													AutoPayAmount: d.AutoPayAmount,
													AutoPayPeriod: d.AutoPayPeriod,
													Date: d.Date,
													BillerProvinceName: d.BillerProvinceName,
													LastPayment: d.LastPayment,
													TelpaySend: d.TelpaySend,
													Amount: d.Amount,
													TotalAmount: d.TotalAmount,
													Fee: d.Fee,
													LastPaymentDate: d.LastPaymentDate,
													ClientName: d.ClientName,
													TransactionDate: d.TransactionDate,
													UniqueClientId: d.UniqueClientId,
													SettlementDate: d.TelpaySendDate,
													TransactionReferenceNumber: d.TransactionReferenceNumber,
													UserName: d.UserName,
													TransactionSource: d.TransactionSource,
													SettlementStatus: d.SettlementStatus,
													SentDate: d.SentDate,
													OutgoingFileName: d.OutgoingFileName,
													SettlementFileName: d.SettlementFileName
												};
											})}
											Columns={[
												{
													Header: "Actions",
													accessor: "Actions",
													sortable: false,
													filterable: false,
													width: 100
												},
												{
													Header: "Transaction Date",
													accessor: "TransactionDate",
													type: GridColumnType.DateTimeUtc,
													ColumnType: GridColumnType.DateTimeUtc
												},
												{
													Header: "Sent Date",
													accessor: "SentDate",
													type: GridColumnType.DateTimeUtc,
													ColumnType: GridColumnType.DateTimeUtc
												},
												{
													Header: "Settlement Date",
													accessor: "SettlementDate",
													type: GridColumnType.DateTimeUtc,
													ColumnType: GridColumnType.DateTimeUtc
												},
												{
													Header: "Transaction Status",
													accessor: "TelpaySend"
												},
												{
													Header: "Settlement Status",
													accessor: "SettlementStatus"
												},
												{
													Header: "Unique Client Id",
													accessor: "UniqueClientId"
												},
												{
													Header: "Client Name",
													accessor: "ClientName"
												},
												{
													Header: "File Name",
													accessor: "OutgoingFileName"
												},
												{
													Header: "Settlement File Name",
													accessor: "SettlementFileName"
												},
												{
													Header: "Customer Name",
													accessor: "ClientCustomerName"
												},
												{
													Header: "Amount",
													accessor: "Amount",
													type: GridColumnType.Money,
													ColumnType: GridColumnType.Money
												},
												{
													Header: "Fee Amount",
													accessor: "Fee",
													type: GridColumnType.Money,
													ColumnType: GridColumnType.Money
												},
												{
													Header: "Payee Name",
													accessor: "BillerName"
												},
												{
													Header: "Payee Wallet No.",
													accessor: "BillAccountNumber"
												},

												{
													Header: "Total Amount",
													accessor: "TotalAmount",
													type: GridColumnType.Money,
													ColumnType: GridColumnType.Money
												},

												{
													Header: "Last Payment",
													accessor: "LastPayment",
													type: GridColumnType.Money,
													ColumnType: GridColumnType.Money
												},
												{
													Header: "Last Payment Date",
													accessor: "LastPaymentDate",
													type: GridColumnType.DateUtc
												},
												{
													Header: "Auto-pay Intructions",
													accessor: "IsAutoPayPeriod",
													Cell: row => (
														<div>{row.value ? "Yes" : "No"}</div>
													),
												},
												{
													Header: "Auto-pay Period",
													accessor: "AutoPayPeriod"
												},
												{
													Header: "Auto-pay Amount",
													accessor: "AutoPayAmount",
													type: GridColumnType.Money,
													ColumnType: GridColumnType.Money
												},
												{
													Header: "Transaction Source",
													accessor: "TransactionSource",
													Cell: row => (
														<div>
															{row.value == "Manuel" ? "Manuel"
																: row.value == "Sftp" ? "Sftp"
																	: row.Value == "FileUpload" ? "File Upload"
																		: "Api"
															}


														</div>
													),
												},
												{
													Header: "Reference Number",
													accessor: "TransactionReferenceNumber"
												}
											]}
											IsSorted={false}
											SelectedIndex={this.state.rowIndex}
											ShowPagination={true}
											ProgramCode={ProgramCodes.Bank_Programs_Bank_Account} />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

			</div >
		);
	}
}

BillTransactionSearch.propTypes = {
	classes: PropTypes.object.isRequired,
	model: PropTypes.object,
	onModelChange: PropTypes.func,
	Disabled: PropTypes.bool
};

export default BillTransactionSearch;