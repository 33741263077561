import { ListItemIcon, withStyles } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput, GenericCheckInput } from "views/Components/Generic";
import { Proxy } from "core";
import LoadingComponent from "views/Components/LoadingComponent";
import externalContainerStyle from "assets/jss/material-dashboard-pro-react/layouts/externalContainerStyle.jsx";
import { ParameterEmptyObject } from "views/Constants/Constant.Parameter";
import { SortedName, SortedParameterValue, SortedPropertyName } from "views/Constants/Constant.Sorted";
import { GridColumnType } from "views/Constants/Constant";
import AlertHelper from "core/AlertHelper";

class CardExpireDate extends React.Component {

	constructor(props) {
		super(props);

		this.getappCenterTitle = "Empty";
		this.getappLeftTitle = "Empty";
		this.zIndex10 = { zIndex: 10 };

		this.state = {
			model: {},
			data: [],
			checkedValues: [],
			recordKeeping: [],
			isSendEmailDialogOpen: false,
			activeClient: [],
			responseDetail: {},
			isDetailDialogOpen: false
		};
		this.cpverTable = React.createRef();
	}

	componentDidMount = () => {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;
		if (setAppCenterTitle) {
			setAppCenterTitle("Card Expire Date");
			this.getappCenterTitle = "Card Expire Date";
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("Card Expire Date");
			this.getappLeftTitle = "Wallet Search";
		}

		this.setState(state => ({ checkedValues: [] }));

	}
	hideAlert = () => {
		this.setState({ alert: null, isLoading: false });
	}
	ShowErrorMessage = (message) => {
		this.setState({
			isLoading: false,
			alert: AlertHelper.CreateAlert("Warning", message, "warning", this.hideAlert)
		});
	}

	Clear = () => {
		this.setState({ model: {}, data: [], selected: null });
	}

	showLoading = () => { this.setState({ isLoading: true }); }
	hideLoading = () => { this.setState({ isLoading: false }); }

	showDetail = async (result) => {
		this.setState({ responseDetail: result || [], isDetailDialogOpen: true });
	}

	HandleCheck(trans) {

		this.setState(state => ({
			checkedValues: state.checkedValues.includes(trans)
				? state.checkedValues.filter(c => c !== trans)
				: [...state.checkedValues, trans]
		}));

		// console.log(this.state)
	}
	HandleSearch = () => {
		const model = { ...this.state.model };
		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/Card/GetExpiredDateCards",
			model,
			(responseData) => {
				this.setState({ isLoading: false, checkedValues: [] });

				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ data: responseData.Item });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}
	Submit = () => {
		if (this.state.checkedValues.length > 0) {
			this.props.showConfirmMessage("warning", "Warning", "Are you sure you want to continue?", () => {

				this.setState({ isLoading: true });

				var model = this.state.checkedValues.map(item => {
					return {
						CardId: item.CardId,
						ClientCustomerId: item.ClientCustomerId,
						ProductId: item.ProductId,
						EmbossName: item.EmbossName1,
						EmbossType: item.EmbossType,
						InstantEmbossBranchId: item.InstantEmbossBranchId,
						UniqueClientId: this.state.model.UniqueClientId
					}
				})

				Proxy.POST(
					"/prepaidapi/v1.0/Prepaid/RenewCardList",
					model,
					(responseData) => {
						this.setState({ isLoading: false });
						if (!responseData.IsSucceeded) {
							this.props.showMessage("error", "Error", responseData.ErrorDescription);
							return;
						}
						if (responseData.Item !== null) {
							this.props.showMessage("success", "Succeeded", responseData.Item.ResultMessage);
							this.HandleSearch();
						}
					},
					(error) => {
						this.setState({ isLoading: false });
						this.props.showMessage("error", "Error", error);
					}
				);
			})
		}
		else {
			this.ShowErrorMessage("Please select at least one data. ");
		}

	}


	handleChange = (name, newValue) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	};

	GetRenderItemClient = (x) => {
		var render = `${x.Name} - ${x.UniqueClientId}`;

		return render;
	}

	ValueChanged = (name, value, data) => {
		const model = { ...this.state.model };
		model[name] = value;
		this.setState({ model });
	}

	validate = () => {
		const model = { ...this.state.model };
		if (model.UniqueClientId == null || model.UniqueClientId == 0) {
			this.props.showMessageNode("warning", "Please Select Client!");
			return false;
		}
		else {
			return true;
		}
	}

	render() {
		const { model, selected, isLoading, data, alert } = this.state;
		const { Disabled } = this.props;

		return (
			<div>
				<LoadingComponent Show={isLoading} />
				{alert}
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.HandleSearch
						},
						{
							Code: "Submit",
							Disabled: Disabled,
							OnClick: this.Submit
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />

				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Filter">
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												Required={model.ReviewTypeIsPeriodic}
												IsInvalid={model.IsUniqueClientId}
												Name="UniqueClientId"
												LabelText="Client"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Method="POST"
												Parameter={ParameterEmptyObject}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={this.GetRenderItemClient}
												Sorted={SortedName}
												Value={model.UniqueClientId}
												ValueChanged={this.ValueChanged} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>

						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={data}
													SelectedIndex={selected}
													IsSorted={true}
													PageSize={10}
													ShowPagination={true}
													ref={this.cpverTable}
													Columns={[
														{
															Header: "Actions",
															accessor: "Actions",
															Cell: row => (
																<div>
																	<GenericCheckInput
																		Grid
																		Value={row.original.IsRenewed == true ? true : this.state.checkedValues.filter(c => c.CardId == row.original.CardId).length > 0}
																		ValueChanged={() => { this.HandleCheck(row.original); }}
																		Disabled={row.original.IsRenewed == true}
																	/>
																</div>
															)
														},
														{
															Header: "Card Id",
															accessor: "CardId"
														},
														{
															Header: "Customer Number",
															accessor: "CustomerNumber"
														},
														{
															Header: "Client Name",
															accessor: "ClientName"
														},
														{
															Header: "Emboss Name 1",
															accessor: "EmbossName1"
														},
														{
															Header: "Expire Date",
															accessor: "ExpireDate",
															type: GridColumnType.DateTime,
															ColumnType: GridColumnType.DateTime
														},
														{
															Header: "Last Reneval Date",
															accessor: "LastRenevalDate",
															type: GridColumnType.DateTime,
															ColumnType: GridColumnType.DateTime
														},
														{
															Header: "CardFinancial Id",
															accessor: "CardFinancialId"
														},
														{
															Header: "Card Token Number",
															accessor: "CardTokenNumber"
														},
														{
															Header: "Is Main Card",
															accessor: "IsMainCard",
															type: GridColumnType.YesNo,
															ColumnType: GridColumnType.YesNo
														},
														{
															Header: "Is Renewed",
															accessor: "IsRenewed",
															type: GridColumnType.YesNo,
															ColumnType: GridColumnType.YesNo
														}
													]}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

CardExpireDate.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	ExecuteApiFileDownloadWithGenericResponse: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default withStyles(externalContainerStyle)(CardExpireDate);