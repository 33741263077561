import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericExpansionPanel, GenericLabel, GenericTextInput } from "views/Components/Generic";
import { BillPayType } from "views/Constants/Constant";

class BillFromFile extends Component {

	constructor(props) {
		super(props);

		this.fileInputRef = React.createRef();

		this.UploadClick = this.UploadClick.bind(this);
		this.FileSelect = this.FileSelect.bind(this);
		this.ValueChanged = this.ValueChanged.bind(this);
	}

	UploadClick() {
		this.fileInputRef.current.click();
	}

	FileSelect(e) {
		e.preventDefault();

		var file = e.target.files[0];

		if (!file) return;

		this.props.onModelChange(model => {
			model.File = file;
			model.FileName = file.name;
			return model;
		});
	}

	ValueChanged(name, newValue) {
		this.props.onModelChange(model => {
			model[name] = newValue;
			return model;
		});
	}

	render() {
		const { model, vModel, Disabled: DisabledProp ,parentModel} = this.props;

		const Selected = parentModel.BillPayType == BillPayType.File;
		const Disabled = DisabledProp || !Selected;

		return (
			<div style={{ opacity: Selected ? 1.0 : 0.4 }}>
				<Card>
					<CardBody style={{ border: `1px solid ${vModel.File == true ? window.InvalidFieldColor : window.RequiredFieldColor}` }}>
						<GenericExpansionPanel IsActive={Selected} Title="File Upload">
							<GridContainer>
								<GridItem xs={3} >
									<GridItem>
										<GenericTextInput LabelMd={3} Name="FileName" LabelText="File Name" Value={model.FileName} Disabled={true} />
									</GridItem>
								</GridItem>
								<GridItem xs={2}>
									{/* <GridItem>
										<div style={{ marginTop: 1 }}></div>
										<GenericCheckInput Name="AutoApprove" LabelText="Automatic Approval" Value={model.AutoApprove} ValueChanged={this.ValueChanged} Disabled={Disabled} />
									</GridItem> */}
								</GridItem>
								<GridItem xs={2} style={{ marginLeft: "50px", marginTop: "1px" }}>
									<GridItem>
										<GenericLabel Text="Upload File" />
										<Button onClick={this.UploadClick} disabled={Disabled} style={{ marginLeft: "16px", marginTop: 0, lineHeight: "4px" }}>Browse</Button>
										<input key={model.FileName} type="file" style={{ display: "none" }} onChange={this.FileSelect} ref={this.fileInputRef}
											accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel" />
									</GridItem>
								</GridItem>
							</GridContainer>
						</GenericExpansionPanel>
					</CardBody>
				</Card>
			</div>
		);
	}
}

BillFromFile.propTypes = {
	classes: PropTypes.object.isRequired,
	model: PropTypes.object,
	vModel: PropTypes.object,
	parentModel: PropTypes.object,
	onModelChange: PropTypes.func,
	Disabled: PropTypes.bool
};

export default BillFromFile;