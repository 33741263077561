import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// core components
import Button from "components/CustomButtons/Button.jsx";

class FileUpload extends React.Component {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
		this.fileInput = React.createRef();
	}
	handleClick() {
		if (this.props.Disabled == true) {
			return;
		}
		this.fileInput.current.click();
	}
	render() {
		var { buttonProps, size, accept } = this.props;
		return (
			<div className="fileinput text-center">
				<input type="file" onChange={this.props.onFileChange} ref={this.fileInput} accept={accept}/>
				<Button {...buttonProps} size={size} onClick={() => this.handleClick()}>Browse</Button>
			</div>
		);
	}
}

FileUpload.propTypes = {
	buttonProps: PropTypes.object,
	onFileChange: PropTypes.func,
	Disabled: PropTypes.bool,
	size: PropTypes.oneOf(["sm", "lg", "md"]),
	accept: PropTypes.string
};

export default FileUpload;
