import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import { ProfileIcon } from "core/Icons";
import { TaskPool } from "core/TaskPool";
import PropTypes from "prop-types";
import React from "react";
import AssignDDTAccountComponent from "views/Components/AssignDDTAccountComponent.jsx";
import BeneficiaryComponent from "views/Components/BeneficiaryComponent";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import CustomerComponent from "views/Components/CustomerComponent.jsx";
import CustomerInformationComponent from "views/Components/CustomerInformationComponent";
import { GenericAlert, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import { ClientType, GridColumnType, ProgramCodes, ReportType, WalletAutoLoadType } from "views/Constants/Constant.js";
import WalletAccount from "./Steps/WalletAccount";

class Wallet extends React.Component {
	constructor(props) {
		super(props);
		this.getappCenterTitle = "Empty";
		this.getappLeftTitle = "Empty";
		this.initialGeneralModel = {
			Id: 0
		};

		this.initialModel = {
			Id: 0,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined,
			isPanelActive: false
		};

		this.initialCustomerModel = {
			Accounts: [],
			Programs: [],
			LinkedAccounts: []
		};

		this.state = {
			model: this.initialModel,
			vModel: {},
			linkedAccountModel: this.initialGeneralModel,
			accountModel: this.initialGeneralModel,
			accountList: [],
			accountIndex: -1,
			cardList: [],
			customerModel: { ...this.initialCustomerModel },
			tempModel: {},
			customerAddress: {},
			hasChangedLinkedAccount: false,
			hasChangedAccount: false,
			isLoading: false,
			KnownCustomer: false
		};
		// preserve the initial state in a new object
		this.baseState = this.state;

		this.maxLength19 = { maxLength: 19 };

		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined
		};

		this.renderItemUniqueClient = {};

		this.loadTaskPool = new TaskPool(this.handleLoadTaskPoolAppend, this.handleLoadTaskPoolCompleted);

		this.ColumnsCard = [
			{
				Header: "Wallet Number",
				accessor: "WalletAccountNumber"
			},
			{
				Header: "Masked Card Number",
				accessor: "MaskedCardNumber"
			},
			{
				Header: "Cardholder Name",
				accessor: "EmbossName1"
			},
			{
				Header: "Balance",
				accessor: "CardBalance",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Pending Balance",
				accessor: "CardPendingBalance",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Usage Period",
				accessor: "CardUsagePeriod.ParameterDesc"
			},
			{
				Header: "Usage Limit",
				accessor: "CardUsageLimit",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			}
		];
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Wallet"); this.getappLeftTitle = "Wallet";
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT"); this.getappCenterTitle = "CUPREPAID CARD MANAGEMENT";
	}

	handleLoadTaskPoolCompleted = () => {
		this.setState({ isLoading: false });
	}

	handleLoadTaskPoolAppend = () => {
		this.setState({ isLoading: true });
	}

	HandleChange = (name, newValue) => {
		const model = { ...this.state.model };

		if (name === "Customer") {
			if (newValue) {
				model.CustomerId = newValue.Id;
				model.CustomerNumber = newValue.CustomerNumber;
				model.CustomerName = newValue.CustomerName;
				this.SelectedRowChangeCustomer(newValue);
				this.HandleSearch(model);

			} else {
				delete model.CustomerId;
				delete model.CustomerNumber;
				delete model.CustomerName;
			}
		}
		if (name === "UniqueClientId") {
			model.CustomerId = 0;
			delete model.CustomerNumber;
		}
		model[name] = newValue;
		this.setState({ model });
	}

	HandleChangeLinkedAccount = (name, newValue, data) => {
		const linkedAccountModel = { ...this.state.linkedAccountModel };
		linkedAccountModel[name] = newValue;

		if (name === "FinancialInstitutionId")
			linkedAccountModel.FinancialInstitution = data;

		if (name === "FinancialInstitutionBranchId")
			linkedAccountModel.FinancialInstitutionBranch = data;

		if (name == "FinancialInstitutionId") {
			linkedAccountModel.BankNumber = data.FinancialInstitutionId;
			// linkedAccountModel.FinancialInstitutionBranchId = null;
			// linkedAccountModel.BranchNumber = null;
		}

		if (name == "FinancialInstitutionBranchId") {
			linkedAccountModel.BranchNumber = data.TransitNumber;
		}

		this.setState({ linkedAccountModel });
	}

	HandleChangeAccount = (name, newValue, data) => {
		const accountModel = { ...this.state.accountModel };
		if (name === "BankingAccountTypeId") {
			accountModel.BankingAccountType = data;
		} else if (name === "AccountCurrency") {
			accountModel.CurrencyId = newValue;
			accountModel.Currency = data;
		}

		if (name === "ReportType" && accountModel[name] === ReportType.ReportableReadOnly) {
			this.ShowMessage("warning", "Warning", "The report type of this Wallet cannot be changed!");
			return false;
		}

		if (name === "AutoLoadTypeId") {
			accountModel.AutoLoadType = data;
			let IsFromMyAccount = data.ParameterValue === WalletAutoLoadType.MyAccounts;
			let IsFromEFT = data.ParameterValue === WalletAutoLoadType.EFT;
			let IsFromETransfer = data.ParameterValue === WalletAutoLoadType.eTransfer;
			if (IsFromMyAccount) {
				delete accountModel.BeneficiaryId;
				delete accountModel.ClientCustomerPayeeId;
			}
			if (IsFromEFT) {
				delete accountModel.SourceAccountId;
				delete accountModel.ClientCustomerPayeeId;
			}
			if (IsFromETransfer) {
				delete accountModel.BeneficiaryId;
				delete accountModel.SourceAccountId;
			}
		}
		accountModel[name] = newValue;
		this.setState({ accountModel });
	}

	HandleClear = () => {
		this.setState(function (state) {
			state = this.baseState;
			return state;
		});
	}

	HandleClearLinkedAccount = () => {
		this.setState({ linkedAccountModel: { ...this.initialGeneralModel } });
	}

	HandleClearAccount = () => {
		this.setState({ accountModel: { ...this.initialGeneralModel } });
	}

	HandleSearch = (tempModel) => {

		let { model, customerModel } = this.state;

		if (!this.Validate(tempModel || model)) {
			return;
		}

		this.setState({ isLoading: true });

		this.loadTaskPool.AppendTask(
			Proxy.POST(
				"/prepaidapi/v1.0/Wallet/Search",
				tempModel || this.state.model,
				(responseData) => {
					this.setState({ isLoading: false });
					if (!responseData.IsSucceeded) {
						this.props.showMessage("error", responseData.ErrorDescription);
						return;
					}
					let customer = responseData.Item.ClientCustomer;
					if (customer != null) {
						customerModel = { ...this.initialCustomerModel, ...customer };
						model.CustomerId = customer.Id;
						model.CustomerNumber = customer.CustomerNumber;
						model.UniqueClientId = customer.UniqueClientId;
					}
					this.setState({ model, customerModel, KnownCustomer: true, accountList: responseData.Item.AccountList, cardList: responseData.Item.CardList });
					this.GetLinkedAccounts();
				},
				(error) => {
					this.setState({ isLoading: false });
					this.props.showMessage("error", "Error", error);
				}
			));
	}

	HandleSubmit = () => {

		const { model, accountList, customerModel } = this.state;


		if (!this.Validate(model)) {
			return;
		}

		this.setState({ isLoading: true });
		accountList.forEach(element => { element.UniqueClientId = model.UniqueClientId; });

		this.loadTaskPool.AppendTask(
			Proxy.POST("/prepaidapi/v1.0/Wallet/InsertOrUpdate",
				{
					UniqueClientId: model.UniqueClientId,
					CustomerId: model.CustomerId,
					AccountList: accountList,
					LinkedAccounts: customerModel.LinkedAccounts
				},
				(responseData) => {
					this.setState({ isLoading: false });
					if (!responseData.IsSucceeded) {
						this.props.showMessage("error", responseData.ErrorDescription);
						return;
					}
					this.ShowMessage("success", "Success", "Operation is successfully!");
				},
				(error) => {
					this.setState({ isLoading: false });
					this.props.showMessage("error", "Error", error);
				}
			));
	}

	GetLinkedAccounts = () => {
		const customerModel = { ...this.state.customerModel };
		this.setState({ isLoading: true });

		this.loadTaskPool.AppendTask(
			Proxy.GET(
				"/bankapi/v1.0/Beneficiary/GetByCustomerId/Id?Id=" + this.state.model.CustomerId,
				(responseData) => {
					this.setState({ isLoading: false });
					if (!responseData.IsSucceeded) {
						this.props.showMessage("error", responseData.ErrorDescription);
						return;
					}
					if (responseData.Item !== null) {
						customerModel.LinkedAccounts = responseData.Item;
						this.setState({ customerModel });
					}
				},
				(error) => {
					this.setState({ isLoading: false });
					this.props.showMessage("error", "Error", error);
				}
			));
	}

	AddLinkedAccount = (index, isEdit) => {
		const { linkedAccountModel, customerModel, hasChangedLinkedAccount } = this.state;

		linkedAccountModel.IsRecordValid = true;

		if (isEdit && index > -1) {
			customerModel.LinkedAccounts[index] = linkedAccountModel;
		} else {
			// if (customerModel.Accounts.filter(x => x.IsRecordValid).length >= 1)
			// 	this.ShowMessage("warning", "Warning", "Cannot be allowed more than one account!");
			// else
			customerModel.LinkedAccounts.push(linkedAccountModel);
		}
		this.setState({ customerModel, linkedAccountModel: { ...this.initialGeneralModel }, hasChangedLinkedAccount: !hasChangedLinkedAccount });
	}

	AddAccount = (index, isEdit) => {
		const { accountModel, accountList, hasChangedAccount } = this.state;

		accountModel.IsRecordValid = true;

		if (isEdit && index > -1) {
			accountList[index] = accountModel;
		} else {
			accountList.push(accountModel);
		}
		this.setState({ accountList, accountModel: { ...this.initialGeneralModel }, hasChangedAccount: !hasChangedAccount });
	}

	DeleteLinkedAccount = (index) => {
		const { customerModel, hasChangedLinkedAccount } = this.state;
		customerModel.LinkedAccounts[index].IsRecordValid = false;
		this.setState({ customerModel, linkedAccountModel: { ...this.initialGeneralModel }, hasChangedLinkedAccount: !hasChangedLinkedAccount });
	}

	DeleteAccount = (index) => {
		const { accountList, hasChangedAccount } = this.state;
		if (accountList.filter(x => x.IsRecordValid).length === 1) {
			this.ShowMessage("warning", "Warning", "Must have at least one account!");
			return;
		}
		accountList[index].IsRecordValid = false;
		this.setState({ accountList, accountModel: { ...this.initialGeneralModel }, hasChangedAccount: !hasChangedAccount });
	}

	SelectedRowChangeCustomer = (customerModel) => {
		var tempModel = { ...this.state.tempModel };

		if (customerModel != null && customerModel.ClientType != null) {
			if (customerModel.ClientType.ParameterValue === ClientType.Personal) {
				tempModel.isPersonal = true;
			}
			else {
				tempModel.isPersonal = false;
			}
		}

		this.setState({ tempModel });
	}

	SelectedRowChangeLinkedAccount = (index) => {
		var linkedAccountModel = this.state.customerModel.LinkedAccounts[index];
		this.setState({ linkedAccountModel });
	}

	SelectedRowChangeAccount = (index) => {
		var accountModel = this.state.accountList[index];
		this.setState({ accountModel, accountIndex: index });
	}

	SelectedRowLinkedChangeAccount = (index) => {
		var linkedAccountModel = this.state.customerModel.LinkedAccounts[index];
		this.setState({ linkedAccountModel: linkedAccountModel });
	}
	FillCustomer = (customer) => {
		this.HandleChange("Customer", customer);
	}

	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}

	Validate = (model) => {
		const { vModel } = this.state;

		model = model ?? this.state.model;

		var messages = [];

		if (!model) {
			this.ShowMessage("warning", "Request not fill", "Request can not be empty.");
			return false;
		}

		vModel.UniqueClientId = model.UniqueClientId == null;
		if (vModel.UniqueClientId) messages.push("Client must be select");

		vModel.CustomerId = model.CustomerId == null || model.CustomerNumber == null;
		if (vModel.CustomerId) messages.push("Customer must be select");

		if (messages.length > 0) {
			this.props.showMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.setState({ vModel });
		return true;
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: null })} OnConfirm={onConfirm} />
		});
	}

	render() {
		const { classes, Disabled } = this.props;
		const { alert, isWalletDialogOpen, model, vModel, isLoading, accountModel, accountList, cardList, customerModel, tempModel, linkedAccountModel, KnownCustomer } = this.state;

		var IsClient = ClientHelper.IsClient();

		return (
			<>

				<LoadingComponent Show={isLoading} />

				<GenericDialog fullScreen open={isWalletDialogOpen}>
					<DialogTitle>
						<GenericLabel Text="Wallet Information" Bold={true} />
					</DialogTitle>
					<DialogContent >
						<GridItem xs={12} style={{ opacity: model.CustomerId > 0 ? 1 : 0.4 }}>
							<Card className="no-radius">
								<CardBody>
									<CustomerInformationComponent
										model={customerModel}
										tempModel={tempModel}
										vModel={vModel}
										handleChange={this.HandleChange}
										Title="Wallet Information"
										ReadOnly={true} />
								</CardBody>
							</Card>
						</GridItem>
						<GenericDialogActions>
							<Button size="sm" onClick={() => this.setState({ isWalletDialogOpen: false })}>Close</Button>
						</GenericDialogActions>
					</DialogContent>
				</GenericDialog>

				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
					{ Code: "Submit", OnClick: this.HandleSubmit, Disabled: Disabled, ModelFunction: () => model, FillDataFromModel: model => this.setState({ model }), ValidationFunction: this.Validate },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card>
							<CardBody>
								<GenericExpansionPanel>
									<GridContainer>
										<GridItem xs={6}>
											<GridItem xs={8}>
												<GenericSelectInput
													LabelMd={3}
													Name="UniqueClientId"
													LabelText="Client"
													Method="POST"
													Url="/bankapi/v1.0/BankCustomer/Search"
													Parameter={this.parameterUniqueClientId}
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={this.RenderItemUniqueClient}
													Value={model.UniqueClientId}
													ValueChanged={this.HandleChange}
													CanClear
													All
													Disabled={IsClient}
													DefaultIndex={IsClient ? 0 : -1}
													Required
													IsInvalid={vModel.UniqueClientId} />
											</GridItem>
											<GridItem>
												<GridContainer>
													<GridItem xs={8}>
														<CustomerComponent
															key={"Customer_" + model.UniqueClientId + KnownCustomer}
															LabelMd={3}
															CustomerNumber={model.CustomerNumber}
															HandleChange={this.HandleChange}
															FillCallback={this.FillCustomer}
															KnownCustomer={KnownCustomer}
															Programs={[ProgramCodes.Prepaid]}
															UniqueClientId={model.UniqueClientId}
															Required
															IsInvalid={vModel.ClientCustomerId} />
													</GridItem>
													<GridItem xs={4}>
														<GridButton
															Icon={ProfileIcon}
															OnClick={() => this.setState({ isWalletDialogOpen: true })}
															tooltip="Wallet Information"
															Disabled={customerModel == null}
														/>
													</GridItem>
												</GridContainer>
											</GridItem>
										</GridItem>
										<GridItem xs={6}>
											<GridItem>
												<GenericNumberInput
													LabelText="Wallet Number"
													NoFormatting={true}
													Name="AccountNumber"
													Value={model.AccountNumber}
													ValueChanged={this.HandleChange}
													MaxLength={12} />
											</GridItem>
											<GridItem>
												<GenericTextInput
													Name="CardholderName"
													IsText
													LabelText="Cardholder Name"
													Value={model.CardholderName}
													ValueChanged={this.HandleChange} />
											</GridItem>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={12} style={{ opacity: model.CustomerId > 0 ? 1 : 0.4 }}>
						<Card className="no-radius">
							<CardBody>
								<AssignDDTAccountComponent
									key={"AssignDDTAccountComponent_" + model.CustomerId}
									ClientCustomerId={model.CustomerId}
									ReadOnly={model.CustomerId === 0}
									showMessage={this.ShowMessage}
									showMessageNode={this.ShowMessageNode}
									showConfirmMessage={this.ShowConfirmMessage}
									isPanelActive={false}
								/>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={12} style={{ opacity: model.CustomerId > 0 ? 1 : 0.4 }}>
						<Card className="no-radius">
							<CardBody>
								<BeneficiaryComponent
									key={"Beneficiary_" + [model.CustomerId, this.state.hasChangedLinkedAccount]}
									list={customerModel.LinkedAccounts}
									model={linkedAccountModel}
									handleChange={this.HandleChangeLinkedAccount}
									handleClear={this.HandleClearLinkedAccount}
									handleAddAccount={this.AddLinkedAccount}
									handleDeleteAccount={this.DeleteLinkedAccount}
									title="Linked Wallets"
									labelText="Linked Wallet"
									selectedRowChange={this.SelectedRowLinkedChangeAccount}
									showMessageNode={this.ShowMessageNode}
									ReadOnly={model.CustomerId === 0}
									isPanelActive={false}
								/>
							</CardBody>
						</Card>

					</GridItem>
					<GridItem xs={12} style={{ opacity: model.CustomerId > 0 ? 1 : 0.4 }}>
						<Card className="no-radius">
							<CardBody>
								<WalletAccount
									key={"WalletAccount_" + [model.CustomerId, this.state.hasChangedAccount]}
									beneficiaryList={customerModel.Accounts}
									list={accountList}
									model={accountModel}
									handleChange={this.HandleChangeAccount}
									handleClear={this.HandleClearAccount}
									handleAdd={this.AddAccount}
									handleDelete={this.DeleteAccount}
									selectedRowChange={this.SelectedRowChangeAccount}
									ClientCustomerId={model.CustomerId}
									UniqueClientId={model.UniqueClientId}
									showMessageNode={this.ShowMessageNode}
									showMessage={this.ShowMessage}
									ReadOnly={model.CustomerId === 0}
									getAppLeftTitle={this.getappLeftTitle }
									getAppCenterTitle={ this.getappCenterTitle}
									getMenuCode={ this.props.MenuCode}
								/>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={12} style={{ opacity: model.CustomerId > 0 ? 1 : 0.4 }}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Cards">
									<GridItem xs={12}>
										<br />
										<GenericGrid
											Data={cardList}
											Columns={this.ColumnsCard}
											HideButton={true} />
									</GridItem>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

			</>
		);
	}
}

Wallet.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	MenuCode: PropTypes.any,

};

export default withArtifex(Wallet, {});