import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ResourceHelper from "core/ResourceHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericCheckInput, GenericExpansionPanel, GenericGrid, GenericSelectInput } from "views/Components/Generic";
import { ParameterEmptyObject } from "views/Constants/Constant.Parameter";
import { SortedName } from "views/Constants/Constant.Sorted";

class MenuAction extends React.Component {
	constructor(props) {
		super(props);

		this.columns = [];

		this.state = {
			model: {
				ParentMenuId: -2
			},
			actionList: [],
			actionDetails: []
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("System Admin");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("Menu Action");
		}

		this.GetActionList();
	}

	ValueChanged = (name, value) => {
		this.setState(state => {
			var model = state.model;
			model[name] = value;

			if (name == "ParentMenuId") {
				model["SubMenuId"] = null;
			}

			return { model, actionDetails: [] };
		});
	}

	CellValueChanged = (name, value, index, isAction) => {
		var { actionDetails } = this.state;

		if (isAction) {
			actionDetails[index].Action = value;
		} else {
			actionDetails[index].WorkflowAction = value;
		}

		this.setState({ actionDetails });
		this.GetColumns(actionDetails);
	}

	GetParameterSubMenu = () => {
		const { model } = this.state;
		var result = {
			Id: model.ParentMenuId
		};

		return result;
	}

	GetActionList = async () => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/coreapi/v1.0/Action/List", {});

		this.setState({ actionList: result || [] });
	}

	GetMenuName(menu) {
		var menuName = ResourceHelper.GetForMenu(menu.Name);
		var menuNameAndCode = `${!menuName ? "! " + menu.Name : menuName} - ${menu.Code}`;

		return menuNameAndCode;
	}

	GetColumns = (actionDetails) => {
		this.columns = [
			{
				Header: "Action Code",
				accessor: "ActionCode"
			},
			{
				Header: "Action Description",
				accessor: "ActionDescription"
			},
			{
				Header: "Action",
				Cell: row => (
					<GenericCheckInput
						Name="Action"
						Value={actionDetails[row.index].Action}
						ValueChanged={(name, value) => this.CellValueChanged(name, value, row.index, true)}
					/>
				)
			},
			{
				Header: "Workflow Action",
				Cell: row => (
					<GenericCheckInput
						Name="WorkflowAction"
						Value={actionDetails[row.index].WorkflowAction}
						ValueChanged={(name, value) => this.CellValueChanged(name, value, row.index, false)}
					/>
				)
			}
		];

		this.forceUpdate();
	}

	Search = async () => {
		var { model, actionList } = this.state;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/coreapi/v1.0/MenuAction/GetByMenuId", model.SubMenuId, null, null, () => this.Validate(false));
		var actionDetails = [];

		if (result != null) {
			actionList.map(x => {
				var action = false, workflowAction = false;

				result
					.filter(y => y.ActionId === x.Id)
					.map(y => {
						action = true;
						workflowAction = y.IsWorkflowAction;

						return null;
					});

				actionDetails.push({
					"ActionId": x.Id,
					"ActionCode": x.Code,
					"ActionDescription": x.Description,
					"Action": action,
					"WorkflowAction": workflowAction
				});

				return null;
			});

			model["IsParentId"] = false;
			model["IsSubMenuId"] = false;

			this.setState({ model, actionDetails });
			this.GetColumns(actionDetails);
		}
	}

	Submit = () => {
		const { model, actionDetails } = this.state;
		const { ExecuteApiPost } = this.props;
		var menuActionList = [];

		actionDetails.map(x => {
			menuActionList.push({
				"ActionId": x.ActionId,
				"Action": x.Action,
				"WorkflowAction": x.WorkflowAction
			});

			return null;
		});

		ExecuteApiPost("/coreapi/v1.0/MenuAction/Submit", { MenuId: model.SubMenuId, MenuActionList: menuActionList }, null, null, () => this.Validate(true), null, true);
	}

	Clear = () => {
		this.setState({ model: { ParentMenuId: -2 }, actionDetails: [] });
	}

	Validate = (isSubmit) => {
		var { model, actionDetails } = this.state;
		const { showValidationErrors } = this.props;
		var errors = [];

		if (!model.ParentMenuId || model.ParentMenuId == -2) {
			errors.push("Parent menu can not be null.");
		}
		if (!model.SubMenuId) {
			errors.push("Sub menu can not be null.");
		}
		if (isSubmit && actionDetails.length == 0) {
			errors.push("Action list can not be null.");
		}

		model["IsParentId"] = !model.ParentMenuId || model.ParentMenuId == -2;
		model["IsSubMenuId"] = !model.SubMenuId;

		if (errors.length > 0) {
			showValidationErrors(errors);

			return false;
		}

		return true;
	}

	render() {
		const { model, actionDetails } = this.state;
		const { Disabled } = this.props;

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},
						{
							Code: "Submit",
							Disabled: Disabled,
							OnClick: this.Submit
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Menu Action">
									<GridContainer>
										<GridItem xs={3}>
											<GenericSelectInput
												Required
												IsInvalid={model.IsParentId}
												Name="ParentMenuId"
												LabelText="Parent Menu"
												LabelMd={3}
												Url="/coreapi/v1.0/Menu/GetMenuListForParentMenu"
												Method="GET"
												Parameter={ParameterEmptyObject}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="FullPath"
												Sorted={SortedName}
												Value={model.ParentMenuId}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												Required
												IsInvalid={model.IsSubMenuId}
												key={model.ParentMenuId}
												Name="SubMenuId"
												LabelText="Sub Menu"
												LabelMd={3}
												Url="/coreapi/v1.0/Menu/GetByMenuId"
												Method="POST"
												Parameter={this.GetParameterSubMenu()}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={this.GetMenuName}
												Sorted={SortedName}
												Value={model.SubMenuId}
												ValueChanged={this.ValueChanged} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													HideButton
													Data={actionDetails}
													Columns={this.columns} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

MenuAction.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default MenuAction;