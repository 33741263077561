import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import { EditIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericEmailInput, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton.jsx";
import LoadingComponent from "views/Components/LoadingComponent";
import ClientHelper from "core/ClientHelper";

class CampaignPartner extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			vModel: {},
			model: {},
			partnerList: [],
			alert: null,
			isLoading: false,
			isClient: ClientHelper.IsClient(),
			phone1: {},
			phone2: {}
		};
		this.getappCenterTitle = "Empty";
		this.getappLeftTitle = "Empty";
		this.ParameterMaxLen30 = { maxLength: 30 };
		this.ParameterMaxLen64 = { maxLength: 64 };
		this.ColumnsData = [
			{
				Header: "Actions",
				accessor: "actions",
				Cell: row => (
					<div>
						<GridButton
							Icon={EditIcon}
							Disabled={props.Disabled}
							OnClick={() => this.setState({
								model: { ...row.original },
								phone1: { ...row.original.Phone1 },
								phone2: { ...row.original.Phone2 }
							})} />
					</div>
				),
				sortable: false,
				filterable: false,
				width: 100
			},
			{
				Header: "Partner Name",
				accessor: "PartnerName"
			},
			{
				Header: "Legal Name",
				accessor: "LegalName"
			},
			{
				Header: "Unique ID",
				accessor: "CampaignPartnerUniqueId"
			},
			{
				Header: "Company Name",
				accessor: "CompanyName"
			},
			{
				Header: "Bussiness Id Number",
				accessor: "BussinessIdNumber"
			},
			{
				Header: "Email",
				accessor: "Email"
			},
			{
				Header: "Phone 1",
				accessor: "Phone1",
				Cell: row => (<span>{row.original.Phone1 == null ? "" : row.original.Phone1.PhoneCountryCode == null ? "" : "+" + row.original.Phone1.PhoneCountryCode.Code + " " + row.original.Phone1.PhoneNumber}</span>)
			},
			{
				Header: "Phone 2",
				accessor: "Phone2",
				Cell: row => (<span>{row.original.Phone2 == null ? "" : row.original.Phone2.PhoneCountryCode == null ? "" : "+" + row.original.Phone2.PhoneCountryCode.Code + " " + row.original.Phone2.PhoneNumber}</span>)
			},
			{
				Header: "City",
				accessor: "City.Name"
			},
			{
				Header: "Country",
				accessor: "Country.Name"
			}, {
				Header: "Zip Code",
				accessor: "ZipCode"
			},
			{
				Header: "Province",
				accessor: "Province.Name"
			},

		];

	}
	componentDidMount() {
		if (this.props.setAppCenterTitle) {
			this.props.setAppCenterTitle("Campaign Manager");this.getappCenterTitle = "Campaign Manager";
		}

		if (this.props.setAppLeftTitle)
			this.props.setAppLeftTitle("Campaign Partner");this.getappLeftTitle = "Campaign Partner";

		this.LoadCanadaPost();
	}
	ValidateEmail = (email) => {
		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}
	OperationCheck = () => {
		var ErrorList = [];
		const data = this.state.model;
		const phone1 = this.state.phone1;
		const phone2 = this.state.phone2;
		const vModel = this.state.vModel;

		if (data.PartnerName == null || data.PartnerName == "") {
			vModel.PartnerName = true;
			ErrorList.push("Campaign Partner must be defined!");
		} else { vModel.PartnerName = false; }
		if (data.BussinessIdNumber == null || data.BussinessIdNumber == "") {
			vModel.BussinessIdNumber = true;
			ErrorList.push("Bussiness ID Number must be defined!");
		} else { vModel.BussinessIdNumber = false; }
		if (data.CompanyName == null || data.CompanyName == "") {
			vModel.CompanyName = true;
			ErrorList.push("Company Name must be defined!");
		} else { vModel.CompanyName = false; }
		if (data.Email == null || data.Email == "") {
			vModel.Email = true;
			ErrorList.push("Email must be defined!");
		} else { vModel.Email = false; }
		if (data.Email != null && !this.ValidateEmail(data.Email)) {
			ErrorList.push("E-mail must be valid!");
		}
		if (data.LegalName == null || data.LegalName == "") {
			vModel.LegalName = true;
			ErrorList.push("Legal Name must be defined!");
		} else { vModel.LegalName = false; }
		if (data.SendSms == null) {
			vModel.SendSms = true;
			ErrorList.push("Text/SMS Notification must be defined!");
		} else { vModel.SendSms = false; }
		if (data.SendEmail == null) {
			vModel.SendEmail = true;
			ErrorList.push("E-Mail Notification must be defined!");
		} else { vModel.SendEmail = false; }

		if (phone1 != null && (phone1.PhoneNumber != null || phone1.PhoneCountryCodeId != null)) {
			if (phone1.PhoneNumber != null && phone1.PhoneNumber.toString().length != 10) {
				ErrorList.push("Phone 1 must be 10 Digits!")
			}
			if (phone1 == null || phone1.PhoneCountryCodeId == null || phone1.PhoneCountryCodeId == "") {
				vModel.PhoneCountryCodeId = true;
				ErrorList.push("Phone 1 Country Code must be defined!");
			} else { vModel.PhoneCountryCodeId = false; }
			if (phone1 == null || phone1.PhoneNumber == null) {
				vModel.PhoneNumber = true;
				ErrorList.push("Phone 1 must be defined!");
			} else { vModel.PhoneNumber = false; }
		}

		if (phone2 != null && (phone2.PhoneNumber != null || phone2.PhoneCountryCodeId != null)) {
			if (phone2.PhoneNumber != null && phone2.PhoneNumber.toString().length != 10) {
				ErrorList.push("Phone 2 must be 10 Digits!")
			}
			if (phone2 == null || phone2.PhoneCountryCodeId == null || phone2.PhoneCountryCodeId == "") {
				vModel.PhoneCountryCodeId = true;
				ErrorList.push("Phone 2 Country Code must be defined!");
			} else { vModel.PhoneCountryCodeId = false; }
			if (phone2 == null || phone2.PhoneNumber == null) {
				vModel.PhoneNumber = true;
				ErrorList.push("Phone 2 must be defined!");
			} else { vModel.PhoneNumber = false; }
		}

		this.setState({ vModel })

		if (ErrorList.length > 0) {
			this.props.showMessageNode("warning", "Please fill required fields!", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.UpdateModel();
	}
	handleChangeCP = (data) => {
		var temp = { ...this.state.model };
		temp.Address1 = data.Address1;
		temp.Address2 = data.Address2;
		temp.ZipCode = data.ZipCode;

		this.setState({ model: temp });
	}
	LoadCanadaPost = () => {
		Proxy.POST("/coreapi/v1.0/Parameter/Search",
			{
				ParameterCode: "CanadaPostApiKey",
				ParameterValue: "CP",
				ParameterDesc: ""
			},
			responseData => {
				if (!responseData.IsSucceeded) {
					console.log("Canada Post Api key not fetched !");
					return;
				}
				if (responseData.Item == null) {
					console.log("Canada Post Api key not fetched !");
					return;
				}
				if (responseData.Item[0] == null) {
					console.log("Canada Post Api key not fetched !");
					return;
				}
				var apikey = responseData.Item[0].ParameterDesc;
				console.log("KEY CP ", apikey);


				var url = window.CanadaPostAddressCompleteUrl;
				var path = url + apikey;

				var pca;

				var temp = this.state.model;
				var CpTemp = this.handleChangeCP;
				var CanadaPostSubmit = this.SubmitCanadaPostAddressModel;
				var CanadaPostRequest=this.SubmitCanadaPostRequest;

				const script = document.createElement("script");
				script.src = path;
				script.async = false;
				document.head.appendChild(script);
				script.onload = function () {
					pca = window.pca;
					if (pca != null) {
						var fields =
							[
								{ element: "street-address", field: "Line1", mode: pca.fieldMode.SEARCH },
								{ element: "street-address2", field: "Line2", mode: pca.fieldMode.POPULATE },
								{ element: "postcode", field: "PostalCode", mode: pca.fieldMode.SEARCH },
							],
							options = {
								key: apikey
							},
							control = new pca.Address(fields, options);
							CanadaPostRequest();
						control.listen("populate", function (address) {
							temp.Address1 = address.Line1;
							temp.Address2 = address.Line2;
							temp.ZipCode = address.PostalCode;
							CpTemp(temp);
							CanadaPostSubmit(address);
						});
					}
				};
			},
			e => {
				console.log("Canada Post Api key not fetched !");
			}
		);
	}	
	SubmitCanadaPostRequest = async () => {
		const model = { ...this.state.model };
		var requestdata = { 

			UniqueClientId: null,
			MenuCode:this.props.MenuCode,
			AppCenterTitle:this.getappCenterTitle,
			AppLeftTitle:this.getappLeftTitle
		};
		Proxy.POST(
			"/bankapi/v1.0/Reporting/CanadaPostRequestReportInsert",
			requestdata,
			responseData => {
				if (responseData.Item != null ) {
					 
				}
			},
 		);
		// var result = await this.props.ExecuteApiPost("/bankapi/v1.0/Reporting/CanadaPostRequestReportInsert", requestdata);
	}
	handleChange = (name, newValue, data) => {
		this.setState(function (state, props) {
			var temp = state.model || {};
			temp[name] = newValue;
			return { model: temp };
		});
	}
	handleChangePhone1 = (name, newValue, data) => {
		this.setState(function (state, props) {
			var temp = state.phone1 || {};
			temp[name] = newValue;
			return { phone1: temp };
		});
	}
	handleChangePhone2 = (name, newValue, data) => {
		this.setState(function (state, props) {
			var temp = state.phone2 || {};
			temp[name] = newValue;
			return { phone2: temp };
		});
	}
	SubmitCanadaPostAddressModel = (model) => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/coreapi/v1.0/Country/CanadaPost",
			model,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ isLoading: false });
				var temp = { ...this.state.model };
				if (responseData.Item != null) {
					temp.CountryId = responseData.Item.CountryId;
					temp.ProvinceId = responseData.Item.ProvinceId;
					temp.CityId = responseData.Item.CityId;
				} else {
					temp.CountryId = 0;
					temp.ProvinceId = 0;
					temp.CityId = 0;
				}
				this.setState({ model: temp });
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}
	UpdateModel = () => {
		var temp = this.state.model;
		temp.Phone1 = this.state.phone1;
		temp.Phone2 = this.state.phone2;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/campaignapi/v1.0/CampaignPartner/Update",
			temp,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.props.showMessage("success", "Success", temp.Id > 0 ? "Campaign Partner Updated ! " : "Campaign Partner Saved ! ");
				this.ClearData();
				this.SearchCampaignPartners();
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}
	SearchCampaignPartners = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/campaignapi/v1.0/CampaignPartner/Search",
			this.state.model,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ isLoading: false });
				if (responseData.Item != null) {
					this.setState({ partnerList: responseData.Item || [] });
				} else {
					this.setState({ partnerList: [] });
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}
	DeleteModel = () => {
		if (this.state.model == null || this.state.model.Id == null || this.state.model.Id == 0) {
			this.props.showMessage("error", "Error", "Campaign partner must be selected for delete operation !");
			return;
		}
		this.setState({ isLoading: true });
		Proxy.GET(
			"/campaignapi/v1.0/CampaignPartner/Delete/Id?Id=" + this.state.model.Id,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.props.showMessage("success", "Success", "Campaign partner deleted !");
				this.ClearData();
				this.SearchCampaignPartners();
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}
	SelectedRowChange = (index) => {
		const temp = this.state.partnerList[index];
		if (temp != null) {
			this.setState({ model: temp, phone1: temp.Phone1, phone2: temp.Phone2 });
		} else {
			this.setState({ model: {}, phone1: {}, phone2: {} });
		}
	}
	ClearData = () => {
		this.setState({
			model: {},
			partnerList: [],
			alert: null,
			isLoading: false,
			phone1: {},
			phone2: {}
		});
	}
	RenderItemUniqueClient = (d) => {
		return `${d.UniqueClientId} - ${d.Name}`;
	}

	render() {
		const { Disabled } = this.props;
		const { model, partnerList, phone1, phone2, vModel } = this.state;
		return (
			<div>
				{this.state.alert}

				<LoadingComponent Show={this.state.isLoading} />

				<ButtonToolbar ButtonList={[
					{ Code: "Submit", OnClick: this.OperationCheck, Disabled: Disabled || model.Id > 0 || !this.state.isClient },
					{ Code: "Update", OnClick: this.OperationCheck, Disabled: Disabled || !model.Id > 0 || !this.state.isClient },
					{ Code: "Search", OnClick: this.SearchCampaignPartners, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.ClearData, Disabled: Disabled },
					{ Code: "Delete", OnClick: () => this.props.showConfirmMessage("warning", "Warning", "Delete this campaign partner ?", this.DeleteModel), Disabled: Disabled || !model.Id > 0 || !this.state.isClient }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />


				<Card className="no-radius">
					<CardBody>
						<b><p style={{ paddingTop: "20px", marginLeft: "10px" }}> Partner Information </p></b>
						<GridContainer spacing={16}>
							{!this.state.isClient && (
								<GridItem xs={4} >
									<GenericSelectInput
										Name="UniqueClientId"
										LabelText="Client"
										Method="POST"
										Url="/bankapi/v1.0/BankCustomer/Search"
										Parameter={{}}
										DataRoot="Item"
										KeyValueMember="Id"
										RenderItem={this.RenderItemUniqueClient}
										Value={model.UniqueClientId}
										ValueChanged={this.handleChange}
										CanClear
										All
										Disabled={this.state.isClient}
										DefaultIndex={this.state.isClient ? 0 : -1}
									/>
								</GridItem>
							)}
							<GridItem xs={4}>
								<GenericTextInput
									Disabled={Disabled}
									inputProps={this.ParameterMaxLen30}
									Name="PartnerName"
									LabelText="Campaign Partner"
									Value={model.PartnerName || ""}
									ValueChanged={this.handleChange}
									Required
									IsInvalid={vModel.PartnerName}
								/>
							</GridItem>
							<GridItem xs={4}>
								<GenericTextInput
									Disabled={true}
									Name="CampaignPartnerUniqueId"
									LabelText="Campaign Partner Unique ID"
									Value={model.CampaignPartnerUniqueId || ""}
									ValueChanged={this.handleChange}
								/>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
				<Card className="no-radius">
					<CardBody>
						<GridContainer spacing={16}>
							<GridItem xs={4}>
								<b><p style={{ paddingTop: "20px" }}> General Information </p></b>
								<GenericTextInput
									Disabled={Disabled}
									inputProps={this.ParameterMaxLen30}
									Name="LegalName"
									LabelText="Legal Name"
									Value={model.LegalName || ""}
									ValueChanged={this.handleChange}
									Required
									IsInvalid={vModel.LegalName}
								/>
								<GenericTextInput
									Disabled={Disabled}
									inputProps={this.ParameterMaxLen30}
									Name="CompanyName"
									LabelText="Company Name"
									Value={model.CompanyName || ""}
									ValueChanged={this.handleChange}
									Required
									IsInvalid={vModel.CompanyName}
								/>
								<GenericNumberInput
									Disabled={Disabled}
									MaxLength={20}
									NoFormatting={true}
									LabelText="Bussiness ID Number"
									Name="BussinessIdNumber"
									Value={model.BussinessIdNumber || ""}
									ValueChanged={this.handleChange}
									Required
									IsInvalid={vModel.BussinessIdNumber}
								/>
							</GridItem>
							<GridItem xs={4}>
								<b><p style={{ paddingTop: "20px" }}> Communication Information </p></b>
								<GenericEmailInput
									Disabled={Disabled}
									inputProps={this.ParameterMaxLen64}
									Name="Email"
									LabelText="E-Mail"
									Value={model.Email || ""}
									ValueChanged={this.handleChange}
									Required
									IsInvalid={vModel.Email}
								/>
								<GridContainer>
									<GridItem xs={8}>
										<GenericSelectInput
											CanClear
											Disabled={Disabled}
											Name="PhoneCountryCodeId"
											LabelText="Phone 1"
											LabelMd={6}
											Method="POST"
											Url="/coreapi/v1.0/Country/GetAll"
											Parameter={{}}
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember={"Code"}
											Value={phone1 == null ? "" : phone1.PhoneCountryCodeId || ""}
											ValueChanged={this.handleChangePhone1}
											Sorted={{ Member: "OrderIndex" }}
											RenderItem={d => "+" + d.Code + " (" + d.Name + ")"}
											Required
											IsInvalid={vModel.PhoneCountryCodeId}
										/>
									</GridItem>
									<GridItem xs={4} >
										<GenericNumberInput
											Disabled={Disabled}
											IsPhone
											MaxLength={10}
											LabelMd={0}
											Name="PhoneNumber"
											Value={phone1 == null ? "" : phone1.PhoneNumber || ""}
											ValueChanged={this.handleChangePhone1}
											Required
											IsInvalid={vModel.PhoneNumber}
										/>
									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={8}>
										<GenericSelectInput
											CanClear
											Disabled={Disabled}
											Name="PhoneCountryCodeId"
											LabelText="Phone 2"
											LabelMd={6}
											Method="POST"
											Url="/coreapi/v1.0/Country/GetAll"
											Parameter={{}}
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember={"Code"}
											Value={phone2 == null ? "" : phone2.PhoneCountryCodeId || ""}
											ValueChanged={this.handleChangePhone2}
											Sorted={{ Member: "OrderIndex" }}
											RenderItem={d => "+" + d.Code + " (" + d.Name + ")"}
											Required
											IsInvalid={vModel.PhoneCountryCodeId}
										/>
									</GridItem>
									<GridItem xs={4} >
										<GenericNumberInput
											Disabled={Disabled}
											IsPhone MaxLength={10}
											LabelMd={0}
											Name="PhoneNumber"
											Value={phone2 == null ? "" : phone2.PhoneNumber || ""}
											ValueChanged={this.handleChangePhone2}
											Required
											IsInvalid={vModel.PhoneNumber}
										/>
									</GridItem>
								</GridContainer>
								<GenericSelectInput
									Disabled={Disabled}
									Name="PreferedLanguageId"
									LabelText="Preferred Language"
									Value={model.PreferedLanguageId || ""}
									ValueChanged={this.handleChange}
									KeyValueMember="Id"
									TextValueMember="ParameterDesc"
									Method="POST"
									Url="/coreapi/v1.0/Parameter/Search"
									Parameter={{ ParameterCode: "Language", ParameterValue: "", ParameterDesc: "" }}
									DataRoot="Item"
								/>
								<GenericSelectInput
									Disabled={Disabled}
									Name="SendSms"
									LabelText="Text/SMS Notification"
									Value={model.SendSms == null ? "" : String(model.SendSms)}
									ValueChanged={(n, v, d) => this.handleChange(n, v == "true")}
									KeyValueMember="ParameterValue"
									TextValueMember="ParameterDesc"
									Method="POST"
									Url="/coreapi/v1.0/Parameter/Search"
									Parameter={{ ParameterCode: "YesNo", ParameterValue: "", ParameterDesc: "" }}
									DataRoot="Item"
									Required
									IsInvalid={vModel.SendSms}
								/>
								<GenericSelectInput
									Disabled={Disabled}
									Name="SendEmail"
									LabelText="E-Mail Notification"
									Value={model.SendEmail == null ? "" : String(model.SendEmail)}
									ValueChanged={(n, v, d) => this.handleChange(n, v == "true")}
									KeyValueMember="ParameterValue"
									TextValueMember="ParameterDesc"
									Method="POST" Url="/coreapi/v1.0/Parameter/Search"
									Parameter={{ ParameterCode: "YesNo", ParameterValue: "", ParameterDesc: "" }}
									DataRoot="Item"
									Required
									IsInvalid={vModel.SendEmail}
								/>
							</GridItem>
							<GridItem xs={4}>
								<b><p style={{ paddingTop: "20px" }}> Address Information </p></b>
								<GenericTextInput
									Disabled={Disabled}
									Name="Address1"
									Id="street-address"
									LabelText="Address Line 1"
									Value={model.Address1 || ""}
									ValueChanged={this.handleChange} />
								<GenericTextInput
									Disabled={Disabled}
									Name="Address2"
									Id="street-address2"
									LabelText="Address Line 2"
									Value={model.Address2 || ""}
									ValueChanged={this.handleChange} />
								<GenericSelectInput
									Name="CountryId"
									LabelText="Country"
									Method="POST"
									Url="/coreapi/v1.0/Country/GetAll"
									Parameter={{}}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="Name"
									Value={model.CountryId}
									ValueChanged={this.handleChange}
									Disabled={Disabled}
								/>
								<GenericSelectInput
									key={model.CountryId + "province"}
									Name="ProvinceId"
									LabelText="Province"
									Method="POST"
									Url="/coreapi/v1.0/Province/Filter"
									Parameter={{ CountryId: model.CountryId }}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="Name"
									Value={model.ProvinceId}
									ValueChanged={this.handleChange}
									Disabled={Disabled}
								/>
								<GenericSelectInput
									key={"city" + model.ProvinceId + ""}
									Name="CityId"
									LabelText="City"
									Method="POST"
									Url="/coreapi/v1.0/City/Filter"
									Parameter={{ ProvinceId: model.ProvinceId }}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="Name"
									Value={model.CityId}
									ValueChanged={this.handleChange}
									Disabled={Disabled}
								/>
								<GenericTextInput
									Disabled={Disabled}
									Name="Location"
									LabelText="Location"
									Id="label"
									Value={model.Location || ""}
									ValueChanged={this.handleChange} />
								<GenericTextInput
									Disabled={Disabled}
									Name="ZipCode"
									Id="postcode"
									LabelText="Postal Code"
									Value={model.ZipCode || ""}
									ValueChanged={this.handleChange} />
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>


				<Card className="no-radius" style={{ display: Disabled ? "none" : "normal" }}>
					<b><p style={{ paddingTop: "20px", marginLeft: "10px" }}> Campaign Partner List </p></b>
					<CardBody>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={partnerList}
									Columns={this.ColumnsData}
									RowSelected={index => {
										this.SelectedRowChange(index);
									}}
								/>
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>
			</div>

		);
	}
}

CampaignPartner.propTypes = {
	classes: PropTypes.object
};

export default withArtifex(CampaignPartner, {});