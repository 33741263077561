import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ClientHelper from "core/ClientHelper";
import { DeleteIcon } from "core/Icons";
import ResourceHelper from "core/ResourceHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericGrid, GenericNumberInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";

class UserMenuFavorite extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			model: {
				list: []
			}
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("Favorite Management");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("Manage Favorite Menus");
		}

		this.GetByUserId();
	}

	OrderChange = (rowIndex, value) => {
		this.setState(state => {
			var model = state.model;
			model.orderList[rowIndex].Order = value;

			return { model };
		});
	}

	GetByUserId = async () => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/coreapi/v1.0/UserFavoriteMenuMap/GetByUserId", {});

		if (result != null) {
			this.setState({ isLoading: true });

			var list = [], orderList = [];

			result.map(x => {
				list.push({
					Id: x.Id,
					MenuId: x.MenuId,
					Menu: ResourceHelper.GetForMenu(x.MenuName),
					Order: x.Order
				});
				orderList.push({
					Order: x.Order
				});

				return null;
			});

			var { model } = this.state;
			model.list = list;
			model.orderList = orderList;

			this.setState({ model, isLoading: false });
		}
	}

	Submit = () => {
		const { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var favoriteOrderList = [];

		for (var i = 0; i < model.list.length; i++) {
			favoriteOrderList.push({
				Id: model.list[i].Id,
				Order: model.orderList[i].Order
			});
		}

		ExecuteApiPost("/coreapi/v1.0/UserFavoriteMenuMap/Submit", { favoriteOrderList }, null, null, this.Validate, [this.AfterSubmitOrDelete], true);
	}

	AfterSubmitOrDelete = () => {
		var user = ClientHelper.GetUser();
		user.IsFavorite = true;

		localStorage.setItem("user", JSON.stringify(user));
		localStorage.removeItem("MenuData");

		setTimeout(function () {
			window.location.reload(true);
		}, 2000);
	}

	Validate = () => {
		const { model } = this.state;
		const { showValidationErrors } = this.props;
		var errors = [];
		var isEmpty = false;

		if (model.list.length == 0) {
			errors.push("Favorite list is empty.");
			showValidationErrors(errors);

			return false;
		}

		model.list.map(x => {
			if (x.Order == null) {
				isEmpty = true;
			}

			return null;
		});

		if (isEmpty) {
			errors.push("Order can not be null.");
			showValidationErrors(errors);

			return false;
		}

		return true;
	}

	render() {
		const { model, isLoading } = this.state;
		const { ExecuteApiPost, showConfirmMessageInDelete } = this.props;

		return (
			<div>
				<LoadingComponent Show={isLoading} />
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Submit",
							OnClick: this.Submit
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericGrid
									HideButton
									Data={model.list}
									Columns={[
										{
											Header: "Actions",
											accessor: "Actions",
											Cell: row => (
												<div>
													<GridButton
														tooltip="Delete"
														Icon={DeleteIcon}
														OnClick={() => {
															showConfirmMessageInDelete(() => ExecuteApiPost("/coreapi/v1.0/UserFavoriteMenuMap/Delete", { Id: row.original.Id }, null, null, null, [this.AfterSubmitOrDelete], true));
														}} />
												</div>
											),
											width: 60
										},
										{
											Header: "Order",
											width: 75,
											Cell: row => (
												<GenericNumberInput
													NoFormatting
													Name="Order"
													LabelStyle={{ width: "0%" }}
													InputStyle={{ width: "100%", marginTop: "-10px" }}
													MaxLength={3}
													Value={model.orderList[row.index].Order}
													ValueChanged={(name, value) => this.OrderChange(row.index, value)}
												/>
											)
										},
										{
											Header: "Menu",
											accessor: "Menu"
										}
									]} />
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

UserMenuFavorite.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default UserMenuFavorite;