import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericCheckInput, GenericDateInput, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType, ProgramCodes } from "views/Constants/Constant";
import CustomerComponent from "views/Components/CustomerComponent";


class CustomerCreditClimit extends Component {
	ShowMessage(arg0, arg1, ErrorDescription) {
		throw new Error("Method not implemented.");
	}
	constructor(props){
		super(props);

		this.initialModel = {
			Id: 0,
			CardId: 0,
			Customer: null,
			CustomerId: 0,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined,
			CurrencyId: 0,
			IsActive: true
		};

		this.state = {
			model: this.initialModel,
			list: [],
			isLoading: false
		};

		this.emptyObject = {};
		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined
		};
		this.renderItemStatus = {};
		this.parameterProduct = {};
		this.Columns = [
			{
				Header: "Status",
				accessor: "Status"
			},
			{
				Header: "Client Customer Number",
				accessor: "ClientCustomerNumber"
			},
			{
				Header: "Currency",
				accessor: "Currency"
			},
			{
				Header: "Pre Auth Limit",
				accessor: "DailyPreAuthLimit"
			},
			{
				Header: "Pre Auth Limit Usage",
				accessor: "DailyPreAuthLimitUsage",
			},
			{
				Header: "Pre Auth Warning Limit",
				accessor: "DailyPreAuthWarningLimit"
			},
			{
				Header: "Pre Auth Limit Usage Date",
				accessor: "DailyPreAuthLimitUsageDateTime",
				type: GridColumnType.DateTime,
				ColumnType: GridColumnType.DateTime
			},
			{
				Header: "Is Pre Auth Warning Limit Sent",
				accessor: "IsDailyPreAuthLimitWarningSent"
			},
			{
				Header: "Pre Auth Warning Limit Sent Date",
				accessor: "DailyPreAuthLimitWarningSentDateTime",
				type: GridColumnType.DateTime,
				ColumnType: GridColumnType.DateTime
			},
			{
				Header: "Completed Limit",
				accessor: "DailySettledAuthLimit"
			},
			{
				Header: "Completed Limit Usage",
				accessor: "DailySettledAuthLimitUsage"
			},
			{
				Header: "Completed Warning Limit",
				accessor: "DailySettledAuthWarningLimit"
			},
			{
				Header: "Completed Limit Usage Date",
				accessor: "DailySettledAuthLimitUsageDateTime",
				type: GridColumnType.DateTime,
				ColumnType: GridColumnType.DateTime
			},
			{
				Header: "Is Completed Warning Limit Sent",
				accessor: "IsDailySettledAuthLimitWarningSent"
			},
			{
				Header: "Completed Warning Limit Sent Date",
				accessor: "DailySettledAuthLimitWarningSentDateTime",
				type: GridColumnType.DateTime,
				ColumnType: GridColumnType.DateTime
			},
			{
				Header: "Is Completed Over Pre Auth Warning Limit Sent",
				accessor: "IsDailySettledOverPreAuthLimitWarningSent"
			},
			{
				Header: "Completed Over Pre Auth Warning Limit Sent Date",
				accessor: "DailySettledOverPreAuthLimitWarningSentDateTime",
				type: GridColumnType.DateTime,
				ColumnType: GridColumnType.DateTime
			}
		];
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Customer Credit Limit");
		this.props.setAppCenterTitle("CARD MANAGEMENT SYSTEM");
	}

	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	};

	FillCustomer = (customer) => {
		this.handleChangeForCustomer("Customer", customer);
	};

	handleChangeForCustomer = (name, newValue) => {
		const model = { ...this.state.model };
		const initialModel = { ...this.initialModel };
		if (name === "Customer") {
		  if (newValue != null) {
			 model.Customer = newValue;
			 if (newValue.Accounts != null && newValue.Accounts.length > 0) {
				model.CustomerNumber = newValue.Accounts[0].CustomerNumber;
				model.CustomerId = newValue.Accounts[0].Id;
			 } else {
				model.CustomerNumber = null;
				model.CustomerId = newValue.Id;
			 }
		  } else {
			 model.Customer = initialModel.Customer;
		  }
		}
		this.setState({ model });
	};

	HandleChange = (name, value, data) => {
		const model = { ...this.state.model };
		model[name] = value;

		this.setState({ model });
	};

	HandleSearch = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/Prepaid/SearchCustomerLimitNotificationAlert",
			this.state.model,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ list: responseData.Item });
				} else {
					this.setState({ list: [] });
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			});
	}

	HandleSubmit = () => {
		if (!this.Validate()) {
			return;
		}

		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/Prepaid/InsertOrUpdateCustomerLimitNotificationAlert",
			this.state.model,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.IsSucceeded) {
					this.setState({ model: { ...this.initialModel }, index: -1 });
					this.props.showMessage("success", "Succeeded", "Operation is successfully!");
					this.HandleSearch();
				} else {
					this.setState({ list: [] });
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			});
	}

	HandleDelete = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/Prepaid/DeleteCustomerLimitNotificationAlert",
			this.state.model,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.IsSucceeded) {
					this.setState({ model: { ...this.initialModel }, index: -1 });
					this.props.showMessage("success", "Succeeded", "Operation is successfully!");
					this.HandleSearch();
				} else {
					this.setState({ list: [] });
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			});
	}

	HandleUpdate = () => {
		if (!this.UpdateValidate()) {
			return;
		}

		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/Prepaid/InsertOrUpdateCustomerLimitNotificationAlert",
			this.state.model,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.IsSucceeded) {
					this.setState({ model: { ...this.initialModel }, index: -1 });
					this.props.showMessage("success", "Succeeded", "Operation is successfully!");
					this.HandleSearch();
				} else {
					this.setState({ list: [] });
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			});
	}

	Validate = () => {
		const { model } = this.state;
		var messages = [];

		if (model.UniqueClientId == undefined || model.UniqueClientId == null || model.UniqueClientId == 0) messages.push("Client must be select");

		if (model.CustomerId == 0) messages.push("Customer must be select");

		if (model.CurrencyId == 0) messages.push("Currency must be select");

		if (model.DailyPreAuthLimit == undefined || model.DailyPreAuthLimit == null || model.DailyPreAuthLimit == 0) messages.push("Pre Auth Limit cannot be empty");

		if (model.DailyPreAuthWarningLimit == undefined || model.DailyPreAuthWarningLimit == null || model.DailyPreAuthWarningLimit == 0) messages.push("Pre Auth Warning Limit cannot be empty");

		if (model.DailySettledAuthLimit == undefined || model.DailySettledAuthLimit == null || model.DailySettledAuthLimit == 0) messages.push("Completed Limit cannot be empty");

		if (model.DailySettledAuthWarningLimit == undefined || model.DailySettledAuthWarningLimit == null || model.DailySettledAuthWarningLimit == 0) messages.push("Completed Warning Limit cannot be empty");

		if (messages.length > 0) {
			this.props.showMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.setState({ model });
		return true;
	}

	UpdateValidate = () => {
		const { model } = this.state;
		var messages = [];

		if (model.Id == undefined || model.Id == null || model.Id == 0) messages.push("Customer Credit Limit must be select");

		if (model.UniqueClientId == undefined || model.UniqueClientId == null || model.UniqueClientId == 0) messages.push("Client must be select");

		if (model.CustomerId == 0) messages.push("Customer must be select");

		if (model.CurrencyId == 0) messages.push("Currency must be select");

		if (model.DailyPreAuthLimit == undefined || model.DailyPreAuthLimit == null || model.DailyPreAuthLimit == 0) messages.push("Pre Auth Limit cannot be empty");

		if (model.DailyPreAuthWarningLimit == undefined || model.DailyPreAuthWarningLimit == null || model.DailyPreAuthWarningLimit == 0) messages.push("Pre Auth Warning Limit cannot be empty");

		if (model.DailySettledAuthLimit == undefined || model.DailySettledAuthLimit == null || model.DailySettledAuthLimit == 0) messages.push("Completed Limit cannot be empty");

		if (model.DailySettledAuthWarningLimit == undefined || model.DailySettledAuthWarningLimit == null || model.DailySettledAuthWarningLimit == 0) messages.push("Completed Warning Limit cannot be empty");

		if (messages.length > 0) {
			this.props.showMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.setState({ model });
		return true;
	}

	RowSelected = (index) => {
		var tempModel = { ...this.state.list[index] };
		this.setState({ isEdit: true, model: tempModel, index });
	}

	HandleClear = () => {
		this.setState({ model: { ...this.initialModel }, isEdit: false, index: -1 });
	}

	render() {
		const { Disabled } = this.props;
		const { alert, model, list, isLoading, index } = this.state;
		
		return (
		<div>
			<LoadingComponent Show={isLoading} />

			{alert}
			<ButtonToolbar ButtonList={[
				{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
				{ Code: "Submit", OnClick: this.HandleSubmit, Disabled: Disabled },
				{ Code: "Update", OnClick: this.HandleUpdate, Disabled: Disabled },
				{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
				{ Code: "Delete", OnClick: this.HandleDelete, Disabled: Disabled }
			]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
			<GridContainer spacing={16}>
				<GridItem xs={12}>
					<Card className="no-radius">
						<GenericExpansionPanel Title="Main Panel">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer>
											<GridItem xs={4}>
												<GenericSelectInput
													Name="UniqueClientId"
													LabelText="Client"
													Method="POST"
													Url="/bankapi/v1.0/BankCustomer/Search"
													Parameter={{}}
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={this.RenderItemUniqueClient}
													Value={model.UniqueClientId}
													ValueChanged={this.HandleChange}
													CanClear
													Required
												/>
											</GridItem>
											<GridItem xs={4}>
												<CustomerComponent
													key={model.UniqueClientId}
													CustomerNumber={
														model.Customer ? model.Customer.CustomerNumber : model.ClientCustomerNumber
													}
													HandleChange={this.HandleChange}
													FillCallback={this.FillCustomer}
													Programs={[ProgramCodes.Prepaid]}
													UniqueClientId={model.UniqueClientId}
													Disabled={!model.UniqueClientId}
													Required
												/>
											</GridItem>
											<GridItem xs={4}>
												<GenericSelectInput
													Name="CurrencyId"
													LabelText="Currency"
													Url="/bankapi/v1.0/Currency/ValidCurrenciesOnlyCadandUsd"
													Method="GET"
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="Code"
													Value={model.CurrencyId}
													ValueChanged={this.HandleChange} 
													Required
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={4}>
										<GenericNumberInput
                            		Name="DailyPreAuthLimit"
                            		LabelText="Pre Auth Limit"
                            		Value={model.DailyPreAuthLimit}
                            		ValueChanged={this.HandleChange}
                            		MaxLength={10}
                            		Prefix="$"
                            		Required
                            		// Disabled={this.CustomerDisactive}
                            		// IsInvalid={vCustomerModel.Amount}
                          		/>
									</GridItem>
									<GridItem xs={4}>
										<GenericNumberInput
                            		Name="DailyPreAuthWarningLimit"
                            		LabelText="Pre Auth Warning Limit"
                            		Value={model.DailyPreAuthWarningLimit}
                            		ValueChanged={this.HandleChange}
                            		MaxLength={10}
                            		Prefix="$"
                            		Required
                            		// Disabled={this.CustomerDisactive}
                            		// IsInvalid={vCustomerModel.Amount}
                          		/>
									</GridItem>
									<GridItem xs={4}>
										<GenericNumberInput
                            		Name="DailySettledAuthLimit"
                            		LabelText="Completed Limit"
                            		Value={model.DailySettledAuthLimit}
                            		ValueChanged={this.HandleChange}
                            		MaxLength={10}
                            		Prefix="$"
                            		Required
                            		// Disabled={this.CustomerDisactive}
                            		// IsInvalid={vCustomerModel.Amount}
                          		/>
									</GridItem>
									<GridItem xs={4}>
										<GenericNumberInput
                            		Name="DailySettledAuthWarningLimit"
                            		LabelText="Completed Warning Limit"
                            		Value={model.DailySettledAuthWarningLimit}
                            		ValueChanged={this.HandleChange}
                            		MaxLength={10}
                            		Prefix="$"
                            		Required
                            		// Disabled={this.CustomerDisactive}
                            		// IsInvalid={vCustomerModel.Amount}
                          		/>
									</GridItem>
									<GridItem xs={4} style={{ marginTop: 6 }}>
											<GenericCheckInput
												Name="IsActive"
												LabelText="Is Active"
												Value={model.IsActive}
												ValueChanged={this.HandleChange}
											/>
										</GridItem>
								</GridContainer>
							</CardBody>
						</GenericExpansionPanel>
					</Card>
					<GridContainer>
						<GridItem xs={12}>
							<Card className="no-radius">
								<CardHeader>
									<GenericTitle text={""} />
								</CardHeader>
								<CardBody>
									<GridContainer>
										<GridItem xs={4}>
										</GridItem>
										<GridItem xs={8} />
									</GridContainer>
									<GridContainer spacing={16}>
										<GridItem xs={12}>
											<GenericGrid
												Data={list}
												Columns={this.Columns}
												RowSelected={this.RowSelected}
												SelectedIndex={index}
												ProgramCode={ProgramCodes.Prepaid} 
												PageSize={10}
												ShowPagination={true}
											/>
										</GridItem>
									</GridContainer>
								</CardBody>
							</Card>
						</GridItem>
					</GridContainer>
				</GridItem>
			</GridContainer>
		</div>);
	}
}

CustomerCreditClimit.propTypes = {
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool
};

export default withArtifex(CustomerCreditClimit, {});