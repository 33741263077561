import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericCheckInput, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericDateInput, GenericLabel } from "views/Components/Generic";
import { MonthsOfYear, MenuCodes, GridColumnType } from "views/Constants/Constant.js";
import ClientHelper from "core/ClientHelper";

class CanadaPostRequestReport extends React.Component {

	constructor(props) {

		super(props);


		this.isBackOffice = ClientHelper.IsBackOffice();

		this.initialModel = {
			Id: 0,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined,
		};


		this.columns = [
			{
				Header: "Unique Client Id",
				accessor: "UniqueClientId",
				show: this.isBackOffice,
				Cell: row => row.value == 0 ? "BackOffice" : row.value
			},
			{
				Header: "Client Name",
				accessor: "UniqueClientName",
				show: this.isBackOffice,
				Cell: row => row.value == "" ? "BackOffice" : row.value
			},
			{
				Header: "Reqeust Date",
				accessor: "RequestDate",
				type: GridColumnType.DateUtc

			},
			{
				Header: "Request Count",
				accessor: "RequestCount"
			}

		];
		this.state = {
			model: this.initialModel,
			vModel: {}
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;
		setAppCenterTitle("Reports");
		setAppLeftTitle("Canada Post Request Report");
	}
	ValueChanged = (name, value) => {
		this.setState(state => {
			var model = state.model;
			model[name] = value;

			return { model };
		});
	}

	Search = async () => {

		const { ExecuteApiPost } = this.props;
		const model = { ...this.state.model };

		var result = await ExecuteApiPost("/bankapi/v1.0/Reporting/CanadaPostRequestReportList", model, null, null, null);
		this.setState({ list: result || [] });
	}

	Clear = () => {
		this.setState({ model: {}, list: [], selected: null, Month: [] });
	}

	// Validate = () => {
	// 	var { model } = this.state;
	// 	const { showValidationErrors } = this.props;
	// 	const vModel = { ...this.state.vModel };
	// 	var errors = [];

	// 	if (!model.ParentMenuId
	// 		|| model.ParentMenuId == -2) {
	// 		errors.push("Parent menu can not be null.");
	// 	}
	// 	if (!model.SubMenuId) {
	// 		errors.push("Sub menu can not be null.");
	// 	}
	// 	if (!model.ActionId) {
	// 		errors.push("Action can not be null.");
	// 	}

	// 	if (errors.length > 0) {
	// 		showValidationErrors(errors);

	// 		return false;
	// 	}

	// 	return true;
	// }



	render() {
		const { model, list } = this.state;
		const { Disabled } = this.props;

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},

						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Panel">
									<GridContainer>
										<GridItem xs={3}>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={{}}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
												Value={model.UniqueClientId || ""}
												ValueChanged={this.ValueChanged}
												CanClear
												All
												Disabled={!this.isBackOffice}
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericCheckInput
												Disabled={Disabled}
												Name="IsBackOffice"
												LabelText="IsBackOffice"
												Value={model.IsBackOffice || false}
												ValueChanged={this.ValueChanged}
											/>
										</GridItem>

										<GridItem xs={3}>
											<GenericDateInput
												Name="FromDate"
												LabelText="From Date"
												Value={model.FromDate ? DateHelper.ToDateInputValue(model.FromDate) : ""}
												ValueChanged={this.ValueChanged}
												MaxDate={model.ToDate || DateHelper.GetDateTimeLocal()}
												Disabled={Disabled}
												LabelMd={3}
												Utc />
										</GridItem>
										<GridItem xs={3}>
											<GenericDateInput
												Name="ToDate"
												LabelText="To Date"
												Value={model.ToDate ? DateHelper.ToDateInputValue(model.ToDate) : ""}
												ValueChanged={this.ValueChanged}
												Disabled={Disabled}
												MinDate={model.FromDate || DateHelper.GetDateTimeLocal()}
												MaxDate={DateHelper.GetDateTimeLocal()}
												Utc />
										</GridItem>



									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columns}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

CanadaPostRequestReport.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any,
	classes: PropTypes.object

};
export default withArtifex(CanadaPostRequestReport, {});
