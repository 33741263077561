import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericExpansionPanel, GenericGrid, GenericSelectInput } from "views/Components/Generic";

const ApiUrlBase = "/amlkycapi/v1.0/DigitalIdentityVerificationIdentityTypeMap/";
const ApiUrl = {
	Search: ApiUrlBase + "Search",
	Update: ApiUrlBase + "Update",
	Delete: ApiUrlBase + "Delete",
};
class DigitalVerificationIdentityTypeMap extends React.Component {

	constructor(props) {
		super(props);
		this.initialState = {
			model: {},
			modelList: []
		};
		this.state = {
			model: {},
			modelList: [],
			tempModelList: [],
			vouchedIdIdentityTypeList: [],
			identityTypeList: []
		};
		this.state = Object.assign(this.state, this.initialState);
		this.sortedParameterDesc = { Member: "ParameterDesc" };
		this.emptyObject = {};
		this.gridSortParameter = [{ id: "Name", desc: false }];
		this.placeholderNA = "-";
		this.identityDialogButtonStyle = {
			flexDirection: "row",
			display: "flex",
			justifyContent: "flex-end",
		};

		this.isBackOffice = ClientHelper.IsBackOffice();
		this.uniqueClientId = ClientHelper.GetClientId();


		this.columnsData = [
			{
				Header: "Identity Type Name",
				accessor: "ParameterIdentityType.ParameterDesc",
				Cell: row => row.value,
			},
			{
				Header: "Third Party Identity Type",
				accessor: "DigitalIdentityType.Name",
				Cell: row => row.value,
			},
		];

	}

	componentDidMount() {
		this.props.setAppCenterTitle && this.props.setAppCenterTitle("AML & KYC");
		this.props.setAppLeftTitle && this.props.setAppLeftTitle("Identity Type Map");

		this.GetVouchedIdIdentityTypeList();
		this.GetIdentityTypeList();
	}

	ValueChanged = (name, newValue, data) => {
		const model = { ...this.state.model };

		model[name] = newValue;
		this.setState({ model });
	}
	ValueChangedIdentityType = (name, newValue, data) => {
		const identityTypeModel = { ...this.state.identityTypeModel };

		identityTypeModel[name] = newValue;
		this.setState({ identityTypeModel });
	}
	ValueChangedFilter = (name, newValue, data) => {
		const filterModel = { ...this.state.filterModel };

		filterModel[name] = newValue;
		this.setState({ filterModel });
	}
	SelectedRowChange = (index) => {
		const model = this.state.modelList[index];
		this.setState({ model: model });
	}
	HandleSearch = async () => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost(ApiUrl.Search, {}) || [];

		this.setState({ model: {}, modelList: result, tempModelList: result });

	}
	GetVouchedIdIdentityTypeList = async () => {
		var result = await this.props.ExecuteApiPost("/amlkycapi/v1.0/DigitalIdentityVerificationIdentityType/Search");
		var vouchedIdIdentityTypeList = result || [];
		this.setState({ vouchedIdIdentityTypeList });
	}
	GetIdentityTypeList = async () => {

		var result = await this.props.ExecuteApiPost("/coreapi/v1.0/Parameter/Search", { ParameterCode: "IdentityType" });
		var identityTypeList = result || [];
		this.setState({ identityTypeList });
	}
	HandleSubmit = async () => {
		const { model } = this.state;
		if (!this.Validate()) return;

		var result = await this.props.ExecuteApiPost(ApiUrl.Update, model);
		if (result) {
			model.ParameterIdentityTypeId = null;
			this.setState({ model: { ...model } });
		}
		this.HandleSearch();
	}

	HandleUpdate = async () => {
		const { model } = this.state;
		if (!this.Validate()) return;

		var result = await this.props.ExecuteApiPost(ApiUrl.Update, model);
		if (result) {
			model.ParameterIdentityTypeId = null;
			this.setState({ model: { ...model } });
		}
		this.HandleSearch();
	}
	HandleDelete = async () => {
		const { model } = this.state;
		if (model.Id) {

			if (!await this.props.showConfirmMessage("warning", "Warning", "Are you sure you want to delete this record?")) return;
			var result = await this.props.ExecuteApiPost(ApiUrl.Delete, { Id: model.Id });
			if (result) {
				this.props.showMessage("success", "Success", "Delete Sucessfully");
				this.HandleSearch();
			}
		}

	}
	HandleClear = () => {
		var defaultState = Object.assign({}, this.initialState);
		this.setState(defaultState);
	}


	AddOrUpdateDigitalIdentityVerificationIdentityType = (isUpdate = false) => async () => {
		const { model, identityTypeModel } = this.state;
		if (!this.OperationCheck(2)()) return;
		var resultId = await this.props.ExecuteApiPost(ApiUrl.InsertIdentityType, { Id: identityTypeModel.Id, Name: identityTypeModel.Name });
		if (resultId) {
			model.InsertedIdentityType = resultId;
			model.DigitalIdentityVerificationIdentityTypeId = resultId;
			this.setState({ model });
			this.GetVouchedIdIdentityTypeList();
			await this.props.showMessage("success", "Success", `Identity Type ${isUpdate ? "updated" : "added"} successfully`);

		}
	}

	ClearDigitalIdentityVerificationIdentityType = async () => {
		this.setState({ identityTypeModel: {} });
	}

	Validate = () => {
		const { model } = this.state;
		if (!model.DigitalIdentityTypeId) {
			this.props.showMessage("error", "Error", "Third Party Identity Type must be selected");
			return false;
		}
		if (!model.ParameterIdentityTypeId) {
			this.props.showMessage("error", "Error", "Identity Type must be selected");
			return false;
		}
		return true;
	}
	RenderVouchedIdIdentityType = (d) => {
		return d.Name;
	}

	RenderIdentityType = (d) => {
		return d?.ParameterDesc;
	}

	render() {
		const { model, modelList, tempModelList, identityTypeList, vouchedIdIdentityTypeList } = this.state;
		const { Disabled } = this.props;

		return (
			<>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.HandleSearch
						},
						{
							Code: "Submit",
							Disabled: Disabled || model.Id,
							OnClick: this.HandleSubmit
						},
						{
							Code: "Update",
							Disabled: Disabled || !model.Id,
							OnClick: this.HandleUpdate
						},
						{
							Code: "Delete",
							Disabled: Disabled || !model.Id,
							OnClick: this.HandleDelete
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.HandleClear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GridContainer>
					<GridItem xs={12}>
						<Card>
							<GenericExpansionPanel
								IsActive={true}
								Title="Identity Type Map">
								<CardBody>
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer>
												<GridItem xs={4}>
													<GenericSelectInput
														IsStatic
														StaticData={identityTypeList.filter(a => a.Id == model.ParameterIdentityTypeId || !tempModelList.find(b => b.ParameterIdentityTypeId == a.Id))}
														Name="ParameterIdentityTypeId"
														LabelText="Identity Type Name"
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="Name"
														Sorted={this.sortedParameterDesc}
														RenderItem={this.RenderIdentityType}
														Value={model.ParameterIdentityTypeId}
														ValueChanged={this.ValueChanged}
														Required />
												</GridItem>
												<GridItem xs={4}>
													<GenericSelectInput
														IsStatic
														StaticData={vouchedIdIdentityTypeList}
														Name="DigitalIdentityTypeId"
														LabelText="Third Party Identity Type"
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="Name"
														RenderItem={this.RenderVouchedIdIdentityType}
														Value={model.DigitalIdentityTypeId}
														ValueChanged={this.ValueChanged}
														Required />
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</CardBody>
							</GenericExpansionPanel>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel
									Name={"IdentityTypeMapList"}
									Title="Identity Type Map List">
									<GenericGrid
										Data={modelList}
										PageSize={5}
										HideButton={true}
										Sorted={this.gridSortParameter}
										Columns={this.columnsData}
										RowSelected={this.SelectedRowChange} />
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</ >
		);
	}
}

DigitalVerificationIdentityTypeMap.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};
export default DigitalVerificationIdentityTypeMap;