import { Dialog, DialogContent } from "@material-ui/core";
import CardMedia from "@material-ui/core/CardMedia";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withRedux } from "core";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React from "react";
import { UserConstants } from "store";
import {
  GenericAlert,
  GenericCircularProgress,
  GenericEmailInput,
  GenericTextInput,
  GenericNumberInput,
} from "views/Components/Generic";
import RouteHelper from "core/RouteHelper";
import LoadingComponent from "views/Components/LoadingComponent";

class InteracInboundLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      isLoading: false,
      model: {
        Email: "",
        Password: "",
      },
    };

    this.cardHeaderStyle = {
      height: 80,
      width: "50%",
      marginLeft: "auto",
      marginRight: "auto",
    };
    this.cardMediaStyle = {
      height: "100%",
      width: "75%",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: 10,
      backgroundSize: "100% 100%",
    };
    this.cardBodyStyle = {
      marginTop: 62,
      marginBottom: 62,
      width: "83%",
      marginLeft: "auto",
      marginRight: "auto",
    };
  }

  componentDidMount() {}

  handleChange = (name, newValue) => {
    this.setState(function (state, props) {
      var model = state.model || {};
      model[name] = newValue;
      return { model };
    });
  };

  PerformLogin = () => {
    var ErrorList = [];
    const { Email, Password } = this.state.model;

    if (!Email || !Email.trim()) {
      ErrorList.push("Email cannot be null.");
    }

    if (!Password || !Password.trim()) {
      ErrorList.push("Password cannot be null.");
    }

    if (ErrorList.length > 0) {
      this.ShowMessageNode(
        "warning",
        "Please fill required fields!",
        ErrorList.map((x, i) => <div key={i}>{x}</div>)
      );
      return false;
    }

    this.setState({ isLoading: true });
    console.log("login?");
    Proxy.POST(
      "/bankapi/v1.0/InteracInbound/Login",
      {
        Email,
        Password,
		  OrganizationUniqueId: window.OrganizationUniqueId
      },
      (responseData) => {
        this.setState({ isLoading: false });

        if (!responseData.IsSucceeded) {
          this.ShowMessage("error", "Error", responseData.ErrorDescription);
          return;
        }

        const user = {
          Email: this.state.model.Email,
          SessionId: responseData.Item.DCBankAccessToken,
          SessionStatus: responseData.Item.SessionStatus,
          MustChangePwd: responseData.Item.MustChangePwd,
          CanBeClient: responseData.Item.CanBeClient,
        };

        console.log(user);
        //Proxy SessionId kullaniyor GetUserByEmail operation icin
        localStorage.setItem(
          "user",
          JSON.stringify({ SessionId: user.SessionId })
        );

        const { dispatch } = this.props;
        dispatch({ type: UserConstants.LOGIN_SUCCESS, user });

        this.PerformGetUser(user.SessionId, user.CanBeClient);
      },
      (error) => {
        this.setState({ isLoading: false });
        this.ShowMessage("error", "Error", error);
      }
    );
  };

  PerformGetUser(SessionId, CanBeClient) {
    const { Email } = this.state.model;
    this.setState({ isLoading: true });

    Proxy.POST(
      "/bankapi/v1.0/InteracInbound/GetUserByEmailWithClientInfo",
      { Email },
      (responseData) => {
        this.setState({ isLoading: false });

        if (!responseData.IsSucceeded) {
          this.ShowMessage("error", "Error", responseData.ErrorDescription);
          return;
        }

        var userInfo = responseData.User;
        userInfo.SessionId = SessionId;
        userInfo.CanBeClient = CanBeClient;
        console.log(userInfo);
        localStorage.setItem("user", JSON.stringify(userInfo));

        var data = {};
        data.Url = window.location.href;
        var ETID = this.GetParameterByName("ETID", data.Url);
        var RQETID = this.GetParameterByName("RQETID", data.Url);
        data.IsValidUrl = false;
        data.clientId = userInfo.UniqueClientId;

        if (ETID != null && ETID != "") {
          // money send
          data.IsValidUrl = true;
          data.ReferenceNumber = ETID;
          data.IsMoneySend = true;
          data.IsMoneyRequest = false;
        }
        if (RQETID != null && RQETID != "") {
          // money request
          data.IsValidUrl = true;
          data.ReferenceNumber = RQETID;
          data.IsMoneySend = false;
          data.IsMoneyRequest = true;
        }

        if (data.IsValidUrl) {
          RouteHelper.Push(
            this.props.history,
            "/InteracConfirm/InboundPage",
            "InteracData",
            data
          );
        } else {
          this.ShowMessage(
            "error",
            "Error",
            "Incorrect Interact reference number!"
          );
        }
      },
      (error) => {
        this.setState({ isLoading: false });
        this.ShowMessage("error", "Error", error);
      }
    );
  }
  GetParameterByName = (name, url) => {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  };
  ShowMessageNode = (type, title, message) => {
    this.setState({
      alert: (
        <GenericAlert
          Title={title}
          MessageNode={message}
          Type={type}
          OnConfirm={() => this.hideAlert()}
        />
      ),
    });
    this.setState({ isLoading: false });
  };
  ShowMessage = (type, title, message) => {
    // type:-success->warning->info->error
    this.setState({
      alert: (
        <GenericAlert
          Title={title}
          Message={message}
          Type={type}
          OnConfirm={() => this.hideAlert()}
        />
      ),
    });
    this.setState({ isLoading: false });
  };
  hideAlert = () => {
    this.setState({
      alert: null,
      isLoading: false,
    });
  };
  handleKeyPress = (e) => {
    if (e.key == "Enter") {
      this.PerformLogin();
    }
  };

  render() {
    const { classes } = this.props;
    const { model, isLoading, alert } = this.state;
    const logo = require("assets/img/" + window.LoginLogo);

    return (
      <div className={classes.container}>
        <LoadingComponent Show={isLoading} />
        {alert}
        <GridContainer justify="center">
          <GridItem
            style={{ minWidth: "600px", maxWidth: "656px", marginTop: "200px" }}
          >
            <form>
              <Card login>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="white"
                  style={this.cardHeaderStyle}
                >
                  <CardMedia
                    className={classes.media}
                    image={logo}
                    style={this.cardMediaStyle}
                    title=""
                  />
                </CardHeader>
                <CardBody style={this.cardBodyStyle}>
                  <GridContainer
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="stretch"
                  >
                    <GridItem>
                      <GenericEmailInput
                        FloatRight={true}
                        LabelMd={5}
                        Name="Email"
                        LabelText="Email"
                        Value={model.Email}
                        ValueChanged={this.handleChange}
                        EndIconName="email"
                        AutoFocus={true}
                        KeyPressed={this.handleKeyPress}
                      />
                    </GridItem>
                    <GridItem>
                      <GenericTextInput
                        FloatRight={true}
                        LabelMd={5}
                        Name="Password"
                        LabelText="Password"
                        IsPassword={true}
                        Value={model.Password}
                        ValueChanged={this.handleChange}
                        EndIconName="lock_outline"
                        KeyPressed={this.handleKeyPress}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    style={{ marginTop: "22px" }}
                  >
                    <GridItem md={5}></GridItem>
                    <GridItem md={7}>
                      <Button
                        color="primary"
                        size="lg"
                        block
                        onClick={() => this.PerformLogin()}
                      >
                        LOGIN
                      </Button>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

InteracInboundLogin.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRedux(InteracInboundLogin, loginPageStyle);
