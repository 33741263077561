import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";

const prepaidBINDefinitionStyle = {
	...customSelectStyle,
	...sweetAlertStyle,
	...customCheckboxRadioSwitch
};

export default prepaidBINDefinitionStyle;
