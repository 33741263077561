import { dangerColor, defaultFont, successColor } from "assets/jss/material-dashboard-pro-react.jsx";

const customInputStyle = {
	disabled: {
		opacity: "0.7 !important",
		"&:before": {
			borderColor: "transparent !important",
			borderBottom: "unset"
		}
	},
	underline: {
		"&:hover:not($disabled):before,&:before": {
			borderBottom: "unset !important"
		},
		"&:after": {
			transition: "unset",
			height: "100%",
			border: "1px solid gray !important",
		}
	},
	underlineError: {
		"&:after": {
			borderColor: dangerColor
		}
	},
	underlineSuccess: {
		"&:after": {
			borderColor: successColor
		}
	},
	labelRoot: {
		...defaultFont,
		color: "#000 !important",
		fontWeight: "normal",
		fontSize: "12px",
		lineHeight: "1.42857",
		//  top: "10px",
		position: "static",
		"& + $underline": {
			marginTop: "0px"
		}
	},
	labelRootError: {
		color: dangerColor + " !important"
	},
	labelRootSuccess: {
		color: successColor + " !important"
	},
	labelRootDisabled: {
		opacity: "0.6 !important"
	},
	formControl: {
		margin: "0 0 0px 0",
		paddingTop: "0px",
		position: "relative",
		"& svg,& .fab,& .far,& .fal,& .fas,& .material-icons": {
			color: "#495057"
		}
	},
	whiteUnderline: {
		"&:hover:not($disabled):before,&:before": {
			backgroundColor: "#FFFFFF"
		},
		"&:after": {
			backgroundColor: "#FFFFFF"
		}
	},
	input: {
		padding: "3px",
		//border: '1px rgba(0, 0, 0, 0.42) solid',
		background: "unset",
		//boxShadow: 'inset 0 0 2px rgba(0, 0, 0, 0.42)',
		color: "#000",
		"&,&::placeholder": {
			fontSize: "12px",
			fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
			fontWeight: "400",
			lineHeight: "1.42857",
			opacity: "1"
		},
		"&::placeholder": {
			color: "#888888"
		}
	},
	whiteInput: {
		"&,&::placeholder": {
			color: "#FFFFFF",
			opacity: "1"
		}
	}
};

export default customInputStyle;
