import userDefinitionStyle from "assets/jss/material-dashboard-pro-react/views/userDefinitionStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import AlertHelper from "core/AlertHelper";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import RouteHelper from "core/RouteHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import {
  GenericCheckInput,
  GenericDateInput,
  GenericEmailInput,
  GenericNumberInput,
  GenericSelectInput,
  GenericTextInput,
  GenericTitle,
} from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent.jsx";
import {
  IdentityTypeParameterValue4,
  RoleGroup,
  ClientType,
  RoleLevel,
} from "views/Constants/Constant";

const sortParamDesc = { Member: "ParameterDesc" };
const sortName = { Member: "Name" };
const sortOrderIndex = { Member: "OrderIndex" };
const sortStoreName = { Member: "StoreName" };
const emptyObject = {};
const RoleObj = {};
const lengthAddress = { maxLength: 280 };
const lengthZipCode = { maxLength: 40 };

class UserDefinition extends Component {
  constructor(props) {
    super(props);
    this.getappCenterTitle = "Empty";
    this.getappLeftTitle = "Empty";
    this.isBackOffice = ClientHelper.IsBackOffice();
    this.clientId = ClientHelper.GetClientId();

    this.defaultModel = {
      UniqueClientId: this.isBackOffice ? undefined : this.clientId,
      CountryId: undefined,
      ProvinceId: undefined,
      CityId: undefined,
      RoleGroup: undefined,
      Id: undefined,
      Name: undefined,
      MiddleName: undefined,
      LastName: undefined,
      Position: undefined,
      IdType: undefined,
      IdNumber: undefined,
      ExpDateOfId: undefined,
      AddressLine1: undefined,
      AddressLine2: undefined,
      PostalCode: undefined,
      ResidentCountry: undefined,
      CellNoFor2FACountryId: undefined,
      CellNoFor2FAPhoneNumber: undefined,
      PhoneCountryCodeId: undefined,
      PhoneNumber: undefined,
      Email: undefined,
      UserRole: undefined,
      UserTypeId: undefined,
      UserName: undefined,
      ExpDateOfUser: undefined,
      SendTempPasswordByEmail: undefined,
      SendTempPasswordByText: undefined,
      UserStatus: undefined,
      Store: undefined,
      IsUserNameValid: undefined,
      IsEmailValid: undefined,
      IdFormat: undefined,
      IdLength: undefined,
      IsSalesPerson: undefined,
      OrganizationUniqueId: this.isBackOffice ? undefined : window.OrganizationUniqueId,
    };

    this.state = {
      model: { ...this.defaultModel },
      vModel: {},
      isLoading: false,
      IsUpdate: false,
    };

    this.ValueChanged = this.ValueChanged.bind(this);
    this.ClearClick = this.ClearClick.bind(this);
    this.ClearScreen = this.ClearScreen.bind(this);
    this.InsertUpdateClick = this.InsertUpdateClick.bind(this);
    this.Validate = this.Validate.bind(this);

    this.ShowMessage = this.ShowMessage.bind(this);
    this.hideAlert = this.hideAlert.bind(this);

    this.RenderCountrySelect = this.RenderCountrySelect.bind(this);
    this.GetRoleParameter = this.GetRoleParameter.bind(this);

    this.LoadCanadaPost = this.LoadCanadaPost.bind(this);
    this.SubmitCanadaPostAddressModel =
      this.SubmitCanadaPostAddressModel.bind(this);

    this.sortedParameterDesc = { Member: "ParameterDesc" };

    this.maxLength20 = { maxLength: 20 };
  }

  componentDidMount() {
    const { setAppCenterTitle, setAppLeftTitle } = this.props;

    if (setAppCenterTitle) setAppCenterTitle("USER ADMIN");
    this.getappCenterTitle = "USER ADMIN";

    if (setAppLeftTitle) setAppLeftTitle("New User Definition");
    this.getappLeftTitle = "New User Definition";

    this.LoadCanadaPost();

    var Id = RouteHelper.ReadAndClear("NewUserDefinition_Id") || this.props.Id;

    if (Id) {
      this.setState({ isLoading: true, IsUpdate: true });
      Proxy.POST(
        "/coreapi/v1.0/User/GetUserById",
        Id,
        (responseData) => {
          this.setState({ isLoading: false });

          if (!responseData.IsSucceeded) {
            this.ShowMessage(
              "error",
              "An error occurred while fetching data",
              responseData.ErrorDescription
            );
            return;
          }
          var data = responseData.Item.User;

          this.setState((state) => {
            var model = state.model || {};

            model.Id = data.Id;
            model.Name = data.FirstName;
            model.MiddleName = data.MiddleName;
            model.LastName = data.LastName;
            model.Position = data.PositionId;
            model.IdType = data.IdTypeId;
            model.IdTypeCode = data.IdType ? data.IdType.ParameterValue : null;
            model.IdNumber = data.IdNumber;
            model.ExpDateOfId = data.ExpDateOfId
              ? DateHelper.ToMoment(data.ExpDateOfId)
              : null;
            model.AddressLine1 = data.AddressLine1;
            model.AddressLine2 = data.AddressLine2;
            model.CityId = data.CityId;
            model.ProvinceId = data.ProvinceId;
            model.PostalCode = data.PostalCode;
            model.CountryId = data.CountryId;
            model.ResidentCountry = data.ResidentCountryId;
            model.CellNoFor2FACountryId =
              data.CellFor2FA != null
                ? data.CellFor2FA.PhoneCountryCodeId
                : undefined;
            model.CellNoFor2FAPhoneNumber =
              data.CellFor2FA != null ? data.CellFor2FA.PhoneNumber : undefined;
            model.PhoneCountryCodeId =
              data.Phone != null ? data.Phone.PhoneCountryCodeId : undefined;
            model.PhoneNumber =
              data.Phone != null ? data.Phone.PhoneNumber : undefined;
            model.Email = data.Email;
            model.IsEmailValid = true;
            model.UserRole = data.RoleId;
            model.UserName = data.UserEmail;
            model.IsUserNameValid = true;
            model.ExpDateOfUser = data.UserExpDate
              ? DateHelper.ToMoment(data.UserExpDate)
              : null;
            model.UserStatus = data.UserStatusId;
            model.UserTypeId = data.UserTypeId;
            model.Store = data.CustomerStoreId;
            model.UniqueClientId = data.UniqueClient
              ? data.UniqueClient.UniqueClientId
              : null;

            model.SendTempPasswordByEmail = data.SendTempPasswordByEmail;
            model.SendTempPasswordByText = data.SendTempPasswordByText;
            model.OrganizationUniqueId = data.OrganizationUniqueId;
            return { model };
          });
        },
        (errorMessage) => {
          this.setState({ isLoading: false });
          this.ShowMessage(
            "error",
            "An error occurred while fetching data",
            errorMessage
          );
        }
      );
    }
  }

  LoadCanadaPost() {
    var parent = this;

    Proxy.POST(
      "/coreapi/v1.0/Parameter/Search",
      {
        ParameterCode: "CanadaPostApiKey",
        ParameterValue: "CP",
      },
      (responseData) => {
        if (
          !responseData.IsSucceeded ||
          responseData.Item == null ||
          responseData.Item[0] == null
        ) {
          console.log("Canada Post Api key not fetched !");
          return;
        }

        var apikey = responseData.Item[0].ParameterDesc;
        var url = window.CanadaPostAddressCompleteUrl;
        var path = url + apikey;

        var pca;
        var CanadaPostRequest = this.SubmitCanadaPostRequest;
        var CanadaPostSubmit = this.SubmitCanadaPostAddressModel;
        const script = document.createElement("script");
        script.src = path;
        script.async = false;
        document.head.appendChild(script);
        script.onload = function () {
          pca = window.pca;
          if (pca != null) {
            var fields = [
                {
                  element: "street-address",
                  field: "Line1",
                  mode: pca.fieldMode.SEARCH,
                },
                {
                  element: "street-address2",
                  field: "Line2",
                  mode: pca.fieldMode.POPULATE,
                },
                {
                  element: "postcode",
                  field: "PostalCode",
                  mode: pca.fieldMode.SEARCH,
                },
              ],
              options = {
                key: apikey,
              },
              control = new pca.Address(fields, options);
            CanadaPostRequest();

            control.listen("populate", function (address) {
              CanadaPostSubmit(address);
              parent.setState((state) => {
                state.model.AddressLine1 = address.Line1;
                state.model.AddressLine2 = address.Line2;
                state.model.PostalCode = address.PostalCode;
                return state;
              });
            });
          }
        };
      },
      (e) => {
        console.log("Canada Post Api key not fetched !");
        console.dir(e);
      }
    );
  }

  SubmitCanadaPostAddressModel(model) {
    this.setState({ isLoading: true });
    Proxy.POST(
      "/coreapi/v1.0/Country/CanadaPost",
      model,
      (responseData) => {
        if (!responseData.IsSucceeded) {
          this.ShowMessage("error", "Error", responseData.ErrorDescription);
          return;
        }
        this.setState({ isLoading: false });
        var address = { ...this.state.model };
        if (responseData.Item != null) {
          address.CountryId = responseData.Item.CountryId;
          address.ProvinceId = responseData.Item.ProvinceId;
          address.CityId = responseData.Item.CityId;
        } else {
          address.CountryId = 0;
          address.ProvinceId = 0;
          address.CityId = 0;
        }
        this.setState({ model: address });
      },
      (error) => {
        this.setState({ isLoading: false });
        this.ShowMessage("error", "Error", error);
      }
    );
  }
  SubmitCanadaPostRequest = async () => {
    const model = { ...this.state.model };
    var requestdata = {
      UniqueClientId: null,
      MenuCode: this.props.getMenuCode,
      AppCenterTitle: this.getappCenterTitle,
      AppLeftTitle: this.getappLeftTitle,
    };
    Proxy.POST(
      "/bankapi/v1.0/Reporting/CanadaPostRequestReportInsert",
      requestdata,
      (responseData) => {
        if (responseData.Item != null) {
        }
      }
    );
    // var result = await this.props.ExecuteApiPost("/bankapi/v1.0/Reporting/CanadaPostRequestReportInsert", requestdata);
  };
  ValueChanged(name, newValue, data) {
    this.setState((state) => {
      var model = state.model || {};
      model[name] = newValue;
      if (name == "Email") {
        model.IsEmailValid = data.IsValid;
      }
      if (name == "UserName") {
        model.IsUserNameValid = data.IsValid;
      }
      if (name == "SendTempPasswordByEmail") {
        model.SendTempPasswordByText = !newValue;
      }
      if (name == "SendTempPasswordByText") {
        model.SendTempPasswordByEmail = !newValue;
      }
      if (name == "UniqueClientId") {
        if (
          data &&
          data.ClientType &&
          data.ClientType.ParameterValue == ClientType.SalesAgentPersonal
        )
          model.IsSalesPerson = true;
        else model.IsSalesPerson = false;
      }
      if (name == "IdType") {
        if (data != null) {
          model.IdTypeCode = data.ParameterValue;
          model.IdNumber = "";
        } else {
          model.IdTypeCode = "";
          model.IdNumber = "";
        }
      }

      return { model };
    });
  }

  ClearClick() {
    this.ClearScreen();
  }

  ClearScreen() {
    if (!this.isBackOffice)
      this.defaultModel.RoleGroup = this.state.model.RoleGroup;

    this.setState({ model: { ...this.defaultModel }, IsUpdate: false });
  }

  InsertUpdateClick() {
    if (!this.Validate()) return;

    const { model, IsUpdate } = this.state;

    var request = {
      Id: model.Id,
      Name: model.Name,
      MiddleName: model.MiddleName,
      LastName: model.LastName,
      PositionId: model.Position,
      IdTypeId: model.IdType,
      IdNumber: model.IdNumber,
      ExpDateOfId: model.ExpDateOfId,
      AddressLine1: model.AddressLine1,
      AddressLine2: model.AddressLine2,
      CityId: model.CityId,
      ProvinceId: model.ProvinceId,
      PostalCode: model.PostalCode,
      CountryId: model.CountryId,
      ResidentCountryId: model.ResidentCountry,
      CellNoFor2FACountryId: model.CellNoFor2FACountryId,
      CellNoFor2FAPhoneNumber: model.CellNoFor2FAPhoneNumber,
      PhoneCountryCodeId: model.PhoneCountryCodeId,
      PhoneNumber: model.PhoneNumber,
      Email: model.Email,
      UniqueClientId: model.UniqueClientId,
      UserRoleId: model.UserRole,
      UserEmail: model.UserName,
      UserName: model.UserName,
      ExpDateOfUser: model.ExpDateOfUser,
      SendTempPasswordByEmail: model.SendTempPasswordByEmail,
      SendTempPasswordByText: model.SendTempPasswordByText,
      UserStatusId: model.UserStatus,
      UserTypeId: model.UserTypeId,
      CustomerStoreId: model.Store,
      OrganizationUniqueId: model.OrganizationUniqueId,
    };

    this.setState({ isLoading: true });

    if (!IsUpdate)
      Proxy.POST(
        "/coreapi/v1.0/User/InsertUser",
        request,
        (responseData) => {
          this.setState({ isLoading: false });

          if (!responseData.IsSucceeded) {
            this.ShowMessage(
              "error",
              "An error occurred while inserting data",
              responseData.ErrorDescription
            );
            return;
          }
          this.ShowMessage("success", "User successfully saved.");
          model.Id = responseData.Item;
          this.setState({ model });
        },
        (e) => {
          this.setState({ isLoading: false });
          this.ShowMessage(
            "error",
            "An error occurred while inserting data",
            e
          );
        }
      );
    else
      Proxy.POST(
        "/coreapi/v1.0/User/UpdateUser",
        request,
        (responseData) => {
          this.setState({ isLoading: false });

          if (!responseData.IsSucceeded) {
            this.ShowMessage(
              "error",
              "An error occurred while updating data",
              responseData.ErrorDescription
            );
            return;
          }

          this.ShowMessage("success", "User successfully updated.");
        },
        (e) => {
          this.setState({ isLoading: false });
          this.ShowMessage("error", "An error occurred while updating data", e);
        }
      );
  }

  Validate() {
    const { model, vModel, IsUpdate } = this.state;

    var messages = [];
    if (!this.isBackOffice && model.UniqueClientId == null) {
      vModel.UniqueClientId = true;
      messages.push("Unique Client is not selected.");
    } else {
      vModel.UniqueClientId = false;
    }
    // if (!this.isBackOffice && model.OrganizationUniqueId == null) { vModel.OrganizationUniqueId = true; messages.push("OrganizationUniqueId is not selected."); } else { vModel.UniqueClientId = false; }

    if (model.UserRole == null || model.UserRole == "") {
      vModel.UserRole = true;
      messages.push("User Role is not selected.");
    } else {
      vModel.UserRole = false;
    }
    if (model.UserName == null || model.UserName == "") {
      vModel.UserName = true;
      messages.push("User Name cannot be null.");
    } else if (!model.IsUserNameValid) {
      vModel.UserName = true;
      messages.push("User Name is not a valid mail.");
    } else {
      vModel.UserName = false;
    }
    if (model.ExpDateOfUser == null) {
      vModel.ExpDateOfUser = true;
      messages.push("Expiration Date Of User cannot be null.");
    } else if (typeof model.ExpDateOfUser != "object") {
      vModel.ExpDateOfUser = true;
      messages.push("Expiration Date Of User is invalid.");
    } else {
      vModel.ExpDateOfUser = false;
    }
    if (!model.SendTempPasswordByEmail && !model.SendTempPasswordByText) {
      vModel.SendTempPassword = true;
      messages.push("Please choose password send method.");
    } else {
      vModel.SendTempPassword = false;
    }
    if (model.UserStatus == null || model.UserStatus == "") {
      vModel.UserStatus = true;
      messages.push("User Status is not selected.");
    } else {
      vModel.UserStatus = false;
    }
    if (model.Name == null || model.Name == "") {
      vModel.Name = true;
      messages.push("Name cannot be null.");
    } else {
      vModel.Name = false;
    }
    if (model.LastName == null || model.LastName == "") {
      vModel.LastName = true;
      messages.push("Last Name cannot be null.");
    } else {
      vModel.LastName = false;
    }
    if (
      model.IdType != null &&
      (model.IdNumber == null || model.IdNumber == "")
    ) {
      vModel.IdNumber = true;
      messages.push("ID type is invalid.");
    } else if (model.IdNumber && model.IdNumber != "") {
      vModel.IdNumber = false;
    }
    if (model.ExpDateOfId != null && typeof model.ExpDateOfId != "object")
      messages.push("Expiration Date Of Id is invalid.");

    if (model.Email == null || model.Email == "") {
      vModel.Email = true;
      messages.push("Email cannot be null.");
    } else if (!model.IsEmailValid) {
      vModel.Email = true;
      messages.push("Email is not a valid mail.");
    } else {
      vModel.Email = false;
    }

    this.setState({ vModel });

    if (messages.length > 0) {
      if (messages.length > 11) {
        var moreWarn = messages.length - 10;
        messages = messages.slice(0, 10);
        messages.push("And " + moreWarn + " more warnings");
      }
      this.ShowMessage(
        "warning",
        "Please fill required fields!",
        messages.map((x, i) => <div key={i}>{x}</div>)
      );
      return false;
    }
    return true;
  }

  ShowMessage(type, title, message) {
    this.setState({
      alert: AlertHelper.CreateAlert(title, message, type, this.hideAlert),
    });
  }

  hideAlert() {
    this.setState({ alert: null });
  }

  RenderCountrySelect(d) {
    return "+" + d.Code + " (" + d.Name + ")";
  }

  GetRoleParameter() {
    const { model } = this.state;

    if (this.isBackOffice)
      if (model.UniqueClientId == null)
        RoleObj.RoleGroupCode = RoleGroup.Backoffice;
      else RoleObj.RoleGroupCode = RoleGroup.Client;
    else RoleObj.RoleGroupCode = RoleGroup.Client;

    return RoleObj;
  }
  RenderOrganizationUniqueIdSelect = (d) => {
    if (window.OrganizationUniqueId != 5000) {
      const model = { ...this.state.model };
      model["OrganizationUniqueId"] = d.OrganizationUniqueId;
      this.setState({ model: model });
    }

    return d.OrganizationUniqueId + "-" + d.OrganizationName;
  };
  render() {
    const { Disabled } = this.props;
    const { isLoading, alert, model, vModel, IsUpdate } = this.state;

    var isClientUser = false;
    if (IsUpdate) {
      if (model.UniqueClientId != null && model.UniqueClientId != "")
        isClientUser = true;
    }

    return (
      <div>
        <LoadingComponent Show={isLoading} />
        {alert}
        <ButtonToolbar
          ButtonList={[
            { Code: "Clear", OnClick: this.ClearClick, Disabled: Disabled },
            {
              Code: "Submit",
              OnClick: this.InsertUpdateClick,
              Disabled: Disabled,
            },
          ]}
          menuId={this.props.menuId}
          ApprovalData={this.props.ApprovalData}
          After={this.props.After}
        />
        <GridContainer spacing={16}>
          <GridItem xs={12}>
            <div>
              <GridContainer>
                <GridItem xs={12}>
                  <Card>
                    <CardHeader>
                      <GenericTitle text="User Information" />
                    </CardHeader>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={4}>
                          <GenericTextInput
                            Name="Name"
                            LabelText="Name"
                            Value={model.Name}
                            ValueChanged={this.ValueChanged}
                            Disabled={Disabled}
                            Required
                            IsInvalid={vModel.Name}
                          />
                          <GenericTextInput
                            Name="MiddleName"
                            LabelText="Middle Name"
                            Value={model.MiddleName}
                            ValueChanged={this.ValueChanged}
                            Disabled={Disabled}
                          />
                          <GenericTextInput
                            Name="LastName"
                            LabelText="Last Name"
                            Value={model.LastName}
                            ValueChanged={this.ValueChanged}
                            Disabled={Disabled}
                            Required
                            IsInvalid={vModel.LastName}
                          />
                          <ParameterComponent
                            Name="Position"
                            Value={model.Position}
                            LabelText="Position"
                            ParameterCode="Position"
                            ValueChanged={this.ValueChanged}
                            Sorted={sortParamDesc}
                            Disabled={Disabled}
                          />
                          <ParameterComponent
                            Name="IdType"
                            Value={model.IdType}
                            LabelText="ID Type"
                            ParameterCode="IdentityType"
                            Sorted={this.sortedParameterDesc}
                            ValueChanged={this.ValueChanged}
                            Disabled={Disabled}
                          />
                          <GenericTextInput
                            Name="IdNumber"
                            LabelText="ID Number"
                            Value={model.IdNumber}
                            ValueChanged={this.ValueChanged}
                            Disabled={
                              Disabled ||
                              model.IdType == null ||
                              model.IdType == ""
                            }
                            Required={model.IdType != null}
                            IsInvalid={vModel.IdNumber}
                            inputProps={this.maxLength20}
                          />
                          <GenericDateInput
                            Name="ExpDateOfId"
                            LabelText="Exp. Date of ID"
                            Value={model.ExpDateOfId}
                            ValueChanged={this.ValueChanged}
                            IsFuture
                            Disabled={Disabled}
                          />
                        </GridItem>
                        <GridItem xs={4}>
                          <GenericTextInput
                            Id="street-address"
                            Name="AddressLine1"
                            LabelText="Address Line 1"
                            Value={model.AddressLine1}
                            ValueChanged={this.ValueChanged}
                            inputProps={lengthAddress}
                            Disabled={Disabled}
                          />
                          <GenericTextInput
                            Id="street-address2"
                            Name="AddressLine2"
                            LabelText="Address Line 2"
                            Value={model.AddressLine2}
                            ValueChanged={this.ValueChanged}
                            inputProps={lengthAddress}
                            Disabled={Disabled}
                          />
                          <GenericSelectInput
                            Name="CountryId"
                            LabelText="Country"
                            Method="POST"
                            Url="/coreapi/v1.0/Country/GetAll"
                            Parameter={{}}
                            DataRoot="Item"
                            KeyValueMember="Id"
                            TextValueMember="Name"
                            Value={model.CountryId}
                            ValueChanged={this.ValueChanged}
                            Disabled={Disabled}
                          />
                          <GenericSelectInput
                            key={model.CountryId + "province"}
                            Name="ProvinceId"
                            LabelText="Province"
                            Method="POST"
                            Url="/coreapi/v1.0/Province/Filter"
                            Parameter={{ CountryId: model.CountryId }}
                            DataRoot="Item"
                            KeyValueMember="Id"
                            TextValueMember="Name"
                            Value={model.ProvinceId}
                            ValueChanged={this.ValueChanged}
                            Disabled={Disabled}
                          />
                          <GenericSelectInput
                            key={"city" + model.ProvinceId + ""}
                            Name="CityId"
                            LabelText="City"
                            Method="POST"
                            Url="/coreapi/v1.0/City/Filter"
                            Parameter={{ ProvinceId: model.ProvinceId }}
                            DataRoot="Item"
                            KeyValueMember="Id"
                            TextValueMember="Name"
                            Value={model.CityId}
                            ValueChanged={this.ValueChanged}
                            Disabled={Disabled}
                          />
                          <GenericTextInput
                            Id="postcode"
                            Name="PostalCode"
                            LabelText="Postal Code"
                            Value={model.PostalCode}
                            ValueChanged={this.ValueChanged}
                            inputProps={lengthZipCode}
                            Disabled={Disabled}
                          />
                        </GridItem>
                        <GridItem xs={4}>
                          <GenericSelectInput
                            Name="ResidentCountry"
                            LabelText="Resident Country"
                            Method="POST"
                            Url="/coreapi/v1.0/Country/GetAll"
                            Parameter={emptyObject}
                            DataRoot="Item"
                            KeyValueMember="Id"
                            TextValueMember={"Name"}
                            Value={model.ResidentCountry}
                            ValueChanged={this.ValueChanged}
                            Sorted={sortName}
                            Disabled={Disabled}
                          />
                          <GridContainer>
                            <GridItem xs={8}>
                              <GenericSelectInput
                                Name="CellNoFor2FACountryId"
                                LabelText="Cell No for 2FA"
                                LabelMd={6}
                                Method="POST"
                                Url="/coreapi/v1.0/Country/GetAll"
                                Parameter={emptyObject}
                                DataRoot="Item"
                                KeyValueMember="Id"
                                TextValueMember={"Code"}
                                Value={model.CellNoFor2FACountryId}
                                ValueChanged={this.ValueChanged}
                                Sorted={sortOrderIndex}
                                RenderItem={this.RenderCountrySelect}
                                Disabled={Disabled}
                              />
                            </GridItem>
                            <GridItem xs={4}>
                              <GenericNumberInput
                                Format={"(###) ### ####"}
                                Name="CellNoFor2FAPhoneNumber"
                                LabelMd={0}
                                Value={model.CellNoFor2FAPhoneNumber}
                                ValueChanged={this.ValueChanged}
                                Disabled={Disabled}
                              />
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem xs={8}>
                              <GenericSelectInput
                                Name="PhoneCountryCodeId"
                                LabelText="Phone Number"
                                LabelMd={6}
                                Method="POST"
                                Url="/coreapi/v1.0/Country/GetAll"
                                Parameter={emptyObject}
                                DataRoot="Item"
                                KeyValueMember="Id"
                                TextValueMember={"Code"}
                                Value={model.PhoneCountryCodeId}
                                ValueChanged={this.ValueChanged}
                                Sorted={sortOrderIndex}
                                RenderItem={this.RenderCountrySelect}
                                Disabled={Disabled}
                              />
                            </GridItem>
                            <GridItem xs={4}>
                              <GenericNumberInput
                                IsPhone
                                Name="PhoneNumber"
                                LabelMd={0}
                                Value={model.PhoneNumber}
                                ValueChanged={this.ValueChanged}
                                Disabled={Disabled}
                              />
                            </GridItem>
                          </GridContainer>
                          <GenericEmailInput
                            Name="Email"
                            LabelText="EMail"
                            Value={model.Email}
                            ValueChanged={this.ValueChanged}
                            Disabled={Disabled}
                            Required
                            IsInvalid={vModel.Email}
                          />
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={12}>
                  <Card>
                    <CardHeader>
                      <GenericTitle text="New User" />
                    </CardHeader>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={4}>
                          <GenericSelectInput
                            IsTextBold
                            Disabled={
                              Disabled ||
                              !this.isBackOffice                           
                            }
                            key={this.state.IsClientComponent}
                            Name="OrganizationUniqueId"
                            LabelText="Organization"
                            Value={model.OrganizationUniqueId || ""}
                            DataRoot="Item"
                            ValueChanged={this.ValueChanged}
                            KeyValueMember="OrganizationUniqueId"
                            TextValueMember="OrganizationUniqueId"
                            //  TaskPoolDomain={this.loadTaskPool}
                            Url="/bankapi/v1.0/OrganizationInfo/GetAll"
                            Method="POST"
                            RenderItem={this.RenderOrganizationUniqueIdSelect}
                            Parameter={emptyObject}
                            Required
                            IsInvalid={vModel.OrganizationUniqueId}
                          />
                          <GenericSelectInput
                            Name="UniqueClientId"
                            LabelText="Unique Client Name"
                            Value={model.UniqueClientId}
                            DataRoot="Item"
                            ValueChanged={this.ValueChanged}
                            KeyValueMember="UniqueClientId"
                            TextValueMember="Name"
                            Url="/bankapi/v1.0/BankCustomer/Search"
                            CanClear={!IsUpdate}
                            Method="POST"
                            Disabled={
                              Disabled ||
                              !this.isBackOffice ||
                              (IsUpdate && !isClientUser)
                            }
                            Parameter={emptyObject}
                            Required={!this.isBackOffice}
                            IsInvalid={vModel.UniqueClientId}
                          />
                          <GenericTextInput
                            LabelText="Unique Client ID"
                            Value={model.UniqueClientId}
                            Disabled={true}
                          />
                          <GenericSelectInput
                            key={"Store_" + model.UniqueClientId}
                            Name="Store"
                            LabelText="Store"
                            Method="POST"
                            Url="/bankapi/v1.0/CustomerStore/GetByCustomerId"
                            Parameter={model.UniqueClientId}
                            DataRoot="Item"
                            KeyValueMember="Id"
                            TextValueMember="StoreName"
                            Value={model.Store}
                            ValueChanged={this.ValueChanged}
                            Sorted={sortStoreName}
                            Disabled={Disabled}
                          />
                          {model.IsSalesPerson != true ? (
                            <GenericSelectInput
                              key={"Role_" + model.UniqueClientId}
                              Name="UserRole"
                              LabelText="User Role"
                              Method="POST"
                              Url="/coreapi/v1.0/Role/GetRoleListByRoleGroupId"
                              Parameter={this.GetRoleParameter()}
                              DataRoot="RoleList"
                              KeyValueMember="Id"
                              TextValueMember="RoleName"
                              Value={model.UserRole}
                              ValueChanged={this.ValueChanged}
                              Disabled={Disabled}
                              Required
                              IsInvalid={vModel.UserRole}
                            />
                          ) : (
                            <GenericSelectInput
                              key={"Role_" + model.UniqueClientId}
                              Name="UserRole"
                              LabelText="User Role"
                              Method="POST"
                              Url="/coreapi/v1.0/Role/GetRoleListByRoleLevelId"
                              Parameter={{
                                RoleLevelCode: RoleLevel.SalesPerson,
                              }}
                              DataRoot="Item"
                              KeyValueMember="RoleId"
                              TextValueMember="RoleName"
                              Value={model.UserRole}
                              ValueChanged={this.ValueChanged}
                              Disabled={Disabled}
                              Required
                              IsInvalid={vModel.UserRole}
                            />
                          )}
                          {model.UniqueClientId != null &&
                            model.UniqueClientId > 0 &&
                            this.isBackOffice && (
                              <GenericSelectInput
                                Name="UserTypeId"
                                LabelText="User Type"
                                Value={model.UserTypeId}
                                ValueChanged={this.ValueChanged}
                                KeyValueMember="Id"
                                TextValueMember="ParameterDesc"
                                Method="POST"
                                Url="/coreapi/v1.0/Parameter/Search"
                                Parameter={{
                                  ParameterCode: "UserType",
                                  ParameterValue3: "CC",
                                }}
                                Disabled={Disabled}
                                DataRoot="Item"
                                Required
                                IsInvalid={vModel.UserTypeId}
                              />
                            )}
                          <GenericEmailInput
                            Name="UserName"
                            LabelText="User Name"
                            Value={model.UserName}
                            ValueChanged={this.ValueChanged}
                            Disabled={Disabled || IsUpdate}
                            Required
                            IsInvalid={vModel.UserName}
                          />
                          <GenericDateInput
                            Name="ExpDateOfUser"
                            LabelText="Exp. Date of User"
                            Value={model.ExpDateOfUser}
                            ValueChanged={this.ValueChanged}
                            IsFuture
                            Disabled={Disabled}
                            Required
                            IsInvalid={vModel.ExpDateOfUser}
                          />
                          <ParameterComponent
                            key={"UserStatus" + (IsUpdate ? "Update" : "")}
                            Name="UserStatus"
                            Value={model.UserStatus}
                            LabelText="User Status"
                            ParameterCode="UserStatus"
                            ValueChanged={this.ValueChanged}
                            ParameterValue2={IsUpdate ? null : "1"}
                            Disabled={Disabled}
                            Required
                            IsInvalid={vModel.UserStatus}
                          />
                        </GridItem>
                        <GridItem xs={4}>
                          <div
                            style={{
                              border: `1px solid ${
                                vModel.SendTempPassword == true
                                  ? window.InvalidFieldColor
                                  : window.RequiredFieldColor
                              }`,
                            }}
                          >
                            <GenericCheckInput
                              Name="SendTempPasswordByEmail"
                              LabelText="Send temporary password to user by e-mail"
                              Value={model.SendTempPasswordByEmail}
                              ValueChanged={this.ValueChanged}
                              Disabled={Disabled}
                            />
                            <br />
                            <GenericCheckInput
                              Name="SendTempPasswordByText"
                              LabelText="Send temporary password to user by text"
                              Value={model.SendTempPasswordByText}
                              ValueChanged={this.ValueChanged}
                              Disabled={Disabled}
                            />
                          </div>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

UserDefinition.propTypes = {
  classes: PropTypes.object.isRequired,
  Disabled: PropTypes.bool,
  Id: PropTypes.number,
};

export default withArtifex(UserDefinition, userDefinitionStyle);
