import { DialogContent, DialogTitle, Slide } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import { DetailIcon, VerificationIcon, DeleteIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericCheckInput, GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericTextInput,GenericRadioInput, GenericSelectInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import ParameterComponent from "views/Components/ParameterComponent";
import { FinScanSearchStatus, FinScanSearchResultType } from "views/Constants/Constant";
import { Proxy } from "core";
import Select from "react-select";
import LoadingComponent from "views/Components/LoadingComponent";


function Transition(props) {
	return <Slide direction="up" {...props} />;
}

class FinScanWatchlistControl extends React.Component {
	constructor(props) {
		super(props);
		this.columnsList = [];
		this.columnsDetail = [
			{
				Header: "FullName",
				accessor: "FullName"
			},
			{
				Header: "Gender",
				accessor: "Gender"
			},
			{
				Header: "Nationality",
				accessor: "Nationality"
			},
			{
				Header: "Country",
				accessor: "Country"
			},
			{
				Header: "PlaceOfBirth",
				accessor: "PlaceOfBirth"
			},
			{
				Header: "DateOfBirth",
				accessor: "DateOfBirth"
			},
			{
				Header: "TextInfo",
				accessor: "TextInfo"
			}
		];
		this.maxLength40 = { maxLength: 40 };

		this.state = {
			model: {
				FromDate: DateHelper.GetLocalDateAsUtc(),
				ToDate: DateHelper.GetLocalDateAsUtc()
			},
			isVerifyDialogOpen: false,
			isDetailManualVerificationDialogOpen: false,
			isDetailUnverifiedDialogOpen: false
		};
		this.finScaTable = React.createRef();
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("AML & KYC");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("Watchlist Control");
		}
	}

	ValueChanged = (name, value, data) => {
		this.setState(state => {
			var model = state.model;

			model[name] = value;

			if (name == "FromDate") {
				model["ToDate"] = undefined;
			}

			return { model };
		});
	}

	GetColumnsList = (searchResult) => {
		this.columnsList = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							Disabled={row.original.SearchStatusParameterValue == FinScanSearchStatus.Verified || row.original.SearchStatusParameterValue == FinScanSearchStatus.Rejected}
							tooltip="Verify"
							Icon={VerificationIcon}
							OnClick={() => row.original.SearchStatusParameterValue == FinScanSearchStatus.Unverified
								? this.MemoVerifyDialogOpen(row.original.Id)
								: this.VerifyDialogOpen(row.original.Id)} />
						<GridButton
							Disabled={row.original.SearchStatusParameterValue == FinScanSearchStatus.Verified || row.original.SearchStatusParameterValue == FinScanSearchStatus.Rejected}
							tooltip="Reject"
							Icon={DeleteIcon}
							OnClick={() => this.MemoRejectDialogOpen(row.original.Id)} />
						<GridButton
							Disabled={row.original.SearchStatusParameterValue == FinScanSearchStatus.Verified}
							tooltip="Detail"
							Icon={DetailIcon}
							OnClick={() => row.original.SearchResultParameterValue == FinScanSearchResultType.ListExactMatch
								? this.DetailUnverifiedDialogOpen(row.original.Id) : 
								this.DetailManualVerificationDialogOpen(row.original.Id) }/>
					</div>
				),
				width: 100
			}
		];

	      searchResult[0].JsonDataParsed.map(x => {
			this.columnsList.push({
				Header: x.Key.split(/(?=[A-Z])/).join(" "),
				accessor: x.Key,
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.SearchStatusParameterValue)}>{row.value}</div>)
			});

			return null;
		});

		this.columnsList.push({
			Header: "Search Type",
			accessor: "SearchTypeParameterDesc",
			Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.SearchStatusParameterValue)}>{row.value}</div>)
		});
		this.columnsList.push({
			Header: "Search Date",
			accessor: "SearchDate",
			Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.SearchStatusParameterValue)}>{Formatter.FormatDate(row.value)}</div>)
		});
		this.columnsList.push({
			Header: "Search Status",
			accessor: "SearchStatusParameterDesc",
			Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.SearchStatusParameterValue)}>{row.value}</div>)
		});
		this.columnsList.push({
			Header: "Is Scheduled",
			accessor: "IsScheduled",
			Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.SearchStatusParameterValue)}>{row.value ? "Yes" : "No"}</div>)
		});
		this.columnsList.push({
			Header: "Client Name",
			accessor: "ClientName",
			Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.SearchStatusParameterValue)}>{row.value}</div>)
		});
		this.columnsList.push({
			Header: "Update User",
			accessor: "UpdateUser",
			Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.SearchStatusParameterValue)}>{row.value}</div>)
		});
		this.columnsList.push({
			Header: "Update Date",
			accessor: "UpdateDateTime",
			Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.SearchStatusParameterValue)}>{Formatter.FormatDate(row.value)}</div>)
		});
		this.columnsList.push({
			Header: "Approve/Reject Reason",
			accessor: "ApproveReason",
			Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.SearchStatusParameterValue)}>{row.value}</div>)
		});

		this.forceUpdate();
	}

	GetColumnsDetail = (searchResult) => {
		this.columnsDetail = [];

		searchResult.ApiPartialMatches[0].JsonResultDataParsed.map(x => {
			this.columnsDetail.push({
				Header: x.Key.split(/(?=[A-Z])/).join(" "),
				accessor: x.Key
			});

			return null;
		});

		this.columnsDetail.push({
			Header: "Search Result Source",
			accessor: "SearchResultSource"
		});

		this.forceUpdate();
	}

	GetColumnsRowStyleColor(status) {
		this.columnsRowStyleColor = {
			color: status != null
				&& (status == FinScanSearchStatus.ManualVerification || status == FinScanSearchStatus.Unverified)
				? "#FB3005"
				: ""
		};

		return this.columnsRowStyleColor;
	}

	VerifyDialogOpen = (finScanSearchLogId) => {
		var { model } = this.state;
		model.FinScanSearchLogId = finScanSearchLogId;
		model.SearchStatusUpdateId = null;

		this.setState({ model, isVerifyDialogOpen: true });
	}

	MemoVerifyDialogOpen = (finScanSearchLogId) => {
		var { model } = this.state;
		model.FinScanSearchLogId = finScanSearchLogId;

		this.setState({ model, isMemoVerifyDialogOpen: true });
	}

	MemoRejectDialogOpen = (finScanSearchLogId) => {
		var { model } = this.state;
		model.FinScanSearchLogId = finScanSearchLogId;

		this.setState({ model, isMemoRejectDialogOpen: true });
	}

	DetailManualVerificationDialogOpen = (finScanSearchLogId) => {
		this.GetFinScanSearchLogDetailManualVerification(finScanSearchLogId);
		this.setState({ isDetailManualVerificationDialogOpen: true });
	}

	DetailUnverifiedDialogOpen = (finScanSearchLogId) => {
		this.GetFinScanSearchLogDetailUnverified(finScanSearchLogId);
		this.setState({ isDetailUnverifiedDialogOpen: true });
	}

	VerifyDialogClose = () => {
		this.setState({ isVerifyDialogOpen: false });
	}

	MemoVerifyDialogClose = () => {
		this.setState({ isMemoVerifyDialogOpen: false });
	}

	MemoRejectDialogClose = () => {
		this.setState({ isMemoRejectDialogOpen: false });
	}
	
	DetailManualVerificationDialogClose = () => {
		this.setState({ isDetailManualVerificationDialogOpen: false });
	}

	DetailUnverifiedDialogClose = () => {
		this.setState({ isDetailUnverifiedDialogOpen: false });
	}

	Search = async () => {
		const { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/amlkycapi/v1.0/FinScanSearchLog/Search", model);

		if (result != null && result.length != 0) {
			this.GetColumnsList(result);
			this.SetList(result);
		} else {
			this.setState({ list: [] });
		}
	}

	GetFinScanSearchLogDetailManualVerification = async (finScanSearchLogId) => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/amlkycapi/v1.0/FinScanSearchLog/GetPartialMatchListByFinScanSearchLogId", { FinScanSearchLogId: finScanSearchLogId });

		if (result != null && result.length != 0) {
			this.SetListPartialMatches(result);
		} else {
			this.setState({ listListPartialMatches: []});
		}
	}

	GetFinScanSearchLogDetailUnverified = async (finScanSearchLogId) => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/amlkycapi/v1.0/FinScanSearchLogDetail/GetDetailBySearchLogId", { FinScanSearchLogId: finScanSearchLogId });

		if (result != null && result.length != 0) {
			var { model } = this.state;

			model = result;

			this.setState({ model });
		}
	}

	UpdateFinScanSearchLogStatus = async () => {
		const { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/amlkycapi/v1.0/FinScanSearchLog/UpdateSearchStatus",
			{ FinScanSearchLogId: model.FinScanSearchLogId, SearchStatusId: model.SearchStatusUpdateId }, null, null, null, null, true);

		if (result != null) {
			this.setState({ isVerifyDialogOpen: false });
			this.Search();
		}
	}

	UpdateFinScanSearchLogStatusForUnverified = async () => {
		const { ExecuteApiPost } = this.props;
		var { model } = this.state;
		if(model.ApproveReason == null || model.ApproveReason == ""){
			this.props.showMessage("error", "Error", "Approve reason must be defined.");
			return;
		}
		var result = await ExecuteApiPost("/amlkycapi/v1.0/FinScanSearchLog/UpdateSearchStatus",
			{ FinScanSearchLogId: model.FinScanSearchLogId, SearchStatusParameterValue: FinScanSearchStatus.Verified, ApproveReason: model.ApproveReason }, null, null, null, null, true);

		if (result != null) {
			this.Search();
		}
		this.MemoVerifyDialogClose();
	}

	UpdateFinScanSearchLogStatusToRejected = async () => {
		const { ExecuteApiPost } = this.props;
		var { model } = this.state;
		if(model.ApproveReason == null || model.ApproveReason == ""){
			this.props.showMessage("error", "Error", "Approve reason must be defined.");
			return;
		}
		var result = await ExecuteApiPost("/amlkycapi/v1.0/FinScanSearchLog/UpdateSearchStatus",
			{ FinScanSearchLogId: model.FinScanSearchLogId, SearchStatusParameterValue: FinScanSearchStatus.Rejected, ApproveReason: model.ApproveReason }, null, null, null, null, true);

		if (result != null) {
			this.Search();
		}
		this.MemoRejectDialogClose();
	}

	SetList = (searchResult) => {
		var list = searchResult;

		for (let i = 0; i < searchResult.length; i++) {
			searchResult[i].JsonDataParsed.map(x => {
				list[i][x.Key] = x.Value;

				return null;
			});
		}

		this.setState({ list });
	}

	SetListPartialMatches = (searchResult) => {
		var listListPartialMatches = searchResult;

		for (let i = 0; i < searchResult.length; i++) {
			searchResult[i].JsonResultDataParsed.map(x => {
				listListPartialMatches[i][x.Key] = x.Value;

				return null;
			});
		}

		this.setState({ listListPartialMatches });
	}

	Clear = () => {
		this.setState({
			model: {
				FromDate: DateHelper.GetLocalDateAsUtc(),
				ToDate: DateHelper.GetLocalDateAsUtc()
			}, list: [], selected: null
		});
	}
	ValueChangedForReportType = (name, value) => {
		this.setState({ [name]: value });
	}
	ExportClick = () => {
		var finScaColumns = [];
		this.finScaTable.current.props.Columns.forEach(c => {
			if (c.show == undefined || c.show) {
				finScaColumns.push({ value: c.accessor, title: c.Header, columntype: c.ColumnType, type: c.type });
			}
		}, this);

		this.setState({ isDownloadDialogOpen: true, GridKeyValueList: finScaColumns, ExportSelectedColumns: finScaColumns });
	}
	ExcelDownload = () => {
		if (!this.state.ExportReportType) {
			this.props.showMessage("warning", "Export Report Type not selected", "Select Export Report Type to continue");
			return;
		}

		if (this.state.ExportReportType == "PDF" && this.state.ExportSelectedColumns.length > 15) {
			this.props.showMessage("warning", "PDF document cannot exceed 15 columns", "PDF document cannot exceed 15 columns");
			return;
		}

		this.setState({ isDownloadDialogOpen: false });


		const temp = { ...this.state.model };


		var jsonColList = [];

		var cpverColumns = {};
		this.state.ExportSelectedColumns.map(c => {
			cpverColumns[c.value] = { value: c.value, title: c.title, columntype: c.columntype, type: c.type };
		});
		jsonColList.push(cpverColumns);

		var mytitle = "Watchlist Control";

		temp.jsonFile = {
			colmns: jsonColList,
			format: this.state.ExportReportType,
			title: mytitle
		};

		var request = {
			SearchTypeId: temp.SearchTypeId,
			SearchStatusId: temp.SearchStatusId,
			FromDate : temp.FromDate,
			ToDate:temp.ToDate,
			IsScheduled:temp.IsScheduled,
			jsonFile:temp.jsonFile,
		};
		console.log(request);
		this.setState({ isLoading: true });
		Proxy.DownloadGeneratedFile(
			"/amlkycapi/v1.0/FinScanSearchLog/FinScanSearchLogExportDownload",
			request,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", errorMessage);
			},
			this.props.showMessage
		);
	}

	showLoading = () => { this.setState({  isLoading: true }); }
	hideLoading = () => { this.setState({  isLoading: false }); }


	render() {
		const { model, list, listListPartialMatches, isVerifyDialogOpen, isDetailManualVerificationDialogOpen, isDetailUnverifiedDialogOpen,isLoading, isMemoVerifyDialogOpen, isMemoRejectDialogOpen } = this.state;
		const { Disabled } = this.props;

		return (
			<div>
				<LoadingComponent Show={isLoading} />
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						},
						{
							Code: "Export",
							Disabled: Disabled,
							OnClick: this.ExportClick,
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
									<GenericDialog open={this.state.isDownloadDialogOpen} fullWidth={true}>
					<DialogTitle>
						<GenericLabel Text="Select Report Type" FontSize="20px" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ height: 300 }}>
						<GenericRadioInput
							Name="ExportReportType"
							LabelText="Report Type"
							IsStatic={true}
							StaticData={[{ Value: "PDF", Text: "PDF" }, { Value: "CSV", Text: "CSV" }, { Value: "EXCEL", Text: "EXCEL" }]}
							KeyValueMember="Value"
							TextValueMember="Text"
							Value={this.state.ExportReportType}
							ValueChanged={this.ValueChangedForReportType}
						/>
						<GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
						<GridItem style={{ marginTop: 20 }}>
							<Select
								value={this.state.ExportSelectedColumns}
								onChange={value => this.setState({ ExportSelectedColumns: value })}
								isMulti
								name="columns"
								getOptionLabel={d => d.title}
								options={this.state.GridKeyValueList}
								className="basic-multi-select"
								classNamePrefix="select"
							/>
						</GridItem>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.ExcelDownload}>Download</Button>
						<Button size="sm" onClick={() => this.setState({ isDownloadDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>

				
				<GenericDialog open={isVerifyDialogOpen} maxWidth="sm" fullWidth onBackdropClick={this.VerifyDialogClose} TransitionComponent={Transition}>
					<DialogContent style={{ height: "auto", marginTop: 8 }}>
						<div style={{ position: "fixed", top: "auto", left: "auto", width: "555px", zIndex: 1, margin: "-5px -1px" }}>
							<ParameterComponent
								Name="SearchStatusUpdateId"
								LabelText="Search Status"
								ParameterCode="FinScanSearchStatus"
								ParameterValue2="T"
								Value={model.SearchStatusUpdateId}
								ValueChanged={this.ValueChanged} />
						</div>
					</DialogContent>
					<GenericDialogActions style={{ marginTop: 5 }}>
						<Button size="sm" onClick={this.VerifyDialogClose}>Close</Button>
						<Button size="sm" disabled={!model.SearchStatusUpdateId} onClick={this.UpdateFinScanSearchLogStatus}>Update</Button>
					</GenericDialogActions>
				</GenericDialog>

				<GenericDialog open={isMemoVerifyDialogOpen} maxWidth="sm" fullWidth onBackdropClick={this.MemoVerifyDialogClose} TransitionComponent={Transition}>
					<DialogContent style={{ height: "auto", marginTop: 8 }}>
							<GenericTextInput 
								Name="ApproveReason" 
								LabelText="Approve Reason" 
								Value={model.ApproveReason} 
								ValueChanged={this.ValueChanged} 
								Required
								inputProps={this.maxLength40}
								 />
					</DialogContent>
					<GenericDialogActions style={{ marginTop: 5 }}>
						<Button size="sm" onClick={this.MemoVerifyDialogClose}>Close</Button>
						<Button size="sm" onClick={this.UpdateFinScanSearchLogStatusForUnverified}>Verify</Button>
					</GenericDialogActions>
				</GenericDialog>

				<GenericDialog open={isMemoRejectDialogOpen} maxWidth="sm" fullWidth onBackdropClick={this.MemoRejectDialogClose} TransitionComponent={Transition}>
					<DialogContent style={{ height: "auto", marginTop: 8 }}>
							<GenericTextInput 
								Name="ApproveReason" 
								LabelText="Reject Reason" 
								Value={model.ApproveReason} 
								ValueChanged={this.ValueChanged} 
								Required
								inputProps={this.maxLength40}
								 />
					</DialogContent>
					<GenericDialogActions style={{ marginTop: 5 }}>
						<Button size="sm" onClick={this.MemoRejectDialogClose}>Close</Button>
						<Button size="sm" onClick={this.UpdateFinScanSearchLogStatusToRejected}>Reject</Button>
					</GenericDialogActions>
				</GenericDialog>


				<GenericDialog open={isDetailManualVerificationDialogOpen} maxWidth="lg" fullWidth onBackdropClick={this.DetailManualVerificationDialogClose} TransitionComponent={Transition}>
					<DialogContent>
						<GridContainer >
							<GridItem xs={12}>
								<GridContainer spacing={16}>
								<GridItem xs={12}>
									<GenericLabel
										Bold
										Text="Similar Customers List"
										FontSize="16px"
										TextColor="black"
										/>
									<br />
									<br />
									<GenericGrid
										HideButton
										Data={listListPartialMatches}
										Columns={this.columnsDetail} />
								</GridItem>
								</GridContainer>
							</GridItem>
						</GridContainer>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.DetailManualVerificationDialogClose}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>

				
				<GenericDialog open={isDetailUnverifiedDialogOpen} maxWidth="md" fullWidth onBackdropClick={this.DetailUnverifiedDialogClose} TransitionComponent={Transition}>
					<DialogTitle>
						<GenericLabel
							Bold
							Text="Unverified Detail"
							FontSize="16px"
							TextColor="black" />
					</DialogTitle>
					<DialogContent>
						<GridContainer >
							<GenericTextInput
								Disabled
								LabelText="Text Info"
								Value={model.TextInfo}
								MultiLine />
						</GridContainer>
						<GridContainer >
							<GenericTextInput
								Disabled
								LabelText="Nationality"
								Value={model.Nationality} />
						</GridContainer>
						<GridContainer >
							<GenericTextInput
								Disabled
								LabelText="Place Of Birth"
								Value={model.Country} />
						</GridContainer>
						<GridContainer >
							<GenericTextInput
								Disabled
								LabelText="Gender"
								Value={model.Gender} />
						</GridContainer>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.DetailUnverifiedDialogClose}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>


				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel>
									<GridContainer>
										<GridItem xs={4}  >
											<GenericSelectInput
													Name="UniqueClientId"
													LabelText="Client Name"
													Method="POST"
													Url="/bankapi/v1.0/BankCustomer/Search"
													Parameter={{}}
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
													Value={model.UniqueClientId || ""}
													ValueChanged={this.ValueChanged}
													CanClear
													All
												/>
											<ParameterComponent
												Name="SearchTypeId"
												LabelText="Search Type"
												ParameterCode="ClientType"
												ParameterValue2="C"
												Value={model.SearchTypeId}
												ValueChanged={this.ValueChanged} />											
										</GridItem>
										<GridItem xs={4}>
											<ParameterComponent
												Name="SearchStatusId"
												LabelText="Search Status"
												ParameterCode="FinScanSearchStatus"
												Value={model.SearchStatusId}
												ValueChanged={this.ValueChanged} />
											<GenericDateInput
												Utc
												Name="FromDate"
												LabelText="From Date"
												IsFuture={false}
												Value={model.FromDate}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={4}>
											<GenericCheckInput
												Name="IsScheduled"
												LabelText="Is Scheduled"
												Value={model.IsScheduled}
												ValueChanged={this.ValueChanged} />
											<GenericDateInput
												Utc
												Name="ToDate"
												LabelText="To Date"
												IsFuture={false}
												MinDate={model.FromDate}
												Value={model.ToDate}
												ValueChanged={this.ValueChanged} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columnsList}
													IsSorted={false}
													PageSize={10}
													ShowPagination={true} 
													ref={this.finScaTable}/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

FinScanWatchlistControl.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default FinScanWatchlistControl;