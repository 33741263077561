import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import SiteVisitAddOrUpdate from "./SiteVisitAddOrUpdate";
import SiteVisitDataTable from "./SiteVisitDataTable";

class SiteVisit extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0
		};

		this.state = {
			vModel: {},
			list: [],
			model: this.initialModel,
			fileModel: {},
			filterModel: {},
			isLoading: false,
		};
		this.getappCenterTitle = "Empty";
		this.getappLeftTitle = "Empty";
		this.fileInputRef = React.createRef();
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Site Visit");
		this.props.setAppCenterTitle("Client");
		this.getappLeftTitle = "Site Visit";
		this.getappCenterTitle = "Client";
		this.FetchCanadaPost();
	}

	HandleChange = (name, value) => {
		const model = { ...this.state.model };
		model[name] = value;
		this.setState({ model });
	}

	HandleChangeFilter = (name, value) => {
		const filterModel = { ...this.state.filterModel };
		filterModel[name] = value;
		this.setState({ filterModel });
	}

	HandleClear = () => {
		this.setState({ model: this.initialModel, filterModel: {}, selected: null, list: [], vModel: {} });
	}

	HandleSelectModel = (model, index) => {
		this.setState({ model, selected: index });
	}

	FileSelect = (e) => {
		const { model } = this.state;

		e.preventDefault();
		let reader = new FileReader();
		var file = e.target.files[0];

		if (!file) return;

		reader.onloadend = () => {
			model.Photo = reader.result;
			this.setState(state => ({
				fileModel: {
					FileName: file.name,
					File: file
				},
				model
			}));
		};
		reader.readAsDataURL(file);
	}

	UploadClick = () => {
		this.fileInputRef.current.click();
	}

	HandleGetList = () => {
		if (!this.FilterValidate())
			return;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/CustomerSiteVisit/Search",
			this.state.filterModel,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ list: responseData.Item });
				} else {
					this.setState({ list: [] });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while requesting data", error.message);
			}
		);
	}

	HandleSubmit = () => {
		const { fileModel, model } = this.state;

		if (!this.Validate())
			return;

		if (!model.IsPhotoTakenCompleted)
			model.Photo = null;

		var formData = new FormData();
		formData.append("Photo", fileModel.File, fileModel.FileName);
		formData.append("Item", JSON.stringify(model));

		this.setState({ isLoading: true });
		Proxy.FileUpload(
			"/bankapi/v1.0/CustomerSiteVisit/InsertOrUpdate",
			formData,
			(responseData) => {
				const { filterModel } = this.state;
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "An error occurred while requesting data", responseData.ErrorDescription);
					return;
				}
				if (!filterModel.StartDateOfVisit || filterModel.StartDateOfVisit > DateHelper.GetDate())
					filterModel.StartDateOfVisit = DateHelper.GetDate();
				if (!filterModel.EndDateOfVisit || filterModel.EndDateOfVisit < DateHelper.GetDate())
					filterModel.EndDateOfVisit = DateHelper.GetDate();
				this.setState({ filterModel });
				this.HandleGetList();
				this.ShowMessage("success", "Saved succesfully!");
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while requesting data", error.message);
			}
		);
	}

	HandleDelete = () => {
		this.setState({ isLoading: true });
		Proxy.GET(
			"/bankapi/v1.0/CustomerSiteVisit/Delete/Id?Id=" + this.state.model.Id,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.HandleGetList();
				this.setState({ model: this.initialModel, selected: null });
				this.ShowMessage("success", "Deleted successfully!");
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while deleting data", error.message);
			}
		);
	}

	FetchCanadaPost = () => {
		Proxy.POST("/coreapi/v1.0/Parameter/Search",
			{
				ParameterCode: "CanadaPostApiKey",
				ParameterValue: "CP",
				ParameterDesc: ""
			},
			responseData => {
				if (!responseData.IsSucceeded) {
					console.log("Canada Post Api key not fetched !");
					return;
				}
				if (responseData.Item == null) {
					return;
				}
				if (responseData.Item[0] == null) {
					return;
				}

				var apikey = responseData.Item[0].ParameterDesc;
				console.log("KEY CP ", apikey);

				var url = window.CanadaPostAddressCompleteUrl;
				var path = url + apikey;

				var CanadaPostRequest=this.SubmitCanadaPostRequest;
				var pca;
				var addressComplete;

				var temp = this.state.model;
				var CpTemp = this.AddressHandle;

				const script = document.createElement("script");
				script.src = path;
				script.async = false;
				document.head.appendChild(script);

				script.onload = function () {
					pca = window.pca;
					addressComplete = window.addressComplete;
					if (pca != null) {
						var fields =
							[
								{ element: "street-address", field: "Line1", mode: pca.fieldMode.SEARCH },
								{ element: "street-address2", field: "Line2", mode: pca.fieldMode.POPULATE },
								{ element: "city", field: "City", mode: pca.fieldMode.POPULATE },
								{ element: "state", field: "ProvinceName", mode: pca.fieldMode.SEARCH },
								{ element: "postcode", field: "PostalCode", mode: pca.fieldMode.SEARCH },
								{ element: "country", field: "CountryName", mode: pca.fieldMode.COUNTRY }
							],
							options = {
								key: apikey
							},
							control = new pca.Address(fields, options);
							CanadaPostRequest();

						control.listen("populate", function (address) {
							console.log(address);
							temp.City = address.City;
							temp.Province = address.ProvinceName;
							temp.Country = address.CountryName;
							temp.AddressLine1 = address.Line1;
							temp.AddressLine2 = address.Line2;
							temp.ZipCode = address.PostalCode;
							CpTemp(temp);
						});
					}
				};
			},
			e => {
				console.log("Canada Post Api key not fetched !");
			}
		);
	}
	SubmitCanadaPostRequest = async () => {
		const model = { ...this.state.model };
		var requestdata = { 

			UniqueClientId: null,
			MenuCode:this.props.MenuCode,
			AppCenterTitle:this.getappCenterTitle,
			AppLeftTitle:this.getappLeftTitle
		};
		Proxy.POST(
			"/bankapi/v1.0/Reporting/CanadaPostRequestReportInsert",
			requestdata,
			responseData => {
				if (responseData.Item != null ) {
					 
				}
			},
 		);
		// var result = await this.props.ExecuteApiPost("/bankapi/v1.0/Reporting/CanadaPostRequestReportInsert", requestdata);
	}
	AddressHandle = (address) => {
		var model = { ...this.state.model };
		model.AddressLine1 = address.AddressLine1;
		model.AddressLine2 = address.AddressLine2;
		model.City = address.City;
		model.Province = address.Province;
		model.Country = address.Country;
		model.ZipCode = address.ZipCode;
		this.setState({ model });
	}

	FilterValidate = () => {
		const { filterModel, vModel } = this.state;

		if (!filterModel.StartDateOfVisit) {
			vModel.StartDateOfVisit = true;
			this.ShowMessage("warning", "Start Date of Visit not selected", "Select Start Date of Visit to continue.");
			return false;
		} else { vModel.StartDateOfVisit = false; }

		if (!filterModel.EndDateOfVisit) {
			vModel.EndDateOfVisit = true;
			this.ShowMessage("warning", "End Date of Visit not selected", "Select End Date of Visit to continue.");
			return false;
		} else { vModel.EndDateOfVisit = false; }

		if (filterModel.StartDateOfVisit && filterModel.EndDateOfVisit && DateHelper.ToMoment(filterModel.EndDateOfVisit) < DateHelper.ToMoment(filterModel.StartDateOfVisit)) {
			this.ShowMessage("warning", "End Date of Visit cannot be past!", "Select End Date of Visit to continue.");
			return false;
		}

		if (!filterModel.UniqueClientId) {
			vModel.UniqueClientId2 = true;
			this.ShowMessage("warning", "Client not selected", "Select Client to continue.");
			return false;
		} else { vModel.UniqueClientId2 = false; }

		this.setState({ vModel });
		return true;
	}

	Validate = () => {
		const { model, vModel } = this.state;

		if (!model.UniqueClientId) {
			vModel.UniqueClientId = true;
			this.ShowMessage("warning", "Client Name not selected", "Select Client to continue.");
			return false;
		} else { vModel.UniqueClientId = false; }
		if (!model.LegalName) {
			vModel.LegalName = true;
			this.ShowMessage("warning", "Legal Name not selected", "Legal Name Year to continue.");
			return false;
		} else { vModel.LegalName = false; }


		if (!model.DateOfVisit) {
			vModel.DateOfVisit = true;
			this.ShowMessage("warning", "Date of Visit not selected", "Select Date of Visit to continue.");
			return false;
		} else { vModel.DateOfVisit = false; }

		if (!model.Country) {
			vModel.Country = true;
			this.ShowMessage("warning", "Country not selected", "Select Country to continue.");
			return false;
		} else { vModel.Country = false; }

		if (!model.City) {
			vModel.City = true;
			this.ShowMessage("warning", "City not selected", "Select City to continue.");
			return false;
		} else { vModel.City = false; }

		if (model.VisitCompletionDate && DateHelper.ToMoment(model.VisitCompletionDate) < DateHelper.ToMoment(model.DateOfVisit)) {
			this.ShowMessage("warning", "Visit Completion Date cannot be past!", "Select Visit Completion Date to continue.");
			return false;
		}

		if (!model.ZipCode) {
			vModel.ZipCode = true
			this.ShowMessage("warning", "Postal Code is empty!", "Enter Postal Code to continue.");
			return false;
		} else { vModel.ZipCode = false; }

		if (model.ZipCode.length > 10) {
			this.ShowMessage("warning", "The postal code must be at maximum length 10.", "Enter valid postal code to continue.");
			return false;
		}
		if (!model.IsPhotoTakenCompleted) {
			this.ShowMessage("warning", "Select Building / Office Photo taken for DCBank Database!", "Select Building / Office Photo taken for DCBank Database.");
			return false;
		}

		if (!model.VisitCompletionStatusId) {
			vModel.VisitCompletionStatusId = true;
			this.ShowMessage("warning", "Completion Status not selected", "Select  Completion Status to continue.");
			return false;
		} else { vModel.VisitCompletionStatusId = false; }
		this.setState({ vModel })
		return true;
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: "" })} OnConfirm={onConfirm} />
		});
	}

	render() {
		const { classes, Disabled } = this.props;
		const { alert, model, isLoading } = this.state;
		return (
			<div>
				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.HandleGetList, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
					{ Code: "Submit", OnClick: this.HandleSubmit, Disabled: Disabled || model.Id > 0, ModelFunction: () => model, FillDataFromModel: model => this.setState({ model }), ValidationFunction: this.Validate },
					{ Code: "Update", OnClick: this.HandleSubmit, Disabled: Disabled || model.Id === 0, ModelFunction: () => model, FillDataFromModel: model => this.setState({ model }), ValidationFunction: this.Validate },
					{ Code: "Delete", OnClick: this.HandleDelete, Disabled: Disabled || model.Id === 0, ModelFunction: () => model, FillDataFromModel: model => this.setState({ model }), ValidationFunction: this.Validate },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<SiteVisitAddOrUpdate
							vModel={this.state.vModel}
							model={this.state.model}
							vModel={this.state.vModel}
							fileModel={this.state.fileModel}
							filterModel={this.state.filterModel}
							fileInputRef={this.fileInputRef}
							fileSelect={this.FileSelect}
							uploadClick={this.UploadClick}
							handleChange={this.HandleChange}
							handleChangeFilter={this.HandleChangeFilter} />
						<SiteVisitDataTable
							key={this.state.list}
							list={this.state.list}
							selected={this.state.selected}
							model={this.state.model}
							handleGetList={this.HandleGetList}
							handleChange={this.HandleChange}
							handleSelectModel={this.HandleSelectModel} />
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

SiteVisit.propTypes = {
	classes: PropTypes.object
};

export default withArtifex(SiteVisit, {});
