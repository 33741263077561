import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { DetailIcon } from "core/Icons";
import RouteHelper from "core/RouteHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import { GenericGrid } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";

class AmlScenarioBlacklist extends React.Component {
	constructor(props) {
		super(props);

		this.columns = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip="Scenario Detail"
							Icon={DetailIcon}
							OnClick={() => {
								RouteHelper.Push(props.history, "/AMLKYCScenario", "AmlKycScenarioScenarioDetail", {
									IsAml: true,
									ScenarioCode: row.original.AmlScenarioCode
								});
							}} />
					</div>
				),
				width: 50
			},
			{
				Header: "AML Scenario Description",
				accessor: "AmlScenarioDescription"
			},
			{
				Header: "Full Name",
				accessor: "FullName"
			}
		];

		this.state = {
			model: {}
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("AML & KYC");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("Scenario Blacklist");
		}
	}

	RowSelected = (index) => {
		const { list } = this.state;

		this.setState({ model: list[index], selected: index });
	}

	List = async () => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/amlkycapi/v1.0/AmlScenarioBlacklist/List", {});

		this.setState({ list: result || [] });
	}

	Clear = () => {
		this.setState({ model: {}, list: [], selected: null });
	}

	render() {
		const { model, list, selected } = this.state;
		const { Disabled, ExecuteApiPost, showConfirmMessageInDelete } = this.props;

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.List
						},
						{
							Code: "Delete",
							Disabled: Disabled || selected == null,
							OnClick: () => showConfirmMessageInDelete(() => ExecuteApiPost("/amlkycapi/v1.0/AmlScenarioBlacklist/Delete", { Id: model.Id }, null, null, null, [this.Clear, this.List], true))
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columns}
													SelectedIndex={selected}
													RowSelected={this.RowSelected} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

AmlScenarioBlacklist.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default AmlScenarioBlacklist;