import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes, { array, bool, func, number } from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import {
  GenericDateInput,
  GenericGrid,
  GenericNumberInput,
  GenericTextInput,
  GenericTitle,
} from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType, ProgramCodes } from "views/Constants/Constant";
import DownloadButton from "views/Components/DownloadButton";
import Button from "components/CustomButtons/Button.jsx";
import { Proxy } from "core";

class eTransferSettlementFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      model: {
        SettlementDate: DateHelper.GetDate(),
      },
      summaryModel: {},
      settlementSummaryDetailList: [],
      isLoading: false,
      DownloadButtonControl: false,
    };

    this.orginationColumns = [
      {
        FileId: {
          value: "FileId",
          title: "File Id",
        },
        PmtRefNo: {
          value: "PmtRefNo",
          title: "PmtRefNo",
        },
        Currency: {
          value: "Currency",
          title: "Currency",
        },
        CpTransactionDate: {
          value: "CpTransactionDate",
          title: "CpTransaction Date",
          type: "datetime",
        },
        PmtAmount: {
          value: "PmtAmount",
          title: "Pmt Amount",
          columntype: "money",
          type: "money",
        },
        PmtStatus: {
          value: "PmtStatus",
          title: "Pmt Status",
        },
        OrginatingRequestFiUserId: {
          value: "OrginatingRequestFiUserId",
          title: "Orginating Request Fi User Id",
        },
        UniqueClientId: {
          value: "UniqueClientId",
          title: "Unique Client Id",
        },
        ClientName: {
          value: "ClientName",
          title: "Client Name",
        },
        OrginatingRequestAccountNumber: {
            value: "OrginatingRequestAccountNumber",
            title: "Orginating Request Wallet Number",
        },
        OrginatingRequestFiRefNo: {
          value: "OrginatingRequestFiRefNo",
          title: "Orginating Request Fi Ref No",
        },
        OrginatingRequestTranDate: {
          value: "OrginatingRequestTranDate",
          title: "Orginating Request Tran Date",
          type: "datetime",
        },
        OrginatingRequestLocalTranDate: {
          value: "OrginatingRequestLocalTranDate",
          title: "Orginating Request Local Tran Date",
          type: "datetime",
        },
        OrginatingRequestInitiatedBy: {
          value: "OrginatingRequestInitiatedBy",
          title: "Orginating Request Initiated By",
        },
        OrginatingRequestChannelIndicator: {
          value: "OrginatingRequestChannelIndicator",
          title: "Orginating Request Channel Indicator",
        },
        DestinationRequestFiId: {
          value: "DestinationRequestFiId",
          title: "Destination Request Fi Id",
        },
        DestinationRequestFiRefNo: {
          value: "DestinationRequestFiRefNo",
          title: "Destination Request Fi Ref No",
        },
        DestinationRequestTranDate: {
          value: "DestinationRequestTranDate",
          title: "Destination Request Tran Date",
          type: "datetime",
        },
        DestinationRequestLocalTranDate: {
          value: "DestinationRequestLocalTranDate",
          title: "Destination Request Local Tran Date",
          type: "datetime",
        },
        DestinationRequestInitiatedBy: {
          value: "DestinationRequestInitiatedBy",
          title: "Destination Request Initiated By",
        },
        DestinationRequestChannelIndicator: {
          value: "DestinationRequestChannelIndicator",
          title: "Destination Request Channel Indicator",
        },
        DestinationRequestAccountHolderName: {
          value: "DestinationRequestAccountHolderName",
          title: "Destination Request Wallet Holder Name",
        },
        DestinationRequestAccountType: {
          value: "DestinationRequestAccountType",
          title: "Destination Request Wallet Type",
        },
        DestinationRequestAccountNumber: {
          value: "DestinationRequestAccountNumber",
          title: "Destination Request Wallet Number",
            },
        DestinationRequestClientID: {
            value: "DestinationRequestClientID",
            title: "Destination Request Client ID",
        },
        DestinationRequestClientName: {
            value: "DestinationRequestClientName",
            title: "Destination Request Client Name",
        },
        Source: {
            value: "Source",
            title: "Source",
        },
        Destination: {
            value: "Destination",
            title: "Destination",
            },
        Direction: {
            value: "Direction",
            title: "Direction",
        },
        TransferType: {
            value: "TransferType",
            title: "Transfer Type",
        },
      },
    ];
    this.destinationColumns = [
      {
        FileId: {
          value: "FileId",
          title: "File Id",
        },
        PmtRefNo: {
          value: "PmtRefNo",
          title: "PmtRefNo",
        },
        Currency: {
          value: "Currency",
          title: "Currency",
        },
        CpTransactionDate: {
          value: "CpTransactionDate",
          title: "CpTransaction Date",
          type: "datetimeutc",
        },
        PmtAmount: {
          value: "PmtAmount",
          title: "Pmt Amount",
          columntype: "money",
          type: "money",
        },
        DestinationCompleteFiUserId: {
          value: "DestinationCompleteFiUserId",
          title: "DestinationCompleteFiUserId",
        },
        UniqueClientId: {
          value: "UniqueClientId",
          title: "Unique Client Id",
        },
        ClientName: {
          value: "ClientName",
          title: "Client Name",
        },
        DestinationCompleteFiRefNo: {
          value: "DestinationCompleteFiRefNo",
          title: "DestinationCompleteFiRefNo",
        },
        DestinationCompleteTranDate: {
          value: "DestinationCompleteTranDate",
          title: "DestinationCompleteTranDate",
          type: "datetimeutc",
        },
        DestinationCompleteLocalTranDate: {
          value: "DestinationCompleteLocalTranDate",
          title: "DestinationCompleteLocalTranDate",
          type: "datetimeutc",
        },
        DestinationCompleteInitiatedBy: {
          value: "DestinationCompleteInitiatedBy",
          title: "DestinationCompleteInitiatedBy",
        },
        DestinationCompleteChannelIndicator: {
          value: "DestinationCompleteChannelIndicator",
          title: "DestinationCompleteChannelIndicator",
        },
        OrginationgCompleteFiId: {
          value: "OrginationgCompleteFiId",
          title: "OrginationgCompleteFiId",
        },
        OrginationgCompleteFiRefNo: {
          value: "OrginationgCompleteFiRefNo",
          title: "OrginationgCompleteFiRefNo",
        },
        OrginationgCompleteTranDate: {
          value: "OrginationgCompleteTranDate",
          title: "OrginationgCompleteTranDate",
          type: "datetimeutc",
        },
        OrginationgCompleteLocalTranDate: {
          value: "OrginationgCompleteLocalTranDate",
          title: "OrginationgCompleteLocalTranDate",
          type: "datetimeutc",
        },
        OrginationgCompleteAccountHolderName: {
          value: "OrginationgCompleteAccountHolderName",
          title: "OrginationgCompleteAccountHolderName",
        },
        OrginationgCompleteAccountNumber: {
          value: "OrginationgCompleteAccountNumber",
          title: "OrginationgCompleteAccountNumber",
        },
      },
    ];
    this.outStandingColumns = [
      {
        FileId: {
          value: "FileId",
          title: "File Id",
        },
        PmtRefNo: {
          value: "PmtRefNo",
          title: "PmtRefNo",
        },
        Currency: {
          value: "Currency",
          title: "Currency",
        },
        PmtAmount: {
          value: "PmtAmount",
          title: "Pmt Amount",
          columntype: "money",
          type: "money",
        },
        FiRefNo: {
          value: "FiRefNo",
          title: "FiRefNo",
        },
        InitiatedBy: {
          value: "InitiatedBy",
          title: "InitiatedBy",
        },
        ChannelIndicator: {
          value: "ChannelIndicator",
          title: "ChannelIndicator",
        },
        UniqueClientId: {
          value: "UniqueClientId",
          title: "Unique Client Id",
        },
        ClientName: {
          value: "ClientName",
          title: "Client Name",
        },
      },
    ];
  }
  componentDidMount() {
    this.props.setAppLeftTitle("Interac e-Transfer / Settlement File");
    this.props.setAppCenterTitle("CLEARING & SETTLEMENT");
  }

  handleChange = (name, newValue) => {
    this.setState((state) => {
      var model = state.model;
      model[name] = newValue;
      return { model, DownloadButtonControl: false };
    });
  };

  GetData = async () => {
    if (this.IsValid()) {
      const { ExecuteApiPost } = this.props;
      var { model, DownloadButtonControl } = this.state;

      var responseData = await ExecuteApiPost(
        "/bankapi/v1.0/ClearingSettlement/GetSettlmentFileDetail",
        model,
        null,
        null,
        null,
        null,
        null
      );

      if (responseData != null) {
        if (
          responseData.SettlementFileDetail != null ||
          (responseData.SettlmentSummaryDetails != null &&
            responseData.SettlmentSummaryDetails.lenght > 0)
        )
          DownloadButtonControl = true;
        else DownloadButtonControl = false;

        this.setState({
          summaryModel: responseData.SettlementFileDetail,
          settlementSummaryDetailList: responseData.SettlmentSummaryDetails,
          DownloadButtonControl,
        });
      } else {
        this.setState({ DownloadButtonControl: false });
      }
    }
  };

  IsValid = () => {
    var vmodel = { ...this.state.model };
    if (!vmodel || !vmodel.SettlementDate) {
      this.props.showMessage(
        "warning",
        "Please fill required fields!",
        "Settlement Date cannot be null"
      );
      return false;
    } else if (typeof vmodel.SettlementDate != "object") {
      this.props.showMessage(
        "warning",
        "Please fill required fields!",
        "Settlement Date is invalid"
      );
      return false;
    }
    return true;
  };
  hideAlert = () => {
    this.setState({ alert: null, isLoading: false });
  };

  hideLoading = () => {
    this.setState({ isLoading: false });
  };

  handleClear = () => {
    this.setState({
      model: { SettlementDate: DateHelper.GetDate() },
      summaryModel: {},
      settlementDetailList: [],
    });
  };

  ExcelDownload = (contentType) => {
    var { model, summaryModel } = this.state;

    if (model.SettlementDate == null || model.SettlementDate == undefined) {
      this.props.showMessage(
        "warning",
        "Please select required field !",
        "Select a Settlement Date !"
      );
      return;
    }

    var mytitle =
      "Settlement.CA000352." +
      contentType +
      "." +
      model.SettlementDate.format("YYYY-MM-DD");
    var apiUrl = "";
    var columns = [];

    if (
      contentType == "OrginationPayments" ||
      contentType == "OrginationPaymentsOnUs"
    ) {
      apiUrl = "ETransferSettlementFileOrginationPaymentsExportDownload";
      columns = this.orginationColumns;
    } else if (contentType == "DestinationPayments") {
      apiUrl = "ETransferSettlementFileDestinationPaymentsExportDownload";
      columns = this.destinationColumns;
    } else if (contentType == "OutstandingPayments") {
      apiUrl = "ETransferSettlementFileOutstandingPaymentsExportDownload";
      columns = this.outStandingColumns;
    } else {
      this.props.showMessage("error", "Error", "Content type is invalid !");
      return;
    }

    model.FileId = summaryModel.FileId;
    model.ContentType = contentType;
    model.jsonFile = {
      colmns: columns,
      format: "CSV",
      title: mytitle,
    };

    this.setState({ isLoading: true });
    Proxy.DownloadGeneratedFile(
      "/bankapi/v1.0/ClearingSettlement/" + apiUrl,
      model,
      (responseData) => {
        this.setState({ isLoading: false });
        if (!responseData.IsSucceeded) {
          this.props.showMessage(
            "error",
            "Error",
            responseData.ErrorDescription
          );
          return;
        }
      },
      (errorMessage) => {
        this.setState({ isLoading: false });
        this.props.showMessage("error", "Error", errorMessage);
      },
      this.props.showMessage
    );
  };

  updateLegacyClients = async (contentType) => {
    const { ExecuteApiPost } = this.props;
    const { SettlementDate } = this.state.model;
    let requestData = {};

    if (contentType === "OrginationPayments") {
      requestData = {
        settlementDate: SettlementDate,
        contentType: "OrginationPayments",
      };
    }
    if (contentType === "DestinationPayments") {
      requestData = {
        settlementDate: SettlementDate,
        contentType: "DestinationPayments",
      };
    }
    if (contentType === "OutstandingPayments") {
      requestData = {
        settlementDate: SettlementDate,
        contentType: "OutstandingPayments",
      };
    }

    let responseData = await ExecuteApiPost(
      "/bankapi/v1.0/ClearingSettlement/SettlementFileDataUpdate",
      requestData
    );
  };

  render() {
    const { Disabled } = this.props;
    const { model, summaryModel, isLoading, DownloadButtonControl } =
      this.state;
    return (
      <div>
        {this.state.alert}
        <ButtonToolbar
          ButtonList={[
            { Code: "Search", OnClick: this.GetData, Disabled: Disabled },
          ]}
          menuId={this.props.menuId}
          ApprovalData={this.props.ApprovalData}
          After={this.props.After}
        />

        <LoadingComponent Show={isLoading} />
        <GridContainer>
          <GridItem xs={5}>
            <Card style={{ height: "90%" }}>
              <CardHeader>
                <GenericTitle text={"Filter"} />
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12}>
                    <GenericDateInput
                      Utc
                      Name="SettlementDate"
                      LabelText="Settlement Date"
                      Value={model.SettlementDate}
                      ValueChanged={this.handleChange}
                      IncludeTime={false}
                      MaxDate={DateHelper.GetDate()}
                    />
                  </GridItem>
                  <br />
                  <br />
                  <br />
                  <br />
                  <GridItem xs={3}>
                    {DownloadButtonControl && (
                      <div>
                        <div>
                          <DownloadButton
                            name={1}
                            title={
                              <>
                                Client Outgoing
                                <br />
                                Transactions
                              </>
                            }
                            onClick={() =>
                              this.ExcelDownload("OrginationPayments")
                            }
                          />
                        </div>
                        <Button
                          size="sm"
                          onClick={() =>
                            this.updateLegacyClients("OrginationPayments")
                          }
                        >
                          Update Clients Data
                        </Button>
                      </div>
                    )}
                  </GridItem>
                  <GridItem xs={3}>
                    {DownloadButtonControl && (
                      <div>
                        <div>
                          <DownloadButton
                            name={1}
                            title={
                              <>
                                Client Outstanding
                                <br />
                                Transactions
                              </>
                            }
                            onClick={() =>
                              this.ExcelDownload("OutstandingPayments")
                            }
                          />
                        </div>
                        <Button
                          size="sm"
                          onClick={() =>
                            this.updateLegacyClients("OutstandingPayments")
                          }
                        >
                          Update Clients Data
                        </Button>
                      </div>
                    )}
                  </GridItem>
                  <GridItem xs={3}>
                    {DownloadButtonControl && (
                      <div>
                        <div>
                          <DownloadButton
                            name={1}
                            title={<>ON US Transactions</>}
                            onClick={() =>
                              this.ExcelDownload("OrginationPaymentsOnUs")
                            }
                          />
                        </div>
                        <Button
                          size="sm"
                          onClick={() =>
                            this.updateLegacyClients("OrginationPayments")
                          }
                        >
                          Update Clients Data
                        </Button>
                      </div>
                    )}
                  </GridItem>
                  <GridItem xs={3}>
                    {DownloadButtonControl && (
                      <div>
                        <div>
                          <DownloadButton
                            name={1}
                            title={
                              <>
                                Client Incoming
                                <br />
                                Transactions
                              </>
                            }
                            onClick={() =>
                              this.ExcelDownload("DestinationPayments")
                            }
                          />
                        </div>
                        <Button
                          size="sm"
                          onClick={() =>
                            this.updateLegacyClients("DestinationPayments")
                          }
                        >
                          Update Clients Data
                        </Button>
                      </div>
                    )}
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={7}>
            <Card style={{ height: "90%" }}>
              <CardHeader>
                <GenericTitle text={"File Information"} />
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={6}>
                    <GenericTextInput
                      Name="ParticipantId"
                      LabelText="ParticipantId"
                      LabelMd={6}
                      Value={summaryModel.ParticipantId}
                      ValueChanged={this.handleChange}
                      Disabled={true}
                    />
                    <GenericTextInput
                      Name="FileType"
                      LabelText="Type"
                      LabelMd={6}
                      Value={summaryModel.FileType}
                      ValueChanged={this.handleChange}
                      Disabled={true}
                    />
                    <GenericTextInput
                      Name="RunDate"
                      LabelText="Run Date"
                      LabelMd={6}
                      Value={Formatter.FormatDateTimeUtc(summaryModel.RunDate)}
                      ValueChanged={this.handleChange}
                      Disabled={true}
                    />
                    <GenericDateInput
                      Name="BeginDate"
                      LabelMd={6}
                      LabelText="Begin Date"
                      Value={
                        summaryModel.BeginDate
                          ? DateHelper.ToDateInputValueAsUtc(summaryModel.BeginDate)
                          : ""
                      }
                      ValueChanged={this.handleChange}
                      IncludeTime={false}
                      Disabled={true}
							 Utc
                    />
                    <GenericDateInput
                      Name="EndDate"
                      LabelText="End Date"
                      Value={
                        summaryModel.EndDate
                          ? DateHelper.ToDateInputValueAsUtc(summaryModel.EndDate)
                          : ""
                      }
                      ValueChanged={this.handleChange}
                      LabelMd={6}
                      IncludeTime={false}
                      Disabled={true}
							 Utc
                    />
                    <GenericNumberInput
                      Name="TotalCreditNumber"
                      LabelText="Total Number of Credit"
                      Value={summaryModel.TotalNumberCredit}
                      Disabled={true}
                      LabelMd={6}
                      DecimalScale={0}
                    />
                    <GenericNumberInput
                      Name="TotalNumberDebit"
                      LabelMd={6}
                      LabelText="Total Number of Debit"
                      Value={summaryModel.TotalNumberDebit}
                      Disabled={true}
                      DecimalScale={0}
                    />
                    <GenericNumberInput
                      Name="TotalNumberCancel"
                      LabelMd={6}
                      LabelText="Total Number of Cancel"
                      Value={summaryModel.TotalNumberCancel}
                      Disabled={true}
                      DecimalScale={0}
                    />
                  </GridItem>
                  <GridItem xs={6}>
                    <GenericTextInput
                      Name="RunNo"
                      LabelMd={6}
                      LabelText="Run No"
                      Value={summaryModel.RunNo}
                      ValueChanged={this.handleChange}
                      Disabled={true}
                    />
                    <GenericTextInput
                      Name="Version"
                      LabelText="Version"
                      LabelMd={6}
                      Value={summaryModel.Version}
                      Disabled={true}
                    />
                    <GenericTextInput
                      Name="FileId"
                      LabelText="File ID"
                      LabelMd={6}
                      Value={summaryModel.FileId}
                      Disabled={true}
                    />
                    <GenericDateInput
                      Name="BeginTimedtamp"
                      LabelText="Begin Timestamp"
                      Value={
                        summaryModel.BeginDate
                          ? DateHelper.ToDateInputValueAsUtc(summaryModel.BeginDate)
                          : ""
                      }
                      DateFormat="HH:mm:ss"
                      LabelMd={6}
                      IncludeTime={false}
                      Disabled={true}
							 Utc
                    />
                    <GenericDateInput
                      Name="EndTimedtamp"
                      LabelText="End Timestamp"
                      Value={
                        summaryModel.EndDate
                          ? DateHelper.ToDateInputValueAsUtc(summaryModel.EndDate)
                          : ""
                      }
                      ValueChanged={this.handleChange}
                      DateFormat="HH:mm:ss"
                      LabelMd={6}
                      IncludeTime={false}
                      Disabled={true}
							 Utc
                    />
                    <GenericNumberInput
                      Name="TotalAmountCredit"
                      LabelText="Total Amount of Credit"
                      Value={summaryModel.TotalAmounCredit}
                      Prefix="$"
                      LabelMd={6}
                      Disabled={true}
                    />
                    <GenericNumberInput
                      Name="TotalAmountDebit"
                      LabelText="Total Amount of Debit"
                      LabelMd={6}
                      Value={summaryModel.TotalAmountDebit}
                      Prefix="$"
                      Disabled={true}
                    />
                    <GenericNumberInput
                      Name="TotalAmountCancel"
                      LabelText="Total Amount of Cancel"
                      LabelMd={6}
                      Value={summaryModel.TotalAmountCancel}
                      Prefix="$"
                      Disabled={true}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
            <br />
          </GridItem>
        </GridContainer>
        <Card className="no-radius">
          <CardBody>
            <CardHeader>
              <GenericTitle text={"Settlement Details"} />
            </CardHeader>
            <GridItem xs={12}>
              <GridItem>
                <GenericGrid
                  Data={this.state.settlementSummaryDetailList}
                  Columns={[
                    {
                      Header: "Financial Ins. Name",
                      accessor: "FinInsName",
                    },
                    {
                      Header: "Participant Id",
                      accessor: "ParticipantId",
                    },
                    {
                      Header: "Is Onus Record",
                      accessor: "IsOnusRecord",
                      Cell: (row) => (
                        <div>{row.value == true ? "Yes" : "No"}</div>
                      ),
                    },
                    {
                      Header: "Total Number of Credit",
                      accessor: "TotalCreditNumber",
                    },
                    {
                      Header: "Credit Balance",
                      accessor: "CreditBalance",
                      type: GridColumnType.Money,
                      ColumnType: GridColumnType.Money,
                    },
                    {
                      Header: "Total Number of Debit",
                      accessor: "TotalDebitNumber",
                    },
                    {
                      Header: "Debit Balance",
                      accessor: "DebitBalance",
                      type: GridColumnType.Money,
                      ColumnType: GridColumnType.Money,
                    },
                    {
                      Header: "Different Balance",
                      accessor: "Difference",
                      type: GridColumnType.Money,
                      ColumnType: GridColumnType.Money,
                    },
                  ]}
                  SelectedIndex={this.state.rowIndex}
                  ProgramCode={ProgramCodes.Bank_Programs_e_Transfer}
                />
              </GridItem>
            </GridItem>
          </CardBody>
        </Card>
      </div>
    );
  }
}

eTransferSettlementFile.propTypes = {
  classes: PropTypes.object,
  handleOperationType: func,
  SelectedRowChange: func,
  showQuestionMessage: func,
  setAppLeftTitle: func,
  setAppCenterTitle: func,
  Disabled: bool,
  menuId: number,
  ApprovalData: array,
  After: func,
};

export default withArtifex(eTransferSettlementFile, {});
