import Dashboard from "layouts/Dashboard.jsx";
import ExternalPage from "layouts/ExternalPage.jsx";
import HeartBeat from "layouts/HeartBeat.jsx";
import Pages from "layouts/Pages.jsx";

//Layouts
var indexRoutes = [
	{ path: "/heartbeat", name: "HeartBeat", component: HeartBeat, private: false },
	{ path: "/out", name: "OutPage", component: ExternalPage, private: false },
	{ path: "/InteracConfirm", name: "InteracConfirm", component: ExternalPage, private: false },
	{ path: "/VisaDirect", name: "VisaDirect", component: ExternalPage, private: false },
	{ path: "/VirtualCard", name: "VirtualCard", component: ExternalPage, private: false },
	{ path: "/acc", name: "Pages", component: Pages, private: false },
	{ path: "/", name: "Home", component: Dashboard, private: true }
];

export default indexRoutes;