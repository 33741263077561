import { DialogContent, DialogTitle } from "@material-ui/core";
import { drawerWidth } from "assets/jss/material-dashboard-pro-react.jsx";
import appStyle from "assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Footer from "components/Footer/Footer.jsx";
import Header from "components/Header/Header.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import { PrivateRoute, Proxy, withRedux } from "core";
import AlertHelper from "core/AlertHelper";
import ArrayHelper from "core/ArrayHelper";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { UserConstants } from "store";
import AppTitleBar from "views/Components/AppTitleBar";
import { GenericDialog, GenericDialogActions, GenericLabel, GenericSelectInput } from "views/Components/Generic";
import IdleComponent from "views/Components/IdleComponent";
import LoadingComponent from "views/Components/LoadingComponent";
import MenuBar from "views/Components/MenuBar";
import { MenuCodes, InteracRedirectURL } from "views/Constants/Constant";
import Portal from "views/Dashboard/Portal";
import RouteHelper from "core/RouteHelper";
import { TaskPool } from "core/TaskPool";
import DashboardHelper from "core/DashboardHelper";
import ResourceHelper from "../core/ResourceHelper";

class Dashboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			flatRoutes: [],
			searchMenuList: [],
			dashboardRoutes: [],
			AppLeftTitle: "",
			AppCenterTitle: "",
			Portal: "",
			headerMenuRightList: [],
			model: {
				ClientUserId: 0
			},
			isChangeUserDialogOpen: false,
			opacity: 1,
			alert: null,
			isLoading: false,
			LogInDisabled: false
		};

		this.userName = "";

		this.mainPanelRef = React.createRef();

		this.setAppLeftTitle = this.setAppLeftTitle.bind(this);
		this.setAppCenterTitle = this.setAppCenterTitle.bind(this);
		this.setPortal = this.setPortal.bind(this);
		this.handleLogout = this.handleLogout.bind(this);
		this.ChangeOpacity = this.ChangeOpacity.bind(this);

		this.ValueChanged = this.ValueChanged.bind(this);
		this.MenuValueChanged = this.MenuValueChanged.bind(this);
		this.LoginCallback = this.LoginCallback.bind(this);
		this.SetUser = this.SetUser.bind(this);
		this.OnChangeUserClick = this.OnChangeUserClick.bind(this);
		this.LoadMenu = this.LoadMenu.bind(this);
		this.LoadMenuProcess = this.LoadMenuProcess.bind(this);
		this.GetMenuRightByParentMenuCode = this.GetMenuRightByParentMenuCode.bind(this);
		this.GetSearchMenuList = this.GetSearchMenuList.bind(this);
		this.GetIsMenuFavorited = this.GetIsMenuFavorited.bind(this);

		this.handleLoadTaskPoolCompleted = this.handleLoadTaskPoolCompleted.bind(this);
		this.handleLoadTaskPoolAppend = this.handleLoadTaskPoolAppend.bind(this);
		this.handleAlertChange = this.handleAlertChange.bind(this);

		this.loadTaskPool = new TaskPool(this.handleLoadTaskPoolAppend, this.handleLoadTaskPoolCompleted);
		this.dashboardHelper = new DashboardHelper(this.handleAlertChange, this.loadTaskPool);

		this.ExecuteApiGet = this.dashboardHelper.ExecuteApiGet;
		this.ExecuteApiPost = this.dashboardHelper.ExecuteApiPost;
		this.ExecuteApiFileUpload = this.dashboardHelper.ExecuteApiFileUpload;
		this.ExecuteApiImageUpload = this.dashboardHelper.ExecuteApiImageUpload;
		this.ExecuteApiFileDownload = this.dashboardHelper.ExecuteApiFileDownload;
		this.ExecuteApiFileDownloadWithGenericResponse = this.dashboardHelper.ExecuteApiFileDownloadWithGenericResponse;
		this.ShowMessage = this.dashboardHelper.ShowMessage;
		this.ShowMessageNode = this.dashboardHelper.ShowMessage;
		this.ShowConfirmMessage = this.dashboardHelper.ShowConfirmMessage;
		this.ShowValidationErrors = this.dashboardHelper.ShowValidationErrors;
		this.ShowMessageAfterOperation = this.dashboardHelper.ShowMessageAfterOperation;
		this.ShowConfirmMessageInDelete = this.dashboardHelper.ShowConfirmMessageInDelete;
	}

	handleAlertChange(alert) {
		this.setState({ alert });
	}

	handleLoadTaskPoolCompleted() {
		this.setState({ isLoading: false });
	}
	handleLoadTaskPoolAppend() {
		this.setState({ isLoading: true });
	}
	componentDidMount() {
		this.LoadMenu();
		this.GetMenuRightByParentMenuCode();

		const Parent = this;
		var userInfo = ClientHelper.GetUser();

		if (userInfo !== null) {
			this.userName = userInfo.UserEmail;
		}

		window.addEventListener("storage", function (e) {
			if (e.key == "user" && document.visibilityState == "hidden") {
				var userInfo = ClientHelper.GetUser();
				if (userInfo !== null) {
					if (userInfo.UserEmail != Parent.userName) {
						Parent.props.history.replace("/acc/login");
					}
				}
			}
		});


		// if user comes from interac approve page 
		var intData = JSON.parse(localStorage.getItem(InteracRedirectURL.LocalStorageKey));
		if (intData != null && intData.IsValidUrl == true) {
			RouteHelper.Push(this.props.history, "/InteracApprovalRequest");
		}
	}

	LoadMenu() {
		var menuData = localStorage.getItem("MenuData");
		var menuDataJson = menuData
			? JSON.parse(menuData)
			: null;

		if (menuDataJson == null) {
			Proxy.GET("/coreapi/v1.0/Menu/GetMenuList",
				responseData => {
					if (!responseData.IsSucceeded)
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
					else {
						flatRoutes = this.GetFlatRoutes(responseData.FlatRoutes);
						dashboardRoutes = this.GetCorrectedMenuList(responseData.MenuList);
						searchMenuList = this.GetSearchMenuList(flatRoutes);

						localStorage.setItem("MenuData", JSON.stringify({ flatRoutes, searchMenuList, dashboardRoutes }));
						this.LoadMenuProcess(flatRoutes, dashboardRoutes, searchMenuList);
					}
				},
				errorMessage => {
					this.ShowMessage("error", "Menu error", errorMessage);
				});
		} else {
			var flatRoutes = menuDataJson.flatRoutes;
			var dashboardRoutes = menuDataJson.dashboardRoutes;
			var searchMenuList = menuDataJson.searchMenuList;

			this.LoadMenuProcess(flatRoutes, dashboardRoutes, searchMenuList);
		}
	}

	LoadMenuProcess(flatRoutes, dashboardRoutes, searchMenuList) {
		flatRoutes.push({ Path: "/Dashboard", component: Portal, Name: "Dashboard" });
		flatRoutes.push({ redirect: true, Path: "/", pathTo: "/Dashboard", Name: "Dashboard" });
		dashboardRoutes.unshift({ Path: "/", Name: "Menu_Home", Id: -1 });

		this.setState({ flatRoutes, searchMenuList, dashboardRoutes });
	}

	async GetMenuRightByParentMenuCode() {
		var result = await this.ExecuteApiPost("/coreapi/v1.0/MenuRight/GetByParentMenuCode", { MenuCode: MenuCodes.Header }, null, null, null, null, null);

		this.setState({ headerMenuRightList: result || [] });
	}

	GetSearchMenuList(flatRoutes) {
		var searchMenuList = [];

		flatRoutes
			.filter(x => x.ShowInMenu)
			.map(x => {
				var menu = {};

				menu.Path = x.Path;
				menu.FullPath = ResourceHelper.CorrectAccountNames(x.FullPath);

				searchMenuList.push(menu);

				return null;
			});

		ArrayHelper.SortArray(searchMenuList, "FullPath");

		return searchMenuList;
	}

	GetCorrectedMenuList(MenuList) {
		var menuList = [];
		MenuList.forEach( menuItem => {
			menuItem.FullPath = ResourceHelper.CorrectAccountNames(menuItem.FullPath)
			menuItem.Views.forEach(menuSubItem => {
				menuSubItem.FullPath = ResourceHelper.CorrectAccountNames(menuSubItem.FullPath)
			})
			menuList.push(menuItem);
		})
		return menuList;
	}
	GetFlatRoutes(FlatRoutes) {
		var flatRoutes = [];
		FlatRoutes.forEach( x => {
			x.FullPath = ResourceHelper.CorrectAccountNames(x.FullPath)
			flatRoutes.push(x);
		})
		return flatRoutes;
	}
	componentDidUpdate(e) {
		var userInfo = ClientHelper.GetUser();
		if (userInfo == null)
			return;

		if (e.history.location.pathname !== e.location.pathname) {
			this.mainPanelRef.current.scrollTop = 0;

			this.setState({ AppLeftTitle: "", AppCenterTitle: "", Portal: "" });
			this.GetIsMenuFavorited(e.history.location.pathname);
		}

		this.userName = userInfo.UserEmail;
	}

	handleLogout() {
		this.setState({ open2: false });

		localStorage.removeItem("user");

		const { dispatch } = this.props;
		dispatch({ type: UserConstants.LOGOUT });
	}

	setAppLeftTitle(val) {
		val = ResourceHelper.CorrectAccountNames(val);
		this.setState({ AppLeftTitle: val });
	}

	setAppCenterTitle(val) {
		val = ResourceHelper.CorrectAccountNames(val);
		this.setState({ AppCenterTitle: val });
	}

	setPortal(val) {
		this.setState({ Portal: val });
	}

	ValueChanged(name, newValue) {
		this.setState(state => {
			var model = state.model || {};
			model[name] = newValue;
			return { model };
		});
	}

	MenuValueChanged(name, value) {
		const { history } = this.props;
		history.push(value);
	}
	
	LoginCallback() {
		const { model } = this.state;
		this.setState({ LogInDisabled: true });
		Proxy.POST(
			"/coreapi/v1.0/Authentication/ShadowLogin",
			{
				ClientUserId: model.ClientUserId
			},
			responseData => {
				this.setState({ isChangeUserDialogOpen: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				const user = {
					Email: this.state.model.Email, SessionId: responseData.DCBankAccessToken, ForceTwoFactorAuth: responseData.ForceTwoFactorAuth,
					SessionStatus: responseData.SessionStatus, MustChangePwd: responseData.MustChangePwd, CanBeClient: responseData.CanBeClient
				};

				localStorage.removeItem("MenuData");
				localStorage.setItem("user", JSON.stringify(user));

				const { dispatch } = this.props;
				dispatch({ type: UserConstants.LOGIN_SUCCESS, user });

				this.SetUser(responseData);
				this.setState({ LogInDisabled: false });
			},
			error => {
				this.setState({ LogInDisabled: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	SetUser(response) {
		var promise =
			Proxy.POST(
				"/coreapi/v1.0/User/GetUserByEmailWithClientInfo",
				{ Email: response.UserEmail },
				responseData => {
					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}

					var user = ClientHelper.GetUser();

					var userInfo = responseData.User;
					userInfo.SessionId = user.SessionId;
					userInfo.ShadowLogin = true;

					localStorage.setItem("user", JSON.stringify(userInfo));

					const { dispatch } = this.props;
					dispatch({ type: UserConstants.LOGIN_SUCCESS, user });

					window.open("/", "_self");
				},
				error => {
					this.ShowMessage("error", "Login failed", error);
				}
			);
		this.loadTaskPool.AppendTask(promise);
	}

	async GetIsMenuFavorited(menuUrl) {
		var result = await this.ExecuteApiPost("/coreapi/v1.0/UserFavoriteMenuMap/GetByMenuUrl", { MenuUrl: menuUrl }, null, null, null, null, null);

		this.setState({ isFavorited: result || false });
	}

	ChangeOpacity(opacity) {
		this.setState({ opacity });
	}

	OnChangeUserClick() {
		this.setState({ isChangeUserDialogOpen: true });
	}
	render() {
		const { classes, ...rest } = this.props;
		const { history } = this.props;
		const { isChangeUserDialogOpen, model, opacity, isLoading, LogInDisabled } = this.state;
		const isLeftMenu = window.MenuBar === "left";
		const mainPanel = classes.mainPanel;

		return (
			<div className={classes.wrapper} style={{ zIndex: "auto" }} >
				<LoadingComponent Show={isLoading} />
				<IdleComponent
					ExecuteApiGet={this.ExecuteApiGet} />
				<GenericDialog open={isChangeUserDialogOpen} maxWidth="sm" fullWidth>
					<DialogTitle style={{ background: "white", marginBottom: -30, marginTop: 0 }}  >
						<GenericLabel FontSize="16px" TextColor="black" Text="Change User" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ height: "auto", marginTop: 8 }}>
						<div style={{ position: "fixed", top: "auto", left: "auto", width: "555px", zIndex: 1, margin: "-5px -1px" }}>
							<GenericSelectInput
								Name="ClientUserId"
								LabelText="Client User"
								Method="GET"
								Url="/coreapi/v1.0/User/GetAllUserAuthorization"
								DataRoot="Item"
								KeyValueMember="ClientUserId"
								RenderItem={d => `${d.ClientUser.UserEmail}`}
								Value={model.ClientUserId}
								ValueChanged={this.ValueChanged} />
						</div>
					</DialogContent>
					<GenericDialogActions style={{ marginTop: 5 }}>
						<Button size="sm" onClick={() => this.LoginCallback()} disabled={LogInDisabled || model.ClientUserId === 0}>Log In</Button>
						<Button size="sm" onClick={() => this.setState({ isChangeUserDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>

				{this.state.alert}
				<div className={mainPanel} style={{ marginLeft: "auto", marginRight: "auto", float: "none" }} ref={this.mainPanelRef}>
					<div
						id={"dashboardDiv"}
						style={{
							zIndex: 5,
							position: "fixed",
							width: `calc(100% - ${drawerWidth + 20}px)`,
							background: "#fff"
						}}>
						<Header
							onChangeUserClick={this.OnChangeUserClick}
							logout={this.handleLogout}
							headerMenuRightList={this.state.headerMenuRightList}
							menuList={this.state.searchMenuList}
							MenuValueChanged={this.MenuValueChanged}
							{...rest} />
						{isLeftMenu &&
							<Sidebar
								history={history}
								routes={this.state.dashboardRoutes}
								color="primary"
								bgColor="black"
								{...rest} />
						}
						{!isLeftMenu &&
							<div style={{ paddingTop: "30px", paddingRight: "30px" }} >
								<MenuBar history={history} routes={this.state.dashboardRoutes} />
							</div>
						}
						<div style={{ padding: isLeftMenu ? "55px 40px 0px" : "1px 40px 0px 40px", marginRight: "-3px", marginLeft: isLeftMenu ? "30vh" : "1px" }}>
							<AppTitleBar
								key={window.location.pathname}
								history={history}
								LeftTitle={this.state.AppLeftTitle}
								CenterTitle={this.state.AppCenterTitle}
								ExecuteApiPost={this.ExecuteApiPost}
								ExecuteApiFileDownloadWithGenericResponse={this.ExecuteApiFileDownloadWithGenericResponse}
								Portal={this.state.Portal}
								ChangeOpacity={this.ChangeOpacity}
								GetIsMenuFavorited={this.GetIsMenuFavorited}
								IsFavorited={this.state.isFavorited}
								showMessage={this.ShowMessage} />
						</div>
					</div>

					<div style={{ marginTop: isLeftMenu ? 86 : 140, marginLeft: isLeftMenu ? "30vh" : 0, opacity: opacity, minWidth: "1600px", minHeight: "800px" }} className={classes.content} >
						<div className={classes.container} style={{ marginLeft: "0px", marginRight: "0px" }}>
							<Switch>
								{this.state.flatRoutes.map((prop, key) => {
									if (prop.redirect)
										return (
											<Redirect
												key={"LayoutRedirect_" + key}
												from={prop.Path} to={prop.pathTo} />
										);
									return (
										<PrivateRoute
											key={key}
											setAppLeftTitle={this.setAppLeftTitle} setAppCenterTitle={this.setAppCenterTitle}
											setPortal={this.setPortal}
											screenId={prop.ScreenId} menuId={prop.Id} route={prop.Route} path={prop.Path} exact={true}
											MenuCode={prop.Code}
											UserContext={this.props.UserContext} component={prop.component}
											ExecuteApiGet={this.ExecuteApiGet}
											ExecuteApiPost={this.ExecuteApiPost}
											ExecuteApiFileUpload={this.ExecuteApiFileUpload}
											ExecuteApiFileDownload={this.ExecuteApiFileDownload}
											ExecuteApiFileDownloadWithGenericResponse={this.ExecuteApiFileDownloadWithGenericResponse}
											showMessage={this.ShowMessage}
											showAlert={this.handleAlertChange}
											showMessageNode={this.ShowMessage}
											showConfirmMessage={this.ShowConfirmMessage}
											showValidationErrors={this.ShowValidationErrors}
											showMessageAfterOperation={this.ShowMessageAfterOperation}
											showConfirmMessageInDelete={this.ShowConfirmMessageInDelete}
											taskPool={this.loadTaskPool} />
									);
								})}
							</Switch>
						</div>
					</div>
					<Footer fluid />
				</div>
			</div >
		);
	}
}

Dashboard.propTypes = {
	classes: PropTypes.object.isRequired,
	dispatch: PropTypes.func,
	history: PropTypes.object
};

export default withRedux(Dashboard, appStyle);