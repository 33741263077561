import { DialogContent, Slide, withStyles, CardMedia } from "@material-ui/core";
import { blackColor } from "assets/jss/material-dashboard-pro-react.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import RouteHelper from "core/RouteHelper";
import PropTypes from "prop-types";
import React from "react";
import Tooltip from "react-tooltip-lite";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDateInput, GenericDialog, GenericDialogActions, GenericEmailInput, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTextInput, GenericLabel, GenericRadioInput } from "views/Components/Generic";
import ParameterComponent from "views/Components/ParameterComponent";
import { CanadaPostIdVerificationStatus, GridColumnType, IdentityType } from "views/Constants/Constant";
import { MaxLength128 } from "views/Constants/Constant.MaxLength";
import { DialogTitle } from "@material-ui/core";
import Select from "react-select";
import { Proxy } from "core";
import LoadingComponent from "views/Components/LoadingComponent";
import { DetailIcon } from "core/Icons";
import GridButton from "views/Components/GridButton";
import externalContainerStyle from "assets/jss/material-dashboard-pro-react/layouts/externalContainerStyle.jsx";


function Transition(props) {
	return <Slide direction="up" {...props} />;
}

class CanadaPostVerification extends React.Component {
	
	constructor(props) {
		super(props);

		this.columns = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip="Detail"
							Icon={DetailIcon}
							Disabled={ row.original.IsHaveResponse == false }
							OnClick={() => this.showDetail(row.original)} 
						/>
					</div>
				),
				sortable: false,
				filterable: false,
				width: 100
			},
			{
				Header: "Created Date",
				accessor: "CreatedDate",
				type: GridColumnType.DateUtc
			},
			{
				Header: "First Name",
				accessor: "FirstName"
			},
			{
				Header: "Last Name",
				accessor: "LastName"
			},
			{
				Header: "Date Of Birth",
				accessor: "DateOfBirth",
				type: GridColumnType.DateUtc
			},
			{
				Header: "Credential",
				accessor: "CredentialDescription"
			},
			{
				Header: "Proof of Residence",
				accessor: "ProofOfResidenceDescription"
			},
			{
				Header: "Address Line",
				accessor: "AddressLine"
			},
			{
				Header: "City",
				accessor: "City"
			},
			{
				Header: "Province",
				accessor: "Province"
			},
			{
				Header: "Postal Code",
				accessor: "PostalCode"
			},
			{
				Header: "Verification Status",
				accessor: "VerificationStatusDescription"
			}
		];
		this.getappCenterTitle = "Empty";
		this.getappLeftTitle = "Empty";
		this.zIndex10 = { zIndex: 10 };
		this.isClient = ClientHelper.IsClient();
		this.parameterUniqueClientId = {
			UniqueClientId: this.isClient ? ClientHelper.GetClientId() : undefined,
		};

		if (!this.isClient) {
			this.columns.splice(1, 0,
				{
					Header: "Client",
					accessor: "ClientName"
				}
			);
		}

		this.state = {
			model: {},
			isSendEmailDialogOpen: false,
			activeClient: [],
			responseDetail: {},
			isDetailDialogOpen: false
		};
		this.cpverTable = React.createRef();
	}

	componentDidMount = () => {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;
		this.LoadCanadaPost();
		if (setAppCenterTitle) {
			setAppCenterTitle("AML & KYC");
			 this.getappCenterTitle = "AML & KYC";
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("Canada Post Verification");
			this.getappLeftTitle = "Canada Post Verification";
		}

		var canadaPostVerificationFromAmlKycAlert = RouteHelper.ReadAndClear("CanadaPostVerificationFromAmlKycAlert");

		if (canadaPostVerificationFromAmlKycAlert != null) {
			this.OpenFromOtherScreen(canadaPostVerificationFromAmlKycAlert.CanadaPostVerificationId);
		}

		var canadaPostVerificationFromStatusControlLog = RouteHelper.ReadAndClear("CanadaPostVerificationFromStatusControlLog");

		if (canadaPostVerificationFromStatusControlLog != null) {
			this.OpenFromOtherScreen(canadaPostVerificationFromStatusControlLog.CanadaPostVerificationId);
		}
	}

	LoadCanadaPost = async () => {

		var parent = this;
		var result = await this.props.ExecuteApiPost("/coreapi/v1.0/Parameter/Search", {
			ParameterCode: "CanadaPostApiKey",
			ParameterValue: "CP"
		});
		if (result?.length > 0) {
			var apikey = result[0].ParameterDesc;

			var url = window.CanadaPostAddressCompleteUrl;
			var path = url + apikey;
			var CanadaPostRequest=this.SubmitCanadaPostRequest;
			var pca;
			const script = document.createElement("script");
			script.src = path;
			script.async = false;
			document.head.appendChild(script);
			script.onload = function () {
				pca = window.pca;
				if (pca != null) {
					var fields =
						[
							{ element: "street-address", field: "Line1", mode: pca.fieldMode.SEARCH },
							{ element: "street-address2", field: "Line2", mode: pca.fieldMode.POPULATE },
							{ element: "postcode", field: "PostalCode", mode: pca.fieldMode.SEARCH },
						],
						options = {
							key: apikey
						},
						control = new pca.Address(fields, options);
						CanadaPostRequest();
					control.listen("populate", function (address) {
						parent.setState(state => {
							state.model.AddressLine = address.Line1 + " " + address.Line2;
							state.model.PostalCode = address.PostalCode;
							state.model.Province = address.ProvinceCode;
							state.model.City = address.City;
							return state;
						});
					});
				}
			};

		}

	}
	SubmitCanadaPostRequest = async () => {
		const model = { ...this.state.model };
		var requestdata = { 

			UniqueClientId: null,
			MenuCode:this.props.MenuCode,
			AppCenterTitle:this.getappCenterTitle,
			AppLeftTitle:this.getappLeftTitle
		};
		Proxy.POST(
			"/bankapi/v1.0/Reporting/CanadaPostRequestReportInsert",
			requestdata,
			responseData => {
				if (responseData.Item != null ) {
					 
				}
			},
 		);
		// var result = await this.props.ExecuteApiPost("/bankapi/v1.0/Reporting/CanadaPostRequestReportInsert", requestdata);
	}
	OpenFromOtherScreen = async (canadaPostVerificationId) => {
		await this.Search();
		await this.GetById(canadaPostVerificationId);

		const { list } = this.state;
		var selected = list.map(function (e) { return e.Id; }).indexOf(canadaPostVerificationId);

		this.RowSelected(selected);
	}

	ValueChanged = (name, value, data) => {
		this.setState(state => {
			var model = state.model;
			model[name] = value;

			if (name == "CredentialId") {
				model.IdentityTypeParameterValue2 = data && data.ParameterValue2;
				model.ProofOfResidenceCredentialId = null;
			}
			else if (name == "EmailAddress") {
				model["EmailAddressIsValid"] = data.IsValid;
			}
			else if (this.isClient && name == "UniqueClientId") {
				state.activeClient = [data];
			}
			return { model };
		});
	}

	RowSelected = (index) => {
		const { list } = this.state;
		var temp = list[index];

		temp.IdentityTypeParameterValue2 = temp && temp.CredentialParameterValue2;

		this.setState({ model: temp, selected: index });
	}

	SendEmaiDialogOpen = () => {
		this.setState({ isSendEmailDialogOpen: true });
	}

	SendEmaiDialogClose = () => {
		var { model } = this.state;
		model.EmailAddress = undefined;

		this.setState({ model, isSendEmailDialogOpen: false });
	}

	GetById = async (canadaPostVerificationId) => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/amlkycapi/v1.0/CanadaPostVerification/GetById", { Id: canadaPostVerificationId });
		var model = result || {};
		model.DateOfBirth = Formatter.FormatDate(model.DateOfBirth);

		this.setState({ model });
	}

	Search = async () => {
		const { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/amlkycapi/v1.0/CanadaPostVerification/Search", model);

		this.setState({ list: result || [] });
	}

	SendEmail = async () => {
		var { model } = this.state;
		const { MenuCode, showMessage } = this.props;

		if (model.EmailAddressIsValid != true) {
			showMessage("warning", "Warning", "E-Mail Address is not valid.");

			return;
		}

		model.MenuCode = MenuCode;

		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/amlkycapi/v1.0/CanadaPostVerification/SendEmail", model);

		if (result != null && result == true) {
			showMessage("info", "Information", "Email sent successfully.");
			this.SendEmaiDialogClose();
		}
	}

	Clear = () => {
		this.setState({ model: {}, list: [], selected: null });
	}

	Validate = () => {
		var { model } = this.state;
		const { showValidationErrors } = this.props;
		var errors = [];

		if (!model.FirstName) {
			errors.push("First name cannot be null");
		}
		if (!model.LastName) {
			errors.push("Last name cannot be null");
		}

		var age = DateHelper.GetDate().diff(model.DateOfBirth, "years");

		if (!model.DateOfBirth) {
			errors.push("Date of birth cannot be null");
		}
		if (!model.AddressLine) {
			errors.push("Address line cannot be null");
		}
		if (!model.Province) {
			errors.push("Province cannot be null");
		}
		if (!model.City) {
			errors.push("City cannot be null");
		}
		if (!model.PostalCode) {
			errors.push("Postal code cannot be null");
		}
		if (!model.CredentialId) {
			errors.push("Credential cannot be null");
		}
		else {
			if (model.IdentityTypeParameterValue2 == IdentityType.OnePoR && !model.ProofOfResidenceCredentialId) {
				errors.push("Proof of residence cannot be null");
			}
		}

		model["IsFirstName"] = !model.FirstName;
		model["IsLastName"] = !model.LastName;
		model["IsDateOfBirth"] = !model.DateOfBirth;
		model["IsAddressLine"] = !model.AddressLine;
		model["IsProvince"] = !model.Province;
		model["IsCity"] = !model.City;
		model["IsPostalCode"] = !model.PostalCode;
		model["IsCredentialId"] = !model.CredentialId;
		model["IsProofOfResidenceCredentialId"] = model.IdentityTypeParameterValue2 == IdentityType.OnePoR && !model.ProofOfResidenceCredentialId;

		if (errors.length > 0) {
			showValidationErrors(errors);

			return false;
		}

		return true;
	}
	RenderItemUniqueClient = (x) => {
		var result = `${x.UniqueClientId} - ${x.Name}`;

		return result;
	}
	ValueChangedForReportType = (name, value) => {
		this.setState({ [name]: value });
	}
	ExportClick = () => {
		var cpverColumns = [];
		this.cpverTable.current.props.Columns.forEach(c => {
			if (c.show == undefined || c.show) {
				cpverColumns.push({ value: c.accessor, title: c.Header, columntype: c.ColumnType, type: c.type });
			}
		}, this);

		this.setState({ isDownloadDialogOpen: true, GridKeyValueList: cpverColumns, ExportSelectedColumns: cpverColumns });
	}
	ExcelDownload = () => {
		if (!this.state.ExportReportType) {
			this.props.showMessage("warning", "Export Report Type not selected", "Select Export Report Type to continue");
			return;
		}

		if (this.state.ExportReportType == "PDF" && this.state.ExportSelectedColumns.length > 15) {
			this.props.showMessage("warning", "PDF document cannot exceed 15 columns", "PDF document cannot exceed 15 columns");
			return;
		}

		this.setState({ isDownloadDialogOpen: false });


		const temp = { ...this.state.model };


		var jsonColList = [];

		var cpverColumns = {};
		this.state.ExportSelectedColumns.map(c => {
			cpverColumns[c.value] = { value: c.value, title: c.title, columntype: c.columntype, type: c.type };
		});
		jsonColList.push(cpverColumns);

		var mytitle = "Canada Post Verification";

		temp.jsonFile = {
			colmns: jsonColList,
			format: this.state.ExportReportType,
			title: mytitle
		};

		var request = {
			UniqueClientId: temp.UniqueClientId,
			FirstName: temp.FirstName,
			LastName : temp.LastName,
			DateOfBirth:temp.DateOfBirth,
			CredentialId:temp.CredentialId,
			AddressLine:temp.AddressLine,
			City:temp.City,
			Province:temp.Province,
			PostalCode:temp.PostalCode,
			jsonFile: temp.jsonFile
		};
		this.setState({ isLoading: true });
		Proxy.DownloadGeneratedFile(
			"/amlkycapi/v1.0/CanadaPostVerification/ExportDownload",
			request,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", errorMessage);
			},
			this.props.showMessage
		);
	}

	showLoading = () => { this.setState({  isLoading: true }); }
	hideLoading = () => { this.setState({  isLoading: false }); }

	showDetail = async (result) =>
	{
		this.setState({ responseDetail: result || [] , isDetailDialogOpen: true });
	}

	DetailDialogClose = () => {
		this.setState({ isDetailDialogOpen: false });
	}

	render() {
		const { model, list, selected, isSendEmailDialogOpen, activeClient, isLoading, isDetailDialogOpen, responseDetail } = this.state;
		const { MenuCode, Disabled, ExecuteApiPost, ExecuteApiFileDownloadWithGenericResponse, showConfirmMessageInDelete, classes } = this.props;

		return (
			<div>
				<LoadingComponent Show={isLoading} />
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},
						{
							Code: "Submit",
							Disabled: Disabled || selected != null,
							OnClick: () => ExecuteApiPost("/amlkycapi/v1.0/CanadaPostVerification/Insert", model, null, null, this.Validate, [this.Clear, this.Search], true)
						},
						{
							Code: "Delete",
							Disabled: Disabled || selected == null,
							OnClick: () => showConfirmMessageInDelete(() => ExecuteApiPost("/amlkycapi/v1.0/CanadaPostVerification/Delete", { Id: model.Id }, null, null, null, [this.Clear, this.Search], true))
						},
						{
							Code: "Print",
							Disabled: Disabled || model.VerificationStatusId == null || (model.VerificationStatusId != null && model.VerificationStatusParameterValue != CanadaPostIdVerificationStatus.None),
							OnClick: () => ExecuteApiFileDownloadWithGenericResponse("/amlkycapi/v1.0/CanadaPostVerification/Print", { Id: model.Id, MenuCode: MenuCode }, model.BarcodeData, "pdf")
						},
						{
							Code: "SendEmail",
							Disabled: Disabled || model.VerificationStatusId == null || (model.VerificationStatusId != null && model.VerificationStatusParameterValue != CanadaPostIdVerificationStatus.None),
							OnClick: this.SendEmaiDialogOpen
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						},
						{ Code: "Export",	OnClick: this.ExportClick,Disabled: Disabled }
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GenericDialog open={this.state.isDownloadDialogOpen} fullWidth={true}>
					<DialogTitle>
						<GenericLabel Text="Select Report Type" FontSize="20px" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ height: 300 }}>
						<GenericRadioInput
							Name="ExportReportType"
							LabelText="Report Type"
							IsStatic={true}
							StaticData={[{ Value: "PDF", Text: "PDF" }, { Value: "CSV", Text: "CSV" }, { Value: "EXCEL", Text: "EXCEL" }]}
							KeyValueMember="Value"
							TextValueMember="Text"
							Value={this.state.ExportReportType}
							ValueChanged={this.ValueChangedForReportType}
						/>
						<GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
						<GridItem style={{ marginTop: 20 }}>
							<Select
								value={this.state.ExportSelectedColumns}
								onChange={value => this.setState({ ExportSelectedColumns: value })}
								isMulti
								name="columns"
								getOptionLabel={d => d.title}
								options={this.state.GridKeyValueList}
								className="basic-multi-select"
								classNamePrefix="select"
							/>
						</GridItem>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.ExcelDownload}>Download</Button>
						<Button size="sm" onClick={() => this.setState({ isDownloadDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>
				<GenericDialog open={isSendEmailDialogOpen} maxWidth="sm" fullWidth onBackdropClick={this.SendEmaiDialogClose} TransitionComponent={Transition}>
					<DialogContent>
						<GridContainer>
							<GenericEmailInput
								Name="EmailAddress"
								LabelText="E-Mail Address"
								inputProps={MaxLength128}
								Value={model.EmailAddress}
								ValueChanged={this.ValueChanged} />
						</GridContainer>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.SendEmaiDialogClose}>Cancel</Button>
						<Button size="sm" onClick={this.SendEmail}>Send</Button>
					</GenericDialogActions>
				</GenericDialog>
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Canada Post Verification">
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												IsStatic={activeClient?.length}
												StaticData={activeClient?.length && activeClient}
												Name="UniqueClientId"
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={this.parameterUniqueClientId}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={this.RenderItemUniqueClient}
												Value={activeClient?.length > 0 ? activeClient[0].Id : model.UniqueClientId}
												ValueChanged={this.ValueChanged}
												Disabled={this.isClient || model.Id}
												DefaultIndex={this.isClient ? 0 : -1} />
											<GenericTextInput
												Required
												IsInvalid={model.IsFirstName}
												Disabled={selected != null}
												Name="FirstName"
												LabelText="First Name"
												Value={model.FirstName}
												ValueChanged={this.ValueChanged} />
											<GenericTextInput
												Required
												IsInvalid={model.IsLastName}
												Disabled={selected != null}
												Name="LastName"
												LabelText="Last Name"
												Value={model.LastName}
												ValueChanged={this.ValueChanged} />
											<GenericDateInput
												Required
												IsInvalid={model.IsDateOfBirth}
												Disabled={selected != null}
												Name="DateOfBirth"
												LabelText="Date Of Birth"
												IncludeTime={false}
												Value={model.DateOfBirth}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={4}>
											<GenericTextInput
												Required
												IsInvalid={model.IsAddressLine}
												Disabled={selected != null}
												Name="AddressLine"
												Id="street-address"
												LabelText="Address Line"
												Value={model.AddressLine}
												ValueChanged={this.ValueChanged} />
											<Tooltip content="Please Use the 2 Letter abbreviations" styles={this.zIndex10} background={blackColor} color="white">
												<GenericTextInput
													Required
													IsInvalid={model.IsProvince}
													Disabled={selected != null}
													Name="Province"
													LabelText="Province"
													Value={model.Province}
													ValueChanged={this.ValueChanged} />
											</Tooltip>
											<GenericTextInput
												Required
												IsInvalid={model.IsCity}
												Disabled={selected != null}
												Name="City"
												LabelText="City"
												Value={model.City}
												ValueChanged={this.ValueChanged} />
											<GenericTextInput
												Required
												IsInvalid={model.IsPostalCode}
												Disabled={selected != null}
												Name="PostalCode"
												Id="postcode"
												LabelText="Postal Code"
												Value={model.PostalCode}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={4}>
											<ParameterComponent
												Required
												IsInvalid={model.IsCredentialId}
												Disabled={selected != null}
												Name="CredentialId"
												LabelText="Credential"
												ParameterCode="IdentityType"
												Value={model.CredentialId}
												ValueChanged={this.ValueChanged} />
											<ParameterComponent
												Disabled={selected != null || model.IdentityTypeParameterValue2 != IdentityType.OnePoR}
												Required={selected == null && model.IdentityTypeParameterValue2 == IdentityType.OnePoR}
												IsInvalid={model.IsProofOfResidenceCredentialId}
												Name="ProofOfResidenceCredentialId"
												LabelText="Proof of Residence"
												ParameterCode="ProofOfResidence"
												Value={model.ProofOfResidenceCredentialId}
												ValueChanged={this.ValueChanged} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columns}
													SelectedIndex={selected}
													RowSelected={this.RowSelected}
													IsSorted={false}
													PageSize={10}
													ShowPagination={true}
													ref={this.cpverTable} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

				<GenericDialog open={isDetailDialogOpen} onBackdropClick={this.DetailDialogClose} TransitionComponent={Transition}>
					<DialogTitle>
						<GenericLabel
							Bold
							Text="Canada Post Verification Detail"
							FontSize="16px"
							TextColor="black" />
					</DialogTitle>
					<DialogContent>
					<GridContainer spacing={16}>
						<GridItem xs={12}>
							<GenericTextInput
								Name="ResponseFirstName"
								LabelText="First Name"
								Value={responseDetail?.ResponseFirstName}
								Disabled={true}
							/>
						</GridItem>
						<GridItem xs={12}>
							<GenericTextInput
								Name="ResponseLastName"
								LabelText="Last Name"
								Value={responseDetail?.ResponseLastName}
								Disabled={true}
							/>
						</GridItem>
						<GridItem xs={12}>
							<GenericTextInput
								Name="DeliveryType"
								LabelText="Delivery Type"
								Value={responseDetail?.DeliveryType}
								Disabled={true}
							/>
						</GridItem>
						<GridItem xs={12}>
							<GenericTextInput
								Name="ResponseAddressLine"
								LabelText="Address Line"
								Value={responseDetail?.ResponseAddressLine}
								Disabled={true}
							/>
						</GridItem>
						<GridItem xs={12}>
							<GenericTextInput
								Name="ResponseCity"
								LabelText="City"
								Value={responseDetail?.ResponseCity}
								Disabled={true}
							/>
						</GridItem>
						<GridItem xs={12}>
							<GenericTextInput
								Name="ResponseProvince"
								LabelText="Province"
								Value={responseDetail?.ResponseProvince}
								Disabled={true}
							/>
						</GridItem>
						<GridItem xs={12}>
							<GenericTextInput
								Name="ResponsePostalCode"
								LabelText="Postal Code"
								Value={responseDetail?.ResponsePostalCode}
								Disabled={true}
							/>
						</GridItem>
						<GridItem xs={12}>
							<GenericTextInput
								Name="CustomerRequestId"
								LabelText="Request Id"
								Value={responseDetail?.CustomerRequestId}
								Disabled={true}
							/>
						</GridItem>
						<GridItem xs={12}>
							<GenericTextInput
								Name="DateOfCheck"
								LabelText="Date of Check"
								Value={responseDetail?.DateOfCheck}
								Disabled={true}
							/>
						</GridItem>
						<GridItem xs={12}>
							<GenericTextInput
								Name="DocumentType"
								LabelText="Document Type"
								Value={responseDetail?.DocumentType}
								Disabled={true}
							/>
						</GridItem>
						<GridItem xs={12}>
							<GenericTextInput
								Name="DocumentNumber"
								LabelText="Document Number"
								Value={responseDetail?.DocumentNumber}
								Disabled={true}
							/>
						</GridItem>
						<GridItem xs={12}>
							<GenericTextInput
								Name="PlaceOfIssue"
								LabelText="Place of Issue"
								Value={responseDetail?.PlaceOfIssue}
								Disabled={true}
							/>
						</GridItem>
						<GridItem xs={12}>
							<GenericTextInput
								Name="ProofId"
								LabelText="CP Proof Id"
								Value={responseDetail?.ProofId}
								Disabled={true}
							/>
						</GridItem>
						<GridItem xs={12}>
							<GenericTextInput
								Name="StatusCode"
								LabelText="Status Code"
								Value={responseDetail?.StatusCode}
								Disabled={true}
							/>
						</GridItem>
						<GridItem xs={12}>
							<GenericTextInput
								Name="StatusReason"
								LabelText="Status Reason"
								Value={responseDetail?.StatusReason}
								Disabled={true}
							/>
						</GridItem>
						<GridItem xs={12}>
							<GenericTextInput
								Name="StatusReason"
								LabelText="Status Reason"
								Value={responseDetail?.StatusReason}
								Disabled={true}
							/>
						</GridItem>
						<GridItem xs={12}>
							<GenericTextInput
								Name="TransactionSource"
								LabelText="Transaction Source"
								Value={responseDetail?.TransactionSource}
								Disabled={true}
							/>
						</GridItem>
						<GridItem xs={12}>
							<GenericTextInput
								Name="ExpiryDate"
								LabelText="Expiry Date"
								Value={responseDetail?.ExpiryDate}
								Disabled={true}
							/>
						</GridItem>
						<GridItem xs={12}>
							<GenericTextInput
								Name="ApplicantId"
								LabelText="Applicant Id"
								Value={responseDetail?.ApplicantId}
								Disabled={true}
							/>
						</GridItem>
						<GridItem xs={12}>
							<GenericTextInput
								Name="CustomFieldName"
								LabelText="Custom Field Name"
								Value={responseDetail?.CustomFieldName}
								Disabled={true}
							/>
						</GridItem>
						<GridItem xs={12}>
							<GenericTextInput
								Name="CustomFieldValue"
								LabelText="Custom Field Value"
								Value={responseDetail?.CustomFieldValue}
								Disabled={true}
							/>
						</GridItem>
						<GridItem xs={12}>
							<CardMedia
								component='img' 
								src={`data:image/png;base64, ${responseDetail.Base64Signature}`}
							/>
						</GridItem>
          		</GridContainer>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.DetailDialogClose}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>
			</div >
		);
	}
}

CanadaPostVerification.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	ExecuteApiFileDownloadWithGenericResponse: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default withStyles(externalContainerStyle)(CanadaPostVerification) ;