import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { DdtFindType, GridColumnType } from "views/Constants/Constant";

class AssignDDTAccountComponent extends Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			ClientCustomerId: props.ClientCustomerId
		};

		this.state = {
			model: this.initialModel,
			vModel: {},
			list: [],
			alert: "",
			isLoading: false,
			isEdit: false,
			index: -1
		};

		this.Columns = [
			{
				Header: "Unique Client Id",
				accessor: "UniqueClientId"
			},
			{
				Header: "Client Name",
				accessor: "AccountOwner"
			},
			{
				Header: "Customer Number",
				accessor: "ClientCustomer.CustomerNumber"
			},
			{
				Header: "Transit Number",
				accessor: "TransitNumber"
			},
			{
				Header: "Wallet Number",
				accessor: "DDTNumber"
			},
			{
				Header: "Issued Date",
				accessor: "IssuedDate",
				type: GridColumnType.Date
			},
			{
				Header: "Expire Date",
				accessor: "ExpireDate",
				type: GridColumnType.Date
			},
			{
				Header: "Status",
				accessor: "Status"
			},
			{
				Header: "Balance",
				accessor: "Balance",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Name",
				accessor: "Name"
			},
			{
				Header: "E-Mail",
				accessor: "Email"
			}
		];
	}

	componentDidMount() {
		if (this.props.ClientCustomerId > 0)
			this.HandleGetList();
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}

	HandleClear = () => {
		this.setState({ model: { ...this.initialModel }, isEdit: false, index: -1 });
	}

	HandleGetList = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/DDT/DdtSearch",
			{
				IsClientCustomerIdAssigned: true,
				ClientCustomerId: this.props.ClientCustomerId
			},
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ list: responseData.Item.DDTList });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	HandleAssign = () => {
		if (!this.Validate()) {
			return;
		}

		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/Prepaid/AssignClientCustomerIdForDdtAccount",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ model: { ...this.initialModel }, index: -1 });
				this.HandleClear();
				this.HandleGetList();
				this.props.showMessage("success", "Success", "Operation is successfully!");
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	RowSelected = (index) => {
		let { model, list } = this.state;
		model = list[index];
		this.setState({ isEdit: true, model, index });
	}

	RenderItemAccount = (d) => {
		this.renderItemAccount = `${d.AccountNumber} - ${d.AccountName}`;
		return this.renderItemAccount;
	}

	Validate = () => {
		const { model, vModel } = this.state;
		var messages = [];

		vModel.DdtFindType = model.DdtFindType == null;
		if (vModel.DdtFindType) messages.push("DDT Assign Type must be select");

		vModel.CustomerNumber = model.DdtFindType === 1 && model.CustomerNumber == null;
		if (vModel.CustomerNumber) messages.push("Customer Number must be select");

		vModel.AccountId = model.DdtFindType === 2 && model.AccountId == null;
		if (vModel.AccountId) messages.push("Wallet must be select");

		vModel.AccountNumber = model.DdtFindType === 3 && model.AccountNumber == null;
		if (vModel.AccountNumber) messages.push("Wallet Number must be select");

		if (messages.length > 0) {
			this.props.showMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.setState({ vModel });
		return true;
	}

	render() {
		const { alert, isLoading, model, vModel, list } = this.state;
		const { ReadOnly, Disabled, isPanelActive } = this.props;

		return (
			<>

				{alert}

				<LoadingComponent Show={isLoading} />

				<GenericExpansionPanel Title="Assign DDT Number" IsActive={isPanelActive} >
					<GridContainer>
						<GridItem xs={3}>
							<GenericSelectInput
								Name="DdtFindType"
								LabelText="DDT Assign Type"
								IsStatic={true}
								StaticData={DdtFindType}
								DataRoot="Item"
								KeyValueMember="Id"
								TextValueMember="Text"
								Value={model.DdtFindType}
								ValueChanged={this.HandleChange}
								Disabled={Disabled || ReadOnly}
								Required
								IsInvalid={vModel.DdtFindType} />
						</GridItem>
						<GridItem xs={3}>
							{model.DdtFindType === 1 &&
								<GenericNumberInput
									Name="CustomerNumber"
									LabelText="Customer Number"
									Value={model.CustomerNumber}
									ValueChanged={this.HandleChange}
									MaxLength={11}
									NoFormatting={true}
									Disabled={Disabled || ReadOnly}
									Required
									IsInvalid={vModel.CustomerNumber} />
							}
							{model.DdtFindType === 2 &&
								<GenericNumberInput
									Name="AccountId"
									LabelText="Wallet Id"
									Value={model.AccountId}
									ValueChanged={this.HandleChange}
									NoFormatting={true}
									MaxLength={16}
									Disabled={Disabled || ReadOnly}
									Required
									IsInvalid={vModel.AccountId} />
							}
							{model.DdtFindType === 3 &&
								<GenericNumberInput
									Name="AccountNumber"
									LabelText="Wallet Number"
									Value={model.AccountNumber}
									ValueChanged={this.HandleChange}
									MaxLength={25}
									NoFormatting={true}
									Disabled={Disabled || ReadOnly}
									Required
									IsInvalid={vModel.AccountNumber} />
							}
						</GridItem>
						<GridItem xs={8}></GridItem>
						<GridItem xs={4}>
							<GridContainer justify="flex-end">
								<GridItem>
									<Button size="sm" onClick={this.HandleAssign} disabled={ReadOnly}>ASSIGN</Button>
									<Button size="sm" onClick={this.HandleClear} disabled={ReadOnly}>CLEAR</Button>
								</GridItem>
							</GridContainer>
						</GridItem>
						<GridItem xs={12}>
							<br />
							<GenericGrid
								Data={list}
								Columns={this.Columns}
								// RowSelected={this.RowSelected}
								HideButton={true} />
						</GridItem>
					</GridContainer>
				</GenericExpansionPanel>

			</>
		);
	}
}

AssignDDTAccountComponent.propTypes = {
	classes: PropTypes.object.isRequired,
	showMessage: PropTypes.func.isRequired,
	showMessageNode: PropTypes.func.isRequired,
	showConfirmMessage: PropTypes.func.isRequired,
	ClientCustomerId: PropTypes.number,
	isPanelActive: PropTypes.bool,
	Disabled: PropTypes.bool
};

export default withArtifex(AssignDDTAccountComponent, {});