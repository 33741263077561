import { primaryColor } from "assets/jss/material-dashboard-pro-react";

const genericDialogStyle = {
	dialog: {
		border: "solid " + primaryColor + " 3px",
		padding: "3px"
	},
	overflowVisible: {
		overflow: "visible"
	}
};

export default genericDialogStyle;