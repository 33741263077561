import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericExpansionPanel, GenericGrid, GenericSelectInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType } from "views/Constants/Constant";
import DateHelper from '../../../core/DateHelper';

class CardDailyReport extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined,
			CreateDateTime: DateHelper.GetDate()
		};

		this.state = {
			model: this.initialModel,
			list: [],
			isEdit: false,
			isLoading: false,
			index: -1
		};


		this.parameterProduct = {
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined,
			IsNoNameProduct: false
		};

		this.renderItemUniqueClient = {};

		this.maxLength40 = { maxLength: 40 };
		this.maxLength25 = { maxLength: 25 };
		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined
		};
		this.Columns = [
			{
				Header: "Create Date",
				accessor: "CreateDateTime",
				type: GridColumnType.DateUtc
			},
			{
				Header: "Product Name",
				accessor: "ProductName"
			},
			{
				Header: "Plastic Type",
				accessor: "PlasticTypeName"
			},
			{
				Header: "Count",
				accessor: "Count"
			},

		];
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Card Daily Report");
		this.props.setAppCenterTitle("PREPAID MANAGEMENT");
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}

	HandleClear = () => {
		this.setState({ model: { ...this.initialModel }, isEdit: false, index: -1 });
	}

	HandleSearch = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/Card/CardDailyReport",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", responseData.ErrorDescription);
					return;
				}
				this.setState({ list: responseData.Item || [] });

			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}


	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}

	RowSelected = (index) => {
		var tempModel = this.state.list[index];
		this.setState({ isEdit: true, model: tempModel, index });
	}


	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: null })} OnConfirm={onConfirm} />
		});
	}
	GetProductParameter = () => {
		this.parameterProduct.UniqueClientId = this.state.model.UniqueClientId;
		this.parameterProduct.IsNoNameProduct = false;
		return this.parameterProduct;
	}

	render() {
		const { Disabled } = this.props;
		const { alert, model, list, isLoading } = this.state;

		var IsClient = ClientHelper.IsClient();

		return (
			<div>

				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer spacing={16}>

					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GridItem>
									<GridContainer>
										<GridItem xs={3}>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelMd={2}
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={this.parameterUniqueClientId}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={this.RenderItemUniqueClient}
												Value={model.UniqueClientId}
												ValueChanged={this.HandleChange}
												CanClear
												Disabled={IsClient}
												DefaultIndex={IsClient ? 0 : -1}
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												key={model.UniqueClientId}
												Name="ProductId"
												LabelMd={2}
												LabelText="Product"
												Method="POST"
												Url="/prepaidapi/v1.0/Product/Search"
												Parameter={this.GetProductParameter()}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ProductName"
												Value={model.ProductId}
												ValueChanged={this.HandleChange}
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												key={model.UniqueClientId}
												Name="PlasticTypeId"
												LabelText="Plastic Type"
												Method="POST"
												Url="/prepaidapi/v1.0/PlasticType/GetAll"
												DataRoot="Item"
												Parameter={
													{ UniqueClientId: model.UniqueClientId }
												}
												KeyValueMember="Id"
												TextValueMember="PlasticName"
												Value={model.PlasticTypeId}
												ValueChanged={this.HandleChange}
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericDateInput
												Name="CreateDateTime"
												LabelText="Create Date"
												Value={model.CreateDateTime || DateHelper.GetDate()}
												ValueChanged={this.HandleChange}
												DateFormat="MMM/YYYY"
												ViewMode="years"
												MaxDate={DateHelper.GetDate()}
											/>
										</GridItem>

									</GridContainer>
								</GridItem>
							</CardBody>
						</Card>

					</GridItem>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Daily Report List">
									<GridContainer>
										<GridItem xs={12}>
											<br />
											<GenericGrid
												Data={list}
												Columns={this.Columns}
											/>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer >
			</div >
		);
	}
}

CardDailyReport.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(CardDailyReport, {});
