import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes, { array, bool, func, number } from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import {
  GenericAlert,
  GenericDateInput,
  GenericDialog,
  GenericDialogActions,
  GenericGrid,
  GenericLabel,
  GenericNumberInput,
  GenericRadioInput,
  GenericSelectInput,
  GenericTitle,
} from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType } from "views/Constants/Constant";
import { DialogContent, DialogTitle } from "@material-ui/core";
import Select from "react-select";
import { EditIcon } from "core/Icons";
import { Proxy } from "core";
import GridButton from "views/Components/GridButton";
import Button from "components/CustomButtons/Button.jsx";

class SettlementEtransferFileByClient extends Component {
  constructor(props) {
    super(props);
    this.initTransactionDetailColumns();
    this.initExportColumns();

    this.directionDataSource = [
      { Key: "Incoming", Value: 16307 },
      { Key: "Outgoing", Value: 16308 },
    ];

    this.state = {
      model: {
        SettlementDate: null,
        UniqueClientId: null,
        UniqueClientId2: null,
        ToDate: DateHelper.GetDate(),
        FromDate: DateHelper.GetDate(),
        SettlementStatusId: -1,
        TransferTypeId: -1,
        DirectionId: -1,
        TimezoneOffset: 0,
        FinancialInstitutionId: -1,
        TransactionStatusId: null,
      },
      filterName: "Filter By",
      tabIndex: 1,
      settlementListBySettlementDate: [],
      settlementListBySettTransactionDate: [],
      isLoading: false,
      GrandTotalCreditBalance: undefined,
      GrandTotalCreditNumber: undefined,
      GrandTotalDebitBalance: undefined,
      GrandTotalDebitNumber: undefined,
      GrandTotalDifference: undefined,
      TotalSettledBalance: undefined,
      TotalCanceledBalance: undefined,
      TotalOutstandingBalance: undefined,
      TotalUnmatchedBalance: undefined,
      NetTotalBalanceChange: undefined,
      TotalUnSettledBalance: undefined,
      isTransactionDetailDialogOpen: false,
      loadAtStartup: false,
      gridTransactionDetailRefreshToken: false,
      selectedRow: undefined,
      headerName: undefined,
      clientNameColumnColor: "#000000",
      settlementStatusColumnColor: "#000000",
      totalCreditNumberColumnColor: "#000000",
      totalDebitNumberColumnColor: "#000000",
      isDownloadDialogOpen: false,
      exportReportType: "PDF",
      exportSelectedColumns: this.exportColumns,
    };

    this.transactionDetailGridRef = React.createRef();
  }
  componentDidMount() {
    this.props.setAppLeftTitle("Settled E-Transfer Transactions by Client Activities");
    this.props.setAppCenterTitle("CLEARING & SETTLEMENT");
    this.setState({ loadAtStartup: true });
  }

  handleChange = (name, newValue) => {
    this.setState((state) => {
      var model = state.model;
      model[name] = newValue;
      return { model };
    });
  };

  initExportColumns() {
    var exportColumns = [];

    this.transactionDetailColumns.forEach((c) => {
      if (
        c.accessor != "Actions" &&
        c.accessor != "ED" &&
        (c.show == undefined || c.show)
      ) {
        var exportColumn = {};
        exportColumn.value = c.accessor;
        exportColumn.title = c.Header;
        if (c.type) {
          exportColumn.type = c.type;
        }

        exportColumns.push(exportColumn);
      }
    }, this);

    this.exportColumns = exportColumns;
  }

  GetData = async () => {
    if (this.IsValid()) {
      const { ExecuteApiPost } = this.props;
      const { model, tabIndex } = this.state;
        let requetModel = {
          TransactionDateFrom: model.FromDate,
          TransactionDateTo: model.ToDate,
          uniqueClientId: model.UniqueClientId2,
          settlementStatusId: model.SettlementStatusId,
          TransferTypeId: model.TransferTypeId,
          DirectionId: model.DirectionId,
          TimezoneOffset: model.TimezoneOffset,
          FinancialInstitutionId: model.FinancialInstitutionId,
        };

        const responseData = await ExecuteApiPost(
          "/bankapi/v1.0/ClearingSettlement/GetSettledEtransferTransactionsSummaryByClients",
          requetModel
        );

        this.setState({
          settlementListBySettTransactionDate:
            responseData.EtransferSettledTransactionsSummaryByClients,
          GrandTotalCreditBalance: responseData.GrandTotalCreditBalance,
          GrandTotalCreditNumber: responseData.GrandTotalCreditNumber,
          GrandTotalDebitBalance: responseData.GrandTotalDebitBalance,
          GrandTotalDebitNumber: responseData.GrandTotalDebitNumber,
          GrandTotalDifference: responseData.GrandTotalDifference,
          TotalSettledBalance: responseData.TotalSettledBalance,
          TotalCanceledBalance: responseData.TotalCanceledBalance,
          TotalOutstandingBalance: responseData.TotalOutstandingBalance,
          TotalUnmatchedBalance: responseData.TotalUnmatchedBalance,
          NetTotalBalanceChange: responseData.NetTotalBalanceChange,
          TotalUnSettledBalance: responseData.TotalUnSettledBalance,
        });
    }
  };

  showMessage = (type, title, message) => {
    this.setState({
      alert: (
        <GenericAlert
          Title={title}
          Message={message}
          Type={type}
          OnConfirm={() => this.setState({ alert: "" })}
        />
      ),
    });
  };

  IsValid = () => {
    var vmodel = { ...this.state.model };
    if ((!vmodel || !vmodel.FromDate || !vmodel.ToDate) && this.state.tabIndex === 1) {
      this.props.showMessage(
        "warning",
        "Please fill required fields!",
        "Settlement From and To Date cannot be null"
      );
      return false;
    } else if (
      typeof vmodel.FromDate != "object" &&
      typeof vmodel.ToDate != "object" &&
      this.state.tabIndex === 1
    ) {
      this.props.showMessage(
        "warning",
        "Please fill required fields!",
        "Settlement From and To Date cannot be null"
      );
      return false;
    }
    return true;
  };

  handleClear = () => {
    this.setState({
      model: { SettlementDate: DateHelper.GetDate() },
      settlementListBySettlementDate: [],
      settlementListBySettTransactionDate: [],
      UniqueClientId: null,
      UniqueClientId2: undefined,
      FromDate: DateHelper.GetDate(),
      ToDate: DateHelper.GetDate(),
      GrandTotalCreditBalance: undefined,
      GrandTotalCreditNumber: undefined,
      GrandTotalDebitBalance: undefined,
      GrandTotalDebitNumber: undefined,
      GrandTotalDifference: undefined,
      TotalSettledBalance: undefined,
      TotalCanceledBalance: undefined,
      TotalOutstandingBalance: undefined,
      TotalUnmatchedBalance: undefined,
      NetTotalBalanceChange: undefined,
    });
  };
  handleChangeTab = (active) => {
    let filterName = "";
    if (active === 0) {
      filterName = "Filter By";
    }
    if (active === 1) {
      filterName = "Filter By";
    }
    this.setState({ tabIndex: active, filterName });
  };

  closeTransactionDetailDialog = () => {
    this.setState({
      transactionList: [],
      isTransactionDetailDialogOpen: false,
    });
  };

  GetTransactionList = async (selectedRow, headerName) => {
    const { ExecuteApiPost } = this.props;
    const { model } = this.state;
    let requestData = null;
    if (headerName === "ClientName" || headerName === "SettlementStatus") {
      requestData = {
        TransactionDateFrom: selectedRow.TransactionDateFrom,
        TransactionDateTo: selectedRow.TransactionDateTo,
        UniqueClientId: selectedRow.UniqueClientId,
        SettlementStatusId: selectedRow.SettlementStatusId,
        PageSize: 1_000_000,
        TransferTypeId: model.TransferTypeId,
        DirectionId: model.DirectionId,
        TimezoneOffset: model.TimezoneOffset,
        FinancialInstitutionId: model.FinancialInstitutionId,
        SettlementDate: model.SettlementDate,
        TransactionStatusId: model.TransactionStatusId,
      };
    }
    if (headerName === "TotalCreditNumber") {
      requestData = {
        TransactionDateFrom: selectedRow.TransactionDateFrom,
        TransactionDateTo: selectedRow.TransactionDateTo,
        UniqueClientId: selectedRow.UniqueClientId,
        SettlementStatusId: selectedRow.SettlementStatusId,
        TransactionTypeId: selectedRow.CreditTransactionTypeId,
        PageSize: 1_000_000,
        TransferTypeId: model.TransferTypeId,
        DirectionId: model.DirectionId,
        TimezoneOffset: model.TimezoneOffset,
        FinancialInstitutionId: model.FinancialInstitutionId,
        SettlementDate: model.SettlementDate,
        TransactionStatusId: model.TransactionStatusId,
      };
    }
    if (headerName === "TotalDebitNumber") {
      requestData = {
        TransactionDateFrom: selectedRow.TransactionDateFrom,
        TransactionDateTo: selectedRow.TransactionDateTo,
        UniqueClientId: selectedRow.UniqueClientId,
        SettlementStatusId: selectedRow.SettlementStatusId,
        TransactionTypeId: selectedRow.DebitTransactionTypeId,
        PageSize: 1_000_000,
        TransferTypeId: model.TransferTypeId,
        DirectionId: model.DirectionId,
        TimezoneOffset: model.TimezoneOffset,
        FinancialInstitutionId: model.FinancialInstitutionId,
        SettlementDate: model.SettlementDate,
        TransactionStatusId: model.TransactionStatusId,
      };
    }

    let responseData = await ExecuteApiPost(
      "/bankapi/v1.0/ClearingSettlement/GetSettledEtransferTransactionsByClientActivitiesPaged",
      requestData
    );
    if (responseData != null) {
      this.setState({
        headerModel: {},
        transactionList: responseData.Data.length < 1 ? [] : responseData.Data,
        isLoading: false,
      });
    }
  };

  getTransactionDetailDialog = () => {
    const { isTransactionDetailDialogOpen, isLoading } = this.state;

    return (
      <GenericDialog
        open={isTransactionDetailDialogOpen && !isLoading}
        maxWidth="xl"
        fullWidth
      >
        <DialogContent>
          <GridContainer spacing={16}>
            <GenericGrid
              Data={this.state.transactionList}
              Columns={this.transactionDetailColumns}
              PageSize={10}
              ShowPagination={true}
            />
          </GridContainer>
        </DialogContent>
        <GenericDialogActions>
          <GridContainer justify="flex-end">
            <Button size="sm" onClick={this.closeTransactionDetailDialog}>
              CANCEL
            </Button>
          </GridContainer>
        </GenericDialogActions>
      </GenericDialog>
    );
  };

  initTransactionDetailColumns() {
    this.transactionDetailColumns = [
      {
        Header: "Actions",
        accessor: "Actions",
        Cell: (row) => (
          <GridButton Disabled={true} tooltip="Edit" Icon={EditIcon} />
        ),
        sortable: false,
        filterable: false,
        width: 70,
        show: true,
      },
      {
        Header: "Client Id",
        accessor: "UniqueClientId",
      },
      {
        Header: "Client Name",
        accessor: "ClientName",
        width: 100,
      },
      {
        Header: "Balance Date",
        accessor: "BalanceDate",
        type: GridColumnType.DateTimeUtc,
        width: 100,
      },
      {
        Header: "Settlement Date",
        accessor: "SettlementDate",
        type: GridColumnType.DateTimeUtc,
      },
      {
        Header: "Received/Sent",
        accessor: "ReceivedSent",
      },
      {
        Header: "Debit/Credit",
        accessor: "TransactionType",
      },
      {
        Header: "Amount",
        accessor: "Amount",
      },
      {
        Header: "Settlement Status",
        accessor: "SettlementStatus",
      },
      {
        Header: "Interac Ref Number",
        accessor: "InteracRefNumber",
      },
      {
        Header: "Reference Number",
        accessor: "TransactionReferenceNumber",
      },
      {
        Header: "Transaction Insert Date",
        accessor: "TransactionInsertDate",
        type: GridColumnType.DateTimeUtc,
      },
      {
        Header: "Transaction Status",
        accessor: "TransactionStatus"
      },
      {
        Header: "Priority Type",
        accessor: "PriorityType"
      },
      {
        Header: "Description",
        accessor: "Description",
        width: 600,
      },
    ];
  }

  getColumns = () => {
    const {
      tabIndex,
      clientNameColumnColor,
      settlementStatusColumnColor,
      totalCreditNumberColumnColor,
      totalDebitNumberColumnColor,
    } = this.state;

     
      return [
        {
          Header: "Client ID",
          accessor: "UniqueClientCode",
        },
        {
          Header: "Client Name",
          accessor: "ClientName",
          Cell: (row) => {
            return (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  cursor: "pointer",
                  color: clientNameColumnColor,
                }}
                onClick={() => {
                  this.openTransactionDetailDialog();
                  this.GetTransactionList(row.original, "ClientName");
                }}
                onMouseEnter={() => {
                  this.setState({
                    clientNameColumnColor: "#FF0000",
                  });
                }}
                onMouseLeave={() => {
                  this.setState({
                    clientNameColumnColor: "#000000",
                  });
                }}
              >
                {row.original.ClientName}
              </div>
            );
          },
        },
        {
          Header: "Settlement Status",
          accessor: "SettlementStatus",
          Cell: (row) => {
            return (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  cursor: "pointer",
                  color: settlementStatusColumnColor,
                }}
                onClick={() => {
                  this.openTransactionDetailDialog();
                  this.GetTransactionList(row.original, "SettlementStatus");
                }}
                onMouseEnter={() => {
                  this.setState({
                    settlementStatusColumnColor: "#FF0000",
                  });
                }}
                onMouseLeave={() => {
                  this.setState({
                    settlementStatusColumnColor: "#000000",
                  });
                }}
              >
                {row.original.SettlementStatus}
              </div>
            );
          },
        },
        {
          Header: "Date From",
          accessor: "TransactionDateFrom",
          type: GridColumnType.DateUtc,
        },
        {
          Header: "Date To",
          accessor: "TransactionDateTo",
          type: GridColumnType.DateUtc,
        },
        {
          Header: "Total Number Of Credits",
          accessor: "TotalCreditNumber",
          Cell: (row) => {
            return (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  cursor: "pointer",
                  color: totalCreditNumberColumnColor,
                }}
                onClick={() => {
                  this.openTransactionDetailDialog();
                  this.GetTransactionList(row.original, "TotalCreditNumber");
                }}
                onMouseEnter={() => {
                  this.setState({
                    totalCreditNumberColumnColor: "#FF0000",
                  });
                }}
                onMouseLeave={() => {
                  this.setState({
                    totalCreditNumberColumnColor: "#000000",
                  });
                }}
              >
                {row.original.TotalCreditNumber}
              </div>
            );
          },
        },
        {
          Header: "Credit Balance",
          accessor: "CreditBalance",
          type: GridColumnType.Money,
          ColumnType: GridColumnType.Money,
        },
        {
          Header: "Total Number Of Debits",
          accessor: "TotalDebitNumber",
          Cell: (row) => {
            return (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  cursor: "pointer",
                  color: totalDebitNumberColumnColor,
                }}
                onClick={() => {
                  this.openTransactionDetailDialog();
                  this.GetTransactionList(row.original, "TotalDebitNumber");
                }}
                onMouseEnter={() => {
                  this.setState({
                    totalDebitNumberColumnColor: "#FF0000",
                  });
                }}
                onMouseLeave={() => {
                  this.setState({
                    totalDebitNumberColumnColor: "#000000",
                  });
                }}
              >
                {row.original.TotalDebitNumber}
              </div>
            );
          },
        },
        {
          Header: "Debit Balance",
          accessor: "DebitBalance",
          type: GridColumnType.Money,
          ColumnType: GridColumnType.Money,
        },
        {
          Header: "Balance Difference",
          accessor: "Difference",
          type: GridColumnType.Money,
          ColumnType: GridColumnType.Money,
        },
      ];
     
  };

  setValueToModel = (name, value) => {
    const model = { ...this.state.model };
    model[name] = value;
    this.setState({ model });
  };

  onValidate = () => {
    const { model } = this.state;

    if (!model.FromDate || !model.ToDate) {
      this.props.showMessage(
        "warning",
        "Please fill required fields!",
        "Settlement Date is invalid"
      );
      return false;
    }

    return true;
  };

  openTransactionDetailDialog = (row, headerName) => {
    this.setState({
      isLoading: true,
      isTransactionDetailDialogOpen: true,
      headerName,
      selectedRow: row,
      gridTransactionDetailRefreshToken:
        !this.state.gridTransactionDetailRefreshToken,
    });
  };

  getDataSource = () => {
    const {
      settlementListBySettTransactionDate,
      tabIndex,
    } = this.state;
      return settlementListBySettTransactionDate;
  };

  isExportRequestValid = () => {
    const { model } = this.state;

    if (model.FromDate == null || model.FromDate == undefined) {
      this.showMessage(
        "warning",
        "Please select required field !",
        "Select a Settlement File Date !"
      );
      return false;
    }

    return true;
  };

  showLoading() {
    this.setState({ isLoading: true });
  }

  hideLoading() {
    this.setState({ isLoading: false });
  }

  onExport = () => {
    if (this.isExportRequestValid()) {
      const { exportReportType } = this.state;
      const { SettlementDate, FromDate, UniqueClientId2, ...model2 } =
        this.state.model;

      const exportModel = {
        ...model2,
        TransactionDateTo: this.state.model.ToDate,
        UniqueClientId: this.state.model.UniqueClientId2,
        TransactionDateFrom: this.state.model.FromDate,
        SettlementDate: this.state.model.SettlementDate,
        jsonFile: {},
      };

      var exportColumns = this.getSelectedExportColumns() ?? [];

      exportModel.jsonFile = {
        colmns: exportColumns,
        format: exportReportType,
        title: "Settled E-Transfer Transactions by Client Activities",
      };

      this.showLoading();
      Proxy.DownloadGeneratedFile(
        "/bankapi/v1.0/ClearingSettlement/GetSettledEtransferTransactionsByClientActivitiesExportDownload",
        exportModel,
        (responseData) => {
          this.hideLoading();

          if (!responseData.IsSucceeded) {
            this.showMessage("error", "Error", responseData.ErrorDescription);
            return;
          }
        },
        (errorMessage) => {
          this.hideLoading();
          this.showMessage("error", "Error", errorMessage);
        },
        this.showMessage
      );
    }
  };

  openExportDialog = () => {
    this.setState({
      isDownloadDialogOpen: true,
    });
  };

  closeExportDialog = () => {
    this.setState({
      isDownloadDialogOpen: false,
    });
  };

  setExportSelection = (value) => {
    this.setState({ exportSelectedColumns: value });
  };

  getSelectedExportColumns = () => {
    const { exportSelectedColumns } = this.state;

    var columns = {};

    exportSelectedColumns.forEach((c) => {
      columns[c.value] = {
        value: c.value,
        title: c.title,
        columntype: c.columntype,
        type: c.type,
      };
    });

    var result = [];
    result.push(columns);

    return result;
  };

  setValueToState = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    const {
      isLoading,
      tabIndex,
      filterName,
      isDownloadDialogOpen,
      exportReportType,
      exportSelectedColumns,
    } = this.state;
	 const { model } = this.state;
	 const {
      GrandTotalCreditBalance,
      GrandTotalCreditNumber,
      GrandTotalDebitBalance,
      GrandTotalDebitNumber,
      GrandTotalDifference,
    } = this.state;

    return (
      <div>
        {this.state.alert}
        <ButtonToolbar
          ButtonList={[
            { Code: "Search", OnClick: this.GetData, Disabled: false },
            { Code: "Export", OnClick: this.openExportDialog, Disabled: false },
				{ Code: "Clear", OnClick: this.handleClear, Disabled: false }
          ]}
          menuId={this.props.menuId}
          ApprovalData={this.props.ApprovalData}
          After={this.props.After}
        />
        <LoadingComponent Show={isLoading} />
        <GridContainer>
          <GridItem xs={5}>
            <Card style={{ height: "95%" }}>
				<CardHeader>
              <GenericTitle text={"Filter By"} />
            </CardHeader>
				<GridContainer>
					<GridItem xs={12}>
						<GenericDateInput
							Name="FromDate"
							LabelText="Settlement From Date"
							Value={
							model.FromDate ? Formatter.FormatDateUtc(model.FromDate) : ""
							}
							ValueChanged={this.handleChange}
							MaxDate={model.ToDate || DateHelper.GetDate()}
							Utc
						/>
					</GridItem>
					<GridItem xs={12}>
						<GenericDateInput
							Name="ToDate"
							LabelText="Settlement To Date"
							Value={model.ToDate ? Formatter.FormatDateUtc(model.ToDate) : ""}
							ValueChanged={this.handleChange}
							MinDate={model.FromDate}
							Utc
						/>
					</GridItem>
					<GridItem xs={12}>
						<GenericSelectInput
							Name="UniqueClientId2"
							LabelText="Client"
							Method="POST"
							Url="/bankapi/v1.0/BankCustomer/Search"
							Parameter={{}}
							DataRoot="Item"
							KeyValueMember="Id"
							RenderItem={(d) => `${d.UniqueClientId} - ${d.Name}`}
							Value={model.UniqueClientId2 || ""}
							ValueChanged={this.handleChange}
							All
							CanClear
						/>
					</GridItem>
					<GridItem xs={12}>
						<GenericSelectInput
							Name="TransferTypeId"
							LabelText="Transfer Type"
							Method="GET"
							Url="/bankapi/v1.0/Transaction/GetAllFinancialTransactionType"
							DataRoot="Item"
							KeyValueMember="Id"
							TextValueMember="ParameterValue6"
							Value={model.TransferTypeId}
							ValueChanged={this.setValueToModel}
							All
							CanClear
						/>
					</GridItem>
					<GridItem xs={12}>
						<GenericSelectInput
							IsStatic
							Name="DirectionId"
							LabelText="Fund Direction"
							StaticData={this.directionDataSource}
							KeyValueMember="Value"
							TextValueMember="Key"
							Value={model.DirectionId}
							ValueChanged={this.setValueToModel}
							All
							CanClear
						/>
					</GridItem>
					<GridItem xs={12}>
						<GenericSelectInput
							Name="FinancialInstitutionId"
							LabelText="Financial Institution"
							Method="GET"
							Url="/bankapi/v1.0/financialinstitution/getall"
							DataRoot="Item"
							KeyValueMember="FinancialInstitutionId"
							TextValueMember="Description"
							Value={model.FinancialInstitutionId}
							ValueChanged={this.setValueToModel}
							All
							CanClear
						/>
					</GridItem>
      </GridContainer>
            </Card>
          </GridItem>
          <GridItem xs={7}>
        <Card>
		  	 <CardHeader>
            <GenericTitle text={"Query Results"} />
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={6}>
                <GenericNumberInput
                  Name="GrandTotalCreditNumber"
                  LabelText="Total Number of Credits"
                  LabelMd={6}
                  Value={GrandTotalCreditNumber}
                  Disabled={true}
                  DecimalScale={0}
                />
                <GenericNumberInput
                  Name="GrandTotalCreditBalance"
                  LabelText="Total Credit Balance"
                  LabelMd={6}
                  Value={GrandTotalCreditBalance}
                  Disabled={true}
                  Prefix="$"
                />
                <GenericNumberInput
                  Name="GrandTotalDifference"
                  LabelText="Total Balance Difference"
                  LabelMd={6}
                  Value={GrandTotalDifference}
                  Disabled={true}
                  Prefix="$"
                />
              </GridItem>
              <GridItem xs={6}>
                <GenericNumberInput
                  Name="GrandTotalDebitNumber"
                  LabelText="Total Number of Debits"
                  Value={GrandTotalDebitNumber}
                  Disabled={true}
                  DecimalScale={0}
                />
                <GenericNumberInput
                  Name="GrandTotalDebitBalance"
                  LabelText="Total Debit Balance"
                  Value={GrandTotalDebitBalance}
                  Disabled={true}
                  Prefix="$"
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
        </GridContainer>
        <Card className="no-radius">
            <CardHeader>
              <GenericTitle text={"Settlement Details"} />
            </CardHeader>
				<CardBody>
					<GridContainer>
            <GridItem xs={12}>
              <GridItem>
                <GenericGrid
                  Data={this.getDataSource()}
                  Columns={this.getColumns()}
                  SelectedIndex={this.state.rowIndex}
                />
              </GridItem>
            </GridItem>
				</GridContainer>
				</CardBody>
        </Card>
        {this.getTransactionDetailDialog()}
        <GenericDialog open={isDownloadDialogOpen} fullWidth={true}>
          <DialogTitle>
            <GenericLabel
              Text="Select Report Type"
              FontSize="20px"
              Bold={true}
            />
          </DialogTitle>
          <DialogContent style={{ height: 300 }}>
            <GenericRadioInput
              Name="exportReportType"
              LabelText="Report Type"
              IsStatic={true}
              StaticData={[
                { Value: "PDF", Text: "PDF" },
                { Value: "CSV", Text: "CSV" },
                { Value: "EXCEL", Text: "EXCEL" },
              ]}
              KeyValueMember="Value"
              TextValueMember="Text"
              Value={exportReportType}
              ValueChanged={this.setValueToState}
            />
            <GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
            <GridItem style={{ marginTop: 20 }}>
              <Select
                value={exportSelectedColumns}
                onChange={this.setExportSelection}
                isMulti
                name="exportSelectedColumns"
                getOptionLabel={(d) => d.title}
                options={this.exportColumns}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </GridItem>
          </DialogContent>
          <GenericDialogActions>
            <Button size="sm" onClick={this.onExport}>
              Download
            </Button>
            <Button size="sm" onClick={this.closeExportDialog}>
              Cancel
            </Button>
          </GenericDialogActions>
        </GenericDialog>
      </div>
    );
  }
}

SettlementEtransferFileByClient.propTypes = {
  classes: PropTypes.object,
  handleOperationType: func,
  SelectedRowChange: func,
  showQuestionMessage: func,
  setAppLeftTitle: func,
  setAppCenterTitle: func,
  Disabled: bool,
  menuId: number,
  ApprovalData: array,
  After: func,
};

export default withArtifex(SettlementEtransferFileByClient, {});
