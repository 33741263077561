import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericCheckInput, GenericDateInput, GenericGrid, GenericNumberInput, GenericTextInput, GenericExpansionPanel, GenericDateFilterInput, GenericTitle, GenericDialog, GenericLabel, GenericRadioInput, GenericDialogActions } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent.jsx";
import ParameterComponent from "views/Components/ParameterComponent";
import { GridColumnType } from "views/Constants/Constant";
import Select from "react-select";
import DateHelper from "core/DateHelper";
import Button from "components/CustomButtons/Button.jsx";
class IvrCallerHistory extends Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			PhoneNumber : null,
			FromDate : null,
			ToDate : null,
		};

		this.initialState = {
			model : this.initialModel,
			isLoading : false,
			ExportReportType: null,
			GridKeyValueList : []
		};

		this.Columns = [
			{
				Header: "Unique Client Id",
				accessor: "IvrUniqueClientId"
			},
			{
				Header: "Phone Number",
				accessor: "PhoneNumber"
			},
			{
				Header: "Call Date",
				accessor: "CallDate",
				type: GridColumnType.DateTimeUtc
			},
			{
				Header: "Session Id",
				accessor: "SessionId"
			},
			{
				Header: "Caller Request Type",
				accessor: "CallerRequestType"
			},
			{
				Header: "Last Four Digit",
				accessor: "LastFourDigits"
			},
			{
				Header: "Api Type",
				accessor: "ApiType"
			}
		];
		this.state = this.initialState;
		this.trxTable = React.createRef();
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Ivr Caller History List");
		this.props.setAppCenterTitle("CALL CENTER");
		this.setState({
			loadingCompleted: true
		});
	}

	handleChange = (name, newValue) => {
		if(name == "ExportReportType"){
			this.setState({
				ExportReportType: newValue
			})
			return;
		}
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}

	showLoading = () => { this.setState({  isLoading: true }); }
	hideLoading = () => { this.setState({  isLoading: false }); }

	handleSearch = () =>{
		this.setState(state => ({ trxGridToken: !state.trxGridToken }));
	}

	handleClear = () =>{
		this.setState({
			model : this.initialState
		})
	}


	ExportClick = () => {
		var trxColumns = [];
		this.trxTable.current.props.Columns.forEach(c => {
			if (c.accessor != "Actions" && c.accessor != "ED" && (c.show == undefined || c.show)) {
				trxColumns.push({ value: c.accessor, title: c.Header, columntype: c.ColumnType, type: c.type });
			}
		}, this);

		this.setState({ isDownloadDialogOpen: true, GridKeyValueList: trxColumns, ExportSelectedColumns: trxColumns });
	}

	ExcelDownload = () => {	
		if (!this.state.ExportReportType) {
			this.props.showMessage("warning", "Export Report Type not selected", "Select Export Report Type to continue");
			return;
		}

		if (this.state.ExportReportType == "PDF" && this.state.ExportSelectedColumns.length > 15) {
			this.props.showMessage("warning", "PDF document cannot exceed 15 columns", "PDF document cannot exceed 15 columns");
			return;
		}

		this.setState({ isDownloadDialogOpen: false });

		const temp = { ...this.state.model };

		var jsonColList = [];

		var trxColumns = {};
		this.state.ExportSelectedColumns.map(c => {
			trxColumns[c.value] = { value: c.value, title: c.title, columntype: c.columntype, type: c.type };
		});
		jsonColList.push(trxColumns);

		var mytitle = "Ivr Caller History List";

		temp.jsonFile = {
			colmns: jsonColList,
			format: this.state.ExportReportType,
			title: mytitle
		};

		this.setState({ isLoading: true });
		Proxy.DownloadGeneratedFile(
			"/bankapi/v1.0/IvrCallerHistory/CallerHistoryExportDownload",
			temp,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", errorMessage);
			},
			this.props.showMessage
		);
	}

	render() {
		const { model, isLoading } = this.state;
		return (
			<div>
				{alert}
				<LoadingComponent Show={isLoading} />
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.handleSearch, Disabled: this.props.Disabled },
					{ Code: "Clear", OnClick: this.handleClear },
					{ Code: "Export", OnClick: this.ExportClick, Disabled: this.props.Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<br />
				<GridContainer spacing={16}>
				<GenericDialog open={this.state.isDownloadDialogOpen} fullWidth={true}>
					<DialogTitle>
						<GenericLabel Text="Select Report Type" FontSize="20px" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ height: 300 }}>
						<GenericRadioInput
							Name="ExportReportType"
							LabelText="Report Type"
							IsStatic={true}
							StaticData={[{ Value: "PDF", Text: "PDF" }, { Value: "CSV", Text: "CSV" }, { Value: "EXCEL", Text: "EXCEL" }]}
							KeyValueMember="Value"
							TextValueMember="Text"
							Value={this.state.ExportReportType}
							ValueChanged={this.handleChange}
						/>
						<GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
						<GridItem style={{ marginTop: 20 }}>
							<Select
								value={this.state.ExportSelectedColumns}
								onChange={value => this.setState({ ExportSelectedColumns: value })}
								isMulti
								name="columns"
								getOptionLabel={d => d.title}
								options={this.state.GridKeyValueList}
								className="basic-multi-select"
								classNamePrefix="select"
							/>
						</GridItem>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.ExcelDownload}>Download</Button>
						<Button size="sm" onClick={() => this.setState({ isDownloadDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>
					<GridItem xs={12}>
						<Card className="no-radius">
							<GenericExpansionPanel Title="Filters">
								<CardBody>
								<GridContainer>
									<GridItem xs={4}>
										<GenericNumberInput
												NoFormatting
												Name="PhoneNumber"
												LabelText="Phone Number"
												Value={model.PhoneNumber}
												ValueChanged={this.handleChange} 
										/>
									</GridItem>
									<GridItem xs={4}>
											<GenericDateInput
												Name="FromDate"
												LabelText="From Date"
												ValueChanged={this.handleChange}
												IncludeTime={false}
												Value={model.FromDate}
												ValueChanged={this.handleChange} 
											/>
									</GridItem>	
									<GridItem xs={4}>	
											<GenericDateInput
												Name="ToDate"
												LabelText="To Date"
												ValueChanged={this.handleChange}
												IncludeTime={false}
												Value={model.ToDate}
											   ValueChanged={this.handleChange} 
											/>
									</GridItem>
									</GridContainer>
								</CardBody>
							</GenericExpansionPanel>
						</Card>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text="Ivr Caller History List" />
							</CardHeader>
							<CardBody>
								<GridContainer spacing={16}>
									<GridItem xs={12}>
									<GenericGrid
											key={"trxGrid" + this.state.trxGridToken}
											ref={this.trxTable}
											Columns={this.Columns}
											ServerSide
											RequestUrl="/bankapi/v1.0/IvrCallerHistory/SearchPaged"
											RequestMethod="POST"
											PrepareRequest={() => { return model; }}
											PageSize={10}
											ShowPagination={true}
											IsSorted={false}
											LoadAtStartup={this.state.loadingCompleted}
										/>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

			</div>
		);
	}
}

IvrCallerHistory.propTypes = {
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool,
	ValueChanged: PropTypes.func,
	model: PropTypes.object
};

export default withArtifex(IvrCallerHistory, {});