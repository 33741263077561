import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import { GenericTitle, GenericDateInput, GenericGrid, GenericPage, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";

class WireTransferRestrictedCountriesTransactionTrail extends GenericPage {
	constructor(props) {
		super(props);

		this.isBackOffice = ClientHelper.IsBackOffice();
		this.clientId = ClientHelper.GetClientId();
		this.columns = [
			{
				Header: "Client Name",
				accessor: "ClientName"
			},
			{
				Header: "Beneficiary Name",
				Cell: row => (<span>{row.original.BeneficiaryCompanyName == "" ? row.original.BeneficiaryName : row.original.BeneficiaryCompanyName}</span>)
			},
			{
				Header: "Wallet Number",
				accessor: "FromAccount"
			},
			{
				Header: "Template Name",
				accessor: "TemplateName",
			},
			{
				Header: "Date Of Request",
				accessor: "DateOfRequest",
			},
			{
				Header: "Value Effective",
				accessor: "ValueEffective"
			},
			{
				Header: "Amount",
				accessor: "Amount",
				Cell: row => (<span>{Formatter.FormatDecimal(row.value)}</span>)
			},
			{
				Header: "Currency",
				accessor: "Currency"
			},
			{
				Header: "Status",
				accessor: "Status"
			},
			{
				Header: "Sub-Status",
				accessor: "SubStatus"
			},
			{
				Header: "Reference Number",
				accessor: "TransactionId"
			},
			{
				Header: "Status",
				accessor: "Status"
			},
			{
				Header: "Destination",
				accessor: "DestinationCountry"
			}
		];

		this.defaultModel = {
			UniqueClientId: this.isBackOffice ? undefined : this.clientId,
			Date: DateHelper.GetDate(),
			TrxDirection: undefined,
			MessageType: undefined,
			FromAmount: undefined,
			ToAmount: undefined,
			BeneficiaryName: undefined,
			ReferenceNumber: undefined
		};

		this.state = {
			model: { ...this.defaultModel }
		};

		this.EmptyObject = {};
	}

	componentDidMount() {
		super.componentDidMount();
		this.Bind(this);
		const { setAppLeftTitle, setAppCenterTitle } = this.props;

		if (setAppLeftTitle)
			setAppLeftTitle("Restricted Countries Transaction Trail");
		if (setAppCenterTitle)
			setAppCenterTitle("WIRE TRANSFER");
	}

	SearchClick() {
		const { model } = this.state;

		this.ExecutePostRequest("/bankapi/v1.0/WireTransfer/SearchRestrictedCountriesTransactionTrail",
			model,
			responseData => {
				this.setState({ Data: responseData.Item });
			});
	}

	ClearClick() {
		this.setState({ model: { ...this.defaultModel } });
	}

	ValueChanged(name, newValue) {
		this.setState(state => {
			var model = state.model || {};
			model[name] = newValue;

			return { model };
		});
	}

	render() {
		const { Disabled } = this.props;
		const { model, Data } = this.state;
		const { IsLoading, Alert } = this.state;

		return (
			<>
				{Alert}
				<LoadingComponent Show={IsLoading} />
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.SearchClick, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.ClearClick, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<Card>
					<CardHeader>
						<GenericTitle text={"Filter Panel"} />
					</CardHeader>
					<CardBody>
						<GridContainer>
							<GridItem xs={4}>
								<GenericSelectInput
									Name="UniqueClientId"
									LabelText={"Client Name"}
									Value={model.UniqueClientId}
									DataRoot="Item"
									ValueChanged={this.ValueChanged}
									KeyValueMember="UniqueClientId"
									TextValueMember="Name"
									Url="/bankapi/v1.0/BankCustomer/Search"
									CanClear
									Method="POST"
									Disabled={Disabled || !this.isBackOffice}
									Parameter={this.EmptyObject} />
								<GenericDateInput Name="Date" LabelText={"Date"}
									Utc={true}
									Value={model.Date} ValueChanged={this.ValueChanged} Disabled={Disabled} />
								<ParameterComponent Name="TrxDirection" LabelText={"Trx. Direction"} Value={model.TrxDirection} ValueChanged={this.ValueChanged} ParameterCode="Direction" Disabled={Disabled} />
								<ParameterComponent Name="MessageType" LabelText={"Message Type"} Value={model.MessageType} ValueChanged={this.ValueChanged} ParameterCode="WireMessageType" Disabled={Disabled} />
							</GridItem>
							<GridItem xs={4}>
								<GenericNumberInput Name="FromAmount" LabelText={"From Amount"} Value={model.FromAmount} ValueChanged={this.ValueChanged} Disabled={Disabled} />
								<GenericNumberInput Name="ToAmount" LabelText={"To Amount"} Value={model.ToAmount} ValueChanged={this.ValueChanged} Disabled={Disabled} />
								<GenericTextInput Name="BeneficiaryName" LabelText={"Beneficiary Name"} Value={model.BeneficiaryName} ValueChanged={this.ValueChanged} Disabled={Disabled} />
								<GenericTextInput Name="ReferenceNumber" LabelText="Reference Number" Value={model.ReferenceNumber} ValueChanged={this.ValueChanged } />
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
				<GenericGrid
					Data={Data}
					Columns={this.columns}
				/>
			</>
		);
	}
}

WireTransferRestrictedCountriesTransactionTrail.propTypes = {
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool,
	ValueChanged: PropTypes.func,
	Model: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	menuId: PropTypes.number.isRequired,
	ApprovalData: PropTypes.object,
	After: PropTypes.func
};

export default withArtifex(WireTransferRestrictedCountriesTransactionTrail, {});