import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import AlertHelper from "core/AlertHelper";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import PropTypes, { func } from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import ToolBar from "views/Components/DynamicToolbar";
import { GenericDateInput, GenericExpansionPanel, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { DefaultBankInformation, Portals } from "views/Constants/Constant.js";
import CDICList from "./SearchTabs/CDICList";

const styles = ({
	...sweetAlertStyle,
	GridItemFreePadding: {
		padding: "0px 5px !important"
	}
});

class CDICAccounts extends Component {
	constructor(props) {
		super(props);
		this.state = {
			model: {},
			toolBarModel: {},
			CDICList: [],
			CdicTotalInfo: {},
			isLoading: false,
			poolModel: {},
			isBackOffice: false
		};
		this.handleChange = this.handleChange.bind(this);
		this.GetAllData = this.GetAllData.bind(this);
		this.errorCallback = this.errorCallback.bind(this);
		this.hideAlert = this.hideAlert.bind(this);
		this.showErrorMessage = this.showErrorMessage.bind(this);
		this.successCdicCallback = this.successCdicCallback.bind(this);
		this.successCallback = this.successCallback.bind(this);
	}

	componentDidMount() {
		var model = { ...this.state.model };

		var isBackOffice = ClientHelper.IsBackOffice();
		if (!isBackOffice) {
			this.GetToolbarData();
			model.UniqueClientId = ClientHelper.GetClientRowId();
		}

		this.props.setAppLeftTitle("DDT - CDIC List");
		this.props.setAppCenterTitle("DDT MANAGEMENT");
		this.props.setPortal(Portals.DDT);
		this.setState({ model, isBackOffice });
	}

	handleChange(name, newValue) {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}

	GetAllData() {
		// if (this.state.model == null)
		// 	this.showErrorMessage("At least one field must be full in Main Parameters !!");
		// else {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/Cdic/CdicSearch",
			this.state.model,
			this.successCdicCallback,
			this.errorCallback
		);
		// } 
	}

	successCdicCallback(responseData) {
		this.hideLoading();
		if (!responseData.IsSucceeded) {
			this.showErrorMessage(responseData.ErrorDescription);
			return;
		}
		if (responseData.Item != null) {
			if (responseData.Item.CdicAccountList != null) {
				if (responseData.Item.CdicAccountList.length < 1) {
					this.setState({ CDICList: [], CdicTotalInfo: {} });
				} else {
					var CDICList = responseData.Item.CdicAccountList.length < 1 ? [] : responseData.Item.CdicAccountList;
					var CdicTotalInfo = responseData.Item.CdicTotalInfo == null ? {} : responseData.Item.CdicTotalInfo;
					this.setState({ CDICList, CdicTotalInfo });
					this.GetToolbarData();
				}
			}
		}
	}

	GetToolbarData = () => {
		this.showLoading();
		Proxy.GET(
			"/bankapi/v1.0/Ddt/GetAllDDTCDICNumberAndBalance",
			this.successCallback,
			this.errorCallback
		);
	}

	successCallback(responseData) {
		var toolBarModel = { ...this.state.toolBarModel };
		var poolModel = { ...this.state.poolModel };
		var model = { ...this.state.model };

		model.PrimaryBranchId = responseData.Item == null ? "" : responseData.Item.CDICPoolAccountInfo == null ? "" : responseData.Item.CDICPoolAccountInfo.BranchId == null ? "" : responseData.Item.CDICPoolAccountInfo.BranchId;
		model.PrimaryAccountNumber = responseData.Item == null ? "" : responseData.Item.CDICPoolAccountInfo == null ? "" : responseData.Item.CDICPoolAccountInfo.AccountNumber == null ? "" : responseData.Item.CDICPoolAccountInfo.AccountNumber;

		toolBarModel.TotalDDTNumber = responseData.Item == null ? 0 : responseData.Item.TotalDDTNumber == null ? 0 : responseData.Item.TotalDDTNumber;
		toolBarModel.TotalDDTBalance = responseData.Item == null ? 0 : responseData.Item.DDTPoolAccountInfo == null ? 0 : responseData.Item.DDTPoolAccountInfo.Balance == null ? 0 : responseData.Item.DDTPoolAccountInfo.Balance;
		toolBarModel.TotalCDICNumber = responseData.Item == null ? 0 : responseData.Item.TotalCDICNumber == null ? 0 : responseData.Item.TotalCDICNumber;
		toolBarModel.TotalCDICBalance = responseData.Item == null ? 0 : responseData.Item.CDICPoolAccountInfo == null ? 0 : responseData.Item.CDICPoolAccountInfo.Balance == null ? 0 : responseData.Item.CDICPoolAccountInfo.Balance;
		this.setState({ model, toolBarModel, poolModel, isLoading: false });
	}

	errorCallback(error) {
		this.showErrorMessage("An error occurred during the api visit" + error);
		this.hideLoading();
	}

	showErrorMessage(message) {
		this.setState({
			CDICList: [],
			CdicTotalInfo: null,
			isLoading: false,
			alert: AlertHelper.CreateAlert("Error", message, "error", this.hideAlert)
		});
	}

	hideAlert() {
		this.setState({ alert: null });
	}

	showLoading = () => {
		this.setState({ isLoading: true });
	}

	ClearModel = () => {
		this.setState({ model: {} });
	}

	hideLoading = () => {
		this.setState({ isLoading: false });
	}

	render() {
		const { model, toolBarModel, isLoading, poolModel, isBackOffice } = this.state;
		const { Disabled, classes } = this.props;
		return (
			<div>
				{this.state.alert}


				<LoadingComponent Show={isLoading} />

				{!isBackOffice &&
					<GridItem xs={12}>
						<ToolBar
							IsNumber
							ItemList={[
								{ Label: "Total No. of DDT", Value: toolBarModel.TotalDDTNumber == null ? 0 : toolBarModel.TotalDDTNumber + "" },
								{ Label: "Total No. of DDT - CDIC", Value: toolBarModel.TotalCDICNumber == null ? 0 : toolBarModel.TotalCDICNumber + "" },
								{ Label: "DDT Wallet Balance", Value: toolBarModel.TotalDDTBalance == null ? 0 : toolBarModel.TotalDDTBalance },
								{ Label: "DDT - CDIC Wallet Balance", Value: toolBarModel.TotalCDICBalance == null ? 0 : toolBarModel.TotalCDICBalance }
							]} />
					</GridItem>
				}

				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.GetAllData, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.ClearModel, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<Card className="no-radius">

					<GenericExpansionPanel Title={"Main Parameters"}>
						<CardBody>
							<GridContainer>
								<GridItem xs={6}>
									<GenericSelectInput
										Name="UniqueClientId"
										LabelText="Client"
										Method="POST"
										Url="/bankapi/v1.0/BankCustomer/Search"
										Parameter={{ UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined }}
										DataRoot="Item"
										KeyValueMember="Id"
										RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
										Value={model.UniqueClientId || ""}
										ValueChanged={this.handleChange}
										All
										CanClear
										Disabled={!isBackOffice}
										DefaultIndex={ClientHelper.IsClient() ? 0 : -1} />
									<GridContainer>
										<GridItem className={classes.GridItemFreePadding} xs={6}>
											<GenericSelectInput
												Name="FinInsId"
												LabelText="Client Primary Wallet"
												LabelMd={8}
												Method="GET"
												Url="/bankapi/v1.0/FinancialInstitution/GetAll"
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="FinInsId"
												Value={DefaultBankInformation.FinancialInstitutionId ? DefaultBankInformation.FinancialInstitutionId : 0}
												Disabled={true} />
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												key={poolModel.FinInsId}
												Name="PrimaryBranchId"
												LabelMd={0}
												TitleLabel="Branc No."
												Method="GET"
												Url="/bankapi/v1.0/FinancialInstitutionBranch/GetAllDCBankBranches"
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="TransitNo"
												Value={model.PrimaryBranchId == null ? "" : model.PrimaryBranchId}
												ValueChanged={this.handleChange}
												Disabled={!isBackOffice} />
										</GridItem>
										<GridItem style={{ paddingRight: 2 + "px !important" }} xs={3}>
											<GenericNumberInput
												NoFormatting={true}
												LabelMd={0}
												InputStyle={{ marginLeft: 2 }}
												Name="PrimaryAccountNumber"
												ValueChanged={this.handleChange}
												Value={model.PrimaryAccountNumber == null ? "" : model.PrimaryAccountNumber}
												MaxLength={12}
												Disabled={!isBackOffice} />
										</GridItem>
									</GridContainer>

									<GenericSelectInput
										Name="StatusId"
										LabelText="CDIC Status"
										Value={model.StatusId == null ? "" : model.StatusId}
										DataRoot="Item"
										ValueChanged={this.handleChange}
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Method="POST"
										All
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={{
											ParameterCode: "AccountStatus",
											"ParameterValue": "",
											ParameterValue3: "CDIC",
											"ParameterDesc": ""
										}} />
									<GridContainer>
										<GridItem xs={6}>
											<GenericSelectInput
												Name="FinInsId"
												LabelText="DDT - CDIC Number"
												Method="GET"
												LabelMd={8}
												Url="/bankapi/v1.0/FinancialInstitution/GetAll"
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="FinInsId"
												Value={DefaultBankInformation.FinancialInstitutionId}
												Disabled={true} />
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												key={poolModel.FinInsId}
												Name="BranchId"
												LabelMd={0}
												TitleLabel="Branc No."
												Method="GET"
												Url="/bankapi/v1.0/FinancialInstitutionBranch/GetAllDCBankBranches"
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="TransitNo"
												Value={model.BranchId == null ? "" : model.BranchId}
												ValueChanged={this.handleChange}
											/>
										</GridItem>
										<GridItem xs={3} style={{ paddingRight: 2 + "px !important" }}>
											<GenericNumberInput
												NoFormatting={true}
												Name="AccNumber"
												InputStyle={{ marginLeft: 2 }}
												LabelMd={0}
												Value={model.AccNumber == null ? "" : model.AccNumber}
												ValueChanged={this.handleChange}
												MaxLength={12} />
										</GridItem>
									</GridContainer>
								</GridItem>
								<GridItem xs={4}>
									<GenericDateInput
										Name="FromDate"
										LabelText="From"
										Value={model == null ? "" : model.FromDate ? DateHelper.ToDateInputValue(model.FromDate) : ""}
										ValueChanged={this.handleChange}
										MaxDate={model.ToDate == null ? DateHelper.GetDate() : model.ToDate}
										IncludeTime={false} />
									<GenericDateInput
										Name="ToDate"
										LabelText="To"
										Value={model == null ? "" : model.ToDate ? DateHelper.ToDateInputValue(model.ToDate) : ""}
										ValueChanged={this.handleChange}
										MaxDate={DateHelper.GetDate()}
										MinDate={model.FromDate}
										IncludeTime={false} />
									<GenericTextInput
										Name="CustomerNumber"
										LabelText="Customer Number"
										Value={model.CustomerNumber == null ? "" : model.CustomerNumber}
										ValueChanged={this.handleChange} />
									<GenericTextInput
										Name="NameAccountOwner"
										LabelText="Wallet Owner"
										Value={model.NameAccountOwner == null ? "" : model.NameAccountOwner}
										ValueChanged={this.handleChange} />
								</GridItem>
							</GridContainer>
						</CardBody>
					</GenericExpansionPanel>
				</Card>
				<Card className="no-radius">
					<CardBody>
						<GridItem xs={12}>
							<GridItem>
								<CDICList
									propIsBackoffice={this.state.isBackOffice}
									propCDICList={this.state.CDICList}
									propCDICTotalInfo={this.state.CdicTotalInfo}
									propGetAllData={this.GetAllData}
									history={this.props.history} />
							</GridItem>
						</GridItem>
						<br /> <br />
					</CardBody>
				</Card>
			</div>
		);
	}
}

CDICAccounts.propTypes = {
	classes: PropTypes.object,
	handleOperationType: PropTypes.func,
	SelectedRowChange: PropTypes.func,
	showQuestionMessage: func,
	setAppLeftTitle: func,
	setAppCenterTitle: func,
	setPortal: func,
	menuId: PropTypes.number,
	ApprovalData: PropTypes.array,
	After: func,
	Disabled: PropTypes.bool
};

export default withArtifex(CDICAccounts, styles);