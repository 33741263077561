import withStyles from "@material-ui/core/styles/withStyles";
import tabComponentStyle from "assets/jss/material-dashboard-pro-react/views/tabComponentStyle";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";

class TabsComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			active: 0 // will set later
		};

		this.handleChange = this.handleChange.bind(this);
	}

	componentDidMount() {
		if (this.props.ActiveIndex != null) {
			this.setState({ active: this.props.ActiveIndex }, () => console.log("tab ", this.state));
		} else {
			this.setState({ active: 0 }, () => console.log("tab ", this.state));
		}
	}

	handleChange(active) {
		this.setState({ active });
		if (this.props.tabIndexChanged) this.props.tabIndexChanged(active);
	}

	render() {
		const { tabList } = this.props;

		const tabButtons = (
			<TabList style={{ fontSize: "12px", fontWeight: 500, lineHeight: "20px", color: "inherit" }}>
				{tabList.filter(x => !x.Hidden).map((prop, key) => {
					return (
						<Tab key={key} disabled={prop.disabled || false}>{prop.tabButton}</Tab>
					);
				})}
			</TabList>
		);
		const tabContent = (
			<div>
				{tabList.filter(x => !x.Hidden).map((prop, key) => {
					return (
						<TabPanel key={key}>
							{prop.tabContent}
						</TabPanel>
					);
				})}
			</div>
		);
		return (
			<Tabs
				selectedIndex={this.state.active}
				onSelect={(active => this.handleChange(active))}
			>
				{tabButtons}
				{tabContent}
			</Tabs >
		);
	}
}

TabsComponent.propTypes = {
	classes: PropTypes.object.isRequired,
	tabList: PropTypes.array,
	ActiveIndex: PropTypes.number,
	tabIndexChanged: PropTypes.func
};

export default withStyles(tabComponentStyle)(TabsComponent);