const ParameterLOSPaymentFrequency = {
	ParameterCode: "LOSPaymentSchedule"
};

const ParameterLOSPaymentMethod = {
	ParameterCode: "LOSPaymentMethod"
};

const ParameterPaymentOptionFee = {
	ParameterCode: "LOSPayOption"
};

const ParameterYesNo = {
	ParameterCode: "LOSYesNo"
};

const ParameterLoanPurpose = {
	ParameterCode: "LOSLoanPurpose"
};

const ParameterLOSRateOption = {
	ParameterCode: "LOSRateOption"
};

const ParameterDocumentVerifiedStatusType = {
	ParameterCode: "LOSVerifiedStatus"
};

const VerifyingPersonParameterCode = {
	ParameterCode: "IdentityVerifyingPerson"
};

const ParameterDocumentType = {
	ParameterCode: "LOSDocumentType"
};

export {
	ParameterDocumentType,
	ParameterLOSPaymentFrequency,
	ParameterLOSPaymentMethod,
	ParameterPaymentOptionFee,
	ParameterYesNo,
	ParameterLoanPurpose,
	ParameterLOSRateOption,
	ParameterDocumentVerifiedStatusType,
	VerifyingPersonParameterCode
};