import { Dialog, DialogContent, withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { GenericCircularProgress } from "views/Components/Generic";

const loadingComponentStyle = {
	dialogContentStyle: {
		overflowX: "hidden"
	}
};

function LoadingComponent(props) {
	return (
		<Dialog open={props.Show}>
			<DialogContent className={props.classes.dialogContentStyle}>
				<GenericCircularProgress TaskPoolDomain={props.TaskPoolDomain} />
			</DialogContent>
		</Dialog>
	);
}

LoadingComponent.propTypes = {
	classes: PropTypes.object.isRequired,
	Show: PropTypes.bool,
	TaskPoolDomain: PropTypes.object
};

LoadingComponent.defaultProps = {
	Show: false
};

export default withStyles(loadingComponentStyle)(LoadingComponent);