// custom components
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React from "react";
import CommissionProfileComponent from "views/Components/CommissionProfileComponent";
import { GenericAlert, GenericDateInput, GenericRadioInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import { OperationType } from "views/Constants/Constant";

class CommissionProfileDetailAddOrUpdate extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			model: this.props.model,
			operationType: OperationType.DEFAULT,
			vModel: {}
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleBooleanChange = this.handleBooleanChange.bind(this);

		this.handleSubmit = this.handleSubmit.bind(this);
		this.successSubmitCallback = this.successSubmitCallback.bind(this);
		this.errorSubmitCallback = this.errorSubmitCallback.bind(this);

		this.selectProfileCallback = this.selectProfileCallback.bind(this);

		this.Validate = this.Validate.bind(this);
	}

	componentDidMount() {
		const model = { ...this.state.model };
		if (model.AgreementSignDate !== undefined) {
			model.AgreementSignDate = DateHelper.ToMoment(model.AgreementSignDate);
		}
		this.setState({ model });
	}

	handleChange(name, value) {
		const model = { ...this.state.model };
		model[name] = value;
		this.setState({ model });
	}

	handleBooleanChange(name, value) {
		const model = { ...this.state.model };
		model[name] = JSON.parse(value);
		this.setState({ model });
	}

	handleSubmit() {
		if (!this.Validate())
			return;

		Proxy.POST(
			"/bankapi/v1.0/CommissionProfileDetail/InsertOrUpdate",
			this.state.model,
			this.successSubmitCallback,
			this.errorSubmitCallback
		);
	}

	successSubmitCallback(responseData) {
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error", responseData.ErrorDescription);
			return;
		}
		this.props.handleGetList();
		this.ShowMessage("success", "Success", "Saved succesfully!");
	}

	errorSubmitCallback(error) {
		this.ShowMessage("error", "Error", "An error occurred during the submit data\n\n" + error);
	}

	selectProfileCallback(profile) {
		const model = { ...this.state.model };
		model.ProfileId = profile.Id;
		model.ProfileName = profile.ProfileName;
		this.setState({ model });
	}

	ShowMessage(type, title, message) {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode(type, title, message) {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	Validate() {
		const { model,vModel } = this.state;

		if (!model.AgreementTypeId) {
			vModel.AgreementTypeId=true;
			this.ShowMessage("warning", "Agreement Type not selected", "Select Agreement Type to continue.");
			return false;
		}else { vModel.AgreementTypeId = false; }

		if (!model.UniqueClientId) {
			vModel.UniqueClientId=true;
			this.ShowMessage("warning", "UniqueClient not selected", "Select Customer to continue.");
			return false;
		}else{vModel.UniqueClientId=false;}

		if (!model.AgreementSignDate) {
			vModel.AgreementSignDate=true;
			this.ShowMessage("warning", "Agreement Sign Date is empty", "Enter Agreement Sign Date to continue.");
			return false;
		}else{vModel.AgreementSignDate=false;}

		if (!model.FirstMonthCommissionRate) {
			vModel.FirstMonthCommissionRate=true;
			this.ShowMessage("warning", "First Month Commission Rate is empty", "Enter First Month Commission Rate to continue.");
			return false;
		}else{vModel.FirstMonthCommissionRate=false;}

		if (!model.FollowingCommissionRate) {
			vModel.FollowingCommissionRate=true;
			this.ShowMessage("warning", "Following Commission Rate is empty", "Enter Following Commission Rate to continue.");
			return false;
		}else{vModel.FollowingCommissionRate=false;}

		return true;
	}

	render() {
		const { classes } = this.props;
		const { model, vModel } = this.state;
		return (
			<div>
				{this.state.alert}
				<GridContainer spacing={16}>
					<GridItem md={12} xs={12}>
						<GridContainer>
							<GridItem xs={12} sm={4} />
							<GridItem xs={12} sm={8}>
								<GridContainer justify="flex-end">
									<GridItem md={2} xs={12}>
										<Button
											size="sm"
											onClick={() => this.props.handleOperationType(OperationType.DEFAULT)}
											fullWidth
										>
											Back
										</Button>
									</GridItem>
									<GridItem md={2} xs={12}>
										<Button
											size="sm"
											onClick={this.handleSubmit}
											fullWidth
										>
											Submit
										</Button>
									</GridItem>
								</GridContainer>
							</GridItem>
						</GridContainer>
						<GridContainer spacing={16}>
							<GridItem md={4} xs={12}>
								<CommissionProfileComponent
									profileId={model.ProfileId}
									profileName={model.ProfileName}
									FillCallback={this.selectProfileCallback}
									handleChange={this.handleChange} />
								<h5>
									<b>Sales Person Referall Commission</b>
								</h5>
								<GridItem md={12} xs={12}>
									<GridItem>
										<GenericRadioInput
											Name="UseStandartRate"
											LabelText="Rate Type"
											IsStatic={true}
											StaticData={[{ Value: true, Text: "Standard Rate" }, { Value: false, Text: "Customize Rate" }]}
											KeyValueMember="Value"
											TextValueMember="Text"
											Value={model.UseStandartRate}
											ValueChanged={this.handleBooleanChange} />
									</GridItem>
									<GridItem>
										<GenericSelectInput
											Name="AgreementTypeId"
											LabelText="Agreement Type"
											Method="GET"
											Url="/bankapi/v1.0/Bank/ValidBankPrograms"
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember="Description"
											Value={model.AgreementTypeId}
											ValueChanged={this.handleChange}
											Required
											IsInvalid={vModel.AgreementTypeId}
										/>
									</GridItem>
									<GridItem md={6} xs={12}>
										<GenericSelectInput
											Name="UniqueClientId"
											LabelText="Client"
											Method="POST"
											Url="/bankapi/v1.0/BankCustomer/Search"
											Parameter={{}}
											DataRoot="Item"
											KeyValueMember="Id"
											RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
											Value={model.UniqueClientId || ""}
											ValueChanged={this.handleChange}
											CanClear
											Required
											IsInvalid={vModel.UniqueClientId}
										/>
									</GridItem>
									<GridItem md={6} xs={12}>
										<GenericDateInput
											Name="AgreementSignDate"
											LabelText="Agreement Sign Date"
											Value={model.AgreementSignDate ? DateHelper.ToDateInputValue(model.AgreementSignDate) : null}
											ValueChanged={this.handleChange}
											Required
											IsInvalid={vModel.AgreementSignDate}
										/>
									</GridItem>
								</GridItem>
							</GridItem>
							<GridItem md={4} xs={12}>
								<GridItem>
									<GenericTextInput
										Name="FirstMonthCommissionRate"
										LabelText="Commission Rate (First 1 Month)"
										Value={model.FirstMonthCommissionRate}
										ValueChanged={this.handleChange}
										Required
										IsInvalid={vModel.FirstMonthCommissionRate} 
										/>
								</GridItem>
								<GridItem>
									<GenericTextInput
										Name="FollowingCommissionRate"
										LabelText="Commission Rate (Following Month)"
										Value={model.FollowingCommissionRate}
										ValueChanged={this.handleChange}
										Required
										IsInvalid={vModel.FollowingCommissionRate} 
										/>
								</GridItem>
							</GridItem>
						</GridContainer>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

CommissionProfileDetailAddOrUpdate.propTypes = {
	classes: PropTypes.object,
	model: PropTypes.object,
	handleGetList: PropTypes.func,
	handleOperationType: PropTypes.func
};

export default CommissionProfileDetailAddOrUpdate;
