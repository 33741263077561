import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
//import StringHelper from '../../core/StringHelper';

class CardTransferMemoDefinition extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined,
			ProgramId: null,
			CardTransferMemoDefinition: "",
			CardTransferMemoDefinitionAnswer: ""
		};

		this.state = {
			model: this.initialModel,
			list: [],
			isEdit: false,
			isLoading: false,
			index: -1
		};


		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined,
		};

		this.renderItemUniqueClient = {};

		this.maxLength40 = { maxLength: 40 };
		this.maxLength25 = { maxLength: 25 };

		this.Columns = [
			{
				Header: "Note",
				accessor: "Memo"
			}
		];
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Card Transfer Memo Definition");
		this.props.setAppCenterTitle("NOTIFICATIONS");
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}

	HandleClear = (keepUniqueClientId) => {
		var emptyModel = { ...this.initialModel };
		if (keepUniqueClientId === true) {
			emptyModel.UniqueClientId = this.state.model?.UniqueClientId;
		}
		this.setState({ model: emptyModel, isEdit: false, index: -1 });
	}

	HandleSearch = () => {

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/VisaDirect/GetMemosByClientId",
			this.initialModel.UniqueClientId,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", responseData.ErrorDescription);
					return;
				}
				this.setState({ list: responseData.Item || [] });

			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	HandleSubmit = () => {
		if (!this.Validate()) {
			return;
		}
		var model = { ...this.state.model };

		this.setState({ isLoading: true });
		Proxy.POST("/bankapi/v1.0/VisaDirect/CreateMemo",
			{ Memo: model.TransactionDescription },
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.HandleClear(true);
				this.ShowMessage("success", "Added successfully!");
				this.HandleSearch();
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	Validate = (forSearch) => {
		const { model } = this.state;
		var Messages = [];

		if (!model.UniqueClientId) {
			Messages.push("Client cannot be empty.");
			model.vUniqueClientId = true;
		} else {
			model.vUniqueClientId = false;
		}

		if (model.TransactionDescription == null || model.TransactionDescription == "") {
			model.vTransactionDescription = true;
			Messages.push("Note must be defined !");
		} else { model.vTransactionDescription = false; }


		if (Messages.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", Messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		else
			return true;
	}

	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}

	RowSelected = (index) => {
		var tempModel = { ...this.state.list[index] };
		tempModel.CardTransferMemoDefinitionAnswer = null;
		this.setState({ isEdit: true, model: tempModel, index });
	}


	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: null })} OnConfirm={onConfirm} />
		});
	}

	render() {
		const { Disabled } = this.props;
		const { alert, model, list, isLoading, index } = this.state;

		var IsClient = ClientHelper.IsClient();

		return (
			<div>

				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
					{ Code: "Submit", OnClick: this.HandleSubmit, Disabled: model.Id || Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Parameters" >
									<GridContainer>
										<GridItem xs={5}>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={this.parameterUniqueClientId}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={this.RenderItemUniqueClient}
												Value={model.UniqueClientId}
												ValueChanged={this.HandleChange}
												CanClear
												Disabled={IsClient}
												DefaultIndex={IsClient ? 0 : -1}
												Required
												IsInvalid={model.vUniqueClientId} />
										</GridItem>
										<GridItem xs={1}></GridItem>
										<GridItem xs={5}>
											<GenericTextInput
												Name="TransactionDescription"
												LabelText="Note"
												Value={model.TransactionDescription}
												ValueChanged={this.HandleChange}
												Required
												IsInvalid={model.vTransactionDescription}
											/>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Card Transfer Memo List">
									<GridContainer>
										<GridItem xs={12}>
											<br />
											<GenericGrid
												Data={list}
												Columns={this.Columns}
												RowSelected={this.RowSelected}
												SelectedIndex={index}
												HideButton={true}
											/>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

CardTransferMemoDefinition.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(CardTransferMemoDefinition, {});