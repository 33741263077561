import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import { DeleteIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType } from "views/Constants/Constant";

class GenerateApiToken extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			vModel: {},
			model: {},
			alert: null,
			isLoading: false,
			list: []
		};

		this.sortName = { Member: "Name" };
		this.EmptyParameter = {};
		this.ParameterChannel = { ParameterCode: "Channel", ParameterValue2: "T" };

		this.ColumnsData = [

			{
				Header: "Actions",
				accessor: "Status",
				Cell: row => (
					<div>
						<GridButton
							tooltip={row.value ? "" : "Black List Add"}
							Disabled={row.value == 3 || row.value == 2 ? true : false}
							Icon={DeleteIcon}
							OnClick={row.value == 3 || row.value == 2 ? () => { } : () => { this.HandleChangeActiveStatus(row.original); }} />

					</div>
				)
			},
			{
				Header: "Channel",
				accessor: "Channel.ParameterValue6"				
			},
			{
				Header: "Date",
				accessor: "InsertDateTime",
				type: GridColumnType.DateTime
			},
			{
				Header: "Status",
				accessor: "Status",
				Cell: row => {
					return (row.value == 1 ? "Active" : row.value == 2 ? "Expired" : row.value == 3 ? "In Black List" : "");

				}
			},
			{
				Header: "Client Name",
				accessor: "UniqueClient.ClientName"
			},
			{
				Header: "User Name",
				accessor: "ParentUser.UserName"
			},
			{
				Header: "User Email",
				accessor: "ParentUser.UserEmail"
			},			
			{
				Header: "Session Time In Minutes",
				accessor: "SessionTimeInMinutes"
			},

			{
				Header: "Token Expiry Date",
				accessor: "SessionExpiredDate",
				type: GridColumnType.DateTime
			},
			{
				Header: "Description",
				accessor: "Description"
			}

		];
	}

	componentDidMount() {
		if (this.props.setAppCenterTitle) {
			this.props.setAppCenterTitle("SYSTEM ADMIN");
		}

		if (this.props.setAppLeftTitle)
			this.props.setAppLeftTitle("Generate API Token");

	}
	ShowConfirmMessage = (type, title, message, onConfirm, showCancel = true) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={showCancel} OnCancel={() => this.setState({ alert: null })} OnConfirm={onConfirm} />
		});
	}
	HandleChangeActiveStatus = (model) => {
		this.ShowConfirmMessage("warning", "Warning",
			"Are you sure you want to add to the Black List?", () => {
				this.setState({ alert: null, isLoading: true });
				Proxy.POST(
					"/coreapi/v1.0/JwtTokenBlackList/SaveBlackListToken", this.state.model,
					responseData => {
						this.setState({ isLoading: false });
						if (!responseData.IsSucceeded) {
							this.props.showMessage("error", responseData.Code, responseData.ErrorDescription);
							return;
						}
						if (responseData.Item) {
							this.ClearData();
							this.Search();
							this.props.showMessage("success", "Success", "Success");
						} else {
							this.props.showMessage("error", "Error", "There was an error adding to the blacklist");
						}
					},
				);
			});
	}

	OperationCheck = () => {
		var ErrorList = [];
		var data = { ...this.state.model };
		const vModel = this.state.vModel;

		if (data.ParentUserId == null || data.ParentUserId == "") {
			vModel.ParentUserId = true;
			ErrorList.push("User Name must be defined!");
		} else { vModel.ParentUserId = false; }
		if (data.UniqueClientId == null || data.UniqueClientId == "") {
			vModel.UniqueClientId = true;
			ErrorList.push("Client Name must be defined!");
		} else { vModel.UniqueClientId = false; }

		if (data.ChannelType == "2") {
			if (data.ApiUserId == null || data.ApiUserId == "") {
				vModel.ApiUserId = true;
				ErrorList.push("Api User Name must be defined!");
			} else { vModel.ApiUserId = false; }
		}

		if (data.SessionTimeInMinutes == null || data.SessionTimeInMinutes == "") {
			vModel.SessionTimeInMinutes = true;
			ErrorList.push("Session Time In Minutes must be defined!");
		} else { vModel.SessionTimeInMinutes = false; }
		if (parseInt(data.SessionTimeInMinutes) > 4500000) {
			ErrorList.push("Session Time In Minutes cannot be greater than 4500000!");
		}

		if (ErrorList.length > 0) {
			this.props.showMessageNode("warning", "Please fill required fields!", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		this.setState({ vModel });
		this.Submit();
	}
	Search = () => {
		this.setState({ isLoading: true });
		var temp = { ...this.state.model };
		temp.SessionTimeInMinutes = 0;

		Proxy.POST(
			"/coreapi/v1.0/ApiUser/GetApiTokens",
			temp,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ isLoading: false });
				this.setState({ list: responseData.Item || [] });
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	Submit = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/coreapi/v1.0/ApiUser/SaveApiToken",
			this.state.model,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.props.showMessage("success", "Success", "The Operation Completed Successfully.");
				this.Search();
				var temp = responseData.Item || {};
				temp.ChannelType = temp?.Channel?.ParameterValue;
				this.setState({ model: temp});
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}
	handleChange = (name, newValue, data) => {
		this.setState(function (state, props) {
			var temp = state.model || {};
			if (name == "ChannelId") {
				temp.ChannelType = data?.ParameterValue || "";
				temp.UniqueClientId = 0;
				temp.ClientCustomerId = 0;
				temp.ParentUserId = 0;
				temp.ApiUserId = 0;
			}
			if (name == "UniqueClientId") {
				temp.ClientCustomerId = 0;
				temp.ParentUserId = 0;
				temp.ApiUserId = 0;
			}
			if (name == "ParentUserId") {
				temp.ApiUserId = 0;
				temp.ClientCustomerId = data?.ClientCustomerId || 0;
			}
			if(name =="ApiUserId"){
				temp.ParentUserId = data?.ParentUserId || 0;
			}
			temp[name] = newValue;
			return { model: temp };
		});
	}
	RenderClientNameSelect = (d) => {
		return d.Name + " - " + d.UniqueClientId;
	}
	GetUserParameterUniqueClientId = () => {
		return { UniqueClientId: this.state.model.UniqueClientId };
	}
	GetCustomerParameterUniqueClientId = () => {
		return { UniqueClientId: this.state.model.UniqueClientId, ClientCustomerId: this.state.model.ClientCustomerId };
	}
	ApiUserParameter = () => {
		return { UniqueClientId: this.state.model.UniqueClientId, ParentUserId: this.state.model.ParentUserId };
	}
	RenderUserNameSelect = (d) => {
		return d.FirstName + " " + d.LastName + " (" + d.UserName + ")";
	}
	RenderApiUserNameSelect = (d) => {
		return d.UserName + " (" + d.User.UserName + ")";
	}
	SelectedRowChange = (e, index) => {
		const model = this.state.list[index];
		model.ChannelType = model?.Channel?.ParameterValue;
		this.setState({ model: model || {} });
	}
	ClearData = () => {
		this.setState({
			model: {},
			alert: null,
			isLoading: false,
			list: []
		});
	}
	render() {
		const { Disabled } = this.props;
		const { model, list, vModel } = this.state;

		return (
			<div>
				{this.state.alert}

				<LoadingComponent Show={this.state.isLoading} />

				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.Search, Disabled: Disabled },
					{ Code: "Submit", OnClick: this.OperationCheck, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.ClearData, Disabled: Disabled },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />


				<Card className="no-radius">
					<CardBody>
						<b><p style={{ paddingTop: "20px", marginLeft: "10px" }}> Generate Api Token </p></b>
						<GridContainer justify="flex-start">
							<GridItem xs={4}>
								<GenericSelectInput
									Disabled={Disabled}
									Name="ChannelId"
									LabelText="Channel"
									Method="POST"
									Url="/coreapi/v1.0/Parameter/Search"
									Parameter={this.ParameterChannel}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="ParameterValue6"
									Value={model.ChannelId}
									Required
									ValueChanged={this.handleChange}
									IsInvalid={vModel.ChannelId}
								/>
								<GenericSelectInput
									Disabled={Disabled}
									Name="UniqueClientId"
									LabelText="Client Name"
									Value={model.UniqueClientId}
									DataRoot="Item"
									ValueChanged={this.handleChange}
									KeyValueMember="Id"
									TextValueMember="Name"
									Url="/bankapi/v1.0/BankCustomer/Search"
									Method="POST"
									Parameter={this.EmptyParameter}
									RenderItem={this.RenderClientNameSelect}
									Required
									IsInvalid={vModel.UniqueClientId}
								/>
								{model.ChannelType == "2" && (
									<>
										<GenericSelectInput
											key={"fe" + model.UniqueClientId}
											Disabled={Disabled}
											Name="ParentUserId"
											LabelText="User Name"
											Value={model.UniqueClientId ? model.ParentUserId : ""}
											DataRoot="Item"
											ValueChanged={this.handleChange}
											KeyValueMember="Id"
											TextValueMember="Name"
											Url="/coreapi/v1.0/User/GetAllByUniqueClientId"
											Method="POST"
											Parameter={this.GetUserParameterUniqueClientId()}
											RenderItem={this.RenderUserNameSelect}
											Required
											IsInvalid={vModel.ParentUserId}
										/>
										<GenericSelectInput
											key={"fe" + model.UniqueClientId + "de" + model.ParentUserId}
											Disabled={Disabled}
											Name="ApiUserId"
											LabelText="API User Name"
											Value={model.UniqueClientId ? model.ApiUserId : ""}
											DataRoot="Item"
											ValueChanged={this.handleChange}
											KeyValueMember="Id"
											TextValueMember="Name"
											Url="/coreapi/v1.0/ApiUser/GetApiUser"
											Method="POST"
											Parameter={this.ApiUserParameter()}
											RenderItem={this.RenderApiUserNameSelect}
											Required
											IsInvalid={vModel.ApiUserId}
										/>
									</>
								)}
								{model.ChannelType == "4" && (
									<>
										<GenericSelectInput
											key={"wCus_" + model.UniqueClientId}
											Name="ClientCustomerId"
											LabelText="Customer Name"
											Method="POST"
											Url="/bankapi/v1.0/ClientCustomer/SearchNameValue"
											Parameter={this.GetUserParameterUniqueClientId()}
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember="Name"
											Value={model.ClientCustomerId}
											ValueChanged={this.handleChange}
											Sorted={this.sortName}
										/>
										<GenericSelectInput
											key={"fwee" + model.UniqueClientId + model.ClientCustomerId}
											Disabled={Disabled}
											Name="ParentUserId"
											LabelText="User Name"
											Value={model.UniqueClientId ? model.ParentUserId : ""}
											DataRoot="Item"
											ValueChanged={this.handleChange}
											KeyValueMember="Id"
											TextValueMember="Name"
											Url="/coreapi/v1.0/User/GetAllCustomerUserByUniqueClientId"
											Method="POST"
											Parameter={this.GetCustomerParameterUniqueClientId()}
											RenderItem={this.RenderUserNameSelect}
											Required
											IsInvalid={vModel.ParentUserId}
										/>
									</>
								)}

								<GenericTextInput
									Disabled={Disabled}
									Name="Description"
									LabelText="Description"
									Value={model.Description || ""}
									ValueChanged={this.handleChange}
								/>
								<GenericNumberInput
									NoFormatting={true}
									Name="SessionTimeInMinutes"
									LabelText="Session Time In Minutes"
									Value={model.SessionTimeInMinutes}
									ValueChanged={this.handleChange}
									MaxLength={7}
									Required
									IsInvalid={vModel.SessionTimeInMinutes}
								/>
							</GridItem>
							<GridItem xs={8} >
								<GenericTextInput
									LabelMd={2}
									Disabled={true}
									Name="Token"
									LabelText="Token"
									MultiLine={true}
									RowCount={9}
									Value={model.Token || ""}
								/>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>


				<Card className="no-radius" >
					<b><p style={{ paddingTop: "20px", marginLeft: "10px" }}> Generated Tokens </p></b>
					<CardBody>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={list}
									Columns={this.ColumnsData}
									RowSelected={index => {
										this.SelectedRowChange(0, index);
									}}
								/>
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>



			</div>
		);
	}
}

GenerateApiToken.propTypes = {
	classes: PropTypes.object
};

export default withArtifex(GenerateApiToken, {});