import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import DDTCustomerInfo from "views/DDT/SearchTabs/DDTCustomerInfo.jsx";
import GridButton from "views/Components/GridButton.jsx";
import AlertHelper from "core/AlertHelper";
import { EditIcon } from "core/Icons";
import PropTypes, { array, bool, func, number } from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import ToolBar from "views/Components/DynamicToolbar";
import { GenericAlert, GenericDateInput, GenericExpansionPanel, GenericGrid, GenericLabel, GenericNumberInput, GenericRadioInput, GenericDialogActions, GenericSelectInput, GenericTextInput, GenericDialog } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { DefaultBankInformation, Portals, GridColumnType, ProgramCodes, DDTStatus } from "views/Constants/Constant.js";
import Formatter from "core/Formatter";
import { DialogTitle, DialogContent } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import Select from "react-select";


const styles = ({
	GridItemFreePadding: {
		padding: "0px 5px !important"
	},
	GridItemTextFreePadding: {
		padding: "0px 3px !important"
	}
});
class DDTAccounts extends Component {
	constructor(props) {
		super(props);

		this.initalModel = {
			FromDate: DateHelper.GetDate(),
			ToDate: DateHelper.GetDate(),
			StatusId: DDTStatus.Active,
			UniqueClientId: "",
		};
		this.state = {
			model: { ...this.initalModel },
			poolModel: {},
			vModel: {},
			IsEmailValid: true,
			toolBarModel: {},
			DDTList: [],
			DdtTotalInfo: {},
			isLoading: false,
			isBackOffice: ClientHelper.IsBackOffice(),
			ddtModel: {
				Id: 0,
				Name: "",
				Email: "",
				Phone: {},
				ddtModel: DDTStatus.Active
			},
			Phone: {
				Id: 0,
				PhoneCountryCodeId: 0,
				PhoneNumber: ""
			},
			openEditDialog: false,
		};
		this.getappCenterTitle = "Empty";
		this.getappLeftTitle = "Empty";
		this.handleChange = this.handleChange.bind(this);
		this.GetAllData = this.GetAllData.bind(this);
		this.errorCallback = this.errorCallback.bind(this);
		this.hideAlert = this.hideAlert.bind(this);
		this.showErrorMessage = this.showErrorMessage.bind(this);
		this.successCallback = this.successCallback.bind(this);
		this.handleEditChange = this.handleEditChange.bind(this);
		this.trxTable = React.createRef();
		const isBackOffice = this.state.isBackOffice;
		this.columns = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: d => (
					<div>
						<GridButton
							tooltip="Edit"
							Icon={EditIcon}
							OnClick={() => this.OpenEditModal(d.original)} />
					</div>
				),
				sortable: false,
				filterable: false,
				width: 60,
				show: !isBackOffice
			},
			{
				Header: "Unique Client Id",
				accessor: "UniqueClientId",
				show: !isBackOffice
			},
			{
				Header: "Client Name",
				accessor: "ClientName",
				show: isBackOffice
			},
			{
				Header: "Wallet Owner",
				accessor: "AccountOwner",
				show: false
			},
			{
				Header: "Customer Number",
				accessor: "CustomerNumber"
			},
			{
				Header: "Customer Name",
				accessor: "CustomerName"
			},
			{
				Header: "Transit Number",
				accessor: "TransitNumber"
			},
			{
				Header: "DDT Number",
				accessor: "DDTNumber"
			},
			{
				Header: "Issued Date",
				accessor: "IssuedDate",
				type: GridColumnType.Date
			},
			{
				Header: "ExpireDate",
				accessor: "ExpireDate",
				type: GridColumnType.Date
			},
			{
				Header: "Status",
				accessor: "Status"
			},
			{
				Header: "Balance",
				accessor: "Balance",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "SIN",
				accessor: "SIN",
				width: 100
			},
			{
				Header: "First Name",
				accessor: "Name"
			},
			{
				Header: "Middle Name",
				accessor: "MiddleName"
			},
			{
				Header: "Last Name",
				accessor: "LastName"
			},
			{
				Header: "Birth Date",
				accessor: "DateOfBirth",
				type: GridColumnType.DateUtc
			},
			{
				Header: "Phone Number",
				accessor: "PhoneNumber",
			},
			{
				Header: "E-Mail",
				accessor: "Email",
				width: 160
			},
			{
				Header: "Address Line 1",
				accessor: "AddressLine1"
			},
			{
				Header: "Address Line 2",
				accessor: "AddressLine2"
			},
			{
				Header: "City",
				accessor: "City"
			},
			{
				Header: "Province",
				accessor: "Province"
			},
			{
				Header: "Postal Code",
				accessor: "ZipCode"
			},
			{
				Header: "Country",
				accessor: "Country"
			},
			{
				Header: "Auto Direct Deposit E-Mail",
				//accessor: "DirectDepositEmail"
			},
			{
				Header: "Direct Deposit Registration Status",
				accessor: "DirectDepositRegistrationStatus",
				Cell: row => (
					Formatter.FormatYesNo(row.value)
				)
			},
			{
				Header: "Direct Deposit Reference Number",
				accessor: "DirectDepositReferenceNumber"
			}
		];
	}

	componentDidMount() {
		var { isBackOffice } = this.state;
		var model = { ...this.state.model };

		if (!isBackOffice) {
			this.GetToolbarData();
			this.initalModel.UniqueClientId = ClientHelper.GetClientRowId();
		}
		model = { ...this.initalModel };
		this.props.setAppLeftTitle("DDT List");
		this.props.setAppCenterTitle("DDT MANAGEMENT");
		this.props.setPortal(Portals.DDT);
		this.setState({ model, isBackOffice, loadingCompleted: true });
		this.getappCenterTitle = "DDT List";
		this.getappLeftTitle = "DDT MANAGEMENT";
 	}

	handleChange(name, newValue) {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}

	handleEditChange(name, newValue, data) {
		const ddtModel = { ...this.state.ddtModel };
		var IsEmailValid = this.state.IsEmailValid;
		const Phone = { ...this.state.Phone };
		if (name == "PhoneCountryCodeId" || name == "PhoneNumber")
			Phone[name] = newValue;
		else
			ddtModel[name] = newValue;

		if (name == "Email") {
			IsEmailValid = newValue == "" ? true : data.IsValid;
		}

		this.setState({ ddtModel, Phone, IsEmailValid });
	}

	ValueChanged = (name, value) => {
		this.setState({ [name]: value });
	}

	GetAllData() {

		if (this.state.poolModel.BranchId == null || this.state.poolModel.AccountNumber == null) {
			this.setState(state => ({ trxGridToken: !state.trxGridToken }));
		} else {
			if (this.state.poolModel.BranchId == "" || this.state.poolModel.AccountNumber == "")
				this.showErrorMessage("No DDT program definition has been made in your name. Please contact the bank manager.");
			else {
				this.setState(state => ({ trxGridToken: !state.trxGridToken }));
			}
		}

	}

	CalculateDdtTotalInfo = async (newData) => {
		const info = {
			Active: 0,
			Inactive: 0,
			Total: 0,
			Balance: 0,
		};

		Proxy.GET(
			"/bankapi/v1.0/Ddt/GetDdtTotalInfo",
			responseData => {
				if (!responseData.IsSucceeded) {
					console.log("Error!");
					return;
				}

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					info.Active = responseData.Item.Active;
					info.Inactive = responseData.Item.Inactive;
					info.Balance = responseData.Item.Balance;
					info.Total = info.Active + info.Inactive;
				}
				this.setState({ DdtTotalInfo: info });
			},
			e => {
				console.log("Error!");
			}
		);
		
		this.setState({ DdtTotalInfo: info });
	}

	GetToolbarData = () => {
		this.showLoading();
		Proxy.GET(
			"/bankapi/v1.0/Ddt/GetAllDDTCDICNumberAndBalance",
			this.successCallback,
			this.errorCallback
		);
	}

	successCallback(responseData) {
		var toolBarModel = { ...this.state.toolBarModel };
		var poolModel = { ...this.state.poolModel };

		this.initalModel.PrimaryAccountId = responseData.Item == null || undefined ? "" : responseData.Item.DDTPoolAccountInfo == null || undefined ? 0 : responseData.Item.DDTPoolAccountInfo.Id == null || undefined ? 0 : responseData.Item.DDTPoolAccountInfo.Id;
		this.initalModel.PrimaryBranchId = responseData.Item == null || undefined ? "" : responseData.Item.DDTPoolAccountInfo == null || undefined ? "" : responseData.Item.DDTPoolAccountInfo.BranchId == null || undefined ? "" : responseData.Item.DDTPoolAccountInfo.BranchId;
		this.initalModel.PrimaryAccountNumber = responseData.Item == null || undefined ? "" : responseData.Item.DDTPoolAccountInfo == null || undefined ? "" : responseData.Item.DDTPoolAccountInfo.AccountNumber == null || undefined ? "" : responseData.Item.DDTPoolAccountInfo.AccountNumber;

		toolBarModel.TotalDDTNumber = responseData.Item == null || undefined ? 0 : responseData.Item.TotalDDTNumber == null || undefined ? 0 : responseData.Item.TotalDDTNumber;
		toolBarModel.TotalDDTBalance = responseData.Item == null || undefined ? 0 : responseData.Item.DDTPoolAccountInfo == null || undefined ? 0 : responseData.Item.DDTPoolAccountInfo.Balance == null || undefined ? 0 : responseData.Item.DDTPoolAccountInfo.Balance;
		toolBarModel.TotalCDICNumber = responseData.Item == null || undefined ? 0 : responseData.Item.TotalCDICNumber == null || undefined ? 0 : responseData.Item.TotalCDICNumber;
		toolBarModel.TotalCDICBalance = responseData.Item == null || undefined ? 0 : responseData.Item.CDICPoolAccountInfo == null || undefined ? 0 : responseData.Item.CDICPoolAccountInfo.Balance == null || undefined ? 0 : responseData.Item.CDICPoolAccountInfo.Balance;

		this.setState({ model: { ...this.initalModel }, toolBarModel, poolModel, isLoading: false });
	}

	errorCallback(error) {
		this.showErrorMessage("An error occurred during the api visit" + error);
		this.hideLoading();
	}

	showErrorMessage(message) {
		this.setState({
			DDTList: [],
			DdtTotalInfo: null,
			isLoading: false,
			alert: <GenericAlert Title={"Error"} MessageNode={message} Type={"warning"} OnConfirm={() => this.hideAlert()} />
		});
	}

	ExportClick = () => {
		var trxColumns = [];
		this.trxTable.current.props.Columns.forEach(c => {
			if (c.accessor != "Actions" && c.accessor != "ED" && (c.show == undefined || c.show)) {
				trxColumns.push({ value: c.accessor, title: c.Header, columntype: c.ColumnType, type: c.type });
			}
		}, this);

		this.setState({ isDownloadDialogOpen: true, GridKeyValueList: trxColumns, ExportSelectedColumns: trxColumns });
	}

	ExcelDownload = () => {
		if (!this.state.ExportReportType) {
			this.props.showMessage("warning", "Export Report Type not selected", "Select Export Report Type to continue");
			return;
		}

		if (this.state.ExportReportType == "PDF" && this.state.ExportSelectedColumns.length > 15) {
			this.props.showMessage("warning", "PDF document cannot exceed 15 columns", "PDF document cannot exceed 15 columns");
			return;
		}

		this.setState({ isDownloadDialogOpen: false });


		const temp = { ...this.state.model };

		var jsonColList = [];

		var trxColumns = {};
		this.state.ExportSelectedColumns.map(c => {
			trxColumns[c.value] = { value: c.value, title: c.title, columntype: c.columntype, type: c.type };
		});
		jsonColList.push(trxColumns);

		var mytitle = "DDT List";

		temp.jsonFile = {
			colmns: jsonColList,
			format: this.state.ExportReportType,
			title: mytitle
		};

		this.setState({ isLoading: true });
		Proxy.DownloadGeneratedFile(
			"/bankapi/v1.0/Ddt/DdtSearchExportDownload",
			temp,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", errorMessage);
			},
			this.props.showMessage
		);
	}

	OpenEditModal(item) {
		var ddtModel = { ...this.state.ddtModel };
		var Phone = { ...this.state.Phone };
		var model = { ...this.state.model };
		model.FinInsId = this.props.propFinInsId == null ? 0 : this.props.propFinInsId;
		model.FinInsBranchId = item.FinInsBranchId == null ? 0 : item.FinInsBranchId;
		model.AccNumber = item.AccNumber == null ? 0 : item.AccNumber;
		model.DetailId = item.Id == null ? 0 : item.Id;
		model.CustomerNumber = item.ClientCustomer == null ? "" : item.ClientCustomer.CustomerNumber;
		model.CustomerName = item.ClientCustomer == null ? "" : item.ClientCustomer.CustomerName;
		ddtModel.Id = item.Id;
		ddtModel.SIN = item.SIN == null ? "" : item.SIN;
		ddtModel.Name = item.Name == null ? "" : item.Name;
		ddtModel.MiddleName = item.MiddleName == null ? "" : item.MiddleName;
		ddtModel.LastName = item.LastName == null ? "" : item.LastName;
		ddtModel.Email = item.Email == null ? "" : item.Email;
		ddtModel.DateOfBirth = item.DateOfBirth == null ? "" : item.DateOfBirth;
		ddtModel.AddressLine1 = item.AddressLine1 == null ? "" : item.AddressLine1;
		ddtModel.AddressLine2 = item.AddressLine2 == null ? "" : item.AddressLine2;
		ddtModel.ZipCode = item.ZipCode == null ? "" : item.ZipCode;
		ddtModel.CountryId = item.CountryId == null ? "" : item.CountryId;
		ddtModel.ProvinceId = item.ProvinceId == null ? "" : item.ProvinceId;
		ddtModel.CityId = item.CityId == null ? "" : item.CityId;
		ddtModel.AccountId = item.AccountId == null ? 0 : item.AccountId;
		ddtModel.StatusId = item.StatusId; /*item.StatusId == null ? DDTStatus.Active : item.StatusId;*/
		Phone.Id = item.Phone == null ? 0 : item.Phone.Id == null ? 0 : item.Phone.Id;
		Phone.PhoneCountryCodeId = item.Phone == null ? "" : item.Phone.PhoneCountryCodeId == null ? "" : item.Phone.PhoneCountryCodeId;
		Phone.PhoneNumber = item.Phone == null ? "" : item.Phone.PhoneNumber == null ? "" : item.Phone.PhoneNumber;
		this.setState({ ddtModel, openEditDialog: true, Phone, model });
	}


	handleClose = () => {
		this.setState({
			openEditDialog: false
		});
	}

	EditDDT = () => {
		const ddtModel = { ...this.state.ddtModel };
		ddtModel.Phone = this.state.Phone;
		this.setState({ ddtModel });

		if (!this.Validate())
			return;

		this.showLoading();
		Proxy.POST(
			"/bankapi/v1.0/Ddt/UpdateDdtDetail",
			ddtModel,
			this.successEditCallBack,
			this.errorCallback
		);
	}

	successEditCallBack = (responseData) => {
		const model = { ...this.state.model };
		model.StatusId = this.state.ddtModel.StatusId;

		this.setState({ isLoading: false, openEditDialog: false });

		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error", responseData.ErrorDescription);
			return;
		}

		this.setState({ model, alert: AlertHelper.CreateAlert("Success", "DDT Number Information successfully edited.", "success", this.hideAlert) });
		this.GetAllData();
	}

	Validate = () => {
		const { ddtModel, vModel } = this.state;

		var messages = [];

		vModel.Name = ddtModel.Name == null || ddtModel.Name == "";
		if (vModel.Name) messages.push("Name must be entered");

		vModel.LastName = ddtModel.LastName == null || ddtModel.LastName == "";
		if (vModel.LastName) messages.push("Last Name must be entered");

		vModel.Email = !this.state.IsEmailValid;
		if (vModel.Email) messages.push("E-Mail is invalid");

		// vModel.DateOfBirth = typeof ddtModel.DateOfBirth != "object" || ddtModel.DateOfBirth == "" || ddtModel.DateOfBirth == null;
		// if (vModel.EndDate) messages.push("Birth Date must be selected");

		var interval = DateHelper.GetDate().diff(ddtModel.DateOfBirth, "years");
		if (ddtModel.DateOfBirth && interval < 18) {
			messages.push("Date of Birth must be bigger than 18");
		}

		vModel.AddressLine1 = ddtModel.AddressLine1 == null || ddtModel.AddressLine1 == "";
		if (vModel.AddressLine1) messages.push("Address Line 1 must be entered");

		vModel.ZipCode = ddtModel.ZipCode == null || ddtModel.ZipCode == "";
		if (vModel.ZipCode) messages.push("Postal Code must be entered");

		vModel.CountryId = ddtModel.CountryId == null || ddtModel.CountryId == 0;
		if (vModel.CountryId) messages.push("Country must be selected");

		vModel.ProvinceId = ddtModel.ProvinceId == null || ddtModel.ProvinceId == 0;
		if (vModel.ProvinceId) messages.push("Province must be selected");

		vModel.CityId = ddtModel.CityId == null || ddtModel.CityId == 0;
		if (vModel.CityId) messages.push("City must be selected");

		if (messages.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.setState({ vModel });
		return true;
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	hideAlert() {
		this.setState({ alert: null });
	}

	showLoading = () => {
		this.setState({ isLoading: true });
	}

	ClearModel = () => {
		this.setState({ model: { ...this.initalModel } });
	}

	hideLoading = () => {
		this.setState({ isLoading: false });
	}

	render() {
		const { model, toolBarModel, isLoading, poolModel, isBackOffice, DdtTotalInfo } = this.state;
		const { Disabled, classes } = this.props;
		return (
			<div>
				{this.state.alert}


				<LoadingComponent Show={isLoading} />

				{!isBackOffice &&
					<div>
						<ToolBar
							IsNumber
							ItemList={[
								{ Label: "Total No. of DDT", Value: toolBarModel.TotalDDTNumber == null || undefined ? 0 : toolBarModel.TotalDDTNumber + "" },
								{ Label: "Total No. of DDT - CDIC", Value: toolBarModel.TotalCDICNumber == null || undefined ? 0 : toolBarModel.TotalCDICNumber + "" },
								{ Label: "DDT Wallet Balance", Value: toolBarModel.TotalDDTBalance == null || undefined ? 0 : toolBarModel.TotalDDTBalance },
								{ Label: "DDT - CDIC Wallet Balance", Value: toolBarModel.TotalCDICBalance == null || undefined ? 0 : toolBarModel.TotalCDICBalance }
							]} />
					</div>
				}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.GetAllData, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.ClearModel, Disabled: Disabled },
					{ Code: "Export", OnClick: this.ExportClick, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				{/*EXPORT DIALOG*/}
				<GenericDialog open={this.state.isDownloadDialogOpen} fullWidth={true}>
					<DialogTitle>
						<GenericLabel Text="Select Report Type" FontSize="20px" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ height: 300 }}>
						<GenericRadioInput
							Name="ExportReportType"
							LabelText="Report Type"
							IsStatic={true}
							StaticData={[{ Value: "PDF", Text: "PDF" }, { Value: "CSV", Text: "CSV" }, { Value: "EXCEL", Text: "EXCEL" }]}
							KeyValueMember="Value"
							TextValueMember="Text"
							Value={this.state.ExportReportType}
							ValueChanged={this.ValueChanged}
						/>
						<GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
						<GridItem style={{ marginTop: 20 }}>
							<Select
								value={this.state.ExportSelectedColumns}
								onChange={value => this.setState({ ExportSelectedColumns: value })}
								isMulti
								name="columns"
								getOptionLabel={d => d.title}
								options={this.state.GridKeyValueList}
								className="basic-multi-select"
								classNamePrefix="select"
							/>
						</GridItem>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.ExcelDownload}>Download</Button>
						<Button size="sm" onClick={() => this.setState({ isDownloadDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>
				{/* Edit dialog */}
				<GenericDialog open={this.state.openEditDialog} onBackdropClick={this.handleClose}>
					<DialogTitle  >
						<GenericLabel Text="DDT Customer Information" FontSize="20px" Bold={true} />
					</DialogTitle>
					<DialogContent>
						<DDTCustomerInfo
							DDTModel={this.state.ddtModel}
							PhoneModel={this.state.Phone}
							handleChange={this.handleEditChange}
							propModel={this.state.model}
							vModel={this.state.vModel} 
							getAppLeftTitle={this.getappLeftTitle}
	                  getAppCenterTitle={this.getappCenterTitle}
							getMenuCode={this.props.MenuCode}
							/>
						<GridContainer>
							<GridItem xs={6} />
							<GridItem xs={6}>
								<GridContainer justify="flex-end">
									<GridItem>
										<Button size="sm" onClick={this.EditDDT}>EDIT</Button>
									</GridItem>
								</GridContainer>
							</GridItem>
						</GridContainer>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.handleClose} >Close</Button>
					</GenericDialogActions>
				</GenericDialog>
				<Card className="no-radius">
					<GenericExpansionPanel Title={"Main Parameters"}>
						<CardBody>
							<GridContainer>
								<GridItem xs={6}>
									<GenericSelectInput
										Name="UniqueClientId"
										LabelText="Client"
										Method="POST"
										Url="/bankapi/v1.0/BankCustomer/Search"
										Parameter={{}}
										DataRoot="Item"
										KeyValueMember="Id"
										RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
										Value={model.UniqueClientId || ""}
										ValueChanged={this.handleChange}
										All
										CanClear
										Disabled={!isBackOffice} />
									<GridContainer>
										<GridItem className={classes.GridItemFreePadding} xs={6}>
											<GenericSelectInput
												LabelMd={8}
												Name="FinInsId"
												LabelText="Client Primary Wallet"
												Method="GET"
												Url="/bankapi/v1.0/FinancialInstitution/GetAll"
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="FinInsId"
												Value={DefaultBankInformation.FinancialInstitutionId != null ? DefaultBankInformation.FinancialInstitutionId : 0}
												Disabled={true} />
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												Name="PrimaryBranchId"
												LabelMd={0}
												TitleLabel="Branch No."
												All
												Method="GET"
												Url="/bankapi/v1.0/FinancialInstitutionBranch/GetAllDCBankBranches"
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="TransitNo"
												Value={model.PrimaryBranchId == null ? "" : model.PrimaryBranchId}
												ValueChanged={this.handleChange}
												Disabled={!isBackOffice} />
										</GridItem>
										<GridItem xs={3} style={{ paddingRight: 2 + "px !important" }}>
											<GenericNumberInput
												NoFormatting={true}
												InputStyle={{ marginLeft: 2 }}
												LabelMd={0}
												ValueChanged={this.handleChange}
												Name="PrimaryAccountNumber"
												Value={model.PrimaryAccountNumber == null ? "" : model.PrimaryAccountNumber}
												Disabled={!isBackOffice}
												MaxLength={12} />
										</GridItem>
									</GridContainer>

									<GenericSelectInput
										Name="StatusId"
										LabelText="DDT Status"
										Value={model.StatusId == null || undefined ? "" : model.StatusId}
										DataRoot="Item"
										ValueChanged={this.handleChange}
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Method="POST"
										All
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={{
											ParameterCode: "AccountStatus",
											"ParameterValue": "",
											ParameterValue2: "DDT",
											"ParameterDesc": ""
										}} />
									<GridContainer>
										<GridItem className={classes.GridItemFreePadding} xs={6}>
											<GenericSelectInput
												Name="FinInsId"
												LabelMd={8}
												LabelText="DDT Number"
												TitleLabel="FIID"
												Method="GET"
												Url="/bankapi/v1.0/FinancialInstitution/GetAll"
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="FinInsId"
												Value={DefaultBankInformation.FinancialInstitutionId}
												Disabled={true} />
										</GridItem>
										<GridItem xs={3}>
											<GenericSelectInput
												key={poolModel.FinInsId}
												Name="BranchId"
												TitleLabel="Branch No."
												LabelMd={0}
												All
												Method="GET"
												Url="/bankapi/v1.0/FinancialInstitutionBranch/GetAllDCBankBranches"
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="TransitNo"
												Value={model.BranchId == null || undefined ? "" : model.BranchId}
												ValueChanged={this.handleChange}
											/>
										</GridItem>
										<GridItem xs={3} style={{ paddingRight: 2 + "px !important" }}>
											<GenericNumberInput
												NoFormatting={true}
												LabelMd={0}
												InputStyle={{ marginLeft: 2 }}
												Name="AccNumber"
												Value={model.AccNumber == null || undefined ? "" : model.AccNumber}
												ValueChanged={this.handleChange}
												MaxLength={12} />
										</GridItem>
									</GridContainer>
								</GridItem>
								<GridItem xs={4}>
									<GenericDateInput
										Name="FromDate"
										LabelText="From"
										Value={model == null || undefined ? "" : model.FromDate ? Formatter.FormatDateUtc(model.FromDate) : ""}
										ValueChanged={this.handleChange}
										MaxDate={model.ToDate == null ? DateHelper.GetDate() : model.ToDate}
										IncludeTime={false}
										Utc />
									<GenericDateInput
										Name="ToDate"
										LabelText="To"
										Value={model == null || undefined ? "" : model.ToDate ? Formatter.FormatDateUtc(model.ToDate) : ""}
										ValueChanged={this.handleChange}
										MaxDate={DateHelper.GetDate()}
										MinDate={model.FromDate == null ? null : model.FromDate}
										IncludeTime={false}
										Utc />
									<GenericNumberInput
										NoFormatting={true}
										Name="CustomerNumber"
										LabelText="Customer Number"
										Value={model.CustomerNumber == null || undefined ? "" : model.CustomerNumber}
										ValueChanged={this.handleChange} />
									<GenericTextInput
										Name="AccountOwnerName"
										LabelText="Wallet Owner"
										Value={model.AccountOwnerName == null || undefined ? "" : model.AccountOwnerName}
										ValueChanged={this.handleChange} />
								</GridItem>
							</GridContainer>
						</CardBody>
					</GenericExpansionPanel>
				</Card>

				<Card className="no-radius">
					<CardHeader><GenericLabel Text={"DDT Number List"} /></CardHeader>
					<CardBody>
						<GenericGrid
							key={"trxGrid" + this.state.trxGridToken}
							ref={this.trxTable}
							ShowPagination={true}
							PageSize={10}
							ServerSide
							LoadAtStartup={this.state.loadingCompleted}
							PrepareRequest={() => { return this.state.model; }}
							RequestUrl="/bankapi/v1.0/Ddt/DdtSearch"
							RequestMethod="POST"
							Columns={this.columns}
							ProgramCode={ProgramCodes.Bank_Programs_Bank_Account}
							PaginationFetchDataCallback={this.CalculateDdtTotalInfo} />
						<br />
						<GridContainer >
							<GridItem xs={6}></GridItem>
							<GridItem xs={6}>
								<GridContainer className={classes.containerHeight} justify="flex-end">
									<GridItem xs={2} className={classes.gridBorder}>
									</GridItem>
									<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
										<p className={classes.pt}>Active</p>
									</GridItem>
									<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
										<p className={classes.pt}>Inactive</p>
									</GridItem>
									<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
										<p className={classes.pt}>Total</p>
									</GridItem>
									<GridItem xs={3} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
										<p className={classes.pt}>Client Total Balance</p>
									</GridItem>
								</GridContainer>
								<GridContainer justify="flex-end">
									<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold}>
										TOTAL
									</GridItem>
									<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
										{DdtTotalInfo == null || undefined ? 0 : DdtTotalInfo.Active == null || undefined ? 0 : typeof DdtTotalInfo.Active == "number" ? Formatter.FormatNumber(DdtTotalInfo.Active) : DdtTotalInfo.Active}
									</GridItem>
									<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
										{DdtTotalInfo == null || undefined ? 0 : DdtTotalInfo.Inactive == null || undefined ? 0 : typeof DdtTotalInfo.Inactive == "number" ? Formatter.FormatNumber(DdtTotalInfo.Inactive) : DdtTotalInfo.Inactive}
									</GridItem>
									<GridItem xs={2} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
										{DdtTotalInfo == null || undefined ? 0 : DdtTotalInfo.Total == null || undefined ? 0 : typeof DdtTotalInfo.Total == "number" ? Formatter.FormatNumber(DdtTotalInfo.Total) : DdtTotalInfo.Total}
									</GridItem>
									<GridItem xs={3} className={classes.gridBorder + " " + classes.gridTextBold + " " + classes.gridTextCenter}>
										{DdtTotalInfo == null || undefined ? 0 : DdtTotalInfo.Balance == null || undefined ? 0 : typeof DdtTotalInfo.Balance == "number" ? Formatter.FormatMoney(DdtTotalInfo.Balance) : DdtTotalInfo.Balance}
									</GridItem>
								</GridContainer>
							</GridItem>
						</GridContainer>
						<br />
					</CardBody>
				</Card>
			</div>
		);
	}
}

DDTAccounts.propTypes = {
	classes: PropTypes.object,
	handleOperationType: PropTypes.func,
	SelectedRowChange: PropTypes.func,
	showQuestionMessage: func,
	setAppLeftTitle: func,
	setAppCenterTitle: func,
	setPortal: func,
	Disabled: bool,
	menuId: number,
	ApprovalData: array,
	After: func
};

export default withArtifex(DDTAccounts, styles);