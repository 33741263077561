import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import CustomerComponent from "views/Components/CustomerComponent";
import {
  GenericAlert,
  GenericDateInput,
  GenericExpansionPanel,
  GenericGrid,
  GenericSelectInput,
  GenericNumberInput,
} from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import {
  ProgramCodes,
  GridColumnType,
  MenuCodes,
  BankingAccountTypes,
  ClientType,
} from "views/Constants/Constant.js";
import moment from "moment";
import Formatter from "core/Formatter";
import { TaskPool } from "core/TaskPool.js";

class CardTransaction extends React.Component {
  constructor(props) {
    super(props);

    this.loadTaskPool = new TaskPool(
      this.handleLoadTaskPoolAppend,
      this.handleLoadTaskPoolCompleted
    );

    this.initialModel = {
      Id: 0,
      UniqueClientId: null,
      Customer: null,
      DefaultAccount: null,
      DefaultCard: null,
      CardId: null,
      ValidFrom: DateHelper.GetLocalDateAsUtc(),
      ValidTo: DateHelper.GetLocalDateAsUtc(),
      IsUniqueDataSearch: null,
      ReferenceNumber: null,
    };

    this.CloumnsData = [
		{
			Header: "Client Name",
			accessor: "UniqueClient.ClientName"
		},
      {
        Header: "Program",
        accessor: "Program.Description",
      },
      {
        Header: "Wallet Type",
        accessor: "BankingAccountType.ParameterDesc",
        Cell: (row) => (
          <div>{this.RenderAccountTypeParameter(row.original)} </div>
        ),
      },
      {
        Header: "Currency",
        accessor: "Currency.Code",
      },
      {
        Header: "Wallet Name",
        accessor: "AccountName",
      },
      {
        Header: "Transit",
        accessor: "Branch.TransitNo",
      },
      {
        Header: "Wallet Number",
        accessor: "AccountNumber",
        Cell: (row) =>
          ClientHelper.IsClient()
            ? this.FilterIdentityNumber(row.value)
            : row.value,
      },
      {
        Header: "Full Wallet Number",
        accessor: "IBAN",
        Cell: (row) =>
          ClientHelper.IsClient()
            ? this.FilterIdentityNumber(row.value)
            : row.value,
      },
      {
        Header: "Balance",
        accessor: "Balance",
        Cell: (row) => (
          <div>
            {row.original.Balance == null || row.original.Balance == ""
              ? Formatter.FormatMoney(0)
              : Formatter.FormatMoney(row.original.Balance)}
          </div>
        ),
      },
      {
        Header: "Pending Balance",
        accessor: "PendingBalance",
        Cell: (row) => (
          <div>
            {row.original.PendingBalance == null ||
            row.original.PendingBalance == ""
              ? Formatter.FormatMoney(0)
              : Formatter.FormatMoney(row.original.PendingBalance)}
          </div>
        ),
      },
      {
        Header: "Total Balance",
        accessor: "TotalBalance",
        Cell: (row) => (
          <div>
            {row.original.Balance == null || row.original.Balance == ""
              ? Formatter.FormatMoney(0)
              : Formatter.FormatMoney(
                  row.original.Balance +
                    (row.original.PendingBalance == null ||
                    row.original.PendingBalance == ""
                      ? 0
                      : row.original.PendingBalance)
                )}
          </div>
        ),
      },
      {
        Header: "Existing System Wallet",
        accessor: "IsExistingSystemAccount",
        Cell: (row) => (
          <div>
            {row.original.IsExistingSystemAccount == true ? "Yes" : "No"}
          </div>
        ),
      },
      {
        Header: "Chequing Wallet",
        accessor: "IsChequingAccount",
        Cell: (row) => (
          <div>{row.original.IsChequingAccount == true ? "Yes" : "No"}</div>
        ),
      },
      {
        Header: "Wallet Status",
        accessor: "AccountStatus.ParameterDesc",
      },
      {
        Header: "Intend Use Of Wallet",
        accessor: "IntendUseOfAccount.ParameterDesc",
      },
      {
        Header: "Store",
        accessor: "Store",
        Cell: (row) => {
          const store = row.value;
          const value =
            store != null ? store.StoreCode + " - " + store.StoreName : "";
          return <div>{value}</div>;
        },
      },
    ];

    this.state = {
      list: [],
      vModel: {},
      model: this.initialModel,
      filterModel: {},
      isLoading: false,
      LimitToken: false,
      LimitBalanceData: {
        AvailableCreditLimit: 0,
        AvailableDebitLimit: 0,
        AvailableBalance: 0,
      },
    };
  }

  handleLoadTaskPoolCompleted = () => {
    this.setState({ isLoading: false });
  };
  handleLoadTaskPoolAppend = () => {
    this.setState({ isLoading: true });
  };

  RenderAccountTypeParameter = (data) => {
    const global = { ...this.props.paramGlobalModel };
    if (data == null) {
      return "";
    }
    if (data.BankingAccountType == null) {
      return "";
    }
    if (
      data.BankingAccountType.ParameterValue == null ||
      data.BankingAccountType.ParameterValue == ""
    ) {
      return "";
    }
    if (
      data.BankingAccountType.ParameterValue !=
      BankingAccountTypes.PrimaryAccount
    ) {
      return data.BankingAccountType.ParameterDesc;
    }
    if (data.Program == null || data.Program.ProgramCode == null) {
      return data.BankingAccountType.ParameterDesc;
    }
    if (data.Program.ProgramCode != ProgramCodes.Bank_Programs_Bank_Account) {
      return data.BankingAccountType.ParameterDesc;
    }
    if (
      global.ClientType == ClientType.Corporate ||
      global.ClientType == ClientType.SalesAgentCorporate
    ) {
      return "Corporate Wallet";
    } else if (
      global.ClientType == ClientType.Personal ||
      global.ClientType == ClientType.SalesAgentPersonal
    ) {
      return "Individual Wallet";
    } else {
      return data.BankingAccountType.ParameterDesc;
    }
  };

  FilterIdentityNumber = (number) => {
    if (number == null) return "";

    var regexp = new RegExp("(.*)(.{4})");
    var onEk = regexp.exec(number)[1].replace(/([^ ])/g, "*");
    var sonEk = regexp.exec(number)[2];

    return onEk + sonEk;
  };

  componentDidMount() {
    this.props.setAppLeftTitle("Client Wallet List");
    this.props.setAppCenterTitle("CLIENT");
  }

  IsValid = () => {
    var vmodel = { ...this.state.model };
    const kModel = { ...this.state.vModel };

    if (
      (!vmodel.IsUniqueDataSearch && vmodel.ValidFrom == null) ||
      vmodel.ValidFrom == ""
    ) {
      kModel.ValidFrom = true;
      this.props.showMessage(
        "warning",
        "Please fill required fields!",
        "Select From Date to continue!"
      );
      this.setState({
        kModel: kModel,
      });
      return false;
    } else {
      kModel.ValidFrom = false;
    }
    if (
      (!vmodel.IsUniqueDataSearch && vmodel.ValidTo == null) ||
      vmodel.ValidTo == ""
    ) {
      kModel.ValidTo = true;
      this.props.showMessage(
        "warning",
        "Please fill required fields!",
        "Select To Date to continue!"
      );
      this.setState({
        kModel: kModel,
      });
      return false;
    } else {
      kModel.ValidTo = false;
    }

    if (
      !vmodel.IsUniqueDataSearch &&
      typeof vmodel.ValidFrom != "object" &&
      vmodel.ValidFrom != "" &&
      vmodel.ValidFrom != null
    ) {
      this.props.showMessage(
        "warning",
        "Please fill required fields!",
        "From Date is invalid"
      );
      return false;
    }

    if (
      !vmodel.IsUniqueDataSearch &&
      typeof vmodel.ValidTo != "object" &&
      vmodel.ValidTo != "" &&
      vmodel.ValidTo != null
    ) {
      this.props.showMessage(
        "warning",
        "Please fill required fields!",
        "To Date is invalid"
      );
      return false;
    }

    if (
      !vmodel.IsUniqueDataSearch &&
      typeof vmodel.ValidFrom == "object" &&
      typeof vmodel.ValidTo == "object" &&
      vmodel.ValidTo < vmodel.ValidFrom
    ) {
      this.props.showMessage(
        "warning",
        "Please fill required fields!",
        "From Date cannot be greater than To Date!"
      );
      return false;
    }

    if (
      !vmodel.IsUniqueDataSearch &&
      typeof vmodel.ValidFrom == "object" &&
      typeof vmodel.ValidTo == "object"
    ) {
      var interval = moment
        .duration(vmodel.ValidTo.diff(vmodel.ValidFrom))
        .asDays();
      if (interval > 30) {
        this.props.showMessage(
          "warning",
          "Please fill required fields!",
          "Between two dates cannot be longer than 30 days!"
        );
        return false;
      }
    }
    this.setState({
      kModel: kModel,
    });
    return true;
  };

  handleChange = (name, newValue) => {
    const model = { ...this.state.model };
    const initialModel = { ...this.initialModel };
    if (name === "UniqueClientId") {
      if (
        ClientHelper.IsClient() &&
        initialModel.UniqueClientId == null &&
        newValue != null
      ) {
        this.initialModel.UniqueClientId = newValue;
        console.log(this.initialModel.UniqueClientId + "initial");
      }
      model.UniqueClientId = newValue;
      console.log(model.UniqueClientId + "model");
    }
    this.setState({ model });
  };

  HandleClear = () => {
    this.setState({
      model: this.initialModel,
      filterModel: {},
      selected: null,
      list: [],
    });
  };

  HandleSearch = () => {
    if (!this.IsValid()) return;

    const model = { ...this.state.model };
    this.setState({ isLoading: true });

    this.loadTaskPool.AppendTask(
      Proxy.GET(
        "/bankapi/v1.0/Account/GetByCustomerIdForClientSetup/Id?Id=" +
          model.UniqueClientId,
        (responseData) => {
          console.log(
            "GetCustomerDCBankAccountModels ->",
            responseData.IsSucceeded
          );

          if (!responseData.IsSucceeded) {
            this.ShowMessage("error", "Error", responseData.ErrorDescription);
            return;
          }
          if (responseData.Item != null) {
            this.setState({
              model,
              list: responseData.Item,
            });
          }
        },
        (error) => {
          this.ShowMessage("error", "Error", error);
        }
      )
    );
  };

  ShowMessage = (type, title, message) => {
    this.setState({
      alert: (
        <GenericAlert
          Title={title}
          Message={message}
          Type={type}
          OnConfirm={() => this.setState({ alert: "" })}
        />
      ),
    });
  };

  render() {
    const { Disabled } = this.props;
    const { alert, model, list, isLoading, vModel } = this.state;

    return (
      <div>
        <LoadingComponent Show={isLoading} />
        {alert}
        <ButtonToolbar
          ButtonList={[
            {
              Code: "Search",
              OnClick: this.HandleSearch,
              Disabled: Disabled || model.Id > 0,
              ModelFunction: () => model,
              FillDataFromModel: (model) => this.setState({ model }),
            },
            { Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
          ]}
          menuId={this.props.menuId}
          ApprovalData={this.props.ApprovalData}
          After={this.props.After}
        />
        <GridContainer spacing={16}>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <GenericExpansionPanel>
                  <GridContainer>
                    <GridItem xs={4}>
                      <GridItem>
                        <GenericSelectInput
                          Name="UniqueClientId"
                          LabelText="Client"
                          Method="POST"
                          Url="/bankapi/v1.0/BankCustomer/Search"
                          Parameter={{
                            UniqueClientId: ClientHelper.IsClient()
                              ? ClientHelper.GetClientId()
                              : undefined,
                          }}
                          DataRoot="Item"
                          KeyValueMember="Id"
                          RenderItem={(d) => `${d.UniqueClientId} - ${d.Name}`}
                          Value={model.UniqueClientId || ""}
                          ValueChanged={this.handleChange}
                          CanClear
                          All
                          Disabled={ClientHelper.IsClient()}
                          DefaultIndex={ClientHelper.IsClient() ? 0 : -1}
                        />
                      </GridItem>
                    </GridItem>
                  </GridContainer>
                </GenericExpansionPanel>
              </CardBody>
            </Card>
            <Card className="no-radius">
              <CardBody>
                <GridContainer>
                  <GridItem xs={12}>
                    <GridContainer spacing={16}>
                      <GridItem xs={12}>
                        <GenericGrid
                          Data={list}
                          Columns={this.CloumnsData}
                          ProgramCode={ProgramCodes.Prepaid}
                          ShowPagination={true}
                          PageSize={100}
                          Sorted={[{ id: "TransactionDate", desc: true }]}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

CardTransaction.propTypes = {
  classes: PropTypes.object,
  setAppLeftTitle: PropTypes.func,
  setAppCenterTitle: PropTypes.func,
  Disabled: PropTypes.bool,
};

export default withArtifex(CardTransaction, {});
