import Card from "components/Card/Card.jsx";
import React from "react";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import Formatter from "core/Formatter";
import Button from "components/CustomButtons/Button.jsx";
import { CompareSharp as PublicImg, Edit as EditImg } from "@material-ui/icons";
import PropTypes from "prop-types";
import { GenericTitle, GenericAlert, GenericGrid, GenericColorInput, GenericLabel, GenericNumberInput, GenericCheckInput, GenericDateInput, GenericSelectInput, GenericEmailInput, GenericTextInput, GenericIcon, GenericDialogActions, GenericDialog } from "views/Components/Generic";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import DateHelper from "core/DateHelper";
import { GenericInput } from "views/Components/Generic.jsx";
import { PropertyType } from "views/Constants/Constant.js";
import { DetailIcon, EditIcon } from "core/Icons";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import HtmlContentRender from "core/HtmlEditorHelper";
import CardHeader from "components/Card/CardHeader";
import GridButton from "views/Components/GridButton.jsx";
import LoadingComponent from "views/Components/LoadingComponent";


class CampaignEvaulate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			Profile: {},
			CampaignCriteriaList: [],
			CampaignMethodList: [],
			CampaignSimulates: [],
			alert: null,
			isLoading: false,
			isMessageDetailDialogOpen: false,
			CustomerActionModel: {}
		};

		this.ColumnsDataCriteria = [
			{
				Header: "Program",
				accessor: "CampaignProfile.Program.Description"
			},
			{
				Header: "ExecutionType",
				accessor: "CampaignProfile.ExecutionType.ParameterDesc"
			},
			{
				Header: "Profile Name",
				accessor: "CampaignProfile.CampaignProfileName"
			},
			{
				Header: "Expression Type",
				accessor: "ExpressionType.ParameterDesc"
			},
			{
				Header: "Right Value",
				accessor: "RightValue"
			},
			{
				Header: "Property Name",
				accessor: "DataModelProperty.PropertyName"
			},
			{
				Header: "Data Model",
				accessor: "DataModelProperty.DataModelDefinition.Description"
			}
		];
		this.ColumnsDataMethod = [
			{
				Header: "Program",
				accessor: "CampaignProfile.Program.Description"
			},
			{
				Header: "ExecutionType",
				accessor: "CampaignEngineMethod.ExecutionType.ParameterDesc"
			},
			{
				Header: "Profile Name",
				accessor: "CampaignProfile.CampaignProfileName"
			},
			{
				Header: "Method Name",
				accessor: "CampaignEngineMethod.MethodName"
			},
			{
				Header: "Method Description",
				accessor: "CampaignEngineMethod.MethodDescription"
			},
			{
				Header: "Engine Description",
				accessor: "CampaignEngineMethod.EngineDefinition.EngineDescription"
			}
		];
		this.ColumnsDataAppliedCustomer = [
			{
				Header: "Message",
				accessor: "Message",
				Cell: row => (
					<div>
						<GridButton
							Icon={EditIcon}
							Disabled={props.Disabled}
							OnClick={() => this.setState({ isMessageDetailDialogOpen: true, CustomerActionModel: row.original })} />
					</div>
				),
				sortable: false,
				filterable: false,
				width: 100
			},
			{
				Header: "Id",
				accessor: "Id"
			},
			{
				Header: "Unique Client ID",
				accessor: "UniqueClientId"
			},
			{
				Header: "Customer Number",
				accessor: "ClientCustomerNumber"
			},
			{
				Header: "Customer Name",
				accessor: "CustomerName"
			},
			{
				Header: "Customer Number",
				accessor: "CustomerNumber"
			},
			{
				Header: "Email",
				accessor: "Email"
			},
			{
				Header: "Phone",
				accessor: "Phone"
			},
			{
				Header: "Notication Type",
				accessor: "NoticationType"
			}
		];

	}

	componentDidMount() {
		var ProfileId = this.props.ProfileId;
		if (ProfileId == null || ProfileId == 0) {
			console.log("Profile Id not valid ");
			return;
		}

		this.setState({ isLoading: true });
		Proxy.POST(
			"/campaignapi/v1.0/CampaignProfile/Simulate",
			{ Id: ProfileId },
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				if (responseData.Item != null) {
					this.setState({
						Profile: responseData.Item || {},
						CampaignCriteriaList: responseData.Item.CampaignCriteriaList || [],
						CampaignMethodList: responseData.Item.CampaignMethodList || [],
						CampaignSimulates: responseData.Item.CampaignSimulates || []
					});
				} else {
					this.setState({
						Profile: {},
						CampaignCriteriaList: [],
						CampaignMethodList: [],
						CampaignSimulates: []
					});
				}
				this.setState({ isLoading: false });
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	ShowMessage = (type, title, message) => {
		// type:-success->warning->info->error
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}

	ReturnHtml = (htmlInput) => {
		var ReactDOMServer = require('react-dom/server');
		var HtmlToReactParser = require('html-to-react').Parser;

		var htmlToReactParser = new HtmlToReactParser();
		var reactElement = htmlToReactParser.parse(htmlInput);
		var reactHtml = ReactDOMServer.renderToStaticMarkup(reactElement);

		return reactElement;
	}

	render() {
		const { classes } = this.props;
		const { Profile, CampaignCriteriaList, CampaignMethodList, CampaignSimulates, CustomerActionModel } = this.state;
		return (
			<div>
				{this.state.alert}
				<LoadingComponent Show={this.state.isLoading} />

				<GridContainer >
					<GridItem xs={2}>
						<b><p style={{ paddingTop: "5px", marginLeft: "5px" }}> Applied Customer: {Profile.TotalCustomer} </p></b>
					</GridItem>
					<GridItem xs={2}>
						<b><p style={{ paddingTop: "5px", marginLeft: "5px" }}> Total Action: {Profile.TotalAction} </p></b>
					</GridItem>
					<GridItem xs={2}>
						<b>	<p style={{ paddingTop: "5px", marginLeft: "5px" }}>Total Criteria: {Profile.TotalCriteria} </p></b>
					</GridItem>
				</GridContainer>

				<Card className="no-radius">
					<CardHeader>
						<GenericTitle text={"Criteria List"} />
					</CardHeader>
					<CardBody>
						<GridItem md={12} xs={12}>
							<GenericGrid
								HideButton={true}
								Data={CampaignCriteriaList}
								Columns={this.ColumnsDataCriteria}
							/>
						</GridItem>
					</CardBody>
				</Card>


				<Card className="no-radius">
					<CardHeader>
						<GenericTitle text={"Method List"} />
					</CardHeader>
					<CardBody>
						<GridItem md={12} xs={12}>
							<GenericGrid
								HideButton={true}
								Data={CampaignMethodList}
								Columns={this.ColumnsDataMethod}
							/>
						</GridItem>
					</CardBody>
				</Card>


				<Card className="no-radius">
					<CardHeader>
						<GenericTitle text={"Applied Customers List"} />
					</CardHeader>
					<CardBody>
						<GridItem md={12} xs={12}>
							<GenericGrid
								HideButton={true}
								Data={CampaignSimulates}
								Columns={this.ColumnsDataAppliedCustomer}
							/>
						</GridItem>
					</CardBody>
				</Card>



				<GenericDialog open={this.state.isMessageDetailDialogOpen} maxWidth="md">
					<DialogTitle><GenericLabel FontSize="16px" TextColor="black" Text="Message Detail" Bold={true} />
					</DialogTitle>
					<DialogContent>
						{CustomerActionModel.NoticationType == "Sms" && (
							<div>
								<b><p style={{ paddingTop: "5px", marginLeft: "5px" }}> Phone Number: {CustomerActionModel.Phone} </p></b>
								<hr />
								{CustomerActionModel.SmsContent}
								<hr /><br />
							</div>
						)}

						{CustomerActionModel.NoticationType == "EMail" && (
							<div>
								<b><p style={{ paddingTop: "5px", marginLeft: "5px" }}> Email: {CustomerActionModel.Email} </p></b>
								<hr />
								<div>{HtmlContentRender.HtmlContentToReact(CustomerActionModel.EmailContent)}</div>
								<hr /><br />
							</div>
						)}

					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={() => this.setState({ isMessageDetailDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>

			</div>
		);
	}
}

CampaignEvaulate.propTypes = {
	classes: PropTypes.object
};

export default withArtifex(CampaignEvaulate, {});