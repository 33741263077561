import { DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import createEftStyle from "assets/jss/material-dashboard-pro-react/views/createEftStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import RouteHelper from "core/RouteHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import AccountLimitToolbar from "views/Components/AccountLimitToolbar.jsx";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericCheckInput, GenericDateInput, GenericDialog, GenericDialogActions, GenericGrid, GenericIcon, GenericLabel, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent.jsx";
import { ClientType, EFTEntryType, EFTTransactionTypes, MenuCodes, PeriodCodes, Portals, ProgramCodes } from "views/Constants/Constant.js";
import CreateEFTFromFile from "./CreateEFTFromFile";
import CreateEFTIndividual from "./CreateEFTIndividual";
import CreateEFTRecurring from "./CreateEFTRecurring";

class CreateEFT extends Component {

	constructor(props) {
		super(props);

		this.isClient = ClientHelper.IsClient();
		this.uniqueClientId = ClientHelper.GetClientRowId();

		this.defaultModel = {
			EntryType: "",
			EntryCode: "",
			EFTType: "",
			IsUpdate: false,
			UniqueClientId: this.uniqueClientId
		};

		this.defaultFromFileModel = {
			AutoApprove: false,
			FileName: "",
			File: null
		};

		this.defaultIndividualModel = {
			Id: null,
			PayeeId: "",
			PayeeName: "",
			NewClient: false,
			BeneficiaryId: "",
			Amount: null,
			TransactionDate: DateHelper.GetDateLocal(),
			TransferMemo: "",
			IsCustomerPopupOpen: false,
			TransactionType: "",
			TransactionTypeCode: "",
			TransactionCode: ""
		};

		this.defaultRecurringModel = {
			Id: null,
			PayeeId: "",
			PayeeName: "",
			NewClient: false,
			BeneficiaryId: "",
			StartDate: DateHelper.GetDate(),
			EndDate: DateHelper.GetDate(),
			PaymentCount: null,
			RecurringAmount: null,
			TransferMemo: "",
			PeriodId: "",
			PeriodCode: "",
			WeeklyId: "",
			WeeklyNumber: "",
			BiWeeklyId: "",
			BiWeeklyNumber: "",
			MonthlyId: "",
			MonthlyNumber: "",
			QuarterlyId: "",
			QuarterlyNumber: "",
			IsCustomerPopupOpen: false,
			TransactionType: "",
			TransactionTypeCode: "",
			TransactionCode: "",
			FreezeStartDate: null,
			FreezeEndDate: null
		};

		this.state = {
			isLoading: this.isClient,
			isFileDialogOpen: false,
			isIndividualDialogOpen: false,
			isRecurringDialogOpen: false,
			isClearDialogOpen: false,
			isFileResultDialogOpen: false,
			idIndividualClientWarningOpen: false,
			FileResultList: [],
			model: { ...this.defaultModel },
			vModel: {},
			FromFileModel: { ...this.defaultFromFileModel },
			vFromFileModel: {},
			IndividualModel: { ...this.defaultIndividualModel },
			vIndividualModel: {},
			RecurringModel: { ...this.defaultRecurringModel },
			vRecurringModel: {},
			LimitToken: false,
			LimitBalanceData: {
				AvailableCreditLimit: 0,
				AvailableDebitLimit: 0,
				AvailableBalance: 0
			},
			addNewCustomerMenuId: 0,
			CustomerStoreList: []
		};

		this.ValueChanged = this.ValueChanged.bind(this);
		this.FromFileModelChange = this.FromFileModelChange.bind(this);
		this.IndividualModelChange = this.IndividualModelChange.bind(this);
		this.RecurringModelChange = this.RecurringModelChange.bind(this);
		this.SubmitClick = this.SubmitClick.bind(this);
		this.ClearClick = this.ClearClick.bind(this);
		this.Validate = this.Validate.bind(this);
		this.ClearScreen = this.ClearScreen.bind(this);
		this.ClearDialogConfirm = this.ClearDialogConfirm.bind(this);

		this.SubmitFileClick = this.SubmitFileClick.bind(this);
		this.SubmitIndividualClick = this.SubmitIndividualClick.bind(this);
		this.SubmitRecurringClick = this.SubmitRecurringClick.bind(this);
		this.RefreshToolBar = this.RefreshToolBar.bind(this);

		this.GetModel = this.GetModel.bind(this);
		this.FillData = this.FillData.bind(this);
		this.GetModelId = this.GetModelId.bind(this);
		this.LimitDataLoad = this.LimitDataLoad.bind(this);
	}

	componentDidMount() {
		const { setAppLeftTitle, setAppCenterTitle, setPortal } = this.props;

		if (setAppLeftTitle)
			setAppLeftTitle("Create EFT");
		if (setAppCenterTitle)
			setAppCenterTitle("EFT SYSTEM");
		if (setPortal)
			setPortal(Portals.EFT);


		Proxy.GET("/bankapi/v1.0/BankCustomer/CheckClientIndividual",
			responseData => {
				if (!responseData.IsSucceeded) {
					// this.props.showMessage("error", "An error occurred while fetching data", responseData.ErrorDescription);
					return;
				}

				var data = responseData.Item;
				if (data) {
					if (data.IsClient && data.IsIndividual)
						this.setState({ idIndividualClientWarningOpen: true });
				}
			});

		Proxy.POST("/coreapi/v1.0/Menu/GetByMenuCode",
			MenuCodes.CustomerDefinition,
			responseData => {
				if (!responseData.IsSucceeded) {
					// this.props.showMessage("error", "An error occurred while fetching data", responseData.ErrorDescription);
					return;
				}

				var menu = responseData.Item;
				if (menu) {
					var menuId = menu.Id;
					this.setState({ addNewCustomerMenuId: menuId });
				}
			});

		var Id = RouteHelper.ReadAndClear("CreateEFT_Id");
		var RecurringId = RouteHelper.ReadAndClear("CreateEFT_RecurringId");
		if ((Id == null || Id <= 0) && RecurringId == null || RecurringId <= 0)
			this.GetCustomerStore();
		// Individual Hmm
		if (Id) {
			this.setState({ isLoading: true });
			Proxy.POST("/bankapi/v1.0/BankBmoEFT/GetByIdRequest",
				{ Item: Id },
				responseData => {
					this.setState({ isLoading: false });

					if (!responseData.IsSucceeded) {
						this.props.showMessage("error", "An error occurred while fetching data", responseData.ErrorDescription);
						return;
					}

					var eftData = responseData.Item;
					var data = eftData.Transaction;

					this.setState(function (state) {
						var model = state.model || {};
						var IndividualModel = state.IndividualModel || {};

						model.UniqueClientId = data.UniqueClientId;

						model.EntryType = data.EntryTypeId;
						model.EntryCode = EFTEntryType.Individual;
						model.EFTType = eftData.EFTTypeId;

						model.IsUpdate = true;
						IndividualModel.Id = data.Id;
						IndividualModel.PayeeId = eftData.PayeeId;
						IndividualModel.PayeeName = eftData.PayeeName;
						IndividualModel.BeneficiaryId = eftData.BeneficiaryId;
						IndividualModel.Amount = data.TransactionAmount;
						IndividualModel.TransactionDate = data.TransactionDate ? DateHelper.ToMoment(data.TransactionDate) : null;
						IndividualModel.TransferMemo = eftData.TransferMemo;
						IndividualModel.TransactionType = data.TransactionTypeId;
						IndividualModel.TransactionTypeCode = data.TransactionType ? data.TransactionType.ParameterValue : "";
						IndividualModel.TransactionCode = eftData.EFTTransactionTypeId;
						IndividualModel.CustomerStoreId = data.StoreId;
						if (IndividualModel.TransactionTypeCode == EFTTransactionTypes.Credit) {
							IndividualModel.BeneficiaryId = eftData.DestinationAccountId;
						}
						else if (IndividualModel.TransactionTypeCode == EFTTransactionTypes.Debit) {
							IndividualModel.BeneficiaryId = eftData.SourceAccountId;
						}

						return { model, IndividualModel };
					}, () => this.GetCustomerStore());
				},
				errorMessage => {
					this.setState({ isLoading: false });
					this.props.showMessage("error", "An error occurred while fetching data", errorMessage);
				}
			);
		}
		// Recurring
		else if (RecurringId) {
			this.setState({ isLoading: true });
			Proxy.POST("/bankapi/v1.0/BankBmoEFT/GetByIdRecurringRequest",
				{ Item: RecurringId },
				responseData => {
					this.setState({ isLoading: false });

					if (!responseData.IsSucceeded) {
						this.props.showMessage("error", "An error occurred while fetching data", responseData.ErrorDescription);
						return;
					}

					var data = responseData.Item;

					this.setState(function (state) {
						var model = state.model || {};
						var RecurringModel = state.RecurringModel || {};

						model.UniqueClientId = data.UniqueClientId;

						model.EntryType = data.EntryTypeId;
						model.EntryCode = EFTEntryType.Recurring;
						model.EFTType = data.EFTTypeId;

						model.IsUpdate = true;
						RecurringModel.Id = data.Id;
						RecurringModel.PayeeId = data.PayeeId;

						if (data.CustomerPayee && data.CustomerPayee.ClientType) {
							if (data.CustomerPayee.ClientType.ParameterValue == ClientType.Corporate)
								RecurringModel.PayeeName = data.CustomerPayee.CompanyLegalName;
							else if (data.CustomerPayee.ClientType.ParameterValue == ClientType.Personal)
								RecurringModel.PayeeName = data.CustomerPayee.Name + " " + data.CustomerPayee.SurName;
						}

						RecurringModel.BeneficiaryId = data.BeneficiaryId;
						RecurringModel.StartDate = data.StartingFromDate ? DateHelper.ToMoment(data.StartingFromDate) : null;
						RecurringModel.EndDate = data.EndOfDate ? DateHelper.ToMoment(data.EndOfDate) : null;
						RecurringModel.FreezeStartDate = data.FreezeFromDate ? DateHelper.ToMoment(data.FreezeFromDate) : null;
						RecurringModel.FreezeEndDate = data.FreezeToDate ? DateHelper.ToMoment(data.FreezeToDate) : null;
						RecurringModel.RecurringAmount = data.TransactionAmount;
						RecurringModel.TransferMemo = data.TransferMemo;
						RecurringModel.PeriodId = data.FirstDueDateOptionId;
						RecurringModel.PeriodCode = data.FirstDueDateOption != null ? data.FirstDueDateOption.ParameterValue : "";
						RecurringModel.WeeklyId = data.PeriodWeeklyId;
						RecurringModel.WeeklyNumber = data.PeriodWeekly != null ? data.PeriodWeekly.ParameterValue : "";
						RecurringModel.BiWeeklyId = data.PeriodBiWeeklyId;
						RecurringModel.BiWeeklyNumber = data.PeriodBiWeekly != null ? data.PeriodBiWeekly.ParameterValue : "";
						RecurringModel.MonthlyId = data.PeriodMonthlyId;
						RecurringModel.MonthlyNumber = data.PeriodMonthly != null ? data.PeriodMonthly.ParameterValue : "";
						RecurringModel.QuarterlyId = data.PeriodQuarterlyId;
						RecurringModel.QuarterlyNumber = data.PeriodQuarterly != null ? data.PeriodQuarterly.ParameterValue : "";
						RecurringModel.PaymentCount = data.NumberOfPayments;
						RecurringModel.TransactionType = data.TransactionTypeId;
						RecurringModel.TransactionTypeCode = data.TransactionType ? data.TransactionType.ParameterValue : "";
						RecurringModel.TransactionCode = data.EFTTransactionTypeId;
						RecurringModel.CustomerStoreId = data.CustomerStoreId;

						return { model, RecurringModel };
					}, () => this.GetCustomerStore());
				},
				errorMessage => {
					this.setState({ isLoading: false });
					this.props.showMessage("error", "An error occurred while fetching data", errorMessage);
				}
			);
		}
	}

	RefreshToolBar() {
		this.setState({ LimitToken: !this.state.LimitToken });
	}

	ValueChanged(name, newValue, data) {
		this.setState(function (state) {
			var model = state.model || {};
			model[name] = newValue;
			if (name == "EntryType")
				model.EntryCode = data ? data.ParameterValue : "";
			return { model };
		});
	}

	FromFileModelChange(modelFunction) {
		this.setState(function (state) {
			var model = state.FromFileModel || {};

			if (modelFunction)
				model = modelFunction(model);

			return { FromFileModel: model };
		});
	}

	IndividualModelChange(modelFunction) {
		this.setState(function (state) {
			var model = state.IndividualModel || {};

			if (modelFunction)
				model = modelFunction(model);

			return { IndividualModel: model };
		});
	}

	RecurringModelChange(modelFunction) {
		this.setState(function (state) {
			var model = state.RecurringModel || {};

			if (modelFunction)
				model = modelFunction(model);

			return { RecurringModel: model };
		});
	}

	SubmitClick(workflowId, after, accessToken) {
		if (!this.Validate())
			return;

		const { model } = this.state;

		switch (model.EntryCode) {
			case EFTEntryType.FromFile:
				this.setState({ isFileDialogOpen: true, workflowId, after, accessToken });
				break;
			case EFTEntryType.Individual:
				this.setState({ isIndividualDialogOpen: true, workflowId, after, accessToken });
				break;
			case EFTEntryType.Recurring:
				this.setState({ isRecurringDialogOpen: true, workflowId, after, accessToken });
				break;
			default:
				break;
		}
	}

	SubmitFileClick() {
		const { FromFileModel } = this.state;

		this.setState({ isLoading: true });

		var formData = new FormData();
		formData.append("file", FromFileModel.File, FromFileModel.FileName);
		formData.append("approved", String(FromFileModel.AutoApprove));

		Proxy.FileUpload("/bankapi/v1.0/BankBmoEFTFile/UploadCreateTransactionFile",
			formData,
			responseData => {
				this.setState({ isLoading: false, isFileDialogOpen: false });

				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "An error occurred while upload file", responseData.ErrorDescription);
					return;
				}

				var fileId = responseData.Item ? responseData.Item.FileId : 0;

				this.setState({ isLoading: true });
				Proxy.POST("/bankapi/v1.0/BankBmoEFTFile/GetFileInformation",
					{ Id: fileId },
					fileResponseData => {
						this.setState({ isLoading: false, isFileDialogOpen: false });

						if (!fileResponseData.IsSucceeded) {
							this.props.showMessage("error", "An error occurred while load file information", fileResponseData.ErrorDescription);
							return;
						}

						this.setState({ FileResultList: fileResponseData.Item, isFileResultDialogOpen: true });
					},
					errorMessage => {
						this.setState({ isLoading: false, isFileDialogOpen: false });
						this.props.showMessage("error", "An error occurred while load file information", errorMessage);
					}
				);
			},
			errorMessage => {
				this.setState({ isLoading: false, isFileDialogOpen: false });
				this.props.showMessage("error", "An error occurred while upload file", errorMessage);
			}
		);
	}

	SubmitIndividualClick() {
		const { model, workflowId, after, accessToken } = this.state;

		var individualRequest = this.GetModel();

		this.setState({ isLoading: true });

		if (!model.IsUpdate)
			Proxy.POST("/bankapi/v1.0/BankBmoEFT/InsertIndividualRequest",
				individualRequest,
				responseData => {
					this.setState({ isLoading: false, isIndividualDialogOpen: false });

					if (!responseData.IsSucceeded) {
						this.props.showMessage("error", "An error occurred while inserting data", responseData.ErrorDescription);
						return;
					}

					if (after) after();

					this.props.showMessage("success", "Individual Transaction Entry successfully saved.");

					if (this.isClient)
						this.ClearScreen();
				},
				errorMessage => {
					this.setState({ isLoading: false, isIndividualDialogOpen: false });
					this.props.showMessage("error", "An error occurred while inserting data", errorMessage);
				},
				workflowId,
				accessToken
			);
		else
			Proxy.POST("/bankapi/v1.0/BankBmoEFT/UpdateIndividualRequest",
				individualRequest,
				responseData => {
					this.setState({ isLoading: false, isIndividualDialogOpen: false });

					if (!responseData.IsSucceeded) {
						this.props.showMessage("error", "An error occurred while updating data", responseData.ErrorDescription);
						return;
					}

					if (after) after();

					this.props.showMessage("success", "Individual Transaction Entry successfully updated.");

					if (this.isClient)
						this.ClearScreen();
				},
				errorMessage => {
					this.setState({ isLoading: false, isIndividualDialogOpen: false });
					this.props.showMessage("error", "An error occurred while updating data", errorMessage);
				},
				workflowId,
				accessToken
			);
	}

	SubmitRecurringClick() {
		const { model, workflowId, after, accessToken } = this.state;

		var recurringRequest = this.GetModel();

		this.setState({ isLoading: true });

		if (!model.IsUpdate)
			Proxy.POST("/bankapi/v1.0/BankBmoEFT/InsertRecurringRequest",
				recurringRequest,
				responseData => {
					this.setState({ isLoading: false, isRecurringDialogOpen: false });

					if (!responseData.IsSucceeded) {
						this.props.showMessage("error", "An error occurred while inserting data", responseData.ErrorDescription);
						return;
					}

					if (after) after();

					this.props.showMessage("success", "Recurring Transaction Entry successfully saved.");

					if (this.isClient)
						this.ClearScreen();
				},
				errorMessage => {
					this.setState({ isLoading: false, isRecurringDialogOpen: false });
					this.props.showMessage("error", "An error occurred while inserting data", errorMessage);
				},
				workflowId,
				accessToken
			);
		else
			Proxy.POST("/bankapi/v1.0/BankBmoEFT/UpdateRecurringRequest",
				recurringRequest,
				responseData => {
					this.setState({ isLoading: false, isRecurringDialogOpen: false });

					if (!responseData.IsSucceeded) {
						this.props.showMessage("error", "An error occurred while updating data", responseData.ErrorDescription);
						return;
					}

					if (after) after();

					this.props.showMessage("success", "Recurring Transaction Entry successfully updated.");

					if (this.isClient)
						this.ClearScreen();
				},
				errorMessage => {
					this.setState({ isLoading: false, isRecurringDialogOpen: false });
					this.props.showMessage("error", "An error occurred while updating data", errorMessage);
				},
				workflowId,
				accessToken
			);
	}

	ClearClick() {
		this.setState({ isClearDialogOpen: true });
	}

	ClearDialogConfirm() {
		this.ClearScreen();
	}

	ClearScreen() {
		this.setState({ model: { ...this.defaultModel }, vModel: {}, vFromFileModel: {}, vIndividualModel: {}, vRecurringModel: {}, isClearDialogOpen: false, isLoading: true });
		this.FromFileModelChange(() => { return { ...this.defaultFromFileModel }; });
		this.IndividualModelChange(() => { return { ...this.defaultIndividualModel }; });
		this.RecurringModelChange(() => { return { ...this.defaultRecurringModel }; });
		this.RefreshToolBar();
	}
	GetCustomerStore = () => {
		Proxy.POST("/bankapi/v1.0/CustomerStore/GetStoreAccountMaps", { Id: this.state.model != null ? this.state.model.UniqueClientId : 0, ProgramCode: ProgramCodes.Bank_Programs_EFT },
			responseData => {
				if (!responseData.IsSucceeded) {
					console.log(responseData.ErrorDescription);
					this.setState({ CustomerStoreList: [] });
					return;
				}
				this.setState({ CustomerStoreList: responseData.Item || [] });
			},
			errorMessage => {
				this.setState({ CustomerStoreList: [] });
				console.log(errorMessage);
			});
	}
	Validate() {
		const { model, vModel, FromFileModel, vFromFileModel, IndividualModel, vIndividualModel, RecurringModel, vRecurringModel } = this.state;

		vModel.EntryType = model.EntryType == null || model.EntryType == "";
		if (vModel.EntryType) {
			this.props.showMessage("warning", "Entry Type not selected", "Select Entry Type to continue.");
			this.setState({ vModel });
			return false;
		}

		vModel.EFTType = (model.EFTType == null || model.EFTType == "") && model.EntryCode != EFTEntryType.FromFile;
		if (vModel.EFTType) {
			this.props.showMessage("warning", "EFT Type not selected", "Select EFT Type to continue.");
			this.setState({ vModel });
			return false;
		}

		switch (model.EntryCode) {
			case EFTEntryType.FromFile:
				vFromFileModel.File = !FromFileModel.File;
				if (vFromFileModel.File) {
					this.props.showMessage("warning", "File not selected", "Select File to continue.");
					this.setState({ vFromFileModel });
					return false;
				}
				return true;

			case EFTEntryType.Individual:
				var individualMessages = [];
				vIndividualModel.TransactionType = IndividualModel.TransactionType == null || IndividualModel.TransactionType == "";
				if (vIndividualModel.TransactionType) individualMessages.push("Transaction Type cannot be null.");
				vIndividualModel.TransactionCode = IndividualModel.TransactionCode == null || IndividualModel.TransactionCode == "";
				if (vIndividualModel.TransactionCode) individualMessages.push("Transaction Code cannot be null.");
				vIndividualModel.PayeeId = IndividualModel.PayeeId == null || IndividualModel.PayeeId == "";
				if (vIndividualModel.PayeeId) individualMessages.push("Customer Name cannot be null.");
				vIndividualModel.BeneficiaryId = IndividualModel.BeneficiaryId == null || IndividualModel.BeneficiaryId == "";
				if (vIndividualModel.BeneficiaryId) individualMessages.push("Customer Wallet cannot be null.");
				vIndividualModel.Amount = IndividualModel.Amount == null || IndividualModel.Amount == "";
				if (IndividualModel.Amount == null || IndividualModel.Amount == "") individualMessages.push("Amount cannot be null.");
				if (this.state.CustomerStoreList.length > 0) {
					vIndividualModel.CustomerStoreId = IndividualModel.CustomerStoreId == null || IndividualModel.CustomerStoreId == "";
					if (vIndividualModel.CustomerStoreId) individualMessages.push("Customer Store cannot be null.");
				}

				vIndividualModel.TransactionDate = IndividualModel.TransactionDate == null;
				if (vIndividualModel.TransactionDate) individualMessages.push("Transaction Date cannot be null.");
				else {
					vIndividualModel.TransactionDate = typeof IndividualModel.TransactionDate != "object";
					if (vIndividualModel.TransactionDate) individualMessages.push("Transaction Date is invalid.");
					else {
						vIndividualModel.TransactionDate = !model.IsUpdate && IndividualModel.TransactionDate < DateHelper.GetDateLocal();
						if (vIndividualModel.TransactionDate) individualMessages.push("Transaction Date cannot be past.");
					}
				}

				if (individualMessages.length > 0) {
					this.props.showMessage("warning", "Please fill required fields!", individualMessages.map((x, i) => <div key={i}>{x}</div>));
					this.setState({ vIndividualModel });
					return false;
				}
				return true;

			case EFTEntryType.Recurring:
				var recurringMessages = [];

				vRecurringModel.TransactionType = RecurringModel.TransactionType == null || RecurringModel.TransactionType == "";
				if (vRecurringModel.TransactionType) recurringMessages.push("Transaction Type cannot be null.");
				vRecurringModel.TransactionCode = RecurringModel.TransactionCode == null || RecurringModel.TransactionCode == "";
				if (vRecurringModel.TransactionCode) recurringMessages.push("Transaction Code cannot be null.");
				vRecurringModel.PayeeId = RecurringModel.PayeeId == null || RecurringModel.PayeeId == "";
				if (vRecurringModel.PayeeId) recurringMessages.push("Customer Name cannot be null.");
				vRecurringModel.BeneficiaryId = RecurringModel.BeneficiaryId == null || RecurringModel.BeneficiaryId == "";
				if (vRecurringModel.BeneficiaryId) recurringMessages.push("Customer Wallet cannot be null.");
				if (this.state.CustomerStoreList.length > 0) {
					vRecurringModel.CustomerStoreId = RecurringModel.CustomerStoreId == null || RecurringModel.CustomerStoreId == "";
					if (vRecurringModel.CustomerStoreId) recurringMessages.push("Customer Store cannot be null.");
				}
				vRecurringModel.StartDate = RecurringModel.StartDate == null;
				if (vRecurringModel.StartDate) recurringMessages.push("Start Date cannot be null.");
				else {
					vRecurringModel.StartDate = typeof RecurringModel.StartDate != "object";
					if (vRecurringModel.StartDate) recurringMessages.push("Start Date is invalid.");
					else {
						vRecurringModel.StartDate = !model.IsUpdate && RecurringModel.StartDate < DateHelper.GetLocalDateAsUtc();
						if (vRecurringModel.StartDate) recurringMessages.push("Start Date cannot be past.");
						else {
							vRecurringModel.StartDate = typeof RecurringModel.StartDate == "object" && typeof RecurringModel.EndDate == "object" && RecurringModel.StartDate > RecurringModel.EndDate;
							if (vRecurringModel.StartDate) recurringMessages.push("Start Date cannot be after End Date.");
						}
					}
				}

				vRecurringModel.EndDate = RecurringModel.EndDate == null;
				if (vRecurringModel.EndDate) recurringMessages.push("End Date cannot be null.");
				else {
					vRecurringModel.EndDate = typeof RecurringModel.EndDate != "object";
					if (vRecurringModel.EndDate) recurringMessages.push("End Date is invalid.");
					else {
						vRecurringModel.EndDate = RecurringModel.EndDate < DateHelper.GetLocalDateAsUtc();
						if (vRecurringModel.EndDate) recurringMessages.push("End Date cannot be past.");
					}
				}

				if (model.IsUpdate) {
					var isFreezeStartDateDefined = false;
					vRecurringModel.FreezeStartDate = RecurringModel.FreezeStartDate != null && RecurringModel.FreezeStartDate != "";
					if (vRecurringModel.FreezeStartDate) {
						vRecurringModel.FreezeStartDate = typeof RecurringModel.FreezeStartDate != "object";
						if (vRecurringModel.FreezeStartDate) recurringMessages.push("Freeze Start Date is invalid.");
						else {
							vRecurringModel.FreezeStartDate = RecurringModel.FreezeStartDate < RecurringModel.StartDate;
							if (vRecurringModel.FreezeStartDate) recurringMessages.push("Freeze Start Date cannot be before Start Date.");
							else isFreezeStartDateDefined = true;
						}
					}

					vRecurringModel.FreezeEndDate = RecurringModel.FreezeEndDate != null && RecurringModel.FreezeEndDate != "";
					if (vRecurringModel.FreezeEndDate) {
						vRecurringModel.FreezeEndDate = typeof RecurringModel.FreezeEndDate != "object";
						if (vRecurringModel.FreezeEndDate) recurringMessages.push("Freeze End Date is invalid.");
						else {
							vRecurringModel.FreezeEndDate = RecurringModel.FreezeEndDate > RecurringModel.EndDate;
							if (vRecurringModel.FreezeEndDate) recurringMessages.push("Freeze End Date cannot be after End Date.");
							else if (!isFreezeStartDateDefined) recurringMessages.push("Freeze Start Date can not be left blank.");
						}
					}
					else if (isFreezeStartDateDefined) recurringMessages.push("Freeze date can not be left blank.");
				}

				vRecurringModel.PaymentCount = RecurringModel.PaymentCount == null;
				if (vRecurringModel.PaymentCount) recurringMessages.push("Period/No. Of Payment cannot be null.");
				vRecurringModel.RecurringAmount = RecurringModel.RecurringAmount == null || RecurringModel.RecurringAmount == "";
				if (vRecurringModel.RecurringAmount) recurringMessages.push("Recurring Amount cannot be null.");
				vRecurringModel.PeriodId = RecurringModel.PeriodId == null || RecurringModel.PeriodId == "";
				if (vRecurringModel.PeriodId) recurringMessages.push("First Due Date Options is not selected.");
				vRecurringModel.WeeklyId = RecurringModel.PeriodCode == PeriodCodes.Weekly && (RecurringModel.WeeklyNumber == null || RecurringModel.WeeklyNumber == "");
				if (vRecurringModel.WeeklyId) recurringMessages.push("Day of week is not selected.");
				vRecurringModel.BiWeeklyId = RecurringModel.PeriodCode == PeriodCodes.BiWeekly && (RecurringModel.BiWeeklyNumber == null || RecurringModel.BiWeeklyNumber == "");
				if (vRecurringModel.BiWeeklyId) recurringMessages.push("Day of week is not selected.");
				vRecurringModel.MonthlyId = RecurringModel.PeriodCode == PeriodCodes.Monthly && (RecurringModel.MonthlyNumber == null || RecurringModel.MonthlyNumber == "");
				if (vRecurringModel.MonthlyId) recurringMessages.push("Day of month is not selected.");
				vRecurringModel.QuarterlyId = RecurringModel.PeriodCode == PeriodCodes.Quarterly && (RecurringModel.QuarterlyNumber == null || RecurringModel.QuarterlyNumber == "");
				if (vRecurringModel.QuarterlyId) recurringMessages.push("Day of first month of quarter is not selected.");

				if (recurringMessages.length > 0) {
					this.props.showMessage("warning", "Please fill required fields!", recurringMessages.map((x, i) => <div key={i}>{x}</div>));
					this.setState({ vRecurringModel });
					return false;
				}
				return true;

			default:
				this.props.showMessage("warning", "Entry Type not selected", "Select Entry Type to continue.");
				return false;
		}
	}

	GetModel() {
		const { model, FromFileModel, IndividualModel, RecurringModel } = this.state;

		switch (model.EntryCode) {
			case EFTEntryType.FromFile: return { model, FromFileModel };
			case EFTEntryType.Individual: return { model, IndividualModel };
			case EFTEntryType.Recurring: return { model, RecurringModel };
			default: return null;
		}
	}

	FillData(data) {
		const { model, FromFileModel, IndividualModel, RecurringModel } = data;

		switch (model.EntryCode) {
			case EFTEntryType.FromFile: this.setState({ model, FromFileModel }); break;
			case EFTEntryType.Individual:
				IndividualModel.TransactionDate = IndividualModel.TransactionDate ? DateHelper.ToMoment(IndividualModel.TransactionDate) : null;
				this.setState({ model, IndividualModel });
				break;
			case EFTEntryType.Recurring:
				RecurringModel.StartDate = RecurringModel.StartDate ? DateHelper.ToMoment(RecurringModel.StartDate) : null;
				RecurringModel.EndDate = RecurringModel.EndDate ? DateHelper.ToMoment(RecurringModel.EndDate) : null;
				RecurringModel.FreezeStartDate = RecurringModel.FreezeStartDate ? DateHelper.ToMoment(RecurringModel.FreezeStartDate) : null;
				RecurringModel.FreezeEndDate = RecurringModel.FreezeEndDate ? DateHelper.ToMoment(RecurringModel.FreezeEndDate) : null;
				this.setState({ model, RecurringModel });
				break;
			default: return;
		}
	}

	GetModelId() {
		const { model, IndividualModel, RecurringModel } = this.state;

		switch (model.EntryCode) {
			case EFTEntryType.FromFile: return null;
			case EFTEntryType.Individual: return IndividualModel.Id;
			case EFTEntryType.Recurring: return RecurringModel.Id;
			default: return null;
		}
	}

	LimitDataLoad(data) {
		this.setState({ LimitBalanceData: data, isLoading: false });
	}

	render() {
		const { model, vModel, isLoading, alert, isFileDialogOpen, isIndividualDialogOpen, isRecurringDialogOpen, isClearDialogOpen, isFileResultDialogOpen, FileResultList, FromFileModel, vFromFileModel, IndividualModel, vIndividualModel, RecurringModel, vRecurringModel, LimitToken, addNewCustomerMenuId, idIndividualClientWarningOpen, CustomerStoreList } = this.state;
		const { Disabled, history } = this.props;

		return (
			<div>
				<LoadingComponent Show={isLoading} />
				{alert}
				{this.isClient && <AccountLimitToolbar key={LimitToken} Program={ProgramCodes.Bank_Programs_EFT} OnLoad={this.LimitDataLoad} IsPopUp={this.props.ApprovalData != null} />}
				<ButtonToolbar ButtonList={[
					{ Code: "Submit", OnClick: this.SubmitClick, Disabled: Disabled || model.IsUpdate, ModelFunction: this.GetModel, FillDataFromModel: this.FillData, DisableWorkflow: this.isClient, RowId: this.GetModelId(), ValidationFunction: this.Validate },
					{ Code: "Update", OnClick: this.SubmitClick, Disabled: Disabled || !model.IsUpdate, ModelFunction: this.GetModel, FillDataFromModel: this.FillData, DisableWorkflow: this.isClient, RowId: this.GetModelId(), ValidationFunction: this.Validate },
					{ Code: "Clear", OnClick: this.ClearClick, Disabled: Disabled || !this.isClient }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius" >
							<CardHeader color="warning" icon>
								<h4 style={{ color: "black" }}>
									<b>Main Parameters</b>
								</h4>
							</CardHeader>
							<CardBody>
								<GridContainer spacing={16}>
									<GridItem xs={4}>
										<GridItem>
											<ParameterComponent
												Name="EntryType"
												Value={model.EntryType}
												LabelText="Entry Type"
												ParameterCode="EFTEntryType"
												ParameterValue2="Entry"
												ValueChanged={this.ValueChanged}
												Disabled={Disabled || model.IsUpdate}
												Required
												IsInvalid={vModel.EntryType} />
										</GridItem>
									</GridItem>
									<GridItem xs={4}>
										<GridItem>
											<ParameterComponent
												Name="EFTType"
												Value={model.EFTType}
												LabelText="EFT Type"
												ParameterCode="EFTType"
												ValueChanged={this.ValueChanged}
												Disabled={Disabled || model.IsUpdate || model.EntryCode == EFTEntryType.FromFile}
												Required={model.EntryCode != EFTEntryType.FromFile}
												IsInvalid={vModel.EFTType} />
										</GridItem>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={12}>
						<div>
							{/* <CreateEFTFromFile Disabled={Disabled} model={FromFileModel} vModel={vFromFileModel} onModelChange={this.FromFileModelChange} parentModel={model} /> */}
							<CreateEFTIndividual CustomerStoreList={CustomerStoreList} Disabled={Disabled} model={IndividualModel} vModel={vIndividualModel} onModelChange={this.IndividualModelChange} addNewCustomerMenuId={addNewCustomerMenuId} parentModel={model} />
							<CreateEFTRecurring CustomerStoreList={CustomerStoreList} Disabled={Disabled} model={RecurringModel} vModel={vRecurringModel} onModelChange={this.RecurringModelChange} addNewCustomerMenuId={addNewCustomerMenuId} parentModel={model} />
						</div>
					</GridItem>
				</GridContainer>

				{/* <GenericDialog open={isFileDialogOpen} maxWidth="md" fullWidth>
					<DialogTitle>
						<GenericLabel Text="Transaction Confirmation" Bold={true} />
					</DialogTitle>
					<DialogContent>
						<GenericTextInput Name="FileName" LabelText="File Name" Value={FromFileModel.FileName} Disabled={true} />
						<GenericTextInput Name="Date" LabelText="Date" Value={Formatter.FormatDate(DateHelper.GetDateTimeLocal())} Disabled={true} />
						<GenericCheckInput Name="AutoApprove" LabelText="Automatic Approval" Value={FromFileModel.AutoApprove} Disabled={true} />
						<br />
						<GenericLabel Text="Are you sure you want to upload file ?" Bold={true} />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.SubmitFileClick}>Confirm</Button>
						<Button size="sm" onClick={() => this.setState({ isFileDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog> */}

				<GenericDialog open={isIndividualDialogOpen} maxWidth="md" fullWidth>
					<DialogTitle>
						<GenericLabel Text="Transaction Confirmation" Bold={true} />
					</DialogTitle>
					<DialogContent>
						<GenericSelectInput
							LabelMd={2}
							Name="PayeeId"
							LabelText="Customer Name"
							Method="POST"
							Url="/bankapi/v1.0/ClientCustomer/GetByProgramCodeAndClientId"
							DataRoot="Item"
							Parameter={{ ProgramCode: ProgramCodes.Bank_Programs_EFT, UniqueClientId: model.UniqueClientId }}
							KeyValueMember="Id"
							TextValueMember="Name"
							Value={IndividualModel.PayeeId}
							Disabled={true} />
						<GenericSelectInput
							LabelMd={2}
							Name="BeneficiaryId"
							LabelText="Customer Wallet"
							Method="GET"
							Url={"/bankapi/v1.0/Beneficiary/GetByCustomerId/Id?Id=" + IndividualModel.PayeeId}
							DataRoot="Item"
							KeyValueMember="Id"
							RenderItem={d => `${d.FinancialInstitution.Description} - ${d.AccountNumber}`}
							Value={IndividualModel.BeneficiaryId}
							Disabled={true} />
						<GenericNumberInput Name="Amount" LabelText="Amount" Value={IndividualModel.Amount} MaxLength={10} Disabled={true} LabelMd={2} />
						<br />
						<GenericLabel Text={model.IsUpdate ?
							"Are you sure you want to apply changes for this transaction ?" :
							"Are you sure you want to enter this transaction ?"} Bold={true} />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.SubmitIndividualClick}>Confirm</Button>
						<Button size="sm" onClick={() => this.setState({ isIndividualDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>

				<GenericDialog open={isRecurringDialogOpen} maxWidth="md" fullWidth>
					<DialogTitle>
						<GenericLabel Text="Transaction Confirmation" Bold={true} />
					</DialogTitle>
					<DialogContent>
						<GenericSelectInput
							Name="PayeeId"
							LabelText="Customer Name"
							Method="POST"
							Url="/bankapi/v1.0/ClientCustomer/GetByProgramCodeAndClientId"
							DataRoot="Item"
							Parameter={{ ProgramCode: ProgramCodes.Bank_Programs_EFT, UniqueClientId: model.UniqueClientId }}
							KeyValueMember="Id"
							TextValueMember="Name"
							Value={RecurringModel.PayeeId}
							Disabled={true} LabelMd={2}
						/>
						<GenericSelectInput
							LabelMd={2}
							Name="BeneficiaryId"
							LabelText="Customer Wallet"
							Method="GET"
							Url={"/bankapi/v1.0/Beneficiary/GetByCustomerId/Id?Id=" + RecurringModel.PayeeId}
							DataRoot="Item"
							KeyValueMember="Id"
							RenderItem={d => `${d.FinancialInstitution.Description} - ${d.AccountNumber}`}
							Value={RecurringModel.BeneficiaryId}
							Disabled={true} />
						<GenericTextInput LabelText="Period/No. Of Payment" Value={RecurringModel.PaymentCount} Disabled={true} LabelMd={2} />
						<GenericNumberInput Name="RecurringAmount" LabelText="Recurring Amount" Value={RecurringModel.RecurringAmount} MaxLength={10} Disabled={true} LabelMd={2} />
						<GenericNumberInput LabelText="Total Amount" Value={RecurringModel.RecurringAmount && RecurringModel.PaymentCount && RecurringModel.RecurringAmount * RecurringModel.PaymentCount} Disabled={true} LabelMd={2} />
						<GenericDateInput Name="StartDate" LabelText="Start Date" Value={RecurringModel.StartDate} Disabled={true} LabelMd={2} Utc />
						<GenericDateInput Name="EndDate" LabelText="End Date" Value={RecurringModel.EndDate} Disabled={true} LabelMd={2} Utc />
						<br />
						<GenericLabel Text={model.IsUpdate ?
							"Are you sure you want to apply changes for this transaction ?" :
							"Are you sure you want to enter this transaction ?"} Bold={true} />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.SubmitRecurringClick}>Confirm</Button>
						<Button size="sm" onClick={() => this.setState({ isRecurringDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>

				<GenericDialog open={isClearDialogOpen} maxWidth="md" fullWidth>
					<DialogContent>
						<GenericLabel Text="This will erase the information you enter on the screen. Are you sure you want to quit this transaction ?" Bold={true} />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.ClearDialogConfirm}>Confirm</Button>
						<Button size="sm" onClick={() => this.setState({ isClearDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>

				<GenericDialog open={isFileResultDialogOpen} maxWidth="lg" fullWidth>
					<DialogTitle>
						<GridContainer justify="space-between" alignItems="center">
							<GridItem>
								<GenericLabel FontSize="16px" TextColor="black" Text="File Upload Process Done" Bold={true} />
							</GridItem>
							<GridItem>
								<IconButton onClick={() => this.setState({ isFileResultDialogOpen: false })}>
									<GenericIcon>close</GenericIcon>
								</IconButton>
							</GridItem>
						</GridContainer>
					</DialogTitle>
					<DialogContent>
						<GenericGrid
							Columns={[
								{
									Header: "Line Number",
									accessor: "LineId"
								},
								{
									Header: "Is Rejected",
									accessor: "IsRejected",
									Cell: row => row.value ? "Yes" : "No"
								},
								{
									Header: "Comment",
									accessor: "Comment"
								},
								{
									Header: "Entry Type",
									accessor: "EntryType"
								},
								{
									Header: "EFT Type",
									accessor: "EFTType"
								},
								{
									Header: "Customer Number",
									accessor: "CustomerNumber"
								},
								{
									Header: "Financial Institution Number",
									accessor: "FinancialInstitutionNumber"
								},
								{
									Header: "Transit Number",
									accessor: "TransitNumber"
								},
								{
									Header: "Customer Wallet",
									accessor: "CustomerAccount"
								},
								{
									Header: "Transaction Type",
									accessor: "TransactionType"
								},
								{
									Header: "Transaction Amount",
									accessor: "TransactionAmount"
								},
								{
									Header: "Transaction Code",
									accessor: "TransactionCode"
								},
								{
									Header: "Transaction Date",
									accessor: "TransactionDate"
								},
								{
									Header: "Start Date",
									accessor: "StartDate"
								},
								{
									Header: "End Date",
									accessor: "EndDate"
								},
								{
									Header: "EFT First Due Date Option",
									accessor: "EFTFirstDueDateOption"
								},
								{
									Header: "Period Weekly",
									accessor: "PeriodWeekly"
								},
								{
									Header: "Period BiWeekly",
									accessor: "PeriodBiWeekly"
								},
								{
									Header: "Period Monthly",
									accessor: "PeriodMonthly"
								},
								{
									Header: "Period Quarterly",
									accessor: "PeriodQuarterly"
								},
								{
									Header: "Freeze Start Date",
									accessor: "FreezeStartDate"
								},
								{
									Header: "Freeze End Date",
									accessor: "FreezeEndDate"
								},
								{
									Header: "Transfer Memo",
									accessor: "TransferMemo"
								},
							]}
							Data={FileResultList}
							Sorted={[{ id: "LineId", desc: false }]}
							ProgramCode={ProgramCodes.Bank_Programs_EFT} />
					</DialogContent>
				</GenericDialog>

				<GenericDialog open={idIndividualClientWarningOpen} maxWidth="md" fullWidth>
					<DialogTitle>
						<GenericLabel Text="Error" Bold={true} />
					</DialogTitle>
					<DialogContent>
						<div style={{ textAlign: "center" }}>
							<GenericLabel Text={"The individual Client has no authorization to enter the EFT Portal. Please Contact us if you see The EFT Portal at the menu screen."} />
						</div>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={() => history.push("/OnlineSupport")}>Online Support</Button>
						<Button size="sm" onClick={() => history.push("/")}>OK</Button>
					</GenericDialogActions>
				</GenericDialog>
			</div>
		);
	}
}

CreateEFT.propTypes = {
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool,
	showMessage: PropTypes.func
};

CreateEFT.defaultProps = {
	Disabled: false
};

export default withArtifex(CreateEFT, createEftStyle);