import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { CrudType, ProgramCodes, TransactionMoneyTransferType } from "views/Constants/Constant";


class MoneyTransaferWithFileUpload extends React.Component {
	constructor(props) {
		super(props);

		this.fileInputRef = React.createRef();

		this.initialModel = {
		};

		this.state = {
			list: [],
			IsResponseDialog: false,
			model: this.initialModel,
			isLoading: false,
			selected: null,
			bankAccounts: [],
			responseList: [],
			isFileDialogOpen: false
		};

		this.CardDisactive = true;
		this.CustomerDisactive = true;

	}

	componentDidMount() {
		this.props.setAppLeftTitle("Bulk Funds Transfer");
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
		//this.HandleGetList();
		this.ProgramCode = ProgramCodes.Prepaid;
		this.state.model.ProgramCode = ProgramCodes.Prepaid;
		this.setState({ model: this.state.model });
		// this.GetCustomerBankAccounts();

	}

	HandleChange = (name, value, data) => {
		const model = { ...this.state.model };
		model[name] = value;

		if (name == "TransferTypeId") {
			var findIndex = TransactionMoneyTransferType.findIndex(x => x.Id == value);

			if (TransactionMoneyTransferType[findIndex].Text === "Card") {
				this.CustomerDisactive = true;
				this.CardDisactive = false;
			}
			else {
				this.CardDisactive = true;
				this.CustomerDisactive = false;
			}
		}
		this.setState({ model });
	}

	HandleSelectModel = (model, index) => {
		this.setState({ model, selected: index });
		this.HandleGetList();
	}

	HandleClear = () => {
		const { model } = { ...this.state };
		delete model.File;
		delete model.Id;
		delete model.FileName;
		delete model.Binary;
		delete model.TempFileId;
		delete model.FileSelect;
		delete model.TransferTypeId;


		this.CustomerDisactive = true;
		this.CardDisactive = true;

		this.setState({ model, IsResponseDialog: false });
	}

	HandleGetList = () => {
		this.setState({ isLoading: true });

		this.state.model.ProgramCode = ProgramCodes.Prepaid;
		Proxy.POST(
			"/bankapi/v1.0/Transaction/GetAllTransactionCustomerByProgramCode",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					this.setState({ list: responseData.Item });
				} else {
					this.setState({ list: [] });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	HandleSubmitDialog = () => {
		if (!this.Validate())
			return;

		this.setState({ isFileDialogOpen: true });
	}
	HandleSubmit = () => {
		if (!this.Validate())
			return;

		if (!this.CardDisactive)
			this.url = "/bankapi/v1.0/Transaction/MoneySendCardTransactionWithBulkFileUpload";
		else
			this.url = "/bankapi/v1.0/Transaction/MoneySendCustomerTransactionWithBulkFileUpload"

		if (this.CardDisactive)
			this.fileUrl = "/bankapi/v1.0/Transaction/GetCustomerFileInformation";
		else
			this.fileUrl = "/bankapi/v1.0/Transaction/GetCardFileInformation";


		this.setState({ isLoading: true });
		const { model } = { ...this.state };
		var formData = new FormData();
		formData.append("file", model.File, model.FileName);
		formData.append("fileId", model.TempFileId || 0);
		formData.append("isBinaryOrBase64", model.Binary);
		formData.append("isInsertOrUpdate", model.Id == null ? CrudType.Insert : CrudType.Update);

		Proxy.FileUpload(
			this.url,
			formData,
			(responseData) => {

				if (!responseData.IsSucceeded && responseData.Item < 1) {
					this.setState({ isLoading: false, isFileDialogOpen: false });
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					this.HandleClear();

					return;
				}
				else {
					Proxy.POST(this.fileUrl,
						{ Id: responseData.Item.FileId },
						fileResponseData => {
							if (!fileResponseData.IsSucceeded) {
								this.setState({ isLoading: false, isFileDialogOpen: false });

								this.props.showMessage("error", "An error occurred while load file information", fileResponseData.ErrorDescription);
								return;
							}

							this.setState({ responseList: fileResponseData.Item, IsResponseDialog: true, isLoading: false, isFileDialogOpen: false });
						},
						errorMessage => {
							this.setState({ isLoading: false, isFileDialogOpen: false });
							this.props.showMessage("error", "An error occurred while load file information", errorMessage);
						}
					);
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while sending data", error.message);
			}
		);
	}

	FileSelect = (e) => {

		this.setState({ isLoading: true })
		e.preventDefault();

		var file = e.target.files[0];
		if (!file) {
			return;
		}

		var { model } = this.state;

		model.File = file;
		model.FileName = file.name;
		model.FileSelect = true;
		model.TempFileId = model.File !== undefined
			? model.File.Id
			: 0;
		this.setState({ model, isLoading: false });

	}


	Validate = () => {
		const model = this.state.model;

		if (!model.File) {
			this.ShowMessage("warning", "Invalid", "Please select file !");
			return;
		}
		return true;
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}
	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: "" })} OnConfirm={onConfirm} />
		});
	}

	Upload = () => {
		this.fileInputRef.current.click();
	}
	render() {
		const { alert, model, isLoading, IsResponseDialog, isFileDialogOpen } = this.state;


		return (
			<div>

				<LoadingComponent Show={isLoading} />

				{alert}

				<input
					type="file"
					key={model.FileName}
					style={{ display: "none" }}
					onChange={this.FileSelect}
					ref={this.fileInputRef}
					accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel" />



				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.HandleClear },
					{ Code: "Submit", OnClick: this.HandleSubmitDialog },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<GenericDialog open={isFileDialogOpen} maxWidth="md" fullWidth>
					<DialogTitle>
						<GenericLabel Text="Transaction Confirmation" Bold={true} />
					</DialogTitle>
					<DialogContent>
						<GenericTextInput Name="FileName" LabelText="File Name" Value={model.FileName} Disabled={true} />
						<GenericTextInput Name="Date" LabelText="Date" Value={Formatter.FormatDate(DateHelper.GetDateTimeLocal())} Disabled={true} />
						<br />
						<GenericLabel Text="Are you sure you want to upload file ?" Bold={true} />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.HandleSubmit}>Confirm</Button>
						<Button size="sm" onClick={() => this.setState({ isFileDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>

				<GenericDialog open={IsResponseDialog} maxWidth="md" fullWidth>
					<DialogTitle>
						<GenericLabel Text="Transaction Information" Bold={true} />
					</DialogTitle>
					<DialogContent>
						<GenericGrid
							Data={this.state.responseList}
							Columns={[
								{
									Header: "Line Number",
									accessor: "LineId"
								},
								{
									Header: "Is Rejected",
									accessor: "IsRejected",
									Cell: row => row.value ? "Yes" : "No"
								},
								{
									Header: "Comment",
									accessor: "Comment"
								},
								{
									Header: "Transaction Type",
									accessor: "TransactionType",
								},
								{
									Header: "Source Wallet Number",
									accessor: "SourceAccountNumber",
								},
								{
									Header: "Destination Wallet Number",
									accessor: "DestinationAccountNumber",
									show: this.CustomerDisactive
								},
								{
									Header: "Client Customer Number",
									accessor: "CustomerNumber",
									show: this.CardDisactive
								},
								{
									Header: "Destination Wallet Number",
									accessor: "DestinationWalletAccountNumber",
									show: this.CardDisactive
								},
								{
									Header: "Amount",
									accessor: "Amount"
								},
								{
									Header: "Description",
									accessor: "Description"
								}

							]}
							ShowPagination={true}
							SelectedIndex={this.state.rowIndex}
							ProgramCode={ProgramCodes.Prepaid} />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.HandleClear}>OK</Button>
					</GenericDialogActions>
				</GenericDialog>



				<GridContainer spacing={16}>
					<GridItem xs={12}>

						<Card className="no-radius" >
							<GenericExpansionPanel Title="Main Parameters" IsActive={true}>
								<CardBody>
									<GridContainer spacing={16}>
										<GridItem xs={4}>
											<GenericSelectInput
												Name="TransferTypeId"
												LabelText="Transfer Type"
												StaticData={TransactionMoneyTransferType}
												isStatic={true}
												Value={model.TransferTypeId}
												KeyValueMember="Id"
												TextValueMember="Text"
												ValueChanged={this.HandleChange}
											/>
										</GridItem>
									</GridContainer>
								</CardBody>
							</GenericExpansionPanel>
						</Card>


						<Card className="no-radius" style={{ opacity: this.CustomerDisactive ? 0.4 : 1 }}>
							<GenericExpansionPanel Title="Customer Transaction" IsActive={this.CardDisactive}>
								<CardBody>
									<GridContainer>
										<GridItem xs={3}>
											<GenericTextInput LabelMd={3} Name="FileName" LabelText="File Name" Value={model.FileName} Disabled={true} />
										</GridItem>
										<GridItem>
											<GenericLabel Text="Upload File" />
										</GridItem>
										<GridItem>
											<Button size="sm" onClick={this.Upload} disabled={this.CustomerDisactive}>BROWSE</Button>
										</GridItem>

									</GridContainer>

								</CardBody>
							</GenericExpansionPanel>
						</Card>

						<Card className="no-radius" style={{ opacity: this.CardDisactive ? 0.4 : 1 }} >
							<GenericExpansionPanel Title="Card Transaction" IsActive={this.CustomerDisactive}>
								<CardBody>
									<GridContainer>
										<GridItem xs={3}>
											<GenericTextInput LabelMd={3} Name="FileName" LabelText="File Name" Value={model.FileName} Disabled={true} />
										</GridItem>
										<GridItem>
											<GenericLabel Text="Upload File" />
										</GridItem>
										<GridItem>
											<Button size="sm" onClick={this.Upload} disabled={this.CardDisactive}>BROWSE</Button>
										</GridItem>

									</GridContainer>
								</CardBody>
							</GenericExpansionPanel>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

MoneyTransaferWithFileUpload.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withArtifex(MoneyTransaferWithFileUpload, {});
