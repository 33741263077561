import { DialogContent } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper.js";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import { EditIcon } from "core/Icons";
import PropTypes, { bool, func } from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType, ProgramCodes } from "views/Constants/Constant";
import BeneficiaryDetail from "views/WireTransfer/CreateWireTransfer/BeneficiaryDetail";

class MsbFileList extends Component {
	constructor(props) {
		super(props);



		this.columns = [
			{
				Header: "File Name",
				accessor: "FileName"
			},
			{
				Header: "Status",
				accessor: "MsbStatusDesc"
			},
			{
				Header: "Main Status",
				accessor: "SubStatus"
			},
			{
				Header: "Client Tranaction Id",
				accessor: "WireTransferMSBFileDetail.RefNo"
			},
			{
				Header: "Message Type",
				accessor: "MessageType"
			},
			{
				Header: "Client Name",
				accessor: "ClientName",
				show: !this.IsClient
			},

			{
				Header: "Date of Request",
				accessor: "DateOfRequest",
				type: GridColumnType.Date
			},
			{
				Header: "Value Effective",
				accessor: "ValueEffective",
				type: GridColumnType.DateTime
			},
			{
				Header: "Sender Name",
				accessor: "WireTransferMSBFileDetail.SenderName"
			},
			{
				Header: "BeneficiaryName",
				accessor: "WireTransferMSBFileDetail.BeneficiaryName"
			},
			{
				Header: "Amount",
				accessor: "WireTransferMSBFileDetail.Amount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Currency",
				accessor: "Currency"
			},
			{
				Header: "Sending Country",
				accessor: "SendingCountry"
			},
			{
				Header: "Destination Country",
				accessor: "DestinationCountry"
			},

		];

		this.detailColumns = [

			{
				Header: "Settlement Direction",
				accessor: "WireTransferMSBFileDetail.Direction"
			},
			{
				Header: "Payment Amount",
				accessor: "WireTransferMSBFileDetail.OriginalAmount"
			},
			{
				Header: "Settlement Amount",
				accessor: "WireTransferMSBFileDetail.SettlementAmount"
			},
			{
				Header: "Settlement Route",
				accessor: "WireTransferMSBFileDetail.SettlementRoute"
			},
			{
				Header: "Settlement Transit",
				accessor: "WireTransferMSBFileDetail.SettlementTransit"
			},
			{
				Header: "OrderingInstIdentifier",
				accessor: "WireTransferMSBFileDetail.OrderingInstIdentifier"
			},
			{
				Header: "Originator Route",
				accessor: "WireTransferMSBFileDetail.OriginatorRoute"
			},
			{
				Header: "Originator Transit",
				accessor: "WireTransferMSBFileDetail.OriginatorTransit"
			},
			{
				Header: "Settlement Transit",
				accessor: "WireTransferMSBFileDetail.SettlementTransit"
			},
			{
				Header: "Ordering Inst. Name",
				accessor: "WireTransferMSBFileDetail.OrderingInstName"
			},
			{
				Header: "Ordering Institution Addr1",
				accessor: "WireTransferMSBFileDetail.OrderingInstitutionAddr1"
			},
			{
				Header: "Ordering Institution Addr2",
				accessor: "WireTransferMSBFileDetail.OrderingInstitutionAddr2"
			},
			{
				Header: "Ordering Institution Addr3",
				accessor: "WireTransferMSBFileDetail.OrderingInstitutionAddr3"
			},
			{
				Header: "Contact Name",
				accessor: "WireTransferMSBFileDetail.ContactName"
			},
			{
				Header: "Deliver To",
				accessor: "WireTransferMSBFileDetail.DeliverTo"
			},
			{
				Header: "Receiver BIC",
				accessor: "WireTransferMSBFileDetail.ReceiverBIC"
			},
			{
				Header: "Dest Branch Identifier",
				accessor: "WireTransferMSBFileDetail.DestBranchIdentifier"
			},
			{
				Header: "Destination Route",
				accessor: "WireTransferMSBFileDetail.DestinationRoute"
			},
			{
				Header: "Contact Name",
				accessor: "WireTransferMSBFileDetail.ContactName"
			},
			{
				Header: "Deliver To",
				accessor: "WireTransferMSBFileDetail.DeliverTo"
			},
			{
				Header: "Receiver BIC",
				accessor: "WireTransferMSBFileDetail.ReceiverBIC"
			},
			{
				Header: "Dest Branch Identifier",
				accessor: "WireTransferMSBFileDetail.DestBranchIdentifier"
			},

			{
				Header: "Destination Route",
				accessor: "WireTransferMSBFileDetail.DestinationRoute"
			},
			{
				Header: "Destination Transit",
				accessor: "WireTransferMSBFileDetail.DestinationTransit"
			},
			{
				Header: "Dest. Branch Name",
				accessor: "WireTransferMSBFileDetail.DestBranchName"
			},
			{
				Header: "Dest. Branch Addr1",
				accessor: "WireTransferMSBFileDetail.DestBranchAddr1"
			},
			{
				Header: "Dest. Branch Addr2",
				accessor: "WireTransferMSBFileDetail.DestBranchAddr2"
			},
			{
				Header: "Dest. Branch Addr3",
				accessor: "WireTransferMSBFileDetail.DestBranchAddr3"
			},
			{
				Header: "Dest Country",
				accessor: "WireTransferMSBFileDetail.DestCountry"
			},
			{
				Header: "Rec Corresondent Name",
				accessor: "WireTransferMSBFileDetail.RecCorresondentName"
			},
			{
				Header: "Rec Corresondent Identifier",
				accessor: "WireTransferMSBFileDetail.RecCorresondentIdentifier"
			},
			{
				Header: "Rec Corresondent Addr1",
				accessor: "WireTransferMSBFileDetail.RecCorresondentAddr1"
			},
			{
				Header: "Rec Corresondent Addr2",
				accessor: "WireTransferMSBFileDetail.RecCorresondentAddr2"
			},
			{
				Header: "Rec Corresondent Addr3",
				accessor: "WireTransferMSBFileDetail.RecCorresondentAddr3"
			},
			{
				Header: "Intermediary Name",
				accessor: "WireTransferMSBFileDetail.IntermediaryName"
			},
			{
				Header: "Intermediary Addr1",
				accessor: "WireTransferMSBFileDetail.IntermediaryAddr1"
			},
			{
				Header: "Intermediary Addr2",
				accessor: "WireTransferMSBFileDetail.IntermediaryAddr2"
			},
			{
				Header: "IntermediaryAddr3",
				accessor: "WireTransferMSBFileDetail.IntermediaryAddr3"
			},
			{
				Header: "Branch Buy",
				accessor: "WireTransferMSBFileDetail.BranchBuy"
			},
			{
				Header: "Rate Special Rate",
				accessor: "WireTransferMSBFileDetail.RateSpecialRate"
			},
			{
				Header: "Exchange Rate Date",
				accessor: "WireTransferMSBFileDetail.ExchangeRateDate"
			},
			{
				Header: "Payment Method",
				accessor: "WireTransferMSBFileDetail.PaymentMethod"
			},
			{
				Header: "Rate Special Rate",
				accessor: "WireTransferMSBFileDetail.RateSpecialRate"
			},
			{
				Header: "Exchange Rate Date",
				accessor: "WireTransferMSBFileDetail.ExchangeRateDate"
			},

			{
				Header: "Branch Sell Rate",
				accessor: "WireTransferMSBFileDetail.BranchSellRate"
			},

			{
				Header: "Payment Method",
				accessor: "WireTransferMSBFileDetail.PaymentMethod"
			},

			{
				Header: "Sender Type",
				accessor: "WireTransferMSBFileDetail.SenderType"
			},
			{
				Header: "Sender Addr1",
				accessor: "WireTransferMSBFileDetail.SenderAddr1"
			},
			{
				Header: "Sender Addr2",
				accessor: "WireTransferMSBFileDetail.SenderAddr2"
			},
			{
				Header: "Sender Addr3",
				accessor: "WireTransferMSBFileDetail.SenderAddr3"
			},
			{
				Header: "Id Country",
				accessor: "WireTransferMSBFileDetail.IdCountry"
			},
			{
				Header: "Id Type",
				accessor: "WireTransferMSBFileDetail.IdSender"
			},
			{
				Header: "Id Issuer",
				accessor: "WireTransferMSBFileDetail.IdIssuer"
			},
			{
				Header: "Sender Phone No",
				accessor: "WireTransferMSBFileDetail.SenderPhoneNo"
			},
			{
				Header: "Principle Natureof Business",
				accessor: "WireTransferMSBFileDetail.PrincipleNatureofBusiness"
			},
			{
				Header: "Sender Birth Date",
				accessor: "WireTransferMSBFileDetail.SenderBirthDate"
			},
			{
				Header: "Company Representative",
				accessor: "WireTransferMSBFileDetail.CompanyRepresentative"
			},
			{
				Header: "Reason For Transfer",
				accessor: "WireTransferMSBFileDetail.ReasonForTransfer"
			},
			{
				Header: "Additional Sender Info",
				accessor: "WireTransferMSBFileDetail.AdditionalSenderInfo"
			},
			{
				Header: "Beneficiary Addr1",
				accessor: "WireTransferMSBFileDetail.BeneficiaryAddr1"
			},
			{
				Header: "Beneficiary Addr2",
				accessor: "WireTransferMSBFileDetail.BeneficiaryAddr2"
			},
			{
				Header: "Beneficiary Addr3",
				accessor: "WireTransferMSBFileDetail.BeneficiaryAddr3"
			},
			{
				Header: "Recipient Information",
				accessor: "WireTransferMSBFileDetail.RecipientInformation"
			},
			{
				Header: "Special Instructions",
				accessor: "WireTransferMSBFileDetail.SpecialInstructions"
			},
			{
				Header: "Internal Notes",
				accessor: "WireTransferMSBFileDetail.InternalNotes"
			},
			{
				Header: "Additional SWIFT Information",
				accessor: "WireTransferMSBFileDetail.AdditionalSWIFTInformation"
			},
			{
				Header: "Member Service Charge",
				accessor: "WireTransferMSBFileDetail.MemberServiceCharge"
			},
			{
				Header: "Billing Code",
				accessor: "WireTransferMSBFileDetail.BillingCode"
			},
			{
				Header: "Billing System",
				accessor: "WireTransferMSBFileDetail.BillingSystem"
			},
			{
				Header: "NON Negotiable",
				accessor: "WireTransferMSBFileDetail.NONNegotiable"
			},
			{
				Header: "Created By",
				accessor: "WireTransferMSBFileDetail.CreatedBy"
			},
			{
				Header: "Created Date",
				accessor: "WireTransferMSBFileDetail.CreatedDate"
			},
			{
				Header: "Date Last Modified",
				accessor: "WireTransferMSBFileDetail.DateLastModified"
			},
			{
				Header: "Last Modified By",
				accessor: "WireTransferMSBFileDetail.LastModifiedBy"
			},
			{
				Header: "WireStatus",
				accessor: "WireTransferMSBFileDetail.WireStatus"
			},
			{
				Header: "Beneficiary Birth Date",
				accessor: "WireTransferMSBFileDetail.BeneficiaryBirthDate"
			},
			{
				Header: "Receiving Institution",
				accessor: "WireTransferMSBFileDetail.ReceivingInstitution"
			}

		];

		this.renderColumns = [{
			Header: "Actions",
			sortable: false,
			filterable: false,
			width: 80,
			Cell: row => (
				<div>

					<GridButton
						tooltip="Msb Edit"
						OnClick={() => this.HandleOpenEdit(row.index)}
						Icon={EditIcon}
					/>
				</div>
			)
		},

		... this.columns,
		...this.detailColumns];

		this.defaultHeader = { TotalTransactionAmount: null, SubTransactionsTotal: null, MainTransactionAmount: null };

		this.initialModel = {
			Id: 0,
			ClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined,

		};
		this.IsClient = ClientHelper.IsClient();
		this.state = {
			model: { ...this.initialModel },
			headerModel: { ... this.defaultHeader },
			subStatusList: [],
			tempSubStatusList: [],
			transactionList: [],
			isLoading: false,
			ShowFileDetail: false,
			FileDetail: [],
			approvalTransactionList: [],
			approvalDialog: false,
			detailDialog: false,
			ApprovalModel: {},
			templateDetailModel: {},
			msbFileList: [],
			fileInfoModel: {}
		};


		this.subTransactionColumns = [
			...this.renderColumns
		]
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Msb File List");
		this.props.setAppCenterTitle("Wire Transfer");

	}

	handleChange = (name, newValue) => {
		this.setState(function (state) {
			var model = state.model || {};
			model[name] = newValue;
			return { model };
		});
	}


	GetFileList = () => {
		Proxy.POST(
			"/bankapi/v1.0/ClearingSettlement/GetMsbFile",
			this.state.model,
			responseData => {
				if (!responseData.IsSucceeded)
					this.showBasicAlert("Error", "Send operation error : " + responseData.ErrorDescription + "!!!", "error");
				else {
					this.setState({
						msbFileList: responseData.Item, isLoading: false, transactionList: []
					});
				}
			},
			error => {
				this.showBasicAlert("Error", "An error occurred during the api visit" + error, "error");
			}
		);
	}

	GetData = (FileId) => {
		this.showLoading();

		this.setState({
			transactionList: [],
			headerModel: { TotalTransactionAmount: null, SubTransactionsTotal: null, MainTransactionAmount: null }
		}, () => {
			Proxy.POST(
				"/bankapi/v1.0/ClearingSettlement/GetMSBSubTransactionsDetail",
				{ FileId: FileId },
				responseData => {
					if (!responseData.IsSucceeded)
						this.showBasicAlert("Error", "Send operation error : " + responseData.ErrorDescription + "!!!", "error");
					else {
						var transactionList = [];


						var headerModel = { ... this.defaultHeader };
						if (responseData.Item != null &&
							responseData.Item.DetailList != null &&
							responseData.Item.DetailList.length != 0) {
							transactionList = responseData.Item.DetailList;
							headerModel = responseData.Item.TransactionInfo;
						}
						this.setState({
							transactionList, headerModel, isLoading: false
						});
					}
				},
				error => {
					this.showBasicAlert("Error", "An error occurred during the api visit" + error, "error");
				}
			);
		});
	}

	HandleView = () => {
		this.setState({ isLoading: true });
		const { ExecuteApiFileDownloadWithGenericResponse } = this.props;

		Proxy.POST(
			"/bankapi/v1.0/WireTransfer/GetMsbFileTemplateInfo",
			{ FileId: this.state.fileInfoModel.FileInformationId },
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					var fileInfo = responseData.Item;
					ExecuteApiFileDownloadWithGenericResponse("/coreapi/v1.0/File/DownloadFileFromBinary", { FileId: fileInfo.Id }, fileInfo.FileName, fileInfo.FileExtension.ParameterDesc);
					this.setState({ isLoading: false });
				}
				else {
					this.setState({ isLoading: false });
					this.props.showMessage("error", "Error", "File Not Found!");
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	showBasicAlert = (title, message, type) => {
		this.setState({ isLoading: false, alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={false} OnConfirm={() => this.hideAlert()} /> });
	}

	showLoading = () => { this.setState({ alert: null, isLoading: true }); }
	hideLoading = () => { this.setState({ alert: null, isLoading: false }); }
	hideAlert = () => { this.setState({ alert: null, isLoading: false }); }

	ClearModel = () => {
		this.setState({

			model: { FromDate: DateHelper.GetDate(), ToDate: DateHelper.GetDate(), ClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined, },
			ShowFileDetail: false,
			FileDetail: []
		});
	}

	HandleOpenDetail = (index) => {
		const approveModel = this.state.transactionList[index].WireTransferMSBFileDetail;
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/WireTransfer/MsbShowSubDetail",
			{ Id: approveModel.Id },
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					this.CloseDialog();

					return;
				}
				if (responseData.Item !== null) {

					this.setState({ approvalDialog: false, ShowFileDetail: true, ApprovalModel: responseData.Item });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);


	}
	HandleOpenEdit = (index) => {
		const approveModel = this.state.transactionList[index].WireTransferMSBFileDetail;
		const templateDetailModel = this.state.transactionList[index].WireBeneficiaryTemplate;
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/WireTransfer/MsbShowSubDetail",
			{ Id: approveModel.Id },
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					this.CloseDialog();

					return;
				}
				if (responseData.Item !== null) {

					this.setState({ approvalDialog: true, ShowFileDetail: true, ApprovalModel: responseData.Item, templateDetailModel });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}
	HandleClose = () => {
		this.CloseDialog();
	}

	CloseDialog = () => {
		this.setState({ detailDialog: false, approvalDialog: false, ShowFileDetail: false });
	}

	SelectedRowChange = (index) => {
		const tempModel = this.state.msbFileList[index];
		const { model } = { ...this.state };
		if (tempModel.FileInformationId == null || tempModel.FileInformationId == undefined)
			this.showBasicAlert("Error", "FileInformation can not be null", "error");
		else {
			model.FileName = tempModel.FileName;
			this.setState({ fileInfoModel: tempModel, isLoading: true, rowIndex: index, model });
			this.GetData(tempModel.FileInformationId);

		}
	}
	render() {
		const { Disabled } = this.props;
		const { model, headerModel, isLoading, transactionList, ShowFileDetail, templateDetailModel, fileInfoModel } = this.state;

		const showSummary = headerModel.SubTransactionsTotal || headerModel.MainTransactionAmount || headerModel.TotalTransactionAmount;
		return (
			<div>
				<LoadingComponent Show={isLoading} />

				{this.state.alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.GetFileList, Disabled: Disabled },
					{ Code: "View", OnClick: this.HandleView, Disabled: Disabled || !(fileInfoModel.FileInformationId > 0) },
					{ Code: "Clear", OnClick: this.ClearModel, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<Card>

					<GenericExpansionPanel Title={"Filter Panel"}>
						<CardBody>
							<GridContainer>
								<GridItem xs={4}>
									<GenericSelectInput
										Name="ClientId"
										LabelText="Client"
										Method="POST"
										Url="/bankapi/v1.0/BankCustomer/Search"
										Parameter={{}}
										DataRoot="Item"
										KeyValueMember="Id"
										RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
										Value={model.ClientId || ""}
										ValueChanged={this.handleChange}
										All
										Disabled={this.IsClient} />
									<GenericDateInput
										key={model.TransactionEndDate}
										Name="TransactionDate"
										LabelText="Start Date"
										Utc={true}
										Value={model.TransactionDate == null || undefined ? "" : model.TransactionDate}
										ValueChanged={this.handleChange}
										MaxDate={model.TransactionEndDate || DateHelper.GetDate()}
										IncludeTime={false} />



								</GridItem>
								<GridItem md={4} sm={12} xs={12}>

									<GenericTextInput
										Name="FileName"
										LabelText="File Name"
										Value={model.FileName}
										ValueChanged={this.handleChange}
									/>
									<GenericDateInput
										Name="TransactionEndDate"
										LabelText="End Date"
										Utc={true}
										Value={model.TransactionEndDate == null || undefined ? "" : model.TransactionEndDate}
										ValueChanged={this.handleChange}
										MinDate={model.TransactionDate == null ? null : model.TransactionDate}
										MaxDate={DateHelper.GetDate()}
										IncludeTime={false} />
								</GridItem>
								<GridItem md={4} sm={6} xs={12}>
									{showSummary &&
										<Card>
											<CardBody>
												{headerModel.MainTransactionAmount &&
													<GenericNumberInput
														LabelText="Main Transaction Amount"
														LabelMd={8}
														Value={headerModel.MainTransactionAmount}
														Prefix="$"
														Disabled={true} />
												}
												{headerModel.SubTransactionsTotal &&
													<GenericNumberInput
														LabelText="Sub-Transactions Total"
														LabelMd={8}
														Value={headerModel.SubTransactionsTotal}
														Prefix="$"
														Disabled={true} />
												}
												{headerModel.TotalTransactionAmount &&
													<GenericNumberInput
														LabelText="Sub-Transactions Total(CAD)"
														LabelMd={8}
														Value={headerModel.TotalTransactionAmount}
														Prefix="$"
														Disabled={true} />
												}
											</CardBody>
										</Card>
									}
								</GridItem>
								<br />
							</GridContainer>

						</CardBody>
					</GenericExpansionPanel>
				</Card>


				<Card className="no-radius">
					<GenericExpansionPanel Title={"Msb Upload File List"}>
						<CardBody>
							<GridItem xs={12}>
								<GridItem>
									<GenericGrid
										Data={this.state.msbFileList}
										Columns={[
											{
												Header: "File Name",
												accessor: "FileName"
											},
											{
												Header: "Client Name",
												accessor: "ClientName",
												show: !this.IsClient
											},
											{
												Header: "File Date",
												accessor: "FileDate",
												Cell: row => (<div>{Formatter.FormatDateUtc(row.value)}</div>)
											},
											{
												Header: "Record Count",
												accessor: "RecordCount"
											},
											{
												Header: "File Status",
												accessor: "FileStatus"
											}
										]}
										RowSelected={index => {
											this.SelectedRowChange(index);
										}}
										SelectedIndex={this.state.rowIndex}
										ProgramCode={ProgramCodes.Bank_Programs_Bank_Account}
										ShowPagination={true}
									/>
								</GridItem>
							</GridItem>
						</CardBody>
					</GenericExpansionPanel>

				</Card>



				<Card className="no-radius">
					<CardBody>
						<CardHeader>
							<GenericTitle text={"Sub-Transaction List"} />
						</CardHeader>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									//Data={transactionList.filter(i => i.MsbStatusValue != WireMsbStatusValue.Pending)}
									Data={transactionList}
									Columns={this.subTransactionColumns} 
									ShowPagination={true}

									/>
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>

				<GenericDialog open={ShowFileDetail} maxWidth="lg">
					<DialogContent>
						<h4 style={{ color: "black", textAlign: "center" }}><b>Transaction Details</b></h4>
						<br />

						<BeneficiaryDetail
							CompanyName={templateDetailModel.CompanyName}
							Name={templateDetailModel.Name}
							MiddleName={templateDetailModel.MiddleName}
							LastName={templateDetailModel.LastName}
							AddressLine1={templateDetailModel.AddressLine1}
							AddressLine2={templateDetailModel.AddressLine2}
							ZipCode={templateDetailModel.ZipCode}
							CityId={templateDetailModel.CityId}
							ProvinceId={templateDetailModel.ProvinceId}
							CountryId={templateDetailModel.CountryId}
							IsDomestic={templateDetailModel.BankCountry != null && templateDetailModel.BankCountry.ISO2 == "CA"}
							BankCountryId={templateDetailModel.BankCountryId}
							BankFinInsId={templateDetailModel.BankFinInsId}
							BankName={templateDetailModel.BankName}
							BankBranchId={templateDetailModel.BankBranchId}
							BankTransitNumber={templateDetailModel.BankTransitNumber}
							AccountNumber={templateDetailModel.AccountNumber}
							BankSwiftCode={templateDetailModel.BankSwiftCode}
							BankAddressLine1={templateDetailModel.BankAddressLine1}
							BankAddressLine2={templateDetailModel.BankAddressLine2}
							BankZipCode={templateDetailModel.BankZipCode}
							BankCityId={templateDetailModel.BankCityId}
							BankProvinceId={templateDetailModel.BankProvinceId}
							BankInstitution={templateDetailModel.BankInstitution}
							Alias={templateDetailModel.Alias}
							Disabled={true} />
						<br />
					</DialogContent>
					<GenericDialogActions>

						<Button size="sm" onClick={this.HandleClose}>
							Close
						</Button>
					</GenericDialogActions>
				</GenericDialog>
			</div>
		);
	}
}

MsbFileList.propTypes = {
	classes: PropTypes.object,
	handleOperationType: func,
	showQuestionMessage: func,
	setAppLeftTitle: func,
	setAppCenterTitle: func,
	Disabled: bool,
	ExecuteApiFileDownloadWithGenericResponse: PropTypes.func,

};

export default withArtifex(MsbFileList, {});