import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import AlertHelper from "core/AlertHelper";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import { EditIcon } from "core/Icons";
import RouteHelper from "core/RouteHelper";
import PropTypes from "prop-types";
import React from "react";
import AccountLimitToolbar from "views/Components/AccountLimitToolbar";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDateInput, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { GridColumnType, ProgramCodes, TransactionOrderStatus } from "views/Constants/Constant.js";

class Transactions extends React.Component {
	constructor(props) {
		super(props);

		this.isClient = ClientHelper.IsClient(); 
		this.clientId = ClientHelper.GetClientId();
		this.initialModel = {
			StartingFromDate: DateHelper.GetDate(),
			EndOfDate: DateHelper.GetDate(),
		};

		this.sortParamDesc = { Member: "ParameterDesc" };

		this.state = {
			list: [],
			model: { ...this.initialModel },
			countModel: {},
			isLoading: false,
			LimitToken: false,
			SelectedRowIndex: undefined,
			SelectedRow: {}
		};

		this.HandleClear = this.HandleClear.bind(this);
		this.HandleChange = this.HandleChange.bind(this);

		this.HandleGetList = this.HandleGetList.bind(this);
		this.HandleCancel = this.HandleCancel.bind(this);
		this.Cancel = this.Cancel.bind(this);
		this.SuccessListCallback = this.SuccessListCallback.bind(this);
		this.ErrorListCallback = this.ErrorListCallback.bind(this);
		this.RowSelected = this.RowSelected.bind(this);

		this.ShowMessage = this.ShowMessage.bind(this);
		this.hideAlert = this.hideAlert.bind(this);
	}

	componentDidMount() {
		const { setAppLeftTitle, setAppCenterTitle } = this.props;

		if (setAppLeftTitle)
			setAppLeftTitle("Recurring Transaction Search");
		if (setAppCenterTitle)
			setAppCenterTitle("EFT SYSTEM");

		this.RefreshToolBar();
	}

	RefreshToolBar = () => {
		this.setState({ LimitToken: !this.state.LimitToken });
	}

	HandleClear() {
		if (this.isClient) {
			this.initialModel.UniqueClientId = this.state.model?.UniqueClientId;
		}
		this.setState({ model: { ...this.initialModel } });
	}

	HandleChange(name, value) {
		const model = { ...this.state.model };
		model[name] = value;
		this.setState({ model });
	}

	HandleGetList() {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/BankBmoEFT/SearchRecurringEFTTransactions",
			this.state.model,
			this.SuccessListCallback,
			this.ErrorListCallback
		);
	}

	HandleCancel() {
		const { SelectedRow } = this.state;
		var status = SelectedRow.TransactionOrderStatus.ParameterValue;
		if (status != TransactionOrderStatus.Working && status != TransactionOrderStatus.Pending) {
			this.props.showMessage("warning", "You can only cancel working or pending recurring transactions");
			return;
		}

		this.props.showConfirmMessage("warning", "Recurring Transaction Cancel Confirmation", "Are you sure to cancel this recurring transaction?", () => { this.Cancel(); });
	}

	Cancel() {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/BankBmoEFT/CancelRecurringRequest",
			{ Id: this.state.SelectedRow.Id },
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "An error occurred while cancelling recurring transaction", responseData.ErrorDescription);
					return;
				}

				this.props.showMessage("info", "Recurring Transaction Cancelled.");

				this.HandleGetList();

				this.setState({ SelectedRow: undefined, SelectedRowIndex: undefined });
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "An error occurred while cancelling recurring transaction", errorMessage);
			}
		);
	}

	SuccessListCallback(responseData) {
		this.setState({ isLoading: false });
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error", responseData.ErrorDescription);
			return;
		}
		if (responseData.Item !== null) {
			this.setState({ list: responseData.Item });
		} else {
			this.setState({ list: [] });
		}
	}

	ErrorListCallback(error) {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", error);
	}

	ShowMessage(type, title, message) {
		this.setState({ alert: AlertHelper.CreateAlert(title, message, type, this.hideAlert) });
	}

	hideAlert() {
		this.setState({ alert: null });
	}

	RowSelected(index) {
		this.setState({ SelectedRowIndex: index, SelectedRow: this.state.list[index] });
	}

	render() {
		const { Disabled } = this.props;
		const { alert, isLoading, model, list, LimitToken, SelectedRowIndex } = this.state;
		return (
			<div>
				{this.isClient && <AccountLimitToolbar key={LimitToken} Program={ProgramCodes.Bank_Programs_EFT} />}
				<LoadingComponent Show={isLoading} />
				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.HandleGetList, Disabled: Disabled },
					{ Code: "Cancel", OnClick: this.HandleCancel, Disabled: Disabled || SelectedRowIndex == null },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled || model === this.initialModel }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Parameter">
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={{ UniqueClientId: this.isClient ? this.clientId : undefined }}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
												Value={model.UniqueClientId || ""}
												ValueChanged={this.HandleChange}
												CanClear
												Disabled={Disabled || this.isClient}
												DefaultIndex={this.isClient ? 0 : -1} />
											<ParameterComponent
												Name="PriorityTypeId" Value={model.PriorityTypeId}
												LabelText="EFT Type" ParameterCode="EFTType"
												ValueChanged={this.HandleChange} />
											<ParameterComponent
												Name="TransactionOrderStatusId" Value={model.TransactionOrderStatusId}
												LabelText="Transaction Order Status" ParameterCode="TransactionOrderStatus"
												ValueChanged={this.HandleChange} Sorted={{ Member: "ParameterDesc" }} />
											<GenericSelectInput
												Name="TransactionTypeId"
												LabelText="Transaction Type"
												Method="GET"
												Url="/bankapi/v1.0/Transaction/GetAllFinancialTransactionType"
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="ParameterDesc"
												Value={model.TransactionTypeId}
												ValueChanged={this.HandleChange} />
											<ParameterComponent
												Name="TransactionCodeId"
												LabelText="EFT Transaction Code"
												ParameterCode="EFTTransactionType"
												Value={model.TransactionCodeId}
												ValueChanged={this.HandleChange}
												Sorted={this.sortParamDesc}
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericTextInput
												Name="PayeeName"
												LabelText="Customer Name"
												Value={model.PayeeName}
												ValueChanged={this.HandleChange} />
											<GenericTextInput
												Name="TransactionAmount"
												LabelText="Amount"
												Value={model.TransactionAmount}
												ValueChanged={this.HandleChange} />
											<ParameterComponent
												Name="FirstDueDateOptionId" Value={model.FirstDueDateOptionId}
												LabelText="First Due Date Option" ParameterCode="EFTRecurringPeriod"
												ValueChanged={this.HandleChange} Sorted={{ Member: "ParameterDesc" }} />
											<GenericNumberInput
												NoFormatting={true}
												Name="AccountNumber"
												LabelText="Wallet Number"
												Value={model.AccountNumber}
												ValueChanged={this.HandleChange}
												MaxLength={12} />
											<GenericSelectInput
												Name="FinancialInstitutionId"
												LabelText="Financial Institution"
												Method="GET"
												Url="/bankapi/v1.0/FinancialInstitution/GetAll"
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Description"
												Value={model.FinancialInstitutionId || -1}
												ValueChanged={this.HandleChange}
												Sorted={{ Member: "Description" }}
												All={true} />
										</GridItem>
										<GridItem xs={4}>
											<GenericDateInput
												Name="StartingFromDate"
												LabelText="Start Date"
												Value={model.StartingFromDate ? DateHelper.ToDateInputValueAsUtc(model.StartingFromDate) : ""}
												MaxDate={model.EndOfDate || DateHelper.GetDate()}
												ValueChanged={this.HandleChange}
												Utc />
											<GenericDateInput
												Name="EndOfDate"
												LabelText="End Date"
												Value={model.EndOfDate ? DateHelper.ToDateInputValueAsUtc(model.EndOfDate) : ""}
												MinDate={model.StartingFromDate}
												ValueChanged={this.HandleChange}
												Utc />
											<GenericDateInput
												Name="FrezeeFromDate"
												LabelText="Frezee From Date"
												Value={model.FrezeeFromDate ? DateHelper.ToDateInputValueAsUtc(model.FrezeeFromDate) : ""}
												ValueChanged={this.HandleChange}
												Utc />
											<GenericDateInput
												Name="FrezeeToDate"
												LabelText="Frezee To Date"
												Value={model.FrezeeToDate ? DateHelper.ToDateInputValueAsUtc(model.FrezeeToDate) : ""}
												ValueChanged={this.HandleChange}
												Utc />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardHeader>
								<GridContainer spacing={16} justify="space-between" alignItems="center">
									<GridItem>
										<CardHeader>
											<h4><b>Recurring List</b></h4>
										</CardHeader>
									</GridItem>
								</GridContainer>
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer>
											<GridItem xs={4}>
											</GridItem>
											<GridItem xs={8} />
										</GridContainer>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={[
														{
															Header: "Actions",
															accessor: "Actions",
															Cell: row => (
																<div>
																	<GridButton
																		tooltip="Edit"
																		Icon={EditIcon}
																		OnClick={() => { RouteHelper.Push(this.props.history, "/CreateEFT", "CreateEFT_RecurringId", row.original.Id); }} />
																</div>
															),
															sortable: false,
															filterable: false,
															width: 60
														},
														{
															Header: "CustomerName",
															accessor: "PayeeName"
														},
														{
															Header: "Transaction Type",
															accessor: "TransactionType.ParameterDesc"
														},
														{
															Header: "Financial Institution",
															accessor: "FinancialInstitution.Description"
														},
														{
															Header: "Transit Number",
															accessor: "FinancialInstitutionBranch.TransitNumber"
														},
														{
															Header: "Customer Wallet Number",
															accessor: "AccountNumber"
														},
														{
															Header: "Amount",
															accessor: "TransactionAmount",
															type: GridColumnType.Money,
															ColumnType: GridColumnType.Money
														},
														{
															Header: "Fee Amount",
															accessor: "FeeAmount",
															type: GridColumnType.Money,
															ColumnType: GridColumnType.Money
														},
														{
															Header: "Transaction Order Status",
															accessor: "TransactionOrderStatus.ParameterDesc"
														},
														{
															Header: "EFT Transaction Code",
															accessor: "EFTTransactionType.ParameterDesc"
														},
														{
															Header: "Settlement Type",
															accessor: "PriorityType.ParameterDesc"
														},
														{
															Header: "First Due Date Option",
															accessor: "FirstDueDateOption.ParameterDesc"
														},
														{
															Header: "Total Amount",
															accessor: "TotalAmount",
															Cell: row => Formatter.FormatMoney(row.original.NumberOfPayments * row.original.TransactionAmount)
														},
														{
															Header: "Period",
															accessor: "NumberOfPayments"
														},
														{
															Header: "Start Date",
															accessor: "StartingFromDate",
															type: GridColumnType.DateUtc
														},
														{
															Header: "End Date",
															accessor: "EndOfDate",
															type: GridColumnType.DateUtc
														},
														{
															Header: "Total Payment Count",
															accessor: "NumberOfPayments"
														},
														{
															Header: "CompletedPaymentCount",
															accessor: "CompletedPayments"
														},
														{
															Header: "Remaining Payment Count",
															accessor: "RemainingPayments"
														},
														{
															Header: "Last Payment Status",
															accessor: "LastPaymentStatus"
														},
														{
															Header: "Freeze From Date",
															accessor: "FreezeFromDate",
															type: GridColumnType.DateUtc
														},
														{
															Header: "Freeze To Date",
															accessor: "FreezeToDate",
															type: GridColumnType.DateUtc
														},
														{
															Header: "Transaction Source",
															accessor: "TransactionSource.ParameterDesc"
														},
														{
															Header: "Client User",
															accessor: "InsertUser"
														},
														{
															Header: "Transaction Reference Number",
															accessor: "UniqueReferenceNumber"
														},
														{
															Header: "Store",
															accessor: "CustomerStore",
															width: 150
														},
														{
															Header: "Last Error Status",
															accessor: "LastCheckStatus"
														}
													]}
													RowSelected={this.RowSelected}
													SelectedIndex={SelectedRowIndex}
													ProgramCode={ProgramCodes.Bank_Programs_EFT}
													IsSorted={false} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

Transactions.propTypes = {
	classes: PropTypes.object
};

export default Transactions;
