import { Print } from "@material-ui/icons";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import PropTypes from "prop-types";
import React from "react";
import { GenericAlert } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import TabsComponent from "views/Components/TabsComponent";
import { ActionCodes, ClientType, MenuCodes } from "views/Constants/Constant.js";
//Steps
import ClientSummaryTab from "./ClientSummaryTab";

class ClientSummary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			CustomDisabled: null
		};
	}
	componentDidMount() {

		if (this.props.setAppLeftTitle) {
			if (this.props.MenuCode == MenuCodes.Backoffice_ClientSetup) {
				this.props.setAppLeftTitle("Client Summary");
			}
			if (this.props.isSalesAgentPersonel) {
				this.props.setAppLeftTitle("Sales Agent Summary");
			}
		}

		this.props.FetchClientLimitedAuthority(MenuCodes.CSLimitedClientSummary, ActionCodes.Submit, (res) => this.setState({ CustomDisabled: res }));
	}

	PrintOut = () => {
		const clientType = this.props.paramGlobalModel == null ? "" : this.props.paramGlobalModel.ClientType || "";
		var FileName = "";
		if (clientType != null && ((clientType == ClientType.Personal) || (clientType == ClientType.SalesAgentPersonal))) {
			FileName = this.props.paramCustomerProfile == null ? "noname" : this.props.paramCustomerProfile.Name + " " + this.props.paramCustomerProfile.SurName || "noname";
		}
		else if (clientType != null && ((clientType == ClientType.Corporate) || (clientType == ClientType.SalesAgentCorporate))) {
			FileName = this.props.paramBankCustomerCorporate == null ? "noname" : this.props.paramBankCustomerCorporate.LegalName || "noname";
		} else {
			FileName = "noname";
			return;
		}
		if (this.props.paramGlobalModel.Id == null || this.props.paramGlobalModel.Id == 0) {
			return;
		}


		this.setState({ isLoading: true });

		Proxy.FileDownload(
			"/bankapi/v1.0/BankCustomer/DowloadFile",
			FileName + "-" + Math.floor(Math.random() * Math.floor(999999)),
			"pdf",
			{
				"Id": this.props.paramGlobalModel.Id
			},
			() => {
				this.setState({ isLoading: false });
			},
			(error) => {
				this.setState({ isLoading: false });
				this.showErrorMessage("An error occurred while sending data " + error);
			}
		);
	}
	showErrorMessage = (message) => {
		this.setState({
			alert: <GenericAlert Title="Error" Message={message} Type="error" OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}
	render() {
		const { classes, paramBankCustomerContractList } = this.props;
		const { model } = this.state;
		const { Disabled: DisabledProp } = this.props;
		const Disabled = DisabledProp || this.state.CustomDisabled;

		var data = [];
		if (paramBankCustomerContractList != null) {
			paramBankCustomerContractList.forEach(element => {
				var temp = {
					tabButton: element.Program.Description,
					tabContent: (
						<ClientSummaryTab
							Disabled={Disabled}
							contract={element}
							paramCustomerProfile={this.props.paramCustomerProfile}
							paramGlobalModel={this.props.paramGlobalModel}
							paramBankCustomerCorporate={this.props.paramBankCustomerCorporate}
							paramBankCustomerInternalAccountList={this.props.paramBankCustomerInternalAccountList}
							paramBankCustomerExternalAccountList={this.props.paramBankCustomerExternalAccountList}
							paramBankCustomerSecurityList={this.props.paramBankCustomerSecurityList}
						/>
					)
				};
				data.push(temp);
			});
		}

		return (
			<GridContainer spacing={16}>
				{this.state.alert}
				<LoadingComponent Show={this.state.isLoading} />
				<GridContainer justify="flex-end">
					<GridItem style={{ float: "right" }}>
						<Button disabled={Disabled} justIcon simple size="md" onClick={() => this.PrintOut()} tooltip="Print" style={{ alingn: "left", float: "left", marginTop: "-2px", marginRight: "5px" }}>
							<Print nativeColor="black" />
						</Button>
						<Button disabled={Disabled} size="sm" color="black" onClick={() => {
							if (this.props.isSalesAgentPersonel)
								this.props.handleChangeParentTab(null, 2);
							else if (this.props.MenuCode == MenuCodes.CallCenterClientMonitor)
								this.props.handleChangeParentTab(null, 3);
							else
								this.props.handleChangeParentTab(null, 4);

						}} > Back </Button>
						<Button disabled={Disabled} size="sm" color="black" onClick={() => {
							if (this.props.isSalesAgentPersonel)
								this.props.handleChangeParentTab(null, 4);
							else if (this.props.MenuCode == MenuCodes.CallCenterClientMonitor)
								this.props.handleChangeParentTab(null, 5);
							else
								this.props.handleChangeParentTab(null, 6);

						}} > Next </Button>
					</GridItem>
				</GridContainer>
				<GridItem xs={12}>
					<TabsComponent
						ref={el => (this.componentRef = el)}
						tabList={data}
					/>
				</GridItem>
			</GridContainer>
		);
	}
}

ClientSummary.propTypes = {
	classes: PropTypes.object,
	paramBankCustomerContractList: PropTypes.array,
	paramGlobalModel: PropTypes.object,
	paramCustomerProfile: PropTypes.object,
	paramBankCustomerCorporate: PropTypes.object,
	paramBankCustomerInternalAccountList: PropTypes.array,
	paramBankCustomerExternalAccountList: PropTypes.array,
	handleChangeParentTab: PropTypes.func,
	paramBankCustomerSecurityList: PropTypes.array
};


export default withArtifex(ClientSummary, {});
