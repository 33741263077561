import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes, { bool, func } from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericCheckInput, GenericDateInput, GenericGrid, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { BankFileStatus, GridColumnType, ProgramCodes } from "views/Constants/Constant";

class CATMResponseFile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			model: {
				FromDate: DateHelper.GetDate(),
				ToDate: DateHelper.GetDate(),
				DirectionParameterValue: ""
			},
			getTransactionByFileIdModel: {
				DirectionParameterValue: ""
			},
			dailyFileList: [],
			fileInfoModel: {
				Id: null,
				FileName: "",
				FileStatusParamValue: ""
			},
			transactionList: [],
			isLoading: false,
			checkedValues: [],
			selectList: []
		};
	}

	componentDidMount() {
		this.props.setAppLeftTitle("CATM Response File");
		this.props.setAppCenterTitle("CLEARING & SETTLEMENT");
		this.GetNewCATMFileFromSFTP();
	}

	GetNewCATMFileFromSFTP = () => {
		this.showLoading();
		Proxy.POST(
			"/bankapi/v1.0/BankBmoEFT/InsertCatmResponseTransactionFileAndTransaction",
			{},
			responseData => {
				if (!responseData.IsSucceeded) {
					this.showBasicAlert("Error", responseData.ErrorDescription, "error");
					return;
				}
				this.hideLoading();
			},
			error => {
				this.showBasicAlert("Error", "An error occurred during the api visit" + error, "error");
			}
		);
	}

	handleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}

	SelectedRowChange = (index) => {
		const tempModel = this.state.dailyFileList[index];
		const model = { ...this.state.model };
		if (tempModel.Id == null || tempModel.Id == undefined)
			this.showBasicAlert("Error", "FileInformationId can not be null", "error");
		else {
			this.setState({ fileInfoModel: tempModel, model, isLoading: true, rowIndex: index });
			this.GetTransactionList(tempModel.Id);

		}
	}

	GetFileList = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/BankBmoEFT/SearchCatmResponseTransactionFile",
			this.state.model,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.showBasicAlert("Error", responseData.ErrorDescription, "error");
					return;
				}
				this.setState({
					transactionList: [], headerModel: {},
					dailyFileList: responseData.Item == null ? [] : responseData.Item.length < 1 ? [] : responseData.Item.map(x => {
						return {
							Id: x.FileInformationId == null || undefined ? "" : x.FileInformationId,
							FileName: x.FileName == null || undefined ? "" : x.FileName,
							FileDate: x.FileDate == null || undefined ? "" : x.FileDate,
							FileSource: x.FileSource == null || undefined ? "" : x.FileSource,
							RecordCount: x.RecordCount == null || undefined ? "" : x.RecordCount,
							RejectedCount: x.RejectedCount == null || undefined ? "" : x.RejectedCount,
							FileStatus: x.FileStatus == null || undefined ? "" : x.FileStatus,
							FileStatusParamValue: x.FileStatusParamValue == null ? "" : x.FileStatusParamValue
						};
					}), isLoading: false
				});
			},
			error => {
				this.showBasicAlert("Error", "An error occurred during the api visit" + error, "error");
			}
		);
	}

	GetTransactionList = (Id) => {
		var transactionList = { ...this.state.transactionList };
		Proxy.POST(
			"/bankapi/v1.0/BankBmoEFT/SearchCatmResponseTransaction",
			{ Id: Id },
			responseData => {
				if (!responseData.IsSucceeded) {
					this.showBasicAlert("Error", responseData.ErrorDescription, "error");
					return;
				}
				if (responseData.Item != null) {

					if (responseData.Item != null && responseData.Item.length > 1) {
						this.setState({ transactionList: responseData.Item, isLoading: false, selectList: [], checkedValues: [] })
					}
					else
						this.setState({ transactionList: [], isLoading: false, selectList: [], checkedValues: [] });
				}
			},
			error => {
				this.showBasicAlert("Error", "An error occurred during the api visit" + error, "error");
			}
		);
	}

	Send = () => {
		this.showBasicAlert("Info", "This property  disabled.", "info");
		return;

		// if (this.state.fileInfoModel == null || this.state.fileInfoModel.Id == null)
		// 	this.showBasicAlert("Warning", "Selected a row from File List !!!", "warning");
		// else			
		// const fileInfoModel = { ...this.state.fileInfoModel };
		// if (fileInfoModel != null && fileInfoModel.FileStatusParamValue == BankFileStatus.Waiting) {
		// 	this.showConfirmAlert("Warning", "Are you sure to send this file ?" + "FileName : " + fileInfoModel.FileName, "warning", () => {
		// 		if (fileInfoModel.Id == null || fileInfoModel == undefined)
		// 			this.showBasicAlert("Warning", "Selected row has not FileInformationId value !!!", "warning");
		// 		else {
		// 			this.hideAlert();
		// 			this.showLoading();
		// 			Proxy.POST(
		// 				"/bankapi/v1.0/BankBmoEFT/SendCatmAndArtifexTransactionToBmo",
		// 				{ FileInformationId: fileInfoModel.Id },
		// 				responseData => {
		// 					if (!responseData.IsSucceeded)
		// 						this.showBasicAlert("Error", "Send operation error : " + responseData.ErrorDescription + "!!!", "error");
		// 					else {
		// 						this.showBasicAlert("Success", "Send operation is successfully completed.", "success");
		// 						this.GetFileList();
		// 					}
		// 				},
		// 				error => {
		// 					this.showBasicAlert("Error", "Send operation error : " + error + "!!!", "error");
		// 				}
		// 			);
		// 		}
		// 	});
		// }
		// else
		// 	this.showBasicAlert("Warning", "File Status must be Waiting !!!", "warning");
	}

	showBasicAlert = (title, message, type) => {
		this.setState({ isLoading: false, alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={false} OnConfirm={() => this.hideAlert()} /> });
	}

	showConfirmAlert = (title, message, type, onConfirm) => {
		this.setState({ isLoading: false, alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={onConfirm} /> });
	}

	hideAlert = () => { this.setState({ alert: null, isLoading: false }); }

	showLoading = () => { this.setState({ alert: null, isLoading: true }); }

	hideLoading = () => { this.setState({ isLoading: false }); }

	ClearModel = () => {
		this.setState({
			model: {
				FromDate: DateHelper.GetDate(),
				ToDate: DateHelper.GetDate(),
			}
		});
	}

	HandleCheck(trans) {
		var selectList = [...this.state.selectList];

		selectList = this.state.selectList.includes(trans.Id)
			? this.state.selectList.filter(t => t !== trans.Id)
			: [...this.state.selectList, trans.Id];

		this.setState(state => ({
			selectList,
			checkedValues: state.checkedValues.includes(trans)
				? state.checkedValues.filter(c => c !== trans)
				: [...state.checkedValues, trans]
		}));
	}

	SubmitAccept = () => {
		var selectList = [...this.state.selectList];
		this.showConfirmAlert("Warning", "Are you sure to accept these transactions ?", "warning", () => {
			this.hideAlert();
			this.showLoading();
			Proxy.POST(
				"/bankapi/v1.0/BankBmoEFT/UpdateCatmResponseTransaction",
				{ CatmBmoClearingTransactionIds: selectList },
				responseData => {
					if (!responseData.IsSucceeded)
						this.showBasicAlert("Error", "Accept operation error : " + responseData.ErrorDescription + "!!!", "error");
					else {
						this.showBasicAlert("Success", "Accept operation is successfully completed.", "success");
						this.GetTransactionList(this.state.fileInfoModel.Id);
					}
				},
				error => {
					this.showBasicAlert("Error", "Accept operation error : " + error + "!!!", "error");
				}
			);
		});
	}

	render() {
		const { Disabled } = this.props;
		const { model, fileInfoModel, isLoading } = this.state;
		return (
			<div>
				<LoadingComponent Show={isLoading} />

				{this.state.alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.GetFileList, Disabled: Disabled },
					{ Code: "Submit", OnClick: this.SubmitAccept, Disabled: Disabled },
					{ Code: "Send", OnClick: this.Send, Disabled: true },
					{ Code: "Clear", OnClick: this.ClearModel, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<Card >
					<CardHeader>
						<h5><b> File Filter</b></h5>
					</CardHeader>
					<CardBody>
						<GridContainer>
							<GridItem xs={4}>
								<GenericDateInput
									Name="FromDate"
									LabelText="From Date"
									Value={model.FromDate == null || undefined ? "" : model.FromDate}
									ValueChanged={this.handleChange}
									MaxDate={model.ToDate || DateHelper.GetDate()}
									IncludeTime={false} />
								<GenericDateInput
									Name="ToDate"
									LabelText="To Date"
									Value={model.ToDate == null || undefined ? "" : model.ToDate}
									ValueChanged={this.handleChange}
									MinDate={model.FromDate == null ? null : model.FromDate}
									MaxDate={DateHelper.GetDate()}
									IncludeTime={false} />
								<br />
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>

				<Card className="no-radius">
					<CardHeader>
						<GenericTitle text={" File List"} />
					</CardHeader>
					<CardBody>
						<GenericGrid
							Data={this.state.dailyFileList}
							Columns={[
								{
									Header: "File Name",
									accessor: "FileName"
								},
								{
									Header: "File Date",
									accessor: "FileDate",
									type: GridColumnType.Date
								},
								{
									Header: "Record Count",
									accessor: "RecordCount"
								},
								{
									Header: "Status",
									accessor: "FileStatus"
								}
							]}
							RowSelected={index => {
								this.SelectedRowChange(index);
							}}
							SelectedIndex={this.state.rowIndex}
							ProgramCode={ProgramCodes.Bank_Programs_EFT} />
					</CardBody>
				</Card>
				<Card className="no-radius">
					<CardHeader>
						<GenericTitle text={" Transaction List"} />
					</CardHeader>
					<CardBody>
						<GenericGrid
							Data={this.state.transactionList}
							Columns={[
								{
									Header: "Actions",
									accessor: "Actions",
									Cell: row => (
										<div>
											<GenericCheckInput
												Grid
												Value={this.state.checkedValues.filter(c => c.Id == row.original.Id).length > 0}
												ValueChanged={() => { this.HandleCheck(row.original); }}
												Disabled={fileInfoModel.FileStatusParamValue == BankFileStatus.Waiting ? false : true} />
										</div>
									)
								},
								{
									Header: "Accept",
									accessor: "IsAccept",
									Cell: row => (
										<div>{row.value == true ? "True" : "False"}</div>
									)
								},
								{
									Header: "Logical Record Type",
									accessor: "LogicalRecordType"
								},
								{
									Header: "LogicalRecordCount",
									accessor: "LogicalRecordCount"
								},
								{
									Header: "Originator Id",
									accessor: "OriginatorId"
								},
								{
									Header: "File Creation Number",
									accessor: "FileCreationNumber"
								},
								{
									Header: "Transaction Type",
									accessor: "TransactionType"
								},
								{
									Header: "Amount",
									accessor: "Amount",
									type: GridColumnType.Money,
									ColumnType:GridColumnType.Money
								},
								{
									Header: "Transaction Date",
									accessor: "TransactionDate"
								},
								{
									Header: "Payee Drawee Institution Id",
									accessor: "PayeeDraweeInstitutionId"
								},
								{
									Header: "Payee Drawee Wallet Number",
									accessor: "PayeeDraweeAccountNumber"
								},
								{
									Header: "Originator Short Name",
									accessor: "OriginatorShortName"
								},
								{
									Header: "PayeeDraweeName",
									accessor: "PayeeDraweeName"
								},
								{
									Header: "Originator Long Name",
									accessor: "OriginatorLongName"
								},
								{
									Header: "Cross Reference Number",
									accessor: "CrossReferenceNumber"
								},
								{
									Header: "Logical Record Count",
									accessor: "LogicalRecordCount"
								},
								{
									Header: "Institution Id For Returns",
									accessor: "InstitutionIdForReturns"
								},
								{
									Header: "Wallet Number For Returns",
									accessor: "AccountNumberForReturns"
								}
							]}
							ProgramCode={ProgramCodes.Bank_Programs_EFT} />
					</CardBody>
				</Card>
			</div>
		);
	}
}

CATMResponseFile.propTypes = {
	classes: PropTypes.object,
	handleOperationType: PropTypes.func,
	showQuestionMessage: func,
	setAppLeftTitle: func,
	setAppCenterTitle: func,
	Disabled: bool
};

export default withArtifex(CATMResponseFile, {});