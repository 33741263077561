import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React from "react";
import { GenericTitle, GenericDateInput, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";

class PersonalAccount extends React.Component {
	constructor(props) {
		super(props);
		this.state = {

		};
	}

	componentDidMount() {
		//this.props.hideSidebar();
		this.props.setAppLeftTitle("My Profile / Individual Wallet");
		this.props.setAppCenterTitle("WALLET");
	}
	render() {
		const { model } = this.props;
		return (
			<Card>
				<CardBody>
					<GridContainer spacing={16}>
						<GridItem xs={12} sm={6} md={6} lg={6}>
							<GenericTitle text={" Individual Wallet"}/>
							<GridContainer justify="flex-start">
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<GenericSelectInput Name="TitleId"
										LabelText="Title" Value={model.TitleId || ""}
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={{ ParameterCode: "Title", ParameterValue: "", ParameterDesc: "" }}
										DataRoot="Item" />
									<GenericSelectInput
										Name="GenderId"
										LabelText="Gender"
										Value={model.GenderId || ""}
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={{ ParameterCode: "Gender", ParameterValue: "", ParameterDesc: "" }}
										DataRoot="Item" />
									<GenericTextInput
										Name="Name"
										LabelText="First Name"
										Value={model.Name || ""}
										Disabled={true} />
									<GenericTextInput
										Name="SurName"
										LabelText="Last Name"
										Value={model.SurName || ""}
										Disabled={true} />
									<GenericNumberInput
										Name="Initial"
										LabelText="Initial"
										Value={model.Initial}
										Disabled={true} />
									<GenericDateInput
										Name="BirthDate"
										LabelText="Date of Birth"
										Value={DateHelper.ToDateInputValue(model.BirthDate) || ""}
										Disabled={true} />
									<GenericTextInput
										Name="Occupation"
										LabelText="Occupation"
										Value={model.Occupation == null ? "" : model.Occupation.ParameterDesc || ""}
										Disabled={true} />

								</GridItem>
							</GridContainer>
						</GridItem>
						<GridItem xs={12} sm={6} md={6} lg={6}>
							<GenericTitle text={" Communication"}/>
							<GridContainer justify="flex-start">
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<GenericTextInput
										LabelText="Address Line 1"
										Value={model.CustomerAddress == null ? "" : model.CustomerAddress.Address1}
										Disabled={true} />
									<GenericTextInput
										LabelText="Address Line 2"
										Value={model.CustomerAddress == null ? "" : model.CustomerAddress.Address2}
										Disabled={true} />
									<GenericTextInput
										LabelText="Province"
										Value={model.CustomerAddress == null ? "" : model.CustomerAddress.Provience}
										Disabled={true} />
									<GenericTextInput
										LabelText="City"
										Value={model.CustomerAddress == null ? "" : model.CustomerAddress.City == null ? "" : model.CustomerAddress.City.ParameterDesc || ""}
										Disabled={true} />
									<GenericTextInput
										LabelText="Postal Code" Value={model.CustomerAddress == null ? "" : model.CustomerAddress.ZipCode}
										Disabled={true} />
									<GenericTextInput
										LabelText="E Mail" Value={model.Email || ""}
										Disabled={true} />
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<GridContainer>
										<GridItem xs={8} sm={8} md={8}>
											<GenericSelectInput
												Name="PhoneCountryCodeId"
												LabelText="Code"
												LabelMd={6}
												Method="POST"
												Url="/coreapi/v1.0/Country/GetAll"
												Parameter={{}}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember={"Code"}
												Value={model.PrimaryPhone == null ? "" : model.PrimaryPhone.PhoneCountryCodeId || ""}
												Disabled={true}
												Sorted={{ Member: "OrderIndex" }}
												RenderItem={d => "+" + d.Code + " (" + d.Name + ")"}
											/>
										</GridItem>
										<GridItem xs={4} sm={4} md={4}>
											<GenericTextInput
												IsPhone
												Name="PhoneNumber"
												LabelText="Primary Phone"
												Value={model.PrimaryPhone == null ? "" : model.PrimaryPhone.PhoneNumber || ""}
												Disabled={true} />
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem xs={8} sm={8} md={8}>
											<GenericSelectInput
												Name="PhoneCountryCodeId"
												LabelText="Modsfdsafe Phone"
												LabelMd={6}
												Method="POST"
												Url="/coreapi/v1.0/Country/GetAll"
												Parameter={{}}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember={"Code"}
												Value={model.MobilePhone == null ? "" : model.MobilePhone.PhoneCountryCodeId || ""}
												Disabled={true}
												Sorted={{ Member: "OrderIndex" }}
												RenderItem={d => "+" + d.Code + " (" + d.Name + ")"}
											/>
										</GridItem>
										<GridItem xs={4} sm={4} md={4}>
											<GenericTextInput
												IsPhone
												Name="PhoneNumber"
												Value={model.MobilePhone == null ? "" : model.MobilePhone.PhoneNumber || ""}
												Disabled={true} />
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem xs={3} sm={3} md={3}>
											<GenericSelectInput
												Name="PhoneCountryCodeId"
												LabelText="Alternative Phone"
												LabelMd={6}
												Method="POST"
												Url="/coreapi/v1.0/Country/GetAll"
												Parameter={{}}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember={"Code"}
												Value={model.AlternativePhone == null ? "" : model.AlternativePhone.PhoneCountryCodeId || ""}
												Disabled={true}
												Sorted={{ Member: "OrderIndex" }}
												RenderItem={d => "+" + d.Code + " (" + d.Name + ")"}
											/>
										</GridItem>
										<GridItem xs={9} sm={9} md={9}>
											<GenericTextInput
												Name="PhoneNumber	"
												Value={model.AlternativePhone == null ? "" : model.AlternativePhone.PhoneNumber || ""}
												Disabled={true} />
										</GridItem>
									</GridContainer>
									<GenericSelectInput
										Name="LanguageId"
										LabelText="Preferred Language"
										Value={model.LanguageId || ""}
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={{ ParameterCode: "Language", ParameterValue: "", ParameterDesc: "" }}
										DataRoot="Item"
										Disabled={true} />
									<GenericSelectInput
										Name="SendSms"
										LabelText="Text/SMS Notification"
										Value={model.SendSms == undefined ? "" : String(model.SendSms)}
										KeyValueMember="ParameterValue"
										TextValueMember="ParameterDesc"
										Method="POST" Url="/coreapi/v1.0/Parameter/Search"
										Parameter={{ ParameterCode: "YesNo", ParameterValue: "", ParameterDesc: "" }}
										DataRoot="Item" Disabled={true} />
									<GenericSelectInput
										Name="SendEmail"
										LabelText="E-Mail Notification"
										Value={model.SendEmail == undefined ? "" : String(model.SendEmail)}
										KeyValueMember="ParameterValue"
										TextValueMember="ParameterDesc"
										Method="POST" Url="/coreapi/v1.0/Parameter/Search"
										Parameter={{ ParameterCode: "YesNo", ParameterValue: "", ParameterDesc: "" }}
										DataRoot="Item"
										Disabled={true} />
								</GridItem>
							</GridContainer>
						</GridItem>
					</GridContainer>
				</CardBody>
			</Card>
		);
	}
}

PersonalAccount.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	model: PropTypes.object
};

export default PersonalAccount;