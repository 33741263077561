import { Card } from "@material-ui/core";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import PropTypes, { any } from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import { GenericExpansionPanel, GenericGrid, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";

class EmvScriptTemplate extends Component {
	constructor(props) {
		super(props);



		this.state = {
			isEdit: false,
			isEditTemplate: false,
			model: {},
			templateModel: {},
			tempCard: {},
			isLoading: false,
			handleList: [],
			fullTagList: [],
			deleteTagList: [],
			templateList: [],
			tempTemplate: {},
			isTemplateExpansionPanelActive: false,
			isTagExpansionPanelActive: false
		};


	}

	componentDidMount() {
		this.props.setAppLeftTitle("EMV Script Template");
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
		this.HandleGetAllTagList();
	}

	HandleClear = () => {
		this.setState({ isEdit: false, model: any });
	}

	AddScriptTemplate = () => {
		const { model, tempCard, handleList, templateModel } = this.state;
		var messages = [];

		if (model.TagName == null || undefined) messages.push("Tag Name can not be null");
		if (model.TagValue == null || undefined || "") messages.push("Tag value can not be null.");
		if (templateModel.TemplateName == null || undefined || "") messages.push("Template can not be null.");

		if (messages.length > 0) {
			this.props.showMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
		}
		else {
			var index = handleList.indexOf(tempCard);
			if (index == -1) {
				handleList.push({
					"TemplateName": templateModel.TemplateName,
					"TagValue": model.TagValue,
					"TagName": model.TagName
				}
				);
			}
			else {
				this.state.handleList[index] = model;
			}
			this.setState({ handleList })
		}
	}

	Clear = () => {
		this.setState({ model: any, templateModel: any });
	}

	Submit = () => {
		var data = this.state.handleList;
		const {  templateModel, deleteTagList } = { ...this.state };
		this.setState({ isLoading: true })
		Proxy.POST("/prepaidapi/v1.0/EmvScriptTemplate/InsertOrUpdateEmvScriptTag",
			{
				emvScriptTemplates: data,
				deleteScriptTemplates: deleteTagList,
				TemplateName: templateModel.TemplateName,
				TemplateId: templateModel.TemplateId
			},
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", responseData.ErrorDescription);
					this.setState({ isLoading: false });
					return;
				}
				else {
					this.props.showMessage("success", "Emv script template transaction successfully");
					this.setState({ handleList: [], deleteTagList: [] });
					this.HandleGetAllTagList();
					this.Clear();
					this.setState({ isLoading: false });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);


	}



	HandleAddTemplate = () => {

		const templateModel = this.state.templateModel;
		Proxy.POST("/prepaidapi/v1.0/EmvScriptTemplate/InsertOrUpdateEmvScriptTemplate",
			{
				TemplateId: templateModel.TemplateId,
				TemplateName: templateModel.TemplateName
			},
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", responseData.ErrorDescription);
					return;
				}
				else {
					this.props.showMessage("success", "Emv script template transaction successfully");
					this.HandleGetAllTemplate();
					this.Clear();
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);

	}

	HandleGetAllTemplate = () => {

		Proxy.POST("/prepaidapi/v1.0/EmvScriptTemplate/GetAllEmvScriptTemplate",
			{

			},
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", responseData.ErrorDescription);
					return;
				}
				else {

					this.setState({ templateList: responseData.Item, isTemplateExpansionPanelActive: true })
					//	this.Clear();
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	HandleDeleteTemplate = () => {

		const { model,  handleList, templateModel } = this.state;
		var messages = [];

		if (model.TagName == null || undefined) messages.push("Tag Name can not be null");
		if (model.TagValue == null || undefined || "") messages.push("Tag value can not be null.");
		if (templateModel.TemplateName == null || undefined || "") messages.push("Template can not be null.");

		if (messages.length > 0) {
			this.props.showMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
		}
		else {
			var index = handleList.indexOf(this.state.tempCard);
			var deleteData = handleList[index];
			this.state.deleteTagList.push({
				"TemplateTagId": deleteData.TemplateTagId,
				"TemplateName": deleteData.TemplateName,
				"TagName": deleteData.TagName,
				"TagValue": deleteData.TagValue,
				"TemplateId": deleteData.TemplateId
			});
			handleList.splice(index, 1);
			this.setState({ isEdit: false, model: any });
		}
	}

	HandleClearTemplate = () => {
		this.setState({ isEditTemplate: false, templateModel: any, model: any });
	}

	TemplateRowSelected = (index) => {

		const tempmodel = this.state.templateModel;
		var data = this.state.templateList[index];
		tempmodel.TemplateName = data.TemplateName;
		tempmodel.TemplateId = data.TemplateId;

		var fullTag = this.state.fullTagList.filter(x => x.TemplateId === tempmodel.TemplateId);
		this.setState({ handleList: fullTag, isEditTemplate: true, templateModel: tempmodel, isTemplateExpansionPanelActive: false, isTagExpansionPanelActive: true });


	}



	HandleGetAllTagList = () => {

		Proxy.POST("/prepaidapi/v1.0/EmvScriptTemplate/GetAllEmvScriptTag",
			{
			},
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", responseData.ErrorDescription);
					return;
				}
				else {
					this.setState({ fullTagList: responseData.Item });
					//	this.Clear();
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);

	}




	RowSelected = (index) => {

		const model = this.state.model;
		var data = this.state.handleList[index];
		model.TemplateTagId = data.Id;
		this.setState({ isEdit: true, model: this.state.handleList[index], tempCard: this.state.handleList[index] });
	}
	HandleChanged = (name, value) => {
		const model = { ...this.state.model };
		model[name] = value;
		this.setState({ model });
	}

	TemplateHandleChange = (name, value) => {
		const templateModel = { ...this.state.templateModel };
		templateModel[name] = value;
		this.setState({ templateModel });
	}

	Search = () => {
		this.HandleGetAllTemplate();

	}

	render() {
		const { model, templateModel, handleList, isEdit, isEditTemplate, isLoading } = this.state;

		return (
			<div>
				<LoadingComponent Show={isLoading} />
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: false,
							OnClick: this.Search
						},
						{
							Code: "Submit",
							Disabled: false,
							OnClick: this.Submit
						},
						{
							Code: "Clear",
							Disabled: false,
							OnClick: this.Clear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After}

				/>

				<br />
				<Card className="no-radius">
					<GenericExpansionPanel Title="Template Name" IsActive={this.state.isTemplateExpansionPanelActive}>
						<CardBody>
							<GridContainer>

								<GridContainer justify="flex-end">
									<GridItem>
										<Button size="sm" onClick={this.HandleAddTemplate} >{isEditTemplate ? "EDIT" : "ADD"}
										</Button>
										{/*
										 <Button size="sm" onClick={this.DeleteScriptTemplate}
										// disabled={!isEdit || ReadOnly}
										>DELETE</Button>
									   */}
										<Button size="sm" onClick={this.HandleClearTemplate}
										// disabled={ReadOnly}
										>CLEAR</Button>
									</GridItem>
								</GridContainer>

								<GridItem xs={12}>
									<GridContainer>
										<GridItem xs={4}>
											<GenericTextInput
												Name="TemplateName"
												LabelText="Template Name"
												Value={templateModel.TemplateName}
												ValueChanged={this.TemplateHandleChange} />
										</GridItem>


									</GridContainer>

									<GridContainer>
										<GridItem xs={12}>
											<br />
											<GenericGrid
												Data={this.state.templateList}
												Columns={[
													{
														Header: "Template Name",
														accessor: "TemplateName"
													},
												]
												}

												RowSelected={this.TemplateRowSelected}
												PageSize={5}
												ShowPagination={true}
												HideButton={true} />

										</GridItem>
									</GridContainer>
								</GridItem>
							</GridContainer>
						</CardBody>
					</GenericExpansionPanel>
				</Card>
				<br />
				<Card className="no-radius">
					<GenericExpansionPanel Title="Tag Values" IsActive={this.state.isTagExpansionPanelActive}>
						<CardBody>
							<GridContainer xs={12}>
								<GridContainer justify="flex-end">
									<GridItem>
										<Button size="sm" onClick={this.AddScriptTemplate} >{isEdit ? "EDIT" : "ADD"}
										</Button>
										<Button size="sm" onClick={this.HandleDeleteTemplate}
										// disabled={!isEdit || ReadOnly}
										>DELETE</Button>
										<Button size="sm" onClick={this.HandleClear}
										// disabled={ReadOnly}
										>CLEAR</Button>
									</GridItem>
								</GridContainer>
								<br />
								<GridContainer>
									<GridItem xs={4}>
										<GenericTextInput
											Name="TemplateName"
											LabelText="Template Name"
											Value={templateModel.TemplateName}
											ValueChanged={this.TemplateHandleChange}
											Disabled={true} />
									</GridItem>
									<GridItem xs={4}>
										<GenericTextInput
											Name="TagName"
											LabelText="Tag Name"
											Value={model.TagName}
											ValueChanged={this.HandleChanged} />
									</GridItem>
									<GridItem xs={4}>
										<GenericTextInput
											Name="TagValue"
											LabelText="Tag Value"
											Value={model.TagValue}
											ValueChanged={this.HandleChanged} />
									</GridItem>
								</GridContainer>
								<br /><br />
								<GridContainer>
									<GridItem xs={12}>
										<br />
										<GenericGrid
											Data={handleList}
											Columns={[
												{
													Header: "Template Name",
													accessor: "TemplateName"
												},
												{
													Header: "Tag Name",
													accessor: "TagName"
												},
												{
													Header: "Tag Value",
													accessor: "TagValue"
												}
											]
											}
											RowSelected={this.RowSelected}
											HideButton={true} />
									</GridItem>
								</GridContainer>
							</GridContainer>
						</CardBody>
					</GenericExpansionPanel>
				</Card>
				{/* </GenericExpansionPanel> */}
			</div >
		);
	}
}

EmvScriptTemplate.propTypes = {
	model: PropTypes.object,
	onModelChange: PropTypes.func,
	Disabled: PropTypes.bool
};

export default EmvScriptTemplate;