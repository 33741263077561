import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React from "react";
import { GenericExpansionPanel, GenericGrid } from "views/Components/Generic";
import { GridColumnType } from "views/Constants/Constant.js";

class Wallet extends React.Component {
	constructor(props) {
		super(props);

		this.initialGeneralModel = {
			Id: 0
		};

		this.initialModel = {
			Id: 0,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined,
			isPanelActive: false
		};

		this.initialCustomerModel = {
			Accounts: [],
			Programs: [],
			LinkedAccounts: []
		};

		this.state = {
			model: this.initialModel,
			vModel: {},
			linkedAccountModel: this.initialGeneralModel,
			accountModel: this.initialGeneralModel,
			accountList: [],
			accountIndex: -1,
			cardList: [],
			customerModel: { ...this.initialCustomerModel },
			tempModel: {},
			customerAddress: {},
			hasChangedLinkedAccount: false,
			hasChangedAccount: false,
			isLoading: false,
			KnownCustomer: false
		};
		// preserve the initial state in a new object
		this.baseState = this.state;

		this.maxLength19 = { maxLength: 19 };

		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined
		};

		this.renderItemUniqueClient = {};

		this.Columns = [
			{
				Header: "Wallet Type",
				accessor: "BankingAccountType.ParameterDesc"
			},
			{
				Header: "Currency",
				accessor: "Currency.Code"
			},
			{
				Header: "Date",
				accessor: "InsertDateTime",
				type: GridColumnType.Date
			},
			{
				Header: "Wallet Name",
				accessor: "AccountName"
			},
			{
				Header: "Wallet Number",
				accessor: "AccountNumber"
			},
			{
				Header: "Full Wallet Number",
				accessor: "IBAN"
			},
			{
				Header: "Balance",
				accessor: "Balance",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Pending Balance",
				accessor: "PendingBalance",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Total Balance",
				accessor: "TotalBalance",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Wallet Status",
				accessor: "AccountStatus.ParameterDesc"
			},
			{
				Header: "Minimum Balance",
				accessor: "MinimumBalance",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Amount",
				accessor: "Amount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			}
		];
		this.ColumnsCard = [
			{
				Header: "Wallet Number",
				accessor: "WalletAccountNumber"
			},
			{
				Header: "Masked Card Number",
				accessor: "MaskedCardNumber"
			},
			{
				Header: "Cardholder Name",
				accessor: "EmbossName1"
			},
			{
				Header: "Card Status",
				accessor: "CardStatusDescription"
			},
			{
				Header: "Balance",
				accessor: "CardBalance",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Pending Balance",
				accessor: "CardPendingBalance",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Usage Period",
				accessor: "CardUsagePeriod.ParameterDesc"
			},
			{
				Header: "Usage Limit",
				accessor: "CardUsageLimit",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			}
		];
	}


	render() {
		const { cardList, accountList } = this.props;

		return (
			<>

				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Wallet List">
									<GridItem xs={12}>
										<br />
										<GenericGrid
											Data={accountList}
											Columns={this.Columns}
											HideButton={true} />
									</GridItem>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Cards">
									<GridItem xs={12}>
										<br />
										<GenericGrid
											Data={cardList}
											Columns={this.ColumnsCard}
											HideButton={true} />
									</GridItem>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

			</>
		);
	}
}

Wallet.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(Wallet, {});