import { withStyles } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import {
  GenericExpansionPanel,
  GenericGrid,
  GenericCheckInput,
  GenericSelectInput,
} from "views/Components/Generic";
import { GridColumnType } from "views/Constants/Constant";
import { Proxy } from "core";
import LoadingComponent from "views/Components/LoadingComponent";
import externalContainerStyle from "assets/jss/material-dashboard-pro-react/layouts/externalContainerStyle.jsx";
import { ParameterEmptyObject } from "views/Constants/Constant.Parameter";
import { SortedName } from "views/Constants/Constant.Sorted";
import { DeleteIcon, EditIcon } from "core/Icons";
import GridButton from "views/Components/GridButton";

class PayClientProvisionProfile extends React.Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        Header: "Id",
        accessor: "Id",
        show: false,
      },
      {
        Header: "Client Id",
        accessor: "UniqueClientId",
      },
      {
        Header: "Approve Failed Avs",
        accessor: "ApproveFailedAvs",
        type: GridColumnType.YesNo,
      },
      {
        Header: "Insert Date Time",
        accessor: "InsertDateTime",
        type: GridColumnType.DateTime,
      },
      {
        Header: "Insert User",
        accessor: "InsertUser",
      },
    ];
    this.getappCenterTitle = "Empty";
    this.getappLeftTitle = "Empty";
    this.zIndex10 = { zIndex: 10 };

    this.state = {
      model: {},
      modelv2: {},
      data: [],
      recordKeeping: [],
      isSendEmailDialogOpen: false,
      activeClient: [],
      responseDetail: {},
      isDetailDialogOpen: false,
    };
    this.cpverTable = React.createRef();
  }

  componentDidMount = () => {
    const { setAppCenterTitle, setAppLeftTitle } = this.props;
    if (setAppCenterTitle) {
      setAppCenterTitle("Profile");
      this.getappCenterTitle = "Profile";
    }
    if (setAppLeftTitle) {
      setAppLeftTitle("Pay Client Provision Profile");
      this.getappLeftTitle = "Pay Client Provision Profile";
    }
  };

  Clear = () => {
    this.setState({ model: {}, data: [], selected: null, modelv2: {} });
  };
  HandleClear = () => {
    this.setState({ modelv2: {} });
  };

  showLoading = () => {
    this.setState({ isLoading: true });
  };
  hideLoading = () => {
    this.setState({ isLoading: false });
  };

  showDetail = async (result) => {
    this.setState({ responseDetail: result || [], isDetailDialogOpen: true });
    console.log(result);
  };

  HandleSearch = () => {
    const model = { ...this.state.model };
    this.setState({ isLoading: true });
    Proxy.POST(
      "/prepaidapi/v1.0/ClientProvisionProfile/Search",
      model,
      (responseData) => {
        this.setState({ isLoading: false });

        if (!responseData.IsSucceeded) {
          this.props.showMessage(
            "error",
            "Error",
            responseData.ErrorDescription
          );
          return;
        }
        if (responseData.Item !== null) {
          this.setState({ data: responseData.Item });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
        this.props.showMessage("error", "Error", error);
      }
    );
  };

  Submit = () => {
    if (!this.validate()) {
      return;
    }
    const model = { ...this.state.modelv2 };
    if (model.Id > 0) {
      this.Update(model);
    } else {
      this.Insert(model);
    }
  };

  Insert = (model)=>{
	this.setState({ isLoading: true });
      Proxy.POST(
        "/prepaidapi/v1.0/ClientProvisionProfile/Insert",
        model,
        (responseData) => {
          this.setState({ isLoading: false });

          if (!responseData.IsSucceeded) {
            this.props.showMessage(
              "error",
              "Error",
              responseData.ErrorDescription
            );
            return;
          } else {
            this.HandleSearch();
            this.HandleClear();
            this.props.showMessage(
              "success",
              "Succeeded",
              "Operation is successfully!"
            );
          }
        },
        (error) => {
          this.setState({ isLoading: false });
          this.props.showMessage("error", "Error", error);
        }
      );

  }

  Update = (model)=>{
	this.setState({ isLoading: true });
	Proxy.POST(
	  "/prepaidapi/v1.0/ClientProvisionProfile/Update",
	  model,
	  (responseData) => {
		 this.setState({ isLoading: false });

		 if (!responseData.IsSucceeded) {
			this.props.showMessage(
			  "error",
			  "Error",
			  responseData.ErrorDescription
			);
			return;
		 } else {
			this.HandleSearch();
			this.HandleClear();
			this.props.showMessage(
			  "success",
			  "Succeeded",
			  "Operation is successfully!"
			);
		 }
	  },
	  (error) => {
		 this.setState({ isLoading: false });
		 this.props.showMessage("error", "Error", error);
	  }
	);
  }
  

  GetRenderItemClient = (x) => {
    var render = `${x.Name} - ${x.UniqueClientId}`;

    return render;
  };
  GetRenderItemClientV2 = (x) => {
    var render = `${x.Name} - ${x.UniqueClientId}`;

    return render;
  };

  ValueChanged = (name, value, data) => {
    const model = { ...this.state.model };
    model[name] = value;
    this.setState({ model });
  };

  ValueChangedv2 = (name, newValue) => {
    const modelv2 = { ...this.state.modelv2 };
    modelv2[name] = newValue;
    this.setState({ modelv2 });
  };

  validate = () => {
    const model = { ...this.state.modelv2 };
    if (model.UniqueClientId == null || model.UniqueClientId == 0) {
      this.props.showMessageNode("warning", "Please Select Client!");
      return false;
    } else {
      return true;
    }
  };

  deleteConfirm = (id) => {
    const { ExecuteApiPost, showConfirmMessage } = this.props;
    showConfirmMessage(
      "warning",
      "Warning",
      "Are you sure you want to delete ?",
      () =>
        ExecuteApiPost(
          "/prepaidapi/v1.0/ClientProvisionProfile/Delete",
          { Id: id },
          null,
          null,
          null,
          [this.HandleSearch],
          true
        )
    );
  };

  HandleEditButton = (modelv2) => () => {
    this.setState({ modelv2: { ...modelv2 } });
  };

  render() {
    const { model, selected, isLoading, data, modelv2 } = this.state;
    const { Disabled } = this.props;

    return (
      <div>
        <LoadingComponent Show={isLoading} />
        <ButtonToolbar
          ButtonList={[
            {
              Code: "Submit",
              Disabled: Disabled,
              OnClick: this.Submit,
            },
            {
              Code: "Search",
              Disabled: Disabled,
              OnClick: this.HandleSearch,
            },
            {
              Code: "Clear",
              Disabled: Disabled,
              OnClick: this.Clear,
            },
          ]}
          menuId={this.props.menuId}
          ApprovalData={this.props.ApprovalData}
          After={this.props.After}
        />

        <GridContainer spacing={16}>
          <GridItem xs={12}>
            <Card className="no-radius">
              <CardBody>
                <GenericExpansionPanel Title="Filter">
                  <GridContainer>
                    <GridItem xs={4}>
                      <GenericSelectInput
                        Required={model.ReviewTypeIsPeriodic}
                        IsInvalid={model.IsUniqueClientId}
                        Name="UniqueClientId"
                        LabelText="Client"
                        Url="/bankapi/v1.0/BankCustomer/Search"
                        Method="POST"
                        Parameter={ParameterEmptyObject}
                        DataRoot="Item"
                        KeyValueMember="Id"
                        RenderItem={this.GetRenderItemClient}
                        Sorted={SortedName}
                        Value={model.UniqueClientId}
                        ValueChanged={this.ValueChanged}
                      />
                    </GridItem>
                  </GridContainer>
                </GenericExpansionPanel>
              </CardBody>
            </Card>
            <Card className="no-radius">
              <CardBody>
                <GenericExpansionPanel Title="Add and Update Panel">
                  <GridContainer>
                    <GridItem xs={4}>
                      <GenericSelectInput
                        Required={model.ReviewTypeIsPeriodic}
                        IsInvalid={model.IsUniqueClientId}
                        Name="UniqueClientId"
                        LabelText="Client"
                        Url="/bankapi/v1.0/BankCustomer/Search"
                        Method="POST"
                        Parameter={ParameterEmptyObject}
                        DataRoot="Item"
                        KeyValueMember="Id"
                        RenderItem={this.GetRenderItemClientV2}
                        Sorted={SortedName}
                        Value={modelv2.UniqueClientId}
                        ValueChanged={this.ValueChangedv2}
                      />
                    </GridItem>
                    <GridItem xs={4}>
                      <GenericCheckInput
                        Name="ApproveFailedAvs"
                        LabelText="Approve Failed Avs"
                        Value={modelv2.ApproveFailedAvs}
                        ValueChanged={this.ValueChangedv2}
                      />
                    </GridItem>
                  </GridContainer>
                </GenericExpansionPanel>
              </CardBody>
            </Card>
            <Card className="no-radius">
              <CardBody>
                <GridContainer>
                  <GridItem xs={12}>
                    <GridContainer spacing={16}>
                      <GridItem xs={12}>
                        <GenericGrid
                          Data={data}
                          Columns={[
                            {
                              Header: "Actions",
                              accessor: "Actions",
                              Cell: (row) => (
                                <div>
                                  <GridButton
                                    tooltip="Edit"
                                    Disabled={this.props.Disabled}
                                    Icon={EditIcon}
                                    OnClick={this.HandleEditButton(
                                      row.original
                                    )}
                                  />
                                  <GridButton
                                    Icon={DeleteIcon}
                                    OnClick={() =>
                                      this.deleteConfirm(row.original.Id)
                                    }
                                  />
                                </div>
                              ),
                              width: 100,
                              sortable: false,
                              filterable: false,
                            },
                            {
                              Header: "Id",
                              accessor: "Id",
                              show: false,
                            },
                            {
                              Header: "UniqueClientId",
                              accessor: "UniqueClientId",
										show: false,
                            },
									 {
                              Header: "Client",
                              accessor: "UniqueClient"
                            },
                            {
                              Header: "Approve Failed Avs",
                              accessor: "ApproveFailedAvs",
                              type: GridColumnType.YesNo,
                            },
                            {
                              Header: "Insert Date Time",
                              accessor: "InsertDateTime",
                              type: GridColumnType.DateTime,
                            },
                            {
                              Header: "Insert User",
                              accessor: "InsertUser",
                            },
                          ]}
                          SelectedIndex={selected}
                          IsSorted={true}
                          PageSize={10}
                          ShowPagination={true}
                          ref={this.cpverTable}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

PayClientProvisionProfile.propTypes = {
  setAppLeftTitle: PropTypes.func,
  setAppCenterTitle: PropTypes.func,
  ExecuteApiPost: PropTypes.func,
  ExecuteApiFileDownloadWithGenericResponse: PropTypes.func,
  Disabled: PropTypes.bool,
  menuId: PropTypes.any,
  ApprovalData: PropTypes.any,
  After: PropTypes.any,
};

export default withStyles(externalContainerStyle)(PayClientProvisionProfile);
