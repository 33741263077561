import CardMedia from "@material-ui/core/CardMedia";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withRedux } from "core";
import PropTypes from "prop-types";
import React from "react";
import Recaptcha from "react-recaptcha";
import { Link } from "react-router-dom";
import { UserConstants } from "store";
import { GenericAlert, GenericEmailInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";

class LoginPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			initialize: false,
			alert: null,
			isLoading: false,
			captchaLoaded: false,
			model: {
				Email: "",
				Password: "",
				IsCaptchaVerified: false,
				IsRestrictedAccess: false,
				IsEmailValid: true
			}
		};

		this.cardHeaderStyle = { height: 80, width: "50%", marginLeft: "auto", marginRight: "auto" };
		this.cardMediaStyle = {
			height: "100%", width: "75%", marginLeft: "auto", marginRight: "auto", marginTop: 0,
			backgroundSize: "100% 100%"
		};
		this.cardBodyStyle = { marginTop: 62, width: "83%", marginLeft: "auto", marginRight: "auto" };
		this.linkDivStyle = { float: "right" };
		this.linkStyle = { color: window.PageMainColor };
		this.lastGridItemStyle = { height: 33 };

		this.performLogin = this.performLogin.bind(this);
		this.PerformGetUser = this.PerformGetUser.bind(this);
		this.showErrorMessage = this.showErrorMessage.bind(this);
		this.showMessage = this.showMessage.bind(this);
		this.hideAlert = this.hideAlert.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
		this.ValueChanged = this.ValueChanged.bind(this);
		this.RecaptchaVerify = this.RecaptchaVerify.bind(this);
		this.RecaptchaLoadCallBack = this.RecaptchaLoadCallBack.bind(this);
		this.RecaptchaExpiredCallBack = this.RecaptchaExpiredCallBack.bind(this);
	}

	componentDidMount() {
		localStorage.removeItem("MenuData");
		this.setState({ initialize: true });
	}

	RecaptchaVerify(response) {
		if (response) {
			this.ValueChanged("IsCaptchaVerified", true);
		}
	}
	RecaptchaLoadCallBack() {
		this.setState({ captchaLoaded: true });
	}

	RecaptchaExpiredCallBack() {
		var { model } = this.state;
		model.IsCaptchaVerified = false;

		this.setState({ model });
	}

	ValueChanged(name, newValue, data) {
		this.setState(state => {
			var model = state.model || {};
			model[name] = newValue;

			if (name == "Email")
				model.IsEmailValid = data?.IsValid ?? false;

			return { model };
		});
	}

	performLogin() {
		const { Email, Password, IsCaptchaVerified, IsEmailValid } = this.state.model;

		if (!Email || !Email.trim()) {
			this.showMessage("warning", "Warning", "Email cannot be null.");
			return;
		}

		if (!IsEmailValid) {
			this.showMessage("warning", "Warning", "Email is invalid.");
			return;
		}

		if (!Password || !Password.trim()) {
			this.showMessage("warning", "Warning", "Password cannot be null.");
			return;
		}

		if (!IsCaptchaVerified) {
			this.showMessage("warning", "Warning", "Captcha cannot be verified.");
			return;
		}

		this.setState({ isLoading: true });

		var IsRestrictedAccess = false;
		if (window.IsRestrictedAccess != null && window.IsRestrictedAccess == "1") {
			IsRestrictedAccess = true;
		}

		Proxy.POST(
			"/coreapi/v1.0/Authentication/Login",
			{
				Email,
				Password,
				IsRestrictedAccess,
				CaptchaCode: window.grecaptcha.getResponse(),
				OrganizationUniqueId:window.OrganizationUniqueId 
			},
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				const user = {
					Email: this.state.model.Email, SessionId: responseData.DCBankAccessToken, ForceTwoFactorAuth: responseData.ForceTwoFactorAuth,
					SessionStatus: responseData.SessionStatus, MustChangePwd: responseData.MustChangePwd, CanBeClient: responseData.CanBeClient
				};

				localStorage.setItem("user", JSON.stringify({ SessionId: user.SessionId }));

				const { dispatch } = this.props;
				dispatch({ type: UserConstants.LOGIN_SUCCESS, user });

				this.PerformGetUser(user.SessionId, user.CanBeClient, user.ForceTwoFactorAuth);
			},
			error => {
				this.setState({ isLoading: false });
				this.showMessage("error", "Error", error);
			}
		);
	}

	PerformGetUser(SessionId, CanBeClient, ForceTwoFactorAuth) {
		this.setState({ isLoading: true });

		Proxy.POST(
			"/coreapi/v1.0/User/GetUserByEmailWithClientInfo",
			{ Email: this.state.model.Email },
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				var userInfo = responseData.User;
				userInfo.SessionId = SessionId;
				userInfo.CanBeClient = CanBeClient;

				localStorage.setItem("user", JSON.stringify(userInfo));

				if (ForceTwoFactorAuth) {
					localStorage.removeItem("MenuData");
					this.props.history.replace("/acc/otp");
				} else {
					this.props.history.replace("/");
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.showMessage("error", "Error", error);
			}
		);
	}

	showErrorMessage(message) {
		this.showMessage("error", "Login failed", message);
	}

	showMessage(type, title, message) {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.setState({ alert: "" })} />
		});
	}

	hideAlert() {
		this.setState({ alert: null });
	}

	handleKeyPress(e) {
		if (e.key == "Enter") {
			this.performLogin();
		}
	}

	render() {
		const { classes } = this.props;
		const { model, isLoading, alert, captchaLoaded, initialize } = this.state;
		const logo = require("assets/img/" + window.LoginLogo);

		return (
			<div className={classes.container}>
				<LoadingComponent Show={isLoading || (!captchaLoaded && initialize)} />
				{alert}
				<GridContainer justify="center">
					<GridItem style={{ minWidth: "600px", maxWidth: "656px" }}>
						<form>
							<Card login>
								<CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color="white" style={this.cardHeaderStyle}>
									<CardMedia className={classes.media} image={logo} style={this.cardMediaStyle} title="" />
								</CardHeader>
								<CardBody style={this.cardBodyStyle}								>
									<GridContainer direction="column" justify="flex-start" alignItems="stretch">
										<GridItem>
											<GenericEmailInput
												FloatRight={true}
												LabelMd={4}
												Name="Email" LabelText="E-Mail"
												Value={model.Email} ValueChanged={this.ValueChanged}
												EndIconName="email" AutoFocus={true} KeyPressed={this.handleKeyPress} />

											<GenericTextInput
												FloatRight={true}
												LabelMd={4}
												Name="Password" LabelText="Password" IsPassword={true}
												Value={model.Password} ValueChanged={this.ValueChanged}
												EndIconName="lock_outline" KeyPressed={this.handleKeyPress} />

											<GridContainer direction="row" justify="flex-start" alignItems="flex-start">
												<GridItem xs={4} />
												<GridItem xs={8}>
													<Recaptcha
														sitekey="6LcWbYEjAAAAADDVjmaHB19WdGcCOZ7Guqh-V5pf"
														render="explicit" 
														verifyCallback={this.RecaptchaVerify}
														onloadCallback={this.RecaptchaLoadCallBack}
														expiredCallback={this.RecaptchaExpiredCallBack} />
												</GridItem>
											</GridContainer>

											<GridContainer direction="row" justify="flex-start" alignItems="flex-start">
												<GridItem xs={4} />
												<GridItem xs={8}>
													<Button color="primary" size="lg" block onClick={this.performLogin} disabled={!model.IsCaptchaVerified}>
														LOGIN
													</Button>
												</GridItem>
											</GridContainer>

											<GridContainer direction="row" justify="flex-start" alignItems="flex-start">
												<GridItem xs={4} />
												<GridItem xs={8}>
													<div style={this.linkDivStyle}>
														<Link style={this.linkStyle} to={"/acc/reset"}> Forgot password? </Link>
													</div>
												</GridItem>
												<GridItem style={this.lastGridItemStyle}></GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</CardBody>
								<CardFooter className={classes.justifyContentCenter} />
							</Card>
						</form>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

LoginPage.propTypes = {
	classes: PropTypes.object.isRequired,
	dispatch: PropTypes.func,
	history: PropTypes.object
};

export default withRedux(LoginPage, loginPageStyle);