import PropTypes from "prop-types";
import React from "react";
import { InfoWindow, Marker } from "react-google-maps";

class GenericMapMarker extends React.PureComponent {
	constructor(props) {
		super(props);
	}

	render() {
		const { MarkerRef, PopupComponent, HidePopup, OpenPopup, infoWindowProps, ...other } = this.props;


		return (
			<Marker
				position={this.props.position}
				key={this.props.key}
				ref={MarkerRef}
				{...other}
			>
				{PopupComponent && OpenPopup ? (
					<InfoWindow {...infoWindowProps}>
						{typeof PopupComponent === "function" ? PopupComponent(this.props) : PopupComponent}
					</InfoWindow>
				) : null}
			</Marker>
		);
	}
}

GenericMapMarker.propTypes = {
	HidePopup: PropTypes.any,
	MarkerRef: PropTypes.any,
	OpenPopup: PropTypes.any,
	PopupComponent: PropTypes.any,
	key: PropTypes.any,
	position: PropTypes.any
};

export default GenericMapMarker;
