import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes, { bool, func } from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTitle, GenericCheckInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { ClientType, GridColumnType } from "views/Constants/Constant";
import ClientHelper from "core/ClientHelper";

class PendinBalanceTransaction extends Component {
	constructor(props) {
		super(props);
		this.initialModel = {
			SalesAgentUniqueClientId: !ClientHelper.IsSalesPerson() ? undefined : ClientHelper.GetClientRowId(),
		};
		this.state = {
			model: this.initialModel,
			list: [],
			salesAgent: [],
			program: [],
			isLoading: false,
			isSalesPerson: false
		};
		this.handleChange = this.handleChange.bind(this);

		this.ParameterYesNo = { ParameterCode: "YesNo" };
	}
	componentDidMount() {
		this.getProgram();
		this.getSalesAgent(-1);
		this.setState({ isSalesPerson: ClientHelper.IsSalesPerson() });
		this.props.setAppLeftTitle("Pending Balance Transaction");
		this.props.setAppCenterTitle("Sales Agent");
	}

	getProgram = () => {
		Proxy.GET(
			"/bankapi/v1.0/Bank/ValidBankPrograms",
			responseData => {
				if (!responseData.IsSucceeded) {
					console.log("Error!");
					return;
				}
				var pos = responseData.Item.filter(check => check.Description != "Payment Wallet Corporate" && check.Description != "Payment Wallet Personal" && check.Description != "Create Customer" && check.Description != "Client Setup")


				this.setState({ program: pos });
			},
			e => {
				console.log("Error!");
				return;
			}
		);
	}
	handleChange(name, newValue) {
		const model = { ...this.state.model };
		model[name] = newValue;


		if (name == "UniqueClientId") {
			model["SalesAgentUniqueClientId"] = null;
			this.getSalesAgent(newValue);
		}

		this.setState({ model });
	}
	getSalesAgent = (value) => {
		Proxy.POST(
			"/bankapi/v1.0/BankCustomer/GetSalesAgent",
			{ Id: value },
			responseData => {
				if (!responseData.IsSucceeded) {
					console.log("Error!");
					return;
				}
				this.setState({ salesAgent: responseData.Item });
			},
			e => {
				console.log("Error!");
				return;
			}
		);
	}

	handleGetList = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/ClearingSettlement/GetPendingBalanceTransactions",
			this.state.model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item !== null) {
					if (responseData.Item.length <= 0)
						this.ShowMessage("warning", "Record not find", "Record not find");

					this.setState({ list: responseData.Item });
				}
				else {
					this.setState({ list: [] });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while requesting data", error.message);
			}
		);
	}
	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	handleClear = () => {
		this.setState({
			model: {},
			list: [],
		});
	}

	render() {
		const { Disabled } = this.props;
		const { model, isLoading, salesAgent, program, isSalesPerson } = this.state;
		return (
			<div>
				{this.state.alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.handleGetList, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.handleClear, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<LoadingComponent Show={isLoading} />

				<Card className="no-radius">
					<CardBody>
						<GenericExpansionPanel Title="Filter Panel">
							<GridContainer>
								<GridItem xs={3}>
									<GenericSelectInput
										Name="UniqueClientId"
										LabelText="Client"
										Method="POST"
										Url="/bankapi/v1.0/BankCustomer/Search"
										DataRoot="Item"
										KeyValueMember="Id"
										Parameter={{}}
										RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
										Value={model.UniqueClientId || ""}
										ValueChanged={this.handleChange}
										All
										CanClear />
									<GenericDateInput
										Name="FromDate"
										LabelText="From Date"
										Value={model == null ? "" : model.FromDate ? DateHelper.ToDateInputValue(model.FromDate) : ""}
										ValueChanged={this.handleChange}
										MaxDate={model.EndDate || DateHelper.GetDate()}
										IncludeTime={false} />
								</GridItem>
								<GridItem xs={3}>

									<GenericSelectInput
										IsStatic={true}
										StaticData={salesAgent}
										All
										Name="SalesAgentUniqueClientId"
										Value={model.SalesAgentUniqueClientId || ""}
										LabelText="Sales Agent "
										ValueChanged={this.handleChange}
										KeyValueMember="Id"
										RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
										CanClear
										Disabled={isSalesPerson}
									/>
									<GenericDateInput
										Name="EndDate"
										LabelText="To Date"
										Value={model == null ? "" : model.EndDate ? DateHelper.ToDateInputValue(model.EndDate) : ""}
										ValueChanged={this.handleChange}
										MinDate={model.FromDate == null ? null : model.FromDate}
										MaxDate={DateHelper.GetDate()}
										IncludeTime={false} />
								</GridItem>
								<GridItem xs={3}>
									<GenericSelectInput
										IsStatic={true}
										Name="ProgramId"
										LabelText="Program"
										StaticData={program}
										All
										KeyValueMember="Id"
										TextValueMember="Description"
										Value={model.ProgramId}
										ValueChanged={this.handleChange}
										CanClear
									/>
								</GridItem>
							</GridContainer>
						</GenericExpansionPanel>
					</CardBody>
				</Card>
				<Card className="no-radius">
					<CardBody>
						<CardHeader>
							<GenericTitle text={"Pending Balance Transactions List"} />
						</CardHeader>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={this.state.list}
									PageSize={20}
									Columns={[
										{
											Header: "Transaction Date",
											accessor: "TransactionDate",
											type: GridColumnType.Date
										},
										{
											Header: "Unique Client Id",
											accessor: "UniqueClientNumber"
										},
										{
											Header: "Client Name",
											accessor: "UniqueClientName"
										},
										{
											Header: "Wallet Name",
											accessor: "AccountName"
										},
										{
											Header: "Wallet Number",
											accessor: "AccountNumber"
										},
										{
											Header: "Amount",
											accessor: "Amount",
											type: GridColumnType.Money,
											ColumnType: GridColumnType.Money
										},
										{
											Header: "Status",
											accessor: "IsReleased",
											Cell: row => (
												<div>{row.value == "1" ? "Released" : "Pending"}</div>
											),
										},
										{
											Header: "Program",
											accessor: "ProgramName"
										},
										{
											Header: "Description",
											accessor: "Description"
										},

										{
											Header: "Release Date",
											accessor: "ReleaseDate",
											type: GridColumnType.Date
										},
										{
											Header: "Release Resolve Date",
											accessor: "ReleaseResolveDate",
											type: GridColumnType.Date
										},
									]}
								/>
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>
			</div>
		);
	}
}

PendinBalanceTransaction.propTypes = {
	classes: PropTypes.object,
	handleOperationType: PropTypes.func,
	SelectedRowChange: PropTypes.func,
	showQuestionMessage: func,
	setAppLeftTitle: func,
	setAppCenterTitle: func,
	Disabled: bool
};

export default withArtifex(PendinBalanceTransaction, {});