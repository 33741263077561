import { Card } from "@material-ui/core";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import AccountComponent from "views/Components/AccountComponent";
import { GenericCheckInput, GenericDateInput, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";

class GeneralInfo extends Component {
	constructor(props) {
		super(props);

		this.state = {
		};

		this.emptyObject = {};

		this.maxLength26 = { maxLength: 26 };
		this.maxLength19 = { maxLength: 19 };

		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined
		};

		this.parameterPeriod = { ParameterCode: "LoadingPeriod" };

		this.parameterCardPhysicalType = { ParameterCode: "CardPhysicalType" };

		this.parameterCardStatus = { Criteria: 1 };

		this.renderItemUniqueClient = {};

		this.renderItemCardStatus = {};

		this.renderItemOnusBin = {};

		this.parameterProduct = {};

		this.renderItemAccount = {};

		this.renderItemCardStatus = {};
	}

	componentDidMount() {
	}

	GetProductParameter = () => {
		this.parameterProduct.UniqueClientId = this.props.UniqueClientId;
		return this.parameterProduct;
	}

	RenderItemOnusBin = (d) => {
		this.renderItemOnusBin = `${d.Bin} - ${d.Description}`;
		return this.renderItemOnusBin;
	}

	RenderItemCardStatus = (d) => {
		this.renderItemCardStatus = `${d.Code} - ${d.Description}`;
		return this.renderItemCardStatus;
	}

	render() {
		const { model, ReadOnly, Disabled } = this.props;

		return (
			<Card className="no-radius">
				<CardBody>
					<GridContainer>
						<GridItem xs={12}>
							<GridContainer>
							<GridItem xs={3}>
									<GenericTextInput
										Name="CardId"
										LabelText="Card Id"
										Value={model.CardId}
										Disabled={Disabled || ReadOnly} />
								</GridItem>
								<GridItem xs={3}>
									<GenericTextInput
										Name="CardTokenNumber"
										LabelText="Card Token Number"
										Format={"@@@@ @@@@ @@@@ @@@@"}
										inputProps={this.maxLength19}
										Value={model.CardTokenNumber}
										Disabled={Disabled || ReadOnly} />
								</GridItem>
								<GridItem xs={3}>
									<GenericTextInput
										Name="MaskedCardNumber"
										LabelText="Masked Card Number"
										Format={"@@@@ @@@@ @@@@ @@@@"}
										inputProps={this.maxLength19}
										Value={model.MaskedCardNumber}
										Disabled={Disabled || ReadOnly} />
								</GridItem>
								<GridItem xs={3} style={{ marginTop: 8 }}>
									<GenericCheckInput
										Name="IsActive"
										LabelText="Active"
										Value={model.IsActive || false}
										Disabled={Disabled || ReadOnly} />
								</GridItem>
								<GridItem xs={3} style={{ marginTop: 8 }}>
									<GenericCheckInput
										Name="IsLastCard"
										LabelText="Last Card"
										Value={model.IsLastCard || false}
										Disabled={Disabled || ReadOnly} />
								</GridItem>
								<GridItem xs={3}>
									<GenericSelectInput
										Name="CardPhysicalTypeId"
										LabelText="Card Physical Type"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.parameterCardPhysicalType}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={model.CardPhysicalTypeId}
										Disabled={Disabled || ReadOnly} />
								</GridItem>
								<GridItem xs={3} style={{ marginTop: 8 }}>
									<GenericCheckInput
										Name="IsMainCard"
										LabelText="Main Card"
										Value={model.IsMainCard || false}
										Disabled={Disabled || ReadOnly} />
								</GridItem>
								<GridItem xs={3}>
									<GenericTextInput
										Name="MainCardTokenNumber"
										LabelText="Main Card Token Number"
										Format={"@@@@ @@@@ @@@@ @@@@"}
										inputProps={this.maxLength19}
										Value={model.MainCardTokenNumber} />
								</GridItem>
								<GridItem xs={3} style={{ marginTop: 8 }}>
									<GenericTextInput
										Name="WalletBalance"
										LabelText="Wallet Balance"
										Value={model.WalletBalance}
										Disabled={Disabled || ReadOnly} />
								</GridItem>

								<GridItem xs={3} style={{ marginTop: 8 }}>
									<GenericCheckInput
										Name="IsClientCustomerIdAssigned"
										LabelText="Assigned"
										Value={model.IsClientCustomerIdAssigned || false}
										Disabled={Disabled || ReadOnly} />
								</GridItem>
								<GridItem xs={3} >
									<GenericDateInput
										Name="ClientCustomerIdAssignDate"
										LabelText="Assign Date"
										Value={model.ClientCustomerIdAssignDate ? DateHelper.ToDateInputValue(model.ClientCustomerIdAssignDate) : ""}
										Disabled={Disabled || ReadOnly} />
								</GridItem>
								<GridItem xs={3} >
									<AccountComponent
										TitleLabel="FIID"
										TitleLabelBranch="Transit"
										LabelText="Wallet"
										IsDCBankAccount={true}
										Model={model.BankAccount || {}}
										ValueChanged={() => { }}
										Disabled={Disabled || ReadOnly} />
								</GridItem>
								<GridItem xs={3} >
									<AccountComponent
										TitleLabel="FIID"
										TitleLabelBranch="Transit"
										LabelText="Wallet"
										IsDCBankAccount={true}
										Model={model.WalletAccount || {}}
										ValueChanged={() => { }}
										Disabled={Disabled || ReadOnly} />
								</GridItem>
								<GridItem xs={3} style={{ marginTop: 8 }}>
									<GenericCheckInput
										Name="HasAutoLoadInstruct"
										LabelText="Auto Load Instruct"
										Value={model.HasAutoLoadInstruct || false}
										Disabled={Disabled || ReadOnly} />
								</GridItem>
								<GridItem xs={3} style={{ marginTop: 8 }}>
									<GenericCheckInput
										Name="HasCardUsageLimit"
										LabelText="Has Card Usage Limit"
										Value={model.HasCardUsageLimit || false}
										Disabled={Disabled || ReadOnly} />
								</GridItem>

								<GridItem xs={3}>
									<GenericNumberInput
										Name="CardUsageLimit"
										LabelText="Card Usage Limit"
										Value={model.CardUsageLimit}
										Prefix="$"
										MaxLength={12}
										Disabled={Disabled || ReadOnly || !model.HasCardUsageLimit} />
								</GridItem>
								<GridItem xs={3}>
									<GenericSelectInput
										key={model.UniqueClientId}
										Name="ProductId"
										LabelText="Product"
										Method="POST"
										Url="/prepaidapi/v1.0/Product/Search"
										Parameter={this.GetProductParameter()}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ProductName"
										Value={model.ProductId}
										Disabled={Disabled || ReadOnly} />
								</GridItem>
								<GridItem xs={3}>
									<GenericSelectInput
										key={model.UniqueClientId}
										Name="PlasticTypeId"
										LabelText="Plastic Type"
										Method="POST"
										DataRoot="Item"
										Url="/prepaidapi/v1.0/PlasticType/Search"
										Parameter={
											{ UniqueClientId: model.UniqueClientId }
										}
										KeyValueMember="Id"
										TextValueMember="PlasticName"
										Value={model.PlasticTypeId}
										Disabled={Disabled || ReadOnly} />
								</GridItem>
								<GridItem xs={3}>
									<GenericSelectInput
										Name="CardUsagePeriodId"
										LabelText="Card Usage Period"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.parameterPeriod}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={model.CardUsagePeriodId}
										Disabled={Disabled || ReadOnly || !model.HasCardUsageLimit} />
								</GridItem>
								<GridItem xs={3}>
									<GenericSelectInput
										Name="CardBinId"
										LabelText="Card Bin"
										Method="GET"
										Url="/prepaidapi/v1.0/OnusBin/GetAll"
										DataRoot="Item"
										KeyValueMember="Id"
										RenderItem={this.RenderItemOnusBin}
										Value={model.CardBinId}
										Disabled={Disabled || ReadOnly} />
								</GridItem>
								<GridItem xs={3} style={{ marginTop: 8 }}>
									<GenericCheckInput
										Name="IsEmvCard"
										LabelText="Emv Card"
										Value={model.IsEmvCard || false}
										Disabled={Disabled || ReadOnly} />
								</GridItem>
								<GridItem xs={3} style={{ marginTop: 8 }}>
									<GenericCheckInput
										Name="IsContactlessCard"
										LabelText="Contactless Card"
										Value={model.IsContactlessCard || false}
										Disabled={Disabled || ReadOnly} />
								</GridItem>
								<GridItem xs={3} style={{ marginTop: 8 }}>
									<GenericCheckInput
										Name="IsMagneticCard"
										LabelText="Magnetic Card"
										Value={model.IsMagneticCard || false}
										Disabled={Disabled || ReadOnly} />
								</GridItem>
								<GridItem xs={3}>
									<GenericNumberInput
										Name="PanSequenceNumber"
										LabelText="Pan Sequence Number"
										Value={model.PanSequenceNumber}
										MaxLength={12}
										Disabled={Disabled || ReadOnly} />
								</GridItem>
								<GridItem xs={3} >
									<GenericDateInput
										Name="ExpiryDate"
										LabelText="Expiry Date"
										Value={model.ExpiryDate ? DateHelper.ToDateInputValue(model.ExpiryDate) : ""}
										Disabled={Disabled || ReadOnly} />
								</GridItem>
								<GridItem xs={3} style={{ marginTop: 8 }}>
									<GenericCheckInput
										Name="IsRenewed"
										LabelText="Is Renewed"
										Value={model.IsRenewed || false}
										Disabled={Disabled || ReadOnly} />
								</GridItem>
								<GridItem xs={3} >
									<GenericDateInput
										Name="LastRenewalDate"
										LabelText="Last Renewal Date"
										Value={model.LastRenewalDate ? DateHelper.ToDateInputValue(model.LastRenewalDate) : ""}
										Disabled={Disabled || ReadOnly} />
								</GridItem>
								<GridItem xs={3}>
									<GenericSelectInput
										Name="CardStatusId"
										LabelText="Card Status"
										Method="POST"
										Url="/prepaidapi/v1.0/CardStatus/GetAll"
										Parameter={this.parameterCardStatus}
										DataRoot="Item"
										KeyValueMember="Id"
										RenderItem={this.RenderItemCardStatus}
										Value={model.CardStatusId}
										Disabled={Disabled || ReadOnly} />
								</GridItem>
								<GridItem xs={3} >
									<GenericDateInput
										Name="CardStatChangeDate"
										LabelText="Card Status Change Date"
										Value={model.CardStatChangeDate ? DateHelper.ToDateInputValue(model.CardStatChangeDate) : ""}
										Disabled={Disabled || ReadOnly} />
								</GridItem>
								<GridItem xs={3}>
									<GenericSelectInput
										Name="OldCardStatusId"
										LabelText="Old Card Status"
										Method="POST"
										Url="/prepaidapi/v1.0/CardStatus/GetAll"
										Parameter={this.parameterCardStatus}
										DataRoot="Item"
										KeyValueMember="Id"
										RenderItem={this.RenderItemCardStatus}
										Value={model.OldCardStatusId}
										Disabled={Disabled || ReadOnly} />
								</GridItem>
								<GridItem xs={3} >
									<GenericDateInput
										Name="OldCardStatChangeDate"
										LabelText="Old Card Status Change Date"
										Value={model.OldCardStatChangeDate ? DateHelper.ToDateInputValue(model.OldCardStatChangeDate) : ""}
										Disabled={Disabled || ReadOnly} />
								</GridItem>
								<GridItem xs={3} style={{ marginTop: 8 }}>
									<GenericCheckInput
										Name="IsNoNameCard"
										LabelText="No Name Card"
										Value={model.IsNoNameCard || false}
									/>
								</GridItem>
								<GridItem xs={3} >
									<GenericDateInput
										Name="ConversionDateTime"
										LabelText="Conversion Date Time"
										Value={model.ConversionDateTime ? DateHelper.ToDateInputValue(model.ConversionDateTime) : ""}
										Disabled={Disabled || ReadOnly} />
								</GridItem>
								<GridItem xs={3}>
									<GenericCheckInput
										Name="IsConversionCard"
										LabelText="Is Conversion Card"
										Value={model.IsConversionCard || false}
										Disabled={Disabled || ReadOnly} />
								</GridItem>
								{model.CreditLimit &&
									<GridItem xs={3}>
										<GenericNumberInput
											Name="CreditLimit"
											LabelText="Credit Limit"
											Value={model.CreditLimit}
											MaxLength={12}
											Disabled={Disabled || ReadOnly} />
									</GridItem>
								}
								{model.CreditLimit &&
									<GridItem xs={3}>
										<GenericNumberInput
											Name="CashLimit"
											LabelText="Cash Limit"
											Value={model.CashLimit}
											MaxLength={12}
											Disabled={Disabled || ReadOnly} />
									</GridItem>
								}
							</GridContainer>

						</GridItem>
					</GridContainer>
				</CardBody>
			</Card>
		);
	}
}

GeneralInfo.propTypes = {
	classes: PropTypes.object.isRequired,
	model: PropTypes.object,
	showMessage: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(GeneralInfo, {});