import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import { DetailIcon } from "core/Icons";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericTitle } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import MastercardClearingDetail from "../MastercardClearingDetail";

class MastercardFileReject extends Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : undefined,
			IsMainCard: true,

		};

		this.state = {
			detailModel: {},
			model: {},
			list: [],
			isLoading: false,
			isDialogOpen: false
		}


		this.emptyObject = {};

		this.maxLength26 = { maxLength: 26 };
		this.maxLength19 = { maxLength: 19 };
		this.maxLength12 = { maxLength: 12 };

		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined
		};

		this.renderItemUniqueClient = {};

		this.parameterProduct = {};

		this.Columns = [
			{
				Header: "Actions",
				accessor: "Actions",
				sortable: false,
				filterable: false,
				width: 60
			},
			{
				Header: "Clearing Date",
				accessor: "ClearingDate"
			},
			{
				Header: "Mti",
				accessor: "Mti"
			},
			{
				Header: "Source Message Number ID",
				accessor: "Pds0138"
			},
			{
				Header: "Source File ID",
				accessor: "Pds0280"
			},
			{
				Header: "File Name",
				accessor: "FileName"
			},
			{
				Header: "File ID",
				accessor: "Pds0105"
			},

		];
	}

	componentDidMount() {
		this.props.setAppLeftTitle("File Reject");
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };

		model[name] = newValue;
		this.setState({ model });
	}

	HandleClear = () => {
		this.setState({ model: { ...this.initialModel }, isEdit: false, index: -1 });
	}

	HandleSearch = () => {
		const model = { ...this.state.model };
		this.setState({ isLoading: true });
		Proxy.POST
			(
				"/prepaidapi/v1.0/MastercardClearing/GetAllMastercardFileReject",
				model,
				(responseData) => {
					this.setState({ isLoading: false });
					if (!responseData.IsSucceeded) {
						this.props.showMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					if (responseData.Item !== null) {
						this.setState({
							list:
								responseData.Item.map(x => {
									return {
										Actions: (
											<div>
												<GridButton
													tooltip="Detail"
													Icon={DetailIcon}
													OnClick={() => { this.setState({ isDialogOpen: true, detailModel: x.MastercardClearingTransaction }) }} />
											</div>
										),
										Id: x.Id == null || undefined ? "" : x.Id,
										De024: x.De024 == null || undefined ? "" : x.De024,
										De072: x.De072 == null || undefined ? "" : x.De072,
										Mti: x.Mti == null || undefined ? "" : x.Mti,
										Pds0005: x.Pds0005 == null || undefined ? "" : x.Pds0005,
										Pds0105: x.Pds0105 == null || undefined ? "" : x.Pds0105,
										Pds0280: x.Pds0280 == null || undefined ? "" : x.Pds0280,
										ClearingDate: x.ClearingDate == null || undefined ? 0 : x.ClearingDate,
										Pds0138: x.Pds0138 == null || undefined ? 0 : x.Pds0138,
										FileName: x.FileName == null || undefined ? 0 : x.FileName,

									}
								})
						});
					}

				},
				(error) => {
					this.setState({ isLoading: false });
					this.props.showMessage("error", "Error", error);
				}
			);

	}

	RowSelected = (index) => {
		let { list } = this.state;
		const tempModel = list[index];
		if (list[index].CardRequestId) {
			tempModel.Id = list[index].Id;
		}
		this.setState({ isEdit: true, model: tempModel, index });
	}

	DetailsModelChange = (modelFunction) => {
		this.setState(function (state, props) {
			var model = state.detailModel || {};

			if (modelFunction)
				model = modelFunction(model);

			return { detailModel: model };
		});
	}

	DialogModelChange = (modelFunction) => {
		this.setState(function (state, props) {
			var model = state.initialModel || {};

			if (modelFunction)
				model = modelFunction(model);

			return { initialModel: model };
		});
	}
	CancelSelectCallback = () => {
		this.setState({
			isDialogOpen: false
		});
	};
	HandleClickUpdate = () => {
		this.setState({ isLoading: true });
		Proxy.POST("/prepaidapi/v1.0/MastercardClearing/MastercardClearingUpdateDetail",
			this.state.detailModel,
			responseData => {
				this.setState({ isLoading: false });
				if (responseData.IsSucceeded != false) {
					this.setState({
						isDialogOpen: false
					});
				} else {
					this.setState({ dialogData: [] });
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			});
	}


	render() {
		const {  Disabled } = this.props;
		const { alert, model, list, isLoading, index } = this.state;

		return (
			<div>

				<LoadingComponent Show={isLoading} />


				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<GenericDialog open={this.state.isDialogOpen} onBackdropClick={this.CancelSelectCallback} maxWidth="md" fullWidth keepMounted={true} style={{ zIndex: 8 }}>
					<DialogTitle><GenericLabel FontSize="16px" Text="Detail" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ marginTop: 8 }}>
						<MastercardClearingDetail

							model={this.state.detailModel}
							initialModel={this.state.initialModel}
							onModelChange={this.DetailsModelChange}
						/>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.HandleClickUpdate}>Update</Button>
						<Button size="sm" onClick={this.CancelSelectCallback}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>


				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Panel">
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer>
												<GridItem xs={3}>
													<GenericDateInput
														Name="ClearingDate"
														LabelText="Clearing Date"
														Value={model.ClearingDate}
														ValueChanged={this.HandleChange}
														Disabled={false} />
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={"Mastercard File Reject List"} />
							</CardHeader>
							<CardBody>
								<GridContainer spacing={16}>
									<GridItem xs={12}>
										<GenericGrid
											Data={list}
											Columns={this.Columns}
											RowSelected={this.RowSelected}
											SelectedIndex={index}
										/>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

MastercardFileReject.propTypes = {
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool
};

export default withArtifex(MastercardFileReject, {});