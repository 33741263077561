import withStyles from "@material-ui/core/styles/withStyles";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import { GenericNumberInput, GenericSelectInput } from "views/Components/Generic";


const styles = ({
	...customCheckboxRadioSwitch,
	...customSelectStyle,
	...sweetAlertStyle
});

class DDTCDICSettlementAccount extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};

	}


	render() {
		const { propDDTModel, propCDICModel } = this.props;

		return (
			<GridContainer>
				<GridItem xs={12} sm={6} md={6}>
					<Card className="no-radius" style={{ height: "90%" }}>
						<CardBody>
							<h6><b>Internal {window.Title} Account</b></h6>
							<GridContainer>
								<GridItem xs={6} sm={6} md={6}>
									<GenericSelectInput
										Name="FinInsId"
										LabelText="DDT Settlement Wallet"
										LabelMd={8}
										Method="GET"
										Url="/bankapi/v1.0/FinancialInstitution/GetAll"
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="FinancialInstitutionId"
										Value={propDDTModel.FinInsId == null || undefined ? "" : propDDTModel.FinInsId}
										Disabled={true} />
								</GridItem>
								<GridItem xs={3} sm={3} md={3}>
									<GenericSelectInput
										key={propDDTModel.FinInsId}
										Name="BranchId"
										LabelMd={0}
										Method="GET"
										Url="/bankapi/v1.0/FinancialInstitutionBranch/GetAllDCBankBranches"
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="TransitNumber"
										Value={propDDTModel.BranchId}
										Disabled={true} />
								</GridItem>
								<GridItem xs={3} sm={3} md={3}>
									<GenericNumberInput
										NoFormatting={true}
										LabelMd={0}
										Name="AccountNumber"
										Value={propDDTModel.AccountNumber == null || undefined ? "" : propDDTModel.AccountNumber}
										Disabled={true} />
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
				<GridItem xs={12} sm={6} md={6}>
					<Card className="no-radius" style={{ height: "90%" }}>
						<CardBody><br /><br />
							<GridContainer>
								<GridItem xs={6} sm={6} md={6}>
									<GenericSelectInput
										Name="FinInsId"
										LabelText="DDT - CDIC Settlement Wallet"
										LabelMd={8}
										Method="GET"
										Url="/bankapi/v1.0/FinancialInstitution/GetAll"
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="FinancialInstitutionId"
										Value={propCDICModel.FinInsId == null || undefined ? "" : propCDICModel.FinInsId}
										Disabled={true} />
								</GridItem>
								<GridItem xs={3} sm={3} md={3}>
									<GenericSelectInput
										key={propCDICModel.FinInsId}
										Name="BranchId"
										LabelMd={0}
										Method="GET"
										Url="/bankapi/v1.0/FinancialInstitutionBranch/GetAllDCBankBranches"
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="TransitNumber"
										Value={propDDTModel.BranchId == null ? "" : propDDTModel.BranchId}
										Disabled={true} />
								</GridItem>
								<GridItem xs={3} sm={3} md={3}>
									<GenericNumberInput
										NoFormatting={true}
										LabelMd={0}
										Name="AccountNumber"
										Value={propCDICModel.AccountNumber == null || undefined ? "" : propCDICModel.AccountNumber}
										Disabled={true} />
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

DDTCDICSettlementAccount.propTypes = {
	classes: PropTypes.object,
	propDDTModel: PropTypes.object,
	propCDICModel: PropTypes.object
};
export default withStyles(styles)(DDTCDICSettlementAccount);