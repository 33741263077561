const SortedCode = {
	Member: "Code"
};

const SortedDescription = {
	Member: "Description"
};

const SortedFullName = {
	Member: "FullName"
};

const SortedFullPath = {
	Member: "FullPath"
};

const SortedId = {
	Member: "Id"
};

const SortedMenuName = {
	Member: "MenuName"
};

const SortedName = {
	Member: "Name"
};

const SortedOrderIndex = {
	Member: "OrderIndex"
};

const SortedParameterDesc = {
	Member: "ParameterDesc"
};

const SortedParameterValue = {
	Member: "ParameterValue"
};

const SortedPeriodNumberAsc = [{
	id: "PeriodNumber",
	desc: false
}];

const SortedPropertyName = {
	Member: "PropertyName"
};

const SortedStoreName = {
	Member: "StoreName"
};

export {
	SortedCode,
	SortedDescription,
	SortedFullName,
	SortedFullPath,
	SortedId,
	SortedMenuName,
	SortedName,
	SortedOrderIndex,
	SortedParameterDesc,
	SortedParameterValue,
	SortedPeriodNumberAsc,
	SortedPropertyName,
	SortedStoreName
};