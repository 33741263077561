import { Card } from "@material-ui/core";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericCheckInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";

class EmbossInfo extends Component {
	constructor(props) {
		super(props);

		this.state = {
		};

		this.parameterEmbossType = { ParameterCode: "EmbossType" };
		this.parameterEmbossStatus = { ParameterCode: "EmbossStatus" };
	}

	componentDidMount() {
	}

	RenderItemAccount = (d) => {
		this.renderItemAccount = `${d.AccountNumber} - ${d.AccountName}`;
		return this.renderItemAccount;
	}

	render() {
		const { model, ReadOnly, Disabled } = this.props;

		return (
			<Card className="no-radius">
				<CardBody>
					<GridContainer>
						<GridItem xs={12}>
							<GridContainer>
								<GridItem xs={3}>
									<GenericTextInput
										Name="EmbossName1"
										LabelText="Emboss Name 1"
										IsText={true}
										Value={model.EmbossName1}
										Disabled={Disabled || ReadOnly} />
								</GridItem>
								<GridItem xs={3}>
									<GenericTextInput
										Name="EmbossName2"
										LabelText="Emboss Name 2"
										IsText={true}
										Value={model.EmbossName2}
										Disabled={Disabled || ReadOnly} />
								</GridItem>
								<GridItem xs={3}>
									<GenericSelectInput
										Name="EmbossTypeId"
										LabelText="Emboss Type"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.parameterEmbossType}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={model.EmbossTypeId}
										Disabled={Disabled || ReadOnly}
										DefaultIndex={1} />
								</GridItem>
								<GridItem xs={3}>
									<GenericSelectInput
										Name="EmbossStatusId"
										LabelText="Emboss Status"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.parameterEmbossStatus}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={model.EmbossStatusId}
										Disabled={Disabled || ReadOnly} />
								</GridItem>
								<GridItem xs={3} style={{ marginTop: 8 }}>
									<GenericCheckInput
										Name="IsEmbossFinished"
										LabelText="Emboss Finished"
										Value={model.IsEmbossFinished || false}
										Disabled={Disabled || ReadOnly} />
								</GridItem>
								<GridItem xs={3} style={{ marginTop: 8 }}>
									<GenericCheckInput
										Name="IsEmbossSucceded"
										LabelText="Emboss Succeed"
										Value={model.IsEmbossSucceded || false}
										Disabled={Disabled || ReadOnly} />
								</GridItem>
							</GridContainer>
						</GridItem>
					</GridContainer>
				</CardBody>
			</Card>
		);
	}
}

EmbossInfo.propTypes = {
	classes: PropTypes.object.isRequired,
	model: PropTypes.object,
	showMessage: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(EmbossInfo, {});