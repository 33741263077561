const atmLocationInfoStyle = (theme) => {

	return ({
		markerContainer: {
			overflow: "auto"
		},
		markerBody: {
			height: "63vh"
		}
	});
};

export default atmLocationInfoStyle;