import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Parser as HtmlToReactParser } from "html-to-react";

/**
 * @param {string} htmlInput
 */
function HtmlContentToReact(htmlInput) {
	var htmlToReactParser = HtmlToReactParser();
	var reactElement = htmlToReactParser.parse(htmlInput);

	return reactElement;
}

function EmptyEditorContent() {
	const html = "<p></p><p></p><p></p><p></p><p></p>";
	var contentBlock = htmlToDraft(html);
	var contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
	const editorState = EditorState.createWithContent(contentState);
	return editorState;
}

/**
 * @param {object} model
 */
function EditorContentToHtml(model) {
	return draftToHtml(convertToRaw(model.getCurrentContent()));
}

/**
 * @param {object} model
 */
function EditorContentToText(model) {
	var html = draftToHtml(convertToRaw(model.getCurrentContent()));
	//	var str = html.replace(/<\/?[^>]+>/ig, " ");
	//	str = str.replace("&nbsp;", " ");
	//	return str;
	var tmp = document.createElement("DIV");
	tmp.innerHTML = html;
	return tmp.textContent || tmp.innerText || "";
}

/**
 * @param {string} html
 */
function HtmlToEditorContent(html) {
	var contentBlock = htmlToDraft(html);
	var contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
	const editorState = EditorState.createWithContent(contentState);
	return editorState;
}

const HtmlEditorHelper = {
	HtmlContentToReact,
	EmptyEditorContent,
	EditorContentToHtml,
	EditorContentToText,
	HtmlToEditorContent
};

export default HtmlEditorHelper;