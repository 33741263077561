import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import ClientHelper from "core/ClientHelper.js";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import AccountComponent from "views/Components/AccountComponent.jsx";
import CustomerComponent from "views/Components/CustomerComponent";
import { GenericAlert, GenericDateInput, GenericEmailInput, GenericExpansionPanel, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { ProgramCodes } from "views/Constants/Constant.js";

class ClientPartnerInformation extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			vModel: {},
		};
		this.parameterEntityType = {
			ParameterCode: "EntityType"
		};
		this.parameterBusinessNature = { ParameterCode: "BusinessNature" };
		this.parameterFatca = {
			ParameterCode: "FATCA"
		};
		this.parameterLanguage = {
			ParameterCode: "Language"
		};
		this.parameterResidency = {
			ParameterCode: "Country"
		};
		this.parameterLicenseRegistrationEntity = {
			ParameterCode: "LicenseRegistrationEntity"
		};
		this.parameterLicenseRegistrationType = {
			ParameterCode: "LicenseRegistrationType"
		};
		this.AccountStatusParameterCode = {
			ParameterCode: "AccountStatus"
		};
		this.FatcaParameterCode = {
			ParameterCode: "FATCA"
		};
		this.YesNoParameterCode = {
			ParameterCode: "YesNo"
		};
		this.maxLength20 = {
			maxLength: 20
		};
		this.maxLength50 = {
			maxLength: 50
		};
		this.marginLeft2 = {
			marginLeft: 2
		};
		this.ParameterMaxLength30 = {
			maxLength: 30
		};
		this.emptyObject = {};
	}

	componentDidMount() { }

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert
				Title={title}
				MessageNode={message}
				Type={type}
				OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}

	hideAlert = () => {
		this.setState({ alert: null, isLoading: false });
	}
	ShowMessage = (type, title, message) => {
		// type:-success->warning->info->error
		this.setState({
			alert: <GenericAlert
				Title={title}
				Message={message}
				Type={type}
				OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	render() {
		const { model, Title, Disabled, vModel } = this.props;

		var UniqueClientId = ClientHelper.GetClientRowId();
		return (
			<div>
				<GenericExpansionPanel Title={Title}>
					<GridContainer>
						{this.state.alert}


						<LoadingComponent Show={this.state.isLoading} />

						<GridItem xs={4}>

							<div>
								<GridItem >
									<GenericTextInput
										LabelMd={4}
										Name="LegalNameOfBusiness"
										inputProps={this.ParameterMaxLength30}
										LabelText="Legal Name Of Business"
										Value={model.LegalNameOfBusiness}
										ValueChanged={this.props.handleChange}
										Disabled={Disabled}
										Required
										IsInvalid={vModel.LegalNameOfBusiness} />
								</GridItem>
								<GridItem >
									<GenericTextInput
										LabelMd={4}
										Name="BusinessIdentityNr"
										inputProps={this.ParameterMaxLength30}
										LabelText="Business Identification No."
										Value={model.BusinessIdentityNr}
										ValueChanged={this.props.handleChange}
										Disabled={Disabled} />
								</GridItem>
								<GridItem >
									<GenericTextInput
										LabelMd={4}
										Name="QuabecEnterpNr"
										inputProps={this.ParameterMaxLength30}
										LabelText="Quebec Enterprise No."
										Value={model.QuabecEnterpNr}
										ValueChanged={this.props.handleChange}
										Disabled={Disabled} />
								</GridItem>
								<GridItem >
									<GenericSelectInput
										LabelMd={4}
										Name="ResidencyForTaxId"
										LabelText="Country of Tax Residence"
										Method="POST"
										Url="/coreapi/v1.0/Country/GetAll"
										Parameter={this.emptyObject}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="Name"
										Value={model.ResidencyForTaxId}
										ValueChanged={this.props.handleChange}
										Disabled={Disabled} />
								</GridItem>
								<GridItem >
									<GenericSelectInput
										Name="CountryIncorporationId"
										LabelMd={4}
										LabelText="Country of Incorporation"
										Method="POST"
										Url="/coreapi/v1.0/Country/GetAll"
										Parameter={this.emptyObject}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="Name"
										Value={model.CountryIncorporationId}
										ValueChanged={this.props.handleChange}
										Disabled={Disabled} />
								</GridItem>
								<GridItem >
									<GenericSelectInput
										LabelMd={4}
										Name="LicenseRegistrationTypeId"
										LabelText="License Registration Type"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.parameterLicenseRegistrationType}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={model.LicenseRegistrationTypeId}
										ValueChanged={this.props.handleChange}
										Disabled={Disabled} />
								</GridItem>
								<GridItem >
									<GenericSelectInput
										LabelMd={4}
										Name="LicenceRegistrationEntityId"
										LabelText="License Registration Entity"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.parameterLicenseRegistrationEntity}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={model.LicenceRegistrationEntityId}
										ValueChanged={this.props.handleChange}
										Disabled={Disabled} />
								</GridItem>
								<GridItem>
									<GenericTextInput
										LabelMd={4}
										inputProps={this.ParameterMaxLength30}
										Name="LicenseRegistrationNumber"
										LabelText="License Registration No."
										Value={model.LicenseRegistrationNumber}
										ValueChanged={this.props.handleChange}
										Disabled={Disabled} />
								</GridItem>
								<GridItem>
									<CustomerComponent
										LabelMd={4}
										CustomerNumber={model.Customer ? model.Customer.CustomerNumber : ""}
										HandleChange={this.props.handleChange}
										FillCallback={this.props.HandleCustomerSelect}
										Disabled={UniqueClientId == null}
										ShowCustomerProgram={true}
										Programs={[ProgramCodes.Bank_Programs_CDIC]}
										UniqueClientId={UniqueClientId}
									/>
								</GridItem>
								<GridItem style={{ marginLeft: -2, marginRight: -2 }}>
									<AccountComponent
										LabelText="Wallet"
										IsDCBankAccount
										Model={model.Account != null ? model.Account : { AccountNumber: null }}
										Disabled={true} />
								</GridItem>
							</div>
						</GridItem>
						<GridItem xs={4}>

							<GenericTitle text={"Communication"} />

							<div>
								<GridItem>
									<GridContainer>
										<GridItem xs={8}>
											<GenericSelectInput
												Name="BusinessPhoneCountryCodeId"
												LabelText="Business Phone No"
												TitleLabel="Country Code"
												LabelMd={6}
												Method="POST"
												Url="/coreapi/v1.0/Country/GetAll"
												Parameter={this.emptyObject}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember={"Code"}
												Value={model.BusinessPhoneCountryCodeId}
												ValueChanged={this.props.handleChange}
												Sorted={{
													Member: "OrderIndex"
												}}
												RenderItem={d => "+" + d.Code + " (" + d.Name + ")"}
												Disabled={Disabled}
												Required={true}
												Query={"==null,==''"} />
										</GridItem>
										<GridItem
											xs={4}
											style={{
												paddingRight: 2 + "px !important"
											}}>
											<GenericNumberInput
												IsPhone
												Name="BusinessPhoneNumber"
												Format="(###) ### ####"
												InputStyle={this.marginLeft2}
												LabelMd={0}
												Value={model.BusinessPhoneNumber}
												ValueChanged={this.props.handleChange}
												MaxLength={10}
												Disabled={Disabled}
												Required={true}
												Query={"==null,==''"} />
										</GridItem>
									</GridContainer>
								</GridItem>
								<GridItem>
									<GridContainer>
										<GridItem xs={8}>
											<GenericSelectInput
												Name="BusinessFaxCountryCodeId"
												LabelText="Business Fax No"
												TitleLabel="Country Code"
												LabelMd={6}
												Method="POST"
												Url="/coreapi/v1.0/Country/GetAll"
												Parameter={this.emptyObject}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember={"Code"}
												Value={model.BusinessFaxCountryCodeId}
												ValueChanged={this.props.handleChange}
												Sorted={{
													Member: "OrderIndex"
												}}
												RenderItem={d => "+" + d.Code + " (" + d.Name + ")"}
												Disabled={Disabled} />
										</GridItem>
										<GridItem
											xs={4}
											style={{
												paddingRight: 2 + "px !important"
											}}>
											<GenericNumberInput
												IsPhone
												Name="BusinessFaxNumber"
												Format="(###) ### ####"
												InputStyle={this.marginLeft2}
												LabelMd={0}
												Value={model.BusinessFaxNumber}
												ValueChanged={this.props.handleChange}
												MaxLength={10}
												Disabled={Disabled} />
										</GridItem>
									</GridContainer>
								</GridItem>
								<GridItem>
									<GenericEmailInput
										Name="Email"
										LabelText="E-Mail"
										inputProps={this.ParameterMaxLength30}
										Value={model.Email}
										ValueChanged={this.props.handleChange}
										Disabled={Disabled || (model.Customer && model.Customer.Id > 0)}
										Required
										IsInvalid={this.props.vModel.Email} />
								</GridItem>
								<GridItem >
									<GenericSelectInput
										Name="LanguageId"
										LabelText="Preferred Language"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.parameterLanguage}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={model.LanguageId}
										ValueChanged={this.props.handleChange}
										Disabled={Disabled} />
								</GridItem>
								<GridItem >
									<GenericSelectInput
										Name="HasTextSmsNotificationId"
										LabelText="Text/SMS Notification"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.YesNoParameterCode}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={model.HasTextSmsNotificationId}
										ValueChanged={this.props.handleChange}
										Disabled={Disabled} />
								</GridItem>
								<GridItem >
									<GenericSelectInput
										Name="HasEmailNotificationId"
										LabelText="E-Mail Notification"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.YesNoParameterCode}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={model.HasEmailNotificationId}
										ValueChanged={this.props.handleChange}
										Disabled={Disabled} />
								</GridItem>
							</div>
						</GridItem>
						<GridItem xs={4}>

							<GenericTitle text={"Others"} />

							<div>
								<GridItem>
									<GenericSelectInput
										LabelMd={4}
										Name="TypeOfEntityId"
										LabelText="Type of Entity"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.parameterEntityType}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={model.TypeOfEntityId}
										ValueChanged={this.props.handleChange}
										Disabled={Disabled} />
								</GridItem>
								<GridItem>
									<GenericSelectInput
										LabelMd={4}
										Name="NatureOfBusiness"
										LabelText="Nature Of Business"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.parameterBusinessNature}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={model.NatureOfBusiness}
										ValueChanged={this.props.handleChange}
										Disabled={Disabled} />

								</GridItem>
								<GridItem>
									<GenericSelectInput
										Disabled={Disabled}
										LabelMd={4}
										Name="IndustryOfEntityId"
										LabelText="Industry Of Entity"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={{ ParameterCode: "IndustryOfEntity" }}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={model.IndustryOfEntityId}
										ValueChanged={this.props.handleChange} />
								</GridItem>
								<GridItem>
									<GenericSelectInput
										LabelMd={4}
										Name="HasAccountUsedByTpId"
										LabelText="Wallet Used By TP"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.YesNoParameterCode}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={model.HasAccountUsedByTpId}
										ValueChanged={this.props.handleChange}
										Disabled={Disabled} />
								</GridItem>
								<GridItem>
									<GenericSelectInput
										LabelMd={4}
										Name="AccountStatusId"
										LabelText="Wallet Status"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.AccountStatusParameterCode}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={model.AccountStatusId}
										ValueChanged={this.props.handleChange}
										Disabled={Disabled} />
								</GridItem>
								<GridItem>
									<GenericSelectInput
										LabelMd={4}
										Name="IsPublicityTradeEntityId"
										LabelText="Publicly Trade Entity"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.YesNoParameterCode}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={model.IsPublicityTradeEntityId}
										ValueChanged={this.props.handleChange}
										Disabled={Disabled} />
								</GridItem>
								<GridItem >
									<GenericDateInput
										LabelMd={4}
										Name="DateOfLastEffectivenessRev"
										IsPast={true}
										LabelText="Date of Last Effectiveness Rev."
										Value={model.DateOfLastEffectivenessRev
											? DateHelper.ToMoment(model.DateOfLastEffectivenessRev)
											: ""}
										ValueChanged={this.props.handleChange}
										IncludeTime={false}
										Disabled={Disabled} />
								</GridItem>
								<GridItem>
									<GenericSelectInput
										LabelMd={4}
										Name="FatcaId"
										LabelText="FATCA"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.FatcaParameterCode}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={model.FatcaId}
										ValueChanged={this.props.handleChange}
										Disabled={Disabled} />
								</GridItem>
								<GridItem >
									<GenericDateInput
										LabelMd={4}
										Name="DateOfLastFintracRev"
										IsPast={true}
										LabelText="Date of Last FINTRAC Rev."
										Value={model.DateOfLastFintracRev
											? DateHelper.ToMoment(model.DateOfLastFintracRev)
											: ""}
										ValueChanged={this.props.handleChange}
										IncludeTime={false}
										Disabled={Disabled} />
								</GridItem>
								{/* <GridItem>
									<Button disabled={Disabled} size="sm" color="black" onClick={() => this.OperationCheck()}>{model.Id > 0 ? "Update" : "Save"}</Button>
								</GridItem> */}
							</div>
						</GridItem>
					</GridContainer>

				</GenericExpansionPanel>
			</div >
		);
	}
}

ClientPartnerInformation.propTypes = {
	classes: PropTypes.object.isRequired,
	model: PropTypes.object,
	tempModel: PropTypes.object,
	addressModel: PropTypes.object,
	handleChange: PropTypes.func,
	HandleCustomerSelect: PropTypes.func,
	ClientPartnerSaved: PropTypes.func,
	handleAddressModel: PropTypes.func,
	Title: PropTypes.string,
	Disabled: PropTypes.bool
};

export default withArtifex(ClientPartnerInformation, {});