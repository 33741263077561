import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericAlert, GenericCheckInput, GenericNumberInput, GenericSelectInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";

class LocDetails extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false
		};

		this.parameterPaymentOptionFee = {
			ParameterCode: "LOSPayOption"
		};

		this.parameterLOSPaymentFrequency = {
			ParameterCode: "LOSPaymentSchedule"
		};

		this.parameterLOSPaymentMethod = {
			ParameterCode: "LOSPaymentMethod"
		};
		this.parameterYesNo = {
			ParameterCode: "LOSYesNo"
		};
		this.SendAgreement = this.SendAgreement.bind(this);

	}

	componentDidMount() {

	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}


	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}
	ShowMessage = (type, title, message) => {
		// type:-success->warning->info->error
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}

	SendAgreementDocuSign = () => {
		const applicationModel = this.props.model;

		this.props.TaskPoolFunc.AppendTask(
			Proxy.POST(
				"/losapi/v1.0/EmbeddedSigning/SignNowEmbeddedSinging",
				{
					ApplicationId: applicationModel.Id,
					UniqueClientId: applicationModel.UniqueClientId,
					ApplicantId: applicationModel.ApplicantId
				},
				responseData => {
					if (!responseData.IsSucceeded) {
						// this.DocuSingAccesToken();
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					this.ShowMessage("success", "Success", "Agreement Template Has Been Successfully Sent");
				},
				error => {
					this.ShowMessage("error", "Error", error);
				}
			));

	}

	SendAgreement() {
		const applicationModel = this.props.model;
		this.props.TaskPoolFunc.AppendTask(
			Proxy.POST(
				"/lmsapi/v1.0/PaymentAdmin/SendAgreement",
				{
					Id: applicationModel.Id
				},
				responseData => {
					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					this.ShowMessage("success", "Success", "Agreement Template Sent");
				},
				error => {
					this.ShowMessage("error", "Error", error);
				}
			));
	}
	render() {
		const { model, Disabled, vModel } = this.props;

		return (
			<div>
				<GridContainer>
					{this.state.alert}

					<LoadingComponent Show={this.state.isLoading} />

					<GridContainer>
						<GridItem xs={4}>
							<GenericTitle text="Requested" />
							<div>
								<GridItem>
									<GenericNumberInput
										key="RequestedAmount"
										Name="RequestedAmount"
										LabelText="Requested Amount"
										LabelMd={5}
										MaxLength={10}
										Prefix="$"
										Value={model.RequestedAmount == null || undefined ? "" : model.RequestedAmount}
										ValueChanged={this.props.HandleChange}
										Disabled={true} />
								</GridItem>
								<GridItem>
									<GenericSelectInput
										Name="RequestedPaymentFrequencyId"
										LabelText="Requested Payment Frequency"
										LabelMd={5}
										Method="POST"
										IsStatic={true}
										StaticData={this.props.ParameterList.filter(prm => prm.ParameterCode == this.parameterLOSPaymentFrequency.ParameterCode)}
										// Url="/coreapi/v1.0/Parameter/Search"
										// Parameter={this.parameterLOSPaymentFrequency}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={model.RequestedPaymentFrequencyId}
										ValueChanged={this.props.HandleChange}
										Disabled={true} />
								</GridItem>
								{/* <GridItem>
									<GenericSelectInput
										Name="RequestedPaymentMethodId"
										LabelText="Requested Payment Method"
										LabelMd={5}
										Method="POST"
										IsStatic={true}
										StaticData={this.props.ParameterList.filter(prm => prm.ParameterCode == this.parameterLOSPaymentMethod.ParameterCode)}
										// Url="/coreapi/v1.0/Parameter/Search"
										// Parameter={this.parameterLOSPaymentMethod}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={model.RequestedPaymentMethodId}
										ValueChanged={this.props.HandleChange}
										Disabled={Disabled} />
								</GridItem> */}
								{/* <GridItem>
									<GenericNumberInput
										NoFormatting
										key="RequestedPaymentPeriod"
										Name="RequestedPaymentPeriod"
										LabelText="Requested Payment Period"
										LabelMd={5}
										Value={model.RequestedPaymentPeriod}
										ValueChanged={this.props.HandleChange}
										Disabled={Disabled} />
								</GridItem> */}
								{/* <GridItem>
									<GenericNumberInput
										Name="SecurityAmount"
										LabelText="Security Amount"
										Prefix="$"
										LabelMd={5}
										Value={model == null ? "" : model.SecurityAmount || ""}
										ValueChanged={this.props.HandleChange}
										Disabled={true} />
								</GridItem> */}
								<GridItem>
									<GenericTitle text="Line of Credit" />
								</GridItem>

								<GridItem>
									<GenericSelectInput
										Name="LoanFeeDefinitionId"
										LabelText="Fee Profile"
										LabelMd={5}
										IsStatic={true}
										StaticData={this.props.FeeProfileList}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="Name"
										Value={model.LoanFeeDefinitionId}
										ValueChanged={this.props.HandleChange}
										Required
										IsInvalid={vModel.LoanFeeDefinitionId} />
								</GridItem>
								<GridItem>
									<GenericNumberInput
										Name="ApprovedAmount"
										Prefix="$"
										LabelMd={5}
										LabelText="Approved Amount"
										Value={model == null ? "" : model.ApprovedAmount || ""}
										ValueChanged={this.props.HandleChange}
										Disabled={Disabled}
										Required
										IsInvalid={vModel.ApprovedAmount} />
								</GridItem>
								<GridItem>
									<GenericSelectInput
										Name="ApprovedPaymentFrequencyId"
										LabelText="Approved Payment Frequency"
										LabelMd={5}
										Method="POST"
										IsStatic={true}
										StaticData={this.props.ParameterList.filter(prm => prm.ParameterCode == this.parameterLOSPaymentFrequency.ParameterCode)}
										// Url="/coreapi/v1.0/Parameter/Search"
										// Parameter={this.parameterLOSPaymentFrequency}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={model.ApprovedPaymentFrequencyId}
										ValueChanged={this.props.HandleChange}
										Disabled={Disabled}
										Required
										IsInvalid={vModel.ApprovedPaymentFrequencyId} />
								</GridItem>
								<GridItem>
									<GenericSelectInput
										Name="ApprovedPaymentMethodId"
										LabelText="Interest Calculation Method"
										LabelMd={5}
										Method="POST"
										IsStatic={true}
										StaticData={this.props.ParameterList.filter(prm => prm.ParameterCode == this.parameterLOSPaymentMethod.ParameterCode)}
										// Url="/coreapi/v1.0/Parameter/Search"
										// Parameter={this.parameterLOSPaymentMethod}
										DataRoot="Item"
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={model.ApprovedPaymentMethodId}
										ValueChanged={this.props.HandleChange}
										Disabled={Disabled}
										Required
										IsInvalid={vModel.ApprovedPaymentMethodId} />
								</GridItem>
								{/* <GridItem>
									<GenericNumberInput
										NoFormatting
										key="ApprovedPaymentPeriod"
										Name="ApprovedPaymentPeriod"
										LabelMd={5}
										LabelText="Approved Payment Period"
										Value={model.ApprovedPaymentPeriod}
										ValueChanged={this.props.HandleChange}
										Disabled={Disabled || isApprovedPeriodDisabled}
									/>
								</GridItem> */}
								<GridItem>
									<GenericSelectInput
										Name="PaymentOptionFeeId"
										LabelText="Fee Payment Type"
										Method="POST"
										IsStatic={true}
										StaticData={this.props.ParameterList.filter(prm => prm.ParameterCode == this.parameterPaymentOptionFee.ParameterCode)}
										// Url="/coreapi/v1.0/Parameter/Search"
										// Parameter={this.parameterPaymentOptionFee}
										DataRoot="Item"
										LabelMd={5}
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={model.PaymentOptionFeeId}
										ValueChanged={this.props.HandleChange}
										Disabled={Disabled}
										DefaultIndex={0}
										Required
										IsInvalid={vModel.PaymentOptionFeeId} />
								</GridItem>
								<GridItem>
									<GenericSelectInput
										Name="SavingsPlanId"
										LabelText="Savings Plan"
										Method="POST"
										IsStatic={true}
										StaticData={this.props.ParameterList.filter(prm => prm.ParameterCode == this.parameterYesNo.ParameterCode)}
										// Url="/coreapi/v1.0/Parameter/Search"
										// Parameter={this.parameterYesNo}
										DataRoot="Item"
										LabelMd={5}
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Value={model.SavingsPlanId}
										ValueChanged={this.props.HandleChange}
										Disabled={Disabled} />
								</GridItem>
							</div>
						</GridItem>
						<GridItem xs={3}>
							<GenericTitle text={"Fees"} />
							{this.props.LoanApprovalFeeList == null ? "" : this.props.LoanApprovalFeeList.map((setupFee, key) => {
								return (
									<GridItem key={key}>
										<GenericNumberInput
											LabelText={setupFee.LOSSetupFee != null ? setupFee.LOSSetupFee.ParameterDesc : ""}
											Name={setupFee.LOSSetupFee.ParameterDesc}
											Value={setupFee.GivenValue}
											LabelMd={5}
											ValueChanged={this.props.HandleChange}
											Disabled={true} />
									</GridItem>
								);
							})}
							<GridItem>
								<GenericNumberInput
									LabelText="Grand Total"
									Name="GrandTotal"
									Value={model.TotalSetupAmount}
									LabelMd={5}
									Prefix="$"
									Disabled={true} />
							</GridItem>
						</GridItem>
						<GridItem xs={3}>
							<GenericTitle text={"Payments"} />
							<div>
								<GridItem>
									<GenericNumberInput
										Name="PrincipalAmount"
										LabelText="Principal Amount"
										Prefix="$"
										LabelMd={5}
										Value={model == null ? "" : model.ApprovedAmount || ""}
										ValueChanged={this.props.HandleChange}
										Disabled={true} />
								</GridItem>
								<GridItem>
									<GenericNumberInput
										Name="TotalSetupAmount"
										LabelText="Total Setup Amount"
										Prefix="$"
										LabelMd={5}
										Value={model == null ? "" : model.TotalSetupAmount != null ? model.TotalSetupAmount : ""}
										ValueChanged={this.props.HandleChange}
										Disabled={true} />
								</GridItem>
							</div>

							<GenericTitle text="Agreement" />
							<GridItem>
								<GenericCheckInput
									Name="IsAgreementSigned" LabelText="Agreement Signed"
									Value={model.IsAgreementSigned || false} ValueChanged={this.props.HandleChange}
									Disabled={this.props.isUserAdmin} />
							</GridItem>
						</GridItem>

						<GridItem xs={1}>
							<GridContainer
								direction="row"
								justify="flex-start"
								alignItems="stretch">
								<GridItem xs={2}>
									<GridContainer
										direction="column"
										justify="flex-start"
										alignItems="stretch"	>
										{this.props.isUserAdmin && <Button size="sm" color="black" onClick={this.props.UpdateLocDetails}>Update</Button>}
										{this.props.isApproved == true ?
											<Button size="sm" color="black" onClick={this.props.LedgerCardDialog}>Ledger Card</Button> : ""}
										<Button size="sm" color="black" onClick={this.props.LoanCalculatorDialog}>Calculator</Button>
									</GridContainer>
								</GridItem>
							</GridContainer>
						</GridItem>
					</GridContainer>
				</GridContainer>
			</div >
		);
	}

}

LocDetails.propTypes = {
	classes: PropTypes.object.isRequired,
	model: PropTypes.object,
	tempModel: PropTypes.object,
	addressModel: PropTypes.object,
	HandleChange: PropTypes.func,
	LedgerCardDialog: PropTypes.func,
	LoanCalculatorDialog: PropTypes.func,
	LoanApprovalFeeList: PropTypes.array,
	Disabled: PropTypes.bool,
	isApproved: PropTypes.bool,
	isApprovedPeriodDisabled: PropTypes.bool,
	TaskPoolFunc: PropTypes.func,
	ParameterList: PropTypes.array,
	FeeProfileList: PropTypes.array,
	isUserAdmin: PropTypes.bool,
};

export default withArtifex(LocDetails, {});