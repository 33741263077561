import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericCheckInput, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericLabel, GenericTitle } from "views/Components/Generic";
import { MonthsOfYear, GridColumnType, MenuCodes } from "views/Constants/Constant.js";

class CardReport extends React.Component {
	constructor(props) {
		super(props);

		let IsCardReport = this.props.MenuCode == MenuCodes.Menu_CardReport;

		this.columns = [
			{
				Header: "Client Name",
				accessor: "ClientName",
				show: IsCardReport
			},
			{
				Header: "Year",
				accessor: "Year",
			},
			{
				Header: "Month",
				accessor: "Month"
			},
			{
				Header: "MCC Name",
				accessor: "MCCName",
				show: !IsCardReport
			},
			{
				Header: "Card Transaction Source",
				accessor: "CardTransactionSourceValue",
				show: !IsCardReport
			},
			{
				Header: "City Name",
				accessor: "CityName",
				show: IsCardReport
			},
			// {
			// 	Header: "Gender",
			// 	accessor: "Gender",
			// 	show: IsCardReport
			// },
			{
				Header: "Age",
				accessor: "Age",
				show: IsCardReport
			},
			{
				Header: "Total Amount",
				accessor: "TotalAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Total Count",
				accessor: "Count"
			}
		];

		this.emptyObject = {};

		this.sortedName = { Member: "Name" };
		this.sortedCode = { Member: "Code" };

		this.state = {
			model: {},
			Month: [],
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("PREPAID CARD MANAGEMENT");
		}

		if (this.props.MenuCode == MenuCodes.Menu_CardReport) {
			setAppLeftTitle("Card Report");
		}
		else {
			setAppLeftTitle("MCC Report");
		}
		this.SetYearList();
	}

	SetYearList = () => {
		var currentYear = parseInt(DateHelper.GetYear());
		var listYear = [];
		var listAge = [];

		for (var i = 2019; i <= currentYear; i++) {
			listYear.push({
				"Year": i
			});
		}
		for (var i = 18; i <= 100; i++) {
			listAge.push({
				"Age": i
			});
		}
		this.setState({ listAge, listYear });
	}
	handleListItemChange = (name, newValue, key, ObjectName) => {
		var model = { ...this.state.model };
		const array = this.state.Month;
		const item = array.includes(key);
		if (item == false && newValue == true) {
			array.push(key);
		} else if (item == true && newValue == false) {
			var index = array.indexOf(key);
			if (index > -1) {
				array.splice(index, 1);
			}
		}
		model["Month"] = array;
		this.setState({ Month: array, model });

	}


	ValueChanged = (name, value) => {
		this.setState(state => {
			var model = state.model;
			model[name] = value;

			return { model };
		});
	}

	Search = async () => {
		const { model } = this.state;
		const { ExecuteApiPost } = this.props;
		if (this.props.MenuCode == MenuCodes.Menu_CardReport) {
			var result = await ExecuteApiPost("/prepaidapi/v1.0/TransactionData/CardReport", model, null, null, null);
			this.setState({ list: result || [] });

		} else {
			var result1 = await ExecuteApiPost("/prepaidapi/v1.0/TransactionData/MCCReport", model, null, null, null);
			this.setState({ list: result1 || [] });
		}

	}

	Clear = () => {
		this.setState({ model: {}, list: [], selected: null, Month: [] });
	}

	Validate = () => {
		var { model } = this.state;
		const { showValidationErrors } = this.props;
		var errors = [];

		if (!model.ParentMenuId
			|| model.ParentMenuId == -2) {
			errors.push("Parent menu can not be null.");
		}
		if (!model.SubMenuId) {
			errors.push("Sub menu can not be null.");
		}
		if (!model.ActionId) {
			errors.push("Action can not be null.");
		}

		if (errors.length > 0) {
			showValidationErrors(errors);

			return false;
		}

		return true;
	}



	render() {
		const { model, list, listYear, listAge, Month } = this.state;
		const { Disabled } = this.props;
		let IsCardReport = this.props.MenuCode == MenuCodes.Menu_CardReport;

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},

						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Panel">
									<GridContainer>
										{(IsCardReport) &&
											<GridItem xs={3}>
												<GenericSelectInput
													Name="UniqueClientId"
													LabelMd={4}
													LabelText="Client"
													Method="POST"
													Url="/bankapi/v1.0/BankCustomer/Search"
													Parameter={{}}
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
													Value={model.UniqueClientId || ""}
													ValueChanged={this.ValueChanged}
													CanClear
												/>
											</GridItem>
										}
										<GridItem xs={3}>
											<GenericSelectInput
												IsStatic
												Name="Year"
												LabelText="Year"
												StaticData={listYear}
												KeyValueMember="Year"
												TextValueMember="Year"
												Value={model.Year}
												ValueChanged={this.ValueChanged}
											// Required 
											/>
										</GridItem>
										{(!IsCardReport) &&
											<GridItem xs={3}>
												<GenericSelectInput
													Name="FullName"
													LabelText="MCC Name"
													Method="POST"
													Url="/prepaidapi/v1.0/Mcc/Search"
													Parameter={{}}
													DataRoot="Item"
													KeyValueMember="FullName"
													TextValueMember="FullName"
													Value={model.FullName}
													ValueChanged={this.ValueChanged}
												// Required
												// IsInvalid={vModel.CountryId}
												/>
											</GridItem>
										}
										{(!IsCardReport) &&
											<GridItem xs={3}>
												<GenericSelectInput
													Name="CardTransactionSourceId"
													LabelText="Card Transaction Source"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={{ ParameterCode: "CardTransactionSource" }}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={model.CardTransactionSourceId}
													ValueChanged={this.ValueChanged}
												// IsInvalid={vModel.GenderId}
												// Required
												/>
											</GridItem>
										}
										{(IsCardReport) &&
											<GridItem xs={3}>
												<GenericSelectInput
													Name="CityName"
													LabelText="City Name"
													Method="POST"
													Url="/prepaidapi/v1.0/TransactionData/GetAllCardCity"
													Parameter={{}}
													DataRoot="Item"
													KeyValueMember="CityName"
													TextValueMember="CityName"
													Value={model.CityName}
													ValueChanged={this.ValueChanged}
												// Required
												// IsInvalid={vModel.CountryId}
												/>
											</GridItem>
										}

										{(IsCardReport) &&
											<GridItem xs={3}>
												<GenericSelectInput
													IsStatic
													Name="Age"
													LabelText="Age"
													StaticData={listAge}
													KeyValueMember="Age"
													TextValueMember="Age"
													Value={model.Age}
													ValueChanged={this.ValueChanged} />
											</GridItem>
										}
										{/* {(IsCardReport) &&
											<GridItem xs={3}>
												<GenericSelectInput
													Name="GenderId"
													LabelText="Gender"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={{ ParameterCode: "Gender", ParameterValue: "", ParameterDesc: "" }}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={model.GenderId}
													ValueChanged={this.ValueChanged}
												IsInvalid={vModel.GenderId}
												Required
												/>
											</GridItem>
										} */}
										{(!IsCardReport) && <GridItem xs={12}> </GridItem>}
										<GridItem xs={1} style={{ marginTop: "10px" }}>
											<GenericLabel
												Text="Month"
												LabelMd={6}
											/>
										</GridItem>
										<GridItem xs={10} style={{ marginTop: "10px" }}>
											{MonthsOfYear != null && MonthsOfYear.map(item => (
												<GenericCheckInput
													key={"MonthsOfYear" + item.Id}
													Name="Id"
													LabelText={item.Text}
													Value={Month.includes(item.Id)}
													ValueChanged={(name, value) => this.handleListItemChange(name, value, item.Id, "MonthsOfYear")}
												/>
											))}

										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columns}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

CardReport.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any,
	classes: PropTypes.object

};
export default withArtifex(CardReport, {});
