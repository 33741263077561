import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes, { func } from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericSelectInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import TabsComponent from "views/Components/TabsComponent";
import { Direction, ProgramCodes, GridColumnType } from "views/Constants/Constant";
import CDIC from "./ReconciliationTabs/ReconciliationCDICTab";
import DDT from "./ReconciliationTabs/ReconciliationDDTTab";
import EFT from "./ReconciliationTabs/ReconciliationEFTTab";
import ETransfer from "./ReconciliationTabs/ReconciliationeTransferTab";

class ClientReconciliation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			model: {
				SettlementDate: DateHelper.GetDate()
			},
			open: false,
			EFTIncomingSummary: { GapList: {} },
			EFTOutgoingSummary: { GapList: {} },
			ETransferIncomingSummary: { GapList: {} },
			ETransferOutgoingSummary: { GapList: {} },
			CDICSummary: { GapList: {} },
			DDTSummary: { GapList: {} },
			isLoading: false,
			GapList: [],
			GapProgramCode: "",
			panel1Active: true
		};
		this.handleChange = this.handleChange.bind(this);
		this.GetData = this.GetData.bind(this);
		this.errorCallback = this.errorCallback.bind(this);
		this.hideAlert = this.hideAlert.bind(this);
		this.showErrorMessage = this.showErrorMessage.bind(this);
		this.successSummaryCallback = this.successSummaryCallback.bind(this);
	}
	componentDidMount() {
		this.props.setAppLeftTitle("Client Reconciliation");
		this.props.setAppCenterTitle("CLEARING & SETTLEMENT");
	}

	handleChange(name, newValue) {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}

	SumValues = (...arr) => {
		return arr.length > 0 ? arr[0].reduce((a, b) => a + b, 0) : 0;
	}

	handleClickOpen = (program, direction) => {
		var GapList = { ...this.state.GapList };
		var { GapProgramCode } = this.state;
		if (program == ProgramCodes.Bank_Programs_EFT) { //EFT
			GapProgramCode = ProgramCodes.Bank_Programs_EFT;
			if (direction == Direction.Incoming)
				GapList = this.state.EFTIncomingSummary.GapList;
			else
				GapList = this.state.EFTOutgoingSummary.GapList;
		}
		if (program == ProgramCodes.Bank_Programs_e_Transfer) { // ETRANSFER
			GapProgramCode = ProgramCodes.Bank_Programs_e_Transfer;
			if (direction == Direction.Incoming)
				GapList = this.state.ETransferIncomingSummary.GapList;
			else
				GapList = this.state.ETransferOutgoingSummary.GapList;
		}
		if (program == ProgramCodes.Bank_Programs_DDT) { // DDT
			GapProgramCode = ProgramCodes.Bank_Programs_DDT;
			GapList = this.state.DDTSummary.GapList;
		}
		if (program == ProgramCodes.Bank_Programs_CDIC) { // CDIC
			GapProgramCode = ProgramCodes.Bank_Programs_CDIC;
			GapList = this.state.CDICSummary.GapList;
		}

		if (GapList.length > 0)
			this.setState({ open: true, GapList, GapProgramCode });
		else
			this.setState({
				isLoading: false, GapList, GapProgramCode,
				alert: <GenericAlert Title={"Warning"} Message={"Gap list is empty."} Type="warning" ShowCancel={false} OnConfirm={() => this.hideAlert()} />
			});
	}

	GetData() {
		if (this.state.model.SettlementDate == null || this.state.model.ClientId == null)
			this.showErrorMessage("Client and Settlement Date can not be empty !!");
		else {
			this.setState({ isLoading: true });
			Proxy.POST(
				"/bankapi/v1.0/ClearingSettlement/GetReconciliationBySettlementDate",
				this.state.model,
				this.successSummaryCallback,
				this.errorCallback
			);
		}
	}

	successSummaryCallback(responseData) {
		if (!responseData.IsSucceeded) {
			this.showErrorMessage(responseData.ErrorDescription);
			return;
		}
		if (responseData.Item == null) {
			this.setState({
				isLoading: false, panel1Active: false,
				EFTIncomingSummary: {},
				EFTOutgoingSummary: {},
				ETransferIncomingSummary: {},
				ETransferOutgoingSummary: {},
				CDICSummary: {},
				DDTSummary: {},
			});
		} else {
			this.setState({
				isLoading: false, panel1Active: false,
				EFTIncomingSummary: responseData.Item.EFTIncomingSummary,
				EFTOutgoingSummary: responseData.Item.EFTOutgoingSummary,
				ETransferIncomingSummary: responseData.Item.ETransferIncomingSummary,
				ETransferOutgoingSummary: responseData.Item.ETransferOutgoingSummary,
				CDICSummary: responseData.Item.CDICSummary,
				DDTSummary: responseData.Item.DDTSummary,
			});
		}
	}

	errorCallback(error) {
		this.showErrorMessage("An error occurred during the api visit" + error);
	}

	showErrorMessage(message) {
		this.setState({
			isLoading: false,
			alert: <GenericAlert Title={"Error"} Message={message} Type="warning" ShowCancel={false} OnConfirm={() => this.hideAlert()} />
		});
	}

	handleClose = () => {
		this.setState({ open: false });
	}

	hideAlert() {
		this.setState({ alert: null });
	}

	handleClear = () => {
		this.setState({ model: { SettlementDate: DateHelper.GetDate() } });
	}

	HandleChangeFilterPanel = (event, expanded) => {
		this.setState({ panel1Active: expanded ? true : false });
	}
	render() {
		var { Disabled } = this.props;
		const { model, isLoading, GapProgramCode } = this.state;
		var data = this.state.GapList == undefined || this.state.GapList == null ? [] : this.state.GapList.map(d => {
			return {
				ProgramCode: d.ProgramName == null || undefined ? "" : d.ProgramName || "",
				PayeeName: d.PayeeName == null || undefined ? "" : d.PayeeName || "",
				AccountNumberStr: d.AccountNumberStr == null || undefined ? "" : d.AccountNumberStr || "",
				TransactionStatus: d.TransactionStatus == null || undefined ? "" : d.TransactionStatus || "",
				TransactionType: d.TransactionType == null || undefined ? "" : d.TransactionType || "",
				TransactionAmount: d.TransactionAmount == null || undefined ? "" : d.TransactionAmount || "",
				TransferMemo: d.Description == null || undefined ? "" : d.Description || "",
				SourceAccFIID: d.SourceAccFIID == null ? "" : d.SourceAccFIID,
				SourceAccTransit: d.SourceAccTransit == null ? "" : d.SourceAccTransit,
				SourceAccNumber: d.SourceAccNumber == null ? "" : d.SourceAccNumber,
				DestAccFIID: d.DestAccFIID == null ? "" : d.DestAccFIID,
				DestAccTransit: d.SDestAccTransit == null ? "" : d.DestAccTransit,
				DestAccNumber: d.DestAccNumber == null ? "" : d.DestAccNumber,
			};
		});
		return (
			<div>
				{this.state.alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.GetData, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.handleClear, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<LoadingComponent Show={isLoading} />
				<GenericDialog open={this.state.open} maxWidth="md" onBackdropClick={this.handleClose}>
					<DialogTitle>
						<GenericLabel Text="Gap Transaction Details" FontSize="20px" Bold={true} />
					</DialogTitle>
					<DialogContent>
						<GenericGrid
							Data={data}
							Columns={[
								{
									Header: "Payee Name",
									accessor: "PayeeName",
									show: GapProgramCode == ProgramCodes.Bank_Programs_EFT || GapProgramCode == ProgramCodes.Bank_Programs_e_Transfer
								},
								{
									Header: "Acc. Number",
									accessor: "AccountNumberStr",
									show: GapProgramCode == ProgramCodes.Bank_Programs_EFT || GapProgramCode == ProgramCodes.Bank_Programs_e_Transfer
								},
								{
									Header: "Trans. Type",
									accessor: "TransactionType"
								},
								{
									Header: "Trans. Status",
									accessor: "TransactionStatus"
								},
								{
									Header: "Source FIID",
									accessor: "SourceAccFIID",
									show: GapProgramCode == ProgramCodes.Bank_Programs_DDT || GapProgramCode == ProgramCodes.Bank_Programs_CDIC
								},
								{
									Header: "Source Transit No",
									accessor: "SourceAccTransit",
									show: GapProgramCode == ProgramCodes.Bank_Programs_DDT || GapProgramCode == ProgramCodes.Bank_Programs_CDIC
								},
								{
									Header: "Source Wallet No",
									accessor: "SourceAccNumber",
									show: GapProgramCode == ProgramCodes.Bank_Programs_DDT || GapProgramCode == ProgramCodes.Bank_Programs_CDIC
								},
								{
									Header: "Dest. FIID",
									accessor: "DestAccFIID",
									show: GapProgramCode == ProgramCodes.Bank_Programs_DDT || GapProgramCode == ProgramCodes.Bank_Programs_CDIC
								},
								{
									Header: "Dest. Transit No",
									accessor: "DestAccTransit",
									show: GapProgramCode == ProgramCodes.Bank_Programs_DDT || GapProgramCode == ProgramCodes.Bank_Programs_CDIC
								},
								{
									Header: "Dest. Wallet No",
									accessor: "DestAccNumber",
									show: GapProgramCode == ProgramCodes.Bank_Programs_DDT || GapProgramCode == ProgramCodes.Bank_Programs_CDIC
								},
								{
									Header: "Trans. Amount",
									accessor: "TransactionAmount",
									type: GridColumnType.Money,
									ColumnType:GridColumnType.Money
								},
								{
									Header: "Trans. Memo",
									accessor: "TransferMemo",
									show: GapProgramCode == ProgramCodes.Bank_Programs_EFT
								},

							]}
							PageSize={7} />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.handleClose}>Close</Button>
					</GenericDialogActions>

				</GenericDialog>
				<Card className="no-radius">
					<GenericExpansionPanel Title="Main Parameters">
						<GridContainer>
							<GridItem xs={4}>
								<GenericSelectInput
									Name="ClientId"
									LabelText="Client"
									Method="POST"
									Url="/bankapi/v1.0/BankCustomer/Search"
									Parameter={{}}
									DataRoot="Item"
									KeyValueMember="Id"
									RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
									Value={model.ClientId || ""}
									ValueChanged={this.handleChange}
									CanClear />
								<GenericDateInput
									Name="SettlementDate"
									LabelText="Settlement Date"
									Value={model == null || undefined ? "" : model.SettlementDate ? DateHelper.ToDateInputValueAsUtc(model.SettlementDate) : ""}
									ValueChanged={this.handleChange}
									IncludeTime={false}
									MaxDate={DateHelper.GetDate()}
									Utc /><br /><br />
							</GridItem>
						</GridContainer><br />
					</GenericExpansionPanel>
				</Card>
				<Card className="no-radius">
					<CardBody>
						<TabsComponent
							tabList={[
								{
									tabButton: "EFT",
									tabContent: (
										<EFT EFTIncomingSummary={this.state.EFTIncomingSummary} EFTOutgoingSummary={this.state.EFTOutgoingSummary} openDialog={this.handleClickOpen} />
									)
								},
								{
									tabButton: "eTransfer",
									tabContent: (
										<ETransfer ETransferIncomingSummary={this.state.ETransferIncomingSummary} ETransferOutgoingSummary={this.state.ETransferOutgoingSummary} openDialog={this.handleClickOpen} />
									)
								},
								{
									tabButton: "DDT",
									tabContent: (
										<DDT DDTSummary={this.state.DDTSummary} openDialog={this.handleClickOpen} />
									)
								},
								{
									tabButton: "DDT - CDIC",
									tabContent: (
										<CDIC CDICSummary={this.state.CDICSummary} openDialog={this.handleClickOpen} />
									)
								}
							]}
						/>
					</CardBody>
				</Card>
			</div>
		);
	}
}

ClientReconciliation.propTypes = {
	classes: PropTypes.object,
	handleOperationType: PropTypes.func,
	SelectedRowChange: PropTypes.func,
	showQuestionMessage: func,
	setAppLeftTitle: func,
	setAppCenterTitle: func
};

export default withArtifex(ClientReconciliation, {});