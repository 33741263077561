import { DialogContent, DialogTitle, Slide } from "@material-ui/core";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Proxy, withArtifex } from "core";
import Formatter from "core/Formatter";
import { DeleteIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import { GenericCheckInput, GenericDialog, GenericDialogActions, GenericGrid, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import ToolbarButton from "views/Components/ToolbarButton";
import SelectUserComponent from "views/Workflow/Components/SelectUserComponent";
import { GridColumnType } from "views/Constants/Constant";

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

class WorkflowUserGroupDefinition extends React.Component {
	constructor(props) {
		super(props);

		this.defaultModel = {
			Name: "",
			UserList: [],
			IsUpdate: false,
			GroupId: undefined
		};

		this.state = {
			vModel: {},
			isLoading: false,
			isSelectUserDialogOpen: false,
			SelectToken: false,
			model: { ...this.defaultModel },
			data: []
		};

		this.EmptyObject = {};

		this.ValueChanged = this.ValueChanged.bind(this);
		this.SubmitClick = this.SubmitClick.bind(this);
		this.Insert = this.Insert.bind(this);
		this.Update = this.Update.bind(this);
		this.ClearClick = this.ClearClick.bind(this);
		this.AddUserClick = this.AddUserClick.bind(this);
		this.AddUser = this.AddUser.bind(this);
		this.CloseSelectDialog = this.CloseSelectDialog.bind(this);
		this.IsUserExists = this.IsUserExists.bind(this);
		this.RefreshSelect = this.RefreshSelect.bind(this);
	}

	componentDidMount() {
		const { setAppLeftTitle, setAppCenterTitle } = this.props;

		if (setAppLeftTitle)
			setAppLeftTitle("Workflow User Group Definition");
		if (setAppCenterTitle)
			setAppCenterTitle("SYSTEM ADMIN");
	}

	SubmitClick() {
		const { model } = this.state;

		if (model.IsUpdate) {
			this.Update();
		}
		else {
			this.Insert();
		}
	}

	Insert() {
		const { model, vModel } = this.state;

		if (model.Name == null || model.Name == "") {
			vModel.Name = true;
			this.props.showMessage("warning", "Warning", "Group Name Cannot Be Empty.");
			return;
		} else { vModel.Name = false; }


		if (model.UserList == null || model.UserList.length == 0) {
			this.props.showMessage("warning", "Warning", "User List Cannot Be Empty.");
			return;
		}
		this.setState({ vModel })
		var request = {
			Name: model.Name,
			UserList: model.UserList.map(x => x.Id)
		};

		this.setState({ isLoading: true });
		Proxy.POST("/coreapi/v1.0/WorkflowUserGroup/InsertWorkflowUserGroup",
			request,
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "An error occurred while inserting data", responseData.ErrorDescription);
					return;
				}

				this.props.showMessage("success", "User Group successfully saved.");

				this.RefreshSelect();
				this.HandleSearch();
				model.GroupId = responseData.Item.Id;
				model.Name = "";
				model.IsUpdate = true;
				this.setState({ model });
				
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "An error occurred while inserting data", errorMessage);
			});
	}

	Update() {
		const { model, vModel } = this.state;

		if (model.GroupId == null) {
			vModel.GroupId = true;
			this.props.showMessage("warning", "Warning", "Group Not Selected For Update.");
			return;
		}


		if (model.UserList == null || model.UserList.length == 0) {
			this.props.showMessage("warning", "Warning", "User List Cannot Be Empty.");
			return;
		}
		this.setState({ vModel })
		var request = {
			Id: model.GroupId,
			UserList: model.UserList.map(x => x.Id)
		};

		this.setState({ isLoading: true });
		Proxy.POST("/coreapi/v1.0/WorkflowUserGroup/UpdateWorkflowUserGroup",
			request,
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "An error occurred while updating data", responseData.ErrorDescription);
					return;
				}

				this.props.showMessage("success", "User Group successfully updated.");

				this.RefreshSelect();
				this.HandleSearch();
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "An error occurred while updating data", errorMessage);
			});
	}
	
	HandleSearch = () => {
		this.setState({ isLoading: true });
		Proxy.GET(
			"/coreapi/v1.0/WorkflowUserGroup/GetAllUserGroup",
			(responseData) => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", "Sorry, an error has occurred");
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ data: responseData.Item });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}

	RefreshSelect() {
		this.setState(state => { return { SelectToken: !state.SelectToken }; });
	}

	ClearClick() {
		this.setState({ model: { ...this.defaultModel }, vModel: {} , data : []});
	}

	AddUserClick() {
		this.setState({ isSelectUserDialogOpen: true });
	}

	AddUser(data) {
		const { model } = this.state;
		if (model.UserList.some(x => x.Id == data.Id))
			return false;

		this.setState(state => {
			state.model.UserList = state.model.UserList.concat([data]);
			return { model: state.model, isSelectUserDialogOpen: false };
		});

		return true;
	}

	CloseSelectDialog() {
		this.setState({ isSelectUserDialogOpen: false });
	}

	RemoveUser(userId) {
		this.setState(state => {
			state.model.UserList = state.model.UserList.filter(x => x.Id != userId);
			return { model: state.model };
		});
	}

	IsUserExists(userId) {
		const { model } = this.state;
		return model.UserList.some(x => x.Id == userId);
	}

	ValueChanged(name, newValue, data) {
		this.setState(state => {
			var model = state.model || {};
			model[name] = newValue;

			if (name == "IsUpdate") {
				if (newValue) {
					model.Name = "";
				}
				else {
					model.GroupId = undefined;
					model.UserList = [];
				}
			}

			if (name == "GroupId") {
				if (data) {
					model.UserList = data.UserList;
				}
			}

			return { model };
		});
	}

	render() {
		const { Disabled } = this.props;
		const { isLoading, model, isSelectUserDialogOpen, SelectToken, vModel, data } = this.state;

		const canAddUser = !model.IsUpdate || model.GroupId != null;

		return (
			<>
				<LoadingComponent Show={isLoading} />
				<ButtonToolbar ButtonList={[
					{ Code: "Submit", OnClick: this.SubmitClick, Disabled: Disabled },
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.ClearClick, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<Card>
					<CardHeader>
						<GenericTitle text={"Filter Panel"} />
					</CardHeader>
					<CardBody>
						<GridContainer>
							<GridItem xs={4}>
								{
									model.IsUpdate ?
										<GenericSelectInput
											key={"WUGSelect_" + SelectToken}
											Name="GroupId"
											LabelText={"Group Name"}
											Value={model.GroupId}
											ValueChanged={this.ValueChanged}
											Disabled={Disabled}
											Method="GET"
											Url="/coreapi/v1.0/WorkflowUserGroup/SearchWorkflowUserGroup"
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember="Name"
											Required
											IsInvalid={vModel.GroupId}
										/>
										:
										<GenericTextInput
											Name="Name"
											LabelText={"Group Name"}
											Value={model.Name}
											ValueChanged={this.ValueChanged}
											Disabled={Disabled}
											Required
											IsInvalid={vModel.Name}
										/>
								}
							</GridItem>
							<GridItem xs={2}>
								<GenericCheckInput
									Name="IsUpdate"
									LabelText={"Update"}
									Value={model.IsUpdate}
									ValueChanged={this.ValueChanged}
									Disabled={Disabled} />
							</GridItem>
							<GridItem xs={2}>
								<ToolbarButton tooltip={"Add User"} onClick={this.AddUserClick} size="sm" disabled={Disabled || !canAddUser}>{"Add User"}</ToolbarButton>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
				<GenericGrid
					Data={data}
					Columns={[
						// {
						// 	Header: "Actions",
						// 	accessor: "Actions",
						// 	Cell: row => (
						// 		<div>
						// 			<GridButton
						// 				tooltip="Delete"
						// 				Icon={DeleteIcon}
						// 				OnClick={() => { this.RemoveUser(row.original.Id); }}
						// 			/>
						// 		</div>
						// 	),
						// 	width: 60,
						// 	sortable: false,
						// 	filterable: false,
						// },
						{
							Header: "User Group Name",
							accessor: "UserGroupName"
						},
						{
							Header: "User Name",
							accessor: "UserName"
						},
						{
							Header: "User Role",
							accessor: "UserRole"
						},
						{
							Header: "Name",
							accessor: "Name"
						},
						{
							Header: "Last Name",
							accessor: "LastName"
						},
						{
							Header: "ID Type",
							accessor: "IdType"
						},
						{
							Header: "ID Number",
							accessor: "IdNumber"
						},
						{
							Header: "Exp. Date of ID",
							accessor: "ExpDateOfId",
							type: GridColumnType.Date
						},
						{
							Header: "User Exp. Date",
							accessor: "UserExpDate",
							type: GridColumnType.Date
						},
						{
							Header: "User Status",
							accessor: "UserStatus"
						}
					]}
				/>
				< GenericDialog open={isSelectUserDialogOpen} fullScreen onBackdropClick={this.CloseSelectDialog} TransitionComponent={Transition} keepMounted={true}>
					<DialogTitle>
						Add User to Group
					</DialogTitle>
					<DialogContent>
						<SelectUserComponent SelectUser={this.AddUser} IsUserExists={this.IsUserExists} showMessage={this.props.showMessage} />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={() => this.setState({ isSelectUserDialogOpen: false })}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>
			</>
		);
	}
}

WorkflowUserGroupDefinition.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	menuId: PropTypes.number.isRequired,
	ApprovalData: PropTypes.object,
	After: PropTypes.func,
	Disabled: PropTypes.bool,
	showMessage: PropTypes.func
};

export default withArtifex(WorkflowUserGroupDefinition, {});