import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import {
	GenericAlert,
  GenericExpansionPanel,
  GenericGrid,
  GenericSelectInput,
  GenericTitle,
} from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";

class DdtReturnAccount extends Component {
  constructor(props) {
    super(props);

    this.initialModel = {
      ProfileId: 0,
      ReturnAccountId: 0,
      UniqueClientId: ClientHelper.IsClient()
        ? ClientHelper.GetClientRowId()
        : undefined,
      IsRequiredUniqueClientId: true,
    };

    this.state = {
      model: this.initialModel,
      list: [],
		vModel: {},
      isLoading: false,
    };

    this.emptyObject = {};
    this.parameterUniqueClientId = {
      UniqueClientId: ClientHelper.IsClient()
        ? ClientHelper.GetClientId()
        : undefined,
    };
  }

  componentDidMount() {
    this.props.setAppLeftTitle("DDT Return Wallet Profile");
    this.props.setAppCenterTitle("DDT MANAGEMENT");
  }

  HandleChange = (name, newValue, data) => {
    const model = { ...this.state.model };
    model[name] = newValue;
    if (newValue == null) {
      model[name] = 0;
    }

    this.setState({ model });
  };

  HandleSearch = () => {
    const model = { ...this.state.model };

    this.setState({ isLoading: true });
    Proxy.POST(
      "/bankapi/v1.0/Ddt/GetReturnDdtAccountProfile",
      {
        ProfileId: model.ProfileId,
        UniqueClientId: model.UniqueClientId,
        ReturnAccountId: model.ReturnAccountId,
        ReturnAccountNumber: model.ReturnAccountNumber,
        ReturnAccountName: model.ReturnAccountName,
        ReturnAccountOwnerName: model.ReturnAccountOwnerName,
      },
      (responseData) => {
        this.setState({ isLoading: false });
        if (!responseData.IsSucceeded) {
          this.props.showMessage(
            "error",
            "Error",
            responseData.ErrorDescription
          );
          return;
        }
        if (responseData.Item !== null) {
          this.setState({ list: responseData.Item });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
        this.props.showMessage("error", "Error", error);
      }
    );
  };

  HandleInsert = () => {
    if (!this.Validate()) {
      return;
    }
    const model = { ...this.state.model };
    this.setState({ isLoading: true });
    Proxy.POST(
      "/bankapi/v1.0/Ddt/InsertDdtReturnAccountProfile",
      {
        ProfileId: model.ProfileId,
        UniqueClientId: model.UniqueClientId,
        ReturnAccountId: model.ReturnAccountId,
      },
      (responseData) => {
        this.setState({ isLoading: false });
        if (!responseData.IsSucceeded) {
          this.props.showMessage(
            "error",
            "Error",
            responseData.ErrorDescription
          );
          return;
        }
        if (responseData.IsSucceeded) {
          this.setState({ model: { ...this.initialModel }, index: -1 });
          this.props.showMessage(
            "success",
            "Succeeded",
            "Operation is successfully!"
          );
          this.HandleSearch();
        }
      },
      (error) => {
        this.setState({ isLoading: false });
        this.props.showMessage("error", "Error", error);
      }
    );
  };

  HandleUpdate = () => {
    if (!this.Validate()) {
      return;
    }
    const model = { ...this.state.model };
    this.setState({ isLoading: true });
    Proxy.POST(
      "/bankapi/v1.0/Ddt/UpdateDdtReturnAccountProfile",
      {
        ProfileId: model.ProfileId,
        UniqueClientId: model.UniqueClientId,
        ReturnAccountId: model.ReturnAccountId,
      },
      (responseData) => {
        this.setState({ isLoading: false });
        if (!responseData.IsSucceeded) {
          this.props.showMessage(
            "error",
            "Error",
            responseData.ErrorDescription
          );
          return;
        }
        if (responseData.IsSucceeded) {
          this.setState({ model: { ...this.initialModel }, index: -1 });
          this.props.showMessage(
            "success",
            "Succeeded",
            "Operation is successfully!"
          );
          this.HandleSearch();
        }
      },
      (error) => {
        this.setState({ isLoading: false });
        this.props.showMessage("error", "Error", error);
      }
    );
  };

  HandleDelete = () => {
    if (!this.Validate()) {
      return;
    }
    const model = { ...this.state.model };
    this.setState({ isLoading: true });
    Proxy.POST(
      "/bankapi/v1.0/Ddt/DeleteDdtReturnAccountProfile",
      {
        ProfileId: model.ProfileId,
        UniqueClientId: model.UniqueClientId,
        ReturnAccountId: model.ReturnAccountId,
      },
      (responseData) => {
        this.setState({ isLoading: false });
        if (!responseData.IsSucceeded) {
          this.props.showMessage(
            "error",
            "Error",
            responseData.ErrorDescription
          );
          return;
        }
        if (responseData.IsSucceeded) {
          this.setState({ model: { ...this.initialModel }, index: -1 });
          this.props.showMessage(
            "success",
            "Succeeded",
            "Operation is successfully!"
          );
          this.HandleSearch();
        }
      },
      (error) => {
        this.setState({ isLoading: false });
        this.props.showMessage("error", "Error", error);
      }
    );
  };

  Validate = () => {
	const { model, vModel } = this.state;
	var messages = [];

	vModel.UniqueClientId = model.UniqueClientId == (null || undefined || 0);
	if (vModel.UniqueClientId) 
		messages.push("Client must be select");

	vModel.ReturnAccountId = model.ReturnAccountId == (null || undefined || 0);
	if (vModel.ReturnAccountId)
		messages.push("Return Wallet must be select");

	if (messages.length > 0) {
		this.ShowMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
		return false;
	}

	this.setState({ vModel });
	return true;
  };

  ShowMessageNode = (type, title, message) => {
	this.setState({
		alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
			this.setState({ alert: "" })} />
	});
};

  RowSelected = (index) => {
    const model = this.state.list[index];
    this.setState({ model, rowIndex: index });
  };

  HandleClear = () => {
    this.setState({
      model: { ...this.initialModel },
      isEdit: false,
      index: -1,
    });

	 console.log(this.state.model);
  };

  render() {
    const { Disabled } = this.props;
    const { alert, model, list, isLoading } = this.state;

    return (
      <div>
        <LoadingComponent Show={isLoading} />

        {alert}
        <ButtonToolbar
          ButtonList={[
            { Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
            {
              Code: "Submit",
              OnClick: this.HandleInsert,
              Disabled: Disabled || model.ReturnAccountId === 0 || model.ProfileId !== 0,
            },
            {
              Code: "Update",
              OnClick: this.HandleUpdate,
              Disabled: Disabled || model.ProfileId === 0,
            },
            {
              Code: "Delete",
              OnClick: this.HandleDelete,
              Disabled: Disabled || model.ProfileId === 0,
            },
            { Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
          ]}
          menuId={this.props.menuId}
          ApprovalData={this.props.ApprovalData}
          After={this.props.After}
        />
        <GridItem xs={12}>
          <Card className="no-radius">
            <CardBody>
              <GenericExpansionPanel Title="Main Panel">
                <GridContainer>
                  <GridItem xs={4}>
                    <GenericSelectInput
                      Name="UniqueClientId"
                      LabelText="Client"
                      Method="POST"
                      Url="/bankapi/v1.0/BankCustomer/Search"
                      Parameter={{}}
                      DataRoot="Item"
                      KeyValueMember="Id"
                      RenderItem={(d) => `${d.UniqueClientId} - ${d.Name}`}
                      Value={model.UniqueClientId}
                      ValueChanged={this.HandleChange}
                      CanClear
							 Disabled={ClientHelper.IsClient()}
                      DefaultIndex={ClientHelper.IsClient() ? 0 : -1}
							 All
                    />
                  </GridItem>
                  <GridItem xs={4}>
                    <GenericSelectInput
						    key={model.UniqueClientId}
                      Name="ReturnAccountId"
                      LabelText="Return Wallet"
                      Method="POST"
                      Url="/bankapi/v1.0/Ddt/GetClientAllAccount"
                      Parameter={ !ClientHelper.IsClient() && model.UniqueClientId > 0 ? { UniqueClientId : model.UniqueClientId } : { } }
                      DataRoot="Item"
                      KeyValueMember="Id"
                      RenderItem={(d) =>
                        `${d.AccountNumber} - ${d.AccountName}`
                      }
                      Value={model.ReturnAccountId}
                      ValueChanged={this.HandleChange}
                      CanClear
                      Required
                    />
                  </GridItem>
                </GridContainer>
              </GenericExpansionPanel>
            </CardBody>
          </Card>
          <Card className="no-radius">
            <CardHeader>
              <GenericTitle text={"Profile List"} />
            </CardHeader>
            <CardBody>
              <GridContainer spacing={16}>
                <GridItem xs={12}>
                  <GenericGrid
                    Data={list}
                    Columns={[
                      {
                        Header: "Return Wallet Number",
                        accessor: "ReturnAccountNumber",
                      },
                      {
                        Header: "Return Wallet Name",
                        accessor: "ReturnAccountName",
                      },
                      {
                        Header: "Return Wallet Owner Name",
                        accessor: "ReturnAccountOwnerName",
                      }
                    ]}
                    RowSelected={this.RowSelected}
                    SelectedIndex={this.state.rowIndex}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </div>
    );
  }
}

DdtReturnAccount.propTypes = {
  classes: PropTypes.object.isRequired,
  Disabled: PropTypes.bool,
};

export default withArtifex(DdtReturnAccount, {});
