import { withArtifex } from "core";
import PropTypes from "prop-types";
import React from "react";
import LoadingComponent from "views/Components/LoadingComponent";

class ResultLetterTemplatePreview extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false
		};
	}

	componentDidMount() {

	}


	render() {
		const { content } = this.props;
		const { isLoading } = this.state;
		return (
			<div>
				<LoadingComponent Show={isLoading} />
				<hr />
				{content}
			</div>
		);
	}
}

ResultLetterTemplatePreview.propTypes = {
	classes: PropTypes.object
};

export default withArtifex(ResultLetterTemplatePreview, {});