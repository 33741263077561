import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { GridColumnType } from "views/Constants/Constant.js";
import { GenericCheckInput } from '../../Components/Generic';

class CardEmbossingFileProfile extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientRowId() : -1,
			IsDefaultProfile: ClientHelper.IsClient() ? false : true,
			IsAutomaticGenerateFile: true
		};

		this.state = {
			model: {...this.initialModel},
			vModel: {},
			list: [],
			isEdit: false,
			isLoading: false,
			index: -1
		};


		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : -1,
		};

		this.renderItemUniqueClient = {};
		this.maxLength40 = { maxLength: 40 };
		this.maxLength25 = { maxLength: 25 };
		this.maxLength3 = { maxLength: 3 };
		this.maxLength128 = { maxLength: 128 };
		this.Columns = [
			{
				Header: "Client",
				accessor: "UniqueClient.ClientName"
			},
			{
				Header: "Profile Name",
				accessor: "ProfileName"
			},
			{
				Header: "Emboss File Export Period",
				accessor: "EmbossFileExportPeriod.ParameterDesc",
			},
			{
				Header: "Is Automatic Generate File",
				accessor: "IsAutomaticGenerateFile",
				type: GridColumnType.YesNo

			},
			{
				Header: "Minimum Record Count",
				accessor: "MinimumRecordCount"
			},

		];
	}

	componentDidMount() {
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
		this.props.setAppLeftTitle("Card Emboss File Profile");
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}

	HandleClear = () => {
		this.setState({ model: { ...this.initialModel }, isEdit: false, index: -1 });
	}

	HandleSearch = async () => {
		this.setState({ isLoading: true });
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/prepaidapi/v1.0/CardEmbossFileProfile/Search", this.state.model);
		this.setState({ list: result || [], isLoading: false });
	}

	HandleSubmit = async () => {
		if (!this.Validate()) {
			return;
		}

		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/prepaidapi/v1.0/CardEmbossFileProfile/Insert", this.state.model);

		if (result != null && result > 0) {
			this.HandleClear();
			this.HandleSearch();
			this.props.showMessage("success", "Operation completed successfully!");
		}
	}

	HandleUpdate = async () => {
		if (!this.Validate()) {
			return;
		}
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/prepaidapi/v1.0/CardEmbossFileProfile/Update", this.state.model);

		if (result != null && result > 0) {
			this.HandleClear();
			this.HandleSearch();
			this.props.showMessage("success", "Operation completed successfully!");
		}

	}

	HandleDelete = () => {
		const { ExecuteApiPost, showConfirmMessage } = this.props;
		showConfirmMessage("warning", "Warning", "Are you sure want to delete ?", () => ExecuteApiPost("/prepaidapi/v1.0/CardEmbossFileProfile/Delete", { Id: this.state.model.Id }, null, null, null, [this.HandleClear, this.HandleSearch], true));
	}


	Validate = () => {
		const { showValidationErrors } = this.props;
		const { model, vModel } = this.state;
		var Messages = [];
		vModel.ProfileName = !model.ProfileName;
		if (vModel.ProfileName)
			Messages.push("Profile Name cannot be empty.");

		vModel.UniqueClientId = !model.UniqueClientId || model.UniqueClientId == null || model.UniqueClientId == -1;
		if (vModel.UniqueClientId)
			Messages.push("Client cannot be empty");

		vModel.EmbossFileExportPeriodId = model.IsAutomaticGenerateFile && !model.EmbossFileExportPeriodId;
		if (vModel.EmbossFileExportPeriodId)
			Messages.push("Emboss File Export Period cannot be empty");

		if (Messages.length > 0) {
			showValidationErrors(Messages);
			return false;
		}
		else
			return true;
	}

	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}

	RowSelected = (index) => {
		var tempModel = this.state.list[index];
		this.setState({ isEdit: true, model: tempModel, index });
	}

	render() {
		const { Disabled } = this.props;
		const { alert, model, list, isLoading, index, vModel } = this.state;

		var IsClient = ClientHelper.IsClient();

		return (
			<div>

				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
					{ Code: "Submit", OnClick: this.HandleSubmit, Disabled: model.Id || Disabled },
					{ Code: "Delete", OnClick: this.HandleDelete, Disabled: !model.Id || Disabled },
					{ Code: "Update", OnClick: this.HandleUpdate, Disabled: !model.Id || Disabled },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel >
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={this.parameterUniqueClientId}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={this.RenderItemUniqueClient}
												Value={model.UniqueClientId}
												ValueChanged={this.HandleChange}
												CanClear={true}
												Disabled={IsClient}
												DefaultIndex={IsClient ? 0 : -1}
												Required
												IsInvalid={vModel.UniqueClientId}
												All={false}
											/>
										</GridItem>

										<GridItem xs={4}>
											<GenericTextInput
												Name="ProfileName"
												LabelText="Profile Name"
												Value={model.ProfileName}
												ValueChanged={this.HandleChange}
												Required
												IsInvalid={vModel.ProfileName}
												inputProps={this.maxLength40}
											/>
										</GridItem>

										<GridItem xs={4}>
											<GenericCheckInput
												Name="IsAutomaticGenerateFile"
												LabelText="Is Automatic Generate File"
												Value={model.IsAutomaticGenerateFile}
												ValueChanged={this.HandleChange}
											/>
										</GridItem>

										{model.IsAutomaticGenerateFile &&
											<GridItem xs={4}>
												<ParameterComponent
													Name="EmbossFileExportPeriodId"
													LabelText="Emboss File Export Period"
													ParameterCode="EmbossFileExportPeriod"
													Value={model.EmbossFileExportPeriodId}
													ValueChanged={this.HandleChange}
													Required
													IsInvalid={vModel.EmbossFileExportPeriodId}
													Disabled={Disabled}
												/>
											</GridItem>
										}
										<GridItem xs={4}>
											<GenericNumberInput
												Name="MinimumRecordCount"
												LabelText="Minumum Record Count"
												MaxLength={4}
												Value={model.MinimumRecordCount}
												ValueChanged={this.HandleChange}
												DecimalScale={0}
											/>
										</GridItem>

									</GridContainer>

								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Card Emboss File Profile List">
									<GridContainer>
										<GridItem xs={12}>
											<br />
											<GenericGrid
												Data={list}
												Columns={this.Columns}
												RowSelected={this.RowSelected}
												SelectedIndex={index}
												HideButton={true}
											/>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

CardEmbossingFileProfile.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(CardEmbossingFileProfile, {});
