import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDateInput, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { GridColumnType } from "views/Constants/Constant.js";
import DateHelper from "core/DateHelper";

class WireTransferUnsuccessfulList extends Component {
	constructor(props) {
		super(props);
		this.initialModel = {
			StartDate: null,
			EndDate: null
		};

		this.state = {
			model: this.initialModel,
			isLoading: false
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("WIRE TRANSFER");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("Unsuccessfull Wire Transaction List");
		}
	}

	HandleClear = () => {
		this.setState({ model: this.initialModel });
	}

	handleChange = (name, newValue, data) => {
		const temp = { ...this.state.model };
		temp[name] = newValue;
		this.setState({ model: temp });
	}

	ValueChanged = (name, value) => {
		this.setState({ [name]: value });
	}

	Search = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/WireTransfer/SearchUnsuccessfulWireTransaction",
			this.state.model,
			responseData => {
				if (!responseData.IsSucceeded) {
					return;
				}
				this.setState({ isLoading: false });
				if (responseData.Item != null) {

					this.setState({ data: responseData.Item });
				}
			},
			e => {

				this.setState({ isLoading: false });
			}
		);

	}

	render() {
		const { Disabled } = this.props;
		const { isLoading, model, data } = this.state;

		return (
			<div>
				<LoadingComponent Show={isLoading} />
				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled || model === this.initialModel },
					{ Code: "Search", OnClick: this.Search, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<Card>
					<GenericExpansionPanel IsActive={true} Title="Filter Panel">
						<CardBody>
							<GridContainer>
								<GridItem xs={4}>
									<GenericSelectInput
										Name="ClientId"
										LabelText="Client"
										Method="POST"
										Url="/bankapi/v1.0/BankCustomer/Search"
										Parameter={{}}
										DataRoot="Item"
										KeyValueMember="Id"
										RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
										Value={model.ClientId || ""}
										ValueChanged={this.handleChange}
										All
										CanClear />
								</GridItem>
								<GridItem xs={4}>
									<GenericDateInput
										Utc
										Name="FromDate"
										LabelText="From Date"
										Value={model.FromDate == null || undefined ? "" : model.FromDate}
										ValueChanged={this.handleChange}
										MaxDate={model.ToDate || DateHelper.GetDate()}
										IncludeTime={false} />
								</GridItem>
								<GridItem xs={4}>
									<GenericDateInput
										Utc
										Name="ToDate"
										LabelText="To Date"
										Value={model.ToDate == null || undefined ? "" : model.ToDate}
										ValueChanged={this.handleChange}
										MinDate={model.FromDate == null ? null : model.FromDate}
										MaxDate={DateHelper.GetDate()}
										IncludeTime={false} />
								</GridItem>
								<GridItem xs={4}>
									<GenericTextInput
										Name="SwiftReferenceNumber"
										LabelText="Swift Reference Number"
										Value={model.SwiftReferenceNumber}
										ValueChanged={this.handleChange} />
								</GridItem>
								<GridItem xs={4}>
									<GenericNumberInput
										Name="AmountFrom"
										LabelText="Amount From"
										Value={model.AmountFrom == null ? "" : model.AmountFrom}
										ValueChanged={this.handleChange}
										Prefix="$" />
								</GridItem>
								<GridItem xs={4}>
									<GenericNumberInput
										Name="AmountTo"
										LabelText="Amount To"
										Value={model.AmountTo == null ? "" : model.AmountTo}
										ValueChanged={this.handleChange}
										Prefix="$" />
								</GridItem>
								<GridItem xs={4}>
									<ParameterComponent
										Name="MessageTypeId"
										Value={model.MessageTypeId}
										LabelText="Message Type"
										ParameterCode="WireMessageType"
										ValueChanged={this.handleChange}
										Disabled={Disabled} />
								</GridItem>
								<GridItem xs={4}>
									<GenericTextInput
										Name="TransactionReferenceNumber"
										LabelText="Transaction Reference Number"
										Value={model.TransactionReferenceNumber}
										ValueChanged={this.handleChange} />
								</GridItem>
							</GridContainer>
						</CardBody>
					</GenericExpansionPanel>
				</Card>
				<Card className="no-radius">
					<CardBody>
						<CardHeader>
							<GenericTitle text={"Transaction List"} />
						</CardHeader>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={data}
									Columns={[
										{
											Header: "Unique Client Id",
											accessor: "UniqueClientId",
										},
										{
											Header: "Client Name",
											accessor: "ClientName",
										},
										{
											Header: "SenderName",
											accessor: "SenderName",
										},
										{
											Header: "Transaction Reference Number",
											accessor: "TransactionReferenceNumber",
										},
										{
											Header: "Swift Reference Number",
											accessor: "SwiftReferenceNumber",
										},
										{
											Header: "Client User",
											accessor: "InsertUser",
										},
										{
											Header: "Source Wallet Number",
											accessor: "FromAccAccountNumber"
										},
										{
											Header: "To Wallet Number",
											accessor: "ToAccountNumber"
										},
										{
											Header: "To IBAN",
											accessor: "ToIBAN"
										},
										{
											Header: "Direction",
											accessor: "Direction"
										},
										{
											Header: "Status",
											accessor: "Status"
										},
										{
											Header: "SubStatus",
											accessor: "SubStatus"
										},
										{
											Header: "MessageType",
											accessor: "MessageType"
										},
										{
											Header: "Date of Request", //DB ile aynı mı kontrol et.
											accessor: "DateRequest",
											type: GridColumnType.DateUtc
										},
										{
											Header: "Value Effective",  //DB ile aynı mı kontrol et.
											accessor: "ValueEffective",
											type: GridColumnType.DateUtc
										},
										{
											Header: "Amount",
											accessor: "Amount",
											type: GridColumnType.Money,
											ColumnType: GridColumnType.Money
										},
										{
											Header: "Fee Amount",
											accessor: "FeeAmount",
											type: GridColumnType.Money,
											ColumnType: GridColumnType.Money
										},
										{
											Header: "Currency",
											accessor: "CurrencyName"
										},
										{
											Header: "Remittance",
											accessor: "Remittance"
										},
										{
											Header: "Purpose Of Wire",
											accessor: "PurposeOfWire"
										},
										{
											Header: "Beneficiary Company Name",
											accessor: "CompanyName"
										},
										{
											Header: "Country",
											accessor: "CountryFormat"
										}
									]}
									PageSize={10}
									ShowPagination={true} />
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>
			</div>
		);
	}
}

WireTransferUnsuccessfulList.propTypes = {
	classes: PropTypes.object,
	model: PropTypes.object,
	onModelChange: PropTypes.func,
	Disabled: PropTypes.bool
};

export default WireTransferUnsuccessfulList;
