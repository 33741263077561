import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import { EditIcon } from "core/Icons";
import RouteHelper from "core/RouteHelper";
import PropTypes from "prop-types";
import React from "react";
import AccountLimitToolbar from "views/Components/AccountLimitToolbar";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import { GridColumnType, ProgramCodes, TransactionOrderStatus, MenuCodes } from "views/Constants/Constant.js";

class Transactions extends React.Component {
	constructor(props) {
		super(props);

		this.isClient = ClientHelper.IsClient();
		this.initialModel = {
			UniqueClientId: this.isClient ? ClientHelper.GetClientRowId() : null,
			StartingFromDate: DateHelper.GetDate(),
			EndOfDate: DateHelper.GetDate()

		};

		this.state = {
			list: [],
			model: this.initialModel,
			countModel: {},
			isLoading: false,
			LimitToken: false,
			SelectedRowIndex: undefined,
			SelectedRow: {}
		};

		this.columns = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip="Edit"
							Icon={EditIcon}
							OnClick={() => { RouteHelper.Push(this.props.history, "/CreateEtransfer", "CreateEtransfer_RecurringId", row.original.Id); }} />
					</div>
				),
				sortable: false,
				filterable: false,
				width: 60,
				show: this.isClient
			},
			{
				Header: "Unique Client Id",
				accessor: "ClientNumber",
				show: !this.isClient
			},
			{
				Header: "Client Name",
				accessor: "ClientName",
				show: !this.isClient
			},
			{
				Header: "Transaction Type",
				accessor: "TransactionTypeDescription",
			},
			{
				Header: "Settlement Type",
				accessor: "PriorityTypeDescription"
			},
			{
				Header: "Name of Customer",
				accessor: "PayeeName"
			},
			{
				Header: "Amount",
				accessor: "TransactionAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Fee Amount",
				accessor: "FeeAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Transaction Order Status",
				accessor: "TransactionOrderStatusDescription"
			},
			{
				Header: "Frequency Type",
				accessor: "FrequencyTypeDescription"
			},
			{
				Header: "Start Date",
				accessor: "StartingFromDate",
				type: GridColumnType.DateUtc
			},
			{
				Header: "End Date",
				accessor: "EndOfDate",
				type: GridColumnType.DateUtc
			},
			{
				Header: "Total Payment Count",
				accessor: "NumberOfPayments"
			},
			{
				Header: "CompletedPaymentCount",
				accessor: "CompletedPayments"
			},
			{
				Header: "Remaining Payment Count",
				accessor: "RemainingPayments"
			},
			{
				Header: "Last Payment Status",
				accessor: "LastPaymentStatus"
			},
			{
				Header: "Freeze From Date",
				accessor: "FreezeFromDate",
				type: GridColumnType.DateUtc
			},
			{
				Header: "Freeze To Date",
				accessor: "FreezeToDate",
				type: GridColumnType.DateUtc
			},			
			{
				Header: "Customer Store",
				accessor: "CustomerStore"
			},
			{
				Header: "Last Error Status",
				accessor: "LastCheckStatus"
			}
		]
		this.sortedByDate = [{ id: "StartingFromDate", desc: true }];

		this.HandleClear = this.HandleClear.bind(this);
		this.HandleChange = this.HandleChange.bind(this);

		this.HandleGetList = this.HandleGetList.bind(this);
		this.HandleCancel = this.HandleCancel.bind(this);
		this.Cancel = this.Cancel.bind(this);
		this.SuccessListCallback = this.SuccessListCallback.bind(this);
		this.ErrorListCallback = this.ErrorListCallback.bind(this);
		this.RowSelected = this.RowSelected.bind(this);
	}

	componentDidMount() {
		const { setAppLeftTitle, setAppCenterTitle } = this.props;
		if (setAppLeftTitle) {
			if (this.props.MenuCode == MenuCodes.Transactions_BackofficeEtransferRecurringTransactionSearch) {
				setAppLeftTitle("Recurring Transaction");
			} else {
				setAppLeftTitle("Recurring Transaction Search");
			}
		}

		if (setAppCenterTitle)
			setAppCenterTitle("Interac e-Transfer");

		this.RefreshToolBar();
	}

	RefreshToolBar = () => {
		this.setState({ LimitToken: !this.state.LimitToken });
	}

	HandleClear() {
		this.setState({ model: this.initialModel, list: [] });
	}

	HandleChange(name, value) {
		const model = { ...this.state.model };
		model[name] = value;
		this.setState({ model });
	}

	HandleGetList() {

		if (!this.IsValid())
			return;

		this.setState({ isLoading: true });

		Proxy.POST(
			"/bankapi/v1.0/BankInteracETransfer/SearchRecurringETransferTransactions",
			this.state.model,
			this.SuccessListCallback,
			this.ErrorListCallback
		);
	}

	IsValid = () => {
		var vmodel = { ...this.state.model };
		if (typeof vmodel.StartingFromDate != "object" && vmodel.StartingFromDate != "" && vmodel.StartingFromDate != null) {
			this.props.showMessage("warning", "Please fill required fields!", "From Date is invalid");
			return false;
		}
		if (typeof vmodel.EndOfDate != "object" && vmodel.EndOfDate != "" && vmodel.EndOfDate != null) {
			this.props.showMessage("warning", "Please fill required fields!", "To Date is invalid");
			return false;
		}
		if ((typeof vmodel.StartingFromDate == "object" && typeof vmodel.EndOfDate == "object") && vmodel.EndOfDate < vmodel.StartingFromDate) {
			this.props.showMessage("warning", "Please fill required fields!", "From Date cannot be bigger than To Date");
			return false;
		}
		return true;
	}
	HandleCancel() {
		const { SelectedRow } = this.state;
		var status = SelectedRow.TransactionOrderStatusValue;
		if (status != TransactionOrderStatus.Working && status != TransactionOrderStatus.Pending) {
			this.props.showMessage("warning", "You can only cancel working or pending recurring transactions");
			return;
		}

		this.props.showConfirmMessage("warning", "Recurring Transaction Cancel Confirmation", "Are you sure to cancel this recurring transaction?", () => { this.Cancel(); });
	}

	Cancel() {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/BankInteracETransfer/CancelRecurringRequest",
			{ Id: this.state.SelectedRow.Id },
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "An error occurred while cancelling recurring transaction", responseData.ErrorDescription);
					return;
				}

				this.props.showMessage("info", "Recurring Transaction Cancelled.");

				this.HandleGetList();

				this.setState({ SelectedRow: undefined, SelectedRowIndex: undefined });
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "An error occurred while cancelling recurring transaction", errorMessage);
			}
		);
	}

	SuccessListCallback(responseData) {
		this.setState({ isLoading: false });
		if (!responseData.IsSucceeded) {
			this.ShowMessage("error", "Error", responseData.ErrorDescription);
			return;
		}
		if (responseData.Item !== null) {
			this.setState({ list: responseData.Item });
		} else {
			this.setState({ list: [] });
		}
	}

	ErrorListCallback(error) {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", error);
	}

	ShowMessage(type, title, message) {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode(type, title, message) {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	RowSelected(index) {
		this.setState({ SelectedRowIndex: index, SelectedRow: this.state.list[index] });
	}

	render() {
		const { Disabled } = this.props;
		const { alert, isLoading, model, list, LimitToken, SelectedRowIndex } = this.state;
		return (
			<div>
				{this.isClient && <AccountLimitToolbar key={LimitToken} Program={ProgramCodes.Bank_Programs_e_Transfer} />}

				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.HandleGetList, Disabled: Disabled },
					{ Code: "Cancel", OnClick: this.HandleCancel, Disabled: Disabled || SelectedRowIndex == null },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Main Parameter">
									<GridContainer>
										<GridItem xs={4}>
											<GridItem>
												<GenericSelectInput
													Name="UniqueClientId"
													LabelText="Client"
													Method="POST"
													Url="/bankapi/v1.0/BankCustomer/Search"
													Parameter={{ Id: model.UniqueClientId }}
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
													Value={model.UniqueClientId}
													ValueChanged={this.HandleChange}
													CanClear
													Disabled={Disabled || this.isClient} />
											</GridItem>
											<GridItem>
												<ParameterComponent
													Name="PriorityTypeId" Value={model.PriorityTypeId}
													LabelText="e-Transfer Type" ParameterCode="EtransferType"
													ValueChanged={this.HandleChange} />
											</GridItem>
											<GridItem>
												<GenericSelectInput
													Name="TransactionOrderStatusId"
													LabelText="Transaction Order Status"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={{
														ParameterCode: "TransactionOrderStatus"
													}}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={model.TransactionOrderStatusId}
													ValueChanged={this.HandleChange}
												/>
											</GridItem>
											<GridItem>
												<GenericSelectInput
													Name="TransactionTypeId"
													LabelText="Transaction Type"
													Method="GET"
													Url="/bankapi/v1.0/Transaction/GetAllFinancialTransactionType"
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterValue6"
													Value={model.TransactionTypeId}
													ValueChanged={this.HandleChange} />
											</GridItem>

										</GridItem>
										<GridItem xs={4}>
											<GridItem>
												<GenericTextInput
													Name="PayeeName"
													LabelText="Customer Name"
													Value={model.PayeeName}
													ValueChanged={this.HandleChange} />
											</GridItem>
											<GridItem>
												<GenericTextInput
													Name="TransactionAmount"
													LabelText="Amount"
													Value={model.TransactionAmount}
													ValueChanged={this.HandleChange} />
											</GridItem>
											<GridItem>
												<GenericSelectInput
													Name="FrequencyTypeId"
													LabelText="Frequency Type"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={{
														ParameterCode: "ETransferFrequencyType"
													}}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={model.FrequencyTypeId}
													ValueChanged={this.HandleChange}
												/>
											</GridItem>
											{/* <GridItem>
												<GenericSelectInput
													Name="FirstDueDateOptionId"
													LabelText="First Due Date Option"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={{
														ParameterCode: "EFTRecurringPeriod"
													}}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Value={model.FirstDueDateOptionId}
													ValueChanged={this.HandleChange}
												/>
											</GridItem> */}
										</GridItem>
										<GridItem xs={4}>
											<GridItem>
												<GenericDateInput
													Name="StartingFromDate"
													LabelText="From Date"
													Value={model.StartingFromDate ? DateHelper.ToDateInputValueAsUtc(model.StartingFromDate) : ""}
													ValueChanged={this.HandleChange}
													MaxDate={model.EndOfDate || DateHelper.GetDate()}
													Utc />
											</GridItem>
											<GridItem>
												<GenericDateInput
													Name="EndOfDate"
													LabelText="To Date"
													Value={model.EndOfDate ? DateHelper.ToDateInputValueAsUtc(model.EndOfDate) : ""}
													ValueChanged={this.HandleChange}
													MinDate={model.StartingFromDate}
													Utc />
											</GridItem>
											<GridItem>
												<GenericDateInput
													Name="FrezeeFromDate"
													LabelText="Frezee Date From"
													Value={model.FrezeeFromDate ? DateHelper.ToDateInputValueAsUtc(model.FrezeeFromDate) : ""}
													ValueChanged={this.HandleChange}
													Utc />
											</GridItem>
											<GridItem>
												<GenericDateInput
													Name="FrezeeToDate"
													LabelText="Frezee Date To"
													Value={model.FrezeeToDate ? DateHelper.ToDateInputValueAsUtc(model.FrezeeToDate) : ""}
													ValueChanged={this.HandleChange}
													Utc />
											</GridItem>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={"Recurring Total"} />
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer>
											<GridItem xs={4}>
											</GridItem>
											<GridItem xs={8} />
										</GridContainer>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columns}
													RowSelected={this.RowSelected}
													SelectedIndex={SelectedRowIndex}
													Sorted={this.sortedByDate}
													ProgramCode={ProgramCodes.Bank_Programs_e_Transfer} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

Transactions.propTypes = {
	classes: PropTypes.object
};

export default Transactions;
