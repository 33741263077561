import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericLabel, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { BankingAccountTypes } from "views/Constants/Constant";

class Transfer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			model: {},
			vModel: {},
			readOnlyData: {},
			isLoading: false,
			isConfirmDialogOpen: false,
			reloadData: false
		};
	}
	componentDidMount() {
		//this.props.hideSidebar();
		this.props.setAppLeftTitle("Transfer  Between My Accounts");
		this.props.setAppCenterTitle("WALLET");
	}
	PerformTransaction = () => {
		const model = { ...this.state.model };
		let vModel = { ...this.state.model }
		var ErrorList = [];
		vModel.SourceAccountId = model.SourceAccountId == null || model.SourceAccountId == "";
		if (vModel.SourceAccountId) {
			ErrorList.push("Please select source Wallet !");
		}
		vModel.DestinationAccountId = model.DestinationAccountId == null || model.DestinationAccountId == ""
		if (vModel.DestinationAccountId) {
			ErrorList.push("Please select receiver Wallet !");
		}
		 
		if (model.SourceAccountId == model.DestinationAccountId) {
			ErrorList.push("Accounts must be different !");
		}
		if (model.SourceAccountType != BankingAccountTypes.PooledAccount) {
			vModel.Amount = model.Amount == null || model.Amount == "";
			if (vModel.Amount) {
				ErrorList.push("Please fill transaction amount !");
			}
		}

this.setState({vModel});
		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", ErrorList.slice(0, 10).map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		this.setState({ isConfirmDialogOpen: true });
	}
	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	SendTransaction = () => {
		//GetCustomerBankAccountProfileOp class
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/Transaction/TransferBetweenAccounts",
			this.state.model,
			responseData => {

				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item == true) {
					this.setState({ isConfirmDialogOpen: false, model: {}, readOnlyData: {}, reloadData: !this.state.reloadData });
					this.ShowMessage("success", "Success", "Transaction Completed !");
				} else {
					this.ShowMessage("error", "Error !", "An error occurred !");
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error !", "An error occurred while requesting data " + error);
			}
		);
	}

	handleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		var temp = { ...this.state.readOnlyData };
		model[name] = newValue;
		if (name == "SourceAccountId" && data != null) {
			temp.SourceAccountNumber = data.AccountNumber;
			temp.SourceBalance = data.Balance;
			temp.SourceName = data.AccountName;
			temp.SourceType = data.AccountType;
			model.SourceAccountType = data.AccountCode;
			model.ProgramId = data.ProgramId;
			model.PoolAccountId = data.PoolAccountId;
			model.Amount = 0;
		}
		if (name == "DestinationAccountId" && data != null) {
			temp.DestinationAccountNumber = data.AccountNumber;
			temp.DestinationBalance = data.Balance;
			temp.DestinationName = data.AccountName;
			temp.DestinationType = data.AccountType;
		}
		this.setState({ model: model, readOnlyData: temp });
	}
	ClearData = () => {
		this.setState({ isLoading: false });
		this.setState({
			alert: null,
			model: {},
			readOnlyData: {}
		});
	}
	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({ alert: null, isLoading: false });
	}
	render() {
		const { classes, Disabled } = this.props;
		const { model, isConfirmDialogOpen, readOnlyData, vModel } = this.state;
		return (
			<div>
				{this.state.alert}

				<LoadingComponent Show={this.state.isLoading} />

				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: () => this.ClearData(), Disabled: Disabled },
					{ Code: "Submit", OnClick: () => this.PerformTransaction(), Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<Card className="no-radius">
					<GenericExpansionPanel>
						<CardBody>
							<GridItem xs={12}>
								<GridContainer justify="flex-start">
									<GridItem xs={12}>
										<GenericTitle text={"Please Select the Transfer Accounts"} />
									</GridItem>
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												key={this.state.reloadData + ""}
												Name="SourceAccountId"
												Disabled={Disabled}
												Method="POST"
												Url="/bankapi/v1.0/Account/CustomerSourceAccounts"
												Parameter={{ IsStatusCheck: true }}
												DataRoot="Item"
												LabelText="From Wallet"
												Value={model.SourceAccountId || ""}
												KeyValueMember="Id"
												TextValueMember="AccountName"
												ValueChanged={this.handleChange}
												Required
												IsInvalid={vModel.SourceAccountId}
											/>
										</GridItem>
										<GridItem xs={4}>
											{readOnlyData.SourceName != null && (
												<Card>
													<CardBody>
														<p style={{ textColor: "#000", fontSize: "12px", fontWeight: "bold" }}> Balance : {Formatter.FormatMoney(readOnlyData.SourceBalance)}</p>
														<p style={{ textColor: "#000", fontSize: "12px", fontWeight: "bold" }}> Wallet Name : {readOnlyData.SourceName}</p>
														<p style={{ textColor: "#000", fontSize: "12px", fontWeight: "bold" }}> Wallet Number : {readOnlyData.SourceAccountNumber}</p>
														<p style={{ textColor: "#000", fontSize: "12px" }}> Wallet Type : {readOnlyData.SourceType} </p>
													</CardBody>
												</Card>
											)}
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												key={this.state.reloadData + ""}
												Name="DestinationAccountId"
												Disabled={Disabled}
												Method="POST"
												Url="/bankapi/v1.0/Account/CustomerTransferAccounts"
												Parameter={{}}
												DataRoot="Item"
												LabelText="To Wallet"
												Value={model.DestinationAccountId || ""}
												KeyValueMember="Id"
												TextValueMember="AccountName"
												ValueChanged={this.handleChange}
												Required
												IsInvalid={vModel.DestinationAccountId}
											/>
										</GridItem>
										<GridItem xs={4}>
											{readOnlyData.DestinationName != null && (
												<Card>
													<CardBody>
														<p style={{ textColor: "#000", fontSize: "12px", fontWeight: "bold" }}> Balance : {Formatter.FormatMoney(readOnlyData.DestinationBalance)}</p>
														<p style={{ textColor: "#000", fontSize: "12px", fontWeight: "bold" }}> Wallet Name : {readOnlyData.DestinationName}</p>
														<p style={{ textColor: "#000", fontSize: "12px", fontWeight: "bold" }}> Wallet Number : {readOnlyData.DestinationAccountNumber}</p>
														<p style={{ textColor: "#000", fontSize: "12px" }}> Wallet Type : {readOnlyData.DestinationType} </p>
													</CardBody>
												</Card>
											)}
										</GridItem>
									</GridContainer>


									<GridItem xs={12} style={{ marginTop: "20px" }}>
										<GenericTitle text={"Please Enter Transaction Information"} />
									</GridItem>
									<GridItem xs={4}>
										{model.SourceAccountType != BankingAccountTypes.PooledAccount && (
											<GenericNumberInput MaxLength={10} Prefix="$ " Name="Amount" LabelText="Transaction Amount" Value={model.Amount} ValueChanged={this.handleChange} Required IsInvalid={vModel.Amount}/>
										)}
										{model.SourceAccountType == BankingAccountTypes.PooledAccount && (
											<p style={{ textColor: "#000", fontSize: "12px", fontWeight: "bold" }}> Collect All Money From All Sub Accounts. Total Balance: {Formatter.FormatMoney(readOnlyData.SourceBalance)}</p>
										)}
										<GenericTextInput inputProps={{ maxLength: 100 }} Name="Explanation" LabelText="Explanation" Value={model.Explanation || ""} ValueChanged={this.handleChange} />
									</GridItem>
								</GridContainer>


								<GenericDialog open={isConfirmDialogOpen} maxWidth="lg">
									<DialogTitle>Transaction Confirmation
									</DialogTitle>
									<DialogContent>
										<GridItem>
											<GenericTextInput Name="" LabelText="From Wallet" Value={readOnlyData.SourceName} Disabled={true} />
											<GenericTextInput Name="" LabelText="To Wallet" Value={readOnlyData.DestinationName} Disabled={true} />
											{model.SourceAccountType != BankingAccountTypes.PooledAccount && (
												<GenericNumberInput Prefix="$ " Name="Amount" LabelText="Amount" Value={model.Amount} MaxLength={10} Disabled={true} Required IsInvalid={vModel.Amount}/>
											)}
											{model.SourceAccountType == BankingAccountTypes.PooledAccount && (
												<p style={{ textColor: "#000", fontSize: "12px", fontWeight: "bold" }}> Collect All Money From All Sub Accounts. Total Balance: {Formatter.FormatMoney(readOnlyData.SourceBalance)}</p>
											)}
											<GenericTextInput inputProps={{ maxLength: 100 }} Name="Explanation" LabelText="Description" Value={model.Explanation || ""} Disabled={true} />
											<GenericLabel Text="Are you sure you want to enter this transaction ?" Bold={true} />
										</GridItem>
									</DialogContent>
									<GenericDialogActions>
										<Button size="sm" onClick={() => this.SendTransaction()}>
											Transfer
											</Button>
										<Button size="sm" onClick={() => this.setState({ isConfirmDialogOpen: false })}>
											Cancel
											</Button>
									</GenericDialogActions>
								</GenericDialog>



							</GridItem>
						</CardBody >
					</GenericExpansionPanel>
				</Card>

			</div>

		);
	}
}

Transfer.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func
};


export default withArtifex(Transfer, {});