import { DynamicComponent } from "core";
import React from "react";
import { withRouter } from "react-router";
import { Redirect, Route } from "react-router-dom";

class PrivateRoute extends React.Component {

	componentDidUpdate(prevProps) {
		if (prevProps.location !== this.props.location) {
			if (prevProps.location != null)
				if (localStorage.getItem("previousUrl") != prevProps.location.pathname)
					localStorage.setItem("previousUrl", prevProps.location.pathname);
		}
	}

	render() {
		const { component, ...rest } = this.props;
		const { UserContext } = this.props;

		if (UserContext && UserContext.user) {
			if (UserContext.user.MustChangePwd) {
				return <Redirect to="/acc/reset" />;
			}
			return <Route {...rest} render={() => <DynamicComponent {...rest} comp={component} />} />;
		}

		return <Redirect to="/acc/login" />;
	}
}

export default withRouter(PrivateRoute);