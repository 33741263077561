import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import CustomerComponent from "views/Components/CustomerComponent";
import { GenericAlert, GenericDateInput, GenericExpansionPanel, GenericGrid, GenericSelectInput,GenericTitle,GenericLabel, GenericRadioInput, GenericDialogActions,GenericDialog } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType, ProgramCodes } from "views/Constants/Constant";
import CardHeader from "components/Card/CardHeader.jsx";
import { DialogTitle, DialogContent } from "@material-ui/core";
import Select from "react-select";
import Button from "components/CustomButtons/Button";


class CardTransaction extends React.Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			UniqueClientId: null,
			Customer: null,
			DefaultAccount: null,
			CardId: null,
			ValidFrom: null,
			ValidTo: null,
			CmsTransactionCodeId: null,
			CatmTransactionCodeId: null
		};

		this.columns = [
			{
				Header: "Transaction Date",
				accessor: "TransactionDate",
				type: GridColumnType.DateTime
			},
			{
				Header: "Cardholder Name",
				accessor: "CardOwnerName"
			},
			{
				Header: "Masked Card Number",
				accessor: "MaskedCardNumber"
			},
			{
				Header: "Transaction Code",
				accessor: "TransactionTypeName"
			},
			{
				Header: "Merchant",
				accessor: "MerchantName"
			},
			{
				Header: "City",
				accessor: "City"
			},
			{
				Header: "Response Code",
				accessor: "ResponseCode"
			},
			{
				Header: "Original Amount",
				accessor: "TransactionAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money

				// Cell: row => (
				// 	<div>
				// 		{row.original.TransactionAmount + " " + (row.original.TransactionCurrency != null ? row.original.TransactionCurrency.Code : "")}
				// 	</div>
				// ),
			},
			{
				Header: "Amount",
				accessor: "BillingAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
				// Cell: row => (
				// 	<div>
				// 		{row.original.BillingAmount + " " + (row.original.CardholderCurrency != null ? row.original.CardholderCurrency.Code : "")}
				// 	</div>
				// ),
			},
			{
				Header: "Fee",
				accessor: "Fee",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Merchant Clearing Amount",
				accessor: "SettlementAmount",

				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
				// Cell: row => (
				// 	<div>
				// 		{row.original.SettlementAmount + " " + (row.original.SettlementCurrency != null ? row.original.SettlementCurrency.Code : "")}
				// 	</div>
				// ),
			},
			{
				Header: "Merchant Clearing Date",
				accessor: "SettlementDate",
				type: GridColumnType.Date
			},
			{
				Header: "Remaining Balance",
				accessor: "RemainingBalance",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Mobile Wallet Type",
				accessor: "MobileWalletType"
			}
		];

		this.cardTrxTable = React.createRef();

		this.parameterIsCatmTrue = { IsCatm: true };
		this.parameterIsCatmFalse = { IsCatm: false };

		this.state = {
			list: [],
			model: this.initialModel,
			filterModel: {},
			isLoading: false,
			LimitToken: false,
			LimitBalanceData: {
				AvailableCreditLimit: 0,
				AvailableDebitLimit: 0,
				AvailableBalance: 0
			},
			isBackOffice: ClientHelper.IsBackOffice()
		};
	}

	componentDidMount() {
		if (this.props.setAppCenterTitle) {
			this.props.setAppLeftTitle("Card Transaction Search");
			this.props.setAppCenterTitle("LOAN MANAGEMENT SYSTEM");
		}
	}

	RefreshToolBar = () => {
		this.setState({ LimitToken: !this.state.LimitToken });
	};

	LimitDataLoad = data => {
		this.setState({ LimitBalanceData: data, isLoading: false });
	};

	handleChange = (name, newValue) => {
		const model = { ...this.state.model };
		const initialModel = { ...this.initialModel };
		if (name === "Customer") {
			if (newValue != null) {
				model.Customer = newValue;
				if (newValue.Accounts != null && newValue.Accounts.length > 0) {
					model.DefaultAccount = newValue.Accounts[0];
				}
			} else {
				model.Customer = initialModel.Customer;
				model.DefaultAccount = initialModel.DefaultAccount;
			}
		} else if (name === "UniqueClientId") {
			if (
				!this.state.isBackOffice &&
				initialModel.UniqueClientId == null &&
				newValue != null
			) {
				this.initialModel.UniqueClientId = newValue;
			}
			model.UniqueClientId = newValue;
		} else if (name === "ValidFrom") {
			model.ValidFrom = newValue;
		} else if (name === "ValidTo") {
			model.ValidTo = newValue;
		} else if (name === "CatmTransactionCodeId") {
			model.CatmTransactionCodeId = newValue;
		} else if (name === "CmsTransactionCodeId") {
			model.CmsTransactionCodeId = newValue;
		}
		this.setState({ model });
	};

	ValueChanged = (name, value) => {
		this.setState({ [name]: value });
	}

	HandleClear = () => {
		this.setState({
			model: this.initialModel,
			filterModel: {},
			selected: null,
			list: []
		});
	};

	ExportClick = () => {
		var cardTrxColumns = [];
		this.cardTrxTable.current.props.Columns.forEach(c => {
			if (c.show == undefined || c.show) {
				cardTrxColumns.push({ value: c.accessor, title: c.Header, columntype: c.ColumnType, type: c.type });
			}
		}, this);

		this.setState({ isDownloadDialogOpen: true, GridKeyValueList: cardTrxColumns, ExportSelectedColumns: cardTrxColumns });
	}

	ExcelDownload = () => {
		if (!this.state.ExportReportType) {
			this.props.showMessage("warning", "Export Report Type not selected", "Select Export Report Type to continue");
			return;
		}

		if (this.state.ExportReportType == "PDF" && this.state.ExportSelectedColumns.length > 15) {
			this.props.showMessage("warning", "PDF document cannot exceed 15 columns", "PDF document cannot exceed 15 columns");
			return;
		}

		this.setState({ isDownloadDialogOpen: false });
		const temp = { ...this.state.model };
		var jsonColList = [];

		var cardTrxColumns = {};
		this.state.ExportSelectedColumns.map(c => {
			cardTrxColumns[c.value] = { value: c.value, title: c.title, columntype: c.columntype, type: c.type };
		});
		jsonColList.push(cardTrxColumns);

		var mytitle = "Card Transaction List";

		temp.jsonFile = {
			colmns: jsonColList,
			format: this.state.ExportReportType,
			title: mytitle
		};

		this.setState({ isLoading: true });
		var request = {
			Criteria: 0,
			BeginDate: temp.ValidFrom,
			EndDate: temp.ValidTo,
			CatmTransactionCodeId: temp.CatmTransactionCodeId,
			CmsTransactionCodeId: temp.CmsTransactionCodeId,
			ClientCustomerId: temp.Customer == null ? null : temp.Customer.Id,
			UniqueClientId: this.state.isBackOffice ? temp.UniqueClientId : ClientHelper.GetClientId(),
			CardId: temp.CardId,
			jsonFile:temp.jsonFile
		};
		Proxy.DownloadGeneratedFile(
			"/lmsapi/v1.0/Statement/CardTransactionSearchExportDownload",
			request,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", errorMessage);
			},
			this.props.showMessage
		);
	}

	addDays = (date, days) => {
		var returnDate = new Date();
		returnDate.setDate(date.getDate() + days);
		return returnDate;
	};

	HandleSearch = () => {
		const model = { ...this.state.model };
		this.setState({ isLoading: true });
		var request = {
			Criteria: 0,
			BeginDate: model.ValidFrom,
			EndDate: model.ValidTo,
			CatmTransactionCodeId: model.CatmTransactionCodeId,
			CmsTransactionCodeId: model.CmsTransactionCodeId,
			ClientCustomerId: model.Customer == null ? null : model.Customer.Id,
			UniqueClientId: this.state.isBackOffice ? model.UniqueClientId : ClientHelper.GetClientId(),
			CardId: model.CardId
		};
		Proxy.ExecuteGeneral(
			this, "POST",
			"/lmsapi/v1.0/Statement/CardTransactionSearch",
			request,
			responseData => {
				this.setState({
					model,
					list: responseData.Item
				});
			}
		);
	};

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })}
				/>
			)
		});
	};

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					MessageNode={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })}
				/>
			)
		});
	};

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					ShowCancel={true}
					OnCancel={() => this.setState({ alert: "" })}
					OnConfirm={onConfirm}
				/>
			)
		});
	};

	render() {
		const { Disabled } = this.props;
		const { alert, model, list, isLoading, isBackOffice } = this.state;

		return (
			<div>

				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							OnClick: this.HandleSearch,
							Disabled: Disabled,
							ModelFunction: () => model,
							FillDataFromModel: modelFillData => this.setState({ modelFillData }),
							//ValidationFunction: this.Validate
						},
						{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
						{ Code: "Export", OnClick: this.ExportClick, Disabled: Disabled }
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After}
				/>

				<GenericDialog open={this.state.isDownloadDialogOpen} fullWidth={true}>
					<DialogTitle>
						<GenericLabel Text="Select Report Type" FontSize="20px" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ height: 300 }}>
						<GenericRadioInput
							Name="ExportReportType"
							LabelText="Report Type"
							IsStatic={true}
							StaticData={[{ Value: "PDF", Text: "PDF" }, { Value: "CSV", Text: "CSV" }, { Value: "EXCEL", Text: "EXCEL" }]}
							KeyValueMember="Value"
							TextValueMember="Text"
							Value={this.state.ExportReportType}
							ValueChanged={this.ValueChanged}
						/>
						<GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
						<GridItem style={{ marginTop: 20 }}>
							<Select
								value={this.state.ExportSelectedColumns}
								onChange={value => this.setState({ ExportSelectedColumns: value })}
								isMulti
								name="columns"
								getOptionLabel={d => d.title}
								options={this.state.GridKeyValueList}
								className="basic-multi-select"
								classNamePrefix="select"
							/>
						</GridItem>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.ExcelDownload}>Download</Button>
						<Button size="sm" onClick={() => this.setState({ isDownloadDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>



				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card>
							<CardBody>
								<GenericExpansionPanel>

									<GridItem xs={12}>
										<GridContainer direction="row" justify="flex-start" alignItems="flex-start">
											<GridItem xs={4}>
												<GenericSelectInput
													Name="UniqueClientId"
													LabelText="Client"
													Method="POST"
													Url="/bankapi/v1.0/BankCustomer/Search"
													Parameter={{
														UniqueClientId: isBackOffice ? undefined : ClientHelper.GetClientId()
													}}
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
													Value={model.UniqueClientId || ""}
													ValueChanged={this.handleChange}
													CanClear
													All
													Disabled={!isBackOffice}
													DefaultIndex={!isBackOffice ? 0 : -1}
												/>
											</GridItem>
											<GridItem xs={4}>
												<CustomerComponent
													LabelMd={4}
													CustomerNumber={model.Customer === null ? null : model.Customer.CustomerNumber}
													UniqueClientId={model.UniqueClientId}
													FillCallback={customer => { this.handleChange("Customer", customer); }}
													Programs={[ProgramCodes.Prepaid]} />
											</GridItem>
											<GridItem xs={4}>
												<GenericSelectInput
													Name="CatmTransactionCodeId"
													LabelText="CATM Transaction Code"
													Url="/lmsapi/v1.0/Statement/CardTransactionCodeList"
													Method="POST"
													Parameter={this.parameterIsCatmTrue}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="TransactionCode"
													Value={model.CatmTransactionCodeId}
													ValueChanged={this.handleChange} />
											</GridItem>
										</GridContainer>
										<GridContainer direction="row" justify="flex-start" alignItems="flex-start" >
											<GridItem xs={4}>
												<GenericDateInput
													Name={"ValidFrom"}
													LabelText="Valid From"
													Value={model.ValidFrom ? DateHelper.ToDateInputValueAsUtc(model.ValidFrom) : ""}
													MaxDate={model.ValidTo || DateHelper.GetDate()}
													ValueChanged={this.handleChange}
													Utc />
											</GridItem>
											<GridItem xs={4}>
												<GenericDateInput
													Name={"ValidTo"}
													LabelText="Valid To"
													Value={model.ValidTo ? DateHelper.ToDateInputValueAsUtc(model.ValidTo) : ""}
													MinDate={model.ValidFrom || DateHelper.GetDate()}
													ValueChanged={this.handleChange}
													Utc />
											</GridItem>
											<GridItem xs={4}>
												<GenericSelectInput
													Name="CmsTransactionCodeId"
													LabelText="Bank Transaction Code"
													Url="/lmsapi/v1.0/Statement/CardTransactionCodeList"
													Method="POST"
													Parameter={this.parameterIsCatmFalse}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="TransactionCode"
													Value={model.CmsTransactionCodeId}
													ValueChanged={this.handleChange} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GenericExpansionPanel>
							</CardBody>
						</Card>

						<Card className="no-radius">
							<CardBody>
								<CardHeader>
									<GenericTitle text={"Card Transaction List"} />
								</CardHeader>
								<GridItem xs={12}>
									<GridItem>
										<GenericGrid
											key={"trxGrid" + this.state.trxGridToken}
											ref={this.cardTrxTable}
											Data={list}
											PageSize={10}
											ShowPagination={true}
											Columns={this.columns}
											ProgramCode={ProgramCodes.Bank_Programs_e_Transfer}
										/>
									</GridItem>
								</GridItem>
							</CardBody>
						</Card>

					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

CardTransaction.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(CardTransaction, {});
