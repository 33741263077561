import withStyles from "@material-ui/core/styles/withStyles";
import { chartGradientColors } from "assets/jss/material-dashboard-pro-react.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import { Chart, Doughnut as DoughnutChart } from "react-chartjs-2";

const chartStyles = ({
	circleBoxGrid: {
		width: "14%",
		textAlign: "center"
	},
	circleBox: {
		display: "inline-block",
		marginTop: "24px",
		marginLeft: "auto",
		marginRight: "auto",
		marginBottom: "24px"
	}
});

class AmlKycAlertChart extends React.Component {
	constructor(props) {
		super(props);

		this.chartOptions = {
			maintainAspectRatio: false,
			responsive: false,
			legend: {
				display: false
			},
			cutoutPercentage: 90,
			animation: {
				duration: 2000
			}
		};

		this.state = {};
	}

	componentDidMount() {
		var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;

		Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
			draw: function () {
				originalDoughnutDraw.apply(this, arguments);

				var chart = this.chart;
				var width = chart.chart.width,
					height = chart.chart.height,
					ctx = chart.chart.ctx;
				var fontSize = 1.5;

				ctx.font = fontSize + "em sans-serif";
				ctx.textBaseline = "middle";
				ctx.fillStyle = "#000";

				var text = chart.config.data.text,
					textX = Math.round((width - ctx.measureText(text).width) / 2),
					textY = height / 2;

				ctx.fillText(text, textX, textY);
			}
		});
	}

	GetGradientList(dataList) {
		var gradientList = undefined;

		if (document.getElementById("canvas") != null) {
			var ctx = document.getElementById("canvas").getContext("2d");
			gradientList = [];

			if (dataList.datasets != undefined) {
				var i;

				for (i = 0; i < dataList.datasets[0].data.length; i++) {
					var gradient = ctx.createLinearGradient(0, 0, 0, 400);

					if (chartGradientColors[i] == undefined) {
						gradient.addColorStop(0, chartGradientColors[0].begin);
						gradient.addColorStop(1, chartGradientColors[0].end);
					} else {
						gradient.addColorStop(0, chartGradientColors[i].begin);
						gradient.addColorStop(1, chartGradientColors[i].end);
					}
					gradientList.push(gradient);
				}

				return gradientList;
			}
		}

		return gradientList;
	}
	render() {
		const { classes, chartDataTotal, chartDataProgram1, chartDataProgram2, chartDataProgram3, chartDataOther, titleChartProgram1, titleChartProgram2, titleChartProgram3 } = this.props;

		var chartDataTotalGradientList = this.GetGradientList(chartDataTotal);
		var chartDataTotalBackground = chartDataTotal;
		if (chartDataTotalGradientList != undefined) {
			chartDataTotalBackground.datasets[0].backgroundColor = chartDataTotalGradientList;
			chartDataTotalBackground.datasets[0].hoverBackgroundColor = chartDataTotalGradientList;
		}

		var chartDataProgram1GradientList = this.GetGradientList(chartDataProgram1);
		var chartDataProgram1Background = chartDataProgram1;
		if (chartDataProgram1GradientList != undefined) {
			chartDataProgram1Background.datasets[0].backgroundColor = chartDataProgram1GradientList;
			chartDataProgram1Background.datasets[0].hoverBackgroundColor = chartDataProgram1GradientList;
		}

		var chartDataProgram2GradientList = this.GetGradientList(chartDataProgram2);
		var chartDataProgram2Background = chartDataProgram2;
		if (chartDataProgram2GradientList != undefined) {
			chartDataProgram2Background.datasets[0].backgroundColor = chartDataProgram2GradientList;
			chartDataProgram2Background.datasets[0].hoverBackgroundColor = chartDataProgram2GradientList;
		}

		var chartDataProgram3GradientList = this.GetGradientList(chartDataProgram3);
		var chartDataProgram3Background = chartDataProgram3;
		if (chartDataProgram3GradientList != undefined) {
			chartDataProgram3Background.datasets[0].backgroundColor = chartDataProgram3GradientList;
			chartDataProgram3Background.datasets[0].hoverBackgroundColor = chartDataProgram3GradientList;
		}

		var chartDataOtherGradientList = this.GetGradientList(chartDataOther);
		var chartDataOtherBackground = chartDataOther;
		if (chartDataOtherGradientList != undefined) {
			chartDataOtherBackground.datasets[0].backgroundColor = chartDataOtherGradientList;
			chartDataOtherBackground.datasets[0].hoverBackgroundColor = chartDataOtherGradientList;
		}

		return (
			<GridContainer spacing={16}>
				<GridItem xs={12}>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs={1} />
								<GridItem className={classes.circleBoxGrid} xs={2}>
									<div className={classes.circleBox}>
										<DoughnutChart
											id="canvas"
											data={chartDataTotalBackground}
											width={200}
											height={300}
											options={this.chartOptions} />
										<h4>Total Issues</h4>
									</div>
								</GridItem>
								<GridItem className={classes.circleBoxGrid} xs={2}>
									<div className={classes.circleBox}>
										<DoughnutChart
											data={chartDataProgram1Background}
											width={200}
											height={300}
											options={this.chartOptions} />
										<h4>{titleChartProgram1}</h4>
									</div>
								</GridItem>
								<GridItem className={classes.circleBoxGrid} xs={2}>
									<div className={classes.circleBox}>
										<DoughnutChart
											data={chartDataProgram2Background}
											width={200}
											height={300}
											options={this.chartOptions} />
										<h4>{titleChartProgram2}</h4>
									</div>
								</GridItem>
								<GridItem className={classes.circleBoxGrid} xs={2}>
									<div className={classes.circleBox}>
										<DoughnutChart
											data={chartDataProgram3Background}
											width={200}
											height={300}
											options={this.chartOptions} />
										<h4>{titleChartProgram3}</h4>
									</div>
								</GridItem>
								<GridItem className={classes.circleBoxGrid} xs={2}>
									<div className={classes.circleBox}>
										<DoughnutChart
											data={chartDataOtherBackground}
											width={200}
											height={300}
											options={chartDataOther.text == 0 ? { ...this.chartOptions, ...{ tooltips: { enabled: false } } } : this.chartOptions} />
										<h4>Other Issues</h4>
									</div>
								</GridItem>
								<GridItem xs={1} />
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

AmlKycAlertChart.propTypes = {
	classes: PropTypes.object,
	chartDataTotal: PropTypes.object,
	chartDataProgram1: PropTypes.object,
	chartDataProgram2: PropTypes.object,
	chartDataProgram3: PropTypes.object,
	chartDataOther: PropTypes.object,
	titleChartProgram1: PropTypes.string,
	titleChartProgram2: PropTypes.string,
	titleChartProgram3: PropTypes.string
};

export default withStyles(chartStyles)(AmlKycAlertChart);