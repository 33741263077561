import { List } from "@material-ui/icons";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import { EmailIcon, PrintIcon } from "core/Icons";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericAlert, GenericDateInput, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";

const styles = ({
	pictureSrc: {
		width: 75,
		height: 75
	}
});

const initialState = {
	model: {
		FileTypeId: null,
		Description: null,
		VersionNumber: null,
	},
	isLoading: false,
	ReadOnly: true,
	LedgerCardList: [],
	modelrow: {},
	selected: null,
};

class LedgerCard extends Component {
	constructor(props) {
		super(props);
		this.emptyObject = {};
		this.initalModel = {};

		this.FileTypeParameterCode = { ParameterCode: "LOSFileType" };
		this.state = initialState;

		this.ColumnsData = [
			{
				Header: "Payment No",
				accessor: "Payment No",
				Cell: (row) => { return <div>{row.index + 1}</div>; },
				sortable: false,
				filterable: false,
			},
			{
				Header: "Installment Date",
				accessor: "InstallmentDateFormatted",
				sortable: false,
				filterable: false,
			},
			{
				Header: "Beginning Balance",
				accessor: "BeginningBalanceFormatted",
				sortable: false,
				filterable: false,
			},
			{
				Header: "Principal Amount",
				accessor: "PrincipalAmountFormatted",
				sortable: false,
				filterable: false,
			},
			{
				Header: "Interest Balance",
				accessor: "InterestBalanceFormatted",
				sortable: false,
				filterable: false,
			},
			{
				Header: "Install Amount",
				accessor: "InstallmentAmountFormatted",
				sortable: false,
				filterable: false,
			},
			{
				Header: "Ending Balance",
				accessor: "EndingBalanceFormatted",
				sortable: false,
				filterable: false,
			},
			{
				Header: "InstallmentDate",
				accessor: "InstallmentDate",
				show: false
			}
		];

		this.InterestTypeParameterCode = { ParameterCode: "LOSInterestType" };
		this.PeriodParameterCode = { ParameterCode: "Period" };
		this.PayOptionParameterCode = { ParameterCode: "LOSPayOption" };
		this.YesNoParameterCode = { ParameterCode: "LOSYesNo" };

		this.sortedInstallmentDate = [{ id: "InstallmentDate", desc: false }];
	}

	componentDidMount() {
		this.Search();
	}

	Search = () => {
		const { ApplicationId } = this.props;
		if (ApplicationId > 0) {
			this.setState({ isLoading: true });
			Proxy.POST(
				"/lmsapi/v1.0/LoanLedgerCard/GetLoanLedgerCard",
				{ ApplicationId },
				responseData => {
					console.log("GetLedgerCard ->", responseData.IsSucceeded);
					if (!responseData.IsSucceeded) {
						this.setState({ isLoading: false });
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
					if (responseData.Item != null) {
						console.log(responseData);
						this.setState({
							LedgerCardList: responseData.Item.LedgerCardList || [],
							TotalPaymentAmount: responseData.Item.FromattedTotalPayment
							, isLoading: false
						});
					} else {
						this.setState({ LedgerCardList: [], isLoading: false });
					}
				},
				error => {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "Error", error);
				}
			);
		}
	}

	HandleChange = (name, newValue) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model, selected: null, });

	}

	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}

	HandleClickClearOk = () => {
		this.HideAlert();
		this.setState({ model: {} });
	}

	HandleClear = () => {
		this.ShowConfirmMessage("warning", "Warning", "This will erase the information you entered on the screen. Are you sure you want to quit this agreement template?", () => this.HandleClickClearOk());
	}

	ErrorCallback = (error) => {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", "An error occurred during the api visit" + error);
	}

	HandleDocument = () => {
		this.setState({ isLoading: true });
		Proxy.FileDownload(
			"/lmsapi/v1.0/LoanLedgerCard/Print",
			"LoanLedgerCard-" + Math.floor(Math.random() * Math.floor(999999)),
			"pdf",
			{
				LedgerCardList: this.state.LedgerCardList,
				BorrowerName: this.props.model.Applicant.FullName,
				ApplicationNumber: this.props.model.ApplicationNumber
			},
			() => {
				this.setState({ isLoading: false });
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while sending data", error.message);
			}
		);
	}

	HandleSendMail = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/lmsapi/v1.0/LoanLedgerCard/SendEmail",
			{
				ApplicantId: this.props.model.ApplicantId,
				LedgerCardList: this.state.LedgerCardList,
				BorrowerName: this.props.model.Applicant.FullName,
				ApplicationNumber: this.props.model.ApplicationNumber
			},
			() => {
				this.setState({ isLoading: false });
				this.ShowMessage("success", "Success", "Operation Successfully Completed");
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while sending data", error.message);
			}
		);
	}

	ShowMessageAndRefresh = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlertAndRefresh()} />
		});
	}
	hideAlertAndRefresh = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
		window.location.reload();

	}
	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: null })} OnConfirm={onConfirm} />
		});
	}

	HideAlert = () => {
		this.setState({ alert: null });
	}

	RefreshToolBar = () => {
		this.setState({ LimitToken: !this.state.LimitToken });
	}

	SelectedRowChange = (e, index) => {
		const model = this.state.LedgerCardList[index];
		this.setState({ modelrow: model });
		console.log(this.state.modelrow);
	}

	render() {
		const { classes, model } = this.props;
		const { alert, isLoading, ReadOnly } = this.state;
		return (
			<div>


				<LoadingComponent Show={isLoading} />

				<GridContainer>
					{alert}
					<GridItem xs={12} style={{ marginTop: 30 }}>
						<Card className="no-radius">
							<CardHeader color="warning" icon>
								<CardIcon color="warning">
									<List />
								</CardIcon>
								<h4 className={classes.cardIconTitle} style={{ color: "black" }}>
									<b>Loan Information</b>
								</h4>
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={4}>
										<GridItem>
											<GenericTextInput
												Name="BorrowerName"
												LabelText="Borrower Name"
												LabelMd={5}
												Value={model == null || undefined ? "" : model.Applicant == null ? "" : model.Applicant.FullName}
												ValueChanged={this.HandleChange}
												Disabled={ReadOnly}
											/>
										</GridItem>
										<GridItem>
											<GenericTextInput
												Name="Address"
												LabelText="Address"
												LabelMd={5}
												Value={model == null || undefined ? "" : model.Applicant == null ? "" : model.Applicant.Address1 == null ? "" : model.Applicant.Address1}
												ValueChanged={this.HandleChange}
												Disabled={ReadOnly}
											/>
										</GridItem>
									</GridItem>
									<GridItem xs={6}>
										<GridItem>
											<GenericTextInput
												Name="ApplicationNumber"
												LabelText="Application Number"
												LabelMd={5}
												Value={model == null || undefined ? "" : model.ApplicationNumber}
												ValueChanged={this.HandleChange}
												Disabled={ReadOnly}
											/>
										</GridItem>
										<GridItem>
											<GenericDateInput
												Name="TransferDate"
												LabelText="Transfer Date"
												LabelMd={5}
												Value={model == null || undefined ? "" : model.TransferDate}
												ValueChanged={this.HandleChange}
												Disabled={ReadOnly}
											/>
										</GridItem>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={6} style={{ marginTop: 30 }}>
						<Card style={{ height: "99%" }}>
							<CardHeader color="warning" icon>
								<CardIcon color="warning">
									<List />
								</CardIcon>
								<h4 className={classes.cardIconTitle} style={{ color: "black" }}>
									<b>Loan Details</b>
								</h4>
							</CardHeader>
							<CardBody style={{ paddingTop: 36 }}>
								<GridContainer>
									<GridItem xs={10}>
										<GenericNumberInput
											Name="RequestedAmount"
											LabelText="Requested Amount"
											LabelMd={5}
											MaxLength={10}
											Prefix="$"
											Disabled={ReadOnly}
											Value={model == null || undefined ? "" : model.RequestedAmount}
											ValueChanged={this.HandleChange}
										/>
									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={10}>
										<GenericNumberInput
											Name="ApprovedAmount"
											LabelText="Approved Amount"
											LabelMd={5}
											MaxLength={10}
											Prefix="$"
											Value={model == null || undefined ? "" : model.ApprovedAmount}
											ValueChanged={this.HandleChange}
											Disabled={ReadOnly}
										/>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={6} style={{ marginTop: 30 }}>
						<Card style={{ height: "99%" }}>
							<CardHeader color="warning" icon>
								<CardIcon color="warning">
									<List />
								</CardIcon>
								<h4 className={classes.cardIconTitle} style={{ color: "black" }}>
									<b>Payments</b>
								</h4>
							</CardHeader>
							<CardBody style={{ paddingTop: 36 }}>
								<GridContainer>
									<GridItem xs={10}>
										<GenericNumberInput
											Name="PrincipalAmount"
											LabelText="Principal Amount"
											LabelMd={5}
											MaxLength={10}
											Prefix="$"
											Value={model == null || undefined ? "" : model.PrincipalAmount}
											ValueChanged={this.HandleChange}
											Disabled={ReadOnly}
										/>
									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={10}>
										<GenericNumberInput
											Name="TotalSetupAmount"
											LabelText="Total Setup Amount"
											LabelMd={5}
											MaxLength={10}
											Prefix="$"
											Value={model == null || undefined ? "" : model.TotalSetupAmount}
											ValueChanged={this.HandleChange}
											Disabled={ReadOnly}
										/>
									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={10}>
										<GenericSelectInput
											Name="PaymentOptionFeeId"
											LabelText={"Fee Payment Type"}
											LabelMd={5}
											Method="POST"
											Url="/coreapi/v1.0/Parameter/Search"
											Parameter={this.PayOptionParameterCode}
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember="ParameterDesc"
											Value={model == null || undefined ? "" : model.PaymentOptionFeeId}
											ValueChanged={this.HandleChange}
											Disabled={ReadOnly}
										/>
									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={10}>
										<GenericSelectInput
											Name="SavingsPlanId"
											LabelText={"Savings Plan"}
											LabelMd={5}
											Method="POST"
											Url="/coreapi/v1.0/Parameter/Search"
											Parameter={this.YesNoParameterCode}
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember="ParameterDesc"
											Value={model == null || undefined ? "" : model.SavingsPlanId}
											ValueChanged={this.HandleChange}
											Disabled={ReadOnly}
										/>
									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={10}>
										<GenericNumberInput
											Name="TotalPaymentAmount"
											LabelText="Total Payment Amount"
											LabelMd={5}
											MaxLength={10}
											Prefix="$"
											Value={this.state.TotalPaymentAmount == null || undefined ? "" : this.state.TotalPaymentAmount}
											ValueChanged={this.HandleChange}
											Disabled={ReadOnly}
										/>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={12} style={{ marginTop: 50 }}>
						<GenericGrid
							Data={this.state.LedgerCardList || []}
							Sorted={this.sortedInstallmentDate}
							Columns={this.ColumnsData}
							HideButton={true}
						/>
						<GridContainer justify="flex-end" style={{ marginTop: "28px" }}>
							<div>
								<GridButton
									Icon={PrintIcon}
									OnClick={this.HandleDocument} />
								<GridButton
									Icon={EmailIcon}
									OnClick={this.HandleSendMail} />
							</div>
						</GridContainer>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}
LedgerCard.propTypes = {
	tabList: PropTypes.array,
	classes: PropTypes.object.isRequired,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ApplicantSaved: PropTypes.func,
	Disabled: PropTypes.bool,
	IsPopUp: PropTypes.bool,
	model: PropTypes.object
};

export default withArtifex(LedgerCard, styles);