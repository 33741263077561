import { DialogContent } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import { DetailIcon, ETransferIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericCheckInput, GenericTextInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericNumberInput, GenericDateInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent.jsx";
import { CheckType, GridColumnType } from "views/Constants/Constant";
import InventoryCheckin from "./InventoryCheckin.jsx";
import OrderCard from "./OrderCard.jsx";
import Receive from "./Receive.jsx";
import Transfer from "./Transfer.jsx";
import DateHelper from "core/DateHelper";

class InventorySafeDetail extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			inventorylist: [],
			inventorycolumns: [],
			inventorydetailscolumns: [],
			safedetailscolumns: [],
			safeactivitycolumns: [],
			safelist: [],
			storelist: [],
			safedetailslist: [],
			safeactivitylist: [],
			model: {
				StoreSummaryId : null,
				SafeSummaryId : null, 
				UniqueClientId : null, 
				Last4Digit : null,
				ClearCardNumber : null,
				FromDate : null,
				ToDate : null,
				MaskedCardNumber : null
			},
			filterModel: {},
			isLoading: false,
			isInventorydetail: false,
			isSafedetail: false,
			isSafeactivity: false,
			IsBackOffice: false,
			openOrderDialog: false,
			openTransferDialog: false,
			openReceiveDialog: false,
			openCheckinDialog: false,
			isOpenDialog: false,
			detailDialog: false,
			checkedValues: [],
			checkmodel: {},
			transferitem: [],
			checkinitem: [],
			safedetailmodel: {},
			StartDate: null,
			EndDate: null,
			Id: 0,
			UniqueClientId: 0,
		};
		this.HandleCheck = this.HandleCheck.bind(this);

		this.maxLenght4 = 4;
		this.maxLength19 = { maxLength: 19 };
		this.maxLength26 = { maxLength: 26 };
	}


	componentDidMount() {
		this.props.setAppLeftTitle("Inventory Safe Detail");
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
		const { model } = { ...this.state }
		var { isBackOffice } = this.state;
		isBackOffice = ClientHelper.IsBackOffice();
		if (!isBackOffice) {
			model.UniqueClientId = ClientHelper.GetClientRowId();
		}

		this.setState({ model, isBackOffice });
		this.Columnlist();


	}

	Columnlist = () => {

		var inventorycolumns = [];
		var safeactivitycolumns = [];
		var safedetailscolumns = [];
		var inventorydetailscolumns = [];
		var tempsamecolumns = [];
		const IsBackOffice = ClientHelper.IsBackOffice();

		safedetailscolumns = [
			{
				Header: "Actions",
				accessor: "Actions",
				sortable: false,
				filterable: false,
				width: 100
			},
			{
				Header: "Store/Safe",
				accessor: "CustomerStoreSafeName"
			},
			{
				Header: "Order Date",
				accessor: "OrderDate",
				type: GridColumnType.Date

			},
			{
				Header: "Serial Number",
				accessor: "CardId"
			},
			{
				Header: "Masked Card Number",
				accessor: "MaskedCardNumber"
			},
			{
				Header: "Received Date",
				accessor: "ReceiveDate",
				type: GridColumnType.Date
			},

			{
				Header: "Package No",
				accessor: "PackageNo"
			},
			{
				Header: "Order Package No",
				accessor: "OrderPackageNo"
			},


			{
				Header: "Record Type",
				accessor: "RecordType"
			},
			{
				Header: "Number",
				accessor: "Id"
			},
			{
				Header: "Person Name",
				accessor: "ClientName"
			},


		];
		inventorydetailscolumns = [
			{
				Header: "Client",
				accessor: "Client",
				show: IsBackOffice,
				width: 80
			},
			{
				Header: "Receive Date",
				accessor: "ReceiveDate"
			}

		];
		inventorycolumns = [
			{
				Header: "Client",
				accessor: "ClientName",
				show: IsBackOffice,
				width: 80
			},
			{
				Header: "Customer Store/Safe",
				accessor: "CustomerStoreSafe"
			},
			{
				Header: "Receive Date",
				accessor: "ReceiveDate",
				type: GridColumnType.Date
			}

		];
		safeactivitycolumns = [
			{
				Header: "Client",
				accessor: "ClientName",
				show: IsBackOffice,
				width: 80
			},
			{
				Header: "Customer Store/Safe",
				accessor: "CustomerStoreSafe"
			},
			{
				Header: "Action Name",
				accessor: "ActionName"
			},

			{
				Header: "Receive Date",
				accessor: "ReceiveDate",
				type: GridColumnType.Date
			}

		];

		Proxy.POST(
			"/prepaidapi/v1.0/PlasticType/SearchPlasticTypeList",
			{ UniqueClientId: ClientHelper.GetClientRowId() },
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				} else {
					if (responseData.Item != null) {
						responseData.Item.map(d => {
							tempsamecolumns.push(
								{
									Header: d.PlasticName,
									accessor: d.PlasticName.trim()
								});
						});
						tempsamecolumns.push(
							{
								Header: "Total",
								accessor: "Total"
							});

						tempsamecolumns.map(d => {
							{
								inventorycolumns.push(
									{
										Header: d.Header,
										accessor: d.accessor.trim()
									}
								)
							}
							{

								safeactivitycolumns.push(
									{
										Header: d.Header,
										accessor: d.accessor.trim()
									}
								)
							}
						});
						this.setState({
							inventorycolumns: inventorycolumns,
							safeactivitycolumns: safeactivitycolumns,
							safedetailscolumns: safedetailscolumns,
							inventorydetailscolumns: inventorydetailscolumns,
							IsBackOffice: IsBackOffice
						});
					}
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "An error occurred while requesting data", error.message);
			}
		);

	}


	OpenCheckOutModal = (d) => {

		const checkmodel = {
			ReceiveDate: d.ReceiveDate,
			PlasticTypeName: d.InventoryOrder.Product.PlasticType.PlasticName,
			ProductName: d.InventoryOrder.Product.ProductName,
			CustomerStoreSafeName: d.InventorySafeDefinition.SafeName + " / " + d.InventorySafeDefinition.CustomerStore.StoreName,
			PackageNo: d.InventoryOrder.PackageNo,
			OrderDate: d.InventoryOrder.OrderReceiveDate,
			RecordType: d.InventoryOrder.ReceiveRecordType.ParameterDesc,
			Id: d.Id,
			CardId: d.CardId,
			MaskedCardNumber: d.MaskedCardNumber,
			CardTokenNumber: d.CardTokenNumber,
			ClientName: d.InventorySafeDefinition.UniqueClient.ClientName,
			CheckType: CheckType.CheckOut,
			CheckTypeId: CheckType.CheckOutId
		}
		this.setState({ checkmodel: checkmodel, openCheckinDialog: true, detailDialog: true });
	}

	SafeDetailActivityModal = (d) => {

		const checkmodel = {
			ReceiveDate: d.ReceiveDate,
			PlasticTypeName: d.InventoryOrder.Product.PlasticType.PlasticName,
			ProductName: d.InventoryOrder.Product.ProductName,
			CustomerStoreSafeName: d.InventorySafeDefinition.SafeName + " / " + d.InventorySafeDefinition.CustomerStore.StoreName,
			PackageNo: d.InventoryOrder.PackageNo,
			OrderDate: d.InventoryOrder.OrderReceiveDate,
			RecordType: d.InventoryOrder.ReceiveRecordType.ParameterDesc,
			Id: d.Id,
			CardId: d.CardId,
			MaskedCardNumber: d.MaskedCardNumber,
			CardTokenNumber: d.CardTokenNumber,
			ClientName: d.InventorySafeDefinition.UniqueClient.ClientName,
			CheckType: CheckType.CheckOut,
			CheckTypeId: CheckType.CheckOutId
		}
		this.setState({ checkmodel: checkmodel, openCheckinDialog: true, detailDialog: true });
	}

	HandleSearch = () => {
		this.Columnlist();
		this.setState({ isLoading: true, checkedValues: [] });
		const { showMessage } = this.props;
		const model = { ...this.state.model };
		Proxy.POST(
			"/prepaidapi/v1.0/InventoryOrder/SafeDetailList",
			{  
				CustomerStoreId: model.StoreSummaryId, 
				InventorySafeDefinitionId: model.SafeSummaryId, 
				UniqueClientId: model.UniqueClientId, 
				Last4Digit: model.Last4Digit,
				ClearCardNumber: model.ClearCardNumber,
				FromDate : model.FromDate,
				ToDate: model.ToDate,
				MaskedCardNumber: model.MaskedCardNumber
			},
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				this.setState({ safedetailslist: responseData.Item || [], isLoading: false, safeactivitylist: [] });
			},
			(error) => {
				this.setState({ isLoading: false });
				// showMessage("error", "An error occurred while requesting data", error.message);
			}
		);
	}

	StoreDetailRowSelected = (index) => {
		this.setState({ isLoading: true });
		const { storelist } = this.state;
		const { showMessage } = this.props;

		var tlist = storelist[index];
		tlist["StatusType"] = 1;
		Proxy.POST(
			"/prepaidapi/v1.0/InventoryOrder/StoreSafeList",
			tlist,
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				responseData.Item.forEach(e1 => {
					e1.ColumnData.forEach(e2 => {
						e1[e2.PlasticName.trim()] = e2.Count;
					});
				});
				this.setState({ safelist: responseData.Item || [], isLoading: false, safedetailslist: [], safeactivitylist: [] });
			},
			(error) => {
				this.setState({ isLoading: false });
				showMessage("error", "An error occurred while requesting data", error.message);
			}
		);

	}
	SafeDetailRowSelected = (index) => {
		const { safelist } = { ...this.state };
		this.SafeDetailListFnc(safelist[index]);
	}
	SafeDetailListFnc = (item) => {
		this.setState({ isLoading: true, checkedValues: [] });
		const { showMessage } = this.props;
		item["StatusType"] = 1;
		Proxy.POST(
			"/prepaidapi/v1.0/InventoryOrder/SafeDetailList",
			item,
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				this.setState({ safedetailslist: responseData.Item || [], isLoading: false, safeactivitylist: [] });
			},
			(error) => {
				this.setState({ isLoading: false });
				// showMessage("error", "An error occurred while requesting data", error.message);
			}
		);
	}

	// SafeActivityDetailRowSelected = (index) => {
	// 	const { safedetailslist } = { ...this.state };
	// 	this.SafeActivityDetailListFnc(safedetailslist[index]);
	// }

	SafeActivityDetailListFnc = (item) => {
		this.setState({ isLoading: true });
		const { showMessage } = this.props;
		item["StatusType"] = 2;
		item["InventorySafeDefinitionId"] = item.InventorySafeDefinition.Id;
		Proxy.POST(
			"/prepaidapi/v1.0/InventoryOrder/StoreSafeList",
			item,
			(responseData) => {
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				responseData.Item.forEach(e1 => {
					e1.ColumnData.forEach(e2 => {
						e1[e2.PlasticName.trim()] = e2.Count;
					});
				});
				this.setState({ safeactivitylist: responseData.Item || [], isLoading: false });
			},
			(error) => {
				this.setState({ isLoading: false });
				// showMessage("error", "An error occurred while requesting data", error.message);
			}
		);
	}

	ValueChanged = (name, value, data) => {
		const model = { ...this.state.model };
		model[name] = value;
		if (name === "Name") model["UniqueClientId"] = value;
		if (name === "UniqueClientId") model["Name"] = value;
		if(name == "StoreSummaryId"){
			model["ReceiveDate"]=data.ReceiveDate;
			model["UniqueClientId"]=data.UniqueClientId;
		} 

		this.setState({ model });
	}
	HandleClear = () => {
		const { model } = this.state;
		model.EndDate = null;
		model.StartDate = null;

		this.setState({ filterModel: {}, selected: null, inventorylist: [], safelist: [], storelist: [], safedetailslist: [], safeactivitylist: [], model: model });
	}
	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: "" })} OnConfirm={onConfirm} />
		});
	}
	OrderEvent = () => {
		this.StoreSafeClear();
		const { model } = { ...this.state }
		model["IsNoNameProduct"] = true;
		this.setState({ openOrderDialog: true, detailDialog: true });
	}
	StoreSafeClear = () => {
		const { model } = { ...this.state }
		model["FromInventorySafeDefinitionId"] = null;
		this.setState({ model: model });
	}
	ReceiveEvent = () => {
		this.StoreSafeClear();
		this.setState({ openReceiveDialog: true, detailDialog: true });

	}
	TransferEvent = (item) => {

		if (item.length > 0) {
			const { model } = { ...this.state }

			model["FromInventorySafeDefinitionId"] = item[0].InventorySafeDefinitionId;
			this.setState({ openTransferDialog: true, detailDialog: true, transferitem: item, model: model });
		} else {
			const { showMessage } = this.props;
			showMessage("error", "Error", "Please Select Item from Safe Detail List!");
		}

	}
	CheckinEvent = (d) => {
		this.StoreSafeClear();

		if (d.length > 0) {
			const checkmodel = {
				ReceiveDate: d[0].ReceiveDate,
				PlasticTypeName: d[0].PlasticType.PlasticName,
				CustomerStoreSafeName: d[0].InventorySafeDefinition.SafeName + " / " + d[0].InventorySafeDefinition.CustomerStore.StoreName,
				PackageNo: d[0].InventoryOrder.PackageNo,
				OrderDate: d[0].InventoryOrder.OrderReceiveDate,
				RecordType: d[0].InventoryOrder.ReceiveRecordType.ParameterDesc,
				Id: d[0].Id,
				ClientName: d[0].InventorySafeDefinition.UniqueClient.ClientName,
				CheckType: CheckType.CheckIn,
				CheckTypeId: CheckType.CheckInId

			}
			this.setState({ openCheckinDialog: true, detailDialog: true, checkmodel: checkmodel });
		}
		else {
			const { showMessage } = this.props;
			showMessage("error", "Error", "Please Safe Detail List Select Item !");
		}
	}
	handleClose = () => {

		this.setState({ detailDialog: false, openCheckinDialog: false, openTransferDialog: false, openReceiveDialog: false, openOrderDialog: false });
	}
	HandleCheck = (e, x) => {

		this.setState(state => ({
			checkedValues: state.checkedValues.includes(x)
				? state.checkedValues.filter(c => c !== x)
				: [...state.checkedValues, x]
		}));
	}

	render() {
		const { alert, model,  safeactivitylist, safelist, storelist, selected: selected,  safedetailslist, safeactivitycolumns, inventorycolumns,  safedetailscolumns, checkedValues, isLoading,  IsBackOffice } = this.state;

		return (
			<div>


				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar ButtonList={[
					{
						Code: "Search",
						OnClick: this.HandleSearch
					},

					{
						Code: "Clear",
						OnClick: this.HandleClear,

					},

				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card >
							<CardBody>
								<GenericExpansionPanel >
									<GridContainer>
										<GridItem xs={3}>
												<GenericSelectInput
													Name="UniqueClientId"
													LabelText="Client"
													Method="POST"
													Url="/bankapi/v1.0/BankCustomer/Search"
													Parameter={{ UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined }}
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
													Value={model.UniqueClientId || ""}
													ValueChanged={this.ValueChanged}
													CanClear
													All
													Disabled={ClientHelper.IsClient()}
													DefaultIndex={ClientHelper.IsClient() ? 0 : -1}
												/>
										</GridItem>
										<GridItem xs={3}  >
											<GenericSelectInput
												key={model.UniqueClientId}
												Name="StoreSummaryId"
												LabelText="Store"
												Method="POST"
												Url= { ClientHelper.IsClient() ? ( model.UniqueClientId > 0 && model.StoreSummaryId == undefined ? "/prepaidapi/v1.0/InventoryOrder/StoreSafeList" : "" ) : (model.StoreSummaryId == undefined ? "/prepaidapi/v1.0/InventoryOrder/StoreSafeList" : "")}
												Parameter={ { UniqueClientId: model.UniqueClientId } }
												DataRoot="Item"
												KeyValueMember="CustomerStoreId"
												RenderItem={d => `${d.ClientName} - ${d.CustomerStoreSafe}`}
												Value={model.StoreSummaryId || ""}
												ValueChanged={this.ValueChanged}
												CanClear
											/>
										</GridItem>
										<GridItem xs={3}  >
											<GenericSelectInput
												key={model.StoreSummaryId}
												Name="SafeSummaryId"
												LabelText="Safe"
												Method="POST"
												Url= { model.StoreSummaryId > 0 ? "/prepaidapi/v1.0/InventoryOrder/StoreSafeList" : "" }
												Parameter={ { UniqueClientId: model.UniqueClientId, StatusType: 1, CustomerStoreId: model.StoreSummaryId, ReceiveDate: model.ReceiveDate } }
												DataRoot="Item"
												KeyValueMember="InventorySafeDefinitionId"
												RenderItem={d => `${d.ClientName} - ${d.CustomerStoreSafe}`}
												Value={model.SafeSummaryId || ""}
												ValueChanged={this.ValueChanged}
												CanClear
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericNumberInput
												NoFormatting
												Name="Last4Digit"
												LabelText="Last 4 Digit"
												MaxLength={4}
												Value={model.Last4Digit}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={3}>
											<GenericDateInput
												Name="FromDate"
												LabelText="From Date"
												Value={model.FromDate ? DateHelper.ToDateInputValue(model.FromDate) : ""}
												ValueChanged={this.ValueChanged}
												MaxDate={model.FromDate || DateHelper.GetDateTimeLocal()}
												Utc/>
										</GridItem>	
										<GridItem xs={3}>
											<GenericDateInput
												Name="ToDate"
												LabelText="To Date"
												Value={model.ToDate ? DateHelper.ToDateInputValue(model.ToDate) : ""}
												ValueChanged={this.ValueChanged}
												MinDate={model.FromDate}
												MaxDate={DateHelper.GetDateLocal()}
												Utc/>
										</GridItem>
										<GridItem xs={3}>
											<GenericTextInput
												Name="ClearCardNumber"
												LabelText="Clear Card Number"
												Format={"@@@@@@@@@@@@@@@@"}
												inputProps={this.maxLength19}
												Value={model.ClearCardNumber}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={3}>
											<GenericTextInput
												Name="MaskedCardNumber"
												LabelMd={4}
												Format={"@@@@@@******@@@@"}
												LabelText="Masked Card Number"
												inputProps={this.maxLength26}
												IsText={true}
												Value={model.MaskedCardNumber}
												ValueChanged={this.ValueChanged}
											/>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius" >
							<GenericExpansionPanel Title={"Safe Detail"} >
								<CardBody>
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer spacing={16}>
												<GridItem xs={12}>
													<GenericGrid
														Data={safedetailslist.map(d => {
															return {
																Actions: (
																	<div>
																		<GenericCheckInput
																			IsWithButton
																			Grid
																			Value={checkedValues.filter(c => c.Id == d.Id).length > 0}
																			ValueChanged={(e) => {
																				this.HandleCheck(e, d);
																			}} />

																		<GridButton
																			tooltip="Check-Out"
																			Icon={ETransferIcon}
																			OnClick={() => { this.OpenCheckOutModal(d); }} />

																		<GridButton
																			tooltip="Safe Detail"
																			Icon={DetailIcon}
																			OnClick={() => { this.SafeActivityDetailListFnc(d); }} />
																	</div>
																),

																ReceiveDate: d.ReceiveDate,
																PlasticTypeName: d.PlasticType.PlasticName,
																ProductName: d.Product.ProductName,
																CustomerStoreSafeName: d.InventorySafeDefinition.SafeName + " / " + d.InventorySafeDefinition.CustomerStore.StoreName,
																PackageNo: d.InventoryOrder.PackageNo,
																OrderPackageNo: d.InventoryOrder.OrderPackageNo,
																OrderDate: d.InventoryOrder.OrderReceiveDate,
																RecordType: d.InventoryOrder.ReceiveRecordType == null ? "" : d.InventoryOrder.ReceiveRecordType.ParameterDesc,
																Id: d.Id,
																ClientName: d.InventorySafeDefinition.UniqueClient.ClientName,
																StatusType: 2,
																CardId: d.CardId,
																MaskedCardNumber: d.MaskedCardNumber,
																CardTokenNumber: d.CardTokenNumber,
																InventorySafeDefinitionId: d.InventorySafeDefinition.Id
															};
														})}
														Columns={safedetailscolumns}
														SelectedIndex={selected}
														ShowPagination={true}
														PageSize={100}
													// RowSelected={this.SafeActivityDetailRowSelected}
													/>
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</CardBody>
							</GenericExpansionPanel>
						</Card>
					</GridItem>
				</GridContainer>
				<GenericDialog open={this.state.detailDialog} maxWidth="md" onBackdropClick={this.handleClose}>
					<DialogContent>

						{this.state.openOrderDialog == true ?
							<h5 style={{ color: "black", textAlign: "center" }}><b>Order Card</b></h5>
							: null}
						{this.state.openReceiveDialog == true ?
							<h5 style={{ color: "black", textAlign: "center" }}><b>Receive</b></h5>
							: null}
						{this.state.openTransferDialog == true ?
							<h5 style={{ color: "black", textAlign: "center" }}><b>Transfer</b></h5>
							: null}
						{this.state.openCheckinDialog == true ?
							<h5 style={{ color: "black", textAlign: "center" }}><b>{this.state.checkmodel.CheckType}</b></h5>
							: null}
						<br />
						{this.state.openOrderDialog == true ?
							<OrderCard
								ordermodel={model}
								showMessage={this.props.showMessage}
								showMessageNode={this.props.showMessageNode}
								showConfirmMessage={this.props.showConfirmMessage}
								clearlist={this.HandleClear}
							>
							</OrderCard>
							: null}
						{this.state.openReceiveDialog == true ?
							<Receive
								showMessage={this.props.showMessage}
								showMessageNode={this.props.showMessageNode}
								clearlist={this.HandleClear}>
							</Receive>
							: null}
						{this.state.openTransferDialog == true ?
							<Transfer
								transferitem={this.state.transferitem}
								showMessage={this.props.showMessage}
								showMessageNode={this.props.showMessageNode}
								transfermodel={model}
								clearlist={this.HandleClear}
							>
							</Transfer>
							: null}
						{this.state.openCheckinDialog == true ?
							<InventoryCheckin
								checkmodel={this.state.checkmodel}
								showMessage={this.props.showMessage}
								showMessageNode={this.props.showMessageNode}
								clearlist={this.HandleClear}>
							</InventoryCheckin> : null}
						<br />
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.handleClose}>Close</Button>
					</GenericDialogActions>
				</GenericDialog>
			</div >
		);
	}
}

InventorySafeDetail.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(InventorySafeDetail, {});