import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import HtmlEditorHelper from "core/HtmlEditorHelper";
import { EditIcon } from "core/Icons";
import { EditorState } from 'draft-js';
import PropTypes from "prop-types";
import React from "react";
import 'react-autocomplete-input/dist/bundle.css';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericGrid, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import GridButton from "views/Components/GridButton.jsx";
import LoadingComponent from "views/Components/LoadingComponent";
import ClientHelper from "core/ClientHelper";

class CampaignNotificationProfile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			vModel: {},
			model: {},
			ProfileList: [],
			PropertyList: [],
			alert: null,
			isLoading: false,
			editorModelEmail: EditorState.createEmpty(),
			editorModelSms: EditorState.createEmpty(),
			File: {},
			isClient: ClientHelper.IsClient(),
		};
		this.fileInputRef = React.createRef();
		this.ColumnsData = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip="Profile"
							Icon={EditIcon}
							Disabled={props.Disabled}
							OnClick={() => this.GetProfileById(row.original.Id)} />
					</div>
				),
				sortable: false,
				filterable: false,
				width: 100
			},
			{
				Header: "Program",
				accessor: "Program.Description"
			},
			{
				Header: "Profile Name",
				accessor: "ProfileName"
			},
			{
				Header: "Campaign Type",
				accessor: "ExecutionType.ParameterDesc"
			}
		];
	}
	componentDidMount() {
		if (this.props.setAppCenterTitle) {
			this.props.setAppCenterTitle("Campaign Manager");
		}

		if (this.props.setAppLeftTitle)
			this.props.setAppLeftTitle("Campaign Notification");


		var emptyState = HtmlEditorHelper.EmptyEditorContent();
		this.setState({ editorModelSms: emptyState, editorModelEmail: emptyState });
	}
	OperationCheck = () => {
		var ErrorList = [];
		const data = this.state.model;
		const vModel = this.state.vModel;

		if (data.ProgramId == null || data.ProgramId == "") {
			vModel.ProgramId = true;
			ErrorList.push("Program must be defined!");
		} else { vModel.ProgramId = false; }
		if (data.ProfileName == null || data.ProfileName == "") {
			vModel.ProfileName = true;
			ErrorList.push("Profile Name must be defined!");
		} else { vModel.ProfileName = false; }
		if (data.ExecutionTypeId == null || data.ExecutionTypeId == "") {
			vModel.ExecutionTypeId = true;
			ErrorList.push("Execution Type must be defined!");
		} else { vModel.ExecutionTypeId = false; }


		var email = this.state.editorModelEmail;
		var MailContent = HtmlEditorHelper.EditorContentToHtml(email);
		if (MailContent == null || MailContent == "") {
			ErrorList.push("Mail Content must be defined!");
		}
		if (data.MailSubject == null || data.MailSubject == "") {
			vModel.MailSubject = true;
			ErrorList.push("Mail Subject must be defined!");
		} else { vModel.MailSubject = false; }
		this.setState({ vModel })

		var sms = this.state.editorModelSms;
		var SmsContent = HtmlEditorHelper.EditorContentToText(sms);
		if (SmsContent == null || SmsContent == "") {
			ErrorList.push("SMS Content must be defined!");
		}

		if (ErrorList.length > 0) {
			this.props.showMessageNode("error", "Please Fill Required Fields", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.UpdateModel();
	}
	handleChange = (name, newValue, data) => {
		this.setState(function (state, props) {
			var temp = state.model || {};
			temp[name] = newValue;

			if (name == "ExecutionTypeId") {
				this.GetAllDataModelProperty(newValue);
			}
			return { model: temp };
		});
	}
	UpdateModel = () => {
		var temp = { ...this.state.model };
		const Id = temp.Id;
		var email = this.state.editorModelEmail;
		temp.MailContent = HtmlEditorHelper.EditorContentToHtml(email);

		var sms = this.state.editorModelSms;
		temp.SmsContent = HtmlEditorHelper.EditorContentToText(sms);

		this.setState({ isLoading: true });
		Proxy.POST(
			"/campaignapi/v1.0/CampaignNotificationProfile/Update",
			temp,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ isLoading: false });
				this.props.showMessage("success", "Success", Id > 0 ? "Campaign Notification Profile Updated ! " : "Campaign Notification Profile Saved ! ");
				this.ClearData();
				this.SearchNotificationProfiles();
				this.ResponseParser(responseData.Item);
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}
	SearchNotificationProfiles = () => {

		this.setState({ isLoading: true });
		Proxy.POST(
			"/campaignapi/v1.0/CampaignNotificationProfile/Search",
			this.state.model,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ ProfileList: responseData.Item || [] });
				this.setState({ isLoading: false });
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}
	GetProfileById = (ProfileId) => {
		if (ProfileId == null || ProfileId == 0) {
			this.props.showMessage("error", "Error", "Profile Id required for fetching profile !");
			return;
		}
		this.setState({ isLoading: true });
		Proxy.POST(
			"/campaignapi/v1.0/CampaignNotificationProfile/GetById",
			{ Id: ProfileId },
			responseData => {
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ isLoading: false });
				this.ResponseParser(responseData.Item);
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}
	ResponseParser = (temp) => {
		this.setState({ model: temp });
		if (temp != null && temp.MailContent != null && temp.MailContent != "") {
			var email = HtmlEditorHelper.HtmlToEditorContent(temp.MailContent);
			this.setState({ editorModelEmail: email });
		} else {
			this.setState({ editorModelEmail: HtmlEditorHelper.EmptyEditorContent() });
		}
		if (temp != null && temp.SmsContent != null && temp.SmsContent != "") {
			var sms = HtmlEditorHelper.HtmlToEditorContent(temp.SmsContent);
			this.setState({ editorModelSms: sms });
		} else {
			this.setState({ editorModelSms: HtmlEditorHelper.EmptyEditorContent() });
		}

		if (temp != null && temp.ExecutionTypeId != null) {
			this.GetAllDataModelProperty(temp.ExecutionTypeId);
		}
	}
	GetAllDataModelProperty = (ExecutionTypeId) => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/campaignapi/v1.0/CampaignDataModelProperty/GetAllById",
			{ Id: ExecutionTypeId },
			responseData => {
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				if (responseData.Item != null) {
					var temp = responseData.Item.map(x => {
						return { text: x.PropertyDescription, value: x.PropertyDescription };
					});
					this.setState({ PropertyList: temp });
				} else {
					this.setState({ PropertyList: [] });
				}
				this.setState({ isLoading: false });
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}
	DeleteModel = () => {
		if (this.state.model == null || this.state.model.Id == null || this.state.model.Id == 0) {
			this.props.showMessage("error", "Error", "Campaign Notifiction Profile must be selected for delete operation !");
			return;
		}
		this.setState({ isLoading: true });
		Proxy.GET(
			"/campaignapi/v1.0/CampaignNotificationProfile/Delete/Id?Id=" + this.state.model.Id,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.props.showMessage("success", "Success", "Campaign Notifiction Profile Deleted !");
				this.ClearData();
				this.SearchNotificationProfiles();
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}
	SelectedRowChange = (index) => {
		const temp = this.state.ProfileList[index];
		this.GetProfileById(temp.Id);
	}
	ClearData = () => {
		this.setState({
			model: {},
			editorModelEmail: HtmlEditorHelper.EmptyEditorContent(),
			editorModelSms: HtmlEditorHelper.EmptyEditorContent(),
			File: {},
			alert: null,
			isLoading: false
		});
	}
	EditorStateChangeEmail = (obj) => {
		this.setState({ editorModelEmail: obj });
		HtmlEditorHelper.EditorContentToText(this.state.editorModelEmail);
	}
	EditorStateChangeSms = (obj) => {
		this.setState({ editorModelSms: obj });
		HtmlEditorHelper.EditorContentToText(this.state.editorModelSms);
	}

	FileSelect = (e) => {
		e.preventDefault();

		var file = e.target.files[0];
		if (!file) {
			return;
		}
		this.setState({ File: file });

		var EsTemp = this.EditorStateChangeEmail;
		var model = this.state.editorModelEmail;
		var ToHtml = HtmlEditorHelper.EditorContentToHtml;
		var ToEditor = HtmlEditorHelper.HtmlToEditorContent;
		var reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			var base64 = reader.result;
			var html = ToHtml(model);
			var img = '<img src="' + base64 + '" alt="undefined" style="float:none;height: auto;width: auto"/>';

			html += img;
			html += "<p></p><p></p><p></p>";

			const editorState = ToEditor(html);
			EsTemp(editorState);
		};
		reader.onerror = function (error) {
			console.log('Error: ', error);
		};
	}
	RenderItemUniqueClient = (d) => {
		return `${d.UniqueClientId} - ${d.Name}`;
	}


	render() {
		const { Disabled } = this.props;
		const { model, ProfileList, PropertyList, editorModelEmail, editorModelSms, vModel } = this.state;
		return (
			<div>
				{this.state.alert}

				<LoadingComponent Show={this.state.isLoading} />


				<ButtonToolbar ButtonList={[
					{ Code: "Submit", OnClick: this.OperationCheck, Disabled: Disabled || model.Id > 0 || !this.state.isClient },
					{ Code: "Update", OnClick: this.OperationCheck, Disabled: Disabled || !model.Id > 0 || !this.state.isClient },
					{ Code: "Search", OnClick: this.SearchNotificationProfiles, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.ClearData, Disabled: Disabled },
					{ Code: "Delete", OnClick: () => this.props.showConfirmMessage("warning", "Warning", "Delete this Campaign Notification Profile ?", this.DeleteModel), Disabled: Disabled || !model.Id > 0 || !this.state.isClient }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<Card className="no-radius">
					<CardHeader>
						<GenericTitle text="Notification Profile Information" />
					</CardHeader>
					<CardBody>
						<GridContainer spacing={16}>
							{!this.state.isClient && (
								<GridItem xs={3} >
									<GenericSelectInput
										Name="UniqueClientId"
										LabelText="Client"
										Method="POST"
										Url="/bankapi/v1.0/BankCustomer/Search"
										Parameter={{}}
										DataRoot="Item"
										KeyValueMember="Id"
										RenderItem={this.RenderItemUniqueClient}
										Value={model.UniqueClientId}
										ValueChanged={this.handleChange}
										CanClear
										All
										Disabled={this.state.isClient}
										DefaultIndex={this.state.isClient ? 0 : -1}
									/>
								</GridItem>
							)}
							<GridItem xs={3}>
								<GenericSelectInput
									Disabled={Disabled || model.Id > 0}
									Name="ProgramId"
									LabelText="Program"
									Value={model.ProgramId || ""}
									ValueChanged={this.handleChange}
									KeyValueMember="Id"
									TextValueMember="Description"
									Method="GET"
									Url="/bankapi/v1.0/Bank/ValidProgramsCampaign"
									DataRoot="Item"
									Required
									IsInvalid={vModel.ProgramId}
								/>
							</GridItem>
							<GridItem xs={3}>
								<GenericTextInput
									Disabled={Disabled}
									IsText={true}
									inputProps={{ maxLength: 30 }}
									Name="ProfileName"
									LabelText="Profile Name"
									Value={model.ProfileName || ""}
									ValueChanged={this.handleChange}
									Required
									IsInvalid={vModel.ProfileName}
								/>
							</GridItem>
							<GridItem xs={3}>
								<GenericSelectInput
									Disabled={Disabled || model.Id > 0}
									Name="ExecutionTypeId"
									LabelText="Execution Type"
									Value={model.ExecutionTypeId || ""}
									ValueChanged={this.handleChange}
									KeyValueMember="Id"
									TextValueMember="ParameterDesc"
									Method="POST"
									Url="/coreapi/v1.0/Parameter/Search"
									Parameter={{ ParameterCode: "CampaignExecutionType" }}
									DataRoot="Item"
									Required
									IsInvalid={vModel.ExecutionTypeId}
								/>
							</GridItem>
							<GridItem xs={3}>

							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>



				<GridContainer>
					<GridItem xs={6}>
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={"e-Mail"} />
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={6}>
										<GenericTextInput
											LabelMd={4}
											Disabled={Disabled}
											inputProps={{ maxLength: 100 }}
											Name="MailSubject"
											LabelText="e-Mail Subject"
											Value={model.MailSubject || ""}
											ValueChanged={this.handleChange}
											Required
											IsInvalid={vModel.MailSubject}
										/>
									</GridItem>

									<GridItem xs={6} style={{ float: "right", textAlign: "end" }}>
										<Button disabled={Disabled} size="sm" onClick={() => { this.setState({ editorModelEmail: HtmlEditorHelper.EmptyEditorContent() }) }} > Clear </Button>
										<Button disabled={Disabled} size="sm" onClick={() => this.fileInputRef.current.click()} >+ Image </Button>
										<input type="file" key={model.FileName} style={{ display: "none" }} onChange={this.FileSelect} ref={this.fileInputRef} accept="image/*" />
									</GridItem>
								</GridContainer>
								<br />
								<Editor
									editorState={editorModelEmail}
									toolbarClassName="toolbarClassName"
									wrapperClassName="wrapperClassName"
									editorClassName="editorClassName"
									onEditorStateChange={this.EditorStateChangeEmail}
									mention={{
										separator: ' ',
										trigger: '@',
										suggestions: PropertyList,
									}}
								/>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={6}>
						<Card className="no-radius">
							<CardHeader>
								<b><p style={{ paddingTop: "20px", marginLeft: "10px" }}> Sms/Text Message </p></b>
								<h4 style={{ marginTop: "10px", marginLeft: "3px" }}><b>SMS Content</b></h4><br />
							</CardHeader>
							<CardBody>
								<GridItem xs={6}>

								</GridItem>
								<div style={{ height: "300px" }}>
									<Editor
										editorState={editorModelSms}
										wrapperClassName="wrapper-class"
										editorClassName="editor-class"
										toolbarClassName="toolbar-class"
										onEditorStateChange={this.EditorStateChangeSms}
										toolbar={{
											options: ['emoji']
										}}
										mention={{
											separator: ' ',
											trigger: '@',
											suggestions: PropertyList,
										}}
									/>
								</div>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>


				<Card className="no-radius">
					<CardHeader>
						<GenericTitle text={"Notification Profile List"} />
					</CardHeader>

					<CardBody>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={ProfileList}
									PageSize={5}
									Columns={this.ColumnsData}
									RowSelected={index => {
										this.SelectedRowChange(index);
									}}
								/>
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>

			</div>
		);
	}
}

CampaignNotificationProfile.propTypes = {
	classes: PropTypes.object
};

export default withArtifex(CampaignNotificationProfile, {});