import { CardMedia, Icon } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import genericCardMediaStyle from "assets/jss/generic/genericCardMediaStyle.jsx";
import cx from "classnames";
import IconButton from "@material-ui/core/IconButton";
import ImageHelper from "core/ImageHelper";

class GenericCardMedia extends PureComponent {

	constructor(props) {
		super(props);

		this.state = {
			imageBase64: null
		};
		this.emptyObject = {};
		this.WhiteDot1x1 = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";
	}
	componentDidMount() {
		this.UpdateImages();
	}
	UpdateImages = () => {
		const { ImageFileId, Image, ImageFileIdPlaceholder, ImagePlaceholder, OnLoaded, Name } = this.props;

		var imageBase64 = null;
		var imageBase64Placeholder = null;
		if (ImageFileId && typeof ImageFileId === "number" && ImageFileId > 0) {
			this.downloadImageFromFileId(ImageFileId)
				.then(item => {
					imageBase64 = ImageHelper.GenerateImageFromRawBase64(item);
					this.setState({ imageBase64 }, () => {
						OnLoaded && imageBase64 && OnLoaded(Name, imageBase64);
					});
				});
		} 
		if (Image && typeof Image === "string") {
			imageBase64 = ImageHelper.GenerateImageFromRawBase64(Image);
		}


		if (ImageFileIdPlaceholder && typeof ImageFileIdPlaceholder === "number" && ImageFileIdPlaceholder > 0) {
			this.downloadImageFromFileId(ImageFileIdPlaceholder)
				.then(item => {
					imageBase64Placeholder = ImageHelper.GenerateImageFromRawBase64(item);
					this.setState({ imageBase64Placeholder });
				});
		} else if (ImagePlaceholder && typeof ImagePlaceholder === "string") {
			imageBase64Placeholder = ImageHelper.GenerateImageFromRawBase64(ImagePlaceholder);
		}

		this.setState({ imageBase64, imageBase64Placeholder });
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.ImageFileId != this.props.ImageFileId ||
			prevProps.ImageFileIdPlaceholder != this.props.ImageFileIdPlaceholder ||
			prevProps.Image != this.props.Image ||
			prevProps.ImagePlaceholder != this.props.ImagePlaceholder
		) {
			this.UpdateImages();
		}
	}
	downloadImageFromFileId = async (id) => {
		const { ExecuteApiPost, TaskPoolDomain, Width, Heigth, Stretch, OnFetch } = this.props;
		OnFetch && OnFetch(id);
		var request = ExecuteApiPost("/coreapi/v1.0/Image/ShowBase64", {
			FileId: id,
			Width: Width,
			Heigth: Heigth,
			Stretch: Stretch
		});
		if (TaskPoolDomain) {
			TaskPoolDomain.AppendTask(request);
		}
		var result = await request;
		return Promise.resolve(result);
	}
	getImageFromFile = (file) => {
		var image = null;
		if (file && file.FileDataBase64) {
			image = ImageHelper.GenerateImageFromRawBase64(file.FileDataBase64);
		}
		return image;
	}
	
	handleOnClick = (e) => {
		const { OnClick, Name } = this.props;
		OnClick && OnClick(Name, e);
	}

	render() {
		const { className, style, CardMediaProps, classes, OnClickRotate } = this.props;
		const { imageBase64, imageBase64Placeholder } = this.state;
		var showingBase64Data = imageBase64 || imageBase64Placeholder || this.WhiteDot1x1;

		var containerStyle = cx({
			[classes.container]: true,
			[className]: true
		});

		return (
			<CardMedia
				onClick={this.handleOnClick}
				image={showingBase64Data}
				title=""
				className={containerStyle}
				style={style}
				{...CardMediaProps}
			>
				{OnClickRotate &&
					<IconButton className={classes.rotateButton} onClick={OnClickRotate}>
						<Icon>rotate_right</Icon>
					</IconButton>
				}
			</CardMedia>

		);
	}
}

GenericCardMedia.propTypes = {
	Name: PropTypes.string,
	CardMediaProps: PropTypes.any,
	ExecuteApiPost: PropTypes.any,
	Heigth: PropTypes.number,
	Image: PropTypes.string,
	ImageFileId: PropTypes.number,
	ImageFileIdPlaceholder: PropTypes.number,
	ImagePlaceholder: PropTypes.string,
	OnFetch: PropTypes.any,
	OnLoaded: PropTypes.func,
	Stretch: PropTypes.bool,
	TaskPoolDomain: PropTypes.object,
	Width: PropTypes.number,
	className: PropTypes.any,
	style: PropTypes.any,
	OnClickRotate: PropTypes.func
};
export default withStyles(genericCardMediaStyle)(GenericCardMedia)