
import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import AlertHelper from "core/AlertHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import { DetailIcon } from "core/Icons";
import PropTypes from "prop-types";
import React, { Component } from 'react';
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDateInput, GenericDialog, GenericGrid, GenericLabel, GenericNumberInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import GridButton from "views/Components/GridButton";
import LoadingComponent from "views/Components/LoadingComponent";
import { Direction, GridColumnType } from "views/Constants/Constant.js";
class Central1OutgoingResponseReport extends Component {
	constructor(props) {
		super(props)

		this.state = {
			model: {
				FromDate: DateHelper.GetDate(),
				ToDate: DateHelper.GetDate(),
			},
			getTransactionByFileIdModel: {
			},
			dailyFileList: [],
			fileInfoModel: {},
			headerModel: {},
			transactionList: [],
			isLoading: false,
		}

		this.ColumnsdailyFileList = [
			{
				Header: "File Name",
				accessor: "FileName"
			},
			{
				Header: "File Date",
				accessor: "FileDate",
				type: GridColumnType.DateUtc
			},
			{
				Header: "Send Status",
				accessor: "SendStatus"
			},
			{
				Header: "File Creation Number",
				accessor: "FileCreationNumber"
			},
			{
				Header: "Record Count",
				accessor: "RecordCount"
			},
			{
				Header: "Reject Count",
				accessor: "RejectedCount"
			}
		]


		this.ColumnstransactionList = [
			{
				Header: "Client Name",
				accessor: "ClientName"
			},
			{
				Header: "Transaction Code",
				accessor: "TransactionCode"
			},
			{
				Header: "Record Type",
				accessor: "RecordType",
				Cell: row => (
					<div>{row.value == "1" ? "Debit detail record" : row.value == "2" ? "Credit detail record" : ""}</div>
				)
			},
			{
				Header: "Item Trace Number",
				accessor: "ItemTraceNumber"
			},
			{
				Header: "File Creation Number",
				accessor: "FileCreationNumber"
			},
			{
				Header: "Payment Type",
				accessor: "PaymentType",
				Cell: row => (
					<div>{row.value == "I" ? "Return of Credit" : row.value == "J" ? "Return of Debit" : row.value == "C" ? "Credit" : row.value == "D" ? "Debit" : "-"}</div>//c ve debit ekle 
				)
			},
			{
				Header: "Payee Drawee Name",
				accessor: "PayeeDraweeName"

			},
			{
				Header: "Release Date",
				accessor: "ReleaseDate",
				type: GridColumnType.DateUtc
			},
			{
				Header: "Cross Reference Number",
				accessor: "CrossReferenceNumber"
			},
			{
				Header: "Original Due Date",
				accessor: "OriginalDueDate",
				type: GridColumnType.DateUtc
			},
			{
				Header: "Financial Institution Number",
				accessor: "FinancialInstitutionNumber"
			},
			{
				Header: "Branch Number",
				accessor: "BranchNumber"
			},
			{
				Header: "Wallet Number",
				accessor: "AccountNumber"
			},
			{
				Header: "Payment Amount",
				accessor: "PaymentAmount",
				type: GridColumnType.Money
			},
			{
				Header: "Transaction Type",
				accessor: "TransactionType"
			},
			{
				Header: "Payment Status",
				accessor: "PaymentStatus",
				Cell: row => (
					<div>{row.value == "A" ? "Valid Payment" : row.value == "R" ? (row.original.PaymentType == "C" || row.original.PaymentType == "D") ? "Rejected Payment/Record" : "Returned Payment/Record" : ""}</div>
				)
			},
			{
				Header: "Match Status",
				accessor: "MatchStatus"
			},
		]
	}


	componentDidMount() {
		const { setAppLeftTitle, setAppCenterTitle } = this.props
		if (setAppLeftTitle) {
			setAppLeftTitle("Central 1 Outgoing Response Report")
		}
		if (setAppCenterTitle) {
			setAppCenterTitle("CLEARING & SETTLEMENT")
		}
	}

	handleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}

	GetDailyFileList = async () => {
		this.setState({ isLoading: true });
		const { ExecuteApiPost } = this.props;
		var model = { ...this.state.model };
		var responseData = await ExecuteApiPost("/bankapi/v1.0/BankBmoEFT/GetEFTCentral1OutgoingResponseReportFileList", model, null, null, null, null, null);
		if (responseData != null) {
			this.setState({
				transactionList: [], headerModel: {},
				dailyFileList:  responseData.length < 1 ? [] : responseData, isLoading: false
			});
		}
	}
	GetTransactionList = async (Id) => {
		const { ExecuteApiPost } = this.props;
		var headerModel = { ...this.state.headerModel };
		var transactionList = { ...this.state.transactionList };
		var getTransactionByFileIdModel = { ...this.state.getTransactionByFileIdModel };
		getTransactionByFileIdModel = { ...this.state.model };
		getTransactionByFileIdModel.FileId = Id;
		getTransactionByFileIdModel.UniqueClientId = this.state.model.UniqueClientId == null ? null : this.state.model.UniqueClientId;
		var responseData = await ExecuteApiPost("/bankapi/v1.0/BankBmoEFT/GetEFTCentralClearingFileDetailsReportByFileId", getTransactionByFileIdModel, null, null, null, null, null);
		if (responseData != null) {
			if (responseData.Central1ClearingReportFileHeaderDetail != null) {
				headerModel = responseData.Central1ClearingReportFileHeaderDetail == null ? {} : responseData.Central1ClearingReportFileHeaderDetail;
				headerModel.FileDate = this.state.fileInfoModel.FileDate;
			}
			else
				headerModel = {};

			if (responseData.EftCentral1ClearingReportFileDetail != null || responseData.EftCentral1ClearingReportFileDetail.length > 1) {
				transactionList = responseData.EftCentral1ClearingReportFileDetail;
			}
			else {
				transactionList = [];
			}
		}
		this.setState({ transactionList, headerModel, isLoading: false });
	}
	SelectedRowChange = (index) => {
		const tempModel = this.state.dailyFileList[index];
		const model = { ...this.state.model };
		if (tempModel.Id == null || tempModel.Id == undefined)
			this.props.showMessage("warning", "File Information", "FileInformationId can not be null");
		else {
			model.UniqueClientId = tempModel.UniqueClientId;
			this.setState({ fileInfoModel: tempModel, model, isLoading: true, rowIndex: index });
			this.GetTransactionList(tempModel.Id);

		}
	}

	ClearModel = () => {
		this.setState({
			model: {
				FromDate: DateHelper.GetDateTime(), ToDate: DateHelper.GetDateTime()
			}, dailyFileList: [],
			fileInfoModel: {},
			headerModel: {},
			transactionList: [],
		});
	}

	render() {
		const { Disabled } = this.props;
		const { model, headerModel, isLoading } = this.state;
		return (
			<div>
				<LoadingComponent Show={isLoading} />
				{this.state.alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.GetDailyFileList, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.ClearModel, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer>
					<GridItem xs={3}>
						<Card style={{ height: "90%" }}>
							<CardHeader>
								<GenericTitle text={" File Filter"} />
							</CardHeader>
							<CardBody>
								<GenericDateInput
									Name="FromDate"
									LabelText="From Date"
									Value={model.FromDate}
									ValueChanged={this.handleChange}
									MaxDate={model.ToDate || DateHelper.GetDate()}
									IncludeTime={false}
									Utc />
								<GenericDateInput
									Name="ToDate"
									LabelText="To Date"
									Value={model.ToDate}
									ValueChanged={this.handleChange}
									MinDate={model.FromDate}
									MaxDate={DateHelper.GetDate()}
									IncludeTime={false}
									Utc />
							</CardBody>
						</Card>

						<br /> <br />
					</GridItem>
					<GridItem xs={9}>
						<Card style={{ height: "90%" }}>
							<CardHeader>
								<GenericTitle text={"File Information"} />
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={6}>
										<GenericDateInput
											Name="FileDate"
											LabelMd={6}
											LabelText="File Date"
											Value={headerModel == null ? "" : headerModel.FileDate ? Formatter.FormatDateUtc(headerModel.FileDate) : ""}
											Disabled={true}
											DateFormat="DD/MMM/YY"
											Utc />
										<GenericNumberInput
											Name="TotalCreditNumber"
											LabelMd={6}
											LabelText="Total Number of Credit"
											Value={headerModel == null ? "" : headerModel.TotalNumbeCredit ? headerModel.TotalNumbeCredit : ""}
											Disabled={true}
											DecimalScale={0} />
										<GenericNumberInput
											Name="TotalNumberDebit"
											LabelText="Total Number of Debit"
											LabelMd={6}
											Value={headerModel == null ? "" : headerModel.TotalNumberDebit ? headerModel.TotalNumberDebit : ""}
											Disabled={true}
											DecimalScale={0} />
									</GridItem>
									<GridItem xs={6}>
										<GenericDateInput
											Name="FileDate"
											LabelText="File Time"
											LabelMd={6}
											Value={headerModel == null ? "" : headerModel.FileDate ? Formatter.FormatTimeUtc(headerModel.FileDate) : ""}
											DateFormat="hh:mm:ss A"
											IncludeTime={false}
											Disabled={true}
											Utc />
										<GenericNumberInput
											Name="TotalAmountCredit"
											LabelText="Total Amount of Credit"
											LabelMd={6}
											Value={headerModel == null ? "" : headerModel.TotalAmountCredit ? headerModel.TotalAmountCredit : ""}
											Prefix="$"
											Disabled={true} />
										<GenericNumberInput
											Name="TotalNumberDebit"
											LabelText="Total Amount of Debit"
											LabelMd={6}
											Value={headerModel == null ? "" : headerModel.TotalAmountDebit ? headerModel.TotalAmountDebit : ""}
											Prefix="$"
											Disabled={true} />

									</GridItem>
								</GridContainer>
							</CardBody>
						</Card><br />
					</GridItem>
				</GridContainer>
				<Card className="no-radius">
					<CardBody>
						<CardHeader>
							<GenericTitle text={"Daily File List"} />
						</CardHeader>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={this.state.dailyFileList}
									Columns={this.ColumnsdailyFileList}
									RowSelected={index => {
										this.SelectedRowChange(index);
									}}
									SelectedIndex={this.state.rowIndex} />
								<br /> <br />
								<h5><b>Transaction List</b></h5> <br />
								<GenericGrid
									Data={this.state.transactionList}
									Columns={this.ColumnstransactionList}
								/>
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>
			</div >

		)
	}
}
Central1OutgoingResponseReport.propTypes = {
	classes: PropTypes.object,
	handleOperationType: PropTypes.func,
	showQuestionMessage: PropTypes.func,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};
export default withArtifex(Central1OutgoingResponseReport, {});
