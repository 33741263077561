import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import PropTypes from "prop-types";
import React from "react";
import { GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";


class City extends React.Component {
	constructor(props) {
		super(props);
		this.initialModel = {
			Id: 0,
			Name: null,
			CountryId: null,
			ProvinceId: null
		};

		this.emptyObject = {};

		this.state = {
			model: { ...this.initialModel },
			cityList: [],
			totalresult: {},
			checkedValues: [],
			selected: null,
			isLoading: false,
			alert: "",
			IsUpdate: false,
			vModel: {},
		};
		this.columns = [
			{
				Header: "Country Name",
				accessor: "Country.Name"
			},
			{
				Header: "Province Name",
				accessor: "Province.Name"
			},
			{
				Header: "City Name",
				accessor: "Name"
			}
		];
	}

	componentDidMount() {

	}
	ValueChanged = (name, value, data) => {
		const model = { ...this.state.model };
		model[name] = value;
		this.setState({ model });
	}

	HandleClear = () => {
		this.setState({ model: { ...this.initialModel }, isEdit: false, index: -1 });
	}

	HandleSearch = async () => {
		this.setState({ isLoading: true });
		const { ExecuteApiPost } = this.props;
		this.state.model.CountryId = this.props.CountryId;
		this.state.model.ProvinceId = this.props.ProvinceId;

		var result = await ExecuteApiPost("/bankapi/v1.0/WireCity/Search", this.state.model);
		this.setState({ isLoading: false });

		if (result != null) {
			this.setState({ cityList: result || [] });
		}
	}

	HandleSubmit = async () => {
		if (!this.Validate()) {
			return;
		}
		this.state.model.CountryId = this.props.CountryId;
		this.state.model.ProvinceId = this.props.ProvinceId;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/bankapi/v1.0/WireCity/Insert", this.state.model);

		if (result != null) {
			this.HandleSearch();
		}
	}

	HandleUpdate = async () => {
		if (!this.Validate()) {
			return;
		}
		const { ExecuteApiPost } = this.props;
		this.state.model.CountryId = this.props.CountryId;
		this.state.model.ProvinceId = this.props.ProvinceId;
		var result = await ExecuteApiPost("/bankapi/v1.0/WireCity/Update", this.state.model);

		if (result != null) {
			this.HandleSearch();
		}
	}

	HandleDelete = () => {
		const { ExecuteApiPost, showConfirmMessage } = this.props;
		showConfirmMessage("warning", "Warning", "Are you sure want to delete ?", () => ExecuteApiPost("/bankapi/v1.0/WireCity/Delete", { Id: this.state.model.Id }, null, null, null, [this.HandleSearch], true));
	}

	Validate() {
		const { model, vModel } = this.state;
		var messages = [];
		const { showValidationErrors, CountryId } = this.props;
		vModel.CountryId = !CountryId;
		if (vModel.CountryId) {
			messages.push("Country cannot be empty!");
		}
		vModel.Name = !model.Name;
		if (vModel.Name) {
			messages.push("Name cannot be empty!");
		}
		if (messages.length > 0) {
			showValidationErrors(messages);

			return false;
		}
		return true;
	}
	RowSelected = (index) => {
		var tempModel = this.state.cityList[index];
		this.setState({ isEdit: true, model: tempModel, index });
		this.props.OnModelChange("City", tempModel.Id);

	}
	render() {
		const { model, cityList, vModel } = this.state;
		const { Disabled, CountryId, ProvinceId } = this.props;

		return (
			<div>
				<LoadingComponent Show={this.state.isLoading} />

				{this.state.alert}
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<CardHeader>
									<GenericTitle text={"City List"} />
								</CardHeader>

								<GridContainer justify="flex-end">
									<GridItem justify="flex-end">
										<Button size="sm" onClick={() => this.HandleSubmit()} disabled={model.Id > 0}>
											SUBMIT
							         	</Button>
										<Button size="sm" onClick={() => this.HandleSearch()}>
											SEARCH
							         	</Button>
										<Button size="sm" onClick={() => this.HandleUpdate()} disabled={model.Id == 0}>
											UPDATE
							         	</Button>
										<Button size="sm" onClick={() => this.HandleDelete()} disabled={model.Id == 0} >
											DELETE
							         	</Button>
										<Button size="sm" onClick={() => this.HandleClear()}>
											CLEAR
							         	</Button>
									</GridItem>

								</GridContainer>
								<GridItem xs={12}>
									<GridContainer>

										<GridItem xs={4}>
											<GenericSelectInput
												Name="CountryId"
												LabelText="Country"
												Method="POST"
												Url="/bankapi/v1.0/WireCountry/Search"
												Parameter={this.emptyObject}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Name"
												Value={CountryId}
												ValueChanged={this.ValueChanged}
												Disabled={true}
												Required
												IsInvalid={vModel.CountryId}
											/>
										</GridItem>
										<GridItem xs={4}>
											<GenericSelectInput
												key={model.CountryId + "province" + ProvinceId}
												Name="ProvinceId"
												LabelText="Province"
												Method="POST"
												Url="/bankapi/v1.0/WireProvince/Search"
												Parameter={{ CountryId: model.CountryId, ProvinceId: ProvinceId }}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Name"
												Value={ProvinceId}
												ValueChanged={this.ValueChanged}
												Disabled={true}
											/>
										</GridItem>

										<GridItem xs={4}>
											<GenericTextInput
												Name="Name"
												Value={model.Name}
												LabelText="Name"
												ValueChanged={this.ValueChanged}
												Required
												IsInvalid={vModel.Name}
											/>
										</GridItem>

									</GridContainer>
								</GridItem>
								<br />
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Columns={this.columns}
													Data={cityList}
													ShowPagination={true}
													HideButton={true}
													PageSize={10}
													RowSelected={this.RowSelected}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

City.propTypes = {
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any,
	classes: PropTypes.object,
	ExecuteApiPost: PropTypes.func,
	OnModelChange: PropTypes.func,
	showConfirmMessage: PropTypes.func,
	showValidationErrors: PropTypes.func

};

export default withArtifex(City, {});