import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import React from "react";
import { GenericAlert } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";

class ApiModelTest extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			list: [],
			alert: null,
			isLoading: false,
			tempList: []
		};

	}
	FetchData = () => {
		this.setState({ list: [] });
		this.setState({ isLoading: true });
		Proxy.GET(
			"/coreapi/v1.0/ApiModelTest/Test",
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ isLoading: false });
				this.setState({ list: responseData.Item });
				this.setState({ tempList: responseData.Item });
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	FilterErrors = () => {
		if (this.state.list != null) {
			var temp = this.state.list.filter(x => x.Status == "Error");
			this.setState({ tempList: temp });
		}
	}
	FilterSuccess = () => {
		if (this.state.list != null) {
			var temp = this.state.list.filter(x => x.Status == "Success");
			this.setState({ tempList: temp });
		}
	}
	FilterWarning = () => {
		if (this.state.list != null) {
			var temp = this.state.list.filter(x => x.Status == "Warning");
			this.setState({ tempList: temp });
		}
	}
	FilterAll = () => {
		this.setState({ tempList: this.state.list });
	}

	ShowMessage = (type, title, message) => {
		// type:-success->warning->info->error
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}
	GridRowCount = (count) => {
		return ++count;
	}
	GetColor = (type) => {
		if (type == "Error")
			return "#f44242";
		else if (type == "Warning")
			return "#ba9100";
		else
			return "#191819";
	}

	render() {
		const { tempList } = this.state;

		return (
			<div style={{ marginLeft: "20px" }}>
				{this.state.alert}
				<LoadingComponent Show={this.state.isLoading} />
				Api Model Test

				<GridContainer justify="flex-start">
					<GridItem style={{ float: "left" }}>
						<Button size="sm" onClick={this.FetchData} >Fetch </Button>
						<Button size="sm" onClick={this.FilterAll} >All </Button>
						<Button size="sm" onClick={this.FilterSuccess} >Success </Button>
						<Button size="sm" onClick={this.FilterWarning} >Warnings </Button>
						<Button size="sm" onClick={this.FilterErrors} >Errors </Button>
					</GridItem>
				</GridContainer>

				<br /><br /><br />
				<GridContainer>
					<GridItem xs={2}>
						<b><p style={{ paddingTop: "10px", marginLeft: "5px" }}> Name </p></b>
					</GridItem>
					<GridItem xs={2}>
						<b><p style={{ paddingTop: "10px", marginLeft: "5px" }}> Namespace </p></b>
					</GridItem>
					<GridItem xs={2}>
						<b><p style={{ paddingTop: "10px", marginLeft: "5px" }}> TableName </p></b>
					</GridItem>
					<GridItem xs={1}>
						<b><p style={{ paddingTop: "10px", marginLeft: "5px" }}> Status </p></b>
					</GridItem>
					<GridItem xs={2}>
						<b><p style={{ paddingTop: "10px", marginLeft: "5px" }}> Error </p></b>
					</GridItem>
					<GridItem xs={3}>
						<b><p style={{ paddingTop: "10px", marginLeft: "5px" }}> ErrorDetail </p></b>
					</GridItem>
				</GridContainer>
				<hr />
				{tempList != null && tempList.map((item, index) => (
					<GridContainer key={item.Id} style={{ color: this.GetColor(item.Status) }}>
						<GridItem xs={2}>
							<p style={{ paddingTop: "5px", marginLeft: "5px" }}> {this.GridRowCount(index)} - {item.Name} </p>
						</GridItem>
						<GridItem xs={2}>
							<p style={{ paddingTop: "5px", marginLeft: "5px" }}> {item.Namespace} </p>
						</GridItem>
						<GridItem xs={2}>
							<p style={{ paddingTop: "10px", marginLeft: "5px" }}> {item.TableName} </p>
						</GridItem>
						<GridItem xs={1}>
							<p style={{ paddingTop: "5px", marginLeft: "5px" }}> {item.Status} </p>
						</GridItem>
						<GridItem xs={2}>
							<p style={{ paddingTop: "5px", marginLeft: "5px" }}> {item.Error} </p>
						</GridItem>
						<GridItem xs={3}>
							<p style={{ paddingTop: "5px", marginLeft: "5px" }}> {item.ErrorDetail} </p>
						</GridItem>
					</GridContainer>
				))}
			</div >
		);
	}
}

export default ApiModelTest;
