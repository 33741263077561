import { DialogContent, DialogTitle, withStyles } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ArrayHelper from "core/ArrayHelper";
import ClientHelper from "core/ClientHelper.js";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import MenuAuthorityRightHelper from "core/MenuAuthorityRightHelper";
import StringHelper from "core/StringHelper";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import DigitalVerificationPanel from "views/AmlKyc/DigitalVerification/DigitalVerificationPanel";
import KycManualCreditValidation from "views/AmlKyc/ManualKycApplication/KycManualCreditValidation";
import ManualKycApplicationDocument from "views/AmlKyc/ManualKycApplication/ManualKycApplicationDocument";
import FlinksAccountPreviewCard from "views/BankAccount/LinkedAccounts/FlinksAccountPreviewCard";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDateInput, GenericDialog, GenericDialogActions, GenericEmailInput, GenericExpansionPanel, GenericGrid, GenericLabel, GenericMultipleSelectInput, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle, GenericVerificationComponent } from "views/Components/Generic";
import ParameterComponent from "views/Components/ParameterComponent";
import { CanadaPostIdVerificationStatus, DigitalVerificationAction, DigitalVerificationPopupUploadCode, IdentificationMethod, IdentitySourceType, KycManualApplicationReasonType, KYCManualApplicationStatus, KYCVerifiedStatus, ManualKycAppMenuAuthority, ProgramCodes } from "views/Constants/Constant";
import { Proxy } from "core";

const initialState = {
	panelActivationStatus: {
		panelApplicantInformationActive: false,
		panelVerificationActive: false,
		panelDigitalVerificationActive: false,
		panelManualKycApplicationDocumentActive: false,
		panelAccountVerificationActive: false,
		panelCreditValidationStatusActive: false,
		panelApplicantListActive: false,
	},
	manualKycDocumentList: []
};
const KycApiUrl = "/amlkycapi/v1.0/KycManualApplication/";
var manualKycApplicationStyle = theme => ({
	verificationContainer: {
		display: "flex"
	},
	propertyBadge: {
		width: "fit-content",
		padding: "1px 1px",
		margin: 4,
		borderWidth: 1,
		borderColor: "#ff000082",
		borderStyle: "dashed",
		borderRadius: "10%",
	},
	propertyContainer: {
		display: "flex",
		borderWidth: 1,
		borderColor: "#00000082",
		borderStyle: "solid",
		width: "fit-content",
		padding: "1px 1px",
	}
});

class ManualKycApplication extends React.Component {
	constructor(props) {
		super(props);

		var initialModel = {
			InPersonIDVerificationEnable: false,
			CanadaPostVerificationEnable: false,
			DigitalIdentityVerificationEnable: false,
			ManualKycApplicationDocumentEnable: false,
			AccountVerificationEnable: false,
			Id: 0,
			Isupdate: false,
			CanadapostCntrl: false,
			IdentityTypeIndex: null,
			IdentityVerifiedPerson: null,
			IdentityVerifiedPersonId: null,
			SelectedIdentityVerificationType: null,
			IdentityType: null,
			IdentityNumber: null,
			ProofOfResidenceId: null,
			IsRequiredProofOfResidence: null,
			IdentitySourceType: null,
			IdentitySourceTypeId: null,
			CanadaPostVerificationId: null,
			DigitalIdentityVerificationStatus: null,
			DigitalIdentityVerificationId: null,
			IsNotSupportedDigitalVerification: null,
			IdentityVerificationTypeIdList: [],
			IdentityTypeId: null,
			IdentityProvinceId: null,
			IdentityCountryId: null,
			FirstName: null,
			MiddleName: null,
			LastName: null,
			CountryId: null,
			ProvinceId: null,
			CityId: null,
			BirthDate: null,
			GenderId: null,
			Email: null,
			MobilePhoneCountryCodeId: null,
			MobilePhoneNumber: null,
			Address1: null,
			ZipCode: null,
			IdVerificationStatus: null,
			ParameterValue: null,
			WatchListVerificationStatus: null,
			InternalBlacklistStatus: null,
			TransUnionIdVerificationStatus: null,
			CanadaPostVerification: null,
			CanadaPostVerificationStatus: null,
			UserPhotoId: null,
			IdentityPhotoId: null,
			IdentityPhotoBackId: null,
			LastDigitalVerificationLinkId: null,
			CreditHistoryVerificationStatus: null,
			IdVerificationEmailStatus: null,
			User: null,
			ReasonTypeId: null,
			Address2: null,
			ReasonComment: null,
			CustomerLinkedAccount: null,
			AccountVerificationRequestStatus: null,
			AccountVerificationStatus: null,
			AccountVerification: null,
			LastAccountVerificationLinkId: null,
			CustomerLinkedAccountId: null,
			DigitalIdentityVerificationRequestStatus: null
		};
		this.state = {
			model: { ...initialModel },
			tempModel: { ...initialModel },
			verificationResultModel: {},
			modelList: [],
			IdVerificationTypeList: [],
			IdVerificationTypeListForMultipleSelect: [],
			vModel: { ...initialModel },
			tempdata: {},
			creditHistoryModel: {},
			panelActivationStatus: {},
			openDialog: false,
			rendererNumber: 0,
			genderList: [],
			idTypeList: [],
			reasonTypeList: [],
			isOpenkycUploadWindow: null,
			manualKycDocumentList: [],
			reRenderStickyPanel: true,
			identitySourceTypeList: [],
			verificationDefinition: {
				VerifyInternalBlacklist: null,
				VerifyExternalBlacklist: null,
				VerifyTransUnion: null,
				VerifyCanadaPost: null,
				VerifyDocuments: null,
				VerifyAccountVerification: null,
				VerifyInPersonId: null,
				VerifyDigitalId: null
			},
			identityDocumentList: [],
			isReasonDialogOpen: false,
			hasFeeProfile: false,
			isModifiedInformationCard: false
		};
		this.getappCenterTitle = "Empty";
		this.getappLeftTitle = "Empty";
		this.ParameterMaxLength30 = { maxLength: 30 };
		this.ParameterMaxLength64 = { maxLength: 64 };
		this.parameterIdentificationMethod = { ParameterCode: "IdentificationMethod" };
		this.maxLength100 = { maxLength: 100 };
		this.maxLength20 = { maxLength: 20 };
		this.marginLeft2 = { marginLeft: 2 };
		this.emptyObject = {};
		this.emptyArray = [];
		this.ColumnsData = [
			{
				Header: "Date",
				accessor: "InsertDate",
				width: 50,
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{Formatter.FormatDate(row.value)}</div>)

			},
			{
				Header: "Full Name",
				accessor: "FullName",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{row.value}</div>)
			},
			{
				Header: "Identity Number",
				accessor: "IdentityNumber",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{row.value}</div>)

			},
			{
				Header: "Identity Type",
				accessor: "IdentityTypeName",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{row.value}</div>)
			},
			{
				Header: "ID Verification Status",
				accessor: "IdVerificationStatus",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{row.value ? "Valid" : "Invalid"}</div>)

			},
			{
				Header: "Internal Blacklist Status",
				accessor: "InternalBlacklistStatus",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{row.value ? "Valid" : "Invalid"}</div>)

			},
			{
				Header: "Watch List Verification Status",
				accessor: "WatchListVerificationStatus",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{row.value ? "Valid" : "Invalid"}</div>)

			},
			{
				Header: "Trans Union Id Verification Status",
				accessor: "TransUnionIdVerificationStatus",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{row.value ? "Valid" : "Invalid"}</div>)
			},
			{
				width: 100,
				Header: "Canada Post Verification Id Status",
				accessor: "CanadaPostVerification",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{row.value}</div>)

			},
			{
				width: 100,
				Header: "Digital Verification Status",
				accessor: "DigitalIdentityVerificationStatus",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{row?.original?.DigitalIdentityVerificationId > 0 ? row?.value ? "Valid" : "Invalid" : ""}</div>)
			},
			{
				width: 80,
				Header: "Digital Verification Request Status",
				accessor: "DigitalIdentityVerificationRequestStatus",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{row.value}</div>)
			},
			{
				width: 100,
				Header: "Wallet Verification Status",
				accessor: "AccountVerificationStatus",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{row?.original?.CustomerLinkedAccountId > 0 ? row?.value ? "Valid" : "Invalid" : ""}</div>)
			},
			{
				width: 80,
				Header: "Wallet Verification Request Status",
				accessor: "AccountVerificationRequestStatus",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{row.value}</div>)
			},
			{
				Header: "Approvel Status",
				accessor: "ParameterStatus",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{row.value}</div>)
			},
			{
				Header: "Reason",
				accessor: "ReasonTypeId",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{this.GetReasonTypeParameterById(row.value)?.ParameterDesc}</div>)
			},
			{
				Header: "Comment",
				accessor: "ReasonComment",
				width: 120,
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{row.value}</div>)
			},
			{
				Header: "E-Mail",
				accessor: "Email",
				width: 80,
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{row.value}</div>)

			},
			{
				Header: "Mobile Phone",

				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{row.original.MobilePhoneNumber}</div>)

			},
			{
				Header: "Identity Type",
				accessor: "IdentityTypeName",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{row.value}</div>)
			},
			{
				Header: "Source Type",
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.ParameterValue)}>{row.original.SourceType}</div>)

			}
		];

		this.tempObject = {};
		this.statusWrapperStyle = { position: "absolute", bottom: 0, left: 0, right: 0, padding: "0px 10px" };
		this.statusGridItemContainerStyle = { position: "relative" };
		this.statusGridContainerStyle = { height: "100%" };
		this.checkBoxGroupStyle = { marginTop: 4 };
		this.overflowVisibleStyle = { overflow: "visible" };
		this.placeholderNA = "N/A";
		this.verificationPanelStyle = {
			position: "sticky",
			zIndex: 1,
			top: 190,
		};
		this.verificationPanelPopupStyle = {
			width: "auto",
			position: "sticky",
			zIndex: 1,
			top: 10,
			margin: "10px 25px",
		};
		this.clientUser = ClientHelper.GetUser();
		this.isClient = ClientHelper.IsClient();

		this.MenuAuthorityRightHelper = new MenuAuthorityRightHelper();
	}

	setStateAsync = (newState) => {
		return new Promise((resolve) => this.setState(newState, resolve));
	}
	async componentDidMount() {

		if (this.props.setAppLeftTitle) {
			this.props.setAppLeftTitle("Manual KYC Application");
			this.getappLeftTitle = "Manual KYC Application";
		}
		if (this.props.setAppCenterTitle) {
			this.props.setAppCenterTitle("AML & KYC");
			this.getappCenterTitle = "AML & KYC";
		}
		const tasks = [];
		tasks.push(this.LoadCanadaPost());
		tasks.push(this.GetGenderList());
		tasks.push(this.GetIdentityVerificationType());
		tasks.push(this.GetIdTypeList());
		tasks.push(this.GetReasonTypeList());
		tasks.push(this.GetIdentitySourceTypeList());
		tasks.push(this.GetIdentityDocumentList());
		tasks.push(this.GetDigitalIdFeeProfileFromContact());

		this.setState({ panelActivationStatus: initialState.panelActivationStatus });
		if (this.props.ManualKycModel?.Id > 0) {
			await Promise.all(tasks);
			var model = this.props.ManualKycModel;
			this.SelectedRowChange(null, model);
			this.setStateAsync({ reRenderStickyPanel: true });
		}

	}

	GetVerificationDefinitionFromMenuAuthority = async () => {

		var result = await this.GetMenuAuthorityRightList();
		var mappedResult = this.MapMenuAuthorityDefinitionToVerificationDefinition(result);
		return mappedResult;
	}
	GetMenuAuthorityRightList = async () => {//For backoffice
		var menuAuthorityRight = await this.MenuAuthorityRightHelper.RightCheckList(this.props.MenuCode, Object.values(ManualKycAppMenuAuthority));
		return menuAuthorityRight;
	}
	MapMenuAuthorityDefinitionToVerificationDefinition = (menuRightList) => {

		var result = {
			VerifyInternalBlacklist: this.HasMenuAuthorityRight(menuRightList, ManualKycAppMenuAuthority.InternalBlacklist),
			VerifyExternalBlacklist: this.HasMenuAuthorityRight(menuRightList, ManualKycAppMenuAuthority.WatchList),
			VerifyTransUnion: this.HasMenuAuthorityRight(menuRightList, ManualKycAppMenuAuthority.TransUnion),
			VerifyCanadaPost: this.HasMenuAuthorityRight(menuRightList, ManualKycAppMenuAuthority.CanadaPost),
			VerifyDocuments: this.HasMenuAuthorityRight(menuRightList, ManualKycAppMenuAuthority.Documents),
			VerifyAccountVerification: this.HasMenuAuthorityRight(menuRightList, ManualKycAppMenuAuthority.AccountVerification),
			VerifyInPersonId: this.HasMenuAuthorityRight(menuRightList, ManualKycAppMenuAuthority.InPerson),
			VerifyDigitalId: this.HasMenuAuthorityRight(menuRightList, ManualKycAppMenuAuthority.DigitalIdVerification),
		};

		return result;
	}

	HasMenuAuthorityRight = (menuAuthorityList, menuAuthorityCode) => {
		return menuAuthorityList.find(a => a.Code == menuAuthorityCode)?.HasAuthority;
	}

	GetDigitalIdFeeProfileFromContact = async () => {
		var response = await this.props.ExecuteApiGet("/bankapi/v1.0/CustomerContract/GetByCustomerId/Id?Id=");
		var isFeeProfileDefinedForDigitalId = false;
		if (response) {
			for (let index = 0; index < response.length; index++) {
				const item = response[index];
				if (item.Program?.ProgramCode == ProgramCodes.DigitalIdVerification && item.FeeProfile) {
					isFeeProfileDefinedForDigitalId = true;
				}
			}
		}
		this.setState({ hasFeeProfile: isFeeProfileDefinedForDigitalId });
	}
	GetIdentityDocumentList = async () => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/amlkycapi/v1.0/DigitalIdentityVerificationIdentityDocument/Search");

		this.setState({ identityDocumentList: result || [] });
	}
	GetVerificationDefinition = async (idVerificationList) => {
		const { ExecuteApiPost } = this.props;
		if (!this.isClient) {
			return {};
		}
		var result = await ExecuteApiPost("/amlkycapi/v1.0/KycManualApplicationVerificationDefinition/Search", this.parameterIdentificationMethod, null, null, null, null, null);
		var definition = {};
		if (result && result.length > 0) {
			definition = result[0];
		}
		return definition;
	}
	GetColumnsRowStyleColor(status) {
		this.columnsRowStyleColor = {
			color: status == KYCManualApplicationStatus.OnHold ? "#FB3005" : ""
		};
		return this.columnsRowStyleColor;
	}
	GetMultiCheckSelectedValue = (selectedModel, discardIdentityVerificationTypeIdList = []) => {
		const { IdVerificationTypeList, model: m } = this.state;
		var model = selectedModel || m;
		var selected = IdVerificationTypeList.map(a => a).filter(a => discardIdentityVerificationTypeIdList.indexOf(a.Id) == -1 && ((model?.IdentityVerificationTypeIdList || []).indexOf(a.Id) > -1));
		if (selected?.length > 0) {
			return selected.map(item => {
				return { value: item.Id, label: item.ParameterDesc };
			});
		}
		return this.emptyArray;
	}

	GetIdVerificationTypeListForMultipleSelect = (newValue, newModel) => {
		const model = newModel || this.state.model;
		const { IdVerificationTypeListForMultipleSelect } = this.state;
		var inPersonIDVerificationId = this.state.IdVerificationTypeList.find(a => a.ParameterValue == IdentificationMethod.InPersonIDVerification)?.Id;
		var canadaPostVerificationId = this.state.IdVerificationTypeList.find(a => a.ParameterValue == IdentificationMethod.CanadaPostVerification)?.Id;
		var digitalVerificationTypeId = this.state.IdVerificationTypeList.find(a => a.ParameterValue == IdentificationMethod.DigitalIdentityVerification)?.Id;
		var documentsVerificationTypeId = this.state.IdVerificationTypeList.find(a => a.ParameterValue == IdentificationMethod.Documents)?.Id;
		var accountVerificationTypeId = this.state.IdVerificationTypeList.find(a => a.ParameterValue == IdentificationMethod.AccountVerification)?.Id;

		var verificationSelects = newValue.map((x) => x.value);

		var isSelectedCanadaPostVerification = verificationSelects.find(a => a == canadaPostVerificationId) > 0;
		var isSelectedDigitalVerificationTypeId = verificationSelects.find(a => a == digitalVerificationTypeId) > 0;
		var isSelectedInPersonIDVerificationId = verificationSelects.find(a => a == inPersonIDVerificationId) > 0;
		var isSelectedDocumentsVerificationId = verificationSelects.find(a => a == documentsVerificationTypeId) > 0;
		var isSelectedAccountVerificationId = verificationSelects.find(a => a == accountVerificationTypeId) > 0;

		var response = {
			isSelectedCanadaPostVerification,
			isSelectedDigitalVerificationTypeId,
			isSelectedInPersonIDVerificationId,
			isSelectedDocumentsVerificationId,
			isSelectedAccountVerificationId,

			inPersonIDVerificationId,
			canadaPostVerificationId,
			digitalVerificationTypeId,
			documentsVerificationTypeId,
			accountVerificationTypeId
		};

		var selectedIdentityTypeParameter = this.GetFilteredIdTypeListByLocation(model).find(a => a.IdentityTypeId == model?.IdentityTypeId && a.IdentitySourceType.ParameterValue == model?.IdentitySourceType?.ParameterValue);
		const isNotSupportedDigitalVerification = !selectedIdentityTypeParameter?.HasRecognized;
		response.list = IdVerificationTypeListForMultipleSelect.map(newItem => {
			var item = { ...newItem };
			item.isDisabled = false;


			if ((isNotSupportedDigitalVerification && item.Id == digitalVerificationTypeId)
				||
				(model.IdVerificationStatus && item.Id == inPersonIDVerificationId)
				||
				(newValue?.length > 0 &&
					(
						(isSelectedInPersonIDVerificationId && (item.Id == canadaPostVerificationId || item.Id == digitalVerificationTypeId))
						||
						(((isSelectedCanadaPostVerification) || isSelectedDigitalVerificationTypeId) && item.Id == inPersonIDVerificationId)
					)
				)
			) {
				item.isDisabled = true;
			}


			if (selectedIdentityTypeParameter && item.Id == canadaPostVerificationId && selectedIdentityTypeParameter.IdentitySourceType.ParameterValue == IdentitySourceType.DigitalIdentityVerificationIdentityType) {
				item.isDisabled = true;
			}
			return item;
		});

		response["IdentityVerificationTypeIdList"] = verificationSelects;
		return response;
	}
	HandleChange = async (name, newValue, data) => {
		const model = { ...this.state.model };
		const verificationDefinition = this.state.verificationDefinition;

		if (name == "IdentityVerifiedPersonId" && ((model.IdentityVerifiedPersonId == null || model.IdentityVerifiedPersonId == 0) ||
			(model.IdentityVerifiedPerson == null))) {
			model["IdentityVerifiedPersonId"] = this.clientUser.Id;
			model["IdentityVerifiedPerson"] = this.clientUser;
		}
		if (name == "SelectedIdentityVerificationType" && data != null) {

			var idVerificationTypeResponse = this.GetIdVerificationTypeListForMultipleSelect(newValue);
			model.SelectedIdentityVerificationType = newValue;
			this.setState({ model, IdVerificationTypeListForMultipleSelect: idVerificationTypeResponse.list });
			return;
		}
		if (name === "WalletAccountId") {
			model["CardFinancialId"] = data && data.CardFinancialId;
		}
		if (name == "IdentityTypeIndex") {
			model["IdentityTypeId"] = data.IdentityTypeId;
			model.IdentityType = data;
			model.IdentityNumber = null;
			model.ProofOfResidenceId = null;
			model.IsRequiredProofOfResidence = data.ProofOfResidence;
			model.IdentitySourceType = data.IdentitySourceType;
			model.IdentitySourceTypeId = data.IdentitySourceType.Id;

			var verificationResultModel = this.state.verificationResultModel;
			var multipleSelectDataResponse = this.GetIdVerificationTypeListForMultipleSelect(this.GetMultiCheckSelectedValue(model), model);
			var canadaPostProofAndDigitalIdMsg = "";
			var canadaPostProofAndDigitalIdMsgContainer = [];
			var idModifiedWarnTemplate = "{0} has been available for the record. If you continue processing the previous process will be canceled.";

			if (verificationDefinition.VerifyCanadaPost !== false && (multipleSelectDataResponse.isSelectedCanadaPostVerification && model?.CanadaPostVerificationId > 0)) {
				canadaPostProofAndDigitalIdMsgContainer.push("Canada Post");
			}
			if (verificationDefinition.VerifyDigitalId !== false && (model?.DigitalIdentityVerificationStatus || model?.DigitalIdentityVerificationId > 0)) {
				canadaPostProofAndDigitalIdMsgContainer.push("Digital ID Verification");
				verificationResultModel = null;
				model.DigitalIdentityVerificationId = null;
				model.DigitalIdentityVerificationStatus = null;
			}
			if (canadaPostProofAndDigitalIdMsgContainer?.length) {
				var affectedValidations = canadaPostProofAndDigitalIdMsgContainer.join(" And ");
				canadaPostProofAndDigitalIdMsg = StringHelper.Format(idModifiedWarnTemplate, [affectedValidations]);
			}
			var canadaPostVerificationTypeId = this.state.IdVerificationTypeList.find(a => a.ParameterValue == IdentificationMethod.CanadaPostVerification)?.Id;
			var multiSelectExcludeList = [];

			if (data.IdentitySourceType.ParameterValue == IdentitySourceType.DigitalIdentityVerificationIdentityType) {
				multiSelectExcludeList.push(canadaPostVerificationTypeId);
			}

			model.IsNotSupportedDigitalVerification = !data?.HasRecognized;

			if (verificationDefinition.VerifyDigitalId !== false && model.IsNotSupportedDigitalVerification) {
				if (canadaPostProofAndDigitalIdMsg)
					if (!await this.props.showConfirmMessage("warning", "Warning", "Canada Post request has been available for the record. This identity type cannot be identified yet. If you select current ID Type, Digital ID won't be available and previous Canada Post request will be canceled.")) {
						return;
					}
					else {
						canadaPostProofAndDigitalIdMsg = "";
					}
				else
					if (!await this.props.showConfirmMessage("warning", "Warning", "This identity type cannot be identified yet. If you select current ID Type, Digital ID won't be available")) return;

				model.DigitalIdentityVerificationEnable = false;
				multiSelectExcludeList.push(this.GetVerificationTypeParameterByParamValue(IdentificationMethod.DigitalIdentityVerification)?.Id);
			}

			if (canadaPostProofAndDigitalIdMsg && !await this.props.showConfirmMessage("warning", "Warning", canadaPostProofAndDigitalIdMsg)) return;

			model.SelectedIdentityVerificationType = this.GetMultiCheckSelectedValue(model, multiSelectExcludeList);
			multipleSelectDataResponse = this.GetIdVerificationTypeListForMultipleSelect(model.SelectedIdentityVerificationType, model);
			model.IdentityVerificationTypeIdList = multipleSelectDataResponse.IdentityVerificationTypeIdList;
			model.CanadaPostVerificationEnable = multipleSelectDataResponse.isSelectedCanadaPostVerification && (model?.IdentitySourceType?.ParameterValue == null || model?.IdentitySourceType?.ParameterValue == IdentitySourceType.ParameterDefinition);

			model.CanadaPostVerificationId = null;
			var IdVerificationTypeListForMultipleSelect = multipleSelectDataResponse.list.map(item => {
				if (item.Id == multipleSelectDataResponse.canadaPostVerificationId) {
					item.isDisabled = data.IdentitySourceType.ParameterValue == IdentitySourceType.DigitalIdentityVerificationIdentityType;
				}
				return item;
			});
			await this.setStateAsync({ IdVerificationTypeListForMultipleSelect, verificationResultModel });
			await this.HandleChangeMenu(null, false, model);
		}

		var isModifiedInformationCard = this.IsChangedIdPanelInformations(model);
		model[name] = newValue;
		await this.setStateAsync({ model, isModifiedInformationCard });

	}


	IsChangedIdPanelInformations = (newModel) => {
		var tempModel = { ...this.state.tempModel };
		var model = newModel || this.state.model;

		var isModified = (
			model.IdentityCountryId != tempModel.IdentityCountryId ||
			model.IdentityProvinceId != tempModel.IdentityProvinceId ||
			model.IdentitySourceTypeId != tempModel.IdentitySourceTypeId ||
			model.IdentityTypeId != tempModel.IdentityTypeId ||
			model.IdentityNumber != tempModel.IdentityNumber
		);
		return isModified;
	}
	HandleChangeMenu = async (name, isOpen, newModel) => {

		if (isOpen) return;
		const model = { ...(newModel ?? this.state.model) };
		this.setState({ model: { ...model } });

		var response = this.GetIdVerificationTypeListForMultipleSelect(model.SelectedIdentityVerificationType || this.GetMultiCheckSelectedValue(model));

		model["IdentityVerificationTypeIdList"] = response.IdentityVerificationTypeIdList;


		model.InPersonIDVerificationEnable = response.isSelectedInPersonIDVerificationId;
		model.CanadaPostVerificationEnable = response.isSelectedCanadaPostVerification && (model?.IdentitySourceType?.ParameterValue == null || model?.IdentitySourceType?.ParameterValue == IdentitySourceType.ParameterDefinition);
		model.DigitalIdentityVerificationEnable = response.isSelectedDigitalVerificationTypeId;
		model.ManualKycApplicationDocumentEnable = response.isSelectedDocumentsVerificationId;
		model.AccountVerificationEnable = response.isSelectedAccountVerificationId;

		if (newModel == null && response.isSelectedDigitalVerificationTypeId) {
			this.panelclose(false, true, true, false, false, false, false);
		}


		if (!model.SelectedIdentityVerificationType || model.SelectedIdentityVerificationType?.length == 0) {
			model.CanadaPostVerificationEnable = false;
			model.InPersonIDVerificationEnable = false;
			model.DigitalIdentityVerificationEnable = false;
			model.ManualKycApplicationDocumentEnable = false;
			model.AccountVerificationEnable = false;
		}
		this.UpdateSelectedVerificationMethodList(model);
		this.props.taskPool.SetDone();
		await this.setStateAsync({ model });
	}
	GetVerificationTypeParameterById = (id) => {
		return { ...this.state?.IdVerificationTypeList?.find(a => a.Id == id) };
	}
	GetVerificationTypeParameterByParamValue = (parameterValue) => {
		return { ...this.state?.IdVerificationTypeList?.find(a => a.ParameterValue == parameterValue) };
	}
	SelectedRowChange = (index, newModel) => {
		const model = newModel || this.state.modelList[index];
		const { genderList } = this.state;
		model.BirthDate = model.BirthDate === null ? null : DateHelper.ToMoment(model.BirthDate);

		model.IdentityExpireDate = model.IdentityExpireDate === null ? null : DateHelper.ToMoment(model.IdentityExpireDate);

		model.Gender = genderList && model.GenderId && (genderList.find(a => a.Id == model.GenderId) || { ParameterValue: "M" })?.ParameterValue;
		var filteredIdTypeList = this.GetFilteredIdTypeListByLocation(model);
		var customIdentityType = filteredIdTypeList.find(a => a.IdentityTypeId == model.IdentityTypeId && a.IdentitySourceType.ParameterValue == model?.IdentitySourceType?.ParameterValue);
		model.IdentityType = customIdentityType;
		model.IdentityTypeIndex = customIdentityType?.Id;
		model.IdentitySourceType = customIdentityType?.IdentitySourceType;
		model.IdentitySourceTypeId = customIdentityType?.IdentitySourceType?.Id;

		model.IsRequiredProofOfResidence = customIdentityType?.ProofOfResidence;

		model.Isupdate = true;
		model.IsNotSupportedDigitalVerification = customIdentityType?.HasRecognized !== undefined && !customIdentityType?.HasRecognized;


		model.SelectedIdentityVerificationType = this.GetMultiCheckSelectedValue(model);
		var multipleSelectDataResponse = this.GetIdVerificationTypeListForMultipleSelect(model.SelectedIdentityVerificationType, model);

		if (model.IdentityVerificationTypeIdList.find(a => a == this.GetVerificationTypeParameterByParamValue(IdentificationMethod.InPersonIDVerification)?.Id)) {
			model.InPersonIDVerificationEnable = true;
		}
		else {
			model.InPersonIDVerificationEnable = false;
		}

		if (model?.IdentitySourceType?.ParameterValue == IdentitySourceType.ParameterDefinition && model.IdentityVerificationTypeIdList.find(a => a == this.GetVerificationTypeParameterByParamValue(IdentificationMethod.CanadaPostVerification)?.Id)) {
			model.CanadaPostVerificationEnable = true;
		} else {
			model.CanadaPostVerificationEnable = false;
		}

		if (!model.IsNotSupportedDigitalVerification && model.IdentityVerificationTypeIdList.find(a => a == this.GetVerificationTypeParameterByParamValue(IdentificationMethod.DigitalIdentityVerification)?.Id)) {// || (IdentityVerificationTypeParameterList.length == 0 && (model?.IdentityPhotoId > 0 || model?.UserPhotoId > 0))
			model.DigitalIdentityVerificationEnable = true;
		} else {
			model.DigitalIdentityVerificationEnable = false;
		}

		if (model.IdentityVerificationTypeIdList.find(a => a == this.GetVerificationTypeParameterByParamValue(IdentificationMethod.Documents)?.Id)) {// || (IdentityVerificationTypeParameterList.length == 0 && (model?.IdentityPhotoId > 0 || model?.UserPhotoId > 0))
			model.ManualKycApplicationDocumentEnable = true;
		} else {
			model.ManualKycApplicationDocumentEnable = false;
		}

		if (model.IdentityVerificationTypeIdList.find(a => a == this.GetVerificationTypeParameterByParamValue(IdentificationMethod.AccountVerification)?.Id)) {// || (IdentityVerificationTypeParameterList.length == 0 && (model?.IdentityPhotoId > 0 || model?.UserPhotoId > 0))
			model.AccountVerificationEnable = true;
		} else {
			model.AccountVerificationEnable = false;
		}

		const rand = Math.random();
		const tempModel = { ...model };
		this.setState({ IdVerificationTypeListForMultipleSelect: multipleSelectDataResponse.list, model, tempModel, isModifiedInformationCard: false, rendererNumber: rand }, async () => {


			this.GetCreditHistory(model.Id);
			await this.GetDigitalIdentityInfo(model.Id);
			this.GetManualKycDocumentModels(model);
			var expandDigitalVerification = (model?.IdentityPhotoId > 0 || model?.UserPhotoId > 0) || multipleSelectDataResponse.isSelectedDigitalVerificationTypeId;

			this.panelclose(true, true, expandDigitalVerification, false, false, false, false);
		});

	}

	HandleSearch = async (disablePanelClose) => {
		const model = this.state.model;

		var result = await this.props.ExecuteApiPost("/amlkycapi/v1.0/KycManualApplication/Search", model);
		this.setState({ modelList: result || [] });

		if (disablePanelClose !== true) {
			this.panelclose(false, false, false, false, false, false, true);
		}
	}
	Delete = async () => {

		if (!await this.props.showConfirmMessage("warning", "Delete", "This record will be deleted.Are you Sure?")) return;

		const model = this.state.model;
		if (model == null || model.Id == null || model.Id == 0) {
			this.props.showMessage("error", "Error", "Kyc Manual Application must be selected for delete operation !");
			return;
		}
		var result = await this.props.ExecuteApiPost("/amlkycapi/v1.0/KycManualApplication/Delete", model);
		if (result) {
			this.props.showMessage("success", "Success", "Kyc Manual Application  deleted !");
			this.HandleClear(true);
		}

	}
	HandleChangeFilterPanel = (name, expanded) => {

		var currentPanelActivation = Object.assign({}, initialState.panelActivationStatus);
		currentPanelActivation.panelApplicantInformationActive = false;
		var value = expanded ? true : false;
		currentPanelActivation[name] = value;

		if (name == "panelVerificationActive" && value) {
			currentPanelActivation["panelDigitalVerificationActive"] = true;
		}

		if (name == "panelDigitalVerificationActive") {
			currentPanelActivation["panelVerificationActive"] = true;
		}
		this.setState({
			panelActivationStatus: currentPanelActivation
		});

	}

	GetFullName = () => {
		const model = { ...this.state.model };
		let fullName = "";
		if (model.FirstName == null || model.FirstName == undefined) { return fullName; }
		fullName = model.FirstName;
		if (model.MiddleName != null && model.MiddleName !== "") { fullName += " " + model.MiddleName; }
		if (model.LastName != null && model.LastName !== "") { fullName += " " + model.LastName; }
		return fullName;
	}

	GetIdentityVerificationType = async () => {

		var verificationDefinition = {};
		if (this.isClient) {
			verificationDefinition = await this.GetVerificationDefinition();
		}
		else {
			verificationDefinition = await this.GetVerificationDefinitionFromMenuAuthority();
		}
		var result = await this.props.ExecuteApiPost("/coreapi/v1.0/Parameter/Search", this.parameterIdentificationMethod, null, null, null, null, null);
		var IdVerificationTypeList = [];

		if (result != null) {
			result
				.filter(x => (x.ParameterValue === IdentificationMethod.CanadaPostVerification && verificationDefinition?.VerifyCanadaPost !== false) ||
					(x.ParameterValue === IdentificationMethod.InPersonIDVerification && verificationDefinition?.VerifyInPersonId !== false) ||
					(x.ParameterValue === IdentificationMethod.DigitalIdentityVerification && verificationDefinition?.VerifyDigitalId !== false) ||
					(x.ParameterValue === IdentificationMethod.Documents && verificationDefinition?.VerifyDocuments !== false) ||
					(x.ParameterValue === IdentificationMethod.AccountVerification && verificationDefinition?.VerifyAccountVerification !== false))
				.forEach(x => {
					IdVerificationTypeList.push(x);
				});
		}
		await this.setStateAsync({ verificationDefinition, IdVerificationTypeList, IdVerificationTypeListForMultipleSelect: IdVerificationTypeList.map(a => a) });
	}
	async LoadCanadaPost() {

		var parent = this;
		var result = await this.props.ExecuteApiPost("/coreapi/v1.0/Parameter/Search", { ParameterCode: "CanadaPostApiKey", ParameterValue: "CP" });

		if (!result) return;
		if (result[0] == null) {
			console.log("Canada Post Api key not fetched !");
			return;
		}
		var apikey = result[0].ParameterDesc;
		console.log("KEY CP ", apikey);


		// @ts-ignore
		var url = window.CanadaPostAddressCompleteUrl;
		var path = url + apikey;

		var pca;

		var CanadaPostSubmit = this.SubmitCanadaPostAddressModel;
		var CanadaPostRequest=this.SubmitCanadaPostRequest;
		const script = document.createElement("script");
		script.src = path;
		script.async = false;
		document.head.appendChild(script);
		script.onload = function () {
			// @ts-ignore
			pca = window.pca;
			if (pca != null) {
				var fields =
					[
						{ element: "street-address", field: "Line1", mode: pca.fieldMode.SEARCH },
						{ element: "street-address2", field: "Line2", mode: pca.fieldMode.POPULATE },
						{ element: "postcode", field: "PostalCode", mode: pca.fieldMode.SEARCH },
					],
					options = {
						key: apikey
					},
					control = new pca.Address(fields, options);
					CanadaPostRequest();
				control.listen("populate", function (address) {
					CanadaPostSubmit(address);
 
					parent.setState(state => {
						state.model.Address1 = address.Line1;
						state.model.Address2 = address.Line2;
						state.model.ZipCode = address.PostalCode;
						return state;
					});
				});
			}
		};


	}
	SubmitCanadaPostAddressModel = async (model) => {

		var result = await this.props.ExecuteApiPost("/coreapi/v1.0/Country/CanadaPost", model);
		if (result) {
			var address = { ...this.state.model };
			if (result != null) {
				address.CountryId = result.CountryId;
				address.ProvinceId = result.ProvinceId;
				address.CityId = result.CityId;
			} else {
				address.CountryId = 0;
				address.ProvinceId = 0;
				address.CityId = 0;
			}
			this.setState({ model: address });
		}
	}

	SubmitCanadaPostRequest = async () => {
		const model = { ...this.state.model };
		var requestdata = { 

			UniqueClientId: null,
			MenuCode:this.props.MenuCode,
			AppCenterTitle:this.getappCenterTitle,
			AppLeftTitle:this.getappLeftTitle
		};
		Proxy.POST(
			"/bankapi/v1.0/Reporting/CanadaPostRequestReportInsert",
			requestdata,
			responseData => {
				if (responseData.Item != null ) {
					 
				}
			},
 		);
		// var result = await this.props.ExecuteApiPost("/bankapi/v1.0/Reporting/CanadaPostRequestReportInsert", requestdata);
	}
	GetPropertyComponent = (name) => {

		const { classes } = this.props;
		return (
			<div key={name} className={classes.propertyBadge}>
				<GenericLabel Text={name} />
			</div>
		);
	}
	GetPropertyContainer = (title, properties) => {
		const { classes } = this.props;
		return (
			<>
				<GenericLabel Text={title} Bold={true} />
				<div className={classes.propertyContainer}>

					{properties.map((item) => this.GetPropertyComponent(item))}
				</div>
			</>
		);
	}
	ShowFeeValidationMessage = async (errors) => {
		return await this.props.showConfirmMessage(
			"warning",
			"There will be a re-charged fee for related any information has been changed",
			<div>
				<br />
				{errors.map((x, i) => <div key={i}>{x}</div>)}
			</div>
		);
	}
	OperationCheck = async (stepIndex, saveModelAfterCheck = true, disableAlert = false, customModel = null) => {
		const { hasFeeProfile, verificationDefinition } = this.state;
		var errorList = [];
		var feeWarning = [];

		const data = { ...(customModel ?? this.state.model) };
		const vModel = { ...this.state.vModel };

		if (data.FirstName == null || data.FirstName == "") {
			errorList.push("First Name must be defined !");
			vModel.FirstName = true;
		} else {
			vModel.FirstName = false;
		}
		if (data.LastName == null || data.LastName == "") {
			errorList.push("Last Name must be defined !");
			vModel.LastName = true;
		} else {
			vModel.LastName = false;
		}
		if (data.BirthDate == null || data.BirthDate == "") {
			errorList.push("Birth Date must be defined !");
			vModel.BirthDate = true;
		} else {
			vModel.BirthDate = false;
		}
		if (typeof data.BirthDate != "object" && data.BirthDate != "" && data.BirthDate != null) {
			this.props.showMessage("warning", "Please fill required fields!", "Date of Birth is invalid");
			return false;
		}

		if (data.GenderId == null || data.GenderId == "") {
			errorList.push("Gender must be defined !");
			vModel.GenderId = true;
		} else {
			vModel.GenderId = false;
		}

		if (data.Email == null || data.Email == "") {
			errorList.push("Email must be defined !");
			vModel.Email = true;
		} else {
			vModel.Email = false;
		}
		if (data.IdentityTypeId == null || data.IdentityTypeId == "") {
			errorList.push("Identity Type must be defined !");
			vModel.IdentityTypeId = true;
		} else {

			var canadaPostVerificationTypeId = this.GetVerificationTypeParameterByParamValue(IdentificationMethod.CanadaPostVerification)?.Id;
			if (data.IsRequiredProofOfResidence && !data.ProofOfResidenceId && (data.IdentityVerificationTypeIdList.indexOf(canadaPostVerificationTypeId) > -1) && stepIndex == 2) {
				errorList.push("Proof of residence must be defined !.");
				vModel.ProofOfResidenceId = true;
			} else {
				vModel.ProofOfResidenceId = false;
			}
			vModel.IdentityTypeId = false;
		}
		if (data.IdentityNumber == null || data.IdentityNumber == "") {
			errorList.push("Identity Number must be defined !");
			vModel.IdentityNumber = true;
		} else {
			vModel.IdentityNumber = false;
		}

		if (data.IdentityCountryId == null || data.IdentityCountryId == "") {
			errorList.push("Identity Country must be defined !");
			vModel.IdentityCountryId = true;
		} else {
			vModel.IdentityCountryId = false;
		}
		if (data.IdentityProvinceId == null || data.IdentityProvinceId == "") {
			errorList.push("Identity Province must be defined !");
			vModel.IdentityProvinceId = true;
		} else {
			vModel.IdentityProvinceId = false;
		}
		if (data.MobilePhoneCountryCodeId == null || data.MobilePhoneCountryCodeId == "") {

			errorList.push("Mobile Phone Country Code must be defined !");
			vModel.MobilePhoneCountryCodeId = true;
		}
		else {
			vModel.MobilePhoneCountryCodeId = false;
		}
		if (data.MobilePhoneNumber == null || data.MobilePhoneNumber == "") {

			errorList.push("Mobile Phone Number must be defined !");
			vModel.MobilePhoneNumber = true;
		} else {
			vModel.MobilePhoneNumber = false;
		}
		if (data.Address1 == null || data.Address1 == "") {
			errorList.push("Address1 must be defined !");
			vModel.Address1 = true;
		} else {
			vModel.Address1 = false;
		}
		if (data.ZipCode == null || data.ZipCode == "") {
			errorList.push("ZipCode must be defined !");
			vModel.ZipCode = true;
		} else {
			vModel.ZipCode = false;
		}
		if (data.CityId == null || data.CityId == "") {
			errorList.push("City must be defined !");
			vModel.CityId = true;
		} else {
			vModel.CityId = false;
		}
		if (data.ProvinceId == null || data.ProvinceId == "") {
			errorList.push("Province must be defined !");
			vModel.ProvinceId = true;
		} else {
			vModel.ProvinceId = false;
		}
		if (data.CountryId == null || data.CountryId == "") {
			errorList.push("Country must be defined !");
			vModel.CountryId = true;
		} else {
			vModel.CountryId = false;
		}

		this.setState({ vModel });
		if (errorList.length > 0) {
			this.props.showValidationErrors(errorList);
			return false;
		}
		var internalProps = this.CheckAndGetModifiedPropsInternalBlacklist();
		if (verificationDefinition.VerifyInternalBlacklist !== false && internalProps.length > 0) {
			feeWarning.push(this.GetPropertyContainer("Internal Blacklist", internalProps));
		}
		var watchListProps = this.CheckAndGetModifiedPropsWatchList();
		if (verificationDefinition.VerifyExternalBlacklist !== false && watchListProps.length > 0) {
			feeWarning.push(this.GetPropertyContainer("WatchList", watchListProps));
		}
		var transUnionProps = this.CheckAndGetModifiedPropsTransUnion();
		if (verificationDefinition.VerifyTransUnion !== false && transUnionProps.length > 0) {
			feeWarning.push(this.GetPropertyContainer("TransUnion", transUnionProps));
		}

		if (this.isClient && data.Id && hasFeeProfile && feeWarning.length > 0) {
			if (!await this.ShowFeeValidationMessage(feeWarning)) return;
		}

		if (saveModelAfterCheck) {
			await this.UpdateModel(stepIndex, false, disableAlert);
		}
		return true;
	}
	OperationStep1 = async () => {
		var model = { ...this.state.model };
		var isModified = false;
		if (this.IsChangedIdPanelInformations(model)) {
			if (model.Id > 0 && model.ParameterValue == KYCManualApplicationStatus.Approved) {
				if (!await this.props.showConfirmMessage("warning", "Warning", "Your Id information has changed and if you continue Approve status will be set as OnHold")) return;
			}

			isModified = true;
		}
		await this.OperationCheck(1, true, false, model);
		if (isModified) {
			model = { ...this.state.model };
			model.IdVerificationStatus = null;
			model.DigitalIdentityVerificationStatus = null;
			var inPersonIdVerificationTypeId = this.state.IdVerificationTypeList.find(a => a.ParameterValue == IdentificationMethod.InPersonIDVerification)?.Id;
			model.SelectedIdentityVerificationType = this.GetMultiCheckSelectedValue(model, [inPersonIdVerificationTypeId]);

			var multipleSelectDataResponse = this.GetIdVerificationTypeListForMultipleSelect(model.SelectedIdentityVerificationType, model);
			this.setState({ model, IdVerificationTypeListForMultipleSelect: multipleSelectDataResponse.list });
		}
	}
	OperationStep2 = async () => {
		await this.OperationCheck(2, true, true);
	}
	OperationStep3 = async () => {
		const { model } = this.state;
		if (model.AccountVerificationEnable) {
			this.panelclose(false, false, false, false, true, false, false);
		}
		else {
			this.panelclose(false, false, false, false, false, true, false);
		}
	}
	OperationStep4 = async () => {
		this.panelclose(false, false, false, false, false, true, false);
	}
	UpdateModel = async (stepIndex, disableSearch = false, disableAlert = false) => {

		var request = { ...this.state.model };

		var result = await this.props.ExecuteApiPost("/amlkycapi/v1.0/KycManualApplication/Insert", request);

		if (result == null) {
			return;
		}
		request.Id = result.Id;
		request.TransUnionIdVerificationStatus = result.TransUnionIdVerificationStatus;
		request.InternalBlacklistStatus = result.InternalBlacklistStatus;
		request.WatchListVerificationStatus = result.WatchListVerificationStatus;
		request.IdVerificationStatus = result.IdVerificationStatus;

		request.CanadaPostVerificationId = result.CanadaPostVerificationId;
		request.CanadaPostVerificationStatus = result.CanadaPostVerificationStatus;
		request.CanadaPostVerification = result.CanadaPostVerification;

		if (!disableAlert) {
			if (request.Id > 0) {
				this.props.showMessage("success", "Success", "KYC Manual Application Updated Successfully .");
			}
			else {
				this.props.showMessage("success", "Success", "KYC Manual Application Saved Successfully .");
			}
		}


		if (stepIndex == 1) {
			this.panelclose(false, true, true, false, false, false, false);
		} else if (stepIndex == 2) {

			if (request.ManualKycApplicationDocumentEnable) {
				this.panelclose(false, false, false, true, false, false, false);
			}
			else if (request.AccountVerificationEnable) {
				this.panelclose(false, false, false, false, true, false, false);
			}
			else {
				this.panelclose(false, false, false, false, false, true, false);
			}

		}

		!disableSearch && this.HandleSearch(true);
		await this.setStateAsync({ model: request, tempModel: request, isModifiedInformationCard: false });
	}

	panelclose = (panelApplicantInformationActive, panelVerificationActive, panelDigitalVerificationActive, panelManualKycApplicationDocumentActive, panelAccountVerificationActive, panelCreditValidationStatusActive, panelApplicantListActive) => {

		const panelActivationStatustemp = {
			panelApplicantInformationActive: panelApplicantInformationActive,
			panelVerificationActive: panelVerificationActive,
			panelDigitalVerificationActive: panelDigitalVerificationActive,
			panelManualKycApplicationDocumentActive: panelManualKycApplicationDocumentActive,
			panelAccountVerificationActive: panelAccountVerificationActive,
			panelCreditValidationStatusActive: panelCreditValidationStatusActive,
			panelApplicantListActive: panelApplicantListActive,
		};

		this.setState({
			panelActivationStatus: panelActivationStatustemp
		});

	}

	GetHandleCanadaPostCodeFunc = (type) => async () => {
		const model = this.state.model;
		const { MenuCode, showMessage } = this.props;

		if (type === "E" && model?.CanadaPostVerificationId > 0) {

			var result = await this.props.ExecuteApiPost("/amlkycapi/v1.0/CanadaPostVerification/SendEmail", { Id: model.CanadaPostVerificationId, MenuCode: MenuCode, EmailAddress: model.Email });
			if (result) {
				showMessage("success", "Success", "Email sent.");

			} else {
				showMessage("error", "Error", "Email not sent.");
			}

		}
		else if (type === "D" && model?.CanadaPostVerificationId > 0) {
			const { ExecuteApiFileDownloadWithGenericResponse } = this.props;
			ExecuteApiFileDownloadWithGenericResponse("/amlkycapi/v1.0/CanadaPostVerification/Print", { Id: model.CanadaPostVerificationId, MenuCode: MenuCode }, "Canada_Post_Verification_Barcode", "pdf");

		}

	}
	SaveCanadaPostRequest = async () => {

		var chechResult = await this.OperationCheck(2, true, true);
		if (!chechResult) return;

		let model = { ...this.state.model };
		var result = await this.props.ExecuteApiPost(`${KycApiUrl}SaveCanadaPostAddressVerificationRequest`,
			{
				Id: model.Id
			});

		if (result == null || result == "") {
			model.CanadaPostVerificationEnable = false;

			this.props.showMessage("error", "Error", "Canada Post Verification Unsuccessful");
		} else {
			model.CanadaPostVerificationId = result;
			model.CanadaPostVerificationEnable = true;
			this.props.showMessage("success", "Success", "Canada Post Verification Successful");
		}
		this.panelclose(false, true, false, false, false, false, false);
		this.setState({ model });
	}
	VerifyDigitalIdentity = async () => {
		let model = this.state.model;
		let modelList = this.state.modelList;

		if (!model.IdentityPhotoId || model.IdentityPhotoId <= 0) {
			this.props.showMessage("error", "Error", "You cannot start verification process without uploading the Card Identity photo");
			return;
		}

		if (model.DigitalIdentityVerificationStatus) {
			if (!await this.props.showConfirmMessage("warning", "Warning", "Digital ID Verification has already been verified successfully. Are you sure you want to check it again?")) return;
		}
		var resultId = await this.props.ExecuteApiPost(`${KycApiUrl}KycDigitalVerification`, { KycManualId: model.Id });

		if (resultId) {
			modelList = modelList.map(item => {
				if (item.Id == resultId) {
					item.DigitalIdentityVerificationId = resultId;
				}
				return item;
			});
			model.DigitalIdentityVerificationId = resultId;

			this.GetDigitalIdentityInfo(model.Id);
			this.props.showMessage("success", "Success", "Digital Verification Completed");
		}
		this.setState({ model, modelList });
	}

	async GetDigitalIdentityInfo(id, updateResultOnly = false) {
		let modelId = id || this.state.model?.Id;
		const model = { ...this.state.model };

		var multipleSelectDataResponse = this.GetIdVerificationTypeListForMultipleSelect(this.GetMultiCheckSelectedValue(model), model);
		if (!modelId || !multipleSelectDataResponse.isSelectedDigitalVerificationTypeId) {
			return;
		}
		var result = await this.props.ExecuteApiPost(`${KycApiUrl}GetKycDigitalVerificationResult`, { KycManualId: modelId });
		if (result) {
			model.UserPhotoId = result.UserPhotoId;
			model.IdentityPhotoId = result.IdentityPhotoId;
			model.IdentityPhotoBackId = result.IdentityPhotoBackId;
			delete result.UserPhotoId;
			delete result.IdentityPhotoId;
			delete result.IdentityPhotoBackId;
		}
		var state = { verificationResultModel: result, model };
		if (updateResultOnly) {
			delete state.model;
		}
		this.setState(state);
	}


	CompleteDigitalIdentityVerified = async () => {

		var model = { ...this.state.model };
		if (!await this.props.showConfirmMessage("warning", "Warning", "Are you sure you want verify Digital ID?")) return;
		var result = await this.props.ExecuteApiPost("/amlkycapi/v1.0/KycManualApplication/KycDigitalVerificationComplete", { Id: model.Id });
		if (result !== null) {
			model.DigitalIdentityVerificationStatus = true;
			this.setState({ model });
			this.props.showMessage("success", "Success", "Digital ID saved as Verified Successfully!");
		}
	}
	RenderMultiSelect = (d) => {
		return `${d.ParameterDesc}`;
	}
	DigitalVerificationResetWrapper = async (action) => {
		let model = { ...this.state.model };
		var { modelList } = this.state;
		let hasWarnedOnce = false;
		if (model.DigitalIdentityVerificationId && model.ParameterValue == KYCManualApplicationStatus.Approved) {
			let confirmResult = await this.props.showConfirmMessage("warning", "Warning", "Digital Verification is already completed. If you want to do this status will be set as incompleted when the process is completed successfully. Are you sure?");
			if (!confirmResult) {
				return;
			}
			hasWarnedOnce = true;
		}
		if (model.DigitalIdentityVerificationId && !hasWarnedOnce && model?.LastDigitalVerificationLinkId > 0) {
			let confirmResult = await this.props.showConfirmMessage("warning", "Warning", "Last Digital Verification status will be reset when the process is completed successfully. Are you sure?");
			if (!confirmResult) {
				return;
			}
		}

		var result = action && await action(model);
		if (!result) {
			return;
		}

		modelList = modelList.map(item => {
			if (item.Id == model.Id) {
				item.DigitalIdentityVerificationId = null;
				item.DigitalIdentityVerificationStatus = false;
				item.DigitalIdentityVerificationRequestStatus = "Send";
				item.ParameterValue = KYCManualApplicationStatus.OnHold;
				item.LastDigitalVerificationLinkId = model?.LastDigitalVerificationLinkId;
			}
			return item;
		});
		model.DigitalIdentityVerificationId = null;
		model.DigitalIdentityVerificationStatus = false;
		model.DigitalIdentityVerificationRequestStatus = "Send";
		model.ParameterValue = KYCManualApplicationStatus.OnHold;
		this.setState({ model, modelList }, () => {
			this.GetDigitalIdentityInfo(model.Id);
		});
	}
	SendDigitalIdentityRequest = async () => {
		this.DigitalVerificationResetWrapper(async (model) => {
			var resultId = await this.props.ExecuteApiPost(`${KycApiUrl}SendDigitalVerification`, { KycManualId: model.Id });
			if (resultId > 0) {
				this.props.showMessage("success", "Success", "Identity Photo request sent successfully for Digital Verification");
				model.LastDigitalVerificationLinkId = resultId;
				return true;
			}
			return false;
		});
	}
	SendEmailForInPersonIdVerificationStatus = async () => {
		const { showMessage } = this.props;
		var model = { ...this.state.model };
		var result = await this.props.ExecuteApiPost(`${KycApiUrl}SendEmailInPersonIdVerificationStatus`, { Id: model.Id, Email: model.Email });

		if (!result) {
			showMessage("error", "Error", "Email not sent.");
			return;
		}
		showMessage("success", "Success", "Email sent.");
		model.IdVerificationEmailStatus = true;
		this.setState({ model });

	}

	GetCreditHistory = async (Id, responseData) => {
		var creditHistoryModel = {};
		const model = { ...this.state.model };
		if (responseData != undefined) {
			model.CreditHistoryVerificationStatus = responseData;
		}
		var result = await this.props.ExecuteApiPost("/amlkycapi/v1.0/KycManualApplication/GetByApplicantId", { Id: Id });


		if (result != null) {
			if (result.CreditHistory) {
				Object.assign(creditHistoryModel, result.CreditHistory);
			}
			if (result.CreditHistoryDetails) {
				creditHistoryModel.CreditHistoryDetails = result.CreditHistoryDetails;
			}
		}

		if (creditHistoryModel == null || creditHistoryModel.CreditHistoryDetails == null) {
			if (responseData != undefined) {
				this.props.showMessage("warning", "Warning", "Credit data not found for the Manual KYC Application");
			}
		}
		this.setState({ model, creditHistoryModel });


	}
	HandleClear = async (skipWarning) => {
		if (skipWarning !== true && !await this.props.showConfirmMessage("warning", "Clear", "This will erase the information you entered on the screen. Are you sure you want to quit this Definition ?")) return;


		const model = {
			InPersonIDVerificationEnable: false,
			CanadaPostVerificationEnable: false,
			Id: 0,
			SelectedIdentityVerificationType: []
		};
		this.panelclose(true, false, false, false, false, false, false);
		this.setState({ model, modelList: [], creditHistoryModel: {}, verificationResultModel: {}, manualKycDocumentList: [], IdVerificationTypeListForMultipleSelect: [...this.state.IdVerificationTypeList] });

	}

	GetGenderList = async () => {
		var result = await this.props.ExecuteApiPost("/coreapi/v1.0/Parameter/Search", { ParameterCode: "Gender" });

		this.setState({ genderList: result || [] });
	}
	GetIdTypeList = async () => {
		var result = await this.props.ExecuteApiPost("/amlkycapi/v1.0/DigitalIdentityVerificationIdentityType/SearchParameter");
		var idTypeList = result || [];
		var mappedList = idTypeList.map((item, index) => {
			item.Id = index + 1;
			return item;
		});
		await this.setStateAsync({ idTypeList: mappedList });
	}

	RenderIdentityTypeSelect = (item) => {

		var str = item.Name;
		if (item.IdentitySourceType.ParameterValue == IdentitySourceType.DigitalIdentityVerificationIdentityType && item.HasRecognized) {
			str += "+";
		}
		return str;
	}

	GetFilteredIdTypeListByLocation = (newModel) => {
		const { model: m, idTypeList, identityDocumentList } = this.state;

		const model = newModel || m;
		var availableIdentityDocumentList = identityDocumentList.filter(a => (!a.CountryId || !model.IdentityCountryId || a.CountryId == model.IdentityCountryId) && (!a.ProvinceId || !model.IdentityProvinceId || a.ProvinceId == model.IdentityProvinceId));

		var mappedList = idTypeList.map(a => {
			a.HasRecognized = availableIdentityDocumentList.find(b => b.Type == a.Type) != null;
			return a;
		});
		return mappedList;
	}
	GetFilteredIdTypeListForSelect = (newModel) => {

		const { identityDocumentList } = this.state;
		var canadaCountryId = identityDocumentList.find(a => a.CountryValue == "CA")?.CountryId;
		var list = this.GetFilteredIdTypeListByLocation(newModel);
		if (newModel.IdentityCountryId > 0 && canadaCountryId != newModel.IdentityCountryId) {
			list = list.filter(a => a.IdentitySourceType.ParameterValue == IdentitySourceType.DigitalIdentityVerificationIdentityType);
		}
		return list;
	}
	GetReasonTypeList = async () => {
		var result = await this.props.ExecuteApiPost("/coreapi/v1.0/Parameter/Search", { ParameterCode: KycManualApplicationReasonType.CODE });
		this.setState({ reasonTypeList: result || [] });
	}

	GetIdentitySourceTypeList = async () => {
		var result = await this.props.ExecuteApiPost("/coreapi/v1.0/Parameter/Search", { ParameterCode: IdentitySourceType.CODE });

		this.setState({ identitySourceTypeList: result || [] });
		return result;
	}
	inPersonVerifyOnClick = async () => {
		var model = { ...this.state.model };

		if (!await this.props.showConfirmMessage("warning", "Warning", "Are you sure you have verified this person?")) return;

		var result = await this.props.ExecuteApiPost(`${KycApiUrl}InPersonIdVerificationUpdateStatus`, { Id: model.Id });
		if (result === true || result === false) {
			model.IdVerificationStatus = true;
			model.IdentityVerifiedPersonId = this.clientUser.Id;
			model.User = this.clientUser;
			var multiSelectResponse = this.GetIdVerificationTypeListForMultipleSelect(model.SelectedIdentityVerificationType, model);
			this.setState({ model, IdVerificationTypeListForMultipleSelect: multiSelectResponse.list });
			this.props.showMessage("success", "Success", "Verified Successfully!");
		}

	}

	UpdateSelectedVerificationMethodList = async (newModel) => {
		var oldModel = this.state.model;

		var oldSelected = oldModel.IdentityVerificationTypeIdList || [];
		var newSelected = newModel.IdentityVerificationTypeIdList || [];

		if (newModel.Id > 0 && !ArrayHelper.IsArraysEqual(oldSelected, newSelected)) {
			await this.props.ExecuteApiPost(`${KycApiUrl}UpdateVerificationList`, { Id: newModel.Id, IdentityVerificationTypeIdList: newModel.IdentityVerificationTypeIdList });
		}
	}


	HandlePopupManualUpload = () => {
		this.DigitalVerificationResetWrapper(async (model) => {
			model.Code = DigitalVerificationPopupUploadCode.ManualKyc;
			localStorage.setItem(DigitalVerificationAction.PopupUpload, JSON.stringify(model));
			this.popupUploadWindow = window.open("/#/out/KycPhotoUpload", "_blank", "toolbar=no,scrollbars=no,resizable=yes,top=100,left=100,width=1200,height=800");
			this.setState({ isOpenkycUploadWindow: true });
			var popupCloseAwaiter = new Promise((resolve) => {
				this.popupUploadWindow.onunload = () => {
					if (this.popupUploadWindow.location.href == "about:blank") {
						return;
					}
					var parsedId = Number.parseInt(localStorage.getItem(DigitalVerificationAction.PopupUpload));
					if (this.popupUploadWindow?.closed && parsedId > 0) {
						this.GetDigitalIdentityInfo(model.Id);
						resolve(parsedId);
					}
					else {
						resolve(false);
					}
					localStorage.removeItem(DigitalVerificationAction.PopupUpload);
					this.popupUploadWindow = null;
					this.setState({ isOpenkycUploadWindow: false });
				};
			});

			var result = await popupCloseAwaiter;
			if (result) {
				this.props.showMessage("success", "Success", "Photos successfully updated!");
				model.LastDigitalVerificationLinkId = result;

			}
			return result;
		});
	}

	GetManualKycDocumentModels = async (stateModel) => {
		const model = stateModel || this.state.model;
		const { ExecuteApiPost } = this.props;

		var result = await ExecuteApiPost("/amlkycapi/v1.0/KycManualApplicationDocument/GetByManualKycId", { KycManualApplicationId: model.Id });
		this.setState({ manualKycDocumentList: result || [] });
	}

	GetStickyVerificationPanel = () => {
		const { onStickContent, classes, ManualKycModel } = this.props;
		const { manualKycDocumentList, verificationDefinition, IdVerificationTypeList } = this.state;
		var model = this.state.model || ManualKycModel;
		var internalBlacklistStatus = (verificationDefinition.VerifyInternalBlacklist === false || model?.InternalBlacklistStatus === null || model?.InternalBlacklistStatus === undefined) ? "empty" : model.InternalBlacklistStatus ? "success" : "error";
		var watchListStatus = (verificationDefinition.VerifyExternalBlacklist === false || model?.WatchListVerificationStatus === null || model?.WatchListVerificationStatus === undefined) ? "empty" : model.WatchListVerificationStatus ? "success" : "error";
		var transUnionStatus = (verificationDefinition.VerifyTransUnion === false || model?.TransUnionIdVerificationStatus === null || model?.TransUnionIdVerificationStatus === undefined) ? "empty" : model.TransUnionIdVerificationStatus ? "success" : "error";
		var creditHistoryVerificationStatus = (model?.CreditHistoryVerificationStatus == null) ? "empty" : model.CreditHistoryVerificationStatus ? "success" : "error";
		var digitalIdVerificationStatus = (model.IsNotSupportedDigitalVerification || verificationDefinition.VerifyDigitalId === false || model?.DigitalIdentityVerificationStatus === null || model?.DigitalIdentityVerificationStatus === undefined) ? "empty" : model.DigitalIdentityVerificationStatus ? "success" : "error";
		var accountVerificationStatus = (verificationDefinition.VerifyAccountVerification === false || model?.AccountVerificationStatus === null || model?.AccountVerificationStatus === undefined) ? "empty" : model.AccountVerificationStatus ? "success" : "error";
		var documentsVerifyStatus = (!model?.Id || verificationDefinition.VerifyDocuments === false || isNaN(manualKycDocumentList?.length) || manualKycDocumentList.length == 0) ? "empty" : manualKycDocumentList.every(a => a?.VerificationStatus?.ParameterValue == KYCVerifiedStatus.Verified) ? "success" : "error";
		var canadaPostVerifyStatus = (verificationDefinition.VerifyCanadaPost === false || !model?.CanadaPostVerificationId) ? "empty" : (model?.CanadaPostVerification == CanadaPostIdVerificationStatus.Cleared ? "success" : (model.CanadaPostVerification == CanadaPostIdVerificationStatus.Denied || model.CanadaPostVerification == CanadaPostIdVerificationStatus.Expired) ? "error" : "warning");

		var InPersonIDVerificationTypeId = IdVerificationTypeList.find(a => a.ParameterValue == IdentificationMethod.InPersonIDVerification)?.Id;
		var hasSelectedInPersonVerificationType = model.IdentityVerificationTypeIdList && model.IdentityVerificationTypeIdList.find(a => a == InPersonIDVerificationTypeId);
		var inPersonIdVerifyStatus = (verificationDefinition.VerifyInPersonId === false || model?.IdVerificationStatus === null || model?.IdVerificationStatus === undefined || !hasSelectedInPersonVerificationType) ? "empty" : model.IdVerificationStatus ? "success" : "error";


		var component = (
			<Card style={ManualKycModel?.Id ? this.verificationPanelPopupStyle : this.verificationPanelStyle} className="no-radius">
				<CardBody>
					<div className={classes.verificationContainer}>
						<GenericVerificationComponent Label={"Internal Blacklist"} Status={internalBlacklistStatus} />
						<GenericVerificationComponent Label={"Watch List"} Status={watchListStatus} />
						<GenericVerificationComponent Label={"TransUnion ID"} Status={transUnionStatus} />
						<GenericVerificationComponent Label={"TransUnion Credit History"} Status={creditHistoryVerificationStatus} />
						<GenericVerificationComponent Label={"Digital ID Verification"} Status={digitalIdVerificationStatus} />
						<GenericVerificationComponent Label={"Wallet Verification"} Status={accountVerificationStatus} />
						<GenericVerificationComponent Label={"Documents"} Status={documentsVerifyStatus} />
						<GenericVerificationComponent Label={"Canada Post"} Status={canadaPostVerifyStatus} />
						<GenericVerificationComponent Label={"In Person"} Status={inPersonIdVerifyStatus} />
					</div>
				</CardBody>
			</Card>
		);
		if (onStickContent) {
			onStickContent(component, this.state.reRenderStickyPanel);

			this.state.reRenderStickyPanel && this.setState({ reRenderStickyPanel: false });
		}
		else {
			return component;
		}
	}
	GetFinishAction = (statusType) => async () => {
		if (statusType != KYCManualApplicationStatus.Approved) {
			const { reasonTypeList } = this.state;
			const model = { ...this.state.model };

			if (statusType == KYCManualApplicationStatus.Reject) {
				var rejectParameter = reasonTypeList.find(a => a.ParameterValue == KycManualApplicationReasonType.Reject);
				model.ReasonTypeId = rejectParameter.Id;
			}

			this.setState({ reasonDialogType: statusType, isReasonDialogOpen: true, model });
		}
		else {
			if (!await this.props.showConfirmMessage("warning", "Warning", "Are you sure you want to Approve this record?")) return;
			this.HandleClickStatusSubmit(statusType)();
		}
	}

	CheckAndGetModifiedPropsInternalBlacklist = () => {
		const { model, tempModel } = this.state;
		var properties = [];

		if (model.FirstName != tempModel.FirstName) {
			properties.push("First Name");
		}

		if (model.LastName != tempModel.LastName) {
			properties.push("Last Name");
		}

		if (model.BirthDate.diff(tempModel.BirthDate, "days") != 0) {
			properties.push("Birth Date");
		}
		return properties;

	}

	CheckAndGetModifiedPropsWatchList = () => {
		const { model, tempModel } = this.state;
		var properties = [];
		if (model.FirstName != tempModel.FirstName) {
			properties.push("First Name");
		}
		if (
			model.LastName != tempModel.LastName) {
			properties.push("Last Name");
		}
		if (model.BirthDate.diff(tempModel.BirthDate, "days") != 0) {
			properties.push("Birth Date");
		}
		if (model.CountryId != tempModel.CountryId) {
			properties.push("Country");
		}

		return properties;
	}

	CheckAndGetModifiedPropsTransUnion = () => {
		const { model, tempModel } = this.state;
		var properties = [];
		if (model.FirstName != tempModel.FirstName) {
			properties.push("First Name");
		}
		if (model.MiddleName != tempModel.MiddleName) {
			properties.push("Middle Name");
		}
		if (model.LastName != tempModel.LastName) {
			properties.push("Last Name");
		}
		if (model.Address1 != tempModel.Address1) {
			properties.push("Address 1");
		}
		if (model.Address2 != tempModel.Address2) {
			properties.push("Address 2");
		}
		if (model.CountryId != tempModel.CountryId) {
			properties.push("Country");
		}
		if (model.CityId != tempModel.CityId) {
			properties.push("City");
		}
		if (model.ProvinceId != tempModel.ProvinceId) {
			properties.push("Province");
		}
		if (model.ZipCode != tempModel.ZipCode) {
			properties.push("Zip Code");
		}
		return properties;
	}

	OperationCheckReason = () => {
		const model = this.state.model;
		const { reasonTypeList } = this.state;


		var vModel = {};
		var messages = [];
		if (!reasonTypeList.find(a => a.ParameterValue2 == "H" && a.Id == (model?.ReasonTypeId ?? 0))) {
			vModel.ReasonTypeId = true;
			messages.push("Reason type cannot be empty");
		}

		this.setState({ vModel });
		if (messages.length > 0) {
			this.props.showValidationErrors(messages);
			return false;
		}
		return true;
	}
	HandleClickStatusSubmit = (statusParameterValue) => async () => {

		const model = this.state.model;
		if (statusParameterValue == KYCManualApplicationStatus.OnHold && !this.OperationCheckReason()) {
			return;
		}
		var result1 = await this.props.ExecuteApiPost(`${KycApiUrl}KycManualFinished`,
			{
				Id: model.Id,
				StatusParameterValue: statusParameterValue,
				ReasonTypeId: model?.ReasonTypeId,
				ReasonComment: model?.ReasonComment
			});
		if (result1) {
			this.ReasonDialogAction(false)();
			await this.props.showMessage("success", "Success", "Kyc Manual Application Status saved successfully");
		}
		else {
			this.props.showMessage("error", "Error", "Kyc Manual Appliction Status save error");
			return;
		}

		const model1 = {
			InPersonIDVerificationEnable: false,
			CanadaPostVerificationEnable: false,
			CreditValidationStatus: result1 > 0,
			Id: 0,
		};
		var inquiryValidationStatus = "error";
		if (result1) {
			inquiryValidationStatus = "success";
		}
		this.setState({ model: model1, modelList: [], creditHistoryModel: {}, inquiryValidationStatus });

	}
	ReasonDialogAction = (openOrClose) => () => {
		this.setState({ isReasonDialogOpen: openOrClose });
	}
	GetReasonTypeParameterById = (id) => {
		const { reasonTypeList } = this.state;
		return reasonTypeList.find(a => a.Id == id);
	}

	GetReasonTypePopupComponent = (reasonType) => {
		const { model, vModel, isReasonDialogOpen, reasonTypeList } = this.state;
		const filteredReasonTypeList = reasonTypeList.filter(a => a.ParameterValue2 == (reasonType == KYCManualApplicationStatus.Reject ? "R" : "H"));

		var title = reasonType == KYCManualApplicationStatus.OnHold ? "On Hold Status" : "Reject Status";
		return (
			<GenericDialog Overflow={true} maxWidth="sm" fullWidth open={isReasonDialogOpen} style={this.overflowVisibleStyle} >
				<DialogTitle>
					<GenericLabel Text={title} Bold={true} />
				</DialogTitle>
				<DialogContent style={this.overflowVisibleStyle}>
					<GridContainer>
						<GridItem xs={12}>
							<GenericSelectInput
								IsStatic
								StaticData={filteredReasonTypeList}
								Name="ReasonTypeId"
								LabelText="Reason Type"
								DataRoot="Item"
								KeyValueMember="Id"
								TextValueMember="ParameterDesc"
								Value={model.ReasonTypeId}
								ValueChanged={this.HandleChange}
								IsInvalid={vModel.ReasonTypeId}
								Disabled={reasonType == KYCManualApplicationStatus.Reject}
								Required
							/>
						</GridItem>
						<GridItem xs={12}>
							<GenericTextInput
								Name="ReasonComment"
								LabelText="Additional Reason Comment"
								MultiLine={true}
								Value={model?.ReasonComment}
								ValueChanged={this.HandleChange}
							/>
							<br />
							<br />
						</GridItem>
						<GridItem xs={6}>
							<GenericLabel Bold={true} Text={`User : ${model?.Email}`} />
						</GridItem>
						<GridItem xs={6}>
							<GenericLabel Bold={true} Text={`Date : ${Formatter.FormatDate(DateHelper.GetDateTimeLocal())}`} />
						</GridItem>
					</GridContainer>
					<GenericDialogActions>
						<Button size="sm" onClick={this.HandleClickStatusSubmit(reasonType)}>Submit</Button>
						<Button size="sm" onClick={this.ReasonDialogAction(false)}>Cancel</Button>
					</GenericDialogActions>
				</DialogContent>
			</GenericDialog>
		);
	}


	AccountVerificationResetWrapper = async (action) => {
		let model = { ...this.state.model };
		var { modelList } = this.state;
		let hasWarnedOnce = false;
		if (model.CustomerLinkedAccountId > 0 && model.ParameterValue == KYCManualApplicationStatus.Approved) {
			let confirmResult = await this.props.showConfirmMessage("warning", "Warning", "Wallet Verification is already completed. If you want to do this status will be set as incompleted when the process is completed successfully. Are you sure?");
			if (!confirmResult) {
				return;
			}
			hasWarnedOnce = true;
		}
		if (model.CustomerLinkedAccountId > 0 && !hasWarnedOnce && model?.LastAccountVerificationLinkId > 0) {
			let confirmResult = await this.props.showConfirmMessage("warning", "Warning", "Last Wallet Verification status will be reset when the process is completed successfully. Are you sure?");
			if (!confirmResult) {
				return;
			}
		}

		var result = action && await action(model);
		if (!result) {
			return;
		}

		modelList = modelList.map(item => {
			if (item.Id == model.Id) {
				item.CustomerLinkedAccountId = null;
				item.AccountVerification = "Invalid";
				item.AccountVerificationStatus = false;
				item.AccountVerificationRequestStatus = "Send";
				item.ParameterValue = KYCManualApplicationStatus.OnHold;
				item.LastAccountVerificationLinkId = model?.LastAccountVerificationLinkId;
			}
			return item;
		});
		model.CustomerLinkedAccountId = null;
		model.AccountVerification = "Invalid";
		model.AccountVerificationStatus = false;
		model.AccountVerificationRequestStatus = "Send";
		model.ParameterValue = KYCManualApplicationStatus.OnHold;
		model.CustomerLinkedAccount = null;
		this.setState({ model, modelList });
	}

	SendAccountVerificationLinkRequest = () => {
		this.AccountVerificationResetWrapper(async (model) => {
			var resultId = await this.props.ExecuteApiPost(`${KycApiUrl}SendAccountVerification`, { KycManualId: model.Id });
			if (resultId > 0) {
				this.props.showMessage("success", "Success", "Wallet Verification request sent successfully");
				model.LastAccountVerificationLinkId = resultId;
				return true;
			}
			return false;
		});
	}

	GetAccountVerificationComponent = () => {
		const { model, modelList } = this.state;
		const { Disabled: disabledProp } = this.props;

		var originalModel = modelList.find(a => a?.Id == model?.Id);
		var isRejected = originalModel?.ReasonTypeId > 0 && (this.GetReasonTypeParameterById(originalModel?.ReasonTypeId)?.ParameterValue == KycManualApplicationReasonType.Reject);
		var Disabled = disabledProp || isRejected;
		return (
			<FlinksAccountPreviewCard
				Model={model?.CustomerLinkedAccount}
				GenericExpansionPanelProps={{
					Name: "panelAccountVerificationActive",
					Title: "Wallet Verification",
					IsActive: this.state.panelActivationStatus.panelAccountVerificationActive,
					ValueChanged: this.HandleChangeFilterPanel
				}}
				Top={
					<>
						<GridItem xs={8}>
							<GridContainer justify="flex-start">
								<GridItem xs={2}>
									<Button
										size="sm"
										color="black"
										fullWidth
										disabled={Disabled || !model.Id}
										onClick={this.SendAccountVerificationLinkRequest}>
										{(model?.LastAccountVerificationLinkId > 0) ? "Res" : "S"}end Link</Button>
								</GridItem>
							</GridContainer>
						</GridItem>
						<GridItem xs={4}></GridItem>
					</>
				}
				Bottom={
					<>
						<GridItem xs={11}>
						</GridItem>
						<GridItem xs={1}>
							<Button size="sm" color="black" fullWidth
								disabled={Disabled}
								onClick={this.OperationStep4}>Next</Button>
						</GridItem>
					</>
				}
			/>
		);
	}
	render() {
		const { vModel, modelList, reasonDialogType, genderList, rendererNumber, isOpenkycUploadWindow, verificationResultModel, IdVerificationTypeListForMultipleSelect, isModifiedInformationCard } = this.state;
		const { Disabled: disabledProp, ManualKycModel } = this.props;
		var model = { ...this.state.model };
		var originalModel = modelList.find(a => a?.Id == model?.Id);
		var isRejected = originalModel?.ReasonTypeId > 0 && (this.GetReasonTypeParameterById(originalModel?.ReasonTypeId)?.ParameterValue == KycManualApplicationReasonType.Reject);
		var Disabled = disabledProp || isRejected;

		var ForInsert = !model?.Id;

		var canadaPostHasFinished = model?.CanadaPostVerificationId > 0 && (model?.CanadaPostVerification == CanadaPostIdVerificationStatus.Cleared || model?.CanadaPostVerification == CanadaPostIdVerificationStatus.Denied);
		var isExpiredCanadaPostVerification = model?.CanadaPostVerificationId > 0 && (model?.CanadaPostVerification == CanadaPostIdVerificationStatus.Expired);

		return (
			<div>
				{!disabledProp &&
					<ButtonToolbar
						ButtonList={[
							{
								Code: "Search",
								Disabled: disabledProp,
								OnClick: this.HandleSearch
							},
							{
								Code: "Clear",
								Disabled: disabledProp,
								OnClick: this.HandleClear
							},
							{
								Code: "Delete",
								Disabled: ForInsert,
								OnClick: this.Delete
							}
						]}
						menuId={this.props.menuId}
						ApprovalData={this.props.ApprovalData}
						After={this.props.After} />
				}
				<GridContainer>
					{this.GetReasonTypePopupComponent(reasonDialogType)}
					<GridItem xs={12}>
						{this.GetStickyVerificationPanel()}
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel
									Name={"panelApplicantInformationActive"}
									Title={"Manual KYC Application"}
									IsActive={this.state.panelActivationStatus.panelApplicantInformationActive}
									ValueChanged={this.HandleChangeFilterPanel}>
									<GridContainer
										direction="row"
										justify="flex-start"
										alignItems="stretch">
										<GridItem xs={12}>
											<GridContainer direction="row" justify="flex-start" alignItems="stretch">
												<GridItem xs={12}>
													<GridContainer direction="row" justify="flex-start" alignItems="flex-start"	>
														<GridItem xs={6}>
															<GenericTitle text={"General Information"} />
															<div>
																<GridItem >
																	<GenericTextInput
																		Name="FirstName"
																		inputProps={this.ParameterMaxLength30}
																		IsText
																		LabelText="First Name"
																		Value={model.FirstName}
																		ValueChanged={this.HandleChange}
																		IsInvalid={vModel.FirstName}
																		Required
																		Disabled={Disabled}
																	/>
																</GridItem>
																<GridItem >
																	<GenericTextInput
																		Name="MiddleName"
																		inputProps={this.ParameterMaxLength30}
																		IsText
																		LabelText="Middle Name"
																		Value={model.MiddleName}
																		ValueChanged={this.HandleChange}
																		Disabled={Disabled}
																	/>
																</GridItem>
																<GridItem >
																	<GenericTextInput
																		Name="LastName"
																		inputProps={this.ParameterMaxLength30}
																		IsText
																		LabelText="Last Name"
																		Value={model.LastName}
																		ValueChanged={this.HandleChange}
																		Required
																		IsInvalid={vModel.LastName}
																		Disabled={Disabled}
																	/>
																</GridItem>
																<GridItem >
																	<GenericDateInput
																		Utc
																		Name="BirthDate"
																		LabelText="Date of Birth"
																		Value={model.BirthDate == null ? "" : DateHelper.ToDateInputValue(model.BirthDate) || ""}
																		ValueChanged={this.HandleChange}
																		IncludeTime={false}
																		MaxDate={moment().subtract((18 * 365), "days")}
																		Required
																		IsInvalid={vModel.BirthDate}
																		Disabled={Disabled}
																	/>
																</GridItem>
																<GridItem >
																	<GenericSelectInput
																		IsStatic
																		StaticData={genderList}
																		Name="GenderId"
																		LabelText="Gender"
																		DataRoot="Item"
																		KeyValueMember="Id"
																		TextValueMember="ParameterDesc"
																		Value={model.GenderId}
																		ValueChanged={this.HandleChange}
																		IsInvalid={vModel.GenderId}
																		Required
																		Disabled={Disabled}
																	/>
																</GridItem>
															</div>
														</GridItem>
														<GridItem xs={6}>
															<GenericTitle text={"ID Verification"} />
															<div>
																<GridItem>
																	<GenericSelectInput
																		Name="IdentityCountryId"
																		LabelText="Identity Country"
																		Method="POST"
																		Url="/coreapi/v1.0/Country/GetAll"
																		Parameter={{}}
																		DataRoot="Item"
																		KeyValueMember="Id"
																		TextValueMember="Name"
																		Value={model.IdentityCountryId}
																		ValueChanged={this.HandleChange}
																		Required
																		IsInvalid={vModel.IdentityCountryId}
																		Disabled={Disabled}
																	/>
																</GridItem>
																<GridItem>
																	<GenericSelectInput
																		key={model.IdentityCountryId + "IdentityProvinceId"}
																		Name="IdentityProvinceId"
																		LabelText="Identity Province"
																		Method="POST" Url="/coreapi/v1.0/Province/Filter"
																		Parameter={{ CountryId: model.IdentityCountryId }} DataRoot="Item"
																		KeyValueMember="Id"
																		TextValueMember="Name"
																		Value={model.IdentityProvinceId}
																		ValueChanged={this.HandleChange}
																		Required
																		IsInvalid={vModel.IdentityProvinceId}
																		Disabled={Disabled}
																	/>
																</GridItem>
																<GridItem>
																	<GenericSelectInput
																		IsStatic
																		StaticData={this.GetFilteredIdTypeListForSelect(model)}
																		Name="IdentityTypeIndex"
																		LabelText="ID Type"
																		DataRoot="Item"
																		KeyValueMember="Id"
																		TextValueMember="ParameterDesc"
																		RenderItem={this.RenderIdentityTypeSelect}
																		Value={model.IdentityTypeIndex}
																		ValueChanged={this.HandleChange}
																		Required
																		IsInvalid={vModel.IdentityTypeId}
																		Disabled={Disabled}
																		CanClear={false}
																	/>
																</GridItem>
																<GridItem>
																	<GenericTextInput
																		inputProps={this.maxLength20}
																		Name="IdentityNumber"
																		LabelText="ID Number"
																		Value={model.IdentityNumber}
																		ValueChanged={this.HandleChange}
																		Required
																		IsInvalid={vModel.IdentityNumber}
																		Disabled={Disabled}

																	/>
																</GridItem>
															</div>
														</GridItem>
													</GridContainer>
												</GridItem>
												<GridItem xs={12}>
													<GridContainer
														direction="row"
														justify="flex-start"
														alignItems="flex-start">
														<GridItem xs={6}>
															<GenericTitle text={"Communication Information"} />
															<div>
																<GridItem>
																	<GenericEmailInput
																		Name="Email"
																		LabelText="E-Mail"
																		inputProps={this.ParameterMaxLength64}
																		Value={model.Email}
																		ValueChanged={this.HandleChange}
																		Required
																		IsInvalid={vModel.Email}
																		Disabled={Disabled}
																	/>
																</GridItem>
																<GridItem>
																	<GridContainer>
																		<GridItem xs={8}>
																			<GenericSelectInput
																				Name="MobilePhoneCountryCodeId"
																				LabelText="Phone Number"
																				TitleLabel="Country Code"
																				LabelMd={6}
																				Method="POST"
																				Url="/coreapi/v1.0/Country/GetAll"
																				Parameter={this.emptyObject}
																				DataRoot="Item"
																				KeyValueMember="Id"
																				TextValueMember={"Code"}
																				Value={model.MobilePhoneCountryCodeId}
																				ValueChanged={this.HandleChange}
																				Sorted={{ Member: "OrderIndex" }}
																				RenderItem={d => "+" + d.Code + " (" + d.Name + ")"}
																				Required
																				IsInvalid={vModel.MobilePhoneCountryCodeId}
																				Disabled={Disabled}
																			/>
																		</GridItem>
																		<GridItem xs={4} style={{ paddingRight: 2 + "px !important" }}>
																			<GenericNumberInput
																				IsPhone
																				Name="MobilePhoneNumber"
																				Format="(###) ### ####"
																				InputStyle={this.marginLeft2}
																				LabelMd={0}
																				Value={model.MobilePhoneNumber}
																				ValueChanged={this.HandleChange}
																				MaxLength={10}
																				Required
																				IsInvalid={vModel.MobilePhoneNumber}
																				Disabled={Disabled} />
																		</GridItem>
																	</GridContainer>
																</GridItem>
																<GridItem>
																	<div></div>
																</GridItem>
															</div>
														</GridItem>
														<GridItem xs={6}>
															<GenericTitle text={"Address Information"} />
															<div>
																<GridItem>
																	<GenericTextInput
																		Name="Address1"
																		LabelText="Address Line 1"
																		Id="street-address"
																		Value={model.Address1}
																		ValueChanged={this.HandleChange}
																		inputProps={this.maxLength100}
																		Required
																		IsInvalid={vModel.Address1}
																		Disabled={Disabled} />
																</GridItem>
																<GridItem>
																	<GenericTextInput
																		Name="Address2"
																		Id="street-address2"
																		LabelText="Address Line 2"
																		Value={model.Address2}
																		ValueChanged={this.HandleChange}
																		inputProps={this.maxLength100}
																		Disabled={Disabled} />
																</GridItem>
																<GridItem>
																	<GenericTextInput
																		Name="ZipCode"
																		LabelText="Postal Code"
																		Id="postcode"
																		Value={model.ZipCode}
																		ValueChanged={this.HandleChange}
																		inputProps={this.maxLength20}
																		Required
																		IsInvalid={vModel.ZipCode}
																		Disabled={Disabled} />
																</GridItem>
																<GridItem>
																	<GenericSelectInput
																		Name="CountryId"
																		LabelText="Country"
																		Method="POST"
																		Url="/coreapi/v1.0/Country/GetAll"
																		Parameter={{}}
																		DataRoot="Item"
																		KeyValueMember="Id"
																		TextValueMember="Name"
																		Value={model.CountryId}
																		ValueChanged={this.HandleChange}
																		Required
																		IsInvalid={vModel.CountryId}
																		Disabled={Disabled} />
																	<GenericSelectInput
																		key={model.CountryId + "province"}
																		Name="ProvinceId"
																		LabelText="Province"
																		Method="POST" Url="/coreapi/v1.0/Province/Filter"
																		Parameter={{ CountryId: model.CountryId }} DataRoot="Item"
																		KeyValueMember="Id"
																		TextValueMember="Name"
																		Value={model.ProvinceId}
																		ValueChanged={this.HandleChange}
																		Required
																		IsInvalid={vModel.ProvinceId}
																		Disabled={Disabled} />
																	<GenericSelectInput
																		key={"city" + model.ProvinceId + ""}
																		Name="CityId"
																		LabelText="City"
																		Method="POST"
																		Url="/coreapi/v1.0/City/Filter"
																		Parameter={{ ProvinceId: model.ProvinceId }}
																		DataRoot="Item"
																		KeyValueMember="Id"
																		TextValueMember="Name"
																		Value={model.CityId}
																		ValueChanged={this.HandleChange}
																		Required
																		IsInvalid={vModel.CityId}
																		Disabled={Disabled} />
																</GridItem>
															</div>
														</GridItem>
													</GridContainer>
												</GridItem>
											</GridContainer>
										</GridItem>
										<GridItem xs={11}>
										</GridItem>
										<GridItem xs={1}>
											<Button size="sm" color="black" fullWidth
												disabled={Disabled}
												onClick={this.OperationStep1}>Next</Button>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius" >
							<CardBody>
								<GenericExpansionPanel
									Name={"panelVerificationActive"}
									Title={"Verifications"}
									IsActive={this.state.panelActivationStatus.panelVerificationActive}
									ValueChanged={this.HandleChangeFilterPanel}>
									<GridContainer
										direction="row"
										justify="flex-start"
										alignItems="stretch">
										<GridItem xs={12}>
											<GridContainer
												direction="row"
												justify="flex-start"
												alignItems="stretch">
												<GridItem xs={12}>
													<GridContainer direction="row" justify="flex-start" alignItems="flex-start">
														<GridItem xs={4}>
															<GenericMultipleSelectInput
																LabelMd={5}
																DisableGroup={true}
																Name={"SelectedIdentityVerificationType"}
																Loading={false}
																LabelText={"Verification Type"}
																KeyValueMember="Id"
																SelectValue="Id"
																Selected={model?.SelectedIdentityVerificationType}
																HandleChange={this.HandleChange}
																HandleChangeMenu={this.HandleChangeMenu}
																RenderItem={this.RenderMultiSelect}
																StaticData={IdVerificationTypeListForMultipleSelect}
																Disabled={Disabled || (canadaPostHasFinished && model?.DigitalIdentityVerificationStatus) || isModifiedInformationCard} />
														</GridItem>
														{model.InPersonIDVerificationEnable &&
															<GridContainer
																direction="row"
																justify="flex-start"
																alignItems="stretch">
																<GridItem xs={12}>
																	<GridContainer direction="row" justify="flex-start" alignItems="flex-start">
																		<GridItem xs={4}>
																			<GenericTextInput
																				Name="IdentityVerifiedPersonId"
																				Value={
																					model?.IdentityVerifiedPerson == null ?
																						this.clientUser.FirstName + " " + this.clientUser.LastName
																						:
																						model.IdentityVerifiedPerson.FullName
																				}
																				LabelText="&nbsp;Verified Person"
																				ValueChanged={this.HandleChange}
																				LabelMd={5}
																				Disabled={true} />
																		</GridItem>
																		{(!model.IdVerificationEmailStatus && !model.IdVerificationStatus) &&
																			<GridItem xs={1}>
																				<Button size="sm" color="black" fullWidth
																					disabled={Disabled || (model == null || model.Id == null || model.Id == 0) || isModifiedInformationCard}
																					onClick={this.SendEmailForInPersonIdVerificationStatus}>Send Email</Button>
																			</GridItem>
																		}
																		{(model.IdVerificationStatus || model.IdVerificationEmailStatus) &&
																			<GridItem>
																				<GridContainer direction="row" justify="flex-start" alignItems="flex-start">
																					<GridItem xs={12}>
																					</GridItem>
																					<GridItem xs={8}>
																						<Button size="sm" color="black" fullWidth disabled={Disabled || model.IdVerificationStatus || isModifiedInformationCard}
																							onClick={this.inPersonVerifyOnClick}>{model.IdVerificationStatus === true ? "Verified" : "Verify"}</Button>
																					</GridItem>
																				</GridContainer>
																			</GridItem>
																		}
																	</GridContainer>
																</GridItem>
															</GridContainer>
														}
														{model.CanadaPostVerificationEnable &&
															<GridItem xs={8}>
																<GridContainer direction="row" justify="flex-start" alignItems="flex-start">
																	{(model.IsRequiredProofOfResidence) &&
																		<GridItem md={4}>
																			<ParameterComponent
																				Required={model.IsRequiredProofOfResidence}
																				IsInvalid={vModel.ProofOfResidenceId}
																				Disabled={Disabled || (originalModel?.ProofOfResidenceId > 0 && model?.CanadaPostVerificationId > 0) || model?.IdVerificationStatus || isModifiedInformationCard}
																				Name="ProofOfResidenceId"
																				LabelText="Proof of Residence"
																				ParameterCode="ProofOfResidence"
																				Value={model.ProofOfResidenceId}
																				ValueChanged={this.HandleChange} />
																		</GridItem>
																	}
																	{(isNaN(model?.CanadaPostVerificationId) || model?.CanadaPostVerificationId <= 0 || isExpiredCanadaPostVerification) &&
																		<GridItem md={2}>
																			<Button size="sm" color="black" fullWidth
																				disabled={Disabled || !model.Id || isModifiedInformationCard}
																				onClick={this.SaveCanadaPostRequest}>Verification Request</Button>
																		</GridItem>
																	}
																	{(model?.CanadaPostVerificationId > 0 && !isExpiredCanadaPostVerification) &&
																		<GridItem xs={6}>
																			<GridContainer direction="row" justify="flex-start" alignItems="flex-start">
																				<GridItem xs={1}>
																					<Button size="sm" color="black" fullWidth
																						disabled={Disabled || canadaPostHasFinished || isModifiedInformationCard}
																						onClick={this.GetHandleCanadaPostCodeFunc("E")}>Email</Button>
																				</GridItem>
																				<GridItem xs={1}>
																					<Button size="sm" color="black" fullWidth
																						disabled={Disabled || canadaPostHasFinished || isModifiedInformationCard}
																						onClick={this.GetHandleCanadaPostCodeFunc("D")}>PDF</Button>
																				</GridItem>
																			</GridContainer>
																		</GridItem>
																	}
																</GridContainer>
															</GridItem>
														}
														{!model.IsNotSupportedDigitalVerification && model.DigitalIdentityVerificationEnable &&
															<>
																{model.CanadaPostVerificationEnable && <GridItem xs={4}></GridItem>}
																<GridItem xs={8}>
																	<GridContainer>
																		<GridItem md={2}>
																			<Button
																				size="sm"
																				color="black"
																				fullWidth
																				onClick={this.SendDigitalIdentityRequest}
																				disabled={Disabled || !model.Id || isModifiedInformationCard}>
																				{(model?.LastDigitalVerificationLinkId > 0) ? "Res" : "S"}end Link</Button>
																		</GridItem>
																		<GridItem md={2}>
																			<Button
																				size="sm"
																				color="black"
																				fullWidth
																				onClick={this.HandlePopupManualUpload}
																				disabled={Disabled || !model.Id || isOpenkycUploadWindow || isModifiedInformationCard}>
																				Manual Photo Upload</Button>
																		</GridItem>
																		<GridItem md={2}>
																			<Button
																				size="sm"
																				color="black"
																				fullWidth
																				onClick={this.VerifyDigitalIdentity}
																				disabled={Disabled || model.ParameterValue == KYCManualApplicationStatus.Approved || (!model?.IdentityPhotoId || !model?.UserPhotoId) || isModifiedInformationCard}>
																				Verify Identity</Button>
																		</GridItem>
																		<GridItem md={2}>
																			<Button
																				size="sm"
																				color="black"
																				fullWidth
																				onClick={this.CompleteDigitalIdentityVerified}
																				disabled={Disabled || model.ParameterValue == KYCManualApplicationStatus.Approved || !model.DigitalIdentityVerificationId || model.DigitalIdentityVerificationStatus || !model?.IdentityPhotoId || !model?.UserPhotoId || isModifiedInformationCard}																							>
																				{model.DigitalIdentityVerificationStatus ? "Verified" : "Verify"}</Button>
																		</GridItem>
																	</GridContainer>
																</GridItem>

															</>
														}
														<GridItem xs={12}>
															<br />
															<br />
															{this.state.verificationDefinition.VerifyDigitalId !== false && model.IsNotSupportedDigitalVerification && <GenericLabel Text="Digital Verification is not supported for the selected ID Type" />}
														</GridItem>
													</GridContainer>
												</GridItem>
											</GridContainer>
										</GridItem>
										<GridItem xs={11}>
										</GridItem>
										<GridItem xs={1}>
											<Button size="sm" color="black" fullWidth
												disabled={Disabled || !model?.Id || isModifiedInformationCard}
												onClick={this.OperationStep2}>Next</Button>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						{!model.IsNotSupportedDigitalVerification && model.DigitalIdentityVerificationEnable &&
							<Card className="no-radius" >
								<CardBody  >
									<GenericExpansionPanel
										Name={"panelDigitalVerificationActive"}
										Title={"Digital Verification Status"}
										IsActive={this.state.panelActivationStatus.panelDigitalVerificationActive}
										ValueChanged={this.HandleChangeFilterPanel}>
										<DigitalVerificationPanel
											key={model.IdentityPhotoId + rendererNumber}
											ExecuteApiPost={this.props.ExecuteApiPost}
											Model={model}
											VerificationModel={model?.DigitalIdentityVerificationId > 0 && verificationResultModel} />
									</GenericExpansionPanel>
								</CardBody>
							</Card>
						}
						{model.ManualKycApplicationDocumentEnable &&
							<Card className="no-radius"  >
								<CardBody>
									<GenericExpansionPanel
										Name={"panelManualKycApplicationDocumentActive"}
										Title={"Documents"}
										IsActive={this.state.panelActivationStatus.panelManualKycApplicationDocumentActive}
										ValueChanged={this.HandleChangeFilterPanel}>
										<GridContainer
											direction="row"
											justify="flex-start"
											alignItems="stretch">
											<GridItem xs={12}>
												<ManualKycApplicationDocument
													ExecuteApiFileDownloadWithGenericResponse={this.props.ExecuteApiFileDownloadWithGenericResponse}
													ExecuteApiPost={this.props.ExecuteApiPost}
													ExecuteApiFileUpload={this.props.ExecuteApiFileUpload}
													showMessage={this.props.showMessage}
													showConfirmMessage={this.props.showConfirmMessage}
													ManualKycModel={this.state.model}
													ManualKycDocumentList={this.state.manualKycDocumentList}
													GetDocumentFunc={this.GetManualKycDocumentModels}
													Disabled={Disabled || ForInsert} />
											</GridItem>
											<GridItem xs={11}>
											</GridItem>
											<GridItem xs={1}>
												<Button size="sm" color="black" fullWidth
													disabled={Disabled || !model?.Id}
													onClick={this.OperationStep3}>Next</Button>
											</GridItem>
										</GridContainer>
									</GenericExpansionPanel>
								</CardBody>
							</Card>
						}
						{model.AccountVerificationEnable &&
							this.GetAccountVerificationComponent()
						}
						<Card className="no-radius" >
							<CardBody>
								<GenericExpansionPanel
									Name={"panelCreditValidationStatusActive"}
									Title={"Credit Validation Status"}
									IsActive={this.state.panelActivationStatus.panelCreditValidationStatusActive}
									ValueChanged={this.HandleChangeFilterPanel}>
									<GridContainer
										direction="row"
										justify="flex-start"
										alignItems="stretch">
										<GridItem xs={12}>
											<KycManualCreditValidation
												GetCreditHistory={this.GetCreditHistory}
												model={this.state.creditHistoryModel}
												Id={model.Id}
												Disabled={Disabled}
											/>
										</GridItem>
										<GridItem style={{ marginLeft: "auto" }}>
											<GridContainer direction="row"  >
												<GridItem xs={4}>
													<Button onClick={this.GetFinishAction(KYCManualApplicationStatus.Approved)} disabled={Disabled || ForInsert || originalModel?.ParameterValue == KYCManualApplicationStatus.Reject || originalModel?.ParameterValue == KYCManualApplicationStatus.Approved} fullWidth color="success">Approve</Button>
												</GridItem>
												<GridItem xs={4}>
													<Button onClick={this.GetFinishAction(KYCManualApplicationStatus.Reject)} disabled={Disabled || ForInsert || originalModel?.ParameterValue == KYCManualApplicationStatus.Reject} fullWidth color="youtube">Reject</Button>
												</GridItem>
												<GridItem xs={4}>
													<Button onClick={this.GetFinishAction(KYCManualApplicationStatus.OnHold)} disabled={Disabled || ForInsert || originalModel?.ParameterValue == KYCManualApplicationStatus.Reject} fullWidth color="warning">On Hold</Button>
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						{!ManualKycModel &&
							<Card className="no-radius">
								<CardBody>
									<GenericExpansionPanel
										Name={"panelApplicantListActive"}
										Title={"Manual KYC Application List"}
										IsActive={this.state.panelActivationStatus.panelApplicantListActive}
										ValueChanged={this.HandleChangeFilterPanel}>
										<GenericGrid
											Data={modelList}
											PageSize={5}
											HideButton={true}
											Sorted={[{ id: "Status", desc: false }]}
											Columns={this.ColumnsData}
											RowSelected={this.SelectedRowChange}
										/>
									</GenericExpansionPanel>
								</CardBody>
							</Card>}
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

ManualKycApplication.propTypes = {
	After: PropTypes.any,
	ApprovalData: PropTypes.any,
	Disabled: PropTypes.any,
	ExecuteApiFileDownloadWithGenericResponse: PropTypes.func.isRequired,
	ExecuteApiFileUpload: PropTypes.any.isRequired,
	ExecuteApiPost: PropTypes.func.isRequired,
	ExecuteApiGet: PropTypes.func.isRequired,
	taskPool: PropTypes.any.isRequired,
	ManualKycModel: PropTypes.any,
	MenuCode: PropTypes.any,
	classes: PropTypes.shape({
		verificationContainer: PropTypes.any
	}),
	menuId: PropTypes.any,
	setAppCenterTitle: PropTypes.func,
	setAppLeftTitle: PropTypes.func,
	onStickContent: PropTypes.func,
	showConfirmMessage: PropTypes.func.isRequired,
	showMessage: PropTypes.func.isRequired
};

export default withStyles(manualKycApplicationStyle)(ManualKycApplication);