import { DialogContent, DialogTitle, Slide } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar";
import { GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericLabel, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import ParameterComponent from "views/Components/ParameterComponent";
import { RoleLevel } from "views/Constants/Constant";
import { MaxLength128 } from "views/Constants/Constant.MaxLength";

function Transition(props) {
	return <Slide direction="up" {...props} />;
}
const emptyObject = {};
class UserRoleDefinition extends React.Component {
	constructor(props) {
		super(props);

		this.columns = [
			{
				Header: "Parent",
				accessor: "ParentRoleName"
			},
			{
				Header: "Role Name",
				accessor: "RoleName"
			},
			{
				Header: "Role Group",
				accessor: "RoleGroupParameterDesc"
			},
			{
				Header: "Role Level",
				accessor: "RoleLevelParameterDesc"
			},
			{
				Header: "OrganizationUniqueId",
				accessor: "OrganizationUniqueId",
				show: window.OrganizationUniqueId != 5000?false:true
			},

		];

		this.isClient = ClientHelper.IsClient();

		this.validateProperties = [{ "RoleName": "Role Name" }, { "RoleGroupId": "Role Group" }, { "RoleLevelId": "Role Level" }, { "OrganizationUniqueId": "OrganizationUniqueId" }];

		this.state = {
			model: {},
			isClientStandardUserOpen: false
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("USER ADMIN");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("User Role Definition");
		}

		var user = ClientHelper.GetUser();

		if (user.Role.RoleLevel.ParameterValue == RoleLevel.ClientStandardUser) {
			this.setState({ isClientStandardUserOpen: true });
		}

		this.GetRoleGroupClientRoleLevel();
	}

	ValueChanged = (name, value) => {
		this.setState(state => {
			var model = state.model;
			var list = state.list;
			var listRoleLevel = state.listRoleLevel;

			model[name] = value;

			if (name == "RoleGroupId") {
				if (value) {
					this.GetRoleListByRoleGroupId(value);
				}
				else {
					model["RoleLevelId"] = null;
					list = [];
					listRoleLevel = [];
				}
			}
			else if (name == "RoleLevelId") {
				model["ParentId"] = null;
			}

			return { model, list, listRoleLevel };
		});
	}

	RowSelected = (index) => {
		const { list } = this.state;

		this.GetRoleListByRoleGroupId(list[index].RoleGroupId);

		this.setState({ model: list[index], selected: index });
	}

	GetRoleGroupClientRoleLevel = async () => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/coreapi/v1.0/Parameter/Search", { ParameterCode: "RoleLevel" });

		if (result != null) {
			var roleLevelBackofficeSuperAdminId = result.filter(x => x.ParameterValue == RoleLevel.BackofficeSuperAdmin)[0].Id;
			var roleLevelClientAdminId = result.filter(x => x.ParameterValue == RoleLevel.ClientAdmin)[0].Id;
			var roleLevelClientStandartUserId = result.filter(x => x.ParameterValue == RoleLevel.ClientStandardUser)[0].Id;
			var user = ClientHelper.GetUser();
			var isBackofficeSuperAdmin = user.Role.RoleLevelId == roleLevelBackofficeSuperAdminId;
			var isClientAdmin = user.Role.RoleLevelId == roleLevelClientAdminId;

			if (isBackofficeSuperAdmin) {
				this.GetRoleClientAdmin(roleLevelClientAdminId);
			} else if (isClientAdmin) {
				var model = {
					RoleGroupId: user.Role.RoleGroupId,
					RoleLevelId: roleLevelClientStandartUserId
				};

				this.GetRoleListByRoleGroupId(model.RoleGroupId);

				this.setState({ model });
			}

			this.setState({ roleLevelBackofficeSuperAdminId, roleLevelClientAdminId, roleLevelClientStandartUserId, isBackofficeSuperAdmin, isClientAdmin });
		}
	}

	GetRoleClientAdmin = async (roleLevelClientAdminId) => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/coreapi/v1.0/Role/Search", { RoleLevelId: roleLevelClientAdminId });

		this.setState({ listRoleLevelClientAdmin: result || [] });
	}

	GetRoleListByRoleGroupId = async (roleGroupId) => {
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/coreapi/v1.0/Role/SearchRoleLevel", { RoleGroupId: roleGroupId });

		this.setState({ listRoleLevel: result || [] });
	}

	Search = async () => {
		var { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/coreapi/v1.0/Role/Search", model);

		model.IsParentId = false;
		model.IsRoleName = false;
		model.IsRoleGroupId = false;
		model.IsRoleLevelId = false;
      model.IsOrganizationUniqueId=false;
		this.setState({ model, list: result || [] });
	}

	Clear = () => {
		var { model, } = this.state;

		if (this.isClient) {
			var roleGroupId = model.RoleGroupId;
			var roleLevelId = model.RoleLevelId;

			model = {
				RoleGroupId: roleGroupId,
				RoleLevelId: roleLevelId
			};
		} else {
			model = {};
		}

		this.setState({ model, list: [], selected: null });
	}

	ValidateParentForBackofficeSuperAdmin = () => {
		var { model, roleLevelClientStandartUserId, isBackofficeSuperAdmin } = this.state;
		const { showValidationErrors } = this.props;

		if (isBackofficeSuperAdmin && model.RoleLevelId == roleLevelClientStandartUserId && model.ParentId == null) {
			model["IsParentId"] = true;

			showValidationErrors(["Parent cannot be null."]);

			return false;
		}

		model["IsParentId"] = false;

		return true;
	}
	RenderOrganizationUniqueIdSelect = (d) => {
		if (window.OrganizationUniqueId != 5000) {
		  const model = { ...this.state.model };
		  model["OrganizationUniqueId"] = d.OrganizationUniqueId;
		  this.setState({ model: model });
		}
  
		return d.OrganizationUniqueId + "-" + d.OrganizationName;
	 };
	render() {
		const { model, list, listRoleLevel, listRoleLevelClientAdmin, selected, roleLevelClientStandartUserId, isBackofficeSuperAdmin, isClientStandardUserOpen } = this.state;
		const { history, Disabled, ExecuteApiPost, showConfirmMessageInDelete } = this.props;

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},
						{
							Code: "Submit",
							Disabled: Disabled || selected != null,
							OnClick: () => ExecuteApiPost("/coreapi/v1.0/Role/Insert", model, model, this.validateProperties, this.ValidateParentForBackofficeSuperAdmin, [this.Clear, this.Search], true)
						},
						{
							Code: "Update",
							Disabled: Disabled || selected == null || (this.isClient && model.ParentId == -1),
							OnClick: () => ExecuteApiPost("/coreapi/v1.0/Role/Update", model, model, this.validateProperties, this.ValidateParentForBackofficeSuperAdmin, [this.Clear, this.Search], true)
						},
						{
							Code: "Delete",
							Disabled: Disabled || selected == null || (this.isClient && model.ParentId == -1),
							OnClick: () => showConfirmMessageInDelete(() => ExecuteApiPost("/coreapi/v1.0/Role/Delete", { Id: model.Id }, null, null, null, [this.Clear, this.Search], true))
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GenericDialog open={isClientStandardUserOpen} maxWidth="md" TransitionComponent={Transition}>
					<DialogTitle>
						<GenericLabel Bold Text="Error" />
					</DialogTitle>
					<DialogContent>
						<div style={{ textAlign: "center" }}>
							<GenericLabel Text="You cannot open this screen." />
						</div>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={() => history.push("/")}>OK</Button>
					</GenericDialogActions>
				</GenericDialog>
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="User Role Definition">
									<GridContainer>
										<GridItem xs={4}>
										<GenericSelectInput
                            IsTextBold
                            key={this.state.IsClientComponent}
                            Name="OrganizationUniqueId"
                            LabelText="Organization"
                            Value={model.OrganizationUniqueId || ""}
                            DataRoot="Item"
                            ValueChanged={this.ValueChanged}
                            KeyValueMember="OrganizationUniqueId"
                            TextValueMember="OrganizationUniqueId"
                            //  TaskPoolDomain={this.loadTaskPool}
                            Url="/bankapi/v1.0/OrganizationInfo/GetAll"
                            Method="POST"
                            RenderItem={this.RenderOrganizationUniqueIdSelect}
                            Parameter={emptyObject}
                            Required
                            IsInvalid={model.IsOrganizationUniqueId}
                          />
											<GenericTextInput
												Required
												IsInvalid={model.IsRoleName}
												Name="RoleName"
												LabelText="Role Name"
												inputProps={MaxLength128}
												Value={model.RoleName}
												ValueChanged={this.ValueChanged} />
											<GridItem xs={12} style={{ display: isBackofficeSuperAdmin && model.RoleLevelId == roleLevelClientStandartUserId ? "inherit" : "none" }}>
												<GenericSelectInput
													All
													IsStatic
													IsRequired={isBackofficeSuperAdmin && model.RoleLevelId == roleLevelClientStandartUserId}
													IsInvalid={model.IsParentId}
													Name="ParentId"
													LabelText="Parent"
													StaticData={listRoleLevelClientAdmin}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="RoleName"
													Value={model.ParentId}
													ValueChanged={this.ValueChanged} />
											</GridItem>
										</GridItem>
										<GridItem xs={4}>
											<ParameterComponent
												Required={!this.isClient}
												IsInvalid={model.IsRoleGroupId}
												Disabled={this.isClient}
												Name="RoleGroupId"
												LabelText="Role Group"
												ParameterCode="RoleGroup"
												Value={model.RoleGroupId}
												ValueChanged={this.ValueChanged} />
										</GridItem>
										<GridItem xs={4}>
											<GenericSelectInput
												IsStatic
												Required={!this.isClient}
												IsInvalid={model.IsRoleLevelId}
												Disabled={this.isClient}
												Name="RoleLevelId"
												LabelText="Role Level"
												StaticData={listRoleLevel}
												DataRoot="Item"
												KeyValueMember="RoleLevelId"
												TextValueMember="RoleLevel"
												Value={model.RoleLevelId}
												ValueChanged={this.ValueChanged} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columns}
													SelectedIndex={selected}
													RowSelected={this.RowSelected}
													IsSorted={false} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

UserRoleDefinition.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default UserRoleDefinition;