import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React from "react";
import AccountLimitToolbar from "views/Components/AccountLimitToolbar";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDateInput, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import { GridColumnType, ProgramCodes,EtransferApplicationTypes } from "views/Constants/Constant.js";

class BulkFiles extends React.Component {
	constructor(props) {
		super(props);

		this.isBackOffice = ClientHelper.IsBackOffice();
		
		this.initialModel = {
			UniqueClientId: this.isBackOffice ? null : ClientHelper.GetClientRowId(),
			FromDate: DateHelper.GetDate(),
			ToDate: DateHelper.GetDate(),
			FileStatusId: null,
			FileName: null,
			ReferenceNumber: null,
			IsUniqueDataSearch: false
		};

		this.state = {
			transactionList: [],
			fileList: [],
			model: { ...this.initialModel },
			vModel: {}
		};

		this.bulkFilesColumns = [
			{
				Header: "File Date",
				accessor: "FileDate",
				type: GridColumnType.DateUtc
			},
			{
				Header: "Unique Client Id",
				accessor: "ClientNumber",
				show: this.isBackOffice
			},
			{
				Header: "Client Name",
				accessor: "ClientName",
				show: this.isBackOffice
			},
			{
				Header: "File Name",
				accessor: "FileName"
			},
			{
				Header: "File Source",
				accessor: "FileSource"
			},
			{
				Header: "File Status",
				accessor: "FileStatus"
			},
			{
				Header: "Record Count",
				accessor: "RecordCount"
			},
			{
				Header: "Reject Count",
				accessor: "RejectCount"
			},
			{
				Header: "Total Nr of Credit Trans.",
				accessor: "TotalNumberCredit"
			},
			{
				Header: "Total Credit Amount",
				accessor: "TotalAmountCredit",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Total Fee Credit Amount",
				accessor: "TotalFeeAmountCredit",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Total Nr of Debit Trans.",
				accessor: "TotalNumberDebit"
			},
			{
				Header: "Total Debit Amount",
				accessor: "TotalAmountDebit",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Total Fee Debit Amount",
				accessor: "TotalFeeAmountDebit",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			}

		];

		this.transactionColumns = [
			{
				Header: "Transaction Date",
				accessor: "TransactionDate",
				type: GridColumnType.DateTimeUtc
			},
			{
				Header: "Unique Client Id",
				accessor: "ClientNumber",
				show: this.isBackOffice
			},
			{
				Header: "Client Name",
				accessor: "ClientName",
				show: this.isBackOffice
			},
			{
				Header: "Customer Name" ,
				accessor: "SenderName"
			},
			{
				Header: "Customer Number",
				accessor: "ClientCustomerNumber"
				
			},
			{
				Header: "Transfer Type",
				accessor: "TransactionType"
			},
			{
				Header: "Customer EMail",
				accessor: "CustomerEmail"
			},
			{
				Header: "Amount",
				accessor: "TransactionAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Fee Amount",
				accessor: "FeeAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Transfer Status",
				accessor: "BankTransactionStatus"
			},
			{
				Header: "Interac Status",
				accessor: "InteracStatus"
			},
			{
				Header: "Transaction Description",
				accessor: "TransactionMemo"
			},
			{
				Header: "Settlement Type",
				accessor: "SettlementType"
			},
			{
				Header: "Deferred",
				accessor: "IsDefered",
				Cell: row => row.value == true ? "Yes" : "No"
			},
			{
				Header: "Expire Date",
				accessor: "ExpireDate",
				type: GridColumnType.DateTimeUtc
			},
			{
				Header: "Interac Ref. Number",
				accessor: "TransferReferenceNumber"
			},
			{
				Header: "Insert Date",
				accessor: "RequestDate",
				type: GridColumnType.DateTimeUtc
			},
			{
				Header: "Transaction Source",
				accessor: "TransactionSource"
			},
			{
				Header: "Store Code",
				accessor: "StoreName"
			},
			{
				Header: "Application",
				accessor: "ApplicationId",
				Cell: row => {
					var rowValue = "";
					EtransferApplicationTypes && EtransferApplicationTypes.forEach(item => {
						if (item.Value == row.value)
							rowValue = item.Text;
					});
					return rowValue;
				}
			},
			{
				Header: "Insert User",
				accessor: "InsertUser"
			},
			{
				Header: "Reference Number",
				accessor: "ParticipantReferenceNumber"
			},
			{
				Header: "Error Description",
				accessor: "ErrorDesc"
			}

		];

		this.transactionColumns = [
			{
				Header: "Transaction Date",
				accessor: "TransactionDate",
				type: GridColumnType.DateTimeUtc
			},
			{
				Header: "Unique Client Id",
				accessor: "ClientNumber",
				show: this.isBackOffice
			},
			{
				Header: "Client Name",
				accessor: "ClientName",
				show: this.isBackOffice
			},
			{
				Header: "Customer Name" ,
				accessor: "SenderName"
			},
			{
				Header: "Customer Number",
				accessor: "ClientCustomerNumber"
				
			},
			{
				Header: "Transfer Type",
				accessor: "TransactionType"
			},
			{
				Header: "Customer EMail",
				accessor: "CustomerEmail"
			},
			{
				Header: "Amount",
				accessor: "TransactionAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Fee Amount",
				accessor: "FeeAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money
			},
			{
				Header: "Transfer Status",
				accessor: "BankTransactionStatus"
			},
			{
				Header: "Interac Status",
				accessor: "InteracStatus"
			},
			{
				Header: "Transaction Description",
				accessor: "TransactionMemo"
			},
			{
				Header: "Settlement Type",
				accessor: "SettlementType"
			},
			{
				Header: "Deferred",
				accessor: "IsDefered",
				Cell: row => row.value == true ? "Yes" : "No"
			},
			{
				Header: "Expire Date",
				accessor: "ExpireDate",
				type: GridColumnType.DateTimeUtc
			},
			{
				Header: "Interac Ref. Number",
				accessor: "TransferReferenceNumber"
			},
			{
				Header: "Insert Date",
				accessor: "RequestDate",
				type: GridColumnType.DateTimeUtc
			},
			{
				Header: "Transaction Source",
				accessor: "TransactionSource"
			},
			{
				Header: "Store Code",
				accessor: "StoreName"
			},
			{
				Header: "Application",
				accessor: "ApplicationId",
				Cell: row => {
					var rowValue = "";
					EtransferApplicationTypes && EtransferApplicationTypes.forEach(item => {
						if (item.Value == row.value)
							rowValue = item.Text;
					});
					return rowValue;
				}
			},
			{
				Header: "Insert User",
				accessor: "InsertUser"
			},
			{
				Header: "Reference Number",
				accessor: "ParticipantReferenceNumber"
			},
			{
				Header: "Error Description",
				accessor: "ErrorDesc"
			}
		];



		this.sortedFilesByDate = [{ id: "FileDate", desc: true }];
		this.sortedTransactionsByDate = [{ id: "TransactionDate", desc: true }];
		this.emptyObject = {};
		this.genericSelectFileStatusParameter = {
			ParameterCode: "BankFileStatus"
		};
	}

	componentDidMount() {

		const { setAppLeftTitle, setAppCenterTitle } = this.props;
		if (setAppLeftTitle)
			setAppLeftTitle("Bulk Files");
		if (setAppCenterTitle)
			setAppCenterTitle("eTransfer SYSTEM");
	}

	HandleClear = () => {
		this.setState({ vModel: {}, model: { ...this.initialModel },transactionList:[],fileList:[] });
	}
	HandleChange = (name, value) => {
		this.setState(function (state) {
			var model = state.model || {};
			model[name] = value;
			if (name == "ReferenceNumber" || name == "FileName") {
				model.IsUniqueDataSearch = value?.length > 0;
			}
			return { model };
		});
	}

	HandleSearch = async () => {

		if (!this.Validate()) return;
		var result = await this.props.ExecuteApiPost("/bankapi/v1.0/BankInteracETransferFile/SearchETransferBulkFile", this.state.model);
		this.setState({ fileList: result || [] ,transactionList: [] });
	}

	HandleRowSelectedBulkFile = async (index) => {

		var file = this.state.fileList[index];
		var result = await this.props.ExecuteApiPost("/bankapi/v1.0/BankInteracETransferFile/SearchETransferBulkFileTransactions", file.Id);
		this.setState({ transactionList: result || [] });

	}

	Validate = () => {
		const { model, vModel } = this.state;

		var messages = [];
		if (!model.IsUniqueDataSearch) {
			if ((vModel.UniqueClientId = !model.UniqueClientId)) messages.push("Client not selected", "Select Client to continue");
			if ((vModel.FromDate = !model.FromDate)) messages.push("From Date not selected", "Select From Date to continue");
			if ((vModel.ToDate = !model.ToDate)) messages.push("To Date not selected", "Select To Date to continue");

			var diffMonths = model.ToDate.diff(model.FromDate, "days");
			if ((vModel.Date = diffMonths < 0)) messages.push("To Date must be later than From Date", "To Date must be later than From Date");
			if ((vModel.Date = diffMonths > 30)) messages.push("Difference between From Date and To Date cannot be longer than 30 days");
		}
		this.setState({ vModel });
		if (messages.length != 0) {

			this.props.showValidationErrors(messages);
			return false;
		}
		else {
			return true;
		}


	}

	render() {
		const { Disabled } = this.props;
		const { model, vModel, isBackOffice } = this.state;
		return (
			<div>
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Filter Panel">
									<GridContainer>
										<GridItem xs={4}>
											<GridItem>
												<GenericSelectInput
													Name="UniqueClientId"
													LabelText="Client"
													Method="POST"
													Url="/bankapi/v1.0/BankCustomer/Search"
													Parameter={this.emptyObject}
													DataRoot="Item"
													KeyValueMember="Id"
													Required={!model.IsUniqueDataSearch}
													RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
													Value={model.UniqueClientId || ""}
													ValueChanged={this.HandleChange}
													CanClear
													IsInvalid={vModel.UniqueClientId}
													DefaultIndex={this.isBackOffice ? -1 : 0}
													Disabled={!this.isBackOffice} />
											</GridItem>
											<GridItem>
												<GenericSelectInput
													All
													Name="FileStatusId"
													LabelText="File Status"
													Value={model.FileStatusId || ""}
													DataRoot="Item"
													ValueChanged={this.HandleChange}
													KeyValueMember="Id"
													TextValueMember="ParameterDesc"
													Url="/coreapi/v1.0/Parameter/Search"
													Method="POST"
													Parameter={this.genericSelectFileStatusParameter} />
											</GridItem>
										</GridItem>
										<GridItem xs={4}>
											<GridItem>
												<GenericDateInput
													Name="FromDate"
													LabelText="From Date"
													Value={model.FromDate}
													ValueChanged={this.HandleChange}
													MaxDate={model.ToDate || DateHelper.GetDate()}
													IsInvalid={vModel.FromDate || vModel.Date}
													Required={!model.IsUniqueDataSearch}
													Utc />
											</GridItem>
											<GridItem>
												<GenericDateInput
													Name="ToDate"
													LabelText="To Date"
													Value={model.ToDate}
													ValueChanged={this.HandleChange}
													MinDate={model.FromDate}
													IsInvalid={vModel.ToDate || vModel.Date}
													Required={!model.IsUniqueDataSearch}
													Utc />
											</GridItem>
										</GridItem>
										<GridItem xs={4}>
											<GridItem>
												<GridItem>
													<GenericTextInput
														Name="FileName"
														LabelText="File Name"
														Value={model.FileName}
														ValueChanged={this.HandleChange} />
												</GridItem>
												<GridItem>
													<GenericTextInput Name="ReferenceNumber" LabelText="Reference Number" Value={model.ReferenceNumber} ValueChanged={this.HandleChange} />
												</GridItem>
											</GridItem>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Bulk Files">
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer>
												<GridItem xs={12}>
													<GridContainer justify="flex-end">
														<GridItem xs={3} />
													</GridContainer>
												</GridItem>
											</GridContainer>
											<GridContainer spacing={16}>
												<GridItem xs={12}>
													<GenericGrid
														Data={this.state.fileList}
														Columns={this.bulkFilesColumns}
														Sorted={this.sortedFilesByDate}
														ProgramCode={ProgramCodes.Bank_Programs_EFT}
														RowSelected={this.HandleRowSelectedBulkFile} />
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardHeader>
								<GridContainer spacing={16} justify="space-between" alignItems="center">
									<GridItem>
										<CardHeader>
											<h4><b>Transactions of File</b></h4>
										</CardHeader>
									</GridItem>
								</GridContainer>
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer>
											<GridItem xs={12}>
												<GridContainer justify="flex-end">
													<GridItem xs={3} />
												</GridContainer>
											</GridItem>
										</GridContainer>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={this.state.transactionList}
													Columns={this.transactionColumns}
													Sorted={this.sortedTransactionsByDate}
													ProgramCode={ProgramCodes.Bank_Programs_EFT} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

BulkFiles.propTypes = {
	classes: PropTypes.object
};

export default BulkFiles;
