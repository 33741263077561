import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericGrid, GenericSelectInput } from "views/Components/Generic";
import ParameterComponent from "views/Components/ParameterComponent";
import { ClientType, FinScanSearchStatus, GridColumnType } from "views/Constants/Constant";
import { SortedPeriodNumberAsc } from "views/Constants/Constant.Sorted";

class FinScanScheduledWatchlistControl extends React.Component {
	constructor(props) {
		super(props);

		this.columnsScheduleList = [
			{
				Header: "Schedule Period W/M",
				accessor: "PeriodNumber",
				width: 30
			},
			{
				Header: "Schedule From Date",
				accessor: "FromDate",
				type: GridColumnType.DateUtc
			},
			{
				Header: "Schedule To Date",
				accessor: "ToDate",
				type: GridColumnType.DateUtc
			}
		];
		this.columnsList = [];

		this.state = {
			model: {}
		};
	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle("AML & KYC");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle("Scheduled Watchlist Control");
		}

		this.SetYearList();
	}

	ValueChanged = (name, value, data) => {
		this.setState(state => {
			var model = state.model;
			var list = state.list;
			var listSchedulePeriodWeeklyList = state.listSchedulePeriodWeeklyList;
			var listSchedulePeriodMonthlyList = state.listSchedulePeriodMonthlyList;

			model[name] = value;

			if (name == "SearchTypeId") {
				model["SearchTypeParameterValue"] = data && data.ParameterValue;
				model["Year"] = null;
				model["Quarter"] = null;

				list = [];
			} else if (name == "Year") {
				if (value == null) {
					model["Quarter"] = null;
					listSchedulePeriodWeeklyList = [];
					listSchedulePeriodMonthlyList = [];
				} else {
					this.GetSchedulePeriodList();
				}
			} else if (name == "Quarter" && model.Year != null) {
				this.GetSchedulePeriodList();
			}

			return { model, list, listSchedulePeriodWeeklyList, listSchedulePeriodMonthlyList };
		});
	}

	RowSelected = async (index) => {
		const { model, listSchedulePeriodMonthlyList, listSchedulePeriodWeeklyList } = this.state;
		const { ExecuteApiPost } = this.props;
		var request = {
			PeriodNumber: model.SearchTypeParameterValue == ClientType.Personal
				? listSchedulePeriodWeeklyList[index].PeriodNumber
				: listSchedulePeriodMonthlyList[index].PeriodNumber,
			SearchTypeId: model.SearchTypeId
		};
		var result = await ExecuteApiPost("/amlkycapi/v1.0/FinScanSearchLog/SearchLogForSchedule", request);

		if (result != null && result.length != 0) {
			this.GetColumnsList(result);
			this.SetList(result);
		} else {
			this.setState({ list: [] });
		}

		this.setState({ selected: index });
	}

	GetColumnsList = (searchResult) => {
		this.columnsList = [];

		searchResult[0].JsonDataParsed.map(x => {
			this.columnsList.push({
				Header: x.Key.split(/(?=[A-Z])/).join(" "),
				accessor: x.Key,
				Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.SearchStatusParameterValue)}>{row.value}</div>)
			});

			return null;
		});

		this.columnsList.push({
			Header: "Search Type",
			accessor: "SearchTypeParameterDesc",
			Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.SearchStatusParameterValue)}>{row.value}</div>)
		});
		this.columnsList.push({
			Header: "Search Date",
			accessor: "SearchDate",
			Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.SearchStatusParameterValue)}>{Formatter.FormatDate(row.value)}</div>)
		});
		this.columnsList.push({
			Header: "Search Status",
			accessor: "SearchStatusParameterDesc",
			Cell: row => (<div style={this.GetColumnsRowStyleColor(row.original.SearchStatusParameterValue)}>{row.value}</div>)
		});

		this.forceUpdate();
	}

	GetColumnsRowStyleColor(status) {
		this.columnsRowStyleColor = {
			color: status != null
				&& (status == FinScanSearchStatus.ManualVerification || status == FinScanSearchStatus.Unverified)
				? "#FB3005"
				: ""
		};

		return this.columnsRowStyleColor;
	}

	GetSchedulePeriodList = async () => {
		const { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/amlkycapi/v1.0/FinScanSearchLog/GetSchedulePeriodList", model);
		var listSchedulePeriodWeeklyList = [], listSchedulePeriodMonthlyList = [];

		if (result != null) {
			listSchedulePeriodWeeklyList = result.WeeklyLists;
			listSchedulePeriodMonthlyList = result.MonthlyLists;
		}

		this.setState({ listSchedulePeriodWeeklyList, listSchedulePeriodMonthlyList });
	}

	SetYearList = () => {
		var currentYear = parseInt(DateHelper.GetYear());
		var listYear = [];

		for (var i = 2019; i <= currentYear; i++) {
			listYear.push({
				"Year": i
			});
		}

		this.setState({ listYear });
	}

	SetList = (searchResult) => {
		var list = searchResult;

		for (let i = 0; i < searchResult.length; i++) {
			searchResult[i].JsonDataParsed.map(x => {
				list[i][x.Key] = x.Value;

				return null;
			});
		}

		this.setState({ list });
	}

	Clear = () => {
		this.setState({ model: { IsScheduled: true }, list: [], selected: null });
	}

	render() {
		const { model, list, listYear, listSchedulePeriodWeeklyList, listSchedulePeriodMonthlyList, selected } = this.state;
		const { Disabled } = this.props;

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={4}>
												<ParameterComponent
													Name="SearchTypeId"
													LabelText="Search Type"
													ParameterCode="ClientType"
													ParameterValue2="C"
													Value={model.SearchTypeId}
													ValueChanged={this.ValueChanged} />
											</GridItem>
											<GridItem xs={4}>
												<GenericSelectInput
													IsStatic
													Disabled={!model.SearchTypeId}
													Name="Year"
													LabelText="Year"
													StaticData={listYear}
													KeyValueMember="Year"
													TextValueMember="Year"
													Value={model.Year}
													ValueChanged={this.ValueChanged} />
											</GridItem>
											<GridItem xs={4}>
												<GenericSelectInput
													IsStatic
													Disabled={!model.Year}
													Name="Quarter"
													LabelText="Quarter"
													StaticData={[{ "Quarter": 1 }, { "Quarter": 2 }, { "Quarter": 3 }, { "Quarter": 4 }]}
													KeyValueMember="Quarter"
													TextValueMember="Quarter"
													Value={model.Quarter}
													ValueChanged={this.ValueChanged} />
											</GridItem>
										</GridContainer>
										<GridContainer spacing={16}>
											<GridItem xs={12} sty>
												<GenericGrid
													Data={model.SearchTypeParameterValue == null ? [] : model.SearchTypeParameterValue == ClientType.Personal ? listSchedulePeriodWeeklyList : listSchedulePeriodMonthlyList}
													Columns={this.columnsScheduleList}
													SelectedIndex={selected}
													RowSelected={this.RowSelected}
													Sorted={SortedPeriodNumberAsc} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.columnsList} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

FinScanScheduledWatchlistControl.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default FinScanScheduledWatchlistControl;