import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GenericNumberInput, GenericTextInput } from "views/Components/Generic";


class Tcr4 extends Component {

	constructor(props) {
		super(props);
		this.state = {
			model: {}
		};

		this.maxLength1 = { maxLength: 1 };
		this.maxLength19 = { maxLength: 19 };
		this.maxLength6 = { maxLength: 6 };
		this.maxLength17 = { maxLength: 17 };
		this.maxLength4 = { maxLength: 3 };
		this.maxLength3 = { maxLength: 3 };
		this.maxLength22 = { maxLength: 22 };
		this.maxLength16 = { maxLength: 16 };
		this.maxLength45 = { maxLength: 45 };
		this.maxLength13 = { maxLength: 13 };
		this.maxLength10 = { maxLength: 10 };
		this.maxLength256 = { maxLength: 256 };
		this.maxLength11 = { maxLength: 11 };
		this.maxLength999 = { maxLength: 999 };
		this.maxLength12 = { maxLength: 12 };
		this.maxLength15 = { maxLength: 15 };
		this.maxLength8 = { maxLength: 8 };
		this.maxLength2 = { maxLength: 2};
		this.maxLength25 =  { maxLength: 25};
	}

	HandleChange = (name, newValue, data) => {
		this.props.onModelChange(model => {
			model[name] = newValue;
			return model;
		});
	}

	render() {

		const { model } = this.props;
		return (
			<div >

				<Card className="no-radius">
					<CardBody>
					<GridContainer>
							<GridItem xs={6}>
								<GenericTextInput
									Name="BusinessFormatCode"
									LabelMd={4}
									LabelText="Business Format Code"
									inputProps={this.maxLength2}
									Value={model && model.BusinessFormatCode}
									ValueChanged={this.HandleChange} />

								<GenericNumberInput
									Name="DebitProductCode"
									LabelMd={4}
									LabelText="Debit Product Code"
									inputProps={this.maxLength10}
									Value={model && model.DebitProductCode}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="ContactForInformation"
									LabelMd={4}
									LabelText="Contact For Information "
									inputProps={this.maxLength25}
									Value={model && model.ContactForInformation}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="AdjustmentProcessingIndicator"
									LabelMd={4}
									LabelText="Adjusment Processing Indicator"
									inputProps={this.maxLength1}
									Value={model && model.AdjustmentProcessingIndicator}
									ValueChanged={this.HandleChange} />

							</GridItem>

							<GridItem xs={6}>
								<GenericTextInput
									Name="MessageReasonCode"
									LabelMd={4}
									LabelText="Message Reason Code"
									inputProps={this.maxLength4}
									Value={model && model.MessageReasonCode}
									ValueChanged={this.HandleChange} />

								<GenericNumberInput
									Name="SurchargeAmount"
									LabelMd={4}
									LabelText="Surcharge Amount"
									inputProps={this.maxLength17}
									Value={model && model.SurchargeAmount}
									ValueChanged={this.HandleChange} />

								<GenericTextInput
									Name="SurchargeCreditDebitIndicator"
									LabelMd={4}
									LabelText="Surcharge Creadit Debit Indicator"
									inputProps={this.maxLength2}
									Value={model && model.SurchargeCreditDebitIndicator}
									ValueChanged={this.HandleChange} />

								<GenericNumberInput
									Name="SurchargeAmountInChBillingCurrency"
									LabelMd={4}
									LabelText="Surcharge Amount InCh BillingCurrency"
									inputProps={this.maxLength17}
									Value={model && model.SurchargeAmountInChBillingCurrency}
									ValueChanged={this.HandleChange} />

							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>

			</div>
		);
	}
}

Tcr4.propTypes = {
	model: PropTypes.object,
	onModelChange: PropTypes.func,
	Disabled: PropTypes.bool
};

export default Tcr4;