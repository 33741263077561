import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React from "react";
import DigitalVerificationPanel from "views/AmlKyc/DigitalVerification/DigitalVerificationPanel";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import { DigitalVerificationAction, DigitalVerificationPopupUploadCode, RoleGroup } from "views/Constants/Constant";
import ParameterComponent from "views/Components/ParameterComponent";
import ClientHelper from "core/ClientHelper";

const ApiUrlBase = "/amlkycapi/v1.0/DigitalIdentityVerificationIdIntroduction/";
const ApiUrl = {
	GetVerificationResult: ApiUrlBase + "GetResult",
	Search: ApiUrlBase + "Search"
};
class IdIntroduction extends React.Component {

	constructor(props) {
		super(props);

		this.isBackOffice = ClientHelper.IsBackOffice();
		this.uniqueClientId = ClientHelper.GetClientRowId();

		this.initialState = {
			model: {},
			vModel: {},
			modelList: [],
			filterModel: {
				UniqueClientId: this.uniqueClientId,
				IsRoleGroupBackoffice: true
			},
			visibilityModel: {},
			isOpenkycUploadWindow: false,
			isIdentityTypeAddOpen: false,
			InsertedIdentityType: null,
			verificationResultModel: null,
			identityTypeModel: {}
		};
		this.state = Object.assign({
			identityTypeList: [],
			tempIdentityTypeList: []
		}, this.initialState);
		this.emptyObject = {};
		this.gridSortParameter = [{ id: "Name", desc: false }];
		this.placeholderNA = "-";
		this.identityDialogButtonStyle = {
			flexDirection: "row",
			display: "flex",
			justifyContent: "flex-end",
		};

		this.columnsData = [
			{
				Header: "Client Name",
				accessor: "ClientName",
				Cell: row => row.value || "Backoffice",
				show: this.isBackOffice,
			},
			{
				Header: "User Name",
				accessor: "UserName"
			},
			{
				Header: "ID Type",
				accessor: "TypeName",
				Cell: row => row.value || this.placeholderNA
			},
			{
				Header: "First Name",
				accessor: "DigitalIdentityVerification.FirstName",
				Cell: row => row.value || this.placeholderNA
			},
			{
				Header: "Last Name",
				accessor: "DigitalIdentityVerification.LastName",
				Cell: row => row.value || this.placeholderNA
			},
			{
				Header: "ID Number",
				accessor: "DigitalIdentityVerification.IdNumber",
				Cell: row => row.value || this.placeholderNA
			},
			{
				Header: "Expire Date",
				accessor: "DigitalIdentityVerification.ExpireDate",
				Cell: row => row.value || this.placeholderNA
			},
			{
				Header: "Issue Date",
				accessor: "DigitalIdentityVerification.IssueDate",
				Cell: row => Formatter.FormatDate(row.value)
			},
			{
				Header: "Birth Date",
				accessor: "DigitalIdentityVerification.BirthDate",
				Cell: row => Formatter.FormatDate(row.value)
			},
			{
				Header: "Place of Issue",
				accessor: "DigitalIdentityVerification",
				Cell: row => row.value.Country?.Name ? (row.value.Country.Name + (row.value?.Province?.Name ? (", " + row.value?.Province?.Name) : "")) : this.placeholderNA
			},
			{
				Header: "Country",
				accessor: "DigitalIdentityVerification.IdCountry.Name",
				Cell: row => row.value || this.placeholderNA
			},
			{
				Header: "Province",
				accessor: "DigitalIdentityVerification.IdProvince.Name",
				Cell: row => row.value || this.placeholderNA
			},
			{
				Header: "City",
				accessor: "DigitalIdentityVerification.IdCity.Name",
				Cell: row => row.value || this.placeholderNA
			},
			{
				Header: "Status",
				accessor: "DigitalIdentityVerification.Success",
				Cell: row => row.value ? "Valid" : "Invalid"
			},
			{
				Header: "Average Score",
				accessor: "AverageScore",
				Cell: row => row.value ? ("%" + row.value) : this.placeholderNA
			}
		];
		this.styleUploadButton = { marginLeft: 40 };

	}

	componentDidMount() {
		this.props.setAppCenterTitle && this.props.setAppCenterTitle("AML & KYC");
		this.props.setAppLeftTitle && this.props.setAppLeftTitle("ID Introduction");

	}

	ValueChanged = (name, newValue, data) => {
		const model = { ...this.state.model };

		model[name] = newValue;
		this.setState({ model });
	}
	ValueChangedFilter = (name, newValue, data) => {
		const filterModel = { ...this.state.filterModel };

		if (name == "RoleGroupId") {
			filterModel["IsRoleGroupBackoffice"] = data?.ParameterValue != RoleGroup.Client;

			if (data?.ParameterValue != RoleGroup.Client) {
				filterModel.UniqueClientId = null;
			}
		}

		filterModel[name] = newValue;
		this.setState({ filterModel });
	}
	SelectedRowChange = (index) => {
		const model = this.state.modelList[index];
		this.GetDigitalIdentityInfo(model);
		this.setState({ model: model });
	}
	HandleSearch = async () => {
		const { ExecuteApiPost } = this.props;
		const { filterModel } = this.state;
		var result = await ExecuteApiPost(ApiUrl.Search, filterModel);

		this.setState({ modelList: result || [] });

	}
	HandleClear = () => {
		var defaultState = Object.assign({}, this.initialState);
		this.setState(defaultState);
	}

	HandlePopupManualUpload = async () => {
		const { model } = this.state;
		model.Code = DigitalVerificationPopupUploadCode.IdIntroduction;
		localStorage.setItem(DigitalVerificationAction.PopupUpload, JSON.stringify(model));
		this.popupUploadWindow = window.open("/#/out/KycPhotoUpload", "_blank", "toolbar=no,scrollbars=no,resizable=yes,top=100,left=100,width=1200,height=800");
		this.setState({ isOpenkycUploadWindow: true });
		var popupCloseAwaiter = new Promise((resolve) => {
			this.popupUploadWindow.onunload = () => {
				if (this.popupUploadWindow.location.href == "about:blank") {
					return;
				}
				var parsed = JSON.parse(localStorage.getItem(DigitalVerificationAction.PopupUpload));
				if (this.popupUploadWindow?.closed && parsed?.Id > 0) {
					this.GetDigitalIdentityInfo(model);
					resolve(parsed);
				}
				else {
					resolve(false);
				}
				localStorage.removeItem(DigitalVerificationAction.PopupUpload);
				this.popupUploadWindow = null;
				this.setState({ isOpenkycUploadWindow: false });
			};
		});

		var result = await popupCloseAwaiter;
		if (result) {
			this.HandleSearch();
			this.GetDigitalIdentityInfo(result);
			this.setState({ model: result });
			await this.props.showMessage("success", "Success", "Photos uploaded & verify process successfully completed!");
		}
		return result;

	}
	GetRenderItemClientSelect = (d) => {
		return `${d.UniqueClientId} - ${d.Name}`;
	}

	GetDigitalIdentityInfo = async (model) => {

		if (!model?.Id) {
			return;
		}
		var result = await this.props.ExecuteApiPost(ApiUrl.GetVerificationResult, { IdIntroductionId: model?.Id });
		if (result) {
			model.IdentityPhotoId = result.IdentityPhotoId;
			model.IdentityPhotoBackId = result.IdentityPhotoBackId;
			delete result.IdentityPhotoId;
			delete result.IdentityPhotoBackId;
		}
		this.setState({ verificationResultModel: result });
	}



	ClearDigitalIdentityVerificationIdentityType = async () => {
		this.setState({ identityTypeModel: {} });
	}



	RenderIdentityTypeList = (a) => {
		return (a.Name + (a.HasRecognized ? "*" : ""));
	}
	render() {
		const { model, modelList, filterModel, verificationResultModel, isOpenkycUploadWindow } = this.state;
		const { Disabled } = this.props;

		return (
			<>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled || isOpenkycUploadWindow,
							OnClick: this.HandleSearch
						},
						{
							Code: "Clear",
							Disabled: Disabled || isOpenkycUploadWindow,
							OnClick: this.HandleClear
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GridContainer>
					<GridItem xs={12}>
						<Card>
							<GenericExpansionPanel
								IsActive={true}
								Title="Filter Panel">
								<CardBody>
									<GridContainer >
										<GridItem xs={4}>
											{this.isBackOffice &&
												<ParameterComponent
													All
													Name="RoleGroupId"
													LabelText="User Type"
													ParameterCode="RoleGroup"
													DefaultParamValue={this.isBackOffice ? undefined : RoleGroup.Client}
													Value={filterModel.RoleGroupId || -1}
													DefaultIndex={0}
													ValueChanged={this.ValueChangedFilter} />
											}
											<GenericSelectInput
												CanClear
												Disabled={!this.isBackOffice || filterModel.IsRoleGroupBackoffice}
												Name="UniqueClientId"
												LabelText="Client"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Method="POST"
												Parameter={this.emptyObject}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={this.GetRenderItemClientSelect}
												Value={filterModel.UniqueClientId || ""}
												ValueChanged={this.ValueChangedFilter}
												DefaultIndex={this.isBackOffice ? -1 : 0} />
											<GenericSelectInput
												CanClear
												Name="DigitalIdentityVerificationIdentityTypeId"
												LabelText="Identity Type"
												Url="/amlkycapi/v1.0/DigitalIdentityVerificationIdentityType/Search"
												Method="POST"
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Name"
												Value={filterModel.DigitalIdentityVerificationIdentityTypeId || ""}
												ValueChanged={this.ValueChangedFilter} />
										</GridItem>
										<GridItem xs={4}>

											<GenericTextInput
												Name="FirstName"
												LabelText="First Name"
												Value={filterModel.FirstName}
												ValueChanged={this.ValueChangedFilter} />
											<GenericTextInput
												Name="LastName"
												LabelText="Last Name"
												Value={filterModel.LastName}
												ValueChanged={this.ValueChangedFilter} />
										</GridItem>
										<GridItem xs={4}>
											<GenericSelectInput
												Name="CountryId"
												LabelText="Country"
												Method="POST"
												Url="/coreapi/v1.0/Country/GetAll"
												Parameter={{}}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Name"
												Value={filterModel.CountryId}
												ValueChanged={this.ValueChangedFilter}
												Disabled={Disabled} />
											<GenericSelectInput
												key={filterModel.CountryId + "province"}
												Name="ProvinceId"
												LabelText="Province"
												Method="POST" Url="/coreapi/v1.0/Province/Filter"
												Parameter={{ CountryId: filterModel.CountryId }} DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Name"
												Value={filterModel.ProvinceId}
												ValueChanged={this.ValueChangedFilter}
												Disabled={Disabled} />
											<GenericSelectInput
												key={"city" + filterModel.ProvinceId + ""}
												Name="CityId"
												LabelText="City"
												Method="POST"
												Url="/coreapi/v1.0/City/Filter"
												Parameter={{ ProvinceId: filterModel.ProvinceId }}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Name"
												Value={filterModel.CityId}
												ValueChanged={this.ValueChangedFilter}
												Disabled={Disabled} />
										</GridItem>
									</GridContainer>
								</CardBody>
							</GenericExpansionPanel>
						</Card>
						<Card>
							<GenericExpansionPanel
								IsActive={true}
								Title="ID Introduction Definition">
								<CardBody>
									<GridContainer>
										<GridItem xs={12}>
											<GridContainer >
												<GridItem xs={1.5}>
													<Button
														style={this.styleUploadButton}
														size="sm"
														color="black"
														fullWidth
														onClick={this.HandlePopupManualUpload}
														disabled={model.Id || isOpenkycUploadWindow}>
														Photo Upload &amp; Verify</Button>
												</GridItem>
											</GridContainer>
										</GridItem>
										<GridItem xs={12}>
											<DigitalVerificationPanel
												ExecuteApiPost={this.props.ExecuteApiPost}
												Model={model?.DigitalIdentityVerification?.DigitalIdentityVerificationInfo?.Image || model}
												VerificationModel={verificationResultModel}
												ShowOnlyCard={true}
												ScoreOnly={true}
											/>
										</GridItem>
									</GridContainer>
								</CardBody>
							</GenericExpansionPanel>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel
									Name={"IdIntroductionPanel"}
									Title="ID Introduction List">
									<GenericGrid
										Data={modelList}
										PageSize={5}
										HideButton={true}
										Sorted={this.gridSortParameter}
										Columns={this.columnsData}
										RowSelected={this.SelectedRowChange} />
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</ >
		);
	}
}

IdIntroduction.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};
export default IdIntroduction;