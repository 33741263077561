import { Icon } from "@material-ui/core";
import React, { PureComponent } from "react";

class GenericIcon extends PureComponent {
	render() {
		const { className, children, ...rest } = this.props;
		var text = children.toString();
		if (text && text.startsWith("fa-")) {
			return <div className={className} ><i {...rest} className={"fa " + text} /></div>;
		}
		return <Icon {...this.props} />;
	}
}

export default GenericIcon;