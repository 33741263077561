import { withStyles } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import ExternalContainer from "layouts/ExternalContainer";
import React, { PureComponent } from "react";
import FlinksAccountPreviewCard from "views/BankAccount/LinkedAccounts/FlinksAccountPreviewCard";
import { GenericExpansionPanel, GenericGrid, GenericLabel, GenericTitle } from "views/Components/Generic";
import { ProgramCodes } from "views/Constants/Constant";


const initialState = {
	model: {},
	openDialog: false,
	accessKey: null,
	hasTimeout: false,
	timeoutWarn: false,
	linkRequestTypeId: 0,
	isFinished: false,
	flinksAccounts: [],
	flinksAccountModel: {},
	isSandbox: false
};
const demoFlinksUrl = "https://toolbox-iframe.private.fin.ag/?desktopLayout=true&demo=true&consentEnable=true";

class KycPhotoUpload extends PureComponent {
	constructor(props) {
		super(props);
		this.state = Object.assign({}, initialState);
		this.emptyObject = {};
		this.timer = null;
		this.iFrameStyle = {
			width: "100%",
			height: "calc(90vh - 185px)"
		};

	}

	AccessKeyExpireInterval = async () => {
		const { model, timeoutWarn, hasTimeout } = this.state;
		var requestDateTime = DateHelper.ToMoment(model.RequestDateTime);
		var minutesDiff = DateHelper.GetDateTime().diff(requestDateTime, "seconds");
		if (!hasTimeout && minutesDiff >= 1800) {
			this.setState({ hasTimeout: true });
			this.timer && clearInterval(this.timer);
			await this.props.showMessage("error", "Error", "Session expired!");
			this.props.history.push("/");
		}
		else if (!timeoutWarn && minutesDiff >= 1500) {
			this.setState({ timeoutWarn: true });
			this.props.showMessage("warning", "Warning", "Session will expire within 5 minutes!");
		}
	}
	setStateAsync = (newState) => {
		return new Promise((resolve) => this.setState(newState, resolve));
	}

	async componentDidMount() {
		if (this.props.setAppLeftTitle) {
			this.props.setAppLeftTitle("Manual KYC Application Linked Wallet");
		}
		if (this.props.setAppCenterTitle) {
			this.props.setAppCenterTitle("AML & KYC");
		}
		var flinksHandle = this.HandleFlinksIframeResponse;
		window.addEventListener("message", function (e) {
			flinksHandle(e.data);
		});
		var thiz = this;
		window.addEventListener("kycAccountSandbox", function (e) {//Switch test mode with => window.dispatchEvent(new Event("kycAccountSandbox"))
			thiz.setState({ isSandbox: !thiz.state.isSandbox });
			thiz.forceUpdate();
		});

		const { match } = this.props;
		if (match.params && match.params.key) {
			await this.setStateAsync({ accessKey: match.params.key });
			await this.getKycManualByAccessKey(match.params.key);
			return;
		}
		else {
			this.props.history.push("/");
			return;
		}


		//this.GetLinkRequestTypeList();
	}

	// GetLinkRequestTypeList = () => {
	// 	var result = this.ExecuteApiPost("/coreapi/v1.0/Parameter/Search", { ParameterCode: LinkRequestType.CODE });
	// 	var kycLinkRequestTypeId = (result || []).find(a => a.ParameterValue == LinkRequestType.DigitalIdentityVerification);
	// 	this.setState({ linkRequestTypeId: kycLinkRequestTypeId });
	// }
	HandleFlinksIframeResponse = (data) => {
		if (data != null) {
			if (data.institution != null && data.loginId != null && data.requestId != null) {
				this.setState({ flinksLoginModel: data });
			}
			if (data.step == "REDIRECT") {
				this.setState({ isFlinksLoginDialog: false });
				this.FlinksAccountSummaryRequest();
			}
		}
	}
	FlinksAccountSummaryRequest = async () => {

		this.state.flinksLoginModel.IsSandbox = this.state.isSandbox;
		var result = await this.ExecuteApiPost("/amlkycapi/v1.0/KycManualApplication/GetFlinksAccountSummaryByAccessKey", this.state.flinksLoginModel);
		if (result) {
			this.setState({
				flinksAccounts: result.Accounts ?? [],
				flinksResponse: result
			});
		}


	}

	getKycManualByAccessKey = async (key) => {

		var result = await this.ExecuteApiPost("/amlkycapi/v1.0/KycManualApplication/GetKycForVerificationByAccessKey", {});
		if (!result) {
			await this.props.showMessage("error", "Error", "Access timed out exceeted");
			this.props.history.push("/");
			return;
		}

		if (result.RequestDateTime) {
			this.timer = setInterval(this.AccessKeyExpireInterval, 10000);
		}
		await this.setStateAsync({ model: result });
	}



	ExecuteApiPost = async (url, requestModel) => {
		const { ExecuteApiPost } = this.props;
		const { accessKey } = this.state;
		requestModel.AccessKey = accessKey;
		return await ExecuteApiPost(url, requestModel);
	}

	FlinksDataModelParse = (model) => {
		var temp = {};
		const flinks = { ...this.state.flinksResponse };
		if (flinks != null) {
			temp.HttpStatusCode = flinks.HttpStatusCode;
			temp.Institution = flinks.Institution;
			temp.RequestId = flinks.RequestId;  // RequestId
			temp.Tag = flinks.Tag;
		}
		if (flinks != null && flinks.Login != null) {
			temp.Username = flinks.Login.Username;
			temp.LastRefresh = flinks.Login.LastRefresh;
			temp.Type = flinks.Login.Type;
			temp.LoginId = flinks.Login.Id; // LoginId
		}
		if (model != null) {
			temp.Title = model.Title;
			temp.AccountNumber = model.AccountNumber;
			temp.Category = model.Category;
			temp.Currency = model.Currency;
			temp.AccountId = model.Id;  // AccountId			
			temp.TransitNumber = model.TransitNumber;
			temp.InstitutionNumber = model.InstitutionNumber;
		}
		if (model != null && model.Balance != null) {
			temp.AvailableBalance = model.Balance.Available;
			temp.CurrentBalance = model.Balance.Current;
			temp.Limit = model.Balance.Limit;
		}
		if (model != null && model.Holder != null) {
			temp.Name = model.Holder.Name;
			temp.Email = model.Holder.Email;
			temp.PhoneNumber = model.Holder.PhoneNumber;
		}
		if (model != null && model.Holder != null && model.Holder.Address != null) {
			temp.CivicAddress = model.Holder.Address.CivicAddress;
			temp.City = model.Holder.Address.City;
			temp.Province = model.Holder.Address.Province;
			temp.PostalCode = model.Holder.Address.PostalCode;
			temp.Pobox = model.Holder.Address.POBox;
			temp.Country = model.Holder.Address.Country;
		}
		if (this.state.isSandbox) {
			temp.InstitutionNumber = 1;
		}
		return temp;
	}
	SaveFlinksModel = async () => {
		var data = this.FlinksDataModelParse(this.state.flinksAccountModel);
		if (data == null || data.AccountId == null || data.AccountId == "") {
			this.props.showMessage("error", "Error", "Wallet must be selected !");
			return;
		}
		var result = await this.ExecuteApiPost("/amlkycapi/v1.0/KycManualApplication/InsertOrUpdateFlinksAccountByAccessKey", data);
		if (result) {
			//this.ClearData();
			this.props.showMessage("success", "Success", "Wallet Verification saved succesfully.You can now close the tab.");
			//this.GetLinkedAccounts();
			this.setState({ isFinished: true });
		}
	}

	HandleCloseTab = async () => {

		const { isFinished } = this.state;
		if (!isFinished && !await this.props.showConfirmMessage("warning", "Warning", "Wallet is not saved yet. Are you sure you want to close the tab?")) return;

		window.close();
	}

	SelectedRowChange = (index) => {
		const model = this.state.flinksAccounts[index];
		this.setState({ flinksAccountModel: model });
	}
	HandleClickSave = () => {
		this.SaveFlinksModel();
	}
	render() {
		const { flinksAccounts, flinksAccountModel, isFinished } = this.state;
		var hasAnyAccount = flinksAccounts?.length > 0;

		var flinksUrl = this.state.isSandbox ? demoFlinksUrl : window.FlinksIframeUrl;
		return (
			<ExternalContainer Title="Wallet Verification" HalfWidth={false} key={this.state.isSandbox}>
				<GridContainer justify="center">
					<GridItem xs={12} sm={11} md={10}>
						<Card>
							<CardHeader>
								<GenericTitle text={hasAnyAccount ? "Select Linked Wallet For Verificaton" : "Please Login with Wallet"} />
							</CardHeader>
							<CardBody>
								{hasAnyAccount ?
									<GridContainer>
										<GridItem xs={12}>
											<FlinksAccountPreviewCard
												Title="Wallet Details"
												Model={flinksAccountModel}
											/>
										</GridItem>
										<GridItem xs={12}>
											<Card className="no-radius">
												<GenericExpansionPanel Title={"Linked Wallet List"}>
													<CardBody>


														<GenericGrid
															HideButton={true}
															Data={flinksAccounts}
															PageSize={5}
															ShowPagination={true}
															Columns={[
																{
																	Header: "Title",
																	accessor: "Title"
																},
																{
																	Header: "Wallet Number",
																	accessor: "AccountNumber"
																},
																{
																	Header: "Balance",
																	accessor: "Balance",
																	Cell: row => (
																		<div>{row.original.Balance == null ? "" : row.original.Balance.Current == null || 0 ? "" : Formatter.FormatMoney(row.original.Balance.Current)}</div>
																	)
																},
																{
																	Header: "Category",
																	accessor: "Category"
																},
																{
																	Header: "Currency",
																	accessor: "Currency"
																},
																{
																	Header: "Name",
																	accessor: "Holder.Name"
																},
																{
																	Header: "Email",
																	accessor: "Holder.Email"
																},
																{
																	Header: "Phone",
																	accessor: "Holder.PhoneNumber"
																},
																{
																	Header: "Address",
																	Cell: row => (
																		<div>{row.original.Holder == null ? "" : row.original.Holder.Address == null ? "" :
																			row.original.Holder.Address.CivicAddress + " " +
																			row.original.Holder.Address.PostalCode + " " +
																			row.original.Holder.Address.City + " " +
																			row.original.Holder.Address.Country + " "
																		}</div>
																	)
																}
															]}
															RowSelected={this.SelectedRowChange}
															ProgramCode={ProgramCodes.Bank_Programs_Bank_Account} />
													</CardBody>
												</GenericExpansionPanel>
											</Card>
										</GridItem>
										{hasAnyAccount &&
											<GridItem xs={12}><br /><br />
												<GenericLabel FontSize={15} Text={"Please select Wallet from grid and click the submit button!"} />
												<GridContainer justify="flex-end">
													<GridItem>
														<Button onClick={this.HandleClickSave} size="sm" disabled={isFinished}>Save</Button>
													</GridItem>
													<GridItem>
														<Button onClick={this.HandleCloseTab} size="sm" >Close</Button>
													</GridItem>
												</GridContainer>
											</GridItem>
										}
									</GridContainer>
									:
									<iframe title="Account" src={flinksUrl} id="frameFlks" style={this.iFrameStyle} />
								}
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</ExternalContainer>

		);
	}
}

export default withStyles({})(KycPhotoUpload);