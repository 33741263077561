import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import PropTypes from "prop-types";
import React, { Component } from "react";
import AccountComponent from "views/Components/AccountComponent";
import { GenericExpansionPanel, GenericGrid } from "views/Components/Generic";

class BeneficiaryComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			vModel: {},
			isEdit: false,
			tempAccount: {},
			rowIndex: -1
		};

		this.Columns = [
			{
				Header: "Financial Institution",
				accessor: "FinancialInstitution"
			},
			{
				Header: "Financial Institution Branch",
				accessor: "FinancialInstitutionBranch"
			},
			{
				Header: "Wallet Number",
				accessor: "AccountNumber"
			},
		];
	}

	componentDidMount() {
	}

	HandleClear = () => {
		this.setState({ isEdit: false, rowIndex: -1 });
		this.props.handleClear();
	}

	AddAccount = () => {
		const { model, list } = this.props;
		const { vModel, tempAccount, isEdit } = this.state;
		var messages = [];

		if (model.FinancialInstitutionId == null) {
			vModel.FinancialInstitutionId = true;
			messages.push("Financial Institution cannot be empty");
		}
		else {
			vModel.FinancialInstitutionId = false;
		}

		if (model.FinancialInstitutionBranchId == null) {
			vModel.FinancialInstitutionBranchId = true;
			messages.push("Branch cannot be empty");
		} else {
			vModel.FinancialInstitutionBranchId = false;
		}

		if (model.AccountNumber == null || model.AccountNumber.trim() == "") {
			vModel.AccountNumber = true;
			messages.push("Wallet Number cannot be empty");
		}
		else {
			vModel.AccountNumer = false;
		}

		if (messages.length > 0) {
			this.props.showMessageNode("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
		}
		else {
			var index = list.indexOf(tempAccount);
			this.props.handleAddAccount(index, isEdit);
		}

		this.setState({ vModel });
	}

	DeleteAccount = () => {
		const { list } = this.props;
		var index = list.indexOf(this.state.tempAccount);
		this.props.handleDeleteAccount(index);
		this.setState({ isEdit: false, rowIndex: -1 });
	}

	RowSelected = (index) => {
		this.setState({ isEdit: true, tempAccount: this.props.list.filter(x => x.IsRecordValid)[index], rowIndex: index });
		this.props.selectedRowChange(index);
	}

	render() {
		const { model, list, title, labelText, ReadOnly,  Required,isPanelActive } = this.props;
		const { vModel, isEdit } = this.state;

		return (
			<div>
				<GenericExpansionPanel	Title={title} IsActive={isPanelActive}>
					<GridContainer>
						<GridItem xs={6} >
							<AccountComponent
								TitleLabel="FIID"
								TitleLabelBranch="Transit"
								LabelText={labelText}
								IsDCBankAccount={false}
								Model={model}
								VModel={vModel}
								ValueChanged={this.props.handleChange}
								Disabled={ReadOnly}
								Required={Required}
								MaxLength={20} />
						</GridItem>
						<GridItem xs={6} />
						<GridItem xs={8} />
						<GridItem xs={4}>
							<GridContainer justify="flex-end">
								<GridItem>
									<Button size="sm" onClick={this.AddAccount} disabled={ReadOnly}>{isEdit ? "EDIT" : "ADD"}</Button>
									<Button size="sm" onClick={this.DeleteAccount} disabled={!isEdit || ReadOnly}>DELETE</Button>
									<Button size="sm" onClick={this.HandleClear} disabled={ReadOnly}>CLEAR</Button>
								</GridItem>
							</GridContainer>
						</GridItem>
						<GridItem xs={12}>
							<br />
							<GenericGrid
								Data={list.filter(x => x.IsRecordValid).map(d => {
									return {
										FinancialInstitutionId: d.FinancialInstitution.Id,
										FinancialInstitution: `${d.FinancialInstitution.FinancialInstitutionId} - ${d.FinancialInstitution.Description}`,
										FinancialInstitutionBranchId: d.FinancialInstitutionBranch.Id,
										FinancialInstitutionBranch: `${d.FinancialInstitutionBranch.Description} - ${d.FinancialInstitutionBranch.TransitNumber}`,
										AccountNumber: d.AccountNumber
									};
								})}
								Columns={this.Columns}
								RowSelected={this.RowSelected}
								SelectedIndex={this.state.rowIndex}
								HideButton={true}
							/>
						</GridItem>
					</GridContainer>
				</GenericExpansionPanel>
			</div >
		);
	}
}

BeneficiaryComponent.propTypes = {
	classes: PropTypes.object.isRequired,
	list: PropTypes.array,
	model: PropTypes.object,
	handleChange: PropTypes.func,
	handleClear: PropTypes.func,
	handleAddAccount: PropTypes.func,
	handleDeleteAccount: PropTypes.func,
	selectedRowChange: PropTypes.func,
	title: PropTypes.string,
	labelText: PropTypes.string,
	showMessageNode: PropTypes.func,
	ReadOnly: PropTypes.bool,
	Disabled: PropTypes.bool,
	isPanelActive:PropTypes.bool
};

export default withArtifex(BeneficiaryComponent, {});