import { InputLabel, withStyles } from "@material-ui/core";
import genericLabelStyle from "assets/jss/generic/genericLabelStyle.jsx";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

class GenericLabel extends PureComponent {

	render() {
		const { classes, htmlFor, Text, TextColor, FontSize, Bold, IsPointer, MarginRight, TextAlign, Display, PaddingLeft, PaddingTop } = this.props;

		return (
			<InputLabel
				htmlFor={htmlFor}
				className={classes.label}
				style={{
					marginRight: MarginRight,
					paddingLeft: PaddingLeft,
					paddingTop: PaddingTop,
					color: TextColor,
					fontSize: FontSize,
					fontWeight: Bold ? "bold" : "normal",
					cursor: IsPointer ? "pointer" : "default",
					textAlign: TextAlign,
					display: Display
				}}>
				{Text}
			</InputLabel>
		);
	}
}

GenericLabel.propTypes = {
	classes: PropTypes.object.isRequired,
	Text: PropTypes.string,
	htmlFor: PropTypes.string,
	TextColor: PropTypes.string,
	FontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	Bold: PropTypes.bool,
	IsPointer: PropTypes.bool,
	TextAlign: PropTypes.string,
	Display: PropTypes.string,
	MarginRight: PropTypes.number,
	PaddingLeft: PropTypes.string,
	PaddingTop: PropTypes.string
};

GenericLabel.defaultProps = {
	TextColor: "#000",
	FontSize: "12px",
	Bold: false,
	IsPointer: false,
	MarginRight: 5,
	TextAlign: "inherit",
	Display: "unset"
};

export default withStyles(genericLabelStyle)(GenericLabel);