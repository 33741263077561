import Card from "components/Card/Card.jsx";
import kycManualPhotoDefinitionStyle from "assets/jss/material-dashboard-pro-react/views/kycManualPhotoDefinitionStyle.jsx";
import { withStyles } from "@material-ui/core";
import LoadingComponent from "views/Components/LoadingComponent";
import cx from "classnames";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import React from "react";
import PropTypes from "prop-types";

import ClientHelper from "core/ClientHelper";
import { GenericExpansionPanel, GenericSelectInput, GenericTextInput, GenericCardMedia, GenericLabel } from "views/Components/Generic";
import ExternalContainer from "layouts/ExternalContainer";
import { CrudType, FileType } from "views/Constants/Constant";
import FileHelper from "core/FileHelper";
import ImageHelper from "core/ImageHelper";

const galerydefault = require("assets/img/galerydefault-image.png");


class CardDesign extends React.PureComponent {

	constructor(props) {
		super(props);
		this.state = {
			alert: null,
			list: [],
			model: {
				UniqueClientId: ClientHelper.IsClient()
					? ClientHelper.GetClientRowId()
					: undefined,
				image: "",
			},
			data: [],
			isLoading: false,
			rowIndex: -1,
			modelList: [],
			photoBase64: null,
			openDialog: false,
			cameraLabel: null,
			imgSrc: null,
			isOpenkycUploadWindow: null
		};
		this.ParameterCardBrand = { ParameterCode: "Brand" };
		this.ParameterCardChipType = { ParameterCode: "CardChipType" };
		this.ParameterCardContactless = { ParameterCode: "CardContactlessColorType" };
		this.BackgroundColor = { ParameterCode: "BackgroundColor" };
		this.CardImageCodeType = { ParameterCode: "CardImageCodeType" };
		this.isBackOffice = ClientHelper.IsBackOffice();
		this.fileInputRef1 = React.createRef();
		this.maxLength5 = { maxLength: 5 };
	}

	componentDidMount() {
		if (this.props.setAppLeftTitle) {
			this.props.setAppLeftTitle("Card Design");
		}
		if (this.props.setAppCenterTitle) {
			this.props.setAppCenterTitle("Card Design");
		}
		var routedModel = JSON.parse(localStorage.getItem("CardDesign"));
		var model = { ...this.state.model };
		model = routedModel;
		this.setState({ model });
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}

	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}

	showDesign = () => {

		if (localStorage.getItem('CardImage1') === "null" || localStorage.getItem('CardImage2') === "null") {
			this.setState({ imgSrc: localStorage.getItem('CardImage') });
		} else {
			this.setState({ imgSrc: localStorage.getItem('CardImage1') + localStorage.getItem('CardImage2') });
		}
	}

	HandleFileSelect = async (e) => {

		const { model } = this.state;
		const { ExecuteApiPost } = this.props;

		e.preventDefault();
		var file1 = e.target.files[0];
		if (!file1) return;
		this.setState({ isLoading: true });

		if (((file1.size || 0) / 1024 / 1024) > 10) {
			this.props.showMessage("error", "Error", "Photo file size cannot be larger than 10mb");
			this.setState({ isLoading: false });
			return;
		}

		var blob = await ImageHelper.CompressImage(file1);//Start Compression if over 500kb
		var compressedBase64 = await FileHelper.BlobToBase64(blob);
		var compressedFile = FileHelper.DataURLtoFile(compressedBase64, file1.name);

		compressedBase64 = await ExecuteApiPost("/coreapi/v1.0/Image/ImageResize", { ImageBase64: compressedBase64 });

		var imageObj = await ImageHelper.GetImageObjectFromBase64(compressedBase64);
		console.log(imageObj.width);
		console.log(imageObj.height);
		if (imageObj?.width < 100 || imageObj?.height < 100) {
			await this.props.showMessage("error", "Error", "Any dimension of the image cannot be under 100 pixel");
			this.setState({ isLoading: false });
			return;
		}

		model.TempFileId = model.UserPhotoId || 0;
		model.File = compressedFile;
		model.FileName = file1.name;
		model.FileSelect = true;

		var cardNumber2 = document.getElementById("cardNumber2").textContent = "5032 9334 3764 9846";
		cardNumber2 = document.getElementById("cardNumber2").style.position = "absolute";
		cardNumber2 = document.getElementById("cardNumber2").style.left = "20px";
		cardNumber2 = document.getElementById("cardNumber2").style.bottom = "55px";
		cardNumber2 = document.getElementById("cardNumber2").style.color = "white";
		cardNumber2 = document.getElementById("cardNumber2").style.fontSize = "15px";
		cardNumber2 = document.getElementById("cardNumber2").style.font = "25px";

		var cardName2 = document.getElementById("cardName2").textContent = "Alexander Theodore";
		cardName2 = document.getElementById("cardName2").style.position = "absolute";
		cardName2 = document.getElementById("cardName2").style.left = "20px";
		cardName2 = document.getElementById("cardName2").style.bottom = "20px";
		cardName2 = document.getElementById("cardName2").style.color = "white";
		cardName2 = document.getElementById("cardName2").style.fontSize = "15px";

		var cardDate2 = document.getElementById("cardDate2").textContent = "09/19";
		cardDate2 = document.getElementById("cardDate2").style.position = "absolute";
		cardDate2 = document.getElementById("cardDate2").style.left = "160px";
		cardDate2 = document.getElementById("cardDate2").style.bottom = "30px";
		cardDate2 = document.getElementById("cardDate2").style.color = "white";
		cardDate2 = document.getElementById("cardDate2").style.fontSize = "15px";


		this.setState({ photoBase64: compressedBase64, isLoading: false });
	}

	handleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		console.log(model.CardBrandId);
		
		if (model.CardChipTypeId == 11100) {
			var documentry = document.getElementById("cardChipType2").style.width = "50px";
			documentry = document.getElementById("cardChipType2").style.height = "40px";
			documentry = document.getElementById("cardChipType2").style.left = "20px";
			documentry = document.getElementById("cardChipType2").style.bottom = "80px";
			documentry = document.getElementById("cardChipType2").style.position = "absolute";
			documentry = document.getElementById("cardChipType2").style.borderRadius = "5px";
			documentry = document.getElementById("cardChipType2").style.backgroundColor = "white";
		}
		else {
			var documentry = document.getElementById("cardChipType2").style.width = "";
			documentry = document.getElementById("cardChipType2").style.height = "";
			documentry = document.getElementById("cardChipType2").style.left = "";
			documentry = document.getElementById("cardChipType2").style.bottom = "";
			documentry = document.getElementById("cardChipType2").style.position = "";
			documentry = document.getElementById("cardChipType2").style.borderRadius = "";
			documentry = document.getElementById("cardChipType2").style.backgroundColor = "";
		}

		if (model.CardBrandId == 3400) {
			var documentry2 = document.getElementById("cardBrandId2").style.width = "80px";
			documentry2 = document.getElementById("cardBrandId2").style.height = "37px";
			documentry2 = document.getElementById("cardBrandId2").style.left = "235px";
			documentry2 = document.getElementById("cardBrandId2").style.bottom = "15px";
			documentry2 = document.getElementById("cardBrandId2").style.position = "absolute";
			documentry2 = document.getElementById("cardBrandId2").style.borderRadius = "10px";
			documentry2 = document.getElementById("cardBrandId2").style.backgroundColor = "white";
		}
		else if (model.CardBrandId == 3403) {
			var documentry2 = document.getElementById("cardBrandId2").style.width = "48px";
			documentry2 = document.getElementById("cardBrandId2").style.height = "48px";
			documentry2 = document.getElementById("cardBrandId2").style.left = "265px";
			documentry2 = document.getElementById("cardBrandId2").style.bottom = "15px";
			documentry2 = document.getElementById("cardBrandId2").style.position = "absolute";
			documentry2 = document.getElementById("cardBrandId2").style.borderRadius = "10px";
			documentry2 = document.getElementById("cardBrandId2").style.backgroundColor = "white";
		}
		else if (model.CardBrandId == 3401) {
			var documentry2 = document.getElementById("cardBrandId2").style.width = "80px";
			documentry2 = document.getElementById("cardBrandId2").style.height = "55px";
			documentry2 = document.getElementById("cardBrandId2").style.left = "235px";
			documentry2 = document.getElementById("cardBrandId2").style.bottom = "15px";
			documentry2 = document.getElementById("cardBrandId2").style.position = "absolute";
			documentry2 = document.getElementById("cardBrandId2").style.borderRadius = "30px";
			documentry2 = document.getElementById("cardBrandId2").style.backgroundColor = "white";
		}
		else {
			var documentry2 = document.getElementById("cardBrandId2").style.width = "";
			documentry2 = document.getElementById("cardBrandId2").style.height = "";
			documentry2 = document.getElementById("cardBrandId2").style.left = "";
			documentry2 = document.getElementById("cardBrandId2").style.bottom = "";
			documentry2 = document.getElementById("cardBrandId2").style.position = "";
			documentry2 = document.getElementById("cardBrandId2").style.borderRadius = "";
			documentry2 = document.getElementById("cardBrandId2").style.backgroundColor = "";
		}

		if (model.CardContactlessColorTypeId == 11102) {
			var documentry3 = document.getElementById("cardContactlessColorType2").style.position = "absolute";
			documentry3 = document.getElementById("cardContactlessColorType2").style.width = "40px";
			documentry3 = document.getElementById("cardContactlessColorType2").style.height = "40px";
			documentry3 = document.getElementById("cardContactlessColorType2").style.left = "275px";
			documentry3 = document.getElementById("cardContactlessColorType2").style.bottom = "100px";
			documentry3 = document.getElementById("cardContactlessColorType2").style.borderRadius = "40px";
			documentry3 = document.getElementById("cardContactlessColorType2").style.backgroundColor = "black";
		}
		else if (model.CardContactlessColorTypeId == 11104) {
			var documentry3 = document.getElementById("cardContactlessColorType2").style.position = "absolute";
			documentry3 = document.getElementById("cardContactlessColorType2").style.width = "40px";
			documentry3 = document.getElementById("cardContactlessColorType2").style.height = "40px";
			documentry3 = document.getElementById("cardContactlessColorType2").style.left = "275px";
			documentry3 = document.getElementById("cardContactlessColorType2").style.bottom = "100px";
			documentry3 = document.getElementById("cardContactlessColorType2").style.borderRadius = "40px";
			documentry3 = document.getElementById("cardContactlessColorType2").style.backgroundColor = "white";
		}
		else {
			var documentry3 = document.getElementById("cardContactlessColorType2").style.position = "";
			documentry3 = document.getElementById("cardContactlessColorType2").style.width = "";
			documentry3 = document.getElementById("cardContactlessColorType2").style.height = "";
			documentry3 = document.getElementById("cardContactlessColorType2").style.left = "";
			documentry3 = document.getElementById("cardContactlessColorType2").style.bottom = "";
			documentry3 = document.getElementById("cardContactlessColorType2").style.borderRadius = "";
			documentry3 = document.getElementById("cardContactlessColorType2").style.backgroundColor = "";
		}

		if(model.BackgroundColorId == 11105 || model.BackgroundColorId == 11109 || model.BackgroundColorId == 11107){
			document.getElementById("cardName2").style.color = "black";
			document.getElementById("cardNumber2").style.color = "black";
			document.getElementById("cardDate2").style.color = "black";
		}
		else if(model.BackgroundColorId == 11106 || model.BackgroundColorId == 11110 || model.BackgroundColorId == 11108){
			document.getElementById("cardName2").style.color = "white";
			document.getElementById("cardNumber2").style.color = "white";
			document.getElementById("cardDate2").style.color = "white";
		}

		if(model.CardImageCodeTypeId === "1"){
			const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
			function generateString(length) {
				 let result = '';
				 const charactersLength = characters.length;
				 for ( let i = 0; i < length; i++ ) {
					  result += characters.charAt(Math.floor(Math.random() * charactersLength));
				 }
			
				 return result;
			}			
			model.CardImageCode = generateString(5);
		}
		else{
			model.CardImageCode = "";
		}

		this.setState({ model });
	}

	handleCloseTab = async () => {
		window.close();

	}
	HandleSubmit = async () => {
		const { ExecuteApiFileUpload, ExecuteApiImageUpload, ExecuteApiPost } = this.props;
		var { model, modelList, photoBase64 } = this.state;
		if(model.CardContactlessColorTypeId == undefined || model.CardChipTypeId == 922703 || model.CardContactlessColorTypeId == null || model.CardContactlessColorTypeId == 0) {
			model.CardContactlessColorTypeId = 11103;
		}

		if ((model.CardImageCode == null || model.CardImageCode == undefined) ||
			(model.CardBrandId == null || model.CardBrandId == undefined) ||
			(model.CardChipTypeId == null || model.CardChipTypeId == undefined) ||
			(model.BackgroundColorId == null || model.BackgroundColorId == undefined) ||
			(model.CardImageCodeTypeId == null || model.CardImageCodeTypeId == undefined || model.CardImageCodeTypeId === "")){
			this.props.showMessage("warning", "Please fill required fields!");
			return;
		}

		var formData = new FormData();
		formData.append("file", model.File, model.FileName);
		formData.append("UniqueClientId", model.UniqueClientId);
		formData.append("CardImageCode", model.CardImageCode);
		formData.append("CardBrandId", model.CardBrandId);
		formData.append("CardContactlessColorTypeId", model.CardContactlessColorTypeId);
		formData.append("CardChipTypeId", model.CardChipTypeId);
		formData.append("BackgroundColorId", model.BackgroundColorId);
		formData.append("PhotoBase64", photoBase64);
		formData.append("isBinaryOrBase64", FileType.Base64);
		formData.append("isInsertOrUpdate", CrudType.Insert);
		formData.append("PlasticName", model.PlasticName);
		model.UserPhotoId = null;
		this.setState({ model });
		var result = await ExecuteApiImageUpload("/coreapi/v1.0/File/UploadImage", formData);
		if (result.IsSuccessed == false) {
			this.props.showMessage("error", "Error", result.ErrorDescription);
			return;
		}
		else if (result.IsSucceeded == true) {
			this.props.showMessage("success", "Success", "Image Uploaded Successfully");
		}

		this.HandleClear();
	}

	OpenFileSelectDialog = () => {
		this.fileInputRef1.current.click();
	}

	HandleClear = () => {
		const nModel={...this.state.model};

		this.setState({ model: {
			Id : 0,
			BackgroundColorId : 0,
			CardBrandId : 0,
			CardChipTypeId : 0,
			CardContactlessColorTypeId : 0,
			CardImageCode : "",
			CardImageCodeTypeId : "",
			UniqueClientId : nModel.UniqueClientId,
			File : {},
			FileName : "",
			FileSelect : false,
			TempFileId: 0,
			UserPhotoId: null,
			image: "",
			classses: {},
			PlasticName : ""
		}, rowIndex: -1, isEdit: false, index: -1, photoBase64: null });

		var documentry = document.getElementById("cardChipType2").style.width = "";
		documentry = document.getElementById("cardChipType2").style.height = "";
		documentry = document.getElementById("cardChipType2").style.left = "";
		documentry = document.getElementById("cardChipType2").style.bottom = "";
		documentry = document.getElementById("cardChipType2").style.position = "";
		documentry = document.getElementById("cardChipType2").style.borderRadius = "";
		documentry = document.getElementById("cardChipType2").style.backgroundColor = "";

		var documentry2 = document.getElementById("cardBrandId2").style.width = "";
		documentry2 = document.getElementById("cardBrandId2").style.height = "";
		documentry2 = document.getElementById("cardBrandId2").style.left = "";
		documentry2 = document.getElementById("cardBrandId2").style.bottom = "";
		documentry2 = document.getElementById("cardBrandId2").style.position = "";
		documentry2 = document.getElementById("cardBrandId2").style.borderRadius = "";
		documentry2 = document.getElementById("cardBrandId2").style.backgroundColor = "";

		var documentry3 = document.getElementById("cardContactlessColorType2").style.position = "";
		documentry3 = document.getElementById("cardContactlessColorType2").style.width = "";
		documentry3 = document.getElementById("cardContactlessColorType2").style.height = "";
		documentry3 = document.getElementById("cardContactlessColorType2").style.left = "";
		documentry3 = document.getElementById("cardContactlessColorType2").style.bottom = "";
		documentry3 = document.getElementById("cardContactlessColorType2").style.borderRadius = "";
		documentry3 = document.getElementById("cardContactlessColorType2").style.backgroundColor = "";

		var cardNumber2 = document.getElementById("cardNumber2").textContent = "";
		cardNumber2 = document.getElementById("cardNumber2").style.position = "";
		cardNumber2 = document.getElementById("cardNumber2").style.left = "";
		cardNumber2 = document.getElementById("cardNumber2").style.bottom = "";
		cardNumber2 = document.getElementById("cardNumber2").style.color = "";
		cardNumber2 = document.getElementById("cardNumber2").style.fontSize = "";
		cardNumber2 = document.getElementById("cardNumber2").style.font = "";

		var cardName2 = document.getElementById("cardName2").textContent = "";
		cardName2 = document.getElementById("cardName2").style.position = "";
		cardName2 = document.getElementById("cardName2").style.left = "";
		cardName2 = document.getElementById("cardName2").style.bottom = "";
		cardName2 = document.getElementById("cardName2").style.color = "";
		cardName2 = document.getElementById("cardName2").style.fontSize = "";

		var cardDate2 = document.getElementById("cardDate2").textContent = "";
		cardDate2 = document.getElementById("cardDate2").style.position = "";
		cardDate2 = document.getElementById("cardDate2").style.left = "";
		cardDate2 = document.getElementById("cardDate2").style.bottom = "";
		cardDate2 = document.getElementById("cardDate2").style.color = "";
		cardDate2 = document.getElementById("cardDate2").style.fontSize = "";

		console.log(nModel);
	}

	render() {
		const { model, photoBase64, isLoading } = this.state;
		const { classes, ExecuteApiPost } = this.props;
		const mainLogo = require("assets/img/" + window.MainLogo);

		return (
			<div>

				<ExternalContainer Title="Card Image Upload" HalfWidth={false} Base64Logo={mainLogo}>
					<LoadingComponent Show={isLoading} />
					<GridContainer>
						<GridItem xs={12}>
							<GridContainer>
								<GridItem xs={12}>
									<Card>
										<CardBody>
											<br />
											<br />
											<GridContainer>
												<GridItem xs={4}>
													<div style={{width: 323, height: 204 }}>
														<GenericCardMedia
															ExecuteApiPost={ExecuteApiPost}
															className={classes.imageProfile}
															ImageFileId={model.Id}
															Image={photoBase64}
															ImagePlaceholder={galerydefault}
														/>
													</div>
													<br></br>
													<br></br>
													<Button
														size="sm"
														onClick={this.OpenFileSelectDialog}>
														Select Photo
													</Button>
													<input type="file" style={{ display: "none" }} onChange={this.HandleFileSelect} ref={this.fileInputRef1} accept="image/png,image/jpeg" />
												</GridItem>
												<GridItem xs={4}>
													<div id="parent" style={{ position: "relative", width: 323, height: 204 }}>
														<GenericCardMedia
															ExecuteApiPost={ExecuteApiPost}
															className={classes.imageProfile}
															ImageFileId={model.Id}
															Image={photoBase64}
															ImagePlaceholder={galerydefault}
														/>
														<div id="cardNumber2"></div>
														<div id="cardName2"></div>
														<div id="cardDate2"></div>
														<div id="cardChipType2"></div>
														<div id="cardBrandId2"></div>
														<div id="cardContactlessColorType2"></div>
													</div>
													<br></br>
													<br></br>
												</GridItem>
											</GridContainer>
											<GridContainer>
												<GridItem xs={5}>
													<GenericSelectInput
														Name="CardBrandId"
														LabelText="Card Brand"
														Method="POST"
														Url="/coreapi/v1.0/Parameter/Search"
														Parameter={this.ParameterCardBrand}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="ParameterDesc"
														Value={model.CardBrandId || ""}
														ValueChanged={this.handleChange}
														Sorted={{ Member: "ParameterDesc" }}
														Disabled={!photoBase64}
													/>
													<GenericSelectInput
														Name="CardChipTypeId"
														key={model.CardBrandId + "CardChipTypeId"}
														LabelText="Card Chip Type"
														Method="POST"
														Url="/coreapi/v1.0/Parameter/Search"
														Parameter={{
															ParameterCode: "CardChipType",
															ParameterValue: `${model.CardBrandId == 3403 ? "C" : ""}`,
														}}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="ParameterDesc"
														Value={model.CardChipTypeId || ""}
														ValueChanged={this.handleChange}
														Sorted={{ Member: "ParameterDesc" }}
														Disabled={!photoBase64}
													/>
													<GenericSelectInput
														Name="CardContactlessColorTypeId"
														LabelText="Card Contactless Color Type"
														Method="POST"
														Url="/coreapi/v1.0/Parameter/Search"
														Parameter={this.ParameterCardContactless}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="ParameterDesc"
														Value={model.CardContactlessColorTypeId || ""}
														ValueChanged={this.handleChange}
														Sorted={{ Member: "ParameterDesc" }}
														Disabled={model.CardChipTypeId != 11100}
													/>
													<GenericSelectInput
														Name="BackgroundColorId"
														key={model.CardBrandId}
														LabelText="Emboss Font Color"
														Method="POST"
														Url="/coreapi/v1.0/Parameter/Search"
														Parameter={{
															ParameterCode: "BackgroundColor",
															ParameterValue4: `${model.CardBrandId == 3400 ? "V" : model.CardBrandId == 3401 ? "M" : model.CardBrandId == 3403 ? "I" : ""}`,
														}}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="ParameterDesc"
														Value={model.BackgroundColorId || ""}
														ValueChanged={this.handleChange}
														Sorted={{ Member: "ParameterDesc" }}
														Disabled={!photoBase64}
													/>
													<GenericSelectInput
														Name="CardImageCodeTypeId"
														LabelText="Card Image Code Type"
														Method="POST"
														Url="/coreapi/v1.0/Parameter/Search"
														Parameter={this.CardImageCodeType}
														DataRoot="Item"
														KeyValueMember="ParameterValue2"
														TextValueMember="ParameterDesc"
														Value={model.CardImageCodeTypeId || ""}
														ValueChanged={this.handleChange}
														Sorted={{ Member: "ParameterDesc" }}
														Disabled={!photoBase64}
													/>
													<GenericTextInput
														Name="CardImageCode"
														LabelText="Card Image Code"
														Value={model.CardImageCode}
														ValueChanged={this.HandleChange}
														inputProps={this.maxLength5}
														Disabled={!model.CardImageCodeTypeId || model.CardImageCodeTypeId == "1"}
													/>
													<GenericTextInput
														Name="PlasticName"
														LabelText="Plastic Name"
														Value={model.PlasticName}
														ValueChanged={this.HandleChange}
														Disabled={!photoBase64}
													/>

												</GridItem>
											</GridContainer>
											<br></br>
											<br></br>
											<GridContainer>
												<GridItem xs={2}>
													<Button
														size="sm"
														onClick={this.HandleSubmit}
														disabled={!model.UniqueClientId}
													>
														Submit
													</Button>
													<Button
														size="sm"
														onClick={this.handleCloseTab}
													>
														Close
													</Button>
													<GridItem style={{ marginLeft: 4, marginTop: 4 }}>
													<GenericLabel Bold={false}  FontSize="11px" TextColor="gray" Text="Dimensions need to be 2046 x 1296" />
												</GridItem>
													
													<GridItem style={{ marginLeft: 4, marginTop: 4 }}>
													
													<GenericLabel Bold={false}  FontSize="11px" TextColor="gray" Text=".png Format" />
												</GridItem>
												</GridItem>
											</GridContainer>
										</CardBody>
									</Card>
								</GridItem>
							</GridContainer>
						</GridItem>
					</GridContainer>
				</ExternalContainer>



			</div >
		);
	}
}
CardDesign.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	ExecuteApiFileUpload: PropTypes.func,
	ExecuteApiImageUpload: PropTypes.func,
	After: PropTypes.any
};
export default withStyles(kycManualPhotoDefinitionStyle)(CardDesign);
