import { createMuiTheme, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, MuiThemeProvider, withStyles } from "@material-ui/core";
import genericExpansionPanelStyle from "assets/jss/material-dashboard-pro-react/views/genericExpansionPanelStyle.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import React from "react";
import GenericIcon from "views/Components/Generic/GenericIcon";
const theme = createMuiTheme({
	overrides: {
		MuiCollapse: {
			entered: {
				height: "auto",
				overflow: "visible"
			}
		}
	},
	palette: {
		primary: { main: window.PageMainColor }
	},
	typography: {
		useNextVariants: true
	}
});

class GenericExpansionPanelCustomHeader extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			active: true,
			title: "Filter Panel"
		};
	}

	handleChange(expanded) {
		const { Name, ValueChanged } = this.props;
		this.setState({
			active: expanded
		});

		if (ValueChanged) {
			ValueChanged(Name, expanded);
		}
	}

	componentDidMount() {
		if (this.props.IsActive != null) {
			this.setState({ active: this.props.IsActive });
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.IsActive != prevProps.IsActive) {
			this.setState({ active: this.props.IsActive });
		}
	}

	render() {
		const { classes, children, AlignToRight, Header } = this.props;
		const { active } = this.state;

		return (
			<div className={classes.root}>
				<MuiThemeProvider theme={theme}>
					<ExpansionPanel
						expanded={active}
						onChange={() => this.handleChange(!active)}
						classes={{
							root: classes.expansionPanel,
							expanded: classes.expansionPanelExpanded
						}}
					>
						<ExpansionPanelSummary
							classes={{
								root: classes.expansionPanelSummary,
								expanded: classes.expansionPanelSummaryExpaned,
								content: classes.expansionPanelSummaryContent,
								expandIcon: classes.expansionPanelSummaryExpandIcon
							}}
						>
							<GridContainer spacing={16} justify={AlignToRight ? "flex-end" : "space-between"} alignItems="center">
								<GridItem>
									<CardHeader>
										<Header/>
									</CardHeader>
								</GridItem>
								<GridItem>
									<GenericIcon className={classes.expansionPanelSummaryExpandIcon} onClick={() => this.handleChange(!active)} >
										expand_more
									</GenericIcon>
								</GridItem>
							</GridContainer>
						</ExpansionPanelSummary>
						<ExpansionPanelDetails className={classes.expansionPanelDetails}>
							{children}
						</ExpansionPanelDetails>
					</ExpansionPanel>
				</MuiThemeProvider>
			</div>
		);
	}
}

GenericExpansionPanelCustomHeader.propTypes = {
	classes: PropTypes.object.isRequired,
	Name: PropTypes.string,
	Header: PropTypes.func,
	IsActive: PropTypes.bool,
	AlignToRight: PropTypes.bool,
	ValueChanged: PropTypes.any
};

export default withStyles(genericExpansionPanelStyle)(GenericExpansionPanelCustomHeader);
