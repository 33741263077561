import withStyles from "@material-ui/core/styles/withStyles";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import AlertHelper from "core/AlertHelper";
import PropTypes from "prop-types";
import React from "react";
import { OperationType } from "views/Constants/Constant";
import CommissionProfileAddOrUpdate from "./CommissionProfileAddOrUpdate";
import CommissionProfileDataTable from "./CommissionProfileDataTable";

const styles = theme => ({
	...sweetAlertStyle
});

const initialModel = {
	ProfileId: "",
	ProfileName: ""
};

class CommissionFeeProfile extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			list: [],
			model: initialModel,
			operationType: OperationType.DEFAULT
		};

		this.handleOperationType = this.handleOperationType.bind(this);

		this.handleGetList = this.handleGetList.bind(this);
		this.successListCallback = this.successListCallback.bind(this);
		this.errorListCallback = this.errorListCallback.bind(this);

		this.showSuccessMessage = this.showSuccessMessage.bind(this);
		this.showErrorMessage = this.showErrorMessage.bind(this);
		this.hideAlert = this.hideAlert.bind(this);
	}

	componentDidMount() {
		this.handleGetList();
	}

	handleOperationType(value, model = initialModel) {
		this.setState({ operationType: value, model });
	}

	handleGetList() {
		Proxy.GET(
			"/bankapi/v1.0/CommissionProfile/GetAll",
			this.successListCallback,
			this.errorListCallback
		);
	}

	successListCallback(responseData) {
		if (!responseData.IsSucceeded) {
			this.showErrorMessage(responseData.ErrorDescription);
			return;
		}
		if (responseData.Item !== null) {
			this.setState({ list: responseData.Item });
		} else {
			this.setState({ list: [] });
		}
	}

	errorListCallback(error) {
		this.showErrorMessage("An error occurred while requesting data. Error: " + error);
	}

	showSuccessMessage(message) {
		this.setState({
			alert: AlertHelper.CreateAlert("Success", message, "success", this.hideAlert)
		});
	}

	showErrorMessage(message) {
		this.setState({
			alert: AlertHelper.CreateAlert("Error", message, "error", this.hideAlert)
		});
	}

	hideAlert() {
		this.setState({ alert: null });
	}

	render() {
		const { operationType } = this.state;

		return (
			<div>
				<GridContainer spacing={16}>
					{this.state.alert}
					<GridItem md={12} xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GridContainer spacing={16}>
									<GridItem md={12} xs={12}>
										{operationType === OperationType.DEFAULT &&
											<CommissionProfileDataTable
												list={this.state.list}
												handleGetList={this.handleGetList}
												handleOperationType={this.handleOperationType} />
										}
										{operationType !== OperationType.DEFAULT &&
											<CommissionProfileAddOrUpdate
												model={this.state.model}
												handleGetList={this.handleGetList}
												handleOperationType={this.handleOperationType} />
										}
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

CommissionFeeProfile.propTypes = {
	classes: PropTypes.object
};

export default withStyles(styles)(CommissionFeeProfile);
