import { Dialog, DialogContent } from "@material-ui/core";
import CardMedia from "@material-ui/core/CardMedia";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import PropTypes from "prop-types";
import React from "react";
import Recaptcha from "react-recaptcha";
import { UserConstants } from "store";
import { GenericAlert, GenericCircularProgress, GenericEmailInput, GenericTextInput, GenericTitle, GenericLabel, GenericSelectInput, GenericNumberInput } from "views/Components/Generic";
import RouteHelper from "core/RouteHelper";
import LoadingComponent from "views/Components/LoadingComponent";

class InteracConfirmPaymentReview extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			alert: null,
			isLoading: false,
			model: {}
		};

		this.ParameterMaxLength100 = { maxLength: 100 };
	}

	componentDidMount() {
		var data = RouteHelper.Read("InteracData");
		this.setState({ model: data || {} });
		console.log(data);
	}

	Submit = () => {
		var data = RouteHelper.Read("InteracData");
		data.Message = this.state.model.Message;


		if (this.state.model.Message == null || this.state.model.Message == "") {
			this.ShowMessage("error", "Error", "Wallet must be selected !");
			return;
		}

		//todo

		console.log(data);
		RouteHelper.Push(this.props.history, "/InteracConfirm/Result", "InteracData", data);
	}

	FetchCompleteTransfer = () => {
		var data = RouteHelper.Read("InteracData");
		var temp = {};
		temp.participantUserId = data.pID;
		temp.transferReferenceNumber = data.src;
	
		

		console.log("CompleteTransfer Request");
		console.log(JSON.parse(temp));

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/InteracConfirmPayment/CompleteTransfer",
			temp,
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				console.log("CompleteTransfer");
				console.log(JSON.parse(responseData));

				if (responseData.Item != null) {
					var data = RouteHelper.Read("InteracData");
					data.DeclineTransferResponse = responseData.Item;
					localStorage.setItem("InteracData", JSON.stringify(data));
				} else {
					console.log("CompleteTransfer response null");
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	Cancel = () =>{
		RouteHelper.Push(this.props.history, "/InteracConfirm/Process");
	}

	handleChange = (name, newValue) => {
		this.setState(function (state, props) {
			var model = state.model || {};
			model[name] = newValue;
			return { model };
		});
	}
	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	ShowMessage = (type, title, message) => {
		// type:-success->warning->info->error
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}

	render() {
		const { classes } = this.props;
		const { model, isLoading, alert } = this.state;

		return (
			<div className={classes.container}>
				{alert}
				<LoadingComponent Show={isLoading} />			
				<GridContainer justify="center">
					<GridItem xs={12} sm={6} md={7}>
						<Card>
							<CardHeader>
							</CardHeader>
							<CardBody>
								<GridContainer container direction="column" justify="flex-start" alignItems="stretch" style={{ padding: "25px" }}>
									<GenericTitle text="Confirm Interac e-Transfer" />
									<br /><br />
									<GridItem>
										<GenericLabel
											Bold={false}
											FontSize="15px"
											TextColor="black"
											Text="Please confirm the following information." />
									</GridItem>
									<br />
									<GridItem>
										<GenericNumberInput
											Disabled={true}
											Prefix="$ "
											Name="Amount"
											LabelText="Transaction Amount"
											Value={model.Amount}
											ValueChanged={this.handleChange}
										/>
									</GridItem>
									<GridItem>
										<GenericSelectInput
											Name="AccountId"
											LabelText="To Wallet"
											Method="POST"
											Url="/bankapi/v1.0/InteracConfirmPayment/GetInteractLoadAccount"
											Parameter={{}}
											DataRoot="Item"
											KeyValueMember="Id"
											TextValueMember="AccountName"
											Value={model.AccountId}
											ValueChanged={this.handleChange}
										/>
									</GridItem>
									<GridItem>
										<GenericTextInput
											inputProps={this.ParameterMaxLength100}
											MultiLine={true}
											RowCount={4}
											Name="Message"
											LabelText="Message"
											Value={model.Message}
											ValueChanged={this.handleChange} />
									</GridItem>
									<br />
									<GridItem>
										<GridContainer>
											<GridItem md={3}>
												<Button color="primary" size="md" block onClick={() => this.Cancel()}>
													Cancel
												</Button>
											</GridItem>
											<GridItem md={6}></GridItem>
											<GridItem md={3}>
												<Button color="primary" size="md" block onClick={() => this.Submit()}>
													Confirm >
												</Button>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

InteracConfirmPaymentReview.propTypes = {
	classes: PropTypes.object
};

export default withArtifex(InteracConfirmPaymentReview, loginPageStyle);