import { warningColor } from "assets/jss/material-dashboard-pro-react.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";

const merchantProfileStyle = {
	iconbuttoncolor: {
		color: warningColor
	},
	tabsHeader: {
		background: warningColor
	},
	root: {
		flexGrow: 1,
	},
	...customSelectStyle,
	...sweetAlertStyle,
	...customCheckboxRadioSwitch
};

export default merchantProfileStyle;
