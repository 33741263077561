import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React from "react";
import { GenericGrid } from "views/Components/Generic";
import { GridColumnType } from "views/Constants/Constant";

class SiteVisitDataTable extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { classes, list } = this.props;
		return (
			<GridContainer>
				<GridItem xs={12}>
					<Card className="no-radius">
						<CardBody>
							<GridContainer spacing={16}>
								<GridItem xs={12}>
									<GenericGrid
										Data={list}
										Columns={[
											{
												Header: "Legal Name",
												accessor: "LegalName"
											},
											{
												Header: "DBA",
												accessor: "Dba"
											},
											{
												Header: "Date of Visit",
												accessor: "DateOfVisit",
												type: GridColumnType.Date
											},
											{
												Header: "DCBank Representative in Attendance",
												accessor: "DcbRepresentInAttandance"
											},
											{
												Header: "Wallet Representative in Attendance",
												accessor: "AccountRepresentInAttandance"
											},
											{
												Header: "Adress Line 1",
												accessor: "AddressLine1"
											},
											{
												Header: "Adress Line 2",
												accessor: "AddressLine2"
											},
											{
												Header: "Province",
												accessor: "Province"
											},
											{
												Header: "PostalCode",
												accessor: "ZipCode"
											},
											{
												Header: "Country",
												accessor: "Country"
											},
											{
												Header: "City",
												accessor: "City"
											}
										]}
										RowSelected={index => {
											const model = this.props.list[index];
											this.props.handleSelectModel(model, index);
										}}
										SelectedIndex={this.props.selected}
									/>
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

SiteVisitDataTable.propTypes = {
	classes: PropTypes.object,
	list: PropTypes.array,
	selected: PropTypes.number,
	model: PropTypes.object,
	handleChange: PropTypes.func,
	handleGetList: PropTypes.func,
	handleSelectModel: PropTypes.func
};

export default withArtifex(SiteVisitDataTable, {});
