import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import { primaryColor } from "assets/jss/material-dashboard-pro-react.jsx";
import { Proxy, withArtifex } from "core";
import { ProfileIcon, EditIcon } from "core/Icons";
import PropTypes from "prop-types";
import React from "react";
import ClientHelper from "core/ClientHelper";
import { GenericTitle, GenericAlert,  GenericIcon, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import { ProgramCodes } from "views/Constants/Constant.js";
import AccountComponent from "views/Components/AccountComponent";
import CustomerComponent from "views/Components/CustomerComponent";
import ParameterComponent from "views/Components/ParameterComponent";
import ToolbarButton from "views/Components/ToolbarButton";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import Button from "components/CustomButtons/Button.jsx";
import LoadingComponent from "views/Components/LoadingComponent";

class FeeProfiles extends React.Component {
	constructor(props) {
		super(props);

		this.emptyProfile = {
			Id: 0,
			IsRecordValid: true,
			ProfileName: null,
			UniqueClientId: null,
			DetailList : null
		}

		this.emptyDetail = {
			Index: null,
			Id: 0,
			IsRecordValid: true,
			ProfileId: 0,
			SupplierId: null,
			Supplier: null,
			CountryId: null,
			Country: null,
			TransferSourceTypeId: null,
			TransferSourceType: null,
			TransferMethodId: null,
			TransferMethod: null,
			SupplierFee: 0.00,
			FeeMarkup: 0.00,
			FxMarkup: 0.00
		}

		this.initialModel = {
			SelectedFeeProfile: Object.assign({}, this.emptyProfile),
			SelectedTransactionFee: Object.assign({}, this.emptyDetail),
			ProfileList: [],
			DetailList: [],
		};

		this.feeProfileColumns = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<ToolbarButton
							color="primary"
							justIcon
							simple
							size="sm"
							tooltip="Edit"
							onClick={() => { this.handleSelectProfile(row.original, row.index); }}>
							<img src={EditIcon} />
						</ToolbarButton>
					</div>
				),
				sortable: false,
				filterable: false,
				resizable: false,
				width: 50
			},
			{
				Header: "Profile Name",
				accessor: "ProfileName"
			}
		];


		if (ClientHelper.IsClient()) {
			this.emptyProfile.UniqueClientId = ClientHelper.GetClientId();
		} else {
			this.emptyProfile.UniqueClientId = null;
		}
		this.feeProfileColumns.push({
			Header: "",
			Cell: row => (<div></div>)
		});

		this.feeItemColumns = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<ToolbarButton
							color="primary"
							justIcon
							simple
							size="sm"
							tooltip="Edit"
							onClick={() => { this.handleSelectDetail(row.original, row.index); }}>
							<img src={EditIcon} />
						</ToolbarButton>
					</div>
				),
				sortable: false,
				filterable: false,
				resizable: false,
				width: 60
			},
			{
				Header: "Profile Name",
				accessor: "Profile.ProfileName"
			},
			{
				Header: "Supplier",
				accessor: "Supplier.Title"
			},
			{
				Header: "Country",
				accessor: "Country.Name"
			},
			{
				Header: "Transfer method",
				accessor: "TransferMethod.ParameterDesc"
			},
			{
				Header: "Transfer Source",
				accessor: "TransferSourceType.ParameterDesc"
			}
		];

		this.state = {
			model: Object.assign({}, this.initialModel),
			isLoading: false
		};
	};

	toArray = (value) => {
		var returnList = [];
		if (value != null && value[0]) {
			for (const key in value) {
				if (value.hasOwnProperty(key)) {
					const element = value[key];
					returnList.push(element);
				}
			}
		}
		return returnList;
	};

	componentDidMount() {
		this.props.setAppLeftTitle("Fee Profiles");
		this.props.setAppCenterTitle("FX TRANSFER");
	}

	handleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		const emptyProfile = { ...this.emptyProfile };
		if (name === "UniqueClientId") {
			if (
				ClientHelper.IsClient() &&
				emptyProfile.UniqueClientId == null &&
				newValue != null
			) {
				this.initialModel.UniqueClientId = newValue;
			}
			model.SelectedFeeProfile.UniqueClientId = newValue;
		} else if (name === "ProfileName") {
			model.SelectedFeeProfile.ProfileName = newValue;
		} else if (name == "SupplierId") {
			model.SelectedTransactionFee.SupplierId = newValue;
			model.SelectedTransactionFee.Supplier = data;
		} else if (name == "CountryId") {
			model.SelectedTransactionFee.CountryId = newValue;
			model.SelectedTransactionFee.Country = data;
		} else if (name == "FeeMarkup") {
			model.SelectedTransactionFee.FeeMarkup = newValue;
		} else if (name == "SupplierFee") {
			model.SelectedTransactionFee.SupplierFee = newValue;
		} else if (name == "FxMarkup") {
			model.SelectedTransactionFee.FxMarkup = newValue;
		} else if (name == "TransferMethodId") {
			model.SelectedTransactionFee.TransferMethodId = newValue;
			model.SelectedTransactionFee.TransferMethod = data;
		} else if (name == "TransferSourceTypeId") {
			model.SelectedTransactionFee.TransferSourceTypeId = newValue;
			model.SelectedTransactionFee.TransferSourceType = data;
		}
		this.setState({ model });
	};

	handleClear = () => {
		this.setState({
			model: {
				SelectedFeeProfile: Object.assign({}, this.emptyProfile),
				SelectedTransactionFee: Object.assign({}, this.emptyDetail),
				ProfileList: [],
				DetailList: [],
			}
		});
	};

	handleSearch = () => {
		this.executeSearch();
	};

	handleSelectProfile = (data, index) => {
		const model = { ...this.state.model };

		var feeProfile = Object.assign({}, data);
		var feeItemList = Object.assign({}, data.DetailList);

		model.SelectedFeeProfile = feeProfile;
		model.SelectedTransactionFee = Object.assign({}, this.emptyDetail);
		model.DetailList = this.toArray(feeItemList);

		this.setState({ model });
	};

	handleSelectDetail = (data, index) => {
		const model = { ...this.state.model };


		model.SelectedTransactionFee = Object.assign({}, data);
		model.SelectedTransactionFee.Index = index;

		this.setState({ model });
	};

	handleAddDetail = () => {
		const model = { ...this.state.model };

		var newItem = Object.assign({}, model.SelectedTransactionFee);

		if (!this.validateNewDetail(newItem, model.DetailList)) {
			return;
		}

		var temporary = [];
		if (newItem.Id == 0) {
			newItem.ProfileId = model.SelectedFeeProfile.Id;
			newItem.CardTransactionFeeProfile = model.SelectedFeeProfile;

			if (newItem.Index != null) {
				for (let index = 0; index < model.DetailList.length; index++) {
					const element = model.DetailList[index];
					temporary.push(index == newItem.Index ? newItem : element);
				}
			} else {
				model.DetailList.forEach((value, index, array) => { temporary.push(value); });
				temporary.push(newItem);
			}
		} else {
			model.DetailList.forEach(element => {
				if (element.Id == newItem.Id) {
					temporary.push(newItem);
				} else {
					temporary.push(element);
				}
			});
		}
		model.SelectedTransactionFee = Object.assign({}, this.emptyDetail);
		model.DetailList = temporary;
		this.setState({ model });
	}

	handleDeleteDetail = () => {
		const model = { ...this.state.model };

		var newItem = Object.assign({}, model.SelectedTransactionFee);

		var temporary = [];
		if (newItem.Id != 0) {
			newItem.IsRecordValid = false;
			model.DetailList.forEach(element => {
				if (element.Id != newItem.Id) { temporary.push(element); }
			});
		} else {
			for (let index = 0; index < model.DetailList.length; index++) {
				const element = model.DetailList[index];
				if (index != newItem.Index) { temporary.push(element); }
			}
		}
		model.SelectedTransactionFee = Object.assign({}, this.emptyDetail);
		model.DetailList = temporary;
		this.setState({ model });
	}

	handleClearDetail = () => {
		const model = { ...this.state.model };
		model.SelectedTransactionFee = Object.assign({}, this.emptyDetail);
		this.setState({ model });
	}

	executeSearch = () => {
		const model = { ...this.state.model };

		const request = {
			ProfileName: model.SelectedFeeProfile.ProfileName
		};
		Proxy.ExecuteGeneral(
			this, "POST",
			"/bankapi/v1.0/FxFeeProfile/SearchProfile",
			request,
			responseData => {
				// model.SelectedFeeProfile = Object.assign({}, this.emptyProfile);
				model.SelectedTransactionFee = Object.assign({}, this.emptyDetail);
				model.ProfileList = responseData.Item;
				model.DetailList = [];
				this.setState({ model });
			}
		);
	};

	executeSubmit = () => {
		if (!this.validateSubmit()) {
			return;
		}

		const model = { ...this.state.model };
		var callUrl = model.SelectedFeeProfile.Id != 0 ?
			"/bankapi/v1.0/FxFeeProfile/Update" :
			"/bankapi/v1.0/FxFeeProfile/Insert";

		var requestList = [];
		if (model.SelectedFeeProfile.Id != 0) {
			var originalDetail = model.ProfileList.find(x => x.Id == model.SelectedFeeProfile.Id).DetailList;
			var originalDetailList = this.toArray(originalDetail);

			model.DetailList.forEach(element => {
				// added list
				if (element.Id == 0) { requestList.push(element); }
				// modified list
				if (element.Id != 0) { requestList.push(element); }
			});

			// deleted list
			originalDetailList.forEach(element => {
				var relatedItem = model.DetailList.find(x => x.Id == element.Id);
				if (relatedItem == null) {
					var deletedItem = Object.assign({}, element);
					deletedItem.IsRecordValid = false;
					requestList.push(deletedItem);
				}
			});
		} else {
			model.DetailList.forEach(element => {
				// added list
				requestList.push(element);
			});
		}

		const UniqueClientId = ClientHelper.IsClient() ? null : model.SelectedFeeProfile.UniqueClientId;
		const request = {
			ProfileName: model.SelectedFeeProfile.ProfileName,
			UniqueClientId: UniqueClientId,
			Id: model.SelectedFeeProfile != null ? model.SelectedFeeProfile.Id : null,
			IsRecordValid: true,
			DetailList: requestList
		};
		Proxy.ExecuteGeneral(
			this, "POST",
			callUrl,
			request,
			responseData => {
				model.SelectedFeeProfile = responseData.Item;
				model.SelectedTransactionFee = Object.assign({}, this.emptyDetail);
				model.ProfileList = [];
				model.ProfileList.push(responseData.Item);
				model.DetailList = responseData.Item.DetailList;
				this.setState({ model });
			}
		);
	};

	executeDelete = () => {
		const model = { ...this.state.model };

		const UniqueClientId = ClientHelper.IsClient() ? null : model.SelectedFeeProfile.UniqueClientId;
		const request = {
			ProfileName: model.SelectedFeeProfile.ProfileName,
			UniqueClientId: UniqueClientId,
			Id: model.SelectedFeeProfile != null ? model.SelectedFeeProfile.Id : null,
			IsRecordValid: false
		};
		Proxy.ExecuteGeneral(
			this, "POST",
			"/prepaidapi/v1.0/CardTransactionFeeProfile/Delete",
			request,
			responseData => {
				this.setState({
					model: {
						SelectedFeeProfile: Object.assign({}, this.emptyProfile),
						SelectedTransactionFee: Object.assign({}, this.emptyDetail),
						ProfileList: [],
						DetailList: [],
					}
				});
			}
		);
	};

	validateSubmit = () => {
		var errorList = [];
		const { model } = this.state;

		// Client is optional
		// if (model.UniqueClientId == null || model.UniqueClientId == 0) {
		// 	errorList.push("Client must be selected!");
		// }

		if (model.SelectedFeeProfile == null
			|| model.SelectedFeeProfile.ProfileName == null
			|| model.SelectedFeeProfile.ProfileName === "") {
			errorList.push("Please fill Profile Name field.");
		}

		if (errorList.length > 0) {
			this.ShowMessageNode("warning", "Required fields!", errorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		return true;
	}

	validateNewDetail = (newItem, allDetail) => {
		var errorList = [];
		const { model } = this.state;

		//model.SelectedTransactionFee.TransactionCodeId
		//model.SelectedTransactionFee.FeeRate
		//model.SelectedTransactionFee.FlatAmount

		if (newItem.SupplierId == null) {
			errorList.push("Please fill in the Supplier field.");
		}
		if (newItem.CountryId == null) {
			errorList.push("Please fill in the Country field.");
		}
		if (newItem.TransferMethodId == null) {
			errorList.push("Please fill in the Transfer Source field.");
		}
		if (newItem.TransferSourceTypeId == null) {
			errorList.push("Please fill in the Transfer Method field.");
		}

		if (newItem.Id == 0) {
			if (newItem.Index != null) {
				for (let index = 0; index < allDetail.length; index++) {
					const element = allDetail[index];
					if (index != newItem.Index &&
						element.SupplierId == newItem.SupplierId &&
						element.CountryId == newItem.CountryId &&
						element.TransferMethodId == newItem.TransferMethodId &&
						element.TransferSourceTypeId == newItem.TransferSourceTypeId) {
						errorList.push("Please fill in fields with different value.");
					}
				}
			} else {
				allDetail.forEach((value, index, array) => {
					if (value.SupplierId == newItem.SupplierId &&
						value.CountryId == newItem.CountryId &&
						value.TransferMethodId == newItem.TransferMethodId &&
						value.TransferSourceTypeId == newItem.TransferSourceTypeId) {
						errorList.push("Please fill in fields with different value.");
					}
				});
			}
		} else {
			allDetail.forEach(element => {
				if (element.Id != newItem.Id &&
					element.SupplierId == newItem.SupplierId &&
					element.CountryId == newItem.CountryId &&
					element.TransferMethodId == newItem.TransferMethodId &&
					element.TransferSourceTypeId == newItem.TransferSourceTypeId) {
					errorList.push("Please fill in fields with different value.");
				}
			});
		}

		if (errorList.length > 0) {
			this.ShowMessageNode("warning", "Required fields!", errorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		return true;
	}

	render() {
		const { Disabled } = this.props;
		const { model, isLoading, alert } = this.state;
		var emptyAccountModel = { FinancialInstitutionId: 0, AccountNumber: "" };
		const dialogBorderStyle = {
			borderColor: primaryColor,
			borderWidth: "7px",
			borderStyle: "Solid"
		};
		return (
			<div>
				
				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Submit",
							OnClick: this.executeSubmit,
							Disabled: Disabled || model.Id > 0,
							ModelFunction: () => model,
							FillDataFromModel: model => this.setState({ model }),
							ValidationFunction: this.Validate
						},
						{
							Code: "Delete",
							OnClick: this.executeDelete,
							Disabled: Disabled || model.Id > 0,
							ModelFunction: () => model,
							FillDataFromModel: model => this.setState({ model }),
							ValidationFunction: this.Validate
						},
						{
							Code: "Search",
							OnClick: this.handleSearch,
							Disabled: Disabled || model.Id > 0,
							ModelFunction: () => model,
							FillDataFromModel: model => this.setState({ model }),
							ValidationFunction: this.Validate
						},
						{ Code: "Clear", OnClick: this.handleClear, Disabled: Disabled }
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card>
							<CardBody>
								<GridContainer>
									<GridItem xs={4}>
										<GridItem>
											<GenericTextInput
												Name="ProfileName"
												LabelText="Profile Name"
												Value={model.SelectedFeeProfile.ProfileName}
												ValueChanged={this.handleChange} />
										</GridItem>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
						<Card>
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid Data={model.ProfileList} Columns={this.feeProfileColumns} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
						<Card>
							<CardHeader>
								<GridContainer spacing={16} alignItems="center">
									<GridItem>
										<GenericTitle text={"Fee Definition"} />
									</GridItem>
								</GridContainer>
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={4}>
										<GenericSelectInput
											Name="SupplierId"
											LabelText="Supplier"
											Method="POST"
											Url="/bankapi/v1.0/Supplier/Search"
											DataRoot="Item"
											KeyValueMember="Id"
											RenderItem={d => `${d.Title}`}
											Value={model.SelectedTransactionFee.SupplierId || ""}
											ValueChanged={this.handleChange}
											CanClear
											All />
									</GridItem>
									<GridItem xs={4}>
										<GenericNumberInput
											Name="SupplierFee"
											LabelText="Supplier Fee"
											LabelMd={6}
											Value={model.SelectedTransactionFee.SupplierFee}
											ValueChanged={this.handleChange}  />
									</GridItem>
									<GridItem xs={4}>
										{/* empty */}
									</GridItem>
									<GridItem xs={4}>
										<GenericSelectInput
											Name="CountryId"
											LabelText="Country"
											Method="POST"
											Url="/coreapi/v1.0/Country/GetAll"
											DataRoot="Item"
											KeyValueMember="Id"
											RenderItem={d => `${d.Code} - ${d.Name}`}
											Value={model.SelectedTransactionFee.CountryId}
											ValueChanged={this.handleChange}
											CanClear
											All/>
									</GridItem>
									<GridItem xs={4}>
										<GenericNumberInput
											Name="FeeMarkup"
											LabelText="Add DCBank Fee Mark-up"
											LabelMd={6}
											Value={model.SelectedTransactionFee.FeeMarkup}
											ValueChanged={this.handleChange}  />
									</GridItem>
									<GridItem xs={4}>
										{/* empty */}
									</GridItem>
									<GridItem xs={4}>
										<ParameterComponent
											Name="TransferSourceTypeId"
											LabelText="Transfer Source"
											ParameterCode="FxTransferSourceType"
											Value={model.SelectedTransactionFee.TransferSourceTypeId}
											ValueChanged={this.handleChange} />
									</GridItem>
									<GridItem xs={4}>
										<GenericNumberInput
											Name="FxMarkup"
											LabelText="Add DCBank Fx Mark-up"
											LabelMd={6}
											Value={model.SelectedTransactionFee.FxMarkup}
											ValueChanged={this.handleChange}  />
									</GridItem>
									<GridItem xs={4}>
										{/* empty */}
									</GridItem>
									<GridItem xs={4}>
										<ParameterComponent
											Name="TransferMethodId"
											LabelText="Transfer Method"
											ParameterCode="FxTransferMethod"
											Value={model.SelectedTransactionFee.TransferMethodId}
											ValueChanged={this.handleChange} />
									</GridItem>
									<GridItem xs={8}>
										{/* empty */}
									</GridItem>
									<GridItem xs={8} />
									<GridItem xs={4}>
										<GridContainer justify="flex-end">
											<GridItem>
												<Button
													size="sm"
													onClick={this.handleAddDetail}>ADD</Button>
												<Button size="sm"
													disabled={model.SelectedTransactionFee.Index == null}
													onClick={this.handleDeleteDetail}>DELETE</Button>
												<Button size="sm"
													onClick={this.handleClearDetail}>CLEAR</Button>
											</GridItem>
										</GridContainer>
									</GridItem>
									<GridItem xs={12}>
										<GenericGrid Data={model.DetailList} Columns={this.feeItemColumns} HideButton />
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		);
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })} />
			)
		});
	};

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					MessageNode={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })} />
			)
		});
	};

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					ShowCancel={true}
					OnCancel={() => this.setState({ alert: "" })}
					OnConfirm={onConfirm} />
			)
		});
	};


}

FeeProfiles.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool,
	handleChange: PropTypes.func,
	menuId: PropTypes.number,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};

export default withArtifex(FeeProfiles, {});
