import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import PropTypes from "prop-types";
import React from "react";
import { GenericTextInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";

class MastercardFileRejectDetail extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			dialog: {
				isOpen: false,
				dialogData: []
			},
			selected: -1,
			isLoading: false,
			PdsData: [],
			ChipData: [],
			isPdsDialogOpen: false,
			isChipDialogOpen: false
		};

		this.maxLength1 = { maxLength: 1 };
		this.maxLength19 = { maxLength: 19 };
		this.maxLength6 = { maxLength: 6 };
		this.maxLength17 = { maxLength: 17 };
		this.maxLength4 = { maxLength: 3 };
		this.maxLength3 = { maxLength: 3 };
		this.maxLength22 = { maxLength: 22 };
		this.maxLength16 = { maxLength: 16 };
		this.maxLength45 = { maxLength: 45 };
		this.maxLength13 = { maxLength: 13 };
		this.maxLength10 = { maxLength: 10 };
		this.maxLength256 = { maxLength: 256 };
		this.maxLength11 = { maxLength: 11 };
		this.maxLength999 = { maxLength: 999 };
		this.maxLength25 = { maxLength: 25 };
		this.maxLength8 = { maxLength: 8 };
		this.maxLength140 = { maxLength: 140 };

	}

	componentDidMount() 
	{
	}

	HandleChange = (name, newValue, data) => {
		this.props.onModelChange(model => {
			model[name] = newValue;
			return model;
		});
	}

	render() {
		const { model } = this.props;
		const { isLoading } = this.state;

		return (
			<>
				
			
				<LoadingComponent Show={isLoading}/>
				
				<GridContainer>
					<GridItem md={6} xs={12}>
						<GenericTextInput
							Name="Mti"
							LabelText="Mti"
							Value={model.Mti}
							inputProps={this.maxLength4}
							ValueChanged={this.HandleChange} />

						<GenericTextInput
							Name="De024"
							LabelText="De024"
							Value={model.De024}
							inputProps={this.maxLength3}
							ValueChanged={this.HandleChange} />

						<GenericTextInput
							Name="De072"
							LabelText="De072"
							Value={model.De072}
							inputProps={this.maxLength999}
							ValueChanged={this.HandleChange} />

						<GenericTextInput
							Name="Pds0005"
							LabelText="Pds0005"
							Value={model.Pds0005}
							inputProps={this.maxLength140}
							ValueChanged={this.HandleChange} />

					</GridItem>
					<GridItem md={6} xs={12}>
						<GenericTextInput
							Name="Pds0105"
							LabelText="Pds0105"
							Value={model.Pds0105}
							inputProps={this.maxLength25}
							ValueChanged={this.HandleChange} />
						<GenericTextInput
							Name="Pds0138"
							LabelText="Pds0138"
							Value={model.Pds0138}
							inputProps={this.maxLength8}
							ValueChanged={this.HandleChange} />
						<GenericTextInput
							Name="Pds0280"
							LabelText="Pds0280"
							Value={model.Pds0280}
							inputProps={this.maxLength25}
							ValueChanged={this.HandleChange} />

						{/* <GenericDateInput
							Name="ClearingDate"
							LabelText="Clearing Date"
							Value={model.ClearingDate}
							ValueChanged={this.HandleChange}
							Disabled={false} /> */}
					</GridItem>

				</GridContainer>
			</>
		);
	}
}

MastercardFileRejectDetail.propTypes = {
	initialModel: PropTypes.any,
	HandleChange: PropTypes.func,
	FillCallback: PropTypes.func,
	LabelMd: PropTypes.number,
	Disabled: PropTypes.bool,
	IsDialogOpen: PropTypes.any
};

export default withArtifex(MastercardFileRejectDetail, {});