import { Checkbox, FormControlLabel } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { Check } from "@material-ui/icons";
import genericCheckInputStyle from "assets/jss/generic/genericCheckInputStyle.jsx";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

const checkboxStyle = {
	width: 24,
	height: 24
};

class GenericCheckInput extends PureComponent {

	render() {
		const { classes, LabelText, Name, Value, ValueChanged, Disabled, Grid, IsTextBold, IsTextLeft, IsWithButton } = this.props;

		return (
			<FormControlLabel
				style={{ marginTop: IsWithButton != null ? "-18px" : Grid == true ? - 3 : 3, marginLeft: 0, marginRight: 10, textAlign: IsTextLeft != null ? "left" : "center" }}
				control={
					<Checkbox
						style={checkboxStyle}
						checked={Value ? Value : false}
						color="primary"
						checkedIcon={<Check className={classes.checkedIcon} />}
						icon={<Check className={classes.uncheckedIcon} />}
						classes={{ checked: Disabled ? classes.disabled : classes.checked }}
						inputProps={{
							name: Name,
							disabled: Disabled
						}}
						onChange={e => {
							if (ValueChanged)
								ValueChanged(Name, e.target.checked);
						}}
					/>
				}
				classes={{ label: IsTextBold != null ? classes.labelBold : classes.label }}
				label={LabelText}
			/>
		);
	}
}

GenericCheckInput.propTypes = {
	classes: PropTypes.object.isRequired,
	Name: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	LabelText: PropTypes.string,
	ValueChanged: PropTypes.func,
	Value: PropTypes.bool,
	Disabled: PropTypes.bool,
	Grid: PropTypes.bool,
	IsTextBold: PropTypes.bool,
	IsTextLeft: PropTypes.bool,
	IsWithButton: PropTypes.bool
};

export default withStyles(genericCheckInputStyle)(GenericCheckInput);