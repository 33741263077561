import PropTypes from "prop-types"; 
import { Dialog, withStyles } from "@material-ui/core";
import genericDialogStyle from "assets/jss/generic/genericDialogStyle.jsx";
import React from "react";

const dialogStyle = {};
function GenericDialog(props) {

	const { classes, children, Overflow, ...rest } = props;



	dialogStyle.paper = Overflow ? classes.overflowVisible : null;
	return (

		<Dialog classes={dialogStyle} {...rest} >
			<div className={classes.dialog}>
				{children}
			</div>
		</Dialog >
	);
}

GenericDialog.propTypes = {
	...Dialog.propTypes,
	Overflow: PropTypes.bool
};

export default withStyles(genericDialogStyle)(GenericDialog);