import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericDateInput, GenericDialog, GenericLabel, GenericDialogActions, GenericRadioInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import DateHelper from "core/DateHelper";
import ClientHelper from "core/ClientHelper";
import Select from "react-select";
import { Proxy } from "core";
import Button from "components/CustomButtons/Button";
import { DialogContent, DialogTitle } from "@material-ui/core";

class CardEmbossBillingList extends React.Component {
	constructor(props) {
		super(props);

		this.sortedName = { Member: "Name" };
		this.emptyObject = {};

		this.columns = [
			{
				Header: "File Date",
				accessor: "FileDate",
			},
			{
				Header: "File Name",
				accessor: "FileName",
			},
			{
				Header: "Client Name",
				accessor: "ClientName",
				width: 200,
			},
			{
				Header: "Card Brand",
				accessor: "CardBrand"
			},
			{
				Header: "Product Name",
				accessor: "ProductName"
			},
			{
				Header: "Number of Total",
				accessor: "NumberofTotal"
			},
			{
				Header: "Number of Success",
				accessor: "NumberofSuccess"
			},
			{
				Header: "Number of Rejected",
				accessor: "NumberofRejected"
			},
			{
				Header: "FileStatus",
				accessor: "FileStatus"
			},
			{
				Header: "Number Of Instant Cards",
				accessor: "NumberOfInstantCards"
			},
			{
				Header: "Number Of Perso Office Cards",
				accessor: "NumberOfPersoOfficeCards"
			},
		];

		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.IsClient() ? ClientHelper.GetClientId() : undefined
		};

		this.cardEmbossTable = React.createRef();

		this.state = {
			model: {
				FromDate: DateHelper.GetDateLocal(),
				ToDate: DateHelper.GetDateLocal(),
			}, isLoading: false, checkedAccounts: [], ClientTypeParameter: {}
		};
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Card Emboss Billing List");
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
	}

	handleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}

	Search = async () => {

		if (!this.Validate())
			return;
		const { ExecuteApiPost } = this.props;
		const model = { ...this.state.model };

		var result = null;
		result = await ExecuteApiPost("/prepaidapi/v1.0/CardEmbossFile/GetCardEmbossBillingList", model, null, null, null);
		if (result != null) {
			this.setState({ list: result || [] });
		}
	}

	Clear = () => {
		this.setState({
			model: {
				FromDate: DateHelper.GetDateLocal(),
				ToDate: DateHelper.GetDateLocal(),
			}
		});
	}

	Validate = () => {
		var { model } = this.state;
		const { showValidationErrors } = this.props;
		var errors = [];

		if (!model.FromDate) {
			errors.push("From Date must be select");
		}

		if (!model.ToDate) {
			errors.push("To Date must be select");
		}

		if (model.ToDate < model.FromDate) {
			errors.push("To Date must be bigger than From Date");
		}

		if (errors.length > 0) {
			showValidationErrors(errors);
			return false;
		}

		return true;
	}

	GetRenderItemUniqueClient = (x) => {
		this.renderItemUniqueClient = `${x.Name} - ${x.UniqueClientId}`;
		return this.renderItemUniqueClient;
	}

	ValueChangedForReportType = (name, value) => {
		this.setState({ [name]: value });
	}

	ExportClick = () => {
		var cardEmbossColumns = [];
		this.cardEmbossTable.current.props.Columns.forEach(c => {
			if (c.show == undefined || c.show) {
				cardEmbossColumns.push({ value: c.accessor, title: c.Header, columntype: c.ColumnType, type: c.type });
			}
		}, this);

		this.setState({ isDownloadDialogOpen: true, GridKeyValueList: cardEmbossColumns, ExportSelectedColumns: cardEmbossColumns });
	}

	ExcelDownload = () => {
		if (!this.state.ExportReportType) {
			this.props.showMessage("warning", "Export Report Type not selected", "Select Export Report Type to continue");
			return;
		}

		if (this.state.ExportReportType == "PDF" && this.state.ExportSelectedColumns.length > 15) {
			this.props.showMessage("warning", "PDF document cannot exceed 15 columns", "PDF document cannot exceed 15 columns");
			return;
		}

		this.setState({ isDownloadDialogOpen: false });
		const temp = { ...this.state.model };
		var jsonColList = [];
		var cardEmbossColumns = {};
		this.state.ExportSelectedColumns.map(c => {
			cardEmbossColumns[c.value] = { value: c.value, title: c.title, columntype: c.columntype, type: c.type };
		});
		jsonColList.push(cardEmbossColumns);

		var mytitle = "Card Emboss Billing List";

		temp.jsonFile = {
			colmns: jsonColList,
			format: this.state.ExportReportType,
			title: mytitle
		};

		this.setState({ isLoading: true });
		Proxy.DownloadGeneratedFile(
			"/prepaidapi/v1.0/CardEmbossFile/CardEmbossBillingListExportDownload",
			temp,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", errorMessage);
			},
			this.props.showMessage
		);
	}

	render() {
		const { model, list } = this.state;
		const { Disabled, } = this.props;

		return (
			<div>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Search",
							Disabled: Disabled,
							OnClick: this.Search
						},
						{
							Code: "Clear",
							Disabled: Disabled,
							OnClick: this.Clear
						},
						{
							Code: "Export",
							OnClick: this.ExportClick,
							Disabled: Disabled
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After} />
				<GenericDialog open={this.state.isDownloadDialogOpen} fullWidth={true}>
					<DialogTitle>
						<GenericLabel Text="Select Report Type" FontSize="20px" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ height: 300 }}>
						<GenericRadioInput
							Name="ExportReportType"
							LabelText="Report Type"
							IsStatic={true}
							StaticData={[{ Value: "PDF", Text: "PDF" }, { Value: "CSV", Text: "CSV" }, { Value: "EXCEL", Text: "EXCEL" }]}
							KeyValueMember="Value"
							TextValueMember="Text"
							Value={this.state.ExportReportType}
							ValueChanged={this.ValueChangedForReportType}
						/>
						<GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
						<GridItem style={{ marginTop: 20 }}>
							<Select
								value={this.state.ExportSelectedColumns}
								onChange={value => this.setState({ ExportSelectedColumns: value })}
								isMulti
								name="columns"
								getOptionLabel={d => d.title}
								options={this.state.GridKeyValueList}
								className="basic-multi-select"
								classNamePrefix="select"
							/>
						</GridItem>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.ExcelDownload}>Download</Button>
						<Button size="sm" onClick={() => this.setState({ isDownloadDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>
				<LoadingComponent Show={this.state.isLoading} />

				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title={"Card Emboss Billing List"}>
									<GridContainer>
										<GridItem xs={3}>
											<GenericSelectInput
												Name="UniqueClientId"
												LabelText="Client"
												Method="POST"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Parameter={this.emptyObject}
												DataRoot="Item"
												KeyValueMember="Id"
												TextValueMember="Name"
												RenderItem={this.GetRenderItemUniqueClient}
												Sorted={this.sortedName}
												All={true}
												Value={model.UniqueClientId}
												ValueChanged={this.handleChange}
											/>
										</GridItem>
										<GridItem xs={3}>
											<GenericDateInput
												Utc
												Name="FromDate"
												LabelText="From Date"
												Value={model.FromDate == null || undefined ? "" : model.FromDate}
												ValueChanged={this.handleChange}
												MaxDate={model.ToDate || DateHelper.GetDateLocal()}
												IncludeTime={false} />
										</GridItem>
										<GridItem xs={3}>
											<GenericDateInput
												Utc
												Name="ToDate"
												LabelText="To Date"
												Value={model.ToDate == null || undefined ? "" : model.ToDate}
												ValueChanged={this.handleChange}
												MinDate={model.FromDate == null ? null : model.FromDate}
												MaxDate={DateHelper.GetDateLocal()}
												IncludeTime={false} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>

						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													ref={this.cardEmbossTable}
													Columns={this.columns}
													ShowPagination={true}
													PageSize={20}
													IsSorted={false}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

CardEmbossBillingList.propTypes = {
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ExecuteApiPost: PropTypes.func,
	Disabled: PropTypes.bool,
	menuId: PropTypes.any,
	ApprovalData: PropTypes.any,
	After: PropTypes.any
};
export default CardEmbossBillingList;