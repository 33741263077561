import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import {
	GenericSelectInput,
	GenericDateInput,
	GenericExpansionPanel,
	GenericGrid,
	GenericAlert,
} from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType, ProgramCodes } from "views/Constants/Constant";

class CorporateSummaryReportbyProduct extends Component {
	constructor(props) {
		super(props);

		this.initialModel = {
			Id: 0,
			UniqueClientId: ClientHelper.IsClient()
				? ClientHelper.GetClientRowId()
				: undefined,
			IsMainCard: true,
			ProgramCode: "",
			FromDate: DateHelper.GetLocalDateAsUtc(),
			ToDate: DateHelper.GetLocalDateAsUtc(),
		};

		this.state = {
			model: this.initialModel,
			list: [],
			isLoading: false,
			vModel: {},
		};

		this.emptyObject = {};

		this.maxLength6 = { maxLength: 6 };

		this.parameterUniqueClientId = {
			UniqueClientId: ClientHelper.IsClient()
				? ClientHelper.GetClientId()
				: undefined,
		};

		this.parameterProduct = {};

		this.Columns = [
			{
				Header: "Client Name",
				accessor: "UniqueClientName",
			},
			{
				Header: "Product Profile",
				accessor: "ProductProfile",
			},
			{
				Header: "Start Date",
				accessor: "FromDate",
				type: GridColumnType.Date
			},
			{
				Header: "End Date",
				accessor: "ToDate",
				type: GridColumnType.Date
			},
			{
				Header: "Approved IDP Count",
				accessor: "ApprovedIDPTrxCount",
			},
			{
				Header: "Approved IDP Amount",
				accessor: "ApprovedIDPTrxAmount",
				type: GridColumnType.Money,
			},
			{
				Header: "Approved SCD Count",
				accessor: "ApprovedSCDTrxCount",
			},
			{
				Header: "Approved SCD Amount",
				accessor: "ApprovedSCDTrxAmount",
				type: GridColumnType.Money,
			},
			{
				Header: "Total Approved IDP and SCD Count",
				accessor: "TotalApprovedIDPandSCDTransactionCount",
			},
			{
				Header: "Declined IDP Count",
				accessor: "DeclinedIDPTrxCount",
			},
			{
				Header: "Declined SCD Count",
				accessor: "DeclinedSCDTrxCount",
			},
			{
				Header: "Total Declined IDP and SCD Count",
				accessor: "TotalDeclinedIDPandSCDTrxCount",
			},
			{
				Header: "Total Approved and Total Declined IDP and SCD Count",
				accessor: "TotalApprovedAndDeclinedIDPandSCDTrxCount",
			},
			{
				Header: "New Cards Count",
				accessor: "TotalNewCardsCount",
			},
			{
				Header: "Reload Count",
				accessor: "TotalReloadCardsCount",
			},
			{
				Header: "Total New And Reload Card Count",
				accessor: "TotalNewAndReloadLoadsCount",
			},
			{
				Header: "Total New And Reload Amount",
				accessor: "TotalNewAndReloadLoadsAmount",
				type: GridColumnType.Money,
			},
			{
				Header: "Directcash Loads",
				accessor: "DirectcashLoads",
			},
			{
				Header: "Balance CSR",
				accessor: "BalanceCSR",
			},
			{
				Header: "Balance IVR",
				accessor: "BalanceIVR",
			},
			{
				Header: "Balance Inquiry",
				accessor: "BalanceInquiry",
			},
			{
				Header: "Monthly Fee",
				accessor: "MonthlyFee",
			},
			{
				Header: "Bill Payment",
				accessor: "BillPayment",
			},

		];

		this.trxTable = React.createRef();
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Corporate Summary Report by Product");
		this.props.setAppCenterTitle("PREPAID CARD MANAGEMENT");
	}

	showMessage = (type, title, message) => {
		this.setState({
			alert: (
				<GenericAlert
					Title={title}
					Message={message}
					Type={type}
					OnConfirm={() => this.setState({ alert: "" })}
				/>
			),
		});
	};

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	};

	HandleClear = () => {
		this.setState({
			model: { ...this.initialModel },
			isEdit: false,
			index: -1,
		});
	};

	HandleSearch = () => {
		if (!this.Validate()) {
			return;
		}

		const model = { ...this.state.model };
		model.ProgramCode = this.ProgramCode;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/prepaidapi/v1.0/Product/GetCorporateSummaryReportbyProduct",
			model,
			(responseData) => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.props.showMessage(
						"error",
						"Error",
						responseData.ErrorDescription
					);
					return;
				}
				if (responseData.Item !== null) {
					this.setState({ list: responseData.Item });
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	};

	Validate = () => {
		const { model, vModel } = this.state;
		const { showValidationErrors } = this.props;

		var errorList = [];

		vModel.FromDate = !model.FromDate;
		if (vModel.FromDate) errorList.push("From Date cannot be empty!");

		vModel.ToDate = !model.ToDate;
		if (vModel.ToDate) errorList.push("To Date cannot be empty!");

		vModel.UniqueClientId = !model.UniqueClientId;
		if (vModel.UniqueClientId) errorList.push("Select the Client to continue.");

		vModel.ProductId = !model.ProductId;
		if (vModel.ProductId) errorList.push("Select the Product to continue.");

		if (errorList.length > 0) {
			showValidationErrors(errorList);
			return false;
		}

		return true;
	};

	GetProductParameter = () => {
		this.parameterProduct.UniqueClientId = this.state.model.UniqueClientId;
		return this.parameterProduct;
	}

	RenderItemProduct = (d) => {
		this.renderItemProduct = d.Name;
		if (d.UniqueClient)
			this.renderItemProduct += ` - (${d.UniqueClient.UniqueClientId} - ${d.UniqueClient.ClientName})`;
		return this.renderItemProduct;
	}

	render() {
		const { Disabled } = this.props;
		const { alert, model, list, isLoading } = this.state;
		var IsClient = ClientHelper.IsClient();
		return (
			<div>
				<LoadingComponent Show={isLoading} />
				{alert}
				<ButtonToolbar
					ButtonList={[
						{ Code: "Search", OnClick: this.HandleSearch, Disabled: Disabled },
						{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After}
				/>
				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Filter Panel">
									<GridContainer>
										<GridItem xs={4}>
											<GenericSelectInput
												Disabled={IsClient}
												Name="UniqueClientId"
												LabelText="Client"
												Url="/bankapi/v1.0/BankCustomer/Search"
												Method="POST"
												Parameter={{}}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={(d) => `${d.UniqueClientId} - ${d.Name}`}
												TextValueMember="Name"
												Value={model.UniqueClientId}
												ValueChanged={this.HandleChange}
												CanClear
												All
												Required
												DefaultIndex={IsClient ? 0 : -1}
											/>
											<GenericDateInput
												Utc
												Name="FromDate"
												LabelText="Start Date"
												Value={
													model.FromDate
														? DateHelper.ToDateInputValue(model.FromDate)
														: ""
												}
												ValueChanged={this.HandleChange}
												Required
											/>

										</GridItem>
										<GridItem xs={4}>
											<GenericSelectInput
												key={model.UniqueClientId}
												Name="ProductId"
												LabelText="Product"
												Method="POST"
												Url="/prepaidapi/v1.0/Product/SearchNonCredit"
												Parameter={this.GetProductParameter()}
												DataRoot="Item"
												KeyValueMember="Id"
												RenderItem={this.RenderItemProduct}
												Value={model.ProductId}
												ValueChanged={this.HandleChange}
												Required
											/>

											<GenericDateInput
												Utc
												Name="ToDate"
												LabelText="End Date"
												Value={
													model.ToDate
														? DateHelper.ToDateInputValue(model.ToDate)
														: ""
												}
												ValueChanged={this.HandleChange}
												MinDate={model.FromDate}
												Required
											/>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={4}></GridItem>
									<GridItem xs={4} />
								</GridContainer>
								<GridContainer spacing={16}>
									<GridItem xs={12}>
										<GenericGrid
											Data={list}
											Columns={this.Columns}
											ProgramCode={ProgramCodes.Prepaid}
											ShowPagination={false}
											ref={this.trxTable}
										/>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

CorporateSummaryReportbyProduct.propTypes = {
	classes: PropTypes.object.isRequired,
	Disabled: PropTypes.bool,
};

export default withArtifex(CorporateSummaryReportbyProduct, {});
