import withStyles from "@material-ui/core/styles/withStyles";
import { chartGradientColors } from "assets/jss/material-dashboard-pro-react";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React from "react";
import { Chart, Doughnut as DoughnutChart } from "react-chartjs-2";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericDateInput, GenericExpansionPanel, GenericGrid, GenericSelectInput, GenericTitle } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType } from "views/Constants/Constant";

const styles = ({});



class CampaignDashboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			model: {},
			CouponProfileList: [],
			alert: null,
			isLoading: false,
			isClient: ClientHelper.IsClient(),

			emailChartObj: {
				labels: ["Total e-Mail"],
				datasets: [{
					data: [1],
					backgroundColor: "rgb(255, 99, 132)",
					hoverBackgroundColor: "rgb(255, 99, 132)"
				}],
				text: "e-Mail"
			},
			smsChartObj: {
				labels: ["Total Sms"],
				datasets: [{
					data: [1],
					backgroundColor: "rgb(255, 159, 64)",
					hoverBackgroundColor: "rgb(255, 159, 64)"
				}],
				text: "SMS"
			},
			couponChartObj: {
				labels: ["Total i-Coupon"],
				datasets: [{
					data: [1],
					backgroundColor: "rgb(54, 162, 235)",
					hoverBackgroundColor: "rgb(54, 162, 235)"
				}],
				text: "i-Coupon"
			},
			campaignChartObj: {
				labels: ["Total Campaigns"],
				datasets: [{
					data: [1],
					backgroundColor: "rgb(54, 162, 235)",
					hoverBackgroundColor: "rgb(54, 162, 235)"
				}],
				text: "Campaigns"
			}
		};

		this.ChartOptions = {
			maintainAspectRatio: false,
			responsive: false,
			legend: { display: false },
			cutoutPercentage: 90,
			animation: { duration: 2000 }
		};

		this.ChartColors = {
			red: "rgb(255, 99, 132)",
			orange: "rgb(255, 159, 64)",
			yellow: "rgb(255, 205, 86)",
			green: "rgb(75, 192, 192)",
			blue: "rgb(54, 162, 235)",
			purple: "rgb(153, 102, 255)",
			grey: "rgb(201, 203, 207)"
		};

		this.ColumnsData = [
			{
				Header: "Program",
				accessor: "Program.Description"
			},
			{
				Header: "Profile Name",
				accessor: "ProfileName"
			},
			{
				Header: "Profile Status",
				accessor: "ProfileStatus.ParameterDesc"
			},
			{
				Header: "Campaign Partner",
				accessor: "CampaignPartner.PartnerName"
			},
			{
				Header: "Coupon Definition",
				accessor: "CouponDefinition.ParameterDesc"
			},
			{
				Header: "Coupon Type",
				accessor: "CouponType",
				Cell: row => (
					<span>
						{row.original.IsFreeGift ? "Free Gift" : "" + row.original.IsDiscount ? "Discount" : ""}
					</span>
				)
			},
			{
				Header: "Coupon Start Date",
				accessor: "StartDateTime",
				type: GridColumnType.DateTime,
				filterMethod: (filter, row, column) => {
					return Formatter.FormatDateTime(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase())
				}
			},
			{
				Header: "Coupon End Date",
				accessor: "ExpireDateTime",
				type: GridColumnType.DateTime,
				filterMethod: (filter, row, column) => {
					return Formatter.FormatDateTime(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase())
				}
			}
		];


	}

	componentDidMount() {
		if (this.props.setAppCenterTitle) {
			this.props.setAppCenterTitle("Campaign Manager");
		}
		if (this.props.setAppLeftTitle)
			this.props.setAppLeftTitle("Campaign Dasboard");

		this.RenderChartData();
	}
	RenderChartData = () => {
		var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
		Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
			draw: function () {
				originalDoughnutDraw.apply(this, arguments);

				var chart = this.chart;
				var width = chart.chart.width,
					height = chart.chart.height,
					ctx = chart.chart.ctx;

				var fontSize = 1.5;
				ctx.font = fontSize + "em sans-serif";
				ctx.textBaseline = "middle";
				ctx.fillStyle = "#000";

				var text = chart.config.data.text,
					textX = Math.round((width - ctx.measureText(text).width) / 2),
					textY = height / 2;

				ctx.fillText(text, textX, textY);
			}
		});
	}
	LoadChartData = () => {
		this.setState({ isLoading: true });
		var temp = this.state.model;
		if (temp.UniqueClientId == null) {
			temp.UniqueClientId = 0;
		}
		Proxy.POST(
			"/campaignapi/v1.0/CampaignDashboard/Filter",
			this.state.model,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ isLoading: false });
				this.ParseChartData(responseData.Item);
			},
			error => {
				this.setState({ isLoading: false });
				this.props.showMessage("error", "Error", error);
			}
		);
	}
	ParseChartData = (data) => {
		if (data == null) {
			return;
		}
		var colorNames = Object.keys(this.ChartColors);
		var i = 0;

		//#region Email
		var arrayData = [];
		var arrayLabel = [];
		var arrayColor = [];
		for (var item of data.EmailList) {
			var colorName = colorNames[i % colorNames.length];
			var newColor = this.ChartColors[colorName];
			arrayColor.push(newColor);
			arrayData.push(item["Count"]);
			arrayLabel.push(item["Name"]);
			i++;
		}
		var emailChartObj = {
			labels: arrayLabel,
			datasets: [{
				data: arrayData,
				hoverBackgroundColor: arrayColor,
				backgroundColor: arrayColor
			}],
			text: "e-Mail"
		};
		//#endregion

		//#region Sms
		arrayColor = [];
		arrayData = [];
		arrayLabel = [];
		var j = 0;
		for (var item of data.SmsList) {
			var colorName = colorNames[j % colorNames.length];
			var newColor = this.ChartColors[colorName];
			arrayColor.push(newColor);
			arrayData.push(item["Count"]);
			arrayLabel.push(item["Name"]);
			j++;
		}
		var smsChartObj = {
			labels: arrayLabel,
			datasets: [{
				data: arrayData,
				hoverBackgroundColor: arrayColor,
				backgroundColor: arrayColor
			}],
			text: "SMS"
		};
		//#endregion

		//#region Coupon
		arrayColor = [];
		arrayData = [];
		arrayLabel = [];

		var k = 0;
		for (var item of data.CouponList) {
			var colorName = colorNames[k % colorNames.length];
			var newColor = this.ChartColors[colorName];
			arrayColor.push(newColor);
			arrayData.push(item["Count"]);
			arrayLabel.push(item["Name"]);
			k++;
		}
		var couponChartObj = {
			labels: arrayLabel,
			datasets: [{
				data: arrayData,
				hoverBackgroundColor: arrayColor,
				backgroundColor: arrayColor
			}],
			text: "Coupon"
		};
		//#endregion

		//#region Campaign
		arrayColor = [];
		arrayData = [];
		arrayLabel = [];

		var k = 0;
		for (var item of data.CampaignList) {
			var colorName = colorNames[k % colorNames.length];
			var newColor = this.ChartColors[colorName];
			arrayColor.push(newColor);
			arrayData.push(item["Count"]);
			arrayLabel.push(item["Name"]);
			k++;
		}
		var campaignChartObj = {
			labels: arrayLabel,
			datasets: [{
				data: arrayData,
				hoverBackgroundColor: arrayColor,
				backgroundColor: arrayColor
			}],
			text: "Campaign"
		};
		//#endregion	


		this.setState({
			emailChartObj: emailChartObj,
			smsChartObj: smsChartObj,
			couponChartObj: couponChartObj,
			campaignChartObj: campaignChartObj,
			CouponProfileList: data.CampaignCouponProfileList
		});
	}

	GetGradientList = (dataList) => {
		var gradientList = undefined;
		if (document.getElementById('canvas') != null) {
			var ctx = document.getElementById('canvas').getContext("2d");
			gradientList = [];
			if (dataList.datasets != undefined) {
				var i;
				for (i = 0; i < dataList.datasets[0].data.length; i++) {
					var gradient = ctx.createLinearGradient(0, 0, 0, 400)
					if (chartGradientColors[i] == undefined) {
						gradient.addColorStop(0, chartGradientColors[0].begin);
						gradient.addColorStop(1, chartGradientColors[0].end);
					} else {
						gradient.addColorStop(0, chartGradientColors[i].begin);
						gradient.addColorStop(1, chartGradientColors[i].end);
					}
					gradientList.push(gradient);
				}
				return gradientList;
			}
		}
		return;
	}
	SelectedRowChange = (index) => {
		const temp = this.state.CouponProfileList[index];
		// todo
	}
	RenderItemUniqueClient = (d) => {
		return `${d.UniqueClientId} - ${d.Name}`;
	}
	handleChange = (name, newValue, data) => {
		this.setState(function (state, props) {
			var temp = state.model || {};
			temp[name] = newValue;
			return { model: temp };
		});
	}

	render() {
		const { classes, Disabled } = this.props;
		const { model, CouponProfileList, smsChartObj, emailChartObj, couponChartObj, campaignChartObj } = this.state;

		var trxGradientList = this.GetGradientList(smsChartObj);
		var trxBackground = smsChartObj;
		if (trxGradientList != undefined) {
			trxBackground.datasets[0].backgroundColor = trxGradientList;
			trxBackground.datasets[0].hoverBackgroundColor = trxGradientList;
		}

		var feeGradientList = this.GetGradientList(emailChartObj);
		var feeBackground = emailChartObj;
		if (feeGradientList != undefined) {
			feeBackground.datasets[0].backgroundColor = feeGradientList;
			feeBackground.datasets[0].hoverBackgroundColor = feeGradientList;
		}

		var holdGradientList = this.GetGradientList(couponChartObj);
		var holdBackground = couponChartObj;
		if (holdGradientList != undefined) {
			holdBackground.datasets[0].backgroundColor = holdGradientList;
			holdBackground.datasets[0].hoverBackgroundColor = holdGradientList;
		}

		var returnedGradientList = this.GetGradientList(campaignChartObj);
		var returnedBackground = campaignChartObj;
		if (returnedGradientList != undefined) {
			returnedBackground.datasets[0].backgroundColor = returnedGradientList;
			returnedBackground.datasets[0].hoverBackgroundColor = returnedGradientList;
		}


		return (
			<div>
				{this.state.alert}
				<LoadingComponent Show={this.state.isLoading} />

				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.LoadChartData, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<Card className="no-radius">
					<CardBody>
						<GenericExpansionPanel Title="Filter Panel">
							<GridContainer spacing={16}>
								{!this.state.isClient && (
									<GridItem xs={3} >
										<GenericSelectInput
											Name="UniqueClientId"
											LabelText="Client"
											Method="POST"
											Url="/bankapi/v1.0/BankCustomer/Search"
											Parameter={{}}
											DataRoot="Item"
											KeyValueMember="Id"
											RenderItem={this.RenderItemUniqueClient}
											Value={model.UniqueClientId}
											ValueChanged={this.handleChange}
											CanClear
											All
											Disabled={this.state.isClient}
											DefaultIndex={this.state.isClient ? 0 : -1}
										/>
									</GridItem>
								)}
								<GridItem xs={3} >
									<GenericDateInput
										IsPast={true}
										Name="FromDate"
										LabelText="From Date"
										Value={model.FromDate == null ? "" : DateHelper.ToDateInputValue(model.FromDate)}
										ValueChanged={this.handleChange} />
								</GridItem>
								<GridItem xs={3} >
									<GenericDateInput
										IsPast={true}
										Name="ToDate"
										LabelText="To Date"
										Value={model.ToDate == null ? "" : DateHelper.ToDateInputValue(model.ToDate)}
										ValueChanged={this.handleChange} />
								</GridItem>
								<GridItem xs={3} >
									<GenericSelectInput
										Disabled={Disabled || model.Id > 0}
										Name="ProgramId"
										LabelText="Program"
										Value={model.ProgramId}
										ValueChanged={this.handleChange}
										KeyValueMember="Id"
										TextValueMember="Description"
										Method="GET"
										Url="/bankapi/v1.0/Bank/ValidProgramsCampaign"
										DataRoot="Item"
									/>
								</GridItem>
							</GridContainer>
						</GenericExpansionPanel>
					</CardBody>
				</Card>

				<Card>
					<CardHeader>
						<GenericTitle text={"Summary of Campaigns"} />
					</CardHeader>
					<CardBody>
						<GridContainer justify="center">
							<GridItem xs={3} className={classes.circleBoxGrid}>
								<div className={classes.circleBox}>
									<DoughnutChart data={trxBackground} height={250} options={this.ChartOptions} id="canvas" />
								</div>
							</GridItem>
							<GridItem xs={3} className={classes.circleBoxGrid}>
								<div className={classes.circleBox}>
									<DoughnutChart data={feeBackground} height={250} options={this.ChartOptions} />
								</div>
							</GridItem>
							<GridItem xs={3} className={classes.circleBoxGrid}>
								<div className={classes.circleBox}>
									<DoughnutChart data={holdBackground} height={250} options={this.ChartOptions} />
								</div>
							</GridItem>
							<GridItem xs={3} className={classes.circleBoxGrid}>
								<div className={classes.circleBox}>
									<DoughnutChart data={returnedBackground} height={250} options={this.ChartOptions} />
								</div>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>

				<Card className="no-radius" style={{ display: Disabled ? "none" : "normal" }}>
					<CardHeader>
						<GenericTitle text={"Profile List"} />
					</CardHeader>
					<CardBody>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={CouponProfileList}
									Columns={this.ColumnsData}
									RowSelected={index => {
										this.SelectedRowChange(index);
									}}
								/>
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>

			</div>
		);
	}
}

CampaignDashboard.propTypes = {
	classes: PropTypes.object
};

export default withStyles(styles)(CampaignDashboard);