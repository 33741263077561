import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withArtifex } from "core";
import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  GenericEmailInput,
  GenericNumberInput,
  GenericAlert,
  GenericIcon,
  GenericLabel,
  GenericSelectInput,
  GenericTextInput,
  GenericTitle,
  GenericInput,
} from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { LOSLoanType } from "views/Constants/Constant";

const VerificationStyle = {
  VerificationTrue: {
    backgroundColor: "green",
    marginLeft: 25,
    margin: 12,
    borderRadius: 50,
    padding: 10,
    fontSize: 26,
    color: "white",
  },
  VerificationFalse: {
    backgroundColor: "red",
    marginLeft: 25,
    margin: 12,
    borderRadius: 50,
    padding: 10,
    fontSize: 26,
    color: "white",
  },
  VerificationDocument: {
    backgroundColor: "orange",
    marginLeft: 25,
    margin: 12,
    borderRadius: 50,
    padding: 10,
    fontSize: 26,
    color: "white",
  },
  VerificationEmpty: {
    backgroundColor: "black",
    marginLeft: 25,
    margin: 12,
    borderRadius: 50,
    padding: 10,
    fontSize: 26,
    color: "white",
  },
};

class LoanApprovalInformation extends Component {
  constructor(props) {
    super(props);

    var initialModel = {
      Address1: null,
      ZipCode: null,
      Address2: null,
      ProvinceId: null,
      CityId: null,
      FullName: null,
      DateOfBirth: null,
      Email: null,
      MobileNumber: null,
      OfficeNumber: null,
      HomeNumber: null,
    };
    this.state = {
      isLoading: false,
      model: {},
      vModel: { ...initialModel },
      ReadOnly: true,
    };
    this.maxLength100 = { maxLength: 100 };
    this.LOSSalaryParameterCode = { ParameterCode: "LOSSalary" };
    this.LOSAddrTimeParameterCode = { ParameterCode: "LOSAddrTime" };
    this.OccupationParameterCode = { ParameterCode: "LOSOccupation" };
    this.parameterEntityType = { ParameterCode: "EntityType" };
    this.parameterPaymentFrequency = { ParameterCode: "PaymentFrequency" };
    this.parameterPaymentMethod = { ParameterCode: "PaymentMethod" };
    this.parameterPaymentOptionFee = { ParameterCode: "PaymentOption" };
    this.YesNoParameterCode = { ParameterCode: "LOSYesNo" };
    this.LOSEmplTimeParameterCode = { ParameterCode: "LOSEmplTime" };
    this.LOSDepositInParameterCode = { ParameterCode: "LOSDepositIn" };
    this.MaritalStatusParameterCode = { ParameterCode: "LOSMaritalStatus" };

    this.maxLength20 = { maxLength: 20 };
    this.maxLength50 = { maxLength: 50 };
    this.marginLeft2 = { marginLeft: 2 };
    this.ParameterMaxLength30 = { maxLength: 30 };
    this.emptyObject = {};
  }

  componentDidMount() {}

  ShowMessageNode = (type, title, message) => {
    this.setState({
      alert: (
        <GenericAlert
          Title={title}
          MessageNode={message}
          Type={type}
          OnConfirm={() => this.hideAlert()}
        />
      ),
    });
    this.setState({ isLoading: false });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
      isLoading: false,
    });
  };
  ShowMessage = (type, title, message) => {
    // type:-success->warning->info->error
    this.setState({
      alert: (
        <GenericAlert
          Title={title}
          Message={message}
          Type={type}
          OnConfirm={() => this.hideAlert()}
        />
      ),
    });
    this.setState({ isLoading: false });
  };
  render() {
    const { model, Verification, IsUserAdmin } = this.props;
    const { ReadOnly } = this.state;
    return (
      <div>
        <GridContainer>
          {this.state.alert}

          <LoadingComponent Show={this.state.isLoading} />

          <GridContainer
            direction="row"
            justify="flex-start"
            alignItems="stretch"
          >
            <GridItem xs={4}>
              <div>
                <GridItem xs={12}>
                  <GenericTitle text={"Applicant"} />
                </GridItem>
                <GridItem>
                  <GenericEmailInput
                    Name="FullName"
                    LabelText="Full Name"
                    Value={
                      model.Applicant == null && model.Applicant === undefined
                        ? ""
                        : (model.Applicant.FirstName !== undefined
                            ? model.Applicant.FirstName
                            : "") +
                          " " +
                          (model.Applicant.MiddleName !== null &&
                          model.Applicant.MiddleName !== undefined
                            ? model.Applicant.MiddleName
                            : "") +
                          " " +
                          (model.Applicant.LastName !== undefined
                            ? model.Applicant.LastName
                            : "")
                    }
                    ValueChanged={this.props.HandleChange}
                    Disabled={ReadOnly}
                  />
                </GridItem>
                <GridItem>
                  <GenericEmailInput
                    Name="DateOfBirth"
                    LabelText="Date Of Birth"
                    Value={
                      model.Applicant == null ? "" : model.Applicant.BirthDate
                    }
                    ValueChanged={this.props.HandleChange}
                    Disabled={ReadOnly}
                  />
                </GridItem>
                <GridItem>
                  <GenericEmailInput
                    Name="Email"
                    LabelText="E-Mail Address"
                    Value={model.Applicant == null ? "" : model.Applicant.Email}
                    ValueChanged={this.props.HandleChange}
                    Disabled={ReadOnly}
                  />
                </GridItem>
                <GridItem>
                  <GridContainer>
                    <GridItem xs={8} sm={8} md={8}>
                      <GenericSelectInput
                        Name="MobilePhoneCountryCodeId"
                        LabelText="Phone Number"
                        TitleLabel="Country Code"
                        LabelMd={6}
                        Method="POST"
                        IsStatic={true}
                        StaticData={this.props.CountryList}
                        DataRoot="Item"
                        KeyValueMember="Id"
                        TextValueMember={"Code"}
                        Value={
                          model.Applicant == null
                            ? ""
                            : model.Applicant.MobilePhoneCountryCodeId
                        }
                        ValueChanged={this.props.HandleChange}
                        Sorted={{ Member: "OrderIndex" }}
                        RenderItem={(d) => "+" + d.Code + " (" + d.Name + ")"}
                        Disabled={ReadOnly}
                      />
                    </GridItem>
                    <GridItem
                      xs={4}
                      sm={4}
                      md={4}
                      style={{ paddingRight: 2 + "px !important" }}
                    >
                      <GenericNumberInput
                        IsPhone
                        Name="MobilePhoneNumber"
                        Format="(###) ### ####"
                        InputStyle={this.marginLeft2}
                        LabelMd={0}
                        Value={
                          model.Applicant == null
                            ? ""
                            : model.Applicant.MobilePhoneNumber
                        }
                        ValueChanged={this.props.HandleChange}
                        MaxLength={10}
                        Disabled={ReadOnly}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={8} sm={8} md={8}>
                      <GenericSelectInput
                        Name="OfficePhoneCountryCodeId"
                        LabelText="Office Phone Number"
                        TitleLabel="Country Code"
                        LabelMd={6}
                        Method="POST"
                        IsStatic={true}
                        StaticData={this.props.CountryList}
                        DataRoot="Item"
                        KeyValueMember="Id"
                        TextValueMember={"Code"}
                        Value={
                          model.Applicant == null
                            ? ""
                            : model.Applicant.MobilePhoneCountryCodeId
                        }
                        ValueChanged={this.props.HandleChange}
                        Sorted={{ Member: "OrderIndex" }}
                        RenderItem={(d) => "+" + d.Code + " (" + d.Name + ")"}
                        Disabled={ReadOnly}
                      />
                    </GridItem>
                    <GridItem
                      xs={4}
                      sm={4}
                      md={4}
                      style={{ paddingRight: 2 + "px !important" }}
                    >
                      <GenericNumberInput
                        IsPhone
                        Name="OfficePhoneNumber"
                        Format="(###) ### ####"
                        InputStyle={this.marginLeft2}
                        LabelMd={0}
                        Value={
                          model.Applicant == null
                            ? ""
                            : model.Applicant.OfficePhoneNumber
                        }
                        ValueChanged={this.props.HandleChange}
                        MaxLength={10}
                        Disabled={ReadOnly}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={8} sm={8} md={8}>
                      <GenericSelectInput
                        Name="HomePhoneCountryCodeId"
                        LabelText="Home Phone Number"
                        TitleLabel="Country Code"
                        LabelMd={6}
                        Method="POST"
                        IsStatic={true}
                        StaticData={this.props.CountryList}
                        // Url="/coreapi/v1.0/Country/GetAll"
                        // Parameter={this.emptyObject}
                        DataRoot="Item"
                        KeyValueMember="Id"
                        TextValueMember={"Code"}
                        Value={
                          model.Applicant == null
                            ? ""
                            : model.Applicant.MobilePhoneCountryCodeId
                        }
                        ValueChanged={this.props.HandleChange}
                        Sorted={{ Member: "OrderIndex" }}
                        RenderItem={(d) => "+" + d.Code + " (" + d.Name + ")"}
                        Disabled={ReadOnly}
                      />
                    </GridItem>
                    <GridItem
                      xs={4}
                      sm={4}
                      md={4}
                      style={{ paddingRight: 2 + "px !important" }}
                    >
                      <GenericNumberInput
                        IsPhone
                        Name="HomePhoneNumber"
                        Format="(###) ### ####"
                        InputStyle={this.marginLeft2}
                        LabelMd={0}
                        Value={
                          model.Applicant == null
                            ? ""
                            : model.Applicant.HomePhoneNumber
                        }
                        ValueChanged={this.props.HandleChange}
                        MaxLength={10}
                        Disabled={ReadOnly}
                      />
                    </GridItem>
						  <GridItem xs={8} sm={8} md={8}>
                      <GenericSelectInput
							   LabelMd={6}
                        Name="GenderId"
                        LabelText="Gender"
                        Value={model.Applicant.GenderId || ""}
                        KeyValueMember="Id"
                        TextValueMember="ParameterDesc"
                        Method="POST"
                        Url="/coreapi/v1.0/Parameter/Search"
                        Parameter={{
                          ParameterCode: "Gender",
                          ParameterValue: "",
                          ParameterDesc: "",
                        }}
                        DataRoot="Item"
                        Disabled={ReadOnly}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </div>
            </GridItem>
            <GridItem xs={4}>
              <div>
                <GridItem xs={12}>
                  <GenericTitle text={""} />
                </GridItem>
					 <GridItem>
                  <GenericTextInput
                    LabelMd={6}
                    Name="AddressLine1"
                    LabelText="Address Line 1"
                    Id="street-address"
                    Value={
                      model.Applicant == null ? "" : model.Applicant.Address1
                    }
                    ValueChanged={this.props.handleChange}
                    Disabled={ReadOnly}
                    inputProps={this.maxLength100}
                  />
                </GridItem>
                <GridItem>
                  <GenericTextInput
                    LabelMd={6}
                    Name="AddressLine2"
                    Id="street-address2"
                    LabelText="Address Line 2"
                    Value={
                      model.Applicant == null ? "" : model.Applicant.Address2
                    }
                    ValueChanged={this.props.handleChange}
                    Disabled={ReadOnly}
                    inputProps={this.maxLength100}
                  />
                </GridItem>
                <GridItem>
                  <GenericTextInput
                    LabelMd={6}
                    Name="ZipCode"
                    LabelText="Postal Code"
                    Id="postcode"
                    Value={
                      model.Applicant == null ? "" : model.Applicant.ZipCode
                    }
                    ValueChanged={this.props.handleChange}
                    Disabled={ReadOnly}
                    inputProps={this.maxLength20}
                  />
                </GridItem>
                <GridItem>
                  <GenericSelectInput
                    LabelMd={6}
                    Name="CountryId"
                    LabelText="Country"
                    Method="POST"
                    Url="/coreapi/v1.0/Country/GetAll"
                    Parameter={{}}
                    DataRoot="Item"
                    KeyValueMember="Id"
                    TextValueMember="Name"
                    Value={
                      model.Applicant == null ? "" : model.Applicant.CountryId
                    }
                    ValueChanged={this.props.handleChange}
                    Disabled={ReadOnly}
                  />
                </GridItem>
                <GridItem>
                  <GenericSelectInput
                    LabelMd={6}
                    Name="ProvinceId"
                    LabelText="Province"
                    Method="POST"
                    Url="/coreapi/v1.0/Province/GetAll"
                    Parameter={{}}
                    DataRoot="Item"
                    KeyValueMember="Id"
                    TextValueMember="Name"
                    Value={
                      model.Applicant == null ? "" : model.Applicant.ProvinceId
                    }
                    ValueChanged={this.props.handleChange}
                    Disabled={ReadOnly}
                  />
                </GridItem>
                <GridItem>
                  <GenericSelectInput
                    LabelMd={6}
                    Name="CityId"
                    LabelText="City"
                    Method="POST"
                    Url="/coreapi/v1.0/City/GetAll"
                    DataRoot="Item"
                    KeyValueMember="Id"
                    TextValueMember="Name"
                    Value={
                      model.Applicant == null ? "" : model.Applicant.CityId
                    }
                    ValueChanged={this.props.handleChange}
                    Disabled={ReadOnly}
                  />
                </GridItem>
              </div>
            </GridItem>
            <GridItem xs={4}>
              <GridContainer
                direction="row"
                justify="flex-start"
                alignItems="stretch"
              >
                <GridItem>
                  <GridContainer
                    direction="column"
                    justify="flex-start"
                    alignItems="stretch"
                  >
                    <GridItem xs={12}>
                      <GenericTitle text={"External Credit Score(s)"} />
                    </GridItem>
                    <GridItem xs={12}>
                      <GenericTextInput
                        Name="TransUnionCreditScore"
                        LabelText="Trans Union"
                        IsText
                        LabelMd={7}
                        Value={
                          model.TransUnionCreditScore &&
                          model.TransUnionCreditScore != 0
                            ? model.TransUnionCreditScore
                            : "N/A"
                        }
                        ValueChanged={this.props.HandleChange}
                        Disabled={true}
                      />
                      {/* <GenericTextInput
												Name="EquifaxCreditScore"
												LabelText="Equifax"
												IsText
												LabelMd={7}
												Value={model.EquifaxCreditScore == null || undefined ? "" : model.EquifaxCreditScore}
												ValueChanged={this.props.HandleChange}
												Disabled={true} /> */}
                      {/* <GenericTextInput
												Name="OtherCreditScore"
												LabelText="Other"
												IsText
												LabelMd={7}
												Value={model.OtherCreditScore == null || undefined ? "" : model.OtherCreditScore}
												ValueChanged={this.props.HandleChange}
												Disabled={true} /> */}
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12}>
              <GridItem xs={12}>
                <GridContainer
                  direction="row"
                  justify="flex-start"
                  alignItems="stretch"
                >
                  <GridItem xs={8}>
                    <GridContainer
                      direction="column"
                      justify="flex-start"
                      alignItems="stretch"
                    >
                      <Card style={{ marginTop: 20 }} className="no-radius">
                        <CardBody>
                          <GridItem xs={12}>
                            <GridContainer
                              direction="row"
                              justify="flex-start"
                              alignItems="stretch"
                            >
                              <GridItem xs={10}>
                                <GridContainer
                                  direction="row"
                                  justify="flex-start"
                                  alignItems="stretch"
                                >
                                  <GridItem xs={2}>
                                    <VerificationComponent
                                      label={"Blacklist"}
                                      verification={
                                        Verification.isBlackListVerification
                                      }
                                    />
                                  </GridItem>
                                  <GridItem xs={2}>
                                    <VerificationComponent
                                      label={"Watch List"}
                                      verification={
                                        Verification.isWatchListVerification
                                      }
                                    />
                                  </GridItem>
                                  <GridItem xs={2}>
                                    <VerificationComponent
                                      label={"TransUnion"}
                                      verification={
                                        Verification.isTransUnionIdVerification
                                      }
                                    />
                                  </GridItem>
                                  <GridItem xs={2}>
                                    <VerificationComponent
                                      label={"Canada Post"}
                                      verification={
                                        Verification.isAddressVerification
                                      }
                                    />
                                  </GridItem>
                                  <GridItem xs={2}>
                                    <VerificationComponent
                                      label={"In Person ID"}
                                      verification={
                                        Verification.isInPersonVerification
                                      }
                                    />
                                  </GridItem>
                                  <GridItem xs={2}>
                                    <VerificationComponent
                                      label={"Credit History"}
                                      verification={
                                        Verification.isCreditHistoryVerification
                                      }
                                    />
                                  </GridItem>
                                </GridContainer>
                              </GridItem>
                              {IsUserAdmin &&
                                model.LoanType &&
                                model.LoanType.ParameterValue ==
                                  LOSLoanType.SecureCard && (
                                  <GridItem xs={2}>
                                    <VerificationComponent
                                      label={"Security Balance"}
                                      verification={
                                        Verification.isSecurityAccountHaveBalance
                                      }
                                    />
                                  </GridItem>
                                )}
                            </GridContainer>
                          </GridItem>
                        </CardBody>
                      </Card>
                    </GridContainer>
                  </GridItem>
                  <GridItem xs={4}>
                    <GenericTitle text={"Internal Credit Score"} />
                    <ScoreComponent Score={this.props.Score} />
                  </GridItem>
                  <GridItem xs={8}>
                    <GridContainer
                      direction="column"
                      justify="flex-start"
                      alignItems="stretch"
                    >
                      <GridItem xs={12}>
                        <GridContainer
                          direction="column"
                          justify="flex-start"
                          alignItems="stretch"
                        >
                          <GridItem xs={12}>
                            <GenericTitle text={"Checker Note"} />
                          </GridItem>
                          <GridItem xs={12} style={{ marginTop: 5 }}>
                            <GenericTextInput
                              Name="CheckerNote"
                              LabelMd={0}
                              MultiLine={true}
                              RowCount={3}
                              Value={
                                model.CheckerNote == null || undefined
                                  ? ""
                                  : model.CheckerNote
                              }
                              ValueChanged={this.props.HandleChange}
                              Disabled={IsUserAdmin ? true : false}
                            />
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem xs={3}>
                    <GridContainer
                      direction="column"
                      justify="flex-start"
                      alignItems="stretch"
                    >
                      <GridItem xs={12}>
                        <GenericTitle text={"System Result"} />
                      </GridItem>
                      <GridItem xs={12}>
                        <SystemResult result={this.props.SystemResult} />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridItem>
          </GridContainer>
        </GridContainer>
      </div>
    );
  }
}
const ScoreComponent = ({ Score }) => (
	<div style={{ backgroundColor: "#457CF7", border: "solid 1px #f2f2f2", borderRadius: 7, height: 60, width: "100%", fontWeight: "bold", fontSize: 38, textAlign: "center", padding: 20, margin: "10px 2px" }}>{Score.score}</div>
);
const SystemResult = ({ result }) => (
  <div
    style={{
      border: "solid 1px #f2f2f2",
      borderRadius: 7,
      height: 60,
      width: "100%",
      fontSize: 16,
      fontWeight: "bold",
      textAlign: "center",
      padding: 20,
      margin: 10,
    }}
  >
    {result}
  </div>
);
const VerificationComponent = ({ label, verification }) => (
  <GridContainer direction="column" justify="flex-start" alignItems="stretch">
    <GenericLabel
      Text={label}
      TextAlign="left"
      Bold
      Display="block"
    ></GenericLabel>
    {verification == null ? (
      <GenericIcon style={VerificationStyle.VerificationEmpty}>
        fa-ban
      </GenericIcon>
    ) : verification == true ? (
      <GenericIcon style={VerificationStyle.VerificationTrue}>
        fa-check-circle
      </GenericIcon>
    ) : (
      <GenericIcon style={VerificationStyle.VerificationFalse}>
        fa-times-circle
      </GenericIcon>
    )}
  </GridContainer>
);

LoanApprovalInformation.propTypes = {
  classes: PropTypes.object.isRequired,
  model: PropTypes.object,
  tempModel: PropTypes.object,
  addressModel: PropTypes.object,
  HandleChange: PropTypes.func,
  LoanApprovalFeeList: PropTypes.array,
  Title: PropTypes.string,
  Disabled: PropTypes.bool,
  SystemResult: PropTypes.string,
  Score: PropTypes.object,
  Verification: PropTypes.object,
  IsUserAdmin: PropTypes.bool,
  ParameterList: PropTypes.array,
  CountryList: PropTypes.array,
};

export default withArtifex(LoanApprovalInformation, {});
