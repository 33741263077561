import { chartColors } from "assets/jss/material-dashboard-pro-react.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericGrid, GenericLabel, GenericNumberInput, GenericSelectInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import FundingSummaryChart from "views/LMS/FundingSummary/FundingSummaryChart";
import { GridColumnType } from "views/Constants/Constant";

class FundingSummary extends Component {
	constructor(props) {
		super(props);
		this.UploadClick = this.UploadClick.bind(this);
		this.fileInputRef = React.createRef();
		this.emptyObject = {};
		this.FileTypeParameterCode = { ParameterCode: "LOSFileType" };

		this.ColumnsData = [
			{
				Header: "Date",
				accessor: "TransactionDate",
				type: GridColumnType.DateTime,
				filterMethod: (filter, row, column) => {
					return Formatter.FormatDateTime(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase());
				},
				sortable: false,
				filterable: false,
			},
			{
				Header: "Partner",
				accessor: "ClientPartner.LegalNameOfBusiness",
				sortable: false,
				filterable: false,
			},
			{
				Header: "No of Un-Funded Approved Loans",
				accessor: "UnFundedApprovedLoansCount",
				sortable: false,
				filterable: false,
			},
			{
				Header: "Amount of Un-Funded Approved Loans",
				accessor: "UnFundedApprovedLoansAmount",
				type: GridColumnType.Money,
				sortable: false,
				filterable: false,
			},
			{
				Header: "No Of 30 days Funded Loans",
				accessor: "LastOneMonthFundedApprovedLoansCount",
				sortable: false,
				filterable: false,
			},
			{
				Header: "Amount Of 30 Days Funded Loans",
				accessor: "LastOneMonthFundedApprovedLoansAmount",
				type: GridColumnType.Money,
				sortable: false,
				filterable: false,
			},
			{
				Header: "No Of 60 days Funded Loans",
				accessor: "LastTwoMonthsFundedApprovedLoansCount",
				sortable: false,
				filterable: false,
			},
			{
				Header: "Amount Of 60 Days Funded Loans",
				accessor: "LastTwoMonthsFundedApprovedLoansAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money,
				sortable: false,
				filterable: false,
			},
			{
				Header: "No Of 90 days Funded Loans",
				accessor: "LastThreeMonthsFundedApprovedLoansCount",
				sortable: false,
				filterable: false,
			},
			{
				Header: "Amount Of 90 Days Funded Loans",
				accessor: "LastThreeMonthsFundedApprovedLoansAmount",
				type: GridColumnType.Money,
				ColumnType: GridColumnType.Money,
				sortable: false,
				filterable: false,
			},
		];

		this.state = {
			model: {
				LoanType: null
			},
			isLoading: false,
			ReadOnly: true,
			selected: null,
			list: [],
			unFundedApprovedChartData: {
				labels: [""],
				datasets: [{
					data: [100],
					backgroundColor: "rgb(255, 159, 64)",
					hoverBackgroundColor: "rgb(255, 159, 64)"
				}],
				text: ""
			},
			approvedFundedOneMonthChartData: {
				labels: [""],
				datasets: [{
					data: [100],
					backgroundColor: "rgb(255, 159, 64)",
					hoverBackgroundColor: "rgb(255, 159, 64)"
				}],
				text: "30"
			},
			approvedFundedTwoMonthsChartData: {
				labels: [""],
				datasets: [{
					data: [100],
					backgroundColor: "rgb(255, 159, 64)",
					hoverBackgroundColor: "rgb(255, 159, 64)"
				}],
				text: "60"
			},
			pieChartData: {
				labels: [""],
				datasets: [
					{
						data: [100],
						backgroundColor: "rgb(255, 159, 64)",
						hoverBackgroundColor: "rgb(255, 159, 64)"
					}
				],
				text: ""
			},
		};

		this.InterestTypeParameterCode = { ParameterCode: "LOSInterestType" };
		this.PeriodParameterCode = { ParameterCode: "Period" };
		this.PayOptionParameterCode = { ParameterCode: "LOSPayOption" };
		this.YesNoParameterCode = { ParameterCode: "LOSYesNo" };
		this.LoanTypeParameterCode = { ParameterCode: "LOSLoanType" };

		this.SuccessListCallback = this.SuccessListCallback.bind(this);
	}

	componentDidMount() {
		this.props.setAppLeftTitle("Funding Summary");
		this.props.setAppCenterTitle("LOAN MANAGEMENT SYSTEM");
	}

	Search = () => {
		const { model } = this.state;
		if (!model.Top) {
			this.ShowMessage("warning", "Top Filter Not Selected", "Select Top Filter To Continue");
			return false;
		}
		this.setState({ isLoading: true });
		Proxy.POST(
			"/lmsapi/v1.0/Dashboard/GetFundingSummary",
			this.state.model,
			responseData => {
				console.log("GetFundingSummary ->", responseData.IsSucceeded);
				if (!responseData.IsSucceeded) {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					console.log(responseData);
					this.SuccessListCallback(responseData);
					model.OutstandingData = responseData.Item.OutstandingData;
					model.TotalLoanData = responseData.Item.TotalLoanData;
					model.PastdueData = responseData.Item.PastdueData;
					model.InterestData = responseData.Item.InterestData;
					model.PaymentsData = responseData.Item.PaymentsData;
					this.setState({ model, list: responseData.Item.FundedLoans, isLoading: false });
				} else {
					this.setState({ model: {}, list: [], isLoading: false });
				}
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		if (name === "LoanTypeId")
			model.LoanType = data;
		this.setState({ model });
	}

	UploadClick() {
		this.fileInputRef.current.click();
	}

	HandleClear = () => {
		this.HideAlert();
		this.setState({ model: {}, selected: null });
	}

	ErrorCallback = (error) => {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", "An error occurred during the api visit" + error);
	}

	ShowMessageAndRefresh = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlertAndRefresh()} />
		});
	}

	hideAlertAndRefresh = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
		window.location.reload();

	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() =>
				this.setState({ alert: "" })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: null })} OnConfirm={onConfirm} />
		});
	}

	HideAlert = () => {
		this.setState({ alert: null, isLoading: false });
	}

	RefreshToolBar = () => {
		this.setState({ LimitToken: !this.state.LimitToken });
	}

	SuccessListCallback(responseData) {
		console.log("successslistcallback");
		var arrayColor = [];
		var arrayColor2 = [];
		var arrayColor3 = [];

		if (responseData.Item !== null) {
			var colorNames = Object.keys(chartColors);
			var i = 0;

			var colorName = colorNames[i % colorNames.length];
			var newColor = chartColors[colorName];
			arrayColor.push(newColor);

			var unFundedApprovedChartDataObj = {
				labels: [responseData.Item.UnFundedChartData[0].Label],
				datasets: [{
					data: [responseData.Item.UnFundedChartData[0].Amount > 0 ? responseData.Item.UnFundedChartData[0].Amount : 100],
					hoverBackgroundColor: arrayColor,
					backgroundColor: arrayColor
				}],
				text: "Un-Approveds"
			};

			colorNames = Object.keys(chartColors);
			colorName = colorNames[i % colorNames.length];
			newColor = chartColors[colorName];
			arrayColor2.push(newColor);

			var approvedFundedOneMonthChartDataObj = {
				labels: [responseData.Item.FundedOneMonthChartData[0].Label],
				datasets: [{
					data: [responseData.Item.FundedOneMonthChartData[0].Amount > 0 ? responseData.Item.FundedOneMonthChartData[0].Amount : 100],
					hoverBackgroundColor: arrayColor2,
					backgroundColor: arrayColor2
				}],
				text: "30"
			};

			colorNames = Object.keys(chartColors);
			colorName = colorNames[i % colorNames.length];
			newColor = chartColors[colorName];
			arrayColor3.push(newColor);

			var approvedFundedTwoMonthsChartDataObj = {
				labels: [responseData.Item.FundedTwoMonthsChartData[0].Label],
				datasets: [{
					data: [responseData.Item.FundedTwoMonthsChartData[0].Amount > 0 ? responseData.Item.FundedTwoMonthsChartData[0].Amount : 100],
					hoverBackgroundColor: arrayColor3,
					backgroundColor: arrayColor3
				}],
				text: "60"
			};

			var pieChartObj = {
				labels: [],
				datasets: [],
				text: ""
			};
			var obj = {
				data: [],
				backgroundColor: [],
				hoverBackgroundColor: []
			};
			colorNames = Object.keys(chartColors);
			for (var item of responseData.Item.FundingStatusChartData) {
				colorName = colorNames[i % colorNames.length];
				newColor = chartColors[colorName];
				pieChartObj.labels.push(item["Label"]);
				obj.data.push(item["Amount"]);
				obj.backgroundColor.push(newColor);
				obj.hoverBackgroundColor.push(newColor);
				pieChartObj.datasets = [obj];
				i++;
			}

			/********************************************************/
			const { model } = this.state;
			model.BeginDate = responseData.Item.BeginDate;
			model.EndDate = responseData.Item.EndDate;
			this.setState({
				model,
				unFundedApprovedChartData: unFundedApprovedChartDataObj,
				approvedFundedOneMonthChartData: approvedFundedOneMonthChartDataObj,
				approvedFundedTwoMonthsChartData: approvedFundedTwoMonthsChartDataObj,
				pieChartData: pieChartObj
			});
		}
	}

	render() {
		const { ForInsert, Disabled } = this.props;
		const { alert, isLoading, ReadOnly, model } = this.state;
		return (
			<div>

				{alert}

				<LoadingComponent Show={isLoading} />

				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled },
					{ Code: "Search", OnClick: this.Search },
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} IsPopUp={ForInsert} />
				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer>
											<GridItem xs={4}>
												<GridItem>
													<GenericNumberInput
														Name="AwailableAccountBalance"
														LabelText="Available Wallet Balance"
														LabelMd={6}
														MaxLength={10}
														Prefix="$"
														LabelStyle={styles.LabelStyle}
														InputStyle={styles.InputStyle}
														ValueChanged={this.HandleChange}
														Disabled={ReadOnly}
													/>
												</GridItem>
											</GridItem>
											<GridItem xs={2}>
												<GridItem>
													<GenericNumberInput
														Name="Limit"
														LabelText="Limit"
														LabelMd={3}
														MaxLength={10}
														Prefix="$"
														LabelStyle={styles.LabelStyle}
														InputStyle={styles.InputStyle}
														ValueChanged={this.HandleChange}
														Disabled={ReadOnly}
													/>
												</GridItem>
											</GridItem>
											<GridItem xs={3}>
												<GridItem>
													<GenericNumberInput
														Name="AvaliableLimit"
														LabelText="Available Limit"
														LabelMd={5}
														MaxLength={10}
														Prefix="$"
														LabelStyle={styles.LabelStyle}
														InputStyle={styles.InputStyle}
														ValueChanged={this.HandleChange}
														Disabled={ReadOnly}
													/>
												</GridItem>
											</GridItem>
											<GridItem xs={3}>
												<GridItem>
													<GenericNumberInput
														Name="AvalianleLmsTransferLimit"
														LabelText="Available LMS Transfer Limit"
														LabelMd={8}
														MaxLength={10}
														Prefix="$"
														LabelStyle={styles.LabelStyle}
														InputStyle={styles.InputStyle}
														ValueChanged={this.HandleChange}
														Disabled={ReadOnly}
													/>
												</GridItem>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>

					<GridItem xs={12} >
						<Card className="no-radius">
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer>
											<GridItem xs={2}>
												<label style={styles.LabelText2}>Top</label>
												<GridItem>
													<GenericSelectInput
														Name="Top"
														LabelMd={2}
														IsStatic={true}
														StaticData={[{ Value: 10, Text: "10" }, { Value: 20, Text: "20" }, { Value: 30, Text: "30" }, { Value: 40, Text: "40" }, { Value: 50, Text: "50" }, { Value: 100, Text: "100" }, { Value: 200, Text: "200" }]}
														KeyValueMember="Value"
														TextValueMember="Text"
														Value={model.Top}
														ValueChanged={this.HandleChange}
														Required />
												</GridItem>
											</GridItem>
											<GridItem xs={2}>
												<label style={styles.LabelText}>Select Program</label>
												<GridItem>
													<GenericSelectInput
														Name="LoanTypeId"
														LabelMd={3}
														Method="POST"
														Url="/coreapi/v1.0/Parameter/Search"
														Parameter={this.LoanTypeParameterCode}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="ParameterDesc"
														Value={model.LoanTypeId}
														ValueChanged={this.HandleChange} />
												</GridItem>
											</GridItem>
											<GridItem xs={2}>
												<label style={styles.LabelText}>Select Partner</label>
												<GridItem>
													<GenericSelectInput
														Name="ClientPartnerId"
														LabelMd={3}
														Method="POST"
														Url="/losapi/v1.0/ClientPartner/GetAll"
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="LegalNameOfBusiness"
														Value={model.ClientPartnerId}
														ValueChanged={this.HandleChange} />
												</GridItem>
											</GridItem>
											<GridItem xs={2}>
												<label style={styles.LabelText2}>From Date</label>
												<GridItem>
													<GenericDateInput
														Name="FromDate"
														LabelMd={2}
														Value={model.FromDate ? DateHelper.ToDateInputValue(model.FromDate) : ""}
														ValueChanged={this.HandleChange}
														Utc />
												</GridItem>
											</GridItem>
											<GridItem xs={2}>
												<label style={styles.LabelText2}>To Date</label>
												<GridItem>
													<GenericDateInput
														Name="ToDate"
														LabelMd={3}
														Value={model.ToDate ? DateHelper.ToDateInputValue(model.ToDate) : ""}
														ValueChanged={this.HandleChange}
														Utc />
												</GridItem>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>

					<GridItem xs={12} style={{ marginTop: 12 }}>
						<GridContainer
							direction="row"
							justify="flex-start"
							alignItems="stretch">

							<GridItem xs={5}>
								<Card style={{ height: "90%" }}>
									<CardHeader color="warning" icon>
										<CardIcon color="warning">
											<p style={{ color: "white" }}>
												<b>Accounts & Limits</b>
											</p>
										</CardIcon>
									</CardHeader>
									<CardBody style={{ paddingTop: 36, paddingBottom: 25 }}>

										<GridContainer>
											<GridItem xs={3} />
											<GridItem xs={2} style={{ textAlign: "center" }}>
												<GenericLabel Text="Amount" />
											</GridItem>
											<GridItem xs={2} />
											<GridItem xs={3} style={{ textAlign: "center" }}>
												<GenericLabel Text="Unit" />
											</GridItem>
										</GridContainer>

										<GridContainer>
											<GridItem xs={12}>
												<GridContainer>
													<GridItem xs={5}>
														<GenericNumberInput
															LabelText="Current Outstanding"
															LabelMd={7}
															MaxLength={10}
															Prefix="$"
															Value={model.OutstandingData != null ? model.OutstandingData.Amount : 0}
															Disabled={ReadOnly} />
													</GridItem>
													<GridItem xs={2}>
														<GenericNumberInput
															LabelMd={0}
															MaxLength={3}
															Suffix="%"
															Value={(model.TotalLoanData != null && model.OutstandingData != null ? model.OutstandingData.Amount / (model.TotalLoanData.Amount == 0 ? 1 : model.TotalLoanData.Amount) : 0) * 100}
															Disabled={ReadOnly} />
													</GridItem>
													<GridItem xs={3}>
														<GenericNumberInput
															NoFormatting={true}
															LabelMd={0}
															MaxLength={10}
															Value={model.OutstandingData != null ? model.OutstandingData.Count : 0}
															Disabled={ReadOnly} />
													</GridItem>
													<GridItem xs={2}>
														<GenericNumberInput
															LabelMd={0}
															MaxLength={3}
															Suffix="%"
															Value={(model.TotalLoanData != null && model.OutstandingData != null ? model.OutstandingData.Count / (model.TotalLoanData.Count == 0 ? 1 : model.TotalLoanData.Count) : 0) * 100}
															Disabled={ReadOnly} />
													</GridItem>
												</GridContainer>
											</GridItem>
										</GridContainer>

										<GridContainer>
											<GridItem xs={12}>
												<GridContainer>
													<GridItem xs={5}>
														<GenericNumberInput
															LabelText="Available"
															LabelMd={7}
															MaxLength={10}
															Prefix="$"
															Value={model.TotalLoanData != null ? model.TotalLoanData.Amount : 0}
															Disabled={ReadOnly} />
													</GridItem>
													<GridItem xs={2}>
														<GenericNumberInput
															LabelMd={0}
															MaxLength={3}
															Suffix="%"
															Value={model.TotalLoanData != null ? 100 : 0}
															Disabled={ReadOnly} />
													</GridItem>
													<GridItem xs={3}>
														<GenericNumberInput
															NoFormatting={true}
															LabelMd={0}
															MaxLength={10}
															Value={model.TotalLoanData != null ? model.TotalLoanData.Count : 0}
															Disabled={ReadOnly} />
													</GridItem>
													<GridItem xs={2}>
														<GenericNumberInput
															LabelMd={0}
															MaxLength={3}
															Suffix="%"
															Value={model.TotalLoanData != null ? 100 : 0}
															Disabled={ReadOnly} />
													</GridItem>
												</GridContainer>
											</GridItem>
										</GridContainer>

										<GridContainer>
											<GridItem xs={12}>
												<GridContainer>
													<GridItem xs={5}>
														<GenericNumberInput
															LabelText="Overdue"
															LabelMd={7}
															MaxLength={10}
															Prefix="$"
															Value={model.PastdueData != null ? model.PastdueData.Amount : 0}
															Disabled={ReadOnly} />
													</GridItem>
													<GridItem xs={2}>
														<GenericNumberInput
															LabelMd={0}
															MaxLength={3}
															Suffix="%"
															Value={(model.TotalLoanData != null && model.PastdueData != null ? model.PastdueData.Amount / (model.TotalLoanData.Amount == 0 ? 1 : model.TotalLoanData.Amount) : 0) * 100}
															Disabled={ReadOnly} />
													</GridItem>
													<GridItem xs={3}>
														<GenericNumberInput
															NoFormatting={true}
															LabelMd={0}
															MaxLength={10}
															Value={model.PastdueData != null ? model.PastdueData.Count : 0}
															Disabled={ReadOnly} />
													</GridItem>
													<GridItem xs={2}>
														<GenericNumberInput
															LabelMd={0}
															MaxLength={3}
															Suffix="%"
															Value={(model.TotalLoanData != null && model.PastdueData != null ? model.PastdueData.Count / (model.TotalLoanData.Count == 0 ? 1 : model.TotalLoanData.Count) : 0) * 100}
															Disabled={ReadOnly} />
													</GridItem>
												</GridContainer>
											</GridItem>
										</GridContainer>

										<GridContainer>
											<GridItem xs={12}>
												<GridContainer>
													<GridItem xs={5}>
														<GenericNumberInput
															LabelText="Interest Of Fees"
															LabelMd={7}
															MaxLength={10}
															Prefix="$"
															Value={model.InterestData != null ? model.InterestData.Amount : 0}
															Disabled={ReadOnly} />
													</GridItem>
													<GridItem xs={2}>
														<GenericNumberInput
															LabelMd={0}
															MaxLength={3}
															Suffix="%"
															Value={(model.TotalLoanData != null && model.InterestData != null ? model.InterestData.Amount / (model.TotalLoanData.Amount == 0 ? 1 : model.TotalLoanData.Amount) : 0) * 100}
															Disabled={ReadOnly} />
													</GridItem>
													<GridItem xs={3}>
														<GenericNumberInput
															NoFormatting={true}
															LabelMd={0}
															MaxLength={10}
															Value={model.InterestData != null ? model.InterestData.Count : 0}
															Disabled={ReadOnly} />
													</GridItem>
													<GridItem xs={2}>
														<GenericNumberInput
															LabelMd={0}
															MaxLength={3}
															Suffix="%"
															Value={(model.TotalLoanData != null && model.InterestData != null ? model.InterestData.Count / (model.TotalLoanData.Count == 0 ? 1 : model.TotalLoanData.Count) : 0) * 100}
															Disabled={ReadOnly} />
													</GridItem>
												</GridContainer>
											</GridItem>
										</GridContainer>

										<GridContainer>
											<GridItem xs={12}>
												<GridContainer>
													<GridItem xs={5}>
														<GenericNumberInput
															LabelText="Payments"
															LabelMd={7}
															MaxLength={10}
															Prefix="$"
															Value={model.PaymentsData != null ? model.PaymentsData.Amount : 0}
															Disabled={ReadOnly} />
													</GridItem>
													<GridItem xs={2}>
														<GenericNumberInput
															LabelMd={0}
															MaxLength={3}
															Suffix="%"
															Value={(model.TotalLoanData != null && model.PaymentsData != null ? model.PaymentsData.Amount / (model.TotalLoanData.Amount == 0 ? 1 : model.TotalLoanData.Amount) : 0) * 100}
															Disabled={ReadOnly} />
													</GridItem>
													<GridItem xs={3}>
														<GenericNumberInput
															NoFormatting={true}
															LabelMd={0}
															MaxLength={10}
															Value={model.PaymentsData != null ? model.PaymentsData.Count : 0}
															Disabled={ReadOnly} />
													</GridItem>
													<GridItem xs={2}>
														<GenericNumberInput
															LabelMd={0}
															MaxLength={3}
															Suffix="%"
															Value={(model.TotalLoanData != null && model.PaymentsData != null ? model.PaymentsData.Count / (model.TotalLoanData.Count == 0 ? 1 : model.TotalLoanData.Count) : 0) * 100}
															Disabled={ReadOnly} />
													</GridItem>
												</GridContainer>
											</GridItem>
										</GridContainer>
									</CardBody>
								</Card>
							</GridItem>

							<GridItem xs={7}>
								<FundingSummaryChart
									unFundedApprovedChartData={this.state.unFundedApprovedChartData}
									approvedFundedOneMonthChartData={this.state.approvedFundedOneMonthChartData}
									approvedFundedTwoMonthsChartData={this.state.approvedFundedTwoMonthsChartData}
									pieChartData={this.state.pieChartData}
									setAppLeftTitle={this.props.setAppLeftTitle}
									setAppCenterTitle={this.props.setAppCenterTitle} />
							</GridItem>
						</GridContainer>
					</GridItem>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<div>
									<p style={{ color: "black" }}>
										<b>Approved Funded Loans</b>
									</p>
								</div>
								<GenericGrid
									Data={this.state.list || []}
									Columns={this.ColumnsData} />
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

const styles = ({
	LabelText: {
		color: "Black",
		marginLeft: 50
	},
	LabelText2: {
		color: "black",
		marginLeft: 80
	},
	LabelStyle: {
		fontSize: 8 + "px !important",
	},
	InputStyle: {
		backgroundColor: "White"
	},
	cardTopMargin: {
		marginTop: 30
	}
});

FundingSummary.propTypes = {
	tabList: PropTypes.array,
	classes: PropTypes.object.isRequired,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ApplicantSaved: PropTypes.func,
	Disabled: PropTypes.bool,
	model: PropTypes.object
};

export default withArtifex(FundingSummary, styles);