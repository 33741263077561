
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericExpansionPanel, GenericGrid, GenericSelectInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import ParameterComponent from "views/Components/ParameterComponent.jsx";
import { GridColumnType } from "views/Constants/Constant.js";

class ExternalBankSettlementInstruction extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			model: {},
			selected: true,
			vModel: {},
			list: [],
			isLoading: false,
		};

		this.index = 0;
		this.sortParamValue = { Member: "ParameterValue" };
		this.trxTable = React.createRef();
		this.ColumnsdailyFileList = [
			{
				Header: "Transaction Date",
				accessor: "InsertDateTime",
				type: GridColumnType.DateUtc
			},
			{
				Header: "Unique Client Id",
				accessor: "UniqueClient.UniqueClientId"
			},
			{
				Header: " Client Name",
				accessor: "UniqueClient.ClientName"
			},
			{
				Header: "Customer Name",
				accessor: "ClientCustomer.CustomerName"
			},
			{
				Header: "Beneficiary",
				Cell: row => (
					<div>{row?.original?.Beneficiary?.FinancialInstitution?.Description + "(" + row?.original?.Beneficiary?.AccountNumber + ")"}</div>
				)
			},
			{
				Header: "Financial Wallet",
				accessor: "FinancialAccount.AccountName",
			},
			{
				Header: "Eft Transaction Type",
				accessor: "EftTransactionType.ParameterDesc",
			},

			{
				Header: "Settlement Amount Finding Type",
				accessor: "SettlementAmountFindingType.ParameterDesc",
			},
			{
				Header: "Settlement Transaction Type",
				accessor: "SettlementTransactionType.ParameterDesc"
			},
		];
	}

	componentDidMount() {
		const { setAppLeftTitle, setAppCenterTitle } = this.props;
		if (setAppLeftTitle) setAppLeftTitle("External Bank Settlement Instruction");
		if (setAppCenterTitle) setAppCenterTitle("Client");
		this.setState({ loadingCompleted: true });
	}

	Validatecheck = () => {
		const { model, vModel } = this.state;
		var messages = [];

		vModel.UniqueClientId = false;
		vModel.ClientCustomerId = false;
		vModel.BeneficiaryId = false;
		vModel.FinancialAccountId = false;
		vModel.SettlementTransactionTypeId = false;
		vModel.EftTransactionTypeId = false;
		if (!model.UniqueClientId) {
			messages.push("Client not selected");
			vModel.UniqueClientId = true;
		}
		if (!model.ClientCustomerId) {
			messages.push("Client Customer not selected");
			vModel.ClientCustomerId = true;
		}
		if (!model.BeneficiaryId) {
			messages.push("Beneficiary not selected");
			vModel.BeneficiaryId = true;
		}
		if (!model.FinancialAccountId) {
			messages.push("Financial Wallet not selected");
			vModel.FinancialAccountId = true;
		}
		if (!model.SettlementTransactionTypeId) {
			messages.push("Transaction Code not selected");
			vModel.SettlementTransactionTypeId = true;
		}
		if (!model.EftTransactionTypeId) {
			messages.push("Transaction Code not selected");
			vModel.EftTransactionTypeId = true;
		}
		if (messages.length > 0) {
			this.props.showMessage("warning", "Please fill required fields!", messages.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		return true;
	}

	SelectedRowChange = (index) => {
		const model = this.state.list[index];
		if (model.Id == null || model.Id == undefined)
			this.props.showMessage("warning", "File Information", "FileInformationId can not be null");
		else {
			this.setState({ model, isLoading: false, selected: false });
		}
	}

	Search = async () => {
		const { model } = this.state;
		const { ExecuteApiPost } = this.props;
		var result = await ExecuteApiPost("/bankapi/v1.0/BankBmoEFT/SettlementInstructionSearch", model);
		if (result != null) {
			this.setState({ list: result });
		}
		else {
			this.setState({ list: [] });

		}
	}


	handleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;
		this.setState({ model });
	}
	ClearModel = () => {
		this.index++;
		this.setState({
			model: {}, list: []
		});
	}

	RenderTransactionCode(d) {
		const transactionCode = d.ParameterValue;
		const transactionCodeDescription = d.ParameterDesc;

		return `${transactionCode} - ${transactionCodeDescription}`;
	}
	RenderItemClientCustomer = (d) => {
		return `${d.ClientCustomerNumber} - ${d.Name}`;
	}
	render() {
		const { Disabled, showConfirmMessageInDelete, ExecuteApiPost } = this.props;
		const { model, isLoading, vModel, selected, list } = this.state;
		return (
			<div>

				<LoadingComponent Show={isLoading} />
				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.Search, Disabled: Disabled },
					{ Code: "Clear", OnClick: this.ClearModel, Disabled: Disabled },
					{
						Code: "Submit",
						OnClick: () => ExecuteApiPost("/bankapi/v1.0/BankBmoEFT/SettlementInstructionInsertOrUpdate", model, null, null, this.Validatecheck, [this.ClearModel, this.Search], true),
						Disabled: Disabled
					},
					{
						Code: "Update",
						Disabled: Disabled || selected,
						OnClick: () => ExecuteApiPost("/bankapi/v1.0/BankBmoEFT/SettlementInstructionInsertOrUpdate", model, null, null, null, [this.ClearModel, this.Search], true)
					},
					{
						Code: "Delete",
						Disabled: Disabled || selected,
						OnClick: () => showConfirmMessageInDelete(() => ExecuteApiPost("/bankapi/v1.0/BankBmoEFT/SettlementInstructionDelete", { Id: model.Id }, null, null, null, [this.ClearModel, this.Search], true))
					},
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Filter Panel">
									<GridContainer>
										<GridItem xs={4}>
											<GridItem>
												<GenericSelectInput
													Name="UniqueClientId"
													LabelText="Client"
													Method="POST"
													Url="/bankapi/v1.0/BankCustomer/Search"
													Parameter={{}}
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
													Value={model.UniqueClientId || ""}
													ValueChanged={this.handleChange}
													CanClear
													All
													Required
													IsInvalid={vModel.UniqueClientId}
												/>
											</GridItem>
											<GridItem>
												<GenericSelectInput
													key={model.UniqueClientId}
													Name="ClientCustomerId"
													LabelText="External Bank Client Name"
													Method="POST"
													Url={model.UniqueClientId ? "/bankapi/v1.0/ClientCustomer/SearchNameValue" : ""}
													Parameter={{ UniqueClientId: model.UniqueClientId }}
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={this.RenderItemClientCustomer}
													Value={model.ClientCustomerId}
													ValueChanged={this.handleChange}
													Required
													IsInvalid={vModel.ClientCustomerId}
												/>
											</GridItem>
											<GridItem>
												<GenericSelectInput
													key={model.ClientCustomerId}
													Name="BeneficiaryId"
													LabelText="Client External Payment Wallet"
													Method="GET"
													Url={model.UniqueClientId ? "/bankapi/v1.0/Beneficiary/GetByCustomerId/Id?Id=" + model.ClientCustomerId : ""}
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={d => `${d.FinancialInstitution.Description} - ${d.AccountNumber}`}
													Value={model.BeneficiaryId}
													ValueChanged={this.handleChange}
													Required
													IsInvalid={vModel.BeneficiaryId}
												/>
											</GridItem>
										</GridItem>
										<GridItem xs={4}>
											<GridItem>
												<GenericSelectInput
													key={model.UniqueClientId}
													Name="FinancialAccountId"
													LabelText="Financial Wallet"
													Method="POST"
													Url={model.UniqueClientId ? "/bankapi/v1.0/Account/GetAllAccountByProgram" : ""}
													DataRoot="Item"
													KeyValueMember="Id"
													TextValueMember="AccountName"
													Value={model.FinancialAccountId}
													ValueChanged={this.handleChange}
													Parameter={{ ProgramId: 11, UniqueClientId: model.UniqueClientId }}
													Required
													IsInvalid={vModel.FinancialAccountId}
												/>
											</GridItem>
											<GridItem>
												<ParameterComponent
													Name="EftTransactionTypeId"
													LabelText="Transaction Code"
													ParameterCode="EFTTransactionType"
													ParameterValue4={"Normal"}
													RenderItem={this.RenderTransactionCode}
													Value={model.EftTransactionTypeId}
													ValueChanged={this.handleChange}
													Disabled={Disabled}
													Sorted={this.sortParamValue}
													Required
													IsInvalid={vModel.EftTransactionTypeId}
												/>
											</GridItem>
											<GridItem>
												<ParameterComponent
													Name="SettlementTransactionTypeId"
													LabelText="Transaction Type"
													ParameterCode="TransactionType"
													ParameterValue4={"1"}
													RenderItem={this.RenderTransactionCode}
													Value={model.SettlementTransactionTypeId}
													ValueChanged={this.handleChange}
													Disabled={Disabled}
													Sorted={this.sortParamValue}
													Required
													IsInvalid={vModel.SettlementTransactionTypeId}
												/>
											</GridItem>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardHeader>
								<GridContainer spacing={16} justify="space-between" alignItems="center">
									<GridItem>
										<CardHeader>
											<h4><b>Transaction List</b></h4>
										</CardHeader>
									</GridItem>
								</GridContainer>
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer>
											<GridItem xs={4}>
											</GridItem>
											<GridItem xs={4} />
											<GridItem xs={4}>
												<GridContainer justify="flex-end">
													<GridItem xs={3}>
													</GridItem>
												</GridContainer>
											</GridItem>
										</GridContainer>
										<GridContainer spacing={16}>
											<GridItem xs={12}>
												<GenericGrid
													Data={list}
													Columns={this.ColumnsdailyFileList}
													RowSelected={this.SelectedRowChange}
													IsSorted={false} />
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>

							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

ExternalBankSettlementInstruction.propTypes = {
	classes: PropTypes.object
};

export default ExternalBankSettlementInstruction;
