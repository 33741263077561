import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import Formatter from "core/Formatter";
import RouteHelper from "core/RouteHelper";
import { TaskPool } from "core/TaskPool";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import {
	GenericAlert,
	GenericCheckInput,
	GenericDialog,
	GenericDialogActions,
	GenericExpansionPanel,
	GenericLabel,
	GenericNumberInput,
	GenericSelectInput,
	GenericTextInput
} from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { IdentificationMethod, LOSApplicationStatus, MenuCodes } from "views/Constants/Constant";
import ApplicantDefinition from "views/LOS/ApplicantDefinition/ApplicantDefinition.jsx";
import LoanApplicantInformation from "views/LOS/CreateApplication/LoanApplicantInformation.jsx";
import LoanApplicationBorrowerReview from "views/LOS/CreateApplication/LoanApplicationBorrowerReview.jsx";
import LoanApplicationCreditScore from "views/LOS/CreateApplication/LoanApplicationCreditScore.jsx";
import LoanApplicationNetworth from "views/LOS/CreateApplication/LoanApplicationNetworth.jsx";
import LoanApplicationScoringInfo from "views/LOS/CreateApplication/LoanApplicationScoringInfo.jsx";
import LoanApplicationSecurityInformation from "views/LOS/CreateApplication/LoanApplicationSecurityInformation.jsx";
const LosQuestionnaireQuestionType = {
	Select: 0,
	Text: 1,
	Number: 2
};
const ConstLoanType = {
	SecureCard: 0,
	Loan: 1,
	LOC: 2
};
function GetAppliedForRequestedLengthOfTerm() {
	var termDataList = [];
	termDataList.push({ Value: 1, Text: "1 Month" });
	for (var i = 2; i < 61; i++) {
		termDataList.push({
			Value: i,
			Text: i + " Months"
		});
	}
	return termDataList;
}
const initialState = {
	panelActivationStatus: {
		panelFilterActive: true,
		panelAppliedForActive: false,
		panelApplicantInformationActive: false,
		panelAdditionalInformationActive: false,
		panelAdditionalInformationOptionalActive: false,
		panelSecurityInformationActive: false,
		panelScoreActive: false,
		panelNetworthActive: false,
		panelBorrowerReviewActive: false,
		panelQuestionnaireActive: false
	},
	model: {
		Id: 0,
		OtherSecurityInfoEnable: false,
		ClientPartnerId: null,
		isGenerateNew: null,
		LoanTypeId: null,
		LoanPurposeId: null,
		LoanPurposeDescription: null
	},
	vModel: {},
	applicantModel: {},
	fileModel: {},
	clientPartnerModel: {
		Id: null
	},
	paramApplicationProfile: {},
	paramApplicationAddressList: [],
	isPeriodDisable: false,
	isLoading: false,
	ReadOnly: false,
	isCreateApplicantDialogOpen: false,
	applicantDefinitionMenuId: 0,
	IsActiveLoanPurposeDescription: false,
	IsAppliedForCardReadOnly: true,
	EnableApplicantUpdate: true,
	RequestedLengthOfTermSource: GetAppliedForRequestedLengthOfTerm(),
	feeProfileList: []
};
class CreateApplication extends Component {

	constructor(props) {
		super(props);
		this.GetApplicationAddressModels = this
			.GetApplicationAddressModels
			.bind(this);
		this.HandleChangeFilterPanel = this
			.HandleChangeFilterPanel
			.bind(this);
		this.InserUpdatePanelActivationStatus = this
			.InserUpdatePanelActivationStatus
			.bind(this);
		this.PanelFilterActiveNextClicked = this
			.PanelFilterActiveNextClicked
			.bind(this);
		this.PanelAppliedForActiveNextClicked = this
			.PanelAppliedForActiveNextClicked
			.bind(this);
		this.PanelApplicantInformationActiveNextClicked = this
			.PanelApplicantInformationActiveNextClicked
			.bind(this);
		this.panelAdditionalInformationActiveNextClicked = this
			.panelAdditionalInformationActiveNextClicked
			.bind(this);
		this.panelAdditionalInformationOptionalActiveNextClicked = this
			.panelAdditionalInformationOptionalActiveNextClicked
			.bind(this);
		this.panelSecurityInformationActiveNextClicked = this
			.panelSecurityInformationActiveNextClicked
			.bind(this);
		this.panelScoreActiveNextClicked = this
			.panelScoreActiveNextClicked
			.bind(this);
		this.panelNetworthActiveNextClicked = this
			.panelNetworthActiveNextClicked
			.bind(this);
		this.panelBorrowerReviewActiveNextClicked = this
			.panelBorrowerReviewActiveNextClicked
			.bind(this);
		this.panelQuestionnaireActiveNextClicked = this
			.panelQuestionnaireActiveNextClicked
			.bind(this);
		this.loadTaskPool = new TaskPool(this.handleLoadTaskPoolAppend, this.handleLoadTaskPoolCompleted);
		this.SavePanelAfterInsert = this
			.SavePanelAfterInsert
			.bind(this);
		this.GetPanelActivationStatus = this
			.GetPanelActivationStatus
			.bind(this);
		this.AfterApplicantSearchCallBack = this
			.AfterApplicantSearchCallBack
			.bind(this);
		this.PrepareQuestionnaireGridList = this
			.PrepareQuestionnaireGridList
			.bind(this);
		this.HandleQuestionnaireValueChange = this
			.HandleQuestionnaireValueChange
			.bind(this);
		this.state = initialState;

		this.maxLength20 = {
			maxLength: 20
		};
		this.marginLeft2 = {
			marginLeft: 2
		};
		this.emptyObject = {};

		this.renderItemApplication = {};

		this.LoanTypeParameterCode = {
			ParameterCode: "LOSLoanType"
		};
		this.LoanPurposeParameterCode = {
			ParameterCode: "LOSLoanPurpose"
		};

		this.parameterLOSPaymentFrequency = {
			ParameterCode: "LOSPaymentSchedule"
		};
		this.IdentityVerifyingPersonParameterCode = {
			ParameterCode: "IdentityVerifyingPerson"
		};
		
		this.parameterIdentificationMethod = {
			ParameterCode: "IdentificationMethod",
			ParameterValue2: "Active"
		}; 

		this.sortedParameterDesc = {
			Member: "ParameterDesc"
		};
		this.sortedApplicantFullName = {
			Member: "FullName"
		};

		this.retiredOrUnemployedOccupationIds = [];
		this.identificationMethodIds = [];
		this.specificIdentityTypesIds = [];

	}

	componentDidMount() {
		const { setAppCenterTitle, setAppLeftTitle, MenuCode } = this.props;

		if (setAppCenterTitle) {
			setAppCenterTitle(MenuCode != MenuCodes.Lms_LoanDetail
				? "LOAN ORIGINATOR SYSTEM"
				: "LOAN MANAGEMENT SYSTEM");
		}
		if (setAppLeftTitle) {
			setAppLeftTitle(MenuCode != MenuCodes.Lms_LoanDetail
				? "Complete Application"
				: "Application Details");
		}

		this.GetRetiredOrUnemployedOccupationIds();
		this.GetSpecificIdentityTypesIds();
		this.GetIdentificationMethodIds();

		var applicationData = RouteHelper.ReadAndClear("ApplicationData");
		if (applicationData && applicationData.ApplicationId && applicationData.ApplicationId > 0) {

			this.HandleGetApplication(applicationData.ApplicationId);
			const modelUpdate = this.state.model;
			if (applicationData.ApplicantId && applicationData.ApplicantId > 0) {
				modelUpdate.ApplicantId = applicationData.ApplicantId;
				this.GetApplicationList(applicationData.ApplicantId);
				this.GetCreditHistory(applicationData.ApplicantId);
				this.HandleGetApplicant(applicationData.ApplicantId);
			}
			this.setState({ model: modelUpdate, EnableApplicantUpdate: applicationData.EnableApplicantUpdate });
			return;
		}
		var applicantIdAndAddNewApplication = RouteHelper.ReadAndClear("ApplicantIdAndAddNewApplication");
		if (applicantIdAndAddNewApplication) {
			var { model } = this.state;
			model.ApplicantId = applicantIdAndAddNewApplication.ApplicantId;
			model.isGenerateNew = applicantIdAndAddNewApplication.isGenerateNew;

			this.HandleChange("isGenerateNew", applicantIdAndAddNewApplication.isGenerateNew);
			this.HandleChange("ApplicantId", applicantIdAndAddNewApplication.ApplicantId);			
		}
	}

	GetSpecificIdentityTypesIds() {
		Proxy.POST(
			"/coreapi/v1.0/Parameter/Search",
			{
				ParameterCode: "IdentityType2",
				ParameterValue: "ID010"
			},
			(responseData) => {
				if (!responseData.IsSucceeded) {
					console.log("Parameters were not found.");
					return;
				}
				let list = responseData.Item;
												
				list.forEach(element => {
					this.specificIdentityTypesIds.push(element.Id);
				});	
			}
		);
	}

	GetIdentificationMethodIds() {
		Proxy.POST(
			"/coreapi/v1.0/Parameter/Search",
			this.parameterIdentificationMethod,		
			(responseData) => {
				if (!responseData.IsSucceeded) {
					console.log("Parameters were not found.");
					return;
				}
				let list = responseData.Item;
								
				for(var i=0; i<list.length; i++) {
					let method = {
						"Id" :	list[i].Id,
						"Value" : list[i].ParameterValue
					};
					this.identificationMethodIds.push(method);	
					
				}			
			}
		);
	}

	GetRetiredOrUnemployedOccupationIds() {
		Proxy.POST(
			"/coreapi/v1.0/Parameter/Search",
			{
				ParameterCode: "Occupation"
			},
			(responseData) => {
				if (!responseData.IsSucceeded) {
					console.log("Parameters were not found.");
					return;
				}
				let list = responseData.Item;
				list = list.filter(x=>x.ParameterDesc.includes("Retired")
												||x.ParameterDesc.includes("Unemploy"));
				
				list.forEach(element => {
					this.retiredOrUnemployedOccupationIds.push(element.Id);
				});	
			}
		);
	}

	GetApplicationAddressModels() {
		this
			.loadTaskPool
			.AppendTask(Proxy.POST("/losapi/v1.0/ApplicationApplicantAddress/GetByApplicationId", {
				ApplicationId: this.state.model.Id
			}, responseData => {
				console.log("GetApplicationAddressModels ->", responseData.IsSucceeded);

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					this.setState({
						paramApplicationAddressList: responseData.Item || []
					});
				} else {
					this.setState({ paramApplicationAddressList: [] });
				}
			}, error => {
				this.ShowMessage("error", "Error", error);
			}));
	}
	handleLoadTaskPoolCompleted = () => {
		this.setState({ isLoading: false });
	}
	handleLoadTaskPoolAppend = () => {
		this.setState({ isLoading: true });
	}
	HandleChange = (name, newValue, data) => {
		const model = {
			...this.state.model
		};
		const clientpartner = {
			...this.state.clientPartnerModel
		};
		model[name] = newValue;

		if (name == "Id" && newValue != null && newValue > 0) {
			this.HandleGetApplication(newValue);
		}
		if (name == "ApplicantId") {
			this.HandleClickClearOk();
		}

		if (name == "SecurityInfoTypeId") {
			if (data != undefined && data.ParameterValue == "3")
				model.OtherSecurityInfoEnable = true;
			else
				model.OtherSecurityInfoEnable = false;
		}
		if (name == "ApplicantId") {
			if (newValue != null && newValue > 0) {
				this.setState({
					model
				}, () => {
					this.HandleGetApplicant(newValue);
					this.GetCreditHistory(newValue);
				});
				return;
			} else {
				model["Id"] = null;
			}
		}
		if (name == "ClientPartnerId" && data == null) {
			clientpartner.Id = null;
			this.setState({ clientPartnerModel: clientpartner });
		}
		if (name == "ClientPartnerId" && data != null) {
			model.ClientPartnerId = data.Id;
			this.setState({ clientPartnerModel: data });
		}
		if (name == "isCreateNewApplicant") {
			this.ShowConfirmMessage("warning", "Warning", "This will erase the information you entered on the screen. Are you sure you want" +
				" to quit this application?",
				() => this.HandleCreateNewApplicant());
			return;
		}
		if (name == "isGenerateNew") {
			if (newValue == true) {
				this.ShowConfirmMessage("warning", "Warning", "This will erase the information you entered on the screen. Are you sure you want" +
					" to quit this application?",
					() => this.HandleCreateNew());
				return;
			} else {
				model.isGenerateNew = false;
				this.setState({
					model
				}, () => {
					this.PrepareQuestionnaireGridList();
				});
			}

		}
		if (name == "LoanPurposeId") {
			this.state.IsActiveLoanPurposeDescription = (data != null && data.ParameterDesc === "Other");
			if (model.Id > 0) {
				// this.ShowConfirmMessage("warning", "Clear Questionnaire", "This will erase
				// the information you entered on the questionnaire section. Are you sure you
				// want to erase the information?", 	() =>
				// this.HandleRefreshApplicationQuestionnaire()); return; remove defined
				// application questionnaire value get new purpose questionnaire list
			} else {
				this
					.loadTaskPool
					.AppendTask(Proxy.POST("/losapi/v1.0/Questionnaire/SearchApplicationQuestionnaire", {
						Application: {
							LoanTypeId: model.LoanTypeId,
							LoanPurposeId: model.LoanPurposeId
						}
					}, responseData => {
						if (!responseData.IsSucceeded) {
							this.ShowMessage("error", "Error", responseData.ErrorDescription);
							return;
						}
						var ApplicationQuestionnaireList = responseData.Item || [];
						this.setState(ApplicationQuestionnaireList);
					}, error => {
						this.ShowMessage("error", "Error", error);
						return;
					}));
			}
		}
		if (!this.state.IsActiveLoanPurposeDescription && (model.LoanPurposeDescription == null || model.LoanPurposeDescription === "")) {
			model["LoanPurposeDescription"] = "";
		}
		if (name == "LoanTypeId") {
			this.state.IsActiveLoanPurposeDescription = (data != null && data.ParameterDesc === "Other");
		}

		this.setState({ model });
	}

	HandleCreateNew() {
		this.HideAlert();
		var newState = initialState;
		newState.model.isGenerateNew = true;

		this.setState(newState, () => {
			this.GetApplicationQuestionnaire();
		});
	}
	HandleCreateNewApplicant() {
		this.HideAlert();
		let newState = Object.assign({}, initialState);
		var userJson = localStorage.getItem("user");
		var user = userJson
			? JSON.parse(userJson)
			: null;
		var menuId = 0;
		this
			.loadTaskPool
			.AppendTask(Proxy.POST("/coreapi/v1.0/MenuRight/GetMenuRightList", {
				RoleId: user != null
					? user.RoleId
					: 0
			}, responseData => {
				if (!responseData.IsSucceeded) {
					// this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item) {
					if (responseData.Item.filter(i => i.Menu.Code == "LOSAPL")[0] != undefined)
						menuId = responseData.Item.filter(i => i.Menu.Code == "LOSAPL")[0].MenuId;

					newState.isCreateApplicantDialogOpen = true;
					newState.applicantDefinitionMenuId = menuId;
					this.setState(newState);
				}
			}, error => {
				// this.ShowMessage("error", "Error", error);
			}));
	}
	HandleGetApplicant = (id) => {
		this
			.loadTaskPool
			.AppendTask(Proxy.POST("/losapi/v1.0/Applicant/Search", {
				Id: id
			}, responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item && responseData.Item.length > 0) {
					var { applicantModel, model } = this.state;
					var appliedForModel = {};

					Object.assign(applicantModel, responseData.Item[0]);
					Object.assign(appliedForModel, responseData.Item[0].ApplicantAppliedFor);

					applicantModel.BirthDate = model.BirthDate === null
						? null
						: Formatter.FormatDateUtc(applicantModel.BirthDate);

					var ApplicationQuestionnaireList = responseData.Item[0].ApplicationQuestionnaireList || [];
					model.LoanTypeId = appliedForModel.LoanTypeId;
					model.LoanType = appliedForModel.LoanType;
					model.RequestedPaymentPeriod = appliedForModel.RequestedPaymentPeriod;
					model.RequestedPaymentFrequencyId = appliedForModel.RequestedPaymentFrequencyId;
					model.RequestedAmount = appliedForModel.RequestedAmount;
					model.LoanPurposeId = appliedForModel.LoanPurposeId;
					model.LoanPurpose = appliedForModel.LoanPurpose;
					model.LoanPurposeDescription = appliedForModel.LoanPurposeDescription;
					model.SecurityAmount = appliedForModel.SecurityAmount;
					this.state.IsActiveLoanPurposeDescription = false;
					if (appliedForModel.LoanPurpose != null && appliedForModel.LoanPurpose.ParameterDesc === "Other") {
						this.state.IsActiveLoanPurposeDescription = true;
					}
					if (model.LoanType != null && model.LoanType.ParameterValue != ConstLoanType.Loan) {
						model.RequestedPaymentPeriod = 1;
						this.state.isPeriodDisable = true;

					}
					this.setState({
						applicantModel,
						model,
						ApplicationQuestionnaireList
					}, () => this.AfterApplicantSearchCallBack(responseData.Item[0].Id));

				}
			}, error => {
				this.ShowMessage("error", "Error", error);
			}));
	}
	AfterApplicantSearchCallBack(applicantId) {
		this.GetApplicationList(applicantId);
		this.PrepareQuestionnaireGridList();
	}
	GetCreditHistory = (applicantId) => {
		const { showMessage } = this.props;

		this
			.loadTaskPool
			.AppendTask(Proxy.POST("/lmsapi/v1.0/LoanCreditHistory/GetByApplicantId", {
				ApplicantId: applicantId
			}, (responseData) => {
				if (!responseData.IsSucceeded) {
					showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				var { applicantModel } = this.state;

				if (responseData.Item != null) {
					if (responseData.Item.CreditHistory) {
						Object.assign(applicantModel, responseData.Item.CreditHistory);
					}
					if (responseData.Item.CreditHistoryDetails) {
						applicantModel.CreditHistoryDetails = responseData.Item.CreditHistoryDetails;
					}
				}

				this.setState({ applicantModel });
			}, (error) => {
				showMessage("error", "An error occurred while requesting data", error.message);
			}));
	}
	GetApplicationList(applicantId) {
		this
			.loadTaskPool
			.AppendTask(Proxy.POST("/losapi/v1.0/Application/Search", {
				ApplicantId: applicantId
			}, responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({
					modelList: responseData.Item || []
				});
			}, error => {
				this.ShowMessage("error", "Error", error);
			}));
	}
	HandleGetApplication = (id) => {
		let { model, clientPartnerModel } = this.state;
		this
			.loadTaskPool
			.AppendTask(Proxy.POST("/losapi/v1.0/Application/SearchByIdForCompleteApplication", {
				Id: id
			}, responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					model = responseData.Item.Application;
					if (model.SecurityInfoType != null && model.SecurityInfoType.ParameterValue == "3")
						model.OtherSecurityInfoEnable = true;
					if (model.ClientPartner != null) {
						clientPartnerModel = model.ClientPartner;
					}
					if (model.LoanType != null && model.LoanType.ParameterValue != ConstLoanType.Loan) {
						model.RequestedPaymentPeriod = 1;
						this.state.isPeriodDisable = true;
					}
					this.state.IsActiveLoanPurposeDescription = false;
					if (model.LoanPurpose != null && model.LoanPurpose.ParameterDesc === "Other") {
						this.state.IsActiveLoanPurposeDescription = true;
					}

					if (responseData.Item.Application.ApplicationBorrowerReview == null) {
						responseData.Item.Application.ApplicationBorrowerReview = {};
					}

					model.ReviewPeriodId = responseData.Item.Application.ApplicationBorrowerReview.ReviewPeriodId;
					model.ReviewTypeId = responseData.Item.Application.ApplicationBorrowerReview.ReviewTypeId;
					model.ReviewAlertTimeId = responseData.Item.Application.ApplicationBorrowerReview.ReviewAlertTimeId;
					model.ReviewAlertNotificationId = responseData.Item.Application.ApplicationBorrowerReview.ReviewAlertNotificationId;
					model.ReviewResultNotificationId = responseData.Item.Application.ApplicationBorrowerReview.ReviewResultNotificationId;
					model.PreviousReviewDate = responseData.Item.Application.ApplicationBorrowerReview.PreviousReviewDate;

					var ApplicationQuestionnaireList = responseData.Item.ApplicationQuestionnaireList || [];
					var appFeeProfileList = responseData.Item.ApplicationLoanFeeDefinitionList || [];

					this.setState({
						model,
						clientPartnerModel,
						paramApplicationAddressList: responseData.Item.ApplicationAddressList,
						ApplicationQuestionnaireList,
						feeProfileList: appFeeProfileList
					}, () => {
						this.PrepareQuestionnaireGridList();
					});

					this.GetPanelActivationStatus(model.Id);
				}
			}, this.ErrorCallback));
	}
	GetPanelActivationStatus(id) {

		this
			.loadTaskPool
			.AppendTask(Proxy.POST("/losapi/v1.0/LOSPanelActivationStatus/GetByParameter", {
				DataId: id,
				ScreenCode: MenuCodes.LOS_CreateApplication
			}, (responseData) => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null)
					this.setState({
						panelActivationStatus: JSON.parse(responseData.Item.PanelInfo)
					});
			}
				, (error) => {
					this.ShowMessage("error", "An error occurred while requesting data", error.message);
				}));
	}
	HandleClickClearOk = () => {
		this.HideAlert();
		this.setState({
			panelActivationStatus: initialState.panelActivationStatus,
			model: {
				OtherSecurityInfoEnable: false
			},
			applicantModel: {},
			fileModel: {},
			clientPartnerModel: {},
			paramApplicationProfile: {},
			paramApplicationAddressList: [],
			isLoading: false,
			ReadOnly: false,
			isCreateApplicantDialogOpen: false,
			applicantDefinitionMenuId: 0,
			feeProfileList: []
		});
	}

	HandleClear = () => {
		this.ShowConfirmMessage("warning", "Warning", "This will erase the information you entered on the screen. Are you sure you want" +
			" to quit this application?",
			() => this.HandleClickClearOk());
	}
	HandleSearch = () => { }
	ErrorCallback = (error) => {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", "An error occurred during the api visit" + error);
	}
	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert
				Title={title}
				Message={message}
				Type={type}
				OnConfirm={() => this.setState({ alert: "" })} />
		});
	}
	showBasicAlert = (title, message, type) => {
		this.setState({
			isLoading: false, alert: <GenericAlert
				Title={title}
				Message={message}
				Type={type}
				ShowCancel={false}
				OnConfirm={() => this.HideAlert()} />
		});
	}
	ApplicantDefinitionDiologOpenOrClose = () => {
		if (this.state.isCreateApplicantDialogOpen == false) {
			this.setState({ isCreateApplicantDialogOpen: true });
		} else {
			this.setState({ isCreateApplicantDialogOpen: false });
		}
	}
	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert
				Title={title}
				MessageNode={message}
				Type={type}
				OnConfirm={() => this.setState({ alert: "" })} />
		});
	}
	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert
				Title={title}
				Message={message}
				Type={type}
				ShowCancel={true}
				OnCancel={() => this.setState({ alert: null })}
				OnConfirm={onConfirm} />
		});
	}
	HideAlert = () => {
		this.setState({ alert: null });
	}

	OperationCheck = () => {

		var ErrorList = [];
		const { vModel } = this.state;
		const data = this.state.model;
		if (data.ApplicantId == null || data.ApplicantId == "") {
			ErrorList.push("Applicant must be defined.");
			vModel.ApplicantId = true;
		} else {
			vModel.ApplicantId = false;
		}
		if (data.LoanTypeId == null || data.LoanTypeId == "") {
			ErrorList.push("Loan type must be defined.");
			vModel.LoanTypeId = true;
		} else {
			vModel.LoanTypeId = false;
		}
		if (data.LoanPurposeId == null || data.LoanPurposeId == "") {
			ErrorList.push("Loan purpose must be defined.");
			vModel.LoanPurposeId = true;
		} else {
			vModel.LoanPurposeId = false;
		}
		if (data.RequestedPaymentPeriod == null || data.RequestedPaymentPeriod == "") {
			ErrorList.push("Period must be defined.");
			vModel.RequestedPaymentPeriod = true;
		} else {
			vModel.RequestedPaymentPeriod = false;
		}
		if (data.RequestedAmount == null || data.RequestedAmount == "") {
			ErrorList.push("Requested amount must be defined.");
			vModel.RequestedAmount = true;
		} else {
			vModel.RequestedAmount = false;
		}
		if (this.state.applicantModel == null || this.state.applicantModel.IdVerification == null || this.state.applicantModel.IdVerification == "" || this.state.applicantModel.IdVerification != "Valid") {
			ErrorList.push("Id must be valid.");
			vModel.IdVerification = true;
		} else {
			vModel.IdVerification = false;
		}
		this.setState({ vModel });
		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please Fill Required Fields", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		this.UpdateModel(LOSApplicationStatus.Open);
	}

	ShowMessageAndRefresh = (type, title, message) => {
		this.setState({
			alert: <GenericAlert
				Title={title}
				MessageNode={message}
				Type={type}
				OnConfirm={() => this.hideAlertAndRefresh()} />
		});
	}
	hideAlertAndRefresh = () => {
		this.setState({ alert: null, isLoading: false });
		window
			.location
			.reload();
	}

	UpdateModel = (status, callback) => {
		const model = this.state.model;
		let IsAppliedForCardReadOnly = this.state.IsAppliedForCardReadOnly;

		if (model.Status == null || model.Status.ParameterValue == LOSApplicationStatus.Saved) {
			model.Status = {
				ParameterValue: status
			};
		}

		var Application = model;
		Application.ApplicationBorrowerReview = {
			ReviewPeriodId: model.ReviewPeriodId,
			ReviewTypeId: model.ReviewTypeId,
			ReviewAlertTimeId: model.ReviewAlertTimeId,
			ReviewAlertNotificationId: model.ReviewAlertNotificationId,
			ReviewResultNotificationId: model.ReviewResultNotificationId
		};

		Application.Id = Application.Id || 0;

		this
			.loadTaskPool
			.AppendTask(Proxy.POST("/losapi/v1.0/Application/InsertUpdate", {
				Application,
				ApplicationQuestionnaireList: this.state.ApplicationQuestionnaireList
			}, responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ model: responseData.Item.Application });

				if (model.Id > 0) {
					this.ShowMessage("success", "Success", "Application Updated");

				} else {
					this.ShowMessage("success", "Success", "Application Saved");
				}
				model.Id = responseData.Item.Id;
				if (callback)
					callback(model.Id);

				this.HandleGetApplication(responseData.Item
					?.Application
					?.Id);
			}, error => {
				this.ShowMessage("error", "Error", error);
			}));
		if (!IsAppliedForCardReadOnly)
			this.setState({ IsAppliedForCardReadOnly: true });
	}

	RenderItemApplication = (d) => {
		this.renderItemApplication = `${d.ApplicationNumber} - ${d.LoanType.ParameterDesc}`;
		return this.renderItemApplication;
	}
	HandleChangeFilterPanel(name, expanded) {
		var currentPanelActivation = Object.assign({}, initialState.panelActivationStatus);
		currentPanelActivation.panelFilterActive = false;
		currentPanelActivation[name] = expanded
			? true
			: false;

		this.setState({ panelActivationStatus: currentPanelActivation });

		this.InserUpdatePanelActivationStatus(currentPanelActivation);
	}
	InserUpdatePanelActivationStatus(PanelInfo, id) {
		if ((this.state.model == null || this.state.model.Id == null || this.state.model.Id == 0) && id == undefined)
			return;
		this
			.loadTaskPool
			.AppendTask(Proxy.POST("/losapi/v1.0/LOSPanelActivationStatus/InsertUpdate", {
				DataId: id == undefined
					? this.state.model.Id
					: id,
				ScreenCode: MenuCodes.LOS_CreateApplication,
				PanelInfo: JSON.stringify(PanelInfo)
			}, (responseData) => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
			}, (error) => {
				this.ShowMessage("error", "An error occurred while requesting data", error.message);
			}));
	}
	PanelFilterActiveNextClicked() {
		var ErrorList = [];
		const data = this.state.model;

		const { vModel } = this.state;
		if (data.ApplicantId == null || data.ApplicantId == "") {
			ErrorList.push("Applicant must be defined.");
			vModel.ApplicantId = true;
		} else {
			vModel.ApplicantId = false;
		}
		if (this.state.applicantModel == null || this.state.applicantModel.IdVerification == null || this.state.applicantModel.IdVerification == "" || this.state.applicantModel.IdVerification != "Valid") {
			ErrorList.push("Id must be valid.");
			vModel.IdVerification = true;
		} else {
			vModel.IdVerification = false;
		}
		this.setState({ vModel });
		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please Fill Required Fields", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.UpdateModel(LOSApplicationStatus.Saved, this.SavePanelAfterInsert);

	}
	SavePanelAfterInsert(id) {
		var currentPanelActivation = Object.assign({}, initialState.panelActivationStatus);
		currentPanelActivation.panelFilterActive = false;
		currentPanelActivation.panelAppliedForActive = true;
		this.setState({ panelActivationStatus: currentPanelActivation });
		this.InserUpdatePanelActivationStatus(currentPanelActivation, id);
	}
	HandleAppliedForUpdateAction = () => {
		if (this.state == null)
			return;
		const data = this.state;
		if (data != null && data.IsAppliedForCardReadOnly != null) {
			data.IsAppliedForCardReadOnly
				? this.setState({ IsAppliedForCardReadOnly: false })
				: this.OperationCheck();
		}
	}
	PanelAppliedForActiveNextClicked() {
		var ErrorList = [];
		const data = this.state.model;
		if (data.LoanTypeId == null || data.LoanTypeId == "") {
			ErrorList.push("Loan type must be defined.");
		}
		if (data.LoanPurposeId == null || data.LoanPurposeId == "") {
			ErrorList.push("Loan purpose must be defined.");
		}
		if (data.RequestedPaymentPeriod == null || data.RequestedPaymentPeriod == "") {
			ErrorList.push("Period must be defined.");
		}
		if (data.RequestedAmount == null || data.RequestedAmount == "") {
			ErrorList.push("Requested amount must be defined.");
		}

		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please Fill Required Fields", ErrorList.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}

		this.UpdateModel(LOSApplicationStatus.Saved);
		var currentPanelActivation = Object.assign({}, initialState.panelActivationStatus);
		currentPanelActivation.panelFilterActive = false;
		currentPanelActivation.panelApplicantInformationActive = true;
		this.setState({ panelActivationStatus: currentPanelActivation });
		this.InserUpdatePanelActivationStatus(currentPanelActivation);

	}
	PanelApplicantInformationActiveNextClicked() {
		const model = this.state.model;
		this.UpdateModel(LOSApplicationStatus.Saved);

		var currentPanelActivation = Object.assign({}, initialState.panelActivationStatus);
		currentPanelActivation.panelFilterActive = false;
		if (model.LoanType != null && model.LoanType.ParameterValue != ConstLoanType.SecureCard) {
			currentPanelActivation.panelAdditionalInformationActive = true;
		} else {
			currentPanelActivation.panelAdditionalInformationOptionalActive = true;
		}

		this.setState({ panelActivationStatus: currentPanelActivation });
		this.InserUpdatePanelActivationStatus(currentPanelActivation);

	}
	panelAdditionalInformationActiveNextClicked() {

		this.UpdateModel(LOSApplicationStatus.Saved);
		var currentPanelActivation = Object.assign({}, initialState.panelActivationStatus);
		currentPanelActivation.panelFilterActive = false;
		currentPanelActivation.panelAdditionalInformationOptionalActive = true;

		this.setState({ panelActivationStatus: currentPanelActivation });
		this.InserUpdatePanelActivationStatus(currentPanelActivation);

	}
	panelAdditionalInformationOptionalActiveNextClicked() {
		const model = this.state.model;
		this.UpdateModel(LOSApplicationStatus.Saved);

		var currentPanelActivation = Object.assign({}, initialState.panelActivationStatus);
		currentPanelActivation.panelFilterActive = false;
		if (model.LoanType != null && model.LoanType.ParameterValue != ConstLoanType.SecureCard) {
			currentPanelActivation.panelSecurityInformationActive = true;
		} else {
			currentPanelActivation.panelBorrowerReviewActive = true;
		}

		this.setState({ panelActivationStatus: currentPanelActivation });
		this.InserUpdatePanelActivationStatus(currentPanelActivation);

	}
	panelSecurityInformationActiveNextClicked() {
		this.UpdateModel(LOSApplicationStatus.Saved);

		var currentPanelActivation = Object.assign({}, initialState.panelActivationStatus);
		currentPanelActivation.panelFilterActive = false;
		currentPanelActivation.panelScoreActive = true;

		this.setState({ panelActivationStatus: currentPanelActivation });
		this.InserUpdatePanelActivationStatus(currentPanelActivation);

	}
	panelScoreActiveNextClicked() {
		this.UpdateModel(LOSApplicationStatus.Saved);

		var currentPanelActivation = Object.assign({}, initialState.panelActivationStatus);
		currentPanelActivation.panelFilterActive = false;
		currentPanelActivation.panelNetworthActive = true;

		this.setState({ panelActivationStatus: currentPanelActivation });
		this.InserUpdatePanelActivationStatus(currentPanelActivation);

	}
	panelNetworthActiveNextClicked() {
		this.UpdateModel(LOSApplicationStatus.Saved);

		var currentPanelActivation = Object.assign({}, initialState.panelActivationStatus);
		currentPanelActivation.panelFilterActive = false;
		currentPanelActivation.panelBorrowerReviewActive = true;

		this.setState({ panelActivationStatus: currentPanelActivation });
		this.InserUpdatePanelActivationStatus(currentPanelActivation);

	}
	panelQuestionnaireActiveNextClicked() {
		this.UpdateModel(LOSApplicationStatus.Saved);
		var currentPanelActivation = Object.assign({}, initialState.panelActivationStatus);
		currentPanelActivation.panelFilterActive = false;
		currentPanelActivation.panelBorrowerReviewActive = true;
		this.setState({ panelActivationStatus: currentPanelActivation });
		this.InserUpdatePanelActivationStatus(currentPanelActivation);

	}
	panelBorrowerReviewActiveNextClicked() {
		this.UpdateModel(LOSApplicationStatus.Open);
		var currentPanelActivation = Object.assign({}, initialState.panelActivationStatus);
		currentPanelActivation.panelFilterActive = false;
		this.setState({ panelActivationStatus: currentPanelActivation });
		this.InserUpdatePanelActivationStatus(currentPanelActivation);

	}

	HandleQuestionnaireValueChange(name, value) {

		const { ApplicationQuestionnaireList } = this.state;
		var changedItem = ApplicationQuestionnaireList.filter(i => i.QuestionnaireId == name);
		if (changedItem != undefined) {
			changedItem = changedItem[0];
			changedItem.GivenValue = value;
			this.setState({
				ApplicationQuestionnaireList
			}, () => {
				this.PrepareQuestionnaireGridList();
			});
		}
	}
	PrepareQuestionnaireGridList() {
		const { ApplicationQuestionnaireList } = this.state;
		var tastPool = this.loadTaskPool;
		var handleChange = this.HandleQuestionnaireValueChange;
		var Disabled = !this.state.model.isGenerateNew && (this.state.model.Id == 0);
		var QuestionnaireGridItemList = [];
		if (ApplicationQuestionnaireList != undefined) {
			ApplicationQuestionnaireList
				.forEach(function (item) {
					if (item.Questionnaire.QuestionType.ParameterValue == LosQuestionnaireQuestionType.Select) { //select
						var select = <GridItem xs={6}>
							<GenericSelectInput
								LabelMd={5}
								Name={item.Questionnaire.Id}
								LabelText={item.Questionnaire.QuestionTitle}
								Method="POST"
								Url="/losapi/v1.0/Questionnaire/GetQuestionSelectData"
								Parameter={item.Questionnaire}
								DataRoot="Item"
								TaskPoolDomain={tastPool}
								KeyValueMember="Id"
								TextValueMember="DisplayText"
								Value={item.GivenValue}
								ValueChanged={handleChange}
								Disabled={Disabled} />
						</GridItem>;
						QuestionnaireGridItemList.push(select);
					} else if (item.Questionnaire.QuestionType.ParameterValue == LosQuestionnaireQuestionType.Text) {
						var textInput = <GridItem xs={6}>
							<GenericTextInput
								LabelMd={5}
								key={item.Questionnaire.Id}
								Name={item.Questionnaire.Id}
								LabelText={item.Questionnaire.QuestionTitle}
								Value={item.GivenValue == null || undefined
									? ""
									: item.GivenValue}
								ValueChanged={handleChange}
								Disabled={Disabled} />
						</GridItem>;
						QuestionnaireGridItemList.push(textInput);
					} else if (item.Questionnaire.QuestionType.ParameterValue == LosQuestionnaireQuestionType.Number) {
						var numberInput = <GridItem xs={6}>
							<GenericNumberInput
								LabelMd={5}
								NoFormatting
								key={item.Questionnaire.Id}
								Name={item.Questionnaire.Id}
								LabelText={item.Questionnaire.QuestionTitle}
								Value={item.GivenValue == null || undefined
									? ""
									: item.GivenValue}
								ValueChanged={handleChange}
								Disabled={Disabled} />
						</GridItem>;
						QuestionnaireGridItemList.push(numberInput);
					}
				});
		}

		this.setState({ QuestionnaireGridItemList });
	}
	GetApplicationQuestionnaire() {
		this
			.loadTaskPool
			.AppendTask(Proxy.POST("/losapi/v1.0/Questionnaire/SearchApplicationQuestionnaire", {
				Application: {
					Id: this.state.model.Id,
					LoanTypeId: this.state.model.LoanTypeId,
					LoanPurposeId: this.state.model.LoanPurposeId
				}
			}, responseData => {
				console.log("GetApplicationQuestionnaire ->", responseData.IsSucceeded);
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				var list = responseData.Item || [];
				this.setState({
					ApplicationQuestionnaireList: list
				}, () => {
					this.PrepareQuestionnaireGridList();
				});
			}, error => {
				this.ShowMessage("error", "Error", error);
			}));
	}

	render() {
		const {
			model,
			modelList,
			applicantModel,
			isCreateApplicantDialogOpen,
			applicantDefinitionMenuId,
			clientPartnerModel,
			alert,
			isLoading,
			isPeriodDisable,
			IsActiveLoanPurposeDescription,
			IsAppliedForCardReadOnly,
			vModel,
			EnableApplicantUpdate,
			RequestedLengthOfTermSource,
			feeProfileList
		} = this.state;
		const { ExecuteApiPost, MenuCode } = this.props;
		var Disabled = (model.Id === 0) && (!model.isGenerateNew);
		var ForInsert = model.Id === 0 || model.Id == null;
		return (
			<div>

				<LoadingComponent Show={isLoading} TaskPoolDomain={this.loadTaskPool} />

				<GenericDialog fullScreen open={isCreateApplicantDialogOpen}>
					<DialogTitle>
						<GenericLabel Text="Create Applicant" Bold={true} />
					</DialogTitle>
					<DialogContent >
						<ApplicantDefinition
							IsPopUp={true}
							menuId={applicantDefinitionMenuId}
							ApprovalData={this.props.ApprovalData}
							ApplicantDefinitionDiologOpenOrClose={this.ApplicantDefinitionDiologOpenOrClose}
							ExecuteApiPost={ExecuteApiPost} />
						<GenericDialogActions>
							<Button size="sm" onClick={this.hideAlertAndRefresh}>Cancel</Button>
						</GenericDialogActions>
					</DialogContent>
				</GenericDialog>
				<ButtonToolbar
					ButtonList={[
						{
							Code: "Submit",
							OnClick: this.OperationCheck,
							Disabled: !ForInsert
						}, {
							Code: "Update",
							OnClick: this.OperationCheck,
							Disabled: ForInsert
						}, {
							Code: "Clear",
							OnClick: this.HandleClear,
							Disabled: false
						}, {
							Code: "Search",
							OnClick: this.HandleSearch,
							Disabled: false
						}
					]}
					menuId={this.props.menuId}
					ApprovalData={this.props.ApprovalData}
					After={this.props.After}
					IsPopUp={false} />
				<GridContainer>
					{alert}
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel
									IsActive={this.state.panelActivationStatus.panelFilterActive}
									Title={"Application"}
									Name={"panelFilterActive"}
									ValueChanged={this.HandleChangeFilterPanel}>
									<GridContainer direction="row" justify="flex-start" alignItems="stretch">
										<GridItem xs={12}>
											<GridContainer>

												<GridItem xs={9}>
													<GridItem xs={12}>
														<GridContainer direction="row" justify="flex-start" alignItems="flex-start">
															<GridItem
																xs={2}
																style={{
																	display: MenuCode == MenuCodes.Lms_ApplicantDetails
																		? "none"
																		: "inherit"
																}}>
																<GenericCheckInput
																	IsTextBold
																	Name="isCreateNewApplicant"
																	LabelText="Create New Application"
																	Value={model.isCreateNewApplicant}
																	ValueChanged={this.HandleChange} />
															</GridItem>
															<GridItem
																xs={2}
																style={{
																	display: MenuCode == MenuCodes.Lms_ApplicantDetails
																		? "none"
																		: "inherit"
																}}>
																<GenericCheckInput
																	IsTextBold
																	Name="isGenerateNew"
																	LabelText="Complete Application"
																	Value={model.isGenerateNew}
																	ValueChanged={this.HandleChange} />
															</GridItem>
														</GridContainer>
													</GridItem>
													<GridItem xs={12}>
														<GridContainer direction="row" justify="flex-start" alignItems="flex-start">
															<br />
														</GridContainer>
													</ GridItem>
													<GridItem xs={12}>
														<GridContainer direction="row" justify="flex-start" alignItems="flex-start">
															<GridItem xs={5}>
																<GenericSelectInput
																	LabelMd={5}
																	Name="ApplicantId"
																	LabelText="Applicant"
																	Method="POST"
																	Url="/losapi/v1.0/Applicant/GetAll"
																	DataRoot="Item"
																	TaskPoolDomain={this.loadTaskPool}
																	KeyValueMember="Id"
																	TextValueMember="FullName"
																	Value={model.ApplicantId}
																	ValueChanged={this.HandleChange}
																	Required
																	IsInvalid={vModel.ApplicantId}
																	Disabled={model && model.ApplicantId && !EnableApplicantUpdate}
																	Sorted={this.sortedApplicantFullName} />
															</GridItem>
															<GridItem xs={5}>
																<GenericTextInput
																	key={"ApplicantUniqueId"}
																	Name="ApplicantUniqueId"
																	LabelMd={5}
																	LabelText="Applicant Unique Id"
																	Value={applicantModel.ApplicantUniqueId == null || undefined
																		? ""
																		: applicantModel.ApplicantUniqueId}
																	Disabled={true} />
															</GridItem>
														</GridContainer>
													</GridItem>
													<GridItem xs={12}>
														<GridContainer direction="row" justify="flex-start" alignItems="flex-start">
															<GridItem
																xs={5}
																style={{
																	opacity: model.isGenerateNew
																		? 0.4
																		: 1
																}}>
																<GenericSelectInput
																	LabelMd={5}
																	IsStatic={true}
																	StaticData={modelList}
																	Name="Id"
																	LabelText="Application Number"
																	KeyValueMember="Id"
																	RenderItem={this.RenderItemApplication}
																	Value={model.Id}
																	ValueChanged={this.HandleChange}
																	Disabled={model && model.ApplicantId && !EnableApplicantUpdate} />
															</GridItem>
															<GridItem xs={5}>
																<GenericTextInput
																	key={this.state.uniq}
																	Name="ApplicationNumber"
																	LabelMd={5}
																	LabelText="Application Number"
																	Value={model.ApplicationNumber == null || undefined
																		? ""
																		: model.ApplicationNumber}
																	ValueChanged={this.HandleChange}
																	Disabled={true} />
															</GridItem>
														</GridContainer>
													</GridItem>
													<GridItem xs={12}>
														<GridContainer direction="row" justify="flex-start" alignItems="flex-start">
															<GridItem xs={5}>
																<GenericSelectInput
																	LabelMd={5}
																	Name="ClientPartnerId"
																	LabelText="Partner"
																	Method="POST"
																	Url="/losapi/v1.0/ClientPartner/GetAll"
																	DataRoot="Item"
																	TaskPoolDomain={this.loadTaskPool}
																	KeyValueMember="Id"
																	TextValueMember="LegalNameOfBusiness"
																	Value={clientPartnerModel.Id}
																	ValueChanged={this.HandleChange} />
															</GridItem>
															<GridItem xs={5}>
																<GenericTextInput
																	key={"PartnerUniqueId"}
																	Name="PartnerUniqueId"
																	LabelMd={5}
																	LabelText="Partner Unique Id"
																	Value={clientPartnerModel.PartnerUniqueId == null || undefined
																		? ""
																		: clientPartnerModel.PartnerUniqueId}
																	Disabled={true} />
															</GridItem>
														</GridContainer>
													</GridItem>
													<GridItem xs={12}>
														<GridContainer direction="row" justify="flex-start" alignItems="flex-start">
															<GridItem xs={5}>
																<GenericSelectInput
																	Name="LoanFeeDefinitionId"
																	LabelText="Fee Profile"
																	LabelMd={5}
																	IsStatic={true}
																	StaticData={feeProfileList}
																	DataRoot="Item"
																	KeyValueMember="Id"
																	TextValueMember="Name"
																	Value={model.LoanFeeDefinitionId}
																	ValueChanged={this.HandleChange}
																	Disabled={model && model.ApplicantId && model.Id}
																	Required
																	IsInvalid={vModel.LoanFeeDefinitionId} />
															</GridItem>
														</GridContainer>
													</GridItem>
												</GridItem>
												<GridItem xs={3}>
													<GenericTextInput
														key={"InternalBlacklist"}
														Name="InternalBlacklist"
														LabelMd={6}
														LabelText="Internal Blacklist Status"
														Value={applicantModel.InternalBlacklist == null || undefined
															? ""
															: applicantModel.IdVerification}
														Disabled={true}
														Required
														IsInvalid={vModel.InternalBlacklist} />
													<GenericTextInput
														key={"WatchListVerification"}
														Name="WatchListVerification"
														LabelMd={6}
														LabelText="Watch List Verification Status"
														Value={applicantModel.WatchListVerification == null || undefined
															? ""
															: applicantModel.WatchListVerification}
														Disabled={true} />
													<GenericTextInput
														key={"TransUnionIdVerification"}
														Name="TransUnionIdVerification"
														LabelMd={6}
														LabelText="Trans Union Verification Status"
														Value={applicantModel.TransUnionIdVerification == null || undefined
															? ""
															: applicantModel.TransUnionIdVerification}
														Disabled={true} /> {applicantModel.IdentityVerificationTypeValue == IdentificationMethod.CanadaPostVerification && <GenericTextInput
															key={"AddressVerification"}
															Name="AddressVerification"
															LabelMd={6}
															LabelText="Canada Post"
															Value={applicantModel.AddressVerification == null || undefined
																? ""
																: applicantModel.AddressVerification}
															Disabled={true} />
													}
													{applicantModel.IdentityVerificationTypeValue == IdentificationMethod.InPersonIDVerification && <GenericTextInput
														key={"IdVerification"}
														Name="IdVerification"
														LabelMd={6}
														LabelText="In Person ID Verification"
														Value={applicantModel.IdVerification == null || undefined
															? ""
															: applicantModel.IdVerification}
														Disabled={true}
														Required
														IsInvalid={vModel.IdVerification} />
													}

													<GenericTextInput
														key={"CreditHistoryVerification"}
														Name="CreditHistoryVerification"
														LabelMd={6}
														LabelText="Credit History Check"
														Value={applicantModel.CreditHistoryVerification == null || undefined
															? ""
															: applicantModel.CreditHistoryVerification}
														Disabled={true} />

												</GridItem>
											</GridContainer>
										</GridItem>
										<GridItem xs={11}></GridItem>
										<GridItem xs={1}>
											<Button
												size="sm"
												color="black"
												fullWidth
												onClick={this.PanelFilterActiveNextClicked}>Next</Button>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel
									IsActive={this.state.panelActivationStatus.panelAppliedForActive}
									Title={"Applied For"}
									Name={"panelAppliedForActive"}
									ValueChanged={this.HandleChangeFilterPanel}>
									<GridContainer direction="row" justify="flex-start" alignItems="stretch">
										<GridItem xs={12}>
											<GridContainer direction="row" justify="flex-start" alignItems="flex-start">
												<GridItem xs={4}>
													<GenericSelectInput
														Name="LoanTypeId"
														LabelText="Loan Type"
														LabelMd={5}
														Method="POST"
														Url="/coreapi/v1.0/Parameter/Search"
														Parameter={this.LoanTypeParameterCode}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="ParameterDesc"
														Value={model.LoanTypeId}
														ValueChanged={this.HandleChange}
														Disabled={Disabled || IsAppliedForCardReadOnly}
														Required
														IsInvalid={vModel.LoanTypeId} />
												</GridItem>
												{((model.LoanType != null && model.LoanType.ParameterValue == ConstLoanType.Loan) || !(isPeriodDisable != undefined && isPeriodDisable)) && <GridItem xs={4}>
													<GenericSelectInput
														IsStatic
														LabelMd={5}
														key="RequestedPaymentPeriod"
														Name="RequestedPaymentPeriod"
														LabelText="Requested Length of Term"
														StaticData={RequestedLengthOfTermSource}
														KeyValueMember="Value"
														TextValueMember="Text"
														Value={model.RequestedPaymentPeriod == null || undefined
															? ""
															: model.RequestedPaymentPeriod}
														ValueChanged={this.HandleChange}
														Disabled={Disabled || IsAppliedForCardReadOnly || (isPeriodDisable != undefined && isPeriodDisable)}
														Required
														IsInvalid={vModel.RequestedPaymentPeriod} />

												</GridItem>
												}
												<GridItem xs={4}>
													<GenericSelectInput
														Name="RequestedPaymentFrequencyId"
														LabelText="Requested Payment Frequency"
														LabelMd={5}
														Method="POST"
														Url="/coreapi/v1.0/Parameter/Search"
														Parameter={this.parameterLOSPaymentFrequency}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="ParameterDesc"
														Value={model.RequestedPaymentFrequencyId}
														ValueChanged={this.HandleChange}
														DefaultIndex={1}
														Disabled={Disabled || IsAppliedForCardReadOnly} />
												</GridItem>
												<GridItem xs={4}>
													<GenericNumberInput
														key="RequestedAmount"
														Name="RequestedAmount"
														LabelText="Requested Amount"
														LabelMd={5}
														MaxLength={10}
														Prefix="$"
														Value={model.RequestedAmount == null || undefined
															? ""
															: model.RequestedAmount}
														ValueChanged={this.HandleChange}
														Disabled={Disabled || IsAppliedForCardReadOnly}
														Required
														IsInvalid={vModel.RequestedAmount} />
												</GridItem>
												{((model.LoanType != null && model.LoanType.ParameterValue == ConstLoanType.SecureCard) || !(isPeriodDisable != undefined && isPeriodDisable)) && <GridItem xs={4}>
													<GenericNumberInput
														key="SecurityAmount"
														Name="SecurityAmount"
														LabelText="Security Amount"
														LabelMd={5}
														MaxLength={10}
														Prefix="$"
														Value={model.SecurityAmount == null || undefined
															? ""
															: model.SecurityAmount}
														ValueChanged={this.HandleChange}
														Disabled={Disabled || IsAppliedForCardReadOnly} />
												</GridItem>
												}
												<GridItem xs={4}>
													<GenericSelectInput
														Name="LoanPurposeId"
														LabelText="Purpose Of Loan"
														LabelMd={5}
														key={model.LoanTypeId + "LoanPurpose"}
														Method="POST"
														Url="/losapi/v1.0/LosParameter/SearchLoanPurposeByLoanTypeId"
														Parameter={{
															LoanTypeId: model.LoanTypeId
														}}
														DataRoot="Item"
														KeyValueMember="Id"
														TextValueMember="ParameterDesc"
														Sorted={this.sortedParameterDesc}
														Value={model.LoanPurposeId}
														ValueChanged={this.HandleChange}
														Disabled={Disabled || IsAppliedForCardReadOnly}
														Required
														IsInvalid={vModel.LoanPurposeId} />
												</GridItem>
												{IsActiveLoanPurposeDescription && <GridItem xs={4}>
													<GenericTextInput
														Name="LoanPurposeDescription"
														inputProps={{
															maxLength: 200
														}}
														IsText
														LabelMd={5}
														LabelText="Purpose Description"
														Value={model.LoanPurposeDescription}
														ValueChanged={this.HandleChange}
														Disabled={Disabled || IsAppliedForCardReadOnly} />
												</GridItem>
												}
											</GridContainer>
										</GridItem>
										<GridItem xs={11}></GridItem>
										<GridContainer direction="row" justify="flex-start" alignItems="flex-start">
											<GridItem xs={6}></GridItem>
											<GridItem xs={6}>
												<GridContainer justify="flex-end">
													<GridItem xs={2}>
														<Button
															size="sm"
															color="black"
															fullWidth
															disabled={(model == null || model.Id == null || model.Id == 0)}
															onClick={this.HandleAppliedForUpdateAction}>{IsAppliedForCardReadOnly
																? "Edit"
																: "Update"}</Button>
													</GridItem>
													<GridItem xs={2}>
														<Button
															size="sm"
															color="black"
															fullWidth
															disabled={(model == null || model.Id == null || model.Id == 0)}
															onClick={this.PanelAppliedForActiveNextClicked}>Next</Button>
													</GridItem>
												</GridContainer>
											</GridItem>
										</GridContainer>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<LoanApplicantInformation
									ApplicantModel={applicantModel}
									Title="Applicant Information"
									Disabled={true}
									NextClicked={this.PanelApplicantInformationActiveNextClicked}
									HandleChangeFilterPanel={this.HandleChangeFilterPanel}
									Name={"panelApplicantInformationActive"}
									IsActive={this.state.panelActivationStatus.panelApplicantInformationActive}
									RetiredOrUnemployedIDs = {this.retiredOrUnemployedOccupationIds} 
									IdentificationMethodIds = {this.identificationMethodIds}
									SpecificIdentityTypesIds = {this.specificIdentityTypesIds} />
							</CardBody>
						</Card>
						{(model.LoanType != null && model.LoanType.ParameterValue != ConstLoanType.SecureCard) && <Card className="no-radius">
							<CardBody>
								<LoanApplicationScoringInfo
									ApplicationModel={model}
									HandleChange={this.HandleChange}
									IsOptional={false}
									Title="Additional Information"
									Disabled={false}
									NextClicked={this.panelAdditionalInformationActiveNextClicked}
									HandleChangeFilterPanel={this.HandleChangeFilterPanel}
									Name={"panelAdditionalInformationActive"}
									IsActive={this.state.panelActivationStatus.panelAdditionalInformationActive} />
							</CardBody>
						</Card>
						}
						<Card className="no-radius">
							<CardBody>
								<LoanApplicationScoringInfo
									ApplicationModel={model}
									IsOptional={true}
									HandleChange={this.HandleChange}
									Title="Additional Information(Optional)"
									Disabled={false}
									NextClicked={this.panelAdditionalInformationOptionalActiveNextClicked}
									HandleChangeFilterPanel={this.HandleChangeFilterPanel}
									Name={"panelAdditionalInformationOptionalActive"}
									IsActive={this.state.panelActivationStatus.panelAdditionalInformationOptionalActive} />
							</CardBody>
						</Card>

						{(model.LoanType != null && model.LoanType.ParameterValue == ConstLoanType.Loan) && <Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel
									IsActive={this.state.panelActivationStatus.panelSecurityInformationActive}
									Title={"Security Information"}
									Name={"panelSecurityInformationActive"}
									ValueChanged={this.HandleChangeFilterPanel}>
									<GridContainer direction="row" justify="flex-start" alignItems="stretch">
										<GridItem xs={12}>
											<LoanApplicationSecurityInformation
												ApplicationModel={this.state.model}
												HandleChange={this.HandleChange}
												Disabled={Disabled} />
										</GridItem>
										<GridItem xs={11}></GridItem>
										<GridItem xs={1}>
											<Button
												size="sm"
												color="black"
												fullWidth
												onClick={this.panelSecurityInformationActiveNextClicked}>Next</Button>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						}
						{(model.LoanType != null && model.LoanType.ParameterValue != ConstLoanType.SecureCard) && <Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel
									IsActive={this.state.panelActivationStatus.panelScoreActive}
									Title={"Score"}
									Name={"panelScoreActive"}
									ValueChanged={this.HandleChangeFilterPanel}>
									<GridContainer direction="row" justify="flex-start" alignItems="stretch">
										<GridItem xs={12}>
											<LoanApplicationCreditScore
												ApplicationModel={this.state.model}
												HandleChange={this.HandleChange}
												Disabled={Disabled} />
										</GridItem>
										<GridItem xs={11}></GridItem>
										<GridItem xs={1}>
											<Button
												size="sm"
												color="black"
												fullWidth
												onClick={this.panelScoreActiveNextClicked}>Next</Button>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						}
						{(model.LoanType != null && model.LoanType.ParameterValue != ConstLoanType.SecureCard) && <Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel
									IsActive={this.state.panelActivationStatus.panelNetworthActive}
									Title={"Networth"}
									Name={"panelNetworthActive"}
									ValueChanged={this.HandleChangeFilterPanel}>
									<GridContainer direction="row" justify="flex-start" alignItems="stretch">
										<GridItem xs={12}>
											<LoanApplicationNetworth
												ApplicationModel={this.state.model}
												HandleChange={this.HandleChange}
												Disabled={Disabled} />
										</GridItem>
										<GridItem xs={11}></GridItem>
										<GridItem xs={1}>
											<Button
												size="sm"
												color="black"
												fullWidth
												onClick={this.panelNetworthActiveNextClicked}>Next</Button>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						}
						{this.state.QuestionnaireGridItemList && this.state.QuestionnaireGridItemList.length != 0 && <Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel
									IsActive={this.state.panelActivationStatus.panelQuestionnaireActive}
									Title={"Questionnaire"}
									Name={"panelQuestionnaireActive"}
									ValueChanged={this.HandleChangeFilterPanel}>
									<GridContainer direction="row" justify="flex-start" alignItems="stretch">
										<GridItem xs={12}>
											<GridContainer>
												{this.state.QuestionnaireGridItemList}
											</GridContainer>
										</GridItem>
										<GridItem xs={11}></GridItem>
										<GridItem xs={1}>
											<Button
												size="sm"
												color="black"
												fullWidth
												onClick={this.panelQuestionnaireActiveNextClicked}>Next</Button>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						}

						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel
									IsActive={this.state.panelActivationStatus.panelBorrowerReviewActive}
									Title={"Borrower Review"}
									Name={"panelBorrowerReviewActive"}
									ValueChanged={this.HandleChangeFilterPanel}>
									<GridContainer direction="row" justify="flex-start" alignItems="stretch">
										<GridItem xs={12}>
											<LoanApplicationBorrowerReview
												ApplicationModel={this.state.model}
												HandleChange={this.HandleChange}
												Disabled={Disabled} />
										</GridItem>
										<GridItem xs={11}></GridItem>
										<GridItem xs={1}>
											<Button
												size="sm"
												color="black"
												fullWidth
												onClick={this.panelBorrowerReviewActiveNextClicked}>Next</Button>
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>

					</GridItem>
				</GridContainer >
			</div >
		);
	}

}

CreateApplication.propTypes = {
	tabList: PropTypes.array,
	classes: PropTypes.object.isRequired,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	ApplicationSaved: PropTypes.func,
	Disabled: PropTypes.bool,
	MenuCode: PropTypes.string,
	ExecuteApiPost: PropTypes.func
};

export
	default withArtifex(CreateApplication, {});