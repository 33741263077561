import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import ClientHelper from "core/ClientHelper";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDateInput, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType } from "views/Constants/Constant";

class ClientSetupIdLetterofGuaranteeList extends Component {
	constructor(props) {
		super(props);

		this.isClient = ClientHelper.IsClient();

		this.initialModel = {
			Id: 0,
			UniqueClientId: this.isClient ? ClientHelper.GetClientRowId() : undefined,
		};

		this.state = {
			alert: null,
			model: { ...this.initialModel },
			list: [],
			isLoading: false
		};

		this.parameterClientType = {
			ParameterCode: "ClientType",
			ParameterValue3: "D"
		};

		this.parameterUniqueClientId = {
			UniqueClientId: this.isClient ? ClientHelper.GetClientId() : undefined
		};

		this.parameterCustomerName = {};

		this.renderItemUniqueClient = {};
		this.renderItemClientCustomer = {};

		this.ColumnsData = [
			{
				Header: "UniqueClientName",
				accessor: "UniqueClientName"
			},
			{
				Header: "Expire Date",
				accessor: "EndDate",
				type: GridColumnType.DateUtc
			},
			
			{
				Header: "Security Type",
				accessor: "SecurityType"
			},
			{
				Header: "Wallet Name",
				accessor: "AccountName"
			},
			{
				Header: "Program",
				accessor: "Program"
			},
			{
				Header: "Transit Number",
				accessor: "TransitNumber"
			},
			{
				Header: "Wallet Number",
				accessor: "AccountNumber"
			},
		
			{
				Header: "Secure Amount",
				accessor: "SecureAmount",
				type: GridColumnType.Money,
				ColumnType:GridColumnType.Money
			},
			{
				Header: "Wallet Amount",
				accessor: "PrepaidAmount",
				type: GridColumnType.Money,
				ColumnType:GridColumnType.Money

			},
			{
				Header: "Available Balance",
				accessor: "AvailableLimit",
				type: GridColumnType.Money,
				ColumnType:GridColumnType.Money
			},
			{
				Header: "Insert Date",
				accessor: "InsertDateTime",
				type: GridColumnType.DateUtc
			},
			{
				Header: "Last Loading Date",
				accessor: "LastLoadingDate",
				type: GridColumnType.DateUtc
			},
		];

		this.sortName = { Member: "Name" };
	}

	componentDidMount() {
		if (this.props.setAppLeftTitle)
			this.props.setAppLeftTitle("Letter of Guarantee Expire Date List");
		if (this.props.setAppCenterTitle)
			this.props.setAppCenterTitle("CLIENT");
	}

	HandleChange = (name, newValue, data) => {
		const model = { ...this.state.model };
		model[name] = newValue;

		this.setState({ model });
	}

	HandleSearch = () => {
		this.setState({ alert: null, isLoading: true });
		Proxy.POST("/bankapi/v1.0/ClientCustomer/SearchLetterofGuaranteeList",
			this.state.model,
			responseData => {
				if (responseData.Item != null && responseData.Item.length > 0) {
					this.setState({ list: responseData.Item, isLoading: false });
				}
				else {
					this.setState({ list: [], isLoading: false });
				}
			},
			this.ErrorCallback
		);
	}

	HandleClear = () => {
		this.setState({ model: { ...this.initialModel } });
	}

	ErrorCallback = (error) => {
		this.setState({ isLoading: false });
		this.ShowMessage("error", "Error", "An error occurred during the api visit" + error);
	}

	RenderItemUniqueClient = (d) => {
		this.renderItemUniqueClient = `${d.UniqueClientId} - ${d.Name}`;
		return this.renderItemUniqueClient;
	}

	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() =>
				this.setState({ alert: null })} />
		});
	}

	ShowConfirmMessage = (type, title, message, onConfirm, showCancel = true) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={showCancel} OnCancel={() => this.setState({ alert: null })} OnConfirm={onConfirm} />
		});
	}

	render() {
		const { classes, Disabled } = this.props;
		const { model, alert, isLoading, ReadOnly ,list} = this.state;
		var data = list == null ? [] : list.map(d => {
			return {
				Id: d.Id,
				UniqueClientId: d.UniqueClientId,
				UniqueClientName:d.UniqueClient == null ? "" : d.UniqueClient.UniqueClientId+"-"+d.UniqueClient.ClientName,
				AccountId: d.Account == null ? "" : d.Account.Id,
				AccountName: d.Account == null ? "" : d.Account.AccountName == null ? "" : d.Account.AccountName,
				AccountNumber: d.Account == null ? "" : d.Account.AccountNumber == null ? "" : d.Account.AccountNumber,
				TransitNumber: d.Account == null ? "" : d.Account.Branch == null ? "" : d.Account.Branch.TransitNumber == null ? "" : d.Account.Branch.TransitNumber,
				SecurityTypeId: d.SecurityTypeId == null ? "" : d.SecurityTypeId,
				SecurityType: d.SecurityType == null ? "" : d.SecurityType.ParameterDesc,
				Program: d.Program == null ? "" : d.Program.Description,
				ProgramId: d.ProgramId == null ? "" : d.ProgramId,
				FromDate: d.FromDate == null ? null : d.FromDate,
				EndDate: d.ToDate == null ? null : d.ToDate,
				AvailableLimit: d.AvailableLimit == null ? "" : d.AvailableLimit,
				LastLoadingDate: d.LastLoadingDate == null ? null : d.LastLoadingDate,
				SecureAmount: d.SecureAmount == null ? "" : d.SecureAmount,
 				InsertDateTime: d.InsertDateTime == null ? "" : d.InsertDateTime 
			}
		});
		return (
			<div>

				<LoadingComponent Show={isLoading} />

				{alert}
				<ButtonToolbar ButtonList={[
					{ Code: "Search", OnClick: this.HandleSearch, Disabled: ReadOnly || Disabled },
					{ Code: "Clear", OnClick: this.HandleClear, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
				<GridContainer>
					<GridItem xs={12}>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel Title="Filter Panel">
									<GridContainer>
										{!this.isClient &&
											<GridItem xs={3}>
												<GenericSelectInput
													Name="UniqueClientId"
													LabelText="Client"
													Method="POST"
													Url="/bankapi/v1.0/BankCustomer/Search"
													Parameter={this.parameterUniqueClientId}
													DataRoot="Item"
													KeyValueMember="Id"
													RenderItem={this.RenderItemUniqueClient}
													Value={model.UniqueClientId}
													ValueChanged={this.HandleChange}
													CanClear
													All
													Disabled={this.isClient}
													DefaultIndex={this.isClient ? 0 : -1} />
											</GridItem>}
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
						<Card className="no-radius">
							<CardBody>
								<GenericExpansionPanel
									Title="Letter of Guarantee Date List">
									<GridContainer>
										<GridItem xs={12}>
											<GenericGrid
												Data={data}
												Columns={this.ColumnsData}
												IsSorted={false} />
										</GridItem>
									</GridContainer>
								</GenericExpansionPanel>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

ClientSetupIdLetterofGuaranteeList.propTypes = {
	classes: PropTypes.object.isRequired,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func,
	Disabled: PropTypes.bool
};

export default withArtifex(ClientSetupIdLetterofGuaranteeList, {});